import { combineReducers } from "redux";
import { Action } from "src/types";
import { ById } from "src/utils/types/general";
import {
    ADMIN_CREATE_OPEN_BUY,
    ADMIN_DELETE_OPEN_BUY,
    ADMIN_FETCH_OPEN_BUY,
    ADMIN_FETCH_OPEN_BUYS,
    ADMIN_UPDATE_OPEN_BUY,
    ADMIN_UPDATE_OPEN_BUY_INSTRUCTION
} from "./actions.admin-tools";

export interface AdminOpenBuysEntitiesState {
    byId: ById<any>;
    allIds: number[];
}

const initialAdminOpenBuysState: AdminOpenBuysEntitiesState = {
    byId: {},
    allIds: []
};

function byId(
    state = initialAdminOpenBuysState.byId,
    action: Action
): ById<any> {
    switch (action.type) {
        case ADMIN_FETCH_OPEN_BUYS.SUCCESS:
            const { normalizedSearchResponse } = action.payload.data;
            return { ...state, ...normalizedSearchResponse.entities.openBuy };

        case ADMIN_FETCH_OPEN_BUY.SUCCESS:
            const fetchedOpenBuy = action.payload.data.openBuy;
            return { ...state, [fetchedOpenBuy.id]: fetchedOpenBuy };

        case ADMIN_CREATE_OPEN_BUY.SUCCESS:
            return { ...state, ...action.payload.data.entities.openBuy };

        case ADMIN_UPDATE_OPEN_BUY.SUCCESS:
            const { openBuy } = action.payload.data;
            return { ...state, [openBuy.id]: openBuy };

        case ADMIN_UPDATE_OPEN_BUY_INSTRUCTION:
            const { openBuyId, instructionSetId } = action.payload;
            const updateInstructionState = { ...state };
            updateInstructionState[
                openBuyId
            ].instructionSetId = instructionSetId;
            return updateInstructionState;

        case ADMIN_DELETE_OPEN_BUY.SUCCESS:
            const stateCopy = { ...state };
            delete stateCopy[action.payload.openBuyId];
            return { ...stateCopy };

        default:
            return state;
    }
}

function allIds(
    state = initialAdminOpenBuysState.allIds,
    action: Action
): number[] {
    switch (action.type) {
        case ADMIN_FETCH_OPEN_BUYS.SUCCESS:
            const { normalizedSearchResponse } = action.payload.data;
            return [...state, ...normalizedSearchResponse.result];

        case ADMIN_CREATE_OPEN_BUY.SUCCESS:
            return [...state, action.payload.data.result];

        case ADMIN_DELETE_OPEN_BUY.SUCCESS:
            const stateCopy = state.filter((id: number) => {
                return id !== action.payload.openBuyId;
            });
            return stateCopy;

        default:
            return state;
    }
}

const adminOpenBuysEntitiesReducer = combineReducers<
    AdminOpenBuysEntitiesState
>({
    byId,
    allIds
});

export default adminOpenBuysEntitiesReducer;
