import merge from "lodash.merge";
import { combineReducers } from "redux";

import { Action, NormalizedActionGeneric } from "src/types";
import { LinkList, LinkListItem } from "../types";

import {
    DELETE_LINK_LIST,
    DELETE_LINK_LIST_ITEM,
    EDIT_LINK_LIST,
    EDIT_LINK_LIST_ITEM,
    FETCH_LINK_LIST
} from "./actions";

export interface ListById {
    [key: string]: LinkList;
}

export interface ItemById {
    [key: string]: LinkListItem;
}

export interface LinkListEntitiesState {
    ListById: ListById;
    ItemById: ItemById;
    allIds: number[];
}

function listByIdReducer(
    state: ListById = {},
    action: NormalizedActionGeneric<LinkList, number[]>
) {
    switch (action.type) {
        case FETCH_LINK_LIST.SUCCESS:
            return {
                ...state,
                ...action.response.entities.linkLists
            };
        case EDIT_LINK_LIST.SUCCESS:
            let list = state[parseInt(action.payload.linkList.id)];
            return {
                ...state,
                [action.payload.linkList.id]: {
                    ...action.payload.linkList,
                    LinkItems: list?.LinkItems || []
                }
            };
        case DELETE_LINK_LIST.SUCCESS:
            let temp = Object.assign({}, state);
            delete temp[action.payload];
            return temp;
        case EDIT_LINK_LIST_ITEM.SUCCESS:
            let list1 = state[parseInt(action.payload.linkListItem.linkListId)];
            if (
                list1 &&
                !list1.LinkItems.includes(action.payload.linkListItem.id)
            )
                list1.LinkItems.push(action.payload.linkListItem.id);
            return {
                ...state,
                [action.payload.linkListItem.linkListId]: {
                    ...list1
                }
            };
        case DELETE_LINK_LIST_ITEM.SUCCESS:
            let list2 = state[action.payload.listId];
            return {
                ...state,
                [action.payload.listId]: {
                    ...list2,
                    LinkItems: list2.LinkItems.filter(
                        id => id !== parseInt(action.payload.id)
                    )
                }
            };
        default:
            return state;
    }
}

function itemByIdReducer(
    state: ItemById = {},
    action: NormalizedActionGeneric<LinkList, number[]>
) {
    switch (action.type) {
        case FETCH_LINK_LIST.SUCCESS:
            return {
                ...state,
                ...action.response.entities.LinkItems
            };
        case EDIT_LINK_LIST_ITEM.SUCCESS:
            return {
                ...state,
                [action.payload.linkListItem.id]: {
                    ...action.payload.linkListItem
                }
            };
        case DELETE_LINK_LIST_ITEM.SUCCESS:
            let temp = Object.assign({}, state);
            delete temp[action.payload.id];
            return temp;
        default:
            return state;
    }
}

function allIdsReducer(
    state: number[] = [],
    action: NormalizedActionGeneric<LinkList, number[]>
) {
    switch (action.type) {
        case FETCH_LINK_LIST.REQUEST:
            return [];
        case FETCH_LINK_LIST.SUCCESS:
            return action.response.result;
        case FETCH_LINK_LIST.FAILURE:
            return [];
        case EDIT_LINK_LIST.SUCCESS:
            let temp = [...state];
            if (!state?.includes(action.payload.linkList.id))
                temp?.push(action.payload.linkList.id);
            return temp;
        case DELETE_LINK_LIST.SUCCESS:
            if (state)
                return state.filter((id: number) => id !== action.payload);
            else return [];
        default:
            return state;
    }
}

export const LinkListsEntitiesReducer = combineReducers<LinkListEntitiesState>({
    ListById: listByIdReducer,
    ItemById: itemByIdReducer,
    allIds: allIdsReducer
} as any);
