import React from "react";
import styled from "styled-components";
import { Top, Middle, Bottom, Form, BlueButton } from "./commonComponents";
import { ContestPrize } from "../../types";
import {
    SmallPillButton,
    WhitePillButton
} from "../../components/styledComponents";
import { formatNumber } from "../../../../util";

const RedText = styled.span`
    color: red;
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Name = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 18px;
`;

interface Props {
    prize: ContestPrize;
    type: "prize" | "cash" | null;
    setType: (cash: "prize" | "cash" | null) => void;
    confirm: () => void;
}

const ChooseForm = ({ prize, type, setType, confirm }: Props) => {
    return (
        <>
            <Top>
                <div>
                    <strong>Choose a Prize</strong>
                </div>
                <div>
                    <span>
                        Select which prize option you would prefer.{" "}
                        <RedText>
                            Disclaimer: Non-cash prizes cannot be shipped
                            internationally.
                        </RedText>
                    </span>
                </div>
            </Top>
            <Middle>
                <Form>
                    <FlexContainer>
                        <Name>{prize.name}</Name>
                        {type === "prize" ? (
                            <SmallPillButton>Select</SmallPillButton>
                        ) : (
                            <WhitePillButton onClick={() => setType("prize")}>
                                Select
                            </WhitePillButton>
                        )}
                    </FlexContainer>
                </Form>
                <Form>
                    <FlexContainer>
                        <Name>${formatNumber(prize.cash_equivalent)} cash</Name>
                        {type === "cash" ? (
                            <SmallPillButton>Select</SmallPillButton>
                        ) : (
                            <WhitePillButton onClick={() => setType("cash")}>
                                Select
                            </WhitePillButton>
                        )}
                    </FlexContainer>
                </Form>
            </Middle>
            <Bottom>
                <BlueButton onClick={confirm} disabled={!type}>
                    Next
                </BlueButton>
            </Bottom>
        </>
    );
};

export default ChooseForm;
