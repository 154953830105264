import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { flexRowCenter } from "src/utils/styles/snippets";
import { useInView } from "react-intersection-observer";

interface StyleProps {
    isPortrait: boolean;
}

const Main = styled.div<StyleProps>`
    ${flexRowCenter()};
    position: relative;
    ${props => (props.isPortrait ? "height: 100%" : "max-height: 100%")};
    width: fit-content;
    max-width: 400px;
    border-radius: 6px;
    overflow: hidden;
    margin: 10px auto;
`;

const Video = styled.video`
    display: block;
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
    border-radius: 6px;
    /* @media (max-width: 386px) {
        height: 60vh;
    } */
`;

const Image = styled.img`
    display: block;
    max-height: 100%;
    max-width: 100%;
    border-radius: 6px;
`;

const PlayScreen = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 50px;
    pointer-events: none;
`;

interface Props {
    mediaUrl: string;
    mediaType: string;
    coverPhotoUrl?: string;
    isPortrait: boolean;
    className?: string;
    controls?: boolean;
}

const CarouselMedia = ({
    mediaUrl,
    mediaType,
    coverPhotoUrl,
    isPortrait,
    controls,
    className
}: Props) => {
    const [play, setPlay] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const { ref, inView } = useInView();

    useEffect(() => {
        if (!inView) {
            videoRef?.current?.pause();
            setPlay(false);
        }
    }, [inView]);

    let media;

    if (mediaType === "video") {
        media = (
            <>
                <Video
                    ref={videoRef}
                    controls={controls}
                    poster={coverPhotoUrl}
                    playsInline={true}
                    src={`${mediaUrl}#t=0.001`}
                    onClick={e => {
                        if (!play) {
                            setPlay(true);
                        }
                        const target = e.target as HTMLVideoElement;
                        target.paused ? target.play() : target.pause();
                    }}
                />
                {!controls && (
                    <PlayScreen style={{ display: play ? "none" : "flex" }}>
                        <FontAwesomeIcon icon={["fas", "play"]} />
                    </PlayScreen>
                )}
            </>
        );
    } else if (mediaType === "photo") {
        media = <Image src={mediaUrl} />;
    } else {
        media = <Image alt={"could not find media"} />;
    }

    return (
        <Main
            ref={ref}
            className={className}
            onClick={e => e.stopPropagation()}
            onMouseDown={e => e.stopPropagation()}
            isPortrait={isPortrait}
        >
            {media}
        </Main>
    );
};

export default CarouselMedia;
