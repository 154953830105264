import { connect } from "react-redux";
import { GlobalState } from "../../reducers";
import { highlightPlacementsModal } from "../../ui/highlights/actions";
import { getHighlights } from "../selectors";
import { fetchCampaignHighlights } from "./actions";
import HighlightsMediaRow from "./HighlightsMediaRow";

const mapStateToProps = (state: GlobalState) => ({
    location: "campaign" as const,
    emptyMessage: "This campaign doesn't have any highlights yet.",
    campaignId: state.ui.campaign.currentCampaignId,
    highlights: getHighlights(state),
    highlightsLoading: state.ui.campaign.highlightsLoading
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchHighlights: (campaignId: number) =>
        dispatch(fetchCampaignHighlights(campaignId)),
    highlightPlacementsModal: () => dispatch(highlightPlacementsModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(HighlightsMediaRow);
