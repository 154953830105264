import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Main from "./main";
import Prizes from "./prizes";
import { GlobalState } from "../../reducers";
import { Contest } from "./types";
import SimpleNavbar from "../../components/SimpleNavbar";
import FullscreenLoader from "../../components/FullscreenLoader";
import NavButton from "../../components/NavButton";
import { goBack, push } from "connected-react-router";
import { fetchContest } from "./actions";
import { getContest } from "./selectors";
import NotFoundPage from "src/navigation/NotFound";
import Navbar from "src/components/Navbar";

interface Props {
    contest: Contest | undefined;
    contestsLoading: boolean;
    fetchContest: () => void;
    push: (path: string) => void;
    goBack: () => void;
}

const ContestPage = ({
    contest,
    contestsLoading,
    fetchContest,
    push,
    goBack
}: Props) => {
    useEffect(() => {
        fetchContest();
    }, []);

    if (contest && contest.status !== "about_to_start") {
        return (
            <Switch>
                <Route
                    exact
                    path={"/contest/prize"}
                    render={() => {
                        return <Prizes contest={contest} push={push} />;
                    }}
                />
                <Route
                    exact
                    path={"/contest"}
                    render={() => {
                        return <Main contest={contest} push={push} />;
                    }}
                />

                <Route component={NotFoundPage} />
            </Switch>
        );
    } else {
        return (
            <>
                <Navbar back title="CLAIM PRIZE" />

                {contestsLoading ? (
                    <FullscreenLoader />
                ) : (
                    <div style={{ padding: "30px 15px" }}>
                        No contest right now
                    </div>
                )}
            </>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    contest: getContest(state),
    contestsLoading: state.ui.contests.contestsLoading
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchContest: () => dispatch(fetchContest()),
    push: (path: string) => dispatch(push(path)),
    goBack: () => dispatch(goBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(ContestPage);
