import React from "react";
import styled from "styled-components";
import { Profile } from "../../types";
import { cashoutMethods } from "../../transactions/cashoutMethods";
import SmallIcon from "../components/SmallIcon";
import SettingsText from "../components/SettingsText";
import ListCell from "../components/ListCell";
import OutlineContainer from "../components/OutlineContainer";
import SettingsLink from "../components/SettingsLink";
import { colors } from "../../../constants";

const Action = styled(SettingsLink)`
    margin-left: auto;
`;

const Red = styled.div`
    color: ${colors.primaryRed};
`;

interface Props {
    profile: Profile;
    cashoutSettingModal: () => void;
}

const CashoutInfo = ({ profile, cashoutSettingModal }: Props) => {
    let cashoutMethod =
        cashoutMethods.find(method => {
            return method.tag === profile.tipaltiPaymentMethod;
        }) || cashoutMethods[0];

    return (
        <OutlineContainer>
            <ListCell>
                <SmallIcon src={cashoutMethod.icon} />
                <SettingsText>
                    {profile.tipaltiPaymentMethod === "NoPM" ? (
                        <Red>Not Connected</Red>
                    ) : (
                        cashoutMethod.tag
                    )}
                </SettingsText>
                {profile.tipaltiPaymentMethod === "NoPM" ? (
                    <Action onClick={cashoutSettingModal}>Set Up Now</Action>
                ) : (
                    <Action
                        grayed
                        onClick={() => {
                            window.open(
                                "https://suppliers.tipalti.com/theplug/portal/index"
                            );
                        }}
                    >
                        Edit
                    </Action>
                )}
            </ListCell>
        </OutlineContainer>
    );
};

export default CashoutInfo;
