import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { ReferralLeaderboardItem } from "./Leaderboard";
import { fetchReferralLeaderboard } from "./actions";
import { push } from "connected-react-router";
import { coinToDollars, formatNumber } from "../util";
import SimpleNavbar from "../components/SimpleNavbar";
import NavButton from "../components/NavButton";
import CoinSummary from "../components/CoinSummary";
import ReferralLeaderboardCell from "./ReferralLeaderboardCell";
import FullscreenLoader from "../components/FullscreenLoader";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";

const List = styled.div`
    padding: 10px 15px 15px;
`;

interface Props {
    referralLeaderboard: ReferralLeaderboardItem[];
    fetchReferralLeaderboard: () => void;
    push: (path: string) => void;
}

interface State {
    maxItems: number;
}

const ReferralLeaderboard = ({
    referralLeaderboard,
    fetchReferralLeaderboard,
    push
}: Props) => {
    const [maxItems, setMaxItems] = useState(25);
    const handleScroll = () => {
        const innerHeight = window.innerHeight;
        const scrollTopBody = document.getElementsByTagName("body")[0]
            .scrollTop;
        const scrollTopDoc = document.documentElement.scrollTop;
        const scrollTop = Math.max(scrollTopBody, scrollTopDoc) + 50;
        const offsetHeight = document.documentElement.offsetHeight;
        if (
            0 < referralLeaderboard.length &&
            maxItems < referralLeaderboard.length &&
            innerHeight + scrollTop >= offsetHeight &&
            document.body.style.position !== "fixed"
        ) {
            setMaxItems(maxItems + 3);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        fetchReferralLeaderboard();
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const leftNavButton = <NavButton onClick={() => push("/analytics")} back />;

    const endList = Math.min(maxItems, referralLeaderboard.length);

    const totalCoins =
        Math.round(
            referralLeaderboard.reduce((a, b) => {
                return a + b.earned_plugcoins;
            }, 0) * 10
        ) / 10;

    if (referralLeaderboard.length === 0) {
        return (
            <>
                <Navbar
                    marginBottom={10}
                    customMobileNavbar={<SimpleNavbar left={leftNavButton} />}
                />
                <FullscreenLoader />
            </>
        );
    } else {
        return (
            <>
                <Navbar
                    marginBottom={10}
                    customMobileNavbar={<SimpleNavbar left={leftNavButton} />}
                />

                <MainContainer>
                    <CoinSummary
                        topLeft={"Total Referral Earnings"}
                        topRight={formatNumber(coinToDollars(totalCoins))}
                        bottomLeft={"All Time"}
                        bottomRight={""}
                    />
                    <List>
                        {referralLeaderboard
                            .slice(0, endList)
                            .map((leaderboardItem, idx) => {
                                return (
                                    <ReferralLeaderboardCell
                                        key={idx}
                                        leaderboardItem={leaderboardItem}
                                        index={idx}
                                    />
                                );
                            })}
                    </List>
                </MainContainer>
            </>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    referralLeaderboard: state.analytics.referralLeaderboard
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchReferralLeaderboard: () => dispatch(fetchReferralLeaderboard()),
    push: (path: string) => dispatch(push(path))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReferralLeaderboard);
