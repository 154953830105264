import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../constants";

const Main = styled.div`
    padding: 10px 15px;
`;

const VerticalCenter = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;

const Title = styled(VerticalCenter)`
    font-size: 20px;
    justify-content: space-between;

    @media screen and (max-width: 360px) {
        font-size: 16px;
    }
`;

const SmallLogo = styled.img`
    height: 24px;
    width: 24px;
    margin-right: 6px;
`;

const Subtitle = styled(VerticalCenter)`
    font-size: ${fonts.subtitle}px;
    color: ${colors.secondaryGray};
    justify-content: space-between;
`;

interface Props {
    topLeft: string;
    topRight: string;
    bottomLeft: string | JSX.Element;
    bottomRight: string;
}

const CoinSummary = ({ topLeft, topRight, bottomLeft, bottomRight }: Props) => {
    return (
        <Main>
            <Title>
                <div>{topLeft}</div>
                <VerticalCenter>
                    <SmallLogo src={"/logo.svg"} />
                    <div>{topRight}</div>
                </VerticalCenter>
            </Title>
            <Subtitle>
                <div>{bottomLeft}</div>
                <div>{bottomRight}</div>
            </Subtitle>
        </Main>
    );
};

export default CoinSummary;
