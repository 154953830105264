import { Action, NormalizedAction } from "../../types";
import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../../reducers";

export const FETCH_CAMPAIGN_MEDIA_RULES = {
    REQUEST: "FETCH_CAMPAIGN_MEDIA_RULES_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_MEDIA_RULES_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_MEDIA_RULES_FAILURE"
};

export const fetchCampaignMediaRules = (campaignId: number): Action => ({
    type: FETCH_CAMPAIGN_MEDIA_RULES.REQUEST,
    payload: { campaignId }
});

export const fetchCampaignMediaRulesSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { campaignId: number }
): NormalizedAction => ({
    type: FETCH_CAMPAIGN_MEDIA_RULES.SUCCESS,
    response,
    payload
});

export const fetchCampaignMediaRulesFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_MEDIA_RULES.FAILURE,
    payload: { error }
});
