import { Action } from "src/types";
import { SET_CURRENT_FLOW_STEP } from "./actions";

export interface FlowsUIState {
    introToDirectBuy: number;
}

const initialFlowsUIState = {
    introToDirectBuy: 0
};

const flowsUIReducer = (state = initialFlowsUIState, action: Action) => {
    switch (action.type) {
        case SET_CURRENT_FLOW_STEP:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default flowsUIReducer;
