import React, { useState } from "react";
import { Channel } from "../types";
import styled from "styled-components";
import ChannelPreview from "./ChannelPreview";
import SearchBar from "../../components/SearchBar";
import PaddingAround from "../../components/PaddingAround";
import { colors } from "../../constants";
import ChannelPreviewWrapper from "../components/ChannelPreviewWrapper";
import CreateButton from "../components/CreateButton";
import SearchWrapper from "../components/SearchWrapper";

const Description = styled.div`
    font-size: 12px;
    line-height: 17px;
    text-align: left;
    padding: 0 15px 5px;
    color: ${colors.primaryGray};
`;

interface Props {
    channels: Channel[];
    userId: number;
    push: (route: string) => void;
}

const RequestsTab = ({ channels, userId, push }: Props) => {
    const [searchString, setSearchString] = useState("");

    return (
        <>
            <SearchWrapper>
                <SearchBar
                    searchString={searchString}
                    updateSearchString={setSearchString}
                />
            </SearchWrapper>

            <CreateButton />

            <Description>
                This is where you’ll see messages from users who aren’t
                connected with you. They won’t know you’ve seen the request
                unless you respond.
            </Description>

            <ChannelPreviewWrapper>
                {channels
                    .filter(channel => {
                        if (searchString.length === 0) {
                            return true;
                        } else {
                            return (
                                channel.sendBirdData.name
                                    .toLowerCase()
                                    .includes(searchString.toLowerCase()) ||
                                channel.sendBirdData.members.some(
                                    (member: any) => {
                                        return (
                                            member.userId !== `p:${userId}` &&
                                            member.nickname
                                                .toLowerCase()
                                                .includes(
                                                    searchString.toLowerCase()
                                                )
                                        );
                                    }
                                )
                            );
                        }
                    })
                    .map(channel => {
                        return (
                            <ChannelPreview
                                key={channel.sendBirdData.url}
                                userId={userId}
                                channel={channel}
                                push={push}
                            />
                        );
                    })}
            </ChannelPreviewWrapper>
        </>
    );
};

export default RequestsTab;
