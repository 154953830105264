import { useSelector } from "react-redux";
import { GlobalState } from "../../../reducers";
import { initialPricing, PricingParams } from "../modals/OfferPricingModal";
import {
    initialOfferType,
    OfferTypeParams
} from "../modals/OfferTypeModalController";
import { selectPublisherOffer } from "../selectors";

const useInitialParams = (publisherOfferId: number | null) => {
    const publisherOffer = useSelector((state: GlobalState) =>
        selectPublisherOffer(state, publisherOfferId)
    );
    if (!publisherOffer)
        return { initialPricing, initialOfferType, initialAccountId: null };

    const publisherOfferPost = publisherOffer.publisherOfferPosts[0];
    const payment = publisherOfferPost.publisherOfferPayments[0];

    let paymentType: null | string = null;
    switch (payment.payment?.type) {
        case "flat_rate":
            paymentType = "Flat Rate";
            break;
        case "dollars_per_k":
            paymentType = "$ per 1k Views";
            break;
        default:
            break;
    }

    const pricing: PricingParams = {
        paymentId: payment.payment?.id || null,
        paymentType,
        customType: payment.customType || "",
        amount: payment.amount
    };

    const offerType: OfferTypeParams = {
        placementId: publisherOfferPost.placement?.id || null,
        placementType: publisherOfferPost.placement?.type || null,
        permanent: publisherOfferPost.permanent,
        duration: publisherOfferPost.duration,
        customType: publisherOfferPost.customType || ""
    };

    const accountId = publisherOfferPost.socialAccountId;

    return {
        initialPricing: pricing,
        initialOfferType: offerType,
        initialAccountId: accountId
    };
};

export default useInitialParams;
