import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useState,
    useRef
} from "react";
import styled from "styled-components";
import { CampaignFilters } from "src/campaigns/types";

const RangeContainer = styled.div`
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const RangePoint = styled.input`
    pointer-events: none;
    position: absolute;
    height: 0;
    width: ${window.innerWidth <= 500 ? "100%" : "464px"};
    outline: none;
    z-index: 3;
    -webkit-appearance: none;
    &&::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-color: #000000;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        height: 16px;
        width: 16px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }
    &&::-moz-range-thumb {
        background-color: #000000;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        height: 16px;
        width: 16px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }
`;

const Slider = styled.div`
    position: relative;
    width: 100%;
`;

const CommonDiv = styled.div`
    position: absolute;
    border-radius: 1px;
    height: 3px;
`;

const SliderTrack = styled(CommonDiv)`
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
`;

const SliderRange = styled(CommonDiv)`
    background-color: #000000;
    z-index: 2;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const SliderValueLabel = styled.div`
    color: #dee2e6;
    font-size: 11px;
    background-color: #000000;
    border-radius: 3px;
    padding: 5px 10px;
`;

interface Props {
    min: number;
    max: number;
    filters: CampaignFilters;
    handleBidChange: (key: "bidLow" | "bidHigh", num: number) => void;
}

const BidPriceSlider = ({ min, max, handleBidChange, filters }: Props) => {
    const { bidLow, bidHigh } = filters;
    const [minVal, setMinVal] = useState(bidLow);
    const [maxVal, setMaxVal] = useState(bidHigh);

    const minValRef = useRef(minVal);
    const maxValRef = useRef(maxVal);
    const range = useRef<HTMLDivElement>(null);

    // Convert to percentage
    const getPercent = useCallback(
        (value: number) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    useEffect(() => {
        if (minVal !== bidLow) setMinVal(bidLow);
        if (maxVal !== bidHigh) setMaxVal(bidHigh);
    }, [bidLow, bidHigh]);

    // Set width of the range to decrease from the left side
    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxValRef.current);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxVal);

        if (range.current) {
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, maxVal, getPercent]);

    const handleChange = (key: "bidLow" | "bidHigh") => {
        return (e: ChangeEvent<HTMLInputElement>) => {
            const value = Number(e.target.value);
            if (key === "bidLow") {
                setMinVal(value);
                minValRef.current = value;
                handleBidChange("bidLow", value);
            } else {
                setMaxVal(value);
                maxValRef.current = value;
                handleBidChange("bidHigh", value);
            }
        };
    };

    return (
        <>
            <LabelContainer>
                <SliderValueLabel>
                    ${bidLow == 0 ? "0.01" : `${bidLow}`}
                </SliderValueLabel>
                <SliderValueLabel>
                    {bidHigh > 9.7 ? "Max" : `$${bidHigh}`}
                </SliderValueLabel>
            </LabelContainer>

            <RangeContainer>
                <RangePoint
                    type="range"
                    min={min}
                    max={max}
                    value={bidLow}
                    step="0.01"
                    onChange={handleChange("bidLow")}
                />
                <RangePoint
                    type="range"
                    min={min}
                    max={max}
                    value={bidHigh}
                    step="0.01"
                    onChange={handleChange("bidHigh")}
                />

                <Slider>
                    <SliderTrack />
                    <SliderRange ref={range} />
                </Slider>
            </RangeContainer>
        </>
    );
};

export default BidPriceSlider;
