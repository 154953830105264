import styled from "styled-components";

export default styled.input`
    border: none;
    outline: none;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
    min-height: 24px;
    margin: 30px 0;
    width: 100%;
`;
