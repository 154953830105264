import React from "react";
import { Media } from "../types";
import { MediaTabState } from "../../campaigns/components/MediaSelectorTabs";
import { colors, fonts } from "../../constants";
import styled from "styled-components";

const OuterBadge = styled.div`
    position: absolute;
    top: 4px;
    left: 4px;
    height: 14px;
    width: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    background-color: white;
    pointer-events: none;
`;

const InnerBadge = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 100%;
`;

const InfoBadge = styled(OuterBadge)`
    left: auto;
    right: 4px;
    width: 20px;
    border-radius: 6px;
    color: ${colors.primaryGray};
    font-size: ${fonts.smallSubtitle}px;
    line-height: 1em;
    pointer-events: auto;
    cursor: pointer;
`;

const Description = styled.div`
    position: absolute;
    bottom: 4px;
    right: 4px;
    padding: 0px 4px;
    background-color: white;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    color: ${colors.primaryGray};
    font-size: ${fonts.smallSubtitle}px;
    text-align: center;
    text-transform: uppercase;
    pointer-events: none;
`;

interface Props {
    media: Media;
    customReasonCallback: VoidFunction;
}

const CustomMediaBadges = ({ media, customReasonCallback }: Props) => {
    function renderCustomText() {
        if (media.mediaStatus === "approved" && media.doNotPayCommission) {
            return <Description>NO COMMISSION</Description>;
        } else {
            return <Description>{media.mediaStatus}</Description>;
        }
    }

    function renderCustomBadge() {
        if (media.mediaStatus === "pending") {
            return (
                <OuterBadge>
                    <InnerBadge
                        style={{ backgroundColor: colors.primaryYellow }}
                    />
                </OuterBadge>
            );
        } else if (media.mediaStatus === "rejected") {
            return (
                <OuterBadge>
                    <InnerBadge
                        style={{ backgroundColor: colors.primaryRed }}
                    />
                </OuterBadge>
            );
        } else if (media.mediaStatus === "approved") {
            return (
                <OuterBadge>
                    <InnerBadge
                        style={{ backgroundColor: colors.primaryGreen }}
                    />
                </OuterBadge>
            );
        }
    }

    function renderCustomInfo() {
        if (
            media.mediaStatus === "rejected" ||
            media.mediaStatus === "approved"
        ) {
            return (
                <InfoBadge
                    onClick={e => {
                        e.stopPropagation();
                        customReasonCallback();
                    }}
                >
                    <div>?</div>
                </InfoBadge>
            );
        }
    }

    return (
        <>
            {renderCustomText()}
            {renderCustomBadge()}
            {renderCustomInfo()}
        </>
    );
};

export default CustomMediaBadges;
