import React from "react";
import styled from "styled-components";
import { colors } from "../../../constants";
import { BlueButton, OutlineButton } from "../../../profile/components/Buttons";

const Wrapper = styled.div`
    width: 100%;
    padding: 24px 12px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 0px;
    background-color: white;
`;
const Counter = styled.p`
    font-size: 1rem;
    color: ${colors.primaryGray};
    display: block;
    margin: 0px;
`;
const SubmitBtn = styled(BlueButton)`
    display: inline-block;
    padding: 8px 12px;
`;

const AddFileFooter = ({
    btnText,
    count,
    handleSubmit,
    disabled
}: {
    btnText: string;
    count: number;
    handleSubmit: () => void;
    disabled: boolean;
}) => {
    return (
        <Wrapper>
            <Counter>{`${count}`} UPLOADED</Counter>
            <div>
                <SubmitBtn onClick={handleSubmit} disabled={disabled}>
                    {btnText}
                </SubmitBtn>
            </div>
        </Wrapper>
    );
};

export default AddFileFooter;
