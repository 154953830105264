import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../reducers";
import { clearModal } from "../../modals/actions";
import styled from "styled-components";
import Modal from "../../modals/Modal";
import ModalButton from "../../modals/ModalButton";
// @ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import { addNotification } from "../../ui/notifications/actions";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Description = styled.div`
    font-size: ${fonts.subtitle}px;
    padding-top: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
`;

interface Props {
    pluglink: string | null;
    clearModal: () => void;
    addNotification: (text: string) => void;
}

const PlugLinkModal = ({ pluglink, clearModal, addNotification }: Props) => {
    return (
        <Modal>
            <Main>
                <Title>Copy This Link</Title>
                <Description>{pluglink}</Description>
            </Main>
            <CopyToClipboard
                text={pluglink}
                onCopy={() => {
                    clearModal();
                    addNotification(`Copied link: ${pluglink}`);
                }}
            >
                <ModalButton text={"Copy"} />
            </CopyToClipboard>
            <ModalButton text={"Close"} />
        </Modal>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    pluglink: state.links.currentPlugLink
});

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal()),
    addNotification: (text: string) => dispatch(addNotification(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlugLinkModal);
