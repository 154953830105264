import { push } from "connected-react-router";
import React from "react";
import { connect } from "react-redux";
import { clearModal } from "../../modals/actions";
import EmojiModal from "../../modals/EmojiModal";
import { allowCashout } from "../../util";
import { updateUserFields } from "../actions";
import { BlueModalButton } from "../components/Buttons";
import { Profile } from "../types";
import { Section, Title } from "./styledComponents";
import TipaltiEmailModal from "./cashout/TipaltiEmailModal";

interface Props {
    profile: Profile;
    clearModal: () => void;
    push: (path: string) => void;
    setting?: boolean;
}

const PaymentModal = ({ profile, clearModal, push, setting }: Props) => {
    // early returns
    if (!setting && profile.balance < 399) {
        return (
            <EmojiModal emoji="💸">
                <Title>Balance Too Low</Title>
                <Section>
                    Your balance must be greater than $20 to cash out.
                </Section>
                <BlueModalButton onClick={clearModal}>Close</BlueModalButton>
            </EmojiModal>
        );
    } else if (!setting && !allowCashout(profile)) {
        return (
            <EmojiModal emoji="✋">
                <Title>You can only cashout once per day.</Title>
                <Section>You've cashed out within the past 24 hours.</Section>
                <BlueModalButton onClick={clearModal}>Close</BlueModalButton>
            </EmojiModal>
        );
    }

    // success
    if (!setting && profile.tipaltiPayeePayable) {
        push("/profile/cashout");
        clearModal();
        return null;
    }

    return (
        <TipaltiEmailModal
            initialEmail={profile.email}
            next={clearModal}
            userExists={
                profile.tipaltiPayeeExists && !profile.tipaltiPayeePayable
            }
            overThreshold
        />
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal()),
    push: (route: string) => dispatch(push(route)),
    updateUserFields: (fields: { [key: string]: any }) =>
        dispatch(updateUserFields(fields))
});

export default connect(null, mapDispatchToProps)(PaymentModal);
