import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Video = styled.video`
    cursor: pointer;
    display: block;
`;

const Photo = styled.img`
    display: block;
`;

const PlayScreen = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    font-size: 30px;
    pointer-events: none;
`;

interface Props {
    mediaType: string;
    mediaUrl: string;
    coverUrl?: string | null;
    style?: any;
    playable: boolean;
}

const MediaContent = ({
    mediaType,
    mediaUrl,
    coverUrl,
    style,
    playable
}: Props) => {
    const [play, setPlay] = useState(false);

    if (!playable && coverUrl && coverUrl.length > 0 && mediaType === "video") {
        return (
            <>
                <Photo style={style} src={coverUrl} />
                <PlayScreen style={play ? { display: "none" } : undefined}>
                    <FontAwesomeIcon icon={["fas", "play"]} />
                </PlayScreen>
            </>
        );
    }

    switch (mediaType) {
        case "video":
            return (
                <>
                    <Video
                        playsInline={true}
                        style={style}
                        src={mediaUrl}
                        poster={coverUrl || undefined}
                        onClick={e => {
                            if (playable) {
                                if (!play) {
                                    setPlay(true);
                                }
                                const target = e.target as HTMLVideoElement;
                                target.paused ? target.play() : target.pause();
                            }
                        }}
                    />
                    <PlayScreen style={play ? { display: "none" } : undefined}>
                        <FontAwesomeIcon icon={["fas", "play"]} />
                    </PlayScreen>
                </>
            );
        case "photo":
            return <Photo style={style} src={mediaUrl} />;
        default:
            return <img alt={"could not find media"} />;
    }
};

export default MediaContent;
