import React, { useState } from "react";
import { Search } from "src/components/icons/exports";
import { colors, shadows } from "src/constants";
import styled from "styled-components";
import { EventInput } from "src/utils/types/form";
import { debouncedSetUserInput } from "src/utils/functions/helpers";

interface MainProps {
    focus: boolean;
    selectionOutline?: true;
    backgroundColor?: string;
    margin?: string;
}

const Main = styled.div<MainProps>`
    display: flex;
    align-items: center;
    gap: 6px;
    margin: ${props => props.margin || "0px"};
    padding: 8px 12px;
    color: ${colors.black};
    background-color: ${props => props.backgroundColor || colors.white};
    border-radius: 6px;
    font-size: 14px;
    transition: 0.2s;
    box-shadow: ${props => {
        return props.focus && props.selectionOutline && shadows.inputSelected;
    }};
`;

const InputField = styled.input`
    width: 100%;
    padding-right: 6px;
    background-color: ${colors.transparent};
    border: none;
    outline: none;

    ::placeholder {
        color: ${colors.primaryGray};
    }
`;

interface Props {
    setSearchString: React.Dispatch<React.SetStateAction<string>>;
    selectionOutline?: true;
    backgroundColor?: string;
    margin?: string;
    className?: string;
}

const InstructionsSearchBar = ({
    setSearchString,
    selectionOutline,
    backgroundColor,
    margin,
    className
}: Props) => {
    const [focus, setFocus] = useState(false);

    function handleInputChange(event: EventInput): void {
        const userInput = event.currentTarget.value;
        debouncedSetUserInput(setSearchString, userInput);
    }

    return (
        <Main
            focus={focus}
            selectionOutline={selectionOutline}
            backgroundColor={backgroundColor}
            margin={margin}
            className={className}
        >
            <Search height={12} fill={colors.primaryGray} />

            <InputField
                placeholder="Search"
                onChange={handleInputChange}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
            />
        </Main>
    );
};

export default InstructionsSearchBar;
