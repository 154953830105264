import React, { useEffect, useState } from "react";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Campaign, Script } from "src/campaigns/types";
import { colors, fonts, shadows } from "src/constants";
import styled from "styled-components";
import { DropdownTitle, UploadSubmissionType } from "../../utils/types";
import CreateDropdownItem from "../components/CreateDropdownItem";
import { GlobalState } from "src/reducers";
import {
    openInstructionElementCarouselModal,
    setInstructionElementCarouselItemIds
} from "src/ui/instructions-sets/actions";
import { uploadSubmissionDropdownTypes } from "./instructions-sets";
import UploadSubmissionItem from "../components/UploadSubmissionItem";
import { addScript } from "src/campaigns/scripts/actions";
import { campaignScriptModal } from "src/ui/scripts/actions";
import { selectCampaign } from "src/campaigns/selectors";
import { ScriptsEntitiesState } from "src/campaigns/scripts/reducer";
import { MediasEntitiesState } from "src/campaigns/medias/reducer";
import { AssetsEntitiesState } from "src/campaigns/assets/reducer";

const DraggableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
    overflow: hidden;
    transition: 0.1s ease-in;
    cursor: grab;

    @media (hover: hover) {
        :hover {
            border: ${colors.darkTeal} solid 1px;
            transform: translateY(-2px);
            box-shadow: ${shadows.one};
        }
    }
`;

export function useGenerateItemsForCreateDropdown(
    title: DropdownTitle,
    searchString: string,
    scripts: ScriptsEntitiesState,
    medias: MediasEntitiesState,
    assets: AssetsEntitiesState,
    currentCampaign: Campaign | undefined
) {
    const dispatch = useDispatch();
    function handleOnClick(
        filteredItems: number[],
        itemPosition: number,
        script?: Script,
        scriptId?: number
    ): void {
        dispatch(openInstructionElementCarouselModal(true));
        const firstHalf = filteredItems.slice(itemPosition);
        const secondHalf = filteredItems.slice(0, itemPosition);
        const carouselItemIds = firstHalf.concat(secondHalf);
        dispatch(setInstructionElementCarouselItemIds(carouselItemIds));

        if (!!script && !!scriptId) {
            dispatch(addScript(script));
            dispatch(campaignScriptModal(scriptId));
        }
    }

    const [dropdownItems, setDropdownItems] = useState<
        JSX.Element | JSX.Element[]
    >(<></>);

    useEffect(() => {
        // Rendering Script Dropdown Items -------------------------------------------------------------------
        if (title === "Scripts") {
            const scriptIds = currentCampaign?.scripts;

            if (!!scriptIds) {
                const filteredCampaignScriptIds = scriptIds.filter(
                    (scriptId: number) => {
                        const script = scripts[scriptId];
                        return script.id
                            .toString()
                            .toLowerCase()
                            .includes(searchString.toLowerCase());
                    }
                );

                const scriptDropdownItems = filteredCampaignScriptIds.map(
                    (scriptId: number, index: number) => {
                        const script = scripts[scriptId];
                        return (
                            <Draggable
                                key={`hooks-create-instructions-script-${script.id}`}
                                draggableId={`${title}-${script.id}`}
                                index={index}
                            >
                                {(provided: DraggableProvided) => (
                                    <DraggableWrapper
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <CreateDropdownItem
                                            onClick={() =>
                                                handleOnClick(
                                                    filteredCampaignScriptIds,
                                                    index,
                                                    script,
                                                    scriptId
                                                )
                                            }
                                            id={scriptId}
                                            type="Script"
                                            scriptTitle={script.title}
                                            scriptText={script.text}
                                        />
                                    </DraggableWrapper>
                                )}
                            </Draggable>
                        );
                    }
                );
                setDropdownItems(scriptDropdownItems);
            }
        }

        // Rendering Media Dropdown Items -------------------------------------------------------------------
        else if (title === "Media") {
            const mediaIds = currentCampaign?.medias;
            const currentCampaignScripts = currentCampaign?.scripts;

            if (!!mediaIds) {
                let associatedScriptMediaIds = mediaIds;

                // Creating a combined array of media ids associated with a campaign and media ids
                // associated with any script that was dragged into an instruction step.
                if (!!currentCampaignScripts) {
                    for (
                        let index = 0;
                        index < currentCampaignScripts.length;
                        index++
                    ) {
                        const scriptId = currentCampaignScripts[index];
                        const currentScript = scripts[scriptId];
                        const mediaIds = currentScript.medias;
                        if (mediaIds) {
                            associatedScriptMediaIds = associatedScriptMediaIds.concat(
                                mediaIds
                            );
                        }
                    }

                    associatedScriptMediaIds = Array.from(
                        new Set([...associatedScriptMediaIds, ...mediaIds])
                    );
                }

                const filteredCampaignMediaIds = associatedScriptMediaIds.filter(
                    (mediaId: number) => {
                        const media = medias[mediaId];
                        return media.id
                            .toString()
                            .toLowerCase()
                            .includes(searchString.toLowerCase());
                    }
                );

                const mediaDropdownItems = filteredCampaignMediaIds.map(
                    (mediaId: number, index: number) => {
                        const media = medias[mediaId];
                        return (
                            <Draggable
                                key={`hooks-create-instructions-media-${media.id}`}
                                draggableId={`${title}-${media.id}`}
                                index={index}
                            >
                                {(provided: DraggableProvided) => (
                                    <DraggableWrapper
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <CreateDropdownItem
                                            onClick={() =>
                                                handleOnClick(
                                                    filteredCampaignMediaIds,
                                                    index
                                                )
                                            }
                                            id={mediaId}
                                            type="Media"
                                            campaignName={
                                                currentCampaign?.campaignName ||
                                                ""
                                            }
                                            coverPhotoUrl={media.coverPhotoUrl}
                                        />
                                    </DraggableWrapper>
                                )}
                            </Draggable>
                        );
                    }
                );
                setDropdownItems(mediaDropdownItems);
            }
        }

        // Rendering Assets Dropdown Items -------------------------------------------------------------------
        else if (title === "Assets") {
            const assetIds = currentCampaign?.assets;
            const currentCampaignScripts = currentCampaign?.scripts;

            // Creating a combined array of asset ids associated with a campaign and asset ids
            // associated with any script that was dragged into an instruction step.
            // let associatedScriptAssetsIds: number[] = [];
            // if (!!assetIds && !!currentCampaignScripts) {
            //     for (
            //         let index = 0;
            //         index < currentCampaignScripts.length;
            //         index++
            //     ) {
            //         const scriptId = currentCampaignScripts[index];
            //         const currentScript = scripts[scriptId];
            //         const assetIds = currentScript.assets;
            //         if (assetIds) {
            //             associatedScriptAssetsIds = associatedScriptAssetsIds.concat(
            //                 assetIds
            //             );
            //         }
            //     }
            //     associatedScriptAssetsIds = Array.from(
            //         new Set([...associatedScriptAssetsIds, ...assetIds])
            //     );
            // }

            if (!!assetIds) {
                const associatedScriptAssetsIds = assetIds;

                if (!!currentCampaignScripts) {
                    for (
                        let index = 0;
                        index < currentCampaignScripts.length;
                        index++
                    ) {
                        const scriptId = currentCampaignScripts[index];
                        const currentScript = scripts[scriptId];
                        const assetIds = currentScript.assets;
                        if (assetIds) {
                            assetIds.forEach((assetId: number) => {
                                if (
                                    !associatedScriptAssetsIds.includes(assetId)
                                ) {
                                    associatedScriptAssetsIds.push(assetId);
                                }
                            });
                        }
                    }
                }

                const filteredCampaignAssetIds = associatedScriptAssetsIds.filter(
                    (assetId: number) => {
                        const asset = assets[assetId];
                        return asset.id
                            .toString()
                            .toLowerCase()
                            .includes(searchString.toLowerCase());
                    }
                );

                const assetsDropdownItems = filteredCampaignAssetIds.map(
                    (assetId: number, index: number) => {
                        const asset = assets[assetId];
                        return (
                            <Draggable
                                key={`hooks-create-instructions-asset-${asset.id}`}
                                draggableId={`${title}-${asset.id}`}
                                index={index}
                            >
                                {(provided: DraggableProvided) => (
                                    <DraggableWrapper
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <CreateDropdownItem
                                            onClick={() =>
                                                handleOnClick(
                                                    filteredCampaignAssetIds,
                                                    index
                                                )
                                            }
                                            id={assetId}
                                            type="Asset"
                                            campaignName={
                                                currentCampaign?.campaignName ||
                                                ""
                                            }
                                            coverPhotoUrl={asset.coverPhotoUrl}
                                        />
                                    </DraggableWrapper>
                                )}
                            </Draggable>
                        );
                    }
                );

                setDropdownItems(assetsDropdownItems);
            }
        }

        // Rendering Links Dropdown Items -------------------------------------------------------------------
        else if (title === "Links") {
            const linkDropdownItems = (
                <>
                    <Draggable
                        key={`hooks-create-instructions-custom-link`}
                        draggableId={`Link-${0}`}
                        index={0}
                    >
                        {(provided: DraggableProvided) => (
                            <DraggableWrapper
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <UploadSubmissionItem
                                    title="Link"
                                    text="Drag to require custom link."
                                />
                            </DraggableWrapper>
                        )}
                    </Draggable>
                    <Draggable
                        key={`hooks-create-instructions-plug-link`}
                        draggableId={`Link-${1}`}
                        index={1}
                    >
                        {(provided: DraggableProvided) => (
                            <DraggableWrapper
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <UploadSubmissionItem
                                    title="Link"
                                    text="Drag to require Plug Link."
                                />
                            </DraggableWrapper>
                        )}
                    </Draggable>
                </>
            );
            setDropdownItems(linkDropdownItems);
        }

        // Rendering Upload Submissions Dropdown Items -------------------------------------------------------------------
        else {
            const uploadSubmissionsDropdownItems = uploadSubmissionDropdownTypes.map(
                (uploadSubmission: UploadSubmissionType, index: number) => {
                    return (
                        <Draggable
                            key={`hooks-create-instructions-asset-${uploadSubmission}`}
                            draggableId={`${uploadSubmission}-${index}`}
                            index={index}
                        >
                            {(provided: DraggableProvided) => (
                                <DraggableWrapper
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <UploadSubmissionItem
                                        title={uploadSubmission}
                                    />
                                </DraggableWrapper>
                            )}
                        </Draggable>
                    );
                }
            );
            setDropdownItems(uploadSubmissionsDropdownItems);
        }
    }, [title, searchString, scripts, medias, assets, currentCampaign]);

    return dropdownItems;
}
