import { Action } from "src/types";
import { FETCH_ELASTIC_SEARCH, FETCH_MORE_SEARCH_HITS } from "./actions";
import { GenericHit } from "./types";

export interface ElasticSearchState {
    [key: string]: GenericHit[];
}

const initialElasticSearchState: ElasticSearchState = {};

const getNewState = (state: ElasticSearchState, action: Action) => {
    const indices = action.payload.indices;
    const newState: ElasticSearchState = {};

    indices.forEach((indexName: string, idx: number) => {
        newState[indexName] = action.payload.queryResults[idx].hits;
    });

    return { ...state, ...newState };
};

const updateState = (state: ElasticSearchState, action: Action) => {
    const indexName = action.payload.index;
    const indexHits = state[indexName];
    return {
        ...state,
        [indexName]: [...indexHits, ...action.payload.queryResults.hits]
    };
};

const elasticSearchReducer = (
    state = initialElasticSearchState,
    action: Action
) => {
    switch (action.type) {
        case FETCH_ELASTIC_SEARCH.SUCCESS:
            return getNewState(state, action);
        case FETCH_MORE_SEARCH_HITS.SUCCESS:
            return updateState(state, action);
        default:
            return state;
    }
};

export default elasticSearchReducer;
