import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { replace } from "connected-react-router";
import Tab from "src/components/Tab";
import DetailTabs from "src/campaigns/details/DetailTabs";
import { GlobalState } from "src/reducers";
import { DetailsTabState } from "src/campaigns/details/components/AuthedDetailsPage";
import { colors, maxWidthDesktop } from "src/constants";

const Main = styled("div")`
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    margin: 0px auto;
`;

const Tabs = styled("div")`
    display: flex;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${maxWidthDesktop}px) {
        border-right: ${colors.transparent} solid 1px;
        border-left: ${colors.transparent} solid 1px;
        border-radius: 0px;
    }
`;

interface Props {
    currentTab: DetailsTabState;
}

const DetailsMain = (props: Props) => {
    const { currentTab } = props;

    const currentCampaign = useSelector((state: GlobalState) => {
        return state.ui.campaign.currentCampaignId;
    });

    const dispatch = useDispatch();
    function replaceRoute(route: string): void {
        const newRoute = `/campaign/${currentCampaign}/${route}`;
        dispatch(replace(newRoute));
    }

    return (
        <Main>
            <Tabs>
                <Tab
                    name={"Promote"}
                    active={currentTab === DetailsTabState.promote}
                    onClick={() => {
                        replaceRoute("promote");
                    }}
                />

                <Tab
                    name={"Payment"}
                    active={currentTab === DetailsTabState.payment}
                    onClick={() => {
                        replaceRoute("payment");
                    }}
                />

                <Tab
                    name={"Details"}
                    active={currentTab === DetailsTabState.details}
                    onClick={() => {
                        replaceRoute("details");
                    }}
                />
            </Tabs>

            <DetailTabs currentTab={currentTab} />
        </Main>
    );
};

export default DetailsMain;
