import { schema } from "normalizr";

const directBuy = new schema.Entity("directBuy");
const directBuyStep = new schema.Entity("directBuyStep");

directBuy.define({
    directBuySteps: [directBuyStep]
});

directBuyStep.define({
    directBuySteps: [directBuyStep]
});

export const directBuyList = new schema.Array(directBuy);

//open buys
const openBuySchema = new schema.Entity("openBuys");
const openBuySubmissionSetSchema = new schema.Entity("openBuySubmissionSets");
const instructionSteps = new schema.Entity("instructionSteps");
const submissions = new schema.Entity("openBuySubmissions");
const openBuyLeaderboardReports = new schema.Entity(
    "openBuyLeaderboardReports"
);

openBuySchema.define({
    instructionSteps: [instructionSteps],
    openBuySubmissions: [submissions]
});

instructionSteps.define({
    instructionSteps: [instructionSteps]
});

export const openBuyList = new schema.Array(openBuySchema);
export const openBuySubmissionSetList = new schema.Array(
    openBuySubmissionSetSchema
);
export const openBuyLeaderboard = new schema.Array(openBuyLeaderboardReports);
