import React, { useEffect, useRef, useState } from "react";
import ScrollableFeed from "react-scrollable-feed";
import styled from "styled-components";
import FullscreenLayout from "src/components/layout/FullscreenLayout";
import {
    FlexContent,
    FlexVerticallyCentered
} from "src/components/CommonStyles";
import SimpleNavbar from "src/components/SimpleNavbar";
import NavButton from "src/components/NavButton";
import { useDispatch, useSelector } from "react-redux";
import { goBack, push } from "connected-react-router";
import {
    getChatRoomInfo,
    isMemberOfInternalNotes,
    selectRequestStateWithId
} from "./selectors";
import { GlobalState } from "src/reducers";
import ProfilePhoto from "src/components/ProfilePhoto";
import ChatRoomFooter from "./components/ChatRoomFooter";
import MessageList from "./components/MessageList";
import { setChatUserOnlineStatus } from "./chat-profiles/actions";
import ChatRoomRequestFooter from "./components/ChatRoomRequestFooter";
import ChatRoomActionsModal from "./group-user-settings/modals/ChatRoomActionsModal";
import { AnimatePresence } from "framer-motion";
import GroupPauseNotificationsModal from "./group-user-settings/modals/GroupPauseNotificationsModal";
import useInternalRouting from "src/hooks/useInternalRouting";
import InternalLink from "src/components/buttons/InternalLink";
import { clearConversationUnreadCount } from "./conversation/actions";
import ChatRoomAdminJoinFooter from "./components/ChatRoomAdminJoinFooter";

const Main = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const TitleWrapper = styled.div<{ linkable: boolean }>`
    ${FlexVerticallyCentered}
    user-select: none;
    ${props => props.linkable && "cursor: pointer;"}
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-left: 4px;
`;

interface Props {
    conversationId: string;
}

const ChatRoom = ({ conversationId }: Props) => {
    const ref = useRef<ScrollableFeed>(null);
    const dispatch = useDispatch();
    const { goBackIfInternalRoute } = useInternalRouting("/newchat");
    const [manageModal, toggleManageModal] = useState(false);
    const [muteDurationModal, toggleMuteDurationModal] = useState(false);
    const info = useSelector((state: GlobalState) =>
        getChatRoomInfo(state, conversationId)
    );
    const requestStatus = useSelector((state: GlobalState) =>
        selectRequestStateWithId(state, conversationId)
    );
    const isMember = useSelector((state: GlobalState) =>
        isMemberOfInternalNotes(state, conversationId)
    );

    useEffect(() => {
        dispatch(setChatUserOnlineStatus(conversationId, true));
        return () => {
            dispatch(setChatUserOnlineStatus(conversationId, false));
        };
    }, []);

    useEffect(() => {
        dispatch(clearConversationUnreadCount(conversationId));
    }, []);

    function scrollToBottom() {
        ref?.current?.scrollToBottom();
    }

    return (
        <>
            <Main>
                {/* <SimpleNavbar
                    title={
                        <TitleWrapper
                            linkable={!!info?.userId}
                            onClick={() => {
                                info?.userId &&
                                    dispatch(
                                        push(`/public/${info?.userId}`, {
                                            internal: true
                                        })
                                    );
                            }}
                        >
                            <ProfilePhoto
                                profilePhotoUrl={info?.imageUrl || null}
                                photoSize={24}
                            />
                            <Title>{info?.displayName}</Title>
                        </TitleWrapper>
                    }
                    left={<NavButton back onClick={goBackIfInternalRoute} />}
                    right={
                        info?.isGroupChat ? (
                            <InternalLink
                                to={`/newchat/${conversationId}/settings`}
                            >
                                <img src="/info-icon.svg" />
                            </InternalLink>
                        ) : (
                            <NavButton
                                right
                                onClick={() => toggleManageModal(true)}
                            >
                                <img src="/ellipsis-button.svg" />
                            </NavButton>
                        )
                    }
                /> */}
                <FlexContent>
                    <MessageList
                        ref={ref}
                        conversationId={conversationId}
                        isGroupChat={info?.isGroupChat}
                    />
                </FlexContent>

                {requestStatus ? (
                    <ChatRoomRequestFooter
                        requestStatus={requestStatus}
                        usernames={info?.usernames}
                    />
                ) : !isMember ? (
                    <ChatRoomAdminJoinFooter conversationId={conversationId} />
                ) : (
                    <ChatRoomFooter
                        conversationId={conversationId}
                        scrollToBottom={scrollToBottom}
                    />
                )}
            </Main>
            <AnimatePresence>
                {manageModal && (
                    <ChatRoomActionsModal
                        conversationId={conversationId}
                        closeModal={() => toggleManageModal(false)}
                        toggleMuteDurationModal={() =>
                            toggleMuteDurationModal(true)
                        }
                        userId={info?.userId || ""}
                    />
                )}
                {muteDurationModal && (
                    <GroupPauseNotificationsModal
                        conversationId={conversationId}
                        closeModal={() => toggleMuteDurationModal(false)}
                    />
                )}
            </AnimatePresence>
        </>
    );
};

export default ChatRoom;
