import React from "react";
import { colors, fonts, shadows, sizes } from "src/constants";
import {
    clearSpacing,
    flexRowCenter,
    hideTextOverflow,
    preventUserInteraction
} from "src/utils/styles/snippets";
import styled from "styled-components";

interface MainProps {
    selected?: boolean;
    shadow?: true;
    disabled?: boolean;
}

const Main = styled.div<MainProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    /* height: ${sizes.marketplace.instructions.dropdownHeight}px; */
    height: ${sizes.selectionButton.height}px;
    padding: 0px 16px;
    background-color: ${props => {
        return props.selected ? colors.secondaryLightGray : colors.white;
    }};
    border-radius: 8px;
    box-shadow: ${props => props.selected && props.shadow && shadows.four};
    transition: 0.1s ease-in;
    cursor: pointer;

    ${props => props.disabled && preventUserInteraction()};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.secondaryLightGray};
        }
    }
`;

const Copy = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
`;

const Title = styled.h3`
    ${hideTextOverflow()};
    ${clearSpacing()};
    color: ${colors.black};
    font-size: ${fonts.subtitle}px;
    font-weight: 400;
`;

const Subtitle = styled.span`
    ${hideTextOverflow()};
    display: block;
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: 300;
`;

interface ArrowProps {
    selected: boolean;
    rotateArrow?: boolean;
}

const Arrow = styled.div<ArrowProps>`
    ${flexRowCenter()};
    transform: ${props => {
        return props.rotateArrow && props.selected
            ? "rotate(90deg)"
            : "rotate(0deg)";
    }};
    transition: 0.1s ease-in;
`;

const ArrowSelected = styled.img``;

const ArrowNotSelected = styled.img``;

interface Props {
    onClick: () => void;
    title: string;
    subtitle?: string;
    selected: boolean;
    shadow?: true;
    isDropdown?: true;
    noArrow?: true;
    disabled?: boolean;
}

const SelectionHeader = ({
    onClick,
    title,
    subtitle,
    selected,
    shadow,
    isDropdown,
    noArrow,
    disabled
}: Props) => {
    return (
        <Main
            onClick={onClick}
            selected={!!selected}
            shadow={shadow}
            disabled={disabled}
        >
            <Copy>
                <Title>{title}</Title>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </Copy>

            {!disabled && !noArrow && (
                <Arrow selected={!!selected} rotateArrow={isDropdown}>
                    {selected ? (
                        <ArrowSelected src="/caret-hovered.svg" />
                    ) : (
                        <ArrowNotSelected src="/caret-not-hovered.svg" />
                    )}
                </Arrow>
            )}
        </Main>
    );
};

export default SelectionHeader;
