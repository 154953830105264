import { Action } from "../../../types";
import { PaymentConjunction } from "../types";
import { OpenBuyPaymentUIState } from "./reducer";

export function addPendingOpenBuyPaymentsGroup(
    state: OpenBuyPaymentUIState,
    action: Action
): OpenBuyPaymentUIState {
    const { pendingOpenBuyPaymentsStore } = state;
    const { openBuyId, instructionStepId } = action.payload;
    const currentGroup = pendingOpenBuyPaymentsStore[
        openBuyId
    ].pendingOpenBuyPaymentsGroups.find(
        pendingOpenBuyPaymentsGroup =>
            pendingOpenBuyPaymentsGroup.instructionStepId === instructionStepId
    );
    if (currentGroup) {
        return {
            ...state,
            showEmpty: false
        };
    }
    return {
        ...state,
        showEmpty: false,
        pendingOpenBuyPaymentsStore: {
            ...pendingOpenBuyPaymentsStore,
            [openBuyId]: {
                ...pendingOpenBuyPaymentsStore[openBuyId],
                pendingOpenBuyPaymentsGroups: [
                    ...pendingOpenBuyPaymentsStore[openBuyId]
                        .pendingOpenBuyPaymentsGroups,
                    {
                        openBuyId,
                        instructionStepId,
                        paymentConjunction: PaymentConjunction.and,
                        base: [],
                        creatorSpecific: {}
                    }
                ]
            }
        }
    };
}

export function removePendingOpenBuyPaymentsGroup(
    state: OpenBuyPaymentUIState,
    action: Action
): OpenBuyPaymentUIState {
    const { pendingOpenBuyPaymentsStore } = state;
    const { openBuyId, instructionStepId } = action.payload;
    return {
        ...state,
        pendingOpenBuyPaymentsStore: {
            ...pendingOpenBuyPaymentsStore,
            [openBuyId]: {
                ...pendingOpenBuyPaymentsStore[openBuyId],
                pendingOpenBuyPaymentsGroups: pendingOpenBuyPaymentsStore[
                    openBuyId
                ].pendingOpenBuyPaymentsGroups.filter(
                    pendingOpenBuyPaymentsGroup =>
                        pendingOpenBuyPaymentsGroup.instructionStepId !==
                        instructionStepId
                )
            }
        }
    };
}

export function updatePendingOpenBuyPaymentsGroup(
    state: OpenBuyPaymentUIState,
    action: Action
): OpenBuyPaymentUIState {
    const { pendingOpenBuyPaymentsStore } = state;
    const {
        openBuyId,
        instructionStepId,
        paymentConjunction,
        payoutTiming
    } = action.payload;
    return {
        ...state,
        pendingOpenBuyPaymentsStore: {
            ...pendingOpenBuyPaymentsStore,
            [openBuyId]: {
                ...pendingOpenBuyPaymentsStore[openBuyId],
                pendingOpenBuyPaymentsGroups: pendingOpenBuyPaymentsStore[
                    openBuyId
                ].pendingOpenBuyPaymentsGroups.map(
                    pendingOpenBuyPaymentsGroup => {
                        if (
                            pendingOpenBuyPaymentsGroup.instructionStepId ===
                            instructionStepId
                        ) {
                            return {
                                openBuyId,
                                instructionStepId,
                                paymentConjunction,
                                payoutTiming,
                                base: [],
                                creatorSpecific: {}
                            };
                        } else {
                            return pendingOpenBuyPaymentsGroup;
                        }
                    }
                )
            }
        }
    };
}

export function addCreatorSpecificPayment(
    state: OpenBuyPaymentUIState,
    action: Action
): OpenBuyPaymentUIState {
    const { pendingOpenBuyPaymentsStore } = state;
    const { openBuyId, instructionStepId, creatorGroupId } = action.payload;
    return {
        ...state,
        pendingOpenBuyPaymentsStore: {
            ...pendingOpenBuyPaymentsStore,
            [openBuyId]: {
                ...pendingOpenBuyPaymentsStore[openBuyId],
                pendingOpenBuyPaymentsGroups: pendingOpenBuyPaymentsStore[
                    openBuyId
                ].pendingOpenBuyPaymentsGroups.map(
                    pendingOpenBuyPaymentsGroup => {
                        if (
                            pendingOpenBuyPaymentsGroup.instructionStepId !==
                            instructionStepId
                        ) {
                            return pendingOpenBuyPaymentsGroup;
                        } else {
                            return {
                                ...pendingOpenBuyPaymentsGroup,
                                creatorSpecific: {
                                    ...pendingOpenBuyPaymentsGroup.creatorSpecific,
                                    [creatorGroupId]: []
                                }
                            };
                        }
                    }
                )
            }
        }
    };
}

export function removeCreatorSpecificPayment(
    state: OpenBuyPaymentUIState,
    action: Action
): OpenBuyPaymentUIState {
    const { pendingOpenBuyPaymentsStore } = state;
    const { openBuyId, instructionStepId, creatorGroupId } = action.payload;
    return {
        ...state,
        pendingOpenBuyPaymentsStore: {
            ...pendingOpenBuyPaymentsStore,
            [openBuyId]: {
                ...pendingOpenBuyPaymentsStore[openBuyId],
                pendingOpenBuyPaymentsGroups: pendingOpenBuyPaymentsStore[
                    openBuyId
                ].pendingOpenBuyPaymentsGroups.map(
                    pendingOpenBuyPaymentsGroup => {
                        if (
                            pendingOpenBuyPaymentsGroup.instructionStepId !==
                            instructionStepId
                        ) {
                            return pendingOpenBuyPaymentsGroup;
                        } else {
                            const newCreatorSpecific = {
                                ...pendingOpenBuyPaymentsGroup.creatorSpecific
                            };
                            delete newCreatorSpecific[creatorGroupId];
                            return {
                                ...pendingOpenBuyPaymentsGroup,
                                creatorSpecific: newCreatorSpecific
                            };
                        }
                    }
                )
            }
        }
    };
}

export function updateCreatorSpecificPayment(
    state: OpenBuyPaymentUIState,
    action: Action
): OpenBuyPaymentUIState {
    const { pendingOpenBuyPaymentsStore } = state;
    const {
        openBuyId,
        instructionStepId,
        oldCreatorGroupId,
        newCreatorGroupId
    } = action.payload;
    return {
        ...state,
        pendingOpenBuyPaymentsStore: {
            ...pendingOpenBuyPaymentsStore,
            [openBuyId]: {
                ...pendingOpenBuyPaymentsStore[openBuyId],
                pendingOpenBuyPaymentsGroups: pendingOpenBuyPaymentsStore[
                    openBuyId
                ].pendingOpenBuyPaymentsGroups.map(
                    pendingOpenBuyPaymentsGroup => {
                        if (
                            pendingOpenBuyPaymentsGroup.instructionStepId !==
                            instructionStepId
                        ) {
                            return pendingOpenBuyPaymentsGroup;
                        } else {
                            const creatorSpecificOpenBuyPaymentItems =
                                pendingOpenBuyPaymentsGroup.creatorSpecific[
                                    oldCreatorGroupId
                                ];
                            const newCreatorSpecific = {
                                ...pendingOpenBuyPaymentsGroup.creatorSpecific
                            };
                            delete newCreatorSpecific[oldCreatorGroupId];
                            newCreatorSpecific[
                                newCreatorGroupId
                            ] = creatorSpecificOpenBuyPaymentItems;
                            return {
                                ...pendingOpenBuyPaymentsGroup,
                                creatorSpecific: newCreatorSpecific
                            };
                        }
                    }
                )
            }
        }
    };
}

export function pushPendingOpenBuyPaymentItem(
    state: OpenBuyPaymentUIState,
    action: Action
): OpenBuyPaymentUIState {
    const { pendingOpenBuyPaymentsStore } = state;
    const {
        openBuyId,
        instructionStepId,
        creatorGroupId,
        payment
    } = action.payload;
    return {
        ...state,
        pendingOpenBuyPaymentsStore: {
            ...pendingOpenBuyPaymentsStore,
            [openBuyId]: {
                ...pendingOpenBuyPaymentsStore[openBuyId],
                pendingOpenBuyPaymentsGroups: pendingOpenBuyPaymentsStore[
                    openBuyId
                ].pendingOpenBuyPaymentsGroups.map(
                    pendingOpenBuyPaymentsGroup => {
                        if (
                            pendingOpenBuyPaymentsGroup.instructionStepId !==
                            instructionStepId
                        ) {
                            return pendingOpenBuyPaymentsGroup;
                        } else {
                            if (!creatorGroupId) {
                                return {
                                    ...pendingOpenBuyPaymentsGroup,
                                    base: [
                                        ...pendingOpenBuyPaymentsGroup.base,
                                        payment
                                    ]
                                };
                            } else {
                                return {
                                    ...pendingOpenBuyPaymentsGroup,
                                    creatorSpecific: {
                                        ...pendingOpenBuyPaymentsGroup.creatorSpecific,
                                        [creatorGroupId]: [
                                            ...pendingOpenBuyPaymentsGroup
                                                .creatorSpecific[
                                                creatorGroupId
                                            ],
                                            payment
                                        ]
                                    }
                                };
                            }
                        }
                    }
                )
            }
        }
    };
}

export function shiftPendingOpenBuyPaymentItem(
    state: OpenBuyPaymentUIState,
    action: Action
): OpenBuyPaymentUIState {
    const { pendingOpenBuyPaymentsStore } = state;
    const { openBuyId, instructionStepId, creatorGroupId } = action.payload;
    return {
        ...state,
        pendingOpenBuyPaymentsStore: {
            ...pendingOpenBuyPaymentsStore,
            [openBuyId]: {
                ...pendingOpenBuyPaymentsStore[openBuyId],
                pendingOpenBuyPaymentsGroups: pendingOpenBuyPaymentsStore[
                    openBuyId
                ].pendingOpenBuyPaymentsGroups.map(
                    pendingOpenBuyPaymentsGroup => {
                        if (
                            pendingOpenBuyPaymentsGroup.instructionStepId !==
                            instructionStepId
                        ) {
                            return pendingOpenBuyPaymentsGroup;
                        } else {
                            if (!creatorGroupId) {
                                return {
                                    ...pendingOpenBuyPaymentsGroup,
                                    base: pendingOpenBuyPaymentsGroup.base.slice(
                                        1
                                    )
                                };
                            } else {
                                return {
                                    ...pendingOpenBuyPaymentsGroup,
                                    creatorSpecific: {
                                        ...pendingOpenBuyPaymentsGroup.creatorSpecific,
                                        [creatorGroupId]: pendingOpenBuyPaymentsGroup.creatorSpecific[
                                            creatorGroupId
                                        ].slice(1)
                                    }
                                };
                            }
                        }
                    }
                )
            }
        }
    };
}

export function updatePendingOpenBuyPaymentItem(
    state: OpenBuyPaymentUIState,
    action: Action
): OpenBuyPaymentUIState {
    const { pendingOpenBuyPaymentsStore } = state;
    const {
        openBuyId,
        instructionStepId,
        creatorGroupId,
        paymentIndex,
        payment
    } = action.payload;
    return {
        ...state,
        pendingOpenBuyPaymentsStore: {
            ...pendingOpenBuyPaymentsStore,
            [openBuyId]: {
                ...pendingOpenBuyPaymentsStore[openBuyId],
                pendingOpenBuyPaymentsGroups: pendingOpenBuyPaymentsStore[
                    openBuyId
                ].pendingOpenBuyPaymentsGroups.map(
                    pendingOpenBuyPaymentsGroup => {
                        if (
                            pendingOpenBuyPaymentsGroup.instructionStepId !==
                            instructionStepId
                        ) {
                            return pendingOpenBuyPaymentsGroup;
                        } else {
                            if (!creatorGroupId) {
                                return {
                                    ...pendingOpenBuyPaymentsGroup,
                                    base: pendingOpenBuyPaymentsGroup.base.map(
                                        (pendingOpenBuyPaymentItem, index) => {
                                            if (index === paymentIndex) {
                                                return payment;
                                            } else {
                                                return pendingOpenBuyPaymentItem;
                                            }
                                        }
                                    )
                                };
                            } else {
                                return {
                                    ...pendingOpenBuyPaymentsGroup,
                                    creatorSpecific: {
                                        ...pendingOpenBuyPaymentsGroup.creatorSpecific,
                                        [creatorGroupId]: pendingOpenBuyPaymentsGroup.creatorSpecific[
                                            creatorGroupId
                                        ].map(
                                            (
                                                pendingOpenBuyPaymentItem,
                                                index
                                            ) => {
                                                if (index === paymentIndex) {
                                                    return payment;
                                                } else {
                                                    return pendingOpenBuyPaymentItem;
                                                }
                                            }
                                        )
                                    }
                                };
                            }
                        }
                    }
                )
            }
        }
    };
}
