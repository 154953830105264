import styled from "styled-components";
import Subtitle from "./Subtitle";
import { colors, fonts } from "src/constants";

export const ModalTitle = styled.div`
    font-weight: 600;
    font-size: ${fonts.default}px;
`;

export const CampaignTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
`;
export const Header = styled.div`
    display: flex;
    flex-direction: column;
    height: 82px;
    justify-content: center;
    ${Subtitle} {
        margin-top: 4px;
    }
`;

export const Info = styled.div`
    color: ${colors.white};
    padding: 5px;
    align-self: center;
    text-align: center;
    margin-bottom: 7px;
    font-size: ${fonts.smallSubtitle}px;
`;
