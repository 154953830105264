import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { get } from "src/Api";
import { colors } from "src/constants";
import { addSocialStatusListener } from "src/firebase/Listeners";
import { BlueModalButton } from "src/profile/components/Buttons";
import { SocialAccount } from "src/profile/types";
import { GlobalState } from "src/reducers";
import BrandedButton from "src/social-accounts/BrandedButton";
import Platforms from "src/social-accounts/Platforms";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Link = styled.a`
    padding: 9px 12px;
    color: ${colors.primaryBlue};
    border: 1px solid ${colors.blueGrey2};
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    :hover {
        text-decoration: none;
        color: ${colors.primaryBlue};
    }
`;

const BoldText = styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
`;

const LinkText = styled.div`
    padding: 9px 12px;
    color: ${colors.primaryBlue};
    border: 1px solid ${colors.blueGrey2};
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
`;

const CodeSection = styled.div`
    padding: 7px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid ${colors.blueGrey2};
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
`;

const CodeText = styled(BoldText)`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: auto 0;
    text-align: left;
`;

const Screenshot = styled.img`
    height: 170px;
    border-radius: 5px;
    margin: 18px 0;
`;

const Code = styled.div<{ position: string }>`
    font-size: 8px;
    font-weight: bold;
    position: absolute;
    ${props => props.position};
`;

const ScreenshotContainer = styled.div`
    position: relative;
    margin: auto;
    width: 260px;
    height: 206px;
`;

interface Props {
    account: SocialAccount;
    checkVerification?: () => void;
    loginKit?: boolean;
    verifyCode?: string;
    both?: boolean;
}

const VerifyAction = ({
    account,
    loginKit,
    checkVerification,
    verifyCode,
    both = false
}: Props) => {
    const history = useHistory();

    let statuses = useSelector(
        (state: GlobalState) => state.entities.socialAccounts.statuses
    );
    const dispatch = useDispatch();

    const platform = Platforms[account.platform];

    //fetches the appropriate login link for tiktok login kit (TCM or non)
    async function tiktokLoginKitURL() {
        const redirectURI = `${window.location.origin}/profile/accounts/view`;
        const res = await get("/v1/socialAccount/tcmCheck", {
            username: account.username,
            socialAccountId: account.id,
            publisherId: account.publisher.id.split("p:")[1],
            redirectURI
        });
        window.location.replace(res.data.data.redirectURL);
    }

    const handleLoginWith = () => {
        account.platform === "tiktok"
            ? tiktokLoginKitURL()
            : history.push(platform.verificationUrlCallback(account) || "");
    };

    if (!platform) return null;
    //if we use loginkit load the login with button
    //all platforms but instagram support code in bio verification option

    if (loginKit && !both)
        return (
            <>
                <div>
                    Click the button below to log into{" "}
                    {Platforms[account.platform].title} to verify your
                    ownership.
                </div>
                <div style={{ margin: "16px 0 0 0" }}>
                    <BrandedButton
                        platform={account.platform}
                        onClick={handleLoginWith}
                    />
                </div>
            </>
        );

    return (
        <Main>
            <>
                <div>
                    Add this code to the end of your{" "}
                    {Platforms[account.platform].title}
                    {account.platform === "snapchat" ? " name" : " bio"}. Don't
                    worry! You can remove the code once you've been verified.
                </div>
                <>
                    <ScreenshotContainer>
                        <Screenshot
                            src={`/verification-screenshots/${account.platform}-verify.svg`}
                        />
                        <Code
                            position={Platforms[account.platform].codePosition}
                        >
                            {verifyCode}
                        </Code>
                    </ScreenshotContainer>
                    <CodeSection>
                        <CodeText>{verifyCode}</CodeText>
                        <CopyToClipboard text={verifyCode || ""}>
                            <LinkText>Copy code</LinkText>
                        </CopyToClipboard>
                    </CodeSection>
                </>
                <CodeSection>
                    <CodeText>@{account.username}</CodeText>
                    <Link
                        href={
                            Platforms[account.platform].urlCallback(
                                account.username
                            ) || ""
                        }
                        target="#"
                    >
                        Open {Platforms[account.platform].title}
                    </Link>
                </CodeSection>
            </>
            <BlueModalButton
                onClick={() => {
                    checkVerification && checkVerification();
                    addSocialStatusListener(account.id, statuses, dispatch);
                }}
            >
                Check code-in-bio
            </BlueModalButton>
            {both && (
                <div style={{ margin: "10px 0" }}>
                    <BrandedButton
                        platform={account.platform}
                        onClick={handleLoginWith}
                    />
                </div>
            )}
        </Main>
    );
};
export default VerifyAction;
