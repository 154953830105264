import { colors, fonts } from "src/constants";
import styled from "styled-components";

export const SimpleButton = styled.div`
    border: 1px solid #e0eef2;
    border-radius: 4px;
    font-size: ${fonts.subtitle}px;
    line-height: 16px;
    color: #0079f5;
    text-align: center;
    padding: 15px 0;
    user-select: none;
    cursor: pointer;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
        }
    }
`;

const PillButton = styled.div`
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
    padding: 4px 16px;
    border-radius: 15px;
    user-select: none;
    cursor: pointer;
`;

export const SmallPillButton = styled(PillButton)`
    color: #f2f2f2;
    background: #0079f5;
`;

export const WhitePillButton = styled(PillButton)`
    background: #f2f2f2;
    color: #0079f5;
`;
