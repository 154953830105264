import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchTag from "src/components/SearchTag";
import { setMediaFilters } from "src/pagination/media/actions";
import { selectMediaFilters } from "../selectors";

const MediaAdminTags = () => {
    const dispatch = useDispatch();

    const filters = useSelector(selectMediaFilters);

    function removeShowAll() {
        dispatch(
            setMediaFilters({
                ...filters,
                admin: { showUserHidden: false, showAll: false }
            })
        );
    }
    function removeUserHidden() {
        dispatch(
            setMediaFilters({
                ...filters,
                admin: { ...filters.admin, showUserHidden: false }
            })
        );
    }

    if (filters.admin.showAll)
        return (
            <>
                {filters.admin.showUserHidden ? (
                    <>
                        <SearchTag
                            color="black"
                            iconSrc="/tag-black-close-icon.svg"
                            text="Show user hidden"
                            onClick={removeUserHidden}
                        />
                        <SearchTag
                            color="black"
                            iconSrc="/tag-black-close-icon.svg"
                            text={"Show all medias"}
                            onClick={removeShowAll}
                        />
                    </>
                ) : (
                    <SearchTag
                        color="black"
                        iconSrc="/tag-black-close-icon.svg"
                        text={"Show all medias"}
                        onClick={removeShowAll}
                    />
                )}
            </>
        );

    return null;
};

export default MediaAdminTags;
