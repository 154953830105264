import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FlexContent } from "src/components/CommonStyles";
import { ReactComponent as Cross } from "src/components/icons/cross.svg";
import NavButton from "src/components/NavButton";
import SimpleNavbar from "src/components/SimpleNavbar";
import FullScreenModal from "src/modals/FullScreenModal";
import { colors } from "src/constants";
import { BigBlueButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import CampaignBidPrice from "../sort-filter/components/CampaignBidPrice";
import { setCampaignFilters } from "src/ui/campaigns/actions";
import { initialCampaignFilters } from "src/ui/campaigns/reducer";
import CampaignGenre from "../sort-filter/components/CampaignGenre";
import CampaignBudget from "../sort-filter/components/CampaignBudget";
import CampaignRegion from "../sort-filter/components/CampaignRegion";
import { motion } from "framer-motion";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const FilterList = styled(motion.div)`
    display: flex;
    flex-direction: column;
    padding: 20px 18px;
    ${FlexContent};
`;

const CrossButton = styled(Cross)`
    cursor: pointer;
    user-select: none;
`;

const Error = styled.div`
    color: ${colors.primaryRed};
    font-size: 12px;
    margin-bottom: 4px;
`;

const ConfirmButtonWrapper = styled.div`
    border-top: 1px solid ${colors.mediumTeal};
    padding: 12px 18px 24px;
    margin-top: auto;
`;

const ConfirmButton = styled(BigBlueButton)`
    font-weight: 500;

    &:disabled {
        opacity: 0.5;
    }
`;

interface Props {
    closeModal: () => void;
}

const CampaignFilterModal = ({ closeModal }: Props) => {
    const dispatch = useDispatch();

    const initialFilters = useSelector(
        (state: GlobalState) => state.ui.campaign.filters
    );

    const [filters, setFilters] = useState(initialFilters);
    const [error, setError] = useState("");

    function setGlobalCampaignFilters() {
        dispatch(setCampaignFilters(filters));
        closeModal();
    }

    useEffect(() => {
        if (filters.bidHigh < filters.bidLow) {
            setError("Bid range is incorrect");
        } else {
            setError("");
        }
    }, [filters.bidHigh, filters.bidLow]);

    return (
        <FullScreenModal
            closeModal={closeModal}
            // keepFrozenOnClose={!!promoting}
        >
            <Main>
                <SimpleNavbar
                    title="Campaign Filters"
                    left={
                        <CrossButton
                            fill={colors.primaryGray}
                            onClick={closeModal}
                        />
                    }
                    right={
                        <NavButton
                            text="Reset"
                            onClick={() => setFilters(initialCampaignFilters)}
                        />
                    }
                />
                <FilterList layout="position" transition={{ duration: 0.3 }}>
                    <CampaignBidPrice
                        filters={filters}
                        setFilters={setFilters}
                    />
                    <CampaignGenre filters={filters} setFilters={setFilters} />
                    <CampaignBudget filters={filters} setFilters={setFilters} />
                    <CampaignRegion filters={filters} setFilters={setFilters} />
                </FilterList>

                <ConfirmButtonWrapper>
                    {error && <Error>{error}</Error>}
                    <ConfirmButton
                        onClick={setGlobalCampaignFilters}
                        disabled={!!error}
                    >
                        Apply
                    </ConfirmButton>
                </ConfirmButtonWrapper>
            </Main>
        </FullScreenModal>
    );
};

export default CampaignFilterModal;
