import { combineReducers } from "redux";
import { ById } from "src/utils/types/general";
import { NormalizedAction } from "../../types";
import {
    CREATE_CREATOR_GROUP,
    DELETE_CREATOR_GROUP,
    FETCH_CREATOR_GROUPS,
    UPDATE_CREATOR_GROUP
} from "./actions.creator-groups";
import { CreatorGroup } from "./types.creator-groups";

export interface CreatorGroupsEntitiesState {
    byId: ById<CreatorGroup>;
    allIds: number[];
}

const initialCreatorGroupsState: CreatorGroupsEntitiesState = {
    byId: {},
    allIds: []
};

function byId(
    state = initialCreatorGroupsState.byId,
    action: NormalizedAction
) {
    switch (action.type) {
        case FETCH_CREATOR_GROUPS.SUCCESS:
            return { ...state, ...action.payload.entities.creatorGroups };

        case CREATE_CREATOR_GROUP.SUCCESS:
        case UPDATE_CREATOR_GROUP.SUCCESS:
            const { creatorGroup } = action.payload;
            const newState = { ...state };
            newState[creatorGroup.id] = creatorGroup;
            return newState;

        case DELETE_CREATOR_GROUP.SUCCESS:
            const { creatorGroupId } = action.payload;
            const postDeleteNewState = { ...state };
            delete postDeleteNewState[creatorGroupId];
            return postDeleteNewState;

        default:
            return state;
    }
}

function allIds(
    state = initialCreatorGroupsState.allIds,
    action: NormalizedAction
) {
    switch (action.type) {
        case FETCH_CREATOR_GROUPS.SUCCESS:
            return action.payload.result;

        default:
            return state;
    }
}

const creatorGroupsEntitiesReducer = combineReducers<
    CreatorGroupsEntitiesState
>({
    byId,
    allIds
});

export default creatorGroupsEntitiesReducer;
