import React from "react";
import { colors } from "src/constants";
import styled from "styled-components";

const Heading = styled.div`
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    padding: 24px 0px 8px;
    border-top: 2px solid ${colors.quaternaryLightGray};
    border-bottom: ${colors.quaternaryLightGray} solid 1px;
    margin-bottom: 12px;
    text-transform: capitalize;
`;

const FormHeading = ({
    text,
    className
}: {
    text: string;
    className?: string;
}) => {
    return <Heading className={className}>{text}</Heading>;
};

export default FormHeading;
