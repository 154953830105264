import React, { ReactElement, useState } from "react";
import { colors } from "src/constants";
import styled from "styled-components";
import DropdownCaret from "src/components/icons/DropdownCaret";
import RichTextPreview from "src/marketplace/reports/components/approvalModal/RichTextPreview";

const Wrapper = styled.div<{ type: "success" | "danger" | "warning" | "info" }>`
    background-color: ${props =>
        props.type === "success"
            ? colors.quaternaryGreen
            : props.type === "warning"
            ? colors.noteYellow
            : props.type === "info"
            ? colors.lightestBlue
            : colors.primaryRedLight};

    border-radius: 4px;
    padding: 8px 12px;
    font-size: 12px;
`;
const HeadingWrap = styled.div<{ noPadding: boolean; closable?: boolean }>`
    display: flex;
    justify-content: space-between;
    cursor: ${props => (props.closable ? "pointer" : "default")};
    padding-bottom: ${props => (props.noPadding ? "0px" : "10px")};
`;
const Heading = styled.div`
    text-align: left;
    font-weight: 700;
`;
const TextBody = styled.div`
    text-align: left;
`;
const InnerWrap = styled.div<{
    open: boolean;
    innerHeight: number;
    closable?: boolean;
}>`
    transition: ${props => (props.closable ? "height 0.4s ease" : "none")};
    height: ${props => (props.open ? `${props.innerHeight}px` : "0px")};
    overflow: hidden;
    margin: 0px;
`;

interface Props {
    type: "success" | "danger" | "warning" | "info";
    heading: string;
    body?: string;
    closable?: boolean;
    defaultOpen?: boolean;
    className?: string;
    onDismiss?: () => void;
    children?: ReactElement;
    invert?: boolean;
    onClick?: () => void;
}

const StatusMessage = ({
    type,
    heading,
    body,
    closable,
    defaultOpen = false,
    children,
    className,
    invert,
    onDismiss,
    onClick,
}: Props) => {
    const [open, setOpen] = useState(defaultOpen);
    const [innerHeight, setInnerHeight] = useState(0);
    return (
        <Wrapper className={className} type={type} onClick={onClick}>
            <HeadingWrap
                onClick={closable ? () => setOpen(!open) : undefined}
                closable={closable}
                noPadding={!body || !open}
            >
                <Heading>{heading}</Heading>
                {closable && <DropdownCaret open={open} invert={invert} />}
                {onDismiss && (
                    <img
                        style={{ cursor: "pointer" }}
                        onClick={onDismiss}
                        src="/black_close.svg"
                        alt="close modal"
                    />
                )}
            </HeadingWrap>
            <InnerWrap
                innerHeight={innerHeight}
                open={open}
                closable={closable}
            >
                <div
                    ref={ref => {
                        if (ref) {
                            setInnerHeight(ref.clientHeight);
                        }
                    }}
                >
                    {/* {children ? children : <TextBody>{body}</TextBody>} */}
                    {children ? children : <RichTextPreview text={body} />}
                </div>
            </InnerWrap>
        </Wrapper>
    );
};

export default StatusMessage;
