import React from "react";
import { useDispatch } from "react-redux";
import { Connection, PublicProfileData } from "../../../types";
import { push } from "connected-react-router";
import PaddingAround from "../../../../components/PaddingAround";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import NavButton from "../../../../components/NavButton";
import FullscreenLoader from "../../../../components/FullscreenLoader";
import PublicAccountsPage from "./PublicAccountsPage";
import XCard from "../../../components/XCard";
import { fetchAccountsPublic } from "../../../actions";
import MainContainer from "src/components/MainContainer";
import { useHistory } from "react-router";
import Navbar from "src/components/Navbar";

interface Props {
    publicData?: PublicProfileData;
}

const PublicAccountsPageController = ({ publicData }: Props) => {
    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();

    function dispatchFetchAccountsPublic(connection: Connection): void {
        dispatch(fetchAccountsPublic(connection));
    }

    function dispatchPush(route: string): void {
        dispatch(push(route));
    }

    if (publicData && publicData.accounts === null) {
        const connection = publicData.connection;

        return (
            <>
                <Navbar back marginBottom={10} title="ACCOUNTS" />

                <MainContainer>
                    <PaddingAround>
                        <XCard
                            text="Failed to load accounts, click the button below to try again."
                            button={{
                                text: "Try again",
                                callback: () => {
                                    dispatchFetchAccountsPublic(connection);
                                }
                            }}
                        />
                    </PaddingAround>
                </MainContainer>
            </>
        );
    } else if (publicData && publicData.accounts) {
        return (
            <>
                <Navbar back marginBottom={10} title="ACCOUNTS" />

                <MainContainer>
                    <PublicAccountsPage
                        accounts={publicData.accounts}
                        push={dispatchPush}
                    />
                </MainContainer>
            </>
        );
    } else {
        return (
            <>
                <Navbar back marginBottom={10} title="ACCOUNTS" />
                <FullscreenLoader />
            </>
        );
    }
};

export default PublicAccountsPageController;
