import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import { setAdminCurrentWizardStep } from "src/ui/admin-tools/actions";
import styled from "styled-components";
import { stepRequiresSubmission } from "../utils/helpers";
import PaymentSetDropdown from "./PaymentSetDropdown";
import { InfoText } from "./styledComponents";
import {
    addPendingOpenBuyPaymentsGroup,
    removePendingOpenBuyPaymentsGroup
} from "../open-buy-payments/actions";

const ErrorBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 8px 12px;
    gap: 10px;
    width: 100%;
    min-height: 30px;
    background: ${colors.errorPink1};
    border-radius: 4px;
    cursor: pointer;
`;

const WarningIcon = styled.img`
    width: 16px;
    height: 14px;
`;

interface Props {
    openBuyId: number;
    instructionStepId?: number;
}

const PayoutTimingDropdown = ({ openBuyId, instructionStepId }: Props) => {
    const dispatch = useDispatch();

    const inWizardFlow = window.location.pathname.includes("edit-challenge");

    // Selectors -----------------------------------------------------------
    const { instructionSteps } = useSelector(
        (state: GlobalState) => state.ui.instructionsSets
    );
    const requiredSteps =
        instructionSteps.filter(step => stepRequiresSubmission(step)) || [];

    const instructionStepIds = requiredSteps.map(step => step.id);

    // Local Functions -----------------------------------------------------------
    const getStepLabel = (stepId?: number) => {
        const step = instructionSteps.find(step => step.id === stepId);
        if (step) {
            const submissionType = stepRequiresSubmission(step);
            const description = step?.description
                ? step.description.slice(0, 100)
                : "";
            const dots = description.length > 100 ? "... " : "";
            return `${submissionType}: ${description} ${dots}`;
        } else return "";
    };

    const handleSetInstructionStep = (stepId?: number) => {
        if (stepId) {
            dispatch(
                addPendingOpenBuyPaymentsGroup({
                    openBuyId,
                    instructionStepId: stepId
                })
            );
        }
        if (instructionStepId) {
            dispatch(
                removePendingOpenBuyPaymentsGroup({
                    openBuyId,
                    instructionStepId
                })
            );
        }
    };

    // Return -----------------------------------------------------------
    return (
        <>
            {requiredSteps.length === 0 ? (
                <ErrorBox
                    onClick={() => {
                        if (inWizardFlow) {
                            dispatch(
                                setAdminCurrentWizardStep(
                                    "Select an Instruction Set"
                                )
                            );
                        }
                    }}
                >
                    <WarningIcon src={"/warning-red.svg"} />
                    <InfoText>
                        Please add a step with a requirement to apply Payout
                        Timing
                    </InfoText>
                </ErrorBox>
            ) : (
                <PaymentSetDropdown
                    itemList={instructionStepIds}
                    selected={instructionStepId}
                    displayName={getStepLabel(instructionStepId)}
                    displayEmptyState={"Select Instruction Step for Payout"}
                    handleOnClick={stepId => handleSetInstructionStep(stepId)}
                    getItemName={stepId => getStepLabel(stepId)}
                />
            )}
        </>
    );
};

export default PayoutTimingDropdown;
