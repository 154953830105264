import React from "react";
import { FlexVCSpaceBetween } from "src/components/CommonStyles";
import { colors, fonts, fontWeight } from "src/constants";
import { clearSpacing } from "src/utils/styles/snippets";
import styled from "styled-components";

const Main = styled.section`
    flex: 1;
    width: 100%;
    padding: 16px;
    background-color: ${colors.white};
    border: ${colors.quaternaryLightGray} solid 1px;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(205, 205, 205, 0.25);
`;

const Title = styled.h1`
    ${clearSpacing()};
    color: ${colors.black};
    font-size: ${fonts.semiTitle}px;
    font-weight: ${fontWeight.medium};
`;

const HeaderWrapper = styled.div`
    ${FlexVCSpaceBetween};
    margin-bottom: 22px;
`;

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

interface Props {
    title: string;
    children?: React.ReactNode;
    Action?: React.ReactNode;
}

const ChallengeFormSection = ({ title, children, Action }: Props) => {
    return (
        <Main>
            <HeaderWrapper>
                <Title>{title}</Title>
                {Action}
            </HeaderWrapper>
            <Contents>{children}</Contents>
        </Main>
    );
};

export default ChallengeFormSection;
