import React from "react";
import moment from "moment";
import {
    Connection,
    PreApprovalCode,
    Profile,
    SocialAccount
} from "./profile/types";
import { Notification } from "./notifications/types";
import { Media, OldMedia } from "./media/types";
import {
    Campaign,
    OldCampaign,
    NewPrice,
    CountryPlatformReward
} from "src/campaigns/types";
import Platforms from "./social-accounts/Platforms";
import { PlacementConfig } from "./ui/placements/reducer";
import { OldSocialAccount } from "./social-accounts/SocialAccount";

export const formatNumber = (num: number | string) => {
    if (typeof num === "number") {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
        return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
};

export const betterFormatNumber = (num: number) => {
    if (!num) return 0;
    return num.toLocaleString("en-US");
};

export const formattedNumberToNumber = (num: string) => {
    return parseFloat(num.replace(/,/g, ""));
};

export const fullname = (
    firstname?: string | null,
    lastname?: string | null
) => {
    if (firstname && lastname) {
        return `${firstname} ${lastname}`;
    } else if (firstname) {
        return firstname;
    } else if (lastname) {
        return lastname;
    } else {
        return "";
    }
};

export const shortTimeIntervalArray = (
    diff: number,
    topIntervals?: number
): string[] => {
    const totalSeconds = Math.floor(diff / 1000);

    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const days = Math.floor(
        (totalSeconds % (60 * 60 * 24 * 7)) / (60 * 60 * 24)
    );
    const weeks = Math.floor(
        (totalSeconds % (60 * 60 * 24 * 365)) / (60 * 60 * 24 * 7)
    );
    const years = Math.floor(totalSeconds / (60 * 60 * 24 * 365));

    const timeArray: string[] = [];
    let addZeros = false;

    if (years > 0) {
        timeArray.push(`${years}y`);
        addZeros = true;
    }

    if (weeks > 0) {
        timeArray.push(`${weeks}w`);
        addZeros = true;
    } else if (addZeros) {
        timeArray.push("0w");
    }

    if (days > 0) {
        timeArray.push(`${days}d`);
        addZeros = true;
    } else if (addZeros) {
        timeArray.push("0d");
    }

    if (hours > 0) {
        timeArray.push(`${hours}h`);
        addZeros = true;
    } else if (addZeros) {
        timeArray.push("0h");
    }

    if (minutes > 0) {
        timeArray.push(`${minutes}m`);
    } else if (addZeros) {
        timeArray.push("0m");
    }

    if (seconds > 0) {
        timeArray.push(`${seconds}s`);
    } else {
        timeArray.push("0s");
    }

    return topIntervals ? timeArray.slice(0, topIntervals) : timeArray;
};

export const shortTimeInterval = (diff: number): string => {
    return shortTimeIntervalArray(diff)[0];
};

export function secondsToTimeIntervals(
    totalSeconds: number,
    includeZeros: boolean = false,
    onlyDays: boolean = false,
    noLabel: boolean = false
): string[] {
    if (totalSeconds < 1) return ["0 seconds"];

    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const days = Math.floor(
        (onlyDays ? totalSeconds : totalSeconds % (60 * 60 * 24 * 7)) /
            (60 * 60 * 24)
    );
    const weeks = Math.floor(
        (totalSeconds % (60 * 60 * 24 * 365)) / (60 * 60 * 24 * 7)
    );
    const years = Math.floor(totalSeconds / (60 * 60 * 24 * 365));

    let timeArray: string[] = [];
    let addZeros = false;

    if (!onlyDays && years > 0) {
        timeArray.push(
            noLabel
                ? years.toString()
                : `${years} year${years === 1 ? "" : "s"}`
        );
        addZeros = true;
    }

    if (!onlyDays && weeks > 0) {
        timeArray.push(
            noLabel
                ? weeks.toString()
                : `${weeks} week${weeks === 1 ? "" : "s"}`
        );
        addZeros = true;
    } else if (includeZeros && addZeros)
        timeArray.push(noLabel ? "0" : "0 weeks");

    if (days > 0) {
        timeArray.push(
            noLabel ? days.toString() : `${days} day${days === 1 ? "" : "s"}`
        );
        addZeros = true;
    } else if (includeZeros && addZeros)
        timeArray.push(noLabel ? "0" : "0 days");

    if (hours > 0) {
        timeArray.push(
            noLabel
                ? hours.toString()
                : `${hours} hour${hours === 1 ? "" : "s"}`
        );
        addZeros = true;
    } else if (includeZeros && addZeros)
        timeArray.push(noLabel ? "0" : "0 hours");

    if (minutes > 0) {
        timeArray.push(
            noLabel
                ? minutes.toString()
                : `${minutes} minute${minutes === 1 ? "" : "s"}`
        );
        addZeros = true;
    } else if (includeZeros && addZeros)
        timeArray.push(noLabel ? "0" : "0 seconds");

    if (seconds > 0) {
        timeArray.push(
            noLabel
                ? seconds.toString()
                : `${seconds} second${seconds === 1 ? "" : "s"}`
        );
    } else if (includeZeros) timeArray.push(noLabel ? "0" : "0 seconds");

    return timeArray;
}

export function timeStringForSeconds(seconds: number) {
    if (seconds < 10) {
        return `0:0${Math.floor(seconds)}`;
    } else {
        const minutes = Math.floor(seconds / 60);
        const remainder = Math.floor(seconds % 60);
        if (remainder < 10) {
            return `${minutes}:0${remainder}`;
        } else {
            return `${minutes}:${remainder}`;
        }
    }
}

export const shortenNumber = (
    number: number | string,
    decimal?: number
): string => {
    let shortNum = typeof number !== "number" ? parseInt(number) : number;
    let numSuffix = "";

    if (number >= 1000000000) {
        shortNum = shortNum / 1000000000;
        numSuffix = "B";
    } else if (number >= 1000000) {
        shortNum = shortNum / 1000000;
        numSuffix = "M";
    } else if (number >= 1000) {
        shortNum = shortNum / 1000;
        numSuffix = "K";
    }

    if (decimal && decimal > 0) {
        return `${parseFloat(shortNum.toFixed(decimal))}${numSuffix}`;
    } else {
        return `${Math.round(shortNum)}${numSuffix}`;
    }
};

export const makeId = (length: number) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
    }
    return result;
};

export const isMobileSafari = () => {
    return navigator.userAgent.match(/(iPod|iPhone|iPad)/);
};

export const isOldSafari = () => {
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        const v = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
        return parseInt(v && v[1] ? v[1] : "0") < 13;
    } else {
        return false;
    }
};

export const freezeBody = () => {
    if (document.body.style.position !== "fixed") {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = "fixed";
    }
};

export const thawBody = () => {
    if (!localStorage.getItem("carouselOpen")) {
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
};

export const getUrlParameter = (name: string) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const loggedIn = (): boolean => {
    return !!localStorage.getItem("token");
};

export const magicInProgress = (): boolean => {
    return !!localStorage.getItem("magicToken");
};

export const getItem = (key: string) => {
    let item = localStorage.getItem(key);
    if (item !== null) return JSON.parse(item);
    else return false;
};

export const accountCompleted = (
    profile: Profile,
    preApprovalCode: PreApprovalCode | null
): boolean => {
    if (profile && profile.firstname && profile.lastname && profile.birthday) {
        return true;
    } else {
        return false;
    }
};

export const makeDateArray = (startDate: string, endDate: string): string[] => {
    const dateArray: string[] = [];

    const diff = moment(endDate).diff(moment(startDate), "day");

    for (let i = 0; i <= diff; i++) {
        dateArray.push(
            moment(startDate)
                .add(i, "day")
                .format("YYYY-MM-DD")
        );
    }

    return dateArray;
};

export const analyticsMediaToString = (data: any): string => {
    if (data.mediaName) {
        return data.mediaName;
    } else if (data.media.toString() === "0") {
        return "Generic Link";
    } else if (data.media.toString() === "2007") {
        return "Other Apps Page";
    } else {
        return `Media #${data.media}`;
    }
};

export const parseNotifications = (notifications: any[]) => {
    const parsedNotifs: Notification[] = [];

    notifications.forEach((notification: any) => {
        const currNotif: Notification = notification;

        if (notification.notif_type === "new_cashout") {
            currNotif.transaction = JSON.parse(notification.transaction);
        } else if (notification.notif_type === "new_referral_bonus") {
            currNotif.transaction = JSON.parse(notification.transaction);
            currNotif.referredUser = JSON.parse(notification.referredUser);
        } else if (
            notification.notif_type === "new_transaction" ||
            notification.notif_type === "campaign_closed"
        ) {
            currNotif.campaign = JSON.parse(notification.campaign);
            if (notification.transaction) {
                currNotif.transaction = JSON.parse(notification.transaction);
            }
        }

        parsedNotifs.push(currNotif);
    });

    return parsedNotifs.sort((a: Notification, b: Notification) => {
        return b.created_at - a.created_at;
    });
};

export const allowCashout = (profile: Profile) => {
    if (profile.Transactions?.length === 0) {
        return true;
    }

    const lastCashout = moment(profile.Transactions[0].date_pst);
    const currTime = moment().startOf("day");

    return currTime.diff(lastCashout, "day") >= 1;
};

export const platformToContact = (
    platform: string,
    instagramHandle: string | null
): {
    urlToOpen: string;
    buttonText: string;
    handle: string;
} => {
    // NOTE: removing while our account is locked
    // if (platform === "instagram") {
    //     return {
    //         urlToOpen: `https://www.instagram.com/${
    //             instagramHandle ? instagramHandle : "hi.plugapp"
    //         }`,
    //         buttonText: "Open Instagram",
    //         handle: instagramHandle ? `@${instagramHandle}` : "@hi.plugapp"
    //     };
    // } else
    if (platform === "snapchat") {
        return {
            urlToOpen: "https://www.snapchat.com/add/theplugapp",
            buttonText: "Open Snapchat",
            handle: "@theplugapp"
        };
    } else if (platform === "facebook") {
        return {
            urlToOpen: "https://www.facebook.com/PlugAppAnalytics",
            buttonText: "Open Facebook",
            handle: "@PlugAppAnalytics"
        };
    } else if (platform === "twitter") {
        return {
            urlToOpen: "https://twitter.com/plugcoin_app",
            buttonText: "Open Twitter",
            handle: "@plugcoin_app"
        };
    } else {
        return {
            urlToOpen: "mailto:support@plugco.in?subject=Verify me please!",
            buttonText: "Open Email",
            handle: "hi@plugco.in"
        };
    }
};

export const convertConnections = (
    selfId: string,
    ownConnections: Connection[],
    otherConnections: Connection[]
): Connection[] => {
    const ownObject: { [otherId: string]: Connection } = {};

    ownConnections.forEach(connection => {
        ownObject[connection.other.id] = connection;
    });

    return otherConnections.map(connection => {
        if (ownObject[connection.other.id]) {
            return ownObject[connection.other.id];
        } else {
            return {
                entity: {
                    id: selfId
                },
                other: connection.other,
                relationship: null,
                state: null
            };
        }
    });
};

export const oldToNewMedia = (oldMedia: OldMedia): Media => {
    return {
        id: oldMedia.id,
        type: oldMedia.type,
        isPortrait: oldMedia.is_portrait,
        name: oldMedia.name,
        key: oldMedia.key,
        coverPhoto: oldMedia.cover_photo,
        url: oldMedia.url,
        coverPhotoUrl: oldMedia.cover_photo_url,
        campaignId: oldMedia.campaign_id,
        externalPageId: oldMedia.external_page_id,
        uploadedByPublisherId: oldMedia.uploaded_by_publisher_id,
        doNotPayCommission: oldMedia.do_not_pay_commission,
        approvedAt: oldMedia.approved_at,
        mediaStatus: oldMedia.media_status,
        rejectionReason: oldMedia.rejection_reason
    };
};

// strapped away rewardData, embedded Media (populate back with state.entities.media selector), and embedded ExternalPages
export const newToOldCampaign = (newCampaign: Campaign): OldCampaign => ({
    id: newCampaign.id,
    campaign_name: newCampaign.campaignName,
    country_platform_reward: newCampaign.countryPlatformReward,
    start_time: newCampaign.startTime,
    end_time: newCampaign.endTime,
    image_url: newCampaign.imageUrl,
    ios_app_id: newCampaign.iosAppId,
    ios_app_url: newCampaign.iosAppUrl,
    android_app_id: newCampaign.androidAppId,
    android_app_url: newCampaign.androidAppUrl,
    reward: newCampaign.reward,
    rewardData: [],
    reward_type: newCampaign.rewardType,
    balance: newCampaign.balance,
    balance_claimed: newCampaign.balanceClaimed,
    have_notified_of_finish: newCampaign.haveNotifiedOfFinish,
    link_service: newCampaign.linkService,
    link_url_prefix: newCampaign.linkUrlPrefix,
    ios_tracker_id: newCampaign.iosTrackerId || "",
    advertiser_id: newCampaign.advertiserId,
    ios_us_reward: newCampaign.iosUsReward,
    ios_english_reward: newCampaign.iosEnglishReward,
    android_us_reward: newCampaign.androidUsReward,
    android_english_reward: newCampaign.androidEnglishReward,
    android_row_reward: newCampaign.androidRowReward,
    color: newCampaign.color,
    app_icon_url: newCampaign.appIconUrl || "",
    hourly_coins_per_publisher: newCampaign.hourlyCoinsPerPublisher,
    campaign_subtitle: newCampaign.campaignSubtitle || "",
    star_rating: newCampaign.starRating,
    num_star_ratings: newCampaign.numStarRatings,
    app_type: newCampaign.appType,
    tags: newCampaign.tags,
    custom_media_ok: newCampaign.customMediaOk,
    is_testing: newCampaign.isTesting,
    Media: [],
    ExternalPages: []
});

export const newToOldPrice = ({
    id,
    countryPlatformReward
}: {
    id: string;
    countryPlatformReward: NewPrice;
}): { id: string; countryPlatformReward: CountryPlatformReward } => ({
    id,
    countryPlatformReward: {
        country: countryPlatformReward.country,
        reward: countryPlatformReward.reward,
        rewardMin: countryPlatformReward.rewardMin,
        rewardMax: countryPlatformReward.rewardMax,
        platform: countryPlatformReward.platform,
        previous_reward: countryPlatformReward.previousReward,
        boost_expires_at: countryPlatformReward.boostExpiresAt
    }
});

export const errorToString = (error: any) => {
    if (
        error.response &&
        error.response.data &&
        error.response.data.error.message === "Failed Validation"
    ) {
        let message = error.response.data.error.message + ": ";
        let validation = error.response.data.error.validation[0];
        let values = Object.values<string>(validation);
        values?.forEach(val => (message += val));
        return message;
    } else if (error.response) {
        return error.response.data
            ? error.response.data.error.message
            : error.message;
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return error.request;
    } else {
        // Something happened in setting up the request that triggered an Error
        return error.message || "";
    }
};

export const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isValidBirthday = (birthday: string) => {
    return moment(birthday, "MM/DD/YYYY").isValid() && birthday.length === 10;
};

export const formatBirthday = (rawInput: string, cleanInput: string) => {
    const lastChar = rawInput.slice(rawInput.length - 1);

    const month = cleanInput.slice(0, 2);
    const day = cleanInput.slice(2, 4);
    const year = cleanInput.slice(4, 8);

    let newDate = "";

    if (month) {
        newDate += month;
    }
    if (day) {
        newDate += `/${day}`;
    }
    if (year) {
        newDate += `/${year}`;
    }

    if (lastChar === "/" && newDate.length < 6) {
        newDate += "/";
    }

    return newDate;
};

export function getImageSrcForSocialAccountArray(
    socialAccounts: SocialAccount[]
): string {
    const otherObject = Platforms.other;
    if (socialAccounts.length === 0) {
        return otherObject.imgSrc;
    } else if (socialAccounts.length === 1) {
        const platformObject = Platforms[socialAccounts[0].platform];
        return (platformObject || otherObject).imgSrc;
    } else {
        const frequencyObject: { [platform: string]: number } = {};
        let maxCount = 0;
        let maxPlatform = socialAccounts[0].platform;
        socialAccounts.forEach(account => {
            const currentPlatform = account.platform;
            if (frequencyObject[currentPlatform]) {
                frequencyObject[currentPlatform] += 1;
            } else {
                frequencyObject[currentPlatform] = 1;
            }
            if (frequencyObject[currentPlatform] > maxCount) {
                maxCount = frequencyObject[currentPlatform];
                maxPlatform = currentPlatform;
            }
        });
        const platformObject = Platforms[maxPlatform];
        return (platformObject || otherObject).imgSrc;
    }
}

export const isIP = (url: string) => {
    var regex = new RegExp(
        "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
    );
    var results = regex.exec(url);
    return results !== null;
};

export const coinToDollars = (
    coin: number,
    asNumber?: boolean,
    noFixed?: boolean
) => {
    const roundeven = (x: number) => {
        return (
            Math.sign(x) *
            (Math.abs(x) + 4.503599627370496e15 - 4.503599627370496e15)
        );
    };
    let ans = (roundeven(coin * 5) / 100).toFixed(noFixed ? 0 : 2);
    return asNumber ? ans : `$${ans}`;
};

export const weeklyBonusAmount = (coin: number, bonusPercentage: number) => {
    const weeklyEarnings = coinToDollars(coin, true);
    let weeklyBonus = (
        (bonusPercentage / 100) *
        Number(weeklyEarnings)
    ).toFixed(2);
    return `$${weeklyBonus}`;
};

export const formatRank = (rank: number) => {
    const lastDigit = rank % 10;
    const lastTwoDigits = rank % 100;
    if (rank === 0) return "Unranked";
    if (lastTwoDigits > 10 && lastTwoDigits < 20) {
        return `${rank}th Place`;
    }
    switch (lastDigit) {
        case 1:
            return `${rank}st Place`;
        case 2:
            return `${rank}nd Place`;
        case 3:
            return `${rank}rd Place`;
        default:
            return `${rank}th Place`;
    }
};

export const debounce = (fn: Function, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function(this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};

export const selectedPlacementsDisplayText = (
    placementConfig: PlacementConfig,
    totalPlacementsLength: number
) => {
    const configLength = Object.keys(placementConfig).length;
    if (!configLength || totalPlacementsLength === configLength)
        return "All Placements";

    if (configLength > 2)
        return (
            Object.keys(placementConfig)
                .slice(0, 2)
                .join(", ") + `, +${configLength - 2} More`
        );

    return Object.keys(placementConfig).join(", ");
};

export function chunk(array: any[], size: number) {
    const chunkedArray: any[] = [];
    for (var i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
}

export function fileUpload(
    file: File,
    path: string,
    storage: any,
    onUpload: (id: string, type: string, url: string) => void,
    onUploadError?: (error: string) => void,
    onCoverPhoto?: (url: string) => void
) {
    let type = file.type.split("/")[1];
    let url = URL.createObjectURL(file);
    try {
        if (type === "quicktime") {
            type = "mov";
        }

        const id = `${localStorage.getItem("userid")}_${makeId(50)}.${type}`;
        const ref = storage.ref().child(`${path}/${id}`);

        ref.put(file)
            .then(() => {
                if (file.type.split("/")[0] === "video") {
                    onUpload && onUpload(id, "video", url);
                    onCoverPhoto &&
                        createCoverPhoto(file, path, storage, onCoverPhoto);
                } else {
                    onUpload && onUpload(id, "photo", url);
                }
            })
            .catch((error: string) => {
                window.alert("Something went wrong. Please Refresh.");
                console.log(error);
            });
    } catch (error) {
        onUploadError && onUploadError(url);
    }
}

function createCoverPhoto(
    file: File,
    path: string,
    storage: any,
    onCoverPhoto: (id: string) => void
) {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    video.preload = "metadata";
    video.crossOrigin = "Anonymous";
    video.src = URL.createObjectURL(file);
    if (isMobileSafari()) {
        video.load();
    } else {
        video.muted = true;
        video.play();
    }
    video.onloadeddata = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");

        if (!context) {
            return null;
        }

        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(blob => {
            if (!blob) return null;

            const id = `${localStorage.getItem("userid")}_${makeId(50)}.jpeg`;
            const ref = storage.ref().child(`${path}/${id}`);

            ref.put(blob)
                .then(() => {
                    onCoverPhoto && onCoverPhoto(id);
                })
                .catch((error: string) => {
                    window.alert("Something went wrong. Please Refresh.");
                });
        }, "image/jpeg");
    };
}

export const generateUniqueNumsArr = (
    maxLength: number,
    min: number,
    max: number
) => {
    const uniqueNums: number[] = [];
    while (uniqueNums.length < maxLength) {
        const randomNum = Math.floor(Math.random() * (max - min) + 1);
        if (uniqueNums.indexOf(randomNum) === -1) uniqueNums.push(randomNum);
    }
    return uniqueNums;
};

export const replaceHtmlEntities = (str: string) => {
    const translate_re = /&(#39|amp|quot|lt|gt);/g;
    const translate: any = {
        amp: "&",
        quot: '"',
        lt: "<",
        gt: ">",
        "#39": "'"
    };
    const translator = ($0: any, $1: string) => {
        return translate[$1];
    };
    return str.replace(translate_re, translator);
};

export const getObjectKeys = Object.keys as <T extends object>(
    obj: T
) => Array<keyof T>;
declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

export const download = (content: any, fileName: string, mimeType: string) => {
    const a = document.createElement("a");
    mimeType = mimeType || "application/octet-stream";

    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(
            new Blob([content], {
                type: mimeType
            }),
            fileName
        );
    } else if (URL && "download" in a) {
        a.href = URL.createObjectURL(
            new Blob([content], {
                type: mimeType
            })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        window.location.href =
            "data:application/octet-stream," + encodeURIComponent(content);
    }
};

export const getPlatform = () => {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return "ios";
    } else if (/Android/i.test(navigator.userAgent)) {
        return "android";
    } else if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        return "desktop";
    }
    return "other";
};

export const checkForSocialAccounts = ({
    accounts,
    platform,
    verified
}: {
    accounts: SocialAccount[];
    platform?: string;
    verified?: boolean;
}) => {
    let filtered: SocialAccount[] = [];
    accounts.forEach(account => {
        if (verified && !account.verified) return;
        if (platform && account.platform !== platform) return;
        filtered.push(account);
    });
    return filtered;
};

export const nth = (n: number) => {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
};

export const formattedInputTypeToInputType = (type?: string) => {
    switch (type) {
        case "currency":
            return "string";
        case "percent":
            return "number";
        default:
            return type;
    }
};

export const formattedInputTypePattern = (type?: string) => {
    switch (type) {
        case "currency":
            return "^\\$?(([1-9](\\d*|\\d{0,2}(,\\d{3})*))|0)(\\.\\d{1,2})?$";
        case "percent":
            return "^\\$?(([1-9](\\d*|\\d{0,2}(,\\d{3})*))|0)(\\.\\d{1,2})?$";
        default:
            return undefined;
    }
};

export const symbolForFormattedInput = (type?: string) => {
    switch (type) {
        case "currency":
            return "$";
        case "percent":
            return "%";
        default:
            return "";
    }
};

export const waitMilliseconds = (ms: number) =>
    new Promise(res => setTimeout(res, ms));
