import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import ProfilePhoto from "../../../components/ProfilePhoto";
import { colors, fonts } from "../../../constants";
import { shortenNumber } from "../../../util";
import {
    OutlineRecommendationButton,
    RecommendationButton
} from "../../components/Buttons";
import GenericContainer from "../../components/GenericContainer";
import { Connection } from "../../types";
import Platforms from "../../../social-accounts/Platforms";
import { PostRecommendationParams } from "../../actions";
import HideOverflow from "../../../components/HideOverflow";
import { flex } from "src/utils/styles/snippets";

const Main = styled(GenericContainer)`
    ${flex("column", "auto", "center")};
    position: relative;
    width: 120px;
    background-color: ${colors.white};
    transition: 0.1s ease-in;
    cursor: pointer;

    & + & {
        margin-left: 10px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
            border: ${colors.darkTeal} solid 1px;
        }
    }
`;

const Title = styled(HideOverflow)`
    font-size: 12px;
    line-height: 16px;
    width: 100%;
`;

const NoUsername = styled(Title)`
    font-style: italic;
    font-weight: 300;
    color: ${colors.primaryGray};
`;

const Subtitle = styled(HideOverflow)`
    font-size: ${fonts.smallSubtitle}px;
    line-height: 16px;
    color: ${colors.primaryGray};
    margin-bottom: 6px;
    width: 100%;
`;

const CloseButton = styled("img")`
    padding: 12px 12px 6px 6px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    opacity: 0.4;
`;

interface Props {
    recommendation: Connection;
    postRecommendation: (params: PostRecommendationParams) => void;
    observer: IntersectionObserver | null;
    push: (path: string) => void;
}

const RecommendationCell = ({
    recommendation,
    postRecommendation,
    observer,
    push
}: Props) => {
    const mainRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const target = mainRef.current;

        if (observer && target) {
            observer.observe(target);
        }

        return () => {
            if (observer && target) {
                observer.unobserve(target);
            }
        };
    }, [observer]);

    const userid = recommendation.other.id.slice(2);

    return (
        <Main
            ref={mainRef}
            data-otherid={recommendation.other.id}
            onClick={() => {
                const path = `/public/${userid}`;
                if (window.location.pathname !== path) {
                    push(path);
                }
            }}
        >
            <ProfilePhoto
                profilePhotoUrl={recommendation.other.profile.avatar}
                status={recommendation.other.profile.status}
                photoSize={60}
                style={{ marginBottom: "6px" }}
            />
            {recommendation.other.profile.username ? (
                <Title>@{recommendation.other.profile.username}</Title>
            ) : (
                <NoUsername>No Username</NoUsername>
            )}
            <Subtitle>
                <strong>
                    {shortenNumber(
                        recommendation.other.profile.tagLine.followerCount,
                        1
                    )}
                </strong>{" "}
                on{" "}
                {
                    (
                        Platforms[
                            recommendation.other.profile.tagLine.platform
                        ] || Platforms.other
                    ).title
                }
            </Subtitle>
            {recommendation.state ? (
                <OutlineRecommendationButton disabled>
                    Requested
                </OutlineRecommendationButton>
            ) : (
                <RecommendationButton
                    onClick={e => {
                        e.stopPropagation();
                        postRecommendation({
                            selfId: recommendation.entity.id,
                            otherId: recommendation.other.id,
                            event: "click"
                        });
                    }}
                >
                    Connect
                </RecommendationButton>
            )}
            <CloseButton
                src="/black_close.svg"
                onClick={e => {
                    e.stopPropagation();
                    postRecommendation({
                        selfId: recommendation.entity.id,
                        otherId: recommendation.other.id,
                        event: "block"
                    });
                }}
            />
        </Main>
    );
};

export default RecommendationCell;
