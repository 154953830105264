import { responsiveContainer } from "src/utils/styles/snippets";
import styled from "styled-components";

interface MainProps {
    debug?: true;
    addSidePadding?: true;
    addBottomPadding?: true;
}

const MainContainer = styled("main")<MainProps>`
    ${responsiveContainer()};
    padding: ${props => props.addSidePadding && `0px 15px`};
    padding-bottom: ${props => props.addBottomPadding && "15px"};
    border: ${props => props.debug && "red solid 1px"};
`;

export default MainContainer;
