import { ApprovalStatus } from "../../../types/ApprovalStatus";
import { Transaction } from "../../../types/Transaction";

export interface Buyer {
    id: number;
    name: string | null;
    photoUrl: string | null;
    publisherId: number | null;
    campaignId: number | null;
    advertiserId: number | null;
}

export interface DirectBuy {
    id: number;
    buyerId: number;
    buyer: Buyer;
    publisherId: number;
    publisher: {
        id: number;
        name: string | null;
        photoUrl: string | null;
    };
    offerStartsAt: string;
    offerExpiresAt: string | null;
    offerViewedAt: string | null;
    offerStatus: ApprovalStatus;
    offerApprovedAt: string | null;
    offerRejectionReason: string | null;
    buyDuration: number | null;
    buyStartsAt: string | null;
    buyExpiresAt: string | null;
    buyCompletedAt: string | null;
    buyStatus: ApprovalStatus;
    buyApprovedAt: string | null;
    buyRejectionReason: string | null;
    isRecentlyCompletedBuy?: boolean;
    publisherPayoutId: number | null;
    publisherPayout: Transaction | null;
    directBuySteps?: number[];
}

export enum DirectBuyStatus {
    available = "available",
    active = "active",
    completed = "completed",
    expired = "expired"
}

export const directBuyStatusAll = [
    DirectBuyStatus.available,
    DirectBuyStatus.active,
    DirectBuyStatus.completed,
    DirectBuyStatus.expired
];

export enum DirectBuyState {
    new,
    viewed,
    active,
    pending,
    rejected,
    expired,
    approved
}

export enum DirectBuyStage {
    offer,
    buy
}
