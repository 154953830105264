import React from "react";
import styled from "styled-components";
import { colors } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import CheckBox from "src/components/CheckBox";
import ShadeScreen from "../components/ShadeScreen";
import MediaTextOverlay from "./MediaTextOverlay";
import MediaButtons from "./MediaButtons";

const Main = styled.div<{ $isSelected?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: ${props =>
        props.$isSelected ? `0px 0px 0px 4px ${colors.primaryBlue}` : `none`};
    cursor: pointer;
    user-select: none;
`;

const MediaWrapper = styled.div<{ $clickable: boolean }>`
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    flex: 1;
    overflow: hidden;
    position: relative;
    cursor: ${props => (props.$clickable ? "pointer" : "initial")};
`;

const CoverPhoto = styled.img`
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

interface Props {
    assetId: number;
    handleClick: () => void;
    isSelected: boolean;
}

const DynamicAssetCell = ({ assetId, handleClick, isSelected }: Props) => {
    const asset = useSelector(
        (state: GlobalState) => state.entities.assets[assetId]
    );

    return (
        <Main $isSelected={isSelected}>
            <MediaWrapper
                onClick={handleClick}
                $clickable={Boolean(handleClick)}
            >
                <CoverPhoto
                    src={asset.coverPhotoUrl || ""}
                    onClick={() => true}
                />
                <ShadeScreen>
                    {asset.type === "video" && <img src={"/play_icon.svg"} />}
                </ShadeScreen>
                <MediaTextOverlay
                    title={`Asset ID: ${asset.id}`}
                    titleSize={13}
                    wrapTitle
                />
            </MediaWrapper>
            <MediaButtons
                inCard
                mediaId={asset.id}
                mediaUrl={asset.url}
                linkCallback={() => true}
                borderRadius={6}
                buttonHideLink
            />
        </Main>
    );
};

export default DynamicAssetCell;
