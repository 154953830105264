import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";

interface Props {
    queryString: string;
    setQueryString: (searchString: string) => void;
}

const SearchBox = ({ queryString, setQueryString }: Props) => {
    return (
        <div>
            <SearchBar
                searchString={queryString}
                updateSearchString={setQueryString}
            />
            {/* {isSearchStalled ? "My search is stalled" : ""} */}
        </div>
    );
};

export default SearchBox;
