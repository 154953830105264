import React from "react";
import ProfilePhoto from "../../../components/ProfilePhoto";
import styled from "styled-components";
import VerticallyCentered from "../../../components/VerticallyCentered";
import { colors } from "../../../constants";

const Main = styled(VerticallyCentered)`
    padding: 15px;
    text-align: left;
`;

const TextContainer = styled.div`
    width: calc(100% - 68px);
`;

const TitleContainer = styled(VerticallyCentered)`
    justify-content: space-between;
    margin-bottom: 4px;
`;

const Title = styled.div`
    font-weight: 500;
`;

const Link = styled.div`
    color: ${colors.primaryBlue};
    user-select: none;
    cursor: pointer;
`;

const Subtitle = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: ${colors.primaryGray};
`;

interface Props {
    coverUrl: string;
    name: string;
    members: number;
    editCallback: VoidFunction;
}

const SettingsHeader = ({ coverUrl, name, members, editCallback }: Props) => {
    return (
        <Main>
            <ProfilePhoto
                profilePhotoUrl={coverUrl}
                photoSize={56}
                style={{ marginRight: "12px" }}
            />
            <TextContainer>
                <TitleContainer>
                    <Title>{name || "No Name"}</Title>
                    <Link onClick={editCallback}>Edit</Link>
                </TitleContainer>
                <Subtitle>
                    <strong>{members}</strong> members
                </Subtitle>
            </TextContainer>
        </Main>
    );
};

export default SettingsHeader;
