import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import ProfilePhoto from "src/components/ProfilePhoto";
import { colors } from "src/constants";
import ErrorModal from "src/modals/ErrorModal";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import { updateGroupChannelInfo } from "../actions";
import EditGroupChannelModal from "../modals/EditGroupChannelModal";
import { selectConversationOwnAdminStatus } from "../selectors";

const Header = styled.div`
    ${FlexVerticallyCentered};

    & > * ~ * {
        margin-left: 12px;
    }
`;

const TextContainer = styled.div`
    text-align: left;
    flex: 1;
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
`;

const MemberCount = styled.div`
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.primaryGray};
`;

const Edit = styled.div`
    color: ${colors.primaryBlue};
    font-size: 14px;
    align-self: flex-start;
`;

interface Props {
    conversationId: string;
    imageUrl?: string;
    displayName?: string;
    memberCount: number;
}

const GroupHeader = ({
    conversationId,
    imageUrl,
    displayName,
    memberCount
}: Props) => {
    const dispatch = useDispatch();
    const [editChannelModal, toggleEditChannelModal] = useState(false);
    const [errorModal, toggleErrorModal] = useState(false);
    const isGroupAdmin = useSelector((state: GlobalState) =>
        selectConversationOwnAdminStatus(state, conversationId)
    );

    function saveChanges(params: { imageId?: string; groupName?: string }) {
        const { imageId, groupName } = params;

        dispatch(
            updateGroupChannelInfo({
                conversationId,
                ...(imageId && { imageId }),
                ...(displayName !== groupName && {
                    displayName: groupName
                })
            })
        );

        toggleEditChannelModal(false);
    }

    function edit() {
        if (isGroupAdmin) {
            toggleEditChannelModal(true);
        } else {
            toggleErrorModal(true);
        }
    }

    return (
        <>
            <Header onClick={edit}>
                <ProfilePhoto
                    profilePhotoUrl={imageUrl || "/group-chat-default.svg"}
                    photoSize={56}
                />
                <TextContainer>
                    <TitleContainer>
                        <Title>{displayName || "No Name"}</Title>
                        {isGroupAdmin && <Edit>Edit</Edit>}
                    </TitleContainer>
                    <MemberCount>
                        <b>{memberCount}</b> Members
                    </MemberCount>
                </TextContainer>
            </Header>
            {editChannelModal && (
                <EditGroupChannelModal
                    title="Edit this group's name:"
                    actionText="Save and Close"
                    imageUrl={imageUrl}
                    displayName={displayName || ""}
                    clearModal={() => toggleEditChannelModal(false)}
                    saveChanges={saveChanges}
                />
            )}
            {errorModal && (
                <ErrorModal
                    closeModal={() => toggleErrorModal(false)}
                    message="Only administrators can edit group information."
                />
            )}
        </>
    );
};

export default GroupHeader;
