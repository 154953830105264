import React from "react";
import { ReactComponent as Caret } from "src/components/icons/caret-small.svg";
import { ReactComponent as BlackCaret } from "src/components/icons/caret-small-black.svg";
import { colors } from "src/constants";
import styled from "styled-components";

const Toggle = styled.div`
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Circle = styled.div<{ invert?: boolean }>`
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: ${props => (props.invert ? colors.white : colors.black)};
    display: inline-block;
    margin-left: 8px;
    display: grid;
    place-items: center;
`;

const StyledCaret = styled(Caret)<{ open: boolean }>`
    transform: ${props => (props.open ? "rotate(180deg)" : "revert")};
`;

const StyledBlackCaret = styled(BlackCaret)<{ open: boolean }>`
    transform: ${props => (props.open ? "rotate(180deg)" : "revert")};
`;

const DropdownCaret = ({
    open,
    invert
}: {
    open: boolean;
    invert?: boolean;
}) => {
    return (
        <Toggle>
            <Circle invert={invert}>
                {invert ? (
                    <StyledBlackCaret open={open} />
                ) : (
                    <StyledCaret open={open} />
                )}
            </Circle>
        </Toggle>
    );
};

export default DropdownCaret;
