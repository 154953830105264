import React, { useState } from "react";
import { SocialAccount } from "../../types";
import { colors, fonts } from "../../../constants";
import styled from "styled-components";
import AccountCell from "../../accounts/components/AccountCell";
import PrivacyAccountModal from "../../accounts/private/modals/PrivacyAccountModal";

const Title = styled.div`
    color: ${colors.primaryGray};
    font-weight: bold;
    font-size: ${fonts.subtitle}px;
    line-height: 28px;
    margin: 5px 0 10px;
`;

const ListContainer = styled.div`
    padding-bottom: 18px;
`;

interface Props {
    editedAccounts: SocialAccount[];
    setEditedAccounts: (editedAccounts: SocialAccount[]) => void;
}

const AccountsCard = ({ editedAccounts, setEditedAccounts }: Props) => {
    const [currentAccount, setCurrentAccount] = useState<SocialAccount | null>(
        null
    );

    return (
        <>
            <Title>YOUR SOCIAL ACCOUNTS</Title>
            <ListContainer>
                {editedAccounts.map(account => {
                    return (
                        <AccountCell
                            account={account}
                            privacyCallback={() => {
                                setCurrentAccount(account);
                            }}
                        />
                    );
                })}
            </ListContainer>
            {currentAccount && (
                <PrivacyAccountModal
                    account={currentAccount}
                    cancelCallback={() => setCurrentAccount(null)}
                    doneCallback={newVisibility => {
                        setEditedAccounts(
                            editedAccounts.map(oldAccount => {
                                if (currentAccount.id === oldAccount.id) {
                                    return {
                                        ...currentAccount,
                                        visibility: newVisibility
                                    };
                                } else {
                                    return oldAccount;
                                }
                            })
                        );
                        setCurrentAccount(null);
                    }}
                />
            )}
        </>
    );
};

export default AccountsCard;
