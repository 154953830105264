import { all, call, put, takeEvery } from "@redux-saga/core/effects";
import { getApi, post } from "src/Api";
import { Action } from "src/types";
import {
    createCustomLinkFailure,
    createCustomLinkSuccess,
    CREATE_CUSTOM_LINK,
    fetchCustomLinksFailure,
    fetchCustomLinksSuccess,
    fetchLinkForPlugFailure,
    fetchLinkForPlugSuccess,
    fetchLinkForPlugWithoutModalSuccess,
    FETCH_CUSTOM_LINKS,
    FETCH_LINK_FOR_PLUG,
    FETCH_LINK_FOR_PLUG_WITHOUT_MODAL
} from "./actions";

function* fetchLinkForPlugRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            getApi,
            "/get_link_for_plug_with_media2",
            {
                userid: localStorage.getItem("userid"),
                mediaid: payload.id,
                external_page_id: payload.externalPageId,
                plugid: payload.campaignId,
                is_portrait: payload.isPortrait
            }
        );

        if (status === 200) {
            yield put(fetchLinkForPlugSuccess(data));
        } else {
            window.alert("Something went wrong. Please Refresh.");
            yield put(fetchLinkForPlugFailure(payload));
        }
    } catch (error) {
        window.alert("Something went wrong. Please Refresh.");
        yield put(fetchLinkForPlugFailure(payload));
    }
}

function* fetchLinkForPlugWithoutModalRequest({ payload }: Action) {
    try {
        const { data } = yield call(getApi, "/get_link_for_plug_with_media2", {
            userid: localStorage.getItem("userid"),
            mediaid: payload.id,
            external_page_id: payload.externalPageId,
            plugid: payload.campaignId,
            is_portrait: payload.isPortrait
        });
        yield put(fetchLinkForPlugWithoutModalSuccess(data));
    } catch (error) {
        console.log(error);
        window.alert("Something went wrong. Please Refresh.");
    }
}

function* fetchCustomLinksRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            getApi,
            "/get_custom_links_for_campaign",
            payload
        );

        if (status === 200) {
            yield put(fetchCustomLinksSuccess(data));
        } else {
            yield put(fetchCustomLinksFailure("Fetch custom links failure"));
        }
    } catch (error) {
        yield put(fetchCustomLinksFailure("Fetch custom links failure"));
    }
}

function* createCustomLinkRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/api/create_new_custom_link",
            payload
        );

        if (status === 200) {
            yield put(createCustomLinkSuccess(data));
        } else {
            yield put(createCustomLinkFailure("Create custom link failure"));
        }
    } catch (error) {
        yield put(createCustomLinkFailure("Create custom link failure"));
    }
}

export default function* linksSaga() {
    yield all([
        takeEvery(FETCH_LINK_FOR_PLUG.REQUEST, fetchLinkForPlugRequest),
        takeEvery(FETCH_CUSTOM_LINKS.REQUEST, fetchCustomLinksRequest),
        takeEvery(CREATE_CUSTOM_LINK.REQUEST, createCustomLinkRequest),
        takeEvery(
            FETCH_LINK_FOR_PLUG_WITHOUT_MODAL.REQUEST,
            fetchLinkForPlugWithoutModalRequest
        )
    ]);
}
