import { StringFormat } from "@firebase/storage-types";
import te from "date-fns/esm/locale/te/index.js";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "src/Api";
import Header from "src/components/Header";
import { colors } from "src/constants";
import { storage } from "src/firebase/FirebaseConfig";
import { GlobalState } from "src/reducers";
import { fileUpload } from "src/util";
import styled from "styled-components";
import {
    fetchScreenshots,
    removeHiddenScreenshots,
    setHiddenScreenshots,
    setScreenshots
} from "../redux/actions";
import { PublicBuysScreenshot } from "../redux/reducer";
import { selectPublicScreenshots } from "../redux/selectors";
import { PBData } from "./PublicBuys";
import PublicBuysFilters, { PublicBuysFilter } from "./PublicBuysFilters";
import PublicBuysScreenshotPreview from "./PublicBuysScreenshot";
import PublicBuyScreenshotModal from "./PublicBuysScreenshotModal";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    background: ${colors.quaternaryLightGray};
    border-radius: 16px;
    padding: 16px 10px;
`;

const ScreenshotContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    height: 100%;
    margin-top: 12px;
    overflow: auto;
    /* justify-content: center; */
`;

const initialFilters = [
    { name: "Show Assigned", values: [], type: "checkbox" }
];
interface Props {
    publisher: PBData;
}

const PublicBuysScreenShots = ({ publisher }: Props) => {
    const [filters, setFilters] = useState<PublicBuysFilter[]>(initialFilters);
    const [search, setSearch] = useState("");
    const [numLoading, setNumLoading] = useState(0);
    const [selected, setSelected] = useState<PublicBuysScreenshot>();
    const dispatch = useDispatch();

    const screenshots = useSelector(selectPublicScreenshots);
    const hidden = useSelector((state: GlobalState) => state.publicBuys.hidden);
    const assigned = useSelector(
        (state: GlobalState) => state.publicBuys.assigned
    );
    useEffect(() => {
        dispatch(fetchScreenshots(search, filters[0].values[0] === "true"));
    }, [publisher, search, filters]);

    const uploadRef = useRef<HTMLInputElement>(null);

    const onHide = (src: string) => {
        if (hidden.includes(src)) dispatch(removeHiddenScreenshots(src));
        else dispatch(setHiddenScreenshots(hidden.concat(src)));
    };

    const editScreenshot = (url: string, status: string, filename?: string) => {
        setNumLoading(numLoading === 0 ? 0 : numLoading - 1);
        let uploadedBy = localStorage.getItem("employeeToken")
            ? "employee"
            : localStorage.getItem("userid")
            ? "owner"
            : "public";
        if (filename) {
            post("/public/buys/screenshot", { filename, uploadedBy });
        }
        let temp = screenshots;
        temp.filter(screenshot => screenshot.screenshotUrl === url);
        temp.unshift({
            screenshotUrl: url,
            status,
            publisherId: publisher.id,
            uploadedBy: uploadedBy
        });
        dispatch(setScreenshots(temp.map(screenshot => screenshot)));
    };

    const uploadFiles = (e: any) => {
        e.preventDefault();
        if (!e.currentTarget.files.length) return;
        const files: File[] = Object.values(e.currentTarget.files);
        for (let i = 0; i < files.length; i++) {
            setNumLoading(numLoading + files.length);
            fileUpload(
                files[i],
                "directBuyScreenshot/",
                storage,
                (filename, type, url) =>
                    editScreenshot(url, "loading", filename),
                url => editScreenshot(url, "error")
            );
        }
    };

    const checkAssigned = (id: number) => {
        let found = false;
        assigned.forEach(shot => {
            if (shot.screenshotId === id) found = true;
        });
        return found;
    };

    return (
        <Main>
            <Header
                title={"Screenshots"}
                subtitle=""
                desktop={true}
                links={[
                    {
                        text: "Upload",
                        onClick: () => uploadRef.current?.click()
                    }
                ]}
                sidePadding={16}
            />
            <Container>
                <PublicBuysFilters
                    filters={filters}
                    search={search}
                    updateSearch={setSearch}
                    setFilters={setFilters}
                    buys={[]}
                />
                <Droppable droppableId="public-buys-screenshot-previews-container">
                    {(provided: DroppableProvided) => (
                        <ScreenshotContainer
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {Array.from(Array(numLoading)).map(
                                (screenshot, index) => (
                                    <PublicBuysScreenshotPreview
                                        key={`${index} screenshotLoading`}
                                        src={""}
                                        status="loading"
                                    />
                                )
                            )}
                            {screenshots.map((screenshot, index) =>
                                !screenshot.screenshotUrl ||
                                (!hidden.includes(screenshot.screenshotUrl) &&
                                    !checkAssigned(screenshot.id || 0)) ? (
                                    <PublicBuysScreenshotPreview
                                        key={`${index} screenshotPreview`}
                                        src={screenshot.screenshotUrl || ""}
                                        status={screenshot.status}
                                        onClick={() => setSelected(screenshot)}
                                        onHide={src => onHide(src)}
                                        info={
                                            moment(screenshot.createdAt).format(
                                                "lll"
                                            ) +
                                            "\n Uploaded By: " +
                                            screenshot.uploadedBy
                                        }
                                        screenshotId={screenshot.id}
                                        index={index}
                                    />
                                ) : null
                            )}

                            {provided.placeholder}
                        </ScreenshotContainer>
                    )}
                </Droppable>
            </Container>
            <input
                style={{ display: "none" }}
                ref={uploadRef}
                type="file"
                multiple
                accept="image/*"
                onChange={uploadFiles}
            />
            {selected && (
                <PublicBuyScreenshotModal
                    screenshot={selected}
                    onClose={() => setSelected(undefined)}
                />
            )}
        </Main>
    );
};
export default PublicBuysScreenShots;
