import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentOpenBuy } from "src/admin-tools/selectors.admin-tools";
import { fetchOrderedCampaigns } from "src/campaigns/selectors";
import { Campaign } from "src/campaigns/types";
import SearchBar from "src/components/SearchBar";
import { colors } from "src/constants";
import SelectionHeader from "src/marketplace/components/SelectionHeader";
import CampaignSelectionRow from "src/marketplace/instructions-sets/components/CampaignSelectionRow";
import {
    SelectionHeaderLoading,
    SelectionHeaderLoadingContainer
} from "src/marketplace/instructions-sets/SkeletonLoader";
import { GlobalState } from "src/reducers";
import { setDetailsUnsaved } from "src/ui/admin-tools/actions";
import styled from "styled-components";
import ChallengeFormSubSection from "./ChallengeFormSubSection";
import ChallengeLogisticsDropdown from "./ChallengeLogisticsDropdown";

const CampaignHeader = styled.header<{ expanded: boolean }>`
    position: sticky;
    top: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    margin-bottom: ${props => props.expanded && "15px"};
`;

const CampaignIncrementor = styled.div`
    width: 100%;
    height: 1px;
    border: ${colors.transparent} solid 4px;
`;

interface Props {
    currentCampaign?: Campaign;
}

const ChallengeLogisticsAssociatedCampaign = ({ currentCampaign }: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();
    const { ref, inView } = useInView();

    // Redux State ---------------------------------------------------
    const campaigns = useSelector(fetchOrderedCampaigns);
    const currentOpenBuy = useSelector(getCurrentOpenBuy);
    const { challengeDetailsIncomplete } = useSelector((state: GlobalState) => {
        return state.ui.adminTools;
    });

    // Local State ---------------------------------------------------
    const [maxCampaigns, setMaxCampaigns] = useState(12);
    const [campaignSearchString, setCampaignSearchString] = useState("");

    const [
        associatedCampaignDropdownExpanded,
        setAssociatedCampaignDropdownExpanded
    ] = useState(false);

    // Local Functions -----------------------------------------------
    function incrementMaxCampaigns(): void {
        if (maxCampaigns + 12 > campaigns.length) {
            setMaxCampaigns(campaigns.length);
        } else {
            setMaxCampaigns(maxCampaigns + 12);
        }
    }

    function toggleAssociatedCampaignDropdown(): void {
        setAssociatedCampaignDropdownExpanded(
            !associatedCampaignDropdownExpanded
        );
    }

    function handleCampaignClick(campaignId: number): void {
        setAssociatedCampaignDropdownExpanded(false);
    }

    // Effects -------------------------------------------------------
    useEffect(() => {
        if (inView) incrementMaxCampaigns();
    }, [inView]);

    // JSX -----------------------------------------------------------
    return (
        <ChallengeFormSubSection
            title="*ASSOCIATED CAMPAIGN"
            error={{
                isError: challengeDetailsIncomplete && !currentCampaign,
                message: "You must set a campaign."
            }}
            children={
                <ChallengeLogisticsDropdown
                    expanded={associatedCampaignDropdownExpanded}
                >
                    <CampaignHeader
                        expanded={associatedCampaignDropdownExpanded}
                    >
                        <SelectionHeader
                            onClick={toggleAssociatedCampaignDropdown}
                            title={
                                !currentCampaign
                                    ? "Select a Campaign"
                                    : currentCampaign.campaignName
                            }
                            selected={associatedCampaignDropdownExpanded}
                            isDropdown
                        />
                        {associatedCampaignDropdownExpanded && (
                            <SearchBar
                                searchString={campaignSearchString}
                                updateSearchString={setCampaignSearchString}
                                autoFocus={false}
                                wrapped
                            />
                        )}
                    </CampaignHeader>
                    {campaigns.length < 1 ? (
                        <SelectionHeaderLoadingContainer
                            style={{ marginTop: "10px" }}
                        >
                            <SelectionHeaderLoading key="challenge logistics associated campaign loader 1" />
                            <SelectionHeaderLoading key="challenge logistics associated campaign loader 2" />
                            <SelectionHeaderLoading key="challenge logistics associated campaign loader 3" />
                            <SelectionHeaderLoading key="challenge logistics associated campaign loader 4" />
                        </SelectionHeaderLoadingContainer>
                    ) : (
                        associatedCampaignDropdownExpanded &&
                        campaigns
                            .filter((campaign: Campaign) => {
                                return campaign.campaignName
                                    .toLowerCase()
                                    .includes(
                                        campaignSearchString.toLowerCase()
                                    );
                            })
                            .slice(0, maxCampaigns)
                            .map((campaign: Campaign) => {
                                return (
                                    <div
                                        key={`marketplace input challenge logistics campaign selection row ${campaign.id}`}
                                        onClick={() =>
                                            handleCampaignClick(campaign.id)
                                        }
                                    >
                                        <CampaignSelectionRow
                                            campaignId={campaign.id}
                                            campaignName={campaign.campaignName}
                                            campaignImageUrl={campaign.imageUrl}
                                            campaignCountryPlatformReward={
                                                campaign.countryPlatformReward
                                            }
                                            campaignRewardType={
                                                campaign.rewardType
                                            }
                                        />
                                    </div>
                                );
                            })
                    )}
                    {campaigns.length > 0 && <CampaignIncrementor ref={ref} />}
                </ChallengeLogisticsDropdown>
            }
        />
    );
};

export default ChallengeLogisticsAssociatedCampaign;
