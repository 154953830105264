import { FlexVerticallyCentered } from "src/components/CommonStyles";
import LabelText from "src/components/text/LabelText";
import { colors } from "src/constants";
import GenericContainer from "src/profile/components/GenericContainer";
import styled from "styled-components";

export const Label = styled(LabelText)`
    margin-bottom: 12px;
`;

export const OptionList = styled(GenericContainer)`
    padding: 0;
    overflow: hidden;

    & > * ~ * {
        border-top: 1px solid ${colors.mediumTeal};
    }
`;

export const OptionText = styled.div`
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Option = styled.div`
    ${FlexVerticallyCentered}
    height: 48px;
    padding: 0 11px 0 16px;
    cursor: pointer;
    user-select: none;
    transition: 0.1s ease-in;

    * ~ ${OptionText} {
        margin-left: 12px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

export const Checkbox = styled.img`
    margin-left: auto;
`;
