import React from "react";
import { createGlobalStyle } from "styled-components";

import Observer from "src/wrappers/Observer";
import { colors } from "src/constants";

import sfPro from "../fonts/SF-Pro.ttf";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: sfPro;
    src: url(${sfPro}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  html, body {
    position: relative;
    width: 100%;
    max-width: 100vw;
    margin: 0px;
    padding: 0px;
    border: none;
    box-sizing: border-box;
    font-family: -apple-system, sfPro, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  }

  body {
    overflow-y: scroll;
  }

  button {
    background-color: ${colors.transparent};
    border: none;
    border-radius: 0rem;
    outline: none;
    cursor: pointer;
  }
  
  button:focus {
    outline: none;
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
  }

  // The code below was taken from the old deleted PlugApp.css, which was just the bootstrapped CSS file
  // that came with create-react-app. Feel free to remove if it isn't needed anymore.

  /* #root { */
    /* margin: 0 auto;
    width: 100vw;
    max-width: 500px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  /* } */

  /* @media only screen and (min-width: 500px) { */
    /* #root {
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
        min-height: 100vh;
    } */
    /* body {
        overflow-y: scroll;
        margin: 0 auto;
        left: 0;
        right: 0;
    } */
  /* } */
`;

interface Props {
    children: React.ReactNode;
}

const Layout = (props: Props) => {
    const { children } = props;

    return (
        <>
            <GlobalStyles />
            <Observer>{children}</Observer>
        </>
    );
};

export default Layout;
