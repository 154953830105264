import React from "react";
import styled from "styled-components";
import AuthInputScreen, { ClearInput } from "./AuthInputScreen";
import { getItem } from "src/util";
import SpinLoader from "src/components/SpinLoader";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";

const ClearButton = styled.div`
    height: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
`;

const Wrapper = styled.div`
    position: relative;
`;

const LoadingWrapper = styled.div<{ loading: boolean }>`
    display: ${props => (props.loading ? "flex" : "none")};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    margin-left: 100px;
`;

interface Props {
    backClicked: () => void;
    phoneNumber: string;
    authCode: string;
    setAuthCode: (authCode: string) => void;
    resendClicked: () => void;
    callClicked: () => void;
}

const PhoneAuthScreen = ({
    backClicked,
    phoneNumber,
    authCode,
    setAuthCode,
    resendClicked,
    callClicked
}: Props) => {
    const phoneAuthLoading = useSelector(
        (state: GlobalState) => state.ui.auth.phoneAuthLoading
    );
    if (phoneNumber.length === 0) backClicked();

    const isDev = getItem("devAccount");

    return (
        <>
            {isDev && <p>Hi, dev. Here's your auth code: {isDev.authCode}</p>}
            <AuthInputScreen
                backClicked={backClicked}
                description={`Enter the 4 digit code sent to your phone ${phoneNumber}`}
                input={
                    <Wrapper>
                        <ClearInput
                            placeholder="• • • •"
                            value={authCode}
                            onChange={(e: any) => {
                                if (
                                    e.currentTarget.value.match(
                                        /^-{0,1}\d+$/
                                    ) ||
                                    e.currentTarget.value === ""
                                ) {
                                    setAuthCode(
                                        e.currentTarget.value.slice(0, 4)
                                    );
                                }
                            }}
                        />
                        <LoadingWrapper loading={phoneAuthLoading}>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </LoadingWrapper>
                    </Wrapper>
                }
                button={
                    <ClearButton onClick={resendClicked}>Resend</ClearButton>
                }
                secondButton={
                    <ClearButton onClick={callClicked}>
                        Call Me Instead
                    </ClearButton>
                }
            />
        </>
    );
};

export default PhoneAuthScreen;
