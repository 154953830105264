import React, { useEffect } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { AnalyticsModal } from "./reducer";
import { CustomLinkEarnings, DailyEarnings } from "./Analytics";
import { fetchDailyCampaignLinkAnalytics } from "./actions";
import { goBack } from "connected-react-router";
import { coinToDollars, formatNumber } from "../util";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import NavButton from "../components/NavButton";
import SimpleNavbar from "../components/SimpleNavbar";
import CoinSummary from "../components/CoinSummary";
import LinkEarningsCell from "./LinkEarningsCell";
import MediaModal from "./MediaModal";
import FullscreenLoader from "../components/FullscreenLoader";
import { colors } from "../constants";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";

const NoActivityContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
`;

const NoActivityText = styled.p`
    color: ${colors.secondaryGray};
    margin-bottom: 30px;
`;

const List = styled.div`
    padding: 10px 15px 15px;
`;

interface Props {
    currentDailyEarnings: DailyEarnings | null;
    dailyCustomLinkEarnings: CustomLinkEarnings[];
    customLinksLoading: boolean;
    modal: AnalyticsModal;
    goBack: () => void;
    fetchDailyCampaignLinkAnalytics: (campaignId: number) => void;
}

const DailyLinksPage = ({
    currentDailyEarnings,
    dailyCustomLinkEarnings,
    customLinksLoading,
    modal,
    goBack,
    fetchDailyCampaignLinkAnalytics
}: Props) => {
    useEffect(() => {
        if (currentDailyEarnings) {
            fetchDailyCampaignLinkAnalytics(currentDailyEarnings.campaign_id);
        }
    }, [currentDailyEarnings]);

    const totalCoins =
        Math.round(
            dailyCustomLinkEarnings.reduce((acc, earnings) => {
                return acc + earnings.plugcoins;
            }, 0) * 10
        ) / 10;

    const leftNavButton = <NavButton onClick={() => goBack()} text={"Back"} />;

    if (!currentDailyEarnings) {
        goBack();
        return null;
    } else if (customLinksLoading) {
        return (
            <>
                <Navbar back marginBottom={10} />
                <FullscreenLoader />
            </>
        );
    } else if (dailyCustomLinkEarnings.length === 0) {
        return (
            <>
                <Navbar back marginBottom={10} />
                <NoActivityContainer>
                    <NoActivityText>No recent activity</NoActivityText>
                    <Button
                        variant="outline-success"
                        style={{ width: "150px" }}
                        onClick={() => {
                            if (currentDailyEarnings) {
                                fetchDailyCampaignLinkAnalytics(
                                    currentDailyEarnings.campaign_id
                                );
                            }
                        }}
                    >
                        Reload
                    </Button>
                </NoActivityContainer>
            </>
        );
    } else {
        return (
            <>
                <Navbar back marginBottom={10} />

                <MainContainer>
                    <CoinSummary
                        topLeft={"Earnings Per Link"}
                        topRight={`${formatNumber(coinToDollars(totalCoins))}`}
                        bottomLeft={"Last 24 hours"}
                        bottomRight={``}
                    />
                    <List>
                        {dailyCustomLinkEarnings.map((earnings, idx) => {
                            return (
                                <LinkEarningsCell
                                    key={idx}
                                    earnings={earnings}
                                />
                            );
                        })}
                    </List>
                </MainContainer>

                {modal === AnalyticsModal.media && <MediaModal />}
            </>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    currentDailyEarnings: state.analytics.currentDailyEarnings,
    dailyCustomLinkEarnings: state.analytics.dailyCustomLinkEarnings,
    customLinksLoading: state.analytics.customLinksLoading,
    modal: state.analytics.modal
});

const mapDispatchToProps = (dispatch: any) => ({
    goBack: () => dispatch(goBack()),
    fetchDailyCampaignLinkAnalytics: (campaignId: number) =>
        dispatch(fetchDailyCampaignLinkAnalytics(campaignId))
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyLinksPage);
