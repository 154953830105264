import React from "react";
import { colors, maxWidthDesktop, fonts } from "src/constants";
import styled from "styled-components";

interface TabProps {
    active: boolean;
}

const Main = styled.div<TabProps>`
    flex: 1;
    font-size: ${fonts.subtitle}px;
    line-height: 14px;
    padding: 20px 0;
    cursor: pointer;
    color: ${props => (props.active ? "black" : "#909A9C")};
    background-color: ${props => props.active && colors.lightTeal};
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${props => !props.active && colors.lightGray};
        }
    }

    @media (max-width: ${maxWidthDesktop}px) {
        background-color: inherit;
        border-bottom: ${props =>
            props.active
                ? `${colors.black} solid 1px`
                : `${colors.mediumTeal} solid 1px;`};
    }
`;

interface Props {
    name: string;
    active: boolean;
    onClick: () => void;
}

const Tab = ({ name, active, onClick }: Props) => {
    return (
        <Main active={active} onClick={onClick}>
            {name}
        </Main>
    );
};

export default Tab;
