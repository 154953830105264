import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../reducers";
import { useHistory } from "react-router";
import AccountFormModal from "./AccountFormModal";

const AccountFormModalController = () => {
    const currentAccount = useSelector((state: GlobalState) => {
        return state.profile.currentAccount;
    });

    const editAccountModalOpen = useSelector((state: GlobalState) => {
        return state.ui.profile.editAccountModalOpen;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    useEffect(() => {
        if (!currentAccount && editAccountModalOpen) {
            goBack();
        }
    }, [currentAccount]);

    if (!currentAccount && editAccountModalOpen) {
        return null;
    } else if (currentAccount && editAccountModalOpen) {
        return <AccountFormModal currentAccount={currentAccount} />;
    } else {
        return <AccountFormModal />;
    }
};

export default AccountFormModalController;
