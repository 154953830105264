import React from "react";
import { BigOutlineButton } from "../../profile/components/Buttons";
import { Results, Row } from "./StyledComponents";
import Header from "../../components/Header";
import { shortenNumber } from "../../util";
import styled from "styled-components";
import { colors } from "../../constants";
import Spacer from "src/components/Spacer";
import { GlobalState } from "src/reducers";
import { shallowEqual, useSelector } from "react-redux";
import FullscreenLoader from "src/components/FullscreenLoader";
import { selectElasticSearchUI } from "../selector";
import InfiniteHits from "./InfiniteHits";
import HitsList from "./HitsList";

const IndividualHeader = styled.div`
    padding: 16px 18px 12px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    color: ${colors.primaryGray};
    text-align: left;
`;

const FiniteMain = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    @media (pointer: fine) {
        grid-gap: 10px;
    }
`;

const SeeAll = styled.div`
    padding: 0px 18px;
`;

interface Props {
    title: string;
    index: string;
    chevron?: () => void;
    infinite?: boolean;
}

const StateResults: React.FC<Props> = ({ title, index, chevron, infinite }) => {
    const { nbHits, page, pages } = useSelector(
        (state: GlobalState) => selectElasticSearchUI(state, index),
        shallowEqual
    );

    const formattedHits: string = `${shortenNumber(nbHits)}${
        nbHits === 10000 ? "+" : ""
    } Result${nbHits !== 1 ? "s" : ""}`;

    if (infinite)
        return (
            <>
                <IndividualHeader>{formattedHits}</IndividualHeader>
                <Results>
                    <InfiniteHits index={index} hasMore={page < pages} />
                </Results>
            </>
        );

    return (
        <FiniteMain>
            <Header title={title} subtitle={formattedHits} chevron={chevron} />

            <Results>
                <HitsList index={index} />
                {nbHits > 0 && (
                    <SeeAll>
                        <BigOutlineButton onClick={chevron}>
                            See All
                        </BigOutlineButton>
                    </SeeAll>
                )}
            </Results>
        </FiniteMain>
    );
};

export default StateResults;
