import React, { useRef, useState } from "react";
import Tooltip from "../../../components/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import MoreBtn from "src/components/modals/components/MoreBtn";
import { colors, fonts } from "src/constants";
import {
    InstructionElementUploadSubmissionKey,
    UploadSubmissionType
} from "src/marketplace/utils/types";
import { GlobalState } from "src/reducers";
import {
    InstructionStepState,
    setInstructionSteps
} from "src/ui/instructions-sets/actions";
import { clearSpacing } from "src/utils/styles/snippets";
import styled from "styled-components";
import Toggler from "src/components/Toggler";
import PlugLinkTooltipMenu from "./PlugLinkTooltipMenu";

const Main = styled.article`
    padding: 12px;
    color: ${colors.black};
    background-color: ${colors.blueGrey};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 12px 0px 12px;
    width: 100%;
`;

const Title = styled.h3`
    ${clearSpacing()};
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: 600;
    text-transform: uppercase;
`;

const DeleteButton = styled.img`
    width: 16px;
    height: 16px;
    cursor: pointer;
`;

const Text = styled.p`
    ${clearSpacing()};
    text-align: left;
`;

const ButtonBox = styled.div`
    display: flex;
    gap: 6px;
    position: relative;
`;

const StyledTooltip = styled(Tooltip)`
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
`;

interface Props {
    title: UploadSubmissionType | "Generate Plug Link" | "Requires Raw Media";
    text: string;
    instructionSteps: InstructionStepState[];
    currentInstructionStepIndex: number;
    currentInstructionStepKey?: InstructionElementUploadSubmissionKey;
    isPlugLinkSubmission?: true;
    toolTipContent?: React.ReactNode;
}

const InstructionStepUploadRequiredElement = ({
    title,
    text,
    instructionSteps,
    currentInstructionStepIndex,
    currentInstructionStepKey,
    isPlugLinkSubmission,
    toolTipContent
}: Props) => {
    const dispatch = useDispatch();
    const moreBtnRef = useRef(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleMoreButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setTooltipOpen(!tooltipOpen);
    };

    const pageType = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets.instructionsPageType;
    });

    function handleDelete(): void {
        const updatedInstructionSteps = Array.from(instructionSteps);
        const currentInstructionStep =
            updatedInstructionSteps[currentInstructionStepIndex];

        if (isPlugLinkSubmission) {
            currentInstructionStep["requiresPlugLink"] = false;
        } else if (currentInstructionStepKey) {
            currentInstructionStep[currentInstructionStepKey] = false;
        }

        dispatch(setInstructionSteps(updatedInstructionSteps));
    }

    return (
        <Main>
            <Header>
                <Title>{title}</Title>
                <ButtonBox>
                    {toolTipContent && (
                        <>
                            <MoreBtn
                                size={16}
                                refProp={moreBtnRef}
                                onClick={handleMoreButtonClick}
                            />
                            <StyledTooltip
                                isOpen={tooltipOpen}
                                position={"top: 30px; left: -260px"}
                                close={() => setTooltipOpen(false)}
                                zIndex={2}
                                controlRef={moreBtnRef}
                            >
                                {toolTipContent}
                            </StyledTooltip>
                        </>
                    )}
                    {pageType !== "Main" && (
                        <DeleteButton
                            src="/marketplace-instructions-delete-icon.svg"
                            alt="marketplace-instructions-delete-icon"
                            onClick={handleDelete}
                        />
                    )}
                </ButtonBox>
            </Header>
            <Text>{text}</Text>
        </Main>
    );
};

export default InstructionStepUploadRequiredElement;
