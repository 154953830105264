import React, { useState } from "react";
import { ContestPrize } from "../types";
import NavButton from "../../../components/NavButton";
import SimpleNavbar from "../../../components/SimpleNavbar";
import ChooseForm from "./form/ChooseForm";
import ContactForm from "./form/ContactForm";
import ShippingForm from "./form/ShippingForm";
import OtherForm from "./form/OtherForm";
import ConfirmForm from "./form/ConfirmForm";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";

enum ScreenState {
    choose,
    contact,
    editContact,
    shipping,
    editShipping,
    other,
    editOther,
    confirm
}

interface Props {
    prize: ContestPrize;
    close: () => void;
}

const ClaimPrize = ({ prize, close }: Props) => {
    const [type, setType] = useState<"prize" | "cash" | null>(null);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");

    const [info, setInfo] = useState("");

    const [currentScreen, setCurrentScreen] = useState(ScreenState.choose);

    const defaultNav = <SimpleNavbar title={"CLAIM PRIZE"} />;

    if (currentScreen === ScreenState.choose) {
        return (
            <>
                <Navbar
                    customMobileNavbar={
                        <SimpleNavbar
                            left={<NavButton onClick={close}>Back</NavButton>}
                            title={"CLAIM PRIZE"}
                        />
                    }
                />

                <MainContainer>
                    <ChooseForm
                        prize={prize}
                        type={type}
                        setType={setType}
                        confirm={() => setCurrentScreen(ScreenState.contact)}
                    />
                </MainContainer>
            </>
        );
    } else if (
        currentScreen === ScreenState.contact ||
        currentScreen === ScreenState.editContact
    ) {
        const navFunctions =
            currentScreen === ScreenState.contact
                ? {
                      back: () => setCurrentScreen(ScreenState.choose),
                      confirm: () => setCurrentScreen(ScreenState.shipping)
                  }
                : { confirm: () => setCurrentScreen(ScreenState.confirm) };

        return (
            <>
                <Navbar customMobileNavbar={defaultNav} />

                <MainContainer>
                    <ContactForm
                        name={name}
                        setName={setName}
                        phone={phone}
                        setPhone={setPhone}
                        email={email}
                        setEmail={setEmail}
                        {...navFunctions}
                    />
                </MainContainer>
            </>
        );
    } else if (
        currentScreen === ScreenState.shipping ||
        currentScreen === ScreenState.editShipping
    ) {
        const navFunctions =
            currentScreen === ScreenState.shipping
                ? {
                      back: () => setCurrentScreen(ScreenState.contact),
                      confirm: () => setCurrentScreen(ScreenState.other)
                  }
                : { confirm: () => setCurrentScreen(ScreenState.confirm) };

        return (
            <>
                <Navbar customMobileNavbar={defaultNav} />

                <MainContainer>
                    <ShippingForm
                        address={address}
                        setAddress={setAddress}
                        city={city}
                        setCity={setCity}
                        state={state}
                        setState={setState}
                        zip={zip}
                        setZip={setZip}
                        {...navFunctions}
                    />
                </MainContainer>
            </>
        );
    } else if (
        currentScreen === ScreenState.other ||
        currentScreen === ScreenState.editOther
    ) {
        const navFunctions =
            currentScreen === ScreenState.other
                ? {
                      back: () => setCurrentScreen(ScreenState.shipping),
                      confirm: () => setCurrentScreen(ScreenState.confirm)
                  }
                : { confirm: () => setCurrentScreen(ScreenState.confirm) };

        return (
            <>
                <Navbar customMobileNavbar={defaultNav} />

                <MainContainer>
                    <OtherForm
                        info={info}
                        setInfo={setInfo}
                        {...navFunctions}
                    />
                </MainContainer>
            </>
        );
    } else {
        return (
            <>
                <Navbar customMobileNavbar={defaultNav} />

                <MainContainer>
                    <ConfirmForm
                        prizeId={prize.id}
                        type={type}
                        name={name}
                        phone={phone}
                        email={email}
                        address={address}
                        city={city}
                        state={state}
                        zip={zip}
                        info={info}
                        editContact={() =>
                            setCurrentScreen(ScreenState.editContact)
                        }
                        editShipping={() =>
                            setCurrentScreen(ScreenState.editShipping)
                        }
                        editOther={() =>
                            setCurrentScreen(ScreenState.editOther)
                        }
                    />
                </MainContainer>
            </>
        );
    }
};

export default ClaimPrize;
