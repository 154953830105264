import { combineReducers } from "redux";
import { NormalizedAction } from "src/types";
import {
    CREATE_INSTRUCTION_STEP_PAYMENT,
    DELETE_INSTRUCTION_STEP_PAYMENT,
    FETCH_INSTRUCTION_STEPS,
    FETCH_INSTRUCTION_STEP_PAYMENT,
    NormalizedInstructionStepsResponse,
    UPDATE_INSTRUCTION_STEP_PAYMENT
} from "./actions.instruction-steps";
import { InstructionPayment } from "./types";

export interface InstructionsSetStepsEntitiesState {
    byId: NormalizedInstructionStepsResponse;
    allIds: number[];
}

const initialInstructionStepsState: InstructionsSetStepsEntitiesState = {
    byId: {},
    allIds: []
};

const updateInstructionStepPayment = (
    state: NormalizedInstructionStepsResponse,
    action: NormalizedAction
) => {
    const instructionStepPayment: InstructionPayment =
        action.payload.instructionStepPayment;
    const stepId = instructionStepPayment.instructionStepId as number;
    const instructionStep = state[stepId];
    const updatedPayments =
        instructionStep.instructionPayments?.map(
            (payment: InstructionPayment) =>
                payment.id === instructionStepPayment.id
                    ? instructionStepPayment
                    : payment
        ) || [];
    return {
        ...state,
        [stepId]: {
            ...instructionStep,
            instructionPayments: updatedPayments
        }
    };
};

function byId(
    state = initialInstructionStepsState.byId,
    action: NormalizedAction
) {
    switch (action.type) {
        case FETCH_INSTRUCTION_STEPS.SUCCESS:
            return { ...state, ...action.payload.entities.instructionSteps };

        case FETCH_INSTRUCTION_STEP_PAYMENT.SUCCESS:
            return updateInstructionStepPayment(state, action);
        case CREATE_INSTRUCTION_STEP_PAYMENT.SUCCESS:
            const newPayment: InstructionPayment =
                action.payload.instructionStepPayment;
            const id = newPayment.instructionStepId as number;
            const oldPayments = state[id].instructionPayments || [];
            return {
                ...state,
                [id]: {
                    ...state[id],
                    instructionPayments: [...oldPayments, newPayment]
                }
            };
        case UPDATE_INSTRUCTION_STEP_PAYMENT.SUCCESS:
            return updateInstructionStepPayment(state, action);
        case DELETE_INSTRUCTION_STEP_PAYMENT.SUCCESS:
            const { stepId, instructionPaymentId } = action.payload;
            const step = state[stepId];
            const newPayments = step.instructionPayments?.filter(
                payment => payment.id !== instructionPaymentId
            );
            return {
                ...state,
                [stepId]: { ...step, instructionPayments: newPayments }
            };

        default:
            return state;
    }
}

function allIds(
    state = initialInstructionStepsState.allIds,
    action: NormalizedAction
) {
    switch (action.type) {
        case FETCH_INSTRUCTION_STEPS.SUCCESS:
            return action.payload.result;

        default:
            return state;
    }
}

const instructionStepsEntitiesReducer = combineReducers<
    InstructionsSetStepsEntitiesState
>({
    byId,
    allIds
});

export default instructionStepsEntitiesReducer;
