import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { colors } from "src/constants";
import { get } from "src/Api";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import { CustomLoading } from "src/components/SkeletonLoader";
import XCard from "src/profile/components/XCard";
import MarketplaceSearchBar from "../components/MarketplaceSearchBar";
import { BlueButton } from "src/profile/components/Buttons";
import useLocalStorage from "react-use-localstorage";

const List = styled.ul`
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
`;

const getBorderColor = (status: string) => {
    switch (status) {
        case "pending":
            return `${colors.secondaryYellow}`;
        case "complete":
            return `${colors.grayBlack}`;
        default:
            return `${colors.primaryBlue}`;
    }
};

const OpenBuyWrap = styled.div`
    width: 295px;
    height: 180px;
    cursor: pointer;
    position: relative;
`;

const IndicatorBackground = styled.div<{ status: string }>`
    width: 100%;
    height: 100%;
    padding: 4px;
    border-radius: 8px;
    background: ${props => getBorderColor(props.status)};
`;

const Main = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 8px;
    overflow: hidden;
`;

const Poster = styled.img<{ pending?: boolean }>`
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${props => props.pending && "opacity: 0.5"};
`;

const Screen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        rgba(0, 0, 0, 0.5) 0%,
        transparent 40%,
        transparent 50%,
        rgba(0, 0, 0, 0.5) 100%
    );
`;

const Title = styled.p`
    margin: 0px;
    position: absolute;
    bottom: 12px;
    left: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.75px;
    color: white;
    text-align: left;
`;

const StatusTag = styled.div<{ status: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 6px;
    position: absolute;
    top: -4px;
    right: -4px;
    height: 24px;
    border-radius: 12px;
    background: ${props => getBorderColor(props.status)};
`;

const StatusLabel = styled.div`
    font-size: 11px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
`;

const StyledBlueButton = styled(BlueButton)`
    align-self: flex-end;
    padding: 8px 12px;
    margin-bottom: 10px;
`;

interface Props {
    onSelect?: (buy: OpenBuy) => void;
}

const ChallengeList = ({ onSelect }: Props) => {
    const savedShowTest = localStorage.getItem("storedShowTestChallengesFlag");
    const dispatch = useDispatch();
    const [openBuys, setOpenBuys] = useState<OpenBuy[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [search, setSearch] = useState("");
    const [showTest, setShowTest] = useState(savedShowTest === "true");

    useEffect(() => {
        localStorage.setItem(
            "storedShowTestChallengesFlag",
            showTest ? "true" : "false"
        );
    }, [showTest]);
    useEffect(() => {
        get("/v1/admin/openBuy/search", { limit: 1000, search })
            .then(res => {
                setOpenBuys(res.data.data);
            })
            .catch(e => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }, [search]);
    function scrollToTop(): void {
        window.scrollTo({
            top: 0,
            left: 0
        });
    }
    if (error) {
        return <XCard text={"Something went wrong!"} />;
    }

    const getStatus = (buy: OpenBuy) => {
        const { startsAt, endsAt } = buy;
        const now = new Date().getTime();
        const end = new Date(endsAt).getTime();

        if (endsAt && end < now) return "complete";
        else if (!startsAt) return "pending";
        else return "active";
    };

    return (
        <>
            <MarketplaceSearchBar
                placeholder="Search by challenge name"
                searchString={search}
                updateSearchString={v => setSearch(v)}
                margin={"0 0 24px"}
            />
            <StyledBlueButton onClick={() => setShowTest(s => !s)}>
                {showTest ? "Hide" : "Show"} Test Challenges
            </StyledBlueButton>
            <List>
                {loading
                    ? Array.from(Array(20).keys()).map((val, idx) => (
                          <CustomLoading
                              height="180px"
                              width="280px"
                              borderRadius="12px"
                              key={idx}
                          />
                      ))
                    : openBuys
                          .filter(buy => {
                              // No filtering when showing test challenges
                              if (showTest) return true;
                              if (buy.name.toLowerCase().includes("test"))
                                  return false;
                              // filter out 2 Jay test challenges not including "test" in name
                              if (buy.id === 1 || buy.id === 319) return false;

                              // Otherwise show the challenge, it's not test
                              return true;
                          })
                          .map(buy => {
                              const status = getStatus(buy);
                              const posterUrl = buy?.posterUrl;
                              return (
                                  <OpenBuyWrap
                                      key={buy.id}
                                      onClick={() => {
                                          if (onSelect) onSelect(buy);
                                          else {
                                              status === "pending"
                                                  ? dispatch(
                                                        push(
                                                            // `/marketplace/your-challenges/${buy.id}/edit-challenge`
                                                            `/admin-tools/your-challenges/${buy.id}/edit-challenge`
                                                        )
                                                    )
                                                  : dispatch(
                                                        push(
                                                            // `/marketplace/your-challenges/${buy.id}/challenge-report`
                                                            `/admin-tools/your-challenges/${buy.id}/challenge-report`
                                                        )
                                                    );
                                          }
                                          scrollToTop();
                                      }}
                                  >
                                      <IndicatorBackground status={status}>
                                          <Main>
                                              {posterUrl && (
                                                  <Poster
                                                      src={posterUrl}
                                                      alt=""
                                                      pending={
                                                          status === "pending"
                                                      }
                                                  />
                                              )}
                                              <Screen />
                                              {buy && <Title>{buy.name}</Title>}
                                          </Main>
                                      </IndicatorBackground>
                                      {status !== "active" && (
                                          <StatusTag status={status}>
                                              <StatusLabel>
                                                  {status}
                                              </StatusLabel>
                                          </StatusTag>
                                      )}
                                  </OpenBuyWrap>
                              );
                          })}
            </List>
        </>
    );
};

export default ChallengeList;
