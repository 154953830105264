import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { WhitePillButton } from "src/components/buttons/PillButton";
import { colors } from "src/constants";
import {
    applyPreApprovalCode,
    fetchPreApprovalCode,
    postAccount
} from "src/profile/actions";
import {
    applyPreApprovalCodeState,
    getHasUIAccess,
    getLoggedInSocialAccounts,
    getPreApprovalCode
} from "src/profile/selectors";
import styled from "styled-components";
import SupportModal from "./SupportModal";
import { stat } from "fs";
import { GlobalState } from "src/reducers";

const Main = styled.div`
    background: ${colors.primaryBlue};
    height: 100vh;
    color: ${colors.white};
    display: flex;
    justify-content: center;
    padding: 24px;
    padding-top: 40px;
`;

const InnerContainer = styled.div`
    max-width: 600px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const NextButton = styled(WhitePillButton)<{ firstAtBottom?: boolean }>`
    width: 100%;
    ${props => props.firstAtBottom && "margin-top: auto;"}
`;

const InputBox = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    border: 1px solid ${colors.primaryGray};
    border-radius: 12px;
    overflow: hidden;
    background: ${colors.white};
    ${props => props.disabled && "opacity: 0.5;"}
`;
const InputPrefix = styled.span<{ disabled?: boolean }>`
    background: ${colors.white};
    color: ${colors.tiktokBlack};
    font-weight: 700;
    font-size: 14px;
    padding-left: 0.5rem;
    ${props => props.disabled && "opacity: 0.5;"}
`;

const InputPrefixable = styled.input<{ disabled?: boolean }>`
    flex-grow: 1;
    font-size: 14px;
    background: ${colors.white};
    border: none;
    outline: none;
    padding: 0.5rem;
    height: auto;
    ${props => props.disabled && "opacity: 0.5;"}
`;

const Info = styled.span`
    border: 1px solid transparent;
    color: ${colors.white};
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
`;

const InfoSuccess = styled(Info)`
    background-color: ${colors.primaryGreen};
`;

const InfoError = styled(Info)`
    background-color: ${colors.white};
    color: ${colors.primaryGray};
    border-color: ${colors.mediumTeal};
`;

const LabelBox = styled.div`
    margin-bottom: 10px;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;
const BottomText = styled.div`
    font-size: 14px;
    color: white;
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }
`;

export default function PreApprovalAccount() {
    const dispatch = useDispatch();
    const history = useHistory();

    const preApprovalCode = useSelector(getPreApprovalCode);
    const preApprovalCodeLoading = useSelector(
        (state: GlobalState) => state.profile.preApprovalCodeLoading
    );
    const { loading: applyCodeLoading, error: applyCodeError } = useSelector(
        applyPreApprovalCodeState
    );
    const socialAccounts = useSelector(getLoggedInSocialAccounts);
    const hasSocialAccounts: boolean =
        Array.isArray(socialAccounts) && socialAccounts.length > 0;
    const hasUIAccess = useSelector(getHasUIAccess);
    const createSocialAccountLoading = useSelector(
        (state: GlobalState) => state.profile.postAccountLoading
    );

    const [code, setCode] = useState("");
    const [tiktokUsername, setTiktokUsername] = useState("");
    const [supportModalOpen, setSupportModalOpen] = useState(false);
    const [submittedAccount, setSubmittedAccount] = useState(false);

    useEffect(() => {
        dispatch(fetchPreApprovalCode());
        const storedPreApprovalCode = localStorage.getItem("preApprovalCode");
        if (storedPreApprovalCode) {
            setCode(storedPreApprovalCode);
        }
    }, []);

    useEffect(() => {
        if (code.length === 6 && !preApprovalCode && !preApprovalCodeLoading) {
            dispatch(applyPreApprovalCode(code));
        }
    }, [code, preApprovalCode, preApprovalCodeLoading]);

    const submitHandler = () => {
        if (!preApprovalCode) {
            return;
        }
        setSubmittedAccount(true);
        if (!hasSocialAccounts) {
            dispatch(
                postAccount({
                    username: tiktokUsername,
                    platform: "tiktok",
                    followers: "0",
                    theme: "",
                    visibility: "visible"
                })
            );
        }
    };

    useEffect(() => {
        if (submittedAccount && !createSocialAccountLoading) {
            window.location.assign("/");
        }
    }, [submittedAccount, createSocialAccountLoading]);

    function codeStateDisplay() {
        if (applyCodeLoading) {
            return (
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            );
        } else if (preApprovalCode) {
            return <InfoSuccess>🎉 Code Applied!</InfoSuccess>;
        } else if (applyCodeError) {
            return <InfoError>🚫️ Could Not Apply Code!</InfoError>;
        } else {
            return null;
        }
    }
    return (
        <Main>
            <InnerContainer>
                <LabelBox>Code: {codeStateDisplay()}</LabelBox>
                <InputBox>
                    <InputPrefixable
                        type="text"
                        value={code}
                        onChange={e => setCode(e.target.value.slice(0, 6))}
                    />
                </InputBox>
                <LabelBox>
                    TikTok Username:{" "}
                    {createSocialAccountLoading && (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </LabelBox>

                <InputBox disabled={!preApprovalCode}>
                    <InputPrefix disabled={!preApprovalCode}>@</InputPrefix>
                    <InputPrefixable
                        type="text"
                        value={tiktokUsername}
                        onChange={e => setTiktokUsername(e.target.value)}
                        disabled={!preApprovalCode}
                    />
                </InputBox>
                <NextButton
                    firstAtBottom
                    onClick={submitHandler}
                    disabled={!preApprovalCode}
                >
                    Join the Plug!
                </NextButton>
                <Row>
                    <BottomText onClick={() => setSupportModalOpen(true)}>
                        Need Help? Contact Us
                    </BottomText>
                </Row>
            </InnerContainer>
            {supportModalOpen && (
                <SupportModal closeModal={() => setSupportModalOpen(false)} />
            )}
        </Main>
    );
}
