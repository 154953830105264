import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { push } from "connected-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title, Image, Row, Chevron } from "./StyledComponents";
import CountryPlatformCell from "../../components/CountryPlatformCell";
import CoinReward from "../../components/CoinReward";
import VerticallyCentered from "../../components/VerticallyCentered";
import { CampaignHit } from "../types";

const BorderImage = styled(Image)`
    border-radius: 12px;
`;

const Reward = styled(VerticallyCentered)`
    font-size: 12px;
`;

interface Props {
    hit: CampaignHit;
    push: (path: string) => void;
}

const CampaignHitRow = ({ hit, push }: Props) => {
    const routeToDetails = () => {
        push(`/campaign/${hit.id}`);
    };

    if (!hit.rewards[0].reward) return null;
    return (
        <Row onClick={routeToDetails}>
            <BorderImage src={hit.image_url} alt="" />
            <div>
                <Title>{hit.campaign_name}</Title>
                <Reward>
                    <CoinReward
                        size={14}
                        textSize={12}
                        reward={hit.rewards[0].reward}
                        type={hit.reward_type}
                    />
                    <CountryPlatformCell
                        size={16}
                        country={"US"}
                        platform={"ios"}
                    />
                </Reward>
            </div>
            <Chevron>
                <FontAwesomeIcon icon="chevron-right" />
            </Chevron>
        </Row>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    push: (path: string) => dispatch(push(path))
});

export default connect(null, mapDispatchToProps)(CampaignHitRow);
