import * as serviceWorker from "./serviceWorker";
import configureStore from "./configureStore";
import { render } from "react-dom";
import PlugApp from "./PlugApp";
import React from "react";
import "./custom.scss";

const store = configureStore(null);

// (window as any).store = store;
render(<PlugApp store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
