import { Action } from "src/types";
import {
    FETCH_OPEN_BUY_PAYMENTS,
    ADD_PENDING_OPEN_BUY_PAYMENTS_GROUP,
    REMOVE_PENDING_OPEN_BUY_PAYMENTS_GROUP,
    UPDATE_PENDING_OPEN_BUY_PAYMENTS_GROUP,
    SHOW_EMPTY_PENDING_OPEN_BUY_PAYMENTS_GROUP,
    PUSH_PENDING_OPEN_BUY_PAYMENT_ITEM,
    SHIFT_PENDING_OPEN_BUY_PAYMENT_ITEM,
    UPDATE_PENDING_OPEN_BUY_PAYMENT_ITEM,
    ADD_CREATOR_SPECIFIC_PAYMENT,
    REMOVE_CREATOR_SPECIFIC_PAYMENT,
    UPDATE_CREATOR_SPECIFIC_PAYMENT
} from "./actions";
import { PendingOpenBuyPaymentsGroup } from "../types";
import {
    addPendingOpenBuyPaymentsGroup,
    removePendingOpenBuyPaymentsGroup,
    updatePendingOpenBuyPaymentsGroup,
    pushPendingOpenBuyPaymentItem,
    shiftPendingOpenBuyPaymentItem,
    updatePendingOpenBuyPaymentItem,
    addCreatorSpecificPayment,
    removeCreatorSpecificPayment,
    updateCreatorSpecificPayment
} from "./reducerHelpers";

export interface PendingOpenBuyPaymentsStore {
    [openBuyId: number]: {
        alreadyHasPayments: boolean;
        pendingOpenBuyPaymentsGroups: PendingOpenBuyPaymentsGroup[];
    };
}

export interface OpenBuyPaymentUIState {
    pendingOpenBuyPaymentsStore: PendingOpenBuyPaymentsStore;
    showEmpty: boolean;
}

const initialOpenBuyPaymentUIState: OpenBuyPaymentUIState = {
    pendingOpenBuyPaymentsStore: {},
    showEmpty: false
};

const openBuyPaymentsUIReducer = (
    state = initialOpenBuyPaymentUIState,
    action: Action
) => {
    switch (action.type) {
        case FETCH_OPEN_BUY_PAYMENTS.SUCCESS:
            return {
                ...state,
                pendingOpenBuyPaymentsStore: {
                    ...state.pendingOpenBuyPaymentsStore,
                    ...action.payload
                }
            };
        case ADD_PENDING_OPEN_BUY_PAYMENTS_GROUP:
            return addPendingOpenBuyPaymentsGroup(state, action);
        case REMOVE_PENDING_OPEN_BUY_PAYMENTS_GROUP:
            return removePendingOpenBuyPaymentsGroup(state, action);
        case UPDATE_PENDING_OPEN_BUY_PAYMENTS_GROUP:
            return updatePendingOpenBuyPaymentsGroup(state, action);
        case SHOW_EMPTY_PENDING_OPEN_BUY_PAYMENTS_GROUP:
            return {
                ...state,
                showEmpty: true
            };
        case ADD_CREATOR_SPECIFIC_PAYMENT:
            return addCreatorSpecificPayment(state, action);
        case REMOVE_CREATOR_SPECIFIC_PAYMENT:
            return removeCreatorSpecificPayment(state, action);
        case UPDATE_CREATOR_SPECIFIC_PAYMENT:
            return updateCreatorSpecificPayment(state, action);
        case PUSH_PENDING_OPEN_BUY_PAYMENT_ITEM:
            return pushPendingOpenBuyPaymentItem(state, action);
        case SHIFT_PENDING_OPEN_BUY_PAYMENT_ITEM:
            return shiftPendingOpenBuyPaymentItem(state, action);
        case UPDATE_PENDING_OPEN_BUY_PAYMENT_ITEM:
            return updatePendingOpenBuyPaymentItem(state, action);
        default:
            return state;
    }
};

export default openBuyPaymentsUIReducer;
