import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { AnalyticsState } from "./reducer";
import {
    fetchRaw24HourAnalyticsData,
    fetchRawAnalyticsData,
    fetchUserAnalytics,
    fetchWeeklyActivity,
    reportParameters
} from "./actions";
import { Route, Switch } from "react-router";
import AnalyticsMain from "./AnalyticsMain";
import DailyEarningsPage from "./DailyEarningsPage";
import MediaLeaderboard from "./MediaLeaderboard";
import ReferralLeaderboard from "./ReferralLeaderboard";
import DailyLinksPage from "./DailyLinksPage";
import FullscreenLoader from "../components/FullscreenLoader";
import moment from "moment";
import Navbar from "src/components/Navbar";
import NotFoundPage from "src/navigation/NotFound";

interface Props {
    analyticsState: AnalyticsState;
    fetchWeeklyActivity: () => void;
    fetchUserAnalytics: () => void;
    fetchRawAnalyticsData: (payload: reportParameters) => void;
    fetchRaw24HourAnalyticsData: () => void;
}

const Analytics = ({
    analyticsState,
    fetchWeeklyActivity,
    fetchUserAnalytics,
    fetchRawAnalyticsData,
    fetchRaw24HourAnalyticsData
}: Props) => {
    useEffect(() => {
        fetchWeeklyActivity();
        fetchUserAnalytics();
        fetchRawAnalyticsData({
            startDate: moment()
                .subtract(13, "day")
                .format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            primary: true
        });
    }, []);

    const [runUpdates, setRunUpdates] = useState(true);

    useEffect(() => {
        if (runUpdates) {
            fetchRawAnalyticsData({
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            });
            fetchRaw24HourAnalyticsData();

            setRunUpdates(false);

            setTimeout(() => {
                setRunUpdates(true);
            }, 60000);
        }
    }, [runUpdates]);

    if (
        analyticsState.weeklyActivityLoading ||
        analyticsState.userAnalyticsLoading ||
        analyticsState.analyticsDataStoreLoading
    ) {
        return (
            <>
                <Navbar />
                <FullscreenLoader />
            </>
        );
    } else {
        return (
            <Switch>
                <Route
                    exact
                    path={"/analytics/referral-leaderboard"}
                    component={ReferralLeaderboard}
                />

                <Route
                    exact
                    path={"/analytics/media-leaderboard"}
                    component={MediaLeaderboard}
                />

                <Route
                    exact
                    path={"/analytics/daily-earnings/links"}
                    component={DailyLinksPage}
                />

                <Route
                    exact
                    path={"/analytics/daily-earnings"}
                    component={DailyEarningsPage}
                />

                <Route exact path={"/analytics"} component={AnalyticsMain} />

                <Route component={NotFoundPage} />
            </Switch>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    analyticsState: state.analytics
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchWeeklyActivity: () => dispatch(fetchWeeklyActivity()),
    fetchUserAnalytics: () => dispatch(fetchUserAnalytics()),
    fetchRawAnalyticsData: (payload: reportParameters) =>
        dispatch(fetchRawAnalyticsData(payload)),
    fetchRaw24HourAnalyticsData: () => dispatch(fetchRaw24HourAnalyticsData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
