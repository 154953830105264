import React, { useEffect } from "react";
import { storage } from "../../firebase/FirebaseConfig";

interface Props {
    media: {
        key: string;
        coverPhoto: string | null;
    };
    linkPrefix: string;
    mediaUrl: string | null;
    setMediaUrl: (mediaUrl: string) => void;
    coverUrl: string | null;
    setCoverUrl: (coverUrl: string) => void;
}

const ConnectFirebase = ({
    media,
    linkPrefix,
    mediaUrl,
    setMediaUrl,
    coverUrl,
    setCoverUrl
}: Props) => {
    useEffect(() => {
        if (media.coverPhoto && !coverUrl) {
            storage
                .ref(`/${linkPrefix}/${media.coverPhoto}`)
                .getDownloadURL()
                .then((url: string) => {
                    setCoverUrl(url);
                })
                .catch((error: string) => {});
        }

        if (media.key && !mediaUrl) {
            storage
                .ref(`/${linkPrefix}/${media.key}`)
                .getDownloadURL()
                .then((url: string) => {
                    setMediaUrl(url);
                })
                .catch((error: string) => {});
        }
    }, [media]);

    return null;
};

export default ConnectFirebase;
