import React, { useEffect, useState } from "react";
import { Channel } from "../types";
import NavButton from "../../components/NavButton";

interface Props {
    channel: Channel;
    setShowSettings: (showSettings: boolean) => void;
    directActionsModal: VoidFunction;
}

const RightNavButton = ({
    channel,
    setShowSettings,
    directActionsModal
}: Props) => {
    const [metaData, setMetaData] = useState<{ type: any }>();

    useEffect(() => {
        channel.sendBirdData.getMetaData(["type"], (data: any, error: any) => {
            if (!error) {
                setMetaData(data);
            } else {
                window.alert(error);
            }
        });
    }, []);

    if (!metaData) {
        return null;
    } else if (metaData.type === "group") {
        return (
            <NavButton onClick={() => setShowSettings(true)}>
                <img src="/info-icon.svg" />
            </NavButton>
        );
    } else {
        return (
            <NavButton onClick={directActionsModal}>
                <img src="/ellipsis-button.svg" />
            </NavButton>
        );
    }
};

export default RightNavButton;
