import {
    SET_EXTENSIONS_ERROR,
    SET_EXTENSIONS_LOADING
} from "src/admin-tools/challenges/challenge-extensions/actions";
import { Action } from "src/types";
import {
    SET_ACTIVE_ACCORDION_SUBMISSION_MANAGER,
    SET_ADMIN_CURRENT_WIZARD_STEP,
    SET_CHALLENGE_DETAILS_INCOMPLETE,
    SET_CHARGE_ADVERTISERS_PER_INSTALL,
    SET_CREATE_CHALLENGE_FORM_OPEN,
    SET_CURRENT_OPEN_BUY_ID,
    SET_DETAILS_UNSAVED,
    SET_IN_ADMIN_PAGE,
    SUBMISSION_MANAGER_ACCORDIONS,
    SET_HAS_READ_LOCAL_STORE_FOR_NAV_TO_ADMIN_TOOLS
} from "./actions";

export const adminWizardSteps = [
    "Set Challenge Details",
    "Select an Instruction Set",
    "Select a Payment Set"
] as const;

export type AdminWizardStep = typeof adminWizardSteps[number];
export type AdminChallengeStatus = null | "end" | "restart" | "launch";

export interface AdminToolsUIState {
    inAdminTools: boolean;
    createChallengeFormOpen: boolean;
    currentOpenBuyId: number | null;
    currentWizardStep: AdminWizardStep;
    detailsHasUnsaved: boolean;
    extensions: {
        isLoading: boolean;
        isError: boolean;
    };
    chargeAdvertisersPerInstall: boolean;
    challengeDetailsIncomplete: boolean;
    hasReadLocalStoreForNavToAdminTools: boolean;
    submissionManager: { activeAccordions: SUBMISSION_MANAGER_ACCORDIONS[] };
}

const initialAdminToolsUIState: AdminToolsUIState = {
    inAdminTools: false,
    createChallengeFormOpen: false,
    currentOpenBuyId: null,
    currentWizardStep: adminWizardSteps[0],
    detailsHasUnsaved: false,
    extensions: {
        isLoading: true,
        isError: false
    },
    chargeAdvertisersPerInstall: false,
    challengeDetailsIncomplete: false,
    submissionManager: {
        activeAccordions: [SUBMISSION_MANAGER_ACCORDIONS.approvalChecklist]
    },
    hasReadLocalStoreForNavToAdminTools: false
};

function adminToolsUIReducer(
    state = initialAdminToolsUIState,
    action: Action
): AdminToolsUIState {
    switch (action.type) {
        case SET_IN_ADMIN_PAGE:
            return { ...state, inAdminTools: action.payload.inAdminTools };

        case SET_CREATE_CHALLENGE_FORM_OPEN:
            return { ...state, createChallengeFormOpen: action.payload.open };

        case SET_CURRENT_OPEN_BUY_ID:
            return { ...state, currentOpenBuyId: action.payload.id };

        case SET_ADMIN_CURRENT_WIZARD_STEP:
            return { ...state, currentWizardStep: action.payload.step };

        case SET_DETAILS_UNSAVED:
            return { ...state, detailsHasUnsaved: action.payload.status };

        case SET_EXTENSIONS_LOADING:
            return {
                ...state,
                extensions: {
                    ...state.extensions,
                    isLoading: action.payload.isLoading
                }
            };

        case SET_EXTENSIONS_ERROR:
            return {
                ...state,
                extensions: {
                    ...state.extensions,
                    isError: action.payload.isError
                }
            };

        case SET_CHARGE_ADVERTISERS_PER_INSTALL:
            return {
                ...state,
                chargeAdvertisersPerInstall:
                    action.payload.chargeAdvertisersPerInstall
            };

        case SET_CHALLENGE_DETAILS_INCOMPLETE:
            return {
                ...state,
                challengeDetailsIncomplete: action.payload.complete
            };
        case SET_ACTIVE_ACCORDION_SUBMISSION_MANAGER:
            return {
                ...state,
                submissionManager: {
                    ...state.submissionManager,
                    activeAccordions: action.payload
                }
            };
        case SET_HAS_READ_LOCAL_STORE_FOR_NAV_TO_ADMIN_TOOLS:
            return {
                ...state,
                hasReadLocalStoreForNavToAdminTools: true
            };

        default:
            return state;
    }
}

export default adminToolsUIReducer;
