import React from "react";
import { colors, fonts, fontWeight, shadows } from "src/constants";
import { clearButton } from "src/utils/styles/snippets";
import { EventInput } from "src/utils/types/form";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { getCurrentOpenBuyAlreadyHasPayments } from "../open-buy-payments/selectors";
import { getCurrentOpenBuy } from "../../../admin-tools/selectors.admin-tools";

const Header = styled.header`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    background-color: ${colors.white};
    box-shadow: ${shadows.one};
`;

const Title = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: ${fonts.title}px;
`;

const TitleInput = styled.input`
    width: 100%;
    margin-right: 12px;
    border: none;
    font-size: ${fonts.title}px;
    outline: none;

    ::placeholder {
        color: ${colors.primaryGray};
    }
`;

const buttonStyles = css`
    margin-left: 8px;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.medium};
    transition: 0.1s ease-in;
`;

const DeleteButton = styled.button`
    ${clearButton()};
    ${buttonStyles};
    color: ${colors.white};
    background-color: ${colors.primaryRed};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryRedDark};
        }
    }
`;

const UpdateButton = styled.button`
    ${clearButton()};
    ${buttonStyles};
    color: ${colors.white};
    background-color: ${colors.primaryBlue};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

interface Props {}

const PaymentSetsCreateHeader = ({}: Props) => {
    const currentOpenBuy = useSelector(getCurrentOpenBuy);
    const currentOpenBuyAlreadyHasPayments = useSelector(
        getCurrentOpenBuyAlreadyHasPayments
    );
    const openBuyName = currentOpenBuy?.name;
    return (
        <Header>
            <Title>
                {openBuyName && `${openBuyName} Payment Set`}
                <UpdateButton type="submit">
                    {currentOpenBuyAlreadyHasPayments ? "Update" : "Create"}
                </UpdateButton>
            </Title>
        </Header>
    );
};

export default PaymentSetsCreateHeader;
