import React from "react";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import ModalList, {
    ModalItem
} from "src/components/modals/components/ModalList";
import { colors, maxWidthDesktop } from "src/constants";
import CarouselMedia from "src/modals/CarouselMedia";
import Platforms from "src/social-accounts/Platforms";
import { shortenNumber } from "src/util";
import styled from "styled-components";
import { SubmissionReport } from "../types";

const Wrapper = styled.div`
    width: 90vw;
    max-width: ${maxWidthDesktop}px;
    height: 90vh;
    max-height: 742px;
    position: relative;
    padding: 24px;
    overflow: visible;
    background-color: white;
    border-radius: 6px;
    display: flex;
    gap: 24px;
`;
const MediaWrap = styled.div`
    flex: 1;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    background-color: ${colors.grayBlack};
`;
const StyledCarouselMedia = styled(CarouselMedia)`
    height: 100%;
    margin: 0px auto;
    & > video {
        height: 100%;
        width: auto;
    }
`;
const DescriptionWrap = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const SocialHeader = styled.div`
    width: 100%;
    display: grid;
    height: 62px;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "avatar username chevron"
        "avatar followers chevron";
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-radius: 8px;
    margin-bottom: 18px;
    &:hover {
        background-color: ${colors.lightGray};
    }
`;
const SocialAvatar = styled.img`
    grid-area: avatar;
    width: 46px;
    height: 46px;
    border-radius: 12px;
    margin-right: 12px;
    object-fit: cover;
`;
const Username = styled.div`
    grid-area: username;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
`;
const Followers = styled.div`
    text-align: left;
    grid-area: followers;
    color: ${colors.primaryGray};
    font-size: 12px;
`;
const IconWrap = styled.div`
    grid-area: chevron;
    display: grid;
    place-items: center;
`;
const Chevron = styled.img`
    transform: rotate(-90deg);
`;

const ViewBtn = styled.a`
    background-color: ${colors.quaternaryLightGray};
    margin-top: auto;
    padding: 8px 12px;
    border-radius: 6px;
    width: 100%;
    font-size: 13px;
    color: black;
    transition: background-color 0.2s ease;
    &:hover {
        color: black;
        text-decoration: none;
        background-color: ${colors.lightGray};
    }
`;
const MediaUnavailable = styled.div`
    display: grid;
    place-items: center;
    flex: 1;
    border: 1px solid ${colors.blueGrey2};
    border-radius: 12px;
    color: ${colors.primaryGray};
`;

const SubmissionMediaModal = ({
    submission,
    openBuy
}: {
    submission: SubmissionReport;
    openBuy: OpenBuy;
}) => {
    const stats: ModalItem[] = [
        { label: "Cost", type: "money", value: submission.cost },
        { label: "eCPM", type: "money", value: submission.ecpm },
        { label: "Likes", type: "number", value: submission.likes },
        { label: "Views", type: "number", value: submission.viewsActual },
        { label: "Comments", type: "number", value: submission.totalComments },
        { label: "Media ID", type: "id", value: submission.media?.id }
    ];

    const platform = submission.socialAccount?.platform
        ? Platforms[submission.socialAccount.platform]
        : null;
    const handleViewAccount = () => {
        if (platform && submission.socialAccount?.username) {
            const urlToOpen = platform.urlCallback(
                submission.socialAccount.username.toLowerCase()
            );

            if (urlToOpen) {
                window.open(urlToOpen);
            }
        }
    };

    return (
        <Wrapper>
            {submission.media?.url ? (
                <MediaWrap>
                    <StyledCarouselMedia
                        mediaUrl={submission.media.url}
                        mediaType={"video"}
                        isPortrait={false}
                    />
                </MediaWrap>
            ) : (
                <MediaUnavailable>Media Unavailable</MediaUnavailable>
            )}
            <DescriptionWrap>
                <SocialHeader onClick={handleViewAccount}>
                    <SocialAvatar
                        src={
                            submission.socialAccount?.profilePhotoUrl ||
                            "/no_profile_photo.svg"
                        }
                        alt=""
                    />
                    <Username>
                        {submission.socialAccount?.username
                            ? `@${submission.socialAccount.username}`
                            : "Unknown"}
                    </Username>
                    {submission.socialAccount?.numFollowers ? (
                        <Followers>
                            {shortenNumber(
                                submission.socialAccount.numFollowers
                            )}{" "}
                            Followers
                        </Followers>
                    ) : null}
                    <IconWrap>
                        <Chevron src="/caret.svg" />
                    </IconWrap>
                </SocialHeader>
                <ModalList
                    header={"SUBMISSION PERFORMANCE"}
                    listItems={stats}
                />
                {submission.linkSubmission ? (
                    <ViewBtn target={"_blank"} href={submission.linkSubmission}>
                        View on {platform ? platform.title : "social account"}
                    </ViewBtn>
                ) : null}
            </DescriptionWrap>
        </Wrapper>
    );
};

export default SubmissionMediaModal;
