import React from "react";
import styled from "styled-components";
import ProfilePhoto from "../../components/ProfilePhoto";
import { PublicProfile } from "../types";
import { statusTypes } from "../StatusTypes";
import { breakpoints, colors, fonts } from "../../constants";
import { shortenNumber } from "../../util";
import Platforms from "../../social-accounts/Platforms";
import Tag from "../../components/Tag";
import VerticallyCentered from "../../components/VerticallyCentered";
import { flex, hideOverflow } from "src/utils/styles/snippets";

const Main = styled("article")`
    @media (max-width: ${breakpoints.tablet}px) {
        padding: 0px 15px 0px;
    }
`;

const UserDetail = styled("div")`
    ${flex("column", "center")};
    width: calc(100% - 115px);
    text-align: left;
`;

const Username = styled("span")<{ onClick?: Function }>`
    ${hideOverflow()};
    display: block;
    color: ${colors.black};
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    transition: 0.1s ease-in;
    ${props => props.onClick && "cursor: pointer;"};

    @media (hover: hover) {
        :hover {
            color: ${colors.primaryBlue};
        }
    }
`;

const NoUsername = styled(Username)`
    color: ${colors.primaryGray};
    font-style: italic;
    font-weight: 300;
`;

const Following = styled("div")`
    ${hideOverflow()};
    margin: 6px 0px;
    color: ${colors.primaryGray};
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
`;

const FollowingCount = styled("span")`
    font-weight: 700;
`;

interface Props {
    publicProfile: PublicProfile;
    statusModal?: () => void;
    privateCallbacks?: {
        photoModal: () => void;
        usernameModal: () => void;
    };
}

const ProfileHeader = ({
    publicProfile,
    statusModal,
    privateCallbacks
}: Props) => {
    const platform =
        Platforms[publicProfile.profile.tagLine.platform] || Platforms.other;

    return (
        <Main>
            <VerticallyCentered>
                <ProfilePhoto
                    profilePhotoUrl={publicProfile.profile.avatar}
                    status={publicProfile.profile.status}
                    photoSize={100}
                    statusSize={28}
                    onClick={privateCallbacks && privateCallbacks.photoModal}
                    style={{ marginRight: "15px" }}
                />

                <UserDetail>
                    {publicProfile.profile.username ? (
                        <Username
                            onClick={
                                privateCallbacks &&
                                privateCallbacks.usernameModal
                            }
                        >
                            {publicProfile.profile.username}
                        </Username>
                    ) : privateCallbacks ? (
                        <NoUsername onClick={privateCallbacks.usernameModal}>
                            Set Username
                        </NoUsername>
                    ) : (
                        <NoUsername>No Username</NoUsername>
                    )}

                    {publicProfile.profile.tagLine.platform ? (
                        <Following>
                            <FollowingCount>
                                {shortenNumber(
                                    publicProfile.profile.tagLine.followerCount,
                                    1
                                )}
                            </FollowingCount>{" "}
                            on {platform.title}
                        </Following>
                    ) : (
                        <Following>···</Following>
                    )}

                    <Tag
                        uppercase
                        text={statusTypes[publicProfile.profile.status].title}
                        tagColor={
                            publicProfile.profile.status === "diamond"
                                ? colors.primaryBlue
                                : publicProfile.profile.status === "gold"
                                ? colors.primaryYellow
                                : publicProfile.profile.status === "silver"
                                ? colors.primaryGray
                                : ""
                        }
                        onClick={statusModal}
                    />
                </UserDetail>
            </VerticallyCentered>
        </Main>
    );
};

export default ProfileHeader;
