import React, { useState } from "react";
import ListCell from "../components/ListCell";
import Modal from "src/modals/Modal";
import styled from "styled-components";
import { colors, fonts } from "src/constants";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { post, setToken } from "src/Api";

const Header = styled.div`
    font-size: ${fonts.title}px;
    color: ${colors.primaryGreen};
    font-weight: bold;
    margin: 20px 20px 0 20px;
`;

const SubTitle = styled.div`
    font-size: ${fonts.smallTitle}px;
    color: ${colors.primaryGray};
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    margin: 20px 30px 5px 30px;
`;

const Text = styled.div`
    font-size: ${fonts.subtitle}px;
    color: ${colors.black};
    text-align: center;
    margin: 20px 30px;
    margin-top: 0px;
`;

const VerifyItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 16px;
    font-size: ${fonts.subtitle}px;
    cursor: pointer;
    user-select: none;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const Error = styled.div`
    font-size: ${fonts.default}px;
    color: ${colors.primaryRed};
    text-align: center;
`;
const Cancel = styled.button`
    color: ${colors.gray2};
    font-size: 14px;
    width: 50%;
    height: 40px;
    border-bottom-left-radius: 10px;
    background: white;
`;
const Submit = styled(Cancel)`
    color: ${colors.primaryBlue};
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
    height: auto;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    background: ${colors.secondaryGray};
    padding-top: 1px;
    gap: 1px;
    margin-top: 20px;
`;

const Input = styled.input`
    outline: none;
    border-radius: 5px;
    border: 1px solid ${colors.gray2};
    padding: 8px;
    font-size: 14px;
`;

const Checkbox = styled.img`
    padding-left: 10px;
`;

interface Props {
    currentId: string | number;
}

const OnboardingTool = ({ currentId }: Props) => {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [devNumber, setDevNumber] = useState("");
    const [preapproved, setPreapproved] = useState(false);

    const phoneNumber = useSelector(
        (state: GlobalState) => state?.profile?.profile?.phone_number
    );

    const loginAsDev = (
        phoneNumber: string | undefined,
        preapproved: boolean
    ) => {
        const token =
            localStorage.getItem("employeeToken") ||
            localStorage.getItem("token");
        setToken(token?.toString());

        post("/api/login_as_dev", {
            phoneNumber,
            preapproved
        })
            .then(response => {
                if (response.data.userType === "loginAsDev") {
                    indexedDB.deleteDatabase("firebaseLocalStorageDb");
                    localStorage.clear();

                    localStorage.setItem(
                        "devAccount",
                        JSON.stringify({
                            devNumber: response.data.devAccount.phone_number,
                            authCode: response.data.devAccount.auth_code,
                            onboardingType: preapproved
                                ? "Magic Path"
                                : "Normal"
                        })
                    );

                    if (token) localStorage.setItem("employeeToken", token);

                    if (preapproved)
                        localStorage.setItem("magicToken", response.data.token);

                    preapproved
                        ? window.location.assign("/approved")
                        : window.location.reload();
                } else setError("Failed to login as dev user for onboarding");
            })
            .catch(err =>
                setError("Failed to login as dev user for onboarding")
            );
    };

    const onClose = () => {
        setOpen(false);
        setDevNumber("");
    };

    return (
        <>
            <ListCell clickable onClick={() => setOpen(true)}>
                Login as Dev: Onboarding
            </ListCell>
            {open && (
                <Modal onClick={onClose}>
                    <Header>Dev Onboarding Flow</Header>
                    <SubTitle>Phone Number</SubTitle>
                    <Text>
                        Please provide a dev phone number starting with 685. Or
                        use your phone number; it'll be converted to a dev
                        number. (No input needed.)
                    </Text>
                    <Input
                        placeholder="685-xxx-xxxx"
                        onChange={e => setDevNumber(e.target.value)}
                        value={devNumber}
                    />
                    <SubTitle>Pre-approval "Magic Path"</SubTitle>
                    <VerifyItem onClick={() => setPreapproved(!preapproved)}>
                        Pre-approved
                        {preapproved ? (
                            <Checkbox src={"/checkbox_selected.svg"} />
                        ) : (
                            <Checkbox src={"/checkbox_unselected.svg"} />
                        )}
                    </VerifyItem>
                    <Error>{error}</Error>
                    <Row>
                        <Cancel onClick={onClose}>Cancel</Cancel>
                        <Submit
                            onClick={() =>
                                loginAsDev(
                                    devNumber ? devNumber : phoneNumber,
                                    preapproved
                                )
                            }
                        >
                            Submit
                        </Submit>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default OnboardingTool;
