import React from "react";
import {
    OpenBuyInstructionStep,
    OpenBuySubmission,
    OpenBuySubmissionStatuses
} from "src/buys/modules/open-buys/types";
import { colors } from "src/constants";
import styled from "styled-components";

const StepHeader = styled.div<{ selected?: boolean }>`
    display: flex;
    font-weight: 600;
    font-size: 12px;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    height: 50px;
    background-color: ${props => props.selected && "rgb(218, 236, 255)"};
    border-radius: 8px;
    padding: 12px 9px;
    :hover {
        background-color: ${props =>
            !props.selected && "rgba(218, 236, 255, 0.4)"};
    }
`;

const StatusIndicator = styled.div<{ status?: OpenBuySubmissionStatuses }>`
    border: 1px solid
        ${props =>
            props.status === OpenBuySubmissionStatuses.pending
                ? colors.messageYellow
                : props.status === OpenBuySubmissionStatuses.rejected ||
                  props.status === OpenBuySubmissionStatuses.revision_requested
                ? colors.primaryRed
                : props.status === OpenBuySubmissionStatuses.approved
                ? colors.primaryGreen
                : colors.primaryBlue};

    color: ${props =>
        props.status === OpenBuySubmissionStatuses.pending
            ? colors.messageYellow
            : props.status === OpenBuySubmissionStatuses.rejected ||
              props.status === OpenBuySubmissionStatuses.revision_requested
            ? colors.primaryRed
            : props.status === OpenBuySubmissionStatuses.approved
            ? colors.primaryGreen
            : colors.primaryBlue};
    font-weight: 700;
    font-size: 12px;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    margin-right: 6px;
    justify-content: center;
    display: flex;
`;

const Caret = styled.img`
    height: 12px;
    margin-left: auto;
    width: 12px;
    transform: rotate(270deg);
`;
interface Props {
    submission?: OpenBuySubmission;
    index: number;
    step: OpenBuyInstructionStep;
    selected?: boolean;
    onClick: VoidFunction;
}

const SubmissionStepSelect = ({
    submission,
    index,
    step,
    selected,
    onClick
}: Props) => {
    function determineStepTitle(step: OpenBuyInstructionStep) {
        if (step.requiresRawMedia) return "RAW MEDIA";
        else if (step.requiresCustomMedia) return "MEDIA";
        else if (step.requiresLinkSubmission) return "LINK";
        else if (step.requiresTextSubmission) return "TEXT";
    }

    return (
        <StepHeader selected={selected} onClick={onClick}>
            <StatusIndicator status={submission?.status}>
                {submission?.status === OpenBuySubmissionStatuses.approved ? (
                    <img src="/approved-status.svg" />
                ) : submission?.status === OpenBuySubmissionStatuses.pending ? (
                    <img src="/pending-status.svg" />
                ) : submission?.status ===
                      OpenBuySubmissionStatuses.revision_requested ||
                  submission?.status === OpenBuySubmissionStatuses.rejected ? (
                    <img src="/rejected-status.svg" />
                ) : (
                    index + 1
                )}
            </StatusIndicator>
            {determineStepTitle(step)} Submission (
            {submission?.id || "Not Completed"})
            {selected && <Caret src="/caret.svg" />}
        </StepHeader>
    );
};
export default SubmissionStepSelect;
