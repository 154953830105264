import React from "react";
import styled from "styled-components";
import { PendingOpenBuyPaymentItem } from "../types";
import ViewCount from "./ViewCount";
import { useDispatch } from "react-redux";
import {
    pushPendingOpenBuyPaymentItem,
    shiftPendingOpenBuyPaymentItem
} from "../open-buy-payments/actions";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0px;
    width: 100%;
    height: 100px;
`;

const PayoutRow = styled.div`
    display: flex;
    position: relative;
    width: calc(100% - 75px);
    margin-top: 32px;
    margin-left: 12px;
    @media (max-width: 700px) {
        width: calc(100% - 40px);
    }
`;

const BarContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8px;
`;

const Bar = styled.span`
    width: 100%;
    height: 12px;
    left: 20px;
    top: 0px;
    background: rgba(144, 154, 156, 0.5);
    border-radius: 19px;
`;

const Icon = styled.img`
    cursor: pointer;
    z-index: 998;
`;

interface Props {
    openBuyId: number;
    instructionStepId: number;
    creatorGroupId: number | null;
    pendingOpenBuyPaymentItems: PendingOpenBuyPaymentItem[];
}

const ViewThresholdsBar = ({
    openBuyId,
    instructionStepId,
    creatorGroupId,
    pendingOpenBuyPaymentItems
}: Props) => {
    const dispatch = useDispatch();

    const handleAddPayment = () => {
        dispatch(
            pushPendingOpenBuyPaymentItem({
                openBuyId,
                instructionStepId,
                creatorGroupId,
                payment: {
                    paymentId: 2,
                    amount: "",
                    viewsMinimum: ""
                }
            })
        );
    };

    const handleDeletePayment = () => {
        dispatch(
            shiftPendingOpenBuyPaymentItem({
                openBuyId,
                instructionStepId,
                creatorGroupId
            })
        );
    };

    return (
        <Main>
            <PayoutRow>
                {pendingOpenBuyPaymentItems.map((payment, index) => (
                    <ViewCount
                        key={`${index}-view`}
                        index={index}
                        openBuyId={openBuyId}
                        instructionStepId={instructionStepId}
                        creatorGroupId={creatorGroupId}
                        pendingOpenBuyPaymentItem={payment}
                        paymentsLength={pendingOpenBuyPaymentItems.length ?? 0}
                    />
                ))}
            </PayoutRow>
            <BarContainer>
                <Icon
                    src={"/marketplace-grey-circle-minus.svg"}
                    onClick={handleDeletePayment}
                />
                <Bar />
                <Icon
                    src={"/marketplace-black-circle-plus.svg"}
                    onClick={handleAddPayment}
                />
            </BarContainer>
        </Main>
    );
};

export default ViewThresholdsBar;
