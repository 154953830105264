import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { Media } from "../../../media/types";
import { clearModal } from "src/modals/actions";
import styled from "styled-components";
import Modal from "../../../modals/Modal";
import ModalButton from "../../../modals/ModalButton";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Description = styled.div`
    font-size: ${fonts.subtitle}px;
    padding-top: 10px;
`;

interface Props {
    media: Media | null;
    clearModal: () => void;
}

const CustomReasonModal = ({ media, clearModal }: Props) => {
    const renderDate = (media: Media) => {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        const endDate = new Date(
            Date.parse(media.approvedAt || "") + 1000 * 60 * 60 * 24 * 30
        );

        return `${
            monthNames[endDate.getMonth()]
        } ${endDate.getDate()}, ${endDate.getFullYear()}`;
    };

    let modalContent;

    if (
        media &&
        media.uploadedByPublisherId === localStorage.getItem("userid")
    ) {
        if (media.mediaStatus === "approved") {
            if (media.doNotPayCommission) {
                modalContent = (
                    <Main>
                        <Title>
                            This ad is approved, but you won't earn a commission
                            for the following reasons(s)
                        </Title>
                        <Description>{media.rejectionReason}</Description>
                    </Main>
                );
            } else {
                modalContent = (
                    <Main>
                        <Title>Your ad is approved for use</Title>
                        <Description>
                            You'll earn a 5% commission up until{" "}
                            {renderDate(media)}. Thanks!
                        </Description>
                    </Main>
                );
            }
        } else if (media.mediaStatus === "rejected") {
            modalContent = (
                <Main>
                    <Title>
                        Your ad was rejected for the following reason(s)
                    </Title>
                    <Description>{media.rejectionReason}</Description>
                </Main>
            );
        }
    } else {
        clearModal();
        return null;
    }

    return (
        <Modal>
            {modalContent}
            <ModalButton />
        </Modal>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    media: state.ui.modals.currentMedia
});

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomReasonModal);
