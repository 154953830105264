import moment from "moment";
import { ItemSelectorOptions } from "src/components/ItemSelector";
import Platforms from "src/social-accounts/Platforms";
import { PublicBuysFilter } from "./components/PublicBuysFilters";
import { PBPreview } from "./redux/reducer";

export const generateOptions = (
    type: string,
    buys: PBPreview[],
    filters: PublicBuysFilter[],
    index: number,
    updateFilters: (index: number, action: string, values: string[]) => void
) => {
    let options: ItemSelectorOptions[] = [];
    if (type === "campaign") {
        buys.forEach(buy => {
            let duplicateIndex = options.findIndex(
                option => option.id === buy.campaignId
            );
            if (duplicateIndex !== -1)
                options[duplicateIndex].subtitle = `${parseInt(
                    options[duplicateIndex].subtitle.split("screenshots")[0]
                ) + parseInt(buy.screenshots)} screenshots`;
            else
                options.push({
                    id: buy.campaignId,
                    value: filters[index].values.includes(
                        buy.campaignId.toString()
                    ),
                    title: buy.campaignName,
                    subtitle: buy.screenshots + " screenshots",
                    image: buy.imageUrl,
                    onSelect: () =>
                        updateFilters(
                            index,
                            filters[index].values.includes(
                                buy.campaignId.toString()
                            )
                                ? "remove"
                                : "add",
                            [buy.campaignId.toString()]
                        )
                });
        });
    }
    if (type === "account") {
        buys.forEach(buy => {
            let duplicateIndex = options.findIndex(
                option => option.id === buy.accountId
            );
            if (duplicateIndex !== -1)
                options[duplicateIndex].subtitle = `${parseInt(
                    options[duplicateIndex].subtitle.split("screenshots")[0]
                ) + parseInt(buy.screenshots)} screenshots`;
            else
                options.push({
                    id: buy.accountId,
                    value: filters[index].values.includes(
                        buy.accountId.toString()
                    ),
                    title: buy.username,
                    subtitle: buy.screenshots + " screenshots",
                    image: Platforms[buy.platform]?.imgSrc,
                    onSelect: () =>
                        updateFilters(
                            index,
                            filters[index].values.includes(
                                buy.accountId.toString()
                            )
                                ? "remove"
                                : "add",
                            [buy.accountId.toString()]
                        )
                });
        });
    }
    if (type === "date") {
        buys.forEach(buy => {
            let duplicateIndex = options.findIndex(
                option => option.id === buy.offerApprovedAt
            );
            if (duplicateIndex !== -1)
                options[duplicateIndex].subtitle = `${parseInt(
                    options[duplicateIndex].subtitle.split("screenshots")[0]
                ) + parseInt(buy.screenshots)} screenshots`;
            else
                options.push({
                    id: buy.offerApprovedAt,
                    value: filters[index].values.includes(
                        buy.offerApprovedAt.toString()
                    ),
                    title: moment(buy.offerApprovedAt).format("lll"),
                    subtitle: buy.screenshots + " screenshots",
                    image: "/date/date@3x.png",
                    onSelect: () =>
                        updateFilters(
                            index,
                            filters[index].values.includes(
                                buy.offerApprovedAt.toString()
                            )
                                ? "remove"
                                : "add",
                            [buy.offerApprovedAt.toString()]
                        )
                });
        });
    }

    return options;
};
