import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DirectBuyPaymentCollection } from "../modules/direct-buy-payment/types";
import { SocialAccount } from "../../profile/types";
import { getStringForDirectBuyPaymentCollectionArray } from "./directBuyPreviewUtil";
import { getImageSrcForSocialAccountArray } from "../../util";
import { fonts } from "src/constants";

interface MainStyleProps {
    borderRadius: number;
}

const Main = styled.div<MainStyleProps>`
    position: relative;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: ${props => props.borderRadius}px;
    overflow: hidden;
`;

const CoverPhoto = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const ContentScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 0.7);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const PaymentsLabel = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: white;
    opacity: 1;
`;

const TimeLabel = styled.div`
    width: fit-content;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: white;
    padding: 3px 6px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    margin-top: 4px;
`;

const PlatformImage = styled.img`
    width: 42px;
    height: 42px;
    object-fit: cover;
    border-radius: 6px;
`;

const AccountLabel = styled.div`
    font-weight: 500;
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
    color: white;
    margin-top: 4px;
`;

interface Props {
    payments: DirectBuyPaymentCollection[];
    socialAccounts: SocialAccount[];
    photoUrl: string | null;
    timeString: string | null;
    borderRadius: number;
}

const DirectBuyPreviewContent = ({
    payments,
    socialAccounts,
    photoUrl,
    timeString,
    borderRadius
}: Props) => {
    const [paymentString, setPaymentString] = useState("");
    useEffect(() => {
        setPaymentString(getStringForDirectBuyPaymentCollectionArray(payments));
    }, [payments]);

    const [socialAccountImage, setSocialAccountImage] = useState(() =>
        getImageSrcForSocialAccountArray(socialAccounts)
    );
    const [socialAccountString, setSocialAccountString] = useState("");
    useEffect(() => {
        setSocialAccountImage(getImageSrcForSocialAccountArray(socialAccounts));
        if (socialAccounts.length === 1) {
            setSocialAccountString(`@${socialAccounts[0].username}`);
        } else {
            setSocialAccountString(`${socialAccounts.length} Accounts`);
        }
    }, [socialAccounts]);

    return (
        <Main borderRadius={borderRadius}>
            {photoUrl && <CoverPhoto src={photoUrl} />}
            <ContentScreen>
                <FlexColumn>
                    <PaymentsLabel>{paymentString}</PaymentsLabel>
                    {timeString && <TimeLabel>{timeString}</TimeLabel>}
                </FlexColumn>
                <FlexColumn>
                    {socialAccounts.length > 0 && (
                        <>
                            <PlatformImage src={socialAccountImage} />
                            <AccountLabel>{socialAccountString}</AccountLabel>
                        </>
                    )}
                </FlexColumn>
            </ContentScreen>
        </Main>
    );
};

export default DirectBuyPreviewContent;
