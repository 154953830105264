import styled, { css } from "styled-components";
import SpaceBetween from "src/components/SpaceBetween";
import { colors, breakpoints, fonts } from "src/constants";
import { flex, hideScrollbar } from "src/utils/styles/snippets";

export const padding = css`
    padding: 18px;
`;

export const List = styled("div")`
    padding: 0px 18px 18px;

    & > * ~ * {
        margin-top: 10px;
    }
`;

export const ItemHeader = styled("div")`
    ${padding}
`;

export const Title = styled(SpaceBetween)`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
`;

export const Subtitle = styled(SpaceBetween)`
    color: ${colors.primaryGray};
    font-weight: normal;
    font-size: ${fonts.subtitle}px;
    line-height: 24px;
`;

export const HorizontalScrollOuter = styled("div")<{
    overflow?: string;
    bottomBorder?: string;
    topBorder?: string;
}>`
    display: flex;
    background-color: ${colors.lightTeal};
    border-top: ${props => {
        return props.topBorder || `${colors.mediumTeal} solid 1px`;
    }};
    border-bottom: ${props => {
        return props.bottomBorder || `${colors.mediumTeal} solid 1px`;
    }};
    overflow-x: ${props => props.overflow || "scroll"};

    @media (min-width: ${breakpoints.mobile}px) {
        ${hideScrollbar()};
    }
`;

export const HorizontalScrollInner = styled("div")`
    ${flex()};
    flex: 1;
    flex-wrap: nowrap;
    padding: 7px 3px;
`;

export const AccessText = styled("div")<{ color?: string }>`
    flex: 1;
    margin-left: 8px;
    color: ${props => (props.color ? props.color : "inherit")};
    font-size: 12px;
    line-height: 14.32px;
    text-align: left;
`;

export const StatusWrapper = styled("div")`
    padding: 0 18px 18px;
`;

export const StatusCard = styled("div")`
    flex: 1;
    margin: 8px;
    background-color: ${colors.white};
    border-radius: 6px;
`;

export const BottomRef = styled("div")``;
