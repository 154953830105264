import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
    OpenBuyInstructionStep,
    OpenBuySubmission,
    OpenBuySubmissionStatuses
} from "src/buys/modules/open-buys/types";
import { colors } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import { get } from "src/Api";
import SubmissionColumn from "./SubmissionColumn";
import XCard from "src/profile/components/XCard";
import MoreBtn from "src/components/modals/components/MoreBtn";
import SubmissionActions from "./SubmissionActions";
import { fetchSubmissionSet } from "src/buys/actions";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
`;

const StepHeader = styled.div`
    display: flex;
    font-weight: 600;
    font-size: 12px;
    align-items: center;
`;
const StepHeaderText = styled.div`
    font-weight: bolder;
    font-size: 12px;
`;

const StatusIndicator = styled.div<{
    status?: OpenBuySubmissionStatuses;
}>`
    border: 1px solid
        ${props =>
            props.status === OpenBuySubmissionStatuses.pending ||
            props.status === OpenBuySubmissionStatuses.revision_requested
                ? colors.messageYellow
                : props.status === OpenBuySubmissionStatuses.rejected
                ? colors.primaryRed
                : props.status === OpenBuySubmissionStatuses.approved
                ? colors.primaryGreen
                : colors.primaryBlue};

    color: ${props =>
        props.status === OpenBuySubmissionStatuses.pending ||
        props.status === OpenBuySubmissionStatuses.revision_requested
            ? colors.messageYellow
            : props.status === OpenBuySubmissionStatuses.rejected
            ? colors.primaryRed
            : props.status === OpenBuySubmissionStatuses.approved
            ? colors.primaryGreen
            : colors.primaryBlue};
    font-weight: 700;
    font-size: 12px;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    margin-right: 12px;
    justify-content: center;
    display: flex;
`;

const ActionBtn = styled(MoreBtn)`
    margin-left: auto;
`;

interface Props {
    submission?: OpenBuySubmission;
    step: OpenBuyInstructionStep;
}
const ApprovalSubmissionView = ({ submission, step }: Props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const tooltipRef = useRef(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const getSubmission = (id: number) => {
        setLoading(true);
        get("/v2/openBuySubmission/approvalSubmissions", {
            submissionIds: [id]
        })
            .then(res => {})
            .catch(e => {})
            .finally(() => setLoading(false));
    };

    function determineStepTitle(step: OpenBuyInstructionStep) {
        if (step.requiresCustomMedia) return "MEDIA";
        else if (step.requiresLinkSubmission) return "LINK";
        else if (step.requiresTextSubmission) return "TEXT";
    }

    return (
        <Main>
            <StepHeader>
                <StatusIndicator status={submission?.status}>
                    {submission?.status ===
                    OpenBuySubmissionStatuses.approved ? (
                        <img src="/approved-status.svg" />
                    ) : submission?.status ===
                      OpenBuySubmissionStatuses.pending ? (
                        <img src="/pending-status.svg" />
                    ) : submission?.status ===
                          OpenBuySubmissionStatuses.revision_requested ||
                      submission?.status ===
                          OpenBuySubmissionStatuses.rejected ? (
                        <img src="/rejected-status.svg" />
                    ) : (
                        "x"
                    )}
                </StatusIndicator>
                <StepHeaderText>
                    {determineStepTitle(step)} SUBMISSION:{" "}
                    {submission?.id || "Not Completed"}
                </StepHeaderText>
                <ActionBtn
                    refProp={tooltipRef}
                    onClick={() => setTooltipOpen(!tooltipOpen)}
                />
            </StepHeader>
            {submission ? (
                <>
                    <SubmissionColumn
                        setIsLoading={setLoading}
                        getSubmission={() => getSubmission(submission.id)}
                        submission={submission}
                    />
                    <SubmissionActions
                        submission={submission}
                        getSubmissionSet={() =>
                            dispatch(
                                fetchSubmissionSet(
                                    submission.openBuySubmissionSetId || 0
                                )
                            )
                        }
                        tooltipOpen={tooltipOpen}
                        tooltipRef={tooltipRef}
                        close={() => setTooltipOpen(false)}
                    />
                </>
            ) : (
                <XCard text="No submission for this step yet" />
            )}
        </Main>
    );
};
export default ApprovalSubmissionView;
