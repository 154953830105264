import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchTag from "src/components/SearchTag";
import { setMediaFilters } from "src/pagination/media/actions";
import { selectMediaFilters } from "../selectors";

const MediaDurationTags = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectMediaFilters);

    function clearDuration(gte: string, lt: string) {
        dispatch(
            setMediaFilters({
                ...filters,
                duration: { gte, lt }
            })
        );
    }

    if (filters.duration.gte && filters.duration.lt)
        return (
            <SearchTag
                color="black"
                iconSrc="/tag-black-close-icon.svg"
                text={`${filters.duration.gte}s - ${filters.duration.lt}s`}
                onClick={() => clearDuration("", "")}
            />
        );

    return (
        <>
            {filters.duration.gte && (
                <SearchTag
                    color="black"
                    iconSrc="/tag-black-close-icon.svg"
                    text={`> ${filters.duration.gte}s`}
                    onClick={() => clearDuration("", filters.duration.lt)}
                />
            )}
            {filters.duration.lt && (
                <SearchTag
                    color="black"
                    iconSrc="/tag-black-close-icon.svg"
                    text={`< ${filters.duration.lt}s`}
                    onClick={() => clearDuration(filters.duration.gte, "")}
                />
            )}
        </>
    );
};

export default MediaDurationTags;
