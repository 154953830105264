import React from "react";
import styled from "styled-components";
import { colors } from "../constants";

interface StyleProps {
    expanded: boolean;
}

const Main = styled.div<StyleProps>`
    ${props => (props.expanded ? "" : "max-height: 100px;")}
    margin-top: 10px;
    padding: 5px 0px 0px 5px;
    box-sizing: border-box;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 5px;
`;

const Label = styled.div`
    margin: 0px 20px 5px 0px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${colors.secondaryGray};
`;

const Point = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 100%;
    margin-right: 5px;
`;

interface Props {
    datasets: any;
    expanded: boolean;
}

const EarningsByDayLegend = ({ datasets, expanded }: Props) => {
    if (datasets.length === 0) {
        return null;
    }

    return (
        <Main expanded={expanded}>
            {datasets
                .sort((a: any, b: any) => {
                    return b.total - a.total;
                })
                .map((data: any, idx: number) => {
                    return (
                        <Label key={idx}>
                            <Point
                                style={{ backgroundColor: data.borderColor }}
                            />
                            <div>{data.label}</div>
                        </Label>
                    );
                })}
        </Main>
    );
};

export default EarningsByDayLegend;
