import React from "react";
import styled from "styled-components";

const commonStyle = `
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const Photo = styled.img<{ clickable: boolean }>`
    ${props => props.clickable && "cursor: pointer;"}
    ${commonStyle}
`;

const Video = styled.video`
    cursor: pointer;
    ${commonStyle}
`;

interface Props {
    mediaType: string;
    mediaUrl?: string | null;
    coverUrl?: string | null;
    playable: boolean;
    play: boolean;
    setPlay: (play: boolean) => void;
}

const RenderMedia = (props: Props) => {
    const { mediaType, mediaUrl, coverUrl, playable, play, setPlay } = props;

    if (!playable && coverUrl) {
        return (
            <Photo
                src={coverUrl}
                clickable={playable && !play}
                onClick={() => {
                    if (playable) {
                        if (!play) {
                            setPlay(true);
                        }
                    }
                }}
            />
        );
    } else if (mediaType === "video") {
        return (
            <Video
                playsInline={true}
                src={mediaUrl || undefined}
                poster={coverUrl || undefined}
                onClick={e => {
                    if (playable) {
                        if (!play) {
                            setPlay(true);
                        }
                        const target = e.target as HTMLVideoElement;
                        target.paused ? target.play() : target.pause();
                    }
                }}
            />
        );
    } else {
        return (
            <Photo
                src={mediaUrl || undefined}
                clickable={playable && !play}
                onClick={() => {
                    if (playable) {
                        if (!play) {
                            setPlay(true);
                        }
                    }
                }}
            />
        );
    }
};

export default RenderMedia;
