import React, { useState } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import ShadeScreen from "src/media/components/ShadeScreen";
import { Campaign } from "src/campaigns/types";
import SimpleNavbar from "src/components/SimpleNavbar";
import LoadingButton from "src/components/LoadingButton";
import { UploadMediaData } from "./PromoteFlowModal";
import TagSelectorButtons, {
    TagButtonsState
} from "src/media/upload/TagSelectorButtons";
import Switch from "react-switch";
import Divider from "../../components/Divider";
import SwitchContainer from "src/media/components/SwitchContainer";
import SwitchDescription from "src/media/components/SwitchDescription";
import adTagInfo from "src/media/upload/adTagInfo";
import { CustomMediaUpload } from "src/media/actions";

const Main = styled.div`
    text-align: left;
`;

const AdPreviewContainer = styled.div`
    position: relative;
    height: 382px;
    width: 228px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    background-color: ${colors.white};
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
`;

const Video = styled.video`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const Photo = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;
const Close = styled.img`
    padding: 15px;
    cursor: pointer;

    svg {
        margin-right: 15px;
    }
`;
const Tag = styled.img`
    position: absolute;
    right: 1px;
    bottom: 1px;
`;
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 18px 3px 18px;
`;
const Title = styled.div`
    color: darkgray;
    font-size: ${fonts.subtitle}px;
    line-height: 40px;
    font-weight: bold;
`;
const SubTitle = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 400;
    padding-left: 19px;
    text-align: center;
    padding-bottom: 18px;
`;

const SelectorContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 12px 54px 20px 54px;
`;

interface Props {
    campaign: Campaign;
    onUpload: () => void;
    onClose: () => void;
    uploadData: CustomMediaUpload;
    setUploadData: (data: any) => void;
    mediaUrl: string;
}

const PlaceAdTag = ({
    onUpload,
    onClose,
    uploadData,
    setUploadData,
    mediaUrl
}: Props) => {
    function renderIcon() {
        if (uploadData.type === "video") {
            return <img src={"/play_icon.svg"} />;
        } else {
            return null;
        }
    }

    const getTagState = (state: string) => {
        switch (uploadData.watermark_type) {
            case "jetfuel":
                return TagButtonsState.jetfuel;
            case "ad":
                return TagButtonsState.ad;
            case "hashtag":
                return TagButtonsState.hashtag;
            default:
                return TagButtonsState.none;
        }
    };

    return (
        <Main>
            <SimpleNavbar
                title={"Upload Media"}
                left={<Close onClick={onClose} src={"/close.svg"} />}
            />
            <SubTitle>Please add an 'ad' tag to this piece of media:</SubTitle>
            <AdPreviewContainer
                onClick={() => {
                    if (mediaUrl) {
                    }
                }}
            >
                {uploadData.type === "video" ? (
                    <Video src={mediaUrl || undefined} />
                ) : (
                    <Photo src={mediaUrl || undefined} />
                )}
                {uploadData.watermark_type === TagButtonsState.none || (
                    <Tag
                        style={
                            adTagInfo[getTagState(uploadData.watermark_type)]
                                .style
                        }
                        src={
                            adTagInfo[getTagState(uploadData.watermark_type)]
                                .image || undefined
                        }
                    />
                )}
                <ShadeScreen>{renderIcon()}</ShadeScreen>
            </AdPreviewContainer>
            <SelectorContainer>
                <Title>STYLE</Title>
                <TagSelectorButtons
                    tagState={getTagState(uploadData.watermark_type)}
                    setTagState={v =>
                        setUploadData((prevState: UploadMediaData) => ({
                            ...prevState,
                            watermark_type: v
                        }))
                    }
                />
            </SelectorContainer>
            <Divider />
            <SwitchContainer>
                <SwitchDescription>
                    This media already has an ‘ad’ tag.
                </SwitchDescription>
                <Switch
                    onColor={"#28c056"}
                    offColor={"#cccccc"}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    handleDiameter={24}
                    checked={uploadData.watermark_type === TagButtonsState.none}
                    onChange={(checked: boolean) => {
                        setUploadData((prevState: UploadMediaData) => ({
                            ...prevState,
                            watermark_type: TagButtonsState.none
                        }));
                    }}
                />
            </SwitchContainer>
            <Divider />
            <ButtonContainer>
                <LoadingButton
                    text="Upload Now"
                    height="48px"
                    width="100%"
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                    onClick={onUpload}
                />
            </ButtonContainer>
        </Main>
    );
};

export default PlaceAdTag;
