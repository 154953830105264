import React from "react";
import { colors, fonts, shadows } from "src/constants";
import { clearSpacing } from "src/utils/styles/snippets";
import styled from "styled-components";

const Main = styled.section`
    background-color: ${colors.white};
    border-radius: 8px;
    overflow: hidden;
    box-shadow: ${shadows.one};
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0px 12px;
`;

const Title = styled.h3`
    ${clearSpacing()};
    color: ${colors.black};
    font-size: ${fonts.title}px;
`;

interface BodyProps {
    padding: string;
    backgroundActive?: true;
}

const Body = styled.article<BodyProps>`
    padding: ${props => props.padding};
    background-color: ${props => {
        return props.backgroundActive && colors.quinaryLightGray;
    }};
`;

interface Props {
    title: string;
    body: JSX.Element;
    bodyPadding: string;
    backgroundActive?: true;
}

const SocialAccountProfileSection = ({
    title,
    body,
    bodyPadding,
    backgroundActive
}: Props) => {
    return (
        <Main>
            <Header>
                <Title>{title}</Title>
            </Header>
            <Body padding={bodyPadding} backgroundActive={backgroundActive}>
                {body}
            </Body>
        </Main>
    );
};

export default SocialAccountProfileSection;
