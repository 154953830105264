import React, { useState } from "react";
import Header from "../../../components/Header";
import Tab from "../../../components/Tab";
import { Channel } from "../../types";
import MemberList from "./MemberList";

export enum ListTabState {
    members,
    admins,
    banned
}

interface Props {
    channel: Channel;
    addMembersCallback: VoidFunction;
    addAdminsCallback: VoidFunction;
    actionsModalCallback: (currentUser: any) => void;
}

const MemberSection = ({
    channel,
    addMembersCallback,
    addAdminsCallback,
    actionsModalCallback
}: Props) => {
    const [tabState, setTabState] = useState(ListTabState.members);

    const connections = channel.connections;

    const admins = connections.filter(connection => {
        return channel.sendBirdData.members.some((sendbirdMember: any) => {
            return (
                connection.other.id === sendbirdMember.userId &&
                sendbirdMember.role === "operator"
            );
        });
    });
    const isAdmin = channel.sendBirdData.myRole === "operator";

    const banned = channel.banned;

    return (
        <>
            <div style={{ display: "flex" }}>
                <Tab
                    name={"Members"}
                    active={tabState === ListTabState.members}
                    onClick={() => {
                        setTabState(ListTabState.members);
                    }}
                />
                <Tab
                    name={"Admins"}
                    active={tabState === ListTabState.admins}
                    onClick={() => {
                        setTabState(ListTabState.admins);
                    }}
                />
                {banned && banned.bannedUsers.length > 0 && (
                    <Tab
                        name={"Banned"}
                        active={tabState === ListTabState.banned}
                        onClick={() => {
                            setTabState(ListTabState.banned);
                        }}
                    />
                )}
            </div>
            {banned && tabState === ListTabState.banned ? (
                <>
                    <Header
                        title={"Banned Users"}
                        subtitle={`${banned.bannedConnections.length} users`}
                    />
                    <MemberList
                        connections={banned.bannedConnections}
                        sendBirdData={channel.sendBirdData}
                        isAdmin={isAdmin}
                        actionsModalCallback={actionsModalCallback}
                        bannedUsers={banned.bannedUsers}
                    />
                </>
            ) : tabState === ListTabState.members ? (
                <>
                    <Header
                        title={"Group Members"}
                        subtitle={`${connections.length} members`}
                        link={{
                            text: "Add",
                            onClick: addMembersCallback
                        }}
                    />
                    <MemberList
                        connections={connections}
                        sendBirdData={channel.sendBirdData}
                        isAdmin={isAdmin}
                        actionsModalCallback={actionsModalCallback}
                    />
                </>
            ) : (
                <>
                    <Header
                        title={"Admins"}
                        subtitle={`${admins.length} admins`}
                        link={
                            isAdmin
                                ? {
                                      text: "Add",
                                      onClick: addAdminsCallback
                                  }
                                : undefined
                        }
                    />
                    <MemberList
                        connections={admins}
                        sendBirdData={channel.sendBirdData}
                        isAdmin={isAdmin}
                        actionsModalCallback={actionsModalCallback}
                    />
                </>
            )}
        </>
    );
};

export default MemberSection;
