import React from "react";
import { useSelector } from "react-redux";
import LabelText from "src/components/text/LabelText";
import { colors } from "src/constants";
import { getSuggestedChatUsers } from "src/new-chat/selectors";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import { SelectedUsers } from "../UserSelectionWithSearchModal";
import ChatUserHitCell from "./ChatUserHitCell";

const List = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    & > * ~ * {
        margin-top: 12px;
    }
`;

const Label = styled(LabelText)`
    position: sticky;
    top: 0px;
    padding: 10px 0;
    background-color: ${colors.white};
    z-index: 1;
`;

interface Props {
    onUserSelection: (
        publisherId: string,
        username: string,
        avatarUrl: string | null
    ) => void;
    filterOutUserIds?: string[];
    selectedUsers: SelectedUsers;
    queryString: string;
}

const SuggestedUserList = ({
    onUserSelection,
    selectedUsers,
    filterOutUserIds,
    queryString
}: Props) => {
    const connections = useSelector((state: GlobalState) =>
        getSuggestedChatUsers(state, queryString || "", filterOutUserIds)
    );

    return (
        <>
            <Label>SUGGESTED</Label>
            <List>
                {connections.map(connection => (
                    <div
                        key={connection.id}
                        onClick={() => {
                            if (connection.id && connection.profile.username)
                                onUserSelection(
                                    connection.id.replace("p:", ""),
                                    connection.profile.username,
                                    connection.profile.avatar
                                );
                        }}
                    >
                        <ChatUserHitCell
                            profilePhotoUrl={connection.profile.avatar}
                            username={connection.profile.username}
                            tagLine={connection.profile.tagLine}
                            status={connection.profile.status}
                            selected={
                                !!selectedUsers[connection.id.replace("p:", "")]
                            }
                        />
                    </div>
                ))}
            </List>
        </>
    );
};

export default SuggestedUserList;
