import React, { useEffect, useState } from "react";
import { DirectBuy } from "../../modules/direct-buy/types";
import { getStateForDirectBuy } from "../../modules/direct-buy/util";
import { useSelector } from "react-redux";
import { selectDirectBuyStepEntities } from "../../selectors";
import {
    Main,
    MiddleContainer,
    Photo,
    Tag,
    Title,
    Subtitle
} from "./styledComponents";
import useInterval from "../../../components/useInterval";
import {
    getTagInfoForDirectBuyState,
    getTimeStringForDirectBuy
} from "./directBuyDetailsHeaderUtil";

interface Props {
    directBuy: DirectBuy;
}

const DirectBuyDetailsHeader = ({ directBuy }: Props) => {
    const directBuySteps = useSelector(selectDirectBuyStepEntities);

    const [[directBuyState, directBuyStage], setDirectBuyState] = useState(() =>
        getStateForDirectBuy(directBuy, directBuySteps)
    );
    useEffect(() => {
        setDirectBuyState(getStateForDirectBuy(directBuy, directBuySteps));
    }, [directBuy, directBuySteps]);

    const [tick, setTick] = useState(0);
    useInterval(() => {
        setDirectBuyState(getStateForDirectBuy(directBuy, directBuySteps));
        setTick(tick + 1);
    }, 1000);

    const [timeString, setTimeString] = useState(
        getTimeStringForDirectBuy(directBuy, directBuyState, directBuyStage)
    );
    const [[tagColor, tagText], setTagInfo] = useState(
        getTagInfoForDirectBuyState(directBuyState)
    );
    useEffect(() => {
        setTimeString(
            getTimeStringForDirectBuy(directBuy, directBuyState, directBuyStage)
        );
        setTagInfo(getTagInfoForDirectBuyState(directBuyState));
    }, [directBuy, directBuyState, directBuyStage, tick]);

    return (
        <Main>
            {directBuy.buyer.photoUrl && (
                <Photo src={directBuy.buyer.photoUrl} />
            )}
            <MiddleContainer>
                <Title>{directBuy.buyer.name}</Title>
                <Subtitle>{timeString}</Subtitle>
            </MiddleContainer>
            {tagColor && tagText && (
                <Tag backgroundColor={tagColor}>{tagText}</Tag>
            )}
        </Main>
    );
};

export default DirectBuyDetailsHeader;
