import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import SearchBar from "src/components/SearchBar";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import ConversationCell from "../components/ConversationCell";
import { getConversations } from "../selectors";

const SearchWrapper = styled.div`
    padding: 10px 18px 20px;
`;

const ConversationList = styled.div`
    & > * ~ * {
        margin-top: 20px;
    }
    padding-bottom: 20px;
`;

const RequestDescription = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: ${colors.primaryGray};
    margin-top: 10px;
`;

interface Props {
    isInbox: boolean;
}

const InboxTab = ({ isInbox }: Props) => {
    const [limit, setLimit] = useState(20);
    const [searchString, updateSearchString] = useState("");
    const conversations = useSelector((state: GlobalState) =>
        getConversations(state, isInbox, searchString, limit)
    );

    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView && conversations.length === limit) {
            setLimit(limit => limit + 20);
        }
    }, [inView]);

    return (
        <>
            <SearchWrapper>
                <SearchBar
                    autoFocus={false}
                    searchString={searchString}
                    updateSearchString={updateSearchString}
                />
                {!isInbox && (
                    <RequestDescription>
                        This is where you’ll see messages from users who aren’t
                        connected with you. They won’t know you’ve seen the
                        request unless you respond.
                    </RequestDescription>
                )}
            </SearchWrapper>
            <ConversationList>
                {conversations.map(conversation => (
                    <ConversationCell
                        key={conversation.id}
                        conversationId={conversation.id}
                        displayName={conversation.displayName}
                        imageUrl={conversation.imageUrl}
                        lastMessage={conversation.lastMessage}
                        lastMessageSentAt={conversation.lastMessageSentAt}
                        unreadCount={conversation.unreadCount}
                        participantIds={conversation.participantIds}
                        blocked={conversation.blocked}
                    />
                ))}
            </ConversationList>
            <div ref={ref} />
        </>
    );
};

export default InboxTab;
