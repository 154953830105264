import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Main = styled(Link)`
    text-decoration: inherit;
    color: inherit;
    display: block;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: inherit;
        color: inherit;
    }
`;

interface Props {
    to: string;
    children: ReactNode;
    className?: string;
}

const InternalLink = ({ to, children, className }: Props) => {
    return (
        <Main
            to={{ pathname: to, state: { internal: true } }}
            className={className}
        >
            {children}
        </Main>
    );
};

export default InternalLink;
