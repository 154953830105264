import { Action, NormalizedAction } from "../../types";
import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../../reducers";

// Fetch Campaign Medias  -------------------------------------------------------------------

export const FETCH_CAMPAIGN_MEDIAS = {
    REQUEST: "FETCH_CAMPAIGN_MEDIAS_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_MEDIAS_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_MEDIAS_FAILURE"
};

export const fetchCampaignMedias = (
    campaignId: number,
    mediaId?: number,
    placementIds?: number[]
): Action => ({
    type: FETCH_CAMPAIGN_MEDIAS.REQUEST,
    payload: { campaignId, mediaId, placementIds }
});

export const fetchCampaignMediasSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { campaignId: number }
): NormalizedAction => {
    return {
        type: FETCH_CAMPAIGN_MEDIAS.SUCCESS,
        response,
        payload
    };
};

export const fetchCampaignMediasFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_MEDIAS.FAILURE,
    payload: { error }
});

// Fetch Campaign Media  -------------------------------------------------------------------
export const FETCH_CAMPAIGN_MEDIA = {
    REQUEST: "FETCH_CAMPAIGN_MEDIA_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_MEDIA_SUCCESS"
};
export function fetchCampaignMedia(
    campaignId: number,
    mediaId: number
): Action {
    return {
        type: FETCH_CAMPAIGN_MEDIA.REQUEST,
        payload: { campaignId, mediaId }
    };
}
export function fetchCampaignMediaSuccess(
    response: NormalizedSchema<{ [key: string]: { [key: string]: any } }, any>
): NormalizedAction {
    return {
        type: FETCH_CAMPAIGN_MEDIA.SUCCESS,
        response
    };
}

// Public Media  -------------------------------------------------------------------

export const FETCH_CAMPAIGN_PUBLIC_MEDIAS = {
    REQUEST: "FETCH_CAMPAIGN_PUBLIC_MEDIAS_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_PUBLIC_MEDIAS_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_PUBLIC_MEDIAS_FAILURE"
};

export function fetchCampaignPublicMedias(campaignId: number): Action {
    return {
        type: FETCH_CAMPAIGN_PUBLIC_MEDIAS.REQUEST,
        payload: { campaignId }
    };
}

export function fetchCampaignPublicMediasSuccess(
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { campaignId: number }
): NormalizedAction {
    return {
        type: FETCH_CAMPAIGN_PUBLIC_MEDIAS.SUCCESS,
        response,
        payload
    };
}

// Script Medias ---------------------------------------------------------------

export const FETCH_SCRIPT_MEDIAS = {
    REQUEST: "FETCH_SCRIPT_MEDIAS_REQUEST",
    SUCCESS: "FETCH_SCRIPT_MEDIAS_SUCCESS",
    FAILURE: "FETCH_SCRIPT_MEDIAS_FAILURE"
};

export const fetchScriptMedias = (scriptId: number): Action => ({
    type: FETCH_SCRIPT_MEDIAS.REQUEST,
    payload: { scriptId }
});

export const fetchScriptMediasSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { scriptId: number }
): NormalizedAction => ({
    type: FETCH_SCRIPT_MEDIAS.SUCCESS,
    response,
    payload
});

export const fetchScriptMediasFailure = (error: string): Action => ({
    type: FETCH_SCRIPT_MEDIAS.FAILURE,
    payload: { error }
});
