import styled from "styled-components";
import SettingsText from "./SettingsText";
import { colors } from "../../../constants";

export default styled(SettingsText)<{ grayed?: boolean }>`
    color: ${props => (props.grayed ? colors.primaryGray : colors.primaryBlue)};
    user-select: none;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            color: ${props => {
                return props.grayed ? colors.black : colors.primaryBlueDark;
            }};
        }
    }
`;
