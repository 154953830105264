import { useSlate } from "slate-react";
import CustomEditor from "../CustomEditor";
import { BtnWrapper } from "./Btn";
import React from "react";

const BlockBtn = ({
    children,
    format
}: {
    children: any;
    format: "ul_list" | "ol_list";
}) => {
    const editor = useSlate();
    const isActive = CustomEditor.isListActive(editor, format);

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        CustomEditor.toggleList(editor, format);
    };
    return (
        <BtnWrapper type="button" isActive={isActive} onMouseDown={handleClick}>
            {children}
        </BtnWrapper>
    );
};
export default BlockBtn;
