import { DropResult } from "react-beautiful-dnd";
import { Dispatch } from "redux";
import { PlugLink } from "src/media/types";
import {
    blankInstructionStepState,
    InstructionStepState,
    setAnInstructionStepHasMoved,
    setInstructionSteps
} from "src/ui/instructions-sets/actions";
import { convertToCamelCase } from "src/utils/functions/helpers";
import {
    DropdownCopy,
    InstructionElementArrayKey,
    InstructionElementType,
    InstructionElementUploadSubmissionKey
} from "../../utils/types";
import {
    ChildInstructionStep,
    ChildInstructionSteps,
    InstructionsSet,
    InstructionStep
} from "../types";
import { setPillNotificationText } from "src/ui/notifications/actions";

// Data ---------------------------------------------------------------

export const dropdownCopies: DropdownCopy[] = [
    { title: "Scripts", subtitle: "Provide a detailed script for the creator" },
    {
        title: "Media",
        subtitle: "Provide media for the creator to reference/use"
    },
    {
        title: "Assets",
        subtitle: "Provide media for the creator to use in their ad"
    },
    { title: "Links", subtitle: "Provide a link for creators to use" }
];

export const instructionElementTitles = [
    "Scripts",
    "Media",
    "Assets",
    "Link",
    "Requires Custom Media",
    "Requires Screenshot",
    "Requires Link Submission",
    "Requires Text Submission"
] as const;

export const uploadSubmissionDropdownTypes = [
    "Requires Custom Media",
    "Requires Screenshot",
    "Requires Link Submission",
    "Requires Text Submission"
] as const;

// Page Partial Helper Functions ---------------------------------------------------------------

export function convertPathsToCleanTexts(paths: string[]): any {
    const cleanedPaths = paths.map((path: string) => {
        return path.split("-").join(" ");
    });

    return cleanedPaths;
}

// Main Page Helper Functions ---------------------------------------------------------------

function _convertInitialStepValues(
    convertedInstructionStep: InstructionStepState,
    id?: number,
    description?: string,
    scriptId?: number | null,
    mediaId?: number | null,
    assetId?: number | null,
    linkUrl?: string | null,
    requiresPlugLink?: boolean,
    requiresCustomMedia?: boolean,
    requiresRawMedia?: boolean,
    requiresScreenshot?: boolean,
    requiresLinkSubmission?: boolean,
    requiresTextSubmission?: boolean,
    plugLinkSkipLandingPage?: boolean,
    submissionLimit?: number
): void {
    if (!!id) {
        convertedInstructionStep["id"] = id;
    }

    if (!!description) {
        convertedInstructionStep["description"] = description;
    }

    if (!!scriptId && !convertedInstructionStep["scripts"].includes(scriptId)) {
        convertedInstructionStep["scripts"].push(scriptId);
    }

    if (!!mediaId && !convertedInstructionStep["media"].includes(mediaId)) {
        convertedInstructionStep["media"].push(mediaId);
    }

    if (!!assetId && !convertedInstructionStep["assets"].includes(assetId)) {
        convertedInstructionStep["assets"].push(assetId);
    }

    if (!!linkUrl) {
        convertedInstructionStep["customLink"] = linkUrl;
    }

    if (!!requiresPlugLink) {
        convertedInstructionStep["requiresPlugLink"] = requiresPlugLink;
    }

    if (plugLinkSkipLandingPage) {
        convertedInstructionStep[
            "plugLinkSkipLandingPage"
        ] = plugLinkSkipLandingPage;
    }
    if (!!requiresRawMedia) {
        convertedInstructionStep["requiresRawMedia"] = requiresRawMedia;
    }

    if (!!requiresCustomMedia) {
        convertedInstructionStep["requiresCustomMedia"] = requiresCustomMedia;
    }

    if (!!requiresScreenshot) {
        convertedInstructionStep["requiresScreenshot"] = requiresScreenshot;
    }

    if (!!requiresLinkSubmission) {
        convertedInstructionStep[
            "requiresLinkSubmission"
        ] = requiresLinkSubmission;
    }

    if (!!requiresTextSubmission) {
        convertedInstructionStep[
            "requiresTextSubmission"
        ] = requiresTextSubmission;
    }

    if (!!submissionLimit) {
        convertedInstructionStep["submissionLimit"] = submissionLimit;
    }
}

function _convertAPIChildSteps(
    convertedInstructionStep: InstructionStepState,
    APIChildInstructionSteps: ChildInstructionSteps | undefined
): void {
    if (!!APIChildInstructionSteps && APIChildInstructionSteps.length > 0) {
        APIChildInstructionSteps.forEach(
            (childInstructionStep: ChildInstructionStep) => {
                if (
                    !!childInstructionStep.scriptId &&
                    !convertedInstructionStep["scripts"].includes(
                        childInstructionStep.scriptId
                    )
                ) {
                    convertedInstructionStep["scripts"].push(
                        childInstructionStep.scriptId
                    );
                }

                if (
                    !!childInstructionStep.mediaId &&
                    !convertedInstructionStep["media"].includes(
                        childInstructionStep.mediaId
                    )
                ) {
                    convertedInstructionStep["media"].push(
                        childInstructionStep.mediaId
                    );
                }

                if (
                    !!childInstructionStep.assetId &&
                    !convertedInstructionStep["assets"].includes(
                        childInstructionStep.assetId
                    )
                ) {
                    convertedInstructionStep["assets"].push(
                        childInstructionStep.assetId
                    );
                }
            }
        );
    }
}

export function convertAPIDataToReduxData(
    currentInstructionsSet: InstructionsSet | undefined,
    dispatch?: Dispatch<any>
): InstructionStepState[] | void {
    let instructionStepsRedux: InstructionStepState[] = [];

    if (!!currentInstructionsSet && !!currentInstructionsSet.instructionSteps) {
        instructionStepsRedux = currentInstructionsSet.instructionSteps.map(
            (instructionStep: InstructionStep) => {
                const convertedInstructionStep = blankInstructionStepState();

                const {
                    id,
                    description,
                    scriptId,
                    mediaId,
                    assetId,
                    linkUrl,
                    requiresPlugLink,
                    requiresCustomMedia,
                    requiresRawMedia,
                    requiresScreenshot,
                    requiresLinkSubmission,
                    requiresTextSubmission,
                    submissionLimit,
                    plugLinkSkipLandingPage: plugLinkSkipLandingPage
                } = instructionStep;

                // Setting up first index values for the converted instruction step (API -> Redux data).
                _convertInitialStepValues(
                    convertedInstructionStep,
                    id,
                    description,
                    scriptId,
                    mediaId,
                    assetId,
                    linkUrl,
                    requiresPlugLink,
                    requiresCustomMedia,
                    requiresRawMedia,
                    requiresScreenshot,
                    requiresLinkSubmission,
                    requiresTextSubmission,
                    plugLinkSkipLandingPage,
                    submissionLimit
                );

                // Setting up the rest of the indexes for the converted instruction step.
                _convertAPIChildSteps(
                    convertedInstructionStep,
                    instructionStep.instructionSteps
                );

                return convertedInstructionStep;
            }
        );
    }

    if (dispatch) {
        dispatch(setInstructionSteps(instructionStepsRedux));
    } else {
        return instructionStepsRedux;
    }
}

// Create Page Helper Functions ---------------------------------------------------------------

export function handleInstructionStepDrag(
    previousColumn: string,
    currentColumn: string | undefined,
    previousPosition: number,
    currentPosition: number | undefined,
    dispatch: Dispatch<any>
): void {
    const anInstructionStepWasMoved =
        previousColumn === "InstructionsFormDroppable" &&
        currentColumn === "InstructionsFormDroppable" &&
        currentPosition !== previousPosition;
    if (anInstructionStepWasMoved) {
        dispatch(setAnInstructionStepHasMoved(true));
    }
}

export function handleDraggingOfInstructionElementIntoAnInstructionStep(
    previousColumn: string,
    currentColumn: string | undefined,
    instructionSteps: InstructionStepState[],
    result: DropResult,
    dispatch: Dispatch<any>,
    linksForPlugs: { [s: string]: PlugLink | null },
    currentCampaignId: number | null
): void {
    const instructionStepIndex = result.destination?.droppableId.split(
        "-"
    )[1] as any;
    const draggedInstructionElementData = result.draggableId.split("-");
    // NOTE: The `instructionElementType` and `instructionElementId` values
    //       below are taken from the `draggableId` value of the <Draggable />'s
    //       in hooks.create-instructions.tsx.
    const instructionElementType = draggedInstructionElementData[0] as InstructionElementType;
    const instructionElementId = Number(draggedInstructionElementData[1]);

    const _previousColumnWasAnInstructionElementDropdown = previousColumn.includes(
        "CreateDropdownItemsDroppable"
    );

    const _currentColumnIsEmptyDragArea = currentColumn
        ?.split("-")
        .includes("InstructionsFormStepEmptyDroppable");

    const _previousColumnType = previousColumn.split("-")[1];
    const _currentColumnType = currentColumn?.split("-")[2];

    const _currentColumnIsATitledDragArea =
        _previousColumnType === _currentColumnType;

    const anInstructionElementWasMovedIntoAnInstructionStep =
        _previousColumnWasAnInstructionElementDropdown &&
        (_currentColumnIsEmptyDragArea || _currentColumnIsATitledDragArea);

    const updatedInstructionSteps = Array.from(instructionSteps);

    const currentUpdatedInstructionStep =
        updatedInstructionSteps[instructionStepIndex];

    if (anInstructionElementWasMovedIntoAnInstructionStep) {
        const draggedInstructionElementIsAScriptMediaOrAsset =
            instructionElementType === "Scripts" ||
            instructionElementType === "Media" ||
            instructionElementType === "Assets";

        if (draggedInstructionElementIsAScriptMediaOrAsset) {
            const currentStepKey = convertToCamelCase(
                instructionElementType
            ) as InstructionElementArrayKey;

            if (!!currentUpdatedInstructionStep) {
                const currentStepValue =
                    currentUpdatedInstructionStep[currentStepKey];

                if (!currentStepValue.includes(instructionElementId)) {
                    currentStepValue.push(instructionElementId);
                }

                dispatch(setInstructionSteps(updatedInstructionSteps));
            }
        }

        // If the dragged instruction element is a link item.
        else if (instructionElementType === "Link") {
            // Adding a custom link input field into an instruction step.
            if (instructionElementId === 0) {
                currentUpdatedInstructionStep["customLink"] = "";
                dispatch(setInstructionSteps(updatedInstructionSteps));
            }
            // Requiring a plug link in an instruction step.
            else {
                currentUpdatedInstructionStep["requiresPlugLink"] = true;
                dispatch(setInstructionSteps(updatedInstructionSteps));
            }
        }

        // If the dragged instruction element is an upload submission item.
        else {
            const currentStepKey = convertToCamelCase(
                instructionElementType
            ) as InstructionElementUploadSubmissionKey;

            // Setting requiresCustomMedia, requiresScreenshot, requiresLinkSubmission, or requiresTextSubmission to "true" when dragged in.
            currentUpdatedInstructionStep[currentStepKey] = true;

            //if media add in the required raw media substep
            if (currentStepKey === "requiresCustomMedia") {
                updatedInstructionSteps.push({
                    ...currentUpdatedInstructionStep,
                    description: "step will not display in the instructions",
                    requiresRawMedia: true
                });
            }

            dispatch(setInstructionSteps(updatedInstructionSteps));
        }
    }
}
