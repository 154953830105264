import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as Papa from "papaparse";
import { getCurrentOpenBuy } from "src/admin-tools/selectors.admin-tools";
import { colors, fonts, fontWeight } from "src/constants";
import ChallengeFormSubSection from "src/marketplace/challenge-creation-form/components/ChallengeFormSubSection";
import MarketplaceInput from "src/marketplace/components/MarketplaceInput";
import ResponsiveModal from "src/modals/ResponsiveModal";
import { BlueButton } from "src/profile/components/Buttons";
import {
    clearSpacing,
    grayBackgroundHoverState
} from "src/utils/styles/snippets";
import { EventInput } from "src/utils/types/form";
import { setPillNotificationText } from "src/ui/notifications/actions";
import PlugTable from "src/components/table/PlugTable";
import { TableRow } from "src/components/table/types";
import { createCell } from "src/components/table/utils";
import { get, post } from "src/Api";
import {
    addCreatorSpecificPayment,
    updateCreatorSpecificPayment
} from "../open-buy-payments/actions";
import { CreatorGroupPublisher } from "../types";

const Main = styled.main`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Header = styled.header`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Title = styled.h1`
    ${clearSpacing()};
    color: ${colors.black};
    font-size: ${fonts.semiTitle}px;
    font-weight: ${fontWeight.medium};
    line-height: 21px;
`;

const InputSection = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const AddButton = styled(BlueButton)`
    padding: 9px 15px;
    font-size: ${fonts.smallTitle}px;
`;

const ConfirmButton = styled(BlueButton)`
    padding: 8px 12px;
    font-size: ${fonts.smallTitle}px;
    align-self: flex-end;
`;

const UploadCSVButton = styled.label`
    ${grayBackgroundHoverState()};
    padding: 8px 12px;
    color: ${colors.grayBlack};
    border-radius: 6px;
    font-size: ${fonts.semiSmallTitle}px;
    font-weight: ${fontWeight.medium};
    cursor: pointer;
    white-space: nowrap;
    margin: 0;
`;

const CSVInput = styled.input`
    display: none;
`;

const Tables = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 24px;
    & > * {
        flex: 1;
    }
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

interface Props {
    index: number;
    openBuyId: number;
    instructionStepId: number;
    currentCreatorGroupId: number;
    closeModal: () => void;
}

const CreatorSpecificPaymentModal = ({
    index,
    openBuyId,
    instructionStepId,
    currentCreatorGroupId,
    closeModal
}: Props) => {
    const dispatch = useDispatch();

    const currentOpenBuy = useSelector(getCurrentOpenBuy);

    // Local State ---------------------------------------------------------------
    const [individualPubId, setIndividualPubId] = useState<string>("");
    const [tableLoading, setTableLoading] = useState<boolean>(true);
    const [stagedPublishers, setStagedPublishers] = useState<
        CreatorGroupPublisher[]
    >([]);
    const [addedPublishers, setAddedPublishers] = useState<
        CreatorGroupPublisher[]
    >([]);
    const [csvAdded, setCsvAdded] = useState(false);

    // Local Constants ------------------------------------------------------------
    const stagedPublishersTableData: TableRow[] = stagedPublishers.map(
        (publisher, idx) => ({
            originalIdx: idx,
            raw: { publisher_id: publisher.publisherId },
            rowData: {
                publisher_id: createCell.number(publisher.publisherId),
                publisher_name: createCell.string(publisher.publisherName),
                unstage: createCell.button(
                    () =>
                        setStagedPublishers(
                            stagedPublishers.filter(
                                stagedPubId =>
                                    publisher.publisherId !==
                                    stagedPubId.publisherId
                            )
                        ),
                    "Unstage"
                )
            }
        })
    );

    const addedPublishersTableData: TableRow[] = addedPublishers.map(
        (publisher, idx) => ({
            originalIdx: idx,
            raw: { publisher_id: publisher.publisherId },
            rowData: {
                publisher_id: createCell.number(publisher.publisherId),
                publisher_name: createCell.string(publisher.publisherName),
                remove: createCell.button(
                    () =>
                        setAddedPublishers(
                            addedPublishers.filter(
                                addedPubId =>
                                    publisher.publisherId !==
                                    addedPubId.publisherId
                            )
                        ),
                    "Remove"
                )
            }
        })
    );

    // Effects --------------------------------------------------------------------
    useEffect(() => {
        get(
            `/v1/creatorGroup/${currentCreatorGroupId}/uniquePublishers`,
            {}
        ).then(({ data }) => {
            setAddedPublishers(data.data);
        });
    }, [currentCreatorGroupId]);

    // Functions ------------------------------------------------------------------
    function handleCSVUpload(e: EventInput) {
        e.preventDefault();
        const files = e.currentTarget.files;
        if (files && files.length > 0) {
            const file = files[0];
            Papa.parse(file, {
                complete: (response: any) => {
                    if (response.data.length > 0) {
                        const csvFileColumns = response.data[0];
                        const publisherIdIndex =
                            csvFileColumns.indexOf("publisher_id") !== -1
                                ? csvFileColumns.indexOf("publisher_id")
                                : csvFileColumns.indexOf("publisherId");
                        const noPublisherIdColumn = publisherIdIndex === -1;
                        if (noPublisherIdColumn) {
                            dispatch(
                                setPillNotificationText(
                                    "CSV does not contain a publisher_id column",
                                    "danger",
                                    3500
                                )
                            );
                        } else {
                            const body = response.data.slice(1);
                            const publisherIds = body
                                .filter(
                                    (row: any) =>
                                        !!parseInt(row[publisherIdIndex])
                                )
                                .map((row: any) => ({
                                    publisherId: parseInt(
                                        row[publisherIdIndex]
                                    ),
                                    publisherName: ""
                                })) as CreatorGroupPublisher[];
                            setStagedPublishers([
                                ...Array.from(
                                    new Set([
                                        ...stagedPublishers,
                                        ...publisherIds
                                    ])
                                )
                            ]);
                            setCsvAdded(true);
                        }
                    }
                }
            });
        }
    }

    const addIndividualPublisher = () => {
        if (
            stagedPublishers.find(
                publisher => publisher.publisherId === parseInt(individualPubId)
            )
        ) {
            dispatch(
                setPillNotificationText(
                    `Publisher ID: ${individualPubId} already staged`,
                    "danger",
                    3500
                )
            );
        } else {
            setStagedPublishers([
                ...stagedPublishers,
                {
                    publisherId: parseInt(individualPubId),
                    publisherName: ""
                }
            ]);
            setIndividualPubId("");
        }
    };

    const handleAssignment = () => {
        setAddedPublishers([...addedPublishers, ...stagedPublishers]);
        setStagedPublishers([]);
    };

    const handleConfirm = () => {
        if (currentOpenBuy) {
            post(`/v1/creatorGroup`, {
                name: `${currentOpenBuy.name} Payment Group ${Date.now()}`,
                publisherIds: addedPublishers.map(
                    publisher => publisher.publisherId
                )
            })
                .then(({ data }) => {
                    if (currentCreatorGroupId) {
                        dispatch(
                            updateCreatorSpecificPayment({
                                openBuyId,
                                instructionStepId,
                                oldCreatorGroupId: currentCreatorGroupId,
                                newCreatorGroupId: data.data.id
                            })
                        );
                    } else {
                        dispatch(
                            addCreatorSpecificPayment({
                                openBuyId,
                                instructionStepId,
                                creatorGroupId: data.data.id
                            })
                        );
                    }
                    closeModal();
                })
                .catch(error => {
                    dispatch(
                        setPillNotificationText(
                            "Unable to add creators",
                            "danger"
                        )
                    );
                });
        }
    };

    // Return JSX -----------------------------------------------------------------
    return (
        <ResponsiveModal
            modalOpen={true}
            closeModal={closeModal}
            title="Publisher Assignment"
        >
            <Main>
                <Header>
                    <Title>
                        Review Publishers Assigned to Specific Payment
                        {` ${index + 1}`}
                    </Title>
                </Header>

                <ChallengeFormSubSection
                    title="ADD INDIVIDUAL PUBLISHER"
                    children={
                        <InputSection>
                            <MarketplaceInput
                                type="number"
                                value={individualPubId}
                                placeholder="Input Publisher ID"
                                onChange={(e: EventInput) =>
                                    setIndividualPubId(e.currentTarget.value)
                                }
                            />
                            <AddButton
                                type="button"
                                onClick={addIndividualPublisher}
                            >
                                Add
                            </AddButton>
                            <UploadCSVButton htmlFor="payments-publisher-assignment-form-csv-upload">
                                <CSVInput
                                    id="payments-publisher-assignment-form-csv-upload"
                                    type="file"
                                    accept=".csv"
                                    onChange={handleCSVUpload}
                                />
                                {csvAdded ? "Upload Another CSV" : "Upload CSV"}
                            </UploadCSVButton>
                        </InputSection>
                    }
                />
                <Tables>
                    <TableContainer>
                        <PlugTable
                            title="Publishers to Add"
                            titleSize={fonts.subtitle}
                            columns={[
                                {
                                    coreKey: "publisher_id",
                                    displayValue: "Publisher ID",
                                    sortType: "number"
                                },
                                {
                                    coreKey: "publisher_name",
                                    displayValue: "Publisher Name",
                                    sortType: "string"
                                },
                                { coreKey: "unstage", displayValue: "Unstage" }
                            ]}
                            cellData={stagedPublishersTableData}
                            resultsPerPage={6}
                            tableButtons={[
                                {
                                    text: "Clear",
                                    color: "gray",
                                    onClick: () => {
                                        setStagedPublishers([]);
                                        setCsvAdded(false);
                                    },
                                    type: "button"
                                },
                                {
                                    text: "Add",
                                    color: "blue",
                                    onClick: () => handleAssignment(),
                                    disabled:
                                        stagedPublishersTableData.length === 0,
                                    type: "button"
                                }
                            ]}
                        />
                    </TableContainer>
                    <TableContainer>
                        <PlugTable
                            title="Added Publishers"
                            titleSize={fonts.subtitle}
                            columns={[
                                {
                                    coreKey: "publisher_id",
                                    displayValue: "Publisher ID",
                                    sortType: "number"
                                },
                                {
                                    coreKey: "publisher_name",
                                    displayValue: "Publisher Name",
                                    sortType: "string"
                                },
                                { coreKey: "remove", displayValue: "Remove" }
                            ]}
                            cellData={addedPublishersTableData}
                            resultsPerPage={6}
                            tableButtons={[
                                {
                                    text: "Remove All",
                                    color: "red",
                                    onClick: () => {
                                        setAddedPublishers([]);
                                        setCsvAdded(false);
                                    },
                                    disabled:
                                        addedPublishersTableData.length === 0
                                }
                            ]}
                        />
                    </TableContainer>
                </Tables>

                <ConfirmButton type="button" onClick={handleConfirm}>
                    Confirm
                </ConfirmButton>
            </Main>
        </ResponsiveModal>
    );
};

export default CreatorSpecificPaymentModal;
