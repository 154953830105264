import React from "react";
// import sessionStorage from "../components/sessionStorage";
import { goBack, replace } from "connected-react-router";
import { TabState } from "./index";
import StateResults from "../components/StateResults";
import { connect } from "react-redux";
import styled from "styled-components";
import { colors, maxWidthDesktop } from "src/constants";

interface AllSectionProps {
    account: boolean;
}

const AllSection = styled.section<AllSectionProps>`
    margin-top: 20px;
    margin-bottom: ${props => (props.account ? "20px" : "0px")};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${maxWidthDesktop}px) {
        margin-top: 0px;
        margin-bottom: 0px;
        border: none;
        border-bottom: ${props => {
            return !props.account ? `${colors.mediumTeal} solid 1px` : "none";
        }};
        border-radius: 0px;
    }
`;

const tabToIndexName = {
    Campaigns: "campaigns",
    Users: "publishers",
    Accounts: "social-accounts"
};

interface Props {
    currentTab: TabState;
    replace: (path: string) => void;
    goBack: () => void;
}

const SearchTabs = ({ currentTab, replace, goBack }: Props) => {
    const tabs = ["Campaigns", "Users", "Accounts"] as const;

    type Tab = typeof tabs[number];

    switch (currentTab) {
        case TabState.all:
            return (
                <>
                    {tabs.map((tab: Tab, index: number) => {
                        return (
                            <AllSection
                                key={`search-page-search-tabs-${index}-${tab}`}
                                account={tab === "Accounts"}
                            >
                                <StateResults
                                    title={tab}
                                    chevron={() =>
                                        replace(`/search/${tab.toLowerCase()}`)
                                    }
                                    index={tabToIndexName[tab]}
                                />
                            </AllSection>
                        );
                    })}
                </>
            );
        case TabState.users:
            return <StateResults title="Users" index="publishers" infinite />;
        case TabState.accounts:
            return (
                <StateResults
                    title="Accounts"
                    index="social-accounts"
                    infinite
                />
            );
        case TabState.campaigns:
            return (
                <StateResults title="Campaigns" index="campaigns" infinite />
            );
        default:
            return null;
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    replace: (path: string) => dispatch(replace(path)),
    goBack: () => dispatch(goBack())
});

export default connect(null, mapDispatchToProps)(SearchTabs);
