import React from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as Share } from "./icons/share-button.svg";
import { colors } from "../constants";
import styled, { css } from "styled-components";
import { addNotification } from "../ui/notifications/actions";
import CopyToClipboard from "react-copy-to-clipboard";

interface StyleProps {
    isCampaign?: boolean;
    isScript?: boolean;
    isModal?: boolean;
}

const ShareContainer = styled.div<StyleProps>`
    display: flex;
    align-items: center;
    margin: ${props => (props.isCampaign ? "0 15px 0 auto" : "0")};
    justify-content: center;
    text-align: center;
    background-color: ${colors.primaryGray};
    border-radius: 50%;
    height: 26px;
    width: 26px;
    cursor: pointer;
    z-index: 90;
    flex-shrink: 0;
    &:hover {
        background-color: ${colors.secondaryGray};
    }

    ${props =>
        props.isCampaign
            ? "margin: 0 8px 0 auto"
            : props.isScript
            ? "margin: 0px"
            : "margin: 0; position: absolute; right: 8px; top: 8px; background-color: rgba(0,0,0,.6);"}
    ${props =>
        props.isModal &&
        css`
            margin: 0px;
            position: static;
            background-color: ${colors.quaternaryLightGray};
            border: 1px solid transparent;
            transition: border-color 0.2s ease;
            & > svg > path {
                fill: ${colors.primaryGray};
            }
            &:hover {
                background-color: ${colors.quaternaryLightGray};
                border-color: ${colors.primaryGray};
            }
        `}
`;

interface Props {
    isCampaign?: boolean;
    isScript?: boolean;
    isModal?: boolean;
    link: string;
    className?: string;
}

const rootURL = window.location.port
    ? `http://${window.location.hostname}:${window.location.port}`
    : `https://${window.location.host}`;

const ShareButton = ({
    isCampaign = false,
    isScript = false,
    isModal = false,
    className,
    link
}: Props) => {
    const dispatch = useDispatch();
    return (
        <CopyToClipboard
            text={`${rootURL}${link}`}
            onCopy={() =>
                dispatch(
                    addNotification(
                        `Link succesfully copied: ${rootURL}${link}`
                    )
                )
            }
        >
            <ShareContainer
                className={className}
                isCampaign={isCampaign}
                isScript={isScript}
                isModal={isModal}
                onClick={e => e.stopPropagation()}
            >
                <Share />
            </ShareContainer>
        </CopyToClipboard>
    );
};

export default ShareButton;
