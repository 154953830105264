import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../reducers";
import {
    campaignScriptModal,
    ModalView,
    setScriptModalView,
    setWarning
} from "../../../ui/scripts/actions";
import { deleteScript, fetchScript } from "../actions";
import { clearModal } from "../../../modals/actions";
import LargeModal from "../../../modals/LargeModal";
import ScriptHome from "../components/ScriptHome";
import AddFile from "../components/AddFile";
import SelectMedia from "../components/SelectMedia";
import SelectAsset from "../components/SelectAsset";
import ScriptEdit from "../components/ScriptEdit";
import ScriptError from "../components/ScriptError";
import Warning from "./Warning";
import { fetchCampaign } from "src/campaigns/actions";
import { selectScriptModalCampaign, selectScriptData } from "../selectors";
import ScriptLoading from "../components/ScriptLoading";
import { selectAdminStatus } from "src/auth/selectors";
import useProcessing from "../hooks/useProcessing";

export interface WarningModal {
    title: string;
    message: string;
    btnText: { exit: string; cancel: string };
    shouldShow: boolean;
    handleExit: () => void;
}

interface Props {
    insideAnotherModal?: true;
    parentModalExitCallback?: Function;
}

const ScriptModal = ({
    insideAnotherModal,
    parentModalExitCallback
}: Props) => {
    const {
        scriptView,
        scriptModal: scriptId,
        warning: isWarning
    } = useSelector((state: GlobalState) => state.ui.script);

    const script = useSelector(selectScriptData);
    const campaign = useSelector(selectScriptModalCampaign);
    const isAdmin = useSelector(selectAdminStatus);

    const dispatch = useDispatch();
    const dispatchFetchScript = (scriptId: number) => {
        if (scriptId) {
            dispatch(fetchScript(scriptId));
        }
    };

    const unsavedChangesModal = {
        title: "Unsaved Changes",
        message:
            "This script has unsaved changes. If you leave the page your changes will be lost.",
        btnText: { exit: "Leave Page", cancel: "Stay" }
    };
    const deleteModal = {
        title: "Delete Script",
        message: "Are you sure you want to delete this script?",
        btnText: { exit: "Delete", cancel: "Cancel" }
    };
    const exitModal = () => {
        dispatch(clearModal());
        dispatch(campaignScriptModal(null));
        dispatch(setScriptModalView(ModalView.loading));
        dispatch(setWarning(false));
        setWarningModal({
            ...unsavedChangesModal,
            handleExit: exitModal,
            shouldShow: false
        });

        if (insideAnotherModal && parentModalExitCallback) {
            parentModalExitCallback();
        }
    };

    const backToMain = () => {
        setWarningModal({
            ...unsavedChangesModal,
            handleExit: exitModal,
            shouldShow: false
        });
        dispatch(setWarning(false));
        dispatch(setScriptModalView(ModalView.main));
    };
    const [warningModal, setWarningModal] = useState<WarningModal>({
        ...unsavedChangesModal,
        handleExit: exitModal,
        shouldShow: false
    });

    const onExit = () => {
        const isSelection = window.getSelection();
        if (isSelection && isSelection.isCollapsed) {
            if (isWarning) {
                setWarningModal({
                    ...unsavedChangesModal,
                    handleExit: exitModal,
                    shouldShow: true
                });
            } else {
                exitModal();
            }
        }
    };
    const onBack = () => {
        if (isWarning) {
            setWarningModal({
                ...unsavedChangesModal,
                handleExit: backToMain,
                shouldShow: true
            });
        } else {
            backToMain();
        }
    };
    const onDelete = () => {
        if (script) {
            const handleDelete = () => {
                exitModal();
                dispatch(deleteScript(script));
            };
            setWarningModal({
                ...deleteModal,
                handleExit: handleDelete,
                shouldShow: true
            });
        }
    };
    useEffect(() => {
        if (!campaign && script) {
            /* Modal is reliant on the Media and Asset entities on a campaign.
            When the modal is used when those entities havent been loaded. Fetch that campaigns data */
            dispatch(fetchCampaign(script.campaignId, undefined, script.id));
            dispatch(setScriptModalView(ModalView.main));
        }
        if (campaign && script && scriptId) {
            dispatchFetchScript(scriptId);
            dispatch(setScriptModalView(ModalView.main));
        }
    }, []);

    const processingControl = useProcessing(script);

    if (!script || !campaign) {
        return (
            <LargeModal
                closeModal={() => onExit()}
                title="View Script"
                isBack={false}
                onBack={onBack}
            >
                <ScriptLoading />
            </LargeModal>
        );
    }
    return (
        <LargeModal
            closeModal={() => onExit()}
            title="View Script"
            isBack={scriptView !== ModalView.main}
            onBack={onBack}
            noAnimation={insideAnotherModal}
        >
            {scriptView === ModalView.loading && <ScriptLoading />}
            {scriptView === ModalView.main && (
                <ScriptHome
                    script={script}
                    campaign={campaign}
                    isAdmin={isAdmin}
                    onDelete={onDelete}
                    processingControl={processingControl}
                />
            )}
            {scriptView === ModalView.scriptEdit && (
                <ScriptEdit script={script} campaign={campaign} />
            )}
            {scriptView === ModalView.addAsset && (
                <AddFile
                    title="Upload New Asset"
                    script={script}
                    campaign={campaign}
                    isAsset={true}
                    processingControl={processingControl}
                />
            )}
            {scriptView === ModalView.addMedia && (
                <AddFile
                    title="Upload New Example Media"
                    script={script}
                    campaign={campaign}
                    processingControl={processingControl}
                />
            )}
            {scriptView === ModalView.selectAsset && (
                <SelectAsset campaign={campaign} script={script} />
            )}
            {scriptView === ModalView.selectMedia && (
                <SelectMedia campaign={campaign} script={script} />
            )}
            {scriptView === ModalView.error && <ScriptError />}
            {warningModal.shouldShow && (
                <Warning
                    warningModal={warningModal}
                    handleCancel={() =>
                        setWarningModal({
                            ...warningModal,
                            shouldShow: false
                        })
                    }
                />
            )}
        </LargeModal>
    );
};

export default ScriptModal;
