import { Action } from "src/types";
import {
    REFERRED_USERS_MODAL_OPEN,
    SHARE_REFERRAL_MODAL_OPEN
} from "./actions";

export interface ReferralsUIState {
    referredUsersModalOpen: boolean;
    shareReferralModalOpen: boolean;
}

const referralModalReducerInitialState: ReferralsUIState = {
    referredUsersModalOpen: false,
    shareReferralModalOpen: false
};

function referralsUIReducer(
    state = referralModalReducerInitialState,
    action: Action
) {
    switch (action.type) {
        case REFERRED_USERS_MODAL_OPEN:
            return { ...state, referredUsersModalOpen: action.payload };

        case SHARE_REFERRAL_MODAL_OPEN:
            return { ...state, shareReferralModalOpen: action.payload };

        default:
            return state;
    }
}

export default referralsUIReducer;
