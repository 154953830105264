import { creatorGroupsRoutes } from "./../utils/marketplace-routes";
import { setSocialAccountGroupModalOpen } from "src/ui/social-accounts/actions";
import { Action } from "src/types";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
    createCreatorGroupSuccess,
    CREATE_CREATOR_GROUP,
    deleteCreatorGroupFailure,
    deleteCreatorGroupSuccess,
    DELETE_CREATOR_GROUP,
    fetchCreatorGroupFailure,
    fetchCreatorGroupsFailure,
    fetchCreatorGroupsSuccess,
    FETCH_CREATOR_GROUP,
    FETCH_CREATOR_GROUPS,
    NormalizedCreatorGroupsResponse,
    updateCreatorGroupFailure,
    updateCreatorGroupSuccess,
    UPDATE_CREATOR_GROUP
} from "./actions.creator-groups";
import { deleteReq, get, patch, post } from "src/Api";
import { normalize } from "normalizr";
import { creatorGroupsList } from "./schema";
import { setPillNotificationText } from "src/ui/notifications/actions";
import {
    fetchCreatorInterestsSuccess,
    fetchCreatorThemesSuccess,
    FETCH_CREATOR_INTERESTS,
    FETCH_CREATOR_THEMES,
    setCreatorGroupsLoading,
    setCurrentCreatorGroupId
} from "src/ui/creator-groups/actions";
import { push } from "connected-react-router";
import { setCurrentWizardStep } from "src/ui/create-offer-wizard/actions";
import { errorToString } from "src/util";
import { createOfferWizardRoute } from "../utils/marketplace-routes";
import { GlobalState } from "src/reducers";

function* fetchCreatorGroupsRequest() {
    try {
        yield put(setCreatorGroupsLoading(true));

        const endpoint = "/v1/creatorGroup";
        const response = yield call(get, endpoint, {});

        const normalizedData: NormalizedCreatorGroupsResponse = normalize(
            response.data.data,
            creatorGroupsList
        );

        yield put(fetchCreatorGroupsSuccess(normalizedData));
        yield put(setCreatorGroupsLoading(false));
    } catch (error) {
        console.log(error);
        yield put(
            fetchCreatorGroupsFailure("Failed to retrieve creator groups.")
        );
        yield put(
            setPillNotificationText(
                "Failed to retrieve creator groups.",
                "danger"
            )
        );
    }
}

function* fetchCreatorGroupRequest(action: Action) {
    try {
        const { creatorGroupId } = action.payload;
        const endpoint = `/v1/creatorGroup/${creatorGroupId}`;
        const response = yield call(get, endpoint, {});

        // yield put(fetchCreatorGroupsSuccess(response.data.data));
    } catch (error) {
        console.log(error);
        yield put(
            fetchCreatorGroupFailure("Failed to retrieve creator group.")
        );
        yield put(
            setPillNotificationText(
                "Failed to retrieve creator group.",
                "danger"
            )
        );
    }
}

function* createCreatorGroupRequest(action: Action) {
    try {
        const { creatorGroupData } = action.payload;
        const endpoint = "/v1/creatorGroup";
        const response = yield call(post, endpoint, creatorGroupData);

        yield put(createCreatorGroupSuccess(response.data.data));
        yield put(
            setPillNotificationText("Creator Group Created Successfully")
        );
        yield put(setCurrentCreatorGroupId(response.data.data.id));
        yield put(setCurrentWizardStep("Select a Creator Group"));
        // yield put(push(createOfferWizardRoute));
        yield put(push(creatorGroupsRoutes.main));

        // const { offerCreationModalCompleted } = yield select(
        //     (state: GlobalState) => state.ui.createOfferWizard
        // );
        // if (offerCreationModalCompleted) {
        //     yield put(push("/marketplace/create-new-offer"));
        // } else {
        //     yield put(push("/marketplace/creator-groups"));
        // }
    } catch (error) {
        console.log(error);
        const errorMessage = errorToString(error);
        console.log(errorMessage);
        yield put(fetchCreatorGroupFailure(errorMessage));
        yield put(setPillNotificationText(errorMessage, "danger"));
    }
}

function* updateCreatorGroupRequest(action: Action) {
    try {
        const { creatorGroupId, creatorGroupData, noRedirect } = action.payload;
        const endpoint = `/v1/creatorGroup/${creatorGroupId}`;
        const response = yield call(patch, endpoint, creatorGroupData);

        yield put(updateCreatorGroupSuccess(response.data.data));
        yield put(
            setPillNotificationText("Creator Group Updated Successfully")
        );
        yield put(setSocialAccountGroupModalOpen(false));
        if (!noRedirect) {
            yield put(setCurrentWizardStep("Select a Creator Group"));
            // yield put(push(createOfferWizardRoute));
            yield put(push(creatorGroupsRoutes.main));
        }

        // const { offerCreationModalCompleted } = yield select(
        //     (state: GlobalState) => state.ui.createOfferWizard
        // );
        // if (offerCreationModalCompleted && !noRedirect) {
        //     yield put(setCurrentWizardStep("Select a Creator Group"));
        //     yield put(push("/marketplace/create-new-offer"));
        // } else {
        //     yield put(push("/marketplace/creator-groups"));
        // }
    } catch (error) {
        console.log(error);
        const errorMessage = errorToString(error);
        console.log(errorMessage);
        yield put(updateCreatorGroupFailure(errorMessage));
        const { creatorGroupData } = action.payload;
        yield put(
            setPillNotificationText(
                creatorGroupData.socialAccountIds.length === 0
                    ? "You must have at least one creator in this group!"
                    : errorMessage,
                "danger"
            )
        );
    }
}

function* deleteCreatorGroupRequest(action: Action) {
    try {
        const { creatorGroupId } = action.payload;
        const endpoint = `/v1/creatorGroup/${creatorGroupId}`;
        const response = yield call(deleteReq, endpoint, {});

        yield put(deleteCreatorGroupSuccess(creatorGroupId));
        yield put(setCurrentWizardStep("Select a Creator Group"));
        // yield put(push(createOfferWizardRoute));
        yield put(push(creatorGroupsRoutes.main));

        // const { offerCreationModalCompleted } = yield select(
        //     (state: GlobalState) => state.ui.createOfferWizard
        // );
        // if (offerCreationModalCompleted) {
        //     yield put(push("/marketplace/create-new-offer"));
        // } else {
        //     yield put(push("/marketplace/creator-groups"));
        // }

        yield put(
            setPillNotificationText("Creator Group Deleted Successfully")
        );
    } catch (error) {
        console.log(error);
        const errorMessage = errorToString(error);
        console.log(errorMessage);
        yield put(deleteCreatorGroupFailure(errorMessage));
        yield put(setPillNotificationText(errorMessage, "danger"));
    }
}

function* fetchCreatorInterestsRequest() {
    try {
        const endpoint = "/v1/socialAccount/interests";
        const response = yield call(get, endpoint, {});

        yield put(fetchCreatorInterestsSuccess(response.data.data));
    } catch (error) {
        console.log(errorToString(error));
        setPillNotificationText("Failed to fetch creator interests.", "danger");
    }
}

function* fetchCreatorThemesRequest() {
    try {
        const endpoint = "/v1/socialAccount/themes";
        const response = yield call(get, endpoint, {});

        yield put(fetchCreatorThemesSuccess(response.data.data));
    } catch (error) {
        console.log(errorToString(error));
        setPillNotificationText("Failed to fetch creator themes.", "danger");
    }
}

export default function* creatorGroupsSaga() {
    yield all([
        takeEvery(FETCH_CREATOR_GROUPS.REQUEST, fetchCreatorGroupsRequest),
        takeEvery(FETCH_CREATOR_GROUP.REQUEST, fetchCreatorGroupRequest),
        takeEvery(CREATE_CREATOR_GROUP.REQUEST, createCreatorGroupRequest),
        takeEvery(UPDATE_CREATOR_GROUP.REQUEST, updateCreatorGroupRequest),
        takeEvery(DELETE_CREATOR_GROUP.REQUEST, deleteCreatorGroupRequest),
        takeEvery(
            FETCH_CREATOR_INTERESTS.REQUEST,
            fetchCreatorInterestsRequest
        ),
        takeEvery(FETCH_CREATOR_THEMES.REQUEST, fetchCreatorThemesRequest)
    ]);
}
