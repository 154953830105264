import { Dispatch } from "redux";
import {
    setAudienceAgeFilters,
    setAudienceCountryRegionFilters,
    setAudienceDeviceFilter,
    setAudienceGenderFilter,
    setAverageViewsFilter,
    setInterestsFilters,
    setCreatorCountryRegionFilters,
    setEngagementRateFilter,
    setFollowersFilter,
    setPlatformFilters,
    setThemesFilters
} from "src/ui/creators/actions";
import { CreatorsSort } from "../pages/CreatorGroupsCreateOrEditPage";
import { CreatorDateRange, FilterType } from "./types";

export function generateCreatorSearchData(
    hitsPerPage: number,
    page: number,
    query: string,
    date: CreatorDateRange,
    filter: any[],
    sort: CreatorsSort[]
) {
    return {
        query: [
            {
                index: "creator-search",
                params: {
                    hitsPerPage: hitsPerPage,
                    page,
                    query,
                    date,
                    filter,
                    sort
                }
            }
        ]
    };
}

// tiktok: {
//     earningPerformance: ["solid", "strong", "high", "top"]
// },
// snapchat: {
//     dollarsPerK: {
//         lte: 2.0,
//         gte: 1.0
//     }
// },
// instagram: {
//     earnings: {
//         lte: 200,
//         gte: 100
//     }
// },

// sort: {
//     type: sortOption,
//     order: sortOrder
// }

// "filter": [
//     { "terms": { "platform": ["tiktok"] }},
//     { "range": { "followers": { "gte": 100000, "lte": 1000000 }}},
//     { "range": { "avg_views": { "gte": 500000, "lte": 1000000 }}},
//     { "range": { "engagement_rate": { "gte": 0.25 }}},
//     { "terms": { "interests": ["Gaming", "Television & Film"] }},
//     { "term": { "country_code": "US" }},
//     { "term": { "audience_country_code": "US" }},
//     { "term": { "audience_age_code": "18-24" }},
//     { "term": { "audience_gender_code": "MALE" }}
// ]

export function selectCategoryFilterIcon(category: string): string {
    if (category === "Fashion") return "👗";
    else if (category === "Tech") return "📱";
    else if (category === "Beauty") return "💄";
    else if (category === "Dance") return "💃";
    else if (category === "Health") return "💪🏻";
    else if (category === "Gaming") return "🕹";
    else if (category === "Family") return "👶";
    else return "🤣"; // Meme
}

export function convertShortenedNumberStringIntoExpandedNumber(
    shortenedNumberString: string
): number {
    if (shortenedNumberString === "0") {
        return 0;
    } else {
        const lastIndex = shortenedNumberString.length - 1;
        const lastChar = shortenedNumberString[lastIndex];
        const baseNumber = Number(shortenedNumberString.slice(0, lastIndex));
        if (lastChar.toLowerCase() === "k") {
            return baseNumber * 1000;
        } else {
            return baseNumber * 1000000;
        }
    }
}

export function convertStringRangeIntoNumberRange(stringRange: string) {
    if (stringRange.toLowerCase() === "all") {
        return { gte: 0, lte: 99999999999999 };
    } else {
        const range = stringRange.split("-");
        return {
            gte: convertShortenedNumberStringIntoExpandedNumber(range[0]),
            lte: convertShortenedNumberStringIntoExpandedNumber(range[1])
        };
    }
}

export function convertPercentRangeIntoNumberRange(percentRange: string) {
    if (percentRange.toLowerCase() !== "all") {
        const range = percentRange.split("-");
        return {
            gte: Number(range[0]) / 100,
            lte: Number(range[1].slice(0, -1)) / 100
        };
    }
}

export function checkSelected(
    filterType: FilterType,
    dropdownOptionTitle: string,
    filter: any
): boolean {
    switch (filterType) {
        case "Platform":
            return !!filter[dropdownOptionTitle];
        case "Interests":
            return !!filter[dropdownOptionTitle];
        case "Themes":
            return !!filter[dropdownOptionTitle]
        case "Creator Country/Region":
            return !!filter[dropdownOptionTitle];
        case "Audience Country/Region":
            return !!filter[dropdownOptionTitle];
        case "Followers":
            return filter === dropdownOptionTitle;
        case "Average Views":
            return filter === dropdownOptionTitle;
        case "Engagement Rate":
            return filter === dropdownOptionTitle;
        case "Audience Gender":
            return filter === dropdownOptionTitle;
        case "Audience Age":
            return !!filter[dropdownOptionTitle];
        default:
            // Audience Device
            return filter === dropdownOptionTitle;
    }
}

export function toggleFilterSelection(
    filterType: FilterType,
    dropdownOptionTitle: string,
    dispatch: Dispatch<any>,
    filter: any,
    country?: string
): void {
    const newFilters = { ...filter };

    if (newFilters[dropdownOptionTitle]) {
        delete newFilters[dropdownOptionTitle];
    } else {
        if (country) {
            newFilters[dropdownOptionTitle] = country;
        } else {
            newFilters[dropdownOptionTitle] = dropdownOptionTitle;
        }
    }

    if (filterType === "Platform") {
        dispatch(setPlatformFilters(newFilters));
    } else if (filterType === "Interests") {
        dispatch(setInterestsFilters(newFilters));
    } else if (filterType === "Themes") {
        dispatch(setThemesFilters(newFilters));
    } else if (filterType === "Creator Country/Region") {
        dispatch(setCreatorCountryRegionFilters(newFilters));
    } else if (filterType === "Audience Country/Region") {
        dispatch(setAudienceCountryRegionFilters(newFilters));
    } else {
        dispatch(setAudienceAgeFilters(newFilters));
    }
}

export function setSingleSelectionFilter(
    filterType: FilterType,
    dropdownOptionTitle: string,
    dispatch: Dispatch<any>
): void {
    if (filterType === "Followers") {
        dispatch(setFollowersFilter(dropdownOptionTitle));
    } else if (filterType === "Average Views") {
        dispatch(setAverageViewsFilter(dropdownOptionTitle));
    } else if (filterType === "Engagement Rate") {
        dispatch(setEngagementRateFilter(dropdownOptionTitle));
    } else if (filterType === "Audience Gender") {
        dispatch(setAudienceGenderFilter(dropdownOptionTitle));
    } else {
        dispatch(setAudienceDeviceFilter(dropdownOptionTitle));
    }
}
