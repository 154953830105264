import { RESET_INSTRUCTION_SETS } from "./../../marketplace/instructions-sets/actions.instructions-sets";
import { NormalizedAction } from "./../../types";
import { FETCH_SCRIPT_ASSETS } from "./../../campaigns/assets/actions";
import { FETCH_SCRIPT_MEDIAS } from "./../../campaigns/medias/actions";
import { Action } from "src/types";
import { InstructionElementType } from "src/marketplace/utils/types";
import {
    SET_CURRENT_INSTRUCTIONS_SET_ID,
    SET_CURRENT_INSTRUCTIONS_ELEMENT,
    SET_INSTRUCTIONS_SETS_ERROR,
    SET_CURRENT_INSTRUCTION_STEP_ID,
    SET_INSTRUCTIONS_STEPS_LOADING,
    SET_INSTRUCTION_STEPS,
    SET_AN_INSTRUCTION_STEP_HAS_MOVED,
    InstructionStepState,
    SET_IS_CURRENT_DRAG_TARGET_AN_INSTRUCTION_ELEMENT,
    SET_CURRENT_DRAG_TARGET_TYPE,
    SET_INSTRUCTIONS_PAGE_STEP,
    OPEN_INSTRUCTION_ELEMENT_CAROUSEL_MODAL,
    SET_INSTRUCTION_ELEMENT_CAROUSEL_ITEM_IDS,
    OPEN_INSTRUCTIONS_CAMPAIGN_SELECTION_MODAL,
    SET_INSTRUCTIONS_SET_SUBMIT_STATUS,
    SET_INSTRUCTIONS_PAGE_TYPE,
    RESET_INSTRUCTIONS_SET_ON_CAMPAIGN_SELECTION,
    SET_INSTRUCTIONS_SET_DELETION_STATUS,
    SET_INSTRUCTIONS_STEP_DELETION_INDEX,
    RESET_INSTRUCTIONS_SET_WHEN_NAVIGATING_TO_MAIN_PAGE,
    SET_INSTRUCTIONS_UNSAVED,
    SET_INSTRUCTIONS_SETS_LOADING,
    SET_CREATING_INSTRUCTION,
    defaultInstructionStepState
} from "./actions";
import {
    FETCH_INSTRUCTIONS_SET,
    FETCH_INSTRUCTIONS_SETS
} from "src/marketplace/instructions-sets/actions.instructions-sets";
import { CREATE_INSTRUCTION_STEP } from "src/marketplace/instructions-sets/actions.instruction-steps";

export type PageType = "Main" | "Edit" | "Create";

export type PageStep = "Selection" | "Scratch";

export type StepDeletionStatus =
    | "not deleting"
    | "prepping for deletion"
    | "deleting";

export type SubmissionStatus =
    | "not submitting"
    | "updating data for adding new instruction"
    | "finish updating data for adding new instruction"
    | "updating data to send to api"
    | "submitting to api";

export interface InstructionsSetsUIState {
    instructionsCampaignSelectionModalOpen: boolean;
    instructionsPageType: PageType;
    instructionsPageStep: PageStep;
    instructionsSetsLoading: boolean;
    instructionsSetLoading: boolean;
    instructionsStepsLoading: boolean;
    currentInstructionsSetId: number | null;
    currentInstructionStepId: number | null;
    currentInstructionElement: InstructionElementType | "";
    dropdownSearchInput: string;
    currentDragTargetType: string;
    isCurrentDragTargetAnInstructionElement: boolean;
    instructionSteps: InstructionStepState[];
    anInstructionStepHasMoved: boolean;
    instructionElementModalOpen: boolean;
    instructionElementModalItemIds: number[];
    isErrors: boolean;
    instructionStepDeletionStatus: StepDeletionStatus;
    instructionStepDeletionIndex: number | null;
    instructionsSetSubmitStatus: SubmissionStatus;
    instructionsHasUnsaved: boolean;
    creatingInstruction: boolean;
}

const initialInstructionsSetsUIState: InstructionsSetsUIState = {
    instructionsCampaignSelectionModalOpen: false,
    instructionsPageType: "Main",
    instructionsPageStep: "Selection",
    instructionsSetsLoading: true,
    instructionsSetLoading: true,
    instructionsStepsLoading: true,
    currentInstructionsSetId: null,
    currentInstructionStepId: null,
    currentInstructionElement: "",
    currentDragTargetType: "",
    isCurrentDragTargetAnInstructionElement: false,
    dropdownSearchInput: "",
    instructionSteps: [defaultInstructionStepState()],
    anInstructionStepHasMoved: false,
    instructionElementModalOpen: false,
    instructionElementModalItemIds: [] as number[],
    isErrors: true,
    instructionStepDeletionStatus: "not deleting",
    instructionStepDeletionIndex: null,
    instructionsSetSubmitStatus: "not submitting",
    instructionsHasUnsaved: false,
    creatingInstruction: false
};

interface ActionOrNormalizedAction {
    type: string;
    payload?: any;
    response?: any;
}

function instructionsSetsUIReducer(
    state = initialInstructionsSetsUIState,
    action: ActionOrNormalizedAction
): InstructionsSetsUIState {
    switch (action.type) {
        case OPEN_INSTRUCTIONS_CAMPAIGN_SELECTION_MODAL:
            return {
                ...state,
                instructionsCampaignSelectionModalOpen: action.payload
            };

        case SET_INSTRUCTIONS_PAGE_TYPE:
            return { ...state, instructionsPageType: action.payload };

        case SET_INSTRUCTIONS_PAGE_STEP:
            return { ...state, instructionsPageStep: action.payload };

        case FETCH_INSTRUCTIONS_SETS.SUCCESS:
            return { ...state, instructionsSetsLoading: false };

        case FETCH_INSTRUCTIONS_SET.SUCCESS:
            return { ...state, instructionsSetLoading: false };

        case CREATE_INSTRUCTION_STEP.SUCCESS:
            const { newInstructionStepId, instructionIndex } = action.payload;
            const updatedInstructionSteps = Array.from(state.instructionSteps);
            if (!!instructionIndex) {
                updatedInstructionSteps[
                    instructionIndex
                ].id = newInstructionStepId;
            }
            return { ...state, instructionSteps: updatedInstructionSteps };

        case SET_INSTRUCTIONS_SETS_LOADING:
            return { ...state, instructionsSetsLoading: action.payload };

        case SET_INSTRUCTIONS_STEPS_LOADING:
            return { ...state, instructionsStepsLoading: action.payload };

        case SET_CURRENT_INSTRUCTIONS_SET_ID:
            return { ...state, currentInstructionsSetId: action.payload };

        case SET_CURRENT_INSTRUCTION_STEP_ID:
            return { ...state, currentInstructionStepId: action.payload };

        case SET_CURRENT_INSTRUCTIONS_ELEMENT:
            return { ...state, currentInstructionElement: action.payload };

        case SET_CURRENT_DRAG_TARGET_TYPE:
            return { ...state, currentDragTargetType: action.payload };

        case SET_IS_CURRENT_DRAG_TARGET_AN_INSTRUCTION_ELEMENT:
            return {
                ...state,
                isCurrentDragTargetAnInstructionElement: action.payload
            };

        case SET_INSTRUCTION_STEPS:
            return { ...state, instructionSteps: action.payload };

        case SET_AN_INSTRUCTION_STEP_HAS_MOVED:
            return { ...state, anInstructionStepHasMoved: action.payload };

        case OPEN_INSTRUCTION_ELEMENT_CAROUSEL_MODAL:
            return { ...state, instructionElementModalOpen: action.payload };

        case SET_INSTRUCTION_ELEMENT_CAROUSEL_ITEM_IDS:
            return { ...state, instructionElementModalItemIds: action.payload };

        case SET_INSTRUCTIONS_SETS_ERROR:
            return { ...state, isErrors: action.payload };

        case SET_INSTRUCTIONS_SET_DELETION_STATUS:
            return { ...state, instructionStepDeletionStatus: action.payload };

        case SET_INSTRUCTIONS_STEP_DELETION_INDEX:
            return { ...state, instructionStepDeletionIndex: action.payload };

        case SET_INSTRUCTIONS_SET_SUBMIT_STATUS:
            return { ...state, instructionsSetSubmitStatus: action.payload };

        case RESET_INSTRUCTIONS_SET_ON_CAMPAIGN_SELECTION:
            return {
                ...state,
                instructionsPageStep:
                    state.instructionsPageType === "Edit"
                        ? "Selection"
                        : state.instructionsPageStep,
                instructionsCampaignSelectionModalOpen: false,
                instructionSteps: [defaultInstructionStepState()]
            };

        case RESET_INSTRUCTIONS_SET_WHEN_NAVIGATING_TO_MAIN_PAGE:
            return {
                ...state,
                instructionsPageType: "Main",
                instructionsPageStep: "Scratch",
                currentInstructionsSetId: null,
                currentInstructionElement: ""
            };

        case RESET_INSTRUCTION_SETS:
            return {
                ...state,
                instructionsSetsLoading: true
            };

        case SET_INSTRUCTIONS_UNSAVED:
            return { ...state, instructionsHasUnsaved: action.payload.status };

        case SET_CREATING_INSTRUCTION:
            return { ...state, creatingInstruction: action.payload.creating };

        default:
            return state;
    }
}

export default instructionsSetsUIReducer;
