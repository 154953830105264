import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { breakpoints, colors } from "src/constants";
import { OutlineButton } from "src/profile/components/Buttons";
import { LinkListItem } from "src/profile/types";
import styled from "styled-components";
import LinkListNewLinkSelector from "./LinkListNewLinkSelector";
import { Header } from "src/components/text/CommonStyles";
import Subtitle from "src/components/text/Subtitle";
import Title from "src/components/text/Title";
import LinkListItemCell from "./LinkListItemCell";
import { GlobalState } from "src/reducers";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { editLinkListItem } from "../actions";
import { push } from "connected-react-router";
import { setLinkListType } from "src/ui/link-lists/actions";
import { getLinkListItems } from "../selectors";
import { useEffect } from "react";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${colors.mediumTeal};
    width: 100%;
    margin-top: 30px;
    padding: 10px 18px;
    margin-bottom: 20px;
    & > * ~ * {
        margin-top: 10px;
    }
`;

const ListContainer = styled.div<{ length: number }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: ${props => props.length * 90}px;
    & > * ~ * {
        margin-top: 10px;
    }
    @media (max-width: ${breakpoints.small}px) {
        min-height: ${props => props.length * 122}px;
    }
`;

const Button = styled(OutlineButton)`
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    margin: auto;
`;

interface Props {
    list: string;
}
const LinkListItemList = ({ list }: Props) => {
    const { links, ids } = useSelector((state: GlobalState) =>
        getLinkListItems(state, list)
    );
    const isMobile = useSelector((state: GlobalState) => {
        return state.ui.observer.isDeviceMobile;
    });
    const dispatch = useDispatch();
    const [add, setAdd] = useState<LinkListItem | null>(null);
    const [cachedLinks, setCachedLinks] = useState(links);

    const newItem = {
        linkListId: list,
        position: links.length - 1,
        type: "",
        url: "",
        disabled: false,
        title: "",
        photoUrl: "",
        campaignId: "",
        id: ""
    };

    const getSubtitle = () => {
        const active = links.filter((item: LinkListItem) => !item.disabled);
        return (
            `${active.length} Active` +
            (active.length !== links.length
                ? `; ${links.length - active.length} Inactive`
                : "")
        );
    };

    const onDragStart = () => {
        if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
        }
    };

    useEffect(() => {
        //check if links has changed order or disable state
        if (links.length === cachedLinks.length) {
            let diff = false;
            links.map((link, index) => {
                if (link.id !== cachedLinks[index].id) diff = true;
                if (link.disabled !== cachedLinks[index].disabled) diff = true;
            });
            if (diff) setCachedLinks(links);
        }
    }, [links]);

    const onDrop = (result: DropResult) => {
        if (result.destination?.index !== undefined) {
            let oldIndex = ids.indexOf(parseInt(result.draggableId));
            let newIds = ids;
            let newCached = cachedLinks;
            let temp = newCached[oldIndex];
            newCached.splice(oldIndex, 1);
            newCached.splice(result.destination?.index, 0, temp);
            newIds.splice(oldIndex, 1);
            newIds.splice(
                result.destination?.index,
                0,
                parseInt(result.draggableId)
            );

            dispatch(
                editLinkListItem({
                    position: result.destination?.index + 1,
                    id: result.draggableId,
                    linkListId: list,
                    linkItemIds: newIds
                })
            );
            setCachedLinks(newCached);
        }
    };

    return (
        <>
            <Container>
                <Header>
                    <Title>Links</Title>
                    <Subtitle>{getSubtitle()}</Subtitle>
                </Header>
                <Button onClick={() => setAdd(newItem)}>Add Link</Button>
                <DragDropContext onDragEnd={onDrop} onDragStart={onDragStart}>
                    <Droppable droppableId="linkList">
                        {(provided, snapshot) => (
                            <ListContainer
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                length={links.length}
                            >
                                {isMobile && (
                                    <Subtitle>
                                        Long Press to reposition links
                                    </Subtitle>
                                )}
                                {cachedLinks.map((link, index) => (
                                    <LinkListItemCell
                                        index={index}
                                        link={link}
                                        key={link.id}
                                        onEdit={() =>
                                            dispatch(
                                                push(`${list}/link/${link.id}`)
                                            )
                                        }
                                    />
                                ))}
                            </ListContainer>
                        )}
                    </Droppable>
                </DragDropContext>
            </Container>
            <LinkListNewLinkSelector
                open={!!add}
                onSelect={selection => {
                    dispatch(setLinkListType(selection));
                    dispatch(push(`${list}/link/`));
                }}
                onClose={() => setAdd(null)}
            />
        </>
    );
};

export default LinkListItemList;
