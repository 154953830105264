import React from "react";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import LockCard from "../components/LockCard";
import PaddingAround from "../../components/PaddingAround";

interface Props {
    goBack: VoidFunction;
}

const NotFound = ({ goBack }: Props) => {
    return (
        <>
            <SimpleNavbar left={<NavButton back onClick={goBack} />} />
            <PaddingAround>
                <LockCard text={"Sorry, no user found"} />
            </PaddingAround>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    goBack: () => dispatch(goBack())
});

export default connect(null, mapDispatchToProps)(NotFound);
