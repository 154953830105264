import { breakpoints } from "src/constants";
import { flex } from "src/utils/styles/snippets";
import styled from "styled-components";

const ChannelPreviewWrapper = styled.div`
    ${flex("column")};
    gap: 10px;
    padding: 0px 15px;

    @media (max-width: ${breakpoints.mobile}px) {
        gap: ${props => props.theme.isMobile && "0px"};
        padding: ${props => props.theme.isMobile && "0px"};
    }
`;

export default ChannelPreviewWrapper;
