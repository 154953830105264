import React from "react";
import { Campaign } from "src/campaigns/types";
import styled from "styled-components";
import GenericContainer from "../../components/GenericContainer";
import LargeRoundedIcon from "../../components/LargeRoundedIcon";
import CoinReward from "../../../components/CoinReward";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { colors } from "src/constants";

const Main = styled(GenericContainer)`
    display: flex;
    align-items: center;
    text-align: left;
    background-color: ${colors.white};
    transition: 0.1s ease-in;
    cursor: pointer;

    & + & {
        margin-top: 10px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
            border: ${colors.darkTeal} solid 1px;
        }
    }
`;

const InfoContainer = styled.div`
    margin-bottom: 4px;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
`;

interface Props {
    campaign: any;
    push: (path: string) => void;
}

const CampaignCell = ({ campaign, push }: Props) => {
    return (
        <Main onClick={() => push(`/campaign/${campaign.id}`)}>
            <LargeRoundedIcon src={campaign.image_url} />
            <InfoContainer>
                <Title>{campaign.campaign_name}</Title>
                {campaign.country_platform_reward && (
                    <CoinReward
                        reward={campaign.country_platform_reward.reward}
                        type={campaign.reward_type}
                        size={16}
                    />
                )}
            </InfoContainer>
        </Main>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    push: (path: string) => dispatch(push(path))
});

export default connect(null, mapDispatchToProps)(CampaignCell);
