import React from "react";
import { useSelector } from "react-redux";
import { getAuthState } from "src/auth/selectors";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import ProfilePhoto from "src/components/ProfilePhoto";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import { selectProfilePhoto } from "../selectors";
import { Message } from "../types/Message";
import MessageAsset from "./MessageAsset";

const Row = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;

const Main = styled.div`
    padding: 12px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
`;

const Self = styled(Main)`
    background-color: ${colors.primaryBlue};
    color: ${colors.white};
`;

const SelfWrapper = styled.div`
    justify-self: end;
    margin-left: 20%;
`;

const Other = styled(Main)`
    border: 1px solid ${colors.mediumTeal};
    background-color: ${colors.white};
`;

const AvatarOffset = styled.div<{ showAvatar: boolean }>`
    margin-left: ${props => (props.showAvatar ? "9px" : "37px")};
`;

const OtherUsername = styled.div`
    margin-left: 37px;
    font-size: 10px;
    line-height: 11.93px;
    text-align: left;
    color: ${colors.primaryGray};
`;

const OtherWrapper = styled.div<{ showAvatar?: boolean }>`
    display: flex;
    align-items: flex-end;
    align-self: flex-start;
    margin-right: 20%;
    margin-bottom: ${props => props.showAvatar && "8px"};
`;

interface Props {
    message: Message;
    showAvatar: boolean;
    showUsername: boolean;
}

const MessageCell = ({ message, showAvatar, showUsername }: Props) => {
    const { userId } = useSelector(getAuthState);
    const { avatarUrl, username } = useSelector((state: GlobalState) =>
        selectProfilePhoto(state, message.authorId)
    );

    if (`${userId}` === message.authorId)
        return (
            <Row>
                <SelfWrapper>
                    <MessageAsset asset={message.asset} />
                    {message.messageText && <Self>{message.messageText}</Self>}
                </SelfWrapper>
            </Row>
        );

    return (
        <>
            {showUsername && (
                <OtherWrapper>
                    <OtherUsername>{username}</OtherUsername>
                </OtherWrapper>
            )}

            <OtherWrapper showAvatar={showAvatar}>
                {showAvatar && (
                    <ProfilePhoto profilePhotoUrl={avatarUrl} photoSize={28} />
                )}
                <AvatarOffset showAvatar={showAvatar}>
                    <MessageAsset asset={message.asset} />
                    {message.messageText && (
                        <Other>{message.messageText}</Other>
                    )}
                </AvatarOffset>
            </OtherWrapper>
        </>
    );
};

export default MessageCell;
