import { NormalizedActionGeneric } from "src/types";
import { Message } from "../types/Message";
import { MESSAGE_CHANNEL } from "./actions";

export interface MessagesEntitiesState {
    [key: string]: Message;
}

const initialMessagesEntitiesState: MessagesEntitiesState = {};

const messagesEntitiesReducer = (
    state = initialMessagesEntitiesState,
    action: NormalizedActionGeneric<Message, string[]>
) => {
    switch (action.type) {
        case MESSAGE_CHANNEL.UPDATE:
            return { ...state, ...action.response.entities.messages };
        default:
            return state;
    }
};

export default messagesEntitiesReducer;
