import styled from "styled-components";
import { colors } from "../../constants";

export default styled.div`
    font-weight: bold;
    font-size: 12px;
    color: ${colors.primaryGray};
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: left;
`;
