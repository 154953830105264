import React from "react";
import SimpleNavbar from "src/components/SimpleNavbar";
import NavButton from "src/components/NavButton";
import { useDispatch, useSelector } from "react-redux";
import { goBack } from "connected-react-router";
import SearchBar from "src/components/SearchBar";
import { useState } from "react";
import styled from "styled-components";
import SearchTag from "src/components/SearchTag";
import { clearSelectMedia, fetchSearchMedia } from "../actions";
import MediaFilterModal from "./MediaFilterModal";
import { AnimatePresence } from "framer-motion";
import DynamicMediaCard from "../view/DynamicMediaCard";
import { getPaginatedSearchMedia } from "./selectors";
import { setCurrentModalMedias } from "src/ui/medias/actions";
import { GlobalState } from "src/reducers";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Shimmer } from "src/components/SkeletonLoader";
import { fetchCampaigns } from "src/campaigns/actions";
import MediaSortModal from "./MediaSortModal";
import MediaSearchHeader from "./header";
import XCard from "src/profile/components/XCard";
import { isScriptModalOpen } from "src/campaigns/scripts/selectors";
import SelectFooter from "../../campaigns/components/SelectFooter";
import { breakpoints } from "src/constants";
import MainContainer from "src/components/MainContainer";

const Main = styled(MainContainer)<{ isScriptModal?: boolean }>`
    padding: 0 18px 10px;
    position: relative;
    height: ${props => (props.isScriptModal ? "auto" : "initial")};
    min-height: ${props => (props.isScriptModal ? "100%" : "none")};
    max-width: ${props => props.isScriptModal && "100%"};
`;

const List = styled.div<{ isScriptModal?: boolean }>`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 13px;
    ${props => {
        if (props.isScriptModal) {
            return `
                grid-template-columns: repeat(7, 1fr);
                width: 100%;
                padding-top: 10px;
                @media (max-width: ${breakpoints.tablet}px) {
                    grid-template-columns: repeat(5, 1fr);
                }
                @media (max-width: ${breakpoints.small}px) {
                    grid-template-columns: repeat(3, 1fr);
                }
                @media (max-width: ${breakpoints.mobile}px) {
                    grid-template-columns: repeat(2, 1fr);
                }
            `;
        }
    }}

    @media (max-width: ${breakpoints.mobile}px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const MediaCardWrapper = styled.div`
    ${Shimmer}
    border-radius: 6px;
    position: relative;

    &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(16 / 9 * 100%);
    }
`;

const Observer = styled.div`
    position: absolute;
    bottom: min(400px, 100%);
`;

const MediaSearch = ({
    isSearchable,
    isMultiSelect,
    handleSubmit
}: {
    isSearchable?: boolean;
    isMultiSelect?: boolean;
    handleSubmit?: () => void;
}) => {
    const promoting = useSelector(
        (state: GlobalState) => state.ui.promote.promoting
    );
    const isScriptModal = useSelector(isScriptModalOpen);

    const dispatch = useDispatch();
    const [ref, inView] = useInView();

    const [filterModal, toggleFilterModal] = useState(false);
    const [sortModal, toggleSortModal] = useState(false);
    const campaignsReady = useSelector(
        (state: GlobalState) => state.entities.campaigns.allIds.length !== 0
    );

    const mediaIds = useSelector(getPaginatedSearchMedia);
    const placementsLoading = useSelector(
        (state: GlobalState) => state.ui.placements.placementsLoading
    );
    const hasMore = useSelector(
        (state: GlobalState) => state.pagination.medias.hasMore
    );
    const currentPage = useSelector(
        (state: GlobalState) => state.pagination.medias.currentPage
    );
    const currentPageLoading = useSelector(
        (state: GlobalState) =>
            state.pagination.medias.pages[state.pagination.medias.currentPage]
                ?.isFetching
    );
    const searchString = useSelector(
        (state: GlobalState) => state.pagination.medias.searchString
    );
    const selectedMedias = useSelector(
        (state: GlobalState) => state.media.selectedMedias
    );
    const isSearchNaNAndNotEmpty =
        searchString.length > 0 && !/^\d+$/.test(searchString);

    useEffect(() => {
        !campaignsReady && dispatch(fetchCampaigns());
    }, []);

    useEffect(() => {
        const inViewOrFirstPage = inView || currentPage === 0;
        if (
            !placementsLoading &&
            inViewOrFirstPage &&
            hasMore &&
            !currentPageLoading
        ) {
            dispatch(fetchSearchMedia());
        }
    }, [placementsLoading, hasMore, inView, currentPageLoading]);

    function mediaClicked(index: number) {
        const newList = [
            ...mediaIds.slice(index, mediaIds.length),
            ...mediaIds.slice(0, index)
        ];

        dispatch(setCurrentModalMedias(newList));
    }
    useEffect(() => {
        return () => {
            dispatch(clearSelectMedia());
        };
    }, []);

    return (
        <>
            {!promoting && !isScriptModal && (
                <SimpleNavbar
                    title="ALL MEDIA"
                    left={<NavButton back onClick={() => dispatch(goBack())} />}
                />
            )}
            <MediaSearchHeader
                isSearchable={isSearchable}
                sortModalOpen={() => toggleSortModal(true)}
                filterModalOpen={() => toggleFilterModal(true)}
            />
            <Main isScriptModal={isScriptModal}>
                <List isScriptModal={isScriptModal}>
                    {!isSearchNaNAndNotEmpty &&
                        mediaIds.map((mediaId, index) => (
                            <MediaCardWrapper key={mediaId}>
                                <DynamicMediaCard
                                    mediaId={mediaId}
                                    mediaCallback={() => mediaClicked(index)}
                                    isMultiSelect={isMultiSelect}
                                />
                            </MediaCardWrapper>
                        ))}
                    {(!campaignsReady || currentPageLoading) &&
                        searchString.length === 0 &&
                        Array.from({ length: 10 }, (_, idx) => (
                            <MediaCardWrapper key={`loading-${idx}`} />
                        ))}
                    {(!campaignsReady || currentPageLoading) &&
                        searchString.length > 0 &&
                        !isSearchNaNAndNotEmpty && <MediaCardWrapper />}
                </List>
                {campaignsReady &&
                    !currentPageLoading &&
                    mediaIds.length === 0 &&
                    searchString.length === 0 && (
                        <XCard
                            text="There are no media that match your filter selection."
                            button={{
                                text: "Update",
                                callback: () => toggleFilterModal(true)
                            }}
                        />
                    )}
                {(isSearchNaNAndNotEmpty && (
                    <XCard text={"Search may only contain numbers."} />
                )) ||
                    (campaignsReady &&
                        !currentPageLoading &&
                        searchString.length > 0 &&
                        mediaIds.length === 0 && (
                            <XCard
                                text={
                                    "There are no medias that match your search."
                                }
                            />
                        ))}
                <Observer ref={ref} />
            </Main>
            {isMultiSelect && handleSubmit && (
                <SelectFooter
                    handleSubmit={handleSubmit}
                    label="medias"
                    selectedIds={selectedMedias}
                />
            )}
            <AnimatePresence>
                {filterModal && (
                    <MediaFilterModal
                        closeModal={() => toggleFilterModal(false)}
                    />
                )}
                {sortModal && (
                    <MediaSortModal closeModal={() => toggleSortModal(false)} />
                )}
            </AnimatePresence>
        </>
    );
};

export default MediaSearch;
