import React from "react";
import { colors } from "../constants";
// @ts-ignore
import PulseLoader from "react-spinners/PulseLoader";

const PulseLoaderWrapper = ({
    color,
    size
}: {
    color?: string;
    size?: number;
}) => {
    return (
        <PulseLoader color={color || colors.primaryGreen} size={size || 30} />
    );
};

export default PulseLoaderWrapper;
