import React from "react";
import { DirectBuyStepCollection } from "../../../modules/direct-buy-step/types";
import { DirectBuyStepsEntitiesState } from "../../../modules/direct-buy-step/reducer";
import { ResourceContainer, ResourceDescription } from "../styledComponents";
import CopyToClipboard from "react-copy-to-clipboard";
import styled from "styled-components";
import { colors } from "../../../../constants";
import { BorderlessButton } from "../../../../profile/components/Buttons";
import { addNotification } from "../../../../ui/notifications/actions";
import { useDispatch } from "react-redux";
import { StepById } from "src/buys/modules/open-buys/reducer";

const CaptionContainer = styled.div`
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    background: white;
    & + & {
        margin-top: 10px;
    }
`;

const CaptionText = styled.div`
    padding: 18px;
    font-size: 12px;
    line-height: 14.32px;
    text-align: left;
    border-bottom: 1px solid ${colors.mediumTeal};
`;

const CopyButton = styled(BorderlessButton)`
    height: 48px;
    width: 100%;
`;

interface Props {
    collection: DirectBuyStepCollection;
    steps: DirectBuyStepsEntitiesState | StepById;
    actionCallback: VoidFunction;
}

const DirectBuyDetailsCaptions = ({
    collection,
    steps,
    actionCallback
}: Props) => {
    const dispatch = useDispatch();
    const captionSteps = collection.combined
        .filter(id => steps[id].caption)
        .map(id => steps[id]);
    if (captionSteps.length === 0) {
        return null;
    }
    return (
        <ResourceContainer>
            <ResourceDescription>CAPTIONS</ResourceDescription>
            {captionSteps.map(step => {
                const caption = step.caption;
                if (caption) {
                    return (
                        <CaptionContainer key={step.id}>
                            <CaptionText>{caption.text}</CaptionText>
                            <CopyToClipboard
                                text={caption.text}
                                onCopy={() => {
                                    actionCallback();
                                    dispatch(
                                        addNotification("Copied caption!")
                                    );
                                }}
                            >
                                <CopyButton>Copy Caption</CopyButton>
                            </CopyToClipboard>
                        </CaptionContainer>
                    );
                } else {
                    return null;
                }
            })}
        </ResourceContainer>
    );
};

export default DirectBuyDetailsCaptions;
