import React from "react";
import { Link, useHistory } from "react-router-dom";
import { colors, fonts } from "src/constants";
import {
    clearLink,
    grayBackgroundHoverState,
    primaryBlueBackgroundHoverState
} from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import { ChallengeToolsLink } from "../utils/challenge-tools.types";
import { ReactComponent as NavbarInstructionSets } from "src/components/icons/navbar-instructions-sets.svg";
import { ReactComponent as NavbarCreatorGroups } from "src/components/icons/navbar-creator-groups.svg";

export const Tab = styled(Link)<{ selected: boolean }>`
    ${clearLink()};
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    color: ${props => (props.selected ? colors.white : colors.primaryGray)};
    border-radius: 6px;
    font-size: ${fonts.semiSmallTitle}px;
    transition: 0.1s ease-in;

    ${props => {
        return props.selected
            ? primaryBlueBackgroundHoverState()
            : grayBackgroundHoverState();
    }};

    @media (hover: hover) {
        :hover {
            color: ${props => props.selected && colors.white};
            background-color: ${props => props.selected && colors.primaryBlue};
        }

        :hover > svg {
            fill: ${props => (props.selected ? colors.white : colors.black)};
        }
    }
`;

const iconStyles = css<{ selected: boolean }>`
    width: 16px;
    height: 16px;
    fill: ${props => (props.selected ? colors.white : colors.primaryGray)};
`;

const InstructionSetsIcon = styled(NavbarInstructionSets)`
    ${iconStyles};
`;

const CreatorGroupsIcon = styled(NavbarCreatorGroups)`
    ${iconStyles};
`;

const PaymentSetsIcon = styled(NavbarCreatorGroups)`
    ${iconStyles};
`;

interface Props extends ChallengeToolsLink {
    includeIcon?: true;
}

const ChallengeToolsLinkTab = ({ text, route, includeIcon }: Props) => {
    // Library Methods -----------------------------------------------
    const history = useHistory();
    const { pathname } = history.location;

    // Local Functions -----------------------------------------------
    function checkSelected(): boolean {
        if (text === "Instruction Sets") {
            return pathname.includes("instruction-sets");
        } else if (text === "Creator Groups") {
            return pathname.includes("creator-groups");
        } else {
            return pathname.includes("payment-sets");
        }
    }

    function pickCorrectIcon(): JSX.Element {
        if (text === "Instruction Sets") {
            return <InstructionSetsIcon selected={checkSelected()} />;
        } else if (text === "Creator Groups") {
            return <CreatorGroupsIcon selected={checkSelected()} />;
        } else {
            return <PaymentSetsIcon selected={checkSelected()} />;
        }
    }

    // JSX -----------------------------------------------------------
    return (
        <Tab to={route} selected={checkSelected()}>
            {includeIcon && pickCorrectIcon()}
            {text}
        </Tab>
    );
};

export default ChallengeToolsLinkTab;
