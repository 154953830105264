import axios, {
    AxiosRequestConfig,
    CancelToken,
    CancelTokenSource
} from "axios";
import qs from "querystring";
import { auth } from "./firebase/FirebaseConfig";
import { isIP } from "./util";

let hostname = window.location.hostname;

if (process.env.BASE_URL) {
    axios.defaults.baseURL = process.env.BASE_URL;
} else if (window.location.hostname === "plugweb-dev.herokuapp.com") {
    axios.defaults.baseURL = "https://theplug-prod.herokuapp.com/";
} else if (
    hostname === "app.plugco.in" ||
    hostname === "plugweb-test.herokuapp.com" ||
    hostname === "app.theplug.co"
) {
    axios.defaults.baseURL = "https://theplug-prod.herokuapp.com/";
} else if (isIP(hostname)) {
    axios.defaults.baseURL = `http://${hostname}:3003/`;
} else {
    axios.defaults.baseURL = "http://localhost:3003/";
}

axios.defaults.withCredentials = false;
axios.defaults.headers.common["Content-Type"] =
    "application/x-www-form-urlencoded";
axios.defaults.headers.common = {
    Authorization:
        localStorage.getItem("token") &&
        `bearer ${localStorage.getItem("token")}`
};
axios.defaults.timeout = 30000;

const useFirebaseToken = async (config: AxiosRequestConfig) => {
    const firebaseIdToken = await auth.currentUser?.getIdToken();
    if (firebaseIdToken && config.headers) {
        config.headers.set("FirebaseToken", firebaseIdToken);
    }
    return config;
};

axios.interceptors.request.use(useFirebaseToken, error => {
    return Promise.reject(error);
});

export const setToken = (manualToken?: string) => {
    const token = localStorage.getItem("token");
    if (token || manualToken)
        axios.defaults.headers.common = {
            Authorization: `bearer ${manualToken || token}`
        };
};

export const get = <Params>(
    path: string,
    params: Params,
    cancelToken?: CancelToken
) => {
    return axios.get(path, { params, cancelToken });
};

export function getPublic<Params>(path: string, params: Params) {
    const token = axios.defaults.headers.common.Authorization;
    return axios.get((!token ? "/public" : "") + path, { params });
}

export function post<Params>(path: string, params: Params, timeout?: number) {
    return axios.request({
        method: "post",
        url: path,
        data: params,
        timeout
    });
}

export function patch<Params>(path: string, params?: Params) {
    return axios.request({
        method: "patch",
        url: path,
        data: params
    });
}

export function deleteReq<Params>(path: string, params?: Params) {
    return axios.request({
        method: "delete",
        url: path,
        data: params
    });
}

// Legacy

export const getApi = (path: string, params: any) => {
    return axios.get("/api" + path, { params });
};

export const getUser = (path: string, params: any) => {
    return axios.get("/user" + path, { params });
};

export const postQS = (path: string, params: any) => {
    return axios.request({
        method: "post",
        url: path,
        data: qs.stringify(params)
    });
};

export default axios;
