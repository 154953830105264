import React from "react";
import {
    FlexVCSpaceBetween,
    FlexVerticallyCentered
} from "src/components/CommonStyles";
import styled from "styled-components";
import { colors } from "src/constants";

const Main = styled.div<{ disabled?: boolean }>`
    ${FlexVCSpaceBetween};
    height: 54px;

    ${props => props.disabled && "opacity: 0.5;"}
`;

const Label = styled.div`
    font-size: 14px;
    text-align: left;
`;

const Status = styled.div`
    font-size: 14px;
    margin-left: auto;
    margin-right: 12px;
    color: ${colors.primaryBlue};
    text-transform: capitalize;
`;

const Button = styled.div`
    cursor: pointer;
    user-select: none;
`;

const Icon = styled.img``;

interface Props {
    toggle: () => void;
    active: boolean;
    label: string;
    status?: string;
    disabled?: boolean;
}

const GroupToggleOption = ({
    toggle,
    active,
    label,
    status,
    disabled
}: Props) => {
    return (
        <Main disabled={disabled}>
            <Label>{label}</Label>
            {status && <Status>{status}</Status>}
            <Button>
                {active ? (
                    <Icon src="/knob-active.svg" onClick={toggle} />
                ) : (
                    <Icon src="/knob.svg" onClick={toggle} />
                )}
            </Button>
        </Main>
    );
};

export default GroupToggleOption;
