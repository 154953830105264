import { push } from "connected-react-router";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import ProfilePhoto from "src/components/ProfilePhoto";
import withModalPortal from "src/components/withModalPortal";
import { colors } from "src/constants";
import CarouselMedia from "src/modals/CarouselMedia";
import CarouselModal from "src/modals/CarouselModalNew";
import { GlobalState } from "src/reducers";
import Platforms from "src/social-accounts/Platforms";
import { shortenNumber } from "src/util";
import styled from "styled-components";
import { getOpenBuyLeaderboardByIds } from "../details/leaderboard/selectors";
import OpenBuyLeaderboardCarouselFooter from "./OpenBuyLeaderboardCarouselFooter";

const StyledCarouselModal = styled(CarouselModal)`
    z-index: auto;
`;

const LeaderboardHeader = styled.div`
    margin-top: 50px;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    max-width: 645px;
    width: 100%;
`;

const ProfileBlock = styled.div`
    ${FlexVerticallyCentered};
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${colors.white};
    margin-left: 12px;
`;

const Title = styled.div`
    font-size: 19px;
    font-weight: 600;
    line-height: 28px;
`;

const Subtitle = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;

    & > span {
        font-weight: 600;
    }
`;

const CloseIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-top: 13px;
    cursor: pointer;
`;

interface Props {
    openBuyId: number;
    modalReportIds: number[];
    closeModal: () => void;
}

const OpenBuyLeaderboardCarouselModal = ({
    openBuyId,
    modalReportIds,
    closeModal
}: Props) => {
    const dispatch = useDispatch();
    const [activeModal, setActiveModal] = useState(0);
    const reports = useSelector((state: GlobalState) =>
        getOpenBuyLeaderboardByIds(state, modalReportIds)
    );
    const isMobile = useSelector(
        (state: GlobalState) => state.ui.observer.isDeviceMobile
    );

    const handleCTA = () => {
        closeModal();
        dispatch(
            push(`/buys/open/${openBuyId}`, {
                scrollToInstructions: true
            })
        );
    };

    return (
        <StyledCarouselModal
            open
            closeModal={closeModal}
            swipeable
            arrows={!isMobile}
            infinite
            columnLayout
            fullHeight
            Header={
                <LeaderboardHeader onClick={e => e.stopPropagation()}>
                    <ProfileBlock>
                        <ProfilePhoto
                            photoSize={60}
                            profilePhotoUrl={
                                reports[activeModal].socialAccount
                                    ?.profilePhotoUrl
                            }
                            photoBorder={`1.5px solid ${colors.white}`}
                        />
                        <Info>
                            <Title>
                                @{reports[activeModal].socialAccount?.username}
                            </Title>
                            <Subtitle>
                                <span>
                                    {shortenNumber(
                                        reports[activeModal].socialAccount
                                            ?.numFollowers || 0
                                    )}
                                </span>{" "}
                                on{" "}
                                {
                                    Platforms[
                                        reports[activeModal].socialAccount
                                            ?.platform || "other"
                                    ].title
                                }
                            </Subtitle>
                        </Info>
                    </ProfileBlock>
                    <CloseIcon src="/white_close.svg" onClick={closeModal} />
                </LeaderboardHeader>
            }
            Footer={
                <OpenBuyLeaderboardCarouselFooter
                    report={reports[activeModal]}
                    handleCTA={handleCTA}
                />
            }
            handleBeforeChange={(nextSlide, state) => {
                if (nextSlide > state.currentSlide) {
                    if (activeModal === reports.length - 1) {
                        setActiveModal(0);
                    } else {
                        setActiveModal(activeModal + 1);
                    }
                } else {
                    if (activeModal !== 0) {
                        setActiveModal(activeModal - 1);
                    } else {
                        setActiveModal(reports.length - 1);
                    }
                }
            }}
        >
            {reports.map(report => (
                <CarouselMedia
                    key={report.id}
                    mediaUrl={report.media.url}
                    mediaType={report.media.type}
                    isPortrait
                />
            ))}
        </StyledCarouselModal>
    );
};
export default withModalPortal(OpenBuyLeaderboardCarouselModal);
