import React from "react";
import { colors, fonts, sizes } from "src/constants";
import { clearSpacing } from "src/utils/styles/snippets";
import styled from "styled-components";

interface SectionProps {
    divider?: boolean;
}

const Section = styled.article<SectionProps>`
    display: flex;
    flex-direction: column;
    border-right: ${props => {
        return props.divider && `${colors.mediumTeal} solid 1px`;
    }};
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    height: ${sizes.personalOffers.headerRowHeaderHeight}px;
    padding: 0px 16px;
    background-color: ${colors.white};
    border-bottom: ${colors.mediumTeal} solid 1px;
`;

const Title = styled.h3`
    ${clearSpacing()};
    color: ${colors.black};
    font-size: ${fonts.smallTitle}px;
`;

const Body = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 12px;
`;

interface Props {
    divider?: boolean;
    title: string;
    children: React.ReactNode;
}

const DirectBuysHeaderRowSection = ({ divider, title, children }: Props) => {
    return (
        <Section divider={divider}>
            <Header>
                <Title>{title}</Title>
            </Header>

            <Body>{children}</Body>
        </Section>
    );
};

export default DirectBuysHeaderRowSection;
