import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectionButton from "src/components/SelectionButton";
import { GlobalState } from "src/reducers";
import { PlatformType } from "src/social-accounts/Platforms";
import { creatorGroupsPlatformFilterOptions } from "../utils/constants";
import { checkSelected, toggleFilterSelection } from "../utils/helpers";

interface Props {
    searchString: string;
}

const PlatformFilterOptions = ({ searchString }: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const { platformFilters } = useSelector((state: GlobalState) => {
        return state.ui.creators;
    });

    // JSX -----------------------------------------------------------
    return (
        <>
            {creatorGroupsPlatformFilterOptions
                .filter((option: PlatformType) => {
                    if (searchString === "") {
                        return true;
                    } else {
                        return option.title
                            .toLowerCase()
                            .includes(searchString.toLowerCase());
                    }
                })
                .map((option: PlatformType, index: number) => {
                    return (
                        <SelectionButton
                            key={`marketplace-creator-groups-${option}-${index}`}
                            iconUrl={option.imgSrc}
                            text={option.title}
                            selected={checkSelected(
                                "Platform",
                                option.title.toLowerCase(),
                                platformFilters
                            )}
                            setSelected={() =>
                                toggleFilterSelection(
                                    "Platform",
                                    option.title.toLowerCase(),
                                    dispatch,
                                    platformFilters
                                )
                            }
                        />
                    );
                })}
        </>
    );
};

export default PlatformFilterOptions;
