import React, { useEffect, useState } from "react";
import { GlobalState } from "../../../../reducers";
import { connect, useDispatch, useSelector } from "react-redux";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import NavButton from "../../../../components/NavButton";
import PromotedPage from "../../components/PromotedPage";
import { Connection, PublicProfileData } from "../../../types";
import FullscreenLoader from "../../../../components/FullscreenLoader";
import PaddingAround from "../../../../components/PaddingAround";
import LockCard from "../../../components/LockCard";
import CampaignModals from "../../../modals/CampaignModals";
import XCard from "../../../components/XCard";
import { fetchPromotedPublic } from "../../../actions";
import MainContainer from "src/components/MainContainer";
import { useHistory } from "react-router";
import Navbar from "src/components/Navbar";

interface Props {
    publicData?: PublicProfileData;
}

const PublicPromotedPage = ({ publicData }: Props) => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        if (!scrolled) {
            window.scrollTo(0, 0);
            setScrolled(true);
        }
    }, [scrolled]);

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();
    function dispatchFetchPromotedPublic(connection: Connection): void {
        dispatch(fetchPromotedPublic(connection));
    }

    if (publicData && publicData.connection.state !== "friend") {
        const connection = publicData.connection;

        return (
            <>
                <Navbar back title="PROMOTING" />

                <MainContainer>
                    <PaddingAround>
                        <LockCard
                            text={`You must connect with ${
                                connection.other.profile.username
                                    ? `@${connection.other.profile.username}`
                                    : "this user"
                            } to view what they are promoting.`}
                            connection={connection}
                        />
                    </PaddingAround>
                </MainContainer>
            </>
        );
    } else if (publicData && publicData.promotedCampaigns === null) {
        return (
            <>
                <Navbar back title="PROMOTING" />

                <MainContainer>
                    <PaddingAround>
                        <XCard
                            text="Failed to load promoted campaigns, click the button below to try again."
                            button={{
                                text: "Try again",
                                callback: () => {
                                    dispatchFetchPromotedPublic(
                                        publicData.connection
                                    );
                                }
                            }}
                        />
                    </PaddingAround>
                </MainContainer>
            </>
        );
    } else if (!(publicData && publicData.promotedCampaigns)) {
        return (
            <>
                <Navbar back title="PROMOTING" />
                <FullscreenLoader />
            </>
        );
    } else {
        const promotedCampaigns = publicData.promotedCampaigns;

        return (
            <>
                <Navbar back title="PROMOTING" />

                <MainContainer>
                    {scrolled && (
                        <PromotedPage promotedCampaigns={promotedCampaigns} />
                    )}
                </MainContainer>

                <CampaignModals />
            </>
        );
    }
};

export default PublicPromotedPage;
