import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { patch } from "src/Api";
import useQuery from "src/hooks/useQuery";
import { BigBlueButton } from "src/profile/components/Buttons";
import styled from "styled-components";
import { getAuthRedirectDescription, getAuthRedirectTitle } from "../helpers";
import { isAxiosError } from "axios";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 900px;
    margin: auto;
    height: 100vh;
    padding: 18px;
    gap: 24px;
    @media (max-width: 900px) {
        align-items: start;
        text-align: left;
        width: auto;
    }
`;

const Pluggy = styled.img`
    height: 80px;
    width: 80px;
    margin: 0 14px;

    @media (min-width: 900px) {
        height: 120px;
        width: 120px;
    }
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    margin: 0 14px;
    @media (min-width: 900px) {
        font-size: 32px;
    }
`;

const Description = styled.div`
    font-size: 14px;
    margin: 0 14px 20px 14px;
    @media (min-width: 900px) {
        font-size: 24px;
    }
`;

const Button = styled(BigBlueButton)`
    max-width: 380px;

    @media (min-width: 900px) {
        font-size: 24px;
    }
`;

interface Props {}

interface State {
    social_account_id: string;
    publisher_id: string;
    username: string;
    isTCM: string;
}

const TikTokVerificationRedirect = ({}: Props) => {
    const history = useHistory();
    const params = useQuery();
    const code = params.get("code");
    let state = params.get("state");

    let stateObj: State = {
        username: "",
        publisher_id: "",
        isTCM: "false",
        social_account_id: ""
    };

    state?.split(",").forEach(prop => {
        try {
            //@ts-ignore
            stateObj[prop.split(":")[0]] = prop.split(":")[1];
        } catch (err) {
            console.log(err);
        }
    });

    const [status, setStatus] = useState("loading");

    const goBack = () => {
        var now = new Date().valueOf();
        setTimeout(function() {
            if (new Date().valueOf() - now > 100) return;
            //default to web redirect if past time limit
            history.push("/profile/accounts");
        }, 25);
        //try to go to mobile app
        window.location.replace("theplug://socialAccount");
    };

    async function processCode() {
        if (stateObj.publisher_id === "98576") {
            setStatus("testing");
            return;
        } else if (!code)
            setStatus("No authorization from TikTok received, try again.");
        else
            try {
                setStatus("loading");

                let res = await patch(
                    stateObj.social_account_id
                        ? `/public/socialAccount/${stateObj.social_account_id}/tiktokLogin/verify`
                        : "/public/socialAccount/tiktokLogin/verify",
                    {
                        authCode: code,
                        isTCM: stateObj.isTCM === "true",
                        publisherId: stateObj.publisher_id
                    }
                );
                setStatus(res.data.data.verified ? "success" : "failure");
            } catch (error) {
                if (isAxiosError(error))
                    setStatus(
                        error?.response?.data?.error?.message?.split(
                            "Error:"
                        )[1] || "Malformed URL please try again"
                    );
            }
    }

    useEffect(() => {
        processCode();
    }, []);
    return (
        <Main>
            <Pluggy src={"/logo.svg"} />
            <Title>{getAuthRedirectTitle(status)}</Title>
            {!!status && (
                <>
                    <Description>
                        {getAuthRedirectDescription(
                            status,
                            "TikTok",
                            stateObj.username
                        )}
                    </Description>
                    <Button onClick={goBack}>Return To The Plug</Button>
                </>
            )}
        </Main>
    );
};
export default TikTokVerificationRedirect;
