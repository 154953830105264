import React from "react";
import styled from "styled-components";
import { indicatorColors, indicatorGradients } from "./indicatorStyleConstants";

interface StyleProps {
    background: string;
    isOpenBuy?: boolean;
}

const Main = styled.div<StyleProps>`
    position: absolute;
    top: 0;
    right: 0;
    height: 22px;
    min-width: 22px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props =>
        props.isOpenBuy &&
        `display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 6px;
    position: absolute;
    top: -4px;
    right: -4px;
    height: 24px;
    border-radius: 12px;`}

    background: ${props => props.background};
`;

const Label = styled.div`
    font-size: 11px;
    font-weight: 600;
    color: white;
    padding: 0 6px 0;
`;

export interface IndicatorTagContent {
    string: string;
    isImageUrl: boolean;
    color: IndicatorTagColor;
}

export enum IndicatorTagColor {
    blue,
    yellow,
    red,
    inactive,
    clear,
    green,
    ended
}

interface Props {
    content: IndicatorTagContent | null;
    isOpenBuy?: boolean;
}

const IndicatorTag = ({ content, isOpenBuy }: Props) => {
    let background;
    if (!content) return null;

    switch (content.color) {
        case IndicatorTagColor.clear:
            return null;
        case IndicatorTagColor.blue:
            background = indicatorGradients.blueGradient;
            break;
        case IndicatorTagColor.yellow:
            background = indicatorGradients.yellowGradient;
            break;
        case IndicatorTagColor.red:
            background = indicatorGradients.redGradient;
            break;
        case IndicatorTagColor.inactive:
            background = indicatorColors.inactiveDark;
            break;
        case IndicatorTagColor.green:
            background = indicatorColors.green;
            break;
        case IndicatorTagColor.ended:
            background = indicatorColors.ended;
    }

    return (
        <Main background={background} isOpenBuy={isOpenBuy}>
            {content.isImageUrl ? (
                <img src={content.string} alt="" />
            ) : (
                <Label>{content.string}</Label>
            )}
        </Main>
    );
};

export default IndicatorTag;
