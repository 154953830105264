import React, { useState } from "react";
import NavButton from "src/components/NavButton";
import SimpleNavbar from "src/components/SimpleNavbar";
import DesktopTabBar from "src/components/desktop/DesktopTabBar";
import InboxTab from "./tabs/InboxTab";
import { AnimatePresence } from "framer-motion";
import ChatCreationModal from "src/new-chat/ChatCreationModal";
import { useDispatch, useSelector } from "react-redux";
import useInternalRouting from "src/hooks/useInternalRouting";
import FullscreenLayout from "src/components/layout/FullscreenLayout";
import styled from "styled-components";
import { FlexContent } from "src/components/CommonStyles";
import { GlobalState } from "src/reducers";
import { getTotalUnreadCount } from "./selectors";

const Main = styled(FullscreenLayout)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const ConversationsList = () => {
    const { goBackIfInternalRoute } = useInternalRouting("/");
    const [composeModal, toggleComposeModal] = useState(false);
    const inboxUnreadCount = useSelector((state: GlobalState) =>
        getTotalUnreadCount(state, true)
    );
    const requestUnreadCount = useSelector((state: GlobalState) =>
        getTotalUnreadCount(state, false)
    );

    return (
        <Main>
            <SimpleNavbar
                title="MESSAGES"
                left={<NavButton back onClick={goBackIfInternalRoute} />}
                right={
                    <NavButton onClick={() => toggleComposeModal(true)}>
                        <img src="/compose.svg" />
                    </NavButton>
                }
            />
            <FlexContent>
                <DesktopTabBar
                    oldLayout
                    items={[
                        {
                            label: `Inbox${
                                inboxUnreadCount ? ` (${inboxUnreadCount})` : ""
                            }`,
                            key: "inbox",
                            component: <InboxTab isInbox />
                        },
                        {
                            label: `Requests${
                                requestUnreadCount
                                    ? ` (${requestUnreadCount})`
                                    : ""
                            }`,
                            key: "requests",
                            component: <InboxTab isInbox={false} />
                        }
                    ]}
                />
            </FlexContent>
            <AnimatePresence>
                {composeModal && (
                    <ChatCreationModal
                        closeModal={() => toggleComposeModal(false)}
                    />
                )}
            </AnimatePresence>
        </Main>
    );
};

export default ConversationsList;
