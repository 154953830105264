import React from "react";
import styled from "styled-components";
import { storage } from "../../firebase/FirebaseConfig";
import { isMobileSafari, makeId } from "../../util";
import SpinLoader from "../../components/SpinLoader";
import { colors, fonts } from "../../constants";
import { ReactComponent as PlusIcon } from "../../components/icons/card-circle-plus.svg";
import ShadeScreen from "src/media/components/ShadeScreen";
import { Campaign } from "src/campaigns/types";
import MediaRulesAccordion from "../details/accordions/MediaRulesAccordion";
import LoadingButton from "src/components/LoadingButton";
import SimpleNavbar from "src/components/SimpleNavbar";
import { UploadMediaData } from "./PromoteFlowModal";
import { CustomMediaUpload } from "src/media/actions";
import PromoteFlowTabs from "./components/PromoteFlowTabs";
import MediaUploader from "src/components/media/MediaUploader";

const Main = styled.div`
    text-align: left;
`;

const ButtonContainer = styled.div`
    display: flex;
    padding: 10px 18px 3px 18px;
`;

interface Props {
    campaign: Campaign;
    onDelete: () => void;
    onNext: () => void;
    onClose: (step?: string) => void;
    uploadData: CustomMediaUpload;
    setUploadData: (data: any) => void;
    mediaUrl: string;
    setMediaUrl: (url: string) => void;
}
const Close = styled.img`
    padding: 15px;
    cursor: pointer;

    svg {
        margin-right: 15px;
    }
`;

const UploadMedia = ({
    campaign,
    onNext,
    onClose,
    uploadData,
    setMediaUrl,
    setUploadData
}: Props) => {
    return (
        <Main>
            <SimpleNavbar
                title={"Promote"}
                left={
                    <Close
                        onClick={() => onClose("select")}
                        src={"/close.svg"}
                    />
                }
            />
            <PromoteFlowTabs
                selected="custom"
                onSelect={() => onClose("select-media")}
            />
            <MediaUploader
                path={`${campaign.linkUrlPrefix}`}
                onUpload={(id, type) =>
                    setUploadData((prevState: UploadMediaData) => ({
                        ...prevState,
                        type,
                        filename: id,
                        cover_photo: type === "photo" ? id : ""
                    }))
                }
                onRemove={() =>
                    setUploadData((prevState: UploadMediaData) => ({
                        ...prevState,
                        type: "",
                        filename: "",
                        mediaUrl: null
                    }))
                }
                onCoverPhoto={id =>
                    setUploadData((prevState: UploadMediaData) => ({
                        ...prevState,
                        cover_photo: id
                    }))
                }
                onMediaUrl={url => setMediaUrl(url)}
            />
            <MediaRulesAccordion
                initiallyExpanded
                initialHeight={195}
                title="Media Rules"
            />
            <ButtonContainer>
                <LoadingButton
                    disabled={!uploadData.type}
                    text="Upload"
                    height="48px"
                    width="100%"
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                    onClick={onNext}
                />
            </ButtonContainer>
        </Main>
    );
};

export default UploadMedia;
