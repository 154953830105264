import isUrl from "is-url";
import { Descendant, Editor } from "slate";
import CustomEditor from "./CustomEditor";
import serialize from "./serializer";

export const serializeContent = (content: Descendant[]): string => {
    const newLine = "  \n";
    const serializedArr = content.map(v => {
        return serialize(v, {});
    });
    const serializedString = serializedArr.join("\n");
    const stringWithNewLines = serializedString.replace(
        new RegExp(/\n|<br>/g),
        newLine
    );

    return stringWithNewLines;
};

export const isContentEmpty = (markdownStr: string): boolean => {
    const newLine = "  \n";
    return markdownStr.replaceAll(newLine, "").trim().length === 0;
};

export const withInlines = (editor: Editor) => {
    const { insertData, insertText, isInline, isVoid } = editor;

    editor.isInline = element =>
        element.type === "link" ||
        element.type === "rule_item" ||
        isInline(element);

    editor.isVoid = element => {
        return element.type === "rule_item" ? true : isVoid(element);
    };

    editor.insertText = text => {
        if (text && isUrl(text)) {
            CustomEditor.wrapLink(editor, text);
        } else {
            insertText(text);
        }
    };

    editor.insertData = data => {
        const text = data.getData("text/plain");

        if (text && isUrl(text)) {
            CustomEditor.wrapLink(editor, text);
        } else {
            insertData(data);
        }
    };

    return editor;
};
