import React, { useState } from "react";
import styled from "styled-components";
import MediaButtons from "./MediaButtons";
import ShadeScreen from "../components/ShadeScreen";
import RenderMedia from "./RenderMedia";
import ConnectFirebase from "./ConnectFirebase";
import { colors } from "../../constants";
import useAuthorization from "../../auth/hooks/useAuthorization";
import { MediaAction } from "../../auth/roles";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { isScriptModalOpen } from "src/campaigns/scripts/selectors";
import { selectAdminStatus } from "src/auth/selectors";
import RemoveBtn from "src/components/RemoveBtn";
import { scriptAssetDelete } from "src/campaigns/scripts/actions";

const Main = styled.div<{ mediaWidth: number }>`
    width: ${props => props.mediaWidth}px;
`;

interface MediaProps {
    mediaHeight: number;
    inCard: boolean;
    clickable: boolean;
}

const MediaContainer = styled.div<MediaProps>`
    height: ${props => props.mediaHeight}px;
    width: 100%;
    position: relative;
    background-color: white;
    overflow: hidden;

    border: 1px solid ${colors.mediumTeal};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    ${props => !props.inCard && "border-radius: 10px;"}
    ${props => props.inCard && "border-bottom: 0;"}
    ${props => props.clickable && "cursor: pointer;"}
`;
const MediaIdNum = styled.div`
    position: absolute;
    bottom: 0px;
    left: 4px;
    color: white;
    font-size: 13px;
    font-weight: bold;
`;
const StyledRemoveBtn = styled(RemoveBtn)`
    position: absolute;
    top: 8px;
    left: 8px;
`;

interface Props {
    media: {
        id: number;
        type: string;
        isPortrait: boolean;
        key: string;
        coverPhoto: string | null;
        url: string | null;
        coverPhotoUrl: string | null;
        linkUrlPrefix?: string;
        shouldShowInApp?: boolean | null;
    };
    linkPrefix: string;
    mediaHeight?: number;
    allPortrait?: true;
    mediaCallback?: VoidFunction;
    buttonCallbacks?: {
        linkCallback: VoidFunction;
        downloadCallback?: VoidFunction;
    };
    buttonDisabled?: boolean;
    buttonHideLink?: boolean;
    playIcon?: true;
    isScriptModal?: boolean;
    children?: any;
}

const MediaCard = ({
    media,
    linkPrefix,
    mediaHeight = 192,
    allPortrait,
    mediaCallback,
    buttonCallbacks,
    buttonDisabled,
    buttonHideLink,
    playIcon,
    isScriptModal,
    children
}: Props) => {
    const [cellButtons] = useAuthorization([MediaAction.cellButtons]);

    const [mediaUrl, setMediaUrl] = useState(media.url);
    const [coverUrl, setCoverUrl] = useState(media.coverPhotoUrl);

    const [play, setPlay] = useState(false);
    const isAdmin = useSelector(selectAdminStatus);
    const scriptId = useSelector(
        (state: GlobalState) => state.ui.script.scriptModal
    );

    const dispatch = useDispatch();

    return (
        <Main
            mediaWidth={
                media.isPortrait || allPortrait
                    ? Math.round((mediaHeight * 9) / 16)
                    : mediaHeight
            }
        >
            <ConnectFirebase
                media={media}
                linkPrefix={media.linkUrlPrefix || linkPrefix}
                mediaUrl={mediaUrl}
                setMediaUrl={setMediaUrl}
                coverUrl={coverUrl}
                setCoverUrl={setCoverUrl}
            />
            <MediaContainer
                mediaHeight={mediaHeight}
                inCard={cellButtons && Boolean(buttonCallbacks)}
                clickable={Boolean(mediaCallback)}
                onClick={mediaCallback}
            >
                <RenderMedia
                    mediaType={media.type}
                    mediaUrl={mediaUrl}
                    coverUrl={coverUrl}
                    playable={!Boolean(mediaCallback)}
                    play={play}
                    setPlay={setPlay}
                />
                {!play && (
                    <>
                        <ShadeScreen
                            opacity={!media.shouldShowInApp ? 0.7 : 0.5}
                        >
                            {media.type === "video" && playIcon && (
                                <img src={"/play_icon.svg"} />
                            )}
                        </ShadeScreen>
                        {children}
                    </>
                )}
                {isAdmin && isScriptModal && scriptId && (
                    <>
                        <MediaIdNum>ID: {media.id}</MediaIdNum>
                        <StyledRemoveBtn
                            handleClick={() =>
                                dispatch(scriptAssetDelete(scriptId, media.id))
                            }
                        />
                    </>
                )}
            </MediaContainer>
            {cellButtons && buttonCallbacks && (
                <MediaButtons
                    inCard
                    mediaId={media.id}
                    mediaUrl={mediaUrl}
                    buttonHideLink={buttonHideLink}
                    linkCallback={buttonCallbacks.linkCallback}
                    downloadCallback={buttonCallbacks.downloadCallback}
                    disabled={buttonDisabled}
                />
            )}
        </Main>
    );
};

export default MediaCard;
