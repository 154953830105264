import { useSlate } from "slate-react";
import { useDispatch } from "react-redux";
import CustomEditor from "../CustomEditor";
import React, { useState } from "react";
import isUrl from "is-url";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";
import { OutlineButton } from "src/profile/components/Buttons";
import { Editor, Range } from "slate";

const TooltipWrapper = styled.div`
    background-color: white;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    z-index: 20000;
    font-size: 0.8rem;
`;
const Label = styled.label`
    margin-bottom: 0px;
    padding-right: 4px;
`;
const UnlinkBtn = styled(OutlineButton)`
    margin-left: 8px;
`;
const FlexWrap = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0px;
    }
`;
const LinkForm = ({ closeTooltip }: { closeTooltip: () => void }) => {
    const editor = useSlate();
    const dispatch = useDispatch();
    const getLinkString = (editor: Editor) => {
        const { selection } = editor;
        const linkNode = CustomEditor.getLink(editor);
        if (selection) {
            if (linkNode && Range.isCollapsed(selection)) {
                return Editor.string(editor, linkNode[1]);
            }
            return Editor.string(editor, selection);
        }
    };
    const initialValue = {
        link: CustomEditor.getSelectedLink(editor),
        text: getLinkString(editor)
    };
    const [url, setUrl] = useState(initialValue.link || "");
    const [urlText, setUrlText] = useState(initialValue.text || "");
    const [urlTextForm, setUrlTextForm] = useState("");

    const handleSubmit = () => {
        const validatedUrl = isUrl(url) ? url : `http://${url}`;
        if (url.length > 0 && isUrl(validatedUrl)) {
            CustomEditor.insertLink(editor, validatedUrl, urlTextForm);
            closeTooltip && closeTooltip();
        } else {
            dispatch(
                setPillNotificationText(
                    "Please enter a valid URL. https://www.example.com",
                    "danger",
                    2500
                )
            );
        }
    };
    const handleUnwrap = () => {
        CustomEditor.unwrapLink(editor);
        closeTooltip && closeTooltip();
    };
    return (
        <TooltipWrapper>
            <FlexWrap>
                {urlText.length > 0 ? (
                    <div>
                        <strong>Text:</strong> {urlText}
                    </div>
                ) : (
                    <>
                        <Label htmlFor="editor-url-texts">
                            <strong>Text:</strong>
                        </Label>
                        <input
                            autoFocus
                            id="editor-url-text"
                            type="text"
                            value={urlTextForm}
                            onChange={e => setUrlTextForm(e.target.value)}
                        />
                    </>
                )}
            </FlexWrap>
            <FlexWrap>
                <Label htmlFor="editor-url">
                    <strong>URL:</strong>
                </Label>
                <input
                    autoFocus={urlText.length > 0}
                    id="editor-url"
                    type="text"
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleSubmit();
                        }
                    }}
                />
            </FlexWrap>
            <FlexWrap>
                <OutlineButton onMouseDown={handleSubmit}>Save</OutlineButton>
                {initialValue.link && (
                    <UnlinkBtn onMouseDown={handleUnwrap}>Remove</UnlinkBtn>
                )}
            </FlexWrap>
        </TooltipWrapper>
    );
};
export default LinkForm;
