import { Action } from "src/types";

// Admin Fetch Open Buys -----------------------------------------------
export const ADMIN_FETCH_OPEN_BUYS = {
    REQUEST: "ADMIN_FETCH_OPEN_BUYS_REQUEST",
    SUCCESS: "ADMIN_FETCH_OPEN_BUYS_SUCCESS"
};

export function adminFetchOpenBuys(openBuyId?: number): Action {
    return {
        type: ADMIN_FETCH_OPEN_BUYS.REQUEST,
        payload: openBuyId
    };
}

export function adminFetchOpenBuysSuccess(data: any): Action {
    return {
        type: ADMIN_FETCH_OPEN_BUYS.SUCCESS,
        payload: { data }
    };
}

// Admin Fetch Open Buy -----------------------------------------------
export const ADMIN_FETCH_OPEN_BUY = {
    REQUEST: "ADMIN_FETCH_OPEN_BUY_REQUEST",
    SUCCESS: "ADMIN_FETCH_OPEN_BUY_SUCCESS"
};
export function adminFetchOpenBuy(openBuyId: number): Action {
    return {
        type: ADMIN_FETCH_OPEN_BUY.REQUEST,
        payload: { openBuyId }
    };
}
export function adminFetchOpenBuySuccess(data: any): Action {
    return {
        type: ADMIN_FETCH_OPEN_BUY.SUCCESS,
        payload: { data }
    };
}

// Admin Create Open Buy -----------------------------------------------
export const ADMIN_CREATE_OPEN_BUY = {
    REQUEST: "ADMIN_CREATE_OPEN_BUY_REQUEST",
    SUCCESS: "ADMIN_CREATE_OPEN_BUY_SUCCESS"
};

export function adminCreateOpenBuy(name: string): Action {
    return {
        type: ADMIN_CREATE_OPEN_BUY.REQUEST,
        payload: { name }
    };
}
export function adminCreateOpenBuySuccess(data: any): Action {
    return {
        type: ADMIN_CREATE_OPEN_BUY.SUCCESS,
        payload: { data }
    };
}

// Admin Update Open Buy -----------------------------------------------
export const ADMIN_UPDATE_OPEN_BUY = {
    REQUEST: "ADMIN_UPDATE_OPEN_BUY_REQUEST",
    SUCCESS: "ADMIN_UPDATE_OPEN_BUY_SUCCESS"
};
export function adminUpdateOpenBuy(data: any, isLocal?: boolean): Action {
    return {
        type: ADMIN_UPDATE_OPEN_BUY.REQUEST,
        payload: { data, isLocal }
    };
}
export function adminUpdateOpenBuySuccess(data: any): Action {
    return {
        type: ADMIN_UPDATE_OPEN_BUY.SUCCESS,
        payload: { data }
    };
}

// Admin Update Open Buy Instruction -----------------------------------------------
export const ADMIN_UPDATE_OPEN_BUY_INSTRUCTION =
    "ADMIN_UPDATE_OPEN_BUY_INSTRUCTION";
export function adminUpdateOpenBuyInstruction(
    openBuyId: number,
    instructionSetId: number
): Action {
    return {
        type: ADMIN_UPDATE_OPEN_BUY_INSTRUCTION,
        payload: { openBuyId, instructionSetId }
    };
}

// Admin Create Open Buy Poster -----------------------------------------------
export const ADMIN_CREATE_OPEN_BUY_POSTER = {
    REQUEST: "ADMIN_CREATE_OPEN_BUY_POSTER_REQUEST",
    SUCCESS: "ADMIN_CREATE_OPEN_BUY_POSTER_SUCCESS"
};
export function adminCreateOpenBuyPoster(
    openBuyId: number,
    filename: string
): Action {
    return {
        type: ADMIN_CREATE_OPEN_BUY_POSTER.REQUEST,
        payload: { openBuyId, filename }
    };
}
export function adminCreateOpenBuyPosterSuccess(data: any): Action {
    return {
        type: ADMIN_CREATE_OPEN_BUY_POSTER.SUCCESS,
        payload: { data }
    };
}

// Admin Update Open Buy Poster -----------------------------------------------
export const ADMIN_UPDATE_OPEN_BUY_POSTER = {
    REQUEST: "ADMIN_UPDATE_OPEN_BUY_POSTER_REQUEST",
    SUCCESS: "ADMIN_UPDATE_OPEN_BUY_POSTER_SUCCESS"
};
export function adminUpdateOpenBuyPoster(
    posterId: number,
    filename: string
): Action {
    return {
        type: ADMIN_UPDATE_OPEN_BUY_POSTER.REQUEST,
        payload: { posterId, filename }
    };
}
export function adminUpdateOpenBuyPosterSuccess(data: any): Action {
    return {
        type: ADMIN_UPDATE_OPEN_BUY_POSTER.SUCCESS,
        payload: { data }
    };
}

// Admin Delete Open Buy -----------------------------------------------
export const ADMIN_DELETE_OPEN_BUY = {
    REQUEST: "ADMIN_DELETE_OPEN_BUY_REQUEST",
    SUCCESS: "ADMIN_DELETE_OPEN_BUY_SUCCESS"
};
export function adminDeleteOpenBuy(openBuyId: number): Action {
    return {
        type: ADMIN_DELETE_OPEN_BUY.REQUEST,
        payload: { openBuyId }
    };
}
export function adminDeleteOpenBuySuccess(openBuyId: number): Action {
    return {
        type: ADMIN_DELETE_OPEN_BUY.SUCCESS,
        payload: { openBuyId }
    };
}
