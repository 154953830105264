import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

const Main = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    background-color: white;
`;

const Icon = styled.img`
    height: 25px;
    width: 27px;
`;

const Title = styled.div`
    font-weight: 600;
    padding: 15px 0px 65px;
`;

const Description = styled.div`
    margin-bottom: 15px;
`;

const AccountsTooSmall = ({ closePage }: { closePage: () => void }) => {
    const buttonStyle = { width: "100%", height: "50px" };

    return (
        <Main>
            <div>
                <Icon src={"/icon_nux_green/icon_nux_green@2x.png"} />
                <Title>Accounts Too Small</Title>
                <Description>
                    The Plug is a service for large scale influencers only.
                    Based on the accounts you listed, you don’t currently have
                    enough followers to qualify to be an influencer on The Plug.
                </Description>
                <Description>
                    If you're able to grow your following significantly, please
                    re-apply! Or, if you think this is a mistake or have other
                    accounts you didn’t include, please update your information
                    using the button below.
                </Description>
            </div>
            <Button onClick={closePage} style={buttonStyle}>
                Update Accounts
            </Button>
        </Main>
    );
};

export default AccountsTooSmall;
