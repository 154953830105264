import { Action } from "src/types";
import { SET_SOCIAL_ACCOUNT_PROFILE_LOADING } from "./actions";

export interface SocialAccountProfileUIState {
    socialAccountProfileLoading: boolean;
}

const initialSocialAccountProfileUIState: SocialAccountProfileUIState = {
    socialAccountProfileLoading: true
};

function socialAccountProfileUIReducer(
    state = initialSocialAccountProfileUIState,
    action: Action
): SocialAccountProfileUIState {
    switch (action.type) {
        case SET_SOCIAL_ACCOUNT_PROFILE_LOADING:
            return {
                ...state,
                socialAccountProfileLoading: action.payload.loading
            };

        default:
            return state;
    }
}

export default socialAccountProfileUIReducer;
