import React from "react";
import { BonusBracket } from "../types";
import styled from "styled-components";
import Modal from "../../modals/Modal";
import ModalButton from "../../modals/ModalButton";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 300;
`;

const Item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
`;

const BracketItem = ({ bracket }: { bracket: BonusBracket }) => {
    return (
        <Item>
            <div>
                {bracket.rankMax === 1
                    ? "First Place"
                    : `Top ${bracket.rankMax}`}
            </div>
            <div>{`${bracket.bonusPercentage}% bonus`}</div>
        </Item>
    );
};

interface Props {
    bonusBrackets: BonusBracket[] | null | undefined;
}

const BracketsModal = ({ bonusBrackets }: Props) => {
    return (
        <Modal>
            <Main>
                <Title>Bonus Brackets</Title>
                <Subtitle>Bonus paid to the top earners each week!</Subtitle>
                {bonusBrackets &&
                    bonusBrackets.map((bracket, i) => (
                        <BracketItem key={i} bracket={bracket} />
                    ))}
            </Main>
            <ModalButton />
        </Modal>
    );
};

export default BracketsModal;
