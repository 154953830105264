import React from "react";
import { AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import FullScreenModal from "src/modals/FullScreenModal";
import { useState } from "react";
import UploadMediaCard from "./UploadMedia";
import { Campaign, CampaignMedia } from "src/campaigns/types";
import MediaSelect from "./MediaSelect";
import MediaPlacement from "./MediaPlacement";
import PlaceAdTag from "./PlaceAdTag";
import SpinLoader from "src/components/SpinLoader";
import PromoteButton from "../details/components/PromoteButton";
import PromoteSelectionModal from "./PromoteSelectionModal";
import { TagButtonsState } from "src/media/upload/TagSelectorButtons";
import { CustomMediaUpload, uploadCustomMedia } from "src/media/actions";
import styled from "styled-components";
import PostMediaModal from "./PostMediaModal";
import { fetchCampaignMediaRules } from "../media-rules/actions";
import { setCurrentCampaignId } from "src/ui/campaigns/actions";
import { initialMediaFilters } from "src/pagination/media/reducer";
import { setMediaFilters } from "src/pagination/media/actions";
import {
    setPostMediaModalOpen,
    setPromoteFlowMedia
} from "src/ui/medias/actions";
import { PROMOTE_FLOW } from "src/ui/promote-flow/actions";
import { clearBidNotifications } from "src/ui/bid-budget/actions";
import { PlacementConfig } from "src/ui/placements/reducer";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const Main = styled.div`
    display: flex;
    width: 100%;
`;

export interface UploadMediaData {
    mediaUrl: string;
    type: string;
    adTag: TagButtonsState;
    shareable: boolean;
    placements: PlacementConfig;
}

interface Props {
    campaign: Campaign;
    inModal?: true;
    currentStep?: string;
    currentMedia?: CampaignMedia;
}

const PromoteFlowModal = ({
    campaign,
    inModal,
    currentStep,
    currentMedia
}: Props) => {
    const initialUploadData = {
        campaign_id: campaign.id,
        filename: "",
        cover_photo: "",
        type: "",
        watermark_type: "none",
        allow_others_to_use: true,
        external_page_id: null,
        placements: {}
    };

    const [step, setStep] = useState(currentStep || "");
    const treatment = useSelector(selectFeatureFlags(featureFlags.PromoteFlow));
    const [uploadData, setUploadData] = useState<CustomMediaUpload>(
        initialUploadData
    );
    const [mediaUrl, setMediaUrl] = useState("");
    const [media, setMedia] = useState<CampaignMedia | null>(
        currentMedia || null
    );

    const dispatch = useDispatch();

    const completeUpload = () => {
        setStep("");
        dispatch(clearBidNotifications());
        dispatch(uploadCustomMedia(uploadData));
        return null;
    };

    const renderPage = () => {
        switch (step) {
            case "select-media":
                return (
                    <MediaSelect
                        onNext={(media: CampaignMedia) => {
                            setStep("promote");
                            setMedia(media);
                        }}
                        onClose={(step?: string) => {
                            step ? setStep(step) : setStep("");

                            !step && dispatch(clearBidNotifications());
                            setUploadData(initialUploadData);
                        }}
                    />
                );
            case "promote":
                return (
                    <PostMediaModal
                        media={media}
                        onClose={() => {
                            dispatch(setPostMediaModalOpen(false));
                            dispatch(PROMOTE_FLOW.setSelectedMedia());
                            setUploadData(initialUploadData);
                            setStep("select-media");
                        }}
                    />
                );
            case "upload":
                return (
                    <UploadMediaCard
                        campaign={campaign}
                        onDelete={() => console.log("delete")}
                        onNext={() => setStep("placement")}
                        onClose={(step?: string) => {
                            step ? setStep(step) : setStep("");
                            !step && dispatch(clearBidNotifications());
                            setUploadData(initialUploadData);
                        }}
                        uploadData={uploadData}
                        mediaUrl={mediaUrl}
                        setMediaUrl={setMediaUrl}
                        setUploadData={setUploadData}
                    />
                );
            case "placement":
                return (
                    <MediaPlacement
                        campaign={campaign}
                        onDelete={() => console.log("delete")}
                        onNext={v => setStep(v || "tag")}
                        onClose={() => {
                            setStep("");
                            dispatch(clearBidNotifications());
                            setUploadData(initialUploadData);
                        }}
                        uploadData={uploadData}
                        mediaUrl={mediaUrl}
                        setUploadData={setUploadData}
                    />
                );
            case "tag":
                return (
                    <PlaceAdTag
                        campaign={campaign}
                        onUpload={completeUpload}
                        onClose={() => {
                            setStep("");
                            dispatch(clearBidNotifications());
                            setUploadData(initialUploadData);
                        }}
                        uploadData={uploadData}
                        mediaUrl={mediaUrl}
                        setUploadData={setUploadData}
                    />
                );
            default:
                return <SpinLoader size={15} color={"white"} />;
        }
    };

    const selectModal = () => {
        if (step === "select") {
            return (
                <PromoteSelectionModal
                    onSelect={v => setStep(v)}
                    onClose={() => {
                        setStep("");
                        setUploadData(initialUploadData);
                        dispatch(PROMOTE_FLOW.setSelectedMedia());
                        dispatch(PROMOTE_FLOW.setPromoting(false));
                    }}
                />
            );
        } else if (step !== "") {
            return (
                <FullScreenModal
                    closeModal={() => {
                        setStep("");
                        dispatch(clearBidNotifications());
                        setUploadData(initialUploadData);
                        dispatch(PROMOTE_FLOW.setPromoting(false));
                        dispatch(setPostMediaModalOpen(false));
                    }}
                >
                    {renderPage()}
                </FullScreenModal>
            );
        } else return null;
    };
    if (treatment)
        return (
            <Main>
                <PromoteButton
                    onClick={() => {
                        dispatch(
                            setMediaFilters({
                                ...initialMediaFilters,
                                selectedCampaigns: {
                                    [campaign.id]: campaign.campaignName
                                },
                                status: {
                                    pending: false,
                                    approved: true,
                                    rejected: false
                                }
                            })
                        );
                        dispatch(setPromoteFlowMedia(""));
                        dispatch(setCurrentCampaignId(campaign.id));
                        dispatch(fetchCampaignMediaRules(campaign.id));
                        dispatch(PROMOTE_FLOW.setPromoting(true));
                        setStep("select");
                    }}
                    inModal={inModal}
                />
                <AnimatePresence>{selectModal()}</AnimatePresence>
            </Main>
        );
    else return null;
};

export default PromoteFlowModal;
