import { GlobalState } from "../reducers";
import { DirectBuyStatus } from "./modules/direct-buy/types";
import { DirectBuyStepsEntitiesState } from "./modules/direct-buy-step/reducer";
import {
    DirectBuyDetailsModalState,
    DirectBuyErrorState,
    DirectBuySearchModalState,
    DirectBuyStepsExpandedState
} from "../ui/direct-buys/reducer";
import { SimpleMedia } from "../modals/SimpleMediaModal";
import {
    OpenBuy,
    OpenBuyState,
    OpenBuySubmission
} from "./modules/open-buys/types";
import { getStateForOpenBuy } from "./modules/open-buys/util";
import { getObjectKeys } from "src/util";
import { createSelector } from "reselect";

// UI Selectors

export function selectDirectBuysLoading(state: GlobalState): boolean {
    return state.ui.directBuys.directBuysLoading;
}

export function selectDirectBuyErrorState(
    state: GlobalState
): DirectBuyErrorState {
    return state.ui.directBuys.errorState;
}

export function selectDirectBuyStoredStatuses(
    state: GlobalState
): DirectBuyStatus[] {
    return state.ui.directBuys.storedStatuses;
}

export function selectDirectBuyStatusFilters(
    state: GlobalState
): DirectBuyStatus[] {
    return state.ui.directBuys.statusFilters;
}

export function selectDirectBuySearchModalState(
    state: GlobalState
): DirectBuySearchModalState {
    return state.ui.directBuys.modalState.search;
}

export function selectDirectBuyDetailsModalState(
    state: GlobalState
): DirectBuyDetailsModalState {
    return state.ui.directBuys.modalState.details;
}

export function selectDirectBuyCurrentMedia(
    state: GlobalState
): SimpleMedia | null {
    return state.ui.directBuys.currentMedia;
}

export function selectDirectBuyStepsExpandedState(
    state: GlobalState
): DirectBuyStepsExpandedState {
    return state.ui.directBuys.stepsExpandedState;
}

// Entity Selectors

export function selectDirectBuysById(state: GlobalState) {
    return state.entities.directBuys.byId;
}

export function selectDirectBuysAllIds(state: GlobalState) {
    return state.entities.directBuys.allIds;
}

export function selectDirectBuyStepEntities(
    state: GlobalState
): DirectBuyStepsEntitiesState {
    return state.entities.directBuySteps;
}

//open buys

export function selectOpenBuysById(state: GlobalState) {
    return state.entities.openBuys.byId;
}

export function selectOpenBuysAllIds(state: GlobalState) {
    return state.entities.openBuys.allIds;
}

export function selectMarketplaceBadges(state: GlobalState) {
    return state.ui.marketplace.marketplaceBadges;
}

export function selectLeaderboardEditorOpen(state: GlobalState) {
    return state.ui.marketplace.leaderboardEditorOpen;
}

export function selectOpenBuysAllIdsSorted(state: GlobalState) {
    let ids = state.entities.openBuys.allIds;
    let byId = state.entities.openBuys.byId;
    let submissions = state.entities.openBuySubmissions;
    let newBuys: number[] = [];
    let pending: number[] = [];
    let error: number[] = [];
    let ended: number[] = [];
    let viewed: number[] = [];
    ids.forEach(id => {
        let state = getStateForOpenBuy(
            byId[id],
            byId[id]?.openBuySubmissions?.map(id => submissions[id])
        );
        switch (state) {
            case OpenBuyState.new:
                newBuys.push(id);
                break;
            case OpenBuyState.rejected:
                error.push(id);
                break;
            case OpenBuyState.pending:
                pending.push(id);
                break;
            case OpenBuyState.viewed:
                viewed.push(id);
                break;
            case OpenBuyState.ended:
                ended.push(id);
                break;
            default:
                return;
        }
    });
    return newBuys.concat(error.concat(pending.concat(viewed.concat(ended))));
}

export function selectOpenBuyStepEntities(state: GlobalState) {
    return state.entities.instructionSteps || [];
}

export function selectOpenBuyStepsExpandedState(
    state: GlobalState
): DirectBuyStepsExpandedState {
    return state.ui.directBuys.openBuyStepsExpandedState;
}

export function selectSelectedAccounts(state: GlobalState) {
    return state.ui.directBuys.selectedAccounts;
}

export function selectOpenBuySubmissions(
    state: GlobalState,
    openBuyId?: number
) {
    let submissions = state.entities.openBuySubmissions;
    let ans: OpenBuySubmission[] = [];
    if (openBuyId && submissions) {
        state.entities.openBuys.byId[openBuyId]?.openBuySubmissions?.forEach(
            id => {
                if (submissions[id]) ans.push(submissions[id]);
            }
        );
    } else {
        Object.keys(submissions).forEach(id =>
            ans.push(submissions[parseInt(id)])
        );
    }
    return ans;
}

export const selectOpenBuySubmissionsCache = createSelector(
    (state: GlobalState) => state.entities.openBuySubmissions,
    (state: GlobalState, publisherId?: number) => publisherId,
    (submissions, publisherId) => {
        let ans: OpenBuySubmission[] = [];

        Object.keys(submissions).forEach(id =>
            ans.push(submissions[parseInt(id)])
        );

        return publisherId
            ? ans.filter(sub => sub.publisherId === publisherId)
            : ans;
    }
);

export function selectYourSets(state: GlobalState, openBuyId?: number) {
    const publisherId = localStorage.getItem("userid");
    let byId = state.entities.openBuySubmissionSets;
    return Object.keys(byId)
        .filter((id: string) => {
            const set = byId[parseInt(id)];
            return (
                set.publisherId.toString() === publisherId &&
                set.openBuyId === openBuyId
            );
        })
        .map(id => parseInt(id));
}

export function selectSetSubmissions(
    state: GlobalState,
    setId: number
): OpenBuySubmission[] {
    let byId = state.entities.openBuySubmissions;
    let ans: OpenBuySubmission[] = [];
    Object.keys(byId).forEach((id: string) => {
        if (byId[parseInt(id)].openBuySubmissionSetId === setId)
            ans.push(byId[parseInt(id)]);
    });

    return ans;
}

export function selectOtherSubmissions(
    state: GlobalState,
    submissions?: number[],
    openBuyId?: number
) {
    const publisherId = localStorage.getItem("userid");
    let byId = state.entities.openBuySubmissions;

    let ans: number[] = [];
    if (!submissions) {
        Object.keys(byId).forEach((id: string) => {
            if (byId[parseInt(id)].publisherId.toString() !== publisherId)
                ans.push(parseInt(id));
        });
    } else
        ans = submissions.filter(
            id => byId[id].publisherId.toString() !== publisherId
        );

    return openBuyId ? ans.filter(id => byId[id].openBuyId === openBuyId) : ans;
}

export function selectOtherSubmissionsTypeGrouping(
    state: GlobalState,
    submissions?: number[],
    openBuyId?: number
) {
    let mediaGroup: number[] = [];
    let linkGroup: number[] = [];

    const publisherId = localStorage.getItem("userid");
    let byId = state.entities.openBuySubmissions;

    (submissions || getObjectKeys(byId)).forEach((id, index) => {
        let sub = byId[id];
        if (
            sub.publisherId.toString() !== publisherId &&
            (!openBuyId || sub.openBuyId === openBuyId)
        ) {
            if (sub.customMedia) mediaGroup.push(id);
            if (sub.linkSubmission) linkGroup.push(id);
        }
    });

    return { mediaGroup, linkGroup };
}

export function selectAllAllowedPlatforms(
    state: GlobalState,
    openBuy: OpenBuy
) {
    let platforms: string[] = [];
    openBuy.instructionSteps?.forEach(stepId => {
        state.entities.instructionSteps[stepId].allowedPlatforms?.forEach(
            platform => {
                if (!platforms.includes(platform)) platforms.push(platform);
            }
        );
    });
    return platforms;
}
export const selectCachedFilters = (state: GlobalState) =>
    state.ui.directBuys.cachedFilters;

export const selectRawMediaStep = (state: GlobalState, openBuyId: number) => {
    const steps = state.entities.openBuys.byId[openBuyId].submissionSteps;

    for (const step of steps) {
        if (step.requiresRawMedia) return step;
    }
    return null;
};
