import { createSelector } from "reselect";
import { GlobalState } from "../reducers";
import { Campaign } from "./types";

export const selectCampaign = (state: GlobalState) => {
    const campaignId = state.ui.campaign.currentCampaignId;
    if (campaignId) {
        return state.entities.campaigns.byId[campaignId];
    }
};

export const selectScript = (state: GlobalState) => {
    const scriptId = state.ui.script.scriptModal;
    if (scriptId) {
        return state.entities.scripts[scriptId];
    }
};

export const currentCampaign = (state: GlobalState) =>
    state.ui.campaign.currentCampaignId;

const campaignEntities = (state: GlobalState) => state.entities.campaigns.byId;

const campaignAllIds = (state: GlobalState) => state.entities.campaigns.allIds;

export const getCampaignsWithSearch = createSelector(
    campaignEntities,
    campaignAllIds,
    (state: GlobalState, filter: string, maxItems: number) => ({
        filter,
        maxItems
    }),
    (campaignEntities, campaignAllIds, { filter, maxItems }) => {
        const allCampaigns = campaignAllIds.map(id => campaignEntities[id]);

        const filteredCampaigns = allCampaigns.filter(
            campaign =>
                !filter ||
                campaign.campaignName
                    .toLowerCase()
                    .includes(filter.toLowerCase())
        );

        return filteredCampaigns.slice(0, maxItems);
    }
);
export const getCampaignsById = createSelector(
    campaignEntities,
    (state: GlobalState, ids: number[]) => ids,
    (campaignEntities, ids) => {
        let temp: Campaign[] = [];
        ids.map(
            (id: number) =>
                campaignEntities[id] && temp.push(campaignEntities[id])
        );
        return temp;
    }
);

export const getMediaRules = createSelector(
    currentCampaign,
    campaignEntities,
    (state: GlobalState) => state.entities.mediaRules,
    (currentCampaign, campaigns, mediaRules) => {
        if (currentCampaign) {
            const rules = campaigns[currentCampaign].mediaRules;
            if (rules) {
                return rules.map(id => mediaRules[id]);
            }
        }
    }
);

export const getScripts = createSelector(
    currentCampaign,
    campaignEntities,
    (state: GlobalState) => state.entities.scripts,
    (currentCampaign, campaigns, scripts) => {
        if (currentCampaign) {
            const campaignScripts = campaigns[currentCampaign]?.scripts;
            if (campaignScripts) {
                return campaignScripts.map(id => scripts[id]);
            }
        }
    }
);

export const getAssets = createSelector(
    currentCampaign,
    campaignEntities,
    (state: GlobalState) => state.entities.assets,
    (currentCampaign, campaigns, assets) => {
        if (currentCampaign) {
            const campaignAssets = campaigns[currentCampaign]?.assets;
            if (campaignAssets) {
                return campaignAssets.map(id => assets[id]);
            }
        }
    }
);

export const getCaptions = createSelector(
    currentCampaign,
    campaignEntities,
    (state: GlobalState) => state.entities.captions,
    (currentCampaign, campaigns, captions) => {
        if (currentCampaign) {
            const campaignCaptions = campaigns[currentCampaign].captions;
            if (campaignCaptions) {
                return campaignCaptions.map(id => captions[id]);
            }
        }
    }
);

export const getMedias = createSelector(
    currentCampaign,
    campaignEntities,
    (state: GlobalState) => state.entities.medias,
    (currentCampaign, campaigns, medias) => {
        if (currentCampaign) {
            const campaignMedias = campaigns[currentCampaign]?.medias;
            if (campaignMedias) {
                return campaignMedias.map(id => medias[id]);
            }
        }
    }
);

export const getHighlights = createSelector(
    currentCampaign,
    campaignEntities,
    (state: GlobalState) => state.entities.highlights.byId,
    (state: GlobalState) => state.ui.placements.placementConfig,
    (currentCampaign, campaigns, highlights, placementConfig) => {
        if (currentCampaign) {
            const campaignHighlights = campaigns[currentCampaign].highlights;
            if (campaignHighlights) {
                const allHighlights = campaignHighlights.map(
                    id => highlights[id]
                );

                if (Object.keys(placementConfig).length) {
                    const filteredHighlights = allHighlights.filter(
                        highlight => placementConfig[highlight.placement.type]
                    );
                    if (campaignHighlights.length && !filteredHighlights.length)
                        return null;
                    return filteredHighlights;
                }
                return allHighlights;
            }
        }
    }
);

export function currentMediaTab(state: GlobalState) {
    return state.ui.campaign.currentMediaTab;
}

export function campaignsLoadingState(state: GlobalState): boolean {
    return state.ui.campaign.campaignsLoading;
}
export const fetchOrderedCampaigns = createSelector(
    (state: GlobalState) => state.entities.campaigns.byId,
    (state: GlobalState) => state.entities.campaigns.allIds,
    (campaignsState, campaignsIds) => {
        const orderedCampaigns = campaignsIds.map((id: number) => {
            return campaignsState[id];
        });

        return orderedCampaigns;
    }
);

export const selectCampaignCountryRewardById = (
    state: GlobalState,
    id: number
) => {
    const campaign = state.entities.campaigns.byId[id];
    if (!campaign) return;

    return {
        countryPlatformReward: campaign.countryPlatformReward,
        rewardType: campaign.rewardType
    };
};

export const getCampaignById = createSelector(
    (state: GlobalState) => state.entities.campaigns.byId,
    (state: GlobalState, campaignId: number) => campaignId,
    (campaignsState, campaignId) => {
        return campaignsState[campaignId];
    }
);
