import { CampaignFilters, Country } from "src/campaigns/types";
import {
    FETCH_CAMPAIGN,
    FETCH_CAMPAIGNS,
    FETCH_CAMPAIGN_ACCESS,
    REQUEST_CAMPAIGN_ACCESS,
    REQUEST_CAMPAIGN_NOTIFICATION
} from "../../campaigns/actions";
import { FETCH_CAMPAIGN_ASSETS } from "../../campaigns/assets/actions";
import { FETCH_CAMPAIGN_CAPTIONS } from "../../campaigns/captions/actions";
import { FETCH_CAMPAIGN_HIGHLIGHTS } from "../../campaigns/highlights/actions";
import { FETCH_CAMPAIGN_MEDIA_RULES } from "../../campaigns/media-rules/actions";
import {
    FETCH_CAMPAIGN_MEDIAS,
    FETCH_CAMPAIGN_PUBLIC_MEDIAS
} from "../../campaigns/medias/actions";
import { FETCH_CAMPAIGN_SCRIPTS } from "../../campaigns/scripts/actions";
import { Action } from "../../types";
import {
    CAMPAIGN_MAX_ITEMS,
    SET_CURRENT_CAMPAIGN_ID,
    SET_CAMPAIGN_MEDIA_MODAL_ITEMS,
    MediaTab,
    SET_CURRENT_MEDIA_TAB,
    SET_CURRENT_LOADED_MEDIAS,
    SET_CAMPAIGN_FILTERS,
    SET_CAMPAIGN_SORT,
    FETCH_CAMPAIGN_GENRES,
    FETCH_CAMPAIGN_REGIONS
} from "./actions";

export interface CampaignsUIState {
    maxItems: number;
    currentCampaignId: number | null;
    currentMediaTab: MediaTab;
    currentLoadedMedias: number[];
    campaignsLoading: boolean;
    highlightsLoading: boolean;
    mediaRulesLoading: boolean;
    scriptsLoading: boolean;
    assetsLoading: boolean;
    captionsLoading: boolean;
    mediasLoading: boolean;
    accessLoading: boolean;
    mediaModalItems: number[];
    genresLoading: boolean;
    regionsLoading: boolean;
    sortBy: string;
    filters: CampaignFilters;
    displayGenres: string[];
    displayRegions: Country[];
}

export const initialCampaignFilters: CampaignFilters = {
    bidLow: 0.0,
    bidHigh: 10.0,
    genres: [],
    budgetLow: false,
    budgetNotLow: false,
    regions: []
};

const initialCampaignsUIState: CampaignsUIState = {
    maxItems: 4,
    currentCampaignId: null,
    currentMediaTab: "Stories",
    currentLoadedMedias: [] as number[],
    campaignsLoading: true,
    highlightsLoading: true,
    mediaRulesLoading: true,
    scriptsLoading: true,
    assetsLoading: true,
    captionsLoading: true,
    mediasLoading: true,
    accessLoading: true,
    mediaModalItems: [] as number[],
    genresLoading: true,
    regionsLoading: true,
    displayGenres: [],
    displayRegions: [],
    sortBy: "Recommended",
    filters: initialCampaignFilters
};

function incrementMaxCampaignItems(
    maxItems: number,
    maxCampaignCount: number
): number {
    if (maxItems + 4 > maxCampaignCount) return maxItems + 4;
    else return maxCampaignCount;
}

const campaignsUIReducer = (
    state = initialCampaignsUIState,
    action: Action
) => {
    switch (action.type) {
        case FETCH_CAMPAIGNS.REQUEST:
            return { ...state, campaignsLoading: true };
        case FETCH_CAMPAIGNS.SUCCESS:
            return { ...state, campaignsLoading: false };
        case FETCH_CAMPAIGN.REQUEST:
            return {
                ...state,
                highlightsLoading: true,
                mediaRulesLoading: true,
                scriptsLoading: true,
                assetsLoading: true,
                captionsLoading: true,
                mediasLoading: true,
                accessLoading: true,
                currentCampaignId: action.payload.campaignId
            };
        case SET_CURRENT_CAMPAIGN_ID:
            return { ...state, currentCampaignId: action.payload.campaignId };
        case FETCH_CAMPAIGN_HIGHLIGHTS.REQUEST:
            return { ...state, highlightsLoading: true };
        case FETCH_CAMPAIGN_HIGHLIGHTS.SUCCESS:
            return { ...state, highlightsLoading: false };
        case FETCH_CAMPAIGN_MEDIA_RULES.REQUEST:
            return { ...state, mediaRulesLoading: true };
        case FETCH_CAMPAIGN_MEDIA_RULES.SUCCESS:
            return { ...state, mediaRulesLoading: false };
        case FETCH_CAMPAIGN_SCRIPTS.REQUEST:
            return { ...state, scriptsLoading: true };
        case FETCH_CAMPAIGN_SCRIPTS.SUCCESS:
            return { ...state, scriptsLoading: false };
        case FETCH_CAMPAIGN_ASSETS.REQUEST:
            return { ...state, assetsLoading: true };
        case FETCH_CAMPAIGN_ASSETS.SUCCESS:
            return { ...state, assetsLoading: false };
        case FETCH_CAMPAIGN_CAPTIONS.REQUEST:
            return { ...state, captionsLoading: true };
        case FETCH_CAMPAIGN_CAPTIONS.SUCCESS:
            return { ...state, captionsLoading: false };
        case FETCH_CAMPAIGN_MEDIAS.REQUEST:
            return { ...state, mediasLoading: true };
        case FETCH_CAMPAIGN_MEDIAS.SUCCESS:
            return { ...state, mediasLoading: false };
        case FETCH_CAMPAIGN_PUBLIC_MEDIAS.REQUEST:
            return { ...state, mediasLoading: true };
        case FETCH_CAMPAIGN_PUBLIC_MEDIAS.SUCCESS:
            return { ...state, mediasLoading: false };
        case REQUEST_CAMPAIGN_ACCESS:
            return { ...state, accessLoading: true };
        case FETCH_CAMPAIGN_ACCESS.REQUEST:
            return { ...state, accessLoading: true };
        case FETCH_CAMPAIGN_ACCESS.SUCCESS:
            return { ...state, accessLoading: false };
        case REQUEST_CAMPAIGN_NOTIFICATION:
            return { ...state, accessLoading: true };
        case CAMPAIGN_MAX_ITEMS:
            return {
                ...state,
                maxItems: incrementMaxCampaignItems(
                    state.maxItems,
                    action.payload
                )
            };
        case SET_CAMPAIGN_MEDIA_MODAL_ITEMS:
            return { ...state, mediaModalItems: action.payload };
        case SET_CURRENT_MEDIA_TAB:
            return { ...state, currentMediaTab: action.payload };
        case SET_CURRENT_LOADED_MEDIAS:
            return { ...state, currentLoadedMedias: action.payload };
        case SET_CAMPAIGN_SORT:
            return { ...state, sortBy: action.payload };
        case SET_CAMPAIGN_FILTERS:
            return { ...state, filters: action.payload };
        case FETCH_CAMPAIGN_GENRES.REQUEST:
            return { ...state, genresLoading: true };
        case FETCH_CAMPAIGN_GENRES.SUCCESS:
            return {
                ...state,
                displayGenres: action.payload,
                genresLoading: false
            };
        case FETCH_CAMPAIGN_GENRES.FAILURE:
            return {
                ...state,
                displayGenres: null,
                genresLoading: false
            };
        case FETCH_CAMPAIGN_REGIONS.REQUEST:
            return { ...state, regionsLoading: true };
        case FETCH_CAMPAIGN_REGIONS.SUCCESS:
            return {
                ...state,
                displayRegions: action.payload,
                regionsLoading: false
            };
        case FETCH_CAMPAIGN_REGIONS.FAILURE:
            return {
                ...state,
                displayRegions: null,
                regionsLoading: false
            };
        default:
            return state;
    }
};

export default campaignsUIReducer;
