import { Action } from "src/types";
import { PublicBuysFilter } from "../components/PublicBuysFilters";
import { PBPreview, PublicBuysScreenshot } from "./reducer";

export const PUBLIC_BUYS = {
    REQUEST: "PUBLIC_BUYS_REQUEST",
    SET: "SET_PUBLIC_BUYS",
    UPDATE: "UPDATE_PUBLIC_BUYS",
    FAILURE: "PUBLIC_BUYS_FAILURE",
    LOADING: "PUBLIC_BUYS_LOADING"
};

export const fetchPublicBuys = (
    search: string,
    filters: PublicBuysFilter[]
): Action => ({
    type: PUBLIC_BUYS.REQUEST,
    payload: { search, filters }
});

export const setPublicBuys = (buys: PBPreview[]): Action => ({
    type: PUBLIC_BUYS.SET,
    payload: buys
});

export const fetchPublicBuysFailure = (error: string): Action => ({
    type: PUBLIC_BUYS.FAILURE,
    payload: error
});

export const updatePublicBuys = (buy: PBPreview): Action => ({
    type: PUBLIC_BUYS.UPDATE,
    payload: buy
});

export const setPublicBuysLoading = (loading: boolean): Action => ({
    type: PUBLIC_BUYS.LOADING,
    payload: loading
});
export const SCREENSHOTS = {
    REQUEST: "SCREENSHOTS_REQUEST",
    SET: "SCREENSHOTS_SET",
    UPDATE: "SCREENSHOTS_UPDATE",
    FAILURE: "SCREENSHOTS_FAILURE"
};

export const fetchScreenshots = (
    search: string,
    showUsed?: boolean
): Action => ({
    type: SCREENSHOTS.REQUEST,
    payload: { search, showUsed }
});

export const setScreenshots = (
    screenshots: PublicBuysScreenshot[]
): Action => ({
    type: SCREENSHOTS.SET,
    payload: screenshots
});

export const updateScreenshots = (
    screenshot: PublicBuysScreenshot
): Action => ({
    type: SCREENSHOTS.UPDATE,
    payload: screenshot
});

export const fetchScreenshotsFailure = (error: string): Action => ({
    type: SCREENSHOTS.REQUEST,
    payload: error
});

export const HIDDEN_SCREENSHOTS = {
    SET: "SET_HIDDEN_SCREENSHOTS",
    REMOVE: "REMOVE_HIDDEN_SCREENSHOTS",
    CLEAR: "CLEAR_HIDDEN_SCREENSHOTS"
};

export const setHiddenScreenshots = (hidden: string[]): Action => ({
    type: HIDDEN_SCREENSHOTS.SET,
    payload: hidden
});

export const removeHiddenScreenshots = (hidden: string): Action => ({
    type: HIDDEN_SCREENSHOTS.REMOVE,
    payload: hidden
});

export const clearHiddenScreenshots = (): Action => ({
    type: HIDDEN_SCREENSHOTS.CLEAR,
    payload: {}
});

export const ASSIGNED_SCREENSHOTS = {
    ADD: "ADD_ASSIGNED_SCREENSHOTS",
    REMOVE: "REMOVE_ASSIGNED_SCREENSHOTS",
    CLEAR: "CLEAR_ASSIGNED_SCREENSHOTS"
};

export const addAssignedScreenshots = (
    screenshotId: number,
    directBuyStepId: number
): Action => ({
    type: ASSIGNED_SCREENSHOTS.ADD,
    payload: { screenshotId, directBuyStepId }
});

export const removeAssignedScreenshots = (directBuyStepId: number): Action => ({
    type: ASSIGNED_SCREENSHOTS.REMOVE,
    payload: directBuyStepId
});

export const clearAssignedScreenshots = (): Action => ({
    type: ASSIGNED_SCREENSHOTS.CLEAR,
    payload: {}
});
