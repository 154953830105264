import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";
import { Payment } from "src/types/Payment";
import { convertSnakeCaseToTitleCase } from "src/utils/functions/helpers";

export const paymentTypesState = (state: GlobalState) =>
    state.entities.paymentTypes;

export const getPaymentTypes = createSelector(
    paymentTypesState,
    paymentTypes => {
        return paymentTypes.allIds.map(
            paymentTypeId => paymentTypes.byId[paymentTypeId]
        );
    }
);

export const getPaymentMapById = createSelector(
    paymentTypesState,
    paymentState => {
        const map: Map<any, any> = new Map();

        paymentState.allIds.forEach(paymentId => {
            const payment = paymentState.byId[paymentId];
            if (!map.has(paymentId)) map.set(paymentId, payment.type);
        });

        return map;
    }
);

export const getPaymentMapByType = createSelector(
    paymentTypesState,
    paymentState => {
        const map: Map<any, any> = new Map();

        paymentState.allIds.forEach(paymentId => {
            const payment = paymentState.byId[paymentId];
            if (!map.has(payment.type)) map.set(payment.type, paymentId);
        });

        return map;
    }
);

export const getPaymentIdFromType = createSelector(
    getPaymentMapByType,
    (state: GlobalState, type: string) => type,
    (paymentMap, type) => {
        if (type && paymentMap.has(type)) return paymentMap.get(type);
    }
);

export const getPaymentTypeFromId = createSelector(
    getPaymentMapById,
    (state: GlobalState, id: number | null) => id,
    (paymentMap, id) => {
        if (!id || !paymentMap.has(id)) return null;
        return paymentMap.get(id);
    }
);
