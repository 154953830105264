import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { freezeBody, thawBody } from "../util";
import { motion } from "framer-motion";
import { hideScrollbar } from "src/utils/styles/snippets";
import { breakpoints, colors } from "src/constants";

const Parent = styled(motion.div)`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    isolation: isolate;
`;
const FlexWrap = styled.div<{ maxWidth?: string }>`
    display: flex;
    width: 85vw;
    max-width: ${props => props.maxWidth || "1600px"};
    height: auto;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 12px;
    color: ${colors.white};
    @media (max-width: ${breakpoints.tablet}px) {
        padding: 12px 18px 12px;
        color: ${colors.black};
        background-color: ${colors.white};
        width: 100%;
        max-width: none;
        border-bottom: 1px solid ${colors.mediumTeal};
    }
`;
const Title = styled.h2`
    font-size: 1rem;
    padding: 0px;
    display: block;
    margin: 0px;
`;
const BackBtn = styled.button`
    background-color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const CloseBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    border-radius: 50%;
    padding: 8px;
    background-color: ${colors.white};
    & > img {
        width: 14px;
        height: 14px;
    }
`;

const Child = styled(motion.div)<{ maxWidth?: string }>`
    width:85vw
    max-height: 85vh;
    max-width: ${props => props.maxWidth || "1600px"};
    position: relative;
    padding: 24px;
    overflow-y: hidden;
    background-color: white;
    border-radius: 6px;
    ${props => props.theme.isMobile && hideScrollbar()}
    
    @media(max-width: ${breakpoints.tablet}px) {
        width: 100%;
        height: 100%;
        max-height: none;
        max-width: none;
        border-radius: 0px;
    }
`;

interface Props {
    children: React.ReactNode;
    closeModal?: VoidFunction;
    onScroll?: (e: number) => void;
    keepFrozenOnClose?: boolean;
    title?: string;
    isBack?: boolean;
    onBack?: () => void;
    noAnimation?: true;
    maxWidth?: string;
}

const LargeModal = (props: Props) => {
    const {
        children,
        closeModal,
        onScroll,
        keepFrozenOnClose,
        title,
        onBack,
        isBack,
        noAnimation,
        maxWidth
    } = props;
    const child = useRef<HTMLDivElement>(null);

    useEffect(() => {
        freezeBody();
        return () => {
            if (!keepFrozenOnClose) thawBody();
        };
    }, []);

    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
        const node = child.current;
        if (node && onScroll) {
            onScroll(node.scrollHeight - node.scrollTop);
        }
    };

    return (
        <Parent
            onClick={closeModal}
            key="full-screen-modal-parent"
            initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            animate={
                noAnimation ? false : { backgroundColor: "rgba(0, 0, 0, 0.8)" }
            }
            exit={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            transition={{ duration: 0.3 }}
        >
            <FlexWrap maxWidth={maxWidth}>
                {isBack && (
                    <BackBtn
                        onClick={e => {
                            e.stopPropagation();
                            onBack && onBack();
                        }}
                    >
                        <img src="/back-button.svg" alt="back" />
                    </BackBtn>
                )}
                <Title>{title}</Title>
                <CloseBtn
                    onClick={e => {
                        e.stopPropagation();
                        closeModal && closeModal();
                    }}
                >
                    <img src="/black_close.svg" alt="close modal" />
                </CloseBtn>
            </FlexWrap>
            <Child
                maxWidth={maxWidth}
                ref={child}
                onScroll={handleScroll}
                key="full-screen-modal"
                onClick={e => e.stopPropagation()}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                {children}
            </Child>
        </Parent>
    );
};

export default LargeModal;
