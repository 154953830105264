import { NormalizedAction } from "../../types";
import { Caption } from "../types";
import { FETCH_CAMPAIGN_CAPTIONS } from "./actions";

export interface CaptionsEntitiesState {
    [id: number]: Caption;
}

const initialCaptionsState: CaptionsEntitiesState = {};

const captionsEntitiesReducer = (
    state = initialCaptionsState,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_CAMPAIGN_CAPTIONS.SUCCESS:
            return { ...state, ...action.response.entities.captions };
        default:
            return state;
    }
};

export default captionsEntitiesReducer;
