import React, { useState } from "react";
import {
    Main,
    Top,
    Description,
    ResourceContainer,
    ResourceDescription
} from "./styledComponents";
import CircleIcon from "../../../components/CircleIcon";
import { CustomLoading, TextLoading } from "../../../components/SkeletonLoader";

interface Props {
    expanded: boolean;
}

const DirectBuyDetailsInstructionsLoading = ({ expanded }: Props) => {
    const [rows] = useState(() => {
        const sections = Math.random() > 0.5 ? [0, 1, 2] : [0, 1];
        return sections.map(() => {
            const subsections = Math.random() > 0.5 ? [0, 1, 2] : [0, 1];
            return subsections.map(() => {
                const randomInt = Math.floor(Math.random() * 3);
                if (randomInt === 2) {
                    return <CustomLoading height="60px" />;
                } else if (randomInt === 1) {
                    return <CustomLoading height="170px" />;
                } else {
                    return (
                        <>
                            <CustomLoading height="160px" />
                            <div style={{ height: "10px" }} />
                            <CustomLoading height="48px" />
                        </>
                    );
                }
            });
        });
    });
    if (!expanded) {
        return null;
    }
    return (
        <>
            {rows.map((subsections, index) => (
                <Main key={index} indent={0} background={"white"}>
                    <Top clickable={false}>
                        <CircleIcon type="loading" />
                        <Description>
                            <TextLoading height={16} width={60} />
                        </Description>
                    </Top>
                    {subsections.map((subsection, index) => (
                        <ResourceContainer key={index}>
                            <ResourceDescription>
                                <TextLoading height={14} width={20} />
                            </ResourceDescription>
                            {subsection}
                        </ResourceContainer>
                    ))}
                </Main>
            ))}
        </>
    );
};

export default DirectBuyDetailsInstructionsLoading;
