import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "src/reducers";
import { NormalizedAction } from "src/types";

export const FETCH_PUBLISHER_FLAGS = {
    REQUEST: "FETCH_PUBLISHER_FLAGS_REQUEST",
    SUCCESS: "FETCH_PUBLISHER_FLAGS_SUCCESS",
    FAILURE: "FETCH_PUBLISHER_FLAGS_REQUEST"
};

export const fetchPublisherFlagsSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>
): NormalizedAction => ({
    type: FETCH_PUBLISHER_FLAGS.SUCCESS,
    response
});
