import React from "react";
import { Route, Switch } from "react-router";
import { Profile } from "../../types";
import ConnectionsPage, { ConnectionsTabState } from "./page";

interface Props {
    profile: Profile;
}

const ConnectionsRouter = ({ profile }: Props) => {
    return (
        <Switch>
            <Route
                path={"/profile/connections/requests"}
                render={() => (
                    <ConnectionsPage
                        profile={profile}
                        tabState={ConnectionsTabState.requests}
                    />
                )}
            />
            <Route
                path={"/profile/connections"}
                render={() => (
                    <ConnectionsPage
                        profile={profile}
                        tabState={ConnectionsTabState.all}
                    />
                )}
            />
        </Switch>
    );
};

export default ConnectionsRouter;
