import React, { useState } from "react";
import { connect } from "react-redux";
import { Shoutout } from "../types";
import { countdownModal } from "src/ui/modals/actions";
import styled from "styled-components";
import { ShoutoutState } from "../utils/evaluateShoutoutState";
import Countdown from "react-countdown-now";
import { colors, fonts } from "../../../constants";

const VerticalCenter = styled.div`
    display: flex;
    align-items: center;
`;

const Main = styled(VerticalCenter)`
    padding: 20px 15px;
    flex-direction: column;
`;

const Title = styled(VerticalCenter)`
    font-size: ${fonts.title}px;
    line-height: 1em;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    line-height: 18px;
    margin-top: 10px;
    color: ${colors.primaryGray};
`;

const QuestionCircle = styled(VerticalCenter)`
    justify-content: center;
    height: 22px;
    width: 22px;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 100%;
    font-size: ${fonts.subtitle}px;
    line-height: 1em;
    font-weight: bold;
    color: ${colors.secondaryGray};
    margin-left: 6px;
    cursor: pointer;
`;

interface Props {
    shoutout: Shoutout;
    state: ShoutoutState;
    countdownModal: () => void;
}

const ShoutoutDetailsMessage = ({ shoutout, state, countdownModal }: Props) => {
    const [timesUp, setTimesUp] = useState(false);

    const endTime = Date.parse(shoutout.start_time) + 1000 * 60 * 60 * 24;

    function renderContent() {
        if (state === ShoutoutState.Unreserved) {
            // fill this in when the shoutouts product changes
            return null;
        } else if (timesUp || state === ShoutoutState.Blocking) {
            return (
                <Title style={{ color: colors.primaryRed }}>Time's up!</Title>
            );
        } else if (state === ShoutoutState.Reserved) {
            return (
                <Title>
                    <Countdown
                        date={endTime}
                        daysInHours={true}
                        onComplete={() => setTimesUp(true)}
                    />
                    <QuestionCircle onClick={countdownModal}>
                        <div>?</div>
                    </QuestionCircle>
                </Title>
            );
        } else if (state === ShoutoutState.Confirming) {
            return (
                <>
                    <Title>Verifying!</Title>
                    <Subtitle>
                        Coins will be added to your account once we've verified
                        the views in your screenshots!
                    </Subtitle>
                </>
            );
        } else if (state === ShoutoutState.Confirmed) {
            return (
                <>
                    <Title style={{ color: colors.secondaryGreen }}>
                        Completed!
                    </Title>
                    <Subtitle style={{ color: colors.secondaryGreen }}>
                        Your views have been verified and coins have been
                        deposited into your account.
                    </Subtitle>
                </>
            );
        } else if (state === ShoutoutState.Rejected) {
            return (
                <>
                    <Title style={{ color: colors.primaryRed }}>Failed!</Title>
                    <Subtitle style={{ color: colors.primaryRed }}>
                        We weren’t able to verify your views. Contact support if
                        there’s been a mistake.
                    </Subtitle>
                </>
            );
        } else {
            return null;
        }
    }

    return <Main>{renderContent()}</Main>;
};

const mapDispatchToProps = (dispatch: any) => ({
    countdownModal: () => dispatch(countdownModal())
});

export default connect(null, mapDispatchToProps)(ShoutoutDetailsMessage);
