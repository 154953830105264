import styled from "styled-components";

export default styled.img`
    height: 56px;
    width: 56px;
    min-width: 56px;
    border-radius: 6px;
    margin-right: 12px;
    object-fit: cover;
`;
