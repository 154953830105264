import React, { useState } from "react";
import { Contest, ContestPrize } from "../types";
import PrizeList from "./PrizeList";
import ClaimPrize from "./ClaimPrize";

interface Props {
    contest: Contest;
    push: (path: string) => void;
}

const Prizes = ({ contest, push }: Props) => {
    const [claimPrize, setClaimPrize] = useState<ContestPrize | null>(null);

    return claimPrize ? (
        <ClaimPrize prize={claimPrize} close={() => setClaimPrize(null)} />
    ) : (
        <PrizeList
            contest={contest}
            push={push}
            setClaimPrize={setClaimPrize}
        />
    );
};

export default Prizes;
