import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { agreedToRules } from "../profile/actions";
import { colors, fonts } from "../constants";
import MainContainer from "src/components/MainContainer";
import { AnimatePresence, motion } from "framer-motion";
import { responsiveContainer } from "src/utils/styles/snippets";

const animations = {
    initial: { opacity: 0, y: 100 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -100 }
};

const Main = styled(motion.div)`
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    ${responsiveContainer};
`;

const Icon = styled.img`
    height: 25px;
    width: 27px;
`;

const List = styled.ol`
    padding-top: 15px;
    margin-bottom: 0px;
`;

const ListItem = styled.li`
    padding: 15px 0px;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: ${fonts.default};
`;

const TopTitle = styled(Title)`
    padding: 15px 0px;
    font-size: ${fonts.title}px;
`;

const Subtitle = styled.div`
    margin-top: 5px;
    font-size: ${fonts.subtitle}px;
`;

const ExImg = styled.img`
    margin-top: 10px;
    max-height: 168px;
    max-width: 272px;
`;

const ContImg = styled.img`
    margin-top: 10px;
    max-height: 300px;
    max-width: 272px;
`;

const BottomText = styled.div`
    padding: 20px 0px;
    font-size: ${fonts.subtitle}px;
`;

const Agreement = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 20px;
`;

const RadioButton = styled.div`
    height: 26px;
    width: 26px;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Unchecked = styled(RadioButton)`
    border: 1px solid ${colors.primaryGreen};
    cursor: pointer;
`;

const Checked = styled(RadioButton)`
    background-color: ${colors.primaryGreen};
    cursor: pointer;
`;

interface Props {
    agreedToRules: () => void;
    push: (path: string) => void;
}

const RulesList = ({ agreedToRules, push }: Props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [checked, setChecked] = useState(false);

    const buttonStyle = { width: "100%", height: "50px" };

    function renderButton() {
        if (checked) {
            return (
                <Checked onClick={() => setChecked(false)}>
                    <img
                        style={{ height: "12px", width: "16px" }}
                        src={"/white_check/white_check@2x.png"}
                    />
                </Checked>
            );
        } else {
            return <Unchecked onClick={() => setChecked(true)} />;
        }
    }

    return (
        <AnimatePresence>
            <Main
                variants={animations}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 1 }}
            >
                <div>
                    <Icon src={"/icon_nux_green/icon_nux_green@2x.png"} />
                    <TopTitle>Platform Rules & Etiquette</TopTitle>
                </div>
                <div>
                    To get started with pay-per-install campaigns on The Plug,
                    please review a few simple rules. <br /> Follow these and
                    you'll be successful with us!
                </div>
                <List>
                    <ListItem>
                        <Title>No Incentivized Traffic</Title>
                        <Subtitle>
                            We do not allow any incentivized traffic. Please
                            view our examples below.
                        </Subtitle>
                        <ExImg
                            style={{ cursor: "pointer" }}
                            onClick={() => push("/rules/examples")}
                            src={
                                "/incentivized_examples/incentivized_examples@2x.png"
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <Title>No Content Blockers</Title>
                        <Subtitle>
                            We do not allow content to be blocked that’s driving
                            traffic to The Plug app.
                        </Subtitle>
                        <ContImg
                            src={"/content_blocker/content_blocker@2x.png"}
                        />
                    </ListItem>
                    <ListItem>
                        <Title>Ads policy</Title>
                        <Subtitle>
                            Any ads you use must be explicitly approved by The
                            Plug team. Please include ad disclosures "clearly
                            and conspicuously" in all ads.
                        </Subtitle>
                    </ListItem>
                    <ListItem>
                        <Title>Don't send to friends or followers</Title>
                        <Subtitle>
                            Never message our links to your friends or followers
                            and ask them to download the app. This will get you
                            banned.
                        </Subtitle>
                    </ListItem>
                    <ListItem>
                        <Title>Ads Submissions</Title>
                        <Subtitle>
                            You are allowed to create ads and submit them for
                            review by The Plug team. Do not post these ads
                            before they have been approved.
                        </Subtitle>
                    </ListItem>
                    <ListItem>
                        <Title>No Manufactured Activity</Title>
                        <Subtitle>
                            Any fake users, downloads, or traffic will be
                            detected.
                        </Subtitle>
                    </ListItem>
                    <ListItem>
                        <Title>English Speaking Countries</Title>
                        <Subtitle>
                            If your traffic primarily comes from
                            India/Brazil/Indonesia or other non English speaking
                            countries, we will have to shut your account down.
                        </Subtitle>
                    </ListItem>
                </List>
                <BottomText>
                    I understand and agree to follow all rules. If broken, I
                    will forfeit my account balance and be subject to removal.
                </BottomText>
                <Agreement>
                    {renderButton()}
                    <Title>I AGREE TO FOLLOW THE RULES</Title>
                </Agreement>
                <Button
                    disabled={!checked}
                    style={buttonStyle}
                    onClick={agreedToRules}
                >
                    Ok, Let's Go!
                </Button>
            </Main>
        </AnimatePresence>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    agreedToRules: () => dispatch(agreedToRules()),
    push: (path: string) => dispatch(push(path))
});

export default connect(null, mapDispatchToProps)(RulesList);
