import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import { GlobalState } from "../../reducers";
import { removeNotification } from "./actions";
import { NotificationViewModel } from "./reducer";

const Wrapper = styled.div`
    position: fixed;
    z-index: 3000;
    top: 0;
    left: 0;
    right: 0;
`;

interface StyleProps {
    backgroundColor: string;
}

const NotificationBar = styled(motion.div)<StyleProps>`
    background-color: ${props => props.backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    position: fixed;

    height: 44px;
    width: 100%;
`;

const Text = styled.div`
    font-weight: 700;
    color: white;
    font-size: ${fonts.subtitle}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface Props {
    notifications: NotificationViewModel[];
    removeNotification: () => void;
    manual?: NotificationViewModel;
}

const Notification = ({ notifications, removeNotification, manual }: Props) => {
    useEffect(() => {
        if (notifications.length > 0) setTimeout(removeNotification, 2000);
    }, [notifications]);

    const variants = {
        visible: { opacity: 1, height: 44 },
        hidden: { opacity: 0, height: 0 },
        exit: { opacity: 0, height: 0 }
    };
    if (manual)
        return (
            <Wrapper>
                <AnimatePresence>
                    <NotificationBar
                        backgroundColor={manual.color || colors.primaryBlue}
                        key={`notification${manual.text}${manual.timestamp}`}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={variants}
                        transition={{ duration: 0.2 }}
                    >
                        <Text>{manual.text}</Text>
                    </NotificationBar>
                </AnimatePresence>
            </Wrapper>
        );
    return (
        <Wrapper>
            <AnimatePresence>
                {notifications.map((notification, index) => (
                    <NotificationBar
                        backgroundColor={
                            notification.color || colors.primaryBlue
                        }
                        key={`notification${notification.text}${notification.timestamp}`}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={variants}
                        transition={{ duration: 0.2 }}
                    >
                        <Text>{notification.text}</Text>
                    </NotificationBar>
                ))}
            </AnimatePresence>
        </Wrapper>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    notifications: state.ui.notification.notifications
});

const mapDispatchToProps = (dispatch: any) => ({
    removeNotification: () => dispatch(removeNotification())
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
