import { combineReducers } from "redux";
import { NormalizedAction } from "src/types";
import { PaymentType } from "../types";
import { FETCH_PAYMENT_TYPES } from "./actions";

interface ById {
    [id: number]: PaymentType;
}

export interface PaymentTypesEntitiesState {
    byId: ById;
    allIds: number[];
}

const initialPaymentTypesState: PaymentTypesEntitiesState = {
    byId: {},
    allIds: []
};

const byId = (
    state = initialPaymentTypesState.byId,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_PAYMENT_TYPES.SUCCESS:
            return { ...state, ...action.response.entities.paymentTypes };
        default:
            return state;
    }
};

const allIds = (
    state = initialPaymentTypesState.allIds,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_PAYMENT_TYPES.SUCCESS:
            return action.response.result;
        default:
            return state;
    }
};

const paymentTypesEntitiesReducer = combineReducers<PaymentTypesEntitiesState>({
    byId,
    allIds
});

export default paymentTypesEntitiesReducer;
