import React, { useState } from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../../constants";
import { Channel } from "../../types";
import InvitationInput from "./InvitationInput";
import MessageInput from "./MessageInput";

const Main = styled.div`
    margin: 0px 15px 20px;
    padding: 15px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;

    @media (max-width: ${breakpoints.mobile}px) {
        margin: 0px;
        padding: 12px 15px 30px;
        border: none;
        border-radius: 0px;
        border-top: ${colors.mediumTeal} solid 1px;
    }
`;

interface Props {
    channel: Channel;
    setChannel: (channel: Channel) => void;
    goBack: VoidFunction;
    sdk: any;
    sendMessage: any;
    sendFileMessage: any;
}

const ChannelInput = ({
    channel,
    setChannel,
    goBack,
    sdk,
    sendMessage,
    sendFileMessage
}: Props) => {
    const [message, setMessage] = useState("");

    if (channel.sendBirdData.myMemberState === "invited") {
        return (
            <Main>
                <InvitationInput
                    channel={channel}
                    deleteCallback={() => {
                        channel.sendBirdData.declineInvitation(
                            (response: any, error: any) => {
                                if (error) {
                                    window.alert(error);
                                } else {
                                    goBack();
                                }
                            }
                        );
                    }}
                    acceptCallback={() => {
                        channel.sendBirdData.acceptInvitation(
                            (newChannel: any, error: any) => {
                                if (error) {
                                    window.alert(error);
                                } else {
                                    setChannel({
                                        ...channel,
                                        sendBirdData: newChannel
                                    });
                                }
                            }
                        );
                    }}
                />
            </Main>
        );
    } else {
        return (
            <Main>
                <MessageInput
                    message={message}
                    setMessage={setMessage}
                    typingCallback={() => {
                        channel.sendBirdData.startTyping();
                    }}
                    uploadCallback={e => {
                        if (e.currentTarget.files) {
                            const params = new sdk.FileMessageParams();
                            params.file = e.currentTarget.files[0];
                            sendFileMessage(channel.sendBirdData.url, params);
                        }
                    }}
                    sendCallback={() => {
                        const params = new sdk.UserMessageParams();
                        params.message = message;
                        channel.sendBirdData.endTyping();
                        sendMessage(channel.sendBirdData.url, params);
                        setMessage("");
                    }}
                />
            </Main>
        );
    }
};

export default ChannelInput;
