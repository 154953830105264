import React, { useRef, useState } from "react";
import ShadeScreen from "src/media/components/ShadeScreen";
import styled from "styled-components";
import { colors } from "src/constants";
import SpinLoader from "src/components/SpinLoader";
import { storage } from "src/firebase/FirebaseConfig";
import { isMobileSafari, makeId } from "src/util";
import { useEffect } from "react";
import { OpenBuySubmissionStatuses } from "src/buys/modules/open-buys/types";

const AdUploadPanel = styled.div<{
    compact: boolean;
    black: boolean;
    status?: OpenBuySubmissionStatuses;
    disabled?: boolean;
}>`
    padding: ${props => (props.compact ? "0" : "30px 0px")};
    background-color: ${props =>
        props.black ? colors.black : colors.blueGrey2};
    width: ${props => props.compact && "100%"};
    display: flex;
    justify-content: center;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    border: ${props =>
        props.status === "approved"
            ? `4px solid ${colors.primaryGreen}`
            : props.status === "pending"
            ? `4px solid ${colors.messageYellow}`
            : props.status === "revision_requested"
            ? `4px solid ${colors.primaryRed}`
            : ""};
`;

const AdPreviewContainer = styled.div<{ compact: boolean; disabled?: boolean }>`
    position: relative;
    height: ${props => (props.compact ? "200px" : "320px")};
    width: ${props => (props.compact ? "120px" : "180px")};
    border-radius: ${props => (props.compact ? "0" : "5px")};
    overflow: hidden;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    background-color: ${colors.blueGrey2};
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const InputContainer = styled.label<{ disabled?: boolean }>`
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;
const Description = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: ${colors.primaryGray};
`;

const Video = styled.video`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const Photo = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const AddVideoIcon = styled.img``;

const MediaUploader = ({
    path,
    onUpload,
    onRemove,
    onCoverPhoto,
    onMediaUrl,
    controlledMedia,
    disabled,
    style,
    triggerRemove,
    acceptedFormats,
    loading,
    status
}: {
    path: string;
    onUpload?: (id: string, type: string, url: string) => void;
    onRemove?: () => void;
    onMediaUrl?: (url: string) => void;
    onCoverPhoto?: (id: string) => void;
    controlledMedia?: { url: string; type: string };
    disabled?: boolean;
    style?: string;
    triggerRemove?: boolean;
    acceptedFormats?: string;
    loading?: boolean;
    status?: OpenBuySubmissionStatuses;
}) => {
    const [uploading, setUploading] = useState<boolean | null>(null);
    const [media, setMedia] = useState(
        controlledMedia || { type: "", url: "" }
    );
    const uploadRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (controlledMedia && controlledMedia?.url !== media.url)
            setMedia(controlledMedia);
    }, [controlledMedia]);

    useEffect(() => {
        if (loading !== undefined) setUploading(loading);
    }, [loading]);

    useEffect(() => {
        if (triggerRemove) {
            onRemove && onRemove();
            setMedia({ url: "", type: "" });
            onMediaUrl && onMediaUrl("");
            setTimeout(() => uploadRef.current?.click(), 300);
        }
    }, [triggerRemove]);

    function createCoverPhoto(file: File) {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        video.preload = "metadata";
        video.crossOrigin = "Anonymous";
        video.src = URL.createObjectURL(file);
        if (isMobileSafari()) {
            video.load();
        } else {
            video.muted = true;
            video.play();
        }
        video.onloadeddata = () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d");

            if (!context) {
                return null;
            }

            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(blob => {
                if (!blob) return null;

                const id = `${localStorage.getItem("userid")}_${makeId(
                    50
                )}.jpeg`;
                const ref = storage.ref().child(`${path}/${id}`);

                ref.put(blob)
                    .then(() => {
                        onCoverPhoto && onCoverPhoto(id);
                    })
                    .catch((error: string) => {
                        window.alert("Something went wrong. Please Refresh.");
                    });
            }, "image/jpeg");
        };
    }

    function fileUpload(e: any) {
        e.preventDefault();
        const allowedTypes = [
            "image/jpeg",
            "image/png",
            "video/mp4",
            "video/quicktime"
        ];
        const file = e.currentTarget.files[0];

        if (file && allowedTypes.includes(file.type)) {
            let type = file.type.split("/")[1];
            let url = URL.createObjectURL(file);
            onMediaUrl && onMediaUrl(url);
            if (!controlledMedia)
                setMedia({ url, type: file.type.split("/")[0] });

            if (type === "quicktime") {
                type = "mov";
            }

            const id = `${localStorage.getItem("userid")}_${makeId(
                50
            )}.${type}`;
            const ref = storage.ref().child(`${path}/${id}`);

            setUploading(true);

            ref.put(file)
                .then(async () => {
                    setUploading(false);
                    if (file.type.split("/")[0] === "video") {
                        onUpload &&
                            onUpload(id, "video", URL.createObjectURL(file));
                        createCoverPhoto(file);
                    } else {
                        //add coverPhoto for url
                        onUpload &&
                            onUpload(id, "photo", URL.createObjectURL(file));
                        onCoverPhoto && onCoverPhoto(id);
                    }
                })
                .catch((error: string) => {
                    window.alert("Something went wrong. Please Refresh.");
                });
        }
    }

    function renderIcon() {
        if (uploading) {
            return <SpinLoader size={15} color={"white"} />;
        } else if (media.type) {
            return <img src={"/green_circle_check.svg"} />;
        } else {
            return null;
        }
    }

    return (
        <AdUploadPanel
            compact={style === "compact"}
            black={!!media.url}
            status={status}
            disabled={disabled}
            onClick={() => {
                if (media.url && !disabled) {
                    onRemove && onRemove();
                    setMedia({ url: "", type: "" });
                    onMediaUrl && onMediaUrl("");
                }
            }}
        >
            <AdPreviewContainer compact={style === "compact"}>
                {media.url ? (
                    <>
                        {media.type === "video" ? (
                            <Video src={media.url || undefined} />
                        ) : (
                            <Photo src={media.url || undefined} />
                        )}
                        <ShadeScreen>{renderIcon()}</ShadeScreen>
                    </>
                ) : (
                    <>
                        <img src="/video-controls/add-video.svg" />
                        <InputContainer disabled={disabled}>
                            <input
                                ref={uploadRef}
                                type={"file"}
                                accept={
                                    acceptedFormats ||
                                    "image/png, image/jpeg, video/mp4, video/quicktime"
                                }
                                style={{ display: "none" }}
                                onChange={fileUpload}
                            />
                        </InputContainer>
                    </>
                )}
            </AdPreviewContainer>
        </AdUploadPanel>
    );
};

export default MediaUploader;
