import React, { useEffect } from "react";
import styled from "styled-components";
import { coinToDollars, formatNumber } from "../util";
import CoinSummary from "../components/CoinSummary";
import { ReferralEarnings } from "./Analytics";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReferralEarningsCell from "./ReferralEarningsCell";
import { GlobalState } from "../reducers";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { colors, fonts } from "../constants";
import AnalyticsEmptyState from "./components/AnalyticsEmptyState";

const Link = styled.div`
    padding: 5px 15px;
    color: ${colors.primaryBlue};
    font-size: ${fonts.subtitle}px;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const List = styled.div`
    padding: 10px 15px 15px;
`;

const Bottom = styled.div`
    padding: 0px 15px 15px;
`;

const GrayText = styled.div`
    font-weight: 300;
    font-size: ${fonts.subtitle}px;
    color: ${colors.secondaryGray};
    padding: 15px 0px 20px;
`;

interface Props {
    referralEarnings: ReferralEarnings[];
    push: (path: string) => void;
    showMore?: () => void;
}

const ReferralEarningsCard = ({ referralEarnings, push, showMore }: Props) => {
    const totalCoins =
        Math.round(
            referralEarnings.reduce((a, b) => {
                return (
                    a +
                    (b.should_pay_percentage
                        ? parseFloat(b.earned_plugcoins)
                        : 500)
                );
            }, 0) * 10
        ) / 10;

    function renderBottom() {
        const earningsList = (
            <List>
                {referralEarnings
                    .slice(0, showMore ? 3 : referralEarnings.length)
                    .map((earnings, idx) => {
                        return (
                            <ReferralEarningsCell
                                key={idx}
                                earnings={earnings}
                            />
                        );
                    })}
            </List>
        );

        if (referralEarnings.length === 0) {
            return (
                <Bottom>
                    <AnalyticsEmptyState />

                    <GrayText>
                        Refer creators to the Plug and get 10% of whatever they earn as a bonus for the first 12 months.
                    </GrayText>
                    <Button
                        block
                        variant="outline-success"
                        onClick={() => push("/profile/bonus?refer=true")}
                    >
                        Refer Now
                    </Button>
                </Bottom>
            );
        } else if (showMore) {
            return (
                <>
                    {earningsList}
                    <Bottom>
                        <Button
                            block
                            variant="outline-success"
                            onClick={showMore}
                        >
                            Show More
                        </Button>
                    </Bottom>
                </>
            );
        } else {
            return earningsList;
        }
    }

    return (
        <>
            <CoinSummary
                topLeft={"Referral Earnings"}
                topRight={`${formatNumber(coinToDollars(totalCoins))}`}
                bottomLeft={"All Time"}
                bottomRight={""}
            />
            {showMore && (
                <Link onClick={() => push("/analytics/referral-leaderboard")}>
                    <div>Referrals Leaderboard</div>
                    <FontAwesomeIcon
                        style={{ color: colors.secondaryGray }}
                        icon={["fas", "chevron-right"]}
                    />
                </Link>
            )}
            {renderBottom()}
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    referralEarnings: state.analytics.userAnalytics.alltime_referral_earnings
});

const mapDispatchToProps = (dispatch: any) => ({
    push: (path: string) => dispatch(push(path))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReferralEarningsCard);
