import React, { ChangeEvent, MouseEvent, useEffect } from "react";
import styled, { css } from "styled-components";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { colors, maxWidthDesktop, overlayBackgrounds } from "src/constants";
import { getIsMobile } from "src/utils/functions/selectors";
import { flexColumnCenter, flexRowCenter } from "src/utils/styles/snippets";
import { preventChildBubbling } from "src/utils/functions/helpers";
import { freezeBody, thawBody } from "src/util";
import CarouselCustomDot from "src/components/CarouselCustomDot";

interface ModalProps {
    open: boolean;
    isAdmin?: boolean;
}

const Parent = styled("main")<ModalProps>`
    ${props => (props.isAdmin ? flexColumnCenter() : flexRowCenter())};
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 2000;
    display: ${props => (props.open ? "flex" : "none")};
    background-color: ${overlayBackgrounds.medium};

    @media (max-width: 640px) {
        padding: 0px 20px;
    }
`;
const AdminHeader = styled.div<{ maxWidth?: string }>`
    width: 90%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    color: white;
    font-size: 18px;
    max-width: ${props => props.maxWidth};
`;
const CloseBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    border-radius: 50%;
    padding: 8px;
    background-color: ${colors.white};

    & > img {
        width: 14px;
        height: 14px;
    }
`;
const Child = styled("section")<{
    noMaxWidth?: true;
    fullHeight?: true;
    columnLayout?: true;
}>`
    width: 100%;
    max-width: ${props => !props.noMaxWidth && "600px"};
    height: ${props => !!props.fullHeight && "100%"};
    ${props => props.columnLayout && "min-height: 0; flex: 1;"}
`;

const ChildCarousel = styled(Carousel)<{
    fullHeight?: true;
    isAdmin?: boolean;
}>`
    height: 100%;
    ${props =>
        props.isAdmin &&
        css`
            & > ul > li {
                display: flex;
                justify-content: center;
            }
        `}
    ${props =>
        props.fullHeight &&
        css`
            > ul {
                height: 100%;
            }

            > ul > li {
                display: flex;
                align-items: center;
            }
        `}
`;

interface Props {
    open: boolean;
    closeModal: (e: MouseEvent) => void;
    arrows?: boolean;
    swipeable?: true;
    draggable?: boolean;
    infinite?: true;
    keyBoardControl?: boolean;
    noMaxWidth?: true;
    fullHeight?: true;
    isAdmin?: boolean;
    keepFrozenOnClose?: boolean;
    headerText?: string;
    className?: string;
    handleBeforeChange?: (nextSlide: number, state: any) => void;
    children: React.ReactNode;
    columnLayout?: true;
    maxWidth?: string;

    Header?: React.ReactNode;
    Footer?: React.ReactNode;
    hideDots?: boolean;
}

// When setting `fullHeight`, have to pass `preventChildBubbling` as the onClick handler
// directly to the child component itself.

const CarouselModal = ({
    open,
    closeModal,
    arrows,
    swipeable,
    draggable,
    infinite,
    keyBoardControl,
    noMaxWidth,
    fullHeight,
    isAdmin,
    keepFrozenOnClose,
    headerText = "View Media",
    columnLayout,
    Header,
    Footer,
    className,
    handleBeforeChange,
    children,
    hideDots,
    maxWidth
}: Props) => {
    const isMobile = useSelector(getIsMobile);
    useEffect(() => {
        freezeBody();
        return () => {
            if (!keepFrozenOnClose) thawBody();
        };
    }, []);
    return (
        <Parent
            className={className}
            isAdmin={columnLayout || isAdmin}
            onClick={closeModal}
            open={open}
        >
            {isAdmin && (
                <AdminHeader maxWidth={maxWidth}>
                    {headerText}
                    <CloseBtn onClick={closeModal}>
                        <img src="/black_close.svg" alt="close modal" />
                    </CloseBtn>
                </AdminHeader>
            )}
            {Header}
            <Child
                onClick={arrows ? preventChildBubbling : closeModal}
                fullHeight={fullHeight}
                noMaxWidth={noMaxWidth}
                columnLayout={columnLayout}
            >
                <ChildCarousel
                    beforeChange={(nextSlide, state) => {
                        handleBeforeChange &&
                            handleBeforeChange(nextSlide, state);
                    }}
                    isAdmin={isAdmin}
                    containerClass="carousel-modal"
                    responsive={{
                        universal: {
                            breakpoint: { min: 0, max: 100000 },
                            items: 1
                        }
                    }}
                    arrows={isMobile || fullHeight || isAdmin ? arrows : false}
                    swipeable={swipeable || false}
                    draggable={draggable || false}
                    infinite={infinite || false}
                    keyBoardControl={keyBoardControl || false}
                    fullHeight={fullHeight}
                    showDots={!hideDots}
                    customDot={<CarouselCustomDot />}
                    renderDotsOutside
                    dotListClass="custom-dot-list-style"
                >
                    {children}
                </ChildCarousel>
            </Child>
            {Footer}
        </Parent>
    );
};

export default CarouselModal;
