import styled from "styled-components";
import { Shimmer } from "../../../components/SkeletonLoader";
import { colors } from "../../../constants";
import { motion } from "framer-motion";

export const PaymentList = styled(motion.div)`
    padding: 0 18px 12px;
    display: flex;
    flex-direction: column;
`;

interface PaymentCellProps {
    isLoading?: true;
}

export const PaymentText = styled.div<PaymentCellProps>`
    min-height: 40px;
    width: fit-content;
    font-size: 20px;
    line-height: 24px;
    padding: 8px 12px;
    margin: 6px 0;
    color: white;
    border-radius: 6px;
    background: ${colors.secondaryGreen};
    ${props => props.isLoading && Shimmer}
    ${props => props.isLoading && "width: 40%;"}
    b {
        font-weight: bold;
    }
`;
