import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FullScreenModal from "src/modals/FullScreenModal";
import { AnimatePresence } from "framer-motion";
import { ReactComponent as Cross } from "src/components/icons/cross.svg";
import SimpleNavbar from "src/components/SimpleNavbar";
import { colors, fonts, sizes } from "src/constants";
import { clearButton } from "src/utils/styles/snippets";
import { CreatorSortOrder, CreatorDateRange } from "../utils/types";
import CheckBox from "src/components/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreatorsRequest } from "../actions.creator-search";
import { generateCreatorSearchData } from "../utils/helpers";
import { CreatorsSort } from "../pages/CreatorGroupsCreateOrEditPage";
import SelectionButton from "src/components/SelectionButton";
import { setCreatorsSort, setCreatorsSortOrder } from "src/ui/creators/actions";
import { GlobalState } from "src/reducers";
import withModalPortal from "src/components/withModalPortal";

const Main = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
`;

const CloseButton = styled(Cross)`
    cursor: pointer;
`;

const Body = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
`;

const SelectionButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    margin: 10px 0px;
    background-color: ${colors.tertiaryLightGray};
    border-radius: 100px;
`;

const SortButton = styled.button`
    ${clearButton()};
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${sizes.selectionButton.height}px;
    color: ${colors.white};
    background-color: ${colors.primaryBlue};
    border-radius: 8px;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

interface Props {
    modalOpen: boolean;
    closeModal: () => void;
    resetForSort: () => void;
}

const CreatorsSortModal = ({ modalOpen, closeModal, resetForSort }: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const {
        hitsPerPage,
        page,
        query,
        dateRange,
        queryFilters,
        sort,
        sortOrder
    } = useSelector((state: GlobalState) => {
        return state.ui.creators;
    });

    // Local State ---------------------------------------------------
    const [sortByUsername, setSortByUsername] = useState(false);
    const [sortByFollowers, setSortByFollowers] = useState(true);
    const [sortByEngagementRate, setSortByEngagementRate] = useState(false);

    // Effects -------------------------------------------------------
    useEffect(() => {
        const sortFilters: any = {};

        if (!sortByUsername && !sortByFollowers && !sortByEngagementRate) {
            // setSortByUsername(true);
            setSortByFollowers(true);
        }

        if (sortByUsername) {
            sortFilters["username"] = { "username.raw": { order: sortOrder } };
        } else {
            delete sortFilters["username"];
        }

        if (sortByFollowers) {
            sortFilters["followers"] = { followers: { order: sortOrder } };
        } else {
            delete sortFilters["followers"];
        }

        if (sortByEngagementRate) {
            sortFilters["engagementRate"] = {
                engagement_rate: { order: sortOrder }
            };
        } else {
            delete sortFilters["engagementRate"];
        }

        dispatch(setCreatorsSort(Object.values(sortFilters)));
    }, [sortByUsername, sortByFollowers, sortByEngagementRate, sortOrder]);

    // Local Functions -----------------------------------------------
    function handleSort(): void {
        resetForSort();
        const searchQuery = generateCreatorSearchData(
            hitsPerPage,
            page,
            query,
            dateRange,
            queryFilters,
            sort
        );
        dispatch(fetchCreatorsRequest(searchQuery, true));
        closeModal();
    }

    // JSX -----------------------------------------------------------
    return (
        <AnimatePresence>
            {modalOpen && (
                <FullScreenModal closeModal={closeModal}>
                    <Main>
                        <SimpleNavbar
                            title="Sort Creators"
                            right={
                                <CloseButton
                                    fill={colors.primaryGray}
                                    onClick={closeModal}
                                />
                            }
                        />
                        <Body>
                            <SelectionButtons>
                                <SelectionButton
                                    key="creators-sort-modal-dropdown-section-descending"
                                    text="Descending"
                                    selected={sortOrder === "desc"}
                                    setSelected={() =>
                                        dispatch(setCreatorsSortOrder("desc"))
                                    }
                                    includeBorder
                                />

                                <SelectionButton
                                    key="creators-sort-modal-dropdown-section-ascending"
                                    text="Ascending"
                                    selected={sortOrder === "asc"}
                                    setSelected={() =>
                                        dispatch(setCreatorsSortOrder("asc"))
                                    }
                                    includeBorder
                                />

                                <Divider />

                                <SelectionButton
                                    key="creators-sort-modal-dropdown-section-username"
                                    text="Username"
                                    selected={sortByUsername}
                                    setSelected={() =>
                                        setSortByUsername(!sortByUsername)
                                    }
                                    includeBorder
                                />
                                <SelectionButton
                                    key="creators-sort-modal-dropdown-section-followers"
                                    text="Followers"
                                    selected={sortByFollowers}
                                    setSelected={() =>
                                        setSortByFollowers(!sortByFollowers)
                                    }
                                    includeBorder
                                />
                                <SelectionButton
                                    key="creators-sort-modal-dropdown-section-engagement-rate"
                                    text="Engagement Rate"
                                    selected={sortByEngagementRate}
                                    setSelected={() =>
                                        setSortByEngagementRate(
                                            !sortByEngagementRate
                                        )
                                    }
                                    includeBorder
                                />
                            </SelectionButtons>

                            <SortButton type="button" onClick={handleSort}>
                                Sort
                            </SortButton>
                        </Body>
                    </Main>
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default withModalPortal(CreatorsSortModal);
