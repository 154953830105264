import React, { useState } from "react";
import { DirectBuyStep } from "../../../modules/direct-buy-step/types";
import LoadingButton from "../../../../components/LoadingButton";
import styled from "styled-components";
import { colors, fonts } from "../../../../constants";
import { errorToString, makeId } from "../../../../util";
import { storage } from "../../../../firebase/FirebaseConfig";
import { addNotification } from "../../../../ui/notifications/actions";
import { post } from "../../../../Api";
import { updateDirectBuyStep } from "../../../actions";
import { setSimpleMedia } from "../../../../modals/actions";
import { directBuyDetailsModal } from "../../../../ui/direct-buys/actions";
import { DirectBuyDetailsModalState } from "../../../../ui/direct-buys/reducer";
import { useDispatch } from "react-redux";
import { ReactComponent as PlusIcon } from "../../../../components/icons/card-circle-plus.svg";

interface MainProps {
    customWidth: string;
    hasUploaded: boolean;
}

const Main = styled.div<MainProps>`
    position: relative;
    height: 170px;
    width: ${props => props.customWidth};
    background: ${props => (props.hasUploaded ? "black" : "white")};
    ${props => props.hasUploaded && "cursor: pointer;"}
    border-radius: 6px;
    border: 1px solid ${colors.mediumTeal};
    & + & {
        margin-left: 10px;
    }
`;

const PreviewImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

interface ContainerProps {
    padding: string;
    hasUploaded: boolean;
}

const Container = styled.div<ContainerProps>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.padding};
    .hide-when-screenshot-uploaded {
        opacity: ${props => (props.hasUploaded ? 0 : 1)};
    }
`;

const Description = styled.div`
    font-size: ${fonts.subtitle}px;
    line-height: 20px;
    color: ${colors.primaryGray};
    b {
        font-weight: bold;
    }
`;

const ButtonContainer = styled.div`
    position: relative;
`;

const InputContainer = styled.label`
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;
`;

interface Props {
    padding: string;
    width: string;
    step: DirectBuyStep;
}

const DirectBuyDetailsScreenshotUploadCard = ({
    padding,
    width,
    step
}: Props) => {
    const dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);

    function fileUpload(e: any) {
        e.preventDefault();
        const allowedTypes = ["image/jpeg", "image/png"];
        const file = e.currentTarget.files[0];

        if (file && allowedTypes.includes(file.type)) {
            let type = file.type.split("/")[1];

            const id = `${localStorage.getItem("userid")}_${makeId(
                50
            )}.${type}`;
            const ref = storage.ref().child(`directBuyScreenshot/${id}`);

            setUploading(true);
            ref.put(file)
                .then(() => {
                    finishUpload(id);
                })
                .catch(error => {
                    dispatch(
                        addNotification(
                            `Unable to upload screenshot: ${errorToString(
                                error
                            )}`,
                            colors.primaryRed
                        )
                    );
                    setUploading(false);
                });
        }
    }

    function finishUpload(filename: string) {
        post(`/v1/directBuyStep/${step.id}/screenshot`, {
            filename
        })
            .then(({ data }) => {
                dispatch(updateDirectBuyStep(data.data));
                setUploading(false);
            })
            .catch(error => {
                dispatch(
                    addNotification(
                        `Unable to upload screenshot: ${errorToString(error)}`,
                        colors.primaryRed
                    )
                );
                setUploading(false);
            });
    }

    const hasUploaded = Boolean(step.screenshotUrl);
    return (
        <Main
            customWidth={width}
            hasUploaded={hasUploaded}
            onClick={() => {
                if (step.screenshotUrl) {
                    dispatch(
                        setSimpleMedia({
                            url: step.screenshotUrl,
                            type: "photo"
                        })
                    );
                    dispatch(
                        directBuyDetailsModal(
                            DirectBuyDetailsModalState.simpleMedia
                        )
                    );
                }
            }}
        >
            {step.screenshotUrl && <PreviewImage src={step.screenshotUrl} />}
            <Container padding={padding} hasUploaded={hasUploaded}>
                <PlusIcon className="hide-when-screenshot-uploaded" />
                <Description className="hide-when-screenshot-uploaded">
                    Click here to upload your screenshot
                    {step.socialAccount && (
                        <>
                            {" for "}
                            <b>@{step.socialAccount.username}</b>
                        </>
                    )}
                </Description>
                <ButtonContainer onClick={e => e.stopPropagation()}>
                    <LoadingButton
                        text={hasUploaded ? "Edit" : "Upload"}
                        onClick={() => {}}
                        isLoading={uploading}
                        width={"fit-content"}
                        height={"32px"}
                        padding={"9px 15px"}
                        spinnerSize={14}
                        borderColor={colors.mediumTeal}
                        textColor={colors.primaryBlue}
                        textSize={"12px"}
                        textWeight={400}
                    />
                    <InputContainer>
                        <input
                            type={"file"}
                            accept={"image/png, image/jpeg"}
                            style={{ display: "none" }}
                            onChange={fileUpload}
                        />
                    </InputContainer>
                </ButtonContainer>
            </Container>
        </Main>
    );
};

export default DirectBuyDetailsScreenshotUploadCard;
