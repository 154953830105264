import React from "react";
import { useDispatch } from "react-redux";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import { BlueButton } from "src/profile/components/Buttons";
import styled from "styled-components";
import ChallengeForm, { ChallengeFormInputs } from "./ChallengeForm";
import PubAssignment from "./PubAssignment";

interface Props {
    openBuy: OpenBuy | null;
    handleSubmit: (form: ChallengeFormInputs) => void;
    handleDelete: (id: number) => void;
}
const ChallengeEditor = ({ openBuy, handleSubmit, handleDelete }: Props) => {
    const dispatch = useDispatch();

    const pageType = openBuy ? "edit" : "create";
    return (
        <div>
            <ChallengeForm
                pageType={pageType}
                handleSubmit={handleSubmit}
                handleDelete={handleDelete}
                openBuy={openBuy}
            />
            {pageType === "edit" && openBuy && (
                <>
                    {/* <EditPosters
                        poster={
                            openBuy?.openBuyPosters[0]
                                ? openBuy.openBuyPosters[0]
                                : null
                        }
                        openBuyId={openBuy.id}
                    /> */}
                    <PubAssignment openBuy={openBuy} />
                </>
            )}
            {/* {openBuy && (
                <EditButton
                    onClick={() => {
                        dispatch(fetchOpenBuyToEdit(openBuy.id));
                        dispatch(push("/admin-tools/challenges/instructions"));
                        dispatch(setInstructionsPageType("Edit"));
                    }}
                >
                    Edit Challenge Instructions
                </EditButton>
            )} */}
        </div>
    );
};

export default ChallengeEditor;
