import { call, put } from "redux-saga/effects";
import { normalize } from "normalizr";

import { get } from "src/Api";

import { fetchReferralCodeSuccess, fetchReferredUsersSuccess } from "./actions";
import { referredUserList } from "./schema";
import { ReferredUser } from "../types";

export function* fetchReferredUsersRequest() {
    try {
        const currentUserId = localStorage.getItem("userid");
        const endpoint = `/v1/publisher/p:${currentUserId}/referral`;

        const { data } = yield call(get, endpoint, {});

        const normalizedReferredUsers = normalize<ReferredUser>(
            data.data,
            referredUserList
        );

        yield put(fetchReferredUsersSuccess(normalizedReferredUsers));
    } catch (error) {
        console.log(error);
    }
}

export function* fetchReferralCodeRequest() {
    try {
        const currentUserId = localStorage.getItem("userid");
        const endpoint = `/v1/publisher/p:${currentUserId}/referralCode`;

        const { data } = yield call(get, endpoint, {});

        yield put(fetchReferralCodeSuccess(data.data));
    } catch (error) {
        console.log(error);
    }
}
