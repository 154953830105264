import React from "react";
import { Route, Switch } from "react-router";
import PublicBuys from "./components/PublicBuys";

const PublicBuysRouter = () => {
    return (
        <Switch>
            <Route
                path="/public/buys/:token"
                render={({ match }: { match: any }) => {
                    return <PublicBuys token={match.params.token} />;
                }}
            />
            <Route
                path="/public/buys/"
                render={({ match }: { match: any }) => {
                    return <PublicBuys />;
                }}
            />
        </Switch>
    );
};
export default PublicBuysRouter;
