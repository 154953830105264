import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CampaignReward from "src/campaigns/details/components/CampaignReward";
import { CountryPlatformReward } from "src/campaigns/types";
import { colors, fonts, sizes } from "src/constants";
import { instructionSetsRoutes } from "src/marketplace/utils/marketplace-routes";
import { GlobalState } from "src/reducers";
import { setCurrentCampaignId } from "src/ui/campaigns/actions";
import {
    openInstructionsCampaignSelectionModal,
    resetInstructionsSetOnCampaignSelection,
    setInstructionsPageStep,
    setInstructionSteps
} from "src/ui/instructions-sets/actions";
import styled from "styled-components";

const Main = styled.section`
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    /* padding: 8px 12px; */
    height: ${sizes.campaigns.selectionRow.height}px;
    padding: 0px 12px;
    background-color: ${colors.white};
    border-radius: 8px;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.secondaryLightGray};
        }
    }
`;

const Info = styled.div`
    display: flex;
    align-items: center;
`;

const AppIcon = styled.img`
    width: 46px;
    height: 46px;
    margin-right: 12px;
    border-radius: 12px;
`;

const CampaignInfo = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;

const CampaignName = styled.span`
    margin-bottom: 2px;
    font-size: ${fonts.subtitle}px;
    font-weight: 600;
`;

const NotHoveredArrow = styled.img``;

const HoveredArrow = styled.img``;

interface Props {
    campaignId: number;
    campaignName: string;
    campaignImageUrl: string;
    campaignCountryPlatformReward: CountryPlatformReward;
    campaignRewardType: string;
}

const CampaignSelectionRow = ({
    campaignId,
    campaignName,
    campaignImageUrl,
    campaignCountryPlatformReward,
    campaignRewardType
}: Props) => {
    const [hovered, setHovered] = useState(false);

    const pageType = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets.instructionsPageType;
    });

    const dispatch = useDispatch();
    const history = useHistory();
    function setCurrentCampaign(): void {
        dispatch(setCurrentCampaignId(campaignId));
        dispatch(resetInstructionsSetOnCampaignSelection());

        // if (pageType === "Edit") history.push(instructionSetsRoutes.main);
    }

    return (
        <Main
            onClick={setCurrentCampaign}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Info>
                <AppIcon
                    src={campaignImageUrl}
                    alt="instructions campaign selection icon url"
                />
                <CampaignInfo>
                    <CampaignName>{campaignName}</CampaignName>
                    <CampaignReward
                        countryPlatformReward={campaignCountryPlatformReward}
                        rewardType={campaignRewardType}
                        textSize={fonts.subtitle}
                    />
                </CampaignInfo>
            </Info>

            {hovered ? (
                <HoveredArrow src="/caret-hovered.svg" />
            ) : (
                <NotHoveredArrow src="/caret-not-hovered.svg" />
            )}
        </Main>
    );
};

export default CampaignSelectionRow;
