import React from "react";
import styled from "styled-components";
import VerticallyCentered from "./VerticallyCentered";
import { colors } from "../constants";
import { coinToDollars } from "src/util";

interface StyleProps {
    size: number;
    textSize?: number;
    color?: string;
}

const Reward = styled.span`
    font-weight: 600;
    margin-right: 4px;
`;

const Logo = styled.img<StyleProps>`
    height: ${props => props.size}px;
    min-width: ${props => props.size}px;
    margin-right: ${props => Math.round(props.size / 4)}px;
`;

const Text = styled.div<StyleProps>`
    color: ${props => props.color || colors.secondaryGreen};
    font-size: ${props => props.textSize || props.size - 4}px;
    line-height: ${props => props.size}px;
    display: flex;
    flex-wrap: wrap;
`;

const OldPrice = styled.div`
    color: ${colors.primaryGray};
    text-decoration: line-through;
    margin-right: 4px;
`;

interface Props {
    reward: number;
    rewardMin?: number;
    rewardMax?: number;
    type: string;
    size?: number;
    textSize?: number;
    color?: string;
    previousReward?: number | null;
}

const CoinReward = ({
    reward,
    rewardMin,
    rewardMax,
    type,
    size = 20,
    textSize,
    color,
    previousReward
}: Props) => {
    const authed = localStorage.getItem("userid");

    const authedReward = <Reward>{coinToDollars(reward)}</Reward>;

    const notAuthedReward = (rewardMin === 0 || rewardMin) && rewardMax && (
        <>
            <Reward>{coinToDollars(rewardMin)}</Reward> ~{" "}
            <Reward>{coinToDollars(rewardMax)}</Reward>
        </>
    );

    return (
        <VerticallyCentered>
            <Logo size={size} src={"/logo.svg"} />
            <Text size={size} textSize={textSize} color={color}>
                {previousReward && (
                    <OldPrice>{coinToDollars(previousReward)}</OldPrice>
                )}

                {authed ? authedReward : notAuthedReward}

                {` per ${type}`}
            </Text>
        </VerticallyCentered>
    );
};

export default CoinReward;
