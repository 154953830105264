import React from "react";

import { Props } from "src/components/icons/types";

const Plus = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || "10"}
            viewBox="0 0 10 10"
            fill={props.fill || "#000"}
        >
            <path d="M5.556 0H4.444v10h1.111V0z" />
            <path d="M0 4.444v1.111h10V4.444H0z" />
        </svg>
    );
};

export default Plus;
