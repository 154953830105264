import React, { useEffect } from "react";
import { useState } from "react";
import { get, post, setToken } from "src/Api";
import styled from "styled-components";
import PublicBuysScreenShots from "./PublicBuysScreenShots";
import PublicBuysSelector from "./PublicBuysSelector";
import SimpleNavbar from "src/components/SimpleNavbar";
import { Title } from "src/profile/modals/styledComponents";
import { Header } from "src/components/text/CommonStyles";
import Subtitle from "src/components/text/Subtitle";
import PublisherSelector from "./PublisherSelector";
import NavButton from "src/components/NavButton";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { colors } from "src/constants";
import { BlueButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import { addNotification } from "src/ui/notifications/actions";
import {
    addAssignedScreenshots,
    removeAssignedScreenshots,
    updatePublicBuys
} from "../redux/actions";
import {
    DragDropContext,
    DraggableLocation,
    DropResult
} from "react-beautiful-dnd";
import { PBPreview, PublicBuysScreenshot } from "../redux/reducer";

const Main = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 3.5fr;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

const SubmissionContainer = styled.div`
    display: flex;
    height: 54px;
    width: 100vw;
    position: fixed;
    bottom: 0;
    background-color: ${colors.white};
    justify-content: right;
    padding-right: 20vw;
    align-items: center;
    gap: 12px;
    box-shadow: 0px 10px 20px grey;
`;

const SubmissionCount = styled.div`
    font-weight: 500;
    font-size: 13px;
`;

const SubmitButton = styled(BlueButton)`
    height: 32px;
    width: 69px;
`;

interface Props {
    token?: string;
}

export interface PBData {
    username?: string;
    id?: number;
    directBuyId?: string;
    screenshot?: { id?: string; type?: string; url?: string };
}

const PublicBuys = ({ token }: Props) => {
    const [publisher, setPublisher] = useState<PBData>({});
    const [employee, setEmployee] = useState("");
    useEffect(() => setToken(token), [token]);
    const dispatch = useDispatch();

    const assigned = useSelector(
        (state: GlobalState) => state.publicBuys.assigned
    );

    useEffect(() => {
        if (token) {
            get("/public/buys/publisher", {})
                .then(res => setPublisher({ ...publisher, ...res.data.data }))
                .catch(err => console.error(err));
        }
    }, [token]);

    useEffect(() => {
        let userId = localStorage.getItem("userid");
        if (userId)
            get(`/v1/publisher/p:${userId}/profile`, {})
                .then(res => {
                    if (res.data.data.profile.status === "employee")
                        setEmployee(res.data.data.profile.username);
                })
                .catch(err => console.error(err));
    }, []);

    const submitScreenshots = () => {
        assigned.forEach(submission => {
            post(`/public/buys/${submission.directBuyStepId}/screenshot`, {
                screenshotId: submission.screenshotId
            })
                .then(res => {
                    dispatch(
                        removeAssignedScreenshots(submission.directBuyStepId)
                    );
                })
                .catch(err => {
                    dispatch(
                        addNotification("Failed to submit screenshot", "red")
                    );
                });
        });
        dispatch(addNotification("Submitted screenshots"));
        window.location.reload();
    };

    const [screenshots, setScreenshots] = useState<PublicBuysScreenshot[]>([]);
    useEffect(() => {
        get("/public/buys/screenshots", { search: "" }).then(res => {
            setScreenshots(res.data.data);
        });
    }, []);

    const publicBuys = useSelector((state: GlobalState) => {
        return state.publicBuys;
    });

    function addScreenshotToDirectBuy(
        result: DropResult,
        destination: DraggableLocation
    ): void {
        const screenshotId = Number(result.draggableId.split("-").splice(-1));
        const directBuyStepId = Number(
            destination.droppableId.split("-").slice(-2)[0]
        );
        dispatch(addAssignedScreenshots(screenshotId, directBuyStepId));

        const directBuyIndex = Number(
            destination.droppableId.split("-").slice(-2)[1]
        );
        const buy = publicBuys.buys[directBuyIndex];
        let temp = { ...buy };
        temp.screenshotUrl = screenshots.find(
            shot => shot.id === screenshotId
        )?.screenshotUrl;
        dispatch(updatePublicBuys(temp));
    }

    function handleOnDragEnd(result: DropResult) {
        const { destination } = result;
        if (destination) {
            const { droppableId } = destination;
            const draggedIntoDirectBuys =
                droppableId !== "public-buys-screenshot-previews-container";
            if (draggedIntoDirectBuys) {
                addScreenshotToDirectBuy(result, destination);
            }
        }
    }

    if (!token)
        return employee ? (
            <>
                <SimpleNavbar title="Public Buys Upload" />
                <Container>
                    <Header>
                        <Title>
                            Welcome {employee}
                            {publisher.username &&
                                `for publisher: ${publisher.username} (${publisher.id})`}
                        </Title>
                        <Subtitle>
                            Select a user to upload screenshots for
                        </Subtitle>
                        <PublisherSelector
                            selectPublisher={(id: number, username: string) =>
                                setPublisher({ ...publisher, id, username })
                            }
                        />
                    </Header>
                </Container>
            </>
        ) : (
            <>
                <SimpleNavbar title="Public Buys Upload" />
                <Container>
                    <Header>
                        <Title>No token found in url</Title>
                        <Subtitle>
                            You can ask one of our publishers to give you a
                            proper link from the copy upload link button found
                            in direct buys
                        </Subtitle>
                    </Header>
                </Container>
            </>
        );
    return (
        <>
            <SimpleNavbar
                left={
                    <NavButton
                        onClick={() =>
                            dispatch(push(employee ? "/public/buys" : "/"))
                        }
                    >
                        Back
                    </NavButton>
                }
                title={publisher.username + "'s Public Buys Upload"}
            />
            <Main>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <PublicBuysScreenShots publisher={publisher} />
                    <PublicBuysSelector publisher={publisher} />
                    {assigned.length > 0 && (
                        <SubmissionContainer>
                            <SubmissionCount>
                                {assigned.length} Submission
                                {assigned.length > 1 && "s"} Assigned
                            </SubmissionCount>
                            <SubmitButton onClick={() => submitScreenshots()}>
                                Submit
                            </SubmitButton>
                        </SubmissionContainer>
                    )}
                </DragDropContext>
            </Main>
        </>
    );
};
export default PublicBuys;
