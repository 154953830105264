import React, { useState } from "react";
import {
    BluePillButton,
    WhitePillButton
} from "src/components/buttons/PillButton";
import { colors } from "src/constants";
import { ReactComponent as Caret } from "../../components/icons/down-caret.svg";
import styled from "styled-components";
import { motion } from "framer-motion";
import ReferralInputModal from "./ReferralInputModal";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useIntercom } from "react-use-intercom";
import { useLocation } from "react-router";

const Main = styled(motion.div)`
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    border-radius: 32px 32px 0px 0px;
    background-color: ${colors.primaryBlue};
    padding: 24px 24px 32px 24px;

    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${colors.white};
`;

const ButtonList = styled.div`
    display: flex;
    flex-direction: column;
    & > * ~ * {
        margin-top: 10px;
    }
`;

const BottomLinkGroup = styled.div`
    align-self: flex-end;
    display: flex;
    & > * ~ * {
        margin-left: 16px;
    }
`;

const LoginText = styled.div`
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 16px;
    cursor: pointer;
`;

const LoginIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    cursor: pointer;
    background-color: ${colors.white};

    margin-left: 4px;
`;

const BottomLink = styled.div``;

const LoginPortalModal = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const [displayReferral, toggleDisplayReferral] = useState(
        new URLSearchParams(location.search).get("referralCode") || false
    );
    const { boot, showNewMessages, getVisitorId } = useIntercom();

    return (
        <Main
            key="full-screen-modal"
            onClick={e => e.stopPropagation()}
            initial={{ y: 300, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 300, opacity: 0 }}
            transition={{
                type: "spring",
                mass: 1,
                stiffness: 170,
                damping: 26
            }}
        >
            {displayReferral ? (
                <ReferralInputModal back={() => toggleDisplayReferral(false)} />
            ) : (
                <>
                    <ButtonList>
                        <WhitePillButton
                            onClick={() => dispatch(push("/onboarding/phone"))}
                        >
                            Sign Up with Phone Number
                        </WhitePillButton>
                        <BluePillButton
                            onClick={() => toggleDisplayReferral(true)}
                        >
                            Sign Up with Referral Code
                        </BluePillButton>
                    </ButtonList>
                    <LoginText
                        onClick={() => dispatch(push("/onboarding/phone"))}
                    >
                        Have an Account? Log In
                        <LoginIcon>
                            <Caret stroke={colors.primaryBlue} />
                        </LoginIcon>
                    </LoginText>
                    <BottomLinkGroup>
                        <BottomLink
                            onClick={() => {
                                boot({
                                    customAttributes: {
                                        disable_chat_bot: false
                                    }
                                });
                                showNewMessages();
                            }}
                        >
                            CONTACT US
                        </BottomLink>
                        <BottomLink
                            onClick={() => {
                                window.open("http://plugco.in/terms");
                            }}
                        >
                            TERMS
                        </BottomLink>
                        <BottomLink
                            onClick={() => {
                                window.open("http://plugco.in/privacy");
                            }}
                        >
                            PRIVACY
                        </BottomLink>
                    </BottomLinkGroup>
                </>
            )}
        </Main>
    );
};
export default LoginPortalModal;
