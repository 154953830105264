import React from "react";
import { Asset } from "src/campaigns/types";
import { colors } from "src/constants";
import Platforms from "src/social-accounts/Platforms";
import styled from "styled-components";

const Row = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 12px;
    border: 1px solid ${colors.blueGrey2};
    box-sizing: border-box;
    border-radius: 12px;
    gap: 8px;
    background: ${colors.white};
    width: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const Icon = styled.img`
    height: 46px;
    width: 46px;
    border-radius: 12px;
    margin-right: 12px;
`;
const Title = styled.h1`
    font-size: 14px;
    text-align: left;
`;

const Subtitle = styled.h2`
    font-size: 12px;
    font-weight: 400;
    color: ${colors.gray2};
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const LinkButton = styled.a`
    margin-left: auto;
    background-color: ${colors.primaryBlue};
    color: white;
    border-radius: 6px;
    transition: 0.1s ease-in;
    padding: 8px 14px;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

const DirectBuyDetailsSoundCell = ({ asset }: { asset: Asset }) => {
    const getPlatform = (url: string, formatted?: boolean) => {
        if (url.includes("tiktok.com")) return formatted ? "TikTok" : "tiktok";
        else if (url.includes("instagram.com"))
            return formatted ? "Instagram" : "instagram";
        else if (url.includes("snapchat.com"))
            return formatted ? "Snapchat" : "snapchat";
        else return "other";
    };
    return (
        <Row>
            <Icon src={Platforms[getPlatform(asset.url)].imgSrc} />
            <Column>
                <Title>{getPlatform(asset.url, true)} Sound</Title>
                <Subtitle>{asset.url}</Subtitle>
            </Column>
            <LinkButton href={asset.url} target="#">
                Open Link
            </LinkButton>
        </Row>
    );
};

export default DirectBuyDetailsSoundCell;
