import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import CopyToClipboard from "react-copy-to-clipboard";
import ScriptAssetsAccordionContainer from "../accordions/ScriptAssetsAccordionContainer";
import ScriptMediasAccordionContainer from "../accordions/ScriptMediasAccordionContainer";
import { breakpoints, colors } from "../../../constants";
import Divider from "../../../components/Divider";
import { Campaign, Script } from "../../types";
import { BlueButton, OutlineButton } from "src/profile/components/Buttons";
import ModalCampaignTitle from "../../components/ModalCampaignTitle";
import UserInfo from "./UserInfo";
import { ProcessingControl } from "../hooks/useProcessing";
import { ModalView, setScriptModalView } from "src/ui/scripts/actions";
import { useDispatch } from "react-redux";
import ShareButton from "src/components/ShareButton";
import removeMd from "remove-markdown";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { replaceHtmlEntities } from "src/util";
import ScriptPreview from "./ScriptPreview";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    @media (max-width: ${breakpoints.tablet}px) {
        height: 100%;
    }
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;
const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;
const ScriptTitle = styled.h2`
    font-size: 1.2rem;
`;
const ScriptId = styled.p`
    font-size: 0.9rem;
    color: ${colors.primaryGray};
`;

const Content = styled.div`
    display: flex;
    overflow-y: auto;
    width: 100%;
    @media (max-width: ${breakpoints.tablet}px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;
const ScriptWrapper = styled.div`
    flex: 1;
    min-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 12px;
    @media (max-width: ${breakpoints.tablet}px) {
        height: auto;
        flex: none;
        padding-bottom: 18px;
    }
`;
const CampaignWrapper = styled.div`
    flex: 1;
    min-width: 50%;
    overflow-x: hidden;
    padding-left: 12px;
    @media (max-width: ${breakpoints.tablet}px) {
        flex: none;
    }
`;
const ScriptHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 18px 14px;
    border-top: 1px solid ${colors.blueGrey2};
`;
const ScriptTag = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
`;
const BtnWrap = styled.div`
    display: flex;
`;
const Btn = styled(BlueButton)`
    font-size: 0.8rem;
    padding: 12px 16px;
    margin-left: 8px;
    & > * {
        display: block;
    }
`;
const CopyBtn = styled(OutlineButton)`
    font-size: 0.8rem;
    padding: 12px 16px;
`;
const StyledScriptCampaignTitle = styled(ModalCampaignTitle)`
    padding-bottom: 16px;
    border-bottom: 1px solid ${colors.blueGrey2};
`;
const MediaWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

interface Props {
    script: Script;
    campaign: Campaign;
    isAdmin: boolean;
    onDelete: () => void;
    processingControl: ProcessingControl;
}

const ScriptHome = ({
    script,
    campaign,
    isAdmin,
    onDelete,
    processingControl
}: Props) => {
    const dispatch = useDispatch();

    const plainTextScript = replaceHtmlEntities(
        removeMd(script.text, {
            stripListLeaders: false
        })
    );
    const shouldShowPrice =
        window.location.pathname.includes("campaign") ||
        window.location.pathname === "/";
    return (
        <Wrapper>
            <Header>
                <TitleWrapper>
                    <ScriptTitle>{script && script.title}</ScriptTitle>
                    <ScriptId>Script ID: {script && script.id}</ScriptId>
                </TitleWrapper>
                <BtnWrap>
                    <ShareButton
                        isScript
                        link={`/campaign/${script.campaignId}?scriptId=${script.id}`}
                    />
                    {isAdmin && (
                        <UserInfo script={script} onDelete={onDelete} />
                    )}
                </BtnWrap>
            </Header>
            <Content>
                <ScriptWrapper>
                    <ScriptHeader>
                        <ScriptTag>Script</ScriptTag>
                        <BtnWrap>
                            <CopyToClipboard
                                text={plainTextScript}
                                onCopy={() =>
                                    dispatch(
                                        setPillNotificationText(
                                            "Copied Script!",
                                            "success",
                                            2000
                                        )
                                    )
                                }
                            >
                                <CopyBtn>Copy Script</CopyBtn>
                            </CopyToClipboard>
                            {isAdmin && (
                                <Btn
                                    onClick={() =>
                                        dispatch(
                                            setScriptModalView(
                                                ModalView.scriptEdit
                                            )
                                        )
                                    }
                                >
                                    Edit
                                </Btn>
                            )}
                        </BtnWrap>
                    </ScriptHeader>
                    <ScriptPreview text={script.text} />
                </ScriptWrapper>
                <CampaignWrapper>
                    <StyledScriptCampaignTitle
                        showPrice={shouldShowPrice}
                        campaign={campaign}
                    />
                    <MediaWrap>
                        <ScriptMediasAccordionContainer
                            processingControl={processingControl}
                            title="Example Media"
                            initiallyExpanded
                            isAdmin={isAdmin}
                            isScriptModal
                        />
                        <Divider />
                        <ScriptAssetsAccordionContainer
                            processingControl={processingControl}
                            title="Assets"
                            isAdmin={isAdmin}
                            initiallyExpanded
                            isScriptModal
                        />
                        <Divider />
                    </MediaWrap>
                </CampaignWrapper>
            </Content>
        </Wrapper>
    );
};

export default ScriptHome;
