import { getUnixTime } from "date-fns";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionsModal from "src/modals/ActionsModal";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import Modal from "src/modals/Modal";
import {
    setConversationPauseDuration,
    updateGroupUserSettings
} from "../actions";
import {
    selectConversationOwnPauseDuration,
    selectGroupUserSettingsById
} from "../selectors";
import TwoButtonFooter from "src/components/TwoButtonFooter";
import Divider from "src/components/Divider";

const Main = styled.div`
    padding: 20px 18px;
`;

const Title = styled.div`
    font-size: 17px;
    font-weight: 600;
`;

const Description = styled.div`
    margin-top: 4px;
    font-size: 14px;
`;

interface Props {
    conversationId: string;
    closeModal: () => void;
    toggleMuteDurationModal: () => void;
    userId: string;
}

const ChatRoomActionsModal = ({
    conversationId,
    closeModal,
    toggleMuteDurationModal,
    userId
}: Props) => {
    const dispatch = useDispatch();
    const [confirmModal, toggleConfirmModal] = useState(false);
    const pauseDuration = useSelector((state: GlobalState) =>
        selectConversationOwnPauseDuration(state, conversationId)
    );
    const userSettings = useSelector((state: GlobalState) =>
        selectGroupUserSettingsById(state, conversationId, userId)
    );

    function setPauseDuration() {
        if (!pauseDuration) {
            toggleMuteDurationModal();
        } else {
            dispatch(
                setConversationPauseDuration({
                    conversationId,
                    notificationsPausedIndefinitely: false,
                    notificationsPausedUntil: null
                })
            );
        }
        closeModal();
    }

    function toggleBlockedStatus() {
        dispatch(
            updateGroupUserSettings({
                id: userSettings.id,
                blocked: userSettings.blocked ? 0 : getUnixTime(new Date())
            })
        );
        closeModal();
    }

    function reportUser() {
        dispatch(
            updateGroupUserSettings({
                id: userSettings.id,
                blocked: getUnixTime(new Date()),
                reported: getUnixTime(new Date())
            })
        );
        closeModal();
    }

    return (
        <>
            <ActionsModal
                localClearModal={closeModal}
                actions={[
                    {
                        text: pauseDuration
                            ? `Resume (${pauseDuration})`
                            : "Pause Notifications",
                        callback: setPauseDuration
                    },
                    {
                        text: userSettings.blocked
                            ? "Unblock User"
                            : "Block User",
                        callback: toggleBlockedStatus
                    },
                    {
                        text: "Report User",
                        negative: true,
                        callback: () => toggleConfirmModal(true)
                    }
                ]}
            />
            {confirmModal && (
                <Modal onClick={() => toggleConfirmModal(false)}>
                    <Main>
                        <Title>Please Confirm</Title>
                        <Description>
                            Reporting this user will also automatically block
                            them.
                        </Description>
                    </Main>
                    <Divider />
                    <TwoButtonFooter
                        leftText="Cancel"
                        rightText="Confirm"
                        leftCallback={() => toggleConfirmModal(false)}
                        rightCallback={reportUser}
                    />
                </Modal>
            )}
        </>
    );
};
export default ChatRoomActionsModal;
