import React, { useState } from "react";
import styled from "styled-components";

//when testing is true display a clickable button that throws an erorr to be caught by ErrorBoyndaries
const ErrorBoundaryTest = ({ testing }: { testing: boolean }) => {
    const Container = styled.div`
        display: ${testing ? "flex" : "none"};
        position: fixed;
        z-index: 999;
        height: 100px;
        width: 200px;
        background-color: rgba(0, 0, 0, 0.8);
        top: calc(50vh - 50px);
        left: calc(50vw - 100px);
    `;

    const Button = styled.div`
        margin: auto;
        padding: 10px;
        height: 50px;
        background-color: rgba(255, 10, 10);
        font-size: 20px;
        border-radius: 10px;
        cursor: pointer;
    `;

    //errors wont be caught from callbacks so we have to use a state to trigger it
    const [error, setError] = useState(false);

    if (error) throw new Error("Testing Error Boundary");
    return (
        <Container>
            <Button onClick={() => setError(true)}>Click to Throw Error</Button>
        </Container>
    );
};

export default ErrorBoundaryTest;
