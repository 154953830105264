import { combineReducers } from "redux";
import campaignsEntitiesReducer, {
    CampaignsEntitiesState
} from "./campaigns/reducer";
import mediaReducer, { MediaState } from "./media/reducer";
import authReducer, { AuthState } from "./auth/reducer";
import accountsReducer, {
    SocialAccountsState
} from "./social-accounts/reducer";
import profileReducer, { ProfileState } from "./profile/reducer";
import analyticsReducer, { AnalyticsState } from "./analytics/reducer";
import notificationsReducer, {
    NotificationsState
} from "./notifications/reducer";
import chatReducer, { ChatState } from "./chat/reducer";
import { connectRouter, RouterState } from "connected-react-router";
import mediaRulesEntitiesReducer, {
    MediaRulesEntitiesState
} from "./campaigns/media-rules/reducer";
import scriptsEntitiesReducer, {
    ScriptsEntitiesState
} from "./campaigns/scripts/reducer";
import highlightsEntitiesReducer, {
    HighlightsEntitiesState
} from "./campaigns/highlights/reducer";
import highlightMediasEntitiesReducer, {
    HighlightMediasEntitiesState
} from "./campaigns/highlight-medias/reducer";
import assetsEntitiesReducer, {
    AssetsEntitiesState
} from "./campaigns/assets/reducer";
import mediasEntitiesReducer, {
    MediasEntitiesState
} from "./campaigns/medias/reducer";
import uiReducer, { UIState } from "./ui/reducer";
import captionsEntitiesReducer, {
    CaptionsEntitiesState
} from "./campaigns/captions/reducer";
import customLinksEntitiesReducer, {
    CustomLinksEntitiesState
} from "./campaigns/custom-links/reducer";
import placementsEntitiesReducer, {
    PlacementsEntitiesState
} from "./campaigns/placements/reducer";
import contestsEntitiesReducer, {
    ContestsEntitiesState
} from "./campaigns/contests/reducer";
import socialAccountsEntitiesReducer, {
    SocialAccountEntitiesState
} from "./profile/accounts/reducer";
import publisherOffersEntitiesReducer, {
    PublisherOffersEntitiesState
} from "./profile/rates/reducer";
import publisherFlagsEntitiesReducer, {
    PublisherFlagsEntitiesState
} from "./profile/flags/reducer";
import directBuysEntitiesReducer, {
    DirectBuysEntitiesState
} from "./buys/modules/direct-buy/reducer";
import directBuyStepsEntitiesReducer, {
    DirectBuyStepsEntitiesState
} from "./buys/modules/direct-buy-step/reducer";
import referredUsersEntitiesReducer, {
    ReferredUsersEntitiesState
} from "./profile/referrals/reducer";
import { splitReducer } from "@splitsoftware/splitio-redux";
import { ISplitState } from "@splitsoftware/splitio-redux/lib/types";
import shoutoutsEntitiesReducer, {
    ShoutoutsEntitiesState
} from "./campaigns/shoutouts/reducers";
import delayedCampaignsEntitiesReducer, {
    DelayedCampaignsEntitiesState
} from "./notifications/delayed-campaigns/reducer";
import linksReducer, { LinksState } from "./links/reducers";
import {
    LinkListsEntitiesReducer,
    LinkListEntitiesState
} from "./profile/link-lists/reducer";
import paginationReducer, { PaginationState } from "./pagination/reducer";
import failuresReducer, { FailuresState } from "./failures/reducer";
import conversationsEntitiesReducer, {
    ConversationsEntitiesState
} from "./new-chat/conversation/reducer";
import chatProfileEntitiesReducer, {
    ChatProfilesEntitiesState
} from "./new-chat/chat-profiles/reducer";
import messagesEntitiesReducer, {
    MessagesEntitiesState
} from "./new-chat/message/reducer";
import groupUserSettingsEntitiesReducer, {
    GroupUserSettingsEntitiesState
} from "./new-chat/group-user-settings/reducer";
import publicProfileEntitiesReducer, {
    PublicProfileEntitiesState
} from "./public-profiles/reducer";
import instructionSetsEntitiesReducer, {
    InstructionsSetsEntitiesState
} from "./marketplace/instructions-sets/reducers.instructions-sets";
import instructionStepsEntitiesReducer, {
    InstructionsSetStepsEntitiesState
} from "./marketplace/instructions-sets/reducers.instruction-steps";
import {
    openBuysEntitiesReducer,
    OpenBuysEntitiesState,
    openBuysStepsEntitiesReducer,
    openBuySubmissionSetsReducer,
    openBuySubmissionsReducer,
    StepById,
    SubmissionById
} from "./buys/modules/open-buys/reducer";
import {
    OpenBuyInstructionStep,
    OpenBuySubmission,
    OpenBuySubmissionSet
} from "./buys/modules/open-buys/types";
import publicBuysReducer, {
    PublicBuysState
} from "./buys/public/redux/reducer";
import elasticSearchReducer, { ElasticSearchState } from "./search/reducer";
import creatorGroupsEntitiesReducer, {
    CreatorGroupsEntitiesState
} from "./marketplace/creator-groups/reducers.creator-groups";
import creatorSearchEntitiesReducer, {
    CreatorSearchEntitiesState
} from "./marketplace/creator-groups/reducers.creator-search";
import criteriaEntitiesReducer, {
    CriteriaEntitiesState
} from "./marketplace/payment-sets/criteria/reducer";
import paymentTypesEntitiesReducer, {
    PaymentTypesEntitiesState
} from "./marketplace/payment-sets/payment-types/reducer";
import socialAccountProfileEntitiesReducer, {
    SocialAccountProfileEntitiesState
} from "./social-accounts/profile/redux/social-account-profile.reducer";
import offersEntitiesReducer, {
    OffersEntitiesState
} from "./marketplace/wizard/reducers.create-offer";
import marketplaceOpenBuysEntitiesReducer, {
    MarketplaceOpenBuysEntitiesState
} from "./marketplace/wizard/reducers.open-buys";
import adminOpenBuysEntitiesReducer, {
    AdminOpenBuysEntitiesState
} from "./admin-tools/reducers.admin-tools";
import openBuyExtensionsEntitiesReducer, {
    OpenBuyExtensionsEntitiesState
} from "./admin-tools/challenges/challenge-extensions/reducer";
import openBuyLeaderboardReportsEntitiesReducer, {
    OpenBuyLeaderboardReportsEntitiesState
} from "./buys/details/leaderboard/reducer";
import { FirebaseState } from "./firebase/reducer";
import firebaseReducer from "./firebase/reducer";

export interface GlobalState {
    auth: AuthState;
    accounts: SocialAccountsState;
    media: MediaState;
    profile: ProfileState;
    analytics: AnalyticsState;
    notifications: NotificationsState;
    chat: ChatState;
    entities: EntitiesState;
    ui: UIState;
    router: RouterState;
    firebase: FirebaseState;
    links: LinksState;
    pagination: PaginationState;
    failures: FailuresState;
    publicBuys: PublicBuysState;
    elasticSearch: ElasticSearchState;
}

export interface EntitiesState {
    campaigns: CampaignsEntitiesState;
    directBuys: DirectBuysEntitiesState;
    openBuys: OpenBuysEntitiesState;
    openBuySubmissions: SubmissionById;
    openBuySubmissionSets: { [id: number]: OpenBuySubmissionSet };
    openBuyLeaderboardReports: OpenBuyLeaderboardReportsEntitiesState;
    instructionSteps: StepById;
    shoutouts: ShoutoutsEntitiesState;
    mediaRules: MediaRulesEntitiesState;
    scripts: ScriptsEntitiesState;
    highlights: HighlightsEntitiesState;
    highlightMedias: HighlightMediasEntitiesState;
    assets: AssetsEntitiesState;
    medias: MediasEntitiesState;
    captions: CaptionsEntitiesState;
    customLinks: CustomLinksEntitiesState;
    placements: PlacementsEntitiesState;
    contests: ContestsEntitiesState;
    referredUsers: ReferredUsersEntitiesState;
    linkLists: LinkListEntitiesState;
    publisherFlags: PublisherFlagsEntitiesState;
    socialAccounts: SocialAccountEntitiesState;
    socialAccountProfile: SocialAccountProfileEntitiesState;
    publisherOffers: PublisherOffersEntitiesState;
    directBuySteps: DirectBuyStepsEntitiesState;
    delayedCampaigns: DelayedCampaignsEntitiesState;
    conversations: ConversationsEntitiesState;
    chatProfiles: ChatProfilesEntitiesState;
    messages: MessagesEntitiesState;
    groupUserSettings: GroupUserSettingsEntitiesState;
    publicProfiles: PublicProfileEntitiesState;
    instructionsSets: InstructionsSetsEntitiesState;
    instructionsSetSteps: InstructionsSetStepsEntitiesState;
    creatorGroups: CreatorGroupsEntitiesState;
    creators: CreatorSearchEntitiesState;
    criteria: CriteriaEntitiesState;
    paymentTypes: PaymentTypesEntitiesState;
    offers: OffersEntitiesState;
    // marketplaceOpenBuys: MarketplaceOpenBuysEntitiesState;
    adminOpenBuys: AdminOpenBuysEntitiesState;
    marketplaceOpenBuys: MarketplaceOpenBuysEntitiesState;
    openBuyExtensions: OpenBuyExtensionsEntitiesState;
}

const entitiesReducer = combineReducers<EntitiesState>({
    campaigns: campaignsEntitiesReducer,
    directBuys: directBuysEntitiesReducer,
    openBuys: openBuysEntitiesReducer,
    openBuySubmissions: openBuySubmissionsReducer,
    openBuySubmissionSets: openBuySubmissionSetsReducer,
    openBuyLeaderboardReports: openBuyLeaderboardReportsEntitiesReducer,
    instructionSteps: openBuysStepsEntitiesReducer,
    directBuySteps: directBuyStepsEntitiesReducer,
    shoutouts: shoutoutsEntitiesReducer,
    mediaRules: mediaRulesEntitiesReducer,
    scripts: scriptsEntitiesReducer,
    highlights: highlightsEntitiesReducer,
    highlightMedias: highlightMediasEntitiesReducer,
    assets: assetsEntitiesReducer,
    medias: mediasEntitiesReducer,
    captions: captionsEntitiesReducer,
    customLinks: customLinksEntitiesReducer,
    placements: placementsEntitiesReducer,
    contests: contestsEntitiesReducer,
    socialAccounts: socialAccountsEntitiesReducer,
    socialAccountProfile: socialAccountProfileEntitiesReducer,
    publisherOffers: publisherOffersEntitiesReducer,
    referredUsers: referredUsersEntitiesReducer,
    linkLists: LinkListsEntitiesReducer,
    publisherFlags: publisherFlagsEntitiesReducer,
    delayedCampaigns: delayedCampaignsEntitiesReducer,
    conversations: conversationsEntitiesReducer,
    chatProfiles: chatProfileEntitiesReducer,
    messages: messagesEntitiesReducer,
    groupUserSettings: groupUserSettingsEntitiesReducer,
    publicProfiles: publicProfileEntitiesReducer,
    instructionsSets: instructionSetsEntitiesReducer,
    instructionsSetSteps: instructionStepsEntitiesReducer,
    creatorGroups: creatorGroupsEntitiesReducer,
    creators: creatorSearchEntitiesReducer,
    criteria: criteriaEntitiesReducer,
    paymentTypes: paymentTypesEntitiesReducer,
    offers: offersEntitiesReducer,
    // marketplaceOpenBuys: marketplaceOpenBuysEntitiesReducer
    adminOpenBuys: adminOpenBuysEntitiesReducer,
    marketplaceOpenBuys: marketplaceOpenBuysEntitiesReducer,
    openBuyExtensions: openBuyExtensionsEntitiesReducer
} as any);

export default (history: any) =>
    combineReducers<GlobalState>({
        entities: entitiesReducer,
        ui: uiReducer,
        media: mediaReducer,
        auth: authReducer,
        accounts: accountsReducer,
        profile: profileReducer,
        analytics: analyticsReducer,
        notifications: notificationsReducer,
        chat: chatReducer,
        router: connectRouter(history),
        firebase: firebaseReducer,
        links: linksReducer,
        pagination: paginationReducer,
        failures: failuresReducer,
        publicBuys: publicBuysReducer,
        elasticSearch: elasticSearchReducer
    } as any);
