import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withModalPortal from "src/components/withModalPortal";
import { colors, fonts, fontWeight } from "src/constants";
import ChallengeFormSubSection from "src/marketplace/challenge-creation-form/components/ChallengeFormSubSection";
import MarketplaceInput from "src/marketplace/components/MarketplaceInput";
import ResponsiveModal from "src/modals/ResponsiveModal";
import { BlueButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import { setCreateChallengeFormOpen } from "src/ui/admin-tools/actions";
import { clearSpacing } from "src/utils/styles/snippets";
import { EventInput, EventSubmit } from "src/utils/types/form";
import styled from "styled-components";
import { adminCreateOpenBuy } from "../actions.admin-tools";

const Main = styled.article`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Title = styled.h1`
    ${clearSpacing()};
    color: ${colors.black};
    font-size: ${fonts.semiTitle}px;
    font-weight: ${fontWeight.medium};
`;

const SubmitButton = styled(BlueButton)`
    align-self: flex-end;
    padding: 8px 12px;
    font-size: ${fonts.semiSmallTitle}px;
`;

const ChallengeCreationForm = () => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const { createChallengeFormOpen } = useSelector((state: GlobalState) => {
        return state.ui.adminTools;
    });

    // Local State ---------------------------------------------------
    const [name, setName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    // Local Functions -----------------------------------------------
    function closeModal(): void {
        dispatch(setCreateChallengeFormOpen(false));
    }

    function handleNameChange(event: EventInput): void {
        const userInput = event.currentTarget.value;
        setName(userInput);
    }

    function handleSubmit(event: EventSubmit): void {
        event.preventDefault();
        if (errorMessage.length === 0 && name.length > 0) {
            dispatch(adminCreateOpenBuy(name));
        } else {
            setErrorMessage("You must give your challenge a name.");
        }
    }

    // Effects -------------------------------------------------------
    useEffect(() => {
        if (name.length > 0) {
            setErrorMessage("");
        } else {
            setErrorMessage("You must give your challenge a name.");
        }
    }, [name]);

    // JSX -----------------------------------------------------------
    return (
        <form onSubmit={handleSubmit}>
            <ResponsiveModal
                modalOpen={createChallengeFormOpen}
                closeModal={closeModal}
            >
                <Main>
                    <Title>Give This Challenge a Name</Title>

                    <ChallengeFormSubSection
                        error={{
                            isError: errorMessage.length > 0,
                            message: errorMessage
                        }}
                    >
                        <MarketplaceInput
                            value={name}
                            placeholder="Input Challenge Name"
                            onChange={handleNameChange}
                        />
                    </ChallengeFormSubSection>

                    <SubmitButton type="submit" disabled={name.length === 0}>
                        Create Challenge
                    </SubmitButton>
                </Main>
            </ResponsiveModal>
        </form>
    );
};

export default withModalPortal(ChallengeCreationForm);
