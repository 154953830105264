import React, { useEffect, useRef, useState } from "react";
import { useMeasure, useScroll } from "react-use";
import { colors } from "src/constants";
import { clearButton, hideScrollbar } from "src/utils/styles/snippets";
import styled, { css } from "styled-components";

const Main = styled.article<{ margin?: string; fullWidth?: boolean }>`
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin: ${props => props.margin};
    min-width: ${props => (props.fullWidth ? "100%" : "revert")};
`;

const FadeScrollButton = styled.button<{
    direction: "left" | "right";
}>`
    ${clearButton()};
    position: absolute;
    top: 0px;
    right: ${props => props.direction === "right" && "0px"};
    bottom: 0px;
    left: ${props => props.direction === "left" && "0px"};
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 64px;
    padding: 6px 4px;
    background: linear-gradient(
        270deg,
        ${colors.white} 20.2%,
        ${colors.transparent} 100%
    );
    transform: ${props => {
        return props.direction === "left" ? "rotate(180deg)" : "rotate(0deg)";
    }};
`;

const HorizontalScrollContainer = styled.div<{
    gap?: number;
    fullWidth?: boolean;
}>`
    ${hideScrollbar()};
    display: flex;
    gap: ${props => props.gap || 4}px;
    overflow-x: auto;
    min-width: ${props => (props.fullWidth ? "100%" : "revert")};
`;

const ArrowIcon = styled.img`
    width: 6px;
    height: auto;
`;

interface Props {
    elements?: JSX.Element[];
    gap?: number;
    margin?: string;
    children?: JSX.Element;
    className?: string;
    fullWidth?: boolean;
}

const ScrollableWithFadedArrows = ({
    elements,
    gap,
    margin,
    className,
    fullWidth,
    children
}: Props) => {
    const [mainRef, { width }] = useMeasure();
    const horizontalScrollContainerRef = useRef<any>(null);
    const { x } = useScroll(horizontalScrollContainerRef);

    const [scrolledAwayFromTheLeft, setScrolledAwayFromTheLeft] = useState(
        false
    );
    const [
        scrolledAllTheWayToTheRight,
        setScrolledAllTheWayToTheRight
    ] = useState(false);

    // Handling the hiding/showing of scroll arrows
    useEffect(() => {
        if (
            horizontalScrollContainerRef &&
            horizontalScrollContainerRef.current
        ) {
            if (width > horizontalScrollContainerRef.current.clientWidth) {
                setScrolledAwayFromTheLeft(false);
                setScrolledAllTheWayToTheRight(true);
            }

            // Scrolled all the way to the right.
            else if (
                Math.floor(x) ===
                horizontalScrollContainerRef.current.scrollWidth -
                    horizontalScrollContainerRef.current.clientWidth
            ) {
                setScrolledAllTheWayToTheRight(true);
            }

            // Scrolled away from the left.
            else if (x > 0) {
                setScrolledAwayFromTheLeft(true);
                setScrolledAllTheWayToTheRight(false);
            } else {
                setScrolledAwayFromTheLeft(false);
                setScrolledAllTheWayToTheRight(false);
            }
        }
    }, [mainRef, width, horizontalScrollContainerRef, x]);

    function scrollHorizontalScrollContainer(
        event: React.MouseEvent<HTMLButtonElement>,
        direction: "left" | "right"
    ): void {
        event.preventDefault();
        if (
            horizontalScrollContainerRef &&
            horizontalScrollContainerRef.current
        ) {
            const xPosition = horizontalScrollContainerRef.current.scrollLeft;
            const containerWidth = horizontalScrollContainerRef.current.getBoundingClientRect()
                .width;

            horizontalScrollContainerRef.current.scrollTo({
                left:
                    direction === "left"
                        ? xPosition - containerWidth
                        : xPosition + containerWidth,
                behavior: "smooth"
            });
        }
    }

    return (
        <Main
            fullWidth={fullWidth}
            ref={mainRef}
            margin={margin}
            className={className}
        >
            {scrolledAwayFromTheLeft && (
                <FadeScrollButton
                    type="button"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                        scrollHorizontalScrollContainer(event, "left")
                    }
                    direction="left"
                >
                    <ArrowIcon src="/caret-hovered.svg" alt="arrow icon" />
                </FadeScrollButton>
            )}

            <HorizontalScrollContainer
                ref={horizontalScrollContainerRef}
                gap={gap}
                fullWidth={fullWidth}
            >
                {elements || children}
            </HorizontalScrollContainer>

            {!scrolledAllTheWayToTheRight && (
                <FadeScrollButton
                    type="button"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                        scrollHorizontalScrollContainer(event, "right")
                    }
                    direction="right"
                >
                    <ArrowIcon src="/caret-hovered.svg" alt="arrow icon" />
                </FadeScrollButton>
            )}
        </Main>
    );
};

export default ScrollableWithFadedArrows;
