import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Connection, PublicProfileData } from "../../../types";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import NavButton from "../../../../components/NavButton";
import FullscreenLoader from "../../../../components/FullscreenLoader";
import CustomMediaPage from "../../components/CustomMediaPage";
import { PlugLink } from "../../../../media/types";
import { fetchLinkForPlugSuccess } from "src/links/actions";
import CampaignModals from "../../../modals/CampaignModals";
import PaddingAround from "../../../../components/PaddingAround";
import XCard from "../../../components/XCard";
import { fetchMediaPublic } from "../../../actions";
import MainContainer from "src/components/MainContainer";
import { useHistory } from "react-router";
import Navbar from "src/components/Navbar";

interface Props {
    publicData?: PublicProfileData;
}

const PublicCustomMediaPage = ({ publicData }: Props) => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        if (!scrolled) {
            window.scrollTo(0, 0);
            setScrolled(true);
        }
    }, [scrolled]);

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();

    function dispatchFetchMediaPublic(connection: Connection): void {
        dispatch(fetchMediaPublic(connection));
    }

    function dispatchFetchLinkForPlugSuccess(link: PlugLink): void {
        dispatch(fetchLinkForPlugSuccess(link));
    }

    const navbar = (
        <SimpleNavbar
            title={"CUSTOM ADS"}
            left={<NavButton back onClick={goBack} />}
        />
    );

    if (publicData && publicData.customMedia === null) {
        return (
            <>
                <Navbar back title="CUSTOM ADS" marginBottom={10} />

                <MainContainer>
                    <PaddingAround>
                        <XCard
                            text="Failed to load custom media, click the button below to try again."
                            button={{
                                text: "Try again",
                                callback: () => {
                                    dispatchFetchMediaPublic(
                                        publicData.connection
                                    );
                                }
                            }}
                        />
                    </PaddingAround>
                </MainContainer>
            </>
        );
    } else if (!(publicData && publicData.customMedia)) {
        return (
            <>
                <Navbar back title="CUSTOM ADS" marginBottom={10} />
                <FullscreenLoader />
            </>
        );
    } else {
        const connection = publicData.connection;
        const customMedia = publicData.customMedia;

        return (
            <>
                <Navbar back title="CUSTOM ADS" marginBottom={10} />

                <MainContainer>
                    {scrolled && (
                        <CustomMediaPage
                            customMedia={customMedia}
                            username={connection.other.profile.username}
                            fetchLinkForPlugSuccess={
                                dispatchFetchLinkForPlugSuccess
                            }
                        />
                    )}
                </MainContainer>

                <CampaignModals />
            </>
        );
    }
};

export default PublicCustomMediaPage;
