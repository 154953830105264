import { createSelector } from "reselect";
import { GlobalState } from "../../../reducers";
import { PendingOpenBuyPaymentsGroup } from "../types";

export const getCurrentOpenBuyAlreadyHasPayments = createSelector(
    (state: GlobalState) => state.ui.adminTools.currentOpenBuyId,
    (state: GlobalState) =>
        state.ui.openBuyPayments.pendingOpenBuyPaymentsStore,
    (state: GlobalState) => state.entities.instructionsSets.byId,
    (state: GlobalState) => state.ui.instructionsSets.currentInstructionsSetId,
    (
        currentOpenBuyId,
        pendingOpenBuyPaymentsStore,
        instructionsSetsState,
        currentInstructionsSetId
    ): boolean | null => {
        if (
            !currentOpenBuyId ||
            !pendingOpenBuyPaymentsStore[currentOpenBuyId]
        ) {
            return null;
        }
        if (
            !currentInstructionsSetId ||
            !instructionsSetsState[currentInstructionsSetId]
        ) {
            return null;
        }
        const {
            alreadyHasPayments,
            pendingOpenBuyPaymentsGroups
        } = pendingOpenBuyPaymentsStore[currentOpenBuyId];
        const { instructionSteps } = instructionsSetsState[
            currentInstructionsSetId
        ];
        const instructionStepIds = new Set(
            instructionSteps.map(step => step.id)
        );
        return (
            alreadyHasPayments &&
            pendingOpenBuyPaymentsGroups.filter(payment =>
                instructionStepIds.has(payment.instructionStepId)
            ).length > 0
        );
    }
);

export const getCurrentPendingOpenBuyPaymentsGroups = createSelector(
    (state: GlobalState) => state.ui.adminTools.currentOpenBuyId,
    (state: GlobalState) =>
        state.ui.openBuyPayments.pendingOpenBuyPaymentsStore,
    (state: GlobalState) => state.entities.instructionsSets.byId,
    (state: GlobalState) => state.ui.instructionsSets.currentInstructionsSetId,
    (
        currentOpenBuyId,
        pendingOpenBuyPaymentsStore,
        instructionsSetsState,
        currentInstructionsSetId
    ): PendingOpenBuyPaymentsGroup[] | null => {
        if (
            !currentOpenBuyId ||
            !pendingOpenBuyPaymentsStore[currentOpenBuyId]
        ) {
            return null;
        }
        if (
            !currentInstructionsSetId ||
            !instructionsSetsState[currentInstructionsSetId]
        ) {
            return null;
        }
        const { pendingOpenBuyPaymentsGroups } = pendingOpenBuyPaymentsStore[
            currentOpenBuyId
        ];
        const { instructionSteps } = instructionsSetsState[
            currentInstructionsSetId
        ];
        const instructionStepIds = new Set(
            instructionSteps.map(step => step.id)
        );
        return pendingOpenBuyPaymentsGroups.filter(payment =>
            instructionStepIds.has(payment.instructionStepId)
        );
    }
);
