import React from "react";
import styled from "styled-components";
import Modal from "src/modals/Modal";
import ModalButton from "src/modals/ModalButton";

const Main = styled.div`
    padding: 20px 18px;
`;

const Title = styled.div`
    font-size: 17px;
    font-weight: 600;
`;

const Description = styled.div`
    margin-top: 4px;
    font-size: 14px;
`;

interface Props {
    title?: string;
    message?: string;
    closeModal: () => void;
}

const ErrorModal = ({
    title = "Error",
    message = "Something went wrong, please try again.",
    closeModal
}: Props) => {
    return (
        <Modal onClick={closeModal}>
            <Main>
                <Title>{title}</Title>
                <Description>{message}</Description>
            </Main>
            <ModalButton onClick={closeModal} />
        </Modal>
    );
};
export default ErrorModal;
