import React from "react";
import ChallengeGeneralButtonFooter from "./ChallengeGeneralButtonFooter";
import { post } from "src/Api";
import { InstructionsSetCreateData } from "src/marketplace/instructions-sets/actions.instructions-sets";
import { getCurrentInstructionsSet } from "src/marketplace/instructions-sets/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useConvertReduxDataToAPIData } from "src/marketplace/instructions-sets/utils/hooks.page-partial";
import { GlobalState } from "src/reducers";
import { useHistory } from "react-router";
import { setPillNotificationText } from "src/ui/notifications/actions";

interface Props {
    onCreatePage: boolean;
    updateHandler?: () => void;
    deleteHandler?: () => void;
}

const ChallengeCreationFormFooter = ({
    onCreatePage,
    updateHandler,
    deleteHandler
}: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentOpenBuyId } = useSelector((state: GlobalState) => {
        return state.ui.adminTools;
    });
    const { currentCampaignId } = useSelector((state: GlobalState) => {
        return state.ui.campaign;
    });
    const formattedInstructionSteps = useConvertReduxDataToAPIData(true);
    const currentInstructionSet = useSelector(getCurrentInstructionsSet);

    const duplicateChallenge = async () => {
        if (!currentInstructionSet || !currentCampaignId || !currentOpenBuyId) {
            dispatch(
                setPillNotificationText(
                    "Missing instructionset or open buy. Buy must be filled out completely before duplicating",
                    "warning"
                )
            );
            return;
        }
        const formattedInstructionsSet: InstructionsSetCreateData = {
            name: `${currentInstructionSet.name} Copy`,
            campaignId: currentCampaignId,
            advertiserId: null,
            duration: null,
            instructionSteps: formattedInstructionSteps
        };
        try {
            const res = await post(
                `/v1/admin/openBuy/${currentOpenBuyId}/duplicate`,
                formattedInstructionsSet
            );

            history.push(
                `/admin-tools/your-challenges/${res.data.data.id}/edit-challenge`
            );
        } catch (err) {
            dispatch(
                setPillNotificationText(
                    "Failed to duplicate challenge",
                    "danger"
                )
            );
        }
    };

    if (onCreatePage)
        return (
            <ChallengeGeneralButtonFooter
                buttons={[
                    {
                        text: "Create Challenge",
                        action: () => {}
                    }
                ]}
            />
        );
    if (updateHandler && deleteHandler)
        return (
            <ChallengeGeneralButtonFooter
                buttons={[
                    { text: "Duplicate Challenge", action: duplicateChallenge },
                    { text: "Update Challenge", action: updateHandler },
                    {
                        text: "Delete Challenge",
                        action: deleteHandler,
                        isDeleteButton: true
                    }
                ]}
            />
        );
    return <ChallengeGeneralButtonFooter buttons={[]} />;
};

export default ChallengeCreationFormFooter;
