import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOpenBuySubmissions, toggleRulesModal } from "src/buys/actions";
import { StepById } from "src/buys/modules/open-buys/reducer";
import { OpenBuy, OpenBuySubmission } from "src/buys/modules/open-buys/types";
import SimpleNavbar from "src/components/SimpleNavbar";
import FullScreenModal from "src/modals/FullScreenModal";
import styled from "styled-components";
import OpenBuySubmissionSection from "./OpenBuySubmissionSection";
import OpenBuySubmissionEditor from "./OpenBuySubmissionEditor";
import SocialAccountSelector from "./SocialAccountSelector";
import { SocialAccount } from "src/profile/types";
import OpenBuySubmissionSetForm from "./OpenBuySubmissionSetForm";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { useHistory } from "react-router";
import { patch, post } from "src/Api";
import { flexRowCenter } from "src/utils/styles/snippets";
import { useIntercom } from "react-use-intercom";
import { get } from "src/new-chat/saga";
import { isAxiosError } from "axios";

const Main = styled.div``;

const Close = styled.img`
    padding: 15px;
    cursor: pointer;

    svg {
        margin-right: 15px;
    }
`;

const ContactIcon = styled.img`
    width: 16px;
    height: 16px;
`;
const IconContainer = styled.div`
    ${flexRowCenter()};
    cursor: pointer;
`;

interface Props {
    openBuy: OpenBuy;
    steps: StepById;
    treatPublic?: boolean;
    onClose: VoidFunction;
    createSet?: boolean;
}

const OpenBuySubmissionScreenModal = ({
    onClose,
    openBuy,
    steps,
    treatPublic,
    createSet
}: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [manageSubmission, setManageSubmission] = useState<Partial<
        OpenBuySubmission
    > | null>(null);
    const [manageSet, setManageSet] = useState<number | null>(null);
    const [selectSocial, setSelectSocial] = useState(false);

    useEffect(() => {}, []);

    const { update, showNewMessages } = useIntercom();
    useEffect(() => {
        if (createSet) setSelectSocial(createSet);
    }, [createSet]);

    const updateSubmissionSet = async (id: number) => {
        try {
            await patch(
                `/v1/openBuy/${manageSet}/submissionSet/socialAccount`,
                {
                    socialAccountId: id
                }
            );
            setSelectSocial(false);
        } catch (err) {
            if (isAxiosError(err))
                dispatch(
                    setPillNotificationText(
                        err?.response?.data?.error?.message,
                        "danger"
                    )
                );
        }
    };

    const createSubmissionSet = async (id: number) => {
        try {
            const res = await post(`/v1/openBuy/${openBuy.id}/submissionSet`, {
                socialAccountId: id
            });
            setSelectSocial(false);
            setManageSet(res.data.data.id);
        } catch (err) {
            if (isAxiosError(err))
                dispatch(
                    setPillNotificationText(
                        err?.response?.data?.error?.message,
                        "danger",
                        4000
                    )
                );
        }
    };

    const getStandards = async () => {
        let res = await get<{ id: string; ruleSetId: number }>(
            "ChallengesStandardMediaRulesSetId",
            "GzADIFgd3zDfZAgtQF1Y"
        );
        if (res?.ruleSetId) {
            if (!localStorage.getItem(`viewedRuleSet${res?.ruleSetId}`))
                dispatch(toggleRulesModal(res?.ruleSetId));
        }
    };

    useEffect(() => {
        getStandards();
        if (!localStorage.getItem(`viewedRuleSet${openBuy.ruleSetId}`))
            dispatch(toggleRulesModal(openBuy.ruleSetId));
        else dispatch(toggleRulesModal(null));
    }, []);

    return (
        <FullScreenModal
            closeModal={() => {
                onClose && onClose();
                dispatch(getOpenBuySubmissions(openBuy.id));
            }}
        >
            <SimpleNavbar
                title={
                    selectSocial
                        ? "Select Social Accounts"
                        : "Challenge Submission"
                }
                left={
                    selectSocial || manageSubmission || manageSet ? (
                        <Close
                            src="/chevron-left.svg"
                            onClick={() => {
                                if (selectSocial) setSelectSocial(false);
                                else if (manageSubmission)
                                    setManageSubmission(null);
                                else setManageSet(null);
                            }}
                        />
                    ) : null
                }
                right={
                    <div style={{ display: "flex" }}>
                        <IconContainer
                            onClick={() => {
                                update({
                                    customAttributes: {
                                        disable_chat_bot: false
                                    }
                                });
                                showNewMessages();
                            }}
                        >
                            <ContactIcon src="/navbar-contact.svg" />
                        </IconContainer>
                        <Close
                            onClick={() => {
                                onClose && onClose();
                                dispatch(getOpenBuySubmissions(openBuy.id));
                            }}
                            src={"/close.svg"}
                        />
                    </div>
                }
            />
            <Main>
                {selectSocial ? (
                    <SocialAccountSelector
                        platforms={openBuy.allowedPlatforms || ["tiktok"]}
                        onSelect={(account: SocialAccount) => {
                            if (manageSet) {
                                updateSubmissionSet(account.id);
                            } else if (manageSubmission) {
                                setManageSubmission(old =>
                                    old
                                        ? {
                                              ...old,
                                              socialAccount: account
                                          }
                                        : { socialAccount: account }
                                );
                                setSelectSocial(false);
                            } else {
                                createSubmissionSet(account.id);
                            }
                        }}
                        openBuy={openBuy}
                    />
                ) : manageSubmission ? (
                    <OpenBuySubmissionEditor
                        openBuy={openBuy}
                        step={steps[manageSubmission?.instructionStepId || 0]}
                        submission={manageSubmission}
                        socialAccount={manageSubmission.socialAccount}
                        setSelectSocial={setSelectSocial}
                    />
                ) : manageSet ? (
                    <OpenBuySubmissionSetForm
                        openBuy={openBuy}
                        submissionSetId={manageSet}
                        selectSocial={() => setSelectSocial(true)}
                    />
                ) : (
                    <OpenBuySubmissionSection
                        openBuy={openBuy}
                        steps={steps}
                        treatPublic={treatPublic}
                        setManageSet={(id: number) => {
                            setManageSet(id);
                            setSelectSocial(id ? false : true);
                        }}
                        setManageSubmission={setManageSubmission}
                    />
                )}
            </Main>
        </FullScreenModal>
    );
};
export default OpenBuySubmissionScreenModal;
