import React from "react";
import { useDispatch } from "react-redux";
import { adminFetchOpenBuy } from "src/admin-tools/actions.admin-tools";
import { fetchOpenBuyExtensions } from "src/admin-tools/challenges/challenge-extensions/actions";
import { patch } from "src/Api";
import GlobalWarning from "src/components/GlobalWarning";
import { setPillNotificationText } from "src/ui/notifications/actions";
import StopChallengeModal from "./StopChallengeModal";
import { OpenBuy } from "src/buys/modules/open-buys/types";

interface Props {
    status: "restart" | "end" | "launch";
    openBuy: OpenBuy;
    setWarningTag: React.Dispatch<
        React.SetStateAction<"restart" | "end" | "launch" | null>
    >;
}

const LaunchModal = ({ status, openBuy, setWarningTag }: Props) => {
    const dispatch = useDispatch();

    const handleChallengeControl = () => {
        if (!openBuy) return;
        patch(`/v1/openBuy/${openBuy.id}/${status}`)
            .then(res => {
                dispatch(adminFetchOpenBuy(openBuy.id));

                if (status === "restart")
                    dispatch(fetchOpenBuyExtensions(openBuy.id));

                dispatch(
                    setPillNotificationText(
                        `Successfully ${status}ed ${openBuy.name}`
                    )
                );
            })
            .catch(e => {
                dispatch(
                    setPillNotificationText(
                        `Failed to ${status} ${openBuy.name}`,
                        "danger"
                    )
                );
            });
    };

    const btnText =
        status === "launch"
            ? "Yes, Launch It!"
            : status === "end"
            ? "Yes, End Now"
            : "Yes, Restart Now";

    const title =
        status === "launch"
            ? `Are you sure you want to launch ${openBuy.name}?`
            : status === "end"
            ? `Are you want to end ${openBuy.name}?`
            : `Are you sure you want to restart ${openBuy.name}`;
    const message =
        `${
            openBuy.allPublishers
                ? `All creators with a media score above ${openBuy.creatorScoreMinimum ||
                      0}`
                : `${openBuy.publisherCount} Creators`
        } ` +
        (status === "launch"
            ? `will be able to make submissions.`
            : status === "restart"
            ? `will be able to make submissions. All extensions will be ended.`
            : `will no longer be able to make submissions.`);

    if (status === "end")
        return (
            <StopChallengeModal
                openBuy={openBuy}
                closeModal={() => setWarningTag(null)}
            />
        );
    return (
        <GlobalWarning
            message={message}
            title={title}
            handleCancel={() => setWarningTag(null)}
            handleApply={() => {
                handleChallengeControl();
                setWarningTag(null);
            }}
            btnText={{ cancel: "Cancel", apply: btnText }}
            blueApplyBtn
        />
    );
};

export default LaunchModal;
