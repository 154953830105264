import { combineReducers } from "redux";
import { NormalizedActionGeneric } from "src/types";
import DelayedCampaign from "src/types/DelayedCampaign";
import { FETCH_CLICK_ACTIVITY, FETCH_DELAYED_CAMPAIGNS } from "./actions";

interface ById {
    [key: string]: DelayedCampaign;
}

export interface DelayedCampaignsEntitiesState {
    byId: ById;
    allIds: number[];
}

const initialDelayedCampaignsState: DelayedCampaignsEntitiesState = {
    byId: {},
    allIds: []
};

const byId = (
    state = initialDelayedCampaignsState.byId,
    action: NormalizedActionGeneric<DelayedCampaign, number[]>
) => {
    switch (action.type) {
        case FETCH_DELAYED_CAMPAIGNS.SUCCESS:
            return { ...state, ...action.response.entities.delayedCampaigns };
        case FETCH_CLICK_ACTIVITY.SUCCESS:
            const delayedCampaignId = action.payload.campaignId;
            const delayedCampaign = state[delayedCampaignId];

            return {
                ...state,
                [delayedCampaignId]: {
                    ...delayedCampaign,
                    clickActivity: action.payload.clickActivity
                }
            };
        default:
            return state;
    }
};

const allIds = (
    state = initialDelayedCampaignsState.allIds,
    action: NormalizedActionGeneric<DelayedCampaign, number[]>
) => {
    switch (action.type) {
        case FETCH_DELAYED_CAMPAIGNS.SUCCESS:
            return action.response.result;
        default:
            return state;
    }
};

const delayedCampaignsEntitiesReducer = combineReducers<
    DelayedCampaignsEntitiesState
>({
    byId,
    allIds
});

export default delayedCampaignsEntitiesReducer;
