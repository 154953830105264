import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../../reducers";
import { Action, NormalizedAction } from "../../types";

export const FETCH_USER_SOCIAL_ACCOUNTS = {
    REQUEST: "FETCH_USER_SOCIAL_ACCOUNTS_REQUEST",
    SUCCESS: "FETCH_USER_SOCIAL_ACCOUNTS_SUCCESS",
    FAILURE: "FETCH_USER_SOCIAL_ACCOUNTS_FAILURE"
};

export const fetchUserSocialAccounts = (): Action => ({
    type: FETCH_USER_SOCIAL_ACCOUNTS.REQUEST,
    payload: null
});

export const fetchUserSocialAccountsSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>
): NormalizedAction => ({
    type: FETCH_USER_SOCIAL_ACCOUNTS.SUCCESS,
    response
});

export const fetchUserSocialAccountsFailure = (error: string): Action => ({
    type: FETCH_USER_SOCIAL_ACCOUNTS.FAILURE,
    payload: { error }
});
