import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveModal from "src/modals/ResponsiveModal";
import { GlobalState } from "src/reducers";
import {
    setSocialAccountDetailsModalOpen,
    setSocialAccountFollowersModalOpen,
    setSocialAccountGroupModalOpen
} from "src/ui/social-accounts/actions";
import { fonts, fontWeight } from "src/constants";
import { setAModalIsOpened } from "src/ui/observer/actions";

const Main = styled.section`
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: left;
`;

const Subtitle = styled.h2`
    font-size: ${fonts.semiTitle}px;
    font-weight: ${fontWeight.medium};
`;

interface Props {
    children: React.ReactNode;
    modalType: "details" | "groups" | "followers";
    title: string;
    subtitle: string;
}

const SocialAccountProfileModal = ({
    children,
    modalType,
    title,
    subtitle
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const {
        detailsModalOpen,
        addToGroupModalOpen,
        followersDemographicsModalOpen
    } = useSelector((state: GlobalState) => {
        return state.ui.socialAccounts;
    });

    // Local State ---------------------------------------------------

    // Local Constants -----------------------------------------------

    // Local Functions -----------------------------------------------
    function closeModal(): void {
        if (modalType === "details") {
            dispatch(setSocialAccountDetailsModalOpen(false));
        } else if (modalType === "groups") {
            dispatch(setSocialAccountGroupModalOpen(false));
        } else {
            dispatch(setSocialAccountFollowersModalOpen(false, ""));
        }
    }

    // Effects -------------------------------------------------------
    useEffect(() => {
        if (
            detailsModalOpen ||
            addToGroupModalOpen ||
            followersDemographicsModalOpen
        ) {
            dispatch(setAModalIsOpened(true));
        } else {
            dispatch(setAModalIsOpened(false));
        }
    }, [detailsModalOpen, addToGroupModalOpen, followersDemographicsModalOpen]);

    // JSX -----------------------------------------------------------
    return (
        <ResponsiveModal
            modalOpen={
                modalType === "details"
                    ? detailsModalOpen
                    : modalType === "groups"
                    ? addToGroupModalOpen
                    : followersDemographicsModalOpen
            }
            closeModal={closeModal}
            title={title}
            contentMaxWidth={500}
            contentMaxHeight={540}
        >
            <Main>
                <Subtitle>{subtitle}</Subtitle>
                {children}
            </Main>
        </ResponsiveModal>
    );
};

export default SocialAccountProfileModal;
