import { Action } from "src/types";
import { SocialAccountProfileById } from "./social-account-profile.reducer";
import { SocialAccountProfile } from "../../utils/social-accounts.types";

// Fetch Social Account Profile ---------------------------------------------------------------

export const FETCH_SOCIAL_ACCOUNT_PROFILE = {
    REQUEST: "FETCH_SOCIAL_ACCOUNT_PROFILE_REQUEST",
    SUCCESS: "FETCH_SOCIAL_ACCOUNT_PROFILE_SUCCESS",
    FAILURE: "FETCH_SOCIAL_ACCOUNT_PROFILE_FAILURE"
};

export function fetchSocialAccountProfile(id: number): Action {
    return {
        type: FETCH_SOCIAL_ACCOUNT_PROFILE.REQUEST,
        payload: { id }
    };
}

export function fetchSocialAccountProfileSuccess<
    NormalizedSocialAccountProfile
>(byId: NormalizedSocialAccountProfile, allIds: number[]): Action {
    return {
        type: FETCH_SOCIAL_ACCOUNT_PROFILE.SUCCESS,
        payload: { byId, allIds }
    };
}

export function fetchSocialAccountProfileFailure(error: string): Action {
    return {
        type: FETCH_SOCIAL_ACCOUNT_PROFILE.FAILURE,
        payload: { error }
    };
}
