import React, { useState } from "react";
// @ts-ignore
import { withSendBird } from "sendbird-uikit";
import { connect } from "react-redux";
import { GlobalState } from "../../reducers";
import { Connection } from "../../profile/types";
import { ChatModal } from "../reducer";
import { createGroupModal } from "../actions";
import { goBack, replace } from "connected-react-router";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import FullscreenLoader from "../../components/FullscreenLoader";
import { clearModal } from "../../modals/actions";
import CreateGroupModal from "./CreateGroupModal";
import SelectConnectionsScreen from "../components/SelectConnectionsScreen";

interface Props {
    connections?: Connection[] | null;
    modal: ChatModal;
    sdk: any;
    goBack: VoidFunction;
    createGroupModal: VoidFunction;
    replace: (route: string) => void;
    clearModal: VoidFunction;
}

const ChannelCreate = ({
    connections,
    modal,
    sdk,
    goBack,
    createGroupModal,
    replace
}: Props) => {
    const [selectedConnections, setSelectedConnections] = useState<
        Connection[]
    >([]);

    const navbar = (
        <SimpleNavbar
            title="Start New Chat"
            left={<NavButton back onClick={goBack} />}
            right={
                <NavButton
                    text="Create"
                    disabled={selectedConnections.length === 0}
                    onClick={createGroupModal}
                />
            }
        />
    );

    if (!connections) {
        return (
            <>
                {navbar}
                <FullscreenLoader />
            </>
        );
    } else {
        return (
            <>
                {navbar}
                <SelectConnectionsScreen
                    connections={connections}
                    selectedConnections={selectedConnections}
                    setSelectedConnections={setSelectedConnections}
                />
                {modal === ChatModal.createGroup && (
                    <CreateGroupModal
                        sdk={sdk}
                        selectedConnections={selectedConnections}
                        onCreate={channelUrl => {
                            clearModal();
                            replace(`/chat/channel/${channelUrl}`);
                        }}
                    />
                )}
            </>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    connections: state.profile.connections,
    modal: state.chat.modal
});

const mapDispatchToProps = (dispatch: any) => ({
    goBack: () => dispatch(goBack()),
    createGroupModal: () => dispatch(createGroupModal()),
    replace: (route: string) => dispatch(replace(route)),
    clearModal: () => dispatch(clearModal())
});

export default withSendBird(
    connect(mapStateToProps, mapDispatchToProps)(ChannelCreate),
    (state: any) => ({
        sdk: state.stores.sdkStore.sdk
    })
);
