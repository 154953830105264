import { GlobalState } from "src/reducers";

export const selectConversationIdBySubmissionSetId = (
    state: GlobalState,
    submissionSetId: number
) => {
    return state.entities.openBuySubmissionSets[submissionSetId].conversationId;
};

export const isSubmissionApprovalInternalNotes = (
    state: GlobalState,
    conversationId: string
) => {
    const conversation = state.entities.conversations.byId[conversationId];
    return !!conversation.submissionSetId;
};
