import { push } from "connected-react-router";
import React from "react";
import { useDispatch } from "react-redux";
import { useIntercom } from "react-use-intercom";
import EmojiModal from "src/modals/EmojiModal";
import Modal from "src/modals/Modal";
import { Section, Title } from "../../../profile/modals/styledComponents";
import {
    BigBlueButton,
    BlueModalButton,
    OutlineButton
} from "src/profile/components/Buttons";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

const Row = styled.div`
    display: flex;
    gap: 10px;
    padding-top: 10px;
`;

const Message = styled.div`
    font-size: 18px;
    font-weight: 600;
`;

interface Props {
    onClose: () => void;
    isPublic?: boolean;
}

const NoAccessModal = ({ onClose, isPublic }: Props) => {
    const dispatch = useDispatch();
    const { boot, showNewMessages } = useIntercom();
    if (isPublic)
        return (
            <EmojiModal emoji="👋" localClearModal={onClose}>
                <Title>Join the Plug to enter this Challenge!</Title>
                <Section>Set up a free account to get started.</Section>
                <BlueModalButton onClick={() => dispatch(push("/onboarding"))}>
                    Sign Up
                </BlueModalButton>
            </EmojiModal>
        );

    return (
        <Modal onClick={onClose}>
            <Main>
                <Message>
                    This Challenge is for select Plug Users only. You do not
                    have access
                </Message>
                <Row>
                    <OutlineButton style={{ width: "100%" }} onClick={onClose}>
                        Close
                    </OutlineButton>
                    <BigBlueButton
                        onClick={() => {
                            boot({
                                customAttributes: {
                                    disable_chat_bot: false
                                }
                            });
                            showNewMessages();
                        }}
                    >
                        Request Access
                    </BigBlueButton>
                </Row>
            </Main>
        </Modal>
    );
};
export default NoAccessModal;
