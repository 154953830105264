import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexCentered } from "src/components/CommonStyles";
import ProfilePhoto from "src/components/ProfilePhoto";
import { CustomLoading } from "src/components/SkeletonLoader";
import { colors } from "src/constants";
import useCachedProfiles from "src/hooks/useCachedProfiles";
import { OutlineButton } from "src/profile/components/Buttons";
import GenericContainer from "src/profile/components/GenericContainer";
import { GlobalState } from "src/reducers";
import Platforms from "src/social-accounts/Platforms";
import { shortenNumber } from "src/util";
import styled from "styled-components";
import { getChatRoomInfo } from "../selectors";
import { isSubmissionApprovalInternalNotes } from "../approval-internal-notes/selectors";

const Main = styled(GenericContainer)`
    ${FlexCentered};
    flex-direction: column;
    background-color: ${colors.white};
    margin: 23px 0px 20px;
    padding: 16px 0px;
`;

const Title = styled.div`
    margin-top: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
`;

const Tagline = styled.div`
    margin-top: 4px;
    color: ${colors.primaryGray};
    font-size: 10px;
    line-height: 12px;
`;

const GroupDescription = styled.div`
    margin-top: 12px;
    color: ${colors.primaryGray};
`;

const Button = styled(OutlineButton)`
    margin-top: 12px;
    padding: 7px 16px;
    font-size: 12px;
`;

interface Props {
    conversationId: string;
}

const ChatSummaryCard = ({ conversationId }: Props) => {
    const dispatch = useDispatch();
    const info = useSelector((state: GlobalState) =>
        getChatRoomInfo(state, conversationId)
    );
    const { profile, fetchProfile } = useCachedProfiles(info?.userId);
    const isNotes = useSelector((state: GlobalState) =>
        isSubmissionApprovalInternalNotes(state, conversationId)
    );

    useEffect(() => {
        if (info && !info.isGroupChat) {
            fetchProfile(info.userId);
        }
    }, [info?.userId]);

    if (info && info.isGroupChat) {
        return (
            <Main>
                <ProfilePhoto
                    photoSize={60}
                    profilePhotoUrl={info.imageUrl || null}
                />
                <Title>{info.displayName}</Title>
                <Tagline>
                    <b>{info.usernames.length}</b> members
                </Tagline>
                {info.groupDescription && (
                    <GroupDescription>{info.groupDescription}</GroupDescription>
                )}
                {!isNotes && (
                    <Button
                        onClick={() =>
                            dispatch(
                                push(`/newchat/${conversationId}/settings`, {
                                    internal: true
                                })
                            )
                        }
                    >
                        View Group Details
                    </Button>
                )}
            </Main>
        );
    }

    if (info && !info.isGroupChat && profile) {
        return (
            <Main>
                <ProfilePhoto
                    photoSize={60}
                    profilePhotoUrl={info.imageUrl || null}
                />
                <Title>{info.displayName}</Title>
                <Tagline>
                    <b>
                        {shortenNumber(
                            profile.profile.tagLine.followerCount || 0
                        )}
                    </b>{" "}
                    on{" "}
                    {
                        Platforms[profile.profile.tagLine.platform || "other"]
                            .title
                    }
                </Tagline>
                <Button
                    onClick={() =>
                        dispatch(push(`/public/${profile.id.slice(2)}`))
                    }
                >
                    View Profile
                </Button>
            </Main>
        );
    }

    return (
        <Main>
            <ProfilePhoto photoSize={60} profilePhotoUrl={null} />
            <Title>
                <CustomLoading height="12px" width="100px" />
            </Title>
            <Tagline>
                <CustomLoading height="12px" width="120px" />
            </Tagline>
        </Main>
    );
};
export default ChatSummaryCard;
