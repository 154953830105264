import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";

import HalfScreenModal from "src/modals/HalfScreenModal";
import { fonts, colors } from "src/constants";
import { TextLoading } from "src/components/SkeletonLoader";
import { List } from "src/campaigns/details/Details.styled";
import { getSelected } from "src/campaigns/utils/sortFilterUtils";
import Divider from "src/components/Divider";
import { Selected } from "src/campaigns/types";
import { GlobalState } from "src/reducers";
import { selectGenres } from "src/ui/campaigns/selectors";

const Title = styled(motion.div)`
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fonts.subtitle}px;
    font-weight: 700;
`;

const GenreList = styled(motion.div)`
    border: 1px solid ${colors.mediumTeal};
    border-radius: 10px;
    margin: 20px 16px;
    max-height: 30vh;
    overflow-y: auto;

    & > * ~ * {
        border-top: 1px solid ${colors.mediumTeal};
    }
`;

const Error = styled.div`
    color: ${colors.primaryRed};
    font-size: 12px;
`;

const Cancel = styled.div`
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
`;

const Save = styled(Cancel)`
    background-color: ${colors.primaryBlue};
    color: white;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

const GenreItem = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const Checkbox = styled.img``;

interface Props {
    localClearModal: () => void;
    keepFrozenOnClose?: true;
    genres: string[];
    setGenreConfig: (genres: string[], allGenres: boolean) => void;
}

const CampaignGenreModal = ({
    localClearModal,
    keepFrozenOnClose,
    genres, // global genres in store
    setGenreConfig
}: Props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const displayGenres = useSelector((state: GlobalState) =>
        selectGenres(state)
    );
    const [selectedGenres, setSelectedGenres] = useState<Selected>({});
    const [allGenresSelected, setAllGenres] = useState(
        genres.length === displayGenres.length || !genres.length
    );

    useEffect(() => {
        if (displayGenres.length > 0) setIsLoaded(true);
        if (genres.length) {
            setSelectedGenres(getSelected(genres));
        }
    }, []);

    useEffect(() => {
        if (allGenresSelected) setSelectedGenres(getSelected(displayGenres));
    }, [allGenresSelected]);

    const toggleAllGenres = () => {
        setAllGenres(!allGenresSelected);
        setSelectedGenres({});
    };

    const toggleSelection = (genre: string) => {
        if (allGenresSelected) toggleAllGenres();

        const nextState = { ...selectedGenres };
        if (selectedGenres[genre]) {
            delete nextState[genre];
        } else {
            nextState[genre] = true;
        }
        setSelectedGenres(nextState);
    };

    const saveAndClose = () => {
        setGenreConfig(Object.keys(selectedGenres), allGenresSelected);
        localClearModal();
    };

    return (
        <HalfScreenModal
            closeModal={localClearModal}
            keepFrozenOnClose={keepFrozenOnClose}
        >
            <Title layout="position" transition={{ duration: 0.3 }}>
                Select Genres
            </Title>
            {!isLoaded ? (
                <List>
                    <TextLoading height={41} />
                    <TextLoading height={41} />
                    <TextLoading height={41} />
                    <TextLoading height={41} />
                </List>
            ) : (
                <>
                    {!allGenresSelected &&
                        !Object.keys(selectedGenres).length && (
                            <Error>Please select at least one genre</Error>
                        )}
                    <GenreList>
                        <GenreItem onClick={toggleAllGenres}>
                            Select All
                            {allGenresSelected ? (
                                <Checkbox src={"/checkbox_selected.svg"} />
                            ) : (
                                <Checkbox src={"/checkbox_unselected.svg"} />
                            )}
                        </GenreItem>
                    </GenreList>
                    <GenreList layout="position" transition={{ duration: 0.3 }}>
                        {displayGenres.map((genre: string, idx: number) => (
                            <GenreItem
                                key={idx}
                                onClick={() => toggleSelection(genre)}
                            >
                                {genre}
                                {selectedGenres[genre] ? (
                                    <Checkbox src={"/checkbox_selected.svg"} />
                                ) : (
                                    <Checkbox
                                        src={"/checkbox_unselected.svg"}
                                    />
                                )}
                            </GenreItem>
                        ))}
                    </GenreList>
                </>
            )}
            <motion.div layout="position" transition={{ duration: 0.3 }}>
                <Divider />
                {Object.keys(selectedGenres).length || allGenresSelected ? (
                    <Save onClick={saveAndClose}>Done</Save>
                ) : (
                    <Cancel onClick={localClearModal}>Cancel</Cancel>
                )}
            </motion.div>
        </HalfScreenModal>
    );
};

export default CampaignGenreModal;
