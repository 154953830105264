import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../reducers";
import { fetchCampaignPublicMedias } from "../../medias/actions";
import { getMedias, selectCampaign } from "../../selectors";
import MediasAccordion from "./MediasAccordion";
import withAccordion from "./withAccordion";

const PublicCampaignMediasAccordionContainer = () => {
    const currentId = useSelector((state: GlobalState) => {
        return state.ui.campaign.currentCampaignId;
    });

    const campaign = useSelector(selectCampaign);

    const mediasLoading = useSelector((state: GlobalState) => {
        return state.ui.campaign.mediasLoading;
    });

    const medias = useSelector(getMedias);

    const dispatch = useDispatch();

    useEffect(() => {
        if (currentId) dispatch(fetchCampaignPublicMedias(currentId));
    }, [currentId]);

    function fetchMedias(campaignId: number): void {
        dispatch(fetchCampaignPublicMedias(campaignId));
    }

    return (
        <>
            <MediasAccordion
                name="campaign"
                currentId={currentId}
                campaign={campaign}
                mediasLoading={mediasLoading}
                medias={medias}
                fetchMedias={fetchMedias}
            />
        </>
    );
};

export default withAccordion(PublicCampaignMediasAccordionContainer);
