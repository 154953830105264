import React from "react";
import { Route, Switch } from "react-router";
import AccountsPage from "./page";
import AccountFormController from "./form";
import AccountViewController from "./view";
import { Profile, PublicProfile } from "../../types";
import RateEdit from "src/profile/rates/modals/RateEdit";

interface Props {
    profile: Profile;
    publicProfile: PublicProfile;
}

const AccountsRouter = ({ profile, publicProfile }: Props) => {
    return (
        <Switch>
            <Route
                path={"/profile/accounts/add"}
                render={() => <AccountFormController />}
            />
            <Route
                path={"/profile/accounts/edit"}
                render={() => <AccountFormController edit />}
            />
            <Route
                path={"/profile/accounts/view"}
                render={() => (
                    <AccountViewController publicProfile={publicProfile} />
                )}
            />
            <Route path="/profile/accounts/rates" component={RateEdit} />
            <Route
                path={"/profile/accounts/visibility/:visibilityId"}
                render={({ match }) => (
                    <AccountsPage
                        profile={profile}
                        visibilityId={match.params.visibilityId}
                    />
                )}
            />
            <Route
                path={"/profile/accounts"}
                render={() => <AccountsPage profile={profile} />}
            />
        </Switch>
    );
};

export default AccountsRouter;
