import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    PublisherEarnings,
    EarningsSummary,
    WeeklyLeaderboardItem
} from "../../types";
import WeeklyHeader from "./WeeklyHeader";
import WeeklyTopThree from "./WeeklyTopThree";
import WeeklyCell from "./WeeklyCell";
import { BigOutlineButton } from "../../components/Buttons";
import CardLoader from "../../components/CardLoader";
import FullscreenLoader from "../../../components/FullscreenLoader";
import { GlobalState } from "../../../reducers";
import { bracketsModal, fetchWeeklyLeaderboard } from "../../actions";
import { push } from "connected-react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import PaddingAround from "../../../components/PaddingAround";
import XCard from "../../components/XCard";
import MainContainer from "src/components/MainContainer";
import { get } from "src/Api";
import LeaderboardModal from "src/components/modals/LeaderboardModal";
import PublisherModal from "./PublisherModal";
import { getLeaderboard, getEarnings } from "src/profile/selectors";
import { colors } from "src/constants";

const Main = styled("div")`
    padding: 0px 18px 18px;
`;

const List = styled("div")`
    padding: 20px 0px;
`;

const BonusText = styled("span")`
    display: block;
    margin: 18px 0px;
    color: ${colors.primaryGray};
    font-size: 0.875rem;
    line-height: 1.375rem;
    text-align: center;
    letter-spacing: -0.01125rem;
`;

const WeeklyCard = () => {
    const dispatch = useDispatch();
    const weeklyLeaderboard = useSelector(getLeaderboard);
    const earningsSummary = useSelector(getEarnings);
    const [viewLeaderboard, setViewLeaderboard] = useState(false);
    const [publisherEarnings, setPublisherEarnings] = useState<
        PublisherEarnings[] | undefined
    >();
    const [publisher, setPublisher] = useState<
        WeeklyLeaderboardItem | undefined
    >();
    if (weeklyLeaderboard === null) {
        return (
            <MainContainer>
                <PaddingAround>
                    <XCard
                        text="Failed to load leaderboard, click the button below to try again."
                        button={{
                            text: "Try again",
                            callback: fetchWeeklyLeaderboard
                        }}
                    />
                </PaddingAround>
            </MainContainer>
        );
    } else if (!weeklyLeaderboard) {
        return <CardLoader />;
    }

    const self = weeklyLeaderboard.find(leaderboardItem => {
        return leaderboardItem.type === "own";
    }) || {
        rank: 0,
        week: "na",
        earnings: "$0",
        type: "own",
        profilePhotoUrl: "",
        bonusPercentage: 0
    };

    const createList = () => {
        if (self.rank < 6 && self.rank !== 0) {
            return weeklyLeaderboard.slice(3, 6);
        } else {
            return [weeklyLeaderboard[3], weeklyLeaderboard[4], self];
        }
    };

    const onSelect = (publisher: WeeklyLeaderboardItem) => {
        setPublisher(publisher);
        setViewLeaderboard(false);
        get(`/v1/leaderboard/earning`, {
            type: "weekly_bonus",
            rank: publisher.rank,
            removeDollar: true
        }).then(res => {
            setPublisherEarnings(res.data.data);
        });
    };

    return (
        <Main>
            <MainContainer>
                <WeeklyHeader
                    plugcoins={earningsSummary.weekly || 0}
                    bonusPercentage={self ? self.bonusPercentage : 0}
                    bracketsModal={() => dispatch(bracketsModal())}
                />
                <WeeklyTopThree
                    weeklyLeaderboard={weeklyLeaderboard}
                    onSelect={onSelect}
                />
                <List>
                    {createList().map((leaderboardItem, idx) => {
                        return (
                            <WeeklyCell
                                key={idx}
                                leaderboardItem={leaderboardItem}
                                onSelect={onSelect}
                            />
                        );
                    })}
                </List>
                <BigOutlineButton onClick={() => setViewLeaderboard(true)}>
                    See Leaderboard
                </BigOutlineButton>
                {publisher && (
                    <PublisherModal
                        closeCallback={() => {
                            setPublisher(undefined);
                            setPublisherEarnings(undefined);
                        }}
                        publisher={publisher}
                        earnings={publisherEarnings}
                    />
                )}
                {viewLeaderboard && (
                    <LeaderboardModal
                        weeklyLeaderboard={weeklyLeaderboard}
                        closeCallback={() => setViewLeaderboard(false)}
                        onSelect={onSelect}
                    />
                )}
            </MainContainer>
            <BonusText>Bonus paid Monday 5pm PST</BonusText>
        </Main>
    );
};

export default WeeklyCard;
