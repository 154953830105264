import React, { useEffect, useRef, useState } from "react";
import MainContainer from "src/components/MainContainer";
import { CustomLoading } from "src/components/SkeletonLoader";
import { breakpoints, maxWidth, spacing } from "src/constants";
import ChallengeGeneralButtonFooter from "src/marketplace/challenge-creation-form/components/ChallengeGeneralButtonFooter";
import ChallengeRules, {
    ChallengeRulesRef
} from "src/marketplace/challenge-creation-form/components/ChallengeRules";
import MarketplacePagePartialHeader from "src/marketplace/components/MarketplacePagePartialHeader";
import { get } from "src/new-chat/saga";
import styled from "styled-components";

const Main = styled(MainContainer)`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 82px;

    @media (max-width: ${breakpoints.tablet}px) {
        padding: 0px ${spacing.sideSpacing}px;
    }

    @media (max-width: ${maxWidth.desktop}px) {
        padding-top: 20px;
    }
`;

interface Props {}

const PlugMediaStandards = ({}: Props) => {
    const [hasRuleChanges, setHasRuleChanges] = useState<boolean>(false);
    const [standardsId, setStandardsId] = useState<number | null>(null);
    const rulesRef = useRef<ChallengeRulesRef>(null);

    const getStandards = async () => {
        try {
            let res = await get<{ id: string; ruleSetId: number }>(
                "ChallengesStandardMediaRulesSetId",
                "GzADIFgd3zDfZAgtQF1Y"
            );
            if (res?.ruleSetId) setStandardsId(res?.ruleSetId);
        } catch (err) {
            console.error(`error getting standards: ${err}`);
        }
    };

    useEffect(() => {
        getStandards();
    }, []);

    return (
        <>
            <Main>
                <MarketplacePagePartialHeader
                    includeHeading
                    customCrumbs={["Admin Tools"]}
                    endCrumb="Plug Media Standards"
                />
                {!!standardsId ? (
                    <ChallengeRules
                        ref={rulesRef}
                        ruleSetId={standardsId}
                        setHasRuleChanges={setHasRuleChanges}
                        displayStandards
                    />
                ) : (
                    <CustomLoading height="30vh" />
                )}

                <ChallengeGeneralButtonFooter
                    buttons={[
                        {
                            text: "Save Changes",
                            action: () => {
                                if (hasRuleChanges) {
                                    rulesRef.current?.handleSaveRules();
                                }
                            }
                        }
                    ]}
                />
            </Main>
        </>
    );
};
export default PlugMediaStandards;
