import React, { useState } from "react";
import AuthInputScreen, { ClearInput } from "./AuthInputScreen";
import FullscreenLoader from "src/components/FullscreenLoader";
import { Redirect } from "react-router";
import { formatBirthday, isValidBirthday, isValidEmail } from "src/util";
import styled from "styled-components";
import { WhitePillButton } from "src/components/buttons/PillButton";
import { useSelector } from "react-redux";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const WhiteButton = styled(WhitePillButton)`
    width: 100%;
`;

enum DetailsScreen {
    firstname,
    lastname,
    email,
    birthday
}

interface Props {
    firstname: string;
    setFirstname: (firstname: string) => void;
    lastname: string;
    setLastname: (lastname: string) => void;
    email: string;
    setEmail: (email: string) => void;
    birthday: string;
    setBirthday: (birthday: string) => void;
    onSubmit: () => void;
}

const PersonalDetailsScreens = ({
    firstname,
    setFirstname,
    lastname,
    setLastname,
    email,
    setEmail,
    birthday,
    setBirthday,
    onSubmit
}: Props) => {
    const delayName = useSelector(
        selectFeatureFlags(featureFlags.DelayNameOnboarding)
    );
    const delayEmail = useSelector(
        selectFeatureFlags(featureFlags.DelayEmailOnboarding)
    );
    const delayBirthday = useSelector(
        selectFeatureFlags(featureFlags.DelayBirthdayOnboarding)
    );

    const [currentScreen, setCurrentScreen] = useState(
        delayName
            ? delayEmail
                ? DetailsScreen.birthday
                : DetailsScreen.email
            : DetailsScreen.firstname
    );

    function formatBirthdayOnChange(e: any) {
        const rawInput = e.currentTarget.value;
        const cleanInput = rawInput.split("/").join("");

        if (cleanInput.match(/^-{0,1}\d+$/)) {
            const formattedBirthday = formatBirthday(rawInput, cleanInput);
            setBirthday(formattedBirthday);
        } else {
            setBirthday("");
        }
    }

    if (delayName && delayEmail && delayBirthday) {
        return <Redirect to={"/social-accounts"} />;
    } else if (currentScreen === DetailsScreen.firstname) {
        return (
            <AuthInputScreen
                description={`What's your first name?`}
                input={
                    <ClearInput
                        placeholder="First name"
                        value={firstname}
                        onChange={(e: any) =>
                            setFirstname(e.currentTarget.value)
                        }
                    />
                }
                button={
                    <WhiteButton
                        disabled={firstname.length === 0}
                        onClick={() =>
                            firstname.length > 0 &&
                            setCurrentScreen(DetailsScreen.lastname)
                        }
                    >
                        Next
                    </WhiteButton>
                }
            />
        );
    } else if (currentScreen === DetailsScreen.lastname) {
        return (
            <AuthInputScreen
                backClicked={() => setCurrentScreen(DetailsScreen.firstname)}
                description={`What's your last name?`}
                input={
                    <ClearInput
                        placeholder="Last name"
                        value={lastname}
                        onChange={(e: any) =>
                            setLastname(e.currentTarget.value)
                        }
                    />
                }
                button={
                    <WhiteButton
                        disabled={lastname.length === 0}
                        onClick={() =>
                            lastname.length > 0 &&
                            (delayEmail
                                ? delayBirthday
                                    ? onSubmit
                                    : setCurrentScreen(DetailsScreen.birthday)
                                : setCurrentScreen(DetailsScreen.email))
                        }
                    >
                        Next
                    </WhiteButton>
                }
            />
        );
    } else if (currentScreen === DetailsScreen.email) {
        return (
            <AuthInputScreen
                backClicked={() => setCurrentScreen(DetailsScreen.lastname)}
                description={`What's your email?`}
                input={
                    <ClearInput
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e: any) => setEmail(e.currentTarget.value)}
                    />
                }
                button={
                    <WhiteButton
                        disabled={!isValidEmail(email)}
                        onClick={() =>
                            isValidEmail(email) &&
                            setCurrentScreen(DetailsScreen.birthday)
                        }
                    >
                        Next
                    </WhiteButton>
                }
            />
        );
    } else if (currentScreen === DetailsScreen.birthday) {
        return (
            <AuthInputScreen
                backClicked={
                    delayEmail
                        ? () => setCurrentScreen(DetailsScreen.lastname)
                        : () => setCurrentScreen(DetailsScreen.email)
                }
                description={`What's your birthday?`}
                input={
                    <ClearInput
                        placeholder="MM/DD/YYYY"
                        value={birthday}
                        onChange={formatBirthdayOnChange}
                    />
                }
                button={
                    <WhiteButton
                        disabled={!isValidBirthday(birthday)}
                        onClick={() => isValidBirthday(birthday) && onSubmit()}
                    >
                        Next
                    </WhiteButton>
                }
            />
        );
    } else {
        return <FullscreenLoader offset={57} color={"white"} />;
    }
};

export default PersonalDetailsScreens;
