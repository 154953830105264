import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { breakpoints, colors } from "src/constants";
import Modal from "src/modals/Modal";
import { GlobalState } from "src/reducers";
import styled, { css } from "styled-components";
import { fetchCampaignBudget } from "../actions";
import { Campaign } from "../types";
import { OldCampaign } from "src/campaigns/types";
import BudgetNotification from "./components/BudgetNotification";

const Tag = styled.div`
    display: flex;
    align-items: center;
    padding: 6px;
    height: 22px;
    flex-direction: row;
    background: ${colors.secondaryYellow};
    border-radius: 12px;
    color: ${colors.white};
    font-weight: 600;
    font-size: 11px;
    margin-top: 10px;
`;

const BudgetTag = ({ campaign }: { campaign: Campaign }) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const promoted = useSelector(
        (state: GlobalState) => state.profile.promotedCampaigns
    );
    const isPromoted = promoted?.find(
        (camp: OldCampaign) => camp.id === campaign.id
    );

    useEffect(() => {
        dispatch(fetchCampaignBudget(campaign.id));
    }, []);
    const budget = campaign.budget;
    if (!budget || !budget.isLow) return null;
    return (
        <>
            <Tag
                onClick={(e?: React.MouseEvent<HTMLElement>) => {
                    e?.stopPropagation();
                    setOpen(true);
                }}
            >
                <img
                    style={{ marginRight: 4 }}
                    src="/campaigns/activity-icon.svg"
                />
                Budget Low!
            </Tag>

            {open && (
                <Modal
                    onClick={(e?: React.MouseEvent<HTMLElement>) => {
                        e?.stopPropagation();
                        setOpen(false);
                    }}
                >
                    <BudgetNotification
                        isPromoted={!!isPromoted}
                        campaign={campaign}
                        closeModal={() => setOpen(false)}
                    />
                </Modal>
            )}
        </>
    );
};

export default BudgetTag;
