import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setScrollRestorationPosition } from "src/ui/scroll-restoration/actions";
import { debounce } from "src/util";

const useScrollRestoration = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const listener = debounce((e: any) => {
            dispatch(
                setScrollRestorationPosition(location.pathname, window.scrollY)
            );
        }, 100);

        window.addEventListener("scroll", listener, true);

        return () => {
            window.removeEventListener("scroll", listener, true);
        };
    }, [location]);
};

export default useScrollRestoration;
