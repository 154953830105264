import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import PaymentTypeDropdown from "./PaymentTypeDropdown";
import SingleInputPayment from "./SingleInputPayment";
import { GreyContainer, Section, SmallTitle } from "./styledComponents";
import ViewThresholdsBar from "./ViewThresholdsBar";
import {
    AbstractPaymentType,
    InstructionStepPayoutTiming,
    PaymentConjunction,
    PendingOpenBuyPaymentItem
} from "../types";
import { getAbstractPaymentTypeFromPendingOpenBuyPayment } from "../open-buy-payments/util";
import { updatePendingOpenBuyPaymentsGroup } from "../open-buy-payments/actions";
import PaymentCreatorGroupPublishers from "./PaymentCreatorGroupPublishers";

const SectionTitle = styled(SmallTitle)`
    text-transform: uppercase;
`;
interface Props {
    openBuyId: number;
    instructionStepId: number;
    creatorGroupId: number | null;
    paymentConjunction: PaymentConjunction;
    pendingOpenBuyPaymentItems: PendingOpenBuyPaymentItem[];
    fixedAbstractionPaymentType?: AbstractPaymentType;
    setCurrentCreatorGroupId?: (creatorGroupId: number) => void;
}

const PaymentBucket = ({
    openBuyId,
    instructionStepId,
    creatorGroupId,
    paymentConjunction,
    pendingOpenBuyPaymentItems,
    fixedAbstractionPaymentType,
    setCurrentCreatorGroupId
}: Props) => {
    const dispatch = useDispatch();

    const [
        abstractPaymentType,
        setAbstractPaymentType
    ] = useState<AbstractPaymentType | null>(
        fixedAbstractionPaymentType ||
            getAbstractPaymentTypeFromPendingOpenBuyPayment(
                paymentConjunction,
                pendingOpenBuyPaymentItems
            )
    );

    function handleSetAbstractPaymentType(
        newAbstractPaymentType: AbstractPaymentType
    ) {
        if (
            instructionStepId &&
            newAbstractPaymentType !== abstractPaymentType
        ) {
            setAbstractPaymentType(newAbstractPaymentType);
            switch (newAbstractPaymentType) {
                case AbstractPaymentType.dollarsPerK:
                    dispatch(
                        updatePendingOpenBuyPaymentsGroup({
                            openBuyId,
                            instructionStepId,
                            paymentConjunction: PaymentConjunction.and,
                            payoutTiming: InstructionStepPayoutTiming.onApproval
                        })
                    );
                    return;
                case AbstractPaymentType.flatRate:
                    dispatch(
                        updatePendingOpenBuyPaymentsGroup({
                            openBuyId,
                            instructionStepId,
                            paymentConjunction: PaymentConjunction.and,
                            payoutTiming: InstructionStepPayoutTiming.onApproval
                        })
                    );
                    return;
                case AbstractPaymentType.viewThresholds:
                    dispatch(
                        updatePendingOpenBuyPaymentsGroup({
                            openBuyId,
                            instructionStepId,
                            paymentConjunction: PaymentConjunction.orGreater,
                            payoutTiming: InstructionStepPayoutTiming.realtime
                        })
                    );
                    return;
            }
        }
    }

    return (
        <GreyContainer>
            <Section>
                {!fixedAbstractionPaymentType && (
                    <PaymentTypeDropdown
                        abstractPaymentType={abstractPaymentType}
                        setAbstractPaymentType={handleSetAbstractPaymentType}
                    />
                )}
                <Section horizontal>
                    {!abstractPaymentType && (
                        <SectionTitle>
                            Please Select A Payment Type
                        </SectionTitle>
                    )}
                    {abstractPaymentType &&
                        (abstractPaymentType ===
                        AbstractPaymentType.viewThresholds ? (
                            <ViewThresholdsBar
                                openBuyId={openBuyId}
                                instructionStepId={instructionStepId}
                                creatorGroupId={creatorGroupId}
                                pendingOpenBuyPaymentItems={
                                    pendingOpenBuyPaymentItems
                                }
                            />
                        ) : (
                            <SingleInputPayment
                                openBuyId={openBuyId}
                                instructionStepId={instructionStepId}
                                creatorGroupId={creatorGroupId}
                                pendingOpenBuyPaymentItems={
                                    pendingOpenBuyPaymentItems
                                }
                                abstractPaymentType={abstractPaymentType}
                            />
                        ))}
                </Section>
                {creatorGroupId && setCurrentCreatorGroupId && (
                    <PaymentCreatorGroupPublishers
                        creatorGroupId={creatorGroupId}
                        setCurrentCreatorGroupId={setCurrentCreatorGroupId}
                    />
                )}
            </Section>
        </GreyContainer>
    );
};

export default PaymentBucket;
