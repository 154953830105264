import React, { useState } from "react";
import { colors } from "src/constants";
import styled from "styled-components";
import ModalHeading from "./ModalHeading";
import { ReactComponent as Caret } from "src/components/icons/caret-small.svg";

const Wrapper = styled.div`
    background-color: ${colors.quaternaryLightGray};
    border-radius: 8px;
    padding: 12px;
    margin: 0 4px;
    position: relative;
`;

const StyledModalHeading = styled(ModalHeading)`
    padding-bottom: 16px;
`;

const InnerWrap = styled.div<{
    open: boolean;
    innerHeight: number;
    closable?: boolean;
}>`
    transition: ${props => (props.closable ? "height 0.4s ease" : "none")};
    height: ${props => (props.open ? `${props.innerHeight}px` : "0px")};
    overflow: hidden;
`;
const Toggle = styled.div`
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
`;
const Circle = styled.div`
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: ${colors.black};
    display: inline-block;
    margin-left: 8px;
    display: grid;
    place-items: center;
`;
const StyledCaret = styled(Caret)<{ open: boolean }>`
    transform: ${props => (props.open ? "rotate(180deg)" : "revert")};
`;
const CornerBtns = styled.div`
    position: absolute;
    top: 6px;
    right: 0px;
    display: flex;
`;

const ModalBox = ({
    headingText,
    children,
    className,
    closable,
    cornerBtns,
    defaultOpen = true
}: {
    headingText: string;
    children: React.ReactNode;
    className?: string;
    closable?: boolean;
    defaultOpen?: boolean;
    cornerBtns?: React.ReactNode;
}) => {
    const [open, setOpen] = useState(defaultOpen);
    const [innerHeight, setInnerHeight] = useState(0);

    return (
        <Wrapper>
            <StyledModalHeading text={headingText} />
            <InnerWrap
                innerHeight={innerHeight}
                className={className}
                open={open}
                closable={closable}
            >
                <div
                    ref={ref => {
                        if (ref) {
                            setInnerHeight(ref.clientHeight);
                        }
                    }}
                >
                    {children}
                </div>
            </InnerWrap>
            {closable && (
                <Toggle onClick={() => setOpen(!open)}>
                    {open ? "Show Less" : "Show More"}
                    <Circle>
                        <StyledCaret open={open} />
                    </Circle>
                </Toggle>
            )}
            {cornerBtns && <CornerBtns>{cornerBtns}</CornerBtns>}
        </Wrapper>
    );
};

export default ModalBox;
