import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useScroll } from "react-use";
import { colors } from "src/constants";
import { preventUserInteraction } from "src/utils/styles/snippets";

const Arrows = styled("div")`
    display: ${props => (props.theme.isMobile ? "none" : "flex")};
    align-items: center;
`;

interface ArrowContainerProps {
    marginRight?: true;
    scrolledToEnd: boolean;
}

const ArrowContainer = styled("button")<ArrowContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: ${props => props.marginRight && "8px"};
    background-color: ${props => {
        return props.scrolledToEnd ? "rgba(0, 0, 0, 0.2)" : colors.white;
    }};
    border: none;
    border-radius: 50%;
    outline: none;
    cursor: default;

    :focus {
        outline: none;
    }

    ${props => {
        if (!props.scrolledToEnd) {
            return css`
                @media (hover: hover) {
                    :hover {
                        border: ${colors.tertiaryGray} solid 1px;
                    }
                }
            `;
        } else {
            return preventUserInteraction();
        }
    }}
`;

const Arrow = styled.img<{ left?: true }>`
    width: auto;
    height: 8px;
    transform: ${props => props.left && "rotate(180deg)"};
`;

interface Props {
    refElement: React.RefObject<any>;
}

const ScrollArrows = (props: Props) => {
    const { refElement } = props;

    const [scrolledToFarLeft, setScrolledToFarLeft] = useState(true);
    const [scrolledToFarRight, setScrolledToFarRight] = useState(false);

    const { x } = useScroll(refElement);

    useEffect(() => {
        if (refElement && refElement.current) {
            // Scrolled all the way to the right.
            if (
                x ===
                refElement.current.scrollWidth - refElement.current.clientWidth
            ) {
                setScrolledToFarLeft(false);
                setScrolledToFarRight(true);
            }

            // Scrolled all the way to the left.
            else if (x === 0) {
                setScrolledToFarLeft(true);
                setScrolledToFarRight(false);
            }

            // Not scrolled all the way to the left, nor to the right.
            else if (x > 0) {
                setScrolledToFarLeft(false);
                setScrolledToFarRight(false);
            }
        }
    }, [refElement, x]);

    function scrollLeft(event: React.MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();
        if (refElement && refElement.current) {
            const xPosition = refElement.current.scrollLeft;
            const containerWidth = refElement.current.getBoundingClientRect()
                .width;

            refElement.current.scrollTo({
                left: xPosition - containerWidth,
                behavior: "smooth"
            });

            setScrolledToFarRight(false);
        }
    }

    function scrollRight(event: React.MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();
        if (refElement && refElement.current) {
            const xPosition = refElement.current.scrollLeft;
            const containerWidth = refElement.current.getBoundingClientRect()
                .width;

            refElement.current.scrollTo({
                left: xPosition + containerWidth,
                behavior: "smooth"
            });

            setScrolledToFarLeft(false);
        }
    }

    return (
        <Arrows>
            {/* Left Arrow */}
            <ArrowContainer
                onClick={scrollLeft}
                scrolledToEnd={scrolledToFarLeft}
                marginRight
            >
                <Arrow src="/caret-hovered.svg" left />
            </ArrowContainer>

            {/* Right Arrow */}
            <ArrowContainer
                onClick={scrollRight}
                scrolledToEnd={scrolledToFarRight}
            >
                <Arrow src="/caret-hovered.svg" />
            </ArrowContainer>
        </Arrows>
    );
};

export default ScrollArrows;
