import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestOpenBuysByStatus } from "src/buys/actions";
import { selectOpenBuysById } from "src/buys/selectors";
import CheckBox from "src/components/CheckBox";
import ModalBox from "src/components/modals/components/ModalBox";
import PlugTable from "src/components/table/PlugTable";
import { TableColumn, TableRow } from "src/components/table/types";
import { createCell } from "src/components/table/utils";
import { colors } from "src/constants";
import styled from "styled-components";
import StatusCell from "../StatusCell";
import PastSubmissionModal from "./PastSubmissionModal";
import {
    OpenBuySubmission,
    OpenBuySubmissionSet
} from "src/buys/modules/open-buys/types";
import { get } from "src/Api";

const columns: TableColumn[] = [
    {
        coreKey: "challengeName",
        displayValue: "Challenge",
        sortType: "string"
    },
    { coreKey: "status", displayValue: "Status", sortType: "string" },
    { coreKey: "type", displayValue: "Type", sortType: "string" }
];

const CheckboxWrap = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 8px;
`;
const StyledCheckBox = styled(CheckBox)`
    margin-left: 6px;
    border-radius: 5px;
    background-color: white;
`;

const PastSubmissions = ({
    submissionSet
}: {
    submissionSet: OpenBuySubmissionSet;
}) => {
    const dispatch = useDispatch();
    const [viewSubmission, setViewSubmission] = useState<null | any>(null);
    const [onlyCurrentChallenge, setOnlyCurrentChallenge] = useState(true);
    const [submissions, setSubmissions] = useState([]);
    const [loading, setLoading] = useState(false);

    const openBuys = useSelector(selectOpenBuysById);

    const getPastSubmissions = async () => {
        try {
            setLoading(true);
            let res = await get(
                `/v1/admin/publisher/${submissionSet.publisherId}/pastSubmissions`,
                {
                    openBuyId: onlyCurrentChallenge
                        ? submissionSet.openBuyId
                        : undefined
                }
            );
            setSubmissions(res.data.data);
        } catch (err) {
            console.error("error getting past submissions");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const submissionOpenBuyIDSet = new Set<number>(
            submissions.map((s: any) => s.openBuyId)
        );

        for (const key of Array.from(submissionOpenBuyIDSet)) {
            if (!openBuys[key]) {
                dispatch(requestOpenBuysByStatus());
                break;
            }
        }
    }, [openBuys, submissions]);

    useEffect(() => {
        if (!loading) getPastSubmissions();
    }, [submissionSet, onlyCurrentChallenge]);

    const tableData: TableRow[] = submissions
        .filter(
            (sub: OpenBuySubmission) =>
                !submissionSet.submissions.map(sub => sub.id).includes(sub.id)
        )
        .map((submission: any, idx: number) => {
            const submissionType = submission.linkSubmission
                ? "link"
                : submission.textSubmission
                ? "text"
                : submission.customMediaId
                ? "media"
                : null;
            return {
                originalIdx: idx,
                raw: submission,
                rowData: {
                    challengeName: createCell.string(
                        openBuys[submission.openBuyId]
                            ? openBuys[submission.openBuyId].name
                            : submission.openBuyId
                    ),
                    type: createCell.string(submissionType),
                    status: createCell.custom(
                        <StatusCell
                            status={submission.status}
                            color={
                                submission.status === "pending"
                                    ? "yellow"
                                    : submission.status === "rejected"
                                    ? "red"
                                    : "green"
                            }
                        />,
                        submission.status
                    )
                },
                rowStyle: submission.deletedAt
                    ? { backgroundColor: colors.primaryRedLight }
                    : {}
            };
        });

    return (
        <>
            <ModalBox headingText="PAST SUBMISSIONS">
                <CheckboxWrap>
                    Only this challenge:
                    <StyledCheckBox
                        selected={onlyCurrentChallenge}
                        onCheck={() => {
                            setLoading(false);
                            setOnlyCurrentChallenge(!onlyCurrentChallenge);
                        }}
                        onUncheck={() => {
                            setLoading(false);
                            setOnlyCurrentChallenge(!onlyCurrentChallenge);
                        }}
                    />
                </CheckboxWrap>
                <PlugTable
                    columns={columns}
                    rowClick={row => setViewSubmission(row.raw)}
                    rowHeight={36}
                    resultsPerPage={4}
                    cellData={tableData}
                />
            </ModalBox>

            {viewSubmission && (
                <PastSubmissionModal
                    submission={viewSubmission}
                    handleClose={() => setViewSubmission(null)}
                />
            )}
        </>
    );
};

export default PastSubmissions;
