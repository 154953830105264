import React from "react";
import { OldSocialAccount } from "./SocialAccount";
import styled from "styled-components";
import Platforms from "./Platforms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../constants";

const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: #4de77f;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    padding: 10px;
    margin-right: 10px;
    border-radius: 100%;
    background-color: ${colors.primaryGreen};
    color: white;
    font-size: 20px;
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 12px;
    text-align: left;
`;

interface Props {
    account: OldSocialAccount;
    onClick?: () => void;
}

const SocialAccountCell = ({ account, onClick }: Props) => {
    const platformObject =
        Platforms[account.platform.replace(/\s+/g, "").toLowerCase()] ||
        Platforms["other"];

    return (
        <ItemContainer
            onClick={() => {
                onClick && onClick();
            }}
        >
            <IconContainer>
                <FontAwesomeIcon icon={platformObject.icon} />
            </IconContainer>
            <DetailsContainer>
                <div>{`@${account.username}`}</div>
                <div>{`${account.num_followers} ${platformObject.countPlaceholder}`}</div>
            </DetailsContainer>
        </ItemContainer>
    );
};

export default SocialAccountCell;
