import { push } from "connected-react-router";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import NavButton from "src/components/NavButton";
import SimpleNavbar from "src/components/SimpleNavbar";
import { colors, plugIconURL } from "src/constants";
import { GlobalState } from "src/reducers";
import Platforms from "src/social-accounts/Platforms";
import { setLinkListType } from "src/ui/link-lists/actions";
import styled from "styled-components";
import LinkCampaignPlatformSelector, {
    LinkSelectorOption
} from "./components/LinkCampaignPlatformSelector";
import LinkEdit from "./components/LinkEdit";

const Error = styled.div`
    margin-top: 16px;
    color: ${colors.primaryRed};
    text-align: center;
`;

const LinkDetails = ({
    linkId,
    linkListId
}: {
    linkId?: string;
    linkListId: string;
}) => {
    const link = useSelector((state: GlobalState) =>
        linkId
            ? state.entities.linkLists.ItemById[parseInt(linkId)] || null
            : undefined
    );
    const addType = useSelector(
        (state: GlobalState) => state.ui.linkList.createType
    );

    const loading = useSelector(
        (state: GlobalState) => state.ui.linkList.loading
    );

    const referralCode = useSelector(
        (state: GlobalState) => state.profile.referralCode
    );

    const referralCodeLink = `https://www.theplug.co/public/v1/referral/${referralCode}`;
    const initializeDetails = () => {
        if (link) {
            if (link.type === "social")
                return {
                    ...link,
                    id: parseInt(link.id),
                    url: link.url?.includes("tumblr")
                        ? Platforms.tumblr.urlCallback("%username%")
                        : link.url?.split(".com/")[0] + ".com/%username%"
                };
            else return { ...link, id: parseInt(link.id) };
        } else if (!linkId) {
            if (addType === "social" || addType === "campaign") return null;
            else if (addType === "other")
                return {
                    photoUrl: plugIconURL,
                    title: "Title",
                    url: "https://",
                    type: addType
                };
            else
                return {
                    photoUrl: plugIconURL,
                    title: "Earn income with The Plug!",
                    url: referralCodeLink,
                    type: "referral"
                };
        }
    };

    const [details, setDetails] = useState<
        LinkSelectorOption | null | undefined
    >(initializeDetails());
    useEffect(() => setDetails(initializeDetails()), [link]);

    const dispatch = useDispatch();

    // When the :listId and :id  params are not numbers.
    if (linkId) {
        if (!parseInt(linkId) || !parseInt(linkListId)) {
            return <Redirect to="/not-found" />;
        }
    }

    //campaign & platform selection
    if (!linkId && (addType === "campaign" || addType === "social"))
        return (
            <>
                <SimpleNavbar
                    title={"Add Link"}
                    left={
                        <NavButton
                            back
                            onClick={() => {
                                dispatch(
                                    push(`/profile/linklist/${linkListId}`)
                                );
                                dispatch(setLinkListType(undefined));
                            }}
                        />
                    }
                />
                <LinkCampaignPlatformSelector
                    onSelect={(value: LinkSelectorOption) => {
                        setDetails(value);
                        dispatch(setLinkListType(undefined));
                    }}
                    type={addType}
                />
            </>
        );
    if (!loading && linkId && link === null) {
        return (
            <>
                {" "}
                <SimpleNavbar
                    title={link ? "Edit Link" : "Add Link"}
                    left={
                        <NavButton
                            back
                            onClick={() => {
                                dispatch(
                                    push(`/profile/linklist/${linkListId}`)
                                );

                                dispatch(setLinkListType(undefined));
                            }}
                        />
                    }
                />
                <Error>Failed to load link</Error>
            </>
        );
    }

    //link editing
    return (
        <LinkEdit
            details={details}
            setDetails={setDetails}
            link={link}
            linkListId={linkListId}
        />
    );
};

export default LinkDetails;
