import { FETCH_PLACEMENTS } from "src/campaigns/placements/actions";
import { GlobalState } from "src/reducers";
import { Action } from "src/types";
import { SET_PLACEMENT_CONFIG } from "./actions";

export interface PlacementConfig {
    [platform: string]: boolean;
}

export interface PlacementsUIState {
    placementsLoading: boolean;
    placementConfig: PlacementConfig;
}

const initialPlacementConfig = localStorage.getItem("placementConfig");

const initialPlacementsUIState: PlacementsUIState = {
    placementsLoading: true,
    placementConfig: initialPlacementConfig
        ? JSON.parse(initialPlacementConfig)
        : {}
};

const placementsUIReducer = (
    state = initialPlacementsUIState,
    action: Action
) => {
    switch (action.type) {
        case FETCH_PLACEMENTS.SUCCESS:
            return { ...state, placementsLoading: false };
        case SET_PLACEMENT_CONFIG:
            localStorage.setItem(
                "placementConfig",
                JSON.stringify(action.payload.placementConfig)
            );
            return {
                ...state,
                placementConfig: action.payload.placementConfig
            };

        default:
            return state;
    }
};

export default placementsUIReducer;
