import React, { useRef, useState } from "react";
import { DirectBuyStep } from "../../../modules/direct-buy-step/types";
import styled from "styled-components";
import { colors, fonts } from "../../../../constants";
import { ApprovalStatus } from "../../../../types/ApprovalStatus";
import CircleIcon from "../../../../components/CircleIcon";
import { post } from "../../../../Api";
import { updateDirectBuyStep } from "../../../actions";
import { addNotification } from "../../../../ui/notifications/actions";
import { errorToString } from "../../../../util";
import SpinLoader from "../../../../components/SpinLoader";
import { useDispatch } from "react-redux";

const Main = styled.div`
    height: 60px;
    padding: 0 12px;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    display: flex;
    align-items: center;
    background: white;
    & + & {
        margin-top: 10px;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: left;
    overflow: hidden;
    padding-left: 8px;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;
    padding-bottom: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const LinkInput = styled.input`
    border: none;
    outline: none;
    height: 14px;
    width: 100%;
    font-size: 12px;
    line-height: 14px;
    text-overflow: ellipsis;
    color: ${colors.primaryGray};

    &::placeholder {
        color: ${colors.primaryGray};
    }
`;

const Button = styled.button`
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    height: 32px;
    padding: 0 16px;
    background: white;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 4px;
    color: ${colors.primaryBlue};
`;

interface Props {
    step: DirectBuyStep;
}

const DirectBuyDetailsLinkSubmissionCell = ({ step }: Props) => {
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);

    const [linkSubmission, setLinkSubmission] = useState(
        step.linkSubmission || ""
    );
    const [uploading, setUploading] = useState(false);

    const submitLink = () => {
        if (linkSubmission) {
            setUploading(true);
            post(`/v1/directBuyStep/${step.id}/linkSubmission`, {
                linkSubmission
            })
                .then(({ data }) => {
                    dispatch(updateDirectBuyStep(data.data));
                    setUploading(false);
                })
                .catch(error => {
                    dispatch(
                        addNotification(
                            `Unable to submit link: ${errorToString(error)}`,
                            colors.primaryRed
                        )
                    );
                    setUploading(false);
                });
        }
    };

    const getIcon = () => {
        if (uploading) {
            return <SpinLoader size={24} color={colors.primaryBlue} />;
        }
        switch (step.status) {
            case ApprovalStatus.pending:
                return (
                    <CircleIcon type="ellipsis" color={colors.primaryYellow} />
                );
            case ApprovalStatus.approved:
                return <CircleIcon type="check" color={colors.primaryGreen} />;
            case ApprovalStatus.rejected:
                return <CircleIcon type="x" color={colors.primaryRed} />;
        }
    };

    return (
        <Main>
            {getIcon()}
            <TextContainer>
                {step.socialAccount && (
                    <Title>@{step.socialAccount.username}</Title>
                )}
                <LinkInput
                    ref={inputRef}
                    value={linkSubmission}
                    onChange={e => {
                        setLinkSubmission(e.currentTarget.value);
                    }}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            inputRef &&
                                inputRef.current &&
                                inputRef.current.blur();
                        }
                    }}
                    onBlur={() => {
                        submitLink();
                    }}
                    placeholder={step.linkSubmission || "No Link Submitted Yet"}
                />
            </TextContainer>
            <Button
                onClick={() => {
                    inputRef && inputRef.current && inputRef.current.focus();
                }}
            >
                {step.linkSubmission ? "Edit" : "Submit Link"}
            </Button>
        </Main>
    );
};

export default DirectBuyDetailsLinkSubmissionCell;
