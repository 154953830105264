import React from "react";
import styled from "styled-components";
import { colors } from "src/constants";
import LoadingButton from "src/components/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { updateGroupActionsAddMember } from "../group-user-settings/actions";
import { selectPlugProfileForChat } from "../selectors";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${colors.mediumTeal};
    padding-bottom: 22px;
`;

const Text = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.9px;
    text-align: left;
    color: ${colors.primaryGray};
    padding: 12px 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ButtonWrapper = styled.div`
    padding: 0 18px;
`;

interface Props {
    conversationId: string;
}

const ChatRoomAdminJoinFooter = ({ conversationId }: Props) => {
    const dispatch = useDispatch();

    const ownProfile = useSelector(selectPlugProfileForChat);

    return (
        <Main>
            <Text>Join conversation?</Text>
            <ButtonWrapper>
                <LoadingButton
                    onClick={() => {
                        if (ownProfile)
                            dispatch(
                                updateGroupActionsAddMember(conversationId, [
                                    ownProfile
                                ])
                            );
                    }}
                    isLoading={false}
                    text="Join"
                    width={"100%"}
                    height={"48px"}
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                />
            </ButtonWrapper>
        </Main>
    );
};
export default ChatRoomAdminJoinFooter;
