import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import SearchBar from "src/components/SearchBar";
import AccountCell from "src/profile/accounts/components/AccountCell";
import { getAccountsWithSearch } from "src/profile/accounts/selectors";
import { GlobalState } from "src/reducers";

const IntroBuysAccountList = () => {
    const [searchString, setSearchString] = useState("");
    const accounts = useSelector((state: GlobalState) =>
        getAccountsWithSearch(state, searchString, true)
    );

    return (
        <>
            <SearchBar
                searchString={searchString}
                updateSearchString={setSearchString}
            />
            {accounts.map(account => (
                <AccountCell key={account.id} account={account} showRates />
            ))}
        </>
    );
};

export default IntroBuysAccountList;
