import React from "react";
import Toggler from "src/components/Toggler";
import { colors, fonts, fontWeight } from "src/constants";
import { clearSpacing } from "src/utils/styles/snippets";
import styled from "styled-components";

const Main = styled.article`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 8px;
`;

const Title = styled.h3`
    ${clearSpacing()};
    margin-bottom: 6px;
    color: ${colors.black};
    font-size: ${fonts.semiSmallTitle}px;
    font-weight: ${fontWeight.medium};
`;

const Subtitle = styled.h4`
    ${clearSpacing()};
    margin-bottom: 6px;
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.default};
`;

interface Props {
    title: string;
    subtitle: string;
    isActive: boolean;
    onSelect: VoidFunction;
    onDeselect: VoidFunction;
}

const ChallengeTogglerSection = ({
    title,
    subtitle,
    isActive,
    onSelect,
    onDeselect
}: Props) => {
    return (
        <Main>
            <Header>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </Header>
            <Toggler
                isActive={isActive}
                onSelect={onSelect}
                onDeselect={onDeselect}
            />
        </Main>
    );
};

export default ChallengeTogglerSection;
