import React, { useState } from "react";
import EmojiModal from "src/modals/EmojiModal";
import ImageModal from "src/modals/ImageModal";
import ModalNameHeader from "src/chat/components/ModalNameHeader";
import ModalNameInput from "src/chat/components/ModalNameInput";
import { BlueModalButton } from "src/profile/components/Buttons";
import { useDispatch } from "react-redux";
import { updateGroupChannelInfo } from "../actions";

interface Props {
    conversationId: string;
    description: string;
    imageUrl: string;
    clearModal: () => void;
}

const EditGroupDescriptionModal = ({
    conversationId,
    description,
    imageUrl,
    clearModal
}: Props) => {
    const dispatch = useDispatch();
    const [groupDescription, setGroupDescription] = useState(description);
    const [placeholder, setPlaceholder] = useState("Add Group Description...");

    function updateDescription() {
        if (groupDescription !== description) {
            dispatch(
                updateGroupChannelInfo({
                    conversationId,
                    groupDescription
                })
            );
        }
        clearModal();
    }

    return (
        <ImageModal imageUrl={imageUrl} localClearModal={clearModal}>
            <ModalNameHeader>Update Description:</ModalNameHeader>
            <ModalNameInput
                placeholder={placeholder}
                value={groupDescription}
                onChange={e => {
                    setGroupDescription(e.currentTarget.value);
                }}
                onFocus={() => setPlaceholder("")}
                onBlur={() => setPlaceholder("Add Group Description...")}
            />
            <BlueModalButton onClick={updateDescription}>
                Update Group
            </BlueModalButton>
        </ImageModal>
    );
};

export default EditGroupDescriptionModal;
