import React, { ReactElement, useMemo, useState } from "react";
import CheckBox from "src/components/CheckBox";
import { colors } from "src/constants";
import styled from "styled-components";
import { MultiSelectReference } from "../hooks/useMultiSelect";
import { PageReference } from "../hooks/usePages";
import { ColumnsReference, RowSingleSelect } from "../PlugTable";
import { TableCell, TableRow } from "../types";
import { formatTableDate } from "../utils";
import TableData from "./TableData";

interface RowProps {
    empty?: boolean;
    isSingleSelected?: boolean;
    rowHeight?: number;
}

const Row = styled.tr<RowProps>`
    isolation: isolate;
    border-bottom: ${props =>
        props.empty ? "none" : `1px solid ${colors.quaternaryLightGray}`};
    height: ${props => (props.rowHeight ? `${props.rowHeight}px` : "49px")};
    background-color: ${props =>
        props.isSingleSelected ? colors.teritaryLightGray : "initial"};
    &:hover {
        background-color: ${colors.blueGrey};
    }
    &:last-child {
        border-bottom: none;
    }
`;

const TableLink = styled.a`
    color: ${colors.primaryBlue};
    text-decoration: underline;
    transition: color 0.2s ease;
    &:hover {
        text-decoration: none;
        color: ${colors.primaryBlueDark};
    }
`;
const TableBtn = styled.button`
    color: ${colors.primaryBlue};
    width: 100%;
    height: 100%;
    text-align: left;
`;
const EmptyData = styled(TableData)`
    color: ${colors.primaryGray};
    font-size: 24px;
`;

const CheckBoxCell = styled.td`
    width: 31px;
`;

interface Props {
    columnsReference: ColumnsReference;
    columnsOrder: string[];
    sortedData: TableRow[];
    pageReference: PageReference;
    rowSingleSelect?: RowSingleSelect;
    rowClick?: (
        row: TableRow,
        rowIdx: number,
        sortedData: TableRow[],
        e: React.MouseEvent<HTMLTableRowElement>
    ) => void;
    rowHeight?: number;
    tableLoading?: boolean;
    multiSelectReference?: MultiSelectReference;
}

const TableBody = ({
    columnsReference,
    columnsOrder,
    sortedData,
    pageReference,
    rowSingleSelect,
    rowClick,
    rowHeight,
    tableLoading,
    multiSelectReference
}: Props) => {
    const { pageIndices, resultsPerPage } = pageReference;
    const [bottomIdx, topIdx] = pageIndices;

    const [selectedRow, setSelectedRow] = useState<number | null>(null);

    const handleRowSelect = (data: TableRow) => {
        if (selectedRow === data.originalIdx) {
            setSelectedRow(null);
            rowSingleSelect && rowSingleSelect.setter(null);
        } else {
            setSelectedRow(data.originalIdx);
            rowSingleSelect && rowSingleSelect.setter(data);
        }
    };

    const generateTable = () => {
        const createCell = (
            cell: TableCell,
            columnKey: string,
            reactKey: string
        ) => {
            if (cell) {
                const column = columnsReference[columnKey];
                const cellStyle: React.CSSProperties = {
                    ...(column.columnWidth && {
                        width: column.columnWidth,
                        maxWidth: column.columnWidth,
                        minWidth: column.columnWidth
                    }),
                    ...(rowHeight && { height: `${rowHeight}px` }),
                    ...(cell.style && { ...cell.style })
                };

                if (cell.type === "number") {
                    let newValue: string | number = cell.data;
                    if (cell.separator) {
                        newValue = newValue.toLocaleString(undefined, {
                            maximumFractionDigits: 2
                        });
                    }
                    if (cell.money) {
                        newValue = `$${newValue}`;
                    }
                    return (
                        <TableData key={reactKey} cellStyle={cellStyle}>
                            {newValue}
                        </TableData>
                    );
                }
                if (cell.type === "link") {
                    return (
                        <TableData key={reactKey} cellStyle={cellStyle}>
                            <TableLink
                                onClick={e => e.stopPropagation()}
                                target="_blank"
                                href={cell.href}
                            >
                                {cell.data}
                            </TableLink>
                        </TableData>
                    );
                }
                if (cell.type === "button") {
                    return (
                        <TableData key={reactKey} cellStyle={cellStyle}>
                            <TableBtn
                                onClick={e => {
                                    e.stopPropagation();
                                    cell.handleClick();
                                }}
                            >
                                {cell.data}
                            </TableBtn>
                        </TableData>
                    );
                }
                if (cell.type === "string") {
                    return (
                        <TableData key={reactKey} cellStyle={cellStyle}>
                            {cell.data}
                        </TableData>
                    );
                }
                if (cell.type === "boolean") {
                    const icon = cell.data
                        ? "/check-mark-circle-primary-green.svg"
                        : "/red-circle-close.svg";

                    const element = cell.isIcon ? (
                        <img
                            src={icon}
                            alt=""
                            style={{ width: "18px", height: "auto" }}
                        />
                    ) : cell.data ? (
                        "True"
                    ) : (
                        "False"
                    );
                    return (
                        <TableData key={reactKey} cellStyle={cellStyle}>
                            {element}
                        </TableData>
                    );
                }
                if (cell.type === "date") {
                    return (
                        <TableData key={reactKey} cellStyle={cellStyle}>
                            {cell.format
                                ? formatTableDate(cell.data, cell.format)
                                : cell.data}
                        </TableData>
                    );
                }
                if (cell.type === "custom") {
                    return (
                        <TableData key={reactKey} cellStyle={cellStyle}>
                            {cell.component}
                        </TableData>
                    );
                }
            }
        };

        const currentPageData = sortedData.slice(bottomIdx, topIdx);

        let rowElements: ReactElement[] = [];

        for (let rowIdx = 0; rowIdx < currentPageData.length; rowIdx++) {
            const row: TableRow = currentPageData[rowIdx];

            let rowNodes: React.ReactNode[] = [];
            if (multiSelectReference) {
                rowNodes.push(
                    <CheckBoxCell>
                        <CheckBox
                            selected={multiSelectReference.selectedRowsSet.has(
                                row.originalIdx
                            )}
                        />
                    </CheckBoxCell>
                );
            }
            for (
                let columnIdx = 0;
                columnIdx < columnsOrder.length;
                columnIdx++
            ) {
                const coreKey = columnsOrder[columnIdx];
                const currentCell = row.rowData[coreKey];

                if (currentCell && !currentCell.isNull) {
                    const cell = createCell(
                        currentCell,
                        coreKey,
                        `${coreKey}-${rowIdx}`
                    );
                    rowNodes.push(cell);
                } else {
                    rowNodes.push(
                        <EmptyData key={`${coreKey}-${rowIdx}`}>-</EmptyData>
                    );
                }
            }

            rowElements.push(
                <Row
                    style={row.rowStyle}
                    rowHeight={rowHeight}
                    key={"row-" + row.originalIdx}
                    onClick={e => {
                        if (rowClick) {
                            const selection = window.getSelection();
                            if (
                                selection &&
                                selection.toString().length === 0
                            ) {
                                rowClick(row, rowIdx, sortedData, e);
                            }
                        }
                        handleRowSelect(row);
                        multiSelectReference?.clickRow?.(row.originalIdx);
                    }}
                    isSingleSelected={selectedRow === row.originalIdx}
                >
                    {rowNodes}
                </Row>
            );
        }
        return rowElements;
    };

    const displayedData = generateTable();

    const extraRows = Array.from({
        length: resultsPerPage - displayedData.length
    });
    return (
        <tbody>
            {displayedData}
            {extraRows.length > 0 &&
                extraRows.map((row, idx) => (
                    <Row rowHeight={rowHeight} empty key={"emptyRow-" + idx} />
                ))}
        </tbody>
    );
};

export default TableBody;
