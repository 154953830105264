import React, { useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { auth } from "../firebase/FirebaseConfig";
import { logout } from "../profile/actions";
import { useIntercom } from "react-use-intercom";

interface Props {
    logout: () => void;
}

const Logout = ({ logout }: Props) => {
    const { shutdown } = useIntercom();
    useEffect(() => {
        shutdown();
        logout();
    }, []);
    return null;
};

const mapDispatchToProps = (dispatch: any) => ({
    logout: () => dispatch(logout())
});

export default connect(null, mapDispatchToProps)(Logout);
