import React from "react";
import { useFocused, useSelected, useSlate } from "slate-react";
import { colors } from "src/constants";
import styled from "styled-components";
import CustomEditor from "./CustomEditor";

//TODO: Refactor to styled components
const Anchor = styled.a<{ isSelected: boolean }>`
    color: ${colors.primaryGreen};
    text-decoration: underline;
    margin-bottom: 0px;
    box-shadow: ${props => (props.isSelected ? "0 0 0 3px #ddd" : "none")};
`;
const StyledLeaf = styled.span<{ bold: boolean; italic: boolean }>`
    font-weight: ${props => (props.bold ? "bold" : "normal")};
    font-style: ${props => (props.italic ? "italic" : "normal")};
`;
const InlineRule = styled.span`
    display: inline-block;
`;

const RuleWrapper = styled.span<{ selected: boolean; focused: boolean }>`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 5px 6px;
    margin: 6px 0;

    border-radius: 3px;
    background-color: ${colors.error100};
    color: ${colors.error900};
    box-shadow: ${props =>
        props.selected && props.focused ? "0 0 0 3px #ddd" : "none"};
`;

const RuleUndo = styled.img`
    margin-left: auto;
    cursor: pointer;
`;

const RuleIcon = styled.span``;

const Rule = styled.span`
    justify-self: flex-start;
    display: block;
    line-height: 150%;
    text-decoration: line-through;

    /* user-select: none; */
    cursor: default;
`;

export const DefaultElement = (props: any) => {
    return (
        <p style={{ marginBottom: "0px" }} {...props.attributes}>
            {props.children}
        </p>
    );
};

export const LinkElement = (props: any) => {
    const isSelected = useSelected();
    // const style = {
    //     color: `${colors.primaryGreen}`,
    //     textDecoration: "underline",
    //     marginBottom: "0px",
    //     boxShadow: `${isSelected ? "0 0 0 3px #ddd" : "none"}`
    // };
    return (
        <Anchor
            isSelected={isSelected}
            href={props.element.link}
            {...props.attributes}
        >
            {props.children}
        </Anchor>
    );
};
export const RuleItem = (props: any) => {
    const editor = useSlate();
    const selected = useSelected();
    const focused = useFocused();

    return (
        <InlineRule
            {...props.attributes}
            contentEditable={false}
            data-cy={`rule-${props.element.rule.replace(" ", "-")}`}
        >
            <RuleWrapper selected={selected} focused={focused}>
                <RuleIcon>❌</RuleIcon>
                <Rule>
                    {props.element.rule}
                    {props.children}
                </Rule>
                <RuleUndo
                    src="/undo_icon.svg"
                    onClick={() => {
                        setTimeout(() => {
                            CustomEditor.removeSelectionOnClick(editor);
                        }, 0);
                    }}
                />
            </RuleWrapper>
        </InlineRule>
    );
};

export const OrderedList = (props: any) => {
    return (
        <ol style={{ marginBlockEnd: "12px", marginBlockStart: "0px" }}>
            {props.children}
        </ol>
    );
};
export const UnorderedList = (props: any) => {
    return (
        <ul style={{ marginBlockEnd: "12px", marginBlockStart: "0px" }}>
            {props.children}
        </ul>
    );
};
export const ListItem = (props: any) => {
    return <li {...props.attributes}>{props.children}</li>;
};

export const Leaf = (props: any) => {
    return (
        <StyledLeaf
            {...props.attributes}
            bold={props.leaf.bold}
            italic={props.leaf.italic}
        >
            {props.children}
        </StyledLeaf>
    );
};
