import React from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import useAuthorization from "../../../auth/hooks/useAuthorization";
import { MediaAction } from "../../../auth/roles";
import { colors, fonts } from "../../../constants";
import CarouselInfo from "../../../modals/CarouselInfo";
import { GlobalState } from "../../../reducers";
import { startPromotingModal } from "../../../ui/auth/actions";
import { selectCampaign } from "../../selectors";
import { Asset, Campaign } from "../../types";
import CampaignReward from "./CampaignReward";
import PromoteButton from "./PromoteButton";

const Main = styled.div`
    width: 100vw;
    max-width: 500px;
    padding-bottom: 20px;
    color: white;
    text-align: left;
    font-weight: 300;
    user-select: none;

    & img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
`;

const Bold = styled.span`
    font-weight: 600;
`;

const PlugLogo = styled.img`
    height: 16px;
    margin-right: 4px;
`;

const Options = styled.div`
    padding: 0 40px;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 24px;
    display: flex;
    justify-content: space-around;
`;

const Cell = styled.a`
    background-color: rgba(0, 0, 0, 0.5);
    padding: 12px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${fonts.smallTitle}px;
    color: white;
    cursor: pointer;

    &:hover {
        color: white;
        text-decoration: none;
    }

    &:active {
        background-color: rgba(0, 0, 0, 0.8);
    }
`;

const Icon = styled.img`
    margin-right: 8px;
`;

interface Props {
    asset: Asset;
    currentCampaign?: Campaign;
    closeModal: () => void;
}

const AssetCarouselCell = ({ asset, currentCampaign, closeModal }: Props) => {
    const [cellButtons] = useAuthorization([MediaAction.cellButtons]);
    const dispatch = useDispatch();

    if (!currentCampaign) return null;
    const {
        imageUrl,
        campaignName,
        countryPlatformReward,
        rewardType
    } = currentCampaign;

    return (
        <Main>
            <CarouselInfo
                appIconUrl={imageUrl}
                campaignName={campaignName}
                amountComponent={
                    <CampaignReward
                        countryPlatformReward={countryPlatformReward}
                        rewardType={rewardType}
                        noPlatform
                        color={colors.white}
                    />
                }
                mediaUrl={asset.url}
                mediaType={asset.type}
                closeModal={closeModal}
                isPortrait
            />
            <Options>
                {cellButtons ? (
                    <Cell
                        target="_blank"
                        download={`asset-id-${asset.id}`}
                        href={asset.url}
                        onMouseDown={e => {
                            e.stopPropagation();
                        }}
                    >
                        <Icon src={"/white_download.svg"} />
                        Download
                    </Cell>
                ) : (
                    <PromoteButton
                        onClick={() => dispatch(startPromotingModal())}
                    />
                )}
            </Options>
        </Main>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    currentCampaign: selectCampaign(state)
});

export default connect(mapStateToProps)(AssetCarouselCell);
