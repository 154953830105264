import styled from "styled-components";
import { colors, fonts } from "../../../constants";
import { motion } from "framer-motion";

interface MainProps {
    indent: number;
    background: string;
}

export const Main = styled(motion.div)<MainProps>`
    padding: 10px 18px 10px ${props => 18 + 24 * props.indent}px;
    background: ${props => props.background};
`;

interface TopProps {
    clickable: boolean;
}

export const Top = styled.div<TopProps>`
    display: flex;
    user-select: none;
    ${props => props.clickable && "cursor: pointer;"}
`;

export const Description = styled.div`
    display: flex;
    align-items: center;
    padding-left: 12px;
    width: 100%;
    text-align: left;
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;
    b {
        font-weight: bold;
    }
`;

export const ResourceContainer = styled.div`
    padding-top: 12px;
`;

export const ResourceDescription = styled.div`
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: ${colors.primaryGray};
`;
