import { Action } from "src/types";

export const SET_CURRENT_MODAL_MEDIAS = "SET_CURRENT_MODAL_MEDIAS";

export const setCurrentModalMedias = (mediaIds: number[]): Action => ({
    type: SET_CURRENT_MODAL_MEDIAS,
    payload: { mediaIds }
});

export const MEDIA_ITEM_MODAL_OPEN = "MEDIA_ITEM_MODAL_OPEN";

export function setMediaItemModalOpen(open: boolean): Action {
    return {
        type: MEDIA_ITEM_MODAL_OPEN,
        payload: open
    };
}

export const POST_MEDIA_MODAL_OPEN = "POST_MEDIA_MODAL_OPEN";

export const setPostMediaModalOpen = (open: boolean): Action => ({
    type: POST_MEDIA_MODAL_OPEN,
    payload: open
});

export const USER_MEDIA_LOAD_SUCCESS = "USER_MEDIA_LOAD_SUCCESS";

export function userMediaLoaded(): Action {
    return {
        type: USER_MEDIA_LOAD_SUCCESS,
        payload: null
    };
}

// Promote Flow Media Selection -----------------------------------------------

export const PROMOTE_FLOW_MEDIA = {
    SET: "PROMOTE_FLOW_MEDIA_SET",
    CLEAR: "PROMOTE_FLOW_MEDIA_CLEAR"
};

export const setPromoteFlowMedia = (media_id: string): Action => ({
    type: PROMOTE_FLOW_MEDIA.SET,
    payload: media_id
});

export const clearPromoteFlowMedia = (): Action => ({
    type: PROMOTE_FLOW_MEDIA.CLEAR,
    payload: null
});
