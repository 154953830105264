import React from "react";
import { goBack } from "connected-react-router";
import { connect } from "react-redux";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import ReloadProfile from "../components/ReloadProfile";

interface Props {
    reloadCallback: VoidFunction;
    goBack: VoidFunction;
}

const TryAgain = ({ reloadCallback, goBack }: Props) => {
    return (
        <>
            <SimpleNavbar left={<NavButton back onClick={goBack} />} />
            <ReloadProfile reloadCallback={reloadCallback} />
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    goBack: () => dispatch(goBack())
});

export default connect(null, mapDispatchToProps)(TryAgain);
