import { motion } from "framer-motion";
import React from "react";
import { colors, fonts, fontWeight } from "src/constants";
import styled from "styled-components";

const Main = styled.article`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.heavy};
    text-transform: uppercase;
`;

const Value = styled.div`
    padding: 4px 6px;
    color: ${colors.white};
    background-color: ${colors.lightBlue};
    border-radius: 4px;
    font-size: ${fonts.semiSuperSmallSubtitle};
    font-weight: ${fontWeight.medium};
`;

const ProgressBarContainer = styled.div`
    position: relative;
    height: 8px;
    background-color: ${colors.gray3};
    border-radius: 100px;
    overflow: hidden;
`;

const ProgressBar = styled(motion.div)`
    position: absolute;
    height: 100%;
    background-color: ${colors.primaryBlue};
    border-radius: 100px;
`;

interface Props {
    name: string;
    weight: number;
}

const FollowerDemographicsProgressBar = ({ name, weight }: Props) => {
    return (
        <Main>
            <Header>
                {name} <Value>{(weight * 100).toFixed(2)}%</Value>
            </Header>
            <ProgressBarContainer>
                <ProgressBar
                    initial={{ width: "0%" }}
                    animate={{ width: `${Number((weight * 100).toFixed(2))}%` }}
                    exit={{ width: "0%" }}
                    transition={{ duration: 0.5 }}
                />
            </ProgressBarContainer>
        </Main>
    );
};

export default FollowerDemographicsProgressBar;
