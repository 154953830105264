import { all, call, put, takeEvery } from "redux-saga/effects";
import { get, patch } from "src/Api";
import { Action } from "src/types";
import {
    FETCH_OPEN_BUY_PAYMENTS,
    fetchOpenBuyPaymentsSuccess,
    fetchOpenBuyPaymentsFailure,
    REPLACE_ALL_OPEN_BUY_PAYMENTS
} from "./actions";
import { errorToString } from "../../../util";
import {
    convertOpenBuyPaymentsToPendingGroups,
    convertPendingGroupsToOpenBuyPayments
} from "./util";
import { PendingOpenBuyPaymentsGroup } from "../types";
import { setPillNotificationText } from "../../../ui/notifications/actions";

function* fetchOpenBuyPayments({ payload: { openBuyId } }: Action) {
    try {
        const endpoint = `/v1/openBuyPayment/${openBuyId}`;
        const { data } = yield call(get, endpoint, {});
        const openBuyPayments = data.data;
        const pendingOpenBuyPaymentsGroups = convertOpenBuyPaymentsToPendingGroups(
            openBuyPayments
        );
        yield put(
            fetchOpenBuyPaymentsSuccess(openBuyId, pendingOpenBuyPaymentsGroups)
        );
    } catch (error) {
        yield put(fetchOpenBuyPaymentsFailure(errorToString(error)));
    }
}

function* replaceAllOpenBuyPayments({
    payload: { openBuyId, pendingOpenBuyPaymentsGroups }
}: Action) {
    try {
        yield all(
            pendingOpenBuyPaymentsGroups
                .filter(
                    (group: PendingOpenBuyPaymentsGroup) => group.payoutTiming
                )
                .map((group: PendingOpenBuyPaymentsGroup) => {
                    const { instructionStepId, payoutTiming } = group;
                    const endpoint = `/v1/instruction/step/${instructionStepId}`;
                    return call(patch, endpoint, { payoutTiming });
                })
        );
        const endpoint = `/v1/openBuyPayment/${openBuyId}`;
        const { data } = yield call(patch, endpoint, {
            openBuyPayments: convertPendingGroupsToOpenBuyPayments(
                pendingOpenBuyPaymentsGroups
            )
        });
        const openBuyPayments = data.data;
        const newPendingOpenBuyPaymentsGroups = convertOpenBuyPaymentsToPendingGroups(
            openBuyPayments
        );
        yield put(
            fetchOpenBuyPaymentsSuccess(
                openBuyId,
                newPendingOpenBuyPaymentsGroups
            )
        );
        yield put(
            setPillNotificationText(`Successfully Set Challenge Payments`)
        );
    } catch (error) {
        yield put(fetchOpenBuyPaymentsFailure(errorToString(error)));
    }
}

export default function* openBuyPaymentsSaga() {
    yield all([
        takeEvery(FETCH_OPEN_BUY_PAYMENTS.REQUEST, fetchOpenBuyPayments),
        takeEvery(REPLACE_ALL_OPEN_BUY_PAYMENTS, replaceAllOpenBuyPayments)
    ]);
}
