import { OpenBuyInstructionStep } from "src/buys/modules/open-buys/types";
import { InstructionStep } from "src/marketplace/instructions-sets/types";
import { InstructionStepState } from "src/ui/instructions-sets/actions";
import { shortenNumber } from "src/util";
import { PaymentSetBucket } from "../types";
import {
    audienceRangeTypes,
    criteriaPercentageRangeTypes,
    criteriaRangeTypes,
    criteriaTypes
} from "./constants";
import {
    AudienceCriteriaType,
    CriteriaPercentageRangeType,
    CriteriaRangeType,
    CriteriaType,
    CustomRangeValue
} from "./types";

export const getPaymentDisplayName = (
    type: string | null,
    hasValue?: boolean
) => {
    const dictionary: { [key: string]: string } = {
        dollars_per_k: hasValue ? "per K" : "$ per K",
        flat_rate: "Flat Fee",
        view_thresholds: "View Thresholds"
    };
    return type && dictionary.hasOwnProperty(type)
        ? dictionary[type]
        : "No Payment Type Selected";
};

export const convertCustomRangeToCriteriaValue = (
    customValue: CustomRangeValue,
    criteriaType: string
): string => {
    const { start, end } = customValue;

    switch (criteriaType) {
        case "Followers":
            return `${shortenNumber(start)}-${shortenNumber(end)}`;
        case "Average Views":
            return `${shortenNumber(start)}-${shortenNumber(end)}`;
        case "Engagement Rate":
            return `${start}-${end}%`;
        case "Creator Age":
            return `${start}-${end}`;
        case "Audience Age":
            return `${start}-${end}`;
        case "Audience Region":
            return `${start}-${end}%`;
        case "Audience Gender":
            return `${start}-${end}%`;
        case "Audience Range":
            return `${start}-${end}%`;
        default:
            return "";
    }
};

export const getCustomRangeTagDisplay = (
    customValue: CustomRangeValue,
    localCriteria: string
) => {
    if (!customValue || !customValue.start || !customValue.end) return "Custom";
    else
        return (
            "Custom: " +
            convertCustomRangeToCriteriaValue(customValue, localCriteria)
        );
};

export const expandNumber = (numberString: string) => {
    if (numberString.length === 0) return numberString;
    const lastIndex = numberString.length - 1;
    const numSuffix = numberString[lastIndex].toLowerCase();
    const base = parseInt(numberString.slice(0, lastIndex));

    switch (numSuffix) {
        case "k":
            return (base * 1000).toString();
        case "m":
            return (base * 1000000).toString();
        case "%":
            return base.toString();
        default:
            return numberString;
    }
};

export const checkRangeOverlap = (usedValues: Set<string>) => {
    const ranges: CustomRangeValue[] = [];
    usedValues.forEach(range => {
        const [start, end] = range.split("-");
        ranges.push({ start: expandNumber(start), end: expandNumber(end) });
    });
    ranges.sort((a, b) => parseInt(a.start) - parseInt(b.start));

    for (let i = 0; i < ranges.length - 1; i++) {
        const currEndValue = parseInt(ranges[i].end);
        const nextFirstValue = parseInt(ranges[i + 1].start);
        if (currEndValue - nextFirstValue > 0) return true; // there is an overlap
    }
    // there is no overlap
    return false;
};

export const verifyPaymentSetTitle = (title: string) => {
    return title.length > 0;
};

export const verifyPaymentSetPayment = (buckets: PaymentSetBucket[]) => {
    return buckets.every(bucket => {
        const isViewThresholdsType = bucket.PaymentSetPayments.length > 1;
        return (
            bucket.PaymentSetPayments.length > 0 &&
            bucket.PaymentSetPayments.every(
                payment =>
                    payment.paymentId &&
                    payment.amount > 0 &&
                    (isViewThresholdsType
                        ? !!payment.viewsMinimum && payment.viewsMinimum > 0
                        : true)
            )
        );
    });
};

// export const verifyPaymentSetCriteria = (buckets: PaymentSetBucket[]) => {
//     return buckets.every(
//         bucket =>
//             bucket.PaymentSetCriteria.length > 0 &&
//             bucket.PaymentSetCriteria.every(
//                 criteria =>
//                     criteria.criteriaId && criteria.criteriaValue.length > 0
//             )
//     );
// };

// Type Checks ---------------------------------------------------

export const isRangeType = (criteria: any): criteria is CriteriaRangeType => {
    return criteriaRangeTypes.includes(criteria);
};

export const isAudienceRangeType = (
    criteria: any
): criteria is AudienceCriteriaType => {
    return audienceRangeTypes.includes(criteria);
};

export const isPercentageRangeType = (
    criteria: any
): criteria is CriteriaPercentageRangeType => {
    return criteriaPercentageRangeTypes.includes(criteria);
};

export const isCriteriaType = (criteria: any): criteria is CriteriaType => {
    return criteriaTypes.includes(criteria);
};

// Checks & returns if instruction step requires submission:
export const stepRequiresSubmission = (
    step: InstructionStepState | InstructionStep | OpenBuyInstructionStep
) => {
    if (step.requiresCustomMedia) return "Custom Media";
    else if (step.requiresLinkSubmission) return "Link Submission";
    else if (step.requiresPlugLink) return "Plug Link";
    else if (step.requiresScreenshot) return "Screenshot";
    else if (step.requiresTextSubmission) return "Text Submission";
    else return null;
};

export const getHighPresetViewThresholdPayments = () => {
    return [
        { paymentId: 2, amount: 50, viewsMinimum: 1000 },
        { paymentId: 2, amount: 100, viewsMinimum: 10000 },
        { paymentId: 2, amount: 300, viewsMinimum: 50000 },
        { paymentId: 2, amount: 500, viewsMinimum: 100000 },
        { paymentId: 2, amount: 1000, viewsMinimum: 250000 },
        { paymentId: 2, amount: 2000, viewsMinimum: 1000000 }
    ];
};

export const getLowPresetViewThresholdPayments = () => {
    return [
        { paymentId: 2, amount: 25, viewsMinimum: 1000 },
        { paymentId: 2, amount: 50, viewsMinimum: 10000 },
        { paymentId: 2, amount: 150, viewsMinimum: 50000 },
        { paymentId: 2, amount: 250, viewsMinimum: 100000 },
        { paymentId: 2, amount: 500, viewsMinimum: 250000 },
        { paymentId: 2, amount: 1000, viewsMinimum: 1000000 }
    ];
};

// Searches to find if Map has a value
// Returns the first element that matches the searchValue or undefined if no values match
export const mapHasValue = (map: Map<number, number>, searchValue: number) =>
    Array.from(map.values()).find(el => el === searchValue);

// Gets the Key by Value from Map
// Returns a Key of the Map or undefined
export const getMapKeyByValue = (
    map: Map<number, number>,
    searchValue: number
) => {
    const pair = Array.from(map.entries()).find(
        ([_, value]) => value === searchValue
    );
    return pair && pair[0];
};
