// Filters -----------------------------------------------
export const creatorSearchFilters = [
    "Platform",
    "Creator/Country Region",
    "Audience/Country Region",
    "Interests",
    "Themes",
    "Followers",
    "Average Views",
    "Engagement Rate",
    "Audience Gender",
    "Audience Device"
] as const;
export type CreatorSearchFilters = typeof creatorSearchFilters[number];
