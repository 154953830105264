import { Action } from "src/types";
import {
    InstructionStepPayoutTiming,
    PaymentConjunction,
    PendingOpenBuyPaymentItem,
    PendingOpenBuyPaymentsGroup
} from "../types";

// Fetch Payments by Open Buy ---------------------------------------------------------------
export const FETCH_OPEN_BUY_PAYMENTS = {
    REQUEST: "FETCH_OPEN_BUY_PAYMENTS.REQUEST",
    SUCCESS: "FETCH_OPEN_BUY_PAYMENTS.SUCCESS",
    FAILURE: "FETCH_OPEN_BUY_PAYMENTS.FAILURE"
};

export const fetchOpenBuyPayments = (openBuyId: number): Action => ({
    type: FETCH_OPEN_BUY_PAYMENTS.REQUEST,
    payload: { openBuyId }
});

export const fetchOpenBuyPaymentsSuccess = (
    openBuyId: number,
    pendingOpenBuyPaymentsGroups: PendingOpenBuyPaymentsGroup[]
): Action => ({
    type: FETCH_OPEN_BUY_PAYMENTS.SUCCESS,
    payload: {
        [openBuyId]: {
            alreadyHasPayments: pendingOpenBuyPaymentsGroups.length > 0,
            pendingOpenBuyPaymentsGroups
        }
    }
});

export const fetchOpenBuyPaymentsFailure = (error: string): Action => ({
    type: FETCH_OPEN_BUY_PAYMENTS.FAILURE,
    payload: { error }
});

// Manipulate Open Buy Payments Groups ---------------------------------------------------------------
export const ADD_PENDING_OPEN_BUY_PAYMENTS_GROUP =
    "ADD_PENDING_OPEN_BUY_PAYMENTS_GROUP";

export const addPendingOpenBuyPaymentsGroup = (payload: {
    openBuyId: number;
    instructionStepId: number;
}): Action => ({
    type: ADD_PENDING_OPEN_BUY_PAYMENTS_GROUP,
    payload
});

export const REMOVE_PENDING_OPEN_BUY_PAYMENTS_GROUP =
    "REMOVE_PENDING_OPEN_BUY_PAYMENTS_GROUP";

export const removePendingOpenBuyPaymentsGroup = (payload: {
    openBuyId: number;
    instructionStepId: number;
}): Action => ({
    type: REMOVE_PENDING_OPEN_BUY_PAYMENTS_GROUP,
    payload
});

export const UPDATE_PENDING_OPEN_BUY_PAYMENTS_GROUP =
    "UPDATE_PENDING_OPEN_BUY_PAYMENTS_GROUP";

export const updatePendingOpenBuyPaymentsGroup = (payload: {
    openBuyId: number;
    instructionStepId: number;
    paymentConjunction: PaymentConjunction;
    payoutTiming?: InstructionStepPayoutTiming;
}): Action => ({
    type: UPDATE_PENDING_OPEN_BUY_PAYMENTS_GROUP,
    payload
});

// Toggle Empty Group ---------------------------------------------------------------
export const SHOW_EMPTY_PENDING_OPEN_BUY_PAYMENTS_GROUP =
    "SHOW_EMPTY_PENDING_OPEN_BUY_PAYMENTS_GROUP";

export const showEmptyPendingOpenBuyPaymentsGroup = (): Action => ({
    type: SHOW_EMPTY_PENDING_OPEN_BUY_PAYMENTS_GROUP,
    payload: null
});

// Manipulate Creator Specific Payments ---------------------------------------------------------------
export const ADD_CREATOR_SPECIFIC_PAYMENT = "ADD_CREATOR_SPECIFIC_PAYMENT";
export const addCreatorSpecificPayment = (payload: {
    openBuyId: number;
    instructionStepId: number;
    creatorGroupId: number;
}): Action => ({
    type: ADD_CREATOR_SPECIFIC_PAYMENT,
    payload
});

export const REMOVE_CREATOR_SPECIFIC_PAYMENT =
    "REMOVE_CREATOR_SPECIFIC_PAYMENT";
export const removeCreatorSpecificPayment = (payload: {
    openBuyId: number;
    instructionStepId: number;
    creatorGroupId: number;
}): Action => ({
    type: REMOVE_CREATOR_SPECIFIC_PAYMENT,
    payload
});

export const UPDATE_CREATOR_SPECIFIC_PAYMENT =
    "UPDATE_CREATOR_SPECIFIC_PAYMENT";
export const updateCreatorSpecificPayment = (payload: {
    openBuyId: number;
    instructionStepId: number;
    oldCreatorGroupId: number;
    newCreatorGroupId: number;
}): Action => ({
    type: UPDATE_CREATOR_SPECIFIC_PAYMENT,
    payload
});

// Manipulate Payment Set Payments ---------------------------------------------------------------
export const PUSH_PENDING_OPEN_BUY_PAYMENT_ITEM =
    "PUSH_PENDING_OPEN_BUY_PAYMENT_ITEM";

export const pushPendingOpenBuyPaymentItem = (payload: {
    openBuyId: number;
    instructionStepId: number;
    creatorGroupId: number | null;
    payment: PendingOpenBuyPaymentItem;
}): Action => ({
    type: PUSH_PENDING_OPEN_BUY_PAYMENT_ITEM,
    payload
});

export const SHIFT_PENDING_OPEN_BUY_PAYMENT_ITEM =
    "SHIFT_PENDING_OPEN_BUY_PAYMENT_ITEM";

export const shiftPendingOpenBuyPaymentItem = (payload: {
    openBuyId: number;
    instructionStepId: number;
    creatorGroupId: number | null;
}): Action => ({
    type: SHIFT_PENDING_OPEN_BUY_PAYMENT_ITEM,
    payload
});

export const UPDATE_PENDING_OPEN_BUY_PAYMENT_ITEM =
    "UPDATE_PENDING_OPEN_BUY_PAYMENT_ITEM";

export const updatePendingOpenBuyPaymentItem = (payload: {
    openBuyId: number;
    instructionStepId: number;
    creatorGroupId: number | null;
    paymentIndex: number;
    payment: PendingOpenBuyPaymentItem;
}): Action => ({
    type: UPDATE_PENDING_OPEN_BUY_PAYMENT_ITEM,
    payload
});

// Replace Payments by Open Buy ---------------------------------------------------------------
export const REPLACE_ALL_OPEN_BUY_PAYMENTS = "REPLACE_ALL_OPEN_BUY_PAYMENTS";

export const replaceAllOpenBuyPayments = (payload: {
    openBuyId: number;
    pendingOpenBuyPaymentsGroups: PendingOpenBuyPaymentsGroup[];
}): Action => ({
    type: REPLACE_ALL_OPEN_BUY_PAYMENTS,
    payload
});
