import React from "react";
import { Link } from "react-router-dom";
import {
    breakpoints,
    colors,
    fonts,
    fontWeight,
    shadows,
    maxWidth
} from "src/constants";
import {
    clearButton,
    clearLink,
    clearSpacing,
    darkTealBorderHoverState,
    grayBackgroundHoverState,
    hideOverflow,
    preventUserInteraction,
    primaryBlueBackgroundHoverState
} from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import { ReactComponent as RightCaret } from "src/components/icons/right-caret.svg";
import { ReactComponent as Ellipsis } from "src/components/icons/ellipsis.svg";
import { ReactComponent as ExternalLink } from "src/components/icons/external-link.svg";
import { useDispatch } from "react-redux";
import { setSocialAccountGroupModalOpen } from "src/ui/social-accounts/actions";

const Main = styled.header`
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 20px;
    padding: 24px;
    background-color: ${colors.white};
    border-radius: 8px;
    text-align: left;
    box-shadow: ${shadows.one};

    @media (max-width: ${breakpoints.tablet}px) {
        flex-direction: column;
        gap: 24px;
        margin-top: 20px;
    }
`;

const InformationSection = styled.div`
    display: flex;
    gap: 24px;

    @media (max-width: 590px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
`;

const ProfilePictureContainer = styled.div`
    position: relative;
`;

const ProfilePicture = styled.img`
    width: 160px;
    height: 160px;
    border-radius: 16px;
`;

const SocialIcon = styled.img`
    position: absolute;
    right: 12px;
    bottom: 12px;
    width: 46px;
    height: 46px;
    border-radius: 12px;
`;

const NamesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-end;
    gap: 8px;
    width: 100%;

    @media (max-width: 440px) {
        align-self: flex-start;
    }
`;

const SocialAccountName = styled.h1`
    ${clearSpacing()};
    ${hideOverflow()};
    max-width: 300px;
    width: 100%;
    color: ${colors.black};
    font-size: ${fonts.bigTitle}px;
    font-weight: ${fontWeight.bold};
`;

const PlugAccountLink = styled(Link)`
    ${clearLink()};
    display: flex;
    align-items: center;
    gap: 4px;

    @media (hover: hover) {
        :hover * {
            color: ${colors.primaryGray};
            fill: ${colors.primaryGray};
        }
    }
`;

const PlugAccountName = styled.h2`
    ${clearSpacing()};
    color: ${colors.secondaryGray};
    font-size: ${fonts.semiBigTitle}px;
    font-weight: ${fontWeight.default};
    transition: 0.1s ease-in;
`;

const ArrowIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
`;

const ArrowIcon = styled(RightCaret)`
    width: auto;
    height: 10px;
    fill: ${colors.secondaryGray};
    transition: 0.1s ease-in;
`;

const ButtonSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: ${breakpoints.tablet}px) {
        flex-direction: row-reverse;
        align-items: center;
    }

    @media (max-width: ${breakpoints.mobile}px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 12px;
    }
`;

const EllipsisButton = styled.button`
    ${clearButton()};
    ${grayBackgroundHoverState()};
    ${darkTealBorderHoverState()};
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: 0.1s ease-in;

    visibility: hidden;
    ${preventUserInteraction()};
`;

const EllipsisIcon = styled(Ellipsis)`
    fill: ${colors.secondaryGray};
`;

const BottomButtonsContainer = styled.div`
    display: flex;
    gap: 8px;

    @media (max-width: ${breakpoints.mobile}px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        width: 100%;
    }
`;

const buttonStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    border-radius: 6px;
    font-weight: ${fontWeight.medium};
    white-space: nowrap;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (max-width: ${breakpoints.mobile}px) {
        width: 100%;
    }
`;

const ExternalLinkIcon = styled(ExternalLink)`
    fill: ${colors.black};
`;

const ViewOnSocialButton = styled.a`
    ${clearLink()};
    ${grayBackgroundHoverState()};
    ${buttonStyles};
    gap: 8px;
    color: ${colors.black};
`;

const AddToGroupButton = styled.button`
    ${clearButton()};
    ${primaryBlueBackgroundHoverState()};
    ${buttonStyles};
    color: ${colors.white};
`;

interface Props {
    profilePictureUrl: string;
    socialIconUrl: string;
    socialAccountName: string;
    plugAccountName: string;
    plugAccountUrl: string;
    socialPlatformUrl: string;
    socialPlatformName: string;
}

const SocialAccountProfileHeader = ({
    profilePictureUrl,
    socialIconUrl,
    socialAccountName,
    plugAccountName,
    plugAccountUrl,
    socialPlatformUrl,
    socialPlatformName
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // JSX -----------------------------------------------------------
    return (
        <Main>
            <InformationSection>
                <ProfilePictureContainer>
                    <ProfilePicture
                        src={profilePictureUrl}
                        alt="Social Account Profile Picture"
                        onError={(e: React.InvalidEvent<HTMLImageElement>) =>
                            (e.target.src = "/no_profile_photo.svg")
                        }
                    />
                    <SocialIcon
                        src={socialIconUrl}
                        alt="Social Account Social Icon"
                    />
                </ProfilePictureContainer>
                <NamesContainer>
                    <SocialAccountName>
                        {socialAccountName[0] !== "@" && "@"}
                        {socialAccountName}
                    </SocialAccountName>
                    <PlugAccountLink to={plugAccountUrl} target="_blank">
                        <PlugAccountName>
                            {plugAccountName || "No Plug Username"}
                        </PlugAccountName>
                        <ArrowIconContainer>
                            <ArrowIcon />
                        </ArrowIconContainer>
                    </PlugAccountLink>
                </NamesContainer>
            </InformationSection>

            <ButtonSection>
                <EllipsisButton
                    type="button"
                    onClick={() => console.log("Ellipsis Button")}
                >
                    <EllipsisIcon />
                </EllipsisButton>
                <BottomButtonsContainer>
                    <ViewOnSocialButton
                        href={socialPlatformUrl}
                        target="_blank"
                    >
                        <ExternalLinkIcon />
                        View On {socialPlatformName}
                    </ViewOnSocialButton>
                    <AddToGroupButton
                        type="button"
                        onClick={() =>
                            dispatch(setSocialAccountGroupModalOpen(true))
                        }
                    >
                        Add To Group
                    </AddToGroupButton>
                </BottomButtonsContainer>
            </ButtonSection>
        </Main>
    );
};

export default SocialAccountProfileHeader;
