import React from "react";
import { colors } from "src/constants";
import styled from "styled-components";

const Wrapper = styled.div<{ color: "blue" | "black" | "yellow" }>`
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 12px;
    color: ${props =>
        props.color === "black" || props.color === "blue"
            ? "white"
            : "initial"};
    background-color: ${props =>
        props.color === "blue"
            ? colors.primaryBlueLight
            : props.color === "black"
            ? colors.grayBlack
            : colors.messageYellow};
`;

export type BannerColor = "blue" | "black" | "yellow";

interface Props {
    message: string;
    color: BannerColor;
    className?: string;
}

const HeaderBanner = ({ message, color, className }: Props) => {
    return (
        <Wrapper color={color} className={className}>
            {message}
        </Wrapper>
    );
};

export default HeaderBanner;
