import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Highlight from "./Highlight";
import Navbar from "src/components/Navbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { get } from "src/Api";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { useDispatch } from "react-redux";
import { FlexContent } from "src/components/CommonStyles";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;
const Main = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    & > div {
        height: 100%;
        & > ul {
            height: 100%;
        }
    }
`;

const HighlightsPage = () => {
    const [highlights, setHighlights] = useState([]);
    const [muted, setMuted] = useState(false);
    const dispatch = useDispatch();

    const fetchHighlights = async () => {
        try {
            const res = await get("/v2/highlights", {});
            setHighlights(res.data.data);
        } catch (err) {
            dispatch(setPillNotificationText("Failed to load highlights"));
        }
    };

    useEffect(() => {
        fetchHighlights();
    }, []);

    return (
        <Wrapper>
            <Navbar />
            <FlexContent>
                <Main>
                    <Carousel
                        containerClass="carousel-modal"
                        responsive={{
                            universal: {
                                breakpoint: { max: 100000, min: 0 },
                                items: 1
                            }
                        }}
                        swipeable
                        draggable
                        infinite
                    >
                        {highlights.map(highlight => (
                            <Highlight
                                highlight={highlight}
                                muted={muted}
                                setMuted={setMuted}
                            />
                        ))}
                    </Carousel>
                </Main>
            </FlexContent>
        </Wrapper>
    );
};
export default HighlightsPage;
