import React from "react";
import MainContainer from "src/components/MainContainer";
import { colors, fonts, fontWeight } from "src/constants";
import { BlueButton, GrayButton } from "src/profile/components/Buttons";
import styled, { css } from "styled-components";

const Main = styled.footer`
    position: fixed;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: ${colors.white};
    text-align: left;
    box-shadow: 0px -0.01px 0px rgba(193, 193, 193, 0.25),
        0px -4px 8px rgba(209, 209, 209, 0.25);
`;

const Contents = styled(MainContainer)<{
    type: "Creator Search" | "Saved Groups";
}>`
    display: flex;
    justify-content: ${props => {
        return props.type === "Creator Search" ? "end" : "space-between";
    }};
    align-items: center;
    height: 60px;
    font-size: ${fonts.semiSmallTitle}px;
    font-weight: ${fontWeight.medium};
`;

const buttonContainerStyles = css`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const RightButtons = styled.section`
    ${buttonContainerStyles};
`;

const CreatorCount = styled.span`
    padding: 8px 12px;
    color: ${colors.black};
`;

const RightButton = styled(BlueButton)`
    padding: 8px 12px;
    font-size: ${fonts.semiSmallTitle}px;
`;

interface Props {
    type: "Creator Search" | "Saved Groups";
    exportModal?: () => void;
    selectedCreatorCount?: number;
}

const CreatorSearchFooter = ({
    type,
    selectedCreatorCount,
    exportModal
}: Props) => {
    // Local Functions -----------------------------------------------
    function exportAsCSV(): void {
        alert(
            "Export As CSV BABBYYYYYYYYYY, LET'S. FREAKING. GOOOOOOOOOOO *insert over-energized goose maymay here*"
        );
    }

    function addAllToGroup(): void {
        alert("SAVE IT TO THE GROUP PELASE !!!");
    }

    function saveGroup(): void {
        alert(
            "Save the group. Save the group. Save the group. Join our cult. Save the group."
        );
    }

    // JSX -----------------------------------------------------------
    return (
        <Main>
            <Contents type={type}>
                {type === "Saved Groups" && (
                    <GrayButton
                        type="button"
                        style={{ padding: "8px 12px" }}
                        onClick={addAllToGroup}
                    >
                        Add All To Group
                    </GrayButton>
                )}

                <RightButtons>
                    {(selectedCreatorCount === 0 || selectedCreatorCount) && (
                        <CreatorCount>
                            {selectedCreatorCount} Creators Selected
                        </CreatorCount>
                    )}
                    <RightButton type="button" onClick={exportModal}>
                        Export As CSV
                    </RightButton>
                    {type === "Saved Groups" && (
                        <RightButton type="button" onClick={saveGroup}>
                            Save To Group
                        </RightButton>
                    )}
                </RightButtons>
            </Contents>
        </Main>
    );
};

export default CreatorSearchFooter;
