import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";

const referredUsersState = (state: GlobalState) => state.entities.referredUsers;

export const getReferredUsers = createSelector(
    referredUsersState,
    referredUsersState => {
        return referredUsersState.allIds.map(
            (id: number) => referredUsersState.byId[id]
        );
    }
);
