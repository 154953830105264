import { combineReducers } from "redux";
import { NormalizedAction } from "../../types";
import { Highlight } from "../types";
import { FETCH_CAMPAIGN_HIGHLIGHTS, FETCH_HIGHLIGHTS } from "./actions";

interface ById {
    [id: number]: Highlight;
}

export interface HighlightsEntitiesState {
    byId: ById;
    allIds: number[];
}

const initialHighlightsState: HighlightsEntitiesState = {
    byId: {},
    allIds: []
};

const byId = (
    state = initialHighlightsState.byId,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_HIGHLIGHTS.SUCCESS:
        case FETCH_CAMPAIGN_HIGHLIGHTS.SUCCESS:
            return { ...state, ...action.response.entities.highlights };
        default:
            return state;
    }
};

const allIds = (
    state = initialHighlightsState.allIds,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_HIGHLIGHTS.SUCCESS:
            return action.response.result;
        default:
            return state;
    }
};

const highlightsEntitiesReducer = combineReducers<HighlightsEntitiesState>({
    byId,
    allIds
});

export default highlightsEntitiesReducer;
