import React from "react";
import styled from "styled-components";
import { colors } from "src/constants";
import { Rule } from "src/types/challenge/Rule";

const Main = styled.div`
    width: 100%;
`;

const SectionHeader = styled.h3<{ small?: boolean }>`
    margin-top: ${props => (props.small ? "18px" : "40px")};
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 18px;
`;

const RuleTitle = styled.b`
    font-weight: 600;
`;
const RuleItem = styled.li<{ small?: boolean }>`
    display: flex;
    flex-direction: row;
    font-size: ${props => (props.small ? "12px" : "16px")};
    padding-bottom: 16px;
    border-bottom: 1px solid ${colors.blueGrey2};
    margin-bottom: 16px;
    :last-child {
        border-bottom: none;
    }
`;

const RuleDescription = styled.div`
    font-weight: 400;
    display: inline;
    padding-left: 19px;
`;

const RulesRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    width: 100%;
`;

interface Props {
    generals: Rule[];
    dos: Rule[];
    donts: Rule[];
    small?: boolean;
}

const MediaRules = ({ generals, dos, donts, small }: Props) => {
    return (
        <Main>
            {!!generals.length && (
                <div>
                    <SectionHeader small={small}></SectionHeader>
                    {generals.map(rule => (
                        <RuleItem
                            key={`rule${rule.id}`}
                            style={{ flexDirection: "column" }}
                            small={small}
                        >
                            <RulesRow>
                                👍
                                <RuleTitle>{rule.title}</RuleTitle>
                            </RulesRow>
                            <RuleDescription
                                style={{ padding: "10px 0px 0px 0px" }}
                            >
                                {rule.rule}
                            </RuleDescription>
                        </RuleItem>
                    ))}
                </div>
            )}
            {!!dos.length && (
                <div>
                    <SectionHeader small={small}>
                        Here's what to do
                    </SectionHeader>
                    {dos.map(rule => (
                        <RuleItem key={`rule${rule.id}`} small={small}>
                            ✅
                            <RuleDescription>
                                <RuleTitle>{rule.title} </RuleTitle>
                                {rule.rule}
                            </RuleDescription>
                        </RuleItem>
                    ))}
                </div>
            )}
            {!!donts.length && (
                <div>
                    <SectionHeader small={small}>
                        Here's what not to do
                    </SectionHeader>
                    {donts.map(rule => (
                        <RuleItem key={`rule${rule.id}`} small={small}>
                            🚫
                            <RuleDescription>
                                <RuleTitle>{rule.title} </RuleTitle>
                                {rule.rule}
                            </RuleDescription>
                        </RuleItem>
                    ))}
                </div>
            )}
        </Main>
    );
};
export default MediaRules;
