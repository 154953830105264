import { normalize } from "normalizr";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { get, getPublic } from "../../Api";
import { fetchPlacementsSuccess, FETCH_PLACEMENTS } from "./actions";
import * as schema from "../schema";
import {
    getPlacementConfigByPlatform,
    globalPlacementConfig
} from "./selectors";
import { GlobalState } from "src/reducers";
import { setPlacementConfig } from "src/ui/placements/actions";
import { PlacementConfig } from "src/ui/placements/reducer";

export function* fetchPlacementsRequest() {
    try {
        const { data } = yield call(getPublic, `/v1/placement`, {});

        yield put(
            fetchPlacementsSuccess(normalize(data.data, schema.placementList))
        );

        const currentPlacements: PlacementConfig = yield select(
            globalPlacementConfig
        );

        if (Object.keys(currentPlacements).length === 0) {
            const platform: string = yield select(
                (state: GlobalState) => state.profile.profile?.dominant_platform
            );

            const dominantPlacements: PlacementConfig = yield select(
                getPlacementConfigByPlatform,
                platform
            );

            yield put(setPlacementConfig(dominantPlacements));
        }
    } catch (error) {}
}

export default function* placementsSaga() {
    yield all([takeEvery(FETCH_PLACEMENTS.REQUEST, fetchPlacementsRequest)]);
}
