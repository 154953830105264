import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "../Api";
import {
    addDirectBuyEntities,
    getOpenBuySubmissions,
    updateDirectBuy,
    updateDirectBuyStep
} from "../buys/actions";
import {
    selectDirectBuysAllIds,
    selectDirectBuysById,
    selectDirectBuysLoading,
    selectDirectBuyStepEntities,
    selectOpenBuySubmissionsCache
} from "../buys/selectors";
import { database, db } from "./FirebaseConfig";

import * as schema from "../buys/schema";
import { normalize } from "normalizr";
import { getUserId } from "../profile/selectors";
import { setSocialAccountStatuses } from "../social-accounts/actions";
import { fetchUser } from "../profile/actions";
import { setFeatureFlag } from "./actions";

export const addSocialStatusListener = (accountId, statuses, dispatch) => {
    let ref = database
        .ref()
        .child("socialAccountStatus")
        .child(accountId);

    //remove in case already there
    ref.off();

    ref.on("value", snapshot => {
        statuses[accountId] = snapshot.val();
        dispatch(setSocialAccountStatuses(statuses));
    });
};

const Listeners = () => {
    const loading = useSelector(selectDirectBuysLoading);
    const directBuysAllIds = useSelector(selectDirectBuysAllIds);
    const directBuySteps = useSelector(selectDirectBuyStepEntities);
    const directBuysById = useSelector(selectDirectBuysById);
    const dispatch = useDispatch();
    const publisherId = useSelector(getUserId);

    const initializeDirectBuyListeners = () => {
        //remove all listeners
        database
            .ref()
            .child("directBuy")
            .child(publisherId)
            .off();
        database
            .ref()
            .child("directBuyStep")
            .child(publisherId)
            .off();

        //initialize new listeners

        for (const directBuy of directBuysAllIds) {
            if (directBuysById[directBuy].directBuySteps)
                for (const step of directBuysById[directBuy].directBuySteps) {
                    let stepRef = database
                        .ref()
                        .child("directBuyStep")
                        .child(step);
                    stepRef.on("value", snapshot => {
                        if (
                            snapshot.val().updatedAt !==
                            directBuySteps[step].updatedAt
                        ) {
                            get(`v1/directBuyStep/${step}`, {}).then(res => {
                                dispatch(updateDirectBuyStep(res.data.data));
                            });
                        }
                    });
                }

            let buyRef = database
                .ref()
                .child("directBuy")
                .child(publisherId)
                .child(directBuy);

            buyRef.on("value", snapshot => {
                if (
                    snapshot.val() &&
                    snapshot.val().updatedAt !==
                        directBuysById[directBuy].updatedAt
                ) {
                    get(`v1/directBuy/${directBuy}`, {}).then(res => {
                        dispatch(updateDirectBuy(res.data.data));
                    });
                }
            });
        }

        let ref = database
            .ref()
            .child("directBuy")
            .child(publisherId);
        ref.on("value", snapshot => {
            //check for publishers not yet in new system
            if (!snapshot.val()) return;
            let ids = Object.keys(snapshot.val());
            for (const id of ids) {
                if (!directBuysAllIds.includes(parseInt(id))) {
                    get(`v1/directBuy/${id}`, {}).then(res => {
                        dispatch(
                            addDirectBuyEntities(
                                normalize([res.data.data], schema.directBuyList)
                            )
                        );
                    });
                }
            }
        });
    };

    //socialAccount Listeners
    const socialAccounts = useSelector(
        state => state.entities.socialAccounts.allIds
    );
    let statuses = useSelector(state => state.entities.socialAccounts.statuses);
    const initializeSocialAccountAuthorizationStatuses = () => {
        let ref = database.ref().child("socialAccountStatus");
        //remove all listeners
        ref.off();
        socialAccounts.forEach(accountId => {
            addSocialStatusListener(accountId, statuses, dispatch);
        });
    };

    useEffect(() => {
        if (!loading) initializeDirectBuyListeners();
    }, [directBuysAllIds]);

    useEffect(() => {
        initializeSocialAccountAuthorizationStatuses();
    }, [socialAccounts]);

    const submissions = useSelector(state =>
        selectOpenBuySubmissionsCache(state, publisherId)
    );
    //openBuy Listeners
    const initializeOpenBuyListeners = () => {
        database
            .ref()
            .child("openBuySubmission")
            .off();

        for (const sub of submissions) {
            let subRef = database
                .ref()
                .child("openBuySubmission")
                .child(sub.id);
            subRef.on("value", snapshot => {
                if (snapshot.val().updatedAt > sub.updatedAt) {
                    dispatch(getOpenBuySubmissions(sub.openBuyId));
                }
            });
        }
    };

    useEffect(() => {
        if (!loading) initializeOpenBuyListeners();
    }, [submissions]);

    //accessStatus listener
    const profileState = useSelector(state => {
        return state.profile;
    });

    const initializeAccessStatusListener = () => {
        let ref = database
            .ref()
            .child("accountRefresh")
            .child(profileState.profile.id || "");

        //remove in case already there
        ref.off();

        ref.on("value", snapshot => {
            if (snapshot.val() === 1) {
                dispatch(fetchUser());
                ref.set(0);
            }
        });
    };

    useEffect(() => {
        if (profileState?.profile?.id) initializeAccessStatusListener();
    }, [profileState]);

    const initializeFeatureFlagListener = async () => {
        const collectionRef = db.collection("FeatureFlag");
        try {
            collectionRef
                .get()
                .then(snapshot => {
                    snapshot.docs.map(doc =>
                        collectionRef.doc(doc.id).onSnapshot(snapshot => {
                            dispatch(
                                setFeatureFlag(
                                    doc.id,
                                    snapshot.data().isEnabled
                                )
                            );
                        })
                    );
                })
                .catch(err => console.log("error getting flags: ", err));
        } catch (err) {
            console.error("error: ", err);
        }
    };

    useEffect(() => {
        initializeFeatureFlagListener();
    }, []);

    return null;
};

export default Listeners;
