import React from "react";
import { useDispatch } from "react-redux";
import { breakpoints, colors, fonts, maxWidth } from "src/constants";
import {
    fetchLinkForPlug,
    fetchLinkForPlugWithoutModal
} from "src/links/actions";
import { setCurrentCampaignMedia } from "src/media/actions";
import { setPostMediaModalOpen } from "src/ui/medias/actions";
import { PROMOTE_FLOW } from "src/ui/promote-flow/actions";
import { clearButton, flex, flexColumnCenter } from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import PromoteButton from "../details/components/PromoteButton";
import { CampaignMedia } from "../types";

const ButtonContainer = styled.div`
    ${flexColumnCenter()};
    gap: 10px;
    width: ${maxWidth.mediaCarousel}px;

    @media (max-width: ${breakpoints.mobile}px) {
        width: 95%;
    }
`;

const LinkButtons = styled.div`
    ${flex("row", "auto", "center")};
    gap: 1px;
    border-radius: 6px;
    overflow: hidden;
    width: fill-available;
    width: -webkit-fill-available;
    width: -moz-available;
`;

const buttonStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    flex: 1;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: ${fonts.smallTitle}px;
    transition: 0.1s ease-in;
    cursor: pointer;

    &:hover {
        color: white;
        text-decoration: none;
    }

    &:active {
        background-color: rgba(0, 0, 0, 0.8);
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlue};
        }
    }
`;

const CopyLinkButton = styled.button`
    ${clearButton()};
    ${buttonStyles};
`;

const DownloadButton = styled.a`
    ${buttonStyles};
`;

const Icon = styled.img`
    width: auto;
    height: 20px;
    margin-right: 8px;
`;

interface Props {
    media: CampaignMedia;
}

const MediaModalButtons = ({ media }: Props) => {
    const dispatch = useDispatch();

    return (
        <ButtonContainer>
            <LinkButtons>
                <CopyLinkButton
                    onClick={() => dispatch(fetchLinkForPlug(media))}
                    onMouseDown={e => e.stopPropagation()}
                >
                    <Icon src="/white_link.svg" />
                    Copy Link
                </CopyLinkButton>

                <DownloadButton
                    onMouseDown={e => e.stopPropagation()}
                    target="_blank"
                    download={`media-id-${media.id}`}
                    href={media.url}
                >
                    <Icon src="/white_download.svg" />
                    Download
                </DownloadButton>
            </LinkButtons>
            <PromoteButton
                onClick={() => {
                    dispatch(PROMOTE_FLOW.setSelectedMedia(media));
                    dispatch(fetchLinkForPlugWithoutModal(media));
                    dispatch(setCurrentCampaignMedia(media));
                    dispatch(setPostMediaModalOpen(true));
                }}
            />
        </ButtonContainer>
    );
};

export default MediaModalButtons;
