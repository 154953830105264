import { Action } from "../../types";
import { CAMPAIGN_MODAL } from "../campaigns/actions";

export enum CustomLinksModalState {
    clear,
    index,
    create
}

export const campaignCustomLinksModal = (
    state: CustomLinksModalState
): Action => ({
    type: CAMPAIGN_MODAL.CUSTOM_LINKS,
    payload: { state }
});
