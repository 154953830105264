import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexCentered } from "src/components/CommonStyles";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import {
    setSubmissionManagerAccordion,
    SUBMISSION_MANAGER_ACCORDIONS
} from "src/ui/admin-tools/actions";
import styled from "styled-components";
import ChallengeDetailsAccordion from "./ChallengeDetailsAccordion";
import {
    OpenBuy,
    OpenBuySubmissionSet
} from "src/buys/modules/open-buys/types";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto auto 1fr;
`;
const UserInfoWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    margin: 6px 0px;
    border-radius: 8px;
`;
const ChevronWrapper = styled.div`
    ${FlexCentered}
    width: 24px;
    height: 24px;
    border-radius: 12px;
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: ${colors.tertiaryLightGray};
    }
`;
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.mediumTeal};
`;
const OverflowWrap = styled.div`
    overflow: auto;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.p`
    font-weight: normal;
    display: block;
    margin: 0px;
    padding: 0px;
    font-size: 0.8rem;
    color: ${colors.black};
`;
const Subtitle = styled.p`
    font-weight: normal;
    font-size: 0.8rem;
    margin: 0px;
    padding: 0px;
    color: ${colors.gray2};
`;
const TitleWrap = styled.div`
    padding-left: 12px;
    text-align: left;
`;

const ChallengePhoto = styled.img`
    width: 84px;
    height: 46px;
    border-radius: 8px;
    object-fit: cover;
`;
interface Props {
    submissionSet: OpenBuySubmissionSet;
    openBuy: OpenBuy;
}

const ChallengeSection = ({ submissionSet, openBuy }: Props) => {
    const dispatch = useDispatch();
    const activeAccordions = useSelector(
        (state: GlobalState) =>
            state.ui.adminTools.submissionManager.activeAccordions
    );

    const onToggle = (accordion: SUBMISSION_MANAGER_ACCORDIONS) => {
        if (activeAccordions.includes(accordion))
            dispatch(
                setSubmissionManagerAccordion(
                    activeAccordions.filter(acc => acc !== accordion)
                )
            );
        else
            dispatch(
                setSubmissionManagerAccordion(
                    activeAccordions.concat([accordion])
                )
            );
    };
    return (
        <Wrapper>
            <UserInfoWrap>
                <Header>
                    <ChallengePhoto src={openBuy.posterUrl ?? ""} />
                    <TitleWrap>
                        <Title>{openBuy.name}</Title>
                        <Subtitle>Challenge ID: {openBuy.id}</Subtitle>
                    </TitleWrap>
                </Header>
                <ChevronWrapper
                    onClick={() => {
                        window.open(
                            `${window.location.origin}/buys/open/${openBuy.id}`
                        );
                    }}
                >
                    <img src="/goto-link.svg" alt="" />
                </ChevronWrapper>
            </UserInfoWrap>
            <OverflowWrap>
                <Divider />
                <ChallengeDetailsAccordion
                    submissionSet={submissionSet}
                    title={"Details"}
                    onToggle={v =>
                        onToggle(SUBMISSION_MANAGER_ACCORDIONS.challengeDetails)
                    }
                    manualControl={activeAccordions.includes(
                        SUBMISSION_MANAGER_ACCORDIONS.challengeDetails
                    )}
                />
            </OverflowWrap>
        </Wrapper>
    );
};

export default ChallengeSection;
