import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import FullscreenLoader from "src/components/FullscreenLoader";
import { GlobalState } from "src/reducers";
import { createCurrentChatUserProfileChannel } from "./chat-profiles/actions";
import ChatRoom from "./ChatRoom";
import GroupSettingsPage from "./group-user-settings/GroupSettingsPage";
import { createMessageChannel } from "./message/actions";
import { selectConversationWithId, selectOwnChatProfile } from "./selectors";

interface Props {
    conversationId: string;
}

const ChatRoomController = ({ conversationId }: Props) => {
    const dispatch = useDispatch();
    const participantIds = useSelector(
        (state: GlobalState) =>
            selectConversationWithId(state, conversationId)?.participantIds,
        shallowEqual
    );

    const ownChatProfile = useSelector(selectOwnChatProfile);

    useEffect(() => {
        conversationId && dispatch(createMessageChannel(conversationId));
    }, [conversationId]);

    useEffect(() => {
        participantIds &&
            dispatch(createCurrentChatUserProfileChannel(participantIds));
    }, [participantIds]);

    if (!participantIds || !ownChatProfile) return <FullscreenLoader />;

    return (
        <Switch>
            <Route
                path="/newchat/:conversationId/settings"
                render={() => (
                    <GroupSettingsPage conversationId={conversationId} />
                )}
            />
            <Route
                path="/newchat/:conversationId"
                render={() => <ChatRoom conversationId={conversationId} />}
            />
        </Switch>
    );
};

export default ChatRoomController;
