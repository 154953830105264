import React, { ReactElement } from "react";
import styled from "styled-components";
import { hideScrollbar } from "src/utils/styles/snippets";
import { colors } from "src/constants";

const Wrapper = styled.div<{ zIndex: string | number }>`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    isolation: isolate;
    z-index: ${props => props.zIndex};
`;
const Child = styled.div`
    width: 460px;
    position: relative;
    padding: 24px;
    max-height: 80%;
    overflow-y: hidden;
    background-color: white;
    border-radius: 6px;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 460px;
    color: white;
    font-size: 18px;
    font-weight: 500;
`;
const CloseBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    border-radius: 50%;
    padding: 8px;
    background-color: ${colors.white};

    & > img {
        width: 14px;
        height: 14px;
    }
`;

const BackBtn = styled.button`
    background-color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface Props {
    children: React.ReactNode;
    zIndex?: number | string;
    className?: string;
    header?: { text: string; handleClose: VoidFunction };
    isBack?: boolean;
    onBack?: () => void;
    svg?: React.ReactNode;
}
const SmallModal = ({
    children,
    zIndex = "auto",
    header,
    isBack,
    onBack,
    className,
    svg
}: Props) => {
    return (
        <Wrapper className={className} zIndex={zIndex}>
            {header && (
                <Header id="small-modal-header">
                    {isBack && (
                        <BackBtn
                            onClick={e => {
                                e.stopPropagation();
                                onBack && onBack();
                            }}
                        >
                            <img src="/back-button.svg" alt="back" />
                        </BackBtn>
                    )}
                    {svg && svg}
                    {header.text}
                    <CloseBtn onClick={header.handleClose}>
                        <img src="/black_close.svg" alt="close modal" />
                    </CloseBtn>
                </Header>
            )}
            <Child id="small-modal-child">{children}</Child>
        </Wrapper>
    );
};

export default SmallModal;
