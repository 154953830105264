import React from "react";
import { Link, useHistory } from "react-router-dom";
import { colors, fonts } from "src/constants";
import {
    clearLink,
    grayBackgroundHoverState,
    primaryBlueBackgroundHoverState
} from "src/utils/styles/snippets";
import styled from "styled-components";
import { ChallengeLink } from "./ChallengeMainHeader";

const StyledLink = styled(Link)<{ selected: boolean }>`
    ${clearLink()};
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    color: ${props => (props.selected ? colors.white : colors.primaryGray)};
    border-radius: 6px;
    font-size: ${fonts.semiSmallTitle}px;
    transition: 0.1s ease-in;

    ${props => {
        return props.selected
            ? primaryBlueBackgroundHoverState()
            : grayBackgroundHoverState();
    }};

    @media (hover: hover) {
        :hover {
            color: ${props => props.selected && colors.white};
            background-color: ${props => props.selected && colors.primaryBlue};
        }

        :hover > svg {
            fill: ${props => (props.selected ? colors.white : colors.black)};
        }
    }
`;

const Badge = styled.div`
    border-radius: 100px;
    background-color: ${colors.secondaryRed};
    color: white;
    height: 20px;
    border-radius: 4px;
    width: 20px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface Props extends ChallengeLink {
    badgeCount?: number;
}

const ChallengeLinkTab = ({ text, route, badgeCount }: Props) => {
    const history = useHistory();
    const { pathname } = history.location;

    function checkSelected(): boolean {
        if (text === "Challenge Report") {
            return pathname.includes("challenge-report");
        } else if (text === "View Challenge") {
            return pathname.includes("view-challenge");
        } else if (text === "Edit Challenge") {
            return pathname.includes("edit-challenge");
        } else if (text === "Extensions") {
            return pathname.includes("extensions");
        } else {
            return pathname.includes("approvals");
        }
    }

    return (
        <StyledLink to={route} selected={checkSelected()}>
            {text}
            {!!badgeCount && <Badge>{badgeCount}</Badge>}
        </StyledLink>
    );
};

export default ChallengeLinkTab;
