import { all, call, fork, put, select } from "@redux-saga/core/effects";
import { normalize } from "normalizr";
import * as schema from "./schema";
import { deleteReq, get, post } from "../../Api";
import { getAuthState } from "../../auth/selectors";
import {
    deletePublisherOfferSuccess,
    DELETE_PUBLISHER_OFFER,
    fetchPublisherOffersSuccess,
    patchPublisherOfferFailure,
    patchPublisherOfferSuccess,
    PATCH_PUBLISHER_OFFER,
    postPublisherOfferFailure,
    postPublisherOfferSuccess,
    POST_PUBLISHER_OFFER
} from "./actions";
import { fetchUserSocialAccountsSuccess } from "../accounts/actions";
import { fetchPlacementsRequest } from "../../campaigns/placements/saga";
import { takeLeading } from "redux-saga/effects";
import { Action } from "../../types";
import { push } from "connected-react-router";
import { errorToString } from "../../util";
import { GlobalState } from "src/reducers";

export function* fetchUserSocialAccountsRequest() {
    try {
        const { userId } = yield select(getAuthState);
        const { data } = yield call(get, "/v1/social_account", {
            publisher: `p:${userId}`
        });

        yield put(
            fetchUserSocialAccountsSuccess(
                normalize(data.data, schema.socialAccountList)
            )
        );
        yield fork(fetchPublisherOffersRequest);
        yield fork(fetchPlacementsRequest);
    } catch (error) {}
}

export function* fetchPublisherOffersRequest() {
    try {
        const { userId } = yield select(getAuthState);
        const { data } = yield call(
            get,
            `/v1/publisherOffer?publisher=p:${userId}`,
            {}
        );

        yield put(
            fetchPublisherOffersSuccess(
                normalize(data.data, schema.publisherOfferList)
            )
        );
    } catch (error) {}
}

export function* postPublisherOfferRequest({ payload }: Action) {
    try {
        const { data } = yield call(post, "/v1/publisherOffer", {
            ...payload
        });

        yield put(
            postPublisherOfferSuccess(
                normalize([data.data], schema.publisherOfferList)
            )
        );
        const onIntroFlowStep: number = yield select(
            (state: GlobalState) => state.ui.flows.introToDirectBuy
        );

        if (onIntroFlowStep === 4) {
            yield put(push("/buys/direct/intro"));
        } else {
            yield put(push("/profile"));
        }
    } catch (error) {
        yield put(postPublisherOfferFailure(errorToString(error)));
    }
}

export function* deletePublisherOfferRequest({
    payload: { publisherOfferId, accountId }
}: Action) {
    try {
        yield call(deleteReq, `/v1/publisherOffer/${publisherOfferId}`, {});

        yield put(deletePublisherOfferSuccess(publisherOfferId, accountId));
        const onIntroFlowStep: number = yield select(
            (state: GlobalState) => state.ui.flows.introToDirectBuy
        );

        if (onIntroFlowStep === 4) {
            yield put(push("/buys/direct/intro"));
        } else {
            yield put(push("/profile"));
        }
    } catch (error) {}
}

export function* patchPublisherOfferRequest({ payload }: Action) {
    try {
        yield call(deleteReq, `/v1/publisherOffer/${payload.deleteId}`, {});
        const { data } = yield call(post, "/v1/publisherOffer", {
            ...payload.newPublisherOffer
        });

        yield put(
            patchPublisherOfferSuccess(
                normalize([data.data], schema.publisherOfferList),
                payload.deleteId,
                data.data.id,
                payload.accountId
            )
        );
        const onIntroFlowStep: number = yield select(
            (state: GlobalState) => state.ui.flows.introToDirectBuy
        );

        if (onIntroFlowStep === 4) {
            yield put(push("/buys/direct/intro"));
        } else {
            yield put(push("/profile"));
        }
    } catch (error) {
        yield put(patchPublisherOfferFailure(errorToString(error)));
    }
}

export default function* rateSaga() {
    yield all([
        takeLeading(POST_PUBLISHER_OFFER.REQUEST, postPublisherOfferRequest),
        takeLeading(
            DELETE_PUBLISHER_OFFER.REQUEST,
            deletePublisherOfferRequest
        ),
        takeLeading(PATCH_PUBLISHER_OFFER.REQUEST, patchPublisherOfferRequest)
    ]);
}
