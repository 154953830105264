import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { breakpoints, colors, fonts } from "../constants";
import Link from "./Link";
import { flex } from "src/utils/styles/snippets";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyButton } from "src/profile/components/Buttons";
import { addNotification } from "src/ui/notifications/actions";
import { useDispatch } from "react-redux";

interface ClickableHeaderProps {
    clickable: boolean;
    topPadding?: number;
    sidePadding?: number;
}

const ClickableHeader = styled.div<ClickableHeaderProps>`
    padding: ${props => {
        const { topPadding, sidePadding } = props;
        if (sidePadding === 0 && topPadding === 0) return "0px";
        else if (topPadding === 0) return `0px ${sidePadding || 15}px`;
        else if (sidePadding === 0) return `${topPadding || 15}px 0px`;
        else return `${topPadding || 15}px ${sidePadding || 15}px`;
    }};
    background-color: ${colors.transparent};
    font-size: 12px;
    transition: 0.1s ease-in;
    cursor: ${props => (props.onClick ? "pointer" : "default")};

    @media (hover: hover) {
        :hover {
            background-color: ${props => props.clickable && colors.lightGray};
        }
    }

    @media (max-width: ${breakpoints.mobile}px) {
        padding-right: 15px;
        padding-left: 15px;
    }
`;

const AlignedRow = styled.div`
    ${flex("row", "space-between", "center")};
`;

const Rows = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

// const Row = styled("div")`
//     ${flex("row", "space-between")};
//     gap: 10px;
// `;

const Title = styled.div<{ desktop?: boolean }>`
    margin-bottom: 4px;
    font-size: ${fonts.title}px;
    font-weight: 500;
    line-height: 23.87px;
    text-align: left;

    @media (max-width: ${breakpoints.mobile}px) {
        font-size: 20px;
    }
`;

const Subtitle = styled.div`
    color: ${colors.primaryGray};
    font-size: ${fonts.subtitle};
    font-weight: 400;
    line-height: 16.71px;
`;

const Chevron = styled.div`
    color: ${colors.primaryGray};
    user-select: none;
    cursor: pointer;
    font-size: 12px;
`;

const Button = styled.button`
    display: inline-block;
    padding: 8px 12px;
    color: ${colors.black};
    background-color: ${colors.quaternaryLightGray};
    border-radius: 6px;
    font-size: ${fonts.smallTitle}px;
    font-weight: 500;
    text-decoration: none;
    transition: 0.1s;
    cursor: pointer;
    white-space: nowrap;

    @media (hover: hover) {
        :hover {
            color: ${colors.white};
            background-color: ${colors.primaryBlue};
            text-decoration: none;
        }
    }

    @media (max-width: 330px) {
        margin-top: 16px;
    }
    @media (max-width: 800) {
        white-space: normal;
    }
`;

const CopyLinkButton = styled(CopyButton)`
    white-space: nowrap;
    width: auto;
    height: auto;
    padding: 8px 12px;
    border-radius: 6px;

    @media (max-width: 330px) {
        margin-top: 16px;
    }
    @media (max-width: 800) {
        white-space: normal;
    }
`;

interface Link {
    text: string;
    onClick?: VoidFunction;
    copyText?: string;
}

interface Props {
    title: string;
    subtitle: string;
    link?: Link;
    links?: Link[];
    chevron?: () => void;
    backgroundColor?: string;
    desktop?: boolean;
    margin?: string;
    topPadding?: number;
    sidePadding?: number;
}

const Header = ({
    title,
    subtitle,
    link,
    links,
    backgroundColor,
    desktop,
    chevron,
    topPadding,
    sidePadding
}: Props) => {
    const dispatch = useDispatch();
    return (
        <ClickableHeader
            onClick={chevron && chevron}
            clickable={!!chevron}
            topPadding={topPadding}
            sidePadding={sidePadding}
        >
            <AlignedRow>
                <Rows>
                    <Row>
                        <Title>{title}</Title>
                        <Row>
                            {links &&
                                links.map((link: Link) =>
                                    desktop ? (
                                        <Button
                                            key={link.text}
                                            onClick={link.onClick}
                                        >
                                            {link.text}
                                        </Button>
                                    ) : (
                                        <Link
                                            key={link.text}
                                            link={{
                                                text: link.text,
                                                onClick: link.onClick
                                            }}
                                        />
                                    )
                                )}
                        </Row>
                        {link &&
                            (desktop ? (
                                <Button key={link.text} onClick={link.onClick}>
                                    {link.text}
                                </Button>
                            ) : (
                                <Link
                                    key={link.text}
                                    link={{
                                        text: link.text,
                                        onClick: link.onClick
                                    }}
                                />
                            ))}
                    </Row>
                    <Row>
                        <Subtitle>{subtitle}</Subtitle>
                    </Row>

                    {link &&
                        (desktop ? (
                            <Button onClick={link.onClick}>{link.text}</Button>
                        ) : (
                            <Link link={link} />
                        ))}
                </Rows>

                {chevron && (
                    <Chevron>
                        <FontAwesomeIcon icon="chevron-right" />
                    </Chevron>
                )}
            </AlignedRow>
        </ClickableHeader>
    );
};

export default Header;
