import React from "react";
import useInitializeChatRoom from "../hooks/useInitializeChatRoom";
import ChatRoom from "../ChatRoom";
import { useDispatch } from "react-redux";

interface Props {
    conversationId: string;
}

const SubmissionApprovalInternalNotesController = ({
    conversationId
}: Props) => {
    const dispatch = useDispatch();
    useInitializeChatRoom(conversationId);

    return <ChatRoom conversationId={conversationId} />;
};
export default SubmissionApprovalInternalNotesController;
