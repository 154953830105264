import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ProfileTabState, PublicProfileData } from "../types";
import { replace } from "connected-react-router";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import ProfileHeader from "../components/ProfileHeader";
import { statusModal } from "../actions";
import FullscreenLoader from "../../components/FullscreenLoader";
import { GlobalState } from "../../reducers";
import { ProfileModal } from "../reducer";
import StatusModal from "../modals/StatusModal";
import InfoRow from "../components/InfoRow";
import HeaderButtons from "./HeaderButtons";
import Bio from "../components/Bio";
import PublicActionsModal from "./PublicActionsModal";
import { actionsModal } from "../../modals/actions";
import moment from "moment";
import CampaignModals from "../modals/CampaignModals";
import MainContainer from "src/components/MainContainer";
import ProfileMainContainer from "../components/ProfileMainContainer";
import ProfileHeaderContainer from "../components/ProfileHeaderContainer";
import ProfileTabsContainer from "../components/ProfileTabsContainer";
import { useHistory } from "react-router";
import Navbar from "src/components/Navbar";
import PublicConnectionsCard from "../connections/public/PublicConnectionsCard";
import Divider from "src/components/Divider";
import PublicAccountsCard from "../accounts/public/PublicAccountsCard";

interface Props {
    publicData?: PublicProfileData;
    tabState: ProfileTabState;
}

const PublicMain = ({ tabState, publicData }: Props) => {
    const modal = useSelector((state: GlobalState) => {
        return state.profile.modal;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();

    function dispatchStatusModal(): void {
        dispatch(statusModal());
    }

    function dispatchActionsModal(): void {
        dispatch(actionsModal());
    }

    function dispatchReplace(route: string): void {
        dispatch(replace(route));
    }

    if (publicData) {
        const connection = publicData.connection;
        const userid = connection.other.id.slice(2);
        const stats = publicData.stats;

        return (
            <ProfileMainContainer>
                <Navbar
                    marginBottom={20}
                    customMobileNavbar={
                        <SimpleNavbar
                            left={<NavButton back onClick={goBack} />}
                            right={
                                <NavButton right onClick={dispatchActionsModal}>
                                    <img src="/ellipsis-button.svg" />
                                </NavButton>
                            }
                        />
                    }
                />

                <MainContainer>
                    <ProfileHeaderContainer>
                        <ProfileHeader
                            publicProfile={connection.other}
                            statusModal={dispatchStatusModal}
                        />
                        <Bio bio={connection.other.profile.bio} />
                        <InfoRow
                            infos={[
                                {
                                    title: stats.rank
                                        ? `#${stats.rank}`
                                        : "None",
                                    subtitle: "Weekly Rank"
                                },
                                {
                                    title: `${stats.connectionCount}`,
                                    subtitle: "Connections"
                                },
                                {
                                    title: `${moment(stats.memberSince).format(
                                        "MMM ‘YY"
                                    )}`,
                                    subtitle: "Member Since"
                                }
                            ]}
                        />
                        <HeaderButtons connection={connection} />
                    </ProfileHeaderContainer>

                    <ProfileTabsContainer>
                        <PublicConnectionsCard publicData={publicData} />
                        <Divider />
                        <PublicAccountsCard publicData={publicData} />
                    </ProfileTabsContainer>
                </MainContainer>

                <CampaignModals />
                {modal === ProfileModal.status && <StatusModal />}
                {modal === ProfileModal.actions && (
                    <PublicActionsModal connection={connection} />
                )}
            </ProfileMainContainer>
        );
    } else {
        return (
            <>
                <Navbar back />
                <FullscreenLoader />
            </>
        );
    }
};

export default PublicMain;
