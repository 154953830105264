import React from "react";
import styled from "styled-components";
import { codeToEmoji } from "../notifications/CodeToEmoji";
import { colors } from "../constants";

interface StyleProps {
    boxSize: number;
}

const Main = styled.div<StyleProps>`
    background-color: ${colors.lightTeal};
    border-radius: ${props => Math.round(props.boxSize * 0.5)}px;
    display: flex;
    margin-left: 4px;
`;

const IconContainer = styled.div<StyleProps>`
    height: ${props => props.boxSize}px;
    padding-left: ${props => Math.round(props.boxSize * 0.4)}px;
    font-size: ${props => props.boxSize * 0.6}px;
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;

    & + & {
        padding-left: ${props => Math.round(props.boxSize * 0.15)}px;
        padding-right: ${props => Math.round(props.boxSize * 0.3)}px;
    }
`;

const EmojiContainer = styled.div<StyleProps>`
    height: ${props => Math.round(props.boxSize * 0.6)}px;
    width: ${props => Math.round(props.boxSize * 0.6)}px;
    margin-top: ${props => (props.boxSize < 20 ? 3 : props.boxSize * 0.1)}px;
`;

const PlatformIcon = styled.img<StyleProps>`
    height: ${props => Math.round(props.boxSize * 0.6)}px;
    width: ${props => Math.round(props.boxSize * 0.6)}px;
    opacity: 0.5;
`;

interface Props {
    size?: number;
    country: string;
    platform: string;
}

const CountryPlatformCell = ({ size = 24, country, platform }: Props) => {
    const emoji = codeToEmoji[country];

    return (
        <Main boxSize={size}>
            <IconContainer boxSize={size}>
                <EmojiContainer boxSize={size}>{emoji}</EmojiContainer>
            </IconContainer>
            <IconContainer boxSize={size}>
                <PlatformIcon
                    boxSize={size}
                    src={
                        platform === "android"
                            ? "/android_logo.svg"
                            : "/apple_logo.svg"
                    }
                />
            </IconContainer>
        </Main>
    );
};

export default CountryPlatformCell;
