import React from "react";

import { Props } from "src/components/icons/types";

const Play = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || "10"}
            viewBox="0 0 10 10"
            fill="none"
        >
            <path
                fillRule="evenodd"
                d="M.04 9.377c0 .463.488.764.902.555L9.62 5.555c.456-.23.456-.881 0-1.11L.942.067C.528-.141.04.159.04.623v8.755z"
                fill={props.fill || "#000"}
            />
        </svg>
    );
};

export default Play;
