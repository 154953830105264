import React from "react";
import { CustomLoading } from "src/components/SkeletonLoader";
import styled from "styled-components";

const Header = styled.div`
    grid-area: header;
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
`;
const MediaWrap = styled.div`
    grid-area: mediaView;
    padding-right: 12px;
`;
const Description = styled.div`
    grid-area: description;
    display: flex;
    flex-direction: column;
    padding-left: 12px;
`;
const Buttons = styled.div`
    grid-area: buttons;
    display: flex;
`;
const MediaModalLoading = () => {
    return (
        <>
            <Header>
                <CustomLoading width="140px" height="26px" borderRadius="8px" />
                <div style={{ display: "flex" }}>
                    <CustomLoading
                        style={{ marginRight: "8px" }}
                        width="24px"
                        height="24px"
                        borderRadius="50%"
                    />
                    <CustomLoading
                        width="24px"
                        height="24px"
                        borderRadius="50%"
                    />
                </div>
            </Header>
            <MediaWrap>
                <CustomLoading width="100%" height="100%" borderRadius="8px" />
            </MediaWrap>
            <Description>
                <CustomLoading
                    style={{ marginBottom: "16px" }}
                    width="100%"
                    height="60px"
                    borderRadius="8px"
                />
                <CustomLoading width="100%" height="60px" borderRadius="8px" />
            </Description>
            <Buttons>
                <CustomLoading
                    style={{ marginRight: "8px" }}
                    width="210px"
                    height="31px"
                    borderRadius="8px"
                />
                <CustomLoading width="210px" height="31px" borderRadius="8px" />
            </Buttons>
        </>
    );
};

export default MediaModalLoading;
