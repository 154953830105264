import React, { useState } from "react";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import FullScreenModal from "../../../../modals/FullScreenModal";
import NavButton from "../../../../components/NavButton";
import { SocialAccount } from "../../../types";
import AccountCell from "../../components/AccountCell";
import Header from "../../../../components/Header";
import PaddingNoTop from "../../../../components/PaddingNoTop";
import styled from "styled-components";
import VerticallyCentered from "../../../../components/VerticallyCentered";
import { colors } from "../../../../constants";
import VisibilityIcon from "../../components/VisibilityIcon";
import PrivacyAccountDescriptions from "./PrivacyAccountDescriptions";

const ToggleButtons = styled.div`
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    margin: 5px 0;
`;

const Divider = styled.div`
    height: 1px;
    width: calc(100% - 30px);
    margin-left: 15px;
    background-color: ${colors.mediumTeal};
`;

const Button = styled(VerticallyCentered)`
    padding: 15px;
    cursor: pointer;

    svg {
        margin-right: 15px;
    }
`;

interface Props {
    account?: SocialAccount;
    cancelCallback: VoidFunction;
    doneCallback: (visibility: SocialAccount["visibility"]) => void;
}

const PrivacyAccountModal = ({
    account,
    cancelCallback,
    doneCallback
}: Props) => {
    const [visibility, setVisibility] = useState(
        account ? account.visibility : "visible"
    );

    return (
        <FullScreenModal closeModal={cancelCallback}>
            <SimpleNavbar
                title={"Set Visibility"}
                left={<NavButton onClick={cancelCallback}>Cancel</NavButton>}
                right={
                    <NavButton onClick={() => doneCallback(visibility)}>
                        Done
                    </NavButton>
                }
            />

            <Header
                title={"Account Visibility"}
                subtitle={"Set restrictions to who can view this account"}
            />

            {account && (
                <PaddingNoTop>
                    <AccountCell account={account} />
                </PaddingNoTop>
            )}

            <PaddingNoTop>
                <ToggleButtons>
                    <Button
                        onClick={() => {
                            setVisibility("visible");
                        }}
                    >
                        <VisibilityIcon
                            visibility={"visible"}
                            active={visibility === "visible"}
                        />
                        <div>Visible</div>
                    </Button>
                    <Divider />
                    <Button
                        onClick={() => {
                            setVisibility("connections");
                        }}
                    >
                        <VisibilityIcon
                            visibility={"connections"}
                            active={visibility === "connections"}
                        />
                        <div>Connections Only</div>
                    </Button>
                    <Divider />
                    <Button
                        onClick={() => {
                            setVisibility("hidden");
                        }}
                    >
                        <VisibilityIcon
                            visibility={"hidden"}
                            active={visibility === "hidden"}
                        />
                        <div>Hidden</div>
                    </Button>
                </ToggleButtons>
            </PaddingNoTop>
            
            <PaddingNoTop>
                <PrivacyAccountDescriptions visibility={visibility} />
            </PaddingNoTop>
        </FullScreenModal>
    );
};

export default PrivacyAccountModal;
