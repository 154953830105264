import firebase from "@firebase/app";
import "@firebase/database";
import "@firebase/auth";
import "@firebase/storage";
import "@firebase/firestore";
import { get, post } from "../Api";
import { loggedIn, waitMilliseconds } from "../util";
import { FirebaseFirestore } from "@firebase/firestore-types";
import { logout } from "../profile/actions";

let firebaseConfig;

if (localStorage.getItem("useDev")) {
    firebaseConfig = {
        apiKey: "AIzaSyBNiplquPwQAWVgGJoWS4LqpVjpOdX-Kw0",
        authDomain: "plugcoin-9c41c.firebaseapp.com",
        databaseURL: "https://plugcoin-9c41c.firebaseio.com",
        storageBucket: "plugcoin-9c41c.appspot.com"
    };
} else {
    firebaseConfig = {
        apiKey: "AIzaSyCRYBeb5B5J0EJQr7-631BTwu4f6p9EsKc",
        authDomain: "plugcoinprod.firebaseapp.com",
        databaseURL: "https://plugcoinprod.firebaseio.com",
        storageBucket: "plugcoinprod.appspot.com",
        projectId: "plugcoinprod"
    };
}

firebase.initializeApp(firebaseConfig);

// @ts-ignore
export const auth = firebase.auth();
let retry = 0;
export const setFirebaseToken = async (firebaseToken: string) => {
    auth.signInWithCustomToken(firebaseToken).catch(error => {
        if (firebaseToken) {
            const code = error.code;
            const message = error.message;

            post("/api/submit_event", {
                event_name: "pw_firebase_auth_error",
                event_extra: {
                    firebaseToken,
                    firebaseError: `Status: ${code}, Message: ${message}`
                }
            }).catch(err => {});
        }
    });
    await waitMilliseconds(1000);
    let token = await auth.currentUser?.getIdToken();
    if (!token && loggedIn() && retry < 5) {
        retry += 1;
        let data = await get("/api/get_new_firebase_token", {});
        await setFirebaseToken(data.data.firebaseToken);
    } else logout();
};

//called on every page load
auth.onAuthStateChanged(function(user) {
    const firebaseToken = localStorage.getItem("firebaseToken");
    console.log(`auth listener fired: ${!!firebaseToken}`, user);
    if (!user && firebaseToken) setFirebaseToken(firebaseToken);
});

//
export async function getFirebaseUser() {
    const firebaseToken = localStorage.getItem("firebaseToken");
    if (auth.currentUser) return auth.currentUser;
    else if (!firebaseToken) logout();
    else {
        await setFirebaseToken(firebaseToken);
    }
}

// @ts-ignore
export const storage = firebase.storage();

// @ts-ignore
export const database = firebase.database();

// @ts-ignore
export const db: FirebaseFirestore = firebase.firestore();
