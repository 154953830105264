import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CircleIcon from "../../../components/CircleIcon";
import VerticallyCentered from "../../../components/VerticallyCentered";
import { GlobalState } from "../../../reducers";
import { fetchCampaignMediaRules } from "../../media-rules/actions";
import { getMediaRules } from "../../selectors";
import { MediaRule } from "../../types";
import MediaRulesList from "../components/MediaRuleList";
import { List, StatusWrapper } from "../Details.styled";
import XCard from "../../../profile/components/XCard";
import withAccordion from "./withAccordion";
import { TextLoading } from "../../../components/SkeletonLoader";

const ListItem = styled(VerticallyCentered)`
    & > * ~ * {
        margin-left: 8px;
    }
`;

interface Props {
    currentCampaign: number | null;
    fetchCampaignMediaRules: (campaignId: number) => void;
    mediaRules?: MediaRule[];
    mediaRulesLoading: boolean;
}

const MediaRulesAccordion = ({
    currentCampaign,
    fetchCampaignMediaRules,
    mediaRules,
    mediaRulesLoading
}: Props) => {
    if (mediaRulesLoading)
        return (
            <List>
                <ListItem>
                    <CircleIcon type="loading" />
                    <TextLoading width={Math.floor(Math.random() * 20) + 70} />
                </ListItem>
                <ListItem>
                    <CircleIcon type="loading" />
                    <TextLoading width={Math.floor(Math.random() * 20) + 70} />
                </ListItem>
                <ListItem>
                    <CircleIcon type="loading" />
                    <TextLoading width={Math.floor(Math.random() * 20) + 70} />
                </ListItem>
            </List>
        );

    if (!mediaRules)
        return (
            <StatusWrapper>
                <XCard
                    text="Failed to load media rules, click the button below to try again."
                    button={{
                        text: "Try again",
                        callback: () => {
                            currentCampaign &&
                                fetchCampaignMediaRules(currentCampaign);
                        }
                    }}
                />
            </StatusWrapper>
        );

    if (mediaRules.length) return <MediaRulesList mediaRules={mediaRules} />;

    return (
        <StatusWrapper>
            <XCard
                text="No media rules for this campaign."
                button={{
                    text: "Try again",
                    callback: () => {
                        currentCampaign &&
                            fetchCampaignMediaRules(currentCampaign);
                    }
                }}
            />
        </StatusWrapper>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    currentCampaign: state.ui.campaign.currentCampaignId,
    mediaRulesLoading: state.ui.campaign.mediaRulesLoading,
    mediaRules: getMediaRules(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchCampaignMediaRules: (campaignId: number) =>
        dispatch(fetchCampaignMediaRules(campaignId))
});

export default withAccordion(
    connect(mapStateToProps, mapDispatchToProps)(MediaRulesAccordion)
);
