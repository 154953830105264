import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { Campaign } from "src/campaigns/types";
import styled from "styled-components";
import CheckBox from "src/components/CheckBox";
import DynamicAssetCell from "../../../media/view/DynamicAssetCell";
import { Shimmer } from "src/components/SkeletonLoader";
import SelectFooter from "../../components/SelectFooter";
import { setCurrentModalAssets } from "src/ui/assets/actions";
import { fetchCampaignAssets } from "src/campaigns/assets/actions";
import SearchBar from "src/components/SearchBar";
import { breakpoints } from "src/constants";
import XCard from "src/profile/components/XCard";
import { searchAssets } from "../selectors";

const AssetWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 13px;
    padding: 6px 24px 0px;
    width: 100%;
    @media (max-width: ${breakpoints.tablet}px) {
        grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: ${breakpoints.small}px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: ${breakpoints.mobile}px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const SelectWrap = styled.div`
    position: relative;
    ${Shimmer}
    border-radius: 6px;

    &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(16 / 9 * 100%);
    }
`;
const SelectBox = styled(CheckBox)`
    position: absolute;
    top: 12px;
    right: 12px;
`;
const SearchWrap = styled.div`
    width: 100%;
    padding-bottom: 12px;
    padding-top: 2px;
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 2;
`;
const StyledSearchBar = styled(SearchBar)`
    width: 100%;
    padding: 0px 2px;
    /* margin-bottom: 12px; */
`;
const StyledXCard = styled(XCard)`
    width: 100%;
`;

interface Props {
    campaign: Campaign;
    handleSubmit: () => void;
    selectedAssets: number[];
    selectAsset: (id: number) => void;
    unselectAsset: (id: number) => void;
}

const AssetSearch = ({
    selectedAssets,
    selectAsset,
    unselectAsset,
    campaign,
    handleSubmit
}: Props) => {
    const dispatch = useDispatch();
    const [searchString, setSearchString] = useState("");
    const campaignAssets = useSelector((state: GlobalState) =>
        searchAssets(state, searchString)
    );

    const isSearchNaN = !/^\d+$/.test(searchString);
    const isSearchNotEmpty = searchString.length > 0;

    if (!campaignAssets) {
        return (
            <>
                <StyledXCard
                    text="Failed to load Assets"
                    button={{
                        text: "Try again",
                        callback: () => {
                            campaign.id &&
                                dispatch(fetchCampaignAssets(campaign.id));
                        }
                    }}
                />
            </>
        );
    }
    if (isSearchNaN && isSearchNotEmpty) {
        return (
            <>
                <SearchWrap>
                    <StyledSearchBar
                        searchString={searchString}
                        updateSearchString={e => setSearchString(e)}
                        placeholder="Search by ID"
                    />
                </SearchWrap>
                <StyledXCard text="Search may only contain numbers." />
            </>
        );
    }
    if (campaignAssets.length > 0) {
        return (
            <>
                <SearchWrap>
                    <StyledSearchBar
                        searchString={searchString}
                        updateSearchString={e => setSearchString(e)}
                        placeholder="Search by ID"
                    />
                </SearchWrap>
                <AssetWrap>
                    {campaignAssets.map((asset, idx) => (
                        <SelectWrap key={idx + asset.id}>
                            <DynamicAssetCell
                                assetId={asset.id}
                                handleClick={() =>
                                    dispatch(setCurrentModalAssets([asset.id]))
                                }
                                isSelected={selectedAssets.includes(asset.id)}
                            />
                            <SelectBox
                                onCheck={() => selectAsset(asset.id)}
                                onUncheck={() => unselectAsset(asset.id)}
                                selected={selectedAssets.includes(asset.id)}
                            />
                        </SelectWrap>
                    ))}
                </AssetWrap>
            </>
        );
    }
    if (campaignAssets.length === 0 && isSearchNotEmpty) {
        return (
            <>
                <SearchWrap>
                    <StyledSearchBar
                        searchString={searchString}
                        updateSearchString={e => setSearchString(e)}
                        placeholder="Search by ID"
                    />
                </SearchWrap>
                <StyledXCard text="There are no assets that match your search." />
            </>
        );
    }
    return (
        <>
            <StyledXCard
                text="This campaign doesn’t have any asset resources yet"
                button={{
                    text: "Try again",
                    callback: () => {
                        campaign.id &&
                            dispatch(fetchCampaignAssets(campaign.id));
                    }
                }}
            />
        </>
    );
};

export default AssetSearch;
