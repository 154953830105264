import React from "react";
import { useLocation } from "react-router";
import { colors } from "src/constants";
import { Tab } from "src/marketplace/challenge-tools/components/ChallengeToolsLinkTab";
import MarketplacePagePartialHeader from "src/marketplace/components/MarketplacePagePartialHeader";
import styled from "styled-components";

interface Link {
    label: string;
    route: string;
}

const links: Link[] = [
    { label: "Creator Search", route: "creator-search" },
    { label: "Saved Groups", route: "creator-search/saved-groups" }
];

const LinkTabs = styled.section`
    display: flex;
    gap: 8px;
    margin: 0px 0px 24px;
`;

const TabLink = styled(Tab)`
    color: ${colors.primaryGray};
    background-color: ${colors.quaternaryLightGray};
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlue};
            color: ${colors.white};
        }
    }
`;

const CreatorSearchTabs = () => {
    // Library Methods -----------------------------------------------
    const location = useLocation();

    // Local Constants -----------------------------------------------
    const { pathname } = location;

    // JSX -----------------------------------------------------------
    return (
        <>
            <MarketplacePagePartialHeader />

            <LinkTabs>
                {links.map((link: Link, index: number) => {
                    return (
                        <Tab
                            key={index}
                            to={`/admin-tools/${link.route}`}
                            selected={pathname === `/admin-tools/${link.route}`}
                        >
                            {link.label}
                        </Tab>
                    );
                })}
            </LinkTabs>
        </>
    );
};

export default CreatorSearchTabs;
