import { GlobalState } from "src/reducers";
import { createSelector } from "reselect";
import { Campaign } from "../types";
import createCachedSelector from "re-reselect";
import { Media } from "src/media/types";

export function campaignsById(state: GlobalState) {
    return state.entities.campaigns.byId;
}

function campaignsAllIds(state: GlobalState) {
    return state.entities.campaigns.allIds;
}

function campaignsMaxItems(state: GlobalState) {
    return state.ui.campaign.maxItems;
}

function searchStringFilter(state: GlobalState, filter: string) {
    return filter;
}

export function campaignSort(state: GlobalState) {
    return state.ui.campaign.sortBy;
}

export const getFeedCampaigns = createSelector(
    campaignsById,
    campaignsAllIds,
    campaignsMaxItems,
    searchStringFilter,
    (campaignItems, campaignsIds, maxCampaignItems, filter) => {
        const campaigns = campaignsIds.map((id: number) => {
            return campaignItems[id];
        });

        // Filter campaigns by search term from search bar
        const filteredCampaigns = campaigns.filter((campaign: Campaign) => {
            if (filter.length === 0) {
                return true;
            } else {
                return campaign.campaignName
                    .toLowerCase()
                    .includes(filter.toLowerCase());
            }
        });

        return filteredCampaigns.slice(0, maxCampaignItems);
    }
);

export const getMemoizedCampaignMedias = createCachedSelector(
    (state: GlobalState) => state.entities.medias,
    (state: GlobalState) => state.entities.campaigns.byId,
    (state: GlobalState, campaignId: number) => campaignId,
    (mediasState, campaignsState, campaignId) => {
        const campaign = campaignsState[campaignId];
        const campaignMediasIds = campaign.medias;

        if (!campaignMediasIds) return [];

        const campaignMedias = campaignMediasIds.map((mediaId: number) => {
            return mediasState[mediaId];
        });

        return campaignMedias;
    }
)((state: GlobalState, campaignId: number) => campaignId);

export const getMemoizedTabbedCampaignMedias = createCachedSelector(
    (state: GlobalState) => state.entities.medias,
    (state: GlobalState) => state.entities.campaigns.byId,
    (state: GlobalState) => state.ui.campaign.currentMediaTab,
    (state: GlobalState, campaignId: number) => campaignId,
    (mediasState, campaignsState, tabType, campaignId) => {
        const campaign = campaignsState[campaignId];
        const campaignMediasIds = campaign.medias;

        if (!campaignMediasIds) return [];

        const campaignMedias = campaignMediasIds.map((mediaId: number) => {
            return mediasState[mediaId];
        });

        const tabbedMedias = campaignMedias.filter((campaignMedia: Media) => {
            const { isPortrait, uploadedByPublisherId } = campaignMedia;
            const currentUserId = localStorage.getItem("userid");

            if (tabType === "Stories") {
                return !!isPortrait && uploadedByPublisherId !== currentUserId;
            } else if (tabType === "Square") {
                return !isPortrait && uploadedByPublisherId !== currentUserId;
            } else {
                return uploadedByPublisherId === currentUserId;
            }
        });

        return tabbedMedias;
    }
)((state: GlobalState, campaignId: number) => campaignId);

export const getTabbedCampaignMediaIds = createCachedSelector(
    (state: GlobalState) => state.entities.medias,
    (state: GlobalState) => state.entities.campaigns.byId,
    (state: GlobalState) => state.ui.campaign.currentMediaTab,
    (state: GlobalState, campaignId: number) => campaignId,
    (mediasState, campaignsState, tabType, campaignId) => {
        const currentCampaign = campaignsState[campaignId];
        const campaignMediasIds = currentCampaign.medias || [];

        const tabbedMediasIds = campaignMediasIds.filter((mediaId: number) => {
            const campaignMedia = mediasState[mediaId];
            const { isPortrait, uploadedByPublisherId } = campaignMedia;
            const currentUserId = localStorage.getItem("userid");

            if (tabType === "Stories") {
                return !!isPortrait && uploadedByPublisherId !== currentUserId;
            } else if (tabType === "Square") {
                return !isPortrait && uploadedByPublisherId !== currentUserId;
            } else {
                return uploadedByPublisherId === currentUserId;
            }
        });

        return tabbedMediasIds;
    }
)((state: GlobalState, campaignId: number) => campaignId);

export const getCampaignMedia = createCachedSelector(
    (state: GlobalState) => state.entities.medias,
    (state: GlobalState, mediaId: number) => mediaId,
    (mediasState, mediaId) => {
        const campaignMedia = mediasState[mediaId];
        return campaignMedia;
    }
)((state: GlobalState, mediaId: number) => mediaId);
