import React, { useState } from "react";
import { Media } from "../../media/types";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import CustomLinksCreateRow from "./CustomLinksCreateRow";
import CarouselModal from "../../modals/CarouselModal";
import CustomLinksCarouselCell from "./CustomLinksCarouselCell";
import { colors } from "../../constants";
import PaddingNoTop from "../../components/PaddingNoTop";

interface Props {
    Media: Media[];
    initialMedia: Media;
    onCancelTapped: () => void;
    onDoneTapped: (media: Media) => void;
}

const CustomLinksCreatePicker = ({
    Media,
    initialMedia,
    onCancelTapped,
    onDoneTapped
}: Props) => {
    const [currentMedia, setCurrentMedia] = useState(initialMedia);

    const leftNavButton = (
        <NavButton onClick={onCancelTapped}>Cancel</NavButton>
    );
    const rightNavButton = (
        <NavButton onClick={() => onDoneTapped(currentMedia)}>
            <div style={{ color: colors.primaryBlue }}>Done</div>
        </NavButton>
    );

    const [mediaList, setMediaList] = useState<Media[]>([]);

    function mediaClicked(index: number) {
        const newList = [
            ...Media.slice(index, Media.length),
            ...Media.slice(0, index)
        ];
        setMediaList(newList);
    }

    function renderCarousel() {
        if (mediaList.length === 0) {
            return null;
        }

        return (
            <CarouselModal>
                {mediaList.map((media, idx) => {
                    return (
                        <CustomLinksCarouselCell
                            key={`${media.campaignId}-${media.id}`}
                            media={media}
                            setCurrentMedia={setCurrentMedia}
                            closeModal={() => setMediaList([])}
                        />
                    );
                })}
            </CarouselModal>
        );
    }

    return (
        <>
            <SimpleNavbar left={leftNavButton} right={rightNavButton} />
            <PaddingNoTop>
                {Media.map((media, index) => {
                    return (
                        <CustomLinksCreateRow
                            key={media.id}
                            media={media}
                            currentMedia={currentMedia}
                            setCurrentMedia={setCurrentMedia}
                            mediaClicked={() => mediaClicked(index)}
                        />
                    );
                })}
            </PaddingNoTop>
            {renderCarousel()}
        </>
    );
};

export default CustomLinksCreatePicker;
