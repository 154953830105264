import React from "react";
import { colors } from "src/constants";
import styled from "styled-components";

interface StyleProps {
    backgroundColor: string;
    textColor: string;
}

const Square = styled.div<StyleProps>`
    min-width: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    background: ${props => props.backgroundColor};
    color: ${props => props.textColor};
`;

interface Props {
    number: number;
    backgroundColor?: string;
    textColor?: string;
}

const SquareNumberIcon = ({
    number,
    backgroundColor = colors.primaryBlue,
    textColor = colors.white
}: Props) => {
    return (
        <Square backgroundColor={backgroundColor} textColor={textColor}>
            {number}
        </Square>
    );
};

export default SquareNumberIcon;
