import styled from "styled-components";
import { fonts } from "src/constants";

export const Top = styled.div`
    padding: 15px;
    border-bottom: 1px solid #e0eef2;
    font-size: ${fonts.smallTitle}px;
    text-align: left;

    div:first-of-type {
        padding-bottom: 5px;
    }
`;

export const Middle = styled.div`
    padding: 15px;
    background-color: #f8fbfc;
`;

export const Bottom = styled.div`
    padding: 15px;
    border-top: 1px solid #e0eef2;
    display: flex;
`;

export const Form = styled.div`
    padding: 15px;
    background-color: white;
    border: 1px solid #e0eef2;
    border-radius: 8px;
    text-align: left;

    & + & {
        margin-top: 10px;
    }
`;

export const Title = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #909a9c;
    margin-bottom: 10px;
    text-transform: uppercase;

    input + & {
        margin-top: 10px;
    }
`;

export const Input = styled.input`
    outline: none;
    width: 100%;
    padding: 12px;
    border: 1px solid #e0eef2;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 12px;
    text-align: center;
`;

export const Text = styled.textarea`
    outline: none;
    resize: none;
    width: 100%;
    padding: 12px;
    height: 150px;
    box-sizing: border-box;
    border: 1px solid #e0eef2;
    border-radius: 8px;
    font-size: 12px;
`;

const Button = styled.button`
    border-style: none;
    outline: none;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    padding: 15px 0;
    text-align: center;
    border-radius: 8px;
    width: 100%;

    &:disabled {
        opacity: 0.6;
    }
`;

export const EmptyButton = styled(Button)`
    background-color: white;
    color: #0079f5;
    border: 1px solid #0079f5;

    &:first-of-type {
        margin-right: 5px;
    }
`;

export const BlueButton = styled(Button)`
    background-color: #0079f5;
    color: #f2f2f2;

    button + & {
        margin-left: 5px;
    }
`;
