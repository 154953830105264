import React from "react";
import { Connection } from "../types";
import EmptyCard from "./EmptyCard";
import { ReactComponent as LockIcon } from "../../components/icons/card-circle-lock.svg";

interface Props {
    text: string;
    button?: {
        text: string;
        callback: VoidFunction;
    };
    connection?: Connection;
    padding?: string;
}

const LockCard = ({ text, button, connection, padding }: Props) => {
    return (
        <EmptyCard
            icon={<LockIcon />}
            text={text}
            button={button}
            connection={connection}
            padding={padding}
        />
    );
};

export default LockCard;
