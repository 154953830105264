import React from "react";
import { useSelector } from "react-redux";
import { colors, fonts, shadows } from "src/constants";
import { getCurrentCreatorGroup } from "src/marketplace/creator-groups/selectors.creator-groups";
import { GlobalState } from "src/reducers";
import {
    clearButton,
    clearSpacing,
    preventUserInteraction
} from "src/utils/styles/snippets";
import styled from "styled-components";
import { ReactComponent as Check } from "src/components/icons/check.svg";

const Main = styled.button<{ selected: boolean }>`
    ${clearButton()};
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    gap: 12px;
    padding: 12px;
    background-color: ${colors.white};
    border-radius: 10px;
    transition: 0.1s ease-in;
    opacity: ${props => (props.selected ? 1 : 0.5)};
    box-shadow: ${shadows.one};

    @media (hover: hover) {
        :hover {
            transform: ${props => !props.selected && "translateY(-4px)"};
            opacity: 1;
            box-shadow: ${shadows.two};
        }
    }

    ${props => props.selected && preventUserInteraction()};
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const StepCount = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    color: ${colors.white};
    background-color: ${props => {
        return props.selected ? colors.primaryGreen : colors.primaryBlue;
    }};
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
`;

const CheckIcon = styled(Check)``;

const StepName = styled.h3`
    ${clearSpacing()};
    color: ${colors.black};
    font-size: ${fonts.default}px;
`;

const SelectedItemName = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 0px;
    color: ${props => {
        return props.selected ? colors.black : colors.primaryGray;
    }};
    background-color: ${props => {
        return props.selected
            ? colors.quaternaryGreen
            : colors.quaternaryLightGray;
    }};
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
`;

interface Props {
    isCurrentStep: boolean;
    setCurrentStep: VoidFunction;
    stepCount: number;
    stepName: string;
    selectedItemName: string;
    itemSelected: boolean;
}

const StepSelector = ({
    isCurrentStep,
    setCurrentStep,
    stepCount,
    stepName,
    selectedItemName,
    itemSelected
}: Props) => {
    // Local Functions -----------------------------------------------------------

    // JSX -----------------------------------------------------------
    return (
        <Main type="button" onClick={setCurrentStep} selected={isCurrentStep}>
            <Header>
                <StepCount selected={itemSelected}>
                    {itemSelected ? <CheckIcon /> : stepCount}
                </StepCount>
                <StepName>{stepName}</StepName>
            </Header>

            <SelectedItemName selected={itemSelected}>
                {selectedItemName}
            </SelectedItemName>
        </Main>
    );
};

export default StepSelector;
