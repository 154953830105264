import { AnalyticsDataStore } from "./reducer";
import { PublisherReportItem } from "./Analytics";
import { makeDateArray } from "../util";

const generateAnalyticsDataStore = (
    startDate: string,
    endDate: string,
    rawData: PublisherReportItem[]
): AnalyticsDataStore => {
    const newDataStore: AnalyticsDataStore = {};

    makeDateArray(startDate, endDate).forEach(date => {
        newDataStore[date] = [];
    });

    for (let i = 0; i < rawData.length; i++) {
        const currentRow = rawData[i];
        const currentDate = currentRow.date;

        if (newDataStore[currentDate]) {
            newDataStore[currentDate].push(currentRow);
        } else {
            newDataStore[currentDate] = [currentRow];
        }
    }

    return newDataStore;
};

export default generateAnalyticsDataStore;
