import React from "react";

import { Props } from "src/components/icons/types";

const Message = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || "10"}
            viewBox="0 0 10 10"
            fill={props.fill || "#000"}
            fillRule="evenodd"
        >
            <path d="M9.929 1.493A.36.36 0 0 0 9.64.92L.358.953a.36.36 0 0 0-.333.229.36.36 0 0 0 .091.394L3.276 4.49l.465 4.273a.36.36 0 0 0 .645.176l5.543-7.446zm-6.067 2.56L1.274 1.668l7.65-.027-4.568 6.137-.381-3.499a.36.36 0 0 0-.114-.225z" />
            <path d="M9.687 1.658a.36.36 0 0 0 .159-.482.36.36 0 0 0-.482-.159l-5.907 2.98a.36.36 0 0 0-.159.482.36.36 0 0 0 .482.159l5.907-2.98z" />
        </svg>
    );
};

export default Message;
