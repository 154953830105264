import { AnimatePresence } from "framer-motion";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadMedia from "src/media/upload";
import FullScreenModal from "src/modals/FullScreenModal";
import { GlobalState } from "src/reducers";
import { addCustomMediaModalOpen } from "src/ui/profile/actions";

const ProfileUploadModal = () => {
    const isAddCustomMediaModalOpen = useSelector((state: GlobalState) => {
        return state.ui.profile.addCustomMediaModalOpen;
    });

    const dispatch = useDispatch();

    return (
        <AnimatePresence>
            {isAddCustomMediaModalOpen && (
                <FullScreenModal
                    closeModal={() => dispatch(addCustomMediaModalOpen(false))}
                >
                    <UploadMedia noScroll />
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default ProfileUploadModal;
