import React from "react";
import SmallModal from "../../../modals/SmallModal";
import styled from "styled-components";
import { OutlineButton, RedButton } from "src/profile/components/Buttons";
import { WarningModal } from "./ScriptModal";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;
const Title = styled.div`
    font-size: 1.2rem;
    display: inline-block;
`;
const Icon = styled.img`
    width: 28px;
    height: 28px;
    display: inline-block;
`;
const Text = styled.p`
    font-size: 1rem;
    margin: 0px;
    margin-bottom: 16px;
    padding: 0px;
`;
const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const StyledOutlineButton = styled(OutlineButton)`
    padding: 8px 12px;
    margin-right: 8px;
`;
const StyledDeleteButton = styled(RedButton)`
    padding: 8px 12px;
    border-radius: 6px;
    font-weight: bold;
`;

interface Props {
    warningModal: WarningModal;
    handleCancel: () => void;
}

const Warning = ({ warningModal, handleCancel }: Props) => {
    return (
        <SmallModal zIndex={100}>
            <Wrapper>
                <div>
                    <Icon src={"/warning.svg"} />
                    <Title>{warningModal.title}</Title>
                </div>
                <Text>{warningModal.message}</Text>
                <BtnWrap>
                    <StyledOutlineButton onClick={handleCancel}>
                        {warningModal.btnText.cancel}
                    </StyledOutlineButton>
                    <StyledDeleteButton onClick={warningModal.handleExit}>
                        {warningModal.btnText.exit}
                    </StyledDeleteButton>
                </BtnWrap>
            </Wrapper>
        </SmallModal>
    );
};

export default Warning;
