import styled from "styled-components";
import { colors } from "../../constants";

export default styled.div`
    padding: 12px;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            border: ${colors.darkTeal} solid 1px;
        }
    }
`;
