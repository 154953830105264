import React from "react";
import { colors, fonts, fontWeight } from "src/constants";
import { DropdownCopy } from "src/marketplace/utils/types";
import {
    clearButton,
    clearSpacing,
    primaryBlueTextHoverState
} from "src/utils/styles/snippets";
import styled from "styled-components";
import { dropdownCopies } from "../utils/instructions-sets";
import CreateDropdown from "./CreateDropdown";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    setInstructionsPageStep,
    setInstructionsPageType
} from "src/ui/instructions-sets/actions";
import { GlobalState } from "src/reducers";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 22px;
`;

const HeaderText = styled.h2`
    ${clearSpacing()};
    color: ${colors.black};
    font-size: ${fonts.semiTitle}px;
    font-weight: ${fontWeight.medium};
`;

const BackButton = styled.button`
    ${clearButton()};
    ${primaryBlueTextHoverState()};
    font-size: ${fonts.smallTitle}px;
    text-align: left;
`;

const PagePartialScratchStep = () => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const { instructionsPageType } = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets;
    });

    // JSX -----------------------------------------------------------
    return (
        <Main>
            <Header>
                <HeaderText>Instruction Elements</HeaderText>

                {instructionsPageType === "Create" && (
                    <BackButton
                        onClick={() => {
                            dispatch(setInstructionsPageType("Main"));
                            dispatch(setInstructionsPageStep("Selection"));
                        }}
                    >
                        Back To Instruction Sets
                    </BackButton>
                )}
            </Header>

            {dropdownCopies.map((copy: DropdownCopy, index: number) => {
                return (
                    <CreateDropdown
                        key={`instructions-sets-create-page-create-dropdown-${copy.title}-${copy.subtitle}-${index}`}
                        title={copy.title}
                        subtitle={copy.subtitle}
                    />
                );
            })}
        </Main>
    );
};

export default PagePartialScratchStep;
