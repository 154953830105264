import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { Connection, PublicProfileData } from "../../types";
import { fetchConnectionsPublic } from "../../actions";
import { push } from "connected-react-router";
import { BigOutlineButton } from "../../components/Buttons";
import Header from "../../../components/Header";
import PaddingNoTop from "../../../components/PaddingNoTop";
import SectionTitle from "../../components/SectionTitle";
import RecommendationRow from "../components/RecommendationRow";
import CardLoader from "../../components/CardLoader";
import ConnectionCell from "../components/ConnectionCell";
import PaddingAround from "../../../components/PaddingAround";
import XCard from "../../components/XCard";

interface Props {
    publicData: PublicProfileData;
    recommendations: Connection[];
    fetchConnectionsPublic: (connection: Connection) => void;
    push: (path: string) => void;
}

const PublicConnectionsCard = ({
    publicData,
    recommendations,
    fetchConnectionsPublic,
    push
}: Props) => {
    if (publicData.allConnections === null) {
        return (
            <PaddingAround>
                <XCard
                    text="Failed to load connections, click the button below to try again."
                    button={{
                        text: "Try again",
                        callback: () => {
                            fetchConnectionsPublic(publicData.connection);
                        }
                    }}
                />
            </PaddingAround>
        );
    } else if (!publicData.allConnections || !publicData.mutualConnections) {
        return <CardLoader />;
    } else {
        const connection = publicData.connection;
        const userid = connection.other.id.slice(2);
        const allConnection = publicData.allConnections;
        const mutualConnections = publicData.mutualConnections;

        return (
            <>
                <Header
                    title={"Connections"}
                    subtitle={`${allConnection.length} Influencers; ${mutualConnections.length} Mutual`}
                    link={{
                        text: "View More",
                        onClick: () => {
                            push(`/public/${userid}/connections`);
                        }
                    }}
                />
                {mutualConnections.length > 0 && (
                    <>
                        <PaddingNoTop>
                            <SectionTitle>Mutual Connections</SectionTitle>
                            {mutualConnections
                                .slice(0, 2)
                                .map(mutualConnection => {
                                    return (
                                        <ConnectionCell
                                            key={mutualConnection.other.id}
                                            connection={mutualConnection}
                                        />
                                    );
                                })}
                        </PaddingNoTop>
                        <PaddingNoTop>
                            <BigOutlineButton
                                onClick={() => {
                                    push(
                                        `/public/${userid}/connections/mutual`
                                    );
                                }}
                            >
                                View All
                            </BigOutlineButton>
                        </PaddingNoTop>
                    </>
                )}
                <RecommendationRow recommendations={recommendations} />
            </>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    recommendations: state.profile.recommendations
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchConnectionsPublic: (connection: Connection) =>
        dispatch(fetchConnectionsPublic(connection)),
    push: (route: string) => dispatch(push(route))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicConnectionsCard);
