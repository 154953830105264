import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import CampaignHitRow from "./CampaignHitRow";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchMoreSearchHits } from "../actions";
import { GlobalState } from "src/reducers";
import {
    isCampaignHit,
    isPublisherHit,
    isSocialAccountHit,
    selectElasticSearchUI,
    selectHits
} from "../selector";
import UserHitRow from "./UserHitRow";
import AccountHitRow from "./AccountHitRow";
import { CampaignHit, PublisherHit, SocialAccountHit } from "../types";

interface Props {
    index: string;
    hasMore: boolean;
}

const InfiniteHits = ({ index, hasMore }: Props) => {
    const [ref, inView] = useInView();
    const dispatch = useDispatch();

    const { page, query, loading } = useSelector(
        (state: GlobalState) => selectElasticSearchUI(state, index),
        shallowEqual
    );

    const hits = useSelector((state: GlobalState) => selectHits(state, index));

    useEffect(() => {
        if (inView && hasMore && !loading) {
            dispatch(fetchMoreSearchHits(index, query, page + 1));
        }
    }, [inView]);

    return (
        <>
            {hits.map(hit => {
                if (index === "campaigns" && isCampaignHit(hit))
                    return <CampaignHitRow key={hit.id} hit={hit} />;
                else if (index === "publishers" && isPublisherHit(hit))
                    return <UserHitRow key={hit.id} hit={hit} />;
                else if (index === "social-accounts" && isSocialAccountHit(hit))
                    return <AccountHitRow key={hit.id} hit={hit} />;
            })}
            <div ref={ref} />
        </>
    );
};

export default InfiniteHits;
