import React from "react";
import { useSelector } from "react-redux";
import withModalPortal from "src/components/withModalPortal";
import { Creator } from "src/marketplace/creator-groups/types.creator-groups";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import FollowerDemographicsProgressBar from "../components/FollowerDemographicsProgressBar";
import {
    SocialAccountProfile,
    SocialAccountProfileAudienceData
} from "../utils/social-accounts.types";
import SocialAccountProfileModal from "./SocialAccountProfileModal";

const ProgressBars = styled.article`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

interface Props {
    currentSocialAccountProfile: SocialAccountProfile;
}

const SocialAccountFollowersDemographicsModal = ({
    currentSocialAccountProfile
}: Props) => {
    // Library Methods -----------------------------------------------

    // Redux State ---------------------------------------------------
    const { followersDemographicsModalType } = useSelector(
        (state: GlobalState) => {
            return state.ui.socialAccounts;
        }
    );

    // Local State ---------------------------------------------------

    // Local Constants -----------------------------------------------

    // Local Functions -----------------------------------------------
    function pickProperDemographicsData() {
        if (followersDemographicsModalType === "Country") {
            return currentSocialAccountProfile.audience_countries;
        } else if (followersDemographicsModalType === "Gender") {
            return currentSocialAccountProfile.audience_genders;
        } else if (followersDemographicsModalType === "Language") {
            return currentSocialAccountProfile.audience_languages;
        } else {
            return currentSocialAccountProfile.audience_ages;
        }
    }

    // Custom Hooks --------------------------------------------------

    // Effects -------------------------------------------------------

    // JSX -----------------------------------------------------------
    return (
        <SocialAccountProfileModal
            modalType="followers"
            title="Followers Demographics"
            subtitle={followersDemographicsModalType}
        >
            <ProgressBars>
                {pickProperDemographicsData()
                    .sort(
                        (
                            a: SocialAccountProfileAudienceData,
                            b: SocialAccountProfileAudienceData
                        ) => {
                            return b.weight - a.weight;
                        }
                    )
                    .map((data: any, index: number) => {
                        return (
                            <FollowerDemographicsProgressBar
                                key={`social account followers demographics modal-${data.name}-${data.code}-${data.weight}-${index}`}
                                name={
                                    followersDemographicsModalType ===
                                        "Gender" ||
                                    followersDemographicsModalType === "Age"
                                        ? data.code
                                        : data.name || "Other"
                                }
                                weight={data.weight}
                            />
                        );
                    })}
            </ProgressBars>
        </SocialAccountProfileModal>
    );
};

export default withModalPortal(SocialAccountFollowersDemographicsModal);
