import React from "react";
import CustomOfferTypeModal from "./CustomOfferTypeModal";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import ItemSelectionModal from "./ItemSelectionModal";
import styled from "styled-components";
import { FlexVCSpaceBetween } from "../../../components/CommonStyles";
import { OutlineTextButton } from "../../components/Buttons";
import { fonts } from "src/constants";

const CurrentType = styled.div`
    ${FlexVCSpaceBetween}
    width: 100%;
    padding: 0 12px;
    height: 46px;
    border: 1px solid #e0eef2;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
    text-align: left;
    margin-bottom: 12px;
`;

const EditButton = styled(OutlineTextButton)`
    font-size: 12px;
`;

export interface PricingParams {
    paymentId: null | number;
    paymentType: null | string;
    customType: string;
    amount: null | number;
}

export const initialPricing: PricingParams = {
    paymentId: null,
    paymentType: null,
    customType: "",
    amount: null
};

export const pricingFinished = (pricing: PricingParams) => {
    if (!pricing.paymentId && !pricing.customType) return false;
    return true;
};

interface Props {
    pricing: PricingParams;
    setPricing: (pricing: PricingParams) => void;
    closeModal: () => void;
}

const OfferPricingModal = ({ pricing, setPricing, closeModal }: Props) => {
    const [customPricingModal, toggleCustomPricingModal] = useState(false);
    const [amountModal, toggleAmountModal] = useState(false);

    return (
        <>
            <ItemSelectionModal
                title="PRICING"
                heading="What is your pricing?"
                description="Select an option below:"
                items={[
                    { value: 2, type: "Flat Fee" },
                    { value: 1, type: "$ per 1k Views" }
                ]}
                onSelect={(value: number, type: string) => {
                    setPricing({
                        ...pricing,
                        customType: "",
                        paymentId: value,
                        paymentType: type
                    });
                    toggleAmountModal(true);
                }}
                closeModal={closeModal}
                customOptionCallback={() => toggleCustomPricingModal(true)}
            />
            <AnimatePresence>
                {customPricingModal && (
                    <CustomOfferTypeModal
                        key="customPricingModal"
                        closeModal={() => toggleCustomPricingModal(false)}
                        onSubmit={(value: string) => {
                            setPricing({
                                ...pricing,
                                paymentId: null,
                                paymentType: null,
                                customType: value
                            });
                            toggleCustomPricingModal(false);
                            toggleAmountModal(true);
                        }}
                        description="What is your pricing?"
                        placeholder="Pricing"
                        modalTitle="PRICING"
                    />
                )}
                {amountModal && (
                    <CustomOfferTypeModal
                        key="amountModal"
                        closeModal={() => toggleAmountModal(false)}
                        onSubmit={(value: string) => {
                            setPricing({
                                ...pricing,
                                amount: parseFloat(value || "0")
                            });
                            closeModal();
                        }}
                        description="How much will you charge for this service?"
                        placeholder="$ Amount"
                        modalTitle="Select Payment Price"
                        currentType={
                            <CurrentType>
                                <div>
                                    {pricing.paymentType || pricing.customType}
                                </div>
                                <EditButton
                                    onClick={() => toggleAmountModal(false)}
                                >
                                    Edit
                                </EditButton>
                            </CurrentType>
                        }
                        numeric
                    />
                )}
            </AnimatePresence>
        </>
    );
};

export default OfferPricingModal;
