import React from "react";
import { connect } from "react-redux";
import { fetchUser } from "../actions";
import SimpleNavbar from "../../components/SimpleNavbar";
import { GlobalState } from "../../reducers";
import FullscreenLoader from "../../components/FullscreenLoader";
import ReloadProfile from "../components/ReloadProfile";

interface Props {
    loading: boolean;
    fetchUser: VoidFunction;
}

const TryAgain = ({ loading, fetchUser }: Props) => {
    return (
        <>
            <SimpleNavbar />
            {loading ? (
                <FullscreenLoader />
            ) : (
                <ReloadProfile reloadCallback={fetchUser} />
            )}
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    loading: state.profile.loading
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUser: () => dispatch(fetchUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(TryAgain);
