import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { breakpoints, colors } from "src/constants";

const Button = styled(Link)`
    display: block;
    margin: 5px 15px 20px;
    padding: 8px 16px;
    color: ${colors.white};
    background-color: ${colors.primaryBlue};
    border-radius: 6px;
    text-decoration: none;
    transition: 0.1s ease-in;

    :hover {
        color: ${colors.white};
        text-decoration: none;
    }

    @media (max-width: ${breakpoints.tablet}px) {
        display: ${props => props.theme.isMobile && "none"};
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

const CreateButton = () => {
    return <Button to="/chat/create">Create</Button>;
};

export default CreateButton;
