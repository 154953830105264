import React from "react";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import withModalPortal from "src/components/withModalPortal";
import CarouselModal from "src/modals/CarouselModalNew";
import styled from "styled-components";
import { SubmissionReport } from "../types";
import SubmissionMediaModal from "./SubmissionMediaModal";

const StyledCarouselModal = styled(CarouselModal)`
    z-index: auto;
`;
interface Props {
    submissionList: SubmissionReport[];
    openBuy: OpenBuy;
    closeModal: VoidFunction;
}

const SubmissionMediaCarousel = ({
    submissionList,
    openBuy,
    closeModal
}: Props) => {
    return (
        <StyledCarouselModal
            open={true}
            closeModal={closeModal}
            noMaxWidth={true}
            maxWidth="910px"
            swipeable
            arrows
            isAdmin
            infinite
            headerText="View Submission"
        >
            {submissionList.map(submission => (
                <SubmissionMediaModal
                    key={submission.submissionId}
                    submission={submission}
                    openBuy={openBuy}
                />
            ))}
        </StyledCarouselModal>
    );
};

export default withModalPortal(SubmissionMediaCarousel);
