import React from "react";
import DirectBuyDetailsMediaPreview from "../components/DirectBuyDetailsMediaPreview";
import { setSimpleMedia } from "../../../../modals/actions";
import { directBuyDetailsModal } from "../../../../ui/direct-buys/actions";
import { DirectBuyDetailsModalState } from "../../../../ui/direct-buys/reducer";
import { Media } from "../../../../media/types";
import { useDispatch } from "react-redux";

interface Props {
    customMedia: Media;
}

const DirectBuyDetailsCustomMediaPreview = ({ customMedia }: Props) => {
    const dispatch = useDispatch();
    const getImageByStatus = () => {
        if (customMedia.mediaStatus === "approved") {
            return "/outline-status-approved.svg";
        } else if (customMedia.mediaStatus === "rejected") {
            return "/outline-status-rejected.svg";
        } else {
            return "/outline-status-pending.svg";
        }
    };
    return (
        <DirectBuyDetailsMediaPreview
            imageUrl={customMedia.coverPhotoUrl || ""}
            centerImage={getImageByStatus()}
            onClick={() => {
                const url = customMedia.url;
                const coverPhotoUrl = customMedia.coverPhotoUrl;
                const type = customMedia.type;
                if (url && type) {
                    dispatch(
                        setSimpleMedia({
                            url,
                            ...(coverPhotoUrl && { coverPhotoUrl }),
                            type
                        })
                    );
                    dispatch(
                        directBuyDetailsModal(
                            DirectBuyDetailsModalState.simpleMedia
                        )
                    );
                }
            }}
        />
    );
};

export default DirectBuyDetailsCustomMediaPreview;
