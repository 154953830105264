import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Main = styled.div`
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
`;

const Caret = styled(motion.img)``;

interface Props {
    title: string;
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
}

const DirectBuyDetailsSectionHeader = ({
    title,
    expanded,
    setExpanded
}: Props) => {
    return (
        <Main
            onClick={() => {
                setExpanded(!expanded);
            }}
        >
            <Title>{title}</Title>
            <Caret
                src={"/caret.svg"}
                initial={{ rotate: 180 }}
                animate={{ rotate: expanded ? 180 : 0 }}
            />
        </Main>
    );
};

export default DirectBuyDetailsSectionHeader;
