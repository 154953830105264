import React from "react";
import {
    DirectBuyStepCollection,
    DirectBuyStepAction
} from "../../../modules/direct-buy-step/types";
import { DirectBuyStepsEntitiesState } from "../../../modules/direct-buy-step/reducer";
import styled from "styled-components";
import { getActionForDirectBuyStep } from "../../../modules/direct-buy-step/util";
import { getStatusImageDescriptionForDirectBuyStepAction } from "./directBuyDetailsStepCollectionStatusUtil";
import { colors } from "../../../../constants";
import { StepById } from "src/buys/modules/open-buys/reducer";

const Main = styled.div`
    display: flex;
    align-items: center;
    padding-top: 14px;
`;

const StatusImage = styled.img`
    width: 14px;
    height: 14px;
    object-fit: contain;
`;

const Description = styled.div`
    padding-left: 10px;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: ${colors.primaryGray};
`;

interface Props {
    collection: DirectBuyStepCollection;
    steps: DirectBuyStepsEntitiesState | StepById;
    action: DirectBuyStepAction;
}

const DirectBuyDetailsStepCollectionStatus = ({
    collection,
    steps,
    action
}: Props) => {
    const [
        statusImage,
        description
    ] = getStatusImageDescriptionForDirectBuyStepAction(
        action,
        collection,
        steps
    );
    return (
        <Main>
            <StatusImage src={statusImage} />
            <Description>{description}</Description>
        </Main>
    );
};

export default DirectBuyDetailsStepCollectionStatus;
