import React from "react";
import { DirectBuyStepCollection } from "../../../modules/direct-buy-step/types";
import { DirectBuyStepsEntitiesState } from "../../../modules/direct-buy-step/reducer";
import { ApprovalStatus } from "../../../../types/ApprovalStatus";
import styled from "styled-components";
import { colors } from "../../../../constants";
import { StepById } from "src/buys/modules/open-buys/reducer";

const Main = styled.div`
    display: flex;
    align-items: center;
    padding-top: 14px;
`;

const StatusImage = styled.img`
    width: 14px;
    height: 14px;
    object-fit: contain;
`;

const Description = styled.div`
    padding-left: 12px;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: ${colors.primaryGray};
`;

interface Props {
    collection: DirectBuyStepCollection;
    steps: DirectBuyStepsEntitiesState | StepById;
}

const DirectBuyDetailsRejectionReason = ({ collection, steps }: Props) => {
    const rejectionReasons: string[] = [];
    for (const stepId of collection.combined) {
        const step = steps[stepId];
        if (
            step.rejectionReason &&
            step.status === ApprovalStatus.rejected &&
            !step.requiresCustomMedia &&
            !step.requiresScreenshot &&
            !step.requiresLinkSubmission &&
            !step.requiresTextSubmission
        ) {
            rejectionReasons.push(step.rejectionReason);
        }
    }
    if (rejectionReasons.length === 0) {
        return null;
    }
    return (
        <Main>
            <StatusImage src="/progress_red/progress_red@2x.png" />
            <Description>{rejectionReasons.join(", ")}</Description>
        </Main>
    );
};

export default DirectBuyDetailsRejectionReason;
