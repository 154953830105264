import { PublicProfile } from "src/profile/types";
import { Action, NormalizedActionGeneric, NormalizedResponse } from "src/types";

export const FETCH_PUBLIC_PROFILE = {
    REQUEST: "FETCH_PUBLIC_PROFILE_REQUEST",
    SUCCESS: "FETCH_PUBLIC_PROFILE_SUCCESS"
};

export function fetchPublicProfile(userId: string): Action {
    return {
        type: FETCH_PUBLIC_PROFILE.REQUEST,
        payload: { userId }
    };
}

export function fetchPublicProfileSuccess(
    response: NormalizedResponse<PublicProfile, string[]>
): NormalizedActionGeneric<PublicProfile, string[]> {
    return {
        type: FETCH_PUBLIC_PROFILE.SUCCESS,
        response
    };
}
