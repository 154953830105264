import {
    CURRENT_HEADER_FILTER_DROPDOWN,
    RESET_AUDIENCE_AGE_FILTERS,
    RESET_AUDIENCE_COUNTRY_REGION_FILTERS,
    RESET_AUDIENCE_DEVICE_FILTER,
    RESET_AUDIENCE_GENDER_FILTERS,
    RESET_AVERAGE_VIEWS_FILTERS,
    RESET_INTERESTS_FILTERS,
    RESET_CREATORS_FILTERS,
    RESET_CREATORS_QUERY_PARAMETERS,
    RESET_CREATOR_COUNTRY_REGION_FILTERS,
    RESET_ENGAGEMENT_RATE_FILTERS,
    RESET_FOLLOWERS_FILTERS,
    RESET_PLATFORM_FILTERS,
    SET_AUDIENCE_AGE_FILTERS,
    SET_AUDIENCE_COUNTRY_REGION_FILTERS,
    SET_AUDIENCE_DEVICE_FILTER,
    SET_AUDIENCE_GENDER_FILTER,
    SET_AVERAGE_VIEWS_FILTER,
    SET_INTERESTS_FILTERS,
    SET_CREATORS_DATE_RANGE,
    SET_CREATORS_HITS_PER_PAGE,
    SET_CREATORS_MAX_PAGES,
    SET_CREATORS_PAGE,
    SET_CREATORS_QUERY,
    SET_CREATORS_QUERY_FILTERS,
    SET_CREATORS_SORT,
    SET_CREATORS_SORT_ORDER,
    SET_CREATOR_COUNTRY_REGION_FILTERS,
    SET_CREATOR_CURRENT_OTHER_AND_SOCIAL_ACCOUNT_ID,
    SET_CREATOR_SEARCH_LOADING,
    SET_CURRENT_CREATOR_INDEX,
    SET_CREATOR_TOTAL_HITS,
    SET_ENGAGEMENT_RATE_FILTERS,
    SET_FOLLOWERS_FILTER,
    SET_PLATFORM_FILTERS,
    SET_THEMES_FILTERS,
    RESET_THEMES_FILTERS
} from "./actions";
import { Action } from "src/types";
import { Creator } from "src/marketplace/creator-groups/types.creator-groups";
import { FETCH_CREATOR_OR_CREATORS_BASED_ON_SOCIAL_ACCOUNT_ID } from "src/marketplace/creator-groups/actions.creator-search";
import {
    CreatorSortOrder,
    FilterType
} from "src/marketplace/creator-groups/utils/types";
import { CreatorsSort } from "src/marketplace/creator-groups/pages/CreatorGroupsCreateOrEditPage";
import {
    getTimezoneBasedOffUTC,
    getTodaysDateInYearMonthDay
} from "src/utils/functions/helpers";

export type Filters = { [key: string]: string };

export const initialPlatformFilters: Filters = {
    tiktok: "tiktok"
};

export interface CreatorsDateRange {
    start: string;
    end: string;
    timeZone: string;
}

export interface CreatorsUIState {
    creatorSearchLoading: boolean;
    currentGroupCreators: Creator[];
    currentOtherId: number | null;
    currentSocialAccountId: number | null;

    hitsPerPage: number;
    page: number;
    query: string;
    dateRange: CreatorsDateRange;
    sortOrder: CreatorSortOrder;
    sort: CreatorsSort[];
    queryFilters: any[];
    maxPages: number;
    totalHits: number;

    currentHeaderFilterDropdown: "" | FilterType;
    platformFilters: Filters;
    interestsFilters: Filters;
    themesFilters: Filters;
    creatorCountryRegionFilters: Filters;
    audienceCountryRegionFilters: Filters;
    followersFilter: string;
    averageViewsFilter: string;
    engagementRateFilter: string;
    audienceGenderFilter: string;
    audienceAgeFilters: Filters;
    audienceDeviceFilter: "ios" | "android" | string;
}

const initialCreatorsUIState: CreatorsUIState = {
    creatorSearchLoading: true,
    currentGroupCreators: [],
    currentOtherId: null,
    currentSocialAccountId: null,

    hitsPerPage: 20,
    page: 0,
    query: "",
    dateRange: {
        start: "2000-01-01",
        end: getTodaysDateInYearMonthDay(),
        timeZone: getTimezoneBasedOffUTC()
    },
    sortOrder: "desc",
    // sort: [{ "username.raw": { order: "desc" } }],
    sort: [{ followers: { order: "desc" } }],
    queryFilters: [
        // { terms: { platform: ["tiktok", "instagram", "snapchat"] } }
        {
            terms: {
                platform: [
                    "instagram",
                    "snapchat",
                    "tiktok",
                    "twitter",
                    "facebook",
                    "youtube",
                    "twitch",
                    "tumblr"
                ]
            }
        }
    ],
    maxPages: 0,
    totalHits: 0,

    currentHeaderFilterDropdown: "",
    platformFilters: initialPlatformFilters,
    interestsFilters: {},
    themesFilters: {},
    creatorCountryRegionFilters: {},
    audienceCountryRegionFilters: {},
    followersFilter: "All",
    averageViewsFilter: "All",
    engagementRateFilter: "All",
    audienceGenderFilter: "All",
    audienceAgeFilters: {},
    audienceDeviceFilter: "All"
};

function creatorsUIReducer(
    state = initialCreatorsUIState,
    action: Action
): CreatorsUIState {
    switch (action.type) {
        case SET_CREATOR_SEARCH_LOADING:
            return { ...state, creatorSearchLoading: action.payload.loading };

        case FETCH_CREATOR_OR_CREATORS_BASED_ON_SOCIAL_ACCOUNT_ID.SUCCESS:
            return {
                ...state,
                currentGroupCreators: action.payload.creatorOrCreators
            };

        case SET_CREATOR_CURRENT_OTHER_AND_SOCIAL_ACCOUNT_ID:
            return {
                ...state,
                currentOtherId: action.payload.otherId,
                currentSocialAccountId: action.payload.socialAccountId
            };

        // Creators Search Query Parameters -----------------------------------------------

        case SET_CREATORS_HITS_PER_PAGE:
            return { ...state, hitsPerPage: action.payload.hits };

        case SET_CREATORS_PAGE:
            return { ...state, page: action.payload.page };

        case SET_CREATORS_QUERY:
            return { ...state, query: action.payload.query };

        case SET_CREATORS_DATE_RANGE:
            return { ...state, dateRange: action.payload.dateRange };

        case SET_CREATORS_SORT_ORDER:
            return { ...state, sortOrder: action.payload.sortOrder };

        case SET_CREATORS_SORT:
            return { ...state, sort: action.payload.sort };

        case SET_CREATORS_QUERY_FILTERS:
            return { ...state, queryFilters: action.payload.queryFilters };
        case SET_CREATOR_TOTAL_HITS:
            return {
                ...state,
                totalHits: action.payload.nbHits
            };

        case SET_CREATORS_MAX_PAGES:
            return { ...state, maxPages: action.payload.maxPages };

        case RESET_CREATORS_QUERY_PARAMETERS:
            return {
                ...state,
                hitsPerPage: 20,
                page: 0,
                dateRange: {
                    start: "2000-01-01",
                    end: getTodaysDateInYearMonthDay(),
                    timeZone: getTimezoneBasedOffUTC()
                }
            };

        // Filters -----------------------------------------------

        case CURRENT_HEADER_FILTER_DROPDOWN:
            return {
                ...state,
                currentHeaderFilterDropdown: action.payload.filterType
            };

        case SET_PLATFORM_FILTERS:
            return { ...state, platformFilters: action.payload.filters };

        case SET_INTERESTS_FILTERS:
            return { ...state, interestsFilters: action.payload.filters };

        case SET_THEMES_FILTERS:
            return { ...state, themesFilters: action.payload.filters };

        case SET_CREATOR_COUNTRY_REGION_FILTERS:
            return {
                ...state,
                creatorCountryRegionFilters: action.payload.filters
            };

        case SET_AUDIENCE_COUNTRY_REGION_FILTERS:
            return {
                ...state,
                audienceCountryRegionFilters: action.payload.filters
            };

        case SET_FOLLOWERS_FILTER:
            return { ...state, followersFilter: action.payload.followers };

        case SET_AVERAGE_VIEWS_FILTER:
            return { ...state, averageViewsFilter: action.payload.views };

        case SET_ENGAGEMENT_RATE_FILTERS:
            return {
                ...state,
                engagementRateFilter: action.payload.engagementRate
            };

        case SET_AUDIENCE_GENDER_FILTER:
            return { ...state, audienceGenderFilter: action.payload.gender };

        case SET_AUDIENCE_AGE_FILTERS:
            return { ...state, audienceAgeFilters: action.payload.filters };

        case SET_AUDIENCE_DEVICE_FILTER:
            return { ...state, audienceDeviceFilter: action.payload.device };

        case RESET_PLATFORM_FILTERS:
            return { ...state, platformFilters: initialPlatformFilters };

        case RESET_INTERESTS_FILTERS:
            return { ...state, interestsFilters: {} };

        case RESET_THEMES_FILTERS:
            return { ...state, themesFilters: {} };

        case RESET_CREATOR_COUNTRY_REGION_FILTERS:
            return { ...state, creatorCountryRegionFilters: {} };

        case RESET_AUDIENCE_COUNTRY_REGION_FILTERS:
            return { ...state, audienceCountryRegionFilters: {} };

        case RESET_FOLLOWERS_FILTERS:
            return { ...state, followersFilter: "All" };

        case RESET_AVERAGE_VIEWS_FILTERS:
            return { ...state, averageViewsFilter: "All" };

        case RESET_ENGAGEMENT_RATE_FILTERS:
            return { ...state, engagementRateFilter: "All" };

        case RESET_AUDIENCE_GENDER_FILTERS:
            return { ...state, audienceGenderFilter: "All" };

        case RESET_AUDIENCE_AGE_FILTERS:
            return { ...state, audienceAgeFilters: {} };

        case RESET_AUDIENCE_DEVICE_FILTER:
            return { ...state, audienceDeviceFilter: "All" };

        case RESET_CREATORS_FILTERS:
            return {
                ...state,
                currentGroupCreators: [],
                platformFilters: initialPlatformFilters,
                interestsFilters: {},
                themesFilters: {},
                creatorCountryRegionFilters: {},
                audienceCountryRegionFilters: {},
                followersFilter: "All",
                averageViewsFilter: "All",
                engagementRateFilter: "All",
                audienceGenderFilter: "All",
                audienceAgeFilters: {},
                audienceDeviceFilter: "All"
            };

        default:
            return state;
    }
}

export default creatorsUIReducer;
