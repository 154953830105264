import { CampaignMedia } from "src/campaigns/types";
import { Action } from "src/types";
import { PROMOTE_FLOW } from "./actions";

export interface PromoteFlowUIState {
    promoting: boolean;
    selected: CampaignMedia | null;
}

const referralModalReducerInitialState: PromoteFlowUIState = {
    promoting: false,
    selected: null
};

function promoteFlowUIReducer(
    state = referralModalReducerInitialState,
    action: Action
) {
    switch (action.type) {
        case PROMOTE_FLOW.SET_PROMOTING:
            return { ...state, promoting: action.payload };
        case PROMOTE_FLOW.SET_SELECTED:
            return { ...state, selected: action.payload };
        default:
            return state;
    }
}

export default promoteFlowUIReducer;
