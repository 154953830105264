import React from "react";
import XCard from "src/profile/components/XCard";

interface Props {
    message?: string;
}

const ScriptError = ({
    message = `Error loading script. Refresh the page to try again`
}: Props) => {
    return <XCard text={message} />;
};

export default ScriptError;
