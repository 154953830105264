import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clearCachedFilters,
    requestDirectBuysByStatus,
    requestOpenBuysByStatus,
    setCachedFilters
} from "../actions";
import styled from "styled-components";
import SearchBar from "../../components/SearchBar";
import {
    DirectBuyStatus,
    directBuyStatusAll
} from "../modules/direct-buy/types";
import DirectBuysSearchModals from "./DirectBuysSearchModals";
import {
    selectCachedFilters,
    selectDirectBuysAllIds,
    selectDirectBuysById,
    selectDirectBuysLoading,
    selectDirectBuyStatusFilters,
    selectDirectBuyStepEntities,
    selectDirectBuyStoredStatuses,
    selectOpenBuysAllIds,
    selectOpenBuysById,
    selectOpenBuyStepEntities,
    selectOpenBuySubmissions
} from "../selectors";
import DirectBuysSearchSortAndFilter from "./DirectBuysSearchSortAndFilter";
import DirectBuysSearchPreviewGrid from "./DirectBuysSearchPreviewGrid";
import {
    getDirectBuysBucket,
    getOpenBuysBucket
} from "../modules/direct-buy/util";
import { GlobalState } from "../../reducers";
import { checkPublisherFlag } from "../../profile/flags/selectors";
import { Redirect } from "react-router";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";
import { Link } from "react-router-dom";
import { colors, fonts } from "src/constants";
import { flexRowCenter } from "src/utils/styles/snippets";
import { checkShoutoutHistory } from "src/campaigns/shoutouts/actions";
import { Moment } from "moment";
import { clearModal } from "src/modals/actions";
import moment from "moment";
import OpenBuysSearchPreviewGrid from "./OpenBuySearchPreviewGrid";
import { setStatusFiltersState } from "src/ui/direct-buys/actions";
import { breakpoints } from "src/constants";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 18px 0;
    position: sticky;
    background: white;
    top: 57px;
    z-index: 90;
    @media (min-width: ${breakpoints.tablet}px) {
        top: 90px;
    }
`;

const FiltersAndLink = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 615px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const LegacyLinkButton = styled(Link)`
    ${flexRowCenter()};
    padding: 8px 12px;
    color: ${colors.black};
    background-color: ${colors.quaternaryLightGray};
    border-radius: 6px;
    font-size: ${fonts.smallTitle}px;
    font-weight: 500;
    text-decoration: none;
    white-space: no-wrap;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            color: ${colors.white};
            background-color: ${colors.primaryBlue};
            text-decoration: none;
        }
    }

    @media (max-width: 615px) {
        margin-bottom: 10px;
    }
`;

const DirectBuysSearch = ({ isOpenBuy }: { isOpenBuy?: boolean }) => {
    const dispatch = useDispatch();
    const directBuysLoading = useSelector(selectDirectBuysLoading);
    const directBuysById = useSelector(selectDirectBuysById);
    const directBuysAllIds = useSelector(selectDirectBuysAllIds);
    const directBuyStepsState = useSelector(selectDirectBuyStepEntities);
    const storedStatuses = useSelector(selectDirectBuyStoredStatuses);
    const statusFilters = useSelector(selectDirectBuyStatusFilters);
    const openBuysAllIds = useSelector(selectOpenBuysAllIds);
    const openBuysById = useSelector(selectOpenBuysById);
    const instructionSteps = useSelector(selectOpenBuyStepEntities);
    const submissions = useSelector(selectOpenBuySubmissions);
    const {
        cachedDate,
        cachedSortMode,
        cachedScrollY,
        cachedStatuses,
        cachedSearch
    } = useSelector(selectCachedFilters);

    const [directBuys, setDirectBuys] = useState<{ [id: string]: number[] }>(
        {}
    );

    const [searchString, setSearchString] = useState(
        cachedSearch ? cachedSearch : ""
    );
    const [dateRange, setDateRange] = useState(
        cachedDate.range
            ? cachedDate.range
            : [moment().subtract(1, "month"), moment()]
    );
    const formattedDateRange = `${dateRange[0].format(
        "X"
    )}-${dateRange[1].format("X")}`;

    const [sortMode, setSortMode] = useState(
        cachedSortMode ? cachedSortMode : "Newest"
    );
    const [date, setDate] = useState(
        cachedDate.type ? cachedDate.type : "1 month"
    );
    const [scrollY, setScrollY] = useState(cachedScrollY ? cachedScrollY : 0);

    const handleCache = () => {
        dispatch(
            setCachedFilters({
                cachedDate: {
                    type: date,
                    range: date === "All Time" ? null : dateRange
                },
                cachedScrollY: window.scrollY,
                cachedSortMode: sortMode,
                cachedSearch: searchString,
                cachedStatuses: statusFilters
            })
        );
    };

    useEffect(() => {
        if (isOpenBuy) {
            dispatch(requestOpenBuysByStatus());
            dispatch(
                setStatusFiltersState(
                    cachedStatuses ? cachedStatuses : directBuyStatusAll
                )
            );
        } else if (formattedDateRange && date !== "All Time") {
            dispatch(
                requestDirectBuysByStatus(
                    cachedStatuses ? cachedStatuses : directBuyStatusAll,
                    formattedDateRange
                )
            );
        }
    }, []);
    useEffect(() => {
        dispatch(checkShoutoutHistory());
    }, []);

    useEffect(() => {
        /*fetch all buys if not already done*/
        if (storedStatuses.length !== 4 && !isOpenBuy) {
            dispatch(
                requestDirectBuysByStatus([
                    DirectBuyStatus.available,
                    DirectBuyStatus.active,
                    DirectBuyStatus.completed,
                    DirectBuyStatus.expired
                ])
            );
        }
    }, [storedStatuses]);

    function setStatusFilters(newStatusFilters: DirectBuyStatus[]) {
        if (newStatusFilters.length === 0) return;
        dispatch(
            isOpenBuy
                ? setStatusFiltersState(newStatusFilters)
                : date === "All Time"
                ? requestDirectBuysByStatus(newStatusFilters)
                : requestDirectBuysByStatus(
                      newStatusFilters,
                      formattedDateRange
                  )
        );
    }

    function setDateFilter(daterange?: [Moment, Moment], type?: string) {
        setDate(type || "Custom");
        dispatch(clearModal());
        if (!daterange) {
            return;
        } else if (isOpenBuy) {
            setDateRange(daterange);
        } else {
            setDateRange(daterange);
            dispatch(
                requestDirectBuysByStatus(
                    statusFilters || directBuyStatusAll,
                    `${daterange[0].format("X")}-${daterange[1].format("X")}`
                )
            );
        }
    }

    const searchIds = useSelector(
        (state: GlobalState) => state.ui.directBuys.searchedIds
    );

    useEffect(() => {
        if (!directBuysLoading) {
            isOpenBuy
                ? setDirectBuys(
                      getOpenBuysBucket(
                          searchIds,
                          openBuysAllIds,
                          openBuysById,
                          instructionSteps,
                          statusFilters,
                          searchString,
                          submissions
                      )
                  )
                : setDirectBuys(
                      getDirectBuysBucket(
                          searchIds,
                          directBuysAllIds,
                          directBuysById,
                          directBuyStepsState,
                          statusFilters,
                          searchString
                      )
                  );

            dispatch(clearCachedFilters());
        }
    }, [
        directBuysLoading,
        directBuysById,
        directBuysAllIds,
        directBuyStepsState,
        searchString,
        sortMode,
        date,
        statusFilters
    ]);
    useEffect(() => {
        if (!directBuysLoading && results > 0 && scrollY) {
            window.scrollTo(0, scrollY);
            setScrollY(0);
        }
    }, [scrollY, directBuys, directBuysLoading]);

    const hasShoutoutHistory = useSelector((state: GlobalState) => {
        return state.ui.shoutouts.hasShoutoutHistory;
    });

    const hasAccess = useSelector((state: GlobalState) =>
        checkPublisherFlag(state, "directBuyAccess", "approved")
    );

    const dateFilterOpenBuys = () => {
        let filtered: { [id: string]: number[] } = {};
        Object.keys(directBuys).map(date => {
            if (
                date === "Current" ||
                (moment(date).diff(dateRange[0]) > 1 &&
                    moment(date).diff(dateRange[1]) < 1)
            )
                filtered[date] = directBuys[date];
        });
        return filtered;
    };

    const results = Object.values(directBuys).reduce((acc, buys) => {
        return acc + buys.length;
    }, 0);

    if (!hasAccess && !isOpenBuy) return <Redirect to={"/"} />;
    return (
        <>
            <Navbar back title={isOpenBuy ? "CHALLENGES" : "PERSONAL OFFERS"} />

            <MainContainer>
                <Main>
                    <SearchBar
                        searchString={searchString}
                        updateSearchString={setSearchString}
                    />

                    <FiltersAndLink>
                        <DirectBuysSearchSortAndFilter
                            sortMode={sortMode}
                            setSortMode={setSortMode}
                            date={date}
                            isOpenBuy={isOpenBuy}
                        />
                        {hasShoutoutHistory && (
                            <LegacyLinkButton to="/campaign/all-direct-buys">
                                Legacy
                            </LegacyLinkButton>
                        )}
                    </FiltersAndLink>
                </Main>
                {isOpenBuy ? (
                    <OpenBuysSearchPreviewGrid
                        dispatch={dispatch}
                        directBuysLoading={directBuysLoading}
                        directBuys={
                            date === "All Time"
                                ? directBuys
                                : dateFilterOpenBuys()
                        }
                        sortMode={sortMode}
                        buysLength={
                            searchIds ? searchIds.length : openBuysAllIds.length
                        }
                        handleCache={handleCache}
                    />
                ) : (
                    <DirectBuysSearchPreviewGrid
                        dispatch={dispatch}
                        directBuysLoading={directBuysLoading}
                        directBuys={directBuys}
                        directBuysById={directBuysById}
                        sortMode={sortMode}
                        buysLength={results}
                        handleCache={handleCache}
                    />
                )}

                <DirectBuysSearchModals
                    sortMode={sortMode}
                    setSortMode={setSortMode}
                    setDate={setDateFilter}
                    isOpenBuy={isOpenBuy}
                />
            </MainContainer>
        </>
    );
};

export default DirectBuysSearch;
