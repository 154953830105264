import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubmissionSets } from "src/buys/actions";
import { StepById } from "src/buys/modules/open-buys/reducer";
import {
    OpenBuy,
    OpenBuyState,
    OpenBuySubmission
} from "src/buys/modules/open-buys/types";
import {
    getSetMedia,
    getStateForOpenBuy
} from "src/buys/modules/open-buys/util";
import { selectDirectBuysLoading, selectYourSets } from "src/buys/selectors";
import { colors } from "src/constants";
import { BigBlueButton } from "src/profile/components/Buttons";
import XCard from "src/profile/components/XCard";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import { SectionLoading } from "../OpenBuyDetails";
import OpenBuySubmissionComponent from "./OpenBuySubmissionComponent";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const Main = styled.div`
    display: flex;
    flex-direction: column;
`;

const Section = styled.div`
    padding: 12px 18px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Title = styled.div`
    color: ${colors.gray2};
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    margin: auto 0;
`;

const HeaderRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

interface Props {
    openBuy: OpenBuy;
    steps: StepById;
    treatPublic?: boolean;
    setManageSubmission: (submission: OpenBuySubmission) => void;
    setManageSet: (id: number) => void;
}

const OpenBuySubmissionSection = ({
    openBuy,
    steps,
    treatPublic,
    setManageSubmission,
    setManageSet
}: Props) => {
    const dispatch = useDispatch();
    const submissions = useSelector(
        (state: GlobalState) => state.entities.openBuySubmissions
    );
    const yourSubmissions = openBuy?.openBuySubmissions || [];

    const treatGrouped = useSelector(
        selectFeatureFlags(featureFlags.ChallengesGroupedSubmissions)
    );

    const submissionSets = useSelector(
        (state: GlobalState) => state.entities.openBuySubmissionSets
    );

    const submissionSetIds = useSelector((state: GlobalState) =>
        selectYourSets(state, openBuy.id)
    );
    const loading = useSelector(selectDirectBuysLoading);

    useEffect(() => {
        dispatch(fetchSubmissionSets(openBuy.id));
    }, []);
    const ended = getStateForOpenBuy(openBuy) === OpenBuyState.ended;
    return (
        <Main>
            {!treatPublic && (
                <>
                    {loading && (
                        <Section>
                            <Title>LOADING SUBMISSIONS</Title>
                            {Array.from(Array(3)).map((i, index) => (
                                <SectionLoading key={`${index} loader`} />
                            ))}
                        </Section>
                    )}
                    {!loading && (
                        <Section>
                            {!(ended && yourSubmissions.length === 0) && (
                                <>
                                    <HeaderRow>
                                        <Title>
                                            {yourSubmissions.filter(
                                                id =>
                                                    !submissions[id]
                                                        .openBuySubmissionSetId
                                            ).length +
                                                submissionSetIds.length}{" "}
                                            SUBMISSIONS
                                        </Title>
                                    </HeaderRow>
                                    {yourSubmissions?.length > 0 &&
                                        yourSubmissions.map(id => {
                                            let submission = submissions[id];
                                            if (
                                                !treatGrouped ||
                                                !submission.openBuySubmissionSetId
                                            )
                                                return (
                                                    <OpenBuySubmissionComponent
                                                        key={id}
                                                        submission={submission}
                                                        steps={steps}
                                                        onClick={() =>
                                                            setManageSubmission(
                                                                submission
                                                            )
                                                        }
                                                    />
                                                );
                                        })}
                                    {treatGrouped &&
                                        submissionSetIds.length > 0 &&
                                        submissionSetIds.map(id => {
                                            let set = submissionSets[id];

                                            return (
                                                <OpenBuySubmissionComponent
                                                    key={id}
                                                    submission={{
                                                        socialAccount:
                                                            set.socialAccount,
                                                        customMedia: getSetMedia(
                                                            set.submissions
                                                        ),
                                                        status: set.status
                                                    }}
                                                    steps={steps}
                                                    progress={
                                                        ((set.submissions
                                                            ?.length -
                                                            1) /
                                                            openBuy
                                                                .submissionSteps
                                                                ?.length) *
                                                        100
                                                    }
                                                    onClick={() =>
                                                        setManageSet(set.id)
                                                    }
                                                />
                                            );
                                        })}
                                </>
                            )}

                            {ended ? (
                                <XCard text="This challenge is completed. Submissions are no longer accepted." />
                            ) : (
                                <BigBlueButton onClick={() => setManageSet(0)}>
                                    Add Another Submission
                                </BigBlueButton>
                            )}
                        </Section>
                    )}
                </>
            )}
        </Main>
    );
};

export default OpenBuySubmissionSection;
