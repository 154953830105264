import React, { useState } from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { FlexCentered, FlexContent } from "src/components/CommonStyles";
import FullscreenLayout from "src/components/layout/FullscreenLayout";
import NavButton from "src/components/NavButton";
import SimpleNavbar from "src/components/SimpleNavbar";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import GroupDescription from "./components/GroupDescription";
import { getChatGroupTitle } from "./selectors";
import GroupHeader from "./components/GroupHeader";
import DesktopTabBar from "src/components/desktop/DesktopTabBar";
import GroupMembersTab from "../tabs/GroupMembersTab";
import GroupOptions from "./components/GroupOptions";
import { AnimatePresence } from "framer-motion";
import GroupUserManageModal from "./modals/GroupUserManageModal";
import GroupUserMuteDurationModal from "./modals/GroupUserMuteDurationModal";
import GroupAddMemberModal from "./modals/GroupAddMemberModal";
import LeaveGroupFooter from "./components/LeaveGroupFooter";
import SpinLoader from "src/components/SpinLoader";
import useInternalRouting from "src/hooks/useInternalRouting";

const Main = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const Info = styled.div`
    padding: 24px 18px 0px;
`;

const LoadingOverlay = styled.div`
    ${FlexCentered}
    z-index: 997;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

interface Props {
    conversationId: string;
}
const GroupSettingsPage = ({ conversationId }: Props) => {
    const dispatch = useDispatch();
    const { goBackIfInternalRoute } = useInternalRouting(
        `/newchat/${conversationId}`
    );
    const isLoading = useSelector(
        (state: GlobalState) => state.ui.chat.chatCreationLoading
    );
    const [manageModalUserId, setManageModalUserId] = useState<null | string>(
        null
    );
    const [muteDurationUserId, setMuteDurationUserId] = useState<null | string>(
        null
    );
    const [addMemberModal, toggleAddMemberModal] = useState(false);
    const conversation = useSelector((state: GlobalState) =>
        getChatGroupTitle(state, conversationId)
    );

    if (!conversation) return null;

    return (
        <>
            <FullscreenLayout>
                <Main>
                    <SimpleNavbar
                        title="DETAILS"
                        left={
                            <NavButton back onClick={goBackIfInternalRoute} />
                        }
                    />
                    <FlexContent>
                        <Info>
                            <GroupHeader
                                conversationId={conversationId}
                                imageUrl={conversation.imageUrl}
                                displayName={conversation.displayName}
                                memberCount={conversation.memberCount}
                            />
                            <GroupDescription
                                conversationId={conversationId}
                                description={conversation.groupDescription}
                                imageUrl={
                                    conversation.imageUrl ||
                                    "/group-chat-default.svg"
                                }
                            />
                            <GroupOptions conversationId={conversationId} />
                        </Info>
                        <div>
                            <DesktopTabBar
                                oldLayout
                                items={[
                                    {
                                        label: "Members",
                                        key: "members",
                                        component: (
                                            <GroupMembersTab
                                                conversationId={conversationId}
                                                setManageModalUserId={
                                                    setManageModalUserId
                                                }
                                                toggleAddMemeberModal={() =>
                                                    toggleAddMemberModal(true)
                                                }
                                            />
                                        )
                                    },
                                    {
                                        label: "Admin",
                                        key: "admin",
                                        component: (
                                            <GroupMembersTab
                                                conversationId={conversationId}
                                                setManageModalUserId={
                                                    setManageModalUserId
                                                }
                                                toggleAddMemeberModal={() =>
                                                    toggleAddMemberModal(true)
                                                }
                                                isAdminTab
                                            />
                                        )
                                    }
                                ]}
                            />
                        </div>
                        <AnimatePresence>
                            {muteDurationUserId && (
                                <GroupUserMuteDurationModal
                                    userId={muteDurationUserId}
                                    conversationId={conversationId}
                                    closeModal={() =>
                                        setMuteDurationUserId(null)
                                    }
                                />
                            )}
                            {manageModalUserId && (
                                <GroupUserManageModal
                                    closeModal={() =>
                                        setManageModalUserId(null)
                                    }
                                    toggleMuteDurationModal={
                                        setMuteDurationUserId
                                    }
                                    userId={manageModalUserId}
                                    conversationId={conversationId}
                                />
                            )}
                            {addMemberModal && (
                                <GroupAddMemberModal
                                    conversationId={conversationId}
                                    participantIds={conversation.participantIds}
                                    closeModal={() =>
                                        toggleAddMemberModal(false)
                                    }
                                />
                            )}
                        </AnimatePresence>
                    </FlexContent>
                    <LeaveGroupFooter conversationId={conversationId} />
                </Main>
                {isLoading && (
                    <LoadingOverlay>
                        <SpinLoader size={60} color={colors.black} />
                    </LoadingOverlay>
                )}
            </FullscreenLayout>
        </>
    );
};

export default GroupSettingsPage;
