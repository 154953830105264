import React from "react";
import ReactDOM from "react-dom";

const withModalPortal = <P extends object>(
    WrappedComponent: React.ComponentType<P>
) => (props: P) => {
    const modals = document.getElementById("portal-modals");

    if (modals) {
        return ReactDOM.createPortal(<WrappedComponent {...props} />, modals);
    } else {
        return <WrappedComponent {...props} />;
    }
};
export default withModalPortal;
