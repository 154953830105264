import React from "react";
import { Link } from "react-router-dom";
import { breakpoints, colors, fonts } from "src/constants";
import { clearLink } from "src/utils/styles/snippets";
import styled from "styled-components";

const Main = styled.div<{ marginBottom?: true }>`
    display: ${props => props.hidden && "none"};
    margin-bottom: ${props => (props.marginBottom ? "20px" : "0px")};
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 16px;
`;

const TitleSubtitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Title = styled.h2`
    margin: 0px;
    color: ${colors.black};
    font-size: ${fonts.title}px;
    font-weight: 500;
    text-align: left;
    line-height: 29px;

    @media (max-width: ${breakpoints.mobile}px) {
        font-size: 20px;
    }
`;

const Subtitle = styled.span`
    color: ${colors.gray2};
    font-size: ${fonts.subtitle}px;
    text-align: left;
`;

const LinkButton = styled(Link)`
    ${clearLink()};
    display: inline-block;
    padding: 8px 12px;
    color: ${colors.black};
    background-color: ${colors.quaternaryLightGray};
    border-radius: 6px;
    font-size: ${fonts.smallTitle}px;
    font-weight: 500;
    text-decoration: none;
    transition: 0.1s;
    cursor: pointer;
    white-space: nowrap;

    @media (hover: hover) {
        :hover {
            color: ${colors.white};
            background-color: ${colors.primaryBlue};
            text-decoration: none;
        }
    }

    @media (max-width: 330px) {
        margin-top: 16px;
    }
    @media (max-width: 800) {
        white-space: normal;
    }
`;

interface Props {
    children?: React.ReactNode;
    title: string;
    subtitle: string;
    link: string;
    linkText: string;
    marginBottom?: true;
}

const LandingExternalHeader = ({
    children,
    title,
    subtitle,
    link,
    linkText,
    marginBottom
}: Props) => {
    return (
        <Main marginBottom={marginBottom}>
            <Header>
                <TitleSubtitleContainer>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </TitleSubtitleContainer>
                <LinkButton to={link}>{linkText}</LinkButton>
            </Header>

            {children}
        </Main>
    );
};

export default LandingExternalHeader;
