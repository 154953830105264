import { Action } from "../../types";

export const NOTIFICATION = {
    ADD: "NOTIFICATION_ADD",
    REMOVE: "NOTIFICATION_REMOVE"
};

export const addNotification = (text: string, color?: string): Action => ({
    type: NOTIFICATION.ADD,
    payload: { text, timestamp: Date.now(), color }
});

export const removeNotification = (): Action => ({
    type: NOTIFICATION.REMOVE,
    payload: null
});

// Set Pill Notification Text -----------------------------------------------

export const SET_PILL_NOTIFICATION_TEXT = "SET_PILL_NOTIFICATION_TEXT";

export type PillNotificationType = "success" | "danger" | "warning";

export function setPillNotificationText(
    text: string,
    type: PillNotificationType = "success",
    time: number = 1000
): Action {
    return {
        type: SET_PILL_NOTIFICATION_TEXT,
        payload: { text, type, time }
    };
}
