import React from "react";
import styled from "styled-components";
import Text from "./Text";
import { colors } from "../constants";

const Button = styled.button`
    outline: none !important;
    border-style: none;
    height: 100%;
    width: fit-content;
    justify-content: center;
    padding: 0;
    background: none;

    &:disabled {
        opacity: 0.5;
    }
`;

interface StyleProps {
    right?: boolean;
}

const ButtonText = styled(Text)<StyleProps>`
    font-size: 16px;
    color: ${colors.primaryBlue};
    transition: 0.1s ease-in;

    ${props => props.right && "text-align: right;"}

    @media (hover: hover) {
        :hover {
            color: ${colors.primaryBlueDark};
        }
    }
`;

interface Props {
    back?: true;
    close?: boolean;
    text?: string;
    right?: boolean;
    disabled?: boolean;
    children?: any;
    onClick: VoidFunction;
}

const NavButton = ({
    back,
    text,
    right,
    disabled,
    children,
    onClick,
    close
}: Props) => {
    return (
        <Button disabled={Boolean(disabled)} onClick={onClick}>
            {close ? (
                <img src="/black_close.svg" alt="close" />
            ) : back ? (
                <img src="/back-button.svg" alt="back" />
            ) : text ? (
                <ButtonText right={right}>{text}</ButtonText>
            ) : (
                children
            )}
        </Button>
    );
};

export default NavButton;
