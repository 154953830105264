import moment from "moment";
import React from "react";
import ProfilePhoto from "src/components/ProfilePhoto";
import { CustomLoading } from "src/components/SkeletonLoader";
import { colors } from "src/constants";
import { PublicProfile } from "src/profile/types";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.p`
    font-weight: normal;
    display: block;
    margin: 0px;
    padding: 0px;
    font-size: 0.8rem;
    color: ${colors.black};
`;
const Subtitle = styled.p`
    font-weight: normal;
    font-size: 0.8rem;
    margin: 0px;
    padding: 0px;
    color: ${colors.gray2};
`;
const Wrap = styled.div`
    padding-left: 12px;
    text-align: left;
`;

interface Props {
    publisher?:
        | PublicProfile
        | {
              id: number;
              profile: {
                  avatar: string;
                  username: string;
                  status: string;
              };
          };
    createdAt?: string;
    loading?: boolean;
    showId?: boolean;
}

const UserInfoHeader = ({ publisher, createdAt, loading, showId }: Props) => {
    if (loading)
        return (
            <Wrapper>
                <CustomLoading width="52px" height="52px" borderRadius="50%" />
                <Wrap>
                    <CustomLoading
                        style={{ marginBottom: "4px" }}
                        width="80px"
                        height="16px"
                    />
                    <CustomLoading width="140px" height="16px" />
                </Wrap>
            </Wrapper>
        );
    return (
        <Wrapper>
            <ProfilePhoto
                profilePhotoUrl={publisher?.profile.avatar}
                photoSize={52}
                status={publisher?.profile.status}
            />
            <Wrap>
                <Title>{publisher?.profile.username || "Unavailable"}</Title>
                {(showId && publisher?.id) || createdAt ? (
                    <Subtitle>
                        {showId && publisher?.id
                            ? `Publisher ID: ${publisher.id}`
                            : `Created on: ${moment(createdAt).format(
                                  `MM/DD/YYYY`
                              )}`}
                    </Subtitle>
                ) : null}
            </Wrap>
        </Wrapper>
    );
};

export default UserInfoHeader;
