import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { getCaptions } from "../../selectors";
import { Caption } from "../../types";
import { List, StatusWrapper } from "../Details.styled";
import CaptionList from "../components/CaptionList";
import XCard from "../../../profile/components/XCard";
import withAccordion from "./withAccordion";
import { fetchCampaignCaptions } from "../../captions/actions";
import { TextLoading } from "../../../components/SkeletonLoader";

interface Props {
    currentCampaign: number | null;
    fetchCampaignCaptions: (campaignId: number) => void;
    captions?: Caption[];
    captionsLoading: boolean;
}

const CaptionsAccordion = ({
    currentCampaign,
    fetchCampaignCaptions,
    captions,
    captionsLoading
}: Props) => {
    if (captionsLoading)
        return (
            <List>
                <TextLoading height={56} />
                <TextLoading height={56} />
            </List>
        );
    if (!captions)
        return (
            <StatusWrapper>
                <XCard
                    text="Failed to load caption resources, click the button below to try again."
                    button={{
                        text: "Try again",
                        callback: () => {
                            currentCampaign &&
                                fetchCampaignCaptions(currentCampaign);
                        }
                    }}
                />
            </StatusWrapper>
        );

    if (captions.length) return <CaptionList captions={captions} />;

    return (
        <StatusWrapper>
            <XCard
                text="This campaign doesn’t have any caption resources yet."
                button={{
                    text: "Try again",
                    callback: () => {
                        currentCampaign &&
                            fetchCampaignCaptions(currentCampaign);
                    }
                }}
            />
        </StatusWrapper>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    currentCampaign: state.ui.campaign.currentCampaignId,
    captionsLoading: state.ui.campaign.captionsLoading,
    captions: getCaptions(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchCampaignCaptions: (campaignId: number) =>
        dispatch(fetchCampaignCaptions(campaignId))
});

export default withAccordion(
    connect(mapStateToProps, mapDispatchToProps)(CaptionsAccordion)
);
