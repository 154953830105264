import React from "react";
import { BlueModalButton } from "src/profile/components/Buttons";
import styled from "styled-components";
import Platforms from "./Platforms";

interface StyleType {
    [index: string]: {
        backgroundColor: string;
        accentColor: string;
        src: string;
    };
}
const brandedStyles: StyleType = {
    tiktok: {
        backgroundColor: "rgb(20, 11, 24)",
        accentColor: "white",
        src: "/tiktok_profile/tiktok_profile@2x.png"
    },
    instagram: {
        backgroundColor:
            "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%",
        accentColor: "white",
        src: "/instagram_profile/instagram_profile@2x.png"
    }
};

const Button = styled(BlueModalButton)<{ platform: string }>`
    display: flex;
    flex-direction: row;
    background-color: ${props => brandedStyles[props.platform].backgroundColor};
    color: ${props => brandedStyles[props.platform].accentColor};
    border-radius: 100px;
    &:hover {
        opacity: 0.8;
        background-color: ${props =>
            brandedStyles[props.platform].backgroundColor};
    }
`;

const Logo = styled.img`
    width: 40px;
    height: 40px;
    position: absolute;
    left: 40px;
    border-radius: 20px;
`;

interface Props {
    platform: string;
    onClick: () => void;
    text?: string;
}

const BrandedButton = ({
    platform,
    onClick,
    text = `Verify with ${Platforms[platform].title}`
}: Props) => {
    return (
        <Button platform={platform} onClick={onClick}>
            <Logo src={brandedStyles[platform].src} />
            {text}
        </Button>
    );
};
export default BrandedButton;
