import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../constants";

interface StyleProps {
    uppercase?: true;
    capitalize?: true;
    tagColor?: string;
    clickable: boolean;
    tight?: true;
}

const Main = styled.div<StyleProps>`
    border: 1px solid ${props => props.tagColor || colors.mediumTeal};
    color: ${props => props.tagColor || colors.primaryGray};
    font-weight: 500;
    font-size: ${fonts.smallSubtitle}px;
    line-height: 12px;
    padding: 4px 6px;
    border-radius: 2px;
    width: fit-content;
    transition: 0.1s ease-in;
    white-space: nowrap;
    ${props => props.uppercase && "text-transform: uppercase;"}
    ${props => props.capitalize && "text-transform: capitalize;"}
    ${props => props.clickable && "cursor: pointer;"}
    
    ${props =>
        !props.tight &&
        `
        & + & {
            margin-left: 8px;
        }
    `}

    @media (hover: hover) {
        border: ${colors.darkTeal} solid 1px;
    }

`;

interface Props {
    text: string;
    uppercase?: true;
    capitalize?: true;
    tight?: true;
    tagColor?: string;
    onClick?: VoidFunction;
}

const Tag = ({
    text,
    uppercase,
    capitalize,
    tagColor,
    onClick,
    tight
}: Props) => {
    return (
        <Main
            uppercase={uppercase}
            capitalize={capitalize}
            tagColor={tagColor}
            onClick={onClick}
            clickable={Boolean(onClick)}
            tight={tight}
        >
            {text}
        </Main>
    );
};

export default Tag;
