import React, { useState } from "react";
import { colors, fonts, fontWeight, sizes } from "src/constants";
import styled from "styled-components";
import PublisherAssignmentModal from "src/admin-tools/components/PublisherAssignmentModal";
import { BlueButton } from "src/profile/components/Buttons";

const Main = styled.section`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const PublisherCount = styled.article`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${sizes.campaigns.selectionRow.height}px;
    padding: 0px 12px;
    color: ${colors.black};
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 8px;
    font-size: ${fonts.subtitle}px;
    font-weight: ${fontWeight.default};
`;

const ViewButton = styled(BlueButton)`
    padding: 12px 20px;
    font-size: ${fonts.subtitle}px;
`;

interface Props {
    allPublishers: boolean;
}

const ChallengeLogisticsPublisherAssignment = ({ allPublishers }: Props) => {
    // Local State ---------------------------------------------------
    const [
        publisherAssignmentModalOpen,
        setPublisherAssignmentModalOpen
    ] = useState(false);
    const [publisherCount, setPublisherCount] = useState(0);

    // JSX -----------------------------------------------------------
    return (
        <>
            <Main>
                <PublisherCount>
                    {publisherCount}{" "}
                    {publisherCount === 1 ? "Publisher" : "Publishers"}{" "}
                    {allPublishers ? "Blocked" : "Allowed"}
                    
                    {/* {`${publisherCount} ${
                        publisherCount === 1 ? "Publisher" : "Publishers"
                    } ${allPublishers ? "Blocked" : "Allowed"}`} */}
                </PublisherCount>
                <ViewButton
                    type="button"
                    onClick={() => setPublisherAssignmentModalOpen(true)}
                >
                    View
                </ViewButton>
            </Main>

            <PublisherAssignmentModal
                modalOpen={publisherAssignmentModalOpen}
                closeModal={() => setPublisherAssignmentModalOpen(false)}
                allPublishers={allPublishers}
                setPublisherCount={setPublisherCount}
            />
        </>
    );
};

export default ChallengeLogisticsPublisherAssignment;
