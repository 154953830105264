import React, { useEffect, useState } from "react";
import { freezeBody, thawBody } from "../util";
import { motion } from "framer-motion";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Parent = styled(motion.div)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Child = styled.div`
    height: fit-content;
    width: 70vw;
    max-width: 350px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
`;

const Video = styled.video`
    display: block;
    width: 100%;
    cursor: pointer;
`;

const Image = styled.img`
    display: block;
    width: 100%;
`;

const PlayScreen = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    font-size: 50px;
    pointer-events: none;
`;

export interface SimpleMedia {
    url: string;
    coverPhotoUrl?: string;
    type: string;
}

interface Props {
    media: SimpleMedia | null;
    closeModal: VoidFunction;
}

const SimpleMediaModal = ({ media, closeModal }: Props) => {
    useEffect(() => {
        freezeBody();
        return thawBody;
    }, []);

    useEffect(() => {
        if (!media) {
            closeModal();
        }
    }, [media]);

    const [play, setPlay] = useState(false);

    if (!media) {
        return null;
    } else {
        return (
            <Parent
                onClick={closeModal}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <Child onClick={e => e.stopPropagation()}>
                    {media.type === "video" ? (
                        <>
                            <Video
                                playsInline={true}
                                src={media.url}
                                poster={media.coverPhotoUrl}
                                onClick={e => {
                                    if (!play) {
                                        setPlay(true);
                                    }
                                    const target = e.target as HTMLVideoElement;
                                    target.paused
                                        ? target.play()
                                        : target.pause();
                                }}
                            />
                            <PlayScreen
                                style={{ display: play ? "none" : "flex" }}
                            >
                                <FontAwesomeIcon icon={["fas", "play"]} />
                            </PlayScreen>
                        </>
                    ) : (
                        <Image src={media.url} />
                    )}
                </Child>
            </Parent>
        );
    }
};

export default SimpleMediaModal;
