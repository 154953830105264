import React from "react";
import styled from "styled-components";
import ProfilePhoto from "../../../components/ProfilePhoto";
import SettingsLink from "../components/SettingsLink";

const Main = styled.div`
    padding: 15px 15px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Link = styled(SettingsLink)`
    margin-top: 8px;
`;

interface Props {
    profilePhotoUrl: string | null;
    photoModal: VoidFunction;
}

const EditProfilePhoto = ({ profilePhotoUrl, photoModal }: Props) => {
    return (
        <Main>
            <ProfilePhoto
                profilePhotoUrl={profilePhotoUrl}
                photoSize={100}
                onClick={photoModal}
            />
            <Link onClick={photoModal}>Edit / Change</Link>
        </Main>
    );
};

export default EditProfilePhoto;
