import React from "react";
import { OldMedia, PlugLink } from "../../media/types";
import styled from "styled-components";
import { connect } from "react-redux";
import { GlobalState } from "../../reducers";
import { colors, fonts } from "../../constants";
import { oldToNewMedia } from "../../util";
import {
    fetchLinkForPlug,
    fetchLinkForPlugSuccess,
    fetchLinkParams
} from "src/links/actions";
import { emailPublisherMedia } from "src/campaigns/actions";

const Main = styled.div`
    padding: 10px 15px;
    border-bottom: 1px solid ${colors.mediumTeal};
    display: flex;
    align-items: center;
`;

const MediaIcon = styled.img`
    height: 46px;
    width: 46px;
    min-width: 46px;
    border-radius: 6px;
    object-fit: cover;
`;

const MediaName = styled.div`
    font-size: ${fonts.subtitle}px;
    margin: 0 10px;
    word-break: break-all;
`;

const ButtonContainer = styled.div`
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    display: flex;
    margin-left: auto;
`;

const Button = styled.a`
    height: 44px;
    width: 44px;
    min-width: 44px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
        height: 20px;
    }
`;

const Divider = styled.div`
    height: 44px;
    width: 1px;
    background-color: ${colors.mediumTeal};
`;

interface Props {
    customLink: OldMedia;
    plugLink: PlugLink | null;
    fetchLinkForPlug: (params: fetchLinkParams) => void;
    fetchLinkForPlugSuccess: (link: PlugLink) => void;
    emailPublisherMedia: (mediaId: number) => void;
}

const CustomLinksIndexRow = ({
    customLink,
    plugLink,
    fetchLinkForPlug,
    fetchLinkForPlugSuccess,
    emailPublisherMedia
}: Props) => {
    function imageUrl() {
        if (customLink.url && customLink.type === "photo") {
            return customLink.url;
        } else if (customLink.cover_photo_url) {
            return customLink.cover_photo_url;
        } else {
            return "/other_profile/other_profile@2x.png";
        }
    }

    function linkClicked() {
        if (plugLink) {
            fetchLinkForPlugSuccess(plugLink);
        } else {
            fetchLinkForPlug(oldToNewMedia(customLink));
        }
    }

    return (
        <Main>
            <MediaIcon src={imageUrl()} />
            <MediaName>{customLink.name}</MediaName>
            <ButtonContainer>
                <Button onClick={linkClicked}>
                    <img src={"/gray_link.svg"} alt="get link" />
                </Button>
                <Divider />
                <Button
                    target="_blank"
                    download={`media-id-${customLink.id}`}
                    href={customLink.url || undefined}
                >
                    <img src={"/gray_download.svg"} alt="download" />
                </Button>
            </ButtonContainer>
        </Main>
    );
};

const mapStateToProps = (state: GlobalState, ownProps: any) => ({
    plugLink:
        state.links.linksForPlugs[
            `${state.ui.campaign.currentCampaignId}-${ownProps.customLink.id}`
        ]
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchLinkForPlug: (params: fetchLinkParams) =>
        dispatch(fetchLinkForPlug(params)),
    fetchLinkForPlugSuccess: (link: PlugLink) =>
        dispatch(fetchLinkForPlugSuccess(link)),
    emailPublisherMedia: (mediaId: number) =>
        dispatch(emailPublisherMedia(mediaId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomLinksIndexRow);
