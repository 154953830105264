import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullScreenModal from "src/modals/FullScreenModal";
import { Row, Image, Chevron } from "src/search/components/StyledComponents";
import SimpleNavbar from "src/components/SimpleNavbar";
import NavButton from "src/components/NavButton";
import { Campaign } from "src/campaigns/types";
import CampaignReward from "src/campaigns/details/components/CampaignReward";
import VerticallyCentered from "src/components/VerticallyCentered";
import Subtitle from "src/components/text/Subtitle";
import { fonts } from "src/constants";

const NumberCampaigns = styled(Subtitle)`
    margin: 12px 18px;
    font-weight: 700;
`;

const BorderImage = styled(Image)`
    border-radius: 12px;
`;

const Reward = styled(VerticallyCentered)`
    font-size: 12px;
`;

const CampaignTitle = styled.div`
    font-weight: 700;
    font-size: ${fonts.smallTitle}px;
    text-align: left;
    margin-bottom: 1px;
`;
interface Props {
    campaigns: Campaign[];
    onSelect: (campaign?: Campaign) => void;
    open: boolean;
}

const CampaignsModal = ({ campaigns, onSelect, open }: Props) => {
    if (!open) return null;
    return (
        <FullScreenModal closeModal={() => onSelect()} keepFrozenOnClose>
            <SimpleNavbar
                left={<NavButton close onClick={() => onSelect()} />}
                title={"Bid Increased Campaigns"}
            />
            <NumberCampaigns>{campaigns.length} CAMPAIGNS</NumberCampaigns>
            {campaigns.map((campaign, index) => (
                <Row
                    key={`${index}-campaign`}
                    onClick={() => onSelect(campaign)}
                    style={{ paddingBottom: "10px" }}
                >
                    <BorderImage src={campaign.imageUrl} alt="" />
                    <div>
                        <CampaignTitle>{campaign.campaignName}</CampaignTitle>
                        <Reward>
                            <CampaignReward
                                countryPlatformReward={
                                    campaign.countryPlatformReward
                                }
                                rewardType={campaign.rewardType}
                            />
                        </Reward>
                    </div>
                    <Chevron>
                        <FontAwesomeIcon icon="chevron-right" />
                    </Chevron>
                </Row>
            ))}
        </FullScreenModal>
    );
};

export default CampaignsModal;
