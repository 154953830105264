import {
    CREATE_CAMPAIGN_CUSTOM_LINK,
    FETCH_CAMPAIGN_CUSTOM_LINKS
} from "../../campaigns/custom-links/actions";
import { Action } from "../../types";
import { CAMPAIGN_MODAL } from "../campaigns/actions";
import { CustomLinksModalState } from "./actions";

export interface CustomLinksUIState {
    customLinksModal: CustomLinksModalState;
    customLinksLoading: boolean;
}

const initialCustomLinksUIState: CustomLinksUIState = {
    customLinksModal: CustomLinksModalState.clear,
    customLinksLoading: true
};

const customLinksUIReducer = (
    state = initialCustomLinksUIState,
    action: Action
) => {
    switch (action.type) {
        case CAMPAIGN_MODAL.CUSTOM_LINKS:
            return { ...state, customLinksModal: action.payload.state };
        case FETCH_CAMPAIGN_CUSTOM_LINKS.REQUEST:
            return { ...state, customLinksLoading: true };
        case FETCH_CAMPAIGN_CUSTOM_LINKS.SUCCESS:
            return { ...state, customLinksLoading: false };
        case CREATE_CAMPAIGN_CUSTOM_LINK.REQUEST:
            return { ...state, customLinksLoading: true };
        case CREATE_CAMPAIGN_CUSTOM_LINK.SUCCESS:
            return { ...state, customLinksLoading: false };
        default:
            return state;
    }
};

export default customLinksUIReducer;
