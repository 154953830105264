import React, { ChangeEvent, StyleHTMLAttributes, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { clearModal } from "./actions";
import { freezeBody, thawBody } from "../util";
import { colors } from "src/constants";

const Parent = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Child = styled.div`
    position: relative;
    width: 75vw;
    min-width: 300px;
    max-width: 375px;
    padding: 86px 20px 20px;
    margin-top: 70px;
    border-radius: 15px;
    background-color: white;
`;

const Badge = styled.div`
    width: 154px;
    height: 154px;
    margin: 0 auto;
    position: absolute;
    top: -78px;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 100%;
    /* border: 1px solid black; */
    overflow: hidden;
`;

const CircleImage = styled.img`
    height: 138px;
    width: 138px;
    object-fit: cover;
    border-radius: 100%;
    border: 1px solid ${colors.mediumTeal};
`;

const Overlay = styled.label`
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 100%;
    cursor: pointer;
`;

interface Props {
    imageUrl: string;
    imageUploadCallback?: (e: ChangeEvent<HTMLInputElement>) => void;
    clearModal: () => void;
    localClearModal?: () => void;
    children: any;
    imageStyle?: React.CSSProperties;
}

const ImageModal = ({
    imageUrl,
    imageUploadCallback,
    clearModal,
    localClearModal,
    children,
    imageStyle
}: Props) => {
    useEffect(() => {
        freezeBody();

        return thawBody;
    }, []);

    function closeModal() {
        if (localClearModal) {
            localClearModal();
        } else {
            clearModal();
        }
    }

    return (
        <Parent onClick={closeModal}>
            <Child onClick={e => e.stopPropagation()}>
                <Badge>
                    <CircleImage src={imageUrl} style={imageStyle} />
                    <Overlay>
                        <input
                            type={"file"}
                            accept={"image/jpeg, image/png"}
                            style={{ display: "none" }}
                            onChange={imageUploadCallback}
                        />
                    </Overlay>
                </Badge>
                {children}
            </Child>
        </Parent>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(null, mapDispatchToProps)(ImageModal);
