import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "src/reducers";
import { Action, NormalizedAction } from "src/types";

// Fetch All Payment Types --------------------------------------------------------------

export const FETCH_PAYMENT_TYPES = {
    REQUEST: "FETCH_PAYMENT_TYPES_REQUEST",
    SUCCESS: "FETCH_PAYMENT_TYPES_SUCCESS",
    FAILURE: "FETCH_PAYMENT_TYPES_FAILURE"
};

export const fetchPaymentTypes = (): Action => ({
    type: FETCH_PAYMENT_TYPES.REQUEST,
    payload: null
});

export const fetchPaymentTypesSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>
): NormalizedAction => ({
    type: FETCH_PAYMENT_TYPES.SUCCESS,
    response
});

export const fetchPaymentTypesFailure = (error: string): Action => ({
    type: FETCH_PAYMENT_TYPES.FAILURE,
    payload: { error }
});
