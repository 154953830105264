import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { Shoutout } from "../types";
import { emailPublisherMedia } from "src/campaigns/actions";
import { goBack, push } from "connected-react-router";
import { storage } from "../../../firebase/FirebaseConfig";
import styled from "styled-components";
import SimpleNavbar from "../../../components/SimpleNavbar";
import NavButton from "../../../components/NavButton";
import ShoutoutDetailsHeader from "../components/ShoutoutDetailsHeader";
import CancelModal from "../modals/CancelModal";
import CountdownModal from "../modals/CountdownModal";
import { CampaignsModal } from "src/ui/modals/reducers";
import evaluateShoutoutState, {
    ShoutoutState
} from "../utils/evaluateShoutoutState";
import { OldSocialAccount } from "../../../social-accounts/SocialAccount";
import ShoutoutDetailsAccountCell from "../components/ShoutoutDetailsAccountCell";
import CopyToClipboard from "react-copy-to-clipboard";
import ShoutoutDetailsButtons from "../components/ShoutoutDetailsButtons";
import DeleteModal from "../modals/DeleteModal";
import { isOldSafari } from "../../../util";
import MediaEmailModal from "../../components/MediaEmailModal";
import { colors, fonts } from "../../../constants";

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${colors.mediumTeal};
`;

const DownloadContainer = styled.a`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CopyAllLinksButton = styled.div`
    color: ${colors.primaryBlue};
    font-size: ${fonts.subtitle}px;
    line-height: 20px;
    padding: 15px 0px;
    cursor: pointer;
`;

interface Props {
    shoutout: Shoutout | null;
    socialAccounts: OldSocialAccount[];
    modal: CampaignsModal;
    emailPublisherMedia: (mediaId: number) => void;
    push: (path: string) => void;
    goBack: () => void;
}

const ShoutoutDetails = ({
    shoutout,
    socialAccounts,
    modal,
    emailPublisherMedia,
    push,
    goBack
}: Props) => {
    const [mediaUrl, setMediaUrl] = useState(
        shoutout ? shoutout.Media[0].url : null
    );
    const [coverUrl, setCoverUrl] = useState(
        shoutout ? shoutout.Media[0].cover_photo_url : null
    );

    useEffect(() => {
        if (shoutout) {
            const mediaObject = shoutout.Media[0];

            if (mediaObject.cover_photo && !coverUrl) {
                storage
                    .ref(
                        `/${mediaObject.Campaign.linkUrlPrefix}/${mediaObject.cover_photo}`
                    )
                    .getDownloadURL()
                    .then((url: string) => {
                        setCoverUrl(url);
                    })
                    .catch((error: string) => {});
            }

            if (mediaObject.key && !mediaUrl) {
                storage
                    .ref(
                        `/${mediaObject.Campaign.linkUrlPrefix}/${mediaObject.key}`
                    )
                    .getDownloadURL()
                    .then((url: string) => {
                        setMediaUrl(url);
                    })
                    .catch((error: string) => {});
            }
        }
    }, [shoutout]);

    function renderDownload(
        state: ShoutoutState,
        mediaId: number,
        mediaUrl: string
    ) {
        if (
            state === ShoutoutState.Reserved ||
            state === ShoutoutState.Blocking
        ) {
            return (
                <>
                    <DownloadContainer
                        target="_blank"
                        download={`media-id-${mediaId}`}
                        href={mediaUrl}
                        onClick={e => {
                            if (
                                shoutout &&
                                shoutout.Media[0].type === "video" &&
                                isOldSafari()
                            ) {
                                e.preventDefault();
                                emailPublisherMedia(shoutout.Media[0].id);
                            }
                        }}
                    >
                        <img
                            style={{ height: `20px` }}
                            src={"/gray_download.svg"}
                        />
                    </DownloadContainer>
                    <Divider />
                </>
            );
        } else {
            return null;
        }
    }

    function renderAccounts(state: ShoutoutState) {
        if (!shoutout) return null;

        let allAccountsString = "";
        const cells = shoutout.ShoutoutFillOrders.map((fillOrder, idx) => {
            const account = socialAccounts.find(account => {
                return account.id === parseInt(fillOrder.social_account_id);
            });

            if (account) {
                allAccountsString += `${account.username}\n${fillOrder.redirect_url}\n\n`;

                return (
                    <div key={fillOrder.id}>
                        <ShoutoutDetailsAccountCell
                            fillOrder={fillOrder}
                            socialAccount={account}
                            state={state}
                        />
                        <Divider />
                    </div>
                );
            } else {
                return null;
            }
        });

        const copyAllButton = (
            <div key={cells.length}>
                <CopyToClipboard text={allAccountsString}>
                    <CopyAllLinksButton>Copy all links</CopyAllLinksButton>
                </CopyToClipboard>
                <Divider />
            </div>
        );

        if (cells.length > 1) {
            cells.push(copyAllButton);
        }

        return cells;
    }

    const leftNavButton = <NavButton onClick={goBack} back />;

    if (shoutout) {
        const state = evaluateShoutoutState(shoutout);

        return (
            <>
                <SimpleNavbar left={leftNavButton} />
                <ShoutoutDetailsHeader
                    shoutout={shoutout}
                    media={mediaUrl || ""}
                    coverPhoto={coverUrl || ""}
                    state={state}
                />
                <Divider />
                {renderDownload(state, shoutout.Media[0].id, mediaUrl || "")}
                {renderAccounts(state)}
                <ShoutoutDetailsButtons shoutout={shoutout} state={state} />
                {modal === CampaignsModal.countdown && <CountdownModal />}
                {modal === CampaignsModal.cancel && <CancelModal />}
                {modal === CampaignsModal.delete && <DeleteModal />}
                {modal === CampaignsModal.mediaEmail && <MediaEmailModal />}
            </>
        );
    } else {
        push("/campaigns");
        return null;
    }
};

const mapStateToProps = (state: GlobalState) => ({
    shoutout: state.ui.shoutouts.currentShoutout,
    socialAccounts: state.profile.profile
        ? state.profile.profile.SocialAccounts
        : [],
    modal: state.ui.modals.modal
});

const mapDispatchToProps = (dispatch: any) => ({
    emailPublisherMedia: (mediaId: number) =>
        dispatch(emailPublisherMedia(mediaId)),
    push: (path: string) => dispatch(push(path)),
    goBack: () => dispatch(goBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoutoutDetails);
