import React, { MouseEventHandler, useMemo } from "react";
import { colors } from "src/constants";
import { clearButton } from "src/utils/styles/snippets";
import styled, { css } from "styled-components";

const Wrapper = styled.button<{
    size?: number;
    disabled?: boolean;
}>`
    ${clearButton()}
    width: ${props => props.size ?? 26}px;
    height: ${props => props.size ?? 26}px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${colors.quaternaryLightGray};
    border: 1px solid transparent;
    transition: 0.1s ease;
    cursor: pointer;

    &:hover {
        border-color: ${colors.primaryGray};
    }
    ${props =>
        props.disabled &&
        css`
            cursor: default;
            opacity: 0.4;
            border: none;
            &:hover {
                border: none;
            }
        `}
`;
const Icon = styled.img`
    width: 12px;
    height: auto;
`;
const ModalIconBtn = ({
    iconSrc,
    onClick,
    className,
    refProp,
    disabled,
    children,
    size = 26
}: {
    className?: string;
    iconSrc?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    children?: JSX.Element;
    refProp?: React.RefObject<any>;
    disabled?: boolean;
    size?: number;
}) => {
    return (
        <Wrapper
            disabled={disabled}
            ref={refProp}
            onClick={onClick}
            className={className}
            size={size}
        >
            {children ? children : <Icon src={iconSrc} alt="" />}
        </Wrapper>
    );
};

export default ModalIconBtn;
