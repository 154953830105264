import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { post } from "src/Api";
import SpinLoader from "src/components/SpinLoader";
import { storage } from "src/firebase/FirebaseConfig";
import ShadeScreen from "src/media/components/ShadeScreen";
import { GlobalState } from "src/reducers";
import Platforms from "src/social-accounts/Platforms";
import { setLinkListError } from "src/ui/link-lists/actions";
import { addNotification } from "src/ui/notifications/actions";
import { errorToString, makeId } from "src/util";
import styled from "styled-components";
import LinkReplacementImageSelector from "./LinkReplaceImageSelector";
const ImageContainer = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 20px;
    margin-top: 10px;
    display: flex;
    position: relative;
    overflow: hidden;
    cursor: pointer;
`;
const Image = styled.img`
    height: 60px;
    width: 60px;
`;

interface Props {
    type: string;
    image: string;
    onImage: (url: string | null) => void;
    campaignId?: number;
}

const LinkImageEdit = ({ type, image, onImage, campaignId }: Props) => {
    const [selectImage, setSelectImage] = useState(false);
    const uploadRef = useRef<HTMLInputElement>(null);
    const [defaultImg, setDefaultImg] = useState("");
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (campaignId) {
            axios
                .get(`/v1/campaign/${campaignId}`, {})
                .then(res => setDefaultImg(res.data.data.imageUrl));
        } else setDefaultImg(image);
    }, []);
    const dispatch = useDispatch();

    const fileUpload = (e: any) => {
        setUploading(true);
        e.preventDefault();
        const allowedTypes = ["image/jpeg", "image/png"];
        const file = e.currentTarget.files[0];

        if (file && allowedTypes.includes(file.type)) {
            let type = file.type.split("/")[1];

            if (type === "quicktime") {
                type = "mov";
            }

            const id = `${localStorage.getItem("userid")}_${makeId(50)}${
                file.name
            }.${file.type.split("/")[1]}`;
            const ref = storage.ref().child("linkListAssets/" + id);

            ref.put(file)
                .then(async () => {
                    try {
                        const fileRes = await post("/v1/linkList/photoUrl", {
                            filename: id
                        });

                        if (fileRes.data.data) {
                            onImage(fileRes.data.data);
                        } else
                            dispatch(addNotification("error uploading image"));
                        setUploading(false);
                    } catch (err) {
                        setUploading(false);
                        dispatch(addNotification("error uploading image"));
                        if (axios.isAxiosError(err) && err.response) {
                            dispatch(
                                setLinkListError(
                                    errorToString(err),
                                    "deleteList"
                                )
                            );
                        }
                    }
                })
                .catch((error: string) => {
                    window.alert("Something went wrong. Please Refresh.");
                });
        }
    };

    return (
        <>
            <ImageContainer onClick={() => setSelectImage(true)}>
                <ShadeScreen>
                    {uploading ? (
                        <SpinLoader size={35} color={"white"} />
                    ) : (
                        <img src={"/action-icons/edit.svg"} />
                    )}
                </ShadeScreen>
                <Image src={image} />
            </ImageContainer>
            <input
                ref={uploadRef}
                value={""}
                type={"file"}
                accept={"image/png, image/jpeg"}
                style={{ display: "none" }}
                onChange={fileUpload}
                onTouchCancel={() => setUploading(false)}
            />
            <LinkReplacementImageSelector
                type={type || ""}
                onClose={() => setSelectImage(false)}
                open={selectImage}
                onSelect={value => {
                    if (value === "custom") uploadRef.current?.click();
                    if (value === "default") onImage(defaultImg || "default");
                    else onImage(null);
                    setSelectImage(false);
                }}
            />
        </>
    );
};
export default LinkImageEdit;
