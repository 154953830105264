import { Action } from "src/types";

export const SET_SCROLL_RESTORATION_POSITION =
    "SET_SCROLL_RESTORATION_POSITION";

export function setScrollRestorationPosition(
    pathname: string,
    top: number
): Action {
    return {
        type: SET_SCROLL_RESTORATION_POSITION,
        payload: { pathname, top }
    };
}
