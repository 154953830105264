import { push } from "connected-react-router";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "src/components/Navbar";
import NavButton from "src/components/NavButton";
import SimpleNavbar from "src/components/SimpleNavbar";
import { CustomLoading } from "src/components/SkeletonLoader";
import { GlobalState } from "src/reducers";
import { setLinkListError } from "src/ui/link-lists/actions";
import styled from "styled-components";
import { BigOutlineButton } from "../components/Buttons";
import LinkListCell from "./components/LinkListCell";
import LinkListCreateModal from "./components/LinkListCreateModal";
import { getLinkLists } from "./selectors";

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    margin: auto;
    gap: 18px;
    padding: 18px;
`;

const LinkLists = () => {
    const dispatch = useDispatch();
    const linkLists = useSelector(getLinkLists);
    const loading = useSelector(
        (state: GlobalState) => state.ui.linkList.loading
    );
    const [creating, setCreating] = useState(false);
    return (
        <>
            <Navbar
                title="LINK LISTS"
                customMobileNavbar={
                    <SimpleNavbar
                        title={"LINK LISTS"}
                        left={
                            <NavButton
                                back
                                onClick={() => {
                                    dispatch(setLinkListError(null));
                                    dispatch(push("/profile/activity"));
                                }}
                            />
                        }
                    />
                }
            />

            <ListContainer>
                {loading ? (
                    Array.from({ length: 20 }, (x, i) => (
                        <CustomLoading height="80px" key={`loader${i}`} />
                    ))
                ) : (
                    <>
                        {linkLists.map(list => (
                            <LinkListCell key={list.id} linkList={list} />
                        ))}
                        <BigOutlineButton onClick={() => setCreating(true)}>
                            Create Link List
                        </BigOutlineButton>
                    </>
                )}
            </ListContainer>
            <LinkListCreateModal
                onClose={() => setCreating(false)}
                open={creating}
            />
        </>
    );
};
export default LinkLists;
