import React, { useState } from "react";
import ScrollableWithFadedArrows from "src/components/ScrollableWithFadedArrows";
import Tag from "src/components/Tag";
import { breakpoints, colors, fonts } from "src/constants";
import { clearSpacing, hideScrollbar } from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import SocialAccountDetailsHoverModal from "../modals/SocialAccountDetailsHoverModal";
import { SocialAccountDetails } from "../utils/social-accounts.types";
import DetailCell from "./DetailCell";
import SocialAccountProfileSection from "./SocialAccountProfileSection";

const Main = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const InterestsAndSpecialties = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const interestsAndSpecialtiesStyles = css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    overflow-x: auto;

    ${props => props.theme.isMobile && hideScrollbar()};
`;

const Interests = styled.article`
    ${interestsAndSpecialtiesStyles};
`;

const Specialties = styled.article`
    ${interestsAndSpecialtiesStyles};
`;

const Details = styled.article`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;

    @media (max-width: ${breakpoints.tablet}px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: 850px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${breakpoints.mobile}px) {
        grid-template-columns: 1fr;
    }
`;

const Disclaimer = styled.p`
    ${clearSpacing()};
    padding: 8px 14px;
    color: ${colors.primaryGray};
    background-color: ${colors.teritaryLightGray};
    border-radius: 4px;
    font-size: ${fonts.smallSubtitle}px;
    text-align: left;
`;

interface Props {
    interests: string[];
    specialties: string[];
    details: SocialAccountDetails[];
}

const SocialAccountProfileDetails = ({
    interests,
    specialties,
    details
}: Props) => {
    // Library Methods -----------------------------------------------

    // Redux State ---------------------------------------------------

    // Local State ---------------------------------------------------
    const [currentHoveredDetail, setCurrentHoveredDetail] = useState("");

    // Local Constants -----------------------------------------------

    // Local Functions -----------------------------------------------

    // Custom Hooks --------------------------------------------------

    // Effects -------------------------------------------------------

    // JSX -----------------------------------------------------------
    return (
        <SocialAccountProfileSection
            title="Details"
            body={
                <Main>
                    <InterestsAndSpecialties>
                        {interests.length > 0 && (
                            <ScrollableWithFadedArrows
                                elements={interests.map(
                                    (interest: string, index: number) => {
                                        return (
                                            <Tag
                                                key={`${interest}-${index}`}
                                                text={interest}
                                                tight
                                            />
                                        );
                                    }
                                )}
                                gap={8}
                            />
                        )}
                        {/* <Specialties>
                            {specialties.map(
                                (specialty: string, index: number) => {
                                    return (
                                        <Tag
                                            key={`${specialty}-${index}`}
                                            text={specialty}
                                        />
                                    );
                                }
                            )}
                        </Specialties> */}
                    </InterestsAndSpecialties>

                    <Details>
                        {details.map(
                            (detail: SocialAccountDetails, index: number) => {
                                if (detail.data !== "Nope") {
                                    return (
                                        <DetailCell
                                            key={`${detail.data}-${detail.label}-${index}`}
                                            data={detail.data}
                                            iconType={
                                                detail.iconType || undefined
                                            }
                                            label={detail.label}
                                            setCurrentHoveredDetail={
                                                setCurrentHoveredDetail
                                            }
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            }
                        )}
                    </Details>

                    <Disclaimer>
                        * This field is from a third party data source and may
                        not be accurate for all creators.
                    </Disclaimer>

                    {/* <SocialAccountDetailsHoverModal
                        currentHoveredDetail={currentHoveredDetail}
                        setCurrentHoveredDetail={setCurrentHoveredDetail}
                    /> */}
                </Main>
            }
            bodyPadding="0px 12px 12px"
        />
    );
};

export default SocialAccountProfileDetails;
