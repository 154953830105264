import {
    all,
    call,
    cancelled,
    put,
    select,
    take,
    takeEvery,
    takeLatest,
    takeLeading
} from "redux-saga/effects";
import { AddMemberParams } from "../group-user-settings/actions";
import { createEventChannel, get, set } from "../saga";
import { ChatProfile } from "../types/ChatProfile";
import { chatProfileList } from "../schema";
import { getAuthState } from "src/auth/selectors";
import {
    CURRENT_CHAT_USER_PROFILE_CHANNEL,
    fetchChatUserProfile,
    fetchChatUserProfileSuccess,
    FETCH_CHAT_USER_PROFILE,
    SET_CHAT_USER_ONLINE_STATUS
} from "./actions";
import { Action } from "src/types";
import * as schema from "../schema";
import { normalize } from "normalizr";
import { chunk } from "src/util";
import { firestore } from "firebase";
import { selectChatProfileById, selectPlugProfileForChat } from "../selectors";

function* createCurrentChatUserProfileChannelRequest({
    payload: { participantIds, conversationId }
}: Action) {
    const participantIdsChunks = chunk(participantIds, 10);
    try {
        const channels = participantIdsChunks.map(userIds =>
            createCurrentChatUserProfileChannelService(userIds)
        );

        yield all(channels);
    } catch (error) {
        console.log(error);
    }
}

function* createCurrentChatUserProfileChannelService(userIds: string[]) {
    const updateChannel = createEventChannel("User", [
        firestore.FieldPath.documentId(),
        "in",
        userIds
    ]);

    try {
        while (true) {
            const profiles: ChatProfile[] = yield take(updateChannel);

            yield put(
                fetchChatUserProfileSuccess(
                    normalize(profiles, chatProfileList)
                )
            );
        }
    } finally {
        if (yield cancelled()) {
            updateChannel.close();
        }
    }
}

export function* fetchOwnChatProfileRequest() {
    try {
        const { userId } = yield select(getAuthState);

        yield call(
            fetchChatUserProfileRequest,
            fetchChatUserProfile(`${userId}`)
        );
    } catch (error) {}
}

function* fetchChatUserProfileRequest({ payload: { otherId } }: Action) {
    try {
        const chatProfile: ChatProfile | null = yield call(
            get,
            "User",
            otherId
        );

        if (chatProfile) {
            yield put(
                fetchChatUserProfileSuccess(
                    normalize(chatProfile, schema.chatProfile)
                )
            );
        }
    } catch (error) {}
}

export function* createChatProfileRequest(
    user: AddMemberParams,
    conversationId?: string
) {
    try {
        const newChatProfile = {
            id: user.publisherId,
            username: user.username,
            ...(user.avatarUrl && { avatarUrl: user.avatarUrl }),
            ...(conversationId && {
                conversationIds: [conversationId],
                conversations: [
                    {
                        conversationId,
                        activeInChat: false,
                        notificationsPausedIndefinitely: false
                    }
                ]
            })
        };

        yield call(set, "User", newChatProfile);
    } catch (error) {
        console.log("error creating new chat profile", error);
    }
}

export function* updateChatProfileService(
    user: AddMemberParams,
    conversationId: string
) {
    try {
        // create chat profile if not exist / else update conversation digest
        const chatProfile: ChatProfile | null = yield call(
            get,
            "User",
            user.publisherId
        );
        if (!chatProfile) {
            yield call(createChatProfileRequest, user, conversationId);
        } else {
            const newChatProfile = {
                id: user.publisherId,
                conversationIds: [
                    ...chatProfile.conversationIds,
                    conversationId
                ],
                conversations: [
                    ...chatProfile.conversations,
                    {
                        conversationId,
                        activeInChat: false,
                        notificationsPausedIndefinitely: false
                    }
                ]
            };

            yield call(set, "User", newChatProfile);
        }
    } catch (error) {
        console.log(error);
    }
}

function* setChatUserOnlineStatusRequest({
    payload: { conversationId, online }
}: Action) {
    const { userId } = yield select(getAuthState);
    let userProfile: ChatProfile = yield select(selectChatProfileById, userId);
    if (!userProfile) {
        const plugProfile: AddMemberParams = yield select(
            selectPlugProfileForChat
        );
        yield call(createChatProfileRequest, plugProfile, conversationId);
        userProfile = yield select(selectChatProfileById, userId);
    }
    const index = userProfile.conversations.findIndex(
        el => el.conversationId === conversationId
    );
    const newConversations = userProfile.conversations.slice();
    newConversations[index] = {
        ...newConversations[index],
        activeInChat: online
    };

    const newUser = {
        id: userProfile.id,
        conversations: newConversations
    };

    yield call(set, "User", newUser);
}

export default function* chatProfileSaga() {
    yield all([
        takeEvery(FETCH_CHAT_USER_PROFILE.REQUEST, fetchChatUserProfileRequest),
        takeLatest(
            CURRENT_CHAT_USER_PROFILE_CHANNEL.CREATE,
            createCurrentChatUserProfileChannelRequest
        ),
        takeLeading(SET_CHAT_USER_ONLINE_STATUS, setChatUserOnlineStatusRequest)
    ]);
}
