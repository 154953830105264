import React from "react";
import { colors } from "src/constants";
import styled from "styled-components";

const Wrapper = styled.h4`
    font-size: 12px;
    font-weight: 700;
    color: ${colors.gray2};
    text-align: left;
    padding: 0px;
    margin: 0px;
`;

const ModalHeading = ({
    text,
    className
}: {
    text: string;
    className?: string;
}) => {
    return <Wrapper className={className}>{text}</Wrapper>;
};

export default ModalHeading;
