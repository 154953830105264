import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchTag from "src/components/SearchTag";
import { getPlacements } from "src/campaigns/placements/selectors";
import { setMediaFilters } from "src/pagination/media/actions";
import { selectedPlacementsDisplayText } from "src/util";
import { selectMediaFilters } from "../selectors";

const MediaPlacementTags = () => {
    const dispatch = useDispatch();
    const placements = useSelector(getPlacements);
    const filters = useSelector(selectMediaFilters);
    const displayText = selectedPlacementsDisplayText(
        filters.placements,
        placements.length
    );

    function clearPlacements() {
        dispatch(setMediaFilters({ ...filters, placements: {} }));
    }

    if (displayText === "All Placements") return null;
    return (
        <SearchTag
            color="black"
            iconSrc="/tag-black-close-icon.svg"
            text={displayText}
            onClick={clearPlacements}
        />
    );
};

export default MediaPlacementTags;
