interface Route {
    main: string;
    create: string;
    edit: string;
}

export const createChallengeRoute =
    "/marketplace/your-challenges/create-challenge";

export const createOfferWizardRoute =
    "/marketplace/challenge-tools/create-offer";

export const instructionSetsRoutes: Route = {
    main: "/marketplace/challenge-tools/instruction-sets",
    create:
        "/marketplace/challenge-tools/instruction-sets/create-instruction-set",
    edit: "/marketplace/challenge-tools/instruction-sets/edit-instruction-set"
};

export const creatorGroupsRoutes: Route = {
    main: "/marketplace/challenge-tools/creator-groups",
    create: "/marketplace/challenge-tools/creator-groups/create-new-group",
    edit: "/marketplace/challenge-tools/creator-groups/edit-group"
};

export const paymentSetsRoutes: Route = {
    main: "/marketplace/challenge-tools/payment-sets",
    create: "/marketplace/challenge-tools/payment-sets/create-new-payment-set",
    edit: "/marketplace/challenge-tools/payment-sets/edit-payment-set"
};
