import React from "react";
import { Channel } from "../../../types";
import ActionsModal, { Action } from "../../../../modals/ActionsModal";
import { clearModal } from "../../../../modals/actions";
import { connect } from "react-redux";
import {
    banOptionsModal,
    muteOptionsModal,
    reportOptionsModal
} from "../../../actions";

interface Props {
    currentUser: any;
    channel: Channel;
    mutedUsers: any[];
    bannedUsers: any[];
    muteBanRefresh: VoidFunction;
    clearModal: VoidFunction;
    banOptionsModal: VoidFunction;
    muteOptionsModal: VoidFunction;
    reportOptionsModal: VoidFunction;
}

const AdminActionsModal = ({
    currentUser,
    channel,
    mutedUsers,
    bannedUsers,
    muteBanRefresh,
    clearModal,
    banOptionsModal,
    muteOptionsModal,
    reportOptionsModal
}: Props) => {
    const sendBirdData = channel.sendBirdData;
    const isMuted = mutedUsers.some(user => {
        return user.userId === currentUser.userId;
    });
    const isBanned = bannedUsers.some(user => {
        return user.userId === currentUser.userId;
    });

    const makeAdmin: Action = {
        text: "Make Admin",
        callback: () => {
            sendBirdData.addOperators(
                [currentUser.userId],
                (_: any, error: any) => {
                    if (error) {
                        window.alert(error);
                    } else {
                        clearModal();
                    }
                }
            );
        }
    };

    const mute: Action = {
        text: "Mute",
        callback: muteOptionsModal
    };

    const unMute: Action = {
        text: "Unmute",
        callback: () => {
            sendBirdData.unmuteUserWithUserId(
                currentUser.userId,
                (_: any, error: any) => {
                    if (error) {
                        window.alert(error);
                    } else {
                        clearModal();
                        muteBanRefresh();
                    }
                }
            );
        }
    };

    const ban: Action = {
        text: "Ban",
        callback: banOptionsModal
    };

    const unBan: Action = {
        text: "Unban",
        callback: () => {
            sendBirdData.unbanUserWithUserId(
                currentUser.userId,
                (_: any, error: any) => {
                    if (error) {
                        window.alert(error);
                    } else {
                        clearModal();
                        muteBanRefresh();
                    }
                }
            );
        }
    };

    const report: Action = {
        text: "Report",
        callback: reportOptionsModal,
        negative: true
    };

    if (isBanned) {
        return <ActionsModal actions={[unBan, report]} />;
    } else if (isMuted) {
        return <ActionsModal actions={[unMute, ban, report]} />;
    } else {
        return <ActionsModal actions={[makeAdmin, mute, ban, report]} />;
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal()),
    banOptionsModal: () => dispatch(banOptionsModal()),
    muteOptionsModal: () => dispatch(muteOptionsModal()),
    reportOptionsModal: () => dispatch(reportOptionsModal())
});

export default connect(null, mapDispatchToProps)(AdminActionsModal);
