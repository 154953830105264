import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { sizes } from "src/constants";
import styled from "styled-components";

const IntersectionObserver = styled.div`
    flex-shrink: 0;
    width: 1px;
    height: ${sizes.mediaItemHeight}px;
`;

interface Props {
    maxMediaCount: number;
    maxLoadedMedia: number;
    incrementMaxLoadedMedia: () => void;
}

const MediaCarouselIncrementor = (props: Props) => {
    const { maxMediaCount, maxLoadedMedia, incrementMaxLoadedMedia } = props;

    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView && maxMediaCount > maxLoadedMedia) {
            incrementMaxLoadedMedia();
        }
    }, [inView]);

    return <IntersectionObserver ref={ref} />;
};

export default MediaCarouselIncrementor;
