import React, { useState } from "react";
import { goBack } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FlexContent } from "../../components/CommonStyles";
import { ReactComponent as Elipsis } from "../../components/icons/ellipsis-button.svg";
import FullscreenLayout from "../../components/layout/FullscreenLayout";
import NavButton from "../../components/NavButton";
import SimpleNavbar from "../../components/SimpleNavbar";
import AccountCell from "../accounts/components/AccountCell";
import { OutlineTextButton } from "../components/Buttons";
import RateFormItem from "./RateFormItem";
import OfferPricingModal, {
    pricingFinished,
    PricingParams
} from "./modals/OfferPricingModal";
import { AnimatePresence } from "framer-motion";
import OfferTypeModalController, {
    offerTypeFinished,
    OfferTypeParams
} from "./modals/OfferTypeModalController";
import { formatNumber } from "../../util";
import AccountSelectionModal from "./modals/AccountSelectionModal";
import { selectAccount } from "../accounts/selectors";
import { GlobalState } from "../../reducers";
import { useEffect } from "react";
import { useLocation } from "react-router";
import RateFormConfirmButton from "./RateFormConfirmButton";
import RateFormSection from "./RateFormSection";
import OfferTypeItem from "./OfferTypeItem";
import useInitialParams from "./hooks/useInitialParams";
import { deletePublisherOffer } from "./actions";
import RateActionsModal from "./modals/RateActionsModal";

const Main = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const EditButton = styled(OutlineTextButton)`
    font-size: 12px;
    align-self: flex-start;
`;

const EditElipsis = styled(Elipsis)`
    width: 24px;
    height: 24px;
`;

enum RateModal {
    account = "account",
    offerType = "offerType",
    pricing = "pricing",
    actions = "actions"
}

interface Props {
    edit?: true;
    publisherOfferId: number | null;
}

const RateForm = ({ edit, publisherOfferId }: Props) => {
    const {
        initialPricing,
        initialOfferType,
        initialAccountId
    } = useInitialParams(publisherOfferId);

    const dispatch = useDispatch();
    const location = useLocation();
    const [modal, setModal] = useState<null | RateModal>(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [pricing, setPricing] = useState<PricingParams>(initialPricing);
    const [offerType, setOfferType] = useState<OfferTypeParams>(
        initialOfferType
    );
    const [accountId, setAccountId] = useState<null | number>(
        initialAccountId ||
            parseInt(
                new URLSearchParams(location.search).get("socialAccount") || ""
            ) ||
            null
    );
    const selectedAccount = useSelector((state: GlobalState) =>
        selectAccount(state, accountId)
    );

    useEffect(() => {
        setOfferType(initialOfferType);
    }, [selectedAccount?.platform]);

    return (
        <>
            <FullscreenLayout>
                <Main>
                    <SimpleNavbar
                        title={edit ? "EDIT RATE" : "ADD RATE"}
                        left={
                            <NavButton
                                back
                                onClick={() => dispatch(goBack())}
                            />
                        }
                        right={
                            edit && (
                                <EditElipsis
                                    onClick={() => {
                                        setModal(RateModal.actions);
                                    }}
                                    fill="#C4C4C4"
                                />
                            )
                        }
                    />
                    <FlexContent>
                        <RateFormSection
                            title="Account(s)"
                            description="Which account is this rate for?"
                            finished={!!selectedAccount}
                            item={
                                <AccountCell
                                    account={selectedAccount}
                                    onClick={() =>
                                        !edit && setModal(RateModal.account)
                                    }
                                    action={
                                        edit ? (
                                            <div />
                                        ) : (
                                            <EditButton>Edit</EditButton>
                                        )
                                    }
                                />
                            }
                            create="Add Account"
                            onCreate={() => setModal(RateModal.account)}
                        />
                        <RateFormSection
                            title="Offer Type"
                            description="What service are you offering?"
                            finished={offerTypeFinished(offerType)}
                            item={
                                <OfferTypeItem
                                    offerType={offerType}
                                    edit={() => {
                                        setOfferType(initialOfferType);
                                        setModal(RateModal.offerType);
                                    }}
                                />
                            }
                            create="Add Offer Type"
                            onCreate={() => setModal(RateModal.offerType)}
                        />
                        <RateFormSection
                            title="Pricing"
                            description="How much does it cost?"
                            finished={pricingFinished(pricing)}
                            item={
                                <RateFormItem
                                    title={`$${formatNumber(
                                        pricing.amount?.toFixed(2) || 0
                                    )}`}
                                    description={
                                        pricing.paymentType ||
                                        pricing.customType
                                    }
                                    edit={() => {
                                        setPricing(initialPricing);
                                        setModal(RateModal.pricing);
                                    }}
                                />
                            }
                            create="Add Pricing"
                            onCreate={() => setModal(RateModal.pricing)}
                        />
                    </FlexContent>
                    <RateFormConfirmButton
                        offerType={offerType}
                        pricing={pricing}
                        accountId={accountId}
                        edit={edit}
                        editId={publisherOfferId}
                        hasChanges={hasChanges}
                    />
                </Main>
            </FullscreenLayout>
            <AnimatePresence>
                {modal === RateModal.pricing && (
                    <OfferPricingModal
                        pricing={pricing}
                        setPricing={pricing => {
                            setPricing(pricing);
                            setHasChanges(true);
                        }}
                        closeModal={() => setModal(null)}
                    />
                )}
                {modal === RateModal.offerType && (
                    <OfferTypeModalController
                        closeModal={() => setModal(null)}
                        offerType={offerType}
                        setOfferType={offerType => {
                            setOfferType(offerType);
                            setHasChanges(true);
                        }}
                        platform={selectedAccount?.platform || ""}
                    />
                )}
                {modal === RateModal.account && (
                    <AccountSelectionModal
                        closeModal={() => setModal(null)}
                        accountId={accountId}
                        setAccountId={accountId => {
                            setAccountId(accountId);
                            setHasChanges(true);
                        }}
                    />
                )}
            </AnimatePresence>
            {modal === RateModal.actions && (
                <RateActionsModal
                    closeModal={() => setModal(null)}
                    deleteOffer={() => {
                        if (publisherOfferId && accountId)
                            dispatch(
                                deletePublisherOffer(
                                    publisherOfferId,
                                    accountId
                                )
                            );
                    }}
                />
            )}
        </>
    );
};

export default RateForm;
