import React from "react";
import styled from "styled-components";
import { Caption } from "../../types";
import { BorderlessButton } from "../../../profile/components/Buttons";
import { colors } from "../../../constants";
import CopyToClipboard from "react-copy-to-clipboard";
import OutlineContainer from "../../../profile/settings/components/OutlineContainer";
import { addNotification } from "../../../ui/notifications/actions";
import { connect } from "react-redux";

const List = styled.div`
    padding: 0 18px 16px 18px;

    & > * ~ * {
        margin-top: 10px;
    }
`;

const CaptionText = styled.div`
    padding: 18px;
    font-size: 12px;
    line-height: 14.32px;
    text-align: left;
    border-bottom: 1px solid ${colors.mediumTeal};
`;

const CopyButton = styled(BorderlessButton)`
    height: 48px;
    width: 100%;
`;

interface Props {
    captions: Caption[];
    addNotification: (text: string) => void;
}

const CaptionList = ({ captions, addNotification }: Props) => (
    <List>
        {captions.map(caption => (
            <OutlineContainer key={caption.id}>
                <CaptionText>{caption.text}</CaptionText>
                <CopyToClipboard
                    text={caption.text}
                    onCopy={() => addNotification("Copied caption!")}
                >
                    <CopyButton>Copy Caption</CopyButton>
                </CopyToClipboard>
            </OutlineContainer>
        ))}
    </List>
);

const mapDispatchToProps = (dispatch: any) => ({
    addNotification: (text: string) => dispatch(addNotification(text))
});

export default connect(null, mapDispatchToProps)(CaptionList);
