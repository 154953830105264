import { AnimatePresence } from "framer-motion";
import React from "react";
import FullScreenModal from "src/modals/FullScreenModal";
import { ModalFilterProps, ModalProps } from "../utils/props";
import AnalyticsCampaignMediaScreen from "src/analytics/AnalyticsCampaignMediaScreen";
import { AnalyticsFullscreenState, AnalyticsScrollTo } from "../AnalyticsMain";

interface Props extends ModalProps, ModalFilterProps {
    dayUseCampaigns: boolean;
    daySelectedCampaigns: Set<string>;
    daySelectedMedia: Set<string>;
    setDaySelectedCampaigns: (campaigns: Set<string>) => void;
    setDaySelectedMedia: (media: Set<string>) => void;
}

const EarningsByDayFilterModal = ({
    open,
    closeModal,
    showMore,
    setScrollTo,
    setFullscreenState,
    parsedData,
    dayUseCampaigns,
    daySelectedCampaigns,
    daySelectedMedia,
    setDaySelectedCampaigns,
    setDaySelectedMedia
}: Props) => {
    return (
        <AnimatePresence>
            {open && (
                <FullScreenModal closeModal={closeModal}>
                    <AnalyticsCampaignMediaScreen
                        parsedData={parsedData}
                        initialSelected={
                            dayUseCampaigns
                                ? daySelectedCampaigns
                                : daySelectedMedia
                        }
                        onCancelTapped={() => {
                            setScrollTo(AnalyticsScrollTo.earningsByDay);
                            showMore
                                ? setFullscreenState(
                                      AnalyticsFullscreenState.earningsByDayMore
                                  )
                                : setFullscreenState(
                                      AnalyticsFullscreenState.default
                                  );
                        }}
                        onDoneTapped={selectedItems => {
                            dayUseCampaigns
                                ? setDaySelectedCampaigns(selectedItems)
                                : setDaySelectedMedia(selectedItems);
                            setScrollTo(AnalyticsScrollTo.earningsByDay);
                            showMore
                                ? setFullscreenState(
                                      AnalyticsFullscreenState.earningsByDayMore
                                  )
                                : setFullscreenState(
                                      AnalyticsFullscreenState.default
                                  );
                        }}
                    />
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default EarningsByDayFilterModal;
