import React from "react";
import styled from "styled-components";
import { WeeklyLeaderboardItem } from "../../types";
import LeaderboardPhoto from "../../../components/LeaderboardPhoto";
import { colors, fonts } from "../../../constants";
import CoinText from "./CoinText";

const Main = styled.div`
    display: flex;
    justify-content: space-around;
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
`;

const Item = styled.div`
    width: 90px;
    cursor: pointer;
`;

const PhotoContainer = styled.div`
    height: 114px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    justify-content: flex-end;
`;

const Title = styled.div`
    margin-top: 4px;
`;

const Bonus = styled.div`
    font-size: 12px;
    font-weight: normal;
    margin-top: 8px;
    color: ${colors.secondaryGray};
`;

interface Props {
    weeklyLeaderboard: WeeklyLeaderboardItem[];
    onSelect?: (publisher: WeeklyLeaderboardItem) => void;
}

const WeeklyTopThree = ({ weeklyLeaderboard, onSelect }: Props) => {
    const [first, second, third] = weeklyLeaderboard;

    return (
        <Main>
            <Item
                onClick={() => {
                    onSelect && onSelect(first);
                }}
            >
                <PhotoContainer>
                    <LeaderboardPhoto
                        profilePhotoUrl={first.profilePhotoUrl}
                        place={1}
                        type={first.type}
                    />
                </PhotoContainer>
                <Title>1st Place</Title>
                <CoinText amount={first.earnings} />
                <Bonus>{first.bonusPercentage}% bonus</Bonus>
            </Item>
            <Item
                onClick={() => {
                    onSelect && onSelect(second);
                }}
            >
                <PhotoContainer>
                    <LeaderboardPhoto
                        profilePhotoUrl={second.profilePhotoUrl}
                        type={third.type}
                        place={2}
                    />
                </PhotoContainer>
                <Title>2nd Place</Title>
                <CoinText amount={second.earnings} />
                <Bonus>{second.bonusPercentage}% bonus</Bonus>
            </Item>
            <Item
                onClick={() => {
                    onSelect && onSelect(third);
                }}
            >
                <PhotoContainer>
                    <LeaderboardPhoto
                        profilePhotoUrl={third.profilePhotoUrl}
                        type={third.type}
                        place={3}
                    />
                </PhotoContainer>
                <Title>3rd Place</Title>
                <CoinText amount={third.earnings} />
                <Bonus>{third.bonusPercentage}% bonus</Bonus>
            </Item>
        </Main>
    );
};

export default WeeklyTopThree;
