import React, { useMemo } from "react";
import { colors } from "src/constants";
import styled from "styled-components";
import ModalIconBtn from "./ModalIconBtn";
import { ReactComponent as Ellipsis } from "src/components/icons/ellipsis.svg";
const Icon = styled(Ellipsis)<{ size?: number }>`
    fill: ${colors.gray2};
    width: ${props => props.size ?? 16}px;
    -webkit-user-drag: none;
`;
const MoreBtn = ({
    className,
    refProp,
    onClick,
    size = 26
}: {
    className?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
    refProp?: React.RefObject<any>;
    size?: number;
}) => {
    return (
        <ModalIconBtn
            refProp={refProp}
            onClick={onClick}
            className={className}
            size={size}
        >
            <Icon size={Math.ceil(size * 0.615)} />
        </ModalIconBtn>
    );
};

export default MoreBtn;
