import { NormalizedAction } from "../../../types";
import { DirectBuyStep } from "./types";
import { ADD_DIRECT_BUY_ENTITIES, UPDATE_DIRECT_BUY_STEP } from "../../actions";

export interface DirectBuyStepsEntitiesState {
    [id: number]: DirectBuyStep;
}

const initialDirectBuyStepsState: DirectBuyStepsEntitiesState = {};

const directBuyStepsEntitiesReducer = (
    state = initialDirectBuyStepsState,
    action: NormalizedAction
): DirectBuyStepsEntitiesState => {
    switch (action.type) {
        case ADD_DIRECT_BUY_ENTITIES:
            return { ...state, ...action.response.entities.directBuyStep };
        case UPDATE_DIRECT_BUY_STEP:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default directBuyStepsEntitiesReducer;
