import { push } from "connected-react-router";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import withAccordion from "src/campaigns/details/accordions/withAccordion";
import { SubmissionReport } from "src/marketplace/reports/types";
import { OutlineButton } from "src/profile/components/Buttons";
import styled from "styled-components";
import OpenBuyLeaderboardCarouselModal from "./OpenBuyLeaderboardCarouselModal";
import OpenBuyLeaderboardCell from "./OpenBuyLeaderboardCell";

const Main = styled.div`
    display: flex;
    flex-direction: column;
`;

const LinkWrapper = styled.div`
    margin: 0 18px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
`;

const Link = styled(OutlineButton)`
    height: 40px;
    font-size: 14px;
`;

const ListWrapper = styled.div`
    overflow-x: auto;
    padding-bottom: 10px;
`;

const List = styled.div`
    margin-left: 18px;
    display: flex;
    gap: 12px;
`;

const CellActionWrapper = styled.div``;

interface Props {
    openBuyId: number;
    leaderboard: SubmissionReport[] | null;
}

const OpenBuyLeaderboardAccordion = ({ openBuyId, leaderboard }: Props) => {
    const dispatch = useDispatch();
    const [modalReportIds, setModalReportIds] = useState<number[]>([]);

    const handleCellClick = (index: number) => {
        if (!leaderboard) return;
        const newModalReportIds = [
            ...leaderboard.slice(index, leaderboard.length),
            ...leaderboard.slice(0, index)
        ].map(submissionReport => submissionReport.id);
        setModalReportIds(newModalReportIds);
    };
    return (
        <Main>
            <ListWrapper>
                <List>
                    {leaderboard?.map((report, index) => (
                        <CellActionWrapper
                            key={report.id}
                            onClick={() => handleCellClick(index)}
                        >
                            <OpenBuyLeaderboardCell
                                report={report}
                                place={index + 1}
                            />
                        </CellActionWrapper>
                    ))}
                </List>
            </ListWrapper>
            {!!modalReportIds.length && (
                <OpenBuyLeaderboardCarouselModal
                    openBuyId={openBuyId}
                    modalReportIds={modalReportIds}
                    closeModal={() => setModalReportIds([])}
                />
            )}
        </Main>
    );
};
export default withAccordion(OpenBuyLeaderboardAccordion);
