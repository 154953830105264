import React, { ChangeEvent } from "react";
import styled from "styled-components";

const Main = styled.div`
    position: relative;
`;

const Icon = styled.img`
    display: block;
`;

const Overlay = styled.label`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    cursor: pointer;
`;

interface Props {
    uploadCallback: (e: ChangeEvent<HTMLInputElement>) => void;
}

const UploadButton = ({ uploadCallback }: Props) => {
    return (
        <Main>
            <Icon src="/chat-add-button.svg" />
            <Overlay>
                <input
                    type="file"
                    accept="image/png, image/jpeg, video/mp4, video/quicktime"
                    style={{ display: "none" }}
                    onChange={uploadCallback}
                />
            </Overlay>
        </Main>
    );
};

export default UploadButton;
