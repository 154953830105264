import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch } from "react-router";

import CampaignHeader from "src/campaigns/details/components/CampaignHeader";
import DetailsMain from "src/campaigns/details/components/DetailsMain";
import useAuthorization from "src/auth/hooks/useAuthorization";
import { CampaignAction } from "src/auth/roles";

import * as Styled from "src/campaigns/details/Details.styled";
import { Campaign } from "src/campaigns/types";
import Navbar from "src/components/Navbar";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";

export enum DetailsTabState {
    promote,
    payment,
    details
}

interface Props {
    currentCampaign?: Campaign;
}

const CampaignDetails = ({ currentCampaign }: Props) => {
    const [details] = useAuthorization([CampaignAction.details]);
    const [scroll, toggleScroll] = useState(false);

    const bottomRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scroll) {
            bottomRef?.current?.scrollIntoView({
                behavior: "smooth"
            });
            toggleScroll(false);
        }
    }, [scroll]);

    const scrollToBottom = () => {
        toggleScroll(true);
    };

    const profile = useSelector((state: GlobalState) => state.profile.profile);

    return (
        <>
            {!details && <Redirect to="/onboarding" />}
            {!profile?.agreed_rules && <Redirect to="/rules" />}

            <Navbar back marginBottom={10} title="CAMPAIGN" />

            <CampaignHeader
                currentCampaign={currentCampaign}
                scrollToBottom={scrollToBottom}
            />
            <Switch>
                <Route
                    path="/campaign/:campaignId/payment"
                    render={() => (
                        <DetailsMain currentTab={DetailsTabState.payment} />
                    )}
                />

                <Route
                    path="/campaign/:campaignId/details"
                    render={() => (
                        <DetailsMain currentTab={DetailsTabState.details} />
                    )}
                />

                <Route
                    path="/campaign/:campaignId"
                    render={() => (
                        <DetailsMain currentTab={DetailsTabState.promote} />
                    )}
                />
            </Switch>
            <Styled.BottomRef ref={bottomRef} />
        </>
    );
};

export default CampaignDetails;
