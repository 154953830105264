import { Payment } from "src/types/Payment";

// Payment Type ---------------------------------------------------------------
export interface PaymentType {
    id: number;
    type: string;
}

// Criteria ---------------------------------------------------------------
export interface Criteria {
    id: number;
    type: string;
    allowedValues: string[];
}

// Payment Bucket ---------------------------------------------------------------

export enum PaymentConjunction {
    and = "AND",
    orGreater = "OR GREATER",
    orLesser = "OR LESSER"
}

export interface PaymentSetPayment {
    id?: number;
    paymentSetBucketId?: number;
    paymentId: number;
    amount: number;
    viewsMinimum?: number | null;
    Payment?: Payment;
}

export interface PaymentSetCriteria {
    id?: number;
    paymentSetBucketId?: number;
    criteriaId: number;
    criteriaValue: string;
}
export interface PaymentSetBucket {
    id?: number;
    paymentConjunction: PaymentConjunction;
    PaymentSetPayments: PaymentSetPayment[];
    PaymentSetCriteria?: PaymentSetCriteria[];
}

// Payment Set ---------------------------------------------------------------
export interface PaymentSet {
    id: number;
    publisherId?: number;
    name: string;
    PaymentSetBuckets: PaymentSetBucket[];
    created_at?: string;
    updated_at?: string;
    deleted_at?: string | null;
}

// Open Buy Payment ---------------------------------------------------------------
export interface OpenBuyPayment {
    openBuyId: number;
    instructionStepId: number;
    creatorGroupId: number | null;
    PaymentSetBucket: PaymentSetBucket;
}

// Pending Open Buy Payment ---------------------------------------------------------------
export interface PendingOpenBuyPaymentsGroup {
    openBuyId: number;
    instructionStepId: number;
    paymentConjunction: PaymentConjunction;
    base: PendingOpenBuyPaymentItem[];
    creatorSpecific: {
        [creatorGroupId: number]: PendingOpenBuyPaymentItem[];
    };
    payoutTiming?: InstructionStepPayoutTiming;
}

export enum InstructionStepPayoutTiming {
    onApproval = "on_approval",
    realtime = "realtime"
}

export interface PendingOpenBuyPaymentItem {
    paymentId: number;
    amount: string;
    viewsMinimum: string;
}

// Abstract Payment Type ---------------------------------------------------------------

export enum AbstractPaymentType {
    dollarsPerK = "$ per K",
    flatRate = "Flat Fee",
    viewThresholds = "View Thresholds"
}

export const allAbstractPaymentTypes = [
    AbstractPaymentType.dollarsPerK,
    AbstractPaymentType.flatRate,
    AbstractPaymentType.viewThresholds
];

// Local Creator Group Type

export interface CreatorGroupPublisher {
    publisherId: number;
    publisherName: string;
}
