import React, { useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../constants";
import TextareaAutosize from "react-textarea-autosize";
import { post } from "../../../../Api";
import { updateDirectBuyStep } from "../../../actions";
import { addNotification } from "../../../../ui/notifications/actions";
import { errorToString } from "../../../../util";
import { DirectBuyStep } from "../../../modules/direct-buy-step/types";
import SpinLoader from "../../../../components/SpinLoader";
import { useDispatch } from "react-redux";

const Main = styled.div`
    position: relative;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    overflow: hidden;
`;

const SpinnerContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30px;
    display: flex;
    align-items: center;
`;

interface Props {
    step: DirectBuyStep;
}

const DirectBuyDetailsTextSubmissionCell = ({ step }: Props) => {
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const [textSubmission, setTextSubmission] = useState(
        step.textSubmission || ""
    );
    const [uploading, setUploading] = useState(false);

    return (
        <Main>
            <TextareaAutosize
                ref={inputRef}
                placeholder={
                    step.textSubmissionPlaceholder || "Submit your response..."
                }
                value={textSubmission}
                onChange={e => {
                    const element = e.currentTarget as HTMLTextAreaElement;
                    setTextSubmission(element.value);
                }}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        inputRef && inputRef.current && inputRef.current.blur();
                    }
                }}
                onBlur={() => {
                    setUploading(true);
                    post(`/v1/directBuyStep/${step.id}/textSubmission`, {
                        textSubmission
                    })
                        .then(({ data }) => {
                            dispatch(updateDirectBuyStep(data.data));
                            setUploading(false);
                        })
                        .catch(error => {
                            dispatch(
                                addNotification(
                                    `Unable to submit response: ${errorToString(
                                        error
                                    )}`,
                                    colors.primaryRed
                                )
                            );
                            setUploading(false);
                        });
                }}
                style={{
                    display: "block",
                    width: "100%",
                    resize: "none",
                    border: "none",
                    outline: "none",
                    padding: uploading ? "12px 36px 12px 12px" : "12px",
                    fontSize: "14px",
                    lineHeight: "18px"
                }}
            />
            {uploading && (
                <SpinnerContainer>
                    <SpinLoader size={18} color={colors.primaryBlue} />
                </SpinnerContainer>
            )}
        </Main>
    );
};

export default DirectBuyDetailsTextSubmissionCell;
