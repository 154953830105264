import { Action } from "src/types";
import {
    MARKETPLACE_SUBMISSION_BADGES,
    SET_MARKETPLACE_OFFER_CREATION_MODAL_OPEN,
    SET_MARKETPLACE_OFFER_DETAILS_MODAL_OPEN,
    TOGGLE_LEADERBOARD_EDITOR_OPEN
} from "./actions";

export interface MarketplaceBadges {
    pending: number;
    flagged: number;
}

export interface MarketplaceUIState {
    marketplaceOfferCreationModalOpen: boolean;
    marketplaceOfferDetailsModalOpen: boolean;
    marketplaceBadges: MarketplaceBadges;
    leaderboardEditorOpen: null | number;
}

const initialMarketplaceUIState: MarketplaceUIState = {
    marketplaceOfferCreationModalOpen: false,
    marketplaceOfferDetailsModalOpen: false,
    marketplaceBadges: { pending: 0, flagged: 0 },
    leaderboardEditorOpen: null
};

function marketplaceUIReducer(
    state = initialMarketplaceUIState,
    action: Action
): MarketplaceUIState {
    switch (action.type) {
        case SET_MARKETPLACE_OFFER_CREATION_MODAL_OPEN:
            return {
                ...state,
                marketplaceOfferCreationModalOpen: action.payload.open
            };
        case SET_MARKETPLACE_OFFER_DETAILS_MODAL_OPEN:
            return {
                ...state,
                marketplaceOfferDetailsModalOpen: action.payload.open
            };
        case MARKETPLACE_SUBMISSION_BADGES.SET:
            return { ...state, marketplaceBadges: action.payload };
        case MARKETPLACE_SUBMISSION_BADGES.DECREMENT:
            let badges = { ...state.marketplaceBadges };
            if (action.payload.pending) badges.pending--;
            if (action.payload.flagged) badges.flagged--;
            return { ...state, marketplaceBadges: badges };

        case TOGGLE_LEADERBOARD_EDITOR_OPEN:
            return {
                ...state,
                leaderboardEditorOpen: action.payload
            };
        default:
            return state;
    }
}

export default marketplaceUIReducer;
