import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { colors } from "src/constants";
import { EventInput } from "src/utils/types/form";
import styled from "styled-components";
import { PendingOpenBuyPaymentItem } from "../types";
import { PaymentInput } from "./SingleInputPayment";
import { shortenNumber } from "../../../util";
import { updatePendingOpenBuyPaymentItem } from "../open-buy-payments/actions";

const Payout = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    padding: 4.5px 6px;
    background: ${colors.black};
    color: ${colors.white};
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    justify-content: center;
    gap: 4px;
    margin-bottom: 8px;
    @media (max-width: 700px) {
        width: 40px;
    }
`;

const ViewsMinimum = styled(Payout)`
    margin: 8px 0px 0px;
`;

const Dollar = styled.img`
    color: ${colors.white};
    @media (max-width: 700px) {
        display: none;
    }
`;

const Divider = styled.div<{ placement: number }>`
    width: 2px;
    height: 12px;
    background: ${colors.black};
    z-index: 80;
`;

const Container = styled.div<{ amount: number }>`
    display: flex;
    align-items: center;
    position: absolute;
    flex-direction: column;
    font-size: 9px;
    font-weight: 500;
    height: 80px;
    margin-left: calc(${props => props.amount}% - 20px);
    margin-top: -32px;
    @media (max-width: 700px) {
        margin-left: calc(${props => props.amount}% - 15px);
    }
`;

const Eye = styled.img`
    width: 13.36px;
    height: 7.19px;
`;

const Input = styled(PaymentInput)`
    color: ${props =>
        props.hasInput ? `${colors.white}` : `${colors.primaryGray}`};
`;

interface Props {
    index: number;
    openBuyId: number;
    instructionStepId: number;
    creatorGroupId: number | null;
    pendingOpenBuyPaymentItem: PendingOpenBuyPaymentItem;
    paymentsLength: number;
}

const ViewCount = ({
    index,
    openBuyId,
    instructionStepId,
    creatorGroupId,
    pendingOpenBuyPaymentItem,
    paymentsLength
}: Props) => {
    const dispatch = useDispatch();

    // Local State ---------------------------------------------------
    const [amountWidth, setAmountWidth] = useState("");
    const [viewsWidth, setViewsWidth] = useState("");
    const [viewsInputSelected, setViewsInputSelected] = useState(false);

    // Local Functions ---------------------------------------------------
    const getBarSpacing = (index: number, length: number) => {
        let width = (index / (length - 1)) * 100;
        return width > 3 ? width : 3;
    };

    const handleInputChange = (event: EventInput) => {
        const target = event.target;
        const { value, name } = target;

        if (
            name === "amount" &&
            !/^\d*\.?\d*$/.test(event.currentTarget.value)
        ) {
            return;
        }

        if (
            name === "viewsMinimum" &&
            !/^\d*$/.test(event.currentTarget.value)
        ) {
            return;
        }

        dispatch(
            updatePendingOpenBuyPaymentItem({
                openBuyId,
                instructionStepId,
                creatorGroupId,
                paymentIndex: index,
                payment: {
                    ...pendingOpenBuyPaymentItem,
                    [name]: value
                }
            })
        );
    };

    const adjustAmountInputSize = () => {
        const amountInput = document.getElementById(
            "amountInput"
        ) as HTMLInputElement;

        if (amountInput && pendingOpenBuyPaymentItem.amount.length < 1) {
            const newWidth = amountInput.placeholder.length + 2 + "ch";
            setAmountWidth(newWidth);
        } else {
            const newWidth = pendingOpenBuyPaymentItem.amount.length + 1 + "ch";
            setAmountWidth(newWidth);
        }
    };

    const viewsInputValue = viewsInputSelected
        ? pendingOpenBuyPaymentItem.viewsMinimum
        : shortenNumber(pendingOpenBuyPaymentItem.viewsMinimum || "0");

    const adjustViewsInputSize = () => {
        const viewsInput = document.getElementById(
            "viewsInput"
        ) as HTMLInputElement;

        if (viewsInput && pendingOpenBuyPaymentItem.viewsMinimum.length < 1) {
            const newWidth = viewsInput.placeholder.length + 2 + "ch";
            setViewsWidth(newWidth);
        } else {
            const newWidth = viewsInputValue.length + 1 + "ch";
            setViewsWidth(newWidth);
        }
    };

    // Use Effects ---------------------------------------------------

    useEffect(() => {
        adjustAmountInputSize();
    }, [pendingOpenBuyPaymentItem.amount]);

    useEffect(() => {
        adjustViewsInputSize();
    }, [pendingOpenBuyPaymentItem.viewsMinimum, viewsInputSelected]);

    // Return JSX ---------------------------------------------------
    return (
        <Container amount={getBarSpacing(index, paymentsLength || 0)}>
            <Payout>
                <Dollar src={"/campaigns/dollar-sign.svg"} />
                <Input
                    type="text"
                    id="amountInput"
                    placeholder="0"
                    name="amount"
                    value={pendingOpenBuyPaymentItem.amount}
                    width={amountWidth}
                    onChange={handleInputChange}
                    hasInput={pendingOpenBuyPaymentItem.amount.length > 0}
                />
            </Payout>
            <Divider placement={getBarSpacing(index, paymentsLength || 0)} />
            <ViewsMinimum>
                <Input
                    type="text"
                    id="viewsInput"
                    placeholder="0"
                    name="viewsMinimum"
                    value={viewsInputValue}
                    width={viewsWidth}
                    onChange={handleInputChange}
                    hasInput={pendingOpenBuyPaymentItem.viewsMinimum.length > 0}
                    onFocus={() => {
                        setViewsInputSelected(true);
                    }}
                    onBlur={() => {
                        setViewsInputSelected(false);
                    }}
                />
                {` Views`}
            </ViewsMinimum>
        </Container>
    );
};

export default ViewCount;
