import { all, fork } from "redux-saga/effects";
import authSaga from "./auth/saga";
import accountsSaga from "./social-accounts/saga";
import campaignsSaga from "./campaigns/saga";
import mediaSaga from "./media/saga";
import profileSaga from "./profile/saga";
import analyticsSaga from "./analytics/saga";
import eventSaga from "./events/saga";
import buysSaga from "./buys/saga";
import notificationsSaga from "./notifications/saga";
import shoutoutsSaga from "./campaigns/shoutouts/saga";
import linksSaga from "./links/saga";
import publicProfilesSaga from "./public-profiles/saga";
import scriptsSaga from "./campaigns/scripts/saga";
import instructionsSetsSaga from "./marketplace/instructions-sets/saga.instructions-sets";
import instructionStepsSaga from "./marketplace/instructions-sets/saga.instruction-steps";
import PublicBuysSaga from "./buys/public/redux/saga";
import chatSaga from "./new-chat/saga";
import elasticSearchSaga from "./search/saga";
import creatorGroupsSaga from "./marketplace/creator-groups/saga.creator-groups";
import creatorSearchSaga from "./marketplace/creator-groups/saga.creator-search";
import socialAccountProfileSaga from "./social-accounts/profile/redux/social-account-profile.saga";
import offersSaga from "./marketplace/wizard/sagas.create-offer";
import openBuyEditSaga from "./marketplace/open-buys/saga";
import adminOpenBuysSaga from "./admin-tools/sagas.admin-tools";
import openBuyExtensionsSaga from "./admin-tools/challenges/challenge-extensions/saga";
import openBuyPaymentsSaga from "./marketplace/payment-sets/open-buy-payments/saga";

export default function* root() {
    yield all([
        fork(authSaga),
        fork(campaignsSaga),
        fork(mediaSaga),
        fork(accountsSaga),
        fork(socialAccountProfileSaga),
        fork(profileSaga),
        fork(analyticsSaga),
        fork(eventSaga),
        fork(buysSaga),
        fork(notificationsSaga),
        fork(shoutoutsSaga),
        fork(linksSaga),
        fork(publicProfilesSaga),
        fork(scriptsSaga),
        fork(instructionsSetsSaga),
        fork(instructionStepsSaga),
        fork(PublicBuysSaga),
        fork(chatSaga),
        fork(elasticSearchSaga),
        fork(creatorGroupsSaga),
        fork(creatorSearchSaga),
        fork(openBuyEditSaga),
        fork(offersSaga),
        // fork(marketplaceOpenBuysSaga)
        fork(adminOpenBuysSaga),
        fork(openBuyExtensionsSaga),
        fork(openBuyPaymentsSaga)
    ]);
}
