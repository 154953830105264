import { OldHighlight } from "src/campaigns/types";
import { Media } from "src/media/types";
import { Action } from "src/types";

// Modals ---------------------------------------------------------------

export interface ShoutoutScreenshot {
    url: string;
    fill_order_id: number;
    shoutout_id: number;
}

export const MODAL = {
    PLUGLINK: "MODAL_PLUGLINK",
    COUNTDOWN: "MODAL_COUNTDOWN",
    MEDIA_EMAIL: "MODAL_MEDIA_EMAIL",
    CANCEL: "MODAL_CANCEL",
    DELETE: "MODAL_DELETE",
    CUSTOM_REASON: "MODAL_CUSTOM_REASON",
    COUNTRIES: "MODAL_COUNTRIES",
    DOWNLOAD_TYPES: "MODAL_DOWNLOAD_TYPES"
};

export const countdownModal = (): Action => ({
    type: MODAL.COUNTDOWN,
    payload: null
});

export const mediaEmailModal = (): Action => ({
    type: MODAL.MEDIA_EMAIL,
    payload: null
});

export const cancelModal = (): Action => ({
    type: MODAL.CANCEL,
    payload: null
});

export const deleteModal = (currentScreenshot: ShoutoutScreenshot): Action => ({
    type: MODAL.DELETE,
    payload: { currentScreenshot }
});

export const customReasonModal = (currentMedia: Media): Action => ({
    type: MODAL.CUSTOM_REASON,
    payload: { currentMedia }
});

export const countriesModal = (countries: string[]): Action => ({
    type: MODAL.COUNTRIES,
    payload: { countries }
});

export const downloadTypesModal = (currentHighlight: OldHighlight): Action => ({
    type: MODAL.DOWNLOAD_TYPES,
    payload: { currentHighlight }
});

export const SET_ROUTE_MODAL_OPEN = "SET_ROUTE_MODAL_OPEN";

export const setRouteModalOpen = (open: boolean): Action => ({
    type: SET_ROUTE_MODAL_OPEN,
    payload: { open }
});

export const SET_ToS_MODAL_OPEN = "SET_ToS_MODAL_OPEN";

export const setSMSToSModalOpen = (open: boolean): Action => ({
    type: SET_ToS_MODAL_OPEN,
    payload: open
});
