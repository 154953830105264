import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { clearModal } from "./actions";
import { freezeBody, thawBody } from "../util";

const Parent = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default; //keep from inheriting cursor when modal lives in clickable
`;

const Child = styled.div<{ style: React.CSSProperties }>`
    width: 75vw;
    min-width: 300px;
    max-width: 375px;
    border-radius: 15px;
    background-color: white;
    cursor: default; //keep from inheriting cursor when modal lives in clickable
`;

const Modal = ({
    clearModal,
    children,
    onClick,
    style
}: {
    clearModal: () => void;
    children: any;
    onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
    style?: any;
}) => {
    useEffect(() => {
        freezeBody();

        return thawBody;
    }, []);

    return (
        <Parent onClick={onClick || clearModal}>
            <Child style={style} onClick={e => e.stopPropagation()}>
                {children}
            </Child>
        </Parent>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(null, mapDispatchToProps)(Modal);
