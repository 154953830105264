import styled from "styled-components";
import VerticallyCentered from "../../components/VerticallyCentered";
import { colors, fonts } from "../../constants";

export const Results = styled.div`
    text-align: left;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;

    & > * ~ * {
        margin-top: 10px;
    }
`;

export const List = styled.div``;

export const Row = styled(VerticallyCentered)`
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (pointer: fine) {
        margin-right: 10px;
        margin-left: 10px;
        padding: 8px;
        border-radius: 6px;
    }

    @media (pointer: coarse) {
        padding: 0px 18px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

export const Image = styled.img`
    width: 46px;
    height: 46px;
    margin-right: 12px;
`;

export const Title = styled.div`
    font-size: ${fonts.smallTitle}px;
    font-weight: 500;

    mark {
        padding: 0;
    }
`;

export const Subtitle = styled.div`
    font-size: 12px;
    color: ${colors.primaryGray};

    span {
        font-weight: 700;
    }
`;

export const Chevron = styled.div`
    margin-left: auto;
    color: ${colors.primaryGray};
    user-select: none;
    cursor: pointer;
    font-size: 8px;
`;
