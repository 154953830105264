import { push } from "connected-react-router";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ChevronRight } from "src/components/icons/exports";
import { breakpoints, colors, fonts } from "src/constants";
import { flex, flexRowCenter } from "src/utils/styles/snippets";
import styled from "styled-components";

const DesktopHeader = styled.section`
    ${flex("row", "space-between")};
    margin: 20px 0px 0px;

    @media (max-width: ${breakpoints.mobile}px) {
        display: none;
    }
`;

const Header = styled.div`
    ${flex("column")};
`;

const Title = styled.h2`
    margin: 0px;
    color: ${colors.black};
    font-size: ${fonts.title}px;
    font-weight: 500;
    text-align: left;
    line-height: 29px;
`;

const Subtitle = styled.span`
    display: block;
    color: ${colors.primaryGray};
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    text-align: left;
`;

const LearnMoreButton = styled(Link)`
    ${flexRowCenter()};
    padding: 8px 12px;
    height: max-content;
    color: ${colors.white};
    background-color: ${colors.primaryBlue};
    border: none;
    border-radius: 5px;
    font-size: ${fonts.smallTitle}px;
    font-weight: 600;
    transition: 0.1s ease-in;

    :hover {
        color: ${colors.white};
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }

    @media (max-width: ${breakpoints.mobile}px) {
        margin-top: 15px;
    }
`;

const ArrowContainer = styled.div`
    ${flexRowCenter()};
    padding-left: 8px;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        ${LearnMoreButton}:hover & {
            transform: translateX(4px);
        }
    }
`;

const MobileHeader = styled.img`
    display: none;
    width: 100%;
    margin-bottom: 5px;
    padding: 0px 15px;
    user-select: none;
    cursor: pointer;

    @media (max-width: ${breakpoints.mobile}px) {
        display: block;
    }
`;

const IntroBuysFlowStarter = () => {
    const dispatch = useDispatch();

    return (
        <>
            <DesktopHeader>
                <Header>
                    <Title>Introducing Personal Offers</Title>
                    <Subtitle>
                        A new way to handle direct buys and more!
                    </Subtitle>
                </Header>

                <LearnMoreButton to="/buys/direct/intro">
                    Learn More{" "}
                    <ArrowContainer>
                        <ChevronRight fill={colors.white} />
                    </ArrowContainer>
                </LearnMoreButton>
            </DesktopHeader>

            <MobileHeader
                src="/intro-buys/header.png"
                alt="Personal Offer Intro Mobile Header"
                onClick={() => dispatch(push("/buys/direct/intro"))}
            />
        </>
    );
};

export default IntroBuysFlowStarter;
