import { Action } from "src/types";

export const REFERRED_USERS_MODAL_OPEN = "REFERRED_USERS_MODAL_OPEN";

export function setReferredUserModal(open: boolean) {
    return {
        type: REFERRED_USERS_MODAL_OPEN,
        payload: open
    };
}

export const SHARE_REFERRAL_MODAL_OPEN = "SHARE_REFERRAL_MODAL_OPEN";

export function setShareReferralModal(open: boolean): Action {
    return {
        type: SHARE_REFERRAL_MODAL_OPEN,
        payload: open
    };
}
