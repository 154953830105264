import React from "react";
import { CustomLoading } from "src/components/SkeletonLoader";
import { colors } from "src/constants";
import styled from "styled-components";
const InnerContent = styled.div<{ noPadding?: boolean }>`
    border-radius: 8px;
    padding: ${props => (props.noPadding ? "0px" : "12px")};
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
`;
const Row = styled.div<{ rowHeight: number }>`
    height: ${props => props.rowHeight}px;
    width: 100%;
    padding: 12px 0px;
`;
const InnerTableLoader = ({
    resultsPerPage,
    rowHeight,
    noPadding
}: {
    resultsPerPage: number;
    rowHeight: number;
    noPadding?: boolean;
}) => {
    return (
        <InnerContent noPadding={noPadding}>
            <CustomLoading width="100%" height="50px" />
            {Array.from(Array(resultsPerPage).keys()).map((val, idx) => {
                return (
                    <Row key={"row-loading-" + idx} rowHeight={rowHeight}>
                        <CustomLoading width="100%" height="100%" />
                    </Row>
                );
            })}
        </InnerContent>
    );
};

export default InnerTableLoader;
