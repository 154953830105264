import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../modals/Modal";
import UpdateModal from "../../modals/UpdateModal";
import { storage } from "../../firebase/FirebaseConfig";
import { makeId } from "../../util";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
    margin-bottom: 15px;
`;

const Container = styled.div`
    position: relative;
    height: 250px;
    width: 250px;
    margin: 0 auto;
`;

const ProfilePhoto = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
`;

const Overlay = styled.label`
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 100%;
`;

interface Props {
    updatePhoto: (filename: string) => void;
    updatePhotoFailure: (error: string) => void;
}

const PhotoModal = ({ updatePhoto, updatePhotoFailure }: Props) => {
    const [photo, setPhoto] = useState(null);
    const [photoURL, setPhotoURL] = useState(
        "/profile_pic_blue/profile_pic_blue@3x.png"
    );

    function onSubmit() {
        if (photo) {
            const id = `${localStorage.getItem("userid")}_${makeId(50)}.jpg`;
            const ref = storage.ref().child(`profile_photos/${id}`);
            // @ts-ignore
            ref.put(photo)
                .then(() => {
                    updatePhoto(id);
                })
                .catch((error: string) => updatePhotoFailure(error));
        }
    }

    function photoUpload(e: any) {
        e.preventDefault();
        const file = e.currentTarget.files[0];

        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            if (typeof fileReader.result === "string") {
                setPhotoURL(fileReader.result);
                setPhoto(file);
            }
        };
        if (file && file.type === "image/jpeg") {
            fileReader.readAsDataURL(file);
        }
    }

    return (
        <Modal>
            <Main>
                <Title>Change Your Profile Photo</Title>
                <Container>
                    <ProfilePhoto onClick={photoUpload} src={photoURL} />
                    <Overlay>
                        <input
                            type={"file"}
                            accept={"image/jpeg"}
                            style={{ display: "none" }}
                            onChange={photoUpload}
                        />
                    </Overlay>
                </Container>
            </Main>
            <UpdateModal onUpdate={onSubmit} active={Boolean(photo)} />
        </Modal>
    );
};

export default PhotoModal;
