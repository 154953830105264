import React from "react";
import { motion } from "framer-motion";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import { ReactComponent as Check } from "src/components/icons/delivered-check.svg";
import { colors } from "src/constants";
import styled from "styled-components";

const Main = styled(motion.div)`
    ${FlexVerticallyCentered};
    justify-content: flex-end;
`;

const Text = styled.span`
    color: ${colors.primaryGray};
    font-size: 11px;
    line-height: 13px;
    margin-right: 5px;
`;

interface Props {}

const MessageDeliverTag = ({}: Props) => {
    return (
        <Main
            initial={{ opacity: 0, height: 13 }}
            animate={{ opacity: 1, height: 13 }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
        >
            <Text>Delivered</Text>
            <Check />
        </Main>
    );
};
export default MessageDeliverTag;
