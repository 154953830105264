import { NormalizedAction } from "src/types";
import { FETCH_OPEN_BUY_EXTENSIONS } from "./actions";
import { Extension } from "./ChallengeExtensions";

export interface OpenBuyExtensionsEntitiesState {
    [openBuyId: number]: { [publisherId: number]: Extension };
}

function openBuyExtensionsEntitiesReducer(
    state = {},
    action: NormalizedAction
): OpenBuyExtensionsEntitiesState {
    switch (action.type) {
        case FETCH_OPEN_BUY_EXTENSIONS.SUCCESS:
            return {
                ...state,
                [action.payload.openBuyId]: {
                    ...action.response.entities.openBuyExtensions
                }
            };
        default:
            return state;
    }
}

export default openBuyExtensionsEntitiesReducer;
