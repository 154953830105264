import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import useAuthorization from "../../auth/hooks/useAuthorization";
import { MediaAction } from "../../auth/roles";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "../../components/CommonStyles";
import PromoteButton from "../details/components/PromoteButton";
import HighlightMediaCarouselTitle from "./HighlightMediaCarouselTitle";
import { Highlight, Metric } from "../types";
import { startPromotingModal } from "../../ui/auth/actions";
import { formatNumber } from "../../util";
import HighlightMetricItem from "./HighlightMetricItem";
import { campaignHighlightDownloadTypesModal } from "src/ui/highlights/actions";
import { fonts } from "src/constants";

const Bold = styled.span`
    font-weight: 600;
`;

const MainWrapper = styled.div`
    padding: 0 42px;

    & > * ~ * {
        margin-top: 24px;
    }
`;

const Main = styled.div`
    /* width: 250px; */
    border-radius: 6px;
    overflow: hidden;
    margin: 10px auto 0;
`;

const Section = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    padding: 12px;
`;

const Top = styled(Section)`
    font-size: 12px;
`;

const ViewsAndSpend = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 9px;
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-around;
`;

const BottomCell = styled(Section)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${fonts.smallTitle}px;
    cursor: pointer;

    &:active {
        background-color: rgba(0, 0, 0, 0.8);
    }
`;

const DownloadLink = styled.a`
    background-color: rgba(0, 0, 0, 0.5);
    padding: 12px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${fonts.smallTitle}px;
    color: white;

    &:hover {
        color: white;
        text-decoration: none;
    }

    &:active {
        background-color: rgba(0, 0, 0, 0.8);
    }
`;

const HorizontalDivider = styled.div`
    height: 1px;
`;

const VerticalDivider = styled.div`
    height: 100%;
    width: 1px;
`;

const Icon = styled.img`
    margin-right: 8px;
`;

const Block = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    padding: 12px;
    font-size: ${fonts.subtitle}px;
`;

const MetricList = styled.div`
    ${FlexVerticallyCentered}
    flex-wrap: wrap;

    & > * {
        margin-right: 20px;
    }

    & > *:last-child {
        margin-right: 0px;
    }
`;

interface Props {
    dollarsPerK: string;
    views: string;
    revenue: number | null;
    mediaId: number;
    mediaUrl: string;
    highlight?: Highlight;
    linkClicked: () => void;
}

const HighlightsCarouselDetails = ({
    dollarsPerK,
    views,
    revenue,
    mediaId,
    mediaUrl,
    highlight,
    linkClicked
}: Props) => {
    const [cellButtons] = useAuthorization([MediaAction.cellButtons]);
    const dispatch = useDispatch();

    if (highlight) {
        const { IGStoryMedia, feedMedia, singleMedia, doubleMedia } = highlight;

        const isAllMediaNull =
            !IGStoryMedia && !feedMedia && !singleMedia && !doubleMedia;

        const highlightDownloadCallback = (): void => {
            dispatch(
                campaignHighlightDownloadTypesModal(highlight.highlightsPageId)
            );
        };

        return (
            <MainWrapper>
                <Main>
                    <Block>
                        <HighlightMediaCarouselTitle
                            metrics={highlight.metrics}
                            titleSize={16}
                        />
                        <MetricList>
                            <HighlightMetricItem
                                icon={highlight.placement.grayscaleLogo}
                                text={highlight.placement.type}
                            />
                            {highlight.metrics.slice(1).map(metric => (
                                <HighlightMetricItem
                                    icon={metric.icon}
                                    key={metric.name}
                                    text={`${metric.value} ${metric.descriptor}`}
                                />
                            ))}
                            {highlight.accountTheme && (
                                <HighlightMetricItem
                                    icon="/account-theme.svg"
                                    text={highlight.accountTheme}
                                />
                            )}
                        </MetricList>
                    </Block>
                    {cellButtons && (
                        <>
                            <HorizontalDivider />
                            <Bottom>
                                <BottomCell
                                    onClick={linkClicked}
                                    onMouseDown={e => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <Icon src={"/white_link.svg"} />
                                    Copy link
                                </BottomCell>
                                <VerticalDivider />
                                <DownloadLink
                                    target="_blank"
                                    download={`media-id-${mediaId}`}
                                    href={mediaUrl}
                                    onMouseDown={e => {
                                        e.stopPropagation();
                                    }}
                                    onClick={(
                                        event: React.MouseEvent<
                                            HTMLAnchorElement
                                        >
                                    ) => {
                                        if (!isAllMediaNull) {
                                            event.preventDefault();
                                            highlightDownloadCallback();
                                        }
                                    }}
                                >
                                    <Icon src={"/white_download.svg"} />
                                    Download
                                </DownloadLink>
                            </Bottom>
                        </>
                    )}
                </Main>
                {!cellButtons && (
                    <PromoteButton
                        onClick={() => dispatch(startPromotingModal())}
                    />
                )}
            </MainWrapper>
        );
    }

    return (
        <Main>
            <Top>
                <div style={{ fontSize: "16px" }}>
                    <Bold>${dollarsPerK}</Bold> per k
                </div>
                <ViewsAndSpend>
                    <div>{formatNumber(views)} views</div>
                    <div>${revenue && revenue.toFixed(2)}</div>
                </ViewsAndSpend>
            </Top>
            <HorizontalDivider />
            <Bottom>
                <BottomCell onClick={linkClicked}>
                    <Icon src={"/white_link.svg"} />
                    Copy link
                </BottomCell>
                <VerticalDivider />
                <DownloadLink
                    target="_blank"
                    download={`media-id-${mediaId}`}
                    href={mediaUrl}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                        event.preventDefault();
                    }}
                >
                    <Icon src={"/white_download.svg"} />
                    Download
                </DownloadLink>
            </Bottom>
        </Main>
    );
};

export default HighlightsCarouselDetails;
