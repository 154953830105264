import { Platform } from "src/marketplace/utils/types";
import { Action } from "src/types";
import {
    SET_CURRENT_WIZARD_STEP,
    SET_WIZARD_EXCLUSIVE_CHALLENGE,
    SET_WIZARD_CHALLENGE_OFFER,
    SET_WIZARD_CHARGE_ADVERTISER_PER_INSTALL,
    SET_WIZARD_OFFER_TYPE,
    SET_WIZARD_CHALLENGE_THUMBNAIL_IMAGE,
    SET_WIZARD_PLATFORM,
    SET_OFFER_CREATION_MODAL_COMPLETED
} from "./actions";

export type CurrentCreateOfferStep =
    | "Select or Create Challenge"
    | "Select an Instruction Set"
    | "Select a Creator Group"
    | "Select a Payment Set";

export type WizardOfferType = "Personal Offer" | "Challenge Offers" | "";

export interface CreateOfferWizardUIState {
    offerType: WizardOfferType;

    // Challenge Offer Data
    selectedOpenBuy?: number; //optional for if we are editing/reviewing an existing challenge
    challengeThumbnailImage: string;
    challengeTitle: string;
    challengeBrief: string;
    requireExclusiveChallenge: boolean;
    challengeLinkUrl: string;
    chargeAdvertiserPerInstall: boolean;
    jetfuelPlatformFee: number | null;
    advertiserBudget: number | null;
    creatorPayoutBudget: number | null;
    platform: Platform | "";

    offerCreationModalCompleted: boolean;
    currentWizardStep: CurrentCreateOfferStep;
}

const initialCreateOfferWizardUIState: CreateOfferWizardUIState = {
    offerType: "",

    // Challenge Offer Data
    challengeThumbnailImage: "",
    challengeTitle: "",
    challengeBrief: "",
    requireExclusiveChallenge: false,
    challengeLinkUrl: "",
    chargeAdvertiserPerInstall: false,
    jetfuelPlatformFee: null,
    advertiserBudget: null,
    creatorPayoutBudget: null,
    platform: "",

    offerCreationModalCompleted: false,
    currentWizardStep: "Select an Instruction Set"
};

function createOfferWizardUIReducer(
    state = initialCreateOfferWizardUIState,
    action: Action
): CreateOfferWizardUIState {
    switch (action.type) {
        case SET_CURRENT_WIZARD_STEP:
            return {
                ...state,
                currentWizardStep: action.payload.currentWizardStep
            };

        case SET_WIZARD_OFFER_TYPE:
            return { ...state, offerType: action.payload.offerType };

        case SET_WIZARD_EXCLUSIVE_CHALLENGE:
            const { requireExclusiveChallenge } = action.payload;
            return { ...state, requireExclusiveChallenge };

        case SET_WIZARD_CHALLENGE_THUMBNAIL_IMAGE:
            const { challengeThumbnailImage } = action.payload;
            return { ...state, challengeThumbnailImage };

        case SET_WIZARD_CHARGE_ADVERTISER_PER_INSTALL:
            const { chargeAdvertiserPerInstall } = action.payload;
            return { ...state, chargeAdvertiserPerInstall };

        case SET_WIZARD_PLATFORM:
            const { platform } = action.payload;
            return { ...state, platform };

        case SET_WIZARD_CHALLENGE_OFFER:
            const {
                challengeTitle,
                challengeBrief,
                challengeLinkUrl,
                jetfuelPlatformFee,
                advertiserBudget,
                creatorPayoutBudget,
                selectedOpenBuy
            } = action.payload;
            return {
                ...state,
                challengeTitle,
                challengeBrief,
                challengeLinkUrl,
                jetfuelPlatformFee,
                advertiserBudget,
                creatorPayoutBudget,
                selectedOpenBuy
            };

        case SET_OFFER_CREATION_MODAL_COMPLETED:
            const { offerCreationModalCompleted } = action.payload;
            return { ...state, offerCreationModalCompleted };

        default:
            return state;
    }
}

export default createOfferWizardUIReducer;
