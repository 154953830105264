import React from "react";
import ItemSelector, { ItemSelectorOptions } from "src/components/ItemSelector";
import FullScreenModal from "src/modals/FullScreenModal";
import { BlueButton, OutlineButton } from "src/profile/components/Buttons";
import styled from "styled-components";

const SelectorContainer = styled.div`
    height: 94%;
`;
const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 5px;
`;
const Save = styled(BlueButton)`
    width: 100%;
    height: 40px;
`;

const Clear = styled(OutlineButton)`
    width: 100%;
    height: 40px;
`;

interface Props {
    onClose: () => void;
    onClear: () => void;
    options: ItemSelectorOptions[];
}

const PublicBuysFilterModal = ({ onClose, onClear, options }: Props) => {
    return (
        <FullScreenModal closeModal={onClose}>
            <SelectorContainer>
                <ItemSelector options={options} length={options.length} />
            </SelectorContainer>
            <ButtonRow>
                <Clear onClick={onClear}>Reset Selection</Clear>
                <Save onClick={onClose}>Save Selection</Save>
            </ButtonRow>
        </FullScreenModal>
    );
};
export default PublicBuysFilterModal;
