import React, { useState } from "react";
import { DirectBuySearchModalState } from "../../ui/direct-buys/reducer";
import HalfScreenSelectModal, {
    SelectOption
} from "../../modals/HalfScreenSelectModal";
import { clearModal } from "../../modals/actions";
import { AnimatePresence } from "framer-motion";
import {
    DirectBuyStatus,
    directBuyStatusAll
} from "../modules/direct-buy/types";
import { useDispatch, useSelector } from "react-redux";
import {
    selectDirectBuySearchModalState,
    selectDirectBuyStatusFilters
} from "../selectors";
import SetDatesScreen from "src/campaigns/highlights/SetDatesScreen";
import moment, { Moment } from "moment";
import FullScreenModal from "src/modals/FullScreenModal";
import {
    clearDirectBuySearch,
    setStatusFiltersState
} from "src/ui/direct-buys/actions";

interface Props {
    sortMode: string;
    setSortMode: (sortMode: string) => void;
    setDate?: (dateRange?: [Moment, Moment], type?: string) => void;
    isOpenBuy?: boolean;
}

const DirectBuysSearchModals = ({
    sortMode,
    setSortMode,
    setDate,
    isOpenBuy
}: Props) => {
    const dispatch = useDispatch();

    const statusFilters = useSelector(selectDirectBuyStatusFilters);
    const modalState = useSelector(selectDirectBuySearchModalState);
    const [persistDate, setPersistDate] = useState<[Moment, Moment]>([
        moment(),
        moment().add(3, "days")
    ]);
    const filterOptions = directBuyStatusAll
        .filter(status => !isOpenBuy || status !== "completed")
        .map(status => ({
            value: status,
            name:
                isOpenBuy && status === "expired"
                    ? "Ended"
                    : status.charAt(0).toUpperCase() + status.slice(1)
        }));
    const sortOptions = [{ value: "Newest" }, { value: "Oldest" }];
    function clearTimeRange() {
        setDate && setDate(undefined, "All Time");
        dispatch(clearModal());
        dispatch(clearDirectBuySearch());
    }

    function renderModal() {
        switch (modalState) {
            case DirectBuySearchModalState.sort:
                return (
                    <HalfScreenSelectModal
                        notEmpty
                        title="Select Sort Option"
                        options={sortOptions}
                        initialSelection={[sortMode]}
                        onSave={selection => {
                            if (selection[0]) {
                                setSortMode(selection[0]);
                            }
                        }}
                        clearModal={() => {
                            dispatch(clearModal());
                        }}
                    />
                );
            case DirectBuySearchModalState.filter:
                return (
                    <HalfScreenSelectModal
                        notEmpty
                        isMulti
                        title="Select Personal Offer Status"
                        options={filterOptions}
                        initialSelection={Array.from(statusFilters)}
                        onSave={selection => {
                            if (selection.length === 0) return;
                            dispatch(
                                setStatusFiltersState(
                                    selection as DirectBuyStatus[]
                                )
                            );
                        }}
                        clearModal={() => {
                            dispatch(clearModal());
                        }}
                    />
                );
            case DirectBuySearchModalState.date:
                return (
                    <FullScreenModal closeModal={() => dispatch(clearModal())}>
                        <SetDatesScreen
                            initialDates={persistDate}
                            onCancelTapped={() => dispatch(clearModal())}
                            onDoneTapped={(daterange, type) => {
                                setPersistDate(daterange);
                                setDate && setDate(daterange, type);
                            }}
                            title="Set Time Frame"
                            clearTimeRange={() => {
                                setPersistDate([
                                    moment(),
                                    moment().add(3, "days")
                                ]);
                                clearTimeRange();
                            }}
                        />
                    </FullScreenModal>
                );
            default:
                return null;
        }
    }

    return <AnimatePresence>{renderModal()}</AnimatePresence>;
};

export default DirectBuysSearchModals;
