import React, { ChangeEvent, useState } from "react";
import ModalNameHeader from "src/chat/components/ModalNameHeader";
import ModalNameInput from "src/chat/components/ModalNameInput";
import { storage } from "src/firebase/FirebaseConfig";
import ImageModal from "src/modals/ImageModal";
import { BlueModalButton } from "src/profile/components/Buttons";
import { makeId } from "src/util";

interface Props {
    title: string;
    actionText: string;
    saveChanges: (params: { imageId?: string; groupName?: string }) => void;
    imageUrl?: string;
    displayName: string;
    clearModal: () => void;
}

const EditGroupChannelModal = ({
    title,
    actionText,
    saveChanges,
    imageUrl,
    displayName,
    clearModal
}: Props) => {
    const [groupName, setGroupName] = useState(displayName);
    const [placeholder, setPlaceholder] = useState("Group Name");
    const [coverUrl, setCoverUrl] = useState(imageUrl);
    const [file, setFile] = useState<File | null>(null);

    function imageUpload() {
        if (!file) return saveChanges({ groupName });

        const allowedTypes = ["image/jpeg", "image/png"];
        if (!allowedTypes.includes(file.type)) return;

        const type = file.type.split("/")[1];

        const imageId = `${localStorage.getItem("userid")}_${makeId(
            50
        )}.${type}`;

        const ref = storage.ref().child(`conversation_group_assets/${imageId}`);

        ref.put(file)
            .then(() => {
                saveChanges({ groupName, imageId });
            })
            .catch(() => {
                alert("Upload Failed, please try again");
            });
    }

    function imageSet(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        if (!e.currentTarget.files) return;
        const file = e.currentTarget.files[0];
        const objectUrl = URL.createObjectURL(file);
        setCoverUrl(objectUrl);
        setFile(file);
    }

    return (
        <ImageModal
            imageUrl={coverUrl || "/profile-photo-camera-blue.svg"}
            imageUploadCallback={imageSet}
            localClearModal={clearModal}
        >
            <ModalNameHeader>{title}</ModalNameHeader>
            <ModalNameInput
                placeholder={placeholder}
                value={groupName}
                onChange={e => {
                    setGroupName(e.currentTarget.value);
                }}
                onFocus={() => setPlaceholder("")}
                onBlur={() => setPlaceholder("Group Name")}
            />
            <BlueModalButton onClick={imageUpload} disabled={!groupName}>
                {actionText}
            </BlueModalButton>
        </ImageModal>
    );
};

export default EditGroupChannelModal;
