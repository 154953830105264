import { goBack, push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

const useInternalRouting = (fallback: string) => {
    const dispatch = useDispatch();
    const location = useLocation<{ internal?: boolean }>();

    function goBackIfInternalRoute() {
        if (location.state?.internal) {
            dispatch(goBack());
        } else {
            dispatch(push(fallback));
        }
    }

    return { goBackIfInternalRoute };
};

export default useInternalRouting;
