import React, { useEffect, useState } from "react";
import { get } from "src/Api";
import { OpenBuySubmissionSet } from "src/buys/modules/open-buys/types";
import ModalBox from "src/components/modals/components/ModalBox";
import { colors } from "src/constants";
import styled from "styled-components";

const Cell = styled.div`
    padding: 16px;
    background-color: ${colors.white};
    border: 1px solid ${colors.blueGrey2};
    border-radius: 6px;
    font-size: 12px;
    text-align: left;
`;

interface Props {
    submissionSet: OpenBuySubmissionSet;
}

const LinkInBio = ({ submissionSet }: Props) => {
    const [links, setLinks] = useState({
        plug: null,
        bio: null
    });

    const fetchLink = async () => {
        try {
            let res = await get(
                `/v1/admin/openBuy/${submissionSet.socialAccountId}/plugLink`,
                { publisherId: submissionSet.publisherId }
            );

            setLinks(old => ({ ...old, plug: res.data.data[0] }));
        } catch (err) {}
    };
    const fetchBio = async () => {
        try {
            let res = await get(
                `/v1/admin/socialAccount/${submissionSet.socialAccountId}/linkInBio`,
                {}
            );
            setLinks(old => ({ ...old, bio: res.data.data?.latestBio }));
        } catch (err) {}
    };

    useEffect(() => {
        if (!links.plug) fetchLink();
        if (!links.bio) fetchBio();
    }, [submissionSet]);

    return (
        <ModalBox headingText="LINK IN BIO">
            <Cell>Required: {links.plug || "No Link Requied"}</Cell>
            <Cell>Latest: {links.bio || "Bio not recorded"}</Cell>
        </ModalBox>
    );
};

export default LinkInBio;
