import React, { useState } from "react";
import styled from "styled-components";
import MediaRuleRow from "./MediaRuleRow";
import { BigOutlineButton } from "../../../profile/components/Buttons";
import { MediaRule } from "src/campaigns/types";

const Main = styled.div<{ buttonSpace: boolean }>`
    padding-bottom: ${props => (props.buttonSpace ? "88px" : "16px")};
`;

const List = styled.div`
    padding: 0px 18px;

    & > * ~ * {
        margin-top: 10px;
    }
`;

const MockList = styled.div`
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    & > * ~ * {
        margin-top: 10px;
    }
`;

const MockItems = styled.div`
    position: relative;
`;

const ButtonWrapper = styled.div`
    padding: 16px 18px 24px 18px;
    background-color: white;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
`;

interface Props {
    mediaRules: MediaRule[];
}

const MediaRulesList = ({ mediaRules }: Props) => {
    const [showCount, setShowCount] = useState(3);

    return (
        <Main buttonSpace={mediaRules.length > 3}>
            <List>
                {mediaRules.slice(0, showCount).map(mediaRule => (
                    <MediaRuleRow key={mediaRule.id} mediaRule={mediaRule} />
                ))}
                <MockItems>
                    <MockList>
                        {showCount === 3 &&
                            mediaRules
                                .slice(3, mediaRules.length)
                                .map(mediaRule => (
                                    <MediaRuleRow
                                        key={mediaRule.id}
                                        mediaRule={mediaRule}
                                    />
                                ))}
                    </MockList>
                </MockItems>
            </List>
            {mediaRules.length > 3 && (
                <ButtonWrapper>
                    {mediaRules.length > showCount ? (
                        <BigOutlineButton
                            onClick={() => setShowCount(mediaRules.length)}
                        >
                            See all
                        </BigOutlineButton>
                    ) : (
                        <BigOutlineButton onClick={() => setShowCount(3)}>
                            See less
                        </BigOutlineButton>
                    )}
                </ButtonWrapper>
            )}
        </Main>
    );
};

export default MediaRulesList;
