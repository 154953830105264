import React from "react";
import styled from "styled-components";

import { Script } from "../../types";

import { colors } from "../../../constants";
import ScriptRow from "./ScriptRow";
import { campaignScriptModal } from "src/ui/scripts/actions";
import { connect } from "react-redux";
import ScriptCreationRow from "./ScriptCreationRow";

const List = styled.div`
    padding: 0 18px 16px 18px;

    & > * ~ * {
        margin-top: 10px;
    }
`;

interface Props {
    scripts: Script[];
    campaignScriptModal: (scriptId: number) => void;
    isAdmin: boolean;
}

const ScriptList = ({ scripts, campaignScriptModal, isAdmin }: Props) => (
    <List>
        {scripts.map(script => (
            <ScriptRow
                key={script.id}
                script={script}
                handleClick={() => campaignScriptModal(script.id)}
            />
        ))}
        {isAdmin && <ScriptCreationRow />}
    </List>
);
const mapDispatchToProps = (dispatch: any) => ({
    campaignScriptModal: (scriptId: number) =>
        dispatch(campaignScriptModal(scriptId))
});

export default connect(null, mapDispatchToProps)(ScriptList);
