import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { selectConversationWithId, selectOwnChatProfile } from "../selectors";
import { createMessageChannel } from "../message/actions";
import { createCurrentChatUserProfileChannel } from "../chat-profiles/actions";

const useInitializeChatRoom = (conversationId: string) => {
    const dispatch = useDispatch();
    const participantIds = useSelector(
        (state: GlobalState) =>
            selectConversationWithId(state, conversationId)?.participantIds,
        shallowEqual
    );

    const ownChatProfile = useSelector(selectOwnChatProfile);

    useEffect(() => {
        conversationId && dispatch(createMessageChannel(conversationId));
    }, [conversationId]);

    useEffect(() => {
        participantIds &&
            dispatch(createCurrentChatUserProfileChannel(participantIds));
    }, [participantIds]);

    return { participantIds, ownChatProfile };
};

export default useInitializeChatRoom;
