import { Shimmer } from "src/components/SkeletonLoader";
import { sizes } from "src/constants";
import styled from "styled-components";

// SelectionHeader

export const SelectionHeaderLoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const SelectionHeaderLoading = styled.div`
    ${Shimmer};
    height: ${sizes.marketplace.instructions.dropdownHeight}px;
    border-radius: 8px;
`;

// CreateDropdownItem

export const CreateDropdownItemLoadingContainer = styled.div`
    display: flex;
    gap: 10px;
    padding: 10px;
`;

export const CreateDropdownItemLoading = styled.div`
    ${Shimmer};
    width: ${sizes.marketplace.instructions.dropdownItem.width}px;
    height: ${sizes.marketplace.instructions.dropdownItem.height}px;
    border-radius: 8px;
`;

// Instructions Campaigns Selection Modal

export const CampaignSelectionItemLoading = styled.div`
    ${Shimmer};
    width: 100%;
    height: 62px;
    flex-shrink: 0;
    border-radius: 8px;
`;

// Admin Tools Instructions Loading Container

export const AdminToolsInstructionsLoadingContainer = styled.div`
    display: flex;
    gap: 24px;
    flex: 1;
    margin-bottom: 24px;
`;

// Admin Tools Selections Loading

export const AdminToolsSelectionsLoading = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 326px;
`;

// Admin Tools Instructions Form

export const AdminToolsInstructionsFormLoading = styled.div`
    ${Shimmer};
    flex: 1;
    border-radius: 8px;
`;
