import { Action } from "src/types";
import { Shoutout, ShoutoutFillOrder } from "./types";

// Fetch Shoutouts -----------------------------------------------

export const FETCH_SHOUTOUTS = {
    REQUEST: "FETCH_SHOUTOUTS_REQUEST",
    SUCCESS: "FETCH_SHOUTOUTS_SUCCESS",
    FAILURE: "FETCH_SHOUTOUTS_FAILURE"
};

export const fetchShoutouts = (limitTime: boolean): Action => ({
    type: FETCH_SHOUTOUTS.REQUEST,
    payload: { limitTime }
});

export interface Shoutouts {
    userSpecificShoutouts: Shoutout[];
    shoutoutsAvailableForAll: any[];
}

export const fetchShoutoutsSuccess = (shoutouts: Shoutouts): Action => ({
    type: FETCH_SHOUTOUTS.SUCCESS,
    payload: { shoutouts }
});

export const fetchShoutoutsFailure = (error: string): Action => ({
    type: FETCH_SHOUTOUTS.FAILURE,
    payload: { error }
});

// Fill Order -----------------------------------------------

export interface ShoutoutMediaUpload {
    shoutout_id: number;
    fill_order_id: number;
    filename: string;
}

export const ADD_MEDIA_TO_FILL_ORDER = {
    REQUEST: "ADD_MEDIA_TO_FILL_ORDER_REQUEST",
    FAILURE: "ADD_MEDIA_TO_FILL_ORDER_FAILURE"
};

export const addMediaToFillOrder = (payload: ShoutoutMediaUpload): Action => ({
    type: ADD_MEDIA_TO_FILL_ORDER.REQUEST,
    payload
});

export const addMediaToFillOrderFailure = (error: string): Action => ({
    type: ADD_MEDIA_TO_FILL_ORDER.FAILURE,
    payload: { error }
});

export interface ShoutoutMediaDelete {
    shoutout_id: number;
    fill_order_id: number;
}

export const DELETE_MEDIA_FROM_FILL_ORDER = {
    REQUEST: "DELETE_MEDIA_FROM_FILL_ORDER_REQUEST",
    FAILURE: "DELETE_MEDIA_FROM_FILL_ORDER_FAILURE"
};

export const deleteMediaFromFillOrder = (
    payload: ShoutoutMediaDelete
): Action => ({
    type: DELETE_MEDIA_FROM_FILL_ORDER.REQUEST,
    payload
});

export const deleteMediaFromFillOrderFailure = (error: string): Action => ({
    type: DELETE_MEDIA_FROM_FILL_ORDER.FAILURE,
    payload: { error }
});

export const CHANGE_MEDIA_FOR_FILL_ORDER = "CHANGE_MEDIA_FOR_FILL_ORDER";

export const changeMediaForFillOrder = (
    payload: ShoutoutMediaUpload | ShoutoutMediaDelete
): Action => ({
    type: CHANGE_MEDIA_FOR_FILL_ORDER,
    payload
});

// Request Verification (fill order) -----------------------------------------------

export const REQUEST_VERIFICATION = {
    REQUEST: "REQUEST_VERIFICATION_REQUEST",
    SUCCESS: "REQUEST_VERIFICATION_SUCCESS",
    FAILURE: "REQUEST_VERIFICATION_FAILURE"
};

export const requestVerification = (
    fill_orders: ShoutoutFillOrder[]
): Action => ({
    type: REQUEST_VERIFICATION.REQUEST,
    payload: { fill_orders }
});

export const requestVerificationSuccess = (): Action => ({
    type: REQUEST_VERIFICATION.SUCCESS,
    payload: null
});

export const requestVerificationFailure = (error: string): Action => ({
    type: REQUEST_VERIFICATION.FAILURE,
    payload: { error }
});

// Remove Verification (fill order) -----------------------------------------------

export const REMOVE_VERIFICATION = {
    REQUEST: "REMOVE_VERIFICATION_REQUEST",
    SUCCESS: "REMOVE_VERIFICATION_SUCCESS",
    FAILURE: "REMOVE_VERIFICATION_FAILURE"
};

export const removeVerification = (
    fill_orders: ShoutoutFillOrder[]
): Action => ({
    type: REMOVE_VERIFICATION.REQUEST,
    payload: { fill_orders }
});

export const removeVerificationSuccess = (): Action => ({
    type: REMOVE_VERIFICATION.SUCCESS,
    payload: null
});

export const removeVerificationFailure = (error: string): Action => ({
    type: REMOVE_VERIFICATION.FAILURE,
    payload: { error }
});

// Check Shoutout History -----------------------------------------------

export const CHECK_SHOUTOUT_HISTORY = {
    REQUEST: "CHECK_SHOUTOUT_HISTORY_REQUEST",
    SUCCESS: "CHECK_SHOUTOUT_HISTORY_SUCCESS",
    FAILURE: "CHECK_SHOUTOUT_HISTORY_FAILURE"
};

export const checkShoutoutHistory = (): Action => ({
    type: CHECK_SHOUTOUT_HISTORY.REQUEST,
    payload: null
});

export const checkShoutoutHistorySuccess = (
    hasShoutoutHistory: boolean
): Action => ({
    type: CHECK_SHOUTOUT_HISTORY.SUCCESS,
    payload: { hasShoutoutHistory }
});

export const checkShoutoutHistoryFailure = (error: string): Action => ({
    type: CHECK_SHOUTOUT_HISTORY.FAILURE,
    payload: { error }
});

// Cancel Shoutout -----------------------------------------------

export const CANCEL_SHOUTOUT = {
    REQUEST: "CANCEL_SHOUTOUT_REQUEST",
    SUCCESS: "CANCEL_SHOUTOUT_SUCCESS",
    FAILURE: "CANCEL_SHOUTOUT_FAILURE"
};

export const cancelShoutout = (fill_orders: ShoutoutFillOrder[]): Action => ({
    type: CANCEL_SHOUTOUT.REQUEST,
    payload: { fill_orders }
});

export const cancelShoutoutSuccess = (): Action => ({
    type: CANCEL_SHOUTOUT.SUCCESS,
    payload: null
});

export const cancelShoutoutFailure = (error: string): Action => ({
    type: CANCEL_SHOUTOUT.FAILURE,
    payload: { error }
});
