import React from "react";
import styled, { keyframes, css } from "styled-components";
import { breakpoints, colors, sizes } from "src/constants";

const animation = keyframes`
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
`;

export const Shimmer = css`
    background: #f3f6f7
        linear-gradient(
            to right,
            #f3f6f7 0%,
            #eef1f2 20%,
            #f3f6f7 40%,
            #f3f6f7 100%
        )
        no-repeat;
    background-size: 800px 1200px;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${animation};
    animation-timing-function: linear;
`;

export const TitleLoading = styled.div`
    ${Shimmer}
    height: 24px;
    border-radius: 6px;
    width: 100px;
`;

export const SubtitleLoading = styled.div`
    ${Shimmer}
    height: 20px;
    margin-top: 4px;
    border-radius: 6px;
    width: 80px;
`;

export const TextLoading = styled.div<{ width?: number; height?: number }>`
    ${Shimmer}
    width: ${props => (props.width ? props.width : 100)}%;
    height: ${props => (props.height ? props.height : 20)}px;
    border-radius: 6px;
`;

export const MediaLoading = styled.div<{ margin?: number }>`
    ${Shimmer}
    width: 106px;
    height: 192px;
    border-radius: 10px;
    margin: ${props => (props.margin !== undefined ? props.margin : 5)}px;
`;

export const CustomLoading = styled.div<{
    width?: string;
    height?: string;
    borderRadius?: string;
}>`
    ${Shimmer}
    width: ${props => (props.width ? props.width : "100%")};
    height: ${props => (props.height ? props.height : "20px")};
    border-radius: ${props =>
        props.borderRadius ? props.borderRadius : "6px"};
`;

// New Campaign Item

export const CampaignItemLoading = styled.div`
    ${Shimmer};
    width: 100%;
    height: 300px;
    border-radius: 10px;
`;

interface MediaItemLoading {
    width: number;
}

export const CampaignMediaItemLoading = styled.div<MediaItemLoading>`
    ${Shimmer};
    width: ${props => `${props.width}px`};
    height: ${sizes.mediaItemHeight}px;
    border-radius: 8px;
`;

// New Campaign Detail

export const CampaignDetailHeaderIcon = styled.div`
    ${Shimmer};
    width: 90px;
    height: 90px;
    border-radius: 12px;
`;

export const CampaignDetailHeaderInfoOne = styled.div`
    ${Shimmer};
    width: 40%;
    height: 27px;
    border-radius: 6px;
`;

export const CampaignDetailHeaderInfoTwo = styled.div`
    ${Shimmer};
    width: 50%;
    height: 20px;
    border-radius: 6px;
`;

export const CampaignDetailHeaderInfoThree = styled.div`
    ${Shimmer};
    width: 30%;
    height: 20px;
    border-radius: 6px;
`;

export const CampaignDetailHeaderAccessIcon = styled.div`
    ${Shimmer};
    width: 24px;
    height: 24px;
    border-radius: 50%;
`;

export const CampaignDetailHeaderAccessText = styled.div`
    ${Shimmer};
    width: 100%;
    height: 20px;
    border-radius: 6px;
`;

// New Campaign Shoutouts

export const ShoutoutsTitleLoading = styled.div`
    ${Shimmer};
    width: 120px;
    height: 12px;
    border-radius: 10px;
`;

export const ShoutoutsHeaderLoading = styled.div`
    ${Shimmer};
    width: 60px;
    height: 60px;
    border-radius: 10rem;
`;

export const ShoutoutsTextLoading = styled.div`
    ${Shimmer};
    width: 100%;
    height: 10px;
    border-radius: 10px;
`;

// -----

export const LoaderContainer = styled.div<{ height?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ height }) => (height ? height : "40vh")};
`;

// link list item
export const LoaderItem = styled.div`
    ${Shimmer};
    border-radius: 10px;
    width: 100%;
    height: 80px;
    padding: 0 12px;
`;

export const InfoLoader = styled.div`
    height: 144px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    max-width: 400px;
    width: calc(100% - 36px);
    margin: 10px 18px 0 18px;
    ${Shimmer}
`;

export const CircleImageLoader = styled.div`
    border-radius: 100px;
    margin-top: 20px;
    margin-bottom: 25px;
    width: 104px;
    height: 104px;
    ${Shimmer}
`;

export const LinkItemListLoader = styled.div`
    ${Shimmer}
    width: 100%;
    height: 400px;
    margin-top: 30px;
    padding: 10px 18px;
    margin-bottom: 20px;
`;

// -----

// Creator Group

export const CreatorGroupLoading = styled.div`
    ${Shimmer};
    width: 100%;
    height: ${sizes.marketplace.instructions.dropdownHeight}px;
    margin-bottom: 8px;
    border-radius: 8px;
`;

// Creator Card

export const CreatorCardLoading = styled.div`
    ${Shimmer};
    width: 100%;
    height: 140px;
`;

// Creator Search Header

export const CreatorSearchHeaderLoading = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 222px;
    padding: 16px;
    background-color: ${colors.white};
`;

export const CreatorSearchHeaderTitleLoading = styled.div`
    ${Shimmer};
    width: 160px;
    height: 45px;
    border-radius: 6px;
`;

export const CreatorSearchHeaderSearchLoading = styled.div`
    ${Shimmer};
    height: 40px;
    border-radius: 6px;
`;

export const CreatorSearchHeaderCreatorCountLoading = styled.div`
    ${Shimmer};
    width: 140px;
    height: 18px;
    border-radius: 6px;
`;

export const CreatorSearchHeaderTagsLoading = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

export const CreatorSearchHeaderTagLoading = styled.div`
    ${Shimmer};
    width: 75px;
    height: 24px;
    border-radius: 6px;
`;

// -----

// Social Account Profile

export const SocialAccountProfileHeaderLoading = styled.div`
    ${Shimmer};
    height: 210px;
    margin-bottom: 20px;
    border-radius: 8px;

    @media (max-width: ${breakpoints.tablet}px) {
        margin-top: 20px;
    }
`;

export const SocialAccountProfileDetailsLoading = styled.div`
    ${Shimmer};
    height: 540px;
    border-radius: 8px;
`;

export const SocialAccountProfileTopPostsLoading = styled.div`
    ${Shimmer};
    height: 490px;
    border-radius: 8px;
`;

export const SocialAccountProfileFollowerDemographicsLoading = styled.div`
    ${Shimmer};
    height: 650px;
    border-radius: 8px;
`;

// -----

// Admin Tools Challenge Details Form

const AdminToolsChallengeFormMainLoading = styled.div`
    display: flex;
    gap: 24px;
    align-items: flex-start;
    margin-bottom: 24px;

    @media (max-width: ${breakpoints.small}px) {
        flex-direction: column;
        height: 858px;
        padding: 0px 15px;
    }
`;

const AdminToolsChallengeFormLogisticsAndBudgetLoading = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 914px;
`;

const AdminToolsChallengeFormElementLoading = styled.div`
    ${Shimmer};
    flex: 1;
    width: 100%;
    border-radius: 16px;
`;

export const AdminToolsChallengeFormLoading = () => {
    return (
        <AdminToolsChallengeFormMainLoading>
            <AdminToolsChallengeFormElementLoading
                style={{ height: "675px" }}
            />
            <AdminToolsChallengeFormLogisticsAndBudgetLoading>
                <AdminToolsChallengeFormElementLoading
                    style={{ height: "100%" }}
                />
                <AdminToolsChallengeFormElementLoading
                    style={{ height: "100%" }}
                />
            </AdminToolsChallengeFormLogisticsAndBudgetLoading>
        </AdminToolsChallengeFormMainLoading>
    );
};
