import React from "react";
import ActionsModal from "../../../modals/ActionsModal";

interface Props {
    closeModal: () => void;
    deleteOffer: () => void;
}

const RateActionsModal = ({ closeModal, deleteOffer }: Props) => {
    return (
        <ActionsModal
            localClearModal={closeModal}
            actions={[
                {
                    text: "Delete",
                    callback: deleteOffer,
                    negative: true
                }
            ]}
        />
    );
};

export default RateActionsModal;
