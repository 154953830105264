import React from "react";
import { connect } from "react-redux";
import { Connection } from "../types";
import { postConnection, PostConnectionParams } from "../actions";
import { BlueEmptyButton, OutlineEmptyButton } from "./Buttons";
import { colors } from "../../constants";
// @ts-ignore
import { withSendBird } from "sendbird-uikit";

interface Props {
    connection: Connection;
    postConnection: (params: PostConnectionParams) => void;
    sendBird: any;
}

const EmptyConnectButton = ({
    connection,
    postConnection,
    sendBird
}: Props) => {
    if (connection.state === "friend") {
        return null;
    } else if (connection.state === "waiting") {
        return <OutlineEmptyButton disabled>Requested</OutlineEmptyButton>;
    } else if (connection.state === "requested") {
        return (
            <BlueEmptyButton
                onClick={() => {
                    postConnection({
                        selfId: connection.entity.id,
                        otherId: connection.other.id,
                        relationship: "friend"
                    });
                }}
            >
                Accept
            </BlueEmptyButton>
        );
    } else if (connection.state === "blocked") {
        return (
            <OutlineEmptyButton
                textColor={colors.primaryRed}
                onClick={() => {
                    postConnection({
                        selfId: connection.entity.id,
                        otherId: connection.other.id,
                        relationship: "none"
                    });
                    sendBird.unblockUserWithUserId(
                        connection.other.id,
                        (user: any, error: any) => {
                            if (error) {
                                window.alert(error);
                            }
                        }
                    );
                }}
            >
                Unblock
            </OutlineEmptyButton>
        );
    } else {
        return (
            <BlueEmptyButton
                onClick={() => {
                    postConnection({
                        selfId: connection.entity.id,
                        otherId: connection.other.id,
                        relationship: "friend"
                    });
                }}
            >
                Connect
            </BlueEmptyButton>
        );
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    postConnection: (params: PostConnectionParams) =>
        dispatch(postConnection(params))
});

export default withSendBird(
    connect(null, mapDispatchToProps)(EmptyConnectButton),
    (state: any) => ({
        sendBird: state.stores.sdkStore.sdk
    })
);
