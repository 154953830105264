import format from "date-fns/format";
import { AnimatePresence } from "framer-motion";
import React, { forwardRef, MutableRefObject, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import ScrollableFeed from "react-scrollable-feed";
import { FlexCentered } from "src/components/CommonStyles";
import SpinLoader from "src/components/SpinLoader";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import MessageDeliverTag from "../message/components/MessageDeliverTag";
import { getMessages } from "../selectors";
import ChatSummaryCard from "./ChatSummaryCard";
import MessageCell from "./MessageCell";

const Main = styled(ScrollableFeed)`
    height: 100%;
    background-color: ${colors.lighterTeal};
    padding: 18px 18px 5px 18px;

    & > * ~ * {
        margin-top: 4px;
    }
`;

const Empty = styled.div`
    min-height: 100%;
    background-color: ${colors.lighterTeal};
    color: ${colors.primaryGray};
    ${FlexCentered}
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;

const Date = styled.div`
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    margin: 16px 0;
    color: ${colors.primaryGray};
`;

interface Props {
    conversationId: string;
    isGroupChat?: boolean;
}

const MessageList = forwardRef<ScrollableFeed, Props>(
    ({ conversationId, isGroupChat }, ref) => {
        const messages = useSelector((state: GlobalState) =>
            getMessages(state, conversationId)
        );
        const { messageDelivering } = useSelector(
            (state: GlobalState) => state.ui.chat,
            shallowEqual
        );

        useEffect(() => {
            (ref as MutableRefObject<
                ScrollableFeed
            >)?.current?.scrollToBottom();
        }, []);

        if (!messages)
            return (
                <Empty>
                    <SpinLoader color={colors.black} size={60} />
                </Empty>
            );

        return (
            <Main ref={ref}>
                <ChatSummaryCard conversationId={conversationId} />

                {messages.map((message, i) => {
                    const lastCreated = messages[i - 1]?.created || 0;
                    const nextCreated = messages[i + 1]?.created || 0;
                    const lastAuthor = messages[i - 1]?.authorId || "";
                    const nextAuthor = messages[i + 1]?.authorId || "";

                    const lastDate = format(
                        lastCreated * 1000,
                        "cccc MMMM d, yyyy"
                    );
                    const date = format(
                        message.created * 1000,
                        "cccc MMMM d, yyyy"
                    );
                    const nextDate = format(
                        nextCreated * 1000,
                        "cccc MMMM d, yyyy"
                    );
                    return (
                        <React.Fragment key={message.id}>
                            {lastDate !== date && <Date>{date}</Date>}
                            <MessageCell
                                message={message}
                                showAvatar={
                                    nextAuthor !== message.authorId ||
                                    nextDate !== date
                                }
                                showUsername={
                                    lastAuthor !== message.authorId ||
                                    lastDate !== date
                                }
                            />
                            <AnimatePresence>
                                {message.myLastMessage &&
                                    !messageDelivering && <MessageDeliverTag />}
                            </AnimatePresence>
                        </React.Fragment>
                    );
                })}
            </Main>
        );
    }
);

export default MessageList;
