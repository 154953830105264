import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Descendant } from "slate";
import { Script } from "src/campaigns/types";
import CheckBox from "src/components/CheckBox";
import TextEditor from "src/components/text-editor";
import {
    isContentEmpty,
    serializeContent
} from "src/components/text-editor/utils";
import { breakpoints, colors, shadows } from "src/constants";
import { BlueButton } from "src/profile/components/Buttons";
import XCard from "src/profile/components/XCard";
import { GlobalState } from "src/reducers";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { setWarning } from "src/ui/scripts/actions";
import styled, { css } from "styled-components";
import ScriptPreview from "./ScriptPreview";

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr auto;
    isolation: isolate;
`;
const EditorWrap = styled.div<{ isError?: boolean; isFocused?: boolean }>`
    background-color: ${props =>
        props.isError ? colors.white : colors.secondaryLightGray};
    height: 100%;
    padding: 16px;
    position: relative;
    border-radius: 8px;
    text-align: left;
    line-height: 24px;
    overflow: auto;
`;
const Label = styled.label`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    padding: 4px 12px 0px;
    & > span {
        transform: translateY(-2px);
    }
`;
const TitleInput = styled.input<{ focus: boolean }>`
    width: 400px;
    margin-left: 8px;
    padding: 4px 8px;
    background-color: white;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    display: flex;
    outline: none;

    &::placeholder {
        opacity: 0.5;
    }

    ${props => {
        return (
            props.focus &&
            `box-shadow: 0px 0px 4px 0px ${colors.primaryBlue}; transition: box-shadow 0.2s;`
        );
    }}
`;
const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 12px 0px;
`;
const StyledCheckBox = styled(CheckBox)`
    margin-left: 8px;
`;
const SaveBtn = styled(BlueButton)`
    margin-left: 12px;
    padding: 8px 12px;
`;

const toolbarStyles = css`
    margin-bottom: 16px;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 2;
`;
const StyledScriptPreview = styled(ScriptPreview)`
    width: 50%;
    background-color: white;
    @media (max-width: ${breakpoints.tablet}px) {
        width: 100%;
    }
`;
interface Props {
    script: Script;
    submitActionCreator: (script: Script) => Action;
    placeholders?: { title?: string; text?: string };
    submitBtnText?: string;
}
const ScriptTextEditor = ({
    script,
    submitActionCreator,
    placeholders,
    submitBtnText
}: Props) => {
    const dispatch = useDispatch();
    const isWarning = useSelector(
        (state: GlobalState) => state.ui.script.warning
    );

    const [scriptTitle, setScriptTitle] = useState(script.title);
    const [isTitleFocused, setIsTitleFocused] = useState(false);
    const [editorContent, setEditorContent] = useState<Descendant[]>([]);
    const [shouldShowInApp, setShouldShowInApp] = useState(
        script.shouldShowInApp
    );
    const [isError, setIsError] = useState(false);

    const handleChange = (newEditorValue: Descendant[]) => {
        setEditorContent(newEditorValue);
        if (!isWarning) {
            dispatch(setWarning(true));
        }
    };

    const handleSubmit = () => {
        const markdownStr = serializeContent(editorContent);
        const isScriptEmpty = isContentEmpty(markdownStr);
        const isTitleEmpty = isContentEmpty(scriptTitle);

        if (isScriptEmpty || isTitleEmpty) {
            dispatch(
                setPillNotificationText(
                    `Can't save an empty ${
                        isScriptEmpty ? `script` : `script title`
                    }!`,
                    "danger",
                    2500
                )
            );
        } else {
            dispatch(
                submitActionCreator({
                    ...script,
                    text: markdownStr,
                    title: scriptTitle,
                    shouldShowInApp
                })
            );
            dispatch(setWarning(false));
        }
    };
    return (
        <Wrapper>
            <Label>
                <>Script Title:</>
                <TitleInput
                    onFocus={() => setIsTitleFocused(true)}
                    onBlur={() => setIsTitleFocused(false)}
                    focus={isTitleFocused}
                    value={scriptTitle}
                    onChange={e => setScriptTitle(e.target.value)}
                    placeholder={placeholders?.title}
                />
            </Label>
            <EditorWrap>
                {isError ? (
                    <XCard text="Something went wrong, please refresh the page." />
                ) : (
                    <TextEditor
                        initialText={script.text}
                        editorContent={editorContent}
                        setEditorContent={setEditorContent}
                        handleChange={handleChange}
                        setIsError={setIsError}
                        toolbarStyles={toolbarStyles}
                        placeholder={placeholders?.text}
                        PreviewComponent={StyledScriptPreview}
                    />
                )}
            </EditorWrap>
            <Footer>
                <Label style={{ margin: "0px", paddingLeft: "0px" }}>
                    <span>Show in app:</span>
                    <StyledCheckBox
                        selected={shouldShowInApp}
                        onCheck={() => setShouldShowInApp(true)}
                        onUncheck={() => setShouldShowInApp(false)}
                    />
                </Label>
                <SaveBtn onClick={handleSubmit}>
                    {submitBtnText || "Submit"}
                </SaveBtn>
            </Footer>
        </Wrapper>
    );
};

export default ScriptTextEditor;
