import { NormalizedAction } from "../../../types";
import { DirectBuy } from "./types";
import {
    ADD_DIRECT_BUY_ENTITIES,
    DELETE_DIRECT_BUY,
    UPDATE_DIRECT_BUY
} from "../../actions";
import { combineReducers } from "redux";
import { Shoutout } from "src/campaigns/shoutouts/types";

interface ById {
    [id: number]: DirectBuy;
}

export interface DirectBuysEntitiesState {
    byId: ById;
    allIds: number[];
    userSpecificShoutouts: Shoutout[];
}

const initialDirectBuysState: DirectBuysEntitiesState = {
    byId: {},
    userSpecificShoutouts: [],
    allIds: []
};

const byId = (
    state = initialDirectBuysState.byId,
    action: NormalizedAction
): ById => {
    switch (action.type) {
        case ADD_DIRECT_BUY_ENTITIES:
            return { ...state, ...action.response.entities.directBuy };
        case UPDATE_DIRECT_BUY:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    ...action.payload
                }
            };
        case DELETE_DIRECT_BUY:
            const newState = { ...state };
            delete newState[action.payload];
            return newState;
        default:
            return state;
    }
};

const allIds = (
    state = initialDirectBuysState.allIds,
    action: NormalizedAction
): number[] => {
    switch (action.type) {
        case ADD_DIRECT_BUY_ENTITIES:
            const currentIds = new Set(state);
            return [
                ...state,
                ...action.response.result.filter(
                    (id: number) => !currentIds.has(id)
                )
            ];
        case DELETE_DIRECT_BUY:
            return state.filter(id => id !== action.payload);
        default:
            return state;
    }
};

const directBuysEntitiesReducer = combineReducers<DirectBuysEntitiesState>({
    byId,
    allIds
} as any);

export default directBuysEntitiesReducer;
