import React, { useState } from "react";
import { DirectBuyStepCollection } from "../../../modules/direct-buy-step/types";
import { DirectBuyStepsEntitiesState } from "../../../modules/direct-buy-step/reducer";
import { ResourceContainer, ResourceDescription } from "../styledComponents";
import DirectBuyDetailsCopyLinksCell from "./DirectBuyDetailsCopyLinksCell";
import { BigOutlineButton } from "../../../../profile/components/Buttons";
import { addNotification } from "../../../../ui/notifications/actions";
import CopyToClipboard from "react-copy-to-clipboard";
import { colors } from "../../../../constants";
import { useDispatch } from "react-redux";
import { StepById } from "src/buys/modules/open-buys/reducer";

interface Props {
    collection: DirectBuyStepCollection;
    steps: DirectBuyStepsEntitiesState | StepById;
    actionCallback: VoidFunction;
}

const DirectBuyDetailsCopyLinks = ({
    collection,
    steps,
    actionCallback
}: Props) => {
    const dispatch = useDispatch();
    const [inactive, setInactive] = useState(false);
    const linkSteps = collection.combined
        .filter(id => steps[id].linkUrl)
        .map(id => steps[id]);
    const supplements = collection.supplements;

    const copyString = linkSteps
        .map(step => {
            let string = step.linkUrl || "";
            if (step.socialAccount) {
                string += ` - ${step.socialAccount.username} on ${step.socialAccount.platform}`;
            }
            return string;
        })
        .concat(
            supplements?.map(supplement => {
                let string = supplement.linkUrl || "";
                if (supplement.socialAccount) {
                    string += ` - ${supplement.socialAccount.username} on ${supplement.socialAccount.platform}`;
                }
                return string;
            }) || []
        )
        .join("\n");

    if (linkSteps.length === 0 && !supplements) {
        return null;
    }
    return (
        <ResourceContainer>
            {linkSteps.map(step => (
                <DirectBuyDetailsCopyLinksCell
                    key={step.id}
                    linkUrl={step.linkUrl}
                    linkAction={step.linkAction}
                    socialAccount={step.socialAccount}
                    actionCallback={actionCallback}
                />
            ))}
            {supplements &&
                supplements.map(
                    (supplement, index) =>
                        steps[supplement.instructionStepId]
                            .requiresPlugLink && (
                            <DirectBuyDetailsCopyLinksCell
                                key={`${index} supplement`}
                                linkUrl={supplement.linkUrl}
                                linkAction={supplement.linkAction}
                                socialAccount={supplement.socialAccount}
                                actionCallback={actionCallback}
                            />
                        )
                )}
            {copyString && linkSteps.length + (supplements?.length || 0) > 1 && (
                <CopyToClipboard
                    text={copyString}
                    onCopy={() => {
                        if (!inactive) {
                            actionCallback();
                            setInactive(true);
                            dispatch(addNotification("Copied link!"));
                            setTimeout(() => setInactive(false), 2000);
                        }
                    }}
                >
                    <BigOutlineButton
                        textColor={
                            inactive ? colors.primaryGray : colors.primaryBlue
                        }
                        style={{ marginTop: "10px" }}
                    >
                        {inactive ? "Links Copied" : "Copy All Links"}
                    </BigOutlineButton>
                </CopyToClipboard>
            )}
        </ResourceContainer>
    );
};

export default DirectBuyDetailsCopyLinks;
