import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    PhoneAuthParams,
    PhoneNumberParams,
    postPhoneAuthRequest,
    postPhoneNumber,
    postPhoneNumberCall
} from "../actions";
import { push } from "connected-react-router";
import { Route, Switch } from "react-router";
import PhoneNumberScreen from "../components/PhoneNumberScreen";
import PhoneAuthScreen from "../components/PhoneAuthScreen";
import { loggedIn } from "../../util";
import { colors } from "../../constants";

interface Props {
    postPhoneNumber: (phoneNumberParams: PhoneNumberParams) => void;
    postPhoneNumberResend: (phoneNumberParams: PhoneNumberParams) => void;
    postPhoneNumberCall: (phoneNumberParams: PhoneNumberParams) => void;
    postPhoneAuth: (phoneAuthParams: PhoneAuthParams) => void;
    push: (route: string) => void;
}

const PhoneAuth = ({
    postPhoneNumber,
    postPhoneNumberResend,
    postPhoneNumberCall,
    postPhoneAuth,
    push
}: Props) => {
    useEffect(() => {
        if (loggedIn()) {
            push("/");
        }
        document.body.style.backgroundColor = colors.primaryBlue;

        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [authCode, setAuthCode] = useState("");

    useEffect(() => {
        if (authCode.length === 4 && phoneNumber.length > 0) {
            postPhoneAuth({ phoneNumber, authCode });
        }
    }, [authCode]);

    return (
        <Switch>
            <Route
                path="/onboarding/phone/auth"
                render={() => {
                    return (
                        <PhoneAuthScreen
                            backClicked={() => push("/onboarding/phone")}
                            phoneNumber={phoneNumber}
                            authCode={authCode}
                            setAuthCode={setAuthCode}
                            resendClicked={() =>
                                postPhoneNumberResend({ phoneNumber })
                            }
                            callClicked={() =>
                                postPhoneNumberCall({ phoneNumber })
                            }
                        />
                    );
                }}
            />
            <Route
                path="/onboarding/phone"
                render={props => {
                    return (
                        <PhoneNumberScreen
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            nextClicked={() => postPhoneNumber({ phoneNumber })}
                        />
                    );
                }}
            />
        </Switch>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    postPhoneNumber: (phoneNumberRequest: PhoneNumberParams) =>
        dispatch(postPhoneNumber(phoneNumberRequest)),
    postPhoneNumberResend: (phoneNumberRequest: PhoneNumberParams) =>
        dispatch(postPhoneNumber(phoneNumberRequest)),
    postPhoneNumberCall: (phoneNumberRequest: PhoneNumberParams) =>
        dispatch(postPhoneNumberCall(phoneNumberRequest)),
    postPhoneAuth: (authRequest: PhoneAuthParams) =>
        dispatch(postPhoneAuthRequest(authRequest)),
    push: (route: string) => dispatch(push(route))
});

export default connect(null, mapDispatchToProps)(PhoneAuth);
