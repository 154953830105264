import React, { useEffect, useState } from "react";
import { DirectBuy } from "../../modules/direct-buy/types";
import { getDirectBuyPaymentCollectionArrayForDirectBuy } from "../../modules/direct-buy/util";
import { useSelector } from "react-redux";
import { selectDirectBuyStepEntities } from "../../selectors";
import { PaymentList, PaymentText } from "./styledComponents";
import { formatNumber } from "../../../util";
import {
    longStringForDirectBuyPayment,
    shortStringForDirectBuyPayment
} from "../../modules/direct-buy-payment/util";
import { paymentConjunctionToShortString } from "../../../types/PaymentConjunction";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { DirectBuyPaymentCollection } from "src/buys/modules/direct-buy-payment/types";

const OverflowHidden = styled.div`
    overflow: hidden;
`;

interface Props {
    payments: DirectBuyPaymentCollection[];
    expanded: boolean;
}

const DirectBuyDetailsPayments = ({ payments, expanded }: Props) => {
    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        }
    }, [firstRender]);
    return (
        <OverflowHidden>
            <AnimatePresence>
                {!expanded ? null : (
                    <PaymentList
                        initial={{
                            opacity: firstRender ? 1 : 0,
                            y: firstRender ? 0 : 100
                        }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 100 }}
                        transition={{ duration: 0.3 }}
                    >
                        {payments.map((paymentCollection, index) => {
                            const transaction = paymentCollection.transaction;
                            const payment = paymentCollection.payment;
                            let text;
                            if (transaction) {
                                const dollars =
                                    transaction.transactionAmount / 20;
                                text = (
                                    <b>{`$${formatNumber(
                                        Math.round(dollars * 100) / 100
                                    )} Paid`}</b>
                                );
                            } else if (payment) {
                                const directBuyPayments =
                                    payment.directBuyPayments;
                                const paymentConjunction =
                                    payment.paymentConjunction;
                                if (directBuyPayments.length === 1) {
                                    text = (
                                        <b>
                                            {longStringForDirectBuyPayment(
                                                payment.directBuyPayments[0]
                                            )}
                                        </b>
                                    );
                                } else {
                                    text = [];
                                    const paymentConjunctionString = paymentConjunctionToShortString(
                                        paymentConjunction
                                    );
                                    directBuyPayments.forEach(
                                        (payment, index) => {
                                            text.push(
                                                <b key={index}>
                                                    {shortStringForDirectBuyPayment(
                                                        payment
                                                    )}
                                                </b>
                                            );
                                            if (
                                                index !==
                                                directBuyPayments.length - 1
                                            ) {
                                                text.push(
                                                    ` ${paymentConjunctionString} `
                                                );
                                            }
                                        }
                                    );
                                }
                            }
                            return (
                                <PaymentText key={index}>{text}</PaymentText>
                            );
                        })}
                    </PaymentList>
                )}
            </AnimatePresence>
        </OverflowHidden>
    );
};

export default DirectBuyDetailsPayments;
