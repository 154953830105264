import { Media } from "src/media/types";
import { SocialAccount } from "src/profile/types";
import { Transaction } from "src/types/Transaction";
import { DirectBuyPayment } from "../direct-buy-payment/types";
import { BuyLinkAction, DirectBuyStep } from "../direct-buy-step/types";
import { Buyer } from "../direct-buy/types";

export interface OpenBuy {
    id: number;
    openBuyId: number;
    buyerId: number;
    name: string;
    description: string;
    startsAt: string;
    endsAt: string;
    budgetAmount: number;
    budgetSpend: number;
    publisher: OpenBuyPublisher;
    buyer?: Buyer;
    Buyer?: Buyer;
    status: OpenBuyStatus;
    allPublishers: boolean;
    viewedAt: string;
    approvedAt: string;
    rejectedAt: string;
    rejectionReason: string;
    removedAt: string;
    duration: number;
    removalReason: string;
    payout: Transaction;
    instructionSetId: number | null;
    instructionSet: OpenBuyInstructionSet;
    submissionSteps: OpenBuyInstructionStep[];
    instructionSteps?: number[];
    openBuySubmissions?: number[];
    openBuySupplements?: OpenBuySupplement[];
    submissionLimitPerPublisher: number;
    submissionLimitPerSocialAccount: number;
    openBuyTags: OpenBuyTag[];
    linkUrl: string;
    linkDescription: string;
    isPublic?: boolean;
    jetfuelPercentFee: number;
    leaderboard?: number[];
    publisherCount?: number;
    allowedPlatforms: string[];
    ruleSetId: number;
    posterUrl?: string;
    isApplication: boolean;
    isExperimental: boolean;
    isComplimentary: boolean;
    creatorScoreMinimum?: number;
    leaderboardScoreMinimum?: number;
    billType?: string;
    linkedOpenBuyId: null | number;
    autoAssignNewUsers: boolean;
    usageRightsLengthDays?: number;
}

export interface OpenBuyTag {
    name: string;
    iconUrl: string;
}

export interface OpenBuyPublisher {
    id: number;
    name: string;
    photoUrl: string;
    status: string;
    lastname: string;
    firstname: string;
    profile_photo_url: string;
    username: string;
}

export interface OpenBuyBuyer {
    id: number;
    name: string;
    photoUrl: string;
    buyerId: number;
    campaignId: number;
    advertiserId: number;
}

export interface OpenBuyInstructionSet {
    id: number;
    buyerId: number;
    buyer: OpenBuyBuyer;
    duration: number;
    instructionSteps: OpenBuyInstructionStep[];
    name: string;
}

export interface OpenBuyInstructionStep extends DirectBuyStep {
    requiresPlugLink: boolean;
    requiresSocialAccount: boolean;
    textSubmissionPlaceholder: string;
    shouldPayout: boolean;
    instructionPayments: DirectBuyPayment[];
    instructionSteps: number[];
    submissionLimit: number | null;
    submissionLimitPerAccount: number | null;
    allowedPlatforms: string[] | null;
    payoutTiming?: string;
    linkSubmissionPlaceholder: string;
}

export interface OpenBuyInstructionStepMedia {
    id: number;
    type: string;
    isPortrait: boolean;
    name: string;
    coverPhotoUrl: string;
    url: string;
    time: number;
    campaignId: number;
}

export interface OpenBuySubmissionSet {
    id: number;
    submissions: OpenBuySubmission[];
    publisherId: number;
    publisher?: OpenBuyPublisher;
    socialAccountId: number;
    socialAccount?: SocialAccount;
    openBuyId: number;
    leaderboardPinned?: boolean;
    leaderboardHidden?: boolean;
    conversationId?: string;
    status: OpenBuySubmissionStatuses;
    statusUpdatedAt: string | null;
    advertiserReviewStatus: OpenBuySubmissionStatuses | null;
    scraperFlag?: string | null;
    creatorRep?: AdminPublisher | null;
    creatorRepType?: string | null;
    createdAt: string | null;
    updatedAt: string | null;
}

export interface OpenBuySubmission {
    id: number;
    openBuyId: number;
    publisherId: number;
    instructionStepId: number;
    socialAccount: SocialAccount | null;
    customMedia: Media | null;
    generatedMedia: Media | null;
    customMediaId: number | null;
    generatedMediaId: number | null;
    createdAt: string | null;
    viewsActual: number | null;
    screenshotUrl: string | null;
    linkSubmission: string | null;
    textSubmission: string | null;
    status: OpenBuySubmissionStatuses;
    advertiserReviewStatus: OpenBuySubmissionStatuses | null;
    approvedAt: string | null;
    revisionRequestedAt: string | null;
    revisionRequest: string | null;
    rejectedAt: string | null;
    rejectionReason: string | null;
    payouts: Transaction[] | null;
    approvedBy?: AdminPublisher | null;
    rejectedBy?: AdminPublisher | null;
    revisionRequestedBy?: AdminPublisher | null;
    scraperFlag?: string;
    fullSubmissionUrl: string | null;
    socialAccountId: number | null;
    creatorRep?: AdminPublisher | null;
    creatorRepType?: string | null;
    leaderboardHidden: boolean;
    leaderboardPinned: boolean | null;
    openBuySubmissionSetId?: number;
    amountToBePaid?: number;
    mediaQuality?: number;
}

export interface AdminPublisher {
    firstname: string;
    lastname: string;
    id: number;
}

export enum OpenBuySubmissionStatuses {
    rejected = "rejected",
    approved = "approved",
    revision_requested = "revision_requested",
    pending = "pending",
    next_step = "next_step"
}

export interface OpenBuySupplement {
    publisherId: number;
    instructionStepId: number;
    linkUrl: string | null;
    linkAction: BuyLinkAction;
    socialAccount: SocialAccount | null;
    socialAccountId: number | null;
}

export enum OpenBuyStatus {
    pending = "pending",
    approved = "approved",
    rejected = "rejected"
}

export const openBuyStatusAll = [
    OpenBuyStatus.approved,
    OpenBuyStatus.pending,
    OpenBuyStatus.rejected
];

export enum OpenBuyState {
    new = "new",
    pending = "pending",
    viewed = "viewed",
    rejected = "rejected",
    revision_requested = "revision_requested",
    approved_next_step = "approved_next_step",
    approved = "approved",
    ended = "ended"
}
