import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS,
    FETCH_MEDIA_LEADERBOARD,
    FETCH_RAW_24_HOUR_ANALYTICS_DATA,
    FETCH_RAW_ANALYTICS_DATA,
    FETCH_REFERRAL_LEADERBOARD,
    FETCH_USER_ANALYTICS,
    FETCH_WEEKLY_ACTIVITY,
    fetchDailyCampaignLinkAnalyticsFailure,
    fetchDailyCampaignLinkAnalyticsSuccess,
    fetchMediaLeaderboardFailure,
    fetchMediaLeaderboardSuccess,
    fetchRaw24HourAnalyticsDataFailure,
    fetchRaw24HourAnalyticsDataSuccess,
    fetchRawAnalyticsData,
    fetchRawAnalyticsDataFailure,
    fetchRawAnalyticsDataSuccess,
    fetchReferralLeaderboardFailure,
    fetchReferralLeaderboardSuccess,
    fetchUserAnalyticsFailure,
    fetchUserAnalyticsSuccess,
    fetchWeeklyActivityFailure,
    fetchWeeklyActivitySuccess,
    rawAnalyticsDataLoaded,
    SEND_EMAIL_REPORT,
    sendEmailReportFailure,
    sendEmailReportSuccess
} from "./actions";
import { getApi } from "../Api";
import { Action, ResponseData } from "../types";
import moment from "moment";

function* fetchWeeklyActivityRequest() {
    try {
        const { data, status } = yield call(getApi, "/get_weekly_activity", {});

        if (status === 200) {
            yield put(fetchWeeklyActivitySuccess(data));
        } else {
            yield put(fetchWeeklyActivityFailure("status" + status));
        }
    } catch (error) {
        yield put(fetchWeeklyActivityFailure("Fetch weekly activity failure"));
    }
}

function* fetchUserAnalyticsRequest() {
    try {
        const { data, status } = yield call(getApi, "/get_user_analytics", {});

        if (status === 200) {
            yield put(fetchUserAnalyticsSuccess(data));
        } else {
            yield put(
                fetchUserAnalyticsFailure("Fetch user analytics failure")
            );
        }
    } catch (error) {
        yield put(fetchUserAnalyticsFailure("Fetch user analytics failure"));
    }
}

function* fetchReferralLeaderboardRequest() {
    try {
        const { data, status } = yield call(
            getApi,
            "/get_referal_leaderboard",
            {}
        );

        if (status === 200) {
            yield put(fetchReferralLeaderboardSuccess(data));
        } else {
            yield put(
                fetchReferralLeaderboardFailure(
                    "Fetch referral leaderboard failure"
                )
            );
        }
    } catch (error) {
        yield put(
            fetchReferralLeaderboardFailure(
                "Fetch referral leaderboard failure"
            )
        );
    }
}

function* fetchMediaLeaderboardRequest() {
    try {
        const { data, status } = yield call(
            getApi,
            "/get_media_monthly_leaderboard",
            {}
        );

        if (status === 200) {
            yield put(fetchMediaLeaderboardSuccess(data));
        } else {
            yield put(
                fetchMediaLeaderboardFailure("Fetch media leaderboard failure")
            );
        }
    } catch (error) {
        yield put(
            fetchMediaLeaderboardFailure("Fetch media leaderboard failure")
        );
    }
}

function* fetchRawAnalyticsDataRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(getApi, "/v1/bqReport", {
            start_date: payload.startDate,
            end_date: moment(payload.endDate)
                .add(1, "day")
                .format("YYYY-MM-DD"),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            columns:
                "date,campaign,campaign_name,campaign_image_url,media,media_url,media_cover_photo_url,media_type,media_name,coins,dollars",
            format: "json"
        });

        if (data.success === 1) {
            yield put(
                fetchRawAnalyticsDataSuccess({
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    data: data.data
                })
            );

            if (payload.primary) {
                yield put(rawAnalyticsDataLoaded());
                yield put(
                    fetchRawAnalyticsData({
                        startDate: moment()
                            .subtract(6, "months")
                            .format("YYYY-MM-DD"),
                        endDate: moment().format("YYYY-MM-DD")
                    })
                );
            }
        } else {
            yield put(fetchRawAnalyticsDataFailure(data.message));
        }
    } catch (error) {
        yield put(fetchRawAnalyticsDataFailure("Oopsies"));
    }
}

function* fetchRaw24HourAnalyticsDataRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(getApi, "/v1/24_hour_report", {
            columns:
                "campaign,campaign_name,campaign_image_url,media,media_url,media_cover_photo_url,media_type,media_name,coins,dollars",
            format: "json"
        });

        if (data.success === 1) {
            yield put(fetchRaw24HourAnalyticsDataSuccess(data.data));
        } else {
            yield put(fetchRaw24HourAnalyticsDataFailure(data.message));
        }
    } catch (error) {
        yield put(fetchRaw24HourAnalyticsDataFailure("Oopsies"));
    }
}

function* sendEmailReportRequest({ payload }: Action) {
    try {
        let data;

        if (payload.startDate && payload.endDate) {
            const result: ResponseData = yield call(getApi, "/v1/bqReport", {
                start_date: payload.startDate,
                end_date: moment(payload.endDate)
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                columns:
                    "date,campaign,campaign_name,media,media_url,media_type,media_name,coins,dollars",
                sort: "date:ASC",
                format: "email_csv",
                email_csv: payload.email
            });
            data = result.data;
        } else {
            const result: ResponseData = yield call(
                getApi,
                "/v1/24_hour_report",
                {
                    columns:
                        "campaign,campaign_name,media,media_url,media_type,media_name,coins,dollars",
                    format: "email_csv",
                    email_csv: payload.email
                }
            );
            data = result.data;
        }

        if (data.success === 1) {
            yield put(sendEmailReportSuccess());
        } else {
            yield put(sendEmailReportFailure(data.message));
        }
    } catch (error) {
        yield put(sendEmailReportFailure("Oopsies"));
    }
}

function* fetchDailyCampaignLinkAnalyticsRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            getApi,
            "/get_daily_campaign_link_analytics",
            payload
        );

        if (status === 200) {
            yield put(fetchDailyCampaignLinkAnalyticsSuccess(data));
        } else {
            yield put(fetchDailyCampaignLinkAnalyticsFailure());
        }
    } catch (error) {
        yield put(fetchDailyCampaignLinkAnalyticsFailure());
    }
}

export default function* analyticsSaga() {
    yield all([
        takeEvery(
            FETCH_RAW_ANALYTICS_DATA.REQUEST,
            fetchRawAnalyticsDataRequest
        ),
        takeEvery(
            FETCH_RAW_24_HOUR_ANALYTICS_DATA.REQUEST,
            fetchRaw24HourAnalyticsDataRequest
        ),
        takeEvery(SEND_EMAIL_REPORT.REQUEST, sendEmailReportRequest),
        takeEvery(FETCH_WEEKLY_ACTIVITY.REQUEST, fetchWeeklyActivityRequest),
        takeEvery(FETCH_USER_ANALYTICS.REQUEST, fetchUserAnalyticsRequest),
        takeEvery(
            FETCH_REFERRAL_LEADERBOARD.REQUEST,
            fetchReferralLeaderboardRequest
        ),
        takeEvery(
            FETCH_MEDIA_LEADERBOARD.REQUEST,
            fetchMediaLeaderboardRequest
        ),
        takeEvery(
            FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS.REQUEST,
            fetchDailyCampaignLinkAnalyticsRequest
        )
    ]);
}
