import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import {
    campaignCustomLinksModal,
    CustomLinksModalState
} from "../../ui/custom-links/actions";

const Main = styled.div`
    padding: 18px;
    background-color: ${colors.lightTeal};
    border-bottom: 1px solid ${colors.mediumTeal};
    display: flex;
`;

const WhiteButton = styled.div`
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: ${colors.primaryBlue};
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    user-select: none;
    cursor: pointer;
`;

const IndexButton = styled(WhiteButton)`
    width: 100%;
    font-size: ${fonts.subtitle}px;
`;

const CreateButton = styled(WhiteButton)`
    width: 46px;
    min-width: 46px;
    margin-left: 10px;
`;

const PlusIcon = styled.img`
    width: 18px;
    height: 18px;
`;

interface Props {
    campaignCustomLinksModal: (state: CustomLinksModalState) => void;
}

const CustomLinksCard = ({ campaignCustomLinksModal }: Props) => {
    return (
        <Main>
            <IndexButton
                onClick={() =>
                    campaignCustomLinksModal(CustomLinksModalState.index)
                }
            >
                Custom Links
            </IndexButton>
            <CreateButton
                onClick={() =>
                    campaignCustomLinksModal(CustomLinksModalState.create)
                }
            >
                <PlusIcon src={"/plus/plus@2x.png"} />
            </CreateButton>
        </Main>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    campaignCustomLinksModal: (state: CustomLinksModalState) =>
        dispatch(campaignCustomLinksModal(state))
});

export default connect(null, mapDispatchToProps)(CustomLinksCard);
