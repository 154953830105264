import React, { useState } from "react";
import styled from "styled-components";
import moment, { Moment } from "moment";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import { datePickerRanges } from "../../components/datesUtil";
// @ts-ignore
import DatePicker from "react-mobile-datepicker";
import { colors, fonts } from "../../constants";
import { AnimatePresence } from "framer-motion";
import HalfScreenModal from "src/modals/HalfScreenModal";
import { FlexVerticallyCentered } from "src/components/CommonStyles";

const Main = styled.div`
    padding: 19px 18px;
    box-sizing: border-box;
    font-size: 12px;
`;

const Title = styled.div`
    color: darkgray;
    text-align: left;
    font-weight: bold;
    margin-bottom: 12px;
`;

const Container = styled.div`
    border-radius: 6px;
    border: 1px solid ${colors.mediumTeal};
    background-color: white;
    margin-bottom: 16px;
    overflow: hidden;
    transition: 0.1s ease-in;

    & > * ~ * {
        border-top: 1px solid ${colors.mediumTeal};
    }

    @media (hover: hover) {
        :hover {
            border: ${colors.darkTeal} solid 1px;
        }
    }
`;
interface RowProps {
    dateRow?: boolean;
}
const Row = styled.div<RowProps>`
    ${FlexVerticallyCentered}
    position: relative;
    text-align: left;
    font-size: ${fonts.subtitle}px;
    line-height: 1em;
    /* padding: 0 16px; */
    padding: ${props => (props.dateRow ? "0px" : "0px 16px")};
    height: 46px;
    cursor: pointer;
    user-select: none;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
        
    &:hover {
        background-color: ${colors.lightGray};
    }
`;
const DateInput = styled.input`
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    /* padding: 12px 16px; */
    font-size: inherit;
    cursor: pointer;
    -webkit-appearance: none;

    &::-webkit-calendar-picker-indicator {
        width: 100%;
        height: 100%;
        padding-left: 0px;
        margin-left: 0px;
        opacity: 0;
        cursor: pointer;
    }
    &::-webkit-datetime-edit-fields-wrapper {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
    @-moz-document url-prefix() {
        padding: 0px 16px;
    }
    &:hover {
        background-color: ${colors.lightGray};
    }
`;

interface Props {
    title?: string;
    initialDates: [Moment, Moment];
    onCancelTapped: () => void;
    onDoneTapped: (dateRange: [Moment, Moment], selected?: string) => void;
    clearTimeRange?: () => void;
}

const SetDatesScreen = ({
    onCancelTapped,
    onDoneTapped,
    initialDates,
    title,
    clearTimeRange
}: Props) => {
    const [startDate, setStartDate] = useState(initialDates[0]);
    const [endDate, setEndDate] = useState(initialDates[1]);

    const leftNavButton = (
        <NavButton onClick={onCancelTapped}>Cancel</NavButton>
    );

    const rightNavButton = (
        <NavButton onClick={() => onDoneTapped([startDate, endDate])}>
            <div style={{ color: "#0079F5" }}>Done</div>
        </NavButton>
    );

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = moment(e.target.value);
        if (newDate > endDate) {
            setEndDate(newDate);
            setStartDate(newDate);
        } else {
            setStartDate(newDate);
        }
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = moment(e.target.value);
        if (newDate < startDate) {
            setStartDate(newDate);
            setEndDate(newDate);
        } else {
            setEndDate(newDate);
        }
    };

    return (
        <>
            <SimpleNavbar
                left={leftNavButton}
                right={rightNavButton}
                title={title}
            />
            <Main>
                <Title>START DATE</Title>
                <Container>
                    <Row dateRow>
                        <DateInput
                            type="date"
                            onChange={handleStartDateChange}
                            value={startDate.format("YYYY-MM-DD")}
                        />
                    </Row>
                </Container>
                <Title>END</Title>
                <Container>
                    <Row dateRow>
                        <DateInput
                            type="date"
                            onChange={handleEndDateChange}
                            value={endDate.format("YYYY-MM-DD")}
                        />
                    </Row>
                </Container>
                <Title>PRESETS</Title>
                <Container>
                    {Object.keys(datePickerRanges).map((label, idx) => {
                        return (
                            <Row
                                key={idx}
                                onClick={() => {
                                    setStartDate(datePickerRanges[label][0]);
                                    setEndDate(datePickerRanges[label][1]);
                                    onDoneTapped(
                                        [
                                            datePickerRanges[label][0],
                                            datePickerRanges[label][1]
                                        ],
                                        label
                                    );
                                }}
                            >
                                {label}
                            </Row>
                        );
                    })}
                    {clearTimeRange && (
                        <Row onClick={clearTimeRange}>All Time</Row>
                    )}
                </Container>
            </Main>
        </>
    );
};

export default SetDatesScreen;
