import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { goBack } from "connected-react-router";
import { get } from "../../Api";
import { PublicProfile, SocialAccount } from "../../profile/types";
import FullscreenLoader from "../../components/FullscreenLoader";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import PublicAccountView from "../../profile/accounts/public/view/PublicAccountView";
import PaddingAround from "../../components/PaddingAround";
import LockCard from "../../profile/components/LockCard";
import MainContainer from "src/components/MainContainer";
import { getIsMobile } from "src/utils/functions/selectors";
import Navbar from "src/components/Navbar";

interface Props {
    socialId: string;
    goBack: () => void;
}

const SearchAccountDetails = ({ socialId, goBack }: Props) => {
    const [account, setAccount] = useState<SocialAccount | null>(null);
    const [profile, setProfile] = useState<PublicProfile | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        get(`/v1/social_account/${socialId}`, null)
            .then(({ data }) => {
                setAccount(data.data);
                return data.data.publisher.id;
            })
            .then((publisherId: string) => {
                get(`/v1/publisher/${publisherId}/profile`, null).then(
                    ({ data }) => {
                        setProfile(data.data);
                        setLoading(false);
                    }
                );
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    const isMobile = useSelector(getIsMobile);

    if (loading)
        return (
            <>
                <Navbar back />

                <FullscreenLoader />
            </>
        );

    if (!account)
        return (
            <>
                <Navbar marginBottom={20} back />

                <MainContainer>
                    <PaddingAround>
                        <LockCard
                            text={"Sorry, no account found"}
                            button={{ text: "Go back", callback: goBack }}
                        />
                    </PaddingAround>
                </MainContainer>
            </>
        );

    return (
        <>
            <Navbar marginBottom={20} back />

            <MainContainer>
                {account && profile && (
                    <PublicAccountView
                        account={account}
                        publicProfile={profile}
                    />
                )}
            </MainContainer>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    goBack: () => dispatch(goBack())
});

export default connect(null, mapDispatchToProps)(SearchAccountDetails);
