import { Shoutout } from "../types";

export enum ShoutoutState {
    Unreserved,
    Reserved,
    Confirming,
    Blocking,
    Confirmed,
    Rejected
}

function evaluateShoutoutState(shoutout: Shoutout) {
    const fillOrders = shoutout.ShoutoutFillOrders;
    let shoutoutState = ShoutoutState.Unreserved;
    let numCompleted = 0;

    for (let i = 0; i < fillOrders.length; i++) {
        const currOrder = fillOrders[i];

        if (currOrder.purchase_status === "posting") {
            const timeElapsed = Date.now() - Date.parse(shoutout.start_time);
            if (timeElapsed < 1000 * 60 * 60 * 24) {
                shoutoutState = ShoutoutState.Reserved;
            } else {
                shoutoutState = ShoutoutState.Blocking;
            }
        } else if (currOrder.purchase_status === "verification_rejected") {
            shoutoutState = ShoutoutState.Rejected;
        } else if (
            currOrder.purchase_status === "verification_submitted" ||
            currOrder.purchase_status === "in_dispute"
        ) {
            shoutoutState = ShoutoutState.Confirming;
        } else if (currOrder.purchase_status === "verification_completed") {
            numCompleted++;
        }

        if (numCompleted === fillOrders.length) {
            shoutoutState = ShoutoutState.Confirmed;
        }
    }

    return shoutoutState;
}

export default evaluateShoutoutState;
