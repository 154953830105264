import React from "react";
import { colors } from "src/constants";
import { SocialAccount } from "src/profile/types";
import Platforms from "src/social-accounts/Platforms";
import styled from "styled-components";

const ProfilePic = styled.img<{ isMedia: boolean }>`
    height: 46px;
    width: 46px;
    border-radius: 10px;
    margin-right: 12px;
    ${props =>
        props.isMedia &&
        `
        width: 80px;
        height: auto;
    `}
`;
const Column = styled.div`
    display: flex;
    flex-direction: column;
`;
const Row = styled.div<{ onClick?: () => void }>`
    display: flex;
    cursor: ${props => (props.onClick ? "curosr" : "default")};
`;
const Name = styled.div`
    font-size: 14px;
    font-weight: 500;
    text-align: left;
`;

const Count = styled.div`
    color: ${colors.gray2};
    font-size: 12px;
    text-align: left;
    font-weight: 400;
`;

const Icon = styled.img`
    height: 15px;
    width: 15px;
    border-radius: 4px;
    position: absolute;
    bottom: 15px;
    left: 40px;
`;

interface Props {
    picture: string | null;
    username: string;
    count?: string;
    isMedia?: boolean;
    icon?: string;
    onClick?: () => void;
}

const SocialAccountHeader = ({
    picture,
    username,
    count,
    isMedia,
    icon,
    onClick
}: Props) => {
    return (
        <Row onClick={() => onClick && onClick()}>
            <ProfilePic src={picture || "/plug.png"} isMedia={!!isMedia} />
            {icon && <Icon src={icon} />}
            <Column>
                <Name>{username}</Name>
                <Count>{count}</Count>
            </Column>
        </Row>
    );
};
export default SocialAccountHeader;
