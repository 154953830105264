import React from "react";
import CheckBox from "src/components/CheckBox";
import { colors } from "src/constants";
import styled from "styled-components";
import { MultiSelectReference } from "../hooks/useMultiSelect";
import { ColumnsReference } from "../PlugTable";

const TableHeader = styled.tr`
    background-color: ${colors.quaternaryLightGray};
    margin-bottom: 10px;
`;
const HeaderCell = styled.th<{ isSortable: boolean }>`
    cursor: ${props => (props.isSortable ? "pointer" : "default")};
    text-align: left;
    padding: 16px 12px;
    font-weight: 500;
    transition: background-color 0.2s ease;
    white-space: nowrap;
    overflow-x: auto;
    &:hover {
        background-color: ${props =>
            props.isSortable ? colors.lightGray : "initial"};
    }

    &:first-child {
        border-radius: 8px 0px 0px 8px;
    }
    &:last-child {
        border-radius: 0px 8px 8px 0px;
    }
`;
const SortIcon = styled.img<{ isAscending: boolean }>`
    margin-left: 2px;
    margin-bottom: 2px;
    width: 12px;
    height: 12px;
    ${props => props.isAscending && `transform: rotate(180deg)`}
`;
const MultiSelectAllCell = styled.th`
    width: 31px;
    max-width: 31px;
`;
const StyledCheckBox = styled(CheckBox)`
    background-color: white;
    border-radius: 6px;
`;

export interface ManualPlugTableSort {
    onSort: (by: string, direction: "ASC" | "DESC") => void;
    by: string;
    direction: "ASC" | "DESC";
}

interface Props {
    columnsOrder: string[];
    columnsReference: ColumnsReference;
    sortReference: any;
    multiSelectReference?: MultiSelectReference;
    manualSort?: ManualPlugTableSort;
}

const TableColumns = ({
    columnsOrder,
    columnsReference,
    sortReference,
    multiSelectReference,
    manualSort
}: Props) => {
    return (
        <thead>
            <TableHeader>
                {multiSelectReference && (
                    <MultiSelectAllCell
                        onClick={
                            multiSelectReference.allSelected
                                ? multiSelectReference.deselectAll
                                : multiSelectReference.selectAll
                        }
                    >
                        <StyledCheckBox
                            selected={multiSelectReference.allSelected}
                        />
                    </MultiSelectAllCell>
                )}
                {columnsOrder.map((coreKey: string, idx: number) => {
                    const currentColumn = columnsReference[coreKey];
                    const columnStyle = {
                        ...(currentColumn.columnWidth && {
                            width: currentColumn.columnWidth,
                            maxWidth: currentColumn.columnWidth,
                            minWidth: currentColumn.columnWidth
                        }),
                        ...currentColumn.columnStyle
                    };
                    return (
                        <HeaderCell
                            onClick={() => {
                                if (currentColumn.sortType)
                                    manualSort
                                        ? manualSort.onSort(
                                              currentColumn.coreKey,
                                              manualSort.direction === "ASC"
                                                  ? "DESC"
                                                  : "ASC"
                                          )
                                        : sortReference.handleSort(
                                              currentColumn.coreKey,
                                              currentColumn.sortType,
                                              sortReference.sortBy
                                          );
                            }}
                            isSortable={!!currentColumn.sortType}
                            key={coreKey + idx}
                            style={columnStyle}
                        >
                            {currentColumn.displayValue
                                ? currentColumn.displayValue
                                : currentColumn.coreKey}

                            {!manualSort &&
                                sortReference.sortBy &&
                                sortReference.sortBy.coreKey ===
                                    currentColumn.coreKey && (
                                    <SortIcon
                                        isAscending={
                                            sortReference.sortBy.direction ===
                                            "ASC"
                                        }
                                        src={"/sortArrow.svg"}
                                        alt=""
                                    />
                                )}
                            {manualSort?.by === currentColumn.coreKey && (
                                <SortIcon
                                    isAscending={manualSort.direction === "ASC"}
                                    src={"/sortArrow.svg"}
                                    alt=""
                                />
                            )}
                        </HeaderCell>
                    );
                })}
            </TableHeader>
        </thead>
    );
};

export default TableColumns;
