import React from "react";
import styled from "styled-components";

const Main = styled.div`
    position: relative;
`;

const Menu = styled.div`
    z-index: 99;
`;

interface Props {
    button: React.ReactElement;
    children: any;
    closeOnClick?: boolean;
}

interface State {
    showMenu: boolean;
}

class DropdownMenu extends React.Component<Props, State> {
    private dropdownMenu: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            showMenu: false
        };

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    showMenu(event: any) {
        event.preventDefault();

        this.setState({ showMenu: true }, () => {
            document.addEventListener("click", this.closeMenu);
        });
    }

    closeMenu(event: any) {
        if (!this.dropdownMenu) {
            document.removeEventListener("click", this.closeMenu);
        } else if (
            this.props.closeOnClick ||
            !this.dropdownMenu.contains(event.target)
        ) {
            this.setState({ showMenu: false }, () => {
                document.removeEventListener("click", this.closeMenu);
            });
        }
    }

    render() {
        return (
            <Main>
                {React.cloneElement(this.props.button, {
                    onClick: this.showMenu
                })}
                {this.state.showMenu ? (
                    <Menu
                        ref={element => {
                            this.dropdownMenu = element;
                        }}
                    >
                        {this.props.children}
                    </Menu>
                ) : null}
            </Main>
        );
    }
}

export default DropdownMenu;
