export const indicatorColors = {
    lightBlue: "#61AFFF",
    darkBlue: "#0479F5",
    lightYellow: "#EAE34B",
    darkYellow: "#FFCA0F",
    lightRed: "#FF2394",
    darkRed: "#EE3300",
    inactiveLight: "#E0EEF2",
    inactiveDark: "#CFDFE3",
    ended: "#000000",
    green: "#009330"
};

export const indicatorGradients = {
    blueGradient: `linear-gradient(to bottom left, ${indicatorColors.lightBlue}, ${indicatorColors.darkBlue})`,
    yellowGradient: `linear-gradient(to bottom left, ${indicatorColors.lightYellow}, ${indicatorColors.darkYellow})`,
    redGradient: `linear-gradient(to bottom left, ${indicatorColors.lightRed}, ${indicatorColors.darkRed})`
};
