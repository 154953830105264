import React from "react";
import PaymentSetDropdown from "./PaymentSetDropdown";
import { AbstractPaymentType, allAbstractPaymentTypes } from "../types";

interface Props {
    abstractPaymentType: AbstractPaymentType | null;
    setAbstractPaymentType: (abstractPaymentType: AbstractPaymentType) => void;
}

const PaymentTypeDropdown = ({
    abstractPaymentType,
    setAbstractPaymentType
}: Props) => {
    return (
        <PaymentSetDropdown
            itemList={allAbstractPaymentTypes}
            selected={abstractPaymentType}
            displayName={abstractPaymentType || undefined}
            displayEmptyState={"No Payment Type Selected"}
            handleOnClick={item => {
                setAbstractPaymentType(item);
            }}
            getItemName={item => item}
        />
    );
};

export default PaymentTypeDropdown;
