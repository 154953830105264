import { firestore } from "firebase";
import { Action, NormalizedActionGeneric, NormalizedResponse } from "src/types";
import { GroupUserSettings } from "../types/GroupUserSettings";

export const UPDATE_GROUP_USER_SETTINGS_CHANNEL =
    "UPDATE_GROUP_USER_SETTINGS_CHANNEL";

export interface NormalizedSettings {
    [key: string]: { [userId: string]: GroupUserSettings };
}

export function updateGroupUserSettingsChannel(
    normalizedSettings: NormalizedSettings
): Action {
    return {
        type: UPDATE_GROUP_USER_SETTINGS_CHANNEL,
        payload: { normalizedSettings }
    };
}

// -----------------------------------------------------------------------------

export const UPDATE_GROUP_CHANNEL_INFO = "UPDATE_GROUP_CHANNEL_INFO";

interface GroupChannelInfoParams {
    conversationId: string;
    displayName?: string;
    imageId?: string;
    groupDescription?: string;
}

export function updateGroupChannelInfo(
    groupChannelInfoParams: GroupChannelInfoParams
): Action {
    return {
        type: UPDATE_GROUP_CHANNEL_INFO,
        payload: { groupChannelInfoParams }
    };
}

// -----------------------------------------------------------------------------

export const UPDATE_GROUP_USER_SETTINGS = "UPDATE_GROUP_USER_SETTINGS";
export const UPDATE_GROUP_ADMIN_STATUS = "UPDATE_GROUP_ADMIN_STATUS";

interface GroupUserSettingsParams {
    id: string;
    isAdmin?: boolean;
    isBanned?: boolean;
    isMutedIndefinitely?: boolean;
    isMutedUntil?: number | firestore.FieldValue;
    blocked?: number;
    reported?: number;
}

export function updateGroupUserSettings(
    groupUserSettingsParams: GroupUserSettingsParams
): Action {
    return {
        type: UPDATE_GROUP_USER_SETTINGS,
        payload: { groupUserSettingsParams }
    };
}

interface GroupAdminStatusParams {
    groupUserSettingsId: string;
    conversationId: string;
    userId: string;
    isAdmin: boolean;
}

export function updateGroupAdminStatus(
    groupAdminStatusParams: GroupAdminStatusParams
): Action {
    return {
        type: UPDATE_GROUP_ADMIN_STATUS,
        payload: { groupAdminStatusParams }
    };
}

// -----------------------------------------------------------------------------

export const UPDATE_GROUP_ACTIONS = {
    REMOVE_USER: "UPDATE_GROUP_ACTIONS_REMOVE_USER",
    LEAVE_GROUP: "UPDATE_GROUP_ACTIONS_LEAVE_GROUP",
    ADD_MEMBER: "UPDATE_GROUP_ACTIONS_ADD_MEMBER",
    ADD_MEMBER_SUCCESS: "UPDATE_GROUP_ACTIONS_ADD_MEMBER_SUCCESS"
};

export function updateGroupRemoveUser(
    conversationId: string,
    userId: string
): Action {
    return {
        type: UPDATE_GROUP_ACTIONS.REMOVE_USER,
        payload: { conversationId, userId }
    };
}

export interface AddMemberParams {
    publisherId: string;
    username: string;
    avatarUrl: string | null;
}

export function updateGroupActionsAddMember(
    conversationId: string,
    users: AddMemberParams[]
): Action {
    return {
        type: UPDATE_GROUP_ACTIONS.ADD_MEMBER,
        payload: { conversationId, users }
    };
}

export function updateGroupActionsAddMemberSuccess(): Action {
    return {
        type: UPDATE_GROUP_ACTIONS.ADD_MEMBER_SUCCESS,
        payload: null
    };
}

// -----------------------------------------------------------------------------

export const SET_CONVERSATION_PAUSE_DURATION =
    "SET_CONVERSATION_PAUSE_DURATION";

interface PauseDurationParams {
    conversationId: string;
    notificationsPausedIndefinitely?: boolean;
    notificationsPausedUntil?: number | null;
}

export function setConversationPauseDuration(
    pauseDurationParams: PauseDurationParams
): Action {
    return {
        type: SET_CONVERSATION_PAUSE_DURATION,
        payload: { pauseDurationParams }
    };
}
