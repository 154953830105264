import moment from "moment";
import Platforms from "../../../social-accounts/Platforms";
import { formatNumber, secondsToTimeIntervals } from "../../../util";
import {
    DirectBuyStep,
    DirectBuyStepAction,
    DirectBuyStepCollection,
    DirectBuyStepCollectionTreatment
} from "./types";
import { DirectBuyPaymentCollection } from "../direct-buy-payment/types";
import { DirectBuyStepsEntitiesState } from "./reducer";
import { DirectBuyStepsExpandedState } from "../../../ui/direct-buys/reducer";
import { ApprovalStatus } from "../../../types/ApprovalStatus";
import { DirectBuy } from "../direct-buy/types";
import { OpenBuyInstructionStep } from "../open-buys/types";

// General Utils -------------------------------------------------------------------

function formatDateForDirectBuyStep(date: string): string {
    return moment(date).format("d MMMM h:mm a zzz");
}

export function getDirectBuyStepsForParentObject(
    parentObject: DirectBuy | DirectBuyStep,
    directBuyStepsState: DirectBuyStepsEntitiesState
): DirectBuyStep[] | null {
    const childStepIds = parentObject.directBuySteps;
    if (!childStepIds) {
        return null;
    }
    const childSteps: DirectBuyStep[] = childStepIds.map(
        stepId => directBuyStepsState[stepId]
    );
    if (childSteps.length === 0) {
        return null;
    }
    return childSteps;
}

// DirectBuyStep Utils -------------------------------------------------------------------

export function directBuyStepIsPending(
    directBuyStep: DirectBuyStep,
    directBuyStepsState: DirectBuyStepsEntitiesState
): boolean {
    if (
        directBuyStep.customMedia &&
        directBuyStep.customMedia.mediaStatus === "pending"
    ) {
        return true;
    }
    if (
        directBuyStep.status === ApprovalStatus.pending &&
        directBuyStep.completedAt
    ) {
        return true;
    }
    const childSteps = getDirectBuyStepsForParentObject(
        directBuyStep,
        directBuyStepsState
    );
    if (childSteps) {
        for (const step of childSteps) {
            if (directBuyStepIsPending(step, directBuyStepsState)) {
                return true;
            }
        }
    }
    return false;
}

export function directBuyStepIsRejected(
    directBuyStep: DirectBuyStep,
    directBuyStepsState: DirectBuyStepsEntitiesState
): boolean {
    if (
        directBuyStep.customMedia &&
        directBuyStep.customMedia.mediaStatus === "rejected"
    ) {
        return true;
    }
    if (directBuyStep.status === ApprovalStatus.rejected) {
        return true;
    }
    const childSteps = getDirectBuyStepsForParentObject(
        directBuyStep,
        directBuyStepsState
    );
    if (childSteps) {
        for (const step of childSteps) {
            if (directBuyStepIsRejected(step, directBuyStepsState)) {
                return true;
            }
        }
    }
    return false;
}

export function directBuyStepIsApproved(
    directBuyStep: DirectBuyStep,
    directBuyStepsState: DirectBuyStepsEntitiesState
): boolean {
    if (directBuyStep.status !== ApprovalStatus.approved) {
        return false;
    }
    const childSteps = getDirectBuyStepsForParentObject(
        directBuyStep,
        directBuyStepsState
    );
    if (childSteps) {
        for (const step of childSteps) {
            if (
                step.requiresBuyerApproval &&
                !directBuyStepIsApproved(step, directBuyStepsState)
            ) {
                return false;
            }
        }
    }
    return true;
}

export function directBuyStepIsExpandable(
    directBuyStep: DirectBuyStep,
    directBuyStepsState: DirectBuyStepsEntitiesState
): boolean {
    const childSteps = getDirectBuyStepsForParentObject(
        directBuyStep,
        directBuyStepsState
    );
    if (!childSteps) {
        return false;
    }
    const allCollapsible = childSteps.every(step =>
        directBuyStepIsCollapsible(step)
    );
    return !allCollapsible;
}

function directBuyStepIsCollapsible(directBuyStep: DirectBuyStep): boolean {
    return !directBuyStep.directBuySteps;
}

export function getDirectBuyStepCollectionArrayForDirectBuyStep(
    directBuyStep: DirectBuyStep,
    directBuyStepsState: DirectBuyStepsEntitiesState,
    directBuyStepsExpandedState: DirectBuyStepsExpandedState,
    indent: number = 0
): DirectBuyStepCollection[] {
    const children = getDirectBuyStepsForParentObject(
        directBuyStep,
        directBuyStepsState
    );
    if (children && children.every(step => directBuyStepIsCollapsible(step))) {
        return [
            {
                indent,
                parent: directBuyStep.id,
                children: directBuyStep.directBuySteps,
                combined: [
                    directBuyStep.id,
                    ...(directBuyStep.directBuySteps || [])
                ]
            }
        ];
    } else if (children && directBuyStepsExpandedState[directBuyStep.id]) {
        let array: DirectBuyStepCollection[] = [
            {
                indent,
                parent: directBuyStep.id,
                combined: [directBuyStep.id]
            }
        ];
        for (const step of children) {
            array = array.concat(
                getDirectBuyStepCollectionArrayForDirectBuyStep(
                    step,
                    directBuyStepsState,
                    directBuyStepsExpandedState,
                    indent + 1
                )
            );
        }
        return array;
    } else {
        return [
            {
                indent,
                parent: directBuyStep.id,
                combined: [directBuyStep.id]
            }
        ];
    }
}

export function getTreatmentArrayForDirectBuyStepCollectionArray(
    directBuyStepCollections: DirectBuyStepCollection[],
    directBuyStepsState: DirectBuyStepsEntitiesState
): DirectBuyStepCollectionTreatment[] {
    const foundFirstUnapprovedSet: Set<number> = new Set();
    let noMoreActive: boolean = false;
    const treatments: DirectBuyStepCollectionTreatment[] = [];
    for (const stepCollection of directBuyStepCollections) {
        if (
            directBuyStepIsApproved(
                directBuyStepsState[stepCollection.parent],
                directBuyStepsState
            )
        ) {
            treatments.push(DirectBuyStepCollectionTreatment.approved);
            continue;
        }
        if (
            directBuyStepIsRejected(
                directBuyStepsState[stepCollection.parent],
                directBuyStepsState
            )
        ) {
            treatments.push(DirectBuyStepCollectionTreatment.rejected);
        } else if (
            foundFirstUnapprovedSet.has(stepCollection.indent) ||
            noMoreActive
        ) {
            treatments.push(DirectBuyStepCollectionTreatment.inactive);
        } else {
            treatments.push(DirectBuyStepCollectionTreatment.active);
        }
        if (!foundFirstUnapprovedSet.has(stepCollection.indent)) {
            foundFirstUnapprovedSet.add(stepCollection.indent);
        } else {
            noMoreActive = true;
        }
    }
    return treatments;
}

export function getDirectBuyPaymentCollectionForDirectBuyStep(
    directBuyStep: DirectBuyStep
): DirectBuyPaymentCollection {
    let paymentCollection: DirectBuyPaymentCollection = {
        payment: null,
        transaction: directBuyStep.payout
    };
    const { directBuyPayments, paymentConjunction } = directBuyStep;
    if (directBuyPayments) {
        paymentCollection = {
            ...paymentCollection,
            payment: { directBuyPayments, paymentConjunction }
        };
    }
    return paymentCollection;
}

export function directBuyStepMatchesSearchString(
    directBuyStep: OpenBuyInstructionStep,
    string: string
): boolean {
    const searchString = string.toLowerCase();

    if (directBuyStep.description?.toLowerCase().includes(searchString)) {
        return true;
    }
    if (directBuyStep.mediaId?.toString().includes(searchString)) {
        return true;
    }
    if (directBuyStep.media?.name?.toLowerCase().includes(searchString)) {
        return true;
    }
    if (
        directBuyStep.socialAccount?.username
            .toLowerCase()
            .includes(searchString)
    ) {
        return true;
    }
    if (
        directBuyStep.socialAccount?.platform
            .toLowerCase()
            .includes(searchString)
    ) {
        return true;
    }
    if (
        directBuyStep.socialAccount?.theme?.toLowerCase().includes(searchString)
    ) {
        return true;
    }
    if (directBuyStep.placement?.type.toLowerCase().includes(searchString)) {
        return true;
    }
    if (
        directBuyStep.placement?.platform.toLowerCase().includes(searchString)
    ) {
        return true;
    }
    if (directBuyStep.script?.title.toLowerCase().includes(searchString)) {
        return true;
    }
    if (directBuyStep.script?.text.toLowerCase().includes(searchString)) {
        return true;
    }
    if (directBuyStep.caption?.text.toLowerCase().includes(searchString)) {
        return true;
    }
    if (directBuyStep.customMediaId?.toString().includes(searchString)) {
        return true;
    }
    if (directBuyStep.customMedia?.name?.toLowerCase().includes(searchString)) {
        return true;
    }
    if (directBuyStep.linkUrl?.toLowerCase().includes(searchString)) {
        return true;
    }
    if (directBuyStep.textSubmission?.toLowerCase().includes(searchString)) {
        return true;
    }

    return false;
}

function directBuyStepIsCompletable(
    directBuyStep: DirectBuyStep,
    directBuyStepsState: DirectBuyStepsEntitiesState
): boolean {
    if (directBuyStep.requiresBuyerApproval) {
        return false;
    }
    const childSteps = getDirectBuyStepsForParentObject(
        directBuyStep,
        directBuyStepsState
    );
    if (childSteps) {
        for (const step of childSteps) {
            if (!directBuyStepIsCompletable(step, directBuyStepsState)) {
                return false;
            }
        }
    }
    return true;
}

export function getActionForDirectBuyStep(
    directBuyStep: DirectBuyStep
): DirectBuyStepAction | null {
    if (directBuyStep.requiresCustomMedia) {
        return DirectBuyStepAction.customMedia;
    }
    if (directBuyStep.requiresScreenshot) {
        return DirectBuyStepAction.screenshot;
    }
    if (directBuyStep.requiresLinkSubmission) {
        return DirectBuyStepAction.linkSubmission;
    }
    if (directBuyStep.requiresTextSubmission) {
        return DirectBuyStepAction.textSubmission;
    }
    return null;
}

function afterTimeOrViewsForDirectBuyStep(
    directBuyStep: DirectBuyStep
): string {
    let string = "";

    if (directBuyStep.duration) {
        string += ` after <b>${secondsToTimeIntervals(
            directBuyStep.duration
        ).join(" ")}</b>`;

        if (directBuyStep.viewsRequested) {
            string += ` or after <b>${formatNumber(
                directBuyStep.viewsRequested
            )} views</b>`;
        }
    } else if (directBuyStep.expiresAt) {
        string += ` before <b>${formatDateForDirectBuyStep(
            directBuyStep.expiresAt
        )}</b>`;

        if (directBuyStep.viewsRequested) {
            string += ` or after <b>${formatNumber(
                directBuyStep.viewsRequested
            )} views</b>`;
        }
    } else if (directBuyStep.viewsRequested) {
        string += ` after <b>${formatNumber(
            directBuyStep.viewsRequested
        )} views</b>`;
    }

    if (directBuyStep.viewsActual) {
        string += ` (<b>${formatNumber(
            directBuyStep.viewsActual
        )} views</b> approved)`;
    }

    return string;
}

function generateCustomMediaStringForDirectBuyStep(
    directBuyStep: DirectBuyStep
): string {
    let string = "Create an ad";

    const placement = directBuyStep.placement;
    if (placement) {
        string += ` for <b>${Platforms[placement.platform].title}</b>`;
        if (placement.descriptor) {
            string += ` as a <b>${placement.descriptor}</b>`;
        }
    }

    if (directBuyStep.expiresAt) {
        string += ` before <b>${formatDateForDirectBuyStep(
            directBuyStep.expiresAt
        )}</b>`;
    }

    return string;
}

function generateScreenshotStringForDirectBuyStep(
    directBuyStep: DirectBuyStep
): string {
    let string = "Upload a screenshot";
    string += afterTimeOrViewsForDirectBuyStep(directBuyStep);
    return string;
}

function generateLinkSubmissionStringForDirectBuyStep(
    directBuyStep: DirectBuyStep
): string {
    let string = "Submit a link";
    string += afterTimeOrViewsForDirectBuyStep(directBuyStep);
    return string;
}

function generateTextSubmissionStringForDirectBuyStep(
    directBuyStep: DirectBuyStep
): string {
    let string = "Submit a response";
    string += afterTimeOrViewsForDirectBuyStep(directBuyStep);
    return string;
}

function generateSocialAccountStringForDirectBuyStep(
    directBuyStep: DirectBuyStep,
    options?: { shortText?: boolean; plainText?: boolean }
): string | null {
    const socialAccount = directBuyStep.socialAccount;

    if (!socialAccount) {
        return null;
    }

    const { shortText, plainText } = options || {};

    const username = plainText
        ? socialAccount.username
        : `<b>${socialAccount.username}</b>`;
    const platform = plainText
        ? Platforms[socialAccount.platform].title
        : `<b>${Platforms[socialAccount.platform].title}</b>`;

    let socialAccountString = `${username} on ${platform}`;

    if (plainText || shortText) {
        return socialAccountString;
    }

    if (directBuyStep.placement?.descriptor) {
        socialAccountString += ` as a <b>${directBuyStep.placement.descriptor}</b>`;
    }

    if (directBuyStep.duration) {
        socialAccountString += ` for <b>${secondsToTimeIntervals(
            directBuyStep.duration
        ).join(" ")}</b>`;
    }

    if (directBuyStep.startsAt && directBuyStep.expiresAt) {
        const startsAtString = formatDateForDirectBuyStep(
            directBuyStep.startsAt
        );
        const expiresAtString = formatDateForDirectBuyStep(
            directBuyStep.expiresAt
        );
        socialAccountString += ` between <b>${startsAtString}</b> and <b>${expiresAtString}</b>`;
    }

    if (directBuyStep.permanent) {
        socialAccountString += " <b>permanently</b>";
    }

    if (directBuyStep.viewsRequested) {
        socialAccountString += ` until it gets <b>${formatNumber(
            directBuyStep.viewsRequested
        )} views</b>`;
    }

    return socialAccountString;
}

function generateCustomMediaStringForDirectBuyStepArray(
    directBuySteps: DirectBuyStep[],
    stringHead: string
): string {
    let string = stringHead;

    const resourceStrings: string[] = [];

    const scriptString = generateScriptStringForDirectBuyStepArray(
        directBuySteps
    );
    if (scriptString) {
        resourceStrings.push(scriptString);
    }
    const assetString = generateAssetStringForDirectBuyStepArray(
        directBuySteps
    );
    if (assetString) {
        resourceStrings.push(assetString);
    }
    const mediaString = generateMediaStringForDirectBuyStepArray(
        directBuySteps
    );
    if (mediaString) {
        resourceStrings.push(mediaString);
    }
    const captionString = generateCaptionStringForDirectBuyStepArray(
        directBuySteps
    );
    if (captionString) {
        resourceStrings.push(captionString);
    }

    if (resourceStrings.length > 0) {
        string += " using";
        resourceStrings.forEach((resource, index) => {
            if (index === 0) {
                string += ` ${resource}`;
            } else if (index === resourceStrings.length - 1) {
                string += ` and ${resource}`;
            } else {
                string += `, ${resource}`;
            }
        });
    }

    return string;
}

function generateScreenshotStringForDirectBuyStepArray(
    directBuySteps: DirectBuyStep[],
    stringHead: string
): string {
    let string = stringHead;

    const accountString = generateSocialAccountStringForDirectBuyStepArray(
        directBuySteps,
        { shortText: true }
    );
    if (accountString) {
        string += ` for ${accountString}`;
    }

    return string;
}

function generateLinkSubmissionStringForDirectBuyStepArray(
    directBuySteps: DirectBuyStep[],
    stringHead: string
): string {
    let string = stringHead;

    const accountString = generateSocialAccountStringForDirectBuyStepArray(
        directBuySteps,
        { shortText: true }
    );
    if (accountString) {
        string += ` for ${accountString}`;
    }

    return string;
}

function generateTextSubmissionStringForDirectBuyStepArray(
    directBuySteps: DirectBuyStep[],
    stringHead: string
): string {
    let string = stringHead;

    const accountString = generateSocialAccountStringForDirectBuyStepArray(
        directBuySteps,
        { shortText: true }
    );
    if (accountString) {
        string += ` for ${accountString}`;
    }

    return string;
}

function generateSocialAccountStringForDirectBuyStepArray(
    directBuySteps: DirectBuyStep[],
    options?: { shortText?: boolean }
): string | null {
    const { shortText } = options || {};

    const accountStrings: string[] = [];

    directBuySteps.forEach(step => {
        const accountString = generateSocialAccountStringForDirectBuyStep(
            step,
            { shortText }
        );
        if (accountString) {
            accountStrings.push(accountString);
        }
    });

    if (accountStrings.length === 0) {
        return null;
    } else {
        let string = "";
        accountStrings.forEach((account, index) => {
            if (index === 0) {
                string += account;
            } else if (index === accountStrings.length - 1) {
                string += ` and ${account}`;
            } else {
                string += `, ${account}`;
            }
        });
        return string;
    }
}

function generateMediaStringForDirectBuyStepArray(
    directBuySteps: DirectBuyStep[]
): string | null {
    let count = 0;

    directBuySteps.forEach(step => {
        if (step.media) {
            count += 1;
        }
    });

    if (count === 0) {
        return null;
    } else if (count === 1) {
        return "this media";
    } else {
        return "these media";
    }
}

function generateCaptionStringForDirectBuyStepArray(
    directBuySteps: DirectBuyStep[]
): string | null {
    let count = 0;

    directBuySteps.forEach(step => {
        if (step.caption) {
            count += 1;
        }
    });

    if (count === 0) {
        return null;
    } else if (count === 1) {
        return "this caption";
    } else {
        return "these captions";
    }
}

function generateScriptStringForDirectBuyStepArray(
    directBuySteps: DirectBuyStep[]
): string | null {
    let count = 0;

    directBuySteps.forEach(step => {
        if (step.script) {
            count += 1;
        }
    });

    if (count === 0) {
        return null;
    } else if (count === 1) {
        return "this script";
    } else {
        return "these scripts";
    }
}

function generateAssetStringForDirectBuyStepArray(
    directBuySteps: DirectBuyStep[]
): string | null {
    let count = 0;

    directBuySteps.forEach(step => {
        if (step.asset) {
            count += 1;
        }
    });

    if (count === 0) {
        return null;
    } else if (count === 1) {
        return "this asset";
    } else {
        return "these assets";
    }
}

// DirectBuyStepCollection Utils -------------------------------------------------------------------

export function generateDescriptionForDirectBuyStepCollection(
    directBuyStepCollection: DirectBuyStepCollection,
    directBuyStepsState: DirectBuyStepsEntitiesState
): string {
    const parent = directBuyStepsState[directBuyStepCollection.parent];
    const combined = directBuyStepCollection.combined.map(
        id => directBuyStepsState[id]
    );

    if (parent.description) {
        return parent.description;
    }

    const action: DirectBuyStepAction | null = getActionForDirectBuyStep(
        parent
    );
    if (action !== null) {
        switch (action) {
            case DirectBuyStepAction.customMedia:
                return generateCustomMediaStringForDirectBuyStepArray(
                    combined,
                    generateCustomMediaStringForDirectBuyStep(parent)
                );
            case DirectBuyStepAction.screenshot:
                return generateScreenshotStringForDirectBuyStepArray(
                    combined,
                    generateScreenshotStringForDirectBuyStep(parent)
                );
            case DirectBuyStepAction.linkSubmission:
                return generateLinkSubmissionStringForDirectBuyStepArray(
                    combined,
                    generateLinkSubmissionStringForDirectBuyStep(parent)
                );
            case DirectBuyStepAction.textSubmission:
                return generateTextSubmissionStringForDirectBuyStepArray(
                    combined,
                    generateTextSubmissionStringForDirectBuyStep(parent)
                );
        }
    }

    const socialAccountString = generateSocialAccountStringForDirectBuyStepArray(
        combined
    );
    if (socialAccountString) {
        const resourceStrings: string[] = [];

        const mediaString = generateMediaStringForDirectBuyStepArray(combined);
        if (mediaString) {
            resourceStrings.push(mediaString);
        }

        const captionString = generateCaptionStringForDirectBuyStepArray(
            combined
        );
        if (captionString) {
            resourceStrings.push(captionString);
        }

        const linkCount: number = combined.filter(step => step.linkUrl).length;

        let stringPrefix = "Post";
        if (linkCount === 1) {
            stringPrefix = "Copy the link below, and post";
        } else if (linkCount > 1) {
            stringPrefix = "Copy the links below, and post";
        }

        if (resourceStrings.length === 0) {
            return `${stringPrefix} it on ${socialAccountString}`;
        } else {
            resourceStrings.forEach((resource, index) => {
                if (index === 0) {
                    stringPrefix += ` ${resource}`;
                } else if (index === resourceStrings.length - 1) {
                    stringPrefix += ` and ${resource}`;
                } else {
                    stringPrefix += `, ${resource}`;
                }
            });
            return `${stringPrefix} on ${socialAccountString}`;
        }
    }

    return `Step #${parent.position}`;
}
