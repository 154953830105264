import { all, call, put, takeEvery } from "redux-saga/effects";
import { post } from "src/Api";
import { Action } from "src/types";
import {
    fetchElasticSearchFailure,
    fetchElasticSearchSuccess,
    fetchMoreSearchHitsFailure,
    fetchMoreSearchHitsSuccess,
    FETCH_ELASTIC_SEARCH,
    FETCH_MORE_SEARCH_HITS
} from "./actions";

export function* fetchElasticSearchRequest({
    payload: {
        index,
        queryString,
        hitsPerPage = 20,
        page = 0,
        country = "US",
        platform = "ios"
    }
}: Action) {
    try {
        const queryIndices: string[] = index.split(", ");
        const queries = queryIndices.map(index => ({
            index,
            params: {
                query: queryString,
                hitsPerPage,
                page,
                country,
                platform
            }
        }));

        const {
            data: { data }
        } = yield call(post, "/v1/search", {
            query: queries
        });

        yield put(fetchElasticSearchSuccess(queryIndices, data));
    } catch (error) {
        yield put(fetchElasticSearchFailure("Elastic search failure"));
    }
}

export function* fetchMoreSearchHitsRequest({
    payload: { index, queryString, page, country = "US", platform = "ios" }
}: Action) {
    try {
        const { data } = yield call(post, "/v1/search", {
            query: [
                {
                    index,
                    params: {
                        query: queryString,
                        hitsPerPage: 20,
                        page,
                        country,
                        platform
                    }
                }
            ]
        });

        yield put(fetchMoreSearchHitsSuccess(index, data.data[0]));
    } catch (error) {
        yield put(
            fetchMoreSearchHitsFailure(
                "Failed to fetch more elastic serach hits"
            )
        );
    }
}

export default function* elasticSearchSaga() {
    yield all([
        takeEvery(FETCH_ELASTIC_SEARCH.REQUEST, fetchElasticSearchRequest),
        takeEvery(FETCH_MORE_SEARCH_HITS.REQUEST, fetchMoreSearchHitsRequest)
    ]);
}
