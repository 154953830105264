import React, { useEffect } from "react";
import LabelText from "src/components/text/LabelText";
import { colors } from "src/constants";
import styled from "styled-components";
import ChatUserHitCell from "./ChatUserHitCell";
import { SelectedUsers } from "../UserSelectionWithSearchModal";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { selectHits, selectPublisherHits } from "src/search/selector";
import { PublisherHit } from "src/search/types";

const List = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    & > * ~ * {
        margin-top: 12px;
    }
`;

const Label = styled(LabelText)`
    position: sticky;
    top: 0px;
    padding: 10px 0;
    background-color: ${colors.white};
    z-index: 1;
`;

interface Props {
    onUserSelection: (
        publisherId: string,
        username: string,
        avatarUrl: string | null
    ) => void;
    selectedUsers: SelectedUsers;
    filterOutUserIds?: string[];
    hits: PublisherHit[];
}

const SearchUserList = ({
    onUserSelection,
    selectedUsers,
    filterOutUserIds,
    hits
}: Props) => {
    return (
        <>
            <Label>USERS</Label>
            <List>
                {hits.map(hit => {
                    if (
                        filterOutUserIds &&
                        filterOutUserIds.includes(`${hit.id}`)
                    )
                        return null;
                    return (
                        <div
                            key={hit.id}
                            onClick={() => {
                                if (hit.id && hit.username) {
                                    onUserSelection(
                                        `${hit.id}`,
                                        hit.username,
                                        hit.profile_photo_url
                                    );
                                }
                            }}
                        >
                            <ChatUserHitCell
                                key={hit.id}
                                profilePhotoUrl={hit.profile_photo_url}
                                username={hit.username}
                                tagLine={{
                                    followerCount: hit.platform_num_followers,
                                    platform: hit.platform
                                }}
                                status={hit.status}
                                selected={!!selectedUsers[hit.id]}
                            />
                        </div>
                    );
                })}
            </List>
        </>
    );
};

export default SearchUserList;
