import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { adminFetchOpenBuys } from "src/admin-tools/actions.admin-tools";
import MainContainer from "src/components/MainContainer";
import {
    breakpoints,
    colors,
    fonts,
    fontWeight,
    maxWidth,
    spacing
} from "src/constants";
import MarketplacePagePartialHeader from "src/marketplace/components/MarketplacePagePartialHeader";
import ChallengeList from "src/marketplace/reports/ChallengeList";
import {
    setChallengeDetailsIncomplete,
    setCreateChallengeFormOpen
} from "src/ui/admin-tools/actions";
import { setCurrentCampaignId } from "src/ui/campaigns/actions";
import {
    clearButton,
    clearLink,
    grayBackgroundHoverState
} from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import ChallengeLinkTab from "../components/ChallengeLinkTab";
import { selectMarketplaceBadges } from "src/buys/selectors";
import {
    requestMarketplaceSubmissionBadges,
    setMarketplaceSubmissionBadges
} from "src/ui/marketplace/actions";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    text-align: left;
`;

const Main = styled(MainContainer)`
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (max-width: ${breakpoints.tablet}px) {
        padding: 0px ${spacing.sideSpacing}px;
    }

    @media (max-width: ${maxWidth.desktop}px) {
        padding-top: 20px;
    }
`;

const buttonStyles = css`
    ${grayBackgroundHoverState()};
    padding: 8px 12px;
    border-radius: 6px;
    color: ${colors.black};
    font-size: ${fonts.semiSmallTitle}px;
    font-weight: ${fontWeight.medium};
`;

const ApprovalsButton = styled(Link)`
    ${clearLink()};
    ${buttonStyles};
    display: flex;
    flex-direction: row;
`;
const Badge = styled.div`
    border-radius: 4px;
    background-color: ${colors.secondaryRed};
    color: white;
    height: 20px;
    width: 20px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CreateChallengeButton = styled.button`
    ${clearButton()};
    ${buttonStyles};
`;

const ChallengesLanding = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(adminFetchOpenBuys());
        dispatch(setCurrentCampaignId(null));
        dispatch(setChallengeDetailsIncomplete(false));
        return function cleanUp(): void {
            dispatch(setCurrentCampaignId(null));
        };
    }, []);
    const badgeCounts = useSelector(selectMarketplaceBadges);

    useEffect(() => {
        dispatch(requestMarketplaceSubmissionBadges());
    }, []);

    return (
        <Wrapper>
            {/* <Navbar marginBottom={24} mobileHasMarginBottom /> */}
            <Main>
                <MarketplacePagePartialHeader
                    includeHeading
                    // hideCrumbs
                    customCrumbs={["Admin Tools"]}
                    endCrumb="Your Challenges"
                    buttons={
                        <>
                            {/* TODO: Add onClick to all approvals */}
                            <ChallengeLinkTab
                                route="/admin-tools/challenges/submissions"
                                text="Submission Approvals"
                                badgeCount={
                                    badgeCounts.pending + badgeCounts.flagged
                                }
                            />
                            {/* TODO: Add onClick here to create a new challenge */}
                            {/* <ApprovalsButton>Create New Challenge</ApprovalsButton> */}

                            <CreateChallengeButton
                                type="button"
                                onClick={() => {
                                    dispatch(
                                        setMarketplaceSubmissionBadges({
                                            pending: 0,
                                            flagged: 0
                                        })
                                    );
                                    dispatch(setCreateChallengeFormOpen(true));
                                }}
                            >
                                Create New Challenge
                            </CreateChallengeButton>
                        </>
                    }
                />
                <ChallengeList />
            </Main>
        </Wrapper>
    );
};

export default ChallengesLanding;
