import React from "react";
import { useIntercom } from "react-use-intercom";
import { breakpoints, colors } from "src/constants";
import { flexRowCenter } from "src/utils/styles/snippets";
import styled from "styled-components";

const LinkButton = styled.button`
    ${flexRowCenter()};
    width: 300px;
    height: 60px;
    color: ${colors.white};
    background-color: ${colors.primaryBlue};
    border-radius: 10px;
    font-size: 22px;
    font-weight: 700;
    text-decoration: none;
    transition: 0.1s ease-in;
    cursor: pointer;
    border: none;

    :hover {
        color: ${colors.white};
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;
const IntercomLauncher = () => {
    const { boot, showNewMessages } = useIntercom();

    return (
        <LinkButton
            onClick={() => {
                boot({
                    customAttributes: {
                        disable_chat_bot: false
                    }
                });
                showNewMessages();
            }}
        >
            Contact Us
        </LinkButton>
    );
};
export default IntercomLauncher;
