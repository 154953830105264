import React from "react";
import { connect } from "react-redux";
import { Media, OldMedia } from "../../../media/types";
import FullScreenModal from "../../../modals/FullScreenModal";
import { GlobalState } from "../../../reducers";
import { getCampaignCustomLinks } from "../selectors";
import {
    campaignCustomLinksModal,
    CustomLinksModalState
} from "../../../ui/custom-links/actions";
import CustomLinksPage from "../CustomLinksPage";
import { getMedias } from "../../selectors";
import { ReactComponent as Cross } from "../../../components/icons/cross.svg";
import { List, StatusWrapper } from "../../details/Details.styled";
import SimpleNavbar from "../../../components/SimpleNavbar";
import { colors } from "../../../constants";
import XCard from "../../../profile/components/XCard";
import { fetchCampaignCustomLinks } from "../actions";
import { TextLoading } from "../../../components/SkeletonLoader";
import { CampaignMedia } from "src/campaigns/types";

interface Props {
    currentCampaign: number | null;
    customLinksLoading: boolean;
    customLinks?: OldMedia[];
    // Media?: CampaignMedia[];
    Media?: Media[];
    clearCustomLinkModal: () => void;
    fetchCampaignCustomLinks: (campaignId: number) => void;
}

const CustomLinkModal = ({
    currentCampaign,
    customLinksLoading,
    Media,
    customLinks,
    clearCustomLinkModal,
    fetchCampaignCustomLinks
}: Props) => {
    if (customLinksLoading)
        return (
            <FullScreenModal closeModal={clearCustomLinkModal}>
                <SimpleNavbar
                    title={"Custom Links"}
                    left={
                        <Cross
                            fill={colors.primaryGray}
                            onClick={() =>
                                campaignCustomLinksModal(
                                    CustomLinksModalState.clear
                                )
                            }
                        />
                    }
                />
                <List style={{ paddingTop: 10 }}>
                    <TextLoading height={56} />
                    <TextLoading height={56} />
                </List>
            </FullScreenModal>
        );

    if (!customLinks)
        return (
            <FullScreenModal closeModal={clearCustomLinkModal}>
                <SimpleNavbar
                    title={"Custom Links"}
                    left={
                        <Cross
                            fill={colors.primaryGray}
                            onClick={() =>
                                campaignCustomLinksModal(
                                    CustomLinksModalState.clear
                                )
                            }
                        />
                    }
                />
                <StatusWrapper style={{ paddingTop: 10 }}>
                    <XCard
                        text="Failed to load custom links, click the button below to try again."
                        button={{
                            text: "Try again",
                            callback: () => {
                                currentCampaign &&
                                    fetchCampaignCustomLinks(currentCampaign);
                            }
                        }}
                    />
                </StatusWrapper>
            </FullScreenModal>
        );

    if (!Media || Media.length === 0)
        return (
            <FullScreenModal closeModal={clearCustomLinkModal}>
                <SimpleNavbar
                    title={"Custom Links"}
                    left={
                        <Cross
                            fill={colors.primaryGray}
                            onClick={() =>
                                campaignCustomLinksModal(
                                    CustomLinksModalState.clear
                                )
                            }
                        />
                    }
                />
                <StatusWrapper style={{ paddingTop: 10 }}>
                    <XCard
                        text="This campaign doesn’t have any media resources yet."
                        button={{
                            text: "Try again",
                            callback: () => {
                                currentCampaign &&
                                    fetchCampaignCustomLinks(currentCampaign);
                            }
                        }}
                    />
                </StatusWrapper>
            </FullScreenModal>
        );

    return (
        <FullScreenModal closeModal={clearCustomLinkModal}>
            <CustomLinksPage Media={Media} customLinks={customLinks} />
        </FullScreenModal>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    currentCampaign: state.ui.campaign.currentCampaignId,
    customLinksLoading: state.ui.customLink.customLinksLoading,
    customLinks: getCampaignCustomLinks(state),
    Media: getMedias(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    clearCustomLinkModal: () =>
        dispatch(campaignCustomLinksModal(CustomLinksModalState.clear)),
    fetchCampaignCustomLinks: (campaignId: number) =>
        dispatch(fetchCampaignCustomLinks(campaignId))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomLinkModal);
