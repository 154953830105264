import { Action } from "src/types";
import {
    FETCH_CREATOR_INTERESTS,
    FETCH_CREATOR_THEMES,
    SET_CREATOR_GROUPS_LOADING,
    SET_CURRENT_CREATOR_GROUP_ID
} from "./actions";

export interface CreatorGroupsUIState {
    creatorGroupsLoading: boolean;
    currentCreatorGroupId: number | null;
    creatorInterests: string[];
    creatorThemes: string[];
}

const initialCreatorGroupsUIState: CreatorGroupsUIState = {
    creatorGroupsLoading: true,
    currentCreatorGroupId: null,
    creatorInterests: [],
    creatorThemes: []
};

function creatorSearchUIReducer(
    state = initialCreatorGroupsUIState,
    action: Action
): CreatorGroupsUIState {
    switch (action.type) {
        case SET_CREATOR_GROUPS_LOADING:
            return { ...state, creatorGroupsLoading: action.payload.loading };

        case SET_CURRENT_CREATOR_GROUP_ID:
            return { ...state, currentCreatorGroupId: action.payload };

        case FETCH_CREATOR_INTERESTS.SUCCESS:
            return { ...state, creatorInterests: action.payload.interests };

        case FETCH_CREATOR_THEMES.SUCCESS:
            return { ...state, creatorThemes: action.payload.themes };

        default:
            return state;
    }
}

export default creatorSearchUIReducer;
