import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 26px;
    height: 26px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    background-color: hsl(0, 0%, 0%, 0.6);
`;
const Icon = styled.img`
    width: 16px;
    height: 16px;
`;

const ShowIcon = ({ className }: { className?: string }) => {
    return (
        <Wrapper className={className}>
            <Icon src="/crossed-eye.svg" alt="" />
        </Wrapper>
    );
};

export default ShowIcon;
