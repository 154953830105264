import React from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

const Caret = styled(animated.img)`
    width: 12px;
    cursor: pointer;
`;

interface Props {
    open?: boolean;
    color?: string;
}

const SpinCaret = ({ open, color }: Props) => {
    const spin = useSpring({
        config: { mass: 1, tension: 170, friction: 26 },
        transform: open ? "rotate(0deg)" : "rotate(90deg)"
    });
    return <Caret src={"/caret.svg"} style={spin} />;
};

export default SpinCaret;
