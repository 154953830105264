import React from "react";
import styled from "styled-components";
import { Script } from "../../types";
import { BigOutlineButton } from "../../../profile/components/Buttons";
import { colors } from "../../../constants";
import ShareButton from "src/components/ShareButton";

const ScriptButton = styled(BigOutlineButton)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    line-height: 18px;
    padding: 18px;
    margin-bottom: 10px;
`;

interface Props {
    script: Script;
    handleClick: () => void;
}

const ScriptRow = ({ script, handleClick }: Props) => (
    <ScriptButton textColor={colors.black} onClick={handleClick}>
        <div>{script.title}</div>
        <ShareButton
            isScript={true}
            link={`/campaign/${script.campaignId}?scriptId=${script.id}`}
        />
    </ScriptButton>
);

export default ScriptRow;
