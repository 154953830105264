import { useMemo, useState } from "react";
import { TableRow } from "../types";

export type SortType = "string" | "number" | undefined;
export type SortDirection = "ASC" | "DESC";
export interface SortBy {
    coreKey: string;
    direction: SortDirection;
    sortType: SortType;
}
export interface SortReference {
    sortedData: TableRow[];
    handleSort: (key: string, type: SortType, sortBy: SortBy | null) => void;
    sortBy: SortBy | null;
}

const useSorting = (
    data: TableRow[],
    defaultSort: SortBy | null
): SortReference => {
    const [sortBy, setSortBy] = useState<null | SortBy>(defaultSort);

    const handleSort = (
        coreKey: string,
        sortType: "number" | "string" | undefined,
        sortBy: SortBy | null
    ) => {
        if (sortType) {
            if (!sortBy) {
                setSortBy({ coreKey, sortType, direction: "ASC" });
            } else {
                if (sortBy.coreKey === coreKey) {
                    if (sortBy.direction === "ASC") {
                        setSortBy({ ...sortBy, direction: "DESC" });
                    } else {
                        setSortBy(null);
                    }
                } else {
                    setSortBy({ coreKey, sortType, direction: "ASC" });
                }
            }
        }
    };

    const sortData = (dataToSort: TableRow[], sortByObj: SortBy | null) => {
        if (sortByObj) {
            const collator = new Intl.Collator("en", {
                numeric: sortByObj.sortType === "number",
                sensitivity: "base"
            });
            if (sortByObj.direction === "DESC") {
                return [...dataToSort].sort((a, b) => {
                    const first = a.rowData[sortByObj.coreKey];
                    const second = b.rowData[sortByObj.coreKey];

                    if (!first && second) {
                        return 1;
                    }
                    if (first && !second) {
                        return -1;
                    }
                    if (!first && !second) {
                        return 0;
                    }
                    //@ts-ignore
                    return collator.compare(second.data, first.data);
                });
            } else {
                return [...dataToSort].sort((a, b) => {
                    const first = a.rowData[sortByObj.coreKey];
                    const second = b.rowData[sortByObj.coreKey];

                    if (!first && second) {
                        return -1;
                    }
                    if (first && !second) {
                        return 1;
                    }
                    if (!first && !second) {
                        return 0;
                    }
                    //@ts-ignore
                    return collator.compare(first.data, second.data);
                });
            }
        } else {
            return dataToSort;
        }
    };

    const sortedData = useMemo(() => sortData(data, sortBy), [data, sortBy]);
    return { handleSort, sortedData, sortBy };
};

export default useSorting;
