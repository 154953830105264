import styled from "styled-components";
import { TextEllipsis } from "../CommonStyles";

const Title = styled.div<{ size?: string; weight?: string }>`
    ${TextEllipsis}
    font-size: ${props => props.size || "20px"};
    font-weight: ${props => props.size || "500"};
    line-height: 24px;
    text-align: left;
`;

export default Title;
