import React, { useState } from "react";
import { PaymentList, PaymentText } from "./styledComponents";

interface Props {
    expanded: boolean;
}

const DirectBuyDetailsPaymentsLoading = ({ expanded }: Props) => {
    const [rows] = useState(() => (Math.random() > 0.8 ? [0, 1] : [0]));
    if (!expanded) {
        return null;
    }
    return (
        <PaymentList>
            {rows.map(index => (
                <PaymentText key={index} isLoading />
            ))}
        </PaymentList>
    );
};

export default DirectBuyDetailsPaymentsLoading;
