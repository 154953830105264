import React, { useState } from "react";
import { SocialAccount } from "../../../types";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import NavButton from "../../../../components/NavButton";
import FormHeader from "./FormHeader";
import TagTitle from "./TagTitle";
import TagContainer from "./TagContainer";
import Platforms from "../../../../social-accounts/Platforms";
import FormTag from "./FormTag";
import Themes from "../../../../social-accounts/Themes";
import { ProfileModal } from "../../../reducer";
import VerifyModal from "../modals/VerifyModal";
import { postAccount, PostAccountParams } from "../../../actions";
import PrivacyAccountModal from "../modals/PrivacyAccountModal";
import { clearModal } from "../../../../modals/actions";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../../reducers";
import { useHistory } from "react-router";
import MainContainer from "src/components/MainContainer";
import { push } from "connected-react-router";

interface Props {
    currentAccount?: SocialAccount;
}

const AccountForm = ({ currentAccount }: Props) => {
    const modal = useSelector((state: GlobalState) => {
        return state.profile.modal;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();

    function dispatchPostAccount(payload: PostAccountParams): void {
        dispatch(postAccount(payload));
    }

    function dispatchClearModal(): void {
        dispatch(clearModal());
    }

    const [username, setUsername] = useState(
        currentAccount && currentAccount.username ? currentAccount.username : ""
    );
    const [followers, setFollowers] = useState(
        currentAccount && currentAccount.followers
            ? currentAccount.followers
            : ""
    );
    const [visibility, setVisibility] = useState<SocialAccount["visibility"]>(
        currentAccount ? currentAccount.visibility : "visible"
    );
    const [platform, setPlatform] = useState(
        currentAccount && currentAccount.platform ? currentAccount.platform : ""
    );
    const [theme, setTheme] = useState(
        currentAccount && currentAccount.theme ? currentAccount.theme : ""
    );

    function submitDisabled() {
        return !Boolean(username && followers && platform && theme);
    }

    function submitForm() {
        if (!submitDisabled()) {
            dispatchPostAccount({
                id: currentAccount && currentAccount.id,
                username,
                followers,
                visibility,
                platform,
                theme
            });
            dispatch(push("/profile/accounts"));
        }
    }

    return (
        <>
            <SimpleNavbar
                title={currentAccount ? "Edit Account" : "Add Acount"}
                left={<NavButton back onClick={goBack} />}
                right={
                    <NavButton
                        text="Done"
                        disabled={submitDisabled()}
                        onClick={submitForm}
                    />
                }
            />

            <MainContainer>
                <FormHeader
                    platform={platform}
                    username={username}
                    setUsername={setUsername}
                    followers={followers}
                    setFollowers={setFollowers}
                    visibility={visibility}
                    currentAccount={currentAccount}
                />
                <TagTitle>Platform</TagTitle>
                <TagContainer>
                    {Object.keys(Platforms).map(platformKey => {
                        return (
                            <FormTag
                                key={platformKey}
                                text={Platforms[platformKey].title}
                                selected={platformKey === platform}
                                onClick={() => {
                                    setPlatform(platformKey);
                                }}
                            />
                        );
                    })}
                </TagContainer>
                <TagTitle>Theme</TagTitle>
                <TagContainer>
                    {Object.keys(Themes).map(themeKey => {
                        return (
                            <FormTag
                                key={themeKey}
                                text={Themes[themeKey].title}
                                selected={themeKey === theme}
                                onClick={() => {
                                    setTheme(themeKey);
                                }}
                            />
                        );
                    })}
                </TagContainer>
                {modal === ProfileModal.verify && currentAccount && (
                    <VerifyModal account={currentAccount} />
                )}
                {modal === ProfileModal.privacyAccount && (
                    <PrivacyAccountModal
                        account={currentAccount}
                        cancelCallback={dispatchClearModal}
                        doneCallback={newVisibility => {
                            setVisibility(newVisibility);
                            dispatchClearModal();
                        }}
                    />
                )}
            </MainContainer>
        </>
    );
};

export default AccountForm;
