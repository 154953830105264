import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    ASSIGN_CODE_TO_USER,
    assignCodeToUser,
    CHECK_MAGIC_TOKEN,
    checkMagicTokenFailure,
    checkMagicTokenSuccess,
    COMPLETE_ACCOUNT_SETUP,
    completeAccountSetupSuccess,
    POST_INVITE_CODE,
    POST_MAGIC_PHONE_AUTH,
    POST_PHONE_AUTH,
    POST_PHONE_NUMBER,
    postInviteCodeFailure,
    postMagicPhoneAuthFailure,
    postPhoneAuthFailure,
    postPhoneNumberFailure,
    postPhoneNumberSuccess,
    SAVE_PERSONAL_DETAILS,
    setIntercomHash,
    INTERCOM_HASH,
    fetchReferringPublisherFailure,
    fetchReferringPublisherSuccess,
    FETCH_REFERRING_PUBLISHER,
    fetchRefferingPublisherPreApprovalCode
} from "./actions";
import { push } from "connected-react-router";
import { Action } from "../types";
import { get, patch, post, postQS, setToken } from "../Api";
import "firebase/auth";
import { setFirebaseToken } from "../firebase/FirebaseConfig";
import { magicInProgress } from "../util";
import { submitEvent } from "../events/actions";
import { addNotification } from "src/ui/notifications/actions";
import { ReferringPublisher } from "src/ui/auth/reducer";
import { isAxiosError } from "axios";

function* postInviteCodeRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(postQS, "/check_invite_code", {
            invite_code: payload.inviteCode
        });

        if (status === 200) {
            localStorage.setItem("inviteCode", data.invite_code);
            yield put(push("/onboarding/phone"));
        }
    } catch (error) {
        window.alert(
            "Something went wrong. Your code may be wrong or you may need to refresh the page."
        );
        yield put(postInviteCodeFailure("Invite code failed"));
    }
}

function* fetchReferringPublisher({ payload }: Action) {
    try {
        const { data, status } = yield call(
            get,
            "/public/v1/publisher/byReferralCode",
            {
                referralCode: payload.referralCode
            }
        );

        if (status >= 400) throw new Error("endpoint failed");

        const publisher: ReferringPublisher = data.data.publisher;

        // Handling alternate code type, as indicated by endpoint
        const preApprovalCode: boolean = data.data.preApprovalCode ?? false;
        if (preApprovalCode) {
            localStorage.setItem("preApprovalCode", payload.referralCode);
            yield put(
                fetchRefferingPublisherPreApprovalCode(payload.referralCode)
            );
            return;
        }

        localStorage.setItem("inviteCode", publisher.referralCode);

        yield put(fetchReferringPublisherSuccess(publisher));
    } catch (error) {
        yield put(fetchReferringPublisherFailure());
    }
}

function* postPhoneNumberRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(postQS, "/phone_auth", {
            phone_number: payload.phoneNumber
        });

        if (status === 200) {
            yield put(postPhoneNumberSuccess(payload));
            if (magicInProgress()) {
                yield put(push("/approved/phone/auth"));
            } else {
                yield put(push("/onboarding/phone/auth"));
            }
        } else {
            yield put(
                addNotification("Something went wrong, please refresh the page")
            );
            yield put(postPhoneNumberFailure("Post phone number failed"));
        }
    } catch (error) {
        const errors: { [key: number]: string } = {
            400: "Phone number is invalid. Please check the number and try again.",
            403: "Phone numbers from this country are blocked.",
            429: "Maximum send attempts reached. Please wait and try again.",
            500: "Unable to validate phone number. Please check the number and try again.",
            451: "Sorry we cannot let you login from that location"
        };

        if (isAxiosError(error))
            yield put(
                addNotification(
                    `${errors[error.response?.status || 500]}`,
                    "red"
                )
            );
        yield put(postPhoneNumberFailure("Post phone number failed"));
    }
}

function* postPhoneNumberCallRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(post, "/phone_auth_call", {
            phone_number: payload.phoneNumber
        });

        if (status === 200) {
            yield put(postPhoneNumberSuccess(payload));
        } else {
            window.alert("Something went wrong, please refresh the page");
            yield put(postPhoneNumberFailure("Post phone number call failed"));
        }
    } catch (error) {
        window.alert("Something went wrong, please refresh the page");
        yield put(postPhoneNumberFailure("Post phone number call failed"));
    }
}

function* postPhoneNumberResendRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(postQS, "/resend_phone_auth", {
            phone_number: payload.phoneNumber
        });

        if (status === 200) {
            yield put(postPhoneNumberSuccess(payload));
        } else {
            window.alert("Something went wrong, please refresh the page");
            yield put(postPhoneNumberFailure(data));
        }
    } catch (error) {
        window.alert("Something went wrong, please refresh the page");
        yield put(postPhoneNumberFailure("Resend request failed"));
    }
}

function* postPhoneAuthRequest({ payload }: Action) {
    try {
        const inviteCode = localStorage.getItem("inviteCode");

        const { data, status } = yield call(
            postQS,
            "/phone_auth_confirmation_v2",
            {
                phone_number: payload.phoneNumber,
                auth_code: payload.authCode,
                referralCode: inviteCode
            }
        );

        if (status !== 200) {
            window.alert("Something went wrong, please try again");
            yield put(postPhoneAuthFailure(data));
            return;
        }

        const { authStatus, token, userType, userid, firebaseToken } = data;

        if (authStatus !== "authSucceeded") {
            window.alert("Something went wrong, please try again");
            yield put(postPhoneAuthFailure("Auth status is not success"));
            return;
        }

        localStorage.setItem("token", token);
        localStorage.setItem("userid", userid.id);
        localStorage.setItem("firebaseToken", firebaseToken);
        localStorage.removeItem("devAccount");

        try {
            (window as any).AF("pba", "setCustomerUserId", `${userid.id}`);
            localStorage.setItem("appsflyerID", userid.id);
        } catch (error) {
            console.error("error setting CUID: ", error);
        }

        setToken();
        yield call(setFirebaseToken, firebaseToken);

        yield put(push("/onboarding/details"));

        if (userType === "newUser") {
            yield put(submitEvent({ event_name: "account_created" }));
        }

        // if (inviteCode && userType === "newUser") {
        //     yield put(assignCodeToUser({ inviteCode }));
        // }
    } catch (error) {
        yield put(postPhoneAuthFailure("Auth status is not success"));
        window.alert("Something went wrong, please try again");
    }
}

function* assignCodeToUserRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/user/assign_code_to_user_v2",
            {
                invite_code: payload.inviteCode
            }
        );

        if (status === 200) {
            localStorage.removeItem("inviteCode");
        }
    } catch (error) {}
}

function* savePersonalDetails({ payload }: Action) {
    const userid: string = localStorage.getItem("userid") || "";
    if (userid.length === 0) {
        return;
    }

    const { status } = yield call(postQS, "/user/update_user_fields", {
        firstname: payload.firstname,
        lastname: payload.lastname,
        birthday: payload.birthday,
        email: payload.email,
        userid
    });

    if (status === 200) {
        yield put(push("/onboarding/verify"));
    } else {
        window.alert("Something went wrong, please try again");
    }
}

function* checkMagicTokenRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/check_preapproved_token",
            payload
        );

        if (status === 200) {
            if (data.valid) {
                localStorage.setItem("magicToken", payload.token);
            }
            yield put(checkMagicTokenSuccess(data));
        } else {
            yield put(checkMagicTokenFailure());
        }
    } catch (error) {
        yield put(checkMagicTokenFailure());
    }
}

export function* getIntercomHash() {
    try {
        const { data } = yield call(get, "/user/intercom_hash", {});
        yield put(setIntercomHash(data.data));
    } catch (error) {
        console.error(error);
    }
}

function* postMagicPhoneAuthRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(post, "/phone_auth_preapproved", {
            phone_number: payload.phoneNumber,
            auth_code: payload.authCode,
            token: payload.magicToken
        });

        if (status !== 200) {
            window.alert("Something went wrong, please try again");
            yield put(postMagicPhoneAuthFailure(data));
            return;
        }

        const { token, userid, firebaseToken } = data;

        localStorage.setItem("token", token);
        localStorage.setItem("userid", userid.id);
        localStorage.setItem("firebaseToken", firebaseToken);

        setToken();
        yield call(setFirebaseToken, firebaseToken);
        // localStorage.removeItem("magicToken");
        localStorage.removeItem("devAccount");

        yield put(push("/onboarding"));
    } catch (error) {
        yield put(
            postMagicPhoneAuthFailure("Something went wrong, please try again")
        );
        window.alert("Something went wrong, please try again");
    }
}

function* completeAccountSetupRequest({ payload }: Action) {
    try {
        yield call(patch, `/v1/publisher/p:${payload}`, {
            accountSetup: true
        });

        localStorage.removeItem("magicToken");

        yield put(completeAccountSetupSuccess());
    } catch (error) {}
}

export default function* authSaga() {
    yield all([
        takeEvery(INTERCOM_HASH.REQUEST, getIntercomHash),
        takeEvery(POST_INVITE_CODE.REQUEST, postInviteCodeRequest),
        takeEvery(POST_PHONE_NUMBER.REQUEST, postPhoneNumberRequest),
        takeEvery(POST_PHONE_NUMBER.CALL_REQUEST, postPhoneNumberCallRequest),
        takeEvery(POST_PHONE_AUTH.REQUEST, postPhoneAuthRequest),
        takeEvery(
            POST_PHONE_NUMBER.RESEND_REQUEST,
            postPhoneNumberResendRequest
        ),
        takeEvery(ASSIGN_CODE_TO_USER, assignCodeToUserRequest),
        takeEvery(SAVE_PERSONAL_DETAILS, savePersonalDetails),
        takeEvery(CHECK_MAGIC_TOKEN.REQUEST, checkMagicTokenRequest),
        takeEvery(POST_MAGIC_PHONE_AUTH.REQUEST, postMagicPhoneAuthRequest),
        takeEvery(COMPLETE_ACCOUNT_SETUP.REQUEST, completeAccountSetupRequest),
        takeEvery(FETCH_REFERRING_PUBLISHER.REQUEST, fetchReferringPublisher)
    ]);
}
