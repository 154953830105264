import React from "react";

import { Props } from "src/components/icons/types";

const Link = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || "10"}
            viewBox="0 0 10 10"
            fill="none"
        >
            <g clipPath="url(#A)">
                <path
                    fillRule="evenodd"
                    d="M1.709 3.522l1.049 1.372a.41.41 0 0 1-.068.585c-.173.147-.433.114-.578-.075l-1.051-1.36C.645 3.506.446 2.85.513 2.18A2.35 2.35 0 0 1 1.404.5C1.918.093 2.573-.077 3.231.032c.648.096 1.241.451 1.657.99L5.94 2.37c.863 1.118.713 2.711-.343 3.545-.092.073-.203.095-.309.081s-.208-.063-.281-.157a.41.41 0 0 1 .068-.585c.699-.552.787-1.608.204-2.362l-1.051-1.36a1.75 1.75 0 0 0-1.13-.679c-.435-.068-.856.047-1.186.308a1.55 1.55 0 0 0-.582 1.089 1.82 1.82 0 0 0 .378 1.273zm7.23 2.434c.417.537.615 1.204.548 1.873-.056.682-.377 1.27-.893 1.676-.489.385-1.122.558-1.76.476l-.059-.008c-.649-.095-1.243-.45-1.661-.987L4.061 7.628c-.865-1.115-.715-2.705.343-3.537.173-.147.433-.114.579.074.147.176.117.438-.069.584-.701.551-.789 1.604-.205 2.357l1.054 1.345c.292.376.685.608 1.133.678.436.068.858-.047 1.188-.307s.544-.644.584-1.086a1.81 1.81 0 0 0-.379-1.27L7.238 5.109c-.147-.176-.117-.438.069-.584.173-.147.434-.114.579.074l1.053 1.357z"
                    fill={props.fill || "#000"}
                />
            </g>
            <defs>
                <clipPath id="A">
                    <path fill="#fff" d="M0 0h10v10H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Link;
