import React, { useState } from "react";
import styled from "styled-components";
import { WeeklyLeaderboardItem } from "../../profile/types";
import FullScreenModal from "src/modals/FullScreenModal";
import SimpleNavbar from "src/components/SimpleNavbar";
import WeeklyTopThree from "../../profile/bonus/weekly/WeeklyTopThree";
import WeeklyCell from "../../profile/bonus/weekly/WeeklyCell";
import { ContestRank } from "src/campaigns/contests/types";
import RankCell from "src/campaigns/contests/main/RankCell";

const Button = styled.img`
    padding: 15px;
    cursor: pointer;

    svg {
        margin-right: 15px;
    }
`;
const List = styled.div`
    padding: 20px 0px;
`;

interface Props {
    weeklyLeaderboard?: WeeklyLeaderboardItem[];
    leaderboard?: ContestRank[];
    closeCallback: () => void;
    onSelect?: (publisher: WeeklyLeaderboardItem) => void;
    self?: WeeklyLeaderboardItem | ContestRank;
    onScrollEnd?: () => void;
}

const LeaderboardModal = ({
    weeklyLeaderboard,
    leaderboard,
    onScrollEnd,
    closeCallback,
    onSelect,
    self
}: Props) => {
    const [maxItems, setMaxItems] = useState(25);

    let weeklyList: WeeklyLeaderboardItem[] = [];
    let contestList: ContestRank[] = [];
    if (weeklyLeaderboard) {
        if (maxItems) {
            weeklyList = weeklyLeaderboard.slice(3, maxItems);
        } else if (self && self.rank < 6) {
            weeklyList = weeklyLeaderboard.slice(3, 6);
        }
    }
    if (leaderboard) {
        if (maxItems) {
            contestList = leaderboard.slice(0, maxItems);
        } else if (self && self.rank < 6) {
            contestList = leaderboard.slice(0, 6);
        }
    }
    const handleScroll = (offset: number) => {
        const position = offset - window.innerHeight;
        if (
            ((leaderboard && maxItems < leaderboard.length) ||
                (weeklyLeaderboard && maxItems < weeklyLeaderboard.length)) &&
            position < 20
        ) {
            setMaxItems(maxItems + 5);
            if (onScrollEnd) onScrollEnd();
        }
    };

    return (
        <FullScreenModal closeModal={closeCallback} onScroll={handleScroll}>
            <SimpleNavbar
                title={"Leaderboard"}
                left={<Button onClick={closeCallback} src={"/close.svg"} />}
            />
            {weeklyLeaderboard ? (
                <>
                    <WeeklyTopThree
                        weeklyLeaderboard={weeklyLeaderboard}
                        onSelect={onSelect}
                    />
                    <List>
                        {weeklyList.map((leaderboardItem, idx) => {
                            return (
                                <WeeklyCell
                                    onSelect={onSelect}
                                    key={idx}
                                    leaderboardItem={leaderboardItem}
                                />
                            );
                        })}
                    </List>
                </>
            ) : (
                <List>
                    {leaderboard &&
                        contestList.map((leaderboardItem, idx) => {
                            return (
                                <RankCell
                                    key={idx}
                                    leaderboardItem={leaderboardItem}
                                />
                            );
                        })}
                </List>
            )}
        </FullScreenModal>
    );
};

export default LeaderboardModal;
