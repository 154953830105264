import React from "react";
import styled from "styled-components";
import { fonts } from "src/constants";

const Main = styled.div`
    font-size: ${fonts.subtitle}px;
    line-height: 19px;
    text-align: left;
`;

const Title = styled.div`
    font-weight: bold;
    padding-bottom: 12px;
`;

const Flex = styled.div`
    display: flex;
`;

const ListItem = styled(Flex)`
    padding-top: 20px;

    & + & {
        padding-top: 15px;
    }
`;

const Icon = styled.div`
    min-width: 25px;
`;

interface Props {
    visibility: "visible" | "connections" | "hidden";
}

const PrivacyAccountDescriptions = ({ visibility }: Props) => {
    let title: string;
    let description: string;
    let messages: { text: string; valence: boolean }[];

    if (visibility === "visible") {
        title = "Visible";
        description =
            "This account will be visible in your public profile, all influencers and advertisers can view it";
        messages = [
            {
                text: "Increased campaign offers from advertisers",
                valence: true
            },
            {
                text: "More connection requests",
                valence: true
            },
            {
                text:
                    "All influencers can find your account for business requests",
                valence: true
            }
        ];
    } else if (visibility === "connections") {
        title = "Connections Only";
        description =
            "This account will only be visible to influencers you are connected with.";
        messages = [
            {
                text: "Decreased campaign offers from advertisers",
                valence: false
            },
            {
                text: "Fewer connection requests",
                valence: false
            },
            {
                text:
                    "You connections can find your account for business requests",
                valence: true
            }
        ];
    } else {
        title = "Hidden";
        description =
            "This account will only be visible to you.  Other influencers will not see it on your public profile.";
        messages = [
            {
                text: "Decreased campaign offers from advertisers",
                valence: false
            },
            {
                text: "Fewer connection requests",
                valence: false
            },
            {
                text:
                    "No inluencers can find your account for business requests",
                valence: false
            }
        ];
    }

    return (
        <Main>
            <Title>{title}</Title>
            <Flex>
                <Icon>👉</Icon>
                <div>
                    <div>{description}</div>
                    {messages.map(message => {
                        return (
                            <ListItem key={message.text}>
                                <Icon>{message.valence ? "✅" : "❌"}</Icon>
                                {message.text}
                            </ListItem>
                        );
                    })}
                </div>
            </Flex>
        </Main>
    );
};

export default PrivacyAccountDescriptions;
