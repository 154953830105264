import React from "react";
import { useSelector } from "react-redux";
import { CustomLoading } from "src/components/SkeletonLoader";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import { getPlacementsDisplayText } from "../selectors";

const ButtonWrapper = styled.div`
    background-color: ${colors.lightTeal};
    padding: 10px 0 0 10px;
    display: flex;
    justify-content: flex-start;
`;

const ButtonText = styled.div`
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    color: ${colors.primaryGray};
`;

const Button = styled.div`
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px 7px;
    border-radius: 2px;
    border: 1px solid ${colors.mediumTeal};
    cursor: pointer;
`;

const Caret = styled.img`
    width: 8px;
    margin-left: 8px;
`;

interface Props {
    openPlacementsModal: () => void;
}

const PlacementSelector = ({ openPlacementsModal }: Props) => {
    const displayText = useSelector(getPlacementsDisplayText);
    const placementsLoading = useSelector(
        (state: GlobalState) => state.ui.placements.placementsLoading
    );

    if (placementsLoading)
        return (
            <ButtonWrapper>
                <CustomLoading height="24px" width="120px" borderRadius="4px" />
            </ButtonWrapper>
        );

    return (
        <ButtonWrapper>
            <Button onClick={openPlacementsModal}>
                <ButtonText>{displayText}</ButtonText>
                <Caret src={"/caret.svg"} />
            </Button>
        </ButtonWrapper>
    );
};

export default PlacementSelector;
