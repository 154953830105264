import React from "react";
import { Channel } from "../../types";
import { colors } from "../../../constants";
import styled from "styled-components";
import SpaceBetween from "../../../components/SpaceBetween";
import {
    BigBlueButton,
    BigOutlineButton
} from "../../../profile/components/Buttons";

const Question = styled.div`
    font-size: 12px;
    text-align: left;
    margin-bottom: 12px;
    color: ${colors.primaryGray};
`;

const DeleteButton = styled(BigOutlineButton)`
    width: calc(50% - 7px);
`;

const AcceptButton = styled(BigBlueButton)`
    width: calc(50% - 7px);
`;

interface Props {
    channel: Channel;
    deleteCallback: VoidFunction;
    acceptCallback: VoidFunction;
}

const InvitationInput = ({
    channel,
    deleteCallback,
    acceptCallback
}: Props) => {
    return (
        <>
            <Question>
                Do you want to let {channel.sendBirdData.inviter.nickname}{" "}
                message you?
            </Question>
            <SpaceBetween>
                <DeleteButton onClick={deleteCallback}>Delete</DeleteButton>
                <AcceptButton onClick={acceptCallback}>Accept</AcceptButton>
            </SpaceBetween>
        </>
    );
};

export default InvitationInput;
