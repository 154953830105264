import { Action, NormalizedAction } from "../../types";
import { combineReducers } from "redux";
import {
    DIRECT_BUY_MODAL,
    SET_DIRECT_BUY_ERROR_STATE,
    SET_DIRECT_BUYS_LOADING,
    TOGGLE_DIRECT_BUY_STEP_EXPANDED_STATE,
    SET_DIRECT_BUY_SEARCH,
    CLEAR_DIRECT_BUY_SEARCH,
    SET_SELECTED_ACCOUNTS,
    SET_STATUS_FILTERS,
    SET_STORED_STATUSES
} from "./actions";
import {
    ADD_DIRECT_BUY_ENTITIES,
    REQUEST_DIRECT_BUY_BY_ID,
    REQUEST_DIRECT_BUYS_BY_STATUS,
    ADD_OPEN_BUY_ENTITIES,
    TOGGLE_OPEN_BUY_STEP_EXPANDED_STATE,
    OPEN_BUY_SUBMISSION_MODAL,
    OPEN_BUY_SUBMISSION_STEP,
    SET_CACHED_FILTERS,
    CLEAR_CACHED_FILTERS,
    TOGGLE_RULES_MODAL
} from "../../buys/actions";
import {
    DirectBuyStatus,
    directBuyStatusAll
} from "../../buys/modules/direct-buy/types";
import { CLEAR_MODAL, SET_SIMPLE_MEDIA } from "../../modals/actions";
import { SimpleMedia } from "../../modals/SimpleMediaModal";
import { Moment } from "moment";

export enum DirectBuyError {
    generic = "generic",
    notFound = "notFound",
    accessDenied = "accessDenied"
}

export interface DirectBuyErrorState {
    [directBuyId: number]: DirectBuyError;
}

export enum DirectBuySearchModalState {
    clear,
    sort,
    filter,
    date
}

export enum DirectBuyDetailsModalState {
    clear,
    actions,
    rejectionReason,
    simpleMedia,
    script,
    selectAccount,
    submitLink
}

interface DirectBuyModalState {
    search: DirectBuySearchModalState;
    details: DirectBuyDetailsModalState;
    rulesModal: number | null;
}

export interface DirectBuyStepsExpandedState {
    [id: number]: boolean;
}

export interface CachedFilters {
    cachedDate: { type: string | null; range: Moment[] | null };
    cachedSortMode: string | null;
    cachedScrollY: number | null;
    cachedStatuses: DirectBuyStatus[] | null;
    cachedSearch: string | null;
}

export interface DirectBuysUIState {
    directBuysLoading: boolean;
    errorState: DirectBuyErrorState;
    storedStatuses: DirectBuyStatus[];
    statusFilters: DirectBuyStatus[];
    modalState: DirectBuyModalState;
    currentMedia: SimpleMedia | null;
    stepsExpandedState: DirectBuyStepsExpandedState;
    openBuyStepsExpandedState: DirectBuyStepsExpandedState;
    searchedIds: number[] | null;
    selectedAccounts: number[];
    openBuySubmissionStep: number | null;
    cachedFilters: CachedFilters;
}
export const clearedCachedFilters: CachedFilters = {
    cachedDate: { type: null, range: null },
    cachedSortMode: null,
    cachedScrollY: null,
    cachedStatuses: null,
    cachedSearch: null
};
const initialDirectBuysUIState: DirectBuysUIState = {
    directBuysLoading: true,
    errorState: {},
    storedStatuses: [],
    statusFilters: directBuyStatusAll,
    modalState: {
        search: DirectBuySearchModalState.clear,
        details: DirectBuyDetailsModalState.clear,
        rulesModal: null
    },
    currentMedia: null,
    searchedIds: null,
    stepsExpandedState: {},
    openBuyStepsExpandedState: {},
    selectedAccounts: [],
    openBuySubmissionStep: null,
    cachedFilters: clearedCachedFilters
};

export interface SubmissionUIState {
    selectedAccounts: number[];
}

const directBuysLoading = (
    state = initialDirectBuysUIState.directBuysLoading,
    action: NormalizedAction
): boolean => {
    switch (action.type) {
        case SET_DIRECT_BUYS_LOADING:
            return action.payload;
        default:
            return state;
    }
};

const errorState = (
    state = initialDirectBuysUIState.errorState,
    action: Action
): DirectBuyErrorState => {
    switch (action.type) {
        case REQUEST_DIRECT_BUY_BY_ID:
            const newState = { ...state };
            delete newState[action.payload];
            return newState;
        case SET_DIRECT_BUY_ERROR_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

const storedStatuses = (
    state = initialDirectBuysUIState.storedStatuses,
    action: NormalizedAction
): DirectBuyStatus[] => {
    switch (action.type) {
        case ADD_DIRECT_BUY_ENTITIES:
            if (action.payload.status) {
                return Array.from(
                    new Set([...state, ...action.payload.status])
                );
            } else {
                return state;
            }
        case SET_STORED_STATUSES:
            return Array.from(new Set(action.payload));
        default:
            return state;
    }
};

const statusFilters = (
    state = initialDirectBuysUIState.statusFilters,
    action: Action
): DirectBuyStatus[] => {
    switch (action.type) {
        case REQUEST_DIRECT_BUYS_BY_STATUS:
            return Array.from(new Set(action.payload.status));
        case SET_STATUS_FILTERS:
            return Array.from(new Set(action.payload));
        default:
            return state;
    }
};

const modalState = (
    state = initialDirectBuysUIState.modalState,
    action: Action
): DirectBuyModalState => {
    switch (action.type) {
        case CLEAR_MODAL:
            return { ...initialDirectBuysUIState.modalState };
        case DIRECT_BUY_MODAL.SEARCH:
            return { ...state, search: action.payload };
        case DIRECT_BUY_MODAL.DETAILS:
            return { ...state, details: action.payload };
        case TOGGLE_RULES_MODAL:
            return { ...state, rulesModal: action.payload };
        default:
            return state;
    }
};

const currentMedia = (
    state = initialDirectBuysUIState.currentMedia,
    action: Action
): SimpleMedia | null => {
    switch (action.type) {
        case CLEAR_MODAL:
            return null;
        case SET_SIMPLE_MEDIA:
            return action.payload;
        default:
            return state;
    }
};

const searchedIds = (
    state = initialDirectBuysUIState.searchedIds,
    action: Action
): number[] | null => {
    switch (action.type) {
        case SET_DIRECT_BUY_SEARCH:
            return action.payload;
        case CLEAR_DIRECT_BUY_SEARCH:
            return null;
        default:
            return state;
    }
};

const stepsExpandedState = (
    state = initialDirectBuysUIState.stepsExpandedState,
    action: NormalizedAction
): DirectBuyStepsExpandedState => {
    switch (action.type) {
        case ADD_DIRECT_BUY_ENTITIES:
            const newState: DirectBuyStepsExpandedState = {};
            const directBuyStepEntities =
                action.response.entities.directBuyStep;
            if (!directBuyStepEntities) {
                return state;
            }
            const ids = Object.keys(directBuyStepEntities);
            for (const id of ids) {
                const storedValue: string | undefined =
                    localStorage[`directBuyStepsExpandedState:${id}`];
                const parsedValue: boolean = storedValue === "true";
                newState[parseInt(id)] = parsedValue;
                localStorage[`directBuyStepsExpandedState:${id}`] = parsedValue;
            }
            return { ...state, ...newState };
        case TOGGLE_DIRECT_BUY_STEP_EXPANDED_STATE:
            const directBuyStepId: number = action.payload;
            const invertedExpandedState: boolean = !state[directBuyStepId];
            localStorage[
                `directBuyStepsExpandedState:${directBuyStepId}`
            ] = invertedExpandedState;
            return { ...state, [directBuyStepId]: invertedExpandedState };
        default:
            return state;
    }
};

const openBuyStepsExpandedState = (
    state = initialDirectBuysUIState.stepsExpandedState,
    action: NormalizedAction
): DirectBuyStepsExpandedState => {
    switch (action.type) {
        case ADD_OPEN_BUY_ENTITIES:
            const newState: DirectBuyStepsExpandedState = {};
            const openBuyStepEntities = action.response.entities.openBuyStep;
            if (!openBuyStepEntities) {
                return state;
            }
            const ids = Object.keys(openBuyStepEntities);
            for (const id of ids) {
                const storedValue: string | undefined =
                    localStorage[`openBuyStepsExpandedState:${id}`];
                const parsedValue: boolean = storedValue === "true";
                newState[parseInt(id)] = parsedValue;
                localStorage[`openBuyStepsExpandedState:${id}`] = parsedValue;
            }
            return { ...state, ...newState };
        case TOGGLE_OPEN_BUY_STEP_EXPANDED_STATE:
            const openBuyStepId: number = action.payload;
            const invertedExpandedState: boolean = !state[openBuyStepId];
            localStorage[
                `openBuyStepsExpandedState:${openBuyStepId}`
            ] = invertedExpandedState;
            return { ...state, [openBuyStepId]: invertedExpandedState };
        default:
            return state;
    }
};

const openBuySubmissionStep = (
    state = initialDirectBuysUIState.openBuySubmissionStep,
    action: NormalizedAction
): number | null => {
    switch (action.type) {
        case OPEN_BUY_SUBMISSION_MODAL:
            return action.payload;
        case OPEN_BUY_SUBMISSION_STEP:
            return action.payload;
        default:
            return state;
    }
};

const selectedAccounts = (
    state = initialDirectBuysUIState.selectedAccounts,
    action: NormalizedAction
): number[] => {
    switch (action.type) {
        case SET_SELECTED_ACCOUNTS:
            return action.payload;
        case OPEN_BUY_SUBMISSION_MODAL:
            return initialDirectBuysUIState.selectedAccounts;
        default:
            return state;
    }
};
const cachedFilters = (
    state = initialDirectBuysUIState.cachedFilters,
    action: Action
) => {
    switch (action.type) {
        case SET_CACHED_FILTERS:
            return action.payload;
        case CLEAR_CACHED_FILTERS:
            return clearedCachedFilters;
        default:
            return state;
    }
};

const directBuysUIReducer = combineReducers<DirectBuysUIState>({
    directBuysLoading,
    errorState,
    storedStatuses,
    statusFilters,
    modalState,
    currentMedia,
    stepsExpandedState,
    openBuyStepsExpandedState,
    searchedIds,
    selectedAccounts,
    openBuySubmissionStep,
    cachedFilters
} as any);

export default directBuysUIReducer;
