import React from "react";
import { SocialAccount } from "../../types";
import styled from "styled-components";
import VisibilityIcon from "./VisibilityIcon";
import VerticallyCentered from "../../../components/VerticallyCentered";
import { fonts } from "src/constants";

const Info = styled(VerticallyCentered)`
    font-size: ${fonts.subtitle}px;
    svg {
        margin-right: 12px;
    }
`;

const Caret = styled.img`
    margin-left: auto;
`;

interface Props {
    visibility: SocialAccount["visibility"];
}

const VisibilityRow = ({ visibility }: Props) => {
    return (
        <>
            <Info>
                <VisibilityIcon visibility={visibility} />
                <div>
                    {visibility === "visible"
                        ? "Visible"
                        : visibility === "connections"
                        ? "Connections Only"
                        : "Hidden"}
                </div>
            </Info>
            <Caret src="/caret.svg" />
        </>
    );
};

export default VisibilityRow;
