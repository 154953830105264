import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Profile } from "../profile/types";
import { OldSocialAccount, SocialAccountPost } from "./SocialAccount";
import { postAccounts, selectCurrentAccount } from "./actions";
import { accountCompleted, magicInProgress } from "../util";
import { push } from "connected-react-router";
import SimpleNavbar from "../components/SimpleNavbar";
import NavButton from "../components/NavButton";
import FlexColumn from "../components/FlexColumn";
import SocialAccountCell from "./SocialAccountCell";
import AccountsTooSmall from "./AccountsTooSmall";
import MainContainer from "src/components/MainContainer";
import styled from "styled-components";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import FullscreenLoader from "src/components/FullscreenLoader";
import { clearSpacing } from "src/utils/styles/snippets";
import { getPreApprovalCode } from "src/profile/selectors";

interface Props {
    profile: Profile;
    userAccounts: Array<OldSocialAccount>;
    selectCurrentAccount: (index: number) => void;
    postAccounts: (socialAccounts: Array<SocialAccountPost>) => void;
    push: (route: string) => void;
}

const AddSocialButton = styled.button`
    width: 100%;
    height: 40px;
    color: #4de77f;
    border: #4de77f solid 1px;
    border-radius: 5px;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            color: ${colors.white};
            background-color: #4de77f;
        }
    }
`;

const ErrorMessage = styled.p`
    ${clearSpacing()};
    margin: 20px 20px 0px;
    color: ${colors.primaryRed};
    font-size: 16px;
`;

const AccountsList = ({
    profile,
    userAccounts,
    selectCurrentAccount,
    postAccounts,
    push
}: Props) => {
    const [tooSmall, setTooSmall] = useState(false);
    const preApprovalCode = useSelector(getPreApprovalCode);

    function handleBackClicked() {
        if (accountCompleted(profile, preApprovalCode)) {
            push("/profile");
        } else {
            push("/onboarding");
        }
    }

    function handleNextClicked() {
        const hasLargeAccount = userAccounts.some(
            account => parseInt(account.num_followers) > 5000
        );

        if (userAccounts.length === 0) {
            return;
        } else if (!hasLargeAccount && !magicInProgress()) {
            setTooSmall(true);
        } else {
            const accountsPost = userAccounts.map(account => {
                return {
                    accountId: account.id,
                    accountUsername: account.username,
                    networkName: account.platform,
                    accountFollowers: account.num_followers,
                    theme: account.theme
                };
            });
            postAccounts(accountsPost);
        }
    }

    const header1 = "Add / Edit Socials";
    const header2 =
        "The Plug is exclusively for top influencers. Which accounts will you promote with?";

    const leftNavButton = (
        <NavButton onClick={handleBackClicked} text={"Back"} />
    );
    const rightNavButton = (
        <NavButton
            onClick={handleNextClicked}
            text={accountCompleted(profile, preApprovalCode) ? "Done" : "Next"}
            disabled={userAccounts.length === 0}
            right
        />
    );

    const postAccountsLoading = useSelector((state: GlobalState) => {
        return state.ui.socialAccounts.postAccountsLoading;
    });

    const postAccountsError = useSelector((state: GlobalState) => {
        return state.ui.socialAccounts.postAccountsError;
    });
    const hasPostAccountsError =
        postAccountsError.length > 0 || postAccountsError !== "";

    if (tooSmall) {
        return <AccountsTooSmall closePage={() => setTooSmall(false)} />;
    } else {
        return (
            <>
                <SimpleNavbar left={leftNavButton} right={rightNavButton} />

                {postAccountsLoading ? (
                    <FullscreenLoader />
                ) : (
                    <MainContainer>
                        {hasPostAccountsError && (
                            <ErrorMessage>{postAccountsError}</ErrorMessage>
                        )}

                        <FlexColumn>
                            <div>
                                <p className={"lead"}>
                                    {accountCompleted(profile, preApprovalCode)
                                        ? header1
                                        : header2}
                                </p>
                                {userAccounts.map((account, index) => {
                                    return (
                                        <SocialAccountCell
                                            account={account}
                                            onClick={() => {
                                                selectCurrentAccount(index);
                                                push("/social-accounts/edit");
                                            }}
                                            key={index}
                                        />
                                    );
                                })}
                                <AddSocialButton
                                    onClick={() => {
                                        selectCurrentAccount(-1);
                                        push("/social-accounts/edit");
                                    }}
                                >
                                    + Add Social Account
                                </AddSocialButton>
                            </div>
                        </FlexColumn>
                    </MainContainer>
                )}
            </>
        );
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    selectCurrentAccount: (index: number) =>
        dispatch(selectCurrentAccount(index)),
    postAccounts: (socialAccounts: Array<SocialAccountPost>) =>
        dispatch(postAccounts(socialAccounts)),
    push: (route: string) => dispatch(push(route))
});

export default connect(null, mapDispatchToProps)(AccountsList);
