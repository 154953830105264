import React, { useState } from "react";
import SearchWithTags from "../../components/SearchWithTags";
import PaddingNoTop from "../../components/PaddingNoTop";
import SelectConnectionRow from "../../components/SelectConnectionRow";
import { Connection } from "../../profile/types";
import styled from "styled-components";
import { colors } from "../../constants";
import MainContainer from "src/components/MainContainer";

const Suggested = styled.div`
    font-weight: bold;
    font-size: 12px;
    color: ${colors.primaryGray};
    text-align: left;
    padding: 15px;
`;

interface Props {
    connections: Connection[];
    selectedConnections: Connection[];
    setSelectedConnections: (selectedConnections: Connection[]) => void;
}

const SelectConnectionsScreen = ({
    connections,
    selectedConnections,
    setSelectedConnections
}: Props) => {
    const [searchString, setSearchString] = useState("");

    return (
        <>
            <MainContainer>
                <SearchWithTags
                    searchString={searchString}
                    setSearchString={setSearchString}
                    tags={selectedConnections.map(connection => {
                        return {
                            name:
                                connection.other.profile.username ||
                                `User: ${connection.other.id.slice(2)}`,
                            value: connection.other.id
                        };
                    })}
                    removeCallback={otherId => {
                        const newArray = selectedConnections.filter(
                            connection => {
                                return otherId !== connection.other.id;
                            }
                        );

                        setSelectedConnections(newArray);
                    }}
                />
                <Suggested>SUGGESTED</Suggested>
                <PaddingNoTop>
                    {connections
                        .filter(connection => {
                            if (searchString.length === 0) {
                                return true;
                            } else {
                                return (
                                    connection.other.profile.username &&
                                    connection.other.profile.username
                                        .toLowerCase()
                                        .includes(searchString.toLowerCase())
                                );
                            }
                        })
                        .map(connection => {
                            const selected = selectedConnections.some(
                                possibleMatch => {
                                    return (
                                        connection.other.id ===
                                        possibleMatch.other.id
                                    );
                                }
                            );

                            return (
                                <SelectConnectionRow
                                    key={connection.other.id}
                                    connection={connection}
                                    selected={selected}
                                    onClick={() => {
                                        if (selected) {
                                            const newArray = selectedConnections.filter(
                                                possibleMatch => {
                                                    return (
                                                        possibleMatch.other
                                                            .id !==
                                                        connection.other.id
                                                    );
                                                }
                                            );

                                            setSelectedConnections(newArray);
                                        } else {
                                            setSelectedConnections([
                                                ...selectedConnections,
                                                connection
                                            ]);
                                        }
                                    }}
                                />
                            );
                        })}
                </PaddingNoTop>
            </MainContainer>
        </>
    );
};

export default SelectConnectionsScreen;
