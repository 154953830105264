import { createSelector } from "reselect";
import { GlobalState } from "../../reducers";

const currentContest = (state: GlobalState) => state.ui.contests.currentContest;
const contestEntities = (state: GlobalState) => state.entities.contests.byId;
const allContestIds = (state: GlobalState) => state.entities.contests.allIds;

export const getContest = createSelector(
    contestEntities,
    allContestIds,
    currentContest,
    (contestEntities, allContestIds, currentContest) => {
        if (currentContest) {
            return contestEntities[currentContest];
        } else if (allContestIds) {
            return contestEntities[allContestIds[0]];
        }
    }
);

export const getContests = createSelector(
    contestEntities,
    allContestIds,
    (contestEntities, allContestIds) => {
        return allContestIds.map(contestId => contestEntities[contestId]);
    }
);
