import React, { useState } from "react";
import NavButton from "src/components/NavButton";
import { Header } from "src/components/text/CommonStyles";
import Title from "src/components/text/Title";
import styled from "styled-components";
import LinkImageEdit from "./LinkImageEdit";
import LinkListDeleteModal from "./LinkListDeleteModal";
import { LinkSelectorOption } from "./LinkCampaignPlatformSelector";
import Subtitle from "src/components/text/Subtitle";
import { breakpoints, colors, fonts } from "src/constants";
import { BlueButton } from "src/profile/components/Buttons";
import { LinkListItem } from "src/profile/types";
import SimpleNavbar from "src/components/SimpleNavbar";
import { useDispatch, useSelector } from "react-redux";
import { goBack } from "connected-react-router";
import { deleteLinkListItem, editLinkListItem } from "../actions";
import LinkDetailsLoader from "./LinkDetailsLoader";
import { setLinkListError } from "src/ui/link-lists/actions";
import Platforms from "src/social-accounts/Platforms";
import { getLinkListError } from "../selectors";
import { GlobalState } from "src/reducers";
import { setLinkListType } from "src/ui/link-lists/actions";
import { useEffect } from "react";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 900px;
    padding: 0 18px 0 18px;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 20px;
    max-width: 700px;
    width: 100%;
    margin-top: 10px;
    margin: 0 18px;
    align-self: center;
    padding: 45px;
`;

const Input = styled.input`
    border: none;
    background: none;
    padding: 10px;
    width: 100%;
    outline: none;
    border-bottom: 1px solid ${colors.mediumTeal};
    &::placeholder {
        color: ${colors.secondaryGray};
    }
`;

const PreviewContainer = styled.a`
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: 15px 25px 15px 25px;
    align-items: center;
    border-radius: 6px;
    background-color: white;
    text-decoration: none;
    outline: none;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 5px);
    cursor: pointer;
    color: black;
    width: 100%;
    &:hover {
        text-decoration: none;
        color: black;
    }
`;

const PreviewPicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 100px;
    margin-right: 10px;
    overflow: hidden;
    object-fit: cover;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    font-size: ${fonts.subtitle}px;
    font-weight: 600;
`;

const CreateButton = styled(BlueButton)`
    height: 50px;
    font-size: 16px;
    width: 700px;
    margin-right: 10px;
    margin: 50px auto;
    @media (max-width: ${breakpoints.small}px) {
        width: 90vw;
    }
`;

const Preview = styled(Subtitle)`
    margin: 16px 0 16px 0;
    font-weight: bold;
`;

interface Props {
    details?: LinkSelectorOption | null;
    setDetails: (a: any) => void;
    link?: LinkListItem | null;
    linkListId: string | null;
}

const Error = styled.div`
    margin-top: 16px;
    color: ${colors.primaryRed};
    text-align: center;
`;

const LinkEdit = ({ details, setDetails, link, linkListId }: Props) => {
    const error = useSelector((state: GlobalState) =>
        getLinkListError(state, "LinkDetails")
    );
    const addType = useSelector(
        (state: GlobalState) => state.ui.linkList.createType
    );
    const [deleting, setDeleting] = useState(false);

    const getSocialUsername = () => {
        const platformNames = Object.keys(Platforms);
        let username: string | undefined = "";
        if (!link) return "";
        else if (link.url?.includes("tumblr")) {
            username = link.url.replace("https://", "");
            username = username?.replace(".tumblr.com/", "");
        } else
            platformNames.forEach(name => {
                if (link?.url?.includes(name)) {
                    username = link.url.replace(
                        Platforms[name].urlCallback("") || "",
                        ""
                    );
                }
            });
        return username;
    };

    const [input1, setInput1] = useState<string>(
        link
            ? link.type === "social"
                ? getSocialUsername()
                : link.url || ""
            : ""
    );
    useEffect(() => {
        if (link?.type === "social") setInput1(getSocialUsername());
    }, [link]);

    const getPreviewLink = () => {
        if (details?.url?.includes("%username%")) {
            let temp = details;
            return temp?.url?.replace("%username%", input1 || "");
        } else return details?.url || undefined;
    };

    const getLinkPlaceholder = () => {
        if (!details || details.type === "other") return "Link";
        else if (details.type === "social") return `username`;
        else return details.url || "";
    };

    const getPlatformImage = () => {
        const platformNames = Object.keys(Platforms);
        let img = "https://app.plugco.in/" + Platforms["other"].imgSrc;
        platformNames.forEach(name => {
            if (link?.url?.includes(name)) {
                img = "https://app.plugco.in/" + Platforms[name].imgSrc;
            }
        });
        return img;
    };

    const replaceUsername = () => {
        const platformNames = Object.keys(Platforms);
        let url = Platforms["other"].urlCallback(input1);
        platformNames.forEach(name => {
            if (details?.url?.includes(name) || link?.url?.includes(name)) {
                url = Platforms[name].urlCallback(input1);
            }
        });
        return url;
    };

    const saveChanges = () => {
        //validation
        if (
            (details?.type === "other" || details?.type === "social") &&
            !input1
        ) {
            dispatch(
                setLinkListError(
                    details?.type === "social"
                        ? "Must provide a username"
                        : "Must provide a link",
                    "LinkDetails"
                )
            );
            return;
        }

        let params: any = {
            ...link,
            type: details?.type,
            linkListId
        };
        switch (details?.type) {
            case "campaign":
                params = {
                    ...params,
                    url: details?.url,
                    title: details?.title,
                    photoUrl: details?.photoUrl,
                    campaignId: details?.id ? details?.id : link?.campaignId
                };
                break;
            case "social":
                params = {
                    ...params,
                    url: replaceUsername(),
                    title: details?.title,
                    photoUrl: details?.photoUrl
                };
                break;
            case "referral":
                params = {
                    ...params,
                    url: details?.url,
                    title: details?.title,
                    photoUrl: details?.photoUrl
                };
                break;
            case "other":
                params = {
                    ...params,
                    url: input1 || details?.url,
                    title: details?.title,
                    photoUrl: details?.photoUrl
                };
                break;
            default:
                break;
        }
        delete params.position;
        dispatch(editLinkListItem(params));
    };

    const dispatch = useDispatch();
    if (!details || !linkListId) return <LinkDetailsLoader />;
    else
        return (
            <>
                <SimpleNavbar
                    title={link ? "Edit Link" : "Add Link"}
                    left={
                        <NavButton
                            back
                            onClick={() => {
                                if (
                                    (details.type === "campaign" ||
                                        details.type === "social") &&
                                    !details.id
                                ) {
                                    dispatch(setLinkListType(details.type));
                                    setDetails(null);
                                } else {
                                    dispatch(goBack());

                                    dispatch(setLinkListType(undefined));
                                }
                            }}
                        />
                    }
                    right={
                        <div
                            style={{
                                cursor: "pointer",
                                display: !link?.id ? "none" : ""
                            }}
                            onClick={() => setDeleting(true)}
                        >
                            <img src="/ellipsis-button.svg" alt="" />
                        </div>
                    }
                />
                <Container>
                    <Header>
                        <Title>Link Details</Title>
                        <Subtitle>Customize your link below</Subtitle>
                    </Header>
                    <InfoContainer>
                        <Input
                            type="string"
                            placeholder={getLinkPlaceholder()}
                            value={input1}
                            onChange={e => {
                                if (addType === "other")
                                    setDetails({
                                        ...details,
                                        url: e.target.value
                                    });
                                setInput1(e.target.value);
                            }}
                            disabled={
                                details?.type === "campaign" ||
                                details?.type === "referral"
                            }
                        />
                        <Input
                            placeholder={details.title || "Title"}
                            onChange={e =>
                                setDetails({
                                    ...details,
                                    title: e.target.value
                                })
                            }
                            value={details.title}
                        />
                        <LinkImageEdit
                            type={details.type || ""}
                            image={details.photoUrl}
                            onImage={(url: string | null) =>
                                setDetails({
                                    ...details,
                                    photoUrl:
                                        url === "default"
                                            ? getPlatformImage()
                                            : url
                                })
                            }
                            campaignId={
                                link
                                    ? parseInt(link.campaignId || "")
                                    : details?.id
                            }
                        />
                    </InfoContainer>
                    <Error>{error}</Error>
                    <Preview>PREVIEW</Preview>
                    <PreviewContainer href={getPreviewLink()} target="#">
                        <PreviewPicture
                            src={details.photoUrl}
                            style={{
                                display: details.photoUrl ? "flex" : "none"
                            }}
                        />
                        <TitleContainer>{details.title}</TitleContainer>
                    </PreviewContainer>
                    <CreateButton onClick={saveChanges}>Save</CreateButton>
                </Container>
                <LinkListDeleteModal
                    type="Link"
                    open={deleting}
                    onClose={() => setDeleting(false)}
                    onDelete={() => {
                        setDeleting(false);
                        dispatch(
                            deleteLinkListItem(
                                link?.id?.toString() || "",
                                linkListId
                            )
                        );
                    }}
                />
            </>
        );
};

export default LinkEdit;
