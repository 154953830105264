import React, { useState } from "react";
import { colors, fonts, fontWeight } from "src/constants";
import { clearButton } from "src/utils/styles/snippets";
import styled from "styled-components";
import { FiltersReference } from "../hooks/useFilters";
import DropdownFilter from "./DropdownFilter";
import ServerDropdownFilter from "./ServerDropdownFilter";
import { resetSelections, ServerFilters } from "./TableServerHeader";
const FilterWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const ResetFiltersBtn = styled.button<{ disabled: boolean }>`
    ${clearButton()}
    height: 24px;
    padding: 0 6px;
    border-radius: 4px;
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.medium};
    text-align: center;
    white-space: nowrap;
    transition: 0.1s ease-in;
    background-color: ${props =>
        props.disabled ? colors.gray2 : colors.primaryBlue};
    color: ${colors.white};
    cursor: ${props => (props.disabled ? "default" : "pointer")};
    &:hover {
        background-color: ${props =>
            props.disabled ? colors.gray2 : colors.primaryBlueDark};
    }
`;

const ServerTableFilters = ({
    filters
}: {
    filters: {
        getter: ServerFilters;
        setter: React.Dispatch<React.SetStateAction<ServerFilters>>;
    };
}) => {
    return (
        <FilterWrap>
            {Object.entries(filters.getter).map(([key, filter]) =>
                filter.disabled ? null : (
                    <ServerDropdownFilter
                        key={key}
                        filterKey={key}
                        filter={filter}
                        setFilter={filters.setter}
                    />
                )
            )}

            <ResetFiltersBtn
                disabled={false}
                onClick={() => filters.setter(resetSelections(filters.getter))}
            >
                Reset Filters
            </ResetFiltersBtn>
        </FilterWrap>
    );
};

export default ServerTableFilters;
