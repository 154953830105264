import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FullScreenModal from "./FullScreenModal";
import { colors } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import { toggleRulesModal } from "src/buys/actions";
import { get } from "src/Api";
import { GlobalState } from "src/reducers";
import ApprovalModalLoading from "src/marketplace/reports/components/approvalModal/ApprovalModalLoading";
import { BigBlueButton } from "src/profile/components/Buttons";
import MediaRules from "./components/MediaRules";
import { Rule } from "src/types/challenge/Rule";
import { RuleSetResponse } from "src/types/challenge/RuleSetResponse";

const Main = styled.div`
    padding: 30px 36px;
    text-align: left;
`;
const Title = styled.h1`
    font-weight: 600;
    font-size: 24px;
`;

const Description = styled.div`
    font-weight: 400;
    font-size: 16px;
`;

const ShowMore = styled.div`
    color: ${colors.primaryBlue};
`;

const Button = styled(BigBlueButton)`
    margin: 20px auto;
`;

const MediaRulesModal = () => {
    const dispatch = useDispatch();
    const rulesModalOpen = useSelector(
        (state: GlobalState) => state.ui.directBuys.modalState.rulesModal
    );
    const [showMore, setShowMore] = useState(true);
    const [ruleSet, setRuleSet] = useState<RuleSetResponse | null>(null);
    const [dos, setDos] = useState<Rule[]>([]);
    const [donts, setDonts] = useState<Rule[]>([]);
    const [generals, setGenerals] = useState<Rule[]>([]);

    const fetchRules = async () => {
        try {
            let res = await get(`v1/ruleSet/${rulesModalOpen || 1}`, {});
            setRuleSet(res.data.data);
            let dos: Rule[] = [];
            let donts: Rule[] = [];
            let generals: Rule[] = [];
            res.data.data?.Rules.forEach((rule: Rule) => {
                if (rule.icon === "x") donts.push(rule);
                else if (rule.icon === "check") dos.push(rule);
                else generals.push(rule);
            });
            setDonts(donts);
            setDos(dos);
            setGenerals(generals);
            if (res.data.data.description.length > 100) setShowMore(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchRules();
    }, [rulesModalOpen]);

    if (!ruleSet) return <ApprovalModalLoading />;

    return (
        <FullScreenModal closeModal={() => dispatch(toggleRulesModal(null))}>
            <Main>
                <Title>{ruleSet.title}</Title>
                <Description>
                    {showMore ? (
                        ruleSet.description
                    ) : (
                        <>
                            {ruleSet.description.substring(0, 100)}
                            ...
                        </>
                    )}
                    {ruleSet.description.length > 100 && (
                        <ShowMore onClick={() => setShowMore(!showMore)}>
                            {!showMore ? "Read More" : "Show Less"}
                        </ShowMore>
                    )}
                </Description>
                <MediaRules generals={generals} dos={dos} donts={donts} />
                <Button
                    onClick={() => {
                        localStorage.setItem(
                            `viewedRuleSet${ruleSet.id}`,
                            "true"
                        );
                        dispatch(toggleRulesModal(null));
                    }}
                >
                    I Understand
                </Button>
            </Main>
        </FullScreenModal>
    );
};
export default MediaRulesModal;
