import React, { useState } from "react";
import { connect } from "react-redux";
import { Channel } from "../types";
import { goBack, push } from "connected-react-router";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import FullscreenLoader from "../../components/FullscreenLoader";
import Tab from "../../components/Tab";
import InboxTab from "./InboxTab";
import RequestsTab from "./RequestsTab";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";
import styled from "styled-components";
import { colors, maxWidthDesktop } from "src/constants";

const Tabs = styled.div`
    display: flex;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${maxWidthDesktop}px) {
        margin: 0px;
        border: none;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

enum InboxTabState {
    default,
    requests
}

interface Props {
    userId: number;
    channels: Channel[] | null;
    goBack: VoidFunction;
    push: (route: string) => void;
}

const ChannelList = ({ userId, channels, goBack, push }: Props) => {
    const [tabState, setTabState] = useState(InboxTabState.default);

    const navbar = (
        <SimpleNavbar
            title="MESSAGES"
            left={<NavButton back onClick={goBack} />}
            right={
                <NavButton onClick={() => push("/chat/create")}>
                    <img src="/compose.svg" />
                </NavButton>
            }
        />
    );

    if (!channels) {
        return (
            <>
                <Navbar title="MESSAGES" customMobileNavbar={navbar} />
                <FullscreenLoader />
            </>
        );
    }

    const inboxChannels: Channel[] = [];
    const requestChannels: Channel[] = [];

    channels
        .filter(channel => {
            return channel.lastMessage;
        })
        .forEach(channel => {
            if (channel.sendBirdData.myMemberState === "joined") {
                inboxChannels.push(channel);
            } else {
                requestChannels.push(channel);
            }
        });

    return (
        <>
            <Navbar
                marginBottom={20}
                title="MESSAGES"
                customMobileNavbar={navbar}
            />

            <MainContainer>
                {requestChannels.length > 0 && (
                    <Tabs>
                        <Tab
                            name={"Inbox"}
                            active={tabState === InboxTabState.default}
                            onClick={() => {
                                setTabState(InboxTabState.default);
                            }}
                        />
                        <Tab
                            name={`Requests (${requestChannels.length})`}
                            active={tabState === InboxTabState.requests}
                            onClick={() => {
                                setTabState(InboxTabState.requests);
                            }}
                        />
                    </Tabs>
                )}

                {requestChannels.length === 0 ||
                tabState === InboxTabState.default ? (
                    <InboxTab
                        channels={inboxChannels}
                        userId={userId}
                        push={push}
                    />
                ) : (
                    <RequestsTab
                        channels={requestChannels}
                        userId={userId}
                        push={push}
                    />
                )}
            </MainContainer>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    goBack: () => dispatch(goBack()),
    push: (route: string) => dispatch(push(route))
});

export default connect(null, mapDispatchToProps)(ChannelList);
