import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { Campaign } from "../../types";
import Divider from "../../../components/Divider";
import PaymentEarnings from "./PaymentEarnings";
import PaymentBreakdown from "./PaymentBreakdown";
import { countriesModal } from "src/ui/modals/actions";
import { selectCampaign } from "../../selectors";
import FullscreenLoader from "../../../components/FullscreenLoader";
import useAuthorization from "../../../auth/hooks/useAuthorization";
import { CampaignAction } from "../../../auth/roles";
import { ItemHeader } from "../Details.styled";
import XCard from "../../../profile/components/XCard";
import { push } from "connected-react-router";
import { startPromotingModal } from "../../../ui/auth/actions";

interface Props {
    campaign?: Campaign;
    countriesModal: (countries: string[]) => void;
    startPromotingModal: () => void;
}

const PaymentTab = ({
    campaign,
    countriesModal,
    startPromotingModal
}: Props) => {
    const [payment] = useAuthorization([CampaignAction.payment]);

    if (!campaign) return <FullscreenLoader blankSpace={332} />;

    if (!payment)
        return (
            <ItemHeader>
                <XCard
                    text="Only users of the Plug can view the campaign payment breakdown details."
                    button={{
                        text: "Sign Up",
                        callback: startPromotingModal
                    }}
                />
            </ItemHeader>
        );

    return (
        <>
            <PaymentEarnings
                total={campaign.total}
                daysActive={campaign.daysActive}
            />
            <Divider />
            <PaymentBreakdown
                installHistory={campaign.installHistory}
                countriesModal={countriesModal}
            />
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    campaign: selectCampaign(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    countriesModal: (countries: string[]) =>
        dispatch(countriesModal(countries)),
    startPromotingModal: () => dispatch(startPromotingModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTab);
