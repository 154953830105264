import React from "react";
import { OpenBuySubmissionStatuses } from "src/buys/modules/open-buys/types";
import { colors } from "src/constants";
import { BlueButton } from "src/profile/components/Buttons";
import { clearButton } from "src/utils/styles/snippets";
import styled from "styled-components";
const ApprovalBtnWrap = styled.div`
    display: flex;
    gap: 8px;
    padding-top: 24px;
`;
const ApprovalBtn = styled(BlueButton)`
    flex: 1;
    height: 32px;
`;
const RejectBtn = styled.button<{ halfCell: boolean }>`
    ${clearButton()}
    flex: ${props => (props.halfCell ? 0.5 : 1)};
    height: 32px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 6px;
    color: ${colors.primaryRed};
    background-color: ${colors.quaternaryLightGray};
    transition: background-color 0.2s ease;
    &:hover {
        background-color: ${colors.lightGray2};
    }
`;

const ReviseBtn = styled(RejectBtn)`
    flex: 1;
`;

interface Props {
    status: OpenBuySubmissionStatuses;
    setModal: (type: any) => void;
    submissionType: "text" | "link" | "media" | "spark" | "";
}

const ApprovalBtns = ({ status, submissionType, setModal }: Props) => {
    return (
        <ApprovalBtnWrap>
            {status !== OpenBuySubmissionStatuses.approved && (
                <ApprovalBtn onClick={() => setModal("approve")}>
                    Approve
                </ApprovalBtn>
            )}
            {status !== OpenBuySubmissionStatuses.pending &&
                submissionType === "media" && (
                    <ApprovalBtn onClick={() => setModal("scoreMedia")}>
                        Score Media
                    </ApprovalBtn>
                )}
            {status !== OpenBuySubmissionStatuses.revision_requested && (
                <ReviseBtn
                    onClick={() => setModal("revisionRequest")}
                    halfCell={false}
                >
                    Request Revision
                </ReviseBtn>
            )}
            {status !== OpenBuySubmissionStatuses.rejected && (
                <RejectBtn
                    onClick={() => setModal("reject")}
                    halfCell={
                        status !== OpenBuySubmissionStatuses.revision_requested
                    }
                >
                    Reject
                </RejectBtn>
            )}
        </ApprovalBtnWrap>
    );
};

export default ApprovalBtns;
