import React from "react";
import OutlineContainer from "../components/OutlineContainer";
import ListCell from "../components/ListCell";
import { useIntercom } from "react-use-intercom";
import { useDispatch } from "react-redux";
import { setSMSToSModalOpen } from "src/ui/modals/actions";

interface Props {
    push: (route: string) => void;
    logout: VoidFunction;
}

const About = ({ push, logout }: Props) => {
    const { update, showNewMessages } = useIntercom();
    const dispatch = useDispatch();
    return (
        <OutlineContainer>
            <ListCell
                clickable
                onClick={() => {
                    window.open("http://plugco.in/terms");
                }}
            >
                Terms
            </ListCell>
            <ListCell
                clickable
                onClick={() => {
                    window.open("http://plugco.in/privacy");
                }}
            >
                Privacy
            </ListCell>
            <ListCell
                clickable
                onClick={() => {
                    update({ customAttributes: { disable_chat_bot: false } });
                    showNewMessages();
                }}
            >
                Contact Us
            </ListCell>
            <ListCell
                clickable
                onClick={() => {
                    push("/rules");
                }}
            >
                The Plug Rules
            </ListCell>
            <ListCell
                clickable
                onClick={() => {
                    window.open(
                        "https://suppliers.tipalti.com/theplug/portal/index"
                    );
                }}
            >
                Go To Plug Payments
            </ListCell>
            <ListCell clickable onClick={logout}>
                Logout
            </ListCell>
        </OutlineContainer>
    );
};

export default About;
