import React from "react";
import styled from "styled-components";
import { SocialAccount } from "../../types";
import { colors } from "../../../constants";
import { shortenNumber } from "../../../util";
import Platforms from "../../../social-accounts/Platforms";

const HorizontalScrollOuter = styled.div`
    display: flex;
    overflow-x: scroll;
    margin-top: 8px;
`;

const HorizontalScrollInner = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

const CellMain = styled.div<{ nux?: true }>`
    border: 1px solid ${props => (props.nux ? "white" : colors.mediumTeal)};
    border-radius: 4px;
    overflow: hidden;
    display: flex;

    & + & {
        margin-left: 4px;
    }
`;

const IconConainter = styled.div`
    height: 46px;
    width: 46px;
    min-width: 46px;
    position: relative;
`;

const MainImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const PlatformIcon = styled.img`
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 13px;
    height: 13px;
    object-fit: cover;
    border-radius: 4px;
`;

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 46px;
    padding: 0 12px;
    font-size: 11px;
    line-height: 13px;
    white-space: nowrap;
`;

const Subtitle = styled.div<{ nux?: true }>`
    margin-top: 2px;
`;

const SocialCell = ({
    account,
    nux
}: {
    account: SocialAccount;
    nux?: true;
}) => {
    const { verified, avatar, username, platform, followers } = account;

    const platformObj = Platforms[platform.toLowerCase()] || Platforms.other;

    return (
        <CellMain>
            <IconConainter>
                <MainImage
                    src={verified && avatar ? avatar : platformObj.imgSrc}
                />
                {verified && avatar && (
                    <PlatformIcon src={platformObj.imgSrc} />
                )}
            </IconConainter>
            <TextContainer>
                <div>@{username}</div>
                <Subtitle nux={nux}>
                    <strong>{shortenNumber(parseInt(followers || "0"))}</strong>{" "}
                    on {platformObj.title}
                </Subtitle>
            </TextContainer>
        </CellMain>
    );
};

const RecommendationSocialRow = ({
    socialAccounts,
    nux
}: {
    socialAccounts: SocialAccount[];
    nux?: true;
}) => {
    return (
        <HorizontalScrollOuter>
            <HorizontalScrollInner>
                {socialAccounts.map(account => {
                    return (
                        <SocialCell
                            key={account.id}
                            account={account}
                            nux={nux}
                        />
                    );
                })}
            </HorizontalScrollInner>
        </HorizontalScrollOuter>
    );
};

export default RecommendationSocialRow;
