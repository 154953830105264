import { SelectedUsers } from "src/modals/UserSelectionWithSearchModal";
import { Action, NormalizedActionGeneric, NormalizedResponse } from "src/types";
import { AddMemberParams } from "../group-user-settings/actions";
import { Conversation } from "../types/Conversation";
import { ConversationRequest } from "../types/ConversationRequest";

export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION";
export const UPDATE_CONVERSATION_UNREAD = "UPDATE_CONVERSATION_UNREAD";
export const UPDATE_CONVERSATION_REQUEST = "UPDATE_CONVERSATION_REQUEST";

export function updateConversation(
    response: NormalizedResponse<Conversation, string[]>
): NormalizedActionGeneric<Conversation, string[]> {
    return { type: UPDATE_CONVERSATION, response };
}

export function updateConversationUnread(
    response: NormalizedResponse<Conversation, string[]>
): NormalizedActionGeneric<Conversation, string[]> {
    return { type: UPDATE_CONVERSATION_UNREAD, response };
}

export function updateConversationRequest(
    response: NormalizedResponse<ConversationRequest, string[]>
): NormalizedActionGeneric<ConversationRequest, string[]> {
    return { type: UPDATE_CONVERSATION_REQUEST, response };
}

// -----------------------------------------------------------------------------

export const CREATE_CONVERSATION = {
    REQUEST: "CREATE_CONVERSATION_REQUEST",
    SUCCESS: "CREATE_CONVERSATION_SUCCESS"
};

export interface CreateConversationParams {
    users: AddMemberParams[];
    displayName?: string;
    imageId?: string;
    submissionSetId?: number;
}

export function createConversation(
    createConversationParams: CreateConversationParams
): Action {
    return {
        type: CREATE_CONVERSATION.REQUEST,
        payload: { createConversationParams }
    };
}

export function createConversationSuccess(): Action {
    return {
        type: CREATE_CONVERSATION.SUCCESS,
        payload: null
    };
}

// -----------------------------------------------------------------------------

export const SET_CONVERSATION_REQUEST_STATUS =
    "SET_CONVERSATION_REQUEST_STATUS";

interface ConversationRequestParams {
    id: string;
    accepted?: boolean;
    deleted?: boolean;
}

export function setConversationRequestStatus(
    conversationRequestParams: ConversationRequestParams
): Action {
    return {
        type: SET_CONVERSATION_REQUEST_STATUS,
        payload: { conversationRequestParams }
    };
}

// -----------------------------------------------------------------------------

export const CLEAR_CONVERSATION_UNREAD_COUNT =
    "CLEAR_CONVERSATION_UNREAD_COUNT";

export function clearConversationUnreadCount(conversationId: string): Action {
    return {
        type: CLEAR_CONVERSATION_UNREAD_COUNT,
        payload: { conversationId }
    };
}
