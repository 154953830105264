import React from "react";
import styled from "styled-components";
import { ReferralLeaderboardItem } from "./Leaderboard";
import { coinToDollars, formatNumber } from "../util";
import ProfilePhoto from "../components/ProfilePhoto";
import { colors } from "../constants";
import LeaderboardPhoto from "src/components/LeaderboardPhoto";

const Cell = styled.div`
    width: 100%;
    display: flex;

    & + & {
        margin-top: 15px;
    }
`;

const Description = styled.div`
    width: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    overflow: hidden;
`;

const Name = styled.div`
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const NoName = styled.div`
    font-weight: 300;
    font-style: italic;
    color: ${colors.secondaryGray};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const InfluencerCount = styled.div`
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: ${colors.secondaryGray};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MoneyContainer = styled.div`
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
`;

const Cash = styled.div`
    font-size: 20px;
    line-height: 24px;
`;

interface Props {
    leaderboardItem: ReferralLeaderboardItem;
    index: number;
}

const ReferralLeaderboardCell = ({ leaderboardItem, index }: Props) => {
    const plugcoins = Math.round(leaderboardItem.earned_plugcoins);

    return (
        <Cell>
            <LeaderboardPhoto
                profilePhotoUrl={leaderboardItem.profile_photo_url}
                style={{ marginRight: "16px" }}
                place={index + 1}
            />
            <Description>
                <NameContainer>
                    {leaderboardItem.username ? (
                        <Name>{leaderboardItem.username}</Name>
                    ) : (
                        <NoName>no username</NoName>
                    )}
                    <InfluencerCount>{`Referred ${leaderboardItem.referred_influencers} influencers`}</InfluencerCount>
                </NameContainer>
                <MoneyContainer>
                    <Cash>{formatNumber(coinToDollars(plugcoins))}</Cash>
                </MoneyContainer>
            </Description>
        </Cell>
    );
};

export default ReferralLeaderboardCell;
