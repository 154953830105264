import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../reducers";
import { BonusBracket, Profile } from "../types";
import { ProfileModal } from "../reducer";
import {
    updateOrCreateTipaltiUser,
    updatePhoto,
    updatePhotoFailure,
    updateUserFields,
    updateUsername
} from "../actions";
import PhotoModal from "./PhotoModal";
import UsernameModal from "./UsernameModal";
import StatusModal from "./StatusModal";
import EmailModal from "./EmailModal";
import BracketsModal from "./BracketsModal";
import CashoutModal from "./CashoutModal";

interface Props {
    profile: Profile;
    modal: ProfileModal;
    error: string | null;
    bonusBrackets: BonusBracket[] | null | undefined;
    updatePhoto: (filename: string) => void;
    updatePhotoFailure: (error: string) => void;
    updateUsername: (username: string, publisherId: number) => void;
    updateOrCreateTipaltiUser: (email: string) => void;
    updateUserFields: (fields: { [key: string]: any }) => void;
}

const ProfileModals = ({
    profile,
    modal,
    error,
    bonusBrackets,
    updatePhoto,
    updatePhotoFailure,
    updateUsername,
    updateUserFields
}: Props) => {
    switch (modal) {
        case ProfileModal.photo:
            return (
                <PhotoModal
                    updatePhoto={updatePhoto}
                    updatePhotoFailure={updatePhotoFailure}
                />
            );
        case ProfileModal.username:
            return (
                <UsernameModal
                    publisherId={profile.id}
                    prevUsername={profile.username}
                    updateUsername={updateUsername}
                    error={error}
                />
            );
        case ProfileModal.status:
            return <StatusModal />;
        case ProfileModal.cashout:
            return <CashoutModal profile={profile} />;
        case ProfileModal.cashoutSetting:
            return <CashoutModal profile={profile} setting />;
        case ProfileModal.email:
            return (
                <EmailModal
                    prevEmail={profile.email}
                    updateUserFields={updateUserFields}
                    error={error}
                />
            );
        case ProfileModal.brackets:
            return <BracketsModal bonusBrackets={bonusBrackets} />;
        default:
            return null;
    }
};

const mapStateToProps = (state: GlobalState) => ({
    modal: state.profile.modal,
    error: state.profile.error,
    bonusBrackets: state.profile.bonusBrackets
});
//TODO: get bonus brackets from new endpoint

const mapDispatchToProps = (dispatch: any) => ({
    updatePhoto: (filename: string) => dispatch(updatePhoto(filename)),
    updatePhotoFailure: (error: string) => dispatch(updatePhotoFailure(error)),
    updateUsername: (username: string, publisherId: number) =>
        dispatch(updateUsername(username, publisherId)),
    updateOrCreateTipaltiUser: (email: string) =>
        dispatch(updateOrCreateTipaltiUser(email)),
    updateUserFields: (fields: { [key: string]: any }) =>
        dispatch(updateUserFields(fields))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileModals);
