import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../../reducers";
import { Action, NormalizedAction } from "../../types";
import { PublisherOfferParams } from "../types";

export const FETCH_PUBLISHER_OFFERS = {
    REQUEST: "FETCH_PUBLISHER_OFFERS_REQUEST",
    SUCCESS: "FETCH_PUBLISHER_OFFERS_SUCCESS",
    FAILURE: "FETCH_PUBLISHER_OFFERS_FAILURE"
};

export const fetchPublisherOffers = (): Action => ({
    type: FETCH_PUBLISHER_OFFERS.REQUEST,
    payload: null
});

export const fetchPublisherOffersSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>
): NormalizedAction => ({
    type: FETCH_PUBLISHER_OFFERS.SUCCESS,
    response
});

export const fetchPublisherOffersFailure = (error: string): Action => ({
    type: FETCH_PUBLISHER_OFFERS.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const POST_PUBLISHER_OFFER = {
    REQUEST: "POST_PUBLISHER_OFFER_REQUEST",
    SUCCESS: "POST_PUBLISHER_OFFER_SUCCESS",
    FAILURE: "POST_PUBLISHER_OFFER_FAILURE"
};

export const postPublisherOffer = (
    publisherOffer: PublisherOfferParams
): Action => ({
    type: POST_PUBLISHER_OFFER.REQUEST,
    payload: publisherOffer
});

export const postPublisherOfferSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>
): NormalizedAction => ({
    type: POST_PUBLISHER_OFFER.SUCCESS,
    response
});

export const postPublisherOfferFailure = (error: string): Action => ({
    type: POST_PUBLISHER_OFFER.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const DELETE_PUBLISHER_OFFER = {
    REQUEST: "DELETE_PUBLISHER_OFFER_REQUEST",
    SUCCESS: "DELETE_PUBLISHER_OFFER_SUCCESS",
    FAILURE: "DELETE_PUBLISHER_OFFER_FAILURE"
};

export const deletePublisherOffer = (
    publisherOfferId: number,
    accountId: number
): Action => ({
    type: DELETE_PUBLISHER_OFFER.REQUEST,
    payload: { publisherOfferId, accountId }
});

export const deletePublisherOfferSuccess = (
    deleteId: number,
    accountId: number
): Action => ({
    type: DELETE_PUBLISHER_OFFER.SUCCESS,
    payload: { deleteId, accountId }
});

export const deletePublisherOfferFailure = (error: string): Action => ({
    type: DELETE_PUBLISHER_OFFER.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const PATCH_PUBLISHER_OFFER = {
    REQUEST: "PATCH_PUBLISHER_OFFER_REQUEST",
    SUCCESS: "PATCH_PUBLISHER_OFFER_SUCCESS",
    FAILURE: "PATCH_PUBLISHER_OFFER_FAILURE"
};

export const patchPublisherOffer = (
    deleteId: number,
    accountId: number,
    newPublisherOffer: PublisherOfferParams
): Action => ({
    type: PATCH_PUBLISHER_OFFER.REQUEST,
    payload: { deleteId, accountId, newPublisherOffer }
});

export const patchPublisherOfferSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    deleteId: number,
    addId: number,
    accountId: number
): NormalizedAction => ({
    type: PATCH_PUBLISHER_OFFER.SUCCESS,
    response,
    payload: { deleteId, addId, accountId }
});

export const patchPublisherOfferFailure = (error: string): Action => ({
    type: PATCH_PUBLISHER_OFFER.FAILURE,
    payload: { error }
});
