import styled from "styled-components";
import { BlueModalButton } from "../components/Buttons";
import { colors, fonts } from "../../constants";

export const Title = styled.div`
    font-size: 20px;
    line-height: 1.2em;
    font-weight: 700;
`;

export const Section = styled.div`
    margin-top: 15px;
    margin-bottom: 18px;
    font-size: ${fonts.subtitle}px;
    font-weight: 400;
    line-height: 17px;
`;

export const EmailInput = styled.input`
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    font-weight: 300;
    text-align: center;

    &::placeholder {
        color: ${colors.primaryGray};
        font-weight: 300;
    }
`;

export const InputContainer = styled.div<{ focus: boolean }>`
    background-color: white;
    border: 1px solid ${colors.mediumTeal};
    margin-top: 34px;
    margin-bottom: 20px;
    height: 40px;
    border-radius: 6px;
    overflow-x: hidden;
    display: flex;
    ${props => {
        return (
            props.focus &&
            `box-shadow: 0px 0px 4px 0px ${colors.primaryBlue}; transition: box-shadow 0.2s;`
        );
    }}
`;

export const Button = styled(BlueModalButton)<{ disabled?: boolean }>`
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`;
