import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserSelectionWithSearchModal, {
    SelectedUsers
} from "src/modals/UserSelectionWithSearchModal";
import { GlobalState } from "src/reducers";
import { createConversation } from "./conversation/actions";
import { AddMemberParams } from "./group-user-settings/actions";
import EditGroupChannelModal from "./group-user-settings/modals/EditGroupChannelModal";

interface Props {
    closeModal: () => void;
}

const ChatCreationModal = ({ closeModal }: Props) => {
    const dispatch = useDispatch();
    const [selectedUsers, setSelectedUsers] = useState<SelectedUsers>({});
    const [groupCreationModal, toggleGroupCreationModal] = useState(false);
    const isLoading = useSelector(
        (state: GlobalState) => state.ui.chat.chatCreationLoading
    );

    function createChatController() {
        const users: AddMemberParams[] = Object.values(selectedUsers);
        if (users.length === 1) {
            dispatch(createConversation({ users }));
        } else if (users.length > 1) {
            toggleGroupCreationModal(true);
        }
    }

    function saveChanges(params: { imageId?: string; groupName?: string }) {
        const { imageId, groupName } = params;
        const users: AddMemberParams[] = Object.values(selectedUsers);

        toggleGroupCreationModal(false);
        dispatch(
            createConversation({ users, displayName: groupName, imageId })
        );
    }

    return (
        <>
            <UserSelectionWithSearchModal
                title="Start New Chat"
                actionText="Chat"
                actionCallback={createChatController}
                closeModal={closeModal}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                isLoading={isLoading}
            />
            {groupCreationModal && (
                <EditGroupChannelModal
                    title="Give this group a name:"
                    actionText="Create Group"
                    displayName=""
                    imageUrl="/profile-photo-camera-blue.svg"
                    clearModal={() => toggleGroupCreationModal(false)}
                    saveChanges={saveChanges}
                />
            )}
        </>
    );
};

export default ChatCreationModal;
