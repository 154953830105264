import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Connection } from "../types";
import {
    deleteConnection,
    DeleteConnectionParams,
    postConnection,
    PostConnectionParams
} from "../actions";
import { BigBlueButton, BigOutlineButton } from "../components/Buttons";
import HorizontalButtonSpacer from "../components/HorizontalButtonSpacer";
import PaddingAround from "../../components/PaddingAround";
import { colors } from "../../constants";
import { push } from "connected-react-router";
// @ts-ignore
import { withSendBird } from "sendbird-uikit";
import { createConversation } from "src/new-chat/conversation/actions";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

interface Props {
    connection: Connection;
    postConnection: (params: PostConnectionParams) => void;
    deleteConnection: (params: DeleteConnectionParams) => void;
    push: (route: string) => void;
    sendBird: any;
}

const HeaderButtons = ({
    connection,
    postConnection,
    deleteConnection,
    push,
    sendBird
}: Props) => {
    const chatRenewal = useSelector(selectFeatureFlags(featureFlags.Chat2));
    const dispatch = useDispatch();

    const messageCallback = () => {
        if (chatRenewal) {
            dispatch(
                createConversation({
                    users: [
                        {
                            avatarUrl: connection.other.profile.avatar,
                            username: connection.other.profile.username || "",
                            publisherId: connection.other.id.slice(2)
                        }
                    ]
                })
            );
        } else {
            push(`/chat/new/${connection.other.id}`);
        }
    };

    if (connection.state === "friend") {
        return (
            <PaddingAround>
                <BigOutlineButton onClick={messageCallback}>
                    Message
                </BigOutlineButton>
            </PaddingAround>
        );
    } else if (connection.state === "waiting") {
        return (
            <PaddingAround>
                <HorizontalButtonSpacer>
                    <BigOutlineButton disabled>Requested</BigOutlineButton>
                    <BigOutlineButton onClick={messageCallback}>
                        Message
                    </BigOutlineButton>
                </HorizontalButtonSpacer>
            </PaddingAround>
        );
    } else if (connection.state === "requested") {
        return (
            <PaddingAround>
                <HorizontalButtonSpacer>
                    <BigBlueButton
                        onClick={() => {
                            postConnection({
                                selfId: connection.entity.id,
                                otherId: connection.other.id,
                                relationship: "friend"
                            });
                        }}
                    >
                        Accept
                    </BigBlueButton>
                    <BigOutlineButton
                        onClick={() => {
                            deleteConnection({
                                selfId: connection.entity.id,
                                otherId: connection.other.id
                            });
                        }}
                    >
                        Reject
                    </BigOutlineButton>
                </HorizontalButtonSpacer>
            </PaddingAround>
        );
    } else if (connection.state === "blocked") {
        return (
            <PaddingAround>
                <HorizontalButtonSpacer>
                    <BigOutlineButton
                        textColor={colors.primaryRed}
                        onClick={() => {
                            postConnection({
                                selfId: connection.entity.id,
                                otherId: connection.other.id,
                                relationship: "none"
                            });
                            if (!chatRenewal) {
                                sendBird.unblockUserWithUserId(
                                    connection.other.id,
                                    (user: any, error: any) => {
                                        if (error) {
                                            window.alert(error);
                                        }
                                    }
                                );
                            }
                        }}
                    >
                        Unblock
                    </BigOutlineButton>
                </HorizontalButtonSpacer>
            </PaddingAround>
        );
    } else {
        return (
            <PaddingAround>
                <HorizontalButtonSpacer>
                    <BigBlueButton
                        onClick={() => {
                            postConnection({
                                selfId: connection.entity.id,
                                otherId: connection.other.id,
                                relationship: "friend"
                            });
                        }}
                    >
                        Connect
                    </BigBlueButton>
                    <BigOutlineButton onClick={messageCallback}>
                        Message
                    </BigOutlineButton>
                </HorizontalButtonSpacer>
            </PaddingAround>
        );
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    postConnection: (params: PostConnectionParams) =>
        dispatch(postConnection(params)),
    deleteConnection: (params: DeleteConnectionParams) =>
        dispatch(deleteConnection(params)),
    push: (route: string) => dispatch(push(route))
});

export default withSendBird(
    connect(null, mapDispatchToProps)(HeaderButtons),
    (state: any) => ({
        sendBird: state ? state.stores.sdkStore.sdk : undefined
    })
);
