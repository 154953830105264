import React from "react";
import styled from "styled-components";
import { timeStringForSeconds } from "../../../../util";

const Main = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    background: black;
    cursor: pointer;
`;

const CoverPhoto = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const Shade = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
`;

const TimeScreen = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 11px;
    color: white;
    padding-right: 7px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
`;

interface Props {
    imageUrl: string;
    centerImage: string | null;
    time?: number;
    onClick: VoidFunction;
}

const DirectBuyDetailsMediaPreview = ({
    imageUrl,
    centerImage,
    time,
    onClick
}: Props) => {
    return (
        <Main onClick={onClick}>
            <CoverPhoto src={imageUrl} />
            <Shade>{centerImage && <img src={centerImage} />}</Shade>
            {time && <TimeScreen>{timeStringForSeconds(time)}</TimeScreen>}
        </Main>
    );
};

export default DirectBuyDetailsMediaPreview;
