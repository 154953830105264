import React, { useEffect } from "react";
import styled from "styled-components";
import useInitializeChatRoom from "../hooks/useInitializeChatRoom";
import ModalHeading from "src/components/modals/components/ModalHeading";
import Divider from "src/components/Divider";
import ChatRoom from "../ChatRoom";
import PlusCard from "src/profile/components/PlusCard";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "src/components/CommonStyles";
import { useDispatch, useSelector } from "react-redux";
import {
    cancelInternalNotesChannel,
    createInternalNotesChannel,
    createInternalNotesConversation
} from "./actions";
import { selectConversationIdBySubmissionSetId } from "./selectors";
import { GlobalState } from "src/reducers";
import SubmissionApprovalInternalNotesController from "./SubmissionApprovalInternalNotesController";
import { CustomLoading } from "src/components/SkeletonLoader";

const Main = styled.div`
    padding: 18px 0;
    background-color: white;
    flex: 1;
    border-radius: 8px;
    overflow: hidden;
`;

const NoRoomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px;
    height: 100%;
`;

const StyledModalHeading = styled(ModalHeading)`
    padding: 0 18px 12px;
`;

interface Props {
    submissionSetId: number;
}

const SubmissionApprovalInternalNotes = ({ submissionSetId }: Props) => {
    const dispatch = useDispatch();
    const conversationId = useSelector(
        (state: GlobalState) =>
            state.entities.openBuySubmissionSets[submissionSetId].conversationId
    );
    const loading = useSelector(
        (state: GlobalState) => state.ui.chat.internalNotesLoading
    );

    useEffect(() => {
        dispatch(createInternalNotesChannel(submissionSetId));

        return () => {
            dispatch(cancelInternalNotesChannel());
        };
    }, []);

    return (
        <Main>
            <StyledModalHeading text="POD APPROVAL DISCUSSION" />
            <Divider />
            {loading ? (
                <NoRoomWrapper>
                    <CustomLoading height="100%" />
                </NoRoomWrapper>
            ) : conversationId ? (
                <SubmissionApprovalInternalNotesController
                    conversationId={conversationId}
                />
            ) : (
                <NoRoomWrapper>
                    <PlusCard
                        text="No internal notes yet, create a new chat room?"
                        button={{
                            text: "Create",
                            callback: () => {
                                dispatch(
                                    createInternalNotesConversation(
                                        submissionSetId
                                    )
                                );
                            }
                        }}
                    />
                </NoRoomWrapper>
            )}
        </Main>
    );
};
export default SubmissionApprovalInternalNotes;
