import React, { useEffect, useState } from "react";
import { colors, fonts } from "src/constants";
import { generateUniqueNumsArr } from "src/util";
import styled from "styled-components";

const AvatarsTag = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const Avatar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;
    width: 24px;
    border: 1px solid ${colors.lightGray};
    border-radius: 100%;
    overflow: hidden;

    :not(:first-child) {
        margin-left: -8px;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

// const TripleFireTag = styled.div`
//     padding: 3px 6px;
//     gap: 4px;
//     height: 24px;
//     background-color: ${colors.black};
//     border: 1px solid ${colors.black};
//     border-radius: 4px;
//     font-size: ${fonts.smallTitle}px;
//     line-height: 15px;
// `;

const Text = styled.div`
    font-size: ${fonts.smallTitle}px;
    line-height: 15px;
    height: 15px;
`;

interface Props {
    statNumber: number;
}

const CampaignSocialProof = ({ statNumber }: Props) => {
    const [avatarIds, setAvatarIds] = useState<number[]>([]);

    useEffect(() => {
        const randomIds = generateUniqueNumsArr(3, 1, 15);
        setAvatarIds(randomIds);
    }, []);

    return (
        <>
            <AvatarsTag>
                {avatarIds.map((id, idx) => (
                    <Avatar key={idx}>
                        <img src={`/profile_avatars/${id}.png`} alt="avatar" />
                    </Avatar>
                ))}
            </AvatarsTag>
            {/* <TripleFireTag>{`🔥🔥🔥`}</TripleFireTag> */}
            <Text>{`Promoted by ${statNumber} Creators in the past week`}</Text>
        </>
    );
};

export default CampaignSocialProof;
