import React from "react";
import { debounce } from "src/util";

type ShortDay = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";

export function convertToFullDay(shortDay: ShortDay | string): string {
    switch (shortDay.toLowerCase()) {
        case "sun":
            return "Sunday";

        case "mon":
            return "Monday";

        case "tue":
            return "Tuesday";

        case "wed":
            return "Wednesday";

        case "thu":
            return "Thursday";

        case "fri":
            return "Friday";

        default:
            return "Saturday";
    }
}

export function convertToMonthString(day: number): string {
    switch (day) {
        case 0:
            return "January";

        case 1:
            return "February";

        case 2:
            return "March";

        case 3:
            return "April";

        case 4:
            return "May";

        case 5:
            return "June";

        case 6:
            return "July";

        case 7:
            return "August";

        case 8:
            return "September";

        case 9:
            return "October";

        case 10:
            return "November";

        default:
            return "December";
    }
}

export function convertToDayString(day: number): string {
    switch (day) {
        case 0:
            return "Sunday";

        case 1:
            return "Monday";

        case 2:
            return "Tuesday";

        case 3:
            return "Wednesday";

        case 4:
            return "Thursday";

        case 5:
            return "Friday";

        default:
            return "Saturday";
    }
}

// Tue Apr 02 2019 00:00:00 GMT-0400 (Eastern Daylight Time)

export function convertToCleanDate(date: Date): string {
    const weekday = convertToDayString(date.getDay());
    const month = convertToMonthString(date.getMonth());
    const day = date.getDate();
    const year = date.getFullYear();

    return `${weekday} • ${month} ${day}, ${year}`;
}

export function checkObjectEmpty<Obj extends {}>(obj: Obj): boolean {
    return Object.entries(obj).length === 0;
}

export function getRefInfo(ref: React.MutableRefObject<any>): void {
    if (ref && ref.current) {
        console.log(ref.current.getBoundingClientRect());
    }
}

export function capitalizeFirstLetter(word: string): string {
    const capitalizedFirstLetter = word[0].toUpperCase();
    const restOfWord = word.slice(1);
    const capitalizedWord = capitalizedFirstLetter + restOfWord;
    return capitalizedWord;
}

export const debouncedSetUserInput = debounce(
    (
        setUserInput: React.Dispatch<React.SetStateAction<string>>,
        userInput: string
    ): void => {
        setUserInput(userInput);
    }
);

export function removeWhitespaceFromString(str: string): string {
    return str.replace(/\s+/g, "");
}

export function turnPluralToSingular(word: string): string {
    const lowercaseLastLetter = word.slice(-1).toLowerCase();

    if (lowercaseLastLetter === "s") {
        return word.slice(0, word.length - 1);
    } else {
        return word;
    }
}

export function convertToCamelCase(str: string): string {
    const splitString = str.split(" ");
    const firstString = splitString.splice(0, 1)[0].toLowerCase();

    const restOfString = splitString.map((word: string) => {
        const firstCharacter = word[0].toUpperCase();
        const otherCharacters = word.slice(1);
        const formattedWord = firstCharacter + otherCharacters;
        return formattedWord;
    });

    const formattedString = [firstString].concat(restOfString).join("");
    return formattedString;
}

export function generateSomethingXTimes<ThingToGenerateXTimes>(
    timesGenerated: number,
    thingToGenerateXTimes: ThingToGenerateXTimes
): ThingToGenerateXTimes[] {
    return Array.from(new Array(timesGenerated), _ => {
        return thingToGenerateXTimes;
    });
}

export function preventChildBubbling<HTMLElement>(
    event: React.MouseEvent<HTMLElement>
): void {
    event.stopPropagation();
}

export function getTodaysDateInYearMonthDay(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    return `${year}-${month}-${day}`;
}

export function getTimezoneBasedOffUTC(): string {
    const timezoneOffsetValue = new Date().getTimezoneOffset() / -60;
    const formattedTimezoneForUTC = `${timezoneOffsetValue}:00`;
    return formattedTimezoneForUTC;
}

export function capitalizeFirstCharacter(string: string): string {
    return string.slice(0, 1).toUpperCase() + string.slice(1);
}

export function convertCamelCaseToRegularText(camelCaseString: string): string {
    let regularText = "";

    let sliceCutoffIndex = 0;
    for (let index = 0; index < camelCaseString.length; index++) {
        const currentCharacter = camelCaseString[index];
        if (currentCharacter === currentCharacter.toUpperCase()) {
            const word = camelCaseString.slice(sliceCutoffIndex, index);
            regularText += word + " ";
            sliceCutoffIndex = index;
        }
    }

    if (regularText === "") {
        return capitalizeFirstCharacter(camelCaseString);
    } else {
        regularText += camelCaseString.slice(sliceCutoffIndex);
        return capitalizeFirstCharacter(regularText);
    }
}

export function convertUnicodeToString(text: string) {
    return text.replace(/\\u[\dA-F]{4}/gi, function(match) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
    });
}

export function convertSnakeCaseToTitleCase(snakeCaseString: string): string {
    return snakeCaseString
        .split("_")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}
