import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../../../../constants";

const Main = styled.div<{ selected: boolean }>`
    height: 40px;
    padding: 0 20px;
    margin: 0 7px 8px 0;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
    cursor: pointer;
    user-select: none;

    ${props =>
        props.selected &&
        `color: white;
        background-color: ${colors.primaryBlue};
        border-color: ${colors.primaryBlue};`}
`;

interface Props {
    text: string;
    selected: boolean;
    onClick: VoidFunction;
}

const FormTag = ({ text, selected, onClick }: Props) => {
    return (
        <Main selected={selected} onClick={onClick}>
            {text}
        </Main>
    );
};

export default FormTag;
