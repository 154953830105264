const adTagInfo = {
    jetfuel: {
        description: "JetFuel.it | Ad",
        image: "/jetfuel_tag/jetfuel_tag@2x.png",
        style: { height: "18px", width: "63px" }
    },
    ad: {
        description: "Ad",
        image: "/ad_tag/ad_tag@2x.png",
        style: { height: "20px", width: "24px", right: "4px", bottom: "4px" }
    },
    hashtag: {
        description: "#ad",
        image: "/hashtag_tag/hashtag_tag@2x.png",
        style: { height: "21px", width: "24px", right: "4px" }
    },
    none: {
        description: null,
        image: null,
        style: {}
    }
};

export default adTagInfo;
