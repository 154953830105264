import { Action } from "../../types";

export const AUTH_MODAL = {
    START_PROMOTING: "START_PROMOTING"
};

export const startPromotingModal = (): Action => ({
    type: AUTH_MODAL.START_PROMOTING,
    payload: null
});
