import { createSelector } from "reselect";
import { GlobalState } from "../../reducers";

export const getCampaignCustomLinks = createSelector(
    (state: GlobalState) => state.ui.campaign.currentCampaignId,
    (state: GlobalState) => state.entities.campaigns.byId,
    (state: GlobalState) => state.entities.customLinks,
    (currentCampaign, campaigns, customLinks) => {
        if (currentCampaign) {
            const campaignCustomLinks = campaigns[currentCampaign].customLinks;
            if (campaignCustomLinks) {
                return campaignCustomLinks.map(id => customLinks[id]);
            }
        }
    }
);
