import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../constants";

const Main = styled.div`
    font-size: ${fonts.subtitle}px;
    text-align: right;
    color: ${colors.primaryBlue};
    cursor: pointer;
    user-select: none;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            color: ${colors.primaryBlueDark};
        }
    }
`;

interface Props {
    link: {
        text: string;
        onClick?: VoidFunction;
    };
}

const Link: React.FC<Props> = ({ link }) => (
    <Main onClick={link.onClick}>{link.text}</Main>
);

export default Link;
