import { Action } from "../types";
import { CampaignMedia } from "src/campaigns/types";

import {
    MODAL,
    SET_CURRENT_CAMPAIGN_MEDIA,
    FETCH_MEDIA,
    SELECT_SEARCH_MEDIA
} from "./actions";
import { CLEAR_MODAL } from "../modals/actions";

export enum MediaModal {
    clear,
    rules
}

interface ByIdMedias {
    [key: string]: CampaignMedia;
}

interface Medias {
    byId: ByIdMedias;
    allIds: number[];
}

export interface MediaState {
    modal: MediaModal;
    currentCampaignMedia: CampaignMedia;
    medias: Medias;
    selectedMedias: number[];
}

const initialMediaState: MediaState = {
    modal: MediaModal.clear,
    currentCampaignMedia: {} as CampaignMedia,
    medias: {} as Medias,
    selectedMedias: [] as number[]
};

const reducer = (state = initialMediaState, action: Action): MediaState => {
    switch (action.type) {
        case CLEAR_MODAL:
            return {
                ...state,
                modal: MediaModal.clear
            };

        case MODAL.RULES:
            return {
                ...state,
                modal: MediaModal.rules
            };

        case SET_CURRENT_CAMPAIGN_MEDIA:
            return { ...state, currentCampaignMedia: action.payload };

        case FETCH_MEDIA.SUCCESS:
            return {
                ...state,
                medias: {
                    byId: action.payload.entities.byId,
                    allIds: action.payload.result
                }
            };
        case SELECT_SEARCH_MEDIA.SELECT:
            return {
                ...state,
                selectedMedias: [...state.selectedMedias, action.payload]
            };
        case SELECT_SEARCH_MEDIA.REMOVE:
            return {
                ...state,
                selectedMedias: state.selectedMedias.filter(
                    media => media !== action.payload
                )
            };
        case SELECT_SEARCH_MEDIA.CLEAR:
            return {
                ...state,
                selectedMedias: action.payload
            };

        default:
            return state;
    }
};

export default reducer;
