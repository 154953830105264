import React from "react";
import styled from "styled-components";
import GenericContainer from "../../../components/GenericContainer";
import { BigOutlineButton } from "../../../components/Buttons";
import VerticallyCentered from "../../../../components/VerticallyCentered";
import Divider from "../../../../components/Divider";
import { AccountInput, AccountUsernameInput } from "../../../components/Inputs";
import { formatNumber } from "../../../../util";
import Platforms from "../../../../social-accounts/Platforms";
import PaddingAround from "../../../../components/PaddingAround";
import { SocialAccount } from "../../../types";
import VerticalButtonSpacer from "../../../components/VerticalButtonSpacer";
import VisibilityRow from "../../components/VisibilityRow";
import { privacyAccountModal, verifyModal } from "../../../actions";
import { connect } from "react-redux";
import { clearModal } from "../../../../modals/actions";

const InputsContainer = styled(GenericContainer)`
    padding: 0 12px;
`;

const InputRow = styled(VerticallyCentered)`
    padding: 12px 0;
`;

const ButtonRow = styled(VerticallyCentered)`
    padding: 16px 3px 16px 0;
    cursor: pointer;
`;

const IconContainer = styled.div`
    height: 32px;
    width: 44px;
    min-width: 44px;
    text-align: left;
`;

const Icon = styled.img`
    height: 100%;
    border-radius: 8px;
`;

interface Props {
    platform: string;
    username: string;
    setUsername: (username: string) => void;
    followers: string;
    setFollowers: (followers: string) => void;
    visibility: SocialAccount["visibility"];
    currentAccount?: SocialAccount;
    privacyAccountModal: VoidFunction;
    verifyModal: VoidFunction;
}

const FormHeader = ({
    platform,
    username,
    setUsername,
    followers,
    setFollowers,
    visibility,
    currentAccount,
    privacyAccountModal,
    verifyModal
}: Props) => {
    return (
        <PaddingAround>
            <VerticalButtonSpacer>
                <InputsContainer>
                    <InputRow>
                        <IconContainer>
                            <Icon
                                src={
                                    Platforms[platform]
                                        ? Platforms[platform].imgSrc
                                        : "/gray_logo.svg"
                                }
                            />
                        </IconContainer>
                        <AccountUsernameInput
                            placeholder="Profile Name"
                            value={username}
                            onChange={e => {
                                setUsername(e.currentTarget.value);
                            }}
                        />
                    </InputRow>
                    <Divider />
                    <InputRow>
                        <IconContainer />
                        <AccountInput
                            placeholder="Number of Followers"
                            value={formatNumber(followers)}
                            onChange={e => {
                                const newStr = e.currentTarget.value.replace(
                                    /,/g,
                                    ""
                                );

                                if (newStr === "" || /^\d+$/.test(newStr)) {
                                    setFollowers(newStr);
                                }
                            }}
                        />
                    </InputRow>
                    <Divider />
                    <ButtonRow onClick={privacyAccountModal}>
                        <VisibilityRow visibility={visibility} />
                    </ButtonRow>
                </InputsContainer>
                {!currentAccount ? null : currentAccount.verified ? (
                    <BigOutlineButton>
                        <VerticallyCentered>
                            <img
                                style={{ marginRight: "6px" }}
                                src="/verified_account.svg"
                            />
                            Account Verified
                        </VerticallyCentered>
                    </BigOutlineButton>
                ) : (
                    <BigOutlineButton onClick={verifyModal}>
                        Verify Account Ownership
                    </BigOutlineButton>
                )}
            </VerticalButtonSpacer>
        </PaddingAround>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    privacyAccountModal: () => dispatch(privacyAccountModal()),
    verifyModal: () => dispatch(verifyModal())
});

export default connect(null, mapDispatchToProps)(FormHeader);
