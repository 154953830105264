import React from "react";
import styled from "styled-components";
import VerticallyCentered from "src/components/VerticallyCentered";
import CampaignReward from "src/campaigns/details/components/CampaignReward";
import CampaignAccess from "src/campaigns/details/CampaignAccess";
import Tag from "src/components/Tag";
import { BigBlueButton } from "src/profile/components/Buttons";
import { Campaign } from "src/campaigns/types";
import { maxWidthDesktop } from "src/constants";
import {
    CampaignDetailHeaderAccessIcon,
    CampaignDetailHeaderAccessText,
    CampaignDetailHeaderIcon,
    CampaignDetailHeaderInfoOne,
    CampaignDetailHeaderInfoThree,
    CampaignDetailHeaderInfoTwo
} from "src/components/SkeletonLoader";
import { AccessText } from "../Details.styled";
import { flex } from "src/utils/styles/snippets";

const Main = styled("div")`
    ${flex("column")};
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    margin: 0px auto;
    padding: 12px 18px 10px;

    & > * ~ * {
        margin-top: 12px;
    }
`;

const Info = styled("div")`
    ${flex("column", "auto", "flex-start")};
    flex: 1;
    overflow: hidden;

    & > * ~ * {
        margin-top: 5px;
    }
`;

const LargeLogo = styled("img")`
    height: 90px;
    width: 90px;
    border-radius: 12px;
    margin-right: 18px;
`;

const Title = styled("div")`
    max-width: 100%;
    height: 27px;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const HeaderButton = styled(BigBlueButton)<{ disabled?: boolean }>`
    ${props => props.disabled && `opacity: 0.5`}
    user-select: none;
`;

const MarginRight = styled("div")`
    margin-right: 18px;
`;

const Tags = styled("div")`
    display: flex;
`;

const LoadingAnimation = () => {
    return (
        <Main>
            <VerticallyCentered>
                <MarginRight>
                    <CampaignDetailHeaderIcon />
                </MarginRight>
                <Info>
                    <CampaignDetailHeaderInfoOne />
                    <CampaignDetailHeaderInfoTwo />
                    <CampaignDetailHeaderInfoThree />
                </Info>
            </VerticallyCentered>

            <VerticallyCentered>
                <CampaignDetailHeaderAccessIcon />
                <AccessText>
                    <CampaignDetailHeaderAccessText />
                </AccessText>
            </VerticallyCentered>

            <HeaderButton disabled>...</HeaderButton>
        </Main>
    );
};

interface Props {
    currentCampaign?: Campaign;
    scrollToBottom: () => void;
}

const CampaignHeader = (props: Props) => {
    const { currentCampaign, scrollToBottom } = props;
    const price = currentCampaign?.countryPlatformReward || undefined;

    if (!currentCampaign || !currentCampaign.countryPlatformReward) {
        return (
            <>
                <LoadingAnimation />
            </>
        );
    }

    return (
        <Main>
            <VerticallyCentered>
                <LargeLogo
                    src={currentCampaign.imageUrl}
                    alt={currentCampaign.campaignName}
                />
                <Info>
                    <Title>{currentCampaign.campaignName}</Title>
                    <CampaignReward
                        countryPlatformReward={currentCampaign.countryPlatformReward}
                        rewardType={currentCampaign.rewardType}
                    />
                    <Tag uppercase text={currentCampaign.state} />
                </Info>
            </VerticallyCentered>
            <CampaignAccess
                campaignName={currentCampaign.campaignName}
                access={currentCampaign.access}
                campaignState={currentCampaign.state}
                campaign={currentCampaign}
                scrollToBottom={scrollToBottom}
            />
        </Main>
    );
};

export default CampaignHeader;
