import { CLEAR_MODAL } from "../../modals/actions";
import {
    FETCH_HIGHLIGHTS,
    FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE
} from "../../campaigns/highlights/actions";
import { FETCH_PLACEMENTS } from "../../campaigns/placements/actions";
import { Action } from "../../types";
import { CAMPAIGN_MODAL } from "../campaigns/actions";
import { HIGHLIGHT_MODAL, SET_CURRENT_MODAL_HIGHLIGHTS } from "./actions";
import { MediaPerformance } from "src/campaigns/types";
import { SET_PLACEMENT_CONFIG } from "../placements/actions";

export interface PlacementConfig {
    [platform: string]: boolean;
}

export interface HighlightsUIState {
    downloadTypesModal: number | null;
    currentModalHighlights: number[];
    placementsModal: boolean;
    mediaPerformance: MediaPerformance[];
    mediaPerformanceLoading: boolean;
    highlightsLoading: boolean;
    placementsLoading: boolean;
    placementConfig: PlacementConfig;
}

const initialPlacementConfig = localStorage.getItem("placementConfig");

const initialHighlightsUIState: HighlightsUIState = {
    downloadTypesModal: null,
    currentModalHighlights: [],
    placementsModal: false,
    mediaPerformance: [],
    mediaPerformanceLoading: true,
    highlightsLoading: true,
    placementsLoading: true,
    placementConfig: initialPlacementConfig
        ? JSON.parse(initialPlacementConfig)
        : {}
};

const highlightsUIReducer = (
    state = initialHighlightsUIState,
    action: Action
) => {
    switch (action.type) {
        case CAMPAIGN_MODAL.DOWNLOAD_TYPES:
            return { ...state, downloadTypesModal: action.payload.highlightId };
        case HIGHLIGHT_MODAL.PLACEMENTS:
            return { ...state, placementsModal: true };
        case CLEAR_MODAL:
            return {
                ...state,
                downloadTypesModal: null,
                placementsModal: false
            };
        case FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE.REQUEST:
            return {
                ...state,
                mediaPerformanceLoading: true,
                mediaPerformance: []
            };
        case FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE.SUCCESS:
            return {
                ...state,
                mediaPerformanceLoading: false,
                ...action.payload
            };
        case FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE.FAILURE:
            return {
                ...state,
                mediaPerformanceLoading: true,
                ...action.payload
            };
        case FETCH_HIGHLIGHTS.REQUEST:
            return { ...state, highlightsLoading: true };
        case FETCH_HIGHLIGHTS.SUCCESS:
            return { ...state, highlightsLoading: false };
        case FETCH_PLACEMENTS.SUCCESS:
            return { ...state, placementsLoading: false };
        case SET_PLACEMENT_CONFIG:
            localStorage.setItem(
                "placementConfig",
                JSON.stringify(action.payload.placementConfig)
            );
            return {
                ...state,
                placementConfig: action.payload.placementConfig
            };
        case SET_CURRENT_MODAL_HIGHLIGHTS:
            return {
                ...state,
                currentModalHighlights: action.payload.highlightIds
            };
        default:
            return state;
    }
};

export default highlightsUIReducer;
