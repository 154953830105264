import { OpenBuy } from "src/buys/modules/open-buys/types";
import { Action } from "src/types";

export const FETCH_OPEN_BUY_TO_EDIT = "FETCH_OPEN_BUY_TO_EDIT";
export const fetchOpenBuyToEdit = (openBuyId: number): Action => ({
    type: FETCH_OPEN_BUY_TO_EDIT,
    payload: openBuyId
});

export const ATTACH_INSTRUCTION_SET_TO_OPEN_BUY =
    "ATTACH_INSTRUCTION_SET_TO_OPEN_BUY";
export const attachInstructionSetToOpenBuy = (
    openBuyId: number,
    instructionSetId: number
): Action => ({
    type: ATTACH_INSTRUCTION_SET_TO_OPEN_BUY,
    payload: { openBuyId, instructionSetId }
});
