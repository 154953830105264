import React, { ChangeEvent, useState } from "react";
import { Channel } from "../../../types";
import ImageModal from "../../../../modals/ImageModal";
import { BlueModalButton } from "../../../../profile/components/Buttons";
import { clearModal } from "../../../../modals/actions";
import { connect } from "react-redux";
import ModalNameHeader from "../../../components/ModalNameHeader";
import ModalNameInput from "../../../components/ModalNameInput";

interface Props {
    channel: Channel;
    setChannel: (channel: Channel) => void;
    sdk: any;
    clearModal: VoidFunction;
}

const EditChannelModal = ({ channel, setChannel, sdk, clearModal }: Props) => {
    const [newCover, setNewCover] = useState(channel.sendBirdData.coverUrl);
    const [coverFile, setCoverFile] = useState<File | null>(null);

    const [groupName, setGroupName] = useState(channel.sendBirdData.name);

    function imageUpload(e: ChangeEvent<HTMLInputElement>) {
        if (e.currentTarget.files) {
            const file = e.currentTarget.files[0];

            setNewCover(URL.createObjectURL(file));

            setCoverFile(file);
        }
    }

    function saveChanges() {
        if (coverFile || groupName !== channel.sendBirdData.name) {
            if (sdk.GroupChannel && sdk.GroupChannelParams) {
                const params = new sdk.GroupChannelParams();

                if (coverFile) {
                    params.coverImage = coverFile;
                }

                if (groupName !== channel.sendBirdData.name) {
                    params.name = groupName;
                }

                channel.sendBirdData.updateChannel(
                    params,
                    (newChannel: any, error: any) => {
                        if (!error) {
                            setChannel({
                                ...channel,
                                sendBirdData: newChannel
                            });
                        } else {
                            window.alert(error);
                        }
                    }
                );
            }
        }

        clearModal();
    }

    const [placeholder, setPlaceholder] = useState("Group Name");

    return (
        <ImageModal
            imageUrl={
                newCover ||
                channel.sendBirdData.coverUrl ||
                "/profile_pic_blue/profile_pic_blue@2x.png"
            }
            imageUploadCallback={imageUpload}
        >
            <ModalNameHeader>Edit this group's name:</ModalNameHeader>
            <ModalNameInput
                placeholder={placeholder}
                value={groupName}
                onChange={e => {
                    setGroupName(e.currentTarget.value);
                }}
                onFocus={() => {
                    setPlaceholder("");
                }}
                onBlur={() => {
                    setPlaceholder("Group Name");
                }}
            />
            <BlueModalButton onClick={saveChanges}>
                Save and Close
            </BlueModalButton>
        </ImageModal>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(null, mapDispatchToProps)(EditChannelModal);
