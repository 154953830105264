import { FETCH_SCRIPT_ASSETS } from "../../campaigns/assets/actions";
import { FETCH_SCRIPT_MEDIAS } from "../../campaigns/medias/actions";
import { FETCH_SCRIPT, SCRIPT_CREATE } from "../../campaigns/scripts/actions";
import { Action } from "../../types";
import { CAMPAIGN_MODAL } from "../campaigns/actions";
import { ModalView } from "./actions";
export interface ScriptsUIState {
    scriptModal: null | number;
    assetsLoading: boolean;
    mediasLoading: boolean;
    scriptView: string;
    warning: boolean;
    create: boolean;
}

const initialScriptsUIState: ScriptsUIState = {
    scriptModal: null,
    assetsLoading: true,
    mediasLoading: true,
    scriptView: ModalView.loading,
    warning: false,
    create: false
};

const scriptsUIReducer = (state = initialScriptsUIState, action: Action) => {
    switch (action.type) {
        case FETCH_SCRIPT.REQUEST:
            return {
                ...state,
                ...initialScriptsUIState,
                scriptModal: action.payload.scriptId
            };
        case FETCH_SCRIPT_ASSETS.REQUEST:
            return { ...state, assetsLoading: true };
        case FETCH_SCRIPT_ASSETS.SUCCESS:
            return { ...state, assetsLoading: false };
        case FETCH_SCRIPT_MEDIAS.REQUEST:
            return { ...state, mediasLoading: true };
        case FETCH_SCRIPT_MEDIAS.SUCCESS:
            return { ...state, mediasLoading: false };
        case CAMPAIGN_MODAL.SCRIPT:
            return {
                ...state,
                scriptModal: action.payload.scriptId
            };
        case CAMPAIGN_MODAL.SCRIPT_VIEW:
            return { ...state, scriptView: action.payload };
        case CAMPAIGN_MODAL.SCRIPT_WARNING:
            return { ...state, warning: action.payload };
        case CAMPAIGN_MODAL.SCRIPT_CREATE:
            return { ...state, create: action.payload };
        case SCRIPT_CREATE.SUCCESS:
            return { ...state, create: false };
        default:
            return state;
    }
};

export default scriptsUIReducer;
