import React from "react";
import { Campaign, Script } from "src/campaigns/types";
import styled from "styled-components";
import ModalHeader from "./ModalHeader";
import { editScript } from "../actions";
import ScriptTextEditor from "./ScriptTextEditor";
const Wrapper = styled.div`
    height: 80vh;
    display: grid;
    grid-template-rows: auto 1fr;
`;
const StyledModalHeader = styled(ModalHeader)`
    padding-bottom: 6px;
`;
interface Props {
    script: Script;
    campaign: Campaign;
}

const ScriptEdit = ({ script, campaign }: Props) => {
    return (
        <Wrapper>
            <StyledModalHeader title={"Edit Script"} campaign={campaign} />
            <ScriptTextEditor
                script={script}
                submitActionCreator={editScript}
                submitBtnText="Save Changes"
            />
        </Wrapper>
    );
};

export default ScriptEdit;
