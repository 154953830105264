import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { colors, fonts } from "src/constants";
import { convertPathsToCleanTexts } from "src/marketplace/instructions-sets/utils/instructions-sets";
import { resetInstructionsSetWhenNavigatingToMainPage } from "src/ui/instructions-sets/actions";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Main = styled.div``;

interface BreadcrumbProps {
    $matchesCurrentPath: boolean;
}

const Breadcrumb = styled(Link)<BreadcrumbProps>`
    color: ${props => {
        return props.$matchesCurrentPath
            ? colors.primaryGray
            : colors.primaryBlue;
    }};
    font-size: ${fonts.smallTitle}px;
    text-transform: capitalize;
    pointer-events: ${props => props.$matchesCurrentPath && "none"};
    transition: 0.1s ease-in;

    :hover {
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            color: ${props => {
                return props.$matchesCurrentPath
                    ? colors.primaryGray
                    : colors.primaryBlueDark;
            }};
        }
    }
`;

interface Props {
    hideCrumbs?: boolean;
    endCrumb?: string;
    customCrumbs?: string[];
}

const BreadCrumbHeader = ({ hideCrumbs, endCrumb, customCrumbs }: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const paths = location.pathname.split("/").filter((path: string) => path);
    const dirtyBreadcrumbs = paths.slice(1);
    const breadcrumbs = convertPathsToCleanTexts(dirtyBreadcrumbs);

    const breadcrumbElements = breadcrumbs.map(
        (breadcrumb: string, index: number, arr: string[]) => {
            let linkEnd = "";
            for (let i = 0; i <= index; i++) {
                linkEnd += `/${dirtyBreadcrumbs[i]}`;
            }

            const breadcrumbLink = "/" + paths[0] + linkEnd;

            const matchesCurrentPath = index === breadcrumbs.length - 1;

            if (index === 0) {
                return (
                    <Breadcrumb
                        key={`${breadcrumb}-${index}`}
                        to={breadcrumbLink}
                        $matchesCurrentPath={matchesCurrentPath}
                        onClick={() => {
                            dispatch(
                                resetInstructionsSetWhenNavigatingToMainPage()
                            );
                        }}
                    >
                        {customCrumbs && customCrumbs[index]
                            ? customCrumbs[index]
                            : breadcrumb}
                    </Breadcrumb>
                );
            } else if (endCrumb && index === arr.length - 1) {
                return (
                    <Breadcrumb
                        key={`${breadcrumb}-${index}`}
                        to={breadcrumbLink}
                        $matchesCurrentPath={matchesCurrentPath}
                    >
                        {" "}
                        / {endCrumb}
                    </Breadcrumb>
                );
            } else {
                return (
                    <Breadcrumb
                        key={`${breadcrumb}-${index}`}
                        to={breadcrumbLink}
                        $matchesCurrentPath={matchesCurrentPath}
                    >
                        {" "}
                        /{" "}
                        {customCrumbs && customCrumbs[index]
                            ? customCrumbs[index]
                            : breadcrumb}
                    </Breadcrumb>
                );
            }
        }
    );

    return <Main> {hideCrumbs ? null : breadcrumbElements}</Main>;
};

export default BreadCrumbHeader;
