import { SubmissionReport } from "src/marketplace/reports/types";
import { Action, NormalizedAction, NormalizedResponse } from "src/types";

export const FETCH_OPEN_BUY_LEADERBOARD = {
    SUCCESS: "FETCH_OPEN_BUY_LEADERBOARD_SUCCESS",
    REQUEST: "FETCH_OPEN_BUY_LEADERBOARD_REQUEST",
    FAILURE: "FETCH_OPEN_BUY_LEADERBOARD_FAILURE"
};

export const fetchOpenBuyLeaderboard = (openBuyId: number): Action => ({
    type: FETCH_OPEN_BUY_LEADERBOARD.REQUEST,
    payload: { openBuyId }
});

export const fetchOpenBuyLeaderboardSuccess = (
    openBuyId: number,
    response: NormalizedResponse<SubmissionReport, number[]>
): NormalizedAction => ({
    type: FETCH_OPEN_BUY_LEADERBOARD.SUCCESS,
    payload: { openBuyId },
    response
});
