import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { flex, hideScrollbar } from "src/utils/styles/snippets";
import MediaCarousel from "src/campaigns/landing/components/MediaCarousel";
import MediaTab from "src/campaigns/landing/components/MediaTab";
import { Campaign } from "src/campaigns/types";
import { breakpoints, colors } from "src/constants";
import UploadFlowModal from "src/campaigns/promote/PromoteFlowModal";
import PlacementSelector from "src/campaigns/placements/components/PlacementSelector";
import { fetchCampaignMedias } from "src/campaigns/medias/actions";
import { getPlacementIdsFromConfig } from "src/campaigns/placements/selectors";
import { GlobalState } from "src/reducers";
import { AnimatePresence } from "framer-motion";
import PlacementsModal from "src/campaigns/placements/modals/PlacementsModal";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const Main = styled.div`
    ${flex("column")};
`;

interface MediaTabsProps {
    customMediaOk: boolean;
}

const MediaTabs = styled("div")<MediaTabsProps>`
    display: grid;
    grid-template-columns: ${props => {
        return props.customMediaOk ? "repeat(3, 1fr)" : "repeat(2, 1fr)";
    }};
    grid-gap: 0px;
    border-bottom: ${colors.mediumTeal} solid 1px;
`;

const MediaTabYours = styled("div")<MediaTabsProps>`
    display: ${props => (props.customMediaOk ? "block" : "none")};
`;

const Placements = styled("div")`
    padding-left: 10px;
`;

const MediaCarousels = styled("div")`
    border-top: 1px solid ${colors.mediumTeal};
    padding-top: 10px;
    border-radius: 0px 0px 10px 10px;

    @media (max-width: 958px) {
        ${hideScrollbar()};
    }
`;

const PromoteButton = styled("div")`
    padding: 0px 20px 20px;

    @media (max-width: ${breakpoints.mobile}px) {
        padding-bottom: 10px;
    }
`;

interface Props {
    campaign: Campaign;
}

const CampaignMediaTabs = ({ campaign }: Props) => {
    const [open, setOpen] = useState(false);

    const promote = useSelector(selectFeatureFlags(featureFlags.PromoteFlow));

    const dispatch = useDispatch();

    const selectedPlacementIds = useSelector((state: GlobalState) =>
        getPlacementIdsFromConfig(state, "global")
    );

    useEffect(() => {
        dispatch(
            fetchCampaignMedias(campaign.id, undefined, selectedPlacementIds)
        );
    }, [selectedPlacementIds]);

    if (promote) {
        return (
            <Main>
                <MediaCarousels>
                    <Placements>
                        <PlacementSelector
                            openPlacementsModal={() => setOpen(true)}
                        />
                    </Placements>
                    <MediaCarousel
                        key="portrait-campaign-media-carousel"
                        tabType="Stories"
                        campaign={campaign}
                    />
                </MediaCarousels>
                <PromoteButton>
                    <UploadFlowModal campaign={campaign} />
                </PromoteButton>
                <AnimatePresence>
                    {open && (
                        <PlacementsModal
                            localClearModal={() => setOpen(false)}
                        />
                    )}
                </AnimatePresence>
            </Main>
        );
    }

    return (
        <>
            <MediaTabs customMediaOk={campaign.customMediaOk}>
                <MediaTab tab="Stories" />
                <MediaTab tab="Square" />

                <MediaTabYours customMediaOk={campaign.customMediaOk}>
                    <MediaTab tab="Yours" />
                </MediaTabYours>
            </MediaTabs>

            <MediaCarousels>
                <MediaCarousel
                    key="portrait-campaign-media-carousel"
                    tabType="Stories"
                    campaign={campaign}
                />
            </MediaCarousels>
        </>
    );
};

export default React.memo(CampaignMediaTabs);
