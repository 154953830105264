import { normalize } from "normalizr";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { get, post } from "src/Api";
import { Action } from "src/types";
import { setPillNotificationText } from "src/ui/notifications/actions";
import {
    fetchOpenBuyExtensions,
    fetchOpenBuyExtensionsSuccess,
    FETCH_OPEN_BUY_EXTENSIONS,
    UPSERT_EXTENSIONS,
    setExtensionsLoading,
    setExtensionsError
} from "./actions";
import { openBuyExtensionList } from "../../schema";

function* fetchOpenBuyExtensionsRequest({ payload }: Action) {
    try {
        yield put(setExtensionsLoading(true));
        const { data } = yield call(get, `/v1/openBuy/publisher/${payload}`, {
            isExtensions: true
        });
        yield put(
            fetchOpenBuyExtensionsSuccess(
                payload,
                normalize(data.data, openBuyExtensionList)
            )
        );
        yield put(setExtensionsLoading(false));
    } catch (error) {
        yield put(setExtensionsLoading(false));
        yield put(setExtensionsError(true));
    }
}
function* upsertExtensionsRequest({ payload }: Action) {
    try {
        yield put(setExtensionsLoading(true));
        yield call(post, `/v1/openBuy/publisher`, {
            openBuyPublisherLinks: payload.pubLinks
        });

        yield put(fetchOpenBuyExtensions(payload.openBuyId));
        yield put(
            setPillNotificationText(payload.message.success, "success", 3500)
        );
    } catch (error) {
        yield put(setExtensionsLoading(false));
        yield put(
            setPillNotificationText(payload.message.failure, "danger", 3500)
        );
    }
}

export default function* openBuyExtensionsSaga() {
    yield all([
        takeEvery(
            FETCH_OPEN_BUY_EXTENSIONS.REQUEST,
            fetchOpenBuyExtensionsRequest
        ),
        takeEvery(UPSERT_EXTENSIONS.REQUEST, upsertExtensionsRequest)
    ]);
}
