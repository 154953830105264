import React, { useState } from "react";
import { EventInput } from "src/utils/types/form";
import styled from "styled-components";
import { trimSingleQuotes } from "tslint/lib/utils";
import { colors } from "../constants";
import { debounce } from "src/util";

const Main = styled.div<{ wrapped?: true }>`
    padding-bottom: 10px;
    ${props =>
        props.wrapped &&
        `padding: 15px;
         border-bottom: 1px solid ${colors.mediumTeal};`}
`;

const InputContainer = styled.div<{ focus: boolean }>`
    background-color: white;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    display: flex;
    ${props => {
        return (
            props.focus &&
            `box-shadow: 0px 0px 4px 0px ${colors.primaryBlue}; transition: box-shadow 0.2s;`
        );
    }}
`;

const SearchInput = styled.input`
    width: calc(100% - 80px);
    padding: 0;
    border: none;
    outline: none;
    font-weight: 300;

    &::placeholder {
        color: ${colors.primaryGray};
        font-weight: 300;
    }
`;

const IconContainer = styled.div`
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SearchIcon = styled.img`
    height: 18px;
    width: 18px;
    opacity: 0.5;
`;

const ClearIcon = styled.img`
    height: 16px;
    width: 16px;
    cursor: pointer;
`;

interface Props {
    searchString: string;
    updateSearchString: (searchString: string) => void;
    wrapped?: true;
    className?: string;
    placeholder?: string;
    autoFocus?: boolean;
}

const SearchBar = ({
    searchString,
    updateSearchString,
    wrapped,
    className,
    placeholder = "Search",
    autoFocus = true
}: Props) => {
    const [focus, setFocus] = useState(false);

    return (
        <Main wrapped={wrapped} className={className}>
            <InputContainer focus={focus}>
                <IconContainer>
                    <SearchIcon src={"/search/search@2x.png"} />
                </IconContainer>
                <SearchInput
                    autoFocus={autoFocus}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    placeholder={placeholder}
                    value={searchString}
                    onChange={e => updateSearchString(e.currentTarget.value)}
                />
                {searchString.length !== 0 && (
                    <IconContainer>
                        <ClearIcon
                            src={"/clear/clear@2x.png"}
                            onClick={() => updateSearchString("")}
                        />
                    </IconContainer>
                )}
            </InputContainer>
        </Main>
    );
};

export default SearchBar;
