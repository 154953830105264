import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import { BigBlueButton } from "../../../profile/components/Buttons";
import { GlobalState } from "../../../reducers";
import { requestCampaignAccess } from "../../actions";

const HeaderButton = styled(BigBlueButton)<{ disabled?: boolean }>`
    user-select: none;
    ${props => props.disabled && `pointer-events: none; opacity: 0.5;`}
`;

const LockIcon = styled.img`
    margin-right: 8px;
`;

interface Props {
    currentCampaign: number | null;
    requested?: true;
    accessLoading: boolean;
    requestCampaignAccess: (campaignId: number) => void;
}

const RequestButton = ({
    currentCampaign,
    requested,
    accessLoading,
    requestCampaignAccess
}: Props) => {
    if (accessLoading)
        return (
            <HeaderButton disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            </HeaderButton>
        );
    return (
        <HeaderButton
            disabled={requested}
            onClick={() => {
                currentCampaign && requestCampaignAccess(currentCampaign);
            }}
        >
            <LockIcon src="/lock.svg" />
            Request Access
        </HeaderButton>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    currentCampaign: state.ui.campaign.currentCampaignId,
    accessLoading: state.ui.campaign.accessLoading
});

const mapDispatchToProps = (dispatch: any) => ({
    requestCampaignAccess: (campaignId: number) =>
        dispatch(requestCampaignAccess(campaignId))
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestButton);
