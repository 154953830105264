import React, { useState } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import SimpleNavbar from "src/components/SimpleNavbar";
import { AnimatePresence } from "framer-motion";
import FullScreenModal from "src/modals/FullScreenModal";
import { useDispatch, useSelector } from "react-redux";
import MediaItem from "../landing/components/MediaItem";
import { addNotification } from "src/ui/notifications/actions";
import { BigOutlineButton } from "src/profile/components/Buttons";
import { Media } from "src/media/types";
import CopyToClipboard from "react-copy-to-clipboard";
import { fetchLinkForPlug } from "src/links/actions";
import { GlobalState } from "src/reducers";

const Close = styled.img`
    padding: 15px;
    cursor: pointer;

    svg {
        margin-right: 15px;
    }
`;

const Main = styled.div``;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    margin: 18px;
    text-align: left;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-around;
    height: 82px;
    justify-content: center;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    white-space: nowrap;
    padding-bottom: 5px;
    padding-left: 18px;
`;

const SubTitle = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 400;
    text-align: left;
    color: ${colors.primaryGray};
    width: 100%;
    padding-left: 18px;
`;

const DownloadButton = styled("a").attrs(() => ({
    target: "_blank"
}))`
    background-color: white;
    border: 1px solid ${colors.mediumTeal};
    color: ${colors.primaryBlue};
    border-radius: 5px;
    font-size: ${fonts.subtitle}px;
    line-height: 14px;
    height: 48px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;

    &:disabled {
        color: ${colors.primaryGray};
        cursor: default;
    }

    &:hover {
        text-decoration: none;
        color: ${colors.primaryBlue};
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
            border: ${colors.darkTeal} solid 1px;
        }
    }
`;

const Checkbox = styled.img`
    margin: auto 20px auto auto;
`;

const Label = styled.div`
    display: flex;
    flex-direction: row;
    font-size: ${fonts.smallTitle}px;
    margin: 12px auto 12px 0;
`;

const MediaContainer = styled.div`
    width: 120px;
    margin: auto;
`;

interface Props {
    media: Media | null | undefined;
    onClose: () => void;
}

const PostMediaModal = ({ media, onClose }: Props) => {
    const dispatch = useDispatch();

    const [downloaded, setDownloaded] = useState(false);
    const [copied, setCopied] = useState(false);
    const copyLink = useSelector(
        (state: GlobalState) =>
            state.links.linksForPlugs[`${media?.campaignId}-${media?.id}`]
    );

    if (!media) return null;
    else {
        const appDownloadLink =
            copyLink?.domain +
            `${copyLink?.campaign_id}/${copyLink?.publisher_id}/${copyLink?.media_id}`;

        return (
            <Main>
                <SimpleNavbar
                    title={"Upload Media"}
                    left={<Close onClick={onClose} src={"/close.svg"} />}
                />
                <Header>
                    <Title>Post Media</Title>
                    <SubTitle>Follow the instructions below</SubTitle>
                </Header>
                <MediaContainer>
                    <MediaItem
                        campaignMedia={media}
                        onDownload={() => setDownloaded(true)}
                        onCopy={() => setCopied(true)}
                        simpleView={true}
                    />
                </MediaContainer>
                <ButtonContainer>
                    <Label>
                        <Checkbox
                            src={
                                downloaded
                                    ? "/circle_check/blue_circle_checked.svg"
                                    : "/circle_check/blue_circle_unchecked.svg"
                            }
                        />
                        Download and post it on your account:
                    </Label>
                    <DownloadButton
                        href={media.url || ""}
                        onClick={() => setDownloaded(true)}
                    >
                        Download Media
                    </DownloadButton>
                </ButtonContainer>
                <ButtonContainer>
                    <Label>
                        <Checkbox
                            src={
                                copied
                                    ? "/circle_check/blue_circle_checked.svg"
                                    : "/circle_check/blue_circle_unchecked.svg"
                            }
                        />
                        Attach this link to your post
                    </Label>
                    <CopyToClipboard
                        text={appDownloadLink}
                        onCopy={() => {
                            dispatch(
                                addNotification(
                                    `Copied Link!: ${appDownloadLink}`
                                )
                            );
                            setCopied(true);
                        }}
                    >
                        <BigOutlineButton>Copy Link</BigOutlineButton>
                    </CopyToClipboard>
                </ButtonContainer>
            </Main>
        );
    }
};

export default PostMediaModal;
