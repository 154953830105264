import { Action } from "../types";
import { SimpleMedia } from "./SimpleMediaModal";

export const CLEAR_MODAL = "CLEAR_MODAL";

export const clearModal = (): Action => ({
    type: CLEAR_MODAL,
    payload: null
});

export const ACTIONS_MODAL = "ACTIONS_MODAL";

export const actionsModal = (): Action => ({
    type: ACTIONS_MODAL,
    payload: null
});

export const SET_SIMPLE_MEDIA = "SET_SIMPLE_MEDIA";

export const setSimpleMedia = (simpleMedia: SimpleMedia): Action => ({
    type: SET_SIMPLE_MEDIA,
    payload: simpleMedia
});
