import { Action } from "../types";
import { MODAL } from "./actions";
import { CLEAR_MODAL } from "../modals/actions";

export enum ChatModal {
    clear,
    editChannel,
    createGroup,
    adminActions,
    banOptions,
    muteOptions,
    reportOptions,
    directActions
}

export type ChatState = {
    modal: ChatModal;
};

const initialState: ChatState = {
    modal: ChatModal.clear
};

const chatReducer = (state: ChatState = initialState, action: Action) => {
    switch (action.type) {
        case CLEAR_MODAL:
            return { ...state, modal: ChatModal.clear };
        case MODAL.EDIT_CHANNEL:
            return { ...state, modal: ChatModal.editChannel };
        case MODAL.CREATE_GROUP:
            return { ...state, modal: ChatModal.createGroup };
        case MODAL.ADMIN_ACTIONS:
            return { ...state, modal: ChatModal.adminActions };
        case MODAL.BAN_OPTIONS:
            return { ...state, modal: ChatModal.banOptions };
        case MODAL.MUTE_OPTIONS:
            return { ...state, modal: ChatModal.muteOptions };
        case MODAL.REPORT_OPTIONS:
            return { ...state, modal: ChatModal.reportOptions };
        case MODAL.DIRECT_ACTIONS:
            return { ...state, modal: ChatModal.directActions };
        default:
            return state;
    }
};

export default chatReducer;
