import React, { useState } from "react";
import { CampaignFilters } from "src/campaigns/types";
import CheckBox from "src/components/CheckBox";
import {
    Option,
    OptionList,
    OptionText
} from "src/media/search/components/CommonStyles";
import styled from "styled-components";
import LabelText from "src/components/text/LabelText";

const Label = styled(LabelText)`
    margin-top: 20px;
    margin-bottom: 12px;
`;

interface Props {
    setFilters: (filters: CampaignFilters) => void;
    filters: CampaignFilters;
}

const CampaignBudget = ({ filters, setFilters }: Props) => {
    const generateSetBudget = (budget: "budgetLow" | "budgetNotLow") => {
        if ("budgetLow" && filters.budgetNotLow) {
            return () =>
                setFilters({
                    ...filters,
                    budgetNotLow: !filters.budgetNotLow,
                    [budget]: !filters[budget]
                });
        } else if ("budgetNotLow" && filters.budgetLow) {
            return () =>
                setFilters({
                    ...filters,
                    budgetLow: !filters.budgetLow,
                    [budget]: !filters[budget]
                });
        } else
            return () => setFilters({ ...filters, [budget]: !filters[budget] });
    };

    return (
        <>
            <Label>BIDS / BUDGETS</Label>
            <OptionList>
                <Option onClick={generateSetBudget("budgetLow")}>
                    <OptionText>Budget Low</OptionText>
                    <CheckBox selected={filters.budgetLow} />
                </Option>
                <Option onClick={generateSetBudget("budgetNotLow")}>
                    <OptionText>Budget Not Low</OptionText>
                    <CheckBox selected={filters.budgetNotLow} />
                </Option>
            </OptionList>
        </>
    );
};

export default CampaignBudget;
