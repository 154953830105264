import React from "react";
import CoinReward from "src/components/CoinReward";
import { colors, fonts } from "src/constants";
import { PublisherEarnings } from "src/profile/types";
import styled, { keyframes } from "styled-components";

const animation = keyframes`
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
`;
const Loader = styled.div`
    .shimmer {
        background: #f3f6f7
            linear-gradient(
                to right,
                #f3f6f7 0%,
                #eef1f2 20%,
                #f3f6f7 40%,
                #f3f6f7 100%
            )
            no-repeat;
        background-size: 800px 100px;

        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: ${animation};
        animation-timing-function: linear;
    }
`;
const DummyCell = styled.div`
    height: 80px;
    border-radius: 6px;
    padding: 12px;
    margin: 17px;
`;

const Logo = styled.img`
    height: 56px;
    width: 56px;
    border-radius: 5px;
    object-fit: cover;
    display: block;
`;
const NameContainer = styled.div`
    margin-left: 10px;
    text-align: left;
    overflow: hidden;
`;

const Name = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const Container = styled.div`
    text-align: left;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    padding: 12px;
    margin: 17px;
`;

const Amount = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    margin-left: auto;
`;
interface Props {
    earnings?: PublisherEarnings[];
}
const EarningsList = ({ earnings }: Props) => {
    if (!earnings || earnings.length < 0)
        return (
            <Loader>
                <DummyCell className="shimmer" />
                <DummyCell className="shimmer" />
                <DummyCell className="shimmer" />
            </Loader>
        );
    else
        return (
            <>
                {earnings.map(earning => (
                    <Container key={`earning${earning.campaign.name}`}>
                        <Logo src={earning.campaign.url} />
                        <NameContainer>
                            <Name>{earning.campaign.name}</Name>
                            <CoinReward
                                reward={earning.countryPlatformReward.reward}
                                type={"install"}
                            />
                        </NameContainer>
                        <Amount>{earning.earnings}</Amount>
                    </Container>
                ))}
            </>
        );
};

export default EarningsList;
