import React from "react";
import { useDispatch } from "react-redux";
import ActionsModal from "src/modals/ActionsModal";
import { setMediaSort } from "src/pagination/media/actions";

interface Props {
    closeModal: () => void;
}

const MediaSortModal = ({ closeModal }: Props) => {
    const dispatch = useDispatch();

    function generateSubmitSort(sortBy: string) {
        return () => {
            dispatch(setMediaSort(sortBy));
            closeModal();
        };
    }

    return (
        <ActionsModal
            actions={[
                {
                    text: "Recommended",
                    callback: generateSubmitSort("Recommended")
                },
                {
                    text: "Newest",
                    callback: generateSubmitSort("Newest")
                },
                {
                    text: "Oldest",
                    callback: generateSubmitSort("Oldest")
                },
                {
                    text: "Most Popular",
                    callback: generateSubmitSort("Most Popular")
                },
                {
                    text: "Least Popular",
                    callback: generateSubmitSort("Least Popular")
                }
            ]}
            localClearModal={closeModal}
        />
    );
};

export default MediaSortModal;
