import React from "react";
import styled from "styled-components";
import { FlexVerticallyCentered } from "../../../components/CommonStyles";
import CoinReward from "../../../components/CoinReward";
import CountryPlatformCell from "../../../components/CountryPlatformCell";
import { CountryPlatformReward } from "../../types";

const CoinDescription = styled.div`
    ${FlexVerticallyCentered}

    & > * ~ * {
        margin-left: 7px;
    }
`;

interface Props {
    countryPlatformReward?: CountryPlatformReward;
    rewardType: string;
    noPlatform?: boolean;
    color?: string;
    textSize?: number;
}

const CampaignReward = ({
    countryPlatformReward,
    rewardType,
    noPlatform,
    color,
    textSize
}: Props) => {
    if (!countryPlatformReward) return null;

    const {
        country,
        platform,
        reward,
        rewardMin,
        rewardMax,
        previous_reward
    } = countryPlatformReward;

    return (
        <CoinDescription>
            <CoinReward
                reward={reward}
                rewardMin={rewardMin}
                rewardMax={rewardMax}
                type={rewardType}
                color={color}
                previousReward={previous_reward}
                textSize={textSize}
            />
            {!noPlatform && (
                <CountryPlatformCell
                    country={country}
                    platform={platform}
                    size={20}
                />
            )}
        </CoinDescription>
    );
};

export default CampaignReward;
