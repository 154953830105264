import React, { useEffect, useState } from "react";
import MarketplaceInput from "src/marketplace/components/MarketplaceInput";
import styled from "styled-components";
import { CurrentFilter, FiltersReference } from "../hooks/useFilters";
import { isNumberInputValid } from "../utils";

const CustomWrap = styled.div`
    display: flex;
    justify-content: space-around;
`;
const CustomLabel = styled.label`
    display: flex;
    align-items: center;
    padding: 0px 12px;
    font-size: 13px;
    margin-bottom: 0px;
`;
const StyledMarketplaceInput = styled(MarketplaceInput)`
    height: 32px;
    margin-left: 8px;
`;

interface Props {
    filtersReference: FiltersReference;
    currentFilter: CurrentFilter;
    currentFilterKey: string;
    className?: string;
}

const RangeFilter = ({
    filtersReference,
    currentFilter,
    currentFilterKey,
    className
}: Props) => {
    const [range, setRange] = useState<[string, string]>(
        currentFilter.type === "number" && currentFilter.customRange
            ? currentFilter.customRange
            : ["", ""]
    );

    useEffect(() => {
        setRange(
            currentFilter.customRange ? currentFilter.customRange : ["", ""]
        );
    }, [currentFilter.customRange]);

    const handleLowerRange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputVal = e.currentTarget.value;
        if (isNumberInputValid(inputVal)) {
            setRange([`${inputVal}`, `${range[1] ? range[1] : ""}`]);
        }
    };
    const handleUpperRange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputVal = e.currentTarget.value;
        if (isNumberInputValid(inputVal)) {
            setRange([`${range[0] ? range[0] : ""}`, `${inputVal}`]);
        }
    };

    return (
        <CustomWrap className={className}>
            <CustomLabel>
                Min:
                <StyledMarketplaceInput
                    onBlur={() =>
                        filtersReference.handleCustomRange(
                            currentFilterKey,
                            range
                        )
                    }
                    value={range[0] ? range[0] : ""}
                    onChange={handleLowerRange}
                />
            </CustomLabel>
            <CustomLabel>
                Max:
                <StyledMarketplaceInput
                    onBlur={() =>
                        filtersReference.handleCustomRange(
                            currentFilterKey,
                            range
                        )
                    }
                    value={range[1] ? range[1] : ""}
                    onChange={handleUpperRange}
                />
            </CustomLabel>
        </CustomWrap>
    );
};

export default RangeFilter;
