import React, { useEffect, useState } from "react";
// @ts-ignore
import { withSendBird } from "sendbird-uikit";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { Connection } from "../profile/types";
import { Channel } from "./types";
import { postConnectionSuccess } from "../profile/actions";
import { addConnectionsToChannels, joinFriendChannel } from "./util";
import { Switch, Route } from "react-router";
import ChannelList from "./channel-list";
import ChannelPage from "./channel";
import LoadChannel from "./channel/LoadChannel";
import ChannelCreate from "./channel-create";
import NotFoundPage from "src/navigation/NotFound";

interface Props {
    userId: number;
    connections?: Connection[] | null;
    postConnectionSuccess: (connection: Connection) => void;
    sdk: any;
}

const ChatRouter = ({
    userId,
    connections,
    postConnectionSuccess,
    sdk
}: Props) => {
    const [channels, setChannels] = useState<Channel[] | null>(null);

    function fetchChannels() {
        if (sdk.GroupChannel) {
            const channelListQuery = sdk.GroupChannel.createMyGroupChannelListQuery();
            channelListQuery.order = "latest_last_message";
            channelListQuery.includeEmpty = true;

            if (channelListQuery.hasNext) {
                channelListQuery.next(function(channelList: any) {
                    addConnectionsToChannels(
                        userId,
                        channelList,
                        postConnectionSuccess,
                        connections
                    ).then(newChannels => {
                        Promise.all(
                            newChannels.map((channel: any) => {
                                return joinFriendChannel(channel);
                            })
                        ).then(() => {
                            setChannels(newChannels as Channel[]);
                        });
                    });
                });
            }
        }
    }

    useEffect(() => {
        fetchChannels();
    }, [sdk]);

    useEffect(() => {
        if (sdk.ChannelHandler) {
            const channelHandler = new sdk.ChannelHandler();

            channelHandler.onMessageReceived = fetchChannels;
            channelHandler.onUserReceivedInvitation = fetchChannels;
            channelHandler.onUserDeclinedInvitation = fetchChannels;

            sdk.addChannelHandler(
                "custom-channel-list-handler",
                channelHandler
            );

            return () => {
                sdk.removeChannelHandler("custom-channel-list-handler");
            };
        }
    }, [sdk.ChannelHandler]);

    useEffect(() => {
        if (sdk.setChannelInvitationPreference) {
            sdk.setChannelInvitationPreference(false, (_: any, error: any) => {
                if (error) {
                    window.alert(error);
                }
            });
        }
    }, [sdk]);

    return (
        <Switch>
            <Route exact path={"/chat/create"} component={ChannelCreate} />

            <Route
                path={"/chat/new/:otherId"}
                render={({ match }) => {
                    return (
                        <LoadChannel
                            userId={userId}
                            otherId={match.params.otherId}
                        />
                    );
                }}
            />

            <Route
                path={"/chat/channel/:channelUrl"}
                render={({ match }) => {
                    return (
                        <ChannelPage
                            userId={userId}
                            channelUrl={match.params.channelUrl}
                            sdk={sdk}
                        />
                    );
                }}
            />

            <Route
                exact
                path={"/chat"}
                render={() => (
                    <ChannelList userId={userId} channels={channels} />
                )}
            />

            <Route component={NotFoundPage} />
        </Switch>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    connections: state.profile.connections
});

const mapDispatchToProps = (dispatch: any) => ({
    postConnectionSuccess: (connection: Connection) =>
        dispatch(postConnectionSuccess(connection))
});

export default withSendBird(
    connect(mapStateToProps, mapDispatchToProps)(ChatRouter),
    (state: any) => ({
        sdk: state.stores.sdkStore.sdk
    })
);
