import React, { ReactNode, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { withSendBird } from "sendbird-uikit";
import { Link } from "react-router-dom";
import * as Icons from "src/components/icons/exports";
import SimpleNavbar from "src/components/SimpleNavbar";
import DropdownMenu from "src/components/DropdownMenu";
import burger from "src/components/icons/burger.svg";
import { colors, breakpoints, sizes } from "src/constants";
import { useIntercom } from "react-use-intercom";
import { getTotalUnreadCount } from "src/new-chat/selectors";
import { useDispatch, useSelector } from "react-redux";
import { goBack } from "connected-react-router";
import NavButton from "./NavButton";
import { getIsMobile } from "src/utils/functions/selectors";
import { useLocation } from "react-router";
import { GlobalState } from "src/reducers";
import {
    createOfferWizardRoute,
    creatorGroupsRoutes,
    instructionSetsRoutes,
    paymentSetsRoutes
} from "src/marketplace/utils/marketplace-routes";
import { selectAdminStatus } from "src/auth/selectors";
import { getLoggedInProfile } from "src/profile/selectors";
import { AccessStatuses } from "src/profile/types";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";
import { LayoutThemeType } from "src/ui/layout/actions";
import { selectLayoutTheme } from "src/ui/layout/selectors";

// This component is basically a clone of the BurgerNavbar component, but it uses slightly different tech.
// This was created for split testing the desktop version of the web app.
// When all navbars across the site gets replaced with the new responsive one, BurgerNavbar should be deleted.

interface NavbarProps {
    forceDisplay?: true;
    marginBottom?: number;
    theme: LayoutThemeType;
}

const Navbar = styled("nav")<NavbarProps>`
    display: ${props => (props.forceDisplay ? "block" : "none")};
    height: ${sizes.navbar.mobileHeight}px;
    margin-bottom: ${props => props.marginBottom}px;
    background-color: ${props =>
        props.theme === LayoutThemeType.light
            ? colors.white
            : colors.darkBackground};

    ${props =>
        !props.forceDisplay &&
        css`
            @media (max-width: ${breakpoints.tablet}px) {
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                display: flex;
                align-items: center;
            }
        `}
`;

const BurgerIcon = styled("img").attrs(() => ({
    src: burger,
    alt: "Mobile Navbar Burger Icon"
}))`
    cursor: pointer;
`;

const Menu = styled("div")`
    position: absolute;
    top: 28px;
    left: -4px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 6px;
    background-color: ${colors.white};
    user-select: none;
    overflow: hidden;
`;

const MenuItem = styled(Link)<{ $isActive?: boolean }>`
    display: block;
    width: 160px;
    padding: 12px 20px;
    font-size: 16px;
    text-align: left;
    line-height: 24px;
    text-decoration: none;
    cursor: pointer;
    color: ${props => (props.$isActive ? colors.primaryBlue : colors.black)};
    box-shadow: ${props =>
        props.$isActive
            ? `inset 2px 0px 0px 0px${colors.primaryBlue}`
            : "none"};

    &:hover {
        color: ${colors.primaryBlue};
        background-color: ${colors.lightTeal};
        text-decoration: none;
    }
`;

const MenuItemButton = styled.button`
    display: block;
    width: 160px;
    padding: 12px 20px;
    color: ${colors.black};
    font-size: 16px;
    text-align: left;
    line-height: 24px;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: ${colors.primaryBlue};
        background-color: ${colors.lightTeal};
        text-decoration: none;
    }
`;

const ChatIconContainer = styled("div")`
    position: relative;
    margin-left: 6px;
`;

const ChatLink = styled.div`
    display: block;
    cursor: pointer;
`;

const UnreadIndicator = styled("div")`
    position: absolute;
    top: 0;
    right: -2px;
    width: 14px;
    height: 14px;
    color: ${colors.white};
    background-color: ${colors.primaryBlue};
    border-radius: 2px;
    font-size: 8px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;

    div {
        margin-left: 1px;
    }
`;

interface Props {
    title?: string;
    right?: ReactNode;
    sdk: any;
    back?: true;
    customNavbar?: React.ReactNode;
    forceDisplay?: true;
    marginBottom?: number;
}

const NavbarMobile = (props: Props) => {
    const { boot, update, showNewMessages } = useIntercom();

    const {
        title,
        right,
        sdk,
        back,
        customNavbar,
        forceDisplay,
        marginBottom
    } = props;

    const theme = useSelector(selectLayoutTheme);
    const unreadCount = useSelector((state: GlobalState) =>
        getTotalUnreadCount(state, undefined)
    );
    const profile = useSelector(getLoggedInProfile);
    const chatRenewal = useSelector(selectFeatureFlags(featureFlags.Chat2));

    const [unreadMessagesCount, setUnreadMessagesCount] = useState<
        number | null
    >(null);
    const unread = chatRenewal ? unreadCount : unreadMessagesCount;

    const currentPath = useLocation().pathname;

    useEffect(() => {
        if (unreadMessagesCount === null && sdk.getTotalUnreadMessageCount) {
            sdk.getTotalUnreadMessageCount((totalCount: number) => {
                setUnreadMessagesCount(totalCount);
            });
        }
    }, [sdk]);

    useEffect(() => {
        if (sdk.UserEventHandler) {
            const UserEventHandler = new sdk.UserEventHandler();

            UserEventHandler.onTotalUnreadMessageCountUpdated = (
                totalCount: number
            ) => {
                setUnreadMessagesCount(totalCount);
            };

            const uniqueHandlerId = `${localStorage.getItem(
                "userid"
            )}-${Date.now()}`;

            sdk.addUserEventHandler(uniqueHandlerId, UserEventHandler);

            return () => sdk.removeUserEventHandler(uniqueHandlerId);
        }
    }, [sdk]);

    interface ItemRoute {
        route?: string;
        onClick?: () => void;
        label: string;
    }

    const location = useLocation();
    const currentPathname = location.pathname.slice(1);
    const isAdminTools = currentPathname.includes("admin-tools");
    const isMarketplace =
        isAdminTools || currentPathname.includes("marketplace");
    const isEmployee = useSelector(selectAdminStatus);

    const blockedRoutes =
        profile?.accessStatus === AccessStatuses.ALL
            ? [
                  { route: "/campaign", label: "Campaigns" },
                  { route: "/analytics", label: "Analytics" }
              ]
            : [];

    const mainAppItemRoutes = [
        // { route: instructionSetsRoutes.main, label: "Marketplace" },
        ...blockedRoutes,
        { route: "/highlights", label: "Highlights" },
        { route: "/leaderboard", label: "Leaderboard" },
        { route: "/notifications", label: "Notifications" },
        { route: "/profile", label: "Profile" },
        { route: "/profile/settings", label: "Settings" },
        {
            onClick: () => {
                update({ customAttributes: { disable_chat_bot: false } });
                showNewMessages();
            },
            label: "Contact"
        }
    ];
    if (isEmployee) {
        mainAppItemRoutes.unshift({
            route: "/admin-tools/your-challenges",
            label: "Admin Tools"
        });
    }

    const marketplaceItemRoutes = [
        { route: "/", label: "The Plug" },
        { route: createOfferWizardRoute, label: "Create New Offer" },
        { route: instructionSetsRoutes.main, label: "Instruction Sets" },
        { route: creatorGroupsRoutes.main, label: "Creator Groups" },
        { route: paymentSetsRoutes.main, label: "Payment Sets" }
    ];

    const admintoolItemRoutes = [
        { route: "/", label: "The Plug" },
        { route: "/admin-tools/your-challenges/", label: "Your Challenges" },
        { route: "/admin-tools/creator-search", label: "Creator Search" }
    ];

    const itemRoutes: ItemRoute[] = isMarketplace
        ? isAdminTools
            ? admintoolItemRoutes
            : marketplaceItemRoutes
        : isEmployee
        ? mainAppItemRoutes
        : mainAppItemRoutes.splice(1);

    const MenuItems = itemRoutes.map((itemRoute: ItemRoute, index: number) => {
        return itemRoute.route ? (
            <MenuItem
                to={itemRoute.route}
                key={`${itemRoute.route}-${itemRoute.label}-${index}`}
                $isActive={
                    new RegExp(itemRoute.route).test(currentPath) ||
                    (itemRoute.route === "/campaign" && currentPath === "/")
                }
            >
                {itemRoute.label}
            </MenuItem>
        ) : (
            <MenuItemButton
                key={`${itemRoute.route}-${itemRoute.label}-${index}`}
                onClick={itemRoute.onClick}
            >
                {itemRoute.label}
            </MenuItemButton>
        );
    });

    const dispatch = useDispatch();

    const isMobile = useSelector(getIsMobile);

    return (
        <Navbar
            forceDisplay={forceDisplay}
            marginBottom={marginBottom}
            theme={theme}
        >
            {back && isMobile ? (
                <SimpleNavbar
                    left={<NavButton back onClick={() => dispatch(goBack())} />}
                    title={title}
                />
            ) : !!customNavbar && isMobile ? (
                customNavbar
            ) : (
                <SimpleNavbar
                    left={
                        <DropdownMenu closeOnClick button={<BurgerIcon />}>
                            <Menu>{MenuItems}</Menu>
                        </DropdownMenu>
                    }
                    title={title}
                    right={
                        <>
                            {right}
                            <ChatIconContainer>
                                <ChatLink
                                    onClick={() => {
                                        boot({
                                            customAttributes: {
                                                disable_chat_bot: false
                                            }
                                        });
                                        showNewMessages();
                                    }}
                                >
                                    <Icons.Message height={22} />
                                </ChatLink>
                            </ChatIconContainer>
                        </>
                    }
                />
            )}
        </Navbar>
    );
};

export default withSendBird(NavbarMobile, (state: any) => ({
    sdk: state.stores.sdkStore.sdk
}));
