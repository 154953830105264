import React from "react";
import styled from "styled-components";
import { colors } from "src/constants";

const Wrapper = styled.div<{ isRightComponentOnly: boolean }>`
    display: flex;
    justify-content: ${props =>
        props.isRightComponentOnly ? "flex-end" : "space-between"};
    align-items: center;
    padding: 10px 8px 0px;
    border-top: 1px solid ${colors.mediumTeal};
    background-color: ${colors.lightTeal};
`;

interface Props {
    leftComponent?: React.ReactNode;
    rightComponent?: React.ReactNode;
    className?: string;
}

const CarouselHeader = ({
    leftComponent,
    rightComponent,
    className
}: Props) => {
    return (
        <Wrapper
            className={className}
            isRightComponentOnly={Boolean(rightComponent) && !leftComponent}
        >
            {leftComponent}
            {rightComponent}
        </Wrapper>
    );
};

export default CarouselHeader;
