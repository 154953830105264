import React, { ChangeEvent, KeyboardEvent } from "react";
import TextareaAutosize from "react-textarea-autosize";

interface Props {
    placeholder: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    style?: any;
    className?: string;
    autoFocus?: boolean;
}

const ResizingTextArea = ({
    placeholder,
    value,
    onChange,
    onKeyDown,
    style,
    autoFocus,
    className
}: Props) => {
    const defaultStyle: any = {
        display: "block",
        width: "100%",
        resize: "none",
        border: "none",
        outline: "none",
        padding: "0",
        fontSize: "14px",
        lineHeight: "17px",
        ...style
    };

    return (
        <TextareaAutosize
            className={className}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            style={!className ? defaultStyle : {}}
            autoFocus={autoFocus}
        />
    );
};

export default ResizingTextArea;
