import React from "react";
import { Dispatch } from "redux";
import {
    createInstructionStep,
    deleteInstructionStep,
    updateInstructionStep
} from "../actions.instruction-steps";
import {
    createInstructionsSet,
    InstructionsSetCreateData,
    updateInstructionsSet
} from "../actions.instructions-sets";
import {
    ChildInstructionStep,
    ChildInstructionSteps,
    InstructionsSet,
    InstructionStep
} from "../types";

// Used to prevent the recreation of any existing child instructions steps on update.
interface Id {
    [key: string]: number;
}

interface AlreadyExistingChildSteps {
    scriptId: Id;
    mediaId: Id;
    assetId: Id;
}

export function generateListOfExistingChildSteps(
    currentInstructionsSet: InstructionsSet | undefined
): AlreadyExistingChildSteps {
    const alreadyExistingChildSteps: AlreadyExistingChildSteps = {
        scriptId: {},
        mediaId: {},
        assetId: {}
    };

    const parentInstructionSteps = currentInstructionsSet?.instructionSteps;

    if (parentInstructionSteps && parentInstructionSteps.length > 0) {
        parentInstructionSteps.forEach(
            (parentInstructionStep: InstructionStep) => {
                const childInstructionSteps =
                    parentInstructionStep?.instructionSteps;

                if (childInstructionSteps && childInstructionSteps.length > 0) {
                    childInstructionSteps.forEach(
                        (childInstructionStep: ChildInstructionStep) => {
                            if (childInstructionStep.scriptId) {
                                alreadyExistingChildSteps.scriptId[
                                    childInstructionStep.scriptId
                                ] = childInstructionStep.scriptId;
                            }
                            if (childInstructionStep.mediaId) {
                                alreadyExistingChildSteps.mediaId[
                                    childInstructionStep.mediaId
                                ] = childInstructionStep.mediaId;
                            }
                            if (childInstructionStep.assetId) {
                                alreadyExistingChildSteps.assetId[
                                    childInstructionStep.assetId
                                ] = childInstructionStep.assetId;
                            }
                        }
                    );
                }
            }
        );
    }

    return alreadyExistingChildSteps;
}

// Handles the updating of the parent instruction step we're currently iterating through, if it already exists.
function _updateParentInstructionStep(
    dispatch: Dispatch<any>,
    instructionStepId: number,
    instructionStep: InstructionStep
): void {
    dispatch(updateInstructionStep(instructionStepId, instructionStep));
}

interface CreateDataIds {
    scriptId?: number;
    mediaId?: number;
    assetId?: number;
}

function _createChildInstructionStep(
    dispatch: Dispatch<any>,
    instructionSetId: number,
    instructionStepId: number,
    createDataIds: CreateDataIds
): void {
    const createData = {
        instructionSetId,
        instructionStepId,
        ...createDataIds
    };

    dispatch(createInstructionStep(createData));
}

function _updateOrDeleteChildInstructionStep(
    dispatch: Dispatch<any>,
    childStep: ChildInstructionStep
): void {
    if (childStep.id) {
        const childStepShouldBeDeleted =
            !childStep.scriptId && !childStep.mediaId && !childStep.assetId;

        // Delete a child step
        if (childStepShouldBeDeleted) {
            dispatch(deleteInstructionStep(childStep.id));
        }
        // Update a child step
        else {
            dispatch(updateInstructionStep(childStep.id, childStep));
        }
    }
}

// Handles the creation of any new child instruction steps that don't exist in the instruction step we're currently iterating through.
function _createUpdateOrDeleteChildInstructionStep(
    dispatch: Dispatch<any>,
    childInstructionSteps: ChildInstructionSteps | undefined,
    instructionsSetId: number,
    instructionStepId: number,
    alreadyExistingChildSteps: AlreadyExistingChildSteps
): void {
    if (childInstructionSteps && childInstructionSteps.length > 0) {
        childInstructionSteps.forEach((childStep: ChildInstructionStep) => {
            const createDataIds: CreateDataIds = {};

            if (childStep.scriptId) {
                if (!alreadyExistingChildSteps.scriptId[childStep.scriptId]) {
                    createDataIds["scriptId"] = childStep.scriptId;
                }
            }
            if (childStep.mediaId) {
                if (!alreadyExistingChildSteps.mediaId[childStep.mediaId]) {
                    createDataIds["mediaId"] = childStep.mediaId;
                }
            }
            if (childStep.assetId) {
                if (!alreadyExistingChildSteps.assetId[childStep.assetId]) {
                    createDataIds["assetId"] = childStep.assetId;
                }
            }

            const createDataIdsHasElements =
                Object.keys(createDataIds).length > 0;

            // Creating new child instruction steps
            if (createDataIdsHasElements) {
                _createChildInstructionStep(
                    dispatch,
                    instructionsSetId,
                    instructionStepId,
                    createDataIds
                );
            } else {
                _updateOrDeleteChildInstructionStep(dispatch, childStep);
            }
        });
    }
}

// Handles the creation of a new instruction step if the one we're currently iterating through doesn't exist.
function _createInstructionStep(
    dispatch: Dispatch<any>,
    instructionSetId: number,
    instructionStep: InstructionStep,
    index: number
): void {
    const createData = { instructionSetId, ...instructionStep };
    dispatch(createInstructionStep(createData, index));
}

function _handleUpdateOrCreateInstructionsSteps(
    dispatch: Dispatch<any>,
    formattedInstructionSteps: InstructionStep[],
    instructionsSetId: number,
    alreadyExistingChildSteps: AlreadyExistingChildSteps
) {
    formattedInstructionSteps.forEach(
        (instructionStep: InstructionStep, index: number) => {
            if (!instructionStep.instructionSteps) return;
            if (instructionStep.id) {
                _updateParentInstructionStep(
                    dispatch,
                    instructionStep.id,
                    instructionStep
                );
                _createUpdateOrDeleteChildInstructionStep(
                    dispatch,
                    instructionStep.instructionSteps,
                    instructionsSetId,
                    instructionStep.id,
                    alreadyExistingChildSteps
                );
            } else {
                _createInstructionStep(
                    dispatch,
                    instructionsSetId,
                    instructionStep,
                    index
                );
            }
        }
    );
}

export function handleUpdateInstructionsSet(
    dispatch: Dispatch<any>,
    currentInstructionsSetId: number,
    instructionSetName: string,
    formattedInstructionSteps: InstructionStep[],
    alreadyExistingChildSteps: AlreadyExistingChildSteps
): void {
    dispatch(
        updateInstructionsSet(
            currentInstructionsSetId,
            { name: instructionSetName },
            formattedInstructionSteps
        )
    );
    _handleUpdateOrCreateInstructionsSteps(
        dispatch,
        formattedInstructionSteps,
        currentInstructionsSetId,
        alreadyExistingChildSteps
    );
}

export function handleCreateInstructionsSet(
    dispatch: Dispatch<any>,
    formattedInstructionsSet: InstructionsSetCreateData,
    setInstructionsSetName: React.Dispatch<React.SetStateAction<string>>
): void {
    dispatch(createInstructionsSet(formattedInstructionsSet));
    setInstructionsSetName("");
}
