import React from "react";
import styled from "styled-components";

const Main = styled.div`
    position: relative;
    height: 70px;
    width: 70px;
    min-width: 70px;
`;

const ContentContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
`;

const Photo = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.15);
`;

const Badge = styled.div`
    position: absolute;
    top: -4px;
    left: -4px;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #0079f5;
    color: white;
    font-weight: bold;
    font-size: 8px;
`;

const PrizeIcon = ({ url, repeats }: { url: string; repeats?: number }) => {
    return (
        <Main>
            <ContentContainer>
                <Photo src={url} />
                <Overlay />
            </ContentContainer>
            {repeats && repeats > 1 && <Badge>{repeats}x</Badge>}
        </Main>
    );
};

export default PrizeIcon;
