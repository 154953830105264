import { Action } from "src/types";

// Set  Creator Groups Loading -----------------------------------------------

export const SET_CREATOR_GROUPS_LOADING = "SET_CREATOR_GROUPS_LOADING";

export function setCreatorGroupsLoading(loading: boolean): Action {
    return {
        type: SET_CREATOR_GROUPS_LOADING,
        payload: { loading }
    };
}

// Set Current Creator Group Id -----------------------------------------------

export const SET_CURRENT_CREATOR_GROUP_ID = "SET_CURRENT_CREATOR_GROUP_ID";

export function setCurrentCreatorGroupId(id: number | null): Action {
    return {
        type: SET_CURRENT_CREATOR_GROUP_ID,
        payload: id
    };
}

// Fetch Creator Interests ---------------------------------------------------------------

export const FETCH_CREATOR_INTERESTS = {
    REQUEST: "FETCH_CREATOR_INTERESTS_REQUEST",
    SUCCESS: "FETCH_CREATOR_INTERESTS_SUCCESS",
    FAILURE: "FETCH_CREATOR_INTERESTS_FAILURE"
};

export function fetchCreatorInterests(): Action {
    return {
        type: FETCH_CREATOR_INTERESTS.REQUEST,
        payload: null
    };
}

export function fetchCreatorInterestsSuccess(interests: string[]): Action {
    return {
        type: FETCH_CREATOR_INTERESTS.SUCCESS,
        payload: { interests }
    };
}

export function fetchCreatorInterestsFailure(error: string): Action {
    return {
        type: FETCH_CREATOR_INTERESTS.FAILURE,
        payload: { error }
    };
}

// Fetch Creator Themes ---------------------------------------------------------------

export const FETCH_CREATOR_THEMES = {
    REQUEST: "FETCH_CREATOR_THEMES_REQUEST",
    SUCCESS: "FETCH_CREATOR_THEMES_SUCCESS",
    FAILURE: "FETCH_CREATOR_THEMES_FAILURE"
};

export function fetchCreatorThemes(): Action {
    return {
        type: FETCH_CREATOR_THEMES.REQUEST,
        payload: null
    };
}

export function fetchCreatorThemesSuccess(themes: string[]): Action {
    return {
        type: FETCH_CREATOR_THEMES.SUCCESS,
        payload: { themes }
    };
}

export function fetchCreatorThemesFailure(error: string): Action {
    return {
        type: FETCH_CREATOR_THEMES.FAILURE,
        payload: { error }
    };
}
