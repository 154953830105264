import React from "react";
import styled from "styled-components";
import VerticallyCentered from "../../components/VerticallyCentered";
import ProfilePhoto from "../../components/ProfilePhoto";
import { colors, fonts } from "../../constants";
import HideOverflow from "../../components/HideOverflow";
import { shortTimeInterval } from "../../util";
import { Channel } from "../types";
import { extractTitleAndPhoto } from "../util";
import { breakpoints } from "src/constants";

const Main = styled(VerticallyCentered)`
    padding: 15px;
    text-align: left;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (pointer: fine) {
        border: ${colors.mediumTeal} solid 1px;
        border-radius: 10px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
        }
    }
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const InfoContainer = styled(FlexColumn)<{ unread: boolean }>`
    width: calc(100% - ${props => (props.unread ? 98 : 58)}px);
    overflow: hidden;
`;

const Title = styled(HideOverflow)`
    font-weight: bold;
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;
    margin-bottom: 6px;
`;

const Subtitle = styled(VerticallyCentered)`
    font-size: 12px;
    line-height: 14px;
`;

const Message = styled(HideOverflow)<{ unread: boolean }>`
    max-width: calc(100% - 50px);
    ${props => props.unread && "font-weight: 500;"}
`;

const Timestamp = styled.div`
    margin-left: 5px;
    color: ${colors.primaryGray};
`;

const Request = styled(HideOverflow)`
    width: 100%;
`;

const UnreadBadge = styled(VerticallyCentered)<{ muted: boolean }>`
    justify-content: center;
    margin-left: auto;
    padding: 4px 8px;
    max-width: 40px;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    color: white;
    border-radius: 4px;
    background-color: ${props =>
        props.muted ? colors.primaryGray : colors.primaryBlue};
`;

interface Props {
    userId: number;
    channel: Channel;
    push: (route: string) => void;
}

const ChannelPreview = ({ userId, channel, push }: Props) => {
    const sendBirdData = channel.sendBirdData;

    const { title, photoUrl } = extractTitleAndPhoto(sendBirdData, userId);

    const unread = sendBirdData.unreadMessageCount > 0;

    const lastMessage = sendBirdData.lastMessage || channel.lastMessage;

    return (
        <Main onClick={() => push(`/chat/channel/${sendBirdData.url}`)}>
            <ProfilePhoto
                profilePhotoUrl={photoUrl}
                photoSize={46}
                style={{ marginRight: "12px" }}
            />
            <InfoContainer unread={unread}>
                <Title>{title}</Title>
                <Subtitle>
                    {lastMessage ? (
                        <>
                            <Message unread={unread}>
                                {lastMessage.message}
                            </Message>
                            <Timestamp>
                                {` • ${shortTimeInterval(
                                    Date.now() - lastMessage.createdAt
                                )}`}
                            </Timestamp>
                        </>
                    ) : (
                        <Request>
                            {channel.sendBirdData.members
                                .map((element: any) => element.nickname)
                                .join(", ")}
                        </Request>
                    )}
                </Subtitle>
            </InfoContainer>
            {unread && (
                <UnreadBadge muted={sendBirdData.myPushTriggerOption === "off"}>
                    {sendBirdData.unreadMessageCount}
                </UnreadBadge>
            )}
        </Main>
    );
};

export default ChannelPreview;
