import React from "react";
import { connect } from "react-redux";
import { Channel } from "../../../types";
import { clearModal } from "../../../../modals/actions";
import ActionsModal from "../../../../modals/ActionsModal";

interface Props {
    currentUser: any;
    channel: Channel;
    muteBanRefresh: VoidFunction;
    clearModal: VoidFunction;
}

const BanOptionsModal = ({
    currentUser,
    channel,
    muteBanRefresh,
    clearModal
}: Props) => {
    const sendBirdData = channel.sendBirdData;

    const completionCallback = (_: any, error: any) => {
        if (error) {
            window.alert(error);
        } else {
            clearModal();
            muteBanRefresh();
        }
    };

    const ban = (length?: number) => {
        if (length) {
            sendBirdData.banUserWithUserId(
                currentUser.userId,
                length,
                "Generic",
                completionCallback
            );
        } else {
            sendBirdData.banUserWithUserId(
                currentUser.userId,
                completionCallback
            );
        }
    };

    return (
        <ActionsModal
            actions={[
                { text: "Ban for:" },
                { text: "30 minutes", callback: () => ban(1800) },
                { text: "1 hour", callback: () => ban(3600) },
                { text: "1 day", callback: () => ban(86400) },
                { text: "Indefinitely", callback: () => ban(315569520) }
            ]}
        />
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(null, mapDispatchToProps)(BanOptionsModal);
