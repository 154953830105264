import React, { useEffect, useState } from "react";
import { goBack } from "connected-react-router";
import { connect } from "react-redux";
import { GlobalState } from "../../reducers";
import { Connection } from "../../profile/types";
import { postConnectionSuccess } from "../../profile/actions";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import FullscreenLoader from "../../components/FullscreenLoader";
import {
    addConnectionsToChannel,
    muteBanRefreshChannel,
    sendBirdMembersToConnections
} from "../util";
import { Channel } from "../types";
import ChannelMain from "./ChannelMain";

interface Props {
    sdk: any;
    userId: number;
    channelUrl: string;
    connections?: Connection[] | null;
    postConnectionSuccess: (connection: Connection) => void;
    goBack: VoidFunction;
}

const ChannelPage = ({
    sdk,
    userId,
    channelUrl,
    connections,
    postConnectionSuccess,
    goBack
}: Props) => {
    const [channel, setChannel] = useState<Channel | null>(null);

    const [membersBoolean, setMembersBoolean] = useState(true);
    const membersRefresh = () => setMembersBoolean(!membersBoolean);

    const [muteBanBoolean, setMuteBanBoolean] = useState(true);
    const muteBanRefresh = () => setMuteBanBoolean(!muteBanBoolean);

    useEffect(() => {
        if (
            (!channel || channel.sendBirdData.channelUrl !== channelUrl) &&
            sdk.GroupChannel
        ) {
            sdk.GroupChannel.getChannel(
                channelUrl,
                (groupChannel: any, error: any) => {
                    if (!error) {
                        addConnectionsToChannel(
                            userId,
                            groupChannel,
                            postConnectionSuccess,
                            connections
                        ).then(newChannel => {
                            setChannel(newChannel);
                            muteBanRefresh();
                        });
                    }
                }
            );
        }
    }, [channelUrl, sdk]);

    useEffect(() => {
        if (channel) {
            sendBirdMembersToConnections(
                userId,
                channel.sendBirdData.members,
                postConnectionSuccess,
                connections
            ).then(connections => {
                setChannel({ ...channel, connections });
            });
        }
    }, [membersBoolean]);

    useEffect(() => {
        if (channel && channel.sendBirdData.myRole === "operator") {
            muteBanRefreshChannel(
                userId,
                channel,
                postConnectionSuccess,
                connections
            ).then(newChannel => {
                setChannel(newChannel);
            });
        }
    }, [muteBanBoolean]);

    if (!channel) {
        return (
            <>
                <SimpleNavbar left={<NavButton back onClick={goBack} />} />
                <FullscreenLoader />
            </>
        );
    } else {
        return (
            <ChannelMain
                sdk={sdk}
                userId={userId}
                channel={channel}
                setChannel={setChannel}
                membersRefresh={membersRefresh}
                muteBanRefresh={muteBanRefresh}
            />
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    connections: state.profile.connections
});

const mapDispatchToProps = (dispatch: any) => ({
    postConnectionSuccess: (connection: Connection) =>
        dispatch(postConnectionSuccess(connection)),
    goBack: () => dispatch(goBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelPage);
