import { Action } from "src/types";
import {
    ADD_ACCOUNT_MODAL_OPEN,
    ADD_CUSTOM_MEDIA_MODAL_OPEN,
    RATE_FORM_MODAL_OPEN,
    SET_ACCOUNT_ID,
    SET_PUBLISHER_OFFER_ID,
    SET_IS_RATE_FORM_EDIT,
    VIEW_ACCOUNT_MODAL_OPEN,
    SET_IS_ACCOUNT_EDIT,
    EDIT_ACCOUNT_MODAL_OPEN,
    CLEAR_PROFILE_MODALS,
    TOGGLE_ADMIN
} from "./actions";

export interface ProfileUIState {
    rateFormModalOpen: boolean;
    isRateFormEdit: boolean;
    addAccountModalOpen: boolean;
    editAccountModalOpen: boolean;
    isAccountEdit: boolean;
    viewAccountModalOpen: boolean;
    addCustomMediaModalOpen: boolean;
    accountId: number | null;
    publisherOfferId: number | null;
    isAdminToggled: boolean;
}

const initialProfileUIState: ProfileUIState = {
    rateFormModalOpen: false,
    isRateFormEdit: false,
    addAccountModalOpen: false,
    editAccountModalOpen: false,
    isAccountEdit: false,
    viewAccountModalOpen: false,
    addCustomMediaModalOpen: false,
    accountId: null,
    publisherOfferId: null,
    isAdminToggled: false
};

const profileUIReducer = (state = initialProfileUIState, action: Action) => {
    switch (action.type) {
        case CLEAR_PROFILE_MODALS:
            return { ...initialProfileUIState };

        case RATE_FORM_MODAL_OPEN:
            return { ...state, rateFormModalOpen: action.payload };

        case SET_IS_RATE_FORM_EDIT:
            return { ...state, isRateFormEdit: action.payload };

        case ADD_ACCOUNT_MODAL_OPEN:
            return { ...state, addAccountModalOpen: action.payload };

        case EDIT_ACCOUNT_MODAL_OPEN:
            return { ...state, editAccountModalOpen: action.payload };

        case SET_IS_ACCOUNT_EDIT:
            return { ...state, isAccountEdit: action.payload };

        case VIEW_ACCOUNT_MODAL_OPEN:
            return { ...state, viewAccountModalOpen: action.payload };

        case ADD_CUSTOM_MEDIA_MODAL_OPEN:
            return { ...state, addCustomMediaModalOpen: action.payload };

        case SET_ACCOUNT_ID:
            return { ...state, accountId: action.payload };

        case SET_PUBLISHER_OFFER_ID:
            return { ...state, publisherOfferId: action.payload };
        case TOGGLE_ADMIN:
            return { ...state, isAdminToggled: action.payload };
        default:
            return state;
    }
};

export default profileUIReducer;
