import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { usePrevious } from "react-use";
import CampaignSearchBar from "src/campaigns/landing/components/CampaignSearchBar";
import CampaignSortFilter from "src/campaigns/landing/components/CampaignSortFilter";
import { CampaignItemLoading } from "src/components/SkeletonLoader";
import { breakpoints, maxWidthDesktop } from "src/constants";
import XCard from "src/profile/components/XCard";
import { GlobalState } from "src/reducers";
import { incrementCampaignMaxItems } from "src/ui/campaigns/actions";
import { flex, hideScrollbar } from "src/utils/styles/snippets";
import styled from "styled-components";
import { fetchCampaigns } from "../actions";
import { fetchContest } from "../contests/actions";
import CampaignFilterModal from "../modals/CampaignFilterModal";
import CampaignSortModal from "../modals/CampaignSortModal";
import { checkShoutoutHistory, fetchShoutouts } from "../shoutouts/actions";
import { Campaign } from "../types";
import CampaignItem from "./components/CampaignItem";
import { Section, SectionHeader } from "./Landing";
import { getFeedCampaigns } from "./selectors";

const Main = styled.div`
    ${flex("column")};
    ${hideScrollbar()};
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    flex: 1;
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    margin: 10px auto 40px;
`;

const IncrementCampaignCountWhenInView = styled.div``;

const Campaigns = styled.article`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    margin-bottom: 10px;

    @media (max-width: ${breakpoints.tablet}px) {
        padding: 0px 15px;
    }

    @media (max-width: ${breakpoints.mobile}px) {
        grid-gap: 0px;
        padding: 0px;
    }
`;

const CampaignsTab = () => {
    const dispatch = useDispatch();

    const [filterModal, toggleFilterModal] = useState(false);
    const [sortModal, toggleSortModal] = useState(false);
    const [searchString, setSearchString] = useState("");
    const campaigns = useSelector((state: GlobalState) => {
        return getFeedCampaigns(state, searchString);
    });

    const {
        campaignsLoading,
        maxItems: campaignMaxItems,
        sortBy,
        filters
    } = useSelector((state: GlobalState) => {
        return state.ui.campaign;
    }, shallowEqual);

    const prevSort = usePrevious(sortBy);
    const prevFilters = usePrevious(filters);

    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView) {
            dispatch(incrementCampaignMaxItems(campaignMaxItems));
        }
    }, [inView]);

    const campaignsAllIds = useSelector((state: GlobalState) => {
        return state.entities.campaigns.allIds;
    });

    useEffect(() => {
        if (campaignsAllIds.length === 0) {
            dispatch(fetchCampaigns());
        }
    }, []);

    useEffect(() => {
        if (
            prevSort &&
            prevFilters &&
            (prevSort !== sortBy || prevFilters !== filters)
        ) {
            dispatch(fetchCampaigns(sortBy, filters));
        }
    }, [sortBy, filters]);

    return (
        <Main>
            <Section>
                <SectionHeader padding="0px 0px 16px">Campaigns</SectionHeader>
                <CampaignSearchBar
                    searchString={searchString}
                    setSearchString={setSearchString}
                />
                <CampaignSortFilter
                    openSortModal={() => toggleSortModal(true)}
                    openFilterModal={() => toggleFilterModal(true)}
                    sortBy={sortBy}
                    filters={filters}
                />
            </Section>
            <Campaigns>
                {campaignsLoading ? (
                    <>
                        <CampaignItemLoading
                            key={`${campaigns.length}-loading-1}`}
                        />
                        <CampaignItemLoading
                            key={`${campaigns.length}-loading-2}`}
                        />
                        <CampaignItemLoading
                            key={`${campaigns.length}-loading-3}`}
                        />
                    </>
                ) : campaigns.length === 0 ? (
                    <XCard text="No campaigns match your query." />
                ) : (
                    <>
                        {campaigns.map((campaign: Campaign, index: number) => {
                            return (
                                <CampaignItem
                                    key={`${campaign.id}`}
                                    campaign={campaign}
                                    rank={index}
                                />
                            );
                        })}
                        <IncrementCampaignCountWhenInView ref={ref} />
                    </>
                )}
            </Campaigns>
            <AnimatePresence>
                {sortModal && (
                    <CampaignSortModal
                        closeModal={() => toggleSortModal(false)}
                    />
                )}
                {filterModal && (
                    <CampaignFilterModal
                        closeModal={() => toggleFilterModal(false)}
                    />
                )}
            </AnimatePresence>
        </Main>
    );
};

export default CampaignsTab;
