import React from "react";
import {
    DirectBuyPayment,
    DirectBuyPaymentCollection
} from "src/buys/modules/direct-buy-payment/types";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import ProgressBar from "src/components/ProgressBar";
import { breakpoints, colors, fonts, fontWeight } from "src/constants";
import { shortenNumber } from "src/util";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 18px;
    gap: 20px;
`;

const Title = styled.h1<{ margin?: number }>`
    color: ${colors.gray2};
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.heavy};
    line-height: 14px;
    text-align: left;
    margin: ${props => props.margin || 0}px;
`;

const PayoutRow = styled.div`
    display: flex;
    position: relative;
    width: calc(100% - 30px);
    margin-top: 32px;
    @media (max-width: 700px) {
        width: calc(100% - 20px);
    }
`;

const Payout = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    padding: 4.5px 6px;
    background: ${colors.black};
    color: ${colors.white};
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    justify-content: center;
    gap: 4px;
    margin-bottom: 8px;
    @media (max-width: 700px) {
        width: 40px;
    }
`;

const PayoutTypes = styled.div`
    align-self: center;
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 8px;
`;

const PaymentTag = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    background: ${colors.secondaryGreen};
    border-radius: 8px;
`;

const PaymentType = styled.span`
    color: ${colors.white};
    font-weight: ${fontWeight.medium};
    font-size: ${fonts.default};
    line-height: 19px;
`;

const PaymentAmount = styled(PaymentType)`
    font-weight: ${fontWeight.heavy};
`;

const Dollar = styled.img`
    color: ${colors.white};
    @media (max-width: 700px) {
        display: none;
    }
`;

const Divider = styled.div<{ placement: number }>`
    width: 2px;
    height: 12px;
    background: ${colors.black};
    z-index: 80;
`;

const ViewCount = styled.div<{ amount: number }>`
    display: flex;
    align-items: center;
    position: absolute;
    flex-direction: column;
    font-size: 9px;
    font-weight: 500;
    height: 80px;
    margin-left: calc(${props => props.amount}% - 30px);
    margin-top: -32px;
    @media (max-width: 700px) {
        margin-left: calc(${props => props.amount}% - 20px);
    }
`;

const Eye = styled.img`
    width: 12px;
    height: 6px;
    margin-top: 8px;
`;

const Or = styled.h3`
    font-size: 12px;
    margin: auto 8px;
`;

const Copy = styled.h3`
    font-size: 9px;
    font-weight: ${fontWeight.medium};
    line-height: 11px;
    text-align: left;
    margin: -8px 0 0;
    color: ${colors.black};
`;

const AndContainer = styled.div`
    ${FlexVerticallyCentered};
`;

const AndDivider = styled.div`
    flex: 1;
    border-bottom: 1px solid ${colors.mediumTeal};
`;

const AndText = styled.h1`
    color: ${colors.gray2};
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.heavy};
    line-height: 14px;
    margin: 0 8px;
`;

interface Props {
    collections: DirectBuyPaymentCollection[];
    getCopy: (id?: number) => string;
    progress?: number;
}

const OpenBuyPayments = ({ collections, getCopy, progress = 0 }: Props) => {
    const getWidth = (index: number, length: number) => {
        let width = (index / (length - 1)) * 100;
        return width > 3 ? width : 3;
    };

    const getPaymentType = (type: string) => {
        switch (type) {
            case "flat_rate":
                return "Flat Fee";
            case "dollars_per_k":
                return "per K";
            default:
                return "";
        }
    };
    const getPaymentConjunction = (conjunction: string) => {
        switch (conjunction) {
            case "OR":
                return <Or>or</Or>;
            case "AND":
                return <Or>&</Or>;
            default:
                return null;
        }
    };

    return (
        <Main>
            {collections.map((collection, index) => {
                let length = collection.payment?.directBuyPayments?.length || 0;
                const instructionStepId =
                    collection.payment?.directBuyPayments[0].instructionStepId;
                return (
                    <React.Fragment key={index}>
                        {index !== 0 && (
                            <AndContainer>
                                <AndDivider />
                                <AndText>AND</AndText>
                                <AndDivider />
                            </AndContainer>
                        )}
                        <PayoutTypes>
                            {collection.payment?.payoutTiming === "realtime" ? (
                                <PaymentTag>
                                    <PaymentType>
                                        Earn Per View Thresholds
                                    </PaymentType>
                                </PaymentTag>
                            ) : (
                                collection.payment?.directBuyPayments.map(
                                    (payment, index) => (
                                        <React.Fragment
                                            key={`${index} payment`}
                                        >
                                            <PaymentTag>
                                                <PaymentAmount>
                                                    ${payment.amount}
                                                </PaymentAmount>
                                                <PaymentType>
                                                    {getPaymentType(
                                                        payment.payment.type
                                                    )}
                                                </PaymentType>
                                            </PaymentTag>

                                            {index !== length - 1 &&
                                                collection.payment
                                                    ?.paymentConjunction &&
                                                getPaymentConjunction(
                                                    collection.payment
                                                        ?.paymentConjunction
                                                )}
                                        </React.Fragment>
                                    )
                                )
                            )}
                        </PayoutTypes>
                        {collection.payment?.payoutTiming === "realtime" && (
                            <>
                                <div
                                    style={{ height: 80 }}
                                    key={`${index} collection`}
                                >
                                    <PayoutRow>
                                        {collection.payment?.directBuyPayments.map(
                                            (payment, i) => (
                                                <ViewCount
                                                    key={`${i} view`}
                                                    amount={getWidth(
                                                        i,
                                                        collection.payment
                                                            ?.directBuyPayments
                                                            .length || 0
                                                    )}
                                                >
                                                    <Payout>
                                                        <Dollar
                                                            src={
                                                                "/campaigns/dollar-sign.svg"
                                                            }
                                                        />
                                                        ${payment.amount}
                                                    </Payout>
                                                    <Divider
                                                        placement={getWidth(
                                                            i,
                                                            collection.payment
                                                                ?.directBuyPayments
                                                                .length || 0
                                                        )}
                                                    />
                                                    {payment.viewsMinimum && (
                                                        <Eye
                                                            src={
                                                                "/buys/eye-icon.svg"
                                                            }
                                                        />
                                                    )}
                                                    {payment.viewsMinimum &&
                                                        shortenNumber(
                                                            payment.viewsMinimum
                                                        )}
                                                </ViewCount>
                                            )
                                        )}
                                    </PayoutRow>
                                    <ProgressBar progress={progress - 2} />
                                </div>
                            </>
                        )}
                        {index === length - 1 && (
                            <Copy>
                                {collection.payment?.directBuyPayments[0]
                                    .instructionStepId &&
                                    getCopy(
                                        collection.payment?.directBuyPayments[0]
                                            .instructionStepId
                                    )}
                            </Copy>
                        )}
                    </React.Fragment>
                );
            })}
        </Main>
    );
};
export default OpenBuyPayments;
