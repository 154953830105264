import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";

const reportEntities = (state: GlobalState) =>
    state.entities.openBuyLeaderboardReports;

export const getOpenBuySubmissionLeaderboard = createSelector(
    reportEntities,
    (state: GlobalState) => state.entities.openBuys.byId,
    (state: GlobalState, openBuyId: number) => openBuyId,
    (reports, openBuysById, openBuyId) => {
        const currentBuy = openBuysById[openBuyId];
        if (!currentBuy?.leaderboard) return null;
        return currentBuy.leaderboard.map(reportId => reports[reportId]);
    }
);

export const getOpenBuyLeaderboardTop = createSelector(
    getOpenBuySubmissionLeaderboard,
    fullLeaderboard => {
        if (!fullLeaderboard) return null;
        return fullLeaderboard.filter(report => !!report.media).slice(0, 5);
    }
);

export const getOpenBuyLeaderboardByIds = createSelector(
    reportEntities,
    (state: GlobalState, reportIds: number[]) => reportIds,
    (reportEntities, reportIds) => {
        return reportIds.map(reportId => reportEntities[reportId]);
    }
);
