import React from "react";
import styled from "styled-components";

const Main = styled.div`
    height: 45px;
    width: 45px;
    min-width: 45px;
    position: relative;
    margin-right: 15px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const PlugIcon = styled(Icon)`
    padding: 6px;
`;

const VideoIcon = styled.video`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
`;

interface Props {
    mediaUrl: string | null;
    video?: boolean;
    onClick?: (e: any) => void;
}

const AnalyticsIcon = ({ mediaUrl, video, onClick }: Props) => {
    if (!mediaUrl) {
        return (
            <Main>
                <PlugIcon src={"/logo.svg"} />
                <Overlay />
            </Main>
        );
    } else if (video) {
        return (
            <Main onClick={onClick}>
                <VideoIcon src={mediaUrl} />
                <Overlay />
            </Main>
        );
    } else {
        return (
            <Main onClick={onClick}>
                <Icon src={mediaUrl} />
                <Overlay />
            </Main>
        );
    }
};

export default AnalyticsIcon;
