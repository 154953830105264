import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { patch } from "src/Api";
import { OpenBuySubmission } from "src/buys/modules/open-buys/types";
import MediaUploader from "src/components/media/MediaUploader";
import withModalPortal from "src/components/withModalPortal";
import SmallModal from "src/modals/SmallModal";
import { BlueButton } from "src/profile/components/Buttons";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";

const StyledSmallModal = styled(SmallModal)`
    isolation: isolate;
`;

const SubmitBtn = styled(BlueButton)`
    margin-left: auto;
    padding: 8px 12px;
    margin-top: 12px;
`;

interface Props {
    submission: OpenBuySubmission;
    closeModal: VoidFunction;
    mediaId?: number;
    getSubmissionSet: VoidFunction;
}

const RepairMediaUploadModal = ({
    submission,
    closeModal,
    mediaId,
    getSubmissionSet
}: Props) => {
    const [repairMedia, setRepairMedia] = useState<null | {
        filename: string;
        type: string;
    }>(null);
    const [coverPhoto, setCoverPhoto] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    async function handleSubmit(e: React.MouseEvent) {
        e.preventDefault();
        if (!repairMedia) {
            dispatch(
                setPillNotificationText("Please upload media", "warning", 3500)
            );
            return;
        }
        if (!coverPhoto) {
            dispatch(
                setPillNotificationText("Pending cover photo", "warning", 3500)
            );
            return;
        }
        if (!mediaId) {
            dispatch(
                setPillNotificationText("Missing Media ID", "danger", 3500)
            );
            return;
        }

        setLoading(true);
        try {
            await patch(`/v1/openBuy/submission/${submission.id}/repairMedia`, {
                mediaId,
                filename: repairMedia.filename,
                type: repairMedia.type,
                coverPhoto
            });
            getSubmissionSet();
            closeModal();
        } catch (err) {
            console.error(err);
            dispatch(
                setPillNotificationText(
                    "Error uploading repair media",
                    "danger",
                    3500
                )
            );
        } finally {
            setLoading(false);
        }
    }

    return (
        <StyledSmallModal
            header={{
                text: "Repair Media with Upload",
                handleClose: closeModal
            }}
        >
            <MediaUploader
                path={"repairUpload"}
                onUpload={(id, type) => {
                    setRepairMedia({ filename: id, type });
                }}
                onRemove={() => {
                    setRepairMedia(null);
                    setCoverPhoto(null);
                }}
                onCoverPhoto={id => {
                    setCoverPhoto(id);
                }}
            />
            <SubmitBtn
                disabled={repairMedia === null && coverPhoto === null}
                onClick={handleSubmit}
                shimmer={loading}
            >
                Upload Repair Media
            </SubmitBtn>
        </StyledSmallModal>
    );
};

export default withModalPortal(RepairMediaUploadModal);
