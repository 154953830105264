import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";

export const getCurrentSocialAccountProfile = createSelector(
    (state: GlobalState) => state.entities.socialAccountProfile.byId,
    (state: GlobalState, socialAccountId: number) => socialAccountId,
    (socialAccountState, socialAccountId) => {
        return socialAccountState[socialAccountId];
    }
);
