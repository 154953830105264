import React, { useState } from "react";
import { OpenBuySubmission } from "src/buys/modules/open-buys/types";
import withModalPortal from "src/components/withModalPortal";
import CarouselModal from "src/modals/CarouselModalNew";
import styled from "styled-components";
import SubmissionApprovalModal from "./SubmissionApprovalModal";

const StyledCarouselModal = styled(CarouselModal)`
    z-index: auto;
    padding-top: 0px;
`;
interface Props {
    submissionSetIds: number[];
    closeModal: VoidFunction;
    advanceOrCloseCarousel: VoidFunction;
}

const SubmissionApprovalCarousel = ({
    submissionSetIds,
    closeModal,
    advanceOrCloseCarousel
}: Props) => {
    const [activeModal, setActiveModal] = useState(0);
    return (
        <StyledCarouselModal
            open={true}
            closeModal={closeModal}
            noMaxWidth={true}
            maxWidth="100vw"
            swipeable
            arrows
            isAdmin
            infinite
            headerText=""
            handleBeforeChange={(nextSlide, state) => {
                if (nextSlide > state.currentSlide) {
                    if (activeModal === submissionSetIds.length - 1) {
                        setActiveModal(0);
                    } else {
                        setActiveModal(activeModal + 1);
                    }
                } else {
                    if (activeModal !== 0) {
                        setActiveModal(activeModal - 1);
                    } else {
                        setActiveModal(submissionSetIds.length - 1);
                    }
                }
            }}
        >
            {submissionSetIds.map((setId, idx) => (
                <SubmissionApprovalModal
                    key={setId + idx}
                    submissionSetId={setId}
                    isActive={activeModal === idx}
                    advanceOrCloseCarousel={advanceOrCloseCarousel}
                />
            ))}
        </StyledCarouselModal>
    );
};

export default withModalPortal(SubmissionApprovalCarousel);
