import React, { useState } from "react";
import SimpleNavbar from "../../../components/SimpleNavbar";
import NavButton from "../../../components/NavButton";
import FullscreenLoader from "../../../components/FullscreenLoader";
import SelectConnectionsScreen from "../../components/SelectConnectionsScreen";
import { Channel } from "../../types";
import { Connection } from "../../../profile/types";

interface Props {
    channel: Channel;
    connections?: Connection[] | null;
    closeCallback: VoidFunction;
}

const AddMembers = ({ channel, connections, closeCallback }: Props) => {
    const [selectedConnections, setSelectedConnections] = useState<
        Connection[]
    >([]);

    return (
        <>
            <SimpleNavbar
                title="Add to Chat"
                left={<NavButton text="Cancel" onClick={closeCallback} />}
                right={
                    <NavButton
                        text="Add"
                        disabled={selectedConnections.length === 0}
                        onClick={() => {
                            if (channel) {
                                channel.sendBirdData.inviteWithUserIds(
                                    selectedConnections.map(connection => {
                                        return connection.other.id;
                                    }),
                                    (groupChannel: any, error: any) => {
                                        if (error) {
                                            window.alert(error);
                                        } else {
                                            if (error) {
                                                window.alert(error);
                                            } else {
                                                closeCallback();
                                            }
                                        }
                                    }
                                );
                            }
                        }}
                    />
                }
            />
            {!connections ? (
                <FullscreenLoader />
            ) : (
                <SelectConnectionsScreen
                    connections={connections.filter(allConnection => {
                        return !channel.connections.some(member => {
                            return member.other.id === allConnection.other.id;
                        });
                    })}
                    selectedConnections={selectedConnections}
                    setSelectedConnections={setSelectedConnections}
                />
            )}
        </>
    );
};

export default AddMembers;
