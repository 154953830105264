import React from "react";
import styled from "styled-components";
import adTagInfo from "./adTagInfo";
import { colors, fonts } from "../../constants";

export enum TagButtonsState {
    jetfuel = "jetfuel",
    ad = "ad",
    hashtag = "hashtag",
    none = "none"
}

const Button = styled.div`
    height: 30px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: ${fonts.subtitle}px;
    cursor: pointer;
    background-color: white;
    border: 1px solid ${colors.mediumTeal};
    padding: 11px 20px 11px 20px;
    margin: 0 5px 0 5px;
`;

const SelectedButton = styled(Button)`
    background-color: ${colors.primaryBlue};
    border: none;
    color: white;
`;

interface ButtonProps {
    thisTag: TagButtonsState;
    tagState: TagButtonsState;
    setTagState: (state: TagButtonsState) => void;
}

const TagButton = ({ thisTag, tagState, setTagState }: ButtonProps) => {
    if (thisTag === tagState) {
        return (
            <SelectedButton onClick={() => setTagState(thisTag)}>
                {adTagInfo[thisTag].description}
            </SelectedButton>
        );
    } else {
        return (
            <Button onClick={() => setTagState(thisTag)}>
                {adTagInfo[thisTag].description}
            </Button>
        );
    }
};

const ButtonsContainer = styled.div`
    display: flex;
    margin: auto;
`;

interface SelectorProps {
    tagState: TagButtonsState;
    setTagState: (state: TagButtonsState) => void;
}

const TagSelectorButtons = ({ tagState, setTagState }: SelectorProps) => {
    return (
        <ButtonsContainer>
            <TagButton
                thisTag={TagButtonsState.jetfuel}
                tagState={tagState}
                setTagState={setTagState}
            />
            <TagButton
                thisTag={TagButtonsState.ad}
                tagState={tagState}
                setTagState={setTagState}
            />
            <TagButton
                thisTag={TagButtonsState.hashtag}
                tagState={tagState}
                setTagState={setTagState}
            />
        </ButtonsContainer>
    );
};

export default TagSelectorButtons;
