import moment from "moment";

export const datePickerRanges: {
    [key: string]: [moment.Moment, moment.Moment];
} = {
    "3 days": [moment().subtract(3, "days"), moment()],
    "1 week": [moment().subtract(7, "days"), moment()],
    "2 weeks": [moment().subtract(14, "days"), moment()],
    "1 month": [moment().subtract(1, "month"), moment()],
    "3 months": [moment().subtract(3, "month"), moment()]
};

export const convertMomentToDateString = (
    dateRange: [moment.Moment, moment.Moment]
) => `${dateRange[0].format("LL")} – ${dateRange[1].format("LL")}`;

export const convertMomentToString = (
    dateRange: [moment.Moment, moment.Moment],
    dateStringFormat?: boolean
): string => {
    const dateString = convertMomentToDateString(dateRange);

    if (dateStringFormat) return dateString;

    let matchedLabel;

    Object.keys(datePickerRanges).forEach(label => {
        const currentRange = datePickerRanges[label];
        const currentString = `${currentRange[0].format(
            "LL"
        )} – ${currentRange[1].format("LL")}`;

        if (currentString === dateString) {
            matchedLabel = label;
        }
    });

    return matchedLabel || dateString;
};

export const convertStringToMoment = (
    dateString: string
): { label: string; moments: [moment.Moment, moment.Moment] } => {
    if (datePickerRanges[dateString]) {
        return {
            label: dateString,
            moments: datePickerRanges[dateString]
        };
    }

    let [startDate, endDate]: any = dateString.split("&");

    if (startDate && endDate) {
        startDate = moment(startDate);
        endDate = moment(endDate);

        return {
            label: `${startDate.format("LL")} – ${endDate.format("LL")}`,
            moments: [startDate, endDate]
        };
    } else {
        return {
            label: "1 week",
            moments: datePickerRanges["1 week"]
        };
    }
};
