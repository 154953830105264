import { Media } from "src/media/types";

// Media ---------------------------------------------------------------

export interface MediaRule {
    id: number;
    campaignId: number;
    rule: string;
    isAllowed: boolean;
    isCommonMistake: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface MediaPerformance {
    campaign_name: string;
    campaign_id: string;
    link_url_prefix: string;
    campaign_image_url: string;
    root_media_id: string;
    "Media.id": number;
    "Media.key": string;
    "Media.type": string;
    "Media.cover_photo": string;
    "Media.is_portrait": boolean;
    "Media.created_at": string;
    "Media.external_page_id": number | null;
    revenue: number;
    num_tests: string;
    views_actual: string;
    rpm: number;
    p75_rpm: number;
    media_url: string;
    type: string;
}

// Script ---------------------------------------------------------------

export interface Script {
    id: number;
    campaignId: number;
    uploadedByPublisherId?: number;
    title: string;
    text: string;
    assets?: number[];
    medias?: number[];
    createdAt: string;
    shouldShowInApp: boolean;
}

// Access ---------------------------------------------------------------

export interface Access {
    status: AccessState;
    expiresAt?: string;
}

export enum AccessState {
    requested = "requested",
    granted = "granted",
    pendingRemoval = "pending_removal",
    denied = "denied",
    none = "none",
    guest = "guest"
}

// Old Highlight ---------------------------------------------------------------

export interface OldHighlight {
    "DoubleMedia.cover_photo": string;
    "DoubleMedia.created_at": string;
    "DoubleMedia.external_page_id": number | null;
    "DoubleMedia.id": number;
    "DoubleMedia.is_portrait": boolean;
    "DoubleMedia.key": string;
    "DoubleMedia.type": string;
    "FeedMedia.cover_photo": string;
    "FeedMedia.created_at": string;
    "FeedMedia.external_page_id": number | null;
    "FeedMedia.id": number;
    "FeedMedia.is_portrait": boolean;
    "FeedMedia.key": string;
    "FeedMedia.type": string;
    "IGStoryMedia.cover_photo": string;
    "IGStoryMedia.created_at": string;
    "IGStoryMedia.external_page_id": number | null;
    "IGStoryMedia.id": number;
    "IGStoryMedia.is_portrait": boolean;
    "IGStoryMedia.key": string;
    "IGStoryMedia.type": string;
    "Media.cover_photo": string;
    "Media.created_at": string;
    "Media.external_page_id": number | null;
    "Media.id": number;
    "Media.is_portrait": boolean;
    "Media.key": string;
    "Media.type": string;
    "SingleMedia.cover_photo": string;
    "SingleMedia.created_at": string;
    "SingleMedia.external_page_id": number | null;
    "SingleMedia.id": number;
    "SingleMedia.is_portrait": boolean;
    "SingleMedia.key": string;
    "SingleMedia.type": string;
    account_theme: string;
    campaign_id: string;
    campaign_name: string;
    dollars_per_k: string;
    external_page_id: number | null;
    highlights_page_id: number;
    image_url: string;
    link_url_prefix: string;
    medias_id: string;
    platform: string;
    publisher_id: number;
    purchase_type: string;
    revenue: number | null;
    views_actual: string;
}

// Highlight ---------------------------------------------------------------

export interface Highlight {
    id: number;
    platform: string;
    revenue: number;
    media: number;
    singleMedia: number | null;
    doubleMedia: number | null;
    IGStoryMedia: number | null;
    feedMedia: number | null;
    placement: Placement;
    tags: string[];
    createdAt: string;
    externalPageId: number | null;
    purchaseType: string | null;
    accountTheme: string | null;
    campaignId: string;
    campaignName: string;
    imageUrl: string;
    linkUrlPrefix: string;
    viewsActual: string;
    startTime: string;
    endTime: string;
    alwaysShow: boolean;
    dollarsPerK: string;
    testId: number;
    publisherId: number;
    mediasId: string;
    testCreatedAt: string;
    metrics: Metric[];
    highlightsPageId: number;
}

export interface HighlightMedia {
    coverPhoto: string;
    coverPhotoUrl: string | null;
    createdAt: string;
    externalPageId: number | null;
    id: number;
    isPortrait: boolean;
    key: string;
    type: "photo" | "video";
    url: string;
}

// Caption ---------------------------------------------------------------

export interface Caption {
    id: number;
    campaignId: number;
    text: string;
    createdAt: string;
}

// Placement ---------------------------------------------------------------

export interface Placement {
    id: number;
    type: string;
    logo: string;
    grayscaleLogo: string;
    platform: string;
    descriptor: string | null;
}

// Metric ---------------------------------------------------------------

export interface Metric {
    name: string;
    value: string;
    descriptor: string;
    icon: string;
    isTitle: boolean;
    isPrimary: boolean;
}

// Asset ---------------------------------------------------------------

export interface Asset {
    id: number;
    type: "photo" | "video" | "sound";
    advertiserId: number;
    coverPhotoUrl: string;
    url: string;
}

// Install History ---------------------------------------------------------------

export interface InstallHistory {
    platform: string; // ios, android
    countries: string[];
    counts: string;
    percent: string;
    reward: number;
    campaignId: number;
}

// Old Campaign ---------------------------------------------------------------

export interface OldCampaign {
    id: number;
    campaign_name: string;
    country_platform_reward?: {
        country: string;
        platform: string;
        reward: number;
    };
    start_time: string;
    end_time: string | null;
    image_url: string;
    ios_app_id: number;
    ios_app_url: string;
    android_app_id: number;
    android_app_url: string;
    reward: number;
    rewardData: RewardData[];
    reward_type: string;
    balance: number;
    balance_claimed: number;
    have_notified_of_finish: boolean;
    link_service: string;
    link_url_prefix: string;
    ios_tracker_id: string;
    advertiser_id: number;
    ios_us_reward: number;
    ios_english_reward: number;
    android_us_reward: number;
    android_english_reward: number;
    android_row_reward: number;
    color: string;
    app_icon_url: string;
    hourly_coins_per_publisher: number;
    campaign_subtitle: string;
    star_rating: number;
    num_star_ratings: number;
    app_type: string;
    tags: string[];
    custom_media_ok: boolean;
    is_testing: boolean;
    Media: Media[];
    ExternalPages: OldExternalPage[];
}

export interface OldExternalPage {
    id: number;
    start_time: string;
    end_time: string | null;
    url: string;
    url_parameters: string;
    image_url: string;
    title: string;
    rank: number;
    campaign_id: number;
    Media: Media[];
}

// Campaign ---------------------------------------------------------------

export interface Campaign {
    id: number;
    reward: number;
    balance: number;
    color: string;
    tags: string[];
    description: string | null;
    state: string; // limited
    campaignName: string;
    startTime: string;
    endTime: string | null;
    imageUrl: string;
    iosAppId: number;
    iosAppUrl: string;
    androidAppId: number;
    androidAppUrl: string;
    rewardType: string; // install
    balanceClaimed: number;
    haveNotifiedOfFinish: boolean;
    linkService: string;
    linkUrlPrefix: string;
    iosTrackerId: string | null;
    androidTrackerId: string | null;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    advertiserId: number;
    iosUsReward: number;
    iosEnglishReward: number;
    iosRowReward: number;
    androidUsReward: number;
    androidEnglishReward: number;
    androidRowReward: number;
    appIconUrl: string | null;
    hourlyCoinsPerPublisher: number;
    iosBundleId: string | null;
    androidBundleId: string | null;
    iosNetworkCampaignName: string;
    androidNetworkCampaignName: string;
    campaignSubtitle: string | null;
    doNotUpdateHourlyCoinsAt: string;
    starRating: number;
    numStarRatings: number;
    appType: string;
    iosTrackingLink: string | null;
    androidTrackingLink: string | null;
    customMediaOk: boolean;
    isTesting: boolean;
    pendingRestart: boolean | null;
    iosAppStoreGenres: null;
    iosAppStorePrimaryGenre: null;
    advertiserName: string;

    // rewardData: RewardData[];
    // Media: Media[];
    ExternalPages: ExternalPage[];

    countryPlatformReward: CountryPlatformReward;
    mediaRules?: number[];
    scripts?: number[];
    highlights?: number[];
    assets?: number[];
    medias?: number[];
    daysActive?: number;
    total?: {
        plugcoins: number;
        dollars: number;
    };
    installHistory?: InstallHistory[];
    captions?: number[];
    access?: Access;
    customLinks?: number[];
    budget?: Budget;
    statistics?: Statistic;
}

export interface Statistic {
    type: string;
    stats: string;
}

export interface Budget {
    startDate: string;
    endDate: string;
    budgetLimit: number;
    budgetExtension: number | null;
    budgetSpend: number;
    isLow: boolean;
}

//update when backend updates to camel case
export interface CountryPlatformReward {
    country: string;
    platform: string;
    reward: number;
    rewardMin: number;
    rewardMax: number;
    previous_reward?: number | null;
    boost_expires_at?: string | null;
}

export interface NewPrice {
    country: string;
    platform: string;
    reward: number;
    rewardMin: number;
    rewardMax: number;
    previousReward?: number | null;
    boostExpiresAt?: string | null;
}

export interface CampaignMedia {
    id: number;
    key: string;
    type: string;
    name: string | null;
    reward: string | null;
    url: string;
    time: number;
    tags: string[] | null;
    username: string | null;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    campaignId: number;
    coverPhoto: string;
    isPortrait: boolean;
    publisherId: string | null;
    installsPerView: number;
    isEndCard: boolean;
    shoutoutPurchaseId: number | null;
    alternateMediaId: number | null;
    niceKey: string;
    shouldShowInApp: boolean;
    shouldShowOnHighlightsPage: boolean;
    deeplinkUrl: string | null;
    externalPageId: number | null;
    uploadedByPublisherId: string | null;
    uploadedByEmployeeId: string | null;
    allowOthersToUse: boolean | null;
    mediaStatus: "approved" | "rejected" | "pending";
    rejectionReason: string | null;
    approvedAt: string | null;
    shouldPayCommission: boolean | null;
    doNotPayCommission: boolean | null;
    needsTesting: boolean;
    originalUploadedKey: string | null;
    movKey: string | null;
    imageHash: string | null;
    pinnedUntil: string | null;
    customLinkForPublisherId: string | null;
    coverPhotoUrl: string;
    linkUrlPrefix: string;
    campaignName: string;
    campaignImageUrl: string;
    campaignRewardtype: string;
    placementIds: number[];
}

export interface RewardData {
    campaign_id: number;
    countries: string[];
    counts: string;
    percent: string;
    platform: string;
    reward: number;
}

export interface ExternalPage {
    id: number;
    start_time: string;
    end_time: string | null;
    url: string;
    urlParameters: string;
    imageUrl: string;
    title: string;
    rank: number;
    campaignId: number;
    Media: Media[];
}

// Campaign Filters ---------------------------------------------------------------
export interface Genre {
    name: string;
}

export interface Country {
    name: string;
    code: string;
}

export interface Selected {
    [key: string]: boolean;
}

export interface CampaignFilters {
    bidLow: number; // 0 or below
    bidHigh: number; // Max is anything over $10
    genres: string[];
    budgetLow: boolean;
    budgetNotLow: boolean;
    regions: string[];
}
