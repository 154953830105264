import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Country } from "src/campaigns/types";
import SelectionButton from "src/components/SelectionButton";
import { codeToEmoji } from "src/notifications/CodeToEmoji";
import { GlobalState } from "src/reducers";
import { selectCountries } from "src/ui/campaigns/selectors";
import { checkSelected, toggleFilterSelection } from "../utils/helpers";

interface Props {
    searchString: string;
    filterType: "Creator Country/Region" | "Audience Country/Region";
}

const CountryRegionFilterOptions = ({ searchString, filterType }: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const countries = useSelector(selectCountries);

    const {
        creatorCountryRegionFilters,
        audienceCountryRegionFilters
    } = useSelector((state: GlobalState) => {
        return state.ui.creators;
    });

    // JSX -----------------------------------------------------------
    return (
        <>
            {countries
                .filter((country: Country) => {
                    if (searchString === "") {
                        return true;
                    } else {
                        return country.name
                            .toLowerCase()
                            .includes(searchString.toLowerCase());
                    }
                })
                .map((country: Country, index: number) => {
                    return (
                        <SelectionButton
                            key={`marketplace-creator-groups-${filterType}-${country.name}-${index}`}
                            icon={codeToEmoji[country.code]}
                            text={country.name}
                            selected={checkSelected(
                                filterType,
                                country.code,
                                filterType === "Creator Country/Region"
                                    ? creatorCountryRegionFilters
                                    : audienceCountryRegionFilters
                            )}
                            setSelected={() =>
                                toggleFilterSelection(
                                    filterType,
                                    country.code,
                                    dispatch,
                                    filterType === "Creator Country/Region"
                                        ? creatorCountryRegionFilters
                                        : audienceCountryRegionFilters,
                                    country.name
                                )
                            }
                        />
                    );
                })}
        </>
    );
};

export default CountryRegionFilterOptions;
