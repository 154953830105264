import { schema } from "normalizr";

export const campaign = new schema.Entity("campaigns");
export const campaignList = new schema.Array(campaign);

export const mediaRule = new schema.Entity("mediaRules");
export const mediaRuleList = new schema.Array(mediaRule);

export const script = new schema.Entity("scripts");
export const scriptList = new schema.Array(script);

const highlightMedia = new schema.Entity("highlightMedias");

export const highlight = new schema.Entity(
    "highlights",
    {
        media: highlightMedia,
        singleMedia: highlightMedia,
        doubleMedia: highlightMedia,
        IGStoryMedia: highlightMedia,
        feedMedia: highlightMedia
    },
    {
        idAttribute: "highlightsPageId"
    }
);
export const highlightList = new schema.Array(highlight);

export const asset = new schema.Entity("assets");
export const assetList = new schema.Array(asset);

export const caption = new schema.Entity("captions");
export const captionList = new schema.Array(caption);

export const media = new schema.Entity("medias");
export const mediaList = new schema.Array(media);

export const customLink = new schema.Entity("customLinks");
export const customLinkList = new schema.Array(customLink);

export const placement = new schema.Entity("placements");
export const placementList = new schema.Array(placement);

export const contest = new schema.Entity("contests");
export const contestList = new schema.Array(contest);
