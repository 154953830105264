import React from "react";
import { AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";
import HalfScreenModal from "src/modals/HalfScreenModal";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors, fonts } from "src/constants";
import { fetchCampaigns } from "src/campaigns/actions";
import { incrementCampaignMaxItems } from "src/ui/campaigns/actions";
import ActionsModal from "src/modals/ActionsModal";

interface Props {
    onClose: () => void;
    onSelect: (value: string) => void;
    open: boolean;
    type: string;
}

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
`;

const Title = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 700;
    white-space: nowrap;
    padding-bottom: 20px;
`;

const Option = styled.div`
    display: flex;
    flex-direction: row;
    font-size: ${fonts.subtitle}px;
    justify-content: space-between;
    padding: 26px 20px 26px 20px;
    border-bottom: 1px solid ${colors.mediumTeal};
    cursor: pointer;
`;

const LinkReplacementImageSelector = ({
    onClose,
    onSelect,
    open,
    type
}: Props) => {
    const getName = () => {
        switch (type) {
            case "campaign":
                return "Use Campaign Icon";
            case "social":
                return `Use Platform Icon`;
            case "referral":
                return "Use Link Default";
            default:
                return "Use Default Icon";
        }
    };
    const options = [
        { callback: () => onSelect("default"), text: getName() },
        { callback: () => onSelect("custom"), text: "Upload Custom Image" },
        { callback: () => onSelect("none"), text: "No Image" }
    ];
    return (
        <AnimatePresence>
            {open && (
                <ActionsModal actions={options} localClearModal={onClose} />
            )}
        </AnimatePresence>
    );
};

export default LinkReplacementImageSelector;
