import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import * as Papa from "papaparse";
import styled from "styled-components";
import ChallengeFormSection from "./ChallengeFormSection";
import { get, patch, post } from "src/Api";
import { GrayButton } from "src/profile/components/Buttons";
import { EventInput } from "src/utils/types/form";
import ChallengeRulesDragAndDrop from "./ChallengeRulesDragAndDrop";
import { grayBackgroundHoverState } from "src/utils/styles/snippets";
import { colors } from "src/constants";
import { useDispatch } from "react-redux";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { useParams } from "react-router";
import { Rule, RuleState } from "src/types/challenge/Rule";
import { RuleSetResponse } from "src/types/challenge/RuleSetResponse";

const StyledGrayButton = styled(GrayButton)`
    align-self: flex-start;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px 12px;
`;

const UploadCSVButton = styled.label`
    ${grayBackgroundHoverState()};
    padding: 8px 12px;
    color: ${colors.grayBlack};
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    margin: 0;
`;

const CSVInput = styled.input`
    display: none;
`;

const InfoBox = styled.div`
    background: ${colors.noteYellow};
    padding: 8px 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
    text-align: left;
`;

export type ChallengeRulesRef = {
    handleSaveRules: () => void;
};

interface Props {
    ruleSetId: number | null;
    setHasRuleChanges: (changes: boolean) => void;
    displayStandards?: boolean;
    challengeName?: string;
}

const ChallengeRules = (
    { ruleSetId, setHasRuleChanges, displayStandards, challengeName }: Props,
    ref: React.Ref<ChallengeRulesRef>
) => {
    const dispatch = useDispatch();
    const { openBuyId } = useParams<{ openBuyId: string }>();
    const [dos, setDos] = useState<string[]>([]);
    const [donts, setDonts] = useState<string[]>([]);
    const [generals, setGenerals] = useState<string[]>([]);
    const [normalizedRules, setNormalizedRules] = useState<{
        [id: string]: Rule;
    }>({});

    const handleSaveRules = async () => {
        try {
            let createdRuleSetId = ruleSetId;
            if (!createdRuleSetId && openBuyId) {
                const createRes = await post("/v1/ruleSet", {
                    title: `${challengeName} Rules`,
                    description: `Please read and follow the rules below`
                });
                createdRuleSetId = createRes.data.data?.id;
                if (!createdRuleSetId)
                    throw Error("Error creating empty rule set");
                await patch(`/v1/openBuy/${openBuyId}`, {
                    ruleSetId: createdRuleSetId
                });
            }

            const ruleIds = [...dos, ...donts, ...generals];
            const rulesToUpdate = ruleIds.map(ruleId => ({
                rule: normalizedRules[ruleId].rule,
                title: normalizedRules[ruleId].title,
                icon: normalizedRules[ruleId].icon
            }));
            const res = await patch(
                `/v1/ruleSet/${createdRuleSetId}/allRules`,
                {
                    rules: rulesToUpdate
                }
            );
            processRules(res.data.data);
            dispatch(
                setPillNotificationText(
                    `Successfully updated rule set.`,
                    "success",
                    3500
                )
            );
        } catch (error) {
            dispatch(
                setPillNotificationText(
                    `Error updating Rule Set, Error: ${error}`,
                    "danger",
                    3500
                )
            );
            fetchRules();
        } finally {
            setHasRuleChanges(false);
        }
    };

    useImperativeHandle(ref, () => ({
        handleSaveRules
    }));

    const fetchRules = async () => {
        try {
            let res = await get(`v1/ruleSet/${ruleSetId}`, {});
            processRules(res.data.data);
        } catch (err) {
            console.error(err);
        }
    };

    const processRules = (res?: RuleSetResponse) => {
        let dos: string[] = [];
        let donts: string[] = [];
        let generals: string[] = [];
        const normalizedRules: RuleState = {};
        res?.Rules.forEach((rule: Rule) => {
            normalizedRules[rule.id] = rule;
            if (rule.icon === "x") donts.push(rule.id);
            else if (rule.icon === "check") dos.push(rule.id);
            else if (rule.icon === "thumb") generals.push(rule.id);
        });

        setNormalizedRules(normalizedRules);
        setDonts(donts);
        setDos(dos);
        setGenerals(generals);
    };

    const handleUpdateRuleFactory = (
        ruleId: string,
        targetKey: "title" | "rule"
    ) => {
        return (e: EventInput) => {
            const input = e.currentTarget.value;
            const newNormalizedRules = { ...normalizedRules };
            newNormalizedRules[ruleId][targetKey] = input;
            setNormalizedRules(newNormalizedRules);
            setHasRuleChanges(true);
        };
    };

    const handleDeleteRuleFactory = (ruleId: string) => {
        return () => {
            setDos(dos.filter(e => e !== ruleId));
            setDonts(donts.filter(e => e !== ruleId));
            setGenerals(generals.filter(e => e !== ruleId));
            setHasRuleChanges(true);
        };
    };

    const handleCreateRuleFactory = (type: "x" | "check" | "thumb") => {
        return () => {
            const id = "T" + new Date().getTime();
            const icon = type;
            const rule = "Placeholder for new rule";
            const title =
                type === "check"
                    ? "Do -"
                    : type === "x"
                    ? "Do Not —"
                    : "Plug Media Standard Title";
            if (type === "check") {
                setDos([...dos, id]);
            } else if (type === "x") {
                setDonts([...donts, id]);
            } else {
                setGenerals([...generals, id]);
            }
            setNormalizedRules({
                ...normalizedRules,
                [id]: { id, icon, rule, title }
            });
            setHasRuleChanges(true);
        };
    };

    const handleCSVUpload = (e: EventInput) => {
        e.preventDefault();
        const files = e.currentTarget.files;
        if (files && files.length > 0) {
            const file = files[0];
            Papa.parse(file, {
                complete: (response: any) => {
                    if (response.data.length > 0) {
                        const csvFileColumns = response.data[0];
                        const requiredColumns = ["title", "rule", "icon"];
                        if (
                            !requiredColumns.every(col =>
                                csvFileColumns.includes(col)
                            )
                        ) {
                            dispatch(
                                setPillNotificationText(
                                    'Rules CSV does not contain all required columns "title", "rule", "icon"',
                                    "danger",
                                    3500
                                )
                            );
                        } else {
                            const body = response.data.slice(1);
                            const titleIndex = csvFileColumns.indexOf("title");
                            const ruleIndex = csvFileColumns.indexOf("rule");
                            const iconIndex = csvFileColumns.indexOf("icon");
                            const acceptedIcons = ["x", "check", "thumb"];
                            const parsedRules: Rule[] = [];
                            for (
                                let index = 0;
                                index < body.length;
                                index += 1
                            ) {
                                let row = body[index];
                                if (!acceptedIcons.includes(row[iconIndex])) {
                                    dispatch(
                                        setPillNotificationText(
                                            '"CSV icon field contains values other than "x", "check", "thumb", import failed',
                                            "danger",
                                            3500
                                        )
                                    );
                                    return;
                                }
                                parsedRules.push({
                                    title: row[titleIndex],
                                    rule: row[ruleIndex],
                                    icon: row[iconIndex],
                                    id: "T" + index
                                });
                            }
                            processRules({
                                Rules: parsedRules,
                                title: "imported from csv",
                                id: "temp",
                                description:
                                    "This rule set was imported via csv"
                            });
                            setHasRuleChanges(true);
                            dispatch(
                                setPillNotificationText(
                                    "Successfully imported CSV",
                                    "success",
                                    3500
                                )
                            );
                        }
                    }
                }
            });
        }
    };

    useEffect(() => {
        if (ruleSetId) fetchRules();
    }, []);

    return (
        <ChallengeFormSection
            title="Challenge Rules"
            Action={
                <UploadCSVButton>
                    <CSVInput
                        type="file"
                        accept=".csv"
                        onChange={handleCSVUpload}
                    />
                    Import from CSV
                </UploadCSVButton>
            }
        >
            <InfoBox>
                To import from CSV, the required columns and options are
                <div>
                    <strong>title:</strong> "Do -", "Do Not -", or a plug
                    standard title
                </div>
                <div>
                    <strong>icon:</strong> "x", "check", "thumb"
                </div>
                <div>
                    <strong>rule:</strong> the display text for the rule
                </div>
            </InfoBox>
            {displayStandards && (
                <>
                    <ChallengeRulesDragAndDrop
                        title="Plug Media Standards"
                        ruleIds={generals}
                        setRuleIds={setGenerals}
                        emoji="👍"
                        droppableId="plug-media-standards"
                        handleDeleteRuleFactory={handleDeleteRuleFactory}
                        handleUpdateRuleFactory={handleUpdateRuleFactory}
                        normalizedRules={normalizedRules}
                        setHasRuleChanges={setHasRuleChanges}
                        editTitle
                    />
                    <StyledGrayButton
                        type="button"
                        onClick={handleCreateRuleFactory("thumb")}
                    >
                        New Plug Media Standard
                    </StyledGrayButton>
                </>
            )}
            <ChallengeRulesDragAndDrop
                title="Here's what to do"
                ruleIds={dos}
                setRuleIds={setDos}
                emoji="✅"
                droppableId="challenge-rules-do"
                handleDeleteRuleFactory={handleDeleteRuleFactory}
                handleUpdateRuleFactory={handleUpdateRuleFactory}
                normalizedRules={normalizedRules}
                setHasRuleChanges={setHasRuleChanges}
            />
            <StyledGrayButton
                type="button"
                onClick={handleCreateRuleFactory("check")}
            >
                New Do Item
            </StyledGrayButton>
            <ChallengeRulesDragAndDrop
                title="Here's what NOT to do"
                ruleIds={donts}
                setRuleIds={setDonts}
                emoji="🚫"
                droppableId="challenge-rules-dont"
                handleDeleteRuleFactory={handleDeleteRuleFactory}
                handleUpdateRuleFactory={handleUpdateRuleFactory}
                normalizedRules={normalizedRules}
                setHasRuleChanges={setHasRuleChanges}
            />
            <StyledGrayButton
                type="button"
                onClick={handleCreateRuleFactory("x")}
            >
                New Do Not Item
            </StyledGrayButton>
        </ChallengeFormSection>
    );
};
export default forwardRef(ChallengeRules);
