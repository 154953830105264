import React from "react";
import styled from "styled-components";
import { ReactComponent as RightCaret } from "src/components/icons/right-caret.svg";
import { colors, sizes, fonts } from "src/constants";
import { FlexCentered } from "src/components/CommonStyles";
import { useDispatch } from "react-redux";
import { setMediaFilters } from "src/pagination/media/actions";
import { initialMediaFilters } from "src/pagination/media/reducer";
import { push } from "connected-react-router";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: ${sizes.mediaItemWidth}px;
    height: ${sizes.mediaItemHeight}px;
    color: ${colors.primaryGray};
    border-radius: 6px;
    font-size: ${fonts.smallTitle}px;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    user-select: none;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.blueGrey1};
        }
    }
`;

const Icon = styled(RightCaret)`
    margin-left: 2px;
`;

const IconWrapper = styled.div`
    ${FlexCentered}
    border: 1px solid ${colors.mediumTeal};
	background-color: ${colors.white};
    width: 46px;
    height: 46px;
    border-radius: 23px;
	margin-bottom: 10px;
`;

interface Props {
    campaignId: number;
    campaignName: string;
    placements?: { [platform: string]: boolean };
}

const MediaSearchButton = ({ campaignId, campaignName, placements }: Props) => {
    const dispatch = useDispatch();

    function setFilterRedirect() {
        dispatch(
            setMediaFilters({
                ...initialMediaFilters,
                selectedCampaigns: { [campaignId]: campaignName },
                placements: placements ? placements : {}
            })
        );
        dispatch(push("/media"));
    }

    return (
        <Main onClick={setFilterRedirect}>
            <IconWrapper>
                <Icon fill={colors.primaryBlue} />
            </IconWrapper>
            See all media
        </Main>
    );
};

export default MediaSearchButton;
