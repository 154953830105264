import React, { useState } from "react";
import styled from "styled-components";
import HalfScreenModal from "./HalfScreenModal";
import Divider from "../components/Divider";
import { colors, fonts } from "../constants";

const Title = styled.div`
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fonts.subtitle}px;
    font-weight: 700;
`;

const OptionList = styled.div`
    border: 1px solid ${colors.mediumTeal};
    border-radius: 10px;
    margin: 20px 16px;
    max-height: 60vh;
    overflow-y: auto;

    & > * ~ * {
        border-top: 1px solid ${colors.mediumTeal};
    }
`;

const OptionItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
`;

const Cancel = styled.div`
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
`;

const Save = styled(Cancel)`
    background-color: ${colors.primaryBlue};
    color: white;
`;

export interface SelectOption {
    value: string;
    name?: string;
}

interface Props {
    title: string;
    options: SelectOption[];
    onSave: (selection: string[]) => void;
    clearModal: () => void;
    initialSelection?: string[];
    isMulti?: true;
    notEmpty?: true;
}

const HalfScreenSelectModal = ({
    title,
    options,
    onSave,
    clearModal,
    initialSelection,
    isMulti,
    notEmpty
}: Props) => {
    const [currentSelection, setCurrentSelection] = useState(() => {
        if (!initialSelection || initialSelection.length === 0) {
            return null;
        } else if (isMulti) {
            return new Set(initialSelection);
        } else {
            return initialSelection[0];
        }
    });

    function isSelected(value: string): boolean {
        if (!currentSelection) {
            return false;
        } else if (typeof currentSelection === "string") {
            return value === currentSelection;
        } else {
            return currentSelection.has(value);
        }
    }

    function toggleSelected(value: string) {
        if (!currentSelection) {
            if (isMulti) {
                setCurrentSelection(new Set([value]));
            } else {
                setCurrentSelection(value);
            }
        } else if (typeof currentSelection === "string") {
            if (currentSelection === value) {
                setCurrentSelection(null);
            } else {
                setCurrentSelection(value);
            }
        } else {
            const newSet = new Set(currentSelection);
            if (newSet.has(value)) {
                newSet.delete(value);
            } else {
                newSet.add(value);
            }
            setCurrentSelection(newSet);
        }
    }

    function getReturnSelection(): string[] {
        if (!currentSelection) {
            return [];
        } else if (typeof currentSelection === "string") {
            return [currentSelection];
        } else {
            return Array.from(currentSelection);
        }
    }

    function allowSave(): boolean {
        const returnSelection = getReturnSelection();
        if (notEmpty && returnSelection.length === 0) {
            return false;
        } else if (!initialSelection || initialSelection.length === 0) {
            return returnSelection.length > 0;
        } else if (initialSelection.length !== returnSelection.length) {
            return true;
        } else {
            const sortedInitial = [...initialSelection].sort();
            const sortedReturn = [...returnSelection].sort();
            for (let i = 0; i < sortedInitial.length; i++) {
                if (sortedInitial[0] !== sortedReturn[0]) {
                    return true;
                }
            }
            return false;
        }
    }

    function saveAndClose() {
        onSave(getReturnSelection());
        clearModal();
    }

    return (
        <HalfScreenModal closeModal={clearModal}>
            <Title>{title}</Title>
            <OptionList>
                {options.map(option => {
                    return (
                        <OptionItem
                            key={option.value}
                            onClick={() => {
                                toggleSelected(option.value);
                            }}
                        >
                            {option.name || option.value}
                            {isSelected(option.value) ? (
                                <img
                                    src="/checkbox_selected.svg"
                                    alt="checked"
                                />
                            ) : (
                                <img
                                    src="/checkbox_unselected.svg"
                                    alt="unchecked"
                                />
                            )}
                        </OptionItem>
                    );
                })}
            </OptionList>
            <Divider />
            {allowSave() ? (
                <Save onClick={saveAndClose}>Done</Save>
            ) : (
                <Cancel onClick={clearModal}>Cancel</Cancel>
            )}
        </HalfScreenModal>
    );
};

export default HalfScreenSelectModal;
