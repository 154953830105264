import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "src/reducers";
import { Action, NormalizedAction } from "src/types";

// All Payment Set Criteria --------------------------------------------------------------

export const FETCH_CRITERIA = {
    REQUEST: "FETCH_CRITERIA_REQUEST",
    SUCCESS: "FETCH_CRITERIA_SUCCESS",
    FAILURE: "FETCH_CRITERIA_FAILURE"
};

export const fetchCriteria = (): Action => ({
    type: FETCH_CRITERIA.REQUEST,
    payload: null
});

export const fetchCriteriaSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>
): NormalizedAction => ({
    type: FETCH_CRITERIA.SUCCESS,
    response
});

export const fetchCriteriaFailure = (error: string): Action => ({
    type: FETCH_CRITERIA.FAILURE,
    payload: { error }
});
