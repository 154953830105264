import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { useMeasure } from "react-use";
import SpaceBetween from "src/components/SpaceBetween";
import { Title } from "src/campaigns/details/Details.styled";
import { breakpoints } from "src/constants";
import { hideScrollbar } from "src/utils/styles/snippets";
import SpinCaret from "src/components/icons/SpinCaret";

const Main = styled(SpaceBetween)`
    padding: 18px;
    cursor: pointer;
`;

const Caret = styled(animated.img)`
    width: 12px;
    cursor: pointer;
`;

const Wrapper = styled(animated.div)`
    position: relative;
    overflow: hidden;

    @media (min-width: ${breakpoints.mobile}px) {
        ${hideScrollbar()};
    }
`;

interface Props {
    title?: string;
    customHeader?: React.ReactNode;
    initiallyExpanded?: boolean;
    initialHeight?: number;
    manualControl?: boolean;
    onToggle?: (value: boolean) => void;
}

const withAccordion = <P extends object>(Component: React.ComponentType<P>) => (
    props: Props & P
) => {
    const {
        title,
        initiallyExpanded,
        initialHeight,
        manualControl,
        onToggle,
        customHeader,
        ...rest
    } = props;

    const defaultHeight = 0;

    const [open, toggle] = useState(initiallyExpanded);
    const [contentHeight, setContentHeight] = useState<number>(
        initialHeight || 0
    );

    useEffect(() => {
        if (manualControl !== undefined) toggle(manualControl);
    }, [manualControl]);

    const [ref, { height }] = useMeasure<HTMLDivElement>();

    const expand = useSpring({
        config: { mass: 1, tension: 170, friction: 26 },
        height: open ? `${contentHeight}px` : `${defaultHeight}px`
    });

    const handleContentHeight = useCallback(() => setContentHeight(height), []);

    useEffect(() => {
        setContentHeight(height);

        window.addEventListener("resize", handleContentHeight);

        return window.removeEventListener("resize", handleContentHeight);
    }, [height]);

    useEffect(() => {
        if (initiallyExpanded !== open) toggle(initiallyExpanded);
    }, [initiallyExpanded]);

    return (
        <>
            <Main
                onClick={() => {
                    toggle(!open);
                    onToggle && onToggle(!open);
                }}
            >
                {customHeader ? customHeader : <Title>{title}</Title>}
                <SpinCaret open={open} />
            </Main>
            <Wrapper style={expand}>
                <div ref={ref}>
                    <Component {...(rest as P)} />
                </div>
            </Wrapper>
        </>
    );
};

export default withAccordion;
