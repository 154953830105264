import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { PlatformType } from "./Platforms";
import { ThemeType } from "./Themes";

interface Props {
    title: string;
    categoryObj: { [key: string]: PlatformType } | { [key: string]: ThemeType };
    tags: string[];
    currentTag: string | null;
    onSelectTag: (tag: string) => void;
}

const TitleLabel = styled.h5`
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: left;
`;
const TagsContainer = styled.div`
    flex-wrap: wrap;
    flex-direction: row;
    text-align: left;
`;
const TagButton = styled(Button)`
    margin: 0px 7px 7px 0px;
`;
const TagPicker = ({
    title,
    categoryObj,
    tags,
    currentTag,
    onSelectTag
}: Props) => {
    function renderTagButton(t: string, idx: number) {
        const variant = currentTag === t ? "primary" : "outline-primary";
        return (
            <TagButton
                size="sm"
                variant={variant}
                onClick={() => onSelectTag(t)}
                key={idx}
            >
                {categoryObj[t].title}
            </TagButton>
        );
    }

    return (
        <div>
            <TitleLabel>{title}</TitleLabel>
            <TagsContainer>
                {tags.map((t, idx) => renderTagButton(t, idx))}
            </TagsContainer>
        </div>
    );
};

export default TagPicker;
