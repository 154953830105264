import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    MagicPhoneAuthParams,
    PhoneNumberParams,
    postMagicPhoneAuth,
    postPhoneNumber,
    postPhoneNumberCall
} from "../actions";
import { push } from "connected-react-router";
import { Route, Switch } from "react-router";
import { loggedIn, magicInProgress } from "../../util";
import PhoneNumberScreen from "../components/PhoneNumberScreen";
import PhoneAuthScreen from "../components/PhoneAuthScreen";
import { colors } from "../../constants";

interface Props {
    postPhoneNumber: (phoneNumberParams: PhoneNumberParams) => void;
    postPhoneNumberResend: (phoneNumberParams: PhoneNumberParams) => void;
    postPhoneNumberCall: (phoneNumberParams: PhoneNumberParams) => void;
    postMagicPhoneAuth: (params: MagicPhoneAuthParams) => void;
    push: (route: string) => void;
}

const PhoneAuth = ({
    postPhoneNumber,
    postPhoneNumberResend,
    postPhoneNumberCall,
    postMagicPhoneAuth,
    push
}: Props) => {
    const magicToken = localStorage.getItem("magicToken");

    if (!magicInProgress()) {
        push("/onboarding");
    } else if (loggedIn()) {
        push("/onboarding");
    }

    useEffect(() => {
        document.body.style.backgroundColor = colors.primaryBlue;

        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [authCode, setAuthCode] = useState("");

    useEffect(() => {
        if (authCode.length === 4 && phoneNumber.length > 0 && magicToken) {
            postMagicPhoneAuth({ phoneNumber, authCode, magicToken });
        }
    }, [authCode]);

    return (
        <Switch>
            <Route
                path="/approved/phone/auth"
                render={() => {
                    return (
                        <PhoneAuthScreen
                            backClicked={() => push("/approved/phone")}
                            phoneNumber={phoneNumber}
                            authCode={authCode}
                            setAuthCode={setAuthCode}
                            resendClicked={() =>
                                postPhoneNumberResend({ phoneNumber })
                            }
                            callClicked={() =>
                                postPhoneNumberCall({ phoneNumber })
                            }
                        />
                    );
                }}
            />
            <Route
                path="/approved/phone"
                render={props => {
                    return (
                        <PhoneNumberScreen
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            nextClicked={() => postPhoneNumber({ phoneNumber })}
                        />
                    );
                }}
            />
        </Switch>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    postPhoneNumber: (phoneNumberRequest: PhoneNumberParams) =>
        dispatch(postPhoneNumber(phoneNumberRequest)),
    postPhoneNumberResend: (phoneNumberRequest: PhoneNumberParams) =>
        dispatch(postPhoneNumber(phoneNumberRequest)),
    postPhoneNumberCall: (phoneNumberRequest: PhoneNumberParams) =>
        dispatch(postPhoneNumberCall(phoneNumberRequest)),
    postMagicPhoneAuth: (params: MagicPhoneAuthParams) =>
        dispatch(postMagicPhoneAuth(params)),
    push: (route: string) => dispatch(push(route))
});

export default connect(null, mapDispatchToProps)(PhoneAuth);
