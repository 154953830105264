import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import ReferredUser from "src/profile/components/ReferredUser";
import XCard from "./XCard";
import { ReferredUser as ReferredUserType } from "src/profile/types";
import { getReferredUsers } from "src/profile/referrals/selectors";
import { setReferredUserModal } from "src/ui/referrals/actions";
import { OutlineButton } from "./Buttons";
import { GlobalState } from "src/reducers";
import { colors } from "src/constants";
import { flexRowCenter } from "src/utils/styles/snippets";

const Main = styled("div")`
    padding: 0px 18px 18px 18px;
`;

const NumberReferredUsers = styled("p")`
    margin: 5px 0px 12px 0px;
    padding: 0px;
    color: ${colors.primaryGray};
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 0.895rem;
    text-align: start;
`;

interface ReferralProps {
    has_referrals: boolean;
}

const ReferralCTABox = styled("div")<ReferralProps>`
    display: ${props => (props.has_referrals ? "none" : "block")};
`;

const ReferredUsersListContainer = styled("div")<ReferralProps>`
    display: ${props => (props.has_referrals ? "grid" : "none")};
    grid-template-columns: 1;
    grid-gap: 10px;
`;

interface ButtonProps {
    referral_count: number;
}

const SeeMoreButton = styled(OutlineButton)<ButtonProps>`
    ${flexRowCenter()};
    display: ${props => (props.referral_count > 3 ? "block" : "none")};
    width: 100%;
    height: 48px;
    margin: 18px 0px;
    font-size: 0.875rem;
    line-height: 0px;
    cursor: pointer;
`;

const ReferredUsers = () => {
    const dispatch = useDispatch();
    function openReferredUsersModal(): void {
        dispatch(setReferredUserModal(true));
    }

    const referredUsersCount = useSelector(
        (state: GlobalState) => state.entities.referredUsers.allIds.length
    );

    function showNumberReferrals(): string {
        if (referredUsersCount === 1) {
            return `${referredUsersCount} REFERRED USER`;
        } else {
            return `${referredUsersCount} REFERRED USERS`;
        }
    }

    const referredUsers = useSelector(getReferredUsers);

    const ReferredUsersList = referredUsers
        .slice(0, 3)
        .map((user: ReferredUserType, index: number) => {
            return (
                <React.Fragment key={`${user.referredPublisher}-${index}`}>
                    <ReferredUser
                        userId={user.referredPublisher}
                        percentEarnings={user.commission * 100}
                        dateReferralCreated={user.created_at}
                        daysActive={user.daysActive}
                        referralBonus={user.earnedPlugcoins / 20}
                    />
                </React.Fragment>
            );
        });

    return (
        <Main>
            <NumberReferredUsers>{showNumberReferrals()}</NumberReferredUsers>

            {/* Rendered when user has 0 referred users. */}
            <ReferralCTABox has_referrals={referredUsersCount > 0}>
                <XCard text="You have not made any referral earnings yet. Share the link above to start earning." />
            </ReferralCTABox>

            {/* Rendered when user has 1 or more referred users. */}
            <ReferredUsersListContainer has_referrals={referredUsersCount > 0}>
                {ReferredUsersList}
            </ReferredUsersListContainer>

            {/* Rendered when user has 4 or more referred users. */}
            <SeeMoreButton
                onClick={openReferredUsersModal}
                referral_count={referredUsersCount}
            >
                See More
            </SeeMoreButton>
        </Main>
    );
};

export default ReferredUsers;
