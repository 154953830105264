import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "../types";
import { goBack, push } from "connected-react-router";
import {
    photoModal,
    usernameModal,
    emailModal,
    logout,
    SaveSettingsParams,
    saveSettings,
    cashoutSettingModal
} from "../actions";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import ProfileModals from "../modals/ProfileModals";
import SettingsPadding from "./components/SettingsPadding";
import SettingsTitle from "./components/SettingsTitle";
import EditProfilePhoto from "./cells/EditProfilePhoto";
import EditUsernameBio from "./cells/EditUsernameBio";
import CashoutInfo from "./cells/CashoutInfo";
import ContactInfo from "./cells/ContactInfo";
import About from "./cells/About";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";
import { GlobalState } from "src/reducers";
import InternalTools from "./cells/InternalTools";
import { useIntercom } from "react-use-intercom";
import Preferences from "./cells/Preferences";

interface Props {
    profile: Profile;
}

const Settings = ({ profile }: Props) => {
    let employee = useSelector(
        (state: GlobalState) => state?.profile?.profile?.status === "employee"
    );
    if (!employee) employee = !!localStorage.getItem("employeeToken");
    const [bio, setBio] = useState(profile.bio || "");
    const [privateAccount, setPrivateAccount] = useState(
        profile.private_account
    );
    const { shutdown } = useIntercom();

    const dispatch = useDispatch();

    function dispatchGoBack(): void {
        dispatch(goBack());
    }

    function dispatchSaveSettings(params: SaveSettingsParams): void {
        dispatch(saveSettings(params));
    }

    function dispatchPhotoModal(): void {
        dispatch(photoModal());
    }

    function dispatchUsernameModal(): void {
        dispatch(usernameModal());
    }

    function dispatchCashoutSettingModal(): void {
        dispatch(cashoutSettingModal());
    }

    function dispatchEmailModal(): void {
        dispatch(emailModal());
    }

    function dispatchPush(route: string): void {
        dispatch(push(route));
    }

    function dispatchLogout(): void {
        shutdown();
        dispatch(logout());
    }

    return (
        <>
            <Navbar
                customMobileNavbar={
                    <SimpleNavbar
                        title={"SETTINGS"}
                        left={<NavButton back onClick={dispatchGoBack} />}
                        right={
                            <NavButton
                                text="Done"
                                onClick={() => {
                                    dispatchSaveSettings({
                                        publisherId: profile.id,
                                        bio,
                                        privateAccount
                                    });
                                }}
                            />
                        }
                    />
                }
            />

            <MainContainer>
                <EditProfilePhoto
                    profilePhotoUrl={profile.profile_photo_url}
                    photoModal={dispatchPhotoModal}
                />

                <SettingsPadding>
                    <EditUsernameBio
                        username={profile.username}
                        usernameModal={dispatchUsernameModal}
                        bio={bio}
                        setBio={setBio}
                    />
                </SettingsPadding>

                <SettingsPadding>
                    <SettingsTitle>CASHOUT INFO</SettingsTitle>
                    <CashoutInfo
                        profile={profile}
                        cashoutSettingModal={dispatchCashoutSettingModal}
                    />
                </SettingsPadding>

                <SettingsPadding>
                    <SettingsTitle>CONTACT INFO</SettingsTitle>
                    <ContactInfo
                        profile={profile}
                        emailModal={dispatchEmailModal}
                    />
                </SettingsPadding>

                <SettingsPadding>
                    <SettingsTitle>ABOUT</SettingsTitle>
                    <About push={dispatchPush} logout={dispatchLogout} />
                </SettingsPadding>

                <SettingsPadding>
                    <SettingsTitle>Preferences</SettingsTitle>
                    <Preferences />
                </SettingsPadding>
                {employee && (
                    <SettingsPadding>
                        <SettingsTitle>Internal Tools</SettingsTitle>
                        <InternalTools />
                    </SettingsPadding>
                )}
            </MainContainer>

            <ProfileModals profile={profile} />
        </>
    );
};

export default Settings;
