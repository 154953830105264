import { Action } from "src/types";
import { Creator } from "./types.creator-groups";

// Fetch All Creator Groups Creators ---------------------------------------------------------------

export const FETCH_CREATORS = {
    REQUEST: "FETCH_CREATORS_REQUEST",
    SUCCESS: "FETCH_CREATORS_SUCCESS",
    FAILURE: "FETCH_CREATORS_FAILURE"
};

// export function fetchCreatorsRequest(data: Partial<Creator>): Action {
export function fetchCreatorsRequest(
    data: any,
    sortingOrFiltering: boolean
): Action {
    return {
        type: FETCH_CREATORS.REQUEST,
        payload: { data, sortingOrFiltering }
    };
}

export interface NormalizedCreatorsResponse {
    [id: number]: Creator;
}

export function fetchCreatorsSuccess(
    // creator: NormalizedCreatorsResponse
    creator: any,
    sortingOrFiltering: boolean
): Action {
    return {
        type: FETCH_CREATORS.SUCCESS,
        payload: { creator, sortingOrFiltering }
    };
}

export function fetchCreatorsFailure(error: string): Action {
    return {
        type: FETCH_CREATORS.FAILURE,
        payload: { error }
    };
}

// Fetch Creator Groups Creator ---------------------------------------------------------------

export const FETCH_CREATOR_OR_CREATORS_BASED_ON_SOCIAL_ACCOUNT_ID = {
    REQUEST: "FETCH_CREATOR_OR_CREATORS_BASED_ON_SOCIAL_ACCOUNT_ID_REQUEST",
    SUCCESS: "FETCH_CREATOR_OR_CREATORS_BASED_ON_SOCIAL_ACCOUNT_ID_SUCCESS",
    FAILURE: "FETCH_CREATOR_OR_CREATORS_BASED_ON_SOCIAL_ACCOUNT_ID_FAILURE"
};

export function fetchCreatorOrCreatorsBasedOnSocialAccountIdRequest(
    socialAccountIds: number[]
): Action {
    return {
        type: FETCH_CREATOR_OR_CREATORS_BASED_ON_SOCIAL_ACCOUNT_ID.REQUEST,
        payload: { socialAccountIds }
    };
}

export function fetchCreatorOrCreatorsBasedOnSocialAccountIdSuccess(
    creatorOrCreators: Creator[]
): Action {
    return {
        type: FETCH_CREATOR_OR_CREATORS_BASED_ON_SOCIAL_ACCOUNT_ID.SUCCESS,
        payload: { creatorOrCreators }
    };
}

export function fetchCreatorOrCreatorsBasedOnSocialAccountIdFailure(
    error: string
): Action {
    return {
        type: FETCH_CREATOR_OR_CREATORS_BASED_ON_SOCIAL_ACCOUNT_ID.FAILURE,
        payload: { error }
    };
}
