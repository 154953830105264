import { combineReducers } from "redux";
import {
    SET_SOCIAL_ACCOUNT_STATUSES,
    SET_SOCIAL_ACCOUNT_STATUS
} from "src/social-accounts/actions";
import { NormalizedAction, Action } from "../../types";
import { POST_ACCOUNT } from "../actions";
import {
    DELETE_PUBLISHER_OFFER,
    FETCH_PUBLISHER_OFFERS,
    PATCH_PUBLISHER_OFFER,
    POST_PUBLISHER_OFFER
} from "../rates/actions";
import { PublisherOffer, SocialAccount } from "../types";
import { FETCH_USER_SOCIAL_ACCOUNTS } from "./actions";

interface ById {
    [id: number]: SocialAccount;
}

export interface SocialAccountEntitiesState {
    byId: ById;
    allIds: number[];
    statuses: {
        [id: string]: string;
    };
}

const initialSocialAccountsState: SocialAccountEntitiesState = {
    byId: {},
    allIds: [],
    statuses: {}
};

const joinRatesToSocialAccounts = (state: ById, action: NormalizedAction) => {
    const newState = { ...state };
    const publisherOffers = action.response.result.map(
        (publisherOfferId: number) =>
            action.response.entities.publisherOffers[publisherOfferId]
    );
    publisherOffers.forEach(({ id, publisherOfferPosts }: PublisherOffer) => {
        const { socialAccountId } = publisherOfferPosts[0];
        const publisherOfferIds =
            newState[socialAccountId].publisherOffers || [];
        newState[socialAccountId].publisherOffers = [...publisherOfferIds, id];
    });

    return newState;
};

const updateRatesInSocialAccounts = (state: ById, action: NormalizedAction) => {
    const { deleteId, addId, accountId } = action.payload;
    const account = state[accountId];
    const publisherOfferSet = new Set(account.publisherOffers);
    if (deleteId) publisherOfferSet.delete(deleteId);
    if (addId) publisherOfferSet.add(addId);

    return {
        ...state,
        [accountId]: {
            ...account,
            publisherOffers: Array.from(publisherOfferSet)
        }
    };
};

const byId = (
    state = initialSocialAccountsState.byId,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_USER_SOCIAL_ACCOUNTS.SUCCESS:
            return { ...state, ...action.response.entities.socialAccounts };
        case FETCH_PUBLISHER_OFFERS.SUCCESS:
        case POST_PUBLISHER_OFFER.SUCCESS:
            return joinRatesToSocialAccounts(state, action);
        case POST_ACCOUNT.ADD:
            return { ...state, [action.payload.id]: action.payload };
        case POST_ACCOUNT.REPLACE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_PUBLISHER_OFFER.SUCCESS:
        case PATCH_PUBLISHER_OFFER.SUCCESS:
            return updateRatesInSocialAccounts(state, action);
        default:
            return state;
    }
};

const allIds = (
    state = initialSocialAccountsState.allIds,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_USER_SOCIAL_ACCOUNTS.SUCCESS:
            return action.response.result;
        case POST_ACCOUNT.ADD:
            return state.concat([action.payload.id]);
        default:
            return state;
    }
};

const statuses = (
    state = initialSocialAccountsState.statuses,
    action: Action
) => {
    switch (action.type) {
        case SET_SOCIAL_ACCOUNT_STATUSES:
            return action.payload;
        case SET_SOCIAL_ACCOUNT_STATUS:
            let temp = state;
            temp[action.payload.id] = action.payload.status;
            return temp;
        default:
            return state;
    }
};

const socialAccountsEntitiesReducer = combineReducers<
    SocialAccountEntitiesState
>({
    byId,
    allIds,
    statuses
});

export default socialAccountsEntitiesReducer;
