import { Action } from "src/types";
import {
    AssignOpenBuyToPublishersData,
    AssignPaymentToOpenBuyData,
    OpenBuyCreateData,
    OpenBuyUpdateData
} from "./types.open-buys";

// Create Open Buy (challenge offer) ---------------------------------------------------------------

export const CREATE_OPEN_BUY = {
    REQUEST: "CREATE_OPEN_BUY_REQUEST",
    SUCCESS: "CREATE_OPEN_BUY_SUCCESS",
    FAILURE: "CREATE_OPEN_BUY_FAILURE"
};

export function createOpenBuy(
    data: OpenBuyCreateData,
    publisherIds: number[]
): Action {
    return {
        type: CREATE_OPEN_BUY.REQUEST,
        payload: { data, publisherIds }
    };
}

export function createOpenBuySuccess(buy: any): Action {
    return {
        type: CREATE_OPEN_BUY.SUCCESS,
        payload: buy
    };
}

export function createOpenBuyFailure(error: string): Action {
    return {
        type: CREATE_OPEN_BUY.FAILURE,
        payload: { error }
    };
}

// Update Open Buy (challenge offer) ---------------------------------------------------------------

export const UPDATE_OPEN_BUY = {
    REQUEST: "UPDATE_OPEN_BUY_REQUEST",
    SUCCESS: "UPDATE_OPEN_BUY_SUCCESS",
    FAILURE: "UPDATE_OPEN_BUY_FAILURE"
};

export function updateOpenBuy(id: number, data: OpenBuyUpdateData): Action {
    return {
        type: UPDATE_OPEN_BUY.REQUEST,
        payload: { id, data }
    };
}

export function updateOpenBuySuccess(buy: any): Action {
    return {
        type: UPDATE_OPEN_BUY.SUCCESS,
        payload: buy
    };
}

export function updateOpenBuyFailure(error: string): Action {
    return {
        type: UPDATE_OPEN_BUY.FAILURE,
        payload: { error }
    };
}

// Assign Open Buy To Publishers (challenge offer) ---------------------------------------------------------------

export const ASSIGN_OPEN_BUY_TO_PUBLISHERS = {
    REQUEST: "ASSIGN_OPEN_BUY_TO_PUBLISHERS_REQUEST",
    SUCCESS: "ASSIGN_OPEN_BUY_TO_PUBLISHERS_SUCCESS",
    FAILURE: "ASSIGN_OPEN_BUY_TO_PUBLISHERS_FAILURE"
};

export function assignOpenBuyToPublishers(
    data: AssignOpenBuyToPublishersData
): Action {
    return {
        type: ASSIGN_OPEN_BUY_TO_PUBLISHERS.REQUEST,
        payload: { data }
    };
}

// export function assignOpenBuyToPublishersSuccess(buy: any): Action {
//     return {
//         type: ASSIGN_OPEN_BUY_TO_PUBLISHERS.SUCCESS,
//         payload: { buy }
//     };
// }

export function assignOpenBuyToPublishersFailure(error: string): Action {
    return {
        type: ASSIGN_OPEN_BUY_TO_PUBLISHERS.FAILURE,
        payload: { error }
    };
}

// Remove Open Buy From Publishers (challenge offer) ---------------------------------------------------------------

export const REMOVE_OPEN_BUY_FROM_PUBLISHERS = {
    REQUEST: "REMOVE_OPEN_BUY_FROM_PUBLISHERS_REQUEST",
    SUCCESS: "REMOVE_OPEN_BUY_FROM_PUBLISHERS_SUCCESS",
    FAILURE: "REMOVE_OPEN_BUY_FROM_PUBLISHERS_FAILURE"
};

export function removeOpenBuyFromPublishers(): Action {
    return {
        type: REMOVE_OPEN_BUY_FROM_PUBLISHERS.REQUEST,
        payload: null
    };
}

export function removeOpenBuyFromPublishersSuccess(buy: any): Action {
    return {
        type: REMOVE_OPEN_BUY_FROM_PUBLISHERS.SUCCESS,
        payload: { buy }
    };
}

export function removeOpenBuyFromPublishersFailure(error: string): Action {
    return {
        type: REMOVE_OPEN_BUY_FROM_PUBLISHERS.FAILURE,
        payload: { error }
    };
}
