import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SearchBar from "src/components/SearchBar";
import SearchTag from "src/components/SearchTag";
import {
    CreatorSearchHeaderCreatorCountLoading,
    CreatorSearchHeaderLoading,
    CreatorSearchHeaderSearchLoading,
    CreatorSearchHeaderTagLoading,
    CreatorSearchHeaderTagsLoading,
    CreatorSearchHeaderTitleLoading
} from "src/components/SkeletonLoader";
import { colors, fonts, fontWeight } from "src/constants";
import CreatorGroupsAppliedFilters from "src/marketplace/creator-groups/components/CreatorGroupsAppliedFilters";
import CreatorsFiltersModal from "src/marketplace/creator-groups/modals/CreatorsFiltersModal";
import CreatorsSortModal from "src/marketplace/creator-groups/modals/CreatorsSortModal";
import { getCreators } from "src/marketplace/creator-groups/selectors.creators-search";
import { Creator } from "src/marketplace/creator-groups/types.creator-groups";
import { GlobalState } from "src/reducers";
import { resetCreatorsQueryParameters } from "src/ui/creators/actions";
import { clearSpacing } from "src/utils/styles/snippets";
import styled from "styled-components";
import { creatorSearchFilters, CreatorSearchFilters } from "../utils";
import CreatorSearchFilterDropdown from "./CreatorSearchFilterDropdown";

const Header = styled.header`
    position: sticky;
    z-index: 99;
    padding: 16px;
    background-color: ${colors.white};
    border-bottom: ${colors.quaternaryLightGray} solid 1px;
`;

const Title = styled.h1`
    ${clearSpacing()};
    padding-bottom: 16px;
    color: ${colors.black};
    font-size: ${fonts.title}px;
    font-weight: ${fontWeight.medium};
`;

const Contents = styled.section`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Search = styled(SearchBar)`
    width: 100%;
`;

const CreatorCount = styled.span`
    display: block;
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.heavy};
    text-transform: capitalize;
`;

const SortAndFilters = styled.section`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

interface Props {
    searchString: string;
    setSearchString: React.Dispatch<React.SetStateAction<string>>;
}

const CreatorSearchHeader = ({ searchString, setSearchString }: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const creators = useSelector(getCreators);
    const { creatorSearchLoading, totalHits } = useSelector(
        (state: GlobalState) => {
            return state.ui.creators;
        },
        shallowEqual
    );

    // Local State ---------------------------------------------------
    const [sortModalOpen, setSortModalOpen] = useState(false);
    const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
    const [allFiltersOpen, setAllFiltersOpen] = useState(false);
    const [currentFilter, setCurrentFilter] = useState<
        "" | CreatorSearchFilters
    >("");

    // Local Functions -----------------------------------------------
    function openSortModal(): void {
        setFilterDropdownOpen(false);
        setSortModalOpen(true);
    }

    function openAllFiltersModal(): void {
        setFilterDropdownOpen(false);
        setAllFiltersOpen(true);
    }

    // JSX -----------------------------------------------------------
    return (
        <>
            <Header>
                <Title>Creator Search</Title>
                <Contents>
                    <Search
                        searchString={searchString}
                        updateSearchString={setSearchString}
                    />
                    {creatorSearchLoading ? (
                        <CreatorSearchHeaderCreatorCountLoading />
                    ) : (
                        <CreatorCount>
                            {creators.length} OF {totalHits} CREATORS
                        </CreatorCount>
                    )}

                    <SortAndFilters>
                        <SearchTag
                            text="Sort By"
                            color="white"
                            iconSrc="/tag-teal-arrow-icon.svg"
                            onClick={openSortModal}
                            margin="0px"
                        />
                        {creatorSearchFilters.map(
                            (filter: CreatorSearchFilters) => {
                                if (
                                    filter === "Platform" ||
                                    filter === "Interests" ||
                                    filter === "Themes"
                                ) {
                                    return (
                                        <SearchTag
                                            key={`creator-search-header-filters-${filter}`}
                                            text={filter}
                                            color="white"
                                            iconSrc="/tag-teal-plus-icon.svg"
                                            onClick={() => {
                                                setFilterDropdownOpen(true);
                                                setCurrentFilter(filter);
                                            }}
                                            margin="0px"
                                        />
                                    );
                                }
                            }
                        )}
                        <SearchTag
                            text="All Filters"
                            color="white"
                            iconSrc="/tag-teal-plus-icon.svg"
                            onClick={openAllFiltersModal}
                            margin="0px"
                        />
                        <CreatorSearchFilterDropdown
                            open={filterDropdownOpen}
                            setOpen={setFilterDropdownOpen}
                            currentFilter={currentFilter}
                        />
                    </SortAndFilters>
                    <CreatorGroupsAppliedFilters noPadding />
                </Contents>
            </Header>

            <CreatorsSortModal
                modalOpen={sortModalOpen}
                closeModal={() => setSortModalOpen(false)}
                resetForSort={() => dispatch(resetCreatorsQueryParameters())}
            />

            <CreatorsFiltersModal
                modalOpen={allFiltersOpen}
                closeModal={() => setAllFiltersOpen(false)}
            />
        </>
    );
};

export default CreatorSearchHeader;
