import moment from "moment";
import React, { useEffect, useState } from "react";
import { get } from "src/Api";
import CollapsableSection from "src/components/layout/CollapsableSection";
import StatusMessage from "src/components/modals/components/StatusMessage";
import { colors } from "src/constants";
import XCard from "src/profile/components/XCard";
import styled from "styled-components";
import SubmissionHistoryBody from "./SubmissionHistoryBody";
import { getHistoryTitle, getHistoryType, getSubmissionHistoryReason } from "./utils";
import { OpenBuySubmissionSet } from "src/buys/modules/open-buys/types";

const ModalContainer = styled.div`
    padding: 0px 4px;
    display: flex;
    flex-direction: column;
`;

const TabRow = styled.div`
    display: flex;
`;

const Tab = styled.div<{ selected?: boolean }>`
    height: 43px;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    background-color: ${props =>
        props.selected ? colors.quaternaryLightGray : colors.primaryLightGray};
    :hover {
        opacity: 0.8;
    }
`;

const HistoryColumn = styled.div`
    overflow-y: auto;
`;

const HistoryHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
`;

const HistoryNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: auto 0;
`;

const HistoryName = styled.div`
    font-weight: 500;
    font-size: 13px;
    display: flex;
    align-items: center;
`;

const HistoryStamp = styled.div`
    color: ${colors.gray2};
    font-size: 12px;
`;

const Dot = styled.div<{ type: "warning" | "danger" | "success" | "info" }>`
    border-radius: 100px;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-color: ${props =>
        props.type === "success"
            ? colors.secondaryGreen
            : props.type === "danger"
            ? colors.primaryRed
            : props.type === "info"
            ? colors.primaryBlue
            : colors.primaryYellow};
`;

const Error = styled.div`
    background-color: ${colors.primaryRedLight};
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    margin-top: -15px;
`;
const Alert = styled.img`
    height: 16px;
    width: 14px;
    margin-right: 8px;
`;

export interface AdminEvent {
    id: number;
    eventName: string;
    publisher: {
        firstname: string;
        lastname: string;
        email: string;
        id: number;
    };
    submissionId: number;
    publisherId: number;
    updateObject: any;
    createdAt: string;
}

const SubmissionHistory = ({
    submissionSet
}: {
    submissionSet: OpenBuySubmissionSet;
}) => {
    const [history, setHistroy] = useState<AdminEvent[]>([]);
    const [scraperHistory, setScraperHistory] = useState<AdminEvent[]>([]);
    const [tab, setTab] = useState("approval");

    const getHistory = async () => {
        try {
            let res = await get(
                `/v1/openBuySubmissionSet/${submissionSet.id}/history`,
                {}
            );

            let scraper: AdminEvent[] = [];
            let edits: AdminEvent[] = [];
            Object.keys(res.data.data).forEach(id =>
                res.data.data[id].forEach((event: AdminEvent) => {
                    if (event.eventName.toLowerCase().includes("scraper"))
                        scraper.push(event);
                    else edits.push(event);
                })
            );
            setHistroy(edits);
            setScraperHistory(scraper);
        } catch (err) {}
    };

    useEffect(() => {
        getHistory();
    }, [submissionSet]);

    const getHistoryAuthor = (event: any) => {
        if (event.publisher.firstname) {
            return `${event.publisher.firstname} ${event.publisher.lastname}`;
        } else if ([
            "open_buy_submission_advertiser_revision_request",
            "open_buy_submission_advertiser_approval",
            "open_buy_submission_advertiser_rejection",
        ].includes(event.eventName)) {
            return "Advertiser";
        } else {
            return "Creator";
        }
    }


    return (
        <ModalContainer>
            <TabRow>
                <Tab
                    selected={tab === "approval"}
                    onClick={() => setTab("approval")}
                >
                    Approval History
                </Tab>
                <Tab
                    selected={tab === "scraper"}
                    onClick={() => setTab("scraper")}
                >
                    Scraper History
                </Tab>
            </TabRow>
            <HistoryColumn>
                {((tab === "approval" && history.length === 0) ||
                    (tab === "scraper" && scraperHistory.length === 0)) && (
                    <XCard
                        text="No history found"
                        customStyles={{ marginTop: "4px" }}
                    />
                )}
                {tab === "approval"
                    ? history?.map((event, idx) => (
                          <React.Fragment key={event.id}>
                              <HistoryHeader>
                                  <HistoryNameContainer>
                                      <HistoryName>
                                          {getHistoryTitle(
                                              event.eventName,
                                              idx === history.length - 1
                                          )}
                                      </HistoryName>
                                      <HistoryStamp>
                                          {getHistoryAuthor(event)}
                                      </HistoryStamp>
                                  </HistoryNameContainer>

                                  <HistoryStamp>
                                      Updated on:{" "}
                                      {moment(event.createdAt).format(
                                          "MM/DD/YYYY hh:mm"
                                      )}
                                  </HistoryStamp>
                              </HistoryHeader>
                              <StatusMessage
                                  invert={true}
                                  heading={`${getHistoryTitle(
                                      event.eventName,
                                      idx === history.length - 1
                                  )} (${event.submissionId}):`}
                                  type={getHistoryType(event.eventName)}
                                  closable={true}
                              >
                                  <SubmissionHistoryBody event={event} />
                              </StatusMessage>
                          </React.Fragment>
                      ))
                    : scraperHistory.map(event => (
                          <React.Fragment key={event.id}>
                              <CollapsableSection
                                  caretLabel="View Scrape Data"
                                  header={
                                      <HistoryHeader
                                          style={{ flexDirection: "column" }}
                                      >
                                          <HistoryName>
                                              <Dot
                                                  type={getHistoryType(
                                                      event.eventName
                                                  )}
                                              />
                                              {getHistoryTitle(event.eventName)}
                                          </HistoryName>
                                          <HistoryStamp>
                                              {moment(event.createdAt).format(
                                                  "lll"
                                              )}
                                          </HistoryStamp>
                                      </HistoryHeader>
                                  }
                              >
                                  {getHistoryType(event.eventName) ===
                                  "success" ? (
                                      <SubmissionHistoryBody event={event} />
                                  ) : (
                                      undefined
                                  )}
                              </CollapsableSection>
                              {getHistoryType(event.eventName) === "danger" && (
                                  <Error>
                                      <Alert src="/alert-triangle.svg" />
                                      {event.eventName === "scraper_flagged"
                                          ? event.updateObject.flag
                                          : event.updateObject.failureReason}
                                  </Error>
                              )}
                          </React.Fragment>
                      ))}
            </HistoryColumn>
        </ModalContainer>
    );
};

export default SubmissionHistory;
