import React, { useEffect, useState } from "react";
import { get as apiGet } from "src/Api";
import { colors } from "src/constants";
import { get } from "src/new-chat/saga";
import XCard from "src/profile/components/XCard";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    height: 40vh;
    overflow-y: auto;
    font-family: sans-serif;
    padding: 12px;
`;
const RuleRow = styled.li<{ selected?: boolean }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 3px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    border-bottom: 1px solid ${colors.blueGrey2};
    :last-child {
        border-bottom: none;
    }
    :hover {
        background-color: ${colors.lightRed};
    }
    ${props =>
        props.selected &&
        `text-decoration: line-through; background-color: ${colors.lightRed}; color: ${colors.primaryRed}`}
`;
const RuleTitle = styled.b`
    font-weight: 600;
`;

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 12px;
`;

const RuleSection = styled.ul`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding: 0;
    &:last-child {
        margin-bottom: 0px;
    }
`;

const RuleDescription = styled.div`
    font-weight: 400;
    display: inline;
    padding-left: 12px;
    text-align: left;
    font-size: 14px;
`;

const GeneralRule = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;

interface Rule {
    title: string;
    id: number;
    icon: string;
    rule: string;
}

interface Props {
    onMessage: (text: string) => void;
    ruleSetId: number;
    handleAddRule: (rule: string, remove?: boolean) => void;
}

const ApprovalChecklist = ({ onMessage, ruleSetId, handleAddRule }: Props) => {
    const [rules, setRules] = useState<{ [id: number]: Rule } | null>(null);
    const [dos, setDos] = useState<Rule[]>([]);
    const [donts, setDonts] = useState<Rule[]>([]);
    const [general, setGeneral] = useState<Rule[]>([]);

    const handleClick = (rule: Rule) => {
        handleAddRule(`${rule.title} ${rule.rule}`.trim());
    };

    const getStandards = async () => {
        let res = await get(
            "ChallengesStandardMediaRulesSetId",
            "GzADIFgd3zDfZAgtQF1Y"
        );
        //@ts-ignore
        res = await apiGet(`/v1/ruleSet/${res?.ruleSetId}`, {});
        //@ts-ignore
        const rules = res.data.data.Rules;
        rules.forEach((rule: Rule) =>
            setRules(old => ({ ...old, [rule.id]: rule }))
        );
        setGeneral(old => old.concat(rules));
    };

    const fetchRules = async () => {
        try {
            let res = await apiGet(`/v1/ruleSet/${ruleSetId}`, {});
            const rules = res.data.data.Rules;
            rules.forEach((rule: Rule) =>
                setRules(old => ({ ...old, [rule.id]: rule }))
            );
            setDonts(rules.filter((rule: Rule) => rule.icon === "x"));
            setDos(rules.filter((rule: Rule) => rule.icon === "check"));
            setGeneral(
                rules.filter(
                    (rule: Rule) => rule.icon !== "x" && rule.icon !== "check"
                )
            );
            getStandards();
        } catch (err) {
            console.error(`error getting rules: ${err}`);
        }
    };

    useEffect(() => {
        fetchRules();
    }, [ruleSetId]);

    return (
        <Main>
            <Title>Here's what to do</Title>
            <RuleSection>
                {dos.length < 1 ? (
                    <XCard text="No Media Standards" />
                ) : (
                    dos.map((rule: Rule) => (
                        <RuleRow
                            key={`${rule.id}`}
                            onClick={() => handleClick({ ...rule, icon: "✅" })}
                        >
                            ✅
                            <RuleDescription>
                                <RuleTitle>{rule.title} </RuleTitle>
                                {rule.rule}
                            </RuleDescription>
                        </RuleRow>
                    ))
                )}
            </RuleSection>
            <Title>Here's what NOT to do</Title>
            <RuleSection>
                {donts.length < 1 ? (
                    <XCard text="No Media Standards" />
                ) : (
                    donts.map((rule: Rule) => (
                        <RuleRow
                            key={`standard: ${rule.id}`}
                            onClick={() => handleClick({ ...rule, icon: "🚫" })}
                        >
                            🚫
                            <RuleDescription>
                                <RuleTitle>{rule.title + " "} </RuleTitle>
                                {rule.rule}
                            </RuleDescription>
                        </RuleRow>
                    ))
                )}
            </RuleSection>
            <Title>Plug Media Standards</Title>
            <RuleSection>
                {general.length < 1 ? (
                    <XCard text="No Media Standards" />
                ) : (
                    general.map((rule: Rule) => (
                        <RuleRow
                            key={`standard: ${rule.id}`}
                            onClick={() => handleClick({ ...rule, icon: "👍" })}
                        >
                            👍
                            <GeneralRule>
                                <RuleTitle>{rule.title + " "} </RuleTitle>
                                <RuleDescription> {rule.rule}</RuleDescription>
                            </GeneralRule>
                        </RuleRow>
                    ))
                )}
            </RuleSection>
        </Main>
    );
};

export default ApprovalChecklist;
