import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { overlayBackgrounds, colors, fonts } from "src/constants";
import { preventChildBubbling } from "src/utils/functions/helpers";
import { clearSpacing } from "src/utils/styles/snippets";
import styled from "styled-components";
import { BigBlueButton } from "src/profile/components/Buttons";

const Parent = styled(motion.div)`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 2001;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
`;

const Child = styled.div`
    border-radius: 6px;
    border-color: ${colors.blueGrey};
    background-color: ${colors.white};
    padding: 35px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 400px;
`;

const Text = styled.p`
    ${clearSpacing()};
    font-size: ${fonts.semiTitle}px;
`;

const Close = styled.img`
    align-self: flex-end;
    width: auto;
    height: 20px;
    cursor: pointer;
`;

interface Props {
    visible: boolean;
    closeModal: VoidFunction;
    onConfirmation: VoidFunction;
}

const AccountEditConfirmationModal = ({
    visible,
    closeModal,
    onConfirmation
}: Props) => {
    return (
        <AnimatePresence>
            {visible && (
                <Parent
                    initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                    animate={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
                    exit={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                    transition={{ duration: 0.3 }}
                    onClick={closeModal}
                >
                    <Child onClick={preventChildBubbling}>
                        <Close
                            src="/close.svg"
                            alt="close button"
                            onClick={closeModal}
                        />
                        <Text>
                            Warning: Changing the username on a verified account
                            will cause the account to become unverified.
                        </Text>
                        <BigBlueButton onClick={onConfirmation}>
                            <Text>I Understand</Text>
                        </BigBlueButton>
                    </Child>
                </Parent>
            )}
        </AnimatePresence>
    );
};

export default AccountEditConfirmationModal;
