import { Shoutout } from "../shoutouts/types";

const replaceFillOrderFilename = (
    shoutouts: Shoutout[],
    shoutoutId: number,
    fillOrderId: number,
    filename: string | null
) => {
    return shoutouts.map(shoutout => {
        if (shoutout.id === shoutoutId) {
            const fillOrders = shoutout.ShoutoutFillOrders.map(fillOrder => {
                if (fillOrder.id === fillOrderId) {
                    return { ...fillOrder, verify_media_filename: filename };
                } else {
                    return fillOrder;
                }
            });

            return { ...shoutout, ShoutoutFillOrders: fillOrders };
        } else {
            return shoutout;
        }
    });
};

export default replaceFillOrderFilename;
