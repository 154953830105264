import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthState } from "src/auth/selectors";
import InternalLink from "src/components/buttons/InternalLink";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "src/components/CommonStyles";
import ProfilePhoto from "src/components/ProfilePhoto";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import { shortTimeIntervalArray } from "src/util";
import styled from "styled-components";
import { fetchChatUserProfile } from "../chat-profiles/actions";
import { selectConversationOwnPauseDuration } from "../group-user-settings/selectors";
import { ConversationUnread } from "../types/ConversationUnread";

const Main = styled.div<{ unread: boolean; paused: boolean }>`
    ${FlexCentered};
    padding: 0 18px;

    & > * ~ * {
        margin-left: 12px;
    }

    ${props =>
        props.unread &&
        `${Username} {
            font-weight: 700;
        }

        ${LatestMessage} {
            font-weight: 500;
        }
    `}

    ${props =>
        props.paused &&
        `${LatestMessage} {
            color: ${colors.primaryGray};
        }

        ${Badge} {
            background-color: ${colors.primaryGray};
        }
    `}
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    text-align: left;
    min-width: 0;
    align-items: flex-start;
`;

const Username = styled.span`
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const LatestWrapper = styled.div`
    ${FlexVerticallyCentered}
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    width: 100%;
`;

const LatestMessage = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const LatestTime = styled.div`
    color: ${colors.primaryGray};
    white-space: nowrap;
`;

const Badge = styled.div`
    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${colors.primaryBlue};
    color: ${colors.white};
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
    text-align: center;
`;

interface Props {
    conversationId: string;
    lastMessage?: string;
    lastMessageSentAt?: number;
    unreadCount?: ConversationUnread;
    participantIds: string[];
    displayName?: string;
    imageUrl?: string;
    blocked?: number;
}

const ConversationCell = ({
    conversationId,
    lastMessage,
    lastMessageSentAt,
    unreadCount,
    participantIds,
    displayName,
    imageUrl,
    blocked
}: Props) => {
    const dispatch = useDispatch();
    const { userId } = useSelector(getAuthState);

    useEffect(() => {
        const otherParticipant =
            participantIds.length === 2 &&
            participantIds.find(id => id !== `${userId}`);
        otherParticipant &&
            !displayName &&
            dispatch(fetchChatUserProfile(otherParticipant));
    }, []);

    const pauseStatus = useSelector((state: GlobalState) =>
        selectConversationOwnPauseDuration(state, conversationId)
    );

    return (
        <InternalLink to={`/newchat/${conversationId}`}>
            <Main unread={!!unreadCount?.unreadCount} paused={!!pauseStatus}>
                <ProfilePhoto
                    profilePhotoUrl={imageUrl || null}
                    photoSize={46}
                />
                <Info>
                    <Username>{displayName}</Username>
                    <LatestWrapper>
                        {blocked ? (
                            "Blocked"
                        ) : (
                            <>
                                <LatestMessage>{lastMessage}</LatestMessage>
                                <LatestTime>
                                    &nbsp;•{" "}
                                    {shortTimeIntervalArray(
                                        Date.now() -
                                            (lastMessageSentAt || 0) * 1000,
                                        1
                                    )}{" "}
                                    ago
                                </LatestTime>
                            </>
                        )}
                    </LatestWrapper>
                </Info>
                {!!unreadCount?.unreadCount && (
                    <Badge>{unreadCount.unreadCount}</Badge>
                )}
            </Main>
        </InternalLink>
    );
};

export default ConversationCell;
