import { combineReducers } from "redux";
import { MediaFilters } from "src/media/types";
import mediasPaginationReducer from "./media/reducer";
import { Pagination } from "./types";

export interface PaginationState {
    medias: Pagination<MediaFilters>;
}

const paginationReducer = combineReducers<PaginationState>({
    medias: mediasPaginationReducer
});

export default paginationReducer;
