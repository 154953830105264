import { DELETE_MEDIA } from "src/media/actions";
import { Action } from "src/types";

import {
    SET_CURRENT_MODAL_MEDIAS,
    MEDIA_ITEM_MODAL_OPEN,
    USER_MEDIA_LOAD_SUCCESS,
    PROMOTE_FLOW_MEDIA,
    POST_MEDIA_MODAL_OPEN
} from "./actions";

export interface MediasUIState {
    currentModalMedias: number[];
    mediaItemModalOpen: boolean;
    userMediaLoading: boolean;
    promoteFlowMedia?: string;
    postMediaModalOpen: boolean;
}

const initialMediasUIState = {
    currentModalMedias: [] as number[],
    mediaItemModalOpen: false,
    userMediaLoading: true,
    postMediaModalOpen: false
};

const mediasUIReducer = (state = initialMediasUIState, action: Action) => {
    switch (action.type) {
        case SET_CURRENT_MODAL_MEDIAS:
            return { ...state, currentModalMedias: action.payload.mediaIds };

        case MEDIA_ITEM_MODAL_OPEN:
            return { ...state, mediaItemModalOpen: action.payload };
        case POST_MEDIA_MODAL_OPEN:
            return { ...state, postMediaModalOpen: action.payload };
        case USER_MEDIA_LOAD_SUCCESS:
            return { ...state, userMediaLoading: false };
        case PROMOTE_FLOW_MEDIA.SET:
            return { ...state, promoteFlowMedia: action.payload };
        case PROMOTE_FLOW_MEDIA.CLEAR:
            return { ...state, promoteFlowMedia: undefined };
        case DELETE_MEDIA.SUCCESS:
            return {
                ...state,
                currentModalMedias: state.currentModalMedias.filter(
                    val => val !== action.payload.mediaId
                )
            };
        default:
            return state;
    }
};

export default mediasUIReducer;
