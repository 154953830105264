import React from "react";
import styled from "styled-components";
import SpinLoader from "./SpinLoader";

interface StyleProps {
    offset?: number;
    blankSpace?: number;
    background?: string;
    height?: string;
}

const LoaderContainer = styled.div<StyleProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.background};
    height: ${props =>
        props.height
            ? props.height
            : props.blankSpace
            ? `calc(80vh - ${props.blankSpace}px)`
            : "80vh"};
    box-sizing: content-box;
    ${props => (props.offset ? `padding-top: ${props.offset}px;` : ``)}
`;

const FullscreenLoader = ({
    offset,
    color,
    blankSpace,
    background,
    height
}: {
    offset?: number;
    color?: string;
    blankSpace?: number;
    background?: string;
    height?: string;
}) => {
    return (
        <LoaderContainer
            offset={offset}
            blankSpace={blankSpace}
            background={background}
            height={height}
        >
            <SpinLoader color={color} size={60} />
        </LoaderContainer>
    );
};

export default FullscreenLoader;
