import React, { useRef } from "react";
import { useHistory } from "react-router";
import ScrollArrows from "src/components/ScrollArrows";
import { colors, fonts, fontWeight } from "src/constants";
import XCard from "src/profile/components/XCard";
import { clearSpacing, hideScrollbar } from "src/utils/styles/snippets";
import styled from "styled-components";
import SocialAccountProfileSection from "./SocialAccountProfileSection";

const Main = styled.section`
    text-align: left;
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
`;

const HeaderTitle = styled.h4`
    ${clearSpacing()};
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.heavy};
    text-transform: capitalize;
`;

const Cards = styled.article`
    display: flex;
    gap: 12px;
    overflow-x: auto;

    ${props => props.theme.isMobile && hideScrollbar()};
`;

const Card = styled.section`
    flex-shrink: 0;
    width: 240px;
    height: 360px;
    border-radius: 12px;

    border: red solid 1px;
`;

interface CardData {
    imageUrl: string;
    numberViews: number;
    numberLikes: number;
}

interface Props {
    dataCount: number;
    cardData: any[];
    socialPlatformUrl: string;
    socialPlatformName: string;
}

const SocialAccountProfileTopPosts = ({
    dataCount,
    cardData,
    socialPlatformUrl,
    socialPlatformName
}: Props) => {
    // Library Methods -----------------------------------------------
    const history = useHistory();

    // Redux State ---------------------------------------------------

    // Local State ---------------------------------------------------
    const cardsRef = useRef(null);

    // Local Constants -----------------------------------------------

    // Local Functions -----------------------------------------------

    // Custom Hooks --------------------------------------------------

    // Effects -------------------------------------------------------

    // JSX -----------------------------------------------------------
    return (
        <SocialAccountProfileSection
            title="Top Posts"
            body={
                <Main>
                    {dataCount > 0 && (
                        <Header>
                            <HeaderTitle>{dataCount} Highlights</HeaderTitle>
                            <ScrollArrows refElement={cardsRef} />
                        </Header>
                    )}

                    {cardData.length > 0 ? (
                        <Cards ref={cardsRef}>
                            {cardData.map((data: any, index: number) => {
                                return (
                                    <Card
                                        key={`social-account-profile-follower-demographics-${data}-${index}`}
                                    >
                                        {data}
                                    </Card>
                                );
                            })}
                        </Cards>
                    ) : (
                        <XCard
                            text={`We don't have any top posts for this creator. View their ${socialPlatformName} account.`}
                            link={{
                                text: `View ${socialPlatformName} Account`,
                                url: socialPlatformUrl
                            }}
                            customStyles={{
                                backgroundColor: colors.white,
                                borderRadius: "8px",
                                textAlign: "center"
                            }}
                        />
                    )}
                </Main>
            }
            bodyPadding="12px"
            backgroundActive
        />
    );
};

export default SocialAccountProfileTopPosts;
