import React, { useEffect } from "react";
import { colors } from "src/constants";
import MarketplaceInput from "src/marketplace/components/MarketplaceInput";
import Dropdown from "src/marketplace/payment-sets/components/PaymentSetDropdown";
import { betterFormatNumber, formattedNumberToNumber } from "src/util";
import styled from "styled-components";
import ChallengeFormSection from "./ChallengeFormSection";
import ChallengeFormSubSection from "./ChallengeFormSubSection";

const InfoBox = styled.div`
    background: ${colors.noteYellow};
    padding: 8px 12px;
    width: 100%;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
`;

export const challengeBudgetTypes: { [type: string]: string } = {
    fixed: "Upfront Payment",
    margin: "Cost Plus Margin",
    cpi: "Charge Per Install"
};

const budgetInfo: { [type: string]: string } = {
    fixed:
        "Advertiser will be billed for the entire budget on the month that the challenge is launched. The full amount will be billed upfront, regardless of how much is paid to creators.",
    margin:
        "The advertisers will be billed for the amount paid to creators, plus a given margin. Billing will occur incrementally as the creators are paid, so the full advertiser budget may not be billed if the full creator budget is not paid out.",
    cpi:
        "The advertisers will only be billed for installs generated by the posts."
};

interface Props {
    jetfuelPercentFee: number;
    setJetfuelPercentFee: React.Dispatch<React.SetStateAction<number>>;
    advertiserBudget: number;
    setAdvertiserBudget: React.Dispatch<React.SetStateAction<number>>;
    creatorPayoutBudget: number;
    setCreatorPayoutBudget: React.Dispatch<React.SetStateAction<number>>;
    billType: string;
    setBillType: React.Dispatch<React.SetStateAction<string>>;
}

const ChallengeBudget = ({
    jetfuelPercentFee,
    setJetfuelPercentFee,
    creatorPayoutBudget,
    setCreatorPayoutBudget,
    advertiserBudget,
    setAdvertiserBudget,
    billType,
    setBillType
}: Props) => {
    // Effects -------------------------------------------------------
    useEffect(() => {
        if (billType === "margin") {
            setAdvertiserBudget(
                creatorPayoutBudget / (1 - jetfuelPercentFee / 100)
            );
        }
    }, [jetfuelPercentFee, creatorPayoutBudget]);

    useEffect(() => {
        if (billType === "fixed") {
            setJetfuelPercentFee(
                advertiserBudget === 0
                    ? 0
                    : ((advertiserBudget - creatorPayoutBudget) /
                          advertiserBudget) *
                          100
            );
        }
    }, [creatorPayoutBudget, advertiserBudget]);

    //Three Budget types

    // fixed - advertiser budget and payout budget  put in margin calculated
    // margin - payout budget and margin input advertiser budget calculated
    // cpi - only payout budget
    const getInputInfo = () => {
        switch (billType) {
            case "cpi":
                return (
                    <ChallengeFormSubSection
                        title="*CREATOR PAYOUT BUDGET"
                        error={{
                            isError: !creatorPayoutBudget,
                            message: "You must set an creator payout budget."
                        }}
                    >
                        <MarketplaceInput
                            placeholder="Input Creator Payout Budget"
                            type="currency"
                            value={betterFormatNumber(creatorPayoutBudget)}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                                setCreatorPayoutBudget(
                                    formattedNumberToNumber(
                                        e.currentTarget.value
                                    )
                                )
                            }
                        />
                    </ChallengeFormSubSection>
                );
            case "fixed":
                return (
                    <>
                        <ChallengeFormSubSection
                            title="*ADVERTISER BUDGET"
                            error={{
                                isError: !advertiserBudget,
                                message: "You must set an advertiser budget."
                            }}
                        >
                            <MarketplaceInput
                                placeholder="Input Advertiser Budget"
                                type="currency"
                                value={betterFormatNumber(advertiserBudget)}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                    setAdvertiserBudget(
                                        formattedNumberToNumber(
                                            e.currentTarget.value
                                        )
                                    )
                                }
                            />
                        </ChallengeFormSubSection>

                        <ChallengeFormSubSection
                            title="*TARGET CREATOR PAYOUT BUDGET"
                            error={{
                                isError: !creatorPayoutBudget,
                                message:
                                    "You must set an creator payout budget."
                            }}
                        >
                            <MarketplaceInput
                                placeholder="Input Creator Payout Budget"
                                type="currency"
                                value={betterFormatNumber(creatorPayoutBudget)}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                    setCreatorPayoutBudget(
                                        formattedNumberToNumber(
                                            e.currentTarget.value
                                        )
                                    )
                                }
                            />
                        </ChallengeFormSubSection>

                        <ChallengeFormSubSection title="TARGET LIFTOFF MARGIN">
                            <MarketplaceInput
                                placeholder=""
                                type="percent"
                                value={betterFormatNumber(jetfuelPercentFee)}
                                disabled={true}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                    setJetfuelPercentFee(
                                        formattedNumberToNumber(
                                            e.currentTarget.value
                                        )
                                    )
                                }
                            />
                        </ChallengeFormSubSection>
                    </>
                );
            case "margin":
                return (
                    <>
                        <ChallengeFormSubSection
                            title="*LIFTOFF MARGIN"
                            error={{
                                isError: !jetfuelPercentFee,
                                message: "You must set a margin."
                            }}
                        >
                            <MarketplaceInput
                                placeholder="Input Liftoff Margin"
                                type="percent"
                                value={betterFormatNumber(jetfuelPercentFee)}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                    setJetfuelPercentFee(
                                        formattedNumberToNumber(
                                            e.currentTarget.value
                                        )
                                    )
                                }
                            />
                        </ChallengeFormSubSection>

                        <ChallengeFormSubSection
                            title="*CREATOR PAYOUT BUDGET"
                            error={{
                                isError: !creatorPayoutBudget,
                                message:
                                    "You must set an creator payout budget."
                            }}
                        >
                            <MarketplaceInput
                                placeholder="Input Creator Payout Budget"
                                type="currency"
                                value={betterFormatNumber(creatorPayoutBudget)}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                    setCreatorPayoutBudget(
                                        formattedNumberToNumber(
                                            e.currentTarget.value
                                        )
                                    )
                                }
                            />
                        </ChallengeFormSubSection>

                        <ChallengeFormSubSection title="ADVERTISER BUDGET">
                            <MarketplaceInput
                                placeholder="Input Advertiser Budget"
                                type="currency"
                                value={betterFormatNumber(advertiserBudget)}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                    setAdvertiserBudget(
                                        formattedNumberToNumber(
                                            e.currentTarget.value
                                        )
                                    )
                                }
                                disabled={true}
                            />
                        </ChallengeFormSubSection>
                    </>
                );
            default:
                return null;
        }
    };

    const inputs = getInputInfo();

    return (
        <ChallengeFormSection title="Challenge Budget">
            <Dropdown
                itemList={Object.keys(challengeBudgetTypes)}
                selected={billType}
                displayName={challengeBudgetTypes[billType]}
                displayEmptyState={"Select a challenge"}
                getItemName={(type: string) => challengeBudgetTypes[type]}
                handleOnClick={type => setBillType(type)}
            />
            <InfoBox>{budgetInfo[billType]}</InfoBox>
            {inputs}
        </ChallengeFormSection>
    );
};

export default ChallengeBudget;
