import React from "react";
import { useDispatch } from "react-redux";
import { AssetsEntitiesState } from "src/campaigns/assets/reducer";
import { MediasEntitiesState } from "src/campaigns/medias/reducer";
import PromoteFlowModal from "src/campaigns/promote/PromoteFlowModal";
import { Campaign } from "src/campaigns/types";
import { colors, fonts } from "src/constants";
import { fetchLinkForPlug } from "src/links/actions";
import { InstructionElementType } from "src/marketplace/utils/types";
import { turnPluralToSingular } from "src/utils/functions/helpers";
import { clearButton } from "src/utils/styles/snippets";
import styled, { css } from "styled-components";

const Main = styled.footer`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Buttons = styled.div`
    display: flex;
    gap: 12px;
`;

const buttonStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 8px 0px;
    flex: 1;
    color: ${colors.black};
    background-color: ${colors.quaternaryLightGray};
    border-radius: 6px;
    font-size: ${fonts.subtitle}px;
    transition: 0.1s ease-in;
    cursor: pointer;

    :hover {
        color: ${colors.black};
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.secondaryGray};
        }
    }
`;

const CopyLinkButton = styled.button`
    ${clearButton()};
    ${buttonStyles};
`;

const DownloadButton = styled.a`
    ${buttonStyles};
`;

const ButtonIcon = styled.img`
    width: 16px;
    height: 16px;
`;

interface Props {
    campaign: Campaign;
    currentInstructionElement: InstructionElementType | "";
    medias: MediasEntitiesState;
    assets: AssetsEntitiesState;
    currentItemId: number;
}

const InstructionElementsCarouselFooter = ({
    campaign,
    currentInstructionElement,
    medias,
    assets,
    currentItemId
}: Props) => {
    const dispatch = useDispatch();

    function handleDownloadUrl(): string {
        if (currentInstructionElement === "Media") {
            return medias[currentItemId].url;
        } else {
            return assets[currentItemId].url;
        }
    }

    const currentInstructionElementIsNotScript =
        currentInstructionElement !== "Scripts";

    const currentInstructionElementIsMediaOrAsset =
        currentInstructionElement === "Media" ||
        currentInstructionElement === "Assets";

    return (
        <Main>
            {currentInstructionElementIsNotScript && (
                <PromoteFlowModal campaign={campaign} inModal />
            )}

            {currentInstructionElementIsMediaOrAsset && (
                <Buttons>
                    {currentInstructionElement === "Media" && (
                        <CopyLinkButton
                            onClick={() =>
                                dispatch(
                                    fetchLinkForPlug(medias[currentItemId])
                                )
                            }
                        >
                            <ButtonIcon
                                src="/modal-link-icon.svg"
                                alt="copy link icon"
                            />
                            Copy Link
                        </CopyLinkButton>
                    )}

                    <DownloadButton target="_blank" href={handleDownloadUrl()}>
                        <ButtonIcon
                            src="/modal-download-icon.svg"
                            alt="download icon"
                        />
                        Download{" "}
                        {turnPluralToSingular(currentInstructionElement)}
                    </DownloadButton>
                </Buttons>
            )}
        </Main>
    );
};

export default InstructionElementsCarouselFooter;
