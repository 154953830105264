import React from "react";
import styled from "styled-components";
import OutlineContainer from "../settings/components/OutlineContainer";
import { ReactComponent as Elipsis } from "../../components/icons/ellipsis-button.svg";
import { FlexVerticallyCentered } from "../../components/CommonStyles";
import { colors } from "../../constants";
import { PublisherOffer } from "../types";
import { formatDuration, intervalToDuration } from "date-fns";
import { formatNumber } from "../../util";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
    rateFormModalOpen,
    setAccountId,
    setPublisherOfferId,
    setIsRateFormEdit
} from "src/ui/profile/actions";

const Main = styled(OutlineContainer)`
    padding: 12px;
    ${FlexVerticallyCentered}
    justify-content: space-between;
    background-color: ${colors.white};
    cursor: default;
`;

const EditButton = styled(Elipsis)`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px 4px 4px;

    & > * ~ * {
        margin-top: 4px;
    }
`;

const Title = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
`;

const Payment = styled.div`
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: ${colors.secondaryGreen};
`;

interface Props {
    publisherOffer: PublisherOffer;
    accountId: number;
}

const RateItem = ({ publisherOffer, accountId }: Props) => {
    const dispatch = useDispatch();

    const publisherOfferPost = publisherOffer.publisherOfferPosts[0];
    const time = () => {
        if (publisherOfferPost.permanent) return " (Permanent)";
        if (!publisherOfferPost.duration) return null;

        const duration = publisherOfferPost.duration || 0;
        return ` (${formatDuration(
            intervalToDuration({ start: 0, end: duration * 1000 }),
            {
                format: ["days", "hours"]
            }
        )})`;
    };

    const amount = () => {
        const rawAmount = publisherOfferPost.publisherOfferPayments[0].amount;
        return `$${formatNumber(rawAmount.toFixed(2))}`;
    };

    const paymentType = () => {
        const payment = publisherOfferPost.publisherOfferPayments[0];
        if (payment.customType) return ` ${payment.customType}`;

        switch (payment.payment?.type) {
            case "flat_rate":
                return " Flat Rate";
            case "dollars_per_k":
                return " per K";
            default:
                return "";
        }
    };

    function openRateEdit(): void {
        dispatch(setIsRateFormEdit(true));
        dispatch(setAccountId(accountId));
        dispatch(setPublisherOfferId(publisherOffer.id));
        dispatch(push("/profile/accounts/rates"));
    }

    return (
        <Main>
            <Text>
                <Title>
                    {publisherOfferPost.customType ||
                        publisherOfferPost.placement.type}
                    {time()}
                </Title>
                <Payment>
                    <strong>{amount()}</strong>
                    {paymentType()}
                </Payment>
            </Text>
            <EditButton onClick={() => openRateEdit()} fill="#C4C4C4" />
        </Main>
    );
};

export default RateItem;
