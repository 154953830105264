import { all, call, put, takeEvery } from "@redux-saga/core/effects";
import { goBack } from "connected-react-router";
import { getApi, post } from "src/Api";
import { Action } from "src/types";
import {
    addMediaToFillOrderFailure,
    ADD_MEDIA_TO_FILL_ORDER,
    cancelShoutoutFailure,
    CANCEL_SHOUTOUT,
    changeMediaForFillOrder,
    checkShoutoutHistoryFailure,
    checkShoutoutHistorySuccess,
    CHECK_SHOUTOUT_HISTORY,
    deleteMediaFromFillOrderFailure,
    DELETE_MEDIA_FROM_FILL_ORDER,
    fetchShoutoutsFailure,
    fetchShoutoutsSuccess,
    FETCH_SHOUTOUTS,
    removeVerificationFailure,
    REMOVE_VERIFICATION,
    requestVerificationFailure,
    REQUEST_VERIFICATION
} from "./actions";

function* fetchShoutoutsRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            getApi,
            "/get_all_live_shoutouts_for_publisher3",
            { ...payload, userid: localStorage.getItem("userid") }
        );

        if (status === 200) {
            yield put(fetchShoutoutsSuccess(data));
        } else {
            yield put(fetchShoutoutsFailure("Fetch shoutouts failure"));
        }
    } catch (error) {
        yield put(fetchShoutoutsFailure("Fetch shoutouts failure"));
    }
}

function* addMediaToFillOrderRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/api/add_media_to_fill_order",
            {
                fill_order_id: payload.fill_order_id,
                filename: payload.filename
            }
        );

        if (status === 200) {
            yield put(changeMediaForFillOrder(payload));
        } else {
            window.alert("Something went wrong. Please Refresh.");
            yield put(
                addMediaToFillOrderFailure("Add media to fill order failure")
            );
        }
    } catch (error) {
        window.alert("Something went wrong. Please Refresh.");
        yield put(
            addMediaToFillOrderFailure("Add media to fill order failure")
        );
    }
}

function* deleteMediaFromFillOrderRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/api/delete_media_from_fill_order",
            { fill_order_id: payload.fill_order_id }
        );

        if (status === 200) {
            yield put(changeMediaForFillOrder(payload));
        } else {
            window.alert("Something went wrong. Please Refresh.");
            yield put(
                deleteMediaFromFillOrderFailure(
                    "Delete media from fill order failure"
                )
            );
        }
    } catch (error) {
        window.alert("Something went wrong. Please Refresh.");
        yield put(
            deleteMediaFromFillOrderFailure(
                "Delete media from fill order failure"
            )
        );
    }
}

function* requestVerificationRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/api/request_verification_for_fill_orders",
            { ...payload, userid: localStorage.getItem("userid") }
        );

        if (status === 200) {
            yield put(goBack());
        } else {
            window.alert("Something went wrong. Please Refresh.");
            yield put(
                requestVerificationFailure(
                    "Request verification request failure"
                )
            );
        }
    } catch (error) {
        window.alert("Something went wrong. Please Refresh.");
        yield put(
            requestVerificationFailure("Request verification request failure")
        );
    }
}

function* removeVerificationRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/api/remove_verification_request_for_fill_orders",
            { ...payload, userid: localStorage.getItem("userid") }
        );

        if (status === 200) {
            yield put(goBack());
        } else {
            window.alert("Something went wrong. Please Refresh.");
            yield put(
                removeVerificationFailure("Remove verification request failure")
            );
        }
    } catch (error) {
        window.alert("Something went wrong. Please Refresh.");
        yield put(
            removeVerificationFailure("Remove verification request failure")
        );
    }
}

function* checkShoutoutHistoryRequest() {
    try {
        const { data, status } = yield call(getApi, "/has_shoutout_history", {
            userid: localStorage.getItem("userid")
        });

        if (status === 200) {
            yield put(checkShoutoutHistorySuccess(data));
        } else {
            yield put(
                checkShoutoutHistoryFailure("Check shoutout history failure")
            );
        }
    } catch (error) {
        yield put(
            checkShoutoutHistoryFailure("Check shoutout history failure")
        );
    }
}

function* cancelShoutoutRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/api/cancel_shoutout_fill_orders",
            { ...payload, userid: localStorage.getItem("userid") }
        );

        if (status === 200) {
            yield put(goBack());
        } else {
            yield put(cancelShoutoutFailure("Cancel shoutout failure"));
        }
    } catch (error) {
        yield put(cancelShoutoutFailure("Cancel shoutout failure"));
    }
}

export default function* shoutoutsSaga() {
    yield all([
        takeEvery(FETCH_SHOUTOUTS.REQUEST, fetchShoutoutsRequest),
        takeEvery(ADD_MEDIA_TO_FILL_ORDER.REQUEST, addMediaToFillOrderRequest),
        takeEvery(
            DELETE_MEDIA_FROM_FILL_ORDER.REQUEST,
            deleteMediaFromFillOrderRequest
        ),
        takeEvery(REQUEST_VERIFICATION.REQUEST, requestVerificationRequest),
        takeEvery(REMOVE_VERIFICATION.REQUEST, removeVerificationRequest),
        takeEvery(CHECK_SHOUTOUT_HISTORY.REQUEST, checkShoutoutHistoryRequest),
        takeEvery(CANCEL_SHOUTOUT.REQUEST, cancelShoutoutRequest)
    ]);
}
