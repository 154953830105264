import React from "react";
import styled from "styled-components";
import PulseLoader from "../components/PulseLoader";

const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 310px;
`;

const AnalyticsLoader = () => {
    return (
        <LoaderContainer>
            <PulseLoader />
        </LoaderContainer>
    );
};

export default AnalyticsLoader;
