import React from "react";
import { SocialAccount } from "../../types";
import AccountIcon from "./AccountIcon";
import styled from "styled-components";
import HideOverflow from "../../../components/HideOverflow";
import { colors, maxWidthDesktop, fonts } from "../../../constants";
import { shortenNumber } from "../../../util";
import Platforms from "../../../social-accounts/Platforms";
import Tag from "../../../components/Tag";
import { BigBlueButton, BigOutlineButton } from "../../components/Buttons";
import PaddingNoTop from "../../../components/PaddingNoTop";
import HorizontalButtonSpacer from "../../components/HorizontalButtonSpacer";

const Main = styled("section")`
    margin-top: 20px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;

    @media (max-width: ${maxWidthDesktop}px) {
        margin-top: 0px;
        border: ${colors.transparent} solid 1px;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

const Section = styled.div`
    display: flex;
    padding: 15px 15px 10px;
`;

const InfoContainer = styled.div`
    width: calc(100% - 105px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const TitleRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
`;

const Subtitle = styled.div`
    margin: 6px 0;
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
    color: ${colors.primaryGray};
`;

const Verified = styled.img`
    margin-left: auto;
`;

interface Props {
    account: SocialAccount;
    editCallback?: VoidFunction;
}

const ViewHeader = ({ account, editCallback }: Props) => {
    function viewCallback() {
        const platform = Platforms[account.platform] || Platforms.other;
        const urlToOpen = platform.urlCallback(account.username);

        if (urlToOpen) {
            window.open(urlToOpen);
        }
    }

    return (
        <Main>
            <Section>
                <AccountIcon
                    large
                    platform={account.platform}
                    avatar={account.avatar}
                    verified={account.verified}
                />
                <InfoContainer>
                    <TitleRow>
                        <HideOverflow>{account.username}</HideOverflow>
                        {account.verified && (
                            <Verified src="/verified_account.svg" />
                        )}
                    </TitleRow>
                    <Subtitle>
                        <strong>
                            {shortenNumber(parseInt(account.followers), 1)}
                        </strong>{" "}
                        Followers on{" "}
                        {Platforms[account.platform]
                            ? Platforms[account.platform].title
                            : account.platform}
                    </Subtitle>
                    <div style={{ display: "flex" }}>
                        {account.theme && (
                            <>
                                <Tag capitalize text={account.theme} />
                            </>
                        )}
                    </div>
                </InfoContainer>
            </Section>
            <PaddingNoTop>
                <HorizontalButtonSpacer>
                    {!editCallback ? (
                        <BigOutlineButton onClick={viewCallback}>
                            View
                        </BigOutlineButton>
                    ) : (
                        <>
                            <BigOutlineButton onClick={editCallback}>
                                Edit
                            </BigOutlineButton>
                            <BigBlueButton onClick={viewCallback}>
                                View
                            </BigBlueButton>
                        </>
                    )}
                </HorizontalButtonSpacer>
            </PaddingNoTop>
        </Main>
    );
};

export default ViewHeader;
