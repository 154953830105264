import { colors } from "src/constants";
import styled from "styled-components";

export const NotificationBadgeButton = styled.div<{ selected?: boolean }>`
    display: flex;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 6px;
    background-color: ${props =>
        props.selected ? colors.primaryBlue : colors.quaternaryLightGray};
    font-size: 13px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: ${props => (props.selected ? colors.white : colors.primaryGray)};
    :hover {
        opacity: 0.7;
    }
`;
