import React, { useState } from "react";
import { ExternalPage } from "src/campaigns/types";
import Header from "../../components/Header";
import PaddingNoTop from "../../components/PaddingNoTop";
import SelectExternalPageRow from "./SelectExternalPageRow";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import SearchBar from "../../components/SearchBar";
import MainContainer from "src/components/MainContainer";

interface Props {
    externalPages: ExternalPage[];
    nextCallback: (page: ExternalPage) => void;
    goBack: VoidFunction;
}

const SelectExternalPage = ({ externalPages, nextCallback, goBack }: Props) => {
    const [currentPage, setCurrentPage] = useState<ExternalPage>();
    const [searchString, setSearchString] = useState("");

    return (
        <>
            <SimpleNavbar
                title={"Upload Media"}
                left={<NavButton back onClick={goBack} />}
                right={
                    <NavButton
                        text={"Next"}
                        onClick={() => {
                            if (currentPage) {
                                nextCallback(currentPage);
                            }
                        }}
                        disabled={!currentPage}
                    />
                }
            />

            <MainContainer>
                <Header
                    title={"Select an Article"}
                    subtitle={"Which article is this ad for?"}
                />
                <PaddingNoTop>
                    <SearchBar
                        searchString={searchString}
                        updateSearchString={setSearchString}
                    />
                </PaddingNoTop>
                <PaddingNoTop>
                    {externalPages
                        .filter(page => {
                            if (searchString.length === 0) {
                                return true;
                            } else {
                                return page.title
                                    .toLowerCase()
                                    .includes(searchString.toLowerCase());
                            }
                        })
                        .map(page => {
                            return (
                                <SelectExternalPageRow
                                    key={page.id}
                                    externalPage={page}
                                    selected={Boolean(
                                        currentPage &&
                                            currentPage.id === page.id
                                    )}
                                    onClick={() => setCurrentPage(page)}
                                />
                            );
                        })}
                </PaddingNoTop>
            </MainContainer>
        </>
    );
};

export default SelectExternalPage;
