import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import LoadingButton from "src/components/LoadingButton";
import { colors, fonts } from "src/constants";
import { Media } from "src/media/types";
import EmojiModal from "src/modals/EmojiModal";
import Modal from "src/modals/Modal";
import { removeMediaStatusNotification } from "src/notifications/actions";
import { MediaStatus } from "src/notifications/types";
import { GlobalState } from "src/reducers";
import { clearProfileModals } from "src/ui/profile/actions";
import styled from "styled-components";
import MediaPreview from "./components/MediaPreview";
import PostMediaModal from "./PostMediaModal";

const Main = styled.div`
    min-height: 500px;
    padding: 20px;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    margin: 10px auto 10px auto;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 18px 3px 18px;
`;

const UploadStatusModal = () => {
    const status = useSelector<GlobalState, MediaStatus | null>(
        state => state.notifications.mediaStatusNotification
    );
    const [media, setMedia] = useState<Media | null>(status?.media || null);
    const dispatch = useDispatch();

    const remove = (id?: number) => dispatch(removeMediaStatusNotification(id));
    const history = useHistory();

    if (!status || !status.media) return null;
    else if (status.type === "pending")
        return (
            <EmojiModal icon="/clapper_board.svg">
                <>
                    <Title>Upload Pending</Title>
                    <Subtitle>
                        Your media is under review and pending approval. We will
                        notify you when it is approved for use.
                    </Subtitle>
                </>
                <ButtonContainer>
                    {media && (
                        <LoadingButton
                            text="Show Media Details"
                            height="48px"
                            width="100%"
                            backgroundColor={colors.white}
                            textColor={colors.primaryBlue}
                            borderColor={colors.primaryBlue}
                            margin="auto 13px 15px auto"
                            onClick={() => {
                                history.push(
                                    `/campaign/${media.campaignId}?mediaId=${media.id}`
                                );
                                remove(status.media?.id);
                            }}
                        />
                    )}
                    <LoadingButton
                        text="Okay"
                        height="48px"
                        width="100%"
                        backgroundColor={colors.primaryBlue}
                        textColor={colors.white}
                        onClick={() => remove(status.media?.id)}
                    />
                </ButtonContainer>
            </EmojiModal>
        );
    else if (status.type === "approved")
        return (
            <Modal onClick={() => remove(status.media?.id)}>
                {!media ? (
                    <Main>
                        <MediaPreview media={status.media} />
                        <>
                            <Title>Media Approved!</Title>
                            <Subtitle>
                                Your media has successfully been approved to be
                                used on the Plug. Click the button below to
                                start promoting your media.
                            </Subtitle>
                        </>
                        <ButtonContainer>
                            <LoadingButton
                                text="Promote Media"
                                height="48px"
                                width="100%"
                                backgroundColor={colors.primaryBlue}
                                textColor={colors.white}
                                margin="auto 13px 15px auto"
                                onClick={() => setMedia(status.media)}
                            />
                            <LoadingButton
                                text="View Media Details"
                                height="48px"
                                width="100%"
                                backgroundColor={colors.white}
                                textColor={colors.primaryBlue}
                                borderColor={colors.primaryBlue}
                                onClick={() => {
                                    history.push(
                                        `/campaign/${status.media?.campaignId}?mediaId=${status.media?.id}`
                                    );
                                    remove(status.media?.id);
                                }}
                            />
                        </ButtonContainer>
                    </Main>
                ) : (
                    <PostMediaModal
                        media={media}
                        onClose={() => remove(status.media?.id)}
                    />
                )}
            </Modal>
        );
    else return null;
};

export default UploadStatusModal;
