import React from "react";
import { colors, fonts } from "src/constants";
import styled from "styled-components";

const TimeContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid ${colors.blueGrey2};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 16px;
    width: 100%;
`;

const TimeSection = styled.div<{ disabled: boolean }>`
    display: flex;
    flex-direction: column;
    text-align: right;
    color: ${props => (props.disabled ? colors.primaryGray : colors.black)};
`;

const TimeHeader = styled.div`
    font-size: ${fonts.smallTitle}px;
    font-weight: 400;
`;

const Time = styled.div`
    font-size: 20px;
    font-weight: 700;
`;
const TimerSection = ({ times }: { times: string[] }) => {
    return (
        <TimeContainer>
            <TimeSection disabled={!times[0]}>
                <TimeHeader>Days</TimeHeader>
                <Time>{times[0] || "00"}</Time>
            </TimeSection>
            <TimeSection disabled={!times[1]}>
                <TimeHeader>Hours</TimeHeader>
                <Time>{times[1] || "00"}</Time>
            </TimeSection>
            <TimeSection disabled={!times[2]}>
                <TimeHeader>Minutes</TimeHeader>
                <Time>{times[2] || "00"}</Time>
            </TimeSection>
            <TimeSection disabled={!times[3]}>
                <TimeHeader>Seconds</TimeHeader>
                <Time>{times[3] || "00"}</Time>
            </TimeSection>
        </TimeContainer>
    );
};
export default TimerSection;
