import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";

const pages = (state: GlobalState) => state.pagination.medias.pages;
const currentPage = (state: GlobalState) => state.pagination.medias.currentPage;

export const getPaginatedSearchMedia = createSelector(
    pages,
    currentPage,
    (pages, currentPage) => {
        let result: number[] = [];

        for (let i = 0; i < currentPage; i++) {
            result = [...result, ...pages[i].ids];
        }

        return result;
    }
);

export const selectMediaFilters = (state: GlobalState) =>
    state.pagination.medias.filters;
