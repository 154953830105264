import React, { useCallback, forwardRef, useImperativeHandle } from "react";
import { colors, fonts } from "src/constants";
import styled from "styled-components";
import { PageReference } from "../hooks/usePages";
// import { ReactComponent as DownloadIcon } from "src/components/icons/Download/Download.svg";
import { clearButton } from "src/utils/styles/snippets";
import { ColumnsReference } from "../PlugTable";
import { TableRow } from "../types";
import { download } from "src/util";
import { throttle } from "lodash";

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 18px;
    padding-left: 16px;
    padding-right: 4px;
`;
const Showing = styled.div`
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
`;
const PageBtns = styled.div`
    display: flex;
    align-items: center;
`;
const ArrowBtn = styled.button<{ isDisabled?: boolean }>`
    border-radius: 50%;
    background-color: ${props =>
        props.disabled ? colors.primaryGray : colors.white};
    width: 16px;
    height: 16px;
    display: grid;
    place-items: center;
    transition: box-shadow 0.2s ease;
    &:last-child {
        margin-left: 8px;
    }
    &:hover {
        box-shadow: ${props =>
            props.isDisabled
                ? `none`
                : `0px 0px 0px 1px ${colors.tertiaryGray};`};
    }
`;
const Arrow = styled.img<{ left?: true }>`
    width: auto;
    height: 8px;
    transform: ${props => props.left && "rotate(180deg) translateX(1px)"};
`;
const Btn = styled.button`
    ${clearButton()};
    margin-right: 16px;
`;
const DownloadIcon = styled.img`
    width: 14px;
    height: auto;
`;
const RefreshIcon = styled.img`
    width: 20px;
    height: auto;
`;

export type TableFooterRef = {
    handleDownload: () => void;
};

interface Props {
    pageReference: PageReference;
    tableLength: number;
    columnsReference: ColumnsReference;
    data: TableRow[];
    filenamePrefix: string;
    serverPaginate: boolean;
    serverCount?: number;
    includedRawColumnsInCsv?: string[];
    refreshHandler?: VoidFunction;
}

const TableFooter = (
    {
        pageReference,
        tableLength,
        columnsReference,
        data,
        filenamePrefix,
        serverPaginate,
        serverCount,
        includedRawColumnsInCsv,
        refreshHandler
    }: Props,
    ref: React.Ref<TableFooterRef>
) => {
    const { pageIndices, pageNext, pageBack, currentPage } = pageReference;
    if (includedRawColumnsInCsv) {
        Object.keys(columnsReference).forEach(coreKey => {
            if (includedRawColumnsInCsv?.includes(coreKey)) {
                console.error(
                    `PLUG TABLE: Included raw column additions should NOT already be included as a column in the plug table. COLUMN: ${coreKey}`
                );
            }
        });
    }
    const handleDownload = () => {
        const columns = includedRawColumnsInCsv
            ? [...Object.keys(columnsReference), ...includedRawColumnsInCsv]
            : Object.keys(columnsReference);
        const columnsStr = `${columns.join(",")}\n`;

        const rows = data.map(row => {
            const res = columns.map(column => {
                const cell = row.rowData[column];
                const rawCell = row.raw[column];
                if (
                    cell &&
                    !cell.isNull &&
                    (cell.data === false || cell.data || cell.data === 0)
                ) {
                    return cell.data;
                } else if (rawCell || rawCell === 0 || rawCell === false) {
                    return `${rawCell}`;
                } else {
                    return "";
                }
            });
            return res.join(",");
        });

        const rowStr = rows.join("\n");
        const csv = columnsStr + rowStr;

        const filename =
            filenamePrefix
                .toLowerCase()
                .split(" ")
                .join("-") +
            "-" +
            new Date().toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit"
            });
        download(csv, `${filename}.csv`, "text/csv;encoding:utf-8");
    };
    useImperativeHandle(ref, () => ({
        handleDownload
    }));
    return (
        <Footer>
            {serverPaginate ? (
                <Showing>
                    {`Page: ${currentPage} ${
                        serverCount
                            ? `of ${Math.ceil(
                                  serverCount / pageReference.resultsPerPage
                              )}`
                            : ""
                    }`}

                    {serverCount &&
                        ` | Showing ${
                            currentPage === 1
                                ? 1
                                : (currentPage - 1) *
                                      pageReference.resultsPerPage +
                                  1
                        }-${
                            serverCount -
                                currentPage * pageReference.resultsPerPage <
                            0
                                ? serverCount
                                : currentPage * pageReference.resultsPerPage
                        } of ${serverCount}`}
                </Showing>
            ) : (
                <Showing>
                    {tableLength > 0
                        ? `Showing ${pageIndices[0] + 1}-${
                              pageNext.disabled ? tableLength : pageIndices[1]
                          } of ${tableLength}`
                        : "No Results"}
                </Showing>
            )}
            <PageBtns>
                {refreshHandler && (
                    <Btn
                        onClick={refreshHandler}
                        style={{ marginRight: "8px" }}
                    >
                        <RefreshIcon src="/refresh.svg" alt="" />
                    </Btn>
                )}
                <Btn onClick={handleDownload}>
                    <DownloadIcon
                        src="/download/download.png"
                        alt="download as csv"
                    />
                </Btn>

                <ArrowBtn
                    onClick={pageBack.go}
                    disabled={pageBack.disabled}
                    isDisabled={pageBack.disabled}
                >
                    <Arrow src="/caret-hovered.svg" left={true} />
                </ArrowBtn>
                <ArrowBtn
                    onClick={pageNext.go}
                    disabled={pageNext.disabled}
                    isDisabled={pageNext.disabled}
                >
                    <Arrow src="/caret-hovered.svg" />
                </ArrowBtn>
            </PageBtns>
        </Footer>
    );
};

export default forwardRef(TableFooter);
