import React from "react";
import ActionsModal from "../../../modals/ActionsModal";
import { useDispatch } from "react-redux";
import { directBuyDetailsModal } from "../../../ui/direct-buys/actions";
import { DirectBuyDetailsModalState } from "../../../ui/direct-buys/reducer";

const DirectBuyDetailsActionsModal = () => {
    const dispatch = useDispatch();
    return (
        <ActionsModal
            actions={[
                {
                    text: "Reject Offer",
                    callback: () => {
                        dispatch(
                            directBuyDetailsModal(
                                DirectBuyDetailsModalState.rejectionReason
                            )
                        );
                    },
                    negative: true
                }
            ]}
        />
    );
};

export default DirectBuyDetailsActionsModal;
