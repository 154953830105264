import { Action, NormalizedAction } from "../../types";
import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../../reducers";

export const FETCH_CAMPAIGN_CUSTOM_LINKS = {
    REQUEST: "FETCH_CAMPAIGN_CUSTOM_LINKS_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_CUSTOM_LINKS_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_CUSTOM_LINKS_FAILURE"
};

export const fetchCampaignCustomLinks = (campaignId: number): Action => ({
    type: FETCH_CAMPAIGN_CUSTOM_LINKS.REQUEST,
    payload: { campaignId }
});

export const fetchCampaignCustomLinksSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { campaignId: number }
): NormalizedAction => ({
    type: FETCH_CAMPAIGN_CUSTOM_LINKS.SUCCESS,
    response,
    payload
});

export const fetchCampaignCustomLinksFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_CUSTOM_LINKS.FAILURE,
    payload: { error }
});

// Create Custom Links ---------------------------------------------------------

export const CREATE_CAMPAIGN_CUSTOM_LINK = {
    REQUEST: "CREATE_CAMPAIGN_CUSTOM_LINK_REQUEST",
    SUCCESS: "CREATE_CAMPAIGN_CUSTOM_LINK_SUCCESS",
    FAILURE: "CREATE_CAMPAIGN_CUSTOM_LINK_FAILURE"
};

export interface CreateCustomLinkParams {
    mediaId: number;
    campaignId: number;
    name: string;
}

export const createCampaignCustomLink = (
    params: CreateCustomLinkParams
): Action => ({
    type: CREATE_CAMPAIGN_CUSTOM_LINK.REQUEST,
    payload: params
});

export const createCampaignCustomLinkSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { campaignId: number }
): NormalizedAction => ({
    type: CREATE_CAMPAIGN_CUSTOM_LINK.SUCCESS,
    response,
    payload
});

export const createCampaignCustomLinkFailure = (error: string): Action => ({
    type: CREATE_CAMPAIGN_CUSTOM_LINK.FAILURE,
    payload: { error }
});
