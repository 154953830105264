import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { AnimatePresence } from "framer-motion";
import HalfScreenModal from "src/modals/HalfScreenModal";
import SimpleNavbar from "src/components/SimpleNavbar";
import { addNotification } from "src/ui/notifications/actions";
import { GlobalState } from "src/reducers";
import { colors, fonts } from "src/constants";
import linkIcon from "./linkIcon.svg";
import codeIcon from "./codeIcon.svg";
import { setShareReferralModal } from "src/ui/referrals/actions";
import { flex, flexRowCenter } from "src/utils/styles/snippets";

const HeaderTitle = styled("h2")`
    margin: 0px;
    padding: 0px;
    color: ${colors.black};
    font-size: ${fonts.subtitle}px;
    font-weight: bold;
    line-height: 17px;
`;

const CopyButtonContainer = styled("div")`
    ${flex("column")};
`;

const CopyButtonElement = styled("button")`
    ${flex("row", "auto", "center")};
    width: 100%;
    height: 64px;
    padding: 0px 18px;
    text-align: left;

    :focus {
        outline: none;
    }
`;

interface IconProps {
    src: string;
}

const ButtonIcon = styled("img").attrs((props: IconProps) => ({
    src: props.src,
    alt: "Referral Button Icon"
}))<IconProps>`
    margin-right: 20px;
`;

const ButtonText = styled("span")`
    color: ${colors.black};
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    line-height: 17px;
`;

const CancelButton = styled("button")`
    ${flexRowCenter()};
    width: 100%;
    height: 64px;
    margin-top: 1px;
    border-top: ${colors.mediumTeal} solid 1px;
    color: ${colors.black};
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    line-height: 17px;

    :focus {
        outline: none;
    }
`;

interface CopyButtonProps {
    category: "link" | "code";
    copyElement: string;
    icon: string;
    text: string;
    closeModal: VoidFunction;
}

const CopyButton = (props: CopyButtonProps) => {
    const { category, copyElement, icon, text, closeModal } = props;

    const dispatch = useDispatch();
    function handleOnCopy(text: string): void {
        if (category === "link") {
            dispatch(addNotification(`Copied Link: ${text}`));
        } else {
            dispatch(addNotification(`Copied Code: ${text}`));
        }
    }

    return (
        <CopyToClipboard
            text={copyElement}
            onCopy={() => handleOnCopy(copyElement)}
        >
            <CopyButtonElement onClick={closeModal}>
                <ButtonIcon src={icon} />
                <ButtonText>{text}</ButtonText>
            </CopyButtonElement>
        </CopyToClipboard>
    );
};

const ShareReferralModal = () => {
    const isOpen = useSelector(
        (state: GlobalState) => state.ui.referrals.shareReferralModalOpen
    );

    const referralCode = useSelector(
        (state: GlobalState) => state.profile.referralCode
    );

    const referralCodeLink = `https://www.theplug.co/public/v1/referral/${referralCode}`;

    const dispatch = useDispatch();
    function closeModal(): void {
        dispatch(setShareReferralModal(false));
    }

    if (!referralCode) return null;

    return (
        <AnimatePresence>
            {isOpen && (
                <HalfScreenModal closeModal={closeModal}>
                    {/* Header */}
                    <SimpleNavbar
                        title={<HeaderTitle>Share Referral Link</HeaderTitle>}
                    />

                    {/* Referral Link / Code */}
                    <CopyButtonContainer>
                        {/* Referral Link */}
                        <CopyButton
                            category="link"
                            copyElement={referralCodeLink}
                            icon={linkIcon}
                            text="Copy Referral Link"
                            closeModal={closeModal}
                        />
                        {/* Referral Code */}
                        <CopyButton
                            category="code"
                            copyElement={referralCode}
                            icon={codeIcon}
                            text="Copy Referral Code"
                            closeModal={closeModal}
                        />
                    </CopyButtonContainer>

                    {/* Cancel Button */}
                    <CancelButton onClick={closeModal}>Cancel</CancelButton>
                </HalfScreenModal>
            )}
        </AnimatePresence>
    );
};

export default ShareReferralModal;
