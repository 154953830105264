import React, { useState } from "react";
import { useSelector } from "react-redux";
import GenericUserCell from "src/components/cells/GenericUserCell";
import { FlexVCSpaceBetween } from "src/components/CommonStyles";
import SearchBar from "src/components/SearchBar";
import { CustomLoading } from "src/components/SkeletonLoader";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import GroupUserCellAction from "../group-user-settings/components/GroupUserCellAction";
import {
    getGroupMembers,
    selectConversationOwnAdminStatus
} from "../group-user-settings/selectors";
import { GroupUserSettingsWithProfile } from "../types/GroupUserSettings";

const Main = styled.div`
    padding: 18px;
`;

const Header = styled.div`
    ${FlexVCSpaceBetween};
    text-align: left;
    align-items: flex-start;
    font-size: 14px;
    padding-bottom: 18px;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
`;

const MemberCount = styled.div`
    margin-top: 4px;
    color: ${colors.primaryGray};
`;

const Add = styled.div`
    color: ${colors.primaryBlue};
`;

const Search = styled(SearchBar)`
    padding-bottom: 20px;
`;

const UserList = styled.div`
    & > * ~ * {
        margin-top: 12px;
    }
`;

interface Props {
    conversationId: string;
    setManageModalUserId: (userId: string) => void;
    toggleAddMemeberModal: () => void;
    isAdminTab?: boolean;
}

const GroupMembersTab = ({
    conversationId,
    setManageModalUserId,
    toggleAddMemeberModal,
    isAdminTab
}: Props) => {
    const [searchString, updateSearchString] = useState("");

    const { users, userCount } = useSelector((state: GlobalState) =>
        getGroupMembers(state, conversationId, searchString, isAdminTab)
    );

    const isGroupAdmin = useSelector((state: GlobalState) =>
        selectConversationOwnAdminStatus(state, conversationId)
    );

    const title = isAdminTab ? "Admin" : "Member";
    const shouldShowPlural = userCount !== 1 ? "s" : "";

    function renderStatusText(user: GroupUserSettingsWithProfile) {
        if (user.isBanned) return "Banned";
        if (user.isAdmin) return "Admin";
        return "";
    }

    return (
        <Main>
            <Header>
                <div>
                    <Title>Group {title}s</Title>
                    <MemberCount>
                        {userCount} {title}
                        {shouldShowPlural}
                    </MemberCount>
                </div>
                {isGroupAdmin && <Add onClick={toggleAddMemeberModal}>Add</Add>}
            </Header>
            <Search
                autoFocus={false}
                searchString={searchString}
                updateSearchString={updateSearchString}
            />
            <UserList>
                {users.map(user =>
                    user.profile ? (
                        <GenericUserCell
                            key={user.userId}
                            profilePhotoUrl={user.profile.avatarUrl}
                            title={user.profile.username}
                            subtitle={renderStatusText(user)}
                            size={46}
                            right={
                                <GroupUserCellAction
                                    groupUserSettings={user}
                                    isGroupAdmin={isGroupAdmin}
                                    onManageClick={() =>
                                        setManageModalUserId(user.userId)
                                    }
                                />
                            }
                        />
                    ) : (
                        <CustomLoading key={user.userId} height="46px" />
                    )
                )}
            </UserList>
        </Main>
    );
};

export default GroupMembersTab;
