import { schema } from "normalizr";

export const openBuy = new schema.Entity("openBuy");
export const openBuyList = new schema.Array(openBuy);

const openBuyPoster = new schema.Entity(
    "openBuyPoster",
    {},
    {
        idAttribute: "openBuyId"
    }
);
export const openBuyPosterList = new schema.Array(openBuyPoster);
