import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchTag from "src/components/SearchTag";
import { colors, sizes } from "src/constants";
import {
    DropdownTitle,
    InstructionElementType
} from "src/marketplace/utils/types";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import SelectionHeader from "../../components/SelectionHeader";
import InstructionsSearchBar from "./InstructionsSearchBar";
import { setCurrentInstructionElement } from "src/ui/instructions-sets/actions";
import { getCurrentInstructionElement, loadingSelector } from "../selectors";
import {
    CreateDropdownItemLoading,
    CreateDropdownItemLoadingContainer
} from "../SkeletonLoader";
import CreateDropdownItems from "./CreateDropdownItems";
import { EventSubmit } from "src/utils/types/form";
import { fetchCampaignMedia } from "src/campaigns/medias/actions";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { fetchCampaignAsset } from "src/campaigns/assets/actions";
import { fetchCampaignScript } from "src/campaigns/scripts/actions";

interface DropdownProps {
    selected: boolean;
}

const Main = styled.form<DropdownProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: ${props => {
        return props.selected ? "1000px" : `${sizes.selectionButton.height}px`;
        // : `${sizes.marketplace.instructions.dropdownHeight}px`;
    }};
    background-color: ${colors.secondaryLightGray};
    border-radius: 8px;
    overflow: hidden;
    transition: ${props => (props.selected ? "0.2s" : "0s")} ease-in;
`;

const SearchAndFilters = styled.article<DropdownProps>`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 10px;
`;

const SortAndFilters = styled.div`
    display: flex;
    gap: 10px;
`;

interface Props {
    title: DropdownTitle;
    subtitle: string;
}

const CreateDropdown = ({ title, subtitle }: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const currentLoadingState = useSelector((state: GlobalState) => {
        return loadingSelector(state, title);
    });
    const { currentCampaignId } = useSelector((state: GlobalState) => {
        return state.ui.campaign;
    });
    const { currentInstructionElement } = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets;
    });

    // Local State ---------------------------------------------------
    const [searchString, setSearchString] = useState("");
    const [itemsLoading, setItemsLoading] = useState(true);

    // Local Constants -----------------------------------------------
    const isDropdownSelected =
        useSelector(getCurrentInstructionElement) === title;

    // Local Functions -----------------------------------------------
    function handleSubmit(event: EventSubmit): void {
        event.preventDefault();
        if (
            currentCampaignId &&
            Number(searchString) &&
            currentInstructionElement.length > 0
        ) {
            if (currentInstructionElement === "Scripts") {
                dispatch(fetchCampaignScript(Number(searchString)));
            } else if (currentInstructionElement === "Media") {
                dispatch(
                    fetchCampaignMedia(currentCampaignId, Number(searchString))
                );
            } else if (currentInstructionElement === "Assets") {
                dispatch(
                    fetchCampaignAsset(currentCampaignId, Number(searchString))
                );
            } else {
                dispatch(
                    setPillNotificationText(
                        "You must input a number.",
                        "danger",
                        3500
                    )
                );
            }
        }
    }

    function setDropdown(): void {
        if (isDropdownSelected) dispatch(setCurrentInstructionElement(""));
        else dispatch(setCurrentInstructionElement(title));
    }

    // Effects -------------------------------------------------------
    useEffect(() => {
        setItemsLoading(currentLoadingState);
    }, [currentLoadingState]);

    // JSX -----------------------------------------------------------
    return (
        <Main onSubmit={handleSubmit} selected={isDropdownSelected}>
            <SelectionHeader
                title={title}
                subtitle={subtitle}
                selected={isDropdownSelected}
                onClick={setDropdown}
                shadow
                isDropdown
            />

            <SearchAndFilters selected={isDropdownSelected}>
                <InstructionsSearchBar
                    setSearchString={setSearchString}
                    margin="10px 0px 0px"
                    selectionOutline
                />
                <button type="submit" />

                {/* <SortAndFilters>
                    <SearchTag
                        onClick={() => console.log("Sort By")}
                        text="Sort By"
                        color="white"
                        iconSrc="/tag-teal-arrow-icon.svg"
                        margin="0px"
                    />
                    <SearchTag
                        onClick={() => console.log("SortAndFilters")}
                        text="Filters"
                        color="white"
                        iconSrc="/tag-teal-plus-icon.svg"
                        margin="0px"
                    />
                </SortAndFilters> */}
            </SearchAndFilters>

            {itemsLoading ? (
                <CreateDropdownItemLoadingContainer>
                    <CreateDropdownItemLoading />
                    <CreateDropdownItemLoading />
                    <CreateDropdownItemLoading />
                </CreateDropdownItemLoadingContainer>
            ) : (
                <CreateDropdownItems
                    title={title}
                    searchString={searchString}
                />
            )}
        </Main>
    );
};

export default CreateDropdown;
