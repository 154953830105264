import React, { useEffect } from "react";
import styled from "styled-components";
import { connect, useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { OldTransaction } from "../types";
import { fetchCashouts } from "../actions";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import TransactionCell from "./TransactionCell";
import FullscreenLoader from "../../components/FullscreenLoader";
import { flexRowCenter } from "src/utils/styles/snippets";
import { colors, maxWidthDesktop } from "src/constants";
import MainContainer from "src/components/MainContainer";
import { useHistory } from "react-router";
import Navbar from "src/components/Navbar";

const Main = styled("div")``;

const TransactionCells = styled("div")`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin: 20px auto;

    @media (max-width: ${maxWidthDesktop}px) {
        grid-gap: 0px;
        margin: 0px;
    }
`;

const EmptyContainer = styled("div")`
    ${flexRowCenter()};
    height: calc(100vh - 200px);
`;

const EmptyContainerText = styled("span")`
    display: block;
    color: ${colors.primaryGray};
`;

const TransactionHistory = () => {
    useEffect(() => {
        dispatchFetchCashouts();
    }, []);

    const transactions = useSelector((state: GlobalState) => {
        return state.profile.transactions;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();
    function dispatchFetchCashouts(): void {
        dispatch(fetchCashouts());
    }

    const navbar = (
        <SimpleNavbar
            title={"ACCOUNT HISTORY"}
            left={<NavButton back onClick={goBack} />}
        />
    );

    if (!transactions) {
        return (
            <>
                <Navbar back title="TRANSACTIONS" />
                <FullscreenLoader />
            </>
        );
    } else {
        return (
            <Main>
                <Navbar back marginBottom={20} title="TRANSACTIONS" />

                <MainContainer>
                    {transactions.length > 0 ? (
                        <TransactionCells>
                            {transactions.map((transaction, index) => {
                                return (
                                    <TransactionCell
                                        key={index}
                                        transaction={transaction}
                                    />
                                );
                            })}
                        </TransactionCells>
                    ) : (
                        <EmptyContainer>
                            <EmptyContainerText>
                                NO TRANSACTIONS YET
                            </EmptyContainerText>
                        </EmptyContainer>
                    )}
                </MainContainer>
            </Main>
        );
    }
};

export default TransactionHistory;
