import { Action } from "../../types";
import { SET_LAYOUT, LayoutType, SET_THEME, LayoutThemeType } from "./actions";

export interface LayoutUIState {
    type: LayoutType;
    theme: LayoutThemeType;
}

const initialLayoutState: LayoutUIState = {
    type: LayoutType.mobile,
    theme: LayoutThemeType.light
};

const layoutReducer = (state = initialLayoutState, action: Action) => {
    switch (action.type) {
        case SET_LAYOUT:
            return { ...state, type: action.payload.layout };
        case SET_THEME:
            return { ...state, theme: action.payload };
        default:
            return state;
    }
};

export default layoutReducer;
