import React, { useEffect, useRef, useState } from "react";
import { useScroll } from "react-use";
import { breakpoints, colors } from "src/constants";
import { hideScrollbar } from "src/utils/styles/snippets";
import styled from "styled-components";
import ScrollButton from "./ScrollButton";

const Main = styled.article`
    position: relative;
`;

interface HorizontalScrollContainerProps {
    backgroundColor?: string;
    border?: string;
    borderRadius?: number;
}

const HorizontalScrollContainer = styled.div<HorizontalScrollContainerProps>`
    background-color: ${props => props.backgroundColor || colors.transparent};
    border: ${props => props.border || "none"};
    border-radius: ${props => `${props.borderRadius}px` || "0px"};
    overflow: hidden;

    @media (max-width: ${breakpoints.mobile}px) {
        border-radius: 0px;
        border-right: none;
        border-left: none;
    }
`;

interface HorizontalScrollProps {
    modalOpen?: boolean;
    padding?: string;
    gap?: number;
}

const HorizontalScroll = styled.div<HorizontalScrollProps>`
    display: flex;
    gap: ${props => `${props.gap}px`};
    padding: ${props => props.padding};
    overflow-x: ${props => (props.modalOpen ? "hidden" : "scroll")};

    ${props => props.theme.isMobile && hideScrollbar()};
`;

interface Props {
    backgroundColor?: string;
    border?: string;
    borderRadius?: number;
    arrowTopOffset?: string;
    modalOpen?: boolean;
    horizontalScrollContainerPadding?: string;
    horizontalScrollPadding?: string;
    horizontalScrollGap?: number;
    children: React.ReactNode;
}

const ScrollContainer = ({
    backgroundColor,
    border,
    borderRadius,
    arrowTopOffset,
    modalOpen,
    horizontalScrollPadding,
    horizontalScrollGap,
    children
}: Props) => {
    const [scrolledToFarLeft, setScrolledToFarLeft] = useState(true);
    const [scrolledToFarRight, setScrolledToFarRight] = useState(false);

    const horizontalScrollRef = useRef<any>(null);

    const { x } = useScroll(horizontalScrollRef);

    useEffect(() => {
        if (horizontalScrollRef && horizontalScrollRef.current) {
            // Scrolled all the way to the left.
            if (x === 0) {
                setScrolledToFarLeft(true);
                setScrolledToFarRight(false);
            }
            // Scrolled all the way to the right.
            else if (
                x ===
                horizontalScrollRef.current.scrollWidth -
                    horizontalScrollRef.current.clientWidth
            ) {
                setScrolledToFarLeft(false);
                setScrolledToFarRight(true);
            }
            // Not scrolled all the way to the left, nor to the right.
            else if (x > 0) {
                setScrolledToFarLeft(false);
                setScrolledToFarRight(false);
            }
        }
    }, [x, horizontalScrollRef, horizontalScrollRef.current]);

    function scrollLeft(): void {
        if (horizontalScrollRef && horizontalScrollRef.current) {
            const xPosition = horizontalScrollRef.current.scrollLeft;
            const containerWidth = horizontalScrollRef.current.getBoundingClientRect()
                .width;

            horizontalScrollRef.current.scrollTo({
                left: xPosition - containerWidth,
                behavior: "smooth"
            });

            setScrolledToFarRight(false);
        }
    }

    function scrollRight(): void {
        if (horizontalScrollRef && horizontalScrollRef.current) {
            const xPosition = horizontalScrollRef.current.scrollLeft;
            const containerWidth = horizontalScrollRef.current.getBoundingClientRect()
                .width;

            horizontalScrollRef.current.scrollTo({
                left: xPosition + containerWidth,
                behavior: "smooth"
            });

            setScrolledToFarLeft(false);
        }
    }

    return (
        <Main>
            <ScrollButton
                onClick={scrollLeft}
                direction="left"
                scrolledToEnd={scrolledToFarLeft}
                top={arrowTopOffset}
            />

            <HorizontalScrollContainer
                backgroundColor={backgroundColor}
                border={border}
                borderRadius={borderRadius}
            >
                <HorizontalScroll
                    ref={horizontalScrollRef}
                    modalOpen={modalOpen}
                    padding={horizontalScrollPadding}
                    gap={horizontalScrollGap}
                >
                    {children}
                </HorizontalScroll>
            </HorizontalScrollContainer>

            <ScrollButton
                onClick={scrollRight}
                direction="right"
                scrolledToEnd={scrolledToFarRight}
                top={arrowTopOffset}
            />
        </Main>
    );
};

export default ScrollContainer;
