import { Moment } from "moment";
import { SelectedCampaigns } from "src/campaigns/modals/CampaignSelectionModal";
import { MediaFilters } from "./types";

const isSelectedCampaign = (campaign: {
    id: number;
    campaignName: string | boolean;
}): campaign is { id: number; campaignName: string } =>
    typeof campaign.campaignName === "string";

export const selectedCampaignsToArray = (
    selectedCampaigns: SelectedCampaigns
) => {
    return Object.keys(selectedCampaigns)
        .map(Number)
        .map((key: number) => ({
            id: key,
            campaignName: selectedCampaigns[key]
        }))
        .filter(isSelectedCampaign);
};

export const parseMediaSort = (sortBy: string) => {
    switch (sortBy) {
        case "Newest":
            return "uploadDate:desc";
        case "Oldest":
            return "uploadDate:asc";
        case "Most Popular":
            return "popular:desc";
        case "Least Popular":
            return "popular:asc";
        default:
            return null;
    }
};

export const parseMediaFilters = (filters: MediaFilters) => {
    const {
        selectedCampaigns,
        type,
        isOwner,
        status,
        startDate,
        endDate,
        duration
    } = filters;

    function parseCampaigns() {
        const campaigns = selectedCampaignsToArray(selectedCampaigns);
        const campaignIds = campaigns.map(({ id }) => id);
        return campaignIds.join(",") || null;
    }

    function parseType() {
        const types = Object.keys(type).filter(key => type[key]);
        return types.join(",");
    }

    function parseStatus() {
        if (!isOwner) return null;

        const statuses = Object.keys(status).filter(key => status[key]);
        return statuses.join(",");
    }

    function parseDate(moment?: Moment) {
        if (!moment) return null;

        return moment.format("YYYY-MM-DD");
    }

    function parseDuration() {
        const durationArray: string[] = [];
        if (duration.gte) durationArray.push(`gte:${duration.gte}`);
        if (duration.lt) durationArray.push(`lt:${duration.lt}`);
        return durationArray.join(",") || null;
    }

    return {
        campaignId: parseCampaigns(),
        type: parseType() || null,
        isOwner: isOwner || null,
        status: parseStatus(),
        startDate: parseDate(startDate),
        endDate: parseDate(endDate),
        duration: parseDuration()
    };
};
