import React from "react";
import { useDispatch } from "react-redux";
import { colors, fonts, sizes } from "src/constants";
import { UploadSubmissionType } from "src/marketplace/utils/types";
import { clearSpacing } from "src/utils/styles/snippets";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 8px;
    width: ${sizes.marketplace.instructions.dropdownItem.width}px;
    min-height: ${sizes.marketplace.instructions.dropdownItem.height}px;
    padding: 12px;
    background-color: ${colors.white};
    border-radius: 8px;
`;

const PlusIcon = styled.img`
    width: 24px;
    height: 24px;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h5`
    ${clearSpacing()};
    margin-bottom: 8px;
    font-size: ${fonts.smallTitle}px;
    font-weight: 700;
`;

const Text = styled.span`
    display: block;
    font-size: ${fonts.smallTitle}px;
    text-align: left;
`;

interface Props {
    title: UploadSubmissionType | string;
    text?: string;
}

const UploadSubmissionItem = ({ title, text }: Props) => {
    return (
        <Main>
            <PlusIcon
                src="/empty-circle-plus-icon.svg"
                alt="Upload Submission Dropdown Add Button"
            />

            <TextContainer>
                <Title>{title}</Title>
                <Text>{text || "Drag to require."}</Text>
            </TextContainer>
        </Main>
    );
};

export default UploadSubmissionItem;
