import React, { useEffect } from "react";
import InstructionsPagePartial from "../components/InstructionsPagePartial";
import { useDispatch } from "react-redux";
import {
    setInstructionSteps,
    setInstructionsPageStep,
    setInstructionsPageType,
    setCurrentInstructionsSetId,
    defaultInstructionStepState
} from "src/ui/instructions-sets/actions";
import PagePartialScratchStep from "../components/PagePartialScratchStep";

interface Props {
    noHeader?: true;
}

const InstructionsSetsCreatePage = ({ noHeader }: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setInstructionsPageType("Create"));

        dispatch(setCurrentInstructionsSetId(null));

        dispatch(setInstructionSteps([defaultInstructionStepState()]));

        // NOTE : Change to "Selection" once I start working on templated instructions.
        dispatch(setInstructionsPageStep("Scratch"));
        // dispatch(setInstructionsPageStep("Selection"));
    }, []);

    return (
        <InstructionsPagePartial
            pageType="create"
            leftColumnContents={
                <PagePartialScratchStep />

                // Replace the above with what we have below once I start working on templated instructions.
                //
                // <CreateSelectionStep
                //     setStepToCreation={() =>
                //         dispatch(setInstructionsPageStep("Scratch"))
                //     }
                // />
            }
            noHeader={noHeader}
        />
    );
};

export default InstructionsSetsCreatePage;
