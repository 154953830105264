import { DirectBuyStepCollectionTreatment } from "../../modules/direct-buy-step/types";
import { colors } from "../../../constants";
import React, { ReactNode } from "react";
import CircleIcon from "../../../components/CircleIcon";
import NumberIcon from "../../../components/NumberIcon";
import SquareIcon from "src/components/SquareIcon";
import SquareNumberIcon from "src/components/SquareNumberIcon";

export function getBackgroundForDirectBuyStepCollectionTreatment(
    treatment: DirectBuyStepCollectionTreatment
): string {
    switch (treatment) {
        case DirectBuyStepCollectionTreatment.approved:
        case DirectBuyStepCollectionTreatment.inactive:
            return "white";
        case DirectBuyStepCollectionTreatment.rejected:
        case DirectBuyStepCollectionTreatment.active:
            return colors.lightTeal;
    }
}

export function getIconForDirectBuyStepCollectionTreatment(
    treatment: DirectBuyStepCollectionTreatment,
    position: number
): ReactNode {
    switch (treatment) {
        case DirectBuyStepCollectionTreatment.approved:
            return (
                <SquareIcon
                    type="check"
                    backgroundColor={colors.primaryGreen}
                />
            );
        case DirectBuyStepCollectionTreatment.rejected:
            return (
                <SquareIcon
                    type="x"
                    backgroundColor={colors.primaryRed}
                    iconColor={colors.white}
                />
            );
        case DirectBuyStepCollectionTreatment.active:
            return (
                <SquareNumberIcon
                    number={position}
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                />
            );
        case DirectBuyStepCollectionTreatment.inactive:
            return (
                <SquareNumberIcon
                    number={position}
                    textColor={colors.white}
                    backgroundColor={colors.mediumTeal}
                />
            );
    }
}
