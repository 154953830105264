import React, { useEffect } from "react";
import { Profile, PublicProfile } from "../types";

import { Route, Switch } from "react-router";
import ProfileMain from "./ProfileMain";
import { ProfileTabState } from "../types";
import CashoutForm from "../transactions/CashoutForm";
import TransactionHistory from "../transactions/TransactionHistory";
import WeeklyPage from "../bonus/weekly/WeeklyPage";
import Settings from "../settings";
import ConnectionsRouter from "../connections/private/ConnectionsRouter";
import AccountsRouter from "../accounts/private/AccountsRouter";
import PromotedPage from "../promoted/private/page";
import CustomMediaPage from "../custom-media/private/page";
import UploadMedia from "../../media/upload";
import { getUrlParameter } from "../../util";
import IntroSocial from "./intro-social";
import RatesRouter from "../rates/RatesRouter";
import LinkList from "../link-lists";
import LinkDetails from "../link-lists/LinkDetails";
import LinkLists from "../link-lists/LinkLists";
import NotFoundPage from "src/navigation/NotFound";
import { useSelector } from "react-redux";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

interface Props {
    profile: Profile;
    publicProfile: PublicProfile;
}

const ProfileRouter = ({ profile, publicProfile }: Props) => {
    const splitAccountRates = useSelector(
        selectFeatureFlags(featureFlags.AccountRates)
    );

    useEffect(() => {
        if (getUrlParameter("refer")) {
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <Switch>
            <Route
                exact
                path={"/profile/intro-social"}
                render={() => <IntroSocial profile={profile} />}
            />

            <Route
                exact
                path={"/profile/settings"}
                render={() => <Settings profile={profile} />}
            />

            <Route
                exact
                path={"/profile/cashout"}
                render={() => <CashoutForm profile={profile} />}
            />

            <Route
                exact
                path={"/profile/transactions"}
                component={TransactionHistory}
            />

            <Route
                path={"/profile/connections"}
                render={() => <ConnectionsRouter profile={profile} />}
            />

            <Route
                path={"/profile/accounts"}
                render={() => (
                    <AccountsRouter
                        profile={profile}
                        publicProfile={publicProfile}
                    />
                )}
            />

            {splitAccountRates && (
                <Route path="/profile/rate" component={RatesRouter} />
            )}
            <Route
                exact
                path={"/profile/promoted"}
                render={() => <PromotedPage profile={profile} />}
            />

            <Route
                exact
                path={"/profile/custom-ads"}
                render={() => {
                    return <CustomMediaPage profile={profile} />;
                }}
            />

            <Route exact path={"/profile/upload"} component={UploadMedia} />

            <Route
                exact
                path={"/profile/linklist/:id"}
                render={({ match }) => <LinkList id={match.params.id} />}
            />

            <Route
                exact
                path={"/profile/linklist/:listId/link/:id"}
                render={({ match }) => (
                    <LinkDetails
                        linkId={match.params.id}
                        linkListId={match.params.listId}
                    />
                )}
            />

            <Route
                exact
                path={"/profile/linklist/:listId/link/"}
                render={({ match }) => (
                    <LinkDetails linkListId={match.params.listId} />
                )}
            />

            <Route exact path={"/profile/linklists"} component={LinkLists} />

            {/* Deprecated? Replaced with weekly anon leaderboard?  */}
            <Route
                exact
                path={"/profile/weekly-leaderboard"}
                component={WeeklyPage}
            />

            <Route
                exact
                path={"/profile/activity"}
                render={() => {
                    return (
                        <ProfileMain
                            profile={profile}
                            publicProfile={publicProfile}
                            tabState={ProfileTabState.activity}
                        />
                    );
                }}
            />

            <Route
                exact
                path={"/profile/bonus"}
                render={() => {
                    return (
                        <ProfileMain
                            profile={profile}
                            publicProfile={publicProfile}
                            tabState={ProfileTabState.bonus}
                        />
                    );
                }}
            />

            <Route
                exact
                path={"/profile"}
                render={() => {
                    return (
                        <ProfileMain
                            profile={profile}
                            publicProfile={publicProfile}
                            tabState={ProfileTabState.about}
                        />
                    );
                }}
            />

            <Route component={NotFoundPage} />
        </Switch>
    );
};

export default ProfileRouter;
