import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import useAuthorization from "../../auth/hooks/useAuthorization";
import { DisplayAction } from "../../auth/roles";
import { GlobalState } from "../../reducers";
import { debounce } from "../../util";
import { LayoutType, setLayout } from "./actions";

const Mobile = styled.div`
    margin: 0 auto;

    width: 100vw;
    max-width: 500px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media only screen and (min-width: 500px) {
        & {
            box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
            min-height: 100vh;
        }
    }
`;

const Desktop = styled.div`
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`;

interface Props {
    layoutType: LayoutType;
    locked?: true;
    setLayout: (layout: LayoutType) => void;
    children: React.ReactNode;
}

const Layout = (props: Props) => {
    const { layoutType, locked, setLayout, children } = props;

    const [desktopView] = useAuthorization([DisplayAction.desktopView]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleResize = debounce(() => {
        if (layoutType === LayoutType.mobile && window.innerWidth > 1024) {
            setLayout(LayoutType.desktop);
        }

        if (window.innerWidth <= 1024) {
            setLayout(LayoutType.mobile);
        }
    });

    if (!locked && desktopView && layoutType === LayoutType.desktop) {
        return <Desktop>{children}</Desktop>;
    }

    return <Mobile>{children}</Mobile>;
};

const mapStateToProps = (state: GlobalState) => ({
    layoutType: state.ui.layout.type
});

const mapDispatchToProps = (dispatch: any) => ({
    setLayout: (layout: LayoutType) => dispatch(setLayout(layout))
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
