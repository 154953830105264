import {
    addNotification,
    setPillNotificationText
} from "./../../ui/notifications/actions";
import { normalize } from "normalizr";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { deleteReq, get, patch, post } from "src/Api";
import { Action } from "src/types";
import { setInstructionsStepsLoading } from "src/ui/instructions-sets/actions";
import {
    createInstructionStepFailure,
    createInstructionStepPaymentFailure,
    createInstructionStepPaymentSuccess,
    createInstructionStepSuccess,
    CREATE_INSTRUCTION_STEP,
    CREATE_INSTRUCTION_STEP_PAYMENT,
    deleteInstructionStepFailure,
    deleteInstructionStepPaymentFailure,
    deleteInstructionStepPaymentSuccess,
    DELETE_INSTRUCTION_STEP,
    DELETE_INSTRUCTION_STEP_PAYMENT,
    fetchInstructionStepFailure,
    fetchInstructionStepPaymentFailure,
    fetchInstructionStepPaymentSuccess,
    fetchInstructionStepsFailure,
    fetchInstructionStepsSuccess,
    FETCH_INSTRUCTION_STEP,
    FETCH_INSTRUCTION_STEPS,
    FETCH_INSTRUCTION_STEP_PAYMENT,
    NormalizedInstructionStepsResponse,
    updateInstructionStepFailure,
    updateInstructionStepPaymentFailure,
    updateInstructionStepPaymentSuccess,
    updateInstructionStepSuccess,
    UPDATE_INSTRUCTION_STEP,
    UPDATE_INSTRUCTION_STEP_PAYMENT
} from "./actions.instruction-steps";
import { instructionStepsList } from "./schema";

function* fetchInstructionStepsRequest(action: Action) {
    try {
        const { instructionsSetId, includePayments } = action.payload;

        const endpoint = `/v1/instruction/step?instructionSetId=${instructionsSetId}&includePayments=${includePayments}`;

        yield put(setInstructionsStepsLoading(true));

        const response = yield call(get, endpoint, {});

        const normalizedData: NormalizedInstructionStepsResponse = normalize(
            response.data.data,
            instructionStepsList
        );
        yield put(fetchInstructionStepsSuccess(normalizedData));
        yield put(setInstructionsStepsLoading(false));
    } catch (error) {
        console.log(error);
        yield put(
            fetchInstructionStepsFailure(
                "Failed to retrieve instruction steps."
            )
        );
    }
}

function* fetchInstructionStepRequest(action: Action) {
    try {
        const { instructionStepId, includePayments } = action.payload;
        const endpoint = `/v1/instruction/step/${instructionStepId}?includePayments=${includePayments}`;
        yield call(get, endpoint, {});
    } catch (error) {
        console.log(error);
        yield put(
            fetchInstructionStepFailure("Failed to retrieve instruction step.")
        );
    }
}

function* createInstructionStepRequest(action: Action) {
    try {
        const { createData, instructionIndex } = action.payload;
        const endpoint = `/v1/instruction/step`;
        const response = yield call(post, endpoint, createData);

        const instructionStepId = response.data.data.id;
        yield put(
            createInstructionStepSuccess(instructionStepId, instructionIndex)
        );
    } catch (error) {
        console.log(error);
        yield put(
            createInstructionStepFailure("Failed to create instruction step.")
        );
    }
}

function* updateInstructionStepRequest(action: Action) {
    try {
        const { instructionStepId, updateData } = action.payload;
        const endpoint = `/v1/instruction/step/${instructionStepId}`;
        yield call(patch, endpoint, updateData);

        yield put(
            setPillNotificationText("Instruction Set Edited Successfully")
        );
    } catch (error) {
        console.log(error);
        yield put(
            updateInstructionStepFailure("Failed to update instruction step.")
        );
    }
}

function* deleteInstructionStepRequest(action: Action) {
    try {
        const instructionStepId = action.payload;
        const endpoint = `/v1/instruction/step/${instructionStepId}`;
        yield call(deleteReq, endpoint, {});

        yield put(
            setPillNotificationText("Instruction Step Deleted Successfully")
        );
    } catch (error) {
        console.log(error);
        yield put(
            deleteInstructionStepFailure("Failed to delete instruction step.")
        );
    }
}

// Instruction Step Payment ---------------------------------------------------------------

function* fetchInstructionStepPaymentRequest(action: Action) {
    try {
        const { instructionStepId } = action.payload;
        const endpoint = `/v1/instruction/payment?instructionStepId=${instructionStepId}`;
        const { data } = yield call(get, endpoint, {});
        yield put(fetchInstructionStepPaymentSuccess(data.data));
    } catch (error) {
        console.log(error);
        yield put(
            fetchInstructionStepPaymentFailure(
                "Failed to retrieve instruction step payment."
            )
        );
    }
}

function* createInstructionStepPaymentRequest(action: Action) {
    try {
        const { instructionPayment } = action.payload;
        const endpoint = `/v1/instruction/payment`;
        const { data } = yield call(post, endpoint, instructionPayment);
        yield put(createInstructionStepPaymentSuccess(data.data));
        yield put(
            setPillNotificationText(
                "Instruction Step Payment Created Successfully"
            )
        );
    } catch (error) {
        console.log(error);
        yield put(
            createInstructionStepPaymentFailure(
                "Failed to create instruction step payment."
            )
        );
        yield put(
            setPillNotificationText(
                "Failed to create instruction step payment.",
                "danger"
            )
        );
    }
}

function* updateInstructionStepPaymentRequest(action: Action) {
    try {
        const { instructionPaymentId, instructionPayment } = action.payload;
        const endpoint = `/v1/instruction/payment/${instructionPaymentId}`;
        const { data } = yield call(patch, endpoint, instructionPayment);

        yield put(updateInstructionStepPaymentSuccess(data.data));
        yield put(
            setPillNotificationText(
                "Instruction Step Payment Updated Successfully"
            )
        );
    } catch (error) {
        console.log(error);
        yield put(
            updateInstructionStepPaymentFailure(
                "Failed to update instruction step payment."
            )
        );
        yield put(
            setPillNotificationText(
                "Failed to update instruction step payment.",
                "danger"
            )
        );
    }
}

function* deleteInstructionStepPaymentRequest(action: Action) {
    try {
        const { stepId, instructionPaymentId } = action.payload;
        const endpoint = `/v1/instruction/payment/${instructionPaymentId}`;
        yield call(deleteReq, endpoint, {});
        yield put(
            deleteInstructionStepPaymentSuccess(stepId, instructionPaymentId)
        );
        yield put(
            setPillNotificationText(
                "Instruction Step Payment Deleted Successfully"
            )
        );
    } catch (error) {
        console.log(error);
        yield put(
            deleteInstructionStepPaymentFailure(
                "Failed to delete instruction step payment."
            )
        );
        yield put(
            setPillNotificationText(
                "Failed to delete instruction step payment.",
                "danger"
            )
        );
    }
}

export default function* instructionStepsSaga() {
    yield all([
        takeEvery(
            FETCH_INSTRUCTION_STEPS.REQUEST,
            fetchInstructionStepsRequest
        ),
        takeEvery(FETCH_INSTRUCTION_STEP.REQUEST, fetchInstructionStepRequest),
        takeEvery(
            CREATE_INSTRUCTION_STEP.REQUEST,
            createInstructionStepRequest
        ),
        takeEvery(
            UPDATE_INSTRUCTION_STEP.REQUEST,
            updateInstructionStepRequest
        ),
        takeEvery(
            DELETE_INSTRUCTION_STEP.REQUEST,
            deleteInstructionStepRequest
        ),
        takeEvery(
            FETCH_INSTRUCTION_STEP_PAYMENT.REQUEST,
            fetchInstructionStepPaymentRequest
        ),
        takeEvery(
            CREATE_INSTRUCTION_STEP_PAYMENT.REQUEST,
            createInstructionStepPaymentRequest
        ),
        takeEvery(
            UPDATE_INSTRUCTION_STEP_PAYMENT.REQUEST,
            updateInstructionStepPaymentRequest
        ),
        takeEvery(
            DELETE_INSTRUCTION_STEP_PAYMENT.REQUEST,
            deleteInstructionStepPaymentRequest
        )
    ]);
}
