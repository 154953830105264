import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import { fetchCampaigns } from "src/campaigns/actions";
import GlobalWarning from "src/components/GlobalWarning";
import { isNumberInputValid } from "src/components/table/utils";
import Toggler from "src/components/Toggler";
import { colors } from "src/constants";
import MarketplaceInput from "src/marketplace/components/MarketplaceInput";
import MarketplaceTextarea from "src/marketplace/components/MarketplaceTextarea";
import Dropdown from "src/marketplace/payment-sets/components/PaymentSetDropdown";
import { BlueButton, RedButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";

const Wrapper = styled.div`
    padding-bottom: 40px;
`;
export const Label = styled.div`
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    margin-bottom: 18px;
    font-size: 12px;
    text-align: left;
    font-weight: 700;
    color: ${colors.gray2};
`;
const StyledMarketplaceInput = styled(MarketplaceInput)`
    margin-top: 8px;
`;
const StyledToggler = styled(Toggler)`
    margin-top: 8px;
`;
const StyledMarketplaceTextarea = styled(MarketplaceTextarea)`
    width: 100%;
    margin-top: 8px;
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;
`;
const SubmitBtn = styled(BlueButton)`
    width: 100%;
    padding: 8px 12px;
`;
const DeleteBtn = styled(RedButton)`
    width: 100%;
    padding: 8px 12px;
    border-radius: 6px;
`;
const Subtitle = styled.div`
    text-align: left;
    font-size: 11px;
    font-style: italic;
    color: black;
    padding-top: 6px;
`;

interface Props {
    openBuy?: OpenBuy | null;
    handleSubmit: (form: ChallengeFormInputs) => void;
    handleDelete: (id: number) => void;
    pageType: "create" | "edit";
}
interface KeyVal {
    [key: string]: any;
}
export interface ChallengeFormInputs extends KeyVal {
    campaign: number | null;
    name: string;
    description: string;
    linkUrl: string;
    linkDescription: string;
    chargeAdvertisers: boolean;
    jetfuelPercentFee: string;
    creatorPayout: string;
    allPublishers: boolean;
}

const ChallengeForm = ({
    openBuy,
    handleSubmit,
    handleDelete,
    pageType
}: Props) => {
    const dispatch = useDispatch();

    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    const campaignTag = "campaign";
    const nameTag = "name";
    const descriptionTag = "description";
    const linkUrlTag = "linkUrl";
    const linkDescriptionTag = "linkDescription";
    const chargeAdvertisersTag = "chargeAdvertisers";
    const creatorPayoutTag = "creatorPayout";
    const jetfuelPercentFeeTag = "jetfuelPercentFee";
    const allPublishersTag = "allPublishers";

    const defaultForm: ChallengeFormInputs = {
        [campaignTag]:
            openBuy && openBuy?.buyer?.campaignId
                ? openBuy.buyer.campaignId
                : null,
        [nameTag]: openBuy ? openBuy.name : "",
        [descriptionTag]:
            openBuy && openBuy.description ? openBuy.description : "",
        [linkUrlTag]: openBuy && openBuy.linkUrl ? openBuy.linkUrl : "",
        [linkDescriptionTag]:
            openBuy && openBuy.linkDescription ? openBuy.linkDescription : "",
        [jetfuelPercentFeeTag]:
            openBuy && openBuy.jetfuelPercentFee
                ? (openBuy.jetfuelPercentFee * 100).toString()
                : "20",
        [chargeAdvertisersTag]:
            openBuy && openBuy.jetfuelPercentFee ? false : true,
        [creatorPayoutTag]:
            openBuy && openBuy.budgetAmount
                ? openBuy.budgetAmount.toString()
                : "",
        [allPublishersTag]: openBuy ? openBuy.allPublishers : false
    };
    const [formInputs, setFormInputs] = useState(defaultForm);

    const handleChange = (key: string, value: any) => {
        setFormInputs({ ...formInputs, [key]: value });
    };

    const { allIds: campaignsAllIds, byId: campaignsById } = useSelector(
        (state: GlobalState) => state.entities.campaigns,
        shallowEqual
    );

    useEffect(() => {
        if (campaignsAllIds.length === 0) dispatch(fetchCampaigns());
    }, []);

    const shouldChargeFee = !formInputs[chargeAdvertisersTag];

    const handleBudgetCalc = (fee: number, budget: number) => {
        return budget * (1 + fee);
    };

    const validateSubmit = () => {
        const campaignInput = formInputs[campaignTag];
        const nameInput = formInputs[nameTag];
        const percentFeeInput = formInputs[jetfuelPercentFeeTag];
        const payoutInput = formInputs[creatorPayoutTag];
        const showError = (msg: string) =>
            dispatch(setPillNotificationText(msg, "danger", 3500));

        if (!campaignInput && pageType === "create") {
            showError("Please select a campaign");
            return false;
        }

        if (nameInput.trim().length === 0) {
            showError("Challenge Name must not be empty");
            return false;
        }

        if (
            !formInputs[chargeAdvertisersTag] &&
            percentFeeInput.trim().length === 0
        ) {
            showError("LI Platform Fee must not be empty");
            return false;
        } else if (parseInt(percentFeeInput) > 100) {
            showError("LI Platform Fee cannot be greater than 100%");
            return false;
        }

        if (payoutInput.trim().length === 0) {
            showError("Creator Payout must not be empty");
            return false;
        }

        return true;
    };

    return (
        <Wrapper>
            {!openBuy && pageType === "create" && (
                <Label style={{ gap: "8px" }}>
                    CAMPAIGN
                    <Dropdown
                        itemList={campaignsAllIds}
                        selected={
                            formInputs.campaign
                                ? formInputs.campaign.toString()
                                : null
                        }
                        displayName={
                            formInputs.campaign !== null
                                ? campaignsById[formInputs.campaign]
                                      .campaignName
                                : undefined
                        }
                        displayEmptyState={"Please select a Campaign"}
                        getItemName={id => campaignsById[id].campaignName}
                        handleOnClick={id => handleChange(campaignTag, id)}
                    />
                </Label>
            )}
            <Label>
                CHALLENGE NAME
                <StyledMarketplaceInput
                    value={formInputs.name}
                    onChange={e => handleChange(nameTag, e.currentTarget.value)}
                />
            </Label>
            <Label>
                CHALLENGE DESCRIPTION
                <StyledMarketplaceTextarea
                    value={formInputs.description}
                    handleChange={e =>
                        handleChange(descriptionTag, e.currentTarget.value)
                    }
                />
            </Label>
            <Label>
                LINK URL
                <StyledMarketplaceInput
                    value={formInputs.linkUrl}
                    onChange={e =>
                        handleChange(linkUrlTag, e.currentTarget.value)
                    }
                />
            </Label>
            <Label>
                LINK DESCRIPTION
                <StyledMarketplaceTextarea
                    value={formInputs.linkDescription}
                    handleChange={e =>
                        handleChange(linkDescriptionTag, e.currentTarget.value)
                    }
                />
            </Label>
            <Label>
                CHARGE ADVERTISERS PER INSTALL
                <StyledToggler
                    isActive={formInputs.chargeAdvertisers}
                    onSelect={() => handleChange(chargeAdvertisersTag, true)}
                    onDeselect={() => handleChange(chargeAdvertisersTag, false)}
                />
            </Label>
            {!formInputs[chargeAdvertisersTag] && (
                <Label>
                    LI PLATFORM FEE %
                    <StyledMarketplaceInput
                        value={`${formInputs.jetfuelPercentFee}`}
                        onChange={e => {
                            const inputVal = e.currentTarget.value;

                            if (isNumberInputValid(inputVal)) {
                                handleChange(jetfuelPercentFeeTag, inputVal);
                            }
                        }}
                        style={{ width: "80px" }}
                    />
                </Label>
            )}
            <Label>
                CREATOR PAYOUT BUDGET
                <StyledMarketplaceInput
                    value={formInputs[creatorPayoutTag]}
                    onChange={e => {
                        const inputVal = e.currentTarget.value;

                        if (isNumberInputValid(inputVal)) {
                            handleChange(creatorPayoutTag, inputVal);
                        }
                    }}
                />
                {shouldChargeFee && (
                    <Subtitle>
                        {formInputs[creatorPayoutTag] &&
                        formInputs[jetfuelPercentFeeTag]
                            ? `Cost to advertiser: $` +
                              handleBudgetCalc(
                                  parseInt(formInputs[jetfuelPercentFeeTag]) /
                                      100,
                                  parseFloat(formInputs[creatorPayoutTag])
                              )
                            : "Please enter valid creator payout and fee"}
                    </Subtitle>
                )}
            </Label>
            <Label>
                OPEN TO ALL PUBLISHERS
                <StyledToggler
                    isActive={formInputs.allPublishers}
                    onSelect={() => handleChange(allPublishersTag, true)}
                    onDeselect={() => handleChange(allPublishersTag, false)}
                />
            </Label>
            <ButtonContainer>
                <SubmitBtn
                    onClick={() => {
                        if (validateSubmit()) handleSubmit(formInputs);
                    }}
                >
                    {pageType === "create"
                        ? "Create Challenge"
                        : "Update Challenge Details"}
                </SubmitBtn>
                {pageType === "edit" && (
                    <DeleteBtn onClick={() => setShowDeleteWarning(true)}>
                        Delete Challenge
                    </DeleteBtn>
                )}
            </ButtonContainer>
            {showDeleteWarning && openBuy && (
                <GlobalWarning
                    title={`Delete Challenge`}
                    message={`Are you sure you want to delete the ${openBuy.name}?`}
                    handleCancel={() => setShowDeleteWarning(false)}
                    handleApply={() => handleDelete(openBuy.id)}
                    btnText={{ cancel: "Cancel", apply: "Delete" }}
                />
            )}
        </Wrapper>
    );
};

export default ChallengeForm;
