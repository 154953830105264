import React from "react";
import { CustomLoading } from "src/components/SkeletonLoader";
import { colors, shadows } from "src/constants";
import styled from "styled-components";
import InnerTableLoader from "./InnerTableLoader";

const Wrapper = styled.div`
    border-radius: 8px;
    width: 100%;
    height: 890px;
    display: flex;
    flex-direction: column;
    box-shadow: ${shadows.four};
`;
const Header = styled.div`
    padding: 16px;
    height: 160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
const FilterWrap = styled.div`
    display: flex;
    gap: 6px;
`;
const Content = styled.div`
    height: 730px;
    width: 100%;
    background-color: ${colors.secondaryLightGray};
    padding: 16px;
`;

const TableLoader = ({
    resultsPerPage = 12,
    rowHeight = 49
}: {
    resultsPerPage?: number;
    rowHeight?: number;
}) => {
    return (
        <Wrapper>
            <Header>
                <CustomLoading width="250px" height="29px" />
                <CustomLoading width="100%" height="40px" />
                <FilterWrap>
                    <CustomLoading height="22px" width="80px" />
                    <CustomLoading height="22px" width="80px" />
                    <CustomLoading height="22px" width="80px" />
                </FilterWrap>
            </Header>
            <Content>
                <InnerTableLoader
                    resultsPerPage={resultsPerPage}
                    rowHeight={rowHeight}
                />
            </Content>
        </Wrapper>
    );
};

export default TableLoader;
