import React from "react";
import { colors, fonts, shadows, sizes } from "src/constants";
import {
    clearButton,
    preventUserInteraction,
    responsiveContainer
} from "src/utils/styles/snippets";
import styled from "styled-components";

const Main = styled.footer`
    position: fixed;
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: ${sizes.marketplace.creatorGroups.submissionFooter.height}px;
    background-color: ${colors.white};
    font-size: ${fonts.smallTitle}px;
    box-shadow: ${shadows.oneUp};
`;

const Container = styled.div`
    ${responsiveContainer()};
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-end;
    gap: 8px;
`;

const SubmitButton = styled.div<{ isDeleteButton?: true }>`
    ${clearButton()};
    align-self: flex-end;
    padding: 8px 12px;
    border-radius: 6px;
    color: ${colors.white};
    background-color: ${props => {
        return props.isDeleteButton ? colors.primaryRed : colors.primaryBlue;
    }};
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${props => {
                return props.isDeleteButton
                    ? colors.primaryRedDark
                    : colors.primaryBlueDark;
            }};
        }
    }
`;

interface Props {
    buttons: {
        text: string;
        action: () => void;
        isDeleteButton?: true;
    }[];
}

const ChallengeGeneralButtonFooter = ({ buttons }: Props) => {
    return (
        <Main>
            <Container>
                <ButtonContainer>
                    {buttons.map(button => (
                        <SubmitButton
                            key={button.text}
                            onClick={button.action}
                            isDeleteButton={button.isDeleteButton}
                        >
                            {button.text}
                        </SubmitButton>
                    ))}
                </ButtonContainer>
            </Container>
        </Main>
    );
};

export default ChallengeGeneralButtonFooter;
