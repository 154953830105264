import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CreatorCardLoading } from "src/components/SkeletonLoader";
import CreatorCard from "src/marketplace/creator-groups/components/CreatorCard";
import { getCreators } from "src/marketplace/creator-groups/selectors.creators-search";
import { Creator } from "src/marketplace/creator-groups/types.creator-groups";
import { GlobalState } from "src/reducers";
import { setCreatorsPage } from "src/ui/creators/actions";
import styled from "styled-components";

const Main = styled.section`
    position: relative;
    flex: 1;
`;

const Results = styled.article`
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    max-height: 100%;
    padding: 16px;
    overflow: auto;
`;

const ResultsIncrementor = styled.div`
    width: 1px;
    height: 1px;
`;

interface Props {
    type: "Creator Search" | "Saved Groups";
    searchString: string;
}

const CreatorSearchResults = ({ type, searchString }: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();
    const { ref, inView } = useInView();

    // Redux State ---------------------------------------------------
    const creators = useSelector(getCreators);
    const { page, maxPages, creatorSearchLoading } = useSelector(
        (state: GlobalState) => {
            return state.ui.creators;
        },
        shallowEqual
    );

    // Effects -------------------------------------------------------
    useEffect(() => {
        if (inView) {
            if (page < maxPages) dispatch(setCreatorsPage(page + 1));
            else dispatch(setCreatorsPage(maxPages));
        }
    }, [inView, maxPages]);

    // JSX -----------------------------------------------------------
    return (
        <Main>
            <Results>
                {creators.map((creator: Creator, index: number) => {
                    return (
                        <CreatorCard
                            key={`admin-creator-search-result-card-${index}-${creator.social_account_id ||
                                creator.publisher_id}`}
                            publisherId={creator.publisher_id}
                            socialAccountId={creator.social_account_id}
                            profilePictureUrl={creator.picture}
                            platform={creator.platform}
                            username={creator.username || ""}
                            location={creator.country_name || ""}
                            audienceCountryWeight={
                                creator.audience_country_weight
                            }
                            interests={creator.interests}
                            numberFollowers={creator.followers}
                            averageViews={creator.avg_views}
                            engagementRate={creator.engagement_rate}
                            countryCode={creator.country_code}
                            pricing={creator.pricing}
                            hideButtons={type === "Creator Search"}
                            index={index}
                        />
                    );
                })}

                {creatorSearchLoading && (
                    <>
                        <CreatorCardLoading key="admin-creator-search-results-loading-card-1" />
                        <CreatorCardLoading key="admin-creator-search-results-loading-card-2" />
                        <CreatorCardLoading key="admin-creator-search-results-loading-card-3" />
                        <CreatorCardLoading key="admin-creator-search-results-loading-card-4" />
                        <CreatorCardLoading key="admin-creator-search-results-loading-card-5" />
                        <CreatorCardLoading key="admin-creator-search-results-loading-card-6" />
                    </>
                )}

                <ResultsIncrementor ref={ref} />
            </Results>
        </Main>
    );
};

export default CreatorSearchResults;
