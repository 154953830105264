import { colors, fonts } from "src/constants";
import { Button } from "src/profile/components/Buttons";
import styled from "styled-components";

const PillButton = styled(Button)`
    height: 52px;
    border-radius: 26px;
`;

export const WhitePillButton = styled(PillButton)`
    color: ${colors.black};
    background-color: ${colors.white};
`;

export const BluePillButton = styled(PillButton)`
    color: ${colors.white};
    border: 1px solid ${colors.white};
    background-color: ${colors.primaryBlue};
`;
