import React from "react";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import { colors } from "src/constants";
import { BigBlueButton } from "src/profile/components/Buttons";
import GenericContainer from "src/profile/components/GenericContainer";
import styled from "styled-components";
import { EmojiNote } from "./IntroBuysContent";

const Main = styled.div`
    & > * ~ * {
        margin-top: 24px;
    }
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
`;

const List = styled.div``;

const ListItem = styled.div`
    ${FlexVerticallyCentered}
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    img {
        margin-right: 16px;
    }

    & + & {
        margin-top: 48px;
    }
`;

const IntroBuysWelcome = () => (
    <Main>
        <Title>Introducing Personal Offers on the Plug</Title>
        <EmojiNote>
            <div>📣</div>
            <div>
                Personal Offers are a beta feature and creators will be given
                access based on an application system.
            </div>
        </EmojiNote>
        <List>
            <ListItem>
                <img src="/intro-social/intro-friends-icon.svg" />
                <div>New ways to make money on the Plug</div>
            </ListItem>
            <ListItem>
                <img src="/intro-social/intro-link-icon.svg" />
                <div>Set your price and rates on your profile</div>
            </ListItem>
            <ListItem>
                <img src="/intro-social/intro-send-icon.svg" />
                <div>Receive personal offers from advertisers</div>
            </ListItem>
        </List>
    </Main>
);

export default IntroBuysWelcome;
