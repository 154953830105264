import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { MediaLeaderboardItem } from "./Leaderboard";
import { fetchMediaLeaderboard } from "./actions";
import { push } from "connected-react-router";
import { coinToDollars, formatNumber } from "../util";
import SimpleNavbar from "../components/SimpleNavbar";
import NavButton from "../components/NavButton";
import CoinSummary from "../components/CoinSummary";
import MediaEarningsCell from "./MediaEarningsCell";
import { AnalyticsModal } from "./reducer";
import MediaModal from "./MediaModal";
import FullscreenLoader from "../components/FullscreenLoader";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";

const List = styled.div`
    padding: 10px 15px 15px;
`;

interface Props {
    mediaLeaderboard: MediaLeaderboardItem[];
    modal: AnalyticsModal;
    fetchMediaLeaderboard: () => void;
    push: (path: string) => void;
}

interface State {
    maxItems: number;
}

const MediaLeaderboard = ({
    mediaLeaderboard,
    modal,
    fetchMediaLeaderboard,
    push
}: Props) => {
    const [maxItems, setMaxItems] = useState(25);

    const handleScroll = () => {
        const innerHeight = window.innerHeight;
        const scrollTopBody = document.getElementsByTagName("body")[0]
            .scrollTop;
        const scrollTopDoc = document.documentElement.scrollTop;
        const scrollTop = Math.max(scrollTopBody, scrollTopDoc) + 50;
        const offsetHeight = document.documentElement.offsetHeight;
        if (
            0 < mediaLeaderboard.length &&
            maxItems < mediaLeaderboard.length &&
            innerHeight + scrollTop >= offsetHeight &&
            document.body.style.position !== "fixed"
        ) {
            setMaxItems(maxItems + 3);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        fetchMediaLeaderboard();
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const leftNavButton = <NavButton onClick={() => push("/analytics")} back />;

    const endList = Math.min(maxItems, mediaLeaderboard.length);

    const totalCoins =
        Math.round(
            mediaLeaderboard.reduce((a, b) => {
                return a + parseFloat(b.earned_plugcoins);
            }, 0) * 10
        ) / 10;

    if (mediaLeaderboard.length === 0) {
        return (
            <>
                <Navbar
                    marginBottom={10}
                    customMobileNavbar={<SimpleNavbar left={leftNavButton} />}
                />
                <FullscreenLoader />
            </>
        );
    } else {
        return (
            <>
                <Navbar
                    marginBottom={10}
                    customMobileNavbar={<SimpleNavbar left={leftNavButton} />}
                />

                <MainContainer>
                    <CoinSummary
                        topLeft={"Total Media Earnings"}
                        topRight={`${formatNumber(coinToDollars(totalCoins))}`}
                        bottomLeft={"This Month"}
                        bottomRight={""}
                    />
                    <List>
                        {mediaLeaderboard
                            .slice(0, endList)
                            .map((leaderboardItem, idx) => {
                                return (
                                    <MediaEarningsCell
                                        key={`${leaderboardItem.campaign_id}-${leaderboardItem.media_id}`}
                                        earnings={leaderboardItem}
                                    />
                                );
                            })}
                    </List>
                </MainContainer>

                {modal === AnalyticsModal.media && <MediaModal />}
            </>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    mediaLeaderboard: state.analytics.mediaLeaderboard,
    modal: state.analytics.modal
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchMediaLeaderboard: () => dispatch(fetchMediaLeaderboard()),
    push: (path: string) => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaLeaderboard);
