import {
    CHECK_SHOUTOUT_HISTORY,
    FETCH_SHOUTOUTS
} from "src/campaigns/shoutouts/actions";
import { Shoutout } from "src/campaigns/shoutouts/types";
import { Action } from "src/types";
import { SET_CURRENT_SHOUTOUT } from "./actions";

export interface ShoutoutsUIState {
    currentShoutout: Shoutout | null;
    hasShoutoutHistory: boolean;
    shoutoutsLoading: boolean;
}

const initialShoutoutsUIState: ShoutoutsUIState = {
    currentShoutout: null,
    hasShoutoutHistory: false,
    shoutoutsLoading: false
};

const shoutoutsUIReducer = (
    state = initialShoutoutsUIState,
    action: Action
) => {
    switch (action.type) {
        case SET_CURRENT_SHOUTOUT:
            return { ...state, ...action.payload };

        case CHECK_SHOUTOUT_HISTORY.SUCCESS:
            return { ...state, ...action.payload };

        case FETCH_SHOUTOUTS.REQUEST:
            return { ...state, shoutoutsLoading: true };

        case FETCH_SHOUTOUTS.SUCCESS:
        case FETCH_SHOUTOUTS.FAILURE:
            return { ...state, shoutoutsLoading: false };

        default:
            return state;
    }
};

export default shoutoutsUIReducer;
