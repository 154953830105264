import React from "react";
import { Shimmer } from "src/components/SkeletonLoader";
import styled from "styled-components";
import ModalBox from "./ModalBox";

const Wrapper = styled.ul`
    list-style: none;
    padding: 0px;
    margin: 0px;
`;

interface ListChildProps {
    readonly paragraph: boolean;
}
const ListChild = styled.li<ListChildProps>`
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    font-size: 12px;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0px;
    }
    ${props =>
        props.paragraph
            ? `
    flex-direction: column;
    text-align: left;
    `
            : ""}
`;
const Label = styled.span`
    font-weight: 500;
    padding-bottom: 10px;
`;

const ValueLoading = styled.div`
    ${Shimmer};
    width: 50%;
    height: 16px;
`;
export type ModalItem =
    | {
          label: string;
          type: "money" | "number" | "id";
          value: number | null;
      }
    | {
          label: string;
          type: "string" | "paragraph";
          value: string | null;
      }
    | {
          label: string;
          type: "boolean";
          value: boolean | null;
      };

interface Props {
    listItems: ModalItem[];
    header: string;
    cornerBtns?: React.ReactNode;
}

const ModalList = ({ listItems, header, cornerBtns }: Props) => {
    const renderStat = (
        value: string | number | boolean | null | undefined,
        type: "string" | "paragraph" | "number" | "money" | "boolean" | "id"
    ) => {
        if (value === null || value === undefined || !type) {
            return "-";
        }
        if (type === "string" || type === "id" || type === "paragraph") {
            return value;
        }
        if (type === "money") {
            return `$${value.toLocaleString(undefined, {
                maximumFractionDigits: 2
            })}`;
        }
        if (type === "number") {
            return value.toLocaleString(undefined, {
                maximumFractionDigits: 2
            });
        }

        if (type === "boolean") {
            return (
                <img
                    src={
                        value
                            ? "/check-mark-circle-primary-green.svg"
                            : "/red-circle-close.svg"
                    }
                    alt=""
                    style={{ width: "18px", height: "auto" }}
                />
            );
        }
        return "-";
    };
    return (
        <ModalBox cornerBtns={cornerBtns} headingText={header}>
            <Wrapper>
                {listItems.map((item, idx) => (
                    <ListChild
                        paragraph={item.type === "paragraph"}
                        key={item.label + idx}
                    >
                        <Label>{item.label}</Label>
                        {item.value ? (
                            <span>{renderStat(item.value, item.type)}</span>
                        ) : (
                            <ValueLoading />
                        )}
                    </ListChild>
                ))}
            </Wrapper>
        </ModalBox>
    );
};

export default ModalList;
