import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../reducers";
import styled from "styled-components";
import Modal from "../../modals/Modal";
import ModalButton from "../../modals/ModalButton";
import { Profile } from "../../profile/types";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Description = styled.div`
    font-size: ${fonts.subtitle}px;
    padding-top: 10px;
`;

const MediaEmailModal = ({ profile }: { profile?: Profile | null }) => {
    return (
        <Modal>
            <Main>
                <Title>Check Your Email</Title>
                <Description>
                    This media has been sent to: {profile && profile.email}
                </Description>
            </Main>
            <ModalButton />
        </Modal>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    profile: state.profile.profile
});

export default connect(mapStateToProps)(MediaEmailModal);
