import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
    fetchLinkForPlug,
    fetchLinkForPlugSuccess,
    fetchLinkParams
} from "src/links/actions";
import HighlightsCarouselDetails from "./HighlightsCarouselDetails";
import { PlugLink } from "../../media/types";
import CarouselInfo from "../../modals/CarouselInfo";
import { GlobalState } from "../../reducers";
import { Highlight, HighlightMedia } from "../types";
import HighlightMediaCarouselTitle from "./HighlightMediaCarouselTitle";

const Main = styled.div`
    width: 100vw;
    max-width: 500px;
    padding-bottom: 20px;
    color: white;
    text-align: left;
    font-weight: 300;
    user-select: none;

    & img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
`;

interface Props {
    closeModal: () => void;
    linksForPlugs: { [s: string]: PlugLink | null };
    fetchLinkForPlug: (params: fetchLinkParams) => void;
    fetchLinkForPlugSuccess: (link: PlugLink) => void;
    highlight: Highlight;
    highlightMedia: HighlightMedia;
}

const HighlightMediaCarouselCell = ({
    linksForPlugs,
    fetchLinkForPlugSuccess,
    fetchLinkForPlug,
    closeModal,
    highlight,
    highlightMedia
}: Props) => {
    const linkClicked = (
        campaignId: string,
        mediaId: number,
        isPortrait: boolean
    ) => {
        const linkKey = `${campaignId}-${mediaId}`;
        const plugLink = linksForPlugs[linkKey];

        if (plugLink) {
            fetchLinkForPlugSuccess(plugLink);
        } else {
            fetchLinkForPlug({
                id: mediaId,
                externalPageId: null,
                campaignId: parseInt(campaignId),
                isPortrait
            });
        }
    };

    return (
        <Main>
            <CarouselInfo
                appIconUrl={highlight.imageUrl}
                campaignName={highlight.campaignName}
                amountComponent={
                    <HighlightMediaCarouselTitle metrics={highlight.metrics} />
                }
                mediaUrl={highlightMedia.url}
                mediaType={highlightMedia.type}
                closeModal={closeModal}
                isPortrait={highlightMedia.isPortrait}
            />
            <HighlightsCarouselDetails
                dollarsPerK={highlight.dollarsPerK}
                views={highlight.viewsActual}
                revenue={highlight.revenue}
                mediaId={highlightMedia.id}
                mediaUrl={highlightMedia.url}
                highlight={highlight}
                linkClicked={() => {
                    linkClicked(
                        highlight.campaignId,
                        highlightMedia.id,
                        highlightMedia.isPortrait
                    );
                }}
            />
        </Main>
    );
};

const mapStateToProps = (
    state: GlobalState,
    { highlight }: { highlight: Highlight }
) => ({
    linksForPlugs: state.links.linksForPlugs,
    highlightMedia: state.entities.highlightMedias[highlight.media]
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchLinkForPlug: (params: fetchLinkParams) =>
        dispatch(fetchLinkForPlug(params)),
    fetchLinkForPlugSuccess: (link: PlugLink) =>
        dispatch(fetchLinkForPlugSuccess(link))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HighlightMediaCarouselCell);
