import { FETCH_SEARCH_MEDIA } from "src/media/actions";
import { Media, MediaFilters } from "src/media/types";
import { NormalizedActionGeneric } from "src/types";
import { Pagination } from "../types";
import {
    SET_MEDIA_FILTERS,
    SET_MEDIA_SEARCH_STRING,
    SET_MEDIA_SORT
} from "./actions";

export const initialMediaFilters: MediaFilters = {
    placements: {},
    selectedCampaigns: {},
    type: { video: true, photo: true },
    status: { pending: true, approved: true, rejected: true },
    duration: { gte: "", lt: "" },
    isOwner: false,
    admin: {
        showAll: true,
        showUserHidden: false
    }
};

const initialMediasPaginationState: Pagination<MediaFilters> = {
    currentPage: 0,
    hasMore: true,
    pages: {},
    filters: initialMediaFilters,
    sortBy: "Recommended",
    searchString: ""
};

const mediasPaginationReducer = (
    state = initialMediasPaginationState,
    action: NormalizedActionGeneric<Media, number[]>
) => {
    switch (action.type) {
        case FETCH_SEARCH_MEDIA.REQUEST:
            return {
                ...state,
                currentPage: state.currentPage,
                pages: {
                    ...state.pages,
                    [state.currentPage]: { ids: [], isFetching: true }
                }
            };
        case FETCH_SEARCH_MEDIA.SUCCESS:
            return {
                ...state,
                currentPage: action.payload.page + 1,
                hasMore: action.response.result.length === 10,
                pages: {
                    ...state.pages,
                    [action.payload.page]: {
                        ids: action.response.result,
                        isFetching: false
                    }
                }
            };
        case SET_MEDIA_FILTERS:
            return {
                ...state,
                currentPage: 0,
                hasMore: true,
                pages: {},
                filters: action.payload.filters
            };
        case SET_MEDIA_SORT:
            return {
                ...state,
                currentPage: 0,
                hasMore: true,
                pages: {},
                sortBy: action.payload.sortBy
            };
        case SET_MEDIA_SEARCH_STRING:
            return {
                ...state,
                currentPage: 0,
                hasMore: true,
                pages: {},
                searchString: action.payload.searchString
            };
        default:
            return state;
    }
};

export default mediasPaginationReducer;
