import React, { useEffect } from "react";
import { connect } from "react-redux";
import { clearModal } from "./actions";
import { freezeBody, thawBody } from "../util";
import { colors } from "../constants";
import styled from "styled-components";
import { motion } from "framer-motion";

const Parent = styled(motion.div)`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 24px 14px;
`;

const Child = styled(motion.div)`
    width: 100%;
    max-width: 472px;
    display: flex;
    flex-direction: column;
`;

const ButtonsContainer = styled.div`
    border-radius: 12px;
    overflow: hidden;

    & > * ~ * {
        border-top: 1px solid ${colors.mediumTeal};
    }
`;

const Button = styled.div<{ negative?: boolean }>`
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    background-color: white;
    font-size: 16px;
    transition: 0.1s ease-in;

    ${props => props.onClick && "cursor: pointer;"}
    ${props =>
        props.negative &&
        `color: ${colors.primaryRed};`}

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const CancelButton = styled(Button)`
    border-radius: 12px;
    margin-top: 8px;
`;

export interface Action {
    text: string;
    callback?: VoidFunction;
    negative?: boolean;
}

interface Props {
    clearModal: () => void;
    localClearModal?: () => void;
    actions: Action[];
}

const ActionsModal = ({ clearModal, actions, localClearModal }: Props) => {
    useEffect(() => {
        freezeBody();

        return thawBody;
    }, []);

    return (
        <Parent
            onClick={() => {
                if (localClearModal) {
                    localClearModal();
                } else {
                    clearModal();
                }
            }}
            key="full-screen-modal-parent"
            initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            animate={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
            exit={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            transition={{ duration: 0.3 }}
        >
            <Child
                key="full-screen-modal"
                initial={{ y: 300, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 300, opacity: 0 }}
                transition={{ duration: 0.3 }}
                layout
            >
                <ButtonsContainer onClick={e => e.stopPropagation()}>
                    {actions.map((action, idx) => {
                        return (
                            <Button
                                key={idx}
                                negative={action.negative}
                                onClick={action.callback}
                            >
                                {action.text}
                            </Button>
                        );
                    })}
                </ButtonsContainer>
                <CancelButton onClick={localClearModal || clearModal}>
                    Cancel
                </CancelButton>
            </Child>
        </Parent>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(null, mapDispatchToProps)(ActionsModal);
