import React from "react";
import OutlineContainer from "../components/OutlineContainer";
import ListCell from "../components/ListCell";
import { useDispatch, useSelector } from "react-redux";
import { selectLayoutTheme } from "src/ui/layout/selectors";
import { LayoutThemeType, setLayoutTheme } from "src/ui/layout/actions";

interface Props {
    push: (route: string) => void;
    logout: VoidFunction;
}

const Preferences = () => {
    const dispatch = useDispatch();
    const theme = useSelector(selectLayoutTheme);
    return (
        <OutlineContainer>
            <ListCell
                clickable
                onClick={() => {
                    const selectedTheme =
                        theme === LayoutThemeType.light
                            ? LayoutThemeType.dark
                            : LayoutThemeType.light;

                    dispatch(setLayoutTheme(selectedTheme));
                    localStorage.setItem("layoutTheme", selectedTheme);
                }}
            >
                {theme === LayoutThemeType.light
                    ? "Switch to dark mode (experimental)"
                    : "Switch to light mode"}
            </ListCell>
        </OutlineContainer>
    );
};

export default Preferences;
