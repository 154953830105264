import { createSelector } from "reselect";
import { GlobalState } from "../../reducers";

const mediaEntities = (state: GlobalState) => state.entities.medias;

const currentModalMediaIds = (state: GlobalState) =>
    state.ui.medias.currentModalMedias;

export const getCurrentModalMedias = createSelector(
    currentModalMediaIds,
    mediaEntities,
    (currentModalMediaIds, medias) => {
        return currentModalMediaIds.map(id => medias[id]);
    }
);
