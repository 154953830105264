import React, { useRef, useState } from "react";
import SearchTag from "src/components/SearchTag";
import SelectionButton from "src/components/SelectionButton";
import { colors, fonts, shadows, sizes } from "src/constants";
import { useCloseModalWhenClickedOutside } from "src/utils/functions/hooks";
import { clearButton } from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import { ServerFilter, ServerFilters } from "./TableServerHeader";

const Wrapper = styled.div`
    position: relative;
`;
const Dropdown = styled.div`
    max-height: ${sizes.selectionButton.height * 8}px;
    background-color: ${colors.white};
    border-radius: 8px;
    box-shadow: ${shadows.one};
    overflow-y: auto;
`;

const DropdownWrap = styled.div<{ shouldShow: boolean }>`
    position: absolute;
    top: 100%;
    display: ${props => (props.shouldShow ? "visible" : "none")};
    z-index: 20;
`;
const Buttons = styled.div`
    position: sticky;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px;
    background-color: ${colors.white};
    box-shadow: 0px -4px 8px rgba(187, 187, 187, 0.25);
`;
const buttonStyles = css`
    ${clearButton()};
    flex: 1;
    border-radius: 6px;
    padding: 8px 0px;
    font-size: ${fonts.semiSmallTitle}px;
    text-align: center;
    transition: 0.1s ease-in;
`;

const ResetButton = styled.button`
    ${buttonStyles};
    color: ${colors.white};
    background-color: ${colors.primaryBlue};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

const CloseButton = styled.button`
    ${buttonStyles};
    background-color: ${colors.quaternaryLightGray};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray2};
        }
    }
`;
interface Props {
    filter: ServerFilter;
    setFilter: React.Dispatch<React.SetStateAction<ServerFilters>>;
    filterKey: string;
}
const ServerDropdownFilter = ({ filter, setFilter, filterKey }: Props) => {
    const mainRef = useRef(null);
    const [open, setOpen] = useState(false);

    useCloseModalWhenClickedOutside(mainRef, open, () => setOpen(false), [
        open
    ]);
    const selectedChoices = filter.choices.filter(choice => choice.selected)
        .length;
    const areAnyChoicesSelected = selectedChoices > 0;

    const handleSelect = (choiceIdx: number) => {
        setFilter(prevState => {
            const filterBeingSelected = prevState[filterKey];
            return {
                ...prevState,
                [filterKey]: {
                    ...filterBeingSelected,
                    choices: filterBeingSelected.choices.map((choice, idx) => {
                        if (idx === choiceIdx) {
                            return { ...choice, selected: !choice.selected };
                        }
                        return choice;
                    })
                }
            };
        });
    };
    const resetFilter = () => {
        setFilter(prevState => {
            return {
                ...prevState,
                [filterKey]: {
                    ...prevState[filterKey],
                    choices: prevState[filterKey].choices.map(choice => ({
                        ...choice,
                        selected: false
                    }))
                }
            };
        });
    };
    return (
        <Wrapper ref={mainRef}>
            <SearchTag
                color={areAnyChoicesSelected ? "black" : "white"}
                counter={selectedChoices}
                text={filter.displayValue}
                iconSrc="/tag-teal-plus-icon.svg"
                textTransform={false}
                onClick={() => setOpen(!open)}
            />
            <DropdownWrap shouldShow={open}>
                <Dropdown>
                    {filter.choices.map((choice, idx) => {
                        return (
                            <SelectionButton
                                key={choice.displayValue + idx}
                                text={choice.displayValue}
                                selected={choice.selected}
                                setSelected={() => handleSelect(idx)}
                                width={
                                    sizes.marketplace.creatorGroups
                                        .filterDropdown.width
                                }
                            />
                        );
                    })}
                    <Buttons>
                        <ResetButton type="button" onClick={resetFilter}>
                            Reset Selection
                        </ResetButton>
                        <CloseButton
                            type="button"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </CloseButton>
                    </Buttons>
                </Dropdown>
            </DropdownWrap>
        </Wrapper>
    );
};

export default ServerDropdownFilter;
