import React, { useState } from "react";
import { Channel } from "../types";
import AddMembers from "./settings/AddMembers";
import AddAdmins from "./settings/AddAdmins";
import { extractTitleAndPhoto } from "../util";
import VerticallyCentered from "../../components/VerticallyCentered";
import ProfilePhoto from "../../components/ProfilePhoto";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import ChannelSettings from "./settings";
import ChannelView from "./ChannelView";
import { GlobalState } from "../../reducers";
import {
    adminActionsModal,
    directActionsModal,
    editChannelModal
} from "../actions";
import { goBack } from "connected-react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import HideOverflow from "../../components/HideOverflow";
import { ChatModal } from "../reducer";
import { Connection } from "../../profile/types";
import RightNavButton from "./RightNavButton";
import DirectActionsModal from "./DirectActionsModal";
import MainContainer from "src/components/MainContainer";

const ChannelContainer = styled.div`
    height: calc(100vh - 57px);
`;

const Title = styled(HideOverflow)`
    max-width: 120px;
`;

interface Props {
    sdk: any;
    userId: number;
    channel: Channel;
    setChannel: (channel: Channel) => void;
    membersRefresh: VoidFunction;
    muteBanRefresh: VoidFunction;
    modal: ChatModal;
    connections?: Connection[] | null;
    editChannelModal: VoidFunction;
    adminActionsModal: VoidFunction;
    directActionsModal: VoidFunction;
    goBack: VoidFunction;
}

const ChannelMain = ({
    sdk,
    userId,
    channel,
    setChannel,
    membersRefresh,
    muteBanRefresh,
    modal,
    connections,
    editChannelModal,
    adminActionsModal,
    directActionsModal,
    goBack
}: Props) => {
    const { title, photoUrl } = extractTitleAndPhoto(
        channel.sendBirdData,
        userId
    );

    const Center = (
        <VerticallyCentered>
            <ProfilePhoto
                profilePhotoUrl={photoUrl}
                photoSize={24}
                style={{ marginRight: "5px" }}
            />
            <Title>{title}</Title>
        </VerticallyCentered>
    );

    const [showSettings, setShowSettings] = useState(false);
    const [addMembers, setAddMembers] = useState(false);
    const [addAdmins, setAddAdmins] = useState(false);

    if (addMembers) {
        return (
            <AddMembers
                channel={channel}
                connections={connections}
                closeCallback={() => {
                    setAddMembers(false);
                    membersRefresh();
                }}
            />
        );
    } else if (addAdmins) {
        return (
            <AddAdmins
                channel={channel}
                closeCallback={() => setAddAdmins(false)}
            />
        );
    } else if (showSettings) {
        return (
            <>
                <SimpleNavbar
                    title={Center}
                    left={
                        <NavButton
                            back
                            onClick={() => setShowSettings(false)}
                        />
                    }
                />

                <MainContainer>
                    <ChannelContainer>
                        <ChannelSettings
                            userId={userId}
                            channel={channel}
                            setChannel={setChannel}
                            sdk={sdk}
                            modal={modal}
                            editChannelModal={editChannelModal}
                            adminActionsModal={adminActionsModal}
                            setAddMembers={setAddMembers}
                            setAddAdmins={setAddAdmins}
                            muteBanRefresh={muteBanRefresh}
                        />
                    </ChannelContainer>
                </MainContainer>
            </>
        );
    } else {
        return (
            <>
                <SimpleNavbar
                    title={Center}
                    left={<NavButton back onClick={goBack} />}
                    right={
                        <RightNavButton
                            channel={channel}
                            setShowSettings={setShowSettings}
                            directActionsModal={directActionsModal}
                        />
                    }
                />

                <MainContainer>
                    <ChannelContainer>
                        <ChannelView
                            channel={channel}
                            setChannel={setChannel}
                            goBack={goBack}
                        />
                    </ChannelContainer>
                </MainContainer>

                {modal === ChatModal.directActions && (
                    <DirectActionsModal
                        sdk={sdk}
                        userId={userId}
                        channel={channel}
                        setChannel={setChannel}
                    />
                )}
            </>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    modal: state.chat.modal,
    connections: state.profile.connections
});

const mapDispatchToProps = (dispatch: any) => ({
    editChannelModal: () => dispatch(editChannelModal()),
    adminActionsModal: () => dispatch(adminActionsModal()),
    directActionsModal: () => dispatch(directActionsModal()),
    goBack: () => dispatch(goBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelMain);
