import React from "react";
import { useIntercom } from "react-use-intercom";
import EmojiModal from "src/modals/EmojiModal";
import {
    BlueModalButton,
    CloseModalButton
} from "src/profile/components/Buttons";
import { Section, Title } from "src/profile/modals/styledComponents";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: column;

    & > * ~ * {
        margin-top: 12px;
    }
`;

interface Props {
    revisionText: string;
    onClose: () => void;
    onManage: () => void;
}

const OpenBuyRevisionRequestDetailsModal = ({
    revisionText,
    onClose,
    onManage
}: Props) => {
    const { boot, showNewMessages } = useIntercom();
    return (
        <EmojiModal icon="/warning-sign.svg" localClearModal={onClose}>
            <Title>Your submission needs revision</Title>
            <Section>{revisionText}</Section>
            <Main>
                <BlueModalButton onClick={onManage}>
                    Resubmit Media
                </BlueModalButton>
                <CloseModalButton
                    onClick={() => {
                        boot({
                            customAttributes: {
                                disable_chat_bot: false
                            }
                        });
                        showNewMessages();
                    }}
                >
                    Contact Support
                </CloseModalButton>
            </Main>
        </EmojiModal>
    );
};
export default OpenBuyRevisionRequestDetailsModal;
