import React from "react";
import styled from "styled-components";
import LoadingButton from "./LoadingButton";
import { colors } from "../constants";

const Main = styled.div`
    padding: 12px 18px;
    display: flex;
`;

const ButtonContainer = styled.div`
    width: 50%;
    height: 48px;
    padding-right: 6px;
    & + & {
        padding-right: 0;
        padding-left: 6px;
    }
`;

interface Props {
    leftText: string;
    leftCallback: VoidFunction;
    leftDisabled?: boolean;
    leftLoading?: boolean;
    rightText: string;
    rightCallback: VoidFunction;
    rightDisabled?: boolean;
    rightLoading?: boolean;
}

const TwoButtonFooter = ({
    leftText,
    leftCallback,
    leftDisabled,
    leftLoading,
    rightText,
    rightCallback,
    rightDisabled,
    rightLoading
}: Props) => {
    return (
        <Main>
            <ButtonContainer>
                <LoadingButton
                    text={leftText}
                    onClick={leftCallback}
                    disabled={leftDisabled}
                    isLoading={leftLoading}
                    width={"100%"}
                    height={"100%"}
                    borderColor={colors.mediumTeal}
                    textColor={colors.primaryBlue}
                />
            </ButtonContainer>
            <ButtonContainer>
                <LoadingButton
                    text={rightText}
                    onClick={rightCallback}
                    disabled={rightDisabled}
                    isLoading={rightLoading}
                    width={"100%"}
                    height={"100%"}
                    backgroundColor={colors.primaryBlue}
                    textColor={"white"}
                />
            </ButtonContainer>
        </Main>
    );
};

export default TwoButtonFooter;
