import React from "react";
import styled from "styled-components";
import { coinToDollars, weeklyBonusAmount } from "../../../util";
import { colors, fonts } from "../../../constants";

const Main = styled.div`
    padding: 10px 0px;
`;

const VerticalCenter = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.div`
    margin-bottom: 7px;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.87px;
`;

const GreenText = styled.div`
    margin-left: auto;
    font-weight: 500;
    color: ${colors.secondaryGreen};
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    color: ${colors.primaryGray};
    font-weight: 400;
    line-height: 16.71px;
`;

const Link = styled.div`
    margin-left: 5px;
    font-size: ${fonts.subtitle}px;
    color: ${colors.primaryBlue};
    cursor: pointer;
`;

interface Props {
    plugcoins: number;
    bonusPercentage: number;
    bracketsModal?: () => void;
}

const WeeklyHeader = ({ plugcoins, bonusPercentage, bracketsModal }: Props) => {
    return (
        <Main>
            <VerticalCenter>
                <Title>Weekly Bonus</Title>
                <GreenText>
                    {weeklyBonusAmount(plugcoins, bonusPercentage)}
                </GreenText>
            </VerticalCenter>
            <VerticalCenter>
                <Subtitle>{bonusPercentage}% bonus bracket.</Subtitle>
                {!!bracketsModal && (
                    <Link onClick={bracketsModal}>Learn More</Link>
                )}
            </VerticalCenter>
        </Main>
    );
};

export default WeeklyHeader;
