import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DirectBuysHeader from "src/buys/header/DirectBuysHeader";
import IntroBuysHeader from "src/buys/intro/IntroBuysHeader";
import { Contest } from "src/campaigns/contests/types";
import ContestCard from "src/campaigns/contests/components/ContestCard";
import { breakpoints, colors, maxWidthDesktop, fonts } from "src/constants";
import FeatureFlagGate from "src/navigation/FeatureFlagGate";
import { GlobalState } from "src/reducers";
import { flex, hideScrollbar } from "src/utils/styles/snippets";
import styled from "styled-components";
import { getContests } from "../contests/selectors";
import OpenBuysFeed from "src/buys/header/OpenBuysFeed";
import { Section } from "./Landing";
import { requestOpenBuysByStatus } from "src/buys/actions";
import DirectBuysSearchSortAndFilter from "src/buys/search/DirectBuysSearchSortAndFilter";
import DirectBuysSearchModals from "src/buys/search/DirectBuysSearchModals";
import SearchBar from "src/components/SearchBar";
import featureFlags from "src/firebase/featureFlags";

const Main = styled.main`
    ${flex("column")};
    ${hideScrollbar()};
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    flex: 1;
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    margin: 10px auto 40px;
`;

interface HeaderProps {
    padding?: string;
}

export const SectionHeader = styled("h2")<HeaderProps>`
    margin: 0px;
    padding: ${props => props.padding || "0px"};
    color: ${colors.black};
    font-size: ${fonts.title}px;
    font-weight: 500;
    text-align: left;
    line-height: 29px;

    @media (max-width: ${breakpoints.mobile}px) {
        font-size: 20px;
    }
`;

const Contests = styled.article`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ChallengesTab = () => {
    const dispatch = useDispatch();

    const [sortMode, setSortMode] = useState("Newest");

    const [searchString, setSearchString] = useState("");

    const contests = useSelector<GlobalState, Contest[]>(state =>
        getContests(state)
    );

    useEffect(() => {
        dispatch(requestOpenBuysByStatus());
    }, []);

    return (
        <Main>
            <Section>
                <FeatureFlagGate featureFlag={featureFlags.DirectBuyNUX}>
                    <IntroBuysHeader />
                </FeatureFlagGate>
                <DirectBuysHeader />
            </Section>

            {contests.length > 0 && (
                <Section padding="0 0 20px">
                    <SectionHeader padding="0px 0px 16px">
                        Contests
                    </SectionHeader>
                    <Contests>
                        {contests.map(contest => (
                            <ContestCard key={contest.id} contest={contest} />
                        ))}
                    </Contests>
                </Section>
            )}

            <Section padding="0 0px 20px">
                <SearchBar
                    searchString={searchString}
                    updateSearchString={setSearchString}
                />
                <DirectBuysSearchSortAndFilter
                    sortMode={sortMode}
                    setSortMode={setSortMode}
                    isOpenBuy={true}
                />
                <OpenBuysFeed sortMode={sortMode} searchString={searchString} />
            </Section>
            <DirectBuysSearchModals
                sortMode={sortMode}
                setSortMode={setSortMode}
                isOpenBuy={true}
            />
        </Main>
    );
};

export default ChallengesTab;
