import React, { useEffect, useState } from "react";
import { Campaign } from "src/campaigns/types";
import { SelectedPlatform } from "../utils/types";
import Dropdown from "src/marketplace/payment-sets/components/PaymentSetDropdown";
import ChallengeFormSection from "./ChallengeFormSection";
import ChallengeFormSubSection from "./ChallengeFormSubSection";
import ChallengeLogisticsAssociatedCampaign from "./ChallengeLogisticsAssociatedCampaign";
import ChallengeLogisticsPublisherAssignment from "./ChallengeLogisticsPublisherAssignment";
import ChallengeLogisticsSelectPlatform from "./ChallengeLogisticsSelectPlatform";
import ChallengeTogglerSection from "./ChallengeTogglerSection";
import styled from "styled-components";
import Rating from "react-rating";
import { colors } from "src/constants";
import ModalHeading from "src/components/modals/components/ModalHeading";
import { useDispatch } from "react-redux";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import { get } from "src/Api";
import DropdownSelect from "src/components/inputs/DropdownSelect";
const MessageWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    grid-template-areas:
        "title input1"
        "subtitle input2";
    padding-bottom: 24px;
    isolation: isolate;
`;
const MessageTitle = styled.div<{ red?: boolean }>`
    display: flex;
    grid-area: title;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    color: ${p => (p.red ? colors.primaryRed : colors.black)};
`;
const StyledRating = styled(Rating)`
    grid-area: input1 / input1 / input2 / input2;
    align-self: center;
`;

const MessageSubtitle = styled.div<{ red?: boolean }>`
    grid-area: subtitle;
    text-align: left;
    font-size: 12px;
    color: ${p => (p.red ? colors.primaryRed : colors.gray2)};
`;
const Star = styled.img`
    width: 20px;
`;
interface Props {
    currentCampaign?: Campaign;
    allPublishers: boolean;
    setAllPublishers: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPlatforms: SelectedPlatform;
    setSelectedPlatforms: (platform: SelectedPlatform) => void;
    isExperimental: boolean;
    setIsExperimental: (experimental: boolean) => void;
    isComplimentary: boolean;
    setIsComplimentary: (complimentary: boolean) => void;
    leaderboardScoreMinimum: number;
    setLeaderboardScoreMinimum: (leaderboardScoreMinimum: number) => void;
    creatorScoreMinimum: number;
    setCreatorScoreMinimum: (leaderboardScoreMinimum: number) => void;
    isApplication: boolean;
    setIsApplication: (val: boolean) => void;
    setAutoAssignNewUsers: (val: boolean) => void;
    autoAssignNewUsers: boolean;
    linkedOpenBuyId: number | null;
    setLinkedOpenBuyId: (val: number | null) => void;
}

const ChallengeLogistics = ({
    currentCampaign,
    allPublishers,
    setAllPublishers,
    selectedPlatforms,
    setSelectedPlatforms,
    isExperimental,
    setIsExperimental,
    isComplimentary,
    setIsComplimentary,
    leaderboardScoreMinimum,
    setLeaderboardScoreMinimum,
    creatorScoreMinimum,
    setCreatorScoreMinimum,
    isApplication,
    setIsApplication,
    setAutoAssignNewUsers,
    autoAssignNewUsers,
    linkedOpenBuyId,
    setLinkedOpenBuyId
}: Props) => {
    const dispatch = useDispatch();
    const [openBuys, setOpenBuys] = useState<OpenBuy[]>([]);
    const [search, setSearch] = useState("");

    const searchOpenBuys = async () => {
        try {
            const res = await get("/v1/admin/openBuy/selectList", { search });
            setOpenBuys(res.data.data || []);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        searchOpenBuys();
    }, []);

    const selectedOpenBuy = openBuys.find(v => v.id === linkedOpenBuyId);
    useEffect(() => {
        if (linkedOpenBuyId && selectedOpenBuy) setSearch(selectedOpenBuy.name);
    }, [linkedOpenBuyId, openBuys]);

    return (
        <ChallengeFormSection title="Challenge Logistics">
            <ChallengeTogglerSection
                title="Auto Assign to New Users"
                subtitle="This challenge will be auto assigned to any user that joins the plug after launch"
                isActive={autoAssignNewUsers}
                onSelect={() => setAutoAssignNewUsers(true)}
                onDeselect={() => setAutoAssignNewUsers(false)}
            />
            <ChallengeTogglerSection
                title="Application Challenge"
                subtitle="This challenge will be an application challenge to enter another challenge"
                isActive={isApplication}
                onSelect={() => setIsApplication(true)}
                onDeselect={() => setIsApplication(false)}
            />
            {isApplication && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px"
                    }}
                >
                    <ModalHeading
                        text={
                            "LINKED CHALLENGE (if blank will create new challenge on launch)"
                        }
                    />
                    <DropdownSelect
                        itemList={openBuys.filter(
                            v =>
                                v.name
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                v.id.toString().includes(search)
                        )}
                        selected={selectedOpenBuy?.name}
                        getItemName={v => v.name}
                        handleOnClick={v => setLinkedOpenBuyId(v ? v.id : null)}
                        search={search}
                        onSearch={v => setSearch(v)}
                    />
                </div>
            )}
            <ChallengeTogglerSection
                title="Include All Publishers"
                subtitle="This challenge will be sent to all creators"
                isActive={allPublishers}
                onSelect={() => setAllPublishers(true)}
                onDeselect={() => setAllPublishers(false)}
            />
            {allPublishers && (
                <MessageWrap>
                    <MessageTitle>Minimum Creator Score</MessageTitle>
                    <MessageSubtitle>
                        Select a score between 0-5 to be the minimum requirement
                        for a publisher to be allowed into this challenge
                    </MessageSubtitle>

                    <StyledRating
                        fractions={2}
                        onChange={setCreatorScoreMinimum}
                        initialRating={creatorScoreMinimum}
                        emptySymbol={<Star src="/star-gray.svg" />}
                        fullSymbol={<Star src="/star-yellow.svg" />}
                    />
                </MessageWrap>
            )}
            <ChallengeTogglerSection
                title="Mark as Experimental Challenge"
                subtitle="Use this to exclude challenge from analytics"
                isActive={isExperimental}
                onSelect={() => setIsExperimental(true)}
                onDeselect={() => setIsExperimental(false)}
            />
            <ChallengeTogglerSection
                title="Mark as Complimentary"
                subtitle="Use this to exclude challenge from revenue reports"
                isActive={isComplimentary}
                onSelect={() => setIsComplimentary(true)}
                onDeselect={() => setIsComplimentary(false)}
            />
            <MessageWrap>
                <MessageTitle>Minimum Leaderboard Score</MessageTitle>
                <MessageSubtitle>
                    Select a score between 0.5-5 to be the minimum requirement
                    to show a media on the leaderboard
                </MessageSubtitle>

                <StyledRating
                    fractions={2}
                    onChange={setLeaderboardScoreMinimum}
                    initialRating={leaderboardScoreMinimum}
                    emptySymbol={<Star src="/star-gray.svg" />}
                    fullSymbol={<Star src="/star-yellow.svg" />}
                />
            </MessageWrap>

            <ChallengeFormSubSection
                title={
                    allPublishers
                        ? "BLOCKED PUBLISHER LIST"
                        : "ALLOWED PUBLISHER LIST"
                }
                children={
                    <ChallengeLogisticsPublisherAssignment
                        allPublishers={allPublishers}
                    />
                }
            />
            <ChallengeLogisticsAssociatedCampaign
                currentCampaign={currentCampaign}
            />
            <ChallengeLogisticsSelectPlatform
                selectedPlatforms={selectedPlatforms}
                setSelectedPlatforms={setSelectedPlatforms}
            />
        </ChallengeFormSection>
    );
};

export default ChallengeLogistics;
