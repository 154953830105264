import { Action } from "../types";
import { OldSocialAccount, SocialAccountPost } from "./SocialAccount";

// export const FETCH_ACCOUNTS = {
//     REQUEST: "FETCH_ACCOUNTS_REQUEST",
//     SUCCESS: "FETCH_ACCOUNTS_SUCCESS",
//     FAILURE: "FETCH_ACCOUNTS_FAILURE"
// };
//
// export const fetchAccounts = (): Action => ({
//     type: FETCH_ACCOUNTS.REQUEST,
//     payload: null
// });
//
// export const fetchAccountsSuccess = (
//     socialAccounts: Array<SocialAccount>
// ): Action => ({
//     type: FETCH_ACCOUNTS.SUCCESS,
//     payload: { socialAccounts }
// });
//
// export const fetchAccountsFailure = (error: string): Action => ({
//     type: FETCH_ACCOUNTS.FAILURE,
//     payload: { error }
// });

export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const CURRENT_ACCOUNT = "CURRENT_ACCOUNT";

export const addNewAccount = (account: OldSocialAccount): Action => ({
    type: ADD_ACCOUNT,
    payload: account
});

export const selectCurrentAccount = (socialAccountsIndex: number): Action => ({
    type: CURRENT_ACCOUNT,
    payload: socialAccountsIndex
});

export const POST_ACCOUNTS = {
    REQUEST: "POST_ACCOUNTS_REQUEST",
    SUCCESS: "POST_ACCOUNTS_SUCCESS",
    FAILURE: "POST_ACCOUNTS_FAILURE"
};

export const postAccounts = (
    social_accounts: Array<SocialAccountPost>
): Action => ({
    type: POST_ACCOUNTS.REQUEST,
    payload: { social_accounts }
});

export const postAccountsSuccess = (data: any): Action => ({
    type: POST_ACCOUNTS.SUCCESS,
    payload: data
});

export const postAccountsFailure = (data: any): Action => ({
    type: POST_ACCOUNTS.FAILURE,
    payload: data
});

// Modals
// -------------------------------------------------------------------

export const MODAL = {
    THANKS: "MODAL_THANKS"
};

export const thanksModal = (): Action => ({
    type: MODAL.THANKS,
    payload: null
});

export const SET_SOCIAL_ACCOUNT_STATUSES = "SET_SOCIAL_ACCOUNT_STATUSES";
//Statuses
export const setSocialAccountStatuses = (data: any): Action => ({
    type: SET_SOCIAL_ACCOUNT_STATUSES,
    payload: data
});

export const SET_SOCIAL_ACCOUNT_STATUS = "SET_SOCIAL_ACCOUNT_STATUS";
//Statuses
export const setSocialAccountStatus = (data: {
    id: number;
    status: string | null;
}): Action => ({
    type: SET_SOCIAL_ACCOUNT_STATUS,
    payload: data
});
