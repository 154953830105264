import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import styled from "styled-components";
import Platforms from "../../social-accounts/Platforms";
import { shortenNumber } from "../../util";
import { Chevron, Image, Row, Subtitle, Title } from "./StyledComponents";
import { SocialAccountHit } from "../types";

const BorderImage = styled(Image)`
    border-radius: 4px;
`;

interface Props {
    hit: SocialAccountHit;
    push: (path: string) => void;
}

const AccountHitRow = ({ hit, push }: Props) => {
    if (!hit.platform) return null;
    return (
        <Row
            onClick={() => {
                push(`/search/accounts/details/${hit.id}`);
            }}
        >
            <BorderImage
                src={
                    Platforms[hit.platform]
                        ? Platforms[hit.platform].imgSrc
                        : Platforms.other.imgSrc
                }
                alt=""
            />
            <div>
                <Title>{hit.username}</Title>
                <Subtitle>
                    <span>{shortenNumber(hit.num_followers)}</span> Followers
                </Subtitle>
            </div>
            <Chevron>
                <FontAwesomeIcon icon="chevron-right" />
            </Chevron>
        </Row>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    push: (path: string) => dispatch(push(path))
});

export default connect(null, mapDispatchToProps)(AccountHitRow);
