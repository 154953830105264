import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import FullscreenLoader from "src/components/FullscreenLoader";
import Tag from "src/components/Tag";
import { selectCampaign } from "src/campaigns/selectors";
import { GlobalState } from "src/reducers";
import { colors, fonts } from "src/constants";
import { flex } from "src/utils/styles/snippets";

const Main = styled("div")`
    ${flex("column")};
    padding: 18px;

    & > * ~ * {
        margin-top: 24px;
    }
`;

const DetailItem = styled("div")`
    ${flex("column")};
    font-size: 12px;
    text-align: left;
`;

const Header = styled("h1")`
    color: ${colors.primaryGray};
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
`;

const Description = styled("div")``;

const AdvertiserName = styled("div")`
    font-size: ${fonts.smallTitle}px;
    font-weight: 500;
`;

const Tags = styled("div")`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const DetailTab = () => {
    const campaign = useSelector((state: GlobalState) => {
        return selectCampaign(state);
    });

    if (!campaign) return <FullscreenLoader blankSpace={332} />;

    return (
        <Main>
            {campaign.description && (
                <DetailItem>
                    <Header>Description</Header>
                    <Description>{campaign.description}</Description>
                </DetailItem>
            )}

            <DetailItem>
                <Header>Tags</Header>
                <Tags>
                    {campaign.tags.map((tag, index) => (
                        <Tag key={index} uppercase tight text={tag} />
                    ))}
                </Tags>
            </DetailItem>

            <DetailItem>
                <Header>Supplier</Header>
                <AdvertiserName>{campaign.advertiserName}</AdvertiserName>
            </DetailItem>
        </Main>
    );
};

export default DetailTab;
