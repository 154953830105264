import { Action, NormalizedAction } from "../../types";
import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../../reducers";
import { Script } from "../types";
import { CustomAdminMediaUpload } from "src/media/actions";

export const FETCH_SCRIPT = {
    REQUEST: "FETCH_SCRIPT_REQUEST",
    SUCCESS: "FETCH_SCRIPT_SUCCESS",
    FAILURE: "FETCH_SCRIPT_FAILURE"
};

export const fetchScript = (scriptId: number): Action => ({
    type: FETCH_SCRIPT.REQUEST,
    payload: { scriptId }
});

export const ADD_SCRIPT = "ADD_SCRIPT";

export const addScript = (script: Script): Action => ({
    type: ADD_SCRIPT,
    payload: script
});

// Campaign Scripts ------------------------------------------------------------

export const FETCH_CAMPAIGN_SCRIPTS = {
    REQUEST: "FETCH_CAMPAIGN_SCRIPTS_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_SCRIPTS_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_SCRIPTS_FAILURE"
};

export const fetchCampaignScripts = (
    campaignId: number,
    scriptId?: number
): Action => ({
    type: FETCH_CAMPAIGN_SCRIPTS.REQUEST,
    payload: { campaignId, scriptId }
});

export const fetchCampaignScriptsSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { campaignId: number }
): NormalizedAction => ({
    type: FETCH_CAMPAIGN_SCRIPTS.SUCCESS,
    response,
    payload
});

export const fetchCampaignScriptsFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_SCRIPTS.FAILURE,
    payload: { error }
});

// Fetch Campaign Script  -------------------------------------------------------------------
export const FETCH_CAMPAIGN_SCRIPT = {
    REQUEST: "FETCH_CAMPAIGN_SCRIPT_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_SCRIPTs_SUCCESS"
};
export function fetchCampaignScript(scriptId: number): Action {
    return {
        type: FETCH_CAMPAIGN_SCRIPT.REQUEST,
        payload: { scriptId }
    };
}
export function fetchCampaignScriptSuccess(
    response: NormalizedSchema<{ [key: string]: { [key: string]: any } }, any>
): NormalizedAction {
    return {
        type: FETCH_CAMPAIGN_SCRIPT.SUCCESS,
        response
    };
}

// Script Actions ----------------------------------------------------------------

export const SCRIPT_CREATE = {
    REQUEST: "SCRIPT_CREATE_REQUEST",
    SUCCESS: "SCRIPT_CREATE_SUCCESS",
    FAILURE: "SCRIPT_CREATE_FAILURE"
};
export const createScript = (script: Script) => ({
    type: SCRIPT_CREATE.REQUEST,
    payload: script
});
export const createScriptSuccess = (
    response: NormalizedSchema<EntitiesState, number>,
    campaignId: number,
    scriptId: number
) => ({
    type: SCRIPT_CREATE.SUCCESS,
    response,
    payload: { campaignId, scriptId }
});

export const SCRIPT_EDIT = {
    REQUEST: "SCRIPT_EDIT_REQUEST",
    SUCCESS: "SCRIPT_EDIT_SUCCESS",
    FAILURE: "SCRIPT_EDIT_FAILURE"
};
export const editScript = (script: Script) => ({
    type: SCRIPT_EDIT.REQUEST,
    payload: {
        scriptId: script.id,
        campaignId: script.campaignId,
        title: script.title,
        text: script.text,
        shouldShowInApp: script.shouldShowInApp
    }
});
export const editScriptSuccess = (
    response: NormalizedSchema<EntitiesState, number>
) => ({
    type: SCRIPT_EDIT.SUCCESS,
    response
});

export const SCRIPT_DELETE = {
    REQUEST: "SCRIPT_DELETE_REQUEST",
    SUCCESS: "SCRIPT_DELETE_SUCCESS",
    FAILURE: "SCRIPT_DELETE_FAILURE"
};
export const deleteScript = (script: Script) => ({
    type: SCRIPT_DELETE.REQUEST,
    payload: script
});
export const SCRIPT_MEDIA_LINK = {
    REQUEST: "SCRIPT_MEDIA_LINK_REQUEST",
    SUCCESS: "SCRIPT_MEDIA_LINK_SUCCESS",
    FAILURE: "SCRIPT_MEDIA_LINK_FAILURE"
};
export const scriptMediaLink = (script: Script, mediaIds: number[]) => ({
    type: SCRIPT_MEDIA_LINK.REQUEST,
    payload: { script, mediaIds }
});

export const SCRIPT_ASSET_LINK = {
    REQUEST: "SCRIPT_ASSET_LINK_REQUEST",
    SUCCESS: "SCRIPT_ASSET_LINK_SUCCESS",
    FAILURE: "SCRIPT_ASSET_LINK_FAILURE"
};
export const scriptAssetLink = (script: Script, assetIds: number[]) => ({
    type: SCRIPT_ASSET_LINK.REQUEST,
    payload: { script, assetIds }
});
export const SCRIPT_ASSET_DELETE = {
    REQUEST: "SCRIPT_ASSET_DELETE_REQUEST",
    SUCCESS: "SCRIPT_ASSET_DELETE_SUCCESS",
    FAILURE: "SCRIPT_ASSET_DELETE_FAILURE"
};
export const scriptAssetDelete = (scriptId: number, assetId: number) => ({
    type: SCRIPT_ASSET_DELETE.REQUEST,
    payload: { scriptId, assetId }
});
export const scriptAssetDeleteSuccess = (
    scriptId: number,
    assetId: number
) => ({
    type: SCRIPT_ASSET_DELETE.SUCCESS,
    payload: { scriptId, assetId }
});
export const SCRIPT_MEDIA_DELETE = {
    REQUEST: "SCRIPT_MEDIA_DELETE_REQUEST",
    SUCCESS: "SCRIPT_MEDIA_DELETE_SUCCESS",
    FAILURE: "SCRIPT_MEDIA_DELETE_FAILURE"
};
export const scriptMediaDelete = (scriptId: number, mediaId: number) => ({
    type: SCRIPT_MEDIA_DELETE.REQUEST,
    payload: { scriptId, mediaId }
});
export const scriptMediaDeleteSuccess = (
    scriptId: number,
    mediaId: number
) => ({
    type: SCRIPT_MEDIA_DELETE.SUCCESS,
    payload: { scriptId, mediaId }
});
export const SCRIPT_MEDIA_UPLOAD = {
    REQUEST: "SCRIPT_MEDIA_UPLOAD_REQUEST",
    SUCCESS: "SCRIPT_MEDIA_UPLOAD_SUCCESS",
    FAILURE: "SCRIPT_MEDIA_UPLOAD_FAILURE"
};
export const scriptMediaUpload = (
    uploadData: CustomAdminMediaUpload[],
    script_id: number,
    publisher_id: number
) => ({
    type: SCRIPT_MEDIA_UPLOAD.REQUEST,
    payload: { uploadData, script_id, publisher_id }
});
