import React, { useEffect, useState } from "react";
import { ReactComponent as Caret } from "../../components/icons/right-caret.svg";
import styled from "styled-components";
import { colors } from "src/constants";
import { WhitePillButton } from "src/components/buttons/PillButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from "react-router";
import * as router from "connected-react-router";
import {
    fetchReferringPublisher,
    fetchRefferingPublisherPreApprovalCode
} from "../actions";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;

const InputWrapper = styled.div<{ borderColor: string }>`
    margin-bottom: 10px;
    height: 52px;
    border: 1px solid ${props => props.borderColor};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 26px;
`;

const Input = styled.input`
    outline: none;
    width: 80%;
    box-sizing: border-box;
    font-size: 16px;
    text-align: center;
    color: ${colors.white};
    border: none;
    background-color: transparent;
`;

const BackCaret = styled(Caret)`
    transform: rotate(180deg);
    cursor: pointer;
`;

const Info = styled.div`
    border: 1px solid transparent;
    color: ${colors.white};
    padding: 8px 10px;
    border-radius: 4px;
    align-self: center;
    margin-bottom: 7px;
    font-size: 12px;
    font-weight: 500;
`;

const InfoSuccess = styled(Info)`
    background-color: ${colors.primaryGreen};
`;

const InfoError = styled(Info)`
    background-color: ${colors.white};
    color: ${colors.primaryGray};
    border-color: ${colors.mediumTeal};
`;

const ReferralStatus = ({
    referralUsername,
    preApprovalCode
}: {
    referralUsername: null | string | false;
    preApprovalCode: null | string;
}) => {
    if (referralUsername)
        return (
            <InfoSuccess>
                🎉 Successful referral by @{referralUsername}!
            </InfoSuccess>
        );
    else if (preApprovalCode)
        return <InfoSuccess>🎉 Pre Approval Code Saved!</InfoSuccess>;
    return <InfoError>🚫️ Referral link not valid</InfoError>;
};

interface Props {
    back: () => void;
}

const ReferralInputModal = ({ back }: Props) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const {
        referringPublisher,
        referringPublisherLoading,
        preApprovalCode
    } = useSelector((state: GlobalState) => state.ui.auth, shallowEqual);

    const referralUsername =
        referringPublisher?.username ?? referringPublisher?.firstname ?? null;

    const [inviteCode, setInviteCode] = useState<string>(
        new URLSearchParams(location.search).get("referralCode") || ""
    );

    const continuableCode =
        !referringPublisherLoading &&
        inviteCode.length === 6 &&
        (referralUsername || preApprovalCode);

    useEffect(() => {
        const code = localStorage.getItem("preApprovalCode");
        if (code) {
            setInviteCode(code);
        }
    }, []);

    useEffect(() => {
        if (inviteCode && inviteCode.length === 6) {
            dispatch(fetchReferringPublisher(inviteCode));
        }
    }, [inviteCode]);

    return (
        <Main>
            <Header>
                <BackCaret fill={colors.white} onClick={back} />
                Enter Referral Code
                <div style={{ width: "8px" }}></div>
            </Header>
            {inviteCode.length === 6 && !referringPublisherLoading && (
                <ReferralStatus
                    referralUsername={referralUsername}
                    preApprovalCode={preApprovalCode}
                />
            )}

            <InputWrapper
                borderColor={
                    inviteCode.length !== 6 || referringPublisherLoading
                        ? colors.white
                        : continuableCode
                        ? colors.primaryGreen
                        : colors.primaryRed
                }
            >
                <Input
                    value={inviteCode}
                    onChange={e =>
                        setInviteCode(e.currentTarget.value.substring(0, 6))
                    }
                />
            </InputWrapper>
            <WhitePillButton
                onClick={() => dispatch(router.push("/onboarding/phone"))}
                disabled={!continuableCode}
            >
                {referringPublisherLoading ? (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    "Continue"
                )}
            </WhitePillButton>
        </Main>
    );
};
export default ReferralInputModal;
