import { Action } from "src/types";
import { MarketplaceBadges } from "./reducers";

// Open Marketplace Selection Modal -----------------------------------------------

export const SET_MARKETPLACE_OFFER_CREATION_MODAL_OPEN =
    "SET_MARKETPLACE_OFFER_CREATION_MODAL_OPEN";

export function setMarketplaceOfferCreationModalOpen(open: boolean): Action {
    return {
        type: SET_MARKETPLACE_OFFER_CREATION_MODAL_OPEN,
        payload: { open }
    };
}

export const SET_MARKETPLACE_OFFER_DETAILS_MODAL_OPEN =
    "SET_MARKETPLACE_OFFER_DETAILS_MODAL_OPEN";

export function setMarketplaceOfferDetailsModalOpen(open: boolean): Action {
    return {
        type: SET_MARKETPLACE_OFFER_DETAILS_MODAL_OPEN,
        payload: { open }
    };
}

export const MARKETPLACE_SUBMISSION_BADGES = {
    REQUEST: "REQUEST_MARKETPLACE_SUBMISSION_BADGES",
    SET: "SET_MARKETPLACE_SUBMISSION_BADGES",
    DECREMENT: "DECREMENT_MARKETPLACE_SUBMISSION_BADGES"
};

export function requestMarketplaceSubmissionBadges(openBuyId?: number): Action {
    return {
        type: MARKETPLACE_SUBMISSION_BADGES.REQUEST,
        payload: openBuyId
    };
}
export function setMarketplaceSubmissionBadges(
    badges: MarketplaceBadges
): Action {
    return {
        type: MARKETPLACE_SUBMISSION_BADGES.SET,
        payload: badges
    };
}

export function decrementMarketplaceSubmissionBadges(
    pending?: boolean,
    flagged?: boolean
): Action {
    return {
        type: MARKETPLACE_SUBMISSION_BADGES.DECREMENT,
        payload: { pending, flagged }
    };
}

export const TOGGLE_LEADERBOARD_EDITOR_OPEN = "TOGGLE_LEADERBOARD_EDITOR_OPEN";

export function toggleLeaderboardEditorOpen(id: number | null): Action {
    return {
        type: TOGGLE_LEADERBOARD_EDITOR_OPEN,
        payload: id
    };
}
