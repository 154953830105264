import React, { useEffect, useState } from "react";
import { push } from "connected-react-router";
import {
    deleteConnection,
    DeleteConnectionParams,
    postConnection,
    PostConnectionParams
} from "../../profile/actions";
import {
    BlueSearchConnectionButton,
    OutlineSearchConnectionButton
} from "../../profile/components/Buttons";
import { connect } from "react-redux";
import { Connection } from "../../profile/types";
import styled from "styled-components";
import LoadingButton from "src/components/LoadingButton";
import { colors } from "src/constants";

const Main = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;

    & > * ~ * {
        margin-left: 6px;
    }
`;

const CloseIcon = styled.img`
    height: 12px;
    width: 12px;
    opacity: 0.4;
`;

const SmallOutlineButton = styled(OutlineSearchConnectionButton)`
    width: auto;
`;

interface Props {
    connection?: Connection | null;
    postConnection: (params: PostConnectionParams) => void;
    deleteConnection: (params: DeleteConnectionParams) => void;
    otherId: string;
    selfId: string;
}

const UserHitConnection = ({
    connection,
    selfId,
    otherId,
    postConnection,
    deleteConnection
}: Props) => {
    const [connectionRequestLoading, setConnectionRequestLoading] = useState(
        false
    );

    useEffect(() => {
        if (connectionRequestLoading && connection) {
            setConnectionRequestLoading(false);
        }
    }, [connection, connectionRequestLoading]);

    if (!connection && connectionRequestLoading) {
        return (
            <Main>
                <LoadingButton
                    text="Loading"
                    width="80px"
                    height="100%"
                    padding="5px"
                    disabled={connectionRequestLoading}
                    isLoading={connectionRequestLoading}
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                />
            </Main>
        );
    } else if (!connection) {
        return (
            <Main>
                <BlueSearchConnectionButton
                    onClick={e => {
                        e.stopPropagation();
                        setConnectionRequestLoading(true);
                        postConnection({
                            selfId,
                            otherId,
                            relationship: "friend"
                        });
                    }}
                >
                    Connect
                </BlueSearchConnectionButton>
            </Main>
        );
    } else if (connection.state === "waiting") {
        return (
            <Main>
                <OutlineSearchConnectionButton
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    disabled
                >
                    Requested
                </OutlineSearchConnectionButton>
            </Main>
        );
    } else if (connection.state === "requested") {
        return (
            <Main>
                <BlueSearchConnectionButton
                    onClick={e => {
                        e.stopPropagation();
                        postConnection({
                            selfId,
                            otherId,
                            relationship: "friend"
                        });
                    }}
                >
                    Accept
                </BlueSearchConnectionButton>
                <SmallOutlineButton
                    onClick={e => {
                        e.stopPropagation();
                        deleteConnection({
                            selfId,
                            otherId
                        });
                    }}
                >
                    <CloseIcon src="/black_close.svg" />
                </SmallOutlineButton>
            </Main>
        );
    }

    return null;
};

const mapDispatchToProps = (dispatch: any) => ({
    postConnection: (params: PostConnectionParams) =>
        dispatch(postConnection(params)),
    deleteConnection: (params: DeleteConnectionParams) =>
        dispatch(deleteConnection(params)),
    push: (route: string) => dispatch(push(route))
});

export default connect(null, mapDispatchToProps)(UserHitConnection);
