import React from "react";
import styled from "styled-components";
import { colors } from "../../constants";
import DirectBuyPreview, {
    DirectBuyPreviewSize
} from "../components/DirectBuyPreview";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const CellContainer = styled.div`
    width: 127px;
    height: 205px;
`;

interface Props {
    directBuyId: number;
}

const DirectBuysHeaderCell = ({ directBuyId }: Props) => {
    const dispatch = useDispatch();
    return (
        <CellContainer>
            <DirectBuyPreview
                directBuyId={directBuyId}
                size={DirectBuyPreviewSize.small}
                onClick={() => {
                    dispatch(push(`/buys/direct/${directBuyId}`));
                }}
            />
        </CellContainer>
    );
};

export default DirectBuysHeaderCell;
