import { Action, NormalizedAction } from "../../types";
import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../../reducers";
import { MediaPerformance } from "../types";

export const FETCH_CAMPAIGN_HIGHLIGHTS = {
    REQUEST: "FETCH_CAMPAIGN_HIGHLIGHTS_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_HIGHLIGHTS_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_HIGHLIGHTS_FAILURE"
};

export const fetchCampaignHighlights = (campaignId: number): Action => ({
    type: FETCH_CAMPAIGN_HIGHLIGHTS.REQUEST,
    payload: { campaignId }
});

export const fetchCampaignHighlightsSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { campaignId: number }
): NormalizedAction => ({
    type: FETCH_CAMPAIGN_HIGHLIGHTS.SUCCESS,
    response,
    payload
});

export const fetchCampaignHighlightsFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_HIGHLIGHTS.FAILURE,
    payload: { error }
});

// All Highlights --------------------------------------------------------------

export const FETCH_HIGHLIGHTS = {
    REQUEST: "FETCH_HIGHLIGHTS_NEW_REQUEST",
    SUCCESS: "FETCH_HIGHLIGHTS_NEW_SUCCESS",
    FAILURE: "FETCH_HIGHLIGHTS_NEW_FAILURE"
};

export const fetchHighlights = (): Action => ({
    type: FETCH_HIGHLIGHTS.REQUEST,
    payload: null
});

export const fetchHighlightsSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>
): NormalizedAction => ({
    type: FETCH_HIGHLIGHTS.SUCCESS,
    response
});

export const fetchHighlightsFailure = (error: string): Action => ({
    type: FETCH_HIGHLIGHTS.FAILURE,
    payload: { error }
});

// Fetch Media Performance --------------------------------------------------------------

export const FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE = {
    REQUEST: "FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE_REQUEST",
    SUCCESS: "FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE_SUCCESS",
    FAILURE: "FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE_FAILURE"
};

export interface FetchMediaParams {
    start_date: number;
    end_date: number;
    campaigns: number[];
}

export const fetchHighlightsMediaPerformance = (
    params: FetchMediaParams
): Action => ({
    type: FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE.REQUEST,
    payload: params
});

export const fetchHighlightsMediaPerformanceSuccess = (
    mediaPerformance: MediaPerformance[]
): Action => ({
    type: FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE.SUCCESS,
    payload: { mediaPerformance }
});

export const fetchHighlightsMediaPerformanceFailure = (
    error: string
): Action => ({
    type: FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE.FAILURE,
    payload: { error }
});
