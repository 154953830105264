import { Action, LOCATION_CHANGE } from "../types";
import {
    CashoutFee,
    Connection,
    EarningsSummary,
    FullscreenAlert,
    InviteCode,
    Profile,
    PublicGroup,
    PublicProfile,
    PublicProfileData,
    SocialAccount,
    OldTransaction,
    WeeklyLeaderboardItem,
    BonusBracket,
    LinkList,
    PreApprovalCode,
    PublisherFlag
} from "./types";
import {
    BIO_CHANGE_SUCCESS,
    FETCH_CASHOUTS,
    FETCH_INVITE_CODES,
    FETCH_PUBLIC,
    FETCH_CONNECTIONS_USER,
    FETCH_USER,
    FETCH_WEEKLY_LEADERBOARD,
    GET_FEES_FOR_CASHOUT,
    GET_FULLSCREEN_ALERTS,
    LISTENER_UPDATE_BALANCE,
    PUSH_WITH_ACCOUNT,
    REQUEST_TIPALTI_CASHOUT,
    RESOLVE_FULLSCREEN_ALERT,
    UPDATE_OR_CREATE_TIPALTI_USER,
    UPDATE_PHOTO,
    UPDATE_USER_FIELDS,
    UPDATE_USERNAME,
    FETCH_CONNECTIONS_PUBLIC,
    FETCH_ACCOUNTS_USER,
    FETCH_ACCOUNTS_PUBLIC,
    PRIVATE_CHANGE_SUCCESS,
    DELETE_RECOMMENDATION,
    COMPLETE_RECOMMENDATION,
    POST_CONNECTION,
    DELETE_CONNECTION,
    FETCH_MEDIA_USER,
    FETCH_MEDIA_PUBLIC,
    FETCH_VERIFY_CODE,
    POST_ACCOUNT,
    FETCH_PROMOTED_USER,
    FETCH_INSTAGRAM_HANDLE_SUCCESS,
    FETCH_CHAT_TOKEN_SUCCESS,
    FETCH_PROMOTED_PUBLIC,
    FETCH_PROFILE_USER_SUCCESS,
    FETCH_EARNINGS_USER_SUCCESS,
    COMPLETE_ACCOUNT_LEGACY,
    FETCH_SEARCH_TOKEN_SUCCESS,
    UPDATE_USER_PAYPAL_CASHOUT_EMAIL,
    REQUEST_PAYPAL_CASHOUT,
    FETCH_PRE_APPROVAL_CODE,
    APPLY_PRE_APPROVAL_CODE
} from "./actions";
import {
    ADD_ACCOUNT,
    CURRENT_ACCOUNT,
    POST_ACCOUNTS
} from "../social-accounts/actions";
import { MODAL } from "./actions";
import { ACTIONS_MODAL, CLEAR_MODAL } from "../modals/actions";
import { Media } from "../media/types";
import { Campaign, OldCampaign } from "src/campaigns/types";
import { COMPLETE_ACCOUNT_SETUP } from "../auth/actions";
import { FETCH_REFERRAL_CODE } from "./referrals/actions";

export enum ProfileModal {
    clear,
    actions,
    brackets,
    status,
    username,
    photo,
    cashout,
    cashoutSetting,
    fees,
    email,
    verify,
    privacyAccount
}

export interface PublicDataStore {
    [otherId: string]: PublicProfileData;
}

export enum PublicProfileError {
    blocked,
    noUser,
    reloadable
}

export interface PublicErrorStore {
    [otherId: string]: PublicProfileError;
}

export interface ProfileState {
    profile?: Profile | null;
    publicProfile: PublicProfile;
    earningsSummary: EarningsSummary;
    connections?: Connection[] | null;
    accounts?: SocialAccount[] | null;
    postAccountLoading: boolean;
    promotedCampaigns?: OldCampaign[] | null;
    customMedia?: Media[] | null;
    publicGroups?: PublicGroup[] | null;
    publicGroupsExplore?: PublicGroup[] | null;
    recommendations: Connection[];
    verifyCode: string | null;
    cashoutFees: {
        fees: CashoutFee;
        fee_schedule: CashoutFee[];
    } | null;
    instagramHandle: string | null;
    currentAccount: SocialAccount | null;
    socialAccountsIndex: number;
    weeklyLeaderboard?: WeeklyLeaderboardItem[] | null;
    linkList?: LinkList[] | null;
    bonusBrackets?: BonusBracket[] | null;
    inviteCodes?: InviteCode[] | null;
    transactions: OldTransaction[] | null;
    fullscreenAlerts: FullscreenAlert[];
    modal: ProfileModal;
    loading: boolean;
    paypalEmailLoading: boolean;
    cashoutLoading: boolean;
    error: string | null;
    publicDataStore: PublicDataStore;
    publicErrorStore: PublicErrorStore;
    chatToken: {
        token: string | null;
        expiry: number;
    };
    searchToken: {
        token: string | null;
        expiry: number;
    };
    referralCode: string | null;
    preApprovalCode: PreApprovalCode | null;
    preApprovalCodeLoading: boolean;
    applyPreApprovalCodeLoading: boolean;
    applyPreApprovalCodeError: string | null;
    flags: PublisherFlag[];
}

const initialAccount = localStorage.getItem("currentAccount");
const initialChatToken = localStorage.getItem("chatToken");
const initialSearchToken = localStorage.getItem("searchToken");

const initialProfileState: ProfileState = {
    publicProfile: {
        id: "",
        profile: {
            username: null,
            bio: null,
            avatar: null,
            status: "none",
            privateAccount: true,
            tagLine: {
                platform: "",
                followerCount: 0
            }
        },
        socialAccounts: []
    },
    earningsSummary: {
        balance: 0,
        weekly: 0,
        total: 0
    },
    recommendations: [],
    verifyCode: null,
    cashoutFees: null,
    instagramHandle: null,
    currentAccount: initialAccount ? JSON.parse(initialAccount) : null,
    socialAccountsIndex: -1,
    transactions: null,
    fullscreenAlerts: [],
    modal: ProfileModal.clear,
    loading: true,
    paypalEmailLoading: false,
    cashoutLoading: false,
    error: null,
    publicDataStore: {},
    publicErrorStore: {},
    chatToken: initialChatToken
        ? JSON.parse(initialChatToken)
        : {
              token: null,
              expiry: 0
          },
    searchToken: initialSearchToken
        ? JSON.parse(initialSearchToken)
        : { token: null, expiry: 0 },
    referralCode: null,
    preApprovalCode: null,
    preApprovalCodeLoading: false,
    applyPreApprovalCodeLoading: false,
    applyPreApprovalCodeError: null,
    postAccountLoading: false,
    flags: []
};

const reducer = (state = initialProfileState, action: Action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);

    switch (action.type) {
        case LOCATION_CHANGE:
            return { ...state, modal: ProfileModal.clear, error: null };
        case CLEAR_MODAL:
            return { ...state, modal: ProfileModal.clear, error: null };
        case ACTIONS_MODAL:
            return { ...state, modal: ProfileModal.actions };
        case MODAL.BRACKETS:
            return { ...state, modal: ProfileModal.brackets, error: null };
        case MODAL.STATUS:
            return { ...state, modal: ProfileModal.status, error: null };
        case MODAL.USERNAME:
            return { ...state, modal: ProfileModal.username, error: null };
        case MODAL.PHOTO:
            return { ...state, modal: ProfileModal.photo, error: null };
        case MODAL.CASHOUT:
            return { ...state, modal: ProfileModal.cashout, error: null };
        case MODAL.CASHOUT_SETTING:
            return {
                ...state,
                modal: ProfileModal.cashoutSetting,
                error: null
            };
        case MODAL.FEES:
            return { ...state, modal: ProfileModal.fees, error: null };
        case MODAL.EMAIL:
            return { ...state, modal: ProfileModal.email, error: null };
        case MODAL.VERIFY:
            return { ...state, modal: ProfileModal.verify, error: null };
        case MODAL.PRIVACY_ACCOUNT:
            return {
                ...state,
                modal: ProfileModal.privacyAccount,
                error: null
            };
        case FETCH_USER.REQUEST:
            return { ...state, loading: true };
        case FETCH_USER.SUCCESS:
            return {
                ...state,
                loading: false,
                profile: action.payload,
                publicProfile: {
                    ...state.publicProfile,
                    id: `p:${action.payload.id}`,
                    profile: {
                        ...state.publicProfile.profile,
                        username: action.payload.username,
                        bio: action.payload.bio,
                        avatar: action.payload.profile_photo_url,
                        status: action.payload.status,
                        privateAccount: action.payload.private_account
                    }
                }
            };
        case FETCH_USER.FAILURE:
            return {
                ...state,
                loading: false,
                profile: null
            };
        case FETCH_PROFILE_USER_SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_EARNINGS_USER_SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_CONNECTIONS_USER.REQUEST:
            return { ...state, connections: undefined };
        case FETCH_CONNECTIONS_USER.SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_CONNECTIONS_USER.FAILURE:
            return { ...state, ...action.payload };
        case FETCH_ACCOUNTS_USER.REQUEST:
            return { ...state, accounts: undefined };
        case FETCH_ACCOUNTS_USER.SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_ACCOUNTS_USER.FAILURE:
            return { ...state, ...action.payload };
        case FETCH_PROMOTED_USER.REQUEST:
            return { ...state, promotedCampaigns: undefined };
        case FETCH_PROMOTED_USER.SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_PROMOTED_USER.FAILURE:
            return { ...state, ...action.payload };
        case FETCH_MEDIA_USER.REQUEST:
            return { ...state, customMedia: undefined };
        case FETCH_MEDIA_USER.SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_MEDIA_USER.FAILURE:
            return { ...state, ...action.payload };
        case POST_CONNECTION.SUCCESS:
            return {
                ...state,
                connections: addOrModifyConnections(
                    state.connections,
                    action.payload
                ),
                publicDataStore: replaceConnection(
                    action.payload,
                    state.publicDataStore
                )
            };
        case DELETE_CONNECTION.SUCCESS:
            return {
                ...state,
                connections: state.connections
                    ? state.connections.filter(connection => {
                          return action.payload.otherId !== connection.other.id;
                      })
                    : null,
                publicDataStore: removeConnection(
                    action.payload.otherId,
                    state.publicDataStore
                )
            };
        case DELETE_RECOMMENDATION:
            return {
                ...state,
                recommendations: state.recommendations.filter(
                    recommendation => {
                        return recommendation.other.id !== action.payload;
                    }
                )
            };
        case COMPLETE_RECOMMENDATION:
            return {
                ...state,
                recommendations: state.recommendations.map(recommendation => {
                    if (recommendation.other.id === action.payload) {
                        return {
                            ...recommendation,
                            relationship: "friend",
                            state: "waiting"
                        };
                    } else {
                        return recommendation;
                    }
                })
            };
        case FETCH_PUBLIC.REQUEST:
            const newPublicErrorStore = { ...state.publicErrorStore };

            delete newPublicErrorStore[action.payload.otherId];

            return {
                ...state,
                publicErrorStore: newPublicErrorStore
            };
        case FETCH_PUBLIC.SUCCESS:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        connection: action.payload.connection,
                        stats: action.payload.stats
                    }
                }
            };
        case FETCH_PUBLIC.FAILURE:
            return {
                ...state,
                publicErrorStore: {
                    ...state.publicErrorStore,
                    ...action.payload
                }
            };
        case FETCH_CONNECTIONS_PUBLIC.REQUEST:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        allConnections: undefined,
                        mutualConnections: undefined
                    }
                }
            };
        case FETCH_CONNECTIONS_PUBLIC.SUCCESS:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        allConnections: action.payload.allConnections,
                        mutualConnections: action.payload.mutualConnections
                    }
                }
            };
        case FETCH_CONNECTIONS_PUBLIC.FAILURE:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        allConnections: null,
                        mutualConnections: null
                    }
                }
            };
        case FETCH_ACCOUNTS_PUBLIC.REQUEST:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        accounts: undefined
                    }
                }
            };
        case FETCH_ACCOUNTS_PUBLIC.SUCCESS:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        accounts: action.payload.accounts
                    }
                }
            };
        case FETCH_ACCOUNTS_PUBLIC.FAILURE:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        accounts: null
                    }
                }
            };
        case FETCH_PROMOTED_PUBLIC.REQUEST:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        promotedCampaigns: undefined
                    }
                }
            };
        case FETCH_PROMOTED_PUBLIC.SUCCESS:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        promotedCampaigns: action.payload.promotedCampaigns
                    }
                }
            };
        case FETCH_PROMOTED_PUBLIC.FAILURE:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        promotedCampaigns: null
                    }
                }
            };
        case FETCH_MEDIA_PUBLIC.REQUEST:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        customMedia: undefined
                    }
                }
            };
        case FETCH_MEDIA_PUBLIC.SUCCESS:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        customMedia: action.payload.customMedia
                    }
                }
            };
        case FETCH_MEDIA_PUBLIC.FAILURE:
            return {
                ...state,
                publicDataStore: {
                    ...state.publicDataStore,
                    [action.payload.otherId]: {
                        ...state.publicDataStore[action.payload.otherId],
                        customMedia: null
                    }
                }
            };
        case FETCH_CHAT_TOKEN_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        case FETCH_SEARCH_TOKEN_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        case POST_ACCOUNT.REQUEST:
        case POST_ACCOUNT.BATCH:
            return { ...state, postAccountLoading: true };
        case POST_ACCOUNT.REPLACE:
            localStorage.setItem(
                "currentAccount",
                JSON.stringify(action.payload)
            );
            return {
                ...state,
                currentAccount: action.payload,
                accounts: state.accounts
                    ? state.accounts.map(account => {
                          if (account.id === action.payload.id) {
                              return action.payload;
                          } else {
                              return account;
                          }
                      })
                    : null,
                postAccountLoading: false
            };
        case POST_ACCOUNT.ADD:
            return {
                ...state,
                accounts: state.accounts
                    ? state.accounts.concat(action.payload)
                    : null,
                postAccountLoading: false
            };
        case FETCH_VERIFY_CODE.SUCCESS:
            return { ...state, ...action.payload };
        case PUSH_WITH_ACCOUNT.DATA:
            localStorage.setItem(
                "currentAccount",
                JSON.stringify(action.payload.currentAccount)
            );
            return { ...state, ...action.payload, verifyCode: null };
        case FETCH_INSTAGRAM_HANDLE_SUCCESS:
            return { ...state, ...action.payload };
        case ADD_ACCOUNT:
            if (state.socialAccountsIndex === -1) {
                if (newState.profile) {
                    newState.profile.SocialAccounts.push(action.payload);
                }
                return newState;
            } else {
                if (newState.profile) {
                    newState.profile.SocialAccounts[state.socialAccountsIndex] =
                        action.payload;
                }
                return newState;
            }
        case CURRENT_ACCOUNT:
            newState.socialAccountsIndex = action.payload;
            return newState;
        case POST_ACCOUNTS.SUCCESS:
            if (newState.profile) {
                newState.profile.SocialAccounts = action.payload;
            }
            return newState;
        case FETCH_INVITE_CODES.REQUEST:
            return { ...state, ...action.payload };
        case FETCH_INVITE_CODES.SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_INVITE_CODES.FAILURE:
            return { ...state, ...action.payload };
        case UPDATE_USERNAME.SUCCESS:
            if (state.publicProfile) {
                return {
                    ...state,
                    profile: { ...state.profile, ...action.payload },
                    publicProfile: {
                        ...state.publicProfile,
                        profile: {
                            ...state.publicProfile.profile,
                            ...action.payload
                        }
                    }
                };
            } else {
                return state;
            }
        case UPDATE_USERNAME.FAILURE:
            return { ...state, ...action.payload };
        case UPDATE_PHOTO.SUCCESS:
            if (state.publicProfile) {
                return {
                    ...state,
                    profile: {
                        ...state.profile,
                        profile_photo_url: action.payload
                    },
                    publicProfile: {
                        ...state.publicProfile,
                        profile: {
                            ...state.publicProfile.profile,
                            avatar: action.payload
                        }
                    }
                };
            } else {
                return state;
            }
        case UPDATE_PHOTO.FAILURE:
            return { ...state, ...action.payload };
        case UPDATE_USER_FIELDS.SUCCESS:
            return {
                ...state,
                profile: { ...state.profile, ...action.payload }
            };
        case UPDATE_USER_FIELDS.FAILURE:
            return { ...state, ...action.payload };
        case UPDATE_USER_PAYPAL_CASHOUT_EMAIL.REQUEST:
            return { ...state, paypalEmailLoading: true };
        case UPDATE_USER_PAYPAL_CASHOUT_EMAIL.SUCCESS:
            return {
                ...state,
                paypalEmailLoading: false,
                profile: {
                    ...state.profile,
                    paypal_cashout_email: action.payload.paypalCashoutEmail
                }
            };
        case UPDATE_USER_PAYPAL_CASHOUT_EMAIL.FAILURE:
            return { ...state, paypalEmailLoading: false, ...action.payload };
        case REQUEST_PAYPAL_CASHOUT.REQUEST:
        case REQUEST_TIPALTI_CASHOUT.REQUEST:
            return { ...state, cashoutLoading: true, error: null };
        case REQUEST_PAYPAL_CASHOUT.SUCCESS:
        case REQUEST_TIPALTI_CASHOUT.SUCCESS:
        case REQUEST_TIPALTI_CASHOUT.FAILURE:
            return { ...state, cashoutLoading: false };
        case REQUEST_PAYPAL_CASHOUT.FAILURE:
            return {
                ...state,
                cashoutLoading: false,
                error: action.payload.error
            };
        case BIO_CHANGE_SUCCESS:
            return {
                ...state,
                profile: { ...state.profile, ...action.payload }
            };
        case PRIVATE_CHANGE_SUCCESS:
            if (state.publicProfile) {
                return {
                    ...state,
                    profile: {
                        ...state.profile,
                        private_account: action.payload
                    },
                    publicProfile: {
                        ...state.publicProfile,
                        profile: {
                            ...state.publicProfile.profile,
                            privateAccount: action.payload
                        }
                    }
                };
            } else {
                return state;
            }
        case COMPLETE_ACCOUNT_SETUP.SUCCESS:
            if (state.profile) {
                return {
                    ...state,
                    profile: { ...state.profile, account_setup: true }
                };
            } else {
                return state;
            }
        case COMPLETE_ACCOUNT_LEGACY:
            if (state.profile) {
                return {
                    ...state,
                    profile: { ...state.profile, legacy_account_setup: true }
                };
            } else {
                return state;
            }
        case UPDATE_OR_CREATE_TIPALTI_USER.FAILURE:
            return { ...state, ...action.payload };
        case FETCH_CASHOUTS.SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_CASHOUTS.FAILURE:
            return { ...state, ...action.payload };
        case GET_FEES_FOR_CASHOUT.SUCCESS:
            return { ...state, ...action.payload };
        case GET_FEES_FOR_CASHOUT.FAILURE:
            return { ...state, ...action.payload };
        case REQUEST_TIPALTI_CASHOUT.FAILURE:
            return { ...state, ...action.payload };
        case REQUEST_PAYPAL_CASHOUT.FAILURE:
            return { ...state, ...action.payload };
        case LISTENER_UPDATE_BALANCE:
            return {
                ...state,
                profile: { ...state.profile, ...action.payload }
            };
        case FETCH_WEEKLY_LEADERBOARD.REQUEST:
            return { ...state, ...action.payload };
        case FETCH_WEEKLY_LEADERBOARD.SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_WEEKLY_LEADERBOARD.FAILURE:
            return { ...state, ...action.payload };
        case GET_FULLSCREEN_ALERTS.SUCCESS:
            return { ...state, ...action.payload };
        case RESOLVE_FULLSCREEN_ALERT.SUCCESS:
            return {
                ...state,
                fullscreenAlerts: state.fullscreenAlerts.filter(
                    alert => alert.id !== action.payload
                )
            };
        case FETCH_REFERRAL_CODE.SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_PRE_APPROVAL_CODE.REQUEST:
            return { ...state, preApprovalCodeLoading: true };
        case FETCH_PRE_APPROVAL_CODE.SUCCESS:
            return {
                ...state,
                preApprovalCodeLoading: false,
                ...action.payload
            };
        case FETCH_PRE_APPROVAL_CODE.FAILURE:
            return { ...state, preApprovalCodeLoading: false };
        case APPLY_PRE_APPROVAL_CODE.REQUEST:
            return {
                ...state,
                applyPreApprovalCodeLoading: true,
                applyPreApprovalCodeError: null
            };
        case APPLY_PRE_APPROVAL_CODE.SUCCESS:
            return {
                ...state,
                applyPreApprovalCodeLoading: false,
                applyPreApprovalCodeError: null,
                profile: {
                    ...state.profile,
                    accessStatus: "challenges"
                }
            };
        case APPLY_PRE_APPROVAL_CODE.FAILURE:
            return {
                ...state,
                applyPreApprovalCodeLoading: false,
                applyPreApprovalCodeError: action.payload
            };
        default:
            return state;
    }
};

export default reducer;

function replaceConnection(
    connection: Connection,
    dataStore: PublicDataStore
): PublicDataStore {
    const newStore: PublicDataStore = {};

    for (let pubId in dataStore) {
        const currentPub = dataStore[pubId];

        if (!(currentPub.allConnections && currentPub.mutualConnections)) {
            newStore[pubId] = currentPub;
        } else {
            const mutualConnections = currentPub.mutualConnections.filter(
                oldConnection => {
                    return (
                        connection.state !== "blocked" ||
                        connection.other.id !== oldConnection.other.id
                    );
                }
            );

            if (connection.state === "friend") {
                const possibleMutual = currentPub.allConnections.find(
                    oldConnection => {
                        return oldConnection.other.id === connection.other.id;
                    }
                );

                if (possibleMutual) {
                    mutualConnections.push(connection);
                }
            }

            newStore[pubId] = {
                ...currentPub,
                allConnections: currentPub.allConnections.map(oldConnection => {
                    if (connection.other.id === oldConnection.other.id) {
                        return connection;
                    } else {
                        return oldConnection;
                    }
                }),
                mutualConnections
            };
        }
    }

    return newStore;
}

function addOrModifyConnections(
    connections: Connection[] | null | undefined,
    newConnection: Connection
) {
    if (connections) {
        let flag = false;
        const updatedConnections = connections.map(oldConnection => {
            if (newConnection.other.id === oldConnection.other.id) {
                flag = true;
                return newConnection;
            }
            return oldConnection;
        });

        if (!flag) {
            updatedConnections.push(newConnection);
        }
        return updatedConnections;
    }
    return null;
}

function removeConnection(
    otherId: string,
    dataStore: PublicDataStore
): PublicDataStore {
    const newStore: PublicDataStore = {};

    for (let pubId in dataStore) {
        const currentPub = dataStore[pubId];

        if (!(currentPub.allConnections && currentPub.mutualConnections)) {
            newStore[pubId] = currentPub;
        } else {
            newStore[pubId] = {
                ...currentPub,
                allConnections: currentPub.allConnections.map(connection => {
                    if (otherId === connection.other.id) {
                        return {
                            ...connection,
                            relationship: null,
                            state: null
                        };
                    } else {
                        return connection;
                    }
                }),
                mutualConnections: currentPub.mutualConnections.filter(
                    connection => {
                        return otherId !== connection.other.id;
                    }
                )
            };
        }
    }

    return newStore;
}
