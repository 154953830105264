import React, { useState } from "react";
import { useDispatch } from "react-redux";
import UserInfoHeader from "src/campaigns/scripts/components/UserInfoHeader";
import { CampaignMedia } from "src/campaigns/types";
import GlobalWarning, {
    GlobalWarningProps
} from "src/components/GlobalWarning";
import Toggler from "src/components/Toggler";
import { colors } from "src/constants";
import { deleteMediaRequest, updateMediaRequest } from "src/media/actions";
import { DeleteButton } from "src/profile/components/Buttons";
import { PublicProfile } from "src/profile/types";
import styled from "styled-components";

const TooltipTitle = styled.h3`
    font-size: 13px;
    padding: 8px 0px;
`;
const TooltipUserWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
`;
const TooltipPlacements = styled.div`
    background-color: ${colors.teritaryLightGray};
    cursor: pointer;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 8px;
    height: 62px;
    margin-bottom: 8px;
    transition: background-color ease 0.2s;
    &:hover {
        background-color: ${colors.secondaryLightGray};
    }
`;
const TooltipPlacementIcon = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 8px;
    margin-right: 12px;
`;
const TooltipHeading = styled.h4`
    font-size: 13px;
    margin: 0px;
    text-align: left;
`;
const TooltipSubtitle = styled.p`
    margin: 0px;
    font-size: 12px;
    color: ${colors.primaryGray};
    text-align: left;
`;
const ChevronIcon = styled.img`
    transform: rotate(-90deg);
    margin: 0px;
    margin-left: auto;
    /* padding-right: 8px; */
`;
const TooltipShowWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid ${colors.blueGrey2};
    padding: 12px 0px;
    margin-bottom: 8px;
`;
const StyledDeleteBtn = styled(DeleteButton)`
    height: 32px;
    font-size: 13px;
    font-weight: normal;
`;

interface Props {
    profile?: PublicProfile;
    placements: any;
    setIsPlacementForm: VoidFunction;
    profileLoading: boolean;
    media: CampaignMedia;
    setShowWarning: React.Dispatch<
        React.SetStateAction<GlobalWarningProps | null>
    >;
}

const MediaDetails = ({
    profile,
    placements,
    setIsPlacementForm,
    profileLoading,
    media,
    setShowWarning
}: Props) => {
    const {
        createdAt,
        placementIds,
        shouldShowInApp,
        allowOthersToUse
    } = media;
    const dispatch = useDispatch();

    const updateShouldShow = () => {
        dispatch(
            updateMediaRequest(
                {
                    ...media,
                    shouldShowInApp: !media.shouldShowInApp
                },

                "Media set to available on The Plug"
            )
        );
    };

    const handleAllowOthersToUse = () => {
        if (allowOthersToUse) {
            updateShouldShow();
        } else {
            setShowWarning({
                title: "WARNING",
                message:
                    "The user who uploaded this media has not allowed others to use it. Only continue if you have been given permission by the uploader!",
                btnText: { cancel: "Cancel", apply: "Continue" },
                handleCancel: () => setShowWarning(null),
                handleApply: () => {
                    updateShouldShow();
                    setShowWarning(null);
                }
            });
        }
    };
    return (
        <>
            <TooltipTitle>Media Details</TooltipTitle>
            <TooltipUserWrap>
                <UserInfoHeader
                    publisher={profile}
                    createdAt={createdAt}
                    loading={profileLoading}
                />
            </TooltipUserWrap>
            {placementIds && (
                <TooltipPlacements onClick={setIsPlacementForm}>
                    {placementIds.length > 0 && (
                        <TooltipPlacementIcon
                            src={placements[placementIds[0]].logo}
                            alt=""
                        />
                    )}
                    <div>
                        <TooltipHeading>
                            {placementIds.length} Placement
                            {placementIds.length !== 1 && "s"}
                        </TooltipHeading>
                        <TooltipSubtitle>
                            {placementIds
                                .map(id => `${placements[id].type}`)
                                .join(", ")}
                        </TooltipSubtitle>
                    </div>
                    <ChevronIcon src="/caret.svg" alt="" />
                </TooltipPlacements>
            )}
            <TooltipShowWrap>
                <div>
                    <TooltipHeading>Available in App</TooltipHeading>
                    <TooltipSubtitle>
                        Allow anyone on The Plug to view
                    </TooltipSubtitle>
                </div>
                <Toggler
                    isActive={shouldShowInApp}
                    onDeselect={() =>
                        dispatch(
                            updateMediaRequest(
                                {
                                    ...media,
                                    shouldShowInApp: !media.shouldShowInApp
                                },
                                "Media set to unavailable on The Plug"
                            )
                        )
                    }
                    onSelect={() => handleAllowOthersToUse()}
                />
            </TooltipShowWrap>
            <StyledDeleteBtn
                onClick={() =>
                    setShowWarning({
                        message: "Are you sure you want to delete this media?",
                        title: "Delete Media",
                        btnText: { cancel: "Cancel", apply: "Delete" },
                        handleCancel: () => setShowWarning(null),
                        handleApply: () =>
                            dispatch(
                                deleteMediaRequest(media.id, media.campaignId)
                            )
                    })
                }
            >
                Delete Media
            </StyledDeleteBtn>
        </>
    );
};

export default MediaDetails;
