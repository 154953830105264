import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { colors, fonts, fontWeight } from "src/constants";
import { shortenNumber } from "src/util";
import Tag from "src/components/Tag";
import Platforms from "src/social-accounts/Platforms";
import ScrollableWithFadedArrows from "src/components/ScrollableWithFadedArrows";
import {
    clearLink,
    lightGrayBackgroundHoverState,
    preventUserInteraction
} from "src/utils/styles/snippets";
import { codeToEmoji } from "src/notifications/CodeToEmoji";
import { CreatorPricing } from "../types.creator-groups";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import {
    setCreatorCurrentOtherAndSocialAccountId,
    setCreatorsPage
} from "src/ui/creators/actions";
import { CreatorSearchGroupsPageType } from "../pages/CreatorGroupsCreateOrEditPage";
import { GlobalState } from "src/reducers";
import { ReactComponent as ExternalLink } from "src/components/icons/marketplace-creator-card-external-link.svg";
import { Link } from "react-router-dom";
import marketplacePriceIcon from "src/components/icons/marketplace-price.svg";
import { setRouteModalOpen } from "src/ui/modals/actions";

const Main = styled.article`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 6px;
    transition: 0.1s ease-in;
`;

const HeaderScrollablesContainer = styled.div``;

// const Header = styled(Link)`
const Header = styled.header`
    ${clearLink()};
    ${lightGrayBackgroundHoverState()};
    display: flex;
    gap: 12px;
    /* margin-bottom: 10px; */
    /* margin: 6px 6px 10px; */
    margin: -6px -6px 10px;
    padding: 6px;
    background-color: ${colors.transparent};
    border-radius: 4px;
    cursor: pointer;

    > div {
        flex-shrink: 0;
    }
`;

const ProfilePicture = styled.div`
    position: relative;
    width: 56px;
    height: 56px;
`;

const Picture = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
`;

const SocialMediaIcon = styled.img`
    position: absolute;
    right: 2px;
    bottom: 2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
`;

const UserInfo = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const Username = styled.span`
    display: block;
    color: ${colors.black};
    font-size: 13px;
`;

const UserInfoSection = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${colors.primaryGray};
    font-size: ${fonts.smallSubtitle}px;
    text-transform: capitalize;
`;

const CountryIcon = styled.div`
    width: 15px;
    height: 15px;
`;

const ExternalLinkButton = styled(Link)`
    ${clearLink()};
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    background-color: ${colors.quaternaryLightGray};
    border: ${colors.transparent} solid 1px;
    border-radius: 50%;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            border: ${colors.primaryGray} solid 1px;
        }
    }
`;

const ExternalLinkIcon = styled(ExternalLink)`
    fill: ${colors.quaternaryLightGray};
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            fill: ${colors.quaternaryGray};
        }
    }
`;

const Statistic = styled.div<{ isPrice?: true }>`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 4px;
    padding: 8px;
    color: ${colors.black};
    background-color: ${colors.quaternaryLightGray};
    border-radius: 6px;
    font-size: ${fonts.smallSubtitle}px;
    font-weight: ${fontWeight.medium};
`;

const buttonStyles = css`
    flex: 1;
    padding: 8px 0px;
    border-radius: 6px;
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.medium};
    transition: 0.1s ease-in;
`;

interface AddCreatorButtonProps {
    creatorAddedToGroup?: boolean;
    onMainPage?: true;
}

const AddCreatorButton = styled.button<AddCreatorButtonProps>`
    ${buttonStyles}
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: ${colors.white};
    border: ${colors.primaryBlue} solid 1px;
    background-color: ${colors.primaryBlue};

    ${props =>
        !props.creatorAddedToGroup &&
        !props.onMainPage &&
        css`
            @media (hover: hover) {
                :hover {
                    background-color: ${colors.primaryBlueDark};
                    border: ${colors.primaryBlueDark} solid 1px;
                }
            }
        `}

    ${props => props.creatorAddedToGroup && preventUserInteraction()};
`;

const RemoveCreatorButton = styled.button`
    ${buttonStyles}
    color: ${colors.primaryRed};
    border: ${colors.mediumTeal} solid 1px;

    @media (hover: hover) {
        :hover {
            color: ${colors.white};
            background-color: ${colors.primaryRed};
            border: ${colors.primaryRed} solid 1px;
        }
    }
`;

const SmallIcon = styled.img`
    width: 12px;
    height: 12px;
`;

const ResultsIncrementor = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1px;
    height: 1px;
`;

export interface CreatorSearchUserStatistics {
    priceRange: [number, number];
    averageViews: number;
    percentEngagement: number;
}

interface Props {
    onMainPage?: true;

    publisherId: number;
    socialAccountId: number;
    profilePictureUrl: string;
    platform: string;
    username: string;
    location: string;
    audienceCountryWeight: number;
    interests: string[];
    numberFollowers: number;
    averageViews: number;
    engagementRate: number;
    countryCode: string | null;
    pricing: CreatorPricing[];

    handleAddCreator?: () => void;
    handleRemoveCreator?: () => void;
    incrementCreatorCards?: () => void;

    index?: number;
    creatorCount?: number;
    creatorAddedToGroup?: boolean;
    hideButtons?: boolean;
    savedGroups?: true;
}

const CreatorCard = ({
    onMainPage,
    profilePictureUrl,
    platform,
    username,
    location,
    audienceCountryWeight,
    interests,
    numberFollowers,
    averageViews,
    engagementRate,
    countryCode,
    pricing,
    handleAddCreator,
    handleRemoveCreator,
    creatorAddedToGroup,
    index,
    creatorCount,
    incrementCreatorCards,
    publisherId,
    socialAccountId,
    hideButtons,
    savedGroups
}: Props) => {
    // Library Methods -----------------------------------------------
    const { ref, inView } = useInView();
    const dispatch = useDispatch();

    // Local State ---------------------------------------------------
    const [addCreatorButtonIsHovered, setAddCreatorButtonIsHovered] = useState(
        false
    );

    // Local Constants -----------------------------------------------

    const interestsTags =
        interests.length > 0
            ? interests.map((theme: string, index: number) => {
                  return (
                      <Tag
                          key={`marketplace-creator-search-${username}-${profilePictureUrl}-${theme}-${index}`}
                          text={theme}
                          capitalize
                          tight
                      />
                  );
              })
            : [
                  <Tag
                      key={`marketplace-creator-search-${username}-${profilePictureUrl}-no-interests-${index}`}
                      text={"No Interests"}
                      capitalize
                      tight
                  />
              ];

    const statisticsElements = [
        <Statistic
            key={`marketplace-creator-search-${numberFollowers}-${username}-${profilePictureUrl}-1`}
        >
            <SmallIcon
                src="/marketplace-followers.svg"
                alt="marketplace followers icon"
            />
            {shortenNumber(numberFollowers, 1)}
        </Statistic>,
        <Statistic
            key={`marketplace-creator-search-${averageViews}-${username}-${profilePictureUrl}-2`}
        >
            <SmallIcon src="/marketplace-eye.svg" alt="marketplace eye icon" />
            {shortenNumber(averageViews, 1)}
        </Statistic>,
        <Statistic
            key={`marketplace-creator-search-${engagementRate}-${username}-${profilePictureUrl}-3`}
        >
            <SmallIcon
                src="/marketplace-engagement-rate.svg"
                alt="marketplace engagement icon"
            />
            {shortenNumber(engagementRate * 100)}%
        </Statistic>,
        generatePriceStatisticElement()
    ];

    // Local Functions -----------------------------------------------
    function handleHeaderClick(): void {
        dispatch(
            setCreatorCurrentOtherAndSocialAccountId(
                publisherId,
                socialAccountId
            )
        );
        dispatch(setRouteModalOpen(true));
    }

    function checkIfFlatRatePricingIsAvailable() {
        for (const price of pricing) {
            if (price.payment_type === "flat_rate") return true;
        }
        return false;
    }

    function generatePriceRange() {
        // Stick to just flat rate for more consistent pricing?
        for (const price of pricing) {
            const { payment_type, min, max } = price;
            if (payment_type === "flat_rate") {
                return (
                    <Statistic
                        key={`marketplace-creator-search-${numberFollowers}-${username}-${profilePictureUrl}-0`}
                    >
                        <SmallIcon
                            src={marketplacePriceIcon}
                            alt="marketplace price icon"
                        />
                        {min === max
                            ? `$${max} Est. Price`
                            : `$${min}-${max} Est. Price`}
                    </Statistic>
                );
            }
        }
        return <></>;
    }

    function generatePriceStatisticElement() {
        const pricingExists = pricing.length > 0;
        const flatRatePricingIsAvailable = checkIfFlatRatePricingIsAvailable();
        if (pricingExists && flatRatePricingIsAvailable) {
            return generatePriceRange();
        } else {
            return <></>;
        }
    }

    // Effects -------------------------------------------------------
    useEffect(() => {
        if (inView && incrementCreatorCards) {
            incrementCreatorCards();
        }
    }, [inView]);

    // JSX -----------------------------------------------------------
    return (
        <Main>
            {!!index && !!creatorCount && index === creatorCount - 6 && (
                <ResultsIncrementor ref={ref} />
            )}

            <HeaderScrollablesContainer>
                {/* <Header onClick={() => dispatch(setRouteModalOpen(true))}> */}
                <Header
                    // to={`/public/${publisherId}/accounts/v/${socialAccountId}`}
                    // target="_blank"
                    onClick={handleHeaderClick}
                >
                    <ProfilePicture>
                        <Picture
                            src={profilePictureUrl || "/no_profile_photo.svg"}
                            alt="profile picture"
                            onError={(
                                e: React.InvalidEvent<HTMLImageElement>
                            ) => (e.target.src = "/no_profile_photo.svg")}
                        />
                        <SocialMediaIcon
                            src={Platforms[platform]?.imgSrc}
                            alt="social media icon"
                        />
                    </ProfilePicture>
                    <UserInfo>
                        <Username>@{username}</Username>
                        <UserInfoSection>
                            <SmallIcon
                                src="/marketplace-location.svg"
                                alt="marketplace location icon"
                            />
                            {location.length > 0
                                ? location
                                : "Location Unknown"}
                        </UserInfoSection>
                        <UserInfoSection>
                            <SmallIcon
                                src="/marketplace-user.svg"
                                alt="marketplace user icon"
                            />
                            {countryCode && (
                                <CountryIcon>
                                    {codeToEmoji[countryCode]}
                                </CountryIcon>
                            )}
                            {shortenNumber(audienceCountryWeight * 100, 0)}%
                        </UserInfoSection>
                    </UserInfo>
                    {/* <ExternalLinkButton
                        to={`/public/${publisherId}/accounts/v/${socialAccountId}`}
                        target="_blank"
                    > */}
                    {/* <ExternalLinkIcon /> */}
                    {/* </ExternalLinkButton> */}
                </Header>

                <ScrollableWithFadedArrows
                    elements={interestsTags}
                    margin={interestsTags.length > 0 ? "0px 0px 10px" : "0px"}
                />

                <ScrollableWithFadedArrows
                    elements={statisticsElements}
                    margin={
                        !hideButtons && statisticsElements.length > 0
                            ? "0px 0px 10px"
                            : "0px"
                    }
                />
            </HeaderScrollablesContainer>

            {!hideButtons && !creatorAddedToGroup && (
                <AddCreatorButton
                    type="button"
                    onClick={!creatorAddedToGroup && handleAddCreator}
                    onMouseEnter={() => setAddCreatorButtonIsHovered(true)}
                    onMouseLeave={() => setAddCreatorButtonIsHovered(false)}
                    creatorAddedToGroup={creatorAddedToGroup}
                    onMainPage={onMainPage}
                >
                    <SmallIcon src="/marketplace-plus.svg" alt="Plus Icon" />
                    Add Creator
                </AddCreatorButton>
            )}

            {!hideButtons && creatorAddedToGroup && handleRemoveCreator && (
                <RemoveCreatorButton
                    type="button"
                    onClick={handleRemoveCreator}
                >
                    Remove Creator
                </RemoveCreatorButton>
            )}
        </Main>
    );
};

export default CreatorCard;
