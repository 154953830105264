import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UserInfoHeader from "src/campaigns/scripts/components/UserInfoHeader";
import { FlexCentered } from "src/components/CommonStyles";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import {
    setSubmissionManagerAccordion,
    SUBMISSION_MANAGER_ACCORDIONS
} from "src/ui/admin-tools/actions";
import styled from "styled-components";
import PublisherDetailsAccordion from "./PublisherDetailsAccordion";
import SubmissionHistoryAccordion from "./SubmissionHistoryAccordion";
import { OpenBuySubmissionSet } from "src/buys/modules/open-buys/types";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto auto 1fr;
`;
const UserInfoWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    margin: 6px 0px;
    border-radius: 8px;
`;
const ChevronWrapper = styled.div`
    ${FlexCentered}
    width: 24px;
    height: 24px;
    border-radius: 12px;
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: ${colors.tertiaryLightGray};
    }
`;

const Chevron = styled.img``;
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.mediumTeal};
`;
const OverflowWrap = styled.div`
    overflow: auto;
    height: 100%;
`;
interface Props {
    submissionSet: OpenBuySubmissionSet;
}

const DetailsColumn = ({ submissionSet }: Props) => {
    const dispatch = useDispatch();
    const activeAccordions = useSelector(
        (state: GlobalState) =>
            state.ui.adminTools.submissionManager.activeAccordions
    );

    const onToggle = (accordion: SUBMISSION_MANAGER_ACCORDIONS) => {
        if (activeAccordions.includes(accordion))
            dispatch(
                setSubmissionManagerAccordion(
                    activeAccordions.filter(acc => acc !== accordion)
                )
            );
        else
            dispatch(
                setSubmissionManagerAccordion(
                    activeAccordions.concat([accordion])
                )
            );
    };
    return (
        <Wrapper>
            <UserInfoWrap>
                {submissionSet?.publisher && (
                    <UserInfoHeader
                        loading={false}
                        publisher={{
                            id: submissionSet.publisherId,
                            profile: {
                                username:
                                    submissionSet.publisher.username ||
                                    submissionSet.publisher.firstname +
                                        " " +
                                        submissionSet.publisher.lastname,
                                avatar:
                                    submissionSet.publisher.profile_photo_url,
                                status: submissionSet.publisher.status
                            }
                        }}
                        showId={true}
                    />
                )}
                <ChevronWrapper
                    onClick={() => {
                        window.open(
                            `https://plugcoin.tryretool.com/apps/e74b9ee2-b6af-11e8-a7b5-37aa970ba234/Creator%20Details?publisher_id=${submissionSet.publisherId}`,
                            "_blank"
                        );
                    }}
                >
                    <Chevron src="/goto-link.svg" alt="" />
                </ChevronWrapper>
            </UserInfoWrap>
            <OverflowWrap>
                <Divider />
                <PublisherDetailsAccordion
                    submissionSet={submissionSet}
                    title={"Details"}
                    onToggle={v =>
                        onToggle(SUBMISSION_MANAGER_ACCORDIONS.publisherDetails)
                    }
                    manualControl={activeAccordions.includes(
                        SUBMISSION_MANAGER_ACCORDIONS.publisherDetails
                    )}
                />
                <Divider />
                <SubmissionHistoryAccordion
                    submissionSet={submissionSet}
                    title={"History"}
                    onToggle={v =>
                        onToggle(SUBMISSION_MANAGER_ACCORDIONS.history)
                    }
                    manualControl={activeAccordions.includes(
                        SUBMISSION_MANAGER_ACCORDIONS.history
                    )}
                />
            </OverflowWrap>
        </Wrapper>
    );
};

export default DetailsColumn;
