import React, { ReactNode } from "react";
import { Redirect } from "react-router";
import { selectFeatureFlags } from "src/firebase/selectors";
import { useSelector } from "react-redux";
import featureFlags from "src/firebase/featureFlags";

interface Props {
    featureFlag: featureFlags;
    redirect?: string;
    children: ReactNode;
}

const FeatureFlagGate = ({ featureFlag, redirect, children }: Props) => {
    const hasAccess = useSelector(selectFeatureFlags(featureFlag));
    if (hasAccess === false && redirect) {
        return <Redirect to={redirect} />;
    }
    return hasAccess ? <>{children}</> : null;
};

export default FeatureFlagGate;
