import React from "react";
import NavbarDesktop from "src/components/NavbarDesktop";
import NavbarMobile from "src/components/NavbarMobile";
import { breakpoints, maxWidthDesktop, sizes } from "src/constants";
import { LayoutThemeType } from "src/ui/layout/actions";
import styled from "styled-components";

interface NavbarProps {
    marginBottom?: number;
    mobileHasMarginBottom?: true;
    theme: LayoutThemeType;
}

const NavbarWrapper = styled.div<NavbarProps>`
    position: relative;
    z-index: 999;
    margin-top: ${props => {
        const { marginBottom } = props;
        return marginBottom
            ? `${sizes.navbar.desktopHeight + marginBottom}px`
            : `${sizes.navbar.desktopHeight}px`;
    }};

    @media (max-width: ${breakpoints.tablet}px) {
        margin-top: ${props => {
            const { marginBottom } = props;
            return marginBottom
                ? `${sizes.navbar.mobileHeight + marginBottom}px`
                : `${sizes.navbar.mobileHeight}px`;
        }};
    }

    @media (max-width: ${maxWidthDesktop}px) {
        margin-top: ${props =>
            props.mobileHasMarginBottom && props.marginBottom
                ? sizes.navbar.mobileHeight + props.marginBottom
                : sizes.navbar.mobileHeight}px;
    }
`;

interface Props {
    marginBottom?: number;
    mobileHasMarginBottom?: true;
    title?: string;
    right?: React.ReactNode;
    back?: true;
    customMobileNavbar?: React.ReactNode;
}

const Navbar = ({
    marginBottom,
    mobileHasMarginBottom,
    title,
    right,
    customMobileNavbar,
    back
}: Props) => {
    return (
        <NavbarWrapper
            marginBottom={marginBottom}
            mobileHasMarginBottom={mobileHasMarginBottom}
        >
            <NavbarDesktop />
            <NavbarMobile
                title={title}
                marginBottom={mobileHasMarginBottom && marginBottom}
                right={right}
                back={back}
                customNavbar={customMobileNavbar}
            />
        </NavbarWrapper>
    );
};

export default Navbar;
