import React, { useEffect, useState } from "react";
import { Contest } from "../types";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import ButtonGroup from "./ButtonGroup";
import {
    combineAndRankOrderPrizes,
    howToWin
} from "../utils/contestObjectFilters";
import { formatNumber } from "../../../util";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { getIsMobile } from "src/utils/functions/selectors";
import { colors, fonts } from "src/constants";

const Main = styled.div`
    margin-bottom: 16px;
    border-radius: 8px;
    overflow: hidden;
`;

interface StyleProps {
    inCard?: true;
}

const ImageContainer = styled.div<StyleProps>`
    position: relative;
    width: 100%;
    height: 0;
    user-select: none;
    padding-top: ${props => (props.inCard ? "160px" : "66%")};
`;

const PrizeImage = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
`;

const TopGradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    pointer-events: none;

    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        transparent
    );
`;

const BottomGradient = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    pointer-events: none;

    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent);
`;

const TagContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 8px 8px 0 8px;
    display: flex;
    flex-direction: column;
`;

const Tag = styled.div`
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    color: white;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
`;

const BlackTag = styled(Tag)`
    background-color: black;
    padding: 8px;
    font-size: ${fonts.subtitle}px;

    & > span {
        font-weight: 400;
    }
`;

const BlueTag = styled(Tag)`
    background: ${colors.primaryBlue};
    margin-top: 4px;
    padding: 6px;
    font-size: ${fonts.smallTitle}px;
`;

interface Props {
    contest: Contest;
    inCard?: true;
}

const PrizeCarousel = ({ contest, inCard }: Props) => {
    const [hide] = useState(
        !!localStorage.getItem(`hide-contest-${contest.id}`) ||
            contest.image_carousel_state === "hide" ||
            contest.status === "about_to_start"
    );

    useEffect(() => {
        if (contest.image_carousel_state === "show_once") {
            localStorage.setItem(`hide-contest-${contest.id}`, "true");
        }
    }, []);

    const isMobile = useSelector(getIsMobile);

    if (hide && inCard) {
        return null;
    } else {
        return (
            <Main>
                <Carousel
                    responsive={{
                        universal: {
                            breakpoint: { max: 100000, min: 0 },
                            items: 1
                        }
                    }}
                    arrows={!isMobile}
                    swipeable
                    draggable
                    customButtonGroup={<ButtonGroup />}
                    keyBoardControl={true}
                >
                    {combineAndRankOrderPrizes(contest).map(prize => {
                        return (
                            <ImageContainer key={prize.id} inCard={inCard}>
                                <PrizeImage src={prize.image_url} />
                                <TopGradient />
                                <BottomGradient />
                                <TagContainer>
                                    <BlackTag>
                                        {prize.name}{" "}
                                        <span>
                                            {` or $${formatNumber(
                                                prize.cash_equivalent
                                            )}`}
                                        </span>
                                    </BlackTag>
                                    <BlueTag>{howToWin(prize)}</BlueTag>
                                </TagContainer>
                            </ImageContainer>
                        );
                    })}
                </Carousel>
            </Main>
        );
    }
};

export default PrizeCarousel;
