import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { MediaTab as MediaTabType } from "src/ui/campaigns/actions";
import { setCurrentMediaTab } from "src/ui/campaigns/actions";
import { GlobalState } from "src/reducers";
import { colors, fonts } from "src/constants";
import { flexRowCenter } from "src/utils/styles/snippets";

interface MainProps {
    current: boolean;
}

const Main = styled("div")<MainProps>`
    ${flexRowCenter()};
    height: 56px;
    border-bottom: ${props => {
        return props.current
            ? `${colors.black} solid 1px`
            : "transparent solid 1px;";
    }};
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const Text = styled("span")<MainProps>`
    display: block;
    color: ${props => (props.current ? colors.black : colors.primaryGray)};
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    line-height: 18px;
`;

interface Props {
    tab: MediaTabType;
}

const MediaTab = (props: Props) => {
    const { tab } = props;

    const currentMediaTab = useSelector((state: GlobalState) => {
        return state.ui.campaign.currentMediaTab;
    });

    const isCurrent = currentMediaTab === tab;

    const dispatch = useDispatch();
    function setCurrentMediaTabHandler(): void {
        dispatch(setCurrentMediaTab(tab));
    }

    return (
        <Main onClick={setCurrentMediaTabHandler} current={isCurrent}>
            <Text current={isCurrent}>{tab}</Text>
        </Main>
    );
};

export default MediaTab;
