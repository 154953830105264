import React from "react";
import { useSelector } from "react-redux";
import CampaignReward from "src/campaigns/details/components/CampaignReward";
import { MediasEntitiesState } from "src/campaigns/medias/reducer";
import { Campaign } from "src/campaigns/types";
import { colors, fonts } from "src/constants";
import { GlobalState } from "src/reducers";
import { hideOverflow } from "src/utils/styles/snippets";
import styled from "styled-components";
import PlacementsElement from "./PlacementsElement";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const AppData = styled.div`
    display: flex;
`;

const AppDataIcon = styled.img`
    width: 46px;
    height: 46px;
    border-radius: 12px;
`;

const AppDataInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    text-align: left;
`;

const CampaignName = styled.span`
    ${hideOverflow()};
    font-size: ${fonts.subtitle}px;
    font-weight: 600;
`;

const Placements = styled.div`
    padding: 12px;
    background-color: ${colors.quaternaryLightGray};
    border-radius: 8px;
`;

const PlacementsTitle = styled.span`
    display: block;
    margin-bottom: 10px;
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: 700;
    text-align: left;
`;

const PlacementsElements = styled.div`
    display: flex;
    gap: 8px;
`;

interface Props {
    campaign: Campaign;
    medias: MediasEntitiesState;
    currentItemId: number;
}

const InstructionElementsBodyAppHeader = ({
    campaign,
    medias,
    currentItemId
}: Props) => {
    const placements = useSelector((state: GlobalState) => {
        return state.entities.placements.byId;
    });

    const currentMedia = medias[currentItemId];

    return (
        <Main>
            <AppData>
                <AppDataIcon src={campaign.imageUrl} />
                <AppDataInfo>
                    <CampaignName>{campaign.campaignName}</CampaignName>
                    <CampaignReward
                        countryPlatformReward={campaign.countryPlatformReward}
                        rewardType={campaign.rewardType}
                        textSize={fonts.smallTitle}
                    />
                </AppDataInfo>
            </AppData>

            {!!currentMedia && currentMedia.placementIds.length > 0 && (
                <Placements>
                    <PlacementsTitle>PLACEMENTS</PlacementsTitle>
                    <PlacementsElements>
                        {currentMedia.placementIds.map(
                            (placementId: number) => {
                                const placement = placements[placementId];

                                return (
                                    <PlacementsElement
                                        key={placementId}
                                        socialLogo={placement.logo}
                                        text={placement.type}
                                    />
                                );
                            }
                        )}
                    </PlacementsElements>
                </Placements>
            )}
        </Main>
    );
};

export default InstructionElementsBodyAppHeader;
