import React from "react";
import { Contest } from "../types";
import styled from "styled-components";
import { SimpleButton } from "../components/styledComponents";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 15px;
    border-top: 1px solid #e0eef2;
    text-align: left;
    font-size: ${fonts.smallTitle}px;
`;

const ContestTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

const Subtitle = styled.div`
    color: #828282;
    margin-bottom: 15px;
`;

const Title = styled.div`
    margin-bottom: 5px;
    font-weight: bold;
`;

const Description = styled.div`
    padding-bottom: 15px;
`;

const Link = styled.a`
    color: #0079f5;
`;

interface Props {
    contest: Contest;
    push: (path: string) => void;
}

const Rules = ({ contest, push }: Props) => {
    return (
        <Main>
            <ContestTitle>{contest.name}</ContestTitle>
            <Subtitle>
                {"Presented by "}
                <strong>{contest.advertiser.name}</strong>
            </Subtitle>
            <Title>Contest Rules</Title>
            <Description>
                <span>
                    {contest.rules}{" "}
                    <Link href={contest.terms_and_condition_url}>
                        Terms and Conditions apply
                    </Link>
                    .
                </span>
            </Description>
            <SimpleButton onClick={() => push("/contest/prize")}>
                View Contest Prizes
            </SimpleButton>
        </Main>
    );
};

export default Rules;
