import { motion } from "framer-motion";
import React from "react";
import { useDispatch } from "react-redux";
import { colors, fonts, fontWeight } from "src/constants";
import { setSocialAccountDetailsModalOpen } from "src/ui/social-accounts/actions";
import { shortenNumber } from "src/util";
import {
    clearButton,
    lightGrayBackgroundHoverState
} from "src/utils/styles/snippets";
import styled from "styled-components";
import { SocialAccountDetailsIconType } from "../utils/social-accounts.types";

const Main = styled(motion.button)`
    ${clearButton()};
    /* ${lightGrayBackgroundHoverState()}; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: ${colors.teritaryLightGray};
    border-radius: 8px;
    /* cursor: pointer; */
    cursor: default;
`;

const Data = styled.span`
    color: ${colors.black};
    font-size: ${fonts.subtitle}px;
    font-weight: ${fontWeight.medium};
`;

const Label = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
`;

const LabelIcon = styled.img`
    width: 12px;
    height: 12px;
`;

const LabelText = styled.span`
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.default};
`;

interface Props {
    data: string | number;
    iconType?: SocialAccountDetailsIconType;
    label: string;
    setCurrentHoveredDetail: React.Dispatch<React.SetStateAction<string>>;
}

const DetailCell = ({
    data,
    iconType,
    label,
    setCurrentHoveredDetail
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------

    // Local State ---------------------------------------------------

    // Local Constants -----------------------------------------------

    // Local Functions -----------------------------------------------
    function openDetailsModal(): void {
        dispatch(setSocialAccountDetailsModalOpen(true));
    }

    // Custom Hooks --------------------------------------------------

    // Effects -------------------------------------------------------

    // JSX -----------------------------------------------------------
    return (
        <Main
            type="button"
            onClick={openDetailsModal}
            onHoverStart={e => {
                setCurrentHoveredDetail(label);
            }}
        >
            <Data>{typeof data === "number" ? shortenNumber(data) : data}</Data>

            <Label>
                {iconType &&
                    (iconType === "person" ? (
                        <LabelIcon
                            src="/marketplace-followers.svg"
                            alt="Social Account Profile Person Icon"
                        />
                    ) : iconType === "eye" ? (
                        <LabelIcon
                            src="/marketplace-eye.svg"
                            alt="Social Account Profile Eye Icon"
                        />
                    ) : (
                        <LabelIcon
                            src="/marketplace-engagement-rate.svg"
                            alt="Social Account Profile Percent Icon"
                        />
                    ))}
                <LabelText>{label}</LabelText>
            </Label>
        </Main>
    );
};

export default DetailCell;
