import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toggler from "src/components/Toggler";
import { GlobalState } from "src/reducers";
import { setInstructionSteps } from "src/ui/instructions-sets/actions";
import styled from "styled-components";

const OuterBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    padding: 14px;
`;
const Label = styled.span`
    font-size: 14px;
`;

interface Props {
    currentInstructionStepIndex: number;
}
function PlugLinkTooltipMenu({ currentInstructionStepIndex }: Props) {
    const dispatch = useDispatch();
    const instructionSteps = useSelector(
        (state: GlobalState) => state.ui.instructionsSets.instructionSteps
    );
    const instructionStep = instructionSteps[currentInstructionStepIndex];
    const updateSkipLandingPage = (value: boolean) => {
        const updatedInstructionSteps = Array.from(instructionSteps);
        const currentInstructionStep =
            updatedInstructionSteps[currentInstructionStepIndex];
        currentInstructionStep.plugLinkSkipLandingPage = value;
        dispatch(setInstructionSteps(updatedInstructionSteps));
    };
    return (
        <OuterBox>
            <Label>Skip Landing Page</Label>
            <Toggler
                isActive={instructionStep.plugLinkSkipLandingPage}
                onSelect={() => updateSkipLandingPage(true)}
                onDeselect={() => updateSkipLandingPage(false)}
            />
        </OuterBox>
    );
}

export default PlugLinkTooltipMenu;
