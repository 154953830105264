import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";

export const getMainHighlights = createSelector(
    (state: GlobalState) => state.entities.highlights,
    (state: GlobalState) => state.ui.highlights.placementConfig,
    (highlights, placementConfig) => {
        const allHighlights = highlights.allIds.map(
            (highlightId: number) => highlights.byId[highlightId]
        );

        if (Object.keys(placementConfig).length) {
            const filteredHighlights = allHighlights.filter(
                (highlight: any) => placementConfig[highlight.placement.type]
            );
            if (allHighlights.length && !filteredHighlights.length) return null;
            return filteredHighlights;
        }
        return allHighlights;
    }
);

const highlightEntities = (state: GlobalState) =>
    state.entities.highlights.byId;

const currentModalHighlightIds = (state: GlobalState) =>
    state.ui.highlights.currentModalHighlights;

export const getCurrentHighlightModalMedias = createSelector(
    currentModalHighlightIds,
    highlightEntities,
    (currentModalHighlightIds, highlights) => {
        return currentModalHighlightIds.map(id => highlights[id]);
    }
);

export const fetchSelectedCampaignsNames = createSelector(
    (state: GlobalState) => state.entities.campaigns.byId,
    (state: GlobalState, campaignIds: number[]) => campaignIds,
    (campaignsState, selectedCampaignsIds) => {
        const selectedCampaignsNames = selectedCampaignsIds.map(
            (id: number) => {
                return campaignsState[id].campaignName;
            }
        );

        const namesFormattedForTextDisplay = selectedCampaignsNames.join(", ");

        return namesFormattedForTextDisplay;
    }
);