import React, { useEffect, useState } from "react";
import { Campaign, Script } from "src/campaigns/types";
import styled from "styled-components";
import DropMediaUploader from "src/components/media/DropMediaUploader";
import { CustomAdminMediaUpload } from "src/media/actions";
import AddFileFooter from "./AddFileFooter";
import ModalHeader from "./ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { setWarning } from "src/ui/scripts/actions";
import { scriptMediaUpload } from "../actions";
import { ProcessingControl } from "../hooks/useProcessing";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    overflow: auto;
`;

interface Props {
    title: string;
    script: Script;
    campaign: Campaign;
    isAsset?: boolean;
    processingControl: ProcessingControl;
}
const AddFile = ({
    title,
    campaign,
    script,
    isAsset = false,
    processingControl
}: Props) => {
    const dispatch = useDispatch();
    const isWarning = useSelector(
        (state: GlobalState) => state.ui.script.warning
    );
    const initialUploadData = {
        campaign_id: campaign.id,
        filename: "",
        original_filename: "",
        cover_photo: "",
        type: "",
        allow_others_to_use: true,
        external_page_id: null,
        uploading: true,
        url: "",
        is_asset: isAsset
    };

    const [uploadData, setUploadData] = useState<CustomAdminMediaUpload[]>([]);

    useEffect(() => {
        if (uploadData.length > 0 && !isWarning) {
            dispatch(setWarning(true));
        } else if (uploadData.length === 0 && isWarning) {
            dispatch(setWarning(false));
        }
    }, [uploadData]);
    const handleUploadBegin = (
        id: string,
        type: string,
        original_filename: string
    ) => {
        setUploadData([
            ...uploadData,
            { ...initialUploadData, type, filename: id, original_filename }
        ]);
    };

    const handleUpload = (id: string, url: string) => {
        setUploadData(prevState =>
            prevState.map(data =>
                data.filename === id
                    ? {
                          ...data,
                          url,
                          uploading: data.type === "photo" ? false : true
                      }
                    : data
            )
        );
    };
    const handleRemove = (idx: number) => {
        setUploadData(uploadData.filter((data, dataIdx) => idx !== dataIdx));
    };

    const handleCoverPhoto = (coverPhotoId: string, id: string) => {
        setUploadData(prevState => {
            const newData = prevState.map(data =>
                data.filename === id
                    ? { ...data, cover_photo: coverPhotoId, uploading: false }
                    : data
            );
            return newData;
        });
    };
    const handleSubmit = () => {
        const publisherId = localStorage.getItem("userid");
        dispatch(setWarning(false));
        if (publisherId && parseInt(publisherId)) {
            dispatch(
                scriptMediaUpload(uploadData, script.id, parseInt(publisherId))
            );
            const processingKey = isAsset
                ? processingControl.assets
                : processingControl.medias;
            processingControl.set(processingKey, uploadData.length);
        }
    };

    const count = uploadData.filter(data => !data.uploading).length;
    const isUploading = uploadData.filter(data => data.uploading).length > 0;

    return (
        <Wrapper>
            <ModalHeader
                title={title}
                campaign={campaign}
                scriptTitle={script.title}
            />
            <DropMediaUploader
                isAsset={isAsset}
                path={`${campaign.linkUrlPrefix}`}
                onUploadBegin={handleUploadBegin}
                onUpload={handleUpload}
                onRemove={(idx: number) => handleRemove(idx)}
                onCoverPhoto={handleCoverPhoto}
                isMultiUpload
                medias={uploadData}
            />
            <AddFileFooter
                handleSubmit={handleSubmit}
                count={count}
                disabled={uploadData.length === 0 || isUploading}
                btnText={isAsset ? "Add Asset" : "Add Media"}
            />
        </Wrapper>
    );
};

export default AddFile;
