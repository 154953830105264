import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ProfilePhoto from "src/components/ProfilePhoto";
import { PublisherProfile } from "src/profile/types";
import { colors } from "src/constants";
import { get } from "src/Api";
import { flex } from "src/utils/styles/snippets";

const Main = styled(Link)`
    ${flex("row", "space-between", "center")};
    width: 100%;
    color: inherit;
    background-color: ${colors.transparent};
    border-radius: 6px;
    text-decoration: none;
    transition: 0.1s ease-in;
    outline: none;

    :hover {
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
        }
    }
`;

const ProfilePic_Username_Info = styled("div")`
    ${flex("row", "auto", "center")};
`;

const Username_Info = styled("div")`
    display: flex;
    flex-direction: column;
    margin-left: 11px;
`;

const Username = styled("h5")`
    margin: 0px 0px 6px 0px;
    padding: 0px;
    color: ${colors.black};
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
`;

const Info = styled("span")`
    display: block;
    color: ${colors.primaryGray};
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.125rem;
    letter-spacing: -0.009625rem;
    text-align: left;
`;

const ReferralBonus = styled("span")`
    display: block;
    color: ${colors.black};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.0625rem;
`;

interface Props {
    userId: number;
    percentEarnings: number;
    dateReferralCreated: string;
    daysActive: number;
    referralBonus: number;
}

const ReferredUser = (props: Props) => {
    const {
        userId,
        percentEarnings,
        dateReferralCreated,
        daysActive,
        referralBonus
    } = props;

    const [profile, setProfile] = useState<PublisherProfile | null>(null);

    useEffect(() => {
        getUserProfilePictureAndUsername();
    }, []);

    async function getUserProfilePictureAndUsername(): Promise<void> {
        try {
            const endpoint = `/v1/publisher/p:${userId}/profile`;

            const response = await get(endpoint, {});

            setProfile(response.data.data);
        } catch (error) {
            console.log(error);
        }
    }

    function calculateReferralActiveUntil(): string {
        const activeUntilDate = new Date(dateReferralCreated);
        activeUntilDate.setDate(activeUntilDate.getDate() + daysActive);
        const month = activeUntilDate.getMonth() + 1;
        const day = activeUntilDate.getDate();

        return `${month}/${day}`;
    }

    return (
        <Main to={`/public/${userId}`}>
            <ProfilePic_Username_Info>
                {/* Profile Photo */}
                <ProfilePhoto
                    profilePhotoUrl={profile?.profile.avatar || ""}
                    status={profile?.profile.status}
                    photoSize={46}
                />

                <Username_Info>
                    {/* Username */}
                    {profile && (
                        <Username>
                            {profile.profile.username || "No Username"}
                        </Username>
                    )}

                    {/* Info */}
                    <Info>
                        {percentEarnings}% earnings • Active until{" "}
                        {calculateReferralActiveUntil()}
                    </Info>
                </Username_Info>
            </ProfilePic_Username_Info>

            {/* Referral Bonus */}
            <ReferralBonus>${referralBonus.toFixed(2)}</ReferralBonus>
        </Main>
    );
};

export default ReferredUser;
