import { normalize } from "normalizr";
import { db, storage } from "../../firebase/FirebaseConfig";
import { QuerySnapshot } from "@firebase/firestore-types";
import {
    all,
    call,
    cancelled,
    put,
    select,
    take,
    takeEvery,
    takeLatest
} from "redux-saga/effects";
import { Action } from "src/types";
import { add, createEventChannel, set } from "../saga";
import { Message } from "../types/Message";
import { messageList } from "../schema";
import {
    MESSAGE_CHANNEL,
    postMessageSuccess,
    POST_MESSAGE,
    updateMessageChannel
} from "./actions";
import { Conversation } from "../types/Conversation";
import {
    selectChatProfileById,
    selectConversationWithId,
    selectGroupSettings,
    selectOwnChatProfile
} from "../selectors";
import { getAuthState } from "src/auth/selectors";
import { GroupUserSettings } from "../types/GroupUserSettings";
import { ConversationUnread } from "../types/ConversationUnread";
import { ChatProfile } from "../types/ChatProfile";
import { CLEAR_CONVERSATION_UNREAD_COUNT } from "../conversation/actions";
import { AssetImage } from "../types/AssetImage";

function* createMessageListChannel({ payload: { conversationId } }: Action) {
    try {
        const updateChannel = createEventChannel(
            "Message",
            ["conversationId", "==", `${conversationId}`],
            "created"
        );

        try {
            while (true) {
                const messages: Message[] = yield take(updateChannel);

                yield put(
                    updateMessageChannel(
                        normalize(messages, messageList),
                        conversationId
                    )
                );
            }
        } catch (error) {
            console.log({ error });
        } finally {
            if (yield cancelled()) {
                updateChannel.close();
            }
        }
    } catch (error) {
        console.log(error);
    }
}

function* postMessageRequest({ payload: { postMessage } }: Action) {
    try {
        const { messageText, conversationId, image } = postMessage;
        const conversation: Conversation = yield select(
            selectConversationWithId,
            conversationId
        );
        const chatProfile: ChatProfile | null = yield select(
            selectOwnChatProfile
        );
        const { userId } = yield select(getAuthState);
        const created = Date.now() / 1000;

        const message = {
            ...(image && { asset: image }),
            conversationId,
            messageText,
            participantIds: conversation.participantIds,
            authorId: `${userId}`,
            created
        };
        yield call(add, "Message", message);

        const lastMessage = image
            ? `${chatProfile?.username} sent an image`
            : messageText;

        const newConversation = {
            id: conversationId,
            lastMessage,
            lastMessageSentAt: created
        };
        yield call(set, "Conversation", newConversation);

        // update unreadCount for all participants
        yield call(updateUnreadCount, conversation);
        yield put(postMessageSuccess());
    } catch (error) {}
}

function* clearUnreadCount({ payload: { conversationId } }: Action) {
    try {
        const conversation: Conversation = yield select(
            selectConversationWithId,
            conversationId
        );

        const unreadCountId = conversation.unreadCount?.id;
        const unreadCount = conversation.unreadCount?.unreadCount;

        if (unreadCountId && unreadCount) {
            const newUnreadCount = {
                id: unreadCountId,
                unreadCount: 0
            };

            yield call(set, "ConversationUnreadCount", newUnreadCount);
        }
    } catch (error) {}
}

function* updateUnreadCount(conversation: Conversation) {
    try {
        const { userId } = yield select(getAuthState);
        const groupSettings: {
            [userId: string]: GroupUserSettings;
        } = yield select(selectGroupSettings, conversation.id);

        const collectionRef = db.collection("ConversationUnreadCount");

        const querySnapshot: QuerySnapshot = yield call([
            collectionRef.where("conversationId", "==", conversation.id),
            collectionRef.get
        ]);

        const unreadCounts: { [key: string]: ConversationUnread } = {};

        querySnapshot.forEach(doc => {
            const data = <ConversationUnread>doc.data();
            unreadCounts[data.publisherId] = { ...data, id: doc.id };
        });

        const shouldUpdate: Promise<any>[] = [];

        for (const participant of conversation.participantIds) {
            // is self
            if (userId == participant) continue;

            // no group settings
            const settings = groupSettings[participant];
            if (!settings) continue;

            // no chat profile
            const chatProfile: ChatProfile = yield select(
                selectChatProfileById,
                participant
            );
            const conversationSettings = chatProfile?.conversations.find(
                el => el.conversationId === conversation.id
            );
            if (!conversationSettings) continue;

            // notifications paused
            // const notificationsPausedUntil =
            //     conversationSettings.notificationsPausedUntil || 0;
            // if (conversationSettings.notificationsPausedIndefinitely) continue;
            // if (Date.now() < notificationsPausedUntil * 1000) continue;

            // skip if user active in chat
            if (conversationSettings.activeInChat) continue;

            // other user blocked you
            if (groupSettings[`${userId}`].blocked) continue;

            // no previous unread count
            const prevUnreadCount = unreadCounts[participant];
            if (!prevUnreadCount) continue;

            const newUnreadCount = {
                ...prevUnreadCount,
                unreadCount: prevUnreadCount.unreadCount + 1
            };

            shouldUpdate.push(set("ConversationUnreadCount", newUnreadCount));
        }
        yield all(shouldUpdate);
    } catch (error) {}
}

export default function* messageSaga() {
    yield all([
        takeLatest(MESSAGE_CHANNEL.CREATE, createMessageListChannel),
        takeEvery(POST_MESSAGE.REQUEST, postMessageRequest),
        takeEvery(CLEAR_CONVERSATION_UNREAD_COUNT, clearUnreadCount)
    ]);
}
