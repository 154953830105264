import { CustomElement, CustomElementTypes, CustomText } from "./types";
import escapeHtml from "escape-html";

interface NodeTypes {
    paragraph: "paragraph";
    block_quote: "block_quote";
    code_block: "code_block";
    link: "link";
    ul_list: "ul_list";
    ol_list: "ol_list";
    listItem: "list_item";
    heading: {
        1: "heading_one";
        2: "heading_two";
        3: "heading_three";
        4: "heading_four";
        5: "heading_five";
        6: "heading_six";
    };
    emphasis_mark: "italic";
    strong_mark: "bold";
    delete_mark: "strikeThrough";
    inline_code_mark: "code";
    thematic_break: "thematic_break";
    image: "image";
}

interface CustomNodeTypes extends NodeTypes {
    rule_item: "rule_item";
}

const defaultNodeTypes: CustomNodeTypes = {
    paragraph: "paragraph",
    block_quote: "block_quote",
    code_block: "code_block",
    link: "link",
    ul_list: "ul_list",
    ol_list: "ol_list",
    listItem: "list_item",
    heading: {
        1: "heading_one",
        2: "heading_two",
        3: "heading_three",
        4: "heading_four",
        5: "heading_five",
        6: "heading_six"
    },
    emphasis_mark: "italic",
    strong_mark: "bold",
    delete_mark: "strikeThrough",
    inline_code_mark: "code",
    thematic_break: "thematic_break",
    image: "image",
    rule_item: "rule_item"
};

const nodeTypes = defaultNodeTypes;
const LIST_TYPES = [nodeTypes.ul_list, nodeTypes.ol_list];
const VOID_ELEMENTS: Array<keyof NodeTypes> = ["thematic_break", "image"];
const BREAK_TAG = "<br>";

const isLeafNode = (node: CustomElement | CustomText): node is CustomText => {
    return typeof (node as CustomText).text === "string";
};

const isList = (node: CustomElement | CustomText): boolean => {
    return !isLeafNode(node)
        ? (LIST_TYPES as string[]).includes(node.type || "")
        : false;
};

const isSelfList = (type: CustomElementTypes): boolean =>
    (LIST_TYPES as string[]).includes(type || "");

const isChildrenHasLink = (chunk: CustomElement) => {
    if (!isLeafNode(chunk) && Array.isArray(chunk.children)) {
        return chunk.children.some(
            f => !isLeafNode(f) && f.type === nodeTypes.link
        );
    }
    return false;
};

const reverseStr = (string: string) =>
    string
        .split("")
        .reverse()
        .join("");

const retainWhitespaceAndFormat = (string: string, format: string) => {
    const frozenString = string.trim();
    let children = frozenString;

    const fullFormat = `${format}${children}${reverseStr(format)}`;

    if (children.length === string.length) {
        return fullFormat;
    }

    const formattedString = format + children + reverseStr(format);

    return string.replace(frozenString, formattedString);
};

interface Options {
    ignoreParagraphNewline?: boolean;
    isBreak?: boolean;
    listDepth?: number;
    parentType?: string;
}

const serialize = (
    chunk: CustomElement | CustomText,
    {
        ignoreParagraphNewline = false,
        isBreak = false,
        listDepth = 0,
        parentType = ""
    }: Options
) => {
    let text = (chunk as CustomText).text || "";
    let type = (chunk as CustomElement).type || "";

    let children: string = text;

    if (!isLeafNode(chunk)) {
        children = chunk.children
            .map(c => {
                const isListFlag = isList(c);
                const selfIsListFlag = isSelfList(type);
                const childrenHasLinkFlag = isChildrenHasLink(chunk);

                return serialize(
                    { ...c },
                    {
                        ignoreParagraphNewline:
                            (ignoreParagraphNewline ||
                                isListFlag ||
                                selfIsListFlag ||
                                childrenHasLinkFlag) &&
                            !isBreak,
                        isBreak,
                        listDepth: (LIST_TYPES as string[]).includes(
                            (c as CustomElement).type || ""
                        )
                            ? listDepth + 1
                            : listDepth,
                        parentType
                    }
                );
            })
            .join("");
    }
    if (
        !ignoreParagraphNewline &&
        (text === "" || text === "\n") &&
        parentType === nodeTypes.paragraph
    ) {
        type = nodeTypes.paragraph;
        children = BREAK_TAG;
    }

    if (children === "" && !VOID_ELEMENTS.find(k => nodeTypes[k] === type))
        return;

    if (children !== BREAK_TAG && isLeafNode(chunk)) {
        if (chunk.strikeThrough && chunk.bold && chunk.italic) {
            children = retainWhitespaceAndFormat(children, "~~***");
        } else if (chunk.bold && chunk.italic) {
            children = retainWhitespaceAndFormat(children, "***");
        } else {
            if (chunk.bold) {
                children = retainWhitespaceAndFormat(children, "**");
            }

            if (chunk.italic) {
                children = retainWhitespaceAndFormat(children, "_");
            }

            if (chunk.strikeThrough) {
                children = retainWhitespaceAndFormat(children, "~~");
            }

            if (chunk.code) {
                children = retainWhitespaceAndFormat(children, "`");
            }
        }
    }

    switch (type) {
        // these are the only node types we currently use, including the custom ones.
        case nodeTypes.rule_item:
            return `${children}\n`;

        case nodeTypes.link:
            return `[${children}](${((chunk as unknown) as CustomElement)
                .link || ""})`;

        case nodeTypes.ul_list:
        case nodeTypes.ol_list:
            return `\n${children}\n`;

        case nodeTypes.listItem:
            const isOL = chunk && parentType === nodeTypes.ol_list;

            let spacer = "";
            for (let k = 0; listDepth > k; k++) {
                if (isOL) {
                    spacer += "   ";
                } else {
                    spacer += "  ";
                }
            }
            return `${spacer}${isOL ? "1." : "-"} ${children}`;

        case nodeTypes.paragraph:
            return `${children}\n`;

        default:
            return escapeHtml(children);
    }
};

export default serialize;
