import { useState } from "react";
import { ColumnsReference } from "../PlugTable";
import { TableRow } from "../types";

export interface SearchReference {
    searchString: string;
    setSearchString: React.Dispatch<React.SetStateAction<string>>;

    searchedData: TableRow[];
    isSearchable: boolean;
}

const useSearch = (
    data: TableRow[],
    columnsReference: ColumnsReference
): SearchReference => {
    const [searchString, setSearchString] = useState("");
    const searchableColumns = Object.entries(columnsReference)
        .filter(([coreKey, column]) => column.searchable)
        .map(([coreKey]) => coreKey);

    const searchData = () => {
        if (searchString.length <= 0) return data;

        let result: TableRow[] = [];
        rowloop: for (const row of data) {
            const searchableElements = Object.entries(row.rowData)
                .filter(([coreKey, data]) =>
                    searchableColumns.includes(coreKey)
                )
                .map(([coreKey, data]) => data?.data);
            for (const val of searchableElements) {
                if (typeof val !== "number" && typeof val !== "string")
                    continue;
                if (val.toString() === searchString) {
                    // exact matches at start of array
                    result.unshift(row);
                    continue rowloop;
                } else if (
                    val
                        .toString()
                        .toLowerCase()
                        .includes(searchString.toLowerCase())
                ) {
                    result.push(row);
                    continue rowloop;
                }
            }
        }

        return result;
    };
    const searchedData = searchData();

    return {
        searchString,
        setSearchString,
        searchedData,
        isSearchable: searchableColumns.length > 0
    };
};

export default useSearch;
