import React, { useState } from "react";
import SimpleNavbar from "../../../components/SimpleNavbar";
import NavButton from "../../../components/NavButton";
import { Channel } from "../../types";
import { Connection } from "../../../profile/types";
import SelectConnectionsScreen from "../../components/SelectConnectionsScreen";

interface Props {
    channel: Channel;
    closeCallback: VoidFunction;
}

const AddAdmins = ({ channel, closeCallback }: Props) => {
    const [selectedConnections, setSelectedConnections] = useState<
        Connection[]
    >([]);

    return (
        <>
            <SimpleNavbar
                title="Add Admins"
                left={<NavButton text="Cancel" onClick={closeCallback} />}
                right={
                    <NavButton
                        text="Add"
                        disabled={selectedConnections.length === 0}
                        onClick={() => {
                            if (channel) {
                                channel.sendBirdData.addOperators(
                                    selectedConnections.map(connection => {
                                        return connection.other.id;
                                    }),
                                    (_: any, error: any) => {
                                        if (error) {
                                            window.alert(error);
                                        } else {
                                            closeCallback();
                                        }
                                    }
                                );
                            }
                        }}
                    />
                }
            />
            <SelectConnectionsScreen
                connections={channel.connections.filter(connection => {
                    return channel.sendBirdData.members.some(
                        (sendbirdMember: any) => {
                            return (
                                connection.other.id === sendbirdMember.userId &&
                                sendbirdMember.role !== "operator"
                            );
                        }
                    );
                })}
                selectedConnections={selectedConnections}
                setSelectedConnections={setSelectedConnections}
            />
        </>
    );
};

export default AddAdmins;
