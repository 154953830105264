import React from "react";
import withAccordion from "src/campaigns/details/accordions/withAccordion";
import styled from "styled-components";

const SubmissionStep = styled.div<{ disabled: boolean }>`
    display: flex;
    flex-direction: column;
    opacity: ${props => (props.disabled ? ".5" : "1")};
    pointer-events: ${props => props.disabled && "none"};
    padding: 0px 12px 12px 12px;
    gap: 12px;
`;

const OpenBuySubmissionStepAccordion = ({
    disabled,
    children,
    style
}: {
    disabled: boolean;
    children: any;
    style?: any;
}) => {
    return (
        <SubmissionStep style={style} disabled={disabled}>
            {children}
        </SubmissionStep>
    );
};

export default withAccordion(OpenBuySubmissionStepAccordion);
