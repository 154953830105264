import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";
import { DropdownTitle, InstructionElementType } from "../utils/types";
import { InstructionsSet } from "./types";

// Instruction Sets -----------------------------------------------

export const getInstructionsSets = createSelector(
    (state: GlobalState) => state.entities.instructionsSets.byId,
    (state: GlobalState) => state.entities.instructionsSets.allIds,
    (instructionsSetsState, instructionsSetsIds) => {
        const instructionsSets = instructionsSetsIds.map((id: number) => {
            return instructionsSetsState[id];
        });
        return instructionsSets;
    }
);

export const selectInstructionsSet = (
    state: GlobalState,
    id: number | null
) => {
    if (!id) return undefined;

    return state.entities.instructionsSets.byId[id];
};

export const getSearchFilteredInstructionsSets = createSelector(
    (state: GlobalState) => state.entities.instructionsSets.byId,
    (state: GlobalState) => state.entities.instructionsSets.allIds,
    (state: GlobalState, searchString: string) => searchString,
    (instructionsSetsState, instructionsSetsIds, searchString) => {
        const instructionsSets = instructionsSetsIds.map((id: number) => {
            return instructionsSetsState[id];
        });

        const searchFilteredInstructionsSets = instructionsSets.filter(
            (instructionsSet: InstructionsSet) => {
                if (!instructionsSet.name) {
                    return true;
                } else {
                    return instructionsSet.name
                        .toLowerCase()
                        .includes(searchString.toLowerCase());
                }
            }
        );

        return searchFilteredInstructionsSets;
    }
);

export const getCurrentInstructionsSet = createSelector(
    (state: GlobalState) => state.entities.instructionsSets.byId,
    (state: GlobalState) => state.ui.instructionsSets.currentInstructionsSetId,
    (instructionsSetsState, currentInstructionsSetId) => {
        if (currentInstructionsSetId) {
            return instructionsSetsState[currentInstructionsSetId];
        }
    }
);

export function getCurrentInstructionElement(state: GlobalState) {
    return state.ui.instructionsSets.currentInstructionElement;
}

// Instruction Steps -----------------------------------------------

export const getAllInstructionSteps = createSelector(
    (state: GlobalState) => state.entities.instructionsSetSteps.byId,
    (state: GlobalState) => state.entities.instructionsSetSteps.allIds,
    (instructionsStepsState, instructionsStepsIds) => {
        const instructionSteps = instructionsStepsIds.map((id: number) => {
            return instructionsStepsState[id];
        });
        return instructionSteps;
    }
);

export const getCurrentInstructionSetSteps = createSelector(
    getAllInstructionSteps,
    (state: GlobalState) => state.ui.instructionsSets.currentInstructionsSetId,
    (allStepsState, currentInstructionSetId) => {
        return allStepsState.filter(
            step => step.instructionSetId === currentInstructionSetId
        );
    }
);

export const getCurrentInstructionStep = createSelector(
    (state: GlobalState) => state.entities.instructionsSetSteps.byId,
    (state: GlobalState) => state.ui.instructionsSets.currentInstructionStepId,
    (instructionStepsState, currentInstructionStepId) => {
        if (currentInstructionStepId) {
            return instructionStepsState[currentInstructionStepId];
        }
    }
);

// Create Instruction Set -----------------------------------------------

export const loadingSelector = createSelector(
    (state: GlobalState, title: DropdownTitle) => title,
    (state: GlobalState) => state.ui,
    (title, uiState) => {
        if (title === "Scripts") {
            return uiState.campaign.scriptsLoading;
        } else if (title === "Media") {
            return uiState.campaign.mediasLoading;
        } else if (title === "Assets") {
            return uiState.campaign.assetsLoading;
        } else if (title === "Links") {
            return false;
        } else if (title === "Upload Submissions") {
            return false;
        }

        return true;
    }
);
