import { all, call, put, takeEvery } from "redux-saga/effects";
import { normalize } from "normalizr";

import { deleteReq, get, post } from "src/Api";

import {
    fetchLinkListSuccess,
    fetchLinkListFailure,
    editLinkListSuccess,
    editLinkListItemSuccess,
    deleteLinkListSuccess,
    deleteLinkListItemSuccess,
    DELETE_LINK_LIST,
    DELETE_LINK_LIST_ITEM,
    EDIT_LINK_LIST,
    EDIT_LINK_LIST_ITEM,
    FETCH_LINK_LIST
} from "./actions";
import { linkListList } from "./schema";
import { LinkList } from "../types";
import { Action } from "src/types";
import { go, goBack, push } from "connected-react-router";
import axios, { AxiosResponse, isAxiosError } from "axios";
import { addNotification } from "src/ui/notifications/actions";
import { errorToString } from "src/util";
import { setLinkListError } from "src/ui/link-lists/actions";

export function* fetchLinkListRequest() {
    try {
        const { data } = yield call(get, "/v1/linkList", {});

        const normalizedLinkList = normalize<LinkList>(data.data, linkListList);
        yield put(fetchLinkListSuccess(normalizedLinkList));
    } catch (error) {
        if (isAxiosError(error))
            yield put(fetchLinkListFailure(error.toString()));
    }
}

function* editLinkListRequest({ payload }: Action) {
    const { name, value, id, initialPhoto } = payload;
    try {
        const { data } = yield call(
            post,
            id ? `/v1/linklist/${id}` : "/v1/linklist",
            {
                [name]: value,
                ...(payload.initialPhoto && { photoUrl: initialPhoto })
            }
        );

        if (!id) {
            yield put(push(`/profile/linklist/${data.data.id}`));
            yield put(addNotification("Link list created!"));
        } else yield put(addNotification("Link list updated!"));
        yield put(editLinkListSuccess(data.data));
    } catch (error) {
        yield put(addNotification("Error saving link list!", "red"));
        if (axios.isAxiosError(error) && error.response) {
            yield put(
                setLinkListError(
                    errorToString(error),
                    !id ? "LinkListCreateModal" : "LinkListEdit"
                )
            );
        }
    }
}

function* editLinkListItemRequest({ payload }: Action) {
    try {
        let res: AxiosResponse | undefined = undefined;
        if (payload.position) {
            res = yield call(post, `/v1/linkItem/position`, {
                ...payload,
                photoUrl: payload.photoUrl || undefined
            });
        } else {
            res = yield call(
                post,
                payload.id ? `/v1/linkItem/${payload.id}` : `/v1/linkItem`,
                {
                    ...payload,
                    url: payload.url && encodeURI(payload.url)
                }
            );
        }

        if (payload.photoUrl !== undefined) {
            yield put(
                addNotification(
                    payload.id ? "Link updated successfully!" : "Link Created!"
                )
            );
            yield put(goBack());
        }
        yield put(
            editLinkListItemSuccess(
                payload.position ? res?.data.data[0] : res?.data.data
            )
        );
    } catch (error) {
        yield put(addNotification("Error saving link!", "red"));
        if (axios.isAxiosError(error) && error.response) {
            yield put(
                setLinkListError(
                    errorToString(error),
                    payload.position ? "LinkListEdit" : "LinkDetails"
                )
            );
        }
    }
}

function* deleteLinkListRequest({ payload }: Action) {
    try {
        yield call(deleteReq, `/v1/linklist/${payload}`, {});

        yield put(addNotification("Link list deleted!"));

        yield put(push(`/profile/linklists`));
        yield put(deleteLinkListSuccess(payload));
    } catch (error) {
        yield put(addNotification("Error deleting link list!", "red"));
        if (axios.isAxiosError(error) && error.response) {
            yield put(setLinkListError(errorToString(error), "LinkListEdit"));
        }
    }
}

function* deleteLinkListItemRequest({ payload }: Action) {
    try {
        yield call(deleteReq, `/v1/linkItem/${payload.id}`, {});

        yield put(addNotification("Link deleted!"));

        yield put(goBack());
        yield put(deleteLinkListItemSuccess(payload.id, payload.listId));
    } catch (error) {
        yield put(addNotification("Error deleting link!", "red"));
        if (axios.isAxiosError(error) && error.response) {
            yield put(setLinkListError(errorToString(error), "LinkDetails"));
        }
    }
}

export default function* linkListSaga() {
    yield all([
        takeEvery(FETCH_LINK_LIST.REQUEST, fetchLinkListRequest),
        takeEvery(EDIT_LINK_LIST.REQUEST, editLinkListRequest),
        takeEvery(EDIT_LINK_LIST_ITEM.REQUEST, editLinkListItemRequest),
        takeEvery(DELETE_LINK_LIST.REQUEST, deleteLinkListRequest),
        takeEvery(DELETE_LINK_LIST_ITEM.REQUEST, deleteLinkListItemRequest)
    ]);
}
