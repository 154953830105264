import { combineReducers } from "redux";
import { NormalizedAction } from "../../types";
import { PublisherOffer } from "../types";
import {
    DELETE_PUBLISHER_OFFER,
    FETCH_PUBLISHER_OFFERS,
    PATCH_PUBLISHER_OFFER,
    POST_PUBLISHER_OFFER
} from "./actions";

interface ById {
    [id: number]: PublisherOffer;
}

export interface PublisherOffersEntitiesState {
    byId: ById;
    allIds: number[];
}

const initialPublisherOffersState: PublisherOffersEntitiesState = {
    byId: {},
    allIds: []
};

const byId = (
    state = initialPublisherOffersState.byId,
    action: NormalizedAction
) => {
    switch (action.type) {
        case POST_PUBLISHER_OFFER.SUCCESS:
        case FETCH_PUBLISHER_OFFERS.SUCCESS:
        case PATCH_PUBLISHER_OFFER.SUCCESS:
            return { ...state, ...action.response.entities.publisherOffers };
        default:
            return state;
    }
};

const allIds = (
    state = initialPublisherOffersState.allIds,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_PUBLISHER_OFFERS.SUCCESS:
            return action.response.result;
        case POST_PUBLISHER_OFFER.SUCCESS:
            return [...state, ...action.response.result];
        case DELETE_PUBLISHER_OFFER.SUCCESS:
            return state.filter(id => id !== action.payload.deleteId);
        case PATCH_PUBLISHER_OFFER.SUCCESS:
            const newState = [...state, ...action.response.result];
            return newState.filter(id => id !== action.payload.deleteId);
        default:
            return state;
    }
};

const publisherOffersEntitiesReducer = combineReducers<
    PublisherOffersEntitiesState
>({
    byId,
    allIds
});

export default publisherOffersEntitiesReducer;
