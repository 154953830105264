import React, { useEffect, useState } from "react";
import { CreatorGroupPublisher } from "../types";
import { get } from "../../../Api";
import styled from "styled-components";
import { colors, fonts, fontWeight, sizes } from "../../../constants";
import HideOverflow from "../../../components/HideOverflow";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    border: 1px solid ${colors.mediumTeal};
    width: 100%;
    height: ${sizes.marketplace.paymentSets.inputContainer.height}px;
    padding: 16px;
    background-color: ${colors.white};
    color: ${colors.black};
    font-weight: ${fontWeight.default};
    font-size: ${fonts.smallTitle}px;
    cursor: pointer;
`;

interface Props {
    creatorGroupId: number;
    setCurrentCreatorGroupId: (creatorGroupId: number) => void;
}

const PaymentCreatorGroupPublishers = ({
    creatorGroupId,
    setCurrentCreatorGroupId
}: Props) => {
    const [publishers, setPublishers] = useState<CreatorGroupPublisher[]>([]);

    useEffect(() => {
        get(`/v1/creatorGroup/${creatorGroupId}/uniquePublishers`, {}).then(
            ({ data }) => {
                setPublishers(data.data);
            }
        );
    }, [creatorGroupId]);

    if (publishers.length === 0) {
        return null;
    }

    return (
        <Container onClick={() => setCurrentCreatorGroupId(creatorGroupId)}>
            <HideOverflow>
                {publishers.length === 1
                    ? "1 Creator: "
                    : `${publishers.length} Creators: `}
                {publishers
                    .map(publisher => publisher.publisherName)
                    .join(", ")}
            </HideOverflow>
        </Container>
    );
};

export default PaymentCreatorGroupPublishers;
