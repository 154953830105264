import React from "react";

import { Props } from "src/components/icons/types";

const Close = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || "10"}
            viewBox="0 0 10 10"
            fill="none"
        >
            <g clipPath="url(#A)">
                <path
                    d="M6.175 4.999l3.58-3.572c.157-.157.245-.369.245-.591S9.912.402 9.755.245 9.386 0 9.164 0s-.434.088-.591.245l-3.572 3.58L1.429.245C1.272.088 1.059 0 .837 0S.403.088.246.245.001.614.001.836s.088.434.245.591l3.58 3.572-3.58 3.572c-.078.077-.14.169-.182.271S0 9.053 0 9.163s.022.219.064.32.104.194.182.271a.83.83 0 0 0 .271.182c.101.042.21.064.32.064a.83.83 0 0 0 .591-.246l3.572-3.58 3.572 3.58a.83.83 0 0 0 .271.182c.101.042.21.064.32.064a.83.83 0 0 0 .591-.246c.078-.077.14-.169.182-.271s.064-.21.064-.32-.022-.219-.064-.32-.104-.194-.182-.271l-3.58-3.572z"
                    fill={props.fill || "#000"}
                />
            </g>
            <defs>
                <clipPath id="A">
                    <path fill="#fff" d="M0 0h10v10H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Close;
