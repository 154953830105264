import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import styled from "styled-components";
import Modal from "../modals/Modal";
import UpdateModal from "../modals/UpdateModal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { emailReportParameters, sendEmailReport } from "./actions";
import ModalButton from "../modals/ModalButton";
import PulseLoader from "../components/PulseLoader";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 300;
`;

const Loading = styled.div`
    padding: 6px 0 2px;
`;

const Error = styled.div`
    color: red;
    font-size: ${fonts.subtitle}px;
    margin-top: 15px;
`;

interface Props {
    defaultEmail: string;
    emailDateRange: [string, string] | null;
    emailReportStatus: boolean | null;
    sendEmailReport: (payload: emailReportParameters) => void;
}

const EmailReportModal = ({
    defaultEmail,
    emailDateRange,
    emailReportStatus,
    sendEmailReport
}: Props) => {
    const [email, setEmail] = useState(defaultEmail);

    function isValidEmail() {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (emailReportStatus !== null) {
            setLoading(false);
        }
    }, [emailReportStatus]);

    function submitReport() {
        if (emailDateRange) {
            sendEmailReport({
                email,
                startDate: emailDateRange[0],
                endDate: emailDateRange[1]
            });
        } else {
            sendEmailReport({ email });
        }
        setLoading(true);
    }

    if (emailReportStatus === true) {
        return (
            <Modal>
                <Main>
                    <Title>Success!</Title>
                    <Subtitle>An earnings report was sent to {email}</Subtitle>
                </Main>
                <ModalButton />
            </Modal>
        );
    } else {
        return (
            <Modal>
                <Main>
                    <Title>Download Report</Title>
                    <Subtitle style={{ marginBottom: "20px" }}>
                        A CSV will be sent to the following email:
                    </Subtitle>
                    {loading ? (
                        <Loading>
                            <PulseLoader size={20} />
                        </Loading>
                    ) : (
                        <InputGroup>
                            <FormControl
                                placeholder={"Email"}
                                value={email}
                                onChange={({ currentTarget }: any) =>
                                    setEmail(currentTarget.value)
                                }
                            />
                        </InputGroup>
                    )}
                    {emailReportStatus === false ? (
                        <Error>Something went wrong, please try again</Error>
                    ) : null}
                </Main>
                <UpdateModal
                    onUpdate={submitReport}
                    updateText={"Send"}
                    active={isValidEmail()}
                />
            </Modal>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    defaultEmail: state.profile.profile
        ? state.profile.profile.email || ""
        : "",
    emailDateRange: state.analytics.emailDateRange,
    emailReportStatus: state.analytics.emailReportStatus
});

const mapDispatchToProps = (dispatch: any) => ({
    sendEmailReport: (payload: emailReportParameters) =>
        dispatch(sendEmailReport(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailReportModal);
