import styled from "styled-components";
import { BtnWrapper } from "./Btn";
import { colors } from "src/constants";
import Tooltip from "src/components/Tooltip";
import { ReactNode } from "react";
import { useSlate } from "slate-react";
import React, { useRef, MutableRefObject, useState } from "react";
import CustomEditor from "../CustomEditor";
import LinkForm from "../components/LinkForm";
import { ReactComponent as LinkIcon } from "../../icons/link-icon.svg";

const Wrapper = styled.div`
    position: relative;
`;
const DropdownBtnWrapper = styled(BtnWrapper)`
    /* width: 44px; */
    & > svg > path {
        fill: ${props =>
            props.isLinkActive ? `${colors.primaryBlue}` : `${colors.black}`};
    }
`;
const StyledTooltip = styled(Tooltip)`
    border-radius: 8px;
    overflow: hidden;
`;
interface DropdownProps {
    DropdownChild: React.ReactElement;
    position: string;
    handleTooltipOpen: (e: React.MouseEvent) => void;
    tooltipOpen: boolean;
    handleClose: () => void;
    children: ReactNode;
}

export const DropdownBtn = ({
    children,
    DropdownChild,
    position,
    handleTooltipOpen,
    tooltipOpen,
    handleClose
}: DropdownProps) => {
    const ref = useRef() as MutableRefObject<HTMLButtonElement>;
    const editor = useSlate();
    return (
        <Wrapper>
            <DropdownBtnWrapper
                type="button"
                ref={ref}
                onClick={handleTooltipOpen}
                isActive={tooltipOpen}
                isLinkActive={CustomEditor.isLinkActive(editor)}
            >
                {children}
            </DropdownBtnWrapper>
            <StyledTooltip
                position={position}
                close={handleClose}
                controlRef={ref}
                isOpen={tooltipOpen}
            >
                {DropdownChild}
            </StyledTooltip>
        </Wrapper>
    );
};
export const LinkMark = () => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const handleTooltipOpen = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setTooltipOpen(() => !tooltipOpen);
    };
    const handleClose = () => {
        setTooltipOpen(false);
    };

    return (
        <DropdownBtn
            position="top: 44px; left: 0px;"
            DropdownChild={<LinkForm closeTooltip={handleClose} />}
            handleClose={handleClose}
            handleTooltipOpen={handleTooltipOpen}
            tooltipOpen={tooltipOpen}
        >
            <LinkIcon />
        </DropdownBtn>
    );
};
