import React from "react";
import { useDispatch } from "react-redux";
import { Campaign, CountryPlatformReward } from "src/campaigns/types";
import { breakpoints, colors, gradients } from "src/constants";
import { setBidNotifications, setOpenBidNotificationModal } from "src/ui/bid-budget/actions";
import styled, { css } from "styled-components";

const IncreaseTag = styled("div")`
    display: flex;
    align-items: center;
    padding: 6px;
    min-width: 127px;
    height: 22px;
    flex-direction: row;
    background: ${gradients.bidIncrease};
    border-radius: 12px;
    color: ${colors.white};
    font-weight: 600;
    font-size: 11px;
    width: fit-content;
    margin-top: 10px;
`;

interface Props {
    increase?: number | null;
    prevReward?: number | null;
    campaign?: Campaign;
}

const BidIncreaseTag = ({ increase, prevReward, campaign }: Props) => {
    const dispatch = useDispatch();
    let platform = campaign?.countryPlatformReward;
    if (!increase || !prevReward || increase - prevReward < 1) return null;
    return (
        <IncreaseTag
            onClick={(e?: React.MouseEvent<HTMLElement>) => {
                e?.stopPropagation();
                platform &&
                    campaign &&
                    dispatch(
                        setBidNotifications([
                            {
                                uid: "unused",
                                price: platform.reward,
                                oldPrice: platform.previous_reward || 0,
                                campaignId: campaign.id,
                                expiresAt: platform.boost_expires_at || ""
                            }
                        ])
                    );
                dispatch(setOpenBidNotificationModal(true));
            }}
        >
            <img
                style={{ marginRight: 4 }}
                src="/campaigns/increase-arrow.svg"
            />
            {(((increase - prevReward) / prevReward) * 100).toFixed(2)}% Bid
            Increase!
        </IncreaseTag>
    );
};

export default BidIncreaseTag;
