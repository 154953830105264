import React, { useState } from "react";
import Platforms from "./Platforms";
import { OldSocialAccount } from "./SocialAccount";
import Themes from "./Themes";
import Container from "react-bootstrap/Container";
import AccountFormGroup from "./AccountFormGroup";
import TagPicker from "./TagPicker";
import FlexColumn from "../components/FlexColumn";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { addNewAccount } from "./actions";
import { push } from "connected-react-router";
import SimpleNavbar from "../components/SimpleNavbar";
import NavButton from "../components/NavButton";
import MainContainer from "src/components/MainContainer";

interface Props {
    currentAccount: number;
    userAccounts: Array<OldSocialAccount>;
    addNewAccount: (account: OldSocialAccount) => void;
    push: (route: string) => void;
}

const AddAccount = ({
    currentAccount,
    userAccounts,
    addNewAccount,
    push
}: Props) => {
    const addingNew = currentAccount === -1;

    const id: number | undefined = addingNew
        ? undefined
        : userAccounts[currentAccount].id;

    const initPlatform: string = addingNew
        ? ""
        : userAccounts[currentAccount].platform;
    const [selectedPlatform, setSelectedPlatform] = useState<string>(
        initPlatform
    );

    const initTheme: string | null = addingNew
        ? null
        : userAccounts[currentAccount].theme;
    const [selectedTheme, setSelectedTheme] = useState<string | null>(
        initTheme
    );

    const initUsername: string = addingNew
        ? ""
        : userAccounts[currentAccount].username;
    const [currentUsername, setCurrentUsername] = useState<string>(
        initUsername
    );

    const initPopularityCount: string = addingNew
        ? ""
        : userAccounts[currentAccount].num_followers;
    const [currentPopularityCount, setCurrentPopularityCount] = useState<
        string
    >(initPopularityCount);

    function handleSelectPlatform(tag: string) {
        setSelectedPlatform(tag);
    }

    function handleSelectTheme(tag: string) {
        setSelectedTheme(tag);
    }

    function handleBackClicked() {
        push("/social-accounts");
    }

    function handleAddClicked() {
        const newAccount = {
            id,
            username: currentUsername,
            num_followers: currentPopularityCount,
            platform: selectedPlatform,
            theme: selectedTheme,
            verified: false
        };

        addNewAccount(newAccount);

        push("/social-accounts");
    }

    const formFilled = (): boolean =>
        currentUsername.length > 0 &&
        currentPopularityCount.length > 0 &&
        selectedPlatform.length > 0;

    const leftNavButton = (
        <NavButton onClick={handleBackClicked} text={"Back"} />
    );
    const rightNavButton = (
        <NavButton
            onClick={handleAddClicked}
            text={"Add"}
            right
            disabled={formFilled() ? false : true}
        />
    );

    return (
        <>
            <SimpleNavbar left={leftNavButton} right={rightNavButton} />

            <MainContainer>
                <FlexColumn>
                    <Container style={{ padding: 0 }}>
                        <AccountFormGroup
                            platform={selectedPlatform}
                            handle={currentUsername}
                            onHandleChanged={setCurrentUsername}
                            popularityCount={currentPopularityCount}
                            onPopularityCountChanged={setCurrentPopularityCount}
                        />
                        <TagPicker
                            title="Platforms"
                            categoryObj={Platforms}
                            currentTag={selectedPlatform}
                            onSelectTag={handleSelectPlatform}
                            tags={Object.keys(Platforms)}
                        />
                        <TagPicker
                            title="Theme"
                            categoryObj={Themes}
                            tags={Object.keys(Themes)}
                            onSelectTag={handleSelectTheme}
                            currentTag={selectedTheme}
                        />
                    </Container>
                </FlexColumn>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    currentAccount: state.profile.socialAccountsIndex
});

const mapDispatchToProps = (dispatch: any) => ({
    addNewAccount: (account: OldSocialAccount) =>
        dispatch(addNewAccount(account)),
    push: (route: string) => dispatch(push(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAccount);
