import { Action, NormalizedActionGeneric, NormalizedResponse } from "src/types";
import { AssetImage } from "../types/AssetImage";
import { Message } from "../types/Message";

export const MESSAGE_CHANNEL = {
    UPDATE: "UPDATE_MESSAGE_CHANNEL",
    CREATE: "CREATE_MESSAGE_CHANNEL"
};

export function createMessageChannel(conversationId: string): Action {
    return {
        type: MESSAGE_CHANNEL.CREATE,
        payload: { conversationId }
    };
}

export function updateMessageChannel(
    response: NormalizedResponse<Message, string[]>,
    conversationId: string
): NormalizedActionGeneric<Message, string[]> {
    return {
        type: MESSAGE_CHANNEL.UPDATE,
        response,
        payload: { conversationId }
    };
}

export const POST_MESSAGE = {
    REQUEST: "POST_MESSAGE_REQUEST",
    SUCCESS: "POST_MESSAGE_SUCCESS",
    FAILURE: "POST_MESSAGE_FAILURE"
};

export interface PostMessageParams {
    conversationId: string;
    messageText: string;
    image?: AssetImage;
}

export function postMessage(postMessage: PostMessageParams): Action {
    return {
        type: POST_MESSAGE.REQUEST,
        payload: { postMessage }
    };
}

export function postMessageSuccess(): Action {
    return {
        type: POST_MESSAGE.SUCCESS,
        payload: null
    };
}
