import { Media } from "../../../media/types";
import { Asset, Caption, Placement, Script } from "../../../campaigns/types";
import { SocialAccount } from "../../../profile/types";
import { ApprovalStatus } from "../../../types/ApprovalStatus";
import { PaymentConjunction } from "../../../types/PaymentConjunction";
import { Transaction } from "../../../types/Transaction";
import { DirectBuyPayment } from "../direct-buy-payment/types";
import { OpenBuySupplement } from "../open-buys/types";
import { InstructionPayment } from "src/marketplace/instructions-sets/types";

export interface DirectBuyStep {
    id: number;
    description: string | null;
    position: number;
    directBuyId: number | null;
    directBuyStepId: number | null;
    mediaId: number | null;
    media: Media | null;
    socialAccountId: number | null;
    socialAccount: SocialAccount | null;
    placementId: number | null;
    placement: Placement | null;
    scriptId: number | null;
    script: Script | null;
    captionId: number | null;
    caption: Caption | null;
    assetId: number | null;
    asset: Asset | null;
    requiresCustomMedia: boolean;
    requiresRawMedia: boolean;
    customMediaId: number | null;
    customMedia: Media | null;
    linkUrl: string | null;
    linkAction: BuyLinkAction;
    duration: number | null;
    startsAt: string | null;
    expiresAt: string | null;
    permanent: boolean;
    viewsRequested: string | null;
    viewsActual: string | null;
    requiresScreenshot: boolean;
    screenshotUrl: string | null;
    requiresLinkSubmission: boolean;
    linkSubmission: string | null;
    requiresTextSubmission: boolean;
    textSubmission: string | null;
    textSubmissionPlaceholder: string | null;
    completedAt: string | null;
    requiresBuyerApproval: boolean;
    status: ApprovalStatus;
    approvedAt: string | null;
    rejectionReason: string | null;
    paymentConjunction: PaymentConjunction;
    payoutId: number | null;
    payout: Transaction | null;
    directBuySteps?: number[];
    directBuyPayments?: DirectBuyPayment[];
    mediaHideDownload?: boolean;
    requiresPlugLink?: boolean;
}

export enum DirectBuyStepAction {
    customMedia = "customMedia",
    screenshot = "screenshot",
    linkSubmission = "linkSubmission",
    textSubmission = "textSubmission"
}

export interface DirectBuyStepCollection {
    indent: number;
    parent: number;
    children?: number[];
    combined: number[];
    supplements?: OpenBuySupplement[];
}

export enum DirectBuyStepCollectionTreatment {
    approved,
    rejected,
    active,
    inactive
}

export enum BuyLinkAction {
    copy = "copy",
    open = "open"
}
