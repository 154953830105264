import React from "react";
import AuthInputScreen from "./AuthInputScreen";
// @ts-ignore
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import "./customreactphoneinput.css";
import { getItem } from "src/util";
import styled from "styled-components";
import { WhitePillButton } from "src/components/buttons/PillButton";
import { colors } from "src/constants";

const NextButton = styled(WhitePillButton)`
    width: 100%;
`;

const Header = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
`;

const LockText = styled.div`
    display: flex;
    align-items: center;
    margin-top: 18px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
`;

const Lock = styled.img`
    margin-right: 8px;
    width: 16px;
`;

const Extra = styled.div`
    background-color: ${colors.primaryBlueLighter};
    padding: 12px;
    color: ${colors.white};
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 24px;
`;

interface Props {
    phoneNumber: string;
    setPhoneNumber: (phoneNumber: string) => void;
    nextClicked: () => void;
}

const PhoneNumberScreen = ({
    phoneNumber,
    setPhoneNumber,
    nextClicked
}: Props) => {
    function isPhoneValid(): boolean {
        const regex = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
        return regex.test(phoneNumber);
    }

    const isDev = getItem("devAccount");

    return (
        <>
            {isDev && <p>Hi, dev. Here's your number: {isDev.devNumber}</p>}
            <AuthInputScreen
                description={
                    <Header>
                        What’s your phone number?
                        <LockText>
                            <Lock src="/lock.svg" />
                            We’ll never share this with 3rd parties.
                        </LockText>
                    </Header>
                }
                input={
                    <ReactPhoneInput
                        country="us"
                        disableAreaCodes={true}
                        value={phoneNumber}
                        onChange={(value: string) => setPhoneNumber(value)}
                        inputClass={"react-phone-input"}
                        buttonClass={"react-phone-button"}
                        dropdownClass={"react-phone-dropdown"}
                    />
                }
                button={
                    <NextButton
                        disabled={!isPhoneValid()}
                        onClick={() => {
                            if (isPhoneValid()) {
                                nextClicked();
                            }
                        }}
                    >
                        Confirm Number
                    </NextButton>
                }
                extra={
                    <Extra>
                        This number will be used as your account log-in the
                        future.
                    </Extra>
                }
            />
        </>
    );
};

export default PhoneNumberScreen;
