import React from "react";
import styled from "styled-components";
import { padding, Subtitle, Title } from "../Details.styled";
import { InstallHistory } from "../../types";
import CampaignDetailsChartBody from "../components/CampaignDetailsChartBody";
import SpinLoader from "../../../components/SpinLoader";
import { LoaderContainer } from "../../../components/SkeletonLoader";

const ItemHeader = styled.div`
    ${padding}

    & > * ~ * {
        margin-top: 18px;
    }
`;

interface Props {
    installHistory?: InstallHistory[];
    countriesModal: (countries: string[]) => void;
}

const PaymentBreakdown = ({ installHistory, countriesModal }: Props) => {
    return (
        <ItemHeader>
            <div>
                <Title>
                    <div>Earnings Breakdown</div>
                </Title>
            </div>
            {installHistory ? (
                <CampaignDetailsChartBody
                    rewardData={installHistory}
                    countriesModal={countriesModal}
                />
            ) : (
                <LoaderContainer>
                    <SpinLoader />
                </LoaderContainer>
            )}
        </ItemHeader>
    );
};

export default PaymentBreakdown;
