import { all, call, put, takeEvery } from "redux-saga/effects";
import { get } from "src/Api";
import { Action } from "src/types";
import {
    PUBLIC_BUYS,
    SCREENSHOTS,
    setPublicBuys,
    setScreenshots
} from "./actions";

function* fetchPublicBuys({ payload: { search, filters } }: Action) {
    try {
        const { data } = yield get("/public/buys", {
            search,
            campaignIds: filters[0].values,
            mediaIds: filters[1].values,
            socialAccountIds: filters[2].values,
            showCompleted: !!filters[3].values[0]
        });
        yield put(setPublicBuys(data.data));
    } catch (error) {
        console.error(error);
    }
}

function* fetchPublicBuyScreenshots({ payload: { search, showUsed } }: Action) {
    try {
        const { data } = yield get("/public/buys/screenshots", {
            search,
            showUsed
        });
        yield put(setScreenshots(data.data));
    } catch (error) {
        console.error(error);
    }
}

export default function* PublicBuysSaga() {
    yield all([
        takeEvery(PUBLIC_BUYS.REQUEST, fetchPublicBuys),
        takeEvery(SCREENSHOTS.REQUEST, fetchPublicBuyScreenshots)
    ]);
}
