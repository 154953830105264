import React from "react";
import { Campaign, OldCampaign } from "src/campaigns/types";
import { breakpoints, colors, fonts } from "../constants";
import styled from "styled-components";
import VerticallyCentered from "./VerticallyCentered";
import { flex } from "src/utils/styles/snippets";

const Main = styled(VerticallyCentered)`
    ${flex("row", "auto", "center")};
    padding: 6px 10px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 6px;
    transition: 0.1s ease-in;
    cursor: pointer;

    & + & {
        margin-top: 15px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
            border: ${colors.darkTeal} solid 1px;
        }
    }

    @media (max-width: ${breakpoints.mobile}px) {
        padding: ${props => {
            return props.theme.isMobile ? "0px" : "6px 10px";
        }};
        border: ${props => {
            return props.theme.isMobile
                ? `${colors.transparent} solid 1px`
                : `${colors.mediumTeal} solid 1px`;
        }};
    }
`;

const AppIcon = styled.img`
    height: 46px;
    width: 46px;
    min-width: 46px;
    border-radius: 6px;
    margin-right: 10px;
`;

const AppInfo = styled.div`
    overflow: hidden;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const HideOverflow = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;
`;

const Title = styled(HideOverflow)`
    font-size: ${fonts.subtitle}px;
`;

const Subtitle = styled(HideOverflow)`
    font-size: 12px;
    color: ${colors.primaryGray};
    margin-right: 4px;
`;

const PlugLogo = styled.img`
    height: 16px;
    margin-right: 4px;
`;

const Checkbox = styled.img`
    padding-left: 10px;
    margin-left: auto;
`;

interface Props {
    campaign: any;
    oldCampaign?: true;
    selected: boolean;
    onClick: VoidFunction;
}

const SelectCampaignRow = ({
    campaign,
    oldCampaign,
    selected,
    onClick
}: Props) => {
    return (
        <Main onClick={onClick}>
            <AppIcon
                src={oldCampaign ? campaign.image_url : campaign.imageUrl}
            />
            <AppInfo>
                <Title>
                    <strong>
                        {oldCampaign
                            ? campaign.campaign_name
                            : campaign.campaignName}
                    </strong>
                </Title>
            </AppInfo>
            {selected ? (
                <Checkbox src={"/checkbox_selected.svg"} />
            ) : (
                <Checkbox src={"/checkbox_unselected.svg"} />
            )}
        </Main>
    );
};

export default SelectCampaignRow;
