import React, { useState } from "react";
import styled from "styled-components";
import { colors, fonts } from "src/constants";
import { useSelector } from "react-redux";
import { getPlacements } from "../placements/selectors";
import { Placement } from "../types";
import { GlobalState } from "src/reducers";
import PlacementsModal from "../placements/modals/PlacementsModal";
import { PlacementConfig } from "src/ui/placements/reducer";

const SubTitle = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 400;
    padding-left: 19px;
`;

const SelectButton = styled.div`
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    padding: 0px 16px 0px 16px;
    cursor: pointer;
    height: 45px;
    align-items: center;
    margin: 19px 12px 19px 12px;
`;

const SectionTitle = styled.div`
    font-size: 12px;
    font-weight: 700;
    color: ${colors.primaryGray};
    padding: 15px 15px 0px;
`;

const PlacementTitle = styled.div`
    font-size: 12px;
    margin-right: auto;
`;

const Logo = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 5px;
`;
const Caret = styled.img`
    width: 12px;
    margin-left: auto;
`;

interface Props {
    onSelect: (value: PlacementConfig) => void;
    selected: PlacementConfig;
}

const PromotePlacementSelector = ({ onSelect, selected }: Props) => {
    const [open, setOpen] = useState(false);
    const placements = useSelector<GlobalState, Placement[]>(getPlacements);

    return (
        <>
            <SectionTitle>PLACEMENT</SectionTitle>
            <SubTitle>Where will you be promoting this?</SubTitle>
            {Object.keys(selected).length > 0 ? (
                Object.keys(selected).map((option, index) => {
                    let placement = placements.find(a => a.type === option);
                    if (!placement) return null;
                    else
                        return (
                            <SelectButton
                                key={`option${index}`}
                                onClick={() => setOpen(true)}
                            >
                                <Logo src={placement.logo} />
                                <PlacementTitle>
                                    {placement.type}
                                </PlacementTitle>
                                <Caret src={"/caret.svg"} />
                            </SelectButton>
                        );
                })
            ) : (
                <SelectButton onClick={() => setOpen(true)}>
                    <Logo src="/add.svg" />
                    <PlacementTitle>Select your Placements</PlacementTitle>
                    <Caret src={"/caret.svg"} />
                </SelectButton>
            )}

            {open && (
                <PlacementsModal
                    localClearModal={() => setOpen(false)}
                    keepFrozenOnClose
                    localPlacementConfig={selected}
                    localSetPlacementConfig={onSelect}
                />
            )}
        </>
    );
};

export default PromotePlacementSelector;
