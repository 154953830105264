import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { clearModal } from "./actions";
import { colors } from "../constants";

const Button = styled.div<{ disabled?: boolean }>`
    padding: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    border-top: 1px solid ${colors.mediumTeal};
    color: ${props =>
        props.disabled ? colors.secondaryGray : colors.primaryBlue};
    ${props => !props.disabled && "cursor: pointer;"}
`;

interface Props {
    clearModal: () => void;
    onClick?: () => any;
    text?: string;
    disabled?: boolean;
}

const ModalButton = ({ onClick, clearModal, text, disabled }: Props) => {
    if (onClick) {
        return (
            <Button
                disabled={disabled}
                onClick={() => {
                    if (!disabled) {
                        onClick();
                    }
                }}
            >
                <div style={{ fontWeight: 300 }}>{text || "Ok"}</div>
            </Button>
        );
    } else {
        return (
            <Button onClick={clearModal}>
                <div style={{ fontWeight: 500 }}>{text || "Ok"}</div>
            </Button>
        );
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(null, mapDispatchToProps)(ModalButton);
