import React from "react";
import styled from "styled-components";
import VerticalDivider from "../../components/VerticalDivider";
import { colors } from "../../constants";

interface MainProps {
    mainHeight: number;
    borderRadius?: number;
    inCard?: true;
    noBorder?: true;
}

const Main = styled.div<MainProps>`
    height: ${props => props.mainHeight}px;
    min-height: ${props => props.mainHeight}px;
    width: 100%;
    display: flex;
    overflow: hidden;

    border: ${props => !props.noBorder && `1px solid ${colors.mediumTeal}`};
    border-bottom-left-radius: ${props =>
        props.borderRadius === 0 ? 0 : props.borderRadius || 10}px;
    border-bottom-right-radius: ${props =>
        props.borderRadius === 0 ? 0 : props.borderRadius || 10}px;
    ${props =>
        !props.inCard && `border-radius: ${props.borderRadius || 10}px;`};
`;

const Button = styled.a`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: ${colors.primaryGray};
    cursor: pointer;
    user-select: none;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.mediumTeal};
        }
    }
`;

interface IconProps {
    iconSize: number;
    disabled?: boolean;
}

const Icon = styled.img<IconProps>`
    height: ${props => props.iconSize}px;
    ${props => props.disabled && "opacity: 0.5;"}
`;

interface Props {
    linkCallback: VoidFunction;
    mediaId: number;
    mediaUrl?: string | null;
    downloadCallback?: VoidFunction;
    height?: number;
    iconSize?: number;
    inCard?: true;
    disabled?: boolean;
    buttonHideLink?: boolean;
    borderRadius?: number;
    noBorder?: true;
}

const MediaButtons = ({
    mediaId,
    mediaUrl,
    linkCallback,
    downloadCallback,
    height = 40,
    iconSize = 20,
    inCard,
    disabled,
    buttonHideLink,
    borderRadius,
    noBorder
}: Props) => {
    return (
        <Main
            mainHeight={height}
            inCard={inCard}
            borderRadius={borderRadius}
            noBorder={noBorder}
        >
            {!buttonHideLink && (
                <>
                    <Button
                        onClick={() => {
                            if (!disabled) {
                                linkCallback();
                            }
                        }}
                    >
                        <Icon
                            iconSize={iconSize}
                            disabled={disabled}
                            src="/gray_link.svg"
                        />
                    </Button>
                    <VerticalDivider />
                </>
            )}
            <Button
                target="_blank"
                download={`media-id-${mediaId}`}
                href={mediaUrl || undefined}
                onClick={e => {
                    if (disabled) {
                        e.preventDefault();
                    } else if (downloadCallback) {
                        e.preventDefault();
                        downloadCallback();
                    }
                }}
            >
                <Icon
                    iconSize={iconSize}
                    disabled={disabled}
                    src="/gray_download.svg"
                />
            </Button>
        </Main>
    );
};

export default MediaButtons;
