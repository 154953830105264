import React from "react";
import { colors, fonts } from "src/constants";
import styled from "styled-components";
import OpenBuyLeaderboardBadge from "./leaderboard/OpenBuyLeaderboardBadge";
import { ReactComponent as ExternalLink } from "src/components/icons/external-link.svg";
import { ReactComponent as Views } from "src/components/icons/metrics/views.svg";
import { ReactComponent as Likes } from "src/components/icons/metrics/likes.svg";
import { ReactComponent as Comments } from "src/components/icons/metrics/comments.svg";
import { BigBlueButton } from "src/profile/components/Buttons";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "src/components/CommonStyles";
import { SubmissionReport } from "src/marketplace/reports/types";
import { shortenNumber } from "src/util";

const MainWrapper = styled.div`
    width: 100%;
    max-width: 645px;
    padding-top: 24px;
    margin-bottom: 50px;

    & > * ~ * {
        margin-top: 12px;
    }
`;

const Main = styled.div`
    border-radius: 6px;
    overflow: hidden;
`;

const Block = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    padding: 8px 9px;
    font-size: ${fonts.subtitle}px;
    display: flex;
    justify-content: flex-start;
`;

const MetricList = styled(Block)`
    flex: 1;
    flex-wrap: wrap;
    gap: 8px 12px;
    align-items: flex-start;
`;

const MetricItem = styled.div`
    ${FlexVerticallyCentered}
`;

const MetricText = styled.div`
    margin-left: 6px;
    color: ${colors.white};
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
`;

const HorizontalDivider = styled.div`
    height: 1px;
`;

const VerticalDivider = styled.div`
    height: 100%;
    width: 1px;
`;

const StyledBadge = styled(OpenBuyLeaderboardBadge)`
    height: 30px;
    font-size: 16px;
    font-weight: 500;
`;

const Bottom = styled.div`
    display: flex;
`;

const Link = styled(ExternalLink)`
    width: 16px;
    height: 16px;
`;

const LinkWrapper = styled.div`
    ${FlexCentered};
    height: 34px;
    width: 32px;
    cursor: pointer;
`;

interface Props {
    report: SubmissionReport;
    handleCTA: () => void;
}

const OpenBuyLeaderboardCarouselFooter = ({ report, handleCTA }: Props) => {
    return (
        <MainWrapper onClick={e => e.stopPropagation()}>
            <Main>
                <Block>
                    <StyledBadge place={report.place || 1} fullBorder />
                </Block>
                <HorizontalDivider />
                <Bottom>
                    <MetricList>
                        <MetricItem>
                            <Views />
                            <MetricText>
                                {shortenNumber(report.viewsActual || 0)} views
                            </MetricText>
                        </MetricItem>
                        <MetricItem>
                            <Likes />
                            <MetricText>
                                {shortenNumber(report.likes || 0)} likes
                            </MetricText>
                        </MetricItem>
                        <MetricItem>
                            <Comments />
                            <MetricText>
                                {shortenNumber(report.totalComments || 0)}{" "}
                                comments
                            </MetricText>
                        </MetricItem>
                    </MetricList>
                    <VerticalDivider />
                    <Block>
                        <LinkWrapper
                            onClick={() =>
                                window.open(
                                    report.linkSubmission || "",
                                    "_blank",
                                    "noopener noreferrer"
                                )
                            }
                        >
                            <Link fill={colors.white} />
                        </LinkWrapper>
                    </Block>
                </Bottom>
            </Main>
            <BigBlueButton onClick={handleCTA}>Enter Challenge</BigBlueButton>
        </MainWrapper>
    );
};
export default OpenBuyLeaderboardCarouselFooter;
