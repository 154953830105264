import React from "react";
import { colors } from "src/constants";
import { CopyButton } from "src/profile/components/Buttons";
import { LinkList } from "src/profile/types";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { addNotification } from "src/ui/notifications/actions";
import { push } from "connected-react-router";

const Container = styled.div`
    border: 1px solid ${colors.mediumTeal};
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    height: 80px;
    padding: 0 12px;
    align-items: center;
`;

const Picture = styled.img`
    width: 56px;
    height: 56px;
    border-radius: 100px;
    margin-right: 10px;
    object-fit: cover;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;

const Name = styled.div`
    font-weight: 600;
    font-size: 16px;
`;

const Link = styled.div`
    font-size: 12px;
    color: ${colors.secondaryGray};
`;

interface Props {
    linkList: LinkList;
}

const LinkListCell = ({ linkList }: Props) => {
    const dispatch = useDispatch();
    if (!linkList) return null;

    return (
        <Container
            onClick={() => {
                dispatch(push(`/profile/linklist/${linkList.id}`));
            }}
        >
            <Picture src={linkList.photoUrl} />
            <TitleContainer>
                <Name>/{linkList.name}</Name>
                <Link>
                    {linkList.LinkItems.length} item
                    {linkList.LinkItems.length !== 1 && "s"}
                </Link>
            </TitleContainer>
            <CopyToClipboard
                text={linkList.listUrl}
                onCopy={() => {
                    dispatch(addNotification("Copied link list!"));
                }}
            >
                <CopyButton onClick={e => e.stopPropagation()}>Copy</CopyButton>
            </CopyToClipboard>
        </Container>
    );
};

export default LinkListCell;
