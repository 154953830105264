import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import styled from "styled-components";
import Modal from "../../../modals/Modal";
import ModalButton from "../../../modals/ModalButton";
import { colors, fonts } from "../../../constants";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
`;

const List = styled.div`
    max-height: 200px;
    margin-top: 10px;
    padding: 5px;
    box-sizing: border-box;
    overflow-y: scroll;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 5px;
    font-size: ${fonts.subtitle}px;
`;

const CountriesModal = ({ countries }: { countries: string[] }) => {
    return (
        <Modal>
            <Main>
                <Title>Countries</Title>
                <List>{countries.join(", ")}</List>
            </Main>
            <ModalButton text={"Close"} />
        </Modal>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    countries: state.ui.modals.countries
});

export default connect(mapStateToProps)(CountriesModal);
