import React from "react";
import { Connection } from "../types";
import EmptyCard from "./EmptyCard";
import { ReactComponent as XIcon } from "../../components/icons/card-circle-x.svg";

interface Props {
    text: string;
    button?: {
        text: string;
        callback: VoidFunction;
    };
    connection?: Connection;
    padding?: string;
    className?: string;
    customStyles?: { [key: string]: string };
    boxFullHeight?: true;
    link?: {
        text: string;
        url: string;
    };
}

const XCard = ({
    text,
    button,
    connection,
    padding,
    className,
    customStyles,
    boxFullHeight,
    link
}: Props) => {
    return (
        <EmptyCard
            className={className}
            icon={<XIcon />}
            text={text}
            button={button}
            connection={connection}
            padding={padding}
            customStyles={customStyles}
            boxFullHeight={boxFullHeight}
            link={link}
        />
    );
};

export default XCard;
