import React from "react";
import { AnalyticsFilterState } from "./AnalyticsMain";
import styled from "styled-components";
import AnalyticsControlTags from "./AnalyticsControlTags";
import {
    CampaignByDate,
    CampaignTotals,
    MediaByDate,
    MediaTotals
} from "./AnalyticsDataParser";
import moment from "moment";
import AnalyticsDateRanges from "./AnalyticsDateRanges";
import { colors, fonts } from "../constants";

const Main = styled.div`
    padding: 5px 15px;
`;

interface RowProps {
    dataLoading?: boolean;
}

const Row = styled.div<RowProps>`
    display: flex;
    justify-content: space-between;
    ${props => (props.dataLoading ? "opacity: 0.6; pointer-events: none;" : "")}

    & + & {
        margin-top: 10px;
    }
`;

const Container = styled.div`
    height: 46px;
    padding: 10px;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: ${fonts.subtitle}px;
    user-select: none;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            border: ${colors.darkTeal} solid 1px;
        }
    }
`;

const DateRangeButton = styled(Container)`
    width: calc(100% - 101px);
`;

const SwipeContainer = styled(Container)`
    width: 91px;
    padding: unset;
`;

const Divider = styled.div`
    height: 100%;
    width: 1px;
    background-color: ${colors.mediumTeal};
`;

const SwipeButton = styled.div<{ disabled?: boolean }>`
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${props => props.disabled && 0.3};
    overflow: hidden;
    background: ${props => props.disabled && colors.blueGrey2};
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

interface FilterButtonProps {
    tagMode?: true;
}

const FilterButton = styled(Container)<FilterButtonProps>`
    width: calc(50% - 5px);

    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
    ${props => props.tagMode && "overflow-x: scroll; padding: unset;"}
`;

const Checkbox = styled.img`
    height: 24px;
    width: 24px;
    padding-right: 10px;
    box-sizing: content-box;
`;

const HideOverflow = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Caret = styled.img`
    margin-left: auto;
    padding-left: 6px;
    padding-right: 4px;
`;

const RightCaret = styled.img`
    opacity: 0.5;
`;

const LeftCaret = styled(RightCaret)`
    transform: rotate(0.5turn);
`;

interface Props {
    filterState: AnalyticsFilterState;
    parsedData: (CampaignTotals | MediaTotals | CampaignByDate | MediaByDate)[];
    pickDates: () => void;
    pickFilters: () => void;
    loading: boolean;
}

const AnalyticsControlButtons = ({
    filterState,
    parsedData,
    pickDates,
    pickFilters,
    loading
}: Props) => {
    function renderCampaignButton() {
        if (
            filterState.useCampaigns &&
            filterState.selectedCampaigns.size > 0
        ) {
            return (
                <FilterButton onClick={pickFilters} tagMode>
                    <AnalyticsControlTags
                        selectedItems={filterState.selectedCampaigns}
                        setSelectedItems={filterState.setSelectedCampaigns}
                        parsedData={parsedData}
                    />
                </FilterButton>
            );
        } else {
            return (
                <FilterButton
                    onClick={e => {
                        if (!filterState.useCampaigns) {
                            e.stopPropagation();
                            filterState.setUseCampaigns(true);
                        } else pickFilters();
                    }}
                >
                    <Checkbox
                        src={
                            filterState.useCampaigns
                                ? "/checkbox_selected.svg"
                                : "/checkbox_unselected.svg"
                        }
                        onClick={e => {
                            if (!filterState.useCampaigns) {
                                e.stopPropagation();
                                filterState.setUseCampaigns(true);
                            }
                        }}
                    />
                    <HideOverflow>Campaigns</HideOverflow>
                    <Caret src={"/caret.svg"} />
                </FilterButton>
            );
        }
    }

    function renderMediaButton() {
        if (!filterState.useCampaigns && filterState.selectedMedia.size > 0) {
            return (
                <FilterButton onClick={pickFilters} tagMode>
                    <AnalyticsControlTags
                        selectedItems={filterState.selectedMedia}
                        setSelectedItems={filterState.setSelectedMedia}
                        parsedData={parsedData}
                    />
                </FilterButton>
            );
        } else {
            return (
                <FilterButton
                    onClick={e => {
                        if (filterState.useCampaigns) {
                            e.stopPropagation();
                            filterState.setUseCampaigns(false);
                        } else pickFilters();
                    }}
                >
                    <Checkbox
                        src={
                            !filterState.useCampaigns
                                ? "/checkbox_selected.svg"
                                : "/checkbox_unselected.svg"
                        }
                        onClick={e => {
                            if (filterState.useCampaigns) {
                                e.stopPropagation();
                                filterState.setUseCampaigns(false);
                            }
                        }}
                    />
                    <HideOverflow>Media</HideOverflow>
                    <Caret src={"/caret.svg"} />
                </FilterButton>
            );
        }
    }

    const customStartDate =
        filterState.startDate && filterState.startDate.format("LL");
    const customEndDate =
        filterState.endDate && filterState.endDate.format("LL");

    const dateRangeText =
        customStartDate && customEndDate
            ? customStartDate === customEndDate
                ? customStartDate
                : `${customStartDate} - ${customEndDate}`
            : filterState.dateRange.title;

    return (
        <Main>
            <Row>
                <DateRangeButton onClick={pickDates}>
                    <HideOverflow>{dateRangeText}</HideOverflow>
                    <Caret src={"/caret.svg"} />
                </DateRangeButton>
                <SwipeContainer>
                    <SwipeButton
                        onClick={() => {
                            if (filterState.dateRange.use24HourEndpoint) {
                                filterState.setDateRange(
                                    AnalyticsDateRanges[1]
                                );
                            } else {
                                const startDate = moment(
                                    filterState.startDate ||
                                        filterState.dateRange.dateRange
                                            .startDate
                                ).subtract(1, "day");
                                const endDate = moment(
                                    filterState.endDate ||
                                        filterState.dateRange.dateRange.endDate
                                ).subtract(1, "day");

                                filterState.setStartDate(startDate);
                                filterState.setEndDate(endDate);
                            }
                        }}
                    >
                        <LeftCaret src={"/big_caret.svg"} />
                    </SwipeButton>
                    <Divider />
                    <SwipeButton
                        disabled={
                            !moment(
                                filterState.endDate ||
                                    filterState.dateRange.dateRange.endDate
                            )
                                .add(1, "day")
                                .isSameOrBefore(moment(), "day")
                        }
                        onClick={() => {
                            if (!filterState.dateRange.use24HourEndpoint) {
                                const startDate = moment(
                                    filterState.startDate ||
                                        filterState.dateRange.dateRange
                                            .startDate
                                ).add(1, "day");
                                const endDate = moment(
                                    filterState.endDate ||
                                        filterState.dateRange.dateRange.endDate
                                ).add(1, "day");

                                if (endDate.isSameOrBefore(moment(), "day")) {
                                    filterState.setStartDate(startDate);
                                    filterState.setEndDate(endDate);
                                }
                            }
                        }}
                    >
                        <RightCaret src={"/big_caret.svg"} />
                    </SwipeButton>
                </SwipeContainer>
            </Row>
            <Row dataLoading={loading}>
                {renderCampaignButton()}
                {renderMediaButton()}
            </Row>
        </Main>
    );
};

export default AnalyticsControlButtons;
