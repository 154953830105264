import { OldMedia, PlugLink } from "src/media/types";
import { CLEAR_MODAL } from "src/modals/actions";
import { Action, LOCATION_CHANGE } from "src/types";
import { CampaignsModal } from "src/ui/modals/reducers";
import {
    CREATE_CUSTOM_LINK,
    FETCH_CUSTOM_LINKS,
    FETCH_LINK_FOR_PLUG,
    FETCH_LINK_FOR_PLUG_WITHOUT_MODAL
} from "./actions";

export interface LinksState {
    currentPlugLink: string | null;
    linksForPlugs: { [s: string]: PlugLink | null };
    customLinks: OldMedia[];
}

const initialLinksState = {
    currentPlugLink: null,
    linksForPlugs: {},
    customLinks: []
};

function linksReducer(state = initialLinksState, action: Action) {
    switch (action.type) {
        case LOCATION_CHANGE:
            return { ...state, customLinks: [] };

        case CLEAR_MODAL:
            return {
                ...state,
                currentPlugLink: null
            };

        case FETCH_LINK_FOR_PLUG.SUCCESS:
        case FETCH_LINK_FOR_PLUG_WITHOUT_MODAL.SUCCESS:
            const linkKey = `${action.payload.campaign_id}-${action.payload.media_id}`;
            state.linksForPlugs = {
                ...state.linksForPlugs,
                [linkKey]: action.payload
            };
            const linkText = `${action.payload.domain}${action.payload.campaign_url}`;
            return {
                ...state,
                currentPlugLink: linkText,
                // modal: CampaignsModal.pluglink
            };

        case FETCH_LINK_FOR_PLUG.FAILURE:
            const errorKey = `${action.payload.campaign_id}-${action.payload.id}`;
            state.linksForPlugs = { ...state.linksForPlugs, [errorKey]: null };
            return state;

        case FETCH_CUSTOM_LINKS.SUCCESS:
            return { ...state, ...action.payload };

        case FETCH_CUSTOM_LINKS.FAILURE:
            return { ...state, ...action.payload };

        case CREATE_CUSTOM_LINK.SUCCESS:
            return {
                ...state,
                customLinks: [...state.customLinks, action.payload]
            };

        case CREATE_CUSTOM_LINK.FAILURE:
            return { ...state, ...action.payload };

        default:
            return state;
    }
}

export default linksReducer;
