import { useLocation } from "react-router";

// returns the properly formatted openBuyId or undefined
const useGetMarketplaceParamOpenBuyid = () => {
    const location = useLocation();
    let openBuyId = location.pathname.split("your-challenges/")[1];
    if (openBuyId) openBuyId = openBuyId.split("/")[0];

    return isNaN(openBuyId) ? undefined : parseInt(openBuyId);
};

export default useGetMarketplaceParamOpenBuyid;
