import { StepById } from "src/buys/modules/open-buys/reducer";
import {
    OpenBuyInstructionStep,
    OpenBuySubmission,
    OpenBuySubmissionStatuses
} from "src/buys/modules/open-buys/types";
import { InstructionStepState } from "src/ui/instructions-sets/actions";
import styled from "styled-components";

const IndicatorImg = styled.img`
    width: inherit;
    height: inherit;
`;

export const getSubmissionType = (submission: OpenBuySubmission) => {
    if (submission.linkSubmission) return "link";
    else if (submission.customMedia) return "customMedia";
    else if (submission.screenshotUrl) return "screenshot";
    else return "text";
};

export const getInstructionStepSubmissionType = (
    step?: OpenBuyInstructionStep | InstructionStepState,
    nice?: boolean
) => {
    if (step?.requiresTextSubmission) return nice ? "Spark Code" : "text";
    else if (step?.requiresLinkSubmission) return nice ? "Link" : "link";
    else if (step?.requiresScreenshot)
        return nice ? "Screenshot" : "screenshot";
    else if (step?.requiresCustomMedia) return nice ? "Media" : "customMedia";
    else return "";
};

export const getSubmissionProgress = (
    submission: Partial<OpenBuySubmission>,
    steps: StepById
) => {
    let payments =
        steps[submission.instructionStepId || 0]?.instructionPayments || [];
    let index = 0;
    let viewsActual = submission.viewsActual || 1;
    let progressToNext = 0;
    payments.forEach((pay, i) => {
        if (parseInt(pay.viewsMinimum || "0") <= viewsActual) index = i;
        else if (index === i - 1 && pay.viewsMinimum) {
            progressToNext =
                (parseInt(payments[index].viewsMinimum || "0") - viewsActual) /
                (parseInt(pay.viewsMinimum) -
                    parseInt(payments[index].viewsMinimum || "0"));
        }
    });

    let width =
        ((index + (progressToNext < 1 ? progressToNext : 0)) /
            (payments.length - 1)) *
        100;
    return width > 3 ? width : 3;
};

export const getMediaSubmissionStatus = (
    submission: Partial<OpenBuySubmission>,
    rawSubmission?: Partial<OpenBuySubmission>
) => {
    if (
        submission?.status === OpenBuySubmissionStatuses.revision_requested ||
        submission.status === OpenBuySubmissionStatuses.rejected ||
        rawSubmission?.status === OpenBuySubmissionStatuses.rejected ||
        rawSubmission?.status === OpenBuySubmissionStatuses.revision_requested
    )
        return OpenBuySubmissionStatuses.revision_requested;
    else if (
        submission?.status === OpenBuySubmissionStatuses.pending ||
        rawSubmission?.status === OpenBuySubmissionStatuses.pending
    )
        return OpenBuySubmissionStatuses.pending;
    else if (
        submission?.status === OpenBuySubmissionStatuses.approved ||
        rawSubmission?.status === OpenBuySubmissionStatuses.approved
    )
        return OpenBuySubmissionStatuses.approved;
    else return undefined;
};

export const getSubmissionStatuIndicator = (
    status?: OpenBuySubmissionStatuses
) => {
    if (status === OpenBuySubmissionStatuses.approved)
        return "/approved-status.svg";
    else if (status === OpenBuySubmissionStatuses.pending)
        return "/pending-status.svg";
    else if (
        status === OpenBuySubmissionStatuses.revision_requested ||
        status === OpenBuySubmissionStatuses.rejected
    )
        return "/rejected-status.svg";
    else return "";
};
