import {
    all,
    call,
    cancel,
    fork,
    put,
    select,
    take,
    takeEvery,
    takeLeading
} from "redux-saga/effects";
import { DocumentData, FirestoreError } from "@firebase/firestore-types";
import {
    CANCEL_INTERNAL_NOTES_CHANNEL,
    CREATE_INTERNAL_NOTES_CHANNEL,
    CREATE_INTERNAL_NOTES_CONVERSATION,
    updateInternalNotesConversation
} from "./actions";
import { getAuthState } from "src/auth/selectors";
import { createEventChannel } from "../saga";
import { ConversationUnread } from "../types/ConversationUnread";
import { conversationList } from "../schema";
import { normalize } from "normalizr";
import { createGroupUserSettingsChannelRequest } from "../group-user-settings/saga";
import { Action } from "src/types";
import { EventChannel } from "redux-saga";
import { createGroupConversationService } from "../conversation/saga";

export function* createInternalNotesChannel({
    payload: { submissionSetId }
}: Action) {
    try {
        const updateChannel = createEventChannel("Conversation", [
            "submissionSetId",
            "==",
            submissionSetId
        ]);
        yield fork(cancelInternalNotesChannel, updateChannel);
        while (true) {
            const conversations: ConversationUnread[] = yield take(
                updateChannel
            );

            const normalizedConversations = normalize(
                conversations,
                conversationList
            );
            yield fork(
                createGroupUserSettingsChannelRequest,
                normalizedConversations.result
            );

            yield put(
                updateInternalNotesConversation(
                    normalize(conversations, conversationList),
                    {
                        submissionSetId
                    }
                )
            );
        }
    } catch (error) {}
}

function* cancelInternalNotesChannel(
    listener: EventChannel<DocumentData[] | FirestoreError>
) {
    while (true) {
        yield take(CANCEL_INTERNAL_NOTES_CHANNEL);
        listener.close();
    }
}

function* createInternalNotesConversation({
    payload: { submissionSetId }
}: Action) {
    yield call(createGroupConversationService, {
        users: [],
        displayName: `Internal Notes for set ID: ${submissionSetId}`,
        submissionSetId
    });
}

export default function* approvaInternalNotesSaga() {
    yield all([
        takeEvery(CREATE_INTERNAL_NOTES_CHANNEL, createInternalNotesChannel),
        takeLeading(
            CREATE_INTERNAL_NOTES_CONVERSATION,
            createInternalNotesConversation
        )
    ]);
}
