import React, { useState } from "react";
import AdminToolInput from "src/admin-tools/components/AdminToolInput";
import ModalHeading from "src/components/modals/components/ModalHeading";
import withModalPortal from "src/components/withModalPortal";
import SmallModal from "src/modals/SmallModal";
import { BlueButton } from "src/profile/components/Buttons";
import styled from "styled-components";

const StyledSmallModal = styled(SmallModal)`
    isolation: isolate;
    & > #small-modal-child {
        overflow: visible;
    }
`;

const DropdownWrap = styled.div`
    position: relative;
    z-index: 1;
    padding-top: 12px;
`;

const SubmitBtn = styled(BlueButton)`
    margin-left: auto;
    padding: 8px 12px;
    margin-top: 12px;
`;

interface Props {
    submission: any;
    closeModal: VoidFunction;
    updateSubmission: (v: any) => void;
}

const UpdateViewCountModal = ({
    updateSubmission,
    closeModal,
    submission
}: Props) => {
    const [views, setViews] = useState(submission.viewsActual);

    return (
        <StyledSmallModal
            header={{ text: "Update Social Account", handleClose: closeModal }}
        >
            <ModalHeading text="SOCIAL ACCOUNT" />
            <DropdownWrap>
                <AdminToolInput
                    type="number"
                    label="View count"
                    onChange={v => setViews(parseInt(v || "0"))}
                    value={views}
                />
            </DropdownWrap>
            <SubmitBtn
                onClick={() =>
                    updateSubmission({
                        viewsActual: views
                    })
                }
                disabled={submission.viewsActual === views}
            >
                Submit
            </SubmitBtn>
        </StyledSmallModal>
    );
};

export default withModalPortal(UpdateViewCountModal);
