import React from "react";
import styled from "styled-components";

import DesktopNavButton from "src/components/desktop/DesktopNavButton";
import DesktopNavTab from "src/components/desktop/DesktopNavTab";
import { ReactComponent as DashboardIcon } from "src/components/icons/dashboard.svg";
import { ReactComponent as AnalyticsIcon } from "src/components/icons/analytics.svg";
import { ReactComponent as Title } from "src/components/icons/title-desktop.svg";
import { ReactComponent as Message } from "src/components/icons/message.svg";
import { startPromotingModal } from "src/ui/auth/actions";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { colors } from "src/constants";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "src/components/CommonStyles";

const Main = styled("nav")`
    ${FlexVerticallyCentered}
    justify-content: space-between;
    position: relative;
    height: 81px;
    padding: 0 32px;
    margin: 0 auto;
    max-width: 1400px;
`;

const Divider = styled("div")`
    border-bottom: 1px solid ${colors.secondaryLightGray};
    max-width: 100vw;
`;

const Logo = styled("img")`
    height: 38px;
    width: 38px;
    margin-right: 9px;
`;

const Name = styled("div")`
    ${FlexVerticallyCentered};
    cursor: pointer;
`;

const Nav = styled("div")`
    ${FlexVerticallyCentered}
    & > * ~ * {
        margin-left: 8px;
    }
`;

const Avatar = styled("img")`
    width: 32px;
    height: 32px;
`;

const AvatarWrapper = styled("div")`
    ${FlexCentered}
    padding: 0 10px;
    & > * ~ * {
        margin-left: 4px;
    }
`;

const Caret = styled("img")`
    width: 12px;
    cursor: pointer;
`;

const CaretButton = styled("div")`
    width: 24px;
    height: 24px;
`;

const TabWrapper = styled("div")`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${FlexCentered}
    pointer-events: none;
    & > * ~ * {
        margin-left: 14px;
    }

    @media (max-width: 760px) {
        display: none;
    }
`;

const NotAuthedNav = () => {
    const dispatch = useDispatch();

    return (
        <>
            <Main>
                <Name onClick={() => dispatch(push("/onboarding"))}>
                    <Logo src={"/logo.svg"} />
                    <Title fill={colors.black} />
                </Name>

                <TabWrapper>
                    <DesktopNavTab
                        title="Campaigns"
                        active
                        onClick={() => dispatch(startPromotingModal())}
                    >
                        <DashboardIcon />
                    </DesktopNavTab>
                    <DesktopNavTab
                        title="Analytics"
                        onClick={() => dispatch(startPromotingModal())}
                    >
                        <AnalyticsIcon />
                    </DesktopNavTab>
                </TabWrapper>

                <Nav>
                    <DesktopNavButton
                        onClick={() => dispatch(startPromotingModal())}
                    >
                        <Message />
                    </DesktopNavButton>
                    <DesktopNavButton
                        onClick={() => dispatch(startPromotingModal())}
                    >
                        <AvatarWrapper
                            onClick={() => dispatch(startPromotingModal())}
                        >
                            <Avatar src={"/no_profile_photo.svg"} />
                            <CaretButton>
                                <Caret src={"/caret.svg"} />
                            </CaretButton>
                        </AvatarWrapper>
                    </DesktopNavButton>
                </Nav>
            </Main>

            <Divider />
        </>
    );
};

export default NotAuthedNav;
