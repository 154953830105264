import React from "react";
import CircleIcon from "src/components/CircleIcon";
import { CustomLoading } from "src/components/SkeletonLoader";
import { colors } from "src/constants";
import { ClickActivity } from "src/types/ClickActivity";
import styled from "styled-components";

const LoadingList = styled.div`
    padding: 5px 18px;

    & > * ~ * {
        margin-top: 10px;
    }
`;

const ChartBody = styled.div`
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
`;

const InstallColumn = styled.div`
    position: relative;
    background-color: ${colors.lightTeal};
    border: 1px solid ${colors.mediumTeal};
    border-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 34%;
`;

const InstallColumnNub = styled.div`
    position: absolute;
    bottom: -10px;
    left: -1px;
    right: -1px;
    height: 10px;
    background-color: ${colors.lightTeal};
    border: 1px solid ${colors.mediumTeal};
    border-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

const ChartColumn = styled.div`
    width: 22%;
    border-top: 1px solid transparent;
`;

const Cell = styled.div`
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
    font-size: 12px;
`;

const InstallTitle = styled(Cell)`
    color: ${colors.secondaryGreen};
    font-weight: 500;
    padding: 5px;
`;

const InstallCell = styled(Cell)`
    margin: 0 15px;
    border-top: 1px solid ${colors.mediumTeal};
`;

const ChartTitle = styled(Cell)`
    color: ${colors.primaryGray};
    font-size: 11px;
    font-weight: 300;
`;

const ChartCell = styled(Cell)`
    border-top: 1px solid ${colors.mediumTeal};
`;

interface Props {
    clickActivity?: ClickActivity[];
}

const ClickActivityChart = ({ clickActivity }: Props) => {
    if (!clickActivity)
        return (
            <LoadingList>
                <CustomLoading height="40px" />
                <CustomLoading height="40px" />
                <CustomLoading height="40px" />
            </LoadingList>
        );

    return (
        <ChartBody>
            <InstallColumn>
                <InstallTitle>Time Frame</InstallTitle>
                {clickActivity.map((data, idx) => {
                    return (
                        <InstallCell key={idx}>{data.installTime}</InstallCell>
                    );
                })}
                <InstallColumnNub />
            </InstallColumn>
            <ChartColumn>
                <ChartTitle>Clicks</ChartTitle>
                {clickActivity.map((data, idx) => {
                    return (
                        <ChartCell key={idx}>
                            {data.clicks ? data.clicks : "--"}
                        </ChartCell>
                    );
                })}
            </ChartColumn>
            <ChartColumn>
                <ChartTitle>Earnings</ChartTitle>
                {clickActivity.map((data, idx) => {
                    return (
                        <ChartCell key={idx}>
                            {data.earnings
                                ? `$${data.earnings.toFixed(2)}`
                                : "--"}
                        </ChartCell>
                    );
                })}
            </ChartColumn>
            <ChartColumn>
                <ChartTitle>Status</ChartTitle>
                {clickActivity.map((data, idx) => {
                    return (
                        <ChartCell key={idx}>
                            {data.updatedAt ? (
                                <CircleIcon
                                    type="check"
                                    color={colors.primaryGreen}
                                />
                            ) : (
                                <CircleIcon
                                    type="ellipsis"
                                    color={colors.primaryYellow}
                                />
                            )}
                        </ChartCell>
                    );
                })}
            </ChartColumn>
        </ChartBody>
    );
};

export default ClickActivityChart;
