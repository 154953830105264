import { NormalizedAction, NormalizedResponse } from "src/types";
import { Extension, PublisherExtensionLink } from "./ChallengeExtensions";

export const FETCH_OPEN_BUY_EXTENSIONS = {
    REQUEST: "FETCH_OPEN_BUY_EXTENSIONS_REQUEST",
    SUCCESS: "FETCH_OPEN_BUY_EXTENSIONS_SUCCESS"
};
export const fetchOpenBuyExtensions = (openBuyId: number) => ({
    type: FETCH_OPEN_BUY_EXTENSIONS.REQUEST,
    payload: openBuyId
});
export const fetchOpenBuyExtensionsSuccess = (
    openBuyId: number,
    response: NormalizedResponse<Extension, number[]>
): NormalizedAction => ({
    type: FETCH_OPEN_BUY_EXTENSIONS.SUCCESS,
    payload: { openBuyId },
    response
});

export const UPSERT_EXTENSIONS = {
    REQUEST: "UPSERT_EXTENSIONS_REQUEST"
};
export const upsertExtensions = (
    openBuyId: number,
    pubLinks: PublisherExtensionLink[],
    message: { success: string; failure: string }
) => ({
    type: UPSERT_EXTENSIONS.REQUEST,
    payload: { pubLinks, openBuyId, message }
});

export const SET_EXTENSIONS_LOADING = "SET_EXTENSIONS_LOADING";

export const setExtensionsLoading = (isLoading: boolean) => ({
    type: SET_EXTENSIONS_LOADING,
    payload: { isLoading }
});
export const SET_EXTENSIONS_ERROR = "SET_EXTENSIONS_ERROR";

export const setExtensionsError = (isError: boolean) => ({
    type: SET_EXTENSIONS_LOADING,
    payload: { isError }
});
