import React from "react";
import styled from "styled-components";
import { ReferralEarnings } from "./Analytics";
import { coinToDollars, formatNumber } from "../util";
import { colors } from "../constants";

const Cell = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & + & {
        margin-top: 15px;
    }
`;

const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    overflow: hidden;
`;

const Name = styled.div`
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Bonus = styled.div`
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: ${colors.secondaryGray};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MoneyContainer = styled.div`
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
`;

const Cash = styled.div`
    font-size: 20px;
    line-height: 24px;
`;

const ReferralEarningsCell = ({ earnings }: { earnings: ReferralEarnings }) => {
    const plugcoins = earnings.should_pay_percentage
        ? Math.round(parseFloat(earnings.earned_plugcoins) * 10) / 10
        : 500;

    const commission = Math.round(earnings.commission * 100);

    function renderBonus() {
        let bonusString;
        if (earnings.should_pay_percentage) {
            bonusString = `${commission}% earnings`;
        } else {
            bonusString = "+500 coin bonus";
        }

        if (earnings.expiration_date) {
            const expirationDate = new Date(earnings.expiration_date);
            const expirationMonth = expirationDate.getMonth() + 1;
            const expirationDay = expirationDate.getDate();

            bonusString += ` • Expired on ${expirationMonth}/${expirationDay}`;
        }

        return bonusString;
    }

    return (
        <Cell>
            <NameContainer>
                <Name>{earnings.name}</Name>
                <Bonus>{renderBonus()}</Bonus>
            </NameContainer>
            <MoneyContainer>
                <Cash>{formatNumber(coinToDollars(plugcoins))}</Cash>
            </MoneyContainer>
        </Cell>
    );
};

export default ReferralEarningsCell;
