import { AnimatePresence } from "framer-motion";
import React from "react";
import FullScreenModal from "src/modals/FullScreenModal";
import { AnalyticsScrollTo } from "../AnalyticsMain";
import { ModalReferralAndMediaMoreProps } from "../utils/props";
import MediaEarningsCard from "src/analytics/MediaEarningsCard";

const MediaEarningsMoreModal = ({
    open,
    closeModal,
    simpleNavbar,
    modal
}: ModalReferralAndMediaMoreProps) => {
    return (
        <AnimatePresence>
            {open && (
                <FullScreenModal closeModal={closeModal}>
                    {simpleNavbar(AnalyticsScrollTo.mediaEarnings)}
                    <MediaEarningsCard />
                    {modal}
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default MediaEarningsMoreModal;
