import React, { useEffect } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../reducers";
import { fetchPublic, FetchPublicParams } from "../actions";
import { PublicProfileData, SocialAccount } from "../types";
import NavButton from "../../components/NavButton";
import SimpleNavbar from "../../components/SimpleNavbar";
import FullscreenLoader from "../../components/FullscreenLoader";
import ProfileHeader from "../components/ProfileHeader";
import Bio from "../components/Bio";
import InfoRow from "../components/InfoRow";
import moment from "moment";
import HeaderButtons from "../public/HeaderButtons";
import Divider from "../../components/Divider";
import Header from "../../components/Header";
import PaddingNoTop from "../../components/PaddingNoTop";
import AccountCell from "../accounts/components/AccountCell";
import styled from "styled-components";
import MainContainer from "src/components/MainContainer";

const Main = styled.div`
    min-height: 100vh;
    background-color: white;
`;

interface Props {
    selfId: number;
    otherId: string;
    socialAccounts: SocialAccount[];
    closeCallback: VoidFunction;
    publicDataStore: {
        [publisherId: string]: PublicProfileData;
    };
    fetchPublic: (params: FetchPublicParams) => void;
}

const PublicProfilePreview = ({
    selfId,
    otherId,
    socialAccounts,
    closeCallback,
    publicDataStore,
    fetchPublic
}: Props) => {
    useEffect(() => {
        if (!publicDataStore[otherId]) {
            fetchPublic({ selfId, otherId });
        }
    }, [selfId, otherId]);

    const publicData = publicDataStore[otherId];

    const navbar = (
        <SimpleNavbar left={<NavButton back onClick={closeCallback} />} />
    );

    if (publicData) {
        const connection = publicData.connection;
        const stats = publicData.stats;

        return (
            <Main>
                {navbar}

                <MainContainer>
                    <ProfileHeader publicProfile={connection.other} />
                    <Bio bio={connection.other.profile.bio} />
                    <InfoRow
                        infos={[
                            {
                                title: stats.rank ? `#${stats.rank}` : "None",
                                subtitle: "Weekly Rank"
                            },
                            {
                                title: `${stats.connectionCount}`,
                                subtitle: "Connections"
                            },
                            {
                                title: `${moment(stats.memberSince).format(
                                    "MMM ‘YY"
                                )}`,
                                subtitle: "Member Since"
                            }
                        ]}
                    />
                    <HeaderButtons connection={connection} />
                    <Divider />
                    <Header
                        title={"Accounts"}
                        subtitle={`${socialAccounts.length} Verified Accounts`}
                    />
                    <PaddingNoTop>
                        {socialAccounts.map(account => {
                            return (
                                <AccountCell
                                    key={account.id}
                                    account={account}
                                />
                            );
                        })}
                    </PaddingNoTop>
                </MainContainer>
            </Main>
        );
    } else {
        return (
            <Main>
                {navbar}
                <FullscreenLoader />
            </Main>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    publicDataStore: state.profile.publicDataStore
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchPublic: (params: FetchPublicParams) => dispatch(fetchPublic(params))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicProfilePreview);
