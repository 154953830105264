import { PlacementConfig } from "src/ui/placements/reducer";

// Convert placement config object to array of placement
const getPlacementIdsFromConfig = (
    placementSelection: PlacementConfig,
    dictionaryByType: { [type: string]: number }
) => {
    const placementTypes = Object.keys(placementSelection);
    const placementIds: number[] = [];
    placementTypes.forEach(type => placementIds.push(dictionaryByType[type]));
    return placementIds;
};

export default getPlacementIdsFromConfig;
