import { get } from "../Api";
import { Channel } from "./types";
import { Connection } from "../profile/types";

export const sendBirdMembersToConnections = async (
    selfId: number,
    sendBirdMembers: any[],
    onFetchConnection: (connection: Connection) => void,
    preloadedConnections?: Connection[] | null
): Promise<Connection[]> => {
    const connectionMap: { [id: string]: Connection } = {};

    preloadedConnections?.forEach(connection => {
        connectionMap[connection.other.id] = connection;
    });

    return await Promise.all(
        sendBirdMembers.map(memberUserId => {
            if (connectionMap[memberUserId]) {
                return connectionMap[memberUserId];
            } else {
                return get(
                    `/v1/connection/p:${selfId}/${memberUserId}`,
                    {}
                ).then(({ data }) => {
                    const connection: Connection = data.data;
                    onFetchConnection(connection);
                    return connection;
                });
            }
        })
    );
};

const addLastMessageToChannel = async (channel: Channel): Promise<Channel> => {
    const { sendBirdData, connections } = channel;
    if (sendBirdData.lastMessage) {
        return {
            sendBirdData,
            connections,
            lastMessage: sendBirdData.lastMessage
        };
    } else {
        const prevMessageListQuery = sendBirdData.createPreviousMessageListQuery();
        const messages = await prevMessageListQuery.load(1, true);

        return {
            sendBirdData,
            connections,
            lastMessage: messages ? messages[0] : undefined
        };
    }
};

export const addConnectionsToChannels = async (
    selfId: number,
    sendBirdChannels: any,
    onFetchConnection: (connection: Connection) => void,
    preloadedConnections?: Connection[] | null
): Promise<Channel[]> => {
    const memberUserIdSet = new Set();
    sendBirdChannels.forEach((channel: any) => {
        channel.members.forEach((member: any) => {
            memberUserIdSet.add(member.userId);
        });
    });

    const allConnections: Connection[] = await sendBirdMembersToConnections(
        selfId,
        Array.from(memberUserIdSet),
        onFetchConnection,
        preloadedConnections
    );

    const connectionMap: { [id: string]: Connection } = {};

    allConnections.forEach(connection => {
        connectionMap[connection.other.id] = connection;
    });

    const channels: Channel[] = [];

    for (const sendBirdData of sendBirdChannels) {
        const connections: Connection[] = sendBirdData.members.map(
            (member: any) => connectionMap[member.userId]
        );

        channels.push({ sendBirdData, connections });
    }

    return await Promise.all(
        channels.map(channel => addLastMessageToChannel(channel))
    );
};

export const addConnectionsToChannel = async (
    selfId: number,
    channel: any,
    onFetchConnection: (connection: Connection) => void,
    preloadedConnections?: Connection[] | null
): Promise<Channel> => {
    const result: Channel[] = await addConnectionsToChannels(
        selfId,
        [channel],
        onFetchConnection,
        preloadedConnections
    );
    return result[0];
};

export const muteBanRefreshChannel = async (
    selfId: number,
    channel: Channel,
    onFetchConnection: (connection: Connection) => void,
    preloadedConnections?: Connection[] | null
): Promise<Channel> => {
    if (channel.sendBirdData.myRole !== "operator") {
        return channel;
    }

    const memberUserListQuery = channel.sendBirdData.createMemberListQuery();
    memberUserListQuery.mutedMemberFilter = "muted";

    const bannedUserListQuery = channel.sendBirdData.createBannedUserListQuery();

    const [mutedUsers, bannedUsers] = await Promise.all([
        memberUserListQuery.next().catch(() => undefined),
        bannedUserListQuery.next().catch(() => undefined)
    ]);

    if (!bannedUsers) {
        return {
            sendBirdData: channel.sendBirdData,
            connections: channel.connections,
            mutedUsers
        };
    }

    const bannedConnections = await sendBirdMembersToConnections(
        selfId,
        bannedUsers,
        onFetchConnection,
        preloadedConnections
    );

    return {
        sendBirdData: channel.sendBirdData,
        connections: channel.connections.filter(connection => {
            return !bannedUsers.some((bannedUser: any) => {
                return connection.other.id === bannedUser.userId;
            });
        }),
        mutedUsers,
        banned: {
            bannedUsers,
            bannedConnections
        }
    };
};

export const joinFriendChannel = async (channel: Channel): Promise<void> => {
    if (
        channel.sendBirdData.myMemberState !== "joined" &&
        channel.connections.some(connection => {
            return (
                channel.sendBirdData.inviter.userId === connection.other.id &&
                connection.state === "friend"
            );
        })
    ) {
        await channel.sendBirdData.acceptInvitation("");
    }
    return;
};

export const extractTitleAndPhoto = (
    sendBirdData: any,
    userId: number
): {
    title: string;
    photoUrl: string;
} => {
    const otherMembers = sendBirdData.members.filter((otherUser: any) => {
        return otherUser.userId !== `p:${userId}`;
    });

    if (otherMembers.length === 0) {
        return {
            title: sendBirdData.name || "Unknown",
            photoUrl: sendBirdData.coverUrl || "/logo.svg"
        };
    }

    const title = sendBirdData.name
        ? sendBirdData.name
        : otherMembers.length === 1
        ? otherMembers[0].nickname
        : "Group Channel";

    const sendBirdCoverPhoto =
        sendBirdData.coverUrl.length > 5 ? sendBirdData.coverUrl : null;
    const photoUrl =
        sendBirdCoverPhoto || otherMembers[0].profileUrl || "/logo.svg";

    return { title, photoUrl };
};
