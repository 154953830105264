import { createSelector } from "reselect";
import { PlacementConfig } from "src/ui/placements/reducer";
import { GlobalState } from "../../reducers";
import { Placement } from "../types";

const placementState = (state: GlobalState) => state.entities.placements;
const platform = (state: GlobalState, platform: string) => platform;
const filtersPlacementConfig = (state: GlobalState) =>
    state.pagination.medias.filters.placements;
export const globalPlacementConfig = (state: GlobalState) =>
    state.ui.placements.placementConfig;

export const getPlacements = createSelector(placementState, placements => {
    return placements.allIds.map(placementId => placements.byId[placementId]);
});

export const getPlacementItemsWithPlatform = createSelector(
    placementState,
    platform,
    (placements, platform) => {
        const allPlacements = placements.allIds.map(
            placementId => placements.byId[placementId]
        );

        const filteredPlacements = allPlacements.filter(
            placement => !platform || placement.platform === platform
        );

        return filteredPlacements.map(placement => ({
            value: placement.id,
            type: `${placement.type} Post`
        }));
    }
);

export const getPlacementDictionaryByType = createSelector(
    placementState,
    placementState => {
        const placements: Placement[] = Object.values(placementState.byId);
        const placementDictionary: { [type: string]: number } = {};
        placements.forEach(placement => {
            placementDictionary[placement.type] = placement.id;
        });
        return placementDictionary;
    }
);

export const getPlacementIdsFromConfig = createSelector(
    placementState,
    filtersPlacementConfig,
    globalPlacementConfig,
    (state: GlobalState, type: string | null) => type,
    (placementState, filtersPlacement, globalPlacement, type) => {
        const placementTypes = Object.keys(
            type === "global" ? globalPlacement : filtersPlacement
        );
        const placements: Placement[] = Object.values(placementState.byId);
        if (placementTypes.length === placements.length) return [];

        const placementDictionary: { [type: string]: number } = {};
        placements.forEach(placement => {
            placementDictionary[placement.type] = placement.id;
        });

        const placementIds: number[] = [];
        placementTypes.forEach(type => {
            placementDictionary[type] &&
                placementIds.push(placementDictionary[type]);
        });

        return placementIds;
    }
);

export const getPlacementConfigByPlatform = createSelector(
    placementState,
    platform,
    (placements, platform) => {
        const allPlacements = placements.allIds.map(
            placementId => placements.byId[placementId]
        );

        let placementConfig: PlacementConfig = {};
        allPlacements.forEach(placement => {
            if (placement.platform === platform)
                placementConfig[placement.type] = true;
        });

        return placementConfig;
    }
);

export const getPlacementsDisplayText = createSelector(
    globalPlacementConfig,
    getPlacements,
    (placementConfig, placements) => {
        const configLength = Object.keys(placementConfig).length;
        if (!configLength || placements.length === configLength)
            return "All Placements";

        if (configLength > 2)
            return (
                Object.keys(placementConfig)
                    .slice(0, 2)
                    .join(", ") + `, +${configLength - 2} More`
            );

        return Object.keys(placementConfig).join(", ");
    }
);
