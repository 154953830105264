import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { FlexCentered } from "src/components/CommonStyles";
import { CustomLoading } from "src/components/SkeletonLoader";
import { colors, fonts } from "src/constants";
import { BlueButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import DelayedClicksUpdatesModal from "./DelayedClicksUpdatesModal";

const Main = styled.div<{ pointer?: boolean }>`
    position: relative;
    padding: 16px 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid ${colors.mediumTeal};
    ${props => props.pointer && "cursor: pointer"};
`;

const Emoji = styled.span`
    font-size: 32px;
    line-height: 38.19px;
    font-weight: 700;
    margin-bottom: 16px;
`;

const Title = styled.div`
    font-size: ${fonts.smallTitle}px;
    font-weight: 700;
`;

const CollapsedTitle = styled(Title)`
    align-self: flex-start;
    margin-right: 24px;
`;

const Subtitle = styled.div`
    margin-top: 16px;
    font-size: 12px;
    font-weight: 400;
`;

const Button = styled(BlueButton)`
    margin-top: 16px;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 700;
`;

const CaretWrapper = styled.div`
    position: absolute;
    top: 12px;
    right: 18px;
    ${FlexCentered}
    width: 24px;
    height: 24px;
    user-select: none;
    cursor: pointer;
`;

const Caret = styled.img<{ expanded: boolean }>`
    transform: ${props =>
        props.expanded ? "rotate(-180deg)" : "rotate(0deg)"};
    transition: all 0.3s;
`;

const DelayedCampaignsHeader = () => {
    const initiallyExpanded =
        localStorage.getItem("delayedCampaignsExpanded") !== "false";
    const [detailsModal, toggleDetailsModal] = useState(false);
    const [expanded, toggleExpanded] = useState(initiallyExpanded);

    const hasDelayedCampaigns = useSelector(
        (state: GlobalState) => state.entities.delayedCampaigns.allIds.length
    );

    const {
        delayedCampaignLoading,
        delayedCampaignInstallsTitle
    } = useSelector((state: GlobalState) => state.notifications, shallowEqual);

    function setExpanded() {
        localStorage.setItem(
            "delayedCampaignsExpanded",
            JSON.stringify(!expanded)
        );
        toggleExpanded(!expanded);
    }

    if (!hasDelayedCampaigns && !delayedCampaignLoading) return null;

    return (
        <>
            {delayedCampaignLoading ? (
                <Main>
                    <CustomLoading height="154px" />
                </Main>
            ) : (
                <Main
                    onClick={() => {
                        !expanded && setExpanded();
                    }}
                    pointer={!expanded}
                >
                    {expanded ? (
                        <>
                            <Emoji>📣</Emoji>
                            <Title>
                                You’re promoting a campaign with delayed
                                installs
                            </Title>
                            <Subtitle>{delayedCampaignInstallsTitle}</Subtitle>
                            <Button onClick={() => toggleDetailsModal(true)}>
                                Details
                            </Button>
                        </>
                    ) : (
                        <CollapsedTitle>
                            You’re promoting a campaign with delayed installs
                        </CollapsedTitle>
                    )}
                    <CaretWrapper onClick={setExpanded}>
                        <Caret src={"/caret.svg"} expanded={expanded} />
                    </CaretWrapper>
                </Main>
            )}
            <AnimatePresence>
                {detailsModal && (
                    <DelayedClicksUpdatesModal
                        closeModal={() => toggleDetailsModal(false)}
                    />
                )}
            </AnimatePresence>
        </>
    );
};

export default DelayedCampaignsHeader;
