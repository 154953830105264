import React from "react";
import FullScreenModal from "../../../modals/FullScreenModal";
import { ReactComponent as Cross } from "../../../components/icons/cross.svg";
import styled from "styled-components";
import SimpleNavbar from "../../../components/SimpleNavbar";
import { colors } from "../../../constants";
import { ModalTitle } from "../../../components/text/CommonStyles";
import Title from "../../../components/text/Title";
import Subtitle from "../../../components/text/Subtitle";
import {
    FlexContent,
    FlexVCSpaceBetween
} from "../../../components/CommonStyles";
import ModalOption from "../../../modals/components/ModalOption";

const CrossIcon = styled(Cross)`
    cursor: pointer;
`;

const Main = styled.div`
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    padding: 18px;

    & > * ~ * {
        margin-top: 4px;
    }
`;

interface Item {
    value: number;
    type: string;
}

interface Props {
    title: string;
    heading: string;
    description: string;
    items: Item[];
    onSelect: (value: number, type: string) => void;
    closeModal: () => void;
    customOptionCallback: () => void;
}

const ItemSelectionModal = ({
    title,
    heading,
    description,
    items,
    onSelect,
    closeModal,
    customOptionCallback
}: Props) => {
    return (
        <FullScreenModal closeModal={closeModal}>
            <Main>
                <SimpleNavbar
                    title={<ModalTitle>{title}</ModalTitle>}
                    left={
                        <CrossIcon
                            fill={colors.primaryGray}
                            onClick={closeModal}
                        />
                    }
                />
                <FlexContent>
                    <Header>
                        <Title>{heading}</Title>
                        <Subtitle>{description}</Subtitle>
                    </Header>
                    {items.map(({ value, type }, index) => (
                        <ModalOption
                            key={`${value}-${index}`}
                            title={type}
                            onClick={() => onSelect(value, type)}
                        />
                    ))}
                    <ModalOption title="Other" onClick={customOptionCallback} />
                </FlexContent>
            </Main>
        </FullScreenModal>
    );
};

export default ItemSelectionModal;
