import React, { useEffect, useState } from "react";
import {
    CampaignByDate,
    CampaignTotals,
    MediaByDate,
    MediaTotals
} from "./AnalyticsDataParser";
import NavButton from "../components/NavButton";
import SimpleNavbar from "../components/SimpleNavbar";
import SearchBar from "../components/SearchBar";
import AnalyticsCampaignMediaRow from "./AnalyticsCampaignMediaRow";
import { mediaModal, ModalMedia } from "./actions";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { AnalyticsModal } from "./reducer";
import MediaModal from "./MediaModal";
import { analyticsMediaToString } from "../util";
import { colors } from "../constants";
import PaddingNoTop from "../components/PaddingNoTop";
import MainContainer from "src/components/MainContainer";

interface Props {
    parsedData: (CampaignTotals | MediaTotals | CampaignByDate | MediaByDate)[];
    initialSelected: Set<string>;
    onCancelTapped: () => void;
    onDoneTapped: (selectedItems: Set<string>) => void;
    modal: AnalyticsModal;
    mediaModal: (modalMedia: ModalMedia) => void;
}

const AnalyticsCampaignMediaScreen = ({
    parsedData,
    initialSelected,
    onCancelTapped,
    onDoneTapped,
    modal,
    mediaModal
}: Props) => {
    const [selectedItems, setSelectedItems] = useState<Set<string>>(
        initialSelected
    );

    const [searchString, setSearchString] = useState("");

    const leftNavButton = (
        <NavButton onClick={onCancelTapped}>Cancel</NavButton>
    );
    const rightNavButton = (
        <NavButton onClick={() => onDoneTapped(selectedItems)}>
            <div style={{ color: colors.primaryBlue }}>Done</div>
        </NavButton>
    );

    return (
        <>
            <SimpleNavbar left={leftNavButton} right={rightNavButton} />
            <MainContainer>
                <SearchBar
                    wrapped
                    searchString={searchString}
                    updateSearchString={setSearchString}
                />
                <PaddingNoTop>
                    {parsedData
                        .filter(data => {
                            const nameString =
                                "campaign" in data
                                    ? data.campaignName
                                    : analyticsMediaToString(data);
                            const campaignName =
                                "media" in data &&
                                data.media.toString() !== "2007" &&
                                data.campaignName
                                    ? data.campaignName
                                    : "";

                            return (
                                searchString.length === 0 ||
                                nameString
                                    .toLowerCase()
                                    .includes(searchString.toLowerCase()) ||
                                campaignName
                                    .toLowerCase()
                                    .includes(searchString.toLowerCase())
                            );
                        })
                        .map(data => {
                            return (
                                <AnalyticsCampaignMediaRow
                                    key={
                                        "campaign" in data
                                            ? data.campaign
                                            : data.media
                                    }
                                    title={
                                        "campaign" in data
                                            ? data.campaignName
                                            : analyticsMediaToString(data)
                                    }
                                    subtitle={
                                        `$${data.totalDollars.toFixed(2)}` +
                                        ("media" in data &&
                                        data.media.toString() !== "2007" &&
                                        data.campaignName
                                            ? ` · ${data.campaignName}`
                                            : "")
                                    }
                                    iconUrl={
                                        "campaignImageUrl" in data
                                            ? data.campaignImageUrl
                                            : data.mediaCoverPhotoUrl ||
                                              data.mediaUrl
                                    }
                                    video={
                                        "campaignImageUrl" in data
                                            ? false
                                            : data.mediaCoverPhotoUrl
                                            ? false
                                            : data.mediaType === "video"
                                    }
                                    rowClick={() => {
                                        const currentId =
                                            "campaign" in data
                                                ? data.campaign
                                                : data.media;
                                        const newSet = new Set(selectedItems);

                                        if (selectedItems.has(currentId)) {
                                            newSet.delete(currentId);
                                        } else {
                                            newSet.add(currentId);
                                        }

                                        setSelectedItems(newSet);
                                    }}
                                    imageClick={(e: any) => {
                                        if (
                                            "media" in data &&
                                            data.mediaUrl &&
                                            (data.mediaType === "video" ||
                                                data.mediaType === "photo")
                                        ) {
                                            e.stopPropagation();
                                            mediaModal({
                                                mediaUrl: data.mediaUrl,
                                                type: data.mediaType
                                            });
                                        }
                                    }}
                                    checked={selectedItems.has(
                                        "campaign" in data
                                            ? data.campaign
                                            : data.media
                                    )}
                                />
                            );
                        })}
                </PaddingNoTop>
            </MainContainer>

            {modal === AnalyticsModal.media && <MediaModal />}
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    modal: state.analytics.modal
});

const mapDispatchToProps = (dispatch: any) => ({
    mediaModal: (modalMedia: ModalMedia) => dispatch(mediaModal(modalMedia))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalyticsCampaignMediaScreen);
