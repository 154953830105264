import React, { useEffect, useState } from "react";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchCampaign, fetchCampaigns } from "src/campaigns/actions";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";
import { breakpoints, colors, maxWidth, sizes, spacing } from "src/constants";
import { useConvertReduxDataToAPIData } from "src/marketplace/instructions-sets/utils/hooks.page-partial";
import { GlobalState } from "src/reducers";
import {
    defaultInstructionStepState,
    openInstructionsCampaignSelectionModal,
    setCurrentDragTargetType,
    setCurrentInstructionsSetId,
    setInstructionsPageStep,
    setInstructionsSetSubmitStatus,
    setInstructionSteps,
    setIsCurrentDragTargetAnInstructionElement
} from "src/ui/instructions-sets/actions";
import { hideScrollbar } from "src/utils/styles/snippets";
import { EventSubmit } from "src/utils/types/form";
import styled from "styled-components";
import {
    createInstructionStep,
    fetchInstructionSteps,
    updateInstructionStep
} from "../actions.instruction-steps";
import {
    createInstructionsSet,
    fetchInstructionsSet,
    InstructionsSetCreateData,
    updateInstructionsSet
} from "../actions.instructions-sets";
import { ChildInstructionStep, InstructionStep } from "../types";
import {
    convertAPIDataToReduxData,
    handleDraggingOfInstructionElementIntoAnInstructionStep,
    handleInstructionStepDrag,
    instructionElementTitles
} from "../utils/instructions-sets";
import InstructionsForm from "./InstructionsForm";
import InstructionsFormXCard from "./InstructionsFormXCard";
import MarketplacePagePartialHeader from "../../components/MarketplacePagePartialHeader";
import PagePartialScratchStep from "./PagePartialScratchStep";
import { getCurrentInstructionsSet } from "src/marketplace/instructions-sets/selectors";
import {
    generateListOfExistingChildSteps,
    handleCreateInstructionsSet,
    handleUpdateInstructionsSet
} from "../utils/helpers.page-partial";
import { setCurrentCampaignId } from "src/ui/campaigns/actions";
import ChallengeToolsHeader from "src/marketplace/challenge-tools/components/ChallengeToolsHeader";
import { InfoText } from "src/marketplace/payment-sets/components/styledComponents";
import { setAdminCurrentWizardStep } from "src/ui/admin-tools/actions";
import MainLeftContent from "./MainLeftContent";
import { currentCampaign } from "src/campaigns/selectors";
import { adminUpdateOpenBuy } from "src/admin-tools/actions.admin-tools";
import {
    AdminToolsInstructionsLoadingContainer,
    AdminToolsInstructionsFormLoading,
    SelectionHeaderLoading,
    SelectionHeaderLoadingContainer,
    AdminToolsSelectionsLoading
} from "../SkeletonLoader";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    text-align: left;
`;

const Main = styled(MainContainer)`
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (max-width: ${breakpoints.tablet}px) {
        padding: 0px ${spacing.sideSpacing}px;
    }

    @media (max-width: ${maxWidth.desktop}px) {
        padding-top: 20px;
    }
`;

const Body = styled.section`
    display: flex;
    flex: 1;
    gap: 24px;
`;

const LeftColumn = styled.aside`
    ${hideScrollbar()};
    position: relative;
    width: ${sizes.marketplace.pagePartial.leftColumnWidth}px;
    overflow-x: hidden;
    overflow-y: auto;
`;

const RightColumn = styled.form`
    display: flex;
    flex: 1;
`;

const ErrorBox = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    padding: 8px 12px;
    gap: 10px;
    width: 100%;
    min-height: 30px;
    background: ${colors.errorPink1};
    border-radius: 4px;
    cursor: pointer;
`;

const WarningIcon = styled.img`
    width: 16px;
    height: 14px;
`;

interface Props {
    pageType?: "main" | "create" | "edit";
    leftColumnContents?: React.ReactNode;
    noHeader?: true;
}

const InstructionsPagePartial = ({
    pageType,
    leftColumnContents,
    noHeader
}: Props) => {
    // Library Methods ----------------------------------------------------------------------------------------------
    const dispatch = useDispatch();

    // Redux State --------------------------------------------------------------------------------------------------
    const { sortBy, filters } = useSelector((state: GlobalState) => {
        return state.ui.campaign;
    }, shallowEqual);

    const currentCampaignId = useSelector((state: GlobalState) => {
        return state.ui.campaign.currentCampaignId;
    });

    const {
        instructionsPageStep,
        instructionsPageType,
        currentInstructionsSetId,
        instructionSteps,
        instructionsSetSubmitStatus,
        instructionsSetsLoading,
        creatingInstruction
    } = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets;
    }, shallowEqual);

    const currentInstructionsSet = useSelector(getCurrentInstructionsSet);

    const linkForPlugs = useSelector((state: GlobalState) => {
        return state.links.linksForPlugs;
    });

    const { inAdminTools, currentOpenBuyId } = useSelector(
        (state: GlobalState) => {
            return state.ui.adminTools;
        }
    );
    const { selectedOpenBuy } = useSelector((state: GlobalState) => {
        return state.ui.createOfferWizard;
    });

    // Local State --------------------------------------------------------------------------------------------------
    const [dropResult, setDropResult] = useState<DropResult | null>(null);
    const [instructionsSetName, setInstructionsSetName] = useState(
        currentInstructionsSet?.name || ""
    );
    const [searchString, setSearchString] = useState("");

    // Local Constants ----------------------------------------------------------------------------------------------
    const formattedInstructionSteps = useConvertReduxDataToAPIData();

    const alreadyExistingChildSteps = generateListOfExistingChildSteps(
        currentInstructionsSet
    );

    // Local Functions ----------------------------------------------------------------------------------------------
    function handleOnDragStart(result: DragStart) {
        const dragTarget = result.draggableId.split("-")[0];

        const elementTitles = [...instructionElementTitles] as string[];

        const isCurrentDragTargetAnInstructionElement = elementTitles.includes(
            dragTarget
        );

        if (isCurrentDragTargetAnInstructionElement) {
            dispatch(setCurrentDragTargetType(dragTarget));
        } else {
            dispatch(setCurrentDragTargetType(""));
        }

        dispatch(
            setIsCurrentDragTargetAnInstructionElement(
                isCurrentDragTargetAnInstructionElement
            )
        );
    }

    function handleOnDragEnd(result: DropResult): void {
        setDropResult(result);

        const previousColumn = result.source.droppableId;
        const currentColumn = result.destination?.droppableId;
        const previousPosition = result.source.index;
        const currentPosition = result.destination?.index;

        handleInstructionStepDrag(
            previousColumn,
            currentColumn,
            previousPosition,
            currentPosition,
            dispatch
        );

        handleDraggingOfInstructionElementIntoAnInstructionStep(
            previousColumn,
            currentColumn,
            instructionSteps,
            result,
            dispatch,
            linkForPlugs,
            currentCampaignId
        );
    }

    function handleInstructionSetSubmit(event: EventSubmit): void {
        event.preventDefault();
        dispatch(
            setInstructionsSetSubmitStatus("updating data to send to api")
        );
    }

    function handleRightColumnRender() {
        if (instructionsPageType === "Main" && !currentCampaignId) {
            return <InstructionsFormXCard step="Campaign Selection" />;
        } else if (instructionsPageType === "Main" && !currentInstructionsSet) {
            return <InstructionsFormXCard step="Template Selection" />;
        } else {
            return (
                <InstructionsForm
                    dropResult={dropResult}
                    title={instructionsSetName}
                    setTitle={setInstructionsSetName}
                />
            );
        }
    }

    // Effects ------------------------------------------------------------------------------------------------------
    useEffect(() => {
        dispatch(fetchCampaigns(sortBy, filters));
    }, []);

    useEffect(() => {
        if (!currentCampaignId) {
            // dispatch(openInstructionsCampaignSelectionModal(true));
        } else {
            dispatch(fetchCampaign(currentCampaignId));
        }

        dispatch(setInstructionSteps([defaultInstructionStepState()]));

        // if (pageType !== "edit") {
        //     dispatch(setCurrentInstructionsSetId(null));
        // }
    }, [currentCampaignId]);

    useEffect(() => {
        if (currentInstructionsSetId) {
            dispatch(fetchInstructionSteps(currentInstructionsSetId, true));

            // if (pageType === "main") {
            //     dispatch(setInstructionsPageStep("Scratch"));
            // }
        }
    }, [currentInstructionsSetId]);
    // }, [pageType, currentInstructionsSetId]);

    useEffect(() => {
        if (currentInstructionsSet) {
            convertAPIDataToReduxData(currentInstructionsSet, dispatch);
            if (currentInstructionsSet.name) {
                setInstructionsSetName(currentInstructionsSet.name);
            }
        }
    }, [currentInstructionsSet]);

    useEffect(() => {
        if (!currentInstructionsSetId) setInstructionsSetName("");
    }, [currentInstructionsSetId]);

    useEffect(() => {
        if (
            !!currentCampaignId &&
            instructionsSetSubmitStatus === "submitting to api"
        ) {
            if (instructionsPageType === "Edit" && currentInstructionsSetId) {
                handleUpdateInstructionsSet(
                    dispatch,
                    currentInstructionsSetId,
                    instructionsSetName,
                    formattedInstructionSteps,
                    alreadyExistingChildSteps
                );
            } else {
                if (inAdminTools) {
                    const formattedInstructionsSet: InstructionsSetCreateData = {
                        name: instructionsSetName,
                        campaignId: currentCampaignId,
                        advertiserId: null,
                        duration: null,
                        instructionSteps: formattedInstructionSteps
                    };
                    if (currentOpenBuyId) {
                        dispatch(
                            createInstructionsSet(
                                formattedInstructionsSet,
                                false,
                                currentOpenBuyId
                            )
                        );
                        if (currentCampaignId) {
                            dispatch(
                                adminUpdateOpenBuy({
                                    id: currentOpenBuyId,
                                    campaignId: currentCampaignId
                                })
                            );
                        }
                        setInstructionsSetName("");
                    } else if (selectedOpenBuy) {
                        dispatch(
                            createInstructionsSet(
                                formattedInstructionsSet,
                                false,
                                selectedOpenBuy
                            )
                        );
                        setInstructionsSetName("");
                    }
                } else {
                    const formattedInstructionsSet: InstructionsSetCreateData = {
                        name: instructionsSetName,
                        campaignId: currentCampaignId,
                        advertiserId: null,
                        duration: null,
                        instructionSteps: formattedInstructionSteps
                    };
                    handleCreateInstructionsSet(
                        dispatch,
                        formattedInstructionsSet,
                        setInstructionsSetName
                    );
                }
            }
            dispatch(setInstructionsSetSubmitStatus("not submitting"));
        }
    }, [
        instructionsSetSubmitStatus,
        currentCampaignId,
        currentInstructionsSetId
    ]);

    // Used to update instructions.
    useEffect(() => {
        if (!!currentInstructionsSetId) {
            dispatch(fetchInstructionsSet(currentInstructionsSetId, true));
        }
    }, [currentInstructionsSetId, instructionsPageStep]);

    // JSX ----------------------------------------------------------------------------------------------------------
    if (instructionsSetsLoading && creatingInstruction) {
        return (
            <AdminToolsInstructionsLoadingContainer>
                <AdminToolsSelectionsLoading>
                    <SelectionHeaderLoading />
                    <SelectionHeaderLoading />
                    <SelectionHeaderLoading />
                </AdminToolsSelectionsLoading>
                <AdminToolsInstructionsFormLoading />
            </AdminToolsInstructionsLoadingContainer>
        );
    } else {
        return (
            <Container>
                {!noHeader && (
                    <Navbar marginBottom={24} mobileHasMarginBottom />
                )}

                <Main>
                    {/* {!noHeader && <MarketplacePagePartialHeader includeHeading />} */}
                    {!noHeader && <ChallengeToolsHeader />}

                    {!currentCampaignId && (
                        <ErrorBox
                            onClick={() =>
                                dispatch(
                                    setAdminCurrentWizardStep(
                                        "Set Challenge Details"
                                    )
                                )
                            }
                        >
                            <WarningIcon src={"/warning-red.svg"} />
                            <InfoText>
                                Please select a campaign in the challenge
                                details step.
                            </InfoText>
                        </ErrorBox>
                    )}

                    <Body>
                        <DragDropContext
                            onDragStart={handleOnDragStart}
                            onDragEnd={handleOnDragEnd}
                        >
                            <LeftColumn>
                                {instructionsPageStep === "Selection" ? (
                                    <MainLeftContent
                                        searchString={searchString}
                                        setSearchString={setSearchString}
                                        currentInstructionsSetId={
                                            currentInstructionsSetId
                                        }
                                    />
                                ) : (
                                    <PagePartialScratchStep />
                                )}
                            </LeftColumn>

                            <RightColumn onSubmit={handleInstructionSetSubmit}>
                                {handleRightColumnRender()}
                            </RightColumn>
                        </DragDropContext>
                    </Body>
                </Main>
            </Container>
        );
    }
};

export default InstructionsPagePartial;
