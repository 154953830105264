import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../modals/Modal";
import UpdateModal from "../../modals/UpdateModal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
    margin-bottom: 15px;
`;

const Error = styled.div`
    color: red;
    font-size: ${fonts.subtitle}px;
    margin-top: 10px;
`;

interface Props {
    prevEmail: string | null;
    updateUserFields: (fields: { [key: string]: any }) => void;
    error: string | null;
}

const EmailModal = ({ prevEmail, updateUserFields, error }: Props) => {
    const [email, setEmail] = useState(prevEmail || "");

    function emailIsValid(): boolean {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    function handleSubmit() {
        if (email.length > 0 && prevEmail !== email && emailIsValid()) {
            updateUserFields({ email });
        }
    }

    return (
        <Modal>
            <Main>
                <Title>Change Your Email</Title>
                <InputGroup>
                    <FormControl
                        placeholder={"Email"}
                        value={email}
                        onChange={({ currentTarget }: any) =>
                            setEmail(currentTarget.value)
                        }
                    />
                </InputGroup>
                {error ? <Error>{error}</Error> : null}
            </Main>
            <UpdateModal
                onUpdate={handleSubmit}
                active={
                    email.length > 0 && prevEmail !== email && emailIsValid()
                }
            />
        </Modal>
    );
};

export default EmailModal;
