import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CarouselInternalState } from "react-multi-carousel";
import styled from "styled-components";

const Dot = styled(motion.div)<{ active: boolean }>`
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: ${props =>
        props.active ? "rgba(144, 154, 156, 1)" : "rgba(144, 154, 156, 0.5)"};
    margin-bottom: 32px;
`;

const CarouselCustomDot = ({ carouselState, index, onClick, active }: any) => {
    const shouldHide =
        carouselState.currentSlide > index + 2 ||
        carouselState.currentSlide < index - 2;

    return (
        <AnimatePresence>
            {!shouldHide && (
                <Dot
                    key={index}
                    onClick={onClick}
                    active={active}
                    initial={{ scale: 0, opacity: 0, marginRight: 0 }}
                    animate={{ scale: 1, opacity: 1, marginRight: 5 }}
                    exit={{ scale: 0, opacity: 0, marginRight: 0 }}
                    transition={{
                        scale: {
                            duration: 0.3
                        }
                    }}
                />
            )}
        </AnimatePresence>
    );
};

export default CarouselCustomDot;
