import {
    AbstractPaymentType,
    OpenBuyPayment,
    PaymentConjunction,
    PaymentSetPayment,
    PendingOpenBuyPaymentItem,
    PendingOpenBuyPaymentsGroup
} from "../types";

export function convertOpenBuyPaymentsToPendingGroups(
    openBuyPayments: OpenBuyPayment[]
): PendingOpenBuyPaymentsGroup[] {
    const groupByInstructionStepId: {
        [instructionStepId: number]: PendingOpenBuyPaymentsGroup;
    } = {};
    openBuyPayments.forEach(openBuyPayment => {
        const {
            openBuyId,
            instructionStepId,
            creatorGroupId,
            PaymentSetBucket
        } = openBuyPayment;
        const { paymentConjunction } = PaymentSetBucket;
        if (!groupByInstructionStepId[instructionStepId]) {
            groupByInstructionStepId[instructionStepId] = {
                openBuyId,
                instructionStepId,
                paymentConjunction,
                base: [],
                creatorSpecific: {}
            };
        }

        const pendingOpenBuyPaymentItems = openBuyPayment.PaymentSetBucket.PaymentSetPayments.map(
            payment => convertPaymentSetPaymentToPending(payment)
        );
        if (!creatorGroupId) {
            groupByInstructionStepId[
                instructionStepId
            ].base = pendingOpenBuyPaymentItems;
        } else {
            groupByInstructionStepId[instructionStepId].creatorSpecific[
                creatorGroupId
            ] = pendingOpenBuyPaymentItems;
        }
    });

    return Object.values(groupByInstructionStepId);
}

function convertPaymentSetPaymentToPending(
    paymentSetPayment: PaymentSetPayment
): PendingOpenBuyPaymentItem {
    const { paymentId, amount, viewsMinimum } = paymentSetPayment;
    return {
        paymentId,
        amount: amount.toString(),
        viewsMinimum: viewsMinimum ? viewsMinimum.toString() : ""
    };
}

export function convertPendingGroupsToOpenBuyPayments(
    pendingOpenBuyPaymentsGroups: PendingOpenBuyPaymentsGroup[]
): OpenBuyPayment[] {
    const openBuyPayments: OpenBuyPayment[] = [];
    for (const pendingGroup of pendingOpenBuyPaymentsGroups) {
        const {
            openBuyId,
            instructionStepId,
            paymentConjunction,
            base,
            creatorSpecific
        } = pendingGroup;
        const creatorIdsArray: (string | null)[] = [
            null,
            ...Object.keys(creatorSpecific)
        ];
        for (const creatorGroupId of creatorIdsArray) {
            openBuyPayments.push({
                openBuyId,
                instructionStepId,
                creatorGroupId:
                    creatorGroupId === null ? null : parseInt(creatorGroupId),
                PaymentSetBucket: {
                    paymentConjunction,
                    PaymentSetPayments:
                        creatorGroupId === null
                            ? base.map(pendingItem =>
                                  convertPendingItemToPaymentSetPayment(
                                      pendingItem
                                  )
                              )
                            : creatorSpecific[
                                  parseInt(creatorGroupId)
                              ].map(pendingItem =>
                                  convertPendingItemToPaymentSetPayment(
                                      pendingItem
                                  )
                              )
                }
            });
        }
    }
    return openBuyPayments;
}

function convertPendingItemToPaymentSetPayment(
    pendingOpenBuyPaymentItem: PendingOpenBuyPaymentItem
): PaymentSetPayment {
    const { paymentId, amount, viewsMinimum } = pendingOpenBuyPaymentItem;
    return {
        paymentId,
        amount: parseFloat(amount),
        ...(viewsMinimum && { viewsMinimum: parseInt(viewsMinimum) })
    };
}

export function getAbstractPaymentTypeFromPendingOpenBuyPayment(
    paymentConjunction: PaymentConjunction,
    pendingOpenBuyPaymentItems: PendingOpenBuyPaymentItem[]
): AbstractPaymentType | null {
    if (
        paymentConjunction === PaymentConjunction.orGreater &&
        pendingOpenBuyPaymentItems.length > 0 &&
        pendingOpenBuyPaymentItems.every(
            payment => payment.viewsMinimum && payment.paymentId === 2
        )
    ) {
        return AbstractPaymentType.viewThresholds;
    } else if (pendingOpenBuyPaymentItems.length === 1) {
        if (!pendingOpenBuyPaymentItems[0].viewsMinimum) {
            const paymentType = pendingOpenBuyPaymentItems[0].paymentId;
            if (paymentType === 1) {
                return AbstractPaymentType.dollarsPerK;
            } else if (paymentType === 2) {
                return AbstractPaymentType.flatRate;
            }
        }
    }
    return null;
}

export function validationMessageForPendingOpenBuyPaymentsGroup(
    pendingOpenBuyPaymentsGroup: PendingOpenBuyPaymentsGroup
): string | null {
    const {
        paymentConjunction,
        base,
        creatorSpecific
    } = pendingOpenBuyPaymentsGroup;
    if (base.length === 0) {
        return "Please complete default payment";
    }
    const abstractPaymentType = getAbstractPaymentTypeFromPendingOpenBuyPayment(
        paymentConjunction,
        base
    );
    const allPaymentArrays = [base].concat(Object.values(creatorSpecific));
    for (const paymentArray of allPaymentArrays) {
        for (const payment of paymentArray) {
            const message = validationMessageForPendingOpenBuyPaymentItem(
                payment,
                abstractPaymentType === AbstractPaymentType.viewThresholds
            );
            if (message) {
                return message;
            }
        }
    }
    return null;
}

function validationMessageForPendingOpenBuyPaymentItem(
    pendingOpenBuyPaymentItem: PendingOpenBuyPaymentItem,
    isViewThresholds: boolean
): string | null {
    const { amount, viewsMinimum } = pendingOpenBuyPaymentItem;
    if (Number.isNaN(parseFloat(amount)) || parseFloat(amount) < 0) {
        return `Invalid payment amount value: ${amount}`;
    }
    if (
        isViewThresholds &&
        (Number.isNaN(parseInt(viewsMinimum)) || parseInt(viewsMinimum) === 0)
    ) {
        return `Invalid views threshold value: ${viewsMinimum}`;
    }
    return null;
}
