import React from "react";
import styled from "styled-components";
import DirectBuyPreview, {
    DirectBuyPreviewSize
} from "../components/DirectBuyPreview";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { colors } from "../../constants";
import { Shimmer, TextLoading } from "../../components/SkeletonLoader";
import { outerBorderRadiusForDirectBuyPreviewSize } from "../components/directBuyPreviewUtil";
import { DirectBuy } from "../modules/direct-buy/types";
import moment from "moment";

const ResultsLabel = styled.div`
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    padding: 2px 18px;
    color: ${colors.primaryGray};
`;

const ResultLabelLoading = styled(TextLoading)`
    margin: 2px 0px;
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 18px 18px;
    gap: 12px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    border-radius: 5px;
`;

const Date = styled.div`
    font-size: 14px;
    text-align: left;
    display: flex;
    flex-direction: row;
    color: ${colors.primaryGray};
    margin-bottom: 4px;
`;

const PreviewCell = styled.div`
    display: flex;
    height: 195px;
    width: 127px;
    &:first-child {
        & > * {
            padding-left: 0px;
        }
    }
    &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(
            260 / 168 * 100%
        ); // (height / width) * 100 = keeping aspect ratio
    }
`;

const Dot = styled.li`
    width: 4px;
    margin: 0px 10px;
`;

const DirectBuyPreviewLoading = styled.div<{
    size: DirectBuyPreviewSize;
}>`
    width: 111px;
    margin: 6px;
    height: calc(100% - 12px);
    border-radius: ${props =>
        outerBorderRadiusForDirectBuyPreviewSize(props.size)}px;
    ${Shimmer};
`;

interface Props {
    dispatch: Dispatch<any>;
    directBuysLoading: boolean;
    directBuys: { [id: string]: number[] };
    sortMode: string;
    buysLength: number;
    directBuysById: {
        [id: number]: DirectBuy;
    };
    handleCache: () => void;
}

interface State {
    maxItems: number;
}

class DirectBuysSearchPreviewGrid extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            maxItems: 12
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll() {
        const innerHeight = window.innerHeight;
        const scrollTopBody = document.getElementsByTagName("body")[0]
            .scrollTop;
        const scrollTopDoc = document.documentElement.scrollTop;
        const scrollTop = Math.max(scrollTopBody, scrollTopDoc) + 50;
        const offsetHeight = document.documentElement.offsetHeight;
        if (
            !this.props.directBuysLoading &&
            this.state.maxItems < this.props.buysLength &&
            innerHeight + scrollTop >= offsetHeight &&
            document.body.style.position !== "fixed"
        ) {
            this.setState({ maxItems: this.state.maxItems + 12 });
        }
    }

    render() {
        if (this.props.directBuysLoading) {
            return (
                <List>
                    <ResultLabelLoading width={20} />
                    {Array.from(Array(3)).map((a, index) => (
                        <div key={`loader${index}`}>
                            <ResultLabelLoading width={20} />
                            <Section>
                                {Array.from(Array(6)).map((a, index) => (
                                    <PreviewCell key={`loadcell${index}`}>
                                        <DirectBuyPreviewLoading
                                            size={DirectBuyPreviewSize.large}
                                        />
                                    </PreviewCell>
                                ))}
                            </Section>
                        </div>
                    ))}
                </List>
            );
        } else {
            const items = this.props.directBuys;
            const { sortMode, dispatch } = this.props;

            return (
                <>
                    <ResultsLabel>
                        {this.props.buysLength} RESULT
                        {this.props.buysLength !== 1 && "S"}
                    </ResultsLabel>
                    <List>
                        {Object.keys(items)
                            .sort((a, b) =>
                                moment(a) > moment(b)
                                    ? sortMode === "Newest"
                                        ? -1
                                        : 1
                                    : sortMode === "Newest"
                                    ? 1
                                    : -1
                            )
                            .map((date, index) => (
                                <div key={`section${index}`}>
                                    <Date>
                                        {moment(date).format("dddd")}
                                        <Dot />
                                        {moment(date).format("MM/DD")}
                                    </Date>
                                    <Section>
                                        {items[date].map(
                                            (directBuyId: number) => {
                                                return (
                                                    <PreviewCell
                                                        key={directBuyId}
                                                    >
                                                        <DirectBuyPreview
                                                            directBuyId={
                                                                directBuyId
                                                            }
                                                            size={
                                                                DirectBuyPreviewSize.large
                                                            }
                                                            onClick={() => {
                                                                this.props.handleCache();
                                                                dispatch(
                                                                    push(
                                                                        `/buys/direct/${directBuyId}`
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </PreviewCell>
                                                );
                                            }
                                        )}
                                    </Section>
                                </div>
                            ))}
                    </List>
                </>
            );
        }
    }
}

export default DirectBuysSearchPreviewGrid;
