import React from "react";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import SpinLoader from "src/components/SpinLoader";
import { colors } from "src/constants";
import ShadeScreen from "src/media/components/ShadeScreen";
import { GlobalState } from "src/reducers";
import styled from "styled-components";

const Main = styled.div<{ inBuy?: boolean }>`
    display: flex;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    width: 180px;
    position: relative;
    height: 250px;
    background-color: ${colors.black};
    cursor: pointer;
    ${props =>
            props.inBuy &&
            `
        position: absolute;
        width: 103px;
        height: 162px;
        top: 10px;
        right: 10px;
        z-index: 10;
    `}
        :hover {
        border: ${props =>
            !props.inBuy && `3px solid ${colors.primaryBlueLight}`};
    }
`;

const Picture = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const Info = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: white;
    margin-top: auto;
`;

const HideButton = styled.img`
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 10;
    border-radius: 100px;
    top: 5px;
    right: 5px;
    :hover {
        border: 2px solid ${colors.primaryRed};
    }
`;

interface Props {
    src: string;
    inBuy?: boolean;
    info?: string;
    status?: string;
    onClick?: () => void;
    onHide?: (src: string) => void;
    screenshotId?: number;
    index?: number;
}

const PublicBuysScreenshotPreview = ({
    src,
    status,
    info,
    onClick,
    inBuy,
    onHide,
    screenshotId,
    index
}: Props) => {
    const hidden = useSelector((state: GlobalState) => state.publicBuys.hidden);
    const dispatch = useDispatch();

    function snapToTopRight<DraggableStyle>(
        style: DraggableStyle,
        snapshot: DraggableStateSnapshot
    ) {
        const dropAnimationIsUndefined = !snapshot.isDropAnimating;
        if (dropAnimationIsUndefined) return style;

        if (!!snapshot.dropAnimation) {
            const { moveTo } = snapshot.dropAnimation;
            const translate = `translate(${moveTo.x + 60}px, ${moveTo.y +
                10}px)`;
            return {
                ...style,
                transform: `${translate}`
            };
        }
    }

    if (index === 0 || !!index) {
        return (
            <Draggable
                key={`public-buys-screenshot-preview-${index}`}
                draggableId={`public-buys-screenshot-preview-draggableid-${screenshotId ||
                    index}`}
                index={index}
            >
                {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                ) => (
                    <Main
                        onClick={onClick}
                        inBuy={inBuy}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={snapToTopRight(
                            provided.draggableProps.style,
                            snapshot
                        )}
                    >
                        {onHide && (
                            <HideButton
                                src="/x_bg@3x.svg"
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onHide(src);
                                }}
                            />
                        )}
                        <Picture src={src} />
                        <ShadeScreen opacity={0.2}>
                            <Info>{info}</Info>
                            {status === "loading" && (
                                <SpinLoader size={15} color={"white"} />
                            )}
                            {status === "error" && (
                                <img
                                    src={"/black_close.svg"}
                                    style={{ color: "red" }}
                                />
                            )}
                        </ShadeScreen>
                    </Main>
                )}
            </Draggable>
        );
    } else {
        return (
            <Main onClick={onClick} inBuy={inBuy}>
                {onHide && (
                    <HideButton
                        src="/x_bg@3x.svg"
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            onHide(src);
                        }}
                    />
                )}
                <Picture src={src} />
                <ShadeScreen opacity={0.2}>
                    <Info>{info}</Info>
                    {status === "loading" && (
                        <SpinLoader size={15} color={"white"} />
                    )}
                    {status === "error" && (
                        <img
                            src={"/black_close.svg"}
                            style={{ color: "red" }}
                        />
                    )}
                </ShadeScreen>
            </Main>
        );
    }
};
export default PublicBuysScreenshotPreview;
