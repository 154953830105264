import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SimpleNavbar from "src/components/SimpleNavbar";
import FullScreenModal from "src/modals/FullScreenModal";
import { ReactComponent as Cross } from "src/components/icons/cross.svg";
import { colors } from "src/constants";
import NavButton from "src/components/NavButton";
import { FlexContent } from "src/components/CommonStyles";
import { BigBlueButton } from "src/profile/components/Buttons";
import MediaFilterOwner from "./MediaFilterOwner";
import MediaFilterMediaType from "./MediaFilterMediaType";
import MediaFilterTime from "./MediaFilterTime";
import MediaFilterPlacement from "./MediaFilterPlacement";
import MediaFilterCampaign from "./MediaFilterCampaign";
import MediaFilterDuration from "./MediaFilterDuration";
import { MediaFilters } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { setMediaFilters } from "src/pagination/media/actions";
import { GlobalState } from "src/reducers";
import { initialMediaFilters } from "src/pagination/media/reducer";
import MediaFilterAdmin from "./MediaFilterAdmin";
import { selectAdminStatus } from "src/auth/selectors";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const FilterList = styled(FlexContent)`
    display: flex;
    flex-direction: column;
    padding: 10px 18px;

    & > * ~ * {
        margin-top: 20px;
    }
`;
const CrossButton = styled(Cross)`
    cursor: pointer;
    user-select: none;
`;

const Error = styled.div`
    color: ${colors.primaryRed};
    font-size: 12px;
    margin-bottom: 4px;
`;

const ConfirmButtonWrapper = styled.div`
    border-top: 1px solid ${colors.mediumTeal};
    padding: 12px 18px 24px;
    margin-top: auto;
`;

const ConfirmButton = styled(BigBlueButton)`
    font-weight: 500;

    &:disabled {
        opacity: 0.5;
    }
`;

interface Props {
    closeModal: () => void;
}

const MediaFilterModal = ({ closeModal }: Props) => {
    const dispatch = useDispatch();
    const promoting = useSelector(
        (state: GlobalState) => state.ui.promote.promoting
    );
    const isScriptModal = useSelector(
        (state: GlobalState) => state.ui.script.scriptModal
    );
    const initialFilters = useSelector(
        (state: GlobalState) => state.pagination.medias.filters
    );
    const isAdmin = useSelector(selectAdminStatus);
    const [filters, setFilters] = useState<MediaFilters>(initialFilters);
    const [error, setError] = useState("");

    function setGlobalMediaFilters() {
        dispatch(setMediaFilters(filters));
        closeModal();
    }

    useEffect(() => {
        if (parseInt(filters.duration.lt) < parseInt(filters.duration.gte)) {
            setError("Media duration incorrect");
        } else {
            setError("");
        }
    }, [filters.duration]);

    return (
        <FullScreenModal
            closeModal={closeModal}
            keepFrozenOnClose={!!promoting}
        >
            <Main>
                <SimpleNavbar
                    title="Filter"
                    left={
                        <CrossButton
                            fill={colors.primaryGray}
                            onClick={closeModal}
                        />
                    }
                    right={
                        <NavButton
                            text="Reset"
                            onClick={() => setFilters(initialMediaFilters)}
                        />
                    }
                />
                <FilterList>
                    <MediaFilterOwner
                        setFilters={setFilters}
                        filters={filters}
                    />
                    <MediaFilterTime
                        setFilters={setFilters}
                        filters={filters}
                    />
                    {!promoting && !isScriptModal && (
                        <MediaFilterCampaign
                            setFilters={setFilters}
                            filters={filters}
                        />
                    )}
                    <MediaFilterMediaType
                        setFilters={setFilters}
                        filters={filters}
                    />
                    <MediaFilterDuration
                        setFilters={setFilters}
                        filters={filters}
                    />
                    <MediaFilterPlacement
                        setFilters={setFilters}
                        filters={filters}
                    />
                    {isAdmin && (
                        <MediaFilterAdmin
                            setFilters={setFilters}
                            filters={filters}
                        />
                    )}
                </FilterList>

                <ConfirmButtonWrapper>
                    {error && <Error>{error}</Error>}
                    <ConfirmButton
                        onClick={setGlobalMediaFilters}
                        disabled={!!error}
                    >
                        Apply
                    </ConfirmButton>
                </ConfirmButtonWrapper>
            </Main>
        </FullScreenModal>
    );
};

export default MediaFilterModal;
