import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { get } from "./Api";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15vh;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 500;
`;
const Pluggy = styled.img`
    height: 200px;
    width: 200px;
`;

const ShortLink = ({ code }: { code: string }) => {
    const [state, setState] = useState("fetching");
    const [message, setMessage] = useState("");
    useEffect(() => {
        get("/l/url", { code })
            .then(res => {
                if (res.data.data.url) setState("redirecting");
                window.location.href = res.data.data.url;
            })
            .catch(err => {
                setState("error");
                setMessage(err.response?.data?.error?.message || "");
            });
    }, []);

    const getTitle = () => {
        switch (state) {
            case "redirecting":
                return "Redirecting you to the link";
            case "error":
                return `Looks like there is an issue with your code (${message}). Be sure you have the correct link.`;
            default:
                return "Processing your code now";
        }
    };

    return (
        <Container>
            <Pluggy src={"/plug/pluggy_work_gif.gif"} />
            <Title>{getTitle()}</Title>
        </Container>
    );
};

export default ShortLink;
