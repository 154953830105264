import React, { ReactNode } from "react";
import { colors } from "src/constants";
import styled from "styled-components";
import { FlexVerticallyCentered } from "../CommonStyles";
import ProfilePhoto from "../ProfilePhoto";

const Main = styled.div`
    ${FlexVerticallyCentered}
    cursor: pointer;
    user-select: none;
    text-align: left;
`;

const Title = styled.div`
    font-size: 13px;
    font-weight: 500;
    line-height: 15.51px;
`;

const Subtitle = styled.div`
    font-size: 12px;
    color: ${colors.primaryGray};
    margin-top: 6px;
    line-height: 14.32px;
    min-height: 14.32px;

    span {
        font-weight: 700;
    }
`;

const RightWrapper = styled.div`
    margin-left: auto;
`;

interface Props {
    title: string;
    subtitle: string;
    size: number;
    profilePhotoUrl?: string | null;
    status?: string;
    right?: ReactNode;
}

const GenericUserCell = ({
    title,
    subtitle,
    size,
    profilePhotoUrl,
    status,
    right
}: Props) => {
    return (
        <Main>
            <ProfilePhoto
                photoSize={size}
                profilePhotoUrl={profilePhotoUrl || null}
                style={{ marginRight: 12 }}
                status={status}
            />
            <div>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </div>
            {right && <RightWrapper>{right}</RightWrapper>}
        </Main>
    );
};

export default GenericUserCell;
