import {
    compareAsc,
    formatDuration,
    fromUnixTime,
    intervalToDuration
} from "date-fns";
import createCachedSelector from "re-reselect";
import { getAuthState } from "src/auth/selectors";
import { GlobalState } from "src/reducers";
import { selectConversationWithId, selectOwnChatProfile } from "../selectors";
import { GroupUserSettings } from "../types/GroupUserSettings";

export const getChatGroupTitle = (
    state: GlobalState,
    conversationId: string
) => {
    const conversation = selectConversationWithId(state, conversationId);
    if (conversation?.otherId) return null;

    if (conversation)
        return {
            displayName: conversation.displayName,
            imageUrl: conversation.imageUrl,
            memberCount: conversation.participantIds?.length || 0,
            groupDescription: conversation.groupDescription,
            participantIds: conversation.participantIds
        };
};

const selectGroupUserSettings = (state: GlobalState) =>
    state.entities.groupUserSettings;

const chatProfileState = (state: GlobalState) => state.entities.chatProfiles;

export const selectGroupUserSettingsById = (
    state: GlobalState,
    conversationId: string,
    userId: string
) => {
    const groupUserSettingsState = selectGroupUserSettings(state);
    const userSettings = groupUserSettingsState[conversationId][userId];

    let isMuted: null | string = null;
    if (
        userSettings.isMutedUntil &&
        compareAsc(fromUnixTime(userSettings.isMutedUntil), new Date()) > 0
    ) {
        const duration = intervalToDuration({
            start: fromUnixTime(userSettings.isMutedUntil),
            end: new Date()
        });
        isMuted = `Unmute (${formatDuration(duration, {
            format: ["hours", "minutes"]
        })} left)`;
    } else if (userSettings.isMutedIndefinitely) {
        isMuted = "Unmute";
    }
    return { ...userSettings, isMuted };
};

export const selectConversationOwnAdminStatus = (
    state: GlobalState,
    conversationId: string
) => {
    const { userId } = getAuthState(state);
    if (userId) {
        const groupUserSettings = selectGroupUserSettings(state)[
            conversationId
        ];
        return groupUserSettings?.[userId].isAdmin;
    }
    return false;
};

export const selectConversationOwnPauseDuration = (
    state: GlobalState,
    conversationId: string
) => {
    let status = "";
    const chatProfile = selectOwnChatProfile(state);
    if (chatProfile) {
        const chatProfileConversation = chatProfile.conversations.find(
            conversation => conversation.conversationId === conversationId
        );

        const pausedUntil = chatProfileConversation?.notificationsPausedUntil;
        if (
            pausedUntil &&
            compareAsc(fromUnixTime(pausedUntil), new Date()) > 0
        ) {
            const duration = intervalToDuration({
                start: fromUnixTime(pausedUntil),
                end: new Date()
            });
            status =
                formatDuration(duration, { format: ["hours", "minutes"] }) +
                " left";
        }

        if (chatProfileConversation?.notificationsPausedIndefinitely) {
            status = "Paused";
        }
    }
    return status;
};

const conversationsEntitiesState = (state: GlobalState) =>
    state.entities.conversations;

export const getGroupMembers = createCachedSelector(
    selectGroupUserSettings,
    conversationsEntitiesState,
    chatProfileState,
    (
        state: GlobalState,
        conversationId: string,
        searchString: string,
        isAdmin?: boolean
    ) => ({
        conversationId,
        searchString,
        isAdmin
    }),
    (
        groupUserSettings,
        conversationEntities,
        chatProfiles,
        { conversationId, searchString, isAdmin }
    ) => {
        const conversationUsers = groupUserSettings[conversationId];
        const conversationParticipants =
            conversationEntities.byId[conversationId]?.participantIds;
        if (conversationParticipants && conversationUsers) {
            const users = conversationParticipants.map(
                participantId => conversationUsers[participantId]
            );
            // const users: GroupUserSettings[] = Object.values(conversationUsers);
            const filteredUsers = users.filter(
                user => !isAdmin || user.isAdmin
            );
            const userCount = filteredUsers.length;
            const withProfile = filteredUsers.map(user => ({
                ...user,
                profile: chatProfiles[user.userId]
            }));

            let result;

            if (searchString) {
                result = withProfile.filter(user =>
                    user.profile.username
                        .toLowerCase()
                        .includes(searchString.toLowerCase())
                );
            } else {
                result = withProfile;
            }
            return { users: result, userCount };
        }
        return { users: [], userCount: 0 };
    }
)((state, conversationId, isAdmin) => (isAdmin ? "1" : "0"));
