import React, { useState } from "react";
import { DirectBuy } from "../modules/direct-buy/types";
import styled from "styled-components";
import Divider from "../../components/Divider";
import TwoButtonFooter from "../../components/TwoButtonFooter";
import { directBuyDetailsModal } from "../../ui/direct-buys/actions";
import { DirectBuyDetailsModalState } from "../../ui/direct-buys/reducer";
import { post } from "../../Api";
import { updateDirectBuy } from "../actions";
import { addNotification } from "../../ui/notifications/actions";
import { colors, maxWidthDesktop } from "../../constants";
import { errorToString } from "../../util";
import { useDispatch } from "react-redux";

interface StyleProps {
    sharedHeight: number;
}

const Main = styled.div<StyleProps>`
    position: relative;
    height: ${props => props.sharedHeight}px;
`;

const Fixed = styled.div<StyleProps>`
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    height: ${props => props.sharedHeight}px;
    position: fixed;
    bottom: 0;
    background: white;
`;

interface Props {
    directBuy?: DirectBuy;
}

const DirectBuyDetailsAcceptDecline = ({ directBuy }: Props) => {
    const dispatch = useDispatch();
    const [acceptLoading, setAcceptLoading] = useState(false);
    if (!directBuy) {
        return null;
    }
    const sharedHeight = 100;
    return (
        <Main sharedHeight={sharedHeight}>
            <Fixed sharedHeight={sharedHeight}>
                <Divider />
                <TwoButtonFooter
                    leftText="Decline"
                    leftCallback={() => {
                        dispatch(
                            directBuyDetailsModal(
                                DirectBuyDetailsModalState.rejectionReason
                            )
                        );
                    }}
                    rightText="Accept"
                    rightCallback={() => {
                        setAcceptLoading(true);
                        post(`/v1/directBuy/${directBuy.id}/offer`, {
                            offerStatus: "approved"
                        })
                            .then(({ data }) => {
                                dispatch(updateDirectBuy(data.data));
                            })
                            .catch(error => {
                                dispatch(
                                    addNotification(
                                        `Unable to accept offer: ${errorToString(
                                            error
                                        )}`,
                                        colors.primaryRed
                                    )
                                );
                                setAcceptLoading(false);
                            });
                    }}
                    rightLoading={acceptLoading}
                />
            </Fixed>
        </Main>
    );
};

export default DirectBuyDetailsAcceptDecline;
