import { OldMedia } from "../../media/types";
import { NormalizedAction } from "../../types";
import {
    CREATE_CAMPAIGN_CUSTOM_LINK,
    FETCH_CAMPAIGN_CUSTOM_LINKS
} from "./actions";

export interface CustomLinksEntitiesState {
    [id: number]: OldMedia;
}

const initialCustomLinksState: CustomLinksEntitiesState = {};

const customLinksEntitiesReducer = (
    state = initialCustomLinksState,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_CAMPAIGN_CUSTOM_LINKS.SUCCESS:
            return { ...state, ...action.response.entities.customLinks };
        case CREATE_CAMPAIGN_CUSTOM_LINK.SUCCESS:
            return { ...state, ...action.response.entities.customLinks };
        default:
            return state;
    }
};

export default customLinksEntitiesReducer;
