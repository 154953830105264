import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Top, Middle, Bottom, Form, BlueButton } from "./commonComponents";
import { SmallPillButton } from "../../components/styledComponents";
import { ClaimParams, claimPrize } from "src/campaigns/contests/actions";
import { fonts } from "src/constants";

const SpaceBetween = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const Title = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: bold;
    margin-bottom: 8px;
`;

const Info = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: #909a9c;
`;

interface Props {
    prizeId: number;
    type: "prize" | "cash" | null;
    name: string;
    phone: string;
    email: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    info: string;
    editContact: () => void;
    editShipping: () => void;
    editOther: () => void;
    claimPrize: (params: ClaimParams) => void;
}

const ConfirmForm = ({
    prizeId,
    type,
    name,
    phone,
    email,
    address,
    city,
    state,
    zip,
    info,
    editContact,
    editShipping,
    editOther,
    claimPrize
}: Props) => {
    function submit() {
        if (type) {
            claimPrize({
                prizeId,
                contact_info: {
                    full_name: name,
                    phone_number: phone,
                    email
                },
                shipping_info: {
                    street_address: address,
                    city,
                    state,
                    zip_code: zip
                },
                additional_info: info,
                claimed_prize_type: type
            });
        }
    }

    return (
        <>
            <Top>
                <div>
                    <strong>Confirm Information</strong>
                </div>
                <div>
                    Review the information and make sure that everything is
                    correct. Click the edit button to make changes.
                </div>
            </Top>
            <Middle>
                <Form>
                    <SpaceBetween>
                        <div>
                            <Title>Contact Information</Title>
                            <Info>{name}</Info>
                            <Info>{phone}</Info>
                            <Info>{email}</Info>
                        </div>
                        <SmallPillButton onClick={editContact}>
                            Edit
                        </SmallPillButton>
                    </SpaceBetween>
                </Form>
                <Form>
                    <SpaceBetween>
                        <div>
                            <Title>Shipping Information</Title>
                            <Info>{address}</Info>
                            <Info>
                                {city}, {state}
                            </Info>
                            <Info>{zip}</Info>
                        </div>
                        <SmallPillButton onClick={editShipping}>
                            Edit
                        </SmallPillButton>
                    </SpaceBetween>
                </Form>
                <Form>
                    <SpaceBetween>
                        <div>
                            <Title>Additional Information</Title>
                            <Info>{info}</Info>
                        </div>
                        <SmallPillButton onClick={editOther}>
                            Edit
                        </SmallPillButton>
                    </SpaceBetween>
                </Form>
            </Middle>
            <Bottom>
                <BlueButton onClick={submit}>Confirm</BlueButton>
            </Bottom>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    claimPrize: (params: ClaimParams) => dispatch(claimPrize(params))
});

export default connect(null, mapDispatchToProps)(ConfirmForm);
