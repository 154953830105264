import { NormalizedCreatorGroupsResponse } from "./actions.creator-groups";
import { normalize } from "normalizr";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "src/types";
import {
    fetchCreatorOrCreatorsBasedOnSocialAccountIdFailure,
    fetchCreatorsFailure,
    fetchCreatorsSuccess,
    fetchCreatorOrCreatorsBasedOnSocialAccountIdSuccess,
    FETCH_CREATOR_OR_CREATORS_BASED_ON_SOCIAL_ACCOUNT_ID,
    FETCH_CREATORS
} from "./actions.creator-search";
import { creatorsList } from "./schema";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { post } from "src/Api";
import {
    setCreatorSearchLoading,
    setCreatorsMaxPages,
    setCreatorTotalHits
} from "src/ui/creators/actions";

function* fetchCreatorsRequest(action: Action) {
    try {
        yield put(setCreatorSearchLoading(true));

        const endpoint = "/v1/search";
        const { data, sortingOrFiltering } = action.payload;
        const response = yield call(post, endpoint, data);

        yield put(
            fetchCreatorsSuccess(
                normalize(response.data.data[0].hits, creatorsList),
                sortingOrFiltering
            )
        );
        yield put(setCreatorsMaxPages(response.data.data[0].pages));
        yield put(setCreatorTotalHits(response.data.data[0].nbHits));
        yield put(setCreatorSearchLoading(false));
    } catch (error) {
        console.log(error);
        yield put(fetchCreatorsFailure("Failed to retrieve creators."));
        yield put(
            setPillNotificationText("Failed to retrieve creators.", "danger")
        );
    }
}

function generateSocialAccountIdSearchQuery(socialAccountId: number) {
    return {
        index: "creator-search",
        params: {
            hitsPerPage: 1,
            page: 0,
            filter: [
                {
                    term: {
                        social_account_id: socialAccountId
                    }
                }
            ]
        }
    };
}

function* fetchCreatorOrCreatorsBasedOnSocialAccountIdRequest(action: Action) {
    try {
        const endpoint = "/v1/search";
        const { socialAccountIds } = action.payload;

        const queries = socialAccountIds.map((socialAccountId: number) => {
            return generateSocialAccountIdSearchQuery(socialAccountId);
        });
        const data = { query: queries };

        const response = yield call(post, endpoint, data);

        const creators = response.data.data.map((data: any) => {
            return data.hits[0];
        });

        yield put(
            fetchCreatorOrCreatorsBasedOnSocialAccountIdSuccess(creators)
        );
    } catch (error) {
        console.log(error);
        yield put(
            fetchCreatorOrCreatorsBasedOnSocialAccountIdFailure(
                "Failed to retrieve creator."
            )
        );
        yield put(
            setPillNotificationText("Failed to retrieve creator.", "danger")
        );
    }
}

export default function* creatorSearchSaga() {
    yield all([
        takeEvery(FETCH_CREATORS.REQUEST, fetchCreatorsRequest),
        takeEvery(
            FETCH_CREATOR_OR_CREATORS_BASED_ON_SOCIAL_ACCOUNT_ID.REQUEST,
            fetchCreatorOrCreatorsBasedOnSocialAccountIdRequest
        )
    ]);
}
