import { push } from "connected-react-router";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { get } from "src/Api";
import ItemSelector, { ItemSelectorOptions } from "src/components/ItemSelector";
import SearchBar from "src/components/SearchBar";
import { addNotification } from "src/ui/notifications/actions";
import styled from "styled-components";

interface Props {
    selectPublisher: (id: number, username: string) => void;
}

const PublisherSelector = ({}: Props) => {
    const [publishers, setPublishers] = useState<ItemSelectorOptions[]>([]);
    const dispatch = useDispatch();

    const selectPublisher = (id: number) => {
        get("/v1/directBuy/code", { publisherId: id }).then(res => {
            if (res.data.data.url) window.open(res.data.data.url);
            else
                dispatch(
                    addNotification(
                        "Error accessing publisher screenshot uploader",
                        "red"
                    )
                );
        });
    };
    useEffect(() => {
        get("/v1/directBuy/publishers", {}).then(res => {
            let options: ItemSelectorOptions[] = [];
            res.data.data.map((publisher: any) => {
                options.push({
                    id: publisher.id,
                    value: publisher.id,
                    title: `${publisher.username} (${publisher.id})`,
                    subtitle: `${publisher.screenshots} screenshots needed`,
                    image:
                        publisher.profile_photo_url || "/plug/pluggy_spy.svg",
                    onSelect: () => selectPublisher(publisher.id)
                });
            });
            setPublishers(options);
        });
    }, []);

    return (
        <ItemSelector
            options={publishers}
            length={publishers.length}
            type="single"
        />
    );
};
export default PublisherSelector;
