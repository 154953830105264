import React, { useState } from "react";
import { useEffect } from "react";

export function useCloseModalWhenClickedOutside<Dependencies>(
    ref: React.MutableRefObject<any>,
    modalOpen: boolean,
    closeModal: () => void,
    dependencies: Dependencies[],
    onCleanUp?: () => void
) {
    // For the `ref` parameter, create a ref using `useRef(null)` and pass that as the argument.

    useEffect(() => {
        function closeModalOnClickOutside(event: any): void {
            if (
                ref.current &&
                modalOpen &&
                !ref.current.contains(event.target)
            ) {
                closeModal();
            }
        }

        document.addEventListener("mousedown", closeModalOnClickOutside);

        return function cleanUp(): void {
            document.removeEventListener("mousedown", closeModalOnClickOutside);
            if (onCleanUp) onCleanUp();
        };
    }, [...dependencies]);
}

// Tip: Use this hook to create a debounced state value and pass it as a dependency
//      into a useEffect(), etc. to debounce the logic held within useEffect(), etc.!
export function useDebounce<ValueToDebounce>(
    valueToDebounce: ValueToDebounce,
    delay: number = 300
): ValueToDebounce {
    const [debouncedValue, setDebouncedValue] = useState(valueToDebounce);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(valueToDebounce);
        }, delay);

        return function cleanUp(): void {
            clearTimeout(handler);
        };
    }, [valueToDebounce]);

    return debouncedValue;
}
