import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FormHeading from "./FormHeading";
import Dropdown from "src/marketplace/payment-sets/components/PaymentSetDropdown";
import { BlueButton } from "src/profile/components/Buttons";
import { patch } from "src/Api";
import { useDispatch } from "react-redux";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { OpenBuyInstructionStep } from "src/buys/modules/open-buys/types";
import { requestOpenBuyById } from "src/buys/actions";
import { SmallTitle } from "src/marketplace/payment-sets/components/styledComponents";
import { InstructionStep } from "src/marketplace/instructions-sets/types";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 16px;
    text-align: left;
    gap: 12px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const SectionTitle = styled(SmallTitle)`
    text-transform: uppercase;
`;

const StyledBlueButton = styled(BlueButton)`
    padding: 8px 12px;
    font-size: 12px;
    align-self: right;
`;

export const PaymentConjunction: { [key: string]: string } = {
    AND: "All Payments",
    OR: "Greatest Payment"
};

export const PayoutTiming: { [key: string]: string } = {
    on_approval: "When Approved",
    realtime: "Use Scraper"
};

interface Props {
    openBuyId: number;
    currentStep?: InstructionStep;
}

const PaymentAssignment = ({ openBuyId, currentStep }: Props) => {
    const dispatch = useDispatch();

    const [selectedConjunction, setConjunction] = useState(
        currentStep ? currentStep.paymentConjunction : ""
    );
    const [selectedTiming, setTiming] = useState(
        currentStep ? currentStep.payoutTiming : ""
    );

    useEffect(() => {
        if (currentStep) {
            currentStep.paymentConjunction &&
                setConjunction(currentStep.paymentConjunction);
            currentStep.payoutTiming && setTiming(currentStep.payoutTiming);
        }
    }, [currentStep?.id]);

    const handleSubmit = () => {
        patch(`/v1/instruction/step/${currentStep?.id}`, {
            paymentConjunction: selectedConjunction,
            payoutTiming: selectedTiming
        })
            .then(res => {
                dispatch(requestOpenBuyById(openBuyId));
                dispatch(
                    setPillNotificationText(
                        "Payment Mode/Timing on Instruction Step sucessfully updated",
                        "success",
                        3500
                    )
                );
            })
            .catch(err => {
                dispatch(
                    setPillNotificationText(
                        "Payment Mode/Timing on Instruction Step failed to be updated",
                        "danger",
                        3500
                    )
                );
            });
    };

    return (
        <Wrapper>
            <FormHeading text="Modify Payment Mode & Timing" />
            <Section>
                <SectionTitle>Payment Mode</SectionTitle>
                <Dropdown
                    itemList={Object.keys(PaymentConjunction)}
                    selected={selectedConjunction}
                    displayName={
                        PaymentConjunction[selectedConjunction as string]
                    }
                    displayEmptyState={"Select a payment mode"}
                    handleOnClick={item => setConjunction(item)}
                    getItemName={item => PaymentConjunction[item]}
                />
            </Section>

            <Section>
                <SectionTitle>Payout Timing</SectionTitle>
                <Dropdown
                    itemList={Object.keys(PayoutTiming)}
                    selected={selectedTiming}
                    displayName={PayoutTiming[selectedTiming as string]}
                    displayEmptyState={"Select payout timing"}
                    handleOnClick={item => setTiming(item)}
                    getItemName={item => PayoutTiming[item]}
                />
            </Section>

            <StyledBlueButton onClick={handleSubmit}>Submit</StyledBlueButton>
        </Wrapper>
    );
};

export default PaymentAssignment;
