import React from "react";
import { Connection } from "../../types";
import { PostRecommendationParams } from "../../actions";
import { shortenNumber } from "../../../util";
import { colors, fonts } from "../../../constants";
import styled from "styled-components";
import ProfilePhoto from "../../../components/ProfilePhoto";
import HideOverflow from "../../../components/HideOverflow";
import Platforms from "../../../social-accounts/Platforms";
import VerticallyCentered from "../../../components/VerticallyCentered";
import RecommendationSocialRow from "./RecommendationSocialRow";

const Main = styled.div<{ nux?: true }>`
    padding: 12px;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    line-height: 1.5;

    & + & {
        margin-top: 8px;
    }

    ${props =>
        props.nux &&
        `border: none;
         background-color: ${colors.tertiaryGreen};`};
`;

const TextContainer = styled.div`
    width: calc(100% - 158px);
`;

const Title = styled(HideOverflow)`
    font-size: ${fonts.smallTitle}px;
    width: 100%;
`;

const NoUsername = styled(Title)`
    font-style: italic;
    font-weight: 300;
    color: ${colors.primaryGray};
`;

const Subtitle = styled(HideOverflow)<{ nux?: true }>`
    font-size: 12px;
    width: 100%;
    color: ${props => (props.nux ? "white" : colors.primaryGray)};
`;

const GenericButton = styled(VerticallyCentered)`
    height: 28px;
    width: 100px;
    border-radius: 4px;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
`;

const SolidButton = styled(GenericButton)<{ nux?: true }>`
    color: ${props => (props.nux ? colors.primaryGreen : "white")};
    background-color: ${props => (props.nux ? "white" : colors.primaryBlue)};
    transition: 0.1s ease-in;
    cursor: pointer;

    :hover {
        background-color: ${props => {
            if (props.nux) {
                return colors.white;
            } else if (props.theme.isMobile) {
                return colors.primaryBlue;
            } else {
                return colors.primaryBlueDark;
            }
        }};
    }
`;

const OutlineButton = styled(GenericButton)<{ nux?: true }>`
    color: ${props => (props.nux ? "white" : colors.primaryGray)};
    border: 1px solid ${props => (props.nux ? "white" : colors.mediumTeal)};
`;

interface Props {
    recommendation: Connection;
    postRecommendation: (params: PostRecommendationParams) => void;
    profileCallback: VoidFunction;
    nux?: true;
}

const RecommendationExpandedCell = ({
    recommendation,
    postRecommendation,
    profileCallback,
    nux
}: Props) => {
    return (
        <Main onClick={profileCallback} nux={nux}>
            <VerticallyCentered>
                <ProfilePhoto
                    profilePhotoUrl={recommendation.other.profile.avatar}
                    status={recommendation.other.profile.status}
                    photoSize={46}
                    style={{ marginRight: "12px" }}
                />
                <TextContainer>
                    {recommendation.other.profile.username ? (
                        <Title>@{recommendation.other.profile.username}</Title>
                    ) : (
                        <NoUsername>No Username</NoUsername>
                    )}
                    <Subtitle nux={nux}>
                        <strong>
                            {shortenNumber(
                                recommendation.other.profile.tagLine
                                    .followerCount
                            )}
                        </strong>{" "}
                        on{" "}
                        {
                            (
                                Platforms[
                                    recommendation.other.profile.tagLine
                                        .platform
                                ] || Platforms.other
                            ).title
                        }
                    </Subtitle>
                </TextContainer>
                {recommendation.state ? (
                    <OutlineButton nux={nux}>Requested</OutlineButton>
                ) : (
                    <SolidButton
                        nux={nux}
                        onClick={e => {
                            e.stopPropagation();
                            postRecommendation({
                                selfId: recommendation.entity.id,
                                otherId: recommendation.other.id,
                                event: "click"
                            });
                        }}
                    >
                        Connect
                    </SolidButton>
                )}
            </VerticallyCentered>
            {recommendation.other.socialAccounts &&
                recommendation.other.socialAccounts.length > 0 && (
                    <RecommendationSocialRow
                        socialAccounts={recommendation.other.socialAccounts}
                    />
                )}
        </Main>
    );
};

export default RecommendationExpandedCell;
