import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import styled from "styled-components";
import { SelectedCampaigns } from "src/campaigns/modals/CampaignSelectionModal";
import CheckBox from "src/components/CheckBox";
import { Chevron } from "src/search/components/StyledComponents";
import { ChevronRight } from "./icons/exports";
import SearchBar from "./SearchBar";
import Subtitle from "src/components/text/Subtitle";

const Main = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    padding: 10px 18px;
`;

const List = styled.div`
    margin-top: 10px;
    position: relative;
    overflow: auto;
    & > * ~ * {
        margin-top: 10px;
    }
`;

const ItemCell = styled.div`
    ${FlexVerticallyCentered}
    cursor: pointer;
    user-select: none;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 2px;
`;

const AppIcon = styled.img`
    width: 46px;
    height: 46px;
    border-radius: 12px;
    margin-right: 12px;
`;

const Observer = styled.div`
    position: absolute;
    bottom: min(200px, 100%);
`;

export interface ItemSelectorOptions {
    id: string | number;
    value: any;
    title: string;
    subtitle: any;
    image: string;
    onSelect: () => void;
}

interface Props {
    initialSelection?: SelectedCampaigns;
    options: ItemSelectorOptions[];
    length: number;
    type?: string;
}

const ItemSelector = ({ options, length, type }: Props) => {
    const [maxItems, setMaxItems] = useState(10);
    const [search, setSearch] = useState("");
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView && maxItems < length) {
            setMaxItems(maxItems + 10);
        }
    }, [inView, length]);

    return (
        <Main>
            <SearchBar searchString={search} updateSearchString={setSearch} />
            <List>
                {options
                    .filter(({ title }) =>
                        title.toUpperCase().includes(search.toUpperCase())
                    )
                    .map(({ id, title, subtitle, value, image, onSelect }) => (
                        <ItemCell key={id} onClick={onSelect}>
                            {image && <AppIcon src={image} />}
                            <div>
                                <Title>{title}</Title>
                                <Subtitle>{subtitle}</Subtitle>
                            </div>
                            {!type || type === "check" ? (
                                <CheckBox selected={value} />
                            ) : (
                                <Chevron>
                                    <ChevronRight />
                                </Chevron>
                            )}
                        </ItemCell>
                    ))}
                <Observer ref={ref} />
            </List>
        </Main>
    );
};

export default ItemSelector;
