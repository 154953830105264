import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { MediaEarnings } from "./Analytics";
import { storage } from "../firebase/FirebaseConfig";
import { coinToDollars, formatNumber } from "../util";
import styled from "styled-components";
import AnalyticsIcon from "./AnalyticsIcon";
import { MediaLeaderboardItem } from "./Leaderboard";
import { mediaModal, ModalMedia } from "./actions";
import { breakpoints, colors } from "../constants";

const Cell = styled.div`
    width: 100%;
    display: flex;
    transition: 0.1s ease-in;
    cursor: pointer;

    & + & {
        margin-top: 15px;
    }

    @media (pointer: fine) {
        padding: 6px;
        border: ${colors.mediumTeal} solid 1px;
        border-radius: 8px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const Description = styled.div`
    width: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    overflow: hidden;
`;

const Name = styled.div`
    font-weight: 600;
    overflow-wrap: break-word;
`;

const Subtitle = styled.span`
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    color: ${colors.secondaryGray};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Username = styled.span`
    font-weight: 600;
`;

const MoneyContainer = styled.div`
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
`;

const Cash = styled.div`
    font-size: 20px;
    line-height: 24px;
`;

interface Props {
    earnings: MediaEarnings | MediaLeaderboardItem;
    mediaModal: (modalMedia: ModalMedia) => void;
}

const MediaEarningsCell = ({ earnings, mediaModal }: Props) => {
    const coverUrl = earnings.cover_photo_url || earnings.cover_photo;
    const mediaUrl = earnings.url;

    function cellClicked() {
        if (mediaUrl) {
            mediaModal({ type: earnings.type, mediaUrl });
        }
    }

    function imageUrl() {
        if (mediaUrl && earnings.type === "photo") {
            return mediaUrl;
        } else if (coverUrl) {
            return coverUrl;
        } else {
            return "/other_profile/other_profile@2x.png";
        }
    }

    function renderSubtitle() {
        // only media leaderboard items have usernames
        if (earnings.username) {
            return (
                <>
                    {"Submitted by "}
                    <Username>@{earnings.username}</Username>
                </>
            );
        }

        let bonusString = "5% earnings • ";

        const expirationDate = new Date(earnings.expiration_date);
        const expirationMonth = expirationDate.getMonth() + 1;
        const expirationDay = expirationDate.getDate();

        if (earnings.expired) {
            bonusString += `Expired on ${expirationMonth}/${expirationDay}`;
        } else {
            bonusString += `Active until ${expirationMonth}/${expirationDay}`;
        }

        return bonusString;
    }

    return (
        <Cell onClick={cellClicked}>
            <AnalyticsIcon mediaUrl={imageUrl()} />
            <Description>
                <NameContainer>
                    <Name>
                        {`${earnings.campaign_name}: Ad ${
                            "medias_id" in earnings
                                ? earnings.medias_id
                                : earnings.media_id
                        }`}
                    </Name>
                    <Subtitle>{renderSubtitle()}</Subtitle>
                </NameContainer>
                <MoneyContainer>
                    <Cash>
                        {earnings.earned_plugcoins
                            ? `${formatNumber(
                                  coinToDollars(
                                      parseInt(earnings.earned_plugcoins)
                                  )
                              )}`
                            : "$0.00"}
                    </Cash>
                </MoneyContainer>
            </Description>
        </Cell>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    mediaModal: (modalMedia: ModalMedia) => dispatch(mediaModal(modalMedia))
});

export default connect(null, mapDispatchToProps)(MediaEarningsCell);
