import React, { useEffect, useRef, useState } from "react";
import { colors } from "src/constants";
import styled from "styled-components";
import CarouselMedia from "src/modals/CarouselMedia";
import StatusMessage from "src/components/modals/components/StatusMessage";
import CopyTextWrap from "src/components/CopyTextWrap";
import XCard from "src/profile/components/XCard";
import { get, patch, post } from "src/Api";
import { useDispatch, useSelector } from "react-redux";
import { setPillNotificationText } from "src/ui/notifications/actions";
import MediaUploader from "src/components/media/MediaUploader";
import { BlueButton } from "src/profile/components/Buttons";
import GlobalWarning from "src/components/GlobalWarning";
import { RingLoader } from "react-spinners";
import { decrementMarketplaceSubmissionBadges } from "src/ui/marketplace/actions";
import HeaderMessage from "src/components/modals/components/HeaderMessage";
import { ReactComponent as ExternalLinkIcon } from "src/components/icons/external-link.svg";
import {
    setSubmissionManagerAccordion,
    SUBMISSION_MANAGER_ACCORDIONS
} from "src/ui/admin-tools/actions";
import {
    OpenBuySubmission,
    OpenBuySubmissionStatuses
} from "src/buys/modules/open-buys/types";
import SubmissionHistoryBody from "./SubmissionHistoryBody";
import ModalIconBtn from "src/components/modals/components/ModalIconBtn";
import {
    getHistoryTitle,
    getHistoryType,
    getSubmissionHistoryReason
} from "./utils";
import { GlobalState } from "src/reducers";
import { AdminEvent } from "./SubmissionHistory";
import { isAxiosError } from "axios";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
const MediaWrap = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
`;
const SaveMediaButton = styled(BlueButton)`
    padding: 4px 10px;
`;

const MediaEditRow = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 4px;
    z-index: 99;
    right: 4px;
    gap: 10px;
`;

const MediaWrapper = styled.div`
    background-color: ${colors.grayBlack};
    height: 100%;
    max-height: 80vh;
    margin: 0px;
    /* flex: 1; */
    width: 100%;
    overflow: hidden;
    border: 3px solid black;
    border-radius: 8px;
    & > video {
        display: block;
        height: 100%;
        width: 100%;
    }
`;

const HeadingRow = styled.div`
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
`;

const StyledStatusMessage = styled(StatusMessage)`
    margin-bottom: 10px;
`;
const StyledCopyTextWrap = styled(CopyTextWrap)`
    min-height: 46px;
`;
const StyledXCard = styled(XCard)`
    height: 100%;
`;

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
    fill: ${colors.gray2};
`;

const SubmissionColumn = ({
    submission,
    setIsLoading,
    getSubmission
}: {
    submission: OpenBuySubmission;
    getSubmission: VoidFunction;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const {
        status,
        rejectedBy,
        approvedBy,
        rejectionReason,
        textSubmission,
        linkSubmission,
        fullSubmissionUrl,
        id: submissionId,
        customMedia,
        generatedMedia,
        revisionRequestedBy,
        revisionRequest,
        openBuySubmissionSetId: submissionSetId
    } = submission;
    let media = generatedMedia?.id ? generatedMedia : customMedia;
    const dispatch = useDispatch();
    const [editMedia, setEditMedia] = useState<null | {
        id?: string;
        url?: string;
        type?: string;
        coverPhoto?: string;
    }>(null);
    const [warning, setWarning] = useState("");
    const activeAccordions = useSelector(
        (state: GlobalState) =>
            state.ui.adminTools.submissionManager.activeAccordions
    );
    const [history, setHistroy] = useState<AdminEvent[]>([]);
    const getHistory = async () => {
        try {
            let res = await get(
                `/v1/openBuySubmissionSet/${submissionSetId}/history`,
                {}
            );
            let edits: AdminEvent[] = [];
            Object.keys(res.data.data).forEach(id =>
                res.data.data[id].forEach((event: AdminEvent) => {
                    if (!event.eventName.toLowerCase().includes("scraper")) {
                        edits.push(event);
                    }
                })
            );
            setHistroy(edits);
        } catch (err) {}
    };
    useEffect(() => {
        getHistory();
    }, [submissionSetId]);

    const shouldShowFixSpark =
        Boolean(textSubmission) &&
        (!Boolean(fullSubmissionUrl) || !Boolean(media?.url));

    const handleFixSpark = async () => {
        try {
            dispatch(
                setPillNotificationText(
                    "Spark code is processing",
                    "success",
                    3500
                )
            );
            const res = await post(
                `/v1/openBuySubmission/${submissionId}/fixSparkCode`,
                {},
                2 * 60 * 1000
            );
            getSubmission();
            if (res.data.data.linkSubmission) {
                dispatch(
                    setPillNotificationText(
                        "Spark code successfully updated",
                        "success",
                        3500
                    )
                );
                if (!res.data.data.customMediaId)
                    setTimeout(getSubmission, 30000);
            } else
                dispatch(
                    setPillNotificationText(
                        "Spark code failed to process, likely a bad code.",
                        "warning",
                        3500
                    )
                );
        } catch (e) {
            dispatch(
                setPillNotificationText("Something went wrong!", "danger", 3500)
            );
        } finally {
            setIsLoading(false);
        }
    };

    const updateSubmission = async (v: string) => {
        try {
            setIsLoading(true);
            await patch(
                `/v1/openBuySubmission/${submissionId}/`,
                submission.linkSubmission
                    ? { linkSubmission: v }
                    : { textSubmission: v }
            );
            dispatch(setPillNotificationText("Updated submission!", "success"));
            getSubmission();
        } catch (err) {
            setIsLoading(false);
            if (isAxiosError(err))
                dispatch(
                    setPillNotificationText(
                        `Submission failed to update ${err.message}`,
                        "danger"
                    )
                );
        }
    };

    const updateMedia = async () => {
        if (!editMedia) {
            dispatch(
                setPillNotificationText("Must submit a media first", "danger")
            );
            return;
        }
        try {
            setIsLoading(true);
            await patch(`/v1/openBuy/submission/${submissionId}/customMedia`, {
                openBuyId: submission.openBuyId,
                instructionStepId: submission.instructionStepId,
                socialAccountId: submission.socialAccountId,
                filename: editMedia?.id,
                coverPhoto: editMedia?.coverPhoto,
                type: editMedia?.type
            });
            dispatch(setPillNotificationText("Media updated successfully"));
            getSubmission();
        } catch (err) {
            setIsLoading(false);
            if (isAxiosError(err)) {
                dispatch(
                    setPillNotificationText(
                        `Submission failed to update ${err.message}`,
                        "danger"
                    )
                );
            }
        }
    };

    const clearFlag = async () => {
        try {
            await patch(`/v1/openBuy/submission/${submission.id}/clearFlag`);
            getSubmission();
        } catch (err) {
            dispatch(
                setPillNotificationText(
                    `Failed to clear scraper flag`,
                    "danger"
                )
            );
            dispatch(decrementMarketplaceSubmissionBadges(false, true));
        }
    };

    const checkSparkCode = async () => {
        try {
            let res = await get("/v1/checkSparkCode", {
                sparkCode: submission.textSubmission
            });
            if (!res.data.data)
                dispatch(
                    setPillNotificationText(
                        "Spark code is no longer valid",
                        "danger"
                    )
                );
            else dispatch(setPillNotificationText("Spark code is valid"));
        } catch (err) {
            dispatch(
                setPillNotificationText(
                    "Spark code is no longer valid",
                    "danger"
                )
            );
        }
    };

    return (
        <Wrapper>
            <HeadingRow>
                {(submission.linkSubmission || submission.textSubmission) && (
                    <a
                        href={
                            submission.linkSubmission ||
                            submission.fullSubmissionUrl ||
                            ""
                        }
                        target="_blank"
                    >
                        <ModalIconBtn>
                            <StyledExternalLinkIcon />
                        </ModalIconBtn>
                    </a>
                )}
            </HeadingRow>

            {history.length > 0 && (
                <StatusMessage
                    invert={true}
                    heading={`${getHistoryTitle(history[0].eventName, true)} (${
                        history[0].submissionId
                    }):`}
                    type={getHistoryType(history[0].eventName)}
                    closable={true}
                    onClick={() => {
                        if (
                            !activeAccordions.includes(
                                SUBMISSION_MANAGER_ACCORDIONS.history
                            )
                        ) {
                            dispatch(
                                setSubmissionManagerAccordion(
                                    activeAccordions.concat([
                                        SUBMISSION_MANAGER_ACCORDIONS.history
                                    ])
                                )
                            );
                        }
                    }}
                >
                    <SubmissionHistoryBody event={history[0]} />
                </StatusMessage>
            )}

            {/* <HeaderMessage
                type={getHeaderStatus()}
                onHeaderClick={() => {
                    if (
                        !activeAccordions.includes(
                            SUBMISSION_MANAGER_ACCORDIONS.history
                        )
                    )
                        dispatch(
                            setSubmissionManagerAccordion(
                                activeAccordions.concat([
                                    SUBMISSION_MANAGER_ACCORDIONS.history
                                ])
                            )
                        );
                }}
                seeMoreText={getSeeMoreText()}
                headerLabel="View History"
                heading={getHeaderHeading()}
                closable={
                    Boolean(rejectionReason) &&
                    status === OpenBuySubmissionStatuses.rejected
                }
            >
                {latest && status !== OpenBuySubmissionStatuses.approved ? (
                    <SubmissionHistoryBody event={latest} />
                ) : (
                    undefined
                )}
            </HeaderMessage> */}

            {textSubmission ? (
                <StyledCopyTextWrap
                    text={textSubmission}
                    onEdit={(v: string) => setWarning(v)}
                />
            ) : linkSubmission ? (
                <StyledCopyTextWrap
                    text={linkSubmission}
                    onEdit={(v: string) => setWarning(v)}
                />
            ) : null}

            {media?.url ? (
                <>
                    OriginalMedia
                    <StyledCopyTextWrap text={media.url} />{" "}
                </>
            ) : null}

            {media?.url ? (
                <>
                    Externally playable media
                    <StyledCopyTextWrap
                        text={`https://plugco.in/media?mediaUrl=${encodeURIComponent(
                            media.url
                        )}`}
                    />
                </>
            ) : null}
            {submission.scraperFlag && (
                <StyledStatusMessage
                    type="danger"
                    heading={`Scraper Flag: ${submission.scraperFlag}`}
                    onDismiss={() => setWarning("flag")}
                />
            )}
            {media?.url && !editMedia && (
                <MediaWrapper>
                    <video controls={true} src={media.url || ""} />
                </MediaWrapper>
            )}
            {editMedia && (
                <MediaWrap>
                    <MediaEditRow style={{ display: "none" }}>
                        <SaveMediaButton
                            disabled={!editMedia.coverPhoto}
                            onClick={() => setWarning("media")}
                        >
                            Save
                        </SaveMediaButton>
                        <SaveMediaButton
                            style={{ backgroundColor: colors.grayBlack }}
                            onClick={() => setEditMedia(null)}
                        >
                            Cancel
                        </SaveMediaButton>
                    </MediaEditRow>
                    <MediaUploader
                        path={"openBuyCustomMedia"}
                        onUpload={(id, type, url) => {
                            setEditMedia({ id, type, url });
                        }}
                        onRemove={() => {
                            setEditMedia(null);
                        }}
                        onCoverPhoto={id => {
                            setEditMedia(old => ({
                                ...old,
                                coverPhoto: id
                            }));
                        }}
                    />
                </MediaWrap>
            )}
            {!media?.url && shouldShowFixSpark && (
                <StyledXCard
                    text="The media and information for this Spark Submission has not been pulled yet. Click the button below to continue"
                    button={{
                        text: "Get Spark Media",
                        callback: handleFixSpark
                    }}
                    boxFullHeight
                />
            )}
            {warning && (
                <GlobalWarning
                    title="Edit submission confirmation"
                    message={
                        warning === "flag"
                            ? "Are you sure you want to clear this flag?"
                            : "Are you sure you want to edit this submission?"
                    }
                    handleApply={() => {
                        warning === "flag"
                            ? clearFlag()
                            : warning === "media"
                            ? updateMedia()
                            : updateSubmission(warning);
                        setWarning("");
                    }}
                    handleCancel={() => {
                        setWarning("");
                    }}
                />
            )}
        </Wrapper>
    );
};

export default SubmissionColumn;
