import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import * as Icons from "src/components/icons/exports";
import { colors } from "src/constants";
import { ReactComponent as Campaign } from "src/components/icons/NavbarPlug/navbar-plug.svg";
import { ReactComponent as Highlights } from "src/components/icons/NavbarHighlights/navbar-highlights.svg";
import { ReactComponent as Analytics } from "src/components/icons/NavbarAnalytics/navbar-analytics.svg";
import { ReactComponent as Notifications } from "src/components/icons/NavbarNotifications/navbar-notifications.svg";
import { ReactComponent as Search } from "src/components/icons/Search/search.svg";
import { ReactComponent as ChallengeTools } from "src/components/icons/marketplace-challenge-tools.svg";

interface IconProps {
    $routematches: boolean;
}

const iconStyles = css<IconProps>`
    width: auto;
    height: 24px;
    fill: ${props => {
        return props.$routematches ? colors.primaryBlue : colors.primaryGray;
    }};
`;

const CampaignIcon = styled(Campaign)<IconProps>`
    ${iconStyles};
`;

const HighlightsIcon = styled(Highlights)<IconProps>`
    ${iconStyles};
`;

const AnalyticsIcon = styled(Analytics)<IconProps>`
    ${iconStyles};
`;

const NotificationsIcon = styled(Notifications)<IconProps>`
    ${iconStyles};
`;

const SearchIcon = styled(Search)<IconProps>`
    ${iconStyles};
`;

const ChallengeToolsIcon = styled(ChallengeTools)<IconProps>`
    ${iconStyles};
`;

const hoverStyles = css<IconProps>`
    color: ${colors.primaryGray};
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            color: ${colors.black};
            text-decoration: none;

            > svg {
                fill: ${props => {
                    return props.$routematches
                        ? colors.primaryBlue
                        : colors.black;
                }};
            }
        }
    }
`;

const Link = styled(RouterLink)`
    ${hoverStyles};
    position: relative;
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 6px;
    justify-items: center;
    width: 124px;
    font-weight: normal;
    text-decoration: none;
    outline: none;

    :before {
        content: "";
        position: absolute;
        right: 0px;
        bottom: -23px;
        left: 0px;
        height: 2px;
        background-color: ${colors.black};
        transform: scale(0);
        transition: 0.1s ease-in;
    }

    @media (hover: hover) {
        :hover:before {
            transform: scale(1);
        }
    }
`;

const IsPathLink = styled(Link)`
    ${hoverStyles};
    color: ${colors.primaryBlue};
    font-weight: 600;

    :before {
        background-color: ${colors.primaryBlue};
        transform: scale(1);
    }

    :hover {
        color: ${colors.primaryBlue};
    }
`;

const NavigationText = styled("span")`
    display: block;
    color: inherit;
    font-size: 12px;
`;

interface Props {
    to: string;
    isPath: boolean;
    text: string;
}

const NavbarDesktopLink = (props: Props) => {
    const { to, isPath, text } = props;

    const [fill, setFill] = useState(colors.primaryGray);

    useEffect(() => {
        if (isPath) setFill(colors.primaryBlue);
        else setFill(colors.primaryGray);
    }, [isPath]);

    function handleIconChoice(): React.ReactNode {
        if (text === "Earn") {
            return <CampaignIcon $routematches={isPath} />;
        } else if (to.includes("highlights")) {
            return <HighlightsIcon $routematches={isPath} />;
        } else if (to.includes("analytics") || to.includes("your-challenges")) {
            return <AnalyticsIcon $routematches={isPath} />;
        } else if (to.includes("notifications")) {
            return <NotificationsIcon $routematches={isPath} />;
        } else if (to.includes("search")) {
            return <SearchIcon $routematches={isPath} />;
        } else {
            return <ChallengeToolsIcon $routematches={isPath} />;
        }
    }
    if (isPath)
        return (
            <IsPathLink to={to} $routematches={isPath}>
                {handleIconChoice()}
                <NavigationText>{text}</NavigationText>
            </IsPathLink>
        );
    else
        return (
            <Link to={to} $routematches={isPath}>
                {handleIconChoice()}
                <NavigationText>{text}</NavigationText>
            </Link>
        );
};

export default NavbarDesktopLink;
