import React from "react";
import { Link } from "react-router-dom";
import { colors, fonts } from "src/constants";
import {
    clearButton,
    clearSpacing,
    flexRowCenter
} from "src/utils/styles/snippets";
import styled from "styled-components";
import cardCircleX from "src/components/icons/card-circle-x.svg";
import { instructionSetsRoutes } from "src/marketplace/utils/marketplace-routes";
import { useDispatch } from "react-redux";
import {
    setInstructionsPageStep,
    setInstructionsPageType
} from "src/ui/instructions-sets/actions";
import { setAdminCurrentWizardStep } from "src/ui/admin-tools/actions";

const Main = styled.article`
    ${flexRowCenter()};
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 18px;
    border: ${colors.quaternaryLightGray} solid 1px;
    border-radius: 8px;
`;

const XIcon = styled.img``;

const Text = styled.p`
    ${clearSpacing()};
    padding: 0px 12px;
    color: ${colors.primaryGray};
    font-size: ${fonts.subtitle}px;
    line-height: 20px;
    text-align: center;
`;

const CreateButton = styled.button`
    ${clearButton()};
    display: block;
    padding: 10px 15px;
    color: ${colors.primaryBlue};
    border: ${colors.blueGrey2} solid 1px;
    border-radius: 4px;
    font-size: ${fonts.smallTitle}px;
    transition: 0.1s ease-in;
    cursor: pointer;

    :hover {
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            color: ${colors.primaryBlue};
            border: ${colors.darkTeal} solid 1px;
        }
    }
`;

interface Props {
    step: "Campaign Selection" | "Template Selection";
}

const InstructionsFormXCard = ({ step }: Props) => {
    const dispatch = useDispatch();

    return (
        <Main>
            <XIcon src={cardCircleX} alt="Instruction Sets XCard Icon" />
            {step === "Campaign Selection" ? (
                <>
                    <Text>
                        You have not yet selected a campaign.
                        <br />
                        Go back to Challenge Details to select a campaign.
                    </Text>
                    <CreateButton
                        onClick={() =>
                            dispatch(
                                setAdminCurrentWizardStep(
                                    "Set Challenge Details"
                                )
                            )
                        }
                    >
                        Challenge Details
                    </CreateButton>
                </>
            ) : (
                <>
                    <Text>
                        You have no instruction sets selected.
                        <br />
                        Either select one to use as a template
                        <br />
                        Or create one by clicking the button below:
                    </Text>
                    <CreateButton
                        onClick={() => {
                            dispatch(setInstructionsPageType("Create"));
                            dispatch(setInstructionsPageStep("Scratch"));
                        }}
                    >
                        Create Instruction Set
                    </CreateButton>
                </>
            )}
        </Main>
    );
};

export default InstructionsFormXCard;
