import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import CampaignHeader from "src/campaigns/details/components/CampaignHeader";
import NotAuthedNav from "src/campaigns/details/components/NotAuthedNav";
import DetailTab from "src/campaigns/details/tabs/DetailTab";
import PaymentTab from "src/campaigns/details/tabs/PaymentTab";
import PromoteTab from "src/campaigns/details/tabs/PromoteTab";
import DesktopTabBar from "src/components/desktop/DesktopTabBar";
import { selectCampaign } from "src/campaigns/selectors";
import { maxWidthDesktop } from "src/constants";
import { flex } from "src/utils/styles/snippets";
import { Campaign } from "src/campaigns/types";

const Main = styled("main")`
    ${flex("row", "center")};
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    margin: 0rem auto;
`;

const Content = styled("div")`
    flex: 2;
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    padding: 0px 14px;
`;

const NotAuthedDetailsPage = () => {
    const currentCampaign = useSelector(selectCampaign);

    return (
        <>
            <NotAuthedNav />

            <Main>
                <Content>
                    <CampaignHeader
                        currentCampaign={currentCampaign}
                        scrollToBottom={() => {}}
                    />

                    <DesktopTabBar
                        items={[
                            {
                                label: "Promote",
                                key: "promote",
                                component: <PromoteTab />
                            },
                            {
                                label: "Payment",
                                key: "payment",
                                component: <PaymentTab />
                            },
                            {
                                label: "Details",
                                key: "details",
                                component: <DetailTab />
                            }
                        ]}
                    />
                </Content>
            </Main>
        </>
    );
};

export default NotAuthedDetailsPage;
