import React, { useRef, useState } from "react";
import SearchTag from "src/components/SearchTag";
import SelectionButton from "src/components/SelectionButton";
import { colors, fonts, shadows, sizes } from "src/constants";
import { useCloseModalWhenClickedOutside } from "src/utils/functions/hooks";
import { clearButton } from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import { CurrentFilter, FiltersReference } from "../hooks/useFilters";
import RangeFilter from "./RangeFilter";

const Wrapper = styled.div`
    position: relative;
`;
const Dropdown = styled.div`
    max-height: ${sizes.selectionButton.height * 8}px;
    background-color: ${colors.white};
    border-radius: 8px;
    box-shadow: ${shadows.one};
    overflow-y: auto;
`;
const Buttons = styled.div`
    position: sticky;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px;
    background-color: ${colors.white};
    box-shadow: 0px -4px 8px rgba(187, 187, 187, 0.25);
`;

const buttonStyles = css`
    ${clearButton()};
    flex: 1;
    border-radius: 6px;
    padding: 8px 0px;
    font-size: ${fonts.semiSmallTitle}px;
    text-align: center;
    transition: 0.1s ease-in;
`;

const ResetButton = styled.button`
    ${buttonStyles};
    color: ${colors.white};
    background-color: ${colors.primaryBlue};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

const CloseButton = styled.button`
    ${buttonStyles};
    background-color: ${colors.quaternaryLightGray};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray2};
        }
    }
`;
const DropdownWrap = styled.div<{ shouldShow: boolean }>`
    position: absolute;
    top: 100%;
    display: ${props => (props.shouldShow ? "visible" : "none")};
    z-index: 20;
`;
const StyledRangeFilter = styled(RangeFilter)`
    margin: 12px 0px;
`;
interface Props {
    currentFilter: CurrentFilter;
    filterCoreKey: string;

    filtersReference: FiltersReference;
}
const DropdownFilter = ({
    currentFilter,
    filterCoreKey,
    filtersReference
}: Props) => {
    const mainRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { handleSelect, resetFilter } = filtersReference;

    useCloseModalWhenClickedOutside(mainRef, open, () => setOpen(false), [
        open
    ]);

    return (
        <Wrapper ref={mainRef}>
            <SearchTag
                color={
                    currentFilter.isCustomRangeActive ||
                    currentFilter.selectedIndexes.length > 0
                        ? "black"
                        : "white"
                }
                counter={
                    currentFilter.isCustomRangeActive
                        ? currentFilter.selectedIndexes.length + 1
                        : currentFilter.selectedIndexes.length
                }
                text={currentFilter.inlineDisplayValue}
                iconSrc="/tag-teal-plus-icon.svg"
                textTransform={false}
                onClick={() => setOpen(!open)}
            />
            <DropdownWrap shouldShow={open}>
                <Dropdown>
                    {currentFilter.type === "number" &&
                        currentFilter.customRange && (
                            <StyledRangeFilter
                                currentFilter={currentFilter}
                                currentFilterKey={filterCoreKey}
                                filtersReference={filtersReference}
                            />
                        )}
                    {Object.entries(currentFilter.filters).map(
                        ([idxKey, filter], idx) => {
                            return (
                                <SelectionButton
                                    key={idxKey + filterCoreKey}
                                    text={filter.displayValue}
                                    selected={currentFilter.selectedIndexes.includes(
                                        parseInt(idxKey)
                                    )}
                                    setSelected={() =>
                                        handleSelect(
                                            parseInt(idxKey),
                                            filterCoreKey,
                                            currentFilter
                                        )
                                    }
                                    width={
                                        sizes.marketplace.creatorGroups
                                            .filterDropdown.width
                                    }
                                />
                            );
                        }
                    )}
                    <Buttons>
                        <ResetButton
                            type="button"
                            onClick={() => resetFilter(filterCoreKey)}
                        >
                            Reset Selection
                        </ResetButton>
                        <CloseButton
                            type="button"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </CloseButton>
                    </Buttons>
                </Dropdown>
            </DropdownWrap>
        </Wrapper>
    );
};

export default DropdownFilter;
