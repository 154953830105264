import React from "react";
import { DirectBuyStepsEntitiesState } from "src/buys/modules/direct-buy-step/reducer";
import {
    DirectBuyStep,
    DirectBuyStepCollection
} from "src/buys/modules/direct-buy-step/types";
import { StepById } from "src/buys/modules/open-buys/reducer";
import { OpenBuyInstructionStep } from "src/buys/modules/open-buys/types";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import { ReactComponent as Money } from "src/components/icons/money.svg";
import styled from "styled-components";

const Main = styled.div`
    margin-top: 16px;
    ${FlexVerticallyCentered}
    font-size: 12px;

    line-height: 14px;
    text-align: left;
    margin: auto 0 auto auto;
`;

const Amount = styled.div`
    margin-left: 8px;
    font-weight: 400;
    & > span {
        font-weight: 600;
    }
`;

function isOpenBuy(
    step: DirectBuyStep | OpenBuyInstructionStep
): step is OpenBuyInstructionStep {
    return (step as OpenBuyInstructionStep).instructionPayments !== undefined;
}

interface Props {
    parentStep: DirectBuyStep | OpenBuyInstructionStep;
}

const DirectBuyDetailsPaymentBrief = ({ parentStep }: Props) => {
    const getPaymentType = (type: string) => {
        switch (type) {
            case "flat_rate":
                return "Flat Fee";
            case "dollars_per_k":
                return "per K";
            default:
                return "";
        }
    };

    const getPaymentCopy = (parentStep: OpenBuyInstructionStep) => {
        switch (parentStep.payoutTiming) {
            case "realtime":
                return "Earn Per View";
            default:
                return parentStep.instructionPayments
                    .map(
                        payment =>
                            `$${payment.amount} ${getPaymentType(
                                payment.payment.type
                            )}`
                    )
                    .join(", ");
        }
    };

    if (!isOpenBuy(parentStep) || !parentStep.instructionPayments.length)
        return null;
    return (
        <Main>
            <Money />
            <Amount>
                <span>{getPaymentCopy(parentStep)}</span> on Approval
            </Amount>
        </Main>
    );
};
export default DirectBuyDetailsPaymentBrief;
