import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styled from "styled-components";

const RichTextWrap = styled.div`
    width: 100%;
    text-align: initial;
    overflow-y: scroll;
    max-height: 16em;
    font-family: sans-serif;
    p {
        margin-bottom: 8px;
    }
`;
const RichTextPreview = ({ text }: { text?: string }) => {
    return (
        <RichTextWrap>
            <ReactMarkdown remarkPlugins={[remarkGfm]} linkTarget="_blank">
                {text || ""}
            </ReactMarkdown>
        </RichTextWrap>
    );
};

export default RichTextPreview;
