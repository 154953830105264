import { FETCH_CAMPAIGN, FETCH_CAMPAIGNS } from "src/campaigns/actions";
import {
    CREATE_CREATOR_GROUP,
    DELETE_CREATOR_GROUP,
    FETCH_CREATOR_GROUP,
    FETCH_CREATOR_GROUPS,
    UPDATE_CREATOR_GROUP
} from "src/marketplace/creator-groups/actions.creator-groups";
import {
    CREATE_INSTRUCTION_STEP,
    DELETE_INSTRUCTION_STEP,
    FETCH_INSTRUCTION_STEP,
    FETCH_INSTRUCTION_STEPS,
    UPDATE_INSTRUCTION_STEP
} from "src/marketplace/instructions-sets/actions.instruction-steps";
import {
    CREATE_INSTRUCTIONS_SET,
    DELETE_INSTRUCTIONS_SET,
    FETCH_INSTRUCTIONS_SET,
    FETCH_INSTRUCTIONS_SETS,
    UPDATE_INSTRUCTIONS_SET
} from "src/marketplace/instructions-sets/actions.instructions-sets";
import { FETCH_SOCIAL_ACCOUNT_PROFILE } from "src/social-accounts/profile/redux/social-account-profile.action";
import { Action } from "src/types";
import { RESET_FAILURES } from "./actions";

export interface FailuresState {
    campaignsFetch: string;
    campaignFetch: string;

    instructionsSetsFetch: string;
    instructionsSetFetch: string;
    instructionsSetCreate: string;
    instructionsSetUpdate: string;
    instructionsSetDelete: string;

    instructionStepsFetch: string;
    instructionStepFetch: string;
    instructionStepCreate: string;
    instructionStepUpdate: string;
    instructionStepDelete: string;

    creatorGroupsFetch: string;
    creatorGroupFetch: string;
    creatorGroupCreate: string;
    creatorGroupUpdate: string;
    creatorGroupDelete: string;

    socialAccountProfileFetch: string;
}

const failuresInitialState: FailuresState = {
    campaignsFetch: "",
    campaignFetch: "",

    instructionsSetsFetch: "",
    instructionsSetFetch: "",
    instructionsSetCreate: "",
    instructionsSetUpdate: "",
    instructionsSetDelete: "",

    instructionStepsFetch: "",
    instructionStepFetch: "",
    instructionStepCreate: "",
    instructionStepUpdate: "",
    instructionStepDelete: "",

    creatorGroupsFetch: "",
    creatorGroupFetch: "",
    creatorGroupCreate: "",
    creatorGroupUpdate: "",
    creatorGroupDelete: "",

    socialAccountProfileFetch: ""
};

function failuresReducer(state = failuresInitialState, action: Action) {
    switch (action.type) {
        case RESET_FAILURES:
            return failuresInitialState;

        // Campaigns ---------------------------------------------------------------

        case FETCH_CAMPAIGNS.FAILURE:
            return { ...state, campaigns: action.payload.error };

        case FETCH_CAMPAIGN.FAILURE:
            return { ...state, campaign: action.payload.error };

        // Instructions Sets ---------------------------------------------------------------

        case FETCH_INSTRUCTIONS_SETS.FAILURE:
            return { ...state, instructionsSets: action.payload.error };

        case FETCH_INSTRUCTIONS_SET.FAILURE:
            return { ...state, instructionsSet: action.payload.error };

        case CREATE_INSTRUCTIONS_SET.FAILURE:
            return { ...state, instructionsSetCreate: action.payload.error };

        case UPDATE_INSTRUCTIONS_SET.FAILURE:
            return { ...state, instructionsSetUpdate: action.payload.error };

        case DELETE_INSTRUCTIONS_SET.FAILURE:
            return { ...state, instructionsSetDelete: action.payload.error };

        // Instruction Steps ---------------------------------------------------------------

        case FETCH_INSTRUCTION_STEPS.FAILURE:
            return { ...state, instructionStepsFetch: action.payload.error };

        case FETCH_INSTRUCTION_STEP.FAILURE:
            return { ...state, instructionStepFetch: action.payload.error };

        case CREATE_INSTRUCTION_STEP.FAILURE:
            return { ...state, instructionStepCreate: action.payload.error };

        case UPDATE_INSTRUCTION_STEP.FAILURE:
            return { ...state, instructionStepUpdate: action.payload.error };

        case DELETE_INSTRUCTION_STEP.FAILURE:
            return { ...state, instructionStepDelete: action.payload.error };

        // Creator Groups ---------------------------------------------------------------

        case FETCH_CREATOR_GROUPS.FAILURE:
            return { ...state, creatorGroupsFetch: action.payload.error };

        case FETCH_CREATOR_GROUP.FAILURE:
            return { ...state, creatorGroupFetch: action.payload.error };

        case CREATE_CREATOR_GROUP.FAILURE:
            return { ...state, creatorGroupCreate: action.payload.error };

        case UPDATE_CREATOR_GROUP.FAILURE:
            return { ...state, creatorGroupUpdate: action.payload.error };

        case DELETE_CREATOR_GROUP.FAILURE:
            return { ...state, creatorGroupDelete: action.payload.error };

        // Creator Groups ---------------------------------------------------------------

        case FETCH_SOCIAL_ACCOUNT_PROFILE.FAILURE:
            return {
                ...state,
                socialAccountProfileFetch: action.payload.error
            };

        default:
            return state;
    }
}

export default failuresReducer;
