import { colors } from "src/constants";
import styled from "styled-components";

const LabelText = styled.div`
    font-size: 12px;
    font-weight: 700;
    line-height: 14.32px;
    text-align: left;
    color: ${colors.primaryGray};
`;

export default LabelText;
