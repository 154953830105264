import { NormalizedAction } from "../../types";
import {
    DELETE_MEDIA,
    FETCH_SEARCH_MEDIA,
    UPDATE_MEDIA
} from "src/media/actions";
import { CampaignMedia } from "../types";
import {
    FETCH_CAMPAIGN_MEDIA,
    FETCH_CAMPAIGN_MEDIAS,
    FETCH_CAMPAIGN_PUBLIC_MEDIAS,
    FETCH_SCRIPT_MEDIAS
} from "./actions";

export interface MediasEntitiesState {
    [id: number]: CampaignMedia;
}

const initialMediasState: MediasEntitiesState = {};

const mediasEntitiesReducer = (
    state = initialMediasState,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_CAMPAIGN_MEDIAS.SUCCESS:
        case FETCH_CAMPAIGN_PUBLIC_MEDIAS.SUCCESS:
        case FETCH_SCRIPT_MEDIAS.SUCCESS:
        case FETCH_SEARCH_MEDIA.SUCCESS:
            return { ...state, ...action.response.entities.medias };
        case UPDATE_MEDIA.SUCCESS:
            const existingMedia = state[action.response.result];
            return {
                ...state,
                [action.response.result]: {
                    ...existingMedia,
                    ...action.response.entities.medias[action.response.result]
                }
            };
        case DELETE_MEDIA.SUCCESS:
            const newState = Object.entries(state)
                .filter(
                    ([key, val]) => parseInt(key) !== action.payload.mediaId
                )
                .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});
            return newState;
        case FETCH_CAMPAIGN_MEDIA.SUCCESS:
            const mediaId = action.response.result;
            const media = action.response.entities.medias[mediaId];
            const stateAfterMediaFetch = { ...state };
            stateAfterMediaFetch[mediaId] = media;
            return stateAfterMediaFetch;
        default:
            return state;
    }
};

export default mediasEntitiesReducer;
