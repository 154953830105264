import styled from "styled-components";
import { fonts } from "src/constants";

const Input = styled.input`
    border: none;
    outline: none;
    box-sizing: border-box;
`;

export const AccountInput = styled(Input)`
    font-size: ${fonts.smallTitle}px;
    width: 100%;
`;

export const AccountUsernameInput = styled(AccountInput)`
    font-weight: 600;

    &::placeholder {
        font-weight: 600;
    }
`;
