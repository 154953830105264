import { AuthAction, Role } from "../roles";
import { useSelector } from "react-redux";
import { getAuthState } from "../selectors";
import { rules } from "../rules";

export const check = (action: AuthAction, role: Role) => {
    const permission = rules[role];
    if (!permission) {
        // role is not present in the rules
        return false;
    }

    const staticPermissions = permission.static;

    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule provided for action
        return true;
    }

    return false;
};

const useAuthorization = (actions: AuthAction[]): boolean[] => {
    const { role } = useSelector(getAuthState);

    return actions.map(action => check(action, role));
};

export default useAuthorization;
