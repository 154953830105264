import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import BidIncreaseTag from "src/components/campaign/BidIncreaseTag";
import LoadingButton from "src/components/LoadingButton";
import { colors } from "src/constants";
import Modal from "src/modals/Modal";
import { GlobalState } from "src/reducers";
import {
    clearBidNotifications,
    setOpenBidNotificationModal
} from "src/ui/bid-budget/actions";
import { BidNotification } from "src/ui/bid-budget/reducer";
import { secondsToTimeIntervals } from "src/util";
import styled from "styled-components";
import PromoteFlowModal from "../promote/PromoteFlowModal";
import { getCampaignsById } from "../selectors";
import { Campaign } from "../types";
import CampaignSection from "./components/CampaignSection";
import CampaignsModal from "./components/CampaignsModal";
import TimerSection from "./components/TimerSection";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 18px;
    text-align: center;
    justify-content: center;
    gap: 16px;
    border-radius: 16px;
    align-items: center;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 20px;
`;

const Subtitle = styled.div`
    font-weight: 400;
    font-size: 16px;
`;

const BidIncreaseNotificationModal = () => {
    const [now, setNow] = useState(new Date());
    const [selected, setSelected] = useState<Campaign | null | undefined>();
    const dispatch = useDispatch();
    const history = useHistory();

    const { notifications, openBidNotificationModal } = useSelector(
        (state: GlobalState) => {
            return state.ui.bidBudget;
        }
    );

    let campaigns: Campaign[] = useSelector((state: GlobalState) =>
        getCampaignsById(
            state,
            notifications?.map((notif: BidNotification) => notif.campaignId) ||
                []
        )
    );
    useEffect(() => {
        const timer = setTimeout(() => setNow(new Date()), 1000);
        return () => clearTimeout(timer);
    });

    if (!openBidNotificationModal) return null;

    if (selected) {
        let date = new Date(
            selected.countryPlatformReward.boost_expires_at || ""
        );
        const times = secondsToTimeIntervals(
            Math.floor((date.getTime() - now.getTime()) / 1000),
            false,
            true,
            true
        );
        return (
            <Modal onClick={() => dispatch(clearBidNotifications())}>
                <Container>
                    <Title>This Campaign has a limited bid increase!</Title>
                    <CampaignSection campaign={selected} />
                    <Subtitle>This bid increase will expire in:</Subtitle>
                    <BidIncreaseTag
                        increase={selected.countryPlatformReward.reward}
                        prevReward={
                            selected.countryPlatformReward.previous_reward
                        }
                    />
                    <TimerSection times={times} />
                    <PromoteFlowModal campaign={selected} />{" "}
                    <LoadingButton
                        onClick={() => setSelected(null)}
                        text="Back to Campaigns"
                        height="58px"
                        backgroundColor={colors.white}
                        textColor={colors.primaryBlue}
                        borderColor={colors.blueGrey2}
                        width="100%"
                    />
                </Container>
            </Modal>
        );
    }

    if (campaigns.length === 1) {
        let date = new Date(
            campaigns[0].countryPlatformReward.boost_expires_at || ""
        );
        const times = secondsToTimeIntervals(
            Math.floor((date.getTime() - now.getTime()) / 1000),
            false,
            true,
            true
        );
        return (
            <Modal
                onClick={() => {
                    dispatch(clearBidNotifications());
                    dispatch(setOpenBidNotificationModal(false));
                }}
            >
                <Container>
                    <CampaignSection campaign={campaigns[0]} />
                    <Title>This Campaign has a Bid Increase!</Title>
                    <Subtitle>
                        The amount of earnings for this campaign has been
                        increased by:
                    </Subtitle>
                    <BidIncreaseTag
                        increase={campaigns[0].countryPlatformReward.reward}
                        prevReward={
                            campaigns[0].countryPlatformReward.previous_reward
                        }
                    />
                    <Subtitle>
                        This increase is only for a limited time, so promote it
                        while you can. The increase is active for:
                    </Subtitle>
                    <TimerSection times={times} />
                    <PromoteFlowModal campaign={campaigns[0]} />{" "}
                </Container>
            </Modal>
        );
    }

    let greatest = { reward: 0, prev: 0, expires: "" };
    campaigns.forEach((campaign: Campaign) => {
        let platform = campaign.countryPlatformReward;
        let diff = platform.reward - (platform.previous_reward || 0);
        if (greatest.reward - greatest.prev < diff)
            greatest = {
                reward: platform.reward,
                prev: platform.previous_reward || 0,
                expires: platform.boost_expires_at || ""
            };
    });

    let date = new Date(greatest.expires || "");
    const times = secondsToTimeIntervals(
        Math.floor((date.getTime() - now.getTime()) / 1000),
        false,
        true,
        true
    );

    function generateSubtitleText(): string {
        if (notifications && notifications.length > 0) {
            if (notifications.length > 10) return "Many";
            else return notifications.length.toString();
        } else {
            return "No";
        }
    }

    return (
        <>
            <Modal
                onClick={() => {
                    dispatch(clearBidNotifications());
                    dispatch(setOpenBidNotificationModal(false));
                }}
            >
                <Container>
                    <Title>Limited time bid increases!</Title>
                    <Subtitle>
                        {generateSubtitleText()} campaigns have bid increases of
                        up to:{" "}
                    </Subtitle>
                    <BidIncreaseTag
                        increase={greatest.reward}
                        prevReward={greatest.prev}
                    />
                    <Subtitle>
                        This increase is only for a limited time, so promote it
                        while you can. The increase is active for:
                    </Subtitle>
                    <TimerSection times={times} />
                    <LoadingButton
                        onClick={() => setSelected(null)}
                        text="View Campaigns"
                        height="58px"
                        backgroundColor={colors.primaryBlue}
                        textColor={colors.white}
                        width="100%"
                    />
                </Container>
            </Modal>
            <AnimatePresence>
                <CampaignsModal
                    open={selected === null}
                    campaigns={campaigns}
                    onSelect={(campaign?: Campaign) => setSelected(campaign)}
                />
            </AnimatePresence>
        </>
    );
};

export default BidIncreaseNotificationModal;
