import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/high-res.css";
import "./customreactphoneinput.css";
import styled from "styled-components";
import { colors } from "src/constants";
import { BigBlueButton, OutlineButton } from "src/profile/components/Buttons";
import { get, getUser } from "src/Api";
import { coinToDollars, formatNumber, loggedIn } from "src/util";
import { AnimatePresence } from "framer-motion";
import ActionsModal from "src/modals/ActionsModal";
import { cashoutModal, fetchUser, logout } from "src/profile/actions";
import { useDispatch, useSelector } from "react-redux";
import ProfileModals from "src/profile/modals/ProfileModals";
import { GlobalState } from "src/reducers";
import EmojiModal from "src/modals/EmojiModal";
import CopyTextWrap from "src/components/CopyTextWrap";
import { getHasUIAccess, getLoggedInProfile } from "src/profile/selectors";
import { useHistory } from "react-router";
import { AccessStatuses } from "src/profile/types";

const Main = styled.div`
    background: ${colors.primaryBlue};
    height: 100vh;
    color: ${colors.white};
    display: flex;
    justify-content: center;
    padding: 24px;
`;

const InnerContainer = styled.div`
    max-width: 600px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
`;

const Header = styled.h2`
    font-size: 20px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
`;

const Body = styled.p`
    font-size: 20px;
    text-align: left;
`;

const Button = styled(OutlineButton)`
    width: 100%;
    background: ${colors.primaryBlue};
    color: white;
    height: 52px;
    border-radius: 26px;
    margin-bottom: 8px;
    :hover {
        opacity: 0.8;
        background: ${colors.primaryBlue};
    }
`;

const Logo = styled.img`
    margin-bottom: 24px;
    height: 24px;
    width: 24px;
`;
const Plus = styled.img`
    height: 12px;
    width: 12px;
    margin-right: 6.5px;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;

const BottomText = styled.div`
    font-size: 14px;
    color: white;
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }
`;
const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
const ModalHeader = styled.div`
    font-size: 24px;
    font-weight: 700;
`;

const ModalBody = styled.div`
    font-size: 14px;
`;

const Link = styled.a`
    color: ${colors.white};
    text-decoration: underline;
    cursor: pointer;
    text-underline-offset: 4px;
`;

interface Props {}

const AccessStatusBlocker = ({}: Props) => {
    const userID = localStorage.getItem("userid");
    const [balance, setBalance] = useState(0);
    const [open, setOpen] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();

    const profileState = useSelector((state: GlobalState) => {
        return state.profile;
    });

    useEffect(() => {
        dispatch(fetchUser());
    }, []);

    const getPublisher = async () => {
        try {
            const { data: userData } = await getUser("/get_user_data_v3", {
                userid: userID
            });
            setBalance(userData.balance);
        } catch (err) {}
    };

    async function tiktokLoginKitURL() {
        const redirectURI = `https://app.theplug.co/onboarding`;
        const res = await get("/v1/socialAccount/tcmCheck", {
            publisherId: userID,
            redirectURI
        });
        window.location.replace(res.data.data.redirectURL);
    }

    const profile = useSelector(getLoggedInProfile);
    const hasUIAccess = useSelector(getHasUIAccess);

    useEffect(() => {
        if (hasUIAccess) history.push("/");
        else {
            history.push("/onboarding");
        }
    }, [hasUIAccess]);
    useEffect(() => {
        getPublisher();
    }, []);

    return (
        <>
            <Main>
                <InnerContainer>
                    <Logo src={"/white-plug-icon.svg"} />
                    <Header>Service Suspended</Header>
                    <Body>
                        The Plug is only currently available for original
                        content creators on TikTok. More details are available
                        in{" "}
                        <Link href="https://blog.plugco.in/the-plug-new-focus/">
                            this blog post.
                        </Link>
                    </Body>
                    <Body>
                        Your account does not include a TikTok page that meets
                        our approval requirements, so your service has been
                        suspended.
                    </Body>

                    {profile?.accessStatus === AccessStatuses.BLOCKED && (
                        <Body>
                            If you believe this is a mistake, please connect
                            your TikTok account below.
                        </Body>
                    )}

                    <Body>
                        You can cash out your remaining balance anytime.
                    </Body>

                    <Body>Thanks, </Body>
                    <Body style={{ marginBottom: "auto" }}>
                        -The Plug Team{" "}
                    </Body>
                    {profile?.accessStatus === AccessStatuses.BLOCKED && (
                        <Button onClick={tiktokLoginKitURL}>
                            <Plus src="/plus_button/plus_button.png" /> Connect
                            TikTok account
                        </Button>
                    )}
                    {balance > 0 && (
                        <Button onClick={() => dispatch(cashoutModal())}>
                            Cash out Balance ({coinToDollars(balance)})
                        </Button>
                    )}
                    <Row>
                        <BottomText onClick={() => setOpen("support")}>
                            Need Help? Contact Us
                        </BottomText>
                        <BottomText onClick={() => setOpen("options")}>
                            More Options
                        </BottomText>
                    </Row>
                </InnerContainer>
            </Main>
            <AnimatePresence>
                {open === "options" ? (
                    <ActionsModal
                        localClearModal={() => setOpen("")}
                        actions={[
                            {
                                text: "Privacy",
                                callback: () =>
                                    window.open("http://plugco.in/privacy")
                            },
                            {
                                text: "Terms",
                                callback: () =>
                                    window.open("http://plugco.in/terms")
                            },
                            {
                                text: "Logout",
                                negative: true,
                                callback: logout
                            }
                        ]}
                    />
                ) : open === "support" ? (
                    <EmojiModal emoji="👋" localClearModal={() => setOpen("")}>
                        <ModalContainer>
                            <ModalHeader>Need Help?</ModalHeader>
                            <ModalBody>
                                We’re here for you! Send us a message at the
                                email below and we’ll get back to you as soon as
                                we can.
                            </ModalBody>
                            <CopyTextWrap text="support@jetfuel.it" />

                            <BigBlueButton onClick={() => setOpen("")}>
                                Done
                            </BigBlueButton>
                        </ModalContainer>
                    </EmojiModal>
                ) : (
                    <></>
                )}
            </AnimatePresence>

            {profileState?.profile && (
                <ProfileModals profile={profileState.profile} />
            )}
        </>
    );
};

export default AccessStatusBlocker;
