import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCampaign } from "src/campaigns/selectors";
import CarouselModal from "src/modals/CarouselModalNew";
import { GlobalState } from "src/reducers";
import { openInstructionElementCarouselModal } from "src/ui/instructions-sets/actions";
import InstructionElementsCarouselItem from "../components/InstructionElementsCarouselItem";

const InstructionElementsCarouselModal = () => {
    const {
        instructionElementModalOpen,
        currentInstructionElement,
        instructionElementModalItemIds
    } = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets;
    });

    const scriptModal = currentInstructionElement === "Scripts";

    const currentCampaign = useSelector(selectCampaign);

    const dispatch = useDispatch();
    function closeModal(): void {
        dispatch(openInstructionElementCarouselModal(false));
    }

    return (
        <CarouselModal
            open={instructionElementModalOpen}
            closeModal={closeModal}
            arrows={scriptModal ? true : false}
            swipeable
            draggable={scriptModal ? false : true}
            infinite
            keyBoardControl={scriptModal ? false : true}
            noMaxWidth
            fullHeight
        >
            {!!currentCampaign &&
                instructionElementModalItemIds.map((itemId: number) => {
                    return (
                        <InstructionElementsCarouselItem
                            key={itemId}
                            itemId={itemId}
                            campaign={currentCampaign}
                            closeModal={closeModal}
                        />
                    );
                })}
        </CarouselModal>
    );
};

export default InstructionElementsCarouselModal;
