import React from "react";
import { Channel } from "../types";
import {
    Channel as ChannelSendBird,
    withSendBird,
    sendBirdSelectors
    // @ts-ignore
} from "sendbird-uikit";
import ChannelInput from "./input/index";

interface Props {
    channel: Channel;
    setChannel: (channel: Channel) => void;
    goBack: VoidFunction;
    sdk: any;
    sendMessage: any;
    sendFileMessage: any;
}

const ChannelView = ({
    channel,
    setChannel,
    goBack,
    sdk,
    sendMessage,
    sendFileMessage
}: Props) => {
    return (
        <ChannelSendBird
            channelUrl={channel.sendBirdData.url}
            renderMessageInput={() => {
                return (
                    <ChannelInput
                        sdk={sdk}
                        channel={channel}
                        setChannel={setChannel}
                        goBack={goBack}
                        sendMessage={sendMessage}
                        sendFileMessage={sendFileMessage}
                    />
                );
            }}
        />
    );
};

export default withSendBird(ChannelView, (state: any) => {
    const sdk = sendBirdSelectors.getSdk(state);
    const sendMessage = sendBirdSelectors.getSendUserMessage(state);
    const sendFileMessage = sendBirdSelectors.getSendFileMessage(state);
    return {
        sdk,
        sendMessage,
        sendFileMessage
    };
});
