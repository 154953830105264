import React, { useState } from "react";
import MainContainer from "src/components/MainContainer";
import styled from "styled-components";
import CreatorSearchFooter from "./components/CreatorSearchFooter";
import CreatorSearchTabs from "./components/CreatorSearchTabs";

const Main = styled(MainContainer)`
    text-align: left;
`;

const SavedGroups = () => {
    // Local State ---------------------------------------------------
    const [selectedCreators, setSelectedCreators] = useState({});

    // JSX -----------------------------------------------------------
    return (
        <>
            <Main>
                <CreatorSearchTabs />
            </Main>

            <CreatorSearchFooter
                type="Saved Groups"
                selectedCreatorCount={Object.keys(selectedCreators).length}
            />
        </>
    );
};

export default SavedGroups;
