import { goBack } from "connected-react-router";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { breakpoints, colors, overlayBackgrounds } from "src/constants";
import { GlobalState } from "src/reducers";
import { setRouteModalOpen } from "src/ui/modals/actions";
import { setAModalIsOpened } from "src/ui/observer/actions";
import { preventChildBubbling } from "src/utils/functions/helpers";
import {
    clearButton,
    responsiveContainer,
    whiteBackgroundHoverState
} from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import withModalPortal from "./withModalPortal";
import { ChevronRight } from "src/components/icons/exports";

const Parent = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${overlayBackgrounds.light};
    text-align: left;
`;

const arrowButtonStyles = css`
    ${clearButton()};
    ${whiteBackgroundHoverState()};
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

const LeftArrowButton = styled.button`
    ${arrowButtonStyles};
    left: 20px;
    transform: rotate(180deg);
`;

const RightArrowButton = styled.button`
    ${arrowButtonStyles};
    right: 20px;
`;

const Page = styled(motion.main)<{ padding?: number }>`
    ${responsiveContainer()};
    padding: ${props => props.padding || 0}px;
    max-height: 90vh;
    background-color: ${colors.white};
    border-radius: 8px;
    overflow-y: auto;

    @media (max-width: ${breakpoints.tablet}px) {
        margin: 0px 15px;
    }
`;

interface Props {
    children: React.ReactNode;
    padding?: number;
    leftButtonOnClick?: VoidFunction;
    rightButtonOnClick?: VoidFunction;
}

const RouteModal = ({
    children,
    padding,
    leftButtonOnClick,
    rightButtonOnClick
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------

    // Local State ---------------------------------------------------
    const { routeModalOpen } = useSelector((state: GlobalState) => {
        return state.ui.modals;
    });

    // Local Constants -----------------------------------------------
    const locationId = document.getElementById("portal-modals");

    // Local Functions -----------------------------------------------
    function handleParentClick(): void {
        dispatch(setRouteModalOpen(false));
        // dispatch(goBack());
    }

    function handleLeftArrowClick(
        event: React.MouseEvent<HTMLButtonElement>
    ): void {
        event.stopPropagation();
        if (leftButtonOnClick) leftButtonOnClick();
    }

    function handleRightArrowClick(
        event: React.MouseEvent<HTMLButtonElement>
    ): void {
        event.stopPropagation();
        if (rightButtonOnClick) rightButtonOnClick();
    }

    // Effects -------------------------------------------------------
    useEffect(() => {
        dispatch(setAModalIsOpened(true));
        return function cleanUp(): void {
            // dispatch(setRouteModalOpen(false));
            dispatch(setAModalIsOpened(false));
        };
    }, []);

    // JSX -----------------------------------------------------------
    return (
        <Parent onClick={handleParentClick}>
            {leftButtonOnClick && (
                <LeftArrowButton type="button" onClick={handleLeftArrowClick}>
                    <ChevronRight />
                </LeftArrowButton>
            )}

            <Page
                onClick={preventChildBubbling}
                initial={{
                    opacity: 0,
                    transform: "translateY(-8px)"
                }}
                animate={{
                    opacity: 1,
                    transform: "translateY(0px)"
                }}
                exit={{ opacity: 0, transform: "translateY(-8px)" }}
                padding={padding}
            >
                {children}
            </Page>

            {rightButtonOnClick && (
                <RightArrowButton type="button" onClick={handleRightArrowClick}>
                    <ChevronRight />
                </RightArrowButton>
            )}
        </Parent>
    );
};

export default withModalPortal(RouteModal);
