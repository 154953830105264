import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { Campaign } from "src/campaigns/types";
import { Media, PlugLink } from "../../../media/types";
import {
    fetchLinkForPlug,
    fetchLinkForPlugSuccess,
    fetchLinkParams
} from "src/links/actions";
import { storage } from "../../../firebase/FirebaseConfig";
import styled from "styled-components";
import CarouselInfo from "../../../modals/CarouselInfo";
import CampaignCarouselDetails from "./CampaignCarouselDetails";
import CampaignReward from "../../details/components/CampaignReward";
import { colors } from "../../../constants";

const Main = styled.div`
    width: 100vw;
    max-width: 500px;
    padding-bottom: 20px;
    color: white;
    text-align: left;
    font-weight: 300;
    user-select: none;
`;

interface Props {
    media: Media;
    campaign: Campaign;
    closeModal: () => void;
    plugLink?: PlugLink | null;
    fetchLinkForPlug: (params: fetchLinkParams) => void;
    fetchLinkForPlugSuccess: (link: PlugLink) => void;
}

const CampaignCarouselCell = ({
    media,
    campaign,
    closeModal,
    plugLink,
    fetchLinkForPlug,
    fetchLinkForPlugSuccess
}: Props) => {
    const [mediaUrl, setMediaUrl] = useState(media.url);
    const [coverUrl, setCoverUrl] = useState(media.coverPhotoUrl);

    useEffect(() => {
        const mediaKey = media.key;
        const mediaCoverKey = media.coverPhoto;
        const linkPrefix = campaign.linkUrlPrefix;

        if (mediaCoverKey && !coverUrl) {
            storage
                .ref(`/${linkPrefix}/${mediaCoverKey}`)
                .getDownloadURL()
                .then((url: string) => {
                    setCoverUrl(url);
                })
                .catch((error: string) => {
                    console.log("cover error");
                });
        }

        if (!mediaUrl) {
            storage
                .ref(`/${linkPrefix}/${mediaKey}`)
                .getDownloadURL()
                .then((url: string) => {
                    setMediaUrl(url);
                })
                .catch((error: string) => {
                    console.log(error);
                });
        }
    }, []);

    function linkClicked() {
        if (plugLink) {
            fetchLinkForPlugSuccess(plugLink);
        } else {
            fetchLinkForPlug(media);
        }
    }

    return (
        <Main>
            <CarouselInfo
                appIconUrl={campaign.imageUrl}
                campaignName={campaign.campaignName}
                amountComponent={
                    <CampaignReward
                        countryPlatformReward={campaign.countryPlatformReward}
                        rewardType={campaign.rewardType}
                        noPlatform
                        color={colors.white}
                    />
                }
                mediaUrl={mediaUrl || ""}
                mediaType={media.type}
                closeModal={closeModal}
                isPortrait={media.isPortrait}
            />
            <CampaignCarouselDetails
                mediaId={media.id}
                mediaUrl={mediaUrl || ""}
                linkClicked={linkClicked}
            />
        </Main>
    );
};

const mapStateToProps = (state: GlobalState, ownProps: { media: Media }) => ({
    plugLink:
        state.links.linksForPlugs[
            `${ownProps.media.campaignId}-${ownProps.media.id}`
        ]
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchLinkForPlug: (params: fetchLinkParams) =>
        dispatch(fetchLinkForPlug(params)),
    fetchLinkForPlugSuccess: (link: PlugLink) =>
        dispatch(fetchLinkForPlugSuccess(link))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignCarouselCell);
