import { Action, NormalizedActionGeneric, NormalizedResponse } from "src/types";
import { Conversation } from "../types/Conversation";

export const UPDATE_INTERNAL_NOTES_CONVERSATION =
    "UPDATE_INTERNAL_NOTES_CONVERSATION";

export function updateInternalNotesConversation(
    response: NormalizedResponse<Conversation, string[]>,
    payload: { submissionSetId: number }
): NormalizedActionGeneric<Conversation, string[]> {
    return { type: UPDATE_INTERNAL_NOTES_CONVERSATION, response, payload };
}

// -----------------------------------------------------------------------------

export const CREATE_INTERNAL_NOTES_CHANNEL = "CREATE_INTERNAL_NOTES_CHANNEL";
export const CANCEL_INTERNAL_NOTES_CHANNEL = "CANCEL_INTERNAL_NOTES_CHANNEL";

export function createInternalNotesChannel(submissionSetId: number): Action {
    return {
        type: CREATE_INTERNAL_NOTES_CHANNEL,
        payload: { submissionSetId }
    };
}

export function cancelInternalNotesChannel() {
    return { type: CANCEL_INTERNAL_NOTES_CHANNEL };
}

// -----------------------------------------------------------------------------

export const CREATE_INTERNAL_NOTES_CONVERSATION =
    "CREATE_INTERNAL_NOTES_CONVERSATION";

export function createInternalNotesConversation(
    submissionSetId: number
): Action {
    return {
        type: CREATE_INTERNAL_NOTES_CONVERSATION,
        payload: { submissionSetId }
    };
}
