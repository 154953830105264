import React from "react";
import styled from "styled-components";
import { colors } from "../../constants";
import { FlexCentered } from "../CommonStyles";

const Main = styled.div`
    ${FlexCentered};
    background-color: ${colors.lightGray};
    border-radius: 23px;
    height: 46px;
    min-width: 46px;
    cursor: pointer;
    user-select: none;
`;

interface Props {
    onClick: () => void;
}

const DesktopNavButton: React.FC<Props> = ({ children, onClick }) => (
    <Main onClick={onClick}>{children}</Main>
);

export default DesktopNavButton;
