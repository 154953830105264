import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import SearchTag from "src/components/SearchTag";
import { CampaignFilters } from "src/campaigns/types";
import { getFiltersDisplayText } from "src/ui/campaigns/selectors";
import { GlobalState } from "src/reducers";

const SortFilterContainer = styled.div`
    position: sticky;
    padding-top: 13px;
    padding-bottom: 7px;
`;

const Tags = styled.div`
    ${FlexVerticallyCentered};
    flex-wrap: wrap;
    overflow-x: auto;
    position: sticky;
`;

interface Props {
    openSortModal: () => void;
    openFilterModal: () => void;
    sortBy: string;
    filters: CampaignFilters;
}

const CampaignSortFilter = ({
    openSortModal,
    openFilterModal,
    sortBy,
    filters
}: Props) => {
    const filtersDisplayText = useSelector((state: GlobalState) =>
        getFiltersDisplayText(state)
    );

    return (
        <SortFilterContainer>
            <Tags>
                <SearchTag
                    text={sortBy}
                    color="white"
                    iconSrc="/tag-teal-arrow-icon.svg"
                    onClick={openSortModal}
                />
                <SearchTag
                    text={filtersDisplayText}
                    color="white"
                    iconSrc="/tag-teal-plus-icon.svg"
                    onClick={openFilterModal}
                />
            </Tags>
        </SortFilterContainer>
    );
};

export default CampaignSortFilter;
