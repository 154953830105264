import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import MediaCard from "../../../media/view/MediaCard";
import { GlobalState } from "../../../reducers";
import { Highlight, HighlightMedia } from "../../types";
import {
    fetchLinkForPlug,
    fetchLinkForPlugSuccess,
    fetchLinkParams
} from "src/links/actions";
import { PlugLink } from "../../../media/types";
import { campaignHighlightDownloadTypesModal } from "../../../ui/highlights/actions";
import MediaPlacementOverlay from "../../medias/components/MediaPlacementOverlay";
import useInViewDispatch from "../../../hooks/useInViewDispatch";
import { submitEvent } from "../../../events/actions";
import ShareButton from "src/components/ShareButton";

const Main = styled.div`
    padding: 5px;
`;

interface Props {
    plugLink: PlugLink | null;
    highlight: Highlight;
    highlightMedia: HighlightMedia;
    rank: number;
    fetchLinkForPlug: (params: fetchLinkParams) => void;
    fetchLinkForPlugSuccess: (link: PlugLink) => void;
    onMediaClick: () => void;
    campaignHighlightDownloadTypesModal: (highlightId: number | null) => void;
}

const PromoteHighlightCell = ({
    plugLink,
    highlight,
    highlightMedia,
    rank,
    fetchLinkForPlug,
    fetchLinkForPlugSuccess,
    onMediaClick,
    campaignHighlightDownloadTypesModal
}: Props) => {
    const ref = useInViewDispatch(
        submitEvent({
            event_name: "highlight_viewed",
            event_extra: {
                highlightId: highlight.highlightsPageId,
                mediaId: highlightMedia.id,
                rank
            }
        })
    );

    function linkClicked() {
        if (plugLink) {
            fetchLinkForPlugSuccess(plugLink);
        } else {
            fetchLinkForPlug({
                id: highlightMedia.id,
                externalPageId: highlightMedia.externalPageId,
                campaignId: parseInt(highlight.campaignId),
                isPortrait: highlightMedia.isPortrait
            });
        }
    }

    const { IGStoryMedia, feedMedia, singleMedia, doubleMedia } = highlight;

    const isAllMediaNull =
        !IGStoryMedia && !feedMedia && !singleMedia && !doubleMedia;

    function downloadCallback(): void {
        campaignHighlightDownloadTypesModal(highlight.highlightsPageId);
    }

    return (
        <Main ref={ref}>
            <MediaCard
                media={{ ...highlightMedia }}
                linkPrefix={highlight.linkUrlPrefix}
                mediaCallback={onMediaClick}
                buttonCallbacks={{
                    linkCallback: linkClicked,
                    downloadCallback: isAllMediaNull
                        ? undefined
                        : downloadCallback
                }}
                allPortrait
            >
                <ShareButton
                    link={`/highlights?id=${highlight.highlightsPageId}`}
                />
                <MediaPlacementOverlay
                    metrics={highlight.metrics}
                    placement={highlight.placement}
                    campaignName={highlight.campaignName}
                />
            </MediaCard>
        </Main>
    );
};

const mapStateToProps = (
    state: GlobalState,
    { highlight }: { highlight: Highlight }
) => ({
    highlightMedia: state.entities.highlightMedias[highlight.media],
    plugLink:
        state.links.linksForPlugs[`${highlight.campaignId}-${highlight.media}`]
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchLinkForPlug: (params: fetchLinkParams) =>
        dispatch(fetchLinkForPlug(params)),
    fetchLinkForPlugSuccess: (link: PlugLink) =>
        dispatch(fetchLinkForPlugSuccess(link)),
    campaignHighlightDownloadTypesModal: (highlightId: number | null) =>
        dispatch(campaignHighlightDownloadTypesModal(highlightId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PromoteHighlightCell);
