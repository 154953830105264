import {
    all,
    call,
    put,
    takeEvery,
    fork,
    delay,
    takeLeading
} from "redux-saga/effects";
import {
    AGREED_TO_RULES,
    bioChangeSuccess,
    FETCH_CASHOUTS,
    FETCH_PUBLIC,
    FETCH_USER,
    FETCH_WEEKLY_LEADERBOARD,
    fetchCashoutsFailure,
    fetchCashoutsSuccess,
    fetchPublicSuccess,
    fetchConnectionsUserSuccess,
    fetchUserSuccess,
    fetchWeeklyLeaderboardFailure,
    fetchWeeklyLeaderboardSuccess,
    GET_FEES_FOR_CASHOUT,
    GET_FULLSCREEN_ALERTS,
    getFeesForCashoutFailure,
    getFeesForCashoutSuccess,
    getFullscreenAlertsSuccess,
    LOG_OUT,
    PUSH_WITH_ACCOUNT,
    pushWithAccountData,
    REQUEST_TIPALTI_CASHOUT,
    requestTipaltiCashoutFailure,
    requestTipaltiCashoutSuccess,
    RESEND_TIPALTI_EMAIL,
    RESOLVE_FULLSCREEN_ALERT,
    resolveFullscreenAlertSuccess,
    SAVE_SETTINGS,
    UPDATE_OR_CREATE_TIPALTI_USER,
    UPDATE_PHOTO,
    UPDATE_USER_FIELDS,
    UPDATE_USERNAME,
    updateOrCreateTipaltiUserFailure,
    updateOrCreateTipaltiUserSuccess,
    updatePhotoFailure,
    updatePhotoSuccess,
    updateUserFieldsFailure,
    updateUserFieldsSuccess,
    updateUsernameFailure,
    updateUsernameSuccess,
    fetchConnectionsPublicSuccess,
    fetchAccountsUserSuccess,
    fetchAccountsPublicSuccess,
    privateChangeSuccess,
    POST_RECOMMENDATION,
    deleteRecommendation,
    completeRecommendation,
    POST_CONNECTION,
    postConnection,
    postConnectionSuccess,
    fetchPublic,
    DELETE_CONNECTION,
    deleteConnectionSuccess,
    fetchMediaUserSuccess,
    fetchMediaPublicSuccess,
    fetchVerifyCodeSuccess,
    FETCH_VERIFY_CODE,
    POST_ACCOUNT,
    postAccountReplace,
    postAccountAdd,
    fetchPromotedUserSuccess,
    fetchInstagramHandleSuccess,
    fetchChatTokenSuccess,
    fetchPromotedPublicSuccess,
    fetchPublicFailure,
    fetchProfileUserSuccess,
    fetchEarningsUserSuccess,
    fetchConnectionsPublicFailure,
    fetchAccountsPublicFailure,
    fetchPromotedPublicFailure,
    fetchMediaPublicFailure,
    fetchUserFailure,
    fetchConnectionsUserFailure,
    FETCH_CONNECTIONS_USER,
    FETCH_ACCOUNTS_USER,
    fetchAccountsUserFailure,
    FETCH_PROMOTED_USER,
    fetchPromotedUserFailure,
    fetchMediaUserFailure,
    FETCH_MEDIA_USER,
    fetchConnectionsUser,
    fetchAccountsUser,
    fetchPromotedUser,
    fetchMediaUser,
    fetchConnectionsPublic,
    FETCH_CONNECTIONS_PUBLIC,
    fetchAccountsPublic,
    FETCH_ACCOUNTS_PUBLIC,
    fetchPromotedPublic,
    FETCH_PROMOTED_PUBLIC,
    fetchMediaPublic,
    FETCH_MEDIA_PUBLIC,
    fetchSearchTokenSuccess,
    UPDATE_USER_PAYPAL_CASHOUT_EMAIL,
    updateUserPaypalCashoutEmailSuccess,
    updateUserPaypalCashoutEmailFailure,
    requestPaypalCashoutSuccess,
    requestPaypalCashoutFailure,
    REQUEST_PAYPAL_CASHOUT,
    fetchVerifyCode,
    fetchPreApprovalCodeFailure,
    fetchPreApprovalCodeSuccess,
    FETCH_PRE_APPROVAL_CODE,
    applyPreApprovalCodeSuccess,
    applyPreApprovalCodeFailure,
    APPLY_PRE_APPROVAL_CODE,
    fetchUser
} from "./actions";
import { clearModal } from "../modals/actions";
import axios, {
    getApi,
    getUser,
    get,
    post,
    deleteReq,
    patch,
    setToken
} from "../Api";
import { goBack, push } from "connected-react-router";
import { Action, ResponseData } from "../types";
import { submitEvent } from "../events/actions";
import { Connection, SocialAccount } from "./types";
import { convertConnections, errorToString, loggedIn } from "../util";
import { PublicProfileError } from "./reducer";
import {
    fetchReferralCodeRequest,
    fetchReferredUsersRequest
} from "src/profile/referrals/saga";
import { fetchPublisherFlagsSuccess } from "./flags/actions";
import { normalize } from "normalizr";
import { publisherFlagList } from "./flags/schema";
import rateSaga, { fetchUserSocialAccountsRequest } from "./rates/saga";
import { fetchUserMedia } from "src/notifications/saga";
import { fetchDelayedCampaignsRequest } from "src/notifications/saga";
import linkListSaga, { fetchLinkListRequest } from "./link-lists/saga";
import {
    fetchCampaignGenresFailure,
    fetchCampaignGenresSuccess,
    fetchCampaignRegionsFailure,
    fetchCampaignRegionsSuccess,
    FETCH_CAMPAIGN_GENRES,
    FETCH_CAMPAIGN_REGIONS
} from "src/ui/campaigns/actions";
import {
    filterBannedCountries,
    parseFetchedData
} from "src/campaigns/utils/sortFilterUtils";
import {
    auth,
    getFirebaseUser,
    setFirebaseToken
} from "src/firebase/FirebaseConfig";
import { User } from "firebase";
import { getIntercomHash } from "src/auth/saga";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { isAxiosError } from "axios";

// Personal Profile Data
// -------------------------------------------------------------------
//WAYNE: this is the problem child

function* fetchUserRequest({ payload }: Action): any {
    try {
        const user: User = yield call(getFirebaseUser);

        yield fork(getIntercomHash);
        yield fork(getFullscreenAlertsRequest);
        const profileRes: ResponseData = yield call(
            getUser,
            "/get_user_data_v3",
            {}
        );

        const profile = profileRes.data;

        yield call(fetchPublisherFlagsRequest, profile.id);
        yield put(fetchUserSuccess(profile));
        yield put(
            fetchPublisherFlagsSuccess(
                normalize(profile.flags, publisherFlagList)
            )
        );
        yield fork(fetchChatTokenRequest, profile.id);
        yield fork(fetchSearchTokenRequest, profile.id);
        yield fork(fetchProfileUserRequest, profile.id);
        yield fork(fetchEarningsUserRequest, profile.id);
        yield fork(
            fetchConnectionsUserRequest,
            fetchConnectionsUser(profile.id)
        );
        yield fork(fetchUserSocialAccountsRequest);
        yield fork(fetchAccountsUserRequest, fetchAccountsUser(profile.id));
        yield fork(fetchPromotedUserRequest, fetchPromotedUser(profile.id));
        yield fork(fetchMediaUserRequest, fetchMediaUser(profile.id));
        yield fork(fetchInstagramHandleRequest);
        yield fork(fetchWeeklyLeaderboardRequest);
        yield fork(fetchLinkListRequest);
        yield fork(fetchReferredUsersRequest);
        yield fork(fetchReferralCodeRequest);
        yield fork(fetchUserMedia);
        yield fork(fetchDelayedCampaignsRequest);
        yield fork(fetchCampaignGenresRequest);
        yield fork(fetchCampaignRegionsRequest);

        // yield fork(fetchShoutoutsRequest);
    } catch (error) {
        if (isAxiosError(error))
            if (error?.response?.status === 401) {
                if (loggedIn()) {
                    if (payload.retryCount < 20) {
                        yield delay(1000);
                        yield put(fetchUser(payload.retryCount + 1));
                    } else window.location.reload();
                }
            } else yield put(fetchUserFailure());
    }
}

function* fetchPublisherFlagsRequest(publisherId: number) {
    try {
        const { data } = yield call(
            get,
            `/v1/publisher/p:${publisherId}/flag`,
            {}
        );

        yield put(
            fetchPublisherFlagsSuccess(normalize(data.data, publisherFlagList))
        );
    } catch (error) {
        console.log("fetchPublisherFlagsRequest error: ", error);
    }
}

function* fetchProfileUserRequest(publisherId: number) {
    try {
        const profileRes: ResponseData = yield call(
            get,
            `/v1/publisher/p:${publisherId}/profile`,
            {}
        );

        const publicProfile = profileRes.data.data;

        yield put(fetchProfileUserSuccess({ publicProfile }));
    } catch (error) {}
}

function* fetchEarningsUserRequest(publisherId: number) {
    try {
        const earningsRes: ResponseData = yield call(
            get,
            `/v1/publisher/p:${publisherId}/revenue/summary`,
            {}
        );

        const earningsSummary = earningsRes.data.data;

        yield put(fetchEarningsUserSuccess({ earningsSummary }));
    } catch (error) {}
}

function* fetchConnectionsUserRequest({ payload }: Action) {
    try {
        const [connectionsRes, recommendationsRes]: ResponseData[] = yield all([
            call(get, `/v1/connection/p:${payload}`, { status: "all" }),
            call(get, `/v1/recommendation/connection/p:${payload}`, {})
        ]);

        const connections = connectionsRes.data.data;
        const recommendations = recommendationsRes.data.data;

        yield put(
            fetchConnectionsUserSuccess({ connections, recommendations })
        );
    } catch (error) {
        yield put(fetchConnectionsUserFailure());
    }
}

function* fetchAccountsUserRequest({ payload }: Action) {
    try {
        let publisherId = payload;
        if (!payload) publisherId = localStorage.getItem("userid");
        const accountsRes: ResponseData = yield call(
            get,
            "/v1/social_account",
            {
                publisher: `p:${publisherId}`
            }
        );

        const accounts = accountsRes.data.data;

        yield put(fetchAccountsUserSuccess({ accounts }));
    } catch (error) {
        yield put(fetchAccountsUserFailure());
    }
}

function* fetchPromotedUserRequest({ payload }: Action) {
    try {
        const promotedRes: ResponseData = yield call(get, "/v1/campaign", {
            publisher: `p:${payload}`,
            promoted: true
        });

        const promotedCampaigns = promotedRes.data.data;

        yield put(fetchPromotedUserSuccess({ promotedCampaigns }));
    } catch (error) {
        yield put(fetchPromotedUserFailure());
    }
}

function* fetchMediaUserRequest({ payload }: Action) {
    try {
        const mediaRes: ResponseData = yield call(get, "/v1/media", {
            owner: `p:${payload}`
        });

        const customMedia = mediaRes.data.data;

        yield put(fetchMediaUserSuccess({ customMedia }));
    } catch (error) {
        yield put(fetchMediaUserFailure());
    }
}

function* fetchChatTokenRequest(publisherId: number) {
    try {
        const tokenCookie = localStorage.getItem("chatToken");
        const oldToken = tokenCookie && JSON.parse(tokenCookie);

        if (oldToken && oldToken.expiry > Date.now()) {
            yield put(fetchChatTokenSuccess({ chatToken: oldToken }));
        } else {
            const chatRes: ResponseData = yield call(
                get,
                `/v1/chat/p:${publisherId}/token`,
                {}
            );

            const chatToken = chatRes.data.data;

            localStorage.setItem("chatToken", JSON.stringify(chatToken));

            yield put(fetchChatTokenSuccess({ chatToken }));
        }
    } catch (error) {}
}

function* fetchSearchTokenRequest(publisherId: number) {
    try {
        const tokenCookie = localStorage.getItem("searchToken");
        const oldToken = tokenCookie && JSON.parse(tokenCookie);

        if (oldToken && oldToken.expiry > Date.now()) {
            yield put(fetchSearchTokenSuccess({ searchToken: oldToken }));
        } else {
            const searchRes: ResponseData = yield call(
                get,
                `/v1/search/token`,
                {
                    publisherId: `p:${publisherId}`
                }
            );
            const searchToken = searchRes.data.data;

            localStorage.setItem("searchToken", JSON.stringify(searchToken));

            yield put(fetchSearchTokenSuccess({ searchToken }));
        }
    } catch (error) {}
}

// Other Profile Data
// -------------------------------------------------------------------

function* fetchPublicRequest({ payload }: Action) {
    const { selfId, otherId } = payload;

    try {
        const [connectionRes, statsRes]: ResponseData[] = yield all([
            call(get, `/v1/connection/p:${selfId}/p:${otherId}`, {}),
            call(get, `/v1/publisher/p:${otherId}/statistic`, {})
        ]);

        const connection: Connection = connectionRes.data.data;
        const stats = statsRes.data.data;

        if (connection.state === "denied") {
            yield put(
                fetchPublicFailure({ [otherId]: PublicProfileError.blocked })
            );
        } else {
            yield put(fetchPublicSuccess({ otherId, connection, stats }));

            yield fork(
                fetchConnectionsPublicRequest,
                fetchConnectionsPublic(connection)
            );
            yield fork(
                fetchAccountsPublicRequest,
                fetchAccountsPublic(connection)
            );
            yield fork(
                fetchPromotedPublicRequest,
                fetchPromotedPublic(connection)
            );
            yield fork(fetchMediaPublicRequest, fetchMediaPublic(connection));
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response && error.response.status === 404) {
                yield put(
                    fetchPublicFailure({ [otherId]: PublicProfileError.noUser })
                );
            } else {
                yield put(
                    fetchPublicFailure({
                        [otherId]: PublicProfileError.reloadable
                    })
                );
            }
        }
    }
}

function* fetchConnectionsPublicRequest({ payload }: Action) {
    const { otherId, connection } = payload;

    try {
        const [ownRes, allRes, mutualRes]: ResponseData[] = yield all([
            call(get, `/v1/connection/${connection.entity.id}`, {
                status: "all"
            }),
            call(get, `/v1/connection/${connection.other.id}`, {
                status: "active"
            }),
            call(get, `/v1/connection/${connection.entity.id}`, {
                mutual: connection.other.id
            })
        ]);

        const allConnections = convertConnections(
            connection.entity.id,
            ownRes.data.data,
            allRes.data.data
        );
        const mutualConnections = mutualRes.data.data;

        yield put(
            fetchConnectionsPublicSuccess({
                otherId,
                allConnections,
                mutualConnections
            })
        );
    } catch (error) {
        yield put(fetchConnectionsPublicFailure({ otherId }));
    }
}

function* fetchAccountsPublicRequest({ payload }: Action) {
    const { otherId } = payload;

    try {
        const accountsRes: ResponseData = yield call(
            get,
            `/v1/social_account`,
            {
                publisher: `p:${otherId}`
            }
        );

        const accounts = accountsRes.data.data;

        yield put(fetchAccountsPublicSuccess({ otherId, accounts }));
    } catch (error) {
        yield put(fetchAccountsPublicFailure({ otherId }));
    }
}

function* fetchPromotedPublicRequest({ payload }: Action) {
    const { otherId, connection } = payload;

    try {
        if (connection.state === "friend") {
            const promotedRes: ResponseData = yield call(get, "/v1/campaign", {
                publisher: `p:${otherId}`,
                promoted: true
            });

            const promotedCampaigns = promotedRes.data.data;

            yield put(
                fetchPromotedPublicSuccess({ otherId, promotedCampaigns })
            );
        }
    } catch (error) {
        yield put(fetchPromotedPublicFailure({ otherId }));
    }
}

function* fetchMediaPublicRequest({ payload }: Action) {
    const { otherId } = payload;

    try {
        const mediaRes: ResponseData = yield call(get, "/v1/media", {
            owner: `p:${otherId}`
        });

        const customMedia = mediaRes.data.data;

        yield put(fetchMediaPublicSuccess({ otherId, customMedia }));
    } catch (error) {
        yield put(fetchMediaPublicFailure({ otherId }));
    }
}

// Profile Posts and Deletes and Misc
// -------------------------------------------------------------------

// Connections

function* postConnectionRequest({ payload }: Action) {
    try {
        let { selfId, otherId, relationship } = payload;

        const connectionRes: ResponseData = yield call(
            post,
            `/v1/connection/${selfId}/${otherId}`,
            {
                relationship
            }
        );

        selfId = selfId.split("p:")[1];
        otherId = otherId.split("p:")[1];

        yield put(fetchPublic({ selfId, otherId }));

        const connection = connectionRes.data.data;

        yield put(postConnectionSuccess(connection));
        yield put(clearModal());
    } catch (error) {}
}

function* deleteConnectionRequest({ payload }: Action) {
    try {
        let { selfId, otherId } = payload;

        yield call(deleteReq, `/v1/connection/${selfId}/${otherId}`, {});

        yield put(deleteConnectionSuccess({ selfId, otherId }));

        selfId = selfId.split("p:")[1];
        otherId = otherId.split("p:")[1];

        yield put(fetchPublic({ selfId, otherId }));
        yield put(clearModal());
    } catch (error) {}
}

// Recommendations

function* postRecommendationRequest({ payload }: Action) {
    try {
        const { selfId, otherId, event } = payload;
        yield call(post, `/v1/recommendation/connection/${selfId}/${otherId}`, {
            event
        });

        if (event === "block") {
            yield put(deleteRecommendation(otherId));
        } else if (event === "click") {
            yield put(
                postConnection({ selfId, otherId, relationship: "friend" })
            );
            yield put(completeRecommendation(otherId));
            yield delay(2000);
            yield put(deleteRecommendation(otherId));
        }
    } catch (error) {}
}

// Accounts

function* postAccountRequest({ payload }: Action) {
    try {
        const edit = Boolean(payload.id);
        const path = edit
            ? `/v1/social_account/${payload.id}`
            : "/v1/social_account";

        delete payload.id;

        const { data } = yield call(post, path, payload);

        if (edit) {
            yield put(postAccountReplace(data.data));
        } else {
            yield fork(fetchVerifyCodeRequest, fetchVerifyCode(data.data.id));
            yield put(postAccountAdd(data.data));
        }
        // yield put(goBack());
    } catch (error) {}
}

function* postAccountBatchRequest({ payload }: Action) {
    try {
        const accountsToUpdate: SocialAccount[] = payload;

        yield all(
            accountsToUpdate.map(account => {
                return call(post, `/v1/social_account/${account.id}`, {
                    visibility: account.visibility
                });
            })
        );

        yield all(
            accountsToUpdate.map(account => {
                return put(postAccountReplace(account));
            })
        );
    } catch (error) {}
}

function* fetchVerifyCodeRequest({ payload }: Action) {
    try {
        const { data } = yield call(
            get,
            `/v1/social_account/${payload}/verify`,
            {}
        );

        yield put(fetchVerifyCodeSuccess(data.data.token));
    } catch (error) {}
}

// Settings

function* saveSettingsRequest({ payload }: Action) {
    try {
        const publisherId = payload.publisherId;

        const [bioRes, privateRes]: ResponseData[] = yield all([
            call(post, `/v1/publisher/p:${publisherId}/profile/bio`, {
                bio: payload.bio
            }),
            call(post, `/v1/publisher/p:${publisherId}/profile/privacy`, {
                privateAccount: payload.privateAccount
            })
        ]);

        yield put(bioChangeSuccess(bioRes.data.data.bio));
        yield put(privateChangeSuccess(privateRes.data.data.privateAccount));
        yield put(goBack());
    } catch (error) {}
}

// -------------------------------------------------------------------

function* fetchInstagramHandleRequest() {
    try {
        const { data } = yield call(
            getApi,
            "/get_instagram_verification_handle",
            {}
        );

        yield put(fetchInstagramHandleSuccess(data.instagram_handle));
    } catch (error) {}
}

function* fetchWeeklyLeaderboardRequest() {
    try {
        const { data } = yield call(
            get,
            "/v1/leaderboard?type=weekly_bonus",
            {}
        );

        yield put(
            fetchWeeklyLeaderboardSuccess(
                data.data.rankings,
                data.data.bonusBrackets
            )
        );
    } catch (error) {
        yield put(fetchWeeklyLeaderboardFailure());
    }
}

function* updateUsernameRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            `/v1/publisher/p:${payload.publisherId}/profile/username`,
            { username: payload.username }
        );

        if (data.changed === true) {
            yield put(updateUsernameSuccess(payload.username));
            yield put(clearModal());
        } else {
            yield put(updateUsernameFailure(data.error_message));
        }
    } catch (error) {
        yield put(updateUsernameFailure(errorToString(error)));
    }
}

function* updatePhotoRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/api/update_profile_photo_with_key",
            payload
        );

        if (data.changed === true) {
            yield put(updatePhotoSuccess(data.profile_photo_url));
            yield put(clearModal());
        } else {
            yield put(updatePhotoFailure("Plug Server request failed"));
        }
    } catch (error) {
        yield put(updatePhotoFailure(errorToString(error)));
    }
}

function* updateUserFieldsRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(post, "/user/update_user_fields", {
            ...payload,
            userid: localStorage.getItem("userid")
        });

        if (status === 200) {
            yield put(updateUserFieldsSuccess(data));
            // yield put(clearModal());
        } else {
            yield put(
                updateUserFieldsFailure("Update user fields request failed")
            );
        }
    } catch (error) {
        yield put(updateUserFieldsFailure(errorToString(error)));
    }
}

function* updateUserPaypalCashoutEmailRequest({ payload }: Action) {
    try {
        const { data } = yield call(
            patch,
            `/v1/publisher/p:${localStorage.getItem("userid")}`,
            {
                paypalCashoutEmail: payload.paypalCashoutEmail
            }
        );

        yield put(updateUserPaypalCashoutEmailSuccess(data.data));
    } catch (error) {
        yield put(updateUserPaypalCashoutEmailFailure(errorToString(error)));
    }
}

function* updateOrCreateTipaltiUserRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/tipalti/update_or_create_tipalti_user",
            { ...payload, userid: localStorage.getItem("userid") }
        );

        if (status === 200) {
            yield put(updateOrCreateTipaltiUserSuccess());
        } else {
            yield put(
                updateOrCreateTipaltiUserFailure("Request failed, try again")
            );
        }
    } catch (error) {
        yield put(
            updateOrCreateTipaltiUserFailure("Request failed, try again")
        );
    }
}

function* resendTipaltiEmailRequest() {
    try {
        const { data, status } = yield call(
            post,
            "/tipalti/resend_tipalti_email",
            {
                userid: localStorage.getItem("userid")
            }
        );

        if (status === 200) {
            yield put(updateOrCreateTipaltiUserSuccess());
        } else {
            yield put(
                updateOrCreateTipaltiUserFailure("Request failed, try again")
            );
        }
    } catch (error) {
        yield put(
            updateOrCreateTipaltiUserFailure("Request failed, try again")
        );
    }
}

function* fetchCashoutsRequest({}: Action) {
    try {
        const { data, status } = yield call(getUser, "/get_cashouts", {
            userid: localStorage.getItem("userid")
        });

        if (status === 200) {
            yield put(fetchCashoutsSuccess(data));
        } else {
            yield put(fetchCashoutsFailure("Request failed, try again"));
        }
    } catch (error) {
        yield put(fetchCashoutsFailure("Request failed, try again"));
    }
}

function* getFeesForCashoutRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            getUser,
            "/get_fees_for_cashout",
            payload
        );

        if (status === 200) {
            yield put(getFeesForCashoutSuccess(data));
        } else {
            yield put(getFeesForCashoutFailure("Cashout fees request failed"));
        }
    } catch (error) {
        yield put(getFeesForCashoutFailure("Cashout fees request failed"));
    }
}

function* requestTipaltiCashoutRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/user/request_tipalti_cashout_v2",
            { ...payload, userid: localStorage.getItem("userid") }
        );

        if (status === 200) {
            yield put(requestTipaltiCashoutSuccess());
            yield put(push("/profile"));
        } else {
            yield put(
                requestTipaltiCashoutFailure(
                    "Cashout failed, please try again."
                )
            );
        }
    } catch (error) {
        yield put(
            requestTipaltiCashoutFailure("Cashout failed, please try again.")
        );
        console.log(errorToString(error));
        yield put(setPillNotificationText(errorToString(error), "danger"));
    }
}

function* requestPaypalCashoutRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/user/request_paypal_cashout",
            { ...payload }
        );

        yield put(requestPaypalCashoutSuccess());
        yield put(push("/profile"));
    } catch (error) {
        yield put(
            requestPaypalCashoutFailure(
                "We could not process your request, please try again or contact support."
            )
        );
    }
}

function* agreedToRulesRequest() {
    try {
        const { data, status } = yield call(post, "/user/agreed_to_rules", {});

        if (status === 200) {
            yield put(fetchUserSuccess(data));
            yield put(submitEvent({ event_name: "pw_user_accepted_rules" }));

            if (localStorage.getItem("newUser")) {
                localStorage.removeItem("newUser");
                yield put(push("/"));
            } else {
                yield put(goBack());
            }
        } else {
            window.alert("Something went wrong, please refresh the page");
        }
    } catch (error) {
        window.alert("Something went wrong, please refresh the page");
    }
}

function* getFullscreenAlertsRequest() {
    try {
        const { data, status } = yield call(
            getApi,
            "/get_user_fullscreen_alerts",
            {}
        );

        if (status === 200) {
            if (data.length > 0) {
                yield put(clearModal());
            }
            yield put(getFullscreenAlertsSuccess(data));
        }
    } catch (error) {}
}

function* resolveFullscreenAlertRequest({ payload }: Action) {
    try {
        const { status } = yield call(
            post,
            "/api/resolve_fullscreen_alert",
            payload
        );

        if (status === 200) {
            yield put(resolveFullscreenAlertSuccess(payload.alert_id));
        } else {
            window.alert("Something went wrong, please refresh the page");
        }
    } catch (error) {
        window.alert("Something went wrong, please refresh the page");
    }
}

function* pushWithAccountPath({ payload }: Action) {
    yield put(pushWithAccountData(payload.account));
    yield put(push(payload.path));
}

function* logout() {
    indexedDB.deleteDatabase("firebaseLocalStorageDb");
    localStorage.clear();
    yield put(push("/onboarding"));
    window.location.reload();
}

function* fetchCampaignGenresRequest() {
    try {
        const {
            data: { data: genres },
            status
        } = yield call(get, "/v1/campaign/genre", null);

        if (status === 200) {
            yield put(fetchCampaignGenresSuccess(parseFetchedData(genres)));
        }
    } catch (error) {
        yield put(fetchCampaignGenresFailure());
    }
}

function* fetchCampaignRegionsRequest(): any {
    try {
        const allCountries = yield call(() =>
            fetch("/countries-list.json").then(res => res.json())
        );

        const {
            data: { data: bannedCountries },
            status
        } = yield call(get, "/v1/campaign/bannedCountries", null);

        if (status === 200) {
            yield put(
                fetchCampaignRegionsSuccess(
                    filterBannedCountries(allCountries, bannedCountries)
                )
            );
        }
    } catch (error) {
        yield put(fetchCampaignRegionsFailure());
    }
}

function* initializeFirebaseUser(): any {
    const firebaseToken = localStorage.getItem("firebaseToken");
    if (firebaseToken) yield call(setFirebaseToken, firebaseToken);
}

function* fetchPreApprovalCode() {
    try {
        const { data } = yield call(get, "/v1/preApprovalCode", {});
        yield put(fetchPreApprovalCodeSuccess(data.data));
    } catch (error) {
        yield put(fetchPreApprovalCodeFailure());
    }
}

function* applyPreApprovalCode({ payload }: Action) {
    try {
        if (payload.length !== 6) {
            return;
        }
        yield call(post, `/v1/preApprovalCode/${payload}/apply`, {});
        yield fork(fetchPreApprovalCode);
        yield put(applyPreApprovalCodeSuccess());
    } catch (error) {
        console.error(error);
        yield put(applyPreApprovalCodeFailure("Could not apply code"));
    }
}

function* fetchUserProfileRequest() {
    const profileRes: ResponseData = yield call(
        getUser,
        "/get_user_data_v2",
        {}
    );

    const profile = profileRes.data;

    yield call(fetchPublisherFlagsRequest, profile.id);
    yield put(fetchUserSuccess(profile));
}

export default function* profileSaga() {
    yield all([
        fork(initializeFirebaseUser),
        fork(rateSaga),
        fork(linkListSaga),
        takeEvery(LOG_OUT, logout),
        takeEvery(FETCH_USER.REQUEST, fetchUserRequest),
        takeEvery(FETCH_USER.PROFILE_REQUEST, fetchUserProfileRequest),
        takeEvery(FETCH_CONNECTIONS_USER.REQUEST, fetchConnectionsUserRequest),
        takeEvery(FETCH_ACCOUNTS_USER.REQUEST, fetchAccountsUserRequest),
        takeEvery(FETCH_PROMOTED_USER.REQUEST, fetchPromotedUserRequest),
        takeEvery(FETCH_MEDIA_USER.REQUEST, fetchMediaUserRequest),
        takeEvery(
            FETCH_WEEKLY_LEADERBOARD.REQUEST,
            fetchWeeklyLeaderboardRequest
        ),
        takeEvery(FETCH_PUBLIC.REQUEST, fetchPublicRequest),
        takeEvery(
            FETCH_CONNECTIONS_PUBLIC.REQUEST,
            fetchConnectionsPublicRequest
        ),
        takeEvery(FETCH_ACCOUNTS_PUBLIC.REQUEST, fetchAccountsPublicRequest),
        takeEvery(FETCH_PROMOTED_PUBLIC.REQUEST, fetchPromotedPublicRequest),
        takeEvery(FETCH_MEDIA_PUBLIC.REQUEST, fetchMediaPublicRequest),
        takeEvery(UPDATE_USERNAME.REQUEST, updateUsernameRequest),
        takeEvery(UPDATE_PHOTO.REQUEST, updatePhotoRequest),
        takeEvery(
            UPDATE_USER_PAYPAL_CASHOUT_EMAIL.REQUEST,
            updateUserPaypalCashoutEmailRequest
        ),
        takeEvery(UPDATE_USER_FIELDS.REQUEST, updateUserFieldsRequest),
        takeEvery(
            UPDATE_OR_CREATE_TIPALTI_USER.REQUEST,
            updateOrCreateTipaltiUserRequest
        ),
        takeEvery(RESEND_TIPALTI_EMAIL, resendTipaltiEmailRequest),
        takeEvery(FETCH_CASHOUTS.REQUEST, fetchCashoutsRequest),
        takeEvery(GET_FEES_FOR_CASHOUT.REQUEST, getFeesForCashoutRequest),
        takeLeading(
            REQUEST_TIPALTI_CASHOUT.REQUEST,
            requestTipaltiCashoutRequest
        ),
        takeEvery(REQUEST_TIPALTI_CASHOUT.SUCCESS, fetchUserRequest),
        takeLeading(
            REQUEST_PAYPAL_CASHOUT.REQUEST,
            requestPaypalCashoutRequest
        ),
        takeEvery(REQUEST_PAYPAL_CASHOUT.SUCCESS, fetchUserRequest),
        takeEvery(AGREED_TO_RULES, agreedToRulesRequest),
        takeEvery(GET_FULLSCREEN_ALERTS.REQUEST, getFullscreenAlertsRequest),
        takeEvery(
            RESOLVE_FULLSCREEN_ALERT.REQUEST,
            resolveFullscreenAlertRequest
        ),
        takeEvery(POST_ACCOUNT.REQUEST, postAccountRequest),
        takeEvery(POST_ACCOUNT.BATCH, postAccountBatchRequest),
        takeEvery(FETCH_VERIFY_CODE.REQUEST, fetchVerifyCodeRequest),
        takeEvery(PUSH_WITH_ACCOUNT.PATH, pushWithAccountPath),
        takeEvery(SAVE_SETTINGS, saveSettingsRequest),
        takeEvery(POST_CONNECTION.REQUEST, postConnectionRequest),
        takeEvery(DELETE_CONNECTION.REQUEST, deleteConnectionRequest),
        takeEvery(POST_RECOMMENDATION, postRecommendationRequest),
        takeEvery(FETCH_CAMPAIGN_REGIONS.REQUEST, fetchCampaignRegionsRequest),
        takeEvery(FETCH_CAMPAIGN_GENRES.REQUEST, fetchCampaignGenresRequest),
        takeEvery(FETCH_PRE_APPROVAL_CODE.REQUEST, fetchPreApprovalCode),
        takeEvery(APPLY_PRE_APPROVAL_CODE.REQUEST, applyPreApprovalCode)
    ]);
}
