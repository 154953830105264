import React from "react";
import styled from "styled-components";
import { MediaTab } from "src/ui/campaigns/actions";
import { sizes } from "src/constants";
import { CampaignMediaItemLoading } from "src/components/SkeletonLoader";

const Main = styled("div")`
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 20px;
`;

interface Props {
    tabType: MediaTab;
}

const MediaCarouselShimmer = (props: Props) => {
    const { tabType } = props;

    const width =
        tabType === "Stories" || tabType === "Yours"
            ? sizes.mediaItemWidth
            : sizes.mediaItemHeight;

    return (
        <Main>
            <CampaignMediaItemLoading width={width} />
            <CampaignMediaItemLoading width={width} />
            <CampaignMediaItemLoading width={width} />
            <CampaignMediaItemLoading width={width} />
            <CampaignMediaItemLoading width={width} />
            <CampaignMediaItemLoading width={width} />
            <CampaignMediaItemLoading width={width} />
        </Main>
    );
};

export default MediaCarouselShimmer;
