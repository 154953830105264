import React from "react";
import styled from "styled-components";
import { indicatorColors, indicatorGradients } from "./indicatorStyleConstants";

interface StyleProps {
    padding: number;
    borderRadius: number;
    background: string;
}

const Main = styled.div<StyleProps>`
    width: 100%;
    height: 100%;
    padding: ${props => props.padding}px;
    border-radius: ${props => props.borderRadius}px;
    background: ${props => props.background};
`;

export enum IndicatorBackgroundColor {
    blue,
    yellow,
    red,
    inactive,
    black,
    green
}

interface Props {
    color: IndicatorBackgroundColor;
    padding: number;
    borderRadius: number;
    children?: React.ReactNode;
}

const IndicatorBackground = ({
    color,
    padding,
    borderRadius,
    children
}: Props) => {
    let background;
    switch (color) {
        case IndicatorBackgroundColor.blue:
            background = indicatorGradients.blueGradient;
            break;
        case IndicatorBackgroundColor.yellow:
            background = indicatorGradients.yellowGradient;
            break;
        case IndicatorBackgroundColor.red:
            background = indicatorGradients.redGradient;
            break;
        case IndicatorBackgroundColor.inactive:
            background = indicatorColors.inactiveLight;
            break;
        case IndicatorBackgroundColor.black:
            background = indicatorColors.ended;
            break;
        case IndicatorBackgroundColor.green:
            background = indicatorColors.green;
    }
    return (
        <Main
            background={background}
            padding={padding}
            borderRadius={borderRadius}
        >
            {children}
        </Main>
    );
};

export default IndicatorBackground;
