import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";

import NotAuthedDetailsPage from "./components/NotAuthedDetailsPage";
import DownloadTypesModal from "src/campaigns/highlights/modals/DownloadTypesModal";
import CustomLinkModal from "src/campaigns/custom-links/modals/CustomLinkModal";
import PlacementsModal from "../placements/modals/PlacementsModal";
import ScriptModal from "src/campaigns/scripts/modals/ScriptModal";
import AuthedDetailsPage from "./components/AuthedDetailsPage";
import useQuery from "src/hooks/useQuery";
import { CustomLinksModalState } from "src/ui/custom-links/actions";
import { fetchCampaign } from "src/campaigns/actions";
import { GlobalState } from "src/reducers";
import { setCurrentCampaignId } from "src/ui/campaigns/actions";
import { Redirect } from "react-router";
import { selectCampaign } from "../selectors";
import { getAuthState } from "src/auth/selectors";
import ScriptCreationModal from "../scripts/modals/ScriptCreationModal";

interface Props {
    campaignId: number;
}

const CampaignDetailsRouter = (props: Props) => {
    const { campaignId } = props;

    const currentCampaign = useSelector(selectCampaign);
    const { userId } = useSelector(getAuthState);

    const dispatch = useDispatch();
    const query = useQuery();
    useEffect(() => {
        window.scrollTo(0, 0);

        dispatch(setCurrentCampaignId(campaignId));

        const mediaId = parseInt(query.get("mediaId") || "") || undefined;
        const scriptId = parseInt(query.get("scriptId") || "") || undefined;
        if (!currentCampaign?.access) {
            dispatch(fetchCampaign(campaignId, mediaId, scriptId));
        }
    }, []);

    const scriptModal = useSelector((state: GlobalState) => {
        return state.ui.script.scriptModal;
    });
    const isScriptCreate = useSelector(
        (state: GlobalState) => state.ui.script.create
    );

    const placementsModal = useSelector((state: GlobalState) => {
        return state.ui.highlights.placementsModal;
    });

    const customLinksModal = useSelector((state: GlobalState) => {
        return state.ui.customLink.customLinksModal;
    });

    const downloadTypesModal = useSelector((state: GlobalState) => {
        return state.ui.highlights.downloadTypesModal;
    });

    const campaignFetchFailure = useSelector((state: GlobalState) => {
        return state.failures.campaignFetch;
    });

    // When the :campaignId  param is not a number.
    if (!campaignId || campaignFetchFailure !== "") {
        return <Redirect to="/not-found" />;
    }

    return (
        <>
            {!!userId ? (
                <AuthedDetailsPage currentCampaign={currentCampaign} />
            ) : (
                <NotAuthedDetailsPage />
            )}
            <AnimatePresence>
                {scriptModal && <ScriptModal />}
                {isScriptCreate && (
                    <ScriptCreationModal campaign={currentCampaign} />
                )}
                {customLinksModal !== CustomLinksModalState.clear && (
                    <CustomLinkModal />
                )}

                {placementsModal && <PlacementsModal />}
            </AnimatePresence>
        </>
    );
};

export default CampaignDetailsRouter;
