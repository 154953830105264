import React, { useState } from "react";
import { Channel } from "../types";
import styled from "styled-components";
import SearchBar from "../../components/SearchBar";
import ChannelPreview from "./ChannelPreview";
import LockCard from "../../profile/components/LockCard";
import ChannelPreviewWrapper from "../components/ChannelPreviewWrapper";
import { breakpoints } from "src/constants";
import CreateButton from "../components/CreateButton";
import SearchWrapper from "../components/SearchWrapper";

interface Props {
    channels: Channel[];
    userId: number;
    push: (route: string) => void;
}

const InboxTab = ({ channels, userId, push }: Props) => {
    const [searchString, setSearchString] = useState("");

    return (
        <>
            <SearchWrapper>
                <SearchBar
                    searchString={searchString}
                    updateSearchString={setSearchString}
                />
            </SearchWrapper>

            <CreateButton />

            {channels.length === 0 && (
                <LockCard
                    text={`Looks like you don’t have any messages. Click the ${
                        window.innerWidth > breakpoints.tablet
                            ? "\"Create\" button"
                            : "button on the right"
                    }  to get started!`}
                    padding="10px 15px 15px 15px"
                />
            )}

            <ChannelPreviewWrapper>
                {channels
                    .filter(channel => {
                        if (searchString.length === 0) {
                            return true;
                        } else {
                            return (
                                channel.sendBirdData.name
                                    .toLowerCase()
                                    .includes(searchString.toLowerCase()) ||
                                channel.sendBirdData.members.some(
                                    (member: any) => {
                                        return (
                                            member.userId !== `p:${userId}` &&
                                            member.nickname
                                                .toLowerCase()
                                                .includes(
                                                    searchString.toLowerCase()
                                                )
                                        );
                                    }
                                )
                            );
                        }
                    })
                    .map(channel => {
                        return (
                            <ChannelPreview
                                key={channel.sendBirdData.url}
                                userId={userId}
                                channel={channel}
                                push={push}
                            />
                        );
                    })}
            </ChannelPreviewWrapper>
        </>
    );
};

export default InboxTab;
