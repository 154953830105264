import { normalize } from "normalizr";
import {
    all,
    call,
    fork,
    put,
    select,
    spawn,
    takeEvery
} from "redux-saga/effects";
import { Media } from "src/media/types";
import { ResponseData } from "src/types";
import { userMediaLoaded } from "src/ui/medias/actions";
import { get } from "../Api";
import {
    ADD_SYSTEM_NOTIFICATIONS,
    fetchMediaNotificationSuccess,
    NEW_SYSTEM_NOTIFICATION
} from "./actions";
import { Action } from "src/types";
import {
    fetchClickActivitySuccess,
    fetchDelayedCampaignsSuccess,
    FETCH_CLICK_ACTIVITY,
    FETCH_DELAYED_CAMPAIGNS
} from "./delayed-campaigns/actions";
import { delayedCampaignList } from "./schema";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { GlobalState } from "src/reducers";
import { fetchAccountsUser } from "src/profile/actions";

export interface CachedMedia {
    id: number;
    campaign: number;
    status: string | null;
}

export function* fetchUserMedia() {
    try {
        const id = localStorage.getItem("userid");
        const res: ResponseData = yield call(
            get,
            `/v1/media?owner=p:${id}`,
            {}
        );

        let mediaCache: { [key: number]: CachedMedia } = JSON.parse(
            localStorage.getItem("mediaCache") || "{}"
        );

        let mediaNotifications: CachedMedia[] = JSON.parse(
            localStorage.getItem("mediaNotifications") || "[]"
        );

        let mediaCacheCounts: { [key: number]: number } = JSON.parse(
            localStorage.getItem("mediaCacheCounts") || "{}"
        );

        let newMedia = res.data.data;
        let firstApproved: Media | null = null;
        newMedia.map((media: Media) => {
            let foundMedia = mediaCache[media.id];

            if (!mediaCacheCounts[media.campaignId])
                mediaCacheCounts[media.campaignId] = 1;
            else mediaCacheCounts[media.campaignId]++;

            if (
                !!foundMedia &&
                foundMedia.status !== media.mediaStatus &&
                media.mediaStatus === "approved" &&
                !firstApproved
            )
                firstApproved = media;

            if (
                (!!foundMedia && foundMedia.status !== media.mediaStatus) ||
                (!foundMedia && media.mediaStatus !== "approved")
            ) {
                mediaNotifications.push({
                    id: media.id,
                    campaign: media.campaignId,
                    status: media.mediaStatus
                });
            }
            mediaCache[media.id] = {
                id: media.id,
                campaign: media.campaignId,
                status: media.mediaStatus
            };
        });

        localStorage.setItem(
            "mediaNotifications",
            JSON.stringify(mediaNotifications)
        );
        localStorage.setItem("mediaCache", JSON.stringify(mediaCache));
        localStorage.setItem(
            "mediaCacheCount",
            JSON.stringify(mediaCacheCounts)
        );

        if (firstApproved)
            yield put(fetchMediaNotificationSuccess(firstApproved));
        yield put(userMediaLoaded());
    } catch (error) {
        console.log(error);
    }
}

export function* fetchDelayedCampaignsRequest() {
    try {
        const {
            data: { data }
        } = yield call(get, "/v1/campaign/delayedInstall", {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });

        yield put(
            fetchDelayedCampaignsSuccess(
                normalize(data.campaigns, delayedCampaignList),
                data.installsTitle
            )
        );
    } catch (error) {}
}

export function* fetchClickActivityRequest({
    payload: { campaignId }
}: Action) {
    try {
        const {
            data: { data }
        } = yield call(get, `/v1/campaign/${campaignId}/delayedInstall/chart`, {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });

        yield put(fetchClickActivitySuccess(data, campaignId));
    } catch (error) {}
}
export function* notificationController({ payload }: Action) {
    try {
        if (payload.notif_type === "social_verification") {
            yield put(fetchAccountsUser());
        }
        yield spawn(displayNotification, payload);
    } catch (e) {
        console.log(e);
    }
}
export function* displayNotification(notification: any) {
    try {
        const timeAppOpened: number = yield select(
            state => state.notifications.timeAppOpened
        );

        if (notification.created_at > timeAppOpened && notification.message) {
            yield put(
                setPillNotificationText(notification.message, "warning", 4000)
            );
        }
    } catch (e) {
        console.log(e);
    }
}

export default function* notificationsSaga() {
    yield all([
        takeEvery(
            FETCH_DELAYED_CAMPAIGNS.REQUEST,
            fetchDelayedCampaignsRequest
        ),
        takeEvery(FETCH_CLICK_ACTIVITY.REQUEST, fetchClickActivityRequest),
        takeEvery(NEW_SYSTEM_NOTIFICATION, notificationController)
    ]);
}
