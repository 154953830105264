import { DirectBuyStatus } from "src/buys/modules/direct-buy/types";
import { Action } from "../../types";
import {
    DirectBuyDetailsModalState,
    DirectBuyError,
    DirectBuySearchModalState
} from "./reducer";

export const DIRECT_BUY_MODAL = {
    SEARCH: "DIRECT_BUY_MODAL_SEARCH",
    DETAILS: "DIRECT_BUY_MODAL_DETAILS"
};

export const directBuySearchModal = (
    state: DirectBuySearchModalState
): Action => ({
    type: DIRECT_BUY_MODAL.SEARCH,
    payload: state
});

export const directBuyDetailsModal = (
    state: DirectBuyDetailsModalState
): Action => ({
    type: DIRECT_BUY_MODAL.DETAILS,
    payload: state
});

export const SET_DIRECT_BUYS_LOADING = "SET_DIRECT_BUYS_LOADING";

export const setDirectBuysLoading = (directBuysLoading: boolean): Action => ({
    type: SET_DIRECT_BUYS_LOADING,
    payload: directBuysLoading
});

export const SET_DIRECT_BUY_ERROR_STATE = "SET_DIRECT_BUY_ERROR_STATE";

export const setDirectBuyErrorState = (
    directBuyId: number,
    errorState: DirectBuyError
): Action => ({
    type: SET_DIRECT_BUY_ERROR_STATE,
    payload: { [directBuyId]: errorState }
});

export const TOGGLE_DIRECT_BUY_STEP_EXPANDED_STATE =
    "TOGGLE_DIRECT_BUY_STEP_EXPANDED_STATE";

export const toggleDirectBuyStepExpandedState = (
    directBuyStepId: number
): Action => ({
    type: TOGGLE_DIRECT_BUY_STEP_EXPANDED_STATE,
    payload: directBuyStepId
});

export const SET_DIRECT_BUY_SEARCH = "SET_DIRECT_BUY_SEARCH";

export const setDirectBuySearch = (ids: number[]): Action => ({
    type: SET_DIRECT_BUY_SEARCH,
    payload: ids
});

export const CLEAR_DIRECT_BUY_SEARCH = "SET_DIRECT_BUY_SEARCH";

export const clearDirectBuySearch = (): Action => ({
    type: CLEAR_DIRECT_BUY_SEARCH,
    payload: null
});

export const SET_SELECTED_ACCOUNTS = "SET_SELECTED_ACCOUNTS";

export const setSelectedAccounts = (accounts: number[]): Action => ({
    type: SET_SELECTED_ACCOUNTS,
    payload: accounts
});

export const SET_STATUS_FILTERS = "SET_STATUS_FILTERS";

export const setStatusFiltersState = (statuses: DirectBuyStatus[]) => ({
    type: SET_STATUS_FILTERS,
    payload: statuses
});

export const SET_STORED_STATUSES = "SET_STORED_STATUSES";

export const setStoredStatuses = (statuses: DirectBuyStatus[]) => ({
    type: SET_STORED_STATUSES,
    payload: statuses
});
