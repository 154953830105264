import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FullscreenAlert } from "../profile/types";
import { resolveFullscreenAlert } from "../profile/actions";
import { push } from "connected-react-router";
import { freezeBody, thawBody } from "../util";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { colors, fonts } from "../constants";
import EmojiModal from "../modals/EmojiModal";
import { useIntercom } from "react-use-intercom";

const Section = styled.div`
    margin: 15px 0;
    line-height: 1.2em;
`;

const Title = styled.div`
    font-size: 20px;
    line-height: 1.2em;
    font-weight: 600;
`;

const Text = styled(Section)`
    font-size: ${fonts.subtitle}px;
    color: ${colors.primaryGray};
    line-height: 1.2em;
`;

interface Props {
    fullscreenAlert: FullscreenAlert;
    resolveFullscreenAlert: (alertId: number) => void;
    push: (route: string) => void;
}

const FullscreenAlertModal = ({
    fullscreenAlert,
    resolveFullscreenAlert,
    push
}: Props) => {
    const { update, showNewMessages } = useIntercom();

    useEffect(() => {
        freezeBody();

        return thawBody;
    }, []);

    function emoji() {
        switch (fullscreenAlert.icon_type) {
            case "thinking":
                return "🤔";
            case "stop":
                return "✋";
            case "party":
                return "🎉";
            default:
                return null;
        }
    }

    function buttonClicked() {
        const buttonDestination = fullscreenAlert.button_destination;

        if (buttonDestination === "url") {
            window.open(fullscreenAlert.button_url);
        } else if (buttonDestination === "rules") {
            push("/rules");
        }

        if (!fullscreenAlert.is_blocking) {
            resolveFullscreenAlert(fullscreenAlert.id);
        }
    }

    return (
        <EmojiModal
            emoji={emoji()}
            localClearModal={() => {
                if (!fullscreenAlert.is_blocking) {
                    resolveFullscreenAlert(fullscreenAlert.id);
                }
            }}
            isDismissable={!fullscreenAlert.is_blocking}
        >
            <Title>{fullscreenAlert.title}</Title>
            <Section>{fullscreenAlert.subtitle}</Section>
            <Text>{fullscreenAlert.text}</Text>
            <Button
                block
                variant="success"
                style={{ marginTop: "25px" }}
                onClick={() => {
                    buttonClicked();
                    if (fullscreenAlert.button_destination === "intercom") {
                        update({
                            customAttributes: { disable_chat_bot: false }
                        });
                        showNewMessages();
                    }
                }}
            >
                {fullscreenAlert.button_text}
            </Button>
        </EmojiModal>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    resolveFullscreenAlert: (alertId: number) =>
        dispatch(resolveFullscreenAlert(alertId)),
    push: (route: string) => dispatch(push(route))
});

export default connect(null, mapDispatchToProps)(FullscreenAlertModal);
