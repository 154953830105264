import React from "react";
import { Media } from "../../media/types";
import styled from "styled-components";
import { fonts } from "src/constants";

const VerticallyCentered = styled.div`
    display: flex;
    align-items: center;
`;

const Main = styled(VerticallyCentered)`
    margin-top: 15px;
    cursor: pointer;
`;

const MediaIcon = styled.img`
    height: 46px;
    width: 46px;
    min-width: 46px;
    border-radius: 6px;
    margin-right: 10px;
    object-fit: cover;
`;

const MediaInfo = styled.div`
    overflow: hidden;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const HideOverflow = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;
`;

const Title = styled(HideOverflow)`
    font-size: ${fonts.subtitle}px;
`;

const Checkbox = styled.img`
    padding-left: 10px;
    margin-left: auto;
`;

interface Props {
    media: Media;
    currentMedia: Media;
    setCurrentMedia: (media: Media) => void;
    mediaClicked: () => void;
}

const CustomLinksCreateRow = ({
    media,
    currentMedia,
    setCurrentMedia,
    mediaClicked
}: Props) => {
    function imageUrl() {
        if (media.url && media.type === "photo") {
            return media.url;
        } else if (media.coverPhotoUrl) {
            return media.coverPhotoUrl;
        } else {
            return "/other_profile/other_profile@2x.png";
        }
    }

    function renderButton() {
        if (media.id === currentMedia.id) {
            return <Checkbox src={"/checkbox_selected.svg"} />;
        } else {
            return <Checkbox src={"/checkbox_unselected.svg"} />;
        }
    }

    return (
        <Main onClick={() => setCurrentMedia(media)}>
            <MediaIcon
                src={imageUrl()}
                onClick={e => {
                    e.stopPropagation();
                    mediaClicked();
                }}
            />
            <MediaInfo
                onClick={e => {
                    e.stopPropagation();
                    mediaClicked();
                }}
            >
                <Title>
                    <strong>Media Id: {media.id}</strong>
                </Title>
            </MediaInfo>
            {renderButton()}
        </Main>
    );
};

export default CustomLinksCreateRow;
