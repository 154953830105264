import React from "react";
import {
    Main,
    MiddleContainer,
    PhotoLoading,
    TagLoading,
    titleLineHeight,
    subtitleLineHeight
} from "./styledComponents";
import { TextLoading } from "../../../components/SkeletonLoader";

const DirectBuyDetailsHeaderLoading = () => {
    return (
        <Main>
            <PhotoLoading />
            <MiddleContainer>
                <TextLoading height={titleLineHeight} width={40} />
                <TextLoading height={subtitleLineHeight} width={60} />
            </MiddleContainer>
            <TagLoading />
        </Main>
    );
};

export default DirectBuyDetailsHeaderLoading;
