import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { colors, fonts, fontWeight } from "src/constants";
import { EventInput } from "src/utils/types/form";
import styled from "styled-components";
import { AbstractPaymentType, PendingOpenBuyPaymentItem } from "../types";
import { Dropdown as Container } from "./PaymentSetDropdown";
import {
    pushPendingOpenBuyPaymentItem,
    updatePendingOpenBuyPaymentItem,
    removeCreatorSpecificPayment
} from "../open-buy-payments/actions";

export const InputContainer = styled(Container)`
    justify-content: flex-start;
    gap: 8px;
    cursor: auto;
`;

export const Dollars = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.black};
    gap: 0px;
`;

const DeleteIcon = styled.img`
    cursor: pointer;
    vertical-align: middle;
    margin-left: auto;
`;

export const PaymentInput = styled.input<{
    hasInput?: boolean;
    width?: number | string;
}>`
    background: transparent;
    border: none;
    width: ${props => (props.width ? `${props.width}` : "auto")};
    font-weight: ${props =>
        props.hasInput ? `${fontWeight.medium}` : `${fontWeight.default}`};
    font-size: ${fonts.smallTitle}px;
    line-height: 14px;
    color: ${props =>
        props.hasInput ? `${colors.black}` : `${colors.primaryGray}`};

    &:focus {
        border: none;
        outline: none;
    }

    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${colors.primaryGray};
    }
    :-ms-input-placeholder {
        color: ${colors.primaryGray};
    }
`;

const SelectedPaymentType = styled.span`
    font-weight: ${fontWeight.medium};
    font-size: ${fonts.smallTitle}px;
    color: ${colors.black};
`;

interface Props {
    openBuyId: number;
    instructionStepId: number;
    creatorGroupId: number | null;
    pendingOpenBuyPaymentItems: PendingOpenBuyPaymentItem[];
    abstractPaymentType: AbstractPaymentType;
}

const SingleInputPayment = ({
    openBuyId,
    instructionStepId,
    creatorGroupId,
    pendingOpenBuyPaymentItems,
    abstractPaymentType
}: Props) => {
    const dispatch = useDispatch();

    const [focus, setFocus] = useState(false);
    const [width, setWidth] = useState("");

    const paymentAmount =
        pendingOpenBuyPaymentItems.length > 0
            ? pendingOpenBuyPaymentItems[0].amount
            : "";

    // Use Effects ---------------------------------------------------

    useEffect(() => {
        adjustPaymentInputSize();
    }, [paymentAmount]);

    // Local Functions ---------------------------------------------------
    function handlePaymentAmountChange(event: EventInput): void {
        if (!/^\d*\.?\d*$/.test(event.currentTarget.value)) {
            return;
        }
        if (pendingOpenBuyPaymentItems.length === 0) {
            dispatch(
                pushPendingOpenBuyPaymentItem({
                    openBuyId,
                    instructionStepId,
                    creatorGroupId,
                    payment: {
                        paymentId:
                            abstractPaymentType ===
                            AbstractPaymentType.dollarsPerK
                                ? 1
                                : 2,
                        amount: event.currentTarget.value,
                        viewsMinimum: ""
                    }
                })
            );
        } else {
            dispatch(
                updatePendingOpenBuyPaymentItem({
                    openBuyId,
                    instructionStepId,
                    creatorGroupId,
                    paymentIndex: 0,
                    payment: {
                        paymentId:
                            abstractPaymentType ===
                            AbstractPaymentType.dollarsPerK
                                ? 1
                                : 2,
                        amount: event.currentTarget.value,
                        viewsMinimum: ""
                    }
                })
            );
        }
    }

    function adjustPaymentInputSize() {
        const paymentInput = document.getElementById(
            "paymentInput"
        ) as HTMLInputElement;

        if (paymentInput && paymentAmount.length < 1) {
            const newWidth = paymentInput.placeholder.length + 2 + "ch";
            setWidth(newWidth);
        } else {
            const newWidth = paymentAmount.length + 1 + "ch";
            setWidth(newWidth);
        }
    }

    // Return JSX ---------------------------------------------------
    return (
        <InputContainer focused={focus}>
            <Dollars>
                {paymentAmount ? "$" : ""}
                <PaymentInput
                    type="text"
                    id="paymentInput"
                    placeholder="Input Payment Amount"
                    value={paymentAmount}
                    width={width}
                    onChange={handlePaymentAmountChange}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    hasInput={paymentAmount.length > 0}
                />
            </Dollars>

            <SelectedPaymentType>
                {abstractPaymentType === AbstractPaymentType.dollarsPerK &&
                    "per K"}
                {abstractPaymentType === AbstractPaymentType.flatRate &&
                    "Flat Fee"}
            </SelectedPaymentType>
            {creatorGroupId && (
                <DeleteIcon
                    src="/marketplace-black-circle-x.svg"
                    onClick={() => {
                        dispatch(
                            removeCreatorSpecificPayment({
                                openBuyId,
                                instructionStepId,
                                creatorGroupId
                            })
                        );
                    }}
                />
            )}
        </InputContainer>
    );
};

export default SingleInputPayment;
