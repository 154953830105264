import { NormalizedAction } from "../../types";
import { Asset } from "../types";
import {
    FETCH_CAMPAIGN_ASSET,
    FETCH_CAMPAIGN_ASSETS,
    FETCH_SCRIPT_ASSETS
} from "./actions";

export interface AssetsEntitiesState {
    [id: number]: Asset;
}

const initialAssetsState: AssetsEntitiesState = {};

const assetsEntitiesReducer = (
    state = initialAssetsState,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_CAMPAIGN_ASSETS.SUCCESS:
        case FETCH_SCRIPT_ASSETS.SUCCESS:
            return { ...state, ...action.response.entities.assets };
        case FETCH_CAMPAIGN_ASSET.SUCCESS:
            const assetId = action.response.result;
            const asset = action.response.entities.assets[assetId];
            const stateAfterAssetFetch = { ...state };
            stateAfterAssetFetch[assetId] = asset;
            return stateAfterAssetFetch;
        default:
            return state;
    }
};

export default assetsEntitiesReducer;
