import { NormalizedAction } from "../../types";
import { FETCH_SCRIPT_ASSETS } from "../assets/actions";
import { FETCH_SCRIPT_MEDIAS } from "../medias/actions";
import { Script } from "../types";
import {
    ADD_SCRIPT,
    FETCH_CAMPAIGN_SCRIPT,
    FETCH_CAMPAIGN_SCRIPTS,
    SCRIPT_ASSET_DELETE,
    SCRIPT_CREATE,
    SCRIPT_EDIT,
    SCRIPT_MEDIA_DELETE
} from "./actions";

export interface ScriptsEntitiesState {
    [id: number]: Script;
}

const initialScriptsState: ScriptsEntitiesState = {};

const updateScript = (
    key: string,
    state: ScriptsEntitiesState,
    action: NormalizedAction
) => {
    const scriptId = action.payload.scriptId;
    const script = state[scriptId];

    return {
        ...state,
        [scriptId]: {
            ...script,
            [key]: action.response.result
        }
    };
};

const scriptsEntitiesReducer = (
    state = initialScriptsState,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_CAMPAIGN_SCRIPTS.SUCCESS:
            return { ...state, ...action.response.entities.scripts };
        case FETCH_CAMPAIGN_SCRIPT.SUCCESS:
            const scriptId = action.response.result;
            const script = action.response.entities.scripts[scriptId];
            const stateAfterScriptFetch = { ...state };
            stateAfterScriptFetch[scriptId] = script;
            return stateAfterScriptFetch;
        case SCRIPT_CREATE.SUCCESS:
            return { ...state, ...action.response.entities.scripts };
        case ADD_SCRIPT:
            return { ...state, [action.payload.id]: action.payload };
        case SCRIPT_EDIT.SUCCESS:
            const { result, entities } = action.response;
            return {
                ...state,
                [result]: {
                    ...state[result],
                    ...entities.scripts[result]
                }
            };
        case SCRIPT_ASSET_DELETE.SUCCESS: {
            const { scriptId, assetId } = action.payload;
            return {
                ...state,
                [scriptId]: {
                    ...state[scriptId],
                    assets: state[scriptId].assets?.filter(id => id !== assetId)
                }
            };
        }
        case SCRIPT_MEDIA_DELETE.SUCCESS: {
            const { scriptId, mediaId } = action.payload;
            return {
                ...state,
                [scriptId]: {
                    ...state[scriptId],
                    medias: state[scriptId].medias?.filter(id => id !== mediaId)
                }
            };
        }
        case FETCH_SCRIPT_MEDIAS.SUCCESS:
            return updateScript("medias", state, action);
        case FETCH_SCRIPT_ASSETS.SUCCESS:
            return updateScript("assets", state, action);
        default:
            return state;
    }
};

export default scriptsEntitiesReducer;
