import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../reducers";
import { SocialAccount } from "../../../types";
import AccountForm from "./AccountForm";
import { useHistory } from "react-router";

interface Props {
    edit?: true;
}

const AccountFormController = ({ edit }: Props) => {
    const currentAccount = useSelector((state: GlobalState) => {
        return state.profile.currentAccount;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    useEffect(() => {
        if (!currentAccount && edit) {
            goBack();
        }
    }, [currentAccount]);

    if (!currentAccount && edit) {
        return null;
    } else if (currentAccount && edit) {
        return <AccountForm currentAccount={currentAccount} />;
    } else {
        return <AccountForm />;
    }
};

export default AccountFormController;
