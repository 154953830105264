import React from "react";

interface Props {
    style?: any;
    children: any;
}

const FlexColumn: React.FC<Props> = ({ style, children }) => {
    return (
        <div
            className="FlexColumn"
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
                padding: 20,
                ...style
            }}
        >
            {children}
        </div>
    );
};

export default FlexColumn;
