import { groupBy, merge } from "lodash";
import { Action, NormalizedActionGeneric } from "src/types";
import { GroupUserSettings } from "../types/GroupUserSettings";
import { UPDATE_GROUP_USER_SETTINGS_CHANNEL } from "./actions";

interface GroupUserSettingsByUser {
    [userId: string]: GroupUserSettings;
}

export interface GroupUserSettingsEntitiesState {
    [conversationId: string]: GroupUserSettingsByUser;
}

const initialGroupUserSettingsEntitiesState: GroupUserSettingsEntitiesState = {};

const groupUserSettingsEntitiesReducer = (
    state = initialGroupUserSettingsEntitiesState,
    action: Action
) => {
    switch (action.type) {
        case UPDATE_GROUP_USER_SETTINGS_CHANNEL:
            return { ...state, ...action.payload.normalizedSettings };
        default:
            return state;
    }
};

export default groupUserSettingsEntitiesReducer;
