import React from "react";
import { PublicProfile } from "../../types";
import { colors } from "../../../constants";
import styled from "styled-components";
import PaddingNoTop from "../../../components/PaddingNoTop";
import GenericContainer from "../../components/GenericContainer";
import ProfilePhoto from "../../../components/ProfilePhoto";
import { shortenNumber } from "../../../util";
import Platforms from "../../../social-accounts/Platforms";

const Main = styled(GenericContainer)`
    display: flex;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const Username = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
`;

const Following = styled.div`
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.primaryGray};
`;

interface Props {
    publicProfile: PublicProfile;
}

const OwnerCell = ({ publicProfile }: Props) => {
    return (
        <PaddingNoTop>
            <Main>
                <ProfilePhoto
                    profilePhotoUrl={publicProfile.profile.avatar}
                    status={publicProfile.profile.status}
                    photoSize={56}
                    style={{ marginRight: "12px" }}
                />
                <InfoContainer>
                    <Username>{publicProfile.profile.username}</Username>
                    <Following>
                        <strong>
                            {shortenNumber(
                                publicProfile.profile.tagLine.followerCount
                            )}
                        </strong>{" "}
                        Followers on{" "}
                        {Platforms[publicProfile.profile.tagLine.platform]
                            ? Platforms[publicProfile.profile.tagLine.platform]
                                  .title
                            : publicProfile.profile.tagLine.platform}
                    </Following>
                </InfoContainer>
            </Main>
        </PaddingNoTop>
    );
};

export default OwnerCell;
