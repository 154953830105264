import React, { useEffect } from "react";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import SimpleNavbar from "src/components/SimpleNavbar";
import { colors, fonts } from "src/constants";
import FullScreenModal from "src/modals/FullScreenModal";
import { ReactComponent as Cross } from "src/components/icons/cross.svg";
import LargeRoundedIcon from "src/profile/components/LargeRoundedIcon";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { selectDelayedCampaign } from "./selectors";
import { fetchClickActivity } from "./actions";
import ClickActivityChart from "./ClickActivityChart";

const Main = styled.div`
    padding: 12px 18px;
`;

const CampaignItem = styled.div`
    ${FlexVerticallyCentered}
    text-align: left;
    width: 100%;
`;

const Icon = styled(LargeRoundedIcon)`
    user-select: none;
`;

const Name = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
`;

const Subtitle = styled.div`
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;
    font-weight: 400;
    color: ${colors.primaryGray};
`;

const CrossIcon = styled(Cross)`
    user-select: none;
    cursor: pointer;
`;

interface Props {
    closeModal: () => void;
    campaignId: number;
}

const ClickActivityModal = ({ closeModal, campaignId }: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchClickActivity(campaignId));
    }, []);

    const delayedCampaign = useSelector((state: GlobalState) =>
        selectDelayedCampaign(state, campaignId)
    );

    const { clickActivity } = delayedCampaign;

    return (
        <FullScreenModal closeModal={closeModal} keepFrozenOnClose>
            <SimpleNavbar
                title="Activity"
                left={
                    <CrossIcon fill={colors.primaryGray} onClick={closeModal} />
                }
            />
            <Main>
                <CampaignItem>
                    <Icon src={delayedCampaign.campaignImageUrl} />
                    <div>
                        <Name>{delayedCampaign.campaignName}</Name>
                        <Subtitle
                            dangerouslySetInnerHTML={{
                                __html: delayedCampaign.subtitle
                            }}
                        />
                    </div>
                </CampaignItem>
            </Main>
            <ClickActivityChart clickActivity={clickActivity} />
        </FullScreenModal>
    );
};

export default ClickActivityModal;
