import { colors, maxWidthDesktop } from "src/constants";
import styled from "styled-components";

interface CardProps {
    marginTop?: string;
    marginBottom?: string;
}

const MainSection = styled.section<CardProps>`
    margin-top: ${props => props.marginTop || "0px"};
    margin-bottom: ${props => props.marginBottom || "0px"};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${maxWidthDesktop}px) {
        margin: 0px;
        border: none;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

export default MainSection;
