import React, { ReactNode } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import LoadingButton from "../../components/LoadingButton";
import { responsiveContainer } from "src/utils/styles/snippets";

const Main = styled.div`
    ${responsiveContainer()};
    height: 100%;

    font-size: ${fonts.subtitle}px;
    line-height: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const FlexContent = styled.div`
    flex: 1;
    padding: 0 24px;
    overflow-y: auto;

    & > * ~ * {
        margin-top: 8px;
    }
`;

const ButtonWrapper = styled.div`
    padding: 12px 18px 24px;
    border-top: 1px solid ${colors.mediumTeal};
`;

interface Props {
    children: ReactNode;
    next: () => void;
    buttonText?: string;
    buttonLoading?: boolean;
}

const IntroBuysScreen = ({
    children,
    next,
    buttonText,
    buttonLoading
}: Props) => (
    <Main>
        <FlexContent>{children}</FlexContent>
        <ButtonWrapper>
            <LoadingButton
                text={buttonText || "Next"}
                onClick={next}
                width="100%"
                height="48px"
                backgroundColor={colors.primaryBlue}
                textColor={colors.white}
                isLoading={buttonLoading}
            />
        </ButtonWrapper>
    </Main>
);

export default IntroBuysScreen;
