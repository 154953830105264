import { Action } from "src/types";
import { BidNotification } from "./reducer";

export const BID_NOTIFICATIONS = {
    SET: "SET_BID_NOTIFICATIONS_SET",
    CLEAR: "SET_BID_NOTIFCATIONS_CLEAR"
};

export const setBidNotifications = (notifications: BidNotification[]) => ({
    type: BID_NOTIFICATIONS.SET,
    payload: notifications
});

export const clearBidNotifications = (uid?: string) => ({
    type: BID_NOTIFICATIONS.CLEAR,
    payload: uid
});

export const OPEN_BID_NOTIFICATION_MODAL = "OPEN_BID_NOTIFICATION_MODAL";

export const setOpenBidNotificationModal = (open: boolean): Action => ({
    type: OPEN_BID_NOTIFICATION_MODAL,
    payload: open
});
