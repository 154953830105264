import { normalize } from "normalizr";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { get } from "src/Api";
import { Action } from "src/types";
import { fetchPublicProfileSuccess, FETCH_PUBLIC_PROFILE } from "./action";
import { publicProfileList, publicProfile } from "./schema";

function* fetchPublicProfileRequest({ payload: { userId } }: Action) {
    try {
        const { data } = yield call(
            get,
            `/v1/publisher/p:${userId}/profile`,
            {}
        );

        yield put(
            fetchPublicProfileSuccess(normalize(data.data, publicProfile))
        );
    } catch (error) {}
}

export default function* publicProfilesSaga() {
    yield all([
        takeEvery(FETCH_PUBLIC_PROFILE.REQUEST, fetchPublicProfileRequest)
    ]);
}
