import React, { KeyboardEvent } from "react";
import {
    Editor,
    Element as SlateElement,
    Node,
    Path,
    Range,
    Text,
    Transforms
} from "slate";
import CustomEditor from "./CustomEditor";

type KeyHandler = (
    editor: Editor,
    event: KeyboardEvent<HTMLInputElement>
) => void;

const handleEnter: KeyHandler = (
    editor: Editor,
    event: KeyboardEvent<HTMLInputElement>
) => {
    const isList = !!CustomEditor.getListType(editor);
    const isLink = CustomEditor.isLinkActive(editor);
    const { selection } = editor;
    if (selection) {
        if (isLink) {
            event.preventDefault();
            return;
        }
        if (isList) {
            event.preventDefault();
            const listNodes = CustomEditor.getListNodes(editor);

            if (listNodes) {
                const {
                    currentList,
                    currentListItem,
                    currentElement,
                    listType
                } = listNodes;

                const textOfNode = Node.string(currentElement.node);

                const textIsEmpty = textOfNode.length === 0;

                if (textIsEmpty) {
                    CustomEditor.deleteListItem(
                        editor,
                        currentList,
                        currentListItem,
                        currentElement,
                        listType
                    );
                } else {
                    const childrenDepth = currentElement.path.length;
                    const isFirstNode =
                        selection.anchor.path[childrenDepth] === 0;

                    const isEndNode =
                        currentElement.node.children.length - 1 ===
                        selection.anchor.path[childrenDepth];
                    const isEdgeOfEndNode =
                        Node.string(
                            currentElement.node.children[
                                currentElement.node.children.length - 1
                            ]
                        ).length === selection.anchor.offset;

                    if (isFirstNode && selection.anchor.offset === 0) {
                        Transforms.insertNodes(
                            editor,
                            {
                                type: "list_item",
                                children: [
                                    {
                                        type: "paragraph",
                                        children: [{ text: "" }]
                                    }
                                ]
                            },
                            { at: currentListItem.path }
                        );
                    } else if (isEndNode && isEdgeOfEndNode) {
                        /* This will be if there is nothing to the right of the cursor:
                        Create new empty list item and move cursor to it */
                        const nextLocation = CustomEditor.getNextPath(
                            currentListItem.path
                        );
                        Transforms.insertNodes(
                            editor,
                            {
                                type: "list_item",
                                children: [
                                    {
                                        type: "paragraph",
                                        children: [{ text: "" }]
                                    }
                                ]
                            },
                            { at: nextLocation }
                        );
                        Transforms.move(editor, { unit: "offset" });
                    } else {
                        Transforms.splitNodes(editor, {
                            at: selection.anchor
                        });
                        const leftNode = CustomEditor.getNode(
                            editor,
                            currentElement.path
                        );
                        const rightNode = CustomEditor.getNextNode(
                            editor,
                            currentElement.path
                        );

                        if (leftNode && rightNode) {
                            Transforms.removeNodes(editor, {
                                at: currentListItem.path
                            });
                            Transforms.insertNodes(
                                editor,
                                {
                                    type: "list_item",
                                    children: [leftNode.node]
                                },
                                { at: currentListItem.path }
                            );
                            const nextListItemLocation = CustomEditor.getNextPath(
                                currentListItem.path
                            );
                            Transforms.insertNodes(
                                editor,
                                {
                                    type: "list_item",
                                    children: [rightNode.node]
                                },
                                { at: nextListItemLocation }
                            );
                            Transforms.select(editor, {
                                path: [...nextListItemLocation, 0, 0],
                                offset: 0
                            });
                        }
                    }
                }
            }
        }
    }
};
const handleBackspace: KeyHandler = (
    editor: Editor,
    event: KeyboardEvent<HTMLInputElement>
) => {
    const { selection } = editor;
    const isLink = CustomEditor.isLinkActive(editor);
    const isList = !!CustomEditor.getListType(editor);
    if (isLink && selection && Range.isCollapsed(selection)) {
        const link = CustomEditor.getLink(editor);
        if (SlateElement.isElement(link[0]) && link[0].children.length === 1) {
            if (
                Text.isText(link[0].children[0]) &&
                link[0].children[0].text.trim().length === 1
            ) {
                Transforms.unwrapNodes(editor, {
                    at: link[1]
                });
            }
        }
    }
    if (isList && selection && Range.isCollapsed(selection)) {
        const listNodes = CustomEditor.getListNodes(editor);
        if (listNodes) {
            const {
                currentListItem,
                currentElement,
                currentList,
                listType
            } = listNodes;
            const texts = Array.from(Node.texts(currentElement.node))
                .flat()
                .filter(val =>
                    Path.isPath(val) ? false : Text.isText(val) ? true : false
                );

            const textString = texts.reduce((acc, val) => {
                if (Path.isPath(val)) return acc;
                return acc + val.text;
            }, "");
            const isTextEmpty = textString.length === 0;

            if (isTextEmpty) {
                event.preventDefault();
                CustomEditor.deleteListItem(
                    editor,
                    currentList,
                    currentListItem,
                    currentElement,
                    listType
                );
            } else if (selection.anchor.offset === 0 && isTextEmpty) {
                event.preventDefault();
                const nextNode = CustomEditor.getNextNode(
                    editor,
                    currentListItem.path
                );

                if (nextNode && nextNode.node.type === "list_item") {
                    CustomEditor.toggleList(editor, listType);
                } else {
                    const shouldListBeRemoved =
                        currentList.node.children.length === 1;

                    if (shouldListBeRemoved) {
                        CustomEditor.toggleList(editor, listType);
                    } else {
                        Transforms.removeNodes(editor, {
                            at: currentListItem.path
                        });
                        Transforms.insertNodes(editor, currentElement.node, {
                            at: Path.next(currentList.path)
                        });
                        Transforms.move(editor, { unit: "line" });
                    }
                }
            }
        }
    }
};
const handleArrowRight: KeyHandler = (
    editor: Editor,
    event: KeyboardEvent<HTMLInputElement>
) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
        if (event.key === "ArrowRight") {
            const isLink = CustomEditor.isLinkActive(editor);
            if (isLink) {
                event.preventDefault();
                Transforms.move(editor, { unit: "offset" });
            }
        }
    }
};
const handleArrowLeft: KeyHandler = (
    editor: Editor,
    event: KeyboardEvent<HTMLInputElement>
) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
        if (event.key === "ArrowLeft") {
            const isLink = CustomEditor.isLinkActive(editor);
            if (isLink) {
                event.preventDefault();
                Transforms.move(editor, { unit: "offset", reverse: true });
            }
        }
    }
};

interface IKeyHash {
    Enter: KeyHandler;
    Backspace: KeyHandler;
    ArrowRight: KeyHandler;
    ArrowLeft: KeyHandler;
}

const keyHash: IKeyHash = {
    Enter: handleEnter,
    Backspace: handleBackspace,
    ArrowRight: handleArrowRight,
    ArrowLeft: handleArrowLeft
};

const handleKeyPressing = (
    editor: Editor,
    event: KeyboardEvent<HTMLInputElement>
) => {
    const keyPressed = event.key as keyof IKeyHash;
    const isHandled = Boolean(keyHash[keyPressed]);
    if (isHandled) {
        keyHash[keyPressed](editor, event);
    }
};

export default handleKeyPressing;
