import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import SimpleNavbar from "src/components/SimpleNavbar";
import { colors, fonts, overlayBackgrounds, sizes } from "src/constants";
import FullScreenModal from "src/modals/FullScreenModal";
import { preventChildBubbling } from "src/utils/functions/helpers";
import {
    clearButton,
    grayBackgroundHoverState,
    primaryBlueBackgroundHoverState
} from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import FiltersModalDropdownSection from "../components/FiltersModalDropdownSection";
import { ReactComponent as Cross } from "src/components/icons/cross.svg";
import { ReactComponent as BackArrow } from "src/components/icons/back-arrow.svg";
import SelectionHeader from "src/marketplace/components/SelectionHeader";
import { EventSubmit } from "src/utils/types/form";
import SelectionButton from "src/components/SelectionButton";
import CreatorsFiltersModalBody from "../components/CreatorsFiltersModalBody";
import NavButton from "src/components/NavButton";
import { FilterType } from "../utils/types";
import withModalPortal from "src/components/withModalPortal";

const Main = styled.main`
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
`;

const Body = styled.section`
    position: relative;
    flex: 1;
    overflow: hidden;
`;

const BodySlider = styled(motion.div)`
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
`;

const iconButtonStyles = css`
    fill: ${colors.gray3};
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            fill: ${colors.primaryGray};
        }
    }
`;

const CloseButton = styled(Cross)`
    ${iconButtonStyles};
`;

const BackButton = styled(BackArrow)`
    ${iconButtonStyles};
`;

export type FilterSelections = {
    [key: string]: string;
};

interface Props {
    modalOpen: boolean;
    closeModal: () => void;
}

const CreatorsFiltersModal = ({ modalOpen, closeModal }: Props) => {
    // Local State ---------------------------------------------------
    const [filterType, setFilterType] = useState<FilterType | "">("");

    // Local Constants -----------------------------------------------
    const initial = {
        opacity: 0,
        transform: "translateY(-10px)"
    };

    const animate = {
        opacity: 1,
        transform: "translateY(0px)"
    };

    // Local Functions -----------------------------------------------
    function handleCloseModal(): void {
        setFilterType("");
        closeModal();
    }

    // JSX -----------------------------------------------------------
    return (
        <AnimatePresence>
            {modalOpen && (
                <FullScreenModal closeModal={handleCloseModal}>
                    <Main onClick={preventChildBubbling}>
                        <SimpleNavbar
                            left={
                                filterType.length > 0 && (
                                    <BackButton
                                        onClick={() => setFilterType("")}
                                    />
                                )
                            }
                            title={
                                filterType.length === 0
                                    ? "Filter Creators"
                                    : filterType
                            }
                            right={<CloseButton onClick={handleCloseModal} />}
                        />
                        <Body>
                            <BodySlider
                                initial={{ transform: "translateX(0%)" }}
                                animate={{
                                    transform:
                                        filterType === ""
                                            ? "translateX(0%)"
                                            : "translateX(-100%)"
                                }}
                                exit={{ transform: "translateX(0%)" }}
                                transition={{ duration: 0.15 }}
                            >
                                <CreatorsFiltersModalBody
                                    page={1}
                                    closeModal={closeModal}
                                    filterType={filterType}
                                    setFilterType={setFilterType}
                                />
                                <CreatorsFiltersModalBody
                                    page={2}
                                    closeModal={closeModal}
                                    filterType={filterType}
                                    setFilterType={setFilterType}
                                />
                            </BodySlider>
                        </Body>
                    </Main>
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default withModalPortal(CreatorsFiltersModal);
