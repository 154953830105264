import React from "react";
import { Campaign } from "src/campaigns/types";
import styled from "styled-components";
import ModalCampaignTitle from "../../components/ModalCampaignTitle";

const Wrapper = styled.div`
    text-align: left;
    padding-bottom: 12px;
`;
const Header = styled.h2`
    font-size: 1.2rem;
    padding-bottom: 8px;
`;
const Label = styled.div`
    padding: 12px 0px 0px 16px;
`;
const StyledModalCampaignTitle = styled(ModalCampaignTitle)`
    padding-left: 16px;
`;

interface Props {
    title: string;
    campaign: Campaign;
    scriptTitle?: string;
    className?: string;
}

const ModalHeader = ({ title, campaign, scriptTitle, className }: Props) => {
    const shouldShowPrice =
        window.location.pathname.includes("campaign") ||
        window.location.pathname === "/";
    return (
        <Wrapper className={className}>
            <Header>{title}</Header>
            <StyledModalCampaignTitle
                showPrice={shouldShowPrice}
                campaign={campaign}
            />
            {scriptTitle ? (
                <Label>
                    <strong>Script:</strong> {scriptTitle}
                </Label>
            ) : null}
        </Wrapper>
    );
};

export default ModalHeader;
