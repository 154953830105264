import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../../reducers";
import { clearModal } from "../../../../modals/actions";
import { platformToContact } from "../../../../util";
import { colors } from "../../../../constants";
import { BlueModalButton } from "../../../components/Buttons";
import styled from "styled-components";
import GenericContainer from "../../../components/GenericContainer";
import CopyToClipboard from "react-copy-to-clipboard";
import { SocialAccount } from "../../../types";
import { fetchVerifyCode } from "../../../actions";
import CardLoader from "../../../components/CardLoader";
import Platforms from "src/social-accounts/Platforms";
import axios from "axios";
import { addNotification } from "src/ui/notifications/actions";
import VerifyAction from "./components/VerifyAction";
import { useHistory } from "react-router";
import ImageModal from "src/modals/ImageModal";
import { setSocialAccountStatus } from "src/social-accounts/actions";
import { selectFeatureFlags } from "src/firebase/selectors";

const Main = styled.div`
    font-size: 16px;
    line-height: 20px;
`;

const BoldText = styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
`;

const LinkText = styled.div`
    padding: 9px 12px;
    color: ${colors.primaryBlue};
    border: 1px solid ${colors.blueGrey2};
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
`;

const CodeSection = styled.div`
    padding: 7px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid ${colors.blueGrey2};
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 10px;
`;

const CodeText = styled(BoldText)`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: auto 0;
    text-align: left;
`;

const ContactContainer = styled(GenericContainer)`
    padding: 20px;
`;

const ContactOuter = styled(ContactContainer)`
    margin-bottom: 20px;
`;

const ContactInner = styled(ContactContainer)`
    cursor: pointer;
`;

interface Props {
    account: SocialAccount;
    verifyCode: string | null;
    instagramHandle: string | null;
    onClose?: () => void;
    fetchVerifyCode: (accountId: number) => void;
    clearModal: VoidFunction;
}

const imageStyles = {
    height: "auto",
    border: "0px",
    borderRadius: "0px",
    width: "50%"
};

const VerifyModal = ({
    account,
    verifyCode,
    instagramHandle,
    onClose,
    fetchVerifyCode,
    clearModal
}: Props) => {
    const history = useHistory();
    const status = useSelector(
        (state: GlobalState) =>
            state.entities.socialAccounts.statuses[account.id]
    );
    const previousFailure = !!status?.includes("Error:");

    useEffect(() => {
        if (!verifyCode) {
            fetchVerifyCode(account.id);
        }
    }, [account]);

    const { urlToOpen, buttonText, handle } = platformToContact(
        account.platform,
        instagramHandle
    );

    const isEmail = handle === "hi@plugco.in";
    const dispatch = useDispatch();

    const checkVerification = () => {
        axios
            .patch(`/v1/social_account/${account.id}/verify_link_in_bio`)
            .then(res => {
                dispatch(
                    addNotification(
                        "Checking your verification we will notify you when its done."
                    )
                );
                dispatch(
                    setSocialAccountStatus({
                        id: account.id,
                        status: "pending"
                    })
                );
            })
            .catch(error =>
                dispatch(addNotification("Error verifying account", "red"))
            )
            .finally(() => {
                clearModal();
            });
    };

    if (previousFailure)
        return (
            <ImageModal
                imageUrl="/verified_account.svg"
                imageStyle={imageStyles}
            >
                {!verifyCode ? (
                    <CardLoader />
                ) : (
                    <Main>
                        <BoldText>Verify Your Ownership</BoldText>

                        <VerifyAction
                            account={account}
                            verifyCode={verifyCode}
                            checkVerification={checkVerification}
                            loginKit={true}
                            both={false}
                        />
                    </Main>
                )}
            </ImageModal>
        );

    return (
        <ImageModal imageUrl="/verified_account.svg" imageStyle={imageStyles}>
            {!verifyCode ? (
                <CardLoader />
            ) : (
                <Main>
                    <BoldText>Verify Your Ownership</BoldText>
                    <div>
                        {isEmail ? "Email" : "DM"} us the following code to
                        prove your ownership:
                    </div>
                    <CodeSection>
                        <CodeText>{verifyCode}</CodeText>
                        <CopyToClipboard text={verifyCode}>
                            <LinkText>Copy code</LinkText>
                        </CopyToClipboard>
                    </CodeSection>
                    <ContactOuter>
                        <BoldText>{handle}</BoldText>
                        <ContactInner onClick={() => window.open(urlToOpen)}>
                            <LinkText>{buttonText}</LinkText>
                        </ContactInner>
                    </ContactOuter>
                    <BlueModalButton
                        onClick={() => {
                            if (onClose) onClose();
                            clearModal();
                        }}
                    >
                        Done
                    </BlueModalButton>
                </Main>
            )}
        </ImageModal>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    verifyCode: state.profile.verifyCode,
    instagramHandle: state.profile.instagramHandle
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchVerifyCode: (accountId: number) =>
        dispatch(fetchVerifyCode(accountId)),
    clearModal: () => dispatch(clearModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyModal);
