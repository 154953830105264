import React from "react";
import { colors } from "src/constants";
import styled from "styled-components";
import { formatNumber } from "../../../util";
import RaffleTicketsEarned from "../components/RaffleTicketsEarned";
import { ContestRaffle } from "../types";
import { fonts } from "src/constants";

const Main = styled.div`
    margin-bottom: 15px;
    background-color: #f8fbfc;
`;

const Card = styled.div`
    background-color: white;
    border-top: 1px solid #e0eef2;
    border-bottom: 1px solid #e0eef2;
    padding: 15px;
    text-align: left;
    font-size: ${fonts.smallTitle}px;
`;

const Title = styled.div`
    margin-bottom: 10px;
    font-weight: bold;
`;

const Raffle = styled.div`
    color: #909a9c;
    margin-top: 14px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
`;

const CheckCircle = styled.img`
    height: 16px;
    width: 16px;
`;

const VideoTitle = styled.div`
    font-size: 14px;
    font-weight: 700;
`;
const VideoLink = styled.a`
    font-size: 14px;
    font-weight: 500;
    margin-left: -18px;
    color: ${colors.primaryBlue};
    text-decoration: underline;
`;

interface Props {
    raffle: ContestRaffle;
}

const RaffleRules = ({ raffle }: Props) => {
    return (
        <Main>
            <Card>
                <Title>How It Works</Title>
                <div style={{ marginBottom: "10px" }}>{raffle.description}</div>
                <Title>Raffle tickets earned</Title>
                <RaffleTicketsEarned
                    tickets={raffle.ticket ? raffle.ticket.earned : 0}
                >
                    {raffle.ticket &&
                    raffle.ticket.min_ticket_num &&
                    raffle.ticket.max_ticket_num ? (
                        <div>{`Your tickets are: #${formatNumber(
                            raffle.ticket.min_ticket_num
                        )} - ${formatNumber(
                            raffle.ticket.max_ticket_num
                        )}`}</div>
                    ) : (
                        <Raffle>{raffle.conversion}</Raffle>
                    )}
                </RaffleTicketsEarned>{" "}
                <Row
                    style={{
                        display: raffle.drawing_video_url ? "flex" : "none"
                    }}
                >
                    <CheckCircle src="/green_circle_check.svg" />
                    <VideoTitle>The winners have been drawn</VideoTitle>
                    <li style={{ fontSize: "18px" }} />
                    <VideoLink href={raffle.drawing_video_url}>
                        Watch Video
                    </VideoLink>
                </Row>
            </Card>
        </Main>
    );
};

export default RaffleRules;
