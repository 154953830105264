import React from "react";
import { breakpoints, colors } from "src/constants";
import { flexRowCenter } from "src/utils/styles/snippets";
import { IntercomProvider } from "react-use-intercom";
import styled from "styled-components";
import IntercomLauncher from "./IntercomLauncher";
import CopyToClipboard from "react-copy-to-clipboard";
import { BorderlessButton } from "src/profile/components/Buttons";
import Notification from "src/ui/notifications/Notification";
import {
    getFirebaseUser,
    onAuthStateChanged
} from "src/firebase/FirebaseConfig";

import { getApi, getUser, post } from "src/Api";
import { getPlatform } from "src/util";

const Main = styled.main`
    ${flexRowCenter()};
    align-items: start;
    width: 100%;
    height: 100vh;
    padding: 5% 30px;
`;

const Content = styled.section`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px;
    justify-items: center;
`;

const Header = styled.article`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    justify-items: center;
`;

const Title = styled.h1`
    color: ${colors.black};
    font-size: 36px;
    font-weight: 700;
    line-height: 150%;

    @media (max-width: ${breakpoints.mobile}px) {
        font-size: 20px;
    }
`;

const Subtitle = styled.p`
    color: ${colors.primaryGray};
    font-size: 22px;
    font-weight: 400;
    line-height: 150%;

    @media (max-width: ${breakpoints.mobile}px) {
        font-size: 14px;
    }
`;

const LinkButtons = styled.article`
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 20px;
`;

const LinkButton = styled.a`
    ${flexRowCenter()};
    width: 300px;
    height: 60px;
    color: ${colors.white};
    background-color: ${colors.primaryBlue};
    border-radius: 10px;
    font-size: 22px;
    font-weight: 700;
    text-decoration: none;
    transition: 0.1s ease-in;
    cursor: pointer;

    :hover {
        color: ${colors.white};
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;
const CopyButton = styled(BorderlessButton)`
    height: 48px;
    width: 100%;
    color: red;
    border: 1px solid ${colors.primaryGray};
    border-radius: 25px;
`;

const Pluggy = styled.img`
    height: 200px;
    width: 200px;
`;
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: "",
            errorInfo: "",
            userid: null,
            hash: null
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //TODO maybe send automated slack messages with crash reports
        console.log("error: ", error);
        console.log("errorInfo: ", errorInfo);
        this.setState({ error, errorInfo });
        this.submitCrashReport(error.message);
    }

    async submitCrashReport(error) {
        try {
            const platform = getPlatform();
            const location = window.location.href;
            const { userid } = this.state;

            await post("/api/submit_event", {
                platform,
                event_name: "web_client_crash",
                event_extra: {
                    location,
                    userid,
                    error
                }
            });
        } catch (error) {
            console.log("error submitting error");
        }
    }

    async initUser() {
        try {
            const userId = localStorage.getItem("userId");
            const { data: hashData } = await getUser("/intercom_hash");
            this.setState({ userid: userId, hash: hashData.data });
        } catch (error) {}
    }

    componentDidMount() {
        this.initUser();
    }

    render() {
        const user = {
            user_id: this.state.userid,
            user_hash: this.state.hash,
            hide_default_launcher: true,
            custom_launcher_selector: ".intercom-link"
        };
        const text = `Error: ${this.state.error}\nStack Trace: ${this.state.errorInfo.componentStack}`;
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Main>
                    <Notification
                        manual={
                            this.state.notify && { text: "Copied Error Report" }
                        }
                    />
                    <Content>
                        <Header>
                            <Pluggy src={"/plug/pluggy_work_gif.gif"} />
                            <Title>
                                Looks like The Plug ran into an issue. Sorry
                                about that.
                            </Title>
                            <Subtitle>
                                Let support know what went wrong to help us
                                prevent this in the future or just logout and
                                see if that works.
                            </Subtitle>
                        </Header>
                        <LinkButtons>
                            <CopyToClipboard
                                text={text}
                                onCopy={() =>
                                    this.setState({ notify: true }, () =>
                                        setTimeout(
                                            () =>
                                                this.setState({
                                                    notify: false
                                                }),
                                            3000
                                        )
                                    )
                                }
                            >
                                <CopyButton>Copy Error Report</CopyButton>
                            </CopyToClipboard>
                            <IntercomProvider
                                appId="qporgstt"
                                autoBootProps={{
                                    customAttributes: { ...user }
                                }}
                            >
                                <IntercomLauncher />
                            </IntercomProvider>
                            <LinkButton href="/logout">Logout</LinkButton>
                        </LinkButtons>
                    </Content>
                </Main>
            );
        }

        return this.props.children;
    }
}
