import React from "react";
import { ChatModal } from "../../../reducer";
import { Channel } from "../../../types";
import AdminActionsModal from "./AdminActionsModal";
import MuteOptionsModal from "./MuteOptionsModal";
import BanOptionsModal from "./BanOptionsModal";
import ReportOptionsModal from "./ReportOptionsModal";

interface Props {
    modal: ChatModal;
    currentUser: any;
    channel: Channel;
    mutedUsers: any[];
    bannedUsers: any[];
    muteBanRefresh: VoidFunction;
}

const AdminModals = ({
    modal,
    currentUser,
    channel,
    mutedUsers,
    bannedUsers,
    muteBanRefresh
}: Props) => {
    switch (modal) {
        case ChatModal.adminActions:
            return (
                <AdminActionsModal
                    currentUser={currentUser}
                    channel={channel}
                    mutedUsers={mutedUsers}
                    bannedUsers={bannedUsers}
                    muteBanRefresh={muteBanRefresh}
                />
            );
        case ChatModal.muteOptions:
            return (
                <MuteOptionsModal
                    currentUser={currentUser}
                    channel={channel}
                    muteBanRefresh={muteBanRefresh}
                />
            );
        case ChatModal.banOptions:
            return (
                <BanOptionsModal
                    currentUser={currentUser}
                    channel={channel}
                    muteBanRefresh={muteBanRefresh}
                />
            );
        case ChatModal.reportOptions:
            return (
                <ReportOptionsModal
                    currentUser={currentUser}
                    channel={channel}
                />
            );
        default:
            return null;
    }
};

export default AdminModals;
