import { getAuthState } from "src/auth/selectors";
import { GlobalState } from "src/reducers";

const conversationsEntitiesState = (state: GlobalState) =>
    state.entities.conversations;

export const selectExistingOneToOneConversation = (
    state: GlobalState,
    otherId: string
) => {
    const { userId } = getAuthState(state);
    const conversationEntities = conversationsEntitiesState(state);
    const conversations = conversationEntities.allIds.map(
        conversationId => conversationEntities.byId[conversationId]
    );
    const oneToOneConversations = conversations.filter(
        conversation =>
            conversation.participantIds.length === 2 &&
            !conversation.displayName
    );
    return (
        oneToOneConversations.find(
            conversation =>
                conversation.participantIds.includes(`${userId}`) &&
                conversation.participantIds.includes(otherId)
        ) || null
    );
};
