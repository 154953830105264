import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import OpenBuyPreview, {
    OpenBuyPreviewSize
} from "../components/OpenBuyPreview";
import { push } from "connected-react-router";
import { useWindowSize } from "react-use";
import { breakpoints } from "src/constants";
import { getOpenBuysWithSearchStringSortedFiltered } from "./selectors";
import { GlobalState } from "src/reducers";
import { useEffect } from "react";

const Main = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 10px 0;
    row-gap: 10px;
    @media (max-width: ${breakpoints.tablet}px) {
        grid-template-columns: 1fr;
    }
`;

const OpenBuysFeed = ({
    sortMode,
    searchString
}: {
    sortMode: string;
    searchString: string;
}) => {
    const dispatch = useDispatch();
    const { width } = useWindowSize();

    const openBuys = useSelector((state: GlobalState) =>
        getOpenBuysWithSearchStringSortedFiltered(state, searchString)
    );

    return (
        <Main>
            {openBuys.map(openBuy => (
                <OpenBuyPreview
                    key={openBuy.id}
                    openBuyId={openBuy.id}
                    size={
                        width < breakpoints.tablet
                            ? OpenBuyPreviewSize.large
                            : OpenBuyPreviewSize.small
                    }
                    onClick={() => {
                        dispatch(push(`/buys/open/${openBuy.id}`));
                    }}
                />
            ))}
        </Main>
    );
};

export default OpenBuysFeed;
