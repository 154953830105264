import React, { useState } from "react";
import { useSelector } from "react-redux";
import { colors } from "src/constants";
import ErrorModal from "src/modals/ErrorModal";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import EditGroupDescriptionModal from "../modals/EditGroupDescriptionModal";
import { selectConversationOwnAdminStatus } from "../selectors";

const Main = styled.div`
    font-size: 12px;
    padding: 24px 0 12px;
    text-align: left;
`;

const Title = styled.div`
    font-weight: 600;
`;

const Description = styled.div`
    color: ${colors.primaryGray};
    margin-top: 8px;
`;

interface Props {
    conversationId: string;
    description?: string;
    imageUrl: string;
}

const GroupDescription = ({ conversationId, description, imageUrl }: Props) => {
    const [editDescriptionModal, toggleEditDescriptionModal] = useState(false);
    const [errorModal, toggleErrorModal] = useState(false);
    const isGroupAdmin = useSelector((state: GlobalState) =>
        selectConversationOwnAdminStatus(state, conversationId)
    );

    function edit() {
        if (isGroupAdmin) {
            toggleEditDescriptionModal(true);
        } else {
            toggleErrorModal(true);
        }
    }

    return (
        <>
            <Main onClick={edit}>
                <Title>Group Description</Title>
                <Description>
                    {description || "Add Group Description..."}
                </Description>
            </Main>
            {editDescriptionModal && (
                <EditGroupDescriptionModal
                    conversationId={conversationId}
                    description={description || ""}
                    imageUrl={imageUrl}
                    clearModal={() => toggleEditDescriptionModal(false)}
                />
            )}
            {errorModal && (
                <ErrorModal
                    closeModal={() => toggleErrorModal(false)}
                    message="Only administrators can edit group information."
                />
            )}
        </>
    );
};

export default GroupDescription;
