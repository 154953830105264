import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../../reducers";
import { Profile } from "../../../types";
import { replace } from "connected-react-router";
import NavButton from "../../../../components/NavButton";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import Tab from "../../../../components/Tab";
import RequestsTab from "./RequestsTab";
import ConnectionsPageTab from "../../components/ConnectionsPageTab";
import FullscreenLoader from "../../../../components/FullscreenLoader";
import PaddingAround from "../../../../components/PaddingAround";
import XCard from "../../../components/XCard";
import { fetchConnectionsUser } from "../../../actions";
import { useHistory } from "react-router";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";
import { colors, maxWidthDesktop } from "src/constants";

const Tabs = styled("div")`
    display: flex;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${maxWidthDesktop}px) {
        border: none;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

export enum ConnectionsTabState {
    all,
    requests
}

interface Props {
    profile: Profile;
    tabState: ConnectionsTabState;
}

const ConnectionsPage = ({ profile, tabState }: Props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const connections = useSelector((state: GlobalState) => {
        return state.profile.connections;
    });

    const recommendations = useSelector((state: GlobalState) => {
        return state.profile.recommendations;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();

    function dispatchFetchConnectionsUsers(publisherId: number): void {
        dispatch(fetchConnectionsUser(publisherId));
    }

    function dispatchReplace(route: string): void {
        dispatch(replace(route));
    }

    const navbar = (
        <SimpleNavbar
            title={"CONNECTIONS"}
            left={<NavButton back onClick={goBack} />}
        />
    );

    if (connections === null) {
        return (
            <>
                <Navbar back marginBottom={20} title="CONNECTIONS" />
                <MainContainer>
                    <PaddingAround>
                        <XCard
                            text="Failed to load connections, click the button below to try again."
                            button={{
                                text: "Try again",
                                callback: () => {
                                    dispatchFetchConnectionsUsers(profile.id);
                                }
                            }}
                        />
                    </PaddingAround>
                </MainContainer>
            </>
        );
    } else if (!connections) {
        return (
            <>
                <Navbar back marginBottom={20} title="CONNECTIONS" />
                <FullscreenLoader />
            </>
        );
    } else {
        const friends = connections.filter(connect => {
            return connect.state === "friend";
        });
        const requested = connections.filter(connect => {
            return connect.state === "requested";
        });

        return (
            <>
                <Navbar back marginBottom={20} title="CONNECTIONS" />

                <MainContainer>
                    {requested.length > 0 && (
                        <Tabs>
                            <Tab
                                name={"All"}
                                active={tabState === ConnectionsTabState.all}
                                onClick={() => {
                                    dispatchReplace("/profile/connections");
                                }}
                            />
                            <Tab
                                name={`Requests (${requested.length})`}
                                active={
                                    tabState === ConnectionsTabState.requests
                                }
                                onClick={() => {
                                    dispatchReplace(
                                        "/profile/connections/requests"
                                    );
                                }}
                            />
                        </Tabs>
                    )}

                    {requested.length === 0 ||
                    tabState === ConnectionsTabState.all ? (
                        <ConnectionsPageTab connections={friends} />
                    ) : (
                        <RequestsTab
                            connections={requested}
                            recommendations={recommendations}
                        />
                    )}
                </MainContainer>
            </>
        );
    }
};

export default ConnectionsPage;
