import { combineReducers } from "redux";
import { NormalizedAction } from "../../types";
import { Placement } from "../types";
import { FETCH_PLACEMENTS } from "./actions";

interface ById {
    [id: number]: Placement;
}

export interface PlacementsEntitiesState {
    byId: ById;
    allIds: number[];
}

const initialPlacementsState: PlacementsEntitiesState = {
    byId: {},
    allIds: []
};

const byId = (
    state = initialPlacementsState.byId,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_PLACEMENTS.SUCCESS:
            return { ...state, ...action.response.entities.placements };
        default:
            return state;
    }
};

const allIds = (
    state = initialPlacementsState.allIds,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_PLACEMENTS.SUCCESS:
            return action.response.result;
        default:
            return state;
    }
};

const placementsEntitiesReducer = combineReducers<PlacementsEntitiesState>({
    byId,
    allIds
});

export default placementsEntitiesReducer;