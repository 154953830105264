import {
    CREATE_INTERNAL_NOTES_CHANNEL,
    UPDATE_INTERNAL_NOTES_CONVERSATION
} from "src/new-chat/approval-internal-notes/actions";
import {
    CURRENT_CHAT_USER_PROFILE_CHANNEL,
    FETCH_CHAT_USER_PROFILE
} from "src/new-chat/chat-profiles/actions";
import { CREATE_CONVERSATION } from "src/new-chat/conversation/actions";
import { UPDATE_GROUP_ACTIONS } from "src/new-chat/group-user-settings/actions";
import { POST_MESSAGE } from "src/new-chat/message/actions";
import { Action } from "src/types";

export interface ChatUIState {
    chatCreationLoading: boolean;
    messageDelivering: boolean;
    messageListProfileLoading: boolean;
    internalNotesLoading: boolean;
}

const initialChatUIState: ChatUIState = {
    chatCreationLoading: false,
    messageDelivering: false,
    messageListProfileLoading: false,
    internalNotesLoading: false
};

const chatUIReducer = (
    state = initialChatUIState,
    action: Action
): ChatUIState => {
    switch (action.type) {
        case CREATE_CONVERSATION.REQUEST:
        case UPDATE_GROUP_ACTIONS.ADD_MEMBER:
            return { ...state, chatCreationLoading: true };
        case CREATE_CONVERSATION.SUCCESS:
        case UPDATE_GROUP_ACTIONS.ADD_MEMBER_SUCCESS:
            return { ...state, chatCreationLoading: false };
        case POST_MESSAGE.REQUEST:
            return { ...state, messageDelivering: true };
        case POST_MESSAGE.SUCCESS:
            return { ...state, messageDelivering: false };
        case CREATE_INTERNAL_NOTES_CHANNEL:
            return { ...state, internalNotesLoading: true };
        case UPDATE_INTERNAL_NOTES_CONVERSATION:
            return { ...state, internalNotesLoading: false };
        // case CURRENT_CHAT_USER_PROFILE_CHANNEL.CREATE:
        //     return { ...state, messageListProfileLoading: true };
        // case FETCH_CHAT_USER_PROFILE.SUCCESS:
        //     return { ...state, messageListProfileLoading: false };
        default:
            return state;
    }
};

export default chatUIReducer;
