import React, { useState } from "react";
import { SocialAccount } from "../../../../profile/types";
import styled from "styled-components";
import { colors, fonts } from "../../../../constants";
import Platforms from "../../../../social-accounts/Platforms";
import CopyToClipboard from "react-copy-to-clipboard";
import { addNotification } from "../../../../ui/notifications/actions";
import { useDispatch } from "react-redux";
import { BuyLinkAction } from "src/buys/modules/direct-buy-step/types";

const Main = styled.div`
    height: 60px;
    padding: 0 12px;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    display: flex;
    align-items: center;
    background: white;
    & + & {
        margin-top: 10px;
    }
`;

const PlatformImage = styled.img`
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: left;
    overflow: hidden;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;
    padding-bottom: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Subtitle = styled.div`
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colors.primaryGray};
`;

interface ButtonProps {
    inactive: boolean;
}

const Button = styled.button<ButtonProps>`
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    height: 32px;
    padding: 0 16px;
    background: white;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 4px;
    color: ${props =>
        props.inactive ? colors.primaryGray : colors.primaryBlue};
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const LinkButton = styled.a`
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    height: 32px;
    padding: 0 16px;
    background: white;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 4px;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

interface Props {
    linkUrl: string | null;
    linkAction: BuyLinkAction;
    socialAccount: SocialAccount | null;
    actionCallback: VoidFunction;
}

const DirectBuyDetailsCopyLinksCell = ({
    linkUrl,
    linkAction,
    socialAccount,
    actionCallback
}: Props) => {
    const dispatch = useDispatch();
    const [inactive, setInactive] = useState(false);
    return (
        <Main>
            {socialAccount && (
                <PlatformImage
                    src={
                        (Platforms[socialAccount.platform] || Platforms.other)
                            .imgSrc
                    }
                />
            )}
            <TextContainer>
                {socialAccount && <Title>@{socialAccount.username}</Title>}
                <Subtitle>{linkUrl}</Subtitle>
            </TextContainer>
            {linkAction === BuyLinkAction.open ? (
                <LinkButton href={linkUrl || ""} target="#">
                    Open Link
                </LinkButton>
            ) : (
                <CopyToClipboard
                    text={linkUrl || ""}
                    onCopy={() => {
                        if (!inactive) {
                            actionCallback();
                            setInactive(true);
                            dispatch(addNotification("Copied link!"));
                            setTimeout(() => setInactive(false), 2000);
                        }
                    }}
                >
                    <Button inactive={inactive}>
                        {inactive ? "Link Copied" : "Copy Link"}
                    </Button>
                </CopyToClipboard>
            )}
        </Main>
    );
};

export default DirectBuyDetailsCopyLinksCell;
