import { Action, NormalizedAction } from "../../types";
import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../../reducers";

// Fetch Campaign Assets  -------------------------------------------------------------------

export const FETCH_CAMPAIGN_ASSETS = {
    REQUEST: "FETCH_CAMPAIGN_ASSETS_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_ASSETS_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_ASSETS_FAILURE"
};

export const fetchCampaignAssets = (campaignId: number): Action => ({
    type: FETCH_CAMPAIGN_ASSETS.REQUEST,
    payload: { campaignId }
});

export const fetchCampaignAssetsSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { campaignId: number }
): NormalizedAction => ({
    type: FETCH_CAMPAIGN_ASSETS.SUCCESS,
    response,
    payload
});

export const fetchCampaignAssetsFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_ASSETS.FAILURE,
    payload: { error }
});

// Fetch Campaign Asset  -------------------------------------------------------------------
export const FETCH_CAMPAIGN_ASSET = {
    REQUEST: "FETCH_CAMPAIGN_ASSET_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_ASSET_SUCCESS"
};
export function fetchCampaignAsset(
    campaignId: number,
    assetId: number
): Action {
    return {
        type: FETCH_CAMPAIGN_ASSET.REQUEST,
        payload: { campaignId, assetId }
    };
}
export function fetchCampaignAssetSuccess(
    response: NormalizedSchema<{ [key: string]: { [key: string]: any } }, any>,
    campaignId: number
): NormalizedAction {
    return {
        type: FETCH_CAMPAIGN_ASSET.SUCCESS,
        response,
        payload: { campaignId }
    };
}

// Script Assets ---------------------------------------------------------------

export const FETCH_SCRIPT_ASSETS = {
    REQUEST: "FETCH_SCRIPT_ASSETS_REQUEST",
    SUCCESS: "FETCH_SCRIPT_ASSETS_SUCCESS",
    FAILURE: "FETCH_SCRIPT_ASSETS_FAILURE"
};

export const fetchScriptAssets = (scriptId: number): Action => ({
    type: FETCH_SCRIPT_ASSETS.REQUEST,
    payload: { scriptId }
});

export const fetchScriptAssetsSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { scriptId: number }
): NormalizedAction => ({
    type: FETCH_SCRIPT_ASSETS.SUCCESS,
    response,
    payload
});

export const fetchScriptAssetsFailure = (error: string): Action => ({
    type: FETCH_SCRIPT_ASSETS.FAILURE,
    payload: { error }
});
