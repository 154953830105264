import { firestore } from "firebase";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionsModal from "src/modals/ActionsModal";
import { createConversation } from "src/new-chat/conversation/actions";
import { selectChatProfileById } from "src/new-chat/selectors";
import { GlobalState } from "src/reducers";
import {
    updateGroupAdminStatus,
    updateGroupRemoveUser,
    updateGroupUserSettings
} from "../actions";
import { selectGroupUserSettingsById } from "../selectors";

interface Props {
    userId: string;
    conversationId: string;
    closeModal: () => void;
    toggleMuteDurationModal: (userId: string) => void;
}

const GroupUserManageModal = ({
    userId,
    conversationId,
    closeModal,
    toggleMuteDurationModal
}: Props) => {
    const dispatch = useDispatch();
    const userSettings = useSelector((state: GlobalState) =>
        selectGroupUserSettingsById(state, conversationId, userId)
    );
    const chatProfile = useSelector((state: GlobalState) =>
        selectChatProfileById(state, userId)
    );

    function setMuteDuration() {
        closeModal();
        toggleMuteDurationModal(userId);
    }

    function unmute() {
        dispatch(
            updateGroupUserSettings({
                id: userSettings.id,
                isMutedIndefinitely: false,
                isMutedUntil: firestore.FieldValue.delete()
            })
        );
        closeModal();
    }

    function toggleAdminStatus() {
        dispatch(
            updateGroupAdminStatus({
                groupUserSettingsId: userSettings.id,
                conversationId,
                userId,
                isAdmin: !userSettings.isAdmin
            })
        );
        closeModal();
    }

    function toggleBannedStatus() {
        dispatch(
            updateGroupUserSettings({
                id: userSettings.id,
                isBanned: !userSettings.isBanned
            })
        );
        closeModal();
    }

    function removeUserFromGroup() {
        dispatch(
            updateGroupRemoveUser(
                userSettings.conversationId,
                userSettings.userId
            )
        );
        closeModal();
    }

    function chatWithUser() {
        dispatch(
            createConversation({
                users: [
                    {
                        avatarUrl: chatProfile.avatarUrl,
                        username: chatProfile.username,
                        publisherId: chatProfile.id
                    }
                ]
            })
        );
        closeModal();
    }

    return (
        <ActionsModal
            localClearModal={closeModal}
            actions={[
                {
                    text: userSettings.isAdmin ? "Make Member" : "Make Admin",
                    callback: toggleAdminStatus
                },
                {
                    text: userSettings.isMuted || "Mute",
                    callback: userSettings.isMuted ? unmute : setMuteDuration
                },
                {
                    text: userSettings.isBanned ? "Unban" : "Ban",
                    callback: toggleBannedStatus
                },
                {
                    text: "Remove from group",
                    callback: removeUserFromGroup
                },
                {
                    text: "Send Message",
                    callback: chatWithUser
                }
            ]}
        />
    );
};

export default GroupUserManageModal;
