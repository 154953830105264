import React, { useState } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";

const Title = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.primaryGray};
`;

const Message = styled.div`
    margin-top: 4px;
    font-size: ${fonts.smallSubtitle}px;
    line-height: 12px;
    color: ${colors.primaryGray};
`;

const OptionContainer = styled.div`
    height: 60px;
    margin-top: 12px;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    user-select: none;
`;

const OptionText = styled.div`
    font-weight: 500;
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;
`;

const Checkbox = styled.img`
    margin-left: auto;
`;

interface Props {
    ratesResponse: boolean;
    setRatesResponse: (ratesResponse: boolean) => void;
    applicationResponse: boolean;
    setApplicationResponse: (applicationResponse: boolean) => void;
}

const IntroBuysApplication = ({
    ratesResponse,
    setRatesResponse,
    applicationResponse,
    setApplicationResponse
}: Props) => {
    return (
        <>
            <Title>APPLICATION</Title>
            <OptionContainer
                onClick={() => {
                    if (ratesResponse) {
                        setApplicationResponse(false);
                    }
                    setRatesResponse(!ratesResponse);
                }}
            >
                <OptionText>I have added rates to my accounts</OptionText>
                <Checkbox
                    src={
                        ratesResponse
                            ? "/checkbox_selected.svg"
                            : "/checkbox_unselected.svg"
                    }
                />
            </OptionContainer>
            <Message>
                Only creators with accounts that have rates listed are eligible
                for access to Personal Offers. Creators without rates listed
                WILL NOT be considered.
            </Message>
            <OptionContainer
                onClick={() => {
                    if (ratesResponse) {
                        setApplicationResponse(!applicationResponse);
                    }
                }}
            >
                <OptionText>
                    I want to receive exclusive personal offers from advertisers
                </OptionText>
                <Checkbox
                    src={
                        applicationResponse
                            ? "/checkbox_selected.svg"
                            : "/checkbox_unselected.svg"
                    }
                />
            </OptionContainer>
        </>
    );
};

export default IntroBuysApplication;
