import React from "react";
import { colors } from "src/constants";
import styled from "styled-components";

const Wrapper = styled.div<{ isActive: boolean }>`
    width: 51px;
    height: 31px;
    border-radius: 20px;
    background-color: ${props =>
        props.isActive ? colors.primaryBlue : colors.secondaryGray};
    padding: 2px;
    overflow: hidden;
    transition: background-color 0.2s ease;
    cursor: pointer;
`;
const Knob = styled.div<{ isActive: boolean }>`
    width: 27px;
    height: 27px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0px 3px 1px 0px hsla(0, 0%, 0%, 0.06),
        0px 3px 8px 0px hsla(0, 0%, 0%, 0.15);
    transform: ${props =>
        props.isActive ? `translateX(20px)` : `translateX(0px)`};
    transition: transform 0.2s ease;
`;

interface Props {
    isActive: boolean;
    onToggle?: VoidFunction;
    onSelect?: VoidFunction;
    onDeselect?: VoidFunction;
    className?: string;
}

const Toggler = ({
    isActive,
    onToggle,
    onSelect,
    onDeselect,
    className
}: Props) => {
    return (
        <Wrapper
            className={className}
            isActive={isActive}
            onClick={onToggle ? onToggle : isActive ? onDeselect : onSelect}
        >
            <Knob isActive={isActive} />
        </Wrapper>
    );
};

export default Toggler;
