import { useEffect, useState } from "react";
import { debounce } from "src/util";

const useResize = (shouldAddListener = true, debounceTime = 500) => {
    const [dimensions, setDimensions] = useState({
        viewWidth: window.innerWidth,
        viewHeight: window.innerHeight
    });
    const handleResize = debounce(() => {
        setDimensions({
            viewWidth: window.innerWidth,
            viewHeight: window.innerHeight
        });
    }, debounceTime);

    useEffect(() => {
        if (shouldAddListener) {
            window.addEventListener("resize", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }
    }, [shouldAddListener]);

    return dimensions;
};

export default useResize;
