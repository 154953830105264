import { CreatorsSort } from "src/marketplace/creator-groups/pages/CreatorGroupsCreateOrEditPage";
import {
    CreatorSortOrder,
    FilterType
} from "src/marketplace/creator-groups/utils/types";
import { Action } from "src/types";
import { CreatorsDateRange, Filters } from "./reducers";

// Set Creator Search Loading -----------------------------------------------

export const SET_CREATOR_SEARCH_LOADING = "SET_CREATOR_SEARCH_LOADING";

export function setCreatorSearchLoading(loading: boolean): Action {
    return {
        type: SET_CREATOR_SEARCH_LOADING,
        payload: { loading }
    };
}

// Set Current Other And Social Account Id -----------------------------------------------

export const SET_CREATOR_CURRENT_OTHER_AND_SOCIAL_ACCOUNT_ID =
    "SET_CREATOR_CURRENT_OTHER_AND_SOCIAL_ACCOUNT_ID";

export function setCreatorCurrentOtherAndSocialAccountId(
    otherId: number,
    socialAccountId: number
): Action {
    return {
        type: SET_CREATOR_CURRENT_OTHER_AND_SOCIAL_ACCOUNT_ID,
        payload: { otherId, socialAccountId }
    };
}

// Set Current Creator Index -----------------------------------------------

export const SET_CURRENT_CREATOR_INDEX = "SET_CURRENT_CREATOR_INDEX";

export function setCurrentCreatorIndex(index: number): Action {
    return {
        type: SET_CURRENT_CREATOR_INDEX,
        payload: { index }
    };
}

// Set Hits Per Page -----------------------------------------------

export const SET_CREATORS_HITS_PER_PAGE = "SET_CREATORS_HITS_PER_PAGE";

export function setCreatorsHitsPerPage(hits: number): Action {
    return {
        type: SET_CREATORS_HITS_PER_PAGE,
        payload: { hits }
    };
}

// Set  Page -----------------------------------------------

export const SET_CREATORS_PAGE = "SET_CREATORS_PAGE";

export function setCreatorsPage(page: number): Action {
    return {
        type: SET_CREATORS_PAGE,
        payload: { page }
    };
}

// Set Query -----------------------------------------------

export const SET_CREATORS_QUERY = "SET_CREATORS_QUERY";

export function setCreatorsQuery(query: string): Action {
    return {
        type: SET_CREATORS_QUERY,
        payload: { query }
    };
}

// Set Date Range -----------------------------------------------

export const SET_CREATORS_DATE_RANGE = "SET_CREATORS_DATE_RANGE";

export function setCreatorsDateRange(dateRange: CreatorsDateRange): Action {
    return {
        type: SET_CREATORS_DATE_RANGE,
        payload: { dateRange }
    };
}

// Set Sort Order -----------------------------------------------

export const SET_CREATORS_SORT_ORDER = "SET_CREATORS_SORT_ORDER";

export function setCreatorsSortOrder(sortOrder: CreatorSortOrder): Action {
    return {
        type: SET_CREATORS_SORT_ORDER,
        payload: { sortOrder }
    };
}

// Set Sort -----------------------------------------------

export const SET_CREATORS_SORT = "SET_CREATORS_SORT";

export function setCreatorsSort(sort: CreatorsSort[]): Action {
    return {
        type: SET_CREATORS_SORT,
        payload: { sort }
    };
}

// Set Query Filters -----------------------------------------------

export const SET_CREATORS_QUERY_FILTERS = "SET_CREATORS_QUERY_FILTERS";

export function setCreatorsQueryFilters(queryFilters: any[]): Action {
    return {
        type: SET_CREATORS_QUERY_FILTERS,
        payload: { queryFilters }
    };
}

// Set Total Hits -----------------------------------------------

export const SET_CREATOR_TOTAL_HITS = "SET_CREATOR_TOTAL_HITS";

export function setCreatorTotalHits(nbHits: number): Action {
    return {
        type: SET_CREATOR_TOTAL_HITS,
        payload: { nbHits }
    };
}

// Set Max Pages -----------------------------------------------

export const SET_CREATORS_MAX_PAGES = "SET_CREATORS_MAX_PAGES";

export function setCreatorsMaxPages(maxPages: number): Action {
    return {
        type: SET_CREATORS_MAX_PAGES,
        payload: { maxPages }
    };
}

// Reset Query Parameters -----------------------------------------------

export const RESET_CREATORS_QUERY_PARAMETERS =
    "RESET_CREATORS_QUERY_PARAMETERS";

export function resetCreatorsQueryParameters(): Action {
    return {
        type: RESET_CREATORS_QUERY_PARAMETERS,
        payload: null
    };
}

// Set Current Header Filter Dropdown -----------------------------------------------

export const CURRENT_HEADER_FILTER_DROPDOWN = "CURRENT_HEADER_FILTER_DROPDOWN";

export function setCurrentHeaderFilterDropdown(
    filterType: "" | FilterType
): Action {
    return {
        type: CURRENT_HEADER_FILTER_DROPDOWN,
        payload: { filterType }
    };
}

// Set Platform Filters -----------------------------------------------

export const SET_PLATFORM_FILTERS = "SET_PLATFORM_FILTERS";

export function setPlatformFilters(filters: Filters): Action {
    return {
        type: SET_PLATFORM_FILTERS,
        payload: { filters }
    };
}

// Set Interests Filters -----------------------------------------------

export const SET_INTERESTS_FILTERS = "SET_INTERESTS_FILTERS";

export function setInterestsFilters(filters: Filters): Action {
    return {
        type: SET_INTERESTS_FILTERS,
        payload: { filters }
    };
}

// Set Themes Filters -----------------------------------------------

export const SET_THEMES_FILTERS = "SET_THEMES_FILTERS";

export function setThemesFilters(filters: Filters): Action {
    return {
        type: SET_THEMES_FILTERS,
        payload: { filters }
    };
}

// Set Creator Country Region Filters -----------------------------------------------

export const SET_CREATOR_COUNTRY_REGION_FILTERS =
    "SET_CREATOR_COUNTRY_REGION_FILTERS";

export function setCreatorCountryRegionFilters(filters: Filters): Action {
    return {
        type: SET_CREATOR_COUNTRY_REGION_FILTERS,
        payload: { filters }
    };
}

// Set Audience Country Region Filters -----------------------------------------------\

export const SET_AUDIENCE_COUNTRY_REGION_FILTERS =
    "SET_AUDIENCE_COUNTRY_REGION_FILTERS";

export function setAudienceCountryRegionFilters(filters: Filters): Action {
    return {
        type: SET_AUDIENCE_COUNTRY_REGION_FILTERS,
        payload: { filters }
    };
}

// Set Followers Filter -----------------------------------------------

export const SET_FOLLOWERS_FILTER = "SET_FOLLOWERS_FILTER";

export function setFollowersFilter(followers: string): Action {
    return {
        type: SET_FOLLOWERS_FILTER,
        payload: { followers }
    };
}

// Set Average Views Filter -----------------------------------------------

export const SET_AVERAGE_VIEWS_FILTER = "SET_AVERAGE_VIEWS_FILTER";

export function setAverageViewsFilter(views: string): Action {
    return {
        type: SET_AVERAGE_VIEWS_FILTER,
        payload: { views }
    };
}

// Set Engagement Rate Filters -----------------------------------------------

export const SET_ENGAGEMENT_RATE_FILTERS = "SET_ENGAGEMENT_RATE_FILTERS";

export function setEngagementRateFilter(engagementRate: string): Action {
    return {
        type: SET_ENGAGEMENT_RATE_FILTERS,
        payload: { engagementRate }
    };
}

// Set Audience Gender Filters -----------------------------------------------

export const SET_AUDIENCE_GENDER_FILTER = "SET_AUDIENCE_GENDER_FILTER";

export function setAudienceGenderFilter(gender: string): Action {
    return {
        type: SET_AUDIENCE_GENDER_FILTER,
        payload: { gender }
    };
}

// Set Audience Age Filters -----------------------------------------------

export const SET_AUDIENCE_AGE_FILTERS = "SET_AUDIENCE_AGE_FILTERS";

export function setAudienceAgeFilters(filters: Filters): Action {
    return {
        type: SET_AUDIENCE_AGE_FILTERS,
        payload: { filters }
    };
}

// Set Audience Device Filters -----------------------------------------------

export const SET_AUDIENCE_DEVICE_FILTER = "SET_AUDIENCE_DEVICE_FILTER";

export function setAudienceDeviceFilter(device: string): Action {
    return {
        type: SET_AUDIENCE_DEVICE_FILTER,
        payload: { device }
    };
}

// Reset Platform Filters -----------------------------------------------

export const RESET_PLATFORM_FILTERS = "RESET_PLATFORM_FILTERS";

export function resetPlatformFilters(): Action {
    return {
        type: RESET_PLATFORM_FILTERS,
        payload: null
    };
}

// Reset Interests Filters -----------------------------------------------

export const RESET_INTERESTS_FILTERS = "RESET_INTERESTS_FILTERS";

export function resetInterestsFilters(): Action {
    return {
        type: RESET_INTERESTS_FILTERS,
        payload: null
    };
}

// Reset Themes Filters -----------------------------------------------

export const RESET_THEMES_FILTERS = "RESET_THEMES_FILTERS";

export function resetThemesFilters(): Action {
    return {
        type: RESET_THEMES_FILTERS,
        payload: null
    };
}

// Reset Creator Country/Region Filters -----------------------------------------------

export const RESET_CREATOR_COUNTRY_REGION_FILTERS =
    "RESET_CREATOR_COUNTRY_REGION_FILTERS";

export function resetCreatorCountryRegionFilters(): Action {
    return {
        type: RESET_CREATOR_COUNTRY_REGION_FILTERS,
        payload: null
    };
}

// Reset Audience Country/Region Filters -----------------------------------------------

export const RESET_AUDIENCE_COUNTRY_REGION_FILTERS =
    "RESET_AUDIENCE_COUNTRY_REGION_FILTERS";

export function resetAudienceCountryRegionFilters(): Action {
    return {
        type: RESET_AUDIENCE_COUNTRY_REGION_FILTERS,
        payload: null
    };
}

// Reset Followers Filters -----------------------------------------------

export const RESET_FOLLOWERS_FILTERS = "RESET_FOLLOWERS_FILTERS";

export function resetFollowersFilters(): Action {
    return {
        type: RESET_FOLLOWERS_FILTERS,
        payload: null
    };
}

// Reset Average Views Filters -----------------------------------------------

export const RESET_AVERAGE_VIEWS_FILTERS = "RESET_AVERAGE_VIEWS_FILTERS";

export function resetAverageViewsFilters(): Action {
    return {
        type: RESET_AVERAGE_VIEWS_FILTERS,
        payload: null
    };
}

// Reset Engagement Rate Filters -----------------------------------------------

export const RESET_ENGAGEMENT_RATE_FILTERS = "RESET_ENGAGEMENT_RATE_FILTERS";

export function resetEngagementRateFilters(): Action {
    return {
        type: RESET_ENGAGEMENT_RATE_FILTERS,
        payload: null
    };
}

// Reset Audience Gender Filters -----------------------------------------------

export const RESET_AUDIENCE_GENDER_FILTERS = "RESET_AUDIENCE_GENDER_FILTERS";

export function resetAudienceGenderFilters(): Action {
    return {
        type: RESET_AUDIENCE_GENDER_FILTERS,
        payload: null
    };
}

// Reset Audience Age Filters -----------------------------------------------

export const RESET_AUDIENCE_AGE_FILTERS = "RESET_AUDIENCE_AGE_FILTERS";

export function resetAudienceAgeFilters(): Action {
    return {
        type: RESET_AUDIENCE_AGE_FILTERS,
        payload: null
    };
}

// Reset Audience Device Filters -----------------------------------------------

export const RESET_AUDIENCE_DEVICE_FILTER = "RESET_AUDIENCE_DEVICE_FILTER";

export function resetAudienceDeviceFilters(): Action {
    return {
        type: RESET_AUDIENCE_DEVICE_FILTER,
        payload: null
    };
}

// Reset Creators Filters -----------------------------------------------

export const RESET_CREATORS_FILTERS = "RESET_CREATORS_FILTERS";

export function resetCreatorsFilters(): Action {
    return {
        type: RESET_CREATORS_FILTERS,
        payload: null
    };
}
