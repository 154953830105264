import React from "react";
import { useSelector } from "react-redux";
import { Placement } from "src/campaigns/types";
import { colors } from "src/constants";
import PlacementsElement from "src/marketplace/instructions-sets/components/PlacementsElement";
import { GlobalState } from "src/reducers";
import styled from "styled-components";

const PlacementsWrap = styled.div`
    background-color: ${colors.quaternaryLightGray};
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
`;
const Title = styled.h4`
    margin: 0px;
    color: ${colors.primaryGray};
    font-size: 12px;
    margin-bottom: 10px;
`;
const PlacementElements = styled.div`
    display: flex;
    overflow: auto;
    width: 100%;
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    & > * {
        :last-child {
            margin-right: 0px;
        }
        margin-right: 8px;
    }
`;
const MediaPlacements = ({
    placementIds,
    className
}: {
    placementIds: number[];
    className?: string;
}) => {
    const placements = useSelector((state: GlobalState) => {
        return state.entities.placements.byId;
    });
    return (
        <PlacementsWrap className={className}>
            <Title>PLACEMENTS</Title>
            <PlacementElements>
                {placementIds.length > 0 ? (
                    placementIds.map((id, idx) => {
                        const placement = placements[id];
                        return (
                            <PlacementsElement
                                key={id + idx}
                                socialLogo={placement.logo}
                                text={placement.type}
                            />
                        );
                    })
                ) : (
                    <PlacementsElement
                        empty
                        socialLogo=""
                        text="No Placements"
                    />
                )}
            </PlacementElements>
        </PlacementsWrap>
    );
};

export default MediaPlacements;
