import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "src/components/CommonStyles";
import { CustomLoading } from "src/components/SkeletonLoader";
import TwoButtonFooter from "src/components/TwoButtonFooter";
import { colors, fonts } from "src/constants";
import HalfScreenModal from "src/modals/HalfScreenModal";
import LargeRoundedIcon from "src/profile/components/LargeRoundedIcon";
import { GlobalState } from "src/reducers";
import { shortenNumber } from "src/util";
import styled from "styled-components";
import { fetchDelayedCampaigns } from "./actions";
import ClickActivityModal from "./ClickActivityModal";
import { getDelayedCampaigns } from "./selectors";

const Title = styled.div`
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fonts.subtitle}px;
    font-weight: 700;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
    padding: 12px 18px;
`;

const ListWrapper = styled.div`
    padding: 12px 18px;
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 30vh;

    & > * ~ * {
        margin-top: 10px;
    }
`;

const CampaignItem = styled.div`
    ${FlexVerticallyCentered}
    text-align: left;
    width: 100%;
    user-select: none;
    cursor: pointer;
`;

const Icon = styled(LargeRoundedIcon)`
    height: 46px;
    width: 46px;
    min-width: 46px;
    user-select: none;
`;

const Name = styled.div`
    font-size: ${fonts.smallTitle}px;
    font-weight: 500;
    line-height: 19px;
`;

const Description = styled.div`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: ${colors.primaryGray};
`;

const Caret = styled.img`
    transform: rotate(-90deg);
`;

const CaretWrapper = styled.div`
    ${FlexCentered}
    margin-left: auto;
    width: 24px;
    height: 24px;
`;

interface Props {
    closeModal: () => void;
}

const DelayedClicksUpdatesModal = ({ closeModal }: Props) => {
    const dispatch = useDispatch();
    const delayedCampaigns = useSelector(getDelayedCampaigns);
    const delayedCampaignLoading = useSelector(
        (state: GlobalState) => state.notifications.delayedCampaignLoading
    );

    const [activityCampaignId, setActivityCampaignId] = useState<number | null>(
        null
    );

    return (
        <HalfScreenModal closeModal={closeModal}>
            <Title>Which campaign?</Title>
            <Subtitle>
                Select the campaign that you’d like to view click data for:
            </Subtitle>
            <ListWrapper>
                <List>
                    {delayedCampaignLoading ? (
                        <>
                            <CustomLoading height="46px" />
                            <CustomLoading height="46px" />
                            <CustomLoading height="46px" />
                        </>
                    ) : (
                        delayedCampaigns.map(
                            ({
                                campaignId,
                                campaignImageUrl,
                                campaignName,
                                clicks
                            }) => (
                                <CampaignItem
                                    key={campaignId}
                                    onClick={() =>
                                        setActivityCampaignId(campaignId)
                                    }
                                >
                                    <Icon src={campaignImageUrl} />
                                    <div>
                                        <Name>{campaignName}</Name>
                                        <Description>
                                            <strong>
                                                {shortenNumber(clicks, 1)}
                                            </strong>{" "}
                                            new clicks
                                        </Description>
                                    </div>
                                    <CaretWrapper>
                                        <Caret src={"/caret.svg"} />
                                    </CaretWrapper>
                                </CampaignItem>
                            )
                        )
                    )}
                </List>
            </ListWrapper>
            <TwoButtonFooter
                leftText="Refresh Clicks"
                leftCallback={() => dispatch(fetchDelayedCampaigns())}
                leftLoading={delayedCampaignLoading}
                rightText="Done"
                rightCallback={closeModal}
            />
            <AnimatePresence>
                {activityCampaignId && (
                    <ClickActivityModal
                        closeModal={() => setActivityCampaignId(null)}
                        campaignId={activityCampaignId}
                    />
                )}
            </AnimatePresence>
        </HalfScreenModal>
    );
};

export default DelayedClicksUpdatesModal;
