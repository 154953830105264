import { Action, NormalizedAction } from "../types";
import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../reducers";
import {
    DirectBuy,
    DirectBuyStatus,
    directBuyStatusAll
} from "./modules/direct-buy/types";
import { DirectBuyStep } from "./modules/direct-buy-step/types";
import {
    OpenBuy,
    OpenBuySubmission,
    OpenBuySubmissionSet
} from "./modules/open-buys/types";
import {
    CachedFilters,
    clearedCachedFilters
} from "src/ui/direct-buys/reducer";
import { SocialAccount } from "src/profile/types";

export const REQUEST_DIRECT_BUY_BY_ID = "REQUEST_DIRECT_BUY_BY_ID";

export const requestDirectBuyById = (directBuyId: number): Action => ({
    type: REQUEST_DIRECT_BUY_BY_ID,
    payload: directBuyId
});

export const REQUEST_DIRECT_BUYS_BY_STATUS = "REQUEST_DIRECT_BUYS_BY_STATUS";

export const requestDirectBuysByStatus = (
    status: DirectBuyStatus[] = directBuyStatusAll,
    daterange?: string
): Action => ({
    type: REQUEST_DIRECT_BUYS_BY_STATUS,
    payload: { status, daterange }
});

export const ADD_DIRECT_BUY_ENTITIES = "ADD_DIRECT_BUY_ENTITIES";

export const addDirectBuyEntities = (
    response: NormalizedSchema<EntitiesState, number[]>,
    status?: DirectBuyStatus[]
): NormalizedAction => ({
    type: ADD_DIRECT_BUY_ENTITIES,
    response,
    payload: { ...(status && { status }) }
});

export const UPDATE_DIRECT_BUY = "UPDATE_DIRECT_BUY";

export const updateDirectBuy = (directBuy: DirectBuy): Action => ({
    type: UPDATE_DIRECT_BUY,
    payload: directBuy
});

export const DELETE_DIRECT_BUY = "DELETE_DIRECT_BUY";

export const deleteDirectBuy = (directBuyId: number): Action => ({
    type: DELETE_DIRECT_BUY,
    payload: directBuyId
});

export const UPDATE_DIRECT_BUY_STEP = "UPDATE_DIRECT_BUY_STEP";

export const updateDirectBuyStep = (directBuyStep: DirectBuyStep): Action => ({
    type: UPDATE_DIRECT_BUY_STEP,
    payload: directBuyStep
});

//OpenBuys

export const REQUEST_OPEN_BUYS_BY_STATUS = "REQUEST_OPEN_BUYS_BY_STATUS";

export const requestOpenBuysByStatus = (
    status: DirectBuyStatus[] = directBuyStatusAll,
    daterange?: string
): Action => ({
    type: REQUEST_OPEN_BUYS_BY_STATUS,
    payload: { status, daterange }
});

export const REQUEST_OPEN_BUY_BY_ID = "REQUEST_OPEN_BUY_BY_ID";

export const requestOpenBuyById = (
    openBuyId: number,
    isPublic?: boolean
): Action => ({
    type: REQUEST_OPEN_BUY_BY_ID,
    payload: { openBuyId, isPublic }
});

export const ADD_OPEN_BUY_ENTITIES = "ADD_OPEN_BUY_ENTITIES";

export const addOpenBuyEntities = (
    response: NormalizedSchema<EntitiesState, number[]>,
    isByIdFetch: boolean
): NormalizedAction => ({
    type: ADD_OPEN_BUY_ENTITIES,
    response,
    payload: { isByIdFetch }
});

export const UPDATE_OPEN_BUY_STEP = "UPDATE_OPEN_BUY_STEP";

export const addOpenBuyStepsEntities = (
    response: NormalizedSchema<EntitiesState, number[]>
): NormalizedAction => ({
    type: UPDATE_OPEN_BUY_STEP,
    response
});
export const UPDATE_OPEN_BUY = "UPDATE_OPEN_BUY";

export const updateOpenBuy = (openBuy: OpenBuy): Action => ({
    type: UPDATE_OPEN_BUY,
    payload: openBuy
});

export const DELETE_OPEN_BUY = "DELETE_OPEN_BUY";

export const deleteOpenBuy = (openBuyId: number): Action => ({
    type: DELETE_OPEN_BUY,
    payload: openBuyId
});

export const TOGGLE_OPEN_BUY_STEP_EXPANDED_STATE =
    "TOGGLE_OPEN_BUY_STEP_EXPANDED_STATE";

export const toggleDirectBuyStepExpandedState = (
    openBuyStepId: number
): Action => ({
    type: TOGGLE_OPEN_BUY_STEP_EXPANDED_STATE,
    payload: openBuyStepId
});

export const OPEN_BUY_SUBMISSION_MODAL = "OPEN_BUY_SUBMISSION_MODAL";

export const setOpenBuySubmissionModal = (
    openBuyStepId: number | null
): Action => ({
    type: OPEN_BUY_SUBMISSION_MODAL,
    payload: openBuyStepId
});

export const OPEN_BUY_SUBMISSION_STEP = "OPEN_BUY_SUBMISSION_STEP";

export const setOpenBuySubmissionStep = (
    openBuyStepId: number | null
): Action => ({
    type: OPEN_BUY_SUBMISSION_STEP,
    payload: openBuyStepId
});

export const FETCH_OPEN_BUY_SUBMISSIONS = "FETCH_OPEN_BUY_SUBMISSIONS";

//gets the publishers open buy submissions
export const getOpenBuySubmissions = (openBuyId: number): Action => ({
    type: FETCH_OPEN_BUY_SUBMISSIONS,
    payload: { openBuyId }
});

export const SET_OPEN_BUY_SUBMISSIONS = "SET_OPEN_BUY_SUBMISSIONS";

export const setOpenBuySubmissions = (
    submissions: OpenBuySubmission[]
): Action => ({ type: SET_OPEN_BUY_SUBMISSIONS, payload: submissions });

export const ADD_OPEN_BUY_SUBMISSIONS = "ADD_OPEN_BUY_SUBMISSIONS";

export const addOpenBuySubmissions = (
    submissions: OpenBuySubmission[],
    openBuyId: number
): Action => ({
    type: ADD_OPEN_BUY_SUBMISSIONS,
    payload: { submissions, openBuyId }
});

export const UPDATE_OPEN_BUY_SUBMISSIONS = "UPDATE_OPEN_BUY_SUBMISSIONS";

export const updateOpenBuySubmission = (
    submission: OpenBuySubmission
): Action => ({
    type: UPDATE_OPEN_BUY_SUBMISSIONS,
    payload: submission
});

export const DELETE_OPEN_BUY_SUBMISSION = "DELETE_OPEN_BUY_SUBMISSION";

export const deleteOpenBuySubmission = (
    submissionId: number,
    openBuyId: number
): Action => ({
    type: DELETE_OPEN_BUY_SUBMISSION,
    payload: { submissionId, openBuyId }
});

export const SET_CACHED_FILTERS = "SET_CACHED_FILTERS";

export const setCachedFilters = (cachedFilters: CachedFilters) => ({
    type: SET_CACHED_FILTERS,
    payload: cachedFilters
});

export const CLEAR_CACHED_FILTERS = "CLEAR_CACHED_FILTERS";

export const clearCachedFilters = () => ({
    type: CLEAR_CACHED_FILTERS,
    payload: clearedCachedFilters
});

export const SET_SUBMISSION_SETS = "SET_SUBMISSION_SETS";

export const setSubmissionSets = (
    submissionsSets: NormalizedSchema<EntitiesState, number[]>
) => ({
    type: SET_SUBMISSION_SETS,
    payload: submissionsSets
});

export const FETCH_SUBMISSION_SETS = "FETCH_SUBMISSION_SETS";

export const fetchSubmissionSets = (openBuyId: number) => ({
    type: FETCH_SUBMISSION_SETS,
    payload: openBuyId
});

export const FETCH_SUBMISSION_SET = "FETCH_SUBMISSION_SET";

export const fetchSubmissionSet = (id: number) => ({
    type: FETCH_SUBMISSION_SET,
    payload: id
});

export const UPDATE_SUBMISSION_SET_SOCIAL = "UPDATE_SUBMISSON_SET_SOCIAL";

export const updateSubmissionSetSocial = (
    setId: number,
    socialAccount: SocialAccount
) => ({
    type: UPDATE_SUBMISSION_SET_SOCIAL,
    payload: { setId, socialAccount }
});

export const TOGGLE_RULES_MODAL = "TOGGLE_RULES_MODAL";

export const toggleRulesModal = (value: number | null) => ({
    type: TOGGLE_RULES_MODAL,
    payload: value
});
