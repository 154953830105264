import React from "react";
import styled from "styled-components";
import SearchTag from "../../components/SearchTag";
import {
    DirectBuyStatus,
    directBuyStatusAll
} from "../modules/direct-buy/types";
import {
    directBuySearchModal,
    setStatusFiltersState
} from "../../ui/direct-buys/actions";
import { DirectBuySearchModalState } from "../../ui/direct-buys/reducer";
import { useDispatch, useSelector } from "react-redux";
import { selectDirectBuyStatusFilters } from "../selectors";

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    position: -webkit-sticky;
    top: 50px;
    z-index: 99;
`;

interface Props {
    sortMode: string;
    date?: string;
    setSortMode: (mode: string) => void;
    isOpenBuy?: boolean;
}

const DirectBuysSearchSortAndFilter = ({
    sortMode,
    date,
    setSortMode,
    isOpenBuy
}: Props) => {
    const dispatch = useDispatch();
    const statusFilters = useSelector(selectDirectBuyStatusFilters);
    return (
        <Main>
            {date && (
                <SearchTag
                    text={date}
                    color={"white"}
                    iconSrc={"/date/date.png"}
                    onClick={() => {
                        dispatch(
                            directBuySearchModal(DirectBuySearchModalState.date)
                        );
                    }}
                />
            )}
            {!isOpenBuy && (
                <SearchTag
                    text={sortMode}
                    color={"white"}
                    iconSrc={
                        sortMode === "Newest"
                            ? "/tag-teal-arrow-icon.svg"
                            : "/tag-teal-arrow-icon-up.svg"
                    }
                    onClick={() => {
                        setSortMode(
                            sortMode === "Newest" ? "Oldest" : "Newest"
                        );
                        // dispatch(
                        //     directBuySearchModal(DirectBuySearchModalState.sort)
                        // );
                    }}
                />
            )}

            <SearchTag
                text={"Filters"}
                color={"white"}
                iconSrc={"/tag-teal-plus-icon.svg"}
                onClick={() => {
                    dispatch(
                        directBuySearchModal(DirectBuySearchModalState.filter)
                    );
                }}
            />
            {directBuyStatusAll.map(status => {
                if (isOpenBuy && status === "completed") return null;
                if (statusFilters.includes(status)) {
                    return (
                        <SearchTag
                            key={status}
                            text={
                                status === "expired" && isOpenBuy
                                    ? "Ended"
                                    : status.charAt(0).toUpperCase() +
                                      status.slice(1)
                            }
                            color={"black"}
                            iconSrc={"/tag-black-close-icon.svg"}
                            onClick={() => {
                                dispatch(
                                    setStatusFiltersState(
                                        statusFilters.filter(
                                            currentStatus =>
                                                currentStatus !== status
                                        ) as DirectBuyStatus[]
                                    )
                                );
                            }}
                            disabled={statusFilters.length === 1 ? true : false}
                        />
                    );
                } else {
                    return null;
                }
            })}
        </Main>
    );
};

export default DirectBuysSearchSortAndFilter;
