import React from "react";
import CopyTextWrap from "src/components/CopyTextWrap";
import EmojiModal from "src/modals/EmojiModal";
import { BigBlueButton } from "src/profile/components/Buttons";
import styled from "styled-components";

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: black;
`;
const ModalHeader = styled.div`
    font-size: 24px;
    font-weight: 700;
`;

const ModalBody = styled.div`
    font-size: 14px;
`;

interface Props {
    closeModal: () => void;
}
export default function SupportModal({ closeModal }: Props) {
    return (
        <EmojiModal emoji="👋" localClearModal={closeModal}>
            <ModalContainer>
                <ModalHeader>Need Help?</ModalHeader>
                <ModalBody>
                    We’re here for you! Send us a message at the email below and
                    we’ll get back to you as soon as we can.
                </ModalBody>
                <CopyTextWrap text="support@jetfuel.it" />

                <BigBlueButton onClick={closeModal}>Done</BigBlueButton>
            </ModalContainer>
        </EmojiModal>
    );
}
