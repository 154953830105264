import React, { useState } from "react";
import { Channel } from "../../types";
import SettingsHeader from "./SettingsHeader";
import SettingsToggle from "./SettingsToggle";
import PaddingAround from "../../../components/PaddingAround";
import EditChannelModal from "./modals/EditChannelModal";
import { ChatModal } from "../../reducer";
import Divider from "../../../components/Divider";
import MemberSection from "./MemberSection";
import AdminModals from "./modals/AdminModals";

interface Props {
    userId: number;
    channel: Channel;
    setChannel: (channel: Channel) => void;
    sdk: any;
    modal: ChatModal;
    editChannelModal: VoidFunction;
    adminActionsModal: VoidFunction;
    setAddMembers: (addMembers: boolean) => void;
    setAddAdmins: (addAdmins: boolean) => void;
    muteBanRefresh: VoidFunction;
}

const ChannelSettings = ({
    userId,
    channel,
    setChannel,
    sdk,
    modal,
    editChannelModal,
    adminActionsModal,
    setAddMembers,
    setAddAdmins,
    muteBanRefresh
}: Props) => {
    const sendBirdData = channel.sendBirdData;

    const [currentUser, setCurrentUser] = useState<any>(null);

    return (
        <>
            <SettingsHeader
                coverUrl={
                    sendBirdData.coverUrl ||
                    "/profile_pic_blue/profile_pic_blue@2x.png"
                }
                name={sendBirdData.name}
                members={sendBirdData.members.length}
                editCallback={editChannelModal}
            />
            <PaddingAround>
                <SettingsToggle
                    description={"Mute"}
                    value={sendBirdData.myPushTriggerOption === "off"}
                    setValue={newValue => {
                        if (sdk.GroupChannel) {
                            const newTextValue = newValue ? "off" : "default";

                            sendBirdData.setMyPushTriggerOption(
                                newTextValue,
                                (newSetting: any, error: any) => {
                                    if (!error) {
                                        sdk.GroupChannel.getChannel(
                                            sendBirdData.url,
                                            (newChannel: any, error: any) => {
                                                if (!error) {
                                                    setChannel({
                                                        ...channel,
                                                        sendBirdData: newChannel
                                                    });
                                                } else {
                                                    window.alert(error);
                                                }
                                            }
                                        );
                                    } else {
                                        window.alert(error);
                                    }
                                }
                            );
                        }
                    }}
                />
                <SettingsToggle
                    description={"Public Group"}
                    value={sendBirdData.isPublic}
                    setValue={newValue => {
                        if (sdk.GroupChannel && sdk.GroupChannelParams) {
                            const params = new sdk.GroupChannelParams();
                            params.isPublic = newValue;
                            sendBirdData.updateChannel(
                                params,
                                (newChannel: any, error: any) => {
                                    if (!error) {
                                        setChannel({
                                            ...channel,
                                            sendBirdData: newChannel
                                        });
                                    } else {
                                        window.alert(error);
                                    }
                                }
                            );
                        }
                    }}
                />
            </PaddingAround>
            <Divider />
            <MemberSection
                channel={channel}
                addMembersCallback={() => {
                    setAddMembers(true);
                }}
                addAdminsCallback={() => {
                    setAddAdmins(true);
                }}
                actionsModalCallback={currentUser => {
                    setCurrentUser(currentUser);
                    adminActionsModal();
                }}
            />
            {modal === ChatModal.editChannel && (
                <EditChannelModal
                    channel={channel}
                    setChannel={setChannel}
                    sdk={sdk}
                />
            )}
            {currentUser && channel.mutedUsers && channel.banned && (
                <AdminModals
                    modal={modal}
                    currentUser={currentUser}
                    channel={channel}
                    mutedUsers={channel.mutedUsers}
                    bannedUsers={channel.banned.bannedUsers}
                    muteBanRefresh={muteBanRefresh}
                />
            )}
        </>
    );
};

export default ChannelSettings;
