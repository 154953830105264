import React, { useEffect } from "react";
import styled from "styled-components";
import { freezeBody, thawBody } from "../util";
import { AnimateSharedLayout, motion } from "framer-motion";
import { breakpoints, colors, maxWidthDesktop, spacing } from "src/constants";

const Parent = styled(motion.div)`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0px ${spacing.sideSpacing}px;

    @media (max-width: ${breakpoints.mobile}px) {
        padding: 0px;
    }
`;

interface ChildProps {
    expand?: true;
}

const Child = styled(motion.div)<ChildProps>`
    width: 100%;
    max-width: ${props => (props.expand ? `${maxWidthDesktop}px` : "500px")};
    background-color: ${colors.white};
    border-radius: 6px 6px 0px 0px;
    overflow: hidden;

    @media (max-width: ${breakpoints.mobile}px) {
        width: 100vw;
        max-width: 500px;
    }
`;

interface Props {
    children: any;
    closeModal?: VoidFunction;
    keepFrozenOnClose?: true;
    expand?: true;
    className?: string;
}

const HalfScreenModal = (props: Props) => {
    const {
        children,
        closeModal,
        keepFrozenOnClose,
        expand,
        className
    } = props;

    useEffect(() => {
        freezeBody();
        return () => {
            if (!keepFrozenOnClose) thawBody();
        };
    }, []);

    return (
        <Parent
            onClick={closeModal}
            key="full-screen-modal-parent"
            initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            animate={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
            exit={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            transition={{ duration: 0.3 }}
        >
            <AnimateSharedLayout>
                <Child
                    key="full-screen-modal"
                    onClick={e => e.stopPropagation()}
                    initial={{ y: 300, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 300, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    layout
                    expand={expand}
                    className={className}
                >
                    {children}
                </Child>
            </AnimateSharedLayout>
        </Parent>
    );
};

export default HalfScreenModal;
