import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DirectBuyPreviewSize } from "../components/DirectBuyPreview";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { breakpoints, colors } from "../../constants";
import { Shimmer, TextLoading } from "../../components/SkeletonLoader";
import { outerBorderRadiusForDirectBuyPreviewSize } from "../components/directBuyPreviewUtil";
import moment from "moment";
import OpenBuyPreview, {
    OpenBuyPreviewSize
} from "../components/OpenBuyPreview";
import { useWindowSize } from "react-use";

const ResultsLabel = styled.div`
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    padding: 2px 18px;
    color: ${colors.primaryGray};
`;

const ResultLabelLoading = styled(TextLoading)`
    margin: 2px 0px;
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 18px 18px;
    gap: 12px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    border-radius: 5px;
    flex-wrap: wrap;
    gap: 12px;
    @media (max-width: ${breakpoints.tablet}px) {
        justify-content: center;
    }
`;

const Date = styled.div`
    font-size: 14px;
    text-align: left;
    display: flex;
    flex-direction: row;
    color: ${colors.primaryGray};
`;

const PreviewCell = styled.div`
    display: flex;
    height: 195px;
    width: 312px;

    &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(
            260 / 168 * 100%
        ); // (height / width) * 100 = keeping aspect ratio
    }
`;

const Dot = styled.li`
    width: 4px;
    margin: 0px 10px;
`;

const DirectBuyPreviewLoading = styled.div<{
    size: DirectBuyPreviewSize;
}>`
    width: 312px;
    margin: 6px;
    height: calc(100% - 12px);
    border-radius: ${props =>
        outerBorderRadiusForDirectBuyPreviewSize(props.size)}px;
    ${Shimmer};
`;

interface Props {
    dispatch: Dispatch<any>;
    directBuysLoading: boolean;
    directBuys: { [id: string]: number[] };
    sortMode: string;
    buysLength: number;
    handleCache: () => void;
}

const OpenBuysSearchPreviewGrid = ({
    directBuysLoading,
    directBuys,
    sortMode,
    buysLength,
    dispatch,
    handleCache
}: Props) => {
    const windowSize = useWindowSize();
    const [maxItems, setMaxItems] = useState(12);
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        window.scrollTo(0, 0);
        return window.removeEventListener("scroll", handleScroll);
    }, []);

    function handleScroll() {
        const innerHeight = window.innerHeight;
        const scrollTopBody = document.getElementsByTagName("body")[0]
            .scrollTop;
        const scrollTopDoc = document.documentElement.scrollTop;
        const scrollTop = Math.max(scrollTopBody, scrollTopDoc) + 50;
        const offsetHeight = document.documentElement.offsetHeight;
        if (
            !directBuysLoading &&
            maxItems < buysLength &&
            innerHeight + scrollTop >= offsetHeight &&
            document.body.style.position !== "fixed"
        ) {
            setMaxItems(old => old + 12);
        }
    }

    if (directBuysLoading) {
        return (
            <List>
                <ResultLabelLoading width={20} />
                {Array.from(Array(3)).map((a, index) => (
                    <div key={`loader${index}`}>
                        <ResultLabelLoading width={20} />
                        <Section>
                            {Array.from(Array(2)).map((a, index) => (
                                <PreviewCell key={`loadcell${index}`}>
                                    <DirectBuyPreviewLoading
                                        size={DirectBuyPreviewSize.large}
                                    />
                                </PreviewCell>
                            ))}
                        </Section>
                    </div>
                ))}
            </List>
        );
    }
    const items = directBuys;
    let results = 0;
    Object.keys(items).forEach(item => (results += items[item].length));
    return (
        <>
            <ResultsLabel>
                {results} RESULT
                {results !== 1 && "S"}
            </ResultsLabel>
            <List>
                {Object.keys(items)
                    .sort((a, b) =>
                        a === "Current" || moment(a) > moment(b)
                            ? sortMode === "Newest"
                                ? -1
                                : 1
                            : sortMode === "Newest"
                            ? 1
                            : -1
                    )
                    .map((date, index) => (
                        <div key={`section${index}`}>
                            {date === "Current" ? (
                                <Date>Active Now</Date>
                            ) : (
                                <Date>
                                    {moment(date).format("dddd")}
                                    <Dot />
                                    {moment(date).format("MM/DD")}
                                </Date>
                            )}
                            <Section>
                                {items[date].map((openBuyId: number) => {
                                    return (
                                        <OpenBuyPreview
                                            key={openBuyId}
                                            size={
                                                windowSize.width >
                                                breakpoints.tablet
                                                    ? OpenBuyPreviewSize.small
                                                    : OpenBuyPreviewSize.large
                                            }
                                            searchView={true}
                                            openBuyId={openBuyId}
                                            onClick={() => {
                                                handleCache();
                                                dispatch(
                                                    push(
                                                        `/buys/open/${openBuyId}`
                                                    )
                                                );
                                            }}
                                        />
                                    );
                                })}
                            </Section>
                        </div>
                    ))}
            </List>
        </>
    );
};

export default OpenBuysSearchPreviewGrid;
