import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Tab from "./Tab";
import {
    Contest,
    ContestLeaderboard,
    ContestPrize,
    ContestRaffle
} from "../types";
import PrizeGroup from "./PrizeGroup";
import NavButton from "../../../components/NavButton";
import SimpleNavbar from "../../../components/SimpleNavbar";
import MainContainer from "src/components/MainContainer";
import { colors, maxWidthDesktop } from "src/constants";
import Navbar from "src/components/Navbar";

const TabContainer = styled.div`
    display: flex;
    margin: 20px 0px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${maxWidthDesktop}px) {
        margin: 0px;
        border: none;
        border-radius: 0px;
    }
`;

const Prizes = styled.div`
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 20px;

    @media (max-width: ${maxWidthDesktop}px) {
        grid-gap: 0px;
        border-bottom: ${colors.mediumTeal} solid 1px;
    }
`;

enum TabState {
    contest,
    raffle
}

interface Props {
    contest: Contest;
    push: (path: string) => void;
    setClaimPrize: (claimPrize: ContestPrize) => void;
}

const PrizeList = ({ contest, push, setClaimPrize }: Props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [currentTab, setCurrentTab] = useState(
        window.location.search === "?raffle"
            ? TabState.raffle
            : TabState.contest
    );

    const prizeArray: (ContestLeaderboard | ContestRaffle)[] =
        contest[currentTab === TabState.raffle ? "raffle" : "leaderboard"];

    return (
        <>
            <Navbar
                title="PRIZE LIST"
                customMobileNavbar={
                    <SimpleNavbar
                        left={
                            <NavButton back onClick={() => push("/contest")} />
                        }
                        title={"PRIZE LIST"}
                    />
                }
            />

            <MainContainer>
                <TabContainer>
                    <Tab
                        name={"CONTEST"}
                        active={currentTab === TabState.contest}
                        onClick={() => setCurrentTab(TabState.contest)}
                    />
                    <Tab
                        name={"RAFFLE"}
                        active={currentTab === TabState.raffle}
                        onClick={() => setCurrentTab(TabState.raffle)}
                    />
                </TabContainer>

                <Prizes>
                    {prizeArray.map((prizeGroup, idx) => {
                        return (
                            <PrizeGroup
                                key={idx}
                                group={prizeGroup}
                                setClaimPrize={setClaimPrize}
                            />
                        );
                    })}
                </Prizes>
            </MainContainer>
        </>
    );
};

export default PrizeList;
