import React from "react";
import { connect } from "react-redux";
import { Connection, PublicProfileData } from "../../types";
import { fetchAccountsPublic } from "../../actions";
import { push } from "connected-react-router";
import Header from "../../../components/Header";
import CardLoader from "../../components/CardLoader";
import PaddingNoTop from "../../../components/PaddingNoTop";
import AccountCell from "../components/AccountCell";
import { BigOutlineButton } from "../../components/Buttons";
import PaddingAround from "../../../components/PaddingAround";
import XCard from "../../components/XCard";

interface Props {
    publicData: PublicProfileData;
    fetchAccountsPublic: (connection: Connection) => void;
    push: (route: string) => void;
}

const PublicAccountsCard = ({
    publicData,
    fetchAccountsPublic,
    push
}: Props) => {
    const connection = publicData.connection;
    const userid = connection.other.id.slice(2);
    const accounts = publicData.accounts;

    if (accounts === null) {
        return (
            <PaddingAround>
                <XCard
                    text="Failed to load accounts, click the button below to try again."
                    button={{
                        text: "Try again",
                        callback: () => {
                            fetchAccountsPublic(connection);
                        }
                    }}
                />
            </PaddingAround>
        );
    } else if (!accounts) {
        return <CardLoader />;
    } else if (accounts.length === 0) {
        return (
            <>
                <Header title={"Accounts"} subtitle={"Social Accounts"} />
                <PaddingNoTop>
                    <XCard
                        text="This user has no social accounts that are visible to you."
                        connection={connection}
                    />
                </PaddingNoTop>
            </>
        );
    } else {
        const verified = accounts.reduce((acc, account) => {
            if (account.verified) {
                return acc + 1;
            } else {
                return acc;
            }
        }, 0);

        const unverified = accounts.reduce((acc, account) => {
            if (account.verified) {
                return acc;
            } else {
                return acc + 1;
            }
        }, 0);

        return (
            <>
                <Header
                    title={"Accounts"}
                    subtitle={`${verified} Verified; ${unverified} Unverified`}
                    link={{
                        text: "View more",
                        onClick: () => {
                            push(`/public/${userid}/accounts`);
                        }
                    }}
                />
                <PaddingNoTop>
                    {accounts.slice(0, 2).map(account => {
                        return (
                            <AccountCell
                                key={account.id}
                                account={account}
                                push={push}
                            />
                        );
                    })}
                </PaddingNoTop>
                <PaddingNoTop>
                    <BigOutlineButton
                        onClick={() => {
                            push(`/public/${userid}/accounts`);
                        }}
                    >
                        See all
                    </BigOutlineButton>
                </PaddingNoTop>
            </>
        );
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchAccountsPublic: (connection: Connection) =>
        dispatch(fetchAccountsPublic(connection)),
    push: (route: string) => dispatch(push(route))
});

export default connect(null, mapDispatchToProps)(PublicAccountsCard);
