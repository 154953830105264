import React, { useState } from "react";
import styled from "styled-components";
// @ts-ignore
import SimpleNavbar from "../components/SimpleNavbar";
import NavButton from "../components/NavButton";
import { AnalyticsDateRangeObject } from "./AnalyticsDateRanges";
import moment from "moment";
import { colors, fonts } from "../constants";
import MainContainer from "src/components/MainContainer";

const Main = styled.div`
    background-color: ${colors.lightTeal};
    padding: 10px 15px;
    box-sizing: border-box;
    font-size: ${fonts.subtitle}px;
    min-height: calc(100vh - 57px);
`;

const Title = styled.div`
    color: darkgray;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
`;

const Container = styled.div`
    border-radius: 6px;
    border: 1px solid ${colors.mediumTeal};
    background-color: white;
    margin-bottom: 10px;
`;

interface RowProps {
    dateRow?: boolean;
}

const Row = styled.div<RowProps>`
    position: relative;
    text-align: left;
    font-size: ${fonts.subtitle}px;
    line-height: 1em;
    padding: ${props => (props.dateRow ? "0px" : "12px 16px")};
    user-select: none;
    cursor: pointer;
    /* transition: 0.1s ease-in; */

    & + & {
        border-top: 1px solid ${colors.mediumTeal};
    }
    &:hover {
        background-color: ${colors.lightGray};
    }
`;
const DateInput = styled.input`
    position: relative;
    width: 100%;
    height: 48px;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    /* padding: 12px 16px; */
    font-size: inherit;
    cursor: pointer;
    -webkit-appearance: none;

    &::-webkit-calendar-picker-indicator {
        width: 100%;
        height: 100%;
        padding-left: 0px;
        margin-left: 0px;
        opacity: 0;
        cursor: pointer;
    }
    &::-webkit-datetime-edit-fields-wrapper {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
    @-moz-document url-prefix() {
        padding: 0px 16px;
    }
    &:hover {
        background-color: ${colors.lightGray};
    }
`;

interface Props {
    onCancelTapped: () => void;
    onDoneTapped: (args: {
        dateRange: AnalyticsDateRangeObject;
        startDate: moment.Moment | null;
        endDate: moment.Moment | null;
    }) => void;
    initialDateRange: AnalyticsDateRangeObject;
    initialStartDate: moment.Moment | null;
    initialEndDate: moment.Moment | null;
    dateRangeArray: AnalyticsDateRangeObject[];
}

const AnalyticsDatePicker = ({
    onCancelTapped,
    onDoneTapped,
    initialDateRange,
    initialStartDate,
    initialEndDate,
    dateRangeArray
}: Props) => {
    const [dateRange, setDateRange] = useState(initialDateRange);

    const [startDate, setStartDate] = useState(
        !initialStartDate ? moment() : initialStartDate
    );
    const [endDate, setEndDate] = useState(
        !initialEndDate ? moment() : initialEndDate
    );

    const leftNavButton = (
        <NavButton onClick={onCancelTapped}>Cancel</NavButton>
    );

    const rightNavButton = (
        <NavButton
            onClick={() => {
                onDoneTapped({
                    dateRange,
                    startDate,
                    endDate
                });
            }}
        >
            <div style={{ color: colors.primaryBlue }}>Done</div>
        </NavButton>
    );

    const currentStartDate = startDate
        ? startDate
        : dateRange.dateRange.startDate;

    const currentEndDate = endDate ? endDate : dateRange.dateRange.endDate;

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = moment(e.target.value);
        if (newDate > currentEndDate) {
            setEndDate(newDate);
            setStartDate(newDate);
        } else {
            setStartDate(newDate);
        }
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = moment(e.target.value);
        if (newDate < currentStartDate) {
            setStartDate(newDate);
            setEndDate(newDate);
        } else {
            setEndDate(newDate);
        }
    };

    return (
        <>
            <SimpleNavbar left={leftNavButton} right={rightNavButton} />
            <Main>
                <MainContainer>
                    <Title>START</Title>
                    <Container>
                        <Row dateRow>
                            <DateInput
                                type="date"
                                onChange={handleStartDateChange}
                                value={currentStartDate.format("YYYY-MM-DD")}
                            />
                        </Row>
                    </Container>
                    <Title>END</Title>
                    <Container>
                        <Row dateRow>
                            <DateInput
                                type="date"
                                onChange={handleEndDateChange}
                                value={currentEndDate.format("YYYY-MM-DD")}
                            />
                        </Row>
                    </Container>
                    <Title>PRESETS</Title>
                    <Container>
                        {dateRangeArray.map((rangeObject, idx) => {
                            return (
                                <Row
                                    key={idx}
                                    onClick={() => {
                                        setDateRange(rangeObject);
                                        setStartDate(
                                            rangeObject.dateRange.startDate
                                        );
                                        setEndDate(
                                            rangeObject.dateRange.endDate
                                        );
                                    }}
                                >
                                    {rangeObject.title}
                                </Row>
                            );
                        })}
                    </Container>
                </MainContainer>
            </Main>
        </>
    );
};

export default AnalyticsDatePicker;
