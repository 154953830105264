import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    POST_ACCOUNTS,
    postAccountsFailure,
    postAccountsSuccess
} from "./actions";
import { post } from "../Api";
import { Action } from "../types";
import { push } from "connected-react-router";
import { pushWithAccount } from "src/profile/actions";

// function* fetchAccountsRequest() {
//     try {
//         const { data, status } = yield call(get, "/get_social_accounts", {});
//
//         if (status === 200) {
//             yield put(fetchAccountsSuccess(data));
//         } else {
//             yield put(fetchAccountsFailure(`status: ${status}`));
//         }
//     } catch (error) {
//         yield put(fetchAccountsFailure("Fetch campaigns fail"));
//     }
// }

function* postAccountsRequest({ payload }: Action) {
    try {
        const { data } = yield call(
            post,
            "/api/bulk_add_social_accounts2",
            payload
        );
        const flattenedAccounts = data.map((account: any) =>
            Array.isArray(account) ? account[1][0] : account
        );
        yield put(postAccountsSuccess(flattenedAccounts));
    } catch (error) {
        console.log(error);
        yield put(
            postAccountsFailure(
                "Failed to add social accounts. Make sure all fields are filled in properly!"
            )
        );
    }
}

export default function* accountsSaga() {
    yield all([takeEvery(POST_ACCOUNTS.REQUEST, postAccountsRequest)]);
}
