import { NormalizedAction } from "../../types";
import { HighlightMedia } from "../types";
import {
    FETCH_CAMPAIGN_HIGHLIGHTS,
    FETCH_HIGHLIGHTS
} from "../highlights/actions";

export interface HighlightMediasEntitiesState {
    [id: number]: HighlightMedia;
}

const initialHighlightMediasState: HighlightMediasEntitiesState = {};

const highlightMediasEntitiesReducer = (
    state = initialHighlightMediasState,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_HIGHLIGHTS.SUCCESS:
        case FETCH_CAMPAIGN_HIGHLIGHTS.SUCCESS:
            const nextState = {
                ...state,
                ...action.response.entities.highlightMedias
            };
            delete nextState.null;
            return nextState;
        default:
            return state;
    }
};

export default highlightMediasEntitiesReducer;
