import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";
import {
    Option,
    OptionList,
    OptionText
} from "src/media/search/components/CommonStyles";
import { CampaignFilters } from "src/campaigns/types";
import CampaignRegionModal from "../modals/CampaignRegionModal";
import LabelText from "src/components/text/LabelText";
import { getSelectedDisplayText } from "src/ui/campaigns/selectors";
import { GlobalState } from "src/reducers";

const Label = styled(LabelText)`
    margin-top: 20px;
    margin-bottom: 12px;
`;

const Caret = styled.img`
    margin-left: auto;
    margin-right: 6px;
`;

interface Props {
    filters: CampaignFilters;
    setFilters: (filters: CampaignFilters) => void;
}

const CampaignRegion = ({ filters, setFilters }: Props) => {
    const [regionModal, toggleRegionModal] = useState(false);
    const regionDisplayText = useSelector((state: GlobalState) =>
        getSelectedDisplayText(state, filters.regions, "region")
    );

    function setRegionConfig(selectedRegions: string[], allRegions: boolean) {
        setFilters({
            ...filters,
            regions: allRegions ? [] : selectedRegions
        });
    }

    return (
        <>
            <Label>REGION COMPATIBILITY</Label>
            <OptionList>
                <Option onClick={() => toggleRegionModal(true)}>
                    <OptionText>{regionDisplayText}</OptionText>
                    <Caret src={"/caret.svg"} />
                </Option>
            </OptionList>
            <AnimatePresence>
                {regionModal && (
                    <CampaignRegionModal
                        localClearModal={() => toggleRegionModal(false)}
                        keepFrozenOnClose
                        regions={filters.regions}
                        setRegionConfig={setRegionConfig}
                    />
                )}
            </AnimatePresence>
        </>
    );
};

export default CampaignRegion;
