import React, { useState } from "react";
import { colors, fonts, fontWeight } from "src/constants";
import styled from "styled-components";
import PayoutTimingDropdown from "../components/PayoutTimingDropdown";
import { Section, SmallTitle, Title } from "../components/styledComponents";
import PaymentBucket from "./PaymentBucket";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { setAdminCurrentWizardStep } from "src/ui/admin-tools/actions";
import { BlueButton } from "src/profile/components/Buttons";
import CreatorSpecificPaymentModal from "../modals/CreatorSpecificPaymentModal";
import XCard from "src/profile/components/XCard";
import { PendingOpenBuyPaymentsGroup } from "../types";
import { removePendingOpenBuyPaymentsGroup } from "../open-buy-payments/actions";
import { getAbstractPaymentTypeFromPendingOpenBuyPayment } from "../open-buy-payments/util";

const Details = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 20px;

    background: ${colors.white};
    border: 0.25px solid #e6e6e6;
    box-shadow: 0px 4px 8px rgba(205, 205, 205, 0.25);
    border-radius: 8px;
`;

const DetailsHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

const DeleteIcon = styled.img`
    cursor: pointer;
`;

const SectionTitle = styled(SmallTitle)`
    text-transform: uppercase;
`;

const InfoText = styled(SmallTitle)<{ value?: boolean }>`
    font-weight: ${props =>
        (props.value && `${fontWeight.heavy}`) || `${fontWeight.medium}`};
    color: ${colors.black};
    padding-right: ${props => (props.value && "8px") || "0px"};
`;

const ErrorBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 8px 12px;
    gap: 10px;
    width: 100%;
    min-height: 30px;
    background: ${colors.errorPink1};
    border-radius: 4px;
    cursor: pointer;
`;

const WarningIcon = styled.img`
    width: 16px;
    height: 14px;
`;

const Tabs = styled.div`
    display: flex;
    width: 100%;
`;

const Tab = styled.div<{ selected: boolean; disabled?: boolean }>`
    display: flex;
    padding: 8px 12px;
    border-radius: 6px;
    background-color: ${props =>
        props.selected ? colors.quaternaryLightGray : colors.transparent};
    color: ${props => (props.selected ? colors.grayBlack : colors.gray4)};
    font-weight: ${props =>
        props.selected ? fontWeight.medium : fontWeight.default};
    font-size: ${fonts.semiSmallTitle}px;
    line-height: 16px;
    cursor: pointer;
    ${props => props.disabled && `pointer-events: none; cursor: default;`};
    :hover {
        background: ${colors.lightGray};
    }
`;

const AddCreatorPaymentButton = styled(BlueButton)`
    display: flex;
    padding: 8px 12px;
    font-size: ${fonts.semiSmallTitle}px;
    margin-left: auto;
`;

enum TabState {
    default,
    creators
}

interface Props {
    index: number;
    openBuyId: number;
    pendingOpenBuyPaymentsGroup: PendingOpenBuyPaymentsGroup | null;
}

const PaymentSetDetails = ({
    index,
    openBuyId,
    pendingOpenBuyPaymentsGroup
}: Props) => {
    const dispatch = useDispatch();

    // Local State -----------------------------------------------------------
    const [selectedTab, setSelectedTab] = useState<TabState>(TabState.default);
    const [currentCreatorGroupId, setCurrentCreatorGroupId] = useState<
        number | null
    >(null);

    // Selectors -----------------------------------------------------------
    const inWizardFlow = window.location.pathname.includes("edit-challenge");
    const { currentInstructionsSetId } = useSelector(
        (state: GlobalState) => state.ui.instructionsSets
    );

    // Local Functions -----------------------------------------------------
    const handleDeleteBucket = () => {
        const instructionStepId =
            pendingOpenBuyPaymentsGroup?.instructionStepId;
        if (instructionStepId) {
            dispatch(
                removePendingOpenBuyPaymentsGroup({
                    openBuyId,
                    instructionStepId
                })
            );
        }
    };

    const creatorSpecificIds =
        pendingOpenBuyPaymentsGroup &&
        Object.keys(pendingOpenBuyPaymentsGroup.creatorSpecific);

    const renderPaymentBuckets = () => {
        if (!pendingOpenBuyPaymentsGroup) {
            return null;
        }
        switch (selectedTab) {
            case TabState.default:
                return (
                    <PaymentBucket
                        openBuyId={openBuyId}
                        instructionStepId={
                            pendingOpenBuyPaymentsGroup.instructionStepId
                        }
                        creatorGroupId={null}
                        paymentConjunction={
                            pendingOpenBuyPaymentsGroup.paymentConjunction
                        }
                        pendingOpenBuyPaymentItems={
                            pendingOpenBuyPaymentsGroup.base
                        }
                    />
                );
            case TabState.creators:
                return creatorSpecificIds && creatorSpecificIds.length > 0 ? (
                    creatorSpecificIds.map(creatorGroupId => {
                        const pendingOpenBuyPaymentItems =
                            pendingOpenBuyPaymentsGroup.creatorSpecific[
                                parseInt(creatorGroupId)
                            ];
                        return (
                            <PaymentBucket
                                key={creatorGroupId}
                                openBuyId={openBuyId}
                                instructionStepId={
                                    pendingOpenBuyPaymentsGroup.instructionStepId
                                }
                                creatorGroupId={parseInt(creatorGroupId)}
                                paymentConjunction={
                                    pendingOpenBuyPaymentsGroup.paymentConjunction
                                }
                                pendingOpenBuyPaymentItems={
                                    pendingOpenBuyPaymentItems
                                }
                                fixedAbstractionPaymentType={
                                    getAbstractPaymentTypeFromPendingOpenBuyPayment(
                                        pendingOpenBuyPaymentsGroup.paymentConjunction,
                                        pendingOpenBuyPaymentsGroup.base
                                    ) || undefined
                                }
                                setCurrentCreatorGroupId={creatorGroupId => {
                                    setCurrentCreatorGroupId(creatorGroupId);
                                }}
                            />
                        );
                    })
                ) : (
                    <Section>
                        <XCard
                            text="There are no Creator Specific Payments for the selected Payout Step."
                            customStyles={{
                                width: "100%",
                                textAlign: "center"
                            }}
                        />
                    </Section>
                );
        }
    };

    const creatorSpecificButtonsDisabled = !pendingOpenBuyPaymentsGroup?.base;
    // Return -----------------------------------------------------------
    return (
        <Details>
            <DetailsHeader>
                <Title>Payment Details {`${index + 1}`}</Title>
                {pendingOpenBuyPaymentsGroup && (
                    <DeleteIcon
                        src="/marketplace-black-circle-x.svg"
                        onClick={handleDeleteBucket}
                    />
                )}
            </DetailsHeader>

            <Section>
                <SectionTitle>Payout Step</SectionTitle>
                {currentInstructionsSetId ? (
                    <PayoutTimingDropdown
                        openBuyId={openBuyId}
                        instructionStepId={
                            pendingOpenBuyPaymentsGroup?.instructionStepId
                        }
                    />
                ) : (
                    <ErrorBox
                        onClick={() => {
                            if (inWizardFlow) {
                                dispatch(
                                    setAdminCurrentWizardStep(
                                        "Select an Instruction Set"
                                    )
                                );
                            }
                        }}
                    >
                        <WarningIcon src={"/warning-red.svg"} />
                        <InfoText>
                            Please select an Instruction Set to apply payment
                            timing.
                        </InfoText>
                    </ErrorBox>
                )}
            </Section>

            <Section>
                <Tabs>
                    <Tab
                        selected={selectedTab === TabState.default}
                        onClick={() => setSelectedTab(TabState.default)}
                    >
                        Default
                    </Tab>
                    <Tab
                        disabled={creatorSpecificButtonsDisabled}
                        selected={selectedTab === TabState.creators}
                        onClick={() => setSelectedTab(TabState.creators)}
                    >
                        Creator Specific Payments (
                        {creatorSpecificIds?.length || 0})
                    </Tab>
                    <AddCreatorPaymentButton
                        type="button"
                        disabled={creatorSpecificButtonsDisabled}
                        onClick={() => setCurrentCreatorGroupId(0)}
                    >
                        Add Creator Specific Payment
                    </AddCreatorPaymentButton>
                </Tabs>
            </Section>

            {renderPaymentBuckets()}

            {pendingOpenBuyPaymentsGroup && currentCreatorGroupId !== null && (
                <CreatorSpecificPaymentModal
                    index={index}
                    openBuyId={openBuyId}
                    instructionStepId={
                        pendingOpenBuyPaymentsGroup.instructionStepId
                    }
                    currentCreatorGroupId={currentCreatorGroupId}
                    closeModal={() => {
                        setSelectedTab(TabState.creators);
                        setCurrentCreatorGroupId(null);
                    }}
                />
            )}
        </Details>
    );
};

export default PaymentSetDetails;
