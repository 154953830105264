import { AxiosResponse } from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { get } from "src/Api";
import { ChevronRight } from "src/components/icons/exports";
import SearchBar from "src/components/SearchBar";
import { CustomLoading } from "src/components/SkeletonLoader";
import { Header } from "src/components/text/CommonStyles";
import Subtitle from "src/components/text/Subtitle";
import Title from "src/components/text/Title";
import { getFeedCampaigns } from "src/campaigns/landing/selectors";
import { Campaign } from "src/campaigns/types";
import { GlobalState } from "src/reducers";
import Platforms from "src/social-accounts/Platforms";
import { incrementCampaignMaxItems } from "src/ui/campaigns/actions";
import styled from "styled-components";
import { fonts } from "src/constants";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 900px;
    padding: 0 18px 0 18px;
`;

const OptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 10px;
    & > * ~ * {
        margin-top: 10px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
`;

const Image = styled.img`
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    width: 46px;
    height: 46px;
    border-radius: 10px;
`;

const OptionTitle = styled.div`
    font-weight: 700;
    text-align: left;
    font-size: ${fonts.subtitle}px;
`;

const Chevron = styled.div`
    margin-left: auto;
`;

export interface LinkSelectorOption {
    photoUrl: string;
    title: string;
    url?: string | null;
    type: string;
    id?: number;
}
interface Props {
    onSelect: (value: LinkSelectorOption) => void;
    type: string;
}

const LinkCampaignPlatformSelector = ({ onSelect, type }: Props) => {
    const [campaignOptions, setCampaignOptions] = useState<
        LinkSelectorOption[] | undefined
    >(undefined);
    const [platformOptions, setPlatformOptions] = useState<
        LinkSelectorOption[]
    >([]);
    const [search, setSearch] = useState("");
    const campaigns = useSelector((state: GlobalState) =>
        getFeedCampaigns(state, search)
    );

    const dispatch = useDispatch();

    const userid: string = localStorage.getItem("userid") || "";

    const { ref, inView } = useInView();

    const campaignMaxItems = useSelector((state: GlobalState) => {
        return state.ui.campaign.maxItems;
    });

    useEffect(() => {
        if (inView) {
            dispatch(incrementCampaignMaxItems(campaignMaxItems + 2));
        }
    }, [inView]);

    useEffect(() => {
        let tempOptions: LinkSelectorOption[] = [];
        let platformNames = Object.keys(Platforms);
        platformNames.map((name, index) => {
            const platform = Platforms[name];
            if (platform.title === "Other")
                tempOptions.push({
                    title: platform.title,
                    photoUrl: "https://app.plugco.in/" + platform.imgSrc,
                    type: "other"
                });
            else
                tempOptions.push({
                    title: platform.title,
                    photoUrl: "https://app.plugco.in/" + platform.imgSrc,
                    url: platform.urlCallback("%username%"),
                    type: "social",
                    id: index
                });
        });
        setPlatformOptions(tempOptions);
    }, []);

    useEffect(() => {
        let tempOptions: LinkSelectorOption[] = [];

        //fetch link
        if (campaignOptions !== undefined || campaigns.length > 0) {
            campaigns.map((campaign: Campaign) =>
                tempOptions.push({
                    photoUrl: campaign.imageUrl,
                    title: campaign.campaignName,
                    url: "",
                    type: "campaign",
                    id: campaign.id
                })
            );
            setCampaignOptions(tempOptions);
        }
    }, [campaigns]);

    const getOptions = () => {
        return type === "campaign" ? campaignOptions || [] : platformOptions;
    };

    const selectOption = (option: any) => {
        //hook for reuse
        if (option.type === "campaign")
            get(
                `/api/get_link_for_plug?plugid=${option.id}&addMedia=true`,
                {}
            ).then((res: AxiosResponse) =>
                onSelect({
                    ...option,
                    title: `Download ${option.title}!`,
                    url: res.data.domain + res.data.campaign_url
                })
            );
        else
            onSelect({
                ...option,
                title: `Follow me on ${option.title}!`
            });
    };

    const header =
        type === "campaign" ? (
            <>
                <Header>
                    <Title>Which Campaign?</Title>
                    <Subtitle>Select an option below</Subtitle>
                </Header>
                <SearchBar
                    searchString={search}
                    updateSearchString={setSearch}
                />
            </>
        ) : (
            <Header>
                <Title>Which Platform?</Title>
                <Subtitle>Select an option below</Subtitle>
            </Header>
        );

    if (type === "campaign" && !campaignOptions)
        return (
            <Container>
                {header}
                <OptionContainer>
                    {Array.from({ length: 20 }, (x, i) => (
                        <CustomLoading height="46px" key={`loader${i}`} />
                    ))}
                </OptionContainer>
            </Container>
        );
    return (
        <>
            <Container>
                {header}
                <OptionContainer>
                    {getOptions().map((option, index) => (
                        <Row
                            key={`option: ${index}`}
                            ref={
                                campaigns && index + 4 > campaigns.length
                                    ? ref
                                    : undefined
                            }
                            onClick={() => selectOption(option)}
                        >
                            <Image src={option.photoUrl} />
                            <OptionTitle>{option.title}</OptionTitle>
                            <Chevron>
                                <ChevronRight />
                            </Chevron>
                        </Row>
                    ))}
                </OptionContainer>
            </Container>
        </>
    );
};

export default LinkCampaignPlatformSelector;
