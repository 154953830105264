import React, { useEffect } from "react";
import styled from "styled-components";
import { colors, overlayBackgrounds } from "../../constants";
import { ReactComponent as Title } from "../../components/icons/title-heavy.svg";
import FullscreenLayout from "../../components/layout/FullscreenLayout";
import LoginPortalModal from "../components/LoginPortalModal";
import { useDispatch, useSelector } from "react-redux";
import { accountCompleted, loggedIn } from "src/util";
import { push } from "connected-react-router";
import { IntercomProvider } from "react-use-intercom";
import { getLoggedInProfile, getPreApprovalCode } from "src/profile/selectors";

const Header = styled.div`
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Logo = styled.img`
    height: 32px;
    width: 32px;
`;

const TitleText = styled(Title)`
    margin-top: 12px;
`;

const Tagline = styled.div`
    margin-top: 4px;
    font-size: 9.8px;
    font-weight: 400;
    line-height: 12px;
    color: ${colors.primaryGray};
`;

const Info = styled.div`
    border: 1px solid transparent;
    color: ${colors.white};
    padding: 8px 10px;
    border-radius: 4px;
    align-self: center;
    margin-bottom: 7px;
    font-size: 12px;
    font-weight: 500;
`;

const Container = styled(FullscreenLayout)`
    position: relative;
`;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.lighterTeal};
    padding: 50px 32px;
    height: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`;

const Card = styled.div`
    margin-top: 27px;
    background-color: ${colors.white};
    border-radius: 16px;
    padding: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;

    & > * ~ * {
        margin-top: 24px;
    }
`;

const Item = styled.div`
    display: flex;
    align-items: center;
`;

const CheckIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 12px;
`;

const InviteCode = () => {
    const dispatch = useDispatch();

    const profile = useSelector(getLoggedInProfile);
    const preApprovalCode = useSelector(getPreApprovalCode);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (
            loggedIn() &&
            !profile?.account_verified &&
            !preApprovalCode?.bypassConnectedSocialAccountRequirement
        )
            dispatch(push("/onboarding/verify"));
        else if (profile && !accountCompleted(profile, preApprovalCode))
            dispatch(push("/onboarding/details"));
        else if (loggedIn()) dispatch(push("/"));
    }, [profile]);

    return (
        <Container>
            <Main>
                <Header>
                    <Logo src={"/logo.svg"} />
                    <TitleText fill={colors.black} />
                    <Tagline>Monetize your Following</Tagline>
                </Header>
                <Card>
                    <Item>
                        Join the Plug to start making money with your social
                        media accounts!
                    </Item>
                    <Item>
                        <CheckIcon src="/pesonal_offers_check.svg" />
                        Direct Deals with Top Advertisers
                    </Item>
                    <Item>
                        <CheckIcon src="/pesonal_offers_check.svg" />
                        Hundreds of Paid Campaigns
                    </Item>
                    <Item>
                        <CheckIcon src="/pesonal_offers_check.svg" />
                        Cash Out Anytime
                    </Item>
                    <Item>
                        <CheckIcon src="/pesonal_offers_check.svg" />
                        Promote on your Own Time
                    </Item>
                    <Item>
                        <CheckIcon src="/pesonal_offers_check.svg" />
                        Anytime Support and Help
                    </Item>
                </Card>
            </Main>
            <IntercomProvider appId="qporgstt">
                <LoginPortalModal />
            </IntercomProvider>
        </Container>
    );
};

export default InviteCode;
