import { useEffect } from "react";
import { useHistory } from "react-router";
import useLoginAsUser from "src/hooks/useLoginAsUser";

const LoginAsSelf = () => {
    const history = useHistory();
    const loginAs = useLoginAsUser();

    useEffect(() => {
        if (localStorage.getItem("employeeToken")) {
            loginAs(true);
        } else history.push("/");
    }, []);
    return null;
};

export default LoginAsSelf;
