import React from "react";
import styled, { css } from "styled-components";
import { OldTransaction } from "../types";
import { cashoutMethods } from "./cashoutMethods";
import { formatNumber } from "../../util";
import { colors, maxWidthDesktop, fonts } from "../../constants";
import { flex } from "src/utils/styles/snippets";

const Main = styled("section")`
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;

    @media (max-width: ${maxWidthDesktop}px) {
        border: ${colors.transparent} solid 1px;
    }
`;

const VerticalCenter = styled("div")`
    ${flex("row", "auto", "center")};
`;

const Header = styled("div")`
    ${flex("row", "space-between", "center")};
    justify-content: space-between;
    padding: 15px 15px 0px;
`;

const detailStyles = css`
    ${flex("row", "space-between", "auto")};
    padding: 15px;
    font-size: ${fonts.subtitle}px;
`;

const StatusDetail = styled("div")`
    ${detailStyles};
    border-bottom: ${colors.mediumTeal} solid 1px;
`;

const Detail = styled(VerticalCenter)`
    padding: 15px;
    border-bottom: 1px solid ${colors.mediumTeal};
    font-size: ${fonts.subtitle}px;
`;

const DateDetail = styled("div")`
    ${detailStyles};

    @media (max-width: ${maxWidthDesktop}px) {
        border-bottom: ${colors.mediumTeal} solid 1px;
    }
`;

const LargeIcon = styled("img")`
    width: 40px;
    height: 40px;
    margin-right: 10px;
`;

export const TransactionAmount = styled("span")`
    display: block;
    font-size: 40px;
`;

const CashTotal = styled("div")`
    color: ${colors.secondaryGreen};
    font-size: 20px;
`;

const IconContainer = styled("div")`
    ${flex("row", "center", "auto")};
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const SmallIcon = styled("img")`
    max-width: 20px;
    max-height: 20px;
`;

const GrayText = styled("div")`
    color: ${colors.secondaryGray};
`;

const TransactionCell = ({ transaction }: { transaction: OldTransaction }) => {
    function renderStatus() {
        let statusIcon;
        let description;

        if (transaction.transaction_status === "pending") {
            statusIcon = "/progress_yellow/progress_yellow@3x.png";
            description = "Processing payment...";
        } else if (transaction.transaction_status === "completed") {
            statusIcon = "/progress_green/progress_green@3x.png";
            description = "Completed";
        } else if (transaction.transaction_status === "cancelled") {
            statusIcon = "/progress_red/progress_red@3x.png";
            description = "You cancelled this payment";
        } else if (transaction.transaction_status === "rejected") {
            statusIcon = "/progress_red/progress_red@3x.png";
            description = "This payment has been rejected";
        } else {
            statusIcon = "/progress_gray/progress_gray@3x.png";
            description = "";
        }

        return (
            <StatusDetail>
                <IconContainer>
                    <SmallIcon src={statusIcon} />
                </IconContainer>
                {description}
            </StatusDetail>
        );
    }

    function renderType() {
        const cashoutMethod =
            cashoutMethods.find(method => {
                if (transaction.transaction_method === "tipalti")
                    return method.tag === transaction.paypal_email;
                else
                    return (
                        method.tag?.toLowerCase() ===
                        transaction.transaction_method?.toLowerCase()
                    );
            }) || cashoutMethods[0];

        return (
            <Detail>
                <IconContainer>
                    <SmallIcon src={cashoutMethod.icon} />
                </IconContainer>
                {transaction.paypal_email}
            </Detail>
        );
    }

    function renderDate() {
        const d = new Date(transaction.date_pst.split("-").join("/"));
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const year = d
            .getFullYear()
            .toString()
            .slice(2, 4);
        return (
            <DateDetail>
                <IconContainer>
                    <SmallIcon src={"/date/date@2x.png"} />
                </IconContainer>
                <GrayText>{`Cash out on ${month}/${day}/${year}`}</GrayText>
            </DateDetail>
        );
    }

    return (
        <Main>
            <Header>
                <VerticalCenter>
                    <LargeIcon src={"/logo.svg"} />
                    <TransactionAmount>
                        {formatNumber(Math.abs(transaction.transaction_amount))}
                    </TransactionAmount>
                </VerticalCenter>
                <CashTotal>
                    {`$${formatNumber(
                        Math.abs(transaction.transaction_amount * 0.05).toFixed(
                            2
                        )
                    )}`}
                </CashTotal>
            </Header>
            {renderStatus()}
            {renderType()}
            {renderDate()}
        </Main>
    );
};

export default TransactionCell;
