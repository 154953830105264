import { Action } from "../types";
import {
    CashoutFee,
    Connection,
    EarningsSummary,
    FullscreenAlert,
    InviteCode,
    Profile,
    ProfileStats,
    PublicProfile,
    SocialAccount,
    OldTransaction,
    WeeklyLeaderboardItem,
    BonusBracket,
    LinkListItem,
    LinkList,
    PreApprovalCode
} from "./types";
import { Media } from "../media/types";
import { Campaign } from "src/campaigns/types";
import { PublicProfileError } from "./reducer";

// Personal Profile Data
// -------------------------------------------------------------------

export const FETCH_USER = {
    REQUEST: "FETCH_USER_REQUEST",
    PROFILE_REQUEST: "FETCH_USER_PROFILE_REQUEST",
    SUCCESS: "FETCH_USER_SUCCESS",
    FAILURE: "FETCH_USER_FAILURE"
};

export const fetchUser = (retryCount = 0): Action => ({
    type: FETCH_USER.REQUEST,
    payload: { retryCount }
});

export const fetchUserProfile = (): Action => ({
    type: FETCH_USER.PROFILE_REQUEST,
    payload: null
});

export const fetchUserSuccess = (payload: Profile): Action => ({
    type: FETCH_USER.SUCCESS,
    payload
});

export const fetchUserFailure = (): Action => ({
    type: FETCH_USER.FAILURE,
    payload: null
});

export const FETCH_PROFILE_USER_SUCCESS = "FETCH_PROFILE_USER_SUCCESS";

export const fetchProfileUserSuccess = (payload: {
    publicProfile: PublicProfile;
}): Action => ({
    type: FETCH_PROFILE_USER_SUCCESS,
    payload
});

export const FETCH_EARNINGS_USER_SUCCESS = "FETCH_EARNINGS_USER_SUCCESS";

export const fetchEarningsUserSuccess = (payload: {
    earningsSummary: EarningsSummary;
}): Action => ({
    type: FETCH_EARNINGS_USER_SUCCESS,
    payload
});

export const FETCH_CONNECTIONS_USER = {
    REQUEST: "FETCH_CONNECTIONS_USER_REQUEST",
    SUCCESS: "FETCH_CONNECTIONS_USER_SUCCESS",
    FAILURE: "FETCH_CONNECTIONS_USER_FAILURE"
};

export const fetchConnectionsUser = (publisherId: number): Action => ({
    type: FETCH_CONNECTIONS_USER.REQUEST,
    payload: publisherId
});

export const fetchConnectionsUserSuccess = (payload: {
    connections: Connection[];
    recommendations: Connection[];
}): Action => ({
    type: FETCH_CONNECTIONS_USER.SUCCESS,
    payload
});

export const fetchConnectionsUserFailure = (): Action => ({
    type: FETCH_CONNECTIONS_USER.FAILURE,
    payload: { connections: null }
});

export const FETCH_ACCOUNTS_USER = {
    REQUEST: "FETCH_ACCOUNTS_USER_REQUEST",
    SUCCESS: "FETCH_ACCOUNTS_USER_SUCCESS",
    FAILURE: "FETCH_ACCOUNTS_USER_FAILURE"
};

export const fetchAccountsUser = (publisherId?: number): Action => ({
    type: FETCH_ACCOUNTS_USER.REQUEST,
    payload: publisherId
});

export const fetchAccountsUserSuccess = (payload: {
    accounts: SocialAccount[];
}): Action => ({
    type: FETCH_ACCOUNTS_USER.SUCCESS,
    payload
});

export const fetchAccountsUserFailure = (): Action => ({
    type: FETCH_ACCOUNTS_USER.FAILURE,
    payload: { accounts: null }
});

export const FETCH_PROMOTED_USER = {
    REQUEST: "FETCH_PROMOTED_USER_REQUEST",
    SUCCESS: "FETCH_PROMOTED_USER_SUCCESS",
    FAILURE: "FETCH_PROMOTED_USER_FAILURE"
};

export const fetchPromotedUser = (publisherId: number): Action => ({
    type: FETCH_PROMOTED_USER.REQUEST,
    payload: publisherId
});

export const fetchPromotedUserSuccess = (payload: {
    promotedCampaigns: Campaign[];
}): Action => ({
    type: FETCH_PROMOTED_USER.SUCCESS,
    payload
});

export const fetchPromotedUserFailure = (): Action => ({
    type: FETCH_PROMOTED_USER.FAILURE,
    payload: { promotedCampaigns: null }
});

export const FETCH_MEDIA_USER = {
    REQUEST: "FETCH_MEDIA_USER_REQUEST",
    SUCCESS: "FETCH_MEDIA_USER_SUCCESS",
    FAILURE: "FETCH_MEDIA_USER_FAILURE"
};

export const fetchMediaUser = (publisherId: number): Action => ({
    type: FETCH_MEDIA_USER.REQUEST,
    payload: publisherId
});

export const fetchMediaUserSuccess = (payload: {
    customMedia: Media[];
}): Action => ({
    type: FETCH_MEDIA_USER.SUCCESS,
    payload
});

export const fetchMediaUserFailure = (): Action => ({
    type: FETCH_MEDIA_USER.FAILURE,
    payload: { customMedia: null }
});

export const FETCH_CHAT_TOKEN_SUCCESS = "FETCH_CHAT_TOKEN_SUCCESS";

export const fetchChatTokenSuccess = (payload: {
    chatToken: string;
}): Action => ({
    type: FETCH_CHAT_TOKEN_SUCCESS,
    payload
});

export const FETCH_SEARCH_TOKEN_SUCCESS = "FETCH_SEARCH_TOKEN_SUCCESS";

export const fetchSearchTokenSuccess = (payload: {
    searchToken: string;
}): Action => ({
    type: FETCH_SEARCH_TOKEN_SUCCESS,
    payload
});

// Other Profile Data
// -------------------------------------------------------------------

export const FETCH_PUBLIC = {
    REQUEST: "FETCH_PUBLIC_REQUEST",
    SUCCESS: "FETCH_PUBLIC_SUCCESS",
    FAILURE: "FETCH_PUBLIC_FAILURE"
};

export interface FetchPublicParams {
    selfId: number;
    otherId: string;
}

export const fetchPublic = (payload: FetchPublicParams): Action => ({
    type: FETCH_PUBLIC.REQUEST,
    payload
});

export interface FetchPublicSuccessParams {
    otherId: string;
    connection: Connection;
    stats: ProfileStats;
}

export const fetchPublicSuccess = (
    payload: FetchPublicSuccessParams
): Action => ({
    type: FETCH_PUBLIC.SUCCESS,
    payload
});

export const fetchPublicFailure = (payload: {
    [otherId: string]: PublicProfileError;
}): Action => ({
    type: FETCH_PUBLIC.FAILURE,
    payload
});

export const FETCH_CONNECTIONS_PUBLIC = {
    REQUEST: "FETCH_CONNECTIONS_PUBLIC_REQUEST",
    SUCCESS: "FETCH_CONNECTIONS_PUBLIC_SUCCESS",
    FAILURE: "FETCH_CONNECTIONS_PUBLIC_FAILURE"
};

export const fetchConnectionsPublic = (connection: Connection): Action => {
    const otherId = connection.other.id.slice(2);

    return {
        type: FETCH_CONNECTIONS_PUBLIC.REQUEST,
        payload: {
            otherId,
            connection
        }
    };
};

export const fetchConnectionsPublicSuccess = (payload: {
    otherId: string;
    allConnections: Connection[];
    mutualConnections: Connection[];
}): Action => ({
    type: FETCH_CONNECTIONS_PUBLIC.SUCCESS,
    payload
});

export const fetchConnectionsPublicFailure = (payload: {
    otherId: string;
}): Action => ({
    type: FETCH_CONNECTIONS_PUBLIC.FAILURE,
    payload
});

export const FETCH_ACCOUNTS_PUBLIC = {
    REQUEST: "FETCH_ACCOUNTS_PUBLIC_REQUEST",
    SUCCESS: "FETCH_ACCOUNTS_PUBLIC_SUCCESS",
    FAILURE: "FETCH_ACCOUNTS_PUBLIC_FAILURE"
};

export const fetchAccountsPublic = (connection: Connection): Action => {
    const otherId = connection.other.id.slice(2);

    return {
        type: FETCH_ACCOUNTS_PUBLIC.REQUEST,
        payload: { otherId }
    };
};

export const fetchAccountsPublicSuccess = (payload: {
    otherId: string;
    accounts: SocialAccount[];
}): Action => ({
    type: FETCH_ACCOUNTS_PUBLIC.SUCCESS,
    payload
});

export const fetchAccountsPublicFailure = (payload: {
    otherId: string;
}): Action => ({
    type: FETCH_ACCOUNTS_PUBLIC.FAILURE,
    payload
});

export const FETCH_PROMOTED_PUBLIC = {
    REQUEST: "FETCH_PROMOTED_PUBLIC_REQUEST",
    SUCCESS: "FETCH_PROMOTED_PUBLIC_SUCCESS",
    FAILURE: "FETCH_PROMOTED_PUBLIC_FAILURE"
};

export const fetchPromotedPublic = (connection: Connection): Action => {
    const otherId = connection.other.id.slice(2);

    return {
        type: FETCH_PROMOTED_PUBLIC.REQUEST,
        payload: {
            otherId,
            connection
        }
    };
};

export const fetchPromotedPublicSuccess = (payload: {
    otherId: string;
    promotedCampaigns: Campaign[];
}): Action => ({
    type: FETCH_PROMOTED_PUBLIC.SUCCESS,
    payload
});

export const fetchPromotedPublicFailure = (payload: {
    otherId: string;
}): Action => ({
    type: FETCH_PROMOTED_PUBLIC.FAILURE,
    payload
});

export const FETCH_MEDIA_PUBLIC = {
    REQUEST: "FETCH_MEDIA_PUBLIC_REQUEST",
    SUCCESS: "FETCH_MEDIA_PUBLIC_SUCCESS",
    FAILURE: "FETCH_MEDIA_PUBLIC_FAILURE"
};

export const fetchMediaPublic = (connection: Connection): Action => {
    const otherId = connection.other.id.slice(2);

    return {
        type: FETCH_MEDIA_PUBLIC.REQUEST,
        payload: { otherId }
    };
};

export const fetchMediaPublicSuccess = (payload: {
    otherId: string;
    customMedia: Media[];
}): Action => ({
    type: FETCH_MEDIA_PUBLIC.SUCCESS,
    payload
});

export const fetchMediaPublicFailure = (payload: {
    otherId: string;
}): Action => ({
    type: FETCH_MEDIA_PUBLIC.FAILURE,
    payload
});

// Profile Posts and Deletes and Misc
// -------------------------------------------------------------------

// Connections

export const POST_CONNECTION = {
    REQUEST: "POST_CONNECTION_REQUEST",
    SUCCESS: "POST_CONNECTION_SUCCESS"
};

export interface PostConnectionParams {
    selfId: string;
    otherId: string;
    relationship: "friend" | "block" | "none";
}

export const postConnection = (payload: PostConnectionParams): Action => ({
    type: POST_CONNECTION.REQUEST,
    payload
});

export const postConnectionSuccess = (payload: Connection): Action => ({
    type: POST_CONNECTION.SUCCESS,
    payload
});

export const DELETE_CONNECTION = {
    REQUEST: "DELETE_CONNECTION_REQUEST",
    SUCCESS: "DELETE_CONNECTION_SUCCESS"
};

export interface DeleteConnectionParams {
    selfId: string;
    otherId: string;
}

export const deleteConnection = (payload: DeleteConnectionParams): Action => ({
    type: DELETE_CONNECTION.REQUEST,
    payload
});

export const deleteConnectionSuccess = (
    payload: DeleteConnectionParams
): Action => ({
    type: DELETE_CONNECTION.SUCCESS,
    payload
});

// Recommendations

export const POST_RECOMMENDATION = "POST_RECOMMENDATION";

export interface PostRecommendationParams {
    selfId: string;
    otherId: string;
    event: "view" | "click" | "block";
}

export const postRecommendation = (
    payload: PostRecommendationParams
): Action => ({
    type: POST_RECOMMENDATION,
    payload
});

export const DELETE_RECOMMENDATION = "DELETE_RECOMMENDATION";

export const deleteRecommendation = (otherId: string): Action => ({
    type: DELETE_RECOMMENDATION,
    payload: otherId
});

export const COMPLETE_RECOMMENDATION = "COMPLETE_RECOMMENDATION";

export const completeRecommendation = (otherId: string): Action => ({
    type: COMPLETE_RECOMMENDATION,
    payload: otherId
});

// Accounts

export const POST_ACCOUNT = {
    REQUEST: "POST_ACCOUNT_REQUEST",
    BATCH: "POST_ACCOUNT_BATCH",
    REPLACE: "POST_ACCOUNT_REPLACE",
    ADD: "POST_ACCOUNT_ADD"
};

export interface PostAccountParams {
    id?: number;
    username: string;
    platform: string;
    followers: string;
    theme: string | null;
    visibility: "visible" | "connections" | "hidden";
}

export const postAccount = (payload: PostAccountParams): Action => ({
    type: POST_ACCOUNT.REQUEST,
    payload
});

export const postAccountBatch = (payload: SocialAccount[]): Action => ({
    type: POST_ACCOUNT.BATCH,
    payload
});

export const postAccountReplace = (payload: SocialAccount): Action => ({
    type: POST_ACCOUNT.REPLACE,
    payload
});

export const postAccountAdd = (payload: SocialAccount): Action => ({
    type: POST_ACCOUNT.ADD,
    payload
});

export const FETCH_VERIFY_CODE = {
    REQUEST: "FETCH_VERIFY_CODE_REQUEST",
    SUCCESS: "FETCH_VERIFY_CODE_SUCCESS"
};

export const fetchVerifyCode = (accountId: number): Action => ({
    type: FETCH_VERIFY_CODE.REQUEST,
    payload: accountId
});

export const fetchVerifyCodeSuccess = (verifyCode: string): Action => ({
    type: FETCH_VERIFY_CODE.SUCCESS,
    payload: { verifyCode }
});

// -------------------------------------------------------------------

export const FETCH_INSTAGRAM_HANDLE_SUCCESS = "FETCH_INSTAGRAM_HANDLE_SUCCESS";

export const fetchInstagramHandleSuccess = (
    instagramHandle: string
): Action => ({
    type: FETCH_INSTAGRAM_HANDLE_SUCCESS,
    payload: { instagramHandle }
});

// -------------------------------------------------------------------

export const FETCH_WEEKLY_LEADERBOARD = {
    REQUEST: "FETCH_WEEKLY_LEADERBOARD_REQUEST",
    SUCCESS: "FETCH_WEEKLY_LEADERBOARD_SUCCESS",
    FAILURE: "FETCH_WEEKLY_LEADERBOARD_FAILURE"
};

export const fetchWeeklyLeaderboard = (): Action => ({
    type: FETCH_WEEKLY_LEADERBOARD.REQUEST,
    payload: { weeklyLeaderboard: undefined, bonusBrackets: undefined }
});

export const fetchWeeklyLeaderboardSuccess = (
    weeklyLeaderboard: WeeklyLeaderboardItem[],
    bonusBrackets: BonusBracket[]
): Action => ({
    type: FETCH_WEEKLY_LEADERBOARD.SUCCESS,
    payload: { weeklyLeaderboard, bonusBrackets }
});

export const fetchWeeklyLeaderboardFailure = (): Action => ({
    type: FETCH_WEEKLY_LEADERBOARD.FAILURE,
    payload: { weeklyLeaderboard: null, bonusBrackets: null }
});

// -------------------------------------------------------------------

export const FETCH_INVITE_CODES = {
    REQUEST: "FETCH_INVITE_CODES_REQUEST",
    SUCCESS: "FETCH_INVITE_CODES_SUCCESS",
    FAILURE: "FETCH_INVITE_CODES_FAILURE"
};

export const fetchInviteCodes = (): Action => ({
    type: FETCH_INVITE_CODES.REQUEST,
    payload: { inviteCodes: undefined }
});

export const fetchInviteCodesSuccess = (inviteCodes: InviteCode[]): Action => ({
    type: FETCH_INVITE_CODES.SUCCESS,
    payload: { inviteCodes }
});

export const fetchInviteCodesFailure = (): Action => ({
    type: FETCH_INVITE_CODES.FAILURE,
    payload: { inviteCodes: null }
});

// -------------------------------------------------------------------

export const FETCH_MORE_CODES = "FETCH_MORE_CODES";

export const fetchMoreCodes = (): Action => ({
    type: FETCH_MORE_CODES,
    payload: null
});

// -------------------------------------------------------------------

export const UPDATE_USERNAME = {
    REQUEST: "UPDATE_USERNAME_REQUEST",
    SUCCESS: "UPDATE_USERNAME_SUCCESS",
    FAILURE: "UPDATE_USERNAME_FAILURE"
};

export const updateUsername = (
    username: string,
    publisherId: number
): Action => ({
    type: UPDATE_USERNAME.REQUEST,
    payload: { username, publisherId }
});

export const updateUsernameSuccess = (username: string): Action => ({
    type: UPDATE_USERNAME.SUCCESS,
    payload: { username }
});

export const updateUsernameFailure = (error: string): Action => ({
    type: UPDATE_USERNAME.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const UPDATE_PHOTO = {
    REQUEST: "UPDATE_PHOTO_REQUEST",
    SUCCESS: "UPDATE_PHOTO_SUCCESS",
    FAILURE: "UPDATE_PHOTO_FAILURE"
};

export const updatePhoto = (filename: string): Action => ({
    type: UPDATE_PHOTO.REQUEST,
    payload: { filename }
});

export const updatePhotoSuccess = (profile_photo_url: string): Action => ({
    type: UPDATE_PHOTO.SUCCESS,
    payload: profile_photo_url
});

export const updatePhotoFailure = (error: string): Action => ({
    type: UPDATE_PHOTO.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const UPDATE_USER_FIELDS = {
    REQUEST: "UPDATE_USER_FIELDS_REQUEST",
    SUCCESS: "UPDATE_USER_FIELDS_SUCCESS",
    FAILURE: "UPDATE_USER_FIELDS_FAILURE"
};

export const updateUserFields = (fields: { [key: string]: any }): Action => ({
    type: UPDATE_USER_FIELDS.REQUEST,
    payload: fields
});

export const updateUserFieldsSuccess = (profile: Profile): Action => ({
    type: UPDATE_USER_FIELDS.SUCCESS,
    payload: profile
});

export const updateUserFieldsFailure = (error: string): Action => ({
    type: UPDATE_USER_FIELDS.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const UPDATE_USER_PAYPAL_CASHOUT_EMAIL = {
    REQUEST: "UPDATE_USER_PAYPAL_CASHOUT_EMAIL_REQUEST",
    SUCCESS: "UPDATE_USER_PAYPAL_CASHOUT_EMAIL_SUCCESS",
    FAILURE: "UPDATE_USER_PAYPAL_CASHOUT_EMAIL_FALIURE"
};

export const updateUserPaypalCashoutEmail = (
    paypalCashoutEmail: string
): Action => ({
    type: UPDATE_USER_PAYPAL_CASHOUT_EMAIL.REQUEST,
    payload: { paypalCashoutEmail }
});

export const updateUserPaypalCashoutEmailSuccess = (
    profile: Profile
): Action => ({
    type: UPDATE_USER_PAYPAL_CASHOUT_EMAIL.SUCCESS,
    payload: profile
});

export const updateUserPaypalCashoutEmailFailure = (error: string): Action => ({
    type: UPDATE_USER_PAYPAL_CASHOUT_EMAIL.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const UPDATE_OR_CREATE_TIPALTI_USER = {
    REQUEST: "UPDATE_OR_CREATE_TIPALTI_REQUEST",
    SUCCESS: "UPDATE_OR_CREATE_TIPALTI_SUCCESS",
    FAILURE: "UPDATE_OR_CREATE_TIPALTI_FAILURE"
};

export const updateOrCreateTipaltiUser = (email: string): Action => ({
    type: UPDATE_OR_CREATE_TIPALTI_USER.REQUEST,
    payload: { email }
});

export const updateOrCreateTipaltiUserSuccess = (): Action => ({
    type: UPDATE_OR_CREATE_TIPALTI_USER.SUCCESS,
    payload: null
});

export const updateOrCreateTipaltiUserFailure = (error: string): Action => ({
    type: UPDATE_OR_CREATE_TIPALTI_USER.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const RESEND_TIPALTI_EMAIL = "RESEND_TIPALTI_EMAIL";

export const resendTipaltiEmail = (): Action => ({
    type: RESEND_TIPALTI_EMAIL,
    payload: null
});

// -------------------------------------------------------------------

export const FETCH_CASHOUTS = {
    REQUEST: "FETCH_CASHOUTS_REQUEST",
    SUCCESS: "FETCH_CASHOUTS_SUCCESS",
    FAILURE: "FETCH_CASHOUTS_FAILURE"
};

export const fetchCashouts = (): Action => ({
    type: FETCH_CASHOUTS.REQUEST,
    payload: null
});

export const fetchCashoutsSuccess = (
    transactions: OldTransaction[]
): Action => ({
    type: FETCH_CASHOUTS.SUCCESS,
    payload: { transactions }
});

export const fetchCashoutsFailure = (error: string): Action => ({
    type: FETCH_CASHOUTS.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const GET_FEES_FOR_CASHOUT = {
    REQUEST: "GET_FEES_FOR_CASHOUT_REQUEST",
    SUCCESS: "GET_FEES_FOR_CASHOUT_SUCCESS",
    FAILURE: "GET_FEES_FOR_CASHOUT_FAILURE"
};

export const getFeesForCashout = (
    feeType: string,
    cashoutAmount: number
): Action => ({
    type: GET_FEES_FOR_CASHOUT.REQUEST,
    payload: { feeType, cashoutAmount }
});

export const getFeesForCashoutSuccess = (cashoutFees: {
    fees: CashoutFee;
    fee_schedule: CashoutFee[];
}): Action => ({
    type: GET_FEES_FOR_CASHOUT.SUCCESS,
    payload: { cashoutFees }
});

export const getFeesForCashoutFailure = (error: string): Action => ({
    type: GET_FEES_FOR_CASHOUT.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const REQUEST_TIPALTI_CASHOUT = {
    REQUEST: "REQUEST_TIPALTI_CASHOUT_REQUEST",
    SUCCESS: "REQUEST_TIPALTI_CASHOUT_SUCCESS",
    FAILURE: "REQUEST_TIPALTI_CASHOUT_FAILURE"
};

export const requestTipaltiCashout = (cashout_amount: number): Action => ({
    type: REQUEST_TIPALTI_CASHOUT.REQUEST,
    payload: { cashout_amount }
});

export const requestTipaltiCashoutSuccess = (): Action => ({
    type: REQUEST_TIPALTI_CASHOUT.SUCCESS,
    payload: null
});

export const requestTipaltiCashoutFailure = (error: string): Action => ({
    type: REQUEST_TIPALTI_CASHOUT.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const REQUEST_PAYPAL_CASHOUT = {
    REQUEST: "REQUEST_PAYPAL_CASHOUT_REQUEST",
    SUCCESS: "REQUEST_PAYPAL_CASHOUT_SUCCESS",
    FAILURE: "REQUEST_PAYPAL_CASHOUT_FAILURE"
};

export const requestPaypalCashout = (cashout_amount: number): Action => ({
    type: REQUEST_PAYPAL_CASHOUT.REQUEST,
    payload: { cashout_amount }
});

export const requestPaypalCashoutSuccess = (): Action => ({
    type: REQUEST_PAYPAL_CASHOUT.SUCCESS,
    payload: null
});

export const requestPaypalCashoutFailure = (error: string): Action => ({
    type: REQUEST_PAYPAL_CASHOUT.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const LISTENER_UPDATE_BALANCE = "LISTENER_UPDATE_BALANCE";

export const listenerUpdateBalance = (balance: number): Action => ({
    type: LISTENER_UPDATE_BALANCE,
    payload: { balance }
});

// -------------------------------------------------------------------

export const AGREED_TO_RULES = "AGREED_TO_RULES";

export const agreedToRules = (): Action => ({
    type: AGREED_TO_RULES,
    payload: null
});

// -------------------------------------------------------------------

export const GET_FULLSCREEN_ALERTS = {
    REQUEST: "GET_FULLSCREEN_ALERTS_REQUEST",
    SUCCESS: "GET_FULLSCREEN_ALERTS_SUCCESS",
    FAILURE: "GET_FULLSCREEN_ALERTS_FAILURE"
};

export const getFullscreenAlerts = (): Action => ({
    type: GET_FULLSCREEN_ALERTS.REQUEST,
    payload: null
});

export const getFullscreenAlertsSuccess = (
    fullscreenAlerts: FullscreenAlert[]
): Action => ({
    type: GET_FULLSCREEN_ALERTS.SUCCESS,
    payload: { fullscreenAlerts }
});

// -------------------------------------------------------------------

export const RESOLVE_FULLSCREEN_ALERT = {
    REQUEST: "RESOLVE_FULLSCREEN_ALERT_REQUEST",
    SUCCESS: "RESOLVE_FULLSCREEN_ALERT_SUCCESS",
    FAILURE: "RESOLVE_FULLSCREEN_ALERT_FAILURE"
};

export const resolveFullscreenAlert = (alert_id: number): Action => ({
    type: RESOLVE_FULLSCREEN_ALERT.REQUEST,
    payload: { alert_id }
});

export const resolveFullscreenAlertSuccess = (alertId: number): Action => ({
    type: RESOLVE_FULLSCREEN_ALERT.SUCCESS,
    payload: alertId
});

// -------------------------------------------------------------------

export const SAVE_SETTINGS = "SAVE_SETTINGS";

export interface SaveSettingsParams {
    publisherId: number;
    bio: string;
    privateAccount: boolean;
}

export const saveSettings = (payload: SaveSettingsParams): Action => ({
    type: SAVE_SETTINGS,
    payload
});

export const BIO_CHANGE_SUCCESS = "BIO_CHANGE_SUCCESS";

export const bioChangeSuccess = (bio: string): Action => ({
    type: BIO_CHANGE_SUCCESS,
    payload: { bio }
});

export const PRIVATE_CHANGE_SUCCESS = "PRIVATE_CHANGE_SUCCESS";

export const privateChangeSuccess = (payload: boolean): Action => ({
    type: PRIVATE_CHANGE_SUCCESS,
    payload
});

// -------------------------------------------------------------------

export const PUSH_WITH_ACCOUNT = {
    PATH: "PUSH_WITH_ACCOUNT_PATH",
    DATA: "PUSH_WITH_ACCOUNT_DATA"
};

export interface PushAccountParams {
    path: string;
    account: SocialAccount;
}

export const pushWithAccount = (payload: PushAccountParams): Action => ({
    type: PUSH_WITH_ACCOUNT.PATH,
    payload
});

export const pushWithAccountData = (currentAccount: SocialAccount): Action => ({
    type: PUSH_WITH_ACCOUNT.DATA,
    payload: { currentAccount }
});

// -------------------------------------------------------------------

export const COMPLETE_ACCOUNT_LEGACY = "COMPLETE_ACCOUNT_LEGACY";

export const completeAccountLegacy = (): Action => ({
    type: COMPLETE_ACCOUNT_LEGACY,
    payload: null
});

// -------------------------------------------------------------------

export const LOG_OUT = "LOG_OUT";

export const logout = (): Action => ({
    type: LOG_OUT,
    payload: null
});

// Modals
// -------------------------------------------------------------------

export const MODAL = {
    BRACKETS: "MODAL_BRACKETS",
    STATUS: "MODAL_STATUS",
    USERNAME: "MODAL_USERNAME",
    PHOTO: "MODAL_PHOTO",
    CASHOUT: "MODAL_CASHOUT",
    CASHOUT_SETTING: "MODAL_CASHOUT_SETTING",
    FEES: "MODAL_FEES",
    EMAIL: "MODAL_EMAIL",
    VERIFY: "MODAL_VERIFY",
    PRIVACY_ACCOUNT: "MODAL_PRIVACY_ACCOUNT"
};

export const bracketsModal = (): Action => ({
    type: MODAL.BRACKETS,
    payload: null
});

export const statusModal = (): Action => ({
    type: MODAL.STATUS,
    payload: null
});

export const usernameModal = (): Action => ({
    type: MODAL.USERNAME,
    payload: null
});

export const photoModal = (): Action => ({
    type: MODAL.PHOTO,
    payload: null
});

export const cashoutModal = (): Action => ({
    type: MODAL.CASHOUT,
    payload: null
});

export const cashoutSettingModal = (): Action => ({
    type: MODAL.CASHOUT_SETTING,
    payload: null
});

export const feesModal = (): Action => ({
    type: MODAL.FEES,
    payload: null
});

export const emailModal = (): Action => ({
    type: MODAL.EMAIL,
    payload: null
});

export const verifyModal = (): Action => ({
    type: MODAL.VERIFY,
    payload: null
});

export const privacyAccountModal = (): Action => ({
    type: MODAL.PRIVACY_ACCOUNT,
    payload: null
});

// Pre Approval Codes
// -------------------------------------------------------------------
export const FETCH_PRE_APPROVAL_CODE = {
    REQUEST: "FETCH_PRE_APPROVAL_CODE_REQUEST",
    SUCCESS: "FETCH_PRE_APPROVAL_CODE_SUCCESS",
    FAILURE: "FETCH_PRE_APPROVAL_CODE_FAILURE"
};

export const fetchPreApprovalCode = (): Action => ({
    type: FETCH_PRE_APPROVAL_CODE.REQUEST,
    payload: null
});

export const fetchPreApprovalCodeSuccess = (payload: {
    preApprovalCode: PreApprovalCode;
}): Action => ({
    type: FETCH_PRE_APPROVAL_CODE.SUCCESS,
    payload
});

export const fetchPreApprovalCodeFailure = (): Action => ({
    type: FETCH_PRE_APPROVAL_CODE.FAILURE,
    payload: null
});

export const APPLY_PRE_APPROVAL_CODE = {
    REQUEST: "APPLY_PRE_APPROVAL_CODE_REQUEST",
    SUCCESS: "APPLY_PRE_APPROVAL_CODE_SUCCESS",
    FAILURE: "APPLY_PRE_APPROVAL_CODE_FAILURE"
};

export const applyPreApprovalCode = (code: string): Action => ({
    type: APPLY_PRE_APPROVAL_CODE.REQUEST,
    payload: code
});

export const applyPreApprovalCodeSuccess = (): Action => ({
    type: APPLY_PRE_APPROVAL_CODE.SUCCESS,
    payload: null
});

export const applyPreApprovalCodeFailure = (message: string): Action => ({
    type: APPLY_PRE_APPROVAL_CODE.FAILURE,
    payload: message
});
