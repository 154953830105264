import { GlobalState } from "../reducers";
import { AccessStatuses } from "./types";

export const getLeaderboard = (state: GlobalState) =>
    state.profile.weeklyLeaderboard;

export const getEarnings = (state: GlobalState) =>
    state.profile.earningsSummary;

export const getUserId = (state: GlobalState) => state.profile.profile?.id;

export const selectIntercomHash = (state: GlobalState) =>
    state.auth.intercomHash;

export const getLoggedInProfile = (state: GlobalState) => state.profile.profile;

export const getLoggedInSocialAccounts = (state: GlobalState) =>
    state.profile.accounts;

export const getPreApprovalCode = (state: GlobalState) =>
    state.profile.preApprovalCode;

export const getHasUIAccess = (state: GlobalState): boolean => {
    // Check Loaded Profile
    if (!state.profile.profile?.id) return false;
    // Check Access Status
    if (
        ![AccessStatuses.ALL, AccessStatuses.CHALLENGES].includes(
            state.profile.profile.accessStatus
        )
    )
        return false;
    // Check Account Verified
    if (state.profile.profile.account_verified) {
        return true;
    } else if (
        //OR Bypass Verification
        state.profile.preApprovalCode
            ?.bypassConnectedSocialAccountRequirement &&
        state.profile.accounts &&
        state.profile.accounts.length > 0
    ) {
        return true;
    } else {
        return false;
    }
};

export const applyPreApprovalCodeState = (
    state: GlobalState
): { loading: boolean; error: string | null } => ({
    loading: state.profile.applyPreApprovalCodeLoading,
    error: state.profile.applyPreApprovalCodeError
});
