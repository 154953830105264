import { push } from "connected-react-router";
import React from "react";
import { useDispatch } from "react-redux";
import LoadingButton from "src/components/LoadingButton";
import { colors, fonts } from "src/constants";
import { Row } from "src/search/components/StyledComponents";
import { formatNumber } from "src/util";
import styled from "styled-components";
import { Campaign } from "../../types";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 18px;
    text-align: center;
    gap: 16px;
`;

const CampaignImg = styled.img`
    width: 62px;
    height: 62px;
    border-radius: 12px;
    align-self: center;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 20px;
`;

const Subtitle = styled.div`
    font-weight: 400;
    font-size: 16px;
`;

const AmountContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 13px;
    padding: 8px 12px;
    background-color: ${colors.blueGrey};
    border-radius: 8px;
`;

const AmountText = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
`;

const BudgetAmount = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    height: 24px;
    min-width: 96px;
    background: ${colors.black};
    color: ${colors.white};
    margin-left: auto;
    padding: 2.5px 4px;
    font-size: 12px;
`;

const ProgressContainer = styled.div`
    border-radius: 19px;
    height: 8px;
    background: ${colors.primaryGray};
    width: 100%;
`;
const ProgressBar = styled(ProgressContainer)<{ width: string }>`
    display: absolute;
    background: ${colors.primaryBlue};
    width: ${props => props.width};
`;

interface Props {
    campaign: Campaign;
    isPromoted?: boolean;
    closeModal: () => void;
}

const BudgetNotification = ({ campaign, isPromoted, closeModal }: Props) => {
    const dispatch = useDispatch();
    const budget = campaign.budget;
    if (!budget) return null;
    else if (budget.budgetExtension) {
        return (
            <Container>
                <CampaignImg src={campaign.imageUrl} />
                <Title>Campaign Has Increased Its Budget!</Title>
                <Subtitle>
                    {campaign.campaignName} has increased its budget so you can
                    continue promoting it like normal.
                </Subtitle>
                <LoadingButton
                    onClick={closeModal}
                    text="Done"
                    borderColor={colors.blueGrey2}
                    height="47px"
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                />
            </Container>
        );
    } else if (
        campaign.state === "closed" ||
        budget.budgetLimit <= budget.budgetSpend
    )
        return (
            <Container>
                <CampaignImg src={campaign.imageUrl} />
                <Title>Campaign is Closed</Title>
                <Subtitle>
                    Fitness Coach’s budget has been expended and they have
                    decided not to expand it. We suggest you take down your
                    promotion and find a new campaign to promote.
                </Subtitle>
                <LoadingButton
                    onClick={closeModal}
                    text="Done"
                    borderColor={colors.blueGrey2}
                    height="47px"
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                />
            </Container>
        );
    else
        return (
            <Container>
                <CampaignImg src={campaign.imageUrl} />
                {isPromoted ? (
                    <Title>
                        A Campaign You Are Actively Promoting Is Low On Budget
                    </Title>
                ) : (
                    <Title>This Campaign’s Budget is Running Low!</Title>
                )}
                <AmountContainer>
                    <Row style={{ cursor: "default" }}>
                        <AmountText>Budget Remaining</AmountText>
                        <BudgetAmount>
                            <img
                                src="/campaigns/dollar-sign.svg"
                                style={{
                                    marginRight: 4,
                                    height: 12,
                                    width: 12
                                }}
                            />
                            {formatNumber(
                                (
                                    budget.budgetLimit - budget.budgetSpend
                                ).toFixed(0)
                            ) + " Left"}
                        </BudgetAmount>
                    </Row>
                    <ProgressContainer>
                        <ProgressBar
                            color={colors.secondaryGreen}
                            width={
                                (
                                    (budget.budgetSpend / budget.budgetLimit) *
                                    100
                                ).toString() + "%"
                            }
                        />
                    </ProgressContainer>
                </AmountContainer>
                {isPromoted ? (
                    <Subtitle>
                        Fitness Coach’s budget is running low and might not
                        extend their budget. We suggest y’all continue promoting
                        this campaign, and we will notify you if the budget is
                        increased or the campaign is closed.
                    </Subtitle>
                ) : (
                    <Subtitle>
                        We encourage y’all to keep promoting this campaign and
                        we will send a notification when the budget is depleted
                        or is extended. In the meantime, we encourage creators
                        to try promoting multiple campaigns at once using our
                        Link List feature.
                    </Subtitle>
                )}
                <LoadingButton
                    onClick={() => {
                        dispatch(push("/profile/linklists"));
                    }}
                    text="View Link Lists"
                    height="47px"
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                />
                <LoadingButton
                    onClick={closeModal}
                    text="Done"
                    borderColor={colors.blueGrey2}
                    height="47px"
                    backgroundColor={colors.white}
                    textColor={colors.primaryBlue}
                />
            </Container>
        );
};

export default BudgetNotification;
