import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "src/constants";
import { BigBlueButton, OutlineButton } from "src/profile/components/Buttons";
import { get, patch } from "src/Api";
import { checkForSocialAccounts } from "src/util";
import { fetchUser } from "src/profile/actions";
import { useDispatch, useSelector } from "react-redux";
import EmojiModal from "src/modals/EmojiModal";
import CopyTextWrap from "src/components/CopyTextWrap";
import {
    getHasUIAccess,
    getLoggedInProfile,
    getLoggedInSocialAccounts
} from "src/profile/selectors";
import { useHistory } from "react-router";
import { WhitePillButton } from "src/components/buttons/PillButton";
import VerifiedSocialAccountCell from "./VerifiedSocialAccountCell";
import { GlobalState } from "src/reducers";
import { AccessStatuses, SocialAccount } from "src/profile/types";
import FullscreenLoader from "src/components/FullscreenLoader";
import useQuery from "src/hooks/useQuery";
import {
    addNotification,
    setPillNotificationText
} from "src/ui/notifications/actions";
import { getErroredSocialAccount } from "src/social-accounts/utils/util";
import Modal from "src/modals/Modal";
import { getStoredPreApprovalCode } from "src/ui/auth/selectors";
import SupportModal from "./SupportModal";

const Main = styled.div`
    background: ${colors.primaryBlue};
    height: 100vh;
    color: ${colors.white};
    display: flex;
    justify-content: center;
    padding: 24px;
    padding-top: 40px;
`;

const InnerContainer = styled.div`
    max-width: 600px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Body = styled.p`
    font-size: 20px;
    text-align: left;
`;

const Button = styled(OutlineButton)`
    width: 100%;
    background: ${colors.primaryBlue};
    color: white;
    height: 52px;
    border-radius: 26px;
    margin-bottom: 8px;
    :hover {
        opacity: 0.8;
        background: ${colors.primaryBlue};
    }
`;

const Plus = styled.img`
    height: 12px;
    width: 12px;
    margin-right: 6.5px;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;

const BottomText = styled.div`
    font-size: 14px;
    color: white;
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }
`;
const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
const ModalHeader = styled.div`
    font-size: 24px;
    font-weight: 700;
`;

const ModalBody = styled.div`
    font-size: 14px;
`;

const NextButton = styled(WhitePillButton)<{ firstAtBottom?: boolean }>`
    width: 100%;
    ${props => props.firstAtBottom && "margin-top: auto;"}
`;

const ModalButton = styled.div`
    padding: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    border-top: 1px solid ${colors.mediumTeal};
    width: 100%;
    color: ${colors.primaryBlue};
    :not(:last-child) {
        border-right: 1px solid ${colors.mediumTeal};
    }
    cursor: pointer;
`;

interface Props {}

const AccountVerification = ({}: Props) => {
    const params = useQuery();
    const error = params.get("error");

    const userID = localStorage.getItem("userid");
    const [modalOpen, setModalOpen] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();

    const profile = useSelector(getLoggedInProfile);
    const socials = useSelector(getLoggedInSocialAccounts);
    const hasUIAccess = useSelector(getHasUIAccess);
    const storedPreApprovalCode = useSelector(getStoredPreApprovalCode);

    const profileLoading = useSelector((state: GlobalState) => {
        return state.ui.auth.profileLoading;
    });
    const [loading, setLoading] = useState(false);

    const tiktokAccounts = checkForSocialAccounts({
        accounts: socials || [],
        platform: "tiktok"
    });

    useEffect(() => {
        dispatch(fetchUser());
        if (storedPreApprovalCode) {
            history.push("/onboarding/preApproval");
        }
    }, []);
    useEffect(() => {
        if (
            !profileLoading &&
            profile?.account_verified &&
            (profile?.accessStatus === AccessStatuses.ALL ||
                profile?.accessStatus === AccessStatuses.CHALLENGES)
        ) {
            dispatch(
                addNotification(
                    "Account verified continue to The Plug!",
                    colors.primaryGreen
                )
            );
            setTimeout(() => history.push("/"), 1400);
        }
    }, [profileLoading]);

    useEffect(() => {
        if (error) {
            dispatch(addNotification(error, "red"));
            params.delete("error");
            history.replace({
                search: params.toString()
            });
        }
    }, [error]);

    async function tiktokLoginKitURL(account?: SocialAccount) {
        try {
            const res = await get("/v1/socialAccount/tcmCheck", {
                publisherId: userID,
                username: account?.username,
                socialAccountId: account?.id,
                redirectURI: window.location.href
            });
            window.location.replace(res.data.data.redirectURL);
        } catch (err) {
            dispatch(
                setPillNotificationText("Failed to get link url", "danger")
            );
        }
    }

    async function checkContinue() {
        setLoading(true);
        if (hasUIAccess) {
            history.push("/");
            return;
        }

        const errored = getErroredSocialAccount(tiktokAccounts);
        if (errored.length) {
            for (const erroredAccount of errored) {
                let res = await patch(
                    `/public/socialAccount/${erroredAccount.id}/tiktokLogin/reverify`
                );

                if (res.data.data.verified) {
                    history.push("/");
                    return;
                }
            }
        }
        setLoading(false);
        setModalOpen("failure");
    }

    if (profileLoading || loading)
        return (
            <FullscreenLoader
                background={colors.primaryBlue}
                color={colors.white}
                height="100vh"
            />
        );

    return (
        <>
            <Main>
                <InnerContainer>
                    <Body>Which accounts will you be using to promote?</Body>
                    {tiktokAccounts.map(account => (
                        <VerifiedSocialAccountCell
                            onClick={() => tiktokLoginKitURL(account)}
                            key={account.id}
                            account={account}
                        />
                    ))}
                    <Button onClick={() => tiktokLoginKitURL()}>
                        <Plus src="/plus_button/plus_button.png" /> Connect
                        TikTok account
                    </Button>
                    <NextButton
                        firstAtBottom
                        onClick={() => history.push("/onboarding/preApproval")}
                    >
                        I have a Pre-Approval Code from a Recruiter
                    </NextButton>
                    <NextButton onClick={checkContinue}>
                        Continue to the Plug
                    </NextButton>
                    <Row>
                        <BottomText onClick={() => setModalOpen("support")}>
                            Need Help? Contact Us
                        </BottomText>
                    </Row>
                </InnerContainer>
            </Main>
            {modalOpen === "support" && (
                <SupportModal closeModal={() => setModalOpen("")} />
            )}
            {modalOpen === "failure" && (
                <Modal onClick={() => setModalOpen("")}>
                    <ModalContainer style={{ padding: "14px 16px" }}>
                        <ModalHeader>Account Not Approved</ModalHeader>
                        <ModalBody>
                            The account that you connected does not meet our
                            approval requirements. If you have another account,
                            please connect it and try again!
                        </ModalBody>
                    </ModalContainer>
                    <div style={{ display: "flex" }}>
                        <ModalButton onClick={() => setModalOpen("support")}>
                            Contact Us
                        </ModalButton>
                        <ModalButton onClick={() => setModalOpen("")}>
                            Ok
                        </ModalButton>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default AccountVerification;
