import { Action } from "src/types";

export const SET_LINK_LIST_ERROR = "SET_LINK_LIST_ERROR";

export const setLinkListError = (
    error: string | null,
    component?: string
): Action => ({
    type: SET_LINK_LIST_ERROR,
    payload: !error ? null : { error, component }
});

export const SET_LINK_LIST_TYPE = "SET_LINK_LIST_TYPE";

export function setLinkListType(type?: string): Action {
    return {
        type: SET_LINK_LIST_TYPE,
        payload: type
    };
}
