import { Action } from "../../types";

export const SET_LAYOUT = "SET_LAYOUT";

export enum LayoutType {
    desktop = "desktop",
    mobile = "mobile"
}

export const setLayout = (layout: LayoutType): Action => ({
    type: SET_LAYOUT,
    payload: { layout }
});

export const SET_THEME = "SET_THEME";

export enum LayoutThemeType {
    light = "light",
    dark = "dark"
}

export const setLayoutTheme = (theme: LayoutThemeType): Action => ({
    type: SET_THEME,
    payload: theme
});
