import React, { useState } from "react";
import { colors } from "src/constants";
import HalfScreenModal from "src/modals/HalfScreenModal";
import styled from "styled-components";

const Modal = styled(HalfScreenModal)`
    border-radius: 0px;
    padding: 18px;
`;
const Header = styled.div`
    display: flex;
    height: 50px;
    max-width: 500px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-top: -72px;
    margin-left: -18px;
    width: 100%;
    background: white;
    position: absolute;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
`;
const Main = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
const Title = styled.div`
    margin-right: 30px;
    width: 100%;
    text-align: center;
    height: fit-content;
    margin: auto 30px auto 0;
`;
const Rating = styled.div`
    display: flex;
`;
const Star = styled.img``;
const Description = styled.p`
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;
const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 8px;
    background-color: ${colors.secondaryLightGray};
    color: ${colors.gray2};
    text-align: left;
    padding: 12px;
`;

const AppDetails = styled.div`
    display: flex;
    gap: 12px;
    align-items: end;
`;
const Icon = styled.img`
    height: 46px;
    width: 46px;
    border-radius: 10px;
`;
const AppTitle = styled.div`
    font-size: 13px;
`;

const CloseBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;

    & > img {
        width: 14px;
        height: 14px;
    }
`;

interface Props {
    onClose: (val: boolean) => void;
    rating: number;
    icon: string;
    title: string;
    description: string;
    campaignName: string;
}

const HighlightDetailsPopup = ({
    onClose,
    rating,
    icon,
    title,
    description,
    campaignName
}: Props) => {
    return (
        <Modal closeModal={() => onClose(false)}>
            <Header>
                <CloseBtn onClick={() => onClose(false)}>
                    <img src="/black_close.svg" alt="close modal" />
                </CloseBtn>
                <Title>{title}</Title>
            </Header>
            <Main>
                <Section>
                    <div>Performance</div>
                    <Rating>
                        {new Array(rating).map(() => (
                            <Star src="star" />
                        ))}
                    </Rating>
                    <Description>
                        Medium performance means that this media is performing
                        very well with users on the Plug. Medium performing
                        highlights are earning X% more than the average Plug
                        media
                    </Description>
                </Section>
                <Section>
                    <div>About the App</div>
                    <AppDetails>
                        <Icon src={icon} />
                        <AppTitle>{campaignName}</AppTitle>
                    </AppDetails>
                    <Description>{description}</Description>
                </Section>
            </Main>
        </Modal>
    );
};

export default HighlightDetailsPopup;
