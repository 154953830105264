import React from "react";
import { Route, Switch } from "react-router";
import Welcome from "./Welcome";
import PhoneAuth from "./PhoneAuth";

const MagicPath = () => {
    return (
        <Switch>
            <Route exact path="/approved" component={Welcome} />
            <Route path="/approved/phone" component={PhoneAuth} />
        </Switch>
    );
};

export default MagicPath;
