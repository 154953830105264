import React from "react";
import { useSelector } from "react-redux";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import ModalBox from "src/components/modals/components/ModalBox";
import { GlobalState } from "src/reducers";
import styled from "styled-components";

const DescriptionBox = styled.p`
    white-space: pre-line;
    text-align: left;
    font-size: 12px;
`;
const ChallengeBrief = ({ openBuy }: { openBuy: OpenBuy }) => {
    return (
        <ModalBox headingText="CHALLENGE BRIEF" closable defaultOpen={false}>
            <DescriptionBox>
                {openBuy.description ?? "Description Missing"}
            </DescriptionBox>
        </ModalBox>
    );
};

export default ChallengeBrief;
