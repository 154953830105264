import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { PlacementsEntitiesState } from "src/campaigns/placements/reducer";
import { CampaignMedia } from "src/campaigns/types";
import CheckBox from "src/components/CheckBox";
import { updateMediaRequest } from "src/media/actions";
import { BlueButton } from "src/profile/components/Buttons";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;
const Title = styled.h4`
    font-size: 13px;
    padding: 8px 0px;
`;
const List = styled.ul`
    list-style: none;
    width: 100%;
    padding: 0px;
    margin-bottom: 16px;
`;
const PlacementOption = styled.li`
    display: flex;
    align-items: center;
    font-size: 13px;
    width: 100%;
    padding: 18px 12px;
    cursor: pointer;
`;
const Icon = styled.img`
    height: 24px;
    width: 24px;
    margin-right: 12px;
    border-radius: 8px;
`;
const BackBtn = styled.button`
    position: absolute;
    top: 0px;
    left: 0px;
    & > img {
        width: 16px;
        height: 16px;
    }
`;
const StyledCheckBox = styled(CheckBox)`
    margin-left: auto;
`;
const SubmitBtn = styled(BlueButton)`
    width: 100%;
    padding: 8px 0px;
`;

interface Props {
    placements: PlacementsEntitiesState;
    back: VoidFunction;
    media: CampaignMedia;
}

const PlacementForm = ({ placements, back, media }: Props) => {
    const dispatch = useDispatch();
    const [activePlacements, setActivePlacements] = useState(
        media.placementIds
    );
    const handleClick = (id: number) => {
        activePlacements.includes(id)
            ? setActivePlacements(activePlacements.filter(val => val !== id))
            : setActivePlacements([...activePlacements, id]);
    };
    return (
        <Wrapper>
            <BackBtn onClick={back}>
                <img src="/back-button.svg" alt="" />
            </BackBtn>
            <Title>Select Placements</Title>
            <List>
                {placements.allIds.map((val: number, idx) => (
                    <PlacementOption
                        key={val + idx}
                        onClick={() => handleClick(val)}
                    >
                        <Icon src={placements.byId[val].logo} alt="" />
                        {placements.byId[val].type}
                        <StyledCheckBox
                            selected={activePlacements.includes(val)}
                        />
                    </PlacementOption>
                ))}
            </List>
            <SubmitBtn
                onClick={() => {
                    dispatch(
                        updateMediaRequest(
                            { ...media, placementIds: activePlacements },
                            "Placements Changed Successfully"
                        )
                    );
                    back();
                }}
            >
                Save Placements
            </SubmitBtn>
        </Wrapper>
    );
};

export default PlacementForm;
