import { push } from "connected-react-router";
import { normalize } from "normalizr";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { deleteReq, get, patch, post } from "src/Api";
import { openBuyList } from "src/buys/schema";
import { Action } from "src/types";
import {
    setChallengeDetailsIncomplete,
    setCreateChallengeFormOpen,
    setCurrentOpenBuyId
} from "src/ui/admin-tools/actions";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { errorToString } from "src/util";
import {
    adminCreateOpenBuySuccess,
    adminDeleteOpenBuySuccess,
    adminFetchOpenBuysSuccess,
    adminFetchOpenBuySuccess,
    adminUpdateOpenBuySuccess,
    ADMIN_CREATE_OPEN_BUY,
    ADMIN_CREATE_OPEN_BUY_POSTER,
    ADMIN_DELETE_OPEN_BUY,
    ADMIN_FETCH_OPEN_BUY,
    ADMIN_FETCH_OPEN_BUYS,
    ADMIN_UPDATE_OPEN_BUY,
    ADMIN_UPDATE_OPEN_BUY_POSTER
} from "./actions.admin-tools";
import { openBuy, openBuyPosterList } from "./schema.admin-tools";

function* adminFetchOpenBuysRequest() {
    try {
        const searchResponse = yield call(get, "/v1/admin/openBuy/search", {
            limit: 1000
        });

        const normalizedSearchResponse = normalize(
            searchResponse.data.data,
            openBuyList
        );

        yield put(
            adminFetchOpenBuysSuccess({
                normalizedSearchResponse
            })
        );
    } catch (error) {
        const errorMessage = errorToString(error);
        yield put(setPillNotificationText(errorMessage, "danger"));
    }
}

function* adminFetchOpenBuyRequest(action: Action) {
    try {
        const { openBuyId } = action.payload;
        const response = yield call(get, "/v1/admin/openBuy/search", {
            id: openBuyId
        });
        const openBuy = response.data.data[0];
        const pubCount = yield call(
            get,
            `/v1/openBuy/${openBuyId}/publisher/count`,
            {}
        );
        const openBuyWithCount = {
            ...openBuy,
            publisherCount: pubCount.data.data
        };
        yield put(
            adminFetchOpenBuySuccess({
                openBuy: openBuyWithCount
            })
        );
    } catch (error) {
        const errorMessage = errorToString(error);
        yield put(
            setPillNotificationText(
                "That challenge no longer exists.",
                "danger",
                3500
            )
        );
        console.error(errorMessage);
    }
}

function* adminCreateOpenBuyRequest(action: Action) {
    try {
        const { name } = action.payload;
        const endpoint = "/v1/openBuy";
        const response = yield call(post, endpoint, { name });

        yield put(
            adminCreateOpenBuySuccess(normalize(response.data.data, openBuy))
        );
        yield put(setPillNotificationText("Challenge successfully created."));
        yield put(setCreateChallengeFormOpen(false));
        yield put(setCurrentOpenBuyId(response.data.data.id));
        yield put(
            push(
                `/admin-tools/your-challenges/${response.data.data.id}/edit-challenge`
            )
        );
    } catch (error) {
        const errorMessage = errorToString(error);
        yield put(setPillNotificationText(errorMessage, "danger"));
    }
}

function* adminUpdateOpenBuyRequest(action: Action) {
    try {
        const { data } = action.payload;
        console.log(data);
        const response = yield call(patch, `/v1/openBuy/${data.id}`, data);

        const openBuyId = response.data.data.id;
        const openBuyResponse = yield call(get, "/v1/admin/openBuy/search", {
            id: openBuyId
        });
        const updatedOpenBuy = openBuyResponse.data.data[0];

        const posterResponse = yield call(get, "/v1/admin/openBuy/poster", {});
        const normalizedPosterResponse = normalize(
            posterResponse.data.data,
            openBuyPosterList
        );

        yield put(setChallengeDetailsIncomplete(false));

        yield put(
            adminUpdateOpenBuySuccess({
                openBuy: updatedOpenBuy,
                posters: normalizedPosterResponse
            })
        );
        yield put(setPillNotificationText("Challenge Updated Successfully"));
    } catch (error) {
        const errorMessage = errorToString(error);
        yield put(setPillNotificationText(errorMessage, "danger"));
    }
}

function* adminCreateOpenBuyPosterRequest(action: Action): any {
    try {
        const { openBuyId, filename } = action.payload;

        const response: any = yield call(
            post,
            `/v1/openBuy/${openBuyId}/poster`,
            {
                openBuyPosters: [
                    {
                        filename,
                        coverPhoto: filename,
                        type: "photo",
                        position: 1
                    }
                ]
            }
        );
    } catch (error) {
        const errorMessage = errorToString(error);
        yield put(setPillNotificationText(errorMessage, "danger"));
    }
}

function* adminUpdateOpenBuyPosterRequest({ payload }: Action): any {
    try {
        const response = yield call(patch, "/v1/openBuy/poster", {
            openBuyPosters: [
                {
                    id: payload.posterId,
                    filename: payload.filename,
                    coverPhoto: payload.filename,
                    type: "photo",
                    position: 1
                }
            ]
        });
    } catch (error) {
        const errorMessage = errorToString(error);
        yield put(setPillNotificationText(errorMessage, "danger"));
    }
}

function* adminDeleteOpenBuyRequest(action: Action) {
    try {
        const { openBuyId } = action.payload;
        yield call(deleteReq, "/v1/openBuy", { openBuyId });
        yield put(setCurrentOpenBuyId(null));
        yield put(adminDeleteOpenBuySuccess(openBuyId));
        yield put(push("/admin-tools/your-challenges"));
        yield put(setPillNotificationText("Challenge Deleted Successfully"));
    } catch (error) {
        const errorMessage = errorToString(error);
        yield put(setPillNotificationText(errorMessage, "danger"));
    }
}

export default function* adminOpenBuysSaga() {
    yield all([
        takeEvery(ADMIN_FETCH_OPEN_BUYS.REQUEST, adminFetchOpenBuysRequest),
        takeEvery(ADMIN_FETCH_OPEN_BUY.REQUEST, adminFetchOpenBuyRequest),
        takeEvery(ADMIN_CREATE_OPEN_BUY.REQUEST, adminCreateOpenBuyRequest),
        takeEvery(
            ADMIN_CREATE_OPEN_BUY_POSTER.REQUEST,
            adminCreateOpenBuyPosterRequest
        ),
        takeEvery(ADMIN_UPDATE_OPEN_BUY.REQUEST, adminUpdateOpenBuyRequest),
        takeEvery(
            ADMIN_UPDATE_OPEN_BUY_POSTER.REQUEST,
            adminUpdateOpenBuyPosterRequest
        ),
        takeEvery(ADMIN_DELETE_OPEN_BUY.REQUEST, adminDeleteOpenBuyRequest)
    ]);
}
