import { Action } from "../../types";
import { SET_CURRENT_MODAL_ASSETS } from "./actions";

export interface AssetsUIState {
    currentModalAssets: number[];
}

const initialAssetsUIState: AssetsUIState = {
    currentModalAssets: []
};

const assetsUIReducer = (state = initialAssetsUIState, action: Action) => {
    switch (action.type) {
        case SET_CURRENT_MODAL_ASSETS:
            return { ...state, currentModalAssets: action.payload.assetIds };
        default:
            return state;
    }
};

export default assetsUIReducer;
