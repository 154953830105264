import React from "react";
import styled from "styled-components";
import {
    BackButton,
    NavContainer,
    ButtonContainer,
    IntroButton
} from "./IntroSocialScreen";
import VerticallyCentered from "../../../components/VerticallyCentered";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";

const Title = styled("div")`
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
`;

const List = styled("div")`
    padding: 40px 0px 60px;
`;

const ListItem = styled(VerticallyCentered)`
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    img {
        margin-right: 16px;
    }

    & + & {
        margin-top: 40px;
    }
`;

const Icon = styled("img")``;

const Text = styled("span")`
    display: block;
    text-align: left;
`;

interface Props {
    backCallback: VoidFunction;
    nextCallback: VoidFunction;
}

const IntroSocialWelcome = ({ backCallback, nextCallback }: Props) => {
    return (
        <>
            <Navbar
                marginBottom={20}
                customMobileNavbar={
                    <NavContainer>
                        <BackButton
                            onClick={backCallback}
                            src="/back-button.svg"
                        />
                    </NavContainer>
                }
            />

            <MainContainer addSidePadding>
                <Title>Introducing Plug Public Profiles</Title>

                <List>
                    <ListItem>
                        <Icon src="/intro-social/intro-friends-icon.svg" />
                        <Text>Make new friends on the Plug</Text>
                    </ListItem>
                    <ListItem>
                        <Icon src="/intro-social/intro-link-icon.svg" />
                        <Text>Connect with the most popular influencers</Text>
                    </ListItem>
                    <ListItem>
                        <Icon src="/intro-social/intro-send-icon.svg" />
                        <Text>Set up deals directly on the Plug with DMs</Text>
                    </ListItem>
                    <ListItem>
                        <Icon src="/intro-social/intro-ellipsis-icon.svg" />
                        <Text>And much more...</Text>
                    </ListItem>
                </List>

                <ButtonContainer>
                    <IntroButton onClick={nextCallback}>
                        Get Started
                    </IntroButton>
                </ButtonContainer>
            </MainContainer>
        </>
    );
};

export default IntroSocialWelcome;
