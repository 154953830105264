import React from "react";
import { connect } from "react-redux";
import { Channel } from "../types";
import { clearModal } from "../../modals/actions";
import { goBack, push } from "connected-react-router";
import ActionsModal from "../../modals/ActionsModal";
import { postConnection, PostConnectionParams } from "../../profile/actions";
import { Connection } from "../../profile/types";

interface Props {
    sdk: any;
    userId: number;
    channel: Channel;
    setChannel: (channel: Channel) => void;
    postConnection: (params: PostConnectionParams) => void;
    clearModal: VoidFunction;
    goBack: VoidFunction;
    push: (route: string) => void;
}

const DirectActionsModal = ({
    sdk,
    userId,
    channel,
    setChannel,
    postConnection,
    clearModal,
    goBack,
    push
}: Props) => {
    const sendBirdData = channel.sendBirdData;

    const otherUser = channel.connections.find(connection => {
        return connection.other.id !== `p:${userId}`;
    });
    const otherSBMember =
        otherUser && channel.sendBirdData.memberMap[otherUser.other.id];

    const isMuted = sendBirdData.myPushTriggerOption === "off";

    const isBlocked = otherSBMember && otherSBMember.isBlockedByMe;

    const block = (otherUser: Connection) => {
        sdk.blockUserWithUserId(otherUser.other.id, (user: any, error: any) => {
            if (!error) {
                postConnection({
                    selfId: otherUser.entity.id,
                    otherId: otherUser.other.id,
                    relationship: "block"
                });
                clearModal();
            } else {
                window.alert(error);
            }
        });
    };

    const unblock = (otherUser: Connection) => {
        sdk.unblockUserWithUserId(
            otherUser.other.id,
            (user: any, error: any) => {
                if (!error) {
                    postConnection({
                        selfId: otherUser.entity.id,
                        otherId: otherUser.other.id,
                        relationship: "none"
                    });
                    clearModal();
                } else {
                    window.alert(error);
                }
            }
        );
    };

    if (!otherUser) {
        return null;
    } else {
        const otherId = otherUser.other.id.slice(2);

        return (
            <ActionsModal
                actions={[
                    {
                        text: "View Profile",
                        callback: () => {
                            push(`/public/${otherId}`);
                            clearModal();
                        }
                    },
                    {
                        text: isMuted ? "Unmute" : "Mute",
                        callback: () => {
                            const newSetting = isMuted ? "default" : "off";

                            sendBirdData.setMyPushTriggerOption(
                                newSetting,
                                (newSetting: any, error: any) => {
                                    if (!error) {
                                        sdk.GroupChannel.getChannel(
                                            sendBirdData.url,
                                            (newChannel: any, error: any) => {
                                                if (!error) {
                                                    setChannel({
                                                        ...channel,
                                                        sendBirdData: newChannel
                                                    });
                                                    clearModal();
                                                } else {
                                                    window.alert(error);
                                                }
                                            }
                                        );
                                    } else {
                                        window.alert(error);
                                    }
                                }
                            );
                        }
                    },
                    {
                        text: isBlocked ? "Unblock" : "Block",
                        callback: () =>
                            isBlocked ? unblock(otherUser) : block(otherUser),
                        negative: true
                    }
                ]}
            />
        );
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    postConnection: (params: PostConnectionParams) =>
        dispatch(postConnection(params)),
    clearModal: () => dispatch(clearModal()),
    goBack: () => dispatch(goBack()),
    push: (route: string) => dispatch(push(route))
});

export default connect(null, mapDispatchToProps)(DirectActionsModal);
