import React, { useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../reducers";
import { Media, OldMedia } from "../../media/types";
import SimpleNavbar from "../../components/SimpleNavbar";
import Button from "react-bootstrap/Button";
import CustomLinksCreatePicker from "./CustomLinksCreatePicker";
import { ReactComponent as Cross } from "../../components/icons/cross.svg";
import CustomLinksCreate from "./CustomLinksCreate";
import CustomLinksIndexRow from "./CustomLinksIndexRow";
import {
    campaignCustomLinksModal,
    CustomLinksModalState
} from "../../ui/custom-links/actions";
import { getCampaignCustomLinks } from "./selectors";
import { colors } from "../../constants";

interface Props {
    customLinksState: CustomLinksModalState;
    customLinks: OldMedia[];
    Media: Media[];
    campaignCustomLinksModal: (state: CustomLinksModalState) => void;
}

const CustomLinksPage = ({
    Media,
    customLinksState,
    campaignCustomLinksModal,
    customLinks
}: Props) => {
    const [currentMedia, setCurrentMedia] = useState(Media[0]);
    const [selectScreen, setSelectScreen] = useState(false);

    if (selectScreen && customLinksState === CustomLinksModalState.create) {
        return (
            <CustomLinksCreatePicker
                Media={Media}
                initialMedia={currentMedia}
                onCancelTapped={() => setSelectScreen(false)}
                onDoneTapped={(media: Media) => {
                    setCurrentMedia(media);
                    setSelectScreen(false);
                }}
            />
        );
    } else if (customLinksState === CustomLinksModalState.create) {
        return (
            <CustomLinksCreate
                media={currentMedia}
                openSelect={() => setSelectScreen(true)}
            />
        );
    } else {
        const leftNavButton = (
            <Cross
                fill={colors.primaryGray}
                onClick={() =>
                    campaignCustomLinksModal(CustomLinksModalState.clear)
                }
            />
        );

        return (
            <>
                <SimpleNavbar title={"Custom Links"} left={leftNavButton} />
                {customLinks.map(link => {
                    return (
                        <CustomLinksIndexRow key={link.id} customLink={link} />
                    );
                })}
                <div style={{ padding: "15px" }}>
                    <Button
                        block
                        variant="success"
                        onClick={() =>
                            campaignCustomLinksModal(
                                CustomLinksModalState.create
                            )
                        }
                    >
                        + New Custom Link
                    </Button>
                </div>
            </>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    customLinksState: state.ui.customLink.customLinksModal
});

const mapDispatchToProps = (dispatch: any) => ({
    campaignCustomLinksModal: (state: CustomLinksModalState) =>
        dispatch(campaignCustomLinksModal(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomLinksPage);
