import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchLinkForPlug,
    fetchLinkForPlugWithoutModal
} from "src/links/actions";
import CampaignCarouselDetails from "src/campaigns/medias/components/CampaignCarouselDetails";
import { colors } from "src/constants";
import CarouselInfo from "src/modals/CarouselInfo";
import CampaignReward from "src/campaigns/details/components/CampaignReward";
import { selectCampaignCountryRewardById } from "src/campaigns/selectors";
import { CampaignMedia } from "src/campaigns/types";
import { BigBlueButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import { PROMOTE_FLOW } from "src/ui/promote-flow/actions";
import styled from "styled-components";

const Main = styled.div`
    width: 100vw;
    max-width: 500px;
    padding-bottom: 20px;
    color: white;
    text-align: left;
    font-weight: 300;
    user-select: none;

    & img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
`;

const Container = styled.div`
    padding: 0 42px;
    margin-top: 24px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    justify-content: space-around;

    & img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
`;

const PromoteButton = styled(BigBlueButton)<{ disabled?: boolean }>`
    /* pointer-events: none; */
    user-select: none;
    ${props => props.disabled && `pointer-events: none; opacity: 0.5;`}

    &:active {
        opacity: 0.5;
    }
`;

interface Props {
    media: CampaignMedia;
    closeModal: () => void;
}

const PromoteMediaCarouselCell = ({ media, closeModal }: Props) => {
    const dispatch = useDispatch();

    const promoting = useSelector(
        (state: GlobalState) => state.ui.promote.promoting
    );

    const campaign = useSelector((state: GlobalState) =>
        selectCampaignCountryRewardById(state, media.campaignId)
    );

    return (
        <Main>
            <CarouselInfo
                mediaType={media.type}
                mediaUrl={media.url || ""}
                isPortrait={media.isPortrait}
                amountComponent={
                    campaign ? (
                        <CampaignReward
                            countryPlatformReward={
                                campaign.countryPlatformReward
                            }
                            rewardType={campaign.rewardType}
                            noPlatform
                            color={colors.white}
                        />
                    ) : null
                }
                closeModal={closeModal}
                campaignName={media.campaignName}
                appIconUrl={media.campaignImageUrl}
            />
            <Container>
                {promoting ? (
                    <PromoteButton
                        onClick={() => {
                            dispatch(PROMOTE_FLOW.setSelectedMedia(media));
                            dispatch(fetchLinkForPlugWithoutModal(media));
                            closeModal();
                        }}
                    >
                        Repost this Media
                    </PromoteButton>
                ) : (
                    <CampaignCarouselDetails
                        mediaId={media.id}
                        mediaUrl={media.url}
                        linkClicked={() => dispatch(fetchLinkForPlug(media))}
                    />
                )}
            </Container>
        </Main>
    );
};

export default PromoteMediaCarouselCell;
