import { push } from "connected-react-router";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { adminUpdateOpenBuy } from "src/admin-tools/actions.admin-tools";
import { WarningModal } from "src/campaigns/scripts/modals/ScriptModal";
import Warning from "src/campaigns/scripts/modals/Warning";
import GlobalWarning from "src/components/GlobalWarning";
import { colors, fonts, fontWeight, shadows } from "src/constants";
import {
    createOfferWizardRoute,
    instructionSetsRoutes
} from "src/marketplace/utils/marketplace-routes";
import { GlobalState } from "src/reducers";
import { setCurrentWizardStep } from "src/ui/create-offer-wizard/actions";
import {
    resetInstructionsSetWhenNavigatingToMainPage,
    setInstructionsPageStep,
    setInstructionsPageType,
    setInstructionsSetsLoading
} from "src/ui/instructions-sets/actions";
import { PageType } from "src/ui/instructions-sets/reducers";
import {
    clearButton,
    clearLink,
    preventUserInteraction,
    primaryBlueBackgroundHoverState
} from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import {
    createInstructionsSet,
    deleteInstructionsSet,
    InstructionsSetCreateData
} from "../actions.instructions-sets";
import { getCurrentInstructionsSet } from "../selectors";
import { useConvertReduxDataToAPIData } from "../utils/hooks.page-partial";
import CampaignSelectionButton from "./CampaignSelectionButton";
import InstructionsFormInput from "./InstructionsFormInput";
import { setInstructionsSetSubmitStatus } from "../../../ui/instructions-sets/actions";

const Main = styled.header`
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;
    background-color: ${colors.white};
    box-shadow: ${shadows.four};
`;

const TitleSubtitleAndCampaignSelectionButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
`;

const TitleAndSubtitle = styled.article<{
    onMainPage: boolean;
}>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    ${props => {
        if (props.onMainPage)
            return css`
                ${preventUserInteraction()};
                cursor: default;
            `;
    }};
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

interface ButtonProps {
    isErrors?: boolean;
}

const buttonStyles = css<ButtonProps>`
    padding: 8px 12px;
    color: ${colors.white};
    border-radius: 6px;
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.medium};
    opacity: ${props => (props.isErrors ? "0.5" : "1")};
    pointer-events: ${props => (props.isErrors ? "none" : "auto")};
    transition: 0.1s ease-in;
`;

const SaveButton = styled.button`
    ${buttonStyles};
    background-color: ${colors.primaryBlue};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

const DeleteButton = styled.button`
    ${buttonStyles};
    background-color: ${colors.primaryRed};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryRedDark};
        }
    }
`;

const EditButton = styled.button<{ $isDisabled?: boolean }>`
    ${clearButton()};
    ${primaryBlueBackgroundHoverState()};
    ${buttonStyles};
    flex-shrink: 0;

    ${props => {
        if (props.$isDisabled) {
            return css`
                ${preventUserInteraction()};
                opacity: 0.5;
            `;
        }
    }}

    @media (hover: hover) {
        :hover {
            color: white;
        }
    }
`;

const CancelButtonLink = styled.button`
    ${clearButton()};
    ${buttonStyles};
    color: ${colors.black};
    background-color: ${colors.quaternaryLightGray};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray2};
        }
    }
`;

interface Props {
    title: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
}

const InstructionsFormHeader = ({ title, setTitle }: Props) => {
    const isErrors = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets.isErrors;
    });

    const pageType = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets.instructionsPageType;
    });

    const inEditPage = pageType === "Edit";
    const inCreatePage = pageType === "Create";

    const currentInstructionsSetId = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets.currentInstructionsSetId;
    });

    const [deleteInstructionWarning, setDeleteInstructionWarning] = useState(
        false
    );

    const dispatch = useDispatch();

    const { inAdminTools, currentOpenBuyId } = useSelector(
        (state: GlobalState) => {
            return state.ui.adminTools;
        }
    );

    const { currentCampaignId } = useSelector((state: GlobalState) => {
        return state.ui.campaign;
    });

    const formattedInstructionSteps = useConvertReduxDataToAPIData(true);
    const currentInstructionSet = useSelector(getCurrentInstructionsSet);
    function handleDuplication(): void {
        // If it belongs to a challenge...
        // 1. Change "edit" wording to "Use In New Challenge"
        // 2. Duplicate the instruction set with a POST request
        // 3. When the new instruction set is created and the API returns
        //      a. the primary key for the instruction set, set that as the currentInstructionSetId
        //      b. change to the edit page with the current instruction set

        if (currentInstructionSet && currentCampaignId && currentOpenBuyId) {
            const formattedInstructionsSet: InstructionsSetCreateData = {
                name: `${currentInstructionSet.name} Copy`,
                campaignId: currentCampaignId,
                advertiserId: null,
                duration: null,
                instructionSteps: formattedInstructionSteps
            };
            dispatch(
                createInstructionsSet(
                    formattedInstructionsSet,
                    true,
                    currentOpenBuyId
                )
            );
            dispatch(
                adminUpdateOpenBuy({
                    id: currentOpenBuyId,
                    campaignId: currentCampaignId
                })
            );
            dispatch(setInstructionsPageType("Edit"));
            dispatch(setInstructionsPageStep("Selection"));
        }
    }

    function handleRouting(pageType: PageType): void {
        if (!inAdminTools) {
            if (pageType === "Edit") {
                dispatch(push(instructionSetsRoutes.edit));
            } else {
                dispatch(push("/admin-tools"));
                dispatch(setCurrentWizardStep("Select an Instruction Set"));
            }
        } else {
            // dispatch(setInstructionsPageType(pageType));
            dispatch(resetInstructionsSetWhenNavigatingToMainPage());
        }
    }

    const currentOpenBuyHasAnAssociatedInstructionSet = !!currentInstructionSet;

    return (
        <Main>
            <TitleSubtitleAndCampaignSelectionButton>
                <TitleAndSubtitle onMainPage={pageType === "Main"}>
                    <InstructionsFormInput
                        placeholder="Untitled Instruction Set"
                        value={title}
                        setValue={setTitle}
                        fontSize={fonts.title}
                        fontWeight={500}
                    />
                </TitleAndSubtitle>

                {/* <CampaignSelectionButton
                    currentInstructionSet={currentInstructionSet}
                /> */}
            </TitleSubtitleAndCampaignSelectionButton>
            <Buttons>
                {pageType !== "Main" && (
                    <SaveButton
                        onClick={e => {
                            e.preventDefault();
                            dispatch(
                                setInstructionsSetSubmitStatus(
                                    "updating data to send to api"
                                )
                            );
                        }}
                    >
                        {inCreatePage ? "Create" : "Update"}
                    </SaveButton>
                )}

                {/* {inEditPage && (
                    <DeleteButton
                        type="button"
                        onClick={() => setDeleteInstructionWarning(true)}
                        isErrors={isErrors}
                    >
                        Delete
                    </DeleteButton>
                )} */}

                {pageType === "Main" && (
                    <>
                        {/* <EditButton
                            type="button"
                            onClick={() => handleRouting("Edit")}
                        >
                            Edit
                        </EditButton> */}
                        <EditButton
                            type="button"
                            // to={instructionSetsRoutes.edit}
                            $isDisabled={
                                !currentOpenBuyHasAnAssociatedInstructionSet
                            }
                            onClick={handleDuplication}
                        >
                            {!currentOpenBuyHasAnAssociatedInstructionSet
                                ? "Select Or Create an Instruction"
                                : "Use As Template"}
                        </EditButton>
                    </>
                )}

                {!currentOpenBuyId && pageType !== "Main" && (
                    <CancelButtonLink
                        // to={createOfferWizardRoute}
                        // to={instructionSetsRoutes.main}
                        onClick={() => handleRouting("Main")}
                    >
                        Cancel
                    </CancelButtonLink>
                )}
            </Buttons>

            {deleteInstructionWarning && (
                <GlobalWarning
                    title={"Are you sure?"}
                    message={
                        "Are you sure you want to delete this instruction?"
                    }
                    handleCancel={() => setDeleteInstructionWarning(false)}
                    handleApply={() => {
                        if (!!currentInstructionsSetId) {
                            dispatch(
                                deleteInstructionsSet(currentInstructionsSetId)
                            );
                        }
                    }}
                    btnText={{
                        cancel: "Cancel",
                        apply: "Delete"
                    }}
                />
            )}
        </Main>
    );
};

export default InstructionsFormHeader;
