import { useState } from "react";
export type PageControl = {
    go: VoidFunction;
    disabled: boolean;
};

export interface PageReference {
    currentPage: number;
    pageIndices: [number, number];
    resultsPerPage: number;
    pageBack: PageControl;
    pageNext: PageControl;
}
const usePages = (
    dataLength: number,
    resultsPerPage: number,
    serverPaginate?: (currentPage: number) => void
): PageReference => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageIndices: [number, number] =
        currentPage === 1
            ? [0, resultsPerPage]
            : [
                  (currentPage - 1) * resultsPerPage,
                  currentPage * resultsPerPage
              ];

    const pageBackDisabled = currentPage === 1;
    const handlePageBack = () => {
        if (serverPaginate) {
            setCurrentPage(page => page - 1);
            serverPaginate(currentPage - 1);
        } else {
            setCurrentPage(page => page - 1);
        }
    };
    const pageNextDisabled = pageIndices[1] >= dataLength;
    const handlePageNext = () => {
        if (serverPaginate) {
            setCurrentPage(page => page + 1);
            serverPaginate(currentPage + 1);
        } else {
            setCurrentPage(page => page + 1);
        }
    };

    return {
        currentPage,
        pageIndices: serverPaginate ? [0, resultsPerPage] : pageIndices,
        resultsPerPage,
        pageBack: {
            go: handlePageBack,
            disabled: serverPaginate
                ? currentPage === 1
                    ? true
                    : false
                : pageBackDisabled
        },
        pageNext: {
            go: handlePageNext,
            disabled: serverPaginate ? false : pageNextDisabled
        }
    };
};

export default usePages;
