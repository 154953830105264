import React, { useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { GlobalState } from "../../../reducers";
import { Media, PlugLink } from "../../../media/types";
import { Campaign } from "src/campaigns/types";
import styled from "styled-components";
import StoryCard from "./StoryCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CarouselModal from "../../../modals/CarouselModal";
import { breakpoints, colors } from "src/constants";
import { LayoutType } from "src/ui/layout/actions";
import { setCurrentModalMedias } from "src/ui/medias/actions";
import CampaignCarouselCell from "./CampaignCarouselCell";
import { getIsMobile } from "src/utils/functions/selectors";
import { AddMediaCell } from "src/campaigns/details/components/AddCell";
import { hideScrollbar } from "src/utils/styles/snippets";

const HorizontalScrollOuter = styled.div`
    display: flex;
    width: 100%;
    overflow-x: auto;
    background-color: ${colors.lightTeal};
    border-bottom: 1px solid ${colors.mediumTeal};
    @media (min-width: ${breakpoints.mobile}px) {
        ${hideScrollbar()};
    }
`;

const HorizontalScrollInner = styled.div`
    padding: 10px 3px;
    display: flex;
    flex-wrap: nowrap;
`;

const SubmitMediaContainer = styled.div`
    width: 95px;
    padding: 0px 20px 0px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${colors.primaryGray};
    font-size: 11px;
    line-height: 13px;

    img {
        height: 46px;
        width: 46px;
        border-radius: 100%;
        margin-bottom: 4px;
        cursor: pointer;
    }
`;

const LoadMoreContainer = styled.div`
    padding: 6px 5px 6px 10px;
`;

const LoadMoreButton = styled.div`
    height: 100%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: ${colors.primaryGray};
    background-color: white;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 5px;
    cursor: pointer;
`;

export interface MediaRowProps {
    Media: Media[];
    campaign: Campaign;
    linkPrefix: string;
    linksForPlugs: { [s: string]: PlugLink | null };
    layoutType: LayoutType;
    globalModal?: boolean;
    setCurrentModalHighlight: (mediaIds: number[]) => void;
    isScriptModal?: boolean;
    isAdmin?: boolean;
    carouselRef: React.RefObject<any>;
}

const StoryMediaRow = ({
    Media,
    campaign,
    linkPrefix,
    linksForPlugs,
    layoutType,
    globalModal,
    setCurrentModalHighlight,
    isScriptModal,
    isAdmin,
    carouselRef
}: MediaRowProps) => {
    const isMobile = useSelector(getIsMobile);

    const increment = isMobile ? 4 : 8;
    const [limit, setLimit] = useState(increment);

    const [mediaList, setMediaList] = useState<Media[]>([]);

    function mediaClicked(index: number) {
        const newList = [
            ...Media.slice(index, Media.length),
            ...Media.slice(0, index)
        ];

        if (globalModal) {
            setCurrentModalHighlight(newList.map(el => el.id));
        } else {
            setMediaList(newList);
        }
    }

    function renderCarousel() {
        if (mediaList.length === 0) {
            return null;
        }

        return (
            <CarouselModal>
                {mediaList.map((media, idx) => {
                    if (!media) return null;
                    return (
                        <CampaignCarouselCell
                            key={`${media.campaignId}-${media.id}`}
                            media={media}
                            campaign={campaign}
                            closeModal={() => setMediaList([])}
                        />
                    );
                })}
            </CarouselModal>
        );
    }

    function renderMoreButton() {
        if (Media.length > limit) {
            return (
                <LoadMoreContainer>
                    <LoadMoreButton onClick={() => setLimit(limit + increment)}>
                        <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                    </LoadMoreButton>
                </LoadMoreContainer>
            );
        } else {
            return null;
        }
    }

    return (
        <>
            <HorizontalScrollOuter ref={carouselRef}>
                <HorizontalScrollInner>
                    {isScriptModal && isAdmin && (
                        <AddMediaCell caption="Add Example Media to Script" />
                    )}
                    {Media.slice(0, limit).map((media: Media, idx: number) => {
                        if (!media) return null;
                        return (
                            <StoryCard
                                key={`${media.campaignId}-${media.id}`}
                                media={media}
                                linkPrefix={linkPrefix}
                                linkService={campaign.linkService}
                                plugLink={
                                    linksForPlugs[
                                        `${media.campaignId}-${media.id}`
                                    ]
                                }
                                onMediaClicked={() => mediaClicked(idx)}
                                rank={idx}
                                isScriptModal={isScriptModal}
                            />
                        );
                    })}
                    {renderMoreButton()}
                </HorizontalScrollInner>
            </HorizontalScrollOuter>
            {!globalModal && renderCarousel()}
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    linksForPlugs: state.links.linksForPlugs,
    layoutType: state.ui.layout.type
});

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentModalHighlight: (mediaIds: number[]) =>
        dispatch(setCurrentModalMedias(mediaIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(StoryMediaRow);
