import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import { FlexCentered } from "../CommonStyles";

const Main = styled.div<{ active?: boolean }>`
    ${FlexCentered};
    flex-direction: column;
    align-self: stretch;
    min-width: 124px;
    cursor: pointer;
    user-select: none;
    pointer-events: auto;
    ${props => props.active && `border-bottom: 2px solid ${colors.primaryBlue}`}
`;

const Title = styled.div<{ active?: boolean }>`
    margin-top: 6px;
    font-size: ${fonts.smallSubtitle}px;
    font-weight: ${props => (props.active ? 500 : 400)};
    line-height: 12px;
    color: ${props => (props.active ? colors.primaryBlue : colors.primaryGray)};
`;

const SVGStyles = styled.div<{ active?: boolean }>`
    & > svg {
        rect:nth-of-type(1) {
            fill: ${props =>
                props.active ? colors.primaryBlue : colors.white};
            ${props => !props.active && `stroke: ${colors.primaryGray}`};
        }
        fill: ${props => (props.active ? colors.white : colors.primaryGray)};
    }
`;

interface Props {
    title: string;
    active?: boolean;
    onClick: () => void;
}

const DesktopNavTab: React.FC<Props> = ({
    children,
    title,
    active,
    onClick
}) => (
    <Main active={active} onClick={onClick}>
        <SVGStyles active={active}>{children}</SVGStyles>
        <Title active={active}>{title}</Title>
    </Main>
);

export default DesktopNavTab;
