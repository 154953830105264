import React from "react";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";
import WeeklyCard from "./WeeklyCard";
import NavButton from "../../../components/NavButton";
import SimpleNavbar from "../../../components/SimpleNavbar";
import Navbar from "src/components/Navbar";

interface Props {
    goBack: VoidFunction;
}

interface State {
    maxItems: number;
}

class WeeklyPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            maxItems: 25
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener("scroll", this.handleScroll);
        window.scrollTo(0, 0);
    }

    componentWillUnmount(): void {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll(): void {
        const innerHeight = window.innerHeight;
        const scrollTopBody = document.getElementsByTagName("body")[0]
            .scrollTop;
        const scrollTopDoc = document.documentElement.scrollTop;
        const scrollTop = Math.max(scrollTopBody, scrollTopDoc) + 50;
        const offsetHeight = document.documentElement.offsetHeight;
        if (
            innerHeight + scrollTop >= offsetHeight &&
            document.body.style.position !== "fixed"
        ) {
            this.setState({ maxItems: this.state.maxItems + 3 });
        }
    }

    render() {
        return (
            <>
                <Navbar back marginBottom={10} />
                <WeeklyCard />
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    goBack: () => dispatch(goBack())
});

export default connect(null, mapDispatchToProps)(WeeklyPage);
