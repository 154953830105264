import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { colors } from "src/constants";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { debounce } from "src/util";
import styled, { keyframes } from "styled-components";
import HighlightDetailsPopup from "./HighlightDetailsPopup";
import { submitEvent } from "src/events/actions";
import useInViewDispatch from "src/hooks/useInViewDispatch";

const Main = styled.div`
    display: flex;
    height: 100%;
    background-color: blue;
    margin: auto;
    position: relative;
    overflow: hidden;
`;

const InfoSection = styled.div`
    margin-top: auto;
    width: 75%;
`;

const CreatorTag = styled.div`
    background-color: ${colors.tertiaryGreen};
    border-radius: 100px;
    font-size: 10px;
    font-weight: 500;
    padding: 6px 11px;
    width: fit-content;
`;

const Name = styled.div`
    font-weight: 700;
    font-size: 18px;
    color: white;
    text-align: left;
    cursor: pointer;
    border-radius: 8px;
    :hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
`;
const TagRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;
const PayTag = styled.div`
    background-color: ${colors.white};
    font-size: 12px;
    color: ${colors.gray2};
    padding: 7px;
    border-radius: 6px;
`;

const PayAmount = styled.b`
    font-weight: 700;
    color: ${colors.black};
`;

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26px;
    margin-left: auto;
    width: fit-content;
    margin-top: auto;
`;

const SidebarIcon = styled.img`
    width: 44px;
    height: 44px;
    border-radius: 4px;
    cursor: pointer;
    object-fit: cover;
`;

const Profile = styled(SidebarIcon)`
    border-radius: 100px;
    border: 1px solid white;
    cursor: default;
`;

const flutter = keyframes`
from {
    transform: translate(0, 10vh);
    opacity: 100;
}
to{
    transform: translate(0,-900px);
    opacity: 0;
}
`;

const FlyingHeart = styled.img`
    display: flex;
    animation-duration: 4s;
    opacity: 0;
    animation: ${flutter} 4s linear forwards;
    position: absolute;
`;
const MediaWrapper = styled.div`
    background-color: ${colors.grayBlack};
    height: 100%;
    /* flex: 1; */
    width: 100%;
    overflow: hidden;
`;

const VideoWrapper = styled.div`
    width: fit-content;
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: auto;
    max-width: 100vw;
    & > video {
        display: block;
        height: 100%;
        margin: auto;
        object-fit: contain;
        max-width: 100vw;
    }
`;
const Overlay = styled.div`
    display: flex;
    position: absolute;
    padding: 15px;
    bottom: 0;
    gap: 30px;
    width: 100%;
`;

interface Props {
    highlight: import("./types").HighlightV2;
    muted: boolean;
    setMuted: (val: boolean) => void;
}

const Highlight = ({ highlight, muted, setMuted }: Props) => {
    const dispatch = useDispatch();
    let timer: NodeJS.Timeout | null = null;
    const [liked, setLiked] = useState(false);
    const [heartsActive, setHeartsActive] = useState(false);
    const hash =
        highlight.publisher.username +
        highlight.creative.createdAt +
        highlight.challenge.id;

    const favoriteHighlight = async () => {
        try {
            liked
                ? await localStorage.removeItem(`highlightLiked${hash}`)
                : await localStorage.setItem(`highlightLiked${hash}`, "true");
            if (!liked) {
                submitEvent({
                    event_name: "highlight_liked",
                    event_extra: {
                        creative: highlight.creative
                    }
                });
                setHeartsActive(true);
                setTimeout(() => setHeartsActive(false), 5000);
            }
            setLiked(!liked);
        } catch (err) {
            dispatch(
                setPillNotificationText("Failed to like highlight", "danger")
            );
        }
    };

    const storedLike = localStorage.getItem(`highlightLiked${hash}`);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const { ref, inView } = useInView();
    const inViewRef = useInViewDispatch(
        submitEvent({
            event_name: "highlight_viewed",
            event_extra: {
                creative: highlight.creative
            }
        })
    );

    useEffect(() => {
        if (storedLike !== undefined) setLiked(storedLike === "true");
    }, [storedLike]);

    useEffect(() => {
        if (inView) {
            videoRef?.current?.play();
            if (!heartsActive) {
                setHeartsActive(true);
                setTimeout(() => setHeartsActive(false), 5000);
            }
        } else videoRef.current?.pause();
    }, [inView, videoRef]);

    function renderHearts() {
        const hearts: ReactNode[] = [];
        const runs = 5 + Math.random() * 20;
        for (let i = 0; i < runs; i++) {
            hearts.push(
                <FlyingHeart
                    src={
                        i % 2 === 0
                            ? "/highlights/heart-emoji.svg"
                            : "/highlights/money-mouth.svg"
                    }
                    style={{
                        animationDelay: `${i * 0.1 + Math.random() - 0.5}s`,
                        right: `${Math.random() * 50}px`
                    }}
                />
            );
        }
        return hearts;
    }

    return (
        <Main>
            <MediaWrapper ref={inViewRef}>
                <VideoWrapper ref={ref}>
                    <video
                        muted={muted}
                        autoPlay={false}
                        loop
                        ref={videoRef}
                        onClick={e => {
                            const target = e.target as HTMLVideoElement;
                            target.paused ? target.play() : target.pause();
                        }}
                        src={highlight.creative.url || ""}
                    />
                    <Overlay>
                        <InfoSection>
                            {/* v2 <CreatorTag>🤑 TOP CREATOR</CreatorTag> */}
                            <Name onClick={() => setDetailsOpen(true)}>
                                {highlight.challenge.title}
                            </Name>
                            {/* v2
                 <TagRow>
                    <PayTag>
                        Creator payout: <PayAmount>$100</PayAmount>
                    </PayTag>
                    <PayTag>
                        Bonus: <PayAmount>5%</PayAmount>
                    </PayTag>
                </TagRow> */}
                        </InfoSection>
                        <Sidebar>
                            <SidebarIcon
                                onClick={() => setMuted(!muted)}
                                src={
                                    muted
                                        ? "/video-controls/volume-xmark-solid.svg"
                                        : "/video-controls/volume-high-solid.svg"
                                }
                            />
                            <Profile src={highlight.publisher.icon} />

                            <SidebarIcon
                                src={
                                    liked
                                        ? "/highlights/heart_filled.svg"
                                        : "/highlights/heart_empty.svg"
                                }
                                onClick={favoriteHighlight}
                            />
                            {heartsActive && renderHearts()}
                            <SidebarIcon src={highlight.challenge.icon} />
                        </Sidebar>
                    </Overlay>
                </VideoWrapper>
            </MediaWrapper>
            {detailsOpen && (
                <HighlightDetailsPopup
                    onClose={() => setDetailsOpen(false)}
                    icon={highlight.campaign.icon}
                    description={highlight.campaign.description}
                    title={highlight.challenge.title}
                    campaignName={highlight.campaign.name}
                    rating={highlight.creative.performance}
                />
            )}
        </Main>
    );
};
export default Highlight;
