import {
    BaseOptions,
    BooleanOptions,
    DateFormat,
    DateOptions,
    NumberOptions,
    TableCell
} from "./types";

export const createLinkCell = (
    value?: string | null,
    href?: string | null,
    options?: BaseOptions
): TableCell => {
    if (!value || !href) {
        return null;
    }
    return {
        type: "link",
        href: href,
        data: value,
        isNull: options?.isNull,
        style: options?.style
    };
};
export const createStringCell = (
    value?: string | null,
    options?: BaseOptions
): TableCell => {
    if (!value) {
        return null;
    }
    return {
        type: "string",
        data: value,
        isNull: options?.isNull,
        style: options?.style
    };
};
export const createNumberCell = (
    value?: number | null,
    options?: NumberOptions
): TableCell => {
    if (value === null) {
        return null;
    }

    return {
        type: "number",
        data: value || 0,
        money: options?.money,
        separator: options?.separator,
        isNull: options?.isNull,
        style: options?.style
    };
};
export const createButtonCell = (
    handleClick: VoidFunction,
    value?: string | null,
    options?: BaseOptions
): TableCell => {
    if (!value) {
        return null;
    }
    return {
        type: "button",
        handleClick,
        data: value,
        isNull: options?.isNull,
        style: options?.style
    };
};
export const createDateCell = (
    value?: string | null,
    options?: DateOptions
): TableCell => {
    if (!value || !Date.parse(value)) {
        return null;
    }
    return {
        type: "date",
        data: value,
        format: options?.format || "MMM DD, YYYY, TT:TT",
        isNull: options?.isNull,
        style: options?.style
    };
};
export const createBooleanCell = (
    value?: boolean | null,
    options?: BooleanOptions
): TableCell => {
    if (value === null || value === undefined) {
        return null;
    }
    return {
        type: "boolean",
        data: value,
        isIcon: options?.isIcon,
        isNull: options?.isNull,
        style: options?.style
    };
};
export const createCustomCell = (
    component: JSX.Element,
    data?: any,
    options?: BaseOptions
): TableCell => {
    if (!component) {
        return null;
    }
    return {
        type: "custom",
        data,
        component,
        isNull: options?.isNull,
        style: options?.style
    };
};
export const createCell = {
    number: createNumberCell,
    string: createStringCell,
    link: createLinkCell,
    button: createButtonCell,
    date: createDateCell,
    boolean: createBooleanCell,
    custom: createCustomCell
};

export const formatTableDate = (
    date: string,
    format: DateFormat
): string | null => {
    if (Date.parse(date)) {
        switch (format) {
            case "MM/DD/YYYY":
                return new Date(date).toLocaleDateString("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit"
                });
            case "MM/DD":
                return new Date(date).toLocaleDateString("en-us", {
                    month: "2-digit",
                    day: "2-digit"
                });
            case "MM/DD/YYYY, TT:TT":
                return new Date(date).toLocaleDateString("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "numeric"
                });
            case "MMM DD, YYYY":
                return new Date(date).toLocaleDateString("en-us", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit"
                });
            case "MMM DD, YYYY, TT:TT":
                return new Date(date).toLocaleDateString("en-us", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "numeric"
                });
            default:
                return date;
        }
    } else {
        return null;
    }
};
/*Conditions for input*/
const isNumber = (val: any) => {
    return !isNaN(parseFloat(val)) && !isNaN(val - 0);
};
const isEmptyStr = (val: string) => val === "";
const isNotSpace = (val: string) => {
    const str = val.toString();
    return !str.includes(" ");
};

/*Function to check if input value meets all strict conditional functions and
   at least one optional conditional function */
const isValid = (val: string, [...strictFuncs], [...conditionalFuncs]) => {
    const isStrict = strictFuncs.map(e => e(val)).every(e => e === true);
    const areConditionsMet = conditionalFuncs.map(e => e(val)).includes(true);
    return isStrict && areConditionsMet ? true : false;
};
export const isNumberInputValid = (val: string, allowDecimals?: boolean) => {
    return isValid(val, [isNotSpace], [isEmptyStr, isNumber]);
};
