import { AnimatePresence } from "framer-motion";
import moment from "moment";
import React from "react";
import FullScreenModal from "src/modals/FullScreenModal";
import AnalyticsDatePicker from "../AnalyticsDatePicker";
import AnalyticsDateRanges, {
    AnalyticsDateRangeObject
} from "../AnalyticsDateRanges";
import { AnalyticsFullscreenState, AnalyticsScrollTo } from "../AnalyticsMain";
import { ModalProps } from "../utils/props";

interface Props extends ModalProps {
    totalDateRange: AnalyticsDateRangeObject;
    totalStartDate: moment.Moment | null;
    totalEndDate: moment.Moment | null;
    setTotalDateRange: (dateRange: AnalyticsDateRangeObject) => void;
    setTotalStartDate: (date: moment.Moment | null) => void;
    setTotalEndDate: (date: moment.Moment | null) => void;
}

const TotalEarningsDatePicker = ({
    open,
    closeModal,
    showMore,
    setScrollTo,
    setFullscreenState,
    totalDateRange,
    totalStartDate,
    totalEndDate,
    setTotalDateRange,
    setTotalStartDate,
    setTotalEndDate
}: Props) => {
    return (
        <AnimatePresence>
            {open && (
                <FullScreenModal closeModal={closeModal}>
                    <AnalyticsDatePicker
                        onCancelTapped={() => {
                            setScrollTo(AnalyticsScrollTo.totalEarnings);
                            showMore
                                ? setFullscreenState(
                                      AnalyticsFullscreenState.totalEarningsMore
                                  )
                                : setFullscreenState(
                                      AnalyticsFullscreenState.default
                                  );
                        }}
                        onDoneTapped={(args: {
                            dateRange: AnalyticsDateRangeObject;
                            startDate: moment.Moment | null;
                            endDate: moment.Moment | null;
                        }) => {
                            setTotalDateRange(args.dateRange);
                            setTotalStartDate(args.startDate);
                            setTotalEndDate(args.endDate);
                            setScrollTo(AnalyticsScrollTo.totalEarnings);
                            showMore
                                ? setFullscreenState(
                                      AnalyticsFullscreenState.totalEarningsMore
                                  )
                                : setFullscreenState(
                                      AnalyticsFullscreenState.default
                                  );
                        }}
                        initialDateRange={totalDateRange}
                        initialStartDate={totalStartDate}
                        initialEndDate={totalEndDate}
                        dateRangeArray={AnalyticsDateRanges}
                    />
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default TotalEarningsDatePicker;
