import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { customReasonModal } from "src/ui/modals/actions";
import {
    fetchLinkForPlug,
    fetchLinkForPlugSuccess,
    fetchLinkParams
} from "src/links/actions";
import { Media, PlugLink } from "../../../media/types";
import styled from "styled-components";
import MediaCard from "../../../media/view/MediaCard";
import CustomMediaBadges from "../../../media/view/CustomMediaBadges";
import { submitEvent } from "../../../events/actions";
import useInViewDispatch from "../../../hooks/useInViewDispatch";
import ShareButton from "../../../components/ShareButton";
import { currentMediaTab } from "src/campaigns/selectors";
import RemoveBtn from "src/components/RemoveBtn";
import { selectAdminStatus } from "src/auth/selectors";
import { scriptMediaDelete } from "src/campaigns/scripts/actions";
import { isScriptModalOpen } from "src/campaigns/scripts/selectors";
import { GlobalState } from "src/reducers";
import ShowIcon from "src/components/ShowIcon";
const StoryCardContainer = styled.div`
    padding: 5px;
`;
const StyledRemoveBtn = styled(RemoveBtn)`
    position: absolute;
    top: 8px;
    left: 8px;
`;

const StyledShowIcon = styled(ShowIcon)`
    position: absolute;
    top: 38px;
    right: 8px;
`;

interface Props {
    media: Media;
    linkPrefix: string;
    linkService: string;
    plugLink?: PlugLink | null;
    fetchLinkForPlug: (params: fetchLinkParams) => void;
    fetchLinkForPlugSuccess: (link: PlugLink) => void;
    customReasonModal: (media: Media) => void;
    onMediaClicked: () => void;
    mediaHeight?: number;
    rank: number;
    isScriptModal?: boolean;
}

const StoryCard = ({
    media,
    linkPrefix,
    linkService,
    plugLink,
    fetchLinkForPlug,
    fetchLinkForPlugSuccess,
    customReasonModal,
    onMediaClicked,
    mediaHeight,
    rank,
    isScriptModal
}: Props) => {
    const dispatch = useDispatch();
    const ref = useInViewDispatch(
        submitEvent({
            event_name: "ad_viewed",
            event_extra: {
                mediaId: media.id,
                campaignId: media.campaignId,
                rank
            },
            value: rank
        })
    );

    const mediaTabState = useSelector(currentMediaTab);
    const isCampaignDetails = window.location.href.includes("campaign/");
    const isAdmin = useSelector(selectAdminStatus);
    const scriptId = useSelector(
        (state: GlobalState) => state.ui.script.scriptModal
    );

    return (
        <StoryCardContainer ref={ref}>
            <MediaCard
                playIcon
                media={media}
                linkPrefix={linkPrefix}
                mediaHeight={mediaHeight}
                mediaCallback={onMediaClicked}
                buttonCallbacks={{
                    linkCallback: () => {
                        if (plugLink) {
                            fetchLinkForPlugSuccess(plugLink);
                        } else {
                            fetchLinkForPlug({
                                ...media,
                                externalPageId:
                                    linkService === "inHouseClickTracking"
                                        ? media.externalPageId
                                        : null
                            });
                        }
                    }
                }}
                buttonDisabled={
                    media.mediaStatus === "pending" ||
                    media.mediaStatus === "rejected"
                }
                buttonHideLink={isScriptModal && !isCampaignDetails}
            >
                <ShareButton
                    link={`/campaign/${media.campaignId}?mediaId=${media.id}`}
                />
                {isScriptModal && isAdmin && scriptId && (
                    <StyledRemoveBtn
                        handleClick={() => {
                            dispatch(scriptMediaDelete(scriptId, media.id));
                        }}
                    />
                )}

                {!media.shouldShowInApp && <StyledShowIcon />}
                {mediaTabState === "Yours" && (
                    <CustomMediaBadges
                        media={media}
                        customReasonCallback={() => customReasonModal(media)}
                    />
                )}
            </MediaCard>
        </StoryCardContainer>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    customReasonModal: (media: Media) => dispatch(customReasonModal(media)),
    fetchLinkForPlug: (params: fetchLinkParams) =>
        dispatch(fetchLinkForPlug(params)),
    fetchLinkForPlugSuccess: (link: PlugLink) =>
        dispatch(fetchLinkForPlugSuccess(link))
});

export default connect(null, mapDispatchToProps)(StoryCard);
