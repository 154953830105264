import { useSlate } from "slate-react";
import React, { ReactNode } from "react";
import { BtnWrapper } from "./Btn";
import CustomEditor from "../CustomEditor";

interface Props {
    isActive?: boolean;
    children?: ReactNode;
    format: string;
}

const MarkBtn = ({ children, format }: Props) => {
    const editor = useSlate();
    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        CustomEditor.toggleMark(editor, format);
    };
    return (
        <BtnWrapper
            type="button"
            isActive={CustomEditor.isMarkActive(editor, format)}
            onMouseDown={handleClick}
        >
            {children}
        </BtnWrapper>
    );
};
export default MarkBtn;
