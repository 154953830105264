import { ChildInstructionStep, ChildInstructionSteps } from "./../types";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { InstructionStepState } from "src/ui/instructions-sets/actions";
import { InstructionStep } from "../types";
import {
    InstructionElementArrayKey,
    InstructionElementKey
} from "../../utils/types";
import { NormalizedInstructionStepsResponse } from "../actions.instruction-steps";

function _setUploadSubmissionFields(
    formattedInstructionsStep: InstructionStep,
    instructionStep: InstructionStepState
): void {
    formattedInstructionsStep["requiresCustomMedia"] =
        instructionStep.requiresCustomMedia;

    formattedInstructionsStep["requiresScreenshot"] =
        instructionStep.requiresScreenshot;

    formattedInstructionsStep["requiresLinkSubmission"] =
        instructionStep.requiresLinkSubmission;

    formattedInstructionsStep["requiresTextSubmission"] =
        instructionStep.requiresTextSubmission;

    formattedInstructionsStep["requiresRawMedia"] =
        instructionStep.requiresRawMedia;
}

function _setParentInstructionAttributes(
    formattedInstructionsStep: InstructionStep,
    instructionStep: InstructionStepState,
    position: number,
    isTemplate?: boolean
): void {
    if (!!instructionStep.id) {
        formattedInstructionsStep["id"] = instructionStep.id;
    }

    formattedInstructionsStep["description"] = instructionStep.description;

    formattedInstructionsStep["position"] = position;

    formattedInstructionsStep["mediaId"] = instructionStep.media[0] || null;
    if (!isTemplate)
        formattedInstructionsStep["scriptId"] =
            instructionStep.scripts[0] || null;
    else formattedInstructionsStep["scriptId"] = null;

    formattedInstructionsStep["assetId"] = instructionStep.assets[0] || null;

    formattedInstructionsStep["linkUrl"] = instructionStep.customLink;

    if (instructionStep.customLink) {
        formattedInstructionsStep["linkAction"] = "open";
    }

    formattedInstructionsStep["requiresPlugLink"] =
        instructionStep.requiresPlugLink;

    formattedInstructionsStep["plugLinkSkipLandingPage"] =
        instructionStep.plugLinkSkipLandingPage;
}

function _generateLongestInstructionStepIdCount(
    instructionStep: InstructionStepState
): number {
    let longestInstructionStepIdCount = 0;

    const instructionStepKeys: InstructionElementArrayKey[] = [
        "scripts",
        "media",
        "assets"
    ];

    // Sets the longest instruction step id count, which is needed to know how many
    //  times we need to iterate to create the nested child instruction steps.
    instructionStepKeys.forEach((key: InstructionElementArrayKey) => {
        const currentInstructionStepIdArray = instructionStep[key];
        if (
            currentInstructionStepIdArray.length > longestInstructionStepIdCount
        ) {
            longestInstructionStepIdCount =
                currentInstructionStepIdArray.length;
        }
    });

    return longestInstructionStepIdCount;
}

function _setNestedChildInstructionStepProperties(
    instructionStep: InstructionStepState,
    index: number,
    nestedChildInstructionSteps: ChildInstructionSteps,
    nestedChildInstructionStep: ChildInstructionStep,
    isTemplate?: boolean
): void {
    const currentScriptId = instructionStep.scripts[index];
    const currentMediaId = instructionStep.media[index];
    const currentAssetId = instructionStep.assets[index];
    if (!isTemplate && currentScriptId) {
        nestedChildInstructionStep["scriptId"] = currentScriptId;
    } else nestedChildInstructionStep["scriptId"] = null;

    if (currentMediaId) {
        nestedChildInstructionStep["mediaId"] = currentMediaId;
    } else {
        nestedChildInstructionStep["mediaId"] = null;
    }

    if (currentAssetId) {
        nestedChildInstructionStep["assetId"] = currentAssetId;
    } else {
        nestedChildInstructionStep["assetId"] = null;
    }

    nestedChildInstructionSteps.push(nestedChildInstructionStep);
}

function _generateNestedInstructionSteps(
    entitiesInstructionSteps: NormalizedInstructionStepsResponse,
    longestInstructionStepIdCount: number,
    instructionStep: InstructionStepState,
    isTemplate?: boolean
): ChildInstructionSteps {
    const mapIteratorCount: string[] = Array(
        longestInstructionStepIdCount
    ).fill("");

    const nestedChildInstructionSteps: ChildInstructionSteps = [];

    if (instructionStep.id) {
        const currentEntitiesInstructionStep =
            entitiesInstructionSteps[instructionStep.id];

        const childInstructionSteps =
            currentEntitiesInstructionStep?.instructionSteps;

        mapIteratorCount.forEach((_: string, index: number) => {
            // Index = 0 represents the ids (e.g. scriptId, mediaId, assetId, etc.), which already
            // exist in the parent instruction step.
            if (index > 0) {
                const nestedChildInstructionStep: ChildInstructionStep = {};

                if (childInstructionSteps && childInstructionSteps.length > 0) {
                    const currentStepId = childInstructionSteps[index - 1]?.id;
                    if (currentStepId) {
                        nestedChildInstructionStep["id"] = currentStepId;
                    }
                }

                _setNestedChildInstructionStepProperties(
                    instructionStep,
                    index,
                    nestedChildInstructionSteps,
                    nestedChildInstructionStep,
                    isTemplate
                );
            }
        });

        if (childInstructionSteps && childInstructionSteps.length > 0) {
            for (
                let index = mapIteratorCount.length - 1;
                index < childInstructionSteps.length;
                index++
            ) {
                const currentStepId = childInstructionSteps[index]?.id;
                const nestedChildInstructionStep: ChildInstructionStep = {};
                if (currentStepId) {
                    nestedChildInstructionStep["id"] = currentStepId;
                }
                nestedChildInstructionSteps.push(nestedChildInstructionStep);
            }
        }
    } else {
        mapIteratorCount.forEach((_: string, index: number) => {
            if (index > 0) {
                const nestedChildInstructionStep: ChildInstructionStep = {};

                _setNestedChildInstructionStepProperties(
                    instructionStep,
                    index,
                    nestedChildInstructionSteps,
                    nestedChildInstructionStep,
                    isTemplate
                );
            }
        });
    }

    return nestedChildInstructionSteps;
}

// Formatting Redux instruction step data to interface with API.
export function useConvertReduxDataToAPIData(
    isTemplate?: boolean
): InstructionStep[] {
    const entitiesInstructionSteps = useSelector((state: GlobalState) => {
        return state.entities.instructionsSetSteps.byId;
    });

    const instructionSteps = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets.instructionSteps;
    });

    const formattedInstructionSteps = instructionSteps.map(
        (instructionStep: InstructionStepState, index: number) => {
            const formattedInstructionsStep: InstructionStep = {} as InstructionStep;
            const position = index + 1;

            _setUploadSubmissionFields(
                formattedInstructionsStep,
                instructionStep
            );

            _setParentInstructionAttributes(
                formattedInstructionsStep,
                instructionStep,
                position,
                isTemplate
            );

            const longestInstructionStepIdCount = _generateLongestInstructionStepIdCount(
                instructionStep
            );

            const nestedChildInstructionSteps = _generateNestedInstructionSteps(
                entitiesInstructionSteps,
                longestInstructionStepIdCount,
                instructionStep,
                isTemplate
            );

            formattedInstructionsStep[
                "instructionSteps"
            ] = nestedChildInstructionSteps;

            return formattedInstructionsStep;
        }
    );

    return formattedInstructionSteps;
}
