import moment from "moment";
import { colors } from "src/constants";
import { DirectBuyStepsExpandedState } from "src/ui/direct-buys/reducer";
import {
    DirectBuyPayment,
    DirectBuyPaymentCollection
} from "../direct-buy-payment/types";
import { DirectBuyStepCollection } from "../direct-buy-step/types";
import { DirectBuyStatus } from "../direct-buy/types";
import { StepById } from "./reducer";
import {
    OpenBuy,
    OpenBuyInstructionStep,
    OpenBuyState,
    OpenBuySubmission,
    OpenBuySubmissionSet,
    OpenBuySubmissionStatuses
} from "./types";
import styled from "styled-components";
import { Media } from "src/media/types";

interface OpenBuyPreNormalize extends Omit<OpenBuy, "instructionSteps"> {
    instructionSteps: OpenBuyInstructionStep[];
}

export function flattenOpenBuyForNormalize(data: OpenBuyPreNormalize[]) {
    let opens: OpenBuyPreNormalize[] = [];
    data.map((buy: OpenBuyPreNormalize) => {
        opens.push({
            ...buy,
            duration: buy?.instructionSet?.duration,
            id: buy.openBuyId || buy.id,
            instructionSteps: buy?.instructionSet?.instructionSteps
        });
    });
    opens.sort((a, b) => (moment(a.endsAt) > moment(b.endsAt) ? -1 : 1));
    return opens;
}

export function isOpenBuyExpired(openBuy: OpenBuy) {
    if (!openBuy?.endsAt) {
        return false;
    }
    return new Date(openBuy.endsAt).getTime() < Date.now();
}

export function openBuyInProgress(
    openBuy: OpenBuy,
    submissions?: OpenBuySubmission[]
) {
    const publisherId = localStorage.getItem("userid");
    if (openBuy.openBuySubmissions?.length === 0) {
        return 0;
    } else {
        let count = 0;
        submissions?.forEach(submission => {
            if (
                submission?.status === OpenBuySubmissionStatuses.pending &&
                submission?.publisherId === parseInt(publisherId || "0")
            ) {
                count++;
            }
        });
        return count;
    }
}

export function openBuyCompleted(
    openBuy: OpenBuy,
    submissions?: OpenBuySubmission[]
) {
    const publisherId = localStorage.getItem("userid");
    if (openBuy.openBuySubmissions?.length === 0 || openBuy.endsAt === null) {
        return 0;
    } else {
        let count = 0;
        submissions?.forEach(submission => {
            if (
                submission?.status !== OpenBuySubmissionStatuses.rejected &&
                submission?.publisherId === parseInt(publisherId || "0")
            ) {
                count++;
            }
        });
        return count;
    }
}

function openBuyRevisionRequested(
    openBuy: OpenBuy,
    submissions?: OpenBuySubmission[]
) {
    if (openBuy?.openBuySubmissions?.length === 0) {
        return 0;
    } else {
        let count = 0;
        submissions?.forEach(submission => {
            if (
                submission.status ===
                OpenBuySubmissionStatuses.revision_requested
            ) {
                count++;
            }
        });
        return count;
    }
}

export function openBuyRejected(
    openBuy: OpenBuy,
    submissions?: OpenBuySubmission[]
) {
    if (openBuy?.openBuySubmissions?.length === 0) {
        return 0;
    } else {
        let count = 0;
        submissions?.forEach(submission => {
            if (submission.status === OpenBuySubmissionStatuses.rejected) {
                count++;
            }
        });
        return count;
    }
}

export function openBuyApprovedCount(
    openBuy: OpenBuy,
    submissions?: OpenBuySubmission[],
    steps?: OpenBuyInstructionStep[]
) {
    let approvedCount = 0;
    submissions?.forEach(submission => {
        if (submission.status === OpenBuySubmissionStatuses.approved) {
            approvedCount++;
        }
    });

    const actionSteps = steps?.filter(step => {
        return (
            step.requiresCustomMedia ||
            step.requiresScreenshot ||
            step.requiresLinkSubmission ||
            step.requiresTextSubmission
        );
    });
    const stepCount = actionSteps?.length || 0;

    if (approvedCount === 0) return false;
    return stepCount - approvedCount;
}

export function getStateForOpenBuy(
    openBuy: OpenBuy,
    submissions?: OpenBuySubmission[],
    steps?: OpenBuyInstructionStep[]
): OpenBuyState {
    if (isOpenBuyExpired(openBuy)) return OpenBuyState.ended;
    if (!!openBuyRejected(openBuy, submissions)) return OpenBuyState.rejected;
    if (!!openBuyRevisionRequested(openBuy, submissions))
        return OpenBuyState.revision_requested;
    if (!!openBuyInProgress(openBuy, submissions)) return OpenBuyState.pending;
    if (!!openBuyApprovedCount(openBuy, submissions, steps))
        return OpenBuyState.approved_next_step;
    if (openBuyApprovedCount(openBuy, submissions, steps) === 0)
        return OpenBuyState.approved;
    if (openBuy.viewedAt) return OpenBuyState.viewed;
    return OpenBuyState.new;
}

export function getStateForOpenBuyAsDirectBuyStatus(
    openBuy: OpenBuy,
    submissions?: OpenBuySubmission[]
): DirectBuyStatus {
    if (!!openBuyInProgress(openBuy, submissions))
        return DirectBuyStatus.active;

    if (!!openBuyRejected(openBuy, submissions)) return DirectBuyStatus.active;

    if (isOpenBuyExpired(openBuy)) return DirectBuyStatus.expired;

    if (openBuy.viewedAt) return DirectBuyStatus.available;

    return DirectBuyStatus.available;
}

export function getTagInfoForOpenBuyState(
    openBuyState: OpenBuyState
): [string | null, string | null] {
    switch (openBuyState) {
        case OpenBuyState.new:
            return [colors.primaryBlue, "NEW"];
        case OpenBuyState.viewed:
            return [colors.primaryBlue, "ACTIVE"];
        case OpenBuyState.pending:
            return [colors.primaryYellow, "PENDING"];
        case OpenBuyState.rejected:
            return [colors.primaryRed, "ERROR"];
        case OpenBuyState.ended:
            return [colors.primaryGray, "ENDED"];
        default:
            return [null, null];
    }
}

export function getOpenBuyPayments(
    openBuy: OpenBuy | OpenBuyInstructionStep,
    steps: StepById
): DirectBuyPaymentCollection[] {
    let payments: DirectBuyPaymentCollection[] = [];
    if (!openBuy) return [];
    openBuy.instructionSteps?.forEach(step => {
        if (steps[step].instructionPayments) {
            payments.push({
                payment: {
                    directBuyPayments: steps[step].instructionPayments,
                    paymentConjunction: steps[step].paymentConjunction,
                    payoutTiming: steps[step].payoutTiming
                },
                transaction: null
            });
        } else if (steps[step].instructionSteps) {
            payments = payments.concat(getOpenBuyPayments(steps[step], steps));
        }
    });
    return payments;
}

export function getOpenBuyStepCollectionArrayForOpenBuy(
    openBuy: OpenBuy,
    steps: StepById,
    openBuyStepsExpandedState: DirectBuyStepsExpandedState
): DirectBuyStepCollection[] {
    let stepCollectionArray: DirectBuyStepCollection[] = [];
    const parentSteps =
        openBuy &&
        getOpenBuyStepsForParentObject(openBuy.instructionSteps || [], steps);
    if (parentSteps) {
        for (const step of parentSteps) {
            stepCollectionArray = stepCollectionArray.concat(
                getOpenBuyStepCollectionArrayForOpenBuyStep(
                    step,
                    parentSteps,
                    openBuyStepsExpandedState
                )
            );
        }
    }
    return stepCollectionArray;
}

export function getOpenBuyStepCollectionArrayForOpenBuyStep(
    openBuyStep: OpenBuy | OpenBuyInstructionStep,
    openBuyStepsState: StepById,
    openBuyStepsExpandedState: DirectBuyStepsExpandedState,
    indent: number = 0
): DirectBuyStepCollection[] {
    const children = getOpenBuyStepsForParentObject(
        openBuyStep.instructionSteps || [],
        openBuyStepsState
    );
    if (children && children.every(step => openBuyStepIsCollapsible(step))) {
        return [
            {
                indent,
                parent: openBuyStep.id,
                children: openBuyStep.instructionSteps,
                combined: [
                    openBuyStep.id,
                    ...(openBuyStep.instructionSteps || [])
                ]
            }
        ];
    } else if (children && openBuyStepsExpandedState[openBuyStep.id]) {
        let array: DirectBuyStepCollection[] = [
            {
                indent,
                parent: openBuyStep.id,
                combined: [openBuyStep.id]
            }
        ];
        for (const step of children) {
            array = array.concat(
                getOpenBuyStepCollectionArrayForOpenBuyStep(
                    step,
                    openBuyStepsState,
                    openBuyStepsExpandedState,
                    indent + 1
                )
            );
        }
        return array;
    } else {
        return [
            {
                indent,
                parent: openBuyStep.id,
                combined: [openBuyStep.id]
            }
        ];
    }
}

function getOpenBuyStepsForParentObject(
    stepIds: number[],
    openBuySteps: StepById
): OpenBuyInstructionStep[] | null {
    if (!stepIds) {
        return null;
    }
    const childSteps: OpenBuyInstructionStep[] = stepIds.map(
        stepId => openBuySteps[stepId]
    );
    if (childSteps.length === 0) {
        return null;
    }
    return childSteps;
}

export function openBuyStepIsExpandable(
    openBuyStep: OpenBuyInstructionStep,
    openBuyStepsState: StepById
): boolean {
    const childSteps = getOpenBuyStepsForParentObject(
        openBuyStep.instructionSteps,
        openBuyStepsState
    );
    if (!childSteps) {
        return false;
    }
    const allCollapsible = childSteps.every(step =>
        openBuyStepIsCollapsible(step)
    );
    return !allCollapsible;
}

export function openBuySupplementsForStep(openBuy: OpenBuy, stepId: number) {
    if (!openBuy.openBuySupplements) return [];
    return openBuy.openBuySupplements.filter(
        supplement => supplement.instructionStepId === stepId
    );
}

function openBuyStepIsCollapsible(
    openBuyStep: OpenBuyInstructionStep
): boolean {
    return !openBuyStep?.instructionSteps;
}

export function getPaymentTypeForSubmission(payments: DirectBuyPayment[]) {
    let length = payments.length || 0;
    let isBar = length > 1 ? true : false;
    payments.forEach((payment, index) => {
        if (payment.payment.type !== "flat_rate") {
            isBar = false;
        }
    });
    return isBar;
}

export function filterOpenBuyByStatus(
    statusFilters: DirectBuyStatus[],
    openBuy: OpenBuy,
    submissions: OpenBuySubmission[]
) {
    return statusFilters.includes(
        getStateForOpenBuyAsDirectBuyStatus(openBuy, submissions)
    );
}

export function getStatusBody(submission: Partial<OpenBuySubmission>) {
    if (submission.status === OpenBuySubmissionStatuses.revision_requested)
        return (
            submission.revisionRequest ||
            "Contact support for revision instructions"
        );
    else if (submission.status === OpenBuySubmissionStatuses.rejected)
        return submission.rejectionReason || "Rejection reason not provided";
    else return "Please wait while we review your submissions.";
}

export function getStatusText(
    submission: Partial<OpenBuySubmission>,
    payoutTotal = 0
) {
    if (submission.status === OpenBuySubmissionStatuses.pending) {
        return "Pending approval";
    } else if (
        submission.status === OpenBuySubmissionStatuses.revision_requested
    ) {
        return "Revision Requested";
    } else if (
        submission.status === OpenBuySubmissionStatuses.approved &&
        payoutTotal
    ) {
        return `${payoutTotal} Paid`;
    } else if (submission.status === OpenBuySubmissionStatuses.next_step) {
        return "Ready for next step";
    } else if (submission.status === OpenBuySubmissionStatuses.rejected) {
        return "Rejected";
    } else return "Approved";
}

export function getStatusColor(
    submission: Partial<OpenBuySubmission>,
    payoutTotal = 0,
    transparent?: boolean
) {
    if (submission.status === OpenBuySubmissionStatuses.pending) {
        return transparent ? colors.lightYellow : colors.secondaryYellow;
    } else if (
        submission.status === OpenBuySubmissionStatuses.revision_requested
    ) {
        return transparent ? colors.lightRed : colors.primaryRed;
    } else if (
        submission.status === OpenBuySubmissionStatuses.approved &&
        payoutTotal
    ) {
        return transparent ? colors.quaternaryGreen : colors.secondaryGreen;
    } else if (submission.status === OpenBuySubmissionStatuses.next_step) {
        return transparent ? colors.lightestBlue : colors.primaryBlue;
    } else if (submission.status === OpenBuySubmissionStatuses.rejected) {
        return transparent ? colors.lightRed : colors.primaryRed;
    } else return transparent ? colors.lightestBlue : colors.primaryBlue;
}

export function getMostRelevantSubmission(
    sets: OpenBuySubmissionSet[],
    submissions: OpenBuySubmission[]
) {
    const bucketOrder = [
        "rejected",
        "revision_requested",
        "next_step",
        "pending",
        "approved"
    ];
    let bucketed: { [id: string]: Partial<OpenBuySubmission>[] } = {
        [OpenBuySubmissionStatuses.rejected]: [],
        [OpenBuySubmissionStatuses.revision_requested]: [],
        [OpenBuySubmissionStatuses.next_step]: [],
        [OpenBuySubmissionStatuses.pending]: [],
        [OpenBuySubmissionStatuses.approved]: []
    };
    sets.forEach(set => {
        if (!set.submissions.length)
            bucketed[OpenBuySubmissionStatuses.next_step].push({
                status: OpenBuySubmissionStatuses.next_step,
                openBuySubmissionSetId: set.id
            });

        let submission: Partial<OpenBuySubmission> = {
            status: set.status
        };

        for (const sub of submissions) {
            if (
                sub.status === set.status ||
                (set.status === OpenBuySubmissionStatuses.next_step &&
                    sub.status === OpenBuySubmissionStatuses.pending)
            )
                submission = sub;
        }
        bucketed[set.status].push(
            set.status === OpenBuySubmissionStatuses.next_step
                ? {
                      ...submission,
                      status: OpenBuySubmissionStatuses.next_step
                  }
                : submission
        );
    });
    for (const key of bucketOrder) {
        if (bucketed[key].length > 0) return bucketed[key][0];
    }
}

export const getSetMedia = (submissions: Partial<OpenBuySubmission[]>) => {
    let media: Media | null = null;
    submissions.forEach(sub => {
        if (sub?.customMedia) media = sub.customMedia;
    });
    return media;
};
