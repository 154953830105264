import React from "react";
import styled, { css } from "styled-components";
import GenericContainer from "./GenericContainer";
import { colors, fonts, fontWeight } from "../../constants";
import { Connection } from "../types";
import EmptyConnectButton from "./EmptyConnectButton";
import { OutlineEmptyButton } from "./Buttons";
import { clearButton, clearLink } from "src/utils/styles/snippets";
import { EventInput } from "src/utils/types/form";

const Main = styled.div<{ padding?: string }>`
    ${props => props.padding && `padding: ${props.padding};`}
`;

const Box = styled(GenericContainer)<{ fullHeight?: true }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${props => (props.fullHeight ? "100%" : "170px")};
    padding: 0px 22px;
    /* padding: 18px 16px 20px; */
`;

const Text = styled.div`
    font-weight: ${fontWeight.default};
    font-size: ${fonts.subtitle}px;
    line-height: 20px;
    padding-top: 12px;
    color: ${colors.primaryGray};
`;

const buttonStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    margin-top: 20px;
    color: ${colors.primaryBlue};
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 6px;
    font-size: ${fonts.subtitle}px;
    text-align: center;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            color: ${colors.primaryBlueDark};
            background-color: ${colors.lightTeal};
            border: ${colors.darkTeal} solid 1px;
        }
    }

    &:disabled {
        color: ${colors.primaryGray};
        cursor: default;
    }
`;

const LinkButton = styled.a`
    ${clearLink()};
    ${buttonStyles};
    padding: 0 15px;
`;

const UploadButton = styled.label`
    ${buttonStyles};
    padding: 8px 16px;
`;

const UploadButtonInput = styled.input`
    display: none;
`;

interface Props {
    icon: JSX.Element;
    text: string;
    button?: {
        text: string;
        callback: VoidFunction;
    };
    connection?: Connection;
    padding?: string;
    className?: string;
    customStyles?: { [key: string]: string };
    boxFullHeight?: true;
    link?: {
        text: string;
        url: string;
    };
    upload?: {
        text: string;
        uploadHandler: (event: EventInput) => void;
    };
}

const EmptyCard = ({
    icon,
    text,
    button,
    connection,
    padding,
    className,
    customStyles,
    boxFullHeight,
    link,
    upload
}: Props) => {
    return (
        <Main className={className} padding={padding}>
            <Box fullHeight={boxFullHeight} style={customStyles}>
                {icon}
                <Text>{text}</Text>
                {upload ? (
                    <>
                        <UploadButton htmlFor="emptyCardUploadImage">
                            {upload.text}
                        </UploadButton>
                        <UploadButtonInput
                            id="emptyCardUploadImage"
                            type="file"
                            accept="image/jpeg, image/png, image/jpg"
                            onChange={upload.uploadHandler}
                        />
                    </>
                ) : connection ? (
                    <EmptyConnectButton connection={connection} />
                ) : button ? (
                    <OutlineEmptyButton onClick={button.callback}>
                        {button.text}
                    </OutlineEmptyButton>
                ) : link ? (
                    <LinkButton href={link.url} target="_blank">
                        {link.text}
                    </LinkButton>
                ) : null}
            </Box>
        </Main>
    );
};

export default EmptyCard;
