import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Connection } from "../../types";
import { push } from "connected-react-router";
import { postRecommendation, PostRecommendationParams } from "../../actions";
import styled from "styled-components";
import RecommendationCell from "./RecommendationCell";
import SectionTitle from "../../components/SectionTitle";
import { colors } from "../../../constants";
import { hideScrollbar, flex } from "src/utils/styles/snippets";
import ScrollArrows from "src/components/ScrollArrows";

const Main = styled("div")<{ wrapped?: true }>`
    ${props =>
        props.wrapped &&
        `background-color: ${colors.lightTeal};
         border-top: 1px solid ${colors.mediumTeal};
         border-bottom: 1px solid ${colors.mediumTeal};`}
`;

const Outer = styled("div")`
    ${hideScrollbar()};
    display: flex;
    width: 100%;
    overflow-x: scroll;
`;

const Inner = styled("div")`
    display: flex;
    padding: ${props => {
        return props.theme.isMobile ? `0px 15px 15px` : `0px 15px 20px`;
    }};
`;

const Header = styled("div")`
    ${flex("row", "space-between", "center")};
    padding: 0px 15px 10px;
`;

const CustomTitle = styled(SectionTitle)<{ wrapped?: true }>`
    margin: 0;
    ${props => props.wrapped && "padding-top: 10px;"}
`;

interface Props {
    recommendations: Connection[];
    wrapped?: true;
}

const RecommendationRow = ({ recommendations, wrapped }: Props) => {
    const [observer, setObserver] = useState<IntersectionObserver | null>(null);
    const outerRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    function dispatchPostRecommendations(
        params: PostRecommendationParams
    ): void {
        dispatch(postRecommendation(params));
    }

    function dispatchPush(route: string) {
        dispatch(push(route));
    }

    useEffect(() => {
        if (outerRef.current && !observer) {
            setObserver(
                new IntersectionObserver(
                    entries => {
                        entries.forEach(entry => {
                            if (entry.isIntersecting) {
                                const element = entry.target as HTMLDivElement;
                                const otherId = element.dataset.otherid;

                                if (recommendations[0] && otherId) {
                                    const selfId = recommendations[0].entity.id;

                                    dispatchPostRecommendations({
                                        selfId,
                                        otherId,
                                        event: "view"
                                    });
                                }
                            }
                        });
                    },
                    {
                        threshold: 1.0
                    }
                )
            );
        }
    }, [outerRef, observer]);

    if (recommendations.length === 0) {
        return null;
    } else {
        return (
            <Main wrapped={wrapped}>
                <Header>
                    <CustomTitle wrapped={wrapped}>Recommended</CustomTitle>
                    <ScrollArrows refElement={outerRef} />
                </Header>

                <Outer ref={outerRef}>
                    <Inner>
                        {recommendations.map(recommendation => {
                            return (
                                <RecommendationCell
                                    key={recommendation.other.id}
                                    recommendation={recommendation}
                                    postRecommendation={
                                        dispatchPostRecommendations
                                    }
                                    observer={observer}
                                    push={dispatchPush}
                                />
                            );
                        })}
                    </Inner>
                </Outer>
            </Main>
        );
    }
};

export default RecommendationRow;
