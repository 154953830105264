import { OpenBuyInstructionStep } from "src/buys/modules/open-buys/types";
import { Action } from "src/types";
import {
    PageStep,
    PageType,
    StepDeletionStatus,
    SubmissionStatus
} from "./reducers";

// Set Instructions Campaign -----------------------------------------------

export const OPEN_INSTRUCTIONS_CAMPAIGN_SELECTION_MODAL =
    "OPEN_INSTRUCTIONS_CAMPAIGN_SELECTION_MODAL";

export function openInstructionsCampaignSelectionModal(open: boolean): Action {
    return {
        type: OPEN_INSTRUCTIONS_CAMPAIGN_SELECTION_MODAL,
        payload: open
    };
}

// Set Instructions Page Type -----------------------------------------------

export const SET_INSTRUCTIONS_PAGE_TYPE = "SET_INSTRUCTIONS_PAGE_TYPE";

export function setInstructionsPageType(pageType: PageType): Action {
    return {
        type: SET_INSTRUCTIONS_PAGE_TYPE,
        payload: pageType
    };
}

// Set Instructions Page Step -----------------------------------------------

export const SET_INSTRUCTIONS_PAGE_STEP = "SET_INSTRUCTIONS_PAGE_STEP";

export function setInstructionsPageStep(pageStep: PageStep): Action {
    return {
        type: SET_INSTRUCTIONS_PAGE_STEP,
        payload: pageStep
    };
}

// Set Instructions Sets Loading -----------------------------------------------

export const SET_INSTRUCTIONS_SETS_LOADING = "SET_INSTRUCTIONS_SETS_LOADING";

export function setInstructionsSetsLoading(loading: boolean): Action {
    return {
        type: SET_INSTRUCTIONS_SETS_LOADING,
        payload: loading
    };
}

// Set Instructions Steps Loading -----------------------------------------------

export const SET_INSTRUCTIONS_STEPS_LOADING = "SET_INSTRUCTIONS_STEPS_LOADING";

export function setInstructionsStepsLoading(loading: boolean): Action {
    return {
        type: SET_INSTRUCTIONS_STEPS_LOADING,
        payload: loading
    };
}

// Set Current Instructions Set Id -----------------------------------------------

export const SET_CURRENT_INSTRUCTIONS_SET_ID =
    "SET_CURRENT_INSTRUCTIONS_SET_ID";

export function setCurrentInstructionsSetId(
    instructionSetId: number | null
): Action {
    return {
        type: SET_CURRENT_INSTRUCTIONS_SET_ID,
        payload: instructionSetId
    };
}

// Set Current Instruction Step Id -----------------------------------------------

export const SET_CURRENT_INSTRUCTION_STEP_ID =
    "SET_CURRENT_INSTRUCTION_STEP_ID";

export function setCurrentInstructionStepId(instructionStepId: number): Action {
    return {
        type: SET_CURRENT_INSTRUCTION_STEP_ID,
        payload: instructionStepId
    };
}

// Set Current Instructions Element -----------------------------------------------

export const SET_CURRENT_INSTRUCTIONS_ELEMENT =
    "SET_CURRENT_INSTRUCTIONS_ELEMENT";

export function setCurrentInstructionElement(
    instructionElement: string
): Action {
    return {
        type: SET_CURRENT_INSTRUCTIONS_ELEMENT,
        payload: instructionElement
    };
}

// Set Current Drag Target  -----------------------------------------------

export const SET_CURRENT_DRAG_TARGET_TYPE = "SET_CURRENT_DRAG_TARGET_TYPE";

export function setCurrentDragTargetType(target: string): Action {
    return {
        type: SET_CURRENT_DRAG_TARGET_TYPE,
        payload: target
    };
}

// Set Is Current Drag Target An Instruction Element  -----------------------------------------------

export const SET_IS_CURRENT_DRAG_TARGET_AN_INSTRUCTION_ELEMENT =
    "SET_IS_CURRENT_DRAG_TARGET_AN_INSTRUCTION_ELEMENT";

export function setIsCurrentDragTargetAnInstructionElement(
    isInstructionElement: boolean
): Action {
    return {
        type: SET_IS_CURRENT_DRAG_TARGET_AN_INSTRUCTION_ELEMENT,
        payload: isInstructionElement
    };
}

// Set Instructions Steps  -----------------------------------------------

export const SET_INSTRUCTION_STEPS = "SET_INSTRUCTION_STEPS";

export interface InstructionStepState {
    id?: number;
    position?: number;
    description: string;
    scripts: number[];
    media: number[];
    assets: number[];
    customLink: null | string;
    requiresPlugLink: boolean;
    requiresCustomMedia: boolean;
    requiresRawMedia: boolean;
    requiresScreenshot: boolean;
    requiresLinkSubmission: boolean;
    requiresTextSubmission: boolean;
    plugLinkSkipLandingPage: boolean;
    submissionLimit: number;
}

export const blankInstructionStepState: () => InstructionStepState = () => {
    return {
        description: "",
        scripts: [],
        media: [],
        assets: [],
        customLink: null,
        requiresPlugLink: false,
        requiresCustomMedia: false,
        requiresRawMedia: false,
        requiresScreenshot: false,
        requiresLinkSubmission: false,
        requiresTextSubmission: false,
        plugLinkSkipLandingPage: false,
        submissionLimit: 1
    };
};

export const defaultInstructionStepState: () => InstructionStepState = () => {
    return {
        description: "Create an ad using the following:",
        scripts: [],
        media: [],
        assets: [],
        customLink: null,
        requiresPlugLink: false,
        requiresCustomMedia: false,
        requiresRawMedia: false,
        requiresScreenshot: false,
        requiresLinkSubmission: false,
        requiresTextSubmission: false,
        plugLinkSkipLandingPage: false,
        submissionLimit: 1
    };
};

export function setInstructionSteps(
    instructionSteps: InstructionStepState[] | OpenBuyInstructionStep[]
): Action {
    return {
        type: SET_INSTRUCTION_STEPS,
        payload: instructionSteps
    };
}

// Set An Instruction Step Has Moved -----------------------------------------------

export const SET_AN_INSTRUCTION_STEP_HAS_MOVED =
    "SET_AN_INSTRUCTION_STEP_HAS_MOVED";

export function setAnInstructionStepHasMoved(moved: boolean): Action {
    return {
        type: SET_AN_INSTRUCTION_STEP_HAS_MOVED,
        payload: moved
    };
}

// Open Instruction Element Carousel Modal -----------------------------------------------

export const OPEN_INSTRUCTION_ELEMENT_CAROUSEL_MODAL =
    "OPEN_INSTRUCTION_ELEMENT_CAROUSEL_MODAL";

export function openInstructionElementCarouselModal(open: boolean): Action {
    return {
        type: OPEN_INSTRUCTION_ELEMENT_CAROUSEL_MODAL,
        payload: open
    };
}

// Set Instruction Element Carousel Item Ids -----------------------------------------------

export const SET_INSTRUCTION_ELEMENT_CAROUSEL_ITEM_IDS =
    "SET_INSTRUCTION_ELEMENT_CAROUSEL_ITEM_IDS";

export function setInstructionElementCarouselItemIds(
    itemIds: number[]
): Action {
    return {
        type: SET_INSTRUCTION_ELEMENT_CAROUSEL_ITEM_IDS,
        payload: itemIds
    };
}

// Set Instructions Sets Error -----------------------------------------------

export const SET_INSTRUCTIONS_SETS_ERROR = "SET_INSTRUCTIONS_SETS_ERROR";

export function setInstructionsSetsError(isErrors: boolean): Action {
    return {
        type: SET_INSTRUCTIONS_SETS_ERROR,
        payload: isErrors
    };
}

// Set Instructions Set Deletion Status -----------------------------------------------

export const SET_INSTRUCTIONS_SET_DELETION_STATUS =
    "SET_INSTRUCTIONS_SET_DELETION_STATUS";

export function setInstructionsSetDeletionStatus(
    status: StepDeletionStatus
): Action {
    return {
        type: SET_INSTRUCTIONS_SET_DELETION_STATUS,
        payload: status
    };
}

// Set Instructions Set Submit Status -----------------------------------------------

export const SET_INSTRUCTIONS_STEP_DELETION_INDEX =
    "SET_INSTRUCTIONS_STEP_DELETION_INDEX";

export function setInstructionsStepDeletionIndex(index: number): Action {
    return {
        type: SET_INSTRUCTIONS_STEP_DELETION_INDEX,
        payload: index
    };
}

// Set Instructions Set Submit Status -----------------------------------------------

export const SET_INSTRUCTIONS_SET_SUBMIT_STATUS =
    "SET_INSTRUCTIONS_SET_SUBMIT_STATUS";

export function setInstructionsSetSubmitStatus(
    status: SubmissionStatus
): Action {
    return {
        type: SET_INSTRUCTIONS_SET_SUBMIT_STATUS,
        payload: status
    };
}

// Reset Instructions Set On Campaign Selection -----------------------------------------------

export const RESET_INSTRUCTIONS_SET_ON_CAMPAIGN_SELECTION =
    "RESET_INSTRUCTIONS_SET_ON_CAMPAIGN_SELECTION";

export function resetInstructionsSetOnCampaignSelection(): Action {
    return {
        type: RESET_INSTRUCTIONS_SET_ON_CAMPAIGN_SELECTION,
        payload: null
    };
}

// Reset Instructions Set When Navigating To Main Page -----------------------------------------------

export const RESET_INSTRUCTIONS_SET_WHEN_NAVIGATING_TO_MAIN_PAGE =
    "RESET_INSTRUCTIONS_SET_WHEN_NAVIGATING_TO_MAIN_PAGE";

export function resetInstructionsSetWhenNavigatingToMainPage(): Action {
    return {
        type: RESET_INSTRUCTIONS_SET_WHEN_NAVIGATING_TO_MAIN_PAGE,
        payload: null
    };
}

// Set Instruction Set Unsaved Changes -----------------------------------------------

export const SET_INSTRUCTIONS_UNSAVED = "SET_INSTRUCTIONS_UNSAVED";
export const setInstructionsUnsaved = (status: boolean) => ({
    type: SET_INSTRUCTIONS_UNSAVED,
    payload: { status }
});

// Set Creating Instruction -----------------------------------------------

export const SET_CREATING_INSTRUCTION = "SET_CREATING_INSTRUCTION";
export const setCreatingInstruction = (creating: boolean) => ({
    type: SET_CREATING_INSTRUCTION,
    payload: { creating }
});
