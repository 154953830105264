import { breakpoints } from "./../../constants";
import { css } from "styled-components";

import { colors, maxWidthDesktop } from "src/constants";

export function responsiveContainer() {
    return css`
        width: 100%;
        max-width: ${maxWidthDesktop}px;
        margin: 0px auto;
    `;
}

export function hideOverflow() {
    return css`
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    `;
}

export function body(margin?: string) {
    return css`
        flex: 1;
        margin: ${margin || "0px"};
        width: 100%;
        max-width: ${maxWidthDesktop}px;
    `;
}

export function flex(
    direction: string = "row",
    justifyContent: string = "auto",
    alignItems: string = "auto"
) {
    return css`
        display: flex;
        flex-direction: ${direction};
        justify-content: ${justifyContent};
        align-items: ${alignItems};
    `;
}

export function flexRowCenter() {
    return css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    `;
}

export function flexColumnCenter() {
    return css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `;
}

export function flexPreventChildStretching() {
    return css`
        width: 0px;
        min-width: 100%;
    `;
}

export function grid(
    columnRepeat: number = 1,
    columnSize: string = "auto",
    gap: number = 0,
    justifyItems: string = "auto",
    alignItems: string = "auto"
) {
    return css`
        display: grid;
        grid-template-columns: repeat(${columnRepeat}, ${columnSize});
        grid-gap: ${gap}px;
        justify-items: ${justifyItems};
        align-items: ${alignItems};
    `;
}

export function hideScrollbar() {
    return css`
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        ::-webkit-scrollbar {
            display: none; /* Chrome, Safari and Opera */
        }
    `;
}

export function hideTextOverflow() {
    return css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `;
}

export function noTextHighlight() {
    return css`
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    `;
}

export function removeTapHighlight() {
    return css`
        -webkit--webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    `;

    // To work on Safari Mobile: document.addEventListener("touchstart", function() {}, true);
}

export function preventUserInteraction() {
    return css`
        pointer-events: none;
        user-select: none;
    `;
}

export function clearSpacing() {
    return css`
        margin: 0;
        padding: 0;
        color: inherit;
    `;
}

export function clearButton() {
    return css`
        margin: 0px;
        padding: 0px;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        outline: none;
        cursor: pointer;
    `;
}

export function clearLink() {
    return css`
        display: block;
        color: black;
        text-align: left;

        :hover {
            color: black;
            text-decoration: none;
        }
    `;
}

export function gradient(degree: number, colorOne: string, colorTwo: string) {
    return css`
        background: linear-gradient(
            ${degree}deg,
            ${colorOne} 0%,
            ${colorTwo} 100%
        );
    `;
}

export function darkTealBorderHoverState() {
    return css`
        border: ${colors.quaternaryLightGray} solid 1px;
        transition: 0.1s ease-in;

        @media (hover: hover) {
            :hover {
                border: ${colors.darkTeal} solid 1px;
            }
        }
    `;
}

export function lightGrayBackgroundHoverState() {
    return css`
        background-color: ${colors.teritaryLightGray};
        transition: 0.1s ease-in;

        @media (hover: hover) {
            :hover {
                background-color: ${colors.quaternaryLightGray};
            }
        }
    `;
}

export function grayBackgroundHoverState() {
    return css`
        background-color: ${colors.quaternaryLightGray};
        transition: 0.1s ease-in;

        @media (hover: hover) {
            :hover {
                background-color: ${colors.quaternaryGray};
            }
        }
    `;
}

export function darkGrayBackgroundHoverState() {
    return css`
        background-color: ${colors.quaternaryLightGray};
        transition: 0.1s ease-in;

        @media (hover: hover) {
            :hover {
                background-color: ${colors.primaryGray};
            }
        }
    `;
}

export function whiteBackgroundHoverState() {
    return css`
        background-color: ${colors.white};
        transition: 0.1s ease-in;

        @media (hover: hover) {
            :hover {
                background-color: ${colors.lightGray2};
            }
        }
    `;
}

export function primaryBlueBackgroundHoverState() {
    return css`
        background-color: ${colors.primaryBlue};
        transition: 0.1s ease-in;

        @media (hover: hover) {
            :hover {
                background-color: ${colors.primaryBlueDark};
            }
        }
    `;
}

export function primaryBlueTextHoverState() {
    return css`
        color: ${colors.primaryBlue};
        transition: 0.1s ease-in;

        @media (hover: hover) {
            :hover {
                color: ${colors.primaryBlueDark};
            }
        }
    `;
}

export function responsiveSidePaddings(
    paddingTop: number = 0,
    paddingBottom: number = 0
) {
    return css`
        @media (max-width: ${breakpoints.tablet}px) {
            padding: ${paddingTop}px 15px ${paddingBottom}px;
        }
    `;
}
