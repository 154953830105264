import { createSelector } from "reselect";
import { GlobalState } from "../../reducers";

export const getAccountsWithSearch = createSelector(
    (state: GlobalState) => state.entities.socialAccounts,
    (state: GlobalState, filter: string, verified?: true) => ({
        filter,
        verified
    }),
    (socialAccountsState, { filter, verified }) => {
        const allSocialAccounts = socialAccountsState.allIds.map(
            socialAccountId => socialAccountsState.byId[socialAccountId]
        );

        const filteredSocialAccounts = allSocialAccounts.filter(
            socialAccount => {
                if (!filter) {
                    return !verified || socialAccount.verified;
                }

                return (
                    (!verified || socialAccount.verified) &&
                    socialAccount.username
                        .toLowerCase()
                        .includes(filter.toLowerCase())
                );
            }
        );

        return filteredSocialAccounts;
    }
);

export const selectAccount = (state: GlobalState, id: null | number) => {
    if (id) return state.entities.socialAccounts.byId[id];
};

export const getAccounts = createSelector(
    (state: GlobalState) => state.profile.accounts,
    accounts => {
        if (accounts) return accounts;
    }
);
