import { GlobalState } from "src/reducers";

export const checkPublisherFlag = (
    state: GlobalState,
    name: string,
    value: string
) => {
    const publisherFlagEntities = state.entities.publisherFlags;
    if (publisherFlagEntities[name]?.value === value) return true;
    return false;
};
