import React from "react";
import { ContestLeaderboard, ContestPrize, ContestRaffle } from "../types";
import styled from "styled-components";
import PrizeCell from "./PrizeCell";
import { collapseRepeats } from "../utils/contestObjectFilters";
import RaffleRules from "./RaffleRules";
import { colors, maxWidthDesktop, fonts } from "src/constants";

const Main = styled.div`
    padding-bottom: 15px;
    background-color: #f8fbfc;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    font-size: ${fonts.subtitle}px;
    text-align: left;

    @media (max-width: ${maxWidthDesktop}px) {
        padding-bottom: 0px;
        border: none;
        border-radius: 0px;

        &:last-of-type {
            padding-bottom: 20px;
        }
    }
`;

const Title = styled.div`
    color: darkgray;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px;
`;

interface Props {
    group: ContestLeaderboard | ContestRaffle;
    setClaimPrize: (claimPrize: ContestPrize) => void;
}

const PrizeGroup = ({ group, setClaimPrize }: Props) => {
    const collapsedPrizes = collapseRepeats(group.prize);

    return (
        <Main>
            <Title>{group.name}</Title>
            {"drawing_video_url" in group && <RaffleRules raffle={group} />}
            {collapsedPrizes.map((prize, idx) => {
                return (
                    <PrizeCell
                        key={idx}
                        prize={prize}
                        setClaimPrize={setClaimPrize}
                    />
                );
            })}
        </Main>
    );
};

export default PrizeGroup;
