import React, { ChangeEvent, useState } from "react";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import SearchBar from "src/components/SearchBar";
import styled from "styled-components";
import { Label } from "./components/CommonStyles";
import { MediaFilters } from "../types";
import { colors, fonts } from "src/constants";

const Input = styled.input`
    outline: none;
    width: 100%;
    padding: 0 12px;
    height: 46px;
    border: 1px solid #e0eef2;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
    text-align: left;
    transition: 0.1s ease-in;

    :focus {
        border: ${colors.darkTeal} solid 1px;
    }
`;

const InputWrapper = styled.div`
    ${FlexVerticallyCentered};

    & > * ~ * {
        margin-left: 10px;
    }
`;

interface Props {
    setFilters: (filters: MediaFilters) => void;
    filters: MediaFilters;
}

const MediaFilterDuration = ({ filters, setFilters }: Props) => {
    const generateHandleChange = (key: "gte" | "lt") => {
        return (e: ChangeEvent<HTMLInputElement>) => {
            setFilters({
                ...filters,
                duration: {
                    ...filters.duration,
                    [key]: e.target.value.replace(/\D/, "")
                }
            });
        };
    };

    return (
        <div>
            <Label>DURATION (SECONDS)</Label>
            <InputWrapper>
                <Input
                    type="search"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    placeholder="Greater Than"
                    value={filters.duration.gte}
                    onChange={generateHandleChange("gte")}
                />
                <Input
                    type="search"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    placeholder="Less Than"
                    value={filters.duration.lt}
                    onChange={generateHandleChange("lt")}
                />
            </InputWrapper>
        </div>
    );
};

export default MediaFilterDuration;
