import React from "react";
import { colors, fonts } from "src/constants";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 6px;
    background-color: ${colors.white};
    border: ${colors.blueGrey2} solid 1px;
    border-radius: 4px;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
    border-radius: 4px;
`;

const Text = styled.span`
    display: block;
    color: ${colors.black};
    font-size: ${fonts.smallTitle}px;
    white-space: nowrap;
`;

interface Props {
    socialLogo: string;
    text: string;
    empty?: boolean;
}

const PlacementsElement = ({ socialLogo, text, empty }: Props) => {
    return (
        <Main>
            {!empty && <Icon src={socialLogo} alt="" />}
            <Text>{text}</Text>
        </Main>
    );
};

export default PlacementsElement;
