import {
    all,
    call,
    cancelled,
    fork,
    put,
    select,
    take,
    takeEvery,
    takeLeading
} from "redux-saga/effects";
import { groupBy, mapValues } from "lodash";
import { groupUserSettingList } from "../schema";
import { getAuthState } from "src/auth/selectors";
import { storage } from "src/firebase/FirebaseConfig";
import { GlobalState } from "src/reducers";
import { Action } from "src/types";
import { set, get, add, getWhere, createEventChannel } from "../saga";
import { selectChatProfileById, selectConversationWithId } from "../selectors";
import { ChatProfile } from "../types/ChatProfile";
import { Conversation } from "../types/Conversation";
import {
    AddMemberParams,
    SET_CONVERSATION_PAUSE_DURATION,
    updateGroupActionsAddMemberSuccess,
    updateGroupUserSettings,
    updateGroupUserSettingsChannel,
    UPDATE_GROUP_ACTIONS,
    UPDATE_GROUP_ADMIN_STATUS,
    UPDATE_GROUP_CHANNEL_INFO,
    UPDATE_GROUP_USER_SETTINGS
} from "./actions";
import { updateChatProfileService } from "../chat-profiles/saga";
import { GroupUserSettings } from "../types/GroupUserSettings";
import { createConversationRequestService } from "../conversation/saga";
import { ConversationUnread } from "../types/ConversationUnread";
import { chunk } from "src/util";
import { normalize } from "normalizr";

export function* createGroupUserSettingsChannelRequest(
    conversationIds: string[]
) {
    const conversationIdsChunks = chunk(conversationIds, 10);
    try {
        const channels = conversationIdsChunks.map(conversationIds =>
            createGroupUserSettingsChannelService(conversationIds)
        );

        yield all(channels);
    } catch (error) {
        console.log(error);
    }
}

function* createGroupUserSettingsChannelService(conversationIds: string[]) {
    const updateChannel = createEventChannel("GroupUserSettings", [
        "conversationId",
        "in",
        conversationIds
    ]);
    try {
        while (true) {
            const groupUserSettings: GroupUserSettings = yield take(
                updateChannel
            );

            const groupByConversationId = groupBy(
                groupUserSettings,
                (el: any) => el.conversationId
            );

            const normalizedSettings = mapValues(
                groupByConversationId,
                (el): any =>
                    normalize(el, groupUserSettingList).entities
                        .groupUserSettings
            );
            yield put(updateGroupUserSettingsChannel(normalizedSettings));
        }
    } catch (error) {
        console.log(error);
    } finally {
        if (yield cancelled()) {
            updateChannel.close();
        }
    }
}

function* updateGroupChannelInfoRequest({
    payload: { groupChannelInfoParams }
}: Action) {
    try {
        const {
            conversationId,
            displayName,
            imageId,
            groupDescription
        } = groupChannelInfoParams;

        let imageUrl: string | undefined;
        if (imageId) {
            const storageRef = storage.ref(
                `conversation_group_assets/${imageId}`
            );
            imageUrl = yield call([storageRef, storageRef.getDownloadURL]);
        }

        const newConversation = {
            id: conversationId,
            ...(displayName && { displayName }),
            ...(imageUrl && { imageUrl }),
            ...(groupDescription && { groupDescription })
        };

        yield call(set, "Conversation", newConversation);
    } catch (error) {}
}

function* setConversationPauseDurationRequest({
    payload: { pauseDurationParams }
}: Action) {
    try {
        const {
            conversationId,
            notificationsPausedIndefinitely,
            notificationsPausedUntil
        } = pauseDurationParams;

        const { userId } = yield select(getAuthState);
        const userProfile: ChatProfile = yield select((state: GlobalState) =>
            selectChatProfileById(state, userId)
        );
        const index = userProfile.conversations.findIndex(
            el => el.conversationId === conversationId
        );
        const newConversations = userProfile.conversations.slice();
        newConversations[index] = {
            ...newConversations[index],
            ...(typeof notificationsPausedIndefinitely === "boolean" && {
                notificationsPausedIndefinitely
            }),
            ...(notificationsPausedUntil && { notificationsPausedUntil })
        };

        if (notificationsPausedUntil === null) {
            delete newConversations[index].notificationsPausedUntil;
        }

        const newUser = {
            id: userProfile.id,
            conversations: newConversations
        };

        yield call(set, "User", newUser);
    } catch (error) {}
}

function* updateGroupAdminStatusRequest({
    payload: { groupAdminStatusParams }
}: Action) {
    try {
        const {
            userId,
            conversationId,
            isAdmin,
            groupUserSettingsId
        } = groupAdminStatusParams;

        const conversation: Conversation = yield select((state: GlobalState) =>
            selectConversationWithId(state, conversationId)
        );

        const newConversation = {
            id: conversationId,
            adminUserIds: conversation.adminUserIds
        };
        if (isAdmin) {
            newConversation.adminUserIds = [
                ...conversation.adminUserIds,
                userId
            ];
        } else {
            newConversation.adminUserIds = conversation.adminUserIds.filter(
                adminId => adminId !== userId
            );
        }

        yield call(set, "Conversation", newConversation);
        yield call(
            updateGroupUserSettingsRequest,
            updateGroupUserSettings({ id: groupUserSettingsId, isAdmin })
        );
    } catch (error) {}
}

function* updateGroupUserSettingsRequest({
    payload: { groupUserSettingsParams }
}: Action) {
    try {
        const {
            id,
            isAdmin,
            isBanned,
            isMutedIndefinitely,
            isMutedUntil,
            blocked,
            reported
        } = groupUserSettingsParams;

        const newGroupUserSettings = {
            id,
            ...(typeof isAdmin === "boolean" && { isAdmin }),
            ...(typeof isBanned === "boolean" && { isBanned }),
            ...(typeof blocked === "number" && { blocked }),
            ...(typeof reported === "number" && { reported }),
            ...(typeof isMutedIndefinitely === "boolean" && {
                isMutedIndefinitely
            }),
            ...(isMutedUntil && { isMutedUntil })
        };

        yield call(set, "GroupUserSettings", newGroupUserSettings);
    } catch (error) {
        console.log(error);
    }
}

function* updateGroupActionsRemoveUserRequest({
    payload: { conversationId, userId }
}: Action) {
    try {
        const conversation: Conversation = yield select((state: GlobalState) =>
            selectConversationWithId(state, conversationId)
        );

        // remove userId from conversation's participantIds
        const newConversation = {
            id: conversationId,
            participantIds: conversation.participantIds.filter(
                participantId => participantId !== userId
            )
        };

        yield call(set, "Conversation", newConversation);

        // remove from user's chatProfile conversation list
        const chatProfile: ChatProfile = yield select((state: GlobalState) =>
            selectChatProfileById(state, userId)
        );

        const newChatProfile = {
            id: userId,
            conversations: chatProfile.conversations.filter(
                conversation => conversation.conversationId !== conversationId
            ),
            conversationIds: chatProfile.conversationIds.filter(
                srcId => srcId !== conversationId
            )
        };

        yield call(set, "User", newChatProfile);
    } catch (error) {}
}

function* updateGroupActionsAddMemberRequest({
    payload: { conversationId, users }
}: Action) {
    try {
        const updateServices = users.map((user: AddMemberParams) =>
            updateGroupActionsAddMemberService(user, conversationId, {
                isGroupRequest: true
            })
        );

        yield all(updateServices);

        const conversation: Conversation = yield select((state: GlobalState) =>
            selectConversationWithId(state, conversationId)
        );

        const userIds = users.map((user: AddMemberParams) => user.publisherId);

        const newConversation = {
            id: conversationId,
            participantIds: [...conversation.participantIds, ...userIds]
        };
        yield call(set, "Conversation", newConversation);
        yield put(updateGroupActionsAddMemberSuccess());
    } catch (error) {
        console.log(error);
    }
}

interface AddMemberOptions {
    isGroupRequest?: boolean;
    isAdmin?: boolean;
}

export function* updateGroupActionsAddMemberService(
    user: AddMemberParams,
    conversationId: string,
    options: AddMemberOptions
) {
    try {
        const { isGroupRequest, isAdmin } = options;
        // WIP: yield all this and loading UI state
        // create chat profile if not exist / else update conversation digest
        yield call(updateChatProfileService, user, conversationId);

        // create conversation request if not exist, but not for self
        yield call(
            createConversationRequestService,
            user,
            conversationId,
            !!isGroupRequest
        );

        // create group settings if not exist
        const groupUserSettings: GroupUserSettings[] = yield call(
            getWhere,
            "GroupUserSettings",
            ["conversationId", "==", conversationId],
            ["userId", "==", user.publisherId]
        );
        if (!groupUserSettings.length) {
            const newGroupUserSettings = {
                conversationId,
                isAdmin: !!isAdmin,
                isBanned: false,
                isMutedIndefinitely: false,
                userId: user.publisherId
            };
            yield call(add, "GroupUserSettings", newGroupUserSettings);
        }

        // create ConversationUnreadCount if not exist
        const conversationUnreadCounts: ConversationUnread[] = yield call(
            getWhere,
            "ConversationUnreadCount",
            ["conversationId", "==", conversationId],
            ["publisherId", "==", user.publisherId]
        );
        if (!conversationUnreadCounts.length) {
            const newConversationUnreadCount = {
                conversationId,
                publisherId: user.publisherId,
                unreadCount: 0
            };
            yield call(
                add,
                "ConversationUnreadCount",
                newConversationUnreadCount
            );
        }
    } catch (error) {
        console.log("main service error", error);
    }
}

export default function* groupUserSettingsSaga() {
    yield all([
        takeLeading(UPDATE_GROUP_CHANNEL_INFO, updateGroupChannelInfoRequest),
        takeLeading(UPDATE_GROUP_USER_SETTINGS, updateGroupUserSettingsRequest),
        takeLeading(UPDATE_GROUP_ADMIN_STATUS, updateGroupAdminStatusRequest),
        takeLeading(
            UPDATE_GROUP_ACTIONS.REMOVE_USER,
            updateGroupActionsRemoveUserRequest
        ),
        takeLeading(
            UPDATE_GROUP_ACTIONS.ADD_MEMBER,
            updateGroupActionsAddMemberRequest
        ),
        takeEvery(
            SET_CONVERSATION_PAUSE_DURATION,
            setConversationPauseDurationRequest
        )
    ]);
}
