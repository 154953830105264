import React, { useState } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import ShadeScreen from "src/media/components/ShadeScreen";
import { Campaign } from "src/campaigns/types";
import SimpleNavbar from "src/components/SimpleNavbar";
import LoadingButton from "src/components/LoadingButton";
import { UploadMediaData } from "./PromoteFlowModal";
import Switch from "react-switch";
import Divider from "../../components/Divider";
import SwitchContainer from "src/media/components/SwitchContainer";
import SwitchDescription from "src/media/components/SwitchDescription";
import ShareSelectModal from "./ShareSelector";
import PromotePlacementSelector from "./PromotePlacementSelector";
import { CustomMediaUpload } from "src/media/actions";

const Main = styled.div`
    text-align: left;
`;
const AdUploadPanel = styled.div`
    padding: 30px 0px;
    background-color: ${colors.lightTeal};
    display: flex;
    justify-content: center;
`;
const AdPreviewContainer = styled.div`
    position: relative;
    height: 205px;
    width: 114px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    background-color: ${colors.white};
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
`;

const Video = styled.video`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const Photo = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;
const Title = styled.div`
    font-size: 20px;
    font-weight: 500;
    padding-left: 18px;
    padding-top: 20px;
`;
const SubTitle = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 400;
    padding-left: 19px;
    padding-bottom: 18px;
`;

const Close = styled.img`
    padding: 15px;
    cursor: pointer;

    svg {
        margin-right: 15px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: "row";
    padding: 10px 18px 3px 18px;
`;

const SectionTitle = styled.div`
    font-size: 12px;
    font-weight: 700;
    color: ${colors.primaryGray};
    padding: 15px 15px 0px;
`;

interface Props {
    campaign: Campaign;
    onDelete: () => void;
    onNext: (v?: string) => void;
    onClose: () => void;
    uploadData: CustomMediaUpload;
    mediaUrl: string;
    setUploadData: (data: any) => void;
}

const MediaPlacement = ({
    campaign,
    onDelete,
    onNext,
    onClose,
    mediaUrl,
    uploadData,
    setUploadData
}: Props) => {
    const [agreed, setAgreed] = useState(false);

    function renderIcon() {
        if (uploadData.type === "video") {
            return <img src={"/play_icon.svg"} />;
        } else {
            return null;
        }
    }

    return (
        <Main>
            <SimpleNavbar
                title={"Upload Media"}
                left={<Close onClick={onClose} src={"/close.svg"} />}
            />
            <Title>Media Details</Title>
            <SubTitle>Please select an option for the prompts below</SubTitle>
            <AdUploadPanel>
                <AdPreviewContainer
                    onClick={() => {
                        if (mediaUrl) {
                            onDelete();
                        }
                    }}
                >
                    {uploadData.type === "video" ? (
                        <Video src={mediaUrl} />
                    ) : (
                        <Photo src={mediaUrl} />
                    )}
                    <ShadeScreen>{renderIcon()}</ShadeScreen>
                </AdPreviewContainer>
            </AdUploadPanel>
            <Divider />
            <SectionTitle>MEDIA RULES</SectionTitle>
            <SwitchContainer>
                <SwitchDescription>
                    This ad follows {campaign.campaignName}'s Media Rules
                </SwitchDescription>
                <Switch
                    onColor={colors.primaryBlue}
                    offColor={"#cccccc"}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    handleDiameter={24}
                    checked={agreed}
                    onChange={(checked: boolean) => {
                        setAgreed(checked);
                    }}
                />
            </SwitchContainer>
            <Divider />
            <PromotePlacementSelector
                selected={uploadData.placements}
                onSelect={v =>
                    setUploadData((prevState: UploadMediaData) => ({
                        ...prevState,
                        placements: { ...v }
                    }))
                }
            />
            <Divider />
            <ShareSelectModal
                shareable={uploadData.allow_others_to_use}
                onSelect={v =>
                    setUploadData((prevState: UploadMediaData) => ({
                        ...prevState,
                        allow_others_to_use: v
                    }))
                }
            />
            <Divider />
            <ButtonContainer>
                <LoadingButton
                    text="Back"
                    height="48px"
                    width="100%"
                    backgroundColor={colors.white}
                    textColor={colors.primaryBlue}
                    borderColor={colors.primaryBlue}
                    margin="auto 13px auto auto"
                    onClick={() => onNext("upload")}
                />
                <LoadingButton
                    text="Next"
                    height="48px"
                    width="100%"
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                    disabled={
                        !agreed ||
                        Object.keys(uploadData.placements).length === 0
                    }
                    onClick={onNext}
                />
            </ButtonContainer>
        </Main>
    );
};

export default MediaPlacement;
