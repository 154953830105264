import React from "react";
import { breakpoints } from "src/constants";
import { codeToEmoji } from "src/notifications/CodeToEmoji";
import { capitalizeFirstCharacter } from "src/utils/functions/helpers";
import styled from "styled-components";
import {
    SocialAccountProfileAudienceData,
    SocialAccountProfileAudienceDataWithName
} from "../utils/social-accounts.types";
import FollowerDemographicsCard from "./FollowerDemographicsCard";
import SocialAccountProfileSection from "./SocialAccountProfileSection";

const Main = styled.section`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;

    @media (max-width: ${breakpoints.mobile}px) {
        grid-template-columns: 1fr;
    }
`;

interface Props {
    audienceCountryCode: string;
    audienceCountries: SocialAccountProfileAudienceDataWithName[];

    audienceGenderCode: string;
    audienceGenders: SocialAccountProfileAudienceData[];

    audienceLanguageName: string;
    audienceLanguages: SocialAccountProfileAudienceDataWithName[];

    audienceAgeCode: string;
    audienceAges: SocialAccountProfileAudienceData[];
}

const SocialAccountProfileFollowerDemographics = ({
    audienceCountryCode,
    audienceCountries,

    audienceGenderCode,
    audienceGenders,

    audienceLanguageName,
    audienceLanguages,

    audienceAgeCode,
    audienceAges
}: Props) => {
    // Library Methods -----------------------------------------------

    // Redux State ---------------------------------------------------

    // Local State ---------------------------------------------------

    // Local Constants -----------------------------------------------
    const audienceGendersWithOtherField = [...audienceGenders];
    audienceGendersWithOtherField.push({
        code: "OTHER",
        weight: 0
    });

    // Local Functions -----------------------------------------------
    function generateGenderEmoji(audienceGenderCode: string): string {
        if (audienceGenderCode.toLowerCase() === "male") {
            return "👨‍🦰";
        } else if (audienceGenderCode.toLowerCase() === "female") {
            return "👱‍♀️";
        } else {
            return "?";
        }
    }

    // Custom Hooks --------------------------------------------------

    // Effects -------------------------------------------------------

    // JSX -----------------------------------------------------------
    return (
        <SocialAccountProfileSection
            title="Follower Demographics"
            body={
                <Main>
                    {/* Audience Countries */}
                    {audienceCountryCode && audienceCountries && (
                        <FollowerDemographicsCard
                            type="Country"
                            title={`${
                                codeToEmoji[audienceCountryCode.toUpperCase()]
                            } Country: ${audienceCountryCode.toUpperCase()}`}
                            demographicsData={audienceCountries.sort(
                                (
                                    a: SocialAccountProfileAudienceData,
                                    b: SocialAccountProfileAudienceData
                                ) => {
                                    return b.weight - a.weight;
                                }
                            )}
                        />
                    )}

                    {/* Audience Genders */}
                    {audienceGenderCode && audienceGendersWithOtherField && (
                        <FollowerDemographicsCard
                            type="Gender"
                            title={`${generateGenderEmoji(
                                audienceGenderCode
                            )} Gender: ${capitalizeFirstCharacter(
                                audienceGenderCode.toLowerCase()
                            )}`}
                            demographicsData={audienceGendersWithOtherField.sort(
                                (
                                    a: SocialAccountProfileAudienceData,
                                    b: SocialAccountProfileAudienceData
                                ) => {
                                    return b.weight - a.weight;
                                }
                            )}
                        />
                    )}

                    {/* Audience Languages */}
                    {audienceLanguageName && audienceLanguages && (
                        <FollowerDemographicsCard
                            type="Language"
                            title={`🔤 Language: ${audienceLanguageName}`}
                            demographicsData={audienceLanguages.sort(
                                (
                                    a: SocialAccountProfileAudienceData,
                                    b: SocialAccountProfileAudienceData
                                ) => {
                                    return b.weight - a.weight;
                                }
                            )}
                        />
                    )}

                    {/* Audience Ages */}
                    {audienceAgeCode && audienceAges && (
                        <FollowerDemographicsCard
                            type="Age"
                            title={`👶 Age: ${audienceAgeCode}`}
                            demographicsData={audienceAges.sort(
                                (
                                    a: SocialAccountProfileAudienceData,
                                    b: SocialAccountProfileAudienceData
                                ) => {
                                    return b.weight - a.weight;
                                }
                            )}
                        />
                    )}
                </Main>
            }
            bodyPadding="12px"
            backgroundActive
        />
    );
};

export default SocialAccountProfileFollowerDemographics;
