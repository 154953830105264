import React from "react";
import { hot } from "react-hot-loader/root";
import AppRouter from "./navigation/AppRouter";
import { Provider, useSelector } from "react-redux";
import { AppContainer } from "react-hot-loader";
import { history } from "./configureStore";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
    faCheck,
    faChevronRight,
    faMusic,
    faPlay,
    faPlusCircle,
    faStar,
    faStarHalf
} from "@fortawesome/free-solid-svg-icons";
import {
    faQuestionCircle,
    faStar as faStarRegular
} from "@fortawesome/free-regular-svg-icons";
import Notification from "./ui/notifications/Notification";
import styled from "styled-components";
import { colors } from "./constants";
import ErrorBoundary from "./components/ErrorBoundaries/ErrorBoundary";
import ErrorBoundaryTest from "./components/ErrorBoundaries/ErrorBoundaryTest";
import Listeners from "./firebase/Listeners";
import { useEffect } from "react";
import PillNotification from "./ui/notifications/PillNotification";

library.add(
    fab,
    faMusic,
    faQuestionCircle,
    faPlusCircle,
    faChevronRight,
    faPlay,
    faCheck,
    faStar,
    faStarHalf,
    faStarRegular
);

const LoginAsBanner = styled.div`
    position: fixed;
    font-size: 10px;
    z-index: 1000;
    width: 200px;
    text-align: center;
    background-color: ${colors.primaryBlue};
    color: white;
    transform: translate(-60px, 30px) rotate(-45deg);
    top: 0;
`;

const PlugApp = ({ store }: { store: any }) => {
    //set CUID when a user is already logged in but hasn't been set in appsflyer yet
    //and is not a logged in as employee
    useEffect(() => {
        let uid = localStorage.getItem("userid");
        let employee = localStorage.getItem("employeeToken");
        let appsflyerID = localStorage.getItem("appsflyerID");
        if (uid && (!appsflyerID || uid !== appsflyerID) && !employee)
            try {
                (window as any).AF("pba", "setCustomerUserId", `${uid}`);
                localStorage.setItem("appsflyerID", uid);
            } catch (error) {
                console.error("error setting CUID: ", error);
            }
    }, []);

    if (!process.env.REACT_APP_DEV_RETOOL_URL)
        console.error("Missing env variable DEV_RETOOL_URL");
    if (!process.env.REACT_APP_FACEBOOK_APP_INFO)
        console.error("Missing env variable FACEBOOK_APP_INFO");
    if (!process.env.REACT_APP_SPLIT_BROWSER_API_KEY)
        console.error("Missing env variable SPLIT_BROWSER_API_KEY");

    return (
        <AppContainer>
            <div style={{ textAlign: "center" }}>
                <Provider store={store}>
                    <Notification />
                    {localStorage.getItem("employeeToken") && (
                        <LoginAsBanner>Logged In As User</LoginAsBanner>
                    )}
                    {localStorage.getItem("devAccount") && (
                        <LoginAsBanner>Dev Onboarding</LoginAsBanner>
                    )}
                    <ErrorBoundary>
                        <ErrorBoundaryTest testing={false} />
                        <PillNotification />
                        <Listeners />
                        <AppRouter history={history} />
                    </ErrorBoundary>
                </Provider>
            </div>
        </AppContainer>
    );
};

export default hot(PlugApp);
