import React from "react";
import styled from "styled-components";
import { DailyEarnings } from "./Analytics";
import { coinToDollars, formatNumber } from "../util";
import Button from "react-bootstrap/Button";
import CoinSummary from "../components/CoinSummary";
import DailyEarningsCell from "./DailyEarningsCell";

const List = styled.div`
    padding: 10px 15px 15px;
`;

const Bottom = styled.div`
    padding: 0px 15px 15px;
`;

interface Props {
    dailyEarnings: DailyEarnings[];
    summary: boolean;
    push: (path: string) => void;
}

const DailyEarningsCard = ({ dailyEarnings, summary, push }: Props) => {
    const totalCoins =
        Math.round(
            dailyEarnings.reduce((a, b) => {
                return a + b.plugcoins;
            }, 0) * 10
        ) / 10;
    return (
        <>
            <CoinSummary
                topLeft={"Daily Earnings"}
                topRight={formatNumber(coinToDollars(totalCoins))}
                bottomLeft={"Last 24 hours"}
                bottomRight={""}
            />
            <List>
                {dailyEarnings
                    .slice(0, summary ? 5 : dailyEarnings.length)
                    .map((earnings, idx) => {
                        return (
                            <DailyEarningsCell key={idx} earnings={earnings} />
                        );
                    })}
            </List>
            {summary && (
                <Bottom>
                    <Button
                        block
                        variant="outline-success"
                        onClick={() => push("/analytics/daily-earnings")}
                    >
                        Show More
                    </Button>
                </Bottom>
            )}
        </>
    );
};

export default DailyEarningsCard;
