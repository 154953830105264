import { push } from "connected-react-router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";
import { BlueModalButton } from "../profile/components/Buttons";
import { Section, Title } from "../profile/modals/styledComponents";
import { GlobalState } from "../reducers";
import EmojiModal from "./EmojiModal";

const Color = styled(animated.div)`
    position: relative;
    z-index: 2001;
`;

const StartPromotingModal = () => {
    const dispatch = useDispatch();
    const startPromotingModal = useSelector(
        (state: GlobalState): boolean => state.ui.auth.startPromotingModal
    );

    const transitions = useTransition(startPromotingModal, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    });

    return (
        <>
            {transitions.map(
                ({ item, key, props }) =>
                    item && (
                        <animated.div key={key}>
                            <Color style={props}>
                                <EmojiModal emoji="💸">
                                    <Title>Start Promoting</Title>
                                    <Section>
                                        The Plug is the easiest way to monetize
                                        your social media following by promoting
                                        what you want. Click below to get
                                        started
                                    </Section>
                                    <BlueModalButton
                                        onClick={() =>
                                            dispatch(push("/onboarding"))
                                        }
                                    >
                                        Sign Up
                                    </BlueModalButton>
                                </EmojiModal>
                            </Color>
                        </animated.div>
                    )
            )}
        </>
    );
};

export default StartPromotingModal;
