import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MainContainer from "src/components/MainContainer";
import ChallengeForm from "src/marketplace/challenge-creation-form/ChallengeForm";
import { getCurrentInstructionsSet } from "src/marketplace/instructions-sets/selectors";
import ChallengeMainHeader from "src/marketplace/landing/components/ChallengeMainHeader";
import StepSelector from "src/marketplace/wizard/components/StepSelector";
import styled from "styled-components";
import { GlobalState } from "src/reducers";
import { getCurrentOpenBuy } from "../selectors.admin-tools";
import {
    setCurrentInstructionsSetId,
    setInstructionsPageStep,
    setInstructionsPageType,
    setInstructionsUnsaved
} from "src/ui/instructions-sets/actions";
import {
    fetchInstructionsSet,
    fetchInstructionsSets,
    resetInstructionSets
} from "src/marketplace/instructions-sets/actions.instructions-sets";
import PaymentSetsCreateOrEditPage from "src/marketplace/payment-sets/pages/PaymentSetsCreateOrEditPage";
import { currentCampaign } from "src/campaigns/selectors";
import { setCurrentCampaignId } from "src/ui/campaigns/actions";
import { adminWizardSteps, AdminWizardStep } from "src/ui/admin-tools/reducers";
import {
    setAdminCurrentWizardStep,
    setCurrentOpenBuyId,
    setDetailsUnsaved
} from "src/ui/admin-tools/actions";
import InstructionsPagePartial from "src/marketplace/instructions-sets/components/InstructionsPagePartial";
import { InstructionsSet } from "src/marketplace/instructions-sets/types";
import GlobalWarning from "src/components/GlobalWarning";
import { breakpoints, sizes } from "src/constants";
import { fetchOpenBuyPayments } from "../../marketplace/payment-sets/open-buy-payments/actions";
import { getCurrentPendingOpenBuyPaymentsGroups } from "../../marketplace/payment-sets/open-buy-payments/selectors";

const Main = styled.main`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 24px - ${sizes.navbar.desktopHeight}px);

    @media (max-width: ${breakpoints.small}px) {
        min-height: calc(100vh ${sizes.navbar.mobileHeight}px);
    }
`;

const Contents = styled(MainContainer)`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const StepSelectors = styled.section`
    display: flex;
    gap: 10px;
    margin-bottom: 24px;
`;

interface CurrentStepViewProps {
    $selected: boolean;
    $isInstructions?: boolean;
}

const CurrentStepView = styled.section<CurrentStepViewProps>`
    display: ${props => {
        const { $selected, $isInstructions } = props;
        if ($selected && $isInstructions) return "flex";
        else if ($selected) return "block";
        else return "none";
    }};
    flex: 1;
`;

interface Props {
    openBuyId: number;
}

const ChallengeWizard = ({ openBuyId }: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const currentCampaignId = useSelector(currentCampaign);
    const { currentInstructionsSetId, instructionsHasUnsaved } = useSelector(
        (state: GlobalState) => {
            return state.ui.instructionsSets;
        },
        shallowEqual
    );
    const currentInstructionsSet = useSelector(getCurrentInstructionsSet);
    const {
        currentOpenBuyId,
        currentWizardStep,
        detailsHasUnsaved
    } = useSelector((state: GlobalState) => {
        return state.ui.adminTools;
    }, shallowEqual);
    const currentOpenBuy = useSelector(getCurrentOpenBuy);
    const currentPendingOpenBuyPaymentsGroups = useSelector(
        getCurrentPendingOpenBuyPaymentsGroups
    );

    // Local State ---------------------------------------------------
    const [detailsComplete, setDetailsComplete] = useState(false);
    const [nextStep, setNextStep] = useState<AdminWizardStep | null>(null);
    const [openModal, setOpenModal] = useState(false);

    const [paymentsHasUnsaved, setPaymentsUnsaved] = useState(false);

    // Local Functions -----------------------------------------------
    function renderTemplatePreview(): void {
        dispatch(setInstructionsPageType("Main"));
        dispatch(setInstructionsPageStep("Selection"));
    }
    function renderEditView(): void {
        dispatch(setInstructionsPageType("Edit"));
        dispatch(setInstructionsPageStep("Scratch"));
    }

    function generateSelectedItemName(step: AdminWizardStep): string {
        switch (step) {
            case "Set Challenge Details":
                if (detailsComplete) return "Challenge Details Completed";
                else return "Challenge details incomplete";
            case "Select an Instruction Set":
                if (currentInstructionsSet) return currentInstructionsSet.name;
                else return "No instruction set has been selected";
            default:
                if (
                    currentPendingOpenBuyPaymentsGroups &&
                    currentPendingOpenBuyPaymentsGroups.length > 0
                )
                    return "Payments Selected";
                else return "No payments have been selected";
        }
    }

    function determineItemSelected(stepCount: number): boolean {
        if (stepCount === 1) return detailsComplete;
        else if (stepCount === 2) return !!currentInstructionsSet;
        else
            return (
                !!currentPendingOpenBuyPaymentsGroups &&
                currentPendingOpenBuyPaymentsGroups.length > 0
            );
    }

    function instructionSetHasOldPayments(instructionSet: InstructionsSet) {
        return instructionSet.instructionSteps.some(
            step =>
                step.instructionPayments && step.instructionPayments.length > 0
        );
    }

    function stepHasUnsaved(step: AdminWizardStep): boolean {
        switch (step) {
            case "Set Challenge Details":
                return detailsHasUnsaved;
            case "Select an Instruction Set":
                return instructionsHasUnsaved;
            case "Select a Payment Set":
                return paymentsHasUnsaved;
            default:
                return (
                    detailsHasUnsaved ||
                    instructionsHasUnsaved ||
                    paymentsHasUnsaved
                );
        }
    }

    function handleUnsavedDispatch(step: AdminWizardStep): void {
        switch (step) {
            case "Set Challenge Details":
                dispatch(setDetailsUnsaved(false));
                return;
            case "Select an Instruction Set":
                dispatch(setInstructionsUnsaved(false));
                return;
            case "Select a Payment Set":
                setPaymentsUnsaved(false);
                return;
            default:
                return;
        }
    }

    // Effects -------------------------------------------------------
    useEffect(() => {
        return function cleanUp(): void {
            dispatch(setAdminCurrentWizardStep("Set Challenge Details"));
            dispatch(setDetailsUnsaved(false));
            dispatch(setInstructionsUnsaved(false));
        };
    }, []);

    useEffect(() => {
        dispatch(setCurrentOpenBuyId(openBuyId));
    }, [openBuyId]);

    useEffect(() => {
        if (currentOpenBuy) {
            if (!currentOpenBuy.instructionSetId) {
                renderTemplatePreview();
            } else {
                renderEditView();
            }
        }
    }, [currentOpenBuy]);

    useEffect(() => {
        if (currentCampaignId) {
            dispatch(fetchInstructionsSets(currentCampaignId, true));
        } else {
            dispatch(resetInstructionSets());
        }
    }, [currentCampaignId]);

    useEffect(() => {
        if (currentInstructionsSetId) {
            dispatch(fetchInstructionsSet(currentInstructionsSetId, true));
        }
    }, [currentInstructionsSetId]);

    useEffect(() => {
        if (currentOpenBuyId) {
            dispatch(fetchOpenBuyPayments(currentOpenBuyId));
        }
    }, [currentOpenBuyId]);

    useEffect(() => {
        if (currentOpenBuy && currentOpenBuy.instructionSetId) {
            dispatch(
                setCurrentInstructionsSetId(currentOpenBuy.instructionSetId)
            );
        }

        if (currentOpenBuy?.Buyer?.campaignId) {
            dispatch(setCurrentCampaignId(currentOpenBuy.Buyer.campaignId));
        }
    }, [currentOpenBuy]);

    // JSX -----------------------------------------------------------
    return (
        <Main>
            <Contents>
                <StepSelectors>
                    {adminWizardSteps.map(
                        (step: AdminWizardStep, index: number) => {
                            return (
                                <StepSelector
                                    isCurrentStep={step === currentWizardStep}
                                    setCurrentStep={() => {
                                        if (stepHasUnsaved(currentWizardStep)) {
                                            setOpenModal(true);
                                            setNextStep(step);
                                        } else {
                                            dispatch(
                                                setAdminCurrentWizardStep(step)
                                            );
                                        }
                                    }}
                                    stepCount={index + 1}
                                    stepName={step}
                                    selectedItemName={generateSelectedItemName(
                                        step
                                    )}
                                    itemSelected={determineItemSelected(
                                        index + 1
                                    )}
                                />
                            );
                        }
                    )}
                </StepSelectors>

                {/* Set Challenge Details */}
                <CurrentStepView
                    $selected={currentWizardStep === "Set Challenge Details"}
                >
                    <ChallengeForm
                        onCreatePage={false}
                        setDetailsComplete={setDetailsComplete}
                        noNavbar
                        noBreadcrumbs
                    />
                </CurrentStepView>

                {/* Select an Instruction Set  */}
                <CurrentStepView
                    $selected={
                        currentWizardStep === "Select an Instruction Set"
                    }
                    $isInstructions={true}
                >
                    <InstructionsPagePartial noHeader />
                </CurrentStepView>

                {/* Select a Payment Set */}
                <CurrentStepView
                    $selected={currentWizardStep === "Select a Payment Set"}
                >
                    {currentOpenBuyId && (
                        <PaymentSetsCreateOrEditPage
                            openBuyId={currentOpenBuyId}
                        />
                    )}
                </CurrentStepView>

                {openModal && (
                    <GlobalWarning
                        title={`Discard Changes`}
                        message={`You have unsaved changes. Are you sure you want to navigate away?`}
                        handleCancel={() => setOpenModal(false)}
                        handleApply={() => {
                            setOpenModal(false);
                            nextStep &&
                                dispatch(setAdminCurrentWizardStep(nextStep));
                            setNextStep(null);
                            handleUnsavedDispatch(currentWizardStep);
                        }}
                        btnText={{ cancel: "Cancel", apply: "Confirm" }}
                    />
                )}
            </Contents>
        </Main>
    );
};

export default ChallengeWizard;
