import React, { useState } from "react";
import Rating from "react-rating";
import { patch } from "src/Api";
import { OpenBuySubmission } from "src/buys/modules/open-buys/types";
import withModalPortal from "src/components/withModalPortal";
import SmallModal from "src/modals/SmallModal";
import { BlueButton } from "src/profile/components/Buttons";
import styled from "styled-components";

interface Props {
    handleClose: VoidFunction;
    submission: OpenBuySubmission;
    getSubmission: VoidFunction;
}

const Star = styled.img`
    width: 40px;
`;

const StyledBlueButton = styled(BlueButton)`
    height: 32px;
    margin-left: auto;
    margin-top: 20px;
`;

const MediaScoreModal = ({ handleClose, submission, getSubmission }: Props) => {
    const [mediaQuality, setMediaQuality] = useState(
        submission.mediaQuality ?? 0
    );

    async function handUpdateMediaQualityScore() {
        await patch(`/v1/openBuySubmission/${submission.id}`, { mediaQuality });
        getSubmission();
        handleClose();
    }

    return (
        <SmallModal
            header={{
                text: "Score Media",
                handleClose
            }}
        >
            <>
                <Rating
                    fractions={2}
                    onChange={setMediaQuality}
                    initialRating={mediaQuality}
                    emptySymbol={<Star src="/star-gray.svg" />}
                    fullSymbol={<Star src="/star-yellow.svg" />}
                />
                <StyledBlueButton onClick={handUpdateMediaQualityScore}>
                    Update Media Quality Score
                </StyledBlueButton>
            </>
        </SmallModal>
    );
};

export default withModalPortal(MediaScoreModal);
