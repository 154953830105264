import React, { useEffect, useState } from "react";
import withAccordion from "src/campaigns/details/accordions/withAccordion";
import ModalList from "src/components/modals/components/ModalList";
import styled from "styled-components";
import { colors } from "src/constants";
import ApprovalInstructionSet from "./ApprovalInstructionSet";
import ChallengeBrief from "./ChallengeBrief";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { selectMarketplaceOpenBuy } from "src/marketplace/redux/selectors";
import { ReactComponent as Caret } from "src/components/icons/caret-small.svg";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import {
    OpenBuySubmission,
    OpenBuySubmissionSet
} from "src/buys/modules/open-buys/types";
import { get } from "src/Api";
import PastSubmissions from "./PastSubmissions";
import Infractions from "./Infractions";

const Wrapper = styled.div`
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export interface EmployeeLink {
    name: string;
    type: string;
    reppedPublisherId: number;
}

const ChallengeDetailsAccordion = ({
    submissionSet
}: {
    submissionSet: OpenBuySubmissionSet;
}) => {
    const openBuy = useSelector((state: GlobalState) =>
        selectMarketplaceOpenBuy(state, submissionSet.openBuyId)
    );

    const [employeeLink, setEmployeeLink] = useState<EmployeeLink | null>(null);
    const [
        currentSubmisison,
        setCurrentSubmission
    ] = useState<OpenBuySubmission | null>(null);

    const fetchEmployeeLink = async () => {
        try {
            let res = await get(
                `/v1/admin/publisher/${submissionSet.publisherId}/employeeLink`,
                {}
            );

            setEmployeeLink(res.data.data[0]);
        } catch (err) {
            console.error("error getting employee link: ", err);
        }
    };

    useEffect(() => {
        if (!employeeLink) fetchEmployeeLink();
    }, [submissionSet]);

    return (
        <Wrapper>
            {openBuy && (
                <>
                    <ModalList
                        listItems={[
                            {
                                label: "Challenge Name",
                                type: "string",
                                value: openBuy.name
                            },
                            {
                                label: "Challenge ID",
                                type: "id",
                                value: openBuy.id
                            },
                            {
                                label: "Budget",
                                type: "money",
                                value: openBuy.budgetAmount
                            },
                            {
                                label: "Remaining Budget",
                                type: "money",
                                value:
                                    openBuy.budgetAmount - openBuy.budgetSpend
                            }
                        ]}
                        header={"CHALLENGE DETAILS"}
                    />
                    <ApprovalInstructionSet
                        openBuy={openBuy}
                        currentInstructionId={
                            currentSubmisison?.instructionStepId
                        }
                    />
                    <ChallengeBrief openBuy={openBuy} />
                </>
            )}
            <PastSubmissions submissionSet={submissionSet} />
            <Infractions submissionSet={submissionSet} />
        </Wrapper>
    );
};

export default withAccordion(ChallengeDetailsAccordion);
