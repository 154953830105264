import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { patch } from "src/Api";
import AdminToolInput from "src/admin-tools/components/AdminToolInput";
import Tooltip from "src/components/Tooltip";
import { colors } from "src/constants";
import Modal from "src/modals/Modal";
import { BigBlueButton } from "src/profile/components/Buttons";
import { InstructionStepState } from "src/ui/instructions-sets/actions";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
`;

const MenuButton = styled.img`
    width: 16px;
    height: 16px;
    background: ${colors.gray2};
    border-radius: 100px;
    cursor: pointer;
`;

const DeleteButton = styled.img`
    width: 16px;
    height: 16px;
    opacity: 0.5;
    margin-bottom: 8px;
    cursor: pointer;
`;

const Action = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    height: 46px;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: ${colors.quaternaryLightGray};
    }
`;

const Caret = styled.img`
    transform: rotate(-90deg);
    height: 12px;
    width: 12px;
`;

const ModalContainer = styled.div`
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

interface Props {
    handleDelete?: VoidFunction;
    instruction: InstructionStepState;
}

const InstructionEditMenu = ({ handleDelete, instruction }: Props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const tooltipRef = useRef(null);
    const dispatch = useDispatch();

    const [submissionLimit, setSubmissionLimit] = useState(
        instruction.submissionLimit
    );

    const submit = async () => {
        try {
            await patch(`/v1/instructionStep/${instruction.id}`, {
                submissionLimit
            });
            dispatch(setPillNotificationText("Submission count updated"));
            setModal(false);
        } catch (err) {
            dispatch(
                setPillNotificationText(
                    "Failed to update submission count",
                    "danger"
                )
            );
        }
    };

    return (
        <>
            <Main ref={tooltipRef}>
                <MenuButton
                    ref={tooltipRef}
                    src="/modal-ellipsis.svg"
                    alt=""
                    onClick={() => setModal(true)}
                />
                {handleDelete && (
                    <DeleteButton
                        // src="/close.svg"
                        src="/marketplace-instructions-delete-icon.svg"
                        alt="instruction step delete button"
                        onClick={handleDelete}
                    />
                )}
            </Main>
            {modal && (
                <Modal
                    onClick={() => setModal(false)}
                    style={{ padding: "12px", gap: "10px", zIndex: 3 }}
                >
                    <ModalContainer>
                        <AdminToolInput
                            type="number"
                            value={submissionLimit}
                            onChange={v => setSubmissionLimit(parseInt(v))}
                            label="SUBMISSION LIMIT"
                        />
                        <BigBlueButton onClick={submit}>Update</BigBlueButton>
                    </ModalContainer>
                </Modal>
            )}
        </>
    );
};

export default InstructionEditMenu;
