import { Genre, Selected, Country, CampaignFilters } from "../types";

// Change sort name to req.query name in DB
export const getSortByParameter = (sort: String) => {
    const sorts = {
        "Newest First": "newest-first",
        "Oldest First": "oldest-first",
        "Payout: Highest to Lowest": "payout-highest-first",
        "Payout: Lowest to Highest": "payout-lowest-first",
        "Alphabetical: A-Z": "alphabetically",
        "Alphabetical: Z-A": "alphabetically-desc"
    };
    return sorts[sort as keyof typeof sorts] || null;
};

// Parse fetched genre or region data into array for display text
export const parseFetchedData = (arr: (Country | Genre)[]) => {
    return arr.map(item => item.name);
};

// Reformat array of genres or countries into selected object format
export const getSelected = (arr: string[]) => {
    const newObj: Selected = {};
    arr.forEach(item => {
        newObj[item] = true;
    });
    return newObj;
};

// Filter out banned countries from all countries
export const filterBannedCountries = (
    allCountries: Country[],
    banned: string[]
) => {
    return allCountries.filter(country => !banned.includes(country.code));
};
