import {
    DirectBuyStep,
    DirectBuyStepAction,
    DirectBuyStepCollection
} from "../../../modules/direct-buy-step/types";
import { DirectBuyStepsEntitiesState } from "../../../modules/direct-buy-step/reducer";
import { ApprovalStatus } from "../../../../types/ApprovalStatus";

export function getStatusImageDescriptionForDirectBuyStepAction(
    action: DirectBuyStepAction,
    collection: DirectBuyStepCollection,
    steps: DirectBuyStepsEntitiesState
): [string, string] {
    const yellow = "/progress_yellow/progress_yellow@2x.png";
    const green = "/progress_green/progress_green@2x.png";
    const red = "/progress_red/progress_red@2x.png";

    const parentStep = steps[collection.parent];

    const allSteps: DirectBuyStep[] = [];
    let pendingSteps = 0;
    let rejectedSteps = 0;
    const rejectionReasons: string[] = [];
    let approvedSteps = 0;

    switch (action) {
        case DirectBuyStepAction.customMedia:
            const customMedia = parentStep.customMedia;
            if (!customMedia) {
                return [yellow, "No media uploaded"];
            } else if (customMedia.mediaStatus === "approved") {
                return [green, "Media approved, please proceed"];
            } else if (customMedia.mediaStatus === "rejected") {
                return [
                    red,
                    `Media rejected${
                        parentStep.rejectionReason
                            ? `: ${parentStep.rejectionReason}`
                            : ""
                    }`
                ];
            } else {
                return [yellow, "Media under review"];
            }
        case DirectBuyStepAction.screenshot:
            collection.combined.forEach(stepId => {
                const step = steps[stepId];
                if (step.requiresScreenshot) {
                    allSteps.push(step);
                    if (step.screenshotUrl) {
                        pendingSteps += 1;
                    }
                    switch (step.status) {
                        case ApprovalStatus.approved:
                            approvedSteps += 1;
                            break;
                        case ApprovalStatus.rejected:
                            rejectedSteps += 1;
                            if (step.rejectionReason) {
                                rejectionReasons.push(step.rejectionReason);
                            }
                    }
                }
            });
            if (allSteps.length === 1) {
                if (rejectedSteps === 1) {
                    return [
                        red,
                        `Screenshot rejected${
                            allSteps[0].rejectionReason
                                ? `: ${allSteps[0].rejectionReason}`
                                : ""
                        }`
                    ];
                } else if (approvedSteps === 1) {
                    return [green, "Screenshot approved"];
                } else {
                    if (pendingSteps === 0) {
                        return [yellow, "Please upload your screenshot"];
                    } else {
                        return [
                            yellow,
                            "Screenshot pending, please wait for approval"
                        ];
                    }
                }
            } else {
                if (rejectedSteps > 0) {
                    return [
                        red,
                        `${rejectedSteps}/${
                            allSteps.length
                        } screenshots rejected${
                            rejectionReasons.length > 0
                                ? `: ${rejectionReasons.join(", ")}`
                                : ""
                        }`
                    ];
                } else if (approvedSteps === allSteps.length) {
                    return [
                        green,
                        `${approvedSteps}/${allSteps.length} screenshots approved`
                    ];
                } else {
                    if (pendingSteps === 0) {
                        return [yellow, "No screenshots uploaded"];
                    } else if (pendingSteps === allSteps.length) {
                        return [
                            yellow,
                            `${pendingSteps}/${allSteps.length} screenshots pending, please wait for approval`
                        ];
                    } else {
                        return [
                            yellow,
                            `${pendingSteps}/${allSteps.length} screenshots pending, please upload the others`
                        ];
                    }
                }
            }
        case DirectBuyStepAction.linkSubmission:
            collection.combined.forEach(stepId => {
                const step = steps[stepId];
                if (step.requiresLinkSubmission) {
                    allSteps.push(step);
                    if (step.linkSubmission) {
                        pendingSteps += 1;
                    }
                    switch (step.status) {
                        case ApprovalStatus.approved:
                            approvedSteps += 1;
                            break;
                        case ApprovalStatus.rejected:
                            rejectedSteps += 1;
                            if (step.rejectionReason) {
                                rejectionReasons.push(step.rejectionReason);
                            }
                    }
                }
            });

            if (allSteps.length === 1) {
                if (rejectedSteps === 1) {
                    return [
                        red,
                        `Link rejected${
                            allSteps[0].rejectionReason
                                ? `: ${allSteps[0].rejectionReason}`
                                : ""
                        }`
                    ];
                } else if (approvedSteps === 1) {
                    return [green, "Link approved"];
                } else {
                    if (pendingSteps === 0) {
                        return [yellow, "Please submit your link"];
                    } else {
                        return [
                            yellow,
                            "Link pending, please wait for approval"
                        ];
                    }
                }
            } else {
                if (rejectedSteps > 0) {
                    return [
                        red,
                        `${rejectedSteps}/${allSteps.length} links rejected${
                            rejectionReasons.length > 0
                                ? `: ${rejectionReasons.join(", ")}`
                                : ""
                        }`
                    ];
                } else if (approvedSteps === allSteps.length) {
                    return [
                        green,
                        `${approvedSteps}/${allSteps.length} links approved`
                    ];
                } else {
                    if (pendingSteps === 0) {
                        return [yellow, "No links submitted"];
                    } else if (pendingSteps === allSteps.length) {
                        return [
                            yellow,
                            `${pendingSteps}/${allSteps.length} links pending, please wait for approval`
                        ];
                    } else {
                        return [
                            yellow,
                            `${pendingSteps}/${allSteps.length} links pending, please submit the others`
                        ];
                    }
                }
            }
        case DirectBuyStepAction.textSubmission:
            if (!parentStep.textSubmission) {
                return [yellow, "Please submit your response"];
            } else {
                switch (parentStep.status) {
                    case ApprovalStatus.pending:
                        return [
                            yellow,
                            "Submission pending, please wait for approval"
                        ];
                    case ApprovalStatus.approved:
                        return [green, "Submission approved"];
                    case ApprovalStatus.rejected:
                        return [
                            red,
                            `Submission rejected${
                                parentStep.rejectionReason
                                    ? `: ${parentStep.rejectionReason}`
                                    : ""
                            }`
                        ];
                }
            }
    }
}
