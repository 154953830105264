import React, { useState } from "react";
import { errorToString, isMobileSafari, makeId } from "../../../../util";
import { storage } from "../../../../firebase/FirebaseConfig";
import { post } from "../../../../Api";
import { DirectBuyStep } from "../../../modules/direct-buy-step/types";
import { addNotification } from "../../../../ui/notifications/actions";
import { colors, fonts } from "../../../../constants";
import { updateDirectBuyStep } from "../../../actions";
import styled from "styled-components";
import LoadingButton from "../../../../components/LoadingButton";
import { useDispatch } from "react-redux";
import { ReactComponent as PlusIcon } from "../../../../components/icons/card-circle-plus.svg";
import DirectBuyDetailsCustomMediaPreview from "./DirectBuyDetailsCustomMediaPreview";

const Main = styled.div<{ edit: boolean }>`
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 6px;
    border: 1px solid ${colors.mediumTeal};
    ${props =>
        !props.edit &&
        `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    `}
`;

const Description = styled.div`
    font-size: ${fonts.subtitle}px;
    line-height: 20px;
    color: ${colors.primaryGray};
`;

const Progress = styled.div`
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: white;
    margin-bottom: -40px;
    z-index: 99;
    justify-content: end;
    padding: 10px;
`;

const ButtonContainer = styled.div<{ edit: boolean }>`
    position: relative;
    justify-content: center;
    display: flex;
    ${props =>
        props.edit &&
        `
    position: relative;
    top: -50px;
    justify-content: center;
    display: flex;
    `}
`;

const InputContainer = styled.label`
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;
`;

interface Props {
    step: DirectBuyStep;
    upload?: { label: string };
}

const DirectBuyDetailsCustomMediaUploadCard = ({ step, upload }: Props) => {
    const dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    function fileUpload(e: any) {
        e.preventDefault();
        const allowedTypes = [
            "image/jpeg",
            "image/png",
            "video/mp4",
            "video/quicktime"
        ];
        const file = e.currentTarget.files[0];

        if (file && allowedTypes.includes(file.type)) {
            let type = file.type.split("/")[1];

            if (type === "quicktime") {
                type = "mov";
            }

            const id = `${localStorage.getItem("userid")}_${makeId(
                50
            )}.${type}`;
            let ref = storage
                .ref()
                .child(`directBuyCustomMedia/${id}`)
                .put(file);

            setUploading(true);
            ref.then(() => {
                if (file.type.split("/")[0] === "video") {
                    createCoverPhoto(file, id);
                } else {
                    finishUpload(id, id, "photo");
                }
            }).catch(error => {
                dispatch(
                    addNotification(
                        `Unable to upload media: ${errorToString(error)}`,
                        colors.primaryRed
                    )
                );
                setUploading(false);
            });

            ref.on("state_changed", (snapshot: any) => {
                setProgress(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
            });
        }
    }

    function createCoverPhoto(file: File, videoId: string) {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        video.preload = "metadata";
        video.crossOrigin = "Anonymous";
        video.src = URL.createObjectURL(file);
        if (isMobileSafari()) {
            video.load();
        } else {
            video.muted = true;
            video.play();
        }
        video.onloadeddata = () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d");

            if (!context) {
                return null;
            }

            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(blob => {
                if (!blob) return null;

                const id = `${localStorage.getItem("userid")}_${makeId(
                    50
                )}.jpeg`;
                const ref = storage
                    .ref()
                    .child(`directBuyCustomMedia/${id}`)
                    .put(blob);

                ref.then(() => {
                    finishUpload(videoId, id, "video");
                }).catch(error => {
                    dispatch(
                        addNotification(
                            `Unable to upload media: ${errorToString(error)}`,
                            colors.primaryRed
                        )
                    );
                    setUploading(false);
                });
                ref.on("state_changed", (snapshot: any) => {
                    setProgress(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                });
            }, "image/jpeg");
        };
    }

    function finishUpload(
        filename: string,
        coverPhoto: string,
        type: "video" | "photo"
    ) {
        post(`/v1/directBuyStep/${step.id}/customMedia`, {
            filename,
            coverPhoto,
            type
        })
            .then(({ data }) => {
                dispatch(updateDirectBuyStep(data.data));
                setUploading(false);
            })
            .catch(error => {
                dispatch(
                    addNotification(
                        `Unable to upload media: ${errorToString(error)}`,
                        colors.primaryRed
                    )
                );
                setUploading(false);
            });
    }

    return (
        <Main edit={!!step.customMedia}>
            {step.customMedia ? (
                <>
                    <Progress style={{ display: !uploading ? "none" : "flex" }}>
                        Progress: {progress.toFixed(0)}%
                    </Progress>
                    <DirectBuyDetailsCustomMediaPreview
                        customMedia={step.customMedia}
                    />
                </>
            ) : (
                <>
                    <PlusIcon />
                    <Description>
                        {uploading
                            ? `Progress: ${progress.toFixed(0)}%`
                            : "Click the button below to upload your media"}
                    </Description>
                </>
            )}

            {upload && (
                <ButtonContainer edit={!!step.customMedia}>
                    <LoadingButton
                        text={upload.label}
                        onClick={() => console.log("click")}
                        isLoading={uploading}
                        width={"fit-content"}
                        height={"32px"}
                        padding={"9px 15px"}
                        spinnerSize={14}
                        borderColor={colors.mediumTeal}
                        textColor={colors.primaryBlue}
                        textSize={"12px"}
                        textWeight={400}
                    />
                    <InputContainer>
                        <input
                            type={"file"}
                            accept={
                                "image/png, image/jpeg, video/mp4, video/quicktime"
                            }
                            style={{ display: "none" }}
                            onChange={fileUpload}
                        />
                    </InputContainer>
                </ButtonContainer>
            )}
        </Main>
    );
};

export default DirectBuyDetailsCustomMediaUploadCard;
