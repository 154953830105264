import React from "react";
import {
    DirectBuyStepCollection,
    DirectBuyStepAction
} from "../../../modules/direct-buy-step/types";
import { DirectBuyStepsEntitiesState } from "../../../modules/direct-buy-step/reducer";
import { ResourceContainer, ResourceDescription } from "../styledComponents";
import DirectBuyDetailsStepCollectionStatus from "../components/DirectBuyDetailsStepCollectionStatus";
import DirectBuyDetailsTextSubmissionCell from "./DirectBuyDetailsTextSubmissionCell";
import { StepById } from "src/buys/modules/open-buys/reducer";
import { useDispatch, useSelector } from "react-redux";
import { SubmissionButton } from "../DirectBuyDetailsStepCollection";
import { setOpenBuySubmissionModal } from "src/buys/actions";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

interface Props {
    collection: DirectBuyStepCollection;
    steps: DirectBuyStepsEntitiesState | StepById;
    open: boolean;
}

const DirectBuyDetailsTextSubmissions = ({
    collection,
    steps,
    open
}: Props) => {
    const dispatch = useDispatch();
    const parentStep = steps[collection.parent];

    const showButton = !useSelector(
        selectFeatureFlags(featureFlags.ChallengesGroupedSubmissions)
    );

    if (!parentStep.requiresTextSubmission) {
        return null;
    }
    if (open)
        return showButton ? (
            <SubmissionButton
                onClick={() =>
                    dispatch(setOpenBuySubmissionModal(collection.parent))
                }
            >
                Submit your spark code
            </SubmissionButton>
        ) : null;
    return (
        <ResourceContainer>
            <ResourceDescription>CUSTOM RESPONSE</ResourceDescription>
            <DirectBuyDetailsTextSubmissionCell step={parentStep} />
            <DirectBuyDetailsStepCollectionStatus
                collection={collection}
                steps={steps}
                action={DirectBuyStepAction.textSubmission}
            />
        </ResourceContainer>
    );
};

export default DirectBuyDetailsTextSubmissions;
