import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "src/components/LoadingButton";
import { colors, fonts } from "src/constants";
import EmojiModal from "src/modals/EmojiModal";
import { GlobalState } from "src/reducers";
import { setLinkListError } from "src/ui/link-lists/actions";
import styled from "styled-components";
import { editLinkList } from "../actions";
import { getLinkListError } from "../selectors";

const Header = styled.div`
    font-size: 18px;
    font-weight: 700;
`;

const LinkText = styled.div`
    font-size: ${fonts.subtitle}px;
    color: ${colors.tertiaryGray};
    margin-top: 10px;
`;

const LinkName = styled.input`
    font-size: ${fonts.subtitle}px;
    text-decoration: none;
    outline: none;
    border: none;
    margin-bottom: 15px;
`;

const Error = styled.div`
    font-size: ${fonts.subtitle}px;
    color: ${colors.primaryRed};
`;

interface Props {
    open: boolean;
    onClose: () => void;
}

const LinkListCreateModal = ({ open, onClose }: Props) => {
    const [name, setName] = useState(undefined);
    const error = useSelector((state: GlobalState) =>
        getLinkListError(state, "LinkListCreateModal")
    );
    const photoUrl = useSelector(
        (state: GlobalState) => state.profile.profile?.profile_photo_url
    );
    const dispatch = useDispatch();

    if (!open) return null;
    return (
        <EmojiModal icon="/clapper_board.svg" localClearModal={onClose}>
            <Header>Create New Plug Link List</Header>
            <LinkText>https://plugco.in/link/@</LinkText>
            <LinkName
                placeholder="Your Link list's name"
                type="text"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
            />
            <LoadingButton
                text="Create"
                height="48px"
                width="100%"
                backgroundColor={colors.primaryBlue}
                textColor={colors.white}
                onClick={() => {
                    name
                        ? dispatch(
                              editLinkList({
                                  name: "name",
                                  value: name || "",
                                  initialPhoto: photoUrl || ""
                              })
                          )
                        : dispatch(
                              setLinkListError(
                                  "Name is required to create a list",
                                  "LinkListCreateModal"
                              )
                          );
                }}
            />
            <Error>{error}</Error>
        </EmojiModal>
    );
};

export default LinkListCreateModal;
