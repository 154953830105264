import React from "react";
import styled, { keyframes } from "styled-components";
import PaddingAround from "../../components/PaddingAround";

const animation = keyframes`
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
`;

const Loader = styled.div`
    .shimmer {
        background: #f3f6f7
            linear-gradient(
                to right,
                #f3f6f7 0%,
                #eef1f2 20%,
                #f3f6f7 40%,
                #f3f6f7 100%
            )
            no-repeat;
        background-size: 800px 100px;

        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: ${animation};
        animation-timing-function: linear;
    }
`;

const DummyHeader = styled.div`
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
    justify-content: space-around;
`;

const DummyIcon = styled.div`
    height: 100px;
    width: 100px;
    border-radius: 100%;
    margin-right: 15px;
`;

const DummyCell = styled.div`
    height: 60px;
    border-radius: 6px;

    & + & {
        margin-top: 15px;
    }
`;

const CardLoader = () => {
    return (
        <PaddingAround>
            <Loader>
                <DummyCell className="shimmer" />
                <DummyHeader>
                    <DummyIcon className="shimmer" />
                    <DummyIcon className="shimmer" />
                    <DummyIcon className="shimmer" />
                </DummyHeader>
                <DummyCell className="shimmer" />
                <DummyCell className="shimmer" />
                <DummyCell className="shimmer" />
            </Loader>
        </PaddingAround>
    );
};

export default CardLoader;
