export enum PaymentConjunction {
    AND = "AND",
    OR = "OR"
}

export function paymentConjunctionToShortString(
    paymentConjunction: PaymentConjunction
) {
    switch (paymentConjunction) {
        case PaymentConjunction.AND:
            return "&";
        case PaymentConjunction.OR:
            return "or";
    }
}
