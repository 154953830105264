import React, { useEffect, useState } from "react";
import { DirectBuy } from "../../modules/direct-buy/types";
import { useSelector } from "react-redux";
import {
    selectDirectBuyStepEntities,
    selectDirectBuyStepsExpandedState
} from "../../selectors";
import { getDirectBuyStepCollectionArrayForDirectBuy } from "../../modules/direct-buy/util";
import DirectBuyDetailsStepCollection from "./DirectBuyDetailsStepCollection";
import { getTreatmentArrayForDirectBuyStepCollectionArray } from "../../modules/direct-buy-step/util";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { colors } from "src/constants";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${colors.lightGray2};
    padding: 12px 16px;
    gap: 12px;
    &:first-child {
        margin-top: 20px;
    }
`;

interface Props {
    directBuy: DirectBuy;
    expanded: boolean;
    openBuyId: number | null;
}

const DirectBuyDetailsInstructions = ({
    directBuy,
    expanded,
    openBuyId
}: Props) => {
    const steps = useSelector(selectDirectBuyStepEntities);
    const stepsExpandedState = useSelector(selectDirectBuyStepsExpandedState);
    const [stepCollectionArray, setStepCollectionArray] = useState(() =>
        getDirectBuyStepCollectionArrayForDirectBuy(
            directBuy,
            steps,
            stepsExpandedState
        )
    );
    const [treatmentArray, setTreatmentArray] = useState(() =>
        getTreatmentArrayForDirectBuyStepCollectionArray(
            stepCollectionArray,
            steps
        )
    );
    useEffect(() => {
        const newStepCollectionArray = getDirectBuyStepCollectionArrayForDirectBuy(
            directBuy,
            steps,
            stepsExpandedState
        );
        setStepCollectionArray(newStepCollectionArray);
        setTreatmentArray(
            getTreatmentArrayForDirectBuyStepCollectionArray(
                newStepCollectionArray,
                steps
            )
        );
    }, [directBuy, steps, stepsExpandedState]);
    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        }
    }, [firstRender]);
    let position = 0;
    return (
        <AnimatePresence>
            {expanded && (
                <Main>
                    {stepCollectionArray.map((collection, index) => {
                        if (steps[collection.parent].requiresRawMedia) return;
                        position++;
                        return (
                            <DirectBuyDetailsStepCollection
                                key={collection.parent}
                                collection={collection}
                                treatment={treatmentArray[index]}
                                steps={steps}
                                stepsExpandedState={stepsExpandedState}
                                firstRender={firstRender}
                                openBuyId={openBuyId}
                                position={position}
                            />
                        );
                    })}
                </Main>
            )}
        </AnimatePresence>
    );
};

export default DirectBuyDetailsInstructions;
