import React, { useState } from "react";
import { Connection } from "../types";
import EmptyCard from "./EmptyCard";
import { ReactComponent as PlusIcon } from "../../components/icons/card-circle-plus.svg";
import styled from "styled-components";
import { colors } from "src/constants";
import GenericContainer from "./GenericContainer";
import { flexColumnCenter } from "src/utils/styles/snippets";
import { OutlineEmptyButton } from "./Buttons";

const Wrapper = styled.div`
    position: relative;
`;
const DragCover = styled.div<{ isHovered: boolean }>`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    pointer-events: ${props => (props.isHovered ? "initial" : "none")};
`;
const StyledIcon = styled(PlusIcon)`
    & > path,
    & > circle {
        stroke: ${colors.primaryBlue};
    }
`;
const Box = styled(GenericContainer)<{ $isHovered: boolean }>`
    ${flexColumnCenter()};
    width: 100%;
    height: 170px;
    padding: 0px 22px;
    /* ${props =>
        props.$isHovered && `border: 1px solid ${colors.primaryBlue};`}; */
    /* padding: 18px 16px 20px; */
`;
const Text = styled.div`
    font-size: 14px;
    line-height: 20px;
    padding-top: 12px;
    color: ${colors.primaryGray};
`;
const FileUploadWrap = styled.label`
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    color: ${colors.primaryBlue};
    border-radius: 6px;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
            border: ${colors.darkTeal} solid 1px;
        }
    }

    &:disabled {
        color: ${colors.primaryGray};
        cursor: default;
    }
`;

interface Props {
    text: string;
    button?: {
        type: "file" | "button";
        text: string;
        callback: (e: any) => void;
    };
    connection?: Connection;
    padding?: string;
    onDragEnter?: (e: React.DragEvent<HTMLDivElement>) => void;
    onDragExit?: (e: React.DragEvent<HTMLDivElement>) => void;
    onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void;
    onDrop?: (e: React.DragEvent<HTMLDivElement>) => void;
    className?: string;
    isDisabled?: boolean;
    isMultiUpload?: boolean;
}

const DragCard = ({
    text,
    button,
    onDragEnter,
    onDragExit,
    onDragOver,
    onDrop,
    isDisabled,
    isMultiUpload = false,
    className
}: Props) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        if (!isDisabled) {
            onDragEnter && onDragEnter(e);
            setIsHovered(true);
        }
    };
    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        if (!isDisabled) {
            onDragExit && onDragExit(e);
            setIsHovered(false);
        }
    };
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!isDisabled) {
            e.stopPropagation();
            onDragOver && onDragOver(e);
        }
    };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsHovered(false);
        if (!isDisabled) {
            e.stopPropagation();
            onDrop && onDrop(e);
        }
    };

    return (
        <Wrapper
            onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
                e.preventDefault();
                e.stopPropagation();
                setIsHovered(true);
            }}
        >
            <DragCover
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                isHovered={isHovered}
            />
            <Box className={className} $isHovered={isHovered}>
                {isHovered && !isDisabled ? <StyledIcon /> : <PlusIcon />}
                <Text>{text}</Text>
                {button && button.type === "file" ? (
                    <OutlineEmptyButton
                        style={
                            isDisabled ? { color: `${colors.primaryGray}` } : {}
                        }
                        as={"label"}
                    >
                        {button.text}
                        <input
                            type={"file"}
                            accept={
                                "image/png, image/jpeg, video/mp4, video/quicktime"
                            }
                            style={{ display: "none" }}
                            onChange={button.callback}
                            disabled={isDisabled}
                            multiple={isMultiUpload}
                        />
                    </OutlineEmptyButton>
                ) : button && button.type === "button" ? (
                    <OutlineEmptyButton onClick={button.callback}>
                        {button.text}
                    </OutlineEmptyButton>
                ) : null}
            </Box>
        </Wrapper>
    );
};

export default DragCard;
