import React, { useState } from "react";
import { getApi } from "../../../Api";
import { ContestLeaderboard, ContestRank } from "../types";
import styled from "styled-components";
import { SimpleButton } from "../components/styledComponents";
import RankCell from "./RankCell";
import { colors, maxWidthDesktop, fonts } from "src/constants";

interface StyleProps {
    page?: true;
}

const Main = styled.div<StyleProps>`
    background-color: #f8fbfc;
    padding-bottom: 0px;
    box-sizing: border-box;
    font-size: ${fonts.subtitle}px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;
    ${props => props.page && "padding-top: 15px;"}

    @media (max-width: ${maxWidthDesktop}px) {
        padding-bottom: 15px;
        border: none;
        border-radius: 0px;
    }
`;

const Title = styled.div`
    color: darkgray;
    margin: 15px 0 15px 15px;
    text-align: left;
    font-size: ${fonts.subtitle}px;
    font-weight: bold;
    text-transform: uppercase;

    @media (max-width: ${maxWidthDesktop}px) {
        margin: 0 0 15px 15px;
    }
`;

const Board = styled.div`
    background-color: white;
    padding: 0 15px 15px;
    box-sizing: border-box;
    border-top: 1px solid #e0eef2;
    font-size: ${fonts.subtitle}px;

    @media (max-width: ${maxWidthDesktop}px) {
        border-bottom: 1px solid #e0eef2;
    }
`;

const Divider = styled.hr<{ hidden: boolean }>`
    display: none;

    @media (max-width: ${maxWidthDesktop}px) {
        display: ${props => (props.hidden ? "none" : "")};
        margin: 0px 17px 0px 17px;
        border-top: 1px solid ${colors.mediumTeal};
    }
`;

interface Props {
    leaderboard: ContestLeaderboard;
    setLeaderboard: (leaderboard: ContestLeaderboard) => void;
    card?: true;
}

const Leaderboard = ({ leaderboard, setLeaderboard }: Props) => {
    const self = leaderboard.my_ranking;
    return (
        <Main>
            <Title>{leaderboard.name} Leaderboard</Title>
            <Board>
                {leaderboard.ranking.map((rank, idx) => (
                    <>
                        <RankCell
                            key={idx}
                            leaderboardItem={rank}
                            self={rank.rank === self.rank ? self : undefined}
                        />
                        <Divider
                            hidden={leaderboard.ranking.length - 1 === idx}
                        />
                    </>
                ))}
                <SimpleButton onClick={() => setLeaderboard(leaderboard)}>
                    View Leaderboard
                </SimpleButton>
            </Board>
        </Main>
    );
};

export default Leaderboard;
