import React, { ReactNode, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { GlobalState } from "src/reducers";
import styled from "styled-components";

const Main = styled.div``;

interface Props {
    children: ReactNode;
}

const ScrollRestoration = ({ children }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const scrollRestorationTop = useSelector(
        (state: GlobalState) => state.ui.scrollRestoration[location.pathname]
    );

    useEffect(() => {
        if (ref.current) {
            if (scrollRestorationTop) {
                window.scrollTo(0, scrollRestorationTop);
            }
        }
    }, []);

    return <Main ref={ref}>{children}</Main>;
};

export default ScrollRestoration;
