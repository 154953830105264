import React, { useState } from "react";
import styled from "styled-components";
import { FlexVCSpaceBetween } from "src/components/CommonStyles";
import { ReactComponent as Knob } from "src/components/icons/knob.svg";
import { ReactComponent as KnobActive } from "src/components/icons/knob-active.svg";
import {
    Label,
    Option,
    OptionList,
    OptionText
} from "./components/CommonStyles";
import CircleIcon from "src/components/CircleIcon";
import { colors, fonts } from "src/constants";
import CheckBox from "src/components/CheckBox";
import { MediaFilters } from "../types";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";

const OnlyYourRow = styled.div`
    ${FlexVCSpaceBetween}
    font-size: ${fonts.smallTitle}px;
    font-weight: 500;
    line-height: 16px;
`;

const Icon = styled.div`
    cursor: pointer;
    user-select: none;
`;

interface Props {
    setFilters: (filters: MediaFilters) => void;
    filters: MediaFilters;
}

const MediaFilterOwner = ({ filters, setFilters }: Props) => {
    const promoting = useSelector(
        (state: GlobalState) => state.ui.promote.promoting
    );
    function toggleIsOwner() {
        setFilters({ ...filters, isOwner: !filters.isOwner });
    }

    function setMediaStatus(mediaStatus: MediaFilters["status"]) {
        setFilters({ ...filters, status: mediaStatus });
    }

    function generateSetMediaStatus(key: "pending" | "approved" | "rejected") {
        return () =>
            setMediaStatus({ ...filters.status, [key]: !filters.status[key] });
    }

    return (
        <>
            <OnlyYourRow>
                Only Your Media
                <Icon>
                    {filters.isOwner ? (
                        <KnobActive onClick={toggleIsOwner} />
                    ) : (
                        <Knob onClick={toggleIsOwner} />
                    )}
                </Icon>
            </OnlyYourRow>
            {!promoting && filters.isOwner && (
                <div>
                    <Label>MEDIA STATUS</Label>
                    <OptionList>
                        <Option onClick={generateSetMediaStatus("approved")}>
                            <CircleIcon
                                type="check"
                                color={colors.primaryGreen}
                            />
                            <OptionText>Approved</OptionText>
                            <CheckBox selected={filters.status.approved} />
                        </Option>
                        <Option onClick={generateSetMediaStatus("pending")}>
                            <CircleIcon
                                type="ellipsis"
                                color={colors.primaryYellow}
                            />
                            <OptionText>Pending</OptionText>
                            <CheckBox selected={filters.status.pending} />
                        </Option>
                        <Option onClick={generateSetMediaStatus("rejected")}>
                            <CircleIcon type="x" color={colors.primaryRed} />
                            <OptionText>Error / Rejected</OptionText>
                            <CheckBox selected={filters.status.rejected} />
                        </Option>
                    </OptionList>
                </div>
            )}
        </>
    );
};

export default MediaFilterOwner;
