import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import InviteCode from "./InviteCode";
import PhoneAuth from "./PhoneAuth";
import AccessStatusBlocker from "../components/AccessStatusBlocker";
import AccountVerification from "./AccountVerification";
import PersonalDetails from "./PersonalDetails";
import { loggedIn } from "src/util";
import PreApprovalAccount from "./PreApprovalAccount";
import { getHasUIAccess } from "src/profile/selectors";
import { useSelector } from "react-redux";

const NormalPath = () => {
    const history = useHistory();
    const hasUIAccess = useSelector(getHasUIAccess);

    useEffect(() => {
        if (hasUIAccess) {
            history.push("/");
        } else if (window.location.pathname !== "/onboarding" && !loggedIn())
            history.push("/onboarding");
    }, []);

    return (
        <Switch>
            <Route exact path="/onboarding" component={InviteCode} />
            <Route path="/onboarding/phone" component={PhoneAuth} />

            <Route path="/onboarding/blocked" component={AccessStatusBlocker} />
            <Route path="/onboarding/verify" component={AccountVerification} />
            <Route path="/onboarding/details" component={PersonalDetails} />
            <Route
                path="/onboarding/preApproval"
                component={PreApprovalAccount}
            />

            <Redirect to={"/onboarding"} />
        </Switch>
    );
};

export default NormalPath;
