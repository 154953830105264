import React from "react";
import styled from "styled-components";
import { colors, sizes } from "../../constants";
import DirectBuysHeaderCell from "./DirectBuysHeaderCell";
import { DirectBuyStatus } from "../modules/direct-buy/types";
import { capitalizeFirstLetter } from "src/utils/functions/helpers";
import DirectBuysHeaderRowSection from "./DirectBuysHeaderRowSection";
import ScrollContainer from "src/campaigns/landing/components/ScrollContainer";

const Main = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

interface Props {
    available: number[];
    active: number[];
    recentlyCompleted: number[];
}

const DirectBuysHeaderRow = ({
    available,
    active,
    recentlyCompleted
}: Props) => {
    const arrowTopOffset = `calc(50% + ${sizes.personalOffers
        .headerRowHeaderHeight / 2}px)`;

    return (
        <ScrollContainer
            backgroundColor={colors.lightTeal}
            border={`${colors.mediumTeal} solid 1px`}
            borderRadius={12}
            arrowTopOffset={arrowTopOffset}
        >
            <Main>
                {available.length > 0 && (
                    <DirectBuysHeaderRowSection
                        divider={active.length > 0}
                        title={capitalizeFirstLetter(DirectBuyStatus.available)}
                    >
                        {available.map((id: number) => {
                            return (
                                <DirectBuysHeaderCell
                                    key={id}
                                    directBuyId={id}
                                />
                            );
                        })}
                    </DirectBuysHeaderRowSection>
                )}

                {active.length > 0 && (
                    <DirectBuysHeaderRowSection
                        divider={recentlyCompleted.length > 0}
                        title={capitalizeFirstLetter(DirectBuyStatus.active)}
                    >
                        {active.map((id: number) => {
                            return (
                                <DirectBuysHeaderCell
                                    key={id}
                                    directBuyId={id}
                                />
                            );
                        })}
                    </DirectBuysHeaderRowSection>
                )}

                {recentlyCompleted.length > 0 && (
                    <DirectBuysHeaderRowSection
                        title={capitalizeFirstLetter(DirectBuyStatus.completed)}
                    >
                        {recentlyCompleted.map((id: number) => {
                            return (
                                <DirectBuysHeaderCell
                                    key={id}
                                    directBuyId={id}
                                />
                            );
                        })}
                    </DirectBuysHeaderRowSection>
                )}
            </Main>
        </ScrollContainer>
    );
};

export default DirectBuysHeaderRow;
