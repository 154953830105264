import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../modals/Modal";
import UpdateModal from "../../modals/UpdateModal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
    margin-bottom: 15px;
`;

const Error = styled.div`
    color: red;
    font-size: ${fonts.subtitle}px;
    margin-bottom: 10px;
`;

interface Props {
    publisherId: number;
    prevUsername?: string | null;
    updateUsername: (username: string, publisherId: number) => void;
    error: string | null;
}

const UsernameModal = ({
    prevUsername,
    updateUsername,
    publisherId,
    error
}: Props) => {
    const [username, setUsername] = useState(prevUsername || "");

    function handleSubmit() {
        if (username.length > 0 && prevUsername !== username) {
            updateUsername(username, publisherId);
        }
    }

    return (
        <Modal>
            <Main>
                <Title>Change Your Username</Title>
                <InputGroup>
                    <FormControl
                        placeholder={"Username"}
                        value={username}
                        onChange={({ currentTarget }: any) =>
                            setUsername(currentTarget.value)
                        }
                    />
                </InputGroup>
                {error ? <Error>{error}</Error> : null}
            </Main>
            <UpdateModal
                onUpdate={handleSubmit}
                active={username.length > 0 && prevUsername !== username}
            />
        </Modal>
    );
};

export default UsernameModal;
