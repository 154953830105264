import React from "react";
import { InstructionStepState } from "src/ui/instructions-sets/actions";
import CreateDropdownItem from "../components/CreateDropdownItem";
import InstructionsFormDragStepAreaItem from "../components/InstructionsFormStepDragAreaItem";
import {
    InstructionElementArrayKey,
    InstructionElementType
} from "../../utils/types";
import { fetchScript } from "src/campaigns/scripts/actions";
import { ScriptsEntitiesState } from "src/campaigns/scripts/reducer";
import { MediasEntitiesState } from "src/campaigns/medias/reducer";
import { AssetsEntitiesState } from "src/campaigns/assets/reducer";
import { Campaign, CampaignMedia } from "src/campaigns/types";
import { PageType } from "src/ui/instructions-sets/reducers";
import { ChildInstructionStep, InstructionStep } from "../types";
import { Media } from "src/media/types";

export function renderInstructionStepCarouselItems(
    title: InstructionElementType,
    currentInstructionStepElementIds: number[],
    instructionSteps: InstructionStepState[],
    currentInstructionStepIndex: number,
    currentInstructionStepKey: InstructionElementArrayKey,
    scriptsState: ScriptsEntitiesState,
    mediasState: MediasEntitiesState,
    assetsState: AssetsEntitiesState,
    currentCampaign: Campaign | undefined,
    dispatch: Function,
    pageType: PageType,
    currentInstructionSteps?: InstructionStep[]
) {
    // Rendering Scripts Dropdown Area Carousel Items -------------------------------------------------------------------
    if (title === "Scripts") {
        return currentInstructionStepElementIds.map(
            (id: number, index: number) => {
                const script = scriptsState[id];

                dispatch(fetchScript(id));
                if (script)
                    return (
                        <InstructionsFormDragStepAreaItem
                            key={`instruction-form-step-drag-area-${title}-${id}-${index}`}
                            isScript
                            title={script.title}
                            subtitle={script.text}
                            itemId={id}
                            itemIndex={index}
                            instructionSteps={instructionSteps}
                            currentInstructionStepIndex={
                                currentInstructionStepIndex
                            }
                            currentInstructionStepKey={
                                currentInstructionStepKey
                            }
                        />
                    );
            }
        );
    }

    // Rendering Media Dropdown Area Carousel Items -------------------------------------------------------------------
    else if (title === "Media") {
        const mediaElements = currentInstructionStepElementIds.map(
            (id: number, index: number) => {
                const media = mediasState[id];

                if (!!media) {
                    return (
                        <InstructionsFormDragStepAreaItem
                            key={`instruction-form-step-drag-area-${title}-${id}=${index}`}
                            itemId={id}
                            itemIndex={index}
                            item={
                                <CreateDropdownItem
                                    id={id}
                                    type="Media"
                                    campaignName={
                                        currentCampaign?.campaignName || ""
                                    }
                                    coverPhotoUrl={media.coverPhotoUrl}
                                    downloadLink={media.url}
                                    media={media}
                                />
                            }
                            instructionSteps={instructionSteps}
                            currentInstructionStepIndex={
                                currentInstructionStepIndex
                            }
                            currentInstructionStepKey={
                                currentInstructionStepKey
                            }
                        />
                    );
                }
            }
        );

        if (pageType === "Edit" && currentInstructionSteps) {
            // This section can be broken down into the following steps:
            //  1. Setting up the parent media item (first media item).
            //  2. Setting up the children media items (second+ media items).
            //  3. Creating an array of iterable existing media items to turn into media carousel elements.
            //  4. Returning an array of <InstructionsFormDragStepAreaItem /> components using the iterables in step 3.
            //
            //  NOTE : It is important to note that this only adds the list of media that are NOT available in the list
            //         of public media entities (e.g. maybe because the `should_show_in_app` property of a media item is `false`)

            interface MediaItem {
                [x: string]: CampaignMedia;
            }
            const currentInstructionStep =
                currentInstructionSteps[currentInstructionStepIndex];

            // If there is no parent media element (the first element to be rendered in the media items carousel), then
            // just render the list of `mediaElements`. Otherwise, render an array of media elements that include the parent
            // and/or child media elements.
            if (currentInstructionStep && currentInstructionStep.media) {
                // Adding existing media to list of media to display in the current instruction step media carousel.
                const parentMediaItem: MediaItem = {};
                if (!mediasState[currentInstructionStep.media.id]) {
                    parentMediaItem[currentInstructionStep.media.id] =
                        currentInstructionStep.media;
                }

                let childMediaItems: MediaItem[] = [];
                // Setting child media items if there are any.
                if (currentInstructionStep.instructionSteps) {
                    childMediaItems = currentInstructionStep.instructionSteps.reduce(
                        (results: any, item: ChildInstructionStep) => {
                            if (item.media && !mediasState[item.media.id]) {
                                results[item.media.id] = item.media;
                            }
                            return results;
                        },
                        {}
                    );
                }

                // List of media items we can iterate through to pass values into <InstructionsFormDragStepAreaItem />
                const instructionStepExistingMedia = Object.values({
                    ...parentMediaItem,
                    ...childMediaItems
                });

                let existingMediaElements: any[] = [];
                // If there is a parent media item, that means that there's at least one existing media item.
                // In that case, we want to turn it into a media carousel item.
                if (!!parentMediaItem) {
                    existingMediaElements = instructionStepExistingMedia.map(
                        (existingMedia: any, index: number) => {
                            if (!!existingMedia) {
                                return (
                                    <InstructionsFormDragStepAreaItem
                                        key={`instruction-form-step-drag-area-${title}-${existingMedia.id}-${index}`}
                                        itemId={existingMedia.id}
                                        itemIndex={index}
                                        item={
                                            <CreateDropdownItem
                                                id={existingMedia.id}
                                                type="Media"
                                                campaignName={
                                                    currentCampaign?.campaignName ||
                                                    ""
                                                }
                                                coverPhotoUrl={
                                                    existingMedia.coverPhotoUrl
                                                }
                                                downloadLink={existingMedia.url}
                                                media={existingMedia}
                                            />
                                        }
                                        instructionSteps={instructionSteps}
                                        currentInstructionStepIndex={
                                            currentInstructionStepIndex
                                        }
                                        currentInstructionStepKey={
                                            currentInstructionStepKey
                                        }
                                    />
                                );
                            }
                        }
                    );
                }

                return [...mediaElements, ...existingMediaElements];
            } else {
                return mediaElements;
            }
        } else {
            return mediaElements;
        }
    }

    // Rendering Assets Dropdown Area Carousel Items -------------------------------------------------------------------
    else if (title === "Assets") {
        return currentInstructionStepElementIds.map(
            (id: number, index: number) => {
                const asset = assetsState[id];

                if (!!asset) {
                    return (
                        <InstructionsFormDragStepAreaItem
                            key={`instruction-form-step-drag-area-${title}-${id}-${index}`}
                            itemId={id}
                            itemIndex={index}
                            item={
                                <CreateDropdownItem
                                    id={id}
                                    campaignName={
                                        currentCampaign?.campaignName || ""
                                    }
                                    coverPhotoUrl={asset.coverPhotoUrl}
                                    downloadLink={asset.url}
                                />
                            }
                            instructionSteps={instructionSteps}
                            currentInstructionStepIndex={
                                currentInstructionStepIndex
                            }
                            currentInstructionStepKey={
                                currentInstructionStepKey
                            }
                        />
                    );
                }
            }
        );
    } else {
        return [];
    }
}
