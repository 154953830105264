import {
    DELETE_LINK_LIST,
    DELETE_LINK_LIST_ITEM,
    EDIT_LINK_LIST,
    EDIT_LINK_LIST_ITEM,
    FETCH_LINK_LIST
} from "src/profile/link-lists/actions";
import { Action } from "src/types";

import { SET_LINK_LIST_ERROR, SET_LINK_LIST_TYPE } from "./actions";

export interface LinkListUIState {
    error: { error: string; component: string } | null;
    loading: boolean;
    createType?: string;
}

const initialLinkListUIState: LinkListUIState = {
    error: null,
    loading: true
};

const linkListUIReducer = (state = initialLinkListUIState, action: Action) => {
    switch (action.type) {
        case SET_LINK_LIST_ERROR:
            return { ...state, error: action.payload };
        case SET_LINK_LIST_TYPE:
            return { ...state, createType: action.payload };
        case FETCH_LINK_LIST.FAILURE:
            return {
                ...state,
                error: {
                    error: "Failed to load link list",
                    component: "LinkListCard"
                }
            };
        case FETCH_LINK_LIST.REQUEST:
        case DELETE_LINK_LIST_ITEM.REQUEST:
        case DELETE_LINK_LIST.REQUEST:
            return { ...state, loading: true };
        case EDIT_LINK_LIST_ITEM.REQUEST:
            return {
                ...state,
                loading: action.payload.photoUrl ? true : false
            };
        case FETCH_LINK_LIST.SUCCESS:
        case EDIT_LINK_LIST_ITEM.SUCCESS:
        case EDIT_LINK_LIST.SUCCESS:
        case DELETE_LINK_LIST_ITEM.SUCCESS:
        case DELETE_LINK_LIST.SUCCESS:
            return { ...initialLinkListUIState, loading: false };
        default:
            return state;
    }
};

export default linkListUIReducer;
