import { AnimatePresence } from "framer-motion";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import SetDatesScreen from "src/campaigns/highlights/SetDatesScreen";
import { convertMomentToDateString } from "../../components/datesUtil";
import FullScreenModal from "src/modals/FullScreenModal";
import styled from "styled-components";
import {
    Label,
    Option,
    OptionText,
    OptionList
} from "./components/CommonStyles";
import { MediaFilters } from "../types";

const Caret = styled.img`
    margin-left: auto;
    margin-right: 6px;
`;

interface Props {
    setFilters: (filters: MediaFilters) => void;
    filters: MediaFilters;
}

const MediaFilterTime = ({ filters, setFilters }: Props) => {
    const [timeSelectionModal, toggleTimeSelectionModal] = useState(false);

    function setTimeRange(dateRange: [Moment, Moment]) {
        setFilters({
            ...filters,
            startDate: dateRange[0],
            endDate: dateRange[1]
        });
        toggleTimeSelectionModal(false);
    }

    function clearTimeRange() {
        const nextFilters = { ...filters };
        delete nextFilters.startDate;
        delete nextFilters.endDate;
        setFilters(nextFilters);
        toggleTimeSelectionModal(false);
    }

    return (
        <div>
            <Label>TIME FRAME</Label>
            <OptionList>
                <Option onClick={() => toggleTimeSelectionModal(true)}>
                    <OptionText>
                        {filters.startDate && filters.endDate
                            ? convertMomentToDateString([
                                  filters.startDate,
                                  filters.endDate
                              ])
                            : "All Time"}
                    </OptionText>
                    <Caret src={"/caret.svg"} />
                </Option>
            </OptionList>
            <AnimatePresence>
                {timeSelectionModal && (
                    <FullScreenModal
                        closeModal={() => toggleTimeSelectionModal(false)}
                        keepFrozenOnClose
                    >
                        <SetDatesScreen
                            initialDates={[
                                moment().subtract(3, "days"),
                                moment()
                            ]}
                            onCancelTapped={() =>
                                toggleTimeSelectionModal(false)
                            }
                            onDoneTapped={setTimeRange}
                            title="Set Time Frame"
                            clearTimeRange={clearTimeRange}
                        />
                    </FullScreenModal>
                )}
            </AnimatePresence>
        </div>
    );
};

export default MediaFilterTime;
