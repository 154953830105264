import {
    all,
    call,
    fork,
    put,
    select,
    takeEvery,
    takeLeading
} from "redux-saga/effects";
import { normalize } from "normalizr";
import * as schema from "./schema";
import { Action, ResponseData } from "../types";
import { get, getApi, getPublic, patch, post, deleteReq } from "../Api";
import {
    emailPublisherMediaFailure,
    EMAIL_PUBLISHER_MEDIA,
    fetchCampaignAccessSuccess,
    fetchCampaignBudgetSuccess,
    fetchCampaignFailure,
    fetchCampaignInstallHistorySuccess,
    fetchCampaignMetricsSuccess,
    fetchCampaignPriceSuccess,
    fetchCampaignsFailure,
    fetchCampaignsSuccess,
    fetchCampaignStatistics,
    fetchCampaignStatisticsFailure,
    fetchCampaignStatisticsSuccess,
    fetchCampaignSuccess,
    FETCH_CAMPAIGN,
    FETCH_CAMPAIGNS,
    FETCH_CAMPAIGN_BUDGET,
    FETCH_CAMPAIGN_STATISTICS,
    REQUEST_CAMPAIGN_ACCESS,
    REQUEST_CAMPAIGN_NOTIFICATION
} from "./actions";
import { mediaEmailModal } from "src/ui/modals/actions";
import { FETCH_SHOUTOUTS, fetchShoutoutsSuccess } from "./shoutouts/actions";
import {
    fetchCampaignMediaRules,
    fetchCampaignMediaRulesSuccess,
    FETCH_CAMPAIGN_MEDIA_RULES
} from "./media-rules/actions";
import {
    fetchCampaignScripts,
    fetchCampaignScriptsSuccess,
    fetchCampaignScriptSuccess,
    fetchScript,
    FETCH_CAMPAIGN_SCRIPT,
    FETCH_CAMPAIGN_SCRIPTS,
    FETCH_SCRIPT
} from "./scripts/actions";
import { fetchCampaignHighlights } from "./highlights/actions";
import highlightsSaga, {
    fetchCampaignHighlightsRequest
} from "./highlights/saga";
import {
    fetchCampaignAssets,
    fetchCampaignAssetsSuccess,
    fetchCampaignAssetSuccess,
    fetchScriptAssets,
    fetchScriptAssetsSuccess,
    FETCH_CAMPAIGN_ASSET,
    FETCH_CAMPAIGN_ASSETS,
    FETCH_SCRIPT_ASSETS
} from "./assets/actions";
import {
    fetchCampaignMedias,
    fetchCampaignMediasSuccess,
    fetchCampaignMediaSuccess,
    fetchCampaignPublicMediasSuccess,
    fetchScriptMedias,
    fetchScriptMediasSuccess,
    FETCH_CAMPAIGN_MEDIA,
    FETCH_CAMPAIGN_MEDIAS,
    FETCH_CAMPAIGN_PUBLIC_MEDIAS,
    FETCH_SCRIPT_MEDIAS
} from "./medias/actions";
import {
    fetchCampaignCaptions,
    fetchCampaignCaptionsSuccess,
    FETCH_CAMPAIGN_CAPTIONS
} from "./captions/actions";
import { submitEvent } from "../events/actions";
import { submitEventRequest } from "../events/saga";
import { AccessState, Script } from "./types";
import {
    addNotification,
    setPillNotificationText
} from "../ui/notifications/actions";
import {
    createCampaignCustomLinkSuccess,
    CREATE_CAMPAIGN_CUSTOM_LINK,
    fetchCampaignCustomLinks,
    fetchCampaignCustomLinksSuccess
} from "./custom-links/actions";
import placementsSaga from "./placements/saga";
import contestsSaga from "./contests/saga";
import { getAuthState, selectAdminStatus } from "../auth/selectors";
import { CampaignAction } from "../auth/roles";
import { check } from "../auth/hooks/useAuthorization";
import { setCurrentModalMedias } from "../ui/medias/actions";
import {
    fetchContestFailure,
    fetchContestSuccess,
    FETCH_CONTEST
} from "./contests/actions";
import { errorToString, newToOldPrice } from "src/util";
import { BidNotification } from "src/ui/bid-budget/reducer";
import { setBidNotifications } from "src/ui/bid-budget/actions";
import {
    campaignScriptModal,
    ModalView,
    setScriptModalView
} from "src/ui/scripts/actions";
import { colors } from "src/constants";
import { getSortByParameter } from "src/campaigns/utils/sortFilterUtils";
import { setCampaignFilters, setCampaignSort } from "src/ui/campaigns/actions";
import { initialCampaignFilters } from "src/ui/campaigns/reducer";

function* fetchCampaignsRequest({
    payload: { sortBy: sort, filters = initialCampaignFilters }
}: Action) {
    try {
        const { data } = yield call(get, "/v1/campaign", {
            publisher: `p:${localStorage.getItem("userid")}`,
            sortBy: getSortByParameter(sort),
            budgetLow: filters.budgetLow ? "true" : null,
            budgetNotLow: filters.budgetNotLow ? "true" : null,
            genre: filters.genres.length > 0 ? filters.genres.join(",") : null,
            regions:
                filters.regions.length > 0 ? filters.regions.join(",") : null,
            bidLow: filters.bidLow > 0 ? filters.bidLow : null,
            bidHigh: filters.bidHigh < 10 ? filters.bidHigh : null
        });
        //check if we have any unnotified price increases
        let notifications = JSON.parse(
            localStorage.getItem("bidNotifications") || "[]"
        );
        let newNotifications: BidNotification[] = [];
        let now = new Date();
        data.data.forEach((campaign: any) => {
            let platform = campaign.countryPlatformReward;
            if (platform) {
                let expires = new Date(platform.boost_expires_at);
                if (
                    platform.previous_reward &&
                    platform.reward !== platform.previous_reward &&
                    expires.getTime() > now.getTime()
                ) {
                    let uid = `${campaign.id}-${campaign.countryPlatformReward.previous_reward}/${campaign.countryPlatformReward.reward}`;
                    if (!notifications.includes(uid))
                        newNotifications.push({
                            uid,
                            price: platform.reward,
                            oldPrice: platform.previous_reward,
                            campaignId: campaign.id,
                            expiresAt: platform.boost_expires_at
                        });
                }
            }
        });
        yield put(setBidNotifications(newNotifications));

        yield put(
            fetchCampaignsSuccess(normalize(data.data, schema.campaignList))
        );

        if (!sort && filters === initialCampaignFilters) {
            yield put(setCampaignSort("Recommended"));
            yield put(setCampaignFilters(initialCampaignFilters));
        }
    } catch (error) {
        console.log(error);
        yield put(fetchCampaignsFailure("Failed to retrieve campaigns."));
    }
}

function* fetchCampaignRequest({
    payload: { campaignId, mediaId, scriptId }
}: Action) {
    try {
        const { role } = yield select(getAuthState);

        const { data } = yield call(
            getPublic,
            `/v1/campaign/${campaignId}`,
            {}
        );

        yield put(fetchCampaignSuccess(normalize(data.data, schema.campaign)));

        yield fork(fetchCampaignAccessRequest, campaignId);
        yield fork(fetchCampaignPublicPriceRequest, campaignId);
        yield fork(
            fetchCampaignMediaRulesRequest,
            fetchCampaignMediaRules(campaignId)
        );
        yield fork(
            fetchCampaignScriptsRequest,
            fetchCampaignScripts(campaignId, scriptId)
        );
        yield fork(
            fetchCampaignHighlightsRequest,
            fetchCampaignHighlights(campaignId)
        );
        yield fork(fetchCampaignAssetsRequest, fetchCampaignAssets(campaignId));
        yield fork(
            fetchCampaignCaptionsRequest,
            fetchCampaignCaptions(campaignId)
        );
        yield fork(
            fetchCampaignMediasRequest,
            fetchCampaignMedias(campaignId, mediaId)
        );

        if (check(CampaignAction.customLinks, role)) {
            yield fork(
                fetchCampaignCustomLinksRequest,
                fetchCampaignCustomLinks(campaignId)
            );
        }

        if (check(CampaignAction.payment, role)) {
            yield fork(fetchCampaignMetricsRequest, campaignId);
            yield fork(fetchCampaignInstallHistoryRequest, campaignId);
        }
        if (scriptId) {
            yield fork(fetchScriptRequest, fetchScript(scriptId));
        }
    } catch (error) {
        console.log(error);
        yield put(fetchCampaignFailure("Failed to retrieve campaign."));
    }
}

function* fetchCampaignBudgetRequest({ payload: { campaignId } }: Action) {
    try {
        const { data } = yield call(
            getPublic,
            `/v1/campaign/${campaignId}/budget`,
            {}
        );
        //uncomment to test Fam low budget
        // if (campaignId === 632) {
        //     yield put(
        //         fetchCampaignBudgetSuccess(campaignId, {
        //             isLow: true,
        //             budgetLimit: 5000,
        //             budgetSpend: 2000,
        //             startDate: "2021-10-25T00:00:00.000Z",
        //             endDate: "2021-11-01T00:00:00.000Z",
        //             budgetExtension: null
        //         })
        //     );
        // }

        //comment this out for testing
        if (data.data[0])
            yield put(
                fetchCampaignBudgetSuccess(campaignId, {
                    ...data.data[0],
                    budgetLimit: parseInt(data.data[0].budgetLimit),
                    budgetSpend: parseInt(data.data[0].budgetSpend)
                })
            );
    } catch (error) {}
}

function* fetchCampaignMediaRulesRequest({ payload: { campaignId } }: Action) {
    try {
        const { data } = yield call(
            getPublic,
            `/v1/campaign/${campaignId}/mediaRule`,
            {}
        );

        yield put(
            fetchCampaignMediaRulesSuccess(
                normalize(data.data, schema.mediaRuleList),
                {
                    campaignId
                }
            )
        );
    } catch (error) {}
}

function* fetchCampaignScriptsRequest({
    payload: { campaignId, scriptId }
}: Action) {
    try {
        const { data } = yield call(getPublic, `/v1/script`, { campaignId });

        const isAdmin: boolean = yield select(selectAdminStatus);

        const scriptData = isAdmin
            ? data.data
            : data.data.filter((script: Script) => script.shouldShowInApp);

        yield put(
            fetchCampaignScriptsSuccess(
                normalize(scriptData, schema.scriptList),
                {
                    campaignId
                }
            )
        );
        const idArr = data.data.map((script: any) => script.id);
        if (scriptId && idArr.includes(scriptId)) {
            yield put(campaignScriptModal(scriptId));
        }
    } catch (error) {}
}

function* fetchCampaignScriptRequest(action: Action) {
    try {
        const { scriptId } = action.payload;
        const { data } = yield call(get, `/v1/script/${scriptId}`, {});
        yield put(
            fetchCampaignScriptSuccess(normalize(data.data, schema.script))
        );
    } catch (error) {
        const errorMessage = errorToString(error);
        console.log(errorMessage);
        yield put(
            setPillNotificationText(
                `${errorMessage}. Please try again.`,
                "danger",
                3500
            )
        );
    }
}

function* fetchCampaignPriceRequest(campaignId: number) {
    try {
        const { userId } = yield select(getAuthState);
        const { data } = yield call(getPublic, `/v1/campaign/price`, {
            campaignId,
            publisher: userId && `p:${userId}`
        });

        const temp = newToOldPrice(data.data[0]);
        yield put(
            fetchCampaignPriceSuccess(normalize([temp], schema.campaignList), {
                campaignId
            })
        );
    } catch (error) {
        console.error(error);
    }
}

// Basically same as fetchCampaignPriceRequest, but this includes price range instead for unauthed users
function* fetchCampaignPublicPriceRequest(campaignId: number) {
    try {
        const { userId } = yield select(getAuthState);

        const { data } = yield call(get, "/public/v1/campaign/price", {
            campaignId,
            publisher: userId && `p:${userId}`
        });

        const temp = newToOldPrice(data.data[0]);
        yield put(
            fetchCampaignPriceSuccess(normalize([temp], schema.campaignList), {
                campaignId
            })
        );
    } catch (error) {
        console.log(error);
    }
}

function* fetchCampaignAssetsRequest({ payload: { campaignId } }: Action) {
    try {
        const { data } = yield call(getPublic, `/v1/asset`, { campaignId });

        yield put(
            fetchCampaignAssetsSuccess(normalize(data.data, schema.assetList), {
                campaignId
            })
        );
    } catch (error) {}
}

function* fetchCampaignAssetRequest(action: Action) {
    try {
        const { campaignId, assetId } = action.payload;
        const { data } = yield call(get, `/v1/asset/${assetId}`, {});
        yield put(
            fetchCampaignAssetSuccess(
                normalize(data.data, schema.asset),
                campaignId
            )
        );
    } catch (error) {
        const errorMessage = errorToString(error);
        console.log(errorMessage);
        yield put(
            setPillNotificationText(
                `${errorMessage}. Please try again.`,
                "danger",
                3500
            )
        );
    }
}

function* fetchScriptRequest({ payload: { scriptId } }: Action) {
    try {
        yield fork(fetchScriptAssetsRequest, fetchScriptAssets(scriptId));
        yield fork(fetchScriptMediasRequest, fetchScriptMedias(scriptId));
    } catch (error) {}
}

function* fetchScriptAssetsRequest({ payload: { scriptId } }: Action) {
    try {
        const { data } = yield call(getPublic, `/v1/asset`, { scriptId });

        yield put(
            fetchScriptAssetsSuccess(normalize(data.data, schema.assetList), {
                scriptId
            })
        );
    } catch (error) {}
}

function* fetchScriptMediasRequest({ payload: { scriptId } }: Action) {
    try {
        const { data } = yield call(getPublic, `/v1/media`, { scriptId });

        yield put(
            fetchScriptMediasSuccess(normalize(data.data, schema.mediaList), {
                scriptId
            })
        );
    } catch (error) {}
}

function* fetchCampaignCaptionsRequest({ payload: { campaignId } }: Action) {
    try {
        const { data } = yield call(getPublic, `/v1/caption`, { campaignId });

        yield put(
            fetchCampaignCaptionsSuccess(
                normalize(data.data, schema.captionList),
                {
                    campaignId
                }
            )
        );
    } catch (error) {}
}

function* fetchCampaignMediasRequest({
    payload: { campaignId, mediaId, placementIds }
}: Action) {
    try {
        const isAdmin: boolean = yield select(selectAdminStatus);
        const { data } = yield call(
            getPublic,
            `/v1/media/search?status=approved`,
            {
                campaignId,
                placementId: placementIds ? placementIds.join(",") : null,
                showAll: isAdmin ? true : false
            }
        );

        const normalizedMediaList = normalize(data.data, schema.mediaList);

        yield put(
            fetchCampaignMediasSuccess(normalize(data.data, schema.mediaList), {
                campaignId
            })
        );

        if (mediaId) {
            const mediaList = normalizedMediaList.result;
            const index = mediaList.indexOf(mediaId);
            if (index !== -1) {
                const newList = [
                    ...mediaList.slice(index, mediaList.length),
                    ...mediaList.slice(0, index)
                ];
                yield put(setCurrentModalMedias(newList));
            }
        }
    } catch (error) {}
}

function* fetchCampaignMediaRequest(action: Action) {
    try {
        const { campaignId, mediaId } = action.payload;
        const { data } = yield call(get, `/v1/media/search?status=approved`, {
            campaignId,
            query: mediaId
        });
        yield put(
            fetchCampaignMediaSuccess(normalize(data.data[0], schema.media))
        );
    } catch (error) {
        const errorMessage = errorToString(error);
        console.log(errorMessage);
        yield put(
            setPillNotificationText(
                `${errorMessage}. Please try again.`,
                "danger",
                3500
            )
        );
    }
}

function* fetchCampaignStatisticsRequest({ payload: { campaignId } }: Action) {
    try {
        const { data } = yield call(
            getPublic,
            `/v1/campaign/${campaignId}/statistics`,
            {}
        );

        const result = data.data[0];
        yield put(fetchCampaignStatisticsSuccess({ result }, { campaignId }));
    } catch (error) {
        yield put(
            fetchCampaignStatisticsFailure(
                "Failed to fetch campaign statistics."
            )
        );
    }
}

function* fetchCampaignPublicMediaRequest({ payload: { campaignId } }: Action) {
    try {
        const endpoint = "/public/v1/media";
        const { data } = yield call(get, endpoint, { campaignId });

        yield put(
            fetchCampaignPublicMediasSuccess(
                normalize(data.data, schema.mediaList),
                { campaignId }
            )
        );
    } catch (error) {
        console.log(error);
    }
}

function* fetchCampaignCustomLinksRequest({ payload: { campaignId } }: Action) {
    try {
        const { data } = yield call(getApi, `/get_custom_links_for_campaign`, {
            campaign_id: campaignId
        });

        yield put(
            fetchCampaignCustomLinksSuccess(
                normalize(data, schema.customLinkList),
                {
                    campaignId
                }
            )
        );
    } catch (error) {
        console.log(error);
    }
}

function* createCampaignCustomLinksRequest({ payload }: Action) {
    try {
        const { data } = yield call(post, "/api/create_new_custom_link", {
            media_id: payload.mediaId,
            name: payload.name
        });

        yield put(
            createCampaignCustomLinkSuccess(
                normalize(data, schema.customLink),
                { campaignId: payload.campaignId }
            )
        );
    } catch (error) {
        console.log(error);
    }
}

function* fetchCampaignMetricsRequest(campaignId: number) {
    try {
        const [activeRes, earningsRes]: ResponseData[] = yield all([
            call(get, `/v1/campaign/${campaignId}/metric`, {
                publisher: `p:${localStorage.getItem("userid")}`
            }),
            call(
                get,
                `/v1/publisher/p:${localStorage.getItem("userid")}/revenue`,
                { campaignId }
            )
        ]);

        yield put(
            fetchCampaignMetricsSuccess(
                normalize(
                    {
                        id: campaignId,
                        ...activeRes.data.data,
                        ...earningsRes.data.data
                    },
                    schema.campaign
                ),
                { campaignId }
            )
        );
    } catch (error) {}
}

function* fetchCampaignAccessRequest(campaignId: number) {
    try {
        const { userId } = yield select(getAuthState);

        if (userId) {
            const { data } = yield call(
                getPublic,
                `/v1/campaign/${campaignId}/access`,
                {}
            );

            yield put(fetchCampaignAccessSuccess(campaignId, data.data));
        } else {
            yield put(
                fetchCampaignAccessSuccess(campaignId, {
                    status: AccessState.guest
                })
            );
        }
    } catch (error) {}
}

function* requestCampaignAccess({ payload: { campaignId } }: Action) {
    try {
        const { data } = yield call(
            post,
            `/v1/campaign/${campaignId}/access`,
            {}
        );

        yield put(addNotification("You have requested access!"));
        yield put(fetchCampaignAccessSuccess(campaignId, data.data));
    } catch (error) {}
}

function* requestCampaignNotification({ payload: { campaignId } }: Action) {
    try {
        yield call(
            submitEventRequest,
            submitEvent({
                event_name: "publisher_campaign_access_notification_request",
                event_extra: {
                    publisherId: localStorage.getItem("userid"),
                    campaignId
                }
            })
        );

        const currentNotifs = JSON.parse(
            localStorage.getItem("campaignNotification") || "{}"
        );
        localStorage.setItem(
            "campaignNotification",
            JSON.stringify({ ...currentNotifs, [campaignId]: true })
        );

        yield put(addNotification("You have subscribed to notifications!"));
        yield put(
            fetchCampaignAccessSuccess(campaignId, {
                status: AccessState.denied
            })
        );
    } catch (error) {}
}

function* fetchCampaignInstallHistoryRequest(campaignId: number) {
    try {
        const { data } = yield call(
            get,
            `/v1/campaign/${campaignId}/installHistory`,
            { publisher: `p:${localStorage.getItem("userid")}` }
        );

        yield put(fetchCampaignInstallHistorySuccess(campaignId, data.data));
    } catch (error) {}
}

export function* fetchShoutoutsRequest() {
    try {
        const userId = localStorage.getItem("userid");
        const endpoint = `/api/get_all_live_shoutouts_for_publisher3?userid=${userId}`;

        const { data } = yield get(endpoint, {});

        yield put(fetchShoutoutsSuccess(data));
    } catch (error) {
        console.log(error);
    }
}

function* fetchContestRequest() {
    //TODO: remove anonymous: true when backend updates
    try {
        const { data } = yield call(getApi, "/contest", { anonymous: true });

        // Test Contest
        // const { data } = yield call(
        //     getApi,
        //     "/contest?start_time=1714247572&end_time=1715639520",
        //     {}
        // );

        yield put(
            fetchContestSuccess(
                normalize(data.data.contest, schema.contestList)
            )
        );
    } catch (error) {
        yield put(fetchContestFailure("Fetch contest fail"));
    }
}

function* emailPublisherMediaRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/api/email_publisher_media",
            payload
        );

        if (status === 200) {
            yield put(mediaEmailModal());
        } else {
            window.alert("Something went wrong. Please Refresh.");
            yield put(
                emailPublisherMediaFailure("Email publisher media failure")
            );
        }
    } catch (error) {
        window.alert("Something went wrong. Please Refresh.");
        yield put(emailPublisherMediaFailure("Email publisher media failure"));
    }
}

export default function* campaignsSaga() {
    yield all([
        fork(highlightsSaga),
        fork(placementsSaga),
        fork(contestsSaga),
        takeEvery(FETCH_CAMPAIGNS.REQUEST, fetchCampaignsRequest),
        takeEvery(FETCH_CAMPAIGN.REQUEST, fetchCampaignRequest),
        takeLeading(FETCH_SCRIPT.REQUEST, fetchScriptRequest),
        takeEvery(FETCH_SCRIPT_MEDIAS.REQUEST, fetchScriptMediasRequest),
        takeEvery(FETCH_SCRIPT_ASSETS.REQUEST, fetchScriptAssetsRequest),
        takeEvery(FETCH_CAMPAIGN_SCRIPTS.REQUEST, fetchCampaignScriptsRequest),
        takeEvery(FETCH_CAMPAIGN_SCRIPT.REQUEST, fetchCampaignScriptRequest),
        takeEvery(FETCH_CAMPAIGN_BUDGET.REQUEST, fetchCampaignBudgetRequest),
        takeEvery(
            FETCH_CAMPAIGN_CAPTIONS.REQUEST,
            fetchCampaignCaptionsRequest
        ),
        takeEvery(FETCH_CAMPAIGN_ASSETS.REQUEST, fetchCampaignAssetsRequest),
        takeEvery(FETCH_CAMPAIGN_ASSET.REQUEST, fetchCampaignAssetRequest),
        takeEvery(FETCH_CAMPAIGN_MEDIAS.REQUEST, fetchCampaignMediasRequest),
        takeEvery(FETCH_CAMPAIGN_MEDIA.REQUEST, fetchCampaignMediaRequest),
        takeEvery(
            FETCH_CAMPAIGN_STATISTICS.REQUEST,
            fetchCampaignStatisticsRequest
        ),
        takeEvery(
            FETCH_CAMPAIGN_PUBLIC_MEDIAS.REQUEST,
            fetchCampaignPublicMediaRequest
        ),
        takeEvery(
            FETCH_CAMPAIGN_MEDIA_RULES.REQUEST,
            fetchCampaignMediaRulesRequest
        ),
        takeEvery(REQUEST_CAMPAIGN_ACCESS, requestCampaignAccess),
        takeEvery(REQUEST_CAMPAIGN_NOTIFICATION, requestCampaignNotification),
        takeEvery(
            CREATE_CAMPAIGN_CUSTOM_LINK.REQUEST,
            createCampaignCustomLinksRequest
        ),
        takeEvery(FETCH_SHOUTOUTS.REQUEST, fetchShoutoutsRequest),
        takeEvery(FETCH_CONTEST.REQUEST, fetchContestRequest),
        takeEvery(EMAIL_PUBLISHER_MEDIA.REQUEST, emailPublisherMediaRequest)
    ]);
}
