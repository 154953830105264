import React, { useEffect, useState } from "react";
import moment from "moment";
import { Access, AccessState, Campaign } from "../types";
import VerticallyCentered from "../../components/VerticallyCentered";
import CircleIcon from "../../components/CircleIcon";
import { AccessText } from "./Details.styled";
import PromoteButton from "./components/PromoteButton";
import { colors, maxWidthDesktop } from "../../constants";
import RequestButton from "./components/RequestButton";
import NotifyButton from "./components/NotifyButton";
import { useDispatch } from "react-redux";
import { startPromotingModal } from "../../ui/auth/actions";
import PromoteFlowModal from "../promote/PromoteFlowModal";
import styled from "styled-components";
import {
    CampaignDetailHeaderAccessIcon,
    CampaignDetailHeaderAccessText
} from "src/components/SkeletonLoader";
import { BigBlueButton } from "src/profile/components/Buttons";
import { flex } from "src/utils/styles/snippets";

const Main = styled("div")`
    ${flex("column")};
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    margin: 0px auto;
    padding: 12px 18px 10px;

    & > * ~ * {
        margin-top: 12px;
    }
`;

const HeaderButton = styled(BigBlueButton)<{ disabled?: boolean }>`
    ${props => props.disabled && `opacity: 0.5`}
    user-select: none;
`;

interface Props {
    access?: Access;
    campaignName: string;
    campaignState: string;
    campaign: Campaign;
    scrollToBottom: () => void;
}

const withPadding = (duration: moment.Duration) => {
    return [
        ("0" + duration.hours()).slice(-2),
        ("0" + duration.minutes()).slice(-2),
        ("0" + duration.seconds()).slice(-2)
    ].join(":");
};

const CampaignAccess = ({
    access,
    campaignState,
    campaignName,
    campaign
}: Props) => {
    const dispatch = useDispatch();
    const [time, setTime] = useState(
        withPadding(moment.duration(moment(access?.expiresAt).diff(moment())))
    );

    const renderAccess = () => {
        if (!access)
            return (
                <Main>
                    <VerticallyCentered>
                        <CampaignDetailHeaderAccessIcon />
                        <AccessText>
                            <CampaignDetailHeaderAccessText />
                        </AccessText>
                    </VerticallyCentered>

                    <HeaderButton disabled>...</HeaderButton>
                </Main>
            );
        switch (access.status) {
            case AccessState.granted:
                if (campaignState === "active") {
                    return (
                        <>
                            <VerticallyCentered>
                                <CircleIcon type="check" />
                                <AccessText>
                                    You have access to this campaign and can
                                    promote it anytime.
                                </AccessText>
                            </VerticallyCentered>
                            <PromoteFlowModal campaign={campaign} />
                        </>
                    );
                }
                return (
                    <>
                        <VerticallyCentered>
                            <CircleIcon type="check" />
                            <AccessText>
                                {`You have access to this campaign and can promote it anytime! ${campaignName} is limited though, so other creators may not have access.`}
                            </AccessText>
                        </VerticallyCentered>
                        <PromoteFlowModal campaign={campaign} />
                    </>
                );

            case AccessState.none:
                return (
                    <>
                        <VerticallyCentered>
                            <CircleIcon type="x" color={colors.primaryRed} />
                            <AccessText>
                                You do not have access to this campaign and
                                cannot promote it.
                            </AccessText>
                        </VerticallyCentered>
                        <RequestButton />
                    </>
                );
            case AccessState.requested:
                return (
                    <>
                        <VerticallyCentered>
                            <CircleIcon type="x" color={colors.primaryRed} />
                            <AccessText>
                                You do not have access to this campaign and
                                cannot promote it.
                            </AccessText>
                        </VerticallyCentered>
                        <RequestButton requested />
                    </>
                );
            case AccessState.denied:
                return (
                    <>
                        <VerticallyCentered>
                            <CircleIcon type="x" color={colors.primaryRed} />
                            <AccessText>
                                The advertiser has removed your access to this
                                campaign.
                            </AccessText>
                        </VerticallyCentered>
                        <NotifyButton />
                    </>
                );
            case AccessState.pendingRemoval:
                return (
                    <>
                        <VerticallyCentered>
                            <CircleIcon type="x" color={colors.primaryRed} />
                            <AccessText color={colors.primaryRed}>
                                This campaign will close in{" "}
                                <strong>{time}</strong> and coins will stop
                                counting. Please remove any remaining posts!
                            </AccessText>
                        </VerticallyCentered>
                    </>
                );
            case AccessState.guest:
                return (
                    <>
                        <VerticallyCentered>
                            <CircleIcon type="check" />
                            <AccessText>
                                Sign up with the plug and start monetizing!
                            </AccessText>
                        </VerticallyCentered>
                        <PromoteButton
                            onClick={() => dispatch(startPromotingModal())}
                            text="Promote This Now"
                        />
                    </>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (access)
                setTime(
                    withPadding(
                        moment.duration(moment(access.expiresAt).diff(moment()))
                    )
                );
        }, 1000);

        return () => clearInterval(timer);
    }, [access]);

    return <>{renderAccess()}</>;
};

export default CampaignAccess;
