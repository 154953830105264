import { Shoutout } from "src/campaigns/shoutouts/types";
import { Action } from "src/types";

// Set Current Shoutout -----------------------------------------------

export const SET_CURRENT_SHOUTOUT = "SET_CURRENT_SHOUTOUT";

export const setCurrentShoutout = (currentShoutout: Shoutout): Action => ({
    type: SET_CURRENT_SHOUTOUT,
    payload: { currentShoutout }
});
