import React, { useRef } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ScrollArrows from "src/components/ScrollArrows";
import { colors } from "src/constants";
import useQuery from "src/hooks/useQuery";
import styled from "styled-components";
import { submitEvent } from "../../events/actions";
import {
    highlightPlacementsModal,
    setCurrentModalHighlights
} from "../../ui/highlights/actions";
import {
    HorizontalScrollInner,
    HorizontalScrollOuter
} from "../details/Details.styled";
import PromoteHighlightCell from "../details/tabs/PromoteHighlightCell";
import PlacementSelector from "../placements/components/PlacementSelector";
import { Highlight } from "../types";

const Main = styled.div`
    background-color: ${colors.lightTeal};
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0px 0px;
    border-top: 1px solid ${colors.mediumTeal};
`;

const ScrollArrowsContainer = styled.div`
    display: ${props => (props.theme.isMobile ? "none" : "block")};
`;

interface Props {
    highlights: Highlight[];
}

const HighlightMediaList = ({ highlights }: Props) => {
    const dispatch = useDispatch();

    const query = useQuery();

    useEffect(() => {
        const highlightId = parseInt(query.get("id") || "") || undefined;
        const index = highlights.findIndex(
            highlight => highlight.highlightsPageId === highlightId
        );
        if (index >= 0) highlightClicked(index);
    }, []);

    function highlightClicked(index: number) {
        const newList = [
            ...highlights.slice(index, highlights.length),
            ...highlights.slice(0, index)
        ];
        dispatch(
            submitEvent({
                event_name: "highlight_tapped",
                event_extra: {
                    highlightId: highlights[index].highlightsPageId,
                    rank: index
                },
                value: index
            })
        );
        dispatch(
            setCurrentModalHighlights(newList.map(el => el.highlightsPageId))
        );
    }

    const carouselRef = useRef(null);

    return (
        <Main>
            <Header>
                <PlacementSelector
                    openPlacementsModal={() =>
                        dispatch(highlightPlacementsModal())
                    }
                />

                <ScrollArrowsContainer>
                    <ScrollArrows refElement={carouselRef} />
                </ScrollArrowsContainer>
            </Header>

            <HorizontalScrollOuter
                ref={carouselRef}
                topBorder="0px"
                bottomBorder="none"
            >
                <HorizontalScrollInner>
                    {highlights.map((highlight, idx) => (
                        <PromoteHighlightCell
                            key={highlight.highlightsPageId}
                            highlight={highlight}
                            onMediaClick={() => highlightClicked(idx)}
                            rank={idx}
                        />
                    ))}
                </HorizontalScrollInner>
            </HorizontalScrollOuter>
        </Main>
    );
};

export default HighlightMediaList;
