import React from "react";

import DetailTab from "./tabs/DetailTab";
import PaymentTab from "./tabs/PaymentTab";
import PromoteTab from "./tabs/PromoteTab";
import { DetailsTabState } from "./components/AuthedDetailsPage";

interface Props {
    currentTab: DetailsTabState;
}

const DetailTabs = ({ currentTab }: Props) => {
    switch (currentTab) {
        case DetailsTabState.details:
            return <DetailTab />;
        case DetailsTabState.payment:
            return <PaymentTab />;
        default:
            return <PromoteTab authed />;
    }
};

export default DetailTabs;
