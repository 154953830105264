import React, { useState } from "react";
import { useDispatch } from "react-redux";
import UserSelectionWithSearchModal, {
    SelectedUsers
} from "src/modals/UserSelectionWithSearchModal";
import { updateGroupActionsAddMember } from "../actions";

interface Props {
    closeModal: () => void;
    conversationId: string;
    participantIds: string[];
}

const GroupAddMemberModal = ({
    closeModal,
    conversationId,
    participantIds
}: Props) => {
    const dispatch = useDispatch();
    const [selectedUsers, setSelectedUsers] = useState<SelectedUsers>({});

    function addMember() {
        dispatch(
            updateGroupActionsAddMember(
                conversationId,
                Object.values(selectedUsers)
            )
        );
        closeModal();
    }

    return (
        <UserSelectionWithSearchModal
            title="Add to Group"
            actionText="Add"
            actionCallback={addMember}
            closeModal={closeModal}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            filterOutUserIds={participantIds}
        />
    );
};
export default GroupAddMemberModal;
