import React from "react";
import {
    DirectBuyStepCollection,
    DirectBuyStepAction
} from "../../../modules/direct-buy-step/types";
import { DirectBuyStepsEntitiesState } from "../../../modules/direct-buy-step/reducer";
import DirectBuyDetailsCustomMediaUploadCard from "./DirectBuyDetailsCustomMediaUploadCard";
import styled from "styled-components";
import { ResourceContainer, ResourceDescription } from "../styledComponents";
import DirectBuyDetailsStepCollectionStatus from "../components/DirectBuyDetailsStepCollectionStatus";
import { StepById } from "src/buys/modules/open-buys/reducer";
import { SubmissionButton } from "../DirectBuyDetailsStepCollection";
import { useDispatch, useSelector } from "react-redux";
import { setOpenBuySubmissionModal } from "src/buys/actions";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const Container = styled.div`
    height: 172px;
`;

interface Props {
    collection: DirectBuyStepCollection;
    steps: DirectBuyStepsEntitiesState | StepById;
    open: boolean;
}

const DirectBuyDetailsCustomMedia = ({ collection, steps, open }: Props) => {
    const parentStep = steps[collection.parent];
    const dispatch = useDispatch();

    const showButton = !useSelector(
        selectFeatureFlags(featureFlags.ChallengesGroupedSubmissions)
    );

    if (!parentStep.requiresCustomMedia) {
        return null;
    }

    if (open)
        return showButton ? (
            <SubmissionButton
                onClick={() =>
                    dispatch(setOpenBuySubmissionModal(collection.parent))
                }
            >
                Submit your media
            </SubmissionButton>
        ) : null;

    return (
        <ResourceContainer>
            <ResourceDescription>CUSTOM MEDIA</ResourceDescription>
            <Container>
                <DirectBuyDetailsCustomMediaUploadCard
                    step={parentStep}
                    upload={
                        !parentStep.customMedia
                            ? { label: "Upload" }
                            : parentStep.customMedia.mediaStatus === "rejected"
                            ? { label: "Edit" }
                            : undefined
                    }
                />
            </Container>
            <DirectBuyDetailsStepCollectionStatus
                collection={collection}
                steps={steps}
                action={DirectBuyStepAction.customMedia}
            />
        </ResourceContainer>
    );
};

export default DirectBuyDetailsCustomMedia;
