export const getAuthRedirectTitle = status => {
    switch (status) {
        case "success":
            return "Account Successfully Verified!";
        case "failure":
            return "Account Verification Failed";
        case "loading":
            return `Please wait a moment while we verify things!`;
        default:
            return "Account Verification Failed";
    }
};

export const getAuthRedirectDescription = (status, platform, username) => {
    switch (status) {
        case "failure":
            return `We were unable to verify the ownership of your ${platform} account @${username} on the Plug.  Please return to the app and try verify your account using the code verifcation method, or reaching out to support.`;
        case "success":
            return `We have verified the ownership of your ${platform} account @${username} on the Plug!  Please return to the Plug app and start earning!`;
        case "loading":
            return "";
        default:
            return status;
    }
};
