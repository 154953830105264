import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { fetchCampaignAssets } from "../../assets/actions";
import { getAssets } from "../../selectors";
import AssetsAccordion from "./AssetsAccordion";
import withAccordion from "./withAccordion";

const mapStateToProps = (state: GlobalState) => ({
    name: "campaign",
    currentId: state.ui.campaign.currentCampaignId,
    assetsLoading: state.ui.campaign.assetsLoading,
    assets: getAssets(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchAssets: (campaignId: number) =>
        dispatch(fetchCampaignAssets(campaignId))
});

export default withAccordion(
    connect(mapStateToProps, mapDispatchToProps)(AssetsAccordion)
);
