import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import styled from "styled-components";
import MediaRules from "src/modals/components/MediaRules";
import { toggleRulesModal } from "src/buys/actions";
import { colors } from "src/constants";
import withAccordion from "src/campaigns/details/accordions/withAccordion";
import { get as getFire } from "src/new-chat/saga";
import { get } from "src/Api";
import { Rule } from "src/types/challenge/Rule";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 16px 30px 16px;
    text-align: left;
`;

const Button = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid ${colors.blueGrey2};
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    padding: 12px 0;
    margin-top: 9px;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.primaryBlue};
    cursor: pointer;
    :hover {
        background-color: ${colors.blueGrey2};
    }
`;

interface Props {
    openBuy: OpenBuy;
}

const OpenBuyMediaRulesSection = ({ openBuy }: Props) => {
    const dispatch = useDispatch();
    const [dos, setDos] = useState<Rule[]>([]);
    const [donts, setDonts] = useState<Rule[]>([]);
    const [generals, setGenerals] = useState<Rule[]>([]);

    const fetchRules = async () => {
        try {
            let res = await get(`v1/ruleSet/${openBuy.ruleSetId}`, {});
            let dos: Rule[] = [];
            let donts: Rule[] = [];
            let generals: Rule[] = [];
            res.data.data?.Rules.forEach((rule: Rule) => {
                if (rule.icon === "x") donts.push(rule);
                else if (rule.icon === "check") dos.push(rule);
                else generals.push(rule);
            });
            setDonts(donts);
            setDos(dos);
            setGenerals(generals);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchRules();
    }, [openBuy]);

    const [standardsId, setStandardsId] = useState(3);
    const getStandards = async () => {
        try {
            let res = await getFire<{ id: string; ruleSetId: number }>(
                "ChallengesStandardMediaRulesSetId",
                "GzADIFgd3zDfZAgtQF1Y"
            );
            if (res?.ruleSetId) setStandardsId(res?.ruleSetId);
        } catch (err) {
            console.error(`error getting standards: ${err}`);
        }
    };
    useEffect(() => {
        getStandards();
    }, []);
    return (
        <Main>
            <MediaRules
                donts={donts}
                dos={dos}
                generals={generals}
                small={true}
            />
            <Button onClick={() => dispatch(toggleRulesModal(standardsId))}>
                👍 View Plug Media Standards
            </Button>
        </Main>
    );
};

export default withAccordion(OpenBuyMediaRulesSection);
