import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PlugTable, { PlugTableRef } from "src/components/table/PlugTable";
import { TableCell, TableRow } from "src/components/table/types";
import { createCell } from "src/components/table/utils";
import MediaCell from "./MediaCell";
import { useParams } from "react-router";
import MainContainer from "src/components/MainContainer";
import { useDispatch, useSelector } from "react-redux";
import { get } from "src/Api";
import Platforms from "src/social-accounts/Platforms";
import { selectOpenBuysById } from "src/buys/selectors";
import { requestOpenBuyById } from "src/buys/actions";
import TableLoader from "src/components/table/components/TableLoader";
import { columns, filterOptions } from "../tableConfig";
import { SubmissionReport } from "../types";
import SubmissionMediaCarousel from "./SubmissionMediaCarousel";
import ChallengeMainHeader from "src/marketplace/landing/components/ChallengeMainHeader";
import { colors, shadows } from "src/constants";
import { LoaderItem } from "src/components/SkeletonLoader";
import { coinToDollars, formatNumber } from "src/util";

import { GlobalState } from "src/reducers";

import XCard from "src/profile/components/XCard";
import { useRef } from "react";
import ChallengeGeneralButtonFooter from "src/marketplace/challenge-creation-form/components/ChallengeGeneralButtonFooter";

const Wrapper = styled.div``;
const StyledMainContainer = styled(MainContainer)`
    padding-bottom: 80px;
    text-align: left;
`;

const MetricsHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-weight: 500;
    gap: 9px;
    padding-bottom: 24px;
`;

const Metric = styled.div`
    background-color: white;
    padding: 12px;
    width: 100%;
    font-size: 14px;
    border-radius: 10px;
    height: 110px;
    box-shadow: ${shadows.four};
    gap: 12px;
`;

const MetricValue = styled.div`
    background-color: ${colors.quaternaryLightGray};
    padding: 12px 0px;
    font-size: 24px;
    border-radius: 8px;
    text-align: center;
`;

export interface SubmissionRow extends TableRow {
    raw: SubmissionReport;
    rowData: {
        username: TableCell;
        platform: TableCell;
        views: TableCell;
        cost: TableCell;
        ecpm: TableCell;
        likes: TableCell;
        comments: TableCell;
        mediaId: TableCell;
        publisherId: TableCell;
        mediaUrl: TableCell;
    };
}

interface Metrics {
    installs: number;
    budgetAmount: number;
    totalCost: number;
    views: number;
    likes: number;
    comments: number;
}

const ChallengeReport = () => {
    const dispatch = useDispatch();
    const plugTableRef = useRef<PlugTableRef>(null);

    const [metrics, setMetrics] = useState<Metrics | undefined | null>(
        undefined
    );

    const { openBuyId } = useParams<{ openBuyId: string }>();

    const openBuy = useSelector(
        (state: GlobalState) => state.entities.adminOpenBuys.byId
    )[parseInt(openBuyId)];
    const isNotReportable = openBuy && !openBuy.startsAt;

    const [tableLoading, setTableLoading] = useState(true);

    const [fetchedData, setFetchedData] = useState<SubmissionReport[]>([]);
    useEffect(() => {
        get(`/v1/openBuy/${openBuyId}/report/`, {}).then(res => {
            setFetchedData(res.data.data);
            setTableLoading(false);
        });
    }, []);

    const fetchMetrics = async () => {
        try {
            let res = await get(`/v1/openBuy/${openBuy.id}/report/metrics`, {});
            setMetrics(res.data.data || null);
        } catch (err) {
            setMetrics(null);
        }
    };

    const displayMetric = (key: string) => {
        if (!metrics) return null;
        let name = "";
        let value = "";
        switch (key) {
            case "installs":
                name = "Installs";
                value = formatNumber(metrics[key] || 0);
                break;
            case "budgetAmount":
                name = "Budget Amount";
                value = "$" + formatNumber(metrics[key] || 0);
                break;
            case "totalCost":
                name = "Total Cost";
                value = coinToDollars(metrics[key] || 0, false, true);
                break;
            case "views":
                name = "View";
                value = formatNumber(metrics[key] || 0);
                break;
            case "likes":
                name = "Likes";
                value = formatNumber(metrics[key] || 0);
                break;
            case "comments":
                name = "Comments";
                value = formatNumber(metrics[key] || 0);
                break;
            default:
                break;
        }

        return (
            <React.Fragment key={key}>
                <Metric>
                    {name}
                    <MetricValue>
                        {//@ts-ignore
                        value || "--"}
                    </MetricValue>
                </Metric>
            </React.Fragment>
        );
    };
    useEffect(() => {
        if (openBuy) fetchMetrics();
    }, [openBuy]);

    const createData = (arr: SubmissionReport[]) => {
        return arr.map(
            (submission, idx): SubmissionRow => {
                return {
                    originalIdx: idx,
                    raw: submission,
                    rowData: {
                        username: createCell.link(
                            submission.socialAccount &&
                                `@${submission.socialAccount.username}`,
                            submission.socialAccount?.username &&
                                submission.socialAccount?.platform &&
                                Platforms[
                                    submission.socialAccount.platform
                                ]?.urlCallback(
                                    submission.socialAccount.username.toLowerCase()
                                )
                        ),
                        publisherId: createCell.number(submission.publisherId, {
                            isNull: !Boolean(submission.publisherId)
                        }),
                        platform: createCell.string(
                            submission.socialAccount?.platform
                        ),

                        views: createCell.number(submission.viewsActual, {
                            separator: true
                        }),
                        cost: createCell.number(submission.cost, {
                            separator: true,
                            money: true
                        }),
                        ecpm: createCell.number(submission.ecpm, {
                            money: true,
                            separator: true
                        }),
                        likes: createCell.number(submission.likes, {
                            separator: true
                        }),
                        comments: createCell.number(submission.totalComments, {
                            separator: true
                        }),
                        mediaId: createCell.custom(
                            <MediaCell
                                mediaUrl={submission?.media?.coverPhotoUrl}
                                handleClick={() => handleMediaClick(idx)}
                            />,
                            submission?.media?.id,
                            { isNull: !Boolean(submission?.media) }
                        ),
                        mediaUrl: createCell.string(submission?.media?.url)
                    }
                };
            }
        );
    };

    const cellData = createData(fetchedData);

    const [carouselOpen, setCarouselOpen] = useState(false);
    const [submissionList, setSubmissionList] = useState<SubmissionReport[]>(
        []
    );

    const handleMediaClick = (idx: number) => {
        const submissionArr = [
            ...fetchedData.slice(idx, fetchedData.length),
            ...fetchedData.slice(0, idx)
        ];

        setSubmissionList(submissionArr);
        setCarouselOpen(true);
    };

    return (
        <Wrapper>
            <StyledMainContainer>
                {tableLoading ? (
                    <>
                        <MetricsHeader>
                            {Array.from({ length: 6 }, (n, index) => (
                                <LoaderItem key={index} />
                            ))}
                        </MetricsHeader>
                        <TableLoader />
                    </>
                ) : isNotReportable ? (
                    <XCard text="Challenge must be launched before reports are available!" />
                ) : (
                    <>
                        {metrics === undefined ? (
                            <MetricsHeader>
                                {Array.from({ length: 6 }, (n, index) => (
                                    <LoaderItem key={index} />
                                ))}
                            </MetricsHeader>
                        ) : (
                            <MetricsHeader>
                                {Object.keys(metrics || {}).map(key =>
                                    displayMetric(key)
                                )}
                            </MetricsHeader>
                        )}
                        <PlugTable
                            ref={plugTableRef}
                            noResults={{
                                message:
                                    "This challenge has no submissions to report on."
                            }}
                            title={"Submission Breakdown"}
                            cellData={cellData}
                            filterOptions={filterOptions}
                            columns={columns}
                            rowClick={row => handleMediaClick(row.originalIdx)}
                            filenamePrefix={`${
                                openBuy?.name ? openBuy.name : "challenge"
                            }-report`}
                            includedRawColumnsInCsv={[
                                "linkSubmission",
                                "textSubmission"
                            ]}
                        />
                        <ChallengeGeneralButtonFooter
                            buttons={[
                                {
                                    text: "Download as CSV",
                                    action: () => {
                                        plugTableRef.current?.exportCsv();
                                    }
                                }
                            ]}
                        />
                    </>
                )}

                {carouselOpen && (
                    <SubmissionMediaCarousel
                        openBuy={openBuy}
                        submissionList={submissionList}
                        closeModal={() => {
                            setSubmissionList([]);
                            setCarouselOpen(false);
                        }}
                    />
                )}
            </StyledMainContainer>
        </Wrapper>
    );
};

export default ChallengeReport;
