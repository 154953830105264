import React from "react";
import ProgressBar from "src/components/ProgressBar";
import { colors, fonts, fontWeight } from "src/constants";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 18px 18px 18px;
`;

const Title = styled.h1`
    color: ${colors.gray2};
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.heavy};
    line-height: 14px;
    text-align: left;
    margin-bottom: 10px;
`;

const Info = styled.span`
    font-size: 9px;
    line-height: 11px;
    font-weight: 500;
    display: flex;
    color: ${colors.gray2};
`;

const Numbers = styled(Info)`
    color: ${colors.primaryBlue};
    margin-left: 3px;
`;

interface Props {
    budgetAmount: number;
    budgetSpend: number;
}

const OpenBuyBudget = ({ budgetAmount, budgetSpend }: Props) => {
    return (
        <Main>
            <Title>REMAINING BUDGET</Title>
            {/* <Payout>
                    <Dollar src={"/campaigns/dollar-sign.svg"} />${budgetAmount}
                </Payout> */}
            <ProgressBar
                color={colors.secondaryGreen}
                progress={((budgetAmount - budgetSpend) / budgetAmount) * 100}
                reverse={true}
            />
        </Main>
    );
};

export default OpenBuyBudget;
