import {
    DirectBuy,
    DirectBuyStage,
    DirectBuyState
} from "../modules/direct-buy/types";
import { IndicatorBackgroundColor } from "./IndicatorBackground";
import { IndicatorTagColor, IndicatorTagContent } from "./IndicatorTag";
import { DirectBuyPreviewSize } from "./DirectBuyPreview";
import {
    DirectBuyPayment,
    DirectBuyPaymentCollection
} from "../modules/direct-buy-payment/types";
import {
    PaymentConjunction,
    paymentConjunctionToShortString
} from "../../types/PaymentConjunction";
import {
    longStringForDirectBuyPayment,
    shortStringForDirectBuyPayment
} from "../modules/direct-buy-payment/util";
import {
    OpenBuy,
    OpenBuyState,
    OpenBuySubmission,
    OpenBuySubmissionStatuses
} from "../modules/open-buys/types";
import { InstructionStep } from "src/marketplace/instructions-sets/types";

export function getIndicatorBackgroundColorForOpenBuyState(
    state: OpenBuyState
): IndicatorBackgroundColor {
    switch (state) {
        case OpenBuyState.new:
            return IndicatorBackgroundColor.blue;
        case OpenBuyState.viewed:
            return IndicatorBackgroundColor.inactive;
        case OpenBuyState.pending:
            return IndicatorBackgroundColor.yellow;
        case OpenBuyState.revision_requested:
            return IndicatorBackgroundColor.red;
        case OpenBuyState.rejected:
            return IndicatorBackgroundColor.red;
        case OpenBuyState.approved:
        case OpenBuyState.approved_next_step:
            return IndicatorBackgroundColor.green;
        case OpenBuyState.ended:
            return IndicatorBackgroundColor.black;
    }
}

export function getIndicatorTagContentForOpenBuy(
    state: OpenBuyState,
    isApplication: boolean,
    submissions: OpenBuySubmission[]
): IndicatorTagContent | null {
    switch (state) {
        case OpenBuyState.new:
            return {
                string: isApplication ? "Apply Today" : "NEW",
                isImageUrl: false,
                color: IndicatorTagColor.blue
            };
        case OpenBuyState.viewed:
            return null;
        case OpenBuyState.pending:
            return {
                string: `PENDING`,
                isImageUrl: false,
                color: IndicatorTagColor.yellow
            };

        case OpenBuyState.rejected:
            return {
                string: `REJECTED`,
                isImageUrl: false,
                color: IndicatorTagColor.red
            };
        case OpenBuyState.approved_next_step:
            return {
                string: `APPROVED FOR NEXT STEP!`,
                isImageUrl: false,
                color: IndicatorTagColor.green
            };
        case OpenBuyState.approved:
            return {
                string: `APPROVED`,
                isImageUrl: false,
                color: IndicatorTagColor.green
            };
        case OpenBuyState.revision_requested:
            return {
                string: "REVISION REQUESTED",
                isImageUrl: false,
                color: IndicatorTagColor.red
            };
        case OpenBuyState.ended:
            return {
                string: "ENDED",
                isImageUrl: false,
                color: IndicatorTagColor.ended
            };
        default:
            return null;
    }
}

export function getStringForDirectBuyPaymentCollectionArray(
    payments: DirectBuyPaymentCollection[]
): string {
    const paymentObjects: {
        paymentConjunction: PaymentConjunction;
        directBuyPayments: DirectBuyPayment[];
    }[] = [];
    payments.forEach(payment => {
        if (payment.payment) {
            paymentObjects.push(payment.payment);
        }
    });
    if (paymentObjects.length === 1) {
        const singlePaymentObject = paymentObjects[0];
        if (singlePaymentObject.directBuyPayments.length === 1) {
            const singlePayment = singlePaymentObject.directBuyPayments[0];
            return longStringForDirectBuyPayment(singlePayment);
        } else {
            return singlePaymentObject.directBuyPayments
                .map(payment => shortStringForDirectBuyPayment(payment))
                .join(
                    paymentConjunctionToShortString(
                        singlePaymentObject.paymentConjunction
                    )
                );
        }
    } else {
        return `${paymentObjects.length} Payments`;
    }
}

export function outerPaddingForDirectBuyPreviewSize(
    size: DirectBuyPreviewSize
) {
    switch (size) {
        case DirectBuyPreviewSize.large:
            return 6;
        case DirectBuyPreviewSize.small:
            return 8;
    }
}

export function outerBorderRadiusForDirectBuyPreviewSize(
    size: DirectBuyPreviewSize
) {
    switch (size) {
        case DirectBuyPreviewSize.large:
            return 20;
        case DirectBuyPreviewSize.small:
            return 14;
    }
}

export function innerPaddingForDirectBuyPreviewSize(
    size: DirectBuyPreviewSize
) {
    switch (size) {
        case DirectBuyPreviewSize.large:
            return 6;
        case DirectBuyPreviewSize.small:
            return 4;
    }
}

export function innerBorderRadiusForDirectBuyPreviewSize(
    size: DirectBuyPreviewSize
) {
    switch (size) {
        case DirectBuyPreviewSize.large:
            return 14;
        case DirectBuyPreviewSize.small:
            return 10;
    }
}
