import { CampaignMedia } from "src/campaigns/types";
import { Action } from "src/types";

const setPromoting = (value: boolean): Action => ({
    type: PROMOTE_FLOW.SET_PROMOTING,
    payload: value
});

const setSelectedMedia = (value?: CampaignMedia): Action => ({
    type: PROMOTE_FLOW.SET_SELECTED,
    payload: value
});

export const PROMOTE_FLOW = {
    SET_PROMOTING: "PROMOTE_FLOW_SET_PROMOTING",
    SET_SELECTED: "PROMOTE_FLOW_SET_SELECTED",
    setPromoting,
    setSelectedMedia
};
