import { Action } from "src/types";
import { BID_NOTIFICATIONS, OPEN_BID_NOTIFICATION_MODAL } from "./actions";

export interface BidNotification {
    uid: string;
    campaignId: number;
    price: number;
    oldPrice: number;
    expiresAt: string;
}

export interface BidBudgetUIState {
    notifications?: BidNotification[];
    openBidNotificationModal: boolean;
}

const initialBidBudgetUIState: BidBudgetUIState = {
    notifications: [],
    openBidNotificationModal: false
};

const bidBudgetUIReducer = (
    state = initialBidBudgetUIState,
    action: Action
): BidBudgetUIState => {
    let temp: BidNotification[] = state.notifications || [];
    switch (action.type) {
        case BID_NOTIFICATIONS.SET:
            temp = temp.concat(action.payload);
            return { ...state, notifications: temp };

        case BID_NOTIFICATIONS.CLEAR:
            let newNotifications = JSON.parse(
                localStorage.getItem("bidNotifications") || "[]"
            );
            if (action.payload) {
                newNotifications.push(action.payload);
                localStorage.setItem(
                    "bidNotifications",
                    JSON.stringify(newNotifications)
                );
                return {
                    ...state,
                    notifications: temp.filter(
                        (notif: BidNotification) => notif.uid !== action.payload
                    )
                };
            } else {
                state.notifications?.map(
                    notif =>
                        notif.uid !== "unused" &&
                        newNotifications.push(notif.uid)
                );
                localStorage.setItem(
                    "bidNotifications",
                    JSON.stringify(newNotifications)
                );
                return { ...state, notifications: [] };
            }

        case OPEN_BID_NOTIFICATION_MODAL:
            return { ...state, openBidNotificationModal: action.payload };

        default:
            return state;
    }
};

export default bidBudgetUIReducer;
