import React, { ReactNode } from "react";
import { useState } from "react";
import styled from "styled-components";
import { FlexVerticallyCentered } from "../../../components/CommonStyles";
import { ModalTitle } from "../../../components/text/CommonStyles";
import { colors, fonts } from "../../../constants";
import HalfScreenModal from "../../../modals/HalfScreenModal";
import { BigBlueButton } from "../../components/Buttons";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 18px 24px;
`;

const Input = styled.input`
    outline: none;
    width: 100%;
    padding: 0 12px;
    height: 46px;
    border: 1px solid #e0eef2;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
    text-align: left;
    margin-bottom: 12px;
`;

const Description = styled.div`
    text-align: left;
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
    margin-top: 21px;
    margin-bottom: 16px;
`;

const Error = styled.div`
    color: ${colors.primaryRed};
    font-size: 12px;
    margin-bottom: 4px;
`;

const DurationWrapper = styled.div`
    ${FlexVerticallyCentered}

    & > * ~ * {
        margin-left: 10px;
    }
`;

const initialDuration = {
    days: "",
    hours: ""
};

interface Props {
    closeModal: () => void;
    onSubmit: (value: any) => void;
    modalTitle: string;
    description: string;
    placeholder: string;
    currentType?: ReactNode;
    durationInput?: true;
    numeric?: true;
}

const CustomOfferTypeModal = ({
    onSubmit,
    closeModal,
    modalTitle,
    description,
    placeholder,
    currentType,
    durationInput,
    numeric
}: Props) => {
    const [value, setValue] = useState("");
    const [duration, setDuration] = useState(initialDuration);
    const [error, setError] = useState("");

    const submitDuration = () => {
        const days = parseInt(duration.days || "0");
        const hours = parseInt(duration.hours || "0");

        if (days + hours > 0) {
            onSubmit(days * 86400 + hours * 3600);
            closeModal();
        } else {
            setError("Please Enter Valid Duration");
        }
    };

    const submitValue = () => {
        if (!value) {
            setError("Please Fill in all Fields");
        } else if (numeric && !parseFloat(value)) {
            setError("Please enter valid numbers");
        } else {
            onSubmit(value);
            closeModal();
        }
    };

    if (durationInput) {
        return (
            <HalfScreenModal closeModal={closeModal}>
                <Main>
                    <ModalTitle>{modalTitle}</ModalTitle>

                    <Description>{description}</Description>
                    {currentType}
                    <DurationWrapper>
                        <Input
                            type="number"
                            placeholder="Days"
                            inputMode="numeric"
                            value={duration.days}
                            onChange={e =>
                                setDuration({
                                    ...duration,
                                    days: e.currentTarget.value
                                })
                            }
                        />
                        <Input
                            type="number"
                            placeholder="Hours"
                            inputMode="numeric"
                            value={duration.hours}
                            onChange={e =>
                                setDuration({
                                    ...duration,
                                    hours: e.currentTarget.value
                                })
                            }
                        />
                    </DurationWrapper>
                    {error && <Error>{error}</Error>}
                    <BigBlueButton onClick={submitDuration}>
                        Confirm
                    </BigBlueButton>
                </Main>
            </HalfScreenModal>
        );
    }

    return (
        <HalfScreenModal closeModal={closeModal}>
            <Main>
                <ModalTitle>{modalTitle}</ModalTitle>
                <Description>{description}</Description>
                {currentType}
                <Input
                    type={numeric ? "number" : "text"}
                    placeholder={placeholder}
                    inputMode={numeric ? "numeric" : "text"}
                    value={value}
                    onChange={e => setValue(e.currentTarget.value)}
                />
                {error && <Error>{error}</Error>}
                <BigBlueButton onClick={submitValue}>Confirm</BigBlueButton>
            </Main>
        </HalfScreenModal>
    );
};

export default CustomOfferTypeModal;
