import { PublicProfile } from "src/profile/types";
import { NormalizedActionGeneric } from "src/types";
import { FETCH_PUBLIC_PROFILE } from "./action";

export interface PublicProfileEntitiesState {
    [id: string]: PublicProfile;
}

const initialPublicProfileEntitiesState: PublicProfileEntitiesState = {};

const publicProfileEntitiesReducer = (
    state = initialPublicProfileEntitiesState,
    action: NormalizedActionGeneric<PublicProfile, string[]>
) => {
    switch (action.type) {
        case FETCH_PUBLIC_PROFILE.SUCCESS:
            return { ...state, ...action.response.entities.publicProfiles };
        default:
            return state;
    }
};

export default publicProfileEntitiesReducer;
