import React from "react";
import {
    DirectBuyStepCollection,
    DirectBuyStepAction
} from "../../../modules/direct-buy-step/types";
import { DirectBuyStepsEntitiesState } from "../../../modules/direct-buy-step/reducer";
import { ResourceContainer, ResourceDescription } from "../styledComponents";
import DirectBuyDetailsLinkSubmissionCell from "./DirectBuyDetailsLinkSubmissionCell";
import DirectBuyDetailsStepCollectionStatus from "../components/DirectBuyDetailsStepCollectionStatus";
import { StepById } from "src/buys/modules/open-buys/reducer";
import { useDispatch, useSelector } from "react-redux";
import { SubmissionButton } from "../DirectBuyDetailsStepCollection";
import { setOpenBuySubmissionModal } from "src/buys/actions";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

interface Props {
    collection: DirectBuyStepCollection;
    steps: DirectBuyStepsEntitiesState | StepById;
    open: boolean;
}

const DirectBuyDetailsLinkSubmissions = ({
    collection,
    steps,
    open
}: Props) => {
    const dispatch = useDispatch();

    const showButton = !useSelector(
        selectFeatureFlags(featureFlags.ChallengesGroupedSubmissions)
    );

    const linkSubmissionSteps = collection.combined
        .filter(id => steps[id].requiresLinkSubmission)
        .map(id => steps[id]);
    if (linkSubmissionSteps.length === 0) {
        return null;
    }

    if (open)
        return showButton ? (
            <SubmissionButton
                onClick={() =>
                    dispatch(setOpenBuySubmissionModal(collection.parent))
                }
            >
                Submit your link
            </SubmissionButton>
        ) : null;
    return (
        <ResourceContainer>
            <ResourceDescription>CONFIRMATION LINKS</ResourceDescription>
            {linkSubmissionSteps.map(step => (
                <DirectBuyDetailsLinkSubmissionCell key={step.id} step={step} />
            ))}
            <DirectBuyDetailsStepCollectionStatus
                collection={collection}
                steps={steps}
                action={DirectBuyStepAction.linkSubmission}
            />
        </ResourceContainer>
    );
};

export default DirectBuyDetailsLinkSubmissions;
