import React, { useState } from "react";
import { push, replace } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAuthState } from "src/auth/selectors";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import { updateGroupRemoveUser } from "../actions";
import { getGroupMembers } from "../selectors";
import ErrorModal from "src/modals/ErrorModal";

const Footer = styled.div`
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: ${colors.primaryRed};
    border-top: 1px solid ${colors.mediumTeal};
    padding: 16px 0 32px;
`;

interface Props {
    conversationId: string;
}

const LeaveGroupFooter = ({ conversationId }: Props) => {
    const dispatch = useDispatch();
    const [errorModal, toggleErrorModal] = useState(false);
    const { users, userCount } = useSelector((state: GlobalState) =>
        getGroupMembers(state, conversationId, "", true)
    );
    const { userId } = useSelector(getAuthState);

    function leaveGroup() {
        if (userCount === 1 && users[0].userId === `${userId}`) {
            toggleErrorModal(true);
        } else {
            dispatch(updateGroupRemoveUser(conversationId, `${userId}`));
            dispatch(replace("/newchat"));
        }
    }

    return (
        <>
            <Footer onClick={leaveGroup}>Leave Group</Footer>
            {errorModal && (
                <ErrorModal
                    closeModal={() => toggleErrorModal(false)}
                    message="Please assign another member as an admin before leaving this group."
                />
            )}
        </>
    );
};
export default LeaveGroupFooter;
