import React, { useState } from "react";
import { DirectBuy } from "../../modules/direct-buy/types";
import styled from "styled-components";
import HalfScreenModal from "../../../modals/HalfScreenModal";
import { clearModal } from "../../../modals/actions";
import TwoButtonFooter from "../../../components/TwoButtonFooter";
import Divider from "../../../components/Divider";
import { colors, fonts } from "../../../constants";
import { post } from "../../../Api";
import { deleteDirectBuy } from "../../actions";
import { addNotification } from "../../../ui/notifications/actions";
import { errorToString } from "../../../util";
import { goBack } from "connected-react-router";
import { useDispatch } from "react-redux";
import { OpenBuy } from "src/buys/modules/open-buys/types";

const Form = styled.div`
    padding: 24px 18px 18px;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
    padding: 18px 0 24px;
`;

const OptionContainer = styled.div`
    border: 1px solid ${colors.mediumTeal};
    border-radius: 10px;
    overflow: hidden;
`;

interface HeightProps {
    cellHeight: number;
}

const OptionItem = styled.div<HeightProps>`
    height: ${props => props.cellHeight}px;
    padding: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    & + & {
        border-top: 1px solid ${colors.mediumTeal};
    }
`;

const Checkbox = styled.img`
    padding-right: 12px;
`;

const TextBox = styled.textarea<HeightProps>`
    height: ${props => 3 * props.cellHeight}px;
    width: 100%;
    display: block;
    outline: none;
    border: none;
    resize: none;
    padding: 12px;
`;

interface Props {
    directBuy: DirectBuy | OpenBuy;
}

enum RejectionReasonOptions {
    payment = "Payment too low",
    media = "The media is bad",
    alternatives = "I’ve committed to other promotions",
    other = "Other"
}

const DirectBuyDetailsRejectionReasonModal = ({ directBuy }: Props) => {
    const dispatch = useDispatch();

    const [selection, setSelection] = useState<RejectionReasonOptions | null>(
        null
    );
    const [otherReason, setOtherReason] = useState("");

    const [skipLoading, setSkipLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const submit = (rejectionReason: string | null) => {
        rejectionReason ? setSubmitLoading(true) : setSkipLoading(true);
        post(`/v1/directBuy/${directBuy.id}/offer`, {
            offerStatus: "rejected",
            offerRejectionReason: rejectionReason
        })
            .then(({ data }) => {
                dispatch(clearModal());
                dispatch(goBack());
                dispatch(deleteDirectBuy(data.data.id));
                dispatch(addNotification(`Feedback submitted, thanks!`));
            })
            .catch(error => {
                dispatch(
                    addNotification(
                        `Unable to reject offer: ${errorToString(error)}`,
                        colors.primaryRed
                    )
                );
                setSubmitLoading(false);
                setSkipLoading(false);
            });
    };

    const cellHeight = 48;
    const isOther = selection === RejectionReasonOptions.other;
    const optionArray = isOther
        ? [RejectionReasonOptions.other]
        : [
              RejectionReasonOptions.payment,
              RejectionReasonOptions.media,
              RejectionReasonOptions.alternatives,
              RejectionReasonOptions.other
          ];
    const hasReason = Boolean(isOther ? otherReason : selection);
    return (
        <HalfScreenModal
            closeModal={() => {
                dispatch(clearModal());
            }}
        >
            <Form>
                <Title>Help us get better 🙏</Title>
                <Subtitle>Why did you decline this purchase?</Subtitle>
                <OptionContainer>
                    {optionArray.map(option => (
                        <OptionItem
                            cellHeight={cellHeight}
                            onClick={() => {
                                if (selection === option) {
                                    setSelection(null);
                                } else {
                                    setSelection(option);
                                }
                            }}
                        >
                            <Checkbox
                                src={
                                    selection === option
                                        ? "/checkbox_selected.svg"
                                        : "/checkbox_unselected.svg"
                                }
                            />
                            <div>{option}</div>
                        </OptionItem>
                    ))}
                    {isOther && (
                        <TextBox
                            cellHeight={cellHeight}
                            placeholder="What went wrong?"
                            value={otherReason}
                            onChange={e =>
                                setOtherReason(e.currentTarget.value)
                            }
                        />
                    )}
                </OptionContainer>
            </Form>
            <Divider />
            <TwoButtonFooter
                leftText="Skip"
                leftCallback={() => {
                    submit(null);
                }}
                leftLoading={skipLoading}
                rightText="Submit"
                rightCallback={() => {
                    submit(isOther ? otherReason : selection);
                }}
                rightDisabled={!hasReason}
                rightLoading={submitLoading}
            />
        </HalfScreenModal>
    );
};

export default DirectBuyDetailsRejectionReasonModal;
