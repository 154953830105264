export const colors = {
    transparent: "rgba(0, 0, 0, 0)",

    grayBlack: "#333333",

    primaryGreen: "#00de49",
    secondaryGreen: "#009330",
    tertiaryGreen: "#4DE77F",
    quaternaryGreen: "#BBFFD1",
    primaryGray: "#909A9C",
    secondaryGray: "#A6ADB0",
    tertiaryGray: "#959FA1",
    quaternaryGray: "#e4e4e4",
    gray2: "#909A9C",
    gray3: "#c4c4c4",
    gray4: "#BDBDBD",
    gray5: "#E9E9E9",

    lightGray: "#f7f7f7",
    primaryLightGray: "#D9D9D9",
    secondaryLightGray: "#F5F5F5",
    tertiaryLightGray: "#F0F0F0",
    teritaryLightGray: "#FAFAFA",
    quaternaryLightGray: "#F2F2F2",
    quinaryLightGray: "#F5F5F5",

    lightGray2: "#e5e5e5",

    lightBlue: "#0079F5",

    primaryBlueLight: "#66ACF4",
    primaryBlueLighter: "#2993FF",
    primaryBlue: "#0079F5",
    primaryBlueDark: "#004387",
    lightestBlue: "#DAE7F4",

    primaryYellow: "#F1CB00",
    secondaryYellow: "#E8C300",
    messageYellow: "#F6E799",
    noteYellow: "#FCF1CF",
    lightYellow: "#FAF0D6",
    primaryRed: "#FF154E",
    primaryRedLight: "#F7CFD9",
    primaryRedDark: "#d11242",
    lightRed: "#FFDEE6",

    secondaryRed: "#EB5757",

    lighterTeal: "#F7FBFC",
    lightTeal: "#f8fbfc",
    mediumTeal: "#e0eef2",
    darkTeal: "#C5D1D4",

    blueGrey: "#F7FBFC",
    blueGrey1: "#ebf8fc",
    blueGrey2: "#E0EEF2",

    black: "#000000",
    white: "#FFFFFF",
    darkBackground: "rgb(70 69 69)",

    errorPink: " #FFC4D2",
    errorPink1: "#F7CFD9",

    //branding
    tiktokRed: "rgb(254, 44, 85)",
    tiktokBlack: "rgb(22, 24, 35)",

    //liftoff colors
    neutral1000: "#00081C",
    neutral900: "#273143",
    neutral700: "#505A6E",
    neutral500: "#9AA9C3",
    neutral300: "#D2DBE8",
    neutral100: "#F9FAFC",
    neutral0: "#FFFFFF",
    primary900: "#4952D0",
    primary700: "#555FFF",
    primary500: "#9CAEFC",
    primary300: "#DCE6FF",
    primary100: "#F0F4FF",
    error100: "#FEE8F1",
    error900: "#F91974",
    success900: "#00BF56",
    success700: "#00DE49",
    warning900: "#FF760D",
    warning100: "#FFF1E6",
    pending900: "#C0A200",
    pending100: "#FFF0A1"
};

export const gradients = {
    bidIncrease: `linear-gradient(225deg, #00DE49 0%, #2AFF38 100%)`
};

export const shadows = {
    transparent: "rgba(0, 0, 0, 0) 0px 0px 0px 0px",
    one: "rgba(189, 190, 198, 0.5) 0px 3px 8px 0px",
    oneUp: "rgba(189, 190, 198, 0.5) 3px 0px 8px 0px",
    two: "rgba(189, 190, 198, 0.3) 0px 6px 12px 0px",
    three: "rgba(189, 190, 198, 0.3) 0px 10px 20px 0px",
    four: "rgba(205, 205, 205, 0.25) 0px 4px 8px ",
    inputSelected: `${colors.primaryBlue} 0px 0px 4px 0px`,
    inputError: `${colors.primaryRed} 0px 0px 4px 0px`,
    corner: `-0.5px 0.6px 0.8px hsl(0deg 0% 79% / 0.26),
    -1.2px 1.4px 1.8px -1.2px hsl(0deg 0% 79% / 0.23),
    -3.1px 3.8px 4.7px -2.4px hsl(0deg 0% 79% / 0.19),
    -8.1px 9.9px 12.4px -3.5px hsl(0deg 0% 79% / 0.15);`
};

export const overlayBackgrounds = {
    light: "rgba(0, 0, 0, 0.5)",
    medium: "rgba(0, 0, 0, 0.70)"
};

export const maxWidth = {
    mediaCarousel: 400,
    navbar: 1200,
    desktop: 910
};

export const maxWidthDesktop = 910;

export const breakpoints = {
    tablet: 1120,
    small: 900,
    mobile: 500
};

export const sizes = {
    mediaItemWidth: 108,
    mediaItemHeight: 222,
    mediaPreviewHeight: 179,
    mediaLinkHeight: 43,
    navbar: {
        desktopHeight: 90,
        mobileHeight: 57,
        desktopBackHeight: 40
    },
    selectionButton: { height: 62 },
    campaigns: {
        selectionRow: { height: 62 }
    },
    personalOffers: {
        headerRowHeaderHeight: 44
    },
    marketplace: {
        pagePartial: {
            leftColumnWidth: 326
        },
        instructions: {
            dropdownHeight: 67,
            dropdownItem: {
                width: 110,
                height: 173
            }
        },
        personalOffers: {
            dropdownHeight: 67,
            dropdownItem: {
                width: 110,
                height: 173
            }
        },
        creatorGroups: {
            submissionFooter: { height: 64 },
            filterDropdown: { width: 360 },
            filterModal: { maxHeight: 685 },
            filterModalDropdown: { width: 444, height: 46 }
        },
        wizard: {
            footer: { height: 80 }
        },
        paymentSets: {
            inputContainer: { height: 46 },
            criteriaModal: { width: 492, minHeight: 352, maxHeight: 392 },
            estimateModal: { width: 492 }
        }
    }
};

export const plugIconURL =
    "https://firebasestorage.googleapis.com/v0/b/plugcoinprod.appspot.com/o/random%2Flarge_plug_logo.png?alt=media&token=e777ef43-8917-448f-acbf-fd661e45d1b1";

export const fonts = {
    bigTitle: 36,
    title: 24,
    semiBigTitle: 20,
    default: 16,
    semiTitle: 18,
    subtitle: 14,
    semiSmallTitle: 13,
    smallTitle: 12,
    semiSmallSubtitle: 11,
    smallSubtitle: 10,
    semiSuperSmallSubtitle: 9,
    superSmallSubtitle: 8
};

export const fontWeight = {
    default: 400,
    medium: 500,
    bold: 600,
    heavy: 700
};

export const spacing = {
    sideSpacing: 15
};
