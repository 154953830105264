import { push } from "connected-react-router";
import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import MainContainer from "src/components/MainContainer";
import PlugTable from "src/components/table/PlugTable";
import { TableColumn, TableRow } from "src/components/table/types";
import { createCell } from "src/components/table/utils";
import { colors, fontWeight } from "src/constants";
import MarketplacePagePartialHeader from "src/marketplace/components/MarketplacePagePartialHeader";
import { getCurrentInstructionsSet } from "src/marketplace/instructions-sets/selectors";
import { InstructionPayment } from "src/marketplace/instructions-sets/types";
import { Dropdown as InfoContainer } from "src/marketplace/payment-sets/components/PaymentSetDropdown";
import {
    SmallTitle,
    Title
} from "src/marketplace/payment-sets/components/styledComponents";
import PaymentSetsCreateOrEditPage from "src/marketplace/payment-sets/pages/PaymentSetsCreateOrEditPage";
import { stepRequiresSubmission } from "src/marketplace/payment-sets/utils/helpers";
import ChallengeList from "src/marketplace/reports/ChallengeList";
import { BlueButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import { setCurrentInstructionsSetId } from "src/ui/instructions-sets/actions";
import { shortenNumber } from "src/util";
import { convertSnakeCaseToTitleCase } from "src/utils/functions/helpers";
import styled from "styled-components";
import AdminSideMenu from "../components/AdminSideMenu";
import { Label } from "../components/ChallengeForm";
import ChallengePaymentForm from "../components/ChallengePaymentForm";
import FormHeading from "../components/FormHeading";
import PaymentAssignment, {
    PayoutTiming,
    PaymentConjunction
} from "../components/PaymentAssignment";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const columns: TableColumn[] = [
    {
        coreKey: "stepId",
        displayValue: "Step ID",
        sortType: "number"
    },
    { coreKey: "requires", displayValue: "Requires" },
    { coreKey: "description", displayValue: "Description" },
    { coreKey: "stepPayment", displayValue: "Payment" },
    { coreKey: "paymentMode", displayValue: "Payment Mode" },
    { coreKey: "payoutTiming", displayValue: "Payout Timing" }
];

const StyledMainContainer = styled(MainContainer)`
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    margin-bottom: 36px;
`;

const StyledBlueButton = styled(BlueButton)`
    padding: 8px 12px;
    font-size: 12px;
    width: 100%;
`;

const InfoText = styled(SmallTitle)<{ value?: boolean }>`
    font-weight: ${props =>
        (props.value && `${fontWeight.heavy}`) || `${fontWeight.medium}`};
    color: ${colors.black};
    padding-right: ${props => (props.value && "8px") || "0px"};
`;

const ErrorBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 8px 12px;
    gap: 10px;
    width: 100%;
    min-height: 30px;
    background: ${colors.errorPink1};
    border-radius: 4px;
    cursor: pointer;
`;

const WarningIcon = styled.img`
    width: 16px;
    height: 14px;
`;

interface Props {
    inEditChallengeFlow: boolean;
    currentOpenBuy: OpenBuy;
}

const ChallengePayments = ({ inEditChallengeFlow, currentOpenBuy }: Props) => {
    const dispatch = useDispatch();

    const paymentSetsSplit = useSelector(
        selectFeatureFlags(featureFlags.PaymentSets)
    );

    // Local State -----------------------------------------------------------
    const [selectedChallenge, setSelectedChallenge] = useState<OpenBuy | null>(
        currentOpenBuy || null
    );
    const [currentStepId, setCurrentStepId] = useState<number | null>(null);
    const [newPaymentsCount, setNewPaymentsCount] = useState<number>(0);

    // Selectors & Constants -----------------------------------------------------------
    const currentInstructionsSet = useSelector(getCurrentInstructionsSet);
    const currentInstructionsSetStepIds =
        currentInstructionsSet?.instructionSteps.map(step => step.id) || [];

    const allInstructionSteps = useSelector(
        (state: GlobalState) => state.entities.instructionsSetSteps.byId
    );

    const currentInstructionSteps = Object.values(allInstructionSteps).filter(
        step => {
            if (currentInstructionsSetStepIds.includes(step.id))
                return allInstructionSteps[step.id];
        }
    );

    const currentStep =
        currentStepId &&
        currentOpenBuy.instructionSetId === currentInstructionsSet?.id
            ? allInstructionSteps[currentStepId]
            : null;
    const currentStepPayments = currentStep?.instructionPayments || [];

    const { currentInstructionsSetId } = useSelector(
        (state: GlobalState) => state.ui.instructionsSets
    );
    const { selectedOpenBuy } = useSelector(
        (state: GlobalState) => state.ui.createOfferWizard
    );

    // Functions -----------------------------------------------------------
    const getPaymentList = (stepPayments: InstructionPayment[]) => {
        return (
            <>
                {stepPayments.map((payment, idx) => (
                    <span key={idx}>{`$${
                        payment.amount
                    } ${convertSnakeCaseToTitleCase(payment.payment.type)} ${
                        payment.viewsMinimum
                            ? `| ${shortenNumber(payment.viewsMinimum)} views`
                            : ""
                    }`}</span>
                ))}
            </>
        );
    };

    const tableData: TableRow[] = currentInstructionSteps.map(
        (instructionStep, idx: number) => {
            const submissionType = stepRequiresSubmission(instructionStep);
            const stepPayments: InstructionPayment[] =
                instructionStep.instructionPayments || [];
            const payments = getPaymentList(stepPayments);
            const description = instructionStep.description
                ? `${instructionStep.description?.slice(0, 75)}${
                      instructionStep.description?.length > 75 ? "..." : ""
                  }`
                : null;

            return {
                originalIdx: idx,
                raw: instructionStep,
                rowData: {
                    stepId: createCell.number(instructionStep.id),
                    description: createCell.string(description),
                    requires: createCell.string(submissionType),
                    stepPayment: createCell.custom(payments),
                    paymentMode: createCell.string(
                        instructionStep.paymentConjunction &&
                            PaymentConjunction[
                                instructionStep.paymentConjunction
                            ]
                    ),
                    payoutTiming: createCell.string(
                        instructionStep.payoutTiming &&
                            PayoutTiming[instructionStep.payoutTiming]
                    )
                }
            };
        }
    );

    const getNewPayments = () => {
        const children: ReactNode[] = [];

        for (let i = 0; i < newPaymentsCount; i++) {
            if (currentStep) {
                children.push(
                    <ChallengePaymentForm
                        key={i}
                        selectedStep={currentStep}
                        setNewPaymentsCount={setNewPaymentsCount}
                    />
                );
            }
        }
        return children;
    };

    // Effects -----------------------------------------------------------

    useEffect(() => {
        // if (selectedChallenge?.id) {
        //     paymentSetsSplit
        //         ? dispatch(fetchOpenBuyToEdit(selectedChallenge.id))
        //         : dispatch(requestOpenBuyById(selectedChallenge.id));
        // }
        setCurrentStepId(null);
        setNewPaymentsCount(0);
    }, [selectedChallenge?.id]);

    useEffect(() => {
        if (currentStepId) {
            setNewPaymentsCount(0);
        }
    }, [currentStepId]);

    // Return -----------------------------------------------------------
    return (
        <>
            {!inEditChallengeFlow && (
                <AdminSideMenu
                    selectedToolIndex={6}
                    title="Challenge Payments Manager"
                />
            )}
            <StyledMainContainer>
                {!inEditChallengeFlow && (
                    <MarketplacePagePartialHeader
                        includeHeading
                        customCrumbs={["Challenge Tools", "Payments"]}
                        headerTitle={
                            !selectedChallenge
                                ? "Challenge Payments Manager"
                                : "Edit Challenge Payments"
                        }
                        buttons={
                            selectedChallenge && (
                                <StyledBlueButton
                                    onClick={() => {
                                        setSelectedChallenge(null);
                                        dispatch(
                                            setCurrentInstructionsSetId(null)
                                        );
                                    }}
                                >
                                    Cancel
                                </StyledBlueButton>
                            )
                        }
                    />
                )}

                {paymentSetsSplit ? (
                    !selectedChallenge?.id ? (
                        <ChallengeList
                            onSelect={buy => setSelectedChallenge(buy)}
                        />
                    ) : !currentInstructionsSetId ? (
                        <ErrorBox
                            onClick={() =>
                                dispatch(
                                    push("/admin-tools/challenges/instructions")
                                )
                            }
                        >
                            <WarningIcon src={"/warning-red.svg"} />
                            <InfoText>
                                Please select an Instruction Set to apply
                                payment timing.
                            </InfoText>
                        </ErrorBox>
                    ) : (
                        <PaymentSetsCreateOrEditPage
                            openBuyId={selectedChallenge.id}
                        />
                    )
                ) : (
                    <>
                        {!selectedChallenge && !inEditChallengeFlow && (
                            <ChallengeList
                                onSelect={buy => {
                                    setSelectedChallenge(buy);
                                }}
                            />
                        )}

                        {!inEditChallengeFlow && selectedChallenge?.id && (
                            <Label style={{ gap: "8px" }}>
                                CHALLENGE
                                <InfoContainer>
                                    {selectedChallenge.name}
                                </InfoContainer>
                            </Label>
                        )}

                        {!!currentOpenBuy && (
                            <>
                                {!inEditChallengeFlow && (
                                    <Label style={{ gap: "8px" }}>
                                        INSTRUCTION SET
                                        <InfoContainer>
                                            {`${currentOpenBuy?.instructionSet?.id}- 
                    ${currentOpenBuy?.instructionSet?.name}`}
                                        </InfoContainer>
                                    </Label>
                                )}
                                <PlugTable
                                    cellData={tableData}
                                    columns={columns}
                                    rowClick={(row: TableRow) =>
                                        setCurrentStepId(row.raw.id)
                                    }
                                    resultsPerPage={
                                        currentInstructionSteps.length
                                    }
                                />
                            </>
                        )}

                        {currentStep !== null && (
                            <>
                                <PaymentAssignment
                                    openBuyId={currentOpenBuy?.id}
                                    currentStep={
                                        currentStep ? currentStep : undefined
                                    }
                                />
                                <FormHeading text="Modify Payments" />
                                {(currentStep?.instructionPayments || []).map(
                                    (payment: any, idx: number) => (
                                        <ChallengePaymentForm
                                            key={`challenge-payment-form-${idx}`}
                                            instructionStepPayment={payment}
                                            setNewPaymentsCount={
                                                setNewPaymentsCount
                                            }
                                        />
                                    )
                                )}
                            </>
                        )}
                        {getNewPayments()}

                        {currentStepId && (
                            <StyledBlueButton
                                onClick={() =>
                                    setNewPaymentsCount(newPaymentsCount + 1)
                                }
                            >
                                {currentStepPayments.length > 0
                                    ? "Add Another Payment"
                                    : "Add A Payment"}
                            </StyledBlueButton>
                        )}

                        {selectedChallenge?.instructionSetId === null && (
                            <Title>
                                There is no Instruction Set for this Challenge.
                                Please create one to apply payments.
                            </Title>
                        )}
                    </>
                )}
            </StyledMainContainer>
        </>
    );
};

export default ChallengePayments;
