import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";
import { LinkListItem } from "../types";

const linkListState = (state: GlobalState) => state.entities.linkLists;
const listId = (state: GlobalState, listId: string) => listId;

export const getLinkList = (state: GlobalState, id: number) =>
    state.entities.linkLists.ListById[id];

export const getLinkLists = createSelector(linkListState, linkListState =>
    linkListState.allIds.map((id: number) => linkListState.ListById[id])
);

export const getLinkListItems = createSelector(
    linkListState,
    listId,
    (linkListState, listId) => {
        const list = linkListState.ListById[listId];
        if (!list) return { links: [], ids: [] };
        let links: LinkListItem[] = list.LinkItems.map(
            (id: number) => linkListState.ItemById[id]
        );
        return {
            links,
            ids: list.LinkItems
        };
    }
);

export const getLinkListError = (state: GlobalState, component: string) =>
    state.ui.linkList.error?.component === component
        ? state.ui.linkList.error?.error
        : null;
