import React from "react";
import styled from "styled-components";
import VerticallyCentered from "../../../components/VerticallyCentered";
import Switch from "react-switch";
import { fonts } from "src/constants";

const Main = styled(VerticallyCentered)`
    justify-content: space-between;
    font-size: ${fonts.subtitle}px;

    & + & {
        margin-top: 15px;
    }
`;

interface Props {
    description: string;
    value: boolean;
    setValue: (newValue: boolean) => void;
}

const SettingsToggle = ({ description, value, setValue }: Props) => {
    return (
        <Main>
            <div>{description}</div>
            <Switch
                onColor={"#28c056"}
                offColor={"#cccccc"}
                checkedIcon={false}
                uncheckedIcon={false}
                handleDiameter={24}
                checked={value}
                onChange={(newValue: boolean) => setValue(newValue)}
            />
        </Main>
    );
};

export default SettingsToggle;
