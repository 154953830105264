import { MediaFilters } from "src/media/types";
import { Action } from "src/types";

export const SET_MEDIA_FILTERS = "SET_MEDIA_FILTERS";

export const setMediaFilters = (filters: MediaFilters): Action => ({
    type: SET_MEDIA_FILTERS,
    payload: { filters }
});

export const SET_MEDIA_SORT = "SET_MEDIA_SORT";

export const setMediaSort = (sortBy: string): Action => ({
    type: SET_MEDIA_SORT,
    payload: { sortBy }
});

export const SET_MEDIA_SEARCH_STRING = "SET_MEDIA_SEARCH_STRING";

export const setMediaSearchString = (searchString: string): Action => ({
    type: SET_MEDIA_SEARCH_STRING,
    payload: { searchString }
});
