import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { breakpoints, colors, fonts } from "src/constants";
import { BlueButton, OutlineButton } from "src/profile/components/Buttons";
import { LinkListItem } from "src/profile/types";
import styled from "styled-components";
import { editLinkListItem } from "../actions";

const Container = styled.div<{
    draggable: any;
    isDragging: boolean;
    isDisabled: boolean;
}>`
    border: 1px solid ${colors.mediumTeal};
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 10px;
    user-select: none;
    height: 90px;
    background: ${(props: any) => props.isDragging && colors.mediumTeal};
    ${(props: any) => props.draggable}
    justify-content: space-around;
    opacity: ${props => props.isDisabled && ".6"};
    @media (max-width: ${breakpoints.small}px) {
        flex-direction: column;
        min-height: 122px;
    }
`;

const Picture = styled.img`
    width: 46px;
    height: 46px;
    border-radius: 100px;
    margin-right: 10px;
    object-fit: cover;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Name = styled.div`
    font-weight: 700;
    font-size: ${fonts.subtitle}px;
`;

const Link = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 400;
    color: ${colors.secondaryGray};
    overflow-wrap: anywhere;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px;
    width: 100%;
`;

const EditButton = styled(BlueButton)`
    height: 40px;
    width: 200px;
    margin-right: 10px;
    @media (max-width: ${breakpoints.small}px) {
        height: 28px;
        width: 140px;
    }
`;

const DisableButton = styled(OutlineButton)<{ isDisabled: boolean }>`
    height: 40px;
    width: 200px;
    color: ${props => !props.isDisabled && "grey"};
    @media (max-width: ${breakpoints.small}px) {
        height: 28px;
        width: 140px;
    }
`;

const Dots = styled.img`
    margin-right: 8px;
    @media (max-width: ${breakpoints.small}px) {
        align-self: end;
    }
`;

interface Props {
    link: LinkListItem;
    onEdit: () => void;
    index: number;
}

const LinkListItemCell = ({ link, onEdit, index }: Props) => {
    const dispatch = useDispatch();

    return (
        <Draggable
            key={"linkItem" + link.id}
            draggableId={link.id.toString()}
            index={index}
        >
            {(provided, snapshot) => (
                <Container
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    draggable={provided.draggableProps.style}
                    isDragging={snapshot.isDragging}
                    isDisabled={!!link.disabled}
                >
                    <Row style={{ justifyContent: "start" }}>
                        <Dots src="/interaction/draggable.svg" />
                        <Picture
                            src={link.photoUrl}
                            style={{
                                opacity: link.photoUrl ? "1" : "0"
                            }}
                        />
                        <TitleContainer>
                            <Name>{link.title}</Name>
                            <Link>{link.url}</Link>
                        </TitleContainer>
                    </Row>
                    <Row>
                        <EditButton onClick={onEdit}>Edit</EditButton>
                        <DisableButton
                            isDisabled={!!link.disabled}
                            onClick={() =>
                                dispatch(
                                    editLinkListItem({
                                        id: link.id,
                                        disabled: !link.disabled
                                    })
                                )
                            }
                        >
                            {link.disabled ? "Enable" : "Disable"}
                        </DisableButton>
                    </Row>
                </Container>
            )}
        </Draggable>
    );
};

export default LinkListItemCell;
