import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLinkForPlug } from "src/links/actions";
import ShareButton from "src/components/ShareButton";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import MediaStatus from "../components/MediaStatus";
import ShadeScreen from "../components/ShadeScreen";
import MediaButtons from "./MediaButtons";
import MediaTextOverlay from "./MediaTextOverlay";
import CheckBox from "src/components/CheckBox";
import { colors } from "src/constants";
import { selectMedia, removeSelectedMedia } from "../actions";
import ShowIcon from "src/components/ShowIcon";

const Main = styled.div<{ $isSelected?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: ${props =>
        props.$isSelected ? `0px 0px 0px 4px ${colors.primaryBlue}` : `none`};
    cursor: pointer;
    user-select: none;
`;

const MediaWrapper = styled.div`
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    flex: 1;
    overflow: hidden;
    position: relative;
`;

const CoverPhoto = styled.img`
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
`;
const SelectBox = styled(CheckBox)`
    position: absolute;
    top: 8px;
    right: 8px;
`;
const StyledShowIcon = styled(ShowIcon)`
    position: absolute;
    right: 8px;
    top: 38px;
`;

interface Props {
    mediaId: number;
    mediaCallback: () => void;
    isMultiSelect?: boolean;
}

const DynamicMediaCard = ({ mediaId, mediaCallback, isMultiSelect }: Props) => {
    const dispatch = useDispatch();
    const media = useSelector(
        (state: GlobalState) => state.entities.medias[mediaId]
    );

    const shouldShowStatus = useSelector(
        (state: GlobalState) => state.pagination.medias.filters.isOwner
    );
    const isSelected = useSelector((state: GlobalState) =>
        state.media.selectedMedias.includes(mediaId)
    );

    return (
        <Main $isSelected={isSelected}>
            <MediaWrapper>
                <CoverPhoto
                    src={media.coverPhotoUrl || ""}
                    onClick={mediaCallback}
                />
                <ShadeScreen opacity={!media.shouldShowInApp ? 0.7 : 0.5}>
                    {media.type === "video" && <img src={"/play_icon.svg"} />}
                </ShadeScreen>
                {isMultiSelect ? (
                    <SelectBox
                        selected={isSelected}
                        onCheck={() => dispatch(selectMedia(mediaId))}
                        onUncheck={() => dispatch(removeSelectedMedia(mediaId))}
                    />
                ) : (
                    <ShareButton
                        link={`/campaign/${media.campaignId}?mediaId=${media.id}`}
                    />
                )}
                {!media.shouldShowInApp && <StyledShowIcon />}
                <MediaTextOverlay
                    title={`Media ID: ${media.id}`}
                    titleSize={13}
                    status={
                        shouldShowStatus && (
                            <MediaStatus status={media.mediaStatus} />
                        )
                    }
                    wrapTitle
                    bottom={media.campaignName}
                    bottomSize={13}
                    bottomSecondary={media.username && `By: @${media.username}`}
                />
            </MediaWrapper>
            <MediaButtons
                inCard
                disabled={media.mediaStatus !== "approved"}
                mediaId={media.id}
                mediaUrl={media.url}
                linkCallback={() => dispatch(fetchLinkForPlug(media))}
                borderRadius={6}
            />
        </Main>
    );
};

export default DynamicMediaCard;
