import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useInterval from "../../../components/useInterval";
import { colors, fonts } from "src/constants";

const Main = styled.div`
    padding: 10px;
    border: ${colors.mediumTeal} solid 1px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
`;

interface StyleProps {
    inactive: boolean;
}

const DatePart = styled.div<StyleProps>`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 56px;
    text-align: center;
    font-size: ${fonts.smallTitle}px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => props.inactive && "color: #909A9C;"}
`;

const DateTitle = styled.span`
    display: block;
    font-size: ${fonts.smallTitle}px;
    color: ${colors.primaryGray};
`;

const DateValue = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

const Countdown = ({ endDate }: { endDate: number }) => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    function convertNumber(number: number) {
        if (number > 9) {
            return number.toString();
        } else {
            return `0${number}`;
        }
    }

    function setState() {
        const now = new Date().getTime();
        const difference = endDate - now;

        const newDays = Math.floor(difference / (1000 * 60 * 60 * 24));
        if (newDays > 0) {
            setDays(newDays);
        } else {
            setDays(0);
        }

        const newHours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        if (newHours > 0) {
            setHours(newHours);
        } else {
            setHours(0);
        }

        const newMinutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        if (newMinutes > 0) {
            setMinutes(newMinutes);
        } else {
            setMinutes(0);
        }

        const newSeconds = Math.floor((difference % (1000 * 60)) / 1000);
        if (newSeconds > 0) {
            setSeconds(newSeconds);
        } else {
            setSeconds(0);
        }
    }

    useEffect(setState, []);

    useInterval(setState, 1000);

    const daysInactive = days === 0;
    const hoursInactive = daysInactive && hours === 0;
    const minutesInactive = hoursInactive && minutes === 0;
    const secondsInactive = minutesInactive && seconds === 0;

    return (
        <Main>
            <DatePart inactive={daysInactive}>
                <DateTitle>Days</DateTitle>
                <DateValue>{days}</DateValue>
            </DatePart>

            <DatePart inactive={hoursInactive}>
                <DateTitle>Hours</DateTitle>
                <DateValue>
                    {hoursInactive ? "0" : convertNumber(hours)}
                </DateValue>
            </DatePart>

            <DatePart inactive={minutesInactive}>
                <DateTitle>Minutes</DateTitle>
                <DateValue>
                    {minutesInactive ? "0" : convertNumber(minutes)}
                </DateValue>
            </DatePart>

            <DatePart inactive={secondsInactive}>
                <DateTitle>Seconds</DateTitle>
                <DateValue>
                    {secondsInactive ? "0" : convertNumber(seconds)}
                </DateValue>
            </DatePart>
        </Main>
    );
};

export default Countdown;
