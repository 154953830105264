import React from "react";
import CampaignBidPriceSlider from "./CampaignBidPriceSlider";
import { Label } from "src/media/search/components/CommonStyles";
import { CampaignFilters } from "src/campaigns/types";

interface Props {
    filters: CampaignFilters;
    setFilters: (filters: CampaignFilters) => void;
}

const CampaignBidPrice = ({ filters, setFilters }: Props) => {
    const handleBidChange = (key: "bidLow" | "bidHigh", num: number) => {
        setFilters({
            ...filters,
            [key]: num
        });
    };

    return (
        <>
            <Label>BID PRICE</Label>
            <CampaignBidPriceSlider
                min={0}
                max={10}
                handleBidChange={handleBidChange}
                filters={filters}
            />
        </>
    );
};

export default CampaignBidPrice;
