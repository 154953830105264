import { Action } from "../types";
import { MODAL } from "./actions";
import { CLEAR_MODAL } from "../modals/actions";
import { SocialAccountProfile } from "./utils/social-accounts.types";

export enum AccountsModal {
    clear,
    thanks
}

export type SocialAccountsState = {
    modal: AccountsModal;
    currentSocialAccountProfile: SocialAccountProfile | null;
};

const initialState: SocialAccountsState = {
    modal: AccountsModal.clear,
    currentSocialAccountProfile: null
};

const accountsReducer = (
    state: SocialAccountsState = initialState,
    action: Action
) => {
    switch (action.type) {
        case CLEAR_MODAL:
            return { ...state, modal: AccountsModal.clear };
        case MODAL.THANKS:
            return { ...state, modal: AccountsModal.thanks };
        default:
            return state;
    }
};

export default accountsReducer;
