import styled from "styled-components";
import { colors, maxWidthDesktop } from "src/constants";

interface ProfileProps {
    private?: true;
}

const ProfileMainContainer = styled("div")<ProfileProps>`
    min-height: 100vh;
    padding-top: ${props => (props.private ? "20px" : "0px")};
    padding-bottom: 40px;

    @media (max-width: ${maxWidthDesktop}px) {
        padding-top: 0px;
    }
`;

export default ProfileMainContainer;
