import React, { MouseEventHandler } from "react";
import { colors } from "src/constants";
import styled from "styled-components";

const BtnWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    height: 26px;
    width: 26px;
    cursor: pointer;
    &:hover {
        background-color: ${colors.primaryGray};
    }
`;
const Close = styled.img`
    width: 12px;
    height: 12px;
`;

const RemoveBtn = ({
    handleClick,
    stopPropagation = true,
    className
}: {
    handleClick: VoidFunction;
    className?: string;
    stopPropagation?: boolean;
}) => {
    return (
        <BtnWrapper
            onClick={e => {
                if (stopPropagation) {
                    e.stopPropagation();
                }
                handleClick();
            }}
            className={className}
        >
            <Close src="/white_close.svg" alt="" />
        </BtnWrapper>
    );
};

export default RemoveBtn;
