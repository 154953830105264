import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    adminCreateOpenBuyPoster,
    adminDeleteOpenBuy,
    adminUpdateOpenBuy,
    adminUpdateOpenBuyPoster
} from "src/admin-tools/actions.admin-tools";
import { getCurrentOpenBuy } from "src/admin-tools/selectors.admin-tools";
import { fetchCampaigns } from "src/campaigns/actions";
import { selectCampaign } from "src/campaigns/selectors";
import GlobalWarning from "src/components/GlobalWarning";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";
import { AdminToolsChallengeFormLoading } from "src/components/SkeletonLoader";
import { breakpoints, sizes } from "src/constants";
import { GlobalState } from "src/reducers";
import {
    setChallengeDetailsIncomplete,
    setChargeAdvertisersPerInstall,
    setDetailsUnsaved
} from "src/ui/admin-tools/actions";
import { setCurrentInstructionsSetId } from "src/ui/instructions-sets/actions";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { capitalizeFirstCharacter } from "src/utils/functions/helpers";
import styled from "styled-components";
import MarketplacePagePartialHeader from "../components/MarketplacePagePartialHeader";
import {
    deleteInstructionsSet,
    resetInstructionSets
} from "../instructions-sets/actions.instructions-sets";
import ChallengeBudget from "./components/ChallengeBudget";
import ChallengeCreationFormFooter from "./components/ChallengeCreationFormFooter";
import ChallengeDetails from "./components/ChallengeDetails";
import ChallengeLogistics from "./components/ChallengeLogistics";
import { SelectedPlatform } from "./utils/types";
import ChallengeRules, { ChallengeRulesRef } from "./components/ChallengeRules";
import { OpenBuy } from "src/buys/modules/open-buys/types";

const Form = styled.form`
    display: relative;
    padding-bottom: ${sizes.marketplace.creatorGroups.submissionFooter.height +
        24}px;
`;

const Container = styled(MainContainer)`
    @media (max-width: ${breakpoints.tablet}px) {
        padding-top: 15px;
    }
`;

const Main = styled.main`
    display: flex;
    gap: 24px;
    align-items: flex-start;
    text-align: left;

    @media (max-width: ${breakpoints.small}px) {
        flex-direction: column;
    }
`;

const VerticalLayout = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

const defaultChallengeDetails = {
    name: "",
    description: "",
    linkUrl: "",
    linkDescription: "",
    details: "",
    posterUrl: "",
    submissionLimitPerPublisher: 1,
    submissionLimitPerSocialAccount: 1,
    usageRightsLengthDays: undefined
};

interface Props {
    onCreatePage: boolean;
    setDetailsComplete?: React.Dispatch<React.SetStateAction<boolean>>;
    noNavbar?: true;
    noBreadcrumbs?: true;
}

const ChallengeForm = ({
    onCreatePage,
    setDetailsComplete,
    noNavbar,
    noBreadcrumbs
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const { sortBy, filters, currentCampaignId } = useSelector(
        (state: GlobalState) => {
            return state.ui.campaign;
        },
        shallowEqual
    );
    const currentCampaign = useSelector(selectCampaign);

    const currentOpenBuyId = useSelector((state: GlobalState) => {
        return state.ui.adminTools.currentOpenBuyId;
    });
    const currentOpenBuy: OpenBuy = useSelector(getCurrentOpenBuy);

    // Local State ---------------------------------------------------
    const [deleteChallengeWarning, setDeleteChallengeWarning] = useState(false);
    // Challenge Details
    const [challengeDetails, setChallengeDetails] = useState<Partial<OpenBuy>>(
        defaultChallengeDetails
    );
    const [posterFilename, setPosterFilename] = useState("");
    // Challenge Logistics
    const [isApplication, setIsApplication] = useState(false);
    const [autoAssignNewUsers, setAutoAssignNewUsers] = useState(false);
    const [allPublishers, setAllPublishers] = useState(false);
    const [selectedPlatforms, setSelectedPlatforms] = useState<
        SelectedPlatform
    >({ Tiktok: "tiktok" });
    const [isExperimental, setIsExperimental] = useState<boolean>(false);
    const [isComplimentary, setIsComplimentary] = useState<boolean>(false);
    const [leaderboardScoreMinimum, setLeaderboardScoreMinimum] = useState(4);
    const [creatorScoreMinimum, setCreatorScoreMinimum] = useState(0);
    const [linkedOpenBuyId, setLinkedOpenBuyId] = useState<number | null>(null);
    // Challenge Budget
    const [jetfuelPercentFee, setJetfuelPercentFee] = useState(0);
    const [creatorPayoutBudget, setCreatorPayoutBudget] = useState(0);
    const [advertiserBudget, setAdvertiserBudget] = useState(0);
    const [billType, setBillType] = useState("");
    // Challenge Rules
    const rulesRef = useRef<ChallengeRulesRef>(null);
    const [hasRuleChanges, setHasRuleChanges] = useState<boolean>(false);

    // Local Functions -----------------------------------------------
    function handleSetSelectedPlatforms(buy: any) {
        if (buy.allowedPlatforms) {
            const newSelectedPlatforms = { ...selectedPlatforms };
            buy.allowedPlatforms.forEach((platform: string) => {
                const capitalizedPlatform = capitalizeFirstCharacter(platform);
                newSelectedPlatforms[capitalizedPlatform] = platform;
            });
            setSelectedPlatforms(newSelectedPlatforms);
        }
    }

    function checkRequiredFieldsFilled(): boolean {
        return (
            !!challengeDetails.posterUrl?.length &&
            !!challengeDetails.name?.length &&
            !!challengeDetails.description?.trim().length &&
            !!currentCampaign &&
            Object.keys(selectedPlatforms).length > 0
        );
    }

    function handleUpdate(): void {
        const requiredFieldsFilled = checkRequiredFieldsFilled();
        if (requiredFieldsFilled) {
            dispatch(setChallengeDetailsIncomplete(false));
            if (currentOpenBuyId && currentCampaignId) {
                const data = {
                    id: currentOpenBuyId,
                    campaignId: currentCampaignId,
                    name: challengeDetails.name,
                    description: challengeDetails.description,
                    budgetAmount: creatorPayoutBudget,
                    submissionLimitPerPublisher:
                        challengeDetails.submissionLimitPerPublisher,
                    submissionLimitPerSocialAccount:
                        challengeDetails.submissionLimitPerSocialAccount,
                    allPublishers,
                    isApplication,
                    autoAssignNewUsers,
                    linkedOpenBuyId: linkedOpenBuyId,
                    creatorScoreMinimum,
                    isExperimental,
                    isComplimentary,
                    allowedPlatforms: Object.values(selectedPlatforms),
                    billType,
                    leaderboardScoreMinimum,
                    jetfuelPercentFee: jetfuelPercentFee / 100,
                    ...(challengeDetails?.linkUrl?.length && {
                        linkUrl: challengeDetails.linkUrl
                    }),
                    ...(challengeDetails?.linkDescription?.length && {
                        linkDescription: challengeDetails.linkDescription
                    }),
                    posterFilename,
                    usageRightsLengthDays:
                        challengeDetails.usageRightsLengthDays || undefined
                } as any;

                if (hasRuleChanges) {
                    rulesRef.current?.handleSaveRules();
                }
                dispatch(adminUpdateOpenBuy(data));
                dispatch(setDetailsUnsaved(false));
            }
        } else {
            dispatch(setChallengeDetailsIncomplete(true));
            dispatch(
                setPillNotificationText(
                    "You must fill in all required fields.",
                    "danger",
                    3500
                )
            );
        }
    }

    function handleDelete(): void {
        if (currentOpenBuy) {
            setDeleteChallengeWarning(true);
        }
    }

    // Effects -------------------------------------------------------
    useEffect(() => {
        dispatch(fetchCampaigns(sortBy, filters));
    }, []);

    useEffect(() => {
        if (setDetailsComplete) {
            setDetailsComplete(checkRequiredFieldsFilled());
        }

        // dispatch(setChallengeDetailsIncomplete(!checkRequiredFieldsFilled()));
    }, [
        challengeDetails.posterUrl,
        challengeDetails.name,
        challengeDetails.description,
        challengeDetails.linkUrl,
        challengeDetails.linkDescription,
        challengeDetails.usageRightsLengthDays,
        currentCampaign,
        selectedPlatforms,
        jetfuelPercentFee,
        creatorPayoutBudget,
        advertiserBudget
    ]);

    useEffect(() => {
        if (currentOpenBuy) {
            setChallengeDetails({
                ...defaultChallengeDetails,
                ...currentOpenBuy
            });
            setAllPublishers(currentOpenBuy.allPublishers || false);
            setIsExperimental(currentOpenBuy.isExperimental ?? false);
            setIsComplimentary(currentOpenBuy.isComplimentary ?? false);
            setCreatorScoreMinimum(currentOpenBuy.creatorScoreMinimum ?? 0);
            setLinkedOpenBuyId(currentOpenBuy.linkedOpenBuyId);
            setIsApplication(currentOpenBuy.isApplication ?? false);
            setAutoAssignNewUsers(currentOpenBuy.autoAssignNewUsers);
            handleSetSelectedPlatforms(currentOpenBuy);
            dispatch(
                setChargeAdvertisersPerInstall(
                    !!currentOpenBuy.jetfuelPercentFee
                )
            );
            if (currentOpenBuy.jetfuelPercentFee) {
                setJetfuelPercentFee(currentOpenBuy.jetfuelPercentFee * 100);
                setAdvertiserBudget(
                    currentOpenBuy.budgetAmount /
                        (1 - currentOpenBuy.jetfuelPercentFee)
                );
            }
            if (currentOpenBuy.leaderboardScoreMinimum)
                setLeaderboardScoreMinimum(
                    currentOpenBuy.leaderboardScoreMinimum
                );
            dispatch(setChargeAdvertisersPerInstall(true));
            setCreatorPayoutBudget(currentOpenBuy.budgetAmount);

            if (currentOpenBuy.billType) {
                setBillType(currentOpenBuy.billType);
            } else setBillType("fixed");

            dispatch(
                setCurrentInstructionsSetId(currentOpenBuy.instructionSetId)
            );
        }
    }, [currentOpenBuy]);

    useEffect(() => {
        if (currentOpenBuy) dispatch(setDetailsUnsaved(hasUnsaved()));
        // Separate function to take advantage of the readability afforded
        // by EARLY RETURN
        function hasUnsaved(): boolean {
            if (allPublishers !== currentOpenBuy.allPublishers) return true;
            if (isExperimental !== currentOpenBuy.isExperimental) return true;
            if (challengeDetails.name !== currentOpenBuy.name) return true;
            if (isComplimentary !== currentOpenBuy.isComplimentary) return true;
            if (currentOpenBuy.description) {
                if (challengeDetails.description !== currentOpenBuy.description)
                    return true;
            } else if (challengeDetails.description !== "") {
                return true;
            }
            if (currentOpenBuy.linkUrl) {
                if (challengeDetails.linkUrl !== currentOpenBuy.linkUrl)
                    return true;
            } else if (challengeDetails.linkUrl !== "") {
                return true;
            }
            if (currentOpenBuy.linkDescription) {
                if (
                    challengeDetails.linkDescription !==
                    currentOpenBuy.linkDescription
                )
                    return true;
            } else if (challengeDetails.linkDescription !== "") {
                return true;
            }

            if (currentOpenBuy.Buyer) {
                if (!currentCampaign) return true;
                if (currentCampaign.id !== currentOpenBuy.Buyer.campaignId)
                    return true;
            }

            if (hasRuleChanges) return true;

            return false;
        }
    }, [
        currentOpenBuy,
        allPublishers,
        isExperimental,
        challengeDetails,
        isComplimentary,
        currentCampaign,
        hasRuleChanges
    ]);

    function updateChallengeDetails(name: string, value: string) {
        setChallengeDetails(old => ({ ...old, [name]: value }));
    }

    // JSX -----------------------------------------------------------
    if (!currentOpenBuy) {
        return <AdminToolsChallengeFormLoading />;
    } else {
        return (
            <Form>
                {!noNavbar && <Navbar marginBottom={24} />}

                <Container addSidePadding>
                    {!noBreadcrumbs && <MarketplacePagePartialHeader />}

                    <Main>
                        <VerticalLayout>
                            <ChallengeDetails
                                challengeDetails={challengeDetails}
                                updateChallengeDetails={updateChallengeDetails}
                                updatePosterFilename={setPosterFilename}
                            />
                            <ChallengeRules
                                ref={rulesRef}
                                setHasRuleChanges={setHasRuleChanges}
                                ruleSetId={currentOpenBuy.ruleSetId}
                                challengeName={challengeDetails.name}
                            />
                        </VerticalLayout>
                        <VerticalLayout>
                            <ChallengeLogistics
                                currentCampaign={currentCampaign}
                                allPublishers={allPublishers}
                                setAllPublishers={setAllPublishers}
                                selectedPlatforms={selectedPlatforms}
                                setSelectedPlatforms={setSelectedPlatforms}
                                isExperimental={isExperimental}
                                setIsExperimental={setIsExperimental}
                                isComplimentary={isComplimentary}
                                setIsComplimentary={setIsComplimentary}
                                leaderboardScoreMinimum={
                                    leaderboardScoreMinimum
                                }
                                setLeaderboardScoreMinimum={
                                    setLeaderboardScoreMinimum
                                }
                                creatorScoreMinimum={creatorScoreMinimum}
                                setCreatorScoreMinimum={setCreatorScoreMinimum}
                                isApplication={isApplication}
                                setIsApplication={setIsApplication}
                                setAutoAssignNewUsers={setAutoAssignNewUsers}
                                autoAssignNewUsers={autoAssignNewUsers}
                                linkedOpenBuyId={linkedOpenBuyId}
                                setLinkedOpenBuyId={setLinkedOpenBuyId}
                            />
                            <ChallengeBudget
                                jetfuelPercentFee={jetfuelPercentFee}
                                setJetfuelPercentFee={setJetfuelPercentFee}
                                creatorPayoutBudget={creatorPayoutBudget}
                                setCreatorPayoutBudget={setCreatorPayoutBudget}
                                advertiserBudget={advertiserBudget}
                                setAdvertiserBudget={setAdvertiserBudget}
                                billType={billType}
                                setBillType={setBillType}
                            />
                        </VerticalLayout>
                    </Main>
                </Container>

                <ChallengeCreationFormFooter
                    onCreatePage={onCreatePage}
                    updateHandler={handleUpdate}
                    deleteHandler={handleDelete}
                />

                {deleteChallengeWarning && (
                    <GlobalWarning
                        title={"Are you sure?"}
                        message={
                            "Are you sure you want to delete this challenge?"
                        }
                        handleCancel={() => setDeleteChallengeWarning(false)}
                        handleApply={() => {
                            if (currentOpenBuy && currentOpenBuy.id) {
                                dispatch(adminDeleteOpenBuy(currentOpenBuy.id));
                                dispatch(resetInstructionSets());
                            }
                            if (
                                currentOpenBuy &&
                                currentOpenBuy.instructionSetId
                            ) {
                                dispatch(
                                    deleteInstructionsSet(
                                        currentOpenBuy.instructionSetId
                                    )
                                );
                            }
                        }}
                        btnText={{
                            cancel: "Cancel",
                            apply: "Delete"
                        }}
                    />
                )}
            </Form>
        );
    }
};

export default ChallengeForm;
