import React, { useEffect, useState } from "react";
import { deleteReq, get, post } from "src/Api";
import { EventInput } from "src/utils/types/form";
import * as Papa from "papaparse";
import PlugTable from "src/components/table/PlugTable";
import { TableRow } from "src/components/table/types";
import { createCell, isNumberInputValid } from "src/components/table/utils";
import styled from "styled-components";
import {
    BlueButton,
    OutlineButton,
    RedButton
} from "src/profile/components/Buttons";
import Toggler from "src/components/Toggler";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import { useDispatch } from "react-redux";
import { setPillNotificationText } from "src/ui/notifications/actions";
import MarketplaceInput from "src/marketplace/components/MarketplaceInput";
import { colors } from "src/constants";

import FormHeading from "./FormHeading";
import GlobalWarning from "src/components/GlobalWarning";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 16px;
    text-align: left;
`;

const InputLabel = styled(OutlineButton)`
    padding: 8px 12px;
    width: fit-content;
    margin-bottom: 0px;
`;
const ToggleWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
`;
const TableWrap = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding-top: 12px;
    & > * {
        flex: 1;
    }
`;
const Disclaimer = styled.div`
    font-size: 12px;
    text-align: left;
    padding-bottom: 4px;
`;
const ColumnWrap = styled.div`
    display: flex;
    flex-direction: column;
`;
const BtnWrap = styled.div`
    display: flex;
    padding-right: 24px;
    gap: 8px;
`;
const StyledRedButton = styled(RedButton)`
    border-radius: 6px;
    flex: 1;
    padding: 8px 12px;
`;

interface Props {
    openBuy: OpenBuy;
}

const PubAssignment = ({ openBuy }: Props) => {
    const dispatch = useDispatch();
    const [isRemoval, setIsRemoval] = useState(
        openBuy.allPublishers ? true : false
    );
    const [tableLoading, setTableLoading] = useState(true);
    const getPublishers = () => {
        get(`/v1/openBuy/publisher/${openBuy.id}`, {
            isRemoved: isRemoval
        }).then(res => {
            setTableLoading(false);
            setRemovedPublishers(res.data.data);
        });
    };
    useEffect(() => {
        getPublishers();
        setTableLoading(true);
    }, [isRemoval]);
    const [individualPubInput, setIndividualPubInput] = useState("");
    const [csvReset, setCsvReset] = useState(false);
    const [stagedPublishers, setStagedPublishers] = useState<number[]>([]);
    const [removedPublishers, setRemovedPublishers] = useState<
        {
            publisherId: number;
            Publisher: { firstname: string; lastname: string };
        }[]
    >([]);
    const handleUnassignment = (pub: number) => {
        deleteReq(`/v1/openBuy/${openBuy.id}/publisher/${pub}`, {})
            .then(res => {
                getPublishers();
                dispatch(
                    setPillNotificationText(
                        `${isRemoval ? "Unremoval" : "Unassignment"} Succesful`,
                        "success",
                        3500
                    )
                );
            })
            .catch(e =>
                dispatch(
                    setPillNotificationText(
                        `${isRemoval ? "Unremoval" : "Unassignment"} Failed`,
                        "danger",
                        3500
                    )
                )
            );
    };

    const handleUpload = (e: EventInput) => {
        const files = e.currentTarget.files;
        if (files && files.length > 0) {
            const file = files[0];
            Papa.parse(file, {
                complete: (res: any) => {
                    if (res.data.length > 0) {
                        const headers = res.data[0];
                        const pubIdx = headers.indexOf("publisher_id");
                        const body = res.data.slice(1);
                        const pubIds = body
                            .filter((row: any) => !!parseInt(row[pubIdx]))
                            .map((row: any) => parseInt(row[pubIdx]));
                        setStagedPublishers([
                            ...Array.from(
                                new Set([...stagedPublishers, ...pubIds])
                            )
                        ]);
                        setCsvReset(!csvReset);
                    }
                }
            });
        }
    };
    const stagedPublishersTableData: TableRow[] = stagedPublishers.map(
        (pub, idx) => ({
            originalIdx: idx,
            raw: { publisher_id: pub },
            rowData: {
                publisher_id: createCell.number(pub),
                unstage: createCell.button(
                    () =>
                        setStagedPublishers(
                            stagedPublishers.filter(
                                stagedPub => pub !== stagedPub
                            )
                        ),
                    "Unstage"
                )
            }
        })
    );
    const removedPublishersTableData: TableRow[] = removedPublishers.map(
        (pub, idx) => ({
            originalIdx: idx,
            raw: pub,
            rowData: {
                publisher_id: createCell.number(pub.publisherId),
                name: createCell.string(
                    `${pub?.Publisher?.firstname} ${pub?.Publisher?.lastname}`,
                    {
                        isNull:
                            !pub?.Publisher?.firstname ||
                            !pub?.Publisher?.lastname
                    }
                ),
                action: createCell.button(
                    () => handleUnassignment(pub.publisherId),
                    isRemoval ? "Unremove" : "Unassign"
                )
            }
        })
    );

    const handleAssignment = () => {
        const pubLinks = stagedPublishers.map(pub => ({
            publisherId: pub,
            openBuyId: openBuy.id,
            ...(isRemoval && { status: "removed" })
        }));
        post("/v1/openBuy/publisher", {
            openBuyPublisherLinks: pubLinks
        })
            .then(res => {
                getPublishers();
                setStagedPublishers([]);
                dispatch(
                    setPillNotificationText(
                        isRemoval
                            ? "Publishers Successfully Removed"
                            : "Publishers Successfully Assigned",
                        "success",
                        3500
                    )
                );
            })
            .catch(e =>
                dispatch(
                    setPillNotificationText(
                        isRemoval
                            ? "Publishers failed to be removed"
                            : "Publishers failed to be assigned",
                        "danger",
                        3500
                    )
                )
            );
    };
    const [unassignWarning, setUnassignWarning] = useState(false);
    const handleBulkUnassignment = () => {
        const pubIds = removedPublishers.map(pub => pub.publisherId);

        deleteReq(`/v1/openBuy/${openBuy.id}/publisher/bulk`, {
            publisherIds: pubIds
        })
            .then(res => {
                getPublishers();
                setUnassignWarning(false);
                dispatch(
                    setPillNotificationText(
                        isRemoval
                            ? "Publishers Successfully Unremoved"
                            : "Publishers Successfully Unassigned",
                        "success",
                        3500
                    )
                );
            })
            .catch(e =>
                dispatch(
                    setPillNotificationText(
                        isRemoval
                            ? "Publishers failed to be Unremoved"
                            : "Publishers failed to be Unassigned",
                        "danger",
                        3500
                    )
                )
            );
    };
    return (
        <Wrapper>
            <FormHeading text="Publisher Assignment" />
            <ToggleWrap style={{ display: "flex" }}>
                {openBuy.allPublishers ? (
                    "Challenge is open to all publishers. You can only remove publishers."
                ) : (
                    <>
                        Remove Publishers
                        <Toggler
                            isActive={isRemoval}
                            onSelect={() => setIsRemoval(true)}
                            onDeselect={() => setIsRemoval(false)}
                        />
                    </>
                )}
            </ToggleWrap>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // justifyContent: "center",
                    gap: "8px"
                }}
            >
                <BtnWrap
                    style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        alignItems: "center"
                    }}
                >
                    Add Individual Publisher
                    <MarketplaceInput
                        style={{ width: "90px" }}
                        onChange={e => {
                            if (isNumberInputValid(e.currentTarget.value)) {
                                setIndividualPubInput(e.currentTarget.value);
                            }
                        }}
                        value={individualPubInput}
                    />
                    <BlueButton
                        style={{ width: "fit-content", padding: "8px 12px" }}
                        onClick={() => {
                            if (
                                stagedPublishers.includes(
                                    parseInt(individualPubInput)
                                )
                            ) {
                                dispatch(
                                    setPillNotificationText(
                                        `Publisher ID: ${individualPubInput} already staged`,
                                        "danger",
                                        3500
                                    )
                                );
                            } else {
                                setStagedPublishers([
                                    ...stagedPublishers,
                                    parseInt(individualPubInput)
                                ]);
                                setIndividualPubInput("");
                            }
                        }}
                    >
                        Add
                    </BlueButton>
                </BtnWrap>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "8px"
                    }}
                >
                    {!csvReset ? (
                        <InputLabel as="label">
                            Upload CSV
                            <input
                                style={{ display: "none" }}
                                type="file"
                                accept=".csv"
                                onChange={handleUpload}
                            />
                        </InputLabel>
                    ) : (
                        <div
                            style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: colors.primaryBlue,
                                cursor: "pointer"
                            }}
                            onClick={() => setCsvReset(false)}
                        >
                            Add Another CSV
                        </div>
                    )}
                    <Disclaimer>
                        Must have column of <strong>publisher_id</strong>
                    </Disclaimer>
                </div>
            </div>
            <TableWrap>
                <ColumnWrap>
                    <PlugTable
                        title={
                            isRemoval
                                ? "Publishers To Remove"
                                : "Publishers To Assign"
                        }
                        columns={[
                            {
                                coreKey: "publisher_id",
                                displayValue: "Publisher ID",
                                sortType: "number",
                                searchable: true
                            },
                            {
                                coreKey: "unstage",
                                displayValue: "Unstage"
                            }
                        ]}
                        cellData={stagedPublishersTableData}
                        resultsPerPage={6}
                    />

                    <BtnWrap style={{ paddingRight: "0px" }}>
                        <BlueButton
                            disabled={stagedPublishersTableData.length === 0}
                            style={{ flex: 1, padding: "8px 12px" }}
                            onClick={() => handleAssignment()}
                        >
                            {isRemoval
                                ? "Remove Publishers"
                                : "Assign Publishers"}
                        </BlueButton>
                        <StyledRedButton
                            disabled={stagedPublishersTableData.length === 0}
                            onClick={() => setStagedPublishers([])}
                        >
                            Clear Publishers
                        </StyledRedButton>
                    </BtnWrap>
                </ColumnWrap>
                <ColumnWrap>
                    <PlugTable
                        title={
                            isRemoval
                                ? "Removed Publishers"
                                : "Assigned Publishers"
                        }
                        columns={[
                            {
                                coreKey: "publisher_id",
                                displayValue: "Publisher ID",
                                sortType: "number",
                                searchable: true
                            },
                            {
                                coreKey: "name",
                                displayValue: "Publisher Name",
                                sortType: "string",
                                searchable: true
                            },
                            {
                                coreKey: "action",
                                displayValue: isRemoval
                                    ? "Unremove"
                                    : "Unassign"
                            }
                        ]}
                        cellData={removedPublishersTableData}
                        resultsPerPage={6}
                        tableLoading={tableLoading}
                        refreshHandler={() => getPublishers()}
                    />
                    <StyledRedButton
                        disabled={removedPublishersTableData.length === 0}
                        style={{ marginTop: "8px" }}
                        onClick={() => setUnassignWarning(true)}
                    >
                        {isRemoval
                            ? "Unremove All Publishers"
                            : "Unassign All Publishers"}
                    </StyledRedButton>
                </ColumnWrap>
            </TableWrap>
            {unassignWarning && (
                <GlobalWarning
                    title={"Are you sure?"}
                    message={`Are you sure you want to ${
                        isRemoval ? "unremove" : "unassign"
                    } all publishers`}
                    handleCancel={() => setUnassignWarning(false)}
                    handleApply={handleBulkUnassignment}
                    btnText={{
                        cancel: "Cancel",
                        apply: isRemoval ? "Unremove All" : "Unassign All"
                    }}
                />
            )}
        </Wrapper>
    );
};

export default PubAssignment;
