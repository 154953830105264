import React, { useState } from "react";
import { connect } from "react-redux";
import { Channel } from "../../../types";
import { clearModal } from "../../../../modals/actions";
import Modal from "../../../../modals/Modal";
import ModalButton from "../../../../modals/ModalButton";
import styled from "styled-components";
import ResizingTextArea from "../../../../components/ResizingTextArea";
import VerticallyCentered from "../../../../components/VerticallyCentered";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
    font-size: ${fonts.subtitle}px;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Description = styled.div`
    font-weight: 500;
    text-align: left;
    padding: 14px 0 2px;
`;

const CheckRow = styled(VerticallyCentered)`
    & + & {
        padding-top: 6px;
    }
`;

const CheckSection = styled(VerticallyCentered)`
    justify-content: space-between;
    width: calc(50% - 5px);
    cursor: pointer;

    & + & {
        margin-left: auto;
    }
`;

enum ReportCategory {
    suspicious = "suspicious",
    harassing = "harassing",
    inappropriate = "inappropriate",
    spam = "spam"
}

interface Props {
    currentUser: any;
    channel: Channel;
    clearModal: VoidFunction;
}

const ReportOptionsModal = ({ currentUser, channel, clearModal }: Props) => {
    const [category, setCategory] = useState<ReportCategory>();
    const [description, setDescription] = useState("");

    return (
        <Modal>
            <Main>
                <Title>Report @{currentUser.nickname}</Title>
                <Description>Type:</Description>
                <CheckRow>
                    <CheckSection
                        onClick={() => {
                            setCategory(ReportCategory.harassing);
                        }}
                    >
                        <div>harassing</div>
                        {category === ReportCategory.harassing ? (
                            <img src={"/checkbox_selected.svg"} />
                        ) : (
                            <img src={"/checkbox_unselected.svg"} />
                        )}
                    </CheckSection>
                    <CheckSection
                        onClick={() => {
                            setCategory(ReportCategory.inappropriate);
                        }}
                    >
                        <div>inappropriate</div>
                        {category === ReportCategory.inappropriate ? (
                            <img src={"/checkbox_selected.svg"} />
                        ) : (
                            <img src={"/checkbox_unselected.svg"} />
                        )}
                    </CheckSection>
                </CheckRow>
                <CheckRow>
                    <CheckSection
                        onClick={() => {
                            setCategory(ReportCategory.spam);
                        }}
                    >
                        <div>spam</div>
                        {category === ReportCategory.spam ? (
                            <img src={"/checkbox_selected.svg"} />
                        ) : (
                            <img src={"/checkbox_unselected.svg"} />
                        )}
                    </CheckSection>
                    <CheckSection
                        onClick={() => {
                            setCategory(ReportCategory.suspicious);
                        }}
                    >
                        <div>suspicious</div>
                        {category === ReportCategory.suspicious ? (
                            <img src={"/checkbox_selected.svg"} />
                        ) : (
                            <img src={"/checkbox_unselected.svg"} />
                        )}
                    </CheckSection>
                </CheckRow>
                <Description>Description:</Description>
                <ResizingTextArea
                    placeholder="Please describe the problem"
                    value={description}
                    onChange={e => {
                        setDescription(e.currentTarget.value);
                    }}
                />
            </Main>
            <ModalButton
                text="Submit"
                disabled={!category || !description}
                onClick={() => {
                    channel.sendBirdData.reportUser(
                        currentUser,
                        category,
                        description,
                        (error: any) => {
                            if (error) {
                                window.alert(error);
                            }
                            clearModal();
                        }
                    );
                }}
            />
            <ModalButton text="Cancel" />
        </Modal>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(null, mapDispatchToProps)(ReportOptionsModal);
