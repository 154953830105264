import React from "react";
import { colors, fonts, overlayBackgrounds, sizes } from "src/constants";
import {
    clearButton,
    clearSpacing,
    flexRowCenter,
    hideOverflow
} from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import * as Icons from "src/components/icons/exports";
import { useDispatch } from "react-redux";
import { fetchLinkForPlug } from "src/links/actions";
import { CampaignMedia } from "src/campaigns/types";

const TextContainer = styled.div`
    position: relative;
    flex-shrink: 0;
    width: ${sizes.marketplace.instructions.dropdownItem.width}px;
    height: ${sizes.marketplace.instructions.dropdownItem.height}px;
    padding: 12px;
    background-color: ${colors.white};
    font-size: ${fonts.smallTitle}px;

    :after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 50%;
        background: ${`linear-gradient(
            to top,
            ${colors.white},
            ${colors.transparent}
        )`};
        text-align: right;
    }
`;

const ScriptId = styled.span`
    display: block;
    margin-bottom: 8px;
    padding: 4px 6px;
    color: ${colors.black};
    background-color: ${colors.lightGray2};
    border-radius: 4px;
    font-size: ${fonts.smallTitle}px;
    font-weight: 500;
`;

const ScriptTitle = styled.h5`
    ${clearSpacing()};
    margin-bottom: 8px;
    font-size: ${fonts.smallTitle}px;
    font-weight: 700;
`;

const ScriptText = styled.p`
    ${clearSpacing()};
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const MediaOrAssetContainer = styled.article`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 8px; */
`;

const Overlay = styled.div`
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: ${overlayBackgrounds.light};
`;

const MediaOrAssetTextContainer = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 3;
    position: flex;
    flex-direction: column;
    padding: 8px;
    word-wrap: break-word;
`;

const MediaOrAssetTextWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const MediaOrAssetText = styled.span<{ hideOverflow?: true }>`
    display: block;
    color: ${colors.white};
    font-size: ${fonts.subtitle}px;
    font-weight: 700;
    ${props => props.hideOverflow && hideOverflow()};
`;

const CoverPhoto = styled.img<{ url: string | null }>`
    width: ${sizes.mediaItemWidth}px;
    height: ${sizes.mediaPreviewHeight}px;
    background-color: ${colors.transparent};
    background-image: ${props => `url(${props.url})` || ""};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Buttons = styled.div`
    display: flex;
`;

const ButtonsDivider = styled.div`
    width: 1px;
    height: 100%;
    background-color: ${colors.mediumTeal};
`;

const buttonStyles = css`
    ${flexRowCenter()};
    flex: 1;
    width: 100%;
    height: 43px;
    color: ${colors.black};
    background-color: ${colors.white};
    text-decoration: none;
    transition: 0.1s ease-in;
    cursor: pointer;

    :hover {
        color: ${colors.black};
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.mediumTeal};
        }
    }
`;

const CopyLinkButton = styled.button`
    ${clearButton()};
    ${buttonStyles};
`;

const DownloadButton = styled.a`
    ${buttonStyles};
`;

interface Props {
    onClick?: () => void;
    id?: number;
    type?: "Script" | "Media" | "Asset";
    scriptTitle?: string;
    scriptText?: string;
    campaignName?: string;
    coverPhotoUrl?: string;
    downloadLink?: string;
    media?: CampaignMedia;
}

const CreateDropdownItem = ({
    onClick,
    id,
    type,
    scriptTitle,
    scriptText,
    campaignName,
    coverPhotoUrl,
    downloadLink,
    media
}: Props) => {
    function generatePrependedIdString(): string {
        if (type === "Script") return `ID: ${id}`;
        else if (type === "Media") return `Media ID: ${id}`;
        else return `Asset ID: ${id}`;
    }

    const dispatch = useDispatch();
    function handleCopyLinkClick(
        event: React.MouseEvent<HTMLButtonElement>
    ): void {
        event.preventDefault();
        if (!!media) dispatch(fetchLinkForPlug(media));
    }

    return (
        <>
            {!!scriptText && (
                <TextContainer onClick={onClick}>
                    {!!id && <ScriptId>{generatePrependedIdString()}</ScriptId>}
                    {!!scriptTitle && <ScriptTitle>{scriptTitle}</ScriptTitle>}
                    {!!scriptText && <ScriptText>{scriptText}</ScriptText>}
                </TextContainer>
            )}

            {!!coverPhotoUrl && (
                <MediaOrAssetContainer onClick={onClick}>
                    <Overlay />
                    <MediaOrAssetTextContainer>
                        <MediaOrAssetTextWrapper>
                            {!!id && (
                                <MediaOrAssetText>
                                    {generatePrependedIdString()}
                                </MediaOrAssetText>
                            )}
                            {!!campaignName && (
                                <MediaOrAssetText hideOverflow>
                                    {campaignName}
                                </MediaOrAssetText>
                            )}
                        </MediaOrAssetTextWrapper>
                    </MediaOrAssetTextContainer>
                    <CoverPhoto url={coverPhotoUrl} />
                </MediaOrAssetContainer>
            )}

            <Buttons>
                {type === "Media" && !!downloadLink && (
                    <>
                        <CopyLinkButton onClick={handleCopyLinkClick}>
                            <Icons.Link height={20} fill={colors.primaryGray} />
                        </CopyLinkButton>
                        <ButtonsDivider />
                    </>
                )}

                {!!downloadLink && (
                    <DownloadButton href={downloadLink} target="_blank">
                        <Icons.Download height={20} fill={colors.primaryGray} />
                    </DownloadButton>
                )}
            </Buttons>
        </>
    );
};

export default CreateDropdownItem;
