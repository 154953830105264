import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalCampaignTitle from "src/campaigns/components/ModalCampaignTitle";
import { breakpoints, colors, maxWidthDesktop } from "src/constants";
import { fetchLinkForPlug } from "src/links/actions";
import PlacementsElement from "src/marketplace/instructions-sets/components/PlacementsElement";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import CarouselMedia from "./CarouselMedia";
import LargeModal from "./LargeModal";
import MediaModalHeader from "./components/MediaModalHeader";
import { motion } from "framer-motion";
import { hideScrollbar } from "src/utils/styles/snippets";
import { CampaignMedia } from "src/campaigns/types";
import { selectCampaignCountryRewardById } from "src/campaigns/selectors";
import MediaModalLoading from "./components/MediaModalLoading";
import MediaPlacements from "./components/MediaPlacements";
import MediaStatus from "./components/MediaStatus";

const Wrapper = styled(motion.div)`
    width: 90vw;
    max-width: ${maxWidthDesktop}px;
    height: 90vh;
    max-height: 742px;
    position: relative;
    padding: 24px;
    overflow: visible;
    background-color: white;
    border-radius: 6px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "header header"
        "mediaView description"
        "mediaView buttons";
    ${props => props.theme.isMobile && hideScrollbar()}
`;

const MediaWrap = styled.div`
    grid-area: mediaView;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
`;
const StyledCarouselMedia = styled(CarouselMedia)`
    height: 100%;
    margin: 0px auto;
    & > video {
        height: 100%;
        width: auto;
    }
`;
const Description = styled.div`
    grid-area: description;
    overflow: hidden;
`;
const StyledMediaStatus = styled(MediaStatus)`
    margin-bottom: 10px;
    :nth-child(2) {
        margin-top: 18px;
    }
`;
const StyledMediaPlacements = styled(MediaPlacements)`
    margin-bottom: 10px;
    :nth-child(2) {
        margin-top: 18px;
    }
`;
const ButtonsContainer = styled.div`
    grid-area: buttons;
    display: flex;
`;
const Btn = styled.button`
    flex: 1;
    font-size: 13px;
    padding: 6px 0px;
    border-radius: 6px;
    color: ${colors.black};
    text-decoration: none;
    background-color: ${colors.quaternaryLightGray};
    transition: background-color 0.2s ease;
    &:last-child {
        margin-left: 12px;
    }
    &:hover {
        background-color: ${colors.secondaryLightGray};
        color: black;
        text-decoration: none;
    }
`;
const Icon = styled.img`
    width: 16px;
    height: 16px;
    transform: translateY(-1px);
`;

interface Props {
    media: CampaignMedia;
}

const AdminMediaModal = ({ media }: Props) => {
    const dispatch = useDispatch();

    const campaign = useSelector((state: GlobalState) =>
        selectCampaignCountryRewardById(state, media.campaignId)
    );

    return (
        <Wrapper>
            {media && campaign ? (
                <>
                    <MediaModalHeader media={media} />
                    <MediaWrap>
                        <StyledCarouselMedia
                            mediaUrl={media.url}
                            mediaType={media.type}
                            isPortrait={media.isPortrait}
                            coverPhotoUrl={media.coverPhotoUrl}
                        />
                    </MediaWrap>
                    <Description>
                        <ModalCampaignTitle
                            campaign={{
                                imageUrl: media.campaignImageUrl,
                                campaignName: media.campaignName,
                                countryPlatformReward:
                                    campaign.countryPlatformReward,
                                rewardType: campaign.rewardType
                            }}
                        />
                        {!media.shouldShowInApp && (
                            <StyledMediaStatus
                                message={
                                    "This media is currently unavailable in app."
                                }
                            />
                        )}
                        {!media.allowOthersToUse && (
                            <StyledMediaStatus
                                icon={"/user-red-icon.svg"}
                                message="User does not want others to use this media!"
                            />
                        )}
                        {media.uploadedByEmployeeId && (
                            <StyledMediaStatus
                                message={`This media was uploaded by an employee.`}
                            />
                        )}
                        {media.placementIds && (
                            <StyledMediaPlacements
                                placementIds={media.placementIds}
                            />
                        )}
                    </Description>
                    <ButtonsContainer>
                        <Btn
                            onClick={() => dispatch(fetchLinkForPlug(media))}
                            onMouseDown={e => e.stopPropagation()}
                        >
                            <Icon src="/modal-link-icon.svg" alt="" />
                            Copy Link
                        </Btn>
                        <Btn
                            as="a"
                            onMouseDown={(e: any) => e.stopPropagation()}
                            target="_blank"
                            download={`media-id-${media.id}`}
                            href={media.url}
                        >
                            <Icon src="/modal-download-icon.svg" alt="" />
                            Download Media
                        </Btn>
                    </ButtonsContainer>
                </>
            ) : (
                <MediaModalLoading />
            )}
        </Wrapper>
    );
};

export default AdminMediaModal;
