import { Action } from "src/types";

// Clear Profile Modals -----------------------------------------------

export const CLEAR_PROFILE_MODALS = "CLEAR_PROFILE_MODALS";

export function clearProfileModals(): Action {
    return {
        type: CLEAR_PROFILE_MODALS,
        payload: null
    };
}

// Rate Form Modal Open -----------------------------------------------

export const RATE_FORM_MODAL_OPEN = "RATE_FORM_MODAL_OPEN";

export function rateFormModalOpen(open: boolean): Action {
    return {
        type: RATE_FORM_MODAL_OPEN,
        payload: open
    };
}

// Set Is Rate Form Edit -----------------------------------------------

export const SET_IS_RATE_FORM_EDIT = "SET_IS_RATE_FORM_EDIT";

export function setIsRateFormEdit(edit: boolean): Action {
    return {
        type: SET_IS_RATE_FORM_EDIT,
        payload: edit
    };
}

// Set Account Id (for rate form) -----------------------------------------------

export const SET_ACCOUNT_ID = "SET_ACCOUNT_ID";

export function setAccountId(id: number | null): Action {
    return {
        type: SET_ACCOUNT_ID,
        payload: id
    };
}

// Set Publisher Offer Id (for rate form)  -----------------------------------------------

export const SET_PUBLISHER_OFFER_ID = "SET_PUBLISHER_OFFER_ID";

export function setPublisherOfferId(id: number | null): Action {
    return {
        type: SET_PUBLISHER_OFFER_ID,
        payload: id
    };
}

// Add Account Modal Open -----------------------------------------------

export const ADD_ACCOUNT_MODAL_OPEN = "ADD_ACCOUNT_MODAL_OPEN";

export function addAccountModalOpen(open: boolean): Action {
    return {
        type: ADD_ACCOUNT_MODAL_OPEN,
        payload: open
    };
}

// Edit Account Modal Open -----------------------------------------------

export const EDIT_ACCOUNT_MODAL_OPEN = "EDIT_ACCOUNT_MODAL_OPEN";

export function editAccountModalOpen(open: boolean): Action {
    return {
        type: EDIT_ACCOUNT_MODAL_OPEN,
        payload: open
    };
}

// Is Account Edit -----------------------------------------------

export const SET_IS_ACCOUNT_EDIT = "SET_IS_ACCOUNT_EDIT";

export function setIsAccountEdit(edit: boolean): Action {
    return {
        type: SET_IS_ACCOUNT_EDIT,
        payload: edit
    };
}

// View Account Modal Open -----------------------------------------------

export const VIEW_ACCOUNT_MODAL_OPEN = "VIEW_ACCOUNT_MODAL_OPEN";

export function viewAccountModalOpen(open: boolean): Action {
    return {
        type: VIEW_ACCOUNT_MODAL_OPEN,
        payload: open
    };
}

// Add Custom Media Modal Open -----------------------------------------------

export const ADD_CUSTOM_MEDIA_MODAL_OPEN = "ADD_CUSTOM_MEDIA_MODAL_OPEN";

export function addCustomMediaModalOpen(open: boolean): Action {
    return {
        type: ADD_CUSTOM_MEDIA_MODAL_OPEN,
        payload: open
    };
}
// Toggle Admin -------------------------------------------------------

export const TOGGLE_ADMIN = "TOGGLE_ADMIN";

export function toggleAdmin(status: boolean): Action {
    return { type: TOGGLE_ADMIN, payload: status };
}
