import React, { useState } from "react";
import { SocialAccount } from "../../../types";
import Header from "../../../../components/Header";
import PaddingNoTop from "../../../../components/PaddingNoTop";
import AccountCell from "../../components/AccountCell";
import SearchBar from "../../../../components/SearchBar";

interface Props {
    accounts: SocialAccount[];
    push: (route: string) => void;
}

const PublicAccountsPage = ({ accounts, push }: Props) => {
    const [searchString, setSearchString] = useState("");

    const verified = accounts.reduce((acc, account) => {
        if (account.verified) {
            return acc + 1;
        } else {
            return acc;
        }
    }, 0);

    const unverified = accounts.reduce((acc, account) => {
        if (account.verified) {
            return acc;
        } else {
            return acc + 1;
        }
    }, 0);

    return (
        <>
            <Header
                title={"Accounts"}
                subtitle={`${verified} Verified; ${unverified} Unverified`}
            />
            <PaddingNoTop>
                <SearchBar
                    searchString={searchString}
                    updateSearchString={setSearchString}
                />
            </PaddingNoTop>
            <PaddingNoTop>
                {accounts
                    .filter(account => {
                        if (searchString.length === 0) {
                            return true;
                        } else {
                            return account.username
                                .toLowerCase()
                                .includes(searchString.toLowerCase());
                        }
                    })
                    .map(account => {
                        return (
                            <AccountCell
                                key={account.id}
                                account={account}
                                push={push}
                            />
                        );
                    })}
            </PaddingNoTop>
        </>
    );
};

export default PublicAccountsPage;
