import React, { useState } from "react";
import { Campaign } from "src/campaigns/types";
import Switch from "react-switch";
import SwitchContainer from "../components/SwitchContainer";
import SwitchDescription from "../components/SwitchDescription";
import Button from "react-bootstrap/Button";
import Divider from "../../components/Divider";
import UploadMediaCard from "./UploadMediaCard";
import UploadMediaDelete from "./UploadMediaDelete";
import styled from "styled-components";
import { colors, fonts } from "../../constants";

const Title = styled.div`
    padding: 15px 15px 0px;
    display: flex;
    align-items: center;
    color: darkgray;
    font-size: ${fonts.subtitle}px;
    line-height: 1em;
    font-weight: bold;
`;

const PlugLogo = styled.img`
    height: 20px;
    width: 20px;
    margin-right: 7px;
`;

const RulesLink = styled.span`
    text-decoration: underline;
    color: ${colors.primaryBlue};
    cursor: pointer;
`;

interface Props {
    campaign: Campaign;
    filename: string | null;
    setFilename: (filename: string | null) => void;
    coverPhoto: string | null;
    setCoverPhoto: (coverPhoto: string | null) => void;
    type: string | null;
    setType: (type: string | null) => void;
    mediaUrl: string | null;
    setMediaUrl: (mediaUrl: string | null) => void;
    allowUse: boolean;
    setAllowUse: (allowUse: boolean) => void;
    rulesModal: VoidFunction;
    nextCallback: VoidFunction;
}

const UploadMediaMain = ({
    campaign,
    filename,
    setFilename,
    coverPhoto,
    setCoverPhoto,
    type,
    setType,
    mediaUrl,
    setMediaUrl,
    allowUse,
    setAllowUse,
    rulesModal,
    nextCallback
}: Props) => {
    const [agreedRules, setAgreedRules] = useState(false);
    const [deleteScreen, setDeleteScreen] = useState(false);

    function onDeleteClicked() {
        setFilename(null);
        setType(null);
        setMediaUrl(null);
        setDeleteScreen(false);
    }

    return (
        <>
            <UploadMediaCard
                campaign={campaign}
                setFilename={setFilename}
                setCoverPhoto={setCoverPhoto}
                type={type}
                setType={setType}
                mediaUrl={mediaUrl}
                setMediaUrl={setMediaUrl}
                setDeleteScreen={setDeleteScreen}
            />
            <Divider />
            <Title>
                <PlugLogo src={"/logo.svg"} />
                <div>EARN A BONUS</div>
            </Title>
            <SwitchContainer>
                <SwitchDescription>
                    Allow anyone to use your ad and you'll earn 5% of total
                    earnings for the first month.
                </SwitchDescription>
                <Switch
                    onColor={"#28c056"}
                    offColor={"#cccccc"}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    handleDiameter={24}
                    checked={allowUse}
                    onChange={(checked: boolean) => setAllowUse(checked)}
                />
            </SwitchContainer>
            <Divider />
            <SwitchContainer>
                <SwitchDescription>
                    {`This ad follows ${campaign.campaignName}'s `}
                    <RulesLink onClick={rulesModal}>Media Rules.</RulesLink>
                </SwitchDescription>
                <Switch
                    onColor={"#28c056"}
                    offColor={"#cccccc"}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    handleDiameter={24}
                    checked={agreedRules}
                    onChange={(checked: boolean) => setAgreedRules(checked)}
                />
            </SwitchContainer>
            <Divider />
            <div style={{ padding: "15px" }}>
                <Button
                    block
                    variant="success"
                    disabled={
                        !(
                            filename &&
                            agreedRules &&
                            (type !== "video" || coverPhoto)
                        )
                    }
                    onClick={nextCallback}
                >
                    Next
                </Button>
            </div>
            {deleteScreen && mediaUrl && type && (
                <UploadMediaDelete
                    mediaUrl={mediaUrl}
                    type={type}
                    setDeleteScreen={setDeleteScreen}
                    onDeleteClicked={onDeleteClicked}
                />
            )}
        </>
    );
};

export default UploadMediaMain;
