import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import HalfScreenModal from "src/modals/HalfScreenModal";
import styled from "styled-components";
import { colors } from "src/constants";
import Modal from "src/modals/Modal";
import { OutlineButton } from "src/profile/components/Buttons";
import ActionsModal from "src/modals/ActionsModal";

interface Props {
    onClose: () => void;
    onDelete: () => void;
    open: boolean;
    type: string;
}

const Option = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 16px;
    height: 54px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin-top: 8px;
    background: white;
`;

const container = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "270px",
    height: "164px",
    borderRadius: "14px",
    overflow: "hidden",
    boxSizing: "border-box"
};

const Title = styled.div`
    height: 44px;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    box-sizing: content-box;
    padding: 18px 16px 5px 16px;
`;

const Subtitle = styled.div`
    height: 36px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 0 16px;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    margin-top: auto;
`;

const Button = styled(OutlineButton)<{ color?: string }>`
    color: ${props => props.color || colors.primaryBlue};
    width: 50%;
    height: 48px;
    border-radius: 0;
`;

const LinkListDeleteModal = ({ onClose, onDelete, open, type }: Props) => {
    const [uSure, setUSure] = useState(false);

    return (
        <>
            <AnimatePresence>
                {open && (
                    <ActionsModal
                        actions={[
                            {
                                text: "Delete",
                                negative: true,
                                callback: () => setUSure(true)
                            }
                        ]}
                        localClearModal={onClose}
                    />
                )}
            </AnimatePresence>{" "}
            {uSure && (
                <Modal
                    onClick={() => {
                        setUSure(false);
                        onClose();
                    }}
                    style={container}
                >
                    <Title>Are you sure you want to delete this {type}?</Title>
                    <Subtitle>
                        You can't undo this and your link(s) will no longer
                        work.
                    </Subtitle>
                    <Row>
                        <Button
                            onClick={() => {
                                onClose();
                                setUSure(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setUSure(false);
                                onDelete();
                            }}
                            color={colors.primaryRed}
                        >
                            Delete
                        </Button>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default LinkListDeleteModal;
