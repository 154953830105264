import React, { useState } from "react";
import { colors } from "src/constants";
import styled, { css } from "styled-components";

const Wrapper = styled.td<{ showOverflow: boolean }>`
    /*
     Height below will be overwritten by height defined in style property of cell creation
     or rowHeight property passed to PlugTable component
      */
    height: 49px;
    text-align: left;
    white-space: nowrap;
    position: relative;
    isolation: isolate;
    ${props =>
        props.showOverflow &&
        css`
            z-index: 1;
        `}
`;
const OverflowWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
const Wrap = styled.div<{ showOverflow?: boolean }>`
    padding: 0px 12px;
    display: grid;
    align-items: center;
    height: 100%;
    overflow-x: ${props => (props.showOverflow ? "visible" : "hidden")};
    ${props =>
        props.showOverflow &&
        css`
            border: 1px solid ${colors.darkTeal};
            background-color: ${colors.mediumTeal};
        `}
`;
const OverflowIndicator = styled.span`
    height: 100%;
    padding: 0px 8px;
    color: ${colors.primaryBlue};
    display: grid;
    align-items: center;
    cursor: default;
`;

interface Props {
    children: JSX.Element | number | string | null;
    cellStyle?: React.CSSProperties;
    className?: string;
}

const TableData = ({ children, cellStyle, className }: Props) => {
    const [isOverflown, setIsOverflown] = useState(false);
    const [showOverflow, setShowOverflow] = useState(false);

    return (
        <Wrapper
            className={className}
            style={cellStyle}
            showOverflow={showOverflow}
        >
            <OverflowWrap
                onMouseLeave={() => {
                    if (showOverflow) setShowOverflow(false);
                }}
            >
                <Wrap
                    showOverflow={showOverflow}
                    ref={ref => {
                        if (ref && ref.scrollWidth > ref.clientWidth) {
                            setIsOverflown(true);
                        }
                    }}
                >
                    {children}
                </Wrap>
                {isOverflown && !showOverflow && (
                    <OverflowIndicator
                        onMouseEnter={() => setShowOverflow(true)}
                    >
                        ...
                    </OverflowIndicator>
                )}
            </OverflowWrap>
        </Wrapper>
    );
};

export default TableData;
