import React from "react";
import { Contest } from "../types";
import styled from "styled-components";
import Countdown from "../components/Countdown";
import moment from "moment";
import RankCell from "./RankCell";
import RaffleTicketsEarned from "../components/RaffleTicketsEarned";
import { addTickets } from "../utils/contestObjectFilters";
import { colors, maxWidthDesktop, fonts } from "src/constants";

const Background = styled.div`
    background-color: #f8fbfc;
    padding: 15px;
    font-size: ${fonts.smallTitle}px;
    text-align: left;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${maxWidthDesktop}px) {
        border: none;
        border-radius: 0px;
    }
`;

const CardTitle = styled.div`
    color: darkgray;
    margin-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
`;

const Main = styled.div`
    padding: 15px;
    background-color: white;
    border: 1px solid #e0eef2;
    border-radius: 8px;
`;

const Title = styled.div`
    margin-bottom: 10px;
    font-size: ${fonts.subtitle}px;
    font-weight: bold;
    text-transform: capitalize;
`;

const RankContainer = styled.div`
    border-top: 1px solid #e0eef2;
    border-bottom: 1px solid #e0eef2;
    margin: 15px 0 15px;
    padding: 15px 0 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
`;

const CheckCircle = styled.img`
    height: 16px;
    width: 16px;
`;

const VideoTitle = styled.div`
    font-size: 14px;
    font-weight: 700;
`;
const VideoLink = styled.a`
    font-size: 14px;
    font-weight: 500;
    margin-left: -18px;
    color: ${colors.primaryBlue};
    text-decoration: underline;
`;

const Conversion = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: ${colors.gray2};
`;

interface Props {
    contest: Contest;
    push: (path: string) => void;
}

const YourRank = ({ contest, push }: Props) => {
    return (
        <Background>
            <CardTitle>Your Rank</CardTitle>
            <Main>
                <Title>Time Remaining</Title>
                <Countdown endDate={moment(contest.end_time).valueOf()} />
                <RankContainer>
                    {contest.leaderboard.map((leaderboard, idx) => (
                        <div key={idx}>
                            <Title>{leaderboard.name} rank</Title>
                            <RankCell
                                key={leaderboard.id}
                                self={leaderboard.my_ranking}
                                leaderboardItem={leaderboard.my_ranking}
                            />
                        </div>
                    ))}
                </RankContainer>
                <Title>Raffle tickets earned</Title>
                <RaffleTicketsEarned tickets={addTickets(contest)} />
                <Conversion>{contest.raffle[0].conversion}</Conversion>
                <Row
                    style={{
                        display: contest.raffle[0].drawing_video_url
                            ? "flex"
                            : "none"
                    }}
                >
                    <CheckCircle src="/green_circle_check.svg" />
                    <VideoTitle>The winners have been drawn</VideoTitle>
                    <li style={{ fontSize: "18px" }} />
                    <VideoLink href={contest.raffle[0].drawing_video_url}>
                        Watch Video
                    </VideoLink>
                </Row>
            </Main>
        </Background>
    );
};

export default YourRank;
