import React from "react";
import { useDispatch } from "react-redux";
import { BigOutlineButton } from "src/profile/components/Buttons";
import { setScriptCreate } from "src/ui/scripts/actions";
import styled from "styled-components";

const Wrapper = styled(BigOutlineButton)`
    padding: 20px;
    height: auto;
`;

const ScriptCreationRow = ({ className }: { className?: string }) => {
    const dispatch = useDispatch();

    return (
        <Wrapper
            className={className}
            onClick={() => dispatch(setScriptCreate(true))}
        >
            Create Script
        </Wrapper>
    );
};

export default ScriptCreationRow;
