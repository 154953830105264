import { Action } from "../types";

// Modals
// -------------------------------------------------------------------

export const MODAL = {
    EDIT_CHANNEL: "MODAL_EDIT_CHANNEL",
    CREATE_GROUP: "MODAL_CREATE_GROUP",
    ADMIN_ACTIONS: "MODAL_ADMIN_ACTIONS",
    BAN_OPTIONS: "MODAL_BAN_OPTIONS",
    MUTE_OPTIONS: "MODAL_MUTE_OPTIONS",
    REPORT_OPTIONS: "MODAL_REPORT_OPTIONS",
    DIRECT_ACTIONS: "MODAL_DIRECT_ACTIONS"
};

export const editChannelModal = (): Action => ({
    type: MODAL.EDIT_CHANNEL,
    payload: null
});

export const createGroupModal = (): Action => ({
    type: MODAL.CREATE_GROUP,
    payload: null
});

export const adminActionsModal = (): Action => ({
    type: MODAL.ADMIN_ACTIONS,
    payload: null
});

export const banOptionsModal = (): Action => ({
    type: MODAL.BAN_OPTIONS,
    payload: null
});

export const muteOptionsModal = (): Action => ({
    type: MODAL.MUTE_OPTIONS,
    payload: null
});

export const reportOptionsModal = (): Action => ({
    type: MODAL.REPORT_OPTIONS,
    payload: null
});

export const directActionsModal = (): Action => ({
    type: MODAL.DIRECT_ACTIONS,
    payload: null
});
