import React, { useState } from "react";
import { animated } from "react-spring";
import { useScratch } from "react-use";
import SpinCaret from "src/components/icons/SpinCaret";
import { colors } from "src/constants";
import InternalTools from "src/profile/settings/cells/InternalTools";
import styled from "styled-components";

const SideBar = styled.div<{ collapsed?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 300px;
    position: absolute;
    z-index: 3;
    background-color: ${colors.blueGrey};
    display: ${props => props.collapsed && "none"};
    top: 0;
`;

const CollapseButton = styled.button<{ collapsed?: boolean }>`
    position: absolute;
    top: 75px;
    left: ${props => (props.collapsed ? "-26px" : "270px")};
    z-index: 2;
    transform: rotate(270deg);
    background-color: ${props =>
        props.collapsed ? colors.primaryGreen : colors.tertiaryGreen};
    color: white;
    padding: 2px 10px;
    :hover {
        opacity: 0.8;
    }
    gap: 10px;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

interface Props {
    title?: string;
    selectedToolIndex: number;
}

const AdminSideMenu = ({ title, selectedToolIndex }: Props) => {
    const [sidebarCollapsed, setSideBarCollapsed] = useState(true);
    return (
        <>
            <SideBar collapsed={sidebarCollapsed}>
                <InternalTools selected={selectedToolIndex} />
            </SideBar>
            <CollapseButton
                collapsed={sidebarCollapsed}
                onClick={() => setSideBarCollapsed(!sidebarCollapsed)}
            >
                <SpinCaret open={!sidebarCollapsed} />
                Tools
            </CollapseButton>
        </>
    );
};

export default AdminSideMenu;
