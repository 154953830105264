import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ResizingTextArea from "src/components/ResizingTextArea";
import { colors, fonts, fontWeight, shadows } from "src/constants";
import { setInstructionsSetsError } from "src/ui/instructions-sets/actions";
import { removeWhitespaceFromString } from "src/utils/functions/helpers";
import { preventUserInteraction } from "src/utils/styles/snippets";
import { EventInput, EventTextarea } from "src/utils/types/form";
import styled, { css } from "styled-components";

interface MainProps {
    isError: boolean;
    noInteraction?: true;
}

const Main = styled.div<MainProps>`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: ${props => (props.isError ? "4px" : "0px")};
    width: 100%;

    ${props => props.noInteraction && preventUserInteraction()};
`;

const ErrorMessage = styled.span`
    display: block;
    color: ${colors.primaryRed};
    font-size: ${fonts.smallTitle}px;
`;

interface UserInputFieldProps {
    fontSize?: number;
    fontWeight?: number;
    padding?: string;
    focus: boolean;
    border?: true;
    isError: boolean;
}

const userInputFieldStyles = css<UserInputFieldProps>`
    width: 100%;
    padding: ${props => props.padding || "4px 8px"};
    color: ${colors.black};
    border: ${props => {
        return props.border ? `${colors.mediumTeal} solid 1px` : "none";
    }};
    border-radius: 6px;
    font-size: ${props => props.fontSize || fonts.smallTitle}px;
    font-weight: ${props => props.fontWeight || fontWeight.default};
    box-shadow: ${props => {
        if (props.isError) return shadows.inputError;
        else if (props.focus) return shadows.inputSelected;
        else return shadows.transparent;
    }};
    outline: none;
    transition: 0.1s ease-in;

    ::placeholder {
        color: ${colors.primaryGray};
    }
`;

const Input = styled.input<UserInputFieldProps>`
    ${userInputFieldStyles};
`;

interface TextAreaErrorWrapperProps {
    focus: boolean;
    isError: boolean;
}

const TextAreaErrorWrapper = styled.div<TextAreaErrorWrapperProps>`
    border-radius: 6px;
    box-shadow: ${props => {
        if (props.isError) return shadows.inputError;
        else if (props.focus) return shadows.inputSelected;
        else return shadows.transparent;
    }};
`;

interface Props {
    setValue: Function;
    value: string;
    placeholder: string;
    error?: string;
    customError?: boolean;
    customErrorMessage?: string;
    fontSize?: number;
    fontWeight?: number;
    padding?: string;
    border?: true;
    textarea?: true;
    noInteraction?: true;
}

const InstructionsFormInput = ({
    setValue,
    value,
    placeholder,
    error,
    customError,
    customErrorMessage,
    fontSize,
    fontWeight,
    padding,
    border,
    textarea,
    noInteraction
}: Props) => {
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [focus, setFocus] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        if (customError && customErrorMessage) {
            setErrorMessage(customErrorMessage);
            setIsError(true);
            dispatch(setInstructionsSetsError(true));
        } else if (isError) {
            setErrorMessage(error || "You must fill out this field.");
            dispatch(setInstructionsSetsError(true));
        } else {
            setErrorMessage("");
            dispatch(setInstructionsSetsError(false));
        }
    }, [isError, customError]);

    function handleUserInputChange(event: EventInput | EventTextarea): void {
        const userInput = event.currentTarget.value;
        setValue(userInput);
        const emptyInput = removeWhitespaceFromString(userInput).length === 0;
        setIsError(emptyInput);
    }

    const resizingTextAreaStyles = {
        width: "100%",
        padding: "16px",
        color: colors.black,
        border: isError
            ? `${colors.transparent} solid 1px`
            : `${colors.mediumTeal} solid 1px`,
        borderRadius: "6px",
        fontSize: `${fonts.smallTitle}px`,
        outline: "none",
        transition: "0.1s ease-in",
        placeholder: colors.primaryGray
    };

    return (
        <Main isError={isError} noInteraction={noInteraction}>
            <ErrorMessage>{errorMessage}</ErrorMessage>

            {textarea ? (
                <TextAreaErrorWrapper
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    focus={focus}
                    isError={isError}
                >
                    <ResizingTextArea
                        placeholder={placeholder}
                        value={value}
                        onChange={handleUserInputChange}
                        style={resizingTextAreaStyles}
                    />
                </TextAreaErrorWrapper>
            ) : (
                <Input
                    value={value}
                    placeholder={placeholder}
                    required={true}
                    onChange={handleUserInputChange}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    focus={focus}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    padding={padding}
                    border={border}
                    isError={isError}
                />
            )}
        </Main>
    );
};

export default InstructionsFormInput;
