import React from "react";
import { Connection } from "../types";
import EmptyCard from "./EmptyCard";
import { ReactComponent as PlusIcon } from "../../components/icons/card-circle-plus.svg";
import { EventInput } from "src/utils/types/form";

interface Props {
    text: string;
    button?: {
        text: string;
        callback: VoidFunction;
    };
    connection?: Connection;
    upload?: {
        text: string;
        uploadHandler: (event: EventInput) => void;
    };
    padding?: string;
    customStyles?: { [key: string]: string };
}

const PlusCard = ({
    text,
    button,
    connection,
    upload,
    padding,
    customStyles
}: Props) => {
    return (
        <EmptyCard
            icon={<PlusIcon />}
            text={text}
            button={button}
            connection={connection}
            upload={upload}
            padding={padding}
            customStyles={customStyles}
        />
    );
};

export default PlusCard;
