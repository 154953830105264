import React from "react";
import { useState } from "react";
import SearchBar from "src/components/SearchBar";
import SearchTag from "src/components/SearchTag";
import { OutlineButton } from "src/profile/components/Buttons";
import styled from "styled-components";
import { PBPreview } from "../redux/reducer";
import { generateOptions } from "../util";
import PublicBuysFilterModal from "./PublicBuysFilterModal";

const Main = styled.div`
    display: flex;
    flex-direction: column;
`;
const FilterRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

const Clear = styled(OutlineButton)`
    margin-left: auto;
    margin-right: 20px;
    height: 25px;
`;

export interface PublicBuysFilter {
    name: string;
    values: string[];
    type?: string;
}

interface Props {
    filters: PublicBuysFilter[];
    search: string;
    updateSearch: (string: string) => void;
    setFilters: (newFilters: PublicBuysFilter[]) => void;
    buys: PBPreview[];
}

const PublicBuysFilters = ({
    filters,
    search,
    updateSearch,
    setFilters,
    buys
}: Props) => {
    const [selectFilter, setSelectFilter] = useState<number>();

    const updateFilters = (index: number, action: string, values: string[]) => {
        if (action === "reset") {
            setFilters(
                filters.map(filter => {
                    filter.values = [];
                    return filter;
                })
            );
        } else {
            if (action === "clear") filters[index].values = [];
            else if (action === "add")
                filters[index].values = filters[index].values.concat(values);
            else if (action === "remove")
                filters[index].values = filters[index].values.filter(
                    val => !values.includes(val)
                );
            setFilters(filters.concat([]));
        }
    };
    return (
        <Main>
            <SearchBar
                searchString={search}
                updateSearchString={updateSearch}
            />
            <FilterRow>
                {filters.map((filter, index) =>
                    filter.type === "checkbox" ? (
                        <SearchTag
                            text={filter.name}
                            color={!!filter.values[0] ? "black" : "white"}
                            iconSrc={
                                !!filter.values[0]
                                    ? "/checkbox_selected.svg"
                                    : "/checkbox_unselected.svg"
                            }
                            onClick={() =>
                                !!filter.values[0]
                                    ? updateFilters(index, "remove", ["true"])
                                    : updateFilters(index, "add", ["true"])
                            }
                        />
                    ) : (
                        <SearchTag
                            key={`${index} filter`}
                            text={
                                filter.name + ` ${filter.values.length || ""}`
                            }
                            color={filter.values.length > 0 ? "black" : "white"}
                            iconSrc={"/tag-teal-plus-icon.svg"}
                            onClick={() => setSelectFilter(index)}
                        />
                    )
                )}
                <Clear onClick={() => updateFilters(0, "reset", [])}>
                    Clear Filters
                </Clear>
            </FilterRow>
            {selectFilter !== undefined && (
                <PublicBuysFilterModal
                    onClose={() => setSelectFilter(undefined)}
                    onClear={() => updateFilters(selectFilter, "clear", [])}
                    options={generateOptions(
                        filters[selectFilter].name,
                        buys,
                        filters,
                        selectFilter || 0,
                        updateFilters
                    )}
                />
            )}
        </Main>
    );
};
export default PublicBuysFilters;
