import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import {
    flex,
    clearButton,
    grid,
    clearSpacing
} from "src/utils/styles/snippets";
import { setShareReferralModal } from "src/ui/referrals/actions";
import { CopyButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import { colors } from "src/constants";

const Main = styled("div").attrs(() => ({
    id: "referral-link-button"
}))`
    ${flex("row", "space-between", "center")};
    ${clearButton()};
    width: 100%;
    padding-right: 12px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 0.375rem;
    cursor: default;
`;

const LeftBox = styled("div")`
    ${grid(1, "auto", 6)};
    flex: 1;
    margin-right: 12px;
    padding: 12px 0px 12px 12px;
`;

const Header = styled("h4")`
    ${clearSpacing()};
    color: ${colors.black};
    font-size: 0.8125rem;
    font-weight: 500;
    text-align: left;
    line-height: 1rem;
`;

const LinkPreview = styled("span")`
    display: block;
    color: ${colors.primaryGray};
    font-size: 0.75rem;
    text-align: left;
    line-height: 0.875rem;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const CopyButtonText = styled("span")`
    display: block;
`;

const ReferralLinkButton = () => {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 2000);
        }
    }, [copied]);

    const referralCode = useSelector(
        (state: GlobalState) => state.profile.referralCode
    );

    const referralCodeLink = `https://www.theplug.co/public/v1/referral/${referralCode}`;

    const dispatch = useDispatch();
    function openModal(): void {
        dispatch(setShareReferralModal(true));
    }

    return (
        <Main>
            <LeftBox>
                <Header>Referral Link</Header>
                <LinkPreview>{referralCodeLink}</LinkPreview>
            </LeftBox>

            <CopyButton onClick={openModal} inactive={copied}>
                <CopyButtonText>{copied ? "Copied!" : "Copy"}</CopyButtonText>
            </CopyButton>
        </Main>
    );
};

export default ReferralLinkButton;
