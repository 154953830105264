import { Action } from "src/types";

// Set Is Device Mobile -----------------------------------------------

export const SET_IS_DEVICE_MOBILE = "SET_IS_DEVICE_MOBILE";

export function setIsDeviceMobile(isMobile: boolean): Action {
    return {
        type: SET_IS_DEVICE_MOBILE,
        payload: { isMobile }
    };
}

// Set A Modal Is Opened -----------------------------------------------

export const SET_A_MODAL_IS_OPENED = "SET_A_MODAL_IS_OPENED";

export function setAModalIsOpened(opened: boolean): Action {
    return {
        type: SET_A_MODAL_IS_OPENED,
        payload: { opened }
    };
}
