import moment from "moment";
import React from "react";
import {
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { patch, post } from "src/Api";
import { colors } from "src/constants";
import ShadeScreen from "src/media/components/ShadeScreen";
import { GlobalState } from "src/reducers";
import { addNotification } from "src/ui/notifications/actions";
import styled from "styled-components";
import { removeAssignedScreenshots, updatePublicBuys } from "../redux/actions";
import { PBPreview } from "../redux/reducer";
import PublicBuysScreenshotPreview from "./PublicBuysScreenshot";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 400px;
    position: relative;
    cursor: pointer;
`;

const DroppableArea = styled.div`
    position: relative;
`;

const DroppableAreaDragHighlight = styled.div<{ isDraggingOver: boolean }>`
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    background-color: ${props => props.isDraggingOver && colors.primaryBlue};
    opacity: 0.2;
`;

const Container = styled.div<{ status: string }>`
    position: relative;
    width: 250px;
    height: 400px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    border: 4.5px solid
        ${props =>
            props.status === "error"
                ? colors.primaryRed
                : props.status === "complete"
                ? colors.primaryBlueLight
                : "white"};
    background-color: ${colors.primaryBlueDark};
`;

const Media = styled.img`
    width: 250px;
    height: 400px;
    border-radius: 8px;
    object-fit: contain;
`;

const BottomRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: auto;
    text-align: left;
`;

const CampaignRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const CampaignImage = styled.img`
    height: 46px;
    width: 46px;
    border-radius: 12px;
    margin-right: 16px;
`;

const Info = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    padding: 5px;
`;

const MediaId = styled(Info)`
    align-self: self-start;
    margin-right: auto;
    padding: 10px;
`;

const CampaignName = styled(Info)`
    font-size: 16px;
    font-weight: 700;
    padding-top: 0;
`;
interface Props {
    media: PBPreview;
    onClick: () => void;
    index: number;
}

const PublicBuyPreview = ({ media, onClick, index }: Props) => {
    const dispatch = useDispatch();
    const assigned = useSelector(
        (state: GlobalState) => state.publicBuys.assigned
    );
    const removeScreenshot = () => {
        let isAssigned = false;
        assigned.forEach(shot => {
            if (shot.directBuyStepId === media.directBuyStepId) {
                isAssigned = true;
            }
        });
        if (isAssigned) {
            dispatch(removeAssignedScreenshots(media.directBuyStepId));
            let temp = { ...media };
            temp.screenshotUrl = undefined;
            dispatch(updatePublicBuys(temp));
        } else {
            patch(`/public/buys/${media.directBuyStepId}/unlink`, {})
                .then(res => {
                    let temp = { ...media };
                    temp.screenshotUrl = undefined;
                    dispatch(updatePublicBuys(temp));
                    addNotification(
                        "Your screenshot submission has been removed "
                    );
                })
                .catch(err => {
                    dispatch(
                        addNotification("Failed to remove screenshot", "red")
                    );
                });
        }
    };

    return (
        <Main onClick={onClick}>
            {media.screenshotUrl && (
                <PublicBuysScreenshotPreview
                    src={media.screenshotUrl}
                    inBuy={true}
                    onHide={() => removeScreenshot()}
                />
            )}
            <Droppable
                droppableId={`public-buy-preview-${media.directBuyStepId}-${index}`}
            >
                {(
                    provided: DroppableProvided,
                    snapshot: DroppableStateSnapshot
                ) => (
                    <DroppableArea
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        <DroppableAreaDragHighlight
                            isDraggingOver={snapshot.isDraggingOver}
                        />
                        <Container
                            status={
                                media.rejectionReason
                                    ? "error"
                                    : media.screenshotUrl
                                    ? "complete"
                                    : ""
                            }
                        >
                            <Media src={media.coverPhotoUrl || media.url} />
                            <ShadeScreen>
                                <Column>
                                    <MediaId>Media ID: {media.mediaId}</MediaId>
                                    <BottomRow>
                                        <Info>
                                            {moment(
                                                media.offerApprovedAt
                                            ).format("lll")}
                                        </Info>
                                        <CampaignRow>
                                            <CampaignImage
                                                src={media.imageUrl}
                                                alt=""
                                            />
                                            <Column>
                                                <Info>{media.username}</Info>
                                                <CampaignName>
                                                    {media.campaignName}
                                                </CampaignName>
                                            </Column>
                                        </CampaignRow>
                                    </BottomRow>
                                </Column>
                            </ShadeScreen>
                        </Container>
                    </DroppableArea>
                )}
            </Droppable>
        </Main>
    );
};
export default PublicBuyPreview;
