import { combineReducers } from "redux";
import { Contest } from "./types";
import { NormalizedAction } from "../../types";
import { FETCH_CONTEST } from "./actions";

interface ById {
    [id: number]: Contest;
}

export interface ContestsEntitiesState {
    byId: ById;
    allIds: number[];
}

const initialContestsState: ContestsEntitiesState = {
    byId: {},
    allIds: []
};

const byId = (state = initialContestsState.byId, action: NormalizedAction) => {
    switch (action.type) {
        case FETCH_CONTEST.SUCCESS:
            return { ...state, ...action.response.entities.contests };
        default:
            return state;
    }
};

const allIds = (
    state = initialContestsState.allIds,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_CONTEST.SUCCESS:
            return action.response.result;
        default:
            return state;
    }
};

const contestsEntitiesReducer = combineReducers<ContestsEntitiesState>({
    byId,
    allIds
});

export default contestsEntitiesReducer;
