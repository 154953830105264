import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import useAuthorization from "../../../auth/hooks/useAuthorization";
import { MediaAction } from "../../../auth/roles";
import PromoteButton from "../../details/components/PromoteButton";
import { startPromotingModal } from "../../../ui/auth/actions";
import { fonts } from "src/constants";
import { isScriptModalOpen } from "src/campaigns/scripts/selectors";

const Cell = styled.a`
    background-color: rgba(0, 0, 0, 0.5);
    padding: 12px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${fonts.smallTitle}px;
    color: white;
    cursor: pointer;

    &:hover {
        color: white;
        text-decoration: none;
    }

    &:active {
        background-color: rgba(0, 0, 0, 0.8);
    }
`;

const VerticalDivider = styled.div`
    height: 100%;
    width: 1px;
`;

const Icon = styled.img`
    margin-right: 8px;
`;

interface Props {
    mediaId: number;
    mediaUrl: string;
    linkClicked: () => void;
}

const CampaignCarouselDetails = ({ mediaId, mediaUrl, linkClicked }: Props) => {
    const [cellButtons] = useAuthorization([MediaAction.cellButtons]);
    const dispatch = useDispatch();

    const isCampaign = window.location.href.includes("campaign");

    return cellButtons ? (
        <>
            {isCampaign && (
                <>
                    <Cell
                        onClick={linkClicked}
                        onMouseDown={e => {
                            e.stopPropagation();
                        }}
                    >
                        <Icon src={"/white_link.svg"} />
                        Copy link
                    </Cell>
                    <VerticalDivider />
                </>
            )}
            <Cell
                target="_blank"
                download={`media-id-${mediaId}`}
                href={mediaUrl}
                onMouseDown={e => {
                    e.stopPropagation();
                }}
            >
                <Icon src={"/white_download.svg"} />
                Download
            </Cell>
        </>
    ) : (
        <PromoteButton onClick={() => dispatch(startPromotingModal())} />
    );
};

export default CampaignCarouselDetails;
