import React from "react";
import { useSelector } from "react-redux";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import ProfilePhoto from "src/components/ProfilePhoto";
import { colors, overlayBackgrounds } from "src/constants";
import featureFlags from "src/firebase/featureFlags";
import { selectFeatureFlags } from "src/firebase/selectors";
import { SubmissionReport } from "src/marketplace/reports/types";
import Platforms from "src/social-accounts/Platforms";
import { shortenNumber } from "src/util";
import styled from "styled-components";

const LeaderboardCell = styled.div`
    position: relative;
    width: 141px;
    height: 207px;
    flex: none;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
`;

const CoverPhoto = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const CellContent = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${overlayBackgrounds.light};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 6px;
`;

const CellMetricsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${colors.white};
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
`;

const CellMetric = styled.div`
    ${FlexVerticallyCentered};
    padding: 0 6px;
    height: 24px;
`;

const CellMetricPlace = styled(CellMetric)<{ fullBorder?: boolean }>`
    background-color: ${colors.primaryBlue};
    border-radius: 4px 4px 0 0;
    border-radius: ${props => (props.fullBorder ? "4px" : "4px 4px 0 0")};
`;

const CellMetricEarnings = styled(CellMetric)`
    background-color: ${colors.secondaryGreen};
    border-radius: 0 4px 4px 4px;
`;

const Icon = styled.img`
    margin-right: 4px;
    width: 12px;
    height: 12px;
`;

const Profile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${colors.white};
`;

const Handle = styled.div`
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
    margin-top: 6px;
`;

const FollowerCount = styled.div`
    font-family: SF Pro Text;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 2px;

    & > span {
        font-weight: 600;
    }
`;

interface Props {
    report: SubmissionReport;
    place?: number;
}

const OpenBuyLeaderboardCell = ({ report, place }: Props) => {
    const showDollarAmount = useSelector(
        selectFeatureFlags(featureFlags.DollarAmountsOnChallengeLeaderboards)
    );

    return (
        <LeaderboardCell>
            <CoverPhoto src={report.media?.coverPhotoUrl} />
            <CellContent>
                <CellMetricsWrapper>
                    {/* {place && (
                        <CellMetricPlace fullBorder={!showDollarAmount}>
                            <Icon src="/leaderboard/crown.svg" />
                            {place}
                            {nth(place)} Place
                        </CellMetricPlace>
                    )} */}
                    {showDollarAmount && (
                        <CellMetricEarnings>
                            <Icon src="/campaigns/dollar-sign.svg" />$
                            {report.cost} Earned
                        </CellMetricEarnings>
                    )}
                </CellMetricsWrapper>
                <Profile>
                    <ProfilePhoto
                        photoSize={48}
                        profilePhotoUrl={report.socialAccount?.profilePhotoUrl}
                        photoBorder={`1.5px solid ${colors.white}`}
                    />
                    <Handle>@{report.socialAccount?.username}</Handle>
                    <FollowerCount>
                        <span>
                            {shortenNumber(
                                report.socialAccount?.numFollowers || 0
                            )}
                        </span>{" "}
                        on{" "}
                        {
                            Platforms[report.socialAccount?.platform || "other"]
                                .title
                        }
                    </FollowerCount>
                </Profile>
            </CellContent>
        </LeaderboardCell>
    );
};
export default OpenBuyLeaderboardCell;
