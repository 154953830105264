import React from "react";
import { connect } from "react-redux";
import { cancelModal } from "src/ui/modals/actions";
import {
    removeVerification,
    requestVerification
} from "src/campaigns/shoutouts/actions";
import { cancelShoutout } from "../actions";
import { goBack } from "connected-react-router";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { Shoutout, ShoutoutFillOrder } from "../types";
import { ShoutoutState } from "../utils/evaluateShoutoutState";

const Main = styled.div`
    padding: 20px 15px;
`;

interface Props {
    shoutout: Shoutout;
    state: ShoutoutState;
    goBack: () => void;
    cancelModal: () => void;
    cancelShoutout: (fillOrders: ShoutoutFillOrder[]) => void;
    requestVerification: (fillOrders: ShoutoutFillOrder[]) => void;
    removeVerification: (fillOrders: ShoutoutFillOrder[]) => void;
}

const ShoutoutDetailsButtons = ({
    shoutout,
    state,
    goBack,
    cancelModal,
    cancelShoutout,
    requestVerification,
    removeVerification
}: Props) => {
    const submitDisabled = !shoutout.ShoutoutFillOrders.every(
        fillOrder => fillOrder.verify_media_filename
    );
    const buttonMargin = { marginTop: "10px" };

    const tryToCancelOrder = () => {
        if (
            window.confirm(
                "Are you sure you want to cancel? Proceeding will release the views you've reserved and you will not get paid."
            )
        ) {
            cancelShoutout(shoutout.ShoutoutFillOrders);
        }
    };

    const tryToRequestVerification = () => {
        if (!submitDisabled) {
            requestVerification(shoutout.ShoutoutFillOrders);
        }
    };

    const tryToRemoveVerification = () => {
        if (
            window.confirm(
                "Are you sure you need to change screenshots? This will take you out of the verification queue, until you've submitted again."
            )
        ) {
            removeVerification(shoutout.ShoutoutFillOrders);
        }
    };

    if (state === ShoutoutState.Unreserved) {
        // fill this in when the shoutouts product changes
        return null;
    } else if (state === ShoutoutState.Reserved) {
        return (
            <Main>
                <Button
                    block
                    variant="success"
                    disabled={submitDisabled}
                    onClick={tryToRequestVerification}
                >
                    Submit Screenshot Proof
                </Button>
                <Button
                    block
                    variant="outline-success"
                    style={buttonMargin}
                    onClick={tryToCancelOrder}
                >
                    Cancel this Plug
                </Button>
            </Main>
        );
    } else if (state === ShoutoutState.Blocking) {
        return (
            <Main>
                <Button
                    block
                    variant="success"
                    disabled={submitDisabled}
                    onClick={tryToRequestVerification}
                >
                    Submit Screenshot Proof
                </Button>
                <Button
                    block
                    variant="outline-success"
                    style={buttonMargin}
                    onClick={cancelModal}
                >
                    I don't have screenshots
                </Button>
            </Main>
        );
    } else if (state === ShoutoutState.Confirming) {
        return (
            <Main>
                <Button
                    block
                    variant="outline-success"
                    onClick={tryToRemoveVerification}
                >
                    Change Screenshots
                </Button>
            </Main>
        );
    } else if (
        state === ShoutoutState.Confirmed ||
        state === ShoutoutState.Rejected
    ) {
        return (
            <Main>
                <Button block variant="success" onClick={goBack}>
                    Done
                </Button>
                <Button
                    block
                    variant="outline-success"
                    style={buttonMargin}
                    href="mailto:support@plugco.in"
                >
                    Contact Support
                </Button>
            </Main>
        );
    } else {
        return null;
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    cancelModal: () => dispatch(cancelModal()),
    cancelShoutout: (fillOrders: ShoutoutFillOrder[]) =>
        dispatch(cancelShoutout(fillOrders)),
    requestVerification: (fillOrders: ShoutoutFillOrder[]) =>
        dispatch(requestVerification(fillOrders)),
    removeVerification: (fillOrders: ShoutoutFillOrder[]) =>
        dispatch(removeVerification(fillOrders)),
    goBack: () => dispatch(goBack())
});

export default connect(null, mapDispatchToProps)(ShoutoutDetailsButtons);
