import React, { useEffect, useState } from "react";
import { colors } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { toggleRulesModal } from "src/buys/actions";
import { GlobalState } from "src/reducers";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import { database } from "src/firebase/FirebaseConfig";
import { get } from "src/new-chat/saga";
import { useParams } from "react-router";

const Main = styled.div``;
const Button = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid ${colors.blueGrey2};
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    padding: 12px 0;
    margin-top: 9px;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.primaryBlue};
    cursor: pointer;
    :hover {
        background-color: ${colors.blueGrey2};
    }
`;

const InstructionInfoList = () => {
    const dispatch = useDispatch();

    const { openBuyId } = useParams<{ openBuyId: string }>();

    const openBuy: OpenBuy = useSelector(
        (state: GlobalState) =>
            state.entities.openBuys.byId[parseInt(openBuyId) || 0]
    );

    const [standardsId, setStandardsId] = useState(3);
    const getStandards = async () => {
        let res = await get<{ id: string; ruleSetId: number }>(
            "ChallengesStandardMediaRulesSetId",
            "GzADIFgd3zDfZAgtQF1Y"
        );
        if (res?.ruleSetId) setStandardsId(res?.ruleSetId);
    };
    useEffect(() => {
        getStandards();
    }, []);

    return (
        <Main>
            {openBuy?.ruleSetId && (
                <Button
                    onClick={() =>
                        dispatch(toggleRulesModal(openBuy.ruleSetId))
                    }
                >
                    ✅ Review Do's and Don'ts
                </Button>
            )}
            <Button onClick={() => dispatch(toggleRulesModal(standardsId))}>
                👍 View Plug Media Standards
            </Button>
            {/* <Button>🎬 View Content Style Suggestions</Button>
            <Button>🗣️ Review Scripts and Assets</Button> */}
        </Main>
    );
};
export default InstructionInfoList;
