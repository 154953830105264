import React from "react";
import ListCell from "../components/ListCell";
import SmallIcon from "../components/SmallIcon";
import SettingsText from "../components/SettingsText";
import OutlineContainer from "../components/OutlineContainer";
import { Profile } from "../../types";

interface Props {
    profile: Profile;
    emailModal: VoidFunction;
}

const ContactInfo = ({ profile, emailModal }: Props) => {
    return (
        <OutlineContainer>
            <ListCell>
                <SmallIcon src="/phone.svg" />
                <SettingsText>{profile.phone_number}</SettingsText>
            </ListCell>

            <ListCell clickable onClick={emailModal}>
                <SmallIcon src="/email.svg" />
                <SettingsText>{profile.email}</SettingsText>
            </ListCell>
        </OutlineContainer>
    );
};

export default ContactInfo;
