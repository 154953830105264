import { Action } from "../../types";
import { CAMPAIGN_MODAL } from "../campaigns/actions";


export const HIGHLIGHT_MODAL = {
    PLACEMENTS: "HIGHLIGHT_MODAL_PLACEMENTS"
};

export const campaignHighlightDownloadTypesModal = (
    highlightId: number | null
): Action => ({
    type: CAMPAIGN_MODAL.DOWNLOAD_TYPES,
    payload: { highlightId }
});

export const highlightPlacementsModal = (): Action => ({
    type: HIGHLIGHT_MODAL.PLACEMENTS,
    payload: {}
});

export const SET_CURRENT_MODAL_HIGHLIGHTS = "SET_CURRENT_MODAL_HIGHLIGHTS";

export const setCurrentModalHighlights = (highlightIds: number[]): Action => ({
    type: SET_CURRENT_MODAL_HIGHLIGHTS,
    payload: { highlightIds }
});
