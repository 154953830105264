import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectFooter from "src/campaigns/components/SelectFooter";
import { Campaign, Script } from "src/campaigns/types";
import { breakpoints } from "src/constants";
import { GlobalState } from "src/reducers";
import { setWarning } from "src/ui/scripts/actions";
import styled from "styled-components";
import { scriptAssetLink } from "../actions";
import AssetSearch from "./AssetSearch";
import ModalHeader from "./ModalHeader";

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    overflow-y: auto;
    height: 80vh;
    isolation: isolate;
    @media (max-width: ${breakpoints.tablet}px) {
        height: 100%;
    }
`;
const OverflowWrap = styled.div`
    width: 100%;
    overflow: auto;
`;
const StyledSelectFooter = styled(SelectFooter)`
    margin-top: auto;
`;

interface Props {
    campaign: Campaign;
    script: Script;
}

const SelectAsset = ({ campaign, script }: Props) => {
    const [selectedAssets, setSelectedAssets] = useState<number[]>([]);
    const dispatch = useDispatch();
    const isWarning = useSelector(
        (state: GlobalState) => state.ui.script.warning
    );
    useEffect(() => {
        if (selectedAssets.length > 0 && !isWarning) {
            dispatch(setWarning(true));
        } else if (selectedAssets.length === 0 && isWarning) {
            dispatch(setWarning(false));
        }
    }, [selectedAssets]);

    const handleSubmit = () => {
        dispatch(scriptAssetLink(script, selectedAssets));
        dispatch(setWarning(false));
    };
    const selectAsset = (id: number) => {
        setSelectedAssets([...selectedAssets, id]);
    };
    const unselectAsset = (id: number) => {
        const filteredArr = selectedAssets.filter((val: number) => val !== id);
        setSelectedAssets(filteredArr);
    };

    return (
        <Wrapper>
            <ModalHeader
                title={"Select Asset from the Plug"}
                campaign={campaign}
                scriptTitle={script.title}
            />
            <OverflowWrap>
                <AssetSearch
                    campaign={campaign}
                    selectedAssets={selectedAssets}
                    selectAsset={selectAsset}
                    unselectAsset={unselectAsset}
                    handleSubmit={handleSubmit}
                />
            </OverflowWrap>
            <StyledSelectFooter
                handleSubmit={handleSubmit}
                selectedIds={selectedAssets}
                label="assets"
            />
        </Wrapper>
    );
};

export default SelectAsset;
