import React, { useEffect, useState } from "react";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import NavButton from "../../../../components/NavButton";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../../reducers";
import { Media, PlugLink } from "../../../../media/types";
import { fetchLinkForPlugSuccess } from "src/links/actions";
import { customReasonModal } from "src/ui/modals/actions";
import CustomMediaPage from "../../components/CustomMediaPage";
import { Profile } from "../../../types";
import FullscreenLoader from "../../../../components/FullscreenLoader";
import CampaignModals from "../../../modals/CampaignModals";
import { fetchMediaUser } from "../../../actions";
import PaddingAround from "../../../../components/PaddingAround";
import XCard from "../../../components/XCard";
import MainContainer from "src/components/MainContainer";
import { useHistory } from "react-router";
import Navbar from "src/components/Navbar";

interface Props {
    profile: Profile;
}

const PrivateCustomMediaPage = ({ profile }: Props) => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        if (!scrolled) {
            window.scrollTo(0, 0);
            setScrolled(true);
        }
    }, [scrolled]);

    const customMedia = useSelector((state: GlobalState) => {
        return state.profile.customMedia;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();

    function dispatchFetchMediaUser(publisherId: number): void {
        dispatch(fetchMediaUser(publisherId));
    }

    function dispatchFetchLinkForPlugSuccess(link: PlugLink): void {
        dispatch(fetchLinkForPlugSuccess(link));
    }

    function dispatchCustomReasonModal(media: Media): void {
        dispatch(customReasonModal(media));
    }

    if (customMedia === null) {
        return (
            <>
                <Navbar back marginBottom={10} title="CUSTOM ADS" />

                <MainContainer>
                    <PaddingAround>
                        <XCard
                            text="Failed to load custom media, click the button below to try again."
                            button={{
                                text: "Try again",
                                callback: () => {
                                    dispatchFetchMediaUser(profile.id);
                                }
                            }}
                        />
                    </PaddingAround>
                </MainContainer>
            </>
        );
    } else if (!customMedia) {
        return (
            <>
                <Navbar back marginBottom={10} title="CUSTOM ADS" />
                <FullscreenLoader />
            </>
        );
    } else {
        return (
            <>
                <Navbar back marginBottom={10} title="CUSTOM ADS" />

                <MainContainer>
                    {scrolled && (
                        <CustomMediaPage
                            customMedia={customMedia}
                            username={profile.username}
                            fetchLinkForPlugSuccess={
                                dispatchFetchLinkForPlugSuccess
                            }
                            customReasonModal={dispatchCustomReasonModal}
                        />
                    )}
                </MainContainer>

                <CampaignModals />
            </>
        );
    }
};

export default PrivateCustomMediaPage;
