import React from "react";
import { colors } from "src/constants";
import styled from "styled-components";

const StatusWrap = styled.div`
    background-color: ${colors.quaternaryLightGray};
    border-radius: 8px;
    padding: 12px;
    display: flex;
    align-items: center;
`;
const StatusIcon = styled.img`
    height: 24px;
    width: 24px;
    margin-right: 8px;
`;
const StatusText = styled.div`
    text-align: left;
    font-size: 12px;
`;
const MediaStatus = ({
    message,
    icon,
    className
}: {
    message: string;
    icon?: string;
    className?: string;
}) => {
    return (
        <StatusWrap className={className}>
            <StatusIcon src={icon || "/blue-warning.svg"} alt="" />
            <StatusText>{message}</StatusText>
        </StatusWrap>
    );
};

export default MediaStatus;
