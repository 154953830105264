import React, { useEffect } from "react";
import styled from "styled-components";
import { colors, shadows } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { setPillNotificationText } from "./actions";
import { AnimatePresence, motion } from "framer-motion";
import withModalPortal from "src/components/withModalPortal";

const Main = styled(motion.div)`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999999;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    color: ${colors.black};
    background-color: ${colors.white};
    border: ${colors.blueGrey2} solid 1px;
    border-radius: 10000px;
    box-shadow: ${shadows.one};
`;

const TypeIcon = styled.img`
    width: 24px;
    height: 24px;
`;

const Text = styled.span`
    font-size: 14px;
    font-weight: 500;
`;

const PillNotification = () => {
    const { text, type, time = 4000 } = useSelector((state: GlobalState) => {
        return state.ui.notification.pillNotification;
    });

    const dispatch = useDispatch();
    useEffect(() => {
        if (text !== "") {
            setTimeout(() => {
                dispatch(setPillNotificationText(""));
            }, time);
        }
    }, [text]);

    if (typeof text !== "string") {
        console.error(
            "Cannot display non string elements: ",
            typeof text === "object" ? JSON.stringify(text) : typeof text
        );
        return <></>;
    }
    return (
        <AnimatePresence>
            {text !== "" && (
                <Main
                    initial={{ opacity: 0, top: "80px" }}
                    animate={{ opacity: 1, top: "120px" }}
                    exit={{ opacity: 0, top: "80px" }}
                    transition={{ duration: 0.2 }}
                >
                    <TypeIcon
                        src={
                            type === "success"
                                ? "/check-mark-circle-primary-green.svg"
                                : type === "danger"
                                ? "/red-circle-close.svg"
                                : "/blue-warning.svg"
                        }
                    />
                    <Text>{text}</Text>
                </Main>
            )}
        </AnimatePresence>
    );
};

export default withModalPortal(PillNotification);
