import React from "react";
import styled from "styled-components";
import { Shimmer } from "./SkeletonLoader";
import { ReactComponent as Check } from "./icons/Check/shortCheck.svg";
import { Exclamation } from "./icons/exports";
import { colors } from "../constants";

const Loading = styled.div`
    min-width: 24px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    ${Shimmer}
`;

const IconWrapper = styled.div<{ backgroundColor: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    width: 24px;
    height: 24px;
    background: ${props => props.backgroundColor};
    border-radius: 4px;
`;

interface Props {
    type: "x" | "check" | "ellipsis" | "loading";
    backgroundColor?: string;
    iconColor?: string;
}

const SquareIcon = ({
    type,
    backgroundColor = colors.primaryGreen,
    iconColor = colors.white
}: Props) => {
    if (type === "loading") {
        return <Loading />;
    } else if (type === "check") {
        return (
            <IconWrapper backgroundColor={backgroundColor}>
                <Check />
            </IconWrapper>
        );
    }
    // else if (type === "ellipsis") {
    //     return (
    //         <IconWrapper backgroundColor={backgroundColor}>
    //             <Ellipsis />
    //         </IconWrapper>
    //     );
    // }
    return (
        <IconWrapper backgroundColor={backgroundColor}>
            <Exclamation fill={iconColor} />
        </IconWrapper>
    );
};

export default SquareIcon;
