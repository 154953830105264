import React from "react";
import {
    BlueButton,
    Bottom,
    EmptyButton,
    Form,
    Input,
    Middle,
    Title,
    Top
} from "./commonComponents";

interface Props {
    address: string;
    setAddress: (address: string) => void;
    city: string;
    setCity: (city: string) => void;
    state: string;
    setState: (state: string) => void;
    zip: string;
    setZip: (zip: string) => void;
    back?: () => void;
    confirm: () => void;
}

const ShippingForm = ({
    address,
    setAddress,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    back,
    confirm
}: Props) => {
    function submitDisabled() {
        return (
            address.length === 0 ||
            city.length === 0 ||
            state.length === 0 ||
            zip.length === 0
        );
    }

    return (
        <>
            <Top>
                <div>
                    <strong>Shipping Information</strong>
                </div>
                <div>
                    Please input the shipping address you want the prize to be
                    delivered to.
                </div>
            </Top>
            <Middle>
                <Form>
                    <Title>Address</Title>
                    <Input
                        value={address}
                        onChange={e => setAddress(e.currentTarget.value)}
                    />
                    <Title>City</Title>
                    <Input
                        value={city}
                        onChange={e => setCity(e.currentTarget.value)}
                    />
                    <Title>State</Title>
                    <Input
                        value={state}
                        onChange={e => setState(e.currentTarget.value)}
                    />
                    <Title>ZIP</Title>
                    <Input
                        value={zip}
                        onChange={e => setZip(e.currentTarget.value)}
                    />
                </Form>
            </Middle>
            <Bottom>
                {back && <EmptyButton onClick={back}>Back</EmptyButton>}
                <BlueButton onClick={confirm} disabled={submitDisabled()}>
                    {back ? "Next" : "Confirm"}
                </BlueButton>
            </Bottom>
        </>
    );
};

export default ShippingForm;
