import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "src/components/CommonStyles";
import NavButton from "src/components/NavButton";
import SearchTag from "src/components/SearchTag";
import SimpleNavbar from "src/components/SimpleNavbar";
import LabelText from "src/components/text/LabelText";
import { colors } from "src/constants";
import FullScreenModal from "src/modals/FullScreenModal";
import styled from "styled-components";
import SearchUserList from "./components/SearchUserList";
import SuggestedUserList from "./components/SuggestedUserList";
import SearchBox from "../search/components/SearchBox";
import SpinLoader from "src/components/SpinLoader";
import { fetchElasticSearch } from "src/search/actions";
import { selectPublisherHits } from "src/search/selector";

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
`;

const Main = styled.div`
    padding: 0 18px;
    overflow-y: auto;
    position: relative;
`;

const Label = styled(LabelText)`
    position: sticky;
    top: 0px;
    padding: 10px 0;
    background-color: ${colors.white};
`;

const Header = styled.div`
    position: sticky;
    z-index: 997;
    top: 57px;
`;

const SearchWrapper = styled.div`
    padding: 10px 18px;
    background-color: white;
`;

const SelectedList = styled.div`
    border-top: 1px solid ${colors.mediumTeal};
    border-bottom: 1px solid ${colors.mediumTeal};
    background-color: ${colors.lighterTeal};
    ${FlexVerticallyCentered}
    flex-wrap: wrap;
    overflow-x: auto;
    padding: 12px 18px 6px;
`;

const LoadingOverlay = styled.div`
    ${FlexCentered}
    z-index: 997;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    bottom: 0;
`;

export interface SelectedUsers {
    [id: string]: {
        publisherId: string;
        username: string;
        avatarUrl: string | null;
    };
}

interface Props {
    title: string;
    actionText: string;
    filterOutUserIds?: string[];
    actionCallback: () => void;
    closeModal: () => void;
    selectedUsers: SelectedUsers;
    setSelectedUsers: (selectedUsers: SelectedUsers) => void;
    isLoading?: boolean;
}

const UserSelectionWithSearchModal = ({
    title,
    actionText,
    filterOutUserIds,
    actionCallback,
    closeModal,
    selectedUsers,
    setSelectedUsers,
    isLoading
}: Props) => {
    const [queryString, setQueryString] = useState("");
    const [debouncedQuery, setDebouncedQuery] = useState(queryString);
    const publisherHits = useSelector(selectPublisherHits);

    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setTimeout(() => setQueryString(debouncedQuery), 500);
        return () => clearTimeout(timer);
    }, [debouncedQuery]);

    useEffect(() => {
        dispatch(fetchElasticSearch("publishers", queryString));
    }, [queryString]);

    const onUserSelection = (
        publisherId: string,
        username: string,
        avatarUrl: string | null
    ) => {
        if (selectedUsers[publisherId]) {
            const newState = { ...selectedUsers };
            delete newState[publisherId];
            setSelectedUsers(newState);
        } else {
            setSelectedUsers({
                ...selectedUsers,
                [publisherId]: { publisherId, username, avatarUrl }
            });
        }
    };

    return (
        <FullScreenModal closeModal={closeModal}>
            <Wrapper>
                <SimpleNavbar
                    left={<NavButton onClick={closeModal} text="Cancel" />}
                    title={title}
                    right={
                        <NavButton onClick={actionCallback} text={actionText} />
                    }
                />

                <Header>
                    <SearchWrapper>
                        <SearchBox
                            queryString={debouncedQuery}
                            setQueryString={setDebouncedQuery}
                        />
                    </SearchWrapper>
                    <SelectedList>
                        {Object.values(selectedUsers).map(user => (
                            <SearchTag
                                key={user.publisherId}
                                text={user.username}
                                color="white"
                                iconSrc="/tag-teal-close-icon.svg"
                                onClick={() =>
                                    onUserSelection(
                                        user.publisherId,
                                        user.username,
                                        user.avatarUrl
                                    )
                                }
                                textTransform={false}
                            />
                        ))}
                    </SelectedList>
                </Header>
                <Main>
                    <SuggestedUserList
                        selectedUsers={selectedUsers}
                        onUserSelection={onUserSelection}
                        filterOutUserIds={filterOutUserIds}
                        queryString={queryString}
                    />
                    <SearchUserList
                        selectedUsers={selectedUsers}
                        onUserSelection={onUserSelection}
                        filterOutUserIds={filterOutUserIds}
                        hits={publisherHits}
                    />
                </Main>
            </Wrapper>

            {isLoading && (
                <LoadingOverlay>
                    <SpinLoader size={60} color={colors.black} />
                </LoadingOverlay>
            )}
        </FullScreenModal>
    );
};

export default UserSelectionWithSearchModal;
