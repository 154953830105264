import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { goBack } from "connected-react-router";
import { requestDirectBuyById, updateDirectBuy } from "../actions";
import {
    selectDirectBuyErrorState,
    selectDirectBuysById,
    selectDirectBuyStepEntities
} from "../selectors";
import {
    DirectBuyDetailsModalState,
    DirectBuyError
} from "../../ui/direct-buys/reducer";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import DirectBuyDetailsError from "./DirectBuyDetailsError";
import DirectBuyDetailsHeader from "./header/DirectBuyDetailsHeader";
import DirectBuyDetailsPayments from "./payments/DirectBuyDetailsPayments";
import DirectBuyDetailsInstructions from "./instructions/DirectBuyDetailsInstructions";
import DirectBuyDetailsModals from "./modals/DirectBuyDetailsModals";
import DirectBuyDetailsAcceptDecline from "./DirectBuyDetailsAcceptDecline";
import DirectBuyDetailsHeaderLoading from "./header/DirectBuyDetailsHeaderLoading";
import DirectBuyDetailsPaymentsLoading from "./payments/DirectBuyDetailsPaymentsLoading";
import DirectBuyDetailsInstructionsLoading from "./instructions/DirectBuyDetailsInstructionsLoading";
import Divider from "../../components/Divider";
import DirectBuyDetailsSectionHeader from "./components/DirectBuyDetailsSectionHeader";
import { post } from "../../Api";
import { DirectBuy } from "../modules/direct-buy/types";
import { GlobalState } from "../../reducers";
import { checkPublisherFlag } from "../../profile/flags/selectors";
import { Redirect } from "react-router";
import { directBuyDetailsModal } from "../../ui/direct-buys/actions";
import MainContainer from "src/components/MainContainer";
import styled from "styled-components";
import { colors, maxWidthDesktop } from "src/constants";
import { addNotification } from "src/ui/notifications/actions";
import { getDirectBuyPaymentCollectionArrayForDirectBuy } from "../modules/direct-buy/util";

const Sections = styled.div`
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 20px;
    margin: 20px auto;

    @media (max-width: ${maxWidthDesktop}px) {
        grid-gap: 0px;
        margin-top: 0px;
    }
`;

interface SectionProps {
    last?: true;
}

const Section = styled.div<SectionProps>`
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;
    @media (max-width: ${maxWidthDesktop}px) {
        border: none;
        border-bottom: ${props =>
            !props.last && `${colors.mediumTeal} solid 1px`};
        border-radius: 0px;
    }
`;
const DisableSection = styled.div<{ disable: boolean }>`
    ${props =>
        props.disable
            ? `
    opacity: 0.6;
    pointer-events: none;
    cursor: default;`
            : ""}
`;

interface Props {
    directBuyId: number;
}

const DirectBuyDetails = ({ directBuyId }: Props) => {
    const dispatch = useDispatch();
    const error = useSelector(selectDirectBuyErrorState)[directBuyId];
    const directBuy = useSelector(selectDirectBuysById)[directBuyId];

    const loadDirectBuy = () => {
        dispatch(requestDirectBuyById(directBuyId));
    };

    useEffect(() => {
        loadDirectBuy();
    }, [directBuyId]);

    const [updatedBuy, setUpdatedBuy] = useState<DirectBuy | null>(null);
    useEffect(() => {
        if (directBuy && !directBuy.offerViewedAt) {
            post(`/v1/directBuy/${directBuy.id}/offerViewed`, {})
                .then(({ data }) => {
                    setUpdatedBuy(data.data);
                })
                .catch(() => {});
        }
    }, [directBuy]);

    const directBuySteps = useSelector(selectDirectBuyStepEntities);
    const [payments] = useState(() =>
        directBuy
            ? getDirectBuyPaymentCollectionArrayForDirectBuy(
                  directBuy,
                  directBuySteps,
                  true
              )
            : []
    );

    const [paymentsExpanded, setPaymentsExpanded] = useState(true);
    const [instructionsExpanded, setInstructionsExpanded] = useState(true);

    const navbar = (
        <SimpleNavbar
            title="PERSONAL OFFER"
            left={
                <NavButton
                    back
                    onClick={() => {
                        if (updatedBuy) {
                            dispatch(updateDirectBuy(updatedBuy));
                        }
                        dispatch(goBack());
                    }}
                />
            }
            right={
                directBuy && (
                    <NavButton
                        right
                        onClick={() => {
                            dispatch(
                                directBuyDetailsModal(
                                    DirectBuyDetailsModalState.actions
                                )
                            );
                        }}
                    >
                        <img src="/ellipsis-button.svg" />
                    </NavButton>
                )
            }
        />
    );

    const hasAccess = useSelector((state: GlobalState) =>
        checkPublisherFlag(state, "directBuyAccess", "approved")
    );

    if (!hasAccess || !directBuy) {
        return <Redirect to={"/"} />;
    } else if (error) {
        let message;
        let reloadCallback;
        switch (error) {
            case DirectBuyError.generic:
                message = "Failed to load personal offer.";
                reloadCallback = loadDirectBuy;
                break;
            case DirectBuyError.notFound:
                message = "No personal offer found.";
                break;
            case DirectBuyError.accessDenied:
                message = "You don't have permission to view this.";
        }
        return (
            <>
                {navbar}
                <DirectBuyDetailsError
                    message={message}
                    reloadCallback={reloadCallback}
                />
            </>
        );
    } else if (directBuy) {
        return (
            <>
                {navbar}

                <MainContainer>
                    <Sections>
                        <Section>
                            <DirectBuyDetailsHeader directBuy={directBuy} />
                        </Section>

                        <Section>
                            <DirectBuyDetailsSectionHeader
                                title="Payments"
                                expanded={paymentsExpanded}
                                setExpanded={setPaymentsExpanded}
                            />
                            <DirectBuyDetailsPayments
                                payments={payments}
                                expanded={paymentsExpanded}
                            />
                        </Section>

                        <Section
                            last
                            onClick={() =>
                                directBuy.offerStatus !== "approved" &&
                                dispatch(
                                    addNotification(
                                        "Offer must be accepted before completing any steps",
                                        "red"
                                    )
                                )
                            }
                        >
                            <DisableSection
                                disable={directBuy.offerStatus !== "approved"}
                            >
                                <DirectBuyDetailsSectionHeader
                                    title="Instructions"
                                    expanded={instructionsExpanded}
                                    setExpanded={setInstructionsExpanded}
                                />
                                <DirectBuyDetailsInstructions
                                    directBuy={directBuy}
                                    expanded={instructionsExpanded}
                                    openBuyId={null}
                                />
                            </DisableSection>
                        </Section>
                    </Sections>

                    {directBuy.offerStatus === "pending" && (
                        <DirectBuyDetailsAcceptDecline directBuy={directBuy} />
                    )}
                </MainContainer>

                <DirectBuyDetailsModals directBuy={directBuy} />
            </>
        );
    } else {
        return (
            <>
                {navbar}

                <MainContainer>
                    <DirectBuyDetailsHeaderLoading />
                    <Divider />
                    <DirectBuyDetailsSectionHeader
                        title="Payments"
                        expanded={paymentsExpanded}
                        setExpanded={setPaymentsExpanded}
                    />
                    <DirectBuyDetailsPaymentsLoading
                        expanded={paymentsExpanded}
                    />
                    <Divider />
                    <DirectBuyDetailsSectionHeader
                        title="Instructions"
                        expanded={instructionsExpanded}
                        setExpanded={setInstructionsExpanded}
                    />
                    <DirectBuyDetailsInstructionsLoading
                        expanded={instructionsExpanded}
                    />
                </MainContainer>
            </>
        );
    }
};

export default DirectBuyDetails;
