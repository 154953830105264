import { Action, NormalizedAction } from "../../types";
import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../../reducers";

export const FETCH_CAMPAIGN_CAPTIONS = {
    REQUEST: "FETCH_CAMPAIGN_CAPTIONS_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_CAPTIONS_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_CAPTIONS_FAILURE"
};

export const fetchCampaignCaptions = (campaignId: number): Action => ({
    type: FETCH_CAMPAIGN_CAPTIONS.REQUEST,
    payload: { campaignId }
});

export const fetchCampaignCaptionsSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { campaignId: number }
): NormalizedAction => ({
    type: FETCH_CAMPAIGN_CAPTIONS.SUCCESS,
    response,
    payload
});

export const fetchCampaignCaptionsFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_CAPTIONS.FAILURE,
    payload: { error }
});
