import React from "react";
import { useDispatch } from "react-redux";
import {
    HorizontalScrollOuter,
    HorizontalScrollInner,
    StatusCard
} from "../details/Details.styled";
import { Highlight } from "../types";
import XCard from "../../profile/components/XCard";
import HighlightMediaList from "../highlight-medias/HighlightMediaList";
import { MediaLoading } from "../../components/SkeletonLoader";
import PlacementSelector from "../placements/components/PlacementSelector";

interface Props {
    location: "campaign" | "main";
    emptyMessage: string;
    campaignId: number | null;
    fetchHighlights: (campaignId: number) => void;
    highlightPlacementsModal: () => void;
    highlights?: Highlight[] | null;
    highlightsLoading: boolean;
}

const HighlightsMediaRow = ({
    location,
    campaignId,
    emptyMessage,
    fetchHighlights,
    highlightPlacementsModal,
    highlights,
    highlightsLoading
}: Props) => {
    const dispatch = useDispatch();
    const handleReload = () => {
        if (location === "main") {
            fetchHighlights(0);
        } else if (location === "campaign") {
            campaignId && fetchHighlights(campaignId);
        }
    };

    if (highlightsLoading)
        return (
            <HorizontalScrollOuter overflow="hidden">
                <HorizontalScrollInner>
                    <MediaLoading />
                    <MediaLoading />
                    <MediaLoading />
                    <MediaLoading />
                    <MediaLoading />
                    <MediaLoading />
                    <MediaLoading />
                    <MediaLoading />
                </HorizontalScrollInner>
            </HorizontalScrollOuter>
        );

    if (highlights === null)
        return (
            <>
                <PlacementSelector
                    openPlacementsModal={() =>
                        dispatch(highlightPlacementsModal())
                    }
                />
                <HorizontalScrollOuter overflow="hidden" topBorder="0px">
                    <HorizontalScrollInner>
                        <StatusCard>
                            <XCard
                                text="There are no highlights that match your placement selection."
                                button={{
                                    text: "Update",
                                    callback: highlightPlacementsModal
                                }}
                            />
                        </StatusCard>
                    </HorizontalScrollInner>
                </HorizontalScrollOuter>
            </>
        );

    if (!highlights)
        return (
            <HorizontalScrollOuter overflow="hidden">
                <HorizontalScrollInner>
                    <StatusCard>
                        <XCard
                            text="Failed to load highlights, click the button below to try again."
                            button={{
                                text: "Try again",
                                callback: handleReload
                            }}
                        />
                    </StatusCard>
                </HorizontalScrollInner>
            </HorizontalScrollOuter>
        );

    if (highlights.length)
        return <HighlightMediaList highlights={highlights} />;

    return (
        <HorizontalScrollOuter overflow="hidden">
            <HorizontalScrollInner>
                <StatusCard>
                    <XCard
                        text={emptyMessage}
                        button={{
                            text: "Try again",
                            callback: handleReload
                        }}
                    />
                </StatusCard>
            </HorizontalScrollInner>
        </HorizontalScrollOuter>
    );
};

export default HighlightsMediaRow;
