import React from "react";
import { PublicProfile, SocialAccount } from "../../../types";
import ViewHeader from "../../components/ViewHeader";
import Header from "../../../../components/Header";
import OwnerCell from "../../components/OwnerCell";
import PaddingNoTop from "../../../../components/PaddingNoTop";
import LockCard from "../../../components/LockCard";
import styled from "styled-components";
import { colors, maxWidthDesktop } from "src/constants";

const Owner = styled("section")`
    margin-top: 20px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;

    @media (max-width: ${maxWidthDesktop}px) {
        margin-top: 0px;
        border: ${colors.transparent} solid 1px;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

interface Props {
    account: SocialAccount;
    publicProfile: PublicProfile;
}

const PublicAccountView = ({ account, publicProfile }: Props) => {
    const ownersArray = [publicProfile];

    return (
        <>
            <ViewHeader account={account} />

            <Owner>
                <Header
                    title={`Owner${ownersArray.length > 1 ? "s" : ""}`}
                    subtitle={`${ownersArray.length} Account Owner${
                        ownersArray.length > 1 ? "s" : ""
                    }`}
                />

                {account.verified ? (
                    ownersArray.map(profile => {
                        return (
                            <OwnerCell
                                key={profile.id}
                                publicProfile={profile}
                            />
                        );
                    })
                ) : (
                    <PaddingNoTop>
                        <LockCard
                            text={
                                "The account owner has not completed the verification process"
                            }
                        />
                    </PaddingNoTop>
                )}
            </Owner>
        </>
    );
};

export default PublicAccountView;
