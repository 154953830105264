import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import HalfScreenModal from "src/modals/HalfScreenModal";
import styled from "styled-components";
import { colors, fonts } from "src/constants";

interface Props {
    onSelect: (value: boolean) => void;
    shareable: boolean;
}

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
`;

const Title = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 700;
    white-space: nowrap;
    padding-bottom: 20px;
`;

const SubTitle = styled.div`
    font-size: ${fonts.smallTitle}px;
    font-weight: 400;
    text-align: left;
    width: 100%;
    padding-left: 18px;
`;

const Option = styled.div`
    display: flex;
    flex-direction: row;
    font-size: ${fonts.subtitle}px;
    text-align: center;
    padding: 26px 20px 26px 20px;
    border-bottom: 1px solid ${colors.mediumTeal};
    cursor: pointer;
`;

const SelectButton = styled.div`
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    padding: 0px 16px 0px 16px;
    cursor: pointer;
    height: 45px;
    align-items: center;
    margin: 19px 12px 19px 12px;
`;

const SectionTitle = styled.div`
    font-size: 12px;
    font-weight: 700;
    color: ${colors.primaryGray};
    padding: 15px 15px 0px;
`;

const PlacementTitle = styled.div`
    font-size: 12px;
    margin-right: auto;
`;

const Caret = styled.img`
    width: 12px;
    margin-left: auto;
`;

const ShareSelector = ({ onSelect, shareable }: Props) => {
    const [open, setOpen] = useState(false);
    const options = [
        { value: true, name: "Usable" },
        { value: false, name: "Not Usable" }
    ];
    const modal = (
        <AnimatePresence>
            <HalfScreenModal closeModal={() => setOpen(false)}>
                <Header>
                    <Title>Sharing</Title>
                    <SubTitle>
                        Would you like other users to to be able to share your
                        media?
                    </SubTitle>
                </Header>
                {options.map((option, index) => (
                    <Option
                        key={`option${index}`}
                        onClick={() => {
                            onSelect(option.value);
                            setOpen(false);
                        }}
                    >
                        {option.name}
                    </Option>
                ))}
            </HalfScreenModal>
        </AnimatePresence>
    );

    return (
        <>
            <SectionTitle>SHARING</SectionTitle>
            <SelectButton onClick={() => setOpen(true)}>
                <PlacementTitle>
                    {shareable ? "Usable" : "Not Usable"}
                </PlacementTitle>
                <Caret src={"/caret.svg"} />
                {open && modal}
            </SelectButton>
        </>
    );
};

export default ShareSelector;
