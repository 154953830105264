import styled from "styled-components";
import { Shimmer } from "../../../components/SkeletonLoader";
import { colors, fonts } from "../../../constants";

export const Main = styled.div`
    padding: 18px;
    display: flex;
    align-items: center;
`;

const photoSize = 46;
const photoBorderRadius = 12;

export const Photo = styled.img`
    min-width: ${photoSize}px;
    width: ${photoSize}px;
    height: ${photoSize}px;
    border-radius: ${photoBorderRadius}px;
`;

export const PhotoLoading = styled.div`
    min-width: ${photoSize}px;
    width: ${photoSize}px;
    height: ${photoSize}px;
    border-radius: ${photoBorderRadius}px;
    ${Shimmer}
`;

export const MiddleContainer = styled.div`
    width: 100%;
    height: ${photoSize}px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-right: 12px;
    text-align: left;
    * + & {
        padding-left: 12px;
    }
`;

export const titleLineHeight = 16;
export const subtitleLineHeight = 14;

export const Title = styled.div`
    font-weight: 500;
    font-size: ${fonts.smallTitle}px;
    line-height: ${titleLineHeight}px;
`;

export const Subtitle = styled.div`
    font-size: 12px;
    line-height: ${subtitleLineHeight}px;
    color: ${colors.primaryGray};
`;

const tagHeight = 26;
const tagBorderRadius = 6;

export interface TagProps {
    backgroundColor: string;
}

export const Tag = styled.div<TagProps>`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    padding: 0 8px;
    color: white;
    display: flex;
    align-items: center;
    background: ${props => props.backgroundColor};
    height: ${tagHeight}px;
    border-radius: ${tagBorderRadius}px;
`;

export const TagLoading = styled.div`
    height: ${tagHeight}px;
    width: 60px;
    min-width: 60px;
    border-radius: ${tagBorderRadius}px;
    ${Shimmer}
`;
