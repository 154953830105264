import React from "react";
import { colors } from "src/constants";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-transform: capitalize;
`;

const Flag = styled.img`
    margin-right: 8px;
    height: 16px;
    width: 16px;
    transform: translateY(2px);
`;
const StatusCircle = styled.div<{ bgColor: string }>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
    margin-right: 8px;
`;

interface Props {
    status: string;
    color: "red" | "yellow" | "green";
    flagged?: boolean;
}

const StatusCell = ({ status, color, flagged }: Props) => {
    return (
        <Wrapper>
            {flagged && <Flag src="/warning.svg" />}
            <StatusCircle
                bgColor={
                    color === "yellow"
                        ? colors.primaryYellow
                        : color === "red"
                        ? colors.primaryRed
                        : color === "green"
                        ? colors.primaryGreen
                        : "transparent"
                }
            />
            {status}
        </Wrapper>
    );
};

export default StatusCell;
