import React from "react";
import SpinLoader from "src/components/SpinLoader";
import { maxWidthDesktop } from "src/constants";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 90vw;
    max-width: ${maxWidthDesktop}px;
    height: 90vh;
    max-height: 742px;
    position: relative;
    padding: 24px;
    overflow: visible;
    background-color: white;
    border-radius: 6px;
    display: grid;
    place-items: center;
`;

const ApprovalModalLoading = () => {
    return (
        <Wrapper>
            <SpinLoader size={60} />
        </Wrapper>
    );
};

export default ApprovalModalLoading;
