import React from "react";
import MainContainer from "src/components/MainContainer";
import {
    colors,
    fonts,
    fontWeight,
    shadows,
    sizes,
    spacing
} from "src/constants";
import { EventSubmit } from "src/utils/types/form";
import styled from "styled-components";
import PaymentSetsPageHeader from "../components/PaymentSetsPageHeader";
import { BlueButton } from "src/profile/components/Buttons";
import PaymentSetDetails from "../components/PaymentSetDetails";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPendingOpenBuyPaymentsGroups } from "../open-buy-payments/selectors";
import { GlobalState } from "../../../reducers";
import {
    replaceAllOpenBuyPayments,
    showEmptyPendingOpenBuyPaymentsGroup
} from "../open-buy-payments/actions";
import { validationMessageForPendingOpenBuyPaymentsGroup } from "../open-buy-payments/util";
import { setPillNotificationText } from "../../../ui/notifications/actions";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0px ${spacing.sideSpacing}px;
    text-align: left;
`;

const Main = styled(MainContainer)`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px 5px 15px;
    overflow-y: hidden;
`;

const Body = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${colors.secondaryLightGray};
    border-radius: 8px;
    box-shadow: ${shadows.one};
    overflow-y: hidden;
    margin-bottom: ${sizes.marketplace.creatorGroups.submissionFooter.height}px;
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    gap: 20px;

    position: relative;
    height: 100%;
    overflow-y: auto;
`;

const AddPaymentButton = styled(BlueButton)`
    padding: 8px 12px;
    width: 100%;
    font-weight: ${fontWeight.medium};
    font-size: ${fonts.semiSmallTitle}px;
    line-height: 16px;
`;

interface Props {
    openBuyId: number;
}

const PaymentSetsCreateOrEditPage = ({ openBuyId }: Props) => {
    const dispatch = useDispatch();

    const currentPendingOpenBuyPaymentsGroups = useSelector(
        getCurrentPendingOpenBuyPaymentsGroups
    );
    const { showEmpty } = useSelector(
        (state: GlobalState) => state.ui.openBuyPayments
    );

    const handleSubmit = (event: EventSubmit): void => {
        event.preventDefault();

        if (
            !currentPendingOpenBuyPaymentsGroups ||
            currentPendingOpenBuyPaymentsGroups.length === 0
        ) {
            dispatch(
                setPillNotificationText(
                    "Must create at least one payment",
                    "danger"
                )
            );
            return;
        }

        for (const group of currentPendingOpenBuyPaymentsGroups) {
            const validationMessage = validationMessageForPendingOpenBuyPaymentsGroup(
                group
            );
            if (validationMessage) {
                dispatch(
                    setPillNotificationText(validationMessage, "danger", 3500)
                );
                return;
            }
        }

        dispatch(
            replaceAllOpenBuyPayments({
                openBuyId,
                pendingOpenBuyPaymentsGroups: currentPendingOpenBuyPaymentsGroups
            })
        );
    };

    // Return JSX -----------------------------------------------------------
    return (
        <Container>
            <Main>
                <Body onSubmit={handleSubmit}>
                    <PaymentSetsPageHeader />
                    <DetailsContainer>
                        {currentPendingOpenBuyPaymentsGroups?.map(
                            (pendingOpenBuyPaymentsGroup, index) => {
                                return (
                                    <PaymentSetDetails
                                        key={
                                            pendingOpenBuyPaymentsGroup.instructionStepId
                                        }
                                        index={index}
                                        openBuyId={openBuyId}
                                        pendingOpenBuyPaymentsGroup={
                                            pendingOpenBuyPaymentsGroup
                                        }
                                    />
                                );
                            }
                        )}
                        {currentPendingOpenBuyPaymentsGroups?.length === 0 ||
                        showEmpty ? (
                            <PaymentSetDetails
                                key={"empty"}
                                index={
                                    currentPendingOpenBuyPaymentsGroups?.length ??
                                    0
                                }
                                openBuyId={openBuyId}
                                pendingOpenBuyPaymentsGroup={null}
                            />
                        ) : (
                            <AddPaymentButton
                                type="button"
                                onClick={() => {
                                    dispatch(
                                        showEmptyPendingOpenBuyPaymentsGroup()
                                    );
                                }}
                            >
                                Add Another Payment Step
                            </AddPaymentButton>
                        )}
                    </DetailsContainer>
                </Body>
            </Main>
        </Container>
    );
};

export default PaymentSetsCreateOrEditPage;
