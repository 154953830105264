import { Action } from "src/types";
import { combineReducers } from "redux";
import { SocialAccountProfile } from "src/social-accounts/utils/social-accounts.types";

import { FETCH_SOCIAL_ACCOUNT_PROFILE } from "./social-account-profile.action";

export interface SocialAccountProfileById {
    [id: number]: SocialAccountProfile;
}

export interface SocialAccountProfileEntitiesState {
    byId: SocialAccountProfileById;
    allIds: number[];
}

const socialAccountProfileInitialState: SocialAccountProfileEntitiesState = {
    byId: {},
    allIds: []
};

function byId(
    state = socialAccountProfileInitialState.byId,
    action: Action
): SocialAccountProfileById {
    switch (action.type) {
        case FETCH_SOCIAL_ACCOUNT_PROFILE.SUCCESS:
            return {
                ...state,
                ...action.payload.byId
            };

        default:
            return state;
    }
}

function allIds(
    state = socialAccountProfileInitialState.allIds,
    action: Action
): number[] {
    switch (action.type) {
        case FETCH_SOCIAL_ACCOUNT_PROFILE.SUCCESS:
            return [...state, ...action.payload.allIds];

        default:
            return state;
    }
}

const socialAccountProfileEntitiesReducer = combineReducers<
    SocialAccountProfileEntitiesState
>({
    byId,
    allIds
});

export default socialAccountProfileEntitiesReducer;
