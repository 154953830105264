import React, { useEffect, useRef, useState } from "react";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { selectCampaign } from "src/campaigns/selectors";
import ScrollArrows from "src/components/ScrollArrows";
import { colors } from "src/constants";
import { DropdownTitle } from "src/marketplace/utils/types";
import { GlobalState } from "src/reducers";
import { hideScrollbar } from "src/utils/styles/snippets";
import styled from "styled-components";
import { useGenerateItemsForCreateDropdown } from "../utils/hooks.create-instructions";

const Main = styled.article`
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    padding: 0px 10px;
`;

const DroppableArea = styled.div``;

const DropdownItemsCarousel = styled.section`
    display: flex;
    gap: 10px;
    padding-top: 8px;
    padding: 8px 0px 10px;
    color: ${colors.black};
    overflow-x: auto;
    ${props => props.theme.isMobile && hideScrollbar()};
`;

interface Props {
    title: DropdownTitle;
    searchString: string;
}

const CreateDropdownItems = ({ title, searchString }: Props) => {
    const { scripts, medias, assets } = useSelector((state: GlobalState) => {
        return state.entities;
    });

    const currentCampaign = useSelector(selectCampaign);

    const dropdownItems = useGenerateItemsForCreateDropdown(
        title,
        searchString,
        scripts,
        medias,
        assets,
        currentCampaign
    );

    const dropdownItemsCarouselRef = useRef<any>(null);

    return (
        <Main>
            <ScrollArrows refElement={dropdownItemsCarouselRef} />

            <Droppable
                droppableId={`CreateDropdownItemsDroppable-${title}`}
                direction="horizontal"
            >
                {(provided: DroppableProvided) => (
                    <DroppableArea
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        <DropdownItemsCarousel ref={dropdownItemsCarouselRef}>
                            {/* {title !== "Upload Submissions" && (
                                <CreateDropdownAddButton
                                    onClick={() =>
                                        console.log(
                                            `Add New ${turnPluralToSingular(
                                                title
                                            )}`
                                        )
                                    }
                                    text={turnPluralToSingular(title)}
                                />
                            )} */}

                            {dropdownItems}

                            {provided.placeholder}
                        </DropdownItemsCarousel>
                    </DroppableArea>
                )}
            </Droppable>
        </Main>
    );
};

export default CreateDropdownItems;
