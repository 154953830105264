import React from "react";
import { colors } from "src/constants";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 12px;
    color: ${colors.primaryBlue};
    cursor: pointer;
`;
const LittleImg = styled.img`
    display: block;
    height: 24px;
    width: 24px;
    object-fit: cover;
    margin-right: 8px;
    border-radius: 3px;
`;

interface Props {
    mediaUrl: string;
    handleClick: VoidFunction;
}

const MediaCell = ({ mediaUrl, handleClick }: Props) => {
    return (
        <Wrapper onClick={handleClick}>
            <LittleImg src={mediaUrl} alt="" />
            View Media
        </Wrapper>
    );
};

export default MediaCell;
