import React from "react";
import styled from "styled-components";

const Main = styled.div`
    position: absolute;
    bottom: 12px;
    left: 12px;
    right: 12px;
    display: flex;
`;

interface DotProps {
    selected: boolean;
}

const Dot = styled.div<DotProps>`
    flex: 1;
    height: 4px;
    border-radius: 2px;
    margin: 0 2px;
    background-color: white;
    opacity: ${props => (props.selected ? "0.9" : "0.4")};
`;

const ButtonGroup = (props: any) => {
    const currentSlide = props.carouselState.currentSlide;
    const totalSlides = props.carouselState.totalItems;

    const buttonData: boolean[] = [];

    for (let i = 0; i < totalSlides; i++) {
        buttonData.push(currentSlide === i);
    }

    return (
        <Main>
            {buttonData.map((selected, idx) => (
                <Dot key={idx} selected={selected} />
            ))}
        </Main>
    );
};

export default ButtonGroup;
