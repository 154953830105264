import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CarouselModal from "src/modals/CarouselModalNew";
import MediaItemModalContent from "./MediaItemModalContent";
import { CampaignMedia } from "src/campaigns/types";
import { setMediaItemModalOpen } from "src/ui/medias/actions";
import { GlobalState } from "src/reducers";
import CarouselInfo from "src/modals/CarouselInfo";
import styled from "styled-components";
import { flex, flexColumnCenter } from "src/utils/styles/snippets";
import MediaModalButtons from "src/campaigns/components/MediaModalButtons";
import { selectCampaign } from "src/campaigns/selectors";
import CampaignReward from "src/campaigns/details/components/CampaignReward";
import { breakpoints, colors } from "src/constants";
import { selectAdminStatus } from "src/auth/selectors";
import AdminMediaModal from "src/modals/AdminMediaModal";
import useResize from "src/hooks/useResize";
import PromoteFlowModal from "src/campaigns/promote/PromoteFlowModal";

const Main = styled.main`
    ${flex("column", undefined, "center")};
    gap: 24px;
    color: ${colors.white};
`;

const MediaItemModal = () => {
    const mediaItemModalOpen = useSelector((state: GlobalState) => {
        return state.ui.medias.mediaItemModalOpen;
    });

    const dispatch = useDispatch();

    function closeMediaItemModal(): void {
        dispatch(setMediaItemModalOpen(false));
    }

    const medias = useSelector((state: GlobalState) => {
        return state.entities.medias;
    });

    const currentLoadedMedia = useSelector((state: GlobalState) => {
        return state.ui.campaign.currentLoadedMedias;
    });

    const currentCampaign = useSelector(selectCampaign);

    const isAdmin = useSelector(selectAdminStatus);
    const { viewWidth } = useResize(mediaItemModalOpen);
    const isAdminAndDesktop = isAdmin && viewWidth > breakpoints.tablet;
    const postMediaModalOpen = useSelector(
        (state: GlobalState) => state.ui.medias.postMediaModalOpen
    );

    const currentCampaignMedia = useSelector(
        (state: GlobalState) => state.media.currentCampaignMedia
    );

    if (postMediaModalOpen && currentCampaign)
        return (
            <PromoteFlowModal
                campaign={currentCampaign}
                currentStep={"promote"}
                currentMedia={currentCampaignMedia}
            />
        );
    else
        return (
            <CarouselModal
                open={mediaItemModalOpen}
                closeModal={closeMediaItemModal}
                arrows={true}
                swipeable
                draggable={true}
                infinite
                keyBoardControl={true}
                noMaxWidth={isAdminAndDesktop ? true : undefined}
                isAdmin={isAdminAndDesktop}
            >
                {currentLoadedMedia.map((mediaId: number) => {
                    const currentMedia = medias[mediaId];
                    if (isAdminAndDesktop) {
                        return (
                            <AdminMediaModal
                                key={`${mediaId}`}
                                media={currentMedia}
                            />
                        );
                    }
                    return (
                        <Main key={`${mediaId}`}>
                            <CarouselInfo
                                appIconUrl={currentMedia.campaignImageUrl}
                                campaignName={currentMedia.campaignName}
                                amountComponent={
                                    currentCampaign && (
                                        <CampaignReward
                                            countryPlatformReward={
                                                currentCampaign.countryPlatformReward
                                            }
                                            rewardType={
                                                currentCampaign.rewardType
                                            }
                                            color={colors.white}
                                            noPlatform
                                        />
                                    )
                                }
                                mediaUrl={currentMedia.url}
                                mediaType={currentMedia.type}
                                isPortrait={currentMedia.isPortrait}
                                closeModal={closeMediaItemModal}
                            />

                            <MediaModalButtons media={currentMedia} />
                        </Main>
                    );
                })}
            </CarouselModal>
        );
};

export default MediaItemModal;
