import { colors, fonts, fontWeight } from "src/constants";
import styled from "styled-components";

// Containers ------------------------------------------------------

export const GreyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;

    width: 100%;
    background: ${colors.blueGrey};
    border: 0.25px solid ${colors.blueGrey2};
    border-radius: 8px;
`;

interface SectionProps {
    horizontal?: boolean;
}
export const Section = styled.div<SectionProps>`
    display: flex;
    flex-direction: ${p => (p.horizontal ? "row" : "column")};
    align-items: flex-start;
    width: 100%;
    padding: 0px;
    gap: 12px;
    position: relative;
`;

export const ErrorBox = styled.div<{ backgroundColor?: string }>`
    display: flex;
    justify-content: center;
    padding: 8px 12px;
    gap: 10px;
    width: 100%;
    min-height: 30px;
    background: ${props =>
        props.backgroundColor ? props.backgroundColor : `${colors.errorPink1}`};
    border-radius: 4px;
    cursor: pointer;
`;

// Text ------------------------------------------------------

export const Title = styled.h4`
    font-weight: ${fontWeight.medium};
    font-size: ${fonts.default}px;
    line-height: 19px;
    margin: 0px;
`;

export const SmallTitle = styled.p`
    font-weight: ${fontWeight.heavy};
    font-size: ${fonts.smallTitle}px;
    line-height: 14px;
    color: ${colors.primaryGray};
    margin: 0px;
    text-align: left;
`;

export const InfoText = styled(SmallTitle)<{ value?: boolean }>`
    font-weight: ${props =>
        (props.value && `${fontWeight.heavy}`) || `${fontWeight.medium}`};
    color: ${colors.black};
    padding-right: ${props => (props.value && "8px") || "0px"};
`;
