import React, { useEffect, useState } from "react";
import withAccordion from "src/campaigns/details/accordions/withAccordion";
import ModalList from "src/components/modals/components/ModalList";
import Platforms from "src/social-accounts/Platforms";
import styled from "styled-components";
import { colors } from "src/constants";
import ModalBox from "src/components/modals/components/ModalBox";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { selectMarketplaceOpenBuy } from "src/marketplace/redux/selectors";
import { ReactComponent as Caret } from "src/components/icons/caret-small.svg";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import {
    OpenBuySubmission,
    OpenBuySubmissionSet
} from "src/buys/modules/open-buys/types";
import { get } from "src/Api";
import LinkInBio from "./LinkInBio";

const Wrapper = styled.div`
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const Circle = styled.div`
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: ${colors.black};
    display: inline-block;
    margin-left: 8px;
    display: grid;
    place-items: center;
`;
const StyledCaret = styled(Caret)`
    transform: rotate(-90deg);
`;
const OpenExternalLink = styled.div`
    ${FlexVerticallyCentered}
    padding: 4px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 12px;
    cursor: pointer;
    user-select: none;
`;
const Text = styled.div`
    font-size: 12px;
    color: ${colors.primaryGray};
`;

export interface EmployeeLink {
    name: string;
    type: string;
    reppedPublisherId: number;
}

const PublisherDetailsAccordion = ({
    submissionSet
}: {
    submissionSet: OpenBuySubmissionSet;
}) => {
    const openBuy = useSelector((state: GlobalState) =>
        selectMarketplaceOpenBuy(state, submissionSet.openBuyId)
    );

    const [employeeLink, setEmployeeLink] = useState<EmployeeLink | null>(null);
    const [
        currentSubmisison,
        setCurrentSubmission
    ] = useState<OpenBuySubmission | null>(null);

    const fetchEmployeeLink = async () => {
        try {
            let res = await get(
                `/v1/admin/publisher/${submissionSet.publisherId}/employeeLink`,
                {}
            );

            setEmployeeLink(res.data.data[0]);
        } catch (err) {
            console.error("error getting employee link: ", err);
        }
    };

    useEffect(() => {
        if (!employeeLink) fetchEmployeeLink();
    }, [submissionSet]);

    const listItems = submissionSet.socialAccount
        ? Object.entries(submissionSet.socialAccount).reduce(
              (acc: any[], [key, val]) => {
                  switch (key) {
                      case "id":
                          return [
                              ...acc,
                              { label: "ID", type: "id", value: val }
                          ];
                      case "username":
                          return [
                              ...acc,
                              { label: "Username", type: "string", value: val }
                          ];
                      case "platform":
                          return [
                              ...acc,
                              { label: "Platform", type: "string", value: val }
                          ];
                      case "verified":
                          return [
                              ...acc,
                              { label: "Verified", type: "boolean", value: val }
                          ];
                      default:
                          return acc;
                  }
              },
              []
          )
        : [];

    const platform = submissionSet?.socialAccount?.platform
        ? Platforms[submissionSet.socialAccount.platform]
        : null;

    const handleViewAccount = () => {
        if (platform && submissionSet?.socialAccount?.username) {
            const urlToOpen = platform.urlCallback(
                submissionSet.socialAccount.username.toLowerCase()
            );

            if (urlToOpen) {
                window.open(urlToOpen);
            }
        }
    };
    return (
        <Wrapper>
            {submissionSet?.socialAccount ? (
                <ModalList
                    cornerBtns={
                        <OpenExternalLink onClick={handleViewAccount}>
                            <div>Open TikTok</div>
                            <Circle>
                                <StyledCaret />
                            </Circle>
                        </OpenExternalLink>
                    }
                    listItems={listItems}
                    header="SOCIAL ACCOUNT"
                />
            ) : (
                <ModalBox headingText="SOCIAL ACCOUNT">
                    <Text>
                        <em>Social Account Missing</em>
                    </Text>
                </ModalBox>
            )}
            {employeeLink ? (
                <ModalList
                    listItems={[
                        {
                            type: "string",
                            label: employeeLink.type
                                .replace("_", " ")
                                .split(" ")
                                .map(
                                    (word: string) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                )
                                .join(" "),
                            value: employeeLink.name
                        }
                    ]}
                    header="SOCIAL ACCOUNT"
                />
            ) : (
                <ModalBox headingText="EMPLOYEE LINK">
                    <Text>
                        <em>No employee linked to this publisher</em>
                    </Text>
                </ModalBox>
            )}
            <LinkInBio submissionSet={submissionSet} />
        </Wrapper>
    );
};

export default withAccordion(PublisherDetailsAccordion);
