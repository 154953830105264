import { motion } from "framer-motion";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { colors } from "src/constants";
import { BigBlueButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import {
    OpenBuyState,
    OpenBuySubmission,
    OpenBuySubmissionStatuses
} from "../modules/open-buys/types";
import {
    getMostRelevantSubmission,
    getStateForOpenBuy,
    getStatusColor,
    getStatusText
} from "../modules/open-buys/util";
import {
    selectOpenBuysById,
    selectOpenBuyStepEntities,
    selectOpenBuySubmissions,
    selectYourSets
} from "../selectors";
import DirectBuyDetailsPaymentBrief from "../details/instructions/DirectBuyDetailsPaymentBrief";
import ProgressBar from "src/components/ProgressBar";
import { SetStatusIndicator } from "../details/submissions/OpenBuySubmissionSetForm";

const Main = styled(motion.div)`
    position: sticky;
    bottom: 0;
    z-index: 998;
    width: 100%;
    padding: 12px 18px 20px 18px;
    background-color: white;
    user-select: none;
    border-top: 1px solid ${colors.mediumTeal};
`;

const InnerContainer = styled.div`
    max-width: 910px;
    margin: 0 auto;
`;

const StatusRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
`;

const StatusText = styled.div`
    margin: auto 6px;
    font-size: 12px;
    text-transform: capitalize;
`;
const IndicatorImg = styled.img`
    width: inherit;
    height: inherit;
`;
const Status = styled.div<{ color?: string }>`
    border-radius: 100px;
    background-color: ${props => props.color};
    padding: 3px 6px;
    display: flex;
    align-items: center;
`;

interface Props {
    openBuyId: number;
    createSet: VoidFunction;
}

const OpenBuyDetailsFooter = ({ openBuyId, createSet }: Props) => {
    const openBuy = useSelector(selectOpenBuysById)[openBuyId];
    const submissions = useSelector(selectOpenBuySubmissions);
    const yourSubmissions = openBuy?.openBuySubmissions || [];

    const sets = useSelector(
        (state: GlobalState) => state.entities.openBuySubmissionSets
    );
    const yourSets = useSelector((state: GlobalState) =>
        selectYourSets(state, openBuy.id)
    );
    const steps = useSelector(selectOpenBuyStepEntities);
    const state = getStateForOpenBuy(
        openBuy,
        submissions.filter(submission =>
            yourSubmissions.includes(submission.id)
        )
    );
    const submission:
        | Partial<OpenBuySubmission>
        | undefined = getMostRelevantSubmission(
        yourSets.map(id => sets[id]),
        submissions
    );

    if (state === OpenBuyState.ended || state === OpenBuyState.rejected)
        return null;
    return (
        <Main
            initial={{ y: 73, opacity: 1 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 73, opacity: 1 }}
            transition={{
                type: "spring",
                mass: 1,
                stiffness: 170,
                damping: 26
            }}
        >
            <InnerContainer>
                {submission && (
                    <>
                        <StatusRow>
                            <Status
                                color={getStatusColor(
                                    submission,
                                    undefined,
                                    true
                                )}
                            >
                                <SetStatusIndicator
                                    status={submission?.status}
                                    height="12px"
                                >
                                    {submission?.status ===
                                    OpenBuySubmissionStatuses.approved ? (
                                        <IndicatorImg src="/approved-status.svg" />
                                    ) : submission?.status ===
                                      OpenBuySubmissionStatuses.pending ? (
                                        <IndicatorImg src="/pending-status.svg" />
                                    ) : submission?.status ===
                                          OpenBuySubmissionStatuses.revision_requested ||
                                      submission?.status ===
                                          OpenBuySubmissionStatuses.rejected ? (
                                        <IndicatorImg src="/rejected-status.svg" />
                                    ) : null}
                                </SetStatusIndicator>
                                <StatusText>
                                    {getStatusText(submission)}
                                </StatusText>
                            </Status>
                            {submission.instructionStepId && (
                                <DirectBuyDetailsPaymentBrief
                                    parentStep={
                                        steps[submission.instructionStepId]
                                    }
                                />
                            )}
                        </StatusRow>
                        {yourSets.length === 1 && (
                            <ProgressBar
                                thinner={true}
                                color={getStatusColor(submission)}
                                style={{ marginBottom: "12px" }}
                                progress={
                                    ((sets[yourSets[0]].submissions?.length -
                                        1) /
                                        openBuy.submissionSteps?.length) *
                                    100
                                }
                            />
                        )}
                    </>
                )}
                <BigBlueButton onClick={createSet}>
                    {!submission ? "Enter Challenge" : "View Submission"}
                </BigBlueButton>
            </InnerContainer>
        </Main>
    );
};
export default OpenBuyDetailsFooter;
