import React from "react";
import styled from "styled-components";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    DropResult,
    Droppable
} from "react-beautiful-dnd";
import { colors } from "src/constants";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import MarketplaceTextarea from "src/marketplace/components/MarketplaceTextarea";
import { BorderlessButton } from "src/profile/components/Buttons";
import { EventInput } from "src/utils/types/form";
import { RuleState } from "src/types/challenge/Rule";

const SectionHeader = styled.div`
    padding: 12px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
`;

const Section = styled.div`
    & > * ~ * {
        border-top: 1px solid ${colors.mediumTeal};
    }
`;

const Item = styled.div`
    ${FlexVerticallyCentered};
    padding: 12px 0px;
    background-color: ${colors.white};
`;

const DragIndicator = styled.img`
    width: 12px;
    height: 12px;
    margin-right: 12px;
`;

const Emoji = styled.div`
    font-size: 12px;
    margin-right: 18px;
`;

const TextAreaWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const TextArea = styled(MarketplaceTextarea)`
    width: 100%;
`;

const RemoveButton = styled(BorderlessButton)`
    margin-left: 12px;
`;

interface Props {
    title: string;
    ruleIds: string[];
    emoji: string;
    droppableId: string;
    normalizedRules: RuleState;
    editTitle?: boolean;
    handleUpdateRuleFactory: (
        ruleId: string,
        targetKey: "title" | "rule"
    ) => (e: EventInput) => void;
    handleDeleteRuleFactory: (ruleId: string) => VoidFunction;
    setRuleIds: (ruleIds: string[]) => void;
    setHasRuleChanges: (changes: boolean) => void;
}

const ChallengeRulesDragAndDrop = ({
    title,
    ruleIds,
    emoji,
    droppableId,
    normalizedRules,
    editTitle,
    setHasRuleChanges,
    handleDeleteRuleFactory,
    handleUpdateRuleFactory,
    setRuleIds
}: Props) => {
    return (
        <DragDropContext
            onDragEnd={(result: DropResult) => {
                const { destination, draggableId, source } = result;
                if (destination?.droppableId === droppableId) {
                    const newIds = [...ruleIds];
                    const removed = newIds.splice(source.index, 1)[0];
                    newIds.splice(destination.index, 0, removed);
                    setRuleIds(newIds);
                    setHasRuleChanges(true);
                }
            }}
        >
            <div>
                <SectionHeader>{title}</SectionHeader>
                <Droppable direction="vertical" droppableId={droppableId}>
                    {(provided, snapshot) => (
                        <Section
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {ruleIds.map((ruleId, index) => (
                                <Draggable
                                    key={ruleId}
                                    draggableId={ruleId.toString()}
                                    index={index}
                                >
                                    {(
                                        provided: DraggableProvided,
                                        snapshot: DraggableStateSnapshot
                                    ) => (
                                        <Item
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <DragIndicator src="/drag_indicator.svg" />
                                            <Emoji>{emoji}</Emoji>
                                            <TextAreaWrapper>
                                                {editTitle && (
                                                    <TextArea
                                                        value={
                                                            normalizedRules[
                                                                ruleId
                                                            ]?.title
                                                        }
                                                        handleChange={handleUpdateRuleFactory(
                                                            ruleId,
                                                            "title"
                                                        )}
                                                    />
                                                )}
                                                <TextArea
                                                    value={
                                                        normalizedRules[ruleId]
                                                            ?.rule
                                                    }
                                                    handleChange={handleUpdateRuleFactory(
                                                        ruleId,
                                                        "rule"
                                                    )}
                                                    placeholder="Please Input rule"
                                                />
                                            </TextAreaWrapper>
                                            <RemoveButton
                                                type="button"
                                                onClick={handleDeleteRuleFactory(
                                                    ruleId
                                                )}
                                            >
                                                Remove
                                            </RemoveButton>
                                        </Item>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Section>
                    )}
                </Droppable>
            </div>
        </DragDropContext>
    );
};
export default ChallengeRulesDragAndDrop;
