import React, { useEffect, useState } from "react";
import SimpleNavbar from "src/components/SimpleNavbar";
import { ReactComponent as Cross } from "src/components/icons/cross.svg";
import FullScreenModal from "src/modals/FullScreenModal";
import styled from "styled-components";
import { colors, fonts } from "src/constants";
import { BigBlueButton } from "src/profile/components/Buttons";
import {
    FlexContent,
    FlexVerticallyCentered
} from "src/components/CommonStyles";
import SearchBar from "src/components/SearchBar";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { getCampaignsWithSearch } from "../selectors";
import CoinReward from "src/components/CoinReward";
import CountryPlatformCell from "src/components/CountryPlatformCell";
import CheckBox from "src/components/CheckBox";
import { useInView } from "react-intersection-observer";

const Main = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
`;

const Content = styled(FlexContent)`
    padding: 10px 18px;
`;

const CrossIcon = styled(Cross)`
    cursor: pointer;
`;

const ConfirmButtonWrapper = styled.div`
    border-top: 1px solid ${colors.mediumTeal};
    padding: 12px 18px 24px;
`;

const ConfirmButton = styled(BigBlueButton)`
    font-weight: 500;

    &:disabled {
        opacity: 0.5;
    }
`;

const List = styled.div`
    margin-top: 10px;
    position: relative;
    & > * ~ * {
        margin-top: 10px;
    }
`;

const CampaignCell = styled.div`
    ${FlexVerticallyCentered}
    cursor: pointer;
    user-select: none;
`;

const Reward = styled.div`
    ${FlexVerticallyCentered}
`;

const Title = styled.div`
    font-weight: 500;
    font-size: ${fonts.smallTitle}px;
    margin-bottom: 2px;
`;

const AppIcon = styled.img`
    width: 46px;
    height: 46px;
    border-radius: 12px;
    margin-right: 12px;
`;

const Observer = styled.div`
    position: absolute;
    bottom: min(200px, 100%);
`;

export interface SelectedCampaigns {
    [id: number]: string | boolean;
}

interface Props {
    closeModal: () => void;
    onSubmit: (selectedCampaigns: SelectedCampaigns) => void;
    initialSelection?: SelectedCampaigns;
}

const CampaignSelectionModal = ({
    closeModal,
    onSubmit,
    initialSelection = {}
}: Props) => {
    const [maxItems, setMaxItems] = useState(10);
    const [ref, inView] = useInView();
    const [searchString, setSearchString] = useState("");
    const [selectedCampaigns, setSelectedCampaigns] = useState<
        SelectedCampaigns
    >(initialSelection);

    function generateSetSelectedCampaigns(campaignId: number, value: string) {
        return () =>
            setSelectedCampaigns({
                ...selectedCampaigns,
                [campaignId]: selectedCampaigns[campaignId] ? false : value
            });
    }

    const campaigns = useSelector((state: GlobalState) =>
        getCampaignsWithSearch(state, searchString, maxItems)
    );

    const campaignsLength = useSelector(
        (state: GlobalState) => state.entities.campaigns.allIds.length
    );

    useEffect(() => {
        if (inView && maxItems < campaignsLength) {
            setMaxItems(maxItems + 10);
        }
    }, [inView, campaignsLength]);

    return (
        <>
            <FullScreenModal keepFrozenOnClose closeModal={closeModal}>
                <Main>
                    <SimpleNavbar
                        title="Pick Campaigns"
                        left={
                            <CrossIcon
                                fill={colors.primaryGray}
                                onClick={closeModal}
                            />
                        }
                    />
                    <Content>
                        <SearchBar
                            searchString={searchString}
                            updateSearchString={setSearchString}
                        />
                        <List>
                            {campaigns.map(
                                ({
                                    id,
                                    imageUrl,
                                    campaignName,
                                    rewardType,
                                    countryPlatformReward: {
                                        reward,
                                        country,
                                        platform
                                    }
                                }) => (
                                    <CampaignCell
                                        key={id}
                                        onClick={generateSetSelectedCampaigns(
                                            id,
                                            campaignName
                                        )}
                                    >
                                        <AppIcon src={imageUrl} />
                                        <div>
                                            <Title>{campaignName}</Title>
                                            <Reward>
                                                <CoinReward
                                                    reward={reward}
                                                    type={rewardType}
                                                    size={14}
                                                    textSize={12}
                                                />
                                                <CountryPlatformCell
                                                    country={country}
                                                    platform={platform}
                                                    size={16}
                                                />
                                            </Reward>
                                        </div>
                                        <CheckBox
                                            selected={!!selectedCampaigns[id]}
                                        />
                                    </CampaignCell>
                                )
                            )}
                            <Observer ref={ref} />
                        </List>
                    </Content>
                    <ConfirmButtonWrapper>
                        <ConfirmButton
                            onClick={() => {
                                onSubmit(selectedCampaigns);
                                closeModal();
                            }}
                        >
                            Done
                        </ConfirmButton>
                    </ConfirmButtonWrapper>
                </Main>
            </FullScreenModal>
        </>
    );
};

export default CampaignSelectionModal;
