import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "src/Api";
import Header from "src/components/Header";
import SpinLoader from "src/components/SpinLoader";
import { colors } from "src/constants";
import ShadeScreen from "src/media/components/ShadeScreen";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import { fetchPublicBuys } from "../redux/actions";
import { PBPreview } from "../redux/reducer";
import { selectPublicBuys } from "../redux/selectors";
import PublicBuyPreview from "./PublicBuyPreview";
import { PBData } from "./PublicBuys";
import PublicBuysFilters, { PublicBuysFilter } from "./PublicBuysFilters";
import PublicBuysModal from "./PublicBuysModal";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 83vh;
    background: ${colors.quaternaryLightGray};
    border-radius: 16px;
    padding: 16px 10px;
`;

const BuysContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
    gap: 8px;
    border-radius: 16px;
    padding: 10px;
    justify-content: center;
`;

const LoaderContainer = styled.div`
    position: fixed;
    z-index: 900;
    height: 75vh;
    width: 65vw;
    margin-top: 80px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.2);
`;

const initialFilters = [
    { name: "campaign", values: [] },
    { name: "date", values: [] },
    { name: "account", values: [] },
    { name: "Show Completed", values: [], type: "checkbox" }
];

interface Props {
    publisher: PBData;
}

const PublicBuysSelector = ({ publisher }: Props) => {
    const [filters, setFilters] = useState<PublicBuysFilter[]>(initialFilters);
    const [search, setSearch] = useState("");
    const [selected, setSelected] = useState<number>();

    const dispatch = useDispatch();

    const buys = useSelector(selectPublicBuys);
    const loading = useSelector(
        (state: GlobalState) => state.publicBuys.loading
    );

    useEffect(() => {
        dispatch(fetchPublicBuys(search, filters));
    }, [publisher, search, filters]);

    return (
        <Main>
            <Header
                title={"Direct Buys"}
                subtitle=""
                desktop={true}
                links={[
                    {
                        text: "Select",
                        onClick: () => console.log("upload")
                    }
                ]}
                sidePadding={16}
            />
            <Container>
                <PublicBuysFilters
                    filters={filters}
                    search={search}
                    updateSearch={string => setSearch(string)}
                    setFilters={setFilters}
                    buys={buys}
                />
                {loading && (
                    <LoaderContainer>
                        <SpinLoader color="white" size={60} />
                    </LoaderContainer>
                )}
                <BuysContainer>
                    {buys.map((buy, index) => (
                        <PublicBuyPreview
                            key={`${index} buy`}
                            media={buy}
                            onClick={() => setSelected(index)}
                            index={index}
                        />
                    ))}
                </BuysContainer>
            </Container>
            {selected !== undefined && (
                <PublicBuysModal
                    onClose={() => {
                        setSelected(undefined);
                    }}
                    buy={buys[selected]}
                />
            )}
        </Main>
    );
};
export default PublicBuysSelector;
