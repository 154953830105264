import React from "react";
import styled from "styled-components";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "../../components/CommonStyles";
import { fonts } from "src/constants";

const Main = styled.div`
    ${FlexVerticallyCentered}
    margin-top: 8px;

    & > * ~ * {
        margin-left: 4px;
    }
`;

const Text = styled.div`
    font-size: ${fonts.smallSubtitle}px;
`;

const IconWrapper = styled.div`
    ${FlexCentered};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
`;

const BWIcon = styled.img`
    width: 12px;
    height: 12px;
`;

interface Props {
    icon: string;
    text: string;
}

const HighlightMetricItem = ({ icon, text }: Props) => (
    <Main>
        <IconWrapper>
            <BWIcon src={icon} />
        </IconWrapper>
        <Text>{text}</Text>
    </Main>
);

export default HighlightMetricItem;
