import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import SmallModal from "src/modals/SmallModal";
import { BlueButton } from "src/profile/components/Buttons";
import { OpenBuySubmission } from "src/buys/modules/open-buys/types";
import { patch } from "src/Api";
import { colors } from "src/constants";
import RichTextPreview from "./RichTextPreview";
import { setPillNotificationText } from "src/ui/notifications/actions";

const SubmitButton = styled(BlueButton)`
    height: 32px;
    margin-left: auto;
    margin-top: 16px;
`;

const Message = styled.div`
    border-bottom: 1px solid ${colors.blueGrey2};
    padding-bottom: 16px;
`;

const HeaderText = styled.div`
    text-align: left;
    font-size: 12px;
    font-weight: 700;
    color: ${colors.gray2};
    margin-bottom: 8px;
`;
const SubText = styled.div`
    margin-top: 16px;
    text-align: left;
    font-size: 14px;
    color: ${colors.gray2};
`;

const AdvertiserReviewRequestModal = ({
    message,
    submission,
    getSubmission,
    handleClose
}: {
    message: string;
    submission: OpenBuySubmission;
    getSubmission: VoidFunction;
    handleClose: VoidFunction;
}) => {
    const dispatch = useDispatch();

    const requestAdvertiserReview = async () => {
        try {
            await patch(
                `/v1/openBuySubmission/${submission.id}/advertiserReviewRequest`,
                {
                    advertiserReviewRequest: message
                }
            );
            getSubmission();
            dispatch(
                setPillNotificationText(
                    "Advertiser review request sent successfully",
                    "success",
                    3500
                )
            );
        } catch (error) {
            console.log(error);
            dispatch(
                setPillNotificationText(
                    "Advertiser review request failed",
                    "danger",
                    3500
                )
            );
        }
    };

    return (
        <SmallModal
            header={{
                text: "Request Advertiser Review",
                handleClose
            }}
        >
            <Message>
                <HeaderText>ADVERTISER MESSAGE</HeaderText>
                <RichTextPreview text={message} />
            </Message>
            <SubText>
                Your review request will be sent to the advertiser, and the
                submission will appear as pending on their dashboard.
            </SubText>
            <SubmitButton onClick={requestAdvertiserReview}>
                Request Review
            </SubmitButton>
        </SmallModal>
    );
};

export default AdvertiserReviewRequestModal;
