import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
    clearButton,
    hideScrollbar,
    primaryBlueBackgroundHoverState
} from "src/utils/styles/snippets";
import { creatorGroupsFilterTypes } from "../utils/constants";
import SelectionHeader from "src/marketplace/components/SelectionHeader";
import { useDispatch } from "react-redux";
import SearchBar from "src/components/SearchBar";
import {
    resetAudienceAgeFilters,
    resetAudienceCountryRegionFilters,
    resetAudienceDeviceFilters,
    resetAudienceGenderFilters,
    resetAverageViewsFilters,
    resetInterestsFilters,
    resetCreatorCountryRegionFilters,
    resetCreatorsFilters,
    resetEngagementRateFilters,
    resetFollowersFilters,
    resetPlatformFilters,
    resetThemesFilters
} from "src/ui/creators/actions";
import { FilterType } from "../utils/types";
import PlatformFilterOptions from "./PlatformFilterOptions";
import CountryRegionFilterOptions from "./CountryRegionFilterOptions";
import SingleSelectionFilters from "./SingleSelectionFilters";
import MultiSelectionFilters from "./MultiSelectionFilters";
import { colors } from "src/constants";
import RangeSlider from "src/components/RangeSlider";

interface MainProps {
    spaceBetween?: true;
    padding?: string;
}

const Main = styled.section<MainProps>`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.spaceBetween && "space-between"};
    flex-shrink: 0;
    width: 100%;
    /* padding: ${props => props.padding || "15px"}; */
`;

const FilterOptionsContainer = styled.div`
    height: 100%;
    overflow-y: auto;
`;

interface FilterOptionsProps {
    applyPadding?: true;
    scroll?: true;
}

const FilterOptions = styled.article<FilterOptionsProps>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 15px;

    ${props =>
        props.scroll &&
        css`
            overflow-y: auto;
        `};

    ${props => props.theme.isMobile && hideScrollbar()};
`;

const ResetFilterButtonContainer = styled.div`
    padding: 15px;
    border-top: ${colors.mediumTeal} solid 1px;
`;

const ResetFiltersButton = styled.button`
    ${clearButton()};
    ${primaryBlueBackgroundHoverState()};
    width: 100%;
    padding: 12px 16px;
    color: white;
    border-radius: 6px;
`;

interface Props {
    page: number;
    closeModal: VoidFunction;
    filterType: FilterType | "";
    setFilterType: React.Dispatch<React.SetStateAction<"" | FilterType>>;
}

const CreatorsFiltersModalBody = ({
    page,
    closeModal,
    filterType,
    setFilterType
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Local State ---------------------------------------------------
    const [searchString, setSearchString] = useState("");

    // Local Functions -----------------------------------------------
    function resetAllFilters(): void {
        dispatch(resetCreatorsFilters());
        setFilterType("");
        closeModal();
    }

    function dispatchResetAction(): void {
        if (filterType !== "") {
            if (filterType === "Platform") {
                dispatch(resetPlatformFilters());
            } else if (filterType === "Interests") {
                dispatch(resetInterestsFilters());
            } else if (filterType === "Themes") {
                dispatch(resetThemesFilters());
            } else if (filterType === "Creator Country/Region") {
                dispatch(resetCreatorCountryRegionFilters());
            } else if (filterType === "Audience Country/Region") {
                dispatch(resetAudienceCountryRegionFilters());
            } else if (filterType === "Followers") {
                dispatch(resetFollowersFilters());
            } else if (filterType === "Average Views") {
                dispatch(resetAverageViewsFilters());
            } else if (filterType === "Engagement Rate") {
                dispatch(resetEngagementRateFilters());
            } else if (filterType === "Audience Gender") {
                dispatch(resetAudienceGenderFilters());
            } else if (filterType === "Audience Age") {
                dispatch(resetAudienceAgeFilters());
            } else {
                dispatch(resetAudienceDeviceFilters());
            }
        }
        setFilterType("");
        closeModal();
    }

    // Effects -------------------------------------------------------
    useEffect(() => {
        if (filterType === "") setSearchString("");
    }, [filterType]);

    // JSX -----------------------------------------------------------
    if (page === 1) {
        return (
            <Main key={`creators filters modal filter type selection ${page}`}>
                <FilterOptionsContainer>
                    <FilterOptions scroll>
                        {creatorGroupsFilterTypes.map(
                            (filter: FilterType, index: number) => {
                                return (
                                    <SelectionHeader
                                        key={`creators filters modal body page 1 ${index} ${filter}`}
                                        onClick={() => setFilterType(filter)}
                                        title={filter}
                                        selected={false}
                                    />
                                );
                            }
                        )}
                    </FilterOptions>
                </FilterOptionsContainer>
                <ResetFilterButtonContainer>
                    <ResetFiltersButton onClick={resetAllFilters}>
                        Reset All Filters
                    </ResetFiltersButton>
                </ResetFilterButtonContainer>
            </Main>
        );
    } else {
        return (
            <Main
                key={`creators filters modal filter options selection ${page}`}
                padding="0px"
            >
                <SearchBar
                    searchString={searchString}
                    updateSearchString={setSearchString}
                    autoFocus={false}
                    wrapped
                />

                {/* {filterType === "Followers" ||
                filterType === "Average Views" ||
                filterType === "Engagement Rate" ? (
                    <RangeSlider
                        padding="0px 15px"
                        initialMinimumValue={0}
                        minimumNextText={
                            filterType === "Engagement Rate"
                                ? `% ${filterType}`
                                : ` ${filterType}`
                        }
                        maximumValue={100000000}
                        maximumNextText={
                            filterType === "Engagement Rate"
                                ? `% ${filterType}`
                                : ` ${filterType}`
                        }
                    />
                ) : null} */}

                <FilterOptionsContainer>
                    <FilterOptions applyPadding>
                        {filterType === "Platform" ? (
                            <PlatformFilterOptions
                                searchString={searchString}
                            />
                        ) : filterType === "Creator Country/Region" ||
                          filterType === "Audience Country/Region" ? (
                            <CountryRegionFilterOptions
                                searchString={searchString}
                                filterType={filterType}
                            />
                        ) : filterType === "Followers" ||
                          filterType === "Average Views" ||
                          filterType === "Engagement Rate" ||
                          filterType === "Audience Gender" ||
                          filterType === "Audience Age" ||
                          filterType === "Audience Device" ? (
                            <SingleSelectionFilters
                                searchString={searchString}
                                singleSelectionFilterType={filterType}
                            />
                        ) : (
                            <MultiSelectionFilters
                                searchString={searchString}
                                multiSelectionFilterType={filterType}
                            />
                        )}
                    </FilterOptions>
                </FilterOptionsContainer>

                <ResetFilterButtonContainer>
                    <ResetFiltersButton onClick={dispatchResetAction}>
                        Reset Filters
                    </ResetFiltersButton>
                </ResetFilterButtonContainer>
            </Main>
        );
    }
};

export default CreatorsFiltersModalBody;
