import React, { ChangeEvent } from "react";
import TextareaAutosize from "react-textarea-autosize";

interface Props {
    placeholder: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus: VoidFunction;
    onBlur: VoidFunction;
}

export default ({ placeholder, value, onChange, onFocus, onBlur }: Props) => {
    const style: any = {
        display: "block",
        width: "100%",
        resize: "none",
        border: "none",
        outline: "none",
        padding: "0",
        fontSize: "14px",
        lineHeight: "17px"
    };

    return (
        <TextareaAutosize
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            style={style}
        />
    );
};
