import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { colors, fonts } from "src/constants";
import { InstructionElementArrayKey } from "src/marketplace/utils/types";
import { GlobalState } from "src/reducers";
import {
    InstructionStepState,
    setInstructionSteps
} from "src/ui/instructions-sets/actions";
import {
    clearSpacing,
    hideTextOverflow,
    lightGrayBackgroundHoverState
} from "src/utils/styles/snippets";
import styled, { css } from "styled-components";

interface ItemCarouselElementProps {
    shouldRenderScript: boolean;
}

const ItemsCarouselElement = styled.article<ItemCarouselElementProps>`
    position: relative;
    flex-shrink: 0;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 4px;
    overflow: hidden;
    cursor: default;

    overflow: auto;

    ${props => {
        if (props.shouldRenderScript) {
            return css`
                display: flex;
                justify-content: space-between;
                /* align-items: center; */
                align-items: flex-start;
                gap: 12px;
                flex: 1;
                padding: 12px;
                background-color: ${colors.white};
                border-radius: 6px;
            `;
        }
    }};
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: calc(100% - 28px);
`;

const Title = styled.h3`
    ${clearSpacing()};
    ${hideTextOverflow()};
    ${lightGrayBackgroundHoverState()};
    flex-shrink: 0;
    width: 100%;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: ${fonts.subtitle}px;
    cursor: pointer;
`;

const Subtitle = styled.p`
    ${clearSpacing()};
    ${hideTextOverflow()};
    font-size: ${fonts.smallTitle}px;
`;

const DeleteButton = styled.img<ItemCarouselElementProps>`
    width: 16px;
    height: 16px;
    opacity: 0.5;
    cursor: pointer;

    ${props => {
        if (!props.shouldRenderScript) {
            return css`
                position: absolute;
                top: 4px;
                right: 4px;
                z-index: 100;
            `;
        }
    }}
`;

interface Props {
    isScript?: true;
    title?: string;
    subtitle?: string;
    itemId: number;
    item?: JSX.Element;
    instructionSteps: InstructionStepState[];
    currentInstructionStepIndex: number;
    currentInstructionStepKey: InstructionElementArrayKey;

    itemIndex: number;
}

const InstructionsFormDragStepAreaItem = ({
    isScript,
    title,
    subtitle,
    itemId,
    item,
    instructionSteps,
    currentInstructionStepIndex,
    currentInstructionStepKey,

    itemIndex
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const pageType = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets.instructionsPageType;
    });

    // Local State ---------------------------------------------------
    const [scriptExpanded, setScriptExpanded] = useState(false);

    // Local Constants -----------------------------------------------
    const shouldRenderScript = (isScript && !!title && !!subtitle) || false;

    // Local Functions -----------------------------------------------
    function handleItemDelete(itemId: number): void {
        const updatedInstructionSteps = Array.from(instructionSteps);
        const currentInstructionStep =
            updatedInstructionSteps[currentInstructionStepIndex];

        const currentInstructionStepItem =
            currentInstructionStep[currentInstructionStepKey];

        const itemIdIndex = currentInstructionStepItem.indexOf(itemId);

        currentInstructionStepItem.splice(itemIdIndex, 1);

        dispatch(setInstructionSteps(updatedInstructionSteps));
    }

    // Custom Hooks --------------------------------------------------

    // Effects -------------------------------------------------------

    // JSX -----------------------------------------------------------
    return (
        <ItemsCarouselElement shouldRenderScript={shouldRenderScript}>
            {shouldRenderScript && (
                <Text>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "16px"
                        }}
                    >
                        <Title
                            onClick={() => setScriptExpanded(!scriptExpanded)}
                        >
                            {title}
                        </Title>
                        {pageType !== "Main" && (
                            <DeleteButton
                                src="/marketplace-instructions-delete-icon.svg"
                                alt="marketplace-instructions-delete-icon"
                                onClick={() => handleItemDelete(itemId)}
                                shouldRenderScript={shouldRenderScript}
                            />
                        )}
                    </div>
                    {scriptExpanded && (
                        <ReactMarkdown>{subtitle || ""}</ReactMarkdown>
                    )}
                </Text>
            )}

            {/* {pageType !== "Main" && (
                <DeleteButton
                    src="/marketplace-instructions-delete-icon.svg"
                    alt="marketplace-instructions-delete-icon"
                    onClick={() => handleItemDelete(itemId)}
                    shouldRenderScript={shouldRenderScript}
                />
            )} */}

            {item}
        </ItemsCarouselElement>
    );
};

export default InstructionsFormDragStepAreaItem;
