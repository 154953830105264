import React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { colors } from "../../constants";
import { Connection } from "../../profile/types";
import { GlobalState } from "../../reducers";
import Platforms from "../../social-accounts/Platforms";
import { shortenNumber } from "../../util";
import ProfilePhoto from "../../components/ProfilePhoto";
import { Image, Row, Subtitle, Title } from "./StyledComponents";
import UserHitConnection from "./UserHitConnection";
import { PublisherHit } from "../types";

const RoundImage = styled(Image)`
    border-radius: 50px;
`;

const NoUsername = styled(Title)`
    font-style: italic;
    color: ${colors.primaryGray};
`;

interface Props {
    hit: PublisherHit;
    push: (path: string) => void;
    connections?: Connection[] | null;
    selfId?: string | null;
}

const UserHitRow = ({ hit, push, connections, selfId }: Props) => {
    // if (!hit.profile_photo_url) return null;
    const hitIdString = hit.id + "";
    const connection =
        connections &&
        connections.find(
            (connection: Connection) => connection.other.id === hitIdString
        );

    return (
        <Row
            onClick={() => {
                push(hitIdString === selfId ? "/profile" : `/public/${hit.id}`);
            }}
        >
            <ProfilePhoto
                profilePhotoUrl={
                    hit.profile_photo_url
                        ? hit.profile_photo_url
                        : "/no_profile_photo.svg"
                }
                photoSize={46}
                style={{ marginRight: "12px" }}
                status={hit.status}
            />
            <div>
                <Title>
                    {hit.username ? (
                        hit.username
                    ) : (
                        <NoUsername>No Username</NoUsername>
                    )}
                </Title>
                <Subtitle>
                    <span>
                        {hit.platform_num_followers
                            ? shortenNumber(hit.platform_num_followers)
                            : 0}
                    </span>
                    {" on "}
                    {hit.platform && Platforms[hit.platform]
                        ? Platforms[hit.platform].title
                        : Platforms.other.title}
                </Subtitle>
            </div>
            {connections && selfId && selfId !== hitIdString && (
                <UserHitConnection
                    connection={connection}
                    otherId={hitIdString}
                    selfId={selfId}
                />
            )}
        </Row>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    selfId: state.profile.profile && `p:${state.profile.profile.id}`,
    connections: state.profile.connections
});

const mapDispatchToProps = (dispatch: any) => ({
    push: (path: string) => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserHitRow);
