import { Action } from "../types";
import { TokenStatus } from "./reducer";
import { ReferringPublisher } from "../ui/auth/reducer";

export const POST_INVITE_CODE = {
    REQUEST: "POST_INVITE_CODE_REQUEST",
    SUCCESS: "POST_INVITE_CODE_SUCCESS",
    FAILURE: "POST_INVITE_CODE_FAILURE"
};

export interface InviteCodeParams {
    inviteCode: string;
}

export const postInviteCodeRequest = (
    inviteCodeParams: InviteCodeParams
): Action => ({
    type: POST_INVITE_CODE.REQUEST,
    payload: inviteCodeParams
});

export const postInviteCodeSuccess = (): Action => ({
    type: POST_INVITE_CODE.SUCCESS,
    payload: null
});

export const postInviteCodeFailure = (error: string): Action => ({
    type: POST_INVITE_CODE.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const POST_PHONE_NUMBER = {
    REQUEST: "POST_PHONE_NUMBER_REQUEST",
    RESEND_REQUEST: "POST_PHONE_NUMBER_RESEND_REQUEST",
    CALL_REQUEST: "POST_PHONE_NUMBER_CALL_REQUEST",
    SUCCESS: "POST_PHONE_NUMBER_SUCCESS",
    FAILURE: "POST_PHONE_NUMBER_FAILURE"
};

export interface PhoneNumberParams {
    phoneNumber: string;
}

export const postPhoneNumber = (
    phoneNumberParams: PhoneNumberParams
): Action => ({
    type: POST_PHONE_NUMBER.REQUEST,
    payload: phoneNumberParams
});

export const postPhoneNumberResend = (
    phoneNumberParams: PhoneNumberParams
): Action => ({
    type: POST_PHONE_NUMBER.RESEND_REQUEST,
    payload: phoneNumberParams
});

export const postPhoneNumberCall = (
    phoneNumberParams: PhoneNumberParams
): Action => ({
    type: POST_PHONE_NUMBER.CALL_REQUEST,
    payload: phoneNumberParams
});

export const postPhoneNumberSuccess = (
    phoneNumberParams: PhoneNumberParams
): Action => ({
    type: POST_PHONE_NUMBER.SUCCESS,
    payload: phoneNumberParams
});

export const postPhoneNumberFailure = (error: string): Action => ({
    type: POST_PHONE_NUMBER.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const POST_PHONE_AUTH = {
    REQUEST: "POST_PHONE_AUTH_REQUEST",
    SUCCESS: "POST_PHONE_AUTH_SUCCESS",
    FAILURE: "POST_PHONE_AUTH_FAILURE"
};

export interface PhoneAuthParams {
    phoneNumber: string;
    authCode: string;
}

export const postPhoneAuthRequest = (
    phoneAuthParams: PhoneAuthParams
): Action => ({
    type: POST_PHONE_AUTH.REQUEST,
    payload: phoneAuthParams
});

export const postPhoneAuthSuccess = (): Action => ({
    type: POST_PHONE_AUTH.SUCCESS,
    payload: null
});

export const postPhoneAuthFailure = (error: string): Action => ({
    type: POST_PHONE_AUTH.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const ASSIGN_CODE_TO_USER = "ASSIGN_CODE_TO_USER";

export interface AssignCodeParams {
    inviteCode: string;
}

export const assignCodeToUser = (
    assignCodeParams: AssignCodeParams
): Action => ({
    type: ASSIGN_CODE_TO_USER,
    payload: assignCodeParams
});

// -------------------------------------------------------------------

export const SAVE_PERSONAL_DETAILS = "SAVE_PERSONAL_DETAILS";

export interface PersonalDetailsParams {
    firstname?: string;
    lastname?: string;
    birthday?: string;
    email?: string;
}

export const savePersonalDetails = (
    personalDetails: PersonalDetailsParams
): Action => ({
    type: SAVE_PERSONAL_DETAILS,
    payload: personalDetails
});

// -------------------------------------------------------------------

export const CHECK_MAGIC_TOKEN = {
    REQUEST: "CHECK_MAGIC_TOKEN_REQUEST",
    SUCCESS: "CHECK_MAGIC_TOKEN_SUCCESS",
    FAILURE: "CHECK_MAGIC_TOKEN_FAILURE"
};

export const checkMagicToken = (token: string): Action => ({
    type: CHECK_MAGIC_TOKEN.REQUEST,
    payload: { token }
});

export const checkMagicTokenSuccess = (tokenStatus: TokenStatus): Action => ({
    type: CHECK_MAGIC_TOKEN.SUCCESS,
    payload: { tokenStatus }
});

export const checkMagicTokenFailure = (): Action => ({
    type: CHECK_MAGIC_TOKEN.FAILURE,
    payload: {
        tokenStatus: {
            valid: false,
            message: "Request failed, please refresh and try again"
        }
    }
});

// -------------------------------------------------------------------

export const POST_MAGIC_PHONE_AUTH = {
    REQUEST: "POST_MAGIC_PHONE_AUTH_REQUEST",
    SUCCESS: "POST_MAGIC_PHONE_AUTH_SUCCESS",
    FAILURE: "POST_MAGIC_PHONE_AUTH_FAILURE"
};

export interface MagicPhoneAuthParams {
    phoneNumber: string;
    authCode: string;
    magicToken: string;
}

export const postMagicPhoneAuth = (params: MagicPhoneAuthParams): Action => ({
    type: POST_MAGIC_PHONE_AUTH.REQUEST,
    payload: params
});

export const postMagicPhoneAuthFailure = (error: string): Action => ({
    type: POST_MAGIC_PHONE_AUTH.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const COMPLETE_ACCOUNT_SETUP = {
    REQUEST: "COMPLETE_ACCOUNT_SETUP_REQUEST",
    SUCCESS: "COMPLETE_ACCOUNT_SETUP_SUCCESS"
};

export const completeAccountSetup = (publisherId: number): Action => ({
    type: COMPLETE_ACCOUNT_SETUP.REQUEST,
    payload: publisherId
});

export const completeAccountSetupSuccess = (): Action => ({
    type: COMPLETE_ACCOUNT_SETUP.SUCCESS,
    payload: null
});

// -------------------------------------------------------------------

export const FETCH_REFERRING_PUBLISHER = {
    REQUEST: "FETCH_REFERRING_PUBLISHER_REQUEST",
    SUCCESS: "FETCH_REFERRING_PUBLISHER_SUCCESS",
    PRE_APPROVAL_CODE: "FETCH_REFERRING_PUBLISHER_PRE_APPROVAL_CODE",
    FAILURE: "FETCH_REFERRING_PUBLISHER_FAILURE"
};

export const fetchReferringPublisher = (referralCode: string): Action => ({
    type: FETCH_REFERRING_PUBLISHER.REQUEST,
    payload: { referralCode }
});

export const fetchReferringPublisherSuccess = (
    referringPublisher: ReferringPublisher
): Action => ({
    type: FETCH_REFERRING_PUBLISHER.SUCCESS,
    payload: { referringPublisher }
});

export const fetchReferringPublisherFailure = (): Action => ({
    type: FETCH_REFERRING_PUBLISHER.FAILURE,
    payload: null
});

export const fetchRefferingPublisherPreApprovalCode = (
    preApprovalCode: string
): Action => ({
    type: FETCH_REFERRING_PUBLISHER.PRE_APPROVAL_CODE,
    payload: { preApprovalCode }
});
// -------------------------------------------------------------------

export const INTERCOM_HASH = {
    SET: "SET_INTERCOM_HASH",
    REQUEST: "REQUEST_INTERCOM_HASH"
};
export const setIntercomHash = (hash: string): Action => ({
    type: INTERCOM_HASH.SET,
    payload: hash
});

export const fetchIntercomHash = (): Action => ({
    type: INTERCOM_HASH.REQUEST,
    payload: {}
});
