import React, { useEffect, useState } from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    DropResult,
    Droppable
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import axios, { get, patch } from "src/Api";
import OpenBuyLeaderboardCell from "src/buys/components/OpenBuyLeaderboardCell";
import { OpenBuySubmission } from "src/buys/modules/open-buys/types";
import { selectLeaderboardEditorOpen } from "src/buys/selectors";
import SpinLoader from "src/components/SpinLoader";
import withModalPortal from "src/components/withModalPortal";
import { colors, maxWidthDesktop } from "src/constants";
import LargeModal from "src/modals/LargeModal";
import { BigBlueButton } from "src/profile/components/Buttons";
import { toggleLeaderboardEditorOpen } from "src/ui/marketplace/actions";
import {
    addNotification,
    setPillNotificationText
} from "src/ui/notifications/actions";
import styled from "styled-components";
import { SubmissionReport } from "../../types";
import { GlobalState } from "src/reducers";
import XCard from "src/profile/components/XCard";

const Main = styled.div`
    width: 90vw;
    max-width: ${maxWidthDesktop}px;
    height: 90vh;
    max-height: 742px;
    position: relative;
    overflow: visible;
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 16px;
`;

const Loader = styled.div`
    display: flex;
    margin: auto;
`;

const Card = styled(XCard)`
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    gap: 12px;
    min-height: 250px;
    overflow: auto;
    flex-wrap: wrap;
    width: 95%;
`;

const Title = styled.h1`
    font-size: 18px;
    text-align: left;
    font-weight: 500;
`;

const SubTitle = styled.h2`
    font-size: 12px;
    text-align: left;
    margin-top: -16px;
`;
const ActionButton = styled(BigBlueButton)`
    background-color: ${colors.primaryRedDark};
    margin-top: auto;
`;

const Submission = styled.div`
    background-color: ${colors.blueGrey};
    border-radius: 10px;
    width: 140px;
    height: 250px;
    gap: 12px;
    display: flex;
    flex-direction: column;
    position: relative;
`;

const PinIcon = styled.img`
    position: absolute;
    right: 9px;
    top: 9px;
`;

const LeaderboardEditor = () => {
    const dispatch = useDispatch();
    const openBuyId = useSelector(selectLeaderboardEditorOpen);
    const [leaderboardLoading, setLeaderboardLoading] = useState(true);
    const [hiddenLoading, setHiddenLoading] = useState(true);
    const openBuy = useSelector((state: GlobalState) => {
        if (openBuyId) {
            return state.entities.adminOpenBuys.byId[openBuyId];
        } else {
            return null;
        }
    });

    async function handleOnDragEnd(result: DropResult) {
        const { destination, draggableId, source } = result;
        console.log(result);
        if (destination) {
            const { droppableId, index } = destination;
            if (droppableId === "leaderboard-editor-pinned") {
                try {
                    setLeaderboardLoading(true);
                    await patch(
                        `/v1/openBuySubmission/${draggableId}/leaderboard/pin`,
                        {
                            position: index + 1,
                            direction: source.index > index ? 1 : -1
                        }
                    );
                    getLeaderboard();
                } catch (err) {
                    dispatch(
                        addNotification("Failed to pin submission", "danger")
                    );
                }
            }
        }
    }

    const [leaderboard, setLeaderboard] = useState<SubmissionReport[]>([]);
    const [hidden, setHidden] = useState<SubmissionReport[]>([]);

    const getHidden = async () => {
        setHiddenLoading(true);
        try {
            let res = await get(
                `/v1/openBuy/${openBuy.id}/submissionLeaderboard`,
                { onlyHidden: true }
            );

            setHidden(res.data.data);
        } catch (err) {
            dispatch(
                setPillNotificationText(
                    "Error fetching hidden submissions",
                    "danger"
                )
            );
        } finally {
            setHiddenLoading(false);
        }
    };

    const getLeaderboard = async () => {
        setLeaderboardLoading(true);
        try {
            let res = await get(
                `/v1/openBuy/${openBuy.id}/submissionLeaderboard`,
                {}
            );

            setLeaderboard(
                res.data.data.filter((sub: SubmissionReport) => !!sub.media)
            );
        } catch (err) {
            dispatch(
                setPillNotificationText(
                    "Error fetching leaderboard submissions",
                    "danger"
                )
            );
        } finally {
            setLeaderboardLoading(false);
        }
    };

    const updateSubmission = async (
        id: number,
        updateObj: Partial<OpenBuySubmission>,
        after: VoidFunction
    ) => {
        try {
            await axios.patch(`/v1/openBuySubmission/${id}`, updateObj);
            after();
        } catch (err) {
            dispatch(addNotification("Failed to save changes"));
        }
    };

    useEffect(() => {
        if (openBuy) {
            getLeaderboard();
            getHidden();
        }
    }, [openBuy]);

    if (!openBuy) return null;

    return (
        <LargeModal
            maxWidth="910px"
            title={openBuy?.name + " Leaderboard"}
            closeModal={() => dispatch(toggleLeaderboardEditorOpen(null))}
        >
            <Main>
                <Title>Current Leaderboard</Title>
                <SubTitle>Drag to Reorder</SubTitle>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable
                        direction="horizontal"
                        droppableId="leaderboard-editor-pinned"
                    >
                        {(provided, snapshot) => (
                            <Row
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {leaderboardLoading ? (
                                    <Loader>
                                        <SpinLoader />
                                    </Loader>
                                ) : leaderboard.length === 0 ? (
                                    <Card
                                        customStyles={{ height: "100%" }}
                                        text="This challenge does not have any submissions eligible for the leaderboard."
                                    />
                                ) : (
                                    leaderboard.map(
                                        (sub, index) =>
                                            index < 5 && (
                                                <Draggable
                                                    key={`hooks-create-instructions-script-${sub.id}`}
                                                    draggableId={sub.id.toString()}
                                                    index={index}
                                                >
                                                    {(
                                                        provided: DraggableProvided,
                                                        snapshot: DraggableStateSnapshot
                                                    ) => (
                                                        <Submission
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <OpenBuyLeaderboardCell
                                                                place={
                                                                    index + 1
                                                                }
                                                                report={sub}
                                                            />
                                                            <ActionButton
                                                                onClick={() =>
                                                                    updateSubmission(
                                                                        sub.id,
                                                                        sub.leaderboardPinned
                                                                            ? {
                                                                                  leaderboardPinned: null
                                                                              }
                                                                            : {
                                                                                  leaderboardHidden: true
                                                                              },
                                                                        () => {
                                                                            getHidden();
                                                                            getLeaderboard();
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                {sub.leaderboardPinned
                                                                    ? "Remove Pin"
                                                                    : "Hide"}
                                                            </ActionButton>
                                                            {sub.leaderboardPinned && (
                                                                <PinIcon src="/pin-icon.svg" />
                                                            )}
                                                        </Submission>
                                                    )}
                                                </Draggable>
                                            )
                                    )
                                )}
                            </Row>
                        )}
                    </Droppable>
                </DragDropContext>
                <Title>Hidden Submissions</Title>

                <Row>
                    {hiddenLoading ? (
                        <Loader>
                            <SpinLoader />
                        </Loader>
                    ) : hidden.length === 0 ? (
                        <Card
                            customStyles={{ height: "100%" }}
                            text="This challenge does not have any submissions hidden from the leaderboard"
                        />
                    ) : (
                        hidden.map(sub => (
                            <Submission>
                                <OpenBuyLeaderboardCell report={sub} />
                                <ActionButton
                                    onClick={() =>
                                        updateSubmission(
                                            sub.id,
                                            { leaderboardHidden: false },
                                            () => {
                                                getHidden();
                                                getLeaderboard();
                                            }
                                        )
                                    }
                                >
                                    Add Back
                                </ActionButton>
                            </Submission>
                        ))
                    )}
                </Row>
            </Main>
        </LargeModal>
    );
};

export default withModalPortal(LeaderboardEditor);
