import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TabState } from "../page/index";
import { replace } from "connected-react-router";
import { tabToIndex } from "../page/SearchMain";
import Tab from "../../components/Tab";
import { submitEvent } from "../../events/actions";
import styled from "styled-components";
import { colors, maxWidthDesktop } from "src/constants";

const Main = styled.section`
    display: flex;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${maxWidthDesktop}px) {
        border-right: ${colors.transparent} solid 1px;
        border-left: ${colors.transparent} solid 1px;
        border-radius: 0px;
    }
`;

export interface TabObject {
    value: string;
    tab: TabState;
    label: string;
    route: string;
    event: string | null;
}

interface Props {
    items: TabObject[];
    currentTab: TabState;
}

const SortBy = ({ items, currentTab }: Props) => {
    const dispatch = useDispatch();

    return (
        <Main>
            {items.map((item: any) => (
                <Tab
                    key={item.label}
                    name={item.label}
                    active={currentTab === item.tab}
                    onClick={() => {
                        if (item.event) {
                            dispatch(submitEvent({ event_name: item.event }));
                        }
                        dispatch(replace(item.route));
                    }}
                />
            ))}
        </Main>
    );
};

export default SortBy;
