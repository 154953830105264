import React from "react";
import { colors } from "src/constants";
import styled, { keyframes } from "styled-components";

const Bar = styled.span<{ thinner?: boolean }>`
    display: flex;
    width: 100%;
    background: ${colors.blueGrey2};
    height: 12px;
    border-radius: 19px;
    overflow: hidden;
    height: ${props => props.thinner && "3px"};
`;

const BlueBar = styled(Bar)<{ animation: any; thinner?: boolean }>`
    border-radius: 19px;
    background: ${props => props.color || colors.primaryBlue};
    width: 0;
    margin-top: 0;
    animation-duration: 2s;
    animation-delay: 1.2s;
    animation-fill-mode: both;

    animation-name: ${props => props.animation};
    height: ${props => props.thinner && "5px"};
`;

const ProgressBar = ({
    progress,
    reverse,
    style,
    color,
    thinner
}: {
    progress: number;
    reverse?: boolean;
    style?: any;
    color?: string;
    thinner?: boolean;
}) => {
    const animation = reverse
        ? keyframes`0% {
            width: 100%;
          }
          100% {
            width: ${progress}%;
          }`
        : keyframes`
0% {
  width: 0;
}
100% {
  width: ${progress}%;
}`;

    return (
        <Bar style={style} thinner={thinner}>
            <BlueBar animation={animation} thinner={thinner} color={color} />
        </Bar>
    );
};
export default ProgressBar;
