import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { Profile } from "../types";
import { ProfileModal } from "../reducer";
import { getFeesForCashout, requestTipaltiCashout } from "../actions";
import { feesModal } from "../actions";
import { allowCashout, coinToDollars, formatNumber } from "../../util";
import { cashoutMethods } from "./cashoutMethods";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FullscreenLoader from "../../components/FullscreenLoader";
import FeesModal from "./modals/FeesModal";
import { colors, fonts } from "../../constants";
import MainContainer from "src/components/MainContainer";
import { flex } from "src/utils/styles/snippets";
import { useHistory } from "react-router";
import Navbar from "src/components/Navbar";

const Main = styled("div")`
    ${flex("column", "space-between")};
    min-height: calc(100vh - 57px);
    padding: 20px 15px;
    font-size: ${fonts.subtitle}px;
    box-sizing: border-box;
`;

const VerticalCenter = styled("div")`
    ${flex("row", "auto", "center")};
`;

const Detail = styled(VerticalCenter)`
    justify-content: space-between;
    padding: 15px 0px;
`;

const DateContainer = styled(VerticalCenter)`
    padding: 15px 0px;
    border-top: 1px solid ${colors.mediumTeal};
    border-bottom: 1px solid ${colors.mediumTeal};
`;

const LargeIcon = styled("img")`
    height: 40px;
    width: 40px;
    margin-right: 10px;
`;

const CashTotal = styled("div")`
    color: ${colors.secondaryGreen};
    font-size: 20px;
`;

const IconContainer = styled(VerticalCenter)`
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const SmallIcon = styled("img")`
    max-width: 20px;
    max-height: 20px;
`;

const GrayText = styled("div")`
    color: ${colors.secondaryGray};
`;

const QuestionCircle = styled(VerticalCenter)`
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 100%;
    font-size: ${fonts.smallSubtitle}px;
    font-weight: bold;
    line-height: 16px;
`;

const Error = styled("div")`
    margin-bottom: 10px;
    color: red;
    font-size: ${fonts.subtitle}px;
`;
interface Props {
    profile: Profile;
}

const CashoutForm = ({ profile }: Props) => {
    const history = useHistory();

    const emailRef = useRef<HTMLInputElement>(null);
    const [emailChanges, setEmailChanges] = useState(false);
    const [emailDisabled, toggleEmailDisabled] = useState(true);

    const cashoutLoading = useSelector((state: GlobalState) => {
        return state.profile.cashoutLoading;
    });

    const modal = useSelector((state: GlobalState) => {
        return state.profile.modal;
    });

    const error = useSelector((state: GlobalState) => {
        return state.profile.error;
    });

    const cashoutFees = useSelector((state: GlobalState) => {
        return state.profile.cashoutFees;
    });

    const dispatch = useDispatch();

    function dispatchGetFeesForCashout(
        feeType: string,
        cashoutAmount: number
    ): void {
        dispatch(getFeesForCashout(feeType, cashoutAmount));
    }

    function dispatchRequestTipaltiCashout(cashout_amount: number): void {
        dispatch(requestTipaltiCashout(cashout_amount));
    }

    function dispatchFeesModal(): void {
        dispatch(feesModal());
    }

    useEffect(() => {
        if (profile) {
            dispatchGetFeesForCashout(
                profile.tipaltiPaymentMethod,
                profile.balance * 0.05
            );
        }
    }, []);

    useEffect(() => {
        if (!emailDisabled)
            emailRef && emailRef.current && emailRef.current.focus();
    }, [emailDisabled]);

    // early returns
    if (!cashoutFees) {
        return (
            <div>
                <Navbar back />
                <FullscreenLoader />
            </div>
        );
    }

    let cashoutMethod;

    if (
        profile.balance > 399 &&
        profile.tipaltiPayeeExists &&
        profile.tipaltiPayeePayable &&
        allowCashout(profile) &&
        profile.tipaltiPaymentMethod !== "NoPM" &&
        profile.tipaltiPaymentMethod !== "HoldMyPayments"
    ) {
        cashoutMethod =
            cashoutMethods.find(method => {
                return method.tag === profile.tipaltiPaymentMethod;
            }) || cashoutMethods[0];
    } else {
        history.goBack();
        return null;
    }

    const currentDate = () => {
        const d = new Date();
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const year = d
            .getFullYear()
            .toString()
            .slice(2, 4);
        return `${month}/${day}/${year}`;
    };

    const totalString = () => {
        if (cashoutFees.fees.min_fee === cashoutFees.fees.max_fee) {
            return `$${formatNumber(
                (profile.balance / 20 - cashoutFees.fees.min_fee).toFixed(2)
            )}`;
        } else {
            const minString = `$${formatNumber(
                (profile.balance / 20 - cashoutFees.fees.min_fee).toFixed(2)
            )}`;
            const maxString = `$${formatNumber(
                (profile.balance / 20 - cashoutFees.fees.max_fee).toFixed(2)
            )}`;
            return `${minString} - ${maxString}`;
        }
    };

    return (
        <>
            <Navbar back />

            <MainContainer>
                <Main>
                    <div>
                        <div style={{ fontSize: "20px" }}>CASH OUT</div>
                        <Detail>
                            <VerticalCenter>
                                <LargeIcon src={"/logo.svg"} />
                            </VerticalCenter>
                            <CashTotal>
                                {`${formatNumber(
                                    coinToDollars(profile.balance)
                                )}`}
                            </CashTotal>
                        </Detail>
                        <Detail>
                            <VerticalCenter style={{ flex: 1 }}>
                                <IconContainer>
                                    <SmallIcon src={cashoutMethod.icon} />
                                </IconContainer>
                                {cashoutFees.fees.type}
                            </VerticalCenter>
                            <div
                                style={{
                                    color: colors.primaryBlue,
                                    cursor: "pointer"
                                }}
                                onMouseDown={() => {
                                    window.open(
                                        "https://suppliers.tipalti.com/theplug/portal/index"
                                    );
                                }}
                            >
                                Change
                            </div>
                        </Detail>
                        <DateContainer>
                            <IconContainer>
                                <SmallIcon src={"/date/date@2x.png"} />
                            </IconContainer>
                            <GrayText>Date {currentDate()}</GrayText>
                        </DateContainer>
                        <Detail>
                            <VerticalCenter
                                style={{ cursor: "pointer" }}
                                onClick={dispatchFeesModal}
                            >
                                <GrayText>
                                    {cashoutFees.fees.type} Fees
                                </GrayText>
                                <QuestionCircle>
                                    <GrayText>?</GrayText>
                                </QuestionCircle>
                            </VerticalCenter>
                            <div>-({cashoutFees.fees.fee_string})</div>
                        </Detail>
                        <Detail style={{ paddingTop: 0 }}>
                            <strong>Total</strong>
                            <strong>{totalString()}</strong>
                        </Detail>
                    </div>
                    <div>
                        {error && <Error>{error}</Error>}
                        <GrayText style={{ marginBottom: "10px" }}>
                            Please allow up to 3-5 business days to process a
                            payment to you.
                        </GrayText>
                        <Button
                            disabled={emailChanges || cashoutLoading}
                            style={{
                                width: "100%",
                                height: "50px",
                                fontSize: "14px"
                            }}
                            onClick={() => {
                                if (cashoutLoading) {
                                    window.alert(
                                        "Please wait, current request in progress"
                                    );
                                } else if (profile.balance < 399) {
                                    window.alert(
                                        "Your balance must be greater than $20 to cash out."
                                    );
                                } else {
                                    dispatchRequestTipaltiCashout(
                                        profile.balance
                                    );
                                }
                            }}
                        >
                            {cashoutLoading ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                "Confirm Cash Out"
                            )}
                        </Button>
                    </div>
                </Main>
            </MainContainer>

            {modal === ProfileModal.fees && (
                <FeesModal feeSchedule={cashoutFees.fee_schedule} />
            )}
        </>
    );
};

export default CashoutForm;
