import React, { useEffect, useState } from "react";
import {
    DirectBuyStepCollection,
    DirectBuyStepAction
} from "../../../modules/direct-buy-step/types";
import { DirectBuyStepsEntitiesState } from "../../../modules/direct-buy-step/reducer";
import { ResourceContainer, ResourceDescription } from "../styledComponents";
import styled from "styled-components";
import DirectBuyDetailsScreenshotUploadCard from "./DirectBuyDetailsScreenshotUploadCard";
import DirectBuyDetailsStepCollectionStatus from "../components/DirectBuyDetailsStepCollectionStatus";
import { colors } from "src/constants";
import CopyToClipboard from "react-copy-to-clipboard";
import { get } from "src/Api";
import { BigOutlineButton } from "src/profile/components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "src/ui/notifications/actions";
import { StepById } from "src/buys/modules/open-buys/reducer";
import { SubmissionButton } from "../DirectBuyDetailsStepCollection";
import { setOpenBuySubmissionModal } from "src/buys/actions";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const HorizontalScrollOuter = styled.div`
    display: flex;
    overflow-x: scroll;
`;

const HorizontalScrollInner = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

const PublicUploadLink = styled.div`
    color: ${colors.primaryBlue};
    font-size: 14px;
    line-height: 20px;
    padding: 15px 0px;
    cursor: pointer;
`;

interface Props {
    collection: DirectBuyStepCollection;
    steps: DirectBuyStepsEntitiesState | StepById;
    open: boolean;
}

const DirectBuyDetailsScreenshots = ({ collection, steps, open }: Props) => {
    const [link, setLink] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        get("/v1/directBuy/code", {}).then(res => {
            setLink("https://app.plugco.in/l/" + res.data.data.code);
        });
    }, []);
    const showButton = !useSelector(
        selectFeatureFlags(featureFlags.ChallengesGroupedSubmissions)
    );

    const filteredSteps = collection.combined
        .filter(id => steps[id].requiresScreenshot)
        .map(id => steps[id]);
    if (filteredSteps.length === 0) {
        return null;
    }

    if (open)
        return showButton ? (
            <SubmissionButton
                onClick={() =>
                    dispatch(setOpenBuySubmissionModal(collection.parent))
                }
            >
                Screenshot Submission
            </SubmissionButton>
        ) : null;

    return (
        <ResourceContainer>
            <ResourceDescription>CONFIRMATION SCREENSHOTS</ResourceDescription>
            {filteredSteps.length === 1 ? (
                <DirectBuyDetailsScreenshotUploadCard
                    padding="18px"
                    width="100%"
                    step={filteredSteps[0]}
                />
            ) : (
                <HorizontalScrollOuter>
                    <HorizontalScrollInner>
                        {filteredSteps.map(step => {
                            return (
                                <DirectBuyDetailsScreenshotUploadCard
                                    key={step.id}
                                    padding="12px"
                                    width="164px"
                                    step={step}
                                />
                            );
                        })}
                    </HorizontalScrollInner>
                </HorizontalScrollOuter>
            )}
            <DirectBuyDetailsStepCollectionStatus
                collection={collection}
                steps={steps}
                action={DirectBuyStepAction.screenshot}
            />
            <CopyToClipboard
                text={link}
                onCopy={() =>
                    dispatch(
                        addNotification(
                            `Screenshot upload link copied \n${link}`
                        )
                    )
                }
            >
                <BigOutlineButton style={{ margin: "14px 0px" }}>
                    Copy Screenshot Upload Link
                </BigOutlineButton>
            </CopyToClipboard>
        </ResourceContainer>
    );
};

export default DirectBuyDetailsScreenshots;
