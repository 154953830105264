import React from "react";

import { Props } from "src/components/icons/types";

const Exclamation = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || "10"}
            viewBox="0 0 10 10"
            fill={props.fill || "#000"}
            fillRule="evenodd"
        >
            <path d="M5.789 9.211A.79.79 0 0 1 5 10a.79.79 0 0 1-.789-.789A.79.79 0 0 1 5 8.422a.79.79 0 0 1 .789.789zM4.999 0a.79.79 0 0 0-.789.789v5.789a.79.79 0 0 0 .789.789.79.79 0 0 0 .789-.789V.789A.79.79 0 0 0 4.999 0z" />
        </svg>
    );
};

export default Exclamation;
