import { AnimatePresence, motion } from "framer-motion";
import React, { useRef, useState } from "react";
import SearchBar from "src/components/SearchBar";
import { colors, shadows } from "src/constants";
import MultiSelectionFilters from "src/marketplace/creator-groups/components/MultiSelectionFilters";
import PlatformFilterOptions from "src/marketplace/creator-groups/components/PlatformFilterOptions";
import { useCloseModalWhenClickedOutside } from "src/utils/functions/hooks";
import styled from "styled-components";
import { CreatorSearchFilters } from "../utils";

const Main = styled(motion.section)`
    position: absolute;
    top: calc(100% + 4px);
    width: 300px;
    max-height: 400px;
    background-color: ${colors.white};
    border-radius: 8px;
    box-shadow: ${shadows.one};
    overflow: auto;
`;

const Filters = styled.article`
    display: flex;
    flex-direction: column;
`;

const Filter = styled.div`
    position: relative;

    > * {
        width: 100%;
    }
`;

const SearchContainer = styled.div`
    position: sticky;
    top: 0px;
    right: 0px;
    left: 0px;
    background-color: ${colors.white};
`;

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    currentFilter: "" | CreatorSearchFilters;
}

const CreatorSearchFilterDropdown = ({
    open,
    setOpen,
    currentFilter
}: Props) => {
    // Local State ---------------------------------------------------
    const mainRef = useRef(null);
    const [searchString, setSearchString] = useState("");

    // Custom Hooks --------------------------------------------------
    useCloseModalWhenClickedOutside(
        mainRef,
        open,
        () => setOpen(false),
        [open],
        () => setSearchString("")
    );

    // JSX -----------------------------------------------------------
    return (
        <AnimatePresence>
            {open && (
                <Main
                    ref={mainRef}
                    initial={{ transform: "translateY(-4px)", opacity: 0 }}
                    animate={{ transform: "translateY(0px)", opacity: 1 }}
                    exit={{ transform: "translateY(-4px)", opacity: 0 }}
                >
                    <Filters>
                        {currentFilter === "Platform" ? (
                            <Filter>
                                <SearchContainer>
                                    <SearchBar
                                        searchString={searchString}
                                        updateSearchString={setSearchString}
                                        wrapped
                                    />
                                </SearchContainer>
                                <PlatformFilterOptions
                                    searchString={searchString}
                                />
                            </Filter>
                        ) : currentFilter === "Interests" ? (
                            <Filter>
                                <SearchContainer>
                                    <SearchBar
                                        searchString={searchString}
                                        updateSearchString={setSearchString}
                                        wrapped
                                    />
                                </SearchContainer>
                                <MultiSelectionFilters
                                    searchString={searchString}
                                    multiSelectionFilterType="Interests"
                                />
                            </Filter>
                        ) : (
                            <Filter>
                                <SearchContainer>
                                    <SearchBar
                                        searchString={searchString}
                                        updateSearchString={setSearchString}
                                        wrapped
                                    />
                                </SearchContainer>
                                <MultiSelectionFilters
                                    searchString={searchString}
                                    multiSelectionFilterType="Themes"
                                />
                            </Filter>
                        )}
                    </Filters>
                </Main>
            )}
        </AnimatePresence>
    );
};

export default CreatorSearchFilterDropdown;
