import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Parent = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const Child = styled.div`
    height: fit-content;
    width: 70vw;
    max-width: 350px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
`;

const Video = styled.video`
    display: block;
    width: 100%;
    cursor: pointer;
`;

const Image = styled.img`
    display: block;
    width: 100%;
`;

const PlayScreen = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    font-size: 50px;
    pointer-events: none;
`;

const Delete = styled.img`
    margin: 15px;
    width: 24px;
    height: 29px;
    cursor: pointer;
`;

interface Props {
    mediaUrl: string;
    type: string;
    setDeleteScreen: (state: boolean) => void;
    onDeleteClicked: () => void;
}

const UploadMediaDelete = ({
    mediaUrl,
    type,
    setDeleteScreen,
    onDeleteClicked
}: Props) => {
    const [play, setPlay] = useState(false);

    let media;

    if (type === "video") {
        media = (
            <>
                <Video
                    playsInline={true}
                    src={mediaUrl}
                    onClick={e => {
                        if (!play) {
                            setPlay(true);
                        }
                        const target = e.target as HTMLVideoElement;
                        target.paused ? target.play() : target.pause();
                    }}
                />
                <PlayScreen style={{ display: play ? "none" : "flex" }}>
                    <FontAwesomeIcon icon={["fas", "play"]} />
                </PlayScreen>
            </>
        );
    } else if (type === "photo") {
        media = <Image src={mediaUrl} />;
    } else {
        media = <Image alt={"could not find media"} />;
    }

    return (
        <Parent onClick={() => setDeleteScreen(false)}>
            <div style={{ minHeight: "60px", height: "60px" }} />
            <Child onClick={e => e.stopPropagation()}>{media}</Child>
            <Delete
                src={"/trash/trash@2x.png"}
                onClick={() => onDeleteClicked()}
            />
        </Parent>
    );
};

export default UploadMediaDelete;
