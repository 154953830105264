import React from "react";
import { connect } from "react-redux";
import { DailyEarnings } from "./Analytics";
import { setCurrentDailyEarnings } from "./actions";
import { push } from "connected-react-router";
import { coinToDollars, formatNumber } from "../util";
import styled from "styled-components";
import AnalyticsIcon from "./AnalyticsIcon";
import { breakpoints, colors, maxWidthDesktop, fonts } from "../constants";

const Cell = styled.div`
    width: 100%;
    display: flex;
    user-select: none;
    transition: 0.1s ease-in;
    cursor: pointer;

    & + & {
        margin-top: 15px;
    }

    @media (pointer: fine) {
        padding: 6px;
        border: ${colors.mediumTeal} solid 1px;
        border-radius: 8px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const Description = styled.div`
    width: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Name = styled.span`
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MoneyContainer = styled.div`
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
`;

const Coins = styled.div`
    font-size: 20px;
    line-height: 24px;
`;

const Cash = styled.div`
    font-size: ${fonts.subtitle}px;
    line-height: 20px;
    color: ${colors.secondaryGray};
`;

interface Props {
    earnings: DailyEarnings;
    push: (path: string) => void;
    setCurrentDailyEarnings: (currentDailyEarnings: DailyEarnings) => void;
}

const DailyEarningsCell = ({
    earnings,
    push,
    setCurrentDailyEarnings
}: Props) => {
    const plugcoins = Math.round(earnings.plugcoins * 10) / 10;

    function onCellClicked() {
        setCurrentDailyEarnings(earnings);
        push("/analytics/daily-earnings/links");
    }

    return (
        <Cell onClick={onCellClicked}>
            <AnalyticsIcon mediaUrl={earnings.image_url} />
            <Description>
                <Name>{earnings.campaign_name}</Name>
                <MoneyContainer>
                    <Coins>{formatNumber(plugcoins)}</Coins>
                    <Cash>{`${formatNumber(coinToDollars(plugcoins))}`}</Cash>
                </MoneyContainer>
            </Description>
        </Cell>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    push: (path: string) => dispatch(push(path)),
    setCurrentDailyEarnings: (currentDailyEarnings: DailyEarnings) =>
        dispatch(setCurrentDailyEarnings(currentDailyEarnings))
});

export default connect(null, mapDispatchToProps)(DailyEarningsCell);
