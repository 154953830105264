import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import * as Icons from "src/components/icons/exports";
import { Campaign } from "src/campaigns/types";
import { colors } from "src/constants";
import { push } from "connected-react-router";
import { setCurrentCampaignId } from "src/ui/campaigns/actions";
import { flex, flexRowCenter } from "src/utils/styles/snippets";

const Main = styled("button")`
    ${flex("column", "center", "center")};
    flex-shrink: 0;
    margin-right: 20px;
    cursor: default !important;

    :focus {
        outline: none;
    }
`;

const Icon = styled.div`
    ${flexRowCenter()};
    width: 46px;
    height: 46px;
    margin-bottom: 4px;
    color: ${colors.black};
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 50%;
    text-decoration: none;
    transition: 0.1s ease-in;
    cursor: pointer;

    :hover {
        color: ${colors.black};
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.mediumTeal};
        }
    }
`;

const Text = styled("span")`
    display: block;
    color: ${colors.primaryGray};
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
`;

interface Props {
    currentCampaign: Campaign;
}

const UploadMediaButton = (props: Props) => {
    const { currentCampaign } = props;

    const dispatch = useDispatch();

    function setCurrentCampaignHandler(): void {
        dispatch(setCurrentCampaignId(currentCampaign.id));
        dispatch(push("/campaign/upload-media"));
    }

    return (
        <Main>
            <Icon onClick={setCurrentCampaignHandler}>
                <Icons.Plus height={18} fill={colors.primaryBlue} />
            </Icon>
            <Text>
                Submit <br /> Your Media
            </Text>
        </Main>
    );
};

export default UploadMediaButton;
