import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DirectBuyStatus } from "../modules/direct-buy/types";
import {
    selectDirectBuysAllIds,
    selectDirectBuysById,
    selectDirectBuysLoading
} from "../selectors";
import { requestDirectBuysByStatus } from "../actions";
import { getStatusForDirectBuy } from "../modules/direct-buy/util";
import DirectBuysHeaderRow from "./DirectBuysHeaderRow";
import { GlobalState } from "../../reducers";
import { checkPublisherFlag } from "../../profile/flags/selectors";
import LandingExternalHeader from "src/components/campaign/LandingExternalHeader";
import styled from "styled-components";
import { Shimmer } from "src/components/SkeletonLoader";
import FlexColumn from "src/components/FlexColumn";

const LoadingHeader = styled.div`
    ${Shimmer};
    width: 200px;
    height: 30px;
    border-radius: 10px;
    margin-bottom: 6px;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 16px;
`;
const LoadingSubHeader = styled.div`
    ${Shimmer};
    width: 130px;
    height: 20px;
    border-radius: 10px;
`;

const LoadingButton = styled.div`
    ${Shimmer}
    width: 75px;
    height: 35px;
    border-radius: 10px;
`;

const TitleSubtitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const DirectBuysHeader = () => {
    const dispatch = useDispatch();
    const cachedHasAccess = localStorage.getItem("hasAccess");

    const hasAccess = useSelector((state: GlobalState) =>
        checkPublisherFlag(state, "directBuyAccess", "approved")
    );
    const started = useSelector((state: GlobalState) =>
        checkPublisherFlag(state, "directBuyStarted", "started")
    );

    useEffect(() => {
        dispatch(
            requestDirectBuysByStatus([
                DirectBuyStatus.available,
                DirectBuyStatus.active,
                DirectBuyStatus.completed,
                DirectBuyStatus.expired
            ])
        );
    }, []);

    useEffect(() => {
        if (!cachedHasAccess || cachedHasAccess !== hasAccess.toString())
            localStorage.setItem("hasAccess", hasAccess.toString());
    }, [hasAccess]);

    const directBuysLoading = useSelector(selectDirectBuysLoading);
    const directBuysById = useSelector(selectDirectBuysById);
    const directBuysAllIds = useSelector(selectDirectBuysAllIds);

    const [available, setAvailable] = useState<number[]>([]);
    const [active, setActive] = useState<number[]>([]);
    const [recentlyCompleted, setRecentlyCompleted] = useState<number[]>([]);

    useEffect(() => {
        const newAvailable: number[] = [];
        const newActive: number[] = [];
        const newRecentlyCompleted: number[] = [];
        for (const directBuyId of directBuysAllIds) {
            const directBuy = directBuysById[directBuyId];
            if (directBuy.isRecentlyCompletedBuy) {
                newRecentlyCompleted.push(directBuyId);
                continue;
            }
            const currentStatus = getStatusForDirectBuy(directBuy);
            if (currentStatus === DirectBuyStatus.available) {
                newAvailable.push(directBuyId);
            } else if (currentStatus === DirectBuyStatus.active) {
                newActive.push(directBuyId);
            }
        }
        setAvailable(newAvailable);
        setActive(newActive);
        setRecentlyCompleted(newRecentlyCompleted);
    }, [directBuysAllIds, directBuysById]);

    const [subtitleStrings, setSubtitleStrings] = useState<string[]>([]);

    useEffect(() => {
        const newSubtitleStrings: string[] = [];
        if (available.length > 0) {
            newSubtitleStrings.push(`${available.length} available`);
        }
        if (active.length > 0) {
            newSubtitleStrings.push(`${active.length} active`);
        }
        if (recentlyCompleted.length > 0) {
            newSubtitleStrings.push(`${recentlyCompleted.length} completed`);
        }
        if (newSubtitleStrings.length === 0) {
            newSubtitleStrings.push(`0 available`);
            newSubtitleStrings.push(`0 active`);
        }
        setSubtitleStrings(newSubtitleStrings);
    }, [available, active, recentlyCompleted]);

    return cachedHasAccess === "false" &&
        (!hasAccess || !started) ? null : directBuysLoading ? (
        <LoadingContainer>
            <TitleSubtitleContainer>
                <LoadingHeader />
                <LoadingSubHeader />
            </TitleSubtitleContainer>
            <LoadingButton />
        </LoadingContainer>
    ) : (
        <LandingExternalHeader
            title="Personal Offers"
            subtitle={subtitleStrings.join("; ")}
            link="/buys/direct"
            linkText="View All"
            marginBottom
        >
            {available.length || active.length || recentlyCompleted.length ? (
                <DirectBuysHeaderRow
                    available={available}
                    active={active}
                    recentlyCompleted={recentlyCompleted}
                />
            ) : null}
        </LandingExternalHeader>
    );
};

export default DirectBuysHeader;
