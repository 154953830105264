import { Action } from "src/types";

export const SET_FEATURE_FLAG = "SET_FEATURE_FLAG";

export function setFeatureFlag(id: string, value: boolean): Action {
    return {
        type: SET_FEATURE_FLAG,
        payload: { id, value }
    };
}
