import { Action, NormalizedActionGeneric, NormalizedResponse } from "src/types";
import { LinkList, LinkListItem } from "../types";

// -------------------------------------------------------------------
export const FETCH_LINK_LIST = {
    REQUEST: "FETCH_LINK_LIST_REQUEST",
    SUCCESS: "FETCH_LINK_LIST_SUCCESS",
    FAILURE: "FETCH_LINK_LIST_FAILURE"
};

export const fetchLinkList = () => ({
    type: FETCH_LINK_LIST.REQUEST
});

export function fetchLinkListSuccess(
    response: NormalizedResponse<LinkList, number[]>
): NormalizedActionGeneric<LinkList, number[]> {
    return {
        type: FETCH_LINK_LIST.SUCCESS,
        response
    };
}

export const fetchLinkListFailure = (error: string) => ({
    type: FETCH_LINK_LIST.FAILURE,
    payload: error
});

// --------------------------------------------------------------

export const EDIT_LINK_LIST = {
    REQUEST: "EDIT_LINK_LIST_REQUEST",
    SUCCESS: "EDIT_LINK_LIST_SUCCESS"
};

export const editLinkList = (params: {
    name: string;
    value: string;
    id?: string;
    initialPhoto?: string;
}): Action => ({
    type: EDIT_LINK_LIST.REQUEST,
    payload: { ...params }
});

export const editLinkListSuccess = (linkList: LinkList): Action => ({
    type: EDIT_LINK_LIST.SUCCESS,
    payload: { linkList }
});

// --------------------------------------------------------------

export const DELETE_LINK_LIST = {
    REQUEST: "DELETE_LINK_LIST_REQUEST",
    SUCCESS: "DELETE_LINK_LIST_SUCCESS"
};

export const deleteLinkList = (id: string): Action => ({
    type: DELETE_LINK_LIST.REQUEST,
    payload: id
});

export const deleteLinkListSuccess = (id: string): Action => ({
    type: DELETE_LINK_LIST.SUCCESS,
    payload: id
});

// -------------------------------------------------------------------
// update link list

export const EDIT_LINK_LIST_ITEM = {
    REQUEST: "EDIT_LINK_LIST_ITEM_REQUEST",
    SUCCESS: "EDIT_LINK_LIST_ITEM_SUCCESS"
};

export const editLinkListItem = (item: any): Action => ({
    type: EDIT_LINK_LIST_ITEM.REQUEST,
    payload: { ...item }
});

export const editLinkListItemSuccess = (
    linkListItem: LinkListItem
): Action => ({
    type: EDIT_LINK_LIST_ITEM.SUCCESS,
    payload: { linkListItem }
});

// --------------------------------------------------------------

export const DELETE_LINK_LIST_ITEM = {
    REQUEST: "DELETE_LINK_LIST_ITEM_REQUEST",
    SUCCESS: "DELETE_LINK_LIST_ITEM_SUCCESS"
};

export const deleteLinkListItem = (id: string, listId: string): Action => ({
    type: DELETE_LINK_LIST_ITEM.REQUEST,
    payload: { id, listId }
});

export const deleteLinkListItemSuccess = (
    id: string,
    listId: string
): Action => ({
    type: DELETE_LINK_LIST_ITEM.SUCCESS,
    payload: { id, listId }
});
