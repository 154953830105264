import React, { useEffect } from "react";
import { colors } from "src/constants";
import styled from "styled-components";
import { ReactComponent as RefreshIcon } from "src/components/icons/refresh.svg";
import { ProcessingControl } from "../hooks/useProcessing";
// import { clearProcessing } from "src/ui/scripts/actions";

const StyledRefreshIcon = styled(RefreshIcon)`
    height: 18px;
    width: 18px;
    color: ${colors.primaryGray};
    &:hover {
        color: ${colors.secondaryGray};
    }
`;
const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${colors.primaryGray};
`;

interface Props {
    isAssets?: boolean;
    currentCount: number;
    handleRefresh: () => void;
    processingControl: ProcessingControl;
}

const ProcessingMedias = ({
    isAssets,
    currentCount,
    handleRefresh,
    processingControl
}: Props) => {
    const key = isAssets ? processingControl.assets : processingControl.medias;
    const processingCounts = processingControl.current[key];

    useEffect(() => {
        if (
            processingCounts.processing &&
            processingCounts.expectedTotal &&
            processingCounts.expectedTotal === currentCount
        ) {
            processingControl.clear(key);
        }
    }, [processingCounts, currentCount]);

    const renderProcessing = () => {
        if (processingCounts.processing && processingCounts.expectedTotal) {
            const countDifference =
                processingCounts.expectedTotal - currentCount;
            return (
                <span>
                    {countDifference}{" "}
                    {countDifference > 1
                        ? key
                        : key.substring(0, key.length - 1)}{" "}
                    processing...
                </span>
            );
        }
    };

    return (
        <BtnWrap>
            <button onClick={handleRefresh}>
                <StyledRefreshIcon />
            </button>
            {processingCounts.processing ? renderProcessing() : null}
        </BtnWrap>
    );
};

export default ProcessingMedias;
