import React from "react";
import styled from "styled-components";
import {
    CampaignByDate,
    CampaignTotals,
    MediaByDate,
    MediaTotals
} from "./AnalyticsDataParser";
import { analyticsMediaToString } from "../util";
import { colors } from "../constants";

const Main = styled.div`
    padding: 10px;
    display: flex;
`;

const Tag = styled.div`
    height: 100%;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    padding-left: 6px;
    display: flex;
    align-items: center;
    font-size: 11px;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    margin-right: 6px;
    transition: 0.1s ease-in;

    &:last-of-type {
        margin-right: unset;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
            border: ${colors.darkTeal} solid 1px;
        }
    }
`;

const Close = styled.img`
    padding: 6px;
    opacity: 0.7;
`;

interface Props {
    selectedItems: Set<string>;
    setSelectedItems: (selectedItems: Set<string>) => void;
    parsedData: (CampaignTotals | MediaTotals | CampaignByDate | MediaByDate)[];
}

const AnalyticsControlTags = ({
    selectedItems,
    setSelectedItems,
    parsedData
}: Props) => {
    function renderTagName(item: string) {
        if (parsedData[0] && "campaign" in parsedData[0]) {
            const campaignObject = parsedData.find(data => {
                return "campaign" in data && data.campaign === item;
            });

            return campaignObject
                ? campaignObject.campaignName
                : `Campaign #${item}`;
        } else {
            const mediaObject = parsedData.find(data => {
                return "media" in data && data.media === item;
            });

            return mediaObject && "media" in mediaObject
                ? analyticsMediaToString(mediaObject)
                : `Media #${item}`;
        }
    }

    return (
        <Main>
            {Array.from(selectedItems).map(item => {
                const name = renderTagName(item);
                return (
                    <Tag
                        key={name}
                        onClick={e => {
                            e.stopPropagation();

                            const newSet = new Set(selectedItems);

                            newSet.delete(item);

                            setSelectedItems(newSet);
                        }}
                    >
                        {name}
                        <Close src={"/black_close.svg"} />
                    </Tag>
                );
            })}
        </Main>
    );
};

export default AnalyticsControlTags;
