import React from "react";
import styled from "styled-components";
import { Contest } from "../types";
import PrizeCarousel from "../components/PrizeCarousel";

const Background = styled.div`
    padding: 15px;
    background-color: #f8fbfc;
`;

const Container = styled.div`
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
`;

interface Props {
    contest: Contest;
}

const Carousel = ({ contest }: Props) => {
    return (
        <Background>
            <Container>
                <PrizeCarousel contest={contest} />
            </Container>
        </Background>
    );
};

export default Carousel;
