import React from "react";
import styled from "styled-components";
import Platforms from "../../../social-accounts/Platforms";

const Main = styled.div<{ large?: true }>`
    position: relative;
    height: ${props => (props.large ? "90px" : "56px")};
    width: ${props => (props.large ? "90px" : "56px")};
    min-width: ${props => (props.large ? "90px" : "56px")};
    margin-right: ${props => (props.large ? "15px" : "12px")};
    border-radius: ${props => (props.large ? "8px" : "4px")};
    overflow: hidden;
`;

const MainImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const PlatformIcon = styled.img<{ large?: true }>`
    position: absolute;
    right: ${props => (props.large ? "3px" : "2px")};
    bottom: ${props => (props.large ? "3px" : "2px")};
    width: ${props => (props.large ? "26px" : "16px")};
    height: ${props => (props.large ? "26px" : "16px")};
    object-fit: cover;
    border-radius: 4px;
`;

interface Props {
    platform: string;
    avatar: string | null;
    verified: boolean;
    large?: true;
}

const AccountIcon = ({ platform, avatar, verified, large }: Props) => {
    const platformImg = (Platforms[platform.toLowerCase()] || Platforms.other)
        .imgSrc;

    return (
        <Main large={large}>
            <MainImage src={verified && avatar ? avatar : platformImg} />
            {verified && avatar && (
                <PlatformIcon large={large} src={platformImg} />
            )}
        </Main>
    );
};

export default AccountIcon;
