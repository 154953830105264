import React from "react";

import { Props } from "src/components/icons/types";

const Download = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || "10"}
            viewBox="0 0 10 10"
            fill="none"
        >
            <g
                clipPath="url(#A)"
                fillRule="evenodd"
                fill={props.fill || "#000"}
            >
                <path d="M.9 9.231c-.212 0-.385.172-.385.385S.687 10 .9 10h8.205c.212 0 .385-.172.385-.385s-.172-.385-.385-.385H.9zM5.387.385C5.387.172 5.215 0 5.002 0s-.385.172-.385.385V6.38L3.223 4.984c-.15-.15-.394-.15-.544 0s-.15.394 0 .544L4.73 7.58c.15.15.394.15.544 0l2.051-2.051c.15-.15.15-.394 0-.544s-.394-.15-.544 0L5.387 6.379V.385z" />
            </g>
            <defs>
                <clipPath id="A">
                    <path fill="#fff" d="M0 0h10v10H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Download;
