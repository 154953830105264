import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { colors } from "../constants";

const Main = styled.div`
    position: relative;
    width: 168px;
    height: 168px;
    margin: 0rem auto;
`;

const Empty = styled.div<{ nux?: true }>`
    height: 100%;
    width: 100%;
    border-radius: 100%;
    background-color: ${props => (props.nux ? colors.tertiaryGreen : "white")};
    ${props => !props.nux && `border: 1px solid ${colors.mediumTeal};`}
`;

const ProfilePhoto = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
`;

const Overlay = styled.label<{ screen: boolean; color: string }>`
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    color: ${props => props.color};
    ${props => props.screen && "background-color: rgba(0,0,0,0.3);"}
`;

interface Props {
    nux?: true;
    photoURL: string | null;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const PhotoUpload = ({ nux, photoURL, onChange }: Props) => {
    return (
        <Main>
            {photoURL ? <ProfilePhoto src={photoURL} /> : <Empty nux={nux} />}
            <Overlay
                screen={Boolean(photoURL)}
                color={photoURL || nux ? "white" : colors.primaryBlue}
            >
                <svg
                    width="43"
                    height="38"
                    viewBox="0 0 43 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M17.956 1.93526H17.9452L17.9343 1.93548C16.918 1.95616 16.025 2.48362 15.3266 3.06843C14.6115 3.6672 13.9734 4.43279 13.4513 5.1549C12.9247 5.88331 12.4899 6.60417 12.1879 7.13966C12.1245 7.25219 12.0666 7.357 12.0147 7.45251H6.63537C3.97626 7.45251 1.93554 9.76093 1.93554 12.425V31.0917C1.93554 33.7558 3.97626 36.0642 6.63537 36.0642H36.526C39.1851 36.0642 41.2258 33.7558 41.2258 31.0917V12.425C41.2258 9.76093 39.1851 7.45251 36.526 7.45251H31.1403C31.0853 7.35311 31.0237 7.24345 30.9559 7.12533C30.6468 6.58693 30.2035 5.86266 29.6717 5.13257C29.1447 4.4091 28.5044 3.64305 27.7949 3.04756C27.1067 2.46982 26.2157 1.93626 25.2056 1.93526H25.2046H17.956Z"
                        stroke="currentColor"
                        stroke-width="2.12898"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.3226 20.5474C13.3226 15.9964 17.0297 12.2893 21.5806 12.2893C26.1317 12.2893 29.8387 15.9964 29.8387 20.5474C29.8387 25.0984 26.1317 28.8054 21.5806 28.8054C17.0297 28.8054 13.3226 25.0984 13.3226 20.5474ZM27.7743 20.5482C27.7743 17.1184 25.0105 14.3547 21.5807 14.3547C18.151 14.3547 15.3872 17.1185 15.3872 20.5482C15.3872 23.978 18.151 26.7418 21.5807 26.7418C25.0105 26.7418 27.7743 23.978 27.7743 20.5482Z"
                        fill="currentColor"
                    />
                </svg>
                <input
                    type={"file"}
                    accept={"image/jpeg"}
                    style={{ display: "none" }}
                    onChange={onChange}
                />
            </Overlay>
        </Main>
    );
};

export default PhotoUpload;
