import React, { useEffect } from "react";
import {
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided,
    DropResult
} from "react-beautiful-dnd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCurrentOpenBuy } from "src/admin-tools/selectors.admin-tools";
import { colors, fonts } from "src/constants";
import { GlobalState } from "src/reducers";
import {
    blankInstructionStepState,
    InstructionStepState,
    setInstructionsSetSubmitStatus,
    setInstructionSteps,
    setInstructionsUnsaved
} from "src/ui/instructions-sets/actions";
import {
    clearButton,
    hideScrollbar,
    preventUserInteraction
} from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import { getCurrentInstructionsSet } from "../selectors";
import InstructionsFormHeader from "./InstructionsFormHeader";
import InstructionsFormStep from "./InstructionsFormStep";

const Main = styled.section`
    position: relative;
    z-index: 600;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 18px;
    background-color: ${colors.secondaryLightGray};
    border: ${colors.quaternaryLightGray} solid 1px;
    border-radius: 8px;
    overflow: hidden;
`;

const InstructionStepsContainer = styled.div`
    position: relative;
    flex: 1;
`;

const InstructionSteps = styled.div`
    ${hideScrollbar()};
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    overflow-y: auto;
`;

const InstructionFormStepDraggable = styled.div``;

const AddInstructionStepButton = styled.button`
    ${clearButton()};
    padding: 12px 0px;
    border-radius: 8px;
    color: ${colors.white};
    background-color: ${colors.primaryBlue};
    font-size: ${fonts.subtitle}px;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

interface Prop {
    dropResult: DropResult | null;
    title: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
}

const InstructionsForm = ({ dropResult, title, setTitle }: Prop) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const {
        instructionSteps,
        instructionsPageType,
        instructionsSetSubmitStatus,
        isCurrentDragTargetAnInstructionElement
    } = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets;
    }, shallowEqual);

    const currentInstructionsSet = useSelector(getCurrentInstructionsSet);

    // Local Constants -----------------------------------------------
    const steps = instructionSteps.map(
        (instructionStep: InstructionStepState, index: number) => {
            if (instructionsPageType === "Main") {
                return (
                    <InstructionsFormStep
                        key={`instructions-form-step-${instructionStep.description}-${index}`}
                        myIndex={index}
                        myPosition={index + 1}
                        description={instructionStep.description}
                        dropResult={dropResult}
                    />
                );
            } else {
                return (
                    <Draggable
                        key={`instructions-form-step-${instructionStep.description}-${index}`}
                        draggableId={`${index + 1}`}
                        index={index}
                    >
                        {(provided: DraggableProvided) => (
                            <InstructionFormStepDraggable
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <InstructionsFormStep
                                    myIndex={index}
                                    myPosition={index + 1}
                                    description={instructionStep.description}
                                    dropResult={dropResult}
                                />
                            </InstructionFormStepDraggable>
                        )}
                    </Draggable>
                );
            }
        }
    );

    // Local Functions -----------------------------------------------
    function addNewEmptyInstructionStep(): void {
        dispatch(
            setInstructionsSetSubmitStatus(
                "updating data for adding new instruction"
            )
        );
    }

    // Effects -------------------------------------------------------
    useEffect(() => {
        if (currentInstructionsSet) {
            dispatch(
                setInstructionsUnsaved(title !== currentInstructionsSet.name)
            );
        }
    }, [currentInstructionsSet, title]);

    useEffect(() => {
        // Adding a new instruction step
        if (
            instructionsSetSubmitStatus ===
            "finish updating data for adding new instruction"
        ) {
            const updatedInstructionSteps = Array.from(instructionSteps);
            updatedInstructionSteps.push(blankInstructionStepState());

            dispatch(setInstructionSteps(updatedInstructionSteps));
            dispatch(setInstructionsSetSubmitStatus("not submitting"));
        }
    }, [instructionsSetSubmitStatus]);

    // JSX -----------------------------------------------------------
    return (
        <Main>
            <InstructionsFormHeader title={title} setTitle={setTitle} />

            <InstructionStepsContainer>
                <Droppable
                    droppableId="InstructionsFormDroppable"
                    isDropDisabled={isCurrentDragTargetAnInstructionElement}
                >
                    {(provided: DroppableProvided) => {
                        if (instructionsPageType === "Main") {
                            return (
                                <InstructionSteps>
                                    {steps}
                                    {provided.placeholder}
                                </InstructionSteps>
                            );
                        } else {
                            return (
                                <InstructionSteps
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {steps}

                                    {provided.placeholder}

                                    <AddInstructionStepButton
                                        type="button"
                                        onClick={addNewEmptyInstructionStep}
                                    >
                                        Add Another Instruction
                                    </AddInstructionStepButton>
                                </InstructionSteps>
                            );
                        }
                    }}
                </Droppable>
            </InstructionStepsContainer>
        </Main>
    );
};

export default InstructionsForm;
