const enum featureFlags {
    PaymentSets = "PaymentSets",
    DelayNameOnboarding = "DelayNameOnboarding",
    DelayEmailOnboarding = "DelayEmailOnboarding",
    DelayBirthdayOnboarding = "DelayBirthdayOnboarding",
    DelayRulesOnboarding = "DelayRulesOnboarding",
    DollarAmountsOnChallengeLeaderboards = "DollarAmountsOnChallengeLeaderboards",
    ChallengesGroupedSubmissions = "ChallengesGroupedSubmissions",
    SubmissionSetV2 = "SubmissionSetV2",
    DirectBuyNUX = "DirectBuyNUX",
    PromoteFlow = "PromoteFlow",
    ShowHighlights = "ShowHighlights",
    Chat2 = "Chat2",
    AccountRates = "AccountRates",
    InstagramBasicDisplayVerification = "InstagramBasicDisplayVerification",
    InstagramCodeVerification = "InstagramCodeVerification",
    snapchatLoginKitVerification = "snapchatLoginKitVerification",
    snapchatCodeVerification = "snapchatCodeVerification",
    tiktokTCMVerification = "tiktokTCMVerification",
    tiktokCodeVerification = "tiktokCodeVerification",
    twitterCodeVerification = "twitterCodeVerification",
    facebookLoginKitVerification = "facebookLoginKitVerification",
    facebookCodeVerificatioin = "facebookCodeVerificatioin",
    youtubeCodeVerification = "youtubeCodeVerification",
    twitchCodeVerification = "twitchCodeVerification",
    tumblrCodeVerification = "tumblrCodeVerification"
}

export default featureFlags;
