import React, { useEffect, useState } from "react";
import {
    DirectBuyStep,
    DirectBuyStepCollection
} from "../../../modules/direct-buy-step/types";
import { DirectBuyStepsEntitiesState } from "../../../modules/direct-buy-step/reducer";
import { ResourceContainer, ResourceDescription } from "../styledComponents";
import { campaignScriptModal } from "../../../../ui/scripts/actions";
import { directBuyDetailsModal } from "../../../../ui/direct-buys/actions";
import { DirectBuyDetailsModalState } from "../../../../ui/direct-buys/reducer";
import { addScript } from "../../../../campaigns/scripts/actions";
import { useDispatch } from "react-redux";
import { StepById } from "src/buys/modules/open-buys/reducer";
import ScriptRow from "src/campaigns/details/components/ScriptRow";
import ScriptRowLoading from "src/campaigns/details/components/ScriptRowLoading";
import { shuffle } from "lodash";
import { OpenBuyInstructionStep } from "src/buys/modules/open-buys/types";
import { get } from "src/Api";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";
import { setPillNotificationText } from "src/ui/notifications/actions";

interface ScriptAssigment {
    publisherId: number;
    openBuyId: number;
    scriptId: number;
}
interface Props {
    collection: DirectBuyStepCollection;
    steps: DirectBuyStepsEntitiesState | StepById;
    actionCallback: VoidFunction;
    openBuyId: number | null;
}

const StyledSpinner = styled(Spinner)`
    height: 10px;
    width: 10px;
    margin-bottom: 2px;
    margin-left: 4px;
`;

const DirectBuyDetailsScripts = ({
    collection,
    steps,
    actionCallback,
    openBuyId
}: Props) => {
    const dispatch = useDispatch();
    const scriptSteps = collection.combined
        .filter(id => steps[id].script)
        .map(id => steps[id]);

    const [shuffled, setShuffled] = useState<boolean>(false);
    const [scriptsOrdered, setScriptsOrdered] = useState<
        (DirectBuyStep | OpenBuyInstructionStep)[]
    >(shuffle(scriptSteps));
    useEffect(() => {
        if (shuffled) return;
        if (scriptSteps.length === 0) return;
        setScriptsOrdered(shuffle(scriptSteps));
        setShuffled(true);
    }, [collection, steps]);

    const [scriptAssignments, setScriptAssignments] = useState<
        ScriptAssigment[] | null
    >(null);
    const [scriptAssignmentsLoading, setScriptAssignmentsLoading] = useState<
        boolean
    >(false);
    async function fetchScriptAssignments() {
        if (!openBuyId) return;
        if (scriptSteps.length === 0) return;
        setScriptAssignmentsLoading(true);
        try {
            const { data } = await get(
                `/v1/openBuy/${openBuyId}/scriptAssignments`,
                {}
            );
            setScriptAssignments(data.data);
        } catch (err) {
            dispatch(
                setPillNotificationText(
                    "Error loading script assignments",
                    "danger"
                )
            );
        } finally {
            setScriptAssignmentsLoading(false);
        }
    }
    useEffect(() => {
        fetchScriptAssignments();
    }, [openBuyId]);

    if (scriptSteps.length === 0) {
        return null;
    }
    return (
        <ResourceContainer>
            <ResourceDescription>
                SCRIPTS
                {scriptAssignmentsLoading && (
                    <StyledSpinner animation="border" role="status" />
                )}
            </ResourceDescription>
            {!scriptAssignmentsLoading &&
                scriptsOrdered
                    .filter(step => {
                        if (
                            !scriptAssignments ||
                            scriptAssignments.length === 0
                        ) {
                            return true;
                        }
                        return scriptAssignments.some(
                            assignment =>
                                assignment.scriptId === step?.script?.id
                        );
                    })
                    .map((step, i) => {
                        const script = step.script;
                        if (script) {
                            return (
                                <ScriptRow
                                    key={`${i} script`}
                                    script={script}
                                    handleClick={() => {
                                        actionCallback();
                                        dispatch(addScript(script));
                                        dispatch(
                                            campaignScriptModal(script.id)
                                        );
                                        dispatch(
                                            directBuyDetailsModal(
                                                DirectBuyDetailsModalState.script
                                            )
                                        );
                                    }}
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
            {scriptAssignmentsLoading && <ScriptRowLoading />}
        </ResourceContainer>
    );
};

export default DirectBuyDetailsScripts;
