import { deleteReq, get, patch, post } from "src/Api";
import { OpenBuySubmission } from "src/buys/modules/open-buys/types";
import {
    convertOpenBuyPaymentsToPendingGroups,
    convertPendingGroupsToOpenBuyPayments
} from "src/marketplace/payment-sets/open-buy-payments/util";
import { PendingOpenBuyPaymentsGroup } from "src/marketplace/payment-sets/types";

export async function setPayout(
    submission: OpenBuySubmission,
    newPayout: number
): Promise<void> {
    if (submission.amountToBePaid && submission.amountToBePaid === newPayout)
        return;
    const payments = await getPayments(submission.openBuyId);

    // Remove creator from existing creator specific payments
    const groupIDs = extractGroupIDs(payments);
    const memberGroupIDs = await queryGroupMembership(
        submission.publisherId,
        groupIDs
    );
    for (const memberGroupID of memberGroupIDs) {
        await deleteReq(
            `/v1/creatorGroup/${memberGroupID}/removePublisher/${submission.publisherId}`
        );
    }

    // Attempt to find a group this creator can be added to
    let paymentGroupID = findGroupWithPayment(newPayout, payments);
    if (paymentGroupID !== null) {
        await addPublisherToGroup(submission.publisherId, paymentGroupID);
    } else {
        paymentGroupID = await makeCreatorGroupWithPublisher(
            submission.publisherId
        );
    }

    addPayment(payments, paymentGroupID, newPayout);
    await overwritePayments(payments, submission.openBuyId);

    const checkPaymentAmount = await getPaymentAmount(submission);
    if (checkPaymentAmount !== newPayout) {
        throw new Error(
            `Payout mismatch! Set to ${newPayout}, received ${checkPaymentAmount}`
        );
    }
}

async function getPayments(openBuyId: number) {
    const res = await get(`/v1/openBuyPayment/${openBuyId}`, {});
    return convertOpenBuyPaymentsToPendingGroups(res.data.data);
}

function extractGroupIDs(payments: PendingOpenBuyPaymentsGroup[]): number[] {
    let idSet = new Set<number>();
    for (const payment of payments) {
        Object.keys(payment.creatorSpecific).forEach(k =>
            idSet.add(parseInt(k))
        );
    }
    return [...idSet];
}

async function queryGroupMembership(
    publisherId: number,
    groupIDs: number[]
): Promise<number[]> {
    if (groupIDs.length === 0) return [];
    const res = await get("/v1/creatorGroup/queryMembership", {
        publisherId,
        creatorGroupIDs: groupIDs
    });
    return res.data.data.creatorGroupIDs as number[];
}

function findGroupWithPayment(
    amount: number,
    payments: PendingOpenBuyPaymentsGroup[]
): number | null {
    for (const paymentSet of payments) {
        for (const key in paymentSet.creatorSpecific) {
            const groupID = parseInt(key);
            const bucket = paymentSet.creatorSpecific[key];
            if (bucket.length !== 1) continue;
            if (parseFloat(bucket[0].amount) === amount) {
                return groupID;
            }
        }
    }
    return null;
}

async function addPublisherToGroup(publisherId: number, groupID: number) {
    await patch(`/v1/creatorGroup/${groupID}/addPublisher/${publisherId}`);
}

async function makeCreatorGroupWithPublisher(
    publisherId: number
): Promise<number> {
    const res = await post(`/v1/creatorGroup`, {
        name: `Generated Payment Group ${Date.now()}`,
        publisherIds: [publisherId]
    });

    return res.data.data.id;
}

function addPayment(
    payments: PendingOpenBuyPaymentsGroup[],
    newGroupID: number,
    newPayout: number
): void {
    if (payments.length < 1) {
        throw new Error("Malformed Payments Object");
    }
    // All payment buckets need to have the new payment. The server will
    // pay the creator by using Math.max() on the payout for each bucket
    for (let i = 0; i < payments.length; i++) {
        // guard clause to not add the group to payments if it already exists
        if (payments[i].creatorSpecific[newGroupID] !== undefined) continue;

        payments[i].creatorSpecific[newGroupID] = [
            {
                amount: newPayout.toString(),
                paymentId: 2, // This somehow corresponds to flat fee payment
                viewsMinimum: ""
            }
        ];
    }
}

async function overwritePayments(
    payments: PendingOpenBuyPaymentsGroup[],
    openBuyId: number
): Promise<void> {
    const openBuyPayments = convertPendingGroupsToOpenBuyPayments(payments);
    await patch(`/v1/openBuyPayment/${openBuyId}`, {
        openBuyPayments
    });
}

export async function getPaymentAmount(
    submission: OpenBuySubmission
): Promise<number> {
    const res = await get(
        `/v1/openBuy/submission/${submission.id}/getPayout`,
        {}
    );
    return res.data.data.paymentAmount;
}
