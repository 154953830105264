import { Action } from "../types";
import { Notification, MediaStatus } from "./types";
import {
    ADD_SYSTEM_NOTIFICATIONS,
    ADD_TRANSACTION_NOTIFICATIONS,
    READ_NOTIFICATIONS,
    ADD_MEDIA_STATUS_NOTIFICATION,
    REMOVE_MEDIA_STATUS_NOTIFICATION,
    FETCH_MEDIA_NOTIFICATION
} from "./actions";
import { FETCH_DELAYED_CAMPAIGNS } from "./delayed-campaigns/actions";
import { Media } from "src/media/types";
import { CachedMedia } from "./saga";

export interface NotificationsState {
    systemNotifications: Notification[];
    transactionNotifications: Notification[];
    mediaStatusNotification: MediaStatus | null;
    unread: boolean;
    delayedCampaignLoading: boolean;
    delayedCampaignInstallsTitle: string;
    timeAppOpened: number;
}

const initialNotificationsState: NotificationsState = {
    systemNotifications: [],
    transactionNotifications: [],
    mediaStatusNotification: null,
    unread: false,
    delayedCampaignLoading: true,
    delayedCampaignInstallsTitle: "",
    timeAppOpened: Date.now()
};

const reducer = (state = initialNotificationsState, action: Action) => {
    switch (action.type) {
        case ADD_SYSTEM_NOTIFICATIONS:
            return { ...state, ...action.payload, unread: true };
        case ADD_TRANSACTION_NOTIFICATIONS:
            return { ...state, ...action.payload, unread: true };
        case READ_NOTIFICATIONS:
            return { ...state, unread: false };
        case ADD_MEDIA_STATUS_NOTIFICATION:
            return { ...state, ...action.payload };
        case REMOVE_MEDIA_STATUS_NOTIFICATION:
            if (action.payload.id) {
                let notifs: CachedMedia[] = JSON.parse(
                    localStorage.getItem("mediaNotifications") || "[]"
                );
                notifs = notifs?.filter(
                    (media: CachedMedia) => media.id !== action.payload.id
                );
                localStorage.setItem(
                    "mediaNotifications",
                    JSON.stringify(notifs) || "[]"
                );
            }
            return { ...state, mediaStatusNotification: null };
        case FETCH_MEDIA_NOTIFICATION.SUCCESS:
            return {
                ...state,
                mediaStatusNotification: {
                    type: "approved",
                    media: action.payload
                }
            };
        case FETCH_DELAYED_CAMPAIGNS.REQUEST:
            return { ...state, delayedCampaignLoading: true };
        case FETCH_DELAYED_CAMPAIGNS.SUCCESS:
            return {
                ...state,
                delayedCampaignLoading: false,
                delayedCampaignInstallsTitle:
                    action.payload.delayedCampaignInstallsTitle
            };
        default:
            return state;
    }
};

export default reducer;
