import { Action } from "src/types";
import { CampaignFilters, Country } from "src/campaigns/types";

// Campaign Modal -----------------------------------------------

export const CAMPAIGN_MODAL = {
    SCRIPT: "CAMPAIGN_MODAL_SCRIPT",
    SCRIPT_VIEW: "SCRIPT_VIEW",
    SCRIPT_WARNING: "SCRIPT_WARNING",
    SCRIPT_CREATE: "SCRIPT_CREATE",
    CUSTOM_LINKS: "CAMPAIGN_MODAL_CUSTOM_LINKS",
    DOWNLOAD_TYPES: "CAMPAIGN_MODAL_DOWNLOAD_TYPES"
};

// Campaign Max Items -----------------------------------------------

export const CAMPAIGN_MAX_ITEMS = "CAMPAIGN_MAX_ITEMS";

export function incrementCampaignMaxItems(maxCampaignCount: number): Action {
    return {
        type: CAMPAIGN_MAX_ITEMS,
        payload: maxCampaignCount
    };
}

// Set Current Campaign Id -----------------------------------------------

export const SET_CURRENT_CAMPAIGN_ID = "SET_CURRENT_CAMPAIGN_ID";

export const setCurrentCampaignId = (campaignId: number | null): Action => ({
    type: SET_CURRENT_CAMPAIGN_ID,
    payload: { campaignId }
});

// Set Campaign Media Modal Items -----------------------------------------------

export const SET_CAMPAIGN_MEDIA_MODAL_ITEMS = "SET_CAMPAIGN_MEDIA_MODAL_ITEMS";

export function setCampaignMediaModalItems(
    currentModalItem: number,
    mediaCarouselLength: number
): Action {
    let previousModalItem: number;
    let nextModalItem: number;

    if (currentModalItem - 1 < 0) {
        previousModalItem = mediaCarouselLength - 1;
    } else {
        previousModalItem = currentModalItem - 1;
    }

    if (currentModalItem + 1 > mediaCarouselLength) {
        nextModalItem = 0;
    } else {
        nextModalItem = currentModalItem + 1;
    }

    return {
        type: SET_CAMPAIGN_MEDIA_MODAL_ITEMS,
        payload: [previousModalItem, currentModalItem, nextModalItem]
    };
}

// Set Current Campaign Tab -----------------------------------------------

export type MediaTab = "Stories" | "Square" | "Yours";

export const SET_CURRENT_MEDIA_TAB = "SET_CURRENT_MEDIA_TAB";

export function setCurrentMediaTab(tab: MediaTab): Action {
    return {
        type: SET_CURRENT_MEDIA_TAB,
        payload: tab
    };
}

// Current Loaded Medias -----------------------------------------------

export const SET_CURRENT_LOADED_MEDIAS = "SET_CURRENT_LOADED_MEDIAS";

export function setCurrentLoadedMedias(loadedMedia: number[]): Action {
    return {
        type: SET_CURRENT_LOADED_MEDIAS,
        payload: loadedMedia
    };
}

// Campaigns Sort By & Filter ---------------------------------------------------------------

export const SET_CAMPAIGN_SORT = "SET_CAMPAIGN_SORT";

export const setCampaignSort = (sortBy: string): Action => ({
    type: SET_CAMPAIGN_SORT,
    payload: sortBy
});

export const SET_CAMPAIGN_FILTERS = "SET_CAMPAIGN_FILTER";

export const setCampaignFilters = (filters: CampaignFilters): Action => ({
    type: SET_CAMPAIGN_FILTERS,
    payload: filters
});

// Campaigns Filter - Genres ---------------------------------------------------------------

export const FETCH_CAMPAIGN_GENRES = {
    REQUEST: "FETCH_GENRES_REQUEST",
    SUCCESS: "FETCH_GENRES_SUCCESS",
    FAILURE: "FETCH_GENRES_FAILURE"
};

export const fetchCampaignGenres = (): Action => ({
    type: FETCH_CAMPAIGN_GENRES.REQUEST,
    payload: null
});

export const fetchCampaignGenresSuccess = (
    campaignGenres: string[]
): Action => ({
    type: FETCH_CAMPAIGN_GENRES.SUCCESS,
    payload: campaignGenres
});

export const fetchCampaignGenresFailure = (): Action => ({
    type: FETCH_CAMPAIGN_GENRES.FAILURE,
    payload: null
});

// Campaigns Filter - Regions ---------------------------------------------------------------

export const FETCH_CAMPAIGN_REGIONS = {
    REQUEST: "FETCH_REGIONS_REQUEST",
    SUCCESS: "FETCH_REGIONS_SUCCESS",
    FAILURE: "FETCH_REGIONS_FAILURE"
};

export const fetchCampaignRegions = (): Action => ({
    type: FETCH_CAMPAIGN_REGIONS.REQUEST,
    payload: null
});

export const fetchCampaignRegionsSuccess = (
    campaignRegions: Country[]
): Action => ({
    type: FETCH_CAMPAIGN_REGIONS.SUCCESS,
    payload: campaignRegions
});

export const fetchCampaignRegionsFailure = (): Action => ({
    type: FETCH_CAMPAIGN_REGIONS.FAILURE,
    payload: null
});
