import React from "react";
import styled from "styled-components";
import { ReactComponent as Cross } from "../../../components/icons/cross.svg";
import { ModalTitle } from "../../../components/text/CommonStyles";
import SimpleNavbar from "../../../components/SimpleNavbar";
import { colors } from "../../../constants";
import FullScreenModal from "../../../modals/FullScreenModal";
import { FlexContent } from "../../../components/CommonStyles";
import Title from "../../../components/text/Title";
import Subtitle from "../../../components/text/Subtitle";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../reducers";
import { getAccountsWithSearch } from "../../accounts/selectors";
import AccountCell from "../../accounts/components/AccountCell";
import { BigBlueButton } from "../../components/Buttons";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const CrossIcon = styled(Cross)`
    cursor: pointer;
`;

const Header = styled.div`
    padding: 18px;

    & > * ~ * {
        margin-top: 4px;
    }
`;

const AccountsList = styled.div`
    padding: 0 18px 10px;
`;

const Checkbox = styled.img`
    width: 24px;
    height: 24px;
`;

const ConfirmButtonWrapper = styled.div`
    border-top: 1px solid ${colors.mediumTeal};
    padding: 12px 18px 24px;
    margin-top: auto;
`;

const ConfirmButton = styled(BigBlueButton)`
    font-weight: 500;

    &:disabled {
        opacity: 0.5;
    }
`;

interface Props {
    closeModal: () => void;
    accountId: number | null;
    setAccountId: (id: number) => void;
}

const AccountSelectionModal = ({
    closeModal,
    accountId,
    setAccountId
}: Props) => {
    const filteredSocialAccounts = useSelector((state: GlobalState) =>
        getAccountsWithSearch(state, "")
    );

    return (
        <FullScreenModal closeModal={closeModal}>
            <Main>
                <SimpleNavbar
                    title={<ModalTitle>ADD ACCOUNT</ModalTitle>}
                    left={
                        <CrossIcon
                            fill={colors.primaryGray}
                            onClick={closeModal}
                        />
                    }
                />
                <FlexContent>
                    <Header>
                        <Title>Which Account?</Title>
                        <Subtitle>Serlect an option below</Subtitle>
                    </Header>
                    <AccountsList>
                        {filteredSocialAccounts.map(account => (
                            <AccountCell
                                key={account.id}
                                account={account}
                                action={
                                    account.id === accountId ? (
                                        <Checkbox
                                            src={"/checkbox_selected.svg"}
                                        />
                                    ) : (
                                        <Checkbox
                                            src={"/checkbox_unselected.svg"}
                                        />
                                    )
                                }
                                onClick={() => setAccountId(account.id)}
                            />
                        ))}
                    </AccountsList>
                </FlexContent>
                <ConfirmButtonWrapper>
                    <ConfirmButton onClick={closeModal}>Select</ConfirmButton>
                </ConfirmButtonWrapper>
            </Main>
        </FullScreenModal>
    );
};

export default AccountSelectionModal;
