import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOpenBuyStepEntities, selectYourSets } from "src/buys/selectors";
import { getAccountsWithSearch } from "src/profile/accounts/selectors";
import { fetchAccountsUser } from "src/profile/actions";
import { OutlineButton } from "src/profile/components/Buttons";
import SectionTitle from "src/profile/components/SectionTitle";
import { SocialAccount } from "src/profile/types";
import { GlobalState } from "src/reducers";
import Platforms from "src/social-accounts/Platforms";
import { addNotification } from "src/ui/notifications/actions";
import {
    addAccountModalOpen,
    editAccountModalOpen
} from "src/ui/profile/actions";
import styled from "styled-components";
import SearchBar from "../../../components/SearchBar";
import SocialAccountHeader from "../../../components/social-accounts/SocialAccountHeader";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0px 18px;
`;

const AccountList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Account = styled.div<{ inactive: boolean }>`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    opacity: ${props => (props.inactive ? ".6" : "1")};
`;

const Checkbox = styled.img`
    padding-right: 12px;
    margin-left: auto;
`;

const AddButton = styled(OutlineButton)`
    height: 48px;
`;

export interface SearchTagType {
    text: string;
    value: string;
}

interface Props {
    platforms?: string[];
    onSelect: (account: SocialAccount) => void;
    openBuy: OpenBuy;
}

const SocialAccountSelector = ({ platforms, onSelect, openBuy }: Props) => {
    const [searchString, setSearchString] = useState("");

    const socialAccounts = useSelector((state: GlobalState) =>
        getAccountsWithSearch(state, searchString)
    );
    const submissions = useSelector(
        (state: GlobalState) => state.entities.openBuySubmissions
    );
    const yourSubmissions = openBuy?.openBuySubmissions || [];
    const steps = useSelector(selectOpenBuyStepEntities);
    const submissionSets = useSelector(
        (state: GlobalState) => state.entities.openBuySubmissionSets
    );
    const yourSets = useSelector((state: GlobalState) =>
        selectYourSets(state, openBuy.id)
    );
    const currentStep = useSelector(
        (state: GlobalState) => state.ui.directBuys.openBuySubmissionStep
    );
    const treatSet = useSelector(
        selectFeatureFlags(featureFlags.ChallengesGroupedSubmissions)
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAccountsUser());
    }, []);

    const getSubmissionCount = (socialAccountId: number) => {
        let count = 0;
        if (treatSet) {
            return yourSets.filter(
                set => submissionSets[set].socialAccountId === socialAccountId
            ).length;
        }
        yourSubmissions?.forEach(id => {
            let submission = submissions[id];
            if (
                submission &&
                submission.socialAccount?.id === socialAccountId &&
                submission.instructionStepId === currentStep
            )
                count++;
        });
        return count;
    };

    const isDisabled = (account: SocialAccount) => {
        if (platforms && !platforms.includes(account.platform)) return true;
        else return false;
    };

    const openAddAccountFormModal = (): void => {
        dispatch(addAccountModalOpen(true));
        dispatch(editAccountModalOpen(false));
    };

    return (
        <Main>
            <SearchBar
                searchString={searchString}
                updateSearchString={v => setSearchString(v)}
            />
            <SectionTitle>{socialAccounts.length} ACCOUNTS</SectionTitle>
            <AccountList>
                {socialAccounts.map(account => (
                    <Account
                        key={`accoung-${account.id}`}
                        inactive={isDisabled(account)}
                        onClick={() => {
                            let step = steps[currentStep || 0] || null;
                            if (isDisabled(account))
                                dispatch(
                                    addNotification(
                                        "This account is on the wrong platform",
                                        "red"
                                    )
                                );
                            else if (
                                openBuy.submissionLimitPerSocialAccount &&
                                getSubmissionCount(account.id) >=
                                    openBuy.submissionLimitPerSocialAccount
                            )
                                dispatch(
                                    addNotification(
                                        "Max Submissions Reached",
                                        "red"
                                    )
                                );
                            else onSelect(account);
                        }}
                    >
                        <SocialAccountHeader
                            picture={
                                account.profilePhotoUrl ||
                                Platforms[account.platform]?.imgSrc
                            }
                            username={account.username}
                            count={
                                getSubmissionCount(account.id) + " SUBMISSIONS"
                            }
                        />
                        <Checkbox src="/checkbox_unselected.svg" />
                    </Account>
                ))}
                <AddButton onClick={openAddAccountFormModal}>
                    Add Account
                </AddButton>
            </AccountList>
        </Main>
    );
};

export default SocialAccountSelector;
