import React, { useEffect, useState } from "react";
import { get } from "src/Api";
import { OpenBuySubmissionSet } from "src/buys/modules/open-buys/types";
import ModalBox from "src/components/modals/components/ModalBox";
import PlugTable from "src/components/table/PlugTable";
import { TableColumn, TableRow } from "src/components/table/types";
import { createCell } from "src/components/table/utils";
import { colors } from "src/constants";
import styled from "styled-components";

const Text = styled.div`
    font-size: 12px;
    color: ${colors.primaryGray};
`;

const columns: TableColumn[] = [
    { coreKey: "reason", displayValue: "Reason", columnWidth: "180px" },
    { coreKey: "adminEmail", displayValue: "Admin Email" },
    { coreKey: "createdAt", displayValue: "Created At" }
];

const Infractions = ({
    submissionSet
}: {
    submissionSet: OpenBuySubmissionSet;
}) => {
    const [infractions, setInfractions] = useState([]);
    const [loading, setLoading] = useState(false);
    const tableData: TableRow[] = infractions.map(
        (infraction: any, idx: number) => ({
            originalIdx: idx,
            raw: infraction,
            rowData: {
                reason: createCell.string(infraction.reason),
                adminEmail: createCell.string(infraction.admin_email),
                createdAt: createCell.date(infraction.createdAt)
            }
        })
    );

    const fetchInfractions = async () => {
        setLoading(true);

        try {
            let res = await get(
                `/v1/admin/publisher/${submissionSet.publisherId}/infractions`,
                {}
            );
            setInfractions(res.data.data);
        } catch (err) {
            console.error("err fetching infractions: ", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!infractions.length && !loading) fetchInfractions();
    }, [submissionSet]);
    return (
        <ModalBox headingText="INFRACTIONS">
            {infractions.length > 0 ? (
                <PlugTable
                    columns={columns}
                    rowHeight={36}
                    resultsPerPage={4}
                    cellData={tableData}
                />
            ) : (
                <Text>
                    <em>No Infractions</em>
                </Text>
            )}
        </ModalBox>
    );
};

export default Infractions;
