import React, { useEffect } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { freezeBody, thawBody } from "../util";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";

const Parent = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2000;
    display: flex;
    justify-content: center;
`;

const Child = styled.div`
    width: 100vw;
    max-width: 500px;
`;

interface Props {
    children: any;
}

const CarouselModal = ({ children }: Props) => {
    const promoting = useSelector(
        (state: GlobalState) => state.ui.promote.promoting
    );
    useEffect(() => {
        localStorage.setItem("carouselOpen", "1");
        freezeBody();

        return () => {
            localStorage.removeItem("carouselOpen");
            if (!promoting) thawBody();
        };
    }, []);

    return (
        <Parent>
            <Child>
                <Carousel
                    containerClass="carousel-modal"
                    responsive={{
                        universal: {
                            breakpoint: { max: 100000, min: 0 },
                            items: 1
                        }
                    }}
                    arrows={false}
                    swipeable
                    draggable
                    infinite
                >
                    {children}
                </Carousel>
            </Child>
        </Parent>
    );
};

export default CarouselModal;
