import React from "react";
import CoinReward from "src/components/CoinReward";
import { CampaignTitle } from "src/components/text/CommonStyles";
import { Campaign } from "src/campaigns/types";
import styled from "styled-components";

const CampaignImg = styled.img`
    width: 62px;
    height: 62px;
    border-radius: 12px;
`;

const Info = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    text-align: left;
`;

const CampaignContainer = styled.div`
    display: flex;
    flex-wrap: none;
    align-items: center;
`;

const CampaignSection = ({ campaign }: { campaign: Campaign }) => {
    return (
        <CampaignContainer>
            <CampaignImg src={campaign.imageUrl} />
            <Info>
                <CampaignTitle>{campaign.campaignName}</CampaignTitle>
                <CoinReward
                    previousReward={campaign.countryPlatformReward.previous_reward}
                    reward={campaign.countryPlatformReward.reward}
                    type={campaign.rewardType}
                />
            </Info>
        </CampaignContainer>
    );
};

export default CampaignSection;
