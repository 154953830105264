import {
    FETCH_REFERRING_PUBLISHER,
    POST_MAGIC_PHONE_AUTH,
    POST_PHONE_AUTH
} from "../../auth/actions";
import { CLEAR_MODAL } from "../../modals/actions";
import { FETCH_USER } from "../../profile/actions";
import { Action } from "../../types";
import { AUTH_MODAL } from "./actions";

export interface ReferringPublisher {
    id: number;
    referralCode: string;
    username: string | null;
    firstname: string | null;
}

export interface AuthUIState {
    referringPublisher: ReferringPublisher | null;
    referringPublisherLoading: boolean;
    profileLoading: boolean;
    startPromotingModal: boolean;
    phoneAuthLoading: boolean;
    preApprovalCode: string | null;
}

const initialAuthUIState: AuthUIState = {
    profileLoading: true,
    startPromotingModal: false,
    phoneAuthLoading: false,
    referringPublisher: null,
    referringPublisherLoading: false,
    preApprovalCode: null
};

const authUIReducer = (state = initialAuthUIState, action: Action) => {
    switch (action.type) {
        case FETCH_REFERRING_PUBLISHER.REQUEST:
            return {
                ...state,
                referringPublisher: null,
                referringPublisherLoading: true
            };
        case FETCH_REFERRING_PUBLISHER.SUCCESS:
            return {
                ...state,
                referringPublisher: action.payload.referringPublisher,
                referringPublisherLoading: false
            };
        case FETCH_REFERRING_PUBLISHER.PRE_APPROVAL_CODE:
            return {
                ...state,
                referringPublisher: null,
                referringPublisherLoading: false,
                preApprovalCode: action.payload.preApprovalCode
            };
        case FETCH_REFERRING_PUBLISHER.FAILURE:
            return {
                ...state,
                referringPublisher: null,
                referringPublisherLoading: false
            };
        case FETCH_USER.REQUEST:
            return { ...state, profileLoading: true };
        case FETCH_USER.SUCCESS:
        case FETCH_USER.FAILURE:
            return { ...state, profileLoading: false };
        case CLEAR_MODAL:
            return { ...state, startPromotingModal: false };
        case AUTH_MODAL.START_PROMOTING:
            return { ...state, startPromotingModal: true };
        case POST_PHONE_AUTH.REQUEST:
        case POST_MAGIC_PHONE_AUTH.REQUEST:
            return { ...state, phoneAuthLoading: true };
        case POST_PHONE_AUTH.FAILURE:
        case POST_MAGIC_PHONE_AUTH.FAILURE:
            return { ...state, phoneAuthLoading: false };
        default:
            return state;
    }
};

export default authUIReducer;
