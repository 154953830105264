import React, { useState } from "react";
import { colors, fonts, fontWeight } from "src/constants";
import { clearButton } from "src/utils/styles/snippets";
import styled from "styled-components";
import { FiltersReference } from "../hooks/useFilters";
import DropdownFilter from "./DropdownFilter";
const FilterWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const ResetFiltersBtn = styled.button<{ disabled: boolean }>`
    ${clearButton()}
    height: 24px;
    padding: 0 6px;
    border-radius: 4px;
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.medium};
    text-align: center;
    white-space: nowrap;
    transition: 0.1s ease-in;
    background-color: ${props =>
        props.disabled ? colors.gray2 : colors.primaryBlue};
    color: ${colors.white};
    cursor: ${props => (props.disabled ? "default" : "pointer")};
    &:hover {
        background-color: ${props =>
            props.disabled ? colors.gray2 : colors.primaryBlueDark};
    }
`;

const TableFilters = ({
    filtersReference
}: {
    filtersReference: FiltersReference;
}) => {
    return (
        <FilterWrap>
            {Object.entries(filtersReference.currentFilters).map(
                ([filterCoreKey, currentFilter], idx) => (
                    <DropdownFilter
                        key={filterCoreKey + idx}
                        currentFilter={currentFilter}
                        filterCoreKey={filterCoreKey}
                        filtersReference={filtersReference}
                    />
                )
            )}

            <ResetFiltersBtn
                disabled={!filtersReference.areAnyFiltersActive}
                onClick={() => filtersReference.resetFilter("all")}
            >
                Reset Filters
            </ResetFiltersBtn>
        </FilterWrap>
    );
};

export default TableFilters;
