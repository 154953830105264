import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchTag from "src/components/SearchTag";
import { GlobalState } from "src/reducers";
import {
    resetAudienceDeviceFilters,
    resetAudienceGenderFilters,
    resetAverageViewsFilters,
    resetCreatorCountryRegionFilters,
    resetEngagementRateFilters,
    resetFollowersFilters,
    resetPlatformFilters,
    setAudienceCountryRegionFilters,
    setInterestsFilters,
    setCreatorCountryRegionFilters,
    setPlatformFilters,
    setThemesFilters
} from "src/ui/creators/actions";
import { capitalizeFirstCharacter } from "src/utils/functions/helpers";
import styled from "styled-components";

interface MainProps {
    filtersApplied: boolean;
    applyBottomMargin?: boolean;
    noPadding?: true;
}

const Main = styled.section<MainProps>`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: ${props => (props.applyBottomMargin ? "16px" : "0px")};
    padding: ${props => {
        return (
            !props.noPadding && (props.filtersApplied ? "16px 0px 0px" : "0px")
        );
    }};
`;

interface FilterTagProps {
    text: string;
    removeFilterHandler?: VoidFunction;
}

const FilterTag = ({ text, removeFilterHandler }: FilterTagProps) => {
    return (
        <div onClick={removeFilterHandler}>
            <SearchTag
                text={text}
                color="black"
                iconSrc="/tag-black-close-icon.svg"
                margin="0px"
            />
        </div>
    );
};

interface Props {
    applyBottomMargin?: boolean;
    noPadding?: true;
}

const CreatorGroupsAppliedFilters = ({
    applyBottomMargin,
    noPadding
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const {
        platformFilters,
        interestsFilters,
        themesFilters,
        creatorCountryRegionFilters,
        audienceCountryRegionFilters,
        followersFilter,
        averageViewsFilter,
        engagementRateFilter,
        audienceGenderFilter,
        audienceAgeFilters,
        audienceDeviceFilter
    } = useSelector((state: GlobalState) => state.ui.creators);

    // Local State ---------------------------------------------------

    // Local Constants -----------------------------------------------
    const selectedPlatformFilters = Object.keys(platformFilters)
        .map((platform: string) => {
            return capitalizeFirstCharacter(platform);
        })
        .join(", ");

    const countryFilters = Object.values(creatorCountryRegionFilters).join(
        ", "
    );

    // Local Functions -----------------------------------------------
    function removePlatformFilter(filter: string): void {
        const newFilters = { ...platformFilters };
        if (Object.keys(newFilters).length > 1) {
            if (newFilters[filter]) delete newFilters[filter];
            dispatch(setPlatformFilters(newFilters));
        }
    }

    function removeCountryFilter(
        filter: string,
        type: "creator" | "audience"
    ): void {
        const newFilters =
            type === "creator"
                ? { ...creatorCountryRegionFilters }
                : { ...audienceCountryRegionFilters };
        if (newFilters[filter]) delete newFilters[filter];
        if (type === "creator") {
            dispatch(setCreatorCountryRegionFilters(newFilters));
        } else {
            dispatch(setAudienceCountryRegionFilters(newFilters));
        }
    }

    function removeCategoryFilter(filter: string): void {
        const newFilters = { ...interestsFilters };
        if (newFilters[filter]) delete newFilters[filter];
        dispatch(setInterestsFilters(newFilters));
    }

    function removeThemesFilter(filter: string): void {
        const newFilters = { ...themesFilters };
        if (newFilters[filter]) delete newFilters[filter];
        dispatch(setThemesFilters(newFilters));
    }

    // Custom Hooks --------------------------------------------------

    // Effects -------------------------------------------------------

    // JSX -----------------------------------------------------------
    return (
        <Main
            filtersApplied={true}
            applyBottomMargin={applyBottomMargin}
            noPadding={noPadding}
        >
            {/* Platform Filters */}
            {Object.keys(platformFilters).map((platformFilter: string) => {
                return (
                    <FilterTag
                        key={`creator groups applied platform filters ${platformFilter}`}
                        text={`Platform: ${capitalizeFirstCharacter(
                            platformFilter
                        )}`}
                        removeFilterHandler={() =>
                            removePlatformFilter(platformFilter)
                        }
                    />
                );
            })}
            {/* <FilterTag
                text={`Platforms: ${selectedPlatformFilters}`}
                removeFilterHandler={() => dispatch(resetPlatformFilters())}
            /> */}

            {/* Creator Country/Region Filters */}
            {Object.entries(creatorCountryRegionFilters).map(
                (filter: [string, string]) => {
                    const code = filter[0];
                    const country = filter[1];
                    return (
                        <FilterTag
                            key={`creator groups applied creator country region filters ${country}`}
                            text={`Creator Country/Region: ${capitalizeFirstCharacter(
                                country
                            )}`}
                            removeFilterHandler={() =>
                                removeCountryFilter(code, "creator")
                            }
                        />
                    );
                }
            )}
            {/* <FilterTag
                text={`Creator Country/Region: ${countryFilters}`}
                removeFilterHandler={() =>
                    dispatch(resetCreatorCountryRegionFilters())
                }
            /> */}

            {/* Audience Country/Region Filters */}
            {Object.entries(audienceCountryRegionFilters).map(
                (filter: [string, string]) => {
                    const code = filter[0];
                    const country = filter[1];
                    return (
                        <FilterTag
                            key={`creator groups applied audience country region filters ${country}`}
                            text={`Audience Country/Region: ${capitalizeFirstCharacter(
                                country
                            )}`}
                            removeFilterHandler={() =>
                                removeCountryFilter(code, "audience")
                            }
                        />
                    );
                }
            )}

            {/* Category Filters */}
            {Object.keys(interestsFilters).map((category: string) => {
                return (
                    <FilterTag
                        key={`creator groups applied category filters ${category}`}
                        text={`Category: ${capitalizeFirstCharacter(category)}`}
                        removeFilterHandler={() =>
                            removeCategoryFilter(category)
                        }
                    />
                );
            })}

            {/* Themes Filters */}
            {Object.keys(themesFilters).map((category: string) => {
                return (
                    <FilterTag
                        key={`creator groups applied category filters ${category}`}
                        text={`Category: ${capitalizeFirstCharacter(category)}`}
                        removeFilterHandler={() => removeThemesFilter(category)}
                    />
                );
            })}

            {/* Followers Filters */}
            {followersFilter !== "All" && (
                <FilterTag
                    key={`creator groups applied followers filter ${followersFilter}`}
                    text={`Followers: ${capitalizeFirstCharacter(
                        followersFilter
                    )}`}
                    removeFilterHandler={() =>
                        dispatch(resetFollowersFilters())
                    }
                />
            )}

            {/* Average Views Filters */}
            {averageViewsFilter !== "All" && (
                <FilterTag
                    key={`creator groups applied average views filter ${averageViewsFilter}`}
                    text={`Average Views: ${capitalizeFirstCharacter(
                        averageViewsFilter
                    )}`}
                    removeFilterHandler={() =>
                        dispatch(resetAverageViewsFilters())
                    }
                />
            )}

            {/* Engagement Rate Filters */}
            {engagementRateFilter !== "All" && (
                <FilterTag
                    key={`creator groups applied engagement rate filter ${engagementRateFilter}`}
                    text={`Engagement: ${capitalizeFirstCharacter(
                        engagementRateFilter
                    )}`}
                    removeFilterHandler={() =>
                        dispatch(resetEngagementRateFilters())
                    }
                />
            )}

            {/* Audience Gender Filters */}
            {audienceGenderFilter !== "All" && (
                <FilterTag
                    key={`creator groups applied audience gender filter ${audienceGenderFilter}`}
                    text={`Audience Gender: ${capitalizeFirstCharacter(
                        audienceGenderFilter
                    )}`}
                    removeFilterHandler={() =>
                        dispatch(resetAudienceGenderFilters())
                    }
                />
            )}

            {/* Audience Device Filters */}
            {audienceDeviceFilter !== "All" && (
                <FilterTag
                    key={`creator groups applied audience device filter ${audienceDeviceFilter}`}
                    text={`Audience Device: ${capitalizeFirstCharacter(
                        audienceDeviceFilter
                    )}`}
                    removeFilterHandler={() =>
                        dispatch(resetAudienceDeviceFilters())
                    }
                />
            )}
        </Main>
    );
};

export default CreatorGroupsAppliedFilters;
