import React, { useRef } from "react";
import { MediaLoading } from "../../../components/SkeletonLoader";
import XCard from "../../../profile/components/XCard";
import { Asset } from "../../types";
import AssetList from "../components/AssetList";
import {
    HorizontalScrollInner,
    HorizontalScrollOuter,
    StatusCard
} from "../Details.styled";
import { AddAssetCell } from "../components/AddCell";
import { useSelector } from "react-redux";
import { isScriptModalOpen } from "src/campaigns/scripts/selectors";
import styled from "styled-components";
import CarouselHeader from "src/campaigns/scripts/components/CarouselHeader";
import ScrollArrows from "src/components/ScrollArrows";
import ProcessingMedias from "src/campaigns/scripts/components/ProcessingMedias";
import { ProcessingControl } from "src/campaigns/scripts/hooks/useProcessing";
interface StyleProps {
    $isScriptModal: boolean;
    $isAdmin: boolean;
}
const StyledXCard = styled(XCard)<StyleProps>`
    height: ${props =>
        props.$isScriptModal && props.$isAdmin ? "100%" : "initial"};
    min-height: ${props =>
        props.$isScriptModal && props.$isAdmin ? "232px" : "initial"};

    & > * {
        height: ${props =>
            props.$isScriptModal && props.$isAdmin ? "100%" : "170px"};
    }
`;
const StyledStatusCard = styled(StatusCard)<StyleProps>`
    margin: ${props =>
        props.$isScriptModal && props.$isAdmin ? "5px" : "8px"};
`;
interface Props {
    currentId: number | null;
    fetchAssets: (id: number) => void;
    assets?: Asset[];
    assetsLoading: boolean;
    name: string;
    isAdmin?: boolean;
    processingControl?: ProcessingControl;
    isScriptModal?: boolean;
}

const AssetsAccordion = ({
    currentId,
    fetchAssets,
    assets,
    assetsLoading,
    name,
    isAdmin = false,
    processingControl,
    isScriptModal = false
}: Props) => {
    const carouselRef = useRef(null);

    if (assetsLoading)
        return (
            <HorizontalScrollOuter overflow="hidden" bottomBorder="0px">
                <HorizontalScrollInner>
                    <MediaLoading />
                    <MediaLoading />
                    <MediaLoading />
                    <MediaLoading />
                </HorizontalScrollInner>
            </HorizontalScrollOuter>
        );
    if (!assets)
        return (
            <HorizontalScrollOuter overflow="hidden" bottomBorder="0px">
                <HorizontalScrollInner>
                    <StyledStatusCard
                        $isAdmin={isAdmin}
                        $isScriptModal={isScriptModal}
                    >
                        <StyledXCard
                            $isAdmin={isAdmin}
                            $isScriptModal={isScriptModal}
                            text="Failed to load asset resources, click the button below to try again."
                            button={{
                                text: "Try again",
                                callback: () => {
                                    currentId && fetchAssets(currentId);
                                }
                            }}
                        />
                    </StyledStatusCard>
                </HorizontalScrollInner>
            </HorizontalScrollOuter>
        );

    if (assets.length)
        return (
            <>
                <CarouselHeader
                    rightComponent={<ScrollArrows refElement={carouselRef} />}
                    leftComponent={
                        isAdmin && processingControl && currentId ? (
                            <ProcessingMedias
                                currentCount={assets.length}
                                processingControl={processingControl}
                                handleRefresh={() => fetchAssets(currentId)}
                                isAssets
                            />
                        ) : null
                    }
                />
                <AssetList
                    carouselRef={carouselRef}
                    isScriptModal={isScriptModal}
                    isAdmin={isAdmin}
                    assets={assets}
                />
            </>
        );

    return (
        <>
            {currentId && isAdmin && processingControl && (
                <CarouselHeader
                    leftComponent={
                        <ProcessingMedias
                            currentCount={assets.length}
                            processingControl={processingControl}
                            handleRefresh={() => fetchAssets(currentId)}
                            isAssets
                        />
                    }
                />
            )}
            <HorizontalScrollOuter
                overflow="hidden"
                bottomBorder="0px"
                topBorder={isScriptModal && isAdmin ? "0px" : undefined}
            >
                <HorizontalScrollInner>
                    {isScriptModal && isAdmin && (
                        <AddAssetCell caption="Add Asset to Script" />
                    )}
                    <StyledStatusCard
                        $isAdmin={isAdmin}
                        $isScriptModal={isScriptModal}
                    >
                        <StyledXCard
                            $isAdmin={isAdmin}
                            $isScriptModal={isScriptModal}
                            text={`This ${name} doesn’t have any asset resources yet`}
                            button={{
                                text: "Try again",
                                callback: () => {
                                    currentId && fetchAssets(currentId);
                                }
                            }}
                        />
                    </StyledStatusCard>
                </HorizontalScrollInner>
            </HorizontalScrollOuter>
        </>
    );
};

export default AssetsAccordion;
