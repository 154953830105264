import { Action, NormalizedActionGeneric, NormalizedResponse } from "src/types";
import { ClickActivity } from "src/types/ClickActivity";
import DelayedCampaign from "src/types/DelayedCampaign";

export const FETCH_DELAYED_CAMPAIGNS = {
    REQUEST: "FETCH_DELAYED_CAMPAIGNS_REQUEST",
    SUCCESS: "FETCH_DELAYED_CAMPAIGNS_SUCCESS",
    FAILURE: "FETCH_DELAYED_CAMPAIGNS_FAILURE"
};

export const fetchDelayedCampaigns = (): Action => ({
    type: FETCH_DELAYED_CAMPAIGNS.REQUEST,
    payload: null
});

export const fetchDelayedCampaignsSuccess = (
    response: NormalizedResponse<DelayedCampaign, number[]>,
    delayedCampaignInstallsTitle: string
): NormalizedActionGeneric<DelayedCampaign, number[]> => ({
    type: FETCH_DELAYED_CAMPAIGNS.SUCCESS,
    response,
    payload: { delayedCampaignInstallsTitle }
});

// Click Activity

export const FETCH_CLICK_ACTIVITY = {
    REQUEST: "FETCH_CLICK_ACTIVITY_REQUEST",
    SUCCESS: "FETCH_CLICK_ACTIVITY_SUCCESS",
    FAILURE: "FETCH_CLICK_ACTIVITY_FAILURE"
};

export const fetchClickActivity = (campaignId: number): Action => ({
    type: FETCH_CLICK_ACTIVITY.REQUEST,
    payload: { campaignId }
});

export const fetchClickActivitySuccess = (
    clickActivity: ClickActivity[],
    campaignId: number
): Action => ({
    type: FETCH_CLICK_ACTIVITY.SUCCESS,
    payload: { clickActivity, campaignId }
});
