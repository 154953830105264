import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { patch } from "src/Api";
import ModalHeading from "src/components/modals/components/ModalHeading";
import Toggler from "src/components/Toggler";
import withModalPortal from "src/components/withModalPortal";
import { colors } from "src/constants";
import SmallModal from "src/modals/SmallModal";
import { BlueButton } from "src/profile/components/Buttons";
import { decrementMarketplaceSubmissionBadges } from "src/ui/marketplace/actions";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";
import { OpenBuySubmission } from "src/buys/modules/open-buys/types";
import { getPaymentAmount, setPayout } from "./payouts";
import { Spinner } from "react-bootstrap";
import RichTextPreview from "./RichTextPreview";
import { serializeContent } from "src/components/text-editor/utils";
import slate from "remark-slate";
import { unified } from "unified";
import markdown from "remark-parse";
import { Descendant } from "slate";
import { isAxiosError } from "axios";

const StyledSmallModal = styled(SmallModal)`
    & > #small-modal-child {
        overflow: visible;
    }
`;

const MessageWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    grid-template-areas:
        "title input1"
        "subtitle input2";
    padding-bottom: 24px;
    isolation: isolate;
`;
const MessageSection = styled.div`
    border-bottom: 1px solid ${colors.blueGrey2};
    padding: 10px 0px;
    margin-bottom: 16px;
`;
const MessageTitle = styled.div<{ red?: boolean }>`
    display: flex;
    grid-area: title;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    color: ${p => (p.red ? colors.primaryRed : colors.black)};
`;
const Message = styled.div<{ red?: boolean }>`
    display: flex;
    grid-area: title;
    text-align: left;
    font-size: 12px;
    color: ${p => (p.red ? colors.primaryRed : colors.black)};
    padding: 4px;
`;
const StyledToggler = styled(Toggler)`
    grid-area: input1 / input1 / input2 / input2;
`;
const SubmitBtn = styled(BlueButton)`
    padding: 8px 12px;
    margin-left: auto;
    background-color: ${colors.primaryRed};
`;

const WarningSign = styled.img`
    width: 18px;
    margin-right: 6px;
`;

const QuestionMarkButton = styled.img`
    cursor: pointer;
    margin-left: 4px;
    height: 12px;
    width: 12px;
    :hover {
        opacity: 0.8;
    }
`;

const Header = styled.h2`
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 16px;
`;

const Warning = styled.p`
    font-size: 13px;
    font-weight: 400;
    text-align: left;
`;

const ButtonRow = styled.div`
    display: flex;
`;

const EditLink = styled.span`
    grid-area: input2;
    text-decoration: underline;
    color: ${colors.lightBlue};
    font-size: 12px;
    align-self: center;
    cursor: pointer;
`;

const UpdatePayoutButton = styled(BlueButton)`
    grid-area: input2;
    padding: 8px;
    height: fit-content;
    align-self: center;
`;

const PaymentTag = styled.div`
    padding: 6px 8px;
    background: ${colors.secondaryGreen};
    border-radius: 6px;
    grid-area: subtitle;
    color: white;
    font-size: 12px;
    width: max-content;
    margin: 8px 0px;
`;

const PaymentBox = styled.span`
    border-radius: 3px;
    background-color: ${colors.lightGray};
    border: 0px;
    color: black;
    padding: 3px;
`;

const PaymentInput = styled.input`
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
    background-color: ${colors.lightGray};
    color: black;
    border: 0px;
    width: 45px;
`;

const ApprovalModal = ({
    approval,
    message,
    sendAsMessage,
    mediaQuality,
    handleClose,
    submission,
    getSubmission
}: {
    approval: "approve" | "reject" | "revisionRequest";
    message: string;
    sendAsMessage: boolean;
    mediaQuality: number;
    handleClose: VoidFunction;
    submission: OpenBuySubmission;
    getSubmission: VoidFunction;
}) => {
    const dispatch = useDispatch();
    const [confirmSuspend, setConfirmSuspend] = useState(false);
    const [suspendAccount, setSuspendAccount] = useState(false);

    const [newPayment, setNewPayment] = useState<number>(0);
    const [editingPayout, setEditingPayout] = useState(false);
    const [loadingSetPayout, setLoadingSetPayout] = useState(false);
    const [currentPayment, setCurrentPayment] = useState<null | number>(null);
    const [preview, setPreview] = useState<Descendant[] | null>(null);

    const updateCurrentPayment = async (submission: OpenBuySubmission) => {
        const payment = await getPaymentAmount(submission);
        setCurrentPayment(payment);
    };

    useEffect(() => {
        updateCurrentPayment(submission);
    }, [submission]);

    const formatTextForPreview = async () => {
        const newLine = "\n";
        const replacedText = message.replace(new RegExp("  \n", "g"), newLine);
        try {
            const data: any = await unified()
                //@ts-ignore
                .use(markdown)
                .use(slate)
                .process(replacedText);
            setPreview(data.result);
        } catch (err) {
            console.error("failed to format message: ", err);
        }
    };

    useEffect(() => {
        formatTextForPreview();
    }, [message]);

    const handleSubmit = async () => {
        if (suspendAccount && !confirmSuspend) {
            setConfirmSuspend(true);
            return;
        } else if (suspendAccount) {
            setConfirmSuspend(false);
            await patch(
                `/v1/admin/publisher/${submission.publisherId}/suspend`
            );
        }
        try {
            const body = {
                ...(approval === "approve" &&
                    message.trim().length > 0 && { message }),
                ...(approval === "reject" &&
                    message.trim().length > 0 && { reason: message }),
                ...(approval === "revisionRequest" &&
                    message.trim().length > 0 && { revisionRequest: message }),
                sendAsMessage,
                ...(mediaQuality && { mediaQuality })
            };
            await patch(
                `/v1/openBuySubmission/${submission.id}/${approval}`,
                body
            );
            getSubmission();
            dispatch(
                setPillNotificationText(
                    `Submission ${
                        approval === "revisionRequest"
                            ? "revision requested"
                            : approval === "approve"
                            ? "approved"
                            : "rejected"
                    } successfully`,
                    "success",
                    3500
                )
            );
            dispatch(
                decrementMarketplaceSubmissionBadges(
                    submission.status === "pending",
                    !!submission.scraperFlag
                )
            );
        } catch (err) {
            console.log(err);
            dispatch(
                setPillNotificationText(
                    `${
                        approval === "revisionRequest"
                            ? "Revision request"
                            : approval === "approve"
                            ? "Approval"
                            : "Rejection"
                    } Failed`,
                    "danger",
                    3500
                )
            );
        }
    };

    const submitNewPayment = async () => {
        try {
            setLoadingSetPayout(true);
            await setPayout(submission, newPayment);
            dispatch(
                setPillNotificationText(
                    "Updated payout to $" + newPayment,
                    "success",
                    3000
                )
            );
            await updateCurrentPayment(submission);
            setEditingPayout(false);
        } catch (err) {
            if (isAxiosError(err))
                dispatch(
                    setPillNotificationText(
                        "Error updating payout: " + err.message,
                        "danger",
                        8000
                    )
                );
        } finally {
            setLoadingSetPayout(false);
        }
    };

    if (approval === "revisionRequest") {
        return (
            <StyledSmallModal
                header={{ text: "Request Revision", handleClose }}
                svg={<WarningSign src="/warning-sign.svg" />}
            >
                {message && (
                    <MessageSection>
                        <ModalHeading text="REVISION REQUEST REASON" />
                        {preview && (
                            <RichTextPreview text={serializeContent(preview)} />
                        )}
                    </MessageSection>
                )}
                <SubmitBtn onClick={handleSubmit}>Request Revision</SubmitBtn>
            </StyledSmallModal>
        );
    }

    const handleFocus = (event: any) => event.target.select();

    const amountPaidElement = editingPayout ? (
        <PaymentBox>
            $
            <PaymentInput
                type="number"
                min="0"
                step="any"
                value={newPayment}
                onChange={e => setNewPayment(parseFloat(e.currentTarget.value))}
                onFocus={handleFocus}
                autoFocus
            />
        </PaymentBox>
    ) : (
        `$${currentPayment ??
            submission?.amountToBePaid?.toFixed?.(2) ??
            "MISSING"}`
    );
    return (
        <>
            <StyledSmallModal
                header={{
                    text: approval === "approve" ? "✅ Approve" : "❌ Reject",
                    handleClose
                }}
            >
                {message && (
                    <MessageSection>
                        <ModalHeading
                            text={
                                approval === "approve"
                                    ? "APPROVAL MESSAGE"
                                    : "REJECTION MESSAGE"
                            }
                        />

                        {preview && (
                            <RichTextPreview text={serializeContent(preview)} />
                        )}
                    </MessageSection>
                )}

                {approval === "approve" && (
                    <MessageWrap>
                        <ModalHeading text="Creator Payout" />
                        <PaymentTag>
                            {amountPaidElement} will be paid
                        </PaymentTag>
                        {editingPayout ? (
                            <UpdatePayoutButton onClick={submitNewPayment}>
                                {loadingSetPayout ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    "Update Payout"
                                )}
                            </UpdatePayoutButton>
                        ) : (
                            <EditLink onClick={() => setEditingPayout(true)}>
                                Edit Payout
                            </EditLink>
                        )}
                    </MessageWrap>
                )}
                {approval === "reject" && (
                    <MessageWrap>
                        <MessageTitle>
                            Suspend Account
                            <QuestionMarkButton src="/navbar-contact.svg" />
                        </MessageTitle>
                        <StyledToggler
                            isActive={suspendAccount}
                            onToggle={() => setSuspendAccount(!suspendAccount)}
                        />
                    </MessageWrap>
                )}
                <SubmitBtn onClick={handleSubmit}>
                    {approval === "approve" ? "Approve" : "Reject"}
                </SubmitBtn>
            </StyledSmallModal>
            {confirmSuspend && (
                <StyledSmallModal>
                    <Header>
                        Are you sure you want to suspend (
                        {submission.publisherId})?
                    </Header>
                    <Warning>
                        After suspension, this creator will no longer have
                        access to Challenges or any CPI campaigns. They will
                        still be able to cash out their remaining balance.
                    </Warning>
                    <ButtonRow>
                        <SubmitBtn
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                                setSuspendAccount(false);
                                setConfirmSuspend(false);
                            }}
                        >
                            Cancel
                        </SubmitBtn>
                        <SubmitBtn
                            style={{ marginLeft: "10px" }}
                            onClick={handleSubmit}
                        >
                            Yes, Suspend
                        </SubmitBtn>
                    </ButtonRow>
                </StyledSmallModal>
            )}
        </>
    );
};

export default withModalPortal(ApprovalModal);
