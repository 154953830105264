import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { instructionSetsRoutes } from "src/marketplace/utils/marketplace-routes";
import { GlobalState } from "src/reducers";
import {
    setInstructionsPageStep,
    setInstructionsPageType
} from "src/ui/instructions-sets/actions";
import InstructionsPagePartial from "../components/InstructionsPagePartial";
import PagePartialScratchStep from "../components/PagePartialScratchStep";
import { getCurrentInstructionsSet } from "../selectors";
import { convertAPIDataToReduxData } from "../utils/instructions-sets";

interface Props {
    noHeader?: true;
}

const InstructionsSetsEditPage = ({ noHeader }: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setInstructionsPageType("Edit"));
        dispatch(setInstructionsPageStep("Scratch"));
    }, []);

    const currentInstructionsSet = useSelector(getCurrentInstructionsSet);
    useEffect(() => {
        if (currentInstructionsSet) {
            convertAPIDataToReduxData(currentInstructionsSet, dispatch);
        }
    }, [currentInstructionsSet]);

    return (
        <InstructionsPagePartial
            pageType="edit"
            leftColumnContents={<PagePartialScratchStep />}
            noHeader={noHeader}
        />
    );
};

export default InstructionsSetsEditPage;
