import React, { useState } from "react";
import styled from "styled-components";
import { Label } from "./components/CommonStyles";
import SearchTag from "src/components/SearchTag";
import { AnimatePresence } from "framer-motion";
import CampaignSelectionModal, {
    SelectedCampaigns
} from "src/campaigns/modals/CampaignSelectionModal";
import { MediaFilters } from "../types";
import { selectedCampaignsToArray } from "../util";

const TagList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

interface Props {
    setFilters: (filters: MediaFilters) => void;
    filters: MediaFilters;
}

const MediaFilterCampaign = ({ filters, setFilters }: Props) => {
    const [campaignSelectionModal, toggleCampaignSelectionModal] = useState(
        false
    );

    function setCampaignSelection(selectedCampaigns: SelectedCampaigns) {
        setFilters({ ...filters, selectedCampaigns });
    }

    function removeCampaignFromSelection(campaignId: number) {
        const newSelectedCampaigns = {
            ...filters.selectedCampaigns,
            [campaignId]: false
        };
        setFilters({ ...filters, selectedCampaigns: newSelectedCampaigns });
    }

    const campaignTags = selectedCampaignsToArray(filters.selectedCampaigns);

    return (
        <div>
            <Label>CAMPAIGNS</Label>
            <TagList>
                <SearchTag
                    text="Add Campaign"
                    color="white"
                    iconSrc="/tag-teal-plus-icon.svg"
                    onClick={() => toggleCampaignSelectionModal(true)}
                />
                {campaignTags.map(campaign => (
                    <SearchTag
                        key={campaign.id}
                        text={campaign.campaignName}
                        color="black"
                        iconSrc="/tag-black-close-icon.svg"
                        onClick={() => removeCampaignFromSelection(campaign.id)}
                    />
                ))}
            </TagList>
            <AnimatePresence>
                {campaignSelectionModal && (
                    <CampaignSelectionModal
                        closeModal={() => toggleCampaignSelectionModal(false)}
                        onSubmit={setCampaignSelection}
                        initialSelection={filters.selectedCampaigns}
                    />
                )}
            </AnimatePresence>
        </div>
    );
};

export default MediaFilterCampaign;
