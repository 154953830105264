import React, { useEffect } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { Profile } from "../profile/types";
import { fetchUser } from "../profile/actions";
import { push } from "connected-react-router";
import { Route, Switch } from "react-router";
import { database } from "../firebase/FirebaseConfig";
import AccountsList from "./AccountsList";
import AddAccount from "./AddAccount";
import AccountDisabled from "../navigation/AccountDisabled";
import VerificationFailed from "../navigation/VerificationFailed";
import FullscreenLoader from "../components/FullscreenLoader";

interface Props {
    profile?: Profile | null;
    fetchUser: () => void;
    push: (path: string) => void;
}

const SocialAccounts = ({ profile, fetchUser, push }: Props) => {
    useEffect(() => {
        fetchUser();

        const userid = localStorage.getItem("userid");
        const ref = userid
            ? database
                  .ref()
                  .child("accountRefresh")
                  .child(userid.toString())
            : null;

        if (ref) {
            ref.on("value", (snapshot: any) => {
                if (snapshot.val() === 1) {
                    fetchUser();
                }
            });
        }

        return () => {
            if (ref) {
                ref.off();
            }
        };
    }, []);

    if (profile) {
        if (!profile.account_enabled) {
            return <AccountDisabled push={push} />;
        } else if (
            !profile.account_verified &&
            profile.account_verification_failed
        ) {
            return <VerificationFailed />;
        }
    } else {
        return <FullscreenLoader offset={57} />;
    }

    return (
        <Switch>
            <Route
                exact
                path="/social-accounts"
                render={() => (
                    <AccountsList
                        profile={profile}
                        userAccounts={profile.SocialAccounts}
                    />
                )}
            />

            <Route
                path="/social-accounts/edit"
                render={() => (
                    <AddAccount userAccounts={profile.SocialAccounts} />
                )}
            />
        </Switch>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    profile: state.profile.profile
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUser: () => dispatch(fetchUser()),
    push: (path: string) => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialAccounts);
