import { isAxiosError } from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { patch, post } from "src/Api";
import {
    addOpenBuySubmissions,
    fetchSubmissionSets,
    updateOpenBuySubmission
} from "src/buys/actions";
import {
    OpenBuyInstructionStep,
    OpenBuySubmission,
    OpenBuySubmissionStatuses
} from "src/buys/modules/open-buys/types";
import { getStatusBody } from "src/buys/modules/open-buys/util";
import StatusMessage from "src/components/modals/components/StatusMessage";
import { colors } from "src/constants";
import { InstructionStep } from "src/marketplace/instructions-sets/types";
import { BigBlueButton, OutlineButton } from "src/profile/components/Buttons";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid ${colors.blueGrey2};
    border-radius: 8px;
    padding: 8px;
`;

const Input = styled.input`
    outline: none;
    border: none;
    font-size: 12px;
    height: 32px;
    width: 100%;
`;

const SubmitButton = styled(OutlineButton)`
    width: 70px;
    height: 32px;
`;

interface Props {
    submission: Partial<OpenBuySubmission>;
    instructionStep: OpenBuyInstructionStep;
    openBuyId: number;
    socialAccountId?: number;
    setId?: number;
}

const OpenBuyPostSubmission = ({
    submission,
    instructionStep,
    openBuyId,
    socialAccountId,
    setId
}: Props) => {
    const dispatch = useDispatch();
    const spark = instructionStep.requiresTextSubmission;
    const [value, setValue] = useState(
        (spark ? submission.textSubmission : submission.linkSubmission) || ""
    );
    const [loading, setLoading] = useState(false);

    const submitLinks = async () => {
        const params = {
            openBuyId,
            instructionStepId: instructionStep.id,
            socialAccountId,
            [spark ? "textSubmission" : "linkSubmission"]: value,
            openBuySubmissionSetId: setId
        };

        setLoading(true);
        try {
            let res = submission.id
                ? await patch(
                      spark
                          ? `/v1/openBuy/submission/${submission.id}/text`
                          : `/v1/openBuy/submission/${submission.id}/link`,
                      params
                  )
                : await post(
                      spark
                          ? "/v1/openBuy/submission/text"
                          : "/v1/openBuy/submission/link",
                      params
                  );
            dispatch(setPillNotificationText("Post submitted successfully"));
            dispatch(fetchSubmissionSets(openBuyId));
            if (!submission.id)
                dispatch(addOpenBuySubmissions([res.data.data], openBuyId));
            else dispatch(updateOpenBuySubmission(res.data.data));
        } catch (err) {
            if (isAxiosError(err))
                dispatch(
                    setPillNotificationText(
                        err?.response?.data?.error?.message,
                        "danger"
                    )
                );
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <Main>
                <Input
                    onChange={e => setValue(e.currentTarget.value)}
                    value={value}
                    placeholder={
                        spark ? "Add Your Spark Code" : "Add Your Link"
                    }
                />
            </Main>
            {submission.id &&
                submission.status !== OpenBuySubmissionStatuses.approved &&
                submission.status !== OpenBuySubmissionStatuses.next_step && (
                    <StatusMessage
                        type={
                            submission.status ===
                            OpenBuySubmissionStatuses.pending
                                ? "warning"
                                : "danger"
                        }
                        heading={
                            submission.status ===
                            OpenBuySubmissionStatuses.rejected
                                ? "Rejected"
                                : submission.status ===
                                  OpenBuySubmissionStatuses.revision_requested
                                ? "Revision Requested"
                                : "Pending approval"
                        }
                        closable
                        body={getStatusBody(submission)}
                        defaultOpen
                    ></StatusMessage>
                )}
            <BigBlueButton onClick={() => submitLinks()} disabled={!value}>
                Submit {spark ? "Spark Code" : "Link"}
            </BigBlueButton>
        </>
    );
};

export default OpenBuyPostSubmission;
