import { normalize } from "normalizr";
import { Redirect } from "react-router";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { get } from "src/Api";
import { Action } from "src/types";
import { setSocialAccountProfileLoading } from "src/ui/social-account-profile/actions";
import { socialAccountProfile } from "./schema";
import {
    fetchSocialAccountProfileFailure,
    fetchSocialAccountProfileSuccess,
    FETCH_SOCIAL_ACCOUNT_PROFILE
} from "./social-account-profile.action";

function* fetchSocialAccountProfileRequest(action: Action) {
    try {
        const { id } = action.payload;

        const endpoint = `/v1/socialAccount/${id}/profile`;

        const response = yield call(get, endpoint, {});

        const normalizedResponse = normalize(
            response.data.data,
            socialAccountProfile
        );

        yield put(
            fetchSocialAccountProfileSuccess(
                normalizedResponse.entities.socialAccountProfile,
                [normalizedResponse.result]
            )
        );

        yield put(setSocialAccountProfileLoading(false));
        yield put(fetchSocialAccountProfileFailure(""));
    } catch (error) {
        console.log(error);
        yield put(
            fetchSocialAccountProfileFailure(
                "Failed to retrieve social account profile."
            )
        );
    }
}

export default function* socialAccountProfileSaga() {
    yield all([
        takeEvery(
            FETCH_SOCIAL_ACCOUNT_PROFILE.REQUEST,
            fetchSocialAccountProfileRequest
        )
    ]);
}
