import React, { useState } from "react";
import OutlineContainer from "../components/OutlineContainer";
import ListCell from "../components/ListCell";
import Modal from "src/modals/Modal";
import styled from "styled-components";
import { colors } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import OnboardingTool from "./OnboardingTool";
import { push } from "connected-react-router";
import Toggler from "src/components/Toggler";
import { toggleAdmin } from "src/ui/profile/actions";
import useLoginAsUser from "src/hooks/useLoginAsUser";

const IdInput = styled.input`
    outline: none;
    border-radius: 5px;
    border: 1px solid ${colors.gray2};
    margin: 20px auto;
    padding: 2px;
    font-size: 14px;
`;

const Header = styled.div`
    font-size: 20px;
    color: ${colors.black};
    text-align: center;
`;

const Error = styled.div`
    font-size: 20px;
    color: ${colors.primaryRed};
    text-align: center;
`;

const Cancel = styled.button`
    color: ${colors.gray2};
    font-size: 14px;
    width: 50%;
    height: 40px;
    border-bottom-left-radius: 10px;
    background: white;
`;
const Submit = styled(Cancel)`
    color: ${colors.primaryBlue};
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
    height: auto;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    background: ${colors.secondaryGray};
    padding-top: 1px;
    gap: 1px;
`;

const Pluggy = styled.img`
    height: 100px;
    width: 100px;
`;
const StyledToggler = styled(Toggler)`
    margin-left: auto;
`;

const InternalTools = ({ selected }: { selected?: number }) => {
    const employeeId = localStorage.getItem("employeeId");
    const [userId, setUserId] = useState("");
    const [open, setOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const dispatch = useDispatch();
    const currentId = useSelector((state: GlobalState) =>
        state?.profile?.profile?.status === "employee"
            ? state.profile.profile.id
            : ""
    );
    const isAdminToggled = useSelector(
        (state: GlobalState) => state.ui.profile.isAdminToggled
    );

    const loginAs = useLoginAsUser();

    const onClose = () => {
        setOpen(false);
        setUserId("");
    };

    const navigationList = [
        {
            title: "Login As User",
            onClick: () => setOpen(true)
        },
        {
            title: "Direct Buy Screenshot Uploader",
            onClick: () => dispatch(push("/public/buys"))
        },
        {
            title: "Remove Admin Status",
            isActive: isAdminToggled,
            onClick: () => dispatch(toggleAdmin(true)),
            onDeSelect: () => dispatch(toggleAdmin(false))
        },
        {
            title: "Submission Manager",
            onClick: () => dispatch(push("/admin-tools/challenges/submissions"))
        },
        {
            title: "Challenge Manager",
            onClick: () => dispatch(push("/admin-tools/challenges/create"))
        },
        {
            title: "Instructions Manager",
            onClick: () =>
                dispatch(push("/admin-tools/challenges/instructions"))
        },
        {
            title: "Payments Manager",
            onClick: () => dispatch(push("/admin-tools/challenges/payments"))
        }
    ];

    return (
        <OutlineContainer>
            <OnboardingTool currentId={currentId} />
            {navigationList.map((tool, index) =>
                tool.onDeSelect ? (
                    <ListCell
                        key={"tool-list:" + index}
                        selected={index === selected}
                    >
                        {tool.title}
                        <StyledToggler
                            isActive={tool.isActive}
                            onSelect={tool.onClick}
                            onDeselect={tool.onDeSelect}
                        />
                    </ListCell>
                ) : (
                    <ListCell
                        selected={index === selected}
                        clickable
                        onClick={tool.onClick}
                    >
                        {tool.title}
                    </ListCell>
                )
            )}

            {open && (
                <Modal onClick={onClose}>
                    <Pluggy src="/plug/pluggy_spy.svg" />
                    <Header>Please proved the user's ID below: </Header>
                    <IdInput
                        placeholder="user id"
                        onChange={e => setUserId(e.target.value)}
                        onKeyDown={event =>
                            event.key === "Enter" &&
                            loginAs(false, userId, setError)
                        }
                        value={userId}
                    />
                    {employeeId && (
                        <>
                            <div style={{ marginTop: "-20px" }}>or</div>
                            <Submit onClick={() => loginAs(true)}>
                                Login As Yourself
                            </Submit>
                        </>
                    )}
                    <Error>{error}</Error>
                    <Row>
                        <Cancel onClick={onClose}>Cancel</Cancel>
                        <Submit
                            onClick={() => loginAs(false, userId, setError)}
                        >
                            Submit
                        </Submit>
                    </Row>
                </Modal>
            )}
        </OutlineContainer>
    );
};

export default InternalTools;
