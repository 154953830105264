import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GlobalWarning from "src/components/GlobalWarning";
import MainContainer from "src/components/MainContainer";
import { CustomLoading } from "src/components/SkeletonLoader";
import TableLoader from "src/components/table/components/TableLoader";
import PlugTable from "src/components/table/PlugTable";
import { TableColumn, TableRow } from "src/components/table/types";
import { createCell } from "src/components/table/utils";
import ChallengeMainHeader from "src/marketplace/landing/components/ChallengeMainHeader";
import XCard from "src/profile/components/XCard";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import ExtensionsForm from "../../components/ExtensionsForm";
import { fetchOpenBuyExtensions, upsertExtensions } from "./actions";
import {
    selectOpenBuyExtensions,
    selectOpenBuyExtensionsError,
    selectOpenBuyExtensionsLoading
} from "./selectors";

const columns: TableColumn[] = [
    {
        coreKey: "publisherId",
        displayValue: "Publisher ID",
        searchable: true,
        sortType: "number"
    },
    {
        coreKey: "publisherName",
        displayValue: "Publisher Name",
        searchable: true,
        sortType: "string"
    },
    {
        coreKey: "username",
        displayValue: "Username",
        searchable: true,
        sortType: "string"
    },
    { coreKey: "action", displayValue: "Action" }
];

const Wrapper = styled(MainContainer)`
    padding-bottom: 40px;
`;
const FormLoaderWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
const LoaderInnerWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 24px;
`;
export interface PublisherExtensionLink {
    publisherId: number;
    openBuyId: number;
    extendedUntil: number | null;
}
export interface Extension {
    publisherId: number;
    extendedUntil: any;
    Publisher: {
        firstname: string;
        lastname: string;
        username: string;
    };
}

const ChallengeExtensions = ({ openBuyId }: { openBuyId: number }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectOpenBuyExtensionsLoading);
    const isError = useSelector(selectOpenBuyExtensionsError);
    const extensions = useSelector(selectOpenBuyExtensions)[openBuyId];
    const extensionsArr = extensions ? Object.values(extensions) : [];
    const openBuy = useSelector(
        (state: GlobalState) => state.entities.adminOpenBuys.byId
    )[openBuyId];

    const isNotExtendable = !openBuy?.endsAt;

    const [isWarning, setIsWarning] = useState<"restart" | "end" | null>(null);

    const handleUpsert = async (
        publisherIds: number[],
        extendedUntil: number | null,
        message: { success: string; failure: string }
    ) => {
        const pubLinks = publisherIds.map(pubId => ({
            publisherId: pubId,
            openBuyId: openBuyId,
            extendedUntil
        }));

        dispatch(upsertExtensions(openBuyId, pubLinks, message));
    };

    useEffect(() => {
        dispatch(fetchOpenBuyExtensions(openBuyId));
    }, []);

    const tableData: TableRow[] = extensionsArr.map((ext, idx) => {
        const { Publisher, publisherId } = ext;

        const name =
            Publisher?.firstname && Publisher?.lastname
                ? `${Publisher.firstname} ${Publisher.lastname}`
                : Publisher?.firstname || Publisher?.lastname
                ? `${Publisher?.firstname || Publisher?.lastname}`
                : null;

        return {
            originalIdx: idx,
            raw: ext,
            rowData: {
                publisherId: createCell.number(publisherId),
                publisherName: createCell.string(name),
                username: createCell.string(Publisher?.username),
                action: createCell.button(
                    () =>
                        handleUpsert([publisherId], null, {
                            success: `Successfully Ended Extension for Publisher: ${publisherId}`,
                            failure: `Failed to End Extension for Publisher: ${publisherId}`
                        }),
                    "End Extension"
                )
            }
        };
    });
    return (
        <Wrapper>
            {isLoading || !openBuy ? (
                <>
                    <FormLoaderWrap>
                        <CustomLoading width="200px" height="14px" />
                        <LoaderInnerWrap>
                            <CustomLoading width="100%" height="52px" />
                            <CustomLoading width="52px" height="36px" />
                            <CustomLoading width="98px" height="36px" />
                        </LoaderInnerWrap>
                    </FormLoaderWrap>
                    <TableLoader />
                </>
            ) : isNotExtendable ? (
                <XCard text="Challenge must have ended before you can create extensions!" />
            ) : (
                <>
                    <ExtensionsForm handleUpsert={handleUpsert} />
                    <PlugTable
                        title="Publishers with Extension"
                        columns={columns}
                        cellData={tableData}
                        noResults={{
                            message:
                                "There are no Publishers with an extension for this challenge."
                        }}
                        error={
                            isError
                                ? {
                                      message: "Failed to Fetch Extensions!",
                                      btnText: "Refresh",
                                      callback: () =>
                                          dispatch(
                                              fetchOpenBuyExtensions(openBuyId)
                                          )
                                  }
                                : null
                        }
                        refreshHandler={() => {
                            dispatch(fetchOpenBuyExtensions(openBuyId));
                        }}
                        tableButtons={[
                            {
                                text: "End All Extensions",
                                color: "red",
                                onClick: () => setIsWarning("end"),
                                disabled: extensionsArr.length === 0
                            }
                        ]}
                    />
                </>
            )}
            {isWarning && (
                <GlobalWarning
                    handleCancel={() => setIsWarning(null)}
                    handleApply={() => {
                        handleUpsert(
                            extensionsArr.map(ext => ext.publisherId),
                            null,
                            {
                                success: `Successfully Ended Extensions for ${extensionsArr.length} Publishers`,
                                failure: `Failed to End Extensions for ${extensionsArr.length} Publishers`
                            }
                        );
                        setIsWarning(null);
                    }}
                    title={"Are you sure?"}
                    message={`End extensions for ${extensionsArr.length} publishers?`}
                    btnText={{ apply: "End All Extensions", cancel: "Cancel" }}
                />
            )}
        </Wrapper>
    );
};

export default ChallengeExtensions;
