import React from "react";
import withAccordion from "src/campaigns/details/accordions/withAccordion";
import styled from "styled-components";
import SubmissionHistory from "./SubmissionHistory";
import { OpenBuySubmissionSet } from "src/buys/modules/open-buys/types";

const Wrapper = styled.div`
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const SubmissionHistoryAccordion = ({
    submissionSet
}: {
    submissionSet: OpenBuySubmissionSet;
}) => {
    return (
        <Wrapper>
            <SubmissionHistory submissionSet={submissionSet} />
        </Wrapper>
    );
};

export default withAccordion(SubmissionHistoryAccordion);
