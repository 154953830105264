import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { clearModal } from "./actions";
import { colors } from "../constants";

const Main = styled.div`
    border-top: 1px solid ${colors.mediumTeal};
    display: flex;
`;

const Button = styled.div`
    padding: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    cursor: pointer;
`;

interface Props {
    clearModal: () => void;
    onUpdate: () => void;
    updateText?: string;
    active: boolean;
}

const UpdateModal = ({ clearModal, onUpdate, updateText, active }: Props) => {
    return (
        <Main>
            <Button
                style={{ borderRight: "1px solid ${colors.mediumTeal}" }}
                onClick={clearModal}
            >
                <div>Cancel</div>
            </Button>
            <Button
                onClick={onUpdate}
                style={
                    active ? { color: colors.primaryBlue } : { color: "gray" }
                }
            >
                {updateText || "Update"}
            </Button>
        </Main>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(null, mapDispatchToProps)(UpdateModal);
