import React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { NotificationsState } from "./reducer";
import BurgerNavbar from "../components/BurgerNavbar";
import NotificationCell from "./NotificationCell";
import { readNotifications } from "./actions";
import Tab from "../components/Tab";
import { EventParams, submitEvent } from "../events/actions";
import MainContainer from "src/components/MainContainer";
import styled from "styled-components";
import { breakpoints, colors, maxWidthDesktop, sizes } from "src/constants";
import Navbar from "src/components/Navbar";
import DelayedCampaignsHeader from "./delayed-campaigns/DelayedCampaignsHeader";
import { debounce } from "src/util";
import { AccessStatuses, Profile } from "src/profile/types";
import { ProfileState } from "src/profile/reducer";

const Tabs = styled.div`
    display: flex;
    margin: 20px 0px 15px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${breakpoints.tablet}px) {
        margin-top: ${`calc(${sizes.navbar.mobileHeight}px + 15px)`};
    }

    @media (max-width: ${maxWidthDesktop}px) {
        margin: 0px;
        border: none;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

const NotificationCells = styled.div`
    margin-bottom: 10px;

    @media (max-width: ${maxWidthDesktop}px) {
        margin-bottom: 0px;
    }
`;

enum TabState {
    updates,
    installs
}

interface Props {
    notificationsState: NotificationsState;
    readNotifications: () => void;
    push: (path: string) => void;
    submitEvent: (event: EventParams) => void;
    profileState: ProfileState;
}

interface State {
    maxNotifs: number;
    currentTab: TabState;
    windowWidth: number;
}

class Notifications extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            maxNotifs: 25,
            currentTab: TabState.updates,
            windowWidth: window.innerWidth
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.observeWindowResize = this.observeWindowResize.bind(this);

        window.addEventListener("resize", this.observeWindowResize);
    }

    componentDidMount(): void {
        window.addEventListener("scroll", this.handleScroll);
        window.scrollTo(0, 0);
        this.props.readNotifications();
        this.props.submitEvent({ event_name: "viewed_notifications" });
    }

    componentDidUpdate(prevProps: Props, prevState: State): void {
        const { currentTab } = this.state;
        if (prevState.currentTab !== currentTab) {
            this.props.submitEvent({
                event_name:
                    currentTab === TabState.updates
                        ? "viewed_notifications"
                        : "viewed_installs"
            });
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.observeWindowResize);
    }

    handleScroll(): void {
        const innerHeight = window.innerHeight;
        const scrollTopBody = document.getElementsByTagName("body")[0]
            .scrollTop;
        const scrollTopDoc = document.documentElement.scrollTop;
        const scrollTop = Math.max(scrollTopBody, scrollTopDoc) + 50;
        const offsetHeight = document.documentElement.offsetHeight;
        if (
            innerHeight + scrollTop >= offsetHeight &&
            document.body.style.position !== "fixed"
        ) {
            this.setState({ maxNotifs: this.state.maxNotifs + 3 });
        }
    }

    observeWindowResize = debounce(() => {
        this.setState({ windowWidth: window.innerWidth });
    });

    render() {
        const currentList =
            this.state.currentTab === TabState.updates
                ? this.props.notificationsState.systemNotifications
                : this.props.notificationsState.transactionNotifications;

        const endList = Math.min(this.state.maxNotifs, currentList.length);

        return (
            <>
                <Navbar marginBottom={20} title="NOTIFICATIONS" />

                <MainContainer>
                    {this.props.profileState?.profile?.accessStatus ===
                        AccessStatuses.ALL && (
                        <Tabs>
                            <Tab
                                name={"UPDATES"}
                                active={
                                    this.state.currentTab === TabState.updates
                                }
                                onClick={() => {
                                    this.setState({
                                        currentTab: TabState.updates
                                    });
                                }}
                            />
                            <Tab
                                name={"INSTALLS"}
                                active={
                                    this.state.currentTab === TabState.installs
                                }
                                onClick={() => {
                                    this.setState({
                                        currentTab: TabState.installs
                                    });
                                }}
                            />
                        </Tabs>
                    )}

                    <DelayedCampaignsHeader />
                    <NotificationCells>
                        {currentList
                            .slice(0, endList)
                            .map((notification, index) => {
                                return (
                                    <NotificationCell
                                        key={index}
                                        notification={notification}
                                    />
                                );
                            })}
                    </NotificationCells>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state: GlobalState) => ({
    notificationsState: state.notifications,
    profileState: state.profile
});

const mapDispatchToProps = (dispatch: any) => ({
    readNotifications: () => dispatch(readNotifications()),
    push: (path: string) => dispatch(push(path)),
    submitEvent: (event: EventParams) => dispatch(submitEvent(event))
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
