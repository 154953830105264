import React from "react";
import styled from "styled-components";
import { BigOutlineButton } from "../../../profile/components/Buttons";
import { colors } from "../../../constants";
import ShareButton from "src/components/ShareButton";
import { Shimmer } from "src/components/SkeletonLoader";

const ScriptButton = styled(BigOutlineButton)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    line-height: 18px;
    padding: 18px;
    margin-bottom: 10px;
`;
const LoadingText = styled.div`
    width: 30%;
    height: 30px;
    border-radius: 4px;
    ${Shimmer}
`;

const ScriptRow = () => (
    <ScriptButton textColor={colors.black}>
        <LoadingText />
        <ShareButton isScript link={`#`} />
    </ScriptButton>
);

export default ScriptRow;
