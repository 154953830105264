import React from "react";
import styled, { css } from "styled-components";
import { Notification } from "./types";
import { codeToEmoji } from "./CodeToEmoji";
import { fullname, shortTimeInterval } from "../util";
import { colors, maxWidthDesktop, fonts } from "../constants";
import { flex } from "src/utils/styles/snippets";

const Main = styled.div`
    ${flex("row", "auto", "center")};
    margin-top: 10px;
    padding: 15px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    font-size: ${fonts.smallTitle}px;
    text-align: left;
    box-sizing: border-box;

    @media (max-width: ${maxWidthDesktop}px) {
        margin-top: 0px;
        border: none;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

const EmojiOuter = styled.div`
    ${flex("row", "auto", "center")};
    margin-right: 10px;
    padding-top: 3px;
    box-sizing: border-box;
`;

const EmojiInner = styled.div`
    width: 30px;
    font-size: 20px;
`;

const SmallLogo = styled.img`
    width: 16px;
    height: 16px;
    margin: 0px 4px;
`;

const PlugAmount = styled.div`
    ${flex("row", "auto", "center")};
    margin-left: auto;
    color: ${colors.secondaryGreen};
    line-height: 16px;
`;

const NotificationCell = ({ notification }: { notification: Notification }) => {
    function renderDate() {
        if (notification.transaction) {
            const d = new Date(
                notification.transaction.date_pst.split("-").join("/")
            );
            const month = d.getMonth() + 1;
            const day = d.getDate();
            const year = d
                .getFullYear()
                .toString()
                .slice(2, 4);
            return `${month}/${day}/${year}`;
        } else {
            const d = new Date(notification.created_at);
            const month = d.getMonth() + 1;
            const day = d.getDate();
            const year = d
                .getFullYear()
                .toString()
                .slice(2, 4);
            return `${month}/${day}/${year}`;
        }
    }

    function emoji() {
        if (notification.notif_type === "new_transaction") {
            const country = notification.install_country
                ? notification.install_country.toUpperCase()
                : null;

            if (country && codeToEmoji[country]) {
                return codeToEmoji[country];
            } else {
                return "🚀";
            }
        } else if (notification.notif_type === "new_cashout") {
            return "🤑";
        } else if (notification.notif_type === "new_referral_bonus") {
            return "🚀";
        } else if (notification.notif_type === "campaign_closed") {
            return "🚫";
        } else if (notification.notif_type === "generic") {
            return "🚀";
        } else {
            return "🚀";
        }
    }

    function message() {
        if (notification.notif_type === "new_transaction") {
            return `New install for ${
                notification.campaign
                    ? notification.campaign.campaign_name
                    : "[error]"
            }`;
        } else if (notification.notif_type === "new_cashout") {
            return `You cashed out plugcoin on ${renderDate()}`;
        } else if (notification.notif_type === "new_referral_bonus") {
            return `You just got paid a referral bonus for ${
                notification.referredUser
                    ? fullname(
                          notification.referredUser.firstname,
                          notification.referredUser.lastname
                      )
                    : "[error]"
            }`;
        } else if (notification.notif_type === "campaign_closed") {
            return `The campaign ${
                notification.campaign
                    ? notification.campaign.campaign_name
                    : "[error]"
            } just ended. You will no longer be making any plugcoins from this campaign. Check feed to find more things to plug.`;
        } else if (
            ["generic", "social_verification"].includes(notification.notif_type)
        ) {
            return `${notification.message}`;
        }
    }

    function renderPlug() {
        if (notification.transaction) {
            return (
                <PlugAmount>
                    <SmallLogo src={"/logo.svg"} />+
                    {`$${(
                        notification.transaction.transaction_amount * 0.05
                    ).toFixed(2)}`}
                </PlugAmount>
            );
        } else {
            return null;
        }
    }

    return (
        <Main>
            <EmojiOuter>
                <EmojiInner>{emoji()}</EmojiInner>
            </EmojiOuter>
            <span>
                {message()}
                <span style={{ color: colors.secondaryGray }}>
                    {` • ${shortTimeInterval(
                        Date.now() - notification.created_at
                    )}`}
                </span>
            </span>
            {notification.notif_type === "new_transaction" && renderPlug()}
        </Main>
    );
};

export default NotificationCell;
