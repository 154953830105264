import React from "react";
import styled from "styled-components";
import SpinLoader from "./SpinLoader";
import { colors } from "src/constants";

interface StyleProps {
    customWidth?: string;
    customHeight?: string;
    padding: string;
    background: string;
    borderColor?: string;
    textColor?: string;
    textSize?: string;
    textWeight?: number;
    disabled?: boolean;
    margin?: string;
    bigBlue?: boolean;
}

const Main = styled.button<StyleProps>`
    width: ${props => props.customWidth};
    height: ${props => props.customHeight};
    outline: none !important;
    border-style: none;
    padding: ${props => props.padding};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${props => props.textWeight || 500};
    font-size: ${props => props.textSize || "14px"};
    white-space: nowrap;
    border-radius: 6px;
    background: ${props => props.background};
    margin: ${props => props.margin};
    transition: 0.1s ease-in;
    ${props => props.borderColor && `border: 1px solid ${props.borderColor};`}
    ${props => props.textColor && `color: ${props.textColor};`}
    ${props => props.disabled && `opacity: 0.5;`};

    ${props =>
        props.bigBlue &&
        `height: 48px;
    width: 100%;
    background-color: ${colors.primaryBlue};
    color: white;
    border-radius: 6px;
    transition: 0.1s ease-in;
    `}

    @media (hover: hover) {
        :hover {
            opacity: .7
        }
    }
`;

interface Props {
    text: string;
    onClick?: VoidFunction;
    width?: string;
    height?: string;
    padding?: string;
    spinnerSize?: number;
    disabled?: boolean;
    isLoading?: boolean;
    backgroundColor?: string;
    borderColor?: string;
    textColor?: string;
    margin?: string;
    textSize?: string;
    bigBlue?: boolean;
    textWeight?: number;
}

const LoadingButton = ({
    text,
    onClick,
    width,
    height,
    padding,
    spinnerSize,
    disabled,
    isLoading,
    backgroundColor,
    borderColor,
    textColor,
    margin,
    textSize,
    bigBlue,
    textWeight
}: Props) => {
    return (
        <Main
            onClick={() => {
                if (!disabled && !isLoading && onClick) {
                    onClick();
                }
            }}
            customWidth={width}
            customHeight={height}
            padding={padding || "0"}
            background={backgroundColor || "white"}
            borderColor={borderColor}
            textColor={textColor}
            margin={margin}
            textSize={textSize}
            textWeight={textWeight}
            bigBlue={bigBlue}
            disabled={disabled}
        >
            {isLoading ? (
                <SpinLoader size={spinnerSize || 20} color={textColor} />
            ) : (
                text
            )}
        </Main>
    );
};

export default LoadingButton;
