import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertMomentToDateString } from "src/components/datesUtil";
import SearchTag from "src/components/SearchTag";
import { setMediaFilters } from "src/pagination/media/actions";
import { selectMediaFilters } from "../selectors";

const MediaTimeTags = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectMediaFilters);

    function clearTime() {
        const nextFilters = { ...filters };
        delete nextFilters.startDate;
        delete nextFilters.endDate;
        dispatch(setMediaFilters(nextFilters));
    }

    if (filters.startDate && filters.endDate)
        return (
            <SearchTag
                color="black"
                iconSrc="/tag-black-close-icon.svg"
                text={convertMomentToDateString([
                    filters.startDate,
                    filters.endDate
                ])}
                onClick={clearTime}
            />
        );

    return null;
};

export default MediaTimeTags;
