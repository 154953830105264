import {
    DirectBuy,
    DirectBuyStage,
    DirectBuyState
} from "../../modules/direct-buy/types";
import { colors } from "../../../constants";
import { shortTimeIntervalArray } from "../../../util";
import moment from "moment";

export function getTagInfoForDirectBuyState(
    directBuyState: DirectBuyState
): [string | null, string | null] {
    switch (directBuyState) {
        case DirectBuyState.new:
            return [colors.primaryBlue, "NEW"];
        case DirectBuyState.active:
            return [colors.primaryBlue, "ACTIVE"];
        case DirectBuyState.pending:
            return [colors.primaryYellow, "PENDING"];
        case DirectBuyState.rejected:
            return [colors.primaryRed, "ERROR"];
        case DirectBuyState.expired:
            return [colors.primaryGray, "EXPIRED"];
        case DirectBuyState.approved:
            return [colors.primaryBlue, "APPROVED"];
        default:
            return [null, null];
    }
}

export function getTimeStringForDirectBuy(
    directBuy: DirectBuy,
    directBuyState: DirectBuyState,
    directBuyStage: DirectBuyStage | null
): string {
    switch (directBuyState) {
        case DirectBuyState.new:
        case DirectBuyState.viewed:
            if (directBuy.offerExpiresAt) {
                return `Offer expires in: ${shortTimeIntervalArray(
                    Date.parse(directBuy.offerExpiresAt) - Date.now()
                ).join(" ")}`;
            } else {
                return "Offer never expires";
            }
        case DirectBuyState.active:
        case DirectBuyState.pending:
            if (directBuy.buyCompletedAt) {
                return "Awaiting buyer approval";
            } else if (directBuy.buyExpiresAt) {
                return `Time remaining: ${shortTimeIntervalArray(
                    Date.parse(directBuy.buyExpiresAt) - Date.now()
                ).join(" ")}`;
            } else {
                return "Unlimited time remaining";
            }
        case DirectBuyState.rejected:
            switch (directBuyStage) {
                case DirectBuyStage.offer:
                    return (
                        "You rejected this buy" +
                        (directBuy.buyRejectionReason
                            ? `: ${directBuy.buyRejectionReason}`
                            : "")
                    );
                case DirectBuyStage.buy:
                    if (directBuy.buyExpiresAt) {
                        return `Time remaining: ${shortTimeIntervalArray(
                            Date.parse(directBuy.buyExpiresAt) - Date.now()
                        ).join(" ")}`;
                    } else {
                        return "Unlimited time remaining";
                    }
            }
            return "";
        case DirectBuyState.expired:
            switch (directBuyStage) {
                case DirectBuyStage.offer:
                    if (directBuy.offerExpiresAt) {
                        return `Offer expired on: ${moment(
                            directBuy.offerExpiresAt
                        ).format("MM/DD/YYYY")}`;
                    }
                    break;
                case DirectBuyStage.buy:
                    if (directBuy.buyExpiresAt) {
                        return `Buy expired on: ${moment(
                            directBuy.buyExpiresAt
                        ).format("MM/DD/YYYY")}`;
                    }
            }
            return "";
        case DirectBuyState.approved:
            if (directBuy.buyApprovedAt) {
                return `Completed: ${moment(directBuy.buyApprovedAt).format(
                    "MM/DD/YYYY"
                )}`;
            }
            return "";
    }
}
