import React from "react";
import styled from "styled-components";
import * as Icons from "src/components/icons/exports";
import { colors } from "src/constants";
import { flex } from "src/utils/styles/snippets";
import { EventInput, EventSubmit } from "src/utils/types/form";
import { debounce } from "src/util";

export const SearchForm = styled("form")`
    ${flex("row", "auto", "center")};
    width: 100%;
    height: 46px;
    padding: 0px 20px;
    background-color: ${colors.lighterTeal};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 12px;
`;

export const SearchInput = styled("input").attrs(() => ({
    placeholder: "Search Campaigns"
}))`
    width: 100%;
    height: 100%;
    margin-left: 12px;
    margin-right: calc(15px + 14px);
    color: ${colors.black};
    background-color: transparent;
    border: none;
    font-size: 16px;
    line-height: 14px;
    outline: none;

    :focus {
        outline: none;
    }

    ::placeholder {
        color: ${colors.primaryGray};
    }
`;

interface Props {
    searchString: string;
    setSearchString: React.Dispatch<React.SetStateAction<string>>;
}

const CampaignSearchBar = (props: Props) => {
    const { searchString, setSearchString } = props;

    const debouncedSetSearchString = debounce((userInput: string): void => {
        setSearchString(userInput);
    });

    function handleInputChange(event: EventInput): void {
        event.persist();
        const userInput = event.currentTarget.value;
        debouncedSetSearchString(userInput);
    }

    function handleSubmit(event: EventSubmit): void {
        event.preventDefault();
        debouncedSetSearchString(searchString);
    }

    return (
        <SearchForm onSubmit={handleSubmit}>
            <Icons.Search height={16} fill={colors.primaryGray} />
            <SearchInput autoFocus={true} onChange={handleInputChange} />
        </SearchForm>
    );
};

export default CampaignSearchBar;
