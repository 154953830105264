import React from "react";
import { SocialAccount } from "../../../types";
import ActionsModal from "../../../../modals/ActionsModal";
import { useDispatch } from "react-redux";
import {
    addAccountModalOpen,
    editAccountModalOpen
} from "src/ui/profile/actions";

interface Props {
    account: SocialAccount;
    push: (path: string) => void;
}

const AccountActionsModal = ({ account, push }: Props) => {
    const dispatch = useDispatch();

    function openEditAccountFormModal(): void {
        dispatch(editAccountModalOpen(true));
        dispatch(addAccountModalOpen(false));
    }

    return (
        <ActionsModal
            actions={[
                // { text: "Share Account Link", callback: () => {} },
                {
                    text: "Edit Account",
                    // callback: () => push("/profile/accounts/edit")
                    callback: openEditAccountFormModal
                }
            ]}
        />
    );
};

export default AccountActionsModal;
