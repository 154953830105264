import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { fetchCampaignMedias } from "../../medias/actions";
import { getMedias, selectCampaign } from "../../selectors";
import MediasAccordion from "./MediasAccordion";
import withAccordion from "./withAccordion";

const mapStateToProps = (state: GlobalState) => ({
    name: "campaign",
    currentId: state.ui.campaign.currentCampaignId,
    campaign: selectCampaign(state),
    mediasLoading: state.ui.campaign.mediasLoading,
    medias: getMedias(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchMedias: (campaignId: number) =>
        dispatch(fetchCampaignMedias(campaignId))
});

export default withAccordion(
    connect(mapStateToProps, mapDispatchToProps)(MediasAccordion)
);
