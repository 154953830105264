import {
    AuthAction,
    CampaignAction,
    DisplayAction,
    MediaAction,
    Role
} from "./roles";

type Rule = {
    [P in Role]: {
        static: AuthAction[];
    };
};

export const rules: Rule = {
    [Role.creator]: {
        static: [
            CampaignAction.list,
            CampaignAction.details,
            CampaignAction.uploadMedia,
            CampaignAction.customLinks,
            CampaignAction.payment,
            MediaAction.cellButtons
        ]
    },
    [Role.guest]: {
        static: [CampaignAction.details, DisplayAction.desktopView]
    }
};
