import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "src/constants";
import { DirectBuyPayment } from "src/buys/modules/direct-buy-payment/types";
import {
    OpenBuySubmission,
    OpenBuySubmissionStatuses
} from "src/buys/modules/open-buys/types";
import { StepById } from "src/buys/modules/open-buys/reducer";
import SocialAccountHeader from "src/components/social-accounts/SocialAccountHeader";
import Platforms from "src/social-accounts/Platforms";
import { shortenNumber } from "src/util";
import ProgressBar from "src/components/ProgressBar";
import { BigBlueButton } from "src/profile/components/Buttons";
import VerifyModal from "src/profile/accounts/private/modals/VerifyModal";
import { getSubmissionProgress } from "./util";
import OpenBuyRevisionRequestDetailsModal from "./OpenBuyRevisionRequestDetailsModal";
import { ChevronRight } from "src/components/icons/exports";
import { getStatusColor, getStatusText } from "src/buys/modules/open-buys/util";
import { SetStatusIndicator } from "./OpenBuySubmissionSetForm";

const Submission = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 12px;
    width: 100%;
    border: 1px solid ${colors.blueGrey2};
    box-sizing: border-box;
    border-radius: 12px;
    gap: 10px;
    cursor: pointer;
    :hover {
        background: ${colors.lighterTeal};
    }
`;
const StatusText = styled.div`
    margin-right: 6px;
`;

const Divider = styled.div<{ placement: number }>`
    width: 2px;
    height: 12px;
    background: ${colors.black};
    z-index: 80;
`;

const ViewCount = styled.div<{ amount: number }>`
    display: flex;
    align-items: center;
    position: absolute;
    flex-direction: column;
    font-size: 9px;
    font-weight: 500;
    height: 80px;
    margin-left: calc(${props => props.amount}% - 10px);
    margin-top: -32px;
    @media (max-width: 700px) {
        margin-left: ${props =>
            props.amount > 1
                ? `calc(${props.amount}% -10px)`
                : `calc(${props.amount}% -10px)`};
    }
`;

const Eye = styled.img`
    width: 12px;
    height: 6px;
    margin-top: 8px;
`;

const PayoutRow = styled.div`
    display: flex;
    position: relative;
    width: calc(100% - 10px);
    margin-top: 32px;
    margin-bottom: -42px;
`;

const InfoRow = styled.div`
    display: flex;
    height: 10px;
    margin-top: 30px;
    justify-content: space-between;
`;

const Info = styled.span`
    font-size: 9px;
    font-weight: 500;
    display: flex;
`;

const Numbers = styled(Info)`
    color: ${colors.primaryBlue};
    margin-left: 3px;
`;

const Status = styled.div<{ color?: string }>`
    border-radius: 100px;
    background-color: ${props => props.color};
    padding: 3px 6px;
    font-size: 12px;
    display: flex;
    margin-left: auto;
    align-items: center;
`;

const HeaderRow = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

const EmptyText = styled.h5``;
const IndicatorImg = styled.img`
    width: inherit;
    height: inherit;
`;
export const OpenBuySubmissionNotification = styled.div<{ error: boolean }>`
    padding: 8px;
    font-size: 12px;
    text-align: left;
    border-radius: 10px;
    background: ${props =>
        props.error ? colors.errorPink : colors.messageYellow};
`;

const InfoHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 12px;
`;

const Username = styled.div`
    font-size: 13px;
    font-weight: 500;
    margin-top: 6px;
`;

const PreviewImage = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 4px;
    object-fit: cover;
`;

interface Props {
    submission: Partial<OpenBuySubmission>;
    isPublic?: boolean;
    steps: StepById;
    children?: JSX.Element;
    onClick?: () => void;
    progress?: number;
}

const OpenBuySubmissionComponent = ({
    submission,
    isPublic,
    steps,
    children,
    onClick,
    progress = 1
}: Props) => {
    const [verify, setVerify] = useState(false);
    const [revisionModal, toggleRevisionModal] = useState(false);

    const getWidth = (index: number, length: number) => {
        let width = (index / (length - 1)) * 100;
        return width > 3 ? width : 3;
    };

    const getViewsTillNext = (
        payments: DirectBuyPayment[] = [],
        views: number
    ) => {
        for (let i = 0; i < payments.length; i++) {
            if (parseInt(payments[i].viewsMinimum || "1") > views)
                return parseInt(payments[i].viewsMinimum || "1") - views;
        }
        return 0;
    };

    if (isPublic) {
        if (submission?.socialAccount) {
            return (
                <Submission onClick={onClick}>
                    <SocialAccountHeader
                        picture={
                            submission?.socialAccount.profilePhotoUrl ||
                            Platforms[submission?.socialAccount.platform]
                                ?.imgSrc
                        }
                        username={submission?.socialAccount.username}
                        count={`${shortenNumber(
                            submission?.socialAccount.followers
                        )} Followers`}
                        icon={
                            submission?.socialAccount.profilePhotoUrl
                                ? Platforms[submission?.socialAccount.platform]
                                      ?.imgSrc
                                : undefined
                        }
                    />
                </Submission>
            );
        } else
            return (
                <Submission onClick={onClick}>
                    <EmptyText>
                        {submission.linkSubmission || submission.textSubmission}
                    </EmptyText>
                </Submission>
            );
    }

    let unverified = submission?.socialAccount
        ? submission?.socialAccount.verified === false
        : false;

    const getSubmissionNotificationCopy = () => {
        if (!submission) return "";
        if (unverified) {
            return `Error: ${
                Platforms[
                    submission?.socialAccount
                        ? submission.socialAccount.platform
                        : "tiktok"
                ].title
            } account, ${submission?.socialAccount &&
                submission?.socialAccount
                    .username} is not verified on The Plug. Click the button below to register this account with your Plug account.`;
        } else if (
            submission.status === OpenBuySubmissionStatuses.rejected &&
            submission.rejectionReason
        ) {
            return submission.rejectionReason;
        } else if (
            submission.status ===
                OpenBuySubmissionStatuses.revision_requested &&
            submission.revisionRequest
        ) {
            return submission.revisionRequest;
        } else if (submission.status === OpenBuySubmissionStatuses.pending) {
            return "Please wait while we review your submission";
        } else {
            return "";
        }
    };

    const step = steps[submission.instructionStepId || 0];

    return (
        <Submission onClick={onClick}>
            <HeaderRow>
                {submission.customMedia && (
                    <PreviewImage src={submission.customMedia?.url || ""} />
                )}
                {submission.socialAccount && !submission.customMedia && (
                    <PreviewImage
                        src={
                            Platforms[submission.socialAccount.platform]?.imgSrc
                        }
                    />
                )}
                <InfoHeader>
                    <div style={{ display: "flex" }}>
                        {submission.socialAccount && (
                            <Username>
                                @{submission.socialAccount?.username}
                            </Username>
                        )}
                        <Status
                            color={getStatusColor(submission, undefined, true)}
                        >
                            <SetStatusIndicator
                                status={submission?.status}
                                height="12px"
                            >
                                {submission?.status ===
                                OpenBuySubmissionStatuses.approved ? (
                                    <IndicatorImg src="/approved-status.svg" />
                                ) : submission?.status ===
                                  OpenBuySubmissionStatuses.pending ? (
                                    <IndicatorImg src="/pending-status.svg" />
                                ) : submission?.status ===
                                      OpenBuySubmissionStatuses.revision_requested ||
                                  submission?.status ===
                                      OpenBuySubmissionStatuses.rejected ? (
                                    <IndicatorImg src="/rejected-status.svg" />
                                ) : null}
                            </SetStatusIndicator>
                            <StatusText>{getStatusText(submission)}</StatusText>
                        </Status>
                    </div>
                    <ProgressBar
                        thinner
                        style={{ marginTop: "12px" }}
                        progress={progress}
                    />
                </InfoHeader>
                <ChevronRight height={9} />
            </HeaderRow>
            {!children && submission && step?.payoutTiming === "realtime" && (
                <>
                    <PayoutRow>
                        {step?.payoutTiming === "realtime" &&
                            step?.instructionPayments?.map((payment, i) => (
                                <ViewCount
                                    key={`${i} viewcount`}
                                    amount={getWidth(
                                        i,
                                        step?.instructionPayments?.length || 0
                                    )}
                                >
                                    <Divider
                                        placement={getWidth(
                                            i,
                                            step?.instructionPayments?.length ||
                                                0
                                        )}
                                    />
                                    {payment.viewsMinimum && (
                                        <Eye src={"/buys/eye-icon.svg"} />
                                    )}
                                    {payment.viewsMinimum &&
                                        shortenNumber(payment.viewsMinimum)}
                                </ViewCount>
                            ))}
                    </PayoutRow>
                    <ProgressBar
                        progress={getSubmissionProgress(submission, steps)}
                    />
                    <InfoRow>
                        <Info>
                            Submission Views:
                            <Numbers>{submission.viewsActual || 0}</Numbers>
                        </Info>
                        <Info>
                            Views to next reward:{" "}
                            <Numbers>
                                {getViewsTillNext(
                                    step.instructionPayments,
                                    submission.viewsActual || 0
                                )}
                            </Numbers>
                        </Info>
                    </InfoRow>
                </>
            )}
            {children}
            {!children && verify && submission?.socialAccount && (
                <VerifyModal
                    onClose={() => setVerify(false)}
                    account={submission?.socialAccount}
                />
            )}
            {/* {deleting && (
                <OpenBuyDeleteConfirmation
                    submissionId={deleting}
                    openBuyId={submission.openBuyId}
                    onClose={() => setDeleting(null)}
                />
            )} */}
            {revisionModal && (
                <OpenBuyRevisionRequestDetailsModal
                    revisionText={getSubmissionNotificationCopy()}
                    onClose={() => toggleRevisionModal(false)}
                    onManage={() => {
                        toggleRevisionModal(false);
                        onClick && onClick();
                    }}
                />
            )}
            {!children && unverified && (
                <BigBlueButton onClick={() => setVerify(true)}>
                    Verify Account
                </BigBlueButton>
            )}
        </Submission>
    );
};

export default OpenBuySubmissionComponent;
