import { Media, OldMedia, PlugLink } from "src/media/types";
import { Action } from "src/types";

// Fetch Link For Plug ---------------------------------------------------------------

export const FETCH_LINK_FOR_PLUG = {
    REQUEST: "FETCH_LINK_FOR_PLUG_REQUEST",
    SUCCESS: "FETCH_LINK_FOR_PLUG_SUCCESS",
    FAILURE: "FETCH_LINK_FOR_PLUG_FAILURE"
};

export interface fetchLinkParams {
    id: number;
    externalPageId: number | null;
    campaignId: number;
    isPortrait: boolean;
}

export const fetchLinkForPlug = (params: fetchLinkParams): Action => ({
    type: FETCH_LINK_FOR_PLUG.REQUEST,
    payload: params
});

export const fetchLinkForPlugSuccess = (link: PlugLink): Action => ({
    type: FETCH_LINK_FOR_PLUG.SUCCESS,
    payload: link
});

export const fetchLinkForPlugFailure = (media: Media): Action => ({
    type: FETCH_LINK_FOR_PLUG.FAILURE,
    payload: media
});

export const FETCH_LINK_FOR_PLUG_WITHOUT_MODAL = {
    REQUEST: "FETCH_LINK_FOR_PLUG_WITHOUT_MODAL_REQUEST",
    SUCCESS: "FETCH_LINK_FOR_PLUG_WITHOUT_MODAL_SUCCESS"
};

export const fetchLinkForPlugWithoutModal = (params: fetchLinkParams): Action => ({
    type: FETCH_LINK_FOR_PLUG_WITHOUT_MODAL.REQUEST,
    payload: params
});

export const fetchLinkForPlugWithoutModalSuccess = (
    link: PlugLink
): Action => ({
    type: FETCH_LINK_FOR_PLUG_WITHOUT_MODAL.SUCCESS,
    payload: link
});

// Fetch Custom Links ---------------------------------------------------------------

export const FETCH_CUSTOM_LINKS = {
    REQUEST: "FETCH_CUSTOM_LINKS_REQUEST",
    SUCCESS: "FETCH_CUSTOM_LINKS_SUCCESS",
    FAILURE: "FETCH_CUSTOM_LINKS_FAILURE"
};

export const fetchCustomLinks = (campaign_id: number): Action => ({
    type: FETCH_CUSTOM_LINKS.REQUEST,
    payload: { campaign_id }
});

export const fetchCustomLinksSuccess = (customLinks: OldMedia[]): Action => ({
    type: FETCH_CUSTOM_LINKS.SUCCESS,
    payload: { customLinks }
});

export const fetchCustomLinksFailure = (error: string): Action => ({
    type: FETCH_CUSTOM_LINKS.FAILURE,
    payload: { error }
});

// Create Custom Link ---------------------------------------------------------------

export const CREATE_CUSTOM_LINK = {
    REQUEST: "CREATE_CUSTOM_LINK_REQUEST",
    SUCCESS: "CREATE_CUSTOM_LINK_SUCCESS",
    FAILURE: "CREATE_CUSTOM_LINK_FAILURE"
};

export interface CreateCustomLinkParams {
    media_id: number;
    name: string;
}

export const createCustomLink = (params: CreateCustomLinkParams): Action => ({
    type: CREATE_CUSTOM_LINK.REQUEST,
    payload: params
});

export const createCustomLinkSuccess = (customLink: OldMedia): Action => ({
    type: CREATE_CUSTOM_LINK.SUCCESS,
    payload: customLink
});

export const createCustomLinkFailure = (error: string): Action => ({
    type: CREATE_CUSTOM_LINK.FAILURE,
    payload: { error }
});
