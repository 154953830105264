import React from "react";
import InfoRow from "../components/InfoRow";
import { EarningsSummary } from "../types";
import { shortenNumber } from "../../util";

interface Props {
    earningsSummary: EarningsSummary;
}

const EarningsRow = ({ earningsSummary }: Props) => {
    return (
        <InfoRow
            green
            infos={[
                {
                    title: `$${shortenNumber(earningsSummary.balance, 2)}`,
                    subtitle: "Current Balance"
                },
                {
                    title: `$${shortenNumber(earningsSummary.weekly, 2)}`,
                    subtitle: "This Week"
                },
                {
                    title: `$${shortenNumber(earningsSummary.total, 2)}`,
                    subtitle: "Total Earnings"
                }
            ]}
        />
    );
};

export default EarningsRow;
