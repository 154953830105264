import { Action } from "src/types";
import { AdminChallengeStatus, AdminWizardStep } from "./reducers";

export const SET_IN_ADMIN_PAGE = "SET_IN_ADMIN_PAGE";
export function setInAdminTools(inAdminTools: boolean): Action {
    return {
        type: SET_IN_ADMIN_PAGE,
        payload: { inAdminTools }
    };
}

export const SET_CREATE_CHALLENGE_FORM_OPEN = "SET_CREATE_CHALLENGE_FORM_OPEN";
export function setCreateChallengeFormOpen(open: boolean): Action {
    return {
        type: SET_CREATE_CHALLENGE_FORM_OPEN,
        payload: { open }
    };
}

export const SET_CURRENT_OPEN_BUY_ID = "SET_CURRENT_OPEN_BUY_ID";
export function setCurrentOpenBuyId(id: number | null): Action {
    return {
        type: SET_CURRENT_OPEN_BUY_ID,
        payload: { id }
    };
}

export const SET_ADMIN_CURRENT_WIZARD_STEP = "SET_ADMIN_CURRENT_WIZARD_STEP";
export function setAdminCurrentWizardStep(step: AdminWizardStep): Action {
    return {
        type: SET_ADMIN_CURRENT_WIZARD_STEP,
        payload: { step }
    };
}

export const SET_DETAILS_UNSAVED = "SET_DETAILS_UNSAVED";
export const setDetailsUnsaved = (status: boolean) => ({
    type: SET_DETAILS_UNSAVED,
    payload: { status }
});

export const SET_CHARGE_ADVERTISERS_PER_INSTALL =
    "SET_CHARGE_ADVERTISERS_PER_INSTALL";
export const setChargeAdvertisersPerInstall = (
    chargeAdvertisersPerInstall: boolean
) => ({
    type: SET_CHARGE_ADVERTISERS_PER_INSTALL,
    payload: { chargeAdvertisersPerInstall }
});

export const SET_CHALLENGE_DETAILS_INCOMPLETE =
    "SET_CHALLENGE_DETAILS_INCOMPLETE";
export function setChallengeDetailsIncomplete(complete: boolean): Action {
    return {
        type: SET_CHALLENGE_DETAILS_INCOMPLETE,
        payload: { complete }
    };
}

export const SET_ACTIVE_ACCORDION_SUBMISSION_MANAGER =
    "SET_ACTIVE_ACCORDION_SUBMISSION_MANAGER";

export const SET_HAS_READ_LOCAL_STORE_FOR_NAV_TO_ADMIN_TOOLS =
    "SET_HAS_READ_LOCAL_STORE_FOR_NAV_TO_ADMIN_TOOLS";
export function setHasReadLocalStoreForNavToAdminTools(): Action {
    return {
        type: SET_HAS_READ_LOCAL_STORE_FOR_NAV_TO_ADMIN_TOOLS,
        payload: {}
    };
}

export enum SUBMISSION_MANAGER_ACCORDIONS {
    publisherDetails,
    challengeDetails,
    history,
    tools,
    approvalChecklist,
    none
}

export function setSubmissionManagerAccordion(
    accordion: SUBMISSION_MANAGER_ACCORDIONS[]
): Action {
    return {
        type: SET_ACTIVE_ACCORDION_SUBMISSION_MANAGER,
        payload: accordion
    };
}
