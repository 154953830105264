import { AnimatePresence } from "framer-motion";
import React from "react";
import FullScreenModal from "src/modals/FullScreenModal";
import { AnalyticsFullscreenState, AnalyticsScrollTo } from "../AnalyticsMain";
import { ModalTotalAndDayMoreProps } from "../utils/props";
import EarningsByDayCard from "src/analytics/EarningsByDayCard";
import { CampaignByDate, MediaByDate } from "../AnalyticsDataParser";

interface Props extends ModalTotalAndDayMoreProps {
    parsedData: (CampaignByDate | MediaByDate)[];
    setParsedData: (data: (CampaignByDate | MediaByDate)[]) => void;
}

const EarningsByDayMoreModal = ({
    open,
    closeModal,
    setFullscreenState,
    filterState,
    simpleNavbar,
    modal,
    parsedData,
    setParsedData
}: Props) => {
    return (
        <AnimatePresence>
            {open && (
                <FullScreenModal closeModal={closeModal} expand>
                    {simpleNavbar(AnalyticsScrollTo.earningsByDay)}
                    <EarningsByDayCard
                        filterState={filterState}
                        parsedData={parsedData}
                        setParsedData={setParsedData}
                        pickDates={() =>
                            setFullscreenState(
                                AnalyticsFullscreenState.earningsByDayDates
                            )
                        }
                        pickFilters={() =>
                            setFullscreenState(
                                AnalyticsFullscreenState.earningsByDayFilter
                            )
                        }
                    />
                    {modal}
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default EarningsByDayMoreModal;
