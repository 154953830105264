import { EventChannel, eventChannel } from "@redux-saga/core";
import { all, fork, takeEvery } from "@redux-saga/core/effects";
import { db } from "../firebase/FirebaseConfig";
import {
    Query,
    QuerySnapshot,
    FieldPath,
    WhereFilterOp,
    DocumentData,
    FirestoreError
} from "@firebase/firestore-types";
import conversationSaga, {
    createConversationListChannel,
    createConversationRequestChannel,
    createConversationUnreadChannel
} from "./conversation/saga";
import groupUserSettingsSaga from "./group-user-settings/saga";
import chatProfileSaga, {
    fetchOwnChatProfileRequest
} from "./chat-profiles/saga";
import messageSaga from "./message/saga";
import { INITIALIZE_FIRESTORE_CHAT } from "./actions";
import approvaInternalNotesSaga from "./approval-internal-notes/saga";

function* initializeFirestoreChat() {
    try {
        yield all([
            fork(fetchOwnChatProfileRequest),
            fork(createConversationUnreadChannel),
            fork(createConversationRequestChannel),
            fork(createConversationListChannel)
        ]);
    } catch (error) {
        console.log("error starting chat");
    }
}

export function createEventChannel(
    collectionName: string,
    whereClause: [string | FieldPath, WhereFilterOp, any],
    order: string = ""
) {
    const listener: EventChannel<
        DocumentData[] | FirestoreError
    > = eventChannel(emit => {
        let ref = db.collection(collectionName).where(...whereClause);

        if (order) {
            ref = ref.orderBy(order);
        }

        const unsubscribe = ref.onSnapshot(
            (querySnapshot: QuerySnapshot) => {
                const data: DocumentData[] = [];
                querySnapshot.forEach(doc =>
                    data.push({ ...doc.data(), id: doc.id })
                );
                emit(data);
            },
            error => {
                console.log({ error });
                emit(error);
            }
        );

        return unsubscribe;
    });

    return listener;
}

export async function get<T extends { id: string }>(
    collectionName: string,
    documentId: string
) {
    const collectionRef = db.collection(collectionName);

    const snapshot = await collectionRef.doc(documentId).get();
    const data = snapshot.data() as T;

    if (data) return { ...data, id: snapshot.id };
    return null;
}

export async function getWhere(
    collectionName: string,
    ...where: [string | FieldPath, WhereFilterOp, any][]
) {
    let collectionRef = db.collection(collectionName) as Query;
    where.forEach((where: [string | FieldPath, WhereFilterOp, any]) => {
        collectionRef = collectionRef.where(...where);
    });
    const snapshot = await collectionRef.get();

    const data: DocumentData[] = [];
    snapshot.forEach(doc => data.push({ ...doc.data(), id: doc.id }));

    return data;
}

export async function add<DocumentType extends DocumentData>(
    collectionName: string,
    document: DocumentType
) {
    const collectionRef = db.collection(collectionName);

    const docRef = await collectionRef.add(document);
    return { ...document, id: docRef.id };
}

export async function set<DocumentType extends { id?: string }>(
    collectionName: string,
    document: DocumentType
) {
    const collectionRef = db.collection(collectionName);

    if (document.id) {
        const documentNoId = { ...document };
        delete documentNoId.id;
        return await collectionRef
            .doc(document.id)
            .set(documentNoId, { merge: true });
    } else {
        return await collectionRef.add(document);
    }
}

export default function* chatSaga() {
    yield all([
        fork(conversationSaga),
        fork(groupUserSettingsSaga),
        fork(chatProfileSaga),
        fork(messageSaga),
        fork(approvaInternalNotesSaga),
        takeEvery(INITIALIZE_FIRESTORE_CHAT, initializeFirestoreChat)
    ]);
}
