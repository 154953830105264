import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { Profile } from "../types";
import { colors, maxWidthDesktop } from "../../constants";
import * as Icons from "src/components/icons/exports";
import { breakpoints, fonts } from "src/constants";
import { flex, flexRowCenter } from "src/utils/styles/snippets";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "../actions";

const Main = styled("div")`
    margin-top: 18px;

    @media (max-width: ${maxWidthDesktop}px) {
        padding: 0px 15px 18px;
    }

    @media (max-width: ${breakpoints.mobile}px) {
        padding: 0px 15px;
    }
`;

const CashoutButton = styled("div")`
    ${flexRowCenter()};
    width: 100%;
    padding: 18px 0px;
    border-radius: 6px;
    color: ${colors.white};
    background: linear-gradient(192.08deg, #00de4c 34.44%, #2aff38 80.8%);
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (max-width: ${breakpoints.mobile}px) {
        height: 48px;
        padding: 0px;
    }
`;

const TransactionsLink = styled(Link).attrs(() => ({
    to: "/profile/transactions"
}))`
    ${flex("row", "space-between", "center")};
    margin-top: 10px;
    padding: 18px 16px;
    color: ${colors.secondaryGray};
    border: ${colors.lightGray} solid 1px;
    border-radius: 6px;
    font-size: ${fonts.subtitle}px;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }

    :hover {
        color: ${props => {
            return props.theme.isMobile ? colors.secondaryGray : colors.black;
        }};
        text-decoration: none;
    }

    @media (max-width: ${breakpoints.mobile}px) {
        margin: 0px;
        padding: 18px 0px;
        border: ${colors.transparent} solid 1px;

        :hover {
            background-color: ${colors.transparent};
        }
    }
`;

const LastCashOut = styled("div")`
    ${flex("row", "auto", "center")};
`;

const Arrow = styled("div")`
    ${flexRowCenter()};
    transition: 0.1s;

    @media (min-width: ${breakpoints.mobile}px) {
        ${TransactionsLink}:hover & {
            transform: translateX(6px);
        }
    }
`;

const StatusIcon = styled("img")`
    height: 14px;
    width: 14px;
`;

interface Props {
    profile: Profile;
    cashOutModal: () => void;
}

const TransactionsCard = ({ profile, cashOutModal }: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (profile.tipaltiPayeePayable === undefined)
            dispatch(fetchUserProfile());
    }, [profile]);

    function lastCashOut() {
        if (profile.Transactions && profile.Transactions[0]) {
            const mostRecent = profile.Transactions[0];
            let statusIcon;

            if (mostRecent.transaction_status === "pending") {
                statusIcon = (
                    <StatusIcon
                        src={"/progress_yellow/progress_yellow@2x.png"}
                    />
                );
            } else if (mostRecent.transaction_status === "completed") {
                statusIcon = (
                    <StatusIcon src={"/progress_green/progress_green@2x.png"} />
                );
            } else if (mostRecent.transaction_status === "cancelled") {
                statusIcon = (
                    <StatusIcon src={"/progress_red/progress_red@2x.png"} />
                );
            } else if (mostRecent.transaction_status === "rejected") {
                statusIcon = (
                    <StatusIcon src={"/progress_red/progress_red@2x.png"} />
                );
            } else {
                statusIcon = (
                    <StatusIcon src={"/progress_gray/progress_gray@2x.png"} />
                );
            }

            return (
                <>
                    {statusIcon}
                    <div style={{ marginLeft: "10px" }}>
                        Last cash out on{" "}
                        {moment(mostRecent.date_pst).format("MM/DD/YY")}
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <StatusIcon src={"/progress_gray/progress_gray@2x.png"} />
                    <div style={{ marginLeft: "10px" }}>
                        No cash out history
                    </div>
                </>
            );
        }
    }

    return (
        <Main>
            <CashoutButton onClick={cashOutModal}>Cash Out</CashoutButton>

            <TransactionsLink>
                <LastCashOut>{lastCashOut()}</LastCashOut>
                <Arrow>
                    <Icons.ChevronRight
                        height={12}
                        fill={colors.secondaryGray}
                    />
                </Arrow>
            </TransactionsLink>
        </Main>
    );
};

export default TransactionsCard;
