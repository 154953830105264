import React, { useEffect, useState } from "react";
import IndicatorBackground from "./IndicatorBackground";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
    selectDirectBuysById,
    selectDirectBuyStepEntities
} from "../selectors";
import {
    getDirectBuyPaymentCollectionArrayForDirectBuy,
    getPhotoUrlForDirectBuy,
    getSocialAccountArrayForDirectBuy,
    getStateForDirectBuy
} from "../modules/direct-buy/util";
import DirectBuyPreviewContent from "./DirectBuyPreviewContent";
import useInterval from "../../components/useInterval";
import {
    getIndicatorBackgroundColorForDirectBuyState,
    getIndicatorTagContentForDirectBuy,
    getTimeStringForDirectBuy,
    innerBorderRadiusForDirectBuyPreviewSize,
    innerPaddingForDirectBuyPreviewSize,
    outerBorderRadiusForDirectBuyPreviewSize,
    outerPaddingForDirectBuyPreviewSize
} from "./directBuyPreviewUtil";
import IndicatorTag from "./IndicatorTag";

interface OuterMainStyleProps {
    padding: number;
    clickable: boolean;
}

const OuterMain = styled.div<OuterMainStyleProps>`
    position: relative;
    width: 130px;
    height: 100%;
    user-select: none;
    padding: ${props => props.padding}px;
    ${props => (props.clickable ? "cursor: pointer;" : "")}
`;

export enum DirectBuyPreviewSize {
    large,
    small
}

interface Props {
    directBuyId: number;
    size: DirectBuyPreviewSize;
    onClick?: () => void;
}

const DirectBuyPreview = ({ directBuyId, size, onClick }: Props) => {
    const directBuy = useSelector(selectDirectBuysById)[directBuyId];
    const directBuySteps = useSelector(selectDirectBuyStepEntities);

    // These values are essentially static and only need to be calculated once per buy
    const [payments, setPayments] = useState(() =>
        getDirectBuyPaymentCollectionArrayForDirectBuy(
            directBuy,
            directBuySteps
        )
    );
    const [socialAccounts, setSocialAccounts] = useState(() =>
        getSocialAccountArrayForDirectBuy(directBuy, directBuySteps)
    );
    useEffect(() => {
        setPayments(
            getDirectBuyPaymentCollectionArrayForDirectBuy(
                directBuy,
                directBuySteps
            )
        );
        setSocialAccounts(
            getSocialAccountArrayForDirectBuy(directBuy, directBuySteps)
        );
    }, [directBuyId]);

    // This value can change when custom media is uploaded
    const [photoUrl, setPhotoUrl] = useState(() =>
        getPhotoUrlForDirectBuy(directBuy, directBuySteps)
    );
    useEffect(() => {
        setPhotoUrl(getPhotoUrlForDirectBuy(directBuy, directBuySteps));
    }, [directBuyId, directBuySteps]);

    // This value can change from clientside actions and time
    const [[directBuyState, directBuyStage], setDirectBuyState] = useState(() =>
        getStateForDirectBuy(directBuy, directBuySteps)
    );
    useEffect(() => {
        setDirectBuyState(getStateForDirectBuy(directBuy, directBuySteps));
    }, [directBuy, directBuySteps]);

    const [tick, setTick] = useState(0);
    useInterval(() => {
        setDirectBuyState(getStateForDirectBuy(directBuy, directBuySteps));
        setTick(tick + 1);
    }, 1000);

    const [
        indicatorBackgroundColor,
        setIndicatorBackgroundColor
    ] = useState(() =>
        getIndicatorBackgroundColorForDirectBuyState(directBuyState)
    );
    const [indicatorTagContent, setIndicatorTagContent] = useState(() =>
        getIndicatorTagContentForDirectBuy(directBuy, directBuyState, size)
    );
    const [timeString, setTimeString] = useState(() =>
        getTimeStringForDirectBuy(
            directBuy,
            directBuyState,
            directBuyStage,
            size
        )
    );
    useEffect(() => {
        setIndicatorBackgroundColor(
            getIndicatorBackgroundColorForDirectBuyState(directBuyState)
        );
        setIndicatorTagContent(
            getIndicatorTagContentForDirectBuy(directBuy, directBuyState, size)
        );
        setTimeString(
            getTimeStringForDirectBuy(
                directBuy,
                directBuyState,
                directBuyStage,
                size
            )
        );
    }, [directBuy, directBuyState, directBuyStage, size, tick]);

    const [outerPadding, setOuterPadding] = useState(() =>
        outerPaddingForDirectBuyPreviewSize(size)
    );
    const [outerBorderRadius, setOuterBorderRadius] = useState(() =>
        outerBorderRadiusForDirectBuyPreviewSize(size)
    );
    const [innerPadding, setInnerPadding] = useState(() =>
        innerPaddingForDirectBuyPreviewSize(size)
    );
    const [innerBorderRadius, setInnerBorderRadius] = useState(() =>
        innerBorderRadiusForDirectBuyPreviewSize(size)
    );

    useEffect(() => {
        setOuterPadding(outerPaddingForDirectBuyPreviewSize(size));
        setOuterBorderRadius(outerBorderRadiusForDirectBuyPreviewSize(size));
        setInnerPadding(innerPaddingForDirectBuyPreviewSize(size));
        setInnerBorderRadius(innerBorderRadiusForDirectBuyPreviewSize(size));
    }, [size]);

    return (
        <OuterMain
            padding={outerPadding}
            clickable={Boolean(onClick)}
            onClick={onClick}
        >
            <IndicatorBackground
                color={indicatorBackgroundColor}
                padding={innerPadding}
                borderRadius={outerBorderRadius}
            >
                <DirectBuyPreviewContent
                    payments={payments}
                    socialAccounts={socialAccounts}
                    photoUrl={photoUrl}
                    timeString={timeString}
                    borderRadius={innerBorderRadius}
                />
            </IndicatorBackground>
            <IndicatorTag content={indicatorTagContent} />
        </OuterMain>
    );
};

export default DirectBuyPreview;
