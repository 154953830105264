import React, { useState } from "react";
import SettingsText from "../components/SettingsText";
import SettingsLink from "../components/SettingsLink";
import SpaceBetween from "../../../components/SpaceBetween";
import styled from "styled-components";
import SettingsTextArea from "../components/SettingsTextArea";
import { colors, fonts } from "../../../constants";
import OutlineContainer from "../components/OutlineContainer";
import Divider from "../../../components/Divider";

const Main = styled(OutlineContainer)`
    padding: 15px 20px;
    position: relative;
    background-color: white;
`;

const CustomDivider = styled(Divider)`
    margin: 15px 0;
`;

const NoUsername = styled(SettingsText)`
    font-style: italic;
    font-weight: 300;
    color: ${colors.primaryGray};
`;

const BioLength = styled.div<{ atLimit: boolean }>`
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: ${fonts.smallSubtitle}px;
    color: ${props => (props.atLimit ? colors.primaryRed : colors.primaryGray)};
`;

interface Props {
    username: string | null;
    usernameModal: VoidFunction;
    bio: string;
    setBio: (bio: string) => void;
}

const EditUsernameBio = ({ username, usernameModal, bio, setBio }: Props) => {
    const [displayCount, setDisplayCount] = useState(false);

    return (
        <Main>
            <SpaceBetween>
                {username ? (
                    <SettingsText>{username}</SettingsText>
                ) : (
                    <NoUsername>Set Username</NoUsername>
                )}
                <SettingsLink onClick={usernameModal}>
                    {username ? "Change" : "Set"}
                </SettingsLink>
            </SpaceBetween>
            <CustomDivider />
            <SettingsTextArea
                placeholder="Edit bio"
                value={bio}
                onChange={e => {
                    const element = e.currentTarget as HTMLTextAreaElement;
                    if (element.value.length <= 130) {
                        setBio(element.value);
                    }
                }}
                onFocus={() => setDisplayCount(true)}
                onBlur={() => setDisplayCount(false)}
            />
            {displayCount && (
                <BioLength atLimit={bio.length === 130}>
                    ({bio.length}/130)
                </BioLength>
            )}
        </Main>
    );
};

export default EditUsernameBio;
