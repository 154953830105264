import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { patch } from "src/Api";
import useQuery from "src/hooks/useQuery";
import styled from "styled-components";
import cookie from "js-cookie";
import { BigBlueButton } from "src/profile/components/Buttons";
import { getAuthRedirectDescription, getAuthRedirectTitle } from "../helpers";

const { appId } = process.env.REACT_APP_FACEBOOK_APP_INFO
    ? JSON.parse(process.env.REACT_APP_FACEBOOK_APP_INFO)
    : {};
const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 900px;
    margin: auto;
    height: 100vh;
    padding: 18px;
    gap: 24px;
    @media (max-width: 900px) {
        align-items: start;
        text-align: left;
        width: auto;
    }
`;

const Pluggy = styled.img`
    height: 80px;
    width: 80px;
    margin: 0 14px;

    @media (min-width: 900px) {
        height: 120px;
        width: 120px;
    }
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    margin: 0 14px;
    @media (min-width: 900px) {
        font-size: 32px;
    }
`;

const Description = styled.div`
    font-size: 14px;
    margin: 0 14px 20px 14px;
    @media (min-width: 900px) {
        font-size: 24px;
    }
`;

const Button = styled(BigBlueButton)`
    max-width: 380px;

    @media (min-width: 900px) {
        font-size: 24px;
    }
`;

const FacebookRedirect = () => {
    const [status, setStatus] = useState("loading");
    const history = useHistory();
    const params = useQuery();
    const socialID = cookie.get("instagramSocialID");

    const redirectURI = window.location.origin.includes("localhost")
        ? window.location.origin
        : "https://app.plugco.in" + `/auth/instagram/`;

    async function processCode() {
        try {
            let res = await patch(
                `/public/social_account/${socialID}/instagram/auth`,
                {
                    code: params.get("code"),
                    redirectURI
                }
            );
            setStatus(res.data.data.verified ? "success" : "failure");
        } catch (error) {
            setStatus("Error verifying account");
        }
    }

    const goBack = () => {
        var now = new Date().valueOf();
        setTimeout(function() {
            if (new Date().valueOf() - now > 100) return;
            //default to web redirect if past time limit
            history.push("/profile/accounts");
        }, 25);
        //try to go to mobile app
        window.location.replace("theplug://socialAccount");
    };

    useEffect(() => {
        if (!params.get("code")) {
            cookie.set("instagramSocialID", params.get("socialID"));
            cookie.set("isMobileRedirect", params.get("mobile"));
            window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${appId}&redirect_uri=${redirectURI}&scope=user_profile,user_media&response_type=code`;
        } else processCode();
    }, []);

    return (
        <Main>
            <Pluggy src={"/logo.svg"} />
            <Title>{getAuthRedirectTitle(status, "Instagram")}</Title>
            {!!status && (
                <>
                    <Description>
                        {getAuthRedirectDescription(status, "Instagram", "")}
                    </Description>
                    <Button onClick={goBack}>Return To The Plug</Button>
                </>
            )}
        </Main>
    );
};
export default FacebookRedirect;
