import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { clearModal } from "../modals/actions";
import { ModalMedia } from "./actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Parent = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Child = styled.div`
    height: fit-content;
    width: 70vw;
    max-width: 350px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
`;

const Video = styled.video`
    display: block;
    width: 100%;
    cursor: pointer;
    min-height: 500px;
`;

const Image = styled.img`
    display: block;
    width: 100%;
`;

const PlayScreen = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    font-size: 50px;
    pointer-events: none;
`;

interface Props {
    modalMedia: ModalMedia | null;
    clearModal: () => void;
}

const MediaModal = ({ modalMedia, clearModal }: Props) => {
    const [play, setPlay] = useState(false);

    if (!modalMedia) {
        clearModal();
        return null;
    }

    let media;

    if (modalMedia.type === "video") {
        media = (
            <>
                <Video
                    playsInline={true}
                    src={modalMedia.mediaUrl}
                    poster={modalMedia.coverUrl}
                    onClick={e => {
                        if (!play) {
                            setPlay(true);
                        }
                        const target = e.target as HTMLVideoElement;
                        target.paused ? target.play() : target.pause();
                    }}
                />
                <PlayScreen style={{ display: play ? "none" : "flex" }}>
                    <FontAwesomeIcon icon={["fas", "play"]} />
                </PlayScreen>
            </>
        );
    } else if (modalMedia.type === "photo") {
        media = <Image src={modalMedia.mediaUrl} />;
    } else {
        media = <Image alt={"could not find media"} />;
    }

    return (
        <Parent onClick={clearModal}>
            <Child onClick={e => e.stopPropagation()}>{media}</Child>
        </Parent>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    modalMedia: state.analytics.currentMedia
});

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaModal);
