interface StatusType {
    title: string;
    description: string;
    img?: string;
    style?: any;
}

export const statusTypes: { [key: string]: StatusType } = {
    diamond: {
        title: "DIAMOND",
        description: "Top 5",
        img: "/diamond/diamond@2x.png",
        style: { height: "30px", width: "30px" }
    },
    gold: {
        title: "GOLD",
        description: "Top 20",
        img: "/gold/gold@2x.png",
        style: { height: "28px", width: "28px" }
    },
    silver: {
        title: "SILVER",
        description: "Top 50",
        img: "/silver/silver@2x.png",
        style: { height: "30px", width: "30px" }
    },
    none: {
        title: "No Status",
        description: "Everyone Else"
    },
    employee: {
        title: "EMPLOYEE",
        description: "Plug Employee"
    }
};
