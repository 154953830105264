import { Action } from "../../types";
import {
    NOTIFICATION,
    PillNotificationType,
    SET_PILL_NOTIFICATION_TEXT
} from "./actions";

export interface NotificationViewModel {
    text: string;
    timestamp: number;
    color?: string;
}

export interface NotificationsUIState {
    notifications: NotificationViewModel[];
    pillNotification: {
        text: string;
        type: PillNotificationType;
        time: number;
    };
}

const initialNotificationsState: NotificationsUIState = {
    notifications: [],
    pillNotification: {
        text: "",
        type: "success",
        time: 1000
    }
};

const notificationsReducer = (
    state = initialNotificationsState,
    action: Action
) => {
    switch (action.type) {
        case NOTIFICATION.ADD:
            if (window.navigator.vibrate) {
                window.navigator.vibrate(400);
            }
            return {
                ...state,
                notifications: state.notifications.concat([action.payload])
            };

        case NOTIFICATION.REMOVE:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (item, index) => index !== 0
                )
            };

        case SET_PILL_NOTIFICATION_TEXT:
            if (window.navigator.vibrate) window.navigator.vibrate(400);
            return {
                ...state,
                pillNotification: action.payload
            };

        default:
            return state;
    }
};

export default notificationsReducer;
