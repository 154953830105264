import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminFetchOpenBuy } from "src/admin-tools/actions.admin-tools";
import ModalHeading from "src/components/modals/components/ModalHeading";
import ModalList from "src/components/modals/components/ModalList";
import StatusMessage from "src/components/modals/components/StatusMessage";
import withModalPortal from "src/components/withModalPortal";
import { colors } from "src/constants";
import { selectMarketplaceOpenBuy } from "src/marketplace/redux/selectors";
import CarouselMedia from "src/modals/CarouselMedia";
import SmallModal from "src/modals/SmallModal";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import { ReactComponent as ExternalLinkIcon } from "src/components/icons/external-link.svg";
import ModalIconBtn from "src/components/modals/components/ModalIconBtn";

interface Props {
    submission: any;
    handleClose: VoidFunction;
}
const Wrapper = styled.div`
    max-height: 500px;
    overflow: auto;
`;
const StyledCarouselMedia = styled(CarouselMedia)`
    background-color: ${colors.grayBlack};
    height: 400px;
    max-height: 400px;
    flex: 1;
    width: auto;
    overflow: hidden;
    & > video {
        display: block;
        height: 100%;
        width: auto;
    }
`;
const StyledStatusMessage = styled(StatusMessage)`
    margin-bottom: 10px;
`;

const HeadingRow = styled.div`
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
`;

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
    fill: ${colors.gray2};
`;

const PastSubmissionModal = ({ submission, handleClose }: Props) => {
    const dispatch = useDispatch();

    const linkTag = "link";
    const mediaTag = "media";
    const textTag = "text";

    const openBuy = useSelector((state: GlobalState) =>
        selectMarketplaceOpenBuy(state, submission.openBuyId)
    );

    useEffect(() => {
        if (!openBuy) dispatch(adminFetchOpenBuy(submission.openBuyId));
    }, [submission]);

    const type = submission.linkSubmission
        ? linkTag
        : submission.customMediaId
        ? mediaTag
        : submission.textSubmission
        ? textTag
        : null;

    const details = Object.entries(submission).reduce(
        (acc: any[], [key, val]) => {
            switch (key) {
                case "createdAt":
                    if (typeof val === "string") {
                        return [
                            ...acc,
                            {
                                label: "Created At",
                                type: "string",
                                value: new Date(val).toLocaleDateString(
                                    "en-us",
                                    {
                                        year: "2-digit",
                                        month: "2-digit",
                                        day: "2-digit"
                                    }
                                )
                            }
                        ];
                    } else {
                        return acc;
                    }
                case "id":
                    return [
                        ...acc,
                        { label: "Submission ID", type: "id", value: val }
                    ];
                case "status":
                    return [
                        ...acc,
                        { label: "Status", type: "string", value: val }
                    ];
                case "openBuyId":
                    return [
                        ...acc,
                        {
                            label: "Challenge Name",
                            type: "string",
                            value: openBuy?.name
                        }
                    ];
                case "deletedAt":
                    if (typeof val === "string") {
                        return [
                            ...acc,
                            {
                                label: "Deleted At",
                                type: "string",
                                value: new Date(val).toLocaleDateString(
                                    "en-us",
                                    {
                                        year: "2-digit",
                                        month: "2-digit",
                                        day: "2-digit"
                                    }
                                )
                            }
                        ];
                    } else {
                        return acc;
                    }
                case "rejectionReason":
                    if (typeof val !== "string") return acc;
                    return [
                        ...acc,
                        {
                            label: "Rejection Reason",
                            type: "paragraph",
                            value: val
                        }
                    ];
                default:
                    return acc;
            }
        },
        []
    );

    const getModalHeading = () => {
        if (submission.textSubmission) return "SPARK SUBMISSION";
        if (submission.linkSubmission) return "LINK SUBMISSION";
        return "MEDIA SUBMISSION";
    };

    return (
        <SmallModal header={{ text: "Previous Submission", handleClose }}>
            <Wrapper>
                <HeadingRow>
                    <ModalHeading text={getModalHeading()} />
                    {type && (type === linkTag || type === textTag) && (
                        <a
                            href={
                                submission.linkSubmission ||
                                submission.fullSubmissionUrl ||
                                ""
                            }
                            target="_blank"
                        >
                            <ModalIconBtn>
                                <StyledExternalLinkIcon />
                            </ModalIconBtn>
                        </a>
                    )}
                </HeadingRow>
                {type === mediaTag && submission.customMedia && (
                    <StyledCarouselMedia
                        mediaUrl={submission.customMedia.url}
                        coverPhotoUrl={submission.customMedia.coverPhotoUrl}
                        mediaType={submission.customMedia.type}
                        isPortrait={false}
                    />
                )}
                {submission.status === "rejected" && (
                    <StyledStatusMessage
                        type={"danger"}
                        heading={`Submission Rejected ${
                            submission.rejectedBy
                                ? `By ${submission.rejectedBy.firstname} ${submission.rejectedBy.lastname}`
                                : ""
                        }`}
                        body={
                            submission.rejectionReason &&
                            submission.rejectionReason
                        }
                    />
                )}
                <ModalList header="DETAILS" listItems={details} />
            </Wrapper>
        </SmallModal>
    );
};

export default withModalPortal(PastSubmissionModal);
