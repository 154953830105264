import React, {
    useMemo,
    useState,
    forwardRef,
    useRef,
    useImperativeHandle,
    Ref
} from "react";
import { colors, shadows } from "src/constants";
import styled from "styled-components";
import { TableColumn, TableRow } from "./types";
import useSorting, { SortBy } from "./hooks/useSorting";
import TableColumns, { ManualPlugTableSort } from "./components/TableColumns";
import TableBody from "./components/TableBody";
import usePages from "./hooks/usePages";
import TableFooter, { TableFooterRef } from "./components/TableFooter";
import TableHeader from "./components/TableHeader";
import useFilters, {
    ActiveFilters,
    CurrentFilters,
    FilterOption
} from "./hooks/useFilters";
import ScrollableWithFadedArrows from "../ScrollableWithFadedArrows";
import XCard from "src/profile/components/XCard";
import useSearch from "./hooks/useSearch";
import InnerTableLoader from "./components/InnerTableLoader";
import TableServerHeader, {
    ServerFilters
} from "./components/TableServerHeader";
import { MultiSelectReference } from "./hooks/useMultiSelect";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: ${shadows.four};
    border: 1px solid ${colors.mediumTeal};
    background-color: white;
`;

const ContentWrapper = styled.div<{ noHeader: boolean }>`
    padding: ${props => (props.noHeader ? "0px 0px 16px" : "16px")};
    background-color: ${colors.secondaryLightGray};
    isolation: isolate;
    border-radius: ${props => (props.noHeader ? "8px" : "revert")};
`;
const TableWrap = styled.div`
    padding: 12px;
    border-radius: 8px;
    background-color: white;
    overflow-x: auto;
`;
const Table = styled.table`
    font-size: 12px;
    border-radius: 8px;
    background-color: white;
    min-width: 100%;
    padding: 12px;
`;
const StyledXCard = styled(XCard)<{ height: number }>`
    height: ${props => props.height}px;
    width: 100%;
`;

export interface ColumnsReference {
    [key: string]: TableColumn;
}

export interface RowSingleSelect {
    setter: React.Dispatch<React.SetStateAction<TableRow | null>>;
    getter: any;
}
export interface CustomComponents {
    [key: string]: React.ReactElement;
}
export interface ServerConfig {
    paginate: (currentPage: number) => void;
    filters: {
        getter: ServerFilters;
        setter: React.Dispatch<React.SetStateAction<ServerFilters>>;
    };
    count: number;
}
export interface TableButton {
    text: string;
    color: "red" | "blue" | "gray";
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
}
export type PlugTableRef = {
    exportCsv: () => void;
};

interface Props {
    cellData: TableRow[];
    className?: string;
    resultsPerPage?: number;
    rowHeight?: number;
    columns?: TableColumn[];
    rowSingleSelect?: RowSingleSelect;
    rowClick?: (
        row: TableRow,
        rowIdx: number,
        sortedData: TableRow[],
        e: React.MouseEvent<HTMLTableRowElement>
    ) => void;
    filterOptions?: FilterOption[] | null;
    title?: string;
    titleSize?: number;
    noResults?: {
        message: string;
        btnText?: string;
        callback?: VoidFunction;
    };
    error?: {
        message: string;
        btnText: string;
        callback: VoidFunction;
    } | null;
    filenamePrefix?: string;
    serversideConfig?: ServerConfig;
    tableLoading?: boolean;
    includedRawColumnsInCsv?: string[];
    refreshHandler?: VoidFunction;
    tableButtons?: TableButton[];
    multiSelectReference?: MultiSelectReference;
    manualSort?: ManualPlugTableSort;
}

const PlugTable = (
    {
        cellData,
        resultsPerPage = 12,
        rowHeight = 49,
        columns,
        rowSingleSelect,
        rowClick,
        className,
        filterOptions = null,
        title,
        titleSize,
        noResults,
        filenamePrefix,
        serversideConfig,
        tableLoading,
        includedRawColumnsInCsv,
        refreshHandler,
        tableButtons,
        error,
        multiSelectReference,
        manualSort
    }: Props,
    ref: Ref<PlugTableRef>
) => {
    const footerRef = useRef<TableFooterRef>(null);
    useImperativeHandle(ref, () => ({
        exportCsv: () => {
            footerRef.current?.handleDownload();
        }
    }));
    const columnsReference: ColumnsReference = columns
        ? columns.reduce((acc, val) => {
              return { ...acc, [val.coreKey]: val };
          }, {})
        : Object.keys(cellData[0].rowData).reduce((acc: any, key: string) => {
              return {
                  ...acc,
                  [key]: { key, displayValue: key }
              };
          }, {});

    const columnsOrder = columns
        ? columns.filter(col => !col.hidden).map(val => val.coreKey)
        : Object.keys(cellData[0].rowData);

    const filters: { [key: string]: FilterOption } | null = filterOptions
        ? filterOptions.reduce((acc, filter) => {
              return { ...acc, [filter.coreKey]: filter };
          }, {})
        : null;

    const validFilterOptions: FilterOption[] | null = filterOptions
        ? serversideConfig
            ? filterOptions
            : columnsOrder.reduce(
                  (acc: FilterOption[], val): FilterOption[] => {
                      if (filters && filters[val]) {
                          return [...acc, filters[val]];
                      } else {
                          return acc;
                      }
                  },
                  []
              )
        : null;

    const filtersReference = useFilters(validFilterOptions);

    const searchReference = useSearch(cellData, columnsReference);
    const { isSearchable } = searchReference;

    const getDefaultSort = (): SortBy | null => {
        const defaultSortColumn =
            !!columns && columns.filter(col => col.defaultSort);

        if (defaultSortColumn && defaultSortColumn.length > 1) {
            console.error(
                `PLUG TABLE: Only one column should have a defaultSort property. COLUMNS: ${defaultSortColumn
                    .map(col => col.coreKey)
                    .join(", ")}`
            );
        }
        if (
            defaultSortColumn &&
            defaultSortColumn.length > 0 &&
            defaultSortColumn[0].defaultSort
        ) {
            const isSortTypeIncluded =
                defaultSortColumn[0].sortType !== undefined;
            if (isSortTypeIncluded) {
                return {
                    sortType: defaultSortColumn[0].sortType,
                    coreKey: defaultSortColumn[0].coreKey,
                    direction: defaultSortColumn[0].defaultSort
                };
            } else {
                console.error(
                    `PLUG TABLE: column ${defaultSortColumn[0].coreKey} is set to defaultSort but missing a sortType!`
                );
            }
        }
        return null;
    };

    const sortReference = useSorting(
        filtersReference?.areAnyFiltersActive
            ? filtersReference.filterData(searchReference.searchedData)
            : searchReference.searchedData,
        getDefaultSort()
    );

    const tableLength = sortReference.sortedData.length;

    const pageReference = usePages(
        serversideConfig?.count ? serversideConfig.count : tableLength,
        resultsPerPage,
        serversideConfig?.paginate
    );

    const noResultsPassedToTable = cellData.length === 0;
    const noFilteredResults = sortReference.sortedData.length === 0;

    const shouldShowHeader =
        Boolean(title) || isSearchable || Boolean(filterOptions);

    const xCardHeight = (resultsPerPage + 1) * rowHeight;

    return (
        <Wrapper>
            {serversideConfig ? (
                <TableServerHeader
                    title={title}
                    titleSize={titleSize}
                    tableButtons={tableButtons}
                    filters={serversideConfig.filters}
                />
            ) : shouldShowHeader ? (
                <TableHeader
                    title={title}
                    titleSize={titleSize}
                    searchReference={searchReference}
                    filtersReference={filtersReference}
                    tableButtons={tableButtons}
                />
            ) : null}
            <ContentWrapper
                noHeader={!shouldShowHeader && !serversideConfig}
                className={className}
            >
                <TableWrap>
                    {tableLoading ? (
                        <InnerTableLoader
                            resultsPerPage={resultsPerPage}
                            rowHeight={rowHeight}
                            noPadding={true}
                        />
                    ) : error ? (
                        <StyledXCard
                            height={xCardHeight}
                            boxFullHeight
                            text={error.message}
                            button={{
                                text: error.btnText,
                                callback: error.callback
                            }}
                        />
                    ) : noResultsPassedToTable ? (
                        <StyledXCard
                            height={xCardHeight}
                            boxFullHeight
                            text={
                                noResults?.message
                                    ? noResults.message
                                    : "No results"
                            }
                            button={
                                noResults &&
                                noResults.btnText &&
                                noResults.callback
                                    ? {
                                          text: noResults.btnText,
                                          callback: noResults.callback
                                      }
                                    : undefined
                            }
                        />
                    ) : noFilteredResults ? (
                        <StyledXCard
                            height={xCardHeight}
                            boxFullHeight
                            text={"No results for the current filters"}
                        />
                    ) : (
                        <>
                            <ScrollableWithFadedArrows fullWidth={true}>
                                <Table>
                                    <TableColumns
                                        columnsOrder={columnsOrder}
                                        columnsReference={columnsReference}
                                        sortReference={sortReference}
                                        multiSelectReference={
                                            multiSelectReference
                                        }
                                        manualSort={manualSort}
                                    />
                                    <TableBody
                                        rowHeight={rowHeight}
                                        columnsReference={columnsReference}
                                        columnsOrder={columnsOrder}
                                        sortedData={sortReference.sortedData}
                                        pageReference={pageReference}
                                        rowSingleSelect={rowSingleSelect}
                                        rowClick={rowClick}
                                        tableLoading={tableLoading}
                                        multiSelectReference={
                                            multiSelectReference
                                        }
                                    />
                                </Table>
                            </ScrollableWithFadedArrows>
                        </>
                    )}
                </TableWrap>
                <TableFooter
                    ref={footerRef}
                    filenamePrefix={filenamePrefix || "table"}
                    columnsReference={columnsReference}
                    data={sortReference.sortedData}
                    pageReference={pageReference}
                    tableLength={tableLength}
                    serverPaginate={Boolean(serversideConfig?.paginate)}
                    serverCount={serversideConfig?.count}
                    includedRawColumnsInCsv={includedRawColumnsInCsv}
                    refreshHandler={refreshHandler}
                />
            </ContentWrapper>
        </Wrapper>
    );
};

export default forwardRef(PlugTable);
