import { mergeWith, isArray } from "lodash";
import { combineReducers } from "redux";
import { NormalizedActionGeneric } from "src/types";
import { MESSAGE_CHANNEL } from "../message/actions";
import { Conversation } from "../types/Conversation";
import {
    UPDATE_CONVERSATION,
    UPDATE_CONVERSATION_REQUEST,
    UPDATE_CONVERSATION_UNREAD
} from "./actions";
import { UPDATE_INTERNAL_NOTES_CONVERSATION } from "../approval-internal-notes/actions";

interface ById {
    [key: string]: Conversation;
}

export interface ConversationsEntitiesState {
    byId: ById;
    allIds: string[];
}

const initialConversationsState: ConversationsEntitiesState = {
    byId: {},
    allIds: []
};

function byIdReducer(
    state = initialConversationsState.byId,
    action: NormalizedActionGeneric<Conversation, string[]>
) {
    switch (action.type) {
        case UPDATE_CONVERSATION:
        case UPDATE_CONVERSATION_UNREAD:
        case UPDATE_CONVERSATION_REQUEST:
        case UPDATE_INTERNAL_NOTES_CONVERSATION:
            return mergeWith(
                {},
                state,
                action.response.entities.conversations,
                (a, b) => (isArray(b) ? b : undefined)
            );
        case MESSAGE_CHANNEL.UPDATE:
            const conversation = state[action.payload.conversationId];
            return {
                ...state,
                [action.payload.conversationId]: {
                    ...conversation,
                    messages: action.response.result
                }
            };
        default:
            return state;
    }
}

function allIdsReducer(
    state = initialConversationsState.allIds,
    action: NormalizedActionGeneric<Conversation, string[]>
) {
    switch (action.type) {
        case UPDATE_CONVERSATION:
            return action.response.result;

        default:
            return state;
    }
}

const conversationsEntitiesReducer = combineReducers<
    ConversationsEntitiesState
>({
    byId: byIdReducer,
    allIds: allIdsReducer
});

export default conversationsEntitiesReducer;
