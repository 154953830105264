import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
    SUBMIT_EVENT,
    submitEventFailure,
    submitEventSuccess
} from "./actions";
import { Action } from "../types";
import { post } from "../Api";
import { getPlatform } from "src/util";

export function* submitEventRequest({
    payload: { event_name, event_extra, value }
}: Action) {
    try {
        const platform = getPlatform();

        const { status } = yield call(post, "/api/submit_event", {
            platform,
            event_name,
            event_extra: {
                client: "web",
                trackedInSplit: false,
                ...event_extra
            }
        });

        if (status === 200) {
            yield put(submitEventSuccess());
        } else {
            yield put(submitEventFailure(event_name));
        }
    } catch (error) {
        yield put(submitEventFailure(event_name));
    }
}

export default function* eventSaga() {
    yield all([takeEvery(SUBMIT_EVENT.REQUEST, submitEventRequest)]);
}
