import React, { useState } from "react";
import IndicatorBackground from "./IndicatorBackground";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
    selectAllAllowedPlatforms,
    selectOpenBuysById,
    selectOpenBuyStepEntities,
    selectOpenBuySubmissions
} from "../selectors";
import IndicatorTag from "./IndicatorTag";
import {
    getIndicatorBackgroundColorForOpenBuyState,
    getIndicatorTagContentForOpenBuy
} from "./openBuyPreviewUtil";
import {
    getOpenBuyPayments,
    getStateForOpenBuy
} from "../modules/open-buys/util";
import { OpenBuyState } from "../modules/open-buys/types";
import { post } from "src/Api";
import { GlobalState } from "src/reducers";
import Platforms from "src/social-accounts/Platforms";
import { breakpoints, colors } from "src/constants";
import { PaymentConjunction } from "src/types/PaymentConjunction";
import { coinToDollars } from "src/util";

interface OuterMainStyleProps {
    clickable: boolean;
    size?: OpenBuyPreviewSize;
}

const OuterMain = styled.div<OuterMainStyleProps>`
    width: 295px;
    height: 180px;
    cursor: pointer;
    position: relative;
    ${props => (props.clickable ? "cursor: pointer;" : "")};
    ${props =>
        props.size === OpenBuyPreviewSize.large &&
        `display: flex; width: 100%; height: 100%; height: 340px;`};
    @media (max-width: ${breakpoints.mobile}px) {
        max-height: 200px;
    }
`;

const Main = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 10px;
    overflow: hidden;
`;

const CoverPhoto = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    max-height: 340px;
`;

const ContentScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        rgba(0, 0, 0, 0.5) 0%,
        transparent 40%,
        transparent 50%,
        rgba(0, 0, 0, 0.5) 100%
    );
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;
const PaymentsLabel = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: white;
`;

const PlatformIcon = styled.img`
    height: 20px;
    width: 20px;
    border-radius: 4px;
`;

const TagIcon = styled.img`
    margin: auto 5px auto auto;
    height: 10px;
    width: 10px;
`;

const TimeLabel = styled.div`
    display: flex;
    width: fit-content;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: white;
    padding: 3px 6px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
`;
const Name = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    color: white;
    margin-top: 4px;
`;

const Row = styled.div`
    display: flex;
    gap: 6px;
    padding: 0;
    align-items: center;
    margin-top: 4px;
`;

const Payouts = styled.div`
    border-radius: 6px;
    height: 26px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    padding: 6px 8px;
    line-height: 14px;
    background: ${colors.secondaryGreen};
`;

export enum OpenBuyPreviewSize {
    large,
    small
}

interface Props {
    openBuyId: number;
    size?: OpenBuyPreviewSize;
    onClick?: () => void;
    searchView?: boolean;
}

const OpenBuyPreview = ({ openBuyId, onClick, size, searchView }: Props) => {
    const openBuy = useSelector(selectOpenBuysById)[openBuyId];
    const submissions = useSelector((state: GlobalState) =>
        selectOpenBuySubmissions(state, openBuyId)
    );
    const yourSubmissions = openBuy?.openBuySubmissions || [];
    const steps = useSelector(selectOpenBuyStepEntities);
    const state = getStateForOpenBuy(
        openBuy,
        submissions.filter(submission =>
            yourSubmissions.includes(submission.id)
        ),
        openBuy.instructionSteps?.map(id => steps[id])
    );
    const allowedPlatforms = useSelector((state: GlobalState) =>
        selectAllAllowedPlatforms(state, openBuy)
    );

    const paymentCollection = getOpenBuyPayments(openBuy, steps);

    const setViewed = () => {
        if (state === OpenBuyState.new)
            post(`/v1/openBuy/${openBuy?.openBuyId}/viewed`, {});
    };

    const formatPayments = () => {
        let ans = "";
        let flat = 0;
        let perK = 0;
        let or = false;
        paymentCollection.map((collection, index) => {
            let currentFlat = 0;
            let currentPerK = 0;
            or =
                collection.payment?.paymentConjunction ===
                PaymentConjunction.OR;
            collection.payment?.directBuyPayments.map(payment => {
                if (payment.payment.type === "flat_rate")
                    or
                        ? (currentFlat =
                              currentFlat > payment.amount
                                  ? currentFlat
                                  : payment.amount)
                        : (currentFlat += payment.amount);
                else
                    currentPerK =
                        currentPerK > payment.amount
                            ? currentPerK
                            : payment.amount;
            });
            flat += currentFlat;
            perK += currentPerK;
        });

        if (flat) ans += or ? `up to $${flat}` : `$${flat}`;
        if (perK) {
            if (flat) ans += or ? " or " : ", ";
            ans += `up to $${perK} per K`;
        }
        return ans;
    };

    const getPaidAmount = () => {
        let sum = 0;
        submissions.forEach(submission => {
            let tranSum = 0;
            if (yourSubmissions.includes(submission.id))
                submission.payouts?.forEach(sub => {
                    tranSum += sub.transactionAmount || 0;
                });
            sum += tranSum;
        });
        return sum ? parseInt(coinToDollars(sum, true)).toFixed(0) : 0;
    };

    const amount = getPaidAmount();
    if (!openBuy) return null;

    const posterUrl = openBuy?.posterUrl;

    return (
        <OuterMain clickable={Boolean(onClick)} size={size} onClick={onClick}>
            <IndicatorBackground
                color={getIndicatorBackgroundColorForOpenBuyState(state)}
                padding={4}
                borderRadius={10}
            >
                <Main onClick={setViewed}>
                    {posterUrl && <CoverPhoto src={posterUrl} />}
                    <ContentScreen>
                        <FlexColumn>
                            {
                                <PaymentsLabel>
                                    {`Earnings: ${formatPayments()}`}
                                </PaymentsLabel>
                            }
                            <Row style={{ gap: 4, padding: 0 }}>
                                {openBuy?.openBuyTags?.map((tag, index) => (
                                    <TimeLabel key={index + "tag"}>
                                        {tag.iconUrl && (
                                            <TagIcon src={tag.iconUrl} />
                                        )}
                                        {tag.name}
                                    </TimeLabel>
                                ))}
                                {allowedPlatforms.map(platform => (
                                    <PlatformIcon
                                        key={platform}
                                        src={Platforms[platform]?.imgSrc}
                                    />
                                ))}
                                {!!amount && (
                                    <Payouts>${amount} Earned!</Payouts>
                                )}
                            </Row>
                        </FlexColumn>
                        <Name>{openBuy.name}</Name>
                    </ContentScreen>
                </Main>
            </IndicatorBackground>
            <IndicatorTag
                content={getIndicatorTagContentForOpenBuy(
                    state,
                    openBuy.isApplication,
                    submissions.filter(submission =>
                        yourSubmissions.includes(submission.id)
                    )
                )}
                isOpenBuy={true}
            />
        </OuterMain>
    );
};

export default OpenBuyPreview;
