import React from "react";
import { useDispatch } from "react-redux";
import ActionsModal from "src/modals/ActionsModal";
import { setCampaignSort } from "src/ui/campaigns/actions";

interface Props {
    closeModal: () => void;
}

const CampaignSortModal = ({ closeModal }: Props) => {
    const dispatch = useDispatch();

    function submitSort(sortBy: string) {
        return () => {
            dispatch(setCampaignSort(sortBy));
            closeModal();
        };
    }

    return (
        <ActionsModal
            actions={[
                {
                    text: "Recommended",
                    callback: submitSort("Recommended")
                },
                {
                    text: "Newest First",
                    callback: submitSort("Newest First")
                },
                {
                    text: "Oldest First",
                    callback: submitSort("Oldest First")
                },
                {
                    text: "Payout: Highest to Lowest",
                    callback: submitSort("Payout: Highest to Lowest")
                },
                {
                    text: "Payout: Lowest to Highest",
                    callback: submitSort("Payout: Lowest to Highest")
                },
                {
                    text: "Alphabetical: A-Z",
                    callback: submitSort("Alphabetical: A-Z")
                },
                {
                    text: "Alphabetical: Z-A",
                    callback: submitSort("Alphabetical: Z-A")
                }
            ]}
            localClearModal={closeModal}
        />
    );
};

export default CampaignSortModal;
