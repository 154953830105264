import React from "react";
import { useDispatch } from "react-redux";
import TwoButtonFooter from "src/components/TwoButtonFooter";
import { colors } from "src/constants";
import styled from "styled-components";
import { setConversationRequestStatus } from "../conversation/actions";
import { ConversationRequest } from "../types/ConversationRequest";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${colors.mediumTeal};
`;

const Text = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.9px;
    text-align: left;
    color: ${colors.primaryGray};
    padding: 12px 18px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface Props {
    requestStatus: ConversationRequest;
    usernames?: string[];
}

const ChatRoomRequestFooter = ({ requestStatus, usernames }: Props) => {
    const dispatch = useDispatch();

    function renderUsernames() {
        if (!usernames) return null;
        const length = usernames.length;
        switch (length) {
            case 1:
                return usernames[0];
            case 2:
                return `${usernames[0]} and ${usernames[1]}`;
            default:
                return `${usernames[0]} and ${length - 1} others`;
        }
    }

    return (
        <Main>
            <Text>Do you want to let {renderUsernames()} message you?</Text>
            <TwoButtonFooter
                leftText="Delete"
                leftCallback={() =>
                    dispatch(
                        setConversationRequestStatus({
                            id: requestStatus.id,
                            deleted: true
                        })
                    )
                }
                rightText="Accept"
                rightCallback={() =>
                    dispatch(
                        setConversationRequestStatus({
                            id: requestStatus.id,
                            accepted: true
                        })
                    )
                }
            />
        </Main>
    );
};
export default ChatRoomRequestFooter;
