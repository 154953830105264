import React from "react";
import { ContestPrize } from "../types";
import styled from "styled-components";
import PrizeIcon from "./PrizeIcon";
import { formatNumber } from "../../../util";
import WinnerCell from "./WinnerCell";
import { howToWin } from "../utils/contestObjectFilters";

const Main = styled.div`
    margin: 0 15px 0;
    padding: 15px 15px 0;
    background: white;
    border: 1px solid #e0eef2;
    border-radius: 6px;

    & + & {
        margin-top: 8px;
    }
`;

const Top = styled.div`
    display: flex;
    padding-bottom: 15px;
`;

const Info = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
`;

const HideOverflow = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Title = styled(HideOverflow)`
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
`;

const Subtitle = styled(HideOverflow)`
    margin: 5px 0px;
    font-size: 11px;
    color: #909a9c;
`;

const HowToWin = styled.div`
    width: fit-content;
    padding: 0 5px;
    border: 1px solid #e0eef2;
    border-radius: 2px;
    font-size: 11px;
    line-height: 18px;
    color: #909a9c;
    margin-bottom: 4px;
`;

interface Props {
    prize: ContestPrize;
    setClaimPrize: (claimPrize: ContestPrize) => void;
}

const PrizeCell = ({ prize, setClaimPrize }: Props) => {
    return (
        <Main>
            <Top>
                <PrizeIcon
                    url={prize.image_url}
                    repeats={prize.subPrizes && prize.subPrizes.length}
                />
                <Info>
                    <div>
                        <Title>{prize.name}</Title>

                        <Subtitle>
                            or ${formatNumber(prize.cash_equivalent)} cash
                        </Subtitle>
                    </div>
                    <HowToWin>{howToWin(prize)}</HowToWin>
                </Info>
            </Top>
        </Main>
    );
};

export default PrizeCell;
