import React from "react";
import styled from "styled-components";
import CarouselInfo from "../../modals/CarouselInfo";
import { Campaign } from "src/campaigns/types";
import { Media } from "../../media/types";
import { GlobalState } from "../../reducers";
import { selectCampaign } from "../selectors";
import { connect } from "react-redux";

const Main = styled.div`
    width: 100vw;
    max-width: 500px;
    padding-bottom: 20px;
    color: white;
    text-align: left;
    font-weight: 300;
    user-select: none;
`;

const SelectButton = styled.div`
    width: 250px;
    border-radius: 6px;
    padding: 12px;
    margin: 10px auto 0;
    background-color: rgba(0, 0, 0, 0.8);
    text-align: center;
    cursor: pointer;
`;

interface Props {
    currentCampaign?: Campaign;
    media: Media;
    setCurrentMedia: (media: Media) => void;
    closeModal: () => void;
}

const CustomLinksCarouselCell = ({
    currentCampaign,
    media,
    setCurrentMedia,
    closeModal
}: Props) => {
    if (!currentCampaign) return null;

    return (
        <Main>
            <CarouselInfo
                appIconUrl={currentCampaign.imageUrl}
                campaignName={currentCampaign.campaignName}
                amountComponent={`Media Id: ${media.id}`}
                mediaUrl={media.url || ""}
                mediaType={media.type}
                closeModal={closeModal}
                isPortrait={media.isPortrait}
            />
            <SelectButton
                onClick={() => {
                    setCurrentMedia(media);
                    closeModal();
                }}
            >
                Select
            </SelectButton>
        </Main>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    currentCampaign: selectCampaign(state)
});

export default connect(mapStateToProps)(CustomLinksCarouselCell);
