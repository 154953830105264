import { OldSocialAccount } from "../social-accounts/SocialAccount";
import { Media } from "../media/types";
import { Campaign, OldCampaign } from "src/campaigns/types";
import { CountryPlatformReward } from "src/campaigns/types";
import { Placement } from "../campaigns/types";

export interface Profile {
    id: number;
    firstname: string;
    lastname: string;
    phone_number: string;
    email: string;
    birthday: string;
    username: string | null;
    profile_photo_url: string | null;
    bio: string;
    private_account: boolean;
    status: "none" | "silver" | "gold" | "diamond" | "employee";
    balance: number;
    account_enabled: boolean;
    account_verified: boolean;
    account_verification_failed: boolean;
    agreed_rules: boolean;
    account_setup: boolean;
    dominant_platform: string;
    legacy_account_setup: boolean;
    tipaltiPayeeExists: boolean;
    tipaltiPayeePayable: boolean;
    tipaltiPaymentMethod: string;
    SocialAccounts: OldSocialAccount[];
    Transactions: OldTransaction[];
    ytd_earnings: string;
    paypal_cashout_email: string | null;
    accessStatus: AccessStatuses;
    flags: PublisherFlag[];
}

export enum AccessStatuses {
    ALL = "all",
    CHALLENGES = "challenges",
    BLOCKED = "blocked",
    BLOCKED_ALL = "blocked_all",
    PENDING = "pending"
}

export interface PublisherFlag {
    name: string;
    value: string;
}

// -------------------------------------------------------------------

export interface ReferredUser {
    referrer: number;
    referredPublisher: number;
    activatedAt: string;
    daysActive: number;
    paidReferralBonusAt: null | string;
    earnedPlugcoins: number;
    commission: number;
    created_at: string;
    updated_at: string;
    deleted_at: null | string;
}

// -------------------------------------------------------------------

interface PublisherProfileProfileTagline {
    platform: string;
    followerCount: number;
}

interface PublisherProfileProfile {
    bio: string;
    avatar: string;
    status: string;
    tagLine: PublisherProfileProfileTagline;
    username: string;
    isPlugPartner: boolean;
    privateAccount: boolean;
}

export interface PublisherProfile {
    id: string;
    profile: PublisherProfileProfile;
}

// -------------------------------------------------------------------

export interface FullscreenAlert {
    id: number;
    icon_type: string;
    title: string;
    subtitle: string;
    text: string;
    has_button: boolean;
    button_text: string;
    button_destination: string;
    button_url: string;
    is_resolved: boolean;
    start_time: string;
    end_time: string;
    publisher_id: string;
    is_blocking: boolean;
    does_block_installs: boolean;
}

// -------------------------------------------------------------------

export interface OldTransaction {
    id: number;
    transaction_amount: number;
    paypal_email: string;
    date_pst: string;
    transaction_status: string;
    transaction_method: string;
    transaction_type: string;
    transaction_amount_cents: number;
    publisher_id: number;
}

// -------------------------------------------------------------------

export interface InviteCode {
    inviteCode: {
        id: number;
        invite_code: string;
        has_been_activated: boolean;
        invite_from_user_id: string;
        invite_used_by_user_id: string | null;
        has_paid_referral_bonus: boolean;
        is_preverified: boolean;
        should_pay_percentage: boolean;
        activated_at: string | null;
        earned_plugcoins: number | null;
    };
    status: string;
    acceptedBy?: AcceptedBy;
}

export interface AcceptedBy {
    id: number;
    firstname: string | null;
    lastname: string | null;
    payments_enabled: boolean;
    account_verified: boolean;
    account_enabled: boolean;
    is_legacy_user: boolean;
    tipaltiPayeeExists: boolean;
}

// -------------------------------------------------------------------

export interface CashoutFee {
    type: string;
    fee_string: string;
    min_fee: number;
    max_fee: number;
}

// ------------------------------------------------------------------

export interface BonusBracket {
    rankMin: number;
    rankMax: number;
    bonusPercentage: number;
}

// -------------------------------------------------------------------

export interface WeeklyLeaderboardItem {
    rank: number;
    week: string;
    earnings?: string;
    type: string;
    profilePhotoUrl: string;
    bonusPercentage: number;
    coinBucket?: string;
    relativeCoinBucket?: string;
}

// -------------------------------------------------------------------

export interface LinkList {
    name: string;
    listUrl: string;
    title: string;
    description: string;
    photoUrl: string;
    publisherId: string;
    socialAccountId: string;
    LinkItems: number[];
    id: string;
    bio: string;
}

export interface LinkListItem {
    id: string;
    linkListId: string;
    position: number;
    type: string;
    url: string;
    disabled: boolean;
    title: string;
    photoUrl: string;
    campaignId?: string;
}

// -------------------------------------------------------------------

export interface PublisherEarnings {
    campaign: EarningsCampaign;
    earnings: string;
    countryPlatformReward: CountryPlatformReward;
}

export interface EarningsCampaign {
    id: string;
    name: string;
    url: string;
}

// -------------------------------------------------------------------
export enum ProfileTabState {
    about,
    activity,
    bonus
}

// new types
// -------------------------------------------------------------------

export interface PublicProfile {
    id: string;
    profile: {
        username: string | null;
        bio: string | null;
        avatar: string | null;
        status: "none" | "silver" | "gold" | "diamond" | "employee";
        privateAccount: boolean;
        tagLine: {
            platform: string;
            followerCount: number;
        };
    };
    socialAccounts: SocialAccount[];
}

export interface EarningsSummary {
    balance: number;
    weekly: number;
    total: number;
}

export interface ProfileStats {
    memberSince: string;
    rank: number | null;
    connectionCount: number;
}

export interface Connection {
    entity: {
        id: string;
    };
    other: PublicProfile;
    relationship: "friend" | "block" | null;
    state: "friend" | "requested" | "waiting" | "denied" | "blocked" | null;
}

export interface SocialAccount {
    id: number;
    username: string;
    platform: string;
    followers: string;
    theme: string | null;
    verified: boolean;
    avatar: string | null;
    visibility: "visible" | "connections" | "hidden";
    publisher: {
        id: string;
    };
    publisherOffers: number[];
    profilePhotoUrl: string | null;
    verificationFailureReason: string | null;
}

export interface PublicProfileData {
    connection: Connection;
    stats: ProfileStats;
    allConnections?: Connection[] | null;
    mutualConnections?: Connection[] | null;
    accounts?: SocialAccount[] | null;
    promotedCampaigns?: OldCampaign[] | null;
    customMedia?: Media[] | null;
}
// mock types
// -------------------------------------------------------------------

export interface PublicGroup {
    name: string;
    members: number;
    photo_url: string;
}

// rate types
// -------------------------------------------------------------------

export interface PublisherOffer {
    id: number;
    publisherOfferPosts: PublisherOfferPost[];
}

export interface PublisherOfferPost {
    id: number;
    publisherOfferId: number;
    socialAccountId: number;
    socialAccount: SocialAccount;
    publisherOfferPayments: PublisherOfferPayment[];
    placementId: number;
    placement: Placement;
    customType: string | null;
    duration: number | null;
    permanent: boolean;
}

interface BasePublisherOfferPayment {
    id: number;
    amount: number;
}

interface PaymentTypePublisherOfferPayment extends BasePublisherOfferPayment {
    payment: {
        id: number;
        type: "flat_rate" | "dollars_per_k";
    };
    customType?: never;
}

interface CustomTypePublisherOfferPayment extends BasePublisherOfferPayment {
    customType: string | null;
    payment?: never;
}

export type PublisherOfferPayment =
    | PaymentTypePublisherOfferPayment
    | CustomTypePublisherOfferPayment;

export interface PublisherOfferParams {
    publisherOfferPosts: PublisherOfferPostParams[];
}

interface PublisherOfferPostParams {
    socialAccountId: number;
    placementId?: number;
    customType?: string;
    duration?: number;
    permanent?: boolean;
    publisherOfferPayments: any;
}

export interface PreApprovalCode {
    code: string;
    repCreationObjects: [
        {
            type: string;
            employee_id: number;
            start_date?: string;
            end_date?: string;
        }
    ];
    bypassConnectedSocialAccountRequirement: boolean;
}
