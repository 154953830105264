import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import HorizontalDivider from "../components/HorizontalDivider";
import Platforms from "./Platforms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-common-types";
import { formatNumber } from "../util";
import { colors } from "../constants";

interface Props {
    platform: string;
    handle: string;
    onHandleChanged: (handle: string) => void;
    popularityCount?: string;
    onPopularityCountChanged: (popularityCount: string) => void;
}

const IconContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    margin-right: 15px;
    padding: 10px;
    border-radius: 100%;
    background-color: ${colors.primaryGreen};
    color: white;
    font-size: 26px;
`;

const BoldInput = styled.input`
    height: 35px;
    font-weight: bold;
    width: 100%;
    outline: none;
    border: none;
    margin: 0;
`;

const NumberInput = styled.input`
    height: 35px;
    width: 100%;
    outline: none;
    border: none;
`;

const FormContainer = styled.div`
    width: 100%;
    height: 110px;
    margin-top: 20px;
    padding: 15px;
    flex: 0 0 100%;
    border-radius: 10px;
    border: 2px solid ${colors.primaryGreen};
`;

const blankPlatform = {
    title: "",
    icon: ["fas", "plus-circle"] as [IconPrefix, IconName],
    countPlaceholder: "Number of Followers"
};

const AccountFormGroup = ({
    platform,
    handle,
    onHandleChanged,
    popularityCount,
    onPopularityCountChanged
}: Props) => {
    const currentPlatform =
        platform.length === 0 ? blankPlatform : Platforms[platform];

    return (
        <FormContainer>
            <Row noGutters>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <IconContainer>
                        <FontAwesomeIcon icon={currentPlatform.icon} />
                    </IconContainer>
                </div>
                <div style={{ width: "calc(100% - 65px)" }}>
                    <BoldInput
                        placeholder={"Your Handle"}
                        value={handle}
                        onChange={(event: any) =>
                            onHandleChanged(event.currentTarget.value)
                        }
                    />
                    <HorizontalDivider />
                    <NumberInput
                        placeholder={currentPlatform.countPlaceholder}
                        value={formatNumber(popularityCount || "")}
                        onChange={(event: any) => {
                            const newStr = event.currentTarget.value.replace(
                                /,/g,
                                ""
                            );

                            if (newStr === "" || /^\d+$/.test(newStr)) {
                                onPopularityCountChanged(newStr);
                            }
                        }}
                    />
                </div>
            </Row>
        </FormContainer>
    );
};

export default AccountFormGroup;
