import React, { useState } from "react";
import { Media, PlugLink } from "../../../media/types";
import Header from "../../../components/Header";
import PaddingNoTop from "../../../components/PaddingNoTop";
import SearchBar from "../../../components/SearchBar";
import CustomMediaGrid from "./CustomMediaGrid";

interface Props {
    customMedia: Media[];
    username: string | null;
    fetchLinkForPlugSuccess: (link: PlugLink) => void;
    customReasonModal?: (media: Media) => void;
}

const CustomMediaPage = ({
    customMedia,
    username,
    fetchLinkForPlugSuccess,
    customReasonModal
}: Props) => {
    const [searchString, setSearchString] = useState("");

    return (
        <>
            <Header
                title={"Custom Ads"}
                subtitle={`${customMedia.length} Approved Ads`}
            />
            <PaddingNoTop>
                <SearchBar
                    searchString={searchString}
                    updateSearchString={setSearchString}
                />
            </PaddingNoTop>
            <PaddingNoTop>
                <CustomMediaGrid
                    customMedia={customMedia}
                    username={username}
                    searchString={searchString}
                    fetchLinkForPlugSuccess={fetchLinkForPlugSuccess}
                    customReasonModal={customReasonModal}
                />
            </PaddingNoTop>
        </>
    );
};

export default CustomMediaPage;
