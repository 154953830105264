import { Action } from "src/types";

// Set Social Account Profile Loading -----------------------------------------------

export const SET_SOCIAL_ACCOUNT_PROFILE_LOADING =
    "SET_SOCIAL_ACCOUNT_PROFILE_LOADING";

export function setSocialAccountProfileLoading(loading: boolean): Action {
    return {
        type: SET_SOCIAL_ACCOUNT_PROFILE_LOADING,
        payload: { loading }
    };
}
