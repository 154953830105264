import {
    DirectBuy,
    DirectBuyStage,
    DirectBuyState,
    DirectBuyStatus
} from "./types";
import { DirectBuyStepCollection } from "../direct-buy-step/types";
import { DirectBuyPaymentCollection } from "../direct-buy-payment/types";
import { ApprovalStatus } from "../../../types/ApprovalStatus";
import { DirectBuyStepsEntitiesState } from "../direct-buy-step/reducer";
import { DirectBuyStepsExpandedState } from "../../../ui/direct-buys/reducer";
import {
    directBuyStepIsPending,
    directBuyStepIsRejected,
    directBuyStepMatchesSearchString,
    getDirectBuyPaymentCollectionForDirectBuyStep,
    getDirectBuyStepCollectionArrayForDirectBuyStep,
    getDirectBuyStepsForParentObject
} from "../direct-buy-step/util";
import { SocialAccount } from "../../../profile/types";
import { OpenBuy, OpenBuySubmission } from "../open-buys/types";
import moment, { Moment } from "moment";
import { getStateForOpenBuyAsDirectBuyStatus } from "../open-buys/util";
import { StepById } from "../open-buys/reducer";

export function getStatusForDirectBuy(directBuy: DirectBuy): DirectBuyStatus {
    if (directBuy.buyStatus === ApprovalStatus.approved) {
        return DirectBuyStatus.completed;
    }
    if (
        directBuyIsOfferExpired(directBuy) ||
        directBuyIsBuyExpired(directBuy)
    ) {
        return DirectBuyStatus.expired;
    }
    if (directBuy.offerStatus === ApprovalStatus.approved) {
        return DirectBuyStatus.active;
    }
    return DirectBuyStatus.available;
}

export function getStateForDirectBuy(
    directBuy: DirectBuy,
    directBuyStepsState: DirectBuyStepsEntitiesState
): [DirectBuyState, DirectBuyStage | null] {
    if (directBuy.buyStatus === ApprovalStatus.approved) {
        return [DirectBuyState.approved, null];
    }

    if (directBuyIsOfferExpired(directBuy)) {
        return [DirectBuyState.expired, DirectBuyStage.offer];
    }
    if (directBuyIsBuyExpired(directBuy)) {
        return [DirectBuyState.expired, DirectBuyStage.buy];
    }

    if (directBuyIsOfferRejected(directBuy)) {
        return [DirectBuyState.rejected, DirectBuyStage.offer];
    }
    if (directBuyIsBuyRejected(directBuy, directBuyStepsState)) {
        return [DirectBuyState.rejected, DirectBuyStage.buy];
    }

    if (directBuyIsPending(directBuy, directBuyStepsState)) {
        return [DirectBuyState.pending, null];
    }

    if (directBuy.offerStatus === ApprovalStatus.approved) {
        return [DirectBuyState.active, null];
    }

    if (directBuy.offerViewedAt) {
        return [DirectBuyState.viewed, null];
    }

    return [DirectBuyState.new, null];
}

function directBuyIsOfferExpired(directBuy: DirectBuy): boolean {
    if (!directBuy.offerExpiresAt) {
        return false;
    }
    return (
        new Date(directBuy.offerExpiresAt).getTime() < Date.now() &&
        directBuy.offerStatus !== ApprovalStatus.approved
    );
}

function directBuyIsBuyExpired(directBuy: DirectBuy): boolean {
    if (!directBuy.buyExpiresAt) {
        return false;
    }
    const pastExpiry = new Date(directBuy.buyExpiresAt).getTime() < Date.now();
    if (pastExpiry && !directBuy.buyCompletedAt) {
        return true;
    }
    if (pastExpiry && directBuy.buyStatus === ApprovalStatus.rejected) {
        return true;
    }
    return false;
}

function directBuyIsOfferRejected(directBuy: DirectBuy): boolean {
    return directBuy.offerStatus === ApprovalStatus.rejected;
}

function directBuyIsBuyRejected(
    directBuy: DirectBuy,
    directBuyStepsState: DirectBuyStepsEntitiesState
): boolean {
    if (directBuy.buyStatus === ApprovalStatus.rejected) {
        return true;
    }
    const steps = getDirectBuyStepsForParentObject(
        directBuy,
        directBuyStepsState
    );
    if (steps) {
        for (const step of steps) {
            if (directBuyStepIsRejected(step, directBuyStepsState)) {
                return true;
            }
        }
    }
    return false;
}

function directBuyIsPending(
    directBuy: DirectBuy,
    directBuyStepsState: DirectBuyStepsEntitiesState
): boolean {
    if (
        directBuy.buyStatus === ApprovalStatus.pending &&
        directBuy.buyCompletedAt
    ) {
        return true;
    }
    const steps = getDirectBuyStepsForParentObject(
        directBuy,
        directBuyStepsState
    );
    if (steps) {
        for (const step of steps) {
            if (directBuyStepIsPending(step, directBuyStepsState)) {
                return true;
            }
        }
    }
    return false;
}

export function getDirectBuyStepCollectionArrayForDirectBuy(
    directBuy: DirectBuy,
    directBuyStepsState: DirectBuyStepsEntitiesState,
    directBuyStepsExpandedState: DirectBuyStepsExpandedState
): DirectBuyStepCollection[] {
    let stepCollectionArray: DirectBuyStepCollection[] = [];
    const steps = getDirectBuyStepsForParentObject(
        directBuy,
        directBuyStepsState
    );
    if (steps) {
        for (const step of steps) {
            stepCollectionArray = stepCollectionArray.concat(
                getDirectBuyStepCollectionArrayForDirectBuyStep(
                    step,
                    directBuyStepsState,
                    directBuyStepsExpandedState
                )
            );
        }
    }
    return stepCollectionArray;
}

export function getDirectBuyPaymentCollectionArrayForDirectBuy(
    directBuy: DirectBuy,
    directBuyStepsState: DirectBuyStepsEntitiesState,
    includePublisherPayout: boolean = true
): DirectBuyPaymentCollection[] {
    const paymentsArray: DirectBuyPaymentCollection[] = [];

    if (includePublisherPayout && directBuy.publisherPayout) {
        paymentsArray.push({
            payment: null,
            transaction: directBuy.publisherPayout
        });
    }

    if (!directBuy.directBuySteps) {
        return paymentsArray;
    }

    let queue: number[] = [...directBuy.directBuySteps];
    while (queue.length > 0) {
        const currentStepId = queue.shift();
        if (!currentStepId) {
            break;
        }
        const currentStep = directBuyStepsState[currentStepId];
        if (currentStep.directBuyPayments) {
            paymentsArray.push(
                getDirectBuyPaymentCollectionForDirectBuyStep(currentStep)
            );
        }
        if (currentStep.directBuySteps) {
            queue = queue.concat(currentStep.directBuySteps);
        }
    }

    return paymentsArray;
}

export function getPhotoUrlForDirectBuy(
    directBuy: DirectBuy,
    directBuyStepsState: DirectBuyStepsEntitiesState
): string | null {
    if (!directBuy.directBuySteps) {
        return null;
    }

    let assetUrl: string | null = null;
    let customMediaUrl: string | null = null;

    let queue: number[] = [...directBuy.directBuySteps];
    while (queue.length > 0) {
        const currentStepId = queue.shift();
        if (!currentStepId) {
            break;
        }
        const currentStep = directBuyStepsState[currentStepId];
        if (currentStep.media?.coverPhotoUrl) {
            return currentStep.media.coverPhotoUrl;
        } else {
            assetUrl = assetUrl || currentStep.asset?.coverPhotoUrl || null;
            customMediaUrl =
                customMediaUrl ||
                currentStep.customMedia?.coverPhotoUrl ||
                null;
        }
        if (currentStep.directBuySteps) {
            queue = queue.concat(currentStep.directBuySteps);
        }
    }

    return assetUrl || customMediaUrl;
}

export function getSocialAccountArrayForDirectBuy(
    directBuy: DirectBuy,
    directBuyStepsState: DirectBuyStepsEntitiesState
): SocialAccount[] {
    if (!directBuy.directBuySteps) {
        return [];
    }

    const socialAccountArray: SocialAccount[] = [];
    const seen: Set<number> = new Set();

    let queue: number[] = [...directBuy.directBuySteps];
    while (queue.length > 0) {
        const currentStepId = queue.shift();
        if (!currentStepId) {
            break;
        }
        const currentStep = directBuyStepsState[currentStepId];
        const socialAccount = currentStep.socialAccount;
        if (socialAccount && !seen.has(socialAccount.id)) {
            seen.add(socialAccount.id);
            socialAccountArray.push(socialAccount);
        }
        if (currentStep.directBuySteps) {
            queue = queue.concat(currentStep.directBuySteps);
        }
    }

    return socialAccountArray;
}

export function directBuyMatchesSearchString(
    directBuy: DirectBuy,
    directBuyStepsState: DirectBuyStepsEntitiesState,
    string: string
): boolean {
    const searchString = string.toLowerCase();

    if (directBuy.buyer.name?.toLowerCase().includes(searchString)) {
        return true;
    }

    if (!directBuy.directBuySteps) {
        return false;
    }

    let queue: number[] = [...directBuy.directBuySteps];
    while (queue.length > 0) {
        const currentStepId = queue.shift();
        if (!currentStepId) {
            break;
        }
        const currentStep = directBuyStepsState[currentStepId];
        //@ts-ignore
        if (directBuyStepMatchesSearchString(currentStep, string)) {
            return true;
        }
        if (currentStep.directBuySteps) {
            queue = queue.concat(currentStep.directBuySteps);
        }
    }

    return false;
}

export function openBuyMatchesSearchString(
    openBuy: OpenBuy,
    instructionSteps: StepById,
    string: string
): boolean {
    const searchString = string.toLowerCase();

    if (openBuy.buyer?.name?.toLowerCase().includes(searchString)) {
        return true;
    }

    if (openBuy.name.toLowerCase().includes(searchString)) {
        return true;
    }

    if (!openBuy.instructionSteps) {
        return false;
    }

    if (
        openBuy.openBuyTags.filter(tag =>
            tag.name.toLowerCase().includes(searchString)
        ).length > 0
    )
        return true;

    let queue: number[] = [...openBuy.instructionSteps];
    while (queue.length > 0) {
        const currentStepId = queue.shift();
        if (!currentStepId) {
            break;
        }
        const currentStep = instructionSteps[currentStepId];
        if (directBuyStepMatchesSearchString(currentStep, string)) {
            return true;
        }
        if (currentStep.instructionSteps) {
            queue = queue.concat(currentStep.instructionSteps);
        }
    }

    return false;
}

export function getDirectBuysBucket(
    searchIds: number[] | null,
    directBuysAllIds: number[],
    directBuysById: { [id: number]: DirectBuy },
    directBuyStepsState: DirectBuyStepsEntitiesState,
    statusFilters: DirectBuyStatus[],
    searchString: string
): { [id: string]: number[] } {
    let newArray = searchIds
        ? [...directBuysAllIds].filter(id => searchIds?.includes(id))
        : [...directBuysAllIds];
    newArray = newArray
        .filter(directBuyId => {
            if (statusFilters.length === 0) {
                return true;
            }
            const status = getStatusForDirectBuy(directBuysById[directBuyId]);
            return statusFilters.includes(status);
        })
        .filter(directBuyId => {
            if (searchString.length === 0) {
                return true;
            }
            return directBuyMatchesSearchString(
                directBuysById[directBuyId],
                directBuyStepsState,
                searchString
            );
        });
    let bucket: { [id: string]: number[] } = {};
    newArray.map(buy => {
        const date = moment(directBuysById[buy].offerStartsAt).format("ll");
        if (bucket[date]) {
            bucket[date].push(buy);
        } else bucket[date] = [buy];
    });
    return bucket;
}

export function getOpenBuysBucket(
    searchIds: number[] | null,
    openBuysAllIds: number[],
    openBuysById: { [id: number]: OpenBuy },
    openBuyStepsState: StepById,
    statusFilters: DirectBuyStatus[],
    searchString: string,
    submissions: OpenBuySubmission[]
): { [id: string]: number[] } {
    let newArray = searchIds
        ? [...openBuysAllIds].filter(id => searchIds?.includes(id))
        : [...openBuysAllIds];
    newArray = newArray
        .filter(openBuyId => {
            if (statusFilters.length === 0) {
                return true;
            }
            const status = getStateForOpenBuyAsDirectBuyStatus(
                openBuysById[openBuyId],
                submissions
            );
            return statusFilters.includes(status);
        })
        .filter(openBuyId => {
            if (searchString.length === 0) {
                return true;
            }
            return openBuyMatchesSearchString(
                openBuysById[openBuyId],
                openBuyStepsState,
                searchString
            );
        });

    let bucket: { [id: string]: number[] } = {};
    newArray.map(buy => {
        let date: Moment | string = moment(openBuysById[buy].endsAt || "");

        if (date.diff(moment()) > 1 || !date.isValid()) date = "Current";
        else date = date.format("ll");
        if (bucket[date]) {
            bucket[date].push(buy);
        } else bucket[date] = [buy];
    });
    return bucket;
}
