import React from "react";
import Grid from "../../components/Grid";
import MediaCard from "../../../media/view/MediaCard";
import { getApi } from "../../../Api";
import { Media, PlugLink } from "../../../media/types";
import CustomMediaOverlay from "./CustomMediaOverlay";
import CustomMediaBadges from "../../../media/view/CustomMediaBadges";

interface Props {
    customMedia: Media[];
    username: string | null;
    searchString: string;
    fetchLinkForPlugSuccess: (link: PlugLink) => void;
    customReasonModal?: (media: Media) => void;
}

interface State {
    maxMedia: number;
}

class CustomMediaGrid extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            maxMedia: 24
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount(): void {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll(): void {
        const innerHeight = window.innerHeight;
        const scrollTopBody = document.getElementsByTagName("body")[0]
            .scrollTop;
        const scrollTopDoc = document.documentElement.scrollTop;
        const scrollTop = Math.max(scrollTopBody, scrollTopDoc) + 50;
        const offsetHeight = document.documentElement.offsetHeight;
        if (
            innerHeight + scrollTop >= offsetHeight &&
            document.body.style.position !== "fixed"
        ) {
            this.setState({ maxMedia: this.state.maxMedia + 6 });
        }
    }

    render() {
        const endList = Math.min(
            this.state.maxMedia,
            this.props.customMedia.length
        );
        const customReasonModal = this.props.customReasonModal;

        return (
            <Grid>
                {this.props.customMedia
                    .slice(0, endList)
                    .filter(media => {
                        if (this.props.searchString.length === 0) {
                            return true;
                        } else {
                            return (
                                media.id
                                    .toString()
                                    .includes(
                                        this.props.searchString.toLowerCase()
                                    ) ||
                                (media.name &&
                                    media.name
                                        .toLowerCase()
                                        .includes(
                                            this.props.searchString.toLowerCase()
                                        ))
                            );
                        }
                    })
                    .map(media => {
                        return (
                            <MediaCard
                                key={media.id}
                                playIcon
                                allPortrait
                                media={media}
                                linkPrefix={""}
                                buttonCallbacks={{
                                    linkCallback: () => {
                                        getApi(
                                            "/get_link_for_plug_with_media2",
                                            {
                                                mediaid: media.id,
                                                external_page_id:
                                                    media.externalPageId,
                                                plugid: media.campaignId,
                                                is_portrait: media.isPortrait
                                            }
                                        ).then(data => {
                                            this.props.fetchLinkForPlugSuccess(
                                                data.data
                                            );
                                        });
                                    }
                                }}
                            >
                                {customReasonModal ? (
                                    <CustomMediaBadges
                                        media={media}
                                        customReasonCallback={() => {
                                            customReasonModal(media);
                                        }}
                                    />
                                ) : (
                                    <CustomMediaOverlay
                                        customMedia={media}
                                        username={this.props.username}
                                    />
                                )}
                            </MediaCard>
                        );
                    })}
            </Grid>
        );
    }
}

export default CustomMediaGrid;
