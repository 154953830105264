import React, { useEffect } from "react";
import { Campaign, Script } from "src/campaigns/types";
import styled from "styled-components";
import MediaSearch from "src/media/search";
import { useDispatch, useSelector } from "react-redux";
import { setMediaFilters } from "src/pagination/media/actions";
import { initialMediaFilters } from "src/pagination/media/reducer";
import { setCurrentCampaignId } from "src/ui/campaigns/actions";
import ModalHeader from "./ModalHeader";

import { setWarning } from "src/ui/scripts/actions";
import { GlobalState } from "src/reducers";
import { scriptMediaLink } from "../actions";
import { breakpoints } from "src/constants";

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 100%;
    overflow-y: auto;
    isolation: isolate;
    height: 80vh;
    position: relative;
    @media (max-width: ${breakpoints.tablet}px) {
        height: 100%;
    }
`;
const StickyPositionFixer = styled.div`
    height: 100%;
    width: 100%;
`;

interface Props {
    campaign: Campaign;
    script: Script;
}

const SelectMedia = ({ campaign, script }: Props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            setMediaFilters({
                ...initialMediaFilters,
                selectedCampaigns: {
                    [campaign.id]: campaign.campaignName
                },
                status: {
                    pending: false,
                    approved: true,
                    rejected: false
                }
            })
        );
        dispatch(setCurrentCampaignId(campaign.id));
    }, []);
    const isWarning = useSelector(
        (state: GlobalState) => state.ui.script.warning
    );
    const selectedMedias = useSelector(
        (state: GlobalState) => state.media.selectedMedias
    );
    useEffect(() => {
        if (selectedMedias.length > 0 && !isWarning) {
            dispatch(setWarning(true));
        } else if (selectedMedias.length === 0 && isWarning) {
            dispatch(setWarning(false));
        }
    }, [selectedMedias]);

    const handleSubmit = () => {
        dispatch(scriptMediaLink(script, selectedMedias));
        dispatch(setWarning(false));
    };

    return (
        <Wrapper>
            <ModalHeader
                title="Select Example Media from the Plug"
                campaign={campaign}
                scriptTitle={script.title}
            />
            <StickyPositionFixer>
                <MediaSearch
                    isSearchable
                    isMultiSelect
                    handleSubmit={handleSubmit}
                />
            </StickyPositionFixer>
        </Wrapper>
    );
};

export default SelectMedia;
