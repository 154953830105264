import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import OpenBuyDetails from "src/buys/details/OpenBuyDetails";
import { fetchCampaigns } from "src/campaigns/actions";
import Navbar from "src/components/Navbar";
import ChallengeMainHeader from "src/marketplace/landing/components/ChallengeMainHeader";
import ChallengesLanding from "src/marketplace/landing/pages/ChallengesLanding";
import ChallengeReport from "src/marketplace/reports/components/ChallengeReport";
import { GlobalState } from "src/reducers";
import { setInAdminTools } from "src/ui/admin-tools/actions";
import ChallengeCreator from "./challenges/ChallengeCreator";
import ChallengeExtensions from "./challenges/challenge-extensions/ChallengeExtensions";
import ChallengeInstructions from "./challenges/ChallengeInstructions";
import ChallengePayments from "./challenges/ChallengePayments";
import SubmissionManager from "./challenges/SubmissionManager";
import ChallengeCreationForm from "./components/ChallengeCreationForm";
import ChallengeWizard from "./components/ChallengeWizard";
import CreatorSearch from "./creator-search/CreatorSearch";
import SavedGroups from "./creator-search/SavedGroups";
import RouteModal from "src/components/RouteModal";
import SocialAccountProfile from "src/social-accounts/profile/SocialAccountProfile";
import { AnimatePresence } from "framer-motion";
import LeaderboardEditor from "src/marketplace/reports/components/approvalModal/LeaderboardEditor";
import PlugMediaStandards from "./rules/PlugMediaStandards";

interface Props {}

const AdminTools = ({}: Props) => {
    const { sortBy, filters } = useSelector((state: GlobalState) => {
        return state.ui.campaign;
    }, shallowEqual);
    const { routeModalOpen } = useSelector((state: GlobalState) => {
        return state.ui.modals;
    });
    const { currentOtherId, currentSocialAccountId } = useSelector(
        (state: GlobalState) => {
            return state.ui.creators;
        },
        shallowEqual
    );

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCampaigns(sortBy, filters));

        dispatch(setInAdminTools(true));
        return function cleanUp(): void {
            dispatch(setInAdminTools(false));
        };
    }, []);

    return (
        <>
            <Navbar marginBottom={24} mobileHasMarginBottom />
            <ChallengeMainHeader />
            <Switch>
                {/* Challenge Tools ------------------------------------------------ */}

                <Route
                    exact
                    path={"/admin-tools/challenges/submissions"}
                    component={SubmissionManager}
                />
                <Route
                    exact
                    path={"/admin-tools/challenges/create"}
                    component={ChallengeCreator}
                />
                <Route
                    exact
                    path={"/admin-tools/challenges/payments"}
                    component={ChallengePayments}
                />
                <Route
                    exact
                    path={"/admin-tools/challenges/instructions"}
                    component={ChallengeInstructions}
                />

                {/* Your Challenges ------------------------------------------------ */}
                <Route
                    exact
                    path="/admin-tools/your-challenges"
                    component={ChallengesLanding}
                />

                <Redirect
                    exact
                    path="/admin-tools/your-challenges/:openBuyId"
                    to="/admin-tools/your-challenges/:openBuyId/edit-challenge"
                />

                <Route
                    exact
                    path="/admin-tools/your-challenges/:openBuyId/approvals"
                    render={({ match }) => {
                        const openBuyId = parseInt(match.params.openBuyId);
                        return (
                            <SubmissionManager staticOpenBuyId={openBuyId} />
                        );
                    }}
                />

                <Route
                    exact
                    path="/admin-tools/your-challenges/:openBuyId/challenge-report"
                    component={ChallengeReport}
                />

                <Route
                    exact
                    path="/admin-tools/your-challenges/:openBuyId/view-challenge"
                    render={({ match }) => {
                        const openBuyId = parseInt(match.params.openBuyId);
                        return <OpenBuyDetails openBuyId={openBuyId} />;
                    }}
                />
                <Route
                    exact
                    path="/admin-tools/your-challenges/:openBuyId/extensions"
                    render={({ match }) => {
                        const openBuyId = parseInt(match.params.openBuyId);
                        return <ChallengeExtensions openBuyId={openBuyId} />;
                    }}
                />

                <Route
                    exact
                    path="/admin-tools/your-challenges/:openBuyId/edit-challenge"
                    render={({ match }) => {
                        const openBuyId = parseInt(match.params.openBuyId);
                        return <ChallengeWizard openBuyId={openBuyId} />;
                    }}
                />

                {/* Creator Search ------------------------------------------------ */}
                <Route
                    exact
                    path="/admin-tools/creator-search"
                    component={CreatorSearch}
                />

                <Route
                    exact
                    path="/admin-tools/creator-search/saved-groups"
                    component={SavedGroups}
                />

                {/* Plug Media Standards ------------------------------------------------ */}

                <Route
                    exact
                    path="/admin-tools/plug-standards"
                    component={PlugMediaStandards}
                />

                <Redirect to="/admin-tools/your-challenges/" />
            </Switch>

            <ChallengeCreationForm />

            {currentOtherId && currentSocialAccountId && (
                <AnimatePresence>
                    {routeModalOpen && (
                        <RouteModal>
                            <SocialAccountProfile
                                otherId={String(currentOtherId)}
                                socialId={String(currentSocialAccountId)}
                                inRouteModal
                            />
                        </RouteModal>
                    )}
                </AnimatePresence>
            )}
            <LeaderboardEditor />
        </>
    );
};
export default AdminTools;
