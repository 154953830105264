import React, { useState } from "react";
import SelectionHeader from "src/marketplace/components/SelectionHeader";
import Platforms from "src/social-accounts/Platforms";
import { capitalizeFirstCharacter } from "src/utils/functions/helpers";
import { SelectedPlatform } from "../utils/types";
import ChallengeFormSubSection from "./ChallengeFormSubSection";
import ChallengeLogisticsDropdown from "./ChallengeLogisticsDropdown";
import SelectionButton from "src/components/SelectionButton";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentOpenBuy } from "src/admin-tools/selectors.admin-tools";
import { setDetailsUnsaved } from "src/ui/admin-tools/actions";
import { GlobalState } from "src/reducers";

const platforms = Object.keys(Platforms).slice(0, -1);

interface Props {
    selectedPlatforms: SelectedPlatform;
    setSelectedPlatforms: (platform: SelectedPlatform) => void;
}

const ChallengeLogisticsSelectPlatform = ({
    selectedPlatforms,
    setSelectedPlatforms
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const currentOpenBuy = useSelector(getCurrentOpenBuy);
    const { challengeDetailsIncomplete } = useSelector((state: GlobalState) => {
        return state.ui.adminTools;
    });

    // Local State ---------------------------------------------------
    const [
        selectPlatformDropdownExpanded,
        setSelectPlatformDropdownExpanded
    ] = useState(false);

    // Local Functions -----------------------------------------------
    function handleTitleText(): string {
        const platforms = Object.keys(selectedPlatforms);
        if (platforms.length === 0) {
            return "Select Platforms";
        } else {
            return `${platforms.length} Selected`;
        }
    }

    function toggleSelectPlatformDropdown(): void {
        setSelectPlatformDropdownExpanded(!selectPlatformDropdownExpanded);
    }

    function handleSetSelectedPlatforms(
        capitalizedPlatform: string,
        platform: string
    ): void {
        const newSelectedPlatforms = {
            ...selectedPlatforms
        };
        if (newSelectedPlatforms[capitalizedPlatform]) {
            delete newSelectedPlatforms[capitalizedPlatform];
        } else {
            newSelectedPlatforms[capitalizedPlatform] = platform;
        }
        setSelectedPlatforms(newSelectedPlatforms);

        const platformValues = Object.values(newSelectedPlatforms);
        if (currentOpenBuy) {
            if (currentOpenBuy.allowedPlatforms) {
                dispatch(
                    setDetailsUnsaved(
                        platformValues !== currentOpenBuy.allowedPlatforms
                    )
                );
            }
        }
    }

    // JSX -----------------------------------------------------------
    return (
        <ChallengeFormSubSection
            title="*SELECT PLATFORM"
            error={{
                isError:
                    challengeDetailsIncomplete &&
                    Object.keys(selectedPlatforms).length === 0,
                message: "You must set at least one platform."
            }}
            children={
                <ChallengeLogisticsDropdown
                    expanded={selectPlatformDropdownExpanded}
                >
                    <SelectionHeader
                        onClick={toggleSelectPlatformDropdown}
                        // title={handleTitleText()}
                        // selected={selectPlatformDropdownExpanded}
                        isDropdown
                        title="TikTok"
                        selected={true}
                        disabled={true}
                    />
                    {selectPlatformDropdownExpanded &&
                        platforms.map((platform: string) => {
                            const capitalizedPlatform = capitalizeFirstCharacter(
                                platform
                            );

                            return (
                                <SelectionButton
                                    key={`marketplace input challenge logistics platform selection row ${platform}`}
                                    text={capitalizedPlatform}
                                    selected={
                                        !!selectedPlatforms[capitalizedPlatform]
                                    }
                                    setSelected={() =>
                                        handleSetSelectedPlatforms(
                                            capitalizedPlatform,
                                            platform
                                        )
                                    }
                                />
                            );
                        })}
                </ChallengeLogisticsDropdown>
            }
        />
    );
};

export default ChallengeLogisticsSelectPlatform;
