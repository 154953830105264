import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { get, patch } from "src/Api";
import { OpenBuySubmissionSet } from "src/buys/modules/open-buys/types";
import ModalHeading from "src/components/modals/components/ModalHeading";
import ScrollableWithFadedArrows from "src/components/ScrollableWithFadedArrows";
import Tooltip from "src/components/Tooltip";
import withModalPortal from "src/components/withModalPortal";
import { colors } from "src/constants";
import MarketplaceInput from "src/marketplace/components/MarketplaceInput";
import SmallModal from "src/modals/SmallModal";
import { BlueButton } from "src/profile/components/Buttons";
import { SocialAccount } from "src/profile/types";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";

const StyledSmallModal = styled(SmallModal)`
    isolation: isolate;
    & > #small-modal-child {
        overflow: visible;
    }
`;

const DropdownWrap = styled.div`
    position: relative;
    z-index: 1;
    padding-top: 12px;
`;

const StyledTooltip = styled(Tooltip)`
    border-radius: 8px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    max-height: 300px;
`;

const SelectedAccountInput = styled(MarketplaceInput)<{
    isPlaceholder: boolean;
    isActive: boolean;
}>`
    color: ${props =>
        props.isPlaceholder ? colors.primaryGray : colors.black};
    text-align: left;
    border-color: ${props =>
        props.isActive ? colors.primaryBlue : colors.mediumTeal};
    cursor: pointer;
`;

const DropdownOption = styled.div`
    padding: 16px;
    text-align: left;
    transition: background-color 0.2s ease;
    font-size: 12px;
    cursor: pointer;
    &:hover {
        background-color: ${colors.quaternaryLightGray};
    }
`;

const SubmitBtn = styled(BlueButton)`
    margin-left: auto;
    padding: 8px 12px;
    margin-top: 12px;
`;

interface Props {
    submissionSet: OpenBuySubmissionSet;
    closeModal: VoidFunction;
    getSubmissionSet: VoidFunction;
}

const UpdateSocialModal = ({
    submissionSet,
    closeModal,
    getSubmissionSet
}: Props) => {
    const dispatch = useDispatch();
    const [selectedAccount, setSelectedAccount] = useState<{
        username: string;
        id: number;
    } | null>(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const tooltipRef = useRef(null);

    const [accounts, setAccounts] = useState<SocialAccount[]>([]);

    const getPublisherSocials = async () => {
        try {
            const res = await get(
                `/v1/admin/publisher/${submissionSet.publisherId}/socialAccounts`,
                {}
            );
            setAccounts(res.data.data);
        } catch (err) {}
    };

    useEffect(() => {
        getPublisherSocials();
    }, [submissionSet]);

    const handleSubmit = async () => {
        try {
            if (selectedAccount) {
                await patch(
                    `/v1/openBuySubmission/${submissionSet.id}/updateSocialAccount`,
                    { socialAccountId: selectedAccount.id }
                );
                getSubmissionSet();
                dispatch(
                    setPillNotificationText(
                        "Social account updated",
                        "success",
                        3500
                    )
                );
                closeModal();
            }
        } catch {
            dispatch(
                setPillNotificationText(
                    "Social account failed to update",
                    "danger",
                    3500
                )
            );
        }
    };

    return (
        <StyledSmallModal
            header={{ text: "Update Social Account", handleClose: closeModal }}
        >
            <ModalHeading text="SOCIAL ACCOUNT" />
            <DropdownWrap>
                <SelectedAccountInput
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    as="div"
                    isPlaceholder={!selectedAccount}
                    isActive={dropdownOpen}
                    ref={tooltipRef}
                >
                    {selectedAccount
                        ? `${selectedAccount.id} - ${selectedAccount.username}`
                        : "Select an account"}
                </SelectedAccountInput>
                <StyledTooltip
                    controlRef={tooltipRef}
                    close={() => setDropdownOpen(false)}
                    isOpen={dropdownOpen}
                    position={"left: 0px; top: 68px;"}
                >
                    {accounts.map(account => (
                        <DropdownOption
                            key={account.id}
                            onClick={() => {
                                setDropdownOpen(false);
                                setSelectedAccount(account);
                            }}
                        >{`${account.username} - ${account.id}`}</DropdownOption>
                    ))}
                </StyledTooltip>
            </DropdownWrap>
            <SubmitBtn onClick={handleSubmit} disabled={!selectedAccount}>
                Submit
            </SubmitBtn>
        </StyledSmallModal>
    );
};

export default withModalPortal(UpdateSocialModal);
