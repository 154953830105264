import React from "react";
import styled from "styled-components";
import { Shoutout } from "../types";
import ShoutoutBubble from "./ShoutoutBubble";
import moment from "moment";
import { colors, fonts } from "../../../constants";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Time = styled.div`
    font-size: ${fonts.smallTitle}px;
    line-height: 26px;
    max-width: 96px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const HorizontalScrollContainer = styled.div`
    display: flex;
    padding-left: 15px;
    align-self: flex-start;
    width: 100%;
`;

const HorizontalScrollView = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

const Item = styled.div`
    width: 66px;
    overflow: hidden;
    margin-right: 15px;
    cursor: pointer;
`;

const Name = styled.div`
    font-size: ${fonts.smallSubtitle}px;
    line-height: 26px;
    color: ${colors.primaryGray};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Divider = styled.div`
    height: 66px;
    width: 1px;
    background-color: ${colors.mediumTeal};
`;

interface Props {
    shoutouts: Shoutout[];
    setCurrentShoutout: (currentShoutout: Shoutout) => void;
    push: (path: string) => void;
    column: boolean;
}

const ShoutoutsGroup = ({
    shoutouts,
    setCurrentShoutout,
    push,
    column
}: Props) => {
    function dateString() {
        const startDate = moment(shoutouts[0].start_time).startOf("day");
        const todayDate = moment().startOf("day");
        const diff = todayDate.diff(startDate, "day");

        if (diff === 0) {
            return "Today";
        } else if (diff === 1) {
            return "Yesterday";
        } else {
            return `${startDate.format("ddd")} • ${startDate.format("M/D")}`;
        }
    }

    return (
        <Main>
            <Time>{dateString()}</Time>
            <HorizontalScrollContainer
                style={
                    column
                        ? { maxWidth: "100%", overflowX: "scroll" }
                        : undefined
                }
            >
                <HorizontalScrollView>
                    {shoutouts.map((shoutout, idx) => {
                        return (
                            <Item
                                key={shoutout.id}
                                onClick={() => {
                                    setCurrentShoutout(shoutout);
                                    push("/campaign/shoutouts/details");
                                    // push("/campaigns/shoutouts/details");
                                }}
                            >
                                <ShoutoutBubble shoutout={shoutout} />
                                <Name>
                                    {shoutout.Media[0].Campaign.campaignName}
                                </Name>
                            </Item>
                        );
                    })}
                    {!column && <Divider />}
                </HorizontalScrollView>
            </HorizontalScrollContainer>
        </Main>
    );
};

export default ShoutoutsGroup;
