import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";
import { getStateForOpenBuy } from "../modules/open-buys/util";
import {
    OpenBuy,
    OpenBuyState,
    OpenBuySubmission
} from "../modules/open-buys/types";
import { DirectBuyStatus } from "../modules/direct-buy/types";
import { openBuyMatchesSearchString } from "../modules/direct-buy/util";
import { StepById } from "../modules/open-buys/reducer";

const openBuysEntitiesState = (state: GlobalState) => state.entities.openBuys;
const directBuyStatusFiltersState = (state: GlobalState) =>
    state.ui.directBuys.statusFilters;
const openBuyStepEntitiesState = (state: GlobalState) =>
    state.entities.instructionSteps;
const openBuySubmissionsEntitiesState = (state: GlobalState) =>
    state.entities.openBuySubmissions;

const openBuyStateHelper = (
    openBuy: OpenBuy,
    stepsById: StepById,
    submissionsById: { [id: number]: OpenBuySubmission }
) => {
    const publisherId = parseInt(localStorage.getItem("userid") || "0");
    const ownSubmissions =
        openBuy.openBuySubmissions
            ?.map(id => submissionsById[id])
            .filter(submission => submission.publisherId === publisherId) || [];

    const instructionSteps =
        openBuy.instructionSteps?.map(id => stepsById[id]) || [];

    return getStateForOpenBuy(openBuy, ownSubmissions, instructionSteps);
};

export const getOpenBuysWithSearchStringSortedFiltered = createSelector(
    openBuysEntitiesState,
    directBuyStatusFiltersState,
    openBuyStepEntitiesState,
    openBuySubmissionsEntitiesState,
    (state: GlobalState, searchString: string) => searchString.toLowerCase(),
    (
        openBuysState,
        statusFilters,
        stepsById,
        submissionsById,
        searchString
    ) => {
        const { byId, allIds } = openBuysState;
        const rawOpenBuys = allIds.map(id => byId[id]);

        const statusFilteredOpenBuys = rawOpenBuys.filter(openBuy => {
            if (statusFilters.length === 0) {
                return true;
            }
            const state = openBuyStateHelper(
                openBuy,
                stepsById,
                submissionsById
            );

            switch (state) {
                case OpenBuyState.ended:
                    return statusFilters.includes(DirectBuyStatus.expired);
                case OpenBuyState.new:
                case OpenBuyState.viewed:
                    return statusFilters.includes(DirectBuyStatus.available);
                default:
                    return statusFilters.includes(DirectBuyStatus.active);
            }
        });

        const searchStringFilteredOpenBuys = statusFilteredOpenBuys.filter(
            openBuy =>
                openBuyMatchesSearchString(openBuy, stepsById, searchString)
        );

        const newBuys: OpenBuy[] = [];
        const pending: OpenBuy[] = [];
        const error: OpenBuy[] = [];
        const ended: OpenBuy[] = [];
        const viewed: OpenBuy[] = [];
        const revision: OpenBuy[] = [];
        const approved_next: OpenBuy[] = [];
        const approved: OpenBuy[] = [];

        searchStringFilteredOpenBuys.forEach(openBuy => {
            const state = openBuyStateHelper(
                openBuy,
                stepsById,
                submissionsById
            );
            switch (state) {
                case OpenBuyState.approved_next_step:
                    approved_next.push(openBuy);
                    break;
                case OpenBuyState.revision_requested:
                    revision.push(openBuy);
                    break;
                case OpenBuyState.new:
                    newBuys.push(openBuy);
                    break;
                case OpenBuyState.viewed:
                    viewed.push(openBuy);
                    break;
                case OpenBuyState.pending:
                    pending.push(openBuy);
                    break;
                case OpenBuyState.approved:
                    approved.push(openBuy);
                    break;
                case OpenBuyState.rejected:
                    error.push(openBuy);
                    break;
                case OpenBuyState.ended:
                    ended.push(openBuy);
                    break;
                default:
                    return;
            }
        });

        return approved_next.concat(
            revision.concat(
                newBuys.concat(
                    viewed.concat(
                        pending.concat(approved.concat(error.concat(ended)))
                    )
                )
            )
        );
    }
);
