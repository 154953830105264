import { Action } from "src/types";

// Create Offer Flow ---------------------------------------------------------------

export const CREATE_OFFER = {
    REQUEST: "CREATE_OFFER_REQUEST",
    SUCCESS: "CREATE_OFFER_SUCCESS",
    FAILURE: "CREATE_OFFER_FAILURE"
};

export interface Offer {
    socialAccountId: number;
    paymentSetBucketId?: number; // If providing paymentAmount & paymentType, don't include
    paymentAmount?: number; // Include with paymentType if paymentSetBucketId is not provided
    paymentType?: "flat_rate" | "dollars_per_k" | string; // Include with paymentAmount if paymentSetBucketId is not provided
    date?: string; // Optional, default to now
    offerDuration?: number; // Optional, defaults to 2
    buyDuration?: number; // Optional, defaults to 7
    prepaymentAmount?: number;
    placementId?: number;
    numViews?: number;
    postDuration?: number;
}

export function createOfferFlow(
    instructionSetId: number,
    creatorGroupId: number,
    offers: Offer[]
): Action {
    return {
        type: CREATE_OFFER.REQUEST,
        payload: { instructionSetId, creatorGroupId, offers }
    };
}

export function createOfferFlowSuccess(offers: any): Action {
    return {
        type: CREATE_OFFER.SUCCESS,
        payload: { offers }
    };
}

export function createOfferFlowFailure(error: string): Action {
    return {
        type: CREATE_OFFER.FAILURE,
        payload: { error }
    };
}
