import React from "react";
import { useSelector } from "react-redux";
import withAccordion from "src/campaigns/details/accordions/withAccordion";
import { GlobalState } from "src/reducers";
import Platforms from "src/social-accounts/Platforms";
import styled from "styled-components";
import OpenBuyBudget from "../details/payments/OpenBuyBudget";
import OpenBuyPayments from "../details/payments/OpenBuyPayments";
import { getSubmissionProgress } from "../details/submissions/util";
import { getOpenBuyPayments } from "../modules/open-buys/util";
import {
    selectOpenBuysById,
    selectOpenBuyStepEntities,
    selectOpenBuySubmissions
} from "../selectors";

const Main = styled.div`
    display: flex;
    flex-direction: column;

    & > * ~ * {
        margin-top: 20px;
    }
`;

interface Props {
    openBuyId: number;
}

const OpenBuyPaymentDetailsAccordion = ({ openBuyId }: Props) => {
    const openBuy = useSelector(selectOpenBuysById)[openBuyId];
    const submissions = useSelector(selectOpenBuySubmissions);
    const yourSubmissions = openBuy?.openBuySubmissions || [];
    const steps = useSelector(selectOpenBuyStepEntities);

    const getFurthestProgress = () => {
        let max = 0;
        submissions.forEach(submission => {
            if (yourSubmissions.includes(submission.id)) {
                let progress = getSubmissionProgress(submission, steps);
                if (progress > max) max = progress;
            }
        });
        return max;
    };

    const generatePaymentCopy = (paymentStep?: number) => {
        if (!paymentStep) return "";
        let step = steps[paymentStep];
        let copy = "";
        if (step.allowedPlatforms)
            step.allowedPlatforms.map((platform, index) => {
                copy += Platforms[platform].title;
                //@ts-ignore
                if (index + 1 < step!.allowedPlatforms.length) {
                    //@ts-ignore
                    if (index + 2 >= step.allowedPlatforms.length)
                        copy += " and ";
                    else copy += ", ";
                } else copy += " Only. ";
            });

        copy += `Limited to ${openBuy.submissionLimitPerPublisher} submission${
            (openBuy.submissionLimitPerPublisher || 0) > 1 ? "s" : ""
        }${
            openBuy.submissionLimitPerSocialAccount
                ? ` overall, ${openBuy.submissionLimitPerSocialAccount} per account.`
                : "."
        }`;
        return copy;
    };

    return (
        <Main>
            <OpenBuyPayments
                collections={getOpenBuyPayments(openBuy, steps)}
                getCopy={generatePaymentCopy}
                progress={getFurthestProgress()}
            />
            <OpenBuyBudget
                budgetAmount={openBuy.budgetAmount}
                budgetSpend={openBuy.budgetSpend}
            />
        </Main>
    );
};
export default withAccordion(OpenBuyPaymentDetailsAccordion);
