import React, { ReactElement, useState } from "react";
import { colors } from "src/constants";
import styled from "styled-components";
import DropdownCaret from "../icons/DropdownCaret";

const Wrapper = styled.div`
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 12px;
`;
const HeadingWrap = styled.div<{ noPadding: boolean }>`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding-bottom: ${props => (props.noPadding ? "0px" : "10px")};
`;

const TextBody = styled.div`
    text-align: left;
`;
const InnerWrap = styled.div<{
    open: boolean;
    innerHeight: number;
}>`
    transition: height 0.4s ease;
    height: ${props => (props.open ? `${props.innerHeight}px` : "0px")};
    overflow: hidden;
    margin: 0px;
`;

const CaretLabel = styled.div`
    font-size: 12px;
    font-weight: 500;
    margin-left: auto;
    display: flex;
    align-items: center;
`;

interface Props {
    header: ReactElement;
    body?: string;
    defaultOpen?: boolean;
    className?: string;
    caretLabel?: string;
    onDismiss?: () => void;
    children?: ReactElement;
}
const CollapsableSection = ({
    header,
    body,
    defaultOpen = false,
    children,
    className,
    caretLabel,
    onDismiss
}: Props) => {
    const [open, setOpen] = useState(false);
    const [innerHeight, setInnerHeight] = useState(0);

    return (
        <Wrapper className={className}>
            <HeadingWrap
                onClick={() => setOpen(!open)}
                noPadding={!body || !open}
            >
                {header}
                {body ||
                    (children && (
                        <>
                            <CaretLabel>{caretLabel}</CaretLabel>
                            <DropdownCaret open={open} />
                            {onDismiss && (
                                <img
                                    style={{ cursor: "pointer" }}
                                    onClick={onDismiss}
                                    src="/black_close.svg"
                                    alt="close modal"
                                />
                            )}
                        </>
                    ))}
            </HeadingWrap>
            {body ||
                (children && (
                    <InnerWrap innerHeight={innerHeight} open={open}>
                        <div
                            ref={ref => {
                                if (ref) {
                                    setInnerHeight(ref.clientHeight);
                                }
                            }}
                        >
                            {children ? children : <TextBody>{body}</TextBody>}
                        </div>
                    </InnerWrap>
                ))}
        </Wrapper>
    );
};

export default CollapsableSection;
