import { Action, NormalizedActionGeneric, NormalizedResponse } from "src/types";
import { ReferredUser } from "../types";

export const FETCH_REFERRED_USERS = {
    REQUEST: "FETCH_REFERRED_USERS_REQUEST",
    SUCCESS: "FETCH_REFERRED_USERS_SUCCESS",
    FAILURE: "FETCH_REFERRED_USERS_FAILURE"
};

export function fetchReferredUsers() {
    return {
        type: FETCH_REFERRED_USERS.REQUEST
    };
}

export function fetchReferredUsersSuccess(
    response: NormalizedResponse<ReferredUser, number[]>
): NormalizedActionGeneric<ReferredUser, number[]> {
    return {
        type: FETCH_REFERRED_USERS.SUCCESS,
        response
    };
}

export function fetchReferredUsersFailure(error: string) {
    return {
        type: FETCH_REFERRED_USERS.FAILURE,
        payload: error
    };
}

// --------------------------------------------------------------

export const FETCH_REFERRAL_CODE = {
    REQUEST: "FETCH_REFERRAL_CODE_REQUEST",
    SUCCESS: "FETCH_REFERRAL_CODE_SUCCESS",
    FAILURE: "FETCH_REFERRAL_CODE_FAILURE"
};

export function fetchReferralCode() {
    return {
        type: FETCH_REFERRAL_CODE.REQUEST
    };
}

export function fetchReferralCodeSuccess(referralCode: number): Action {
    return {
        type: FETCH_REFERRAL_CODE.SUCCESS,
        payload: { referralCode }
    };
}

export function fetchReferralCodeFailure(error: string): Action {
    return {
        type: FETCH_REFERRAL_CODE.FAILURE,
        payload: { error }
    };
}
