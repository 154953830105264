import { mergeWith, isArray } from "lodash";
import { NormalizedAction } from "../../../types";
import {
    OpenBuy,
    OpenBuyInstructionStep,
    OpenBuySubmission,
    OpenBuySubmissionSet
} from "./types";
import { combineReducers } from "redux";
import {
    ADD_OPEN_BUY_ENTITIES,
    UPDATE_OPEN_BUY_STEP,
    DELETE_OPEN_BUY,
    UPDATE_OPEN_BUY,
    SET_OPEN_BUY_SUBMISSIONS,
    ADD_OPEN_BUY_SUBMISSIONS,
    DELETE_OPEN_BUY_SUBMISSION,
    SET_SUBMISSION_SETS,
    UPDATE_SUBMISSION_SET_SOCIAL,
    UPDATE_OPEN_BUY_SUBMISSIONS
} from "src/buys/actions";
import { FETCH_OPEN_BUY_LEADERBOARD } from "src/buys/details/leaderboard/actions";
import { UPDATE_INTERNAL_NOTES_CONVERSATION } from "src/new-chat/approval-internal-notes/actions";

interface ById {
    [id: number]: OpenBuy;
}

export interface OpenBuysEntitiesState {
    byId: ById;
    allIds: number[];
}

const initialOpenBuysState: OpenBuysEntitiesState = {
    byId: {},
    allIds: []
};

const byId = (
    state = initialOpenBuysState.byId,
    action: NormalizedAction
): ById => {
    switch (action.type) {
        case ADD_OPEN_BUY_ENTITIES:
            return { ...state, ...action.response.entities.openBuys };
        case UPDATE_OPEN_BUY:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    ...action.payload
                }
            };
        case DELETE_OPEN_BUY:
            const newState = { ...state };
            delete newState[action.payload];
            return newState;

        case ADD_OPEN_BUY_SUBMISSIONS:
            let current = state[action.payload.openBuyId];
            if (!current) return state;
            action.payload.submissions.map((submission: OpenBuySubmission) => {
                if (!current?.openBuySubmissions?.includes(submission?.id))
                    current.openBuySubmissions?.push(submission?.id);
            });
            return {
                ...state,
                [action.payload.openBuyId]: current
            };
        case DELETE_OPEN_BUY_SUBMISSION:
            let buy = state[action.payload.openBuyId];
            buy.openBuySubmissions = buy.openBuySubmissions?.filter(
                id => id !== action.payload.submissionId
            );
            return {
                ...state,
                [action.payload.openBuyId]: buy
            };
        case FETCH_OPEN_BUY_LEADERBOARD.SUCCESS:
            return {
                ...state,
                [action.payload.openBuyId]: {
                    ...state[action.payload.openBuyId],
                    leaderboard: action.response.result
                }
            };
        default:
            return state;
    }
};

const allIds = (
    state = initialOpenBuysState.allIds,
    action: NormalizedAction
): number[] => {
    switch (action.type) {
        case ADD_OPEN_BUY_ENTITIES:
            if (!action.payload.isByIdFetch) return action.response.result;
            return state;
        case DELETE_OPEN_BUY:
            return state.filter(id => id !== action.payload);
        default:
            return state;
    }
};

export const openBuysEntitiesReducer = combineReducers<OpenBuysEntitiesState>({
    byId,
    allIds
} as any);

export interface StepById {
    [id: number]: OpenBuyInstructionStep;
}

export interface SubmissionById {
    [id: number]: OpenBuySubmission;
}

export const openBuysStepsEntitiesReducer = (
    state: StepById = {},
    action: NormalizedAction
): StepById => {
    switch (action.type) {
        case ADD_OPEN_BUY_ENTITIES:
            return { ...state, ...action.response.entities.instructionSteps };
        case UPDATE_OPEN_BUY_STEP:
            return action.payload
                ? {
                      ...state,
                      [action.payload.id]: {
                          ...state[action.payload.id],
                          ...action.payload
                      }
                  }
                : state;
        default:
            return state;
    }
};

export const openBuySubmissionsReducer = (
    state: SubmissionById = {},
    action: NormalizedAction
): SubmissionById => {
    switch (action.type) {
        case ADD_OPEN_BUY_ENTITIES:
            return {
                ...state,
                ...action.response.entities.openBuySubmissions
            };
        case SET_OPEN_BUY_SUBMISSIONS:
            return action.payload;
        case ADD_OPEN_BUY_SUBMISSIONS:
            return {
                ...state,
                ...action.payload.submissions.reduce(
                    (p: any, c: OpenBuySubmission) => ({
                        ...p,
                        [c?.id]: c
                    }),
                    {}
                )
            };
        case UPDATE_OPEN_BUY_SUBMISSIONS:
            return {
                ...state,
                [action.payload?.id]: action.payload
            };
        case DELETE_OPEN_BUY_SUBMISSION:
            let temp = { ...state };
            delete temp[action.payload.submissionId];
            return temp;
        default:
            return state;
    }
};

export const openBuySubmissionSetsReducer = (
    state: OpenBuySubmissionSet[] = [],
    action: NormalizedAction
): OpenBuySubmissionSet[] => {
    switch (action.type) {
        case SET_SUBMISSION_SETS:
            return mergeWith(
                {},
                state,
                action.payload.entities.openBuySubmissionSets,
                (a, b) => (isArray(b) ? b : undefined)
            );
        case UPDATE_SUBMISSION_SET_SOCIAL:
            let index = state.findIndex(set => set.id === action.payload.setId);
            state[index].socialAccount = action.payload.socialAccount;
            state[index].socialAccount = action.payload.socialAccount.id;
            return {
                ...state
            };
        case UPDATE_INTERNAL_NOTES_CONVERSATION:
            return {
                ...state,
                [action.payload.submissionSetId]: {
                    ...state[action.payload.submissionSetId],
                    conversationId: action.response.result[0]
                }
            };
        default:
            return state;
    }
};
