import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Connection, Profile, SocialAccount } from "../../types";
import { goBack, push } from "connected-react-router";
import PublicProfilePreview from "../../preview/PublicProfilePreview";
import IntroSocialWelcome from "./IntroSocialWelcome";
import IntroSocialScreen, {
    ClearInput,
    IntroButton
} from "./IntroSocialScreen";
import styled from "styled-components";
import RecommendationsCard from "./RecommendationsCard";
import AccountsCard from "./AccountsCard";
import { GlobalState } from "../../../reducers";
import CardLoader from "../../components/CardLoader";
import {
    completeAccountLegacy,
    postAccountReplace,
    updatePhotoSuccess,
    updateUsernameSuccess
} from "../../actions";
import { patch, post } from "../../../Api";
import { makeId } from "../../../util";
import { storage } from "../../../firebase/FirebaseConfig";
import PhotoUpload from "../../../components/PhotoUpload";
import { fonts } from "src/constants";

const Video = styled("video")`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;

    @media (max-width: 710px) {
        width: 100%;
        height: auto;
    }
`;

const Message = styled("div")`
    font-weight: 500;
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
    padding: 30px 0;
`;

enum IntroScreen {
    welcome,
    profiles,
    privacy,
    accounts,
    connect,
    messages,
    username,
    photo,
    goodbye
}

interface Props {
    profile: Profile;
}

const IntroSocial = ({ profile }: Props) => {
    const accounts = useSelector((state: GlobalState) => {
        return state.profile.accounts;
    });

    const dispatch = useDispatch();

    function dispatchGoBack(): void {
        dispatch(goBack());
    }

    function dispatchPostAccountReplace(account: SocialAccount): void {
        dispatch(postAccountReplace(account));
    }

    function dispatchUpdateUsernameSuccess(username: string): void {
        dispatch(updateUsernameSuccess(username));
    }

    function dispatchUpdatePhotoSuccess(profilePhotoUrl: string): void {
        dispatch(updatePhotoSuccess(profilePhotoUrl));
    }

    function dispatchCompleteAccountLegacy(): void {
        dispatch(completeAccountLegacy());
    }

    const [currentScreen, setCurrentScreen] = useState(IntroScreen.welcome);

    const [username, setUsername] = useState(profile.username || "");
    const [photo, setPhoto] = useState<string | null>(null);
    const [photoURL, setPhotoURL] = useState<string | null>(
        profile.profile_photo_url
    );

    const [currentProfile, setCurrentProfile] = useState<Connection | null>(
        null
    );

    const [editedAccounts, setEditedAccounts] = useState<
        SocialAccount[] | null
    >(null);

    useEffect(() => {
        if (accounts && !editedAccounts) {
            setEditedAccounts(
                accounts.map(
                    (account): SocialAccount => {
                        return { ...account, visibility: "visible" };
                    }
                )
            );
        }
    }, [accounts]);

    function onPhotoChange(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        if (e.currentTarget.files) {
            const file = e.currentTarget.files[0];

            const id = `${localStorage.getItem("userid")}_${makeId(50)}.jpg`;
            const ref = storage.ref().child(`profile_photos/${id}`);

            ref.put(file)
                .then(() => {
                    setPhoto(id);
                    setPhotoURL(URL.createObjectURL(file));
                })
                .catch();
        }
    }

    if (currentProfile) {
        return (
            <PublicProfilePreview
                selfId={profile.id}
                otherId={currentProfile.other.id.split(":")[1]}
                socialAccounts={currentProfile.other.socialAccounts}
                closeCallback={() => {
                    setCurrentProfile(null);
                }}
            />
        );
    } else if (currentScreen === IntroScreen.welcome) {
        return (
            <IntroSocialWelcome
                backCallback={dispatchGoBack}
                nextCallback={() => setCurrentScreen(IntroScreen.profiles)}
            />
        );
    } else if (currentScreen === IntroScreen.profiles) {
        return (
            <IntroSocialScreen
                step={1}
                backClicked={() => setCurrentScreen(IntroScreen.welcome)}
                description={{
                    title: "👋 Welcome to Profiles",
                    subtitle:
                        "Introducing public profiles on the Plug! Every influencer on the Plug now has a profile that you can view. But, don’t worry, your earnings are always private."
                }}
                middle={
                    <Video
                        muted={true}
                        autoPlay={true}
                        loop={true}
                        src="/intro-social/intro-video-1.mp4"
                    />
                }
                button={
                    <IntroButton
                        onClick={() => setCurrentScreen(IntroScreen.privacy)}
                    >
                        Next
                    </IntroButton>
                }
            />
        );
    } else if (currentScreen === IntroScreen.privacy) {
        return (
            <IntroSocialScreen
                step={2}
                backClicked={() => setCurrentScreen(IntroScreen.profiles)}
                description={{
                    title: "👁 Privacy First",
                    subtitle:
                        "You also have complete control over the visibility of your social accounts. You can easily toggle the visibility of your accounts by clicking on the profile image."
                }}
                middle={
                    <Video
                        muted={true}
                        autoPlay={true}
                        loop={true}
                        src="/intro-social/intro-video-2.mp4"
                    />
                }
                button={
                    <IntroButton
                        onClick={() => setCurrentScreen(IntroScreen.accounts)}
                    >
                        Next
                    </IntroButton>
                }
            />
        );
    } else if (currentScreen === IntroScreen.accounts) {
        return (
            <IntroSocialScreen
                step={3}
                backClicked={() => setCurrentScreen(IntroScreen.privacy)}
                description={{
                    title: "👁 Set Your Account Visibility",
                    subtitle:
                        "Let's set up the visibility for all of the social accounts you have listed. You can toggle the visiblity of your accounts below."
                }}
                middle={
                    editedAccounts ? (
                        <AccountsCard
                            editedAccounts={editedAccounts}
                            setEditedAccounts={setEditedAccounts}
                        />
                    ) : (
                        <CardLoader />
                    )
                }
                button={
                    <IntroButton
                        onClick={() => {
                            if (editedAccounts) {
                                Promise.all(
                                    editedAccounts.map(newAccount => {
                                        return post(
                                            `/v1/social_account/${newAccount.id}`,
                                            newAccount
                                        );
                                    })
                                );
                                editedAccounts.forEach(newAccount => {
                                    dispatchPostAccountReplace(newAccount);
                                });
                                setCurrentScreen(IntroScreen.connect);
                            }
                        }}
                    >
                        Next
                    </IntroButton>
                }
            />
        );
    } else if (currentScreen === IntroScreen.connect) {
        return (
            <IntroSocialScreen
                step={4}
                backClicked={() => setCurrentScreen(IntroScreen.accounts)}
                description={{
                    title: "👋 Let’s Get Connected",
                    subtitle:
                        "Connect with influencers on the Plug to keep up with the latest trends and make new friends."
                }}
                middle={
                    <RecommendationsCard
                        setCurrentProfile={setCurrentProfile}
                    />
                }
                button={
                    <IntroButton
                        onClick={() => setCurrentScreen(IntroScreen.messages)}
                    >
                        Next
                    </IntroButton>
                }
            />
        );
    } else if (currentScreen === IntroScreen.messages) {
        return (
            <IntroSocialScreen
                step={5}
                backClicked={() => setCurrentScreen(IntroScreen.connect)}
                description={{
                    title: "💬 Keep the Conversation Going",
                    subtitle:
                        "Interact with other influencers on the Plug by joining group chats, or set up deals directly by hitting up the DMs."
                }}
                middle={
                    <Video
                        muted={true}
                        autoPlay={true}
                        loop={true}
                        src="/intro-social/intro-video-3.mp4"
                    />
                }
                button={
                    <IntroButton
                        onClick={() => setCurrentScreen(IntroScreen.username)}
                    >
                        Next
                    </IntroButton>
                }
            />
        );
    } else if (currentScreen === IntroScreen.username) {
        return (
            <IntroSocialScreen
                step={6}
                backClicked={() => setCurrentScreen(IntroScreen.messages)}
                description={{ title: "Set Your Username" }}
                middle={
                    <>
                        <ClearInput
                            placeholder="Username"
                            value={username}
                            onChange={(e: any) =>
                                setUsername(e.currentTarget.value)
                            }
                        />
                        <Message>
                            Don’t worry, you can change this later
                        </Message>
                    </>
                }
                button={
                    <IntroButton
                        enabled={username.length > 0}
                        onClick={() => {
                            if (username.length > 0) {
                                post(
                                    `/v1/publisher/p:${profile.id}/profile/username`,
                                    { username }
                                )
                                    .then(response => {
                                        dispatchUpdateUsernameSuccess(
                                            response.data.data.username
                                        );
                                        setCurrentScreen(IntroScreen.photo);
                                    })
                                    .catch(error => {
                                        window.alert(
                                            `Error: ${error.response.data.error.message}`
                                        );
                                        setCurrentScreen(IntroScreen.photo);
                                    });
                            }
                        }}
                    >
                        Next
                    </IntroButton>
                }
            />
        );
    } else if (currentScreen === IntroScreen.photo) {
        return (
            <IntroSocialScreen
                step={7}
                backClicked={() => setCurrentScreen(IntroScreen.username)}
                skipClicked={() => setCurrentScreen(IntroScreen.goodbye)}
                description={{
                    title: "Set Your Profile Picture"
                }}
                middle={
                    <>
                        <PhotoUpload
                            photoURL={photoURL}
                            onChange={onPhotoChange}
                        />
                        <Message>
                            Don’t worry, you can change this later
                        </Message>
                    </>
                }
                button={
                    <IntroButton
                        enabled={Boolean(photo)}
                        onClick={() => {
                            if (Boolean(photo)) {
                                post(
                                    `/v1/publisher/p:${profile.id}/profile/avatar`,
                                    { filename: photo }
                                )
                                    .then(response => {
                                        dispatchUpdatePhotoSuccess(
                                            response.data.data.avatar
                                        );
                                        setCurrentScreen(IntroScreen.goodbye);
                                    })
                                    .catch(error => {
                                        window.alert(
                                            `Error: ${error.response.data.error.message}`
                                        );
                                    });
                            }
                        }}
                    >
                        Next
                    </IntroButton>
                }
            />
        );
    } else {
        return (
            <IntroSocialScreen
                backClicked={() => setCurrentScreen(IntroScreen.photo)}
                description={{
                    title: "You’re all set!"
                }}
                middle={
                    <Video
                        muted={true}
                        autoPlay={true}
                        src="/intro-social/intro-video-4.mp4"
                    />
                }
                button={
                    <IntroButton
                        onClick={() => {
                            patch(`/v1/publisher/p:${profile.id}`, {
                                legacyAccountSetup: true
                            })
                                .then(() => {
                                    dispatchCompleteAccountLegacy();
                                    dispatch(push("/profile"));
                                })
                                .catch(error => {
                                    window.alert(error);
                                });
                        }}
                    >
                        Take me to my profile!
                    </IntroButton>
                }
            />
        );
    }
};

export default IntroSocial;
