import React from "react";
import MainContainer from "src/components/MainContainer";
import { Info } from "src/components/text/CommonStyles";
import styled from "styled-components";
import { breakpoints, colors, fonts } from "../../constants";

const Main = styled.div`
    padding: 0 20px 20px;
    color: white;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
`;

const BackButton = styled.img`
    padding: 16px 20px;
    cursor: pointer;
`;

const SkipButton = styled.div`
    margin-left: auto;
    margin-right: 20px;
    font-weight: 500;
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
    color: white;
    cursor: pointer;
`;

const VerticallyCentered = styled.div`
    display: flex;
    align-items: center;
`;

const NavContainer = styled(VerticallyCentered)`
    height: 80px;
`;

const DescriptionContainer = styled(VerticallyCentered)`
    height: 72px;
    font-weight: bold;
`;

const InputContainer = styled.div`
    padding: 20px 0px 24px;
    font-weight: bold;
`;

const ButtonContainer = styled.div`
    height: 50px;
`;

interface ButtonProps {
    enabled?: boolean;
}

export const WhiteButton = styled.div<ButtonProps>`
    height: 100%;
    background-color: white;
    font-size: 16px;
    color: ${colors.primaryGreen};
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    ${props => {
        if (props.enabled || props.enabled === undefined) {
            return "cursor: pointer;";
        } else {
            return "opacity: 0.6;";
        }
    }}
`;

export const ClearInput = styled.input`
    height: 50px;
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    background-color: unset;
    color: white;
    font-size: 32px;
    font-weight: 700;
    box-sizing: border-box;

    &::placeholder {
        color: white;
        opacity: 0.6;
    }
`;

interface Props {
    backClicked?: () => void;
    skipClicked?: () => void;
    description: JSX.Element | string;
    input: JSX.Element;
    extra?: JSX.Element;
    button: JSX.Element;
    secondButton?: JSX.Element;
    info?: string;
}

const AuthInputScreen = ({
    backClicked,
    skipClicked,
    description,
    input,
    extra,
    button,
    secondButton,
    info
}: Props) => {
    return (
        <>
            <NavContainer>
                {backClicked && (
                    <BackButton onClick={backClicked} src={"/auth-back.svg"} />
                )}
                {skipClicked && (
                    <SkipButton onClick={skipClicked}>Skip</SkipButton>
                )}
            </NavContainer>

            <MainContainer>
                <Main>
                    <DescriptionContainer>{description}</DescriptionContainer>
                    <InputContainer>{input}</InputContainer>
                    {extra}
                    <ButtonContainer>{button}</ButtonContainer>
                    {Boolean(secondButton) && (
                        <ButtonContainer>{secondButton}</ButtonContainer>
                    )}
                    {Boolean(info) && <Info>{info}</Info>}
                </Main>
            </MainContainer>
        </>
    );
};

export default AuthInputScreen;
