import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OpenBuyInstructionStep } from "src/buys/modules/open-buys/types";
import { colors, shadows } from "src/constants";
import {
    createInstructionStepPayment,
    deleteInstructionStepPayment,
    updateInstructionStepPayment
} from "src/marketplace/instructions-sets/actions.instruction-steps";
import {
    InstructionPayment,
    InstructionStep
} from "src/marketplace/instructions-sets/types";
import {
    Dollars,
    InputContainer,
    PaymentInput
} from "src/marketplace/payment-sets/components/SingleInputPayment";
import Dropdown from "src/marketplace/payment-sets/components/PaymentSetDropdown";
import {
    GreyContainer,
    Section,
    SmallTitle,
    Title
} from "src/marketplace/payment-sets/components/styledComponents";
import { fetchPaymentTypes } from "src/marketplace/payment-sets/payment-types/actions";
import {
    getPaymentMapById,
    getPaymentTypes
} from "src/marketplace/payment-sets/payment-types/selectors";
import { getPaymentDisplayName } from "src/marketplace/payment-sets/utils/helpers";
import { BlueButton } from "src/profile/components/Buttons";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: flex-start;
    margin-top: 12px;
    gap: 20px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 12px;
    gap: 20px;

    background: ${colors.white};
    border: 0.25px solid ${colors.lightGray2};
    box-shadow: ${shadows.four};
    border-radius: 8px;
`;

const Payment = styled(GreyContainer)`
    flex-direction: row;
`;

const SectionTitle = styled(SmallTitle)`
    text-transform: uppercase;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 12px;
`;

const StyledBlueButton = styled(BlueButton)`
    padding: 8px 12px;
    font-size: 12px;
    width: 100%;
`;

const StyledRedButton = styled(StyledBlueButton)`
    background-color: ${props =>
        props.disabled ? colors.primaryRedDark : colors.primaryRed};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryRedDark};
        }
    }
`;

interface ChallengePaymentForm {
    instructionStepId: number | null;
    paymentId: number | null;
    amount: string;
    viewsMinimum: string;
}

interface Props {
    instructionStepPayment?: InstructionPayment;
    selectedStep?: InstructionStep;
    setNewPaymentsCount: React.Dispatch<React.SetStateAction<number>>;
}

const ChallengePaymentForm = ({
    instructionStepPayment,
    selectedStep,
    setNewPaymentsCount
}: Props) => {
    const dispatch = useDispatch();

    // Form -----------------------------------------------------------
    const instructionStepId = "instructionStepId";
    const paymentId = "paymentId";
    const amount = "amount";
    const viewsMinimum = "viewsMinimum";

    const defaultForm: ChallengePaymentForm = {
        [instructionStepId]: instructionStepPayment
            ? instructionStepPayment.instructionStepId
            : selectedStep && selectedStep.id
            ? selectedStep.id
            : null,
        [paymentId]: instructionStepPayment
            ? instructionStepPayment.paymentId
            : null,
        [amount]: instructionStepPayment
            ? instructionStepPayment.amount.toString()
            : "",
        [viewsMinimum]: instructionStepPayment
            ? instructionStepPayment.viewsMinimum
            : ""
    };

    // Local State -----------------------------------------------------------
    const [formInputs, setFormInputs] = useState(defaultForm);
    const [focus, setFocus] = useState(false);

    const paymentTypes = useSelector(getPaymentTypes).filter(
        payment => payment.type !== "view_thresholds"
    );
    const paymentMapById = useSelector(getPaymentMapById);

    // Effects -----------------------------------------------------------
    useEffect(() => {
        if (paymentTypes.length < 1) dispatch(fetchPaymentTypes());
    }, [paymentTypes]);

    // Handle Functions -----------------------------------------------------------
    const handleChange = (key: string, value: any) => {
        setFormInputs({ ...formInputs, [key]: value });
    };

    const handleSubmit = () => {
        const {
            instructionStepId,
            paymentId,
            amount,
            viewsMinimum
        } = formInputs;

        const validated =
            instructionStepId && paymentId && parseFloat(amount) > 0;

        if (!instructionStepId) {
            dispatch(
                setPillNotificationText(
                    "Please check you have selected an instruction step",
                    "danger"
                )
            );
        } else if (!paymentId) {
            dispatch(
                setPillNotificationText(
                    "Please check you have selected a payment type",
                    "danger"
                )
            );
        } else if (parseFloat(amount) === 0) {
            dispatch(
                setPillNotificationText(
                    "Please enter a valid payment amount",
                    "danger"
                )
            );
        } else if (!validated) return;
        else {
            if (instructionStepPayment) {
                dispatch(
                    updateInstructionStepPayment(instructionStepPayment.id, {
                        ...(paymentId && { paymentId }),
                        ...(amount && { amount: parseFloat(amount) }),
                        ...(viewsMinimum && {
                            viewsMinimum: parseInt(viewsMinimum)
                        })
                    })
                );
            } else {
                dispatch(
                    createInstructionStepPayment({
                        instructionStepId,
                        paymentId,
                        amount: parseFloat(amount),
                        viewsMinimum:
                            viewsMinimum.length > 0
                                ? parseInt(viewsMinimum)
                                : null
                    })
                );
            }
            setNewPaymentsCount(0);
        }
    };

    const handleDeletePayment = () => {
        if (formInputs.instructionStepId && instructionStepPayment) {
            dispatch(
                deleteInstructionStepPayment(
                    formInputs.instructionStepId,
                    instructionStepPayment.id
                )
            );
        }
    };

    // Return -----------------------------------------------------------
    return (
        <BodyContainer>
            <Body>
                <Title>Instruction Step: {formInputs.instructionStepId}</Title>
                <Payment>
                    <Section>
                        <SectionTitle>Payment Amount</SectionTitle>
                        <InputContainer focused={focus}>
                            <Dollars>
                                $
                                <PaymentInput
                                    type="text"
                                    placeholder="Input Payment Amount"
                                    value={formInputs.amount}
                                    onChange={e =>
                                        handleChange(
                                            amount,
                                            e.currentTarget.value
                                        )
                                    }
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => setFocus(false)}
                                    hasInput={!!formInputs.amount}
                                />
                            </Dollars>
                        </InputContainer>
                    </Section>

                    <Section>
                        <SectionTitle>Payment Type</SectionTitle>
                        <Dropdown
                            itemList={paymentTypes}
                            selected={formInputs.paymentId}
                            displayName={getPaymentDisplayName(
                                paymentMapById.get(formInputs.paymentId)
                            )}
                            displayEmptyState={"No Payment Type Selected"}
                            handleOnClick={item => {
                                handleChange(paymentId, item.id);
                            }}
                            getItemName={item =>
                                getPaymentDisplayName(item.type)
                            }
                        />
                    </Section>

                    <Section>
                        <SectionTitle>Minimum Views</SectionTitle>
                        <InputContainer focused={focus}>
                            <PaymentInput
                                type="text"
                                placeholder="Input Minimum Views"
                                value={formInputs.viewsMinimum}
                                onChange={e =>
                                    handleChange(
                                        viewsMinimum,
                                        e.currentTarget.value
                                    )
                                }
                                onFocus={() => setFocus(true)}
                                onBlur={() => setFocus(false)}
                                hasInput={parseInt(formInputs.viewsMinimum) > 0}
                            />
                        </InputContainer>
                    </Section>
                </Payment>

                <Buttons>
                    <StyledBlueButton onClick={handleSubmit}>
                        {instructionStepPayment
                            ? "Edit Payment"
                            : "Create Payment"}
                    </StyledBlueButton>
                    <StyledRedButton
                        disabled={!instructionStepPayment}
                        onClick={handleDeletePayment}
                    >
                        Delete Payment
                    </StyledRedButton>
                </Buttons>
            </Body>
        </BodyContainer>
    );
};

export default ChallengePaymentForm;
