import { Action } from "src/types";

export const RESET_FAILURES = "RESET_FAILURES";

export function resetFailures(): Action {
    return {
        type: RESET_FAILURES,
        payload: null
    };
}
