import { Action, NormalizedActionGeneric, NormalizedResponse } from "src/types";
import { ChatProfile } from "../types/ChatProfile";

export const FETCH_CHAT_USER_PROFILE = {
    REQUEST: "FETCH_CHAT_USER_PROFILE_REQUEST",
    SUCCESS: "FETCH_CHAT_USER_PROFILE_SUCCESS"
};

export function fetchChatUserProfile(otherId: string): Action {
    return { type: FETCH_CHAT_USER_PROFILE.REQUEST, payload: { otherId } };
}

export function fetchChatUserProfileSuccess(
    response: NormalizedResponse<ChatProfile, string[]>
): NormalizedActionGeneric<ChatProfile, string[]> {
    return { type: FETCH_CHAT_USER_PROFILE.SUCCESS, response };
}

export const CURRENT_CHAT_USER_PROFILE_CHANNEL = {
    UPDATE: "UPDATE_CURRENT_CHAT_USER_PROFILE_CHANNEL",
    CREATE: "CREATE_CURRENT_CHAT_USER_PROFILE_CHANNEL"
};

export function createCurrentChatUserProfileChannel(
    participantIds: string[]
): Action {
    return {
        type: CURRENT_CHAT_USER_PROFILE_CHANNEL.CREATE,
        payload: { participantIds }
    };
}

export const SET_CHAT_USER_ONLINE_STATUS = "SET_CHAT_USER_ONLINE_STATUS";

export function setChatUserOnlineStatus(
    conversationId: string,
    online: boolean
) {
    return {
        type: SET_CHAT_USER_ONLINE_STATUS,
        payload: { conversationId, online }
    };
}
