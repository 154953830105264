import { Action } from "src/types";

import { SET_A_MODAL_IS_OPENED, SET_IS_DEVICE_MOBILE } from "./actions";

export interface ObserverUIState {
    isDeviceMobile: boolean;
    aModalIsOpen: boolean;
}

const observerInitialState = {
    isDeviceMobile: false,
    aModalIsOpen: false
};

export function ObserverReducer(state = observerInitialState, action: Action) {
    switch (action.type) {
        case SET_IS_DEVICE_MOBILE:
            return { ...state, isDeviceMobile: action.payload.isMobile };

        case SET_A_MODAL_IS_OPENED:
            return { ...state, aModalIsOpen: action.payload.opened };

        default:
            return state;
    }
}
