import { useDispatch, useSelector } from "react-redux";
import { fetchPublicProfile } from "src/public-profiles/action";
import { selectProfileFromCache } from "src/public-profiles/selectors";
import { GlobalState } from "src/reducers";

// takes userId in "number" format without "p:"
const useCachedProfiles = (userId?: string) => {
    const dispatch = useDispatch();

    const profile = useSelector((state: GlobalState) =>
        selectProfileFromCache(state, userId)
    );

    function fetchProfile(userId?: string) {
        if (!profile && userId) dispatch(fetchPublicProfile(userId));
    }

    return { profile, fetchProfile };
};

export default useCachedProfiles;
