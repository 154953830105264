import { normalize } from "normalizr";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { get, getApi, getPublic } from "../../Api";
import { Action } from "../../types";
import {
    fetchCampaignHighlightsSuccess,
    fetchHighlightsSuccess,
    fetchHighlightsMediaPerformanceFailure,
    fetchHighlightsMediaPerformanceSuccess,
    FETCH_CAMPAIGN_HIGHLIGHTS,
    FETCH_HIGHLIGHTS,
    FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE
} from "./actions";
import * as schema from "../schema";
import { fetchPlacementsRequest } from "../placements/saga";

// function* fetchHighlightsRequest() {
//     try {
//         const { data, status } = yield call(getApi, "/get_highlights_page", {});

//         if (status === 200) {
//             yield put(fetchHighlightsSuccess(data.highlights));
//         } else {
//             yield put(fetchHighlightsFailure("Fetch highlights failure"));
//         }
//     } catch (error) {
//         yield put(fetchHighlightsFailure("Fetch highlights failure"));
//     }
// }

export function* fetchHighlightsRequest() {
    try {
        yield fork(fetchPlacementsRequest);
        const { data } = yield call(get, `/v1/highlight`, {});

        yield put(
            fetchHighlightsSuccess(normalize(data.data, schema.highlightList))
        );
    } catch (error) {}
}

export function* fetchCampaignHighlightsRequest({
    payload: { campaignId }
}: Action) {
    try {
        yield fork(fetchPlacementsRequest);
        const { data } = yield call(getPublic, `/v1/highlight`, {
            campaignId,
            getAllForCampaign: true
        });

        yield put(
            fetchCampaignHighlightsSuccess(
                normalize(data.data, schema.highlightList),
                {
                    campaignId
                }
            )
        );
    } catch (error) {}
}

function* fetchHighlightsMediaPerformanceRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            getApi,
            "/get_media_performance",
            payload
        );

        if (status === 200) {
            yield put(
                fetchHighlightsMediaPerformanceSuccess(data.media_performances)
            );
        } else {
            yield put(
                fetchHighlightsMediaPerformanceFailure(
                    "Fetch media performance failure"
                )
            );
        }
    } catch (error) {
        yield put(
            fetchHighlightsMediaPerformanceFailure(
                "Fetch media performance failure"
            )
        );
    }
}

export default function* highlightsSaga() {
    yield all([
        takeEvery(FETCH_HIGHLIGHTS.REQUEST, fetchHighlightsRequest),
        takeEvery(
            FETCH_CAMPAIGN_HIGHLIGHTS.REQUEST,
            fetchCampaignHighlightsRequest
        ),
        takeEvery(
            FETCH_HIGHLIGHTS_MEDIA_PERFORMANCE.REQUEST,
            fetchHighlightsMediaPerformanceRequest
        )
    ]);
}
