import { useState } from "react";
import { TableRow } from "../types";

export interface MultiSelectReference {
    selectedRows: number[];
    selectedRowsSet: Set<number>;
    allSelected: boolean;
    selectAll: VoidFunction;
    deselectAll: VoidFunction;
    clickRow: (idx: number) => void;
    setSelectedRows: (idxs: number[]) => void;
}

export default function useMultiSelect(
    cellData: TableRow[],
    onMultiSelectUpdate: (selectedRows: number[]) => void,
    initialChecks?: number[] | (() => number[])
): MultiSelectReference {
    const [selectedRows, setSelectedRows] = useState<number[]>(
        initialChecks ?? []
    );
    const allSelected = selectedRows.length === cellData.length;
    const selectedRowsSet = new Set(selectedRows);

    const deselectAll = () => {
        setSelectedRows([]);
        onMultiSelectUpdate([]);
    };
    const selectAll = () => {
        const allRowIdxs = cellData.map(d => d.originalIdx);
        setSelectedRows(allRowIdxs);
        onMultiSelectUpdate(allRowIdxs);
    };
    const clickRow = (idx: number) => {
        if (selectedRowsSet.has(idx)) {
            const newIdxs = selectedRows.filter(n => n !== idx);
            setSelectedRows(newIdxs);
            onMultiSelectUpdate(newIdxs);
        } else {
            const newIdxs = [...selectedRows, idx];
            setSelectedRows(newIdxs);
            onMultiSelectUpdate(newIdxs);
        }
    };
    return {
        selectedRows,
        selectedRowsSet,
        allSelected,
        selectAll,
        deselectAll,
        clickRow,
        setSelectedRows
    };
}
