import React from "react";

import { Props } from "src/components/icons/types";

const ChevronRight = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || "10"}
            viewBox="0 0 10 10"
            fill="none"
        >
            <path
                d="M3.762.273l3.881 4.048c.091.088.164.194.213.31s.075.242.075.369-.026.252-.075.369-.122.222-.213.31L3.762 9.727c-.36.352-1.009.376-1.392 0s-.415-.897 0-1.357L5.6 5.001 2.37 1.632c-.415-.459-.384-.984 0-1.359s1.032-.353 1.392 0z"
                fill={props.fill || "#000"}
            />
        </svg>
    );
};

export default ChevronRight;
