import { Action } from "src/types";
import { SET_SCROLL_RESTORATION_POSITION } from "./actions";

export interface ScrollRestorationUIState {
    [pathname: string]: number;
}

const initialScrollRestorationUIState: ScrollRestorationUIState = {};

const scrollRestorationUIReducer = (
    state = initialScrollRestorationUIState,
    action: Action
) => {
    switch (action.type) {
        case SET_SCROLL_RESTORATION_POSITION:
            return { ...state, [action.payload.pathname]: action.payload.top };
        default:
            return state;
    }
};

export default scrollRestorationUIReducer;
