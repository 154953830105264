import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useScroll } from "react-use";
import {
    flexPreventChildStretching,
    flex,
    hideScrollbar
} from "src/utils/styles/snippets";
import UploadMediaButton from "src/campaigns/landing/components/UploadMediaButton";
import { sizes, breakpoints } from "src/constants";
import { Campaign } from "src/campaigns/types";
import { GlobalState } from "src/reducers";
import MediaCarouselShimmer from "./MediaCarouselShimmer";
import { debounce } from "src/util";
import MediaItem from "./MediaItem";
import { useInView } from "react-intersection-observer";
import { Media } from "src/media/types";
import {
    getMemoizedTabbedCampaignMedias,
    getTabbedCampaignMediaIds
} from "../selectors";
import {
    MediaTab,
    setCurrentCampaignId,
    setCurrentLoadedMedias
} from "src/ui/campaigns/actions";
import MediaCarouselIncrementor from "./MediaCarouselIncrementor";
import MediaSearchButton from "./MediaSearchButton";
import ScrollButton from "./ScrollButton";
import { globalPlacementConfig } from "src/campaigns/placements/selectors";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const IntersectionObserver = styled.div``;

const Main = styled("div")`
    position: relative;
    width: 100%;

    @media (max-width: 958px) {
        padding-bottom: 0;
        align-items: center;
    }
`;

interface ItemsProps {
    modalOpen: boolean;
}

const MediaItemsContainer = styled("div")<ItemsProps>`
    ${flexPreventChildStretching()};
    ${flex("row", "auto", "center")};
    padding: 20px;
    overflow-x: ${props => (props.modalOpen ? "hidden" : "scroll")};

    @media (max-width: ${breakpoints.mobile}px) {
        padding: 10px 20px;
    }

    ${props => {
        return props.theme.isMobile && hideScrollbar();
    }};
`;

interface Props {
    tabType: MediaTab;
    campaign: Campaign;
}

const MediaCarousel = (props: Props) => {
    const { tabType, campaign } = props;

    const mediaItemsRef = useRef<any>(null);

    function calculateMediaItemsCount(): number {
        if (mediaItemsRef && mediaItemsRef.current) {
            const carouselWidth = mediaItemsRef.current.getBoundingClientRect()
                .width;

            const sidePaddingTotal = 40;

            const mediaItemsCount =
                (carouselWidth - sidePaddingTotal) / sizes.mediaItemWidth;

            return Math.ceil(mediaItemsCount);
        } else {
            return 0;
        }
    }

    const [maxLoadedMedia, setMaxLoadedMedia] = useState(
        calculateMediaItemsCount()
    );
    const [loading, setLoading] = useState(true);
    const [scrolledToFarLeft, setScrolledToFarLeft] = useState(true);
    const [scrolledToFarRight, setScrolledToFarRight] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleResize = debounce(() => {
        if (maxLoadedMedia < 9) {
            setMaxLoadedMedia(calculateMediaItemsCount());
        }
    });

    const tabbedCampaignMedias = useSelector((state: GlobalState) => {
        return getMemoizedTabbedCampaignMedias(state, campaign.id);
    });

    const tabbedCampaignMediaIds = useSelector((state: GlobalState) => {
        return getTabbedCampaignMediaIds(state, campaign.id);
    });

    const placementConfig = useSelector(globalPlacementConfig);

    const dispatch = useDispatch();
    function handleMediaItemClick(mediaModalCarouselLocation: number) {
        const currentLoadedMedias = [
            ...tabbedCampaignMediaIds.slice(
                mediaModalCarouselLocation,
                tabbedCampaignMediaIds.length
            ),
            ...tabbedCampaignMediaIds.slice(0, mediaModalCarouselLocation)
        ];

        dispatch(setCurrentCampaignId(campaign.id));
        dispatch(setCurrentLoadedMedias(currentLoadedMedias));
    }

    const mediasLength = useSelector(
        (state: GlobalState) =>
            state.entities.campaigns.byId[campaign.id].medias?.length
    );

    useEffect(() => {
        if (mediasLength !== undefined && tabbedCampaignMedias.length >= 0)
            setLoading(false);
    }, [mediasLength, tabbedCampaignMedias]);

    const modalOpen = useSelector((state: GlobalState) => {
        return state.ui.medias.mediaItemModalOpen;
    });

    const { x } = useScroll(mediaItemsRef);

    useEffect(() => {
        if (mediaItemsRef && mediaItemsRef.current) {
            // Scrolled all the way to the left.
            if (x === 0) {
                setScrolledToFarLeft(true);
                setScrolledToFarRight(false);
            }
            // Scrolled all the way to the right.
            else if (
                x ===
                mediaItemsRef.current.scrollWidth -
                    mediaItemsRef.current.clientWidth
            ) {
                setScrolledToFarLeft(false);
                setScrolledToFarRight(true);
            }
            // Not scrolled all the way to the left, nor to the right.
            else if (x > 0) {
                setScrolledToFarLeft(false);
                setScrolledToFarRight(false);
            }
        }
    }, [x, maxLoadedMedia, mediaItemsRef, mediaItemsRef.current]);

    const maxIncrementValue = Math.min(tabbedCampaignMedias.length, 10);

    function incrementMaxLoadedMedia(): void {
        if (maxLoadedMedia + 4 < maxIncrementValue) {
            setMaxLoadedMedia(maxLoadedMedia + 4);
        } else {
            setMaxLoadedMedia(maxIncrementValue);
        }

        setScrolledToFarRight(false);
    }

    function scrollLeft(): void {
        if (mediaItemsRef && mediaItemsRef.current) {
            const xPosition = mediaItemsRef.current.scrollLeft;
            const containerWidth = mediaItemsRef.current.getBoundingClientRect()
                .width;

            mediaItemsRef.current.scrollTo({
                left: xPosition - containerWidth,
                behavior: "smooth"
            });

            setScrolledToFarRight(false);
        }
    }

    function scrollRight(): void {
        if (mediaItemsRef && mediaItemsRef.current) {
            const xPosition = mediaItemsRef.current.scrollLeft;
            const containerWidth = mediaItemsRef.current.getBoundingClientRect()
                .width;

            mediaItemsRef.current.scrollTo({
                left: xPosition + containerWidth,
                behavior: "smooth"
            });

            setScrolledToFarLeft(false);
            // incrementMaxLoadedMedia();
        }
    }

    const promote = useSelector(selectFeatureFlags(featureFlags.PromoteFlow));

    const { ref, inView } = useInView({ threshold: 0 });

    // Hides left and right arrows on desktop when the max media items count is smaller
    // than the width of the carousel.
    useEffect(() => {
        if (mediaItemsRef && mediaItemsRef.current) {
            const sidePadding = 20 * 2;
            const mediaItemsCount = tabbedCampaignMedias.length;
            const mediaItemsGap = 16;
            const mediaItemsGapCount = mediaItemsCount - 1;

            const mediaItemsTotalWidth =
                mediaItemsCount * sizes.mediaItemWidth +
                mediaItemsGap * mediaItemsGapCount;

            if (
                mediaItemsTotalWidth <
                mediaItemsRef.current.getBoundingClientRect().width -
                    sidePadding
            ) {
                setScrolledToFarLeft(true);
                setScrolledToFarRight(true);
            }
        }
    }, [tabbedCampaignMedias.length, window.innerWidth]);

    return (
        <IntersectionObserver ref={ref}>
            <Main>
                <ScrollButton
                    onClick={scrollLeft}
                    direction="left"
                    scrolledToEnd={scrolledToFarLeft}
                />

                <MediaItemsContainer ref={mediaItemsRef} modalOpen={modalOpen}>
                    {!promote && (
                        <UploadMediaButton currentCampaign={campaign} />
                    )}

                    {loading || !inView ? (
                        <MediaCarouselShimmer tabType={tabType} />
                    ) : (
                        tabbedCampaignMedias
                            .slice(0, maxLoadedMedia)
                            .map((campaignMedia: Media, index: number) => {
                                return (
                                    <MediaItem
                                        key={`${campaignMedia.id}`}
                                        campaignMedia={campaignMedia}
                                        linkService={campaign.linkService}
                                        onItemClick={handleMediaItemClick}
                                        rank={index}
                                    />
                                );
                            })
                    )}

                    {!loading && (
                        <MediaCarouselIncrementor
                            maxMediaCount={maxIncrementValue}
                            maxLoadedMedia={maxLoadedMedia}
                            incrementMaxLoadedMedia={incrementMaxLoadedMedia}
                        />
                    )}

                    {!loading && maxIncrementValue <= maxLoadedMedia && (
                        <MediaSearchButton
                            campaignId={campaign.id}
                            campaignName={campaign.campaignName}
                            placements={placementConfig}
                        />
                    )}
                </MediaItemsContainer>

                <ScrollButton
                    onClick={scrollRight}
                    direction="right"
                    scrolledToEnd={scrolledToFarRight}
                />
            </Main>
        </IntersectionObserver>
    );
};

export default MediaCarousel;
