import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { get, patch } from "src/Api";
import { colors } from "src/constants";
import { BigBlueButton } from "src/profile/components/Buttons";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import Tooltip from "src/components/Tooltip";
import UpdateViewCountModal from "./UpdateViewCountModal";
import RepairMediaUploadModal from "./RepairMediaUploadModal";
import { toggleLeaderboardEditorOpen } from "src/ui/marketplace/actions";
import { OpenBuySubmission } from "src/buys/modules/open-buys/types";
import { getSubmissionTypeHierarchy } from "src/admin-tools/challenges/util";

const Wrapper = styled.div`
    width: 360px;
    padding: 12px;
    gap: 10px;
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-size: 16px;
    text-align: center;
    padding: 5px 0px;
`;
const Action = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    height: 46px;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: ${colors.quaternaryLightGray};
    }
`;

const Caret = styled.img`
    transform: rotate(-90deg);
    height: 12px;
    width: 12px;
`;

const DangerAction = styled(BigBlueButton)`
    background: red;
`;

const StyledTooltip = styled(Tooltip)`
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
`;
interface Props {
    submission: OpenBuySubmission;
    getSubmissionSet: VoidFunction;
    close: VoidFunction;
    tooltipOpen: boolean;
    tooltipRef: any;
}
const SubmissionActions = ({
    submission,
    getSubmissionSet,
    close,
    tooltipOpen,
    tooltipRef
}: Props) => {
    const [modal, setModal] = useState<
        "" | "views" | "social" | "upload" | "delete"
    >("");
    const isMediaSubmission =
        getSubmissionTypeHierarchy(submission) === "media";
    const dispatch = useDispatch();

    const updateSubmission = async (updateObject: any) => {
        try {
            let res = await patch(
                `/v1/openBuySubmission/${submission.id}`,
                updateObject
            );

            dispatch(setPillNotificationText("Updated submission"));
            if (updateObject.leaderboardPinned !== undefined)
                dispatch(toggleLeaderboardEditorOpen(submission.openBuyId));
            else getSubmissionSet();
        } catch (err) {
            dispatch(
                setPillNotificationText("Failed to update submission", "danger")
            );
        }
    };

    const checkSparkCode = async () => {
        try {
            await get("/v1/checkSparkCode", {
                sparkCode: submission.textSubmission
            });
            dispatch(setPillNotificationText("Spark code is valid"));
        } catch (err) {
            dispatch(
                setPillNotificationText(
                    "Spark code is no longer valid",
                    "danger"
                )
            );
        }
    };

    return (
        <>
            <StyledTooltip
                position={"top: 40px; left: 45vw"}
                controlRef={tooltipRef}
                zIndex={2}
                isOpen={tooltipOpen}
                close={() => {
                    if (!modal) close();
                }}
            >
                <Wrapper>
                    <Title>Submission Tools</Title>

                    {submission.textSubmission && (
                        <Action onClick={checkSparkCode}>
                            Check Spark Code Still Valid
                        </Action>
                    )}
                    <Action onClick={() => setModal("views")}>
                        Update View Count
                        <Caret src={"/caret.svg"} />
                    </Action>
                    {!isMediaSubmission && (
                        <Action onClick={() => setModal("upload")}>
                            Repair Media Upload
                            <Caret src={"/caret.svg"} />
                        </Action>
                    )}
                    <DangerAction onClick={() => setModal("delete")}>
                        Delete Submission
                    </DangerAction>
                </Wrapper>
            </StyledTooltip>
            {modal === "views" && (
                <UpdateViewCountModal
                    updateSubmission={updateSubmission}
                    submission={submission}
                    closeModal={() => setModal("")}
                />
            )}
            {modal === "upload" && (
                <RepairMediaUploadModal
                    submission={submission}
                    closeModal={() => setModal("")}
                    mediaId={submission.customMedia?.id}
                    getSubmissionSet={getSubmissionSet}
                />
            )}
            {modal === "delete" && (
                <DeleteConfirmationModal
                    id={submission.id}
                    closeModal={() => setModal("")}
                    advanceOrCloseCarousel={getSubmissionSet}
                />
            )}
        </>
    );
};

export default SubmissionActions;
