import React from "react";
import { colors, sizes } from "src/constants";
import styled from "styled-components";

interface Props {
    expanded: boolean;
    itemCount?: number;
    disabled?: boolean;
}

const ChallengeLogisticsDropdown = styled.div<Props>`
    display: flex;
    flex-direction: column;
    height: ${props => {
        if (props.expanded) {
            return props.itemCount
                ? `${sizes.campaigns.selectionRow.height *
                      (props.itemCount + 1)}px`
                : `${sizes.campaigns.selectionRow.height * 6}px`;
        } else {
            return `${sizes.campaigns.selectionRow.height}px`;
        }
    }};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 8px;
    transition: 0.1s ease-in;
    overflow-y: ${props => (props.expanded ? "auto" : "hidden")};
`;

export default ChallengeLogisticsDropdown;
