import React, { useState } from "react";
import SmallModal from "src/modals/SmallModal";
import withModalPortal from "src/components/withModalPortal";
import { DeleteButton } from "src/profile/components/Buttons";
import { deleteReq } from "src/Api";
import { useDispatch } from "react-redux";
import { setPillNotificationText } from "src/ui/notifications/actions";

interface Props {
    id: any;
    isSet?: boolean;
    closeModal: VoidFunction;
    advanceOrCloseCarousel: VoidFunction;
}
const SetDeletionConfirmationModal = ({
    id,
    closeModal,
    advanceOrCloseCarousel
}: Props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleDelete = async () => {
        try {
            setLoading(true);
            await deleteReq(`/v1/admin/openBuySubmissionSet/${id}`);
            dispatch(
                setPillNotificationText(
                    "Submission Set Deleted",
                    "success",
                    3500
                )
            );
            advanceOrCloseCarousel();
            closeModal();
        } catch {
            dispatch(
                setPillNotificationText(
                    `Failed To Delete Submission Set`,
                    "danger",
                    3500
                )
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <SmallModal
            header={{ text: "Delete Submission", handleClose: closeModal }}
        >
            <DeleteButton shimmer={loading} onClick={handleDelete}>
                Delete Submission Set
                {id}
            </DeleteButton>
        </SmallModal>
    );
};

export default withModalPortal(SetDeletionConfirmationModal);
