import { createSelector } from "reselect";
import { GlobalState } from "../../reducers";

const currentHighlight = (state: GlobalState) =>
    state.ui.highlights.downloadTypesModal;
const highlightEntities = (state: GlobalState) =>
    state.entities.highlights.byId;
const highlightMediaEntities = (state: GlobalState) =>
    state.entities.highlightMedias;
const placement = (
    state: GlobalState,
    placement: "IGStoryMedia" | "singleMedia" | "doubleMedia" | "feedMedia"
) => placement;

export const getHighlightMedias = createSelector(
    placement,
    currentHighlight,
    highlightEntities,
    highlightMediaEntities,
    (placement, currentHighlight, highlights, highlightMedias) => {
        if (currentHighlight) {
            const highlight = highlights[currentHighlight];
            const placementMediaId = highlight?.[placement];
            if (placementMediaId) return highlightMedias[placementMediaId];
        }
    }
);
