import React, { useState } from "react";
import styled from "styled-components";
import { Campaign } from "src/campaigns/types";
import { storage } from "../../firebase/FirebaseConfig";
import { isMobileSafari, makeId } from "../../util";
import PulseLoader from "../../components/PulseLoader";
import ShadeScreen from "../components/ShadeScreen";
import { colors } from "../../constants";

const AdUploadPanel = styled.div`
    padding: 30px 0px;
    background-color: ${colors.lightTeal};
    display: flex;
    justify-content: center;
`;

const AdPreviewContainer = styled.div`
    position: relative;
    height: 320px;
    width: 180px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
`;

const InputContainer = styled.label`
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;
`;

const ImageDefault = styled.img`
    height: 100%;
    width: 100%;
`;

const Video = styled.video`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const Photo = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

interface Props {
    campaign: Campaign;
    setFilename: (filename: string | null) => void;
    setCoverPhoto: (coverPhoto: string | null) => void;
    type: string | null;
    setType: (type: string | null) => void;
    mediaUrl: string | null;
    setMediaUrl: (mediaUrl: string | null) => void;
    setDeleteScreen: (state: boolean) => void;
}

const UploadMediaCard = ({
    campaign,
    setFilename,
    setCoverPhoto,
    type,
    setType,
    mediaUrl,
    setMediaUrl,
    setDeleteScreen
}: Props) => {
    const [uploading, setUploading] = useState(false);

    function createCoverPhoto(file: File) {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        video.preload = "metadata";
        video.crossOrigin = "Anonymous";
        video.src = URL.createObjectURL(file);
        if (isMobileSafari()) {
            video.load();
        } else {
            video.muted = true;
            video.play();
        }
        video.onloadeddata = () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d");

            if (!context) {
                return null;
            }

            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(blob => {
                if (!blob) return null;

                const id = `${localStorage.getItem("userid")}_${makeId(
                    50
                )}.jpeg`;
                const ref = storage
                    .ref()
                    .child(`${campaign.linkUrlPrefix}/${id}`);

                ref.put(blob)
                    .then(() => {
                        setCoverPhoto(id);
                    })
                    .catch((error: string) => {
                        window.alert("Something went wrong. Please Refresh.");
                    });
            }, "image/jpeg");
        };
    }

    function fileUpload(e: any) {
        e.preventDefault();
        const allowedTypes = [
            "image/jpeg",
            "image/png",
            "video/mp4",
            "video/quicktime"
        ];
        const file = e.currentTarget.files[0];

        if (file && allowedTypes.includes(file.type)) {
            if (file.type.split("/")[0] === "video") {
                setType("video");
                createCoverPhoto(file);
            } else {
                setType("photo");
            }

            setMediaUrl(URL.createObjectURL(file));

            let type = file.type.split("/")[1];

            if (type === "quicktime") {
                type = "mov";
            }

            const id = `${localStorage.getItem("userid")}_${makeId(
                50
            )}.${type}`;
            const ref = storage.ref().child(`${campaign.linkUrlPrefix}/${id}`);

            setUploading(true);

            ref.put(file)
                .then(() => {
                    setUploading(false);
                    setFilename(id);
                })
                .catch((error: string) => {
                    window.alert("Something went wrong. Please Refresh.");
                });
        }
    }

    function renderIcon() {
        if (uploading) {
            return <PulseLoader size={15} color={"white"} />;
        } else if (type === "video") {
            return <img src={"/play_icon.svg"} />;
        } else {
            return null;
        }
    }

    return (
        <AdUploadPanel>
            <AdPreviewContainer
                onClick={() => {
                    if (mediaUrl && !uploading) {
                        setDeleteScreen(true);
                    }
                }}
            >
                {mediaUrl ? (
                    <>
                        {type === "video" ? (
                            <Video src={mediaUrl || undefined} />
                        ) : (
                            <Photo src={mediaUrl || undefined} />
                        )}
                        <ShadeScreen>{renderIcon()}</ShadeScreen>
                    </>
                ) : (
                    <>
                        <ImageDefault src={"/upload_ad/upload_ad@2x.png"} />
                        <InputContainer>
                            <input
                                type={"file"}
                                accept={
                                    "image/png, image/jpeg, video/mp4, video/quicktime"
                                }
                                style={{ display: "none" }}
                                onChange={fileUpload}
                            />
                        </InputContainer>
                    </>
                )}
            </AdPreviewContainer>
        </AdUploadPanel>
    );
};

export default UploadMediaCard;
