import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { TextEllipsis } from "src/components/CommonStyles";
import HideOverflow from "src/components/HideOverflow";
import Tag from "src/components/Tag";
import { colors, fonts } from "src/constants";
import AccountIcon from "src/profile/accounts/components/AccountIcon";
import { SocialAccount } from "src/profile/types";
import { GlobalState } from "src/reducers";
import { shortenNumber } from "src/util";
import { flex } from "src/utils/styles/snippets";
import styled from "styled-components";

const Main = styled("div")`
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 6px;
    transition: 0.1s ease-in;
    cursor: pointer;

    & + & {
        margin-top: 10px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
            border: ${colors.darkTeal} solid 1px;
        }
    }
`;

const Section = styled.div<{ clickable: boolean }>`
    ${flex("row", "space-between", "center")};
    padding: 12px 20px 12px 12px;
    ${props => props.clickable && "cursor: pointer;"};
`;

const TopLeftContainer = styled.div`
    display: flex;
    min-width: 0;
    flex: 1;
    margin-right: 10px;
`;

const AccountInfo = styled("div")`
    ${flex("column", "center", "auto")};
    width: calc(100% - 70px);
    height: 56px;
    font-weight: 500;
    text-align: left;
    flex: 1;
    min-width: 0;
`;

const Title = styled(HideOverflow)`
    ${flex("row", "auto", "center")};
    margin-bottom: 2px;
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;
    color: black;
    svg {
        margin-left: 4px;
    }
`;

const Username = styled.div`
    ${TextEllipsis}
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;
`;

const AccountTheme = styled.div`
    display: flex;
    overflow: hidden;
`;

const Subtitle = styled(HideOverflow)`
    margin-bottom: 4px;
    color: ${colors.primaryGray};
    font-size: ${fonts.smallSubtitle}px;
    line-height: 12px;
`;

const Verified = styled.img`
    min-width: 20px;
`;

const ColorWrapper = styled.div<{ color: string }>`
    border-radius: 8px;
    background-color: ${({ color }) => color};
    & + & {
        margin-top: 10px;
    }
`;

interface Props {
    account?: SocialAccount;
    action?: ReactNode;
    onClick?: () => void;
}

const AccountCell = ({ account, onClick, action }: Props) => {
    const status = useSelector(
        (state: GlobalState) =>
            state.entities.socialAccounts.statuses[account?.id || 0]
    );

    if (!account) return null;

    const followerCount = `${shortenNumber(
        parseInt(account.followers),
        1
    )} Followers`;

    const statusColor = () => {
        if (account?.verified) return colors.primaryBlue;
        if (account?.verificationFailureReason) return colors.primaryRed;
        switch (status?.includes("Error:") ? "failure" : status) {
            case "pending":
                return colors.primaryYellow;
            case "failure":
                return colors.primaryRed;
            default:
                return colors.gray2;
        }
    };

    const statusIcon = () => {
        if (account?.verified) return "/verified_account.svg";
        if (account?.verificationFailureReason) return "/alert_badge.svg";
        switch (status?.includes("Error:") ? "failure" : status) {
            case "pending":
                return "/yellow-pending-outline.svg";
            case "failure":
                return "/alert_badge.svg";
            default:
                return "/unverified-account-shield.svg";
        }
    };

    return (
        <ColorWrapper color={statusColor()}>
            <Main>
                <Section
                    clickable={Boolean(onClick)}
                    onClick={() => {
                        if (onClick) {
                            onClick();
                        }
                    }}
                >
                    <TopLeftContainer>
                        <AccountIcon
                            platform={account.platform}
                            avatar={account.avatar}
                            verified={account.verified}
                        />
                        <AccountInfo>
                            <Title>
                                <Username>@{account.username}</Username>
                            </Title>
                            <Subtitle>{followerCount || "Unknown"}</Subtitle>
                            {account.theme && (
                                <AccountTheme>
                                    <Tag capitalize text={account.theme} />
                                </AccountTheme>
                            )}
                        </AccountInfo>
                    </TopLeftContainer>
                    {action ? action : <Verified src={statusIcon()} />}
                </Section>
            </Main>
        </ColorWrapper>
    );
};

export default AccountCell;
