import React from "react";
import styled from "styled-components";
import { colors } from "../../../constants";

const Icon = styled.svg<{ disabled: boolean }>`
    ${props => !props.disabled && "cursor: pointer;"}
`;

interface Props {
    disabled: boolean;
    sendCallback: VoidFunction;
}

const SendButton = ({ disabled, sendCallback }: Props) => {
    const color = disabled ? colors.mediumTeal : colors.primaryBlue;

    return (
        <Icon
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            disabled={disabled}
            onClick={() => {
                if (!disabled) {
                    sendCallback();
                }
            }}
        >
            <rect width="32" height="32" fill="white" />
            <path
                d="M5.33333 5.33331L26.6667 16L5.33333 26.6666L9.33333 16L5.33333 5.33331Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.3333 15.9999L9.33332 15.9999"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Icon>
    );
};

export default SendButton;
