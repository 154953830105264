import React from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../constants";

interface StyleProps {
    textColor: string;
    borderColor: string;
    background: string;
    disabled?: boolean;
    textTransform?: boolean;
    margin?: string;
    padding?: string;
}

const Main = styled.div<StyleProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 24px;
    padding: ${props => props.padding || "0 6px"};
    margin: ${props => props.margin || "0 6px 6px 0"};
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    text-transform: ${props => props.textTransform && "capitalize"};
    color: ${props => props.textColor};
    border: 1px solid ${props => props.borderColor};
    background: ${props => props.background};
    transition: 0.1s ease-in;
    white-space: nowrap;

    ${props =>
        props.disabled &&
        css`
            opacity: 0.6;
            cursor: not-allowed;
        `}

    @media (hover: hover) {
        :hover {
            background-color: ${props => {
                return props.textColor === "black" && colors.lightTeal;
            }};
            border: ${props => {
                return (
                    props.textColor === "black" &&
                    `${colors.darkTeal} solid 1px`
                );
            }};
        }
    }
`;

const Icon = styled.img`
    padding-left: 4px;
    width: 16px;
    height: 16px;
`;

const Counter = styled.div<{ color: "white" | "black" }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    margin-left: 4px;
    color: ${props => (props.color === "white" ? colors.white : colors.black)};
    background-color: ${props => {
        return props.color === "white" ? colors.black : colors.white;
    }};
    border-radius: 50%;
    font-size: ${fonts.superSmallSubtitle}px;
    text-align: center;
`;

interface Props {
    text: string;
    color: "white" | "black";
    iconSrc: string;
    margin?: string;
    onClick?: () => void;
    padding?: string;
    disabled?: boolean;
    textTransform?: boolean;
    counter?: number;
}

const SearchTag = ({
    text,
    color,
    iconSrc,
    margin,
    padding,
    onClick,
    disabled,
    textTransform = true,
    counter
}: Props) => {
    let textColor;
    let borderColor;
    let background;
    switch (color) {
        case "white":
            textColor = "black";
            borderColor = colors.mediumTeal;
            background = "white";
            break;
        case "black":
            textColor = "white";
            borderColor = "black";
            background = "black";
    }
    return (
        <Main
            textColor={textColor}
            borderColor={borderColor}
            background={background}
            margin={margin}
            padding={padding}
            onClick={() => onClick && !disabled && onClick()}
            disabled={disabled}
            textTransform={textTransform}
        >
            {text}

            {!counter ? (
                <Icon src={iconSrc} />
            ) : (
                <Counter color={color}>{counter}</Counter>
            )}
        </Main>
    );
};

export default SearchTag;
