import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectDirectBuyStepEntities,
    selectDirectBuyStepsExpandedState,
    selectOpenBuyStepEntities,
    selectOpenBuyStepsExpandedState
} from "../../selectors";
import { getDirectBuyStepCollectionArrayForDirectBuy } from "../../modules/direct-buy/util";
import DirectBuyDetailsStepCollection from "./DirectBuyDetailsStepCollection";
import { getTreatmentArrayForDirectBuyStepCollectionArray } from "../../modules/direct-buy-step/util";
import { AnimatePresence } from "framer-motion";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import {
    getOpenBuyStepCollectionArrayForOpenBuy,
    openBuySupplementsForStep
} from "src/buys/modules/open-buys/util";
import { DirectBuyStepCollectionTreatment } from "src/buys/modules/direct-buy-step/types";
import { BigBlueButton } from "src/profile/components/Buttons";
import { directBuyDetailsModal } from "src/ui/direct-buys/actions";
import { DirectBuyDetailsModalState } from "src/ui/direct-buys/reducer";
import styled from "styled-components";
import { colors } from "src/constants";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${colors.lightGray2};
    padding: 12px 16px;
    gap: 12px;
    &:first-child {
        margin-top: 20px;
    }
`;

interface Props {
    openBuy: OpenBuy;
    expanded: boolean;
}

const SubmitButton = styled(BigBlueButton)`
    width: 95%;
    margin: 12px auto;
`;

const DirectBuyDetailsInstructions = ({ openBuy, expanded }: Props) => {
    const dispatch = useDispatch();

    const openBuySteps = useSelector(selectOpenBuyStepEntities);
    const stepsExpandedState = useSelector(selectOpenBuyStepsExpandedState);
    const [stepCollectionArray, setStepCollectionArray] = useState(() =>
        getOpenBuyStepCollectionArrayForOpenBuy(
            openBuy,
            openBuySteps,
            stepsExpandedState
        )
    );

    useEffect(() => {
        const newStepCollectionArray = getOpenBuyStepCollectionArrayForOpenBuy(
            openBuy,
            openBuySteps,
            stepsExpandedState
        );
        setStepCollectionArray(newStepCollectionArray);
    }, [openBuy, openBuySteps, stepsExpandedState]);
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        }
    }, [firstRender]);
    let position = 0;
    return (
        <AnimatePresence>
            {expanded && (
                <Main>
                    {stepCollectionArray.map((collection, index) => {
                        if (openBuySteps[collection.parent].requiresRawMedia)
                            return;
                        position++;
                        collection.supplements = openBuySupplementsForStep(
                            openBuy,
                            collection.parent
                        );
                        return (
                            <DirectBuyDetailsStepCollection
                                key={collection.parent}
                                collection={collection}
                                treatment={
                                    DirectBuyStepCollectionTreatment.active
                                }
                                steps={openBuySteps}
                                stepsExpandedState={stepsExpandedState}
                                firstRender={firstRender}
                                openBuyId={openBuy.id}
                                position={position}
                            />
                        );
                    })}
                </Main>
            )}
        </AnimatePresence>
    );
};

export default DirectBuyDetailsInstructions;
