import { push } from "connected-react-router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChevronRight } from "src/components/icons/exports";
import { colors, fonts } from "src/constants";
import { campaign } from "src/campaigns/schema";
import { Campaign } from "src/campaigns/types";
import { CachedMedia } from "src/notifications/saga";
import { setMediaFilters } from "src/pagination/media/actions";
import { initialMediaFilters } from "src/pagination/media/reducer";
import { GlobalState } from "src/reducers";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 46px;
    align-items: center;
    padding: 0 20px 0 20px;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const Notification = styled.div`
    display: flex;
    background-color: ${props => props.color};
    border-radius: 4px;
    height: 24px;
    min-width: 24px;
    padding: 2px;
    text-align: center;
`;

const Description = styled.div`
    font-size: ${fonts.smallTitle}px;
    margin-left: 14px;
`;

const Button = styled.div`
    margin-left: auto;
`;

const Text = styled.div`
    margin: auto;
    font-size: 12px;
    color: ${colors.white};
`;

const descriptions: any = {
    default: "Your media",
    updates: "Media updates",
    pending: "Media upload pending",
    rejected: "Media upload rejected",
    approved: "Media upload approved"
};

const colorStatus: any = {
    default: colors.tertiaryGray,
    updates: colors.primaryBlue,
    pending: colors.primaryYellow,
    rejected: colors.primaryRed,
    approved: colors.primaryBlue
};

const getStatus = (notifications: CachedMedia[]) => {
    let status: string | null = "default";
    notifications.map((notif: CachedMedia) => {
        if (status !== "default") {
            status = "updates";
            return;
        } else status = notif.status;
    });
    return status;
};

interface Props {
    campaign: Campaign;
}

const MediaStatus = ({ campaign }: Props) => {
    const dispatch = useDispatch();
    const notifications: CachedMedia[] = JSON.parse(
        localStorage.getItem("mediaNotifications") || "[]"
    ).filter((notif: CachedMedia) => notif.campaign === campaign.id);

    const mediaCacheCounts: { [key: number]: number } = JSON.parse(
        localStorage.getItem("mediaCacheCount") || "{}"
    );

    const isLoading = useSelector(
        (state: GlobalState) => state.ui.medias.userMediaLoading
    );
    if (isLoading || !mediaCacheCounts[campaign.id]) return null;
    else
        return (
            <Container
                onClick={() => {
                    dispatch(
                        setMediaFilters({
                            ...initialMediaFilters,
                            selectedCampaigns: {
                                [campaign.id]: campaign.campaignName
                            },
                            isOwner: true
                        })
                    );
                    dispatch(push("/media"));
                }}
            >
                <Notification color={colorStatus[getStatus(notifications)]}>
                    <Text>
                        {notifications.length ||
                            (mediaCacheCounts[campaign.id] >= 99
                                ? "99+"
                                : mediaCacheCounts[campaign.id])}
                    </Text>
                </Notification>
                <Description>
                    {descriptions[getStatus(notifications)]}
                </Description>
                <Button>
                    <ChevronRight fill={colors.primaryGray} />
                </Button>
            </Container>
        );
};

export default MediaStatus;
