import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSubmissionTypeHierarchy } from "src/admin-tools/challenges/util";
import { get } from "src/Api";
import { OpenBuySubmission } from "src/buys/modules/open-buys/types";
import useMultiSelect from "src/components/table/hooks/useMultiSelect";
import PlugTable from "src/components/table/PlugTable";
import { TableColumn, TableRow } from "src/components/table/types";
import { createCell } from "src/components/table/utils";
import withModalPortal from "src/components/withModalPortal";
import LargeModal from "src/modals/LargeModal";
import { BlueButton, GrayButton } from "src/profile/components/Buttons";
import Platforms from "src/social-accounts/Platforms";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";

const Heading = styled.h4`
    font-weight: 500px;
    font-size: 18px;
    text-align: left;
    padding-bottom: 16px;
`;

const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const DoneButton = styled(BlueButton)`
    padding: 8px 12px;
    margin: 12px 4px 0px;
`;

const SelectAllButton = styled(GrayButton)`
    padding: 8px 12px;
    margin: 12px 4px 0px;
`;

const columns: TableColumn[] = [
    {
        coreKey: "submissionId",
        displayValue: "Submission ID",
        columnWidth: "118px"
    },
    {
        coreKey: "status",
        displayValue: "Status",
        columnWidth: "118px"
    },
    {
        coreKey: "type",
        displayValue: "Type",
        columnWidth: "99px"
    },
    {
        coreKey: "publisher",
        displayValue: "Publisher",
        columnWidth: "109px"
    },
    {
        coreKey: "username",
        displayValue: "Username",
        columnWidth: "114px"
    },
    {
        coreKey: "rep",
        displayValue: "Rep",
        columnWidth: "114px"
    }
];

interface Props {
    closeModal: VoidFunction;
    openBuyId: number;
    publisherIDsToExtend: number[];
    setPublisherIDsToExtend: (ids: number[]) => void;
}
const ExtendChallengeModal = ({
    closeModal,
    openBuyId,
    publisherIDsToExtend,
    setPublisherIDsToExtend
}: Props) => {
    const [submissions, setSubmissions] = useState<OpenBuySubmission[]>([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                let res = await get("/v1/admin/openBuySubmission/search", {
                    openBuyId,
                    statuses: ["pending", "revision_requested"],
                    limit: 2000
                });
                setSubmissions(res.data.data.rows);
            } catch (err) {
                console.error(err);
                dispatch(
                    setPillNotificationText(
                        "Failed to load extension candidates",
                        "danger",
                        3500
                    )
                );
            } finally {
                setLoading(false);
            }
        })();
    }, [openBuyId]);

    // Invariant: cellData.originalIdx corresponds to index in both submissions
    // and cellData Array
    const cellData: TableRow[] = submissions.map((submission, originalIdx) => ({
        originalIdx,
        raw: submission,
        rowData: {
            submissionId: createCell.string(submission.id.toString()),
            status: createCell.string(submission.status),
            type: createCell.string(getSubmissionTypeHierarchy(submission)),
            publisher: createCell.string(submission.publisherId.toString()),
            username: createCell.link(
                `@${submission.socialAccount?.username}`,
                Platforms[
                    submission.socialAccount?.platform || ""
                ]?.urlCallback(submission.socialAccount?.username || "")
            ),
            rep: createCell.string(
                submission.creatorRep
                    ? `${submission.creatorRep.firstname} ${submission.creatorRep.lastname}`
                    : null
            )
        }
    }));

    const onMultiSelectUpdate = (selectedRowIdxs: number[]) => {
        setPublisherIDsToExtend(
            selectedRowIdxs.map(idx => cellData[idx].raw.publisherId)
        );
    };
    const multiSelectReference = useMultiSelect(cellData, onMultiSelectUpdate);

    // Initial checkmarks after table content loads
    useEffect(() => {
        if (submissions.length === 0) return;
        let selectedRows: number[] = [];
        for (let i = 0; i < submissions.length; i++) {
            if (publisherIDsToExtend.includes(submissions[i].publisherId)) {
                selectedRows.push(i);
            }
        }
        multiSelectReference.setSelectedRows(selectedRows);
    }, [submissions]);

    return (
        <LargeModal title="Extend Challenge" closeModal={closeModal}>
            <Heading>Select Submissions for Challenge Extension</Heading>
            <PlugTable
                resultsPerPage={8}
                cellData={cellData}
                columns={columns}
                multiSelectReference={multiSelectReference}
                tableLoading={loading}
            />
            <ButtonBox>
                {multiSelectReference.allSelected ? (
                    <SelectAllButton onClick={multiSelectReference.deselectAll}>
                        {`Deselect All (${cellData.length})`}
                    </SelectAllButton>
                ) : (
                    <SelectAllButton
                        onClick={multiSelectReference.selectAll}
                    >{`Select All (${cellData.length})`}</SelectAllButton>
                )}
                <DoneButton onClick={closeModal}>Done</DoneButton>
            </ButtonBox>
        </LargeModal>
    );
};

export default withModalPortal(ExtendChallengeModal);
