import React from "react";
import { Contest } from "../types";
import {
    addTickets,
    combineAndRankOrderPrizes
} from "../utils/contestObjectFilters";
import { WhitePillButton } from "./styledComponents";
import styled from "styled-components";
import { formatNumber } from "../../../util";
import { useDispatch } from "react-redux";
import { setCurrentContest } from "../../../ui/contests/actions";
import { fonts } from "src/constants";

const Main = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-weight: bold;
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;
    text-align: left;
    min-height: 60px;
    padding: 0 15px;

    img {
        margin-right: 5px;
    }
`;

const Subtitle = styled.div`
    font-size: ${fonts.smallSubtitle}px;
`;

interface Props {
    contest: Contest;
    endingSoon: boolean;
    push: (path: string) => void;
}

const DrawerContent = ({ contest, endingSoon, push }: Props) => {
    const dispatch = useDispatch();
    const totalTickets = addTickets(contest);

    if (endingSoon) {
        return (
            <Main>
                <img src="/lightning_icon.svg" />
                This contest will be concluding soon!
            </Main>
        );
    } else if (contest.status === "ended") {
        const [topPrize] = combineAndRankOrderPrizes(
            contest,
            parseInt(localStorage.getItem("userid") || "0")
        );
        if (topPrize && topPrize.winner.coins) {
            return (
                <Main>
                    <div>
                        <div>You’ve won a contest prize!</div>
                        <Subtitle>Claim your prize.</Subtitle>
                    </div>
                    <WhitePillButton
                        onClick={() => {
                            dispatch(setCurrentContest(contest.id));
                            push("/contest/prize");
                        }}
                    >
                        Claim
                    </WhitePillButton>
                </Main>
            );
        } else if (topPrize && topPrize.winner.ticket_number) {
            return (
                <Main>
                    <div>
                        <div>You’ve won a raffle prize!</div>
                        <Subtitle>Claim your prize.</Subtitle>
                    </div>
                    <WhitePillButton
                        onClick={() => {
                            dispatch(setCurrentContest(contest.id));
                            push("/contest/prize?raffle");
                        }}
                    >
                        Claim
                    </WhitePillButton>
                </Main>
            );
        } else if (contest.raffle.some(raffle => raffle.drawing_video_url)) {
            return (
                <Main>
                    <div>
                        <div>Raffle winners have been drawn!</div>
                        <Subtitle>View winners.</Subtitle>
                    </div>
                    <WhitePillButton
                        onClick={() => {
                            dispatch(setCurrentContest(contest.id));
                            push("/contest/prize?raffle");
                        }}
                    >
                        View
                    </WhitePillButton>
                </Main>
            );
        } else if (totalTickets > 0) {
            return (
                <Main>
                    <div>
                        <div>
                            You’ve earned {formatNumber(totalTickets)} tickets!
                        </div>
                        <Subtitle>Stay tuned for the drawing.</Subtitle>
                    </div>
                    <WhitePillButton
                        onClick={() => {
                            dispatch(setCurrentContest(contest.id));
                            push("/contest");
                        }}
                    >
                        View
                    </WhitePillButton>
                </Main>
            );
        } else {
            return (
                <Main>
                    <div>
                        <div>Contest has concluded!</div>
                        <Subtitle>View winners.</Subtitle>
                    </div>
                    <WhitePillButton
                        onClick={() => {
                            dispatch(setCurrentContest(contest.id));
                            push("/contest");
                        }}
                    >
                        View
                    </WhitePillButton>
                </Main>
            );
        }
    } else {
        return null;
    }
};

export default DrawerContent;
