import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SelectionButton from "src/components/SelectionButton";
import { breakpoints, colors, fonts, fontWeight } from "src/constants";
import { getCreatorGroups } from "src/marketplace/creator-groups/selectors.creator-groups";
import {
    CreatorGroup,
    CreatorGroupMember
} from "src/marketplace/creator-groups/types.creator-groups";
import {
    clearButton,
    clearLink,
    clearSpacing,
    hideScrollbar,
    primaryBlueBackgroundHoverState
} from "src/utils/styles/snippets";
import styled from "styled-components";
import SocialAccountProfileModal from "./SocialAccountProfileModal";
import { EventSubmit } from "src/utils/types/form";
import {
    deleteCreatorGroupRequest,
    fetchCreatorGroups,
    updateCreatorGroup
} from "src/marketplace/creator-groups/actions.creator-groups";
import { GlobalState } from "src/reducers";
import withModalPortal from "src/components/withModalPortal";
import { creatorGroupsRoutes } from "src/marketplace/utils/marketplace-routes";

const Main = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const CreateNewGroupButton = styled(Link)`
    ${clearLink()};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    color: ${colors.primaryBlue};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 6px;
    font-size: ${fonts.subtitle}px;
    font-weight: ${fontWeight.default};
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            color: ${colors.primaryBlueDark};
            background-color: ${colors.mediumTeal};
        }
    }
`;

const CreatorGroupsContainer = styled.article`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const CreatorGroupsCount = styled.h4`
    ${clearSpacing()};
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.heavy};
    text-transform: uppercase;
`;

const CreatorGroups = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;

    ${props => props.theme.isMobile && hideScrollbar()};
`;

const SaveButton = styled.button`
    ${clearButton()};
    ${primaryBlueBackgroundHoverState()};
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    padding: 8px 12px;
    color: ${colors.white};
    border-radius: 6px;
    font-size: ${fonts.semiSmallTitle}px;
    font-weight: ${fontWeight.medium};

    @media (max-width: ${breakpoints.mobile}px) {
        align-self: auto;
        width: 100%;
    }
`;

interface SelectedGroups {
    [key: number]: number;
}

interface Props {
    currentCreatorSocialAccountId: number;
}

const SocialAccountAddToGroupModal = ({
    currentCreatorSocialAccountId
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const creatorGroups = useSelector(getCreatorGroups);

    const creatorGroupsEntitiesState = useSelector((state: GlobalState) => {
        return state.entities.creatorGroups.byId;
    });

    // Local State ---------------------------------------------------
    const [creatorGroupIds, setCreatorGroupIds] = useState<SelectedGroups>({});

    // Local Constants -----------------------------------------------

    // Local Functions -----------------------------------------------
    function addGroupToSelectedGroups(id: number): void {
        const newCreatorGroupIds = { ...creatorGroupIds };
        if (!!newCreatorGroupIds[id]) delete newCreatorGroupIds[id];
        else newCreatorGroupIds[id] = id;
        setCreatorGroupIds(newCreatorGroupIds);
    }

    function handleSubmit(event: EventSubmit): void {
        event.preventDefault();
        const creatorGroupIdsArray = Object.values(creatorGroupIds);

        creatorGroups.forEach((creatorGroup: CreatorGroup) => {
            const socialAccountIds = creatorGroup.CreatorGroupMembers.map(
                (member: CreatorGroupMember) => {
                    return member.socialAccountId;
                }
            );

            const currentGroupIsSelected = !!creatorGroupIds[creatorGroup.id];
            const currentCreatorIsInGroup = socialAccountIds.includes(
                currentCreatorSocialAccountId
            );

            if (currentGroupIsSelected && !currentCreatorIsInGroup) {
                dispatch(
                    updateCreatorGroup(
                        creatorGroup.id,
                        {
                            name: creatorGroup.name,
                            socialAccountIds: [
                                ...socialAccountIds,
                                currentCreatorSocialAccountId
                            ]
                        },
                        true
                    )
                );
            } else if (!currentGroupIsSelected && currentCreatorIsInGroup) {
                const socialAccountIdsWithoutCurrentCreator = socialAccountIds.filter(
                    (id: number) => {
                        return id !== currentCreatorSocialAccountId;
                    }
                );
                dispatch(
                    updateCreatorGroup(
                        creatorGroup.id,
                        {
                            name: creatorGroup.name,
                            socialAccountIds: [
                                ...socialAccountIdsWithoutCurrentCreator
                            ]
                        },
                        true
                    )
                );
            }
        });
    }

    // Custom Hooks --------------------------------------------------

    // Effects -------------------------------------------------------
    useEffect(() => {
        dispatch(fetchCreatorGroups());
    }, []);

    useEffect(() => {
        const selectedCreatorGroups: SelectedGroups = {};
        creatorGroups.forEach((creatorGroup: CreatorGroup) => {
            const socialAccountIds = creatorGroup.CreatorGroupMembers.map(
                (member: CreatorGroupMember) => {
                    return member.socialAccountId;
                }
            );
            if (socialAccountIds.includes(currentCreatorSocialAccountId)) {
                selectedCreatorGroups[creatorGroup.id] = creatorGroup.id;
            }
        });
        setCreatorGroupIds(selectedCreatorGroups);
    }, [creatorGroups]);

    // JSX -----------------------------------------------------------
    return (
        <SocialAccountProfileModal
            modalType="groups"
            title="Add To Group"
            subtitle="Select Group(s)"
        >
            <Main onSubmit={handleSubmit}>
                <CreateNewGroupButton
                    to={creatorGroupsRoutes.create}
                    target="_blank"
                >
                    Create New Group
                </CreateNewGroupButton>

                <CreatorGroupsContainer>
                    <CreatorGroupsCount>
                        {creatorGroups.length} GROUPS
                    </CreatorGroupsCount>

                    <CreatorGroups>
                        {creatorGroups.map((creatorGroup: CreatorGroup) => {
                            return (
                                <SelectionButton
                                    text={creatorGroup.name}
                                    subText={`${creatorGroup.CreatorGroupMembers.length} Creators`}
                                    selected={
                                        !!creatorGroupIds[creatorGroup.id]
                                    }
                                    setSelected={() =>
                                        addGroupToSelectedGroups(
                                            creatorGroup.id
                                        )
                                    }
                                />
                            );
                        })}
                    </CreatorGroups>
                </CreatorGroupsContainer>

                <SaveButton type="submit">Save</SaveButton>
            </Main>
        </SocialAccountProfileModal>
    );
};

export default withModalPortal(SocialAccountAddToGroupModal);
