import React, { useEffect, useState } from "react";
import { Campaign, OldCampaign } from "src/campaigns/types";
import SearchBar from "../../components/SearchBar";
import SelectCampaignRow from "../../components/SelectCampaignRow";
import PaddingNoTop from "../../components/PaddingNoTop";
import Header from "../../components/Header";
import NavButton from "../../components/NavButton";
import SimpleNavbar from "../../components/SimpleNavbar";
import FullscreenLoader from "../../components/FullscreenLoader";
import MainContainer from "src/components/MainContainer";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";

interface Props {
    campaigns: Campaign[];
    oldCampaign?: true;
    fetchCampaigns: VoidFunction;
    nextCallback: (campaign: Campaign) => void;
    goBack: VoidFunction;
}

const SelectCampaign = ({
    campaigns,
    oldCampaign,
    fetchCampaigns,
    nextCallback,
    goBack
}: Props) => {
    const [currentCampaign, setCurrentCampaign] = useState<Campaign>();
    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        if (campaigns.length === 0) {
            fetchCampaigns();
        }
    }, [campaigns]);

    const openInProfileCustomAdsSection = useSelector((state: GlobalState) => {
        return state.ui.profile.addCustomMediaModalOpen;
    });

    const navbar = (
        <SimpleNavbar
            title={"Upload Media"}
            left={
                !openInProfileCustomAdsSection && (
                    <NavButton back onClick={goBack} />
                )
            }
            right={
                <NavButton
                    text={"Next"}
                    onClick={() => {
                        if (currentCampaign) {
                            nextCallback(currentCampaign);
                        }
                    }}
                    disabled={!currentCampaign}
                />
            }
        />
    );

    if (campaigns.length === 0) {
        return (
            <>
                {navbar}
                <FullscreenLoader />
            </>
        );
    } else {
        return (
            <>
                {navbar}

                <MainContainer>
                    <Header
                        title={"Select a Campaign"}
                        subtitle={"Which campaign is this ad for?"}
                    />

                    <PaddingNoTop>
                        <SearchBar
                            searchString={searchString}
                            updateSearchString={setSearchString}
                        />
                    </PaddingNoTop>

                    <PaddingNoTop>
                        {campaigns
                            .filter((campaign: Campaign) => {
                                if (searchString.length === 0) {
                                    return true;
                                } else {
                                    const name = campaign.campaignName;

                                    return name
                                        .toLowerCase()
                                        .includes(searchString.toLowerCase());
                                }
                            })
                            .map((campaign: Campaign) => {
                                return (
                                    <SelectCampaignRow
                                        key={campaign.id}
                                        campaign={campaign}
                                        oldCampaign={oldCampaign}
                                        selected={Boolean(
                                            currentCampaign &&
                                                currentCampaign.id ===
                                                    campaign.id
                                        )}
                                        onClick={() =>
                                            setCurrentCampaign(campaign)
                                        }
                                    />
                                );
                            })}
                    </PaddingNoTop>
                </MainContainer>
            </>
        );
    }
};

export default SelectCampaign;
