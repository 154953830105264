import { Action } from "src/types";

export const FETCH_ELASTIC_SEARCH = {
    REQUEST: "FETCH_ELASTIC_SEARCH_REQUEST",
    SUCCESS: "FETCH_ELASTIC_SEARCH_SUCCESS",
    FAILURE: "FETCH_ELASTIC_SEARCH_FAILURE"
};

export const fetchElasticSearch = (
    index: string,
    queryString: string,
    hitsPerPage?: number,
    page?: number,
    country?: string,
    platform?: string
): Action => ({
    type: FETCH_ELASTIC_SEARCH.REQUEST,
    payload: { index, queryString, hitsPerPage, page, country, platform }
});

export const fetchElasticSearchSuccess = (
    indices: string[],
    queryResults: any[]
): Action => ({
    type: FETCH_ELASTIC_SEARCH.SUCCESS,
    payload: { indices, queryResults }
});

export const fetchElasticSearchFailure = (error: string) => ({
    type: FETCH_ELASTIC_SEARCH.FAILURE,
    payload: { error }
});

// FETCH MORE FOR INFINITE SCROLL -----------------------------------
export const FETCH_MORE_SEARCH_HITS = {
    REQUEST: "FETCH_MORE_SEARCH_HITS_REQUEST",
    SUCCESS: "FETCH_MORE_SEARCH_HITS_SUCCESS",
    FAILURE: "FETCH_MORE_SEARCH_HITS_FAILURE"
};

export const fetchMoreSearchHits = (
    index: string,
    queryString: string,
    page?: number,
    country?: string,
    platform?: string
): Action => ({
    type: FETCH_MORE_SEARCH_HITS.REQUEST,
    payload: { index, queryString, page, country, platform }
});

export const fetchMoreSearchHitsSuccess = (
    index: string,
    queryResults: any[]
): Action => ({
    type: FETCH_MORE_SEARCH_HITS.SUCCESS,
    payload: { index, queryResults }
});

export const fetchMoreSearchHitsFailure = (error: string) => ({
    type: FETCH_MORE_SEARCH_HITS.FAILURE,
    payload: { error }
});
