import React from "react";
import styled from "styled-components";

const Main = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
`;

const Icon = styled.img`
    height: 25px;
    width: 27px;
`;

const Title = styled.div`
    font-weight: 600;
    padding: 15px 0px 65px;
`;

const Description = styled.div`
    margin-bottom: 15px;
`;

const VerificationFailed = () => {
    return (
        <Main>
            <Icon src={"/icon_nux_green/icon_nux_green@2x.png"} />
            <Title>Verification Failed</Title>
            <Description>
                After careful review of your account, we have determined that
                you don't currently qualify for The Plug. We wish you the best
                of luck - please contact us after 2 months if your engagement
                has meaningfully changed and you wish to reapply.
            </Description>
            <Description>hi@plugco.in</Description>
        </Main>
    );
};

export default VerificationFailed;
