import React from "react";
import VerticallyCentered from "../../components/VerticallyCentered";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import { flex } from "src/utils/styles/snippets";

const Cell = styled("div")`
    ${flex("column", "space-around", "center")};
    flex: 1;
    padding: 10px 0px;
`;

const Title = styled("div")<{ green?: true }>`
    font-size: 16px;
    font-weight: 600;
    ${props => props.green && `color: ${colors.secondaryGreen};`}
`;

const Subtitle = styled("div")`
    font-size: ${fonts.smallSubtitle}px;
    font-weight: 300;
    color: ${colors.primaryGray};
`;

interface Info {
    title: string;
    subtitle: string;
}

interface Props {
    infos: Info[];
    green?: true;
}

const InfoRow = ({ infos, green }: Props) => {
    return (
        <VerticallyCentered>
            {infos.map((info: Info, index: number) => {
                return (
                    <Cell key={index}>
                        <Title green={green}>{info.title}</Title>
                        <Subtitle>{info.subtitle}</Subtitle>
                    </Cell>
                );
            })}
        </VerticallyCentered>
    );
};

export default InfoRow;
