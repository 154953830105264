import React, { useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import { setIsDeviceMobile } from "src/ui/observer/actions";
import { GlobalState } from "src/reducers";
import { getIsMobile } from "src/utils/functions/selectors";

const ThemeObserver = createGlobalStyle<{ theme: ObserverTheme }>`
    html, body {
        overflow: ${props => props.theme.aModalIsOpen && "hidden"};
    }
`;

interface ObserverTheme {
    isMobile: boolean;
    aModalIsOpen: boolean;
}

interface Props {
    children: React.ReactNode;
}

const Observer = (props: Props) => {
    const { children } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        const deviceIsiPhone = navigator.userAgent.includes("iPhone");
        const deviceIsiPad = navigator.userAgent.includes("iPad");
        const deviceIsAndroid = navigator.userAgent.includes("Android");

        if (deviceIsiPhone || deviceIsiPad || deviceIsAndroid) {
            dispatch(setIsDeviceMobile(true));
        } else {
            dispatch(setIsDeviceMobile(false));
        }
    }, [navigator.userAgent]);

    const { isDeviceMobile, aModalIsOpen } = useSelector(
        (state: GlobalState) => {
            return state.ui.observer;
        }
    );

    const themeProvider = {
        isMobile: isDeviceMobile,
        aModalIsOpen
    };

    return (
        <ThemeProvider theme={themeProvider}>
            <ThemeObserver />
            <>{children}</>
        </ThemeProvider>
    );
};

export default Observer;
