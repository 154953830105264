import React, { useRef, useState } from "react";
import { colors, fonts, fontWeight, sizes } from "src/constants";
import { useCloseModalWhenClickedOutside } from "src/utils/functions/hooks";
import styled from "styled-components";

interface MainProps {
    focused?: boolean;
    selected?: boolean;
}

const Container = styled.div`
    background-color: ${colors.transparent};
    width: 100%;
`;

export const Dropdown = styled.div<MainProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    border: ${props =>
        props.focused
            ? ` 1px solid ${colors.primaryBlue}`
            : `1px solid ${colors.mediumTeal}`};
    width: 100%;
    height: ${sizes.marketplace.paymentSets.inputContainer.height}px;
    padding: 16px;
    background-color: ${colors.white};
    color: ${props =>
        props.selected ? `${colors.black}` : `${colors.primaryGray}`};

    font-weight: ${props =>
        props.selected ? `${fontWeight.medium}` : `${fontWeight.default}`};
    font-size: ${fonts.smallTitle}px;
    line-height: 14px;
    transition: border 0.3s ease-in-out;
    cursor: pointer;
`;

const SelectionCaret = styled.img<{ expanded: boolean }>`
    margin-right: 3px;
    transform: ${props =>
        props.expanded ? "rotate(-180deg)" : "rotate(0deg)"};
    transition: all 0.3s;
`;

const Caret = styled.img`
    margin-right: 10px;
    transform: rotate(-90deg);
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: absolute;
    min-width: 363px;
    /* height: auto; */
    max-height: 372px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(204, 204, 204, 0.25);
    background: ${colors.white};
    z-index: 999;
    overflow: auto;
`;

export const DropdownItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 363px;
    min-height: 62px;
    padding: 23px 12px;
    gap: 10px;
    background: ${colors.white};
    border-radius: 8px;
    cursor: pointer;
    font-weight: ${fontWeight.medium};
    font-size: ${fonts.semiSmallTitle}px;
    line-height: 16px;
    color: ${colors.black};
    text-align: left;

    &:hover {
        background: ${colors.quaternaryLightGray};
    }
`;

interface Props {
    itemList: any[];
    selected: any;
    displayName?: string;
    displayEmptyState: string;
    handleOnClick: (item: any) => void;
    getItemName?: (item: any) => string;
}

const PaymentSetDropdown = ({
    itemList,
    selected,
    displayName,
    displayEmptyState,
    handleOnClick,
    getItemName
}: Props) => {
    const mainRef = useRef(null);
    const [expanded, toggleExpanded] = useState(false);

    useCloseModalWhenClickedOutside(
        mainRef,
        expanded,
        () => toggleExpanded(false),
        [expanded]
    );

    return (
        <Container ref={mainRef} onClick={() => toggleExpanded(!expanded)}>
            <Dropdown
                focused={expanded}
                selected={!!selected}
                onClick={() => toggleExpanded(!expanded)}
            >
                {selected
                    ? displayName
                        ? `${displayName}`
                        : `${selected}`
                    : `${displayEmptyState}`}
                <SelectionCaret expanded={expanded} src={"/caret.svg"} />
            </Dropdown>
            {expanded && (
                <List>
                    {itemList.map((item, idx) => {
                        return (
                            <DropdownItem
                                key={idx}
                                onClick={() => {
                                    handleOnClick(item);
                                    toggleExpanded(!expanded);
                                }}
                            >
                                {getItemName ? getItemName(item) : item}
                                <Caret src={"/caret.svg"} />
                            </DropdownItem>
                        );
                    })}
                </List>
            )}
        </Container>
    );
};

export default PaymentSetDropdown;
