import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../reducers";
import PlugLinkModal from "../../campaigns/modals/PlugLinkModal";
import MediaEmailModal from "../../campaigns/components/MediaEmailModal";
import CustomReasonModal from "../../campaigns/medias/components/CustomReasonModal";
import { CampaignsModal } from "src/ui/modals/reducers";

interface Props {
    modal: CampaignsModal;
}

const CampaignModals = ({ modal }: Props) => {
    switch (modal) {
        case CampaignsModal.pluglink:
            return <PlugLinkModal />;
        case CampaignsModal.mediaEmail:
            return <MediaEmailModal />;
        case CampaignsModal.customReason:
            return <CustomReasonModal />;
        default:
            return null;
    }
};

const mapStateToProps = (state: GlobalState) => ({
    modal: state.ui.modals.modal
});

export default connect(mapStateToProps)(CampaignModals);
