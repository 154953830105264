import { combineReducers } from "redux";
import { NormalizedAction } from "src/types";
import { Criteria } from "../types";
import { FETCH_CRITERIA } from "./actions";

interface ById {
    [id: number]: Criteria;
}

export interface CriteriaEntitiesState {
    byId: ById;
    allIds: number[];
}

const initialCriteriaState: CriteriaEntitiesState = {
    byId: {},
    allIds: []
};

const byId = (state = initialCriteriaState.byId, action: NormalizedAction) => {
    switch (action.type) {
        case FETCH_CRITERIA.SUCCESS:
            return { ...state, ...action.response.entities.criteria };
        default:
            return state;
    }
};

const allIds = (
    state = initialCriteriaState.allIds,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_CRITERIA.SUCCESS:
            return action.response.result;
        default:
            return state;
    }
};

const criteriaEntitiesReducer = combineReducers<CriteriaEntitiesState>({
    byId,
    allIds
});

export default criteriaEntitiesReducer;
