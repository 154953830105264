import { breakpoints } from "src/constants";
import styled from "styled-components";

const SearchWrapper = styled.div`
    padding: 20px 15px 15px;

    @media (max-width: ${breakpoints.tablet}px) {
        padding: 15px;
    }

    @media (max-width: ${breakpoints.mobile}px) {
        padding: ${props => props.theme.isMobile && "15px 15px 5px"};
    }
`;

export default SearchWrapper;
