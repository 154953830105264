import React, { useEffect, useState } from "react";
import { SocialAccount } from "../../../types";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import NavButton from "../../../../components/NavButton";
import FormHeader from "../form/FormHeader";
import TagTitle from "../form/TagTitle";
import TagContainer from "../form/TagContainer";
import Platforms from "src/social-accounts/Platforms";
import FormTag from "../form/FormTag";
import Themes from "src/social-accounts/Themes";
import { ProfileModal } from "../../../reducer";
import VerifyModal from "../modals/VerifyModal";
import { postAccount, PostAccountParams } from "../../../actions";
import PrivacyAccountModal from "../modals/PrivacyAccountModal";
import { clearModal } from "../../../../modals/actions";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../../reducers";
import { useHistory } from "react-router";
import MainContainer from "src/components/MainContainer";
import FullScreenModal from "src/modals/FullScreenModal";
import {
    addAccountModalOpen,
    clearProfileModals,
    editAccountModalOpen
} from "src/ui/profile/actions";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import { colors, fonts } from "src/constants";
import AccountEditConfirmationModal from "./AccountEditConfirmationModal";

const Close = styled.img`
    width: auto;
    height: 14px;
    cursor: pointer;
`;

const Error = styled.div`
    background: ${colors.errorPink};
    padding: 8px;
    font-size: ${fonts.smallSubtitle}px;
    width: 100%;
`;

interface Props {
    currentAccount?: SocialAccount;
}

const AccountFormModal = ({ currentAccount }: Props) => {
    const modal = useSelector((state: GlobalState) => {
        return state.profile.modal;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();

    function dispatchPostAccount(payload: PostAccountParams): void {
        dispatch(postAccount(payload));
    }

    function dispatchClearModal(): void {
        dispatch(clearModal());
    }

    const [username, setUsername] = useState("");
    const [followers, setFollowers] = useState("");
    const [visibility, setVisibility] = useState<SocialAccount["visibility"]>(
        "visible"
    );
    const [platform, setPlatform] = useState("");
    const [theme, setTheme] = useState("");
    const [error, setError] = useState("");
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const isAddAccountModalOpen = useSelector((state: GlobalState) => {
        return state.ui.profile.addAccountModalOpen;
    });

    const isEditAccountModalOpen = useSelector((state: GlobalState) => {
        return state.ui.profile.editAccountModalOpen;
    });

    useEffect(() => {
        if (isAddAccountModalOpen) {
            setUsername("");
            setFollowers("");
            setVisibility("visible");
            setPlatform("");
            setTheme("");
        } else if (
            isEditAccountModalOpen &&
            currentAccount &&
            currentAccount.theme
        ) {
            setUsername(currentAccount.username);
            setFollowers(currentAccount.followers);
            setVisibility(currentAccount.visibility);
            setPlatform(currentAccount.platform);
            setTheme(currentAccount.theme);
        }
    }, [isAddAccountModalOpen, isEditAccountModalOpen, currentAccount]);

    useEffect(() => {
        if (error) {
            const timeId = setTimeout(() => {
                setError("");
            }, 3000);

            return () => clearTimeout(timeId);
        }
    }, [error]);

    function closeModal(): void {
        dispatch(editAccountModalOpen(false));
        dispatch(addAccountModalOpen(false));
    }

    function submitDisabled() {
        return !Boolean(username && followers && platform && theme);
    }

    function submitForm() {
        setConfirmationModalOpen(false);
        if (!submitDisabled()) {
            dispatchPostAccount({
                id: currentAccount && currentAccount.id,
                username,
                followers,
                visibility,
                platform,
                theme
            });
            // closeModal();
            dispatch(clearProfileModals());
        }
    }

    return (
        <AnimatePresence>
            {(isAddAccountModalOpen || isEditAccountModalOpen) && (
                <FullScreenModal closeModal={closeModal}>
                    <SimpleNavbar
                        left={
                            <Close
                                src="/close.svg"
                                alt="close button"
                                onClick={closeModal}
                            />
                        }
                        title={currentAccount ? "Edit Account" : "Add Account"}
                        right={
                            <NavButton
                                text="Done"
                                disabled={submitDisabled()}
                                onClick={
                                    currentAccount?.verified &&
                                    username !== currentAccount.username
                                        ? () => setConfirmationModalOpen(true)
                                        : submitForm
                                }
                            />
                        }
                    />

                    <MainContainer>
                        {error && <Error>{error}</Error>}
                        <AccountEditConfirmationModal
                            visible={confirmationModalOpen}
                            closeModal={() => setConfirmationModalOpen(false)}
                            onConfirmation={submitForm}
                        />
                        <FormHeader
                            platform={platform}
                            username={username}
                            setUsername={setUsername}
                            followers={followers}
                            setFollowers={setFollowers}
                            visibility={visibility}
                            currentAccount={currentAccount}
                        />
                        <TagTitle>Platform</TagTitle>
                        <TagContainer>
                            {Object.keys(Platforms).map(platformKey => {
                                return (
                                    <FormTag
                                        key={platformKey}
                                        text={Platforms[platformKey].title}
                                        selected={platformKey === platform}
                                        onClick={
                                            currentAccount?.verified
                                                ? () =>
                                                      setError(
                                                          "Cannot change Platform on Verified account."
                                                      )
                                                : () => {
                                                      setPlatform(platformKey);
                                                  }
                                        }
                                    />
                                );
                            })}
                        </TagContainer>
                        <TagTitle>Theme</TagTitle>
                        <TagContainer>
                            {Object.keys(Themes).map(themeKey => {
                                return (
                                    <FormTag
                                        key={themeKey}
                                        text={Themes[themeKey].title}
                                        selected={themeKey === theme}
                                        onClick={
                                            currentAccount?.verified
                                                ? () =>
                                                      setError(
                                                          "Cannot change Theme on Verified account."
                                                      )
                                                : () => {
                                                      setTheme(themeKey);
                                                  }
                                        }
                                    />
                                );
                            })}
                        </TagContainer>
                        {modal === ProfileModal.verify && currentAccount && (
                            <VerifyModal account={currentAccount} />
                        )}
                        {modal === ProfileModal.privacyAccount && (
                            <PrivacyAccountModal
                                account={currentAccount}
                                cancelCallback={dispatchClearModal}
                                doneCallback={newVisibility => {
                                    setVisibility(newVisibility);
                                    dispatchClearModal();
                                }}
                            />
                        )}
                    </MainContainer>
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default AccountFormModal;
