import React from "react";
import SearchBar from "src/components/SearchBar";
import { colors, fonts, fontWeight } from "src/constants";
import {
    BlueButton,
    GrayButton,
    RedButton
} from "src/profile/components/Buttons";
import { clearButton } from "src/utils/styles/snippets";
import styled from "styled-components";
import { FiltersReference } from "../hooks/useFilters";
import { SearchReference } from "../hooks/useSearch";
import { TableButton } from "../PlugTable";
import TableFilters from "./TableFilters";

const Header = styled.header`
    padding: 16px;
    isolation: isolate;
    z-index: 1;
`;
const TitleWrap = styled.div<{ noMarginBottom: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${props => (props.noMarginBottom ? "0px" : "14px")};
`;
const Title = styled.h2<{ titleSize?: number }>`
    font-size: ${props => (props.titleSize ? props.titleSize : fonts.title)}px;
    font-weight: ${fontWeight.medium};
    text-align: left;
    margin: 0px;
`;
const BtnWrap = styled.div<{ marginLeftAuto: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    ${props => props.marginLeftAuto && `margin-left: auto;`}
`;
const StyledSearchBar = styled(SearchBar)`
    margin-bottom: 12px;
`;
const TableRedBtn = styled(RedButton)`
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
`;
const TableBlueBtn = styled(BlueButton)`
    padding: 8px 12px;
    font-size: 13px;
    font-weight: 500;
`;
const TableGrayBtn = styled(GrayButton)`
    border-radius: 6px;
    padding: 8px 12px;
`;

interface Props {
    searchReference: SearchReference;
    filtersReference: FiltersReference | null;
    title?: string;
    titleSize?: number;
    tableButtons?: TableButton[];
}
const TableHeader = ({
    searchReference,
    filtersReference,
    title,
    titleSize,
    tableButtons
}: Props) => {
    const { isSearchable, searchString, setSearchString } = searchReference;
    const renderButton = (btn: TableButton, idx: number) => {
        if (btn.color === "red") {
            return (
                <TableRedBtn
                    key={btn.text + idx}
                    onClick={btn.onClick}
                    disabled={btn.disabled}
                    type={btn.type}
                >
                    {btn.text}
                </TableRedBtn>
            );
        }
        if (btn.color === "blue") {
            return (
                <TableBlueBtn
                    key={btn.text + idx}
                    onClick={btn.onClick}
                    disabled={btn.disabled}
                    type={btn.type}
                >
                    {btn.text}
                </TableBlueBtn>
            );
        }
        return (
            <TableGrayBtn
                key={btn.text + idx}
                onClick={btn.onClick}
                disabled={btn.disabled}
                type={btn.type}
            >
                {btn.text}
            </TableGrayBtn>
        );
    };

    return (
        <Header>
            {title || tableButtons ? (
                <TitleWrap noMarginBottom={!isSearchable && !filtersReference}>
                    {title ? (
                        <Title titleSize={titleSize}>{title}</Title>
                    ) : null}
                    {tableButtons && tableButtons.length > 0 ? (
                        <BtnWrap marginLeftAuto={!title}>
                            {tableButtons.map(renderButton)}
                        </BtnWrap>
                    ) : null}
                </TitleWrap>
            ) : null}
            {isSearchable && (
                <StyledSearchBar
                    searchString={searchString}
                    updateSearchString={setSearchString}
                    autoFocus={false}
                />
            )}
            {filtersReference && (
                <TableFilters filtersReference={filtersReference} />
            )}
        </Header>
    );
};

export default TableHeader;
