import React from "react";
import styled from "styled-components";

import ReferredUsers from "src/profile/components/ReferredUsers";
import Header from "src/components/Header";
import PaddingNoTop from "src/components/PaddingNoTop";
import { colors } from "src/constants";

import ReferralLinkButton from "./ReferralLinkButton";
import { getLoggedInProfile } from "src/profile/selectors";
import { useDispatch, useSelector } from "react-redux";
import { RedButton } from "src/profile/components/Buttons";
import { usernameModal } from "src/profile/actions";

const StarIcon = styled.img`
    height: 16px;
    width: 16px;
`;

const Description = styled.div`
    display: flex;
    padding: 5px 0 15px;
    margin-bottom: 13px;
    align-items: center;
`;

const DescriptionText = styled.div`
    margin-left: 8px;
    color: ${colors.black};
    font-size: 0.75rem;
    line-height: 0.875rem;
    letter-spacing: -0.009625rem;
    text-align: left;
`;

const UpdateUsernameButton = styled(RedButton)`
    width: 100%;
    height: 48px;
    margin: 6px;
    border-radius: 6px;
`;

const ReferralCard = () => {
    const dispatch = useDispatch();
    const profile = useSelector(getLoggedInProfile);
    const hasUsername = Boolean(
        profile &&
            typeof profile.username === "string" &&
            profile.username.length > 0
    );
    return (
        <>
            <Header
                title={"Referral Bonus"}
                subtitle={"Invite friends to join the Plug"}
            />

            <PaddingNoTop>
                <Description>
                    <StarIcon src={"/star/star@2x.png"} />
                    <DescriptionText>
                        Refer creators to the Plug and get 15% of whatever they
                        earn as a bonus for the first 12 months.
                    </DescriptionText>
                </Description>

                {hasUsername ? (
                    <ReferralLinkButton />
                ) : (
                    <UpdateUsernameButton
                        onClick={() => dispatch(usernameModal())}
                    >
                        Add a username to your profile in order to make refferal
                        links
                    </UpdateUsernameButton>
                )}
            </PaddingNoTop>

            <ReferredUsers />
        </>
    );
};

export default ReferralCard;
