import { Action, NormalizedAction } from "../types";
import {
    Access,
    InstallHistory,
    Budget,
    CampaignFilters,
    Statistic
} from "./types";
import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../reducers";

// All Campaigns ---------------------------------------------------------------

export const FETCH_CAMPAIGNS = {
    REQUEST: "FETCH_CAMPAIGNS_NEW_REQUEST",
    SUCCESS: "FETCH_CAMPAIGNS_NEW_SUCCESS",
    FAILURE: "FETCH_CAMPAIGNS_NEW_FAILURE"
};

export const fetchCampaigns = (
    sortBy?: string,
    filters?: CampaignFilters
): Action => ({
    type: FETCH_CAMPAIGNS.REQUEST,
    payload: { sortBy, filters }
});

export const fetchCampaignsSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>
): NormalizedAction => ({
    type: FETCH_CAMPAIGNS.SUCCESS,
    response
});

export const fetchCampaignsFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGNS.FAILURE,
    payload: { error }
});

// Single Campaign -------------------------------------------------------------

export const FETCH_CAMPAIGN = {
    REQUEST: "FETCH_CAMPAIGN_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_FAILURE"
};

export const fetchCampaign = (
    campaignId: number,
    mediaId?: number,
    scriptId?: number
): Action => ({
    type: FETCH_CAMPAIGN.REQUEST,
    payload: { campaignId, mediaId, scriptId }
});

export const fetchCampaignSuccess = (
    response: NormalizedSchema<EntitiesState, number>
): NormalizedAction => ({
    type: FETCH_CAMPAIGN.SUCCESS,
    response
});

export const fetchCampaignFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN.FAILURE,
    payload: { error }
});

// Campaign Price --------------------------------------------------------------

export const FETCH_CAMPAIGN_PRICE = {
    REQUEST: "FETCH_CAMPAIGN_PRICE_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_PRICE_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_PRICE_FAILURE"
};

export const fetchCampaignPrice = (): Action => ({
    type: FETCH_CAMPAIGN_PRICE.REQUEST,
    payload: null
});

export const fetchCampaignPriceSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { campaignId: number }
): NormalizedAction => ({
    type: FETCH_CAMPAIGN_PRICE.SUCCESS,
    response,
    payload
});

export const fetchCampaignPriceFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_PRICE.FAILURE,
    payload: { error }
});

// Campaign Metrics ------------------------------------------------------------

export const FETCH_CAMPAIGN_METRICS = {
    REQUEST: "FETCH_CAMPAIGN_METRICS_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_METRICS_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_METRICS_FAILURE"
};

export const fetchCampaignMetrics = (): Action => ({
    type: FETCH_CAMPAIGN_METRICS.REQUEST,
    payload: null
});

export const fetchCampaignMetricsSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>,
    payload: { campaignId: number }
): NormalizedAction => ({
    type: FETCH_CAMPAIGN_METRICS.SUCCESS,
    response,
    payload
});

export const fetchCampaignMetricsFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_METRICS.FAILURE,
    payload: { error }
});

// Campaign Install History ----------------------------------------------------

export const FETCH_CAMPAIGN_INSTALL_HISTORY = {
    REQUEST: "FETCH_CAMPAIGN_INSTALL_HISTORY_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_INSTALL_HISTORY_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_INSTALL_HISTORY_FAILURE"
};

export const fetchCampaignInstallHistory = (campaignId: number): Action => ({
    type: FETCH_CAMPAIGN_INSTALL_HISTORY.REQUEST,
    payload: { campaignId }
});

export const fetchCampaignInstallHistorySuccess = (
    campaignId: number,
    installHistory: InstallHistory
): Action => ({
    type: FETCH_CAMPAIGN_INSTALL_HISTORY.SUCCESS,
    payload: { campaignId, installHistory }
});

export const fetchCampaignInstallHistoryFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_INSTALL_HISTORY.FAILURE,
    payload: { error }
});

// Campaign Access -------------------------------------------------------------

export const FETCH_CAMPAIGN_ACCESS = {
    REQUEST: "FETCH_CAMPAIGN_ACCESS_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_ACCESS_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_ACCESS_FAILURE"
};

export const fetchCampaignAccess = (campaignId: number): Action => ({
    type: FETCH_CAMPAIGN_ACCESS.REQUEST,
    payload: { campaignId }
});

export const fetchCampaignAccessSuccess = (
    campaignId: number,
    access: Access
): Action => ({
    type: FETCH_CAMPAIGN_ACCESS.SUCCESS,
    payload: { campaignId, access }
});

export const fetchCampaignAccessFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_ACCESS.FAILURE,
    payload: { error }
});

// Request Campaign Access -----------------------------------------------------

export const REQUEST_CAMPAIGN_ACCESS = "REQUEST_CAMPAIGN_ACCESS";

export const requestCampaignAccess = (campaignId: number): Action => ({
    type: REQUEST_CAMPAIGN_ACCESS,
    payload: { campaignId }
});

// Request Campaign Notification -----------------------------------------------

export const REQUEST_CAMPAIGN_NOTIFICATION = "REQUEST_CAMPAIGN_NOTIFICATION";

export const requestCampaignNotification = (campaignId: number): Action => ({
    type: REQUEST_CAMPAIGN_NOTIFICATION,
    payload: { campaignId }
});

// Budget -------------------------------------------------------------

export const FETCH_CAMPAIGN_BUDGET = {
    REQUEST: "FETCH_CAMPAIGN_BUDGET_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_BUDGET_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_BUDGET_FAILURE"
};

export const fetchCampaignBudget = (campaignId: number): Action => ({
    type: FETCH_CAMPAIGN_BUDGET.REQUEST,
    payload: { campaignId }
});

export const fetchCampaignBudgetSuccess = (
    campaignId: String,
    budget: Budget
): Action => ({
    type: FETCH_CAMPAIGN_BUDGET.SUCCESS,
    payload: { campaignId, budget }
});

export const fetchCampaignBudgetFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_BUDGET.FAILURE,
    payload: { error }
});

// Email Publisher Media ---------------------------------------------------------------

export const EMAIL_PUBLISHER_MEDIA = {
    REQUEST: "EMAIL_PUBLISHER_MEDIA_REQUEST",
    SUCCESS: "EMAIL_PUBLISHER_MEDIA_SUCCESS",
    FAILURE: "EMAIL_PUBLISHER_MEDIA_FAILURE"
};

export const emailPublisherMedia = (media_id: number) => ({
    type: EMAIL_PUBLISHER_MEDIA.REQUEST,
    payload: { media_id }
});

export const emailPublisherMediaFailure = (error: string) => ({
    type: EMAIL_PUBLISHER_MEDIA.FAILURE,
    payload: { error }
});

// Campaign Statistics ---------------------------------------------------------
export const FETCH_CAMPAIGN_STATISTICS = {
    REQUEST: "FETCH_CAMPAIGN_STATISTICS_REQUEST",
    SUCCESS: "FETCH_CAMPAIGN_STATISTICS_SUCCESS",
    FAILURE: "FETCH_CAMPAIGN_STATISTICS_FAILURE"
};

export const fetchCampaignStatistics = (campaignId: number): Action => ({
    type: FETCH_CAMPAIGN_STATISTICS.REQUEST,
    payload: { campaignId }
});

export const fetchCampaignStatisticsSuccess = (
    response: { result: Statistic },
    payload: { campaignId: number }
): NormalizedAction => ({
    type: FETCH_CAMPAIGN_STATISTICS.SUCCESS,
    response,
    payload
});

export const fetchCampaignStatisticsFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGN_STATISTICS.FAILURE,
    payload: { error }
});
