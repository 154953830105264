import React from "react";
import { AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";
import HalfScreenModal from "src/modals/HalfScreenModal";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors, fonts } from "src/constants";
import { fetchCampaigns } from "src/campaigns/actions";
import { incrementCampaignMaxItems } from "src/ui/campaigns/actions";

interface Props {
    onClose: () => void;
    onSelect: (value: string) => void;
    open: boolean;
}

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
`;

const Title = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 700;
    white-space: nowrap;
    padding-bottom: 20px;
`;

const Option = styled.div`
    display: flex;
    flex-direction: row;
    font-size: ${fonts.subtitle}px;
    justify-content: space-between;
    padding: 26px 20px 26px 20px;
    border-bottom: 1px solid ${colors.mediumTeal};
    cursor: pointer;
`;

const LinkListNewLinkSelector = ({ onClose, onSelect, open }: Props) => {
    const dispatch = useDispatch();
    const options = [
        { value: "campaign", name: "Plug Campaign" },
        { value: "social", name: "Social Media Handle" },
        { value: "referral", name: "Your Referral Link" },
        { value: "other", name: "Other" }
    ];
    return (
        <AnimatePresence>
            {open && (
                <HalfScreenModal closeModal={onClose}>
                    <Header>
                        <Title>Add Link</Title>
                    </Header>
                    {options.map((option, index) => (
                        <Option
                            key={`option: ${index}`}
                            onClick={() => {
                                if (option.value === "campaign") {
                                    dispatch(incrementCampaignMaxItems(30));
                                    dispatch(fetchCampaigns());
                                }
                                onSelect(option.value);
                            }}
                        >
                            {option.name}
                            <FontAwesomeIcon icon="chevron-right" />
                        </Option>
                    ))}
                </HalfScreenModal>
            )}
        </AnimatePresence>
    );
};

export default LinkListNewLinkSelector;
