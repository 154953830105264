import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import { BlueButton } from "./Buttons";
import { OutlineButton } from "./Buttons";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
    color: ${colors.primaryGray};
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

const ReloadButton = styled(BlueButton)`
    height: 46px;
    width: 136px;
    margin-top: 16px;
`;

const LogoutButton = styled(OutlineButton)`
    height: 46px;
    width: 136px;
    margin-top: 16px;
`;

interface Props {
    reloadCallback: VoidFunction;
}

const ReloadProfile = ({ reloadCallback }: Props) => {
    const dispatch = useDispatch();

    return (
        <Main>
            <div>Couldn’t load profile, try again!</div>
            <ButtonWrapper>
                <ReloadButton onClick={reloadCallback}>Reload</ReloadButton>
                <LogoutButton onClick={() => dispatch(push("/logout"))}>
                    Logout
                </LogoutButton>
            </ButtonWrapper>
        </Main>
    );
};

export default ReloadProfile;
