import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";

import HalfScreenModal from "src/modals/HalfScreenModal";
import { fonts, colors } from "src/constants";
import { TextLoading } from "src/components/SkeletonLoader";
import { List } from "src/campaigns/details/Details.styled";
import { getSelected } from "src/campaigns/utils/sortFilterUtils";
import Divider from "src/components/Divider";
import { Country, Selected } from "src/campaigns/types";
import { selectCountries } from "src/ui/campaigns/selectors";
import { GlobalState } from "src/reducers";

const Title = styled(motion.div)`
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fonts.subtitle}px;
    font-weight: 700;
`;

const RegionList = styled(motion.div)`
    border: 1px solid ${colors.mediumTeal};
    border-radius: 10px;
    margin: 20px 16px;
    max-height: 30vh;
    overflow-y: auto;

    & > * ~ * {
        border-top: 1px solid ${colors.mediumTeal};
    }
`;

const Error = styled.div`
    color: ${colors.primaryRed};
    font-size: 12px;
`;

const Cancel = styled.div`
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
`;

const Save = styled(Cancel)`
    background-color: ${colors.primaryBlue};
    color: white;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

const RegionItem = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const Checkbox = styled.img``;

interface Props {
    localClearModal: () => void;
    keepFrozenOnClose?: true;
    regions: string[];
    setRegionConfig: (selectedRegions: string[], allRegions: boolean) => void;
}

const CampaignRegionModal = ({
    localClearModal,
    keepFrozenOnClose,
    regions, // global regions in store
    setRegionConfig
}: Props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState<Selected>({});
    const countries = useSelector((state: GlobalState) =>
        selectCountries(state)
    );
    const [allRegionSelected, setAllRegions] = useState(
        regions.length === countries.length || !regions.length
    );

    useEffect(() => {
        if (countries.length > 0) setIsLoaded(true);
        if (regions.length) setSelectedCountries(getSelected(regions));
    }, []);

    useEffect(() => {
        if (allRegionSelected) {
            const allCountries = countries.map(country => country.code);
            setSelectedCountries(getSelected(allCountries));
        }
    }, [allRegionSelected]);

    const toggleAllRegions = () => {
        setAllRegions(!allRegionSelected);
        setSelectedCountries({});
    };

    const toggleSelection = (country: string) => {
        if (allRegionSelected) toggleAllRegions();

        const nextState = { ...selectedCountries };
        if (selectedCountries[country]) {
            delete nextState[country];
        } else {
            nextState[country] = true;
        }
        setSelectedCountries(nextState);
    };

    const saveAndClose = () => {
        setRegionConfig(Object.keys(selectedCountries), allRegionSelected);
        localClearModal();
    };

    return (
        <HalfScreenModal
            closeModal={localClearModal}
            keepFrozenOnClose={keepFrozenOnClose}
        >
            <Title layout="position" transition={{ duration: 0.3 }}>
                Select Regions
            </Title>
            {!isLoaded ? (
                <List>
                    <TextLoading height={41} />
                    <TextLoading height={41} />
                    <TextLoading height={41} />
                    <TextLoading height={41} />
                </List>
            ) : (
                <>
                    {!allRegionSelected &&
                        !Object.keys(selectedCountries).length && (
                            <Error>Please select at least one region</Error>
                        )}
                    <RegionList>
                        <RegionItem onClick={toggleAllRegions}>
                            Select All
                            {allRegionSelected ? (
                                <Checkbox src={"/checkbox_selected.svg"} />
                            ) : (
                                <Checkbox src={"/checkbox_unselected.svg"} />
                            )}
                        </RegionItem>
                    </RegionList>
                    <RegionList
                        layout="position"
                        transition={{ duration: 0.3 }}
                    >
                        {countries.map((country: Country, idx: number) => (
                            <RegionItem
                                key={idx}
                                onClick={() => toggleSelection(country.code)}
                            >
                                {country.name}
                                {selectedCountries[country.code] ? (
                                    <Checkbox src={"/checkbox_selected.svg"} />
                                ) : (
                                    <Checkbox
                                        src={"/checkbox_unselected.svg"}
                                    />
                                )}
                            </RegionItem>
                        ))}
                    </RegionList>
                </>
            )}
            <motion.div layout="position" transition={{ duration: 0.3 }}>
                <Divider />
                {Object.keys(selectedCountries).length || allRegionSelected ? (
                    <Save onClick={saveAndClose}>Done</Save>
                ) : (
                    <Cancel onClick={localClearModal}>Cancel</Cancel>
                )}
            </motion.div>
        </HalfScreenModal>
    );
};

export default CampaignRegionModal;
