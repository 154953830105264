import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";

export const getDelayedCampaigns = createSelector(
    (state: GlobalState) => state.entities.delayedCampaigns,
    delayedCampaignsState => {
        return delayedCampaignsState.allIds.map(
            id => delayedCampaignsState.byId[id]
        );
    }
);

export const selectDelayedCampaign = (state: GlobalState, campaignId: number) =>
    state.entities.delayedCampaigns.byId[campaignId];
