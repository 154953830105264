import { combineReducers } from "redux";
import { Action } from "src/types";
import { ById } from "src/utils/types/general";
import { CREATE_OFFER } from "./actions.create-offer";

export interface OffersEntitiesState {
    byId: ById<any>;
    allIds: number[];
}

const initialOffersEntitiesState: OffersEntitiesState = {
    byId: {},
    allIds: []
};

function byId(
    state = initialOffersEntitiesState.byId,
    action: Action
): ById<any> {
    switch (action.type) {
        case CREATE_OFFER.SUCCESS:
            return { ...state, ...action.payload.offers.entities.offers };

        default:
            return state;
    }
}

function allIds(
    state = initialOffersEntitiesState.allIds,
    action: Action
): number[] {
    switch (action.type) {
        case CREATE_OFFER.SUCCESS:
            return [...state, ...action.payload.offers.result];

        default:
            return state;
    }
}

const offersEntitiesReducer = combineReducers<OffersEntitiesState>({
    byId,
    allIds
});

export default offersEntitiesReducer;
