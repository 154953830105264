import React from "react";
import ResizingTextArea from "src/components/ResizingTextArea";
import { colors, fonts, fontWeight } from "src/constants";
import styled from "styled-components";

const StyledMarketplaceInput = styled(ResizingTextArea)`
    width: 100%;
    padding: 16px;
    color: ${colors.black};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.default};
    outline: none;
    transition: 0.1s ease-in;
    resize: none;

    :focus {
        border: ${colors.primaryBlue} solid 1px;
    }

    ::placeholder {
        color: ${colors.primaryGray};
    }
`;

const MarketplaceTextarea = ({
    value,
    handleChange,
    placeholder,
    autoFocus,
    className
}: {
    value: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    className?: string;
    autoFocus?: boolean;
}) => {
    return (
        <StyledMarketplaceInput
            className={className}
            autoFocus={autoFocus}
            value={value}
            onChange={(e: any) => handleChange(e)}
            placeholder={placeholder || ""}
        />
    );
};

export default MarketplaceTextarea;
