import React from "react";
import ReactMarkdown from "react-markdown";
import { colors } from "src/constants";
import remarkGfm from "remark-gfm";
import styled from "styled-components";

const ScriptTextWrap = styled.div`
    background-color: ${colors.secondaryLightGray};
    padding: 20px 16px;
    border-radius: 8px;
    overflow-y: auto;
    text-align: initial;
    overflow-y: auto;
    p {
        margin-bottom: 8px;
    }
`;
const ScriptPreview = ({
    text,
    className
}: {
    text?: string;
    className?: string;
}) => {
    return (
        <ScriptTextWrap className={className}>
            <ReactMarkdown remarkPlugins={[remarkGfm]} linkTarget="_blank">
                {text || "There is no text in this script"}
            </ReactMarkdown>
        </ScriptTextWrap>
    );
};

export default ScriptPreview;
