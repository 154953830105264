import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { selectCampaign } from "../../selectors";
import MediasAccordion from "../../details/accordions/MediasAccordion";
import { getScriptMedias, selectScriptModalCampaign } from "../selectors";
import { fetchScriptMedias } from "../../medias/actions";
import withAccordion from "../../details/accordions/withAccordion";
import { setScriptModalView } from "src/ui/scripts/actions";

const mapStateToProps = (state: GlobalState) => ({
    name: "script",
    currentId: state.ui.script.scriptModal,
    campaign: selectCampaign(state) || selectScriptModalCampaign(state),
    mediasLoading: state.ui.script.mediasLoading,
    medias: getScriptMedias(state),
    scriptView: state.ui.script.scriptView
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchMedias: (scriptId: number) => dispatch(fetchScriptMedias(scriptId)),
    setScriptModalView: (tag: string) => dispatch(setScriptModalView(tag))
});

export default withAccordion(
    connect(mapStateToProps, mapDispatchToProps)(MediasAccordion)
);
