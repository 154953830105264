import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { colors, sizes } from "src/constants";
import SelectionHeader from "src/marketplace/components/SelectionHeader";
import {
    InstructionStepState,
    setInstructionSteps
} from "src/ui/instructions-sets/actions";
import styled from "styled-components";

const Main = styled.div``;

interface DropdownProps {
    expanded: boolean;
    itemCount?: number;
}

const Dropdown = styled.div<DropdownProps>`
    display: flex;
    flex-direction: column;
    height: ${props => {
        if (props.expanded) {
            return props.itemCount
                ? `${sizes.campaigns.selectionRow.height *
                      (props.itemCount + 1)}px`
                : `${sizes.campaigns.selectionRow.height * 6}px`;
        } else {
            return `${sizes.campaigns.selectionRow.height}px`;
        }
    }};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 8px;
    transition: 0.1s ease-in;
    overflow-y: ${props => (props.expanded ? "auto" : "hidden")};
    cursor: pointer;
`;

interface Props {
    currentInstructionStep: InstructionStepState;
    instructionSteps: InstructionStepState[];
    currentInstructionStepIndex: number;
}

const instructionStepDropdownItems = [
    "requiresCustomMedia",
    "requiresRawMedia",
    "requiresLinkSubmission",
    "requiresTextSubmission"
] as const;
const InstructionSubmissionTypeSelector = ({
    currentInstructionStep,
    instructionSteps,
    currentInstructionStepIndex
}: Props) => {
    const [expanded, setExpanded] = useState(false);
    const [selectedType, setSelectedType] = useState("");
    const dispatch = useDispatch();

    const determineSelectedType = () => {
        instructionStepDropdownItems.map(item => {
            if (currentInstructionStep[item] === true) {
                setSelectedType(item);
            }
        });
    };

    useEffect(() => {
        determineSelectedType();
    }, [currentInstructionStep]);

    const setInstructionStepSubmissionType = (type: string) => {
        const updatedInstructionSteps = Array.from(instructionSteps);
        const currentStep =
            updatedInstructionSteps[currentInstructionStepIndex];
        instructionStepDropdownItems.map(item => {
            if (type === item) {
                currentStep[item] = true;
                setSelectedType(item);
            } else {
                currentStep[item] = false;
            }
        });
        if (type === "requiresRawMedia") {
            currentStep["requiresCustomMedia"] = true;
        }
        setExpanded(false);
        dispatch(setInstructionSteps(updatedInstructionSteps));
    };
    return (
        <Main>
            <Dropdown expanded={expanded}>
                <SelectionHeader
                    title={selectedType || "No submission"}
                    selected={expanded}
                    onClick={() => setExpanded(!expanded)}
                />
                {instructionStepDropdownItems.map(item => (
                    <SelectionHeader
                        title={item}
                        selected={item === selectedType}
                        onClick={() => setInstructionStepSubmissionType(item)}
                    />
                ))}
            </Dropdown>
        </Main>
    );
};
export default InstructionSubmissionTypeSelector;
