import styled from "styled-components";
import { colors, fonts } from "../../constants";
import { TextEllipsis } from "../CommonStyles";

const Subtitle = styled.div`
    ${TextEllipsis}
    font-size: ${fonts.subtitle}px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: ${colors.primaryGray};
`;

export default Subtitle;
