import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../../reducers";
import { Connection, PublicProfileData } from "../../../types";
import { replace } from "connected-react-router";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import NavButton from "../../../../components/NavButton";
import Tab from "../../../../components/Tab";
import FullscreenLoader from "../../../../components/FullscreenLoader";
import ConnectionsPageTab from "../../components/ConnectionsPageTab";
import { fetchConnectionsPublic } from "../../../actions";
import PaddingAround from "../../../../components/PaddingAround";
import XCard from "../../../components/XCard";
import MainContainer from "src/components/MainContainer";
import { useHistory } from "react-router";
import Navbar from "src/components/Navbar";
import styled from "styled-components";
import { breakpoints, colors, maxWidthDesktop, sizes } from "src/constants";

const Tabs = styled.div`
    display: flex;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${maxWidthDesktop}px) {
        margin: 0px;
        border: none;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

export enum PublicConnectionsTabState {
    all,
    mutual
}

interface Props {
    publicData?: PublicProfileData;
    tabState: PublicConnectionsTabState;
}

const PublicConnectionsPage = ({ publicData, tabState }: Props) => {
    const recommendations = useSelector((state: GlobalState) => {
        return state.profile.recommendations;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();

    function dispatchFetchConnectionsPublic(connection: Connection): void {
        dispatch(fetchConnectionsPublic(connection));
    }

    function dispatchReplace(route: string): void {
        dispatch(replace(route));
    }

    const navbar = (
        <SimpleNavbar
            title={"CONNECTIONS"}
            left={<NavButton back onClick={goBack} />}
        />
    );

    if (publicData && publicData.allConnections === null) {
        return (
            <>
                <Navbar back marginBottom={20} title="CONNECTIONS" />

                <MainContainer>
                    <PaddingAround>
                        <XCard
                            text="Failed to load connections, click the button below to try again."
                            button={{
                                text: "Try again",
                                callback: () => {
                                    dispatchFetchConnectionsPublic(
                                        publicData.connection
                                    );
                                }
                            }}
                        />
                    </PaddingAround>
                </MainContainer>
            </>
        );
    } else if (
        !(
            publicData &&
            publicData.allConnections &&
            publicData.mutualConnections
        )
    ) {
        return (
            <>
                <Navbar back marginBottom={20} title="CONNECTIONS" />
                <FullscreenLoader />
            </>
        );
    } else {
        const userid = publicData.connection.other.id.slice(2);
        const allConnections = publicData.allConnections;
        const mutualConnections = publicData.mutualConnections;

        return (
            <>
                <Navbar back marginBottom={20} title="CONNECTIONS" />

                <MainContainer>
                    {mutualConnections.length > 0 && (
                        <Tabs>
                            <Tab
                                name={"All"}
                                active={
                                    tabState === PublicConnectionsTabState.all
                                }
                                onClick={() => {
                                    dispatchReplace(
                                        `/public/${userid}/connections`
                                    );
                                }}
                            />
                            <Tab
                                name={"Mutual"}
                                active={
                                    tabState ===
                                    PublicConnectionsTabState.mutual
                                }
                                onClick={() => {
                                    dispatchReplace(
                                        `/public/${userid}/connections/mutual`
                                    );
                                }}
                            />
                        </Tabs>
                    )}

                    {mutualConnections.length === 0 ||
                    tabState === PublicConnectionsTabState.all ? (
                        <ConnectionsPageTab connections={allConnections} />
                    ) : (
                        <ConnectionsPageTab
                            connections={mutualConnections}
                            recommendations={recommendations}
                        />
                    )}
                </MainContainer>
            </>
        );
    }
};

export default PublicConnectionsPage;
