import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { setCurrentModalAssets } from "../../../ui/assets/actions";
import { Asset } from "../../types";
import {
    HorizontalScrollInner,
    HorizontalScrollOuter
} from "../Details.styled";
import AssetCell from "./AssetCell";
import { AddAssetCell } from "./AddCell";

interface Props {
    assets: Asset[];
    isScriptModal?: boolean;
    isAdmin?: boolean;
    carouselRef: React.RefObject<any>;
}

const AssetList = ({ assets, isScriptModal, isAdmin, carouselRef }: Props) => {
    const dispatch = useDispatch();

    function assetClicked(index: number) {
        const newList = [
            ...assets.slice(index, assets.length),
            ...assets.slice(0, index)
        ];
        dispatch(setCurrentModalAssets(newList.map(el => el.id)));
    }
    return (
        <>
            <HorizontalScrollOuter
                ref={carouselRef}
                bottomBorder="0px"
                topBorder="0px"
            >
                <HorizontalScrollInner>
                    {isScriptModal && isAdmin && (
                        <AddAssetCell caption="Add Asset to Script" />
                    )}
                    {assets.map((asset, idx) => (
                        <AssetCell
                            key={idx}
                            asset={asset}
                            onMediaClick={() => assetClicked(idx)}
                            isScriptModal={isScriptModal}
                        />
                    ))}
                </HorizontalScrollInner>
            </HorizontalScrollOuter>
        </>
    );
};

export default AssetList;
