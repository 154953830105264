import React, { ReactNode } from "react";
import styled from "styled-components";
import CircleIcon from "../../components/CircleIcon";
import { FlexVerticallyCentered } from "../../components/CommonStyles";
import { colors, fonts } from "../../constants";
import { BigOutlineButton } from "../components/Buttons";

const Section = styled.div`
    padding: 13px 18px 20px;

    & > * ~ * {
        margin-top: 10px;
    }

    & ~ & {
        border-top: 1px solid ${colors.mediumTeal};
    }
`;

const SectionHeader = styled.div`
    ${FlexVerticallyCentered}
    justify-content: space-between;
    text-align: left;
`;

const Title = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 3px;
`;

const Description = styled.div`
    font-size: 12px;
    color: ${colors.primaryGray};
`;

interface Props {
    title: string;
    description: string;
    finished: boolean;
    item: ReactNode;
    create: string;
    onCreate: () => void;
}

const RateFormSection = ({
    title,
    description,
    finished,
    item,
    create,
    onCreate
}: Props) => {
    return (
        <Section>
            <SectionHeader>
                <div>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </div>
                {finished && (
                    <CircleIcon type="check" color={colors.primaryGreen} />
                )}
            </SectionHeader>
            {finished ? (
                item
            ) : (
                <BigOutlineButton onClick={onCreate}>{create}</BigOutlineButton>
            )}
        </Section>
    );
};

export default RateFormSection;
