import React, { useEffect, useState } from "react";
import { goBack } from "connected-react-router";
import styled from "styled-components";
import SortBy from "../components/SortBy";
import SearchBox from "../components/SearchBox";
import { TabState } from "./index";
import SearchTabs from "./SearchTabs";
import { colors, maxWidthDesktop } from "../../constants";
import { useDispatch } from "react-redux";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";
import { fetchElasticSearch } from "../actions";

const InputRow = styled.div`
    padding: 15px 0px;
    display: flex;
    align-items: center;

    & > * ~ * {
        margin-left: 15px;
    }

    @media (max-width: ${maxWidthDesktop}px) {
        padding: 15px;
    }
`;

const SearchWrapper = styled.div`
    flex: 1;
`;

const Sticky = styled.div`
    position: sticky;
    top: 0;
    z-index: 998;
    user-select: none;
    background-color: ${colors.white};
`;

interface Props {
    currentTab: TabState;
}

export const tabToIndex = (tab: TabState) => {
    switch (tab) {
        case TabState.users:
            return "publishers";
        case TabState.accounts:
            return "social-accounts";
        case TabState.campaigns:
            return "campaigns";
        default:
            return "campaigns, publishers, social-accounts";
    }
};

const SearchMain = ({ currentTab }: Props) => {
    const [queryString, setQueryString] = useState("");
    const [debouncedQuery, setDebouncedQuery] = useState(queryString);

    const dispatch = useDispatch();
    const queryIndex = tabToIndex(currentTab);

    useEffect(() => {
        const timer = setTimeout(() => setQueryString(debouncedQuery), 500);
        return () => clearTimeout(timer);
    }, [debouncedQuery]);

    useEffect(() => {
        dispatch(
            fetchElasticSearch(
                "campaigns, publishers, social-accounts",
                queryString
            )
        );
    }, [queryString]);

    return (
        <>
            <Sticky>
                <Navbar back />

                <MainContainer>
                    <InputRow>
                        <SearchWrapper>
                            <SearchBox
                                queryString={debouncedQuery}
                                setQueryString={setDebouncedQuery}
                            />
                        </SearchWrapper>
                        {/* <Link link={{ text: "Cancel", onClick: dispatch(goBack) }} /> */}
                    </InputRow>

                    <SortBy
                        items={[
                            {
                                value: "dev_publisher",
                                tab: TabState.all,
                                label: "All",
                                route: "/search",
                                event: null
                            },
                            {
                                value: "dev_campaign",
                                tab: TabState.campaigns,
                                label: "Campaigns",
                                route: "/search/campaigns",
                                event: "search_campaigns_tapped"
                            },
                            {
                                value: "dev_publisher",
                                tab: TabState.users,
                                label: "Users",
                                route: "/search/users",
                                event: "search_users_tapped"
                            },
                            {
                                value: "dev_social_account",
                                tab: TabState.accounts,
                                label: "Accounts",
                                route: "/search/accounts",
                                event: "search_accounts_tapped"
                            }
                        ]}
                        currentTab={currentTab}
                    />
                </MainContainer>
            </Sticky>

            <MainContainer>
                <SearchTabs currentTab={currentTab} />
            </MainContainer>
        </>
    );
};

export default SearchMain;
