import React, { ChangeEvent } from "react";
import { colors } from "../../../constants";
import VerticallyCentered from "../../../components/VerticallyCentered";
import ResizingTextArea from "../../../components/ResizingTextArea";
import SendButton from "./SendButton";
import UploadButton from "./UploadButton";

interface Props {
    message: string;
    setMessage: (message: string) => void;
    typingCallback: VoidFunction;
    uploadCallback: (e: ChangeEvent<HTMLInputElement>) => void;
    sendCallback: VoidFunction;
}

const MessageInput = ({
    message,
    setMessage,
    typingCallback,
    uploadCallback,
    sendCallback
}: Props) => {
    return (
        <VerticallyCentered>
            <UploadButton uploadCallback={uploadCallback} />
            <ResizingTextArea
                placeholder="Write a message..."
                value={message}
                onChange={e => {
                    typingCallback();
                    setMessage(e.currentTarget.value);
                }}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        sendCallback();
                    }
                }}
                style={{
                    backgroundColor: colors.lightTeal,
                    border: `1px solid ${colors.mediumTeal}`,
                    borderRadius: "6px",
                    padding: "12px",
                    margin: "0 15px 0"
                }}
            />
            <SendButton
                disabled={message.length === 0}
                sendCallback={sendCallback}
            />
        </VerticallyCentered>
    );
};

export default MessageInput;
