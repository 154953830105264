import React from "react";
import Header from "../../../components/Header";
import { BigOutlineButton } from "../../components/Buttons";
import PaddingNoTop from "../../../components/PaddingNoTop";
import AccountCell from "../components/AccountCell";
import {
    fetchAccountsUser,
    PushAccountParams,
    pushWithAccount,
    pushWithAccountData
} from "../../actions";
import { Profile, SocialAccount } from "../../types";
import CardLoader from "../../components/CardLoader";
import { GlobalState } from "../../../reducers";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import PaddingAround from "../../../components/PaddingAround";
import XCard from "../../components/XCard";
import PlusCard from "../../components/PlusCard";
import { getAccounts } from "../selectors";
import {
    addAccountModalOpen,
    editAccountModalOpen,
    viewAccountModalOpen
} from "src/ui/profile/actions";
import { colors } from "src/constants";
import styled from "styled-components";

const SeeMore = styled(BigOutlineButton)`
    color: ${colors.white};
    background: ${colors.primaryBlue};
    margin-top: 15px;
`;
interface Props {
    profile: Profile;
}

const AccountsCard = ({ profile }: Props) => {
    const accounts = useSelector((state: GlobalState) => {
        return state.profile.accounts;
    });

    const dispatch = useDispatch();

    function dispatchFetchAccountsUser(publisherId: number): void {
        dispatch(fetchAccountsUser(publisherId));
    }

    function dispatchPush(route: string): void {
        dispatch(push(route));
    }

    function dispatchPushWithAccount(data: PushAccountParams): void {
        dispatch(pushWithAccount(data));
    }

    if (accounts === null) {
        return (
            <PaddingAround>
                <XCard
                    text="Failed to load accounts, click the button below to try again."
                    button={{
                        text: "Try again",
                        callback: () => {
                            dispatchFetchAccountsUser(profile.id);
                        }
                    }}
                />
            </PaddingAround>
        );
    } else if (!accounts) {
        return <CardLoader />;
    } else if (accounts.length === 0) {
        return (
            <>
                <Header title={"Accounts"} subtitle={"0 Accounts"} />
                <PaddingNoTop>
                    <PlusCard
                        text="You don’t have any social accounts, click the button below to add accounts."
                        button={{
                            text: "Add Account",
                            callback: () => {
                                dispatchPush("/profile/accounts/add");
                            }
                        }}
                    />
                </PaddingNoTop>
            </>
        );
    } else {
        const verified = accounts.reduce((acc, account) => {
            if (account.verified) {
                return acc + 1;
            } else {
                return acc;
            }
        }, 0);

        const unverified = accounts.reduce((acc, account) => {
            if (account.verified) {
                return acc;
            } else {
                return acc + 1;
            }
        }, 0);

        const openAccountViewModal = (account: SocialAccount): void => {
            dispatch(viewAccountModalOpen(true));
            dispatch(pushWithAccountData(account));
        };

        const openAddAccountFormModal = (): void => {
            dispatch(addAccountModalOpen(true));
            dispatch(editAccountModalOpen(false));
        };

        return (
            <>
                <Header
                    title={"Accounts"}
                    subtitle={
                        verified === 1
                            ? `${verified} social account connected to the Plug.`
                            : `${verified} social accounts connected to the Plug.`
                    }
                    link={{
                        text: "View more",
                        onClick: () => dispatchPush("/profile/accounts")
                    }}
                />
                <PaddingNoTop>
                    {accounts.slice(0, 3).map(account => {
                        return (
                            <AccountCell
                                key={account.id}
                                account={account}
                                push={dispatchPush}
                                pushWithAccount={dispatchPushWithAccount}
                                privacyIcon
                                showRates
                            />
                        );
                    })}
                </PaddingNoTop>
                <PaddingNoTop>
                    <BigOutlineButton onClick={openAddAccountFormModal}>
                        Add Account
                    </BigOutlineButton>
                    <SeeMore
                        style={{
                            background: colors.primaryBlue,
                            color: colors.white
                        }}
                        onClick={() => dispatchPush("/profile/accounts")}
                    >
                        See More
                    </SeeMore>
                </PaddingNoTop>
            </>
        );
    }
};

export default AccountsCard;
