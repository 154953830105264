import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { withSendBird } from "sendbird-uikit";
import { useLocation } from "react-router";
import * as Icons from "src/components/icons/exports";
import { colors, breakpoints, maxWidth, sizes } from "src/constants";
import NavbarDesktopLink from "./NavbarDesktopLink";
import { flex, flexRowCenter } from "src/utils/styles/snippets";
import InternalLink from "./buttons/InternalLink";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import ProfilePhoto from "src/components/ProfilePhoto";
import { useIntercom } from "react-use-intercom";
import NavbarDesktopLogoDropdown from "./NavbarDesktopLogoDropdown";
import { selectAdminStatus } from "src/auth/selectors";
import { getLoggedInProfile } from "src/profile/selectors";
import { AccessStatuses } from "src/profile/types";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";
import { selectLayoutTheme } from "src/ui/layout/selectors";
import { LayoutThemeType } from "src/ui/layout/actions";

const Navbar = styled("nav")<{ theme: LayoutThemeType }>`
    ${flexRowCenter()};
    width: 100%;
    height: ${sizes.navbar.desktopHeight}px;
    background-color: ${props =>
        props.theme === LayoutThemeType.light
            ? colors.white
            : colors.darkBackground};
    border-bottom: ${colors.secondaryLightGray} solid 2px;
    position: fixed;
    top: 0;

    @media (max-width: ${breakpoints.tablet}px) {
        display: none;
    }
`;

const NavbarDesktopMaxWidth = styled("div")`
    ${flex("row", "space-between", "center")}
    width: 100%;
    max-width: ${maxWidth.navbar}px;
    height: 100%;
    margin: 0px auto;

    @media (max-width: ${maxWidth.navbar + 40}px) {
        padding: 0px 20px;
    }
`;
const Logo = styled(RouterLink)`
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 16px;
    align-items: center;
    text-decoration: none;

    :hover {
        text-decoration: none;
    }
`;

const LogoSymbol = styled.img`
    width: 40px;
    height: 40px;
`;

interface LogoTextProps {
    isMarketplace: boolean;
}

const LogoText = styled.img<LogoTextProps>`
    height: ${props => (props.isMarketplace ? 40 : 16)}px;
`;

const NavigationLinks = styled("article")`
    ${flex("row", "center", "center")};
    gap: 5px;
`;

const MessageProfile = styled("section")`
    ${flex("row", "auto", "center")};
    gap: 12px;
    align-items: center;
`;

const buttonStyles = css`
    ${flexRowCenter()};
    position: relative;
    width: 40px;
    height: 40px;
    background-color: ${colors.secondaryLightGray};
    border: ${colors.transparent} solid 1px;
    border-radius: 10000000000rem;
    outline: none;
    cursor: pointer;
    transition: 0.1s ease-in;

    :hover {
        color: ${colors.primaryGray};
        border: ${colors.darkTeal} solid 1px;
        text-decoration: none;
    }
`;

const CircleLink = styled(InternalLink)`
    ${buttonStyles};
`;
const CircleButton = styled.div`
    ${buttonStyles};
`;

const IconContainer = styled("div")`
    ${flexRowCenter()};
`;

const SupportButton = styled.button`
    ${buttonStyles};
`;

const ContactIcon = styled.img`
    width: 16px;
    height: 16px;
`;

interface Props {
    sdk: any;
}

const NavbarDesktop = (props: Props) => {
    const { sdk } = props;

    const theme = useSelector(selectLayoutTheme);

    const [unreadMessagesCount, setUnreadMessagesCount] = useState<
        number | null
    >(null);

    useEffect(() => {
        if (unreadMessagesCount === null && sdk.getTotalUnreadMessageCount) {
            sdk.getTotalUnreadMessageCount((totalCount: number) => {
                setUnreadMessagesCount(totalCount);
            });
        }
    }, [sdk]);

    useEffect(() => {
        if (sdk.UserEventHandler) {
            const UserEventHandler = new sdk.UserEventHandler();

            UserEventHandler.onTotalUnreadMessageCountUpdated = (
                totalCount: number
            ) => {
                setUnreadMessagesCount(totalCount);
            };

            const uniqueHandlerId = `${localStorage.getItem(
                "userid"
            )}-${Date.now()}`;

            sdk.addUserEventHandler(uniqueHandlerId, UserEventHandler);

            return () => sdk.removeUserEventHandler(uniqueHandlerId);
        }
    }, [sdk]);

    const location = useLocation();
    const currentPathname = location.pathname.slice(1);

    const isAdminTools = currentPathname.includes("admin-tools");
    const isMarketplace =
        isAdminTools || currentPathname.includes("marketplace");

    const profilePhotoUrl = useSelector((state: GlobalState) => {
        return state.profile.profile?.profile_photo_url;
    });

    const highlightsSplit = useSelector(
        selectFeatureFlags(featureFlags.ShowHighlights)
    );

    const { boot, update, showNewMessages } = useIntercom();
    function handleContactClick(): void {
        update({ customAttributes: { disable_chat_bot: false } });
        showNewMessages();
    }

    function scrollToTop(): void {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    const isEmployee = useSelector(selectAdminStatus);

    const profile = useSelector(getLoggedInProfile);

    const MainAppNavigationLinks = (
        <NavigationLinks>
            <NavbarDesktopLink
                to="/"
                isPath={
                    currentPathname === "" || currentPathname === "campaign"
                }
                text="Earn"
            />

            {profile?.accessStatus === AccessStatuses.ALL && (
                <NavbarDesktopLink
                    to="/analytics"
                    isPath={currentPathname === "analytics"}
                    text="Analytics"
                />
            )}
            {highlightsSplit && (
                <NavbarDesktopLink
                    to="/highlights"
                    isPath={currentPathname === "highlights"}
                    text="Highlights"
                />
            )}
            <NavbarDesktopLink
                to="/notifications"
                isPath={currentPathname === "notifications"}
                text="Notifications"
            />
        </NavigationLinks>
    );

    const MarketplaceNavigationLinks = (
        <NavigationLinks>
            <NavbarDesktopLink
                to="/marketplace/your-challenges/"
                isPath={currentPathname.includes("your-challenges")}
                text="Your Challenges"
            />
        </NavigationLinks>
    );

    const AdminToolsNavigationLinks = (
        <NavigationLinks>
            <NavbarDesktopLink
                to="/admin-tools/your-challenges/"
                isPath={currentPathname.includes("your-challenges")}
                text="Your Challenges"
            />
            <NavbarDesktopLink
                to="/admin-tools/creator-search"
                isPath={currentPathname.includes("creator-search")}
                text="Creator Search"
            />
            <NavbarDesktopLink
                to="/admin-tools/plug-standards"
                isPath={currentPathname.includes("plug-standards")}
                text="Plug Media Standards"
            />
        </NavigationLinks>
    );

    return (
        <Navbar theme={theme}>
            <NavbarDesktopMaxWidth>
                {isEmployee ? (
                    <NavbarDesktopLogoDropdown
                        isMarketplace={isMarketplace}
                        isAdminTools={isAdminTools}
                    />
                ) : (
                    <Logo to="/" onClick={scrollToTop}>
                        <LogoSymbol
                            src={
                                isMarketplace
                                    ? isAdminTools
                                        ? "/marketplace-logo-symbol.svg"
                                        : "/marketplace-logo-symbol.svg"
                                    : "/logo.svg"
                            }
                        />
                        <LogoText
                            src={
                                isMarketplace
                                    ? isAdminTools
                                        ? ""
                                        : "/marketplace-logo-text.svg"
                                    : "/logo-text.svg"
                            }
                            isMarketplace={isMarketplace}
                        />
                    </Logo>
                )}

                {isMarketplace
                    ? isAdminTools
                        ? AdminToolsNavigationLinks
                        : MarketplaceNavigationLinks
                    : MainAppNavigationLinks}

                <MessageProfile>
                    <CircleLink to="/profile">
                        <ProfilePhoto
                            profilePhotoUrl={profilePhotoUrl || ""}
                            photoSize={36}
                        />
                    </CircleLink>

                    {/* <FeatureFlagGate treatmentName="Instructions">
                        <CreateNewOfferButton />
                    </FeatureFlagGate> */}

                    <CircleButton
                        onClick={() => {
                            boot({
                                customAttributes: {
                                    disable_chat_bot: false
                                }
                            });
                            showNewMessages();
                        }}
                    >
                        <IconContainer>
                            <Icons.Message
                                height={16}
                                fill={colors.primaryGray}
                            />
                        </IconContainer>
                    </CircleButton>

                    <CircleLink to="/profile/settings">
                        <IconContainer>
                            <Icons.Settings
                                height={16}
                                fill={colors.primaryGray}
                            />
                        </IconContainer>
                    </CircleLink>

                    <SupportButton onClick={handleContactClick}>
                        <IconContainer>
                            <ContactIcon src="/navbar-contact.svg" />
                        </IconContainer>
                    </SupportButton>
                </MessageProfile>
            </NavbarDesktopMaxWidth>
        </Navbar>
    );
};

export default withSendBird(NavbarDesktop, (state: any) => ({
    sdk: state.stores.sdkStore.sdk
}));
