import { Action } from "src/types";
import {
    HIDDEN_SCREENSHOTS,
    ASSIGNED_SCREENSHOTS,
    PUBLIC_BUYS,
    SCREENSHOTS
} from "./actions";

export interface PBPreview {
    mediaId: number;
    campaignId: number;
    accountId: number;
    username: string;
    platform: string;
    campaignName: string;
    imageUrl: string;
    screenshots: string;
    url: string;
    coverPhotoUrl: string;
    type: string;
    directBuyId: number;
    offerApprovedAt: string;
    screenshotUrl?: string;
    directBuyStepId: number;
    rejectionReason?: string;
}

export interface PublicBuysScreenshot {
    publisherId?: number;
    screenshotUrl?: string;
    uploadedBy?: string;
    status?: string;
    id?: number;
    createdAt?: string;
}

export interface PublicBuysState {
    buys: PBPreview[];
    screenshots: PublicBuysScreenshot[];
    hidden: string[];
    assigned: { screenshotId: number; directBuyStepId: number }[];
    loading: boolean;
}

const initialPublicBuysState: PublicBuysState = {
    buys: [],
    screenshots: [],
    hidden: [],
    assigned: [],
    loading: false
};

const publicBuysReducer = (state = initialPublicBuysState, action: Action) => {
    switch (action.type) {
        case PUBLIC_BUYS.REQUEST:
            return { ...state, loading: true };
        case PUBLIC_BUYS.SET:
            return { ...state, buys: action.payload, loading: false };
        case PUBLIC_BUYS.UPDATE:
            let temp = JSON.parse(JSON.stringify(state.buys));
            temp.forEach((buy: PBPreview, index: number) => {
                if (buy.directBuyStepId === action.payload.directBuyStepId)
                    temp[index] = action.payload;
            });
            return { ...state, buys: temp };

        case SCREENSHOTS.SET:
            return { ...state, screenshots: action.payload };
        case SCREENSHOTS.UPDATE:
            let temp2 = JSON.parse(JSON.stringify(state.screenshots));
            temp2.forEach((screenshot: PublicBuysScreenshot, index: number) => {
                if (screenshot.id === action.payload.id)
                    temp2[index] = action.payload;
            });
            return { ...state, screenshots: temp2 };
        case HIDDEN_SCREENSHOTS.SET:
            return { ...state, hidden: action.payload };
        case HIDDEN_SCREENSHOTS.REMOVE:
            return {
                ...state,
                hidden: state.hidden.filter(url => url !== action.payload)
            };
        case HIDDEN_SCREENSHOTS.CLEAR:
            return { ...state, assigned: [] };
        case ASSIGNED_SCREENSHOTS.ADD:
            return {
                ...state,
                assigned: state.assigned.concat([action.payload])
            };
        case ASSIGNED_SCREENSHOTS.REMOVE:
            return {
                ...state,
                assigned: state.assigned.filter(
                    entry => entry.directBuyStepId !== action.payload
                )
            };
        case ASSIGNED_SCREENSHOTS.CLEAR:
            return { ...state, assigned: [] };
        default:
            return state;
    }
};

export default publicBuysReducer;
