import { CLAIM_PRIZE, FETCH_CONTEST } from "../../campaigns/contests/actions";
import { Action } from "../../types";
import { SET_CURRENT_CONTEST } from "./actions";

export interface ContestsUIState {
    currentContest: null | number;
    contestsLoading: boolean;
}

const initialContestsUIState: ContestsUIState = {
    currentContest: null,
    contestsLoading: true
};

const contestsUIReducer = (state = initialContestsUIState, action: Action) => {
    switch (action.type) {
        case FETCH_CONTEST.REQUEST:
        case CLAIM_PRIZE.SUCCESS:
            return { ...state, contestsLoading: true };
        case FETCH_CONTEST.SUCCESS:
            return { ...state, contestsLoading: false };
        case SET_CURRENT_CONTEST:
            return { ...state, currentContest: action.payload.contestId };
        default:
            return state;
    }
};

export default contestsUIReducer;
