import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Campaign } from "src/campaigns/types";
import LargeModal from "src/modals/LargeModal";
import XCard from "src/profile/components/XCard";
import { GlobalState } from "src/reducers";
import { setScriptCreate, setWarning } from "src/ui/scripts/actions";
import styled from "styled-components";
import { createScript } from "../actions";
import ModalHeader from "../components/ModalHeader";
import ScriptTextEditor from "../components/ScriptTextEditor";
import { WarningModal } from "./ScriptModal";
import Warning from "./Warning";

const Wrapper = styled.div`
    height: 80vh;
    display: grid;
    grid-template-rows: auto 1fr;
`;
const StyledModalHeader = styled(ModalHeader)`
    padding-bottom: 6px;
`;

const ScriptCreationModal = ({
    campaign
}: {
    campaign: Campaign | undefined;
}) => {
    const dispatch = useDispatch();
    const currentPublisher = useSelector(
        (state: GlobalState) => state.auth.userId
    );

    const isWarning = useSelector(
        (state: GlobalState) => state.ui.script.warning
    );
    const [showWarningModal, setShowWarningModal] = useState(false);
    const warningModal: WarningModal = {
        title: "Unsaved Script",
        message:
            "This script has not been created. If you leave the page your changes will be lost.",
        shouldShow: showWarningModal,
        btnText: { exit: "Leave Page", cancel: "Stay" },
        handleExit: () => {
            dispatch(setWarning(false));
            dispatch(setScriptCreate(false));
        }
    };

    const handleExit = () => {
        const isSelection = window.getSelection();
        if (isSelection && isSelection.isCollapsed) {
            if (!isWarning) {
                dispatch(setScriptCreate(false));
            } else {
                setShowWarningModal(true);
            }
        }
    };

    if (!campaign || !currentPublisher) {
        return (
            <LargeModal closeModal={handleExit}>
                <XCard text="Something went wrong. Please refresh the page and try again" />
            </LargeModal>
        );
    }

    const emptyScript = {
        id: 0,
        title: "",
        text: "",
        campaignId: campaign.id,
        createdAt: "",
        shouldShowInApp: true,
        uploadedByPublisherId: currentPublisher
    };

    return (
        <LargeModal closeModal={handleExit}>
            <Wrapper>
                <StyledModalHeader campaign={campaign} title="Create Script" />
                <ScriptTextEditor
                    placeholders={{
                        title: "Enter Script Title",
                        text: "Enter Script Text"
                    }}
                    submitActionCreator={createScript}
                    submitBtnText={"Create Script"}
                    script={emptyScript}
                />
            </Wrapper>
            {showWarningModal && (
                <Warning
                    warningModal={warningModal}
                    handleCancel={() => setShowWarningModal(false)}
                />
            )}
        </LargeModal>
    );
};

export default ScriptCreationModal;
