import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import {
    breakpoints,
    colors,
    fonts,
    fontWeight,
    overlayBackgrounds
} from "src/constants";
import {
    clearButton,
    clearSpacing,
    hideScrollbar,
    responsiveContainer,
    whiteBackgroundHoverState
} from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import { ReactComponent as Cross } from "src/components/icons/cross.svg";
import { preventChildBubbling } from "src/utils/functions/helpers";
import { ReactComponent as ChevronLeft } from "src/components/icons/ChevronLeft/chevron-left.svg";
import { useDispatch } from "react-redux";
import { setAModalIsOpened } from "src/ui/observer/actions";

const Parent = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${overlayBackgrounds.light};
`;

const Child = styled(motion.main)<{ contentMaxWidth?: number }>`
    ${responsiveContainer()};
    display: flex;
    flex-direction: column;
    gap: 12px;

    ${props =>
        props.contentMaxWidth &&
        css`
            max-width: ${props.contentMaxWidth}px;
            width: 100%;
        `};

    @media (max-width: ${breakpoints.tablet}px) {
        padding: 0px 15px;
    }
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TitleBackButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

const iconContainerStyles = css`
    ${clearButton()};
    ${whiteBackgroundHoverState()};
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;

const BackButtonContainer = styled.button`
    ${iconContainerStyles};
    margin-right: 8px;
`;

const BackIcon = styled(ChevronLeft)`
    width: 12px;
    height: 12px;
    fill: ${colors.black};
`;

const Title = styled.h1`
    ${clearSpacing()};
    color: ${colors.white};
    font-size: ${fonts.semiTitle}px;
    font-weight: ${fontWeight.medium};
`;

const CloseButtonContainer = styled.button`
    ${iconContainerStyles};
`;

const CloseIcon = styled(Cross)`
    width: 12px;
    height: 12px;
    fill: ${colors.black};
`;

const ContentContainer = styled.div`
    border-radius: 8px;
    overflow: hidden;
`;

interface ContentProps {
    padding?: string;
    contentMaxHeight?: number;
}

const Content = styled.section<ContentProps>`
    background-color: ${colors.white};
    padding: ${props => props.padding || "24px"};
    text-align: left;

    ${props =>
        props.contentMaxHeight &&
        css`
            max-height: ${props.contentMaxHeight}px;
            height: 100%;
            overflow-y: auto;
        `};

    ${props => props.theme.isMobile && hideScrollbar()};
`;

interface Props {
    children: React.ReactNode;
    modalOpen: boolean;
    closeModal: VoidFunction;
    title?: string;
    padding?: string;
    contentMaxWidth?: number;
    contentMaxHeight?: number;
    includeBackButton?: true | boolean;
    backButtonCallback?: VoidFunction;
}

const ResponsiveModal = ({
    children,
    modalOpen,
    closeModal,
    title,
    padding,
    contentMaxWidth,
    contentMaxHeight,
    includeBackButton,
    backButtonCallback
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Effects -------------------------------------------------------
    useEffect(() => {
        if (modalOpen) {
            dispatch(setAModalIsOpened(true));
        }

        return function cleanup() {
            dispatch(setAModalIsOpened(false));
        };
    }, [modalOpen]);

    // JSX -----------------------------------------------------------
    return (
        <AnimatePresence>
            {modalOpen && (
                <Parent onClick={closeModal}>
                    <Child
                        onClick={preventChildBubbling}
                        initial={{ opacity: 0, transform: "translateY(-8px)" }}
                        animate={{ opacity: 1, transform: "translateY(0px)" }}
                        exit={{ opacity: 0, transform: "translateY(-8px)" }}
                        contentMaxWidth={contentMaxWidth}
                    >
                        <Header>
                            <TitleBackButtonContainer>
                                {includeBackButton && backButtonCallback && (
                                    <BackButtonContainer
                                        type="button"
                                        onClick={backButtonCallback}
                                    >
                                        <BackIcon />
                                    </BackButtonContainer>
                                )}
                                {title && <Title>{title}</Title>}
                            </TitleBackButtonContainer>
                            <CloseButtonContainer
                                type="button"
                                onClick={closeModal}
                            >
                                <CloseIcon />
                            </CloseButtonContainer>
                        </Header>

                        <ContentContainer>
                            <Content
                                padding={padding}
                                contentMaxHeight={contentMaxHeight}
                            >
                                {children}
                            </Content>
                        </ContentContainer>
                    </Child>
                </Parent>
            )}
        </AnimatePresence>
    );
};

export default ResponsiveModal;
