import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { clearModal } from "../../../modals/actions";
import styled from "styled-components";
import Modal from "../../../modals/Modal";
import ModalButton from "../../../modals/ModalButton";
import { colors, fonts } from "../../../constants";
import { getHighlightMedias } from "../../highlight-medias/selectors";
import { HighlightMedia } from "../../types";

const Main = styled.div`
    padding: 20px 25px;
`;

const Description = styled.div`
    font-size: ${fonts.subtitle}px;
`;

const ActiveButton = styled.a`
    padding: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.primaryBlue};
    font-weight: 300;
    border-top: 1px solid ${colors.mediumTeal};

    &:hover {
        color: ${colors.primaryBlue};
        text-decoration: none;
    }
`;

const DisabledButton = styled.div`
    padding: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.secondaryGray};
    font-weight: 300;
    border-top: 1px solid ${colors.mediumTeal};
    user-select: none;
`;

interface Props {
    IGStoryMedia?: HighlightMedia;
    feedMedia?: HighlightMedia;
    singleMedia?: HighlightMedia;
    doubleMedia?: HighlightMedia;
    clearModal: () => void;
}

const DownloadTypesModal = ({
    IGStoryMedia,
    feedMedia,
    singleMedia,
    doubleMedia,
    clearModal
}: Props) => {
    return (
        <Modal>
            <Main>
                <Description>
                    Which version of this ad would you like to download?
                </Description>
            </Main>
            {feedMedia ? (
                <ActiveButton target="_blank" href={feedMedia.url}>
                    IG Feed - Square
                </ActiveButton>
            ) : (
                <DisabledButton>IG Feed - Square</DisabledButton>
            )}
            {IGStoryMedia ? (
                <ActiveButton target="_blank" href={IGStoryMedia.url}>
                    IG Story - 15s
                </ActiveButton>
            ) : (
                <DisabledButton>IG Story - 15s</DisabledButton>
            )}
            {doubleMedia ? (
                <ActiveButton target="_blank" href={doubleMedia.url}>
                    Snapchat Double - 20s
                </ActiveButton>
            ) : (
                <DisabledButton>Snapchat Double - 20s</DisabledButton>
            )}
            {singleMedia ? (
                <ActiveButton target="_blank" href={singleMedia.url}>
                    Snapchat Single - 10s
                </ActiveButton>
            ) : (
                <DisabledButton>Snapchat Single - 10s</DisabledButton>
            )}
            <ModalButton text="Cancel" />
        </Modal>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    IGStoryMedia: getHighlightMedias(state, "IGStoryMedia"),
    feedMedia: getHighlightMedias(state, "feedMedia"),
    singleMedia: getHighlightMedias(state, "singleMedia"),
    doubleMedia: getHighlightMedias(state, "doubleMedia")
});

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadTypesModal);
