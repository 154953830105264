import React, { useEffect, useState } from "react";
import { responsiveContainer } from "src/utils/styles/snippets";
import styled from "styled-components";
import { debounce } from "../../util";

const Screen = styled.div<{ height: number }>`
    ${responsiveContainer()};
    height: ${props => (props.height ? `${props.height}px` : "100vh")};
`;

interface Props {
    className?: string;
}

const FullscreenLayout: React.FC<Props> = ({ children, className }) => {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleResize = debounce(() => {
        setHeight(window.innerHeight);
    });

    return (
        <Screen height={height} className={className}>
            {children}
        </Screen>
    );
};

export default FullscreenLayout;
