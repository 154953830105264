import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import {
    AccessStatuses,
    Profile,
    ProfileTabState,
    PublicProfile
} from "../types";
import {
    cashoutModal,
    fetchAccountsUser,
    photoModal,
    statusModal,
    usernameModal
} from "../actions";
import { replace } from "connected-react-router";
import ProfileHeader from "../components/ProfileHeader";
import ProfileModals from "../modals/ProfileModals";
import TransactionsCard from "./TransactionsCard";
import Tab from "../../components/Tab";
import ProfileTabs from "./ProfileTabs";
import { getUrlParameter } from "../../util";
import EarningsRow from "./EarningsRow";
import Bio from "../components/Bio";
import CampaignModals from "../modals/CampaignModals";
import IntroSocialHeader from "./intro-social/IntroSocialHeader";
import ReferredUsersModal from "src/profile/components/ReferredUsersModal/ReferredUsersModal";
import ShareReferralModal from "src/profile/components/ShareReferralModal/ShareReferralModal";
import Navbar from "src/components/Navbar";
import MainContainer from "src/components/MainContainer";
import ProfileMainContainer from "../components/ProfileMainContainer";
import ProfileHeaderContainer from "../components/ProfileHeaderContainer";
import ProfileTabsContainer from "../components/ProfileTabsContainer";

const Tabs = styled("article")`
    display: flex;
`;

interface Props {
    profile: Profile;
    publicProfile: PublicProfile;
    tabState: ProfileTabState;
}

const ProfileMain = ({ profile, publicProfile, tabState }: Props) => {
    const earningsSummary = useSelector((state: GlobalState) => {
        return state.profile.earningsSummary;
    });

    const dispatch = useDispatch();

    function dispatchPhotoModal(): void {
        dispatch(photoModal());
    }

    function dispatchUsernameModal(): void {
        dispatch(usernameModal());
    }

    function dispatchStatusModal(): void {
        dispatch(statusModal());
    }

    function dispatchCashoutModal(): void {
        dispatch(cashoutModal());
    }

    function replaceRoute(route: string): void {
        dispatch(replace(route));
    }

    useEffect(() => {
        if (getUrlParameter("help")) {
            // @ts-ignore
            window.Intercom("show");
        }
        dispatch(fetchAccountsUser(profile.id));
    }, []);

    return (
        <ProfileMainContainer private>
            <Navbar />

            <MainContainer>
                <ProfileHeaderContainer>
                    <ProfileHeader
                        publicProfile={publicProfile}
                        statusModal={dispatchStatusModal}
                        privateCallbacks={{
                            photoModal: dispatchPhotoModal,
                            usernameModal: dispatchUsernameModal
                        }}
                    />

                    <Bio bio={profile.bio} />

                    <EarningsRow earningsSummary={earningsSummary} />

                    <TransactionsCard
                        profile={profile}
                        cashOutModal={dispatchCashoutModal}
                    />
                </ProfileHeaderContainer>

                <ProfileTabsContainer>
                    {profile.accessStatus === AccessStatuses.ALL && (
                        <Tabs>
                            <Tab
                                name={"About"}
                                active={tabState === ProfileTabState.about}
                                onClick={() => {
                                    replaceRoute("/profile");
                                }}
                            />
                            <Tab
                                name={"Bonus"}
                                active={tabState === ProfileTabState.bonus}
                                onClick={() => {
                                    replaceRoute("/profile/bonus");
                                }}
                            />
                        </Tabs>
                    )}

                    <ProfileTabs profile={profile} tabState={tabState} />
                </ProfileTabsContainer>

                <ProfileModals profile={profile} />
                <CampaignModals />
                <ReferredUsersModal />
                <ShareReferralModal />
            </MainContainer>
        </ProfileMainContainer>
    );
};

export default ProfileMain;
