import React from "react";
import styled from "styled-components";
import { breakpoints } from "src/constants";

const Main = styled.div`
    margin: 10px 0px;
    font-size: 11px;
    text-align: left;

    @media (max-width: ${breakpoints.tablet}px) {
        padding: 0px 15px;
    }
`;

interface Props {
    bio: string | null;
}

const Bio = ({ bio }: Props) => {
    return <Main>{bio}</Main>;
};

export default Bio;
