import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { AnalyticsDataStore } from "./reducer";
import { fetchRawAnalyticsData, reportParameters } from "./actions";
import AnalyticsDataParser, {
    CampaignByDate,
    MediaByDate
} from "./AnalyticsDataParser";
import { AnalyticsFilterState } from "./AnalyticsMain";
import { analyticsMediaToString, formatNumber } from "../util";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import CoinSummary from "../components/CoinSummary";
import EarningsByDayLegend from "./EarningsByDayLegend";
import AnalyticsControlButtons from "./AnalyticsControlButtons";
import Button from "react-bootstrap/Button";
import AnalyticsLoader from "./AnalyticsLoader";
import { emailReportModal } from "./actions";
import { colors } from "../constants";
import PaddingAround from "../components/PaddingAround";
import PaddingNoTop from "../components/PaddingNoTop";
import AnalyticsEmptyState from "./components/AnalyticsEmptyState";

const Link = styled.div`
    color: ${colors.primaryBlue};
    user-select: none;
    cursor: pointer;
`;

interface Props {
    analyticsDataStore: AnalyticsDataStore;
    fetchRawAnalyticsData: (payload: reportParameters) => void;
    emailReportModal: (emailDateRange: [string, string] | null) => void;
    filterState: AnalyticsFilterState;
    parsedData: (CampaignByDate | MediaByDate)[];
    setParsedData: (parsedData: (CampaignByDate | MediaByDate)[]) => void;
    pickDates: () => void;
    pickFilters: () => void;
}

const EarningsByDayCard = ({
    analyticsDataStore,
    fetchRawAnalyticsData,
    emailReportModal,
    filterState,
    parsedData,
    setParsedData,
    pickDates,
    pickFilters
}: Props) => {
    const [loading, setLoading] = useState(true);
    const [showMore, setShowMore] = useState(false);

    function toggleShowMore(): void {
        setShowMore(!showMore);
    }

    useEffect(() => {
        const parser = new AnalyticsDataParser(analyticsDataStore);

        let startDate;
        let endDate;

        if (filterState.startDate && filterState.endDate) {
            startDate = filterState.startDate.format("YYYY-MM-DD");
            endDate = filterState.endDate.format("YYYY-MM-DD");
        } else {
            startDate = filterState.dateRange.dateRange.startDate.format(
                "YYYY-MM-DD"
            );
            endDate = filterState.dateRange.dateRange.endDate.format(
                "YYYY-MM-DD"
            );
        }

        const missingDates = parser.missingDates(startDate, endDate);

        if (missingDates[0]) {
            setLoading(true);
            fetchRawAnalyticsData({
                startDate: missingDates[0],
                endDate: missingDates[1]
            });
        } else {
            setLoading(false);
            setParsedData(
                filterState.useCampaigns
                    ? parser.generateDayCampaignTotals(startDate, endDate)
                    : parser.generateDayMediaTotals(startDate, endDate)
            );
        }
    }, [
        analyticsDataStore,
        filterState.startDate,
        filterState.endDate,
        filterState.dateRange,
        filterState.useCampaigns
    ]);

    function generateDataObject() {
        const colors: {
            [index: number]: string;
        } = {
            0: "#00de4a",
            1: "#0078f5",
            2: "#f50082",
            3: "#f5c900",
            4: "#f53300",
            5: "#874ded",
            6: "#14e0ff",
            7: "#54ff14"
        };
        const indexes: {
            [color: string]: number;
        } = {
            "#00de4a": 0,
            "#0078f5": 1,
            "#f50082": 2,
            "#f5c900": 3,
            "#f53300": 4,
            "#874ded": 5,
            "#14e0ff": 6,
            "#54ff14": 7
        };
        let currColor = 0;

        const labels = parsedData[0] ? parsedData[0].dates : [];
        const datasets: any[] = [];

        parsedData.forEach(currData => {
            let passesFilter = false;

            if ("campaign" in currData) {
                passesFilter =
                    filterState.selectedCampaigns.size === 0 ||
                    filterState.selectedCampaigns.has(currData.campaign);
            } else {
                passesFilter =
                    filterState.selectedMedia.size === 0 ||
                    filterState.selectedMedia.has(currData.media);
            }

            if (passesFilter) {
                const storageKey =
                    "campaign" in currData
                        ? `c-color-${currData.campaign}`
                        : `m-color-${currData.media}`;
                const storedColor = sessionStorage.getItem(storageKey);

                if (storedColor && typeof indexes[storedColor] === "number") {
                    currColor = indexes[storedColor];
                } else {
                    sessionStorage.setItem(storageKey, colors[currColor]);
                }

                datasets.push({
                    data: currData.dollars,
                    total: currData.totalDollars,
                    label:
                        "campaign" in currData
                            ? currData.campaignName
                            : analyticsMediaToString(currData),
                    borderColor: colors[currColor],
                    pointBackgroundColor: colors[currColor],
                    lineTension: 0,
                    pointStyle: "circle",
                    pointRadius: 4,
                    fill: false
                });
            }

            currColor = (currColor + 1) % 8;
        });

        return {
            labels,
            datasets: datasets.slice(0, !showMore ? 5 : datasets.length)
        };
    }

    function downloadReport() {
        let startDate;
        let endDate;

        if (filterState.startDate && filterState.endDate) {
            startDate = filterState.startDate.format("YYYY-MM-DD");
            endDate = filterState.endDate.format("YYYY-MM-DD");
        } else {
            startDate = filterState.dateRange.dateRange.startDate.format(
                "YYYY-MM-DD"
            );
            endDate = filterState.dateRange.dateRange.endDate.format(
                "YYYY-MM-DD"
            );
        }

        emailReportModal([startDate, endDate]);
    }

    const totalCoins = Math.round(
        parsedData.reduce((a, b) => {
            return a + b.totalCoins;
        }, 0)
    );

    const totalDollars =
        Math.round(
            parsedData.reduce((a, b) => {
                return a + b.totalDollars;
            }, 0) * 100
        ) / 100;

    const dataObject = generateDataObject();

    const thereIsNoData = dataObject.datasets.length === 0;

    return (
        <>
            <CoinSummary
                topLeft={"Earnings By Day"}
                topRight={`$${formatNumber(totalDollars.toFixed(2))}`}
                bottomLeft={
                    <Link onClick={downloadReport}>Download Report</Link>
                }
                bottomRight={""}
            />
            <AnalyticsControlButtons
                filterState={filterState}
                parsedData={parsedData}
                pickDates={pickDates}
                pickFilters={pickFilters}
                loading={loading}
            />
            {loading ? (
                <AnalyticsLoader />
            ) : thereIsNoData ? (
                <AnalyticsEmptyState />
            ) : (
                <PaddingAround>
                    <Line
                        height={200}
                        width={300}
                        data={dataObject}
                        options={{
                            legend: { display: false },
                            scales: {
                                xAxes: [
                                    {
                                        gridLines: {
                                            display: false,
                                            drawBorder: false
                                        },
                                        ticks: {
                                            fontColor: colors.secondaryGray,
                                            padding: 10,
                                            callback: function(value: any) {
                                                return value.slice(8, 10);
                                            }
                                        }
                                    }
                                ],
                                yAxes: [
                                    {
                                        gridLines: {
                                            display: false,
                                            drawBorder: false
                                        },
                                        ticks: {
                                            fontColor: colors.secondaryGray,
                                            padding: 5,
                                            maxTicksLimit: 5,
                                            beginAtZero: true,
                                            callback: function(value: any) {
                                                if (value > 999) {
                                                    return `$${value / 1000}K`;
                                                } else {
                                                    return `$${value}`;
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            tooltips: {
                                displayColors: false,
                                callbacks: {
                                    title: function() {}
                                }
                            }
                        }}
                    />
                    <EarningsByDayLegend
                        datasets={dataObject.datasets}
                        expanded={showMore}
                    />
                </PaddingAround>
            )}

            <PaddingNoTop>
                <Button
                    block
                    variant="outline-success"
                    onClick={toggleShowMore}
                >
                    Show {showMore ? "Less" : "More"}
                </Button>
            </PaddingNoTop>
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    analyticsDataStore: state.analytics.analyticsDataStore
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchRawAnalyticsData: (payload: reportParameters) =>
        dispatch(fetchRawAnalyticsData(payload)),
    emailReportModal: (emailDateRange: [string, string] | null) =>
        dispatch(emailReportModal(emailDateRange))
});

export default connect(mapStateToProps, mapDispatchToProps)(EarningsByDayCard);
