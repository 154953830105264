import styled from "styled-components";
import { colors, maxWidthDesktop } from "src/constants";

const ProfileHeaderContainer = styled("section")`
    margin: 20px auto 0px;
    padding: 20px;
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;

    @media (max-width: ${maxWidthDesktop}px) {
        margin: 0px;
        padding: 20px 0px 0px;
        border: ${colors.transparent} solid 1px;
        border-bottom: ${colors.mediumTeal} solid 1px;
    }

    @media (max-width: ${maxWidthDesktop}px) {
        border-radius: 0px;
    }
`;

export default ProfileHeaderContainer;
