import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { push } from "connected-react-router";
import Header from "../../components/Header";
import PaddingNoTop from "../../components/PaddingNoTop";
import { BigOutlineButton } from "../components/Buttons";
import PaddingAround from "../../components/PaddingAround";
import XCard from "../components/XCard";
import { fetchLinkList } from "./actions";
import PlusCard from "../components/PlusCard";
import { useState } from "react";
import LinkListCreateModal from "./components/LinkListCreateModal";
import LinkListCell from "./components/LinkListCell";
import styled from "styled-components";
import { LoaderItem } from "src/components/SkeletonLoader";
import { getLinkListError, getLinkLists } from "./selectors";
import { LinkList } from "../types";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 18px 18px 18px;
    gap: 18px;
`;
interface Props {}

const LinkListCard = ({}: Props) => {
    const [creating, setCreating] = useState(false);
    const linkLists = useSelector(getLinkLists);
    const error = useSelector((state: GlobalState) =>
        getLinkListError(state, "LinkListCard")
    );
    const loading = useSelector(
        (state: GlobalState) => state.ui.linkList.loading
    );

    const dispatch = useDispatch();

    const header = (
        <Header
            title={"Link Lists"}
            subtitle={`${linkLists?.length} Active Lists`}
            link={{
                text: "View All",
                onClick: () => {
                    dispatch(push(`/profile/linklists`));
                }
            }}
        />
    );

    if (loading) {
        return (
            <>
                {header}
                <Container>
                    {Array.from({ length: 3 }, (n, index) => (
                        <LoaderItem key={index} />
                    ))}
                </Container>
            </>
        );
    }

    if (error) {
        return (
            <PaddingAround>
                <XCard
                    text="Failed to load link list, click the button below to try again."
                    button={{
                        text: "Try again",
                        callback: () => dispatch(fetchLinkList())
                    }}
                />
            </PaddingAround>
        );
    }

    if (linkLists.length === 0) {
        return (
            <>
                {header}
                <PaddingNoTop>
                    <PlusCard
                        text="You do not have any link lists yet. Click the button below to start."
                        button={{
                            text: "Set Up",
                            callback: () => setCreating(true)
                        }}
                    />
                    <LinkListCreateModal
                        onClose={() => setCreating(false)}
                        open={creating}
                    />
                </PaddingNoTop>
            </>
        );
    } else {
        return (
            <>
                {header}
                <Container>
                    {linkLists.slice(0, 3).map((list: LinkList, index) => (
                        <LinkListCell key={list.id} linkList={list} />
                    ))}
                    <BigOutlineButton onClick={() => setCreating(true)}>
                        Create Link List
                    </BigOutlineButton>
                </Container>
                <LinkListCreateModal
                    onClose={() => setCreating(false)}
                    open={creating}
                />
            </>
        );
    }
};

export default LinkListCard;
