import React from "react";
import styled from "styled-components";
import CountryPlatformCell from "src/components/CountryPlatformCell";
import CoinReward from "src/components/CoinReward";
import { colors } from "src/constants";
import { flex } from "src/utils/styles/snippets";

const Main = styled("div")`
    ${flex("row", "auto", "center")};

    @media (max-width: 386px) {
        ${flex("column", "flex-start", "flex-start")};
    }
`;

interface AvatarProps {
    name: string;
}

const Avatar = styled("img").attrs((props: AvatarProps) => ({
    alt: `${props.name} Avatar`
}))<AvatarProps>`
    width: 56px;
    height: 56px;
    margin-right: 12px;
    border-radius: 12px;
    object-fit: cover;

    @media (max-width: 386px) {
        margin: 0px 0px 12px;
    }
`;

const CampaignName_Platform = styled("div")`
    ${flex("column")};
`;

const CampaignName = styled("h3")`
    margin: 0px 0px 2px;
    padding: 0px;
    color: ${colors.black};
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
`;

const Platform = styled("div")`
    ${flex("row", "auto", "center")};

    @media (max-width: 272px) {
        display: grid;
        grid-template-columns: repeat(1, auto);
        grid-gap: 4px;
    }
`;

interface Props {
    imageUrl: string;
    campaignName: string;
    reward: number;
    rewardType: string;
    country: string;
    platform: string;
}

const AvatarNamePlatform = (props: Props) => {
    const {
        imageUrl,
        campaignName,
        reward,
        rewardType,
        country,
        platform
    } = props;

    return (
        <Main>
            <Avatar src={imageUrl} name={campaignName} />

            <CampaignName_Platform>
                <CampaignName>{campaignName}</CampaignName>

                <Platform>
                    <CoinReward reward={reward} type={rewardType} />
                    <CountryPlatformCell
                        country={country}
                        platform={platform}
                    />
                </Platform>
            </CampaignName_Platform>
        </Main>
    );
};

export default AvatarNamePlatform;
