import { combineReducers } from "redux";

import { NormalizedActionGeneric } from "src/types";
import { ReferredUser } from "src/profile/types";

import { FETCH_REFERRED_USERS } from "./actions";

export interface ById {
    [key: string]: ReferredUser;
}

export interface ReferredUsersEntitiesState {
    byId: ById;
    allIds: number[];
}

const initialReferredUsersState: ReferredUsersEntitiesState = {
    byId: {},
    allIds: []
};

function byIdReducer(
    state = initialReferredUsersState.byId,
    action: NormalizedActionGeneric<ReferredUser, number[]>
) {
    switch (action.type) {
        case FETCH_REFERRED_USERS.SUCCESS:
            return { ...state, ...action.response.entities.referredUsers };

        default:
            return state;
    }
}

function allIdsReducer(
    state = initialReferredUsersState.allIds,
    action: NormalizedActionGeneric<ReferredUser, number[]>
) {
    switch (action.type) {
        case FETCH_REFERRED_USERS.SUCCESS:
            return action.response.result;

        default:
            return state;
    }
}

const referredUsersEntitiesReducer = combineReducers<
    ReferredUsersEntitiesState
>({
    byId: byIdReducer,
    allIds: allIdsReducer
});

export default referredUsersEntitiesReducer;
