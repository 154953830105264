import { SubmissionReport } from "src/marketplace/reports/types";
import { NormalizedAction } from "src/types";
import { FETCH_OPEN_BUY_LEADERBOARD } from "./actions";

export interface OpenBuyLeaderboardReportsEntitiesState {
    [id: number]: SubmissionReport;
}

const initialState: OpenBuyLeaderboardReportsEntitiesState = {};

const openBuyLeaderboardReportsEntitiesReducer = (
    state = initialState,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_OPEN_BUY_LEADERBOARD.SUCCESS:
            return {
                ...state,
                ...action.response.entities.openBuyLeaderboardReports
            };

        default:
            return state;
    }
};

export default openBuyLeaderboardReportsEntitiesReducer;
