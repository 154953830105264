import React, { useEffect } from "react";
// @ts-ignore
import { withSendBird } from "sendbird-uikit";
import { connect } from "react-redux";
import { goBack, replace } from "connected-react-router";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import FullscreenLoader from "../../components/FullscreenLoader";

interface Props {
    userId: number;
    otherId: string;
    goBack: VoidFunction;
    replace: (route: string) => void;
    sdk: any;
}

const LoadChannel = ({ userId, otherId, goBack, replace, sdk }: Props) => {
    function classifyChannel(channel: any) {
        channel.getMetaData(["type"], (data: any, error: any) => {
            if (error) {
                window.alert(error);
            } else {
                if (data.type) {
                    replace(`/chat/channel/${channel.url}`);
                } else {
                    channel.createMetaData({ type: "direct" }, (data: any, error: any) => {
                        if (error) {
                            window.alert(error);
                        } else {
                            replace(`/chat/channel/${channel.url}`);
                        }
                    });
                }
            }
        });
    }

    useEffect(() => {
        if (sdk.GroupChannel && sdk.GroupChannelParams) {
            const params = new sdk.GroupChannelParams();
            params.isPublic = false;
            params.isEphemeral = false;
            params.isDistinct = true;
            params.name = "";
            params.coverUrl = "";
            params.addUserIds([otherId]);

            sdk.GroupChannel.createChannel(
                params,
                (channel: any, error: any) => {
                    if (!error) {
                        classifyChannel(channel);
                    } else {
                        window.alert(error);
                    }
                }
            );
        }
    }, [userId, otherId, sdk]);

    return (
        <>
            <SimpleNavbar left={<NavButton back onClick={goBack} />} />
            <FullscreenLoader />
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    goBack: () => dispatch(goBack()),
    replace: (route: string) => dispatch(replace(route))
});

export default withSendBird(
    connect(null, mapDispatchToProps)(LoadChannel),
    (state: any) => ({
        sdk: state.stores.sdkStore.sdk
    })
);
