import React, { useEffect, useState } from "react";
import { Shoutout } from "../types";
import { storage } from "../../../firebase/FirebaseConfig";
import styled from "styled-components";
import { shortTimeInterval } from "../../../util";
import evaluateShoutoutState, {
    ShoutoutState
} from "../utils/evaluateShoutoutState";

const Container = styled.div`
    height: 66px;
    width: 66px;
    position: relative;
`;

const Ring = styled.img`
    height: 100%;
    width: 100%;
`;

const Preview = styled.img`
    position: absolute;
    border-radius: 100%;
    height: 56px;
    width: 56px;
    top: 5px;
    left: 5px;
    object-fit: cover;
`;

const LabelContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: fit-content;
`;

const LabelImage = styled.img`
    display: block;
    height: 18px;
    width: 25px;
`;

const LabelText = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 9px;
    line-height: 18px;
    color: white;
`;

const CircleLabel = styled.img`
    position: absolute;
    bottom: 0;
    right: 4px;
    height: 17px;
    width: 17px;
`;

interface Props {
    shoutout: Shoutout;
}

const ShoutoutBubble = ({ shoutout }: Props) => {
    const [mediaUrl, setMediaUrl] = useState(shoutout.Media[0].url);
    const [coverUrl, setCoverUrl] = useState(shoutout.Media[0].cover_photo_url);

    useEffect(() => {
        const mediaObject = shoutout.Media[0];

        if (mediaObject.cover_photo && !coverUrl) {
            storage
                .ref(
                    `/${mediaObject.Campaign.linkUrlPrefix}/${mediaObject.cover_photo}`
                )
                .getDownloadURL()
                .then((url: string) => {
                    setCoverUrl(url);
                })
                .catch((error: string) => {
                    console.log("cover error");
                });
        }

        if (mediaObject.key && !mediaUrl) {
            storage
                .ref(
                    `/${mediaObject.Campaign.linkUrlPrefix}/${mediaObject.key}`
                )
                .getDownloadURL()
                .then((url: string) => {
                    setMediaUrl(url);
                })
                .catch((error: string) => {
                    console.log(error);
                });
        }
    }, [shoutout]);

    const state = evaluateShoutoutState(shoutout);
    const timeLeft =
        Date.parse(shoutout.start_time) + 1000 * 60 * 60 * 24 - Date.now();
    let previewImage;

    if (shoutout.Media[0].type === "video") {
        previewImage = coverUrl || "/other_profile/other_profile.png";
    } else {
        previewImage = mediaUrl || "/other_profile/other_profile.png";
    }

    if (state === ShoutoutState.Unreserved) {
        // fill this in when the shoutouts product changes
        return null;
    } else if (
        state === ShoutoutState.Reserved &&
        timeLeft < 1000 * 60 * 60 * 2
    ) {
        return (
            <Container>
                <Ring src={"/shoutout_red/shoutout_red@2x.png"} />
                <Preview src={previewImage} />
                <LabelContainer>
                    <LabelImage
                        src={"/shoutout_active_red/shoutout_active_red@2x.png"}
                    />
                    <LabelText>{shortTimeInterval(timeLeft)}</LabelText>
                </LabelContainer>
            </Container>
        );
    } else if (state === ShoutoutState.Reserved) {
        return (
            <Container>
                <Ring src={"/shoutout_blue/shoutout_blue@2x.png"} />
                <Preview src={previewImage} />
                <LabelContainer>
                    <LabelImage
                        src={"/shoutout_active/shoutout_active@2x.png"}
                    />
                    <LabelText>{shortTimeInterval(timeLeft)}</LabelText>
                </LabelContainer>
            </Container>
        );
    } else if (state === ShoutoutState.Confirming) {
        return (
            <Container>
                <Ring src={"/shoutout_blue/shoutout_blue@2x.png"} />
                <Preview src={previewImage} />
                <CircleLabel
                    src={"/shoutout_pending/shoutout_pending@2x.png"}
                />
            </Container>
        );
    } else if (state === ShoutoutState.Blocking) {
        return (
            <Container>
                <Ring src={"/shoutout_red/shoutout_red@2x.png"} />
                <Preview src={previewImage} />
                <CircleLabel
                    src={"/shoutout_rejected/shoutout_rejected@2x.png"}
                />
            </Container>
        );
    } else if (state === ShoutoutState.Confirmed) {
        return (
            <Container>
                <Ring src={"/shoutout_blue/shoutout_blue@2x.png"} />
                <Preview src={previewImage} />
                <CircleLabel
                    src={"/shoutout_confirmed/shoutout_confirmed@2x.png"}
                />
            </Container>
        );
    } else if (state === ShoutoutState.Rejected) {
        return (
            <Container>
                <Ring src={"/shoutout_red/shoutout_red@2x.png"} />
                <Preview src={previewImage} />
                <CircleLabel
                    src={"/shoutout_rejected/shoutout_rejected@2x.png"}
                />
            </Container>
        );
    } else {
        return null;
    }
};

export default ShoutoutBubble;
