import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";
import { convertPercentRangeIntoNumberRange } from "./utils/helpers";

export const getCreators = createSelector(
    (state: GlobalState) => state.entities.creators.byId,
    (state: GlobalState) => state.entities.creators.allIds,
    (creators, creatorIds) => {
        return creatorIds.map((id: number) => {
            return creators[id];
        });
    }
);

export const getCreator = createSelector(
    (state: GlobalState) => state.entities.creators.byId,
    (state: GlobalState, socialAccountId: number) => socialAccountId,
    (creators, socialAccountId) => {
        if (creators[socialAccountId]) {
            return creators[socialAccountId];
        }
    }
);

export const generateQueryFilters = createSelector(
    (state: GlobalState) => state.ui.creators,
    creatorsUIState => {
        const {
            platformFilters,
            interestsFilters,
            themesFilters,
            creatorCountryRegionFilters,
            audienceCountryRegionFilters,
            followersFilter,
            averageViewsFilter,
            engagementRateFilter,
            audienceGenderFilter,
            audienceAgeFilters,
            audienceDeviceFilter
        } = creatorsUIState;

        const queryFilters: any = {};

        const platforms = Object.keys(platformFilters);
        const interests = Object.keys(interestsFilters);
        const themes = Object.keys(themesFilters);
        const creatorCountryRegions = Object.keys(creatorCountryRegionFilters);
        const audienceCountryRegions = Object.keys(
            audienceCountryRegionFilters
        );
        const audienceAges = Object.keys(audienceAgeFilters);

        // Platform Filters
        if (platforms.length > 0) {
            queryFilters["platforms"] = { terms: { platform: platforms } };
        } else {
            delete queryFilters["platforms"];
        }

        // Interests Filters
        if (interests.length > 0) {
            queryFilters["interestsFilters"] = {
                terms: { interests }
            };
        } else {
            delete queryFilters["interestsFilters"];
        }

        // Themes Filters
        if (themes.length > 0) {
            queryFilters["themesFilters"] = {
                terms: { theme: themes }
            };
        } else {
            delete queryFilters["themesFilters"];
        }

        // Creator Country/Regions Filters
        if (creatorCountryRegions.length > 0) {
            queryFilters["creatorCountryRegions"] = {
                terms: { country_code: creatorCountryRegions }
            };
        } else {
            delete queryFilters["creatorCountryRegions"];
        }

        // Audience Country/Regions Filters
        if (audienceCountryRegions.length > 0) {
            queryFilters["audienceCountryRegions"] = {
                terms: { audience_country_code: audienceCountryRegions }
            };
        } else {
            delete queryFilters["audienceCountryRegions"];
        }

        // Followers Filter
        if (followersFilter === "All") {
            queryFilters["followers"] = {
                range: { followers: { gte: 0 } }
            };
        } else if (followersFilter === "0-50K Followers") {
            queryFilters["followers"] = {
                range: { followers: { gte: 0, lte: 50000 } }
            };
        } else if (followersFilter === "50K-150K Followers") {
            queryFilters["followers"] = {
                range: { followers: { gte: 50000, lte: 150000 } }
            };
        } else if (followersFilter === "150K-500K Followers") {
            queryFilters["followers"] = {
                range: { followers: { gte: 150000, lte: 500000 } }
            };
        } else if (followersFilter === "500K-1M Followers") {
            queryFilters["followers"] = {
                range: { followers: { gte: 500000, lte: 1000000 } }
            };
        } else if (followersFilter === "1M-10M Followers") {
            queryFilters["followers"] = {
                range: { followers: { gte: 1000000, lte: 10000000 } }
            };
        } else {
            queryFilters["followers"] = {
                range: { followers: { gte: 10000000 } }
            };
        }

        // Average Views Filter
        if (averageViewsFilter === "All") {
            queryFilters["averageViews"] = {
                range: { avg_views: { gte: 0 } }
            };
        } else if (averageViewsFilter === "0-50K Views") {
            queryFilters["averageViews"] = {
                range: { avg_views: { gte: 0, lte: 50000 } }
            };
        } else if (averageViewsFilter === "50K-150K Views") {
            queryFilters["averageViews"] = {
                range: { avg_views: { gte: 500000, lte: 150000 } }
            };
        } else if (averageViewsFilter === "150K-500K Views") {
            queryFilters["averageViews"] = {
                range: { avg_views: { gte: 150000, lte: 500000 } }
            };
        } else if (averageViewsFilter === "500K-1M Views") {
            queryFilters["averageViews"] = {
                range: { avg_views: { gte: 500000, lte: 1000000 } }
            };
        } else if (averageViewsFilter === "1M-10M Views") {
            queryFilters["averageViews"] = {
                range: { avg_views: { gte: 1000000, lte: 10000000 } }
            };
        } else {
            queryFilters["averageViews"] = {
                range: { avg_views: { gte: 10000000 } }
            };
        }

        // Engagement Rates Filters
        if (engagementRateFilter !== "All") {
            queryFilters["engagementRates"] = {
                range: {
                    engagement_rate: convertPercentRangeIntoNumberRange(
                        engagementRateFilter
                    )
                }
            };
        } else {
            delete queryFilters["engagementRates"];
        }

        // Audience Gender Filter
        if (audienceGenderFilter !== "All") {
            queryFilters["audienceGenders"] = {
                term: {
                    audience_gender_code: audienceGenderFilter.toUpperCase()
                }
            };
        } else {
            delete queryFilters["audienceGenders"];
        }

        // Audience Ages Filters
        if (audienceAges.length > 0) {
            queryFilters["audienceAges"] = {
                term: { audience_age_code: "18-24" }
            };
        } else {
            delete queryFilters["audienceAges"];
        }

        // Audience Devices Filter
        if (audienceDeviceFilter !== "All") {
            queryFilters["audienceDevices"] = {
                term: {
                    audience_device_code: audienceDeviceFilter.toLowerCase()
                }
            };
        } else {
            delete queryFilters["audienceDevices"];
        }

        return Object.values(queryFilters);
    }
);
