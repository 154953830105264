import React, {
    Ref,
    forwardRef,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import styled, { css } from "styled-components";
import { Descendant, Editor } from "slate";
import TextEditor, { TextEditorRef } from "src/components/text-editor";
import { serializeContent } from "src/components/text-editor/utils";
import RichTextPreview from "./RichTextPreview";

const editableStyles = css`
    height: 15vh;
    overflow-y: auto;
    margin-bottom: 1rem;
    font-family: sans-serif;
    padding: 2px 6px;
`;
const toolbarStyles = css`
    margin-bottom: 1rem;
`;
const TextEditorWrapper = styled.div`
    text-align: left;
`;

export type RichTextEditorRef = {
    controlEditor: (callback: (editor: Editor) => void) => void;
};

const RichTextEditor = (
    {
        message,
        setMessage,
        placeholder
    }: {
        message?: string;
        setMessage: React.Dispatch<React.SetStateAction<string>>;
        placeholder?: string;
    },
    ref: Ref<RichTextEditorRef>
) => {
    const [editorContent, setEditorContent] = React.useState<Descendant[]>([]);
    const [isError, setIsError] = useState(false);

    const handleChange = (newEditorValue: Descendant[]) => {
        setEditorContent(newEditorValue);
        setMessage(serializeContent(newEditorValue));
    };

    const textEditorRef = useRef<TextEditorRef>(null);
    useImperativeHandle(ref, () => ({
        controlEditor: (callback: (editor: Editor) => void) => {
            textEditorRef.current?.controlEditor(callback);
        }
    }));

    return (
        <TextEditorWrapper>
            <TextEditor
                ref={textEditorRef}
                initialText={message}
                editorContent={editorContent}
                setEditorContent={setEditorContent}
                handleChange={handleChange}
                setIsError={setIsError}
                editableStyles={editableStyles}
                toolbarStyles={toolbarStyles}
                placeholder={placeholder}
                PreviewComponent={RichTextPreview}
            />
        </TextEditorWrapper>
    );
};

export default forwardRef(RichTextEditor);
