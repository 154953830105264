import { normalize } from "normalizr";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { post } from "src/Api";
import { Action } from "src/types";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { errorToString } from "src/util";
import {
    createOfferFlowFailure,
    createOfferFlowSuccess,
    CREATE_OFFER
} from "./actions.create-offer";
import { offersList } from "./schema";

function* createOfferRequest(action: Action) {
    try {
        const { instructionSetId, creatorGroupId, offers } = action.payload;
        const endpoint = "/v1/directBuy/createOfferFlow";
        const createOfferData = {
            instructionSetId,
            creatorGroupId,
            offers
        };
        const response = yield call(post, endpoint, createOfferData);

        yield put(
            createOfferFlowSuccess(normalize(response.data.data, offersList))
        );
        yield put(setPillNotificationText("Offer successfully created."));
    } catch (error) {
        const errorMessage = errorToString(error);
        yield put(setPillNotificationText(errorMessage, "danger"));
        yield put(createOfferFlowFailure(errorMessage));
    }
}

export default function* offersSaga() {
    yield all([takeEvery(CREATE_OFFER.REQUEST, createOfferRequest)]);
}

// {
// 	"data": {
// 			"data": [
// 					{
// 							"offerStatus": "pending",
// 							"buyStatus": "pending",
// 							"adminTags": [],
// 							"id": 28757,
// 							"buyerId": 198,
// 							"publisherId": 48704,
// 							"offerStartsAt": "2022-09-14T20:03:16.881Z",
// 							"offerExpiresAt": "2022-09-16T20:03:16.881Z",
// 							"prepaymentAmount": null,
// 							"buyDuration": 604800,
// 							"buyStartsAt": null,
// 							"buyExpiresAt": null,
// 							"employeeId": 96390,
// 							"instructionSetId": 290,
// 							"creatorGroupId": 137,
// 							"paymentSetBucketId": 25,
// 							"updatedAt": "2022-09-14T20:03:16.881Z",
// 							"createdAt": "2022-09-14T20:03:16.881Z",
// 							"offerViewedAt": null,
// 							"offerApprovedAt": null,
// 							"offerRejectedAt": null,
// 							"offerRejectionReason": null,
// 							"buyCompletedAt": null,
// 							"buyApprovedAt": null,
// 							"buyRejectedAt": null,
// 							"buyRejectionReason": null,
// 							"deletedAt": null,
// 							"prepaymentPayoutId": null
// 					}
// 			]
// 	},
// 	"status": 200,
// 	"statusText": "OK",
// 	"headers": {
// 			"content-length": "682",
// 			"content-type": "application/json; charset=utf-8"
// 	},
// 	"config": {
// 			"url": "/v1/directBuy/createOfferFlow",
// 			"method": "post",
// 			"data": "{\"instructionSetId\":290,\"creatorGroupId\":137,\"offers\":[{\"socialAccountId\":144094,\"paymentSetBucketId\":25}],\"requiresPlugLink\":true}",
// 			"headers": {
// 					"Authorization": "bearer eyJhbGciOiJIUzI1NiJ9.OTYzOTA.AOEGvGrHx8k7vq5t82Z8IPe41UZbxL6OLNhZ6_58VFA",
// 					"Content-Type": "application/json",
// 					"FirebaseToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkMjNmMzc0MDI1ZWQzNTNmOTg0YjUxMWE3Y2NlNDlhMzFkMzFiZDIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcGx1Z2NvaW5wcm9kIiwiYXVkIjoicGx1Z2NvaW5wcm9kIiwiYXV0aF90aW1lIjoxNjYxMjc1Mzg0LCJ1c2VyX2lkIjoiOTYzOTAiLCJzdWIiOiI5NjM5MCIsImlhdCI6MTY2MzE4MjU0MywiZXhwIjoxNjYzMTg2MTQzLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7fSwic2lnbl9pbl9wcm92aWRlciI6ImN1c3RvbSJ9fQ.PKGTWKuPUpCYXfg5uVScRI_PXGiKVJHDtUEl6gSA6Oat3ZtKNCYZoAlXmMnL3IAFsPVgJ2jcRLP88QtSwIDYanWfagE8LPeWbpn1SliA9vG-k7V1XnRWB7x9HJGfle5MBHSzbHwVLpKh6_DFe3QjQq0GoCFLrSOUYSGNQ3gLPRTv0YE9aVzOGxJsSu53tjNhaXUDKiFYxtm4tMrdN4CCSW5JleJu5S0T_Gh3YQM2pi1N5Hn4Df4DdDkH_CF2_7XVfG44K0ZgDsYTPAjClNA-57ev40LoXi0CRx2EvLwOU5QOOvu6DBsnUlFJ0PzAdE-ZNmHFGWWu6UZITs0si1LlGw"
// 			},
// 			"baseURL": "http://localhost:3003/",
// 			"transformRequest": [
// 					null
// 			],
// 			"transformResponse": [
// 					null
// 			],
// 			"timeout": 30000,
// 			"withCredentials": false,
// 			"xsrfCookieName": "XSRF-TOKEN",
// 			"xsrfHeaderName": "X-XSRF-TOKEN",
// 			"maxContentLength": -1,
// 			"maxBodyLength": -1,
// 			"transitional": {
// 					"silentJSONParsing": true,
// 					"forcedJSONParsing": true,
// 					"clarifyTimeoutError": false
// 			}
// 	},
// 	"request": {}
// }
