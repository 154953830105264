import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CarouselModal from "../../../modals/CarouselModal";
import { setCurrentModalAssets } from "../../../ui/assets/actions";
import AssetCarouselCell from "../../details/components/AssetCarouselCell";
import { getCurrentModalAssets } from "../selectors";

const AssetCarouselModal = () => {
    const assetsList = useSelector(getCurrentModalAssets);
    const dispatch = useDispatch();

    if (assetsList.length === 0) {
        return null;
    }

    return (
        <CarouselModal>
            {assetsList.map((asset, idx) => {
                return (
                    <AssetCarouselCell
                        asset={asset}
                        key={idx}
                        closeModal={() => dispatch(setCurrentModalAssets([]))}
                    />
                );
            })}
        </CarouselModal>
    );
};

export default AssetCarouselModal;
