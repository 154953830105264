import { formatDuration, intervalToDuration } from "date-fns";
import React from "react";
import { OfferTypeParams } from "./modals/OfferTypeModalController";
import RateFormItem from "./RateFormItem";

interface Props {
    offerType: OfferTypeParams;
    edit: () => void;
}

const OfferTypeItem = ({ offerType, edit }: Props) => {
    const displayDuration = () => {
        if (offerType.permanent) return "Permanent";
        if (!offerType.duration) return "N/A";

        return formatDuration(
            intervalToDuration({
                start: 0,
                end: offerType.duration * 1000
            }),
            {
                format: ["days", "hours"]
            }
        );
    };

    return (
        <RateFormItem
            title={offerType.placementType || offerType.customType}
            description={displayDuration()}
            edit={edit}
        />
    );
};

export default OfferTypeItem;
