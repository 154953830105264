import { add, getUnixTime } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionsModal from "src/modals/ActionsModal";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import { updateGroupUserSettings } from "../actions";
import { selectGroupUserSettingsById } from "../selectors";

interface Props {
    userId: string;
    conversationId: string;
    closeModal: () => void;
}

const GroupUserMuteDurationModal = ({
    conversationId,
    closeModal,
    userId
}: Props) => {
    const dispatch = useDispatch();
    const userSettings = useSelector((state: GlobalState) =>
        selectGroupUserSettingsById(state, conversationId, userId)
    );

    function dispatchPauseDuration(duration: Duration, indefinitely?: true) {
        if (indefinitely) {
            dispatch(
                updateGroupUserSettings({
                    id: userSettings.id,
                    isMutedIndefinitely: true
                })
            );
        } else {
            const isMutedUntil = getUnixTime(add(new Date(), duration));
            dispatch(
                updateGroupUserSettings({ id: userSettings.id, isMutedUntil })
            );
        }
        closeModal();
    }

    return (
        <ActionsModal
            actions={[
                {
                    text: "30 Minutes",
                    callback: () => dispatchPauseDuration({ minutes: 30 })
                },
                {
                    text: "1 Hour",
                    callback: () => dispatchPauseDuration({ hours: 1 })
                },
                {
                    text: "1 Day",
                    callback: () => dispatchPauseDuration({ days: 1 })
                },
                {
                    text: "Indefinitely",
                    callback: () => dispatchPauseDuration({}, true)
                }
            ]}
            localClearModal={closeModal}
        />
    );
};
export default GroupUserMuteDurationModal;
