import React, { useState } from "react";
import { connect } from "react-redux";
import { Media } from "../../media/types";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import { colors, fonts } from "../../constants";
import {
    campaignCustomLinksModal,
    CustomLinksModalState
} from "../../ui/custom-links/actions";
import { GlobalState } from "../../reducers";
import { selectCampaign } from "../selectors";
import { Campaign } from "src/campaigns/types";
import { createCampaignCustomLink, CreateCustomLinkParams } from "./actions";

const SelectPanel = styled.div`
    padding: 10px 15px 0;
    background-color: ${colors.lightTeal};
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${colors.mediumTeal};
`;

const Block = styled.div`
    margin-bottom: 15px;
    text-align: left;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    color: ${colors.primaryGray};
`;

const SelectButton = styled(Block)`
    padding: 10px;
    background-color: white;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const MediaIcon = styled.img`
    height: 46px;
    width: 46px;
    min-width: 46px;
    border-radius: 6px;
    margin-right: 8px;
    object-fit: cover;
`;

const MediaTitle = styled.div`
    width: 100%;
    font-size: ${fonts.subtitle}px;
`;

const Caret = styled.img`
    margin-left: 8px;
`;

const Input = styled.input`
    height: 60px;
    width: 100%;
    padding: 0 10px;
    outline: none;
    font-weight: 300;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;

    &::placeholder {
        color: ${colors.primaryGray};
        font-weight: 300;
    }
`;

interface Props {
    currentCampaign?: Campaign;
    media: Media;
    openSelect: () => void;
    campaignCustomLinksModal: (state: CustomLinksModalState) => void;
    createCampaignCustomLink: (params: CreateCustomLinkParams) => void;
}

const CustomLinksCreate = ({
    currentCampaign,
    media,
    openSelect,
    campaignCustomLinksModal,
    createCampaignCustomLink
}: Props) => {
    const [linkName, setLinkName] = useState("");

    function imageUrl() {
        if (media.url && media.type === "photo") {
            return media.url;
        } else if (media.coverPhotoUrl) {
            return media.coverPhotoUrl;
        } else {
            return "/other_profile/other_profile@2x.png";
        }
    }

    const leftNavButton = (
        <NavButton
            onClick={() =>
                campaignCustomLinksModal(CustomLinksModalState.index)
            }
            back
        />
    );

    return (
        <>
            <SimpleNavbar title={"Custom Links"} left={leftNavButton} />
            <SelectPanel>
                <Block>
                    <Title>Select ad</Title>
                    <Subtitle>Which ad will you use this link for?</Subtitle>
                </Block>
                <SelectButton onClick={openSelect}>
                    <MediaIcon src={imageUrl()} />
                    <MediaTitle>
                        <strong>Media Id: {media.id}</strong>
                    </MediaTitle>
                    <Caret src={"/caret.svg"} />
                </SelectButton>
                <Block>
                    <Title>Link Name</Title>
                    <Subtitle>Give the link a custom name</Subtitle>
                </Block>
                <Block>
                    <Input
                        value={linkName}
                        onChange={e => setLinkName(e.currentTarget.value)}
                        placeholder={`${currentCampaign &&
                            currentCampaign.campaignName} - @myaccount - Ad 01`}
                    />
                </Block>
            </SelectPanel>
            <div style={{ padding: "15px" }}>
                <Button
                    block
                    variant="success"
                    disabled={!linkName}
                    onClick={() => {
                        if (linkName && currentCampaign) {
                            campaignCustomLinksModal(
                                CustomLinksModalState.index
                            );
                            createCampaignCustomLink({
                                mediaId: media.id,
                                campaignId: currentCampaign.id,
                                name: linkName
                            });
                        }
                    }}
                >
                    Create
                </Button>
            </div>
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    currentCampaign: selectCampaign(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createCampaignCustomLink: (params: CreateCustomLinkParams) =>
        dispatch(createCampaignCustomLink(params)),
    campaignCustomLinksModal: (state: CustomLinksModalState) =>
        dispatch(campaignCustomLinksModal(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomLinksCreate);
