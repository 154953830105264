import React from "react";
import { Route, Switch } from "react-router";
import FullscreenLayout from "src/components/layout/FullscreenLayout";
import ChatRoomController from "./ChatRoomController";
import ConversationsList from "./ConversationsList";

const NewChatRouter = () => {
    return (
        <FullscreenLayout>
            <Switch>
                <Route
                    path="/newchat/:conversationId"
                    render={({ match }) => (
                        <ChatRoomController
                            conversationId={match.params.conversationId}
                        />
                    )}
                />
                <Route path="/newchat" component={ConversationsList} />
            </Switch>
        </FullscreenLayout>
    );
};

export default NewChatRouter;
