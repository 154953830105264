import {
    Action,
    NormalizedActionGeneric,
    NormalizedPayload,
    NormalizedResponse
} from "src/types";
import { Media } from "./types";
import { MediaRule } from "src/campaigns/types";
import { CampaignMedia } from "src/campaigns/types";
import { PlacementConfig } from "src/ui/placements/reducer";

// Media Rules ---------------------------------------------------------------

export const FETCH_MEDIA_RULES = {
    REQUEST: "FETCH_MEDIA_RULES_REQUEST",
    SUCCESS: "FETCH_MEDIA_RULES_SUCCESS",
    FAILURE: "FETCH_MEDIA_RULES_FAILURE"
};

export const fetchMediaRules = (campaign_id: number): Action => ({
    type: FETCH_MEDIA_RULES.REQUEST,
    payload: { campaign_id }
});

export const fetchMediaRulesSuccess = (mediaRules: MediaRule[]): Action => ({
    type: FETCH_MEDIA_RULES.SUCCESS,
    payload: { mediaRules }
});

export const fetchMediaRulesFailure = (error: string): Action => ({
    type: FETCH_MEDIA_RULES.FAILURE,
    payload: { error }
});

// Upload Custom Media ---------------------------------------------------------------

export interface CustomMediaUpload {
    campaign_id: number;
    filename: string;
    cover_photo: string;
    type: string;
    watermark_type: string;
    allow_others_to_use: boolean;
    external_page_id: number | null;
    placements: PlacementConfig;
}
export interface CustomAdminMediaUpload {
    campaign_id: number;
    filename: string;
    original_filename: string;
    cover_photo: string;
    type: string;
    allow_others_to_use: boolean;
    external_page_id: number | null;
    uploading: boolean;
    url: string;
    is_asset: boolean;
}

export const UPLOAD_CUSTOM_MEDIA = {
    REQUEST: "UPLOAD_CUSTOM_MEDIA_REQUEST",
    SUCCESS: "UPLOAD_CUSTOM_MEDIA_SUCCESS",
    FAILURE: "UPLOAD_CUSTOM_MEDIA_FAILURE"
};

export const uploadCustomMedia = (payload: CustomMediaUpload): Action => ({
    type: UPLOAD_CUSTOM_MEDIA.REQUEST,
    payload
});

export const uploadCustomMediaFailure = (error: string): Action => ({
    type: UPLOAD_CUSTOM_MEDIA.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const FETCH_SEARCH_MEDIA = {
    REQUEST: "FETCH_SEARCH_MEDIA_REQUEST",
    SUCCESS: "FETCH_SEARCH_MEDIA_SUCCESS",
    FAILURE: "FETCH_SEARCH_MEDIA_FAILURE"
};

export const fetchSearchMedia = (): Action => ({
    type: FETCH_SEARCH_MEDIA.REQUEST,
    payload: {}
});

export const fetchSearchMediaSuccess = (
    response: NormalizedResponse<Media, number[]>,
    page: number
): NormalizedActionGeneric<Media, number[]> => ({
    type: FETCH_SEARCH_MEDIA.SUCCESS,
    response,
    payload: { page }
});

export const SELECT_SEARCH_MEDIA = {
    SELECT: "SELECT_SEARCH_MEDIA",
    REMOVE: "REMOVE_SEARCH_MEDIA",
    CLEAR: "CLEAR_SEARCH_MEDIA"
};
export const selectMedia = (mediaId: number): Action => ({
    type: SELECT_SEARCH_MEDIA.SELECT,
    payload: mediaId
});
export const removeSelectedMedia = (mediaId: number): Action => ({
    type: SELECT_SEARCH_MEDIA.REMOVE,
    payload: mediaId
});
export const clearSelectMedia = (): Action => ({
    type: SELECT_SEARCH_MEDIA.CLEAR,
    payload: []
});

// Modals
// -------------------------------------------------------------------

export const MODAL = {
    RULES: "MODAL_RULES"
};

export const rulesModal = (): Action => ({
    type: MODAL.RULES,
    payload: null
});

// Set Current Campaign Media ---------------------------------------------------------------

export const SET_CURRENT_CAMPAIGN_MEDIA = "SET_CURRENT_CAMPAIGN_MEDIA";

export function setCurrentCampaignMedia(campaignMedia: Media): Action {
    return {
        type: SET_CURRENT_CAMPAIGN_MEDIA,
        payload: campaignMedia
    };
}

// Fetch Media ---------------------------------------------------------------

export const FETCH_MEDIA = {
    REQUEST: "FETCH_MEDIA_REQUEST",
    SUCCESS: "FETCH_MEDIA_SUCCESS",
    FAILURE: "FETCH_MEDIA_FAILURE"
};

export function fetchMedia(): Action {
    return {
        type: FETCH_MEDIA.REQUEST,
        payload: null
    };
}

export function fetchMediaSuccess(medias: NormalizedPayload): Action {
    return {
        type: FETCH_MEDIA.SUCCESS,
        payload: medias
    };
}
// Admin Actions -------------------------------------------------------------
export const UPDATE_MEDIA = {
    REQUEST: "UPDATE_MEDIA_REQUEST",
    SUCCESS: "UPDATE_MEDIA_SUCCESS",
    FAILURE: "UPDATE_MEDIA_FAILURE"
};
export const updateMediaRequest = (
    newMedia: Media | CampaignMedia,
    notification: string
) => ({
    type: UPDATE_MEDIA.REQUEST,
    payload: { media: newMedia, notification }
});
export const updateMediaSuccess = (media: NormalizedPayload) => ({
    type: UPDATE_MEDIA.SUCCESS,
    response: media
});
export const DELETE_MEDIA = {
    REQUEST: "DELETE_MEDIA_REQUEST",
    SUCCESS: "DELETE_MEDIA_SUCCESS",
    FAILURE: "DELETE_MEDIA_FAILURE"
};

export const deleteMediaRequest = (mediaId: number, campaignId: number) => ({
    type: DELETE_MEDIA.REQUEST,
    payload: { mediaId, campaignId }
});
export const deleteMediaSuccess = (mediaId: number, campaignId: number) => ({
    type: DELETE_MEDIA.SUCCESS,
    payload: { mediaId, campaignId }
});
