import { NormalizedAction } from "../../types";
import { MediaRule } from "../types";
import { FETCH_CAMPAIGN_MEDIA_RULES } from "./actions";

export interface MediaRulesEntitiesState {
    [id: number]: MediaRule;
}

const initialMediaRulesState: MediaRulesEntitiesState = {};

const mediaRulesEntitiesReducer = (
    state = initialMediaRulesState,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_CAMPAIGN_MEDIA_RULES.SUCCESS:
            return { ...state, ...action.response.entities.mediaRules };
        default:
            return state;
    }
};

export default mediaRulesEntitiesReducer;
