import React from "react";
import { AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import HalfScreenModal from "src/modals/HalfScreenModal";
import styled from "styled-components";
import SimpleNavbar from "src/components/SimpleNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors, fonts } from "src/constants";

interface Props {
    onClose: () => void;
    onSelect: (value: string) => void;
}

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
`;

const Title = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 700;
    white-space: nowrap;
    padding-bottom: 20px;
`;

const SubTitle = styled.div`
    font-size: ${fonts.smallTitle}px;
    font-weight: 400;
    text-align: left;
    width: 100%;
    padding-left: 18px;
`;

const Option = styled.div`
    display: flex;
    flex-direction: row;
    font-size: ${fonts.subtitle}px;
    justify-content: space-between;
    padding: 26px 20px 26px 20px;
    border-bottom: 1px solid ${colors.mediumTeal};
    cursor: pointer;
`;

const PromoteSelectionModal = ({ onClose, onSelect }: Props) => {
    const options = [
        { value: "upload", name: "Make Your Own Ad" },
        { value: "select-media", name: "Repost Pre-made Ad" }
    ];
    return (
        <HalfScreenModal closeModal={onClose}>
            <Header>
                <Title>Promote</Title>
                <SubTitle>
                    How would you like to promote this campaign?
                </SubTitle>
            </Header>
            {options.map((option, index) => (
                <Option
                    key={`promote-option-${index}`}
                    onClick={() => onSelect(option.value)}
                >
                    {option.name}
                    <FontAwesomeIcon icon="chevron-right" />
                </Option>
            ))}
        </HalfScreenModal>
    );
};

export default PromoteSelectionModal;
