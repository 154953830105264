import React, { useEffect, useState } from "react";
import FullScreenModal from "src/modals/FullScreenModal";
import styled from "styled-components";
import OpenBuyCustomMediaSubmission from "./OpenBuyCustomMediaSubmision";
import {
    OpenBuy,
    OpenBuyInstructionStep,
    OpenBuySubmission
} from "src/buys/modules/open-buys/types";
import OpenBuyPostSubmission from "./OpenBuyPostSubmission";
import { SocialAccount } from "src/profile/types";
import SocialAccountSelector from "./SocialAccountSelector";
import Platforms from "src/social-accounts/Platforms";
import { shortenNumber } from "src/util";
import SocialAccountHeader from "src/components/social-accounts/SocialAccountHeader";
import SimpleNavbar from "src/components/SimpleNavbar";
import { useDispatch } from "react-redux";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    padding: 18px;
    gap: 12px;
`;

interface Props {
    submission?: Partial<OpenBuySubmission>;
    openBuy: OpenBuy;
    step: OpenBuyInstructionStep;
    socialAccount?: SocialAccount | null;
    setSelectSocial: (val: boolean) => void;
}

const OpenBuySubmissionEditor = ({
    submission,
    openBuy,
    step,
    socialAccount,
    setSelectSocial
}: Props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (!socialAccount) setSelectSocial(true);
    }, [socialAccount]);

    if (!socialAccount) return null;
    return (
        <Main>
            <SocialAccountHeader
                username={socialAccount.username}
                picture={
                    socialAccount.profilePhotoUrl ||
                    Platforms[socialAccount.platform]?.imgSrc
                }
                count={`${shortenNumber(socialAccount.followers)} followers`}
                onClick={() => setSelectSocial(true)}
            />
            {step.requiresCustomMedia ? (
                <OpenBuyCustomMediaSubmission
                    submission={submission || {}}
                    openBuyId={openBuy.id}
                    instructionStep={step}
                    type="media"
                    socialAccountId={socialAccount.id}
                />
            ) : (
                <OpenBuyPostSubmission
                    submission={submission || {}}
                    socialAccountId={socialAccount.id}
                    instructionStep={step}
                    openBuyId={openBuy.id}
                />
            )}
        </Main>
    );
};

export default OpenBuySubmissionEditor;
