import React, { useState } from "react";
import FullScreenModal from "./FullScreenModal";
import styled from "styled-components";
import { BigBlueButton } from "src/profile/components/Buttons";
import Divider from "src/components/Divider";
import CheckBox from "src/components/CheckBox";
import { colors } from "src/constants";
import { patch, post } from "src/Api";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInProfile } from "src/profile/selectors";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { setSMSToSModalOpen } from "src/ui/modals/actions";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Section = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Header = styled.div`
    font-size: 18px;
    font-weight: 600;
    padding: 12px;
    border-bottom: 1px solid ${colors.lightGray2};
`;

const Title = styled.div`
    font-size: 32px;
    font-weight: 600;
    text-align: left;
    padding: 10px 0px;
`;

const Description = styled.p`
    text-align: left;
    padding: 5px;
`;

const CheckRow = styled.div`
    display: flex;
    gap: 6px;
`;
const CheckDescription = styled.div`
    font-size: 16px;
    text-align: left;
`;

const ToSModal = () => {
    const dispatch = useDispatch();
    const [tosCheck, setToSCheck] = useState(false);

    const profile = useSelector(getLoggedInProfile);

    const acceptTerms = async () => {
        try {
            if (!profile) {
                throw new Error("Failed to get profile");
            }
            await post(`/v1/publisher/p:${profile.id}/flag/tos_agreement`, {
                value: "yes"
            });
            dispatch(setSMSToSModalOpen(false));
            dispatch(setPillNotificationText("Terms Accepted", "success"));
        } catch (err) {
            dispatch(
                setPillNotificationText("Error accepting terms", "danger")
            );
        }
    };

    return (
        <FullScreenModal>
            <Main>
                <Header> Terms of Service</Header>
                <Section>
                    <Title>Terms & Conditions</Title>
                    <Description>
                        The Plug is an ad network to help enable the purchase
                        and sale of mobile advertisements (collectively, the
                        "Services"). The Plug...
                    </Description>
                    <BigBlueButton
                        onClick={() => window.open("http://plugco.in/terms")}
                    >
                        View Full Terms & Conditions
                    </BigBlueButton>
                </Section>
                <Section style={{ marginTop: "auto", marginBottom: "40px" }}>
                    <Divider />
                    <CheckRow>
                        <CheckBox
                            style={{ marginLeft: 0 }}
                            selected={tosCheck}
                            onCheck={() => setToSCheck(true)}
                            onUncheck={() => setToSCheck(false)}
                        />
                        <CheckDescription>
                            I agree with the Terms of Service
                        </CheckDescription>
                    </CheckRow>
                    <BigBlueButton
                        onClick={() =>
                            tosCheck
                                ? acceptTerms()
                                : dispatch(
                                      setPillNotificationText(
                                          "You must accept the Terms of Service to use The Plug",
                                          "danger"
                                      )
                                  )
                        }
                    >
                        Accept
                    </BigBlueButton>
                </Section>
            </Main>
        </FullScreenModal>
    );
};

export default ToSModal;
