import React from "react";
import { Switch, Route } from "react-router";
import RateForm from "./RateForm";

const RatesRouter = () => (
    <Switch>
        <Route
            path="/profile/rate/add/:publisherOfferId?"
            render={({ match }) => (
                <RateForm
                    publisherOfferId={
                        parseInt(match.params.publisherOfferId || "") || null
                    }
                />
            )}
        />
        <Route
            path="/profile/rate/edit/:publisherOfferId"
            render={({ match }) => (
                <RateForm
                    edit
                    publisherOfferId={
                        parseInt(match.params.publisherOfferId) || null
                    }
                />
            )}
        />
    </Switch>
);

export default RatesRouter;
