import React, { useState } from "react";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";

const TabPanel = styled.div`
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
`;

export interface DesktopTab {
    label: string;
    component: JSX.Element;
    key: string;
}

interface Props {
    items: DesktopTab[];
    currentTab: number;
    onChange: (index: number) => void;
}

const DesktopTabs = ({ items, currentTab, onChange }: Props) => {
    const [previousTab, setPreviousTab] = useState(currentTab);

    const transition = useTransition(items[currentTab], p => p.key, {
        unique: true,
        from: tab => {
            return {
                transform: `translate3d(${(currentTab - previousTab) *
                    100}%, 0, 0)`,
                position: "static"
            };
        },
        enter: {
            transform: "translate3d(0%, 0, 0)",
            position: "static"
        },

        leave: tab => ({
            transform: `translate3d(${(previousTab - currentTab) *
                100}%, 0, 0)`,
            zIndex: 1,
            position: "absolute"
            // boxShadow: "0px 0px 30px rgba(0,0,0,0.3)"
        })
    });

    if (currentTab !== previousTab) setPreviousTab(currentTab);

    return (
        <TabPanel>
            {transition.map(({ item, props, key }) => (
                <animated.div
                    key={key}
                    style={{
                        ...props,
                        width: "100%"
                    }}
                    onClick={() => onChange(currentTab)}
                >
                    {item.component}
                </animated.div>
            ))}
        </TabPanel>
    );
};

export default DesktopTabs;
