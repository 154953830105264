import React from "react";
import CheckBox from "src/components/CheckBox";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import ProfilePhoto from "src/components/ProfilePhoto";
import { colors } from "src/constants";
import Platforms from "src/social-accounts/Platforms";
import { shortenNumber } from "src/util";
import styled from "styled-components";

const Main = styled.div`
    ${FlexVerticallyCentered}
    cursor: pointer;
    user-select: none;
`;

export const Title = styled.div`
    font-size: 13px;
    font-weight: 500;
    line-height: 15.51px;

    mark {
        padding: 0;
    }
`;

export const Subtitle = styled.div`
    font-size: 12px;
    color: ${colors.primaryGray};
    margin-top: 6px;
    line-height: 14.32px;

    span {
        font-weight: 700;
    }
`;

interface Props {
    profilePhotoUrl: string | null;
    username: string | null;
    tagLine: {
        followerCount: number | null;
        platform: string | null;
    };
    status?: string;
    selected: boolean;
}

const ChatUserHitCell = ({
    profilePhotoUrl,
    username,
    tagLine,
    status,
    selected
}: Props) => (
    <Main>
        <ProfilePhoto
            photoSize={46}
            profilePhotoUrl={profilePhotoUrl}
            style={{ marginRight: 12 }}
            status={status}
        />
        <div>
            <Title>{username}</Title>
            <Subtitle>
                <span>
                    {tagLine.followerCount
                        ? shortenNumber(tagLine.followerCount)
                        : 0}
                </span>
                {" on "}
                {tagLine.platform && Platforms[tagLine.platform]
                    ? Platforms[tagLine.platform].title
                    : Platforms.other.title}
            </Subtitle>
        </div>
        <CheckBox selected={!!selected} />
    </Main>
);

export default ChatUserHitCell;
