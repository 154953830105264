import {
    all,
    call,
    fork,
    put,
    select,
    takeEvery,
    takeLeading
} from "redux-saga/effects";
import { Action } from "../types";
import { deleteReq, get } from "../Api";
import { normalize } from "normalizr";
import * as schema from "./schema";
import { DirectBuy, DirectBuyStatus } from "./modules/direct-buy/types";
import {
    addDirectBuyEntities,
    REQUEST_DIRECT_BUY_BY_ID,
    REQUEST_DIRECT_BUYS_BY_STATUS,
    REQUEST_OPEN_BUY_BY_ID,
    REQUEST_OPEN_BUYS_BY_STATUS,
    addOpenBuyEntities,
    addOpenBuyStepsEntities,
    FETCH_OPEN_BUY_SUBMISSIONS,
    addOpenBuySubmissions,
    DELETE_OPEN_BUY_SUBMISSION,
    setSubmissionSets,
    FETCH_SUBMISSION_SETS,
    FETCH_SUBMISSION_SET,
    setOpenBuySubmissions
} from "./actions";
import {
    clearDirectBuySearch,
    setDirectBuyErrorState,
    setDirectBuySearch,
    setDirectBuysLoading,
    setStoredStatuses
} from "../ui/direct-buys/actions";
import {
    selectDirectBuysAllIds,
    selectDirectBuyStoredStatuses
} from "./selectors";
import { DirectBuyError } from "../ui/direct-buys/reducer";
import axios from "axios";
import { flattenOpenBuyForNormalize } from "./modules/open-buys/util";
import {
    fetchOpenBuyLeaderboard,
    fetchOpenBuyLeaderboardSuccess,
    FETCH_OPEN_BUY_LEADERBOARD
} from "./details/leaderboard/actions";
import { SubmissionReport } from "src/marketplace/reports/types";

function* requestDirectBuyById({ payload }: Action) {
    try {
        const { data } = yield call(get, `/v1/directBuy/${payload}`, {
            includeSteps: true
        });
        yield put(
            addDirectBuyEntities(normalize([data.data], schema.directBuyList))
        );
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const status = error.response?.status;
            if (status === 404) {
                yield put(
                    setDirectBuyErrorState(payload, DirectBuyError.notFound)
                );
            } else if (status === 401) {
                yield put(
                    setDirectBuyErrorState(payload, DirectBuyError.accessDenied)
                );
            } else {
                yield put(
                    setDirectBuyErrorState(payload, DirectBuyError.generic)
                );
            }
        }
    }
}

function* requestDirectBuysByStatus({ payload }: Action) {
    try {
        yield put(setDirectBuysLoading(true));
        yield put(clearDirectBuySearch());
        const requestedStatuses: DirectBuyStatus[] = payload.status;
        const storedStatuses: DirectBuyStatus[] = yield select(
            selectDirectBuyStoredStatuses
        );
        const missingStatus: DirectBuyStatus[] = payload.daterange
            ? requestedStatuses
            : requestedStatuses.filter(
                  status => !storedStatuses.includes(status)
              );

        if (missingStatus.length > 0 || payload.daterange) {
            const { data } = yield call(get, "/v1/directBuy", {
                includeSteps: true,
                status: missingStatus.join(","),
                offerStartsAtRange: payload.daterange
            });
            yield put(setStoredStatuses([...storedStatuses, ...missingStatus]));
            if (payload.daterange) {
                const ids: number[] = data.data.map((buy: DirectBuy) => buy.id);
                yield put(setDirectBuySearch(ids));
            }
            yield put(
                addDirectBuyEntities(
                    normalize(data.data, schema.directBuyList),
                    missingStatus
                )
            );
        }
    } catch (error) {
        console.log("error getting open buy: ", error);
    }
    yield put(setDirectBuysLoading(false));
}

function* requestOpenBuyById({ payload }: Action) {
    try {
        const { data } = yield call(
            get,
            `${payload.isPublic ? "/public" : ""}/v1/openBuy/${
                payload.openBuyId
            }`,
            {
                includeInstructionSet: true,
                includeSupplements: true,
                includeSubmissions: true,
                includeSubmissionSteps: true,
                includePosters: true,
                includeRules: true,
                includeTags: true
            }
        );
        yield fork(
            fetchOpenBuyLeaderboardRequest,
            fetchOpenBuyLeaderboard(payload.openBuyId)
        );
        yield put(
            addOpenBuyEntities(
                normalize(
                    flattenOpenBuyForNormalize([
                        {
                            ...data.data,
                            isPublic: payload.isPublic
                        }
                    ]),
                    schema.openBuyList
                ),
                true
            )
        );
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const status = error.response?.status;
            if (status === 404) {
                if (!payload.isPublic) {
                    const { data } = yield call(
                        get,
                        `/public/v1/openBuy/${payload.openBuyId}`,
                        {
                            includeInstructionSet: true,
                            includeSupplements: true,
                            includeSubmissions: true,
                            includeSubmissionSteps: true,
                            includePosters: true,
                            includeRules: true,
                            includeTags: true
                        }
                    );
                    yield put(
                        addOpenBuyEntities(
                            normalize(
                                flattenOpenBuyForNormalize([
                                    { ...data.data, isPublic: true }
                                ]),
                                schema.openBuyList
                            ),
                            true
                        )
                    );
                } else
                    yield put(
                        setDirectBuyErrorState(
                            payload.openBuyId,
                            DirectBuyError.notFound
                        )
                    );
            } else if (status === 401) {
                yield put(
                    setDirectBuyErrorState(
                        payload.openBuyId,
                        DirectBuyError.accessDenied
                    )
                );
            } else {
                yield put(
                    setDirectBuyErrorState(
                        payload.openBuyId,
                        DirectBuyError.generic
                    )
                );
            }
        } else console.log("error: ", error);
    }
}

function* requestOpenBuysByStatus({ payload }: Action) {
    try {
        yield put(setDirectBuysLoading(true));
        const { data } = yield call(get, "/v1/openBuy", {
            includeInstructionSet: true,
            includeSupplements: true,
            includeSubmissions: true,
            includeSubmissionSteps: true,
            includePosters: true,
            includeRules: true,
            includeTags: true
        });
        yield put(
            addOpenBuyEntities(
                normalize(
                    flattenOpenBuyForNormalize(data.data),
                    schema.openBuyList
                ),
                false
            )
        );
        yield put(
            addOpenBuyStepsEntities(
                normalize(
                    flattenOpenBuyForNormalize(data.data),
                    schema.openBuyList
                )
            )
        );
        const ids = data.data.map((buy: any) => buy.openBuyId);
        yield put(setDirectBuySearch(ids));
    } catch (error) {
        console.log(error);
    }
    yield put(setDirectBuysLoading(false));
}

function* requestOpenBuySubmissions({ payload }: Action) {
    try {
        yield put(setDirectBuysLoading(true));
        const { data } = yield call(get, "/v1/openBuy/submission", {
            openBuyId: payload.openBuyId
        });
        yield put(addOpenBuySubmissions(data.data, payload.openBuyId));
    } catch (error) {
        console.log(error);
    }
    yield put(setDirectBuysLoading(false));
}

function* requestOpenBuySubmissionSets({ payload }: Action) {
    try {
        const { data } = yield call(
            get,
            `/v1/openBuy/${payload}/submissionSets`,
            {}
        );
        yield put(
            setSubmissionSets(
                normalize(data.data, schema.openBuySubmissionSetList)
            )
        );
        yield put(setDirectBuysLoading(false));
    } catch (error) {
        console.log(error);
    }
}

function* requestOpenBuySubmissionSet({ payload }: Action) {
    try {
        const { data } = yield call(
            get,
            `/v1/admin/openBuySubmissionSet/${payload}`,
            { includeSubmissions: true, includeSocialAccount: true }
        );
        yield put(
            setSubmissionSets(
                normalize([data.data], schema.openBuySubmissionSetList)
            )
        );
        yield put(
            addOpenBuySubmissions(data.data.submissions, data.data.openBuyId)
        );
    } catch (error) {
        console.log(error);
    }
}

function* fetchOpenBuyLeaderboardRequest({ payload: { openBuyId } }: Action) {
    try {
        const { data } = yield call(
            get,
            `/v1/openBuy/${openBuyId}/submissionLeaderboard`,
            {}
        );

        let place = 0;
        const placedReports = data.data.map((report: SubmissionReport) => {
            if (!!report.media) {
                place += 1;
                return { ...report, place };
            }
            return report;
        });

        yield put(
            fetchOpenBuyLeaderboardSuccess(
                openBuyId,
                normalize(placedReports, schema.openBuyLeaderboard)
            )
        );
    } catch (error) {
        console.log(error);
    }
}

function* deleteOpenBuySubmission({ payload }: Action) {
    try {
        const { data } = yield call(
            deleteReq,
            `/v1/openBuy/submission/${payload.submissionId}`,
            {}
        );
    } catch (error) {}
}

export default function* buysSaga() {
    yield all([
        takeEvery(DELETE_OPEN_BUY_SUBMISSION, deleteOpenBuySubmission),
        takeEvery(REQUEST_DIRECT_BUY_BY_ID, requestDirectBuyById),
        takeEvery(REQUEST_DIRECT_BUYS_BY_STATUS, requestDirectBuysByStatus),
        takeEvery(REQUEST_OPEN_BUY_BY_ID, requestOpenBuyById),
        takeLeading(REQUEST_OPEN_BUYS_BY_STATUS, requestOpenBuysByStatus),
        takeEvery(FETCH_OPEN_BUY_SUBMISSIONS, requestOpenBuySubmissions),
        takeEvery(
            FETCH_OPEN_BUY_LEADERBOARD.REQUEST,
            fetchOpenBuyLeaderboardRequest
        ),
        takeEvery(FETCH_SUBMISSION_SETS, requestOpenBuySubmissionSets),
        takeEvery(FETCH_SUBMISSION_SET, requestOpenBuySubmissionSet)
    ]);
}
