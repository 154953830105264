import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { Profile, ProfileTabState, PublicProfileData } from "../types";
import { fetchPublic, FetchPublicParams } from "../actions";
import { Route, Switch } from "react-router";
import PublicMain from "./PublicMain";
import PublicConnectionsRouter from "../connections/public/PublicConnectionsRouter";
import PublicAccountsRouter from "../accounts/public/PublicAccountsRouter";
import PublicCustomMediaPage from "../custom-media/public/page";
import PublicPromotedPage from "../promoted/public/page";
import NotFound from "./NotFound";
import { PublicProfileError } from "../reducer";
import TryAgain from "./TryAgain";
import NotFoundPage from "src/navigation/NotFound";

interface Props {
    profile: Profile;
    otherId: string;
}

const PublicRouter = ({ profile, otherId }: Props) => {
    const publicDataStore = useSelector((state: GlobalState) => {
        return state.profile.publicDataStore;
    });

    const publicErrorStore = useSelector((state: GlobalState) => {
        return state.profile.publicErrorStore;
    });

    const dispatch = useDispatch();
    function dispatchFetchPublic(params: FetchPublicParams): void {
        dispatch(fetchPublic(params));
    }

    useEffect(() => {
        if (!publicDataStore[otherId]) {
            dispatchFetchPublic({ selfId: profile.id, otherId });
        }
        window.scrollTo(0, 0);
    }, [profile, otherId]);

    if (
        publicErrorStore[otherId] &&
        publicErrorStore[otherId] === PublicProfileError.reloadable
    ) {
        return (
            <TryAgain
                reloadCallback={() => {
                    dispatchFetchPublic({ selfId: profile.id, otherId });
                }}
            />
        );
    } else if (publicErrorStore[otherId]) {
        return <NotFound />;
    } else {
        return (
            <Switch>
                <Route
                    exact
                    path={"/public/:otherId/custom-ads"}
                    render={() => {
                        return (
                            <PublicCustomMediaPage
                                publicData={publicDataStore[otherId]}
                            />
                        );
                    }}
                />

                <Route
                    exact
                    path={"/public/:otherId/promoted"}
                    render={() => {
                        return (
                            <PublicPromotedPage
                                publicData={publicDataStore[otherId]}
                            />
                        );
                    }}
                />

                <Route
                    path={"/public/:otherId/accounts"}
                    render={() => {
                        return (
                            <PublicAccountsRouter
                                publicData={publicDataStore[otherId]}
                            />
                        );
                    }}
                />

                <Route
                    path={"/public/:otherId/connections"}
                    render={() => {
                        return (
                            <PublicConnectionsRouter
                                publicData={publicDataStore[otherId]}
                            />
                        );
                    }}
                />

                <Route
                    exact
                    path={"/public/:otherId/activity"}
                    render={() => {
                        return (
                            <PublicMain
                                publicData={publicDataStore[otherId]}
                                tabState={ProfileTabState.activity}
                            />
                        );
                    }}
                />

                <Route
                    exact
                    path={"/public/:otherId"}
                    render={() => {
                        return (
                            <PublicMain
                                publicData={publicDataStore[otherId]}
                                tabState={ProfileTabState.about}
                            />
                        );
                    }}
                />

                <Route component={NotFoundPage} />
            </Switch>
        );
    }
};

export default PublicRouter;
