import React from "react";
import styled from "styled-components";

const Container = styled.div`
    position: relative;
`;

interface StyleProps {
    clickable: boolean;
    border?: string;
}

const Photo = styled.img<StyleProps>`
    height: 100%;
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
    display: block;
    ${props => props.clickable && "cursor: pointer;"}
    ${props => props.border && `border: ${props.border}`}
`;

const Status = styled.img`
    height: 28%;
    width: 28%;
    position: absolute;
    bottom: 0;
    right: 0;
`;

const Crown = styled.img`
    height: 30px;
    width: 38px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -24px;
`;

interface Props {
    profilePhotoUrl?: string | null;
    photoSize: number;
    status?: string;
    statusSize?: number;
    style?: any;
    onClick?: () => any;
    first?: boolean;
    photoBorder?: string;
}

const ProfilePhoto = (props: Props) => {
    const {
        profilePhotoUrl,
        photoSize,
        status,
        statusSize,
        style,
        onClick,
        first,
        photoBorder
    } = props;

    function statusIcon() {
        switch (status) {
            case "diamond":
                return "/diamond/diamond@2x.png";
            case "gold":
                return "/gold/gold@2x.png";
            case "silver":
                return "/silver/silver@2x.png";
            case "employee":
                return "/logo.svg";
            default:
                return "";
        }
    }

    return (
        <Container
            style={{
                ...style,
                height: `${photoSize}px`,
                width: `${photoSize}px`,
                minHeight: `${photoSize}px`,
                minWidth: `${photoSize}px`
            }}
        >
            <Photo
                onClick={onClick}
                clickable={Boolean(onClick)}
                border={photoBorder}
                src={
                    profilePhotoUrl ||
                    (onClick
                        ? "/profile_pic_blue/profile_pic_blue@2x.png"
                        : "/no_profile_photo.svg")
                }
            />
            {status && status !== "none" && (
                <Status
                    src={statusIcon()}
                    style={{
                        height: `${statusSize}px`,
                        width: `${statusSize}px`
                    }}
                />
            )}
            {first && <Crown src={"/crown/crown@2x.png"} />}
        </Container>
    );
};

export default ProfilePhoto;
