import React from "react";
import styled from "styled-components";
import AnalyticsIcon from "./AnalyticsIcon";
import { breakpoints, colors } from "../constants";
import { fonts } from "src/constants";

const VerticallyCentered = styled.div`
    display: flex;
    align-items: center;
`;

const Main = styled(VerticallyCentered)`
    margin-top: 15px;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (pointer: fine) {
        padding: 6px;
        border: ${colors.mediumTeal} solid 1px;
        border-radius: 8px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const Info = styled.div`
    overflow: hidden;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const HideOverflow = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;
`;

const Title = styled(HideOverflow)`
    font-size: ${fonts.subtitle}px;
`;

const Subtitle = styled(HideOverflow)`
    font-size: 12px;
    color: ${colors.primaryGray};
`;

const Checkbox = styled.img`
    padding-left: 10px;
    margin-left: auto;
`;

interface Props {
    title: string;
    subtitle: string;
    iconUrl: string | null;
    video: boolean;
    rowClick: () => void;
    imageClick: (e: any) => void;
    checked: boolean;
}

const AnalyticsCampaignMediaRow = ({
    title,
    subtitle,
    iconUrl,
    video,
    rowClick,
    imageClick,
    checked
}: Props) => {
    return (
        <Main onClick={rowClick}>
            <AnalyticsIcon
                mediaUrl={iconUrl}
                video={video}
                onClick={imageClick}
            />
            <Info>
                <Title>
                    <strong>{title}</strong>
                </Title>
                <Subtitle>{subtitle}</Subtitle>
            </Info>
            <Checkbox
                src={
                    checked
                        ? "/checkbox_selected.svg"
                        : "/checkbox_unselected.svg"
                }
            />
        </Main>
    );
};

export default AnalyticsCampaignMediaRow;
