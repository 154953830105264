import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SelectionButton from "src/components/SelectionButton";
import { GlobalState } from "src/reducers";
import { Filters } from "src/ui/creators/reducers";
import {
    // creatorGroupsMultiSelectionFilterOptions,
    MultiSelectionFilterTypes
} from "../utils/constants";
import { checkSelected, toggleFilterSelection } from "../utils/helpers";
import { FilterType } from "../utils/types";

interface Props {
    searchString: string;
    // multiSelectionFilterType: MultiSelectionFilterTypes | "";
    multiSelectionFilterType: FilterType | "";
}

const MultiSelectionFilters = ({
    searchString,
    multiSelectionFilterType
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const { creatorInterests, creatorThemes } = useSelector(
        (state: GlobalState) => {
            return state.ui.creatorGroups;
        }
    );

    const { interestsFilters, themesFilters } = useSelector(
        (state: GlobalState) => {
            return state.ui.creators;
        },
        shallowEqual
    );

    // Local Functions -----------------------------------------------
    function selectCreatorGroupFilter(): string[] {
        if (multiSelectionFilterType === "Interests") return creatorInterests;
        else return creatorThemes;
    }

    function selectCreatorsFilter(): Filters {
        if (multiSelectionFilterType === "Interests") return interestsFilters;
        else return themesFilters;
    }

    // JSX -----------------------------------------------------------
    return (
        <>
            {multiSelectionFilterType !== "" &&
                selectCreatorGroupFilter()
                    .filter((option: string) => {
                        if (searchString === "") {
                            return true;
                        } else {
                            return option
                                .toLowerCase()
                                .includes(searchString.toLowerCase());
                        }
                    })
                    .map((option: string, index: number) => {
                        return (
                            <SelectionButton
                                key={`marketplace-creator-groups-${multiSelectionFilterType}-${option}-${index}`}
                                // icon={selectCategoryFilterIcon(option)}
                                text={option}
                                selected={checkSelected(
                                    multiSelectionFilterType,
                                    option,
                                    selectCreatorsFilter()
                                )}
                                setSelected={() =>
                                    toggleFilterSelection(
                                        multiSelectionFilterType,
                                        option,
                                        dispatch,
                                        selectCreatorsFilter()
                                    )
                                }
                            />
                        );
                    })}
        </>
    );
};

export default MultiSelectionFilters;
