import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isScriptModalOpen } from "src/campaigns/scripts/selectors";
import SearchTag from "src/components/SearchTag";
import { selectedCampaignsToArray } from "src/media/util";
import { setMediaFilters } from "src/pagination/media/actions";
import { GlobalState } from "src/reducers";
import { selectMediaFilters } from "../selectors";

const MediaCampaignTags = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectMediaFilters);

    const campaignTags = selectedCampaignsToArray(filters.selectedCampaigns);

    function removeCampaignFromSelection(campaignId: number) {
        const newSelectedCampaigns = {
            ...filters.selectedCampaigns,
            [campaignId]: false
        };
        dispatch(
            setMediaFilters({
                ...filters,
                selectedCampaigns: newSelectedCampaigns
            })
        );
    }

    const promoting = useSelector(
        (state: GlobalState) => state.ui.promote.promoting
    );
    const isScriptModal = useSelector(isScriptModalOpen);

    return (
        <>
            {campaignTags.map(({ id, campaignName }) => (
                <SearchTag
                    color="black"
                    iconSrc="/tag-black-close-icon.svg"
                    key={id}
                    text={campaignName}
                    disabled={promoting || isScriptModal}
                    onClick={() => removeCampaignFromSelection(id)}
                />
            ))}
        </>
    );
};

export default MediaCampaignTags;
