import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MainContainer from "src/components/MainContainer";
import RouteModal from "src/components/RouteModal";
import { sizes, colors, shadows } from "src/constants";
import { fetchCreatorGroups } from "src/marketplace/creator-groups/actions.creator-groups";
import { fetchCreatorsRequest } from "src/marketplace/creator-groups/actions.creator-search";
import { generateQueryFilters } from "src/marketplace/creator-groups/selectors.creators-search";
import { generateCreatorSearchData } from "src/marketplace/creator-groups/utils/helpers";
import { GlobalState } from "src/reducers";
import {
    fetchCreatorInterests,
    fetchCreatorThemes
} from "src/ui/creator-groups/actions";
import {
    resetCreatorsQueryParameters,
    setCreatorsQueryFilters,
    setCreatorsSort
} from "src/ui/creators/actions";
import { useDebounce } from "src/utils/functions/hooks";
import styled from "styled-components";
import CreatorSearchCSVExportModal from "./components/CreatorSearchCSVExportModal";
import CreatorSearchFooter from "./components/CreatorSearchFooter";
import CreatorSearchHeader from "./components/CreatorSearchHeader";
import CreatorSearchResults from "./components/CreatorSearchResults";
import CreatorSearchTabs from "./components/CreatorSearchTabs";

const Main = styled(MainContainer)`
    display: flex;
    flex-direction: column;
    padding-bottom: calc(60px + 20px);
    text-align: left;
    min-height: calc(100vh - ${sizes.navbar.desktopHeight}px - 24px);
`;

const Contents = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${colors.quinaryLightGray};
    border-radius: 8px;
    overflow: hidden;
    box-shadow: ${shadows.one};
`;

const CreatorSearch = () => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const {
        hitsPerPage,
        page,
        dateRange,
        sort,
        sortOrder,
        queryFilters,
        platformFilters,
        interestsFilters,
        themesFilters,
        creatorCountryRegionFilters,
        audienceCountryRegionFilters,
        followersFilter,
        averageViewsFilter,
        engagementRateFilter,
        audienceGenderFilter,
        audienceAgeFilters,
        audienceDeviceFilter
    } = useSelector((state: GlobalState) => {
        return state.ui.creators;
    }, shallowEqual);

    // Local State ---------------------------------------------------
    const [searchString, setSearchString] = useState("");
    const [exportModal, toggleExportModal] = useState(false);

    // Local Constants -----------------------------------------------
    const searchQuery = generateCreatorSearchData(
        hitsPerPage,
        page,
        searchString,
        dateRange,
        queryFilters,
        sort
    );
    const debouncedSearchString = useDebounce(searchString);
    const generatedQueryFilters = useSelector(generateQueryFilters);

    // Effects -------------------------------------------------------
    useEffect(() => {
        dispatch(fetchCreatorsRequest(searchQuery, false));
        dispatch(fetchCreatorInterests());
        dispatch(fetchCreatorThemes());
        // dispatch(resetCreatorsFilters());
    }, []);

    // Sorting
    useEffect(() => {
        if (Object.keys(sort).length === 0) {
            dispatch(
                // setCreatorsSort([{ "username.raw": { order: sortOrder } }])
                setCreatorsSort([{ followers: { order: sortOrder } }])
            );
        }
    }, [sort]);
    useEffect(() => {
        dispatch(resetCreatorsQueryParameters());
        dispatch(setCreatorsQueryFilters(generatedQueryFilters));
        const searchQuery = generateCreatorSearchData(
            hitsPerPage,
            page,
            searchString,
            dateRange,
            queryFilters,
            sort
        );
        dispatch(fetchCreatorsRequest(searchQuery, true));
    }, [debouncedSearchString]);
    useEffect(() => {
        const searchQuery = generateCreatorSearchData(
            hitsPerPage,
            page,
            searchString,
            dateRange,
            queryFilters,
            sort
        );
        dispatch(fetchCreatorsRequest(searchQuery, false));
    }, [page]);
    useEffect(() => {
        const searchQuery = generateCreatorSearchData(
            hitsPerPage,
            page,
            searchString,
            dateRange,
            queryFilters,
            sort
        );
        dispatch(fetchCreatorsRequest(searchQuery, true));
    }, [queryFilters]);

    // Filtering
    useEffect(() => {
        dispatch(resetCreatorsQueryParameters());
        dispatch(setCreatorsQueryFilters(generatedQueryFilters));
    }, [
        searchString,
        platformFilters,
        interestsFilters,
        themesFilters,
        creatorCountryRegionFilters,
        audienceCountryRegionFilters,
        followersFilter,
        averageViewsFilter,
        engagementRateFilter,
        audienceGenderFilter,
        audienceAgeFilters,
        audienceDeviceFilter
    ]);

    // JSX -----------------------------------------------------------
    return (
        <Main>
            {/* <CreatorSearchTabs /> */}
            <Contents>
                <CreatorSearchHeader
                    searchString={searchString}
                    setSearchString={setSearchString}
                />
                <CreatorSearchResults
                    type="Creator Search"
                    searchString={searchString}
                />
            </Contents>
            <CreatorSearchFooter
                type="Creator Search"
                exportModal={() => toggleExportModal(true)}
            />
            {exportModal && (
                <CreatorSearchCSVExportModal
                    closeModal={() => toggleExportModal(false)}
                    searchString={searchString}
                />
            )}
        </Main>
    );
};

export default CreatorSearch;
