import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { Connection } from "../../types";
import { postRecommendation, PostRecommendationParams } from "../../actions";
import styled from "styled-components";
import RecommendationExpandedCell from "../../connections/components/RecommendationExpandedCell";
import { colors, fonts } from "../../../constants";

const Title = styled.div`
    color: ${colors.primaryGray};
    font-weight: bold;
    font-size: ${fonts.subtitle}px;
    line-height: 28px;
    margin: 5px 0 10px;
`;

const ListContainer = styled.div`
    padding-bottom: 18px;
`;

interface Props {
    recommendations: Connection[];
    postRecommendation: (params: PostRecommendationParams) => void;
    setCurrentProfile: (currentProfile: Connection) => void;
}

const RecommendationsCard = ({
    recommendations,
    postRecommendation,
    setCurrentProfile
}: Props) => {
    return (
        <>
            <Title>RECOMMENDED CONNECTIONS</Title>
            <ListContainer>
                {recommendations.map(recommendation => {
                    return (
                        <RecommendationExpandedCell
                            key={recommendation.other.id}
                            recommendation={recommendation}
                            postRecommendation={postRecommendation}
                            profileCallback={() => {
                                setCurrentProfile(recommendation);
                            }}
                        />
                    );
                })}
            </ListContainer>
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    recommendations: state.profile.recommendations
});

const mapDispatchToProps = (dispatch: any) => ({
    postRecommendation: (params: PostRecommendationParams) =>
        dispatch(postRecommendation(params))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecommendationsCard);
