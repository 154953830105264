import React, { useState } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import { FlexCentered } from "../CommonStyles";
import DesktopTabs, { DesktopTab } from "./DesktopTabs";
import Tab from "../Tab";

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const Main = styled.div<{ oldLayout?: true }>`
    box-shadow: inset 0 -1px 0 ${colors.secondaryLightGray};
    display: flex;
    align-items: stretch;
    font-weight: 500;

    ${props =>
        !props.oldLayout &&
        `
            height: 50px;
            border-top: 1px solid ${colors.secondaryLightGray};
            margin: 12px 0;
            font-weight: 400;
        `}
`;

const Cell = styled.div<{ active?: boolean; oldLayout?: true }>`
    ${FlexCentered}
    flex: 1;
    border-bottom: 2px solid transparent;
    font-size: ${fonts.subtitle}px;
    font-weight: 400;
    color: ${colors.primaryGray};
    cursor: pointer;

    ${props =>
        props.active &&
        `
			background-color: ${colors.teritaryLightGray};
			border-bottom: 2px solid ${colors.primaryBlue};
			font-weight: 500;
			color: ${colors.black};
		`}
`;

interface Props {
    items: DesktopTab[];
    oldLayout?: true;
}

const DesktopTabBar = ({ items, oldLayout }: Props) => {
    const [previousTab, setPreviousTab] = useState(0);

    return (
        <Wrapper>
            <Main oldLayout={oldLayout}>
                {items.map((tab: DesktopTab, index) =>
                    oldLayout ? (
                        <Tab
                            key={`label-${tab.key}`}
                            name={tab.label}
                            active={index === previousTab}
                            onClick={() => setPreviousTab(index)}
                        />
                    ) : (
                        <Cell
                            key={`label-${tab.key}`}
                            active={index === previousTab}
                            onClick={() => setPreviousTab(index)}
                        >
                            {tab.label}
                        </Cell>
                    )
                )}
            </Main>
            <DesktopTabs
                currentTab={previousTab}
                onChange={setPreviousTab}
                items={items}
            />
        </Wrapper>
    );
};

export default DesktopTabBar;
