import React, { ChangeEvent, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { ReactComponent as PlusIcon } from "src/components/icons/box-plus.svg";
import { ReactComponent as SendArrow } from "src/components/icons/send-arrow.svg";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "src/components/CommonStyles";
import { colors } from "src/constants";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { postMessage } from "../message/actions";
import { makeId } from "src/util";
import { storage } from "src/firebase/FirebaseConfig";
import { AssetImage } from "../types/AssetImage";

const Main = styled.div`
    display: flex;
    align-items: flex-end;
    border-top: 1px solid ${colors.mediumTeal};
    padding: 12px 18px 24px;

    & > * ~ * {
        margin-left: 16px;
    }
`;

const UploadButton = styled(PlusIcon)`
    margin-bottom: 4px;
    cursor: pointer;
    user-select: none;
`;

const UploadWrapper = styled.label`
    margin-bottom: 0;
`;

const TextArea = styled(TextareaAutosize)`
    flex: 1;
    min-height: 38px;
    resize: none;
    border: 1px solid ${colors.mediumTeal};
    background-color: ${colors.lighterTeal};
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    padding: 12px;
    border-radius: 6px;
`;

const ChatIconWrapper = styled.div`
    width: 32px;
    height: 32px;
    ${FlexCentered}
    margin-bottom: 4px;
`;

const ChatIcon = styled(SendArrow)`
    cursor: pointer;
    user-select: none;
`;

interface Props {
    conversationId: string;
    scrollToBottom: () => void;
}

const ChatRoomFooter = ({ conversationId, scrollToBottom }: Props) => {
    const [messageText, setMessageText] = useState("");
    const dispatch = useDispatch();

    const handlePostMessage = () => {
        const trimmedMessage = messageText.trim();

        if (!trimmedMessage || !conversationId) {
            setMessageText("");
        } else {
            dispatch(
                postMessage({ messageText: trimmedMessage, conversationId })
            );
            scrollToBottom();
            setMessageText("");
        }
    };

    function handleImageUpload(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        if (!e.currentTarget.files) return;
        const file = e.currentTarget.files[0];
        const objectUrl = URL.createObjectURL(file);

        const allowedTypes = ["image/jpeg", "image/png"];
        if (!allowedTypes.includes(file.type)) return;

        const type = file.type.split("/")[1];

        const imageId = `${localStorage.getItem("userid")}_${makeId(
            50
        )}.${type}`;

        const img = new Image();
        img.src = objectUrl;
        img.onload = function() {
            const ref = storage.ref().child(`conversation_assets/${imageId}`);

            ref.put(file)
                .then(() => {
                    ref.getDownloadURL().then(imageUrl => {
                        const imageAsset: AssetImage = {
                            content: { type: "Image" },
                            height: img.height,
                            width: img.width,
                            url: imageUrl,
                            thumbnailURL: imageUrl
                        };

                        dispatch(
                            postMessage({
                                messageText: "",
                                conversationId,
                                image: imageAsset
                            })
                        );
                    });
                    scrollToBottom();
                })
                .catch(() => {
                    alert("Upload Failed, please try again");
                });
        };
    }

    return (
        <Main>
            <UploadWrapper>
                <UploadButton stroke={colors.primaryBlue} />
                <input
                    type={"file"}
                    accept={"image/jpeg, image/png"}
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                />
            </UploadWrapper>
            <TextArea
                value={messageText}
                onChange={e => setMessageText(e.target.value)}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        handlePostMessage();
                    }
                }}
            />
            <ChatIconWrapper>
                <ChatIcon
                    onClick={handlePostMessage}
                    stroke={colors.primaryBlue}
                />
            </ChatIconWrapper>
        </Main>
    );
};

export default ChatRoomFooter;
