import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthState } from "src/auth/selectors";
import { createConversation } from "src/new-chat/conversation/actions";
import { selectChatProfileById } from "src/new-chat/selectors";
import { GroupUserSettingsWithProfile } from "src/new-chat/types/GroupUserSettings";
import { BlueButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import styled from "styled-components";

const Button = styled(BlueButton)`
    font-size: 12px;
    font-weight: 500;
    padding: 7px 16px;
`;

interface Props {
    groupUserSettings: GroupUserSettingsWithProfile;
    onManageClick: () => void;
    isGroupAdmin: boolean;
}

const GroupUserCellAction = ({
    groupUserSettings,
    onManageClick,
    isGroupAdmin
}: Props) => {
    const dispatch = useDispatch();
    const { userId } = useSelector(getAuthState);
    const chatProfile = useSelector((state: GlobalState) =>
        selectChatProfileById(state, groupUserSettings.userId)
    );

    function chatWithUser() {
        dispatch(
            createConversation({
                users: [
                    {
                        avatarUrl: chatProfile.avatarUrl,
                        username: chatProfile.username,
                        publisherId: chatProfile.id
                    }
                ]
            })
        );
    }

    if (`${userId}` === groupUserSettings.userId) return null;

    if (isGroupAdmin) return <Button onClick={onManageClick}>Manage</Button>;

    return <Button onClick={chatWithUser}>Message</Button>;
};

export default GroupUserCellAction;
