import {
    FETCH_ELASTIC_SEARCH,
    FETCH_MORE_SEARCH_HITS
} from "src/search/actions";
import { Action } from "src/types";

interface SearchUIState {
    nbHits: number;
    hitsPerPage: number;
    page: number;
    pages: number;
    query: string;
    loading: boolean;
    error: string;
}

export interface ElasticSearchUIState {
    [key: string]: SearchUIState;
}

const initialSearchUIState: SearchUIState = {
    nbHits: 0,
    hitsPerPage: 0,
    page: 0,
    pages: 0,
    query: "",
    loading: false,
    error: ""
};

const initialElasticSearchUIState: ElasticSearchUIState = {
    campaigns: initialSearchUIState,
    publishers: initialSearchUIState,
    "social-accounts": initialSearchUIState
};

const getNewState = (state: ElasticSearchUIState, action: Action) => {
    const indices = action.payload.indices;
    const newState: ElasticSearchUIState = {};

    indices.forEach((indexName: string, idx: number) => {
        const {
            hitsPerPage,
            nbHits,
            page,
            pages,
            query
        } = action.payload.queryResults[idx];
        newState[indexName] = {
            hitsPerPage,
            nbHits,
            page,
            pages,
            query,
            loading: false,
            error: ""
        };
    });

    return { ...state, ...newState };
};

const updateState = (state: ElasticSearchUIState, action: Action) => {
    const index = action.payload.index;
    const indexState = state[index];
    const {
        hitsPerPage,
        nbHits,
        page,
        pages,
        query
    } = action.payload.queryResults;
    return {
        ...state,
        [index]: {
            ...indexState,
            hitsPerPage,
            nbHits,
            page,
            pages,
            query,
            loading: false
        }
    };
};

const elasticSearchUIReducer = (
    state = initialElasticSearchUIState,
    action: Action
) => {
    switch (action.type) {
        case FETCH_ELASTIC_SEARCH.REQUEST:
            const newState: { [key: string]: any } = {};
            action.payload.index.split(", ").forEach((indexName: string) => {
                newState[indexName] = { ...state[indexName], loading: true };
            });
            return { ...state, ...newState };
        case FETCH_MORE_SEARCH_HITS.REQUEST:
            return {
                ...state,
                [action.payload.index]: {
                    ...state[action.payload.index],
                    loading: true
                }
            };
        case FETCH_ELASTIC_SEARCH.SUCCESS:
            return getNewState(state, action);
        case FETCH_MORE_SEARCH_HITS.SUCCESS:
            return updateState(state, action);
        case FETCH_ELASTIC_SEARCH.FAILURE:
        case FETCH_MORE_SEARCH_HITS.FAILURE:
            return {
                ...state,
                [action.payload.index]: {
                    ...state[action.payload.index],
                    loading: false,
                    error: action.payload.error
                }
            };
        default:
            return state;
    }
};

export default elasticSearchUIReducer;
