import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { AnalyticsModal } from "./reducer";
import { push } from "connected-react-router";
import { coinToDollars, formatNumber } from "../util";
import BurgerNavbar from "../components/BurgerNavbar";
import TotalEarningsCard from "./TotalEarningsCard";
import EarningsByDayCard from "./EarningsByDayCard";
import ReferralEarningsCard from "./ReferralEarningsCard";
import MediaEarningsCard from "./MediaEarningsCard";
import CoinSummary from "../components/CoinSummary";
import LocationsCard from "./LocationsCard";
import MediaModal from "./MediaModal";
import SimpleNavbar from "../components/SimpleNavbar";
import NavButton from "../components/NavButton";
import AnalyticsDateRanges, {
    AnalyticsDateRangeObject
} from "./AnalyticsDateRanges";
import moment from "moment";
import AnalyticsDatePicker from "./AnalyticsDatePicker";
import {
    CampaignByDate,
    CampaignTotals,
    MediaByDate,
    MediaTotals
} from "./AnalyticsDataParser";
import AnalyticsCampaignMediaScreen from "./AnalyticsCampaignMediaScreen";
import EmailReportModal from "./EmailReportModal";
import { colors, maxWidthDesktop } from "../constants";
import Navbar from "src/components/Navbar";
import MainContainer from "src/components/MainContainer";
import MainSection from "src/components/MainSection";
import TotalEarningsDatePicker from "./modals/TotalEarningsDatePicker";
import EarningsByDayDatePicker from "./modals/EarningsByDayDatePicker";
import TotalEarningsFilterModal from "./modals/TotalEarningsFilterModal";
import EarningsByDayFilterModal from "./modals/EarningsByDayFilterModal";
import TotalEarningsMoreModal from "./modals/TotalEarningsMoreModal";
import EarningsByDayMoreModal from "./modals/EarningsByDayMoreModal";
import ReferralEarningsMoreModal from "./modals/ReferralEarningsMoreModal";
import MediaEarningsMoreModal from "./modals/MediaEarningsMoreModal";

const Cards = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    @media (max-width: ${maxWidthDesktop}px) {
        margin-bottom: 0px;
        grid-gap: 0px;
    }
`;

const Card = styled.div`
    margin-top: 20px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;

    @media (max-width: ${maxWidthDesktop}px) {
        margin-top: 0px;
        border: ${colors.transparent} solid 1px;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

const Close = styled.img`
    width: auto;
    height: 14px;
    cursor: pointer;
`;

const ScrollToElement = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.transparent};
`;

export enum AnalyticsFullscreenState {
    default,
    totalEarningsDates,
    earningsByDayDates,
    totalEarningsFilter,
    earningsByDayFilter,
    totalEarningsMore,
    earningsByDayMore,
    referralEarningsMore,
    mediaEarningsMore
}

export enum AnalyticsScrollTo {
    default,
    totalEarnings,
    earningsByDay,
    referralEarnings,
    mediaEarnings
}

export interface AnalyticsFilterState {
    dateRange: AnalyticsDateRangeObject;
    setDateRange: (dateRange: AnalyticsDateRangeObject) => void;
    startDate: moment.Moment | null;
    setStartDate: (startDate: moment.Moment | null) => void;
    endDate: moment.Moment | null;
    setEndDate: (endDate: moment.Moment | null) => void;
    useCampaigns: boolean;
    setUseCampaigns: (useCampaigns: boolean) => void;
    selectedCampaigns: Set<string>;
    setSelectedCampaigns: (selectedCampaigns: Set<string>) => void;
    selectedMedia: Set<string>;
    setSelectedMedia: (selectedMedia: Set<string>) => void;
}

interface Props {
    allTimeCoins: number;
    analyticsModal: AnalyticsModal;
    push: (path: string) => void;
}

const AnalyticsMain = ({ allTimeCoins, analyticsModal, push }: Props) => {
    const [fullscreenState, setFullscreenState] = useState(
        AnalyticsFullscreenState.default
    );
    const [scrollTo, setScrollTo] = useState(AnalyticsScrollTo.default);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        if (fullscreenState === AnalyticsFullscreenState.default) {
            setShowMore(false);
        } else if (
            fullscreenState === AnalyticsFullscreenState.totalEarningsMore ||
            fullscreenState === AnalyticsFullscreenState.earningsByDayMore ||
            fullscreenState === AnalyticsFullscreenState.referralEarningsMore ||
            fullscreenState === AnalyticsFullscreenState.mediaEarningsMore
        ) {
            setShowMore(true);
        }
    }, [fullscreenState]);

    const [totalDateRange, setTotalDateRange] = useState(
        AnalyticsDateRanges[1]
    );
    const [totalStartDate, setTotalStartDate] = useState<moment.Moment | null>(
        null
    );
    const [totalEndDate, setTotalEndDate] = useState<moment.Moment | null>(
        null
    );

    const [totalUseCampaigns, setTotalUseCampaigns] = useState(true);
    const [totalSelectedCampaigns, setTotalSelectedCampaigns] = useState<
        Set<string>
    >(new Set());
    const [totalSelectedMedia, setTotalSelectedMedia] = useState<Set<string>>(
        new Set()
    );

    const [totalParsedData, setTotalParsedData] = useState<
        (CampaignTotals | MediaTotals)[]
    >([]);

    const totalState: AnalyticsFilterState = {
        dateRange: totalDateRange,
        setDateRange: setTotalDateRange,
        startDate: totalStartDate,
        setStartDate: setTotalStartDate,
        endDate: totalEndDate,
        setEndDate: setTotalEndDate,
        useCampaigns: totalUseCampaigns,
        setUseCampaigns: setTotalUseCampaigns,
        selectedCampaigns: totalSelectedCampaigns,
        setSelectedCampaigns: setTotalSelectedCampaigns,
        selectedMedia: totalSelectedMedia,
        setSelectedMedia: setTotalSelectedMedia
    };

    const [dayDateRange, setDayDateRange] = useState(AnalyticsDateRanges[4]);
    const [dayStartDate, setDayStartDate] = useState<moment.Moment | null>(
        null
    );
    const [dayEndDate, setDayEndDate] = useState<moment.Moment | null>(null);

    const [dayUseCampaigns, setDayUseCampaigns] = useState(true);
    const [daySelectedCampaigns, setDaySelectedCampaigns] = useState<
        Set<string>
    >(new Set());
    const [daySelectedMedia, setDaySelectedMedia] = useState<Set<string>>(
        new Set()
    );

    const [dayParsedData, setDayParsedData] = useState<
        (CampaignByDate | MediaByDate)[]
    >([]);

    const dayState: AnalyticsFilterState = {
        dateRange: dayDateRange,
        setDateRange: setDayDateRange,
        startDate: dayStartDate,
        setStartDate: setDayStartDate,
        endDate: dayEndDate,
        setEndDate: setDayEndDate,
        useCampaigns: dayUseCampaigns,
        setUseCampaigns: setDayUseCampaigns,
        selectedCampaigns: daySelectedCampaigns,
        setSelectedCampaigns: setDaySelectedCampaigns,
        selectedMedia: daySelectedMedia,
        setSelectedMedia: setDaySelectedMedia
    };

    const dayRef = useRef<HTMLDivElement>(null);
    const referralRef = useRef<HTMLDivElement>(null);
    const mediaRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollTo === AnalyticsScrollTo.totalEarnings) {
            window.scrollTo(0, 0);
        } else if (scrollTo === AnalyticsScrollTo.earningsByDay) {
            dayRef.current && window.scrollTo(0, dayRef.current.offsetTop - 56);
        } else if (scrollTo === AnalyticsScrollTo.referralEarnings) {
            referralRef.current &&
                window.scrollTo(0, referralRef.current.offsetTop - 56);
        } else if (scrollTo === AnalyticsScrollTo.mediaEarnings) {
            mediaRef.current &&
                window.scrollTo(0, mediaRef.current.offsetTop - 56);
        }

        if (scrollTo !== AnalyticsScrollTo.default) {
            setScrollTo(AnalyticsScrollTo.default);
        }
    }, [scrollTo]);

    const simpleNavbar = (newScrollTo: AnalyticsScrollTo) => (
        <SimpleNavbar
            left={
                <Close
                    src="/close.svg"
                    alt="close button"
                    onClick={() => {
                        setScrollTo(newScrollTo);
                        setFullscreenState(AnalyticsFullscreenState.default);
                    }}
                />
            }
        />
    );
    const modal =
        analyticsModal === AnalyticsModal.media ? (
            <MediaModal />
        ) : analyticsModal === AnalyticsModal.emailReport ? (
            <EmailReportModal />
        ) : null;

    function closeModal(): void {
        setFullscreenState(AnalyticsFullscreenState.default);
    }

    return (
        <>
            <Navbar />

            <MainContainer>
                <Cards>
                    <MainSection marginTop="20px">
                        <TotalEarningsCard
                            filterState={totalState}
                            parsedData={totalParsedData}
                            setParsedData={setTotalParsedData}
                            pickDates={() =>
                                setFullscreenState(
                                    AnalyticsFullscreenState.totalEarningsDates
                                )
                            }
                            pickFilters={() =>
                                setFullscreenState(
                                    AnalyticsFullscreenState.totalEarningsFilter
                                )
                            }
                            showMore={() =>
                                setFullscreenState(
                                    AnalyticsFullscreenState.totalEarningsMore
                                )
                            }
                        />
                        <ScrollToElement ref={dayRef} />
                    </MainSection>

                    <MainSection marginTop="20px">
                        <EarningsByDayCard
                            filterState={dayState}
                            parsedData={dayParsedData}
                            setParsedData={setDayParsedData}
                            pickDates={() =>
                                setFullscreenState(
                                    AnalyticsFullscreenState.earningsByDayDates
                                )
                            }
                            pickFilters={() =>
                                setFullscreenState(
                                    AnalyticsFullscreenState.earningsByDayFilter
                                )
                            }
                        />
                        <ScrollToElement ref={referralRef} />
                    </MainSection>

                    <MainSection marginTop="20px">
                        <ReferralEarningsCard
                            showMore={() =>
                                setFullscreenState(
                                    AnalyticsFullscreenState.referralEarningsMore
                                )
                            }
                        />

                        <ScrollToElement ref={mediaRef} />
                    </MainSection>

                    <MainSection marginTop="20px">
                        <MediaEarningsCard
                            showMore={() =>
                                setFullscreenState(
                                    AnalyticsFullscreenState.mediaEarningsMore
                                )
                            }
                        />
                    </MainSection>

                    <MainSection marginTop="20px">
                        <CoinSummary
                            topLeft={"All Time"}
                            topRight={formatNumber(coinToDollars(allTimeCoins))}
                            bottomLeft={"Earnings"}
                            bottomRight={""}
                        />
                    </MainSection>

                    <MainSection marginTop="20px">
                        <LocationsCard />
                    </MainSection>
                </Cards>
            </MainContainer>

            {/* Modals */}

            {modal}

            <TotalEarningsDatePicker
                open={
                    fullscreenState ===
                    AnalyticsFullscreenState.totalEarningsDates
                }
                closeModal={closeModal}
                showMore={showMore}
                setScrollTo={setScrollTo}
                setFullscreenState={setFullscreenState}
                totalDateRange={totalDateRange}
                totalStartDate={totalStartDate}
                totalEndDate={totalEndDate}
                setTotalDateRange={setTotalDateRange}
                setTotalStartDate={setTotalStartDate}
                setTotalEndDate={setTotalEndDate}
            />

            <EarningsByDayDatePicker
                open={
                    fullscreenState ===
                    AnalyticsFullscreenState.earningsByDayDates
                }
                closeModal={closeModal}
                showMore={showMore}
                setScrollTo={setScrollTo}
                setFullscreenState={setFullscreenState}
                dayDateRange={dayDateRange}
                dayStartDate={dayStartDate}
                dayEndDate={dayEndDate}
                setDayDateRange={setDayDateRange}
                setDayStartDate={setDayStartDate}
                setDayEndDate={setDayEndDate}
            />

            <TotalEarningsFilterModal
                open={
                    fullscreenState ===
                    AnalyticsFullscreenState.totalEarningsFilter
                }
                closeModal={closeModal}
                showMore={showMore}
                setScrollTo={setScrollTo}
                setFullscreenState={setFullscreenState}
                parsedData={totalParsedData}
                totalUseCampaigns={totalUseCampaigns}
                totalSelectedCampaigns={totalSelectedCampaigns}
                totalSelectedMedia={totalSelectedMedia}
                setTotalSelectedCampaigns={setTotalSelectedCampaigns}
                setTotalSelectedMedia={setTotalSelectedMedia}
            />

            <EarningsByDayFilterModal
                open={
                    fullscreenState ===
                    AnalyticsFullscreenState.earningsByDayFilter
                }
                closeModal={closeModal}
                showMore={showMore}
                setScrollTo={setScrollTo}
                setFullscreenState={setFullscreenState}
                parsedData={dayParsedData}
                dayUseCampaigns={dayUseCampaigns}
                daySelectedCampaigns={daySelectedCampaigns}
                daySelectedMedia={daySelectedMedia}
                setDaySelectedCampaigns={setDaySelectedCampaigns}
                setDaySelectedMedia={setDaySelectedMedia}
            />

            <TotalEarningsMoreModal
                open={
                    fullscreenState ===
                    AnalyticsFullscreenState.totalEarningsMore
                }
                closeModal={closeModal}
                setFullscreenState={setFullscreenState}
                filterState={totalState}
                parsedData={totalParsedData}
                setParsedData={setTotalParsedData}
                simpleNavbar={simpleNavbar}
                modal={modal}
            />

            <EarningsByDayMoreModal
                open={
                    fullscreenState ===
                    AnalyticsFullscreenState.earningsByDayMore
                }
                closeModal={closeModal}
                setFullscreenState={setFullscreenState}
                filterState={dayState}
                parsedData={dayParsedData}
                setParsedData={setDayParsedData}
                simpleNavbar={simpleNavbar}
                modal={modal}
            />

            <ReferralEarningsMoreModal
                open={
                    fullscreenState ===
                    AnalyticsFullscreenState.referralEarningsMore
                }
                closeModal={closeModal}
                simpleNavbar={simpleNavbar}
                modal={modal}
            />

            <MediaEarningsMoreModal
                open={
                    fullscreenState ===
                    AnalyticsFullscreenState.mediaEarningsMore
                }
                closeModal={closeModal}
                simpleNavbar={simpleNavbar}
                modal={modal}
            />
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    allTimeCoins: Math.round(
        state.analytics.weeklyActivity.allTimeActivity.activity
    ),
    analyticsModal: state.analytics.modal
});

const mapDispatchToProps = (dispatch: any) => ({
    push: (path: string) => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsMain);
