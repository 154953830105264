import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";
import isEqual from "react-fast-compare";

import FullScreenModal from "src/modals/FullScreenModal";
import SimpleNavbar from "src/components/SimpleNavbar";
import DefaultDivider from "src/components/Divider";
import LoadingButton from "src/components/LoadingButton";
import { ReactComponent as Cross } from "src/components/icons/cross.svg";
import { TextLoading } from "src/components/SkeletonLoader";
import { List } from "src/campaigns/details/Details.styled";
import { colors, fonts } from "src/constants";

import { GlobalState } from "src/reducers";
import { clearModal } from "src/modals/actions";
import { setPlacementConfig } from "src/ui/placements/actions";
import { PlacementConfig } from "src/ui/placements/reducer";
import { getPlacements, globalPlacementConfig } from "../selectors";

const Main = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
`;

const PlacementList = styled(motion.div)`
    border: 1px solid ${colors.mediumTeal};
    border-radius: 10px;
    margin: 20px 16px 0;
    overflow-y: auto;

    & > * ~ * {
        border-top: 1px solid ${colors.mediumTeal};
    }
`;

const PlacementItem = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const Checkbox = styled.img``;

const Divider = styled(DefaultDivider)`
    margin-top: auto;
`;

const Cancel = styled.div`
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
`;

const Save = styled(Cancel)`
    background-color: ${colors.primaryBlue};
    color: white;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

const Error = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: ${colors.primaryRed};
    font-size: 12px;
`;

interface Props {
    localClearModal?: () => void;
    keepFrozenOnClose?: true;
    localPlacementConfig?: PlacementConfig;
    localSetPlacementConfig?: (placementConfig: PlacementConfig) => void;
}

const PlacementsModal = ({
    localClearModal,
    keepFrozenOnClose,
    localPlacementConfig,
    localSetPlacementConfig
}: Props) => {
    const dispatch = useDispatch();
    const placements = useSelector(getPlacements);

    const globalConfig = useSelector(globalPlacementConfig);
    const placementConfig = localPlacementConfig || globalConfig;

    const [placementSelection, setPlacementSelection] = useState(
        placementConfig
    );

    const [allPlacements, setAllPlacements] = useState(
        placements.length === Object.keys(placementConfig).length ||
            !Object.keys(placementConfig).length
    );

    useEffect(() => {
        if (allPlacements) {
            const nextState = { ...placementSelection };
            placements.forEach(placement => {
                nextState[placement.type] = true;
            });
            setPlacementSelection(nextState);
        }
    }, [allPlacements]);

    const placementsLoading = useSelector(
        (state: GlobalState) => state.ui.placements.placementsLoading
    );

    const toggleAllPlacements = () => {
        setAllPlacements(!allPlacements);
        setPlacementSelection({});
    };

    const toggleSelection = (platform: string) => {
        if (allPlacements) toggleAllPlacements();

        const nextState = { ...placementSelection };
        if (placementSelection[platform]) {
            delete nextState[platform];
        } else {
            nextState[platform] = true;
        }
        setPlacementSelection(nextState);
    };

    const saveAndClose = () => {
        if (localSetPlacementConfig) {
            localSetPlacementConfig(placementSelection);
        } else {
            // Set global placement config
            dispatch(setPlacementConfig(placementSelection));
        }
        closeModal();
    };

    const closeModal = () => {
        if (localClearModal) localClearModal();
        else dispatch(clearModal());
    };
    return (
        <FullScreenModal
            closeModal={closeModal}
            keepFrozenOnClose={keepFrozenOnClose}
        >
            <Main>
                <SimpleNavbar
                    title="Placements"
                    left={
                        <Cross fill={colors.primaryGray} onClick={closeModal} />
                    }
                />
                {placementsLoading ? (
                    <List>
                        <TextLoading height={41} />
                        <TextLoading height={41} />
                        <TextLoading height={41} />
                        <TextLoading height={41} />
                    </List>
                ) : (
                    <>
                        {!allPlacements &&
                            !Object.keys(placementSelection).length && (
                                <Error>
                                    Please select at least one placement
                                </Error>
                            )}
                        <PlacementList onClick={toggleAllPlacements}>
                            <PlacementItem>
                                Select All
                                {allPlacements ? (
                                    <Checkbox src={"/checkbox_selected.svg"} />
                                ) : (
                                    <Checkbox
                                        src={"/checkbox_unselected.svg"}
                                    />
                                )}
                            </PlacementItem>
                        </PlacementList>
                        <PlacementList
                            layout="position"
                            transition={{ duration: 0.3 }}
                        >
                            {placements.map(placement => (
                                <PlacementItem
                                    key={placement.type}
                                    onClick={() =>
                                        toggleSelection(placement.type)
                                    }
                                >
                                    {placement.type}
                                    {placementSelection[placement.type] ? (
                                        <Checkbox
                                            src={"/checkbox_selected.svg"}
                                        />
                                    ) : (
                                        <Checkbox
                                            src={"/checkbox_unselected.svg"}
                                        />
                                    )}
                                </PlacementItem>
                            ))}
                        </PlacementList>
                    </>
                )}
                <Divider />
                {/* {isEqual(placementSelection, placementConfig) ||
                !Object.keys(placementSelection).length ? (
                    <Cancel onClick={closeModal}>Cancel</Cancel>
                ) : (
                    <Save onClick={saveAndClose}>Done</Save>
                )} */}
                <LoadingButton
                    text="Apply"
                    height="48px"
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                    onClick={saveAndClose}
                    margin="12px 18px 12px 18px"
                />
            </Main>
        </FullScreenModal>
    );
};

export default PlacementsModal;
