import React from "react";
import styled from "styled-components";
import { BlueButton } from "src/profile/components/Buttons";
import { breakpoints } from "src/constants";

const Wrapper = styled.div`
    width: 100%;
    position: sticky;
    bottom: -1px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 24px 12px 1px;
`;
const SelectedMediaWrap = styled.div`
    display: flex;
    height: 100%;
`;
const Label = styled.div`
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-right: 6px;
    font-size: 0.9rem;
    @media (max-width: ${breakpoints.tablet}px) {
        display: none;
    }
`;
const SelectedMediaList = styled.ul`
    height: 100%;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    line-height: initial;
    @media (max-width: ${breakpoints.tablet}px) {
        display: none;
    }
`;
const Item = styled.li`
    white-space: pre;
    display: inline-block;
    line-height: initial;
    font-size: 0.9rem;
    &:after {
        content: ", ";
    }
    &:last-child:after {
        content: "";
    }
`;
const Btn = styled(BlueButton)`
    padding: 8px 12px;
    margin-left: auto;
    width: auto;
`;

const SelectFooter = ({
    selectedIds,
    label,
    handleSubmit,
    className
}: {
    selectedIds: number[];
    label: string;
    handleSubmit: () => void;
    className?: string;
}) => {
    return (
        <Wrapper className={className}>
            {selectedIds.length === 0 ? (
                <Label>No {label} selected</Label>
            ) : (
                <SelectedMediaWrap>
                    <Label>Selected:</Label>
                    <SelectedMediaList>
                        {selectedIds.length < 12 ? (
                            selectedIds.map(id => (
                                <Item key={`selected-${id}`}>{id}</Item>
                            ))
                        ) : (
                            <div>({selectedIds.length})</div>
                        )}
                    </SelectedMediaList>
                </SelectedMediaWrap>
            )}
            <Btn onClick={handleSubmit} disabled={selectedIds.length === 0}>
                Add {label}
            </Btn>
        </Wrapper>
    );
};

export default SelectFooter;
