import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteReq, patch, post } from "src/Api";
import { requestOpenBuysByStatus, updateOpenBuy } from "src/buys/actions";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import MainContainer from "src/components/MainContainer";
import MarketplacePagePartialHeader from "src/marketplace/components/MarketplacePagePartialHeader";
import ChallengeList from "src/marketplace/reports/ChallengeList";
import { BlueButton } from "src/profile/components/Buttons";
import { setCreateChallengeFormOpen } from "src/ui/admin-tools/actions";
import {
    addNotification,
    setPillNotificationText
} from "src/ui/notifications/actions";
import styled from "styled-components";
import AdminSideMenu from "../components/AdminSideMenu";
import ChallengeCreationForm from "../components/ChallengeCreationForm";
import ChallengeEditor from "../components/ChallengeEditor";
import { ChallengeFormInputs } from "../components/ChallengeForm";

const StyledMainContainer = styled(MainContainer)`
    padding-top: 12px;
    margin-bottom: 36px;
`;
const StyledBlueButton = styled(BlueButton)`
    padding: 8px 12px;
`;

const ChallengeCreator = () => {
    const dispatch = useDispatch();
    const [currentChallenge, setCurrentChallenge] = useState<null | OpenBuy>(
        null
    );
    const [newChallenge, setNewChallenge] = useState(false);
    const createNewChallenge = ({
        campaign,
        name,
        description,
        linkUrl,
        linkDescription,
        creatorPayout,
        allPublishers,
        jetfuelPercentFee,
        chargeAdvertisers
    }: ChallengeFormInputs) => {
        const body = {
            ...(name && { name }),
            ...(description && { description }),
            ...(linkUrl && { linkUrl }),
            ...(linkDescription && { linkDescription }),
            ...(creatorPayout && { budgetAmount: parseFloat(creatorPayout) }),
            allPublishers,
            ...(jetfuelPercentFee &&
                !chargeAdvertisers && {
                    jetfuelPercentFee: parseFloat(jetfuelPercentFee) / 100
                }),
            ...(campaign && { campaignId: campaign })
        };

        post("/v1/openBuy", body)
            .then(res => {
                setNewChallenge(false);
                dispatch(
                    setPillNotificationText(
                        "Challenge Created Succefully",
                        "success",
                        3500
                    )
                );
            })
            .catch(e =>
                dispatch(
                    setPillNotificationText(
                        "Challenge Creation Failed",
                        "danger",
                        3500
                    )
                )
            );
    };
    const updateChallenge = ({
        name,
        description,
        linkUrl,
        linkDescription,
        creatorPayout,
        allPublishers,
        jetfuelPercentFee,
        chargeAdvertisers
    }: ChallengeFormInputs) => {
        if (currentChallenge) {
            const body = {
                ...(name && { name }),
                ...(description && { description }),
                ...(linkUrl && { linkUrl }),
                ...(linkDescription && { linkDescription }),
                ...(creatorPayout && {
                    budgetAmount: parseFloat(creatorPayout)
                }),
                allPublishers,
                ...(jetfuelPercentFee && !chargeAdvertisers
                    ? {
                          jetfuelPercentFee: parseFloat(jetfuelPercentFee) / 100
                      }
                    : { jetfuelPercentFee: 0 })
            };

            patch(`/v1/openBuy/${currentChallenge.id}`, body)
                .then(res => {
                    dispatch(updateOpenBuy(res.data.data));
                    dispatch(
                        setPillNotificationText(
                            "Challenge Updated",
                            "success",
                            3500
                        )
                    );
                    setNewChallenge(false);
                })
                .catch(e =>
                    dispatch(
                        setPillNotificationText(
                            "Challenge Update Failed",
                            "danger",
                            3500
                        )
                    )
                );
        }
    };

    const handleDelete = (id: number) => {
        deleteReq("/v1/openBuy", { openBuyId: id })
            .then(() => {
                setCurrentChallenge(null);
                dispatch(
                    setPillNotificationText(
                        "Challenge Deleted",
                        "success",
                        3500
                    )
                );
            })
            .catch(e =>
                dispatch(
                    setPillNotificationText(
                        "Challenge Deletion Failed",
                        "danger",
                        3500
                    )
                )
            );
    };

    useEffect(() => {
        dispatch(requestOpenBuysByStatus());
    }, []);

    return (
        <>
            <AdminSideMenu title="Challenge Manager" selectedToolIndex={4} />
            <StyledMainContainer>
                <MarketplacePagePartialHeader
                    includeHeading
                    customCrumbs={["Challenge Tools", "Challenge Details"]}
                    headerTitle={
                        newChallenge
                            ? "Create Challenge"
                            : currentChallenge === null
                            ? "Challenge Manager"
                            : "Edit Challenge"
                    }
                    buttons={
                        currentChallenge || newChallenge ? (
                            <StyledBlueButton
                                onClick={() => {
                                    setNewChallenge(false);
                                    setCurrentChallenge(null);
                                }}
                            >
                                Cancel
                            </StyledBlueButton>
                        ) : (
                            <StyledBlueButton
                                // onClick={() => setNewChallenge(true)}
                                onClick={() =>
                                    dispatch(setCreateChallengeFormOpen(true))
                                }
                            >
                                Create Challenge
                            </StyledBlueButton>
                        )
                    }
                />

                {newChallenge ? (
                    <ChallengeEditor
                        openBuy={null}
                        handleSubmit={createNewChallenge}
                        handleDelete={handleDelete}
                    />
                ) : currentChallenge === null ? (
                    <ChallengeList onSelect={buy => setCurrentChallenge(buy)} />
                ) : (
                    <ChallengeEditor
                        openBuy={currentChallenge}
                        handleSubmit={updateChallenge}
                        handleDelete={handleDelete}
                    />
                )}
            </StyledMainContainer>
        </>
    );
};

export default ChallengeCreator;
