import { push } from "connected-react-router";
import { getUnixTime } from "date-fns";
import { firestore } from "firebase";
import { normalize } from "normalizr";
import {
    all,
    call,
    fork,
    put,
    select,
    take,
    takeLeading
} from "redux-saga/effects";
import { getAuthState } from "src/auth/selectors";
import { storage } from "src/firebase/FirebaseConfig";
import { GlobalState } from "src/reducers";
import { Action } from "src/types";
import { updateChatProfileService } from "../chat-profiles/saga";
import { AddMemberParams } from "../group-user-settings/actions";
import {
    createGroupUserSettingsChannelRequest,
    updateGroupActionsAddMemberService
} from "../group-user-settings/saga";
import { add, createEventChannel, getWhere, set } from "../saga";
import {
    conversationUnreadCountList,
    conversationList,
    conversationRequestList
} from "../schema";
import {
    ConnectionsEntitiesState,
    selectFlattenedConnections,
    selectPlugProfileForChat
} from "../selectors";
import { Conversation } from "../types/Conversation";
import { ConversationRequest } from "../types/ConversationRequest";
import { ConversationUnread } from "../types/ConversationUnread";
import {
    updateConversationUnread,
    updateConversation,
    CREATE_CONVERSATION,
    SET_CONVERSATION_REQUEST_STATUS,
    createConversationSuccess,
    CreateConversationParams
} from "./actions";
import { selectExistingOneToOneConversation } from "./selectors";

export function* createConversationUnreadChannel() {
    try {
        const { userId } = yield select(getAuthState);
        const updateChannel = createEventChannel("ConversationUnreadCount", [
            "publisherId",
            "==",
            `${userId}`
        ]);
        while (true) {
            const conversations: ConversationUnread[] = yield take(
                updateChannel
            );

            yield put(
                updateConversationUnread(
                    normalize(conversations, conversationUnreadCountList)
                )
            );
        }
    } catch (error) {}
}

export function* createConversationRequestChannel() {
    try {
        const { userId } = yield select(getAuthState);
        const updateChannel = createEventChannel("ConversationRequest", [
            "publisherId",
            "==",
            `${userId}`
        ]);
        while (true) {
            const conversations: ConversationRequest[] = yield take(
                updateChannel
            );

            yield put(
                updateConversationUnread(
                    normalize(conversations, conversationRequestList)
                )
            );
        }
    } catch (error) {}
}

export function* createConversationListChannel() {
    try {
        const { userId } = yield select(getAuthState);
        const updateChannel = createEventChannel("Conversation", [
            "participantIds",
            "array-contains",
            `${userId}`
        ]);
        while (true) {
            const conversations: ConversationUnread[] = yield take(
                updateChannel
            );

            const normalizedConversations = normalize(
                conversations,
                conversationList
            );
            yield fork(
                createGroupUserSettingsChannelRequest,
                normalizedConversations.result
            );
            yield put(
                updateConversation(normalize(conversations, conversationList))
            );
        }
    } catch (error) {}
}

function* createConversationRequest({
    payload: { createConversationParams }
}: Action) {
    try {
        const { users } = createConversationParams;
        if (users.length === 1) {
            yield call(createOnetoOneConversationService, users[0]);
        } else {
            yield call(
                createGroupConversationService,
                createConversationParams
            );
        }
    } catch (error) {
        console.log(error);
    }
}

export function* createGroupConversationService(
    createConversationParams: CreateConversationParams
) {
    try {
        const {
            users,
            imageId,
            displayName,
            submissionSetId
        } = createConversationParams;
        const userIds = users.map(user => user.publisherId);

        let imageUrl: string | undefined;
        if (imageId) {
            const storageRef = storage.ref(
                `conversation_group_assets/${imageId}`
            );
            imageUrl = yield call([storageRef, storageRef.getDownloadURL]);
        }

        const ownProfile: AddMemberParams = yield select(
            selectPlugProfileForChat
        );

        // create conversation
        const newConversation = {
            isPublic: false,
            participantIds: [ownProfile.publisherId, ...userIds],
            adminUserIds: [ownProfile.publisherId],
            lastMessage: `${ownProfile.username} created ${displayName}.`,
            lastMessageSentAt: getUnixTime(new Date()),
            ...(imageUrl && { imageUrl }),
            ...(displayName && { displayName }),
            ...(submissionSetId && { submissionSetId })
        };
        const { id: conversationId } = yield call(
            add,
            "Conversation",
            newConversation
        );

        //reuse add member service
        const updateUserServices = users.map(user =>
            updateGroupActionsAddMemberService(user, conversationId, {
                isGroupRequest: true
            })
        );

        yield all([
            updateGroupActionsAddMemberService(ownProfile, conversationId, {
                isAdmin: true
            }),
            ...updateUserServices
        ]);

        if (!submissionSetId) {
            yield put(createConversationSuccess());
            yield put(push(`/newchat/${conversationId}`, { internal: true }));
        }
    } catch (error) {
        console.log(error);
    }
}

function* createOnetoOneConversationService(user: AddMemberParams) {
    try {
        // find existing 1:1 conversation locally
        const existingConversation: Conversation | null = yield select(
            (state: GlobalState) =>
                selectExistingOneToOneConversation(state, user.publisherId)
        );

        const ownProfile: AddMemberParams = yield select(
            selectPlugProfileForChat
        );

        if (existingConversation) {
            yield put(
                push(`/newchat/${existingConversation.id}`, { internal: true })
            );
            yield put(createConversationSuccess());
            return;
        }

        // create conversation
        const newConversation = {
            isPublic: false,
            participantIds: [ownProfile.publisherId, user.publisherId],
            adminUserIds: [ownProfile.publisherId, user.publisherId]
        };
        const { id: conversationId } = yield call(
            add,
            "Conversation",
            newConversation
        );

        //reuse add member service
        yield all([
            updateGroupActionsAddMemberService(ownProfile, conversationId, {
                isAdmin: true
            }),
            updateGroupActionsAddMemberService(user, conversationId, {})
        ]);

        yield put(createConversationSuccess());
        yield put(push(`/newchat/${conversationId}`, { internal: true }));
    } catch (error) {
        console.log(error);
    }
}

function* setConversationRequestStatusRequest({
    payload: { conversationRequestParams }
}: Action) {
    try {
        const { id, accepted, deleted } = conversationRequestParams;

        const newConversationRequest = {
            id,
            ...(typeof accepted === "boolean" && { accepted }),
            ...(typeof deleted === "boolean" && { deleted })
        };
        yield call(set, "ConversationRequest", newConversationRequest);
    } catch (error) {}
}

export function* createConversationRequestService(
    user: AddMemberParams,
    conversationId: string,
    groupRequest?: boolean
) {
    try {
        // create conversation request if not exist, but not for self or connections
        const { userId } = yield select(getAuthState);
        const connections: ConnectionsEntitiesState = yield select(
            selectFlattenedConnections
        );
        if (`${userId}` === user.publisherId) return;
        if (!groupRequest && !!connections[`p:${user.publisherId}` || ""])
            return;

        const conversationRequests: ConversationRequest[] = yield call(
            getWhere,
            "ConversationRequest",
            ["conversationId", "==", conversationId],
            ["publisherId", "==", user.publisherId]
        );

        if (!conversationRequests.length) {
            const newConversationRequest = {
                conversationId,
                publisherId: user.publisherId,
                accepted: false,
                deleted: false
            };
            yield call(add, "ConversationRequest", newConversationRequest);
        }
    } catch (error) {
        console.log("createConversationRequestService error", error);
    }
}

export default function* conversationSaga() {
    yield all([
        takeLeading(CREATE_CONVERSATION.REQUEST, createConversationRequest),
        takeLeading(
            SET_CONVERSATION_REQUEST_STATUS,
            setConversationRequestStatusRequest
        )
    ]);
}
