import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";

export const selectCountries = (state: GlobalState) => {
    return state.ui.campaign.displayRegions;
};

export const selectGenres = (state: GlobalState) => {
    return state.ui.campaign.displayGenres;
};

const selectCampaignFilters = (state: GlobalState) => {
    return state.ui.campaign.filters;
};

// Get country Map() - code: name
export const countryMapByCode = (state: GlobalState) => {
    const countryObj = new Map();
    state.ui.campaign.displayRegions.forEach(region =>
        countryObj.set(region.code, region.name)
    );
    return countryObj;
};

// Get the display text for selected filters (genres & regions)
export const getSelectedDisplayText = createSelector(
    countryMapByCode,
    (state: GlobalState, selected: string[], type: string) => ({
        selected,
        type
    }),
    (countryMap, { selected, type }) => {
        const configLength = selected.length;
        let displayText;
        if (!configLength)
            return type === "region" ? "All Regions" : "All Genres";

        if (countryMap && type === "region")
            displayText = selected.map(country => countryMap.get(country));
        else displayText = selected;

        if (configLength > 2)
            return (
                displayText.slice(0, 2).join(", ") +
                `, +${configLength - 2} More`
            );

        return displayText.join(", ");
    }
);

// Get filters display text in sort/filter header on Landing
export const getFiltersDisplayText = createSelector(
    selectCampaignFilters,
    countryMapByCode,
    (filters, countryMap) => {
        const {
            budgetLow,
            budgetNotLow,
            genres,
            regions,
            bidLow,
            bidHigh
        } = filters;

        let wholeString = "";
        const defaultString = "Filter";
        let totalFilters = 0;

        if (budgetLow) {
            wholeString = "Budget Low";
            totalFilters += 1;
        }

        if (budgetNotLow) {
            if (totalFilters === 0) wholeString = "Budget Not Low";
            totalFilters += 1;
        }

        if (genres.length > 0) {
            if (totalFilters === 0) wholeString = `Genres: ${genres[0]}`;
            totalFilters += genres.length;
        }

        if (regions.length > 0) {
            if (totalFilters === 0)
                wholeString = `Regions: ${
                    countryMap ? countryMap.get(regions[0]) : regions[0]
                }`;
            totalFilters += regions.length;
        }

        let alreadyHasBid = false;
        if (bidLow > 0) {
            if (totalFilters === 0) {
                bidHigh < 10
                    ? (wholeString = `Bid Range: $${bidLow}-$${bidHigh}`)
                    : (wholeString = `Bid More Than: $${bidLow}`);
                alreadyHasBid = true;
            }
            totalFilters += 1;
        }

        if (bidHigh < 10 && !alreadyHasBid) {
            if (totalFilters === 0) {
                bidLow > 0
                    ? (wholeString = `Bid Range: ${bidLow}-$${bidHigh}`)
                    : (wholeString = `Bid Less Than: $${bidHigh}`);
            }
            totalFilters += 1;
        }

        totalFilters -= 1;

        if (totalFilters > 0) wholeString += ` + ${totalFilters} more`;

        return wholeString == "" ? defaultString : wholeString;
    }
);
