import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OpenBuy } from "src/buys/modules/open-buys/types";
import ModalBox from "src/components/modals/components/ModalBox";
import { colors } from "src/constants";
import { fetchInstructionsSet } from "src/marketplace/instructions-sets/actions.instructions-sets";
import { selectInstructionsSet } from "src/marketplace/instructions-sets/selectors";
import { GlobalState } from "src/reducers";
import styled from "styled-components";

const InstructionWrap = styled.div`
    background-color: ${colors.white};
    border-radius: 8px;
    display: flex;
    margin-bottom: 8px;
    font-size: 12px;
    gap: 12px;
    width: 100%;
    padding: 12px;
    &:last-child {
        margin-bottom: 0px;
    }
`;
const OrderNum = styled.div<{ isActive: boolean }>`
    display: grid;
    place-items: center;
    background-color: ${props =>
        props.isActive ? colors.primaryBlue : colors.primaryGray};
    color: ${colors.white};
    width: 24px;
    height: 24px;
    border-radius: 4px;
    flex-shrink: 0;
`;
const InstructionText = styled.div`
    text-align: left;
`;

const ApprovalInstructionSet = ({
    currentInstructionId,
    openBuy
}: {
    openBuy: OpenBuy;
    currentInstructionId?: number;
}) => {
    const dispatch = useDispatch();

    const instructionSet = useSelector((state: GlobalState) =>
        selectInstructionsSet(state, openBuy.instructionSetId)
    );

    useEffect(() => {
        if (!instructionSet && openBuy.instructionSetId)
            dispatch(fetchInstructionsSet(openBuy.instructionSetId, true));
    }, [openBuy]);

    return (
        <ModalBox
            headingText="INSTRUCTION SET"
            closable={true}
            defaultOpen={false}
        >
            {instructionSet?.instructionSteps.map(instruction => {
                return (
                    <InstructionWrap key={instruction.position}>
                        <OrderNum
                            isActive={instruction.id === currentInstructionId}
                        >
                            {instruction.position}
                        </OrderNum>
                        <InstructionText>
                            {instruction.description}
                        </InstructionText>
                    </InstructionWrap>
                );
            })}
        </ModalBox>
    );
};

export default ApprovalInstructionSet;
