import React from "react";
import styled from "styled-components";
import { Metric } from "../../campaigns/types";
import { fonts } from "src/constants";

const Title = styled.div`
    font-size: 18px;
    line-height: 21.48px;
    font-weight: 700;
`;

const TitleDescriptor = styled.div`
    font-size: ${fonts.subtitle}px;
    line-height: 14.32px;
    font-weight: 500;
    margin-bottom: 5px;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1px;
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
`;

const ItemIcon = styled.img``;

const ItemDescriptor = styled.div`
    font-size: 12px;
    line-height: 14.32px;
    font-weight: 700;
    margin-left: 4px;
`;

interface Props {
    metric: Metric;
}

const MetricRow = ({ metric }: Props) => {
    if (metric.isTitle) {
        return (
            <>
                <Title>{metric.value}</Title>
                <TitleDescriptor>{metric.descriptor}</TitleDescriptor>
            </>
        );
    } else if (metric.isPrimary) {
        return (
            <Item>
                <IconWrapper>
                    <ItemIcon src={metric.icon} />
                </IconWrapper>
                <ItemDescriptor>{metric.value}</ItemDescriptor>
            </Item>
        );
    }
    return null;
};

export default MetricRow;
