import React, { useEffect, useState } from "react";
import { Campaign } from "src/campaigns/types";
import { GlobalState } from "../../../../reducers";
import { connect, useDispatch, useSelector } from "react-redux";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import NavButton from "../../../../components/NavButton";
import PromotedPage from "../../components/PromotedPage";
import FullscreenLoader from "../../../../components/FullscreenLoader";
import CampaignModals from "../../../modals/CampaignModals";
import { Profile } from "../../../types";
import PaddingAround from "../../../../components/PaddingAround";
import XCard from "../../../components/XCard";
import { fetchPromotedUser } from "../../../actions";
import { useHistory } from "react-router";
import Navbar from "src/components/Navbar";

interface Props {
    profile: Profile;
}

const PrivatePromotedPage = ({ profile }: Props) => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        if (!scrolled) {
            window.scrollTo(0, 0);
            setScrolled(true);
        }
    }, [scrolled]);

    const promotedCampaigns = useSelector((state: GlobalState) => {
        return state.profile.promotedCampaigns;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();
    function dispatchFetchPromotedUser(publisherId: number): void {
        dispatch(fetchPromotedUser(publisherId));
    }

    if (promotedCampaigns === null) {
        return (
            <>
                <Navbar back marginBottom={10} title="PROMOTING" />
                <PaddingAround>
                    <XCard
                        text="Failed to load promoted campaigns, click the button below to try again."
                        button={{
                            text: "Try again",
                            callback: () => {
                                dispatchFetchPromotedUser(profile.id);
                            }
                        }}
                    />
                </PaddingAround>
            </>
        );
    } else if (!promotedCampaigns) {
        return (
            <>
                <Navbar back marginBottom={10} title="PROMOTING" />
                <FullscreenLoader />
            </>
        );
    } else {
        return (
            <>
                <Navbar back marginBottom={10} title="PROMOTING" />
                {scrolled && (
                    <PromotedPage promotedCampaigns={promotedCampaigns} />
                )}
                <CampaignModals />
            </>
        );
    }
};

export default PrivatePromotedPage;
