import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../../../constants";
import LeaderboardPhoto from "src/components/LeaderboardPhoto";
import { coinToDollars, formatRank } from "src/util";
import { ContestMyRanking, ContestRank } from "src/campaigns/contests/types";
import CoinText from "src/profile/bonus/weekly/CoinText";

const Main = styled.div<{ clickable?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 14px 17px 14px 17px;
    font-size: ${fonts.subtitle}px;
    cursor: ${props => props.clickable && "pointer"};

    & + & {
        margin-top: 15px;
    }
`;

const User = styled.div`
    text-align: left;
    display: flex;
    align-items: center;
    max-width: 80%;
`;

const NameContainer = styled.div`
    margin-left: 10px;
    text-align: left;
    overflow: hidden;
    max-width: 80%;
`;

const Name = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 700;
`;

const Prize = styled.div`
    font-size: ${fonts.smallTitle}px;
    color: ${colors.secondaryGray};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface Props {
    leaderboardItem?: ContestRank;
    self?: ContestMyRanking;
}

const getPlace = (item: ContestRank) => {
    if (item.rank < 4) return item.rank;
    else if (item.prize_name) return 5;
    else return undefined;
};
const RankCell = ({ leaderboardItem, self }: Props) => {
    return leaderboardItem ? (
        <Main>
            <User>
                <LeaderboardPhoto
                    profilePhotoUrl={self && self.publisher.profile_photo_url}
                    type={self && "own"}
                    place={getPlace(leaderboardItem)}
                    style={{ height: 48, width: 48 }}
                />
                <NameContainer>
                    <Name>
                        {self
                            ? `${formatRank(leaderboardItem.rank)} (you)`
                            : formatRank(leaderboardItem.rank)}
                    </Name>
                    <Prize>{leaderboardItem.prize_name || "No Prize"}</Prize>
                </NameContainer>
            </User>
            <CoinText
                color={colors.black}
                amount={coinToDollars(leaderboardItem.coins)}
            />
        </Main>
    ) : self ? (
        <Main>
            <User>
                <Name style={{ marginRight: 10 }}>{self.rank}</Name>
                <NameContainer>
                    <Name>{self.publisher.username}</Name>
                    <Prize>{self.prize_name || "No Prize"}</Prize>
                </NameContainer>
            </User>
            <CoinText color={colors.black} amount={coinToDollars(self.coins)} />
        </Main>
    ) : null;
};

export default RankCell;
