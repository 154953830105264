import { FETCH_USER } from "../profile/actions";
import { Action } from "../types";
import { CHECK_MAGIC_TOKEN, INTERCOM_HASH } from "./actions";
import { Role } from "./roles";

export interface TokenStatus {
    valid: boolean;
    message: string;
}

export interface AuthState {
    tokenStatus?: TokenStatus;
    userId: number | null;
    token: string | null;
    role: Role;
    intercomHash: string;
}

const initialAuthState: AuthState = {
    userId: null,
    token: null,
    role: Role.guest,
    intercomHash: ""
};

const reducer = (state: AuthState = initialAuthState, action: Action) => {
    switch (action.type) {
        case CHECK_MAGIC_TOKEN.SUCCESS:
            return { ...state, ...action.payload };
        case CHECK_MAGIC_TOKEN.FAILURE:
            return { ...state, ...action.payload };
        case FETCH_USER.SUCCESS:
            return {
                ...state,
                userId: action.payload.id,
                token: localStorage.getItem("token"),
                role: Role.creator
            };
        case INTERCOM_HASH.SET:
            return {
                ...state,
                intercomHash: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
