import { OpenBuySubmission } from "src/buys/modules/open-buys/types";
import { ServerFilters } from "src/components/table/components/TableServerHeader";
import { TableColumn } from "src/components/table/types";

export const getSubmissionTypeHierarchy = (
    submission: Partial<OpenBuySubmission>,
    isDbTypes?: Boolean
) => {
    if (submission.textSubmission) return isDbTypes ? "text" : "spark";
    if (submission.linkSubmission) return "link";
    if (submission.customMedia) return "media";
    return "";
};

export const submissionManagerTableColumns: TableColumn[] = [
    {
        coreKey: "challenge",
        displayValue: "Challenge",
        columnWidth: "180px"
    },
    {
        coreKey: "id",
        displayValue: "ID",
        columnWidth: "60px"
    },
    { coreKey: "createdAt", hidden: true },
    {
        coreKey: "status",
        displayValue: "Status",
        columnWidth: "90px"
    },
    {
        coreKey: "publisherId",
        displayValue: "Publisher",
        columnWidth: "90px"
    },
    {
        coreKey: "username",
        displayValue: "Username",
        columnWidth: "150px"
    },
    {
        coreKey: "platform",
        displayValue: "Platform",
        columnWidth: "75px",
        hidden: true
    },
    {
        coreKey: "creatorRep",
        displayValue: "Rep",
        columnWidth: "140px"
    },
    {
        coreKey: "statusUpdatedAt",
        displayValue: "Last Update",
        columnWidth: "100px",
        sortType: "number"
    },
    {
        coreKey: "url",
        displayValue: "Media Url",
        columnWidth: "100px",
    },
    {
        coreKey: "playableUrl",
        displayValue: "Playable Url",
        columnWidth: "100px",
    }
];
export const filterTags = {
    statuses: "statuses",
    types: "types",
    flags: "flags",
    challengeStatuses: "challengeStatuses",
    creatorReps: "creatorReps"
};
export const submissionFilterOptions: ServerFilters = {
    [filterTags.statuses]: {
        displayValue: "Statuses",
        type: "string",
        choices: [
            {
                valueToFilterBy: "approved",
                displayValue: "Approved",
                selected: false
            },
            {
                valueToFilterBy: "next_step",
                displayValue: "Next Step",
                selected: false
            },
            {
                valueToFilterBy: "rejected",
                displayValue: "Rejected",
                selected: false
            },
            {
                valueToFilterBy: "pending",
                displayValue: "Pending",
                selected: true
            },
            {
                valueToFilterBy: "revision_requested",
                displayValue: "Revision Requested",
                selected: false
            }
        ]
    },
    [filterTags.types]: {
        displayValue: "Types",
        type: "string",
        choices: [
            {
                valueToFilterBy: "media",
                displayValue: "Media",
                selected: false
            },
            {
                valueToFilterBy: "link",
                displayValue: "Link",
                selected: false
            },
            {
                valueToFilterBy: "text",
                displayValue: "Text/Spark",
                selected: false
            }
        ]
    },
    [filterTags.flags]: {
        displayValue: "Flags",
        type: "string",
        choices: [
            {
                valueToFilterBy: "showFlagged",
                displayValue: "Only Scraper Flagged",
                selected: false
            },
            {
                valueToFilterBy: "excludeEmployees",
                displayValue: "Exclude Employees",
                selected: true
            }
        ]
    },
    [filterTags.challengeStatuses]: {
        displayValue: "Challenge Status",
        type: "string",

        choices: [
            {
                valueToFilterBy: "active",
                displayValue: "Active",
                selected: true
            },
            {
                valueToFilterBy: "ended",
                displayValue: "Ended",
                selected: false
            }
        ]
    },
    [filterTags.creatorReps]: {
        displayValue: "Creator Rep",
        type: "string",
        choices: [
            {
                valueToFilterBy: "0",
                displayValue: "...loading reps",
                selected: false
            }
        ]
    }
};
