import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import DragCard from "src/profile/components/DragCard";
import { makeId, isMobileSafari } from "src/util";
import { storage } from "src/firebase/FirebaseConfig";
import ShadeScreen from "src/media/components/ShadeScreen";
import SpinLoader from "../SpinLoader";
import { colors } from "src/constants";
import { useDispatch } from "react-redux";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { CustomAdminMediaUpload } from "src/media/actions";

const Wrapper = styled.div`
    width: 100%;
`;
const MediaWrap = styled.div`
    overflow-x: auto;
    white-space: nowrap;
    text-align: left;
    padding-top: 24px;
`;
const AdPreviewContainer = styled.div<{ isMultiUpload?: boolean }>`
    position: relative;
    height: 320px;
    width: 180px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    background-color: ${colors.white};
    justify-content: center;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    margin: ${props => (props.isMultiUpload ? "0px 8px" : "0px auto")};
    ${props =>
        props.isMultiUpload &&
        `
        &:first-child {
            margin-left: 0px;
        }
        &:last-child {
            margin-right: 0px;
        }
    `}
`;
const Video = styled.video`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const Photo = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;
const StyledDragCard = styled(DragCard)`
    height: 240px;
`;
interface Props {
    path: string;
    isMultiUpload?: boolean;
    onUpload?: (id: string, url: string) => void;
    onUploadBegin?: (
        id: string,
        type: string,
        original_filename: string
    ) => void;
    onRemove: (idx: number) => void;
    onCoverPhoto?: (coverPhotoId: string, id: string) => void;
    controlledMedia?: { url: string; type: string };
    renderOwnMedia?: boolean;
    isAsset?: boolean;
    medias: any[];
}
const DropMediaUploader = ({
    path,
    onUpload,
    onUploadBegin,
    onRemove,
    onCoverPhoto,
    isMultiUpload,
    renderOwnMedia,
    medias,
    isAsset
}: Props) => {
    const dispatch = useDispatch();

    const [fileQueue, setFileQueue] = useState<File[] | null>(null);
    useEffect(() => {
        if (fileQueue) {
            fileUpload(fileQueue[0]);
        }
    }, [fileQueue]);

    function createCoverPhoto(file: File, id: string) {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        video.preload = "metadata";
        video.crossOrigin = "Anonymous";
        video.src = URL.createObjectURL(file);
        if (isMobileSafari()) {
            video.load();
        } else {
            video.muted = true;
            video.play();
        }
        video.onloadeddata = () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d");

            if (!context) {
                return null;
            }

            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(blob => {
                if (!blob) return null;

                const coverPhotoId = `${localStorage.getItem(
                    "userid"
                )}_${makeId(50)}.jpeg`;

                const ref = isAsset
                    ? storage.ref().child(`assets/${path}/${coverPhotoId}`)
                    : storage.ref().child(`${path}/${coverPhotoId}`);

                ref.put(blob)
                    .then(() => {
                        onCoverPhoto && onCoverPhoto(coverPhotoId, id);
                    })
                    .catch((error: string) => {
                        window.alert("Something went wrong. Please Refresh.");
                    });
            }, "image/jpeg");
        };
    }

    const fileUpload = (file: any) => {
        if (file) {
            const allowedTypes = [
                "image/jpeg",
                "image/png",
                "video/mp4",
                "video/quicktime"
            ];

            if (file && allowedTypes.includes(file.type)) {
                let fileExtension = file.type.split("/")[1];
                const extensionIdx = file.name.lastIndexOf(".");
                const original_filename = file.name.substring(0, extensionIdx);
                const fileType =
                    file.type.split("/")[0] === "image" ? "photo" : "video";

                if (fileExtension === "quicktime") {
                    fileExtension = "mov";
                }
                const id = `${localStorage.getItem("userid")}_${makeId(
                    50
                )}.${fileExtension}`;

                onUploadBegin && onUploadBegin(id, fileType, original_filename);

                const ref = isAsset
                    ? storage.ref().child(`assets/${path}/${id}`)
                    : storage.ref().child(`${path}/${id}`);

                ref.put(file)
                    .then(() => {
                        if (fileType === "video") {
                            console.log(URL.createObjectURL(file));
                            onUpload && onUpload(id, URL.createObjectURL(file));
                            createCoverPhoto(file, id);
                        } else {
                            onUpload && onUpload(id, URL.createObjectURL(file));
                        }
                    })
                    .catch((error: string) => {
                        window.alert("Something went wrong. Please Refresh.");
                    });
            } else {
                dispatch(
                    setPillNotificationText(
                        "File type must be .jpeg, .jpg, .png, .mp4, or quicktime/.mov",
                        "danger",
                        2500
                    )
                );
            }
            if (fileQueue && fileQueue.length > 0) {
                const newQueue = fileQueue.slice(1);
                setFileQueue(newQueue);
            } else {
                setFileQueue(null);
            }
        }
    };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const files = Object.values(e.dataTransfer.files);
        setFileQueue(files);
    };
    const handleClick = (e: any) => {
        e.preventDefault();
        const files: File[] = Object.values(e.currentTarget.files);
        setFileQueue(files);
    };

    function renderIcon(idx: number) {
        if (medias[idx].uploading) {
            return <SpinLoader size={15} color={"white"} />;
        } else if (medias[idx].type) {
            return <img src={"/green_circle_check.svg"} />;
        } else {
            return null;
        }
    }
    if (isMultiUpload) {
        return (
            <Wrapper>
                <StyledDragCard
                    text="Drag and drop file or click the button below to upload to the Plug"
                    button={{
                        text: "Upload",
                        type: "file",
                        callback: (e: any) => handleClick(e)
                    }}
                    isDisabled={false}
                    onDrop={handleDrop}
                    isMultiUpload={isMultiUpload}
                />
                {medias.length > 0 && !renderOwnMedia && (
                    <MediaWrap>
                        {medias.map(
                            (mediaItem: CustomAdminMediaUpload, idx: number) =>
                                mediaItem.type === "video" ? (
                                    <AdPreviewContainer
                                        onClick={() => onRemove(idx)}
                                        key={mediaItem.url + idx}
                                        isMultiUpload={isMultiUpload}
                                    >
                                        <Video
                                            src={mediaItem.url || undefined}
                                        />
                                        <ShadeScreen>
                                            {renderIcon(idx)}
                                        </ShadeScreen>
                                    </AdPreviewContainer>
                                ) : (
                                    <AdPreviewContainer
                                        onClick={() => onRemove(idx)}
                                        key={mediaItem.url + idx}
                                        isMultiUpload={isMultiUpload}
                                    >
                                        <Photo
                                            src={mediaItem.url || undefined}
                                        />
                                        <ShadeScreen>
                                            {renderIcon(idx)}
                                        </ShadeScreen>
                                    </AdPreviewContainer>
                                )
                        )}
                    </MediaWrap>
                )}
            </Wrapper>
        );
    }
    return (
        <Wrapper>
            {medias.length > 0 && !renderOwnMedia ? (
                <>
                    {medias[0].type === "video" ? (
                        <AdPreviewContainer>
                            <Video src={medias[0].url || undefined} />
                            <ShadeScreen>{renderIcon(0)}</ShadeScreen>
                        </AdPreviewContainer>
                    ) : (
                        <AdPreviewContainer>
                            <Photo src={medias[0].url || undefined} />
                            <ShadeScreen>{renderIcon(0)}</ShadeScreen>
                        </AdPreviewContainer>
                    )}
                </>
            ) : renderOwnMedia && medias.length > 0 ? (
                <></>
            ) : (
                <StyledDragCard
                    text="Drag and drop file or click the button below to upload to the Plug"
                    button={{
                        text: "Upload",
                        type: "file",
                        callback: (e: any) => handleClick(e)
                    }}
                    onDrop={handleDrop}
                />
            )}
        </Wrapper>
    );
};

export default DropMediaUploader;
