import React, { useEffect } from "react";
import { IntercomProvider } from "react-use-intercom";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { SendBirdProvider } from "sendbird-uikit";
import { push } from "connected-react-router";
import "sendbird-uikit/dist/index.css";
import "src/chat/overwrite.scss";
import FullscreenLoader from "src/components/FullscreenLoader";
import FullscreenAlertModal from "./FullscreenAlertModal";
import VerificationFailed from "./VerificationFailed";
import TryAgain from "src/profile/private/TryAgain";
import GlobalModals from "src/modals/GlobalModals";
import AccountDisabled from "./AccountDisabled";
import CampaignsRouter from "src/campaigns";
import PublicRouter from "src/profile/public";
import Notifications from "src/notifications";
import ContestRouter from "src/campaigns/contests/router";
import Layout from "src/ui/layout/Layout";
import ProfileRouter from "src/profile/private";
import AnalyticsRouter from "src/analytics";
import SearchRouter from "src/search/page";
import ChatRouter from "src/chat";
import { accountCompleted, loggedIn, parseNotifications } from "src/util";
import { fetchUser } from "src/profile/actions";
import { database } from "src/firebase/FirebaseConfig";
import { GlobalState } from "src/reducers";
import {
    addSystemNotifications,
    addTransactionNotifications,
    newSystemNotification
} from "src/notifications/actions";
import BuysRouter from "../buys/router";
import MediaSearch from "src/media/search";
import NotFoundPage from "./NotFound";
import {
    getPreApprovalCode,
    getUserId,
    selectIntercomHash
} from "src/profile/selectors";
import OpenBuyDetails from "src/buys/details/OpenBuyDetails";
import NewChatRouter from "src/new-chat";
import { initializeFirestoreChat } from "src/new-chat/actions";
import AdminTools from "src/admin-tools";
import Landing from "src/campaigns/landing/Landing";
import { AccessStatuses } from "src/profile/types";
import useScrollRestoration from "src/hooks/useScrollRestoration";
import HighlightsPage from "src/highlights/HightlightsPage";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const LandingRouter = () => {
    useScrollRestoration();
    const profileState = useSelector((state: GlobalState) => {
        return state.profile;
    });

    const profileLoading = useSelector((state: GlobalState) => {
        return state.ui.auth.profileLoading;
    });
    const preApprovalCode = useSelector(getPreApprovalCode);

    const dispatch = useDispatch();
    const history = useHistory();

    const userid = useSelector(getUserId);
    const userHash = useSelector(selectIntercomHash);
    const highlightsSplit = useSelector(
        selectFeatureFlags(featureFlags.ShowHighlights)
    );

    useEffect(() => {
        if (
            profileState.profile?.accessStatus === AccessStatuses.CHALLENGES &&
            window.location.pathname.includes("/analytics")
        ) {
            history.push("/");
        }
    });

    useEffect(() => {
        const systemRef = userid
            ? database
                  .ref()
                  .child("system_notifications")
                  .child(userid.toString())
            : null;
        const transactionRef = userid
            ? database
                  .ref()
                  .child("transaction_notifications")
                  .child(userid.toString())
            : null;

        const updateNotifications = (snapshot: any) => {
            if (systemRef && transactionRef && snapshot.val()) {
                const systemQuery = systemRef.orderByKey().limitToLast(200);
                const transactionQuery = transactionRef
                    .orderByKey()
                    .limitToLast(200);

                systemQuery.on("value", (snapshot: any) => {
                    if (snapshot.val()) {
                        dispatch(
                            addSystemNotifications(
                                parseNotifications(
                                    Object.values(snapshot.val())
                                )
                            )
                        );
                    }
                });
                systemRef
                    .orderByKey()
                    .limitToLast(1)
                    .on("child_added", (snapshot: any) => {
                        if (snapshot.val()) {
                            dispatch(newSystemNotification(snapshot.val()));
                        }
                    });

                transactionQuery.on("value", (snapshot: any) => {
                    if (snapshot.val()) {
                        dispatch(
                            addTransactionNotifications(
                                parseNotifications(
                                    Object.values(snapshot.val())
                                )
                            )
                        );
                    }
                });
            }
        };

        database
            .ref(".info/connected")
            .on("value", (snapshot: any) => updateNotifications(snapshot));

        userid && dispatch(initializeFirestoreChat());

        return () => {
            if (systemRef) {
                systemRef.off();
            }
            if (transactionRef) {
                transactionRef.off();
            }
        };
    }, [userid]);

    useEffect(() => {
        const ref = userid
            ? database
                  .ref()
                  .child("accountRefresh")
                  .child(userid.toString())
            : null;

        if (ref) {
            ref.on("value", (snapshot: any) => {
                if (snapshot.val() === 1) {
                    dispatch(fetchUser());
                }
            });
        }

        return () => {
            if (ref) {
                ref.off();
            }
        };
    }, []);

    const user = {
        user_id: userid,
        user_hash: userHash,
        hide_default_launcher: true,
        custom_launcher_selector: ".intercom-link"
    };

    const chatRenewal = useSelector(selectFeatureFlags(featureFlags.Chat2));
    const delayRules = useSelector(
        selectFeatureFlags(featureFlags.DelayRulesOnboarding)
    );
    const { hasReadLocalStoreForNavToAdminTools } = useSelector(
        (state: GlobalState) => {
            return state.ui.adminTools;
        }
    );

    useEffect(() => {
        if (!hasReadLocalStoreForNavToAdminTools) return;
        const unlisten = history.listen(location => {
            const isAdmin = location.pathname.startsWith("/admin-tools");
            localStorage.setItem("adminToolsSticky", JSON.stringify(isAdmin));
        });
        return unlisten;
    }, [hasReadLocalStoreForNavToAdminTools]);

    if (profileLoading) return <FullscreenLoader offset={57} />;

    if (profileState.profile && profileState.publicProfile) {
        const profile = profileState.profile;
        const publicProfile = profileState.publicProfile;

        if (!profile.account_enabled) {
            return <AccountDisabled push={route => dispatch(push(route))} />;
        } else if (
            !profile.account_verified &&
            profile.account_verification_failed
        ) {
            return <VerificationFailed />;
        } else if (!accountCompleted(profile, preApprovalCode)) {
            return <Redirect to={"/onboarding"} />;
        } else if (!profile.agreed_rules && !delayRules) {
            localStorage.setItem("newUser", "true");
            return <Redirect to={"/rules"} />;
        } else {
            return (
                <SendBirdProvider
                    appId={"782B98AD-014D-4B6B-BCAA-4788D9B00609"}
                    userId={`p:${profile?.id}`}
                    accessToken={profileState?.chatToken?.token}
                >
                    <IntercomProvider
                        appId="qporgstt"
                        autoBoot
                        autoBootProps={{
                            customAttributes: { ...user }
                        }}
                    >
                        <div style={{ isolation: "isolate" }}>
                            <Switch>
                                <Route exact path={"/"} component={Landing} />
                                {profile.accessStatus ===
                                    AccessStatuses.ALL && (
                                    <Route
                                        path={"/campaign"}
                                        component={CampaignsRouter}
                                    />
                                )}
                                <Route path={"/buys"} component={BuysRouter} />
                                <Route
                                    path={"/profile"}
                                    render={() => (
                                        <ProfileRouter
                                            profile={profile}
                                            publicProfile={publicProfile}
                                        />
                                    )}
                                />
                                <Route
                                    path={"/public/:otherId"}
                                    render={({ match }) => (
                                        <PublicRouter
                                            profile={profile}
                                            otherId={match.params.otherId}
                                        />
                                    )}
                                />

                                <Route
                                    path={"/search"}
                                    component={SearchRouter}
                                />
                                <Route
                                    path={"/contest"}
                                    component={ContestRouter}
                                />
                                {highlightsSplit && (
                                    <Route
                                        path={"/highlights"}
                                        component={HighlightsPage}
                                    />
                                )}
                                <Route
                                    path={"/analytics"}
                                    component={AnalyticsRouter}
                                />
                                <Route
                                    path={"/notifications"}
                                    component={Notifications}
                                />

                                <Route
                                    path="/newchat"
                                    render={() =>
                                        chatRenewal ? (
                                            <Layout>
                                                <NewChatRouter />
                                            </Layout>
                                        ) : null
                                    }
                                />
                                <Route
                                    path={"/chat"}
                                    render={() =>
                                        !chatRenewal ? (
                                            <ChatRouter userId={profile.id} />
                                        ) : null
                                    }
                                />

                                <Route
                                    exact
                                    path="/not-found"
                                    component={NotFoundPage}
                                />
                                <Route
                                    path={"/media"}
                                    component={MediaSearch}
                                />
                                <Route
                                    path="/admin-tools"
                                    component={AdminTools}
                                />
                                <Route component={NotFoundPage} />
                            </Switch>
                        </div>
                        <div style={{ isolation: "isolate" }}>
                            {profileState.fullscreenAlerts.length > 0 && (
                                <FullscreenAlertModal
                                    fullscreenAlert={
                                        profileState.fullscreenAlerts[0]
                                    }
                                />
                            )}

                            {profileState.fullscreenAlerts.length > 0 && (
                                <FullscreenAlertModal
                                    fullscreenAlert={
                                        profileState.fullscreenAlerts[0]
                                    }
                                />
                            )}

                            <GlobalModals />
                        </div>
                        <div
                            style={{ isolation: "isolate" }}
                            id="portal-modals"
                        ></div>
                    </IntercomProvider>
                </SendBirdProvider>
            );
        }
    } else {
        return (
            <>
                <Switch>
                    <Route path="/campaign" component={CampaignsRouter} />
                    <Route
                        path={"/buys/open/:buyId"}
                        render={({ match }) => {
                            return (
                                <OpenBuyDetails
                                    openBuyId={parseInt(match.params.buyId)}
                                    isPublic={true}
                                />
                            );
                        }}
                    />

                    {!loggedIn() && <Redirect to={"/onboarding"} />}

                    <Route path="/" component={TryAgain} />
                </Switch>
                <GlobalModals />
            </>
        );
    }
};

export default LandingRouter;
