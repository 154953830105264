import React from "react";
import { connect } from "react-redux";
import { Connection } from "../types";
import {
    deleteConnection,
    DeleteConnectionParams,
    postConnection,
    PostConnectionParams
} from "../actions";
import ActionsModal, { Action } from "../../modals/ActionsModal";
import { push } from "connected-react-router";
// @ts-ignore
import { withSendBird } from "sendbird-uikit";

interface Props {
    connection: Connection;
    postConnection: (params: PostConnectionParams) => void;
    deleteConnection: (params: DeleteConnectionParams) => void;
    push: (route: string) => void;
    sendBird: any;
}

const PublicActionsModal = ({
    connection,
    postConnection,
    deleteConnection,
    push,
    sendBird
}: Props) => {
    const actions: Action[] = [];

    if (connection.state === "blocked") {
        actions.unshift({
            text: "Unblock",
            callback: () => {
                postConnection({
                    selfId: connection.entity.id,
                    otherId: connection.other.id,
                    relationship: "none"
                });
                sendBird.unblockUserWithUserId(
                    connection.other.id,
                    (user: any, error: any) => {
                        if (error) {
                            window.alert(error);
                        }
                    }
                );
            },
            negative: true
        });
    } else {
        actions.unshift({
            text: "Block",
            callback: () => {
                postConnection({
                    selfId: connection.entity.id,
                    otherId: connection.other.id,
                    relationship: "block"
                });
                sendBird.blockUserWithUserId(
                    connection.other.id,
                    (user: any, error: any) => {
                        if (error) {
                            window.alert(error);
                        }
                    }
                );
            },
            negative: true
        });
    }

    if (connection.state === "friend") {
        actions.unshift({
            text: "Unconnect",
            callback: () => {
                deleteConnection({
                    selfId: connection.entity.id,
                    otherId: connection.other.id
                });
            }
        });
    }

    actions.unshift({
        text: "My Profile",
        callback: () => {
            push("/profile");
        }
    });

    return <ActionsModal actions={actions} />;
};

const mapDispatchToProps = (dispatch: any) => ({
    postConnection: (params: PostConnectionParams) =>
        dispatch(postConnection(params)),
    deleteConnection: (params: DeleteConnectionParams) =>
        dispatch(deleteConnection(params)),
    push: (route: string) => dispatch(push(route))
});

export default withSendBird(
    connect(null, mapDispatchToProps)(PublicActionsModal),
    (state: any) => ({
        sendBird: state.stores.sdkStore.sdk
    })
);
