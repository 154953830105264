import { NormalizedActionGeneric } from "src/types";
import { ChatProfile } from "../types/ChatProfile";
import { FETCH_CHAT_USER_PROFILE } from "./actions";

export interface ChatProfilesEntitiesState {
    [key: string]: ChatProfile;
}

const initialChatProfileState: ChatProfilesEntitiesState = {};

const chatProfileEntitiesReducer = (
    state = initialChatProfileState,
    action: NormalizedActionGeneric<ChatProfile, string[]>
) => {
    switch (action.type) {
        case FETCH_CHAT_USER_PROFILE.SUCCESS:
            return { ...state, ...action.response.entities.chatProfiles };
        default:
            return state;
    }
};

export default chatProfileEntitiesReducer;
