import React, { useState } from "react";
import { Connection } from "../../../profile/types";
import PaddingNoTop from "../../../components/PaddingNoTop";
import SearchBar from "../../../components/SearchBar";
import ConnectionCell from "../../../profile/connections/components/ConnectionCell";

interface Props {
    connections: Connection[];
    sendBirdData: any;
    isAdmin: Boolean;
    actionsModalCallback: (currentUser: any) => void;
    bannedUsers?: any[];
}

const MemberList = ({
    connections,
    sendBirdData,
    isAdmin,
    actionsModalCallback,
    bannedUsers
}: Props) => {
    const [searchString, setSearchString] = useState("");

    return (
        <>
            <PaddingNoTop>
                <SearchBar
                    searchString={searchString}
                    updateSearchString={setSearchString}
                />
            </PaddingNoTop>
            <PaddingNoTop>
                {connections
                    .filter(connection => {
                        if (searchString.length === 0) {
                            return true;
                        } else {
                            return (
                                connection.other.profile.username &&
                                connection.other.profile.username
                                    .toLowerCase()
                                    .includes(searchString.toLowerCase())
                            );
                        }
                    })
                    .map(connection => {
                        const sendBirdMember = !bannedUsers
                            ? sendBirdData.memberMap[connection.other.id]
                            : bannedUsers.find(user => {
                                  return connection.other.id === user.userId;
                              });
                        const otherIsAdmin = sendBirdMember
                            ? sendBirdMember.role === "operator"
                            : false;

                        return (
                            <ConnectionCell
                                key={connection.other.id}
                                connection={connection}
                                optionalCallback={
                                    isAdmin && !otherIsAdmin
                                        ? () => {
                                              actionsModalCallback(
                                                  sendBirdMember
                                              );
                                          }
                                        : undefined
                                }
                            />
                        );
                    })}
            </PaddingNoTop>
        </>
    );
};

export default MemberList;
