import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../../reducers";
import { Campaign } from "src/campaigns/types";
import { MediaModal } from "../reducer";
import { TagButtonsState } from "./TagSelectorButtons";
import { CustomMediaUpload, uploadCustomMedia } from "../actions";
import { rulesModal } from "../actions";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import UploadMediaTag from "./UploadMediaTag";
import RulesModal from "./RulesModal";
import SelectCampaign from "./SelectCampaign";
import UploadMediaMain from "./UploadMediaMain";
import SelectExternalPage from "./SelectExternalPage";
import { useHistory } from "react-router-dom";
import {
    fetchOrderedCampaigns,
    getMediaRules,
    selectCampaign
} from "src/campaigns/selectors";
import { fetchCampaignMediaRules } from "src/campaigns/media-rules/actions";
import MainContainer from "src/components/MainContainer";
import { fetchCampaigns } from "src/campaigns/actions";

interface Props {
    noScroll?: true;
}

const UploadMedia = ({ noScroll }: Props) => {
    const currentCampaign = useSelector(selectCampaign);

    const campaigns = useSelector(fetchOrderedCampaigns);

    const modal = useSelector((state: GlobalState) => {
        return state.media.modal;
    });

    const mediaRules = useSelector(getMediaRules);

    const dispatch = useDispatch();

    function dispatchFetchCampaigns(): void {
        dispatch(fetchCampaigns());
    }

    function dispatchFetchMediaRules(campaignId: number): void {
        dispatch(fetchCampaignMediaRules(campaignId));
    }

    function dispatchUploadCustomMedia(payload: CustomMediaUpload): void {
        dispatch(uploadCustomMedia(payload));
    }

    function dispatchRulesModal(): void {
        dispatch(rulesModal());
    }

    const history = useHistory();
    function goBack() {
        history.goBack();
    }

    useEffect(() => {
        !noScroll && window.scrollTo(0, 0);
    }, [noScroll]);

    const [selectedCampaign, setSelectedCampaign] = useState(currentCampaign);

    useEffect(() => {
        if (selectedCampaign) {
            dispatchFetchMediaRules(selectedCampaign.id);
        }
    }, [selectedCampaign]);

    const [filename, setFilename] = useState<string | null>(null);
    const [coverPhoto, setCoverPhoto] = useState<string | null>(null);
    const [type, setType] = useState<string | null>(null);
    const [mediaUrl, setMediaUrl] = useState<string | null>(null);
    const [allowUse, setAllowUse] = useState(true);
    const [externalPageId, setExternalPageId] = useState<number | null>(null);
    const [tagState, setTagState] = useState<TagButtonsState>(
        TagButtonsState.jetfuel
    );

    const [tagScreen, setTagScreen] = useState(false);

    const openInProfileCustomAdsSection = useSelector((state: GlobalState) => {
        return state.ui.profile.addCustomMediaModalOpen;
    });

    if (!selectedCampaign) {
        return (
            <SelectCampaign
                campaigns={campaigns.filter((campaign: Campaign) => {
                    return campaign.customMediaOk;
                })}
                fetchCampaigns={dispatchFetchCampaigns}
                nextCallback={campaign => setSelectedCampaign(campaign)}
                goBack={goBack}
            />
        );
    } else if (
        selectedCampaign.ExternalPages &&
        selectedCampaign.ExternalPages.length > 0 &&
        !externalPageId
    ) {
        return (
            <SelectExternalPage
                externalPages={selectedCampaign.ExternalPages}
                nextCallback={page => setExternalPageId(page.id)}
                goBack={goBack}
            />
        );
    } else if (tagScreen && mediaUrl && type) {
        return (
            <UploadMediaTag
                setTagScreen={setTagScreen}
                mediaUrl={mediaUrl}
                type={type}
                tagState={tagState}
                setTagState={setTagState}
                uploadMedia={() => {
                    if (type === "photo" && filename) {
                        dispatchUploadCustomMedia({
                            campaign_id: selectedCampaign.id,
                            filename: filename,
                            cover_photo: filename,
                            type: type,
                            watermark_type: tagState,
                            allow_others_to_use: allowUse,
                            external_page_id: externalPageId,
                            placements: {}
                        });
                    } else if (type === "video" && filename && coverPhoto) {
                        dispatchUploadCustomMedia({
                            campaign_id: selectedCampaign.id,
                            filename: filename,
                            cover_photo: coverPhoto,
                            type: type,
                            watermark_type: tagState,
                            allow_others_to_use: allowUse,
                            external_page_id: externalPageId,
                            placements: {}
                        });
                    }
                }}
            />
        );
    } else {
        return (
            <>
                <SimpleNavbar
                    title={"Upload Media"}
                    left={
                        !openInProfileCustomAdsSection && (
                            <NavButton back onClick={goBack} />
                        )
                    }
                />

                <MainContainer>
                    <UploadMediaMain
                        campaign={selectedCampaign}
                        filename={filename}
                        setFilename={setFilename}
                        coverPhoto={coverPhoto}
                        setCoverPhoto={setCoverPhoto}
                        type={type}
                        setType={setType}
                        mediaUrl={mediaUrl}
                        setMediaUrl={setMediaUrl}
                        allowUse={allowUse}
                        setAllowUse={setAllowUse}
                        rulesModal={dispatchRulesModal}
                        nextCallback={() => setTagScreen(true)}
                    />
                </MainContainer>

                {modal === MediaModal.rules && selectedCampaign && (
                    <RulesModal
                        campaign={selectedCampaign}
                        mediaRules={mediaRules}
                    />
                )}
            </>
        );
    }
};

export default UploadMedia;
