import React from "react";
import { useLocation } from "react-router";
import { CustomLoading } from "src/components/SkeletonLoader";
import { colors } from "src/constants";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const BreadcrumbWrap = styled.div`
    padding-top: 2px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: ${colors.primaryGray};
`;
const BannerWrap = styled.div`
    margin-top: 16px;
`;
const TitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${colors.quaternaryLightGray};
`;
const TabWrap = styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 24px;
`;

const MainHeaderLoader = () => {
    const location = useLocation();
    const paths = location.pathname.split("/").filter((path: string) => path);
    return (
        <Wrapper>
            <BreadcrumbWrap>
                {paths.length > 0 ? (
                    paths.slice(1).map((path, idx, arr) => (
                        <React.Fragment key={"loader-crumb-" + idx}>
                            <CustomLoading height="12px" width="100px" />
                            {idx !== arr.length - 1 && "/"}
                        </React.Fragment>
                    ))
                ) : (
                    <>
                        <CustomLoading height="12px" width="100px" />
                        /
                        <CustomLoading height="12px" width="160px" />
                        /
                        <CustomLoading height="12px" width="100px" />
                    </>
                )}
            </BreadcrumbWrap>
            <BannerWrap>
                <CustomLoading height="34px" width="100%" />
            </BannerWrap>
            <TitleWrap>
                <CustomLoading height="48px" width="400px" />
                <CustomLoading height="37px" width="128px" />
            </TitleWrap>
            <TabWrap>
                <CustomLoading height="35px" width="128px" />
                <CustomLoading height="35px" width="117px" />
                <CustomLoading height="35px" width="111px" />
                <CustomLoading height="35px" width="157px" />
                <CustomLoading height="35px" width="89px" />
            </TabWrap>
        </Wrapper>
    );
};

export default MainHeaderLoader;
