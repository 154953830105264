import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
    adminFetchOpenBuy,
    adminUpdateOpenBuySuccess
} from "src/admin-tools/actions.admin-tools";
import { selectMarketplaceBadges } from "src/buys/selectors";
import MainContainer from "src/components/MainContainer";
import { breakpoints, spacing, maxWidth, colors } from "src/constants";
import useGetMarketplaceParamOpenBuyid from "src/hooks/useGetMarketplaceParamOpenBuyId";
import MarketplacePagePartialHeader from "src/marketplace/components/MarketplacePagePartialHeader";
import { selectMarketplaceOpenBuy } from "src/marketplace/redux/selectors";
import {
    BlueButton,
    GrayButton,
    RedButton
} from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import {
    setAdminCurrentWizardStep,
    setChallengeDetailsIncomplete
} from "src/ui/admin-tools/actions";
import {
    requestMarketplaceSubmissionBadges,
    setMarketplaceSubmissionBadges,
    toggleLeaderboardEditorOpen
} from "src/ui/marketplace/actions";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";
import ChallengeLinkTab from "./ChallengeLinkTab";
import { BannerColor } from "./HeaderBanner";
import LaunchModal from "./LaunchModal";
import MainHeaderLoader from "./MainHeaderLoader";
import { getCurrentOpenBuyAlreadyHasPayments } from "../../payment-sets/open-buy-payments/selectors";
import moment, { Moment } from "moment";
import Modal from "src/modals/Modal";
import { DropdownItem } from "src/marketplace/payment-sets/components/PaymentSetDropdown";
import { patch } from "src/Api";
import { NotificationBadgeButton } from "src/components/buttons";
import ChallengeInternalDetails from "./ChallengeInternalDetails";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100vh; */
    text-align: left;
`;

const Main = styled(MainContainer)`
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (max-width: ${breakpoints.tablet}px) {
        padding: 0px ${spacing.sideSpacing}px;
    }

    /* @media (max-width: ${maxWidth.desktop}px) {
        padding-top: 20px;
    } */
`;

const LinkTabs = styled.section`
    display: flex;
    gap: 8px;
    margin: 0px 0px 24px;
`;

const StyledBlueButton = styled(BlueButton)`
    padding: 8px 12px;
`;
const StyledRedButton = styled(RedButton)`
    padding: 8px 12px;
    border-radius: 6px;
`;
const StyledGrayButton = styled(GrayButton)`
    padding: 8px 12px;
`;

const ScraperStatus = styled.div<{ stopScrapingAt: string }>`
    display: flex;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 12px;
    color: black;
    background-color: ${props =>
        moment(props.stopScrapingAt).diff(moment()) > 0
            ? colors.quaternaryGreen
            : colors.noteYellow};
    margin-bottom: 24px;
`;

const ScraperExtensionButton = styled.div`
    cursor: pointer;
    color: ${colors.primaryBlue};
    margin-left: 8px;
`;

const Caret = styled.img`
    margin-right: 10px;
    transform: rotate(-90deg);
`;

const ExtensionList = styled.div`
    display: flex;
    flex-direction: column;
`;

const extensionOptions = [
    { label: "1 Day", value: moment().add(1, "day") },
    { label: "3 Days", value: moment().add(3, "day") },
    { label: "1 Week", value: moment().add(1, "week") },
    { label: "2 Weeks", value: moment().add(2, "week") }
];

const HeaderButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
`;

export interface ChallengeLink {
    text:
        | "Challenge Report"
        | "View Challenge"
        | "Edit Challenge"
        | "Submission Approvals"
        | "Extensions";
    route: string;
}

export interface LaunchTags {
    end: "end";
    launch: "launch";
    restart: "restart";
}

interface Props {
    openBuyIdOverride?: number;
    endCrumb?: string;
}

const ChallengeMainHeader = ({ endCrumb, openBuyIdOverride }: Props) => {
    const dispatch = useDispatch();
    const paramOpenBuyId = useGetMarketplaceParamOpenBuyid();
    const openBuyId = openBuyIdOverride || paramOpenBuyId;
    const badgeCounts = useSelector(selectMarketplaceBadges);
    const [extensionOpen, setExtensionOpen] = useState(false);
    const openBuy = useSelector((state: GlobalState) =>
        selectMarketplaceOpenBuy(state, openBuyId)
    );

    useEffect(() => {
        if (!openBuy && openBuyId) {
            dispatch(adminFetchOpenBuy(openBuyId));
        }
    }, [openBuy, openBuyId, dispatch]);

    useEffect(() => {
        dispatch(setMarketplaceSubmissionBadges({ pending: 0, flagged: 0 }));
        dispatch(requestMarketplaceSubmissionBadges(openBuyId));
    }, [openBuyId]);

    const links: ChallengeLink[] = [
        {
            text: "Challenge Report",
            route: `/admin-tools/your-challenges/${openBuyId}/challenge-report`
        },
        {
            text: "View Challenge",
            route: `/admin-tools/your-challenges/${openBuyId}/view-challenge`
        },
        {
            text: "Edit Challenge",
            route: `/admin-tools/your-challenges/${openBuyId}/edit-challenge`
        },
        {
            text: "Submission Approvals",
            route: `/admin-tools/your-challenges/${openBuyId}/approvals`
        },
        {
            text: "Extensions",
            route: `/admin-tools/your-challenges/${openBuyId}/extensions`
        }
    ];
    const launchTags: LaunchTags = {
        end: "end",
        restart: "restart",
        launch: "launch"
    };
    const [warningTag, setWarningTag] = useState<
        null | "end" | "restart" | "launch"
    >(null);

    const challengeStatus = openBuy?.endsAt
        ? launchTags.restart
        : openBuy?.startsAt
        ? launchTags.end
        : launchTags.launch;

    const bannerReference: { color: BannerColor; message: string } = {
        color:
            challengeStatus === "launch"
                ? "yellow"
                : challengeStatus === "end"
                ? "blue"
                : "black",
        message:
            challengeStatus === "launch"
                ? `This Challenge is Pending`
                : challengeStatus === "end"
                ? `This Challenge is Active for ${openBuy.publisherCount} publishers`
                : `This Challenge has Ended`
    };

    const location = useLocation();
    const { pathname } = location;
    const inEditTab = pathname.includes("edit-challenge");
    const {
        challengeDetailsIncomplete,
        chargeAdvertisersPerInstall,
        detailsHasUnsaved
    } = useSelector((state: GlobalState) => {
        return state.ui.adminTools;
    }, shallowEqual);
    const currentOpenBuyAlreadyHasPayments = useSelector(
        getCurrentOpenBuyAlreadyHasPayments
    );

    function _handleChargeAdvertisersPerInstallCheck(
        creatorPayoutBudget: number,
        jetfuelPercentFee: number
    ): boolean {
        if (chargeAdvertisersPerInstall) {
            return creatorPayoutBudget > 0;
        } else {
            const jetfuelPercentFeePercent =
                jetfuelPercentFee / (1 + jetfuelPercentFee);
            const advertiserBudget =
                creatorPayoutBudget / (1 - jetfuelPercentFeePercent);
            return (
                jetfuelPercentFeePercent > 0 &&
                creatorPayoutBudget > 0 &&
                advertiserBudget > 0
            );
        }
    }
    function _checkChallengeDetailsIncomplete(): boolean {
        const {
            posterUrl,
            name,
            description,
            allowedPlatforms,
            budgetAmount,
            jetfuelPercentFee
        } = openBuy;
        const requiredFieldsFilled =
            !!posterUrl &&
            name.length > 0 &&
            (!!description || description.trim().length > 0) &&
            allowedPlatforms.length > 0 &&
            _handleChargeAdvertisersPerInstallCheck(
                budgetAmount,
                jetfuelPercentFee
            );
        return requiredFieldsFilled;
    }

    function getMissingRequiredFields(): string {
        const {
            posterUrl,
            name,
            description,
            allowedPlatforms,
            budgetAmount,
            jetfuelPercentFee
        } = openBuy;
        if (!posterUrl) return "Missing cover photo";
        if (name.length === 0) return "Missin Challenge name";
        if (!description || description.trim().length === 0)
            return "Missin Challenge description";
        if (allowedPlatforms.length === 0) return "Select allowed platforms";
        if (
            !_handleChargeAdvertisersPerInstallCheck(
                budgetAmount,
                jetfuelPercentFee
            )
        )
            return "Please set the Challenge budget";
        return "Fill out required fields and save changes.";
    }

    function handleLaunchButtonClick(): void {
        if (detailsHasUnsaved) {
            dispatch(
                setPillNotificationText(
                    "You have unsaved changes to the Challenge",
                    "warning",
                    3500
                )
            );
        }
        if (openBuy) {
            // Challenge Details validations when in the `Edit Challenge` tab.
            if (
                inEditTab &&
                challengeDetailsIncomplete &&
                !_checkChallengeDetailsIncomplete()
            ) {
                dispatch(setAdminCurrentWizardStep("Set Challenge Details"));
                dispatch(
                    setPillNotificationText(
                        getMissingRequiredFields(),
                        "danger",
                        3500
                    )
                );
            }
            // Challenge Details validations when not in the `Edit Challenge` tab.
            else if (!_checkChallengeDetailsIncomplete()) {
                dispatch(setChallengeDetailsIncomplete(true));
                dispatch(setAdminCurrentWizardStep("Set Challenge Details"));
                dispatch(
                    setPillNotificationText(
                        getMissingRequiredFields(),
                        "danger",
                        3500
                    )
                );
                dispatch(
                    push(
                        `/admin-tools/your-challenges/${openBuy.id}/edit-challenge`
                    )
                );
            }
            // Instruction Sets validations.
            else if (!openBuy.instructionSetId) {
                dispatch(setChallengeDetailsIncomplete(true));
                dispatch(
                    setAdminCurrentWizardStep("Select an Instruction Set")
                );
                dispatch(
                    setPillNotificationText(
                        "You must add an instruction set.",
                        "danger",
                        3500
                    )
                );
                dispatch(
                    push(
                        `/admin-tools/your-challenges/${openBuy.id}/edit-challenge`
                    )
                );
            } else if (!currentOpenBuyAlreadyHasPayments) {
                dispatch(setChallengeDetailsIncomplete(true));
                dispatch(setAdminCurrentWizardStep("Select a Payment Set"));
                dispatch(
                    setPillNotificationText(
                        "You must add a payment set.",
                        "danger",
                        3500
                    )
                );
                dispatch(
                    push(
                        `/admin-tools/your-challenges/${openBuy.id}/edit-challenge`
                    )
                );
            }
            // When all validations have passed, allow challenge launch.
            else {
                dispatch(setChallengeDetailsIncomplete(false));
                setWarningTag(challengeStatus);
            }
        }
    }
    const headerBtn = () => {
        const props = {
            onClick: handleLaunchButtonClick,
            disabled: !openBuy
        };
        const text =
            challengeStatus === "launch"
                ? "Launch Challenge"
                : challengeStatus === "end"
                ? "End Challenge"
                : "Restart Challenge";

        return challengeStatus === "launch" ? (
            <StyledBlueButton {...props}>{text}</StyledBlueButton>
        ) : challengeStatus === "end" ? (
            <StyledRedButton {...props}>{text}</StyledRedButton>
        ) : (
            <StyledGrayButton {...props}>{text}</StyledGrayButton>
        );
    };

    const extendChallengeScraping = async (date: Moment) => {
        try {
            await patch(`/v1/admin/openBuy/${openBuy.id}/scrapingExtension`, {
                stopScrapingAt: date
            });
            dispatch(
                adminUpdateOpenBuySuccess({
                    openBuy: {
                        ...openBuy,
                        stopScrapingAt: date.toISOString()
                    }
                })
            );
            dispatch(setPillNotificationText("Scraping extended successfully"));
        } catch (err) {
            console.error(err);
            dispatch(
                setPillNotificationText("Error extending scraping", "danger")
            );
        } finally {
            setExtensionOpen(false);
        }
    };

    if (!openBuyId) return null;
    return (
        <>
            <Container>
                <Main>
                    {!openBuy ? (
                        <MainHeaderLoader />
                    ) : (
                        <>
                            <MarketplacePagePartialHeader
                                includeHeading
                                customCrumbs={[
                                    "Admin Tools",
                                    !!openBuy && openBuy.name
                                ]}
                                endCrumb={endCrumb}
                                headerTitle={openBuy && openBuy.name}
                                buttons={
                                    <HeaderButtons>
                                        {openBuy.linkedOpenBuyId && (
                                            <StyledBlueButton
                                                onClick={() =>
                                                    window.open(
                                                        `/admin-tools/your-challenges/${openBuy.linkedOpenBuyId}/edit-challenge`
                                                    )
                                                }
                                            >
                                                Open Linked Challenge
                                            </StyledBlueButton>
                                        )}
                                        {headerBtn()}
                                    </HeaderButtons>
                                }
                                banner={bannerReference}
                            />
                            <ChallengeInternalDetails openBuyId={openBuyId} />
                            <LinkTabs>
                                {links.map(
                                    (link: ChallengeLink, idx: number) => {
                                        return (
                                            <ChallengeLinkTab
                                                key={`${link.text}-` + idx}
                                                text={link.text}
                                                route={link.route}
                                                badgeCount={
                                                    link.text ===
                                                    "Submission Approvals"
                                                        ? badgeCounts.pending +
                                                          badgeCounts.flagged
                                                        : undefined
                                                }
                                            />
                                        );
                                    }
                                )}
                                {/* <NotificationBadgeButton
                                    onClick={() =>
                                        dispatch(
                                            toggleLeaderboardEditorOpen(
                                                openBuyId
                                            )
                                        )
                                    }
                                >
                                    Edit Leaderboard
                                </NotificationBadgeButton> */}
                            </LinkTabs>
                            {openBuy.endsAt && (
                                <ScraperStatus
                                    stopScrapingAt={openBuy.stopScrapingAt}
                                >
                                    Scraping{" "}
                                    {moment(openBuy.stopScrapingAt).diff(
                                        moment()
                                    ) < 0
                                        ? "completed at"
                                        : "submissions until"}
                                    {moment(openBuy.stopScrapingAt)
                                        .startOf("day")
                                        .format(" MMM Do, hh:mm a")}
                                    <ScraperExtensionButton
                                        onClick={() => setExtensionOpen(true)}
                                    >
                                        Extend
                                    </ScraperExtensionButton>
                                </ScraperStatus>
                            )}
                        </>
                    )}
                </Main>
                {warningTag && !challengeDetailsIncomplete && (
                    <LaunchModal
                        status={challengeStatus}
                        openBuy={openBuy}
                        setWarningTag={setWarningTag}
                    />
                )}
            </Container>
            {extensionOpen && (
                <Modal onClick={() => setExtensionOpen(false)}>
                    <ExtensionList>
                        {extensionOptions.map((extension, idx) => (
                            <DropdownItem
                                key={`extension.value-${idx}`}
                                onClick={() =>
                                    extendChallengeScraping(extension.value)
                                }
                            >
                                {extension.label} <Caret src={"/caret.svg"} />
                            </DropdownItem>
                        ))}
                    </ExtensionList>
                </Modal>
            )}
        </>
    );
};

export default ChallengeMainHeader;
