import React from "react";
import { Contest } from "../types";
import moment from "moment";
import styled from "styled-components";
import Countdown from "./Countdown";
import { SmallPillButton } from "./styledComponents";
import PrizeCarousel from "./PrizeCarousel";
import DrawerContent from "./DrawerContent";
import { push } from "connected-react-router";
import { setCurrentContest } from "../../../ui/contests/actions";
import { connect } from "react-redux";
import { colors, maxWidthDesktop, fonts } from "src/constants";

const blue = "#0079f5";
const yellow = "#E8C300";

interface DrawerProps {
    color: string;
}

const DrawerBackground = styled.div<DrawerProps>`
    background-color: ${props => props.color};
    border-radius: 12px;
`;

const Main = styled.div`
    padding: 16px;
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 12px;
    overflow: hidden;
`;

interface StyleProps {
    showTimer: boolean;
}

const TextContainer = styled.div<StyleProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    text-align: left;
    ${props => !props.showTimer && "padding-bottom: 0;"};
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

const Subtitle = styled.div`
    font-size: ${fonts.smallTitle}px;
    color: #828282;
`;

interface Props {
    contest: Contest | undefined;
    push: (path: string) => void;
    setCurrentContest: (contestId: number) => void;
}

const ContestCard = ({ contest, push, setCurrentContest }: Props) => {
    const endingSoon =
        !!contest &&
        contest.status === "active" &&
        moment(contest.end_time).diff(moment(), "days") === 0;

    if (contest) {
        return (
            <DrawerBackground
                color={
                    contest.status === "ended"
                        ? blue
                        : endingSoon
                        ? yellow
                        : "transparent"
                }
            >
                <Main>
                    <TextContainer showTimer={contest.should_show_timer}>
                        <div>
                            <Title>
                                {contest.status === "about_to_start"
                                    ? "New Contest Starting Soon!"
                                    : contest.name}
                            </Title>
                            <Subtitle>
                                {contest.status === "about_to_start" ? (
                                    "Contest Begins In:"
                                ) : (
                                    <>
                                        Presented by{" "}
                                        <strong>
                                            {contest.advertiser.name}
                                        </strong>
                                    </>
                                )}
                            </Subtitle>
                        </div>
                        {contest.status === "active" && (
                            <SmallPillButton
                                onClick={() => {
                                    setCurrentContest(contest.id);
                                    push("/contest");
                                }}
                            >
                                View
                            </SmallPillButton>
                        )}
                    </TextContainer>

                    <PrizeCarousel contest={contest} inCard />

                    {contest.should_show_timer && (
                        <Countdown
                            endDate={moment(
                                contest.status === "about_to_start"
                                    ? contest.start_time
                                    : contest.end_time
                            ).valueOf()}
                        />
                    )}
                </Main>
                <DrawerContent
                    contest={contest}
                    endingSoon={endingSoon}
                    push={push}
                />
            </DrawerBackground>
        );
    } else {
        return null;
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentContest: (contestId: number) =>
        dispatch(setCurrentContest(contestId)),
    push: (path: string) => dispatch(push(path))
});

export default connect(null, mapDispatchToProps)(ContestCard);
