import axios from "axios";
import React, { useState } from "react";
import { colors } from "src/constants";
import { BlueButton } from "src/profile/components/Buttons";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    height: 100vh;
    margin: 0 20px;
    align-items: center;
`;

const Pluggy = styled.img`
    height: 200px;
    width: 200px;
`;

const Title = styled.h4`
    margin-bottom: 20px;
`;

const Response = styled.h4``;

const Button = styled(BlueButton)`
    background-color: ${colors.primaryGreen};
    padding: 15px 45px;
    margin: 10px;
`;

const DeletionInstructions = ({}) => {
    const [res, setRes] = useState();
    return (
        <Main>
            <Title>
                The Plug doesn't save any user data from your instagram account.
                We just look at your username and compare it with the one you
                entered in our social account setup then throw everything away.
                No need to delete what isn't there.
            </Title>
            <Pluggy src={"/plug/pluggy_work_gif.gif"} />
            <Button
                onClick={() =>
                    axios
                        .get(
                            "https://theplug-prod.herokuapp.com/api/public/social_account/instagram/deletion"
                        )
                        .then(res => setRes(res.data.data))
                }
            >
                Delete Request
            </Button>
            <Response>{JSON.stringify(res)}</Response>
        </Main>
    );
};
export default DeletionInstructions;
