import React from "react";
import { Route, Switch } from "react-router";
import DirectBuyDetails from "./details/DirectBuyDetails";
import DirectBuysSearch from "./search/DirectBuysSearch";
import FeatureFlagGate from "../navigation/FeatureFlagGate";
import IntroBuys from "./intro";
import NotFoundPage from "src/navigation/NotFound";
import OpenBuyDetails from "./details/OpenBuyDetails";
import featureFlags from "src/firebase/featureFlags";

const BuysRouter = () => {
    return (
        <Switch>
            <Route
                path={"/buys/direct/intro"}
                render={() => {
                    return (
                        <FeatureFlagGate
                            featureFlag={featureFlags.DirectBuyNUX}
                            redirect="/buys/direct"
                        >
                            <IntroBuys />
                        </FeatureFlagGate>
                    );
                }}
            />

            <Route
                path={"/buys/direct/:directBuyId"}
                render={({ match }) => {
                    const directBuyId = parseInt(match.params.directBuyId);
                    if (directBuyId) {
                        return (
                            <DirectBuyDetails
                                directBuyId={parseInt(match.params.directBuyId)}
                            />
                        );
                    } else {
                        return <DirectBuysSearch />;
                    }
                }}
            />

            <Route
                path={"/buys/open/:openBuyId"}
                render={({ match }) => {
                    const openBuyId = parseInt(match.params.openBuyId);
                    if (openBuyId) {
                        return (
                            <OpenBuyDetails
                                openBuyId={parseInt(match.params.openBuyId)}
                            />
                        );
                    } else {
                        return <DirectBuysSearch />;
                    }
                }}
            />

            <Route path={"/buys/direct"} render={() => <DirectBuysSearch />} />
            <Route
                path={"/buys/open"}
                render={() => <DirectBuysSearch isOpenBuy />}
            />

            <Route component={NotFoundPage} />
        </Switch>
    );
};

export default BuysRouter;
