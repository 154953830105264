import React from "react";
import styled from "styled-components";
import {
    TitleLoading,
    SubtitleLoading,
    CustomLoading,
    TextLoading
} from "src/components/SkeletonLoader";
import { breakpoints } from "src/constants";

const Wrapper = styled.div`
    display: grid;
    width: 100%;
    height: 85vh;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "top top"
        "mid mid"
        "bottom bottom";
`;
const TopLoader = styled.div`
    width: 100%;
    grid-area: top;
    padding-bottom: 24px;
`;
const MidLoader = styled.div`
    display: flex;
    grid-area: mid;
    padding-bottom: 24px;
    & > *:first-child {
        margin-right: 24px;
    }
    & > * {
        width: 100%;
        height: 80px;
        border-radius: 6px;
    }
    @media (max-width: ${breakpoints.tablet}px) {
        flex-direction: column;
        & > *:first-child {
            margin-bottom: 24px;
            margin-right: 0px;
        }
        & > *:last-child {
            height: 500px;
        }
    }
`;
const BottomLoader = styled.div`
    grid-area: bottom;
    height: 100%;
    width: 100%;
    display: flex;
    & > *:first-child {
        margin-right: 24px;
    }
    & > * {
        width: 100%;
        height: 80%;
        border-radius: 6px;
    }
    @media (max-width: ${breakpoints.tablet}px) {
        flex-direction: column;
        & > *:first-child {
            margin-bottom: 24px;
            margin-right: 0px;
            height: 80px;
        }
        & > *:last-child {
            height: 500px;
        }
    }
`;

const ScriptLoading = () => {
    return (
        <Wrapper>
            <TopLoader>
                <TitleLoading />
                <SubtitleLoading />
            </TopLoader>
            <MidLoader>
                <CustomLoading />
                <CustomLoading />
            </MidLoader>
            <BottomLoader>
                <CustomLoading />
                <CustomLoading />
            </BottomLoader>
        </Wrapper>
    );
};

export default ScriptLoading;
