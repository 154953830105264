import { OldHighlight } from "src/campaigns/types";
import { FETCH_LINK_FOR_PLUG } from "src/links/actions";
import { Media } from "src/media/types";
import { CLEAR_MODAL } from "src/modals/actions";
import { Action } from "src/types";
import {
    MODAL,
    SET_ROUTE_MODAL_OPEN,
    SET_ToS_MODAL_OPEN,
    ShoutoutScreenshot
} from "./actions";

export enum CampaignsModal {
    clear,
    pluglink,
    countdown,
    cancel,
    delete,
    mediaEmail,
    customReason,
    countries,
    downloadTypes
}

export interface ModalsUIState {
    modal: CampaignsModal;
    currentScreenshot: ShoutoutScreenshot | null;
    currentMedia: Media | null;
    countries: string[];
    currentHighlight: OldHighlight | null;
    routeModalOpen: boolean;
    toSModalOpen: boolean;
}

const initialModalsUIState: ModalsUIState = {
    modal: CampaignsModal.clear,
    currentScreenshot: null,
    currentMedia: null,
    countries: [],
    currentHighlight: null,
    routeModalOpen: false,
    toSModalOpen: false
};

function modalsUIReducer(state = initialModalsUIState, action: Action) {
    switch (action.type) {
        case CLEAR_MODAL:
            return {
                ...state,
                modal: CampaignsModal.clear,
                currentScreenshot: null,
                currentMedia: null,
                countries: [],
                currentHighlight: null
            };

        case MODAL.PLUGLINK:
        case FETCH_LINK_FOR_PLUG.SUCCESS:
            return { ...state, modal: CampaignsModal.pluglink };

        case MODAL.COUNTDOWN:
            return { ...state, modal: CampaignsModal.countdown };

        case MODAL.MEDIA_EMAIL:
            return { ...state, modal: CampaignsModal.mediaEmail };

        case MODAL.CANCEL:
            return { ...state, modal: CampaignsModal.cancel };

        case MODAL.DELETE:
            return {
                ...state,
                modal: CampaignsModal.delete,
                ...action.payload
            };

        case MODAL.CUSTOM_REASON:
            return {
                ...state,
                modal: CampaignsModal.customReason,
                ...action.payload
            };

        case MODAL.COUNTRIES:
            return {
                ...state,
                modal: CampaignsModal.countries,
                ...action.payload
            };

        case MODAL.DOWNLOAD_TYPES:
            return {
                ...state,
                modal: CampaignsModal.downloadTypes,
                ...action.payload
            };

        case SET_ROUTE_MODAL_OPEN:
            return {
                ...state,
                routeModalOpen: action.payload.open
            };

        case SET_ToS_MODAL_OPEN:
            return { ...state, toSModalOpen: action.payload };

        default:
            return state;
    }
}

export default modalsUIReducer;
