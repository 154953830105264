import { Action } from "../types";

export const SUBMIT_EVENT = {
    REQUEST: "SUBMIT_EVENT_REQUEST",
    SUCCESS: "SUBMIT_EVENT_SUCCESS",
    FAILURE: "SUBMIT_EVENT_FAILURE"
};

export interface EventParams {
    event_name: string;
    event_extra?: { [key: string]: any };
    value?: number;
}

export const submitEvent = (payload: EventParams): Action => ({
    type: SUBMIT_EVENT.REQUEST,
    payload
});

export const submitEventSuccess = (): Action => ({
    type: SUBMIT_EVENT.SUCCESS,
    payload: null
});

export const submitEventFailure = (error: string): Action => ({
    type: SUBMIT_EVENT.FAILURE,
    payload: error
});
