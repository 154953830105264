import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CarouselModal from "../../../modals/CarouselModal";
import { setCurrentModalHighlights } from "../../../ui/highlights/actions";
import { getCurrentHighlightModalMedias } from "../selectors";
import HighlightMediaCarouselCell from "../../highlight-medias/HighlightMediaCarouselCell";

const HighlightCarouselModal = () => {
    const highlightsList = useSelector(getCurrentHighlightModalMedias);
    const dispatch = useDispatch();

    if (highlightsList.length === 0) {
        return null;
    }

    return (
        <CarouselModal>
            {highlightsList.map((highlight, idx) => {
                return (
                    <HighlightMediaCarouselCell
                        key={`${highlight.highlightsPageId}-${idx}`}
                        highlight={highlight}
                        closeModal={() =>
                            dispatch(setCurrentModalHighlights([]))
                        }
                    />
                );
            })}
        </CarouselModal>
    );
};

export default HighlightCarouselModal;
