import React, { FC, InputHTMLAttributes, useEffect, useState } from "react";
import { colors, fonts, fontWeight } from "src/constants";
import {
    formattedInputTypePattern,
    formattedInputTypeToInputType,
    symbolForFormattedInput
} from "src/util";
import { preventUserInteraction } from "src/utils/styles/snippets";
import styled from "styled-components";

const Input = styled.input<{ preventUserInteraction?: boolean }>`
    width: 100%;
    padding: 16px 16px 16px 24px;
    color: ${colors.black};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.default};
    outline: none;
    transition: 0.1s ease-in;

    ${props => props.preventUserInteraction && preventUserInteraction()};

    :focus {
        border: ${colors.primaryBlue} solid 1px;
    }

    ::placeholder {
        color: ${colors.primaryGray};
    }

    position: relative;
`;

const Symbol = styled.div<{ type?: string }>`
    position: absolute;
    margin: ${props =>
        props.type === "percent" ? "40px 0px 0px 8px" : "40px 0px 0px 10px"};
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    preventUserInteraction?: boolean;
}

const MarketplaceInput: FC<InputProps> = ({ type, ...props }) => {
    const [value, setValue] = useState(props.value || "");
    const symbol = symbolForFormattedInput(type);

    useEffect(() => {
        setValue(props.value === undefined ? "" : props.value);
    }, [props.value]);

    if (type === "percent") {
        props.min = props.min || ".01";
        props.step = props.step || ".01";
    }
    return (
        <>
            <Input
                pattern={formattedInputTypePattern(type)}
                {...props}
                type={formattedInputTypeToInputType(type)}
                value={value}
                onChange={e => {
                    if (
                        type === "currency" &&
                        e.currentTarget.value.charAt(
                            e.currentTarget.value.length - 1
                        ) === "."
                    )
                        setValue(e.currentTarget.value);
                    else if (props.onChange) props.onChange(e);
                }}
            />
            {symbol && <Symbol type={type}>{symbol}</Symbol>}
        </>
    );
};

export default MarketplaceInput;
