import React from "react";
import styled from "styled-components";
import Modal from "../../modals/Modal";
import ModalButton from "../../modals/ModalButton";
import { statusTypes } from "../StatusTypes";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 300;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
`;

const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    margin-right: 10px;
`;

const StatusItem = ({ status }: any) => {
    return (
        <Item>
            <ImgContainer>
                {status.img && <img style={status.style} src={status.img} />}
            </ImgContainer>
            <div>{status.title}</div>
            <div style={{ textAlign: "right", marginLeft: "auto" }}>
                {status.description}
            </div>
        </Item>
    );
};

const StatusModal = () => {
    return (
        <Modal>
            <Main>
                <Title>Plug Status</Title>
                <Subtitle>
                    Plug status is given to the top earners at the end of each
                    month.
                </Subtitle>
                <StatusItem status={statusTypes.diamond} />
                <StatusItem status={statusTypes.gold} />
                <StatusItem status={statusTypes.silver} />
                <StatusItem status={statusTypes.none} />
            </Main>
            <ModalButton />
        </Modal>
    );
};

export default StatusModal;
