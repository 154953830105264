import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ShoutoutFillOrder } from "../types";
import { OldSocialAccount } from "../../../social-accounts/SocialAccount";
import {
    addMediaToFillOrder,
    addMediaToFillOrderFailure,
    ShoutoutMediaUpload
} from "src/campaigns/shoutouts/actions";
import { storage } from "../../../firebase/FirebaseConfig";
import Platforms from "../../../social-accounts/Platforms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import PulseLoader from "../../../components/PulseLoader";
import { deleteModal, ShoutoutScreenshot } from "src/ui/modals/actions";
import { ShoutoutState } from "../utils/evaluateShoutoutState";
import { makeId } from "../../../util";
import { colors, fonts } from "../../../constants";

const Main = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
`;

const Details = styled.div`
    display: flex;
    overflow: hidden;
`;

const IconContainer = styled.div`
    height: 36px;
    margin-right: 15px;
    font-size: 36px;
    line-height: 36px;
    color: ${colors.primaryGray};
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: ${fonts.subtitle}px;
    line-height: 14px;
    text-align: left;
    overflow: hidden;
`;

const Title = styled.div`
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Subtitle = styled.div`
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ButtonsContainer = styled.div`
    display: flex;
    height: 46px;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 5px;
`;

const LinkButton = styled.div`
    color: ${colors.primaryGray};
    line-height: 1em;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    border-right: 1px solid ${colors.mediumTeal};
    cursor: pointer;
`;

const AttachButton = styled.label`
    margin: 0;
    color: ${colors.primaryBlue};
    font-size: ${fonts.subtitle}px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const ScreenshotContainer = styled.div`
    position: relative;
    height: 46px;
    width: 52px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
`;

const Screenshot = styled.img`
    display: block;
    height: 100%;
`;

const Screen = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    font-size: 18px;
    pointer-events: none;
`;

interface Props {
    fillOrder: ShoutoutFillOrder;
    socialAccount: OldSocialAccount;
    state: ShoutoutState;
    addMediaToFillOrder: (payload: ShoutoutMediaUpload) => void;
    addMediaToFillOrderFailure: (error: string) => void;
    deleteModal: (currentScreenshot: ShoutoutScreenshot) => void;
}

const ShoutoutDetailsAccountCell = ({
    fillOrder,
    socialAccount,
    state,
    addMediaToFillOrder,
    addMediaToFillOrderFailure,
    deleteModal
}: Props) => {
    const [uploading, setUploading] = useState(false);
    const [mediaUrl, setMediaUrl] = useState("");

    useEffect(() => {
        if (fillOrder.verify_media_filename && !mediaUrl) {
            storage
                .ref(`/cpmScreenshots/${fillOrder.verify_media_filename}`)
                .getDownloadURL()
                .then((url: string) => {
                    setMediaUrl(url);
                    setUploading(false);
                })
                .catch((error: string) => {
                    console.log("screenshot error");
                });
        }
    }, [fillOrder]);

    function photoUpload(e: any) {
        e.preventDefault();
        const file = e.currentTarget.files[0];

        if (file) {
            const id = `${localStorage.getItem("userid")}_${makeId(50)}.${
                file.type.split("/")[1]
            }`;
            const ref = storage.ref().child(`cpmScreenshots/${id}`);
            setUploading(true);

            ref.put(file)
                .then(() => {
                    addMediaToFillOrder({
                        shoutout_id: parseInt(fillOrder.shoutout_purchase_id),
                        fill_order_id: fillOrder.id,
                        filename: id
                    });
                })
                .catch((error: string) => {
                    addMediaToFillOrderFailure(error);
                    window.alert("Something went wrong. Please Refresh.");
                });
        }
    }

    function renderButtons() {
        if (uploading) {
            return (
                <ScreenshotContainer>
                    <Screen>
                        <PulseLoader size={8} color={"white"} />
                    </Screen>
                </ScreenshotContainer>
            );
        } else if (fillOrder.verify_media_filename) {
            const deleteEnabled =
                state === ShoutoutState.Reserved ||
                state === ShoutoutState.Blocking;
            const screenshotStyle = deleteEnabled
                ? { cursor: "pointer" }
                : undefined;

            const shoutoutScreenshot = {
                url: mediaUrl,
                shoutout_id: parseInt(fillOrder.shoutout_purchase_id),
                fill_order_id: fillOrder.id
            };

            const onScreenshotClicked = () => {
                if (deleteEnabled) {
                    deleteModal(shoutoutScreenshot);
                }
            };

            return (
                <ScreenshotContainer
                    onClick={onScreenshotClicked}
                    style={screenshotStyle}
                >
                    <Screenshot src={mediaUrl} />
                    <Screen>
                        <FontAwesomeIcon icon={["fas", "check"]} />
                    </Screen>
                </ScreenshotContainer>
            );
        } else {
            return (
                <ButtonsContainer>
                    <CopyToClipboard text={fillOrder.redirect_url}>
                        <LinkButton>
                            <img
                                style={{ height: `20px` }}
                                src={"/gray_link.svg"}
                            />
                        </LinkButton>
                    </CopyToClipboard>
                    <AttachButton>
                        <div>Attach</div>
                        <input
                            type={"file"}
                            accept={"image/png, image/jpeg"}
                            style={{ display: "none" }}
                            onChange={photoUpload}
                        />
                    </AttachButton>
                </ButtonsContainer>
            );
        }
    }

    return (
        <Main>
            <Details>
                <IconContainer>
                    <FontAwesomeIcon
                        icon={Platforms[socialAccount.platform].icon}
                    />
                </IconContainer>
                <FlexColumn>
                    <Title>{socialAccount.username}</Title>
                    <Subtitle>
                        {parseInt(fillOrder.num_views) > 0
                            ? fillOrder.num_views
                            : ""}
                    </Subtitle>
                </FlexColumn>
            </Details>
            {renderButtons()}
        </Main>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    addMediaToFillOrder: (payload: ShoutoutMediaUpload) =>
        dispatch(addMediaToFillOrder(payload)),
    addMediaToFillOrderFailure: (error: string) =>
        dispatch(addMediaToFillOrderFailure(error)),
    deleteModal: (currentScreenshot: ShoutoutScreenshot) =>
        dispatch(deleteModal(currentScreenshot))
});

export default connect(null, mapDispatchToProps)(ShoutoutDetailsAccountCell);
