import { normalize } from "normalizr";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { deleteReq, patch, post } from "src/Api";
import { Action } from "src/types";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { ModalView, setScriptModalView } from "src/ui/scripts/actions";

import { fetchScriptAssets } from "../assets/actions";
import { fetchScriptMedias } from "../medias/actions";
import {
    createScriptSuccess,
    editScriptSuccess,
    fetchCampaignScripts,
    fetchScript,
    scriptAssetDeleteSuccess,
    scriptMediaDeleteSuccess,
    SCRIPT_ASSET_DELETE,
    SCRIPT_ASSET_LINK,
    SCRIPT_CREATE,
    SCRIPT_DELETE,
    SCRIPT_EDIT,
    SCRIPT_MEDIA_DELETE,
    SCRIPT_MEDIA_LINK,
    SCRIPT_MEDIA_UPLOAD
} from "./actions";
import { script as scriptSchema } from "../schema";

function* scriptCreateRequest({ payload }: Action) {
    try {
        const { data } = yield call(post, "/v1/script", {
            campaignId: payload.campaignId,
            title: payload.title,
            text: payload.text,
            shouldShowInApp: payload.shouldShowInApp,
            uploadedByPublisherId: payload.uploadedByPublisherId
        });
        const { campaignId, id } = data.data;

        yield put(
            createScriptSuccess(
                normalize(data.data, scriptSchema),
                campaignId,
                id
            )
        );
        yield put(
            setPillNotificationText("Script Creation Success!", "success", 2500)
        );
    } catch (error) {
        console.log(error);
        yield put(
            setPillNotificationText("Script Creation failed", "danger", 2500)
        );
    }
}

function* scriptEditRequest({ payload }: Action) {
    yield put(setScriptModalView(ModalView.loading));
    try {
        const { data, status } = yield call(
            patch,
            `/v1/script/${payload.scriptId}`,
            {
                title: payload.title,
                text: payload.text,
                shouldShowInApp: payload.shouldShowInApp
            }
        );

        yield put(editScriptSuccess(normalize(data.data, scriptSchema)));
        yield put(
            setPillNotificationText("Script edit successful!", "success", 2500)
        );
    } catch (error) {
        yield put(
            setPillNotificationText("Script edit failed", "danger", 2500)
        );
    } finally {
        yield put(setScriptModalView(ModalView.main));
    }
}
function* scriptDeleteRequest({ payload }: Action) {
    yield put(setScriptModalView(ModalView.loading));
    try {
        const { data, status } = yield call(
            deleteReq,
            `/v1/script/${payload.id}`,
            {}
        );
        yield put(
            setPillNotificationText(
                `Script: ${payload.id} deleted succesfully!`,
                "success",
                2500
            )
        );
        yield put(fetchCampaignScripts(payload.campaignId, payload.id));
    } catch (error) {
        yield put(
            setPillNotificationText(`Delete script failed`, "danger", 2500)
        );
    } finally {
        yield put(setScriptModalView(ModalView.main));
    }
}
function* scriptMediaRequest({ payload }: Action) {
    yield put(setScriptModalView(ModalView.loading));
    try {
        const { data, status } = yield call(post, `/v1/script/script_media`, {
            scriptId: payload.script.id,
            mediaIds: payload.mediaIds
        });

        yield put(
            setPillNotificationText(
                `Script: ${payload.script.id} Succesfully linked to medias`,
                "success",
                2500
            )
        );
        yield put(fetchScriptMedias(payload.script.id));
    } catch (error) {
        yield put(
            setPillNotificationText(
                `Failed to link Script: ${payload.script.id} to medias`,
                "danger",
                2500
            )
        );
    } finally {
        yield put(setScriptModalView(ModalView.main));
    }
}
function* scriptAssetRequest({ payload }: Action) {
    yield put(setScriptModalView(ModalView.loading));
    try {
        const { data, status } = yield call(post, `/v1/script/script_asset`, {
            scriptId: payload.script.id,
            assetIds: payload.assetIds
        });

        yield put(
            setPillNotificationText(
                `Script: ${payload.script.id} Succesfully linked to assets`,
                "success",
                2500
            )
        );
        yield put(fetchScriptAssets(payload.script.id));
    } catch (error) {
        yield put(
            setPillNotificationText(
                `Failed to link Script: ${payload.script.id} to assets`,
                "danger",
                2500
            )
        );
    } finally {
        yield put(setScriptModalView(ModalView.main));
    }
}
function* scriptAssetDeleteRequest({ payload }: Action) {
    yield put(setScriptModalView(ModalView.loading));
    const { scriptId, assetId } = payload;
    try {
        const { data } = yield call(
            deleteReq,
            `/v1/script/script_asset/${scriptId}/${assetId}`,
            {}
        );
        yield put(
            setPillNotificationText(
                `Sucessfully deleted asset: ${assetId} from script: ${scriptId}`,
                "success",
                2500
            )
        );
        yield put(scriptAssetDeleteSuccess(scriptId, assetId));
    } catch (error) {
        yield put(
            setPillNotificationText(
                `Failed to delete asset: ${assetId} from script: ${scriptId}`,
                "danger",
                2500
            )
        );
    } finally {
        yield put(setScriptModalView(ModalView.main));
    }
}
function* scriptMediaDeleteRequest({ payload }: Action) {
    yield put(setScriptModalView(ModalView.loading));
    const { scriptId, mediaId } = payload;

    try {
        const { data } = yield call(
            deleteReq,
            `/v1/script/script_media/${scriptId}/${mediaId}`,
            {}
        );
        yield put(
            setPillNotificationText(
                `Sucessfully deleted media: ${mediaId} from script: ${scriptId}`,
                "success",
                2500
            )
        );
        yield put(scriptMediaDeleteSuccess(scriptId, mediaId));
    } catch (error) {
        yield put(
            setPillNotificationText(
                `Failed to delete media: ${mediaId} from script: ${scriptId}`,
                "danger",
                2500
            )
        );
    } finally {
        yield put(setScriptModalView(ModalView.main));
    }
}
function* scriptMediaUploadRequest({ payload }: Action): any {
    const { uploadData, script_id, publisher_id } = payload;
    yield put(setScriptModalView(ModalView.loading));
    try {
        yield all(
            uploadData.map((data: any) =>
                call(post, "/v1/script/upload_script_media", {
                    ...data,
                    script_id,
                    publisher_id
                })
            )
        );

        yield put(
            setPillNotificationText("Media added to queue", "success", 3500)
        );
    } catch (err) {
        yield put(
            setPillNotificationText(
                `Something went wrong: ${err}`,
                "danger",
                2500
            )
        );
    } finally {
        yield put(setScriptModalView(ModalView.main));
    }
}

export default function* scriptsSaga() {
    yield all([
        takeEvery(SCRIPT_CREATE.REQUEST, scriptCreateRequest),
        takeEvery(SCRIPT_EDIT.REQUEST, scriptEditRequest),
        takeEvery(SCRIPT_DELETE.REQUEST, scriptDeleteRequest),
        takeEvery(SCRIPT_MEDIA_LINK.REQUEST, scriptMediaRequest),
        takeEvery(SCRIPT_ASSET_LINK.REQUEST, scriptAssetRequest),
        takeEvery(SCRIPT_MEDIA_UPLOAD.REQUEST, scriptMediaUploadRequest),
        takeEvery(SCRIPT_ASSET_DELETE.REQUEST, scriptAssetDeleteRequest),
        takeEvery(SCRIPT_MEDIA_DELETE.REQUEST, scriptMediaDeleteRequest)
    ]);
}
