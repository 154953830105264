import React from "react";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import { colors } from "src/constants";
import { nth } from "src/util";
import styled from "styled-components";

const CellMetric = styled.div`
    ${FlexVerticallyCentered};
    padding: 0 6px;
    height: 24px;
    color: ${colors.white};
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
`;

const CellMetricPlace = styled(CellMetric)<{ fullBorder?: true }>`
    background-color: ${colors.primaryBlue};
    border-radius: 4px 4px 0 0;
    border-radius: ${props => (props.fullBorder ? "4px" : "4px 4px 0 0")};
`;

const Icon = styled.img`
    margin-right: 4px;
    width: 12px;
    height: 12px;
`;

interface Props {
    place: number;
    fullBorder?: true;
    className?: string;
}

const OpenBuyLeaderboardBadge = ({ place, fullBorder, className }: Props) => {
    return (
        <CellMetricPlace fullBorder={fullBorder} className={className}>
            <Icon src="/leaderboard/crown.svg" />
            {place}
            {nth(place)} Place
        </CellMetricPlace>
    );
};
export default OpenBuyLeaderboardBadge;
