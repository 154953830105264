import React, { ReactNode } from "react";
import styled from "styled-components";
import { colors, fonts, maxWidthDesktop } from "../constants";

const Navbar = styled.div<{ border?: number }>`
    position: sticky;
    top: 0;
    z-index: 998;
    width: 100%;
    padding: 8px 15px;
    background-color: white;
    border-bottom: ${({ border }) => border ?? 1}px solid ${colors.mediumTeal};
    user-select: none;
`;

const Main = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    height: 40px;
    margin: 0px auto;
`;

const Logo = styled.img`
    width: 30px;
    height: 30px;
`;

const NavbarTitle = styled.div`
    font-size: ${fonts.default}px;
    font-weight: 700;
    white-space: nowrap;
`;

const Container = styled.div<{ right?: true }>`
    display: flex;
    align-items: center;
    width: 100px;
    ${props => props.right && "justify-content: flex-end;"}
`;

interface Props {
    title?: ReactNode;
    left?: ReactNode;
    right?: ReactNode;
    border?: number;
}

const SimpleNavbar = ({ left, title, right, border }: Props) => {
    return (
        <Navbar border={border}>
            <Main>
                <Container>{left}</Container>
                {title ? (
                    <NavbarTitle>{title}</NavbarTitle>
                ) : (
                    <Logo src="/logo.svg" alt="The Plug" />
                )}
                <Container right>{right}</Container>
            </Main>
        </Navbar>
    );
};

export default SimpleNavbar;
