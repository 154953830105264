import styled from "styled-components";

export default styled.div<{ opacity?: number }>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, ${props => props.opacity || 0.6});
    pointer-events: none;
`;
