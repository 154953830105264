import React from "react";
import styled from "styled-components";
import { Shoutout } from "../types";
import MediaContent from "src/campaigns/medias/components/MediaContent";
import ShoutoutDetailsMessage from "./ShoutoutDetailsMessage";
import { ShoutoutState } from "../utils/evaluateShoutoutState";
import { colors, fonts } from "../../../constants";

const VerticalCenter = styled.div`
    display: flex;
    \align-items: center;
`;

const Main = styled.div`
    background-color: ${colors.lightTeal};
`;

const Head = styled(VerticalCenter)`
    text-align: left;
    font-size: ${fonts.subtitle}px;
    line-height: 14px;
    padding: 15px;
`;

const ColumnSpaceBetween = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 34px;
`;

const Title = styled.div`
    font-size: 20px;
    line-height: 24px;
`;

const Subtitle = styled.div`
    color: ${colors.primaryGray};
`;

const PlugText = styled(Title)`
    color: ${colors.secondaryGreen};
    display: flex;
    align-items: center;
`;

const PlugIcon = styled.img`
    height: 24px;
    width: 24px;
    margin-right: 6px;
`;

const Summary = styled(VerticalCenter)`
    justify-content: space-between;
    width: 100%;
    font-size: ${fonts.subtitle}px;
`;

const Details = styled(VerticalCenter)`
    flex-direction: column;
    justify-content: center;
    width: 40%;
    min-height: 20vh;
`;

const MediaContainer = styled.div`
    position: relative;
    width: 20%;
    border-radius: 10px;
    overflow: hidden;
`;

interface Props {
    shoutout: Shoutout;
    media: string;
    coverPhoto: string;
    state: ShoutoutState;
}

const ShoutoutDetailsHeader = ({
    shoutout,
    media,
    coverPhoto,
    state
}: Props) => {
    const cpm = shoutout.cpm || 0;

    function calculateViews() {
        const firstFillOrder = shoutout.ShoutoutFillOrders[0];

        if (firstFillOrder && firstFillOrder.pay_all_views) {
            return null;
        } else {
            return shoutout.ShoutoutFillOrders.reduce((acc, fillOrder) => {
                return acc + parseInt(fillOrder.num_views);
            }, 0);
        }
    }

    const totalViews = calculateViews();

    function renderReward() {
        if (shoutout.purchase_type === "feed") {
            return `$${shoutout.total_feed_price.toFixed(2)} reward`;
        } else {
            return `$${cpm.toFixed(2)} per K`;
        }
    }

    return (
        <Main>
            <Head>
                <img
                    src={"/insta_snap_cpm/insta_snap_cpm@2x.png"}
                    style={{
                        height: "34px",
                        width: "36px",
                        marginRight: "10px"
                    }}
                />
                <ColumnSpaceBetween>
                    <div style={{ fontWeight: 600 }}>
                        Instagram/Snapchat Promo
                    </div>
                    <Subtitle style={{ fontWeight: 300 }}>
                        {renderReward()}
                    </Subtitle>
                </ColumnSpaceBetween>
            </Head>
            <Summary>
                <Details>
                    <Title>{totalViews || "Max"}</Title>
                    <Subtitle>Views Reserved</Subtitle>
                </Details>
                <MediaContainer>
                    <MediaContent
                        mediaType={shoutout.Media[0].type}
                        mediaUrl={media}
                        coverUrl={coverPhoto}
                        style={{ width: "100%" }}
                        playable={true}
                    />
                </MediaContainer>
                <Details>
                    <PlugText>
                        <PlugIcon src={"/logo.svg"} />
                        <span>
                            $
                            {totalViews
                                ? ((totalViews * cpm) / 1000).toFixed(2)
                                : cpm.toFixed(2)}
                        </span>
                    </PlugText>
                    <Subtitle>{totalViews ? "Reward" : "per K"}</Subtitle>
                </Details>
            </Summary>
            <ShoutoutDetailsMessage shoutout={shoutout} state={state} />
        </Main>
    );
};

export default ShoutoutDetailsHeader;
