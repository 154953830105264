import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserInfoHeader from "src/campaigns/scripts/components/UserInfoHeader";
import { CampaignMedia } from "src/campaigns/types";
import { GlobalWarningProps } from "src/components/GlobalWarning";
import { colors } from "src/constants";
import useCachedProfiles from "src/hooks/useCachedProfiles";
import { DeleteButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import MediaDetails from "./MediaDetails";
import PlacementForm from "./PlacementForm";

const TooltipChild = styled.div`
    width: 363px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface Props {
    media: CampaignMedia;
    isOpen: boolean;
    setShowWarning: React.Dispatch<
        React.SetStateAction<GlobalWarningProps | null>
    >;
}

const MediaModalDetails = ({ media, isOpen, setShowWarning }: Props) => {
    const [isPlacementForm, setIsPlacementForm] = useState(false);
    const [profileLoading, setProfileLoading] = useState(true);

    const placements = useSelector((state: GlobalState) => {
        return state.entities.placements;
    });
    const { profile, fetchProfile } = useCachedProfiles(
        `${media && media.uploadedByPublisherId}`
    );

    useEffect(() => {
        if (!profile && isOpen && media.uploadedByPublisherId) {
            fetchProfile(`${media.uploadedByPublisherId}`);
        } else {
            setProfileLoading(false);
        }
    }, [profile, fetchProfile]);

    return (
        <TooltipChild onMouseDown={e => e.stopPropagation()}>
            {isPlacementForm ? (
                <PlacementForm
                    placements={placements}
                    back={() => setIsPlacementForm(false)}
                    media={media}
                />
            ) : (
                <MediaDetails
                    profile={profile}
                    placements={placements.byId}
                    setIsPlacementForm={() => setIsPlacementForm(true)}
                    profileLoading={profileLoading}
                    media={media}
                    setShowWarning={setShowWarning}
                />
            )}
        </TooltipChild>
    );
};

export default MediaModalDetails;
