import styled from "styled-components";
import VerticallyCentered from "../../../components/VerticallyCentered";
import { colors, fonts } from "../../../constants";

interface ListCellProps {
    clickable?: true;
    selected?: boolean;
}

export default styled(VerticallyCentered)<ListCellProps>`
    padding: 15px 20px;
    background-color: ${colors.transparent};
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
    transition: 0.1s ease-in;

    & + & {
        border-top: 1px solid ${colors.mediumTeal};
    }

    @media (hover: hover) {
        :hover {
            background-color: ${props => props.clickable && colors.lightTeal};
        }
    }

    ${props => props.clickable && "cursor: pointer;"}

    ${props =>
        props.selected &&
        `border-color: ${colors.primaryBlue}; border: 1px; background-color: ${colors.primaryBlueLight}`}
`;
