import Platforms, { PlatformType } from "src/social-accounts/Platforms";
import { CreatorFilterOptions, FilterType } from "./types";

export const creatorGroupsFilterTypes: FilterType[] = [
    "Platform",
    "Creator Country/Region",
    "Audience Country/Region",
    "Interests",
    "Themes",
    "Followers",
    "Average Views",
    "Engagement Rate",
    "Audience Gender",
    // "Audience Age",
    "Audience Device"
];

export const creatorGroupsPlatformFilterOptions = Object.values(
    Platforms
).slice(0, -1);

export type SingleSelectionFilterTypes =
    | "Followers"
    | "Average Views"
    | "Engagement Rate"
    | "Audience Gender"
    | "Audience Age"
    | "Audience Device";

type SingleSelectionFilterOptions = {
    [key in SingleSelectionFilterTypes]: string[];
};

export const creatorGroupsSingleSelectionFilterOptions: SingleSelectionFilterOptions = {
    Followers: [
        "All",
        "0-50K Followers",
        "50K-150K Followers",
        "150K-500K Followers",
        "500K-1M Followers",
        "1M-10M Followers",
        ">10M Followers"
    ],
    "Average Views": [
        "All",
        "0-50K Views",
        "50K-150K Views",
        "150K-500K Views",
        "500K-1M Views",
        "1M-10M Views",
        ">10M Views"
    ],
    "Engagement Rate": ["All", "0-25%", "25-50%", "50-75%", "75-100%"],
    "Audience Gender": ["All", "Male", "Female"],
    "Audience Age": ["Option 1", "Option 2", "Option 3"],
    "Audience Device": ["All", "iOS", "Android"]
};

export type MultiSelectionFilterTypes = "Interests";

// type MultiSelectionFilterOptions = {
//     [key in MultiSelectionFilterTypes]: string[];
// };

// export const creatorGroupsMultiSelectionFilterOptions: MultiSelectionFilterOptions = {};
