import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import * as Icons from "src/components/icons/exports";
import { flex } from "src/utils/styles/snippets";
import MediaItemButton from "src/campaigns/landing/components/MediaItemButton";
import useInViewDispatch from "src/hooks/useInViewDispatch";
import ShareButton from "src/components/ShareButton";
import { colors, sizes, overlayBackgrounds, breakpoints } from "src/constants";
import { setMediaItemModalOpen } from "src/ui/medias/actions";
import { setCurrentCampaignMedia } from "src/media/actions";
import { submitEvent } from "src/events/actions";
import { Media } from "src/media/types";
import { MediaTab } from "src/ui/campaigns/actions";
import MediaItemModalContent from "./MediaItemModalContent";
import ShowIcon from "src/components/ShowIcon";

const Main = styled("div")<{ noMargin?: true }>`
    position: relative;
    margin-right: ${props => (props.noMargin ? "0px" : "16px")};
`;

const ShareButtonContainer = styled("div")`
    position: absolute;
    top: 0px;
    right: 0px;
`;
const StyledShowIcon = styled(ShowIcon)`
    position: absolute;
    z-index: 1;
    top: 38px;
    right: 8px;
`;

const MediaItemContainer = styled("div")`
    ${flex("column")};
    flex-shrink: 0;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 6px;
    overflow: hidden;
`;

const Modal = styled("main")`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2000;
    display: flex;
    justify-content: center;
`;

const Child = styled("section")`
    width: 100%;
    max-width: 600px;
`;

interface PreviewProps {
    image: string | null;
    tab?: MediaTab;
}

const Preview = styled("button")<PreviewProps>`
    position: relative;
    width: ${props => {
        return props.tab === "Stories"
            ? `${sizes.mediaItemWidth}px`
            : props.tab === "Square"
            ? `${sizes.mediaPreviewHeight}px`
            : `${sizes.mediaItemWidth}px`;
    }};
    height: ${sizes.mediaPreviewHeight}px;
    background-color: transparent;
    background-image: ${props => `url(${props.image})` || ""};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    outline: none;
    cursor: pointer;

    :focus {
        outline: none;
    }
`;

const PreviewOverlay = styled("div")<{ shouldShow?: boolean }>`
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: ${props =>
        props.shouldShow
            ? overlayBackgrounds.light
            : overlayBackgrounds.medium};
`;

const PreviewButton = styled("div")`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
`;

interface LinkDownloadProps {
    viewOnly?: true;
}

const LinkDownload = styled("div")<LinkDownloadProps>`
    display: ${props => (props.viewOnly ? "none" : "flex")};
`;

const ButtonDivider = styled("div")`
    width: 1px;
    background-color: ${colors.mediumTeal};
`;

interface Props {
    tab?: MediaTab;
    campaignMedia: Media;
    linkService?: string;
    onItemClick?: any;
    rank?: number;
    onDownload?: () => void;
    onCopy?: () => void;
    simpleView?: boolean;
    viewOnly?: true;
    linkOnly?: true;
    downloadOnly?: true;
    noMargin?: true;
}

const MediaItem = (props: Props) => {
    const {
        tab,
        campaignMedia,
        linkService,
        onItemClick,
        rank,
        onDownload,
        onCopy,
        simpleView,
        viewOnly,
        linkOnly,
        downloadOnly,
        noMargin
    } = props;
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    function openMediaItemModal(): void {
        dispatch(setCurrentCampaignMedia(campaignMedia));
        dispatch(setMediaItemModalOpen(true));
        trackAdClicked();
    }

    let PreviewButtonIcon: React.ReactNode = <></>;
    if (campaignMedia.type === "photo") {
        PreviewButtonIcon = <></>;
    } else if (campaignMedia.type === "video") {
        PreviewButtonIcon = <Icons.Play height={24} fill={colors.white} />;
    }

    const campaignId = campaignMedia.campaignId;
    const mediaId = campaignMedia.id;

    const trackAdViewedRef = useInViewDispatch(
        submitEvent({
            event_name: "ad_viewed",
            event_extra: {
                mediaId: campaignMedia.id,
                campaignId: campaignMedia.campaignId,
                rank
            },
            value: rank
        })
    );

    function trackAdClicked() {
        dispatch(
            submitEvent({
                event_name: "ad_clicked",
                event_extra: {
                    mediaId: campaignMedia.id,
                    campaignId: campaignMedia.campaignId,
                    rank
                },
                value: rank
            })
        );
    }

    return (
        <>
            <Main
                onClick={() => onItemClick && onItemClick(rank)}
                ref={trackAdViewedRef}
                noMargin={noMargin}
            >
                <ShareButtonContainer>
                    <ShareButton
                        link={`/campaign/${campaignId}?mediaId=${mediaId}`}
                    />
                </ShareButtonContainer>
                {!campaignMedia.shouldShowInApp && <StyledShowIcon />}
                <MediaItemContainer>
                    <Preview
                        onClick={() =>
                            simpleView ? setOpen(true) : openMediaItemModal()
                        }
                        image={campaignMedia.coverPhotoUrl}
                        tab={tab}
                    >
                        <PreviewOverlay
                            shouldShow={campaignMedia.shouldShowInApp}
                        />
                        <PreviewButton>{PreviewButtonIcon}</PreviewButton>
                    </Preview>

                    <LinkDownload viewOnly={viewOnly}>
                        {linkOnly ? (
                            <MediaItemButton
                                onClick={onCopy}
                                type="link"
                                // copyLink={appDownloadLink}
                                width="100%"
                                height={"43px"}
                                campaignMedia={campaignMedia}
                                linkService={linkService}
                            />
                        ) : downloadOnly ? (
                            <MediaItemButton
                                onClick={onDownload}
                                type="download"
                                downloadLink={campaignMedia.url}
                                width="100%"
                                height={"43px"}
                                campaignMedia={campaignMedia}
                            />
                        ) : (
                            <>
                                <MediaItemButton
                                    onClick={onCopy}
                                    type="link"
                                    // copyLink={appDownloadLink}
                                    width="calc(50% - 0.5px)"
                                    height={"43px"}
                                    campaignMedia={campaignMedia}
                                    linkService={linkService}
                                />
                                <ButtonDivider />
                                <MediaItemButton
                                    onClick={onDownload}
                                    type="download"
                                    downloadLink={campaignMedia.url}
                                    width="calc(50% - 0.5px)"
                                    height={"43px"}
                                    campaignMedia={campaignMedia}
                                />
                            </>
                        )}
                    </LinkDownload>
                </MediaItemContainer>
            </Main>

            {open && (
                <Modal>
                    <Child>
                        <MediaItemModalContent
                            currentMediaId={mediaId}
                            closeMediaItemModal={() => setOpen(false)}
                            rank={1}
                        />
                    </Child>
                </Modal>
            )}
        </>
    );
};

export default React.memo(MediaItem);
