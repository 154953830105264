import React, { useEffect, useState } from "react";
import { Connection } from "../../types";
import ConnectionCell from "./ConnectionCell";
import PaddingNoTop from "../../../components/PaddingNoTop";
import SearchBar from "../../../components/SearchBar";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";

interface Props {
    connections: Connection[];
    includeSearch?: true;
}

const IntersectionObserver = styled.div``;

const ConnectionsSection = ({ connections, includeSearch }: Props) => {
    const [searchString, setSearchString] = useState("");
    const [maxLoadedConnections, setMaxLoadedConnections] = useState(10);

    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView) {
            setMaxLoadedConnections(maxLoadedConnections + 10);
        }
    }, [inView]);

    return (
        <>
            {includeSearch && (
                <PaddingNoTop>
                    <SearchBar
                        searchString={searchString}
                        updateSearchString={setSearchString}
                    />
                </PaddingNoTop>
            )}

            <PaddingNoTop>
                {connections
                    .filter(connection => {
                        if (searchString.length === 0) {
                            return true;
                        } else {
                            return (
                                connection.other.profile.username &&
                                connection.other.profile.username
                                    .toLowerCase()
                                    .includes(searchString.toLowerCase())
                            );
                        }
                    })
                    .slice(0, maxLoadedConnections)
                    .map(connection => {
                        return (
                            <ConnectionCell
                                key={connection.other.id}
                                connection={connection}
                            />
                        );
                    })}
            </PaddingNoTop>

            <IntersectionObserver ref={ref} />
        </>
    );
};

export default ConnectionsSection;
