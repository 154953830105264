import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../../reducers";
import { ProfileModal } from "../../../reducer";
import { PublicProfile, SocialAccount } from "../../../types";
import { actionsModal } from "../../../../modals/actions";
import { push } from "connected-react-router";
import AccountView from "./AccountView";
import { useHistory } from "react-router";
import { verifyModal } from "src/profile/actions";

interface Props {
    publicProfile: PublicProfile;
}

const AccountViewController = ({ publicProfile }: Props) => {
    const currentAccount = useSelector((state: GlobalState) => {
        return state.profile.currentAccount;
    });

    const modal = useSelector((state: GlobalState) => {
        return state.profile.modal;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();

    function dispatchPush(route: string): void {
        dispatch(push(route));
    }

    function dispatchActionsModal(): void {
        dispatch(actionsModal());
    }

    function dispatchVerifyModal(): void {
        dispatch(verifyModal());
    }

    useEffect(() => {
        if (!currentAccount) {
            goBack();
        }
    }, [currentAccount]);

    if (!currentAccount) {
        return null;
    } else {
        return (
            <AccountView
                publicProfile={publicProfile}
                account={currentAccount}
                modal={modal}
                push={dispatchPush}
                goBack={goBack}
                actionsModal={dispatchActionsModal}
                verifyModal={dispatchVerifyModal}
            />
        );
    }
};

export default AccountViewController;
