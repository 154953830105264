import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchTag from "src/components/SearchTag";
import { setMediaFilters } from "src/pagination/media/actions";
import { selectMediaFilters } from "../selectors";

const MediaOwnerStatusTags = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectMediaFilters);
    const statuses = Object.keys(filters.status).filter(
        key => filters.status[key]
    );

    function removeIsOwner() {
        dispatch(setMediaFilters({ ...filters, isOwner: false }));
    }

    if (filters.isOwner)
        return (
            <SearchTag
                color="black"
                iconSrc="/tag-black-close-icon.svg"
                text={`Yours${
                    statuses.length < 3 ? ` - ${statuses.join(", ")}` : ""
                }`}
                onClick={removeIsOwner}
            />
        );

    return null;
};

export default MediaOwnerStatusTags;
