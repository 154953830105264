import { Action } from "src/types";
import { FollowersDemographicsModalType } from "./reducers";

// Set Social Account Details Modal Open -----------------------------------------------

export const SET_SOCIAL_ACCOUNT_DETAILS_MODAL_OPEN =
    "SET_SOCIAL_ACCOUNT_DETAILS_MODAL_OPEN";

export function setSocialAccountDetailsModalOpen(open: boolean): Action {
    return {
        type: SET_SOCIAL_ACCOUNT_DETAILS_MODAL_OPEN,
        payload: { open }
    };
}

// Set Social Account Group Modal Open -----------------------------------------------

export const SET_SOCIAL_ACCOUNT_GROUP_MODAL_OPEN =
    "SET_SOCIAL_ACCOUNT_GROUP_MODAL_OPEN";

export function setSocialAccountGroupModalOpen(open: boolean): Action {
    return {
        type: SET_SOCIAL_ACCOUNT_GROUP_MODAL_OPEN,
        payload: { open }
    };
}

// Set Social Account Followers Modal Open -----------------------------------------------

export const SET_SOCIAL_ACCOUNT_FOLLOWERS_MODAL_OPEN =
    "SET_SOCIAL_ACCOUNT_FOLLOWERS_MODAL_OPEN";

export function setSocialAccountFollowersModalOpen(
    open: boolean,
    type: FollowersDemographicsModalType
): Action {
    return {
        type: SET_SOCIAL_ACCOUNT_FOLLOWERS_MODAL_OPEN,
        payload: { open, type }
    };
}
