import React, { useEffect } from "react";
import { DirectBuy } from "../../modules/direct-buy/types";
import { DirectBuyDetailsModalState } from "../../../ui/direct-buys/reducer";
import { AnimatePresence } from "framer-motion";
import DirectBuyDetailsRejectionReasonModal from "./DirectBuyDetailsRejectionReasonModal";
import SimpleMediaModal from "../../../modals/SimpleMediaModal";
import { useDispatch, useSelector } from "react-redux";
import {
    selectDirectBuyCurrentMedia,
    selectDirectBuyDetailsModalState
} from "../../selectors";
import { clearModal } from "../../../modals/actions";
import ScriptModal from "src/campaigns/scripts/modals/ScriptModal";
import DirectBuyDetailsActionsModal from "./DirectBuyDetailsActionsModal";

interface Props {
    directBuy?: DirectBuy;
}

const DirectBuyDetailsModals = ({ directBuy }: Props) => {
    const dispatch = useDispatch();
    const modalState = useSelector(selectDirectBuyDetailsModalState);
    const media = useSelector(selectDirectBuyCurrentMedia);
    function renderModal() {
        switch (modalState) {
            case DirectBuyDetailsModalState.actions:
                return <DirectBuyDetailsActionsModal />;
            case DirectBuyDetailsModalState.rejectionReason:
                if (directBuy)
                    return (
                        <DirectBuyDetailsRejectionReasonModal
                            directBuy={directBuy}
                        />
                    );
                else return null;
            case DirectBuyDetailsModalState.simpleMedia:
                return (
                    <SimpleMediaModal
                        media={media}
                        closeModal={() => {
                            dispatch(clearModal());
                        }}
                    />
                );
            case DirectBuyDetailsModalState.script:
                return <ScriptModal />;
            default:
                return null;
        }
    }
    return <AnimatePresence>{renderModal()}</AnimatePresence>;
};

export default DirectBuyDetailsModals;
