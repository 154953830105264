import React, {
    Ref,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import { Editor, createEditor } from "slate";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { BaseEditor, Descendant } from "slate";
import { CustomElement, CustomText } from "./types";
import {
    DefaultElement,
    Leaf,
    LinkElement,
    ListItem,
    OrderedList,
    RuleItem,
    UnorderedList
} from "./Elements";
import Toolbar from "./Toolbar";
import slate from "remark-slate";
import { unified } from "unified";
import markdown from "remark-parse";

import { withHistory } from "slate-history";

import { CustomLoading } from "../SkeletonLoader";

import handleKeyPressing from "./Keybinds";
import { serializeContent, withInlines } from "./utils";
import styled, { css } from "styled-components";
import { hideOverflow } from "src/utils/styles/snippets";
import { colors } from "src/constants";
import CustomEditor from "./CustomEditor";
import { AnimatePresence, motion } from "framer-motion";

const EditableSection = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 4px 0px 4px 4px;
    border-radius: 8px;
    border: 1px solid ${colors.blueGrey2};
`;

interface EditableContainerProps {
    editableStyles: any;
    addFocus?: true;
    addFocusError?: boolean;
    focused: boolean;
}

const EditableContainer = styled.div<EditableContainerProps>`
    ${hideOverflow()};
    flex: 1;
    cursor: text;
    p {
        margin-bottom: 8px !important;
    }
    li > p {
        margin-bottom: 0px !important;
    }
    ul,
    ol {
        margin-bottom: 8px !important;
    }
    #slate-editor > :last-child {
        margin-bottom: 0px !important;
    }
    ${props => css`
        & > #slate-editor {
            ${props.editableStyles};
            transition: 0.1s ease-in;
        }
    `};

    /* Adds focus border and handles error, focused, and unfocused states. */
    ${props =>
        props.addFocus &&
        css`
            & > #slate-editor {
                border: ${props.addFocusError
                    ? `${colors.primaryRed} 1px solid`
                    : props.focused
                    ? `${colors.primaryBlue} 1px solid`
                    : `${colors.mediumTeal} 1px solid`};
            }
        `}
`;

declare module "slate" {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor;
        Element: CustomElement;
        Text: CustomText;
    }
}

export type TextEditorRef = {
    controlEditor: (callback: (editor: Editor) => void) => void;
};

interface Props {
    initialText?: string;
    editorContent: Descendant[];
    setEditorContent: (newContent: Descendant[]) => void;
    handleChange: (newContent: Descendant[]) => void;
    autoFocus?: boolean;
    setIsFocused?: (bool: boolean) => void;
    setIsError: (bool: boolean) => void;
    leftComponent?: React.ReactNode;
    rightComponent?: React.ReactNode;
    toolbarStyles?: any;
    editableStyles?: any;
    addFocus?: true;
    addFocusError?: boolean;
    placeholder?: string;
    PreviewComponent?: React.ElementType;
    fadeInToolbar?: true;
}
const TextEditor = (
    {
        initialText,
        editorContent,
        setEditorContent,
        handleChange,
        autoFocus = false,
        setIsFocused,
        setIsError,
        leftComponent,
        rightComponent,
        toolbarStyles,
        editableStyles,
        addFocus,
        addFocusError,
        placeholder,
        PreviewComponent,
        fadeInToolbar
    }: Props,
    ref: Ref<TextEditorRef>
) => {
    const [editor] = useState(() =>
        withHistory(withInlines(withReact(createEditor())))
    );
    const [isLoading, setIsLoading] = useState(true);
    const [focused, setFocused] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    useImperativeHandle(ref, () => ({
        controlEditor: (callback: (editor: Editor) => void) => {
            callback(editor);
        }
    }));

    useEffect(() => {
        if (initialText) {
            const newLine = "\n";
            const replacedText = initialText.replace(
                new RegExp("  \n", "g"),
                newLine
            );
            unified()
                //@ts-ignore
                .use(markdown)
                .use(slate)
                .process(replacedText)
                .then((data: any) => {
                    setEditorContent(data.result);
                    setIsLoading(false);
                })
                .catch(e => {
                    setIsError(true);
                });
        } else {
            setEditorContent([{ type: "paragraph", children: [{ text: "" }] }]);
            setIsLoading(false);
        }
    }, []);

    const renderElement = useCallback(props => {
        switch (props.element.type) {
            case "ol_list":
                return <OrderedList {...props} />;
            case "ul_list":
                return <UnorderedList {...props} />;
            case "list_item":
                return <ListItem {...props} />;
            case "link":
                return <LinkElement {...props} />;
            case "rule_item":
                return <RuleItem {...props} />;
            default:
                return <DefaultElement {...props} />;
        }
    }, []);
    const renderLeaf = useCallback(props => {
        return <Leaf {...props} />;
    }, []);

    const onEditorChange = (newContent: any) => {
        const { adjacentListIdx } = newContent.reduce(
            (acc: any, val: any, idx: number) => {
                if (
                    val.type === acc.previousValType &&
                    (val.type === "ol_list" || val.type === "ul_list")
                ) {
                    return {
                        adjacentListIdx: idx,
                        previousValType: val.type
                    };
                } else {
                    return { ...acc, previousValType: val.type };
                }
            },
            { adjacentListIdx: null, previousVal: "" }
        );

        if (adjacentListIdx) {
            CustomEditor.combineAdjacentLists(editor, [adjacentListIdx]);
        } else {
            handleChange(newContent);
        }
    };
    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    if (isLoading) {
        return (
            <>
                <CustomLoading width="100%" height="100%" />
            </>
        );
    }

    return (
        <>
            <Slate
                editor={editor}
                value={editorContent}
                onChange={onEditorChange}
            >
                {fadeInToolbar ? (
                    <AnimatePresence>
                        {focused && (
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    transform: "translateY(-4px)"
                                }}
                                animate={{
                                    opacity: 1,
                                    transform: "translateY(0px)"
                                }}
                                exit={{
                                    opacity: 0,
                                    transform: "translateY(-4px)"
                                }}
                                transition={{ delay: 0 }}
                            >
                                <Toolbar
                                    toolbarStyles={toolbarStyles}
                                    shouldShowPreviewBtn={
                                        PreviewComponent ? true : false
                                    }
                                    togglePreview={togglePreview}
                                    showPreview={showPreview}
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                ) : (
                    <Toolbar
                        toolbarStyles={toolbarStyles}
                        shouldShowPreviewBtn={PreviewComponent ? true : false}
                        togglePreview={togglePreview}
                        showPreview={showPreview}
                    />
                )}

                <EditableSection>
                    {leftComponent}
                    {showPreview && PreviewComponent ? (
                        <PreviewComponent
                            text={serializeContent(editorContent)}
                        />
                    ) : (
                        <EditableContainer
                            editableStyles={editableStyles}
                            addFocus={addFocus}
                            focused={focused}
                            addFocusError={addFocusError}
                        >
                            <Editable
                                onFocus={() =>
                                    (setIsFocused && setIsFocused(true)) ||
                                    setFocused(true)
                                }
                                onBlur={() => {
                                    if (!fadeInToolbar) {
                                        return (
                                            (setIsFocused &&
                                                setIsFocused(false)) ||
                                            setFocused(false)
                                        );
                                    }
                                }}
                                autoFocus={autoFocus}
                                id="slate-editor"
                                renderElement={renderElement}
                                renderLeaf={renderLeaf}
                                onKeyDown={(
                                    event: React.KeyboardEvent<HTMLInputElement>
                                ) => handleKeyPressing(editor, event)}
                                placeholder={placeholder}
                            />
                        </EditableContainer>
                    )}
                    {rightComponent}
                </EditableSection>
            </Slate>
        </>
    );
};

export default forwardRef(TextEditor);
