import React from "react";
import styled from "styled-components";
import VerticallyCentered from "../../../components/VerticallyCentered";
import { padding, Subtitle, Title } from "../Details.styled";
import PlusCard from "../../../profile/components/PlusCard";
import { formatNumber } from "../../../util";
import { replace } from "connected-react-router";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import {
    SubtitleLoading,
    TitleLoading
} from "../../../components/SkeletonLoader";

const ItemHeader = styled.div`
    ${padding}

    & > * ~ * {
        margin-top: 18px;
    }
`;

const SmallLogo = styled.img`
    height: 24px;
    width: 24px;
    margin-right: 6px;
`;

interface Props {
    currentCampaign: number | null;
    replace: (route: string, state: any) => void;
    total?: {
        plugcoins: number;
        dollars: number;
    };
    daysActive?: number;
}

const PaymentEarnings = ({
    total,
    daysActive,
    currentCampaign,
    replace
}: Props) => {
    if (!total || daysActive === undefined)
        return (
            <ItemHeader>
                <div>
                    <Title>
                        <div>Your Earnings</div>
                        <TitleLoading />
                    </Title>
                    <Subtitle>
                        <div>- Days Active</div>
                    </Subtitle>
                </div>
            </ItemHeader>
        );
    return (
        <ItemHeader>
            <div>
                <Title>
                    Your Earnings
                    <VerticallyCentered>
                        <SmallLogo src={"/logo.svg"} />
                        <div>
                            {`$${formatNumber(total.dollars.toFixed(2))}`}
                        </div>
                    </VerticallyCentered>
                </Title>
                <Subtitle>{daysActive ? daysActive : "0"} Days Active</Subtitle>
            </div>
            {total.plugcoins === 0 && (
                <PlusCard
                    text="You haven’t earned any money from this campaign yet, click below to get started."
                    button={{
                        text: "Promote",
                        callback: () => {
                            replace(`/campaign/${currentCampaign}/promote`, {
                                scrollToPromote: true
                            });
                        }
                    }}
                />
            )}
        </ItemHeader>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    currentCampaign: state.ui.campaign.currentCampaignId
});

const mapDispatchToProps = (dispatch: any) => ({
    replace: (route: string, state: any) => dispatch(replace(route, state))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentEarnings);
