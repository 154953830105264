import { Action } from "../types";
import {
    DailyEarnings,
    PublisherReportItem,
    UserAnalytics,
    WeeklyActivity
} from "./Analytics";
import { MediaLeaderboardItem, ReferralLeaderboardItem } from "./Leaderboard";

// -------------------------------------------------------------------

export const FETCH_WEEKLY_ACTIVITY = {
    REQUEST: "FETCH_WEEKLY_ACTIVITY_REQUEST",
    SUCCESS: "FETCH_WEEKLY_ACTIVITY_SUCCESS",
    FAILURE: "FETCH_WEEKLY_ACTIVITY_FAILURE"
};

export const fetchWeeklyActivity = (): Action => ({
    type: FETCH_WEEKLY_ACTIVITY.REQUEST,
    payload: null
});

export const fetchWeeklyActivitySuccess = (
    weeklyActivity: WeeklyActivity
): Action => ({
    type: FETCH_WEEKLY_ACTIVITY.SUCCESS,
    payload: { weeklyActivity }
});

export const fetchWeeklyActivityFailure = (error: string): Action => ({
    type: FETCH_WEEKLY_ACTIVITY.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const FETCH_USER_ANALYTICS = {
    REQUEST: "FETCH_USER_ANALYTICS_REQUEST",
    SUCCESS: "FETCH_USER_ANALYTICS_SUCCESS",
    FAILURE: "FETCH_USER_ANALYTICS_FAILURE"
};

export const fetchUserAnalytics = (): Action => ({
    type: FETCH_USER_ANALYTICS.REQUEST,
    payload: null
});

export const fetchUserAnalyticsSuccess = (
    userAnalytics: UserAnalytics
): Action => ({
    type: FETCH_USER_ANALYTICS.SUCCESS,
    payload: { userAnalytics }
});

export const fetchUserAnalyticsFailure = (error: string): Action => ({
    type: FETCH_USER_ANALYTICS.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const FETCH_REFERRAL_LEADERBOARD = {
    REQUEST: "FETCH_REFERRAL_LEADERBOARD_REQUEST",
    SUCCESS: "FETCH_REFERRAL_LEADERBOARD_SUCCESS",
    FAILURE: "FETCH_REFERRAL_LEADERBOARD_FAILURE"
};

export const fetchReferralLeaderboard = (): Action => ({
    type: FETCH_REFERRAL_LEADERBOARD.REQUEST,
    payload: null
});

export const fetchReferralLeaderboardSuccess = (
    referralLeaderboard: ReferralLeaderboardItem[]
): Action => ({
    type: FETCH_REFERRAL_LEADERBOARD.SUCCESS,
    payload: { referralLeaderboard }
});

export const fetchReferralLeaderboardFailure = (error: string): Action => ({
    type: FETCH_REFERRAL_LEADERBOARD.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const FETCH_MEDIA_LEADERBOARD = {
    REQUEST: "FETCH_MEDIA_LEADERBOARD_REQUEST",
    SUCCESS: "FETCH_MEDIA_LEADERBOARD_SUCCESS",
    FAILURE: "FETCH_MEDIA_LEADERBOARD_FAILURE"
};

export const fetchMediaLeaderboard = (): Action => ({
    type: FETCH_MEDIA_LEADERBOARD.REQUEST,
    payload: null
});

export const fetchMediaLeaderboardSuccess = (
    mediaLeaderboard: MediaLeaderboardItem[]
): Action => ({
    type: FETCH_MEDIA_LEADERBOARD.SUCCESS,
    payload: { mediaLeaderboard }
});

export const fetchMediaLeaderboardFailure = (error: string): Action => ({
    type: FETCH_MEDIA_LEADERBOARD.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export interface reportParameters {
    startDate: string;
    endDate: string;
    primary?: true;
}

export const FETCH_RAW_ANALYTICS_DATA = {
    REQUEST: "FETCH_RAW_ANALYTICS_DATA_REQUEST",
    SUCCESS: "FETCH_RAW_ANALYTICS_DATA_SUCCESS",
    FAILURE: "FETCH_RAW_ANALYTICS_DATA_FAILURE"
};

export const fetchRawAnalyticsData = (payload: reportParameters): Action => ({
    type: FETCH_RAW_ANALYTICS_DATA.REQUEST,
    payload
});

export const fetchRawAnalyticsDataSuccess = (payload: {
    startDate: string;
    endDate: string;
    data: PublisherReportItem[];
}): Action => ({
    type: FETCH_RAW_ANALYTICS_DATA.SUCCESS,
    payload
});

export const fetchRawAnalyticsDataFailure = (error: string): Action => ({
    type: FETCH_RAW_ANALYTICS_DATA.FAILURE,
    payload: null
});

// -------------------------------------------------------------------

export const RAW_ANALYTICS_DATA_LOADED = "RAW_ANALYTICS_DATA_LOADED";

export const rawAnalyticsDataLoaded = (): Action => ({
    type: RAW_ANALYTICS_DATA_LOADED,
    payload: null
});

// -------------------------------------------------------------------

export const FETCH_RAW_24_HOUR_ANALYTICS_DATA = {
    REQUEST: "FETCH_RAW_24_HOUR_ANALYTICS_DATA_REQUEST",
    SUCCESS: "FETCH_RAW_24_HOUR_ANALYTICS_DATA_SUCCESS",
    FAILURE: "FETCH_RAW_24_HOUR_ANALYTICS_DATA_FAILURE"
};

export const fetchRaw24HourAnalyticsData = (): Action => ({
    type: FETCH_RAW_24_HOUR_ANALYTICS_DATA.REQUEST,
    payload: null
});

export const fetchRaw24HourAnalyticsDataSuccess = (
    payload: PublisherReportItem[]
): Action => ({
    type: FETCH_RAW_24_HOUR_ANALYTICS_DATA.SUCCESS,
    payload
});

export const fetchRaw24HourAnalyticsDataFailure = (error: string): Action => ({
    type: FETCH_RAW_24_HOUR_ANALYTICS_DATA.FAILURE,
    payload: null
});

// -------------------------------------------------------------------

export interface emailReportParameters {
    email: string;
    startDate?: string;
    endDate?: string;
}

export const SEND_EMAIL_REPORT = {
    REQUEST: "SEND_EMAIL_REPORT_REQUEST",
    SUCCESS: "SEND_EMAIL_REPORT_SUCCESS",
    FAILURE: "SEND_EMAIL_REPORT_FAILURE"
};

export const sendEmailReport = (payload: emailReportParameters): Action => ({
    type: SEND_EMAIL_REPORT.REQUEST,
    payload
});

export const sendEmailReportSuccess = (): Action => ({
    type: SEND_EMAIL_REPORT.SUCCESS,
    payload: null
});

export const sendEmailReportFailure = (error: string): Action => ({
    type: SEND_EMAIL_REPORT.FAILURE,
    payload: null
});

// -------------------------------------------------------------------

export const FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS = {
    REQUEST: "FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS_REQUEST",
    SUCCESS: "FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS_SUCCESS",
    FAILURE: "FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS_FAILURE"
};

export const fetchDailyCampaignLinkAnalytics = (
    campaign_id: number
): Action => ({
    type: FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS.REQUEST,
    payload: { campaign_id }
});

export const fetchDailyCampaignLinkAnalyticsSuccess = (
    dailyCustomLinkEarnings: any
): Action => ({
    type: FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS.SUCCESS,
    payload: { dailyCustomLinkEarnings }
});

export const fetchDailyCampaignLinkAnalyticsFailure = (): Action => ({
    type: FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS.FAILURE,
    payload: null
});

// -------------------------------------------------------------------

export const SET_CURRENT_DAILY_EARNINGS = "SET_CURRENT_DAILY_EARNINGS";

export const setCurrentDailyEarnings = (
    currentDailyEarnings: DailyEarnings
): Action => ({
    type: SET_CURRENT_DAILY_EARNINGS,
    payload: { currentDailyEarnings }
});

// Modals
// -------------------------------------------------------------------

export interface ModalMedia {
    type: string;
    mediaUrl: string;
    coverUrl?: string;
}

export const MODAL = {
    MEDIA: "MODAL_MEDIA",
    EMAIL_REPORT: "MODAL_EMAIL_REPORT"
};

export const mediaModal = (media: ModalMedia): Action => ({
    type: MODAL.MEDIA,
    payload: media
});

export const emailReportModal = (
    emailDateRange: [string, string] | null
): Action => ({
    type: MODAL.EMAIL_REPORT,
    payload: { emailDateRange }
});
