import React from "react";
import styled from "styled-components";
import { FlexVerticallyCentered } from "../../../components/CommonStyles";
import { ReactComponent as Hidden } from "../../../components/icons/account-hidden.svg";
import { ReactComponent as Connections } from "../../../components/icons/account-connections.svg";
import { colors, fonts } from "../../../constants";
import { PushAccountParams, pushWithAccount, verifyModal } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { SocialAccount } from "../../types";
import { GlobalState } from "src/reducers";
import { setSocialAccountStatus } from "src/social-accounts/actions";
import { database } from "src/firebase/FirebaseConfig";
import axios from "axios";

const Main = styled.div`
    height: 56px;
    padding: 0 12px;
    ${FlexVerticallyCentered};
    color: ${colors.white};
`;

const Text = styled.div`
    flex: 1;
    font-size: ${fonts.smallTitle}px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    margin: 0 14px;
`;

const PillButton = styled.div<{ color: string }>`
    ${FlexVerticallyCentered};
    height: 22px;
    border-radius: 11px;
    color: ${({ color }) => color};
    background-color: ${colors.white};
    padding: 0 12px;
    font-size: 11px;
    font-weight: 700;
    cursor: pointer;
`;

interface Props {
    account: SocialAccount;
}

const RateInfo = ({ account }: Props) => {
    const { verified, visibility } = account;
    const dispatch = useDispatch();
    const status = useSelector(
        (state: GlobalState) =>
            state.entities.socialAccounts.statuses[account.id]
    );

    if (status === "verified" && account)
        setTimeout(() => {
            axios.patch(`/v1/socialAccount/${account.id}/verifyViewed`);
        }, 2000);

    const statusText = () => {
        if (account.platform === "tiktok")
            switch (status?.includes("Error:") ? "failure" : status) {
                case "pending":
                    return "Verification In Progress";
                case "verified":
                    return "Account Verified!";
                case "failure":
                    return "Account verifiecation Failed.";
                default:
                    return verified
                        ? "Account Verified!"
                        : "Account requires verification.";
            }
    };

    const statusColor = () => {
        switch (status?.includes("Error:") ? "failure" : status) {
            case "pending":
                return colors.primaryYellow;
            case "failure":
                return colors.primaryRed;
            default:
                return verified ? colors.primaryBlue : colors.gray2;
        }
    };

    if (!verified || status === "verified") {
        return (
            <Main>
                <Hidden fill={statusColor()} />
                <Text>{statusText()}</Text>
                {status !== "recent" && (
                    <PillButton
                        color={statusColor()}
                        onClick={() => {
                            dispatch(
                                pushWithAccount({
                                    account,
                                    path: "/profile/accounts/view"
                                })
                            );
                            dispatch(verifyModal());
                        }}
                    >
                        {status?.includes("Error:") ? "Try Again" : "Verify"}
                    </PillButton>
                )}
            </Main>
        );
    }

    if (visibility === "hidden") {
        return (
            <Main>
                <Hidden fill={statusColor()} />
                <Text>These rates are hidden.</Text>
                <PillButton
                    color={statusColor()}
                    onClick={() => dispatch(push("/profile/accounts"))}
                >
                    Update
                </PillButton>
            </Main>
        );
    }

    if (visibility === "connections") {
        return (
            <Main>
                <Connections fill={colors.primaryYellow} />
                <Text>These rates are only visible to your connections.</Text>
                <PillButton
                    color={colors.primaryYellow}
                    onClick={() => dispatch(push("/profile/accounts"))}
                >
                    Update
                </PillButton>
            </Main>
        );
    }

    return null;
};

export default RateInfo;
