import React from "react";
import styled from "styled-components";
import { formatNumber } from "../../../util";
import { fonts } from "src/constants";

const VerticalCenter = styled.div`
    display: flex;
    align-items: center;
`;

const Emoji = styled.div`
    font-size: 30px;
    line-height: 30px;
    margin-right: 10px;
`;

const Tickets = styled.div`
    font-size: ${fonts.title}px;
    line-height: 24px;
    font-weight: bold;
    margin: 0 10px 2px 0;
    letter-spacing: 2px;
`;

const Label = styled.div`
    font-weight: bold;
    font-size: ${fonts.subtitle}px;
    line-height: 14px;
`;

interface Props {
    tickets: number;
}

const RaffleTicketsEarned: React.FC<Props> = ({ tickets, children }) => {
    return (
        <>
            <VerticalCenter>
                <Emoji>🎟</Emoji>
                <Tickets>{formatNumber(tickets)}</Tickets>
                <Label>Tickets</Label>
            </VerticalCenter>
            {children}
        </>
    );
};

export default RaffleTicketsEarned;
