import React from "react";
import styled from "styled-components";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import TagSelectorButtons, { TagButtonsState } from "./TagSelectorButtons";
import adTagInfo from "./adTagInfo";
import Switch from "react-switch";
import SwitchContainer from "../components/SwitchContainer";
import SwitchDescription from "../components/SwitchDescription";
import Divider from "../../components/Divider";
import Button from "react-bootstrap/Button";
import { colors, fonts } from "../../constants";
import MainContainer from "src/components/MainContainer";

const AdPanel = styled.div`
    padding: 20px;
    background-color: ${colors.lightTeal};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const MediaContainer = styled.div`
    max-height: 320px;
    max-width: 100%;
    position: relative;
    margin-top: 15px;
`;

const Video = styled.video`
    max-height: 320px;
    max-width: 100%;
    border: 1px solid ${colors.mediumTeal};
`;

const Photo = styled.img`
    max-height: 320px;
    max-width: 100%;
    border: 1px solid ${colors.mediumTeal};
`;

const Tag = styled.img`
    position: absolute;
    right: 1px;
    bottom: 1px;
`;

const Title = styled.div`
    color: darkgray;
    font-size: ${fonts.subtitle}px;
    line-height: 40px;
    font-weight: bold;
`;

interface Props {
    setTagScreen: (state: boolean) => void;
    mediaUrl: string;
    type: string;
    tagState: TagButtonsState;
    setTagState: (state: TagButtonsState) => void;
    uploadMedia: () => void;
}

const UploadMediaTag = ({
    setTagScreen,
    mediaUrl,
    type,
    tagState,
    setTagState,
    uploadMedia
}: Props) => {
    return (
        <>
            <SimpleNavbar
                title={"Upload Media"}
                left={
                    <NavButton
                        onClick={() => setTagScreen(false)}
                        text={"Back"}
                    />
                }
            />

            <MainContainer>
                <AdPanel>
                    <div>Please add an 'ad' tag to this piece of media:</div>
                    <MediaContainer>
                        {type === "video" ? (
                            <Video src={mediaUrl} />
                        ) : (
                            <Photo src={mediaUrl} />
                        )}
                        {tagState === TagButtonsState.none || (
                            <Tag
                                style={adTagInfo[tagState].style}
                                src={adTagInfo[tagState].image}
                            />
                        )}
                    </MediaContainer>
                    <Title>STYLE</Title>
                    <TagSelectorButtons
                        tagState={tagState}
                        setTagState={setTagState}
                    />
                </AdPanel>
                <Divider />
                <SwitchContainer>
                    <SwitchDescription>
                        This media already has an ‘ad’ tag.
                    </SwitchDescription>
                    <Switch
                        onColor={"#28c056"}
                        offColor={"#cccccc"}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        handleDiameter={24}
                        checked={tagState === TagButtonsState.none}
                        onChange={(checked: boolean) => {
                            if (checked) {
                                setTagState(TagButtonsState.none);
                            } else {
                                setTagState(TagButtonsState.jetfuel);
                            }
                        }}
                    />
                </SwitchContainer>
                <Divider />
                <div style={{ padding: "15px" }}>
                    <Button block variant="success" onClick={uploadMedia}>
                        Upload Now
                    </Button>
                </div>
            </MainContainer>
        </>
    );
};

export default UploadMediaTag;
