import { useSelector } from "react-redux";
import { post, setToken } from "src/Api";
import { setFirebaseToken } from "src/firebase/FirebaseConfig";
import { GlobalState } from "src/reducers";

const useLoginAsUser = () => {
    const employeeId = localStorage.getItem("employeeId");
    const currentId = useSelector((state: GlobalState) =>
        state?.profile?.profile?.status === "employee"
            ? state.profile.profile.id
            : ""
    );

    const loginAs = (
        self?: boolean,
        userId?: string,
        setError?: (error: string) => void
    ) => {
        if (!userId && !self) {
            setError && setError("User id to login as is required");
            return;
        }
        const token =
            localStorage.getItem("employeeToken") ||
            localStorage.getItem("token");
        setToken(token?.toString());
        post("/api/login_as_user", {
            loginAsUser: self ? employeeId : userId
        })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem(
                        "firebaseToken",
                        response.data.firebaseToken
                    );
                    localStorage.setItem("userid", response.data.userid.id);
                    indexedDB.deleteDatabase("firebaseLocalStorageDb");
                    if (self) {
                        localStorage.removeItem("employeeToken");
                        localStorage.removeItem("employeeId");
                    } else {
                        if (token) localStorage.setItem("employeeToken", token);
                        if (!employeeId && currentId)
                            localStorage.setItem(
                                "employeeId",
                                currentId.toString()
                            );
                    }
                    setFirebaseToken(response.data.firebaseToken);
                    window.location.reload();
                } else
                    setError &&
                        setError("Failed to login as user try another id");
            })
            .catch(
                err =>
                    setError &&
                    setError("Failed to login as user try another id")
            );
    };

    return loginAs;
};

export default useLoginAsUser;
