import React from "react";
import styled from "styled-components";
import { FlexVCSpaceBetween } from "../../components/CommonStyles";
import { colors, fonts } from "../../constants";

const Main = styled.div`
    ${FlexVCSpaceBetween}
    height: 64px;
    padding: 0 18px;
    border-top: 1px solid ${colors.mediumTeal};
`;

const Title = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    line-height: 17px;
`;

const RightCaret = styled.img`
    width: 8px;
    cursor: pointer;
    transform: rotate(-90deg);
`;

interface Props {
    title: string;
    onClick?: () => void;
}

const ModalOption = ({ title, onClick }: Props) => (
    <Main onClick={onClick}>
        <Title>{title}</Title>
        <RightCaret src={"/caret.svg"} />
    </Main>
);

export default ModalOption;
