import React from "react";
import { useDispatch } from "react-redux";
import { colors, fonts, fontWeight } from "src/constants";
import { setSocialAccountFollowersModalOpen } from "src/ui/social-accounts/actions";
import { FollowersDemographicsModalType } from "src/ui/social-accounts/reducers";
import {
    clearButton,
    grayBackgroundHoverState
} from "src/utils/styles/snippets";
import styled from "styled-components";
import FollowerDemographicsProgressBar from "./FollowerDemographicsProgressBar";

const Main = styled.section`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    background-color: ${colors.white};
    border-radius: 8px;
`;

const Title = styled.header`
    display: flex;
    align-items: center;
    height: 46px;
    font-size: ${fonts.semiSmallTitle}px;
    font-weight: ${fontWeight.default};
`;

const ViewAllButton = styled.button`
    ${clearButton()};
    ${grayBackgroundHoverState()};
    padding: 8px 0px;
    border-radius: 8px;
    font-size: ${fonts.semiSmallTitle}px;
    font-weight: ${fontWeight.medium};
    text-align: center;
`;

interface Props {
    type: FollowersDemographicsModalType;
    title: string;
    demographicsData: any[];
}

const FollowerDemographicsCard = ({ type, title, demographicsData }: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // JSX -----------------------------------------------------------
    return (
        <Main>
            <Title>{title}</Title>

            {demographicsData.slice(0, 3).map((data: any, index: number) => {
                return (
                    <FollowerDemographicsProgressBar
                        key={`follower demographics card ${data.name} ${data.code} ${data.weight} ${index} `}
                        name={
                            type === "Gender" || type === "Age"
                                ? data.code
                                : data.name
                        }
                        weight={data.weight}
                    />
                );
            })}

            <ViewAllButton
                type="button"
                onClick={() =>
                    dispatch(setSocialAccountFollowersModalOpen(true, type))
                }
            >
                View All
            </ViewAllButton>
        </Main>
    );
};

export default FollowerDemographicsCard;
