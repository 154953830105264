import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../../../constants";
import { WeeklyLeaderboardItem } from "../../types";

const Main = styled.div<{ textColor?: string }>`
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    ${props => props.textColor && `color: ${props.textColor};`}
`;

interface Props {
    leaderboardItem?: WeeklyLeaderboardItem | null;
    amount?: string;
    rank?: string;
    color?: string;
}

const CoinText = ({ leaderboardItem = null, amount, rank, color }: Props) => {
    const convertToUSD = (coin: string) => {
        let usd = parseInt(coin) * 0.05;
        let res = "";
        if (coin.includes("k")) {
            if (Math.abs(usd) < 1) res = (usd * 1000).toString();
            else res = usd + "k";
        } else if (coin.includes("m")) {
            if (Math.abs(usd) < 1) res = usd * 1000 + "k";
            else res = usd + "m";
        } else {
            res = usd.toString();
        }
        if (res.includes("-")) return "-$" + res.replace("-", "");
        else return "$" + res;
    };

    if (amount) {
        return <Main textColor={color || colors.secondaryGreen}>{amount}</Main>;
    } else if (leaderboardItem) {
        if (leaderboardItem.coinBucket) {
            return <Main>{convertToUSD(leaderboardItem.coinBucket)}</Main>;
        } else if (leaderboardItem.relativeCoinBucket) {
            const sign = leaderboardItem.relativeCoinBucket.slice(0, 1);

            return (
                <Main
                    textColor={
                        sign === "+" ? colors.secondaryGreen : colors.primaryRed
                    }
                >
                    {convertToUSD(leaderboardItem.relativeCoinBucket) +
                        ` ${sign === "+" ? "up" : "down"}`}
                </Main>
            );
        } else return null;
    } else if (rank) {
        return <Main>{rank}</Main>;
    } else {
        return null;
    }
};

export default CoinText;
