import React from "react";
import { PublicProfileData } from "../../types";
import { Route, Switch } from "react-router";
import PublicAccountViewController from "./view";
import PublicAccountsPageController from "./page";
import NotFoundPage from "src/navigation/NotFound";
import SocialAccountProfile from "src/social-accounts/profile/SocialAccountProfile";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import RouteModal from "src/components/RouteModal";
import CreatorGroupsMainPage from "src/marketplace/creator-groups/pages/CreatorGroupsMainPage";
import CreatorGroupsCreateOrEditPage from "src/marketplace/creator-groups/pages/CreatorGroupsCreateOrEditPage";
import { getCreators } from "src/marketplace/creator-groups/selectors.creators-search";
import styled, { css } from "styled-components";
import {
    clearButton,
    whiteBackgroundHoverState
} from "src/utils/styles/snippets";
import { Creator } from "src/marketplace/creator-groups/types.creator-groups";

const SocialAccountsCarousel = styled.main`
    display: flex;
    height: 100%;
    max-height: 90vh;

    overflow-x: auto;
    border: blue solid 2px;
`;

const SocialAccountContainer = styled.div`
    flex-shrink: 0;
    width: 100%;

    border: red solid 1px;
`;

interface Props {
    publicData?: PublicProfileData;
}

const PublicAccountsRouter = ({ publicData }: Props) => {
    const { routeModalOpen } = useSelector((state: GlobalState) => {
        return state.ui.modals;
    });

    // const creators = useSelector(getCreators);
    // console.log("Creators:", creators);

    // function leftArrowClick(): void {
    //     console.log("Left");
    // }

    // function rightArrowClick(): void {
    //     console.log("Right");
    // }

    return (
        <Switch>
            {!routeModalOpen && (
                <Route
                    path={"/public/:otherId/accounts/v/:socialId"}
                    render={({ match }) => {
                        return (
                            <SocialAccountProfile
                                socialId={match.params.socialId}
                                otherId={match.params.otherId}
                            />
                        );

                        // return (
                        //     <PublicAccountViewController
                        //         publicData={publicData}
                        //         socialId={match.params.socialId}
                        //     />
                        // );
                    }}
                />
            )}

            {/* <Route
                path={"/public/:otherId/accounts/v/:socialId"}
                render={({ match }) => {
                    return !routeModalOpen ? (
                        <SocialAccountProfile
                            socialId={match.params.socialId}
                            otherId={match.params.otherId}
                        />
                    ) : (
                        <RouteModal
                            leftButtonOnClick={leftArrowClick}
                            rightButtonOnClick={rightArrowClick}
                        >
                            <SocialAccountsCarousel>
                                {creators.map((creator: Creator) => {
                                    return (
                                        <SocialAccountContainer>
                                            <SocialAccountProfile
                                                socialId={String(
                                                    creator.social_account_id
                                                )}
                                                otherId={String(
                                                    creator.publisher_id
                                                )}
                                                inRouteModal
                                            />
                                        </SocialAccountContainer>
                                    );
                                })}
                            </SocialAccountsCarousel>
                        </RouteModal>
                    );

                    // return (
                    //     <PublicAccountViewController
                    //         publicData={publicData}
                    //         socialId={match.params.socialId}
                    //     />
                    // );
                }}
            /> */}

            <Route
                exact
                path={"/public/:otherId/accounts"}
                render={() => {
                    return (
                        <PublicAccountsPageController publicData={publicData} />
                    );
                }}
            />
            <Route component={NotFoundPage} />
        </Switch>
    );
};

export default PublicAccountsRouter;
