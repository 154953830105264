import React from "react";
import {
    DirectBuyStep,
    DirectBuyStepCollection
} from "../../../modules/direct-buy-step/types";
import { DirectBuyStepsEntitiesState } from "../../../modules/direct-buy-step/reducer";
import { ResourceContainer, ResourceDescription } from "../styledComponents";
import DirectBuyDetailsMediaCell from "./DirectBuyDetailsMediaCell";
import styled from "styled-components";
import { setSimpleMedia } from "../../../../modals/actions";
import { directBuyDetailsModal } from "../../../../ui/direct-buys/actions";
import { DirectBuyDetailsModalState } from "../../../../ui/direct-buys/reducer";
import { useDispatch } from "react-redux";
import { StepById } from "src/buys/modules/open-buys/reducer";
import DirectBuyDetailsSoundCell from "./DirectBuyDetailsSoundCell";

const HorizontalScrollOuter = styled.div`
    display: flex;
    overflow-x: scroll;
`;

const HorizontalScrollInner = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

const MediaCellContainer = styled.div`
    width: 148px;
    & + & {
        padding-left: 10px;
    }
`;

export enum DirectBuyDetailsMediaMode {
    media = "media",
    assets = "assets"
}

interface Props {
    mode: DirectBuyDetailsMediaMode;
    collection: DirectBuyStepCollection;
    steps: DirectBuyStepsEntitiesState | StepById;
    actionCallback: VoidFunction;
}

const DirectBuyDetailsMedia = ({
    mode,
    collection,
    steps,
    actionCallback
}: Props) => {
    const dispatch = useDispatch();
    const filteredSteps = collection.combined
        .filter(id =>
            mode === DirectBuyDetailsMediaMode.assets
                ? steps[id].asset
                : steps[id].media
        )
        .map(id => steps[id]);
    if (filteredSteps.length === 0) {
        return null;
    }

    const launchMediaViewer = (
        url: string | null,
        coverPhotoUrl: string | null,
        type: string
    ) => {
        if (url) {
            dispatch(
                setSimpleMedia({
                    url,
                    ...(coverPhotoUrl && { coverPhotoUrl }),
                    type
                })
            );
            dispatch(
                directBuyDetailsModal(DirectBuyDetailsModalState.simpleMedia)
            );
        }
    };

    const mediaCellForStep = (step: DirectBuyStep) => {
        const media = step.media;
        const asset = step.asset;
        if (mode === DirectBuyDetailsMediaMode.media && media) {
            return (
                <DirectBuyDetailsMediaCell
                    mediaPreviewHeight={mediaPreviewHeight}
                    url={media.url}
                    coverPhotoUrl={media.coverPhotoUrl}
                    type={media.type}
                    time={media.time}
                    id={media.id}
                    hideDownload={step.mediaHideDownload}
                    previewCallback={() => {
                        launchMediaViewer(
                            media.url,
                            media.coverPhotoUrl,
                            media.type
                        );
                    }}
                    actionCallback={actionCallback}
                />
            );
        } else if (mode === DirectBuyDetailsMediaMode.assets && asset) {
            if (asset.type === "sound")
                return <DirectBuyDetailsSoundCell asset={asset} />;
            else
                return (
                    <DirectBuyDetailsMediaCell
                        mediaPreviewHeight={mediaPreviewHeight}
                        url={asset.url}
                        coverPhotoUrl={asset.coverPhotoUrl}
                        type={asset.type}
                        hideDownload={step.mediaHideDownload}
                        previewCallback={() => {
                            launchMediaViewer(
                                asset.url,
                                asset.coverPhotoUrl,
                                asset.type
                            );
                        }}
                        actionCallback={actionCallback}
                    />
                );
        }
    };

    const mediaPreviewHeight = 170;
    return (
        <ResourceContainer>
            <ResourceDescription>
                {mode === DirectBuyDetailsMediaMode.assets
                    ? filteredSteps[0]?.asset?.type === "sound"
                        ? "SOUNDS"
                        : "ASSETS"
                    : "MEDIA"}
            </ResourceDescription>
            {filteredSteps.length === 1 ? (
                mediaCellForStep(filteredSteps[0])
            ) : (
                <HorizontalScrollOuter>
                    <HorizontalScrollInner>
                        {filteredSteps.map(step => {
                            return (
                                <MediaCellContainer key={step.id}>
                                    {mediaCellForStep(step)}
                                </MediaCellContainer>
                            );
                        })}
                    </HorizontalScrollInner>
                </HorizontalScrollOuter>
            )}
        </ResourceContainer>
    );
};

export default DirectBuyDetailsMedia;
