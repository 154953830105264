import { AnimatePresence } from "framer-motion";
import React from "react";
import FullScreenModal from "src/modals/FullScreenModal";
import { ModalFilterProps, ModalProps } from "../utils/props";
import AnalyticsCampaignMediaScreen from "src/analytics/AnalyticsCampaignMediaScreen";
import { AnalyticsFullscreenState, AnalyticsScrollTo } from "../AnalyticsMain";

interface Props extends ModalProps, ModalFilterProps {
    totalUseCampaigns: boolean;
    totalSelectedCampaigns: Set<string>;
    totalSelectedMedia: Set<string>;
    setTotalSelectedCampaigns: (campaigns: Set<string>) => void;
    setTotalSelectedMedia: (media: Set<string>) => void;
}

const TotalEarningsFilterModal = ({
    open,
    closeModal,
    showMore,
    setScrollTo,
    setFullscreenState,
    parsedData,
    totalUseCampaigns,
    totalSelectedCampaigns,
    totalSelectedMedia,
    setTotalSelectedCampaigns,
    setTotalSelectedMedia
}: Props) => {
    return (
        <AnimatePresence>
            {open && (
                <FullScreenModal closeModal={closeModal}>
                    <AnalyticsCampaignMediaScreen
                        parsedData={parsedData}
                        initialSelected={
                            totalUseCampaigns
                                ? totalSelectedCampaigns
                                : totalSelectedMedia
                        }
                        onCancelTapped={() => {
                            setScrollTo(AnalyticsScrollTo.totalEarnings);
                            showMore
                                ? setFullscreenState(
                                      AnalyticsFullscreenState.totalEarningsMore
                                  )
                                : setFullscreenState(
                                      AnalyticsFullscreenState.default
                                  );
                        }}
                        onDoneTapped={selectedItems => {
                            totalUseCampaigns
                                ? setTotalSelectedCampaigns(selectedItems)
                                : setTotalSelectedMedia(selectedItems);
                            setScrollTo(AnalyticsScrollTo.totalEarnings);
                            showMore
                                ? setFullscreenState(
                                      AnalyticsFullscreenState.totalEarningsMore
                                  )
                                : setFullscreenState(
                                      AnalyticsFullscreenState.default
                                  );
                        }}
                    />
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default TotalEarningsFilterModal;
