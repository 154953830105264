import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors, fonts } from "src/constants";
import { GlobalState } from "src/reducers";
import {
    InstructionStepState,
    setInstructionSteps,
    setInstructionsUnsaved
} from "src/ui/instructions-sets/actions";
import { clearSpacing } from "src/utils/styles/snippets";
import styled from "styled-components";
import InstructionsFormInput from "./InstructionsFormInput";

const Main = styled.div`
    padding: 12px;
    color: ${colors.black};
    background-color: ${colors.blueGrey};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 12px 0px 12px;
    width: 100%;
`;

const Title = styled.h3`
    ${clearSpacing()};
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: 600;
    text-transform: uppercase;
`;

const DeleteButton = styled.img`
    width: 16px;
    height: 16px;
    cursor: pointer;
`;

interface Props {
    instructionSteps: InstructionStepState[];
    currentInstructionStepIndex: number;
    customLinkUrl: string;
    setCustomLinkUrl: Function;
}

const InstructionsFormStepDragAreaCustomLinkItem = ({
    instructionSteps,
    currentInstructionStepIndex,
    customLinkUrl,
    setCustomLinkUrl
}: Props) => {
    const pageType = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets.instructionsPageType;
    });

    useEffect(() => {
        const currentInstructionStep =
            instructionSteps[currentInstructionStepIndex];

        const customLinkUrl = currentInstructionStep["customLink"];

        if (customLinkUrl) setCustomLinkUrl(customLinkUrl);
    }, [instructionSteps]);

    const dispatch = useDispatch();
    function handleDelete(): void {
        setCustomLinkUrl(null);

        const updatedInstructionSteps = Array.from(instructionSteps);
        const currentInstructionStep =
            updatedInstructionSteps[currentInstructionStepIndex];
        currentInstructionStep["customLink"] = null;
        dispatch(setInstructionSteps(updatedInstructionSteps));
        dispatch(setInstructionsUnsaved(true));
    }

    const [customError, setCustomError] = useState(false);
    useEffect(() => {
        setCustomError(!customLinkUrl.includes("http"));
    }, [customLinkUrl]);

    return (
        <Main>
            <Header>
                <Title>Custom Link URL</Title>
                {pageType !== "Main" && (
                    <DeleteButton
                        src="/marketplace-instructions-delete-icon.svg"
                        alt="marketplace-instructions-delete-icon"
                        onClick={handleDelete}
                    />
                )}
            </Header>

            <InstructionsFormInput
                setValue={setCustomLinkUrl}
                value={customLinkUrl}
                placeholder="Add a link."
                customError={customError}
                customErrorMessage={`You must include "http" in your custom link.`}
                padding="8px"
                border
            />
        </Main>
    );
};

export default InstructionsFormStepDragAreaCustomLinkItem;
