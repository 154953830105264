import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation, RouteComponentProps } from "react-router";
import { GlobalState } from "../../reducers";
import SearchMain from "./SearchMain";
import SearchAccountDetails from "./SearchAccountDetails";
import FullscreenLoader from "../../components/FullscreenLoader";
import { submitEvent } from "../../events/actions";
import { fetchCampaigns } from "src/campaigns/actions";
import NotFoundPage from "src/navigation/NotFound";

export enum TabState {
    all,
    users,
    accounts,
    campaigns
}

interface Props {
    location: Location;
}

const Search: React.FC<Props> = ({ location }) => {
    const campaignsAllIds = useSelector((state: GlobalState) => {
        return state.entities.campaigns.allIds;
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(submitEvent({ event_name: "search_entered" }));

        if (campaignsAllIds.length === 0) {
            dispatch(fetchCampaigns());
        }
    }, []);

    const routeLocation = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [routeLocation]);

    interface MatchParams {
        socialId: string;
    }

    interface MatchProps extends RouteComponentProps<MatchParams> {}

    return (
        <Switch>
            <Route
                exact
                path={"/search"}
                render={() => <SearchMain currentTab={TabState.all} />}
            />

            <Route
                exact
                path={"/search/users"}
                render={() => <SearchMain currentTab={TabState.users} />}
            />

            <Route
                path={"/search/accounts/details/:socialId"}
                render={({ match }: MatchProps) => (
                    <SearchAccountDetails socialId={match.params.socialId} />
                )}
            />

            <Route
                exact
                path={"/search/accounts"}
                render={() => <SearchMain currentTab={TabState.accounts} />}
            />

            <Route
                exact
                path={"/search/campaigns"}
                render={() => <SearchMain currentTab={TabState.campaigns} />}
            />

            <Route component={NotFoundPage} />
        </Switch>
        // <SearchTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
    );
};

export default Search;
