import React from "react";
import styled, { css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { AnimatePresence } from "framer-motion";
import ReferredUser from "src/profile/components/ReferredUser";
import FullScreenModal from "src/modals/FullScreenModal";
import SimpleNavbar from "src/components/SimpleNavbar";
import { ReferredUser as ReferredUserType } from "src/profile/types";
import { getReferredUsers } from "src/profile/referrals/selectors";
import { setReferredUserModal } from "src/ui/referrals/actions";
import { GlobalState } from "src/reducers";
import { colors } from "src/constants";
import closeIcon from "./close.svg";
import { grid } from "src/utils/styles/snippets";

const HeaderTitle = styled("h2")`
    margin: 0px;
    padding: 0px;
    color: ${colors.black};
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    line-height: 1.044375rem;
    letter-spacing: -0.025625rem;
`;

const closeStyles = css`
    width: 13.2px;
    height: 13.2px;
    border: transparent solid 1px;
`;

const ModalCloseButton = styled("img").attrs(() => ({
    src: closeIcon,
    alt: "Modal Close Button"
}))`
    ${closeStyles};
    cursor: pointer;
`;

const ReferredUsersContainer = styled("div")`
    flex: 1;
    padding: 0px 18px 18px;
    overflow-y: scroll;
`;

const ReferredUsersText = styled("span")`
    display: block;
    margin: 18px 0px 11px;
    color: ${colors.primaryGray};
    font-size: 0.75rem;
    font-weight: bold;
    text-align: left;
    line-height: 0.875rem;
`;

const ReferredUsers = styled("div")`
    ${grid(1, "auto", 10, "center")};
`;

const ReferredUsersModal = () => {
    const referredUsersModalOpenState = useSelector(
        (state: GlobalState) => state.ui.referrals.referredUsersModalOpen
    );

    const dispatch = useDispatch();
    function closeModal(): void {
        dispatch(setReferredUserModal(false));
    }

    const referredUsers = useSelector(getReferredUsers);

    const ReferredUsersList = referredUsers.map(
        (user: ReferredUserType, index: number) => {
            return (
                <ReferredUser
                    key={`${user.referredPublisher}-${index}`}
                    userId={user.referredPublisher}
                    percentEarnings={user.commission * 100}
                    dateReferralCreated={user.created_at}
                    daysActive={user.daysActive}
                    referralBonus={user.earnedPlugcoins / 20}
                />
            );
        }
    );

    return (
        <AnimatePresence>
            {referredUsersModalOpenState && (
                <FullScreenModal closeModal={closeModal}>
                    <SimpleNavbar
                        left={<ModalCloseButton onClick={closeModal} />}
                        title={<HeaderTitle>Referred Users</HeaderTitle>}
                    />

                    <ReferredUsersContainer>
                        <ReferredUsersText>REFERRED USERS</ReferredUsersText>
                        <ReferredUsers>{ReferredUsersList}</ReferredUsers>
                    </ReferredUsersContainer>
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default ReferredUsersModal;
