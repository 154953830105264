import React, { useState } from "react";
import styled from "styled-components";
import SimpleNavbar from "src/components/SimpleNavbar";
import LoadingButton from "src/components/LoadingButton";
import { colors, fonts } from "../../constants";
import { Media } from "src/media/types";
import PromoteFlowTabs from "./components/PromoteFlowTabs";
import MediaSearch from "src/media/search";
import { useEffect } from "react";
import { CampaignMedia } from "../types";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";

const Close = styled.img`
    padding: 15px;
    cursor: pointer;

    svg {
        margin-right: 15px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-around;
    height: 82px;
    justify-content: center;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    white-space: nowrap;
    padding-bottom: 5px;
    padding-left: 18px;
`;

const SubTitle = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 400;
    text-align: left;
    color: ${colors.primaryGray};
    width: 100%;
    padding-left: 18px;
`;

const ButtonContainer = styled.div`
    display: flex;
    padding: 10px 18px 3px 18px;
    margin: auto 10px 20px 10px;
`;

interface Props {
    onClose: (step?: string) => void;
    onNext: (media: CampaignMedia) => void;
}

const MediaSelect = ({ onClose, onNext }: Props) => {
    const selected = useSelector(
        (state: GlobalState) => state.ui.promote.selected
    );
    useEffect(() => {
        if (selected) onNext(selected);
    }, [selected]);
    return (
        <Container>
            <SimpleNavbar
                title={"Promote"}
                left={
                    <Close
                        onClick={() => onClose("select")}
                        src={"/close.svg"}
                    />
                }
            />
            <PromoteFlowTabs
                selected="repost"
                onSelect={() => onClose("upload")}
            />
            <Header>
                <Title>Which ad will you post?</Title>
                <SubTitle>Select an option below</SubTitle>
            </Header>
            <MediaSearch />
        </Container>
    );
};
export default MediaSelect;
