import React, { useState, useEffect } from "react";
import { goBack, push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FlexContent } from "src/components/CommonStyles";
import { ReactComponent as Elipsis } from "src/components/icons/ellipsis-button.svg";
import NavButton from "src/components/NavButton";
import SimpleNavbar from "src/components/SimpleNavbar";
import AccountCell from "src/profile/accounts/components/AccountCell";
import { OutlineTextButton } from "src/profile/components/Buttons";
import RateFormItem from "../RateFormItem";
import OfferPricingModal, {
    pricingFinished,
    PricingParams
} from "./OfferPricingModal";
import { AnimatePresence } from "framer-motion";
import OfferTypeModalController, {
    offerTypeFinished,
    OfferTypeParams
} from "./OfferTypeModalController";
import { formatNumber, freezeBody, thawBody } from "src/util";
import AccountSelectionModal from "./AccountSelectionModal";
import { selectAccount } from "src/profile/accounts/selectors";
import { GlobalState } from "src/reducers";
import { useLocation } from "react-router";
import RateFormConfirmButton from "../RateFormConfirmButton";
import RateFormSection from "../RateFormSection";
import OfferTypeItem from "../OfferTypeItem";
import useInitialParams from "../hooks/useInitialParams";
import { deletePublisherOffer } from "../actions";
import RateActionsModal from "./RateActionsModal";
import {
    rateFormModalOpen,
    setAccountId,
    setPublisherOfferId
} from "src/ui/profile/actions";
import MainContainer from "src/components/MainContainer";

const Main = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const EditButton = styled(OutlineTextButton)`
    font-size: 12px;
    align-self: flex-start;
`;

enum RateModal {
    account = "account",
    offerType = "offerType",
    pricing = "pricing",
    actions = "actions"
}

const RateEdit = () => {
    useEffect(() => {
        freezeBody();
        return thawBody();
    }, []);

    const accountId = useSelector((state: GlobalState) => {
        return state.ui.profile.accountId;
    });

    const publisherOfferId = useSelector((state: GlobalState) => {
        return state.ui.profile.publisherOfferId;
    });

    const edit = useSelector((state: GlobalState) => {
        return state.ui.profile.isRateFormEdit;
    });

    const {
        initialPricing,
        initialOfferType,
        initialAccountId
    } = useInitialParams(publisherOfferId);

    const dispatch = useDispatch();
    const location = useLocation();
    const [modal, setModal] = useState<null | RateModal>(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [pricing, setPricing] = useState<PricingParams>(initialPricing);
    const [offerType, setOfferType] = useState<OfferTypeParams>(
        initialOfferType
    );
    const selectedAccount = useSelector((state: GlobalState) =>
        selectAccount(state, accountId)
    );

    useEffect(() => {
        setPricing(initialPricing);
        setOfferType(initialOfferType);
    }, [selectedAccount?.platform, publisherOfferId]);

    function closeModal(): void {
        dispatch(setPublisherOfferId(null));
        dispatch(rateFormModalOpen(false));
    }

    const isRateEditOpen = useSelector((state: GlobalState) => {
        return state.ui.profile.rateFormModalOpen;
    });

    return (
        <MainContainer>
            <Main>
                <SimpleNavbar
                    left={<NavButton back onClick={() => dispatch(goBack())} />}
                />
                <FlexContent>
                    <RateFormSection
                        title="Account(s)"
                        description="Which account is this rate for?"
                        finished={!!selectedAccount}
                        item={
                            <AccountCell
                                account={selectedAccount}
                                onClick={() =>
                                    !edit && setModal(RateModal.account)
                                }
                                action={
                                    edit ? (
                                        <div />
                                    ) : (
                                        <EditButton>Edit</EditButton>
                                    )
                                }
                            />
                        }
                        create="Add Account"
                        onCreate={() => setModal(RateModal.account)}
                    />
                    <RateFormSection
                        title="Offer Type"
                        description="What service are you offering?"
                        finished={offerTypeFinished(offerType)}
                        item={
                            <OfferTypeItem
                                offerType={offerType}
                                edit={() => {
                                    setOfferType(initialOfferType);
                                    setModal(RateModal.offerType);
                                }}
                            />
                        }
                        create="Add Offer Type"
                        onCreate={() => setModal(RateModal.offerType)}
                    />
                    <RateFormSection
                        title="Pricing"
                        description="How much does it cost?"
                        finished={pricingFinished(pricing)}
                        item={
                            <RateFormItem
                                title={`$${formatNumber(
                                    pricing.amount?.toFixed(2) || 0
                                )}`}
                                description={
                                    pricing.paymentType || pricing.customType
                                }
                                edit={() => {
                                    setPricing(initialPricing);
                                    setModal(RateModal.pricing);
                                }}
                            />
                        }
                        create="Add Pricing"
                        onCreate={() => setModal(RateModal.pricing)}
                    />
                </FlexContent>
                <RateFormConfirmButton
                    offerType={offerType}
                    pricing={pricing}
                    accountId={accountId}
                    edit={edit}
                    editId={publisherOfferId}
                    hasChanges={hasChanges}
                />
            </Main>

            <AnimatePresence>
                {modal === RateModal.pricing && (
                    <OfferPricingModal
                        pricing={pricing}
                        setPricing={pricing => {
                            setPricing(pricing);
                            setHasChanges(true);
                        }}
                        closeModal={() => setModal(null)}
                    />
                )}
                {modal === RateModal.offerType && (
                    <OfferTypeModalController
                        closeModal={() => setModal(null)}
                        offerType={offerType}
                        setOfferType={offerType => {
                            setOfferType(offerType);
                            setHasChanges(true);
                        }}
                        platform={selectedAccount?.platform || ""}
                    />
                )}
                {modal === RateModal.account && (
                    <AccountSelectionModal
                        closeModal={() => setModal(null)}
                        accountId={accountId}
                        setAccountId={accountId => {
                            // setAccountId(accountId);
                            dispatch(setAccountId(accountId));
                            setHasChanges(true);
                        }}
                    />
                )}
            </AnimatePresence>

            {modal === RateModal.actions && (
                <RateActionsModal
                    closeModal={() => setModal(null)}
                    deleteOffer={() => {
                        if (publisherOfferId && accountId) {
                            dispatch(
                                deletePublisherOffer(
                                    publisherOfferId,
                                    accountId
                                )
                            );
                            closeModal();
                        }
                    }}
                />
            )}
        </MainContainer>
    );
};

export default RateEdit;
