import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentOpenBuy } from "src/admin-tools/selectors.admin-tools";
import { colors, fonts, fontWeight } from "src/constants";
import { storage } from "src/firebase/FirebaseConfig";
import MarketplaceInput from "src/marketplace/components/MarketplaceInput";
import MarketplaceTextarea from "src/marketplace/components/MarketplaceTextarea";
import PlusCard from "src/profile/components/PlusCard";
import { GlobalState } from "src/reducers";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { makeId } from "src/util";
import {
    primaryBlueTextHoverState,
    whiteBackgroundHoverState
} from "src/utils/styles/snippets";
import { EventInput } from "src/utils/types/form";
import styled from "styled-components";
import ChallengeFormSection from "./ChallengeFormSection";
import ChallengeFormSubSection from "./ChallengeFormSubSection";
import { OpenBuy } from "src/buys/modules/open-buys/types";

const UploadedImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const UploadedImage = styled.img`
    width: 100%;
    height: 180px;
    border-radius: 6px;
    overflow: hidden;
    object-fit: cover;
`;

const ChangeImageButton = styled.label`
    ${whiteBackgroundHoverState()};
    ${primaryBlueTextHoverState()};
    position: absolute;
    padding: 8px 16px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 4px;
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.default};
    cursor: pointer;
`;

const ChangeImageInput = styled.input`
    display: none;
`;

interface Props {
    challengeDetails: Partial<OpenBuy>;
    updateChallengeDetails: (name: string, value: string) => void;
    updatePosterFilename: (val: string) => void;
}

const ChallengeDetails = ({
    challengeDetails: {
        posterUrl,
        name,
        description,
        linkUrl,
        linkDescription,
        usageRightsLengthDays,
        submissionLimitPerPublisher,
        submissionLimitPerSocialAccount
    },
    updateChallengeDetails,
    updatePosterFilename
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const currentOpenBuy = useSelector(getCurrentOpenBuy);
    const { challengeDetailsIncomplete } = useSelector((state: GlobalState) => {
        return state.ui.adminTools;
    });

    // Local State ---------------------------------------------------
    const [mediaErrorMessage, setMediaErrorMessage] = useState("");

    // Local Functions -----------------------------------------------
    function _checkIfCorrectFileType(fileList: FileList | null): void {
        if (fileList) {
            const { type } = fileList[0];
            const fileExtension = type.split("/")[1];
            if (
                fileExtension !== "jpeg" &&
                fileExtension !== "png" &&
                fileExtension !== "jpg"
            ) {
                setMediaErrorMessage("Your media must be a jpeg, png, or jpg.");
            } else {
                setMediaErrorMessage("");
            }
        }
    }
    function _generateAndSetMediaUrl(
        noUploadError: boolean,
        fileList: FileList | null
    ): void {
        if (noUploadError && fileList) {
            const file = fileList[0];

            const type = file.type.split("/")[0];
            const url = URL.createObjectURL(file);
            const generatedFilename = `${localStorage.getItem(
                "userid"
            )}_${makeId(50)}.${type}`;
            const filename = `openBuyPoster/${generatedFilename}`;
            const ref = storage.ref().child(filename);
            ref.put(file)
                .then(() => {
                    updatePosterFilename(generatedFilename);
                })
                .catch(() => {
                    dispatch(
                        setPillNotificationText(
                            "There was an error in uploading your image. Please refresh and try again.",
                            "danger",
                            3500
                        )
                    );
                });

            updateChallengeDetails("posterUrl", url);
            // dispatch(setDetailsUnsaved(true));
        }
    }
    function handleUploadImage(event: EventInput): void {
        event.preventDefault();
        _checkIfCorrectFileType(event.target.files);
        _generateAndSetMediaUrl(
            mediaErrorMessage.length === 0,
            event.target.files
        );
    }

    // JSX -----------------------------------------------------------
    return (
        <ChallengeFormSection title="Challenge Details">
            <ChallengeFormSubSection
                title="*CHALLENGE THUMBNAIL IMAGE"
                error={{
                    isError: challengeDetailsIncomplete && !posterUrl?.length,
                    message: "You must add a thumbnail."
                }}
            >
                {posterUrl?.length ?? 0 > 0 ? (
                    <UploadedImageContainer>
                        <UploadedImage src={posterUrl} alt="Uploaded Image" />
                        <ChangeImageButton htmlFor="inputChallengeChangeImageButton">
                            Change Image
                        </ChangeImageButton>
                        <ChangeImageInput
                            id="inputChallengeChangeImageButton"
                            type="file"
                            accept="image/jpeg, image/png, image/jpg"
                            onChange={handleUploadImage}
                        />
                    </UploadedImageContainer>
                ) : (
                    <PlusCard
                        text="Select a thumbnail image to represent your challenge."
                        upload={{
                            text: "Upload Image",
                            uploadHandler: handleUploadImage
                        }}
                    />
                )}
            </ChallengeFormSubSection>
            <ChallengeFormSubSection
                title="*CHALLENGE TITLE"
                error={{
                    isError: challengeDetailsIncomplete && !name?.length,
                    message: "You must set a title."
                }}
                children={
                    <MarketplaceInput
                        placeholder="Input Offer Title"
                        value={name}
                        onChange={e =>
                            updateChallengeDetails(
                                "name",
                                e.currentTarget.value
                            )
                        }
                    />
                }
            />
            <ChallengeFormSubSection
                title="*CHALLENGE description"
                error={{
                    isError: challengeDetailsIncomplete && !description?.length,
                    message: "You must set a description."
                }}
                children={
                    <MarketplaceTextarea
                        value={description || ""}
                        handleChange={e =>
                            updateChallengeDetails(
                                "description",
                                e.currentTarget.value
                            )
                        }
                        placeholder="Input Offer description"
                    />
                }
            />
            <ChallengeFormSubSection
                title="LINK URL"
                children={
                    <MarketplaceInput
                        placeholder="Input Link URL"
                        value={linkUrl}
                        onChange={e =>
                            updateChallengeDetails(
                                "linkUrl",
                                e.currentTarget.value
                            )
                        }
                    />
                }
            />
            <ChallengeFormSubSection
                title="Usage Rights Length (days)"
                children={
                    <MarketplaceInput
                        value={usageRightsLengthDays}
                        onChange={e =>
                            updateChallengeDetails(
                                "usageRightsLengthDays",
                                e.currentTarget.value
                            )
                        }
                    />
                }
            />
            <ChallengeFormSubSection
                title="LINK DESCRIPTION"
                children={
                    <MarketplaceInput
                        placeholder="Input Link Description"
                        value={linkDescription}
                        onChange={e =>
                            updateChallengeDetails(
                                "linkDescription",
                                e.currentTarget.value
                            )
                        }
                    />
                }
            />
            <ChallengeFormSubSection
                title="Submission Limit Per Publisher"
                children={
                    <MarketplaceInput
                        value={submissionLimitPerPublisher}
                        onChange={e =>
                            updateChallengeDetails(
                                "submissionLimitPerPublisher",
                                e.currentTarget.value
                            )
                        }
                    />
                }
            />
            <ChallengeFormSubSection
                title="Submission Limit Per Social Account"
                children={
                    <MarketplaceInput
                        value={submissionLimitPerSocialAccount}
                        onChange={e =>
                            updateChallengeDetails(
                                "submissionLimitPerSocialAccount",
                                e.currentTarget.value
                            )
                        }
                    />
                }
            />
        </ChallengeFormSection>
    );
};

export default ChallengeDetails;
