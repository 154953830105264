import { Action } from "../types";
import {
    CustomLinkEarnings,
    DailyEarnings,
    PublisherReportItem,
    UserAnalytics,
    WeeklyActivity
} from "./Analytics";
import { MediaLeaderboardItem, ReferralLeaderboardItem } from "./Leaderboard";
import {
    FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS,
    FETCH_MEDIA_LEADERBOARD,
    FETCH_RAW_24_HOUR_ANALYTICS_DATA,
    FETCH_RAW_ANALYTICS_DATA,
    FETCH_REFERRAL_LEADERBOARD,
    FETCH_USER_ANALYTICS,
    FETCH_WEEKLY_ACTIVITY,
    RAW_ANALYTICS_DATA_LOADED,
    SEND_EMAIL_REPORT,
    SET_CURRENT_DAILY_EARNINGS
} from "./actions";
import { CLEAR_MODAL } from "../modals/actions";
import { MODAL, ModalMedia } from "./actions";
import generateAnalyticsDataStore from "./generateAnalyticsDataStore";

export enum AnalyticsModal {
    clear,
    media,
    emailReport
}

export interface AnalyticsDataStore {
    [date: string]: PublisherReportItem[];
}

export interface AnalyticsState {
    weeklyActivity: WeeklyActivity;
    userAnalytics: UserAnalytics;
    weeklyActivityLoading: boolean;
    userAnalyticsLoading: boolean;
    dailyCustomLinkEarnings: CustomLinkEarnings[];
    customLinksLoading: boolean;
    referralLeaderboard: ReferralLeaderboardItem[];
    mediaLeaderboard: MediaLeaderboardItem[];
    analyticsDataStore: AnalyticsDataStore;
    analyticsDataStoreLoading: boolean;
    analyticsLiveDataStore: AnalyticsDataStore;
    emailDateRange: [string, string] | null;
    emailReportStatus: boolean | null;
    modal: AnalyticsModal;
    currentDailyEarnings: DailyEarnings | null;
    currentMedia: ModalMedia | null;
}

const initialAnalyticsState: AnalyticsState = {
    weeklyActivity: {
        weeklyActivity: {
            activity: null,
            last_week: null
        },
        allTimeActivity: {
            activity: 0
        },
        num_installs: 0,
        lastWeekBonus: 0
    },
    userAnalytics: {
        daily_earnings: [],
        weekly_earnings: [],
        alltime_referral_earnings: [],
        alltime_media_earnings: [],
        country_earnings: []
    },
    weeklyActivityLoading: true,
    userAnalyticsLoading: true,
    dailyCustomLinkEarnings: [],
    customLinksLoading: true,
    referralLeaderboard: [],
    mediaLeaderboard: [],
    analyticsDataStore: {},
    analyticsDataStoreLoading: true,
    analyticsLiveDataStore: {},
    emailDateRange: null,
    emailReportStatus: null,
    modal: AnalyticsModal.clear,
    currentDailyEarnings: null,
    currentMedia: null
};

const reducer = (
    state = initialAnalyticsState,
    action: Action
): AnalyticsState => {
    switch (action.type) {
        case FETCH_RAW_ANALYTICS_DATA.SUCCESS:
            const newDataStore = generateAnalyticsDataStore(
                action.payload.startDate,
                action.payload.endDate,
                action.payload.data
            );

            return {
                ...state,
                analyticsDataStore: {
                    ...state.analyticsDataStore,
                    ...newDataStore
                }
            };
        case RAW_ANALYTICS_DATA_LOADED:
            return { ...state, analyticsDataStoreLoading: false };
        case FETCH_RAW_24_HOUR_ANALYTICS_DATA.SUCCESS:
            return {
                ...state,
                analyticsLiveDataStore: {
                    "24-hours": action.payload
                }
            };
        case SEND_EMAIL_REPORT.REQUEST:
            return {
                ...state,
                emailReportStatus: null
            };
        case SEND_EMAIL_REPORT.SUCCESS:
            return {
                ...state,
                emailReportStatus: true
            };
        case SEND_EMAIL_REPORT.FAILURE:
            return {
                ...state,
                emailReportStatus: false
            };
        case FETCH_WEEKLY_ACTIVITY.SUCCESS:
            return {
                ...state,
                ...action.payload,
                weeklyActivityLoading: false
            };
        case FETCH_USER_ANALYTICS.SUCCESS:
            return { ...state, ...action.payload, userAnalyticsLoading: false };
        case FETCH_REFERRAL_LEADERBOARD.SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_MEDIA_LEADERBOARD.SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS.REQUEST:
            return { ...state, customLinksLoading: true };
        case FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS.SUCCESS:
            return { ...state, ...action.payload, customLinksLoading: false };
        case FETCH_DAILY_CAMPAIGN_LINK_ANALYTICS.FAILURE:
            return { ...state, ...action.payload, customLinksLoading: false };
        case SET_CURRENT_DAILY_EARNINGS:
            return { ...state, ...action.payload, dailyCustomLinkEarnings: [] };
        case MODAL.MEDIA:
            return {
                ...state,
                modal: AnalyticsModal.media,
                currentMedia: action.payload
            };
        case MODAL.EMAIL_REPORT:
            return {
                ...state,
                modal: AnalyticsModal.emailReport,
                ...action.payload
            };
        case CLEAR_MODAL:
            return {
                ...state,
                modal: AnalyticsModal.clear,
                currentMedia: null,
                emailReportStatus: null
            };
        default:
            return state;
    }
};

export default reducer;
