import { Action } from "src/types";
import { PlacementConfig } from "./reducer";

export const SET_PLACEMENT_CONFIG = "SET_PLACEMENT_CONFIG";

export const setPlacementConfig = (
    placementConfig: PlacementConfig
): Action => ({
    type: SET_PLACEMENT_CONFIG,
    payload: { placementConfig }
});
