import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { clearModal } from "./actions";
import { freezeBody, thawBody } from "../util";
import { Action } from "src/types";

const Parent = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2001;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Child = styled.div`
    position: relative;
    width: 75vw;
    min-width: 300px;
    max-width: 375px;
    padding: 56px 20px 20px;
    margin-top: 70px;
    border-radius: 15px;
    background-color: white;
`;

const Badge = styled.div`
    width: 143px;
    height: 120px;
    margin: 0 auto;
    position: absolute;
    top: -70px;
    right: 0;
    left: 0;
    overflow: hidden;
`;

const EmojiContainer = styled.div`
    width: 143px;
    height: 143px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 66px;
    background-color: white;
    border-radius: 100%;
`;

const Logo = styled.img`
    height: 66px;
    width: 66px;
`;

const CloseButton = styled.img`
    height: 16px;
    width: 16px;
    position: absolute;
    z-index: 2001;
    top: 45px;
    right: 45px;
    cursor: pointer;
`;

interface Props {
    emoji?: string | null;
    clearModal: () => void;
    localClearModal?: () => void;
    children: any;
    icon?: string;
    isDismissable?: boolean;
}

const EmojiModal = ({
    emoji,
    clearModal,
    children,
    icon,
    localClearModal,
    isDismissable
}: Props) => {
    const thawed = useRef<boolean>(false);
    useEffect(() => {
        freezeBody();

        return () => {
            !thawed.current && thawBody();
        };
    }, []);

    return (
        <>
            <Parent
                onClick={() => {
                    thawed.current = true;
                    if (localClearModal) {
                        localClearModal();
                    } else {
                        clearModal();
                    }
                    thawBody();
                }}
            >
                <Child onClick={e => e.stopPropagation()}>
                    <Badge>
                        <EmojiContainer>
                            {icon ? (
                                <Logo src={icon} />
                            ) : (
                                emoji || <Logo src="/logo.svg" />
                            )}
                        </EmojiContainer>
                    </Badge>
                    {children}
                </Child>
            </Parent>
            {isDismissable && (
                <CloseButton
                    src={"/white_close.svg"}
                    onClick={() => {
                        if (localClearModal) {
                            localClearModal();
                        } else {
                            clearModal();
                        }
                    }}
                />
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(null, mapDispatchToProps)(EmojiModal);
