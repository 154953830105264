import { Action } from "../../types";
import { CAMPAIGN_MODAL } from "../campaigns/actions";

export enum ModalView {
    main = "main",
    scriptEdit = "scriptEdit",
    addAsset = "addAsset",
    selectAsset = "selectAsset",
    addMedia = "addMedia",
    selectMedia = "selectMedia",
    error = "error",
    loading = "loading"
}

export const campaignScriptModal = (scriptId: number | null): Action => ({
    type: CAMPAIGN_MODAL.SCRIPT,
    payload: { scriptId }
});

export const setScriptModalView = (tag: string): Action => ({
    type: CAMPAIGN_MODAL.SCRIPT_VIEW,
    payload: tag
});

export const setWarning = (bool: boolean) => ({
    type: CAMPAIGN_MODAL.SCRIPT_WARNING,
    payload: bool
});
export const setScriptCreate = (bool: boolean) => ({
    type: CAMPAIGN_MODAL.SCRIPT_CREATE,
    payload: bool
});
