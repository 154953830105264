import { PaymentType } from "../../../types/Payment";
import { DirectBuyPayment } from "./types";

export const shortStringForDirectBuyPayment = (
    directBuyPayment: DirectBuyPayment
): string => {
    switch (directBuyPayment.payment.type) {
        case PaymentType.dollarsPerK:
            return `$${directBuyPayment.amount} per K`;
        case PaymentType.flatRate:
            return `$${directBuyPayment.amount}`;
    }
};

export const longStringForDirectBuyPayment = (
    directBuyPayment: DirectBuyPayment
): string => {
    switch (directBuyPayment.payment.type) {
        case PaymentType.dollarsPerK:
            return `$${directBuyPayment.amount} per K`;
        case PaymentType.flatRate:
            return `$${directBuyPayment.amount} Flat Fee`;
    }
};
