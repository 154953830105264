import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CustomLinkEarnings } from "./Analytics";
import { mediaModal, ModalMedia } from "./actions";
import { storage } from "../firebase/FirebaseConfig";
import { coinToDollars, formatNumber } from "../util";
import styled from "styled-components";
import AnalyticsIcon from "./AnalyticsIcon";
import { breakpoints, colors, maxWidthDesktop, fonts } from "../constants";

const Cell = styled.div`
    width: 100%;
    display: flex;
    transition: 0.1s ease-in;
    cursor: pointer;

    & + & {
        margin-top: 15px;
    }

    @media (pointer: fine) {
        padding: 6px;
        border: ${colors.mediumTeal} solid 1px;
        border-radius: 8px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
        }
    }
`;

const Description = styled.div`
    width: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Name = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MoneyContainer = styled.div`
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
`;

const Coins = styled.div`
    font-size: 20px;
    line-height: 24px;
`;

const Cash = styled.div`
    font-size: ${fonts.subtitle}px;
    line-height: 20px;
    color: ${colors.secondaryGray};
`;

interface Props {
    earnings: CustomLinkEarnings;
    mediaModal: (modalMedia: ModalMedia) => void;
}

const LinkEarningsCell = ({ earnings, mediaModal }: Props) => {
    const [mediaUrl, setMediaUrl] = useState("");
    const [coverUrl, setCoverUrl] = useState("");

    useEffect(() => {
        if (earnings.cover_photo && !coverUrl) {
            storage
                .ref(`/${earnings.link_url_prefix}/${earnings.cover_photo}`)
                .getDownloadURL()
                .then((url: string) => {
                    setCoverUrl(url);
                })
                .catch((error: string) => {
                    console.log("cover error");
                });
        }

        if (earnings.key && !mediaUrl) {
            storage
                .ref(`/${earnings.link_url_prefix}/${earnings.key}`)
                .getDownloadURL()
                .then((url: string) => {
                    setMediaUrl(url);
                })
                .catch((error: string) => {
                    console.log(error);
                });
        }
    }, [earnings]);

    function cellClicked() {
        if (mediaUrl) {
            mediaModal({ type: earnings.media_type, mediaUrl });
        }
    }

    function imageUrl() {
        if (mediaUrl && earnings.media_type === "photo") {
            return mediaUrl;
        } else if (coverUrl) {
            return coverUrl;
        } else {
            return "/other_profile/other_profile@2x.png";
        }
    }

    return (
        <Cell onClick={cellClicked}>
            <AnalyticsIcon mediaUrl={imageUrl()} />
            <Description>
                <Name>{earnings.media_name}</Name>
                <MoneyContainer>
                    <Coins>{formatNumber(earnings.plugcoins)}</Coins>
                    <Cash>{`$${formatNumber(
                        coinToDollars(earnings.plugcoins)
                    )}`}</Cash>
                </MoneyContainer>
            </Description>
        </Cell>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    mediaModal: (modalMedia: ModalMedia) => dispatch(mediaModal(modalMedia))
});

export default connect(null, mapDispatchToProps)(LinkEarningsCell);
