import { Action } from "src/types";
import { CampaignsModal } from "src/ui/modals/reducers";
import replaceFillOrderFilename from "../utils/replaceFillOrderFilename";
import {
    CHANGE_MEDIA_FOR_FILL_ORDER,
    CHECK_SHOUTOUT_HISTORY,
    FETCH_SHOUTOUTS,
    Shoutouts
} from "./actions";
import { Shoutout } from "./types";

export interface ShoutoutsEntitiesState extends Shoutouts {}

const shoutoutsInitialState: ShoutoutsEntitiesState = {
    userSpecificShoutouts: [] as Shoutout[],
    shoutoutsAvailableForAll: []
};

function shoutoutsEntitiesReducer(
    state = shoutoutsInitialState,
    action: Action
) {
    switch (action.type) {
        case FETCH_SHOUTOUTS.SUCCESS:
            return { ...action.payload.shoutouts };

        case CHANGE_MEDIA_FOR_FILL_ORDER:
            const userSpecificShoutouts = replaceFillOrderFilename(
                state.userSpecificShoutouts,
                action.payload.shoutout_id,
                action.payload.fill_order_id,
                action.payload.filename || null
            );
            const shoutoutsAvailableForAll = replaceFillOrderFilename(
                state.shoutoutsAvailableForAll,
                action.payload.shoutout_id,
                action.payload.fill_order_id,
                action.payload.filename || null
            );
            const currentShoutout =
                userSpecificShoutouts
                    .concat(shoutoutsAvailableForAll)
                    .find(shoutout => {
                        return shoutout.id === action.payload.shoutout_id;
                    }) || null;

            return {
                ...state,
                currentShoutout,
                shoutouts: { userSpecificShoutouts, shoutoutsAvailableForAll },
                modal: CampaignsModal.clear
            };

        default:
            return state;
    }
}

export default shoutoutsEntitiesReducer;
