import React from "react";
import styled from "styled-components";
import CampaignMediaTabs from "src/campaigns/landing/components/CampaignMediaTabs";
import CampaignHeader from "src/campaigns/landing/components/CampaignHeader";
import useInViewDispatch from "src/hooks/useInViewDispatch";
import { Campaign } from "src/campaigns/types";
import { colors, breakpoints } from "src/constants";
import { submitEvent } from "src/events/actions";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { useHistory } from "react-router";

const Main = styled("article")`
    width: 100%;
    text-align: left;
    background-color: ${colors.lightTeal};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;

    @media (max-width: ${breakpoints.mobile}px) {
        border: none;
        border-top: ${colors.mediumTeal} solid 1px;
        border-radius: 0;
    }
`;

interface Props {
    campaign: Campaign;
    rank: number;
}

const CampaignItem = (props: Props) => {
    const { campaign, rank } = props;

    const trackCampaignViewedRef = useInViewDispatch(
        submitEvent({
            event_name: "campaign_viewed",
            event_extra: { rank, campaignId: campaign.id },
            value: rank
        })
    );

    const profile = useSelector((state: GlobalState) => state.profile.profile);

    const history = useHistory();
    const redirectToRules = () => {
        if (!profile?.agreed_rules) {
            localStorage.setItem("newUser", "true");
            history.replace("/rules");
        }
    };

    return (
        <Main ref={trackCampaignViewedRef} onClick={redirectToRules}>
            <CampaignHeader campaign={campaign} rank={rank} />
            <CampaignMediaTabs campaign={campaign} />
        </Main>
    );
};

export default React.memo(CampaignItem);
