import { AnalyticsDataStore } from "./reducer";
import { PublisherReportItem } from "./Analytics";
import { makeDateArray } from "../util";
import moment from "moment";

export interface CampaignTotals {
    campaign: string;
    campaignName: string;
    campaignImageUrl: string;
    totalCoins: number;
    totalDollars: number;
}

export interface MediaTotals {
    media: string;
    mediaUrl: string | null;
    mediaCoverPhotoUrl: string | null;
    mediaType: "video" | "photo" | "other";
    mediaName: string | null;
    campaignName: string;
    totalCoins: number;
    totalDollars: number;
}

export interface CampaignByDate {
    campaign: string;
    campaignName: string;
    campaignImageUrl: string;
    totalCoins: number;
    totalDollars: number;
    dates: string[];
    coins: number[];
    dollars: number[];
    dateObject: {
        [date: string]: {
            coins: number;
            dollars: number;
        };
    };
}

export interface MediaByDate {
    media: string;
    mediaUrl: string | null;
    mediaCoverPhotoUrl: string | null;
    mediaType: "video" | "photo" | "other";
    mediaName: string | null;
    campaignName: string;
    totalCoins: number;
    totalDollars: number;
    dates: string[];
    coins: number[];
    dollars: number[];
    dateObject: {
        [date: string]: {
            coins: number;
            dollars: number;
        };
    };
}

class AnalyticsDataParser {
    private readonly dataStore: AnalyticsDataStore;

    constructor(dataStore: AnalyticsDataStore) {
        this.dataStore = dataStore;
    }

    // bufferDates(
    //     startDate: string,
    //     endDate: string
    // ): [[string, string] | [null, null], [string, string] | [null, null]] {
    //     let trailingStartDate: string | null = null;
    //     let trailingEndDate: string | null = null;
    //
    //     [1, 2, 3, 4, 5, 6, 7].forEach(i => {
    //         const currDate = moment(startDate)
    //             .subtract(i, "days")
    //             .format("YYYY-MM-DD");
    //
    //         if (!this.dataStore[currDate] && !trailingEndDate) {
    //             trailingStartDate = currDate;
    //             trailingEndDate = currDate;
    //         } else if (!this.dataStore[currDate]) {
    //             trailingStartDate = currDate;
    //         }
    //     });
    //
    //     let leadingStartDate: string | null = null;
    //     let leadingEndDate: string | null = null;
    //
    //     [1, 2, 3, 4, 5, 6, 7].forEach(i => {
    //         const currentDate = moment(endDate)
    //             .add(i, "days")
    //             .format("YYYY-MM-DD");
    //
    //         if (moment(currentDate).isSameOrBefore(moment(), "day")) {
    //             if (!this.dataStore[currentDate] && !leadingStartDate) {
    //                 leadingStartDate = currentDate;
    //                 leadingEndDate = currentDate;
    //             } else if (!this.dataStore[currentDate]) {
    //                 leadingEndDate = currentDate;
    //             }
    //         }
    //     });
    //
    //     return [
    //         [trailingStartDate, trailingEndDate],
    //         [leadingStartDate, leadingEndDate]
    //     ];
    // }

    missingDates(
        startDate: string,
        endDate: string
    ): [string, string] | [null, null] {
        const dateArray = makeDateArray(startDate, endDate);

        let missingStartDate: string | null = null;
        let missingEndDate: string | null = null;

        dateArray.forEach(currentDate => {
            if (moment(currentDate).isSameOrBefore(moment(), "day")) {
                if (!this.dataStore[currentDate] && !missingStartDate) {
                    missingStartDate = currentDate;
                    missingEndDate = currentDate;
                } else if (!this.dataStore[currentDate]) {
                    missingEndDate = currentDate;
                }
            }
        });

        return [missingStartDate, missingEndDate];
    }

    private addToCampaignTotals(
        campaignsObject: { [campaign: string]: CampaignTotals },
        dataArray: PublisherReportItem[]
    ) {
        for (let i = 0; i < dataArray.length; i++) {
            const dataRow = dataArray[i];
            const campaign = dataRow.campaign;
            const campaignName = dataRow.campaign_name;
            const campaignImageUrl = dataRow.campaign_image_url;
            const coins = dataRow.coins;
            const dollars = dataRow.dollars;

            if (campaignsObject[campaign]) {
                campaignsObject[campaign].totalCoins += coins;
                campaignsObject[campaign].totalDollars += dollars;
            } else {
                campaignsObject[campaign] = {
                    campaign,
                    campaignName,
                    campaignImageUrl,
                    totalCoins: coins,
                    totalDollars: dollars
                };
            }
        }
    }

    generateCampaignTotals(
        startDate: string,
        endDate: string
    ): CampaignTotals[] {
        const campaignsObject: { [campaign: string]: CampaignTotals } = {};

        for (let date in this.dataStore) {
            if (
                !moment(date).isBetween(
                    moment(startDate),
                    moment(endDate),
                    undefined,
                    "[]"
                )
            ) {
                continue;
            }

            const dataForDate = this.dataStore[date];

            this.addToCampaignTotals(campaignsObject, dataForDate);
        }

        return Object.values(campaignsObject).sort(
            (c1, c2) => c2.totalCoins - c1.totalCoins
        );
    }

    generateCampaignTotals24Hours(): CampaignTotals[] | null {
        const data = this.dataStore["24-hours"];
        if (data) {
            const campaignsObject: { [campaign: string]: CampaignTotals } = {};

            this.addToCampaignTotals(campaignsObject, data);

            return Object.values(campaignsObject).sort(
                (c1, c2) => c2.totalCoins - c1.totalCoins
            );
        } else {
            return null;
        }
    }

    private addToMediaTotals(
        mediaObject: { [media: string]: MediaTotals },
        dataArray: PublisherReportItem[]
    ) {
        for (let i = 0; i < dataArray.length; i++) {
            const dataRow = dataArray[i];
            const media = dataRow.media || "0";
            const mediaUrl = dataRow.media_url;
            const mediaCoverPhotoUrl = dataRow.media_cover_photo_url;
            const mediaType = dataRow.media_type;
            const mediaName = dataRow.media_name;
            const campaignName = dataRow.campaign_name;
            const coins = dataRow.coins;
            const dollars = dataRow.dollars;

            if (mediaObject[media]) {
                mediaObject[media].totalCoins += coins;
                mediaObject[media].totalDollars += dollars;
            } else {
                mediaObject[media] = {
                    media,
                    mediaUrl,
                    mediaCoverPhotoUrl,
                    mediaType,
                    mediaName,
                    campaignName,
                    totalCoins: coins,
                    totalDollars: dollars
                };
            }
        }
    }

    generateMediaTotals(startDate: string, endDate: string): MediaTotals[] {
        const mediaObject: { [media: string]: MediaTotals } = {};

        for (let date in this.dataStore) {
            if (
                !moment(date).isBetween(
                    moment(startDate),
                    moment(endDate),
                    undefined,
                    "[]"
                )
            ) {
                continue;
            }

            const dataForDate = this.dataStore[date];

            this.addToMediaTotals(mediaObject, dataForDate);
        }

        return Object.values(mediaObject).sort(
            (m1, m2) => m2.totalCoins - m1.totalCoins
        );
    }

    generateMediaTotals24Hours(): MediaTotals[] | null {
        const data = this.dataStore["24-hours"];
        if (data) {
            const mediaObject: { [media: string]: MediaTotals } = {};

            this.addToMediaTotals(mediaObject, data);

            return Object.values(mediaObject).sort(
                (m1, m2) => m2.totalCoins - m1.totalCoins
            );
        } else {
            return null;
        }
    }

    generateDayCampaignTotals(
        startDate: string,
        endDate: string
    ): CampaignByDate[] {
        const dateArray = makeDateArray(startDate, endDate);

        const campaignsObject: { [campaign: string]: CampaignByDate } = {};

        for (let date in this.dataStore) {
            if (
                !moment(date).isBetween(
                    moment(startDate),
                    moment(endDate),
                    undefined,
                    "[]"
                )
            ) {
                continue;
            }

            const dataForDate = this.dataStore[date];

            for (let i = 0; i < dataForDate.length; i++) {
                const dataRow = dataForDate[i];
                const campaign = dataRow.campaign;
                const campaignName = dataRow.campaign_name;
                const campaignImageUrl = dataRow.campaign_image_url;
                const coins = dataRow.coins;
                const dollars = dataRow.dollars;

                if (campaignsObject[campaign]) {
                    campaignsObject[campaign].totalCoins += coins;
                    campaignsObject[campaign].totalDollars += dollars;
                    if (campaignsObject[campaign].dateObject[date]) {
                        campaignsObject[campaign].dateObject[
                            date
                        ].coins += coins;
                        campaignsObject[campaign].dateObject[
                            date
                        ].dollars += dollars;
                    } else {
                        campaignsObject[campaign].dateObject[date] = {
                            coins,
                            dollars
                        };
                    }
                } else {
                    campaignsObject[campaign] = {
                        campaign,
                        campaignName,
                        campaignImageUrl,
                        dates: dateArray,
                        coins: [],
                        dollars: [],
                        totalCoins: coins,
                        totalDollars: dollars,
                        dateObject: {
                            [date]: {
                                coins,
                                dollars
                            }
                        }
                    };
                }
            }
        }

        return Object.values(campaignsObject)
            .map(campaign => {
                campaign.dates.forEach(date => {
                    campaign.coins.push(
                        campaign.dateObject[date]
                            ? campaign.dateObject[date].coins
                            : 0
                    );
                    campaign.dollars.push(
                        campaign.dateObject[date]
                            ? campaign.dateObject[date].dollars
                            : 0
                    );
                });
                return campaign;
            })
            .sort((c1, c2) => c2.totalCoins - c1.totalCoins);
    }

    generateDayMediaTotals(startDate: string, endDate: string): MediaByDate[] {
        const dateArray = makeDateArray(startDate, endDate);

        const mediaObject: { [media: string]: MediaByDate } = {};

        for (let date in this.dataStore) {
            if (
                !moment(date).isBetween(
                    moment(startDate),
                    moment(endDate),
                    undefined,
                    "[]"
                )
            ) {
                continue;
            }

            const dataForDate = this.dataStore[date];

            for (let i = 0; i < dataForDate.length; i++) {
                const dataRow = dataForDate[i];
                const media = dataRow.media || "0";
                const mediaUrl = dataRow.media_url;
                const mediaCoverPhotoUrl = dataRow.media_cover_photo_url;
                const mediaType = dataRow.media_type;
                const mediaName = dataRow.media_name;
                const campaignName = dataRow.campaign_name;
                const coins = dataRow.coins;
                const dollars = dataRow.dollars;

                if (mediaObject[media]) {
                    mediaObject[media].totalCoins += coins;
                    mediaObject[media].totalDollars += dollars;
                    if (mediaObject[media].dateObject[date]) {
                        mediaObject[media].dateObject[date].coins += coins;
                        mediaObject[media].dateObject[date].dollars += dollars;
                    } else {
                        mediaObject[media].dateObject[date] = {
                            coins,
                            dollars
                        };
                    }
                } else {
                    mediaObject[media] = {
                        media,
                        mediaUrl,
                        mediaCoverPhotoUrl,
                        mediaType,
                        mediaName,
                        campaignName,
                        dates: dateArray,
                        coins: [],
                        dollars: [],
                        totalCoins: coins,
                        totalDollars: dollars,
                        dateObject: {
                            [date]: {
                                coins,
                                dollars
                            }
                        }
                    };
                }
            }
        }

        return Object.values(mediaObject)
            .map(media => {
                media.dates.forEach(date => {
                    media.coins.push(
                        media.dateObject[date]
                            ? media.dateObject[date].coins
                            : 0
                    );
                    media.dollars.push(
                        media.dateObject[date]
                            ? media.dateObject[date].dollars
                            : 0
                    );
                });
                return media;
            })
            .sort((m1, m2) => m2.totalCoins - m1.totalCoins);
    }
}

export default AnalyticsDataParser;
