import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { get, patch } from "src/Api";
import { BlueButton } from "src/profile/components/Buttons";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { colors } from "src/constants";

interface Props {
    openBuyId: number | null;
}

const Container = styled.div`
    width: 100%;
`;

const NotesInput = styled(TextareaAutosize)`
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-size: 14px;
    margin-bottom: 10px;
`;

const DateInput = styled.input`
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-size: 14px;
    margin-bottom: 10px;
`;

const SubmitButton = styled(BlueButton)`
    margin-left: auto;
    padding: 8px 12px;
    border-radius: 6px;
    margin-bottom: 10px;
`;

const InputLabel = styled.label`
    display: block;
    color: ${colors.grayBlack};
    font-size: 14px;
`;

export default function ChallengeInternalDetails({ openBuyId }: Props) {
    const dispatch = useDispatch();
    const [internalNotes, setInternalNotes] = useState<string>("");
    const [targetLaunchDate, setTargetLaunchDate] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    let dateInputValue = "";
    if (targetLaunchDate) {
        dateInputValue = new Date(targetLaunchDate).toISOString().slice(0, 10);
    }

    const fetchDetails = async (openBuyId: number) => {
        const response = await get(
            `/v1/openBuy/${openBuyId}/internalDetails`,
            {}
        );
        setInternalNotes(response.data.data.internalNotes);
        setTargetLaunchDate(response.data.data.targetLaunchDate);
        setLoading(false);
    };

    const saveDetails = async () => {
        if (!openBuyId) return;
        setLoading(true);
        try {
            let result = await patch(
                `/v1/openBuy/${openBuyId}/internalDetails`,
                {
                    ...(internalNotes && { internalNotes }),
                    ...(targetLaunchDate && { targetLaunchDate })
                }
            );
            if (result.status >= 400) {
                dispatch(
                    setPillNotificationText(
                        "Error saving internal details",
                        "danger"
                    )
                );
            } else {
                dispatch(
                    setPillNotificationText("Internal details saved", "success")
                );
            }
            await fetchDetails(openBuyId);
        } catch (error) {
            dispatch(
                setPillNotificationText(
                    "Error saving internal details",
                    "danger"
                )
            );
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (openBuyId) {
            fetchDetails(openBuyId);
        }
    }, [openBuyId]);
    return (
        <Container>
            <InputLabel htmlFor="target-launch-date-input">
                TARGET LAUNCH DATE
            </InputLabel>
            <DateInput
                type="date"
                placeholder="Target Launch Date"
                value={dateInputValue}
                disabled={loading}
                onChange={e => {
                    setTargetLaunchDate(e.target.value);
                }}
                id="target-launch-date-input"
            />
            <InputLabel htmlFor="internal-notes-input">
                INTERNAL NOTES
            </InputLabel>
            <NotesInput
                placeholder="Internal Notes"
                value={internalNotes}
                disabled={loading}
                onChange={e => setInternalNotes(e.target.value)}
                minRows={3}
                maxRows={10}
                id="internal-notes-input"
            />

            <SubmitButton onClick={saveDetails} shimmer={loading}>
                Save Notes and Target Launch Date
            </SubmitButton>
        </Container>
    );
}
