import { normalize } from "normalizr";
import { Action, ResponseData } from "../types";
import { deleteReq, get, post } from "../Api";
import {
    all,
    call,
    put,
    takeEvery,
    takeLatest,
    select,
    cancelled,
    CancelledEffect
} from "redux-saga/effects";
import {
    UPLOAD_CUSTOM_MEDIA,
    uploadCustomMediaFailure,
    FETCH_SEARCH_MEDIA,
    fetchSearchMediaSuccess,
    FETCH_MEDIA,
    fetchMediaSuccess,
    UPDATE_MEDIA,
    updateMediaSuccess,
    DELETE_MEDIA,
    deleteMediaSuccess
} from "./actions";
import { media, mediaList } from "src/campaigns/schema";
import { GlobalState } from "src/reducers";
import { byIdSchemaList } from "./schema";
import { addMediaStatusNotification } from "src/notifications/actions";
import { parseMediaFilters, parseMediaSort } from "./util";
import {
    getPlacementDictionaryByType,
    getPlacementIdsFromConfig
} from "src/campaigns/placements/selectors";
import axios from "axios";
import getPlacementIds from "src/campaigns/placements/utils/getPlacementIdsFromConfig";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { selectAdminStatus } from "src/auth/selectors";

function* uploadCustomMediaRequest({ payload }: Action) {
    try {
        const dictionaryByType: { [type: string]: number } = yield select(
            getPlacementDictionaryByType
        );

        const { data, status } = yield call(
            post,
            "/api/upload_user_submitted_media2",
            {
                ...payload,
                placements: getPlacementIds(
                    payload.placements,
                    dictionaryByType
                ),
                userid: localStorage.getItem("userid")
            }
        );

        if (status === 200) {
            let medias = JSON.parse(localStorage.getItem("mediaCache") || "{}");
            let notifications = JSON.parse(
                localStorage.getItem("mediaNotifications") || "[]"
            );

            medias[data.id] = {
                id: data.id,
                status: data.status,
                campaign: data.campaign_id
            };
            notifications.push({
                id: data.id,
                status: data.status,
                campaign: data.campaign_id
            });

            localStorage.setItem("mediaCache", JSON.stringify(medias));
            localStorage.setItem(
                "mediaNotifcations",
                JSON.stringify(notifications)
            );
            yield put(
                addMediaStatusNotification({ type: "pending", media: data })
            );
        } else {
            window.alert("Something went wrong. Please Refresh.");
            yield put(uploadCustomMediaFailure("Upload media failure"));
        }
    } catch (error) {
        console.log(error);
        window.alert("Something went wrong. Please Refresh.");
        yield put(uploadCustomMediaFailure("Upload media failure"));
    }
}

function* fetchSearchMediaRequest() {
    const cancelTokenSource = axios.CancelToken.source();
    try {
        const { currentPage, filters, sortBy, searchString } = yield select(
            (state: GlobalState) => state.pagination.medias
        );
        const placementIds: number[] = yield select(
            getPlacementIdsFromConfig,
            null
        );
        const isAdmin: boolean = yield select(selectAdminStatus);

        const { data } = yield call(
            get,
            "/v1/media/search",
            {
                page: currentPage,
                limit: 10,
                ...parseMediaFilters(filters),
                sort: parseMediaSort(sortBy),
                placementId: placementIds.join(",") || null,
                query: parseInt(searchString) || null,
                ...(isAdmin && { ...filters.admin })
            },
            cancelTokenSource.token
        );

        yield put(
            fetchSearchMediaSuccess(
                normalize(data.data, mediaList),
                currentPage
            )
        );
    } catch (error) {
        console.log(error);
    } finally {
        const isCancelled: CancelledEffect = yield cancelled();
        if (isCancelled) {
            yield call(cancelTokenSource.cancel);
        }
    }
}

function* fetchMedia() {
    try {
        const endpoint = "/v1/media/search";
        const response: ResponseData = yield call(get, endpoint, {});

        const medias = response.data.data;

        const normalizedMedias = normalize(medias, byIdSchemaList);

        yield put(fetchMediaSuccess(normalizedMedias));
    } catch (error) {
        console.log(error);
    }
}

function* updateMedia({ payload }: Action) {
    try {
        const { placementIds } = yield select(
            (state: GlobalState) => state.entities.medias[payload.media.id]
        );
        const havePlacementsChanged =
            JSON.stringify(placementIds.sort()) !==
            JSON.stringify(payload.media.placementIds.sort());

        const { data } = yield call(post, `/v1/media/${payload.media.id}`, {
            ...payload.media,
            updatePlacements: havePlacementsChanged
        });
        yield put(
            updateMediaSuccess(
                normalize(
                    { ...data.data, placementIds: payload.media.placementIds },
                    media
                )
            )
        );
        yield put(
            setPillNotificationText(payload.notification, "success", 2500)
        );
    } catch (e) {
        yield put(
            setPillNotificationText("Something went wrong", "danger", 2500)
        );
    }
}

function* deleteMedia({ payload }: Action) {
    try {
        const { data } = yield call(
            deleteReq,
            `/v1/media/${payload.mediaId}`,
            {}
        );
        yield put(
            setPillNotificationText(
                `Media: ${payload.mediaId} deleted succesfully!`,
                "success",
                2500
            )
        );
        yield put(deleteMediaSuccess(payload.mediaId, payload.campaignId));
    } catch (e) {
        yield put(
            setPillNotificationText("Something went wrong", "danger", 2500)
        );
    }
}

export default function* mediaSaga() {
    yield all([
        takeEvery(UPLOAD_CUSTOM_MEDIA.REQUEST, uploadCustomMediaRequest),
        takeLatest(FETCH_SEARCH_MEDIA.REQUEST, fetchSearchMediaRequest),
        takeEvery(FETCH_MEDIA.REQUEST, fetchMedia),
        takeEvery(UPDATE_MEDIA.REQUEST, updateMedia),
        takeEvery(DELETE_MEDIA.REQUEST, deleteMedia)
    ]);
}
