import React from "react";
import styled from "styled-components";

const Icon = styled.img`
    margin-left: auto;
    cursor: pointer;
    user-select: none;
    box-shadow: 0px 0px 5px grey;
    border-radius: 5px;
    height: 24px;
    width: 24px;
`;

interface Props {
    selected: boolean;
    style?: any;
    className?: string;
    onCheck?: () => void;
    onUncheck?: () => void;
}

const CheckBox = ({
    selected,
    className,
    style,
    onCheck,
    onUncheck
}: Props) => {
    if (selected)
        return (
            <Icon
                style={style}
                className={className}
                src="/checkbox_selected.svg"
                onClick={onUncheck}
            />
        );

    return (
        <Icon
            style={style}
            className={className}
            src="/checkbox_unselected.svg"
            onClick={onCheck}
        />
    );
};

export default CheckBox;
