import { Action } from "src/types";
import { InstructionPayment, InstructionStep } from "./types";

// Fetch All Instruction Steps  ---------------------------------------------------------------

export const FETCH_INSTRUCTION_STEPS = {
    REQUEST: "FETCH_INSTRUCTION_STEPS_REQUEST",
    SUCCESS: "FETCH_INSTRUCTION_STEPS_SUCCESS",
    FAILURE: "FETCH_INSTRUCTION_STEPS_FAILURE"
};

export function fetchInstructionSteps(
    instructionsSetId: number,
    includePayments: boolean
): Action {
    return {
        type: FETCH_INSTRUCTION_STEPS.REQUEST,
        payload: { instructionsSetId, includePayments }
    };
}

export interface NormalizedInstructionStepsResponse {
    [id: number]: InstructionStep;
}

export function fetchInstructionStepsSuccess(
    instructionSteps: NormalizedInstructionStepsResponse
): Action {
    return {
        type: FETCH_INSTRUCTION_STEPS.SUCCESS,
        payload: instructionSteps
    };
}

export function fetchInstructionStepsFailure(error: string): Action {
    return {
        type: FETCH_INSTRUCTION_STEPS.FAILURE,
        payload: error
    };
}

// Fetch An Instruction Step ---------------------------------------------------------------

export const FETCH_INSTRUCTION_STEP = {
    REQUEST: "FETCH_INSTRUCTION_STEP_REQUEST",
    SUCCESS: "FETCH_INSTRUCTION_STEP_SUCCESS",
    FAILURE: "FETCH_INSTRUCTION_STEP_FAILURE"
};

export function fetchInstructionStep(
    instructionStepId: number,
    includePayments: boolean
): Action {
    return {
        type: FETCH_INSTRUCTION_STEP.REQUEST,
        payload: { instructionStepId, includePayments }
    };
}

export function fetchInstructionStepSuccess(
    instructionStep: InstructionStep
): Action {
    return {
        type: FETCH_INSTRUCTION_STEP.SUCCESS,
        payload: instructionStep
    };
}

export function fetchInstructionStepFailure(error: string): Action {
    return {
        type: FETCH_INSTRUCTION_STEP.FAILURE,
        payload: error
    };
}

// Create An Instruction Step ---------------------------------------------------------------

export const CREATE_INSTRUCTION_STEP = {
    REQUEST: "CREATE_INSTRUCTION_STEP_REQUEST",
    SUCCESS: "CREATE_INSTRUCTION_STEP_SUCCESS",
    FAILURE: "CREATE_INSTRUCTION_STEP_FAILURE"
};

interface InstructionStepData {
    position?: number;
    description?: string;
    mediaId?: number;
    placementId?: number;
    scriptId?: number;
    captionId?: number;
    assetId?: number;
    linkUrl?: string;
    duration?: number;
    permanent?: boolean;
    viewsRequested?: number;
    requiresPlugLink?: boolean;
    submissionLimit?: number;
    submissionLimitPerAccount?: number;
    requiresSocialAccount?: boolean;
    requiresCustomMedia?: boolean;
    requiresRawMedia?: boolean;
    requiresScreenshot?: boolean;
    requiresLinkSubmission?: boolean;
    requiresTextSubmission?: boolean;
    textSubmissionPlaceholder?: string;
    paymentConjunction?: "AND" | "OR";
    shouldPayout?: boolean;
}

export interface InstructionStepCreateData extends InstructionStepData {
    instructionSetId: number;
    instructionStepId?: number;
    instructionSteps?: InstructionStep[];
    instructionPayments?: InstructionPayment[];
}

export function createInstructionStep(
    createData: Partial<InstructionStep>,
    instructionIndex?: number
): Action {
    return {
        type: CREATE_INSTRUCTION_STEP.REQUEST,
        payload: { createData, instructionIndex }
    };
}

export function createInstructionStepSuccess(
    newInstructionStepId: number,
    instructionIndex?: number
): Action {
    return {
        type: CREATE_INSTRUCTION_STEP.SUCCESS,
        payload: { newInstructionStepId, instructionIndex }
    };
}

export function createInstructionStepFailure(error: string): Action {
    return {
        type: CREATE_INSTRUCTION_STEP.FAILURE,
        payload: error
    };
}

// Update An Instruction Step ---------------------------------------------------------------

export const UPDATE_INSTRUCTION_STEP = {
    REQUEST: "UPDATE_INSTRUCTION_STEP_REQUEST",
    SUCCESS: "UPDATE_INSTRUCTION_STEP_SUCCESS",
    FAILURE: "UPDATE_INSTRUCTION_STEP_FAILURE"
};

export interface InstructionStepUpdateData extends InstructionStepData {
    id: number;
}

export function updateInstructionStep(
    instructionStepId: number,
    updateData: Partial<InstructionStep>
): Action {
    return {
        type: UPDATE_INSTRUCTION_STEP.REQUEST,
        payload: {
            instructionStepId,
            updateData
        }
    };
}

export function updateInstructionStepSuccess(
    instructionStepId: number,
    updatedData: Partial<InstructionStep>
): Action {
    return {
        type: UPDATE_INSTRUCTION_STEP.SUCCESS,
        payload: { instructionStepId, updatedData }
    };
}

export function updateInstructionStepFailure(error: string): Action {
    return {
        type: UPDATE_INSTRUCTION_STEP.FAILURE,
        payload: error
    };
}

// Delete An Instruction Step ---------------------------------------------------------------

export const DELETE_INSTRUCTION_STEP = {
    REQUEST: "DELETE_INSTRUCTION_STEP_REQUEST",
    SUCCESS: "DELETE_INSTRUCTION_STEP_SUCCESS",
    FAILURE: "DELETE_INSTRUCTION_STEP_FAILURE"
};

export function deleteInstructionStep(instructionStepId: number): Action {
    return {
        type: DELETE_INSTRUCTION_STEP.REQUEST,
        payload: instructionStepId
    };
}

export function deleteInstructionStepFailure(error: string): Action {
    return {
        type: DELETE_INSTRUCTION_STEP.FAILURE,
        payload: error
    };
}

// Fetch an Instruction Step's Payment ---------------------------------------------------------------

export const FETCH_INSTRUCTION_STEP_PAYMENT = {
    REQUEST: "FETCH_INSTRUCTION_STEP_PAYMENT_REQUEST",
    SUCCESS: "FETCH_INSTRUCTION_STEP_PAYMENT_SUCCESS",
    FAILURE: "FETCH_INSTRUCTION_STEP_PAYMENT_FAILURE"
};

export const fetchInstructionStepPayment = (
    instructionStepId: number
): Action => ({
    type: FETCH_INSTRUCTION_STEP_PAYMENT.REQUEST,
    payload: { instructionStepId }
});

export const fetchInstructionStepPaymentSuccess = (
    instructionStepPayment: InstructionPayment
): Action => ({
    type: FETCH_INSTRUCTION_STEP_PAYMENT.SUCCESS,
    payload: { instructionStepPayment }
});

export const fetchInstructionStepPaymentFailure = (error: string): Action => ({
    type: FETCH_INSTRUCTION_STEP_PAYMENT.FAILURE,
    payload: { error }
});

// Create an Instruction Step Payment ---------------------------------------------------------------

export const CREATE_INSTRUCTION_STEP_PAYMENT = {
    REQUEST: "CREATE_INSTRUCTION_STEP_PAYMENT_REQUEST",
    SUCCESS: "CREATE_INSTRUCTION_STEP_PAYMENT_SUCCESS",
    FAILURE: "CREATE_INSTRUCTION_STEP_PAYMENT_FAILURE"
};

export interface NewInstructionPayment {
    instructionStepId: number | null;
    paymentId: number | null;
    amount: number;
    viewsMinimum: number | null;
}

export const createInstructionStepPayment = (
    instructionPayment: NewInstructionPayment
): Action => ({
    type: CREATE_INSTRUCTION_STEP_PAYMENT.REQUEST,
    payload: { instructionPayment }
});

export const createInstructionStepPaymentSuccess = (
    instructionStepPayment: InstructionPayment
): Action => ({
    type: CREATE_INSTRUCTION_STEP_PAYMENT.SUCCESS,
    payload: { instructionStepPayment }
});

export const createInstructionStepPaymentFailure = (error: string): Action => ({
    type: CREATE_INSTRUCTION_STEP_PAYMENT.FAILURE,
    payload: { error }
});

// Update an Instruction Step Payment ---------------------------------------------------------------

export const UPDATE_INSTRUCTION_STEP_PAYMENT = {
    REQUEST: "UPDATE_INSTRUCTION_STEP_PAYMENT_REQUEST",
    SUCCESS: "UPDATE_INSTRUCTION_STEP_PAYMENT_SUCCESS",
    FAILURE: "UPDATE_INSTRUCTION_STEP_PAYMENT_FAILURE"
};

export const updateInstructionStepPayment = (
    instructionPaymentId: number,
    instructionPayment: {
        paymentId?: number;
        amount?: number;
        viewsMinimum?: number;
    }
): Action => ({
    type: UPDATE_INSTRUCTION_STEP_PAYMENT.REQUEST,
    payload: { instructionPaymentId, instructionPayment }
});

export const updateInstructionStepPaymentSuccess = (
    instructionStepPayment: InstructionPayment
): Action => ({
    type: UPDATE_INSTRUCTION_STEP_PAYMENT.SUCCESS,
    payload: { instructionStepPayment }
});

export const updateInstructionStepPaymentFailure = (error: string): Action => ({
    type: UPDATE_INSTRUCTION_STEP_PAYMENT.FAILURE,
    payload: { error }
});

// Delete an Instruction Step Payment ---------------------------------------------------------------

export const DELETE_INSTRUCTION_STEP_PAYMENT = {
    REQUEST: "DELETE_INSTRUCTION_STEP_PAYMENT_REQUEST",
    SUCCESS: "DELETE_INSTRUCTION_STEP_PAYMENT_SUCCESS",
    FAILURE: "DELETE_INSTRUCTION_STEP_PAYMENT_FAILURE"
};

export const deleteInstructionStepPayment = (
    stepId: number,
    instructionPaymentId: number
): Action => ({
    type: DELETE_INSTRUCTION_STEP_PAYMENT.REQUEST,
    payload: { stepId, instructionPaymentId }
});

export const deleteInstructionStepPaymentSuccess = (
    stepId: number,
    instructionPaymentId: number
): Action => ({
    type: DELETE_INSTRUCTION_STEP_PAYMENT.SUCCESS,
    payload: { stepId, instructionPaymentId }
});

export const deleteInstructionStepPaymentFailure = (error: string): Action => ({
    type: DELETE_INSTRUCTION_STEP_PAYMENT.FAILURE,
    payload: { error }
});
