import { useEffect, useState } from "react";
import { TableRow } from "../types";

interface FilterChoice {
    valueToFilterBy: string | number[];
    displayValue: string;
    defaultActive?: boolean;
}

export interface FilterOption {
    coreKey: string;
    type: "number" | "string";
    custom?: boolean;
    choices: FilterChoice[];
    inlineDisplayValue: string;
    unique?: boolean;
}

export type CurrentFilter =
    | {
          type: "number";
          filters: {
              [key: number]: {
                  valueToFilterBy: number[];
                  displayValue: string;
              };
          };
          selectedIndexes: number[];
          inlineDisplayValue: string;
          displayValue: string;
          unique?: boolean;
          isCustomRangeActive: boolean;
          customRange?: [string, string];
      }
    | {
          type: "string";
          filters: {
              [key: number]: {
                  valueToFilterBy: string;
                  displayValue: string;
              };
          };
          selectedIndexes: number[];
          inlineDisplayValue: string;
          displayValue: string;
          unique?: boolean;
          isCustomRangeActive: boolean;
          customRange?: [string, string];
      };
export interface CurrentFilters {
    [key: string]: CurrentFilter;
}
export type FilterSelector = (
    choiceIdx: number,
    currentFilterKey: string,
    currentFilter: CurrentFilter
) => void;
const checkNumberIsBetweenCustomRange = (range: number[], value: number) => {
    return range[0] && range[1]
        ? value >= range[0] && value <= range[1]
        : range[0]
        ? value >= range[0]
        : value <= range[1];
};

const checkNumberIsBetweenRanges = (
    ranges: number[][],
    value: number,
    custom?: number[]
): boolean => {
    const passRegularFilters =
        ranges.length > 0
            ? ranges.filter((range: number[]) => {
                  if (range.length === 2) {
                      return value >= range[0] && value <= range[1];
                  } else {
                      return value >= range[0];
                  }
              }).length > 0
            : true;
    const passCustomFilter = custom
        ? checkNumberIsBetweenCustomRange(custom, value)
        : true;
    return custom && ranges.length > 0
        ? passRegularFilters || passCustomFilter
        : custom
        ? passCustomFilter
        : passRegularFilters;
};

const checkString = (strings: string[], value: string) => {
    return strings.includes(value);
};

export interface ActiveFilters {
    [key: string]: {
        customRange?: [string, string];
        selectedFilters: string[] | [number, number][];
    };
}

export interface FiltersReference {
    currentFilters: CurrentFilters;
    setCurrentFilters: React.Dispatch<
        React.SetStateAction<CurrentFilters | null>
    >;
    filterData: (data: any[]) => any[];
    handleSelect: FilterSelector;
    handleCustomRange: (filterKey: string, range: [string, string]) => void;
    resetFilter: (key: string) => void;
    areAnyFiltersActive: boolean;
}

const useFilters = (
    filterOptions: FilterOption[] | null
): FiltersReference | null => {
    const filterMap: CurrentFilters | null = filterOptions
        ? filterOptions.reduce((acc, val, idx) => {
              return {
                  ...acc,
                  [val.coreKey]: {
                      type: val.type,
                      filters: val.choices.reduce(
                          (acc, choice, idx) => ({
                              ...acc,
                              [idx]: {
                                  valueToFilterBy: choice.valueToFilterBy,
                                  displayValue: choice.displayValue
                              }
                          }),
                          {}
                      ),
                      selectedIndexes: val.choices.reduce(
                          (acc: number[], val: FilterChoice, idx: number) => {
                              if (val.defaultActive) {
                                  return [...acc, idx];
                              }
                              return acc;
                          },
                          []
                      ),
                      inlineDisplayValue: val.inlineDisplayValue,
                      unique: val.unique,
                      isCustomRangeActive: false,
                      customRange: val.custom &&
                          val.type === "number" && ["", ""]
                  }
              };
          }, {})
        : null;

    const [currentFilters, setCurrentFilters] = useState<CurrentFilters | null>(
        filterMap
    );

    if (!filterOptions || !currentFilters) return null;

    const filterData = (data: TableRow[]) => {
        const newData = data.filter((dataObj, idx) => {
            const shouldNotBeFiltered = Object.entries(currentFilters).reduce(
                (acc, [filterKey, filterValue]) => {
                    if (
                        !dataObj.rowData[filterKey] &&
                        (filterValue.selectedIndexes.length > 0 ||
                            filterValue.isCustomRangeActive)
                    ) {
                        return false;
                    }
                    if (
                        acc &&
                        dataObj.rowData[filterKey] &&
                        (filterValue.selectedIndexes.length > 0 ||
                            filterValue.isCustomRangeActive)
                    ) {
                        const cell = dataObj.rowData[filterKey];
                        if (cell) {
                            if (
                                filterValue.type === "number" &&
                                typeof cell.data === "number"
                            ) {
                                const valuesToFilterBy = filterValue.selectedIndexes.map(
                                    selectedIdx =>
                                        filterValue.filters[selectedIdx]
                                            .valueToFilterBy
                                );

                                const customNumRange = filterValue.isCustomRangeActive
                                    ? filterValue.customRange?.reduce(
                                          (acc: number[], rangeVal) => {
                                              const num = parseFloat(rangeVal);

                                              if (num) {
                                                  return [...acc, num];
                                              } else {
                                                  return [...acc, 0];
                                              }
                                          },
                                          []
                                      )
                                    : undefined;
                                return checkNumberIsBetweenRanges(
                                    valuesToFilterBy,
                                    cell.data,
                                    customNumRange && [
                                        customNumRange[0],
                                        customNumRange[1]
                                    ]
                                );
                            }
                            if (
                                filterValue.type === "string" &&
                                typeof cell.data === "string"
                            ) {
                                const valuesToFilterBy = filterValue.selectedIndexes.map(
                                    selectedIdx =>
                                        filterValue.filters[selectedIdx]
                                            .valueToFilterBy
                                );
                                return checkString(valuesToFilterBy, cell.data);
                            }
                        }
                    }
                    return acc;
                },
                true
            );

            return shouldNotBeFiltered;
        });
        return newData;
    };
    const handleSelect = (
        choiceIdx: number,
        currentFilterKey: string,
        currentFilter: CurrentFilter
    ) => {
        if (
            currentFilter.type === "number" ||
            currentFilter.type === "string"
        ) {
            if (currentFilter.selectedIndexes.includes(choiceIdx)) {
                setCurrentFilters(prevFilters => {
                    if (prevFilters) {
                        return {
                            ...prevFilters,
                            [currentFilterKey]: {
                                ...prevFilters[currentFilterKey],
                                selectedIndexes: prevFilters[
                                    currentFilterKey
                                ].selectedIndexes.filter(
                                    idx => idx !== choiceIdx
                                )
                            }
                        };
                    } else {
                        return null;
                    }
                });
            } else {
                setCurrentFilters(prevFilters => {
                    if (prevFilters) {
                        return {
                            ...prevFilters,
                            [currentFilterKey]: {
                                ...prevFilters[currentFilterKey],
                                selectedIndexes: currentFilter.unique
                                    ? [choiceIdx]
                                    : [
                                          ...prevFilters[currentFilterKey]
                                              .selectedIndexes,
                                          choiceIdx
                                      ]
                            }
                        };
                    } else {
                        return null;
                    }
                });
            }
        }
    };
    const resetFilter = (filterKey: string | "all") => {
        if (filterKey === "all") {
            setCurrentFilters(filterMap);
        } else {
            setCurrentFilters(prevFilters => {
                if (prevFilters) {
                    return {
                        ...prevFilters,
                        [filterKey]: {
                            ...prevFilters[filterKey],
                            selectedIndexes: [],
                            customRange: prevFilters[filterKey].customRange && [
                                "",
                                ""
                            ],
                            isCustomRangeActive: false
                        }
                    };
                } else {
                    return null;
                }
            });
        }
    };
    const handleCustomRange = (filterKey: string, range: [string, string]) => {
        const isCustomRangeActive = range.some(rangeVal => Boolean(rangeVal));
        setCurrentFilters(prevFilters => {
            if (prevFilters) {
                return {
                    ...prevFilters,
                    [filterKey]: {
                        ...prevFilters[filterKey],
                        customRange: range,
                        isCustomRangeActive
                    }
                };
            } else {
                return null;
            }
        });
    };

    const areAnyFiltersActive =
        Object.values(currentFilters).filter(
            val => val.selectedIndexes.length > 0
        ).length > 0 ||
        Object.values(currentFilters).filter(
            val => val.type === "number" && val.isCustomRangeActive
        ).length > 0;

    return {
        currentFilters,
        setCurrentFilters,
        filterData,
        handleSelect,
        handleCustomRange,
        resetFilter,
        areAnyFiltersActive
    };
};

export default useFilters;
