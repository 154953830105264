import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CampaignMedia } from "src/campaigns/types";
import GlobalWarning, {
    GlobalWarningProps
} from "src/components/GlobalWarning";
import ShareButton from "src/components/ShareButton";
import Tooltip from "src/components/Tooltip";
import { colors } from "src/constants";
import { deleteMediaRequest } from "src/media/actions";
import styled from "styled-components";
import MediaModalDetails from "./MediaModalDetails";
const Header = styled.div`
    grid-area: header;
    display: flex;
    justify-content: space-between;
    height: auto;
    margin-bottom: 17px;
`;
const Title = styled.h3`
    font-size: 18px;
    margin-bottom: 0px;
`;
const HeaderBtnContainer = styled.div`
    display: flex;
`;
const StyledShareButton = styled(ShareButton)`
    background-color: ${colors.primaryGray};
    position: static;
    margin-right: 8px;
    &:hover {
        background-color: ${colors.secondaryGray};
    }
`;
const MoreBtn = styled.button`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: ${colors.primaryGray};
    padding: 0px;
    &:hover {
        background-color: ${colors.secondaryGray};
    }
    & > img {
        width: 26px;
        height: 26px;
    }
`;
const StyledTooltip = styled(Tooltip)`
    background-color: white;
    border-radius: 8px;
    padding: 16px 12px;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
    -webkit-user-drag: none;
`;
interface Props {
    media: CampaignMedia;
}

const MediaModalHeader = ({ media }: Props) => {
    const dispatch = useDispatch();
    const tooltipRef = useRef(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [showWarning, setShowWarning] = useState<GlobalWarningProps | null>(
        null
    );

    const handleTooltipClose = () => {
        if (!showWarning) {
            setTooltipOpen(false);
        }
    };

    return (
        <Header>
            <Title>Media ID: {media.id}</Title>
            <HeaderBtnContainer>
                <StyledShareButton
                    link={`/campaign/${media.campaignId}?mediaId=${media.id}`}
                />
                <MoreBtn
                    ref={tooltipRef}
                    onClick={e => setTooltipOpen(!tooltipOpen)}
                    onMouseDown={e => e.stopPropagation()}
                >
                    <Icon src="/modal-ellipsis.svg" alt="" />
                </MoreBtn>
                <StyledTooltip
                    zIndex={200}
                    isOpen={tooltipOpen}
                    close={() => handleTooltipClose()}
                    controlRef={tooltipRef}
                    position="right: -192px; top: 36px;"
                >
                    <MediaModalDetails
                        setShowWarning={setShowWarning}
                        media={media}
                        isOpen={tooltipOpen}
                    />
                </StyledTooltip>
            </HeaderBtnContainer>
            {showWarning && (
                <GlobalWarning
                    title={showWarning.title}
                    message={showWarning.message}
                    btnText={showWarning.btnText}
                    handleCancel={showWarning.handleCancel}
                    handleApply={showWarning.handleApply}
                />
            )}
        </Header>
    );
};

export default MediaModalHeader;
