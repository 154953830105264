import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import { GlobalState } from "../../reducers";

export const getRatesForSocialAccount = createCachedSelector(
    (state: GlobalState) => state.entities.socialAccounts.byId,
    (state: GlobalState) => state.entities.publisherOffers.byId,
    (state: GlobalState, socialAccountId: number | undefined) =>
        socialAccountId,
    (accountsState, publisherOffersState, socialAccountId) => {
        if (!socialAccountId) return [];

        const socialAccount = accountsState[socialAccountId];
        if (!socialAccount?.publisherOffers) {
            return [];
        }
        return socialAccount.publisherOffers.map(
            publisherOfferId => publisherOffersState[publisherOfferId]
        );
    }
)((state, socialAccountId) => socialAccountId || 1);

export const selectPublisherOffer = (
    state: GlobalState,
    publisherOfferId: number | null
) => {
    if (!publisherOfferId) return null;
    return state.entities.publisherOffers.byId[publisherOfferId];
};
