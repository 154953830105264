import React, { useEffect, useState } from "react";
import { get } from "src/new-chat/saga";
import Truncate from "react-truncate";
import { OutlineButton } from "src/profile/components/Buttons";
import withAccordion from "src/campaigns/details/accordions/withAccordion";
import { colors } from "src/constants";
import styled from "styled-components";

const Main = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0 18px;
    padding-bottom: 12px;
`;

const Link = styled(OutlineButton)`
    height: 40px;
    font-size: 14px;
`;

const ReadMore = styled.span`
    color: ${colors.primaryBlue};
    cursor: pointer;
`;

interface Props {
    description?: string;
    linkUrl: string;
    linkDescription: string;
}

const OpenBuyBriefAccordion = ({
    description,
    linkUrl,
    linkDescription
}: Props) => {
    const [maxLines, setMaxLines] = useState<number | false>(6);

    const getFirestoreMaxLines = async () => {
        try {
            const data = await get<{ maxLines: number; id: string }>(
                "ChallengeBriefNumberOfLines",
                "8MZOaCtnJXl640ZsOTpU"
            );
            if (data) setMaxLines(data.maxLines);
        } catch (error) {}
    };

    useEffect(() => {
        getFirestoreMaxLines();
    }, []);

    return (
        <Main>
            <Truncate
                lines={maxLines}
                ellipsis={
                    <span>
                        ...{" "}
                        <ReadMore onClick={() => setMaxLines(false)}>
                            Read more
                        </ReadMore>
                    </span>
                }
            >
                {description?.split("\n").map((text, i, arr) => {
                    const line = <span key={i}>{text}</span>;

                    if (i === arr.length - 1) {
                        return line;
                    } else {
                        return [line, <br key={i + "br"} />];
                    }
                })}
            </Truncate>

            {linkUrl && (
                <Link
                    onClick={() => {
                        window.open(linkUrl, "#");
                    }}
                >
                    {linkDescription}
                </Link>
            )}
        </Main>
    );
};
export default withAccordion(OpenBuyBriefAccordion);
