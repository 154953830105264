import { createSelector } from "reselect";
import { GlobalState } from "../../reducers";

const assetEntities = (state: GlobalState) => state.entities.assets;

const currentModalAssetIds = (state: GlobalState) =>
    state.ui.assets.currentModalAssets;

export const getCurrentModalAssets = createSelector(
    currentModalAssetIds,
    assetEntities,
    (currentModalAssetIds, assets) => {
        return currentModalAssetIds.map(id => assets[id]);
    }
);
