import React, { useState } from "react";
import { useRef } from "react";
import { colors } from "src/constants";
import ShadeScreen from "src/media/components/ShadeScreen";
import { Media } from "src/media/types";
import SimpleMediaModal, { SimpleMedia } from "src/modals/SimpleMediaModal";
import styled from "styled-components";

const AdUploadPanel = styled.div`
    padding: 30px 0px;
    display: flex;
    justify-content: center;
`;
const AdPreviewContainer = styled.div`
    position: relative;
    height: 205px;
    width: 114px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    background-color: ${colors.white};
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
`;

const Video = styled.video`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const Photo = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

interface Props {
    media: Media | SimpleMedia | null;
    style?: React.CSSProperties;
}

const MediaPreview = ({ media, style }: Props) => {
    const [showModal, setShowModal] = useState(false);

    function renderIcon() {
        if (media?.type === "video") {
            return <img src={"/play_icon.svg"} />;
        } else {
            return null;
        }
    }

    const simpleMedia: SimpleMedia = {
        url: media?.url || "",
        coverPhotoUrl: media?.coverPhotoUrl || "",
        type: media?.type || ""
    };

    const vid = useRef<HTMLVideoElement>(null);

    return (
        <AdUploadPanel>
            <AdPreviewContainer
                onClick={() => setShowModal(true)}
                style={style}
            >
                {media?.type === "video" ? (
                    <Video ref={vid} src={media?.url || undefined} />
                ) : (
                    <Photo src={media?.url || undefined} />
                )}
                {media?.type === "video" && (
                    <ShadeScreen>{renderIcon()}</ShadeScreen>
                )}
            </AdPreviewContainer>
            {showModal && (
                <SimpleMediaModal
                    media={simpleMedia}
                    closeModal={() => setShowModal(false)}
                />
            )}
        </AdUploadPanel>
    );
};

export default MediaPreview;
