import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { Profile } from "../../profile/types";
import { fetchUser } from "../../profile/actions";
import { PersonalDetailsParams, savePersonalDetails } from "../actions";
import { accountCompleted, loggedIn, magicInProgress } from "../../util";
import { push } from "connected-react-router";
import moment from "moment";
import PersonalDetailsScreens from "../components/PersonalDetailsScreens";
import FullscreenLoader from "../../components/FullscreenLoader";
import { colors } from "../../constants";
import { getPreApprovalCode } from "src/profile/selectors";

interface Props {
    profile?: Profile | null;
    fetchUser: () => void;
    savePersonalDetails: (personalDetails: PersonalDetailsParams) => void;
    push: (route: string) => void;
}

const PersonalDetails = ({
    profile,
    fetchUser,
    savePersonalDetails,
    push
}: Props) => {
    const preApprovalCode = useSelector(getPreApprovalCode);
    useEffect(() => {
        document.body.style.backgroundColor = colors.primaryBlue;

        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        if (profile) {
            if (profile.firstname) {
                setFirstname(profile.firstname);
            }
            if (profile.lastname) {
                setLastname(profile.lastname);
            }
            if (profile.email) {
                setEmail(profile.email);
            }
            if (profile.birthday) {
                setBirthday(
                    moment(profile.birthday, "YYYY-MM-DD").format("MM/DD/YYYY")
                );
            }
        }
    }, [profile]);

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [birthday, setBirthday] = useState("");

    if (!loggedIn() && magicInProgress()) {
        push("/approved/phone");
        return null;
    } else if (!loggedIn()) {
        push("/onboarding");
        return null;
    } else if (profile && accountCompleted(profile, preApprovalCode)) {
        push("/");
        return null;
    } else if (profile) {
        return (
            <PersonalDetailsScreens
                firstname={firstname}
                setFirstname={setFirstname}
                lastname={lastname}
                setLastname={setLastname}
                email={email}
                setEmail={setEmail}
                birthday={birthday}
                setBirthday={setBirthday}
                onSubmit={() =>
                    savePersonalDetails({
                        firstname,
                        lastname,
                        email,
                        birthday
                    })
                }
            />
        );
    } else {
        return <FullscreenLoader offset={57} color={"white"} />;
    }
};

const mapStateToProps = (state: GlobalState) => ({
    profile: state.profile.profile
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUser: () => dispatch(fetchUser()),
    savePersonalDetails: (personalDetails: PersonalDetailsParams) =>
        dispatch(savePersonalDetails(personalDetails)),
    push: (route: string) => dispatch(push(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
