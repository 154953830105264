import React from "react";
import { Route, Switch } from "react-router";
import Landing from "./landing/Landing";
import ShoutoutDetails from "src/campaigns/shoutouts/pages/ShoutoutDetails";
import CampaignDetailsRouter from "./details/router";
import UploadMedia from "src/media/upload";
import ShoutoutsHistory from "src/campaigns/shoutouts/pages/ShoutoutsHistory";

const CampaignsRouter = () => {
    return (
        <Switch>
            <Route exact={true} path={"/"} render={() => <Landing />} />

            <Route exact={true} path={"/campaign"} render={() => <Landing />} />

            <Route
                exact={true}
                path={"/campaign/upload-media"}
                render={() => <UploadMedia />}
            />

            <Route
                exact={true}
                path={"/campaign/all-direct-buys"}
                render={() => <ShoutoutsHistory />}
                // render={() => <Shoutouts />}
            />

            <Route
                exact={true}
                path={"/campaign/shoutouts/details"}
                render={() => <ShoutoutDetails />}
            />

            <Route
                path={"/campaign/:campaignId"}
                render={({ match }) => {
                    return (
                        // <CampaignDetails campaignId={match.params.campaignId} />
                        <CampaignDetailsRouter
                            campaignId={parseInt(match.params.campaignId)}
                        />
                    );
                }}
            />
        </Switch>
    );
};

export default CampaignsRouter;
