import React, { useEffect, useRef, useState } from "react";
import {
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot
} from "react-beautiful-dnd";
import { colors, fonts } from "src/constants";
import { clearSpacing, hideScrollbar } from "src/utils/styles/snippets";
import styled from "styled-components";
import {
    InstructionElementArrayKey,
    InstructionElementType
} from "src/marketplace/utils/types";
import {
    InstructionStepState,
    setInstructionSteps
} from "src/ui/instructions-sets/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { renderInstructionStepCarouselItems } from "src/marketplace/instructions-sets/utils/instruction-steps";
import { GlobalState } from "src/reducers";
import ScrollArrows from "src/components/ScrollArrows";
import { selectCampaign } from "src/campaigns/selectors";
import { getCurrentInstructionsSet } from "../selectors";

interface MainProps {
    hasElements: boolean;
    isDraggingOver: boolean;
}

const Main = styled.article<MainProps>`
    display: ${props => (props.hasElements ? "block" : "none")};
    min-height: 140px;
    color: ${colors.black};
    background-color: ${props => {
        return props.isDraggingOver ? colors.mediumTeal : colors.blueGrey;
    }};
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
    overflow: hidden;
`;

const HeaderAndScrollArrows = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0px 12px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 12px 0px 12px;
    width: 100%;
`;

const Title = styled.h3`
    ${clearSpacing()};
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: 600;
    text-transform: uppercase;
`;

const DeleteButton = styled.img`
    width: 16px;
    height: 16px;
    cursor: pointer;
`;

interface ItemsCarouselProps {
    isScripts: boolean;
}

const ItemsCarousel = styled.section<ItemsCarouselProps>`
    display: flex;
    flex-direction: ${props => (props.isScripts ? "column" : "row")};
    gap: 10px;
    width: 100%;
    padding: 12px;
    overflow-x: auto;
    transition: 0.1s ease-in;
    ${props => props.theme.isMobile && hideScrollbar()};
`;

interface Props {
    instructionSteps: InstructionStepState[];
    currentInstructionStepIndex: number;
    currentInstructionStepKey: InstructionElementArrayKey;
    currentInstructionStepElementIds: number[];
    hasElements: boolean;
    title: InstructionElementType;
}

const InstructionsFormStepDragArea = ({
    instructionSteps,
    currentInstructionStepIndex,
    currentInstructionStepKey,
    currentInstructionStepElementIds,
    hasElements,
    title
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const { scripts, medias, assets } = useSelector((state: GlobalState) => {
        return state.entities;
    }, shallowEqual);

    const {
        instructionsPageType,
        currentDragTargetType,
        isCurrentDragTargetAnInstructionElement
    } = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets;
    }, shallowEqual);

    // Local State ---------------------------------------------------
    const [items, setItems] = useState<(JSX.Element | undefined)[]>([]);

    // Local Constants -----------------------------------------------
    const currentCampaign = useSelector(selectCampaign);
    const currentInstructionsSet = useSelector(getCurrentInstructionsSet);
    const entitiesInstructionSteps = currentInstructionsSet?.instructionSteps;
    const isDragTargetTypeSameAsDragAreaType = currentDragTargetType === title;
    const itemsCarouselRef = useRef<any>(null);

    // Local Functions -----------------------------------------------
    function handleSectionDelete(): void {
        const updatedInstructionSteps = Array.from(instructionSteps);
        const currentInstructionStep =
            updatedInstructionSteps[currentInstructionStepIndex];
        currentInstructionStep[currentInstructionStepKey] = [];
        dispatch(setInstructionSteps(updatedInstructionSteps));
    }

    // Effects -------------------------------------------------------
    useEffect(() => {
        setItems(
            renderInstructionStepCarouselItems(
                title,
                currentInstructionStepElementIds,
                instructionSteps,
                currentInstructionStepIndex,
                currentInstructionStepKey,
                scripts,
                medias,
                assets,
                currentCampaign,
                dispatch,
                instructionsPageType,
                entitiesInstructionSteps
            )
        );
    }, [
        title,
        currentInstructionStepElementIds,
        instructionSteps,
        currentInstructionStepIndex,
        currentInstructionStepKey,
        currentCampaign?.id
    ]);

    // JSX -----------------------------------------------------------
    return (
        <Droppable
            droppableId={`InstructionsFormStepDroppable-${currentInstructionStepIndex}-${title}`}
            isDropDisabled={
                !isCurrentDragTargetAnInstructionElement &&
                !isDragTargetTypeSameAsDragAreaType
            }
        >
            {(
                provided: DroppableProvided,
                snapshot: DroppableStateSnapshot
            ) => (
                <Main
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={
                        isCurrentDragTargetAnInstructionElement &&
                        isDragTargetTypeSameAsDragAreaType &&
                        snapshot.isDraggingOver
                    }
                    hasElements={hasElements}
                >
                    <HeaderAndScrollArrows>
                        <Header>
                            <Title>{title}</Title>
                            {instructionsPageType !== "Main" && (
                                <DeleteButton
                                    src="/marketplace-instructions-delete-icon.svg"
                                    alt="marketplace-instructions-delete-icon"
                                    onClick={handleSectionDelete}
                                />
                            )}
                        </Header>
                        {title !== "Scripts" && (
                            <ScrollArrows refElement={itemsCarouselRef} />
                        )}
                    </HeaderAndScrollArrows>

                    <ItemsCarousel
                        ref={itemsCarouselRef}
                        isScripts={title === "Scripts"}
                    >
                        {items}
                        {provided.placeholder}
                    </ItemsCarousel>
                </Main>
            )}
        </Droppable>
    );
};

export default InstructionsFormStepDragArea;
