import { normalize } from "normalizr";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { getApi, post } from "src/Api";
import {
    claimPrizeFailure,
    claimPrizeSuccess,
    fetchContestFailure,
    fetchContestSuccess,
    FETCH_CONTEST,
    CLAIM_PRIZE,
    fetchHolidayContestSuccess,
    fetchHolidayContestFailure,
    FETCH_HOLIDAY_CONTEST
} from "./actions";
import * as schema from "../schema";
import { push } from "connected-react-router";
import { Action } from "src/types";

function* fetchContestRequest() {
    //TODO: remove anonymous: true when backend updates
    try {
        const { data } = yield call(getApi, "/contest", { anonymous: true });

        yield put(
            fetchContestSuccess(
                normalize(data.data.contest, schema.contestList)
            )
        );
    } catch (error) {
        yield put(fetchContestFailure("Fetch contest fail"));
    }
}

function* claimPrizeRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            `/api/contest_prizes/${payload.prizeId}`,
            payload
        );

        yield put(claimPrizeSuccess());
        yield put(push("/contest"));
    } catch (error) {
        yield put(claimPrizeFailure());
    }
}

function* fetchHolidayContestRequest() {
    try {
        const { data, status } = yield call(getApi, "/get_holiday_contest", {});

        if (status === 200) {
            yield put(fetchHolidayContestSuccess(data));
        } else {
            yield put(
                fetchHolidayContestFailure("Fetch holiday contest failure")
            );
        }
    } catch (error) {
        yield put(fetchHolidayContestFailure("Fetch holiday contest failure"));
    }
}

export default function* contestsSaga() {
    yield all([
        takeEvery(FETCH_CONTEST.REQUEST, fetchContestRequest),
        takeEvery(CLAIM_PRIZE.SUCCESS, fetchContestRequest),
        takeEvery(CLAIM_PRIZE.REQUEST, claimPrizeRequest),
        takeEvery(FETCH_HOLIDAY_CONTEST.REQUEST, fetchHolidayContestRequest)
    ]);
}
