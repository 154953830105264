import { isAxiosError } from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { post, patch } from "src/Api";
import {
    addOpenBuySubmissions,
    fetchSubmissionSets,
    updateOpenBuySubmission
} from "src/buys/actions";
import {
    OpenBuyInstructionStep,
    OpenBuySubmission,
    OpenBuySubmissionStatuses
} from "src/buys/modules/open-buys/types";
import { getStatusBody } from "src/buys/modules/open-buys/util";
import MediaUploader from "src/components/media/MediaUploader";
import StatusMessage from "src/components/modals/components/StatusMessage";
import { colors } from "src/constants";
import featureFlags from "src/firebase/featureFlags";
import { selectFeatureFlags } from "src/firebase/selectors";
import { BigBlueButton, OutlineButton } from "src/profile/components/Buttons";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 12px;
    padding: 0px 18px;
`;

const Submission = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 8px;
    gap: 10px;
    margin-bottom: 10px;
`;

const EditButton = styled(OutlineButton)`
    margin-top: 35%;
    margin-left: 39%;
    position: absolute;
    width: 52px;
    height: 32px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
`;

const Error = styled.h2`
    display: flex;
    height: 40px;
    font-size: 10px;
    font-weight: 400;
    border-radius: 6px;
    background: ${colors.errorPink};
    padding: 8px;
    width: 100%;
`;

const Title = styled.h3`
    color: ${colors.gray2};
    font-size: 12px;
    font-weight: 700;
    margin: 10px 0 0 0;
    text-align: left;
`;
const SubTitle = styled.h4`
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    margin: 0;
`;
interface Media {
    type: string;
    coverPhoto: string;
    filename: string;
}

const defaultMedia = {
    type: "",
    coverPhoto: "",
    filename: ""
};

interface Props {
    openBuyId: number;
    instructionStep: OpenBuyInstructionStep;
    type: string;
    submission: Partial<OpenBuySubmission>;
    rawSubmission?: Partial<OpenBuySubmission>;
    socialAccountId?: number;
    setId?: number;
    rawMediaStep?: OpenBuyInstructionStep;
}

const OpenBuyCustomMediaSubmission = ({
    openBuyId,
    instructionStep,
    type,
    submission,
    socialAccountId,
    setId,
    rawMediaStep,
    rawSubmission = {}
}: Props) => {
    const dispatch = useDispatch();
    const [media, setMedia] = useState<Media>(defaultMedia);
    const [edit, setEdit] = useState(false);
    const [removed, setRemoved] = useState(false);
    const [loading, setLoading] = useState(false);

    //Raw Media
    const [mediaRaw, setRawMedia] = useState<Media>(defaultMedia);
    const [editRaw, setRawEdit] = useState(false);
    const [removedRaw, setRawRemoved] = useState(false);

    const v2SubmissionSplitActive = useSelector(
        selectFeatureFlags(featureFlags.SubmissionSetV2)
    );

    const submitCustom = async () => {
        const params = {
            openBuyId,
            instructionStepId: instructionStep.id,
            filename: media.filename,
            coverPhoto: media.coverPhoto,
            type: media.type,
            socialAccountId,
            openBuySubmissionSetId: setId
        };
        if (submission?.status === "approved") {
            dispatch(setPillNotificationText("Can't edit an approved media"));
            return;
        }
        setLoading(true);
        try {
            let res = await (submission.id
                ? patch(
                      `/v1/openBuy/submission/${submission.id}/customMedia`,
                      params
                  )
                : post(`/v1/openBuy/submission/customMedia`, params));
            dispatch(setPillNotificationText("Media submitted successfully"));
            dispatch(fetchSubmissionSets(openBuyId));
            if (!submission.id)
                dispatch(addOpenBuySubmissions([res.data.data], openBuyId));
            else dispatch(updateOpenBuySubmission(res.data.data));
        } catch (err) {
            if (isAxiosError(err))
                dispatch(
                    setPillNotificationText(
                        err?.response?.data?.error?.message,
                        "danger"
                    )
                );
        } finally {
            setLoading(false);
        }
    };
    const submitRaw = async () => {
        const rawParams = {
            openBuyId,
            instructionStepId: rawMediaStep?.id,
            filename: mediaRaw.filename,
            coverPhoto: mediaRaw.coverPhoto,
            type: mediaRaw.type,
            socialAccountId,
            openBuySubmissionSetId: setId
        };
        if (rawSubmission?.status === "approved") {
            dispatch(
                setPillNotificationText("Can't edit an approved raw media")
            );
            return;
        }
        setLoading(true);
        try {
            let res = await (rawSubmission.id
                ? patch(
                      `/v1/openBuy/submission/${rawSubmission.id}/customMedia`,
                      rawParams
                  )
                : post(`/v1/openBuy/submission/customMedia`, rawParams));
            dispatch(setPillNotificationText("Media submitted successfully"));
            dispatch(fetchSubmissionSets(openBuyId));
            if (!rawSubmission.id)
                dispatch(addOpenBuySubmissions([res.data.data], openBuyId));
            else dispatch(updateOpenBuySubmission(res.data.data));
        } catch (err) {
            if (isAxiosError(err))
                dispatch(
                    setPillNotificationText(
                        err?.response?.data?.error?.message,
                        "danger"
                    )
                );
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Submission>
                <Title>Your Custom Ad</Title>
                <SubTitle>
                    Ad your custom ad below and allow up to 4 business days for
                    our team to review it.
                </SubTitle>
                <MediaUploader
                    status={submission.status}
                    disabled={submission.status === "approved"}
                    loading={loading}
                    style="compact"
                    path={
                        type === "screenshot"
                            ? "openBuyScreenshot"
                            : "openBuyCustomMedia"
                    }
                    triggerRemove={edit}
                    onUpload={(id, type, url) => {
                        setMedia({
                            filename: id,
                            type,
                            coverPhoto: type === "photo" ? id : ""
                        });
                        setEdit(false);
                    }}
                    onRemove={() => {
                        setMedia(defaultMedia);
                        setRemoved(true);
                        setEdit(true);
                    }}
                    onCoverPhoto={id => {
                        setMedia((old: any) => ({ ...old, coverPhoto: id }));
                    }}
                    acceptedFormats={
                        type === "screenshot"
                            ? "image/png, image/jpeg"
                            : undefined
                    }
                    controlledMedia={
                        !removed && submission.customMedia
                            ? {
                                  url: submission.customMedia.url || "",
                                  type: submission.customMedia.type
                              }
                            : undefined
                    }
                />
                {rawMediaStep && v2SubmissionSplitActive && (
                    <>
                        <Title>Raw Media</Title>
                        <SubTitle>
                            Raw media means the original video of just you
                            talking to the camera (or in a skit), which means no
                            music, no captions, no tiktok watermark, no assets,
                            no greenscreening.
                        </SubTitle>
                        <MediaUploader
                            status={rawSubmission.status}
                            disabled={rawSubmission.status === "approved"}
                            loading={loading}
                            style="compact"
                            path={
                                type === "screenshot"
                                    ? "openBuyScreenshot"
                                    : "openBuyCustomMedia"
                            }
                            triggerRemove={editRaw}
                            onUpload={(id, type, url) => {
                                setRawMedia({
                                    filename: id,
                                    type,
                                    coverPhoto: type === "photo" ? id : ""
                                });
                                setRawEdit(false);
                            }}
                            onRemove={() => {
                                setRawMedia(defaultMedia);
                                setRawRemoved(true);
                                setRawEdit(true);
                            }}
                            onCoverPhoto={id => {
                                setRawMedia((old: any) => ({
                                    ...old,
                                    coverPhoto: id
                                }));
                            }}
                            acceptedFormats={
                                type === "screenshot"
                                    ? "image/png, image/jpeg"
                                    : undefined
                            }
                            controlledMedia={
                                !removedRaw && rawSubmission.customMedia
                                    ? {
                                          url:
                                              rawSubmission.customMedia.url ||
                                              "",
                                          type: rawSubmission.customMedia.type
                                      }
                                    : undefined
                            }
                        />
                    </>
                )}
            </Submission>
            {submission.status &&
                submission.status !== OpenBuySubmissionStatuses.approved &&
                submission.status !== OpenBuySubmissionStatuses.next_step && (
                    <StatusMessage
                        type={
                            submission.status ===
                            OpenBuySubmissionStatuses.pending
                                ? "warning"
                                : "danger"
                        }
                        heading={
                            submission.status ===
                            OpenBuySubmissionStatuses.rejected
                                ? "Rejected"
                                : submission.status ===
                                  OpenBuySubmissionStatuses.revision_requested
                                ? "Revision Requested"
                                : "Pending approval"
                        }
                        closable
                        body={getStatusBody(submission)}
                        defaultOpen
                    ></StatusMessage>
                )}
            <BigBlueButton
                onClick={() => {
                    v2SubmissionSplitActive && mediaRaw.filename && submitRaw();
                    media.filename && submitCustom();
                }}
                disabled={
                    edit ||
                    editRaw ||
                    (!media.filename &&
                        (!v2SubmissionSplitActive || !mediaRaw.filename))
                }
            >
                Submit Media
            </BigBlueButton>
        </>
    );
};

export default OpenBuyCustomMediaSubmission;
