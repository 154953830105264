import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";
import {
    Option,
    OptionList,
    OptionText
} from "src/media/search/components/CommonStyles";
import LabelText from "src/components/text/LabelText";
import { CampaignFilters } from "src/campaigns/types";
import CampaignGenreModal from "../modals/CampaignGenreModal";
import { getSelectedDisplayText } from "src/ui/campaigns/selectors";
import { GlobalState } from "src/reducers";

const Label = styled(LabelText)`
    margin-top: 20px;
    margin-bottom: 12px;
`;

const Caret = styled.img`
    margin-left: auto;
    margin-right: 6px;
`;

interface Props {
    filters: CampaignFilters;
    setFilters: (filters: CampaignFilters) => void;
}

const CampaignGenre = ({ filters, setFilters }: Props) => {
    const [genreModal, toggleGenreModal] = useState(false);
    const genreDisplayText = useSelector((state: GlobalState) =>
        getSelectedDisplayText(state, filters.genres, "genre")
    );

    function setGenreConfig(selectedGenres: string[], allGenres: boolean) {
        setFilters({
            ...filters,
            genres: allGenres ? [] : selectedGenres
        });
    }

    return (
        <>
            <Label>APP GENRES</Label>
            <OptionList>
                <Option onClick={() => toggleGenreModal(true)}>
                    <OptionText>{genreDisplayText}</OptionText>
                    <Caret src={"/caret.svg"} />
                </Option>
            </OptionList>
            <AnimatePresence>
                {genreModal && (
                    <CampaignGenreModal
                        localClearModal={() => toggleGenreModal(false)}
                        keepFrozenOnClose
                        genres={filters.genres}
                        setGenreConfig={setGenreConfig}
                    />
                )}
            </AnimatePresence>
        </>
    );
};

export default CampaignGenre;
