import React from "react";
import {
    BlueButton,
    Bottom,
    EmptyButton,
    Form,
    Text,
    Middle,
    Title,
    Top
} from "./commonComponents";

interface Props {
    info: string;
    setInfo: (info: string) => void;
    back?: () => void;
    confirm: () => void;
}

const OtherForm = ({ info, setInfo, back, confirm }: Props) => {
    return (
        <>
            <Top>
                <div>
                    <strong>Any Other Details</strong>
                </div>
                <div>
                    Please provide any additional information that you would
                    like us to know.
                </div>
            </Top>
            <Middle>
                <Form>
                    <Title>Info</Title>
                    <Text
                        value={info}
                        onChange={e => setInfo(e.currentTarget.value)}
                    />
                </Form>
            </Middle>
            <Bottom>
                {back && <EmptyButton onClick={back}>Back</EmptyButton>}
                <BlueButton onClick={confirm}>
                    {back ? "Next" : "Confirm"}
                </BlueButton>
            </Bottom>
        </>
    );
};

export default OtherForm;
