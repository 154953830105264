import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "src/components/Navbar";
import { getLoggedInProfile } from "src/profile/selectors";
import { AccessStatuses } from "src/profile/types";
import CampaignsTab from "./CampaignsTab";
import ChallengesTab from "./ChallengesTab";
import { breakpoints, colors, fonts } from "src/constants";
import { flex, hideScrollbar } from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import { setHasReadLocalStoreForNavToAdminTools } from "../../ui/admin-tools/actions";
import { Redirect } from "react-router";
import { GlobalState } from "src/reducers";
import ScrollRestoration from "src/components/ScrollRestoration";

const Main = styled.main`
    ${flex("column")};
    ${hideScrollbar()};
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
`;

interface HeaderProps {
    padding?: string;
}

export const SectionHeader = styled("h2")<HeaderProps>`
    margin: 0px;
    padding: ${props => props.padding || "0px"};
    color: ${colors.black};
    font-size: ${fonts.title}px;
    font-weight: 500;
    text-align: left;
    line-height: 29px;

    @media (max-width: ${breakpoints.mobile}px) {
        font-size: 20px;
    }
`;

const TabRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    top: 90px;
    position: fixed;
    z-index: 100;
    background-color: white;
    margin: auto;
    @media (max-width: ${breakpoints.tablet}px) {
        top: 57px;
    }
`;

const TabInner = styled.div`
    max-width: 910px;
    width: 100%;
`;

const Tab = styled.div<{ selected: boolean }>`
    background-color: ${colors.white};
    justify-content: center;
    padding: 12px 0px;
    width: 50%;
    max-width: 500px;
    cursor: pointer;
    border-bottom: ${props =>
            props.selected ? colors.black : colors.secondaryLightGray}
        solid 2px;
    color: ${props => (props.selected ? colors.black : colors.gray2)};
`;

interface SectionProps {
    hidden?: boolean;
    padding?: string;
    noBreakpoints?: true;
}

export const Section = styled("div")<SectionProps>`
    display: ${props => props.hidden && "none"};
    padding: ${props => props.padding};

    ${props =>
        !props.noBreakpoints &&
        css`
            @media (max-width: ${breakpoints.tablet}px) {
                margin: 0px 15px 0px;
            }
        `}
`;

const Landing = () => {
    const [selectedTab, setSelectedTab] = useState(
        window.location.pathname.includes("campaign") ? 1 : 0
    );
    const dispatch = useDispatch();

    const profile = useSelector(getLoggedInProfile);
    const { hasReadLocalStoreForNavToAdminTools } = useSelector(
        (state: GlobalState) => {
            return state.ui.adminTools;
        }
    );
    const [navToAdmin, setNavToAdmin] = useState<boolean>(false);

    useEffect(() => {
        if (!hasReadLocalStoreForNavToAdminTools) {
            const adminToolsSticky: boolean = Boolean(
                JSON.parse(localStorage.getItem("adminToolsSticky") ?? "false")
            );
            setNavToAdmin(adminToolsSticky);
            dispatch(setHasReadLocalStoreForNavToAdminTools());
        }
    }, [hasReadLocalStoreForNavToAdminTools]);

    if (navToAdmin) return <Redirect to="/admin-tools/your-challenges" />;

    return (
        <ScrollRestoration>
            <Main>
                <Navbar
                    marginBottom={
                        profile?.accessStatus === AccessStatuses.ALL ? 70 : 30
                    }
                    mobileHasMarginBottom={true}
                    title="EARN"
                />
                {profile?.accessStatus === AccessStatuses.ALL && (
                    <TabRow>
                        <TabInner
                            style={{
                                maxWidth: "910px",
                                display: "flex",
                                width: "100%"
                            }}
                        >
                            <Tab
                                onClick={() => setSelectedTab(0)}
                                selected={selectedTab === 0}
                            >
                                Challenges
                            </Tab>
                            <Tab
                                onClick={() => setSelectedTab(1)}
                                selected={selectedTab === 1}
                            >
                                Campaigns
                            </Tab>
                        </TabInner>
                    </TabRow>
                )}

                {selectedTab === 0 ? <ChallengesTab /> : <CampaignsTab />}
            </Main>
        </ScrollRestoration>
    );
};

export default Landing;
