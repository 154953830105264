import React from "react";
import { connect } from "react-redux";
import { CampaignTotals, MediaTotals } from "./AnalyticsDataParser";
import { push } from "connected-react-router";
import { analyticsMediaToString, formatNumber } from "../util";
import styled from "styled-components";
import AnalyticsIcon from "./AnalyticsIcon";
import { mediaModal, ModalMedia } from "./actions";
import { colors } from "../constants";

interface StyleProps {
    clickable?: true;
}

const Cell = styled.div<StyleProps>`
    width: 100%;
    display: flex;
    ${props => (props.clickable ? "user-select: none;" : "")}
    ${props => (props.clickable ? "cursor: pointer;" : "")}

    & + & {
        margin-top: 15px;
    }
`;

const Description = styled.div`
    width: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Name = styled.span`
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MoneyContainer = styled.div`
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
`;

const Cash = styled.div`
    font-size: 20px;
    line-height: 24px;
`;

interface Props {
    totals: CampaignTotals | MediaTotals;
    push: (path: string) => void;
    mediaModal: (modalMedia: ModalMedia) => void;
}

const DailyEarningsCell = ({ totals, push, mediaModal }: Props) => {
    const dollars = Math.round(totals.totalDollars * 100) / 100;

    if ("campaignImageUrl" in totals) {
        return (
            <Cell>
                <AnalyticsIcon mediaUrl={totals.campaignImageUrl} />
                <Description>
                    <Name>{totals.campaignName}</Name>
                    <MoneyContainer>
                        <Cash>{`$${formatNumber(dollars.toFixed(2))}`}</Cash>
                    </MoneyContainer>
                </Description>
            </Cell>
        );
    } else {
        return (
            <Cell
                clickable
                onClick={() => {
                    if (
                        "media" in totals &&
                        totals.mediaUrl &&
                        (totals.mediaType === "video" ||
                            totals.mediaType === "photo")
                    ) {
                        mediaModal({
                            mediaUrl: totals.mediaUrl,
                            coverUrl: totals.mediaCoverPhotoUrl || undefined,
                            type: totals.mediaType
                        });
                    }
                }}
            >
                <AnalyticsIcon
                    mediaUrl={totals.mediaCoverPhotoUrl || totals.mediaUrl}
                    video={
                        totals.mediaCoverPhotoUrl
                            ? false
                            : totals.mediaType === "video"
                    }
                />
                <Description>
                    <Name>{analyticsMediaToString(totals)}</Name>
                    <MoneyContainer>
                        <Cash>{`$${formatNumber(dollars.toFixed(2))}`}</Cash>
                    </MoneyContainer>
                </Description>
            </Cell>
        );
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    push: (path: string) => dispatch(push(path)),
    mediaModal: (modalMedia: ModalMedia) => dispatch(mediaModal(modalMedia))
});

export default connect(null, mapDispatchToProps)(DailyEarningsCell);
