import React from "react";
import {
    Top,
    Middle,
    Bottom,
    Title,
    Input,
    Form,
    EmptyButton,
    BlueButton
} from "./commonComponents";

interface Props {
    name: string;
    setName: (name: string) => void;
    phone: string;
    setPhone: (phone: string) => void;
    email: string;
    setEmail: (email: string) => void;
    back?: () => void;
    confirm: () => void;
}

const ContactForm = ({
    name,
    setName,
    phone,
    setPhone,
    email,
    setEmail,
    back,
    confirm
}: Props) => {
    function submitDisabled() {
        return (
            name.length === 0 ||
            phone.length < 10 ||
            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        );
    }

    return (
        <>
            <Top>
                <div>
                    <strong>Contact Information</strong>
                </div>
                <div>
                    Please provide your contact information so we can contact
                    you with status updates.
                </div>
            </Top>
            <Middle>
                <Form>
                    <Title>Name</Title>
                    <Input
                        value={name}
                        onChange={e => setName(e.currentTarget.value)}
                    />
                    <Title>Phone</Title>
                    <Input
                        value={phone}
                        onChange={e => setPhone(e.currentTarget.value)}
                    />
                    <Title>Email</Title>
                    <Input
                        value={email}
                        onChange={e => setEmail(e.currentTarget.value)}
                    />
                </Form>
            </Middle>
            <Bottom>
                {back && <EmptyButton onClick={back}>Back</EmptyButton>}
                <BlueButton onClick={confirm} disabled={submitDisabled()}>
                    {back ? "Next" : "Confirm"}
                </BlueButton>
            </Bottom>
        </>
    );
};

export default ContactForm;
