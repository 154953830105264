import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainContainer from "src/components/MainContainer";
import MarketplacePagePartialHeader from "src/marketplace/components/MarketplacePagePartialHeader";
import InstructionsSetsCreatePage from "src/marketplace/instructions-sets/pages/InstructionsSetsCreatePage";
import InstructionsSetsEditPage from "src/marketplace/instructions-sets/pages/InstructionsSetsEditPage";
import InstructionsSetsMainPage from "src/marketplace/instructions-sets/pages/InstructionsSetsMainPage";
import { getCurrentInstructionsSet } from "src/marketplace/instructions-sets/selectors";
import { fetchOpenBuyToEdit } from "src/marketplace/open-buys/actions";
import ChallengeList from "src/marketplace/reports/ChallengeList";
import { BlueButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import {
    resetInstructionsSetWhenNavigatingToMainPage,
    setCurrentInstructionsSetId,
    setInstructionsPageType
} from "src/ui/instructions-sets/actions";
import styled from "styled-components";
import AdminSideMenu from "../components/AdminSideMenu";

const Main = styled(MainContainer)`
    padding-top: 12px;
    margin-bottom: 36px;
`;

const StyledBlueButton = styled(BlueButton)`
    padding: 8px 12px;
`;

const ChallengeInstructions = () => {
    const dispatch = useDispatch();
    // Redux State ---------------------------------------------------
    const { instructionsPageType } = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets;
    });

    const currentInstructionsSet = useSelector(getCurrentInstructionsSet);
    const { currentInstructionsSetId } = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets;
    });

    // JSX -----------------------------------------------------------
    return (
        <>
            <AdminSideMenu
                title="Challenge Instructions Manager"
                selectedToolIndex={5}
            />
            <Main>
                <MarketplacePagePartialHeader
                    includeHeading
                    customCrumbs={["Challenge Tools", "Instructions"]}
                    headerTitle={
                        instructionsPageType === "Main"
                            ? "Challenge Instructions Manager"
                            : instructionsPageType === "Edit"
                            ? "Edit Challenge Instructions"
                            : undefined
                    }
                    buttons={
                        (instructionsPageType === "Edit" ||
                            instructionsPageType === "Create") && (
                            <StyledBlueButton
                                onClick={() =>
                                    // dispatch(setInstructionsPageType("Main"))
                                    dispatch(
                                        resetInstructionsSetWhenNavigatingToMainPage()
                                    )
                                }
                            >
                                Cancel
                            </StyledBlueButton>
                        )
                    }
                />

                {instructionsPageType === "Main" ? (
                    <ChallengeList
                        onSelect={buy => {
                            dispatch(fetchOpenBuyToEdit(buy.id));
                            if (
                                buy.instructionSetId ||
                                currentInstructionsSetId
                            ) {
                                dispatch(setInstructionsPageType("Edit"));
                            } else {
                                dispatch(setInstructionsPageType("Create"));
                            }
                        }}
                    />
                ) : instructionsPageType === "Edit" ? (
                    <InstructionsSetsEditPage noHeader />
                ) : (
                    <InstructionsSetsCreatePage noHeader />
                )}
            </Main>
        </>
    );
};

export default ChallengeInstructions;
