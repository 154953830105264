import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { adminFetchOpenBuy } from "src/admin-tools/actions.admin-tools";
import axios, { get } from "src/Api";
import {
    OpenBuy,
    OpenBuyInstructionStep,
    OpenBuySubmission,
    OpenBuySubmissionStatuses
} from "src/buys/modules/open-buys/types";
import MoreBtn from "src/components/modals/components/MoreBtn";
import ShareButton from "src/components/ShareButton";
import { colors } from "src/constants";
import { selectMarketplaceOpenBuy } from "src/marketplace/redux/selectors";
import XCard from "src/profile/components/XCard";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import ApprovalActions from "./ApprovalActions";
import ApprovalModalLoading from "./ApprovalModalLoading";
import DetailsColumn from "./DetailsColumn";
import { fetchSubmissionSet } from "src/buys/actions";
import { selectSetSubmissions } from "src/buys/selectors";
import ApprovalColumn from "./ApprovalColumn";
import ModalHeading from "src/components/modals/components/ModalHeading";
import SubmissionStepSelect from "./SubmissionStepSelect";
import ApprovalSubmissionView from "./ApprovalSubmissionView";
import ChallengeSection from "./ChallengeSection";
import { ReactComponent as ChatIcon } from "src/components/icons/chat-icon.svg";
import { ReactComponent as DetailsIcon } from "src/components/icons/details-icon.svg";
import SubmissionApprovalInternalNotes from "src/new-chat/approval-internal-notes/SubmissionApprovalInternalNotes";
import moment from "moment";

const Wrapper = styled.div<{ type?: OpenBuySubmissionStatuses }>`
    height: 90vh;
    width: 90vw;
    position: relative;
    padding: 6px;
    gap: 6px;
    overflow: visible;
    background-color: ${colors.gray5};
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    border: 4px solid
        ${props =>
            props.type === OpenBuySubmissionStatuses.pending
                ? colors.primaryYellow
                : props.type === OpenBuySubmissionStatuses.rejected ||
                  props.type === OpenBuySubmissionStatuses.revision_requested
                ? colors.primaryRed
                : props.type === OpenBuySubmissionStatuses.approved
                ? colors.primaryGreen
                : colors.primaryBlue};
`;
const Header = styled.header`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
    border-bottom: 1px solid ${colors.blueGrey2};
`;
const Column = styled.div`
    height: 100%;
    width: 33%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    gap: 6px;
`;
const LeftColumn = styled(Column)`
    padding: 12px;
    padding-bottom: 0px;
    max-height: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    background-color: ${colors.white};
`;

const SubmissionStatuses = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
`;

const SubmissionStatus = styled.div<{ type: string }>`
    font-weight: 500;
    border-radius: 100px;
    font-size: 14px;
    height: 24px;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 8px;
    gap: 4px;
    font-family: sans-serif;
    text-transform: capitalize;
    color: white;
    background-color: ${props =>
        props.type === OpenBuySubmissionStatuses.pending
            ? colors.primaryYellow
            : props.type === OpenBuySubmissionStatuses.rejected ||
              props.type === OpenBuySubmissionStatuses.revision_requested
            ? colors.primaryRed
            : props.type === OpenBuySubmissionStatuses.approved
            ? colors.primaryGreen
            : colors.primaryBlue};
    margin-top: 10px;
    width: fit-content;
`;
const BtnWrap = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;
const SectionContainer = styled.div`
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
`;
const MoreWrap = styled.div`
    position: relative;
`;
const StyledXCard = styled(XCard)`
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 6px;
`;

const Title = styled.div`
    font-weight: 510;
    font-size: 12px;
    text-align: left;
`;
const SubTitle = styled.div`
    font-weight: 510;
    font-size: 12px;
    text-align: left;
    color: ${colors.gray2};
`;

const SubmissionStatusDate = styled.div<{ color?: string }>`
    color: ${props => props.color};
    gap: 6px;
    font-weight: 400;
    font-size: 10px;
    margin-top: 4px;
`;
const SubmissionList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 12px;
`;

const StyledModalHeading = styled(ModalHeading)`
    margin-bottom: 6px;
`;

const TabRow = styled.div`
    display: flex;
    margin-top: auto;
    width: 100%;
    justify-content: center;
    gap: 18px;
    padding: 9px;
`;
const Tab = styled.div<{ selected?: boolean }>`
    background-color: ${props => props.selected && colors.primaryLightGray};
    border-radius: 8px;
    width: 48px;
    height: 48px;
    padding: 10px;
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }
`;

const SubmissionApprovalModal = ({
    submissionSetId,
    isActive,
    advanceOrCloseCarousel
}: {
    submissionSetId: number;
    isActive: boolean;
    advanceOrCloseCarousel: VoidFunction;
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const tooltipRef = useRef(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [loadingBuy, setLoadingBuy] = useState(false);
    const [loadedSet, setLoadedSet] = useState(false);
    const [leaderboardType, setLeaderboardType] = useState("text");
    const [detailsTab, setDetailsTab] = useState("details");

    const [
        selectedStep,
        setSelectedStep
    ] = useState<OpenBuyInstructionStep | null>(null);

    const submissionSet = useSelector(
        (state: GlobalState) =>
            state.entities.openBuySubmissionSets[submissionSetId]
    );
    const openBuy: OpenBuy | null = useSelector((state: GlobalState) =>
        selectMarketplaceOpenBuy(state, submissionSet?.openBuyId)
    );

    const submissions = useSelector((state: GlobalState) =>
        selectSetSubmissions(state, submissionSetId)
    );

    useEffect(() => {
        if (!isActive) return;
        let unmounted = false;
        let source = axios.CancelToken.source();
        if (!submissionSet) dispatch(fetchSubmissionSet(submissionSetId));
        history.replace(
            `${window.location.pathname}?submissionSetId=${submissionSetId}`
        );
        return () => {
            unmounted = true;
            source.cancel("Cancelling in cleanup");
        };
    }, [isActive, submissionSetId]);
    useEffect(() => {
        if (!openBuy && submissionSet?.openBuyId && !loadingBuy) {
            setLoadingBuy(true);
            dispatch(adminFetchOpenBuy(submissionSet.openBuyId));
        }
    }, [submissionSet]);

    useEffect(() => {
        if (submissionSet?.openBuyId && !leaderboardType)
            get(
                `/v1/openBuy/${submissionSet.openBuyId}/highestOrderSubmissionType`,
                {}
            ).then(res => setLeaderboardType(res.data.data));
    }, [submissionSet?.openBuyId]);

    useEffect(() => {
        if (!submissionSet && !loadedSet) {
            setLoadedSet(true);
            dispatch(fetchSubmissionSet(submissionSetId));
        }
    }, [submissionSetId]);

    useEffect(() => {
        if (openBuy?.submissionSteps)
            setSelectedStep(openBuy?.submissionSteps[0]);
    }, [openBuy?.submissionSteps]);

    if (isError) {
        return (
            <Wrapper>
                <StyledXCard boxFullHeight text="Something went wrong!" />
            </Wrapper>
        );
    }

    const currentSubmission = selectedStep
        ? submissions.find(sub => sub.instructionStepId === selectedStep.id)
        : submissions[0];
    const tabRow = (
        <TabRow>
            <Tab
                selected={detailsTab === "details"}
                onClick={() => setDetailsTab("details")}
            >
                <DetailsIcon
                    fill={
                        detailsTab === "details"
                            ? colors.lightBlue
                            : colors.gray2
                    }
                />
            </Tab>
            <Tab
                selected={detailsTab === "chat"}
                onClick={() => setDetailsTab("chat")}
            >
                <ChatIcon
                    fill={
                        detailsTab === "chat" ? colors.lightBlue : colors.gray2
                    }
                />
            </Tab>
        </TabRow>
    );

    if (!submissionSet || !openBuy) return <ApprovalModalLoading />;
    return (
        <Wrapper type={submissionSet.status}>
            {detailsTab === "details" ? (
                <Column>
                    <SectionContainer>
                        <Header>
                            <div>
                                <Title>
                                    Submission Set ID: {submissionSet?.id}
                                </Title>
                                <SubTitle>{openBuy.name}</SubTitle>
                                <SubmissionStatuses>
                                    <SubmissionStatus
                                        type={submissionSet.status}
                                    >
                                        {submissionSet.status ===
                                        OpenBuySubmissionStatuses.next_step
                                            ? "Next Step"
                                            : submissionSet.status}
                                        <SubmissionStatusDate>
                                            {`Last update: `}
                                            {moment(
                                                submissionSet.statusUpdatedAt
                                            ).format("l")}
                                        </SubmissionStatusDate>
                                    </SubmissionStatus>
                                    {submissionSet.advertiserReviewStatus && (
                                        <SubmissionStatus
                                            type={submissionSet.advertiserReviewStatus}
                                        >
                                            {"Adv. "}
                                            {submissionSet.advertiserReviewStatus ===
                                            OpenBuySubmissionStatuses.revision_requested
                                                ? "Revision Requested"
                                                : submissionSet.advertiserReviewStatus}
                                        </SubmissionStatus>
                                    )}
                                </SubmissionStatuses>
                            </div>
                            <BtnWrap>
                                <ShareButton
                                    isModal
                                    link={`/admin-tools/challenges/submissions?submissionSetId=${submissionSetId}`}
                                />
                                <MoreWrap>
                                    <MoreBtn
                                        refProp={tooltipRef}
                                        onClick={() =>
                                            setTooltipOpen(!tooltipOpen)
                                        }
                                    />

                                    <ApprovalActions
                                        getSubmissionSet={() =>
                                            dispatch(
                                                fetchSubmissionSet(
                                                    submissionSetId
                                                )
                                            )
                                        }
                                        advanceOrCloseCarousel={
                                            advanceOrCloseCarousel
                                        }
                                        tooltipOpen={tooltipOpen}
                                        tooltipRef={tooltipRef}
                                        close={() => setTooltipOpen(false)}
                                        submissionSet={submissionSet}
                                    />
                                </MoreWrap>
                            </BtnWrap>
                        </Header>
                        <SubmissionList>
                            <StyledModalHeading text="SUBMISSION STEPS" />
                            {openBuy.submissionSteps.map(
                                (step: OpenBuyInstructionStep, index) => {
                                    const submission:
                                        | OpenBuySubmission
                                        | undefined = submissions.find(
                                        sub => sub.instructionStepId === step.id
                                    );
                                    return (
                                        <SubmissionStepSelect
                                            submission={submission}
                                            index={index}
                                            step={step}
                                            onClick={() =>
                                                setSelectedStep(step)
                                            }
                                            selected={
                                                selectedStep
                                                    ? step.id ===
                                                      selectedStep.id
                                                    : false
                                            }
                                        />
                                    );
                                }
                            )}
                        </SubmissionList>
                    </SectionContainer>
                    {isActive && (
                        <SectionContainer>
                            <DetailsColumn submissionSet={submissionSet} />
                        </SectionContainer>
                    )}
                    <SectionContainer>
                        <ChallengeSection
                            submissionSet={submissionSet}
                            openBuy={openBuy}
                        />
                    </SectionContainer>

                    {tabRow}
                </Column>
            ) : (
                <Column style={{ maxWidth: "400px" }}>
                    {isActive && (
                        <SubmissionApprovalInternalNotes
                            submissionSetId={submissionSetId}
                        />
                    )}

                    {tabRow}
                </Column>
            )}
            <Column>
                <SectionContainer>
                    <ApprovalSubmissionView
                        submission={currentSubmission}
                        step={selectedStep || openBuy.submissionSteps[0]}
                    />
                </SectionContainer>
            </Column>
            {currentSubmission && (
                <Column>
                    <SectionContainer>
                        <ApprovalColumn
                            publisherName={
                                submissionSet.publisher?.firstname ||
                                submissionSet.publisher?.username ||
                                ""
                            }
                            submission={currentSubmission}
                            openBuy={openBuy}
                        />
                    </SectionContainer>
                </Column>
            )}
        </Wrapper>
    );
};

export default SubmissionApprovalModal;
