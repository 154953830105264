import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Script } from "src/campaigns/types";
import Tooltip from "src/components/Tooltip";
import { colors } from "src/constants";
import styled from "styled-components";
import CheckBox from "src/components/CheckBox";
import { DeleteButton } from "src/profile/components/Buttons";
import UserInfoHeader from "./UserInfoHeader";
import { deleteScript, editScript } from "../actions";
import useCachedProfiles from "src/hooks/useCachedProfiles";

const Wrapper = styled.div`
    position: relative;
    margin-left: 8px;
`;

const InfoBtn = styled.div<{ isOpen: boolean }>`
    position: relative;
    border: none;
    background-color: ${props =>
        props.isOpen ? colors.secondaryGray : colors.primaryGray};
    color: ${colors.white};
    border-radius: 50%;
    height: 26px;
    width: 26px;
    font-weight: 400;
    font-size: 1rem;
    transition: background-color, 0.2s ease;
    display: grid;
    place-items: center;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        background-color: ${colors.secondaryGray};
    }
    & > * {
        transform: translateX(-0.5px);
    }
`;
const StyledTooltip = styled(Tooltip)`
    width: 320px;
    padding: 12px;
    flex-direction: column;
    border-radius: 8px;
    background-color: white;
`;
const UserHeader = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 10px;
`;
const Title = styled.p`
    font-weight: normal;
    display: block;
    margin: 0px;
    padding: 0px;
    font-size: 0.8rem;
    color: ${colors.black};
`;
const Subtitle = styled.p`
    font-weight: normal;
    font-size: 0.8rem;
    margin: 0px;
    padding: 0px;
    color: ${colors.gray2};
`;
const ToggleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
`;
const StyledDeleteBtn = styled(DeleteButton)`
    height: 48px;
    font-weight: normal;
    font-size: 0.8rem;
`;
export interface PublisherInfo {
    username: string | null;
    avatar: string | null;
}
const UserInfo = ({
    script,
    onDelete
}: {
    script: Script;
    onDelete: () => void;
}) => {
    const dispatch = useDispatch();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const ref = useRef() as MutableRefObject<HTMLDivElement>;
    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setTooltipOpen(() => !tooltipOpen);
    };

    const [profileLoading, setProfileLoading] = useState(true);
    const { profile, fetchProfile } = useCachedProfiles(
        `${script && script.uploadedByPublisherId}`
    );

    useEffect(() => {
        if (!profile) {
            fetchProfile(`${script.uploadedByPublisherId}`);
        } else {
            setProfileLoading(false);
        }
    }, [profile, fetchProfile, setProfileLoading]);

    const handleCheck = () => {
        dispatch(
            editScript({ ...script, shouldShowInApp: !script.shouldShowInApp })
        );
    };

    return (
        <Wrapper>
            <InfoBtn isOpen={tooltipOpen} ref={ref} onClick={handleClick}>
                <span>i</span>
            </InfoBtn>
            <StyledTooltip
                controlRef={ref}
                isOpen={tooltipOpen}
                close={() => setTooltipOpen(false)}
                position={`bottom: -172px; left: -310px;`}
                zIndex={91}
            >
                <UserHeader>
                    <UserInfoHeader
                        publisher={profile}
                        createdAt={script.createdAt}
                        loading={profileLoading}
                    />
                </UserHeader>
                <ToggleWrapper>
                    <div style={{ textAlign: "left" }}>
                        <Title>Make Script Visible</Title>
                        <Subtitle>Allow anyone on the Plug to view</Subtitle>
                    </div>
                    <CheckBox
                        onCheck={handleCheck}
                        onUncheck={handleCheck}
                        selected={script.shouldShowInApp}
                    />
                </ToggleWrapper>
                <StyledDeleteBtn
                    style={{
                        height: "48px",
                        fontWeight: "normal",
                        fontSize: "0.8rem"
                    }}
                    onClick={onDelete}
                >
                    Delete Script
                </StyledDeleteBtn>
            </StyledTooltip>
        </Wrapper>
    );
};

export default UserInfo;
