import { combineReducers } from "redux";
import { Action } from "src/types";
import { ById } from "src/utils/types/general";
import { CREATE_OPEN_BUY } from "./actions.open-buys";
import { MarketplaceOpenBuy } from "./types.open-buys";

export interface MarketplaceOpenBuysEntitiesState {
    byId: ById<MarketplaceOpenBuy>;
    allIds: number[];
}

const initialMarketplaceOpenBuysState: MarketplaceOpenBuysEntitiesState = {
    byId: {},
    allIds: []
};

function byId(
    state = initialMarketplaceOpenBuysState.byId,
    action: Action
): ById<MarketplaceOpenBuy> {
    switch (action.type) {
        case CREATE_OPEN_BUY.SUCCESS:
            return { ...state, ...action.payload.entities.openBuy };

        default:
            return state;
    }
}

function allIds(
    state = initialMarketplaceOpenBuysState.allIds,
    action: Action
): number[] {
    switch (action.type) {
        case CREATE_OPEN_BUY.SUCCESS:
            return [...state, ...action.payload.entities.result];

        default:
            return state;
    }
}

const marketplaceOpenBuysEntitiesReducer = combineReducers<
    MarketplaceOpenBuysEntitiesState
>({
    byId,
    allIds
});

export default marketplaceOpenBuysEntitiesReducer;
