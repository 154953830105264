import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { fetchScriptAssets } from "../../assets/actions";
import AssetsAccordion from "../../details/accordions/AssetsAccordion";
import withAccordion from "../../details/accordions/withAccordion";
import { getScriptAssets } from "../selectors";

const mapStateToProps = (state: GlobalState) => ({
    name: "script",
    currentId: state.ui.script.scriptModal,
    assetsLoading: state.ui.script.assetsLoading,
    assets: getScriptAssets(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchAssets: (scriptId: number) => dispatch(fetchScriptAssets(scriptId))
});

export default withAccordion(
    connect(mapStateToProps, mapDispatchToProps)(AssetsAccordion)
);
