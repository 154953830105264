import React from "react";
import { MediaFilters } from "../types";
import CheckBox from "src/components/CheckBox";
import {
    Label,
    Option,
    OptionList,
    OptionText
} from "./components/CommonStyles";

interface Props {
    setFilters: (filters: MediaFilters) => void;
    filters: MediaFilters;
}

const MediaFilterMediaType = ({ filters, setFilters }: Props) => {
    function setMediaType(mediaType: MediaFilters["type"]) {
        setFilters({ ...filters, type: mediaType });
    }

    function generateSetMediaType(key: "photo" | "video") {
        return () =>
            setMediaType({ ...filters.type, [key]: !filters.type[key] });
    }

    return (
        <div>
            <Label>MEDIA TYPE</Label>
            <OptionList>
                <Option onClick={generateSetMediaType("photo")}>
                    <OptionText>Image</OptionText>
                    <CheckBox selected={filters.type.photo} />
                </Option>
                <Option onClick={generateSetMediaType("video")}>
                    <OptionText>Video</OptionText>
                    <CheckBox selected={filters.type.video} />
                </Option>
            </OptionList>
        </div>
    );
};

export default MediaFilterMediaType;
