import React from "react";
import Toggler from "src/components/Toggler";
import { fonts } from "src/constants";
import styled from "styled-components";
import { MediaFilters } from "../types";
import { Label } from "./components/CommonStyles";

const FilterWrap = styled.div`
    font-size: ${fonts.smallTitle}px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    &:last-child {
        padding-bottom: 0px;
    }
`;

interface Props {
    filters: MediaFilters;
    setFilters: (filters: MediaFilters) => void;
}
const MediaFilterAdmin = ({ filters, setFilters }: Props) => {
    const toggleShowAll = () => {
        setFilters({
            ...filters,
            admin: { ...filters.admin, showAll: !filters.admin.showAll }
        });
    };
    const toggleUserHidden = () => {
        setFilters({
            ...filters,
            admin: {
                ...filters.admin,
                showUserHidden: !filters.admin.showUserHidden
            }
        });
    };

    return (
        <div>
            <Label>ADMIN</Label>
            <FilterWrap>
                Visible in the app only
                <Toggler
                    isActive={filters.admin.showAll}
                    onSelect={() => toggleShowAll()}
                    onDeselect={() => toggleShowAll()}
                />
            </FilterWrap>
            {filters.admin.showAll && (
                <FilterWrap>
                    Include user hidden media
                    <Toggler
                        isActive={filters.admin.showUserHidden}
                        onSelect={() => toggleUserHidden()}
                        onDeselect={() => toggleUserHidden()}
                    />
                </FilterWrap>
            )}
        </div>
    );
};

export default MediaFilterAdmin;
