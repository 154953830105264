import React from "react";
import DirectBuyDetailsMediaPreview from "../components/DirectBuyDetailsMediaPreview";
import styled from "styled-components";
import { BigOutlineButton } from "../../../../profile/components/Buttons";
import { colors } from "src/constants";

interface MediaPreviewContainerProps {
    height: number;
}

const MediaPreviewContainer = styled.div<MediaPreviewContainerProps>`
    padding-bottom: 10px;
    height: ${props => props.height}px;
`;
const MediaID = styled.div`
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: white;
    margin-bottom: -40px;
    z-index: 99;
    text-align: left;
    padding: 10px;
`;

interface Props {
    mediaPreviewHeight: number;
    url: string | null;
    coverPhotoUrl: string | null;
    type: string;
    time?: number;
    id?: number;
    previewCallback: VoidFunction;
    actionCallback: VoidFunction;
    hideDownload?: boolean;
}

const DirectBuyDetailsMediaCell = ({
    mediaPreviewHeight,
    url,
    coverPhotoUrl,
    type,
    time,
    id,
    previewCallback,
    actionCallback,
    hideDownload
}: Props) => {
    return (
        <>
            <MediaID>Media ID: {id}</MediaID>
            <MediaPreviewContainer height={mediaPreviewHeight}>
                <DirectBuyDetailsMediaPreview
                    imageUrl={coverPhotoUrl || ""}
                    centerImage={type === "video" ? "/play_icon.svg" : null}
                    time={time}
                    onClick={previewCallback}
                />
            </MediaPreviewContainer>
            {!hideDownload && (
                <BigOutlineButton
                    onClick={() => {
                        if (url) {
                            actionCallback();
                            window.open(url);
                        }
                    }}
                >
                    Download
                </BigOutlineButton>
            )}
        </>
    );
};

export default DirectBuyDetailsMediaCell;
