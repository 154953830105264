import React, { MutableRefObject, ReactElement, useRef, useState } from "react";
import { colors } from "src/constants";
import styled from "styled-components";
import { setScriptModalView } from "src/ui/scripts/actions";
import { ModalView } from "src/ui/scripts/actions";
import Tooltip from "src/components/Tooltip";
import { ChevronRight } from "src/components/icons/exports";
import { useDispatch } from "react-redux";
import { ReactComponent as PlusIcon } from "src/components/icons/plus.svg";

const Wrapper = styled.div`
    padding: 5px;
    height: 100%;
    position: relative;
    overflow: visible;
`;
const FlexWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 232px;
    width: 110px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid ${colors.mediumTeal};
    overflow: hidden;
    cursor: pointer;
`;
const BtnWrap = styled.div`
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const IconWrap = styled.div`
    border: 1px solid ${colors.mediumTeal};
    background-color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: ${colors.black};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s ease;
    margin-bottom: 6px;
    ${Wrapper}:hover & {
        color: ${colors.primaryBlue};
    }
`;
const Caption = styled.p`
    font-size: 0.9rem;
`;
const StyledTooltip = styled(Tooltip)`
    border-radius: 12px;
    width: 300px;
    overflow: hidden;
    flex-direction: column;
    align-items: stretch;
`;
const ToolTipBtn = styled.button`
    text-align: left;
    padding: 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    &:hover {
        background-color: ${colors.lightTeal};
    }
`;

interface Props {
    caption: string;
    children: ReactElement[] | ReactElement;
}

const AddCell = ({ caption, children }: Props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const handleTooltipOpen = () => {
        setTooltipOpen(() => !tooltipOpen);
    };
    const ref = useRef() as MutableRefObject<HTMLDivElement>;

    return (
        <Wrapper ref={ref} onClick={handleTooltipOpen}>
            <StyledTooltip
                controlRef={ref}
                close={() => setTooltipOpen(false)}
                isOpen={tooltipOpen}
                position={`top: 0px; left: 100px;`}
                zIndex={91}
            >
                {children}
            </StyledTooltip>
            <FlexWrap>
                <BtnWrap>
                    <IconWrap>
                        <PlusIcon />
                    </IconWrap>
                    <Caption>{caption}</Caption>
                </BtnWrap>
            </FlexWrap>
        </Wrapper>
    );
};

export const AddAssetCell = ({ caption }: { caption: string }) => {
    const dispatch = useDispatch();

    const handleClick = (viewTag: string) => {
        dispatch(setScriptModalView(viewTag));
    };
    return (
        <AddCell caption={caption}>
            <ToolTipBtn onClick={() => handleClick(ModalView.addAsset)}>
                Upload New Asset
                <ChevronRight />
            </ToolTipBtn>
            <ToolTipBtn onClick={() => handleClick(ModalView.selectAsset)}>
                Select Asset from the Plug
                <ChevronRight />
            </ToolTipBtn>
        </AddCell>
    );
};
export const AddMediaCell = ({ caption }: { caption: string }) => {
    const dispatch = useDispatch();

    const handleClick = (viewTag: string) => {
        dispatch(setScriptModalView(viewTag));
    };
    return (
        <AddCell caption={caption}>
            <ToolTipBtn onClick={() => handleClick(ModalView.addMedia)}>
                Upload New Media
                <ChevronRight />
            </ToolTipBtn>
            <ToolTipBtn onClick={() => handleClick(ModalView.selectMedia)}>
                Select Media from the Plug
                <ChevronRight />
            </ToolTipBtn>
        </AddCell>
    );
};

export default AddCell;
