export const cashoutMethods = [
    {
        tag: "NoPM",
        icon: "/progress_red/progress_red@2x.png"
    },
    {
        tag: "PayPal",
        icon: "/paypal/paypal@2x.png"
    },
    {
        tag: "WireTransfer",
        icon: "/wire/wire@2x.png"
    },
    {
        tag: "ACH",
        icon: "/ach/ach@2x.png"
    },
    {
        tag: "Check",
        icon: "/prepaid/prepaid@2x.png"
    },
    {
        tag: "eCheck",
        icon: "/prepaid/prepaid@2x.png"
    },
    {
        tag: "HoldMyPayments",
        icon: "/hold/hold@2x.png"
    }
];
