import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";
import {
    CampaignHit,
    GenericHit,
    PublisherHit,
    SocialAccountHit
} from "./types";

export const isCampaignHit = (hit: GenericHit): hit is CampaignHit => {
    return (hit as CampaignHit).rewards[0].reward !== undefined;
};

export const isPublisherHit = (hit: GenericHit): hit is PublisherHit => {
    return (hit as PublisherHit).username !== undefined;
};

export const isSocialAccountHit = (
    hit: GenericHit
): hit is SocialAccountHit => {
    return (hit as SocialAccountHit).platform !== undefined;
};

export const selectElasticSearchUI = (state: GlobalState, index: string) => {
    return state.ui.elasticSearch[index] || {};
};

export const selectHits = createSelector(
    (state: GlobalState) => state.elasticSearch,
    (state: GlobalState, index: string) => index,
    (searchState, index) => {
        const hits = searchState[index];
        if (hits && hits.length > 0) return searchState[index];
        else return [];
    }
);

export const selectFilteredSlicedHits = createSelector(
    (state: GlobalState) => state.elasticSearch,
    (state: GlobalState, index: string) => index,
    (searchState, index) => {
        const hits = searchState[index];
        if (hits && hits.length > 0) {
            if (index === "campaigns")
                return hits.filter(isCampaignHit).slice(0, 5);
            if (index === "publishers")
                return hits.filter(isPublisherHit).slice(0, 5);
            return hits.filter(isSocialAccountHit).slice(0, 5);
        }
        return [];
    }
);

export const selectPublisherHits = createSelector(
    (state: GlobalState) => state.elasticSearch,
    searchState => {
        const publisherState: PublisherHit[] = searchState.publishers?.filter(
            isPublisherHit
        );
        return publisherState && publisherState.length > 0
            ? publisherState
            : [];
    }
);
