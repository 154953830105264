import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { breakpoints, colors, fonts } from "src/constants";
import { resetFailures } from "src/failures/actions";
import { flexRowCenter } from "src/utils/styles/snippets";
import styled from "styled-components";

const Main = styled.main`
    ${flexRowCenter()};
    width: 100%;
    height: 100vh;
    padding: 0px 30px;
`;

const Content = styled.section`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px;
    justify-items: center;
`;

const Header = styled.article`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    justify-items: center;
`;

const Title = styled.h1`
    color: ${colors.black};
    font-size: 36px;
    font-weight: 700;
    line-height: 150%;

    @media (max-width: ${breakpoints.mobile}px) {
        font-size: ${fonts.title}px;
    }
`;

const Subtitle = styled.p`
    color: ${colors.primaryGray};
    font-size: 22px;
    font-weight: 400;
    line-height: 150%;

    @media (max-width: ${breakpoints.mobile}px) {
        font-size: 18px;
    }
`;

const LinkButtons = styled.article`
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 20px;
`;

const LinkButton = styled(Link)`
    ${flexRowCenter()};
    width: 300px;
    height: 60px;
    color: ${colors.white};
    background-color: ${colors.primaryBlue};
    border-radius: 10px;
    font-size: 22px;
    font-weight: 700;
    text-decoration: none;
    transition: 0.1s ease-in;
    cursor: pointer;

    :hover {
        color: ${colors.white};
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

const NotFoundPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetFailures());
    }, []);

    return (
        <Main>
            <Content>
                <Header>
                    <Title>
                        Uh oh, the page you're looking for does not exist!
                    </Title>
                    <Subtitle>You can check out these pages instead.</Subtitle>
                </Header>

                <LinkButtons>
                    <LinkButton
                        onClick={() => dispatch(resetFailures())}
                        to="/"
                    >
                        Go Home
                    </LinkButton>
                    <LinkButton
                        onClick={() => dispatch(resetFailures())}
                        to="/profile"
                    >
                        Profile
                    </LinkButton>
                </LinkButtons>
            </Content>
        </Main>
    );
};

export default NotFoundPage;
