export const applyApprovalTemplate = (message, publisher, challenge, type) => {
    switch (type) {
        case "revisionRequest":
            return `
Hey ${publisher || `Plug User`},
We are requesting the following revision for your submission in our ${challenge}:\n
${message}\n
Please take a moment to make these adjustments and resubmit directly from the app.
If you have any questions or need help resubmitting, reply here so we can help!
`;

        case "approve":
            return `
Hey ${publisher || `Plug User`},
Your submission was approved for ${challenge}.\n
${message}`;

        case "reject":
            return `
Hey ${publisher || `Plug User`},
Your submission in our ${challenge} has been rejected due to the following:\n
${message}`;
        default:
            return message;
    }
};
