import React from "react";
import styled from "styled-components";
import OutlineContainer from "../settings/components/OutlineContainer";
import { OutlineTextButton } from "../components/Buttons";
import { FlexVerticallyCentered } from "../../components/CommonStyles";
import { colors, fonts } from "../../constants";

const Main = styled(OutlineContainer)`
    padding: 12px;
    ${FlexVerticallyCentered}
    justify-content: space-between;
`;

const EditButton = styled(OutlineTextButton)`
    align-self: flex-start;
    font-size: 12px;
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px 4px 4px;

    & > * ~ * {
        margin-top: 4px;
    }
`;

const Title = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
`;

const Description = styled.div`
    font-size: ${fonts.smallSubtitle}px;
    font-weight: 400;
    line-height: 12px;
    color: ${colors.primaryGray};
`;

interface Props {
    title: string;
    description: string;
    edit: () => void;
}

const RateFormItem = ({ title, description, edit }: Props) => (
    <Main>
        <Text>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </Text>
        <EditButton onClick={edit}>Edit</EditButton>
    </Main>
);

export default RateFormItem;
