import { NormalizedAction } from "src/types";
import { PublisherFlag } from "../types";
import { FETCH_PUBLISHER_FLAGS } from "./actions";

export interface PublisherFlagsEntitiesState {
    [key: string]: PublisherFlag;
}

const initialPublisherFlagsState: PublisherFlagsEntitiesState = {};

const publisherFlagsEntitiesReducer = (
    state = initialPublisherFlagsState,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_PUBLISHER_FLAGS.SUCCESS:
            return { ...state, ...action.response.entities.publisherFlags };
        default:
            return state;
    }
};

export default publisherFlagsEntitiesReducer;
