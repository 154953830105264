import React from "react";
import { CustomLoading } from "src/components/SkeletonLoader";
import styled from "styled-components";
import { AssetImage } from "../types/AssetImage";

const Image = styled.img<{ height: number; width: number }>`
    max-width: 100%;
    height: auto;
    aspect-ratio: ${props => props.width / props.height};
`;

const Wrapper = styled(CustomLoading)`
    overflow: hidden;
`;

interface Props {
    asset?: AssetImage;
}

const MessageAsset = ({ asset }: Props) => {
    if (!asset) return null;
    if (asset.content.type === "Image")
        return (
            <Wrapper width="100%" height="100%">
                <Image
                    src={asset.url}
                    height={asset.height}
                    width={asset.width}
                    loading="lazy"
                />
            </Wrapper>
        );
    return null;
};
export default MessageAsset;
