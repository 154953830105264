import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import NormalPath from "../auth/normal";
import MagicPath from "../auth/magic";
import SocialAccountsRouter from "../social-accounts";
import RulesRouter from "../rules";
import LandingRouter from "./LandingRouter";
import Logout from "./Logout";
import { accountCompleted, loggedIn } from "../util";

import NewLayout from "src/wrappers/Layout";
import PublicBuysRouter from "src/buys/public/router";
import ShortLink from "src/ShortLink";
import FacebookRedirect from "src/auth/facebook/FacebookRedirect";
import DeletionInstructions from "src/auth/facebook/DeletionInstructions";
import TikTokVerificationRedirect from "src/auth/tiktok/TikTokVerificationRedirect";
import LoginAsSelf from "./LoginAsSelf";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInProfile, getPreApprovalCode } from "src/profile/selectors";
import { LayoutThemeType, setLayoutTheme } from "src/ui/layout/actions";
import { AccessStatuses } from "src/profile/types";
import { fetchPreApprovalCode, fetchUser } from "src/profile/actions";
import { setSMSToSModalOpen } from "src/ui/modals/actions";
import { colors } from "src/constants";
import { selectLayoutTheme } from "src/ui/layout/selectors";

const AppRouter = ({ history }: { history: any }) => {
    const profile = useSelector(getLoggedInProfile);
    const theme = useSelector(selectLayoutTheme);
    const preApprovalCode = useSelector(getPreApprovalCode);
    const dispatch = useDispatch();

    useEffect(() => {
        if (loggedIn() && !localStorage.getItem("userid")) {
            localStorage.clear();
            window.location.reload();
        }
        if (!profile) dispatch(fetchUser());
        dispatch(fetchPreApprovalCode());
        localStorage.removeItem("carouselOpen");

        const cachedTheme = localStorage.getItem("layoutTheme");
        dispatch(
            setLayoutTheme(
                cachedTheme === LayoutThemeType.dark
                    ? LayoutThemeType.dark
                    : LayoutThemeType.light
            )
        );
    }, []);

    useEffect(() => {
        //put routes that are accessible via non logged in/authed users here
        if (
            window.location.pathname.includes("/public") ||
            window.location.pathname.includes("/buys") ||
            window.location.pathname.includes("/onboarding") ||
            window.location.pathname.includes("/approved")
        )
            return;
        if (!loggedIn()) history.push("/onboarding");

        if (
            profile?.accessStatus === AccessStatuses.BLOCKED ||
            profile?.accessStatus === AccessStatuses.BLOCKED_ALL
        )
            history.push("/onboarding/blocked");
        if (!loggedIn()) history.push("/onboarding");
        if (profile?.accessStatus === AccessStatuses.PENDING) {
            history.push("/onboarding/verify");
        }
        if (profile && !profile.account_verified)
            history.push("/onboarding/verify");

        if (profile && !accountCompleted(profile, preApprovalCode))
            history.push("/onboarding/details");

        if (
            profile &&
            accountCompleted(profile, preApprovalCode) &&
            !profile.flags.find(
                flag => flag.name === "tosAgreement" && flag.value === "yes"
            )
        )
            dispatch(setSMSToSModalOpen(true));
    }, [profile]);

    return (
        <div
            style={{
                backgroundColor:
                    theme === LayoutThemeType.dark
                        ? colors.darkBackground
                        : "none",
                minHeight: "100vh",
                height: "100%"
            }}
        >
            <ConnectedRouter history={history}>
                <NewLayout>
                    <Switch>
                        <Route
                            path="/l/:code"
                            render={({ match }: { match: any }) => {
                                return <ShortLink code={match.params.code} />;
                            }}
                        />
                        <Route path="/onboarding" component={NormalPath} />
                        <Route path="/approved" component={MagicPath} />
                        <Route
                            path="/auth/instagram/instructions"
                            component={DeletionInstructions}
                        />
                        <Route
                            path="/auth/instagram/"
                            component={FacebookRedirect}
                        />
                        <Route
                            path="/auth/tiktok"
                            component={TikTokVerificationRedirect}
                        />
                        <Route path="/socialAccountVerified">
                            <Redirect to="profile/accounts" />
                        </Route>
                        <Route
                            path="/social-accounts"
                            component={SocialAccountsRouter}
                        />
                        <Route path="/rules" component={RulesRouter} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/login-as-self" component={LoginAsSelf} />
                        <Route
                            path="/public/buys"
                            component={PublicBuysRouter}
                        />
                        <Route path="/" component={LandingRouter} />
                    </Switch>
                </NewLayout>
            </ConnectedRouter>
        </div>
    );
};

export default AppRouter;
