import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";

export const getCreatorGroups = createSelector(
    (state: GlobalState) => state.entities.creatorGroups.byId,
    (state: GlobalState) => state.entities.creatorGroups.allIds,
    (creatorGroups, creatorGroupsIds) => {
        return creatorGroupsIds.map((id: number) => {
            return creatorGroups[id];
        });
    }
);

export const getCurrentCreatorGroup = createSelector(
    (state: GlobalState) => state.entities.creatorGroups.byId,
    (state: GlobalState) => state.ui.creatorGroups.currentCreatorGroupId,
    (creatorGroups, currentCreatorGroupId) => {
        if (currentCreatorGroupId) return creatorGroups[currentCreatorGroupId];
    }
);
