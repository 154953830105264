import { Action } from "src/types";

export const INITIALIZE_FIRESTORE_CHAT = "INITIALIZE_FIRESTORE_CHAT";

export function initializeFirestoreChat(): Action {
    return {
        type: INITIALIZE_FIRESTORE_CHAT,
        payload: null
    };
}
