import React from "react";
import { connect, useSelector } from "react-redux";
import { TextLoading } from "../../../components/SkeletonLoader";
import XCard from "../../../profile/components/XCard";
import { GlobalState } from "../../../reducers";
import { fetchCampaignScripts } from "../../scripts/actions";
import { getScripts } from "../../selectors";
import { Script } from "../../types";
import ScriptList from "../components/ScriptList";
import { List, StatusWrapper } from "../Details.styled";
import withAccordion from "./withAccordion";
import { selectAdminStatus } from "src/auth/selectors";
import ScriptCreationRow from "../components/ScriptCreationRow";
import styled from "styled-components";

const StyledScriptCreationRow = styled(ScriptCreationRow)`
    margin-top: 10px;
`;
interface Props {
    currentCampaign: number | null;
    fetchCampaignScripts: (campaignId: number) => void;
    scripts?: Script[];
    scriptsLoading: boolean;
}

const ScriptsAccordion = ({
    currentCampaign,
    fetchCampaignScripts,
    scripts,
    scriptsLoading
}: Props) => {
    const isAdmin = useSelector(selectAdminStatus);
    if (scriptsLoading)
        return (
            <List>
                <TextLoading height={56} />
                <TextLoading height={56} />
            </List>
        );

    if (!scripts)
        return (
            <StatusWrapper>
                <XCard
                    text="Failed to load script resources, click the button below to try again."
                    button={{
                        text: "Try again",
                        callback: () => {
                            currentCampaign &&
                                fetchCampaignScripts(currentCampaign);
                        }
                    }}
                />
            </StatusWrapper>
        );

    if (scripts.length)
        return <ScriptList scripts={scripts} isAdmin={isAdmin} />;

    return (
        <StatusWrapper>
            <XCard
                text="This campaign doesn’t have any script resources yet"
                button={{
                    text: "Try again",
                    callback: () => {
                        currentCampaign &&
                            fetchCampaignScripts(currentCampaign);
                    }
                }}
            />
            {isAdmin && <StyledScriptCreationRow />}
        </StatusWrapper>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    currentCampaign: state.ui.campaign.currentCampaignId,
    scriptsLoading: state.ui.campaign.scriptsLoading,
    scripts: getScripts(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchCampaignScripts: (campaignId: number) =>
        dispatch(fetchCampaignScripts(campaignId))
});

export default withAccordion(
    connect(mapStateToProps, mapDispatchToProps)(ScriptsAccordion)
);
