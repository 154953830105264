// All Placements --------------------------------------------------------------

import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../../reducers";
import { Action, NormalizedAction } from "../../types";

export const FETCH_PLACEMENTS = {
    REQUEST: "FETCH_PLACEMENTS_REQUEST",
    SUCCESS: "FETCH_PLACEMENTS_SUCCESS",
    FAILURE: "FETCH_PLACEMENTS_FAILURE"
};

export const fetchPlacements = (): Action => ({
    type: FETCH_PLACEMENTS.REQUEST,
    payload: null
});

export const fetchPlacementsSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>
): NormalizedAction => ({
    type: FETCH_PLACEMENTS.SUCCESS,
    response
});

export const fetchPlacementsFailure = (error: string): Action => ({
    type: FETCH_PLACEMENTS.FAILURE,
    payload: { error }
});
