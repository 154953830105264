import React, { useEffect } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../reducers";
import { TokenStatus } from "../reducer";
import { checkMagicToken } from "../actions";
import { push } from "connected-react-router";
import { getItem, getUrlParameter, loggedIn } from "../../util";
import PulseLoader from "../../components/PulseLoader";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { colors, fonts } from "../../constants";
import MainContainer from "src/components/MainContainer";

const Main = styled.div`
    height: 80vh;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

const Logo = styled.img`
    height: 50px;
    width: 50px;
    margin-bottom: 20px;
`;

const Name = styled.img`
    width: 100px;
    margin-bottom: 5px;
`;

const Subtitle = styled.div`
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: 300;
`;

const PositiveMessage = styled.div`
    margin: 30px 0;
`;

const NegativeMessage = styled.div`
    margin: 30px 0;
    color: red;
`;

interface Props {
    tokenStatus?: TokenStatus;
    checkMagicToken: (token: string) => void;
    push: (route: string) => void;
}

const Welcome = ({ tokenStatus, checkMagicToken, push }: Props) => {
    const isDev = getItem("devAccount");
    const token =
        isDev.onboardingType === "Magic Path"
            ? localStorage.getItem("magicToken")
            : getUrlParameter("token");

    useEffect(() => {
        if (token && token.length > 0) {
            checkMagicToken(token);
        }
    }, []);

    function renderMessage(status: TokenStatus) {
        if (status.valid) {
            return (
                <PositiveMessage>
                    Congrats! You've been pre-approved for a Plug account. Click
                    the button below and get paid.
                </PositiveMessage>
            );
        } else {
            return <NegativeMessage>{status.message}</NegativeMessage>;
        }
    }

    if (!token) {
        push("/onboarding");
        return null;
    } else if (loggedIn()) {
        push("/onboarding");
        return null;
    } else if (tokenStatus) {
        return (
            <MainContainer>
                <Main>
                    <Logo src={"/logo.svg"} />
                    <Name src={"/title.svg"} />
                    <Subtitle>Powered by JetFuel.it</Subtitle>
                    {renderMessage(tokenStatus)}
                    <Button
                        block
                        variant="primary"
                        onClick={() => {
                            if (tokenStatus.valid) {
                                push("/approved/phone");
                            } else {
                                push("/onboarding");
                            }
                        }}
                    >
                        {tokenStatus.valid
                            ? "Activate Account"
                            : "Complete Standard Application"}
                    </Button>
                </Main>
            </MainContainer>
        );
    } else {
        return (
            <Main>
                <PulseLoader />
            </Main>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    tokenStatus: state.auth.tokenStatus
});

const mapDispatchToProps = (dispatch: any) => ({
    checkMagicToken: (token: string) => dispatch(checkMagicToken(token)),
    push: (route: string) => dispatch(push(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
