import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { deleteReq, get, post } from "src/Api";
import PlugTable from "src/components/table/PlugTable";
import withModalPortal from "src/components/withModalPortal";
import ResponsiveModal from "src/modals/ResponsiveModal";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { EventInput } from "src/utils/types/form";
import { getCurrentOpenBuy } from "../selectors.admin-tools";
import * as Papa from "papaparse";
import { TableRow } from "src/components/table/types";
import { createCell, isNumberInputValid } from "src/components/table/utils";
import {
    clearButton,
    clearSpacing,
    grayBackgroundHoverState,
    lightGrayBackgroundHoverState
} from "src/utils/styles/snippets";
import { colors, fonts, fontWeight } from "src/constants";
import ChallengeFormSubSection from "src/marketplace/challenge-creation-form/components/ChallengeFormSubSection";
import MarketplaceInput from "src/marketplace/components/MarketplaceInput";
import { BlueButton } from "src/profile/components/Buttons";
import GlobalWarning from "src/components/GlobalWarning";

const Main = styled.main`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Header = styled.header`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Title = styled.h1`
    ${clearSpacing()};
    color: ${colors.black};
    font-size: ${fonts.semiTitle}px;
    font-weight: ${fontWeight.medium};
`;

const Subtitle = styled.h2`
    ${clearSpacing()}
		color: ${colors.primaryGray};
		font-size: ${fonts.smallTitle}px;
		font-weight: ${fontWeight.default};
`;

const IndividualPublisherInputGroup = styled.article`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const AddButton = styled(BlueButton)`
    padding: 8px 16px;
    font-size: ${fonts.smallTitle}px;
`;

const AssignmentRemovalTablesContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding-top: 12px;
    & > * {
        flex: 1;
    }
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const UploadCSVButton = styled.label`
    ${grayBackgroundHoverState()};
    align-self: flex-end;
    padding: 8px 12px;
    color: ${colors.black};
    border-radius: 6px;
    font-size: ${fonts.semiSmallTitle}px;
    font-weight: ${fontWeight.medium};
    cursor: pointer;
`;

const CSVInput = styled.input`
    display: none;
`;

interface Props {
    modalOpen: boolean;
    closeModal: () => void;
    allPublishers: boolean;
    setPublisherCount: (publisherCount: number) => void;
}

const PublisherAssignmentModal = ({
    modalOpen,
    closeModal,
    allPublishers,
    setPublisherCount
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const currentOpenBuy = useSelector(getCurrentOpenBuy);

    // Local State ---------------------------------------------------
    const [individualPublisherId, setIndividualPublisherId] = useState("");
    const [tableLoading, setTableLoading] = useState(true);
    const [stagedPublishers, setStagedPublishers] = useState<number[]>([]);
    const [blockedPublishers, setBlockedPublishers] = useState<
        {
            publisherId: number;
            Publisher: { firstname: string; lastname: string };
        }[]
    >([]);
    const [unassignWarning, setUnassignWarning] = useState(false);
    const [csvAdded, setCsvAdded] = useState(false);

    // Local Constants -----------------------------------------------
    const stagedPublishersTableData: TableRow[] = stagedPublishers.map(
        (pub, idx) => ({
            originalIdx: idx,
            raw: { publisher_id: pub },
            rowData: {
                publisher_id: createCell.number(pub),
                unstage: createCell.button(
                    () =>
                        setStagedPublishers(
                            stagedPublishers.filter(
                                stagedPub => pub !== stagedPub
                            )
                        ),
                    "Unstage"
                )
            }
        })
    );

    const blockedPublishersTableData: TableRow[] = blockedPublishers.map(
        (pub, idx) => ({
            originalIdx: idx,
            raw: pub,
            rowData: {
                publisher_id: createCell.number(pub.publisherId),
                name: createCell.string(
                    `${pub?.Publisher?.firstname} ${pub?.Publisher?.lastname}`,
                    {
                        isNull:
                            !pub?.Publisher?.firstname ||
                            !pub?.Publisher?.lastname
                    }
                ),
                action: createCell.button(
                    () => handleUnassignment(pub.publisherId),
                    allPublishers ? "Unblock" : "Unassign"
                )
            }
        })
    );

    // Local Functions -----------------------------------------------
    function getPublishers() {
        if (currentOpenBuy) {
            get(`/v1/openBuy/publisher/${currentOpenBuy.id}`, {
                isRemoved: allPublishers
            }).then((response: any) => {
                setTableLoading(false);
                setBlockedPublishers(response.data.data);
            });
        }
    }

    function addIndividualPublisher() {
        if (stagedPublishers.includes(parseInt(individualPublisherId))) {
            dispatch(
                setPillNotificationText(
                    `Publisher ID: ${individualPublisherId} already staged`,
                    "danger",
                    3500
                )
            );
        } else {
            setStagedPublishers([
                ...stagedPublishers,
                parseInt(individualPublisherId)
            ]);
            setIndividualPublisherId("");
        }
    }

    function handleAssignment() {
        if (currentOpenBuy) {
            const pubLinks = stagedPublishers.map(pub => ({
                publisherId: pub,
                openBuyId: currentOpenBuy.id,
                ...(allPublishers && { status: "removed" })
            }));
            post("/v1/openBuy/publisher", {
                openBuyPublisherLinks: pubLinks,
                isAsync: true
            })
                .then((response: any) => {
                    getPublishers();
                    setStagedPublishers([]);
                    setCsvAdded(false);
                    dispatch(
                        setPillNotificationText(
                            allPublishers
                                ? "Publishers Successfully Blocked"
                                : "Publishers Successfully Assigned",
                            "success",
                            3500
                        )
                    );
                })
                .catch((event: any) =>
                    dispatch(
                        setPillNotificationText(
                            allPublishers
                                ? "Publishers Failed To Be Blocked"
                                : "Publishers Failed To Be Assigned",
                            "danger",
                            3500
                        )
                    )
                );
        }
    }

    function handleUnassignment(pub: number) {
        if (currentOpenBuy) {
            deleteReq(`/v1/openBuy/${currentOpenBuy.id}/publisher/${pub}`, {})
                .then((response: any) => {
                    getPublishers();
                    dispatch(
                        setPillNotificationText(
                            `${
                                allPublishers ? "Unblocking" : "Unassignment"
                            } Successful`,
                            "success",
                            3500
                        )
                    );
                })
                .catch((event: any) =>
                    dispatch(
                        setPillNotificationText(
                            `${
                                allPublishers ? "Unblocking" : "Unassignment"
                            } Failed`,
                            "danger",
                            3500
                        )
                    )
                );
        }
    }

    function handleBulkUnassignment() {
        if (currentOpenBuy) {
            const pubIds = blockedPublishers.map(pub => pub.publisherId);
            deleteReq(`/v1/openBuy/${currentOpenBuy.id}/publisher/bulk`, {
                publisherIds: pubIds
            })
                .then((response: any) => {
                    getPublishers();
                    setUnassignWarning(false);
                    dispatch(
                        setPillNotificationText(
                            allPublishers
                                ? "Publishers Successfully Unblocked"
                                : "Publishers Successfully Unassigned",
                            "success",
                            3500
                        )
                    );
                })
                .catch((event: any) =>
                    dispatch(
                        setPillNotificationText(
                            allPublishers
                                ? "Publishers failed to be Unblocked"
                                : "Publishers failed to be Unassigned",
                            "danger",
                            3500
                        )
                    )
                );
        }
    }

    function handleCSVUpload(event: EventInput) {
        event.preventDefault();
        const files = event.currentTarget.files;
        if (files && files.length > 0) {
            const file = files[0];
            Papa.parse(file, {
                complete: (response: any) => {
                    if (response.data.length > 0) {
                        const csvFileColumns = response.data[0];
                        const publisherIdIndex =
                            csvFileColumns.indexOf("publisher_id") !== -1
                                ? csvFileColumns.indexOf("publisher_id")
                                : csvFileColumns.indexOf("publisherId");
                        const noPublisherIdColumn = publisherIdIndex === -1;
                        if (noPublisherIdColumn) {
                            dispatch(
                                setPillNotificationText(
                                    "CSV does not contain a publisher_id column",
                                    "danger",
                                    3500
                                )
                            );
                        } else {
                            const body = response.data.slice(1);
                            const publisherIds = body
                                .filter(
                                    (row: any) =>
                                        !!parseInt(row[publisherIdIndex])
                                )
                                .map((row: any) =>
                                    parseInt(row[publisherIdIndex])
                                ) as number[];
                            setStagedPublishers([
                                ...Array.from(
                                    new Set([
                                        ...stagedPublishers,
                                        ...publisherIds
                                    ])
                                )
                            ]);
                            setCsvAdded(true);
                        }
                    }
                }
            });
        }
    }

    // Custom Hooks --------------------------------------------------

    // Effects -------------------------------------------------------
    useEffect(() => {
        getPublishers();
        setTableLoading(true);
    }, [allPublishers, currentOpenBuy]);

    useEffect(() => {
        if (allPublishers) {
            setPublisherCount(blockedPublishersTableData.length);
        } else {
            setPublisherCount(blockedPublishersTableData.length);
        }
    }, [blockedPublishersTableData]);

    // JSX -----------------------------------------------------------
    return (
        <ResponsiveModal
            modalOpen={modalOpen}
            closeModal={closeModal}
            title="Publisher Assignment"
        >
            <Main>
                <Header>
                    <Title>
                        Review Publishers{" "}
                        {allPublishers ? "Blocked" : "Assigned"}{" "}
                        {allPublishers ? "From" : "To"} Challenge
                    </Title>
                    <Subtitle>
                        {allPublishers
                            ? "Challenge is open to all publishers. You can only block access to publishers."
                            : "Challenge is open to select publishers. Assign by individual ID or CSV."}
                    </Subtitle>
                </Header>

                <ChallengeFormSubSection title="ADD INDIVIDUAL PUBLISHER">
                    <IndividualPublisherInputGroup>
                        <MarketplaceInput
                            type="number"
                            value={individualPublisherId}
                            placeholder="Input Publisher ID"
                            onChange={(event: EventInput) =>
                                setIndividualPublisherId(
                                    event.currentTarget.value
                                )
                            }
                        />
                        <AddButton
                            type="button"
                            onClick={addIndividualPublisher}
                        >
                            Add
                        </AddButton>
                    </IndividualPublisherInputGroup>
                </ChallengeFormSubSection>

                <AssignmentRemovalTablesContainer>
                    {/* Assigning / Blocking */}
                    <TableContainer>
                        <PlugTable
                            title={
                                allPublishers
                                    ? "Publishers To Block"
                                    : "Publishers To Assign"
                            }
                            titleSize={fonts.subtitle}
                            columns={[
                                {
                                    coreKey: "publisher_id",
                                    displayValue: "Publisher ID",
                                    sortType: "number",
                                    searchable: true
                                },
                                {
                                    coreKey: "unstage",
                                    displayValue: "Unstage"
                                }
                            ]}
                            cellData={stagedPublishersTableData}
                            resultsPerPage={6}
                            tableButtons={[
                                {
                                    text: "Clear",
                                    color: "gray",
                                    onClick: () => {
                                        setStagedPublishers([]);
                                        setCsvAdded(false);
                                    }
                                },
                                {
                                    text: allPublishers ? "Block" : "Assign",
                                    color: allPublishers ? "red" : "blue",
                                    onClick: () => handleAssignment(),
                                    disabled:
                                        stagedPublishersTableData.length === 0
                                }
                            ]}
                        />
                    </TableContainer>
                    {/* Assigned / Blocked */}
                    <TableContainer>
                        <PlugTable
                            title={
                                allPublishers
                                    ? "Blocked Publishers"
                                    : "Assigned Publishers"
                            }
                            titleSize={fonts.subtitle}
                            columns={[
                                {
                                    coreKey: "publisher_id",
                                    displayValue: "Publisher ID",
                                    sortType: "number",
                                    searchable: true
                                },
                                {
                                    coreKey: "name",
                                    displayValue: "Publisher Name",
                                    sortType: "string",
                                    searchable: true
                                },
                                {
                                    coreKey: "action",
                                    displayValue: allPublishers
                                        ? "Unblock"
                                        : "Unassign"
                                }
                            ]}
                            cellData={blockedPublishersTableData}
                            resultsPerPage={6}
                            tableLoading={tableLoading}
                            refreshHandler={() => getPublishers()}
                            tableButtons={[
                                {
                                    text: allPublishers
                                        ? "Unblock All"
                                        : "Unassign All",
                                    color: "red",
                                    onClick: () => setUnassignWarning(true),
                                    disabled:
                                        blockedPublishersTableData.length === 0
                                }
                            ]}
                        />
                    </TableContainer>
                </AssignmentRemovalTablesContainer>

                <UploadCSVButton htmlFor="challenge-publisher-assignment-form-csv-upload">
                    <CSVInput
                        id="challenge-publisher-assignment-form-csv-upload"
                        type="file"
                        accept=".csv"
                        onChange={handleCSVUpload}
                    />
                    {csvAdded ? "Upload Another CSV" : "Upload CSV"}
                </UploadCSVButton>
            </Main>

            {unassignWarning && (
                <GlobalWarning
                    title={"Are you sure?"}
                    message={`Are you sure you want to ${
                        allPublishers ? "unblock" : "unassign"
                    } all publishers`}
                    handleCancel={() => setUnassignWarning(false)}
                    handleApply={handleBulkUnassignment}
                    btnText={{
                        cancel: "Cancel",
                        apply: allPublishers ? "Unblock All" : "Unassign All"
                    }}
                />
            )}
        </ResponsiveModal>
    );
};

export default withModalPortal(PublisherAssignmentModal);
