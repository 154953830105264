import { Action } from "src/types";
import { InstructionsSet, InstructionStep } from "./types";

// Fetch All Instructions Sets ---------------------------------------------------------------

export const FETCH_INSTRUCTIONS_SETS = {
    REQUEST: "FETCH_INSTRUCTIONS_SETS_REQUEST",
    SUCCESS: "FETCH_INSTRUCTIONS_SETS_SUCCESS",
    FAILURE: "FETCH_INSTRUCTIONS_SETS_FAILURE"
};

export function fetchInstructionsSets(
    campaignId: number,
    includeSteps: boolean
): Action {
    return {
        type: FETCH_INSTRUCTIONS_SETS.REQUEST,
        payload: { includeSteps, campaignId }
    };
}

export interface NormalizedInstructionSetsResponse {
    [id: number]: InstructionsSet;
}

export function fetchInstructionsSetsSuccess(
    instructionsSets: NormalizedInstructionSetsResponse
): Action {
    return {
        type: FETCH_INSTRUCTIONS_SETS.SUCCESS,
        payload: instructionsSets
    };
}

export function fetchInstructionsSetsFailure(error: string): Action {
    return {
        type: FETCH_INSTRUCTIONS_SETS.FAILURE,
        payload: error
    };
}

// Fetch An Instruction Set ---------------------------------------------------------------

export const FETCH_INSTRUCTIONS_SET = {
    REQUEST: "FETCH_INSTRUCTIONS_SET_REQUEST",
    SUCCESS: "FETCH_INSTRUCTIONS_SET_SUCCESS",
    FAILURE: "FETCH_INSTRUCTIONS_SET_FAILURE"
};

export function fetchInstructionsSet(
    instructionsSetId: number,
    includeSteps: boolean
): Action {
    return {
        type: FETCH_INSTRUCTIONS_SET.REQUEST,
        payload: { instructionsSetId, includeSteps }
    };
}

export function fetchInstructionsSetSuccess(
    instructionsSet: InstructionsSet
): Action {
    return {
        type: FETCH_INSTRUCTIONS_SET.SUCCESS,
        payload: instructionsSet
    };
}

export function fetchInstructionsSetFailure(error: string): Action {
    return {
        type: FETCH_INSTRUCTIONS_SET.FAILURE,
        payload: error
    };
}

// Create An Instruction Set ---------------------------------------------------------------

export const CREATE_INSTRUCTIONS_SET = {
    REQUEST: "CREATE_INSTRUCTIONS_SET_REQUEST",
    SUCCESS: "CREATE_INSTRUCTIONS_SET_SUCCESS",
    FAILURE: "CREATE_INSTRUCTIONS_SET_FAILURE"
};

export interface InstructionsSetCreateData {
    name: string;
    campaignId: number;
    advertiserId: number | null;
    duration: number | null;
    instructionSteps: InstructionStep[];
}

export function createInstructionsSet(
    data: InstructionsSetCreateData,
    isDuplicating?: boolean,
    openBuyId?: number
): Action {
    return {
        type: CREATE_INSTRUCTIONS_SET.REQUEST,
        payload: { data, isDuplicating, openBuyId }
    };
}

export function createInstructionsSetFailure(error: string): Action {
    return {
        type: CREATE_INSTRUCTIONS_SET.FAILURE,
        payload: error
    };
}

// Update An Instruction Set ---------------------------------------------------------------

export const UPDATE_INSTRUCTIONS_SET = {
    REQUEST: "UPDATE_INSTRUCTIONS_SET_REQUEST",
    SUCCESS: "UPDATE_INSTRUCTIONS_SET_SUCCESS",
    FAILURE: "UPDATE_INSTRUCTIONS_SET_FAILURE"
};

export function updateInstructionsSet(
    instructionsSetId: number,
    data: Partial<InstructionsSetCreateData>,
    instructionSteps: InstructionStep[]
): Action {
    return {
        type: UPDATE_INSTRUCTIONS_SET.REQUEST,
        payload: { instructionsSetId, data, instructionSteps }
    };
}

export function updateInstructionsSetSuccess(
    instructionsSetId: number,
    updatedData: InstructionsSetCreateData,
    instructionSteps: InstructionStep[]
): Action {
    return {
        type: UPDATE_INSTRUCTIONS_SET.SUCCESS,
        payload: { instructionsSetId, updatedData, instructionSteps }
    };
}

export function updateInstructionsSetFailure(error: string): Action {
    return {
        type: UPDATE_INSTRUCTIONS_SET.FAILURE,
        payload: error
    };
}

// Delete An Instruction Set ---------------------------------------------------------------

export const DELETE_INSTRUCTIONS_SET = {
    REQUEST: "DELETE_INSTRUCTIONS_SET_REQUEST",
    SUCCESS: "DELETE_INSTRUCTIONS_SET_SUCCESS",
    FAILURE: "DELETE_INSTRUCTIONS_SET_FAILURE"
};

export function deleteInstructionsSet(instructionsSetId: number): Action {
    return {
        type: DELETE_INSTRUCTIONS_SET.REQUEST,
        payload: instructionsSetId
    };
}

export function deleteInstructionsSetSuccess(
    instructionsSetId: number
): Action {
    return {
        type: DELETE_INSTRUCTIONS_SET.SUCCESS,
        payload: instructionsSetId
    };
}

export function deleteInstructionsSetFailure(error: string): Action {
    return {
        type: DELETE_INSTRUCTIONS_SET.FAILURE,
        payload: error
    };
}

// Reset Instruction Set ---------------------------------------------------------------
export const RESET_INSTRUCTION_SETS = "RESET_INSTRUCTION_SETS";
export function resetInstructionSets(): Action {
    return {
        type: RESET_INSTRUCTION_SETS,
        payload: null
    };
}
