import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { colors, shadows } from "src/constants";
import { useCloseModalWhenClickedOutside } from "src/utils/functions/hooks";
import { clearLink, preventUserInteraction } from "src/utils/styles/snippets";
import styled from "styled-components";

const Main = styled.div`
    position: relative;
`;

const MainDisplay = styled.div<{ open: boolean }>`
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 12px;
    background-color: ${props => {
        return props.open ? colors.secondaryLightGray : colors.white;
    }};
    border: ${props => {
        return props.open
            ? `${colors.tertiaryLightGray} solid 1px;`
            : `${colors.transparent} solid 1px;`;
    }};
    border-bottom: none;
    border-radius: ${props => (props.open ? "8px 8px 0px 0px" : "8px")};
    text-decoration: none;
    transition: 0.1s ease-in;
    cursor: pointer;

    :hover {
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.secondaryLightGray};
        }
    }
`;

const SymbolIcon = styled.img`
    width: 40px;
    height: 40px;
`;

interface LogoTextProps {
    isMarketplace: boolean;
}

const TextIcon = styled.img<LogoTextProps>`
    height: ${props => (props.isMarketplace ? 40 : 16)}px;
`;

const Arrow = styled.img<{ open: boolean }>`
    margin-left: 12px;
    transform: ${props => (props.open ? "rotate(360deg)" : "rotate(270deg)")};
    transition-duration: 0.1s;
`;

const Dropdown = styled.div<{ open: boolean }>`
    position: absolute;
    top: ${props => (props.open ? "100%" : "90%")};
    right: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    background-color: ${colors.white};
    border: ${colors.tertiaryLightGray} solid 1px;
    border-top: none;
    border-radius: 0px 0px 8px 8px;
    box-shadow: ${props => (props.open ? shadows.one : shadows.transparent)};
    opacity: ${props => (props.open ? "1" : "0")};
    transition: 0.1s ease-in;

    ${props => !props.open && preventUserInteraction()};
`;

const DropdownOption = styled(Link)<{ $isMarketplace: boolean }>`
    ${clearLink()};
    padding: 12px 8px;
    border-radius: 6px;
    color: ${props => {
        return props.$isMarketplace ? colors.primaryBlue : colors.black;
    }};
    transition: 0.1s ease-in;

    :hover {
        color: ${props => {
            return props.$isMarketplace ? colors.primaryBlue : colors.black;
        }};
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.secondaryLightGray};
        }
    }
`;

interface Props {
    isMarketplace: boolean;
    isAdminTools: boolean;
}

const NavbarDesktopLogoDropdown = ({ isMarketplace, isAdminTools }: Props) => {
    const mainRef = useRef(null);

    const [open, setOpen] = useState(false);

    function handleDropdownClick(): void {
        setOpen(!open);
    }

    function scrollToTop(): void {
        const onMainCampaignFeed = !isMarketplace;
        if (onMainCampaignFeed) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }
    }

    useCloseModalWhenClickedOutside(mainRef, open, () => setOpen(false), [
        open
    ]);

    return (
        <Main ref={mainRef} onClick={handleDropdownClick}>
            <MainDisplay open={open}>
                <SymbolIcon
                    src={
                        isMarketplace
                            ? isAdminTools
                                ? "/marketplace-logo-symbol.svg"
                                : "/marketplace-logo-symbol.svg"
                            : "/logo.svg"
                    }
                />
                <TextIcon
                    src={
                        isMarketplace
                            ? isAdminTools
                                ? "/admin-tools-logo-text.svg"
                                : "/marketplace-logo-text.svg"
                            : "/logo-text.svg"
                    }
                    isMarketplace={isMarketplace && !isAdminTools}
                />
                <Arrow src="/caret.svg" open={open} />
            </MainDisplay>

            <Dropdown open={open}>
                <DropdownOption
                    to="/"
                    onClick={scrollToTop}
                    $isMarketplace={!isMarketplace}
                >
                    The Plug
                </DropdownOption>
                {/* <DropdownOption
                    to="/marketplace/your-challenges"
                    $isMarketplace={isMarketplace && !isAdminTools}
                >
                    Marketplace
                </DropdownOption> */}
                <DropdownOption
                    to="/admin-tools/your-challenges"
                    $isMarketplace={isAdminTools}
                >
                    Admin Tools
                </DropdownOption>
            </Dropdown>
        </Main>
    );
};

export default NavbarDesktopLogoDropdown;
