import { FilterOption } from "src/components/table/hooks/useFilters";
import { TableColumn } from "src/components/table/types";

export const columns: TableColumn[] = [
    {
        coreKey: "username",
        displayValue: "Username",
        columnWidth: "150px",
        sortType: "string",
        searchable: true
    },
    {
        coreKey: "publisherId",
        hidden: true
    },
    {
        coreKey: "mediaId",
        displayValue: "Media",
        columnWidth: "160px",
        sortType: "string"
    },
    { coreKey: "mediaUrl", hidden: true },
    {
        coreKey: "platform",
        displayValue: "Platform",
        sortType: "string",
        columnWidth: "100px"
    },
    {
        coreKey: "views",
        displayValue: "Views",
        sortType: "number",
        columnWidth: "100px",
        defaultSort: "DESC"
    },
    {
        coreKey: "cost",
        displayValue: "Cost",
        sortType: "number",
        columnWidth: "68px"
    },
    {
        coreKey: "ecpm",
        displayValue: "eCPM",
        sortType: "number",
        columnWidth: "68px"
    },
    {
        coreKey: "likes",
        displayValue: "Likes",
        sortType: "number",
        columnWidth: "68px"
    },
    {
        coreKey: "comments",
        displayValue: "Comments",
        sortType: "number",
        columnWidth: "90px"
    }
];

export const filterOptions: FilterOption[] = [
    {
        coreKey: "status",
        inlineDisplayValue: "Status",
        type: "string",
        choices: [
            { valueToFilterBy: "approved", displayValue: "Approved" },
            { valueToFilterBy: "pending", displayValue: "Pending" },
            { valueToFilterBy: "rejected", displayValue: "Rejected" }
        ]
    },
    {
        coreKey: "views",
        inlineDisplayValue: "Views",
        type: "number",
        custom: true,
        choices: [
            { valueToFilterBy: [0, 50000], displayValue: "0 - 50K" },
            {
                valueToFilterBy: [50000, 100000],
                displayValue: "50K - 100K"
            },
            {
                valueToFilterBy: [100000, 250000],
                displayValue: "100K - 250K"
            },
            {
                valueToFilterBy: [250000, 500000],
                displayValue: "250K - 500K"
            },
            {
                valueToFilterBy: [500000],
                displayValue: "500K+"
            }
        ]
    },
    {
        coreKey: "platform",
        inlineDisplayValue: "Platform",
        type: "string",
        choices: [
            {
                valueToFilterBy: "tiktok",
                displayValue: "TikTok"
            },
            {
                valueToFilterBy: "instagram",
                displayValue: "Instagram"
            }
        ]
    },
    {
        coreKey: "comments",
        inlineDisplayValue: "Comments",
        type: "number",
        custom: true,
        choices: [
            { valueToFilterBy: [0, 25], displayValue: "0 - 25" },
            { valueToFilterBy: [25, 50], displayValue: "25 - 50" },
            { valueToFilterBy: [50, 100], displayValue: "50 - 100" },
            { valueToFilterBy: [100, 200], displayValue: "100 - 200" },
            { valueToFilterBy: [200, 500], displayValue: "200 - 500" },
            { valueToFilterBy: [500], displayValue: "500+" }
        ]
    },
    {
        coreKey: "likes",
        inlineDisplayValue: "Likes",
        type: "number",
        custom: true,
        choices: [
            { valueToFilterBy: [0, 250], displayValue: "0 - 250" },
            { valueToFilterBy: [250, 500], displayValue: "250 - 500" },
            { valueToFilterBy: [500, 2000], displayValue: "500 - 2,000" },
            {
                valueToFilterBy: [2000, 5000],
                displayValue: "2,000 - 5,000"
            },
            {
                valueToFilterBy: [5000, 10000],
                displayValue: "5,000 - 10,000"
            },
            { valueToFilterBy: [10000], displayValue: "10,000+" }
        ]
    },
    {
        coreKey: "cost",
        inlineDisplayValue: "Cost",
        type: "number",
        custom: true,
        choices: [
            { valueToFilterBy: [0, 50], displayValue: "$0 - $50" },
            { valueToFilterBy: [50, 100], displayValue: "$50 - $100" },
            { valueToFilterBy: [100, 300], displayValue: "$100 - $300" },
            { valueToFilterBy: [300, 500], displayValue: "$300 - $500" },
            { valueToFilterBy: [500, 1000], displayValue: "$500 - $1000" },
            { valueToFilterBy: [1000], displayValue: "$1000+" }
        ]
    },
    {
        coreKey: "ecpm",
        inlineDisplayValue: "eCPM",
        type: "number",
        custom: true,
        choices: [
            { valueToFilterBy: [0, 2.5], displayValue: "$0 - $2.50" },
            { valueToFilterBy: [2.5, 5], displayValue: "$2.50 - $5" },
            { valueToFilterBy: [5, 10], displayValue: "$5 - $10" },
            { valueToFilterBy: [10, 20], displayValue: "$10 - $20" },
            { valueToFilterBy: [20], displayValue: "$20+" }
        ]
    }
];
