import React from "react";
import { Connection } from "../../types";
import Header from "../../../components/Header";
import ConnectionsSection from "./ConnectionsSection";
import RecommendationRow from "./RecommendationRow";

interface Props {
    connections: Connection[];
    recommendations?: Connection[];
}

const ConnectionsPageTab = ({ connections, recommendations }: Props) => {
    return (
        <>
            <Header
                title={"Connections"}
                subtitle={`${connections.length} Influencers`}
            />
            <ConnectionsSection includeSearch connections={connections} />
            {recommendations && (
                <RecommendationRow wrapped recommendations={recommendations} />
            )}
        </>
    );
};

export default ConnectionsPageTab;
