export const getSubmissionHistoryReason = (
    eventName: string,
    updateObject: any
) => {
    switch (eventName) {
        case "open_buy_submission_revision_request":
            return updateObject.revisionRequest;
        case "open_buy_submission_rejection":
            return updateObject.rejectionReason;
        case "open_buy_submission_advertiser_review_request":
            return updateObject.advertiserReviewRequest;
        case "open_buy_submission_advertiser_revision_request":
        case "open_buy_submission_advertiser_approval":
        case "open_buy_submission_advertiser_rejection":
            return updateObject.advertiserMessage;
        default:
            return updateObject.reason;
    }
};

export const getSubmissionHistoryBodyType = (eventName: string) => {
    if (eventName.includes("scraper")) {
        return "scraper";
    } else if (eventName.includes("advertiser")) {
        return "advertiser";
    } else {
        return "admin";
    }
};


export const getHistoryType = (eventName: string) => {
    switch (eventName) {
        case "open_buy_submission_edit":
        case "scraper_queued":
        case "open_buy_submission_update_social_acccount":
            return "warning";

        case "open_buy_submission_revision_request":
        case "open_buy_submission_rejection":
        case "open_buy_submission_delete":
        case "open_buy_submission_advertiser_rejection":
        case "open_buy_submission_advertiser_revision_request":
        case "scraper_failure":
        case "scraper_flagged":
            return "danger";

        case "open_buy_submission_approval":
        case "open_buy_submission_advertiser_approval":
        case "scraper_success":
            return "success";

        case "open_buy_submission_advertiser_review_request":
            return "info";

        default:
            return "success";
    }
};

export const getHistoryTitle = (eventName: string, isFirst?: boolean) => {
    switch (eventName) {
        case "open_buy_submission_edit":
            return isFirst ? "Submission Created" : "Submission Edited";
        case "open_buy_submission_delete":
            return "Submission Deleted";
        case "open_buy_submission_approval":
            return "Submission Approved";
        case "open_buy_submission_rejection":
            return "Submission Rejected";
        case "open_buy_submission_revision_request":
            return "Revision Requested";
        case "scraper_queued":
            return "Scraper Run Pending";
        case "scraper_success":
            return "Scraper Successful";
        case "scraper_failure":
            return "Scraper Failed";
        case "scraper_flagged":
            return "Flagged";
        case "open_buy_submission_update_social_acccount":
            return "Submission Edited";
        case "open_buy_submission_advertiser_review_request":
            return "Advertiser Review Requested";
        case "open_buy_submission_advertiser_revision_request":
            return "Advertiser Revision Requested";
        case "open_buy_submission_advertiser_approval":
            return "Advertiser Approved";
        case "open_buy_submission_advertiser_rejection":
            return "Advertiser Rejected";
        default:
            return "";
    }
};