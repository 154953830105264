import React from "react";
import { Profile } from "../types";
import { ProfileTabState } from "../types";
import ConnectionsCard from "../connections/private/ConnectionsCard";
import AccountsCard from "../accounts/private/AccountsCard";
import PromotedCard from "../promoted/private/PromotedCard";
import CustomMediaCard from "../custom-media/private/CustomMediaCard";
import ReferralCard from "../bonus/referral/ReferralCard";
import Divider from "../../components/Divider";
import WeeklyCard from "../bonus/weekly/WeeklyCard";
import LinkListCard from "../link-lists/LinkListCard";

interface Props {
    profile: Profile;
    tabState: ProfileTabState;
}

const ProfileTabs = ({ profile, tabState }: Props) => {
    if (tabState === ProfileTabState.about) {
        return (
            <>
                <AccountsCard profile={profile} />
                <Divider />
                <ReferralCard />
                <Divider />
                <LinkListCard />
            </>
        );
    } else {
        return (
            <>
                <WeeklyCard />
            </>
        );
    }
};

export default ProfileTabs;
