import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAdminStatus } from "src/auth/selectors";
import PromoteMediaCarouselCell from "src/media/carousel/PromoteMediaCarouselCell";
import AdminMediaModal from "src/modals/AdminMediaModal";
import { setCurrentModalMedias } from "src/ui/medias/actions";

import CarouselModal from "src/modals/CarouselModalNew";
import { getCurrentModalMedias } from "../selectors";
import useResize from "src/hooks/useResize";
import { breakpoints } from "src/constants";

const MediaCarouselModal = () => {
    const mediaList = useSelector(getCurrentModalMedias);
    const dispatch = useDispatch();

    const isAdmin = useSelector(selectAdminStatus);
    const { viewWidth } = useResize(mediaList.length > 0);
    const isAdminAndDesktop = isAdmin && viewWidth > breakpoints.tablet;

    if (mediaList.length === 0) {
        return null;
    }
    return (
        <CarouselModal
            open={mediaList.length > 0}
            closeModal={() => dispatch(setCurrentModalMedias([]))}
            noMaxWidth={isAdminAndDesktop ? true : undefined}
            swipeable
            draggable
            arrows
            infinite
            isAdmin={isAdminAndDesktop}
        >
            {mediaList.map((media, idx) => {
                if (isAdminAndDesktop) {
                    return (
                        <AdminMediaModal
                            key={`${media.campaignId}-${media.id}`}
                            media={media}
                        />
                    );
                } else {
                    return (
                        <PromoteMediaCarouselCell
                            key={`${media.campaignId}-${media.id}`}
                            media={media}
                            closeModal={() =>
                                dispatch(setCurrentModalMedias([]))
                            }
                        />
                    );
                }
            })}
        </CarouselModal>
    );
};

export default MediaCarouselModal;
