import { go, goBack, push } from "connected-react-router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import NavButton from "src/components/NavButton";
import SimpleNavbar from "src/components/SimpleNavbar";
import { GlobalState } from "src/reducers";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import { LinkList } from "../types";
import { CopyButton, OutlineButton } from "../components/Buttons";
import {
    CircleImageLoader,
    InfoLoader,
    LinkItemListLoader
} from "src/components/SkeletonLoader";
import { setLinkListError } from "src/ui/link-lists/actions";
import { Redirect, useLocation } from "react-router";
import LinkListEdit from "./components/LinkListEdit";

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    padding: 18px 0 18px 0;
    gap: 13px;
    width: calc(100% - 36px);
    max-width: 400px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    margin: auto;
`;

const Button = styled(OutlineButton)`
    padding: 20px;
    box-sizing: border-box;
    height: 48px;
    width: 100%;
`;

const Copy = styled(CopyButton)`
    width: 100%;
    height: 48px;
`;

const LinkListDetails = ({ id }: { id: string }) => {
    const dispatch = useDispatch();
    const [list, setList] = useState<LinkList | null | undefined>(undefined);
    const linkList = useSelector(
        (state: GlobalState) => state.entities.linkLists.ListById[id]
    );
    const loading = useSelector(
        (state: GlobalState) => state.ui.linkList.loading
    );
    const location: any = useLocation();
    const prevLocation: string | undefined = location.state?.prev;

    useEffect(() => setList(linkList), [linkList]);

    // When the :id  param is not a number.
    if (!parseInt(id)) {
        return <Redirect to="/not-found" />;
    } else if (loading || list === undefined)
        return (
            <>
                <SimpleNavbar
                    title={"Link List"}
                    left={
                        <NavButton
                            back
                            onClick={() => {
                                dispatch(setLinkListError(null));
                                dispatch(goBack());
                            }}
                        />
                    }
                />
                <Container>
                    <CircleImageLoader className="shimmer" />
                    <InfoLoader className="shimmer" />
                    <ButtonRow>
                        <Button>...</Button>
                        <Copy>...</Copy>
                    </ButtonRow>
                    <LinkItemListLoader />
                </Container>
            </>
        );
    if (!list)
        return (
            <div>
                <SimpleNavbar
                    title={"Link List"}
                    left={
                        <NavButton
                            back
                            onClick={() => {
                                dispatch(setLinkListError(null));
                                dispatch(goBack());
                            }}
                        />
                    }
                />
                <div style={{ color: "red", marginTop: 40 }}>
                    No Link List found
                </div>
            </div>
        );

    return <LinkListEdit list={list} linkList={linkList} />;
};

export default LinkListDetails;
