import React, { ChangeEventHandler, ReactChildren, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { colors } from "src/constants";
import { BlueButton } from "src/profile/components/Buttons";
import { addNotification } from "src/ui/notifications/actions";
import styled from "styled-components";

const Wrapper = styled.div`
    border-radius: 8px;
    border: 1px solid ${colors.blueGrey2};
    display: flex;
    align-items: center;
    padding: 8px 12px;
    overflow: hidden;
    width: 100%;
`;
const TextWrap = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-grow: 4;
    font-size: 12px;
`;
const CopyBtn = styled(BlueButton)`
    min-width: 60px;
    height: 32px;
    margin-left: 4px;
    display: grid;
    place-items: center;
`;

const BlankInput = styled.input`
    border: none;
    outline: none;
    flex-grow: 4;
    font-size: 12px;
    background: none;
`;

const CopyTextWrap = ({
    text,
    className,
    onEdit
}: {
    text: string;
    className?: string;
    onEdit?: (v: string) => void;
}) => {
    const dispatch = useDispatch();
    const [editing, setEditing] = useState<null | string>(null);
    if (editing !== null)
        return (
            <Wrapper className={className}>
                <BlankInput
                    autoFocus
                    value={editing}
                    onChange={e => setEditing(e.currentTarget.value)}
                />
                <CopyBtn onClick={() => onEdit && onEdit(editing)}>
                    Save
                </CopyBtn>
                <CopyBtn
                    style={{ backgroundColor: colors.grayBlack }}
                    onClick={() => setEditing(null)}
                >
                    Cancel
                </CopyBtn>
            </Wrapper>
        );
    else
        return (
            <Wrapper className={className}>
                <TextWrap>{text}</TextWrap>

                <CopyToClipboard
                    text={text}
                    onCopy={() =>
                        dispatch(
                            addNotification(`Text succesfully copied: ${text}`)
                        )
                    }
                >
                    <CopyBtn>Copy</CopyBtn>
                </CopyToClipboard>
                {onEdit && (
                    <CopyBtn onClick={() => setEditing(text)}>Edit</CopyBtn>
                )}
            </Wrapper>
        );
};

export default CopyTextWrap;
