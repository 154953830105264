import React from "react";
import styled from "styled-components";
import CampaignReward from "src/campaigns/details/components/CampaignReward";
import { colors } from "src/constants";
import { Campaign, CountryPlatformReward } from "src/campaigns/types";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;
const Img = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 12px;
    margin-right: 12px;
`;
const Title = styled.h3`
    font-size: 0.9rem;
    text-align: left;
`;
interface CustomCampaign {
    imageUrl: string;
    campaignName: string;
    countryPlatformReward: CountryPlatformReward;
    rewardType: string;
}
const ModalCampaignTitle = ({
    campaign,
    showPrice = true,
    className
}: {
    campaign: Campaign | CustomCampaign;
    showPrice?: boolean;
    className?: string;
}) => {
    return (
        <Wrapper className={className}>
            <Img src={campaign?.imageUrl} />
            <div>
                <Title>{campaign?.campaignName}</Title>
                {showPrice && (
                    <CampaignReward
                        countryPlatformReward={campaign?.countryPlatformReward}
                        rewardType={campaign?.rewardType || ""}
                    />
                )}
            </div>
        </Wrapper>
    );
};

export default ModalCampaignTitle;
