import React from "react";
import { Route, Switch } from "react-router";
import RulesList from "./RulesList";
import RulesExamples from "./RulesExamples";

const Rules = ({ push }: { push: (path: string) => void }) => {
    return (
        <Switch>
            <Route path={"/rules/examples"} exact component={RulesExamples} />
            <Route path={"/rules"} component={RulesList} />
        </Switch>
    );
};

export default Rules;
