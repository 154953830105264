import React from "react";
import styled from "styled-components";
import { colors, fonts } from "src/constants";

const Main = styled.div<{ color: string }>`
    padding: 4px 6px;
    border-radius: 2px;
    background-color: ${props => props.color};
    font-size: ${fonts.smallSubtitle}px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 12px;
`;

interface Props {
    // status: false | "approved" | "rejected" | "pending";
    status: string | null;
}

const MediaStatus = ({ status }: Props) => {
    function renderColor() {
        switch (status) {
            case "approved":
                return colors.primaryBlue;
            case "pending":
                return colors.primaryYellow;
            default:
                return colors.primaryRed;
        }
    }

    if (!status) return null;

    return <Main color={renderColor()}>{status}</Main>;
};

export default MediaStatus;
