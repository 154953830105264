import React from "react";
import styled from "styled-components";
import MediaCard from "../../../media/view/MediaCard";
import { Asset } from "../../types";

const Main = styled.div`
    padding: 5px;
`;

interface Props {
    asset: Asset;
    onMediaClick: () => void;
    isScriptModal?: boolean;
}

const AssetCell = ({ asset, onMediaClick, isScriptModal }: Props) => (
    <Main>
        <MediaCard
            media={{
                ...asset,
                isPortrait: true,
                coverPhoto: asset.coverPhotoUrl,
                key: ""
            }}
            linkPrefix=""
            mediaCallback={onMediaClick}
            buttonCallbacks={{
                linkCallback: () => {}
            }}
            buttonHideLink
            isScriptModal={isScriptModal}
        />
    </Main>
);

export default AssetCell;
