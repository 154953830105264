import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchTag from "src/components/SearchTag";
import { setMediaFilters } from "src/pagination/media/actions";
import { selectMediaFilters } from "../selectors";

const MediaTypeTags = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectMediaFilters);

    function toggleOtherType() {
        dispatch(
            setMediaFilters({ ...filters, type: { photo: true, video: true } })
        );
    }

    if (filters.type.photo && filters.type.video) return null;

    return (
        <>
            {filters.type.photo && (
                <SearchTag
                    color="black"
                    iconSrc="/tag-black-close-icon.svg"
                    text="Image"
                    onClick={toggleOtherType}
                />
            )}
            {filters.type.video && (
                <SearchTag
                    color="black"
                    iconSrc="/tag-black-close-icon.svg"
                    text="Video"
                    onClick={toggleOtherType}
                />
            )}
        </>
    );
};

export default MediaTypeTags;
