import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../../../constants";
import { InstallHistory, RewardData } from "../../types";
import { coinToDollars } from "src/util";

const ChartBody = styled.div`
    padding-left: 15px;
    box-sizing: border-box;
    display: flex;
`;

const InstallColumn = styled.div`
    position: relative;
    background-color: ${colors.lightTeal};
    border: 1px solid ${colors.mediumTeal};
    border-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 34%;
`;

const InstallColumnNub = styled.div`
    position: absolute;
    bottom: -10px;
    left: -1px;
    right: -1px;
    height: 10px;
    background-color: ${colors.lightTeal};
    border: 1px solid ${colors.mediumTeal};
    border-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

const ChartColumn = styled.div`
    width: 22%;
    border-top: 1px solid transparent;
`;

const ChartSpacer = styled.div`
    width: 15px;
    border-top: 1px solid transparent;
`;

const SmallLogo = styled.img`
    height: 24px;
    width: 24px;
`;

const Cell = styled.div`
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
    font-size: ${fonts.subtitle}px;
`;

const InstallTitle = styled(Cell)`
    color: ${colors.secondaryGreen};
    font-weight: 500;
    padding: 5px;
`;

const InstallCell = styled(Cell)`
    margin: 0 15px;
    border-top: 1px solid ${colors.mediumTeal};
`;

const ChartTitle = styled(Cell)`
    color: ${colors.primaryGray};
    font-size: 11px;
    font-weight: 300;
`;

const ChartCell = styled(Cell)`
    border-top: 1px solid ${colors.mediumTeal};
`;

const PlatformIcon = styled.img`
    height: 24px;
    width: 24px;
    opacity: 0.5;
`;

const CountriesLink = styled.div`
    color: ${colors.primaryBlue};
    cursor: pointer;
`;

const DollarCell = styled.div`
    color: ${colors.secondaryGreen};
    font-weight: 600;
`;

interface Props {
    // rewardData: RewardData[];
    rewardData: InstallHistory[];
    countriesModal: (countries: string[]) => void;
}

const CampaignDetailsChartBody = ({ rewardData, countriesModal }: Props) => {
    return (
        <ChartBody>
            <InstallColumn>
                <InstallTitle>Your Install History</InstallTitle>
                {rewardData.map((data, idx) => {
                    return (
                        <InstallCell key={idx}>
                            {Math.round(parseFloat(data.percent))}%
                        </InstallCell>
                    );
                })}
                <InstallColumnNub />
            </InstallColumn>
            <ChartColumn>
                <Cell>{<DollarCell>$</DollarCell>}</Cell>
                {rewardData.map((data, idx) => {
                    return (
                        <ChartCell key={idx}>
                            {coinToDollars(data.reward)}
                        </ChartCell>
                    );
                })}
            </ChartColumn>
            <ChartColumn>
                <ChartTitle>Device</ChartTitle>
                {rewardData.map((data, idx) => {
                    return (
                        <ChartCell key={idx}>
                            <PlatformIcon
                                src={
                                    data.platform === "android"
                                        ? "/android_logo.svg"
                                        : "/apple_logo.svg"
                                }
                            />
                        </ChartCell>
                    );
                })}
            </ChartColumn>
            <ChartColumn>
                <ChartTitle>Countries</ChartTitle>
                {rewardData.map((data, idx) => {
                    if (data.countries.length > 1) {
                        return (
                            <ChartCell
                                key={idx}
                                onClick={() => countriesModal(data.countries)}
                            >
                                <CountriesLink>
                                    +{data.countries.length}
                                </CountriesLink>
                            </ChartCell>
                        );
                    } else {
                        return (
                            <ChartCell key={idx}>{data.countries[0]}</ChartCell>
                        );
                    }
                })}
            </ChartColumn>
            <ChartSpacer>
                <Cell />
                {rewardData.map((data, idx) => (
                    <ChartCell key={idx} />
                ))}
            </ChartSpacer>
        </ChartBody>
    );
};

export default CampaignDetailsChartBody;
