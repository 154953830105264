import React, { ReactNode } from "react";
import { push as routerPush } from "connected-react-router";
import styled from "styled-components";
import AccountIcon from "./AccountIcon";
import { breakpoints, colors, fonts } from "../../../constants";
import { shortenNumber } from "../../../util";
import { PushAccountParams } from "../../actions";
import Tag from "../../../components/Tag";
import HideOverflow from "../../../components/HideOverflow";
import { SocialAccount } from "../../types";
import VerticallyCentered from "../../../components/VerticallyCentered";
import Divider from "../../../components/Divider";
import VisibilityRow from "./VisibilityRow";
import VisibilitySmallIcon from "./VisibilitySmallIcon";
import { flex } from "src/utils/styles/snippets";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { getRatesForSocialAccount } from "src/profile/rates/selectors";
import RateItem from "src/profile/rates/RateItem";
import { BigOutlineButton } from "src/profile/components/Buttons";
import RateInfo from "./RateInfo";
import { TextEllipsis } from "src/components/CommonStyles";
import {
    rateFormModalOpen,
    setAccountId,
    setIsRateFormEdit
} from "src/ui/profile/actions";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const Main = styled("div")`
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 6px;
    transition: 0.1s ease-in;
    cursor: pointer;

    & + & {
        margin-top: 10px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
            border: ${colors.darkTeal} solid 1px;
        }
    }
`;

const Section = styled.div<{ clickable: boolean }>`
    ${flex("row", "space-between", "center")};
    padding: 12px 20px 12px 12px;
    ${props => props.clickable && "cursor: pointer;"};
`;

const TopLeftContainer = styled.div`
    display: flex;
    min-width: 0;
    flex: 1;
    margin-right: 10px;
`;

const AccountInfo = styled("div")`
    ${flex("column", "center", "auto")};
    width: calc(100% - 70px);
    height: 56px;
    font-weight: 500;
    text-align: left;
    flex: 1;
    min-width: 0;
`;

const Title = styled(HideOverflow)`
    ${flex("row", "auto", "center")};
    margin-bottom: 2px;
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;

    svg {
        margin-left: 4px;
    }
`;

const Username = styled.div`
    ${TextEllipsis}
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;
`;

const AccountTheme = styled.div`
    display: flex;
    overflow: hidden;
`;

const Subtitle = styled(HideOverflow)`
    margin-bottom: 4px;
    color: ${colors.primaryGray};
    font-size: ${fonts.smallSubtitle}px;
    line-height: 12px;
`;

const Verified = styled.img`
    min-width: 20px;
`;

const RatesList = styled.div`
    padding: 0 12px 12px;

    & > * ~ * {
        margin-top: 10px;
    }
`;

const AddRatesButton = styled(BigOutlineButton)`
    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray};
            border: ${colors.darkTeal} solid 1px;
        }
    }
`;

const ColorWrapper = styled.div<{ color: string }>`
    border-radius: 8px;
    background-color: ${({ color }) => color};
    & + & {
        margin-top: 10px;
    }
`;

interface Props {
    account?: SocialAccount;
    push?: (route: string) => void;
    pushWithAccount?: (data: PushAccountParams) => void;
    privacyCallback?: VoidFunction;
    privacyIcon?: true;
    action?: ReactNode;
    onClick?: () => void;
    showRates?: true;
}

const AccountCell = ({
    account,
    push,
    pushWithAccount,
    privacyCallback,
    onClick,
    privacyIcon,
    action,
    showRates
}: Props) => {
    const status = useSelector(
        (state: GlobalState) =>
            state.entities.socialAccounts.statuses[account?.id || 0]
    );
    const rates = useSelector((state: GlobalState) =>
        getRatesForSocialAccount(state, account?.id)
    );
    const dispatch = useDispatch();

    const splitAccountRates = useSelector(
        selectFeatureFlags(featureFlags.AccountRates)
    );

    if (!account) return null;

    const followerCount = `${shortenNumber(
        parseInt(account.followers),
        1
    )} Followers`;

    function openRateEdit(): void {
        dispatch(rateFormModalOpen(true));
        dispatch(setIsRateFormEdit(false));
        dispatch(setAccountId(account?.id || null));
    }

    const statusColor = () => {
        if (account?.verified) return colors.primaryBlue;
        switch (status?.includes("Error:") ? "failure" : status) {
            case "pending":
                return colors.primaryYellow;
            case "failure":
                return colors.primaryRed;
            default:
                return colors.gray2;
        }
    };

    const statusIcon = () => {
        if (account?.verified) return "/verified_account.svg";
        switch (status?.includes("Error:") ? "failure" : status) {
            case "pending":
                return "/yellow-pending-outline.svg";
            case "failure":
                return "/alert_badge.svg";
            default:
                return "/unverified-account-shield.svg";
        }
    };

    return (
        <ColorWrapper
            color={
                account.visibility === "connections"
                    ? colors.primaryYellow
                    : statusColor()
            }
        >
            <Main>
                <Section
                    clickable={Boolean(pushWithAccount || push)}
                    onClick={() => {
                        if (onClick) {
                            onClick();
                        } else if (pushWithAccount) {
                            pushWithAccount({
                                account,
                                path: "/profile/accounts/view"
                            });
                        } else if (push) {
                            const otherId = account.publisher.id.slice(2);
                            const path = `/public/${otherId}/accounts/v/${account.id}`;
                            push(path);
                        }
                    }}
                >
                    <TopLeftContainer>
                        <AccountIcon
                            platform={account.platform}
                            avatar={account.avatar}
                            verified={account.verified}
                        />
                        <AccountInfo>
                            <Title>
                                <Username>@{account.username}</Username>
                                {privacyIcon && (
                                    <VisibilitySmallIcon
                                        visibility={account.visibility}
                                        active={false}
                                    />
                                )}
                            </Title>
                            <Subtitle>{followerCount || "Unknown"}</Subtitle>
                            {account.theme && (
                                <AccountTheme>
                                    <Tag capitalize text={account.theme} />
                                </AccountTheme>
                            )}
                        </AccountInfo>
                    </TopLeftContainer>
                    {action ? action : <Verified src={statusIcon()} />}
                </Section>

                {splitAccountRates && account.verified && showRates && (
                    <RatesList>
                        {rates.map(rate => (
                            <RateItem
                                key={rate.id}
                                publisherOffer={rate}
                                accountId={account.id}
                            />
                        ))}
                        <AddRatesButton
                            onClick={() => {
                                if (pushWithAccount) {
                                    pushWithAccount({
                                        account,
                                        path: "/profile/accounts/rates"
                                    });
                                }
                            }}
                        >
                            Add Rates
                        </AddRatesButton>
                    </RatesList>
                )}
                {privacyCallback && (
                    <>
                        <Divider />
                        <Section clickable={true} onClick={privacyCallback}>
                            <VisibilityRow visibility={account.visibility} />
                        </Section>
                    </>
                )}
            </Main>
            {splitAccountRates && showRates && <RateInfo account={account} />}
        </ColorWrapper>
    );
};

export default AccountCell;
