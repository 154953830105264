import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import SearchBar from "src/components/SearchBar";
import SearchTag from "src/components/SearchTag";
import { colors } from "src/constants";
import { GlobalState } from "src/reducers";
import { responsiveContainer } from "src/utils/styles/snippets";
import styled from "styled-components";
import MediaCampaignTags from "./MediaCampaignTags";
import MediaDurationTags from "./MediaDurationTags";
import MediaOwnerStatusTags from "./MediaOwnerStatusTags";
import MediaPlacementTags from "./MediaPlacementTags";
import MediaTimeTags from "./MediaTimeTags";
import MediaTypeTags from "./MediaTypeTags";
import { isScriptModalOpen } from "src/campaigns/scripts/selectors";
import { debounce } from "src/util";
import { setMediaSearchString } from "src/pagination/media/actions";
import MediaAdminTags from "./MediaAdminTags";
import { selectAdminStatus } from "src/auth/selectors";

const StickySearch = styled.div<{ isScriptModal: boolean }>`
    ${responsiveContainer()};
    position: sticky;
    z-index: 998;
    top: 57px;
    padding: 10px 18px 4px;
    background-color: ${colors.white};
    ${props =>
        props.isScriptModal &&
        `
        top: 0px;
        width: 100%;
        max-width: 100%;
        padding: 0px; 
        padding-top: 2px;
        margin: 0px;   
    `}
`;

const StyledSearchBar = styled(SearchBar)`
    padding: 0px 2px 10px;
`;

const SearchTags = styled.div`
    ${FlexVerticallyCentered};
    flex-wrap: wrap;
    overflow-x: auto;
    position: sticky;
    top: 0;
`;

interface Props {
    sortModalOpen: () => void;
    filterModalOpen: () => void;
    isSearchable?: boolean;
}

const MediaSearchHeader = ({
    sortModalOpen,
    filterModalOpen,
    isSearchable
}: Props) => {
    const dispatch = useDispatch();
    const { sortBy } = useSelector(
        (state: GlobalState) => state.pagination.medias
    );

    const isAdmin = useSelector(selectAdminStatus);
    const isScriptModal = useSelector(isScriptModalOpen);

    const [searchString, updateSearchString] = useState("");

    const delayedDispatch = useCallback(
        debounce(
            (userInput: string) => dispatch(setMediaSearchString(userInput)),
            800
        ),
        []
    );
    useEffect(() => {
        return () => {
            dispatch(setMediaSearchString(""));
        };
    }, []);
    const updateSearchAndDispatch = (userInput: string) => {
        updateSearchString(userInput);
        delayedDispatch(userInput);
    };

    return (
        <StickySearch isScriptModal={isScriptModal}>
            {isSearchable && (
                <StyledSearchBar
                    searchString={searchString}
                    updateSearchString={updateSearchAndDispatch}
                    placeholder="Search by ID"
                />
            )}
            <SearchTags>
                <SearchTag
                    text={sortBy}
                    color="white"
                    iconSrc="/tag-teal-arrow-icon.svg"
                    onClick={sortModalOpen}
                />
                <SearchTag
                    text="Filters"
                    color="white"
                    iconSrc="/tag-teal-plus-icon.svg"
                    onClick={filterModalOpen}
                />
                <MediaCampaignTags />
                <MediaOwnerStatusTags />
                <MediaPlacementTags />
                <MediaTimeTags />
                <MediaDurationTags />
                <MediaTypeTags />
                {isAdmin && <MediaAdminTags />}
            </SearchTags>
        </StickySearch>
    );
};

export default MediaSearchHeader;
