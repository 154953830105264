import React from "react";
import { useSelector } from "react-redux";
import UploadStatusModal from "src/campaigns/promote/UploadStatusModal";
import CountriesModal from "../campaigns/details/modals/CountriesModal";
import PlugLinkModal from "../campaigns/modals/PlugLinkModal";
import { CampaignsModal } from "src/ui/modals/reducers";
import AssetCarouselModal from "../campaigns/assets/modals/AssetCaroutselModal";
import HighlightCarouselModal from "../campaigns/highlights/modals/HighlightCarouselModal";
import MediaCarouselModal from "../campaigns/medias/modals/MediaCarouselModal";
import { GlobalState } from "../reducers";
import StartPromotingModal from "./StartPromotingModal";
import BidIncreaseNotificationModal from "src/campaigns/budgets-bids/BidIncreaseNotificationModal";
import DownloadTypesModal from "src/campaigns/highlights/modals/DownloadTypesModal";
import MediaItemModal from "src/campaigns/landing/components/MediaItemModal";
import AccountViewModal from "src/profile/accounts/private/modals/AccountViewModal";
import AccountFormModalController from "src/profile/accounts/private/modals/AccountFormModalController";
import ProfileUploadModal from "src/profile/modals/ProfileUploadModal";
import InstructionElementsCarouselModal from "src/marketplace/instructions-sets/modals/InstructionElementsCarouselModal";
import MediaRulesModal from "./MediaRulesModal";
import ToSModal from "./ToSModal";

const GlobalModals = () => {
    const modal = useSelector(
        (state: GlobalState): CampaignsModal => state.ui.modals.modal
    );

    const downloadTypesModal = useSelector((state: GlobalState) => {
        return state.ui.highlights.downloadTypesModal;
    });

    const mediaItemModalOpen = useSelector((state: GlobalState) => {
        return state.ui.medias.mediaItemModalOpen;
    });

    const instructionElementModalOpen = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets.instructionElementModalOpen;
    });

    const rulesModalOpen = useSelector(
        (state: GlobalState) => state.ui.directBuys.modalState.rulesModal
    );

    const toSModalOpen = useSelector(
        (state: GlobalState) => state.ui.modals.toSModalOpen
    );

    return (
        <>
            <HighlightCarouselModal />
            <MediaCarouselModal />
            <AssetCarouselModal />
            <StartPromotingModal />
            <UploadStatusModal />
            <BidIncreaseNotificationModal />

            {downloadTypesModal && <DownloadTypesModal />}

            {/* Campaign Feed Modals */}
            {mediaItemModalOpen && <MediaItemModal />}

            {/* Private Profile Modals */}
            <AccountViewModal />
            <AccountFormModalController />
            <ProfileUploadModal />

            {/* Instructions Modals */}
            {instructionElementModalOpen && (
                <InstructionElementsCarouselModal />
            )}

            {modal === CampaignsModal.countries && <CountriesModal />}
            {modal === CampaignsModal.pluglink && <PlugLinkModal />}
            {rulesModalOpen !== null && <MediaRulesModal />}
            {toSModalOpen && <ToSModal />}
        </>
    );
};

export default GlobalModals;
