import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { get } from "src/Api";
import { requestOpenBuysByStatus } from "src/buys/actions";
import {
    OpenBuySubmission,
    OpenBuySubmissionSet,
    OpenBuySubmissionStatuses
} from "src/buys/modules/open-buys/types";
import {
    selectMarketplaceBadges,
    selectOpenBuysAllIds,
    selectOpenBuysById
} from "src/buys/selectors";
import MainContainer from "src/components/MainContainer";
import ModalHeading from "src/components/modals/components/ModalHeading";
import { updateSelections } from "src/components/table/components/TableServerHeader";
import { SortBy } from "src/components/table/hooks/useSorting";
import PlugTable from "src/components/table/PlugTable";
import { TableCell, TableRow } from "src/components/table/types";
import { createCell } from "src/components/table/utils";
import { colors } from "src/constants";
import useQuery from "src/hooks/useQuery";
import MarketplacePagePartialHeader from "src/marketplace/components/MarketplacePagePartialHeader";
import Dropdown from "src/marketplace/payment-sets/components/PaymentSetDropdown";
import SubmissionApprovalCarousel from "src/marketplace/reports/components/approvalModal/SubmissionApprovalCarousel";
import XCard from "src/profile/components/XCard";
import { GlobalState } from "src/reducers";
import Platforms from "src/social-accounts/Platforms";
import { requestMarketplaceSubmissionBadges } from "src/ui/marketplace/actions";
import styled from "styled-components";
import AdminToolInput from "../components/AdminToolInput";

import {
    filterTags,
    submissionFilterOptions,
    submissionManagerTableColumns
} from "./util";
import { NotificationBadgeButton } from "src/components/buttons";
import moment from "moment";
const Main = styled(MainContainer)`
    margin-bottom: 36px;
`;

const StyledAdAdminToolInput = styled(AdminToolInput)`
    max-width: 150px;
`;

const PlatformIcon = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 50px;
`;

const SearchSideBar = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 10px;
    padding-bottom: 24px;
    & > * {
        flex: 1;
    }
    /* display: flex;
    flex-direction: row;
    position: absolute;
    background-color: white;
    z-index: 99;
    gap: 10px;
    padding: 20px;
    right: 0; */
`;

const SubmissionStatus = styled.div<{ type: string }>`
    font-weight: 500;
    border-radius: 100px;
    font-size: 10px;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 4px;
    font-family: sans-serif;
    text-transform: capitalize;
    color: white;
    background-color: ${props =>
        props.type === OpenBuySubmissionStatuses.pending
            ? colors.primaryYellow
            : props.type === OpenBuySubmissionStatuses.rejected ||
              props.type === OpenBuySubmissionStatuses.revision_requested
            ? colors.primaryRed
            : props.type === OpenBuySubmissionStatuses.approved
            ? colors.primaryGreen
            : colors.primaryBlue};
    width: fit-content;
`;

const defaultPagination = {
    page: 1,
    limit: 12
};

const Badge = styled.div`
    border-radius: 100px;
    background-color: ${colors.secondaryRed};
    color: white;
    height: 20px;
    border-radius: 4px;
    width: 20px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BadgeRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
`;

interface SubmissionRow extends TableRow {
    raw: OpenBuySubmissionSet;
    rowData: {
        challenge: TableCell;
        publisherId: TableCell;
        id: TableCell;
        username: TableCell;
        platform: TableCell;
        status: TableCell;
        statusUpdatedAt: TableCell;
        createdAt: TableCell;
        creatorRep: TableCell;
        url: TableCell;
        playableUrl: TableCell;
    };
}

const SubmissionManager = ({
    staticOpenBuyId
}: {
    staticOpenBuyId?: number;
}) => {
    const query = useQuery();
    const subSetId = query.get("submissionSetId");
    const [controller, setController] = useState(new AbortController());

    const [pagination, setPagination] = useState(defaultPagination);
    const [loading, setLoading] = useState(false);
    const [openBuyId, setOpenBuyId] = useState<number | null>(
        staticOpenBuyId || null
    );

    const [submissions, setSubmissions] = useState<OpenBuySubmissionSet[]>([]);
    const [submissionLimit, setSubmissionLimit] = useState(0);
    const [error, setError] = useState("");

    const [searches, setSearches] = useState<{
        submissionSetId?: string;
        publisherId?: string;
    }>({
        submissionSetId: subSetId || undefined,
        publisherId: undefined
    });
    const badgeCounts = useSelector(selectMarketplaceBadges);
    const openBuys = useSelector(selectOpenBuysById);
    const openBuyIds = useSelector(selectOpenBuysAllIds);
    const [defaultFilters, setDefaultFilters] = useState(["pending"]);
    const [sortBy, setSortBy] = useState("statusUpdatedAt");
    const [direction, setDirection] = useState<"ASC" | "DESC">("DESC");
    const dispatch = useDispatch();
    const history = useHistory();

    const staticOpenBuy = useSelector((state: GlobalState) => {
        if (staticOpenBuyId) {
            return state.entities.adminOpenBuys.byId[staticOpenBuyId];
        } else {
            return null;
        }
    });

    const isNotSubmittable = !staticOpenBuy?.startsAt;

    useEffect(() => {
        if (subSetId) {
            if (submissions.length === 0 && !searches.submissionSetId) {
                setSearches(old => ({ ...old, submissionSetId: subSetId }));
            }
            let data = formatCells();
            let idx = data.findIndex(sub => sub.raw.id === parseInt(subSetId));
            if (!modalSubmissionSetIds.length)
                handleRowClick(data[idx], idx, formatCells());
        }
    }, [submissions, subSetId]);

    const [filters, setFilters] = useState(submissionFilterOptions);

    useEffect(() => {
        let statusFilter = filters[filterTags.challengeStatuses];
        if (openBuyId) {
            statusFilter.disabled = true;
        } else {
            statusFilter.disabled = false;
        }

        setFilters(state => ({
            ...state,
            [filterTags.challengeStatuses]: statusFilter
        }));
    }, [openBuyId]);

    useEffect(() => {
        searchSubmissions();
    }, [pagination, sortBy, direction, filters, openBuyId]);

    useEffect(() => {
        if (!staticOpenBuyId) {
            dispatch(requestOpenBuysByStatus());
        }
    }, []);

    useEffect(() => {
        dispatch(requestMarketplaceSubmissionBadges(openBuyId || undefined));
    }, [openBuyId]);

    const formatCells = () => {
        return submissions.map(
            (
                submission: OpenBuySubmissionSet,
                index: number
            ): SubmissionRow => ({
                originalIdx: index,
                raw: submission,
                rowData: {
                    challenge: createCell.string(
                        openBuys[submission.openBuyId]?.name
                    ),
                    publisherId: createCell.string(
                        submission.publisherId.toString()
                    ),
                    id: createCell.string(submission.id.toString()),
                    username: createCell.link(
                        `@${submission.socialAccount?.username}`,
                        Platforms[
                            submission.socialAccount?.platform || ""
                        ]?.urlCallback(submission.socialAccount?.username || "")
                    ),
                    platform: createCell.custom(
                        submission.socialAccount ? (
                            <PlatformIcon
                                src={
                                    Platforms[
                                        submission.socialAccount?.platform || ""
                                    ]?.imgSrc
                                }
                            />
                        ) : (
                            <></>
                        )
                    ),
                    statusUpdatedAt: createCell.date(
                        submission.statusUpdatedAt,
                        {
                            format: "MM/DD",
                            style: {
                                color:
                                    submission.status !==
                                        OpenBuySubmissionStatuses.next_step &&
                                    submission.status !==
                                        OpenBuySubmissionStatuses.approved &&
                                    moment(submission.statusUpdatedAt).diff(
                                        moment().subtract(5, "days")
                                    ) < 0
                                        ? "red"
                                        : "black"
                            }
                        }
                    ),
                    status: createCell.custom(
                        <SubmissionStatus
                            type={
                                submission.scraperFlag
                                    ? "rejected"
                                    : submission.status
                            }
                        >
                            {submission.scraperFlag
                                ? "Flagged"
                                : submission.status.substring(
                                      0,
                                      submission.status.includes("_")
                                          ? submission.status.indexOf("_")
                                          : submission.status.length
                                  )}
                        </SubmissionStatus>
                    ),
                    createdAt: createCell.date(submission.createdAt, {
                        format: "MM/DD/YYYY, TT:TT"
                    }),
                    creatorRep: createCell.string(
                        submission.creatorRep
                            ? `${submission.creatorRep.firstname} ${submission.creatorRep.lastname}`
                            : null
                    ),
                    url: createCell.string(
                        (submission.submissions || [])
                            .map(s => s.customMedia?.url)
                            .filter(
                                x =>
                                    x?.toLowerCase().includes(".mov") ||
                                    x?.toLowerCase().includes(".mp4")
                            )
                            .join(" | ")
                    ),
                    playableUrl: createCell.string(
                        (submission.submissions || [])
                            .map(
                                s =>
                                    `https://plugco.in/media?mediaUrl=${encodeURIComponent(
                                        s.customMedia?.url || ""
                                    )}`
                            )
                            .join(" | ")
                    )
                }
            })
        );
    };

    const getSelectedFilters = (key: string) => {
        if (filters[key]) {
            return filters[key].choices
                .filter(choice => choice.selected)
                .map(choice => choice.valueToFilterBy);
        }
    };
    const searchSubmissions = async (): Promise<OpenBuySubmission[]> => {
        try {
            setLoading(true);
            controller.abort();

            const flags = filters[filterTags.flags].choices.reduce(
                (acc, choice) => {
                    if (choice.selected) {
                        return { ...acc, [choice.valueToFilterBy]: true };
                    }
                    return acc;
                },
                {}
            );

            const creatorRepIds = getSelectedFilters(
                filterTags.creatorReps
            )?.filter(value => !isNaN(parseInt(value)) && parseInt(value) > 0);
            let res = await get("/v1/admin/openBuySubmissionSet/search", {
                signal: controller.signal,
                openBuyId: staticOpenBuyId ? staticOpenBuyId : openBuyId,
                ...pagination,
                // platforms: getSelectedFilters("platforms"),
                types: getSelectedFilters(filterTags.types),
                statuses: !searches.submissionSetId
                    ? getSelectedFilters(filterTags.statuses)
                    : undefined,
                challengeStatuses: !searches.submissionSetId
                    ? getSelectedFilters(filterTags.challengeStatuses)
                    : undefined,
                creatorRepIds:
                    creatorRepIds && creatorRepIds?.length > 0
                        ? creatorRepIds
                        : undefined,
                ...flags,
                ...(searches.publisherId
                    ? {
                          publisherIds: [searches.publisherId]
                      }
                    : {}),
                ...(searches.submissionSetId
                    ? { submissionSetIds: [searches.submissionSetId] }
                    : {}),
                sortBy,
                direction
            });
            setSubmissions(res.data.data.rows);
            setSubmissionLimit(res.data.data.count);
            return res.data.data.rows;
        } catch (err) {
            setError("Failed to fetch submissions");
            console.error(err);
            return [];
        } finally {
            setLoading(false);
        }
    };

    const [modalSubmissionSetIds, setmodalSubmissionSetIds] = useState<
        number[]
    >([]);

    const handleRowClick = (
        row: TableRow,
        rowIdx: number,
        data: TableRow[]
    ) => {
        const submissionSetIdsArr = [
            ...data.slice(rowIdx, data.length),
            ...data.slice(0, rowIdx)
            //@ts-ignore
        ].map(set => set.raw.id);
        setmodalSubmissionSetIds(submissionSetIdsArr);
    };

    const advanceOrCloseCarousel = async () => {
        const rows = await searchSubmissions();
        setmodalSubmissionSetIds(rows.map(s => s.openBuySubmissionSetId || 0));
    };

    useEffect(() => {
        (async () => {
            let res = await get("/v1/publisher/listReps", {});
            const creatorReps =
                //@ts-ignore
                res.data.data.map(r => {
                    return {
                        valueToFilterBy: r.id.toString(),
                        displayValue: `${r.firstname} ${r.lastname}`,
                        selected: false
                    };
                });
            setFilters(f => {
                return {
                    ...f,
                    [filterTags.creatorReps]: {
                        ...f[filterTags.creatorReps],
                        choices: creatorReps
                    }
                };
            });
        })();
    }, []);

    return (
        <>
            <Main>
                {!staticOpenBuyId && (
                    <MarketplacePagePartialHeader
                        includeHeading
                        customCrumbs={["Challenge Tools", "Submissions"]}
                        headerTitle="Submission Approvals"
                    />
                )}

                {staticOpenBuy && isNotSubmittable ? (
                    <XCard text="Challenge must be launched before submissions are available!" />
                ) : (
                    <>
                        <BadgeRow>
                            <NotificationBadgeButton
                                selected={defaultFilters.includes("pending")}
                                onClick={() => {
                                    setDefaultFilters(["pending"]);
                                    setFilters(
                                        updateSelections(
                                            filters,
                                            [
                                                {
                                                    key: filterTags.statuses,
                                                    valueToFilterBy: "pending",
                                                    valueToChangeTo: true
                                                },
                                                {
                                                    key:
                                                        filterTags.challengeStatuses,
                                                    valueToFilterBy: "active",
                                                    valueToChangeTo: true
                                                }
                                            ],
                                            true
                                        )
                                    );
                                }}
                            >
                                Pending Submission
                                {!!badgeCounts.pending && (
                                    <Badge>{badgeCounts.pending}</Badge>
                                )}
                            </NotificationBadgeButton>
                            <NotificationBadgeButton
                                selected={defaultFilters.includes(
                                    "showFlagged"
                                )}
                                onClick={() => {
                                    setDefaultFilters(["showFlagged"]);
                                    setFilters(
                                        updateSelections(
                                            filters,
                                            [
                                                {
                                                    key: filterTags.statuses,
                                                    valueToFilterBy: "pending",
                                                    valueToChangeTo: true
                                                },
                                                {
                                                    key: filterTags.statuses,
                                                    valueToFilterBy: "approved",
                                                    valueToChangeTo: true
                                                },
                                                {
                                                    key: filterTags.flags,
                                                    valueToFilterBy:
                                                        "showFlagged",
                                                    valueToChangeTo: true
                                                },
                                                {
                                                    key:
                                                        filterTags.challengeStatuses,
                                                    valueToFilterBy: "active",
                                                    valueToChangeTo: true
                                                }
                                            ],
                                            true
                                        )
                                    );
                                }}
                            >
                                Scraper Flagged
                                {!!badgeCounts.flagged && (
                                    <Badge>{badgeCounts.flagged}</Badge>
                                )}
                            </NotificationBadgeButton>
                        </BadgeRow>
                        <SearchSideBar>
                            <StyledAdAdminToolInput
                                type="number"
                                onChange={v =>
                                    setSearches(old => ({
                                        ...old,
                                        publisherId: v
                                    }))
                                }
                                onDoneTyping={() => searchSubmissions()}
                                label="PUBLISHER ID"
                            />
                            <StyledAdAdminToolInput
                                label="SUBMISSION SET ID"
                                type="number"
                                onDoneTyping={(e, v) => {
                                    setDefaultFilters(["pending"]);
                                    if (searches.submissionSetId)
                                        setFilters(
                                            updateSelections(filters, [], true)
                                        );
                                    searchSubmissions();
                                }}
                                onChange={v =>
                                    setSearches(old => ({
                                        ...old,
                                        submissionSetId: v
                                    }))
                                }
                                value={searches.submissionSetId}
                            />
                            {!staticOpenBuyId && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px"
                                    }}
                                >
                                    <ModalHeading text={"CHALLENGE"} />
                                    <Dropdown
                                        itemList={openBuyIds}
                                        selected={
                                            openBuyId
                                                ? openBuys[openBuyId].name
                                                : null
                                        }
                                        displayName={
                                            openBuyId
                                                ? openBuys[openBuyId].name
                                                : undefined
                                        }
                                        displayEmptyState={"Select a challenge"}
                                        getItemName={id => openBuys[id].name}
                                        handleOnClick={id => setOpenBuyId(id)}
                                    />
                                </div>
                            )}
                        </SearchSideBar>

                        <PlugTable
                            title="Submission Approvals"
                            resultsPerPage={pagination.limit}
                            serversideConfig={{
                                filters: {
                                    getter: filters,
                                    setter: setFilters
                                },
                                count: submissionLimit,
                                paginate: (page: number) =>
                                    setPagination(old => ({ ...old, page }))
                            }}
                            tableLoading={loading}
                            cellData={formatCells()}
                            columns={submissionManagerTableColumns}
                            rowClick={handleRowClick}
                            manualSort={{
                                by: sortBy,
                                direction,
                                onSort: (by, direction) => {
                                    setDirection(direction);
                                    setSortBy(by);
                                }
                            }}
                        />
                    </>
                )}
            </Main>
            {modalSubmissionSetIds.length > 0 && (
                <SubmissionApprovalCarousel
                    submissionSetIds={modalSubmissionSetIds}
                    closeModal={() => {
                        setmodalSubmissionSetIds([]);
                        history.replace(`${window.location.pathname}`);
                    }}
                    advanceOrCloseCarousel={advanceOrCloseCarousel}
                />
            )}
        </>
    );
};
export default SubmissionManager;
