import { Shoutout } from "../types";

const sortShoutouts = (shoutouts: Shoutout[]) => {
    let sortedShoutouts: Shoutout[][] = [];

    for (let i = 0; i < shoutouts.length; i++) {
        const shoutout = shoutouts[i];

        if (i === 0) {
            sortedShoutouts.push([shoutout]);
            continue;
        }

        const currGroup = sortedShoutouts[sortedShoutouts.length - 1];

        if (
            currGroup[0].start_time.slice(0, 10) ===
            shoutout.start_time.slice(0, 10)
        ) {
            currGroup.push(shoutout);
        } else {
            sortedShoutouts.push([shoutout]);
        }
    }

    return sortedShoutouts;
};

export default sortShoutouts;
