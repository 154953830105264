import React from "react";
import { colors, fonts, fontWeight } from "src/constants";
import { clearSpacing } from "src/utils/styles/snippets";
import styled from "styled-components";

const Main = styled.article`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Title = styled.h2`
    ${clearSpacing()};
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.heavy};
    text-transform: uppercase;
`;

const ErrorMessage = styled.span`
    color: ${colors.primaryRed};
    font-size: ${fonts.smallTitle}px;
`;

interface Props {
    title?: string;
    error?: { isError: boolean; message: string };
    children?: React.ReactNode;
    hidden?: boolean;
}

const ChallengeFormSubSection = ({ title, error, children, hidden }: Props) => {
    if (hidden) return <></>;
    return (
        <Main>
            {title && <Title>{title}</Title>}
            {children}
            {error && error.isError && (
                <ErrorMessage>{error.message}</ErrorMessage>
            )}
        </Main>
    );
};

export default ChallengeFormSubSection;
