import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { adminFetchOpenBuy } from "src/admin-tools/actions.admin-tools";
import { get } from "src/Api";
import { Action } from "src/types";
import { setCurrentCampaignId } from "src/ui/campaigns/actions";
import { setWizardChallengeOffer } from "src/ui/create-offer-wizard/actions";
import {
    resetInstructionsSetOnCampaignSelection,
    setCurrentInstructionsSetId
} from "src/ui/instructions-sets/actions";
import {
    MARKETPLACE_SUBMISSION_BADGES,
    setMarketplaceSubmissionBadges
} from "src/ui/marketplace/actions";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { errorToString } from "src/util";
import {
    ATTACH_INSTRUCTION_SET_TO_OPEN_BUY,
    FETCH_OPEN_BUY_TO_EDIT
} from "./actions";

function* fetchOpenBuyToEdit({ payload }: Action) {
    try {
        const { data } = yield axios.get(
            `/v1/admin/openBuy/search?id=${payload}`
        );
        const buy = data.data[0];
        yield put(
            setWizardChallengeOffer(
                buy.name,
                buy.description,
                buy.linkUrl,
                buy.jetfuelPercentFee,
                buy.budgetAmount * (1 + buy.jetfuelPercentFee),
                buy.budgetAmount,
                buy.id
            )
        );
        yield put(setCurrentCampaignId(buy.buyerId || 0));
        yield put(resetInstructionsSetOnCampaignSelection());

        //implement this later i guess yield put(setCurrentCreatorGroupId(buy.createor))
        //yield put(setCurrentPaymentBucketId(b))
        yield put(setCurrentInstructionsSetId(buy.instructionSetId));
    } catch (err) {
        console.log("Error:", err);
    }
}

function* attachInstructionSetToOpenBuy({ payload }: Action) {
    try {
        const { openBuyId, instructionSetId } = payload;
        const endpoint = `/v1/openBuy/${openBuyId}/instructionSet/${instructionSetId}`;
        yield axios.patch(endpoint);
        // yield put(adminUpdateOpenBuyInstruction(openBuyId, instructionSetId));
        yield put(adminFetchOpenBuy(openBuyId));
    } catch (error) {
        const errorMessage = errorToString(error);
        yield put(setPillNotificationText(errorMessage, "danger"));
    }
}

function* getBadgeCounts({ payload }: Action) {
    try {
        const pendingRes = yield call(
            get,
            "/v1/admin/openBuySubmissionSet/search",
            {
                statuses: ["pending"],
                countOnly: true,
                challengeStatuses: ["active"],
                openBuyId: payload
            }
        );
        const scrapeRes = yield call(
            get,
            "/v1/admin/openBuySubmissionSet/search",
            {
                showFlagged: true,
                countOnly: true,
                statuses: ["pending", "approved"],
                challengeStatuses: ["active"],
                openBuyId: payload
            }
        );
        yield put(
            setMarketplaceSubmissionBadges({
                pending: pendingRes.data.data,
                flagged: scrapeRes.data.data
            })
        );
    } catch (err) {}
}

export default function* openBuyEditSaga() {
    yield all([
        takeEvery(FETCH_OPEN_BUY_TO_EDIT, fetchOpenBuyToEdit),
        takeEvery(
            ATTACH_INSTRUCTION_SET_TO_OPEN_BUY,
            attachInstructionSetToOpenBuy
        ),
        takeEvery(MARKETPLACE_SUBMISSION_BADGES.REQUEST, getBadgeCounts)
    ]);
}
