import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "src/components/Navbar";
import { breakpoints, colors, maxWidth } from "src/constants";
import { fetchCreatorsRequest } from "src/marketplace/creator-groups/actions.creator-search";
import { getCreator } from "src/marketplace/creator-groups/selectors.creators-search";
import { generateCreatorSearchData } from "src/marketplace/creator-groups/utils/helpers";
import { codeToEmoji } from "src/notifications/CodeToEmoji";
import { GlobalState } from "src/reducers";
import { shortenNumber } from "src/util";
import { capitalizeFirstCharacter } from "src/utils/functions/helpers";
import {
    responsiveContainer,
    responsiveSidePaddings
} from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import SocialAccountProfileDetails from "../components/SocialAccountProfileDetails";
import SocialAccountProfileTopPosts from "../components/SocialAccountProfileTopPosts";
import SocialAccountProfileHeader from "../components/SocialAccountProfileHeader";
import Platforms from "../Platforms";
import { SocialAccountDetails } from "../utils/social-accounts.types";
import SocialAccountProfileFollowerDemographics from "../components/SocialAccountProfileFollowerDemographics";
import {
    SocialAccountProfileDetailsLoading,
    SocialAccountProfileFollowerDemographicsLoading,
    SocialAccountProfileHeaderLoading,
    SocialAccountProfileTopPostsLoading
} from "src/components/SkeletonLoader";
import SocialAccountDetailsModal from "../modals/SocialAccountDetailsModal";
import SocialAccountAddToGroupModal from "../modals/SocialAccountAddToGroupModal";
import SocialAccountFollowersDemographicsModal from "../modals/SocialAccountFollowersDemographicsModal";
import { fetchCreatorGroups } from "src/marketplace/creator-groups/actions.creator-groups";
import {
    fetchSocialAccountProfile,
    fetchSocialAccountProfileFailure
} from "./redux/social-account-profile.action";
import { getCurrentSocialAccountProfile } from "../utils/social-account-profile.selectors";
import NotFoundPage from "src/navigation/NotFound";
import { Redirect, useHistory } from "react-router";

const Main = styled.div<{ inRouteModal?: true }>`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: ${props => `24px ${props.inRouteModal ? "24px" : "0px"} 40px`};
    background-color: ${colors.teritaryLightGray};

    ${responsiveSidePaddings(0, 40)};

    ${props =>
        !props.inRouteModal &&
        css`
            @media (${breakpoints.mobile}px) {
                padding: 16px 0px 40px;
            }
        `}
`;

const Body = styled.main`
    ${responsiveContainer()};
    flex: 1;
`;

const Columns = styled.div`
    ${responsiveContainer()};
    display: flex;
    gap: 24px;

    @media (max-width: ${breakpoints.tablet}px) {
        flex-direction: column;
    }
`;

const LeftColumn = styled.section`
    width: 330px;

    @media (max-width: ${breakpoints.tablet}px) {
        width: 100%;
    }
`;

const RightColumn = styled.section`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: ${maxWidth.desktop - 330 - 24}px;

    @media (max-width: ${breakpoints.tablet}px) {
        max-width: 100%;
    }
`;

const details = [
    "Followers",
    "Average Views",
    "Engagement",
    "Country",
    "Gender *",
    "Ethnicity *",
    "Sexuality *",
    "Page Style *",
    "Brand Safety",
    "Age Range *"
] as const;

export type SocialAccountLabel = typeof details[number];

interface Props {
    socialId: string;
    otherId: string;
    inRouteModal?: true;
}

const SocialAccountProfile = ({ socialId, otherId, inRouteModal }: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();
    const history = useHistory();

    // Redux State ---------------------------------------------------
    const currentSocialAccountProfile = useSelector((state: GlobalState) => {
        if (Number(socialId)) {
            return getCurrentSocialAccountProfile(state, Number(socialId));
        }
    });

    const { hitsPerPage, page, dateRange } = useSelector(
        (state: GlobalState) => {
            return state.ui.creators;
        }
    );

    const { socialAccountProfileLoading } = useSelector(
        (state: GlobalState) => {
            return state.ui.socialAccountProfile;
        }
    );

    const { socialAccountProfileFetch } = useSelector((state: GlobalState) => {
        return state.failures;
    });

    // Local Functions -----------------------------------------------
    const generateDetails = useCallback(() => {
        if (currentSocialAccountProfile) {
            return details.map(
                (detail: SocialAccountLabel): SocialAccountDetails => {
                    switch (detail) {
                        case "Followers":
                            return {
                                data: currentSocialAccountProfile.followers,
                                label: detail,
                                iconType: "person"
                            };

                        case "Average Views":
                            return {
                                data: currentSocialAccountProfile.avg_views,
                                label: detail,
                                iconType: "eye"
                            };

                        case "Engagement":
                            return {
                                data: `${shortenNumber(
                                    currentSocialAccountProfile.engagement_rate *
                                        100,
                                    1
                                )}%`,
                                label: detail,
                                iconType: "percent"
                            };

                        case "Country":
                            const {
                                country_code
                            } = currentSocialAccountProfile;
                            if (country_code) {
                                return {
                                    data: `${
                                        codeToEmoji[country_code.toUpperCase()]
                                    } ${country_code.toUpperCase()}`,
                                    label: detail,
                                    iconType: "person"
                                };
                            } else {
                                return {
                                    data: "N/A",
                                    label: detail,
                                    iconType: "person"
                                };
                            }

                        case "Gender *":
                            return {
                                data: "Nope",
                                label: detail
                            };

                        case "Ethnicity *":
                            return {
                                data: "Nope",
                                label: detail
                            };

                        case "Sexuality *":
                            return {
                                data: "Nope",
                                label: detail
                            };

                        case "Page Style *":
                            return {
                                data: "Creator",
                                label: detail
                            };

                        case "Brand Safety":
                            return {
                                data: "Nope",
                                label: detail
                            };

                        default:
                            return {
                                data:
                                    currentSocialAccountProfile.audience_age_code,
                                label: detail
                            };
                    }
                }
            );
        } else {
            return [];
        }
    }, [currentSocialAccountProfile]);

    // Effects -------------------------------------------------------

    useEffect(() => {
        // dispatch(fetchCreatorGroups());
        if (Number(socialId)) {
            dispatch(fetchSocialAccountProfile(Number(socialId)));
        }
    }, []);

    // useEffect(() => {
    //     if (socialId) {
    //         const searchQuery = generateCreatorSearchData(
    //             hitsPerPage,
    //             page,
    //             "",
    //             dateRange,
    //             [{ term: { social_account_id: socialId } }],
    //             []
    //         );
    //         dispatch(fetchCreatorsRequest(searchQuery, false));
    //     }
    // }, [socialId]);

    // JSX -----------------------------------------------------------
    if (socialAccountProfileFetch.length > 0) {
        // dispatch(fetchSocialAccountProfileFailure(""));
        return <Redirect to="/not-found" />;
    } else if (!socialAccountProfileLoading && currentSocialAccountProfile) {
        return (
            <Main inRouteModal={inRouteModal}>
                {!inRouteModal && <Navbar mobileHasMarginBottom />}

                <Body>
                    <SocialAccountProfileHeader
                        profilePictureUrl={currentSocialAccountProfile.picture}
                        socialIconUrl={
                            Platforms[
                                currentSocialAccountProfile.platform.toLowerCase()
                            ].imgSrc
                        }
                        socialAccountName={
                            currentSocialAccountProfile.username ||
                            "No Username"
                        }
                        plugAccountName={
                            currentSocialAccountProfile.plug_username
                        }
                        plugAccountUrl={`/public/${otherId}`}
                        socialPlatformUrl={currentSocialAccountProfile.url}
                        socialPlatformName={capitalizeFirstCharacter(
                            currentSocialAccountProfile.platform
                        )}
                    />

                    <Columns>
                        <LeftColumn>
                            <SocialAccountProfileDetails
                                interests={
                                    currentSocialAccountProfile.interests
                                }
                                specialties={[
                                    "Talk about what we're going to do with specialties with Martin"
                                ]}
                                details={generateDetails()}
                            />
                        </LeftColumn>

                        <RightColumn>
                            <SocialAccountProfileTopPosts
                                dataCount={0}
                                cardData={[]}
                                socialPlatformUrl={
                                    currentSocialAccountProfile.url
                                }
                                socialPlatformName={capitalizeFirstCharacter(
                                    currentSocialAccountProfile.platform
                                )}
                            />
                            <SocialAccountProfileFollowerDemographics
                                audienceCountryCode={
                                    currentSocialAccountProfile.audience_country_code
                                }
                                audienceCountries={
                                    currentSocialAccountProfile.audience_countries
                                }
                                audienceGenderCode={
                                    currentSocialAccountProfile.audience_gender_code
                                }
                                audienceGenders={
                                    currentSocialAccountProfile.audience_genders
                                }
                                audienceLanguageName={
                                    currentSocialAccountProfile.audience_language_name
                                }
                                audienceLanguages={
                                    currentSocialAccountProfile.audience_languages
                                }
                                audienceAgeCode={
                                    currentSocialAccountProfile.audience_age_code
                                }
                                audienceAges={
                                    currentSocialAccountProfile.audience_ages
                                }
                            />
                        </RightColumn>
                    </Columns>
                </Body>

                {/* Modals */}
                {/* <SocialAccountDetailsModal /> */}
                <SocialAccountAddToGroupModal
                    currentCreatorSocialAccountId={
                        currentSocialAccountProfile.social_account_id
                    }
                />
                <SocialAccountFollowersDemographicsModal
                    currentSocialAccountProfile={currentSocialAccountProfile}
                />
            </Main>
        );
    } else {
        // Loading State
        return (
            <Main>
                <Navbar mobileHasMarginBottom />

                <Body>
                    <SocialAccountProfileHeaderLoading />

                    <Columns>
                        <LeftColumn>
                            <SocialAccountProfileDetailsLoading />
                        </LeftColumn>

                        <RightColumn>
                            <SocialAccountProfileTopPostsLoading />
                            <SocialAccountProfileFollowerDemographicsLoading />
                        </RightColumn>
                    </Columns>
                </Body>
            </Main>
        );
    }
};

export default SocialAccountProfile;
