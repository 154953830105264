import React, { useState } from "react";
import { Script } from "src/campaigns/types";
type Processing = {
    processing: number | null;
    expectedTotal: number | null;
};
export interface ScriptMediaProcessing {
    assets: Processing;
    medias: Processing;
}
export type SetProcessing = (key: "assets" | "medias", count: number) => void;
export type ClearProcessing = (key: "assets" | "medias") => void;
export interface ProcessingControl {
    assets: "assets";
    medias: "medias";
    current: ScriptMediaProcessing;
    clear: ClearProcessing;
    set: SetProcessing;
}
const defaultProcessing = {
    assets: { processing: null, expectedTotal: null },
    medias: { processing: null, expectedTotal: null }
};

const useProcessing = (script?: Script) => {
    const [processing, setProcessing] = useState<ScriptMediaProcessing>(
        defaultProcessing
    );
    const processingControl: ProcessingControl = {
        medias: "medias",
        assets: "assets",
        current: processing,
        clear: key => {
            switch (key) {
                case processingControl.medias:
                    setProcessing({
                        assets: processing.assets,
                        medias: defaultProcessing.medias
                    });
                    break;
                case processingControl.assets:
                    setProcessing({
                        medias: processing.medias,
                        assets: defaultProcessing.assets
                    });
                    break;
            }
        },
        set: (key, count) => {
            if (script && script[key]) {
                setProcessing(prevState => {
                    if (prevState[key].expectedTotal === null) {
                        return {
                            ...prevState,
                            [key]: {
                                processing: count,
                                //@ts-ignore
                                expectedTotal: count + script[key].length
                            }
                        };
                    } else {
                        return {
                            ...prevState,
                            [key]: {
                                processing: count,
                                expectedTotal:
                                    //@ts-ignore
                                    count + prevState[key].expectedTotal
                            }
                        };
                    }
                });
            }
        }
    };
    return processingControl;
};

export default useProcessing;
