import React from "react";
import { WeeklyLeaderboardItem } from "../../types";
import styled from "styled-components";
import { colors, fonts } from "../../../constants";
import CoinText from "./CoinText";
import LeaderboardPhoto from "src/components/LeaderboardPhoto";
import { formatRank } from "src/util";
import { ContestRank } from "src/campaigns/contests/types";

const Main = styled.div<{ clickable?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${fonts.subtitle}px;
    margin: 0px 18px 0px 18px;
    cursor: ${props => props.clickable && "pointer"};

    & + & {
        margin-top: 15px;
    }
`;

const User = styled.div`
    text-align: left;
    display: flex;
    align-items: center;
`;

const NameContainer = styled.div`
    margin-left: 10px;
    text-align: left;
    overflow: hidden;
`;

const Name = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Bonus = styled.div`
    font-size: 12px;
    color: ${colors.secondaryGray};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface Props {
    leaderboardItem?: WeeklyLeaderboardItem;
    onSelect?: (publisher: WeeklyLeaderboardItem) => void;
}

const WeeklyCell = ({ leaderboardItem, onSelect }: Props) => {
    if (!leaderboardItem) return null;
    return (
        <Main
            onClick={() => {
                onSelect && onSelect(leaderboardItem);
            }}
            clickable={!!onSelect}
        >
            <User>
                <LeaderboardPhoto
                    profilePhotoUrl={leaderboardItem.profilePhotoUrl}
                    type={leaderboardItem.type}
                />
                <NameContainer>
                    <Name>
                        {leaderboardItem.type === "own"
                            ? `${formatRank(leaderboardItem.rank)} (you)`
                            : formatRank(leaderboardItem.rank)}
                    </Name>
                    <Bonus>{leaderboardItem.bonusPercentage}% bonus</Bonus>
                </NameContainer>
            </User>
            <CoinText color={colors.black} amount={leaderboardItem.earnings} />
        </Main>
    );
};

export default WeeklyCell;
