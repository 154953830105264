import React from "react";
import styled from "styled-components";
import Modal from "../../../modals/Modal";
import ModalButton from "../../../modals/ModalButton";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 300;
`;

const CountdownModal = () => {
    return (
        <Modal>
            <Main>
                <Title>Time remaining</Title>
                <Subtitle>
                    You have 24 hours to deliver these views and upload a
                    screenshot as proof.
                </Subtitle>
            </Main>
            <ModalButton />
        </Modal>
    );
};

export default CountdownModal;
