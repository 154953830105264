import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { Action } from "../types";

const useInViewDispatch = (action: Action, timeout?: number) => {
    const [ref, inView] = useInView({ threshold: 0.7 });
    const inViewRef = useRef(inView);
    const dispatch = useDispatch();

    useEffect(() => {
        inViewRef.current = inView;
        if (inView) {
            if (timeout) {
                setTimeout(() => {
                    inViewRef.current && dispatch(action);
                }, timeout);
            } else {
                dispatch(action);
            }
        }
    }, [inView]);

    return ref;
};

export default useInViewDispatch;
