import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import FullscreenLoader from "src/components/FullscreenLoader";
import Divider from "src/components/Divider";
import CampaignAssetsAccordionContainer from "src/campaigns/details/accordions/CampaignAssetsAccordionContainer";
import CampaignMediasAccordionContainer from "src/campaigns/details/accordions/CampaignMediasAccordionContainer";
import MediaRulesAccordion from "src/campaigns/details/accordions/MediaRulesAccordion";
import CaptionsAccordion from "src/campaigns/details/accordions/CaptionsAccordion";
import ScriptsAccordion from "src/campaigns/details/accordions/ScriptsAccordion";
import CampaignHighlightsMediaRowContainer from "src/campaigns/highlights/CampaignHighlightsMediaRowContainer";
import CustomLinksCard from "src/campaigns/custom-links/CustomLinksCard";
import useAuthorization from "src/auth/hooks/useAuthorization";
import { CampaignAction } from "src/auth/roles";
import {
    ItemHeader,
    Subtitle,
    Title,
    BottomRef
} from "src/campaigns/details/Details.styled";
import { selectCampaign } from "src/campaigns/selectors";
import { GlobalState } from "src/reducers";
import PublicCampaignMediasAccordionContainer from "../accordions/PublicCampaignMediasAccordionContainer";

interface Props {
    authed?: true;
}

const PromoteTab = ({ authed }: Props) => {
    const [customLinks] = useAuthorization([CampaignAction.customLinks]);
    const bottomRef = useRef<HTMLDivElement>(null);

    const campaign = useSelector((state: GlobalState) => {
        return selectCampaign(state);
    });

    const scrollToPromote = useSelector((state: GlobalState): boolean => {
        return (
            !!state.router.location.state &&
            !!(state.router.location.state as { scrollToPromote: boolean })
                .scrollToPromote
        );
    });

    useEffect(() => {
        setTimeout(() => {
            if (scrollToPromote) {
                bottomRef?.current?.scrollIntoView({
                    behavior: "smooth"
                });
            }
        }, 500);
    }, []);

    if (!campaign) return <FullscreenLoader blankSpace={332} />;
    return (
        <>
            <ItemHeader>
                <Title>Highlights</Title>
                <Subtitle>Top performing recent promotions</Subtitle>
            </ItemHeader>

            <CampaignHighlightsMediaRowContainer />

            <MediaRulesAccordion
                title="Media Rules"
                initiallyExpanded
                initialHeight={195}
            />

            <Divider />
            <ScriptsAccordion title="Scripts" />

            <Divider />
            <CampaignAssetsAccordionContainer title="Assets" />

            <Divider />
            <CaptionsAccordion title="Captions" />

            <Divider />
            {authed ? (
                <CampaignMediasAccordionContainer
                    initiallyExpanded
                    title="Approved Media"
                />
            ) : (
                <PublicCampaignMediasAccordionContainer
                    initiallyExpanded
                    title="Approved Media"
                />
            )}

            <Divider />
            <BottomRef ref={bottomRef} />

            {customLinks && <CustomLinksCard />}
        </>
    );
};

export default PromoteTab;
