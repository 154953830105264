import React from "react";
import { Shimmer } from "./SkeletonLoader";
import styled from "styled-components";
import { ReactComponent as Check } from "./icons/circle-check.svg";
import { ReactComponent as X } from "./icons/circle-x.svg";
import { ReactComponent as Ellipsis } from "./icons/circle-ellipsis.svg";
import { colors } from "../constants";

const Loading = styled.div`
    min-width: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    ${Shimmer}
`;

const IconWrapper = styled.div<{ iconColor: string }>`
    min-width: 24px;
    color: ${props => props.iconColor};
`;

interface Props {
    type: "x" | "check" | "ellipsis" | "loading";
    color?: string;
}

const CircleIcon = ({ type, color = colors.primaryBlue }: Props) => {
    if (type === "loading") {
        return <Loading />;
    } else if (type === "check") {
        return (
            <IconWrapper iconColor={color}>
                <Check />
            </IconWrapper>
        );
    } else if (type === "ellipsis") {
        return (
            <IconWrapper iconColor={color}>
                <Ellipsis />
            </IconWrapper>
        );
    }
    return (
        <IconWrapper iconColor={color}>
            <X />
        </IconWrapper>
    );
};

export default CircleIcon;
