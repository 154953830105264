import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SearchTag from "src/components/SearchTag";
import { colors, fonts } from "src/constants";
import PlusCard from "src/profile/components/PlusCard";
import { GlobalState } from "src/reducers";
import { clearButton, flexRowCenter } from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import InstructionsSearchBar from "./InstructionsSearchBar";
import { InstructionsSet } from "../types";
import {
    getInstructionsSets,
    getSearchFilteredInstructionsSets
} from "../selectors";
import {
    SelectionHeaderLoading,
    SelectionHeaderLoadingContainer
} from "../SkeletonLoader";
import SelectionHeader from "../../components/SelectionHeader";
import {
    setCurrentInstructionsSetId,
    setInstructionsPageStep,
    setInstructionsPageType
} from "src/ui/instructions-sets/actions";
import { fetchInstructionSteps } from "../actions.instruction-steps";
import CampaignSelectionButton from "./CampaignSelectionButton";
import { linkButtonStyles } from "src/marketplace/utils/styles";
import { instructionSetsRoutes } from "src/marketplace/utils/marketplace-routes";
import { PageType } from "src/ui/instructions-sets/reducers";
import { push } from "connected-react-router";

const Main = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding-bottom: 18px;
`;

const CreateInstructionSetLink = styled.button`
    ${clearButton()};
    width: 100%;
    padding: 12px 6px;
    color: ${colors.white};
    background-color: ${colors.primaryBlue};
    border-radius: 6px;
    font-size: ${fonts.subtitle}px;
    text-align: center;
    transition: 0.1s ease-in;

    :hover {
        color: ${colors.white};
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

const SearchFiltersAndItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

const SearchAndFilters = styled.section`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Filters = styled.div`
    display: flex;
    gap: 10px;
`;

const InstructionsSets = styled.div`
    display: flex;
    flex-direction: column;
`;

interface Props {
    searchString: string;
    setSearchString: React.Dispatch<React.SetStateAction<string>>;
    currentInstructionsSetId: number | null;
}

const MainLeftContent = ({
    searchString,
    setSearchString,
    currentInstructionsSetId
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const instructionsSetsExist = useSelector(getInstructionsSets).length > 0;

    const { instructionsSetsLoading } = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets;
    });

    const searchFilteredInstructionsSets = useSelector((state: GlobalState) => {
        return getSearchFilteredInstructionsSets(state, searchString);
    });

    const { inAdminTools } = useSelector((state: GlobalState) => {
        return state.ui.adminTools;
    });

    // Local Functions -----------------------------------------------
    function handleRouting(): void {
        if (!inAdminTools) {
            dispatch(push(instructionSetsRoutes.create));
        } else {
            dispatch(setInstructionsPageType("Create"));
        }
    }

    function renderCreateView(): void {
        dispatch(setInstructionsPageType("Create"));
        dispatch(setInstructionsPageStep("Scratch"));
    }

    // JSX -----------------------------------------------------------
    return (
        <Main>
            {/* <CreateInstructionSetLink to={instructionSetsRoutes.create}> */}
            {/* <CreateInstructionSetLink onClick={handleRouting}> */}
            {!instructionsSetsLoading && (
                <CreateInstructionSetLink onClick={renderCreateView}>
                    Create New Instruction Set
                </CreateInstructionSetLink>
            )}

            {/* <CampaignSelectionButton /> */}

            <SearchFiltersAndItems>
                <SearchAndFilters>
                    <InstructionsSearchBar
                        setSearchString={setSearchString}
                        backgroundColor={colors.lighterTeal}
                    />
                    {/* <Filters>
                        <SearchTag
                            onClick={() => console.log("Sort By")}
                            text="Sort By"
                            color="white"
                            iconSrc="/tag-teal-arrow-icon.svg"
                            margin="0px"
                        />
                        <SearchTag
                            onClick={() => console.log("Filters")}
                            text="Filters"
                            color="white"
                            iconSrc="/tag-teal-plus-icon.svg"
                            margin="0px"
                        />
                    </Filters> */}
                </SearchAndFilters>

                {instructionsSetsLoading ? (
                    <SelectionHeaderLoadingContainer>
                        <SelectionHeaderLoading />
                        <SelectionHeaderLoading />
                        <SelectionHeaderLoading />
                    </SelectionHeaderLoadingContainer>
                ) : instructionsSetsExist ? (
                    <InstructionsSets>
                        {searchFilteredInstructionsSets.map(
                            (instructionsSet: InstructionsSet) => {
                                function setInstructionId(): void {
                                    dispatch(
                                        setCurrentInstructionsSetId(
                                            instructionsSet.id
                                        )
                                    );
                                    if (!!currentInstructionsSetId) {
                                        dispatch(
                                            fetchInstructionSteps(
                                                currentInstructionsSetId,
                                                true
                                            )
                                        );
                                    }
                                }
                                return (
                                    <SelectionHeader
                                        key={`instructions-sets-main-left-content-selections-${instructionsSet.id}`}
                                        onClick={setInstructionId}
                                        title={
                                            instructionsSet.name || "No Name"
                                        }
                                        selected={
                                            currentInstructionsSetId ===
                                            instructionsSet.id
                                        }
                                    />
                                );
                            }
                        )}
                    </InstructionsSets>
                ) : (
                    <PlusCard
                        text="You have no created instruction sets. Create one using the button above."
                        customStyles={{ textAlign: "center" }}
                    />
                )}
            </SearchFiltersAndItems>
        </Main>
    );
};

export default MainLeftContent;
