import React from "react";
import styled, { css } from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import * as Icons from "src/components/icons/exports";
import { addNotification } from "src/ui/notifications/actions";
import { colors, fonts } from "src/constants";
import { Media } from "src/media/types";
import { GlobalState } from "src/reducers";
import { fetchLinkForPlug, fetchLinkForPlugSuccess } from "src/links/actions";
import { flexRowCenter } from "src/utils/styles/snippets";

interface ButtonProps {
    width?: string;
    height?: string;
}

const buttonStyles = css<ButtonProps>`
    ${flexRowCenter()};
    width: ${props => props.width || "100%"};
    height: ${props => props.height || "100%"};
    color: ${colors.black};
    background-color: ${colors.white};
    text-decoration: none;
    transition: 0.1s ease-in;
    cursor: pointer;

    :hover {
        color: ${colors.black};
        text-decoration: none;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.mediumTeal};
        }
    }
`;

const LinkButton = styled("div")<ButtonProps>`
    ${buttonStyles};
`;

const DownloadButton = styled("a").attrs(() => ({
    target: "_blank"
}))<ButtonProps>`
    ${buttonStyles};
`;

interface TextProps {
    text: boolean;
}

const Text = styled("span")<TextProps>`
    display: ${props => (props.text ? "block" : "none")};
    margin-left: 6px;
    color: ${colors.primaryGray};
    font-size: ${fonts.subtitle}px;
    font-weight: 600;
`;

interface Props {
    type: "link" | "download";
    copyLink?: string;
    downloadLink?: string | null;
    text?: string;
    width?: string;
    height?: string;
    campaignMedia: Media;
    linkService?: string;
    downloadCallback?: VoidFunction;
    onClick?: () => void;
}

const MediaItemButton = (props: Props) => {
    const {
        type,
        copyLink,
        downloadLink,
        text,
        width,
        height,
        campaignMedia,
        linkService,
        downloadCallback,
        onClick
    } = props;

    const dispatch = useDispatch();
    function handleOnCopy(): void {
        dispatch(addNotification(`Copied Link: ${copyLink}`));
    }

    let Button: React.ReactNode = <></>;

    const plugLink = useSelector((state: GlobalState) => {
        return state.links.linksForPlugs[
            `${campaignMedia.campaignId}-${campaignMedia.id}`
        ];
    });

    function linkCallback(): void {
        if (plugLink) {
            dispatch(fetchLinkForPlugSuccess(plugLink));
        } else {
            dispatch(
                fetchLinkForPlug({
                    ...campaignMedia,
                    externalPageId:
                        linkService === "inHouseClickTracking"
                            ? campaignMedia.externalPageId
                            : null
                })
            );
        }
    }

    if (type === "link") {
        Button = (
            /* <CopyToClipboard text={copyLink} onCopy={handleOnCopy}> */
            <LinkButton
                onClick={() => {
                    onClick && onClick();
                    linkCallback();
                }}
                width={width}
                height={height}
            >
                <Icons.Link height={20} fill={colors.primaryGray} />
                <Text text={!!text}>{text}</Text>
            </LinkButton>
            /* </CopyToClipboard> */
        );
    } else {
        if (!!downloadLink) {
            Button = (
                <DownloadButton
                    onClick={() => {
                        onClick && onClick();
                        downloadCallback && downloadCallback();
                    }}
                    href={downloadLink}
                    width={width}
                    height={height}
                    download
                >
                    <Icons.Download height={20} fill={colors.primaryGray} />
                    <Text text={!!text}>{text}</Text>
                </DownloadButton>
            );
        }
    }

    return <>{Button}</>;
};

export default MediaItemButton;
