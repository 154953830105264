import { createSelector } from "reselect";
import { GlobalState } from "src/reducers";

export const getCurrentOpenBuy = createSelector(
    (state: GlobalState) => state.entities.adminOpenBuys.byId,
    (state: GlobalState) => state.ui.adminTools.currentOpenBuyId,
    (openBuysState, currentOpenBuyId) => {
        if (currentOpenBuyId) {
            return openBuysState[currentOpenBuyId];
        }
    }
);
