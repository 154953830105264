import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../../../constants";
import VerticallyCentered from "../../../components/VerticallyCentered";
import { flexRowCenter, responsiveContainer } from "src/utils/styles/snippets";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";

const Main = styled.main`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export const NavContainer = styled(VerticallyCentered)`
    ${responsiveContainer()};
    height: 80px;
`;

export const BackButton = styled("img")`
    padding: 16px 20px;
    cursor: pointer;
`;

const SkipButton = styled("div")`
    margin-right: 20px;
    margin-left: auto;
    color: ${colors.primaryGray};
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
`;

const Body = styled(MainContainer)`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const Step = styled("div")`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.primaryGray};
`;

const Title = styled("div")`
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin: 10px 0px;
`;

const MiddleContainer = styled("div")`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const ButtonContainer = styled("div")`
    height: 50px;
`;

interface ButtonProps {
    enabled?: boolean;
}

export const IntroButton = styled("div")<ButtonProps>`
    ${flexRowCenter()};
    height: 100%;
    color: white;
    background-color: ${colors.primaryBlue};
    border-radius: 6px;
    font-size: 16px;
    transition: 0.1s ease-in;
    user-select: none;

    ${props => {
        if (props.enabled || props.enabled === undefined) {
            return "cursor: pointer;";
        } else {
            return "opacity: 0.6;";
        }
    }};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.primaryBlueDark};
        }
    }
`;

export const ClearInput = styled("input")`
    width: 100%;
    height: 50px;
    padding: 0;
    background-color: unset;
    border: none;
    font-size: 32px;
    font-weight: 700;
    outline: none;
    box-sizing: border-box;
    text-align: center;
`;

interface Props {
    step?: number;
    backClicked?: () => void;
    skipClicked?: () => void;
    description: {
        title: string;
        subtitle?: string;
    };
    middle: JSX.Element;
    button: JSX.Element;
}

const IntroSocialScreen = ({
    step,
    backClicked,
    skipClicked,
    description,
    middle,
    button
}: Props) => {
    return (
        <Main>
            <Navbar
                marginBottom={20}
                customMobileNavbar={
                    <NavContainer>
                        {backClicked && (
                            <BackButton
                                onClick={backClicked}
                                src="/back-button.svg"
                            />
                        )}
                        {skipClicked && (
                            <SkipButton onClick={skipClicked}>Skip</SkipButton>
                        )}
                    </NavContainer>
                }
            />

            <Body addSidePadding addBottomPadding>
                {step && <Step>Step {step} of 7</Step>}
                <Title>{description.title}</Title>
                {description.subtitle && <div>{description.subtitle}</div>}
                <MiddleContainer>{middle}</MiddleContainer>
                {/* <MiddleContainer></MiddleContainer> */}
                <ButtonContainer>{button}</ButtonContainer>
            </Body>
        </Main>
    );
};

export default IntroSocialScreen;
