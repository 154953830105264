import { Action } from "src/types";
import { CreatorGroup } from "./types.creator-groups";

// Fetch All Creator Groups ---------------------------------------------------------------

export const FETCH_CREATOR_GROUPS = {
    REQUEST: "FETCH_CREATOR_GROUPS_REQUEST",
    SUCCESS: "FETCH_CREATOR_GROUPS_SUCCESS",
    FAILURE: "FETCH_CREATOR_GROUPS_FAILURE"
};

export function fetchCreatorGroups(): Action {
    return {
        type: FETCH_CREATOR_GROUPS.REQUEST,
        payload: null
    };
}

export interface NormalizedCreatorGroupsResponse {
    [id: number]: CreatorGroup;
}

export function fetchCreatorGroupsSuccess(
    creatorGroups: NormalizedCreatorGroupsResponse
): Action {
    return {
        type: FETCH_CREATOR_GROUPS.SUCCESS,
        payload: creatorGroups
    };
}

export function fetchCreatorGroupsFailure(error: string): Action {
    return {
        type: FETCH_CREATOR_GROUPS.FAILURE,
        payload: { error }
    };
}

// Fetch A Creator Group ---------------------------------------------------------------

export const FETCH_CREATOR_GROUP = {
    REQUEST: "FETCH_CREATOR_GROUP_REQUEST",
    SUCCESS: "FETCH_CREATOR_GROUP_SUCCESS",
    FAILURE: "FETCH_CREATOR_GROUP_FAILURE"
};

export function fetchCreatorGroup(creatorGroupId: number): Action {
    return {
        type: FETCH_CREATOR_GROUP.REQUEST,
        payload: { creatorGroupId }
    };
}

export function fetchCreatorGroupSuccess(creatorGroup: any): Action {
    return {
        type: FETCH_CREATOR_GROUP.SUCCESS,
        payload: { creatorGroup }
    };
}

export function fetchCreatorGroupFailure(error: string): Action {
    return {
        type: FETCH_CREATOR_GROUP.FAILURE,
        payload: { error }
    };
}

// Create A Creator Group ---------------------------------------------------------------

export const CREATE_CREATOR_GROUP = {
    REQUEST: "CREATE_CREATOR_GROUP_REQUEST",
    SUCCESS: "CREATE_CREATOR_GROUP_SUCCESS",
    FAILURE: "CREATE_CREATOR_GROUP_FAILURE"
};

export interface CreatorGroupData {
    name: string;
    socialAccountIds: number[];
}

export function createCreatorGroup(
    creatorGroupData: CreatorGroupData
): Action {
    return {
        type: CREATE_CREATOR_GROUP.REQUEST,
        payload: { creatorGroupData }
    };
}

export function createCreatorGroupSuccess(creatorGroup: CreatorGroup): Action {
    return {
        type: CREATE_CREATOR_GROUP.SUCCESS,
        payload: { creatorGroup }
    };
}

export function createCreatorGroupFailure(error: string): Action {
    return {
        type: CREATE_CREATOR_GROUP.FAILURE,
        payload: { error }
    };
}

// Update A Creator Group ---------------------------------------------------------------

export const UPDATE_CREATOR_GROUP = {
    REQUEST: "UPDATE_CREATOR_GROUP_REQUEST",
    SUCCESS: "UPDATE_CREATOR_GROUP_SUCCESS",
    FAILURE: "UPDATE_CREATOR_GROUP_FAILURE"
};

// export function updateCreatorGroup(creatorGroupId: number, creatorGroupData: Partial<CreatorGroupData>): Action {
export function updateCreatorGroup(
    creatorGroupId: number,
    creatorGroupData: CreatorGroupData,
    noRedirect: boolean = false
): Action {
    return {
        type: UPDATE_CREATOR_GROUP.REQUEST,
        payload: { creatorGroupId, creatorGroupData, noRedirect }
    };
}

export function updateCreatorGroupSuccess(creatorGroup: CreatorGroup): Action {
    return {
        type: UPDATE_CREATOR_GROUP.SUCCESS,
        payload: { creatorGroup }
    };
}

export function updateCreatorGroupFailure(error: string): Action {
    return {
        type: UPDATE_CREATOR_GROUP.FAILURE,
        payload: { error }
    };
}

// Delete A Creator Group ---------------------------------------------------------------

export const DELETE_CREATOR_GROUP = {
    REQUEST: "DELETE_CREATOR_GROUP_REQUEST",
    SUCCESS: "DELETE_CREATOR_GROUP_SUCCESS",
    FAILURE: "DELETE_CREATOR_GROUP_FAILURE"
};

export function deleteCreatorGroupRequest(creatorGroupId: number): Action {
    return {
        type: DELETE_CREATOR_GROUP.REQUEST,
        payload: { creatorGroupId }
    };
}

export function deleteCreatorGroupSuccess(creatorGroupId: number): Action {
    return {
        type: DELETE_CREATOR_GROUP.SUCCESS,
        payload: { creatorGroupId }
    };
}

export function deleteCreatorGroupFailure(error: string): Action {
    return {
        type: DELETE_CREATOR_GROUP.FAILURE,
        payload: { error }
    };
}
