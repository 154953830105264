import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import {
    DirectBuyStepCollection,
    DirectBuyStepCollectionTreatment
} from "../../modules/direct-buy-step/types";
import { Description, Top } from "./styledComponents";
import { getIconForDirectBuyStepCollectionTreatment } from "./directBuyDetailsStepCollectionUtil";
import {
    directBuyStepIsExpandable,
    generateDescriptionForDirectBuyStepCollection
} from "../../modules/direct-buy-step/util";
import styled from "styled-components";
import { motion } from "framer-motion";
import { DirectBuyStepsEntitiesState } from "../../modules/direct-buy-step/reducer";
import { DirectBuyStepsExpandedState } from "../../../ui/direct-buys/reducer";
import { post } from "../../../Api";
import { useDispatch } from "react-redux";
import { updateDirectBuyStep } from "../../actions";
import { toggleDirectBuyStepExpandedState } from "../../../ui/direct-buys/actions";
import DirectBuyDetailsCopyLinks from "./copy-links/DirectBuyDetailsCopyLinks";
import DirectBuyDetailsMedia, {
    DirectBuyDetailsMediaMode
} from "./media/DirectBuyDetailsMedia";
import DirectBuyDetailsCaptions from "./captions/DirectBuyDetailsCaptions";
import DirectBuyDetailsScripts from "./scripts/DirectBuyDetailsScripts";
import DirectBuyDetailsCustomMedia from "./custom-media/DirectBuyDetailsCustomMedia";
import DirectBuyDetailsScreenshots from "./screenshots/DirectBuyDetailsScreenshots";
import DirectBuyDetailsLinkSubmissions from "./link-submissions/DirectBuyDetailsLinkSubmissions";
import DirectBuyDetailsTextSubmissions from "./text-submissions/DirectBuyDetailsTextSubmissions";
import DirectBuyDetailsRejectionReason from "./rejection-reason/DirectBuyDetailsRejectionReason";
import { StepById } from "src/buys/modules/open-buys/reducer";
import { BigBlueButton } from "src/profile/components/Buttons";
import { colors } from "src/constants";
import DirectBuyDetailsPaymentBrief from "./DirectBuyDetailsPaymentBrief";
import InstructionInfoList from "./components/InstructionInfoList";
import { useLocation } from "react-router";

const Main = styled(motion.div)`
    background: ${colors.white};
    padding: 12px;
    border-radius: 8px;
    width: 100%;
`;

const CaretContainer = styled.div`
    min-width: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const SubmissionButton = styled(BigBlueButton)`
    width: 100%;
    margin: 0px auto;
`;

const Caret = motion.img;

interface Props {
    collection: DirectBuyStepCollection;
    treatment: DirectBuyStepCollectionTreatment;
    steps: DirectBuyStepsEntitiesState | StepById;
    stepsExpandedState: DirectBuyStepsExpandedState;
    firstRender: boolean;
    openBuyId: number | null;
    position: number;
}

const DirectBuyDetailsStepCollection = ({
    collection,
    treatment,
    steps,
    stepsExpandedState,
    firstRender,
    openBuyId,
    position
}: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [description, setDescription] = useState(() =>
        generateDescriptionForDirectBuyStepCollection(collection, steps)
    );

    useEffect(() => {
        setDescription(
            generateDescriptionForDirectBuyStepCollection(collection, steps)
        );
    }, [collection, steps]);

    const [expandable, setExpandable] = useState(() =>
        directBuyStepIsExpandable(steps[collection.parent], steps)
    );
    useEffect(() => {
        setExpandable(
            directBuyStepIsExpandable(steps[collection.parent], steps)
        );
    }, [collection, steps]);

    let open = window.location.pathname.includes("open");

    const actionCallback = (directBuyStepId: number) => {
        if (open) return;
        post(`/v1/directBuyStep/${directBuyStepId}/complete`, {}).then(
            ({ data }) => {
                dispatch(updateDirectBuyStep(data.data));
            }
        );
    };

    return (
        <Main
            initial={{ opacity: firstRender ? 1 : 0, y: firstRender ? 0 : 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            transition={{ duration: 0.3 }}
        >
            <Top
                clickable={expandable}
                onClick={() => {
                    if (expandable) {
                        dispatch(
                            toggleDirectBuyStepExpandedState(collection.parent)
                        );
                    }
                }}
            >
                {getIconForDirectBuyStepCollectionTreatment(
                    treatment,
                    position
                )}
                <Description>
                    <ReactMarkdown>{description}</ReactMarkdown>
                </Description>
                {expandable && (
                    <CaretContainer>
                        <Caret
                            src={"/caret.svg"}
                            initial={{
                                rotate: stepsExpandedState[collection.parent]
                                    ? 180
                                    : 0
                            }}
                            animate={{
                                rotate: stepsExpandedState[collection.parent]
                                    ? 180
                                    : 0
                            }}
                        />
                    </CaretContainer>
                )}
            </Top>
            <DirectBuyDetailsCopyLinks
                collection={collection}
                steps={steps}
                actionCallback={() => {
                    actionCallback(collection.parent);
                }}
            />
            <DirectBuyDetailsMedia
                mode={DirectBuyDetailsMediaMode.media}
                collection={collection}
                steps={steps}
                actionCallback={() => {
                    actionCallback(collection.parent);
                }}
            />
            <DirectBuyDetailsCaptions
                collection={collection}
                steps={steps}
                actionCallback={() => {
                    actionCallback(collection.parent);
                }}
            />
            <DirectBuyDetailsScripts
                collection={collection}
                steps={steps}
                actionCallback={() => {
                    actionCallback(collection.parent);
                }}
                openBuyId={openBuyId}
            />
            <DirectBuyDetailsMedia
                mode={DirectBuyDetailsMediaMode.assets}
                collection={collection}
                steps={steps}
                actionCallback={() => {
                    actionCallback(collection.parent);
                }}
            />
            <DirectBuyDetailsCustomMedia
                collection={collection}
                steps={steps}
                open={open}
            />
            <DirectBuyDetailsScreenshots
                collection={collection}
                steps={steps}
                open={open}
            />
            <DirectBuyDetailsLinkSubmissions
                collection={collection}
                steps={steps}
                open={open}
            />
            <DirectBuyDetailsTextSubmissions
                collection={collection}
                steps={steps}
                open={open}
            />
            <DirectBuyDetailsRejectionReason
                collection={collection}
                steps={steps}
            />
            <DirectBuyDetailsPaymentBrief
                parentStep={steps[collection.parent]}
            />
            {steps[collection.parent].requiresCustomMedia && (
                <InstructionInfoList />
            )}
        </Main>
    );
};

export default DirectBuyDetailsStepCollection;
