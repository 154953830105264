import React, { ReactNode } from "react";
import styled from "styled-components";
import MediaStatus from "../components/MediaStatus";

const Main = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    color: white;
    pointer-events: none;
    text-align: left;
`;

const Top = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > * ~ * {
        margin-top: 4px;
    }
`;

const Title = styled.div<{ titleSize: number; wrapTitle?: true }>`
    font-size: ${props => props.titleSize}px;
    line-height: 15.51px;
    font-weight: bold;
    ${props => props.wrapTitle && "margin-right: 27px"}
`;

const Subtitle = styled.div<{ subtitleSize: number }>`
    font-size: ${props => props.subtitleSize}px;
    line-height: 1em;
`;

const Bottom = styled.div<{ bottomSize: number }>`
    font-size: ${props => props.bottomSize}px;
    line-height: 1em;
    font-weight: bold;
`;

const BottomSecondary = styled.div`
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    margin-top: 4px;
`;

interface Props {
    title: string;
    titleSize?: number;
    status?: ReactNode;
    wrapTitle?: true;
    subtitle?: string;
    subtitleSize?: number;
    bottom?: string;
    bottomSize?: number;
    bottomSecondary?: string | null;
}

const MediaTextOverlay = ({
    title,
    titleSize = 19,
    status,
    wrapTitle,
    subtitle,
    subtitleSize = 12,
    bottom,
    bottomSize = 14,
    bottomSecondary
}: Props) => {
    return (
        <Main>
            <Top>
                <Title titleSize={titleSize} wrapTitle={wrapTitle}>
                    {title}
                </Title>
                {subtitle && (
                    <Subtitle subtitleSize={subtitleSize}>{subtitle}</Subtitle>
                )}
                {status}
            </Top>
            <div>
                <Bottom bottomSize={bottomSize}>{bottom}</Bottom>
                {bottomSecondary && (
                    <BottomSecondary>{bottomSecondary}</BottomSecondary>
                )}
            </div>
        </Main>
    );
};

export default MediaTextOverlay;
