import React from "react";
import styled, { keyframes } from "styled-components";
import { colors, fonts } from "src/constants";
import FullScreenModal from "src/modals/FullScreenModal";
import SimpleNavbar from "src/components/SimpleNavbar";
import LeaderboardPhoto from "src/components/LeaderboardPhoto";
import { formatRank } from "src/util";
import CoinReward from "src/components/CoinReward";
import { PublisherEarnings, WeeklyLeaderboardItem } from "src/profile/types";
import EarningsList from "./EarningsList";

const Button = styled.img`
    padding: 15px;
    cursor: pointer;

    svg {
        margin-right: 15px;
    }
`;

const NameContainer = styled.div`
    margin-left: 10px;
    text-align: left;
    overflow: hidden;
`;

const Name = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Bonus = styled.div`
    font-size: 12px;
    color: ${colors.secondaryGray};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const List = styled.div`
    padding: 20px 0px;
`;

const Container = styled.div`
    text-align: left;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    padding: 12px;
    margin: 17px;
`;

const Amount = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 500;
    margin-left: auto;
`;

const PromoText = styled.h1`
    font-size: 12px;
    color: ${colors.secondaryGray};
    text-align: left;
    margin-left: 18px;
    font-weight: 700;
`;
interface Props {
    closeCallback: () => void;
    earnings?: PublisherEarnings[];
    publisher: WeeklyLeaderboardItem;
}

const Publisher = ({ closeCallback, publisher, earnings }: Props) => {
    return (
        <FullScreenModal closeModal={closeCallback}>
            <SimpleNavbar
                title={"Anonymous User"}
                left={<Button onClick={closeCallback} src={"/close.svg"} />}
            />
            <List>
                <Container>
                    <LeaderboardPhoto
                        profilePhotoUrl={publisher.profilePhotoUrl}
                        type={publisher.type}
                    />
                    <NameContainer>
                        <Name>
                            {publisher.type === "own"
                                ? `${formatRank(publisher.rank)} (you)`
                                : formatRank(publisher.rank)}
                        </Name>
                        <Bonus>{publisher.bonusPercentage}% bonus</Bonus>
                    </NameContainer>
                    <Amount>{publisher.earnings}</Amount>
                </Container>
                <PromoText style={{ fontSize: 12 }}>Promoting</PromoText>
                <EarningsList earnings={earnings} />
            </List>
        </FullScreenModal>
    );
};

export default Publisher;
