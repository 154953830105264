import React, { useState } from "react";
import SmallModal from "src/modals/SmallModal";
import withModalPortal from "../../../components/withModalPortal";
import styled from "styled-components";
import { BlueButton, GrayButton } from "src/profile/components/Buttons";
import { colors } from "src/constants";
import ExtendChallengeModal from "./ExtendChallengeModal";
import { useDispatch } from "react-redux";
import { setPillNotificationText } from "src/ui/notifications/actions";
import { patch } from "src/Api";
import { adminFetchOpenBuy } from "src/admin-tools/actions.admin-tools";

const Heading = styled.h4`
    font-weight: 500px;
    font-size: 18px;
    text-align: left;
    padding-bottom: 16px;
`;
const SubHeading = styled(Heading)`
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 16px;
`;

const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const EndButton = styled(BlueButton)`
    padding: 8px 12px;
    margin: 12px 4px 0px;
`;

const CancelButton = styled(GrayButton)`
    padding: 8px 12px;
    margin: 12px 4px 0px;
`;

const OpenExtensionModalClickable = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    padding-bottom: 16px;
    grid-template-areas:
        "title caret"
        "subtitle caret";
    cursor: pointer;
    border-radius: 6px;
    padding: 8px;

    &:hover {
        background-color: ${colors.lightGray};
    }
`;
const ClickableTitle = styled.h5`
    grid-area: title;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
`;
const ClickableSubtitle = styled(ClickableTitle)`
    grid-area: subtitle;
    font-size: 12px;
    font-weight: 400;
    color: ${colors.primaryGray};
`;
const Caret = styled.img`
    grid-area: caret;
    transform: rotate(270deg);
    margin-right: 3px;
    align-self: center;
`;

interface Props {
    closeModal: VoidFunction;
    openBuy: any;
}
const StopChallengeModal = ({ closeModal, openBuy }: Props) => {
    const [extendModalOpen, setExtendModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [publisherIDsToExtend, setPublisherIDsToExtend] = useState<number[]>(
        []
    );
    const dispatch = useDispatch();
    const handleEnd = async () => {
        if (!openBuy) {
            dispatch(
                setPillNotificationText("Error: no open buy", "danger", 1000)
            );
            return;
        }
        try {
            setLoading(true);
            await patch(`/v1/openBuy/${openBuy.id}/end`, {
                ...(publisherIDsToExtend.length && { publisherIDsToExtend })
            });
            dispatch(adminFetchOpenBuy(openBuy.id));
            dispatch(
                setPillNotificationText(`Successfully ended ${openBuy.name}`)
            );
            closeModal();
        } catch (err) {
            dispatch(
                setPillNotificationText(
                    "Error: could not end challenge",
                    "danger",
                    3500
                )
            );
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SmallModal
            header={{ text: "Stop Challenge", handleClose: closeModal }}
        >
            <Heading>Confirm Challenge Stop</Heading>
            <SubHeading>
                {openBuy.publisherCount - publisherIDsToExtend.length} Creators
                will no longer be able to make submissions.
            </SubHeading>

            <OpenExtensionModalClickable
                onClick={() => setExtendModalOpen(true)}
            >
                <ClickableTitle>
                    Extend this Offer for In Progress Creators
                </ClickableTitle>
                <ClickableSubtitle>
                    {publisherIDsToExtend.length} Extensions
                </ClickableSubtitle>
                <Caret src="/caret.svg" />
            </OpenExtensionModalClickable>
            {extendModalOpen && (
                <ExtendChallengeModal
                    closeModal={() => setExtendModalOpen(false)}
                    openBuyId={openBuy.id}
                    publisherIDsToExtend={publisherIDsToExtend}
                    setPublisherIDsToExtend={ids => {
                        setPublisherIDsToExtend([...new Set(ids)]);
                    }}
                />
            )}

            <ButtonBox>
                <CancelButton onClick={closeModal}>Cancel</CancelButton>
                <EndButton shimmer={loading} onClick={handleEnd}>
                    End
                </EndButton>
            </ButtonBox>
        </SmallModal>
    );
};

export default withModalPortal(StopChallengeModal);
