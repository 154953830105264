import React from "react";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";
import styled from "styled-components";
import SimpleNavbar from "../components/SimpleNavbar";
import NavButton from "../components/NavButton";
import MainContainer from "src/components/MainContainer";
import { sizes, fonts } from "src/constants";

const ScrollOuter = styled.div`
    display: flex;
    height: ${`calc(100vh - ${sizes.navbar.mobileHeight}px)`};
    overflow: scroll;
`;

const ScrollInner = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 100%);
    width: 100%;
    height: 100%;
`;

const ExampleContainer = styled.div`
    padding: 20px;
`;

const ExampleImage = styled.img`
    height: 80%;
`;

const BottomText = styled.div`
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: ${fonts.subtitle}px;
`;

const Example = ({
    image,
    title,
    subtitle
}: {
    image: string;
    title: string;
    subtitle: string;
}) => {
    return (
        <ExampleContainer>
            <ExampleImage src={image} />
            <BottomText>
                <strong>{title}</strong>
                <div>{subtitle}</div>
            </BottomText>
        </ExampleContainer>
    );
};

const RulesExamples = ({ goBack }: { goBack: VoidFunction }) => {
    const leftNavButton = <NavButton onClick={() => goBack()} back />;

    return (
        <>
            <SimpleNavbar left={leftNavButton} />

            <MainContainer>
                <ScrollOuter>
                    <ScrollInner>
                        <Example
                            image={"/giveaway_example/giveaway_example.png"}
                            title={"No Giveaways"}
                            subtitle={
                                "Promising users a prize of any kind if they download you app is not allowed"
                            }
                        />
                        <Example
                            image={"/chat_example/chat_example.png"}
                            title={`No 'Download to Chat'`}
                            subtitle={
                                "Promising users the ability to chat with you or anyone else (real of fictional) is not allowed"
                            }
                        />
                        <Example
                            image={"/shoutout_example/shoutout_example.png"}
                            title={"No Follow Backs"}
                            subtitle={
                                "Promising to follow users back if they download your app is not allowed"
                            }
                        />
                        <Example
                            image={"/proof_example/proof_example.png"}
                            title={"No Send Proof"}
                            subtitle={
                                "Asking for proof that a user downloaded a app is not allowed"
                            }
                        />
                    </ScrollInner>
                </ScrollOuter>
            </MainContainer>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    goBack: () => dispatch(goBack())
});

export default connect(null, mapDispatchToProps)(RulesExamples);
