import { combineReducers } from "redux";
import { NormalizedAction } from "./../../types";
import { FETCH_CREATORS } from "./actions.creator-search";
import { Creator } from "./types.creator-groups";

interface ById {
    [id: number]: Creator;
}

export interface CreatorSearchEntitiesState {
    byId: ById;
    allIds: number[];
}

const initialCreatorSearchState: CreatorSearchEntitiesState = {
    byId: {},
    allIds: []
};

function byId(
    state = initialCreatorSearchState.byId,
    action: NormalizedAction
) {
    switch (action.type) {
        case FETCH_CREATORS.SUCCESS:
            if (action.payload.sortingOrFiltering) {
                return { ...action.payload.creator.entities.creators };
            } else {
                return {
                    ...state,
                    ...action.payload.creator.entities.creators
                };
            }

        default:
            return state;
    }
}

function allIds(
    state = initialCreatorSearchState.allIds,
    action: NormalizedAction
) {
    switch (action.type) {
        case FETCH_CREATORS.SUCCESS:
            if (action.payload.sortingOrFiltering) {
                return [...action.payload.creator.result];
            } else {
                return [...state, ...action.payload.creator.result];
            }

        default:
            return state;
    }
}

const creatorSearchEntitiesReducer = combineReducers<
    CreatorSearchEntitiesState
>({
    byId,
    allIds
});

export default creatorSearchEntitiesReducer;
