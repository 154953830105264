import React, { ChangeEvent, useState } from "react";
import { Connection } from "../../profile/types";
import ImageModal from "../../modals/ImageModal";
import { BlueModalButton } from "../../profile/components/Buttons";
import ModalNameHeader from "../components/ModalNameHeader";
import ModalNameInput from "../components/ModalNameInput";

interface Props {
    sdk: any;
    selectedConnections: Connection[];
    onCreate: (channelUrl: string) => void;
}

const CreateGroupModal = ({ sdk, selectedConnections, onCreate }: Props) => {
    const [newCover, setNewCover] = useState("");
    const [coverFile, setCoverFile] = useState<File | null>(null);

    const [groupName, setGroupName] = useState("");

    function imageUpload(e: ChangeEvent<HTMLInputElement>) {
        if (e.currentTarget.files) {
            const file = e.currentTarget.files[0];

            setNewCover(URL.createObjectURL(file));

            setCoverFile(file);
        }
    }

    function classifyChannel(channel: any) {
        channel.getMetaData(["type"], (data: any, error: any) => {
            if (error) {
                window.alert(error);
            } else {
                if (data.type) {
                    onCreate(channel.url);
                } else {
                    const type = selectedConnections.length > 1 ? "group" : "direct";

                    channel.createMetaData({ type }, (data: any, error: any) => {
                        if (!error) {
                            onCreate(channel.url);
                        } else {
                            window.alert(error);
                        }
                    });
                }
            }
        });
    }

    function createChannel() {
        if (
            sdk.GroupChannel &&
            sdk.GroupChannelParams &&
            selectedConnections[0]
        ) {
            const params = new sdk.GroupChannelParams();
            params.isPublic = false;
            params.isEphemeral = false;
            params.name = groupName;
            if (coverFile) {
                params.coverImage = coverFile;
            } else {
                params.coverUrl = "";
            }

            const selfId = selectedConnections[0].entity.id;

            params.addUserIds([
                ...selectedConnections.map(connection => connection.other.id),
                selfId
            ]);
            params.operatorUserIds = [selfId];

            sdk.GroupChannel.createChannel(
                params,
                (channel: any, error: any) => {
                    if (!error) {
                        classifyChannel(channel);
                    } else {
                        window.alert(error);
                    }
                }
            );
        }
    }

    const [placeholder, setPlaceholder] = useState("Group Name");

    return (
        <ImageModal
            imageUrl={newCover || "/profile_pic_blue/profile_pic_blue@2x.png"}
            imageUploadCallback={imageUpload}
        >
            <ModalNameHeader>Give this group a name:</ModalNameHeader>
            <ModalNameInput
                placeholder={placeholder}
                value={groupName}
                onChange={e => {
                    setGroupName(e.currentTarget.value);
                }}
                onFocus={() => {
                    setPlaceholder("");
                }}
                onBlur={() => {
                    setPlaceholder("Group Name");
                }}
            />
            <BlueModalButton onClick={createChannel}>
                Create Group
            </BlueModalButton>
        </ImageModal>
    );
};

export default CreateGroupModal;
