import React from "react";
import { useDispatch } from "react-redux";
import BreadCrumbs from "src/components/headers/BreadCrumbs";
import { colors } from "src/constants";
import styled, { css } from "styled-components";
import { convertPathsToCleanTexts } from "../instructions-sets/utils/instructions-sets";
import HeaderBanner, { BannerColor } from "../landing/components/HeaderBanner";

const Header = styled.header<{ includeHeading?: true }>`
    padding-bottom: 12px;
    text-align: left;

    ${props =>
        props.includeHeading &&
        css`
            margin-bottom: 24px;
            border-bottom: ${colors.quaternaryLightGray} solid 1px;
        `};
`;

const Title = styled.h1`
    margin-top: 24px;
    margin-bottom: 0px;
    color: ${colors.black};
    font-size: 36px;
    text-transform: capitalize;
`;

const TitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const ButtonWrap = styled.div`
    display: flex;
    gap: 8px;
    & > a:hover {
        text-decoration: none;
    }
`;
const StyledHeaderBanner = styled(HeaderBanner)`
    margin-top: 16px;
`;

interface Props {
    endCrumb?: string;
    rightComponent?: React.ReactNode;
    customCrumbs?: string[];
    includeHeading?: true;
    hideCrumbs?: boolean;
    buttons?: React.ReactNode;
    headerTitle?: string;
    banner?: { color: BannerColor; message: string };
}

const MarketplacePagePartialHeader = ({
    rightComponent,
    endCrumb,
    customCrumbs,
    includeHeading,
    hideCrumbs,
    buttons,
    headerTitle,
    banner
}: Props) => {
    const dispatch = useDispatch();

    const paths = convertPathsToCleanTexts(window.location.pathname.split("/"));
    const title = paths[paths.length - 1].split("?")[0];

    return (
        <Header includeHeading={includeHeading}>
            <BreadCrumbs
                hideCrumbs={hideCrumbs}
                customCrumbs={customCrumbs}
                endCrumb={endCrumb}
            />
            {banner && (
                <StyledHeaderBanner
                    message={banner.message}
                    color={banner.color}
                />
            )}
            <TitleWrap>
                {includeHeading && (
                    <Title>
                        {headerTitle
                            ? headerTitle
                            : endCrumb
                            ? endCrumb
                            : title}
                    </Title>
                )}
                {rightComponent}
                {!!buttons && <ButtonWrap>{buttons}</ButtonWrap>}
            </TitleWrap>
        </Header>
    );
};

export default MarketplacePagePartialHeader;
