import React from "react";
import styled from "styled-components";
import { Shimmer } from "src/components/SkeletonLoader";
import { useDispatch } from "react-redux";
import { setLinkListType } from "src/ui/link-lists/actions";
import { push } from "connected-react-router";
import SimpleNavbar from "src/components/SimpleNavbar";
import NavButton from "src/components/NavButton";
import { BlueButton } from "src/profile/components/Buttons";
import { Header } from "src/components/text/CommonStyles";
import Subtitle from "src/components/text/Subtitle";
import Title from "src/components/text/Title";
import { breakpoints } from "src/constants";

const LinkInfoLoader = styled.div`
    border-radius: 20px;
    max-width: 700px;
    width: 100%;
    margin-top: 10px;
    margin: 0 18px;
    align-self: center;
    height: 252px;
    padding: 45px;
    ${Shimmer}
`;

const PreviewLoader = styled.div`
    margin: auto;
    padding: 15px 25px 15px 25px;
    border-radius: 6px;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 5px);
    width: 100%;
    height: 70px;
    ${Shimmer}
`;

const CreateButton = styled(BlueButton)`
    height: 50px;
    font-size: 16px;
    width: 700px;
    margin-right: 10px;
    margin: 50px auto;
    @media (max-width: ${breakpoints.small}px) {
        width: 90vw;
    }
`;

const Preview = styled(Subtitle)`
    margin: 16px 0 16px 0;
    font-weight: bold;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 900px;
    padding: 0 18px 0 18px;
`;

const LinkDetailsLoader = () => {
    const dispatch = useDispatch();
    let listIdReg = new RegExp("(?<=linklist/)([0-9]*)(?=/link)");
    let listMatch = window.location.pathname.match(listIdReg);
    const listId = listMatch ? parseInt(listMatch[0]) : null;
    return (
        <>
            <SimpleNavbar
                title={"Loading Link"}
                left={
                    <NavButton
                        back
                        onClick={() => {
                            dispatch(push(`/profile/linklist/${listId}`));

                            dispatch(setLinkListType(undefined));
                        }}
                    />
                }
                right={
                    <div
                        style={{
                            cursor: "pointer"
                        }}
                    >
                        <img src="/ellipsis-button.svg" alt="" />
                    </div>
                }
            />
            <Container>
                <Header>
                    <Title>Link Details</Title>
                    <Subtitle>Customize your link below</Subtitle>
                </Header>
                <LinkInfoLoader />
                <Preview>PREVIEW</Preview>
                <PreviewLoader />
                <CreateButton>...</CreateButton>
            </Container>
        </>
    );
};
export default LinkDetailsLoader;
