import { Platform } from "src/marketplace/utils/types";
import { Action } from "src/types";
import { CurrentCreateOfferStep, WizardOfferType } from "./reducers";

// Set Current Wizard Step -----------------------------------------------

export const SET_CURRENT_WIZARD_STEP = "SET_CURRENT_WIZARD_STEP";

export function setCurrentWizardStep(
    currentWizardStep: CurrentCreateOfferStep
): Action {
    return {
        type: SET_CURRENT_WIZARD_STEP,
        payload: { currentWizardStep }
    };
}

// Set Wizard Offer Type -----------------------------------------------

export const SET_WIZARD_OFFER_TYPE = "SET_WIZARD_OFFER_TYPE";

export function setWizardOfferType(offerType: WizardOfferType): Action {
    return {
        type: SET_WIZARD_OFFER_TYPE,
        payload: { offerType }
    };
}

// Set Wizard Challenge Thumbnail Image -----------------------------------------------

export const SET_WIZARD_CHALLENGE_THUMBNAIL_IMAGE =
    "SET_WIZARD_CHALLENGE_THUMBNAIL_IMAGE";

export function setWizardChallengeThumbnailImage(
    challengeThumbnailImage: string
): Action {
    return {
        type: SET_WIZARD_CHALLENGE_THUMBNAIL_IMAGE,
        payload: { challengeThumbnailImage }
    };
}

// Set Wizard Exclusive Challenge -----------------------------------------------

export const SET_WIZARD_EXCLUSIVE_CHALLENGE = "SET_WIZARD_EXCLUSIVE_CHALLENGE";

export function setWizardExclusiveChallenge(
    requireExclusiveChallenge: boolean
): Action {
    return {
        type: SET_WIZARD_EXCLUSIVE_CHALLENGE,
        payload: { requireExclusiveChallenge }
    };
}

// Set Wizard Charge Advertiser Per Install -----------------------------------------------

export const SET_WIZARD_CHARGE_ADVERTISER_PER_INSTALL =
    "SET_WIZARD_CHARGE_ADVERTISER_PER_INSTALL";

export function setWizardChargeAdvertiserPerInstall(
    chargeAdvertiserPerInstall: boolean
): Action {
    return {
        type: SET_WIZARD_CHARGE_ADVERTISER_PER_INSTALL,
        payload: { chargeAdvertiserPerInstall }
    };
}

// Set Wizard Platform -----------------------------------------------

export const SET_WIZARD_PLATFORM = "SET_WIZARD_PLATFORM";

export function setWizardPlatform(platform: Platform): Action {
    return {
        type: SET_WIZARD_PLATFORM,
        payload: { platform }
    };
}

// Set Wizard Challenge Offer -----------------------------------------------

export const SET_WIZARD_CHALLENGE_OFFER = "SET_WIZARD_CHALLENGE_OFFER";

export function setWizardChallengeOffer(
    challengeTitle: string,
    challengeBrief: string,
    challengeLinkUrl: string,
    jetfuelPlatformFee: number,
    advertiserBudget: number,
    creatorPayoutBudget: number,
    selectedOpenBuy?: number
): Action {
    return {
        type: SET_WIZARD_CHALLENGE_OFFER,
        payload: {
            challengeTitle,
            challengeBrief,
            challengeLinkUrl,
            jetfuelPlatformFee,
            advertiserBudget,
            creatorPayoutBudget,
            selectedOpenBuy
        }
    };
}

// Set Offer Creation Modal Completed -----------------------------------------------

export const SET_OFFER_CREATION_MODAL_COMPLETED =
    "SET_OFFER_CREATION_MODAL_COMPLETED";

export function setOfferCreationModalCompleted(
    offerCreationModalCompleted: boolean
): Action {
    return {
        type: SET_OFFER_CREATION_MODAL_COMPLETED,
        payload: { offerCreationModalCompleted }
    };
}
