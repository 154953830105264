import React from "react";
import {
    CampaignHit,
    GenericHit,
    PublisherHit,
    SocialAccountHit
} from "../types";
import CampaignHitRow from "./CampaignHitRow";
import UserHitRow from "./UserHitRow";
import AccountHitRow from "./AccountHitRow";
import { Results } from "./StyledComponents";
import { useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import {
    isCampaignHit,
    isPublisherHit,
    isSocialAccountHit,
    selectFilteredSlicedHits
} from "../selector";

interface Props {
    index: string;
}

const HitsList = ({ index }: Props) => {
    const hits = useSelector((state: GlobalState) =>
        selectFilteredSlicedHits(state, index)
    );

    return (
        <Results>
            {(hits as Array<CampaignHit | PublisherHit | SocialAccountHit>).map(
                hit => {
                    if (index === "campaigns" && isCampaignHit(hit))
                        return <CampaignHitRow key={hit.id} hit={hit} />;
                    else if (index === "publishers" && isPublisherHit(hit))
                        return <UserHitRow key={hit.id} hit={hit} />;
                    else if (
                        index === "social-accounts" &&
                        isSocialAccountHit(hit)
                    )
                        return <AccountHitRow key={hit.id} hit={hit} />;
                }
            )}
        </Results>
    );
};

export default HitsList;
