import React from "react";
import { PublicProfile, SocialAccount } from "../../../types";
import { ProfileModal } from "../../../reducer";
import AccountActionsModal from "../modals/AccountActionsModal";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import NavButton from "../../../../components/NavButton";
import ViewHeader from "../../components/ViewHeader";
import Divider from "../../../../components/Divider";
import Header from "../../../../components/Header";
import OwnerCell from "../../components/OwnerCell";
import LockCard from "../../../components/LockCard";
import PaddingNoTop from "../../../../components/PaddingNoTop";
import VerifyModal from "../modals/VerifyModal";
import MainContainer from "src/components/MainContainer";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { getRatesForSocialAccount } from "src/profile/rates/selectors";
import RateItem from "src/profile/rates/RateItem";
import { BigOutlineButton } from "src/profile/components/Buttons";
import {
    rateFormModalOpen,
    setAccountId,
    setIsRateFormEdit
} from "src/ui/profile/actions";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const RatesList = styled.div`
    padding: 0 15px 15px;

    & > * ~ * {
        margin-top: 10px;
    }
`;

interface Props {
    account: SocialAccount;
    publicProfile: PublicProfile;
    modal: ProfileModal;
    push: (path: string) => void;
    goBack: VoidFunction;
    actionsModal: VoidFunction;
    verifyModal: VoidFunction;
}

const AccountView = ({
    account,
    publicProfile,
    modal,
    push,
    goBack,
    actionsModal,
    verifyModal
}: Props) => {
    const ownersArray = [publicProfile];
    const rates = useSelector((state: GlobalState) =>
        getRatesForSocialAccount(state, account.id)
    );
    const splitAccountRates = useSelector(
        selectFeatureFlags(featureFlags.AccountRates)
    );

    const dispatch = useDispatch();

    function openRateEdit(accountId: number): void {
        dispatch(rateFormModalOpen(true));
        dispatch(setIsRateFormEdit(false));
        dispatch(setAccountId(accountId));
    }

    return (
        <>
            <SimpleNavbar
                left={<NavButton back onClick={goBack} />}
                right={
                    <NavButton right onClick={actionsModal}>
                        <img src="/ellipsis-button.svg" />
                    </NavButton>
                }
            />

            <MainContainer>
                <ViewHeader
                    account={account}
                    editCallback={() => push("/profile/accounts/edit")}
                />

                <Header
                    title={`Owner${ownersArray.length > 1 ? "s" : ""}`}
                    subtitle={`${ownersArray.length} Account Owner${
                        ownersArray.length > 1 ? "s" : ""
                    }`}
                />
                {account.verified ? (
                    ownersArray.map(profile => {
                        return (
                            <OwnerCell
                                key={profile.id}
                                publicProfile={profile}
                            />
                        );
                    })
                ) : (
                    <PaddingNoTop>
                        <LockCard
                            text={
                                "You have not completed the verification process for this account"
                            }
                            button={{
                                text: "Verify",
                                callback: verifyModal
                            }}
                        />
                    </PaddingNoTop>
                )}
                {account.verified && <Divider />}
                {splitAccountRates && account.verified && (
                    <>
                        <Header
                            title="Rates"
                            subtitle={`You have a total of ${rates.length} rate(s) for this account`}
                        />
                        <RatesList>
                            {rates.map(rate => (
                                <RateItem
                                    key={rate.id}
                                    publisherOffer={rate}
                                    accountId={account.id}
                                />
                            ))}
                            <BigOutlineButton
                                onClick={() => {
                                    dispatch(setAccountId(account.id));
                                    push("/profile/accounts/rates");
                                }}
                            >
                                Add Rates
                            </BigOutlineButton>
                        </RatesList>
                    </>
                )}
                {modal === ProfileModal.actions && (
                    <AccountActionsModal account={account} push={push} />
                )}

                {modal === ProfileModal.verify && (
                    <VerifyModal account={account} />
                )}
            </MainContainer>
        </>
    );
};

export default AccountView;
