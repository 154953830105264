import React from "react";
import { replace } from "connected-react-router";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { GlobalState } from "../../../reducers";
import { BigBlueButton } from "../../../profile/components/Buttons";
import { fonts, sizes } from "src/constants";

interface HeaderButtonProps {
    disabled?: boolean;
    inModal?: true;
}

const HeaderButton = styled(BigBlueButton)<HeaderButtonProps>`
    /* pointer-events: none; */
    user-select: none;
    ${props => props.disabled && `pointer-events: none; opacity: 0.5;`}

    ${props =>
        props.inModal &&
        css`
            height: auto;
            padding: 8px 0px;
        `}

    &:active {
        opacity: 0.5;
    }
`;

interface Props {
    currentCampaign: number | null;
    pathname: string;
    disabled?: true;
    text?: string;
    replace: (route: string, state: any) => void;
    scrollToBottom?: () => void;
    onClick?: () => void;
    inModal?: true;
}

const PromoteButton = ({
    currentCampaign,
    pathname,
    disabled,
    text,
    replace,
    scrollToBottom,
    onClick,
    inModal
}: Props) => {
    return (
        <HeaderButton
            disabled={disabled}
            onMouseDown={e => {
                e.stopPropagation();
            }}
            onClick={() => {
                if (onClick) {
                    onClick();
                } else if (pathname.includes("promote") && scrollToBottom) {
                    scrollToBottom();
                } else {
                    replace(`/campaign/${currentCampaign}/promote`, {
                        scrollToPromote: true
                    });
                }
            }}
            inModal={inModal}
        >
            {text || "Promote"}
        </HeaderButton>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    pathname: state.router.location.pathname,
    currentCampaign: state.ui.campaign.currentCampaignId
});

const mapDispatchToProps = (dispatch: any) => ({
    replace: (route: string, state: any) => dispatch(replace(route, state))
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoteButton);
