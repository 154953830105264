import { firestore } from "firebase";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "src/modals/ErrorModal";
import { GlobalState } from "src/reducers";
import { setConversationPauseDuration } from "../actions";
import GroupPauseNotificationsModal from "../modals/GroupPauseNotificationsModal";
import {
    selectConversationOwnAdminStatus,
    selectConversationOwnPauseDuration
} from "../selectors";
import GroupToggleOption from "./GroupToggleOption";

interface Props {
    conversationId: string;
}

const GroupOptions = ({ conversationId }: Props) => {
    const dispatch = useDispatch();
    const [pauseDurationModal, togglePauseDurationModal] = useState(false);
    const status = useSelector((state: GlobalState) =>
        selectConversationOwnPauseDuration(state, conversationId)
    );
    const [errorModal, toggleErrorModal] = useState(false);
    const isGroupAdmin = useSelector((state: GlobalState) =>
        selectConversationOwnAdminStatus(state, conversationId)
    );

    function togglePublic() {
        if (!isGroupAdmin) {
            toggleErrorModal(true);
        }
    }

    return (
        <>
            <GroupToggleOption
                label="Pause Notifications"
                status={status}
                active={!!status}
                toggle={() => {
                    if (!status) {
                        togglePauseDurationModal(true);
                    } else {
                        dispatch(
                            setConversationPauseDuration({
                                conversationId,
                                notificationsPausedIndefinitely: false,
                                notificationsPausedUntil: null
                            })
                        );
                    }
                }}
            />
            <GroupToggleOption
                label="Public Group"
                disabled
                status=""
                active={false}
                toggle={togglePublic}
            />
            <AnimatePresence>
                {pauseDurationModal && (
                    <GroupPauseNotificationsModal
                        conversationId={conversationId}
                        closeModal={() => togglePauseDurationModal(false)}
                    />
                )}
            </AnimatePresence>
            {errorModal && (
                <ErrorModal
                    closeModal={() => toggleErrorModal(false)}
                    message="Only administrators can edit group information."
                />
            )}
        </>
    );
};

export default GroupOptions;
