import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { AnalyticsDataStore } from "./reducer";
import {
    fetchRaw24HourAnalyticsData,
    fetchRawAnalyticsData,
    reportParameters
} from "./actions";
import { formatNumber } from "../util";
import styled from "styled-components";
import CoinSummary from "../components/CoinSummary";
import TotalEarningsCell from "./TotalEarningsCell";
import Button from "react-bootstrap/Button";
import AnalyticsDataParser, {
    CampaignTotals,
    MediaTotals
} from "./AnalyticsDataParser";
import { AnalyticsFilterState } from "./AnalyticsMain";
import AnalyticsControlButtons from "./AnalyticsControlButtons";
import AnalyticsLoader from "./AnalyticsLoader";
import { emailReportModal } from "./actions";
import { colors } from "../constants";
import AnalyticsEmptyState from "./components/AnalyticsEmptyState";

const List = styled.div`
    padding: 10px 15px 15px;
`;

const Bottom = styled.div`
    padding: 0px 15px 15px;
`;

const Link = styled.div`
    color: ${colors.primaryBlue};
    user-select: none;
    cursor: pointer;
`;

interface Props {
    analyticsDataStore: AnalyticsDataStore;
    analyticsLiveDataStore: AnalyticsDataStore;
    fetchRawAnalyticsData: (payload: reportParameters) => void;
    fetchRaw24HourAnalyticsData: () => void;
    emailReportModal: (emailDateRange: [string, string] | null) => void;
    filterState: AnalyticsFilterState;
    parsedData: (CampaignTotals | MediaTotals)[];
    setParsedData: (parsedData: (CampaignTotals | MediaTotals)[]) => void;
    pickDates: () => void;
    pickFilters: () => void;
    showMore?: () => void;
}

const TotalEarningsCard = ({
    analyticsDataStore,
    analyticsLiveDataStore,
    fetchRawAnalyticsData,
    fetchRaw24HourAnalyticsData,
    emailReportModal,
    filterState,
    parsedData,
    setParsedData,
    pickDates,
    pickFilters,
    showMore
}: Props) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (
            filterState.dateRange.use24HourEndpoint &&
            !filterState.startDate &&
            !filterState.endDate
        ) {
            return;
        }

        const parser = new AnalyticsDataParser(analyticsDataStore);

        let startDate;
        let endDate;

        if (filterState.startDate && filterState.endDate) {
            startDate = filterState.startDate.format("YYYY-MM-DD");
            endDate = filterState.endDate.format("YYYY-MM-DD");
        } else {
            startDate = filterState.dateRange.dateRange.startDate.format(
                "YYYY-MM-DD"
            );
            endDate = filterState.dateRange.dateRange.endDate.format(
                "YYYY-MM-DD"
            );
        }

        const missingDates = parser.missingDates(startDate, endDate);

        if (missingDates[0]) {
            setLoading(true);
            fetchRawAnalyticsData({
                startDate: missingDates[0],
                endDate: missingDates[1]
            });
        } else {
            setLoading(false);
            setParsedData(
                filterState.useCampaigns
                    ? parser.generateCampaignTotals(startDate, endDate)
                    : parser.generateMediaTotals(startDate, endDate)
            );
        }
    }, [
        analyticsDataStore,
        filterState.dateRange,
        filterState.startDate,
        filterState.endDate,
        filterState.useCampaigns
    ]);

    useEffect(() => {
        if (
            !filterState.dateRange.use24HourEndpoint ||
            filterState.startDate ||
            filterState.endDate
        ) {
            return;
        }

        const parser = new AnalyticsDataParser(analyticsLiveDataStore);

        let parsedData;

        if (filterState.useCampaigns) {
            parsedData = parser.generateCampaignTotals24Hours();
        } else {
            parsedData = parser.generateMediaTotals24Hours();
        }

        if (parsedData) {
            setLoading(false);
            setParsedData(parsedData);
        } else {
            setLoading(true);
            fetchRaw24HourAnalyticsData();
        }
    }, [
        analyticsLiveDataStore,
        filterState.dateRange,
        filterState.startDate,
        filterState.endDate,
        filterState.useCampaigns
    ]);

    function downloadReport() {
        if (
            filterState.dateRange.use24HourEndpoint &&
            !filterState.startDate &&
            !filterState.endDate
        ) {
            emailReportModal(null);
        } else {
            let startDate;
            let endDate;

            if (filterState.startDate && filterState.endDate) {
                startDate = filterState.startDate.format("YYYY-MM-DD");
                endDate = filterState.endDate.format("YYYY-MM-DD");
            } else {
                startDate = filterState.dateRange.dateRange.startDate.format(
                    "YYYY-MM-DD"
                );
                endDate = filterState.dateRange.dateRange.endDate.format(
                    "YYYY-MM-DD"
                );
            }

            emailReportModal([startDate, endDate]);
        }
    }

    const totalCoins = Math.round(
        parsedData.reduce((a, b) => {
            return a + b.totalCoins;
        }, 0)
    );
    const totalDollars =
        Math.round(
            parsedData.reduce((a, b) => {
                return a + b.totalDollars;
            }, 0) * 100
        ) / 100;

    const thereIsNoData = parsedData.length === 0;

    return (
        <>
            <CoinSummary
                topLeft={"Total Earnings"}
                topRight={`$${formatNumber(totalDollars)}`}
                bottomLeft={
                    <Link onClick={downloadReport}>Download Report</Link>
                }
                bottomRight={""}
            />
            <AnalyticsControlButtons
                filterState={filterState}
                parsedData={parsedData}
                pickDates={pickDates}
                pickFilters={pickFilters}
                loading={loading}
            />
            {loading ? (
                <AnalyticsLoader />
            ) : thereIsNoData ? (
                <AnalyticsEmptyState />
            ) : (
                <List>
                    {parsedData
                        .filter(totals => {
                            if ("campaign" in totals) {
                                return (
                                    filterState.selectedCampaigns.size === 0 ||
                                    filterState.selectedCampaigns.has(
                                        totals.campaign
                                    )
                                );
                            } else {
                                return (
                                    filterState.selectedMedia.size === 0 ||
                                    filterState.selectedMedia.has(totals.media)
                                );
                            }
                        })
                        .slice(0, showMore ? 5 : parsedData.length)
                        .map((totals, idx) => {
                            return (
                                <TotalEarningsCell
                                    key={
                                        "campaign" in totals
                                            ? `c${totals.campaign}`
                                            : `m${totals.media}`
                                    }
                                    totals={totals}
                                />
                            );
                        })}
                </List>
            )}
            {showMore && (
                <Bottom>
                    <Button block variant="outline-success" onClick={showMore}>
                        Show More
                    </Button>
                </Bottom>
            )}
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    analyticsDataStore: state.analytics.analyticsDataStore,
    analyticsLiveDataStore: state.analytics.analyticsLiveDataStore
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchRawAnalyticsData: (payload: reportParameters) =>
        dispatch(fetchRawAnalyticsData(payload)),
    fetchRaw24HourAnalyticsData: () => dispatch(fetchRaw24HourAnalyticsData()),
    emailReportModal: (emailDateRange: [string, string] | null) =>
        dispatch(emailReportModal(emailDateRange))
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalEarningsCard);
