import React from "react";
import { colors, fonts } from "src/constants";
import styled from "styled-components";

const Container = styled.div<{ margin?: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
    min-width: 300px;
    min-height: 36px;
    background: ${colors.lighterTeal};
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    padding: 11px 13px;
    ${props => props.margin && `margin: ${props.margin}`};
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: ${fonts.subtitle}px;
    line-height: 16px;
    font-weight: 300;

    :focus {
        outline: none;
    }
    ::placeholder {
        color: ${colors.primaryGray};
    }
`;

const IconContainer = styled.div`
    height: 14px;
    width: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SearchIcon = styled.img`
    height: 14px;
    width: 14px;
`;

const ClearIcon = styled.img`
    height: 14px;
    width: 14px;
    cursor: pointer;
`;

interface Props {
    searchString: string;
    updateSearchString: (searchString: string) => void;
    placeholder?: string;
    margin?: string;
}

const MarketplaceSearchBar = ({
    searchString,
    updateSearchString,
    placeholder = "Search",
    margin
}: Props) => {
    return (
        <Container margin={margin}>
            <IconContainer>
                <SearchIcon src={"/search/marketplace-search.svg"} />
            </IconContainer>
            <SearchInput
                placeholder={placeholder}
                value={searchString}
                onChange={e => updateSearchString(e.currentTarget.value)}
            />
            {searchString.length !== 0 && (
                <IconContainer>
                    <ClearIcon
                        src={"/clear/clear@2x.png"}
                        onClick={() => updateSearchString("")}
                    />
                </IconContainer>
            )}
        </Container>
    );
};

export default MarketplaceSearchBar;
