import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getPlacementItemsWithPlatform } from "../../../campaigns/placements/selectors";
import { GlobalState } from "../../../reducers";
import CustomOfferTypeModal from "./CustomOfferTypeModal";
import ItemSelectionModal from "./ItemSelectionModal";
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";

export interface OfferTypeParams {
    placementId: null | number;
    placementType: null | string;
    customType: string;
    duration: null | number;
    permanent: boolean;
}

export const initialOfferType: OfferTypeParams = {
    placementId: null,
    placementType: null,
    customType: "",
    duration: null,
    permanent: false
};

export const offerTypeFinished = (offerType: OfferTypeParams) => {
    if (!offerType.placementId && !offerType.customType) return false;
    if (!offerType.permanent && offerType.duration === null) return false;
    return true;
};

interface Props {
    closeModal: () => void;
    offerType: OfferTypeParams;
    platform: string;
    setOfferType: (params: OfferTypeParams) => void;
}

const OfferTypeModalController = ({
    closeModal,
    offerType,
    platform,
    setOfferType
}: Props) => {
    useEffect(() => {
        setOfferType(initialOfferType);
    }, [platform]);

    const placements = useSelector((state: GlobalState) =>
        getPlacementItemsWithPlatform(state, platform)
    );

    const [customPlacementModal, toggleCustomPlacementModal] = useState(false);
    const [customDurationModal, toggleCustomDurationModal] = useState(false);

    if (!offerType.placementId && !offerType.customType) {
        return (
            <>
                <ItemSelectionModal
                    title="OFFER TYPE"
                    heading="What are you offering?"
                    description="Select an option below:"
                    items={placements}
                    onSelect={(value: number, type: string) =>
                        setOfferType({
                            ...offerType,
                            placementId: value,
                            placementType: type
                        })
                    }
                    closeModal={closeModal}
                    customOptionCallback={() =>
                        toggleCustomPlacementModal(true)
                    }
                />
                <AnimatePresence>
                    {customPlacementModal && (
                        <CustomOfferTypeModal
                            closeModal={() => toggleCustomPlacementModal(false)}
                            onSubmit={(value: string) =>
                                setOfferType({
                                    ...offerType,
                                    customType: value
                                })
                            }
                            modalTitle="Offer Type"
                            description="What are you offering?"
                            placeholder="Offer Name"
                        />
                    )}
                </AnimatePresence>
            </>
        );
    }

    return (
        <>
            <ItemSelectionModal
                title="OFFER TYPE"
                heading="How long will you post for?"
                description="Select an option below:"
                items={[
                    { value: 0, type: "Permanent" },
                    { value: 24, type: "24 Hours" },
                    { value: 12, type: "12 Hours" },
                    { value: 0, type: "N/A" }
                ]}
                onSelect={(value: number, type: string) => {
                    setOfferType({
                        ...offerType,
                        duration: value * 3600,
                        permanent: type === "Permanent"
                    });
                    closeModal();
                }}
                closeModal={closeModal}
                customOptionCallback={() => toggleCustomDurationModal(true)}
            />
            <AnimatePresence>
                {customDurationModal && (
                    <CustomOfferTypeModal
                        closeModal={() => toggleCustomDurationModal(false)}
                        onSubmit={value => {
                            setOfferType({
                                ...offerType,
                                duration: value
                            });
                            closeModal();
                        }}
                        modalTitle="Offer Type"
                        description="How long will you post for?"
                        placeholder="Select Duration"
                        durationInput
                    />
                )}
            </AnimatePresence>
        </>
    );
};

export default OfferTypeModalController;
