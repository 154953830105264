import { IconName, IconPrefix } from "@fortawesome/fontawesome-common-types";
import { ReactElement } from "react";
import { SocialAccount } from "src/profile/types";
import BrandedButton from "./BrandedButton";
import featureFlags from "src/firebase/featureFlags";
export interface PlatformType {
    title: string;
    icon: [IconPrefix, IconName];
    countPlaceholder: string;
    imgSrc: string;
    urlCallback: (username: string) => string | null;
    loginKitSplit?: featureFlags;
    codeVerificationSplit?: featureFlags;
    verificationUrlCallback: (account: SocialAccount) => string;
    codePosition: string;
}

const Platforms: { [key: string]: PlatformType } = {
    instagram: {
        title: "Instagram",
        icon: ["fab", "instagram"],
        countPlaceholder: "Number of Followers",
        imgSrc: "/instagram_profile/instagram_profile@2x.png",
        urlCallback: username => {
            return `https://www.instagram.com/${username}`;
        },
        loginKitSplit: featureFlags.InstagramBasicDisplayVerification,
        codeVerificationSplit: featureFlags.InstagramCodeVerification,
        verificationUrlCallback: account =>
            `/auth/instagram/?socialID=${account.id}`,
        codePosition: ` bottom: 89px; right: 152px;`
    },
    snapchat: {
        title: "Snapchat",
        icon: ["fab", "snapchat-ghost"],
        countPlaceholder: "Number of Followers",
        imgSrc: "/snapchat_profile/snapchat_profile@2x.png",
        urlCallback: username => {
            return `https://www.snapchat.com/add/${username}`;
        },
        loginKitSplit: featureFlags.snapchatLoginKitVerification,
        codeVerificationSplit: featureFlags.snapchatCodeVerification,
        verificationUrlCallback: account =>
            `/auth/snapchat/?socialID=${account.id}`,
        codePosition: ` bottom: 91px; right: 60px;`
    },
    tiktok: {
        title: "TikTok",
        icon: ["fas", "music"],
        countPlaceholder: "Number of Followers",
        imgSrc: "/tiktok_profile/tiktok_profile@2x.png",
        urlCallback: username => {
            return `https://www.tiktok.com/@${username}`;
        },
        loginKitSplit: featureFlags.tiktokTCMVerification,
        codeVerificationSplit: featureFlags.tiktokCodeVerification,
        verificationUrlCallback: account => ``,
        codePosition: ` bottom: 53px; right: 128px;`
    },
    twitter: {
        title: "Twitter",
        icon: ["fab", "twitter"],
        countPlaceholder: "Number of Followers",
        imgSrc: "/twitter_profile/twitter_profile@2x.png",
        urlCallback: username => {
            return `https://twitter.com/${username}`;
        },
        codeVerificationSplit: featureFlags.twitterCodeVerification,
        verificationUrlCallback: account => ``,
        codePosition: ` bottom: 61px; right: 88px;`
    },
    facebook: {
        title: "Facebook",
        icon: ["fab", "facebook"],
        countPlaceholder: "Number of Followers",
        imgSrc: "/facebook_profile/facebook_profile@2x.png",
        urlCallback: username => {
            return `https://www.facebook.com/${username}`;
        },
        loginKitSplit: featureFlags.facebookLoginKitVerification,
        codeVerificationSplit: featureFlags.facebookCodeVerificatioin,
        verificationUrlCallback: account =>
            `/auth/facebook/?socialID=${account.id}`,
        codePosition: ` bottom: 26px; right: 120px;`
    },
    youtube: {
        title: "YouTube",
        icon: ["fab", "youtube"],
        countPlaceholder: "Number of Subscribers",
        imgSrc: "/youtube_profile/youtube_profile@2x.png",
        urlCallback: username => {
            return `https://www.youtube.com/${username}`;
        },
        codeVerificationSplit: featureFlags.youtubeCodeVerification,
        verificationUrlCallback: account => ``,
        codePosition: ` bottom: 51px; right: 78px;`
    },
    twitch: {
        title: "Twitch",
        icon: ["fab", "twitch"],
        countPlaceholder: "Number of Followers",
        imgSrc: "/twitch_profile/twitch_profile@2x.png",
        urlCallback: username => {
            return `https://www.twitch.tv/${username}`;
        },
        codeVerificationSplit: featureFlags.twitchCodeVerification,
        verificationUrlCallback: account => ``,
        codePosition: ` bottom: 92px; right: 143px;`
    },
    tumblr: {
        title: "Tumblr",
        icon: ["fab", "tumblr"],
        countPlaceholder: "Number of Followers",
        imgSrc: "/tumblr_profile/tumblr_profile@2x.png",
        urlCallback: username => {
            return `https://${username}.tumblr.com/`;
        },
        codeVerificationSplit: featureFlags.tumblrCodeVerification,
        verificationUrlCallback: account => ``,
        codePosition: ` bottom: 61px; right: 88px;`
    },
    other: {
        title: "Other",
        icon: ["far", "question-circle"],
        countPlaceholder: "Number of Followers",
        imgSrc: "/other_profile/other_profile@2x.png",
        urlCallback: username => {
            return null;
        },
        verificationUrlCallback: account => ``,
        codePosition: ` bottom: 61px; right: 88px;`
    }
};

export default Platforms;
