import React from "react";
import { colors, fonts } from "src/constants";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const Tab = styled.div<{ selected: boolean }>`
    border-bottom: ${props =>
        props.selected ? `1px solid black` : `1px solid ${colors.blueGrey2}`};
    color: ${props => (props.selected ? `black` : colors.primaryGray)};
    font-weight: ${props => (props.selected ? "500" : "400")};
    cursor: ${props => !props.selected && "pointer"};
    font-size: ${fonts.subtitle}px;
    padding: 20px 0 20px 0;
    width: 50%;
    text-align: center;
`;

interface Props {
    selected: string;
    onSelect: () => void;
}
const PromoteFlowTabs = ({ selected, onSelect }: Props) => {
    return (
        <Main>
            <Tab
                onClick={() => {
                    if (selected !== "repost") onSelect();
                }}
                selected={selected === "repost"}
            >
                Repost Media
            </Tab>
            <Tab
                onClick={() => {
                    if (selected !== "custom") onSelect();
                }}
                selected={selected === "custom"}
            >
                Create Custom Media
            </Tab>
        </Main>
    );
};

export default PromoteFlowTabs;
