import { NormalizedSchema } from "normalizr";
import { EntitiesState } from "../../reducers";
import { Action, NormalizedAction } from "../../types";

export const FETCH_CONTEST = {
    REQUEST: "FETCH_CONTEST_REQUEST",
    SUCCESS: "FETCH_CONTEST_SUCCESS",
    FAILURE: "FETCH_CONTEST_FAILURE"
};

export const fetchContest = (): Action => ({
    type: FETCH_CONTEST.REQUEST,
    payload: null
});

export const fetchContestSuccess = (
    response: NormalizedSchema<EntitiesState, number[]>
): NormalizedAction => ({
    type: FETCH_CONTEST.SUCCESS,
    response
});

export const fetchContestFailure = (error: string): Action => ({
    type: FETCH_CONTEST.FAILURE,
    payload: { error }
});

// Claim Prize -------------------------------------------------------------

export const CLAIM_PRIZE = {
    REQUEST: "CLAIM_PRIZE_REQUEST",
    SUCCESS: "CLAIM_PRIZE_SUCCESS",
    FAILURE: "CLAIM_PRIZE_FAILURE"
};

export interface ClaimParams {
    prizeId: number;
    contact_info?: {
        full_name: string;
        phone_number: string;
        email: string;
    };
    shipping_info?: {
        street_address: string;
        city: string;
        state: string;
        zip_code: string;
    };
    additional_info?: string;
    claimed_prize_type: "prize" | "cash";
}

export const claimPrize = (params: ClaimParams): Action => ({
    type: CLAIM_PRIZE.REQUEST,
    payload: params
});

export const claimPrizeSuccess = (): Action => ({
    type: CLAIM_PRIZE.SUCCESS,
    payload: null
});

export const claimPrizeFailure = (): Action => ({
    type: CLAIM_PRIZE.FAILURE,
    payload: null
});

// Fetch Holiday Contest -------------------------------------------------------------

export const FETCH_HOLIDAY_CONTEST = {
    REQUEST: "FETCH_HOLIDAY_CONTEST_REQUEST",
    SUCCESS: "FETCH_HOLIDAY_CONTEST_SUCCESS",
    FAILURE: "FETCH_HOLIDAY_CONTEST_FAILURE"
};

export const fetchHolidayContest = (): Action => ({
    type: FETCH_HOLIDAY_CONTEST.REQUEST,
    payload: null
});

export const fetchHolidayContestSuccess = (contest: any): Action => ({
    type: FETCH_HOLIDAY_CONTEST.SUCCESS,
    payload: contest
});

export const fetchHolidayContestFailure = (error: string): Action => ({
    type: FETCH_HOLIDAY_CONTEST.FAILURE,
    payload: { error }
});
