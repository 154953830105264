import React from "react";
import { colors, fonts, fontWeight, sizes } from "src/constants";
import { clearButton, clearSpacing } from "src/utils/styles/snippets";
import styled, { css } from "styled-components";
import CheckBox from "./CheckBox";

interface MainProps {
    includeBorder?: true;
    width?: number;
}

const Main = styled.button<MainProps>`
    ${clearButton()};
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: ${props => `${props.width}px` || "100%"};
    height: ${sizes.selectionButton.height}px;
    padding: 8px 12px;
    color: ${colors.black};
    border-radius: 8px;
    text-align: left;
    transition: 0.1s ease-in;

    ${props => {
        return (
            props.includeBorder &&
            css`
                border: ${colors.tertiaryLightGray} solid 1px;
            `
        );
    }};

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray2};
        }
    }
`;

const IconTextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 6px;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

interface TextProps {
    fontSize?: number;
    isSubTextIncluded: boolean;
}

const Text = styled.p<TextProps>`
    ${clearSpacing()};
    font-size: ${props => props.fontSize || fonts.semiSmallTitle}px;
    margin-bottom: ${props => (props.isSubTextIncluded ? "6px" : "0px")};
`;

const SubText = styled.span`
    color: ${colors.primaryGray};
    font-size: ${fonts.smallTitle}px;
    font-weight: ${fontWeight.default};
`;

interface Props {
    width?: number;
    icon?: string;
    iconUrl?: string;
    text: string;
    subText?: string;
    selected: boolean;
    setSelected: () => void;
    includeBorder?: true;
}

const SelectionButton = ({
    width,
    icon,
    iconUrl,
    text,
    subText,
    selected,
    setSelected,
    includeBorder
}: Props) => {
    return (
        <Main
            type="button"
            onClick={setSelected}
            width={width}
            includeBorder={includeBorder}
        >
            <IconTextContainer>
                {icon}
                {!!iconUrl && <Icon src={iconUrl} alt="Icon" />}
                <TextContainer>
                    <Text isSubTextIncluded={!!subText}>{text}</Text>
                    {subText && <SubText>{subText}</SubText>}
                </TextContainer>
            </IconTextContainer>
            <CheckBox selected={selected} />
        </Main>
    );
};

export default SelectionButton;
