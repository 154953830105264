import React from "react";
import styled, { css } from "styled-components";
import { ChevronLeft, ChevronRight } from "src/components/icons/exports";
import { colors, shadows } from "src/constants";

interface ButtonProps {
    scrolledToEnd: boolean;
    top?: string;
}

const buttonStyles = css<ButtonProps>`
    position: absolute;
    top: ${props => props.top || "50%"};
    transform: translateY(-50%);
    z-index: 4;
    display: ${props => {
        if (props.scrolledToEnd) return "none";
        else if (!props.scrolledToEnd) return "flex";
    }};
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: ${colors.white};
    border: transparent solid 1px;
    border-radius: 50%;
    outline: none;
    box-shadow: ${shadows.one};
    transition: 0.1s ease-in;
    cursor: pointer;

    :focus {
        outline: none;
    }

    @media (hover: hover) {
        :hover {
            border: ${colors.darkTeal} solid 1px;
            box-shadow: ${shadows.two};
        }
    }

    @media (max-width: 958px) {
        display: none;
    }
`;

const ScrollButtonLeft = styled.button`
    ${buttonStyles};
    left: calc(-48px / 2);
`;

const ScrollButtonRight = styled.button`
    ${buttonStyles};
    right: calc(-48px / 2);
`;

interface Props {
    onClick: () => void;
    direction: "left" | "right";
    scrolledToEnd: boolean;
    top?: string;
}

const ScrollButton = ({ onClick, direction, scrolledToEnd, top }: Props) => {
    return (
        <>
            {direction === "left" ? (
                <ScrollButtonLeft
                    onClick={onClick}
                    scrolledToEnd={scrolledToEnd}
                    top={top}
                >
                    <ChevronLeft height={16} fill={colors.primaryGray} />
                </ScrollButtonLeft>
            ) : (
                <ScrollButtonRight
                    onClick={onClick}
                    scrolledToEnd={scrolledToEnd}
                    top={top}
                >
                    <ChevronRight height={16} fill={colors.primaryGray} />
                </ScrollButtonRight>
            )}
        </>
    );
};

export default ScrollButton;
