import React, { useState } from "react";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import { colors, fonts } from "src/constants";
import GenericContainer from "src/profile/components/GenericContainer";
import styled from "styled-components";
import IntroBuysAccountList from "./IntroBuysAccountList";
import IntroBuysScreen from "./IntroBuysScreen";
import IntroBuysWelcome from "./IntroBuysWelcome";
import IntroBuysApplication from "./IntroBuysApplication";
import { post } from "../../Api";
import { errorToString } from "../../util";
import { normalize } from "normalizr";
import { publisherFlag } from "../../profile/flags/schema";
import { fetchPublisherFlagsSuccess } from "../../profile/flags/actions";
import { useDispatch } from "react-redux";

const Step = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.primaryGray};
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
`;

const Description = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
`;

export const EmojiNote = styled(GenericContainer)`
    ${FlexVerticallyCentered}
    font-size: 12px;
    line-height: 15px;
    padding: 12px;

    & > * ~ * {
        margin-left: 8px;
    }
`;

const Video = styled.video`
    width: 100%;
    margin-top: 24px;
`;

interface Props {
    currentStep: number;
    setCurrentStep: (step: number) => void;
    handleFinished: VoidFunction;
}

const IntroBuysContent = ({
    currentStep,
    setCurrentStep,
    handleFinished
}: Props) => {
    const dispatch = useDispatch();
    const [ratesResponse, setRatesResponse] = useState(true);
    const [applicationResponse, setApplicationResponse] = useState(true);
    const [applicationLoading, setApplicationLoading] = useState(false);

    function submitApplication() {
        setApplicationLoading(true);
        post(
            `/v1/publisher/p:${localStorage.getItem(
                "userid"
            )}/flag/directBuyApplication`,
            {
                value: applicationResponse ? "complete" : "incomplete"
            }
        )
            .then(({ data }) => {
                dispatch(
                    fetchPublisherFlagsSuccess(
                        normalize(data.data, publisherFlag)
                    )
                );
                finishedFlow();
            })
            .catch(error => {
                window.alert(errorToString(error));
                setApplicationLoading(false);
            });
    }

    function finishedFlow() {
        post(
            `/v1/publisher/p:${localStorage.getItem(
                "userid"
            )}/flag/directBuyIntroFlow`,
            {
                value: "complete"
            }
        )
            .then(({ data }) => {
                dispatch(
                    fetchPublisherFlagsSuccess(
                        normalize(data.data, publisherFlag)
                    )
                );
                setApplicationLoading(false);
                setCurrentStep(currentStep + 1);
            })
            .catch(error => {
                window.alert(errorToString(error));
                setApplicationLoading(false);
            });
    }

    switch (currentStep) {
        case 0:
            return (
                <IntroBuysScreen
                    next={() => setCurrentStep(currentStep + 1)}
                    buttonText="Get Started"
                >
                    <IntroBuysWelcome />
                </IntroBuysScreen>
            );
        case 1:
            return (
                <IntroBuysScreen next={() => setCurrentStep(currentStep + 1)}>
                    <Step>STEP 1 OF 5</Step>
                    <Title>👋 Say Hello to Personal Offers</Title>
                    <Description>
                        Introducing Personal Offers, a new way to earn on the
                        Plug. Receive buy offers from advertisers with
                        guaranteed payment.
                    </Description>
                    <Video
                        src="https://firebasestorage.googleapis.com/v0/b/plugcoinprod.appspot.com/o/random%2Fpersonal_offers_animation_1.mp4?alt=media&token=3b7ab00b-2415-4669-b069-31ea74221827"
                        loop
                        autoPlay
                        muted
                        playsInline
                    />
                </IntroBuysScreen>
            );
        case 2:
            return (
                <IntroBuysScreen next={() => setCurrentStep(currentStep + 1)}>
                    <Step>STEP 2 OF 5</Step>
                    <Title>✍️ Set Your Rates on Your Profile</Title>
                    <Description>
                        Select which accounts you want to run promotions on and
                        set the rates you want to be paid for your services.
                    </Description>
                    <Video
                        poster="https://firebasestorage.googleapis.com/v0/b/plugcoinprod.appspot.com/o/random%2Fpersonal_offers_animation_2.jpg?alt=media&token=8071b67e-c116-4fe7-887e-5156e11c60b3"
                        src="https://firebasestorage.googleapis.com/v0/b/plugcoinprod.appspot.com/o/random%2Fpersonal_offers_animation_2.mp4?alt=media&token=dad6c27b-1880-4e4c-8aa7-2354d4c5fe09"
                        loop
                        autoPlay
                        muted
                        playsInline
                    />
                </IntroBuysScreen>
            );
        case 3:
            return (
                <IntroBuysScreen next={() => setCurrentStep(currentStep + 1)}>
                    <Step>STEP 3 OF 5</Step>
                    <Title>💰 Receive Personal Offers</Title>
                    <Description>
                        You can find Personal Offers on the Campaign Page on the
                        Plug. Just click on an offer, accept it, complete the
                        instructions and make money!
                    </Description>
                    <Video
                        poster="https://firebasestorage.googleapis.com/v0/b/plugcoinprod.appspot.com/o/random%2Fpersonal_offers_animation_3.jpg?alt=media&token=82256946-42ed-44d6-b40c-242b9d108d79"
                        src="https://firebasestorage.googleapis.com/v0/b/plugcoinprod.appspot.com/o/random%2Fpersonal_offers_animation_3.mp4?alt=media&token=0e469910-87b1-4ed7-b1f9-0b4b80b33b48"
                        loop
                        autoPlay
                        muted
                        playsInline
                    />
                </IntroBuysScreen>
            );
        case 4:
            return (
                <IntroBuysScreen
                    next={() => setCurrentStep(currentStep + 1)}
                    buttonText="Next"
                >
                    <Step>STEP 4 OF 5</Step>
                    <Title>✍️ Set Your Rates on Your Profile</Title>
                    <Description>
                        Select which accounts you want to run promotions on and
                        set the rates you want to be paid for your services.
                    </Description>
                    <EmojiNote>
                        <div>⭐</div>
                        <div>
                            Be sure to input the most accurate rates. Inaccurate
                            rates may lead to missed opportunities
                        </div>
                    </EmojiNote>
                    <IntroBuysAccountList />
                </IntroBuysScreen>
            );
        case 5:
            return (
                <IntroBuysScreen
                    next={submitApplication}
                    buttonText="Submit Application"
                    buttonLoading={applicationLoading}
                >
                    <Step>STEP 5 OF 5</Step>
                    <Title>Apply for Access Personal Offers</Title>
                    <Description>
                        Complete the following application to join the waitlist
                        for Personal Offers.
                    </Description>
                    <IntroBuysApplication
                        ratesResponse={ratesResponse}
                        setRatesResponse={setRatesResponse}
                        applicationResponse={applicationResponse}
                        setApplicationResponse={setApplicationResponse}
                    />
                </IntroBuysScreen>
            );
        default:
            return (
                <IntroBuysScreen next={handleFinished} buttonText="Done">
                    <Title>
                        Your application has been submitted! You’ll be contacted
                        when you get access to Personal Offers!
                    </Title>
                    <Video
                        src="https://firebasestorage.googleapis.com/v0/b/plugcoinprod.appspot.com/o/random%2Fpersonal_offers_animation_4.mp4?alt=media&token=940a6650-8cee-45a4-aee1-5a2c15cae6f7"
                        autoPlay
                        muted
                        playsInline
                    />
                </IntroBuysScreen>
            );
    }
};

export default IntroBuysContent;
