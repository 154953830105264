import React from "react";
import XCard from "src/profile/components/XCard";
import styled from "styled-components";

const Container = styled.section`
    margin: 5px 15px 15px;
`;

const AnalyticsEmptyState = () => {
    return (
        <Container>
            <XCard text="There is no available data." />
        </Container>
    );
};

export default AnalyticsEmptyState;
