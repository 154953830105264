import React from "react";
import { Route, Switch } from "react-router";
import PublicConnectionsPage, { PublicConnectionsTabState } from "./page";
import { PublicProfileData } from "../../types";
import NotFoundPage from "src/navigation/NotFound";

interface Props {
    publicData?: PublicProfileData;
}

const PublicConnectionsRouter = ({ publicData }: Props) => {
    return (
        <Switch>
            <Route
                exact
                path={"/public/:otherId/connections/mutual"}
                render={() => (
                    <PublicConnectionsPage
                        publicData={publicData}
                        tabState={PublicConnectionsTabState.mutual}
                    />
                )}
            />

            <Route
                exact
                path={"/public/:otherId/connections"}
                render={() => (
                    <PublicConnectionsPage
                        publicData={publicData}
                        tabState={PublicConnectionsTabState.all}
                    />
                )}
            />

            <Route component={NotFoundPage} />
        </Switch>
    );
};

export default PublicConnectionsRouter;
