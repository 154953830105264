import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { GlobalState } from "../../../reducers";
import { Shoutout } from "../types";
import { setCurrentShoutout } from "src/ui/shoutouts/actions";
import { push } from "connected-react-router";
import styled from "styled-components";
import sortShoutouts from "../utils/sortShoutoutsIntoGroups";
import ShoutoutsGroup from "../components/ShoutoutsGroup";
import SimpleNavbar from "../../../components/SimpleNavbar";
import NavButton from "../../../components/NavButton";
import { colors, maxWidthDesktop } from "../../../constants";
import FullscreenLoader from "src/components/FullscreenLoader";
import XCard from "src/profile/components/XCard";
import { fetchShoutouts } from "../actions";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    margin: 5px auto;
`;

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${colors.mediumTeal};
    margin: 5px 0px;
`;

interface Props {
    shoutouts: {
        userSpecificShoutouts: Shoutout[];
        shoutoutsAvailableForAll: Shoutout[];
    };
    shoutoutsLoading: boolean;
    setCurrentShoutout: (currentShoutout: Shoutout) => void;
    push: (path: string) => void;
}

const ShoutoutsHistory = ({
    shoutouts,
    shoutoutsLoading,
    // fetchShoutouts,
    setCurrentShoutout,
    push
}: Props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchShoutouts(false));
    }, []);

    const sortedShoutouts = sortShoutouts(shoutouts.userSpecificShoutouts);
    const leftNavButton = <NavButton onClick={() => push("/campaign")} back />;

    return (
        <>
            <SimpleNavbar left={leftNavButton} />

            {shoutoutsLoading ? (
                <FullscreenLoader />
            ) : (
                <Main>
                    {sortedShoutouts.length === 0 && (
                        <XCard text="You have no past direct buys." />
                    )}

                    {sortedShoutouts.map((currGroup, idx) => {
                        return (
                            <>
                                {idx !== 0 && <Divider />}
                                <ShoutoutsGroup
                                    key={idx}
                                    shoutouts={currGroup}
                                    setCurrentShoutout={setCurrentShoutout}
                                    push={push}
                                    column={true}
                                />
                            </>
                        );
                    })}
                </Main>
            )}
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    shoutouts: state.entities.shoutouts,
    shoutoutsLoading: state.ui.shoutouts.shoutoutsLoading
});

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentShoutout: (currentShoutout: Shoutout) =>
        dispatch(setCurrentShoutout(currentShoutout)),
    push: (path: string) => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoutoutsHistory);
