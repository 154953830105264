import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import { BlueButton } from "../../profile/components/Buttons";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    font-size: ${fonts.subtitle}px;
    line-height: 17px;
    color: ${colors.primaryGray};
`;

const ReloadButton = styled(BlueButton)`
    height: 46px;
    width: 136px;
    margin-top: 16px;
`;

interface Props {
    message: string;
    reloadCallback?: VoidFunction;
}

const DirectBuyDetailsError = ({ message, reloadCallback }: Props) => {
    return (
        <Main>
            <div>{message}</div>
            {reloadCallback && (
                <ReloadButton onClick={reloadCallback}>Reload</ReloadButton>
            )}
        </Main>
    );
};

export default DirectBuyDetailsError;
