import React, { useEffect } from "react";
import styled from "styled-components";
import { coinToDollars, formatNumber } from "../util";
import CoinSummary from "../components/CoinSummary";
import { MediaEarnings } from "./Analytics";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MediaEarningsCell from "./MediaEarningsCell";
import { GlobalState } from "../reducers";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { colors, fonts } from "../constants";
import AnalyticsEmptyState from "./components/AnalyticsEmptyState";

const Link = styled.div`
    padding: 5px 15px;
    color: ${colors.primaryBlue};
    font-size: ${fonts.subtitle}px;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const List = styled.div`
    padding: 10px 15px 15px;
`;

const Bottom = styled.div`
    padding: 0px 15px 15px;
`;

const GrayText = styled.div`
    font-weight: 300;
    font-size: ${fonts.subtitle}px;
    color: ${colors.secondaryGray};
    padding: 15px 0px 20px;
`;

interface Props {
    mediaEarnings: MediaEarnings[];
    push: (path: string) => void;
    showMore?: () => void;
}

const MediaEarningsCard = ({ mediaEarnings, push, showMore }: Props) => {
    const totalCoins =
        Math.round(
            mediaEarnings.reduce((a, b) => {
                return a + parseFloat(b.earned_plugcoins || "0");
            }, 0) * 10
        ) / 10;

    function renderBottom() {
        const earningsList = (
            <List>
                {mediaEarnings
                    .slice(0, showMore ? 3 : mediaEarnings.length)
                    .map((earnings, idx) => {
                        return (
                            <MediaEarningsCell
                                key={`${earnings.campaign_id}-${earnings.medias_id}`}
                                earnings={earnings}
                            />
                        );
                    })}
            </List>
        );

        if (mediaEarnings.length === 0) {
            return (
                <Bottom>
                    <AnalyticsEmptyState />
                    <GrayText>
                        Submit your ads and you'll earn 5% of overall earnings
                        from that ad for the first month!
                    </GrayText>
                    <Button
                        block
                        variant="outline-success"
                        onClick={() => push("/profile/upload")}
                    >
                        Submit Now
                    </Button>
                </Bottom>
            );
        } else if (showMore) {
            return (
                <>
                    {earningsList}
                    <Bottom>
                        <Button
                            block
                            variant="outline-success"
                            onClick={showMore}
                        >
                            Show More
                        </Button>
                    </Bottom>
                </>
            );
        } else {
            return earningsList;
        }
    }

    return (
        <>
            <CoinSummary
                topLeft={"Media Earnings"}
                topRight={formatNumber(coinToDollars(totalCoins || 0))}
                bottomLeft={"All Time"}
                bottomRight={""}
            />
            {showMore && (
                <Link onClick={() => push("/analytics/media-leaderboard")}>
                    <div>Media Leaderboard</div>
                    <FontAwesomeIcon
                        style={{ color: colors.secondaryGray }}
                        icon={["fas", "chevron-right"]}
                    />
                </Link>
            )}
            {renderBottom()}
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    mediaEarnings: state.analytics.userAnalytics.alltime_media_earnings
});

const mapDispatchToProps = (dispatch: any) => ({
    push: (path: string) => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaEarningsCard);
