import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OpenBuyCustomMediaSubmission from "./OpenBuyCustomMediaSubmision";
import {
    OpenBuy,
    OpenBuyInstructionStep,
    OpenBuySubmission,
    OpenBuySubmissionStatuses
} from "src/buys/modules/open-buys/types";
import OpenBuyPostSubmission from "./OpenBuyPostSubmission";
import SocialAccountHeader from "src/components/social-accounts/SocialAccountHeader";
import { shortenNumber } from "src/util";
import { colors } from "src/constants";
import Platforms from "src/social-accounts/Platforms";
import { useDispatch, useSelector } from "react-redux";
import { selectSetSubmissions } from "src/buys/selectors";
import { GlobalState } from "src/reducers";
import { selectAccount } from "src/profile/accounts/selectors";
import OpenBuySubmissionStepAccordion from "./OpenBuySubmissionStepAccordion";
import { getOpenBuySubmissions } from "src/buys/actions";
import { getMediaSubmissionStatus, getSubmissionStatuIndicator } from "./util";

const Main = styled.div`
    display: flex;
    flex-direction: column;
`;

const StepHeader = styled.div`
    display: flex;
    font-weight: 600;
    font-size: 12px;
    align-items: center;
`;

export const SetStatusIndicator = styled.div<{
    status?: OpenBuySubmissionStatuses;
    height?: string;
}>`
    border: 1px solid
        ${props =>
            props.status === OpenBuySubmissionStatuses.pending ||
            props.status === OpenBuySubmissionStatuses.revision_requested
                ? colors.messageYellow
                : props.status === OpenBuySubmissionStatuses.rejected
                ? colors.primaryRed
                : props.status === OpenBuySubmissionStatuses.approved
                ? colors.primaryGreen
                : colors.primaryBlue};

    color: ${props =>
        props.status === OpenBuySubmissionStatuses.pending ||
        props.status === OpenBuySubmissionStatuses.revision_requested
            ? colors.messageYellow
            : props.status === OpenBuySubmissionStatuses.rejected
            ? colors.primaryRed
            : props.status === OpenBuySubmissionStatuses.approved
            ? colors.primaryGreen
            : colors.primaryBlue};
    font-weight: 700;
    font-size: 12px;
    align-items: center;
    width: ${props => props.height || "24px"};
    height: ${props => props.height || "24px"};
    border-radius: 100px;
    margin-right: 6px;
    justify-content: center;
    display: flex;
`;

const IndicatorImg = styled.img`
    width: inherit;
    height: inherit;
`;

const SocialAccountButton = styled.div`
    cursor: pointer;
    padding: 12px;
    margin: 18px;
    border: 1px solid ${colors.blueGrey2};
    border-radius: 12px;
`;

interface Props {
    openBuy: OpenBuy;
    submissionSetId: number;
    selectSocial: VoidFunction;
}
const OpenBuySubmissionSetForm = ({
    openBuy,
    submissionSetId,
    selectSocial
}: Props) => {
    const dispatch = useDispatch();
    const submissionSets = useSelector(
        (state: GlobalState) => state.entities.openBuySubmissionSets
    );
    const submissionSet = submissionSets[submissionSetId];

    const submissions = useSelector((state: GlobalState) =>
        selectSetSubmissions(state, submissionSet?.id)
    );

    const socialAccount = useSelector((state: GlobalState) =>
        selectAccount(state, submissionSet?.socialAccountId)
    );

    function determineStepTitle(step: OpenBuyInstructionStep) {
        if (step.requiresCustomMedia) return "MEDIA";
        else if (step.requiresLinkSubmission) return "LINK";
        else if (step.requiresTextSubmission) return "TEXT";
    }

    function checkDisabled(index: number) {
        let count = 0;

        for (let sub of submissions) {
            if (sub?.status === OpenBuySubmissionStatuses.approved) count++;
        }
        return index > count;
    }

    useEffect(() => {
        if (submissionSet?.submissions.length > 0 && submissions.length < 1)
            dispatch(getOpenBuySubmissions(submissionSet.openBuyId));
    }, [submissionSet]);
    let position = 0;
    return (
        <Main>
            <SocialAccountButton onClick={() => selectSocial()}>
                {socialAccount && (
                    <SocialAccountHeader
                        username={socialAccount.username}
                        picture={
                            socialAccount.profilePhotoUrl ||
                            Platforms[socialAccount.platform]?.imgSrc
                        }
                        count={`${shortenNumber(
                            socialAccount.followers
                        )} followers`}
                        onClick={selectSocial}
                    />
                )}
            </SocialAccountButton>
            {openBuy.submissionSteps?.map((step, index) => {
                if (step.requiresRawMedia) return;
                position++;

                const submission: Partial<OpenBuySubmission> =
                    submissions?.find(
                        sub => sub.instructionStepId === step.id
                    ) || {};
                let hasRawMediaStep =
                    openBuy.submissionSteps[index + 1]?.requiresRawMedia;

                const rawSubmission = step.requiresCustomMedia
                    ? submissions.find(
                          sub =>
                              sub.instructionStepId ===
                              openBuy.submissionSteps[index + 1]?.id
                      ) || {}
                    : {};

                return (
                    <OpenBuySubmissionStepAccordion
                        key={`step-${step.id}`}
                        disabled={checkDisabled(index)}
                        initiallyExpanded={
                            !checkDisabled(index) &&
                            submission.status !==
                                OpenBuySubmissionStatuses.approved
                        }
                        customHeader={
                            <StepHeader>
                                <SetStatusIndicator
                                    status={getMediaSubmissionStatus(
                                        submission,
                                        rawSubmission
                                    )}
                                >
                                    {submission?.status ? (
                                        <IndicatorImg
                                            src={getSubmissionStatuIndicator(
                                                getMediaSubmissionStatus(
                                                    submission,
                                                    rawSubmission
                                                )
                                            )}
                                        />
                                    ) : (
                                        position
                                    )}
                                </SetStatusIndicator>
                                {determineStepTitle(step)}
                            </StepHeader>
                        }
                    >
                        {step.requiresCustomMedia ? (
                            <OpenBuyCustomMediaSubmission
                                submission={submission}
                                openBuyId={openBuy.id}
                                instructionStep={step}
                                rawSubmission={
                                    hasRawMediaStep ? rawSubmission : undefined
                                }
                                rawMediaStep={
                                    hasRawMediaStep
                                        ? openBuy.submissionSteps[index + 1]
                                        : undefined
                                }
                                socialAccountId={socialAccount?.id}
                                type="media"
                                setId={submissionSet?.id}
                            />
                        ) : (
                            <OpenBuyPostSubmission
                                submission={submission}
                                socialAccountId={socialAccount?.id}
                                instructionStep={step}
                                openBuyId={openBuy.id}
                                setId={submissionSet?.id}
                            />
                        )}
                    </OpenBuySubmissionStepAccordion>
                );
            })}
        </Main>
    );
};
export default OpenBuySubmissionSetForm;
