export enum Role {
    guest = "guest",
    creator = "creator"
}

export enum DisplayAction {
    desktopView = "desktopView",
    displayCoins = "displayCoins"
}

export enum CampaignAction {
    list = "list",
    details = "details",
    uploadMedia = "uploadMedia",
    customLinks = "customLinks",
    payment = "payment"
}

export enum MediaAction {
    cellButtons = "cellButtons"
}

enum OtherAction {
    test = "test"
}

export const AuthAction = {
    ...DisplayAction,
    ...CampaignAction,
    ...MediaAction,
    ...OtherAction
};

export type AuthAction =
    | CampaignAction
    | OtherAction
    | MediaAction
    | DisplayAction;
