import React from "react";
import { Connection } from "../profile/types";
import styled from "styled-components";
import VerticallyCentered from "./VerticallyCentered";
import { breakpoints, colors, fonts } from "../constants";
import ProfilePhoto from "./ProfilePhoto";
import { shortenNumber } from "../util";
import Platforms from "../social-accounts/Platforms";
import HideOverflow from "./HideOverflow";

const Main = styled(VerticallyCentered)`
    padding: 10px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 8px;
    transition: 0.1s ease-in;
    cursor: pointer;
    text-align: left;

    & + & {
        margin-top: 15px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
        }
    }

    @media (max-width: ${breakpoints.mobile}px) {
        padding: ${props => props.theme.isMobile && "0px"};
        border: ${props => props.theme.isMobile && "none"};
        border-radius: ${props => props.theme.isMobile && "0px"};
    }
`;

const TextContainer = styled.div`
    width: calc(100% - 92px);
`;

const Title = styled(HideOverflow)`
    font-size: ${fonts.smallTitle}px;
    width: 100%;
`;

const NoUsername = styled(Title)`
    font-style: italic;
    font-weight: 300;
    color: ${colors.primaryGray};
`;

const Subtitle = styled(HideOverflow)`
    font-size: 12px;
    width: 100%;
    color: ${colors.primaryGray};
`;

const Checkbox = styled.img`
    margin-left: auto;
`;

interface Props {
    connection: Connection;
    selected: boolean;
    onClick: VoidFunction;
}

const SelectConnectionRow = ({ connection, selected, onClick }: Props) => {
    const platformTitle = Platforms[connection.other.profile.tagLine.platform]
        ? Platforms[connection.other.profile.tagLine.platform].title
        : connection.other.profile.tagLine.platform;

    const { followerCount } = connection.other.profile.tagLine;

    return (
        <Main onClick={onClick}>
            <ProfilePhoto
                profilePhotoUrl={connection.other.profile.avatar}
                status={connection.other.profile.status}
                photoSize={46}
                style={{ marginRight: "12px" }}
            />
            <TextContainer>
                {connection.other.profile.username ? (
                    <Title>@{connection.other.profile.username}</Title>
                ) : (
                    <NoUsername>No Username</NoUsername>
                )}
                <Subtitle>
                    {!!followerCount ? (
                        <>
                            <strong>
                                {shortenNumber(
                                    connection.other.profile.tagLine
                                        .followerCount
                                )}
                            </strong>{" "}
                            on {platformTitle}
                        </>
                    ) : (
                        "Unknown"
                    )}
                </Subtitle>
            </TextContainer>
            {selected ? (
                <Checkbox src={"/checkbox_selected.svg"} />
            ) : (
                <Checkbox src={"/checkbox_unselected.svg"} />
            )}
        </Main>
    );
};

export default SelectConnectionRow;
