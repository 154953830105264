import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ShareButton from "src/components/ShareButton";
import { Campaign } from "src/campaigns/types";
import { breakpoints, colors } from "src/constants";
import { submitEvent } from "src/events/actions";
import BidIncreaseTag from "src/components/campaign/BidIncreaseTag";
import CampaignReward from "src/campaigns/details/components/CampaignReward";
import { setCurrentCampaignId } from "src/ui/campaigns/actions";
import MediaStatus from "./MediaStatus";
import BudgetTag from "src/campaigns/budgets-bids/BudgetTag";
import CampaignSocialProof from "./CampaignSocialProof";
import { fetchCampaignStatistics } from "src/campaigns/actions";

const Main = styled("div")`
    position: relative;
    background-color: ${colors.white};
    border: ${colors.transparent} solid 1px;
    border-radius: 10px 10px 0px 0px;
`;

const HeaderTags = styled.div`
    display: block;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            background: ${colors.lightGray};
        }
    }

    :hover {
        text-decoration: none;
    }

    ${props =>
        props.theme.isMobile &&
        css`
            @media (hover: hover) {
                :hover {
                    background: ${colors.transparent};
                }
            }

            @media (max-width: ${breakpoints.mobile}px) {
                padding: 0px 10px;
            }
        `}
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    cursor: pointer;

    @media (max-width: ${breakpoints.mobile}px) {
        height: auto;
    }
`;

const AppIcon = styled.img`
    width: 66px;
    height: 66px;
    margin-right: 15px;
    border-radius: 12px;
`;

const AppMeta = styled.div`
    text-align: left;
    line-height: 1.4em;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const CampaignName = styled.span`
    display: block;
    color: ${colors.black};
    font-weight: 500;
`;

const SocialProof = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: 100%;
    height: 40px;
    background-color: ${colors.lightGray};
    border-radius: 8px;

    @media (max-width: ${breakpoints.mobile}px) {
        margin-top: 10px;
    }

    ${HeaderTags}:hover & {
        background-color: ${colors.white};
    }
`;

const Tags = styled("div")`
    display: flex;
    gap: 8px;
`;

interface Props {
    campaign: Campaign;
    rank: number;
}
const CampaignHeader = (props: Props) => {
    const { campaign, rank } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCampaignStatistics(campaign.id));
    }, []);

    function setCurrentCampaignHandler(): void {
        dispatch(setCurrentCampaignId(campaign.id));
    }

    const increase: number | undefined | null =
        campaign.countryPlatformReward?.previous_reward ||
        0 !== campaign.countryPlatformReward?.reward
            ? campaign.countryPlatformReward?.reward
            : null;

    const prevReward: number | undefined | null =
        campaign.countryPlatformReward?.previous_reward;

    const statNumber: number = parseInt(campaign.statistics?.stats || "0");

    const history = useHistory();
    function trackCampaignClicked(event: React.MouseEvent<HTMLDivElement>) {
        dispatch(setCurrentCampaignId(campaign.id));

        dispatch(
            submitEvent({
                event_name: "campaign_clicked",
                event_extra: {
                    campaignId: campaign.id,
                    rank
                },
                value: rank
            })
        );

        event.stopPropagation();
        history.push(`/campaign/${campaign.id}`);
    }
    return (
        <Main>
            <HeaderTags onClick={e => trackCampaignClicked(e)}>
                <Header onClick={setCurrentCampaignHandler}>
                    <AppIcon
                        src={campaign.imageUrl}
                        alt={campaign.campaignName}
                    />
                    <AppMeta>
                        <CampaignName>{campaign.campaignName}</CampaignName>
                        {campaign.countryPlatformReward && (
                            <CampaignReward
                                countryPlatformReward={
                                    campaign.countryPlatformReward
                                }
                                rewardType={campaign.rewardType}
                            />
                        )}
                    </AppMeta>

                    <ShareButton
                        isCampaign={true}
                        link={`/campaign/${campaign.id}`}
                    />
                </Header>
                {statNumber > 20 && (
                    <SocialProof>
                        <CampaignSocialProof statNumber={statNumber} />
                    </SocialProof>
                )}
                <Tags>
                    <BidIncreaseTag
                        prevReward={prevReward}
                        increase={increase}
                        campaign={campaign}
                    />
                    <BudgetTag campaign={campaign} />
                </Tags>
            </HeaderTags>

            <MediaStatus campaign={campaign} />
        </Main>
    );
};

export default CampaignHeader;
