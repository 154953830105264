import React from "react";
import { goBack, push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { FlexVCSpaceBetween } from "src/components/CommonStyles";
import FullscreenLayout from "src/components/layout/FullscreenLayout";
import NavButton from "src/components/NavButton";
import { GlobalState } from "src/reducers";
import { setCurrentFlowStep } from "src/ui/flows/actions";
import styled from "styled-components";
import IntroBuysContent from "./IntroBuysContent";
import Navbar from "src/components/Navbar";
import NavbarMobile from "src/components/NavbarMobile";

const Main = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const Nav = styled.div`
    min-height: 60px;
    padding: 0 16px;
    ${FlexVCSpaceBetween}
`;

const Content = styled.div`
    flex: 1;
    min-height: 0;
`;

const IntroBuys = () => {
    const currentStep = useSelector(
        (state: GlobalState) => state.ui.flows.introToDirectBuy
    );
    const dispatch = useDispatch();

    function setCurrentStep(step: number) {
        dispatch(setCurrentFlowStep("introToDirectBuy", step));
    }

    function handleBack() {
        if (currentStep === 0) {
            dispatch(goBack());
        } else {
            setCurrentStep(currentStep - 1);
        }
    }

    function handleFinished() {
        dispatch(push("/"));
        setCurrentStep(0);
    }

    return (
        <>
            <NavbarMobile marginBottom={20} back forceDisplay />

            <FullscreenLayout>
                <Main>
                    {/* <Nav>
                    <NavButton back onClick={handleBack} />
                </Nav> */}

                    <Content>
                        <IntroBuysContent
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                            handleFinished={handleFinished}
                        />
                    </Content>
                </Main>
            </FullscreenLayout>
        </>
    );
};

export default IntroBuys;
