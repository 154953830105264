import { AnimatePresence } from "framer-motion";
import moment from "moment";
import React from "react";
import FullScreenModal from "src/modals/FullScreenModal";
import AnalyticsDatePicker from "../AnalyticsDatePicker";
import AnalyticsDateRanges, {
    AnalyticsDateRangeObject
} from "../AnalyticsDateRanges";
import { AnalyticsFullscreenState, AnalyticsScrollTo } from "../AnalyticsMain";
import { ModalProps } from "../utils/props";

interface Props extends ModalProps {
    dayDateRange: AnalyticsDateRangeObject;
    dayStartDate: moment.Moment | null;
    dayEndDate: moment.Moment | null;
    setDayDateRange: (dateRange: AnalyticsDateRangeObject) => void;
    setDayStartDate: (date: moment.Moment | null) => void;
    setDayEndDate: (date: moment.Moment | null) => void;
}

const EarningsByDayDatePicker = ({
    open,
    closeModal,
    showMore,
    setScrollTo,
    setFullscreenState,
    dayDateRange,
    dayStartDate,
    dayEndDate,
    setDayDateRange,
    setDayStartDate,
    setDayEndDate
}: Props) => {
    return (
        <AnimatePresence>
            {open && (
                <FullScreenModal closeModal={closeModal}>
                    <AnalyticsDatePicker
                        onCancelTapped={() => {
                            setScrollTo(AnalyticsScrollTo.earningsByDay);
                            showMore
                                ? setFullscreenState(
                                      AnalyticsFullscreenState.earningsByDayMore
                                  )
                                : setFullscreenState(
                                      AnalyticsFullscreenState.default
                                  );
                        }}
                        onDoneTapped={(args: {
                            dateRange: AnalyticsDateRangeObject;
                            startDate: moment.Moment | null;
                            endDate: moment.Moment | null;
                        }) => {
                            setDayDateRange(args.dateRange);
                            setDayStartDate(args.startDate);
                            setDayEndDate(args.endDate);
                            setScrollTo(AnalyticsScrollTo.earningsByDay);
                            showMore
                                ? setFullscreenState(
                                      AnalyticsFullscreenState.earningsByDayMore
                                  )
                                : setFullscreenState(
                                      AnalyticsFullscreenState.default
                                  );
                        }}
                        initialDateRange={dayDateRange}
                        initialStartDate={dayStartDate}
                        initialEndDate={dayEndDate}
                        dateRangeArray={AnalyticsDateRanges.slice(3)}
                    />
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default EarningsByDayDatePicker;
