import React from "react";
import { colors } from "../../../constants";

interface Props {
    visibility: "visible" | "connections" | "hidden";
    active?: boolean;
}

const VisibilityIcon = ({ visibility, active = true }: Props) => {
    const iconColor = !active
        ? colors.primaryGray
        : visibility === "visible"
        ? colors.primaryBlue
        : visibility === "connections"
        ? colors.primaryYellow
        : colors.primaryRed;

    if (visibility === "visible") {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="12" cy="12" r="12" fill={iconColor} />
                <path
                    d="M18.63 11.6587C18.63 11.6587 15.8224 8.36523 12 8.36523C8.17785 8.36523 5.36999 11.6587 5.36999 11.6587C5.21 11.8463 5.21 12.1534 5.36999 12.3413C5.36999 12.341 8.17785 15.6345 12 15.6345C15.8222 15.6345 18.6298 12.341 18.6298 12.341C18.79 12.1534 18.79 11.8463 18.63 11.6587ZM12 14.7978C10.4387 14.7978 9.17287 13.5452 9.17287 11.9998C9.17287 10.4545 10.4387 9.20188 12 9.20188C13.5613 9.20188 14.8272 10.4547 14.8272 11.9998C14.8272 13.545 13.5615 14.7978 12 14.7978Z"
                    fill="white"
                />
                <path
                    d="M13.3335 11.4145C13.0013 11.4145 12.7318 11.1481 12.7318 10.8191C12.7318 10.7333 12.7504 10.6515 12.7835 10.5779C12.5507 10.452 12.2838 10.3805 12.0001 10.3805C11.0964 10.3805 10.3638 11.1056 10.3638 11.9999C10.3638 12.8943 11.0964 13.6196 12.0001 13.6196C12.9038 13.6196 13.6364 12.8945 13.6364 12.0002C13.6364 11.7832 13.5931 11.5763 13.5148 11.3871C13.4576 11.4048 13.3966 11.4145 13.3335 11.4145Z"
                    fill="white"
                />
            </svg>
        );
    } else if (visibility === "connections") {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="12" cy="12" r="12" fill={iconColor} />
                <circle cx="16.2858" cy="9.71429" r="1.71429" fill="#F7F7F7" />
                <path
                    d="M12.8573 15.3333C12.8573 13.4924 14.3497 12 16.1906 12H16.3811C18.2221 12 19.7144 13.4924 19.7144 15.3333C19.7144 15.7015 19.416 16 19.0478 16H13.524C13.1558 16 12.8573 15.7015 12.8573 15.3333Z"
                    fill="#F7F7F7"
                />
                <circle cx="7.42852" cy="9.71429" r="1.71429" fill="#F7F7F7" />
                <path
                    d="M4 15.3333C4 13.4924 5.49238 12 7.33333 12H7.52381C9.36476 12 10.8571 13.4924 10.8571 15.3333C10.8571 15.7015 10.5587 16 10.1905 16H4.66667C4.29848 16 4 15.7015 4 15.3333Z"
                    fill="#F7F7F7"
                />
                <circle cx="12.0002" cy="9.14286" r="2.14286" fill="white" />
                <path
                    d="M7.71436 16.1667C7.71436 13.8655 9.57984 12 11.881 12H12.1191C14.4203 12 16.2858 13.8655 16.2858 16.1667C16.2858 16.6269 15.9127 17 15.4525 17H8.54769C8.08745 17 7.71436 16.6269 7.71436 16.1667Z"
                    fill="white"
                />
            </svg>
        );
    } else {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="12" cy="12" r="12" fill={iconColor} />
                <circle cx="12" cy="8.57143" r="2.57143" fill="white" />
                <path
                    d="M6.85718 17C6.85718 14.2386 9.09575 12 11.8572 12H12.1429C14.9043 12 17.1429 14.2386 17.1429 17C17.1429 17.5523 16.6952 18 16.1429 18H7.85718C7.30489 18 6.85718 17.5523 6.85718 17Z"
                    fill="white"
                />
            </svg>
        );
    }
};

export default VisibilityIcon;
