import React, { useRef, useState } from "react";
import { colors, fonts, fontWeight, sizes } from "src/constants";
import { useCloseModalWhenClickedOutside } from "src/utils/functions/hooks";
import styled from "styled-components";

interface MainProps {
    focused?: boolean;
    selected?: boolean;
}

const Container = styled.div`
    background-color: ${colors.transparent};
    width: 100%;
`;

export const Input = styled.input<MainProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: ${colors.white};
    cursor: ;
    color: ${props =>
        props.selected ? `${colors.black}` : `${colors.primaryGray}`};

    font-weight: ${props =>
        props.selected ? `${fontWeight.medium}` : `${fontWeight.default}`};
    font-size: ${fonts.smallTitle}px;
    transition: border 0.3s ease-in-out;
    border: none;
    outline: none;
`;

const Close = styled.img`
    margin-right: 3px;
    transition: all 0.3s;
`;

const Caret = styled.img`
    margin-right: 10px;
    width: 12px;
    transform: rotate(-90deg);
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: absolute;
    width: 400px;
    max-height: 372px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(204, 204, 204, 0.25);
    background: ${colors.white};
    z-index: 999;
    overflow: auto;
`;

export const DropdownItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 62px;
    padding: 23px 12px;
    gap: 10px;
    background: ${colors.white};
    border-radius: 8px;
    cursor: pointer;
    font-weight: ${fontWeight.medium};
    font-size: ${fonts.semiSmallTitle}px;
    line-height: 16px;
    color: ${colors.black};
    text-align: left;
    text-overflow: ellipsis;

    &:hover {
        background: ${colors.quaternaryLightGray};
    }
`;

const InputContainer = styled.div<MainProps>`
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border: ${props =>
        props.focused
            ? ` 1px solid ${colors.primaryBlue}`
            : `1px solid ${colors.mediumTeal}`};
    width: 100%;
    height: ${sizes.marketplace.paymentSets.inputContainer.height}px;
    padding: 16px;
    background-color: ${colors.white};
    cursor: pointer;
`;

interface Props {
    itemList: any[];
    selected: any;
    handleOnClick: (item: any) => void;
    getItemName?: (item: any) => string;
    search: string;
    onSearch: (v: string) => void;
}

const DropdownSelect = ({
    itemList,
    selected,
    handleOnClick,
    getItemName,
    search = "",
    onSearch
}: Props) => {
    const mainRef = useRef(null);
    const [expanded, toggleExpanded] = useState(false);

    useCloseModalWhenClickedOutside(
        mainRef,
        expanded,
        () => toggleExpanded(false),
        [expanded]
    );

    return (
        <Container ref={mainRef}>
            <InputContainer>
                <Input
                    selected={!!selected}
                    value={search}
                    onChange={e => {
                        onSearch && onSearch(e.currentTarget.value);
                    }}
                    onClick={() => toggleExpanded(!expanded)}
                />
                <Close
                    src={"/close.svg"}
                    onClick={e => {
                        handleOnClick(null);
                        onSearch("");
                        e.stopPropagation();
                    }}
                />
            </InputContainer>
            {expanded && (
                <List>
                    {itemList.map((item, idx) => {
                        return (
                            <DropdownItem
                                key={idx}
                                onClick={() => {
                                    handleOnClick(item);
                                    getItemName && onSearch(getItemName(item));
                                    toggleExpanded(!expanded);
                                }}
                            >
                                {getItemName ? getItemName(item) : item}
                                <Caret src={"/caret.svg"} />
                            </DropdownItem>
                        );
                    })}
                </List>
            )}
        </Container>
    );
};

export default DropdownSelect;
