import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectionButton from "src/components/SelectionButton";
import { GlobalState } from "src/reducers";
import {
    creatorGroupsSingleSelectionFilterOptions,
    SingleSelectionFilterTypes
} from "../utils/constants";
import { checkSelected, setSingleSelectionFilter } from "../utils/helpers";

interface Props {
    searchString: string;
    singleSelectionFilterType: SingleSelectionFilterTypes | "";
}

const SingleSelectionFilters = ({
    searchString,
    singleSelectionFilterType
}: Props) => {
    // Library Methods -----------------------------------------------
    const dispatch = useDispatch();

    // Redux State ---------------------------------------------------
    const {
        followersFilter,
        averageViewsFilter,
        engagementRateFilter,
        audienceGenderFilter,
        audienceAgeFilters,
        audienceDeviceFilter
    } = useSelector((state: GlobalState) => {
        return state.ui.creators;
    });

    // JSX -----------------------------------------------------------
    return (
        <>
            {singleSelectionFilterType !== "" &&
                creatorGroupsSingleSelectionFilterOptions[
                    singleSelectionFilterType
                ]
                    .filter((option: string) => {
                        if (searchString === "") {
                            return true;
                        } else {
                            return option
                                .toLowerCase()
                                .includes(searchString.toLowerCase());
                        }
                    })
                    .map((option: string, index: number) => {
                        return (
                            <SelectionButton
                                key={`marketplace-creator-groups-filters-modal-${singleSelectionFilterType}-${option}-${index}`}
                                text={option}
                                selected={checkSelected(
                                    singleSelectionFilterType,
                                    option,
                                    singleSelectionFilterType === "Followers"
                                        ? followersFilter
                                        : singleSelectionFilterType ===
                                          "Average Views"
                                        ? averageViewsFilter
                                        : singleSelectionFilterType ===
                                          "Engagement Rate"
                                        ? engagementRateFilter
                                        : singleSelectionFilterType ===
                                          "Audience Gender"
                                        ? audienceGenderFilter
                                        : audienceDeviceFilter
                                )}
                                setSelected={() =>
                                    setSingleSelectionFilter(
                                        singleSelectionFilterType,
                                        option,
                                        dispatch
                                    )
                                }
                            />
                        );
                    })}
        </>
    );
};

export default SingleSelectionFilters;
