import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { Shoutout, ShoutoutFillOrder } from "../types";
import { cancelShoutout } from "../actions";
import { clearModal } from "../../../modals/actions";
import styled from "styled-components";
import Modal from "../../../modals/Modal";
import ModalButton from "../../../modals/ModalButton";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 300;
`;

interface Props {
    currentShoutout: Shoutout | null;
    cancelShoutout: (fillOrders: ShoutoutFillOrder[]) => void;
    clearModal: () => void;
}

const CancelModal = ({
    currentShoutout,
    cancelShoutout,
    clearModal
}: Props) => {
    if (currentShoutout) {
        const tryToCancelOrder = () => {
            if (
                window.confirm(
                    "Are you sure you want to cancel? Proceeding will release the views you've reserved and you will not get paid."
                )
            ) {
                cancelShoutout(currentShoutout.ShoutoutFillOrders);
                clearModal();
            }
        };

        return (
            <Modal>
                <Main>
                    <Title>No Screenshots?</Title>
                    <Subtitle>
                        Without screenshots we cannot verify that you've
                        provided the views. You can either cancel the order (you
                        will not be paid), or contact support for further
                        assistance.
                    </Subtitle>
                </Main>
                <ModalButton
                    text={"Contact Support"}
                    onClick={() => window.open("mailto:support@plugco.in")}
                />
                <ModalButton text={"Cancel Order"} onClick={tryToCancelOrder} />
                <ModalButton text={"Close"} />
            </Modal>
        );
    } else {
        clearModal();
        return null;
    }
};

const mapStateToProps = (state: GlobalState) => ({
    currentShoutout: state.ui.shoutouts.currentShoutout
});

const mapDispatchToProps = (dispatch: any) => ({
    cancelShoutout: (fillOrders: ShoutoutFillOrder[]) =>
        dispatch(cancelShoutout(fillOrders)),
    clearModal: () => dispatch(clearModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelModal);
