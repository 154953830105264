import { Contest, ContestPrize } from "../types";

export function combineAndRankOrderPrizes(
    contest: Contest,
    userid?: number
): ContestPrize[] {
    let allPrizes: ContestPrize[] = [];

    for (let i = 0; i < contest.leaderboard.length; i++) {
        const currentLeaderboard = contest.leaderboard[i];
        allPrizes = allPrizes.concat(currentLeaderboard.prize);
    }

    for (let i = 0; i < contest.raffle.length; i++) {
        const currentRaffle = contest.raffle[i];
        allPrizes = allPrizes.concat(currentRaffle.prize);
    }

    return collapseRepeats(allPrizes)
        .sort((prize1, prize2) => {
            return (
                parseFloat(prize2.cash_equivalent) -
                parseFloat(prize1.cash_equivalent)
            );
        })
        .filter(prize => {
            if (prize.subPrizes) {
                return prize.subPrizes.some(subPrize => {
                    return subPrize.winner.id === userid || !userid;
                });
            } else {
                return prize.winner.id === userid || !userid;
            }
        })
        .slice(0, 10);
}

export function collapseRepeats(prizes: ContestPrize[]): ContestPrize[] {
    const prizeObject: { [name: string]: ContestPrize } = {};

    prizes &&
        prizes.forEach(prize => {
            if (prize) {
                if (!prizeObject[prize.name]) {
                    prizeObject[prize.name] = { ...prize };
                }

                const subPrizes = prizeObject[prize.name].subPrizes;

                if (subPrizes) {
                    subPrizes.push({ ...prize });
                } else {
                    prizeObject[prize.name].subPrizes = [{ ...prize }];
                }
            }
        });

    return Object.values(prizeObject);
}

export function howToWin(prize: ContestPrize) {
    return prize.winner.ticket_number !== undefined
        ? `${
              prize.subPrizes && prize.subPrizes.length > 1
                  ? `${prize.subPrizes.length} `
                  : ""
          }Raffle Winner${
              prize.subPrizes && prize.subPrizes.length > 1 ? "s" : ""
          }`
        : prize.how_to_win;
}

export function addTickets(contest: Contest): number {
    let total = 0;

    contest.raffle.forEach(raffle => {
        if (raffle.ticket) {
            total += raffle.ticket.earned;
        }
    });

    return total;
}
