import React from "react";
import {
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot
} from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { colors, fonts } from "src/constants";
import { GlobalState } from "src/reducers";
import { clearSpacing, flexRowCenter } from "src/utils/styles/snippets";
import styled from "styled-components";

interface MainProps {
    isDraggingOver: boolean;
}

const Main = styled.article<MainProps>`
    ${flexRowCenter()};
    min-height: 140px;
    color: ${colors.black};
    background-color: ${colors.blueGrey};
    border-radius: 8px;
    font-size: ${fonts.smallTitle}px;
    overflow: hidden;
    background-color: ${props => props.isDraggingOver && colors.mediumTeal};
`;

const Text = styled.p`
    ${clearSpacing()};
    width: 350px;
    text-align: center;
`;

interface Props {
    instructionStepIndex: number;
}

const InstructionsFormStepEmptyDragArea = ({ instructionStepIndex }: Props) => {
    const isCurrentDragTargetAnInstructionElement = useSelector(
        (state: GlobalState) => {
            return state.ui.instructionsSets
                .isCurrentDragTargetAnInstructionElement;
        }
    );

    return (
        <Droppable
            key={`instructions-form-step-empty-drag-area-${instructionStepIndex}`}
            droppableId={`InstructionsFormStepEmptyDroppable-${instructionStepIndex}`}
        >
            {(
                provided: DroppableProvided,
                snapshot: DroppableStateSnapshot
            ) => (
                <Main
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={
                        isCurrentDragTargetAnInstructionElement &&
                        snapshot.isDraggingOver
                    }
                >
                    <Text>
                        Drag and drop an instruction element from the left-side
                        column.
                    </Text>
                </Main>
            )}
        </Droppable>
    );
};

export default InstructionsFormStepEmptyDragArea;
