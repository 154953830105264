import React from "react";
import { Campaign, OldCampaign } from "src/campaigns/types";
import Header from "../../../components/Header";
import PaddingNoTop from "../../../components/PaddingNoTop";
import SearchBar from "../../../components/SearchBar";
import Divider from "../../../components/Divider";
import MainContainer from "src/components/MainContainer";
import CampaignCell from "./CampaignCell";
import styled from "styled-components";
import { breakpoints, maxWidthDesktop } from "src/constants";

const SearchDivider = styled(Divider)`
    display: none;

    @media (max-width: ${maxWidthDesktop}px) {
        display: block;
    }
`;

const PromotedCampaigns = styled.section`
    margin: 15px 0px 40px;
    padding: 0px 15px;

    @media (max-width: ${breakpoints.mobile}px) {
        margin-bottom: 15px;
    }
`;

const IncrementWhenInView = styled.div`
    height: 1px;
`;

interface Props {
    promotedCampaigns: OldCampaign[];
}

interface State {
    maxCampaigns: number;
    searchString: string;
}

class PromotedPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            maxCampaigns: 12,
            searchString: ""
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    incrementWhenInViewRef = React.createRef<HTMLDivElement>();

    observer = new IntersectionObserver(
        () => this.setState({ maxCampaigns: this.state.maxCampaigns + 4 }),
        {
            threshold: 1.0
        }
    );

    componentDidMount(): void {
        window.addEventListener("scroll", this.handleScroll);

        if (
            this.incrementWhenInViewRef &&
            this.incrementWhenInViewRef.current
        ) {
            this.observer.observe(this.incrementWhenInViewRef.current);
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll(): void {
        const innerHeight = window.innerHeight;
        const scrollTopBody = document.getElementsByTagName("body")[0]
            .scrollTop;
        const scrollTopDoc = document.documentElement.scrollTop;
        const scrollTop = Math.max(scrollTopBody, scrollTopDoc) + 50;
        const offsetHeight = document.documentElement.offsetHeight;
        if (
            innerHeight + scrollTop >= offsetHeight &&
            document.body.style.position !== "fixed"
        ) {
            this.setState({ maxCampaigns: this.state.maxCampaigns + 4 });
        }
    }

    render() {
        const endList = Math.min(
            this.state.maxCampaigns,
            this.props.promotedCampaigns.length
        );

        return (
            <MainContainer>
                <Header
                    title={"Promoted Campaigns"}
                    subtitle={`${this.props.promotedCampaigns.length} Campaigns`}
                />
                <PaddingNoTop>
                    <SearchBar
                        searchString={this.state.searchString}
                        updateSearchString={(searchString: string) => {
                            this.setState({ searchString });
                        }}
                    />
                </PaddingNoTop>

                <SearchDivider />

                <PromotedCampaigns>
                    {this.props.promotedCampaigns
                        .filter((campaign: OldCampaign) => {
                            if (this.state.searchString.length === 0) {
                                return true;
                            } else {
                                return campaign.campaign_name
                                    .toLowerCase()
                                    .includes(
                                        this.state.searchString.toLowerCase()
                                    );
                            }
                        })
                        .slice(0, endList)
                        .map((campaign: OldCampaign) => {
                            return (
                                <CampaignCell
                                    key={campaign.id}
                                    campaign={campaign}
                                />
                            );
                        })}
                </PromotedCampaigns>

                <IncrementWhenInView ref={this.incrementWhenInViewRef} />
            </MainContainer>
        );
    }
}

export default PromotedPage;
