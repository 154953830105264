import { add, Duration, getUnixTime } from "date-fns";
import React from "react";
import { useDispatch } from "react-redux";
import ActionsModal from "src/modals/ActionsModal";
import { setConversationPauseDuration } from "../actions";

interface Props {
    conversationId: string;
    closeModal: () => void;
}

const GroupPauseNotificationsModal = ({
    conversationId,
    closeModal
}: Props) => {
    const dispatch = useDispatch();

    function dispatchPauseDuration(duration: Duration, indefinitely?: true) {
        if (indefinitely) {
            dispatch(
                setConversationPauseDuration({
                    conversationId,
                    notificationsPausedIndefinitely: true
                })
            );
        } else {
            const notificationsPausedUntil = getUnixTime(
                add(new Date(), duration)
            );
            dispatch(
                setConversationPauseDuration({
                    conversationId,
                    notificationsPausedUntil
                })
            );
        }
        closeModal();
    }

    return (
        <ActionsModal
            actions={[
                {
                    text: "Pause Notifications For:",
                    callback: () => {}
                },
                {
                    text: "30 Minutes",
                    callback: () => dispatchPauseDuration({ minutes: 30 })
                },
                {
                    text: "1 Hour",
                    callback: () => dispatchPauseDuration({ hours: 1 })
                },
                {
                    text: "1 Day",
                    callback: () => dispatchPauseDuration({ days: 1 })
                },
                {
                    text: "Indefinitely",
                    callback: () => dispatchPauseDuration({}, true)
                }
            ]}
            localClearModal={closeModal}
        />
    );
};

export default GroupPauseNotificationsModal;
