import { POST_ACCOUNTS } from "src/social-accounts/actions";
import { Action } from "src/types";
import {
    SET_SOCIAL_ACCOUNT_DETAILS_MODAL_OPEN,
    SET_SOCIAL_ACCOUNT_FOLLOWERS_MODAL_OPEN,
    SET_SOCIAL_ACCOUNT_GROUP_MODAL_OPEN
} from "./actions";

export type FollowersDemographicsModalType =
    | ""
    | "Language"
    | "Age"
    | "Country"
    | "Gender";

export interface SocialAccountsUIState {
    postAccountsLoading: boolean;
    postAccountsError: string;

    detailsModalOpen: boolean;
    addToGroupModalOpen: boolean;
    followersDemographicsModalOpen: boolean;
    followersDemographicsModalType: FollowersDemographicsModalType;
}

const initialSocialAccountsUIState: SocialAccountsUIState = {
    postAccountsLoading: false,
    postAccountsError: "",

    detailsModalOpen: false,
    addToGroupModalOpen: false,
    followersDemographicsModalOpen: false,
    followersDemographicsModalType: ""
};

const socialAccountsUIReducer = (
    state: SocialAccountsUIState = initialSocialAccountsUIState,
    action: Action
): SocialAccountsUIState => {
    switch (action.type) {
        case POST_ACCOUNTS.REQUEST:
            return { ...state, postAccountsLoading: true };

        case POST_ACCOUNTS.SUCCESS:
            return { ...state, postAccountsLoading: false };

        case POST_ACCOUNTS.FAILURE:
            return { ...state, postAccountsError: action.payload };

        // Social Account -----------------------------------------------

        case SET_SOCIAL_ACCOUNT_DETAILS_MODAL_OPEN:
            return { ...state, detailsModalOpen: action.payload.open };

        case SET_SOCIAL_ACCOUNT_GROUP_MODAL_OPEN:
            return { ...state, addToGroupModalOpen: action.payload.open };

        case SET_SOCIAL_ACCOUNT_FOLLOWERS_MODAL_OPEN:
            return {
                ...state,
                followersDemographicsModalOpen: action.payload.open,
                followersDemographicsModalType: action.payload.type
            };

        default:
            return state;
    }
};

export default socialAccountsUIReducer;
