import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as Icons from "src/components/icons/exports";
import AvatarNamePlatform from "src/campaigns/landing/components/AvatarNamePlatform";
import MediaItemButton from "src/campaigns/landing/components/MediaItemButton";
import useInViewDispatch from "src/hooks/useInViewDispatch";
import CarouselMedia from "src/modals/CarouselMedia";
import { CampaignMedia } from "src/campaigns/types";
import { submitEvent } from "src/events/actions";
import { GlobalState } from "src/reducers";
import { colors } from "src/constants";
import { useInView } from "react-intersection-observer";
import { getCampaignMedia } from "../selectors";
import { setCampaignMediaModalItems } from "src/ui/campaigns/actions";
import { selectCampaign } from "src/campaigns/selectors";
import { flex, flexRowCenter } from "src/utils/styles/snippets";

const Main = styled("div")`
    ${flex("column")};
    width: 100%;
    height: 100%;
    min-height: 90vh;
    padding: 20px;
    background-color: ${colors.white};
    border-radius: 10px;
`;

const Header = styled("div")`
    ${flex("row", "space-between", "center")};

    @media (max-width: 386px) {
        ${flex("row", "space-between", "flex-start")};
    }
`;

const HeaderCloseButton = styled("button")`
    ${flexRowCenter()};
    background-color: none;
    outline: none;
    cursor: pointer;

    :focus {
        outline: none;
    }
`;

const Asset = styled("div")`
    margin: 20px 0px;
    flex: 1;
`;

const ButtonContainer = styled("div")`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 6px;
`;

const Button = styled("div")`
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 4px;
    overflow: hidden;
`;

interface Props {
    currentMediaId: number;
    closeMediaItemModal: React.MouseEventHandler<HTMLButtonElement>;
    rank: number;
}

const MediaItemModalContent = (props: Props) => {
    const { currentMediaId, closeMediaItemModal, rank } = props;

    const currentCampaign = useSelector(selectCampaign);

    let HeaderAvatarNamePlatform: React.ReactNode | string = "";
    if (currentCampaign) {
        HeaderAvatarNamePlatform = (
            <AvatarNamePlatform
                imageUrl={currentCampaign.imageUrl}
                campaignName={currentCampaign.campaignName}
                reward={currentCampaign.countryPlatformReward.reward}
                rewardType={currentCampaign.rewardType}
                country={currentCampaign.countryPlatformReward.country}
                platform={currentCampaign.countryPlatformReward.platform}
            />
        );
    }

    const currentMedia = useSelector((state: GlobalState) => {
        return getCampaignMedia(state, currentMediaId);
    });

    const trackAdViewedRef = useInViewDispatch(
        submitEvent({
            event_name: "ad_viewed",
            event_extra: {
                mediaId: currentMedia.id,
                campaignId: currentMedia.campaignId,
                rank
            },
            value: rank
        }),
        2000
    );

    const { ref, inView } = useInView({ threshold: 0.95 });

    const mediaCarouselLength = useSelector((state: GlobalState) => {
        return state.ui.campaign.currentLoadedMedias.length;
    });

    const dispatch = useDispatch();
    useEffect(() => {
        if (inView) {
            dispatch(setCampaignMediaModalItems(rank, mediaCarouselLength));
        }
    }, [inView]);

    const mediaModalItems = useSelector((state: GlobalState) => {
        return state.ui.campaign.mediaModalItems;
    });

    const MediaModalItem = mediaModalItems.includes(rank) && (
        <CarouselMedia
            mediaUrl={currentMedia.url || ""}
            mediaType={currentMedia.type}
            isPortrait={currentMedia.isPortrait}
        />
    );

    const campaignMedia = useSelector((state: GlobalState) => {
        return state.entities.medias[currentMediaId];
    });

    return (
        <Main ref={trackAdViewedRef}>
            <Header>
                {HeaderAvatarNamePlatform}
                <HeaderCloseButton onClick={closeMediaItemModal}>
                    <Icons.Close height={20} />
                </HeaderCloseButton>
            </Header>

            <Asset ref={ref}>{MediaModalItem}</Asset>

            <ButtonContainer>
                <Button>
                    <MediaItemButton
                        type="link"
                        // copyLink={appDownloadLink}
                        height={"40px"}
                        text="Copy Link"
                        campaignMedia={campaignMedia}
                    />
                </Button>
                <Button>
                    <MediaItemButton
                        type="download"
                        downloadLink={currentMedia.url}
                        height={"40px"}
                        text="Download"
                        campaignMedia={campaignMedia}
                    />
                </Button>
            </ButtonContainer>
        </Main>
    );
};

export default MediaItemModalContent;
