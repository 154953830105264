import {
    DirectBuy,
    DirectBuyStage,
    DirectBuyState
} from "../modules/direct-buy/types";
import { IndicatorBackgroundColor } from "./IndicatorBackground";
import { IndicatorTagColor, IndicatorTagContent } from "./IndicatorTag";
import { DirectBuyPreviewSize } from "./DirectBuyPreview";
import { shortTimeInterval, shortTimeIntervalArray } from "../../util";
import moment from "moment";
import {
    DirectBuyPayment,
    DirectBuyPaymentCollection
} from "../modules/direct-buy-payment/types";
import {
    PaymentConjunction,
    paymentConjunctionToShortString
} from "../../types/PaymentConjunction";
import {
    longStringForDirectBuyPayment,
    shortStringForDirectBuyPayment
} from "../modules/direct-buy-payment/util";

export function getIndicatorBackgroundColorForDirectBuyState(
    directBuyState: DirectBuyState
): IndicatorBackgroundColor {
    switch (directBuyState) {
        case DirectBuyState.new:
            return IndicatorBackgroundColor.blue;
        case DirectBuyState.viewed:
            return IndicatorBackgroundColor.inactive;
        case DirectBuyState.active:
            return IndicatorBackgroundColor.blue;
        case DirectBuyState.pending:
            return IndicatorBackgroundColor.yellow;
        case DirectBuyState.rejected:
            return IndicatorBackgroundColor.red;
        case DirectBuyState.expired:
            return IndicatorBackgroundColor.blue;
        case DirectBuyState.approved:
            return IndicatorBackgroundColor.blue;
    }
}

export function getIndicatorTagContentForDirectBuy(
    directBuy: DirectBuy,
    directBuyState: DirectBuyState,
    directBuyPreviewSize: DirectBuyPreviewSize
): IndicatorTagContent {
    const defaultContent: IndicatorTagContent = {
        string: "",
        isImageUrl: false,
        color: IndicatorTagColor.clear
    };
    switch (directBuyState) {
        case DirectBuyState.new:
            return {
                string: "NEW",
                isImageUrl: false,
                color: IndicatorTagColor.blue
            };
        case DirectBuyState.viewed:
            if (
                directBuy.offerExpiresAt &&
                directBuyPreviewSize === DirectBuyPreviewSize.small
            ) {
                return {
                    string: shortTimeInterval(
                        Date.parse(directBuy.offerExpiresAt) - Date.now()
                    ),
                    isImageUrl: false,
                    color: IndicatorTagColor.inactive
                };
            } else {
                return defaultContent;
            }
        case DirectBuyState.active:
            if (
                directBuy.buyExpiresAt &&
                directBuyPreviewSize === DirectBuyPreviewSize.small
            ) {
                return {
                    string: shortTimeInterval(
                        Date.parse(directBuy.buyExpiresAt) - Date.now()
                    ),
                    isImageUrl: false,
                    color: IndicatorTagColor.blue
                };
            } else {
                return defaultContent;
            }
        case DirectBuyState.pending:
            return {
                string: "/indicator-pending.svg",
                isImageUrl: true,
                color: IndicatorTagColor.yellow
            };
        case DirectBuyState.rejected:
            return {
                string: "/indicator-rejected.svg",
                isImageUrl: true,
                color: IndicatorTagColor.red
            };
        case DirectBuyState.expired:
            return defaultContent;
        case DirectBuyState.approved:
            return {
                string: "/indicator-approved.svg",
                isImageUrl: true,
                color: IndicatorTagColor.blue
            };
    }
}

export function getTimeStringForDirectBuy(
    directBuy: DirectBuy,
    directBuyState: DirectBuyState,
    directBuyStage: DirectBuyStage | null,
    directBuyPreviewSize: DirectBuyPreviewSize
): string | null {
    switch (directBuyState) {
        case DirectBuyState.new:
            if (
                directBuy.offerExpiresAt &&
                directBuyPreviewSize === DirectBuyPreviewSize.large
            ) {
                return `⏰ ${shortTimeIntervalArray(
                    Date.parse(directBuy.offerExpiresAt) - Date.now(),
                    2
                ).join(" ")}`;
            } else {
                return null;
            }
        case DirectBuyState.viewed:
            if (
                directBuy.offerExpiresAt &&
                directBuyPreviewSize === DirectBuyPreviewSize.large
            ) {
                return `⏰ ${shortTimeIntervalArray(
                    Date.parse(directBuy.offerExpiresAt) - Date.now(),
                    2
                ).join(" ")}`;
            } else {
                return null;
            }
        case DirectBuyState.active:
            if (
                directBuy.buyExpiresAt &&
                directBuyPreviewSize === DirectBuyPreviewSize.large
            ) {
                return `⏰ ${shortTimeIntervalArray(
                    Date.parse(directBuy.buyExpiresAt) - Date.now(),
                    2
                ).join(" ")}`;
            } else {
                return null;
            }
        case DirectBuyState.pending:
            if (
                directBuy.buyExpiresAt &&
                directBuyPreviewSize === DirectBuyPreviewSize.large
            ) {
                return `⏰ ${shortTimeIntervalArray(
                    Date.parse(directBuy.buyExpiresAt) - Date.now(),
                    2
                ).join(" ")}`;
            } else {
                return null;
            }
        case DirectBuyState.rejected:
            if (
                directBuy.buyExpiresAt &&
                directBuyPreviewSize === DirectBuyPreviewSize.large
            ) {
                return `⏰ ${shortTimeIntervalArray(
                    Date.parse(directBuy.buyExpiresAt) - Date.now(),
                    2
                ).join(" ")}`;
            } else {
                return null;
            }
        case DirectBuyState.expired:
            if (directBuyPreviewSize !== DirectBuyPreviewSize.large) {
                return null;
            } else if (
                directBuy.offerExpiresAt &&
                directBuyStage === DirectBuyStage.offer
            ) {
                return `🚫 ${moment(directBuy.offerExpiresAt).format(
                    "MM/DD/YYYY"
                )}`;
            } else if (
                directBuy.buyExpiresAt &&
                directBuyStage === DirectBuyStage.buy
            ) {
                return `🚫 ${moment(directBuy.buyExpiresAt).format(
                    "MM/DD/YYYY"
                )}`;
            } else {
                return null;
            }
        case DirectBuyState.approved:
            if (
                directBuy.buyApprovedAt &&
                directBuyPreviewSize === DirectBuyPreviewSize.large
            ) {
                return `✅ ${moment(directBuy.buyApprovedAt).format(
                    "MM/DD/YYYY"
                )}`;
            } else {
                return null;
            }
    }
}

export function getStringForDirectBuyPaymentCollectionArray(
    payments: DirectBuyPaymentCollection[]
): string {
    const paymentObjects: {
        paymentConjunction: PaymentConjunction;
        directBuyPayments: DirectBuyPayment[];
    }[] = [];
    payments.forEach(payment => {
        if (payment.payment) {
            paymentObjects.push(payment.payment);
        }
    });
    if (paymentObjects.length === 1) {
        const singlePaymentObject = paymentObjects[0];
        if (singlePaymentObject.directBuyPayments.length === 1) {
            const singlePayment = singlePaymentObject.directBuyPayments[0];
            return longStringForDirectBuyPayment(singlePayment);
        } else {
            return singlePaymentObject.directBuyPayments
                .map(payment => shortStringForDirectBuyPayment(payment))
                .join(
                    paymentConjunctionToShortString(
                        singlePaymentObject.paymentConjunction
                    )
                );
        }
    } else {
        return `${paymentObjects.length} Payments`;
    }
}

export function outerPaddingForDirectBuyPreviewSize(
    size: DirectBuyPreviewSize
) {
    switch (size) {
        case DirectBuyPreviewSize.large:
            return 6;
        case DirectBuyPreviewSize.small:
            return 8;
    }
}

export function outerBorderRadiusForDirectBuyPreviewSize(
    size: DirectBuyPreviewSize
) {
    switch (size) {
        case DirectBuyPreviewSize.large:
            return 20;
        case DirectBuyPreviewSize.small:
            return 14;
    }
}

export function innerPaddingForDirectBuyPreviewSize(
    size: DirectBuyPreviewSize
) {
    switch (size) {
        case DirectBuyPreviewSize.large:
            return 6;
        case DirectBuyPreviewSize.small:
            return 4;
    }
}

export function innerBorderRadiusForDirectBuyPreviewSize(
    size: DirectBuyPreviewSize
) {
    switch (size) {
        case DirectBuyPreviewSize.large:
            return 14;
        case DirectBuyPreviewSize.small:
            return 10;
    }
}
