import React, { useState } from "react";
import SmallModal from "src/modals/SmallModal";
import withModalPortal from "src/components/withModalPortal";
import { DeleteButton } from "src/profile/components/Buttons";
import { deleteReq } from "src/Api";
import { useDispatch } from "react-redux";
import { setPillNotificationText } from "src/ui/notifications/actions";

interface Props {
    id: any;
    closeModal: VoidFunction;
    advanceOrCloseCarousel: VoidFunction;
}
const DeleteConfirmationModal = ({
    id,
    closeModal,
    advanceOrCloseCarousel
}: Props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleDelete = async () => {
        try {
            setLoading(true);
            await deleteReq(`/v1/openBuySubmission/${id}/`);
            dispatch(
                setPillNotificationText("Submission Deleted", "success", 3500)
            );
            advanceOrCloseCarousel();
            closeModal();
        } catch {
            dispatch(
                setPillNotificationText(
                    `Failed To Delete Submission `,
                    "danger",
                    3500
                )
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <SmallModal
            header={{ text: "Delete Submission", handleClose: closeModal }}
        >
            <DeleteButton shimmer={loading} onClick={handleDelete}>
                Delete Submission
                {id}
            </DeleteButton>
        </SmallModal>
    );
};

export default withModalPortal(DeleteConfirmationModal);
