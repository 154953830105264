import React from "react";
import { MediaRule } from "src/campaigns/types";
import styled from "styled-components";
import CircleIcon from "../../../components/CircleIcon";
import VerticallyCentered from "../../../components/VerticallyCentered";
import { colors } from "../../../constants";

const Body = styled.div`
    font-size: 12px;
    line-height: 14.32px;
    margin-left: 8px;
    text-align: left;
`;

interface Props {
    mediaRule: MediaRule;
}

const MediaRuleRow = ({ mediaRule }: Props) => {
    if (mediaRule.isAllowed)
        return (
            <VerticallyCentered key={mediaRule.id}>
                <CircleIcon type="check" color={colors.primaryGreen} />
                <Body>{mediaRule.rule}</Body>
            </VerticallyCentered>
        );

    return (
        <VerticallyCentered key={mediaRule.id}>
            <CircleIcon type="x" color={colors.primaryRed} />
            <Body>{mediaRule.rule}</Body>
        </VerticallyCentered>
    );
};

export default MediaRuleRow;
