import {
    defaultInstructionStepState,
    setCreatingInstruction
} from "./../../ui/instructions-sets/actions";
import { GlobalState } from "src/reducers";
import { setCurrentWizardStep } from "src/ui/create-offer-wizard/actions";
import { push } from "connected-react-router";
import { normalize } from "normalizr";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { deleteReq, get, patch, post } from "src/Api";
import { Action } from "src/types";
import {
    setCurrentInstructionsSetId,
    setInstructionsPageStep,
    setInstructionsPageType,
    setInstructionsSetsLoading,
    setInstructionsSetSubmitStatus,
    setInstructionSteps
} from "src/ui/instructions-sets/actions";
import { setPillNotificationText } from "src/ui/notifications/actions";
import {
    createInstructionsSetFailure,
    CREATE_INSTRUCTIONS_SET,
    deleteInstructionsSetFailure,
    deleteInstructionsSetSuccess,
    DELETE_INSTRUCTIONS_SET,
    fetchInstructionsSetFailure,
    fetchInstructionsSetsFailure,
    fetchInstructionsSetsSuccess,
    fetchInstructionsSetSuccess,
    FETCH_INSTRUCTIONS_SET,
    FETCH_INSTRUCTIONS_SETS,
    NormalizedInstructionSetsResponse,
    updateInstructionsSetFailure,
    updateInstructionsSetSuccess,
    UPDATE_INSTRUCTIONS_SET
} from "./actions.instructions-sets";
import { instructionsSetsList } from "./schema";
import {
    createOfferWizardRoute,
    instructionSetsRoutes
} from "../utils/marketplace-routes";
import { attachInstructionSetToOpenBuy } from "../open-buys/actions";

function* fetchInstructionsSetsRequest(action: Action) {
    try {
        const { campaignId, includeSteps } = action.payload;

        const endpoint = `/v1/instruction/?includeSteps=${includeSteps}&campaignId=${campaignId}`;

        const response = yield call(get, endpoint, { isInOpenBuy: true });

        const normalizedData: NormalizedInstructionSetsResponse = normalize(
            response.data.data,
            instructionsSetsList
        );

        yield put(fetchInstructionsSetsSuccess(normalizedData));
    } catch (error) {
        console.log(error);
        yield put(
            fetchInstructionsSetsFailure(
                "Failed to retrieve instructions sets."
            )
        );
    }
}

function* fetchInstructionsSetRequest(action: Action) {
    try {
        const { instructionsSetId, includeSteps } = action.payload;
        const endpoint = `/v1/instruction/${instructionsSetId}?includeSteps=${includeSteps}`;
        const response = yield call(get, endpoint, {});

        yield put(fetchInstructionsSetSuccess(response.data.data));
    } catch (error) {
        console.log(error);
        yield put(
            fetchInstructionsSetFailure("Failed to retrieve instructions set.")
        );
    }
}

function* createInstructionsSetRequest(action: Action) {
    try {
        yield put(setInstructionsSetsLoading(true));
        yield put(setCreatingInstruction(true));

        const endpoint = "/v1/instruction";
        const { data, isDuplicating, openBuyId } = action.payload;
        const response = yield call(post, endpoint, data);

        yield put(setCurrentInstructionsSetId(response.data.data.id));

        // yield put(push(createOfferWizardRoute));
        // yield put(push(instructionSetsRoutes.main));
        yield put(setCurrentWizardStep("Select an Instruction Set"));
        yield put(setInstructionsSetsLoading(false));
        yield put(setCreatingInstruction(false));

        const { inAdminTools, currentOpenBuyId } = yield select(
            (state: GlobalState) => {
                return state.ui.adminTools;
            }
        );
        const { offerCreationModalCompleted } = yield select(
            (state: GlobalState) => state.ui.createOfferWizard
        );
        if (inAdminTools) {
            if (openBuyId) {
                yield put(
                    attachInstructionSetToOpenBuy(
                        openBuyId,
                        response.data.data.id
                    )
                );
                yield put(setInstructionsPageType("Edit"));
                yield put(setInstructionsPageStep("Scratch"));
            }
            if (currentOpenBuyId || isDuplicating) {
                yield put(setInstructionsPageType("Edit"));
            } else {
                yield put(setInstructionsPageType("Main"));
            }
        } else if (offerCreationModalCompleted) {
            yield put(push("/marketplace/create-new-offer"));
        } else {
            yield put(push(instructionSetsRoutes.main));
        }

        if (!currentOpenBuyId) {
            yield put(setInstructionSteps([defaultInstructionStepState()]));
        }
        yield put(
            setPillNotificationText("Instruction Set Created Successfully")
        );
    } catch (error) {
        console.log(error);
        yield put(
            createInstructionsSetFailure("Failed to create instructions set.")
        );
    }
}

function* updateInstructionsSetRequest(action: Action) {
    try {
        const { instructionsSetId, data, instructionSteps } = action.payload;
        const endpoint = `/v1/instruction/${instructionsSetId}`;
        const response = yield call(patch, endpoint, data);

        yield put(
            updateInstructionsSetSuccess(
                instructionsSetId,
                response.data.data,
                instructionSteps
            )
        );
        yield put(setCurrentInstructionsSetId(instructionsSetId));
        yield put(setCurrentWizardStep("Select an Instruction Set"));
        // yield put(push(createOfferWizardRoute));
        // yield put(push(instructionSetsRoutes.main));

        const { inAdminTools, currentOpenBuyId } = yield select(
            (state: GlobalState) => {
                return state.ui.adminTools;
            }
        );
        const { offerCreationModalCompleted } = yield select(
            (state: GlobalState) => state.ui.createOfferWizard
        );
        if (inAdminTools) {
            if (currentOpenBuyId) {
                yield put(setInstructionsPageType("Edit"));
            } else {
                yield put(setInstructionsPageType("Main"));
            }
        } else if (offerCreationModalCompleted) {
            yield put(push("/marketplace/create-new-offer"));
        } else {
            yield put(push(instructionSetsRoutes.main));
        }
    } catch (error) {
        console.log(error);
        yield put(
            updateInstructionsSetFailure("Failed to update instructions set.")
        );
    }
}

function* deleteInstructionsSetRequest(action: Action) {
    try {
        const instructionsSetId = action.payload;
        const endpoint = `/v1/instruction/${instructionsSetId}`;
        yield call(deleteReq, endpoint, {});

        yield put(deleteInstructionsSetSuccess(instructionsSetId));
        yield put(setCurrentInstructionsSetId(null));
        yield put(setInstructionsPageStep("Selection"));
        yield put(setCurrentWizardStep("Select an Instruction Set"));
        // yield put(push(createOfferWizardRoute));
        // yield put(push(instructionSetsRoutes.main));

        const { inAdminTools, currentOpenBuyId } = yield select(
            (state: GlobalState) => {
                return state.ui.adminTools;
            }
        );
        const { offerCreationModalCompleted } = yield select(
            (state: GlobalState) => state.ui.createOfferWizard
        );
        if (inAdminTools) {
            yield put(setInstructionsPageType("Main"));
            if (currentOpenBuyId) {
                // yield put(setInstructionsPageType("Create"));
                // yield put(setInstructionsPageStep("Scratch"));
                yield put(setInstructionsPageType("Main"));
                yield put(setInstructionsPageStep("Selection"));
            }
        } else if (offerCreationModalCompleted) {
            yield put(push("/marketplace/create-new-offer"));
        } else {
            yield put(push(instructionSetsRoutes.main));
        }

        yield put(
            setPillNotificationText("Instruction Set Deleted Successfully")
        );
    } catch (error) {
        console.log(error);
        yield put(
            deleteInstructionsSetFailure("Failed to delete instructions set.")
        );
    }
}

export default function* instructionsSetsSaga() {
    yield all([
        takeEvery(
            FETCH_INSTRUCTIONS_SETS.REQUEST,
            fetchInstructionsSetsRequest
        ),
        takeEvery(FETCH_INSTRUCTIONS_SET.REQUEST, fetchInstructionsSetRequest),
        takeEvery(
            CREATE_INSTRUCTIONS_SET.REQUEST,
            createInstructionsSetRequest
        ),
        takeEvery(
            UPDATE_INSTRUCTIONS_SET.REQUEST,
            updateInstructionsSetRequest
        ),
        takeEvery(DELETE_INSTRUCTIONS_SET.REQUEST, deleteInstructionsSetRequest)
    ]);
}
