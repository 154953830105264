import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import ModalHeading from "src/components/modals/components/ModalHeading";
import MarketplaceInput from "src/marketplace/components/MarketplaceInput";
import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Label = styled.div`
    font-size: 12px;
    font-weight: bold;
`;

const StyledMarketplaceInput = styled(MarketplaceInput)`
    height: 46px;
`;

interface Props {
    label: string;
    value?: string | number;
    type: string;
    onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
    onChange?: (v: string, e: ChangeEvent<HTMLInputElement>) => void;
    //@ts-ignore typescript is broken here
    onDoneTyping?: (e: KeyboardEvent<HTMLInputElement>, v?: string) => void;
}
const AdminToolInput = ({
    label,
    value,
    type,
    onChange,
    onBlur,
    onDoneTyping
}: Props) => {
    const ref = useRef(null);
    const [val, setVal] = useState<string | undefined>(undefined);

    let timer: NodeJS.Timeout | null = null;

    return (
        <Main>
            <ModalHeading text={label} />
            <StyledMarketplaceInput
                onKeyUp={e =>
                    (timer = setTimeout(
                        () => onDoneTyping && onDoneTyping(e, val),
                        1300
                    ))
                }
                onKeyDown={() => timer && clearTimeout(timer)}
                value={value || val}
                type={type}
                onBlur={e => onBlur && onBlur(e)}
                onChange={e => {
                    onChange && onChange(e.currentTarget.value, e);
                    setVal(e.currentTarget.value);
                }}
            />
        </Main>
    );
};

export default AdminToolInput;
