import { CampaignMedia } from "src/campaigns/types";
import { Action } from "../types";
import { MediaStatus, Notification } from "./types";

export const ADD_SYSTEM_NOTIFICATIONS = "ADD_SYSTEM_NOTIFICATIONS";

export const addSystemNotifications = (
    systemNotifications: Notification[]
): Action => ({
    type: ADD_SYSTEM_NOTIFICATIONS,
    payload: { systemNotifications }
});

export const NEW_SYSTEM_NOTIFICATION = "NEW_SYSTEM_NOTIFICATION";

export const newSystemNotification = (
    systemNotification: Notification
): Action => ({ type: NEW_SYSTEM_NOTIFICATION, payload: systemNotification });

export const ADD_TRANSACTION_NOTIFICATIONS = "ADD_TRANSACTION_NOTIFICATIONS";

export const addTransactionNotifications = (
    transactionNotifications: Notification[]
): Action => ({
    type: ADD_TRANSACTION_NOTIFICATIONS,
    payload: { transactionNotifications }
});

export const READ_NOTIFICATIONS = "READ_NOTIFICATIONS";

export const readNotifications = (): Action => ({
    type: READ_NOTIFICATIONS,
    payload: null
});

export const ADD_MEDIA_STATUS_NOTIFICATION = "ADD_MEDIA_STATUS_NOTIFCATIONS";

export const addMediaStatusNotification = (
    mediaStatusNotification: MediaStatus
): Action => ({
    type: ADD_MEDIA_STATUS_NOTIFICATION,
    payload: { mediaStatusNotification }
});

export const REMOVE_MEDIA_STATUS_NOTIFICATION =
    "REMOVE_MEDIA_STATUS_NOTIFCATION";

export const removeMediaStatusNotification = (id?: number): Action => ({
    type: REMOVE_MEDIA_STATUS_NOTIFICATION,
    payload: { id }
});

export const OPEN_POST_MEDIA_MODAL = "OPEN_POST_MEDIA_MODAL";

export const openPostMediaModal = (
    mediaStatusNotification: MediaStatus
): Action => ({
    type: OPEN_POST_MEDIA_MODAL,
    payload: { mediaStatusNotification }
});

export const CLEAR_POST_MEDIA_MODAL = "CLEAR_POST_MEDIA_MODAL";

export const clearPostMediaModal = (): Action => ({
    type: CLEAR_POST_MEDIA_MODAL,
    payload: null
});

// -------------------------------------------------------------------

export const FETCH_MEDIA_NOTIFICATION = {
    REQUEST: "FETCH_MEDIA_NOTIFICATION_REQUEST",
    SUCCESS: "FETCH_MEDIA_NOTIFICATION_SUCCESS",
    FAILURE: "FETCH_MEDIA_NOTIFICATION_FAILURE"
};

export function fetchMedia(): Action {
    return {
        type: FETCH_MEDIA_NOTIFICATION.REQUEST,
        payload: null
    };
}

export function fetchMediaNotificationSuccess(
    media: CampaignMedia | null
): Action {
    return {
        type: FETCH_MEDIA_NOTIFICATION.SUCCESS,
        payload: media
    };
}

//rename these
