import { AnimatePresence } from "framer-motion";
import React from "react";
import FullScreenModal from "src/modals/FullScreenModal";
import TotalEarningsCard from "src/analytics/TotalEarningsCard";
import { AnalyticsFullscreenState, AnalyticsScrollTo } from "../AnalyticsMain";
import { ModalTotalAndDayMoreProps } from "../utils/props";
import { CampaignTotals, MediaTotals } from "../AnalyticsDataParser";

interface Props extends ModalTotalAndDayMoreProps {
    parsedData: (CampaignTotals | MediaTotals)[];
    setParsedData: (data: (CampaignTotals | MediaTotals)[]) => void;
}

const TotalEarningsMoreModal = ({
    open,
    closeModal,
    setFullscreenState,
    filterState,
    simpleNavbar,
    modal,
    parsedData,
    setParsedData
}: Props) => {
    return (
        <AnimatePresence>
            {open && (
                <FullScreenModal closeModal={closeModal}>
                    {simpleNavbar(AnalyticsScrollTo.totalEarnings)}
                    <TotalEarningsCard
                        filterState={filterState}
                        parsedData={parsedData}
                        setParsedData={setParsedData}
                        pickDates={() =>
                            setFullscreenState(
                                AnalyticsFullscreenState.totalEarningsDates
                            )
                        }
                        pickFilters={() =>
                            setFullscreenState(
                                AnalyticsFullscreenState.totalEarningsFilter
                            )
                        }
                    />
                    {modal}
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default TotalEarningsMoreModal;
