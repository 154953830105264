import React from "react";

import { Props } from "src/components/icons/types";

const Search = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || "10"}
            viewBox="0 0 10 10"
            fill="none"
        >
            <g clipPath="url(#A)">
                <path
                    fillRule="evenodd"
                    d="M7.576 6.83c1.293-1.661 1.176-4.063-.35-5.59-1.653-1.653-4.333-1.653-5.986 0s-1.653 4.333 0 5.986c1.527 1.527 3.929 1.643 5.59.35l.022.024 2.245 2.245a.53.53 0 0 0 .748 0 .53.53 0 0 0 0-.748L7.6 6.852l-.024-.022zM6.478 1.988c1.24 1.24 1.24 3.25 0 4.49s-3.25 1.24-4.49 0-1.24-3.25 0-4.49 3.25-1.24 4.49 0z"
                    fill={props.fill || "#000"}
                />
            </g>
            <defs>
                <clipPath id="A">
                    <path fill="#fff" d="M0 0h10v10H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Search;
