import React from "react";

import { Props } from "src/components/icons/types";

const ChevronLeft = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || "10"}
            viewBox="0 0 10 10"
            fill="none"
        >
            <g clipPath="url(#A)">
                <path
                    d="M7.632.273c.385.376.415.899 0 1.359l-3.23 3.369 3.23 3.369c.415.46.385.984 0 1.357s-1.032.352-1.392 0L2.359 5.68c-.091-.088-.164-.194-.213-.31S2.07 5.127 2.07 5s.026-.252.075-.369.122-.222.213-.31L6.24.273c.36-.353 1.009-.376 1.392 0z"
                    fill={props.fill || "#000"}
                />
            </g>
            <defs>
                <clipPath id="A">
                    <path fill="#fff" d="M0 0h10v10H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ChevronLeft;
