import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import MediaPreview from "src/campaigns/promote/components/MediaPreview";
import FlexColumn from "src/components/FlexColumn";
import Modal from "src/modals/Modal";
import styled from "styled-components";
import { PublicBuysScreenshot } from "../redux/reducer";

const Main = styled.div`
    display: flex;
    flex-direction: row;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 2px;
`;

interface Props {
    onClose: () => void;
    screenshot: PublicBuysScreenshot;
}

const PublicBuyScreenshotModal = ({ onClose, screenshot }: Props) => {
    const [screenshots, setScreenshots] = useState<PublicBuysScreenshot[]>([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    return (
        <Modal
            onClick={onClose}
            style={{
                maxWidth: "80vw",
                minWidth: 600,
                maxHeight: "90vh",
                width: "auto"
            }}
        >
            <Main>
                <FlexColumn>
                    <MediaPreview
                        media={{
                            url: screenshot.screenshotUrl || "",
                            type: "image"
                        }}
                        style={{
                            height: "600px",
                            width: "350px",
                            backgroundColor: "black"
                        }}
                    />
                </FlexColumn>
                <FlexColumn>
                    <Title>
                        Uploaded At:{" "}
                        {moment(screenshot.createdAt).format("lll")}
                    </Title>
                    <Title>Uploaded By: {screenshot.uploadedBy}</Title>
                </FlexColumn>
            </Main>
        </Modal>
    );
};
export default PublicBuyScreenshotModal;
