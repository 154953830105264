import React from "react";
import { Connection } from "../../types";
import { breakpoints, colors, fonts } from "../../../constants";
import styled from "styled-components";
import ProfilePhoto from "../../../components/ProfilePhoto";
import VerticallyCentered from "../../../components/VerticallyCentered";
import {
    BlueConnectionButton,
    OutlineConnectionButton,
    SquareConnectionButton
} from "../../components/Buttons";
import { shortenNumber } from "../../../util";
import Platforms from "../../../social-accounts/Platforms";
import {
    deleteConnection,
    DeleteConnectionParams,
    postConnection,
    PostConnectionParams
} from "../../actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import HideOverflow from "../../../components/HideOverflow";
// @ts-ignore
import { withSendBird } from "sendbird-uikit";
import { flex } from "src/utils/styles/snippets";
import { createConversation } from "src/new-chat/conversation/actions";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const Main = styled.div`
    ${flex("row", "auto", "center")};
    width: 100%;
    padding: 6px 10px;
    background-color: ${colors.transparent};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 5px;
    text-align: left;
    transition: 0.1s ease-in;
    cursor: pointer;

    & + & {
        margin-top: 15px;
    }

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
            border: ${colors.darkTeal} solid 1px;
        }
    }

    @media (max-width: ${breakpoints.mobile}px) {
        padding: ${props => {
            return props.theme.isMobile ? "0px" : "6px 10px";
        }};
        border: ${props => {
            return props.theme.isMobile
                ? `${colors.transparent} solid 1px`
                : `${colors.mediumTeal} solid 1px`;
        }};
    }
`;

const TextContainer = styled.div<{ containerWidth: number }>`
    width: calc(100% - ${props => props.containerWidth}px);
`;

const Title = styled(HideOverflow)`
    width: 100%;
    font-size: ${fonts.smallTitle}px;
`;

const NoUsername = styled(Title)`
    color: ${colors.primaryGray};
    font-style: italic;
    font-weight: 300;
`;

const Subtitle = styled(HideOverflow)`
    width: 100%;
    color: ${colors.primaryGray};
    font-size: 12px;
`;

const ButtonContainer = styled(VerticallyCentered)`
    margin-left: auto;
`;

const CloseIcon = styled.img`
    width: 12px;
    height: 12px;
    opacity: 0.4;
`;

const RejectButton = styled(SquareConnectionButton)`
    margin-left: 8px;
`;

const OptionalButton = styled(SquareConnectionButton)`
    margin-right: 8px;
`;

interface Props {
    connection: Connection;
    postConnection: (params: PostConnectionParams) => void;
    deleteConnection: (params: DeleteConnectionParams) => void;
    push: (path: string) => void;
    sendBird: any;
    optionalCallback?: VoidFunction;
}

const ConnectionCell = ({
    connection,
    postConnection,
    deleteConnection,
    push,
    sendBird,
    optionalCallback
}: Props) => {
    const chatRenewal = useSelector(selectFeatureFlags(featureFlags.Chat2));
    const dispatch = useDispatch();

    const messageCallback = () => {
        if (chatRenewal) {
            dispatch(
                createConversation({
                    users: [
                        {
                            avatarUrl: connection.other.profile.avatar,
                            username: connection.other.profile.username || "",
                            publisherId: connection.other.id.slice(2)
                        }
                    ]
                })
            );
        } else {
            push(`/chat/new/${connection.other.id}`);
        }
    };

    if (connection.state === "denied") {
        return null;
    }

    const self = connection.entity.id === connection.other.id;

    const userid = connection.other.id.slice(2);

    const platformTitle = Platforms[connection.other.profile.tagLine.platform]
        ? Platforms[connection.other.profile.tagLine.platform].title
        : connection.other.profile.tagLine.platform;

    let textContainerWidth = 170;

    if (connection.state === "requested") {
        textContainerWidth += 40;
    }

    if (optionalCallback) {
        textContainerWidth += 40;
    }

    return (
        <Main
            onClick={() => {
                if (self) {
                    push("/profile");
                } else {
                    const path = `/public/${userid}`;
                    if (window.location.pathname !== path) {
                        push(path);
                    }
                }
            }}
        >
            <ProfilePhoto
                profilePhotoUrl={connection.other.profile.avatar}
                status={connection.other.profile.status}
                photoSize={46}
                style={{ marginRight: "12px" }}
            />
            <TextContainer containerWidth={textContainerWidth}>
                {connection.other.profile.username ? (
                    <Title>@{connection.other.profile.username}</Title>
                ) : (
                    <NoUsername>No Username</NoUsername>
                )}
                <Subtitle>
                    {connection.other.profile.tagLine.followerCount ? (
                        <>
                            <strong>
                                {shortenNumber(
                                    connection.other.profile.tagLine
                                        .followerCount,
                                    1
                                )}
                            </strong>{" "}
                            on {platformTitle}
                        </>
                    ) : (
                        <>{"No Socials"}</>
                    )}
                </Subtitle>
            </TextContainer>
            <ButtonContainer onClick={e => e.stopPropagation()}>
                {optionalCallback && (
                    <OptionalButton onClick={optionalCallback}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="6"
                                cy="12"
                                r="2"
                                fill={colors.secondaryGray}
                            />
                            <circle
                                cx="12"
                                cy="12"
                                r="2"
                                fill={colors.secondaryGray}
                            />
                            <circle
                                cx="18"
                                cy="12"
                                r="2"
                                fill={colors.secondaryGray}
                            />
                        </svg>
                    </OptionalButton>
                )}
                {self ? null : connection.state === "friend" ? (
                    <BlueConnectionButton onClick={messageCallback}>
                        Message
                    </BlueConnectionButton>
                ) : connection.state === "waiting" ? (
                    <OutlineConnectionButton disabled>
                        Requested
                    </OutlineConnectionButton>
                ) : connection.state === "requested" ? (
                    <>
                        <BlueConnectionButton
                            onClick={() => {
                                postConnection({
                                    selfId: connection.entity.id,
                                    otherId: connection.other.id,
                                    relationship: "friend"
                                });
                            }}
                        >
                            Accept
                        </BlueConnectionButton>
                        <RejectButton
                            onClick={() => {
                                deleteConnection({
                                    selfId: connection.entity.id,
                                    otherId: connection.other.id
                                });
                            }}
                        >
                            <CloseIcon src="/black_close.svg" />
                        </RejectButton>
                    </>
                ) : connection.state === "blocked" ? (
                    <OutlineConnectionButton
                        textColor={colors.primaryRed}
                        onClick={() => {
                            postConnection({
                                selfId: connection.entity.id,
                                otherId: connection.other.id,
                                relationship: "none"
                            });
                            sendBird.unblockUserWithUserId(
                                connection.other.id,
                                (user: any, error: any) => {
                                    if (error) {
                                        window.alert(error);
                                    }
                                }
                            );
                        }}
                    >
                        Unblock
                    </OutlineConnectionButton>
                ) : (
                    <BlueConnectionButton
                        onClick={() => {
                            postConnection({
                                selfId: connection.entity.id,
                                otherId: connection.other.id,
                                relationship: "friend"
                            });
                        }}
                    >
                        Connect
                    </BlueConnectionButton>
                )}
            </ButtonContainer>
        </Main>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    postConnection: (params: PostConnectionParams) =>
        dispatch(postConnection(params)),
    deleteConnection: (params: DeleteConnectionParams) =>
        dispatch(deleteConnection(params)),
    push: (route: string) => dispatch(push(route))
});

export default withSendBird(
    connect(null, mapDispatchToProps)(ConnectionCell),
    (state: any) => ({
        sendBird: state.stores.sdkStore.sdk
    })
);
