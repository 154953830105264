import React from "react";
import { colors } from "../../../constants";

interface Props {
    visibility: "visible" | "connections" | "hidden";
    active?: boolean;
}

const VisibilitySmallIcon = ({ visibility, active }: Props) => {
    const iconColor = !active
        ? colors.primaryGray
        : visibility === "visible"
        ? colors.primaryBlue
        : visibility === "connections"
        ? colors.primaryYellow
        : colors.primaryRed;

    if (visibility === "visible") {
        return (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="8" cy="8" r="8" fill={iconColor} />
                <path
                    d="M12.42 7.77239C12.42 7.77239 10.5482 5.57678 7.99993 5.57678C5.45182 5.57678 3.57992 7.77239 3.57992 7.77239C3.47326 7.89748 3.47326 8.10223 3.57992 8.22748C3.57992 8.22733 5.45182 10.4229 7.99993 10.4229C10.548 10.4229 12.4198 8.22733 12.4198 8.22733C12.5266 8.10223 12.5266 7.89748 12.42 7.77239ZM7.99993 9.86517C6.95908 9.86517 6.11517 9.03012 6.11517 7.99986C6.11517 6.9696 6.95908 6.13454 7.99993 6.13454C9.04078 6.13454 9.8847 6.96975 9.8847 7.99986C9.8847 9.02997 9.04094 9.86517 7.99993 9.86517Z"
                    fill="white"
                />
                <path
                    d="M8.88889 7.60971C8.66747 7.60971 8.48782 7.43207 8.48782 7.21278C8.48782 7.15554 8.50022 7.10102 8.52225 7.05195C8.36709 6.96805 8.18913 6.92035 7.99999 6.92035C7.39755 6.92035 6.9091 7.40375 6.9091 7.99998C6.9091 8.59621 7.39755 9.07976 7.99999 9.07976C8.60244 9.07976 9.09088 8.59636 9.09088 8.00013C9.09088 7.8555 9.06196 7.71754 9.00978 7.59139C8.97168 7.6032 8.93097 7.60971 8.88889 7.60971Z"
                    fill="white"
                />
            </svg>
        );
    } else if (visibility === "connections") {
        return (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="8" cy="8" r="8" fill={iconColor} />
                <circle cx="10.8572" cy="6.4762" r="1.14286" fill="#F7F7F7" />
                <path
                    d="M8.57151 10.2222C8.57151 8.99492 9.56644 8 10.7937 8H10.9207C12.148 8 13.1429 8.99492 13.1429 10.2222V10.2222C13.1429 10.4677 12.944 10.6667 12.6985 10.6667H9.01596C8.7705 10.6667 8.57151 10.4677 8.57151 10.2222V10.2222Z"
                    fill="#F7F7F7"
                />
                <circle cx="4.95236" cy="6.4762" r="1.14286" fill="#F7F7F7" />
                <path
                    d="M2.66667 10.2222C2.66667 8.99492 3.66159 8 4.88889 8H5.01587C6.24317 8 7.2381 8.99492 7.2381 10.2222V10.2222C7.2381 10.4677 7.03911 10.6667 6.79365 10.6667H3.11111C2.86565 10.6667 2.66667 10.4677 2.66667 10.2222V10.2222Z"
                    fill="#F7F7F7"
                />
                <circle cx="8.00008" cy="6.09523" r="1.42857" fill="white" />
                <path
                    d="M5.14287 10.7778C5.14287 9.24365 6.38652 8 7.92065 8H8.07937C9.6135 8 10.8572 9.24365 10.8572 10.7778V10.7778C10.8572 11.0846 10.6084 11.3333 10.3016 11.3333H5.69842C5.3916 11.3333 5.14287 11.0846 5.14287 10.7778V10.7778Z"
                    fill="white"
                />
            </svg>
        );
    } else {
        return (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="8" cy="8" r="8" fill={iconColor} />
                <circle cx="8.00005" cy="5.71429" r="1.71429" fill="white" />
                <path
                    d="M4.57141 11.3333C4.57141 9.49238 6.0638 8 7.90475 8H8.09522C9.93617 8 11.4286 9.49238 11.4286 11.3333V11.3333C11.4286 11.7015 11.1301 12 10.7619 12H5.23808C4.86989 12 4.57141 11.7015 4.57141 11.3333V11.3333Z"
                    fill="white"
                />
            </svg>
        );
    }
};

export default VisibilitySmallIcon;
