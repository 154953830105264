import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { Campaign, Script } from "src/campaigns/types";
import { colors, fonts } from "src/constants";
import CarouselMedia from "src/modals/CarouselMedia";
import { GlobalState } from "src/reducers";
import { clearButton, responsiveContainer } from "src/utils/styles/snippets";
import styled from "styled-components";
import InstructionElementsCarouselFooter from "./InstructionElementsCarouselFooter";
import InstructionElementsBodyAppHeader from "./InstructionElementsBodyAppHeader";
import { preventChildBubbling } from "src/utils/functions/helpers";
import ScriptModal from "src/campaigns/scripts/modals/ScriptModal";
import { addScript } from "src/campaigns/scripts/actions";
import { campaignScriptModal } from "src/ui/scripts/actions";
import { motion } from "framer-motion";
import { openInstructionElementCarouselModal } from "src/ui/instructions-sets/actions";

const Main = styled.main`
    ${responsiveContainer()};

    & img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
`;

const ScriptModalCarouselItem = styled.div``;

const MediaAndAssetContainer = styled(motion.div)``;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const HeaderTitle = styled.span`
    display: block;
    color: ${colors.white};
    font-size: ${fonts.semiTitle}px;
`;

const HeaderCloseButton = styled.button`
    ${clearButton()};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${colors.white};
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightGray2};
        }
    }
`;

const HeaderCloseIcon = styled.img``;

const Body = styled.section`
    padding: 24px;
    background-color: ${colors.white};
    border-radius: 8px;
    overflow: hidden;
`;

const BodyHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const BodyHeaderTitle = styled.span`
    display: block;
    color: ${colors.black};
    font-size: ${fonts.semiTitle};
    font-weight: 500;
`;

const BodyContent = styled.div`
    display: flex;
    gap: 24px;
`;

const BodyMediaPreview = styled.article`
    display: flex;
    justify-content: center;
    flex: 1;
    height: 652px;
`;

const BodyMediaPreviewAsset = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
`;

const BodyAppInformation = styled.article`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`;

interface Props {
    itemId: number;
    campaign: Campaign;
    closeModal: () => void;
}

const InstructionElementsCarouselItem = ({
    itemId,
    campaign,
    closeModal
}: Props) => {
    const { ref, inView } = useInView({ threshold: 0 });

    const currentInstructionElement = useSelector((state: GlobalState) => {
        return state.ui.instructionsSets.currentInstructionElement;
    });

    const scripts = useSelector((state: GlobalState) => {
        return state.entities.scripts;
    });

    const medias = useSelector((state: GlobalState) => {
        return state.entities.medias;
    });

    const assets = useSelector((state: GlobalState) => {
        return state.entities.assets;
    });

    const dispatch = useDispatch();
    useEffect(() => {
        if (currentInstructionElement === "Scripts") {
            if (!!inView) {
                // In this case, `itemId` = the script id.
                const script = scripts[itemId];
                dispatch(addScript(script));
                dispatch(campaignScriptModal(itemId));
            }
        }
    }, [inView]);

    function renderMediaAndAssetsCarouselItem(): JSX.Element {
        if (currentInstructionElement.includes("Media")) {
            const media = medias[itemId];
            return (
                <CarouselMedia
                    mediaUrl={media.url}
                    mediaType={media.type}
                    isPortrait={media.isPortrait}
                />
            );
        } else {
            const asset = assets[itemId];
            return (
                <CarouselMedia
                    mediaUrl={asset.url}
                    mediaType={asset.type}
                    isPortrait={true}
                />
            );
        }
    }

    return (
        <Main onClick={preventChildBubbling}>
            {/* Used for script carousel modal items */}
            {currentInstructionElement === "Scripts" ? (
                <ScriptModalCarouselItem ref={ref}>
                    {inView && (
                        <ScriptModal
                            insideAnotherModal
                            parentModalExitCallback={() =>
                                dispatch(
                                    openInstructionElementCarouselModal(false)
                                )
                            }
                        />
                    )}
                </ScriptModalCarouselItem>
            ) : (
                <MediaAndAssetContainer
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    {/* Used for media and asset carousel modal items */}
                    <Header>
                        <HeaderTitle>
                            {currentInstructionElement === "Media"
                                ? "View Media"
                                : "View Asset"}
                        </HeaderTitle>
                        <HeaderCloseButton>
                            <HeaderCloseIcon
                                src="/modal-close-icon.svg"
                                alt="header close icon"
                                onClick={closeModal}
                            />
                        </HeaderCloseButton>
                    </Header>

                    <Body>
                        <BodyHeader>
                            <BodyHeaderTitle>
                                {currentInstructionElement === "Media"
                                    ? `Media ID: ${itemId}`
                                    : `Asset ID: ${itemId}`}
                            </BodyHeaderTitle>
                        </BodyHeader>

                        <BodyContent>
                            <BodyMediaPreview>
                                <BodyMediaPreviewAsset ref={ref}>
                                    {inView &&
                                        renderMediaAndAssetsCarouselItem()}
                                </BodyMediaPreviewAsset>
                            </BodyMediaPreview>

                            <BodyAppInformation>
                                <InstructionElementsBodyAppHeader
                                    campaign={campaign}
                                    medias={medias}
                                    currentItemId={itemId}
                                />

                                <InstructionElementsCarouselFooter
                                    campaign={campaign}
                                    currentInstructionElement={
                                        currentInstructionElement
                                    }
                                    medias={medias}
                                    assets={assets}
                                    currentItemId={itemId}
                                />
                            </BodyAppInformation>
                        </BodyContent>
                    </Body>
                </MediaAndAssetContainer>
            )}
        </Main>
    );
};

export default InstructionElementsCarouselItem;
