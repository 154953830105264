import { createSelector } from "reselect";
import { GlobalState } from "../../reducers";
import { getAssets } from "../selectors";

export const getScriptAssets = createSelector(
    (state: GlobalState) => state.ui.script.scriptModal,
    (state: GlobalState) => state.entities.scripts,
    (state: GlobalState) => state.entities.assets,
    (currentScript, scripts, assets) => {
        if (currentScript) {
            const scriptAssets = scripts[currentScript].assets;
            if (scriptAssets) {
                return scriptAssets
                    .sort((a, b) => (a > b ? -1 : a < b ? 1 : 0))
                    .map(id => assets[id]);
            }
        }
    }
);

export const getScriptMedias = createSelector(
    (state: GlobalState) => state.ui.script.scriptModal,
    (state: GlobalState) => state.entities.scripts,
    (state: GlobalState) => state.entities.medias,
    (currentScript, scripts, medias) => {
        if (currentScript) {
            const scriptMedias = scripts[currentScript].medias;
            if (scriptMedias) {
                return scriptMedias.map(id => medias[id]);
            }
        }
    }
);

export const isScriptModalOpen = (state: GlobalState) =>
    !!state.ui.script.scriptModal;

export const selectScriptData = (state: GlobalState) => {
    const scriptId = state.ui.script.scriptModal;
    const scripts = state.entities.scripts;
    if (scriptId) {
        return scripts[scriptId];
    }
};

export const selectScriptModalCampaign = (state: GlobalState) => {
    const scriptId = state.ui.script.scriptModal;
    if (scriptId) {
        const campaignId = state.entities.scripts[scriptId].campaignId;
        return state.entities.campaigns.byId[campaignId];
    }
};

export const searchAssets = createSelector(
    [
        (state: GlobalState) => getAssets(state),
        (state: GlobalState, searchString: string) => searchString
    ],
    (assets, searchString) => {
        return searchString.length > 0
            ? assets?.filter(asset =>
                  asset.id.toString().includes(searchString)
              )
            : assets;
    }
);
