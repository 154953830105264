import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { DailyEarnings } from "./Analytics";
import { push } from "connected-react-router";
import SimpleNavbar from "../components/SimpleNavbar";
import NavButton from "../components/NavButton";
import DailyEarningsCard from "./DailyEarningsCard";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";

interface Props {
    dailyEarnings: DailyEarnings[];
    push: (path: string) => void;
}

const DailyEarningsPage = ({ dailyEarnings, push }: Props) => {
    const leftNavButton = <NavButton onClick={() => push("/analytics")} back />;

    return (
        <>
            <Navbar
                marginBottom={10}
                customMobileNavbar={<SimpleNavbar left={leftNavButton} />}
            />

            <MainContainer>
                <DailyEarningsCard
                    dailyEarnings={dailyEarnings}
                    summary={false}
                    push={push}
                />
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    dailyEarnings: state.analytics.userAnalytics.daily_earnings
});

const mapDispatchToProps = (dispatch: any) => ({
    push: (path: string) => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyEarningsPage);
