import moment from "moment";

export interface AnalyticsDateRangeObject {
    title: string;
    use24HourEndpoint: boolean;
    dateRange: {
        startDate: moment.Moment;
        endDate: moment.Moment;
    };
}

const AnalyticsDateRanges: AnalyticsDateRangeObject[] = [
    {
        title: "Last 24 Hours",
        use24HourEndpoint: true,
        dateRange: {
            startDate: moment(),
            endDate: moment()
        }
    },
    {
        title: "Today So Far",
        use24HourEndpoint: false,
        dateRange: {
            startDate: moment(),
            endDate: moment()
        }
    },
    {
        title: "Yesterday",
        use24HourEndpoint: false,
        dateRange: {
            startDate: moment().subtract(1, "day"),
            endDate: moment().subtract(1, "day")
        }
    },
    {
        title: "3 Days",
        use24HourEndpoint: false,
        dateRange: {
            startDate: moment().subtract(2, "day"),
            endDate: moment()
        }
    },
    {
        title: "1 Week",
        use24HourEndpoint: false,
        dateRange: {
            startDate: moment().subtract(6, "day"),
            endDate: moment()
        }
    },
    {
        title: "2 Weeks",
        use24HourEndpoint: false,
        dateRange: {
            startDate: moment().subtract(13, "day"),
            endDate: moment()
        }
    },
    {
        title: "1 Month",
        use24HourEndpoint: false,
        dateRange: {
            startDate: moment().subtract(1, "month"),
            endDate: moment()
        }
    }
];

export default AnalyticsDateRanges;
