import { NormalizedSchema } from "normalizr";

export interface Action {
    type: string;
    payload: any;
}

export interface NormalizedAction {
    type: string;
    response: any;
    payload?: any;
}

export interface NormalizedPayload {
    entities: any;
    result: any[];
}

export type NormalizedResponse<E, R> = NormalizedSchema<
    { [key: string]: { [key: string]: E } | undefined },
    R
>;

export interface NormalizedActionGeneric<E, R> {
    type: string;
    response: NormalizedResponse<E, R>;
    payload?: any;
}

export interface ResponseData {
    data: any;
}

export const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";
