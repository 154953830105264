import React from "react";
import styled from "styled-components";
import { Metric } from "../types";

const Bold = styled.span<{ titleSize?: number }>`
    font-weight: 600;
    font-size: ${props => (props.titleSize ? props.titleSize : 16)}px;
`;

interface Props {
    metrics: Metric[];
    titleSize?: number;
}

const HighlightMediaCarouselTitle = ({ metrics, titleSize }: Props) => {
    return (
        <div>
            <Bold titleSize={titleSize}>{metrics[0].value}</Bold>{" "}
            {metrics[0].descriptor}
        </div>
    );
};

export default HighlightMediaCarouselTitle;
