import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { freezeBody, thawBody } from "../util";
import { AnimatePresence, motion } from "framer-motion";
import { hideScrollbar } from "src/utils/styles/snippets";
import { breakpoints, colors, maxWidthDesktop, spacing } from "src/constants";

const Parent = styled(motion.div)`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    padding: 0px ${spacing.sideSpacing}px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${breakpoints.mobile}px) {
        padding: 0px;
    }
`;

interface ChildProps {
    expand?: true;
}

const Child = styled(motion.div)<ChildProps>`
    ${props => props.theme.isMobile && hideScrollbar()};
    margin-top: ${props => (props.expand ? "0px" : "20px")};
    width: 100%;
    max-width: ${props => (props.expand ? `${maxWidthDesktop}px` : "500px")};
    height: ${props => {
        return props.expand ? "calc(100% - 40px)" : "calc(100% - 20px)";
    }};
    background-color: ${colors.white};
    border-radius: ${props => (props.expand ? "6px" : "6px 6px 0px 0px")};
    overflow-y: auto;

    @media (max-width: ${breakpoints.mobile}px) {
        width: 100vw;
        max-width: 500px;
        height: calc(100% - 20px);
        margin-top: 20px;
        border-radius: 6px 6px 0px 0px;
    }
`;

interface Props {
    children: React.ReactNode;
    closeModal?: VoidFunction;
    onScroll?: (e: number) => void;
    keepFrozenOnClose?: boolean;
    expand?: true;
}

const FullScreenModal = (props: Props) => {
    const { children, closeModal, onScroll, keepFrozenOnClose, expand } = props;
    const child = useRef<HTMLDivElement>(null);

    useEffect(() => {
        freezeBody();
        return () => {
            if (!keepFrozenOnClose) thawBody();
        };
    }, []);

    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
        const node = child.current;
        if (node && onScroll) {
            onScroll(node.scrollHeight - node.scrollTop);
        }
    };

    return (
        <Parent
            onClick={closeModal}
            key="full-screen-modal-parent"
            initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            animate={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
            exit={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            transition={{ duration: 0.3 }}
        >
            <Child
                ref={child}
                onScroll={handleScroll}
                key="full-screen-modal"
                onClick={e => e.stopPropagation()}
                initial={{ y: 300, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 300, opacity: 0 }}
                transition={{ duration: 0.3 }}
                expand={expand}
            >
                {children}
            </Child>
        </Parent>
    );
};

export default FullScreenModal;
