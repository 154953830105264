import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchSubmissionSets,
    requestOpenBuyById,
    setOpenBuySubmissionStep,
    updateOpenBuy
} from "../actions";
import {
    selectDirectBuyErrorState,
    selectOpenBuysById,
    selectOpenBuyStepEntities,
    selectYourSets
} from "../selectors";
import { DirectBuyError } from "../../ui/direct-buys/reducer";
import SimpleNavbar from "../../components/SimpleNavbar";
import NavButton from "../../components/NavButton";
import DirectBuyDetailsError from "./DirectBuyDetailsError";
import DirectBuyDetailsSectionHeader from "./components/DirectBuyDetailsSectionHeader";
import { post } from "../../Api";
import { GlobalState } from "../../reducers";
import MainContainer from "src/components/MainContainer";
import styled from "styled-components";
import { colors, maxWidthDesktop } from "src/constants";
import { Shimmer } from "src/components/SkeletonLoader";
import OpenBuyPreview, {
    OpenBuyPreviewSize
} from "../components/OpenBuyPreview";
import DirectBuyDetailsModals from "./modals/DirectBuyDetailsModals";
import NoAccessModal from "./modals/NoAccessModal";
import { Route, Switch, useHistory, useLocation } from "react-router";
import OpenBuyBriefAccordion from "../components/OpenBuyBriefAccordion";
import OpenBuyLeaderboardAccordion from "../components/OpenBuyLeaderboardAccordion";
import OpenBuyPaymentDetailsAccordion from "../components/OpenBuyPaymentDetailsAccordion";
import { getOpenBuySubmissionLeaderboard } from "./leaderboard/selectors";
import OpenBuyDetailsFooter from "../components/OpenBuyDetailsFooter";
import { AnimatePresence } from "framer-motion";
import useScrollToTop from "src/hooks/useScrollToTop";
import { OpenBuy, OpenBuyState } from "../modules/open-buys/types";
import { getStateForOpenBuy } from "../modules/open-buys/util";
import OpenBuyDetailsInstructions from "./instructions/OpenBuyDetailsInstructions";
import OpenBuySubmissionScreenModal from "./submissions/OpenBuySubmissionScreenModal";
import { fetchOpenBuyLeaderboard } from "./leaderboard/actions";
import OpenBuyMediaRulesSection from "./instructions/OpenBuyMediaRulesSection";

const Sections = styled.div`
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 20px;
    margin: 20px auto;

    @media (max-width: ${maxWidthDesktop}px) {
        grid-gap: 0px;
        margin-top: 0px;
    }
`;

export const PhotoLoading = styled.div`
    width: 100%;
    margin-top: 10px;
    height: 340px;
    ${Shimmer}
`;

export const SectionLoading = styled(PhotoLoading)`
    height: 140px;
    border-radius: 12px;
    ${Shimmer}
`;

interface SectionProps {
    last?: true;
    isSolid?: boolean;
}

const Section = styled.div<SectionProps>`
    border: ${props => !props.isSolid && `${colors.mediumTeal} solid 1px`};
    background: ${props => props.isSolid && `${colors.secondaryLightGray}`};
    border-radius: 8px;
    overflow: hidden;
    @media (max-width: ${maxWidthDesktop}px) {
        border: ${props => !props.isSolid && `none`};
        border-bottom: ${props =>
            !props.isSolid && !props.last && `${colors.mediumTeal} solid 1px`};
        margin: ${props => props.isSolid && `10px 10px 0`};
    }
`;

const PreviewWrapper = styled.div`
    @media (max-width: ${maxWidthDesktop}px) {
        margin: 16px 16px 0;
    }
`;

const DisableSection = styled.div<{ disable?: boolean; isPublic?: boolean }>`
    ${props =>
        props.disable &&
        `opacity: 0.6;
        pointer-events: none;
        cursor: default;`}

    ${props =>
        props.isPublic
            ? `
    pointer-events: none;
    z-index: 9999;
    cursor: default;`
            : ""}
`;

interface Props {
    openBuyId: number;
    isPublic?: boolean;
}

const OpenBuyDetails = ({ openBuyId, isPublic }: Props) => {
    useScrollToTop();
    const history = useHistory();
    const dispatch = useDispatch();
    const error = useSelector(selectDirectBuyErrorState)[openBuyId];
    const openBuy = useSelector(selectOpenBuysById)[openBuyId];
    const steps = useSelector(selectOpenBuyStepEntities);
    const [noAccessNotify, setNoAccessNotify] = useState(false);

    const yourSets = useSelector((state: GlobalState) =>
        selectYourSets(state, openBuyId)
    );
    const leaderboard = useSelector((state: GlobalState) =>
        getOpenBuySubmissionLeaderboard(state, openBuyId)
    );
    const loadDirectBuy = () => {
        dispatch(requestOpenBuyById(openBuyId, isPublic));
    };
    const inAdminOrMarketplace =
        window.location.pathname.includes("admin-tools") ||
        window.location.pathname.includes("marketplace");

    useEffect(() => {
        dispatch(fetchSubmissionSets(openBuyId));
    }, []);

    useEffect(() => {
        if (!openBuy) loadDirectBuy();
        else dispatch(fetchOpenBuyLeaderboard(openBuyId));
    }, [openBuyId]);
    const [updatedBuy, setUpdatedBuy] = useState<OpenBuy | null>(null);

    useEffect(() => {
        if (openBuy && !openBuy.viewedAt) {
            post(`/v1/openBuy/${openBuyId}/viewed`, {})
                .then(({ data }) => {
                    setUpdatedBuy(data.data);
                })
                .catch(() => {});
        }
    }, [openBuy]);

    const [instructionsExpanded, setInstructionsExpanded] = useState(true);
    const [viewSubmissions, setViewSubmissions] = useState<{
        create?: boolean;
    } | null>(null);

    const location = useLocation();
    const instructionsRef = useRef<HTMLDivElement>(null);
    const scrollToInstructions = (location.state as {
        scrollToInstructions: boolean;
    })?.scrollToInstructions;

    useEffect(() => {
        if (scrollToInstructions) {
            instructionsRef?.current?.scrollIntoView({
                behavior: "smooth"
            });
            history.replace(location.pathname, {});
        }
    }, [scrollToInstructions]);

    const navbar = inAdminOrMarketplace ? null : (
        <>
            <SimpleNavbar
                title="CHALLENGE"
                left={
                    <NavButton
                        back
                        onClick={() => {
                            if (updatedBuy) {
                                dispatch(updateOpenBuy(updatedBuy));
                            }
                            location.pathname.includes("submissions")
                                ? history.push(`/buys/open/${openBuyId}`)
                                : history.push("/");
                        }}
                    />
                }
            />
        </>
    );

    const getModal = () => {
        if (treatPublic && !noAccessNotify) {
            setNoAccessNotify(true);
            return null;
        } else if (treatPublic) return null;
    };

    const treatPublic = isPublic || openBuy?.isPublic;

    if (error) {
        let message;
        let reloadCallback;
        switch (error) {
            case DirectBuyError.generic:
                message = openBuy
                    ? "Failed to load challenge."
                    : "Failed to load offer.";
                reloadCallback = loadDirectBuy;
                break;
            case DirectBuyError.notFound:
                message = "No offer found.";
                break;
            case DirectBuyError.accessDenied:
                message = "You don't have permission to view this.";
        }
        return (
            <>
                {navbar}
                <DirectBuyDetailsError
                    message={message}
                    reloadCallback={reloadCallback}
                />
            </>
        );
    } else if (openBuy) {
        return (
            <>
                {navbar}

                <MainContainer>
                    <Sections>
                        <PreviewWrapper>
                            <OpenBuyPreview
                                openBuyId={openBuyId}
                                size={OpenBuyPreviewSize.large}
                            />
                        </PreviewWrapper>
                        <Section last>
                            <OpenBuyBriefAccordion
                                title="How it Works"
                                initiallyExpanded
                                description={openBuy.description}
                                linkUrl={openBuy.linkUrl}
                                linkDescription={openBuy.linkDescription}
                            />
                        </Section>
                        {!!leaderboard?.length && (
                            <Section last>
                                <OpenBuyLeaderboardAccordion
                                    leaderboard={leaderboard}
                                    title="Leaderboard"
                                    initiallyExpanded
                                    openBuyId={openBuy.id}
                                />
                            </Section>
                        )}

                        {!!treatPublic || (
                            <Section last>
                                <OpenBuyPaymentDetailsAccordion
                                    title="Payment Details"
                                    initiallyExpanded
                                    openBuyId={openBuy.id}
                                />
                            </Section>
                        )}

                        {openBuy.ruleSetId && (
                            <Section last>
                                <OpenBuyMediaRulesSection
                                    title="Challenge Do's and Don'ts"
                                    openBuy={openBuy}
                                    initiallyExpanded
                                />
                            </Section>
                        )}

                        <Section last>
                            <div ref={instructionsRef} />
                            <Switch>
                                <Route path="/buys/open/:openBuyId">
                                    <DisableSection
                                        disable={
                                            getStateForOpenBuy(openBuy) ===
                                            OpenBuyState.ended
                                        }
                                    >
                                        <DirectBuyDetailsSectionHeader
                                            title="Instructions"
                                            expanded={instructionsExpanded}
                                            setExpanded={
                                                setInstructionsExpanded
                                            }
                                        />
                                        <OpenBuyDetailsInstructions
                                            openBuy={openBuy}
                                            expanded={instructionsExpanded}
                                        />
                                    </DisableSection>
                                </Route>
                            </Switch>
                            {getModal()}
                        </Section>
                    </Sections>
                </MainContainer>
                {noAccessNotify && (
                    <NoAccessModal
                        isPublic={isPublic}
                        onClose={() => {
                            setNoAccessNotify(false);
                            dispatch(setOpenBuySubmissionStep(null));
                        }}
                    />
                )}
                <DirectBuyDetailsModals />
                {viewSubmissions && (
                    <OpenBuySubmissionScreenModal
                        openBuy={openBuy}
                        onClose={() => setViewSubmissions(null)}
                        treatPublic={treatPublic}
                        steps={steps}
                        createSet={viewSubmissions.create}
                    />
                )}
                <AnimatePresence>
                    <OpenBuyDetailsFooter
                        openBuyId={openBuy.id}
                        createSet={() =>
                            setViewSubmissions({
                                create: yourSets.length ? false : true
                            })
                        }
                    />
                </AnimatePresence>
            </>
        );
    } else {
        return (
            <>
                {navbar}

                <MainContainer style={{ height: "100vh" }}>
                    <PhotoLoading />
                    <SectionLoading />
                    <SectionLoading />
                    <SectionLoading style={{ height: "100%" }} />
                </MainContainer>
            </>
        );
    }
};

export default OpenBuyDetails;
