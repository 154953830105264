import { combineReducers } from "redux";
import assetsUIReducer, { AssetsUIState } from "./assets/reducer";
import authUIReducer, { AuthUIState } from "./auth/reducer";
import campaignsUIReducer, { CampaignsUIState } from "./campaigns/reducer";
import contestsUIReducer, { ContestsUIState } from "./contests/reducer";
import customLinksUIReducer, {
    CustomLinksUIState
} from "./custom-links/reducer";
import highlightsUIReducer, { HighlightsUIState } from "./highlights/reducer";
import layoutReducer, { LayoutUIState } from "./layout/reducer";
import mediasUIReducer, { MediasUIState } from "./medias/reducer";
import notificationsReducer, {
    NotificationsUIState
} from "./notifications/reducer";
import publisherOfferUIReducer, {
    PublisherOfferUIState
} from "./publisher-offers/reducer";
import scriptsUIReducer, { ScriptsUIState } from "./scripts/reducer";
import referralsUIReducer, { ReferralsUIState } from "./referrals/reducer";
import { ObserverReducer, ObserverUIState } from "./observer/reducers";
import flowsUIReducer, { FlowsUIState } from "./flows/reducer";
import directBuysUIReducer, { DirectBuysUIState } from "./direct-buys/reducer";
import shoutoutsUIReducer, { ShoutoutsUIState } from "./shoutouts/reducer";
import modalsUIReducer, { ModalsUIState } from "./modals/reducers";
import linkListUIReducer, { LinkListUIState } from "./link-lists/reducer";
import placementsUIReducer, { PlacementsUIState } from "./placements/reducer";
import promoteFlowUIReducer, {
    PromoteFlowUIState
} from "./promote-flow/reducer";
import bidBudgetUIReducer, { BidBudgetUIState } from "./bid-budget/reducer";
import chatUIReducer, { ChatUIState } from "./chat/reducer";
import profileUIReducer, { ProfileUIState } from "./profile/reducers";
import instructionsSetsUIReducer, {
    InstructionsSetsUIState
} from "./instructions-sets/reducers";
import socialAccountsUIReducer, {
    SocialAccountsUIState
} from "./social-accounts/reducers";
import elasticSearchUIReducer, { ElasticSearchUIState } from "./search/reducer";
import creatorSearchUIReducer, {
    CreatorGroupsUIState
} from "./creator-groups/reducers";
import creatorsUIReducer, { CreatorsUIState } from "./creators/reducers";

import marketplaceUIReducer, {
    MarketplaceUIState
} from "./marketplace/reducers";
import socialAccountProfileUIReducer, {
    SocialAccountProfileUIState
} from "./social-account-profile/reducers";
import createOfferWizardUIReducer, {
    CreateOfferWizardUIState
} from "./create-offer-wizard/reducers";
import adminToolsUIReducer, { AdminToolsUIState } from "./admin-tools/reducers";
import openBuyPaymentsUIReducer, {
    OpenBuyPaymentUIState
} from "../marketplace/payment-sets/open-buy-payments/reducer";
import scrollRestorationUIReducer, {
    ScrollRestorationUIState
} from "./scroll-restoration/reducer";

export interface UIState {
    auth: AuthUIState;
    campaign: CampaignsUIState;
    profile: ProfileUIState;
    script: ScriptsUIState;
    notification: NotificationsUIState;
    customLink: CustomLinksUIState;
    highlights: HighlightsUIState;
    contests: ContestsUIState;
    layout: LayoutUIState;
    medias: MediasUIState;
    assets: AssetsUIState;
    publisherOffer: PublisherOfferUIState;
    referrals: ReferralsUIState;
    observer: ObserverUIState;
    flows: FlowsUIState;
    directBuys: DirectBuysUIState;
    shoutouts: ShoutoutsUIState;
    modals: ModalsUIState;
    linkList: LinkListUIState;
    placements: PlacementsUIState;
    promote: PromoteFlowUIState;
    bidBudget: BidBudgetUIState;
    chat: ChatUIState;
    socialAccounts: SocialAccountsUIState;
    socialAccountProfile: SocialAccountProfileUIState;
    elasticSearch: ElasticSearchUIState;
    marketplace: MarketplaceUIState;
    instructionsSets: InstructionsSetsUIState;
    creatorGroups: CreatorGroupsUIState;
    creators: CreatorsUIState;
    createOfferWizard: CreateOfferWizardUIState;
    adminTools: AdminToolsUIState;
    openBuyPayments: OpenBuyPaymentUIState;
    scrollRestoration: ScrollRestorationUIState;
}
const uiReducer = combineReducers<UIState>({
    auth: authUIReducer,
    campaign: campaignsUIReducer,
    profile: profileUIReducer,
    script: scriptsUIReducer,
    notification: notificationsReducer,
    customLink: customLinksUIReducer,
    highlights: highlightsUIReducer,
    contests: contestsUIReducer,
    layout: layoutReducer,
    medias: mediasUIReducer,
    assets: assetsUIReducer,
    referrals: referralsUIReducer,
    observer: ObserverReducer,
    publisherOffer: publisherOfferUIReducer,
    directBuys: directBuysUIReducer,
    flows: flowsUIReducer,
    shoutouts: shoutoutsUIReducer,
    modals: modalsUIReducer,
    linkList: linkListUIReducer,
    placements: placementsUIReducer,
    promote: promoteFlowUIReducer,
    bidBudget: bidBudgetUIReducer,
    chat: chatUIReducer,
    socialAccounts: socialAccountsUIReducer,
    socialAccountProfile: socialAccountProfileUIReducer,
    elasticSearch: elasticSearchUIReducer,
    marketplace: marketplaceUIReducer,
    instructionsSets: instructionsSetsUIReducer,
    creatorGroups: creatorSearchUIReducer,
    creators: creatorsUIReducer,
    createOfferWizard: createOfferWizardUIReducer,
    adminTools: adminToolsUIReducer,
    openBuyPayments: openBuyPaymentsUIReducer,
    scrollRestoration: scrollRestorationUIReducer
});

export default uiReducer;
