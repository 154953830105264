import { schema } from "normalizr";

const conversationUnreadCount = new schema.Entity(
    "conversations",
    {},
    {
        idAttribute: "conversationId",
        processStrategy: (value, parent, key) => {
            return { id: value.conversationId, unreadCount: { ...value } };
        }
    }
);

export const conversationUnreadCountList = new schema.Array(
    conversationUnreadCount
);

const conversationRequest = new schema.Entity(
    "conversations",
    {},
    {
        idAttribute: "conversationId",
        processStrategy: (value, parent, key) => {
            return { id: value.conversationId, request: { ...value } };
        }
    }
);

export const conversationRequestList = new schema.Array(conversationRequest);

const conversation = new schema.Entity(
    "conversations",
    {},
    {
        processStrategy: (value, parent, key) => {
            try {
                const userId = localStorage.getItem("userid");
                const otherId =
                    value.participantIds.length === 2 &&
                    value.participantIds.find((id: string) => id !== userId);
                return { ...value, ...(otherId && { otherId }) };
            } catch (error) {
                console.log(error);
            }
        }
    }
);
export const conversationList = new schema.Array(conversation);

export const chatProfile = new schema.Entity("chatProfiles");
export const chatProfileList = new schema.Array(chatProfile);

const message = new schema.Entity("messages");
export const messageList = new schema.Array(message);

const groupUserSetting = new schema.Entity(
    "groupUserSettings",
    {},
    {
        idAttribute: "userId"
    }
);
export const groupUserSettingList = new schema.Array(groupUserSetting);
