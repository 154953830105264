import React from "react";
import { colors } from "src/constants";
import { body } from "src/utils/styles/snippets";
import styled from "styled-components";
import RichTextPreview from "./RichTextPreview";
import { getSubmissionHistoryReason, getSubmissionHistoryBodyType } from "./utils";

const BodyText = styled.div`
    text-align: left;
    gap: 6px;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
`;
const Section = styled.div`
    overflow-wrap: anywhere;
`;

const ScraperSection = styled.div`
    display: flex;
    overflow-wrap: anywhere;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    background-color: ${colors.teritaryLightGray};
    gap: 10px;
`;
const ScraperRow = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    width: 100%;
    padding: 8px 12px;
`;

const Title = styled.h1<{ margin?: number }>`
    color: ${colors.gray2};
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    margin: ${props => props.margin || 0}px;
`;

const VideoLink = styled.a``;

const ScraperSectionTitle = styled.div`
    font-weight: 500;
`;

const SubmissionHistoryBody = ({
    event,
}: {
    event: any;
}) => {
    const bodyType = getSubmissionHistoryBodyType(event.eventName);
    const reason = getSubmissionHistoryReason(event.eventName, event.updateObject);
    const updateObject: any = event.updateObject;

    return (
        <BodyText>
            {bodyType === "admin" && (
                <>
                    {
                        <Section>
                            <b> Reason: </b>
                            {reason || "No reason provided"}
                        </Section>
                    }
                    {updateObject.mediaQuality && (
                        <Section>
                            <b>Media Quality Score: </b>{" "}
                            {updateObject.mediaQuality} / 5
                        </Section>
                    )}
                    {updateObject.media && (
                        <Section>
                            <b>Media Submission: </b>
                            <a
                                href={updateObject.media.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                view video
                            </a>
                        </Section>
                    )}
                    {updateObject.linkSubmission && (
                        <Section>
                            <b>Link Submission: </b>{" "}
                            <a
                                href={updateObject.linkSubmission}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                view link
                            </a>
                        </Section>
                    )}
                    <Section>
                        <b>Spark Submission: </b>
                        {updateObject.textSubmission}
                    </Section>
                    <Section>
                        <b>Social Account ID: </b>
                        {updateObject.socialAccountId}
                    </Section>
                    <Section>
                        <b>Views: </b>
                        {updateObject.viewsActual}
                    </Section>
                </>
            )}
            {bodyType === "scraper" && (
                <ScraperSection>
                    <Title>SCRAPER DATA</Title>
                    <ScraperRow>
                        <ScraperSectionTitle>Views</ScraperSectionTitle>
                        {updateObject.views}
                    </ScraperRow>
                    <ScraperRow>
                        <ScraperSectionTitle>Likes</ScraperSectionTitle>
                        {updateObject.likes}
                    </ScraperRow>
                    <ScraperRow>
                        <ScraperSectionTitle>Comments</ScraperSectionTitle>
                        {updateObject.totalComments}
                    </ScraperRow>
                </ScraperSection>
            )}
            {bodyType === "advertiser" && (
                <Section>
                    <b>Message:</b>
                    <RichTextPreview text={reason} />
                </Section>
            )}
        </BodyText>
    );
};
export default SubmissionHistoryBody;
