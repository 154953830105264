import React, { useRef, useState } from "react";
import styled from "styled-components";
import ApprovalChecklist from "./ApprovalChecklist";
import { OpenBuy, OpenBuySubmission } from "src/buys/modules/open-buys/types";
import ApprovalBtns from "./ApprovalBtns";
import ApprovalModal from "./ApprovalModal";
import MediaScoreModal from "./MediaScoreModal";
import AdvertiserReviewRequestModal from "./AdvertiserReviewRequestModal";
import { getSubmissionTypeHierarchy } from "src/admin-tools/challenges/util";
import Divider from "src/components/Divider";
import MarketplaceTextarea from "src/marketplace/components/MarketplaceTextarea";
import { colors } from "src/constants";
import Rating from "react-rating";
import { useDispatch, useSelector } from "react-redux";
import { setPillNotificationText } from "src/ui/notifications/actions";
import Toggler from "src/components/Toggler";
import RichTextEditor, { RichTextEditorRef } from "./RichTextEditor";
import { fetchSubmissionSet } from "src/buys/actions";
import { applyApprovalTemplate } from "./approvalConstants";
import { GlobalState } from "src/reducers";
import {
    SUBMISSION_MANAGER_ACCORDIONS,
    setSubmissionManagerAccordion
} from "src/ui/admin-tools/actions";
import withAccordion from "src/campaigns/details/accordions/withAccordion";
import { BlueButton } from "src/profile/components/Buttons";
import { Editor } from "slate";
import CustomEditor from "src/components/text-editor/CustomEditor";

const Main = styled.div`
    display: flex;
    flex-direction: column;
`;
const MessageWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    grid-template-areas:
        "title input1"
        "subtitle input2";
    padding-top: 24px;
    isolation: isolate;
`;

const MessageTitle = styled.div<{ red?: boolean }>`
    display: flex;
    grid-area: title;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    color: ${p => (p.red ? colors.primaryRed : colors.black)};
`;
const StyledRating = styled(Rating)`
    grid-area: input1 / input1 / input2 / input2;
    align-self: center;
`;

const MessageSubtitle = styled.div<{ red?: boolean }>`
    grid-area: subtitle;
    text-align: left;
    font-size: 12px;
    color: ${p => (p.red ? colors.primaryRed : colors.gray2)};
`;

const StyledMarketplaceTextarea = styled(MarketplaceTextarea)`
    margin: 12px 0px 24px;
`;

const StyledToggler = styled(Toggler)`
    grid-area: input1 / input1 / input2 / input2;
`;
const Star = styled.img`
    width: 20px;
`;

const ApprovalChecklistAccordion = withAccordion(ApprovalChecklist);

const MessageTabs = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
`;
const TabRow = styled.div`
    display: flex;
    width: 100%;
`;
const Tab = styled.div<{ selected?: boolean }>`
    border-bottom: 2px solid ${colors.transparent};
    border-bottom-color: ${props =>
        props.selected ? colors.black : colors.transparent};
    color: ${props => (props.selected ? colors.black : colors.primaryGray)};
    font-weight: ${props => (props.selected ? "bold" : "normal")};
    font-size: 0.8rem;
    cursor: pointer;
    text-align: center;
    padding: 8px 16px;
`;
const MessageTab = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
`;
const AdvertiserReviewRequestButton = styled(BlueButton)`
    height: 32px;
    margin-top: 12px;
    margin-left: auto;
`;

interface Props {
    submission: OpenBuySubmission;
    publisherName: string;
    openBuy: OpenBuy;
}

const ApprovalColumn = ({ submission, openBuy, publisherName }: Props) => {
    const dispatch = useDispatch();
    const [mediaQuality, setMediaQuality] = useState(0);
    const [error, setError] = useState(false);
    const [sendAsMessage, setSendAsMessage] = useState(false);
    const [approval, setApproval] = useState<
        | "approve"
        | "reject"
        | "revisionRequest"
        | "scoreMedia"
        | "advertiserReviewRequest"
        | null
    >(null);
    const [loading, setLoading] = useState(false);
    const [publisherMessage, setPublisherMessage] = useState("");
    const [advertiserReviewRequest, setAdvertiserReviewRequest] = useState("");
    const [rulesMessage, setRulesMessage] = useState("");
    const [messageTab, setMessageTab] = useState("publisher");

    const richTextEditorRef = useRef<RichTextEditorRef>(null);

    const handleAddRule = (rule: string) => {
        richTextEditorRef.current?.controlEditor((editor: Editor) => {
            CustomEditor.insertRule(editor, rule);
        });
    };

    const getSubmission = () => {
        setApproval(null);
        if (submission.openBuySubmissionSetId)
            dispatch(fetchSubmissionSet(submission.openBuySubmissionSetId));
    };
    const markdownFormat = true;

    const gatherMediaQualityScore =
        getSubmissionTypeHierarchy(submission) === "media";

    const validateInputs = () => {
        if (gatherMediaQualityScore && mediaQuality < 0.5) {
            setError(true);
            dispatch(
                setPillNotificationText(
                    "Please enter a Media Quality Score",
                    "danger",
                    3500
                )
            );
            return false;
        } else return true;
    };

    const activeAccordions = useSelector(
        (state: GlobalState) =>
            state.ui.adminTools.submissionManager.activeAccordions
    );

    const onToggle = (accordion: SUBMISSION_MANAGER_ACCORDIONS) => {
        if (activeAccordions.includes(accordion))
            dispatch(
                setSubmissionManagerAccordion(
                    activeAccordions.filter(acc => acc !== accordion)
                )
            );
        else
            dispatch(
                setSubmissionManagerAccordion(
                    activeAccordions.concat([accordion])
                )
            );
    };

    return (
        <Main>
            <ApprovalChecklistAccordion
                title={"Revisions Checklist"}
                onToggle={v =>
                    onToggle(SUBMISSION_MANAGER_ACCORDIONS.approvalChecklist)
                }
                manualControl={activeAccordions.includes(
                    SUBMISSION_MANAGER_ACCORDIONS.approvalChecklist
                )}
                initiallyExpanded={true}
                onMessage={(text: string) => {
                    //always send as message if we have rules text summarized
                    setSendAsMessage(!!text);
                    setRulesMessage(text);
                }}
                ruleSetId={openBuy.ruleSetId}
                handleAddRule={handleAddRule}
            />
            <Divider />
            <MessageTabs>
                <TabRow>
                    <Tab
                        selected={messageTab === "publisher"}
                        onClick={() => setMessageTab("publisher")}
                    >
                        MESSAGE PUBLISHER
                    </Tab>
                    <Tab
                        selected={messageTab === "advertiser"}
                        onClick={() => setMessageTab("advertiser")}
                    >
                        MESSAGE ADVERTISER
                    </Tab>
                </TabRow>
                {messageTab === "publisher" && (
                    <MessageTab>
                        {markdownFormat ? (
                            <RichTextEditor
                                ref={richTextEditorRef}
                                message={publisherMessage}
                                setMessage={setPublisherMessage}
                                placeholder="Publisher message"
                            />
                        ) : (
                            <StyledMarketplaceTextarea
                                value={publisherMessage}
                                handleChange={e =>
                                    setPublisherMessage(e.currentTarget.value)
                                }
                                placeholder="Input your Approval Message"
                            />
                        )}

                        {gatherMediaQualityScore && (
                            <MessageWrap>
                                <MessageTitle red={error && mediaQuality < 0.5}>
                                    Media Quality Score
                                </MessageTitle>
                                <MessageSubtitle
                                    red={error && mediaQuality < 1}
                                >
                                    Give this media a 0.5-5 star rating
                                </MessageSubtitle>

                                <StyledRating
                                    fractions={2}
                                    onChange={setMediaQuality}
                                    initialRating={mediaQuality}
                                    emptySymbol={<Star src="/star-gray.svg" />}
                                    fullSymbol={<Star src="/star-yellow.svg" />}
                                />
                            </MessageWrap>
                        )}
                        <MessageWrap>
                            <MessageTitle>Send as Message</MessageTitle>
                            <MessageSubtitle>
                                Send the{" "}
                                {approval === "approve"
                                    ? "approval"
                                    : "rejection"}{" "}
                                reason as an Intercom message
                            </MessageSubtitle>
                            <StyledToggler
                                isActive={sendAsMessage}
                                onSelect={() =>
                                    setSendAsMessage(!sendAsMessage)
                                }
                                onDeselect={() =>
                                    setSendAsMessage(!sendAsMessage)
                                }
                            />
                        </MessageWrap>
                        <ApprovalBtns
                            setModal={(type: any) => {
                                if (validateInputs()) setApproval(type);
                            }}
                            submissionType={getSubmissionTypeHierarchy(
                                submission
                            )}
                            status={submission.status}
                        />
                        {(approval === "approve" ||
                            approval === "reject" ||
                            approval === "revisionRequest") && (
                            <ApprovalModal
                                getSubmission={getSubmission}
                                sendAsMessage={sendAsMessage}
                                mediaQuality={mediaQuality}
                                message={applyApprovalTemplate(
                                    rulesMessage + `\n` + publisherMessage,
                                    publisherName,
                                    openBuy.name,
                                    approval
                                )}
                                submission={submission}
                                handleClose={() => setApproval(null)}
                                approval={approval}
                            />
                        )}
                        {approval === "scoreMedia" && (
                            <MediaScoreModal
                                getSubmission={getSubmission}
                                submission={submission}
                                handleClose={() => setApproval(null)}
                            />
                        )}
                    </MessageTab>
                )}
                {messageTab === "advertiser" && (
                    <MessageTab>
                        <RichTextEditor
                            ref={richTextEditorRef}
                            message={advertiserReviewRequest}
                            setMessage={setAdvertiserReviewRequest}
                            placeholder="Add details for your review request to advertisers"
                        />
                        <AdvertiserReviewRequestButton
                            onClick={() =>
                                setApproval("advertiserReviewRequest")
                            }
                        >
                            Request Review
                        </AdvertiserReviewRequestButton>
                        {approval === "advertiserReviewRequest" && (
                            <AdvertiserReviewRequestModal
                                message={advertiserReviewRequest}
                                submission={submission}
                                getSubmission={getSubmission}
                                handleClose={() => setApproval(null)}
                            />
                        )}
                    </MessageTab>
                )}
            </MessageTabs>
        </Main>
    );
};

export default ApprovalColumn;
