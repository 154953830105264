import React from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { isScriptModalOpen } from "src/campaigns/scripts/selectors";
import { breakpoints, maxWidth } from "src/constants";
import styled from "styled-components";
import CarouselMedia from "./CarouselMedia";

const Main = styled.div`
    & img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
`;

const Bold = styled.span`
    font-weight: 600;
`;

const CampaignHeader = styled.div`
    display: flex;
    padding: 20px 0;
    width: ${maxWidth.mediaCarousel}px;

    @media (max-width: ${breakpoints.mobile}px) {
        width: 100%;
    }
    `;

const AppIcon = styled.img`
    height: 60px;
    width: 60px;
    min-width: 60px;
    border-radius: 18px;
`;

const AppInfo = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
`;

const HideOverflow = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
`;

const CloseIcon = styled.img`
    margin-left: auto;
    padding: 15px;
    cursor: pointer;

    &:active {
        opacity: 0.5;
    }
`;

const MediaContainer = styled.div`
    height: calc(85vh - 230px);
    max-height: 800px;
    width: 80vw;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface Props {
    appIconUrl: string;
    campaignName: string;
    amountComponent: any;
    mediaUrl: string;
    mediaType: string;
    isPortrait: boolean;
    closeModal: () => void;
}

const CarouselInfo = ({
    appIconUrl,
    campaignName,
    amountComponent,
    mediaUrl,
    mediaType,
    isPortrait,
    closeModal
}: Props) => {
    const { ref, inView } = useInView({ threshold: 0 });
    const isScriptModal = useSelector(isScriptModalOpen);
    const isCampaignDetails = window.location.href.includes("campaign/");

    return (
        <Main>
            <CampaignHeader>
                <AppIcon src={appIconUrl} />
                <AppInfo>
                    <HideOverflow style={{ fontSize: "19px" }}>
                        <Bold>{campaignName}</Bold>
                    </HideOverflow>
                    <HideOverflow>
                        {isScriptModal && !isCampaignDetails
                            ? null
                            : amountComponent}
                    </HideOverflow>
                </AppInfo>
                <CloseIcon
                    src={"/white_close.svg"}
                    onClick={e => {
                        closeModal();
                    }}
                    onMouseDown={e => {
                        e.stopPropagation();
                    }}
                />
            </CampaignHeader>
            <MediaContainer ref={ref}>
                {inView && (
                    <CarouselMedia
                        mediaUrl={mediaUrl}
                        mediaType={mediaType}
                        isPortrait={isPortrait}
                    />
                )}
            </MediaContainer>
        </Main>
    );
};

export default CarouselInfo;
