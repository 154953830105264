import React, { useEffect, useState } from "react";
import LinkListItemList from "./LinkListItemList";
import { deleteLinkList, editLinkList } from "../actions";
import CopyToClipboard from "react-copy-to-clipboard";
import { addNotification } from "src/ui/notifications/actions";
import SpinLoader from "src/components/SpinLoader";
import styled from "styled-components";
import NavButton from "src/components/NavButton";
import SimpleNavbar from "src/components/SimpleNavbar";
import { colors, fonts } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import { goBack } from "connected-react-router";
import { setLinkListError } from "src/ui/link-lists/actions";
import { getLinkListError } from "../selectors";
import { GlobalState } from "src/reducers";
import { LinkList } from "src/profile/types";
import { storage } from "src/firebase/FirebaseConfig";
import { makeId } from "src/util";
import { post } from "src/Api";
import LinkListDeleteModal from "./LinkListDeleteModal";
import { CopyButton, OutlineButton } from "src/profile/components/Buttons";

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    padding: 18px 0 18px 0;
    gap: 13px;
    width: calc(100% - 36px);
    max-width: 400px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    margin: auto;
`;

const Button = styled(OutlineButton)`
    padding: 20px;
    box-sizing: border-box;
    height: 48px;
    width: 100%;
`;

const Copy = styled(CopyButton)`
    width: 100%;
    height: 48px;
`;

const LinkWrapper = styled.a`
    outline: none;
    text-decoration: none;
    border: none;
    width: 100%;
    &:hover {
        text-decoration: none;
    }
`;

const PictureContainer = styled.div`
    border-radius: 100px;
    margin-top: 20px;
    margin-bottom: 5px;
    width: 104px;
    height: 104px;
    background: ${colors.lightGray};
    justify-content: center;
    align-items: center;
    display: flex;
`;
const Picture = styled.img`
    border-radius: 100px;
    width: 104px;
    height: 104px;
    object-fit: cover;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 10px;
    max-width: 400px;
    width: calc(100% - 36px);
    margin: 10px 18px 0 18px;
`;

const Link = styled.div`
    display: flex;
    flex-direction: row;
    font-size: ${fonts.subtitle}px;
    padding: 10px 20px 10px 20px;
    color: ${colors.secondaryGray};
`;

const Name = styled.div`
    border-bottom: 1px solid ${colors.mediumTeal};
    border-top: 1px solid ${colors.mediumTeal};
    font-size: ${fonts.subtitle}px;
    padding: 10px 20px 10px 20px;
`;

const PlainInput = styled.input`
    border: none;
    background: none;
    width: 100%;
    outline: none;
    &::placeholder {
        color: ${colors.secondaryGray};
    }
`;

const Bio = styled.div`
    padding: 10px;
    font-size: ${fonts.subtitle}px;
    padding: 10px 20px 10px 20px;
`;

const InputContainer = styled.label`
    color: ${colors.primaryBlue};
    font-size: ${fonts.subtitle}px;
    cursor: pointer;
    margin-top: 8px;
`;

interface Props {
    linkList: LinkList;
    list: LinkList;
}

const LinkListEdit = ({ linkList, list }: Props) => {
    const dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);
    const [linkName, setLinkName] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [deleting, setDeleting] = useState(false);

    const error = useSelector((state: GlobalState) =>
        getLinkListError(state, "LinkListEdit")
    );

    useEffect(() => {
        if (linkList) {
            setLinkName(linkList.name);
            setTitle(linkList.title || "");
            setDescription(linkList.description || "");
        }
    }, [linkList]);

    const fileUpload = (e: any) => {
        e.preventDefault();
        const allowedTypes = ["image/jpeg", "image/png"];
        const file = e.currentTarget.files[0];

        if (file && allowedTypes.includes(file.type)) {
            let type = file.type.split("/")[1];

            if (type === "quicktime") {
                type = "mov";
            }

            const id = `${localStorage.getItem("userid")}_${makeId(50)}.${
                file.type.split("/")[1]
            }`;
            const ref = storage.ref().child("linkListAssets/" + id);

            setUploading(true);

            ref.put(file)
                .then(async () => {
                    try {
                        const fileRes = await post("/v1/linkList/photoUrl", {
                            filename: id
                        });

                        if (fileRes.data.data) {
                            dispatch(
                                editLinkList({
                                    name: "photoUrl",
                                    value: fileRes.data.data,
                                    id: list?.id || ""
                                })
                            );
                        }
                        setUploading(false);
                    } catch (err) {
                        setUploading(false);
                    }
                })
                .catch((error: string) => {
                    window.alert("Something went wrong. Please Refresh.");
                });
        }
    };

    return (
        <>
            <SimpleNavbar
                title={"Link List"}
                left={
                    <NavButton
                        back
                        onClick={() => {
                            dispatch(setLinkListError(null));
                            dispatch(goBack());
                        }}
                    />
                }
                right={
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setDeleting(true)}
                    >
                        <img src="/ellipsis-button.svg" alt="" />
                    </div>
                }
            />
            <Container>
                <PictureContainer>
                    {uploading ? (
                        <SpinLoader size={35} color={colors.primaryBlue} />
                    ) : (
                        <Picture src={list?.photoUrl} alt="" />
                    )}
                </PictureContainer>
                <InputContainer>
                    <input
                        type={"file"}
                        accept={"image/png, image/jpeg"}
                        style={{ display: "none" }}
                        onChange={fileUpload}
                    />
                    Change
                </InputContainer>
                <InfoContainer>
                    <Link>
                        theplug.co/list@
                        <PlainInput
                            value={linkName}
                            onChange={(e: any) => setLinkName(e.target.value)}
                            type="text"
                            onBlur={(e: any) => {
                                dispatch(
                                    editLinkList({
                                        name: "name",
                                        value: e.target.value,
                                        id: list.id
                                    })
                                );
                            }}
                        />
                    </Link>
                    <Name>
                        <PlainInput
                            placeholder="Add a name"
                            value={title}
                            onChange={(e: any) => setTitle(e.target.value)}
                            type="text"
                            onBlur={(e: any) => {
                                dispatch(
                                    editLinkList({
                                        name: "title",
                                        value: e.target.value,
                                        id: list.id
                                    })
                                );
                            }}
                        />
                    </Name>
                    <Bio>
                        <PlainInput
                            placeholder="Add a description"
                            value={description}
                            onChange={(e: any) =>
                                setDescription(e.target.value)
                            }
                            type="text"
                            onBlur={(e: any) => {
                                dispatch(
                                    editLinkList({
                                        name: "description",
                                        value: e.target.value,
                                        id: list.id
                                    })
                                );
                            }}
                        />
                    </Bio>
                </InfoContainer>
                <ButtonRow>
                    <LinkWrapper href={linkList?.listUrl} target="#">
                        <Button>Preview</Button>
                    </LinkWrapper>
                    <CopyToClipboard
                        text={linkList?.listUrl}
                        onCopy={() => {
                            dispatch(addNotification("Copied link list!"));
                        }}
                    >
                        <Copy>Copy Link</Copy>
                    </CopyToClipboard>
                </ButtonRow>
                <LinkListItemList list={list.id} />
            </Container>
            <LinkListDeleteModal
                open={deleting}
                type="Link List"
                onClose={() => setDeleting(false)}
                onDelete={() => {
                    setDeleting(false);
                    dispatch(deleteLinkList(list.id));
                }}
            />
        </>
    );
};

export default LinkListEdit;
