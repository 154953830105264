import { compareAsc, fromUnixTime } from "date-fns";
import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import { getAuthState } from "src/auth/selectors";
import { Connection, PublicProfile } from "src/profile/types";
import { GlobalState } from "src/reducers";
import { AddMemberParams } from "./group-user-settings/actions";

const conversationsEntitiesState = (state: GlobalState) =>
    state.entities.conversations;

export const selectConversationWithId = (
    state: GlobalState,
    conversationId: string
) => state.entities.conversations.byId[conversationId];

const messagesEntitiesState = (state: GlobalState) => state.entities.messages;

const chatProfileState = (state: GlobalState) => state.entities.chatProfiles;

export const selectChatProfileById = (state: GlobalState, userId: string) =>
    chatProfileState(state)[userId];

export const selectOwnChatProfile = (state: GlobalState) => {
    const { userId } = getAuthState(state);
    if (userId) return chatProfileState(state)[userId];

    return null;
};

export interface ConnectionsEntitiesState {
    [key: string]: PublicProfile;
}

export const selectFlattenedConnections = (state: GlobalState) => {
    const connectionEntities: ConnectionsEntitiesState = {};
    const connections = state.profile.connections?.filter(
        connection => connection.state === "friend"
    );

    connections?.forEach(connection => {
        connectionEntities[connection.other.id] = connection.other;
    });
    return connectionEntities;
};

export const getSuggestedChatUsers = createSelector(
    (state: GlobalState) => state.profile.connections,
    (
        state: GlobalState,
        searchString: string,
        filterOutUserIds?: string[]
    ) => ({ searchString, filterOutUserIds }),
    (connections, { searchString, filterOutUserIds }) => {
        if (!connections) return [];

        const filteredConnections = connections.filter(
            connection =>
                !filterOutUserIds ||
                !filterOutUserIds.includes(connection.other.id.slice(2))
        );

        const friends = filteredConnections.filter(connection => {
            if (searchString) {
                return (
                    connection.state === "friend" &&
                    connection.other.profile.username
                        ?.toLowerCase()
                        .includes(searchString.toLowerCase())
                );
            } else {
                return connection.state === "friend";
            }
        });

        return friends.map(connection => connection.other);
    }
);

const selectGroupUserSettings = (state: GlobalState) =>
    state.entities.groupUserSettings;

export const getConversations = createCachedSelector(
    conversationsEntitiesState,
    getAuthState,
    chatProfileState,
    selectGroupUserSettings,
    selectFlattenedConnections,
    (
        state: GlobalState,
        isInbox: boolean,
        searchString: string,
        limit: number
    ) => ({
        searchString,
        isInbox,
        limit
    }),
    (
        state,
        auth,
        chatProfileState,
        groupUserSettings,
        connections,
        { searchString, isInbox, limit }
    ) => {
        const rawConversations = state.allIds.map(conversationId => {
            const conversation = state.byId[conversationId];
            const otherParticipant =
                conversation.participantIds.length === 2 &&
                conversation.participantIds.find(id => id !== `${auth.userId}`);

            if (otherParticipant && chatProfileState[otherParticipant]) {
                return {
                    displayName: chatProfileState[otherParticipant].username,
                    imageUrl: chatProfileState[otherParticipant].avatarUrl,
                    otherId: otherParticipant,
                    ...conversation,
                    isConnection: !!connections[`p:${otherParticipant}` || ""],
                    blocked:
                        groupUserSettings[conversation.id]?.[otherParticipant]
                            ?.blocked
                };
            }
            return { imageUrl: "/group-chat-default.svg", ...conversation };
        });

        rawConversations.sort((a, b) => {
            if (b.blocked) return -1;
            if (a.blocked) return 1;
            return (a.lastMessageSentAt || 0) < (b.lastMessageSentAt || 0)
                ? 1
                : -1;
        });

        const acceptedConversations = rawConversations.filter(conversation => {
            if (conversation.request && conversation.request.deleted) {
                return false;
            }
            if (!conversation.lastMessage) return false;
            if (
                groupUserSettings[conversation.id]?.[`${auth.userId}`]?.isBanned
            )
                return false;
            return true;
        });

        const searchResults = acceptedConversations.filter(conversation => {
            if (!searchString) {
                return true;
            }

            return (
                conversation.displayName &&
                conversation.displayName
                    .toLowerCase()
                    .includes(searchString.toLowerCase())
            );
        });

        return searchResults
            .filter(conversation => {
                const isRequest =
                    conversation.request &&
                    !conversation.request.deleted &&
                    !conversation.request.accepted;
                if (!isInbox) {
                    if (isRequest) {
                        return true;
                    }
                } else {
                    if (!isRequest) return true;
                }
            })
            .slice(0, limit);
    }
)((state, isInbox) => (isInbox ? "1" : "0"));

export const getChatRoomInfo = (state: GlobalState, conversationId: string) => {
    const auth = getAuthState(state);
    const conversation = state.entities.conversations.byId[conversationId];
    if (!conversation) return null;

    const chatProfileEntities = chatProfileState(state);
    if (conversation.displayName) {
        return {
            imageUrl: conversation.imageUrl || "/group-chat-default.svg",
            displayName: conversation.displayName,
            isGroupChat: true,
            groupDescription: conversation.groupDescription,
            usernames: conversation.participantIds.map(
                pId => chatProfileEntities[pId]?.username
            )
        };
    }

    const otherParticipant =
        conversation.participantIds?.length === 2 &&
        conversation.participantIds.find(id => id !== `${auth.userId}`);

    if (otherParticipant) {
        const chatProfile = chatProfileState(state)[otherParticipant];
        return {
            imageUrl: chatProfile?.avatarUrl,
            displayName: chatProfile?.username,
            isGroupChat: false,
            usernames: [chatProfile?.username],
            userId: chatProfile?.id
        };
    }
};

export const selectProfilePhoto = (state: GlobalState, publisherId: string) => {
    const profile = chatProfileState(state)[publisherId];
    return { avatarUrl: profile?.avatarUrl, username: profile?.username };
};

export const getMessages = createSelector(
    getAuthState,
    conversationsEntitiesState,
    messagesEntitiesState,
    selectGroupUserSettings,
    (state: GlobalState, conversationId: string) => conversationId,
    (
        { userId },
        conversationsEntitiesState,
        messagesEntitiesState,
        groupUserSettings,
        conversationId
    ) => {
        const conversation = conversationsEntitiesState.byId[conversationId];
        if (!conversation?.messages) return;

        const otherId = conversation.otherId || "";
        const blocked =
            groupUserSettings[conversationId]?.[otherId]?.blocked || 0;

        let messages = conversation.messages.map(
            messageId => messagesEntitiesState[messageId]
        );

        const index = messages.findIndex(
            message => blocked !== 0 && blocked < message.created
        );

        if (index !== -1) {
            messages = messages.slice(0, index);
        }

        const myLastMessage = messages
            .map(message => message.authorId === `${userId}`)
            .lastIndexOf(true);

        messages[myLastMessage] = {
            ...messages[myLastMessage],
            myLastMessage: true
        };

        return messages;
    }
);

export const selectGroupSettings = (
    state: GlobalState,
    conversationId: string
) => state.entities.groupUserSettings[conversationId];

export const selectRequestStateWithId = (
    state: GlobalState,
    conversationId: string
) => {
    const conversation = selectConversationWithId(state, conversationId);
    if (
        conversation.request &&
        !conversation.request.deleted &&
        !conversation.request.accepted
    ) {
        return conversation.request;
    }
    return null;
};

export const selectPlugProfileForChat = (
    state: GlobalState
): AddMemberParams | null => {
    const profile = state.profile.profile;
    if (profile) {
        return {
            publisherId: `${profile.id}`,
            username: profile.username || "no-username-placeholder",
            avatarUrl: profile.profile_photo_url
        };
    }
    return null;
};

export const getTotalUnreadCount = createCachedSelector(
    selectOwnChatProfile,
    conversationsEntitiesState,
    (state: GlobalState, isInbox: boolean | undefined) => isInbox,
    (chatProfile, conversationEntities, isInbox) => {
        let unreadCount = 0;
        if (chatProfile) {
            const nonPausedConversationIds = chatProfile.conversations
                .filter(conversation => {
                    const pausedUntil = conversation.notificationsPausedUntil;
                    return (
                        !(
                            pausedUntil &&
                            compareAsc(fromUnixTime(pausedUntil), new Date()) >
                                0
                        ) && !conversation.notificationsPausedIndefinitely
                    );
                })
                .map(conversation => conversation.conversationId);

            const conversations = nonPausedConversationIds.map(
                conversationId => conversationEntities.byId[conversationId]
            );

            const filteredConversations = conversations.filter(conversation => {
                if (isInbox === true) {
                    return (
                        !conversation?.request ||
                        (conversation?.request &&
                            !conversation?.request.deleted &&
                            conversation?.request.accepted)
                    );
                } else if (isInbox === false) {
                    return (
                        conversation?.request &&
                        !conversation?.request.deleted &&
                        !conversation?.request.accepted
                    );
                }
                return true;
            });

            if (!filteredConversations) console.log(filteredConversations);

            filteredConversations.forEach(conversation => {
                // if (!conversation) {
                //     debugger;
                // }
                if (conversation?.unreadCount?.unreadCount) {
                    unreadCount += conversation.unreadCount?.unreadCount;
                }
            });
        }
        return unreadCount;
    }
)((state, isInbox) => (isInbox ? "1" : "0"));

export const isMemberOfInternalNotes = (
    state: GlobalState,
    conversationId: string
) => {
    const { userId } = getAuthState(state);
    if (!userId) return;
    const participantIds =
        state.entities.conversations.byId[conversationId].participantIds;
    return participantIds.includes(`${userId}`);
};
