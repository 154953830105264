import { combineReducers } from "redux";
import merge from "lodash.merge";
import { Campaign, CampaignMedia } from "./types";
import { Action, NormalizedAction } from "../types";
import {
    FETCH_CAMPAIGN,
    FETCH_CAMPAIGNS,
    FETCH_CAMPAIGN_ACCESS,
    FETCH_CAMPAIGN_BUDGET,
    FETCH_CAMPAIGN_INSTALL_HISTORY,
    FETCH_CAMPAIGN_METRICS,
    FETCH_CAMPAIGN_PRICE,
    FETCH_CAMPAIGN_STATISTICS
} from "./actions";
import { FETCH_CAMPAIGN_MEDIA_RULES } from "./media-rules/actions";
import {
    FETCH_CAMPAIGN_SCRIPT,
    FETCH_CAMPAIGN_SCRIPTS,
    SCRIPT_CREATE
} from "./scripts/actions";
import { FETCH_CAMPAIGN_HIGHLIGHTS } from "./highlights/actions";
import { FETCH_CAMPAIGN_ASSET, FETCH_CAMPAIGN_ASSETS } from "./assets/actions";
import {
    FETCH_CAMPAIGN_MEDIA,
    FETCH_CAMPAIGN_MEDIAS,
    FETCH_CAMPAIGN_PUBLIC_MEDIAS
} from "./medias/actions";
import { FETCH_CAMPAIGN_CAPTIONS } from "./captions/actions";
import {
    CREATE_CAMPAIGN_CUSTOM_LINK,
    FETCH_CAMPAIGN_CUSTOM_LINKS
} from "./custom-links/actions";
import { DELETE_MEDIA } from "src/media/actions";

interface ById {
    [id: number]: Campaign;
}

export interface CampaignsEntitiesState {
    byId: ById;
    allIds: number[];
}

const initialCampaignsState: CampaignsEntitiesState = {
    byId: {},
    allIds: []
};

// update to not take in explicit "key", update all keys in action.response.entities?
const updateCampaign = (key: string, state: ById, action: NormalizedAction) => {
    const campaignId = action.payload.campaignId;
    const campaign = state[campaignId];

    return {
        ...state,
        [campaignId]: {
            ...campaign,
            [key]: action.response.result
        }
    };
};

const campaignNewEntry = (
    key: "customLinks" | "scripts",
    state: ById,
    action: NormalizedAction
) => {
    const campaignId = action.payload.campaignId;
    const campaign = state[campaignId];
    const newEntryArray = campaign[key];

    if (newEntryArray)
        return {
            ...state,
            [campaignId]: {
                ...campaign,
                [key]: [...newEntryArray, action.response.result]
            }
        };
    return state;
};

const byId = (state = initialCampaignsState.byId, action: NormalizedAction) => {
    switch (action.type) {
        case FETCH_CAMPAIGNS.SUCCESS:
            return { ...state, ...action.response.entities.campaigns };

        case FETCH_CAMPAIGN.SUCCESS:
            return merge({}, state, action.response.entities.campaigns);

        case FETCH_CAMPAIGN_BUDGET.SUCCESS:
            const campaignId = action.payload.campaignId;
            const campaign = state[campaignId];
            return {
                ...state,
                [action.payload.campaignId]: {
                    ...campaign,
                    budget: action.payload.budget
                }
            };

        case FETCH_CAMPAIGN_MEDIA_RULES.SUCCESS:
            return updateCampaign("mediaRules", state, action);

        case FETCH_CAMPAIGN_SCRIPTS.SUCCESS:
            return updateCampaign("scripts", state, action);
        case SCRIPT_CREATE.SUCCESS:
            return campaignNewEntry("scripts", state, action);
        case FETCH_CAMPAIGN_HIGHLIGHTS.SUCCESS:
            return updateCampaign("highlights", state, action);

        case FETCH_CAMPAIGN_ASSETS.SUCCESS:
            return updateCampaign("assets", state, action);

        case FETCH_CAMPAIGN_CAPTIONS.SUCCESS:
            return updateCampaign("captions", state, action);

        case FETCH_CAMPAIGN_MEDIAS.SUCCESS:
            return updateCampaign("medias", state, action);

        case FETCH_CAMPAIGN_PUBLIC_MEDIAS.SUCCESS:
            return updateCampaign("medias", state, action);

        case FETCH_CAMPAIGN_CUSTOM_LINKS.SUCCESS:
            return updateCampaign("customLinks", state, action);

        case FETCH_CAMPAIGN_STATISTICS.SUCCESS:
            return updateCampaign("statistics", state, action);

        case FETCH_CAMPAIGN_PRICE.SUCCESS:

        case FETCH_CAMPAIGN_METRICS.SUCCESS:
            return merge({}, state, action.response.entities.campaigns);

        case FETCH_CAMPAIGN_INSTALL_HISTORY.SUCCESS:
            return {
                ...state,
                [action.payload.campaignId]: {
                    ...state[action.payload.campaignId],
                    installHistory: action.payload.installHistory
                }
            };

        case FETCH_CAMPAIGN_ACCESS.SUCCESS:
            return {
                ...state,
                [action.payload.campaignId]: {
                    ...state[action.payload.campaignId],
                    access: action.payload.access
                }
            };

        case CREATE_CAMPAIGN_CUSTOM_LINK.SUCCESS:
            return campaignNewEntry("customLinks", state, action);
        case DELETE_MEDIA.SUCCESS:
            const { mediaId, campaignId: mediaCampaignId } = action.payload;
            return {
                ...state,
                [mediaCampaignId]: {
                    ...state[mediaCampaignId],
                    medias: state[mediaCampaignId].medias?.filter(
                        val => val !== mediaId
                    )
                }
            };

        case FETCH_CAMPAIGN_SCRIPT.SUCCESS:
            const fetchedScriptId = action.response.result;
            const script = action.response.entities.scripts[fetchedScriptId];
            const fetchScriptCampaignId = script.campaignId;
            const stateAfterScriptFetch = { ...state };
            const scriptFetchCampaign =
                stateAfterScriptFetch[fetchScriptCampaignId];
            if (scriptFetchCampaign && scriptFetchCampaign.scripts) {
                scriptFetchCampaign.scripts.push(fetchedScriptId);
            }
            return stateAfterScriptFetch;

        case FETCH_CAMPAIGN_MEDIA.SUCCESS:
            const fetchedMediaId = action.response.result;
            const media = action.response.entities.medias[fetchedMediaId];
            const fetchedMediaCampaignId = media.campaignId as number;
            const stateAfterMediaFetch = { ...state };
            const mediaFetchCampaign =
                stateAfterMediaFetch[fetchedMediaCampaignId];
            if (mediaFetchCampaign && mediaFetchCampaign.medias) {
                mediaFetchCampaign.medias.push(fetchedMediaId);
            }
            return stateAfterMediaFetch;

        case FETCH_CAMPAIGN_ASSET.SUCCESS:
            const fetchedAssetId = action.response.result;
            const fetchedAssetCampaignId = action.payload.campaignId as number;
            const stateAfterAssetFetch = { ...state };
            const assetFetchCampaign =
                stateAfterAssetFetch[fetchedAssetCampaignId];
            if (assetFetchCampaign && assetFetchCampaign.assets) {
                assetFetchCampaign.assets.push(fetchedAssetId);
            }
            return stateAfterAssetFetch;

        default:
            return state;
    }
};

const allIds = (
    state = initialCampaignsState.allIds,
    action: NormalizedAction
) => {
    switch (action.type) {
        case FETCH_CAMPAIGNS.SUCCESS:
            return action.response.result;

        default:
            return state;
    }
};

const campaignsEntitiesReducer = combineReducers<CampaignsEntitiesState>({
    byId,
    allIds
} as any);

export default campaignsEntitiesReducer;
