import React from "react";
import styled from "styled-components";
import { CountryEarnings } from "./Analytics";
import { codeToName } from "./CodeToName";
import { HorizontalBar } from "react-chartjs-2";
import { GlobalState } from "../reducers";
import { connect } from "react-redux";
import { colors, fonts } from "../constants";
import AnalyticsEmptyState from "./components/AnalyticsEmptyState";

const Main = styled.div`
    padding: 10px 15px 15px;
    text-align: left;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    color: ${colors.secondaryGray};
    margin-bottom: 10px;
`;

const LocationsCard = ({
    countryEarnings
}: {
    countryEarnings: CountryEarnings[];
}) => {
    function generateDataObject(earningsList: CountryEarnings[]) {
        let labels: string[] = [];
        let data: number[] = [];
        let backgroundColor: string[] = [];

        for (let i = 0; i < earningsList.length; i++) {
            labels.push(codeToName[earningsList[i].country]);
            data.push(parseFloat(earningsList[i].percent));
            backgroundColor.push(colors.primaryGreen);
        }

        return {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor
                }
            ]
        };
    }

    const thereIsNoData =
        generateDataObject(countryEarnings).datasets[0].data.length === 0;

    return (
        <Main>
            <Title>Top Locations</Title>
            <Subtitle>Installs by Country</Subtitle>
            {thereIsNoData ? (
                <AnalyticsEmptyState />
            ) : (
                <HorizontalBar
                    height={200}
                    width={300}
                    data={generateDataObject(countryEarnings)}
                    options={{
                        legend: { display: false },
                        layout: {
                            padding: {
                                right: 45
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    gridLines: {
                                        display: false,
                                        drawBorder: false
                                    },
                                    ticks: {
                                        fontColor: colors.secondaryGray,
                                        padding: 10,
                                        min: 0,
                                        max: 100,
                                        stepSize: 33,
                                        beginAtZero: true,
                                        callback: function(value: any) {
                                            return `${value}%`;
                                        }
                                    }
                                }
                            ],
                            yAxes: [
                                {
                                    gridLines: {
                                        display: false,
                                        drawBorder: false
                                    },
                                    ticks: {
                                        fontColor: colors.secondaryGray,
                                        padding: 5
                                    }
                                }
                            ]
                        },
                        tooltips: {
                            displayColors: false,
                            xAlign: "left",
                            callbacks: {
                                title: function() {}
                            }
                        }
                    }}
                />
            )}
        </Main>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    countryEarnings: state.analytics.userAnalytics.country_earnings
});

export default connect(mapStateToProps)(LocationsCard);
