import { schema } from "normalizr";

export const creatorGroups = new schema.Entity("creatorGroups");
export const creatorGroupsList = new schema.Array(creatorGroups);

export const creators = new schema.Entity(
    "creators",
    {},
    {
        idAttribute: "social_account_id"
    }
);
export const creatorsList = new schema.Array(creators);
