import React, { useState } from "react";
import ModalHeading from "src/components/modals/components/ModalHeading";
import { isNumberInputValid } from "src/components/table/utils";
import MarketplaceInput from "src/marketplace/components/MarketplaceInput";
import { BlueButton, GrayButton } from "src/profile/components/Buttons";
import { EventInput } from "src/utils/types/form";
import styled from "styled-components";
import * as Papa from "papaparse";
import { useDispatch } from "react-redux";
import { setPillNotificationText } from "src/ui/notifications/actions";

const Wrapper = styled.div``;

const InputWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding-top: 12px;
    padding-bottom: 24px;
`;
const AddBtn = styled(BlueButton)`
    padding: 9px 15px;
    font-size: 12px;
`;
const CsvBtn = styled(GrayButton)`
    font-size: 12px;
    padding: 9px 15px;
    margin: 0px;
    white-space: nowrap;
`;

interface Props {
    handleUpsert: (
        pubIds: number[],
        extendedUntil: number,
        message: { success: string; failure: string }
    ) => Promise<void>;
}

const ExtensionsForm = ({ handleUpsert }: Props) => {
    const dispatch = useDispatch();
    const [individualInput, setIndividualInput] = useState("");

    const handleUpload = async (e: EventInput) => {
        const files = e.currentTarget.files;
        if (files && files.length > 0) {
            const file = files[0];
            Papa.parse(file, {
                complete: async (res: any) => {
                    if (res.data.length > 0) {
                        const headers = res.data[0];
                        const pubIdx =
                            headers.indexOf("publisher_id") !== -1
                                ? headers.indexOf("publisher_id")
                                : headers.indexOf("publisherId");
                        if (pubIdx === -1) {
                            dispatch(
                                setPillNotificationText(
                                    "CSV must have publisher_id or publisherId column",
                                    "danger",
                                    3500
                                )
                            );
                        } else {
                            const body = res.data.slice(1);
                            const pubIds = body
                                .filter((row: any) => !!parseInt(row[pubIdx]))
                                .map((row: any) => parseInt(row[pubIdx]));

                            handleUpsert(
                                pubIds,
                                new Date().setFullYear(
                                    new Date().getFullYear() + 100
                                ),
                                {
                                    success: `Successfully Extended Challenge for ${pubIds.length} Publishers`,
                                    failure: `Failed to Extend Challenge for ${pubIds.length} Publishers`
                                }
                            );
                        }
                    }
                }
            });
        }
    };
    const handleIndividualUpload = async () => {
        handleUpsert(
            [parseInt(individualInput)],
            new Date().setFullYear(new Date().getFullYear() + 100),
            {
                success: `Challenge Successfully Extended for Publisher: ${individualInput}`,
                failure: `Failed to Extend Challenge for Publisher: ${individualInput}`
            }
        );
        setIndividualInput("");
    };

    return (
        <Wrapper>
            <ModalHeading text="ADD INDIVIDUAL PUBLISHER" />
            <InputWrap>
                <MarketplaceInput
                    value={individualInput}
                    onChange={e => {
                        const val = e.currentTarget.value;
                        if (isNumberInputValid(val)) {
                            setIndividualInput(val);
                        }
                    }}
                />
                <AddBtn
                    disabled={individualInput.length === 0}
                    onClick={handleIndividualUpload}
                >
                    Add
                </AddBtn>
                <CsvBtn as="label">
                    Upload CSV
                    <input
                        style={{ display: "none" }}
                        type="file"
                        accept=".csv"
                        onChange={handleUpload}
                        onClick={(
                            e: React.MouseEvent<HTMLInputElement, MouseEvent>
                        ) => ((e.target as HTMLInputElement).value = "")}
                    />
                </CsvBtn>
            </InputWrap>
        </Wrapper>
    );
};

export default ExtensionsForm;
