import { combineReducers } from "redux";
import { NormalizedAction } from "src/types";
import {
    FETCH_INSTRUCTIONS_SETS,
    DELETE_INSTRUCTIONS_SET,
    NormalizedInstructionSetsResponse,
    FETCH_INSTRUCTIONS_SET,
    UPDATE_INSTRUCTIONS_SET,
    RESET_INSTRUCTION_SETS
} from "./actions.instructions-sets";

export interface InstructionsSetsEntitiesState {
    byId: NormalizedInstructionSetsResponse;
    allIds: number[];
}

const initialInstructionsSetsState: InstructionsSetsEntitiesState = {
    byId: {},
    allIds: []
};

function byId(
    state = initialInstructionsSetsState.byId,
    action: NormalizedAction
) {
    switch (action.type) {
        case FETCH_INSTRUCTIONS_SETS.SUCCESS:
            return { ...state, ...action.payload.entities.instructionsSets };

        case FETCH_INSTRUCTIONS_SET.SUCCESS:
            const updatedFetchState = { ...state };
            updatedFetchState[action.payload.id] = action.payload;
            return updatedFetchState;

        case UPDATE_INSTRUCTIONS_SET.SUCCESS:
            const updatedUpdateState = { ...state };
            updatedUpdateState[action.payload.instructionsSetId].name =
                action.payload.updatedData.name;
            updatedUpdateState[
                action.payload.instructionsSetId
            ].instructionSteps = action.payload.instructionSteps;
            return updatedUpdateState;

        case DELETE_INSTRUCTIONS_SET.SUCCESS:
            const newState = { ...state };
            const currentInstructionsSetId = action.payload;
            delete newState[currentInstructionsSetId];
            return newState;

        case RESET_INSTRUCTION_SETS:
            return {};

        default:
            return state;
    }
}

function allIds(
    state = initialInstructionsSetsState.allIds,
    action: NormalizedAction
) {
    switch (action.type) {
        case FETCH_INSTRUCTIONS_SETS.SUCCESS:
            return action.payload.result;

        case DELETE_INSTRUCTIONS_SET.SUCCESS:
            const newState = [...state];
            const currentInstructionsSetId = action.payload;
            return newState.filter((instructionsSetId: number) => {
                return instructionsSetId !== currentInstructionsSetId;
            });

        case RESET_INSTRUCTION_SETS:
            return [];

        default:
            return state;
    }
}

const instructionSetsEntitiesReducer = combineReducers<
    InstructionsSetsEntitiesState
>({
    byId,
    allIds
});

export default instructionSetsEntitiesReducer;
