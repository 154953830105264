import React, { useState } from "react";
import SmallModal from "src/modals/SmallModal";
import styled from "styled-components";
import {
    Label,
    Option,
    OptionList,
    OptionText
} from "../../../media/search/components/CommonStyles";
import { FlexContent, FlexVCSpaceBetween } from "src/components/CommonStyles";
import Toggler from "src/components/Toggler";
import CheckBox from "src/components/CheckBox";
import {
    BigBlueButton,
    BlueButton,
    BlueEmptyButton
} from "src/profile/components/Buttons";
import { colors } from "src/constants";
import { post } from "src/Api";
import { download } from "src/util";
import { shallowEqual, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { Spinner } from "react-bootstrap";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    & > * ~ * {
        margin-top: 16px;
    }
`;

const Item = styled.div`
    ${FlexVCSpaceBetween};
    height: 46px;
    padding: 0 16px;
`;

const FieldsList = styled(OptionList)`
    flex: 1;
    overflow-y: auto;
`;

const Title = styled.div`
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
`;

const Caret = styled.img`
    margin-left: auto;
    margin-right: 6px;
`;

const ExportButton = styled(BlueButton)`
    height: 32px;
    padding: 0 15px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

interface ExportableFieldsState {
    [field: string]: boolean;
}

const initialExportableFieldsState = {
    social_account_id: true,
    publisher_id: true,
    username: true,
    platform: false,
    created_at: false,
    plug_username: false,
    age: false,
    last_app_open: false,
    pricing: false,
    picture: false,
    followers: false,
    followers_growth_rate: false,
    avg_likes: false,
    avg_views: false,
    engagement_rate: false,
    url: false,
    interests: false,
    country_name: false,
    country_code: false,
    page_style: false,
    brand_safety: false,
    theme: false,
    audience_countries: false,
    audience_country_code: false,
    audience_country_name: false,
    audience_country_weight: false,
    audience_languages: false,
    audience_language_code: false,
    audience_language_name: false,
    audience_language_weight: false,
    audience_genders: false,
    audience_gender_code: false,
    audience_gender_weight: false,
    audience_ages: false,
    audience_age_code: false,
    audience_age_weight: false,
    audience_devices: false,
    audience_device_code: false,
    audience_device_weight: false
};

interface Props {
    closeModal: () => void;
    searchString: string;
}

const CreatorSearchCSVExportModal = ({ closeModal, searchString }: Props) => {
    const [exportFields, setExportFields] = useState<ExportableFieldsState>(
        initialExportableFieldsState
    );
    const [fieldsModal, toggleFieldsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [groupByPublisher, setGroupByPublisher] = useState(true);
    const { dateRange, sort, queryFilters } = useSelector(
        (state: GlobalState) => state.ui.creators,
        shallowEqual
    );

    const handleFieldSelect = (field: string) => {
        const nextState = { ...exportFields, [field]: !exportFields[field] };
        setExportFields(nextState);
    };

    const activeFields = Object.keys(exportFields).filter(
        field => exportFields[field]
    );

    const downloadCSV = async () => {
        setLoading(true);
        const { data } = await post("/v1/search/export_creators", {
            group_by_publisher: groupByPublisher,
            fields: activeFields,
            params: {
                query: searchString,
                date: dateRange,
                filter: queryFilters,
                sort
            }
        });

        const filename = new Date().toLocaleDateString("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit"
        });
        download(data.data, `${filename}.csv`, "text/csv;encoding:utf-8");
        setLoading(false);
    };

    const renderActiveFields = () => {
        if (activeFields.length > 3) {
            return (
                activeFields.slice(0, 3).join(", ") +
                ` +${activeFields.length - 3} more.`
            );
        }

        return activeFields.join(", ");
    };

    if (fieldsModal)
        return (
            <SmallModal
                header={{
                    text: "CSV Export Fields",
                    handleClose: closeModal
                }}
                isBack
                onBack={() => toggleFieldsModal(false)}
                zIndex={2000}
            >
                <Main>
                    <FieldsList>
                        {Object.keys(exportFields).map(field => (
                            <Option
                                key={field}
                                onClick={() => handleFieldSelect(field)}
                            >
                                <OptionText>{field}</OptionText>
                                <CheckBox selected={exportFields[field]} />
                            </Option>
                        ))}
                    </FieldsList>

                    <BigBlueButton onClick={() => toggleFieldsModal(false)}>
                        Set Fields
                    </BigBlueButton>
                </Main>
            </SmallModal>
        );

    return (
        <SmallModal
            header={{
                text: "Export Selection as CSV",
                handleClose: closeModal
            }}
            zIndex={2000}
        >
            <Main>
                <Item>
                    <Title>Group by publisher</Title>
                    <Toggler
                        isActive={groupByPublisher}
                        onSelect={() => setGroupByPublisher(true)}
                        onDeselect={() => setGroupByPublisher(false)}
                    />
                </Item>
                <div>
                    <Label>CSV EXPORT FIELDS</Label>
                    <OptionList>
                        <Option onClick={() => toggleFieldsModal(true)}>
                            <OptionText>{renderActiveFields()}</OptionText>
                            <Caret src={"/caret.svg"} />
                        </Option>
                    </OptionList>
                </div>
                <ButtonWrapper>
                    <ExportButton onClick={downloadCSV} disabled={loading}>
                        {loading ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : (
                            "Export"
                        )}
                    </ExportButton>
                </ButtonWrapper>
            </Main>
        </SmallModal>
    );
};
export default CreatorSearchCSVExportModal;
