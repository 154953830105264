import styled from "styled-components";
import { colors, maxWidthDesktop } from "src/constants";

const ProfileTabsContainer = styled("section")`
    margin-top: 20px;
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${maxWidthDesktop}px) {
        margin-top: 0px;
        border: none;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

export default ProfileTabsContainer;
