import React from "react";
import { Media } from "../../../media/types";
import MediaTextOverlay from "../../../media/view/MediaTextOverlay";

interface Props {
    customMedia: Media;
    username: string | null;
}

const CustomMediaOverlay = ({ customMedia, username }: Props) => {
    return (
        <MediaTextOverlay
            title={`Media Id: ${customMedia.id}`}
            titleSize={13}
            subtitle={username ? `By: @${username}` : ""}
            subtitleSize={11}
            bottom={customMedia.campaignName}
            bottomSize={13}
        />
    );
};

export default CustomMediaOverlay;
