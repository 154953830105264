import React from "react";
import styled from "styled-components";
import Modal from "../../../modals/Modal";
import ModalButton from "../../../modals/ModalButton";
import { CashoutFee } from "../../types";
import { fonts } from "src/constants";

const Main = styled.div`
    padding: 20px 25px;
    font-size: ${fonts.subtitle}px;
`;

const Title = styled.div`
    font-size: 18px;
`;

const Description = styled.div`
    font-weight: 300;
`;

const Item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
`;

const FeeItem = ({ fee }: { fee: CashoutFee }) => {
    return (
        <Item>
            <div>{fee.type}</div>
            <div>{fee.fee_string}</div>
        </Item>
    );
};

const FeesModal = ({ feeSchedule }: { feeSchedule: CashoutFee[] }) => {
    return (
        <Modal>
            <Main>
                <Title>Processing Fees</Title>
                <Description>
                    Fees vary by payment method. We recommend ACH for lowest
                    fees!
                </Description>
                {feeSchedule.map((fee, idx) => (
                    <FeeItem key={idx} fee={fee} />
                ))}
            </Main>
            <ModalButton
                onClick={() => {
                    window.open(
                        "https://suppliers.tipalti.com/theplug/portal/index"
                    );
                }}
                text={"Update Payment Method"}
            />
            <ModalButton />
        </Modal>
    );
};

export default FeesModal;
