import { push } from "connected-react-router";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { breakpoints, colors, fonts } from "src/constants";
import styled from "styled-components";
import { GlobalState } from "../../reducers";
import { checkPublisherFlag } from "../../profile/flags/selectors";
import CircleIcon from "../../components/CircleIcon";
import LoadingButton from "../../components/LoadingButton";
import { post } from "../../Api";
import { fetchPublisherFlagsSuccess } from "../../profile/flags/actions";
import { normalize } from "normalizr";
import { publisherFlag } from "../../profile/flags/schema";
import { errorToString } from "../../util";
import IntroBuysFlowStarter from "./IntroBuysFlowStarter";

const Header = styled.div`
    margin: 0px;
    padding-bottom: 16px;
    color: ${colors.black};
    font-size: ${fonts.title}px;
    font-weight: 500;
    text-align: left;
    line-height: 29px;

    @media (max-width: ${breakpoints.mobile}px) {
        font-size: 20px;
    }
`;

const Card = styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 12px;
`;

const Button = styled.img`
    width: 100%;
    cursor: pointer;
    user-select: none;
`;

const AccessText = styled.div`
    width: 100%;
    padding: 0 8px;
    font-weight: 500;
    font-size: ${fonts.smallTitle}px;
    line-height: 16px;
    text-align: left;
`;

interface CardProps {
    children: React.ReactNode;
}

const CardContainer = ({ children }: CardProps) => {
    return (
        <>
            <Header>Personal Offers</Header>
            <Card>{children}</Card>
        </>
    );
};

const IntroBuysHeader = () => {
    const dispatch = useDispatch();

    const flowCompleted = useSelector((state: GlobalState) =>
        checkPublisherFlag(state, "directBuyIntroFlow", "complete")
    );
    const applicationCompleted = useSelector((state: GlobalState) =>
        checkPublisherFlag(state, "directBuyApplication", "complete")
    );
    const hasAccess = useSelector((state: GlobalState) =>
        checkPublisherFlag(state, "directBuyAccess", "approved")
    );
    const started = useSelector((state: GlobalState) =>
        checkPublisherFlag(state, "directBuyStarted", "started")
    );

    const [startLoading, setStartLoading] = useState(false);

    function startDirectBuys() {
        setStartLoading(true);
        post(
            `/v1/publisher/p:${localStorage.getItem(
                "userid"
            )}/flag/directBuyStarted`,
            {
                value: "started"
            }
        )
            .then(({ data }) => {
                setStartLoading(false);
                dispatch(
                    fetchPublisherFlagsSuccess(
                        normalize(data.data, publisherFlag)
                    )
                );
            })
            .catch(error => {
                window.alert(errorToString(error));
                setStartLoading(false);
            });
    }

    if (!flowCompleted) {
        return <IntroBuysFlowStarter />;
    } else if (!applicationCompleted) {
        return (
            <CardContainer>
                <CircleIcon type="x" color={colors.primaryRed} />
                <AccessText>You haven’t applied for Personal Offers</AccessText>
                <LoadingButton
                    text="Apply"
                    onClick={() => dispatch(push("/buys/direct/intro"))}
                    width="fit-content"
                    height="32px"
                    padding="0 16px"
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                    textSize="12px"
                />
            </CardContainer>
        );
    } else if (!hasAccess) {
        return (
            <CardContainer>
                <CircleIcon type="ellipsis" color={colors.primaryYellow} />
                <AccessText>Application pending</AccessText>
                <LoadingButton
                    text="Edit Rates"
                    onClick={() => dispatch(push("/profile/accounts"))}
                    width="fit-content"
                    height="32px"
                    padding="0 16px"
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                    textSize="12px"
                />
            </CardContainer>
        );
    } else if (!started) {
        return (
            <CardContainer>
                <CircleIcon type="check" color={colors.primaryGreen} />
                <AccessText>
                    Your application for Personal Offers has been accepted!
                </AccessText>
                <LoadingButton
                    text="Start"
                    onClick={startDirectBuys}
                    width="fit-content"
                    height="32px"
                    padding="0 16px"
                    backgroundColor={colors.primaryBlue}
                    textColor={colors.white}
                    textSize="12px"
                    isLoading={startLoading}
                    spinnerSize={12}
                />
            </CardContainer>
        );
    } else {
        return null;
    }
};

export default IntroBuysHeader;
