import React from "react";
import SearchBar from "src/components/SearchBar";
import { colors, fonts, fontWeight } from "src/constants";
import {
    BlueButton,
    GrayButton,
    RedButton
} from "src/profile/components/Buttons";
import { clearButton } from "src/utils/styles/snippets";
import styled from "styled-components";
import { FiltersReference } from "../hooks/useFilters";
import { SearchReference } from "../hooks/useSearch";
import { TableButton } from "../PlugTable";
import TableFilters from "./TableFilters";
import ServerTableFilters from "./TableServerFilters";

const Header = styled.header`
    padding: 16px;
    isolation: isolate;
    z-index: 1;
`;
const TitleWrap = styled.div<{ noMarginBottom: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${props => (props.noMarginBottom ? "0px" : "14px")};
`;
const Title = styled.h2<{ titleSize?: number }>`
    font-size: ${props => (props.titleSize ? props.titleSize : fonts.title)}px;
    font-weight: ${fontWeight.medium};
    text-align: left;
    margin: 0px;
`;
const BtnWrap = styled.div<{ marginLeftAuto: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    ${props => props.marginLeftAuto && `margin-left: auto;`}
`;
const StyledSearchBar = styled(SearchBar)`
    margin-bottom: 12px;
`;
const TableRedBtn = styled(RedButton)`
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
`;
const TableBlueBtn = styled(BlueButton)`
    padding: 8px 12px;
    font-size: 13px;
    font-weight: 500;
`;
const TableGrayBtn = styled(GrayButton)`
    border-radius: 6px;
    padding: 8px 12px;
`;
export interface ServerFilterOption {
    valueToFilterBy: string;
    displayValue: string;
    selected: boolean;
}
export interface ServerFilter {
    displayValue: string;
    type: "string";
    choices: ServerFilterOption[];
    disabled?: boolean;
}
export interface ServerFilters {
    [key: string]: ServerFilter;
}
export interface FilterChange {
    key: string;
    valueToFilterBy: string;
    valueToChangeTo: boolean;
}
export const resetSelections = (filters: ServerFilters) => {
    return Object.entries(filters).reduce(
        (acc, [key, filter]): ServerFilters => {
            return {
                ...acc,
                [key]: {
                    ...filter,
                    choices: filter.choices.map(choice => ({
                        ...choice,
                        selected: false
                    }))
                }
            };
        },
        {}
    );
};
export const updateSelections = (
    filters: ServerFilters,
    choicesToChange: FilterChange[],
    resetBeforeChange?: boolean
) => {
    return choicesToChange.reduce(
        (acc: ServerFilters, choice: FilterChange) => {
            return {
                ...acc,
                [choice.key]: {
                    ...acc[choice.key],
                    choices: acc[choice.key]?.choices?.map(val => {
                        if (val.valueToFilterBy === choice.valueToFilterBy) {
                            return { ...val, selected: choice.valueToChangeTo };
                        }
                        return val;
                    })
                }
            };
        },
        { ...(resetBeforeChange ? resetSelections(filters) : filters) }
    );
};
interface Props {
    title?: string;
    titleSize?: number;
    tableButtons?: TableButton[];
    filters?: {
        getter: ServerFilters;
        setter: React.Dispatch<React.SetStateAction<ServerFilters>>;
    };
}
const TableServerHeader = ({
    title,
    titleSize,
    tableButtons,
    filters
}: Props) => {
    const renderButton = (btn: TableButton, idx: number) => {
        if (btn.color === "red") {
            return (
                <TableRedBtn
                    key={btn.text + idx}
                    onClick={btn.onClick}
                    disabled={btn.disabled}
                >
                    {btn.text}
                </TableRedBtn>
            );
        }
        if (btn.color === "blue") {
            return (
                <TableBlueBtn
                    key={btn.text + idx}
                    onClick={btn.onClick}
                    disabled={btn.disabled}
                >
                    {btn.text}
                </TableBlueBtn>
            );
        }
        return (
            <TableGrayBtn
                key={btn.text + idx}
                onClick={btn.onClick}
                disabled={btn.disabled}
            >
                {btn.text}
            </TableGrayBtn>
        );
    };

    return (
        <Header>
            {title || tableButtons ? (
                <TitleWrap noMarginBottom={!filters}>
                    {title ? (
                        <Title titleSize={titleSize}>{title}</Title>
                    ) : null}
                    {tableButtons && tableButtons.length > 0 ? (
                        <BtnWrap marginLeftAuto={!title}>
                            {tableButtons.map(renderButton)}
                        </BtnWrap>
                    ) : null}
                </TitleWrap>
            ) : null}
            {/* {isSearchable && (
                <StyledSearchBar
                    searchString={searchString}
                    updateSearchString={setSearchString}
                    autoFocus={false}
                />
            )} */}
            {filters && <ServerTableFilters filters={filters} />}
        </Header>
    );
};

export default TableServerHeader;
