import { Action } from "../types";
import { SET_FEATURE_FLAG } from "./actions";

export interface FirebaseState {
    firestore: {
        featureFlags: { [id: string]: boolean };
    };
}

const initialFirebaseState: FirebaseState = {
    firestore: {
        featureFlags: {}
    }
};

const reducer = (
    state = initialFirebaseState,
    action: Action
): FirebaseState => {
    switch (action.type) {
        case SET_FEATURE_FLAG:
            state.firestore.featureFlags[action.payload.id] =
                action.payload.value;
            return state;
        default:
            return state;
    }
};

export default reducer;
