import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

const Main = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    background-color: white;
`;

const Icon = styled.img`
    height: 25px;
    width: 27px;
`;

const Title = styled.div`
    font-weight: 600;
    padding: 15px 0px 65px;
`;

const Description = styled.div`
    margin-bottom: 15px;
`;

const AccountDisabled = ({ push }: { push: (route: string) => void }) => {
    const buttonStyle = { width: "100%", height: "50px" };

    return (
        <Main>
            <div>
                <Icon src={"/icon_nux_green/icon_nux_green@2x.png"} />
                <Title>Account Disabled</Title>
                <Description>
                    Your account has been disabled for breaking the network
                    guidelines that you agreed to.
                </Description>
                <Description>
                    Please contact us if you think this is an error or have any
                    questions regarding your account at
                </Description>
                <Description>hi@plugco.in</Description>
            </div>
            <Button onClick={() => push("/rules")} style={buttonStyle}>
                See Our Guidelines
            </Button>
        </Main>
    );
};

export default AccountDisabled;
