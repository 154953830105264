import React from "react";
import MainContainer from "src/components/MainContainer";
import MarketplacePagePartialHeader from "src/marketplace/components/MarketplacePagePartialHeader";
import {
    creatorGroupsRoutes,
    instructionSetsRoutes,
    paymentSetsRoutes
} from "src/marketplace/utils/marketplace-routes";
import styled from "styled-components";
import { ChallengeToolsLink } from "../utils/challenge-tools.types";
import ChallengeToolsLinkTab from "./ChallengeToolsLinkTab";

const LinkTabs = styled.section`
    display: flex;
    gap: 8px;
    margin: 12px 0px 24px;
`;

const links: ChallengeToolsLink[] = [
    {
        text: "Instruction Sets",
        route: instructionSetsRoutes.main
    },
    {
        text: "Creator Groups",
        route: creatorGroupsRoutes.main
    },
    {
        text: "Payment Sets",
        route: paymentSetsRoutes.main
    }
];

const ChallengeToolsHeader = () => {
    return (
        <MainContainer>
            <MarketplacePagePartialHeader />
            <LinkTabs>
                {links.map((link: ChallengeToolsLink) => {
                    return (
                        <ChallengeToolsLinkTab
                            text={link.text}
                            route={link.route}
                            includeIcon
                        />
                    );
                })}
            </LinkTabs>
        </MainContainer>
    );
};

export default ChallengeToolsHeader;
