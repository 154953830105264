import React from "react";
import { Connection } from "../../../types";
import Header from "../../../../components/Header";
import ConnectionsSection from "../../components/ConnectionsSection";
import RecommendationRow from "../../components/RecommendationRow";

interface Props {
    connections: Connection[];
    recommendations: Connection[];
}

const RequestsTab = ({ connections, recommendations }: Props) => {
    return (
        <>
            <Header
                title={"Requests"}
                subtitle={`${connections.length} Requests`}
            />
            <ConnectionsSection includeSearch connections={connections} />
            <RecommendationRow wrapped recommendations={recommendations} />
        </>
    );
};

export default RequestsTab;
