import React from "react";
import styled from "styled-components";
import { ExternalPage } from "src/campaigns/types";
import { fonts } from "src/constants";

const VerticallyCentered = styled.div`
    display: flex;
    align-items: center;
`;

const Main = styled(VerticallyCentered)`
    cursor: pointer;

    & + & {
        margin-top: 15px;
    }
`;

const ArticleIcon = styled.img`
    height: 46px;
    width: 46px;
    min-width: 46px;
    border-radius: 6px;
    margin-right: 10px;
    object-fit: cover;
`;

const ArticleInfo = styled.div`
    overflow: hidden;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const HideOverflow = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;
`;

const Title = styled(HideOverflow)`
    font-size: ${fonts.subtitle}px;
`;

const Checkbox = styled.img`
    padding-left: 10px;
    margin-left: auto;
`;

interface Props {
    externalPage: ExternalPage;
    selected: boolean;
    onClick: VoidFunction;
}

const SelectExternalPageRow = ({ externalPage, selected, onClick }: Props) => {
    return (
        <Main onClick={onClick}>
            <ArticleIcon src={externalPage.imageUrl} />
            <ArticleInfo>
                <Title>
                    <strong>{externalPage.title}</strong>
                </Title>
            </ArticleInfo>
            {selected ? (
                <Checkbox src={"/checkbox_selected.svg"} />
            ) : (
                <Checkbox src={"/checkbox_unselected.svg"} />
            )}
        </Main>
    );
};

export default SelectExternalPageRow;
