import React from "react";

interface Props {
    style?: any;
}

const HorizontalDivider = ({ style }: Props) => {
    return (
        <div
            style={{
                height: 1,
                backgroundColor: "gray",
                width: "100%",
                ...style
            }}
        ></div>
    );
};

export default HorizontalDivider;
