import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../../reducers";
import { clearModal } from "../../../modals/actions";
import { ShoutoutScreenshot } from "src/ui/modals/actions";
import { deleteMediaFromFillOrder, ShoutoutMediaDelete } from "../actions";
import styled from "styled-components";
import Modal from "../../../modals/Modal";
import ModalButton from "../../../modals/ModalButton";
import { fonts } from "src/constants";

const PhotoContainer = styled.div`
    width: 100%;
    padding: 25px 25px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Main = styled.div`
    padding: 20px 25px;
`;

const Photo = styled.img`
    max-height: 250px;
    max-width: 100%;
`;

const Title = styled.div`
    font-size: 20px;
`;

const Subtitle = styled.div`
    font-size: ${fonts.subtitle}px;
    font-weight: 300;
`;

interface Props {
    currentScreenshot: ShoutoutScreenshot | null;
    clearModal: () => void;
    deleteMediaFromFillOrder: (payload: ShoutoutMediaDelete) => void;
}

const DeleteModal = ({
    currentScreenshot,
    clearModal,
    deleteMediaFromFillOrder
}: Props) => {
    if (currentScreenshot) {
        return (
            <Modal>
                <PhotoContainer>
                    <Photo src={currentScreenshot.url} />
                </PhotoContainer>
                <Main>
                    <Title>Delete Screenshot?</Title>
                    <Subtitle>
                        Would you like to delete this screenshot and add
                        another?
                    </Subtitle>
                </Main>
                <ModalButton
                    text={"Delete"}
                    onClick={() => deleteMediaFromFillOrder(currentScreenshot)}
                />
                <ModalButton text={"Cancel"} />
            </Modal>
        );
    } else {
        clearModal();
        return null;
    }
};

const mapStateToProps = (state: GlobalState) => ({
    currentScreenshot: state.ui.modals.currentScreenshot
});

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal()),
    deleteMediaFromFillOrder: (payload: ShoutoutMediaDelete) =>
        dispatch(deleteMediaFromFillOrder(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
