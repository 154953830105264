import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import PlacementsModal from "src/campaigns/placements/modals/PlacementsModal";
import { getPlacements } from "src/campaigns/placements/selectors";
import { GlobalState } from "src/reducers";
import { PlacementConfig } from "src/ui/placements/reducer";
import { selectedPlacementsDisplayText } from "src/util";
import styled from "styled-components";
import { MediaFilters } from "../types";
import {
    Label,
    Option,
    OptionList,
    OptionText
} from "./components/CommonStyles";

const Caret = styled.img`
    margin-left: auto;
    margin-right: 6px;
`;

interface Props {
    filters: MediaFilters;
    setFilters: (filters: MediaFilters) => void;
}

const MediaFilterPlacement = ({ filters, setFilters }: Props) => {
    const [placementsModal, togglePlacementsModal] = useState(false);
    const placements = useSelector(getPlacements);

    function setPlacementConfig(placementConfig: PlacementConfig) {
        setFilters({ ...filters, placements: placementConfig });
    }

    return (
        <div>
            <Label>PLACEMENT</Label>
            <OptionList>
                <Option onClick={() => togglePlacementsModal(true)}>
                    <OptionText>
                        {selectedPlacementsDisplayText(
                            filters.placements,
                            placements.length
                        )}
                    </OptionText>
                    <Caret src={"/caret.svg"} />
                </Option>
            </OptionList>
            <AnimatePresence>
                {placementsModal && (
                    <PlacementsModal
                        localClearModal={() => togglePlacementsModal(false)}
                        keepFrozenOnClose
                        localPlacementConfig={filters.placements}
                        localSetPlacementConfig={setPlacementConfig}
                    />
                )}
            </AnimatePresence>
        </div>
    );
};

export default MediaFilterPlacement;
