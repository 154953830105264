import React, { useEffect } from "react";
import { SocialAccount } from "../../../types";
import { ProfileModal } from "../../../reducer";
import AccountActionsModal from "../modals/AccountActionsModal";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import NavButton from "../../../../components/NavButton";
import ViewHeader from "../../components/ViewHeader";
import Divider from "../../../../components/Divider";
import Header from "../../../../components/Header";
import OwnerCell from "../../components/OwnerCell";
import LockCard from "../../../components/LockCard";
import PaddingNoTop from "../../../../components/PaddingNoTop";
import VerifyModal from "../modals/VerifyModal";
import MainContainer from "src/components/MainContainer";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "src/reducers";
import { getRatesForSocialAccount } from "src/profile/rates/selectors";
import RateItem from "src/profile/rates/RateItem";
import { BigOutlineButton } from "src/profile/components/Buttons";
import {
    addAccountModalOpen,
    editAccountModalOpen,
    rateFormModalOpen,
    setAccountId,
    setIsRateFormEdit,
    viewAccountModalOpen
} from "src/ui/profile/actions";
import FullScreenModal from "src/modals/FullScreenModal";
import { useHistory } from "react-router";
import { actionsModal } from "src/modals/actions";
import { verifyModal } from "src/profile/actions";
import { push } from "connected-react-router";
import { AnimatePresence } from "framer-motion";
import { selectFeatureFlags } from "src/firebase/selectors";
import featureFlags from "src/firebase/featureFlags";

const RatesList = styled.div`
    padding: 0 15px 15px;

    & > * ~ * {
        margin-top: 10px;
    }
`;

const Close = styled.img`
    width: auto;
    height: 14px;
    cursor: pointer;
`;

const AccountViewModal = () => {
    const profile = useSelector((state: GlobalState) => {
        return state.profile;
    });

    const publicProfile = profile.publicProfile;
    const account = profile.currentAccount || ({} as SocialAccount);
    const modal = profile.modal;

    const history = useHistory();

    useEffect(() => {
        if (!account) {
            history.goBack();
        }
    }, [account]);

    const dispatch = useDispatch();

    const ownersArray = [publicProfile];
    const rates = useSelector((state: GlobalState) =>
        getRatesForSocialAccount(state, account.id)
    );
    const splitAccountRates = useSelector(
        selectFeatureFlags(featureFlags.AccountRates)
    );

    function openRateEdit(accountId: number): void {
        dispatch(rateFormModalOpen(true));
        dispatch(setIsRateFormEdit(false));
        dispatch(setAccountId(accountId));
    }

    const isViewAccountModalOpen = useSelector((state: GlobalState) => {
        return state.ui.profile.viewAccountModalOpen;
    });

    function openEditAccountFormModal(): void {
        dispatch(editAccountModalOpen(true));
        dispatch(addAccountModalOpen(false));
    }

    return (
        <AnimatePresence>
            {isViewAccountModalOpen && (
                <FullScreenModal
                    closeModal={() => dispatch(viewAccountModalOpen(false))}
                >
                    <SimpleNavbar
                        left={
                            <Close
                                src="/close.svg"
                                alt="close button"
                                onClick={() =>
                                    dispatch(viewAccountModalOpen(false))
                                }
                            />
                        }
                        right={
                            <NavButton
                                right
                                onClick={() => dispatch(actionsModal())}
                            >
                                <img src="/ellipsis-button.svg" />
                            </NavButton>
                        }
                    />

                    <MainContainer>
                        <ViewHeader
                            account={account}
                            // editCallback={() =>
                            //     dispatch(push("/profile/accounts/edit"))
                            // }
                            editCallback={openEditAccountFormModal}
                        />

                        <Header
                            title={`Owner${ownersArray.length > 1 ? "s" : ""}`}
                            subtitle={`${ownersArray.length} Account Owner${
                                ownersArray.length > 1 ? "s" : ""
                            }`}
                        />
                        {account.verified ? (
                            ownersArray.map(profile => {
                                return (
                                    <OwnerCell
                                        key={profile.id}
                                        publicProfile={profile}
                                    />
                                );
                            })
                        ) : (
                            <PaddingNoTop>
                                <LockCard
                                    text={
                                        "You have not completed the verification process for this account"
                                    }
                                    button={{
                                        text: "Verify",
                                        callback: () => dispatch(verifyModal())
                                    }}
                                />
                            </PaddingNoTop>
                        )}
                        {account.verified && <Divider />}
                        {splitAccountRates && account.verified && (
                            <>
                                <Header
                                    title="Rates"
                                    subtitle={`You have a total of ${rates.length} rate(s) for this account`}
                                />
                                <RatesList>
                                    {rates.map(rate => (
                                        <RateItem
                                            key={rate.id}
                                            publisherOffer={rate}
                                            accountId={account.id}
                                        />
                                    ))}
                                    <BigOutlineButton
                                        onClick={() => openRateEdit(account.id)}
                                    >
                                        Add Rates
                                    </BigOutlineButton>
                                </RatesList>
                            </>
                        )}

                        {modal === ProfileModal.actions && (
                            <AccountActionsModal
                                account={account}
                                push={push}
                            />
                        )}

                        {modal === ProfileModal.verify && (
                            <VerifyModal account={account} />
                        )}
                    </MainContainer>
                </FullScreenModal>
            )}
        </AnimatePresence>
    );
};

export default AccountViewModal;
