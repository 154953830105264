import React, { useState } from "react";
import styled from "styled-components";
import NavButton from "../../../../components/NavButton";
import SimpleNavbar from "../../../../components/SimpleNavbar";
import PaddingNoTop from "../../../../components/PaddingNoTop";
import SearchBar from "../../../../components/SearchBar";
import Header from "../../../../components/Header";
import AccountCell from "../../components/AccountCell";
import {
    fetchAccountsUser,
    postAccount,
    PostAccountParams,
    PushAccountParams,
    pushWithAccount,
    pushWithAccountData
} from "../../../actions";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { Profile, SocialAccount } from "../../../types";
import { GlobalState } from "../../../../reducers";
import FullscreenLoader from "../../../../components/FullscreenLoader";
import PaddingAround from "../../../../components/PaddingAround";
import XCard from "../../../components/XCard";
import PrivacyAccountModal from "../modals/PrivacyAccountModal";
import { useHistory } from "react-router";
import { maxWidthDesktop } from "src/constants";
import MainContainer from "src/components/MainContainer";
import Navbar from "src/components/Navbar";
import {
    addAccountModalOpen,
    editAccountModalOpen,
    viewAccountModalOpen
} from "src/ui/profile/actions";
import { BigOutlineButton } from "src/profile/components/Buttons";

const Main = styled("main")`
    width: 100%;
    max-width: ${maxWidthDesktop}px;
    margin: 0px auto;
`;

interface Props {
    profile: Profile;
    visibilityId?: string;
}

const AccountsPage = ({ profile, visibilityId }: Props) => {
    const [searchString, setSearchString] = useState("");

    const accounts = useSelector((state: GlobalState) => {
        return state.profile.accounts;
    });

    const history = useHistory();
    function goBack(): void {
        history.goBack();
    }

    const dispatch = useDispatch();

    function dispatchFetchAccountUser(publisherId: number): void {
        dispatch(fetchAccountsUser(publisherId));
    }

    function dispatchPushWithAccount(data: PushAccountParams): void {
        dispatch(pushWithAccount(data));
    }

    function dispatchPostAccount(payload: PostAccountParams): void {
        dispatch(postAccount(payload));
    }

    function dispatchPush(route: string): void {
        dispatch(push(route));
    }

    const openAddAccountFormModal = (): void => {
        dispatch(addAccountModalOpen(true));
        dispatch(editAccountModalOpen(false));
    };

    const currentAccount =
        accounts &&
        accounts.find(account => {
            return account.id.toString() === visibilityId;
        });

    if (accounts === null) {
        return (
            <>
                <Navbar back title="SOCIAL ACCOUNTS" />
                <MainContainer>
                    <PaddingAround>
                        <XCard
                            text="Failed to load accounts, click the button below to try again."
                            button={{
                                text: "Try again",
                                callback: () => {
                                    dispatchFetchAccountUser(profile.id);
                                }
                            }}
                        />
                    </PaddingAround>
                </MainContainer>
            </>
        );
    } else if (!accounts) {
        return (
            <>
                <Navbar back title="SOCIAL ACCOUNTS" />
                <FullscreenLoader />
            </>
        );
    } else {
        const verified = accounts.reduce((acc, account) => {
            if (account.verified) {
                return acc + 1;
            } else {
                return acc;
            }
        }, 0);

        const unverified = accounts.reduce((acc, account) => {
            if (account.verified) {
                return acc;
            } else {
                return acc + 1;
            }
        }, 0);

        return (
            <>
                <Navbar back title="SOCIAL ACCOUNTS" />
                <MainContainer>
                    <Header
                        title={"Accounts"}
                        subtitle={`${verified} Verified; ${unverified} Unverified`}
                    />

                    <PaddingNoTop>
                        <SearchBar
                            searchString={searchString}
                            updateSearchString={setSearchString}
                        />
                    </PaddingNoTop>

                    <PaddingNoTop>
                        {accounts
                            .filter(account => {
                                if (searchString.length === 0) {
                                    return true;
                                } else {
                                    return account.username
                                        .toLowerCase()
                                        .includes(searchString.toLowerCase());
                                }
                            })
                            .map(account => {
                                return (
                                    <AccountCell
                                        key={account.id}
                                        account={account}
                                        push={dispatchPush}
                                        pushWithAccount={
                                            dispatchPushWithAccount
                                        }
                                        privacyCallback={() => {
                                            dispatchPush(
                                                `/profile/accounts/visibility/${account.id}`
                                            );
                                        }}
                                        showRates
                                    />
                                );
                            })}
                    </PaddingNoTop>
                    <PaddingNoTop>
                        <BigOutlineButton onClick={openAddAccountFormModal}>
                            + Add Social Account
                        </BigOutlineButton>
                    </PaddingNoTop>

                    {currentAccount && (
                        <PrivacyAccountModal
                            account={currentAccount}
                            cancelCallback={goBack}
                            doneCallback={newVisibility => {
                                dispatchPostAccount({
                                    ...currentAccount,
                                    visibility: newVisibility
                                });
                            }}
                        />
                    )}
                </MainContainer>
            </>
        );
    }
};

export default AccountsPage;
