import React, { useState } from "react";
import { Contest, ContestLeaderboard, ContestRank } from "../types";
import NavButton from "../../../components/NavButton";
import SimpleNavbar from "../../../components/SimpleNavbar";
import Rules from "./Rules";
import YourRank from "./YourRank";
import Carousel from "./Carousel";
import Leaderboard from "./Leaderboard";
import LeaderboardModal from "src/components/modals/LeaderboardModal";
import { getApi } from "src/Api";
import MainContainer from "src/components/MainContainer";
import styled from "styled-components";
import { colors, maxWidthDesktop } from "src/constants";
import Navbar from "src/components/Navbar";

const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 20px;
    padding: 20px 0px 40px;

    @media (max-width: ${maxWidthDesktop}px) {
        grid-gap: 0px;
        padding: 0px;
    }
`;

const Header = styled.div`
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: ${maxWidthDesktop}px) {
        border: none;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

interface Props {
    contest: Contest;
    push: (path: string) => void;
}

const Main = ({ contest, push }: Props) => {
    const [leaderboard, setLeaderboard] = useState<ContestLeaderboard | null>(
        null
    );
    const [leaderboardRanks, setLeaderboardRanks] = useState<ContestRank[]>([]);
    const getLeaderboard = (selected: ContestLeaderboard) => {
        getApi(
            `/contest/${contest.id}/leaderboard/${selected.id}/ranking`,
            {}
        ).then(response => {
            setLeaderboardRanks(response.data.data.ranking);
        });
    };

    if (leaderboard || leaderboardRanks.length > 0) {
        return (
            <>
                <Navbar
                    customMobileNavbar={
                        <SimpleNavbar
                            left={
                                <NavButton
                                    onClick={() => setLeaderboard(null)}
                                    back
                                />
                            }
                        />
                    }
                />

                <LeaderboardModal
                    leaderboard={leaderboardRanks}
                    closeCallback={() => setLeaderboardRanks([])}
                />
            </>
        );
    } else {
        return (
            <>
                <Navbar back title="CLAIM PRIZE" />

                <MainContainer>
                    <Content>
                        <Header>
                            <Carousel contest={contest} />
                            <Rules contest={contest} push={push} />
                        </Header>

                        <YourRank contest={contest} push={push} />

                        {contest.leaderboard.map((leaderboard, idx) => {
                            return (
                                <Leaderboard
                                    key={idx}
                                    leaderboard={leaderboard}
                                    setLeaderboard={getLeaderboard}
                                />
                            );
                        })}
                    </Content>
                </MainContainer>
            </>
        );
    }
};

export default Main;
