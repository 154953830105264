import styled, { keyframes, css } from "styled-components";
import { colors, fonts } from "../../constants";
import { flexRowCenter } from "src/utils/styles/snippets";

const animation = keyframes`
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
`;

export const Button = styled.button<{ disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    font-size: ${fonts.subtitle}px;
    /* line-height: 14px; */
    transition: background-color 0.2s;
    user-select: none;
    outline: none !important;
    box-sizing: border-box;
    cursor: pointer;
    opacity: ${props => props.disabled && 0.3};
    cursor: ${props => props.disabled && "default"};
`;

// Red Button

interface ShimmerButtonProps {
    shimmer?: boolean;
}
export const RedButton = styled(Button)<ShimmerButtonProps>`
    background-color: ${colors.primaryRed};
    color: white;
    &:hover {
        background-color: ${colors.primaryRedDark};
    }
    ${p =>
        p.shimmer &&
        css`
            background: #e61346
                linear-gradient(
                    to right,
                    #e61346 0%,
                    #ff5b83 20%,
                    #e61346 40%,
                    #e61346 100%
                )
                no-repeat;
            background-size: 800px 1200px;

            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: ${animation};
            animation-timing-function: linear;
        `}
`;

export const DeleteButton = styled(RedButton)`
    height: 56px;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    border-radius: 6px;
`;

// Blue Button

export const BlueButton = styled(Button)<ShimmerButtonProps>`
    background-color: ${props =>
        props.disabled ? colors.primaryBlueDark : colors.primaryBlue};
    color: white;
    border-radius: 6px;
    transition: 0.1s ease-in;

    @media (hover: hover) {
        :hover {
            opacity: ${props => (props.disabled ? 1 : 0.8)};
        }
    }
    ${p =>
        p.shimmer &&
        css`
            background: #0079f5
                linear-gradient(
                    to right,
                    #0079f5 0%,
                    #66b3ff 20%,
                    #0079f5 40%,
                    #0079f5 100%
                )
                no-repeat;
            background-size: 800px 1200px;

            animation-duration: 1.5s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: ${animation};
            animation-timing-function: linear;
        `}
`;

export const BigBlueButton = styled(BlueButton)`
    height: 48px;
    width: 100%;
`;

export const BlueConnectionButton = styled(BlueButton)`
    height: 32px;
    width: 100px;
`;

export const BlueSearchConnectionButton = styled(BlueButton)`
    font-size: 12px;
    height: 28px;
    font-weight: 500;
    width: 80px;
`;

export const RecommendationButton = styled(BlueButton)`
    height: 28px;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
`;

export const CopyButton = styled(BlueButton)<{ inactive?: boolean }>`
    height: 36px;
    width: 72px;
    border-radius: 4px;
    margin-left: auto;
    ${props => props.inactive && `background-color: ${colors.secondaryGray}`}
`;

export const BlueModalButton = styled(BlueButton)`
    height: 56px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;

    &:disabled {
        opacity: 0.5;
    }
`;

export const BlueEmptyButton = styled(BlueButton)`
    height: 32px;
    padding: 0 15px;
    margin-top: 20px;
`;

// Green Button

export const GreenButton = styled(Button)`
    background-color: ${colors.primaryGreen};
    color: ${colors.white};
    border-radius: 6px;
`;

export const GreenModalButton = styled(GreenButton)`
    ${flexRowCenter()};
    height: 58px;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.32px;

    &:disabled {
        opacity: 0.5;
    }
`;

// Outline Button

export const OutlineButton = styled(Button)<{ textColor?: string }>`
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    color: ${props => props.textColor || colors.primaryBlue};
    border-radius: 6px;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
            border: ${colors.darkTeal} solid 1px;
        }
    }

    &:disabled {
        color: ${colors.primaryGray};
        cursor: default;
    }
`;

export const OutlineConnectionButton = styled(OutlineButton)`
    height: 32px;
    width: 100px;
`;

export const OutlineRecommendationButton = styled(OutlineButton)`
    height: 28px;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
`;

export const BigOutlineButton = styled(OutlineButton)`
    width: 100%;
    height: 48px;
`;

export const OutlineSearchConnectionButton = styled(OutlineButton)`
    font-size: 12px;
    height: 28px;
    font-weight: 500;
    width: 80px;
`;

export const SquareConnectionButton = styled(OutlineButton)`
    width: 32px;
    height: 32px;
`;

export const OutlineEmptyButton = styled(OutlineButton)`
    height: 32px;
    padding: 0 15px;
    margin-top: 20px;
`;

export const OutlineTextButton = styled(OutlineButton)`
    border-radius: 4px;
    padding: 6px 12px;
    color: ${colors.primaryGray};
`;

export const CloseModalButton = styled(OutlineButton)`
    height: 56px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
`;

// borderless button

export const BorderlessButton = styled(Button)`
    background-color: white;
    border-radius: 6px;
    color: ${colors.primaryBlue};
`;

export const GrayButton = styled(Button)`
    background: ${colors.quaternaryLightGray};
    border-radius: 6px;
    color: ${colors.black};
    font-size: ${fonts.semiSmallTitle}px;
    transition: background-color 0.2s ease;
    &:hover {
        background-color: ${colors.quaternaryGray};
    }
`;
