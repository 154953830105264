import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import EmojiModal from "../../../modals/EmojiModal";
import {
    Title,
    Section,
    InputContainer,
    EmailInput,
    Button
} from "../styledComponents";
import { updateOrCreateTipaltiUser, resendTipaltiEmail } from "../../actions";
import { isValidEmail } from "../../../util";
import { useIntercom } from "react-use-intercom";
import { clearModal } from "src/modals/actions";

interface Props {
    overThreshold?: boolean;
    userExists?: boolean;
    initialEmail: string | null;
    updateOrCreateTipaltiUser: (email: string) => void;
    resendTipaltiEmail: () => void;
    next: () => void;
}

const TipaltiEmailModal = ({
    initialEmail,
    overThreshold,
    userExists,
    next,
    updateOrCreateTipaltiUser
}: Props) => {
    const [submittedTipalti, setSubmittedTipalti] = useState(false);
    const [email, setEmail] = useState(initialEmail || "");
    const [focus, setFocus] = useState(false);
    const { update, showNewMessages } = useIntercom();
    const dispatch = useDispatch();

    if (submittedTipalti) {
        return (
            <EmojiModal emoji="😎">
                <Title>Sent. Go to your inbox!</Title>
                <Section>{email}</Section>
                <Button onClick={next}>Close</Button>
            </EmojiModal>
        );
    } else if (userExists) {
        return (
            <EmojiModal emoji="✋">
                <Title>You're unable to cashout right now.</Title>
                <Section>
                    Please make sure you've registered for Plug Payments and
                    your registration has been completed successfully.
                </Section>
                <Button
                    onClick={() => {
                        update({
                            customAttributes: {
                                disable_chat_bot: true
                            }
                        });
                        showNewMessages(
                            `I need my Tipalti invite email resent to me. Email: ${email}`
                        );
                        dispatch(clearModal());
                    }}
                >
                    Resend Invite
                </Button>
                <Button
                    style={{ marginTop: 16 }}
                    onClick={() => {
                        window.open(
                            "https://suppliers.tipalti.com/theplug/portal/index"
                        );
                    }}
                >
                    Check Status
                </Button>
            </EmojiModal>
        );
    }

    return (
        <EmojiModal emoji="🎉">
            {overThreshold ? (
                <>
                    <Section>
                        By US Law, we’re now required to collect tax forms.
                        Enter your email to receive next steps.
                    </Section>
                </>
            ) : (
                <>
                    <Title>Great, let’s set it up!</Title>
                    <Section>
                        By US Law, we’re required to collect tax forms to pay
                        with methods other than PayPal. Enter your email to
                        receive next steps:
                    </Section>
                </>
            )}
            <InputContainer focus={focus}>
                <EmailInput
                    type="email"
                    placeholder="Your Email"
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    value={email}
                    onChange={e => setEmail(e.currentTarget.value)}
                />
            </InputContainer>
            <Button
                disabled={!isValidEmail(email)}
                onClick={() => {
                    if (isValidEmail(email)) {
                        updateOrCreateTipaltiUser(email);
                        setSubmittedTipalti(true);
                    }
                }}
            >
                Continue
            </Button>
        </EmojiModal>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    updateOrCreateTipaltiUser: (email: string) =>
        dispatch(updateOrCreateTipaltiUser(email)),
    resendTipaltiEmail: () => dispatch(resendTipaltiEmail())
});

export default connect(null, mapDispatchToProps)(TipaltiEmailModal);
