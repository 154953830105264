import React from "react";
import styled from "styled-components";
import { Metric, Placement } from "../../types";
import MetricRow from "../../../media/view/MetricRow";

const Main = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 8px;
    color: white;
    pointer-events: none;
    text-align: left;
`;

const Title = styled.div<{ titleSize: number }>`
    font-size: ${props => props.titleSize}px;
    line-height: 1em;
    font-weight: bold;
`;

const Subtitle = styled.div<{ subtitleSize: number }>`
    font-size: ${props => props.subtitleSize}px;
    line-height: 1em;
`;

const PlacementContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.img`
    width: 16px;
    height: 16px;
    border-radius: 4px;
`;

const Text = styled.div`
    margin-left: 4px;
    font-size: 12px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const CampaignName = styled.div`
    font-size: 12px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface Props {
    metrics: Metric[];
    placement: Placement;
    campaignName: string;
}

const MediaPlacementOverlay = ({ metrics, placement, campaignName }: Props) => {
    return (
        <Main>
            <div>
                {metrics.map((metric, idx) => (
                    <MetricRow key={`metric-${idx}`} metric={metric} />
                ))}
            </div>
            <div>
                <PlacementContainer>
                    <Logo src={placement.logo} />
                    <Text>{placement.type}</Text>
                </PlacementContainer>
                <CampaignName>{campaignName}</CampaignName>
            </div>
        </Main>
    );
};

export default MediaPlacementOverlay;
