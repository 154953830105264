import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, post, patch } from "src/Api";
import MediaPreview from "src/campaigns/promote/components/MediaPreview";
import { FlexVerticallyCentered } from "src/components/CommonStyles";
import FlexColumn from "src/components/FlexColumn";
import LoadingButton from "src/components/LoadingButton";
import { colors } from "src/constants";
import Modal from "src/modals/Modal";
import { GlobalState } from "src/reducers";
import Platforms from "src/social-accounts/Platforms";
import { addNotification } from "src/ui/notifications/actions";
import styled from "styled-components";
import {
    addAssignedScreenshots,
    removeAssignedScreenshots,
    updatePublicBuys
} from "../redux/actions";
import { PBPreview, PublicBuysScreenshot } from "../redux/reducer";
import PublicBuysScreenshotPreview from "./PublicBuysScreenshot";

const Main = styled.div`
    display: flex;
    flex-direction: row;
`;
const Image = styled.img`
    height: 450px;
    width: 400px;
    object-fit: contain;
    background-color: ${colors.black};
`;

const Media = styled.div`
    font-weight: 500;
    padding: 4px;
`;

const ItemCell = styled.div`
    ${FlexVerticallyCentered}
    margin: 10px;
    cursor: pointer;
    user-select: none;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 2px;
`;

const AppIcon = styled.img`
    width: 46px;
    height: 46px;
    border-radius: 12px;
    margin-right: 12px;
`;

const ScreenshotContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    width: 100%;
    margin-top: 12px;
    overflow-y: auto;
    height: 400px;
`;

interface Props {
    onClose: () => void;
    buy: PBPreview;
}

const PublicBuysModal = ({ onClose, buy }: Props) => {
    const [screenshots, setScreenshots] = useState<PublicBuysScreenshot[]>([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const hidden = useSelector((state: GlobalState) => state.publicBuys.hidden);
    const assigned = useSelector(
        (state: GlobalState) => state.publicBuys.assigned
    );

    useEffect(() => {
        setLoading(true);
        get("/public/buys/screenshots", { search: "" }).then(res => {
            setScreenshots(res.data.data);
            setLoading(false);
        });
    }, []);

    const linkScreenshot = (screenshotId: number) => {
        setLoading(true);
        dispatch(addAssignedScreenshots(screenshotId, buy.directBuyStepId));
        let temp = { ...buy };
        temp.screenshotUrl = screenshots.find(
            shot => shot.id === screenshotId
        )?.screenshotUrl;

        dispatch(updatePublicBuys(temp));
        setLoading(false);
    };

    const unlinkScreenshot = () => {
        setLoading(true);
        let isAssigned = false;
        assigned.forEach(shot => {
            if (shot.directBuyStepId === buy.directBuyStepId) {
                isAssigned = true;
            }
        });
        if (isAssigned) {
            dispatch(removeAssignedScreenshots(buy.directBuyStepId));
            let temp = { ...buy };
            temp.screenshotUrl = undefined;
            dispatch(updatePublicBuys(temp));
            setLoading(false);
        } else {
            patch(`/public/buys/${buy.directBuyStepId}/unlink`, {})
                .then(res => {
                    let temp = { ...buy };
                    temp.screenshotUrl = undefined;
                    dispatch(updatePublicBuys(temp));
                    addNotification(
                        "Your screenshot submission has been removed "
                    );
                    setLoading(false);
                })
                .catch(err => {
                    dispatch(
                        addNotification("Failed to remove screenshot", "red")
                    );
                    setLoading(false);
                });
        }
    };

    const checkAssigned = (id: number) => {
        let found = false;
        assigned.forEach(shot => {
            if (shot.screenshotId === id) found = true;
        });
        return found;
    };

    return (
        <Modal
            onClick={onClose}
            style={{
                maxWidth: "80vw",
                minWidth: 900,
                maxHeight: "90vh",
                width: "auto"
            }}
        >
            <Main>
                <FlexColumn>
                    <Media>Media ID: {buy.mediaId}</Media>
                    <MediaPreview
                        media={{
                            type: buy.type,
                            url: buy.url,
                            coverPhotoUrl: buy.coverPhotoUrl
                        }}
                        style={{
                            height: "600px",
                            width: "350px",
                            backgroundColor: "black"
                        }}
                    />
                </FlexColumn>
                <FlexColumn style={{ margin: "auto" }}>
                    <Media>{moment(buy.offerApprovedAt).format("lll")}</Media>
                    <ItemCell>
                        {buy.imageUrl && <AppIcon src={buy.imageUrl} />}
                        <div>
                            <Title>{buy.campaignName}</Title>
                        </div>
                    </ItemCell>
                    <ItemCell>
                        {<AppIcon src={Platforms[buy.platform]?.imgSrc} />}
                        <div>
                            <Title>{buy.username}</Title>
                        </div>
                    </ItemCell>
                    <Media>
                        {buy.screenshotUrl
                            ? "Submitted Screenshot"
                            : "Select a Screenshot"}
                    </Media>
                    {buy.screenshotUrl ? (
                        <Image src={buy.screenshotUrl} alt="" />
                    ) : (
                        <ScreenshotContainer>
                            {screenshots.map((screenshot, index) =>
                                screenshot.screenshotUrl &&
                                !hidden.includes(screenshot.screenshotUrl) &&
                                !checkAssigned(screenshot.id || 0) ? (
                                    <PublicBuysScreenshotPreview
                                        key={index + "screenshot"}
                                        src={screenshot.screenshotUrl || ""}
                                        onClick={() =>
                                            linkScreenshot(screenshot.id || 0)
                                        }
                                        status=""
                                        info={
                                            moment(screenshot.createdAt).format(
                                                "lll"
                                            ) +
                                            "\n Uploaded By: " +
                                            screenshot.uploadedBy
                                        }
                                    />
                                ) : null
                            )}
                        </ScreenshotContainer>
                    )}
                    {buy.screenshotUrl && (
                        <LoadingButton
                            onClick={unlinkScreenshot}
                            isLoading={loading}
                            text="Remove Screenshot"
                            backgroundColor={colors.primaryRed}
                            textColor="white"
                            margin="10px 0"
                            height="40px"
                        />
                    )}
                </FlexColumn>
            </Main>
        </Modal>
    );
};
export default PublicBuysModal;
