import React from "react";
import { Campaign, MediaRule } from "src/campaigns/types";
import styled from "styled-components";
import Modal from "../../modals/Modal";
import ModalButton from "../../modals/ModalButton";
import PulseLoader from "../../components/PulseLoader";
import { colors, fonts } from "../../constants";

const Main = styled.div`
    padding: 20px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Icon = styled.img`
    height: 100px;
    width: 100px;
    border-radius: 20px;
`;

const TextWrapper = styled.div`
    margin-top: 10px;
`;

const RulesContainer = styled.div`
    height: 200px;
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 6px;
    text-align: left;
    overflow-y: scroll;

    div:first-of-type {
        margin-top: 0;
    }
`;

const RuleList = styled.ul`
    font-size: ${fonts.subtitle}px;
    margin: 10px 0 0;
    list-style-type: square;
`;

const LoaderContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface Props {
    campaign: Campaign;
    mediaRules?: MediaRule[];
}

const RulesModal = ({ campaign, mediaRules }: Props) => {
    if (!mediaRules) return null;
    const dos = mediaRules.filter(rule => rule.isAllowed);
    const donts = mediaRules.filter(rule => !rule.isAllowed);

    return (
        <Modal>
            <Main>
                <Icon src={campaign.imageUrl} alt={campaign.campaignName} />
                <TextWrapper>
                    All Media submitted for {campaign.campaignName} must follow
                    these guidelines:
                </TextWrapper>
                <RulesContainer>
                    {mediaRules.length === 0 && (
                        <LoaderContainer>
                            <PulseLoader />
                        </LoaderContainer>
                    )}
                    {dos.length > 0 && (
                        <>
                            <TextWrapper>
                                <strong>DOs</strong>
                            </TextWrapper>
                            <RuleList>
                                {dos.map((rule, idx) => (
                                    <li key={idx}>{rule.rule}</li>
                                ))}
                            </RuleList>
                        </>
                    )}
                    {donts.length > 0 && (
                        <>
                            <TextWrapper>
                                <strong>DON'Ts</strong>
                            </TextWrapper>
                            <RuleList>
                                {donts.map((rule, idx) => (
                                    <li key={idx}>{rule.rule}</li>
                                ))}
                            </RuleList>
                        </>
                    )}
                </RulesContainer>
            </Main>
            <ModalButton text={"Close"} />
        </Modal>
    );
};

export default RulesModal;
