export interface ThemeType {
    title: string;
}

const Themes: { [key: string]: ThemeType } = {
    personal: {
        title: "Personal"
    },
    meme: {
        title: "Meme"
    },
    celebrity: {
        title: "Celebrity"
    },
    food: {
        title: "Food"
    },
    gaming: {
        title: "Gaming"
    },
    fashion: {
        title: "Fashion"
    },
    satisfying: {
        title: "Satisfying"
    },
    couples: {
        title: "Couples"
    },
    animals: {
        title: "Animals"
    },
    fitness: {
        title: "Fitness"
    },
    sports: {
        title: "Sports"
    },
    lifestyle: {
        title: "Lifestyle"
    },
    facts: {
        title: "Facts"
    },
    model: {
        title: "Model"
    },
    makeup: {
        title: "Makeup"
    },
    music: {
        title: "Music"
    },
    outdoors: {
        title: "Outdoors"
    },
    travel: {
        title: "Travel"
    },
    freaky: {
        title: "Freaky"
    },
    other: {
        title: "Other"
    }
};

export default Themes;
