import React from "react";
import SmallModal from "src/modals/SmallModal";
import styled, { css } from "styled-components";
import { OutlineButton, RedButton } from "src/profile/components/Buttons";
import withModalPortal from "./withModalPortal";
import { colors } from "src/constants";
import { CustomLoading } from "./SkeletonLoader";

const StyledSmallModal = styled(SmallModal)`
    padding-left: 12px;
    padding-right: 12px;
    z-index: 999999999999999999;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;
const TitleWrap = styled.div`
    display: flex;
`;
const Title = styled.div`
    font-size: 18px;
`;
const Icon = styled.img`
    width: 28px;
    height: 28px;
    display: block;
    margin-right: 4px;
`;
const Text = styled.p`
    font-size: 14px;
    margin: 0px;
    margin-bottom: 16px;
    margin-top: 12px;
    padding: 0px;
`;
const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const StyledOutlineButton = styled(OutlineButton)`
    padding: 8px 12px;
    margin-right: 8px;
`;
const StyledDeleteButton = styled(RedButton)<{ blueBtn?: boolean }>`
    padding: 8px 12px;
    border-radius: 6px;
    font-weight: bold;

    ${props =>
        props.blueBtn
            ? css`
                  background-color: ${colors.primaryBlue};
                  &:hover {
                      background-color: ${colors.primaryBlueDark};
                  }
              `
            : ""}
`;

export interface GlobalWarningProps {
    handleCancel: VoidFunction;
    handleApply: VoidFunction;
    title: string;
    message: string;
    btnText?: { cancel: string; apply: string };
    blueApplyBtn?: boolean;
    isLoadingMessage?: boolean;
}

const GlobalWarning = ({
    handleCancel,
    handleApply,
    title,
    message,
    btnText = { cancel: "Cancel", apply: "Apply" },
    blueApplyBtn,
    isLoadingMessage
}: GlobalWarningProps) => {
    return (
        <StyledSmallModal>
            <Wrapper>
                <TitleWrap>
                    <Icon src={"/warning.svg"} />
                    <Title>{title}</Title>
                </TitleWrap>
                {isLoadingMessage ? (
                    <CustomLoading
                        style={{ margin: "12px 0px 16px" }}
                        width="100%"
                        height="16px"
                    />
                ) : (
                    <Text>{message}</Text>
                )}
                <BtnWrap>
                    <StyledOutlineButton onClick={handleCancel}>
                        {btnText.cancel}
                    </StyledOutlineButton>
                    <StyledDeleteButton
                        blueBtn={blueApplyBtn}
                        onClick={handleApply}
                    >
                        {btnText.apply}
                    </StyledDeleteButton>
                </BtnWrap>
            </Wrapper>
        </StyledSmallModal>
    );
};

export default withModalPortal(GlobalWarning);
