import React from "react";
import styled from "styled-components";
import { colors } from "src/constants";

const Container = styled.div<{
    height: string;
    width: string;
}>`
    position: relative;
    border-radius: 100%;
    background-color: ${colors.primaryBlue};
    text-align: center;
    height: ${props => props.height || "70px"};
    width: ${props => props.width || "70px"};
`;

const Icon = styled.img`
    height: 100%;
    width: 40%;
`;
const Photo = styled.img<{ clickable: boolean }>`
    height: 100%;
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
    display: block;
    ${props => props.clickable && "cursor: pointer;"}
`;

const Status = styled.img`
    height: 28%;
    width: 28%;
    position: absolute;
    bottom: 0;
    right: 0;
`;

const Crown = styled.img`
    height: 30px;
    width: 38px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -24px;
`;

interface Props {
    profilePhotoUrl?: string | null;
    type?: string;
    style?: any;
    onClick?: () => any;
    place?: number;
}

const places = [
    "/leaderboard/crown.svg",
    "/leaderboard/2nd-medal.svg",
    "/leaderboard/3rd-medal.svg",
    "/leaderboard/fire.svg",
    "/leaderboard/start-medal.svg"
];

const LeaderboardPhoto = (props: Props) => {
    const { type, profilePhotoUrl, style, place = 4, onClick } = props;

    return type === "own" ? (
        <Container
            style={{ ...style }}
            height={place === 1 ? "90px" : "70px"}
            width={place === 1 ? "90px" : "70px"}
        >
            <Photo
                onClick={onClick}
                clickable={Boolean(onClick)}
                src={
                    profilePhotoUrl ||
                    (onClick
                        ? "/profile_pic_blue/profile_pic_blue@2x.png"
                        : "/no_profile_photo.svg")
                }
            />
            {place === 1 && <Crown src={"/crown/crown@2x.png"} />}
        </Container>
    ) : (
        <Container
            style={{ ...style }}
            height={place === 1 ? "90px" : "70px"}
            width={place === 1 ? "90px" : "70px"}
        >
            <Icon src={places[place - 1]} />
        </Container>
    );
};

export default LeaderboardPhoto;
