import React, { useState } from "react";
import styled from "styled-components";
import VerticallyCentered from "./VerticallyCentered";
import { colors, maxWidthDesktop, fonts } from "../constants";

const Main = styled(VerticallyCentered)`
    flex-wrap: wrap;
    margin: 20px 15px 0px;
    padding: 15px 7px 7px 15px;
    background-color: ${colors.lightTeal};
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 8px;

    @media (max-width: ${maxWidthDesktop}px) {
        margin: 0px;
        border: none;
        border-bottom: ${colors.mediumTeal} solid 1px;
        border-radius: 0px;
    }
`;

const Tag = styled(VerticallyCentered)`
    padding: 4px 6px;
    margin: 0 8px 8px 0;
    border: 1px solid ${colors.mediumTeal};
    border-radius: 2px;
    color: ${colors.primaryGray};
    font-weight: 600;
    font-size: ${fonts.smallSubtitle}px;
    line-height: 12px;
`;

const RemoveButton = styled.img`
    margin-left: 6px;
    cursor: pointer;
`;

const Input = styled.input`
    border: none;
    outline: none;
    background: none;
    width: 100px;
    height: 22px;
    font-size: ${fonts.subtitle}px;
    padding: 0;
    margin: 0 8px 8px 0;
`;

interface TagData {
    name: string;
    value: string | number;
}

interface Props {
    searchString: string;
    setSearchString: (searchString: string) => void;
    tags: TagData[];
    removeCallback: (value: string | number) => void;
}

const SearchWithTags = ({
    searchString,
    setSearchString,
    tags,
    removeCallback
}: Props) => {
    const [placeholder, setPlaceholder] = useState("Search...");

    return (
        <Main>
            {tags.map(tagData => {
                return (
                    <Tag key={tagData.value}>
                        <div>{tagData.name}</div>
                        <RemoveButton
                            src="/teal-close.svg"
                            onClick={() => {
                                removeCallback(tagData.value);
                            }}
                        />
                    </Tag>
                );
            })}
            <Input
                placeholder={placeholder}
                value={searchString}
                onChange={e => {
                    setSearchString(e.currentTarget.value);
                }}
                onFocus={() => {
                    setPlaceholder("");
                }}
                onBlur={() => {
                    setPlaceholder("Search...");
                }}
            />
        </Main>
    );
};

export default SearchWithTags;
