import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patch, post } from "src/Api";
import { OpenBuySubmissionSet } from "src/buys/modules/open-buys/types";
import { colors } from "src/constants";
import { BigBlueButton } from "src/profile/components/Buttons";
import { GlobalState } from "src/reducers";
import { setPillNotificationText } from "src/ui/notifications/actions";
import styled from "styled-components";
import UpdateSocialModal from "./UpdateSocialModal";
import Tooltip from "src/components/Tooltip";
import { toggleLeaderboardEditorOpen } from "src/ui/marketplace/actions";
import SetDeletionConfirmationModal from "./SetDeletionConfirmationModal";

const Wrapper = styled.div`
    width: 360px;
    padding: 12px;
    gap: 10px;
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-size: 13px;
    text-align: center;
    padding: 5px 0px;
`;
const Action = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    height: 46px;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: ${colors.quaternaryLightGray};
    }
`;

const Caret = styled.img`
    transform: rotate(-90deg);
    height: 12px;
    width: 12px;
`;

const DangerAction = styled(BigBlueButton)`
    background: red;
`;

const StyledTooltip = styled(Tooltip)`
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
`;
interface Props {
    submissionSet: OpenBuySubmissionSet;
    getSubmissionSet: VoidFunction;
    advanceOrCloseCarousel: VoidFunction;
    close: VoidFunction;
    tooltipOpen: boolean;
    tooltipRef: any;
}
const ApprovalActions = ({
    submissionSet,
    getSubmissionSet,
    advanceOrCloseCarousel,
    close,
    tooltipOpen,
    tooltipRef
}: Props) => {
    const [modal, setModal] = useState<
        "" | "views" | "social" | "upload" | "delete"
    >("");
    const dispatch = useDispatch();
    const employeeEmail = useSelector(
        (state: GlobalState) => state.profile.profile?.email
    );
    const handleVerify = async () => {
        if (
            submissionSet.socialAccountId &&
            !submissionSet.socialAccount?.verified
        ) {
            try {
                await post(
                    `/v1/openBuySubmission/${submissionSet.socialAccountId}/verifySocialAccount`,
                    {
                        employeeEmail
                    }
                );
                getSubmissionSet();
                dispatch(
                    setPillNotificationText(
                        "Social account verified",
                        "success",
                        3500
                    )
                );
            } catch {
                dispatch(
                    setPillNotificationText(
                        "Social account failed to verify",
                        "danger",
                        3500
                    )
                );
            }
        } else {
            dispatch(
                setPillNotificationText(
                    "Account already verified",
                    "warning",
                    3500
                )
            );
        }
    };

    const updateSubmissionSet = async (updateObject: any) => {
        try {
            let res = await patch(
                `/v1/openBuySubmissionSet/${submissionSet.id}`,
                updateObject
            );

            dispatch(setPillNotificationText("Updated submission"));
            if (updateObject.leaderboardPinned !== undefined)
                dispatch(toggleLeaderboardEditorOpen(submissionSet.openBuyId));
            else getSubmissionSet();
        } catch (err) {
            dispatch(
                setPillNotificationText("Failed to update submission", "danger")
            );
        }
    };

    return (
        <>
            <StyledTooltip
                position={"top: 30px; left: -300px"}
                controlRef={tooltipRef}
                zIndex={2}
                isOpen={tooltipOpen}
                close={() => {
                    if (!modal) close();
                }}
            >
                <Wrapper>
                    <Title>Admin Tools</Title>

                    <>
                        {/* <Action
                            onClick={() => {
                                updateSubmissionSet({
                                    leaderboardHidden: !submissionSet.leaderboardHidden
                                });
                            }}
                        >
                            Hide from Leaderboard
                            <Toggler
                                isActive={!!submissionSet.leaderboardHidden}
                            />
                        </Action>
                        <Action
                            onClick={async () => {
                                if (!submissionSet.leaderboardPinned)
                                    await patch(
                                        `/v1/openBuySubmissionSet/${submissionSet.id}/leaderboard/pin`,
                                        {
                                            position: 1,
                                            direction: 1
                                        }
                                    );

                                dispatch(
                                    toggleLeaderboardEditorOpen(
                                        submissionSet.openBuyId
                                    )
                                );
                            }}
                        >
                            {submissionSet.leaderboardPinned
                                ? "Edit Leaderboard"
                                : "Pin to Leaderboard"}
                            <Caret src={"/caret.svg"} />
                        </Action> */}
                    </>
                    <Action onClick={() => setModal("social")}>
                        Update Social Account
                        <Caret src={"/caret.svg"} />
                    </Action>
                    <Action onClick={handleVerify}>
                        Verify Social Account
                        <Caret src={"/caret.svg"} />
                    </Action>

                    <DangerAction onClick={() => setModal("delete")}>
                        Delete Submission Set
                    </DangerAction>
                </Wrapper>
            </StyledTooltip>
            {modal === "social" && (
                <UpdateSocialModal
                    getSubmissionSet={getSubmissionSet}
                    submissionSet={submissionSet}
                    closeModal={() => setModal("")}
                />
            )}
            {modal === "delete" && (
                <SetDeletionConfirmationModal
                    id={submissionSet.id}
                    closeModal={() => setModal("")}
                    advanceOrCloseCarousel={advanceOrCloseCarousel}
                />
            )}
        </>
    );
};

export default ApprovalActions;
