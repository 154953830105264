import { goBack } from "connected-react-router";
import React from "react";
import { Spinner } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { rateFormModalOpen } from "src/ui/profile/actions";
import styled from "styled-components";
import { colors } from "../../constants";
import { GlobalState } from "../../reducers";
import { BigBlueButton } from "../components/Buttons";
import { patchPublisherOffer, postPublisherOffer } from "./actions";
import { pricingFinished, PricingParams } from "./modals/OfferPricingModal";
import {
    offerTypeFinished,
    OfferTypeParams
} from "./modals/OfferTypeModalController";

const ConfirmButtonWrapper = styled.div`
    border-top: 1px solid ${colors.mediumTeal};
    padding: 12px 18px 24px;
`;

const ConfirmButton = styled(BigBlueButton)`
    font-weight: 500;

    &:disabled {
        opacity: 0.5;
    }
`;

const Error = styled.div`
    color: ${colors.primaryRed};
    font-size: 12px;
    margin-bottom: 4px;
`;

interface Props {
    offerType: OfferTypeParams;
    pricing: PricingParams;
    accountId: null | number;
    edit?: boolean;
    hasChanges: boolean;
    editId: number | null;
}

const RateFormConfirmButton = ({
    offerType,
    pricing,
    accountId,
    edit,
    editId,
    hasChanges
}: Props) => {
    const { postLoading, postError } = useSelector(
        (state: GlobalState) => state.ui.publisherOffer,
        shallowEqual
    );
    const dispatch = useDispatch();
    const finished =
        pricingFinished(pricing) && offerTypeFinished(offerType) && accountId;

    const submitOffer = () => {
        const { placementId, duration, permanent, customType } = offerType;
        const { customType: customPaymentType, amount, paymentId } = pricing;
        const seconds = duration;

        if (!accountId || !finished || !hasChanges) return null;

        const offer = {
            publisherOfferPosts: [
                {
                    socialAccountId: accountId,
                    ...(placementId && { placementId }),
                    ...(permanent && { permanent }),
                    ...(customType && { customType }),
                    ...(seconds && { duration: seconds }),
                    publisherOfferPayments: [
                        {
                            ...(paymentId && { paymentId }),
                            ...(customPaymentType && {
                                customType: customPaymentType
                            }),
                            amount
                        }
                    ]
                }
            ]
        };

        if (edit && editId) {
            dispatch(patchPublisherOffer(editId, accountId, offer));
        } else {
            dispatch(postPublisherOffer(offer));
        }

        dispatch(goBack());
    };

    return (
        <ConfirmButtonWrapper>
            {postError && <Error>{postError}</Error>}
            <ConfirmButton
                disabled={!finished || postLoading || !hasChanges}
                onClick={submitOffer}
            >
                {postLoading ? (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : edit ? (
                    "Update Offer"
                ) : (
                    "Create Offer"
                )}
            </ConfirmButton>
        </ConfirmButtonWrapper>
    );
};

export default RateFormConfirmButton;
