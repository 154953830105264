import {
    PATCH_PUBLISHER_OFFER,
    POST_PUBLISHER_OFFER
} from "../../profile/rates/actions";
import { Action } from "../../types";

export interface PublisherOfferUIState {
    postLoading: boolean;
    postError: string;
}

const initialPublisherOfferUIState: PublisherOfferUIState = {
    postLoading: false,
    postError: ""
};

const publisherOfferUIReducer = (
    state = initialPublisherOfferUIState,
    action: Action
) => {
    switch (action.type) {
        case POST_PUBLISHER_OFFER.REQUEST:
        case PATCH_PUBLISHER_OFFER.REQUEST:
            return { ...state, postLoading: true, postError: "" };
        case POST_PUBLISHER_OFFER.SUCCESS:
        case PATCH_PUBLISHER_OFFER.SUCCESS:
            return { ...state, postLoading: false };
        case POST_PUBLISHER_OFFER.FAILURE:
        case PATCH_PUBLISHER_OFFER.REQUEST:
            return {
                ...state,
                postLoading: false,
                postError: action.payload.error
            };
        default:
            return state;
    }
};

export default publisherOfferUIReducer;
