import { SocialAccount } from "src/profile/types";

export function getErroredSocialAccount(socials: SocialAccount[]) {
    let errored: SocialAccount[] = [];
    for (const social of socials) {
        if (social.verificationFailureReason) {
            errored.push(social);
        }
    }
    return errored;
}
